// legacy 버튼 -> 이 파일보다는 DSButton 사용 권장

// @ts-strict-ignore
import React from 'react'
import Div from '../basic/Div'
import { DivPropType } from '../basic/DivPropsType'
import LoadingSpinner from '../common/LoadingSpinner'

interface ButtonType extends DivPropType {
  primary?: any
  secondary?: any
  black?: any
  blackOutlined?: any
  outlined?: any
  teensOutlined?: any
  outlinedWhite?: any
  outlinedRed?: any
  danger?: any
  warning?: any
  onlyOutlined?: any
  primaryOp20?: any
  hoverPrimary?: any
  hoverSecondary?: any
  disabled?: any
  teens?: any
  lg?: any
  md?: any
  sm?: any
  xs?: any
  children?: any
  clx?: any
  clxComp?: any
  chipMd?: any
  chipSm?: any
  chipSmGray?: any
  chipSmOutlined?: any
  full?: any
  yFull?: any
  flexFull?: any
  lift?: any
  style?: any
  noCursor?: any
  onClick?: any
  tabIndex?: any
  type?: any
  selected?: any
  icon?: any
  aTag?: boolean
  dataTip?: any
  radius?: any
  href?: any
  target?: any
  loading?: boolean
  fontBold?: boolean
  id?: string
  dataRef?: string
}

const Button: React.FC<ButtonType> = (props) => {
  const {
    primary,
    secondary,
    black,
    blackOutlined,
    outlined,
    teensOutlined,
    outlinedWhite,
    outlinedRed,
    onlyOutlined,
    danger,
    warning,
    primaryOp20,
    hoverPrimary,
    hoverSecondary,
    disabled,
    teens,
    lg,
    md,
    sm,
    xs,
    children,
    clx,
    clxComp,
    chipMd,
    chipSm,
    chipSmGray,
    chipSmOutlined,
    full,
    yFull,
    flexFull,
    lift,
    style,
    noCursor,
    onClick,
    tabIndex,
    type,
    selected,
    icon,
    aTag = false,
    dataTip,
    radius,
    href,
    target,
    loading = false,
    id,
    fontBold = false,
    dataRef,
    ...others
  } = props

  return (
    <Div
      dataTip={dataTip}
      buttonTag={!aTag}
      aTag={aTag || href}
      px0
      appearanceNone
      cond={['box-border focus:outline-none cursor-pointer', full && 'w-full', yFull && 'h-full']}
      // 이후 div 태그로 변경할 때 style={false}가 되지 않도록 고려해주어야 합니다
      style={flexFull && { flex: '1' }}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
      id={id}
      dataRef={dataRef}
    >
      <Div
        {...others}
        inlineBlock
        fontNormal={!fontBold}
        fontBold={fontBold}
        textCenter
        clxComp={clxComp}
        clx={clx}
        style={style}
        cond={[
          'text-h4 leading-snug box-border',
          primary && !disabled && 'border border-purple-500 bg-purple-500 text-white hover:bg-purple-500',
          secondary && 'border border-purple-500 bg-purple-500 text-white hover:bg-purple-500',
          teens && 'border border-teens-primary bg-teens-primary text-white hover:opacity-80',
          black && 'border border-black bg-black text-white',
          outlined && selected && 'bg-gray-200 text-purple-500 border border-purple-500 hover:bg-gray-200',
          outlined && !selected && !primaryOp20 && 'bg-white text-purple-500 border border-purple-500 hover:bg-gray-50',
          onlyOutlined && 'text-purple-500 border border-purple-500',
          blackOutlined && 'bg-white text-black border border-black',
          outlinedRed && 'bg-white text-red-500 border border-red-500',
          danger && 'bg-red-500 text-white border border-red-500',
          warning && 'bg-yellow-500 text-black border border-yellow-500',
          outlinedWhite && 'bg-white text-black border border-gray-300 hover:bg-gray-50',
          teensOutlined && 'bg-white text-teens-primary border border-teens-primary hover:bg-gray-50',
          disabled && 'border border-gray-200 bg-gray-200 text-gray-300 hover:bg-gray-200',
          xs && 'btn-xs',
          sm && 'btn-sm',
          md && 'btn-md',
          lg && 'btn-lg',
          !lg && !md && !sm && !xs && 'btn-sm',
          full && 'w-full',
          lift && 'lift',
          chipMd && 'bg-white chip-md text-purple-500 border border-purple-500 hover:bg-opacity-80',
          chipSm && 'bg-purple-500 chip-sm text-white hover:bg-opacity-80 border-purple-500 border',
          chipSmOutlined && 'bg-white chip-sm text-purple-500 border border-purple-500 hover:bg-gray-50',
          noCursor && 'cursor-default',
          primaryOp20 && 'border border-purple-500 bg-purple-50 text-purple-500 hover:bg-opacity-30',
          hoverPrimary && 'hover:text-white hover:bg-purple-500',
          hoverSecondary && 'hover:text-white hover:bg-purple-500',
          icon && 'flex items-center justify-center',
        ]}
      >
        {icon && <img src={icon} className="mr-[8px] h-[20px] w-[20px]" />}

        {loading ? (
          <div className="px-[8px]">
            <LoadingSpinner show md muted={outlined} />
          </div>
        ) : (
          <>
            {href ? (
              <a href={href} target={target ? target : '_self'}>
                {children}
              </a>
            ) : (
              <>{children}</>
            )}
          </>
        )}
      </Div>
    </Div>
  )
}

export default Button
