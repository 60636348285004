// @ts-strict-ignore
/*
  워딩파일 주의사항
  1. 워딩파일은 상단의 해시값으로 깃처럼 버전관리를 하고 있습니다.
  2. 그래서 변경사항이 있어도 해시값을 바꾸지 않으면 다음 wdown/wup 때 변경사항이 사라집니다.
  3. 그러니 머지 컨플릭트가 생길 때에는 꼭 해당 브랜치의 해시값을(incoming) 적용해주세요.
*/
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-target-blank */
import { format } from 'date-fns'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import DateComponent from '../../../../components/DateComponent'
import Div from '../../../../components/basic/Div'
import Br from '../../../../components/common/Br'
import { FeatherChevronRight } from '../../../../components/common/Icons'
import Span from '../../../../components/common/Span'
import urls from '../../../../modules/urls'
import { currencyFormat, financial, getMonthName } from '../../../../modules/utils'
import { DOLLARS_TO_KRW } from '../../../../modules/vars'
import LinkLocale from '../../LinkLocale'
export const s_landing_pricing = {
  ask_channeltalk: [
    `1:1 문의하기`,
    `Contact us`,
    's_landing_pricing.ask_channeltalk',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi`,
  ],
  regular_lesson_title: [
    `정규수업권`,
    `Regular Credits`,
    's_landing_pricing.regular_lesson_title',
    `一般课程券`,
    `一般課程券`,
    `レギュラーレッスン`,
    `Buổi học tiêu chuẩn`,
  ],
  point: [
    `실제 결제한 수업권 금액의 3%를 적립해 드립니다. 적립된 수업 포인트는 결제 및 수강기간 연장에 사용할 수 있습니다. (매 수업 종료 7일 후 자동 적립 예정)`,
    `Additional points 3% of the purchased price can be awarded. Accumulated points can be used on purchasing lessons and extra validity period. (Lesson points are awarded 7 days after taking the lesson)`,
    's_landing_pricing.point',
    `按课程券实际付款金额的3%进行累计积分, 积累的课程积分可用于付款及延长使用期限。(课程积分将于课程结束后7日起自动进行付款)`,
    `額外為您累積實際付款課程券金額的3%，累積的點數可用於付款及延長使用期限。（課程點數於每次課程結束7天後自動累積。）`,
    `実際に決済したレッスンチケット金額の3%を追加で付与いたします。付与されたレッスンポイントは、決済および受講期間の延長に使用することができます。(レッスンポイントは毎レッスン完了7日後に付与されます)`,
    `3% giá trị thanh toán sẽ được quy đổi thành điểm thưởng. Bạn sẽ nhận được điểm trong vòng 7 ngày. Điểm có thể được sử dụng cho lần mua tiếp theo hoặc gia hạn ngày kết thúc của khoá học.`,
  ],
  bonus_lesson_title: [
    `도전기간 내 완강 시 추가 수업`,
    `Challenge Complete Credits`,
    's_landing_pricing.bonus_lesson_title',
    `挑战时间内完成时额外赠送课程`,
    `挑戰期間內聽完時贈送額外課程`,
    `挑戦期間内に全レッスン終了時、追加レッスン`,
    `Thử thách hoàn thành thẻ học`,
  ],
  actual_payment_unit_price: [
    `실질 회당 단가`,
    `Final Price per Credit`,
    's_landing_pricing.actual_payment_unit_price',
    `实际课程单价`,
    `實際單次單價`,
    `1レッスンあたりの単価`,
    `Giá cuối cho một buổi học`,
  ],
  b2b_bonus_lesson_title: [
    `B2B 특별 추가 수업권`,
    `B2B Bonus Credits`,
    's_landing_pricing.b2b_bonus_lesson_title',
    `B2B特别追加课程`,
    `B2B特別額外課程`,
    `B2B特別追加レッスン`,
    `Thẻ học bổ sung B2B`,
  ],
  time: [`회`, `Credit`, 's_landing_pricing.time', `次`, `次`, `回`, ``],
}
export const s_pricing = {
  free: [
    <>
      언제 어디서나 <br />
      자유롭게 수강하세요
    </>,
    <>Learn whenever and wherever</>,
    's_pricing.free',
    <>
      无论何时何地 <br />
      随心自由的学习吧
    </>,
    <>
      隨時隨地
      <br />
      自由地上課吧
    </>,
    <>
      いつでもどこでも <br />
      自由に受講しましょう。
    </>,
    <>Học bất cứ khi nào và ở đâu</>,
  ],
  credit: [`수강권`, `Credits`, 's_pricing.credit', `课程券`, `課程券`, `受講チケット`, `Thẻ học`],
  credit_detail: [
    `수업권 상세`,
    `Credits Detail`,
    's_pricing.credit_detail',
    `课程券详情`,
    `課程券詳情`,
    `チケット料金内訳`,
    `Chi tiết thẻ học`,
  ],
  time: [`운영시간`, `Hours of Operation`, 's_pricing.time', `运营时间`, `營運時間`, `運営時間`, `Giờ làm việc`],
  assure_title: [
    `링글이 보장합니다`,
    `We guarantee Ringle is worth your time`,
    's_pricing.assure_title',
    `Ringle向您承诺`,
    `Ringle向您承諾`,
    `Ringleのお約束`,
    `Chúng tôi đảm bảo Ringle xứng đáng để bạn dành thời gian`,
  ],
  usePoint: [`보유 포인트`, `My points`, 's_pricing.usePoint', `持有积分`, `持有點數`, `保有ポイント`, `Điểm của bạn`],
  useAll: [`모두 사용`, `Use all`, 's_pricing.useAll', `全部使用`, `全部使用`, `すべて利用`, `Sử dụng hết`],
  purchaseMethod: [
    `결제방식`,
    `Payment Method`,
    's_pricing.purchaseMethod',
    `选择付款方式`,
    `選擇付款方式`,
    `お支払い方法`,
    `Chọn phương thức thanh toán`,
  ],
  domesticPayment: [
    `국내 카드 결제`,
    `Credit card`,
    's_pricing.domesticPayment',
    `韩国国内银行卡付款`,
    `韓國國內信用卡付款`,
    `国内カード決済`,
    `Thẻ tín dụng`,
  ],
  normalPayment: [
    `카드 일반 결제`,
    `Credit card`,
    's_pricing.normalPayment',
    `银行卡一般付款`,
    `信用卡一般付款`,
    `カード一般決済`,
    `Thẻ tín dụng`,
  ],
  directPayment: [
    `카드 직접 입력`,
    `Credit card (manual)`,
    's_pricing.directPayment',
    `银行卡直接输入`,
    `手動輸入信用卡`,
    `カード直接入力`,
    `Thẻ tín dụng`,
  ],
  kakaoPayment: [`카카오페이`, `KakaoPay`, 's_pricing.kakaoPayment', `KakaoPay`, `KakaoPay`, `KakaoPay`, `KakaoPay`],
  samsungPayment: [
    `삼성페이`,
    `SamsungPay`,
    's_pricing.samsungPayment',
    `SamsungPay`,
    `SamsungPay`,
    `SamsungPay`,
    `SamsungPay`,
  ],
  paycoPayment: [`PAYCO`, `PAYCO`, 's_pricing.paycoPayment', `PAYCO`, `PAYCO`, `PAYCO`, `PAYCO`],
  dollarPayment: [
    `FALSE`,
    `Pay in USD`,
    's_pricing.dollarPayment',
    `美金付款`,
    `美元付款`,
    `アメリカドルで決済`,
    `Trả bằng USD`,
  ],
  installmentPayment: [
    `분할 결제`,
    `Pay in installations`,
    's_pricing.installmentPayment',
    `分期付款`,
    `分批付款`,
    `分割払い`,
    `Trả góp`,
  ],
  paymentWithTwoCard: [
    `2개 카드 결제`,
    `Pay with two cards`,
    's_pricing.paymentWithTwoCard',
    `双卡付款`,
    `2張信用卡付款`,
    `2枚のカードで決済`,
    `Trả với hai thẻ`,
  ],
  purchase: [`결제하기`, `Submit order`, 's_pricing.purchase', `付款`, `付款`, `決済する`, `Hoàn thành`],
  agreeValidateMessage: [
    `상품 구매 관련 환불 규정에 동의 후 결제를 진행할 수 있습니다.`,
    `Please check the confirmation box.`,
    's_pricing.agreeValidateMessage',
    `同意商品购买的相关退换规则后方可进行付款。`,
    `同意商品購買相關退款規定後可進行付款。`,
    `商品購入に関する返金規定同意後に決済を行うことができます。`,
    `Vui lòng đánh dấu vào ô xác nhận.`,
  ],
  selectPaymentType: [
    `결제 방식을 선택하지 않으셨습니다. 결제 방식을 선택해주세요`,
    `You haven't selected the purchase method. Please select your purchase method.`,
    's_pricing.selectPaymentType',
    `尚未选择付款方式，请选择您希望的付款方式。`,
    `您尚未選擇付款方式，請選擇付款方式。`,
    `決済方法が選択されていません。決済方法を選択してください。`,
    `Bạn chưa chọn phương thức thanh toán. Vui lòng lựa chọn.`,
  ],
  first_benefit: {
    title: [
      <>
        첫 3회 수업권 구매하고, <br />
        <Div textPrimary clx="text-purple-500">
          포인트로 100% 돌려받으세요
        </Div>
      </>,
      <>
        <Div textPrimary clx="text-purple-500">
          100% Payback Offer
        </Div>{' '}
        for First Purchase Benefit
      </>,
      's_pricing.first_benefit.title',
      <>
        前3次购买课程券时, <br />
        <Div textPrimary clx="text-purple-500">
          可获得100%积分返还
        </Div>
      </>,
      <>
        請購買首3次課程券，
        <br />
        <Div textPrimary clx="text-purple-500">
          領回100%的點數。
        </Div>
      </>,
      <>
        初回3回レッスンチケットを購入して、 <br />
        <Div textPrimary clx="text-purple-500">
          100%ポイントバックを受け取りましょう。
        </Div>
      </>,
      <>
        <Div textPrimary clx="text-purple-500">
          100% Ưu đã hoàn lại
        </Div>{' '}
        cho thanh toán đầu tiên
      </>,
    ],
    new_user_pay: (amount) => [
      <>
        <Div clx="h5 mb-0 text-gray-700">첫 구매가</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">New usesr pay</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      's_pricing.first_benefit.new_user_pay',
      <>
        <Div clx="h5 mb-0 text-gray-700">首次购买价格</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">首次購買價</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">初回購入価格</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">Học viên mới thanh toán</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
    ],
    point_payback: (amount) => [
      <>첫 구매시 {amount} 포인트 즉시 지급!</>,
      <>${amount} given right away on your first purchase!</>,
      's_pricing.first_benefit.point_payback',
      <>首次购买时{amount}积分立即支付!</>,
      <>首次購買時立即發送${amount}P！</>,
      <>初回購入時 {amount} ポイントを直ちに付与！</>,
      <>${amount} tặng ngay trong lần thanh toán đầu tiên!</>,
    ],
    purchase_now: [
      `구매하기`,
      `Purchase Now`,
      's_pricing.first_benefit.purchase_now',
      `购买`,
      `購買`,
      `購入する`,
      `Thanh toán ngay`,
    ],
  },
  july_promotion: {
    badge: [
      <>얼리버드</>,
      <>Early Bird</>,
      's_pricing.july_promotion.badge',
      <>早鸟</>,
      <>早鳥</>,
      <>アーリーバード</>,
      <>Sáng sớm</>,
    ],
    sign_up_title: [
      <>
        하반기 프로모션
        <br />
        얼리버드
      </>,
      `2nd Half Promotion Early Bird`,
      's_pricing.july_promotion.sign_up_title',
      <>
        下半期促销活动
        <br />
        早鸟
      </>,
      <>
        下半期宣傳活動
        <br />
        早鳥
      </>,
      <>
        下半期プロモーション
        <br />
        アーリーバード
      </>,
      `Ưu đãi sớm nửa cuối năm`,
    ],
    sign_up_subtitle: [
      <>
        최대 <span className="text-blue-500">50만원</span> 추가 할인
      </>,
      <>
        Additional discount up to <span className="text-blue-500">$431.03</span>
      </>,
      's_pricing.july_promotion.sign_up_subtitle',
      <>
        高达<span className="text-blue-500">$431</span>的追加优惠
      </>,
      <>
        最高<span className="text-blue-500">$431.03</span>額外優惠
      </>,
      <>
        最大 <span className="text-blue-500">$431</span> 追加割引
      </>,
      <>
        Giảm giá thêm lên tới <span className="text-blue-500">$431.03</span>
      </>,
    ],
  },
  ringle_guarantee: [
    `링글이 보장합니다`,
    `Ringle guarantees`,
    's_pricing.ringle_guarantee',
    `Ringle向您承诺`,
    `Ringle向您承諾`,
    `Ringleのお約束`,
    `Ringle đảm bảo`,
  ],
  refund_all: [
    <Div textH3 textGray700>
      첫 수업 불만족 시<br />
      <Div textBlack spanTag fontBold textH2>
        100% 환불
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        100% Money-back guarantee
      </Div>
    </Div>,
    's_pricing.refund_all',
    <Div textH3 textGray700>
      首次课程不满意时
      <br />
      <Div textBlack spanTag fontBold textH2>
        承诺100%退款
      </Div>
    </Div>,
    <Div textH3 textGray700>
      首次課程不滿意時，
      <br />
      <Div textBlack spanTag fontBold textH2>
        可全額退款
      </Div>
    </Div>,
    <Div textH3 textGray700>
      全額返金保証
      <br />
      <Div textBlack spanTag fontBold textH2></Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        Hoàn tiền 100%
      </Div>
    </Div>,
  ],
  not_opened: [
    <Div textH3 textGray700>
      첫 수업 시작 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        수강기간 미차감
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        Start Whenever
      </Div>
    </Div>,
    's_pricing.not_opened',
    <Div textH3 textGray700>
      首次课程开始前
      <br />
      <Div textBlack spanTag fontBold textH2>
        不扣除使用期限
      </Div>
    </Div>,
    <Div textH3 textGray700>
      首次課程開始之前，
      <br />
      <Div textBlack spanTag fontBold textH2>
        不扣除使用期限
      </Div>
    </Div>,
    <Div textH3 textGray700>
      自分のペースで始められる
      <br />
      <Div textBlack spanTag fontBold textH2></Div>
    </Div>,
    <Div textH5 textGray700>
      <Div textBlack spanTag fontBold textH4>
        Bắt đầu bất cứ khi nào
      </Div>
    </Div>,
  ],
  additional_extend: [
    `구매 1년 후 수강기간 자동 차감`,
    `Buy now and start later`,
    's_pricing.additional_extend',
    `可延长使用期限`,
    `可額外延長使用期限`,
    `チケット有効期限は延長可能`,
    `Mua ngay và học sau`,
  ],
  change_before_day: [
    <Div textH3 textGray700>
      수업 하루 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        일정 변경 가능
      </Div>
    </Div>,
    <Div textBlack fontBold textH3>
      Flexible cancellation
    </Div>,
    's_pricing.change_before_day',
    <Div textBlack fontBold textH3>
      截止于课程开始前一天可进行变更
    </Div>,
    <Div textBlack fontBold textH3>
      最晚可於課程開始1天前變更日程
    </Div>,
    <Div textBlack fontBold textH3>
      レッスン前日まで予約変更できる
    </Div>,
    <Div textBlack fontBold textH3>
      Huỷ linh hoạt
    </Div>,
  ],
  provide_makeup_lesson: [
    `수업 시작 기준 2시간 이전까지 변경 시, 당일수업권 제공`,
    <>
      If you cancel 24 to 2 hours before the start of
      <br />
      the lesson, a Last-minute Credit will be issued.
    </>,
    's_pricing.provide_makeup_lesson',
    `截止于课程开始前两小时变更时, 将提供补充课程券`,
    `最晚於課程開始2小時前變更時間時，將提供補充課程券`,
    `開始2時間前までの変更は当日レッスンチケットに交換`,
    `Huỷ tối thiểu 24 giờ trước buổi học`,
  ],
  credit_info: [
    `수업권 선택 방법`,
    `Credits Detail`,
    's_pricing.credit_info',
    `课程券选择方法`,
    `課程券選擇方法`,
    `レッスンチケットの種類`,
    `Chi tiết thẻ học`,
  ],
  duration: [
    `수업 길이`,
    `Lesson duration`,
    's_pricing.duration',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng buổi học`,
  ],
  full_desc: [
    <Div ml16 textGray700 textH5>
      하나의 주제에 대해 심도 있게!
      <br />
      최신 시사부터 비즈니스 영어까지
      <br />
      지적 대화를 레벨업하는 40분 수업
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for advanced discussions
      <br />
    </Div>,
    's_pricing.full_desc',
    <Div ml16 textGray700 textH5>
      对一个主题进行深度探索!
      <br />
      从最新时事到商务英语
      <br />
      知性对话提升实力的40分钟课程
    </Div>,
    <Div ml16 textGray700 textH5>
      深度討論一個主題！
      <br />
      從最新時事至商務英語，
      <br />
      提升知識性對話的40分鐘課程
    </Div>,
    <Div ml16 textGray700 textH5>
      一つのテーマについて深く！
      <br />
      最新時事からビジネス英語まで
      <br />
      高レベルな会話を学びたい方向け
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Buổi thảo luận nâng cao
      <br />
    </Div>,
  ],
  half_desc: [
    <Div ml16 textGray700 textH5>
      바쁜 일상 속, 짧지만 확실하게!
      <br />
      부담스러운 예습 시간은 줄이고
      <br />
      일상 대화 능력을 향상시키고 싶으신 분
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for casual conversations
    </Div>,
    's_pricing.half_desc',
    <Div ml16 textGray700 textH5>
      在忙碌的日常中简短却充实的对话!
      <br />
      缩短让人感到负担的预习时间
      <br />
      希望提升日常对话能力的人群
    </Div>,
    <Div ml16 textGray700 textH5>
      在忙碌的日常中，簡單卻確實！
      <br />
      想縮短感到負擔的預習時間，
      <br />
      提升日常對話能力的人
    </Div>,
    <Div ml16 textGray700 textH5>
      日常会話のレベルアップに！
      <br />
      なるべく短い予習時間で
      <br />
      会話力を上達したい方向け
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Buổi hội thoại thông thường
    </Div>,
  ],
  time_info: [`시간대`, `Times`, 's_pricing.time_info', `时间段`, `時段`, `レッスン受講可能時間帯`, `Thời gian`],
  base: [
    `수업 시작 기준`,
    `Lesson starting time`,
    's_pricing.base',
    `以课程开始为准`,
    `以課程開始時間為準（韓國標準時間）`,
    `レッスン開始時間`,
    `Thời gian bắt đầu học`,
  ],
  all_time: [`정규`, `Regular`, 's_pricing.all_time', `正规`, `正規`, `レギュラー`, `Tiêu chuẩn`],
  early: [`새벽반`, `Early Bird`, 's_pricing.early', `凌晨班`, `早鳥班`, `早朝プラン`, `Sáng sớm`],
  lunch: [`점심반`, `Matinee`, 's_pricing.lunch', `午间班`, `午間班`, `デイタイムプラン`, `Buổi trưa`],
  night_owl: [`심야반`, `Night Owl`, 's_pricing.night_owl', `深夜班`, `深夜班`, `深夜プラン`, `Ban đêm`],
  for_purpose: [
    `수강 목적에 꼭 맞는`,
    `Select the right package for you`,
    's_pricing.for_purpose',
    `请妥善选择符合课程目的的`,
    `請選擇確實符合課程目的的`,
    `受講する`,
    `Chọn gói học phù hợp với bạn`,
  ],
  choose_your_credit: [
    <>&nbsp;수업권을 선택해보세요</>,
    ``,
    's_pricing.choose_your_credit',
    `课程券`,
    `課程券`,
    `チケットを選択してください。`,
    ``,
  ],
  pay_in_pieces: [
    `2개 카드 분할 결제 가능`,
    `Split payment available`,
    's_pricing.pay_in_pieces',
    `可以使用两张信用卡结帐`,
    `可以使用兩張信用卡結帳`,
    `2つのカード分割決済が可能`,
    `Đóng học phí từng phần`,
  ],
  interest_free: [
    `최대 6개월 무이자 할부`,
    `Interest-free for up to 12 monthly installments`,
    's_pricing.interest_free',
    `最高可分12期无利率`,
    `最高可分12期無利率`,
    `最大12ヶ月金利0%の分割払い`,
    `Trả góp không lãi suất lên đến 12 tháng`,
  ],
  certificates: [
    `증명서 발급`,
    `Lesson Certificates & Receipts`,
    's_pricing.certificates',
    `发放证明`,
    `核發證明`,
    `証明書発行`,
    `Chứng nhận buổi học & Biên nhận`,
  ],
  cert_example: [
    `증명서 예시 상세보기`,
    `See sample`,
    's_pricing.cert_example',
    `证明材料范本详细查看`,
    `詳細確認證明範例`,
    `証明書のサンプル`,
    `Xem mẫu`,
  ],
  can_provide_cert: [
    <>
      구매한 수업권 증명서 상시 발급 가능합니다.
      <br />
      수강증, 출석증, 영수증 (국/영문)
    </>,
    `You can issue lesson certificates, proof of attendance, or receipts.`,
    's_pricing.can_provide_cert',
    <>
      购买的课程券证明可随时发送。
      <br />
      上课证明, 出席证明, 收据 (中/英文)
    </>,
    <>
      購買的課程券可隨時核發證明。
      <br />
      上課證明、出席證明、收據（英文）
    </>,
    <>
      受講したレッスンの証明書類はいつでも発行可能です。
      <br />
      受講証、出席証、領収証 (日本語/英語)
    </>,
    `Bạn có thể lấy chứng nhận buổi học, chứng nhận tham gia hoặc biên nhận.`,
  ],
  benefit_detail: [
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+종의 링글&nbsp;
          <Div fontSemibold textBlack>
            오리지널 콘텐츠
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF 파일 / MP3 오디오북&nbsp;
          </Div>
          다운로드 지원
        </Div>
        <Div flex mb4>
          매주 진행되는&nbsp;
          <Div fontSemibold textBlack>
            튜터의 Live Class
          </Div>
        </Div>
        <Div flex>
          커리어, 영어학습 등&nbsp;
          <Div fontSemibold textBlack>
            380+편 VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          수업 24시간 전까지&nbsp;
          <Div fontSemibold textBlack>
            무제한 일정 변경&nbsp;
          </Div>
          가능
        </Div>
        <Div flex mb4>
          2시간 전 수업 취소 시,&nbsp;
          <Div fontSemibold textBlack>
            100% 보충 수업&nbsp;
          </Div>
          제공
        </Div>
        <Div flex mb4>
          모든&nbsp;
          <Div fontSemibold textBlack>
            수업 녹음파일 무제한&nbsp;
          </Div>
          듣기
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            개별 첨삭 수업노트&nbsp;
          </Div>
          (Ringle docs) 별도 제공
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            튜터의 1:1 피드백 리포트&nbsp;
          </Div>
          매 수업 종료 후 제공
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI 기반 대화 스크립트와 말하기 패턴 분석 자료
          </Div>
        </Div>
        <Div flex>
          말하기, 듣기, 읽기, 쓰기&nbsp;
          <Div fontSemibold textBlack>
            영역별 학습 데이터
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+ Ringle's&nbsp;
          <Div fontSemibold textBlack>
            Original Content
          </Div>
        </Div>
        <Div flex mb4>
          Download&nbsp;
          <Div fontSemibold textBlack>
            PDF/MP3 Audiobook&
          </Div>
        </Div>
        <Div flex mb4>
          Weekly top tutor&nbsp;
          <Div fontSemibold textBlack>
            LIVE Webinar
          </Div>
        </Div>
        <Div flex>
          380+&nbsp;
          <Div fontSemibold textBlack>
            Video Courses
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Flexible cancellations&nbsp;
          </Div>
          up to 24hrs before the lesson
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Last-minute lessons&nbsp;
          </Div>
          for late cancellations
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Audio replay & transcript&nbsp;
          </Div>
          for all lessons
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Lesson Notes&nbsp;
          </Div>
          after class
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Tutor's Feedback&nbsp;
          </Div>
          report
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI Analysis&nbsp;
          </Div>
          on speech patterns
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Activities log&nbsp;
          </Div>
          on your Ringling
        </Div>
      </Div>
    </Div>,
    's_pricing.benefit_detail',
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000多种的Ringle&nbsp;
          <Div fontSemibold textBlack>
            原版内容
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF文件 / MP3音频&nbsp;
          </Div>
          可随时下载
        </Div>
        <Div flex mb4>
          每周进行的&nbsp;
          <Div fontSemibold textBlack>
            教师Live Class
          </Div>
        </Div>
        <Div flex>
          职业, 英语学习等&nbsp;
          <Div fontSemibold textBlack>
            380多部VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          截止于课程开始前24小时&nbsp;
          <Div fontSemibold textBlack>
            可无限变更课程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          截止于2小时前取消课程时, &nbsp;
          <Div fontSemibold textBlack>
            100%提供补充课程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          全部&nbsp;
          <Div fontSemibold textBlack>
            课程录音无限量&nbsp;
          </Div>
          收听
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            各课程修改笔记&nbsp;
          </Div>
          (Ringle docs)分别提供
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            教师的1:1Feedback Report&nbsp;
          </Div>
          于课程结束后提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI基础对话脚本及口语模式分析资料
          </Div>
        </Div>
        <Div flex>
          口语, 听力, 阅读, 写作&nbsp;
          <Div fontSemibold textBlack>
            各方面的学习数据
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000多種Ringle的&nbsp;
          <Div fontSemibold textBlack>
            原創內容
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF檔／MP3有聲書&nbsp;
          </Div>
          下載支援
        </Div>
        <Div flex mb4>
          每週進行的&nbsp;
          <Div fontSemibold textBlack>
            任課教師Live Class
          </Div>
        </Div>
        <Div flex>
          職涯、英語學習等&nbsp;
          <Div fontSemibold textBlack>
            380多集VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          最晚可於課程開始24小時前&nbsp;
          <Div fontSemibold textBlack>
            無限變更日程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          2小時前取消課程時，&nbsp;
          <Div fontSemibold textBlack>
            100%提供補課&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          所有&nbsp;
          <Div fontSemibold textBlack>
            課程錄音檔無限&nbsp;
          </Div>
          聆聽
        </Div>
        <Div flex>
          額外提供
          <Div fontSemibold textBlack>
            個別增刪課堂筆記&nbsp;
          </Div>
          （Ringle docs）
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            任課教師的1:1意見回饋報告書&nbsp;
          </Div>
          於每堂課結束後提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            結合AI技術的對話腳本及口說模式分析資料
          </Div>
        </Div>
        <Div flex>
          口說、聽力、閱讀、寫作&nbsp;
          <Div fontSemibold textBlack>
            各領域學習數據
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+種類のRingle&nbsp;
          <Div fontSemibold textBlack>
            オリジナルコンテンツ
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDFファイル / MP3オーディオブ&nbsp;
          </Div>
          ック
        </Div>
        <Div flex mb4>
          毎週行われる&nbsp;
          <Div fontSemibold textBlack>
            チューターのLive Class
          </Div>
        </Div>
        <Div flex>
          キャリアや英語学習など&nbsp;
          <Div fontSemibold textBlack>
            380+本のVOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          レッスン24時間前まで&nbsp;
          <Div fontSemibold textBlack>
            無制限で日程変更&nbsp;
          </Div>
          可能
        </Div>
        <Div flex mb4>
          2時間前にキャンセル時、&nbsp;
          <Div fontSemibold textBlack>
            当日レッスン&nbsp;
          </Div>
          提供
        </Div>
        <Div flex mb4>
          すべての&nbsp;
          <Div fontSemibold textBlack>
            レッスン録音ファイルを無制限で&nbsp;
          </Div>
          聴く
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            個別添削レッスンノート&nbsp;
          </Div>
          (Ringle docs) 別途提供
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Feedback Report&nbsp;
          </Div>
          レッスン終了後に提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            会話スクリプトとスピーキング分析資料
          </Div>
        </Div>
        <Div flex>
          スピーキング、リスニングなどの&nbsp;
          <Div fontSemibold textBlack>
            学習データ
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+&nbsp;
          <Div fontSemibold textBlack>
            Nội dung độc quyền trên Ringle
          </Div>
        </Div>
        <Div flex mb4>
          Tải&nbsp;
          <Div fontSemibold textBlack>
            PDF/sách nói MP3
          </Div>
        </Div>
        <Div flex mb4>
          Top gia sư mỗi tuần&nbsp;
          <Div fontSemibold textBlack>
            Webinar trực tuyến
          </Div>
        </Div>
        <Div flex>
          380+&nbsp;
          <Div fontSemibold textBlack>
            Video buổi học
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Huỷ linh hoạt&nbsp;
          </Div>
          tối thiểu 24 giờ trước buổi học
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Buổi học bù&nbsp;
          </Div>
          khi huỷ sát giờ
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Ghi âm & Bản ghi&nbsp;
          </Div>
          cho tất cả buổi học
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Ghi chú buổi học&nbsp;
          </Div>
          khi kết thúc
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Báo cáo đánh giá&nbsp;
          </Div>
          của gia sư
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Phân tích Ai&nbsp;
          </Div>
          dựa trên đặc điểm nói
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Nhật ký hoạt động&nbsp;
          </Div>
          trên trang của bạn
        </Div>
      </Div>
    </Div>,
  ],
  notice: [`유의사항`, `Terms`, 's_pricing.notice', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
  use_credit: [
    `수업권 사용 안내`,
    `Credits policy`,
    's_pricing.use_credit',
    `课程券使用介绍`,
    `課程券使用說明`,
    `レッスンチケットの使用方法`,
    `Chính sách thẻ học`,
  ],
  free_to_use: [
    `수업권은 구매한 횟수만큼, 수강기간 내 자유롭게 이용 가능합니다.`,
    `You can use your lesson credits before they expire.`,
    's_pricing.free_to_use',
    `课程券按购买次数, 可于使用期限内自由使用。`,
    `如同購買的次數，使用期限內可自由使用課程券。`,
    `購入したレッスンチケットは有効期限内に自由に使うことができます。`,
    `Bạn hãy học hết các buổi học trước khi hết hạn.`,
  ],
  free_to_use_ex: [
    `(예: 수강기간 내, 하루 3회 연속 수업 신청도 가능합니다.)`,
    ``,
    's_pricing.free_to_use_ex',
    `(例如: 使用期限内一天可连续申请3次课程。)`,
    `（例：可於使用期限內一天申請3次課程。）`,
    `(例: 有効期限内であれば、1日3回連続レッスンを予約することも可能です。)`,
    ``,
  ],
  twenty_four_seven: [
    `수업 시간은 연중무휴로 한국 시간 기준 새벽 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
    `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
    's_pricing.twenty_four_seven',
    `课程时间全年无休,  参照韩国时间凌晨5点 ~ 下午2点, 晚上7点 ~ 凌晨3点。`,
    `課程時間為全年無休，可於韓國時間凌晨5點～下午2點、晚上7點～凌晨3點上課。`,
    `レッスンは年中無休、日本時間5時～14時、19時～翌午前3時まで受講可能です。`,
    `Buổi học có sẵn lúc 05:00 - 14:00 / 19:00 - 03:00 (giờ Hàn quốc)  mỗi ngày.`,
  ],
  start_after_first_lesson: [
    `구매 후, 첫 수업 완료 전까지 수강기간은 시작되지 않습니다.`,
    `Expiration dates are calculated from the date you complete your first lesson using the purchased package.`,
    's_pricing.start_after_first_lesson',
    `购买后, 完成首次课程前, 不会开始计算使用期限。`,
    `購買後，完成首次課程前，不會開始計算使用期限。`,
    `有効期限は、購入したチケットパックを使って予約した初回レッスン日から起算されます。`,
    `Ngày hết hạn chỉ bắt đầu tính từ ngày bạn hoàn thành buổi học đã thanh toán đầu tiên.`,
  ],
  start_after_first_lesson_ex: [
    `(예: 구매 후 3개월이 지난 후 첫 수업을 완료하면, 해당 시점부터 수강기간이 시작됩니다.)`,
    ``,
    's_pricing.start_after_first_lesson_ex',
    `（例：购买后3个月，于完成首次课程时开始计算使用期限。）`,
    `（例：購買後3個月，於完成首次課程時開始計算使用期限。）`,
    `(例: チケットパックを購入してから3か月後にレッスンを受講した場合、その初回レッスン日から有効期限が起算されます。)`,
    ``,
  ],
  extend_by_payment: [
    `수강기간은 포인트 사용/소액 결제를 통해 연장할 수 있습니다.`,
    `You can extend credit expiration dates with your points or by paying a small fee.`,
    's_pricing.extend_by_payment',
    `使用期限可通过使用积分/小额付款的方式进行延长。`,
    `使用期限可以使用點數／小額付款延長。`,
    `有効期限はポイント又は少額手数料で延長することができます。`,
    `Bạn có thể gia hạn bằng cách sử dụng điểm hoặc trả một khoản phí nhỏ.`,
  ],
  refund_policy: [
    `수업권 환불 정책`,
    `Refund policy`,
    's_pricing.refund_policy',
    `课程券退款政策`,
    `課程券退款政策`,
    `レッスンチケット返金ポリシー`,
    `Chính sách hoàn tiền`,
  ],
  refund_all_before_open: [
    `구매 후 사용하지 않은 수업권은 전액 환불 가능합니다.`,
    `Unused lesson credits are eligible for refunds.`,
    's_pricing.refund_all_before_open',
    `购买后, 未使用的课程券将享受全额退款。`,
    `購買後未使用的課程券可全額退款。`,
    `未使用のレッスンチケットは全額返金可能です。`,
    `Các Thẻ học chưa sử dụng có thể được hoàn lại.`,
  ],
  refund_after_trial: [
    `체험 수업 포함 첫 수업 이후, 불만족 시 전액 환불 가능합니다.`,
    `We offer 100% money-back guarantee for your first lesson.`,
    's_pricing.refund_after_trial',
    `包含体验课程在内的首次课程结束后,  不满意可全额进行退款。`,
    `包含體驗課程的首次課程開始後，不滿意時可全額退款。`,
    `体験レッスン含む初回レッスンをご利用いただき、ご満足いただけなかった場合は全額返金いたします。`,
    `Cam kết hoàn tiền 100% với buổi học đầu tiên.`,
  ],
  check_policy: [
    `수강 이력이 있는 수업권은, 환불 시 이용약관(13조, 15조)에 의거하여 처리합니다.`,
    `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
    's_pricing.check_policy',
    `已有课程使用记录的课程券, 退款时将按照使用条款中的(13条, 15条)进行处理!`,
    `曾使用過的課程券將依據使用條款第13條、第15條進行退款。`,
    `受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づいて処理されます。`,
    `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
  ],
  bonus: (bonus) => [
    `+${bonus}회 수업`,
    `+${bonus} Lessons`,
    's_pricing.bonus',
    `+${bonus}次课程`,
    `+${bonus}次課程`,
    `+${bonus}回レッスン`,
    `+${bonus} buổi học`,
  ],
  if_finish_all: (date, credit) => [
    `${date}일 내 ${credit}회 완강 시`,
    `After ${credit} lessons in ${date} days`,
    's_pricing.if_finish_all',
    `${date}日内完成${credit}次课程时`,
    `${date}天內聽完${credit}次時`,
    `${date}日以内に全 ${credit}レッスン終了時`,
    `Sau ${credit} buổi học trong ${date} ngày`,
  ],
  free_subsidy: (free) => [
    `+${free}회 당일수업권`,
    `+${free} Last-minute Credits`,
    's_pricing.free_subsidy',
    `+${free}次补充课程`,
    `+${free}次補充課程`,
    `+${free}回分の当日レッスン`,
    `+${free} Buổi học bù`,
  ],
  count: (credit) => [
    `${credit}회 수업권`,
    `${credit} Credits`,
    's_pricing.count',
    `${credit}次`,
    `${credit}次`,
    `${credit}回`,
    `${credit} Buổi học`,
  ],
  min: (duration) => [
    `${duration}분`,
    `${duration} min`,
    's_pricing.min',
    `${duration}分钟`,
    `${duration}分鐘`,
    `${duration}分`,
    `${duration} phút`,
  ],
  day: (day) => [`${day}일`, `${day} days`, 's_pricing.day', `${day}日`, `${day}天`, `${day}日`, `${day} ngày`],
  by_one: [`/회`, `/Credit`, 's_pricing.by_one', `/次`, `/次`, `/回`, `/Buổi học`],
  normal_price: [`정가`, `Regular price`, 's_pricing.normal_price', `定价`, `定價`, `通常価格`, `Học phí tiêu chuẩn`],
  expected_point: [
    `예상 적립 포인트`,
    `Estimated points`,
    's_pricing.expected_point',
    `预计积累积分`,
    `預計累積點數`,
    `獲得予定ポイント`,
    `Điểm ước tính`,
  ],
  without_trial: [
    `체험없이 구매`,
    `Skip trial`,
    's_pricing.without_trial',
    `无体验直接购买`,
    `未先體驗即購買`,
    `体験なしで購入`,
    `Không học thử`,
  ],
  additional_lessons_and_discount: (count, discount) => [
    `${count ? `${count}회 수업 추가` : ''}${discount ? ` + ${discount}만원 할인` : ''}`,
    `${count ? `${count} Free lesson(s)` : ''}${discount ? ` + ${discount} Off` : ''}`,
    's_pricing.additional_lessons_and_discount',
    `${count ? `${count}次追加课程` : ''}${discount ? ` + ${discount}优惠` : ''}`,
    `${count ? `${count}次額外課程` : ''}${discount ? ` + ${discount}折扣` : ''}`,
    `${count ? `${count}回レッスン追加` : ''}${discount ? ` + ${discount}割引` : ''}`,
    `${count ? `${count} Buổi học miễn phí(s)` : ''}${discount ? ` + ${discount} Off` : ''}`,
  ],
  buy: [`구매`, `Purchase`, 's_pricing.buy', `购买`, `購買`, `購入する`, `Thanh toán`],
  including_trial: [
    `체험 수업도 첫 수업에 포함됨`,
    `Includes trial lesson.`,
    's_pricing.including_trial',
    `体验课程也包含在首次课程中`,
    `首次課程也包含體驗課程`,
    `初回レッスン、体験レッスンが対象`,
    `Bao gồm buổi học thử`,
  ],
  time_limit: [
    `시간한정`,
    `Limited Window`,
    's_pricing.time_limit',
    `时间限定`,
    `時間限定`,
    `時間帯限定`,
    `Giờ đặc biệt`,
  ],
  final_price: [
    `프로모션 특가`,
    `Promotional Deal`,
    's_pricing.final_price',
    `活动特价`,
    `活動特價`,
    `キャンペーン特価`,
    `Giá đặc biệt`,
  ],
  final_credit: [
    `최종 수업권 횟수`,
    `Total lessons`,
    's_pricing.final_credit',
    `最终课程券次数`,
    `最終課程券次數`,
    `合計レッスン回数`,
    `Tổng số buổi học`,
  ],
  additional_lesson: [
    `추가 정규수업`,
    `Bonus lessons`,
    's_pricing.additional_lesson',
    `追加正规课程`,
    `額外正規課程`,
    `追加レギュラーレッスン`,
    `Buổi học tặng thêm`,
  ],
  additional_benefit: (duration, name) => [
    `[${duration}분] ${name}회 수업권의 추가 혜택입니다.`,
    `Benefits for [${duration} min] ${name} Lessons Package`,
    's_pricing.additional_benefit',
    `[${duration}分钟] ${name}次, 课程券追加优惠。`,
    `[${duration}分鐘] ${name}次課程券的額外優惠。`,
    `[${duration}分] ${name}回レッスンチケットの追加特典です。`,
    `Ưu đãi cho [${duration} phút] ${name} gói học`,
  ],
  provide_additional_lesson: (challenge_date, count, bonus, day) => [
    <>
      {challenge_date > 0 ? `${challenge_date}일` : `수강기간 `} 이내 {count}회 수업을 모두 사용하면 추가 {bonus}회
      정규수업권이 제공됩니다. [정상 수강기간: {day}일]
    </>,
    <>
      If you complete {count} lessons in {challenge_date > 0 ? `${challenge_date} days` : `credits expire in`}, you'll
      get {bonus} bonus lessons. [Credits expire in {day} days]`
    </>,
    's_pricing.provide_additional_lesson',
    <>
      {challenge_date > 0 ? `${challenge_date}日` : `课程期限`} 以内 {count}次课程全部使用时, 追加提供{bonus}
      次正规课程券。 [正规使用期限: {day}日]
    </>,
    <>
      於{challenge_date > 0 ? `${challenge_date}天` : `使用期限`}內將{count}次課程全數使用時，額外提供{bonus}
      次正規課程券。[正規使用期限：{day}日]
    </>,
    <>
      {challenge_date > 0 ? `${challenge_date}日` : `受講期間 `} 内に {count}回レッスンをすべて使用すると追加で {bonus}
      回レギュラーレッスンチケットが提供されます。 [通常受講期間: {day}日]
    </>,
    <>
      Nếu bạn hoàn thành {count} buổi học trong {challenge_date > 0 ? `${challenge_date} days` : `credits expire in`},
      bạn sẽ nhận {bonus} buổi học tặng thêm. [Thẻ học hết hạn trong {day} ngày]`
    </>,
  ],
  about_additional_lesson: [
    `정규수업권을 모두 사용한 시점에 수업권이 제공되며, 추가 수업권의 수강기간은 1년입니다.`,
    `Issued bonus lessons are valid for 1 year.`,
    's_pricing.about_additional_lesson',
    `正规课程全部使用时将提供额外课程券, 追加课程券的使用期限为1年。`,
    `將所有正規課程全數使用時提供課程券，額外課程券的使用期限為1年。`,
    `レギュラーレッスンがすべて終了した時点でレッスンチケットが提供され、追加レッスンチケットの受講期間は1年です。`,
    `Buổi học tặng thêm có thời hạn trong 1 năm.`,
  ],
  considered_to_be_used: [
    `* 수강생의 수업 불참/2시간 이내 취소도 수업권 [사용]으로 인정됩니다.`,
    `*Student no-show and cancellations in less than 2 hours also count as completing a lesson.`,
    's_pricing.considered_to_be_used',
    `* 学员旷课/课程开始前2小时以内对课程进行取消时, 将被视为[使用]课程券。`,
    `* 學生未出現／2小時以內取消也視同[使用]課程券。`,
    `* 受講生のレッスン欠席/2時間以内のキャンセルもレッスンチケット[使用]と認定されます。`,
    `*Học viên vắng mặt hoặc huỷ trong vòng 2 giờ sẽ được tính là hoàn thành buổi học.`,
  ],
  for_long: [
    `장기 수업권 혜택`,
    `Size Benefits`,
    's_pricing.for_long',
    `长期课程券优惠`,
    `長期課程券優惠`,
    `長期レッスンチケット特典`,
    `Giảm giá`,
  ],
  long_benefit_list_1: [
    <>당일수업권</>,
    `Last-minute Credits`,
    's_pricing.long_benefit_list_1',
    <>补充课程</>,
    <>補充課程</>,
    <>当日レッスン</>,
    `Buổi học bù`,
  ],
  about_lesson_in_a_day: [
    <>
      매 정규수업권 6회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능) 당일수업권은 예약 시점
      기준 24시간 이내에 시작하는 당일수업만 예약할 수 있는 수업권입니다.
    </>,
    `For every six completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
    's_pricing.about_lesson_in_a_day',
    <>
      每使用6张正規课程卷，提供1张当日课程卷（使用期限1年）。
      <br />- 当日课程卷：可预约24小时以内开始的课程
    </>,
    <>
      目前於24小時以內開始的課程，只可選擇Free Talking教材。耗盡6次正規課程時，將發送1次。（使用期限：自發送日起1年）
    </>,
    <>
      24時間以内のレッスンのみ受講できるチケットです。48回チケットを購入した場合、レギュラーレッスンを6回受講するごとに、1回分の当日チケットが発行されます。(有効期限：発行から1年間)
    </>,
    <>
      Sau khi hoàn thành 6 tiết học bạn sẽ nhận được một Tín chỉ cuối giờ. (Thời hạn 1 năm) Bạn có thể sử dụng Tín chỉ
      cuối giờ cho các lớp học bắt đầu trong vòng 24 giờ kể từ thời điểm đặt lớp.
    </>,
  ],
  long_benefit_list_2: [
    `튜터 우선 예약`,
    `Early Booking Access`,
    's_pricing.long_benefit_list_2',
    `教师优先选定`,
    `優先指定任課教師`,
    `チューター優先予約`,
    `Gia sư ưu tiên`,
  ],
  schedule_first_benefit: [
    `튜터의 1주일 뒤 스케쥴을 24시간 동안 우선 예약할 수 있는 권한을 드립니다.`,
    `You'll have 24-hour priority access to your favorite tutor's schedule on a weekly basis.`,
    's_pricing.schedule_first_benefit',
    `您可于24小时内优先预约您满意的教师一周后的日程。(可通过Ringle应用程序提醒进行确认)`,
    `24小時期間提供您可優先預約喜歡的任課教師一週後日程的權限。（可透過Ringle APP通知確認）`,
    `通常より24時間早く好きなチューターの翌週のスケジュールにアクセス・予約ができます。(Ringleアプリ内で通知あり)`,
    `Bạn được ưu tiên truy cập thời khoá biểu hằng tuần của gia sư yêu thích trong vòng 24 giờ.`,
  ],
  long_benefit_list_3: [
    `80단어 답변 첨삭`,
    `Response proofreading`,
    's_pricing.long_benefit_list_3',
    `80单词回答修改`,
    `80個單字的增刪回覆`,
    `回答添削(80単語まで)`,
    `Hiệu đính câu trả lời`,
  ],
  editing_service: [
    <>
      튜터가 매 수업 80단어를 수업 전 첨삭해 드립니다. 수업 24시간 전까지 예습 페이지에서 신청 가능합니다. [예습] {'>'}{' '}
      [답변] {'>'} [첨삭 신청]
    </>,
    `Have your tutor proofread your response up to 80 words. You can submit requests up to 24 hours before the lesson at Lesson Prep.`,
    's_pricing.editing_service',
    <>
      教师可提供每次课程前80个单词的内容修改。 截止于课程开始前24小时可在预习页面中进行申请。[预习] {'>'} [回答] {'>'}{' '}
      [申请修改]
    </>,
    <>
      任課教師於每堂課於課程開始前為您增刪80個單字。 最晚可於課程開始24小時前於預習頁面申請。[預習] {'>'} [回覆] {'>'}{' '}
      [申請增刪]
    </>,
    <>
      チューターが毎レッスン80単語まで添削します。 添削リクエストはレッスン24時間前まで予習ページからできます。[予習]{' '}
      {'>'} [回答] {'>'} [添削リクエスト]
    </>,
    `Gia sư của bạn đã hiệu đính câu trả lời dưới 80 từ của bạn chưa? Bạn có thể gửi yêu cầu tối thiểu 24 giờ trước khi buổi học bắt đầu ở phần Chuẩn bị buổi học.`,
  ],
  input_company_code: [
    `기업 맞춤 수업권을 확인해 보세요.`,
    `Please enter B2B Partner code.`,
    's_pricing.input_company_code',
    `请输入企业优惠代码`,
    `請輸入企業優惠代碼`,
    `企業専用コードを入力してください。`,
    `Please enter B2B Partner code.`,
  ],
  company_code: [
    `기업 맞춤 코드를 입력해 주세요.`,
    `B2B Partner Code`,
    's_pricing.company_code',
    `企业优惠代码`,
    `企業優惠代碼`,
    `企業専用コード`,
    `Mã code đối tác B2B`,
  ],
  channel_talk: [
    `할인 코드 문의하기`,
    `Contact Us`,
    's_pricing.channel_talk',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi`,
  ],
  additional_discount: [
    `추가할인`,
    `Bonus Discount`,
    's_pricing.additional_discount',
    `追加优惠`,
    `額外折扣`,
    `追加割引`,
    `Ưu đãi bổ sung`,
  ],
  recommended_product: [
    `추천 수업권`,
    `Popular`,
    's_pricing.recommended_product',
    `推荐的课程券`,
    `推薦的課程券`,
    `Popular`,
    `Phổ biến`,
  ],
  applied_price: [
    `첫구매 혜택이 반영된 가격입니다.`,
    `First purchase benefits applied`,
    's_pricing.applied_price',
    `适用第一次购买优惠`,
    `適用第一次購買優惠`,
    `初購入の特典が反映された価格です。`,
    `Lợi ích mua hàng đầu tiên được áp dụng`,
  ],
  normal_customer: [
    `일반 수업권`,
    `Individuals`,
    's_pricing.normal_customer',
    `一般课程券`,
    `一般課程券`,
    `一般レッスンチケット`,
    `Cá nhân`,
  ],
  b2b_price: [
    `기업 맞춤 수업권`,
    `B2B`,
    's_pricing.b2b_price',
    `企业专属课程券`,
    `企業專屬課程券`,
    `法人向けレッスンチケット`,
    `B2B`,
  ],
  alltime_time: [
    `수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
    `Lesson time: 5AM - 2PM / 7PM - 3AM (KST)`,
    's_pricing.alltime_time',
    `课程时间: 凌晨5点~下午2点 / 晚上19点~凌晨3点(KST)`,
    `課程時間: 凌晨5點～下午2點／晚上19點～凌晨3點`,
    `時間: 5:00 ~ 14:00 / 19:00 ~ 翌3時 (JST)`,
    `Buổi học: 05:00 - 14:00 / 19:00 - 03:00 (Giờ Hàn quốc)`,
  ],
  early_time: [
    `오전 5시~ 7시(KST)`,
    `5AM - 7AM (KST)`,
    's_pricing.early_time',
    `凌晨5点~7点(KST)`,
    `上午5點～7點`,
    `5:00~7:00 (JST)`,
    `05:00 - 07:00 (Giờ Hàn quốc)`,
  ],
  lunch_time: [
    `오전 11시 ~ 오후 1시(KST)`,
    `11AM - 1PM (KST)`,
    's_pricing.lunch_time',
    `上午11点~下午1点(KST)`,
    `上午11點～下午1點`,
    `11:00 ~ 13:00 (JST)`,
    `11:00 - 13:00 (Giờ Hàn quốc)`,
  ],
  night_time: [
    `자정 00시 ~ 새벽 2시(KST)`,
    `12AM - 2AM (KST)`,
    's_pricing.night_time',
    `午夜00点~凌晨2点(KST)`,
    `午夜00點～凌晨2點`,
    `深夜0時 ~ 2:00 (JST)`,
    `00:00 - 02:00 (Giờ Hàn quốc)`,
  ],
  certi: [`증명서류`, `Certificate`, 's_pricing.certi', `证明材料`, `證明`, `証明書類`, `Chứng nhận`],
  certi_example_desc: [
    `수강증 분할 발급 횟수가 n회 남았어요.`,
    `Sample certificate. Can be issued monthly.`,
    's_pricing.certi_example_desc',
    `Ringle证明材料范本。 1个月以上的课程券可每月分期发放。`,
    `Ringle的證明範例。1個月以上的課程券可按月核發。`,
    `Ringleの証明書類の例です。1か月以上の受講チケットの場合、毎月分割発行が可能です。`,
    `Mẫu chứng nhận. Có thể phát hành mỗi tháng.`,
  ],
  attendance_ex: [
    `- 출석증 증명서류 예시 -`,
    `- Sample Proof of Attendance -`,
    's_pricing.attendance_ex',
    `- 出席证范本 -`,
    `- 出席證明範例 -`,
    `- 出席証の証明書類の例 -`,
    `- Mẫu chứng nhận tham gia -`,
  ],
  lesson_certi_ex: [
    `- 수강증 증명서류 예시 -`,
    `- Sample Lesson Certificate -`,
    's_pricing.lesson_certi_ex',
    `- 上课证明范例  -`,
    `- 上課證明範例 -`,
    `- 受講証の証明書類の例 -`,
    `- Mẫu chứng nhận buổi học -`,
  ],
  what_is_additional_lesson: [
    `당일수업이란?`,
    `What are [Last-minute Lessons]?`,
    's_pricing.what_is_additional_lesson',
    `何为补充课程?`,
    `補充課程意指？`,
    `当日レッスンについて`,
    `Buổi Học bù là gì?`,
  ],
  free_subsidy_description: [
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          당일수업이란?
          <br />
          - 예약 시점 기준 24시간 이내에 시작하는 수업
          <br />- &#91;1:1 수업&#93; - &#91;예약&#93; - &#91;당일수업&#93;에서 수업 예약 가능{' '}
        </Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          What are [Last-minute lessons]?
          <br />
          - Lessons starting within 24 hours in real-time.
          <br />
          - For every four completed lessons, one Last-minute Credit will be given.
          <br />- Can be reserved at [1:1 Lessons] - [Reservation] - [Last-minute lessons]
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>What counts as used lesson credits?</Div>
        <Div textGray700>
          - Completed lessons
          <br />
          -Caceled lessons
          <br />- No-show lessons all count as using lesson credits
        </Div>
      </Div>
    </>,
    's_pricing.free_subsidy_description',
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          何为补充课程?
          <br />
          - 当前于24小时以内开始的课程
          <br />- 使用期限为发行之日起1年
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>扣除正规课程券时机?</Div>
        <Div textGray700>- 课程正常进行 - 课程旷课 - 课程开始前24小时以内取消课程</Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          補充課程意指?
          <br />
          - 目前於24小時以內開始的課程
          <br />- 使用期限自發送日起1年
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>耗盡正規課程意指？</Div>
        <Div textGray700>- 正常進行課程 - 上課未出現 - 於課程開始前24小時以內取消</Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          当日レッスンとは？
          <br />
          - リアルタイムで24時間以内に受講できるレッスンです。
          <br />- 有効期限は発行から1年間です。
        </Div>
      </Div>
      <Div mt16></Div>
    </>,
    <>
      {' '}
      <Div>
        <Div Body2-14Md textGray700>
          Thẻ Học bù
          <br />
          - Buổi học sẽ bắt đầu trong vòng 24 giờ.
          <br />- Thời hạn hiệu lực là một năm.
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>Những buổi nào được tính là Thẻ học đã sử dụng?</Div>
        <Div textGray700>
          - Buổi học đã hoàn thành - Buổi học đã huỷ - Buổi học vắng mặt cũng được coi là đã sử dụng thẻ học
        </Div>
      </Div>
    </>,
  ],
  refund_fee: [
    `환불시 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료는 차감하고 환불될 수 있습니다.`,
    `In the case of refunds, any transaction fees incurred will be deducted from the refund amount.`,
    's_pricing.refund_fee',
    `退款时，可能会扣除退还银行、信用卡公司、Paypal等支付方式的手续费。`,
    `退款時，可能會扣除退還銀行，信用卡公司，Paypal等支付方式的手續費.`,
    `返金時は銀行やカード会社、Paypalなどの手数料を差し引かれた額が返金されます。`,
    `Với trường hợp hoàn tiền, phí giao dịch sẽ bị trừ vào khoản tiền được hoàn trả.`,
  ],
  regular_price: (price, number) => [
    `(1회당 ${price} X ${number}회)`,
    `(${number} per Credit X ${number} lessons)`,
    's_pricing.regular_price',
    `(1次 ${price} X ${number}次)`,
    `(1次 ${price} X ${number}次)`,
    `(1回当たり${price}X ${number}回)`,
    `(Giá tiêu chuẩn ${price} X ${number} buổi học)`,
  ],
  first_purchase_benefit: [
    `첫 구매 혜택`,
    `First Purchase`,
    's_pricing.first_purchase_benefit',
    `首次购买优惠`,
    `首次購買折扣優惠`,
    `初回購入割引特典`,
    `Giảm giá cho học viên mới`,
  ],
  buy_btn: [`구매하기`, `Purchase`, 's_pricing.buy_btn', `购买`, `購買`, `購入する`, `Thanh toán ngay`],
  switch_to_won: [
    `원화로 바꾸기`,
    `Convert to KRW`,
    's_pricing.switch_to_won',
    `转换为韩元`,
    `轉換為韓元`,
    `韓国のウォン貨に通貨変更する`,
    `Chuyển qua tiền Hàn Quốc`,
  ],
  switch_to_other: [
    `현지 통화로 바꾸기`,
    `Convert to Local Currency`,
    's_pricing.switch_to_other',
    `转换为当地货币`,
    `轉換為當地貨幣`,
    `現地の通貨に変更する`,
    `Chuyển qua tiền địa phương`,
  ],
  buy_credit: [`수업권 구매`, `Purchase`, 's_pricing.buy_credit', `购买`, `購買`, `購入する`, `Thanh toán`],
  tab: {
    purchase: [`수업권`, `Purchase`, 's_pricing.tab.purchase', `购买`, `購買`, `購入する`, `Thanh toán`],
    method: [
      `선택방법`,
      `Credits Detail`,
      's_pricing.tab.method',
      `课程券选择方法`,
      `課程券選擇方法`,
      `レッスンチケットの種類`,
      `Chi tiết thẻ học`,
    ],
    guarantee: [
      `링글 보장제도`,
      `Guarantee Policy`,
      's_pricing.tab.guarantee',
      `保障制度&优惠`,
      `保障制度&優惠`,
      `保障制度＆特典`,
      `Chính sách đảm bảo`,
    ],
    benefit: [`수강 혜택`, `Benefits`, 's_pricing.tab.benefit', `课程优惠`, `課程優惠`, `受講特典`, `Ưu đãi`],
    certificate: [
      `증명 서류`,
      `Certificate`,
      's_pricing.tab.certificate',
      `证明材料`,
      `證明`,
      `証明書類`,
      `Chứng nhận`,
    ],
    precaution: [`FAQ`, `FAQ`, 's_pricing.tab.precaution', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
    guarantee_and_benefit: [
      `보장제도 & 혜택`,
      `Guarantee Policy`,
      's_pricing.tab.guarantee_and_benefit',
      `保障制度&优惠`,
      `保障制度&優惠`,
      `保障制度＆特典`,
      `Chính sách đảm bảo`,
    ],
  },
  credit_info_description: [
    `수업 진행 방식과 콘텐츠는 동일합니다`,
    `Lesson format and available materials are the same`,
    's_pricing.credit_info_description',
    `课程进行方式和使用的教材相同`,
    `課程進行方式和使用的教材相同`,
    `レッスンの進行方式と教材は同じ`,
    `Hình thức học và tài liệu đều giống nhau`,
  ],
  guarantee: {
    desc1: [
      `첫 구매 혜택도 첫 수업에 포함됨`,
      `Bonus or free lessons may count as a first lesson`,
      's_pricing.guarantee.desc1',
      `使用赠送课程劵申请的首次课程也算作首次课程`,
      `使用贈送課程券申請的首次課程也算作首次課程`,
      `初回購入も初回レッスンに含まれる`,
      `Buổi học thêm hoặc miễn phí có thể được tính như buổi học đầu tiên.`,
    ],
  },
  benefit_list_1: {
    title1: [
      `오리지널 콘텐츠`,
      `Original Content`,
      's_pricing.benefit_list_1.title1',
      `独家原创教材`,
      `獨家原創教材`,
      `オリジナルコンテンツ`,
      `Nội dung độc quyền`,
    ],
    desc1: [
      `1,000+종의 링글`,
      `1,000+ Ringle's`,
      's_pricing.benefit_list_1.desc1',
      `1,000多篇学习材料`,
      `1,000多篇學習材料`,
      `1,000種類以上の教材`,
      `1,000+ nội dung từ Ringle`,
    ],
    title2: [
      `튜터의 Live Class`,
      `LIVE Webinar`,
      's_pricing.benefit_list_1.title2',
      `教师直播研讨会`,
      `教師直播研討會`,
      `チューターのLive Class`,
      `Webinar trực tuyến`,
    ],
    desc2: [
      `매주 진행되는`,
      `Weekly top tutor`,
      's_pricing.benefit_list_1.desc2',
      `每周进行的`,
      `每周進行的`,
      `毎週行われる`,
      `Các gia sư hàng đầu mỗi tuần`,
    ],
    title3: [
      `380+편 VOD`,
      `380+ Video Courses`,
      's_pricing.benefit_list_1.title3',
      `380多部教学视频`,
      `380多部教學視頻`,
      `380+編の VOD`,
      `380+ video khóa học`,
    ],
    desc3: [
      `커리어, 영어학습 등`,
      `Career & other topical`,
      's_pricing.benefit_list_1.desc3',
      `职场，英语学习等`,
      `職場，英語學習等`,
      `キャリア、英語学習など`,
      `Sự nghiệp & các chủ đề khác`,
    ],
    caption1: [
      `PDF 파일 / MP3 오디오북 다운로드 지원`,
      `PDF/MP3 Audiobook provided`,
      's_pricing.benefit_list_1.caption1',
      `提供PDF/MP3有声读物`,
      `提供PDF/MP3有聲讀物`,
      `PDF/MP3オーディオブック提供`,
      `PDF/MP3 Audiobook được cung cấp`,
    ],
    title: [
      `매일 업데이트 되는 최신 콘텐츠`,
      `New learning content updated daily`,
      's_pricing.benefit_list_1.title',
      `每日更新的最新内容`,
      `每日更新的最新内容`,
      `毎日更新される最新のコンテンツ`,
      `Tài liệu học cập nhật mới mỗi ngày`,
    ],
  },
  benefit_list_2: {
    title1: [
      `말하기 패턴 분석 자료`,
      `Speech pattern analysis`,
      's_pricing.benefit_list_2.title1',
      `口语分析材料`,
      `口語分析材料`,
      `話すパターンの分析材料`,
      `Phân tích mẫu nói`,
    ],
    desc1: [
      `AI 기반 분석 시스템`,
      `AI Review system's`,
      's_pricing.benefit_list_2.desc1',
      `人工智能分析系统`,
      `人工智能分析系統`,
      `AI基盤の分析システム`,
      `Hệ thống đánh giá`,
    ],
    title2: [
      `개별 첨삭 수업노트`,
      `Real-time lesson notes`,
      's_pricing.benefit_list_2.title2',
      `课堂修改笔记`,
      `課堂修改筆記`,
      `個別の添削レッスンノート`,
      `Ghi chú trực tiếp trong buổi học`,
    ],
    desc2: [
      `별도 제공`,
      `See after lesson`,
      's_pricing.benefit_list_2.desc2',
      `附加提供`,
      `附加提供`,
      `別途提供`,
      `Xem sau buổi học`,
    ],
    title3: [
      `튜터의 1:1 피드백 리포트`,
      `Tutor's Feedback report`,
      's_pricing.benefit_list_2.title3',
      `1:1教师反馈报告`,
      `1:1教師反饋報告`,
      `チューターの1:1フィードバックレポート`,
      `Đánh giá từ gia sư`,
    ],
    desc3: [
      `매 수업 종료 후 제공`,
      `Thorough and personalized`,
      's_pricing.benefit_list_2.desc3',
      `课程结束后提供`,
      `課程結束后提供`,
      `毎回レッスン終了後に提供`,
      `Toàn diện và cá nhân hóa`,
    ],
    caption1: [
      `모든 수업 녹음파일 무제한 듣기`,
      `Exclusive Lesson Materials`,
      's_pricing.benefit_list_2.caption1',
      `贴合您现实生活的教材`,
      `符合您的現實生活的教材`,
      `あなたのニーズに合わせた教材`,
      `Tài liệu độc quyền cho buổi học`,
    ],
    caption2: [
      `말하기, 듣기, 읽기, 쓰기 영역별 학습 데이터`,
      `Detailed feedback on vocabulary, fluency, grammar, and pronunciation`,
      's_pricing.benefit_list_2.caption2',
      `对词汇, 语法, 发音及流畅性等各方面进行分别评价`,
      `診斷詞彙、文法、發音及流暢性等各領域`,
      `語彙、文法、発音、流暢性など分野別診断`,
      `Đánh giá chi tiết về phát âm, ngữ pháp, từ vựng và mức độ trôi chảy`,
    ],
    title: [
      `AI가 기록하는 복습 시스템`,
      `AI-supported review system`,
      's_pricing.benefit_list_2.title',
      `人工智能复习系统`,
      `人工智能復習系統`,
      `AIが記録する復習システム`,
      `Hệ thống đánh giá bằng AI`,
    ],
  },
  btn: {
    detail: [`상세보기`, `See details`, 's_pricing.btn.detail', `查看详情`, `詳細內容`, `詳しく見る`, `Xem chi tiết`],
  },
  additional_lessons: (count) => [
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}회
          </Div>{' '}
          수업 추가
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>{' '}
          Free lesson(s)
        </>
      ) : (
        ''
      )}
    </>,
    's_pricing.additional_lessons',
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          次追加课程
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          次額外課程
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          回レッスン追加
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>{' '}
          Buổi học miễn phí(s)
        </>
      ) : (
        ''
      )}
    </>,
  ],
  additional_discounts: (discount) => [
    <>{discount ? `${discount}만원 할인` : ''}</>,
    <>{discount ? `${discount} Off` : ''}</>,
    's_pricing.additional_discounts',
    <>{discount ? `${discount}优惠` : ''}</>,
    <>{discount ? `${discount}折扣` : ''}</>,
    <>{discount ? `${discount}割引` : ''}</>,
    <>{discount ? `${discount} Off` : ''}</>,
  ],
  long_package: {
    benefit1: (count) => [
      <>{count ? `${count}회 수업 추가` : ''}</>,
      <>{count ? `${count} Free lesson(s)` : ''}</>,
      's_pricing.long_package.benefit1',
      <>{count ? `${count}次追加课程` : ''}</>,
      <>{count ? `${count}次額外課程` : ''}</>,
      <>{count ? `${count}回レッスン追加` : ''}</>,
      <>{count ? `${count} Buổi học miễn phí(s)` : ''}</>,
    ],
    benefit2: (discount) => [
      <>{discount ? `${discount}만원 할인` : ''}</>,
      <>{discount ? `${discount} Off` : ''}</>,
      's_pricing.long_package.benefit2',
      <>{discount ? `${discount}优惠` : ''}</>,
      <>{discount ? `${discount}折扣` : ''}</>,
      <>{discount ? `${discount}割引` : ''}</>,
      <>{discount ? `${discount} Off` : ''}</>,
    ],
    free_subsidy: (free) => [
      `${free}회 당일수업권`,
      `${free} Last-minute Credits`,
      's_pricing.long_package.free_subsidy',
      `${free}次补充课程`,
      `${free}次補充課程`,
      `${free}回分の当日レッスン`,
      `${free} Buổi học bù`,
    ],
    additional: [
      `장기권 추가 혜택`,
      `Size Benefit`,
      's_pricing.long_package.additional',
      `长期课程券优惠`,
      `長期課程券優惠`,
      `長期レッスンチケット特典`,
      `Gói học Ưu đãi`,
    ],
    benefit: [`혜택`, `Benefits`, 's_pricing.long_package.benefit', `课程优惠`, `課程優惠`, `受講特典`, `Ưu đãi`],
    benefit_1: {
      desc: [
        <>
          매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능)
          <br />
          당일수업권은 예약 시점 기준 24시간 이내에 시작하는 [당일수업]을 예약할 수 있습니다.
        </>,
        `For every four completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
        's_pricing.long_package.benefit_1.desc',
        <>
          是指将于24小时以内开始的课程。但只能选择Free Talking教材, 正规课程进行4次时将提供1次。(使用期限: 发行后1年)
        </>,
        <>
          目前於24小時以內開始的課程，只可選擇Free
          Talking教材。耗盡4次正規課程時，將發送1次。（使用期限：自發送日起1年）
        </>,
        <>
          リアルタイムで24時間以内に受講できるレッスンです。Free
          Talking教材のみ選択可能で、レギュラーレッスンを4回使用すると当日レッスン1回分のチケットが発行されます。(有効期限:
          発行から1年間)
        </>,
        <>
          Buổi học bù sẽ diễn ra trong vòng 24 giờ. Chỉ có buổi học về Chủ đề Tự do hoặc Tập trung luyện nói. Cứ sáu
          buổi học hoàn thành, bạn được tặng một buổi học Bù. (có giá trị 1 năm)
        </>,
      ],
    },
  },
  actual_payment_unit_price_desc: [
    `정규수업권과 당일수업권을 포함한 총 수업 횟수를 합산하여 최종 결제 금액으로 나눈 단가입니다.`,
    `Final price divided by total credits (includes Last-minute Credit(s))`,
    's_pricing.actual_payment_unit_price_desc',
    `最终购买价格除以全部课程次数后的单价。（包含24小时内的补充课程）`,
    `最終購買價格除以全部課程次數後的單價。（包含24小時内的補充課程）`,
    <>
      最終決済の金額を提供される総レッスンの回数で
      <br />
      分割した単価です。（24時間以内の補充レッスンを含む）
    </>,
    `Giá cuối cùng được chia theo tổng số thẻ học (bao gồm cả thẻ học bù)`,
  ],
  expected_point_desc: [
    `최종 결제 금액의 3%가 적립됩니다. (매 수업 후 7일 후 적립)`,
    `3% of paid amount will be awarded as points (within 7 days of purchase)`,
    's_pricing.expected_point_desc',
    `累计积分为最终购买价格的3%。（每次课程结束7天后累计）`,
    `纍計點數為最終購買價格的3%。（每次課程結束7天后纍計）`,
    `実際に決済した全体のレッスンチケットの金額、3％のポイントを差し上げます。（毎回レッスン終了7日後に提供）`,
    `3% học phí đã thanh toán sẽ được thưởng dưới dạng điểm (sau 7 ngày thanh toán)`,
  ],
  not_opened_1: [
    `첫 수업 시작 전까지`,
    `Lesson days are frozen until first lesson`,
    's_pricing.not_opened_1',
    `首次课程开始前`,
    `首次課程開始之前，`,
    `自分のペースで始められる`,
    ``,
  ],
  not_opened_2: [
    `수강기간 미차감`,
    ``,
    's_pricing.not_opened_2',
    `不扣除使用期限`,
    `不扣除使用期限`,
    ``,
    `Bắt đầu bất cứ khi nào`,
  ],
  refund_all_1: [
    `첫 수업 불만족 시`,
    <>
      100% Money-back
      <br />
      guarantee
    </>,
    's_pricing.refund_all_1',
    `首次课程不满意时`,
    `首次課程不滿意時，`,
    `全額返金保証`,
    ``,
  ],
  refund_all_2: [`100% 환불`, ``, 's_pricing.refund_all_2', `承诺100%退款`, `可全額退款`, ``, `Hoàn tiền 100%`],
  change_before_day_1: [
    `수업 하루 전까지`,
    <>
      Change schedule up to 24 hours
      <br />
      before the lesson
    </>,
    's_pricing.change_before_day_1',
    `截止于课程开始前一天可进行变更`,
    `最晚可於課程開始1天前變更日程`,
    `レッスン前日まで予約変更できる`,
    `Huỷ linh hoạt`,
  ],
  change_before_day_2: [`일정 변경 가능`, ``, 's_pricing.change_before_day_2', ``, ``, ``, ``],
  hero: {
    benefit1: [
      `링글 첫 구매 수강생에게 드리는 혜택`,
      `First Purchase Benefits for New Ringlers`,
      's_pricing.hero.benefit1',
      `首次购买优惠`,
      `首次購買優惠`,
      `Ringle初回購入の受講生に与える特典`,
      `Ưu đãi đặc biệt cho người dùng mới`,
    ],
    benefit2: (point) => [
      `최대 3회 수업 추가 & 1만원 할인`,
      <>
        Up to 3 extra credits{isMobile && <br />} & {point} off
      </>,
      's_pricing.hero.benefit2',
      <>
        最多赠送３次课程{isMobile && <br />}&优惠{point}
      </>,
      <>
        最多贈送３次課程{isMobile && <br />}&優惠{point}
      </>,
      <>
        最大3回レッスン追加{isMobile && <br />}&{point}割引
      </>,
      <>
        Ưu đãi tới {point}
        {isMobile && <br />} & 3 buổi học miễn phí
      </>,
    ],
    benefit3: [
      <>
        첫 1회 수업
        <br />
        불만족 시, 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      's_pricing.hero.benefit3',
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
    ],
    benefit4: [
      <>
        첫 수업 시작 전<br />
        수강기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.hero.benefit4',
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
    ],
    benefit5: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      's_pricing.hero.benefit5',
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
    ],
    benefit6: [
      <>
        매 수업 완료 시<br />
        링글 포인트 적립
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      's_pricing.hero.benefit6',
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
    ],
    benefit7: [
      <>
        검증된 퀄리티의 수업을
        <br />
        합리적인 가격으로
      </>,
      <>
        Verified premium lessons
        <br />
        at a reasonable price
      </>,
      's_pricing.hero.benefit7',
      <>
        以合理的价格
        <br />
        获得高质量的课程
      </>,
      <>
        以合理的價格
        <br />
        獲得高質量的課程
      </>,
      <>
        検証されたクオリティのレッスンを <br />
        合理的な価格で
      </>,
      <>
        Buổi học chất lượng
        <br />
        Tương xứng số tiền bỏ ra
      </>,
    ],
    benefit8: [
      `2022년 영어는 제대로 시작하세요!`,
      `Learn English the right way in 2022!`,
      's_pricing.hero.benefit8',
      `2022年开启完美英语学习之旅吧！`,
      `2022年開啓完美英語學習之旅吧！`,
      `2022年は英語を正式に始めてみませんか？`,
      `Bắt đầu 2022 thật khác biệt!`,
    ],
    benefit9: (point) => [
      <>
        친구초대 성공 시 <b>{point}포인트 제공</b>
      </>,
      <>Invite your friends and earn {point} </>,
      's_pricing.hero.benefit9',
      <>邀请朋友成功时可获得 {point} 积分</>,
      <>邀請朋友成功時可獲得{point}點數</>,
      <>友達紹介に成功した場合、{point}提供</>,
      <>Mời bạn bè và nhận {point} điểm thưởng</>,
    ],
    benefit10: [
      <>
        수업권 구매 후 <b>원하는 시점 시작 가능</b>
      </>,
      `Buy now and start later`,
      's_pricing.hero.benefit10',
      `购买课程劵后自由选择首次课程开始时间`,
      `購買課程券后自由選擇首次課程開始時間`,
      `レッスンチケットを購入後、好きなタイミングで始められます。`,
      `Thời gian học tính từ buổi học đầu tiên`,
    ],
    benefit11: [
      <>
        수업이 끝나면 <b>3% 포인트 적립</b>
      </>,
      `Get 3% points of the purchased price after each lesson`,
      's_pricing.hero.benefit11',
      `每次课程结束后累计３％积分`,
      `每次課程結束后纍計3%點數`,
      `レッスンが終わったら3％ポイントゲット`,
      `3% Điểm thưởng sau khi hoàn thành buổi học`,
    ],
    title: [
      `오직 한 달간 진행되는 최대 할인`,
      `Maximum Discount for One Month`,
      's_pricing.hero.title',
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
    ],
    subtitle1: [
      <>
        할인부터 영어 공부법까지 챙기는{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          링글 하반기 프로모션
        </Div>
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      's_pricing.hero.subtitle1',
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
    ],
    date: [
      `22.06.07 (Tue) - 07.07 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      's_pricing.hero.date',
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
    ],
    date_mobile: [
      `~07.07 (목)`,
      `~July 07, 2022 (Thu)`,
      's_pricing.hero.date_mobile',
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
    ],
    top: [
      `1년에 단 2번`,
      `Only Twice a Year`,
      's_pricing.hero.top',
      `Only Twice a Year`,
      `Only Twice a Year`,
      `Only Twice a Year`,
      `Only Twice a Year`,
    ],
    label_new1: [
      `오직 단 한번!`,
      `One-time`,
      's_pricing.hero.label_new1',
      `One-time`,
      `One-time`,
      `One-time`,
      `One-time`,
    ],
    label_new2: [
      `신규회원 혜택`,
      `Newcomer Event`,
      's_pricing.hero.label_new2',
      `Newcomer Event`,
      `Newcomer Event`,
      `Newcomer Event`,
      `Newcomer Event`,
    ],
    before_login: [
      <>
        지금 링글 가입하면
        <br />
        <span className="text-[#FF6712]">최대 15만원 </span> 즉시 할인!
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$ 169 off!</span>
      </>,
      's_pricing.hero.before_login',
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$ 169 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$ 169 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$ 169 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$ 169 off!</span>
      </>,
    ],
    welcome: [
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          <span className="text-[#503CC8]">최대 15만원 </span>할인 부터
          <br />
          무료 수업권 <span className="text-[#503CC8] mx-[4px]">최대 3회</span>까지.
        </div>
        <div className="text-[12px] sm:text-[15px] text-purple-700 font-medium text-opacity-70">
          *체험 수업 없이 구매 시
        </div>
      </>,
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          Get up to <span className="text-[#503CC8]">$115 extra off!</span>
          <br />& <span className="text-[#503CC8]">3 Bonus Credits</span>
        </div>
        <div className="text-[15px] text-purple-700 font-medium text-opacity-70">
          *when you purchase without a trial
        </div>
      </>,
      's_pricing.hero.welcome',
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          Get up to <span className="text-[#503CC8]">$115 extra off!</span>
          <br />& <span className="text-[#503CC8]">3 Bonus Credits</span>
        </div>
        <div className="text-[15px] text-purple-700 font-medium text-opacity-70">
          *when you purchase without a trial
        </div>
      </>,
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          Get up to <span className="text-[#503CC8]">$115 extra off!</span>
          <br />& <span className="text-[#503CC8]">3 Bonus Credits</span>
        </div>
        <div className="text-[15px] text-purple-700 font-medium text-opacity-70">
          *when you purchase without a trial
        </div>
      </>,
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          Get up to <span className="text-[#503CC8]">$115 extra off!</span>
          <br />& <span className="text-[#503CC8]">3 Bonus Credits</span>
        </div>
        <div className="text-[15px] text-purple-700 font-medium text-opacity-70">
          *when you purchase without a trial
        </div>
      </>,
      <>
        <div className="leading-[140%] Headline2-24Bd sm:Hero2-48Bd">
          Get up to <span className="text-[#503CC8]">$115 extra off!</span>
          <br />& <span className="text-[#503CC8]">3 Bonus Credits</span>
        </div>
        <div className="text-[15px] text-purple-700 font-medium text-opacity-70">
          *when you purchase without a trial
        </div>
      </>,
    ],
    label_old1: [
      `잊지 않으셨죠?`,
      `Don't forget`,
      's_pricing.hero.label_old1',
      `Don't forget`,
      `Don't forget`,
      `Don't forget`,
      `Don't forget`,
    ],
    label_old2: [
      `고객님께만 드린 혜택`,
      `to get our special offer`,
      's_pricing.hero.label_old2',
      `to get our special offer`,
      `to get our special offer`,
      `to get our special offer`,
      `to get our special offer`,
    ],
    next: [
      <>
        최대 15만원 할인
        <br />
        혜택이 <span className="text-[#FF6712]">곧 사라져요.</span>
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $115</span>
        <br />
        are waiting for you
      </>,
      's_pricing.hero.next',
      <>
        <span className="text-[#FF6712]">Discounts up to $115</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $115</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $115</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $115</span>
        <br />
        are waiting for you
      </>,
    ],
    label_challenge1: [
      `이벤트 진행 중`,
      `Join Now!`,
      's_pricing.hero.label_challenge1',
      `Join Now!`,
      `Join Now!`,
      `Join Now!`,
      `Join Now!`,
    ],
    label_challenge2: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_pricing.hero.label_challenge2',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    challenge: [
      <>
        <span className="text-[#FF6712]">특별한 여행이</span> 기다립니다.
        <br />
        지금 링글을 시작하세요.
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      's_pricing.hero.challenge',
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
    ],
    countdown_day: [
      `일 후 종료`,
      `Day(s) left`,
      's_pricing.hero.countdown_day',
      `Day(s) left`,
      `Day(s) left`,
      `Day(s) left`,
      `Day(s) left`,
    ],
    countdown: [
      `할인 혜택 종료까지`,
      `Special offer expires in`,
      's_pricing.hero.countdown',
      `Special offer expires in`,
      `Special offer expires in`,
      `Special offer expires in`,
      `Special offer expires in`,
    ],
  },
  no_split_payment: [
    `1년 수업권 결제`,
    `For year-long credits`,
    's_pricing.no_split_payment',
    `1年课程券结算`,
    `1年課程券結算`,
    `年間レッスンチケット`,
    `Không chia nhỏ phần thanh toán`,
  ],
  no_split_payment_desc: [
    `[카드 일반결제/토스페이/가상계좌 발급]만 지원되고 있습니다. 분할 결제 또한 가능하지 않습니다.`,
    `Only [Credit card/Toss Pay/Virtual Account] are accepted.`,
    's_pricing.no_split_payment_desc',
    `只支持一次性结算，无法分期付款`,
    `只支持1次性結算，無法分期付款`,
    `一般決済のみ支援しております。分割払い決済は適応されません。`,
    `Với gói học một năm, bạn phải thanh toán một lần`,
  ],
  welcome_coupon: {
    label: [
      `3만원 웰컴쿠폰이 적용되어 있습니다`,
      `$25 OFF WELCOME COUPON applied`,
      's_pricing.welcome_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  starter_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Starter Coupon Pack applied`,
      's_pricing.starter_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  repurchase_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Next Purchase Coupon Pack applied`,
      's_pricing.repurchase_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    starter_coupon: {
      title_1: [
        `링글 첫 시작 한정`,
        `For first-purchasers only`,
        's_pricing.popup.starter_coupon.title_1',
        ``,
        ``,
        ``,
        ``,
      ],
      title_2: [`스타터 쿠폰팩 발급`, `Starter Coupon Pack`, 's_pricing.popup.starter_coupon.title_2', ``, ``, ``, ``],
      footer: [
        <>
          *쿠폰 발급 후 35일 이내 사용 가능합니다.
          <br />
          *20만원 이상 정규수업권 구매 시 적용 가능한 쿠폰입니다.
        </>,
        <>
          * Valid for 35 days after issuance.
          <br />* Only redeemable toward regular packages worth $115 or more.
        </>,
        's_pricing.popup.starter_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`한 달간 보지않기`, `Remind me in a month`, 's_pricing.popup.starter_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`확인`, `Close`, 's_pricing.popup.starter_coupon.btn_2', ``, ``, ``, ``],
    },
    repurchase_coupon: {
      title_1: [
        `기존 회원 한정`,
        `Next Purchase`,
        's_pricing.popup.repurchase_coupon.title_1',
        `Next Purchase`,
        `Next Purchase`,
        `Next Purchase`,
        `Next Purchase`,
      ],
      title_2: [
        `재구매 쿠폰 발급 안내`,
        `Coupon Pack`,
        's_pricing.popup.repurchase_coupon.title_2',
        `Coupon Pack`,
        `Coupon Pack`,
        `Coupon Pack`,
        `Coupon Pack`,
      ],
      footer: [
        <>* 쿠폰은 수업권 금액에 따라 차등 적용됩니다.</>,
        <>* Discounts vary according to credit price.</>,
        's_pricing.popup.repurchase_coupon.footer',
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
      ],
      btn_1: [
        `일주일간 보지않기`,
        `Remind me in a week`,
        's_pricing.popup.repurchase_coupon.btn_1',
        `Remind me in a week`,
        `Remind me in a week`,
        `Remind me in a week`,
        `Remind me in a week`,
      ],
      btn_2: [`확인`, `Close`, 's_pricing.popup.repurchase_coupon.btn_2', `Close`, `Close`, `Close`, `Close`],
    },
  },
  card_promo: [
    `프로모션 한정 판매`,
    `2022 Promo Only`,
    's_pricing.card_promo',
    `2022 Promo Only`,
    `2022 Promo Only`,
    `2022 Promo Only`,
    `2022 Promo Only`,
  ],
  card_popular: [
    `인기`,
    `Most Popular`,
    's_pricing.card_popular',
    `Most Popular`,
    `Most Popular`,
    `Most Popular`,
    `Most Popular`,
  ],
  promo_price: [
    `연중 최대 할인가`,
    `Best Deal All Year`,
    's_pricing.promo_price',
    `全年最高折扣价`,
    `全年最高折扣價`,
    `今年最大の割引価格`,
    `Giá thấp nhất`,
  ],
  package_price: [
    `패키지 할인`,
    `Package discount`,
    's_pricing.package_price',
    `礼包折扣`,
    `禮包折扣`,
    `パッケージ割引`,
    `Giảm giá gói hàng`,
  ],
  benefit_list1: (number) => [
    <>무료 수업권 {number}회 추가 증정</>,
    <>
      {number} extra regular {number > 1 ? 'credits' : 'credit'}{' '}
    </>,
    's_pricing.benefit_list1',
    <>{number}次追加课程</>,
    <>{number}次額外課程</>,
    <>{number}回レッスン追加</>,
    `Giảm giá cho học viên mới`,
  ],
  benefit_list2: (price) => [
    <>{price} 추가 할인</>,
    <>Extra {price} off</>,
    's_pricing.benefit_list2',
    <>{price}优惠</>,
    <>{price}折扣</>,
    <>{price}割引</>,
    <>{price} Off</>,
  ],
  benefit_list3: (number) => [
    <>당일수업권 {number}회 추가 증정</>,
    <>{number} extra last-minute credits</>,
    's_pricing.benefit_list3',
    <>{number}次补充课程</>,
    <>{number}次補充課程</>,
    <>{number}回分の当日レッスン</>,
    <>{number} Buổi học bù</>,
  ],
  benefit_list4: [
    `튜터 우선 예약`,
    `Early Booking Access`,
    's_pricing.benefit_list4',
    `教师优先选定`,
    `優先指定任課教師`,
    `チューター優先予約`,
    `Gia sư ưu tiên`,
  ],
  benefit_list5: (number) => [
    <>{number}회 당일수업권 제공</>,
    <>{number} Last-minute Credits</>,
    's_pricing.benefit_list5',
    <>{number}次补充课程</>,
    <>{number}次補充課程</>,
    <>{number}回分の当日レッスン</>,
    <>{number} Buổi học bù</>,
  ],
  btn_detail: [
    `자세히 보기`,
    `See details`,
    's_pricing.btn_detail',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  qna: {
    title: [
      `자주 묻는 질문`,
      `Frequently Asked Questions`,
      's_pricing.qna.title',
      `常见问题`,
      `常見問題`,
      `よくある質問`,
      `Những câu hỏi thường gặp`,
    ],
    list1: {
      main: [
        `어떤 수업권을 선택하면 될까요?`,
        `Which credit type should I buy?`,
        's_pricing.qna.list1.main',
        `该选择哪一个课程券呢？`,
        `我應該選擇哪一個課程券呢？`,
        `どのレッスンチケットを選択すればいいですか？`,
        `Tôi nên mua thẻ học nào?`,
      ],
      desc1: [
        `20분/40분 수업권은 수업 길이 차이만 있고 수업 진행 방식, 교재와 튜터 선택지는 모두 같습니다.`,
        `20 min and 40 min credits are only different in terms of time, everything else such as materials, tutors and lesson format are the same.`,
        's_pricing.qna.list1.desc1',
        `20分钟和40分钟课程券的区别只在于课程时间长度，课程进行方式、教材，选择任课教师均相同。`,
        `20分鐘及40分鐘課程券差別只在於課程長度，進行方式、教材、任課教師的選擇均相同。`,
        `20分と40分のレッスンチケットはレッスン時間が異なりますが、進行方式、教材、チューターの選択肢は全て同じです。`,
        `Khác biệt duy nhất ở thẻ học 20 và 40 phút là thời gian, mọi thừ từ tài liệu, gia sư và hình thức đều giống nhau.`,
      ],
      desc2: [
        `20분 수업은 비교적 가벼운 주제로 꾸준한 영어 말하기 습관을 만드는데 추천하는 수업입니다.`,
        `20 minute lessons are recommended for those who wish to build English speaking habits on relatively lighter topics.`,
        's_pricing.qna.list1.desc2',
        `20分钟课程是进行轻松主题对话，培养英语口语习惯的课程。`,
        `20分鐘課程是利用相對輕鬆的主題培養按時說英語的習慣。`,
        `20分レッスンは比較的軽いテーマでコツコツと英語の話す習慣を形成するのにお勧めするレッスンです。`,
        `Buổi học 20 phút phù hợp với những ai muốn xây dựng thói quen nói tiếng Anh qua những chủ đề đơn giản.`,
      ],
      desc3: [
        `40분 수업은 특정 주제에 대한 깊이 있는 대화, 인터뷰/Writing 첨삭 등에 추천하는 수업입니다.`,
        `40 minute lessons are recommended for in-depth conversations on a specific topic or for editing interviews/writing.`,
        's_pricing.qna.list1.desc3',
        `40分钟课程是针对特定主题进行深度讨论或练习面试/笔试增删的课程。`,
        `40分鐘課程是針對特定主題進行深度討論或練習面試／Writing增刪。`,
        `40分レッスンは特定のテーマに対して深く対話をしたり、面接／Writingの添削にお勧めのレッスンです。`,
        `Buổi học 40 phút phù hợp với những buổi thảo luận sâu dựa trên những chủ đề nhất định hoặc luyện phỏng vấn thử/ chỉnh sửa bài viết.`,
      ],
      desc4: [
        `특히 영어 초·중급자의 경우 최소 3개월 이상 주 1-2회 수업을 수강했을 때 영어 실력이 향상됩니다. 20분/40분 24회 이상 수업권으로 시작하시는 것을 추천합니다.`,
        `For beginners/intermediates, we recommend credits more than 24 lessons. We suggest taking 1-2 lessons per week for at least 3 months to improve English proficiency.`,
        's_pricing.qna.list1.desc4',
        `针对英语能力初/中级者，我们建议上课频率为一週 1~2次，并且至少持续3个月，如此一来才能看见明显进步。我们建议从购买 20分钟/40分钟至少24回以上的课程券开始，以利长期学习。`,
        `針對英文能力初/中級者，我們建議上課頻率為一週 1~2次，並且至少持續3個月，如此一來才能看見明顯進步。我們建議從購買 20分鐘/40分鐘至少24回以上的課程券開始，以利長期學習。`,
        `特に英語の初級者、中級者の場合、最低3ヶ月以上週1~2回授業をした時に英語力が向上します。 20分/40分、24回以上のチケットで始めることをおすすめします。`,
        `Đối với người mới bắt đầu/trung cấp, chúng tôi khuyên bạn tham gia 24 bài học, tần suất 1-2 bài mỗi tuần trong ít nhất 3 tháng để cải thiện trình độ tiếng Anh.`,
      ],
    },
    list2: {
      main: [
        `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
        `Is there a limit on the time/number of times I can book a lesson?`,
        's_pricing.qna.list2.main',
        `可预约课程的时间/次数有限吗？`,
        `可預約課程的時間／次數有限嗎？`,
        `レッスンを予約することの出来る時間／回数は決まっていますか？`,
        `Có giới hạn số buổi học có thể đặt lịch không?`,
      ],
      desc1: [
        `링글 수업권은 횟수제로 제공되며, 구매하신 수업권 횟수만큼 수강기간 내 자유롭게 수강할 수 있습니다. 일 별 또는 주 별로 수강할 수 있는 수업 횟수에 제한이 없습니다.`,
        `You can take as many lessons as the number of 1:1 credits purchased before their expiration date. There is no limit to the number of lessons you can take per day or per week.`,
        's_pricing.qna.list2.desc1',
        `1:1课程券是按次数制使用，根据购买的课程次数，可在使用期限内自由使用。可每天或每周不限次数听课。`,
        `1:1課程券採次數制，依據購買的課程券次數，您可於使用期限內自由上課。每日或每週可上課的次數並無限制。`,
        `1on1レッスンチケットは回数制で提供され、購入されたレッスンチケットの回数だけ受講期間内に自由に受講することが出来ます。日別または週別で受講することが出来るレッスンの回数は制限がありません。`,
        `Bạn có thể đặt số buổi học tương đương số thẻ học bạn đã mua trước khi hết hạn. Không có giới hạn số buổi học bạn có thể học trong một ngày hay trong một tuần.`,
      ],
      desc2: [
        `정규수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Regular lessons are lessons that start 24 hours after the time of reservation, and you can freely choose a tutor and materials.`,
        's_pricing.qna.list2.desc2',
        `正规课程是在预约课程起24小时后开始的课程，可自由选择任课教师和教材。`,
        `正規課程為自預約起24小時後開始的課程，可自由選擇任課教師及教材。`,
        `レギュラーレッスンは予約時の基準から24時間以降に始まるレッスンで、チューターと教材を自由に選択することができます。`,
        `Buổi học tiêu chuẩn là buổi học bạn sẽ đặt ít nhất 24 giờ trước khi bắt đầu. Bạn tự do chọn gia sư và tài liệu.`,
      ],
      desc3: [
        `당일수업은 예약 시점 기준 24시간 이내에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Last-minute credits can be used to book lessons that start within 24 hours. You can choose any tutor or material for last-minute lessons.`,
        's_pricing.qna.list2.desc3',
        `当日课程是指以预约时间为准 24 小时内开始的课程，可自由选择任课教师和教材。`,
        `當日課程是指以預約時間爲準 24 小時內開始的課程，可自由選擇任課教師和教材。`,
        `当日レッスンは予約から24時間以内に開始するレッスンであり、チューターと教材を自由に選択することができます。`,
        `Buổi học trong ngày là buổi học bắt đầu trong vòng 24 giờ sau khi đặt và có thể tự do chọn gia sư, tài liệu.`,
      ],
      desc4: [
        `[정규수업권]은 정규수업 및 당일수업 예약에 사용할 수 있습니다. (정규 시간 : 연중무휴 KST 기준 오전 5시 ~ 오후 14시, 저녁 19시 ~ 새벽 3시)`,
        `[Regular Credits] can be used freely during regular hours and can also be used for last-minute lessons. (Regular Hours: Open all year round, 5 am to 2 pm KST, 7 pm to 3 am KST)`,
        's_pricing.qna.list2.desc4',
        `[正规课程券]可在正规时间内自由使用，也可用于当天课程预约。(正规时间 : 年中无休， 以KST为准，凌晨5点 ~ 下午14点, 晚上19点 ~ 凌晨 3点)`,
        `[正規課程券]可於正規時間內自由使用，也可用於預約當天課程。（正規時間：全年無休，韓國時間凌晨5點～下午2點、晚上7點～凌晨3點）`,
        `[レギュラーレッスンチケット]は正規時間内に自由に利用が可能で、当日にレッスンの予約をすることが出来ます。（正規時間：年中無休　午前5時～午後4時、午後7時～午前3時）`,
        `[Thẻ học tiêu chuẩn] được sử dụng để đặt học trong giờ tiêu chuẩn và cũng có thể trong cùng một ngày. (Giờ tiêu chuẩn: 05:00-14:00, 19:00-03:00 (Giờ Hàn Quốc) mọi ngày trong năm`,
      ],
      desc5: [
        `[시간한정수업권]은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
        `[Limited Window Credits] are lesson credits that can be used during limited hours. (Morning hours: 5 am to 8 am (KST), Late night hours: midnight to 3 am (KST))`,
        's_pricing.qna.list2.desc5',
        `[限时课程券]可用于指定时间段的课程券。(凌晨班: 凌晨5点~ 上午8点(KST), 深夜班: 晚上00点 ~ 凌晨3点(KST))`,
        `[時間限定課程券]可用於指定時段的課程券。（早鳥班：凌晨5點～上午8點（韓國時間）、深夜班：晚上12點～凌晨3點（韓國時間））`,
        `[時間限定レッスンチケット]は決まった時間帯に利用可能なレッスンチケットです。（早朝：午前5時～午前8時、深夜：午前0時～午前3時）`,
        `[Thẻ học giờ đặc biệt] là thẻ để đặt lịch trong khung giờ đặc biệt (Sáng sớm: 05:00-08:00; Ban đêm 00:00-03:00 theo giờ Hàn Quốc)`,
      ],
      desc6: [
        `[당일수업권]이란 예약 시점 기준 24시간 이내에 시작하는 당일수업을 예약할 수 있는 수업권으로,  [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
        `[Last-minute Credits] are lesson credits that can only be used to reserve [Last-minute Lessons] starting within 24 hours from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
        's_pricing.qna.list2.desc6',
        `[当天课程券]是可用于预约课程起24小时内开始的[当天课程]的课程券,可在 [1:1课程] → [预约] → [当天课程]中使用。`,
        `[當天課程券]僅可預約自預約起24小時內開始的[當天課程]，可於[1:1課程] → [預約] → [當天課程]使用。`,
        `[当日レッスンチケット]とは予約時の基準から24時間以内に始まる[当日レッスン]だけ予約することが出来るレッスンチケットで、[レッスン]→[予約]→[当日レッスン]→で利用することが出来ます。`,
        `[Thẻ học bù] là thẻ học đặt lịch cho các buổi học trong 24 giờ tới. Đặt lịch tại [Buổi học 1:1] → [Đặt lịch] → [Buổi học 24 giờ tới].`,
      ],
    },
    list3: {
      main: [
        `수업권은 어떻게 구매하나요?`,
        `How can I purchase credits?`,
        's_pricing.qna.list3.main',
        `如何购买课程券?`,
        `如何購買課程券?`,
        `レッスンチケットの購入方法は？`,
        `Mua thẻ học như thế nào?`,
      ],
      desc1: [
        `카드 일반 결제, 실시간 계좌 이체, 가상 계좌 발급, 2개 카드 분할 결제, 카카오/네이버/삼성 페이 등의 결제 방식을 지원합니다. (월 단위 정기 결제 형식은 지원하지 않습니다.)`,
        `If you are using Korean payment, you can purchase credits through the following payment methods: credit card (split payment with two credit cards possible), real-time payment, bank transfer to a virtual account, and mobile payment services such as Kakao Pay, Naver Pay, and Samsung Pay. Please note that we do not offer a monthly subscription service.`,
        's_pricing.qna.list3.desc1',
        `如果您选择韩国付款方式，我们支持刷卡、实时转账、虚拟账户发放、两卡分开付款、Kakao/Naver/SamsungPay 等支付方式。（不支持分月定期付款形式。）`,
        `支持刷卡、實時轉賬、虛擬賬戶發放、兩卡分開付款、Kakao/Naver/SamsungPay 等支付方式。（不支持分月定期付款形式。）`,
        `クレジットカード決済、口座振替、バーチャル口座振込決済、カード2枚による分割決済、カカオ/ネイバー/サムソンペイなどの決済方式に対応しております。（月単位の長期決済方式は対応しておりません。）`,
        `Hỗ trợ thanh toán thẻ tín dụng, thanh toán qua tài khoản ảo, chia thanh toán trên nhiều thẻ, Kakao / Naver / Samsung Pay,.v.v... (Không hỗ trợ thanh toán định kỳ theo tháng.)`,
      ],
      desc2: [
        `해외 결제의 경우 카드 결제, Paypal 결제가 가능하며 모두 미국 달러로 결제됩니다.`,
        `If you are not in Korea, you can purchase credits using a credit card or via Paypal, and all payments will be processed in US dollars.`,
        's_pricing.qna.list3.desc2',
        `如果您不在韩国，支付形式包括刷卡、PayPal 支付，均以美元支付`,
        `海外支付形式包括刷卡、PayPal 支付，均以美元爲準。`,
        `海外決済の場合は、クレジットカード決済、PayPal決済が可能であり、すべて米ドル決済で行われます。`,
        `Khi thanh toán quốc tế ngoài Hàn Quốc bằng PayPal, mọi giao dịch sẽ được thanh toán bằng đồng USD.`,
      ],
    },
    list4: {
      main: [
        `수업권의 수강기간이 궁금해요.`,
        `When do Lesson Credits expire?`,
        's_pricing.qna.list4.main',
        `想了解课程券的使用期限。`,
        `我想了解課程券的使用期限。`,
        `レッスンチケットの受講期間が知りたいです。`,
        `Khi nào Thẻ học hết hạn?`,
      ],
      desc1: [
        `속성 수업권을 제외한 수업권의 수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
        `Expiration date countdown will begin after the first lesson is completed. (Except Expedite credits)`,
        's_pricing.qna.list4.desc1',
        `在完成首次课程时扣除使用期限`,
        `於完成首次課程時扣除使用期限。`,
        `受講期間は、最初の授業が完了した時点から始まります。`,
        `Ngày hết hạn sẽ bắt đầu được tính khi hoàn thành buổi học đầu tiên.`,
      ],
      desc2: [
        `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다.`,
        `However, if you do not take a lesson within 1 year after your purchase, the credits will automatically expire. Therefore, lessons may begin at anytime within 1 year of your purchase.`,
        's_pricing.qna.list4.desc2',
        `但购买课程券后1年期间里未开始上课，将自动扣除使用期限。而且，可在购买课程券1年内随时开始听课。`,
        `但購買後1年期間未開始上課之情況，將自動扣除使用期限。因此，可於購買課程券後1年內於希望的時間開始上課。`,
        `ただ、レッスンチケットを購入後に1年になる時点でレッスンを開始しなかった場合には受講期間が自動差し引きになります。したがって、レッスンチケットを購入後1年以内だとお好きな時点でレッスンを始めることが出来ます。`,
        `Tuy nhiên, nếu bạn không học trong vòng 1 năm kể từ khi mua, thẻ học sẽ tự động hết hạn. Nên bạn phải học buổi học đầu tiên trong vòng 1 năm kể từ khi mua.`,
      ],
      desc3: [
        `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
        `Expiration dates are calculated as of the time your first lesson is completed, not reserved.`,
        's_pricing.qna.list4.desc3',
        `使用期限将以第一次课程结束时间为准，并非以预约课程的时间为准。`,
        `使用期限係以完課程課的時間為準，並非預約課程的時間。`,
        `受講期間の基準はレッスンを予約する時点ではなく、受講完了の時点です。`,
        `Ngày hết hạn được tính khi buổi học đầu tiên hoàn thành, không theo ngày bạn đặt học.`,
      ],
      desc4: [
        `수강기간 연장은 [프로필] > [수업권/증명서류] > [수강기간 연장]에서 포인트 사용 또는 소액 결제를 통해 가능합니다.`,
        `You can extend the expiration date at [Profile] > [Credits] > [Extend expiration dates] by using points or paying an extension fee.`,
        's_pricing.qna.list4.desc4',
        `可在[简介] > [课程券/证明资料] > [延长使用期限]通过用积分或小额付款延长使用期限。`,
        `可於[簡介] > [課程券／證明] > [延長使用期限]透過使用點數或小額付款延長使用期限。`,
        `有効期限の延長は[プロフィール]＞[レッスンチケット／証明書類]＞[受講期間の延長]からポイントの使用又は少額決済を通して可能です。`,
        `Bạn có thể gia hạn ngày hết hạn tại  [Profile] → [Thẻ học] → [Gia hạn thẻ học] bằng cách sử dụng điểm hoặc trả khoản phí nhỏ.`,
      ],
      desc5: [
        `실결제가 30만원 이상의 수업권을 재구매하시면, 보유한 수업권의 처음 수강기간을 추가로 연장할 수 있는 [수강기간 연장 쿠폰]이 발급됩니다.`,
        `You will be issued an [Extension Coupon] for extending the lesson days on your existing credits upon repurchasing a credit package worth $231 or more.`,
        's_pricing.qna.list4.desc5',
        `重新购买实际结算金额30万韩元以上的课程券时，将发放【课时延长优惠券】，该券可以追加延长现有课程券的第一次课时。`,
        `重新購買實際結算金額30萬韓元以上的課程券時，將發放【課時延長優惠券】，該券可以追加延長現有課程券的第一次課時。`,
        `決済金額が30万ウォン以上のレッスンチケットを再購入されると、保有しているレッスンチケットの初めの受講期間を追加で延長できる［受講期間延長クーポン］が発給されます。`,
        `Bạn sẽ được cấp [Phiếu gia hạn] để kéo dài hạn sử dụng gói học trên các phiếu học hiện có của bạn khi mua lại gói học trị giá ₩300.000 trở lên.`,
      ],
    },
    list5: {
      main: [
        `수업 취소 정책이 궁금해요.`,
        `What is the cancellation policy?`,
        's_pricing.qna.list5.main',
        `想了解课程取消政策。`,
        `我想了解課程取消政策。`,
        `キャンセルポリシーはどうなっていますか？`,
        `Chính sách hủy thể nào?`,
      ],
      desc1: [
        `수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
        `If you cancel 24 hours before the start of the lesson, the credit will be automatically restored.`,
        's_pricing.qna.list5.desc1',
        `在课程开始24小时前取消课程，可自动恢复课程券。`,
        `於課程開始24小時以前取消時，自動復原該課程券。`,
        `レッスン開始基準24時間以前に取り消しした場合、該当のレッスンチケットは自動復元されます。`,
        `Nếu bạn hủy trên 24 giờ trước khi buổi học bắt đầu, thẻ học sẽ được khôi phục tự động.`,
      ],
      desc2: [
        `수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.`,
        `If you cancel 24 to 2 hours before the start of the lesson, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will be issued.`,
        's_pricing.qna.list5.desc2',
        `在课程开始24小时~2小时前取消课程，将扣除课程券，并发送[当天课程券]。`,
        `於課程開始24小時～2小時前取消時，扣除該課程券，並發送[當天課程券]。`,
        `レッスン開始基準24時間～2時間以前に取り消しした場合は、予約に使用したチケットの代わりに、新たに当日チケットが支給されます。`,
        `Nếu hủy trong khoảng 2-24 giờ trước buổi học, thẻ học sẽ bị trừ và thay thể bằng Thẻ học bù.`,
      ],
      desc3: [
        `수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
        `If you cancel within 2 hours before the lesson starts, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will not be issued.`,
        's_pricing.qna.list5.desc3',
        `在课程开始2小时内取消课程，将扣除课程券，并且不发送[当天课程券]。`,
        `於課程開始前2小時以內取消時，扣除該課程券，並不發送[當天課程券]。`,
        `レッスンが始まる2時間以内に取り消しした場合、予約に使用したチケットは費消され、当日チケットも支給されません。`,
        `Nếu bạn hủy ít hơn 2 giờ trước buổi học, thẻ học của bạn sẽ bị trừ.`,
      ],
    },
    list6: {
      main: [
        `수업권 환불 정책이 궁금해요.`,
        `How can I get a refund?`,
        's_pricing.qna.list6.main',
        `想了解课程券退款政策。`,
        `我想了解課程券退款政策。`,
        `レッスン料金の返金は可能でしょうか？`,
        `Làm sao để được hoàn tiền?`,
      ],
      desc1: [
        `수강기간 차감 전, 수업권 사용 이력이 없는 경우 전액 환불이 가능합니다. 무료 수업권 및 체험 수업을 포함한 첫 수업 1회 진행 후 불만족 시에도 전액 환불이 가능합니다.`,
        `Credit packages are fully refundable before their lesson days have started to diminish if they have not been used. Full refunds are also available if you are unsatisfied with our service after your first lesson, including lessons taken using free credits and trial lessons.`,
        's_pricing.qna.list6.desc1',
        `在课时扣除前，课程券没有使用过的情况下，可全额退款。包括免费课程券及体验课程在内的第一堂课听取1课时后，不满意也可全额退款。`,
        `在課時扣除前，課程券沒有使用過的情況下，可全額退款。包括免費課程券及體驗課程在內的第一堂課聽取1課時後，不滿意也可全額退款。`,
        `受講期間終了前、レッスンチケット使用履歴がない場合は全額返金可能です。無料レッスンチケット及び体験レッスンを含む初レッスン１回を行った後不満足時でも全額返金可能です。`,
        `Số phiếu học trong gói đăng ký được hoàn trả đầy đủ trước khi hết hạn nếu chúng chưa được sử dụng. Bạn cũng có thể được hoàn lại toàn bộ tiền nếu không hài lòng với dịch vụ của chúng tôi sau buổi học đầu tiên, bao gồm cả những buổi học miễn phí và những buổi học thử.`,
      ],
      desc2: [
        <>
          수강기간 차감 후, 수강기간 또는 수업 횟수가 절반 이하 남은 수업권은 환불이 불가합니다. 그 외 사용 이력이 있는
          수업권은{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            이용약관 13조 및 15조
          </a>
          에 의거하여 부분 환불 처리합니다.{' '}
        </>,
        <>
          Credit packages with less than half of remaining in lesson days or lessons are not refundable. Any other
          credit packages that have been used may be eligible for partial refunds based on{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            Articles 13 and 15 of Ringle's Terms of Service.
          </a>
        </>,
        's_pricing.qna.list6.desc2',
        `扣除课时后，剩余课时或课程次数不足一半的课程券不能退款。除此之外已经使用过的课程券可以根据使用条款第13条和第15条进行部分退款。`,
        `扣除課時後，剩餘課時或課程次數不足一半的課程券不能退款。除此之外已經使用過的課程券可以根據使用條款第13條和第15條進行部分退款。`,
        `受講期間終了後、受講期間またはレッスン回数が半数以下残ったレッスンチケットは返金不可です。それ以外の使用履歴があるレッスンチケットは利用規約13条及び15条に基づき部分返金可能です。`,
        `Các gói còn ít hơn một nửa thời hạn dùng hoặc số bài học còn lại sẽ không được hoàn lại. Bất kỳ gói học nào khác đã được sử dụng có thể đủ điều kiện được hoàn tiền một phần dựa trên Điều 13 và 15 của Điều khoản Dịch vụ của Ringle.`,
      ],
      desc3: [
        `수강기간 연장 이력이 있는 수업권 또는 증명서를 발급한 수업권은 환불이 불가합니다.`,
        `Credits for which lesson days were extended or certificate(s) were issued are not refundable.`,
        's_pricing.qna.list6.desc3',
        `有延长使用期限的课程券或已经发放证书的课程券不能退款。`,
        `有延長使用期限的課程券或已經發放證書的課程券不能退款。`,
        `受講期間延長履歴があるレッスンチケットまたは証明書を発給したレッスンチケットは返金が不可能です。`,
        `Với những phiếu học mà hạn sử dụng đã được kéo dài hoặc (các) chứng chỉ đã được cấp sẽ không được hoàn lại.`,
      ],
      desc4: [
        `환불 금액 산정 시, 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료가 차감될 수 있습니다.`,
        `In the case of refunds, any transaction fees incurred from your bank, credit card or Paypal will be deducted from the refund amount.`,
        's_pricing.qna.list6.desc4',
        `计算退款金额时，可能会扣除银行、信用卡公司、Paypal等结算手段的相应手续费。`,
        `計算退款金額時，可能會扣除銀行、信用卡公司、Paypal等結算手段的相應手續費。`,
        `返金金額が算定時、銀行、カード会社、Paypalなどの決済手段による手数料が発生することがあります。`,
        `Trong trường hợp hoàn tiền, mọi khoản phí giao dịch phát sinh từ ngân hàng, thẻ tín dụng hoặc Paypal của bạn sẽ được khấu trừ vào số tiền hoàn trả.`,
      ],
      desc5: [
        <>
          그 외 사용 이력이 있는 수업권은{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            이용약관 13조 및 15조
          </a>
          에 의거하여 환불 처리합니다.{' '}
        </>,
        <>
          Used credits will be refunded according to{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            articles 13 and 15 of your terms of service.
          </a>
        </>,
        's_pricing.qna.list6.desc5',
        `已有曾使用过的课程券，退款时将按照使用条款中的(13条, 15条)进行处理!`,
        `其他曾使用過的課程券將依據使用條款第13條及第15條進行退款。（需插入超連結 https://www.ringleplus.com/ko/student/policy/terms）`,
        `その他の受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づき、処理されます。 https://www.ringleplus.com/ko/student/policy/terms`,
        `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
      ],
    },
    list7: {
      main: [
        `수업 증명서를 발급 받을 수 있나요?`,
        `Can I get lesson certificates?`,
        's_pricing.qna.list7.main',
        `如何申请上课证明？`,
        `如何核發課程證明呢？`,
        `レッスンの証明書の発行は出来ますか？`,
        `Làm sao để nhận chứng nhận?`,
      ],
      desc1: [
        <>
          증명서 발급은{' '}
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/ko/student/portal/mypage/credit"
          >
            [프로필] {'>'} [수업권/증명서류] {'>'} [증명서 발급]
          </a>
          에서 수강증, 출석증, 또는 영수증을 직접 발급하실 수 있습니다.{' '}
        </>,
        `To get certificates, go to [Profile] > [Credits] > [Issue Certificate] to directly issue a lesson certificate, attendance certificate, or receipt. (Link https://www.ringleplus.com/ko/student/portal/mypage/credit)`,
        's_pricing.qna.list7.desc1',
        `[可在[简介] > [课程券/证明资料] > [证明书发放]中亲自申请发放上课证明、出席证明或收据。(链接 https://www.ringleplus.com/ko/student/portal/mypage/credit)`,
        `[可於[簡介] > [課程券／證明] > [核發證明]親自申請核發上課證明、出席證明或收據。（連結 https://www.ringleplus.com/ko/student/portal/mypage/credit）`,
        `受講したレッスンの証明書類はいつでも発行可能です。受講証、出席証、領収証 (英語)`,
        `Để nhận chứng nhận vào phần [Profile] > [Thẻ học/Chứng nhận] > [Xuất chứng nhận] để nhận chứng nhận buổi học, chứng nhận tham gia hoặc hoá đơn.`,
      ],
      desc2: [
        `수강증 및 출석증은 월별로 나누어 발급하실 수 있습니다.`,
        `Lesson certificates and attendance certificates can be downloaded on a monthly basis.`,
        's_pricing.qna.list7.desc2',
        `可按月发放上课证明及出席证明。`,
        `可按月核發上課證明及出席證明。`,
        `受講証及び出席証は月別で分けて発行することが出来ます。`,
        `Chứng nhận buổi học và chứng nhận tham gia có thể xuất mỗi tháng.`,
      ],
      desc3: [
        `증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 발급하실 수 있습니다.`,
        `Certificates will only be issued for purchased credits and regular lessons.`,
        's_pricing.qna.list7.desc3',
        `仅针对实际付款的课程券及正常进行的课程发放证明。`,
        `僅可針對實際付款的課程券及正常進行的課程核發證明。`,
        `受講証は実際に決済したレッスンチケット及び正常にレッスンをしたもののみ発行することが出来ます。`,
        `Chứng nhận chĩ xuất cho buổi học tiêu chuẩn và các thẻ học đã thanh toán.`,
      ],
      text_btn: [
        `증명서류 예시 상세보기`,
        `See detailed preview of certificate`,
        's_pricing.qna.list7.text_btn',
        `证明资料示例详情`,
        `詳細確認證明範例`,
        `証明書類の例示を見る`,
        `Xem chi tiết chứng nhận`,
      ],
    },
    new_list1_title: [
      `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
      `Are there any limits to booking lessons (e.g., how many and when)?`,
      's_pricing.qna.new_list1_title',
      `预订课程有时间/次数限制吗？`,
      `預訂課程有時間/次數限制嗎？`,
      `授業を予約することができる時間/回数は決まっていますか？`,
      `Có giới hạn về thời gian/số lần mua lớp học không?`,
    ],
    new_list1_content: [
      <>
        <li className="li-tag-inside">
          링글 수업권은 수강기간 내 자유롭게 사용 가능합니다. (단, 매주 수업 듣기는 주 1회 필수 수강이 필요합니다.)
        </li>
        <li className="li-tag-inside">
          정규 수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택할 수 있습니다.
        </li>
        <li className="li-tag-inside">
          당일 수업은 예약 시점 기준 24시간 이내에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택할 수 있습니다.
        </li>
        <li className="li-tag-inside">
          [정규수업권]은 정규 수업 및 당일 수업 예약에 사용할 수 있습니다. (정규 시간 : 연중무휴 KST 기준 오전 5시 ~
          오후 14시, 저녁 19시 ~ 새벽 3시)
        </li>
        <li className="li-tag-inside">
          [당일수업권]은 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있습니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          There is no limit to the number of lessons you can take per day or week within the validity period.
        </li>
        <li className="li-tag-inside">
          Regular lessons are lessons that are booked at least 24 hours in advance. You can freely choose tutors and
          materials for regular lessons.
        </li>
        <li className="li-tag-inside">
          Last-minute lessons are lessons that are booked to start within 24 hours. You can freely choose tutors and
          materials for last-minute lessons.
        </li>
        <li className="li-tag-inside">
          [Regular Credits] can be used for booking regular and last-minute lessons. (Regular lesson hours: 5:00 am -
          14:00 pm KST, 19:00 pm - 3:00 am KST)
        </li>
        <li className="li-tag-inside">
          [Last-minute Credits] can only be used for booking last-minute lessons that start within 24 hours.
        </li>
      </>,
      's_pricing.qna.new_list1_content',
      <>
        <li className="li-tag-inside">Ringle 课程券在课程期限内没有每天或每周的课程次数限制。</li>
        <li className="li-tag-inside">正规课程是在预订后的24小时之后开始的课程，您可以自由选择教师和教材。</li>
        <li className="li-tag-inside">当日课程是在预订后的24小时内开始的课程，您可以自由选择教师和教材。</li>
        <li className="li-tag-inside">
          [正规课程券]可用于预订正规课程和当日课程（可利用时间：全年无休的JST标准时间上午5点至下午2点，晚上7点至凌晨3点）。
        </li>
        <li className="li-tag-inside">
          [当日课程券]只能用于在预订时刻的24小时内开始的当日课程。您可以在[1:1课程] - [预订] -
          [可预约的当日课程]中使用该券。
        </li>
      </>,
      <>
        <li className="li-tag-inside">Ringle 課程券在課程期限內沒有每天或每週的課程次數限制。</li>
        <li className="li-tag-inside">正規課程是在預訂後的24小時之後開始的課程，您可以自由選擇教師和教材。</li>
        <li className="li-tag-inside">當日課程是在預訂後的24小時內開始的課程，您可以自由選擇教師和教材。</li>
        <li className="li-tag-inside">
          [正規課程券]可用於預訂正規課程和當日課程（可利用時間：全年無休的JST標準時間上午5點至下午2點，晚上7點至淩晨3點）。
        </li>
        <li className="li-tag-inside">
          [當日課程券]只能用於在預訂時刻的24小時內開始的當日課程。您可以在[1:1課程] - [預訂] -
          [可預約的當日課程]中使用該券。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Ringleのレッスンチケットは、受講期間内における日ごとまたは週ごとの授業回数に制限はありません。
        </li>
        <li className="li-tag-inside">
          レギュラーレッスンは、予約時点から24時間以降に始まる授業で、チューターと教材を自由に選択できます。.
        </li>
        <li className="li-tag-inside">
          当日授業は、予約時点から24時間以内に始まる授業で、チューターと教材を自由に選択できます。
        </li>
        <li className="li-tag-inside">
          【レギュラーレッスンチケット】は、レギュラーレッスンおよび当日レッスンの予約に使用することができます。（通常時間：年中無休　午前5時〜午後２時、午後７時〜午前3時）
        </li>
        <li className="li-tag-inside">
          【当日レッスンチケット】は、予約時点から24時間以内に始まる当日レッスンのみ予約できます。[1on1レッスン] -
          [レッスンを予約する] - [予約可能な当日レッスン]で使用できます。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Không có giới hạn về số lượng bài học, có thể tham gia mỗi ngày hoặc mỗi tuần với gói học Ringle{' '}
        </li>
        <li className="li-tag-inside">
          Gói học thường bắt đầu sau 24h kể từ khi mua gói học, có thể thoải mái lựa chọn gia sư và giáo trình.
        </li>
        <li className="li-tag-inside">
          Gói học trong ngày bắt đầu trong vòng 24 giờ kể từ thời điểm đăng ký, có thể tự do lựa chọn gia sư và giáo
          trình.
        </li>
        <li className="li-tag-inside">
          [Gói học thường] có thể dùng để đăng ký lớp học thường và lớp học trong ngày. (Lớp học thường: Sáng: 5H - 14H,
          Tối: 19H - 3H sáng (KST))
        </li>
        <li className="li-tag-inside">
          [Gói học trong ngày] chỉ có thể đăng ký các lớp học trong vòng 24H kể từ khi mua khóa học. Có thể đăng ký tại
          [Lớp học 1:1] - [Đăng ký] - [Lớp học trong ngày có thể đăng ký]
        </li>
      </>,
    ],
    new_list2_title: [
      `매주 수업 듣기가 무엇인가요?`,
      `What are Every Week credits?`,
      's_pricing.qna.new_list2_title',
      `什么是每週课程券？`,
      `什麼是每週課程券？`,
      `「毎週レッスンを受ける」とは何ですか？`,
      `Lớp hàng tuần là gì?`,
    ],
    new_list2_content: [
      <>
        <li className="li-tag-inside">매주 1회 이상 꾸준한 영어 학습을 희망하는 경우 추천하는 수업권입니다.</li>
        <li className="li-tag-inside">
          수업권 구매 즉시 수강 기간이 차감됩니다. 단, 여름 프로모션 기간 내 구매 시, 이벤트 혜택으로 2024년 9월 2일
          00시부터 수강기간이 차감됩니다.
        </li>
        <li className="li-tag-inside">
          구매 시점부터 주 1회 이상 수업을 수강하지 않을 경우 수업권 1회가 자동으로 차감됩니다.
        </li>
        <li className="li-tag-inside">
          매주 수업 듣기가 어려운 경우 수업권 구매 시 제공되었던 수업 연기 혜택이 자동 적용됩니다. 수업을 듣지 못한
          주차가 지나면 수업권이 차감되지 않고, 수강기간이 7일 연장됩니다.
        </li>
        <li className="li-tag-inside">
          꾸준한 영어 공부 습관을 들일 수 있도록, 링글 계정에 저장된 휴대폰 번호로 매주 수업 독려 알림을 보내드립니다.
        </li>
        <li className="li-tag-inside">
          수업 시작 시점으로부터 24시간 이내 튜터의 수업 취소/노쇼/미배정으로 인해 예정된 수업이 취소될 경우, 해당 주차
          수업권은 차감되지 않습니다.
        </li>
        <li className="li-tag-inside">
          수강기간은 연장되지 않으며, 20분/40분 수업 길이 변경 기능을 지원하지 않습니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">Recommended for users who wish to study English consistently every week.</li>
        <li className="li-tag-inside">
          The countdown towards the expiration of lesson credits begins immediately upon purchase. However, if purchased
          during the 24 summer promotion period, your credit expiration will not be deducted until you start your first
          lesson, which can be done any time before September 1, 2024.
        </li>
        <li className="li-tag-inside">
          One credit will be automatically deducted each week, even if no lessons are taken.
        </li>
        <li className="li-tag-inside">
          If you have difficulty taking lessons every week, the deferral benefit will be automatically applied. When a
          week passes without attending a lesson, no credit will be deducted, and the expiration date will be extended
          by 7 days.
        </li>
        <li className="li-tag-inside">
          We send weekly lesson encouragement notifications to the mobile number of your Ringle account if a credit is
          to be deducted.
        </li>
        <li className="li-tag-inside">
          If a lesson is cancelled within 24 hours of its scheduled start time due to reasons like tutor cancellation,
          no-show, or non-assignment, no credit will be deducted for that week.
        </li>
        <li className="li-tag-inside">
          Validity period extensions or exchanges between 20-minute and 40-minute credits are not supported for this
          credit type.
        </li>
      </>,
      's_pricing.qna.new_list2_content',
      <>
        <li className="li-tag-inside">如果计划每週规律上课，我们十分推荐每週课程券</li>
        <li className="li-tag-inside">从购买课程券后立即开始计算使用期限</li>
        <li className="li-tag-inside">从购买后，如果一週没有进行一次以上的课程，课程券将自动被扣除</li>
        <li className="li-tag-inside">
          如果每周上课有困难，购买课程时提供的课程延期福利将自动适用。未上课的那一周过后，不会扣除课时，学习期限将延长7天。
        </li>
        <li className="li-tag-inside">我们将发送上课提醒至您注册Ringle使用的手机</li>
        <li className="li-tag-inside">如果在课程开始前24小时有教师取消/教师未出席的情况，课程券将不被扣除</li>
        <li className="li-tag-inside">每週课程券无法变更上课时间，进行20分/40分课程互换</li>
      </>,
      <>
        <li className="li-tag-inside">如果計劃每週規律上課，我們十分推薦每週課程券</li>
        <li className="li-tag-inside">從購買課程券後立即開始計算使用期限</li>
        <li className="li-tag-inside">從購買後，如果一週沒有進行一次以上的課程，課程券將自動被扣除</li>
        <li className="li-tag-inside">
          如果每週上課有困難，購買課程時提供的課程延期福利將自動適用。未上課的那一週過後，不會扣除課時，學習期限將延長7天。
        </li>
        <li className="li-tag-inside">我們將發送上課提醒至您註冊Ringle使用的手機</li>
        <li className="li-tag-inside">如果在課程開始前24小時有教師取消/教師未出席的情況，課程券將不被扣除</li>
        <li className="li-tag-inside">每週課程券無法變更上課時間，進行20分/40分課程互換</li>
      </>,
      <>
        <li className="li-tag-inside">毎週コツコツと英語学習をしたい方におすすめです。</li>
        <li className="li-tag-inside">レッスンチケットを購入するとすぐに受講期間が差し引かれます。.</li>
        <li className="li-tag-inside">
          購入時点から週1回以上レッスンを受講しない場合、レッスンチケット1枚が自動的に差し引かれます。
        </li>
        <li className="li-tag-inside">
          毎週授業を受けるのが難しい場合、授業権購入時に提供された授業延期特典が自動的に適用されます。授業を受けられなかった週が過ぎても、授業権は差し引かれず、受講期間が7日間延長されます。
        </li>
        <li className="li-tag-inside">
          Ringleアカウントに保存されている携帯電話番号に、レッスンチケットが引き落とされる予定がある場合、毎週レッスンの催促をお知らせします。
        </li>
        <li className="li-tag-inside">
          レッスン開始から24時間以内にチューターのキャンセル/ノーショー/未手配などやむを得ない事由が発生した場合、該当のレッスンチケットは費消されません。
        </li>
        <li className="li-tag-inside">
          受講期間の延長はできず、20分/40分のレッスン時間変更機能はサポートされていません。
        </li>
      </>,
      <>
        <li className="li-tag-inside">Recommended for users who wish to study English consistently every week.</li>
        <li className="li-tag-inside">
          The countdown towards the expiration of lesson credits begins immediately upon purchase.
        </li>
        <li className="li-tag-inside">
          One credit will be automatically deducted each week, even if no lessons are taken.
        </li>
        <li className="li-tag-inside">
          If you have difficulty attending weekly lessons, you can use the deferral benefit to restore a deducted
          credit. Please contact the Ringle team to utilize this benefit. (This benefit is available once for 12
          Credits, thrice for 24 Credits, ten times for 48 Credits, and twenty-four times for 100 Credits.)
        </li>
        <li className="li-tag-inside">
          We send weekly lesson encouragement notifications to the mobile number of your Ringle account if a credit is
          to be deducted.
        </li>
        <li className="li-tag-inside">
          If a lesson is cancelled within 24 hours of its scheduled start time due to reasons like tutor cancellation,
          no-show, or non-assignment, no credit will be deducted for that week.
        </li>
        <li className="li-tag-inside">
          Validity period extensions or exchanges between 20-minute and 40-minute credits are not supported for this
          credit type.
        </li>
      </>,
    ],
    new_list3_title: [
      `수업권의 수강기간이 궁금해요.`,
      `What are the validity periods for the credits?`,
      's_pricing.qna.new_list3_title',
      `想要知道课程券能够使用的时间`,
      `想要知道課程券能夠使用的時間`,
      `レッスンチケットの受講期間が知りたいです。`,
      `Thắc mắc về thời lượng khóa học`,
    ],
    new_list3_content: [
      <>
        <li className="li-tag-inside">
          링글 수업권의 수강 기간은 수강을 완료하는 시점 기준입니다. 자유 수강 수업권은, 수업권 구매 이후 첫 수업을
          완료한 일자로부터 수강기간 차감이 시작됩니다.(단, 구매 후 1년이 지난 시점부터는 자동 차감)
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            수강기간 연장은 웹(PC)에서만 가능하며, 로그인 후 우상단 [프로필] - [내 수업권] - [수강기간 연장]에서 포인트
            사용 또는 소액 결제를 통해 가능합니다. (잔여 수강기간 1/2이하 시점부터 수강기간 연장이 가능합니다.)
          </li>
          <li className="li-tag-inside">
            30만원 이상의 수업권을 재구매하시면, 보유한 수업권의 처음 수강기간을 추가로 연장할 수 있는 [수강기간 연장
            쿠폰]이 발급됩니다.
          </li>
        </div>
        <li className="li-tag-inside">
          매주 수업 듣기 수업권은 결제 시점부터 수강기간이 차감되며, 포인트 사용 및 수업권 재구매를 통한 수강기간 연장을
          지원하지 않습니다. 단, 여름 프로모션 기간 내 구매 시, 2024년 9월 2일 00시부터 수강기간 차감이 시작됩니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          [Flexible Credits] will only start to expire after you complete your first lesson (or one year after the
          purchase date, if no lessons are completed).
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            You can extend these credits using your points or by making a payment at [Profile] - [Credits/Certificates]
            - [Extend Credits].
          </li>
          <li className="li-tag-inside">
            You will be issued an [Extension Coupon] for extending the original validity period of your existing credits
            when you make another purchase of credits worth $230.76 or more.
          </li>
        </div>
        <li className="li-tag-inside">
          [Every Week Credits] start expiring immediately after purchase, and their validity period cannot be extended
          using points or coupons. However, if purchased during the 24 summer promotion period, your credit expiration
          will not be deducted until you start your first lesson, which can be done any time before September 1, 2024.
        </li>
      </>,
      's_pricing.qna.new_list3_content',
      <>
        <li className="li-tag-inside">
          自由课程券在购买后，首次上课之前不会扣除課程券使用期限。但自购买之日起1年后将自动扣除。
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            您可以通过使用积分或小额支付来延長课程使用期限，方法是由 [个人资料] - [课程券/证明材料] - [延长学习期限]。
          </li>
          <li className="li-tag-inside">
            如果重新购买了超过30万韩元的课程券，将会发放 [延长课程券使用期限优惠券]，以额外延长持有课程券的使用期限。
          </li>
        </div>
        <li className="li-tag-inside">
          每周上课券在付款时开始计算使用期限，并且不支持使用积分或重新购买课程券来延长使用期限。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          自由課程券在購買後，首次上課之前不會扣除課程券使用期限。但自購買之日起1年後將自動扣除。
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            您可以使用點數或小額支付來延長課程使用期限，方法是由 [個人資料] - [課程券/證明] - [延長學習期限]。
          </li>
          <li className="li-tag-inside">
            如果重新購買了超過30萬韓元的課程券，將會發放 [延長課程券使用期限優惠券]，以額外延長持有課程券的使用期限。
          </li>
        </div>
        <li className="li-tag-inside">
          每週上課券在付款時開始計算使用期限，並且不能使用點數或重新購買課程券來延長使用期限。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          自由受講のレッスンチケットは、購入後最初の授業開始までは受講期間が差し引かれませんが、1年が経過すると自動で受講期間が差し引かれます。
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            受講期間の延長は、【プロフィール】 - 【レッスンチケット/証明書類】 -
            【受講期間延長】でポイントを使用する、あるいは少額の支払いを行うと可能です。.
          </li>
          <li className="li-tag-inside">
            30万ウォン相当額以上のレッスンチケットを再購入すると、保有しているレッスンチケットの最初の受講期間を追加で延長できる【受講期間延長クーポン】が発行されます。
          </li>
        </div>
        <li>
          毎週授業を受けるレッスンチケットは、支払い時点から受講期間が差し引かれます。ポイント使用、または、レッスンチケットを再購入して受講期間を延長することはできません。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Thời lượng học lớp tự do sẽ không trừ trước khi bắt đầu buổi học đầu tiên. Hệ thống tự động trừ sau 1 năm mua
          gói học.
        </li>
        <div className="li-tag-inside">
          <li className="li-tag-inside">
            Mua thêm hoặc dùng điểm tích lũy để gia hạn thời gian học bằng cách vào [Profile] - [Gói khóa học / Chứng
            chỉ] - [Gia hạn khóa học]
          </li>
          <li className="li-tag-inside">
            Nếu mua thêm gói học có giá trị từ 300,000 won trở lên, [Phiếu giảm giá gia hạn khóa học] sẽ được phát hành
            để gia hạn thêm thời gian cho gói học đầu tiên mà bạn đã mua.
          </li>
        </div>
        <li className="li-tag-inside">
          Thời hạn của lớp học hàng tuần được tính kể từ thời điểm thanh toán và không hỗ trợ việc sử dụng điểm tích lũy
          hoặc mua lại gói học để gia hạn thời gian học.
        </li>
      </>,
    ],
    new_list4_title: [
      `수업 취소 정책이 궁금해요.`,
      `What happens when I cancel my lesson?`,
      's_pricing.qna.new_list4_title',
      `想知道取消课程政策`,
      `想知道課程取消政策`,
      `授業のキャンセルポリシーが知りたいです。`,
      `Thắc mắc về chính sách hủy gói học`,
    ],
    new_list4_content: [
      <>
        <li className="li-tag-inside">수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.</li>
        <li className="li-tag-inside">
          수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.
        </li>
        <li className="li-tag-inside">
          수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다. (당일수업권으로
          예약한 수업 취소 시에는 수업권이 차감되며 추가 수업권은 지급되지 않습니다.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          For cancellations made with more than 24 hours left before the lesson is scheduled to start, the corresponding
          credit will be automatically restored.
        </li>
        <li className="li-tag-inside">
          For cancellations made within 24 hours up to 2 hours before the lesson is scheduled to start, a [Last-minute
          Credit] will be issued instead of restoring the corresponding credit.
        </li>
        <li className="li-tag-inside">
          For cancellations within 2 hours before the lesson is scheduled to start, neither will the corresponding
          credit be restored nor a [Last-minute Credit] be issued.
        </li>
      </>,
      's_pricing.qna.new_list4_content',
      <>
        <li className="li-tag-inside">如果在课程开始前24小时之前取消，该课程券将自动恢复。</li>
        <li className="li-tag-inside">如果在课程开始前24到2小时之前取消，将扣除该课程券并发放[当日课程券]。</li>
        <li className="li-tag-inside">如果在课程开始前2小时之内取消，将扣除该课程券，但不会发放[当日课程券]。</li>
      </>,
      <>
        <li className="li-tag-inside">如果在課程開始前24小時之前取消，該課程券將自動恢復。</li>
        <li className="li-tag-inside">如果在課程開始前24到2小時之前取消，將扣除該課程券並發放[當日課程券]。</li>
        <li className="li-tag-inside">如果在課程開始前2小時之內取消，將扣除該課程券，但不會發放[當日課程券]。</li>
      </>,
      <>
        <li className="li-tag-inside">
          授業開始の24時間より前にキャンセルすると、該当するレッスンチケットは自動的に復元されます。
        </li>
        <li className="li-tag-inside">
          授業開始の24〜2時間前にキャンセルすると、該当するレッスンチケットが差し引かれた後、[当日レッスンチケット]が支給されます。
        </li>
        <li className="li-tag-inside">
          授業開始前2時間以内にキャンセルすると、該当するレッスンチケットは差し引かれ、[当日レッスンチケット]は支給されません
        </li>
      </>,
      <>
        <li className="li-tag-inside"> Nếu hủy trước 24H trước khi khóa học bắt đầu, gói học sẽ tự động khôi phục.</li>
        <li className="li-tag-inside">
          {' '}
          Nếu hủy trong khoảng 24 đến 2 giờ trước khi khóa học bắt đầu, gói khóa học sẽ được khấu trừ và bạn sẽ nhận
          được một 'Thẻ học trong ngày'.
        </li>
        <li className="li-tag-inside">
          Nếu hủy trong vòng 2 giờ trước khi khóa học bắt đầu, gói khóa học sẽ bị khấu trừ và bạn sẽ không nhận được
          'thẻ học trong ngày'.
        </li>
      </>,
    ],
    new_list5_title: [
      `수업 환불 정책이 궁금해요.`,
      `What is Ringle's refund policy?`,
      's_pricing.qna.new_list5_title',
      `想了解课程券退款政策。`,
      `我想了解課程券退款政策。`,
      `レッスン料金の返金は可能でしょうか？`,
      `Làm sao để được hoàn tiền?`,
    ],
    new_list5_content: [
      <>
        <li className="li-tag-inside">
          수강기간 차감 전, 수업권 사용 이력이 없는 경우 전액 환불이 가능합니다. 무료 수업권 및 체험 수업을 포함한 첫
          수업 1회 진행 후 불만족 시에도 전액 환불이 가능합니다.
        </li>
        <li className="li-tag-inside">
          수강기간 연장 및 수업권 변경 이력이 있는 경우 또는 증명서를 발급한 경우 환불이 불가합니다.
        </li>
        <li className="li-tag-inside">
          가격 할인 외 혜택이 있는 이벤트 통해 수업권 구매 후 해당 혜택을 사용한 경우 환불이 불가합니다.
        </li>
        <li className="li-tag-inside">
          환불 금액 산정 시, 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료가 차감될 수 있습니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          A full refund is possible for credits that have not begun to expire and have not been used at all. We also
          offer a full refund if you are not satisfied after the first lesson, including those taken using a free credit
          or a trial credit.
        </li>
        <li className="li-tag-inside">
          Credits for which extensions or changes have been made, or certificates issued, are non-refundable.
        </li>
        <li className="li-tag-inside">
          Credits purchased through events offering additional benefits beyond a discount are non-refundable once those
          benefits are used.
        </li>
        <li className="li-tag-inside">
          Processing fees charged by payment agencies, such as banks, credit card companies, and Paypal, may be deducted
          from the final refund amount.
        </li>
      </>,
      's_pricing.qna.new_list5_content',
      <>
        <li className="li-tag-inside">
          在扣除课程券使用期限之前，如果课程券都尚未使用，可以全额退款。包括免费课程券和体验课程，在进行第一次课程后如果不满意，也可以全额退款。
        </li>
        <li className="li-tag-inside">
          在扣除课程券使用之后，剩余使用期限或课程次数少于一半的课程券无法退款。其他有使用记录的课程券将根据使用条款第13和第15条进行部分退款处理。
        </li>
        <li className="li-tag-inside">有延长课程券使用时间的课程券或已发放上课证明的的课程券无法退款。</li>
        <li className="li-tag-inside">在计算退款金额时，可能会扣除银行、信用卡公司、PayPal等支付方式的手续费。</li>
      </>,
      <>
        <li className="li-tag-inside">
          在扣除課程券使用時間之前，如果課程券都尚未使用，可以全額退款。包括免費課程券和體驗課程，在進行第一次課程後如果不滿意，也可以全額退款。
        </li>
        <li className="li-tag-inside">
          在扣除課程券使用之後，剩余使用期限或課程次數少於一半的課程券無法退款。其他有使用記錄的課程券將根據使用條款第13和第15條進行部分退款處理。
        </li>
        <li className="li-tag-inside">有延長課程券使用時間的課程券或已發放上課證明的的課程券無法退款。</li>
        <li className="li-tag-inside">在計算退款金額時，可能會扣除銀行、信用卡公司、PayPal等支付方式的手續費。</li>
      </>,
      <>
        <li className="li-tag-inside">
          受講期間の差し引き前に、レッスンチケットの使用履歴がない場合は全額返金が可能です。無料のレッスンチケットや体験授業を含む1回目の授業を受講した後にも、サービスについてご満足いただけない場合は全額返金が可能です。
        </li>
        <li className="li-tag-inside">
          受講期間の差し引き後、受講期間または授業回数が半分以下になったレッスンチケットは返金不可です。それ以外の使用履歴があるレッスンチケットは利用規約の第13条および第15条に基づいて部分的な返金処理が行われます。
        </li>
        <li className="li-tag-inside">
          受講期間の延長履歴があるレッスンチケットまたは証明書を発行したレッスンチケットは返金不可です。
        </li>
        <li className="li-tag-inside">
          返金金額の算定時に、銀行、カード会社、PayPalなどの支払い手段に対する手数料が差し引かれる場合があります。
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Nếu không có lịch sử sử dụng gói học trước khi trừ thời gian học, bạn có thể được hoàn lại toàn bộ tiền. Ngay
          cả sau khi tham gia một buổi học miễn phí hoặc buổi học thử đầu tiên, nếu không hài lòng, bạn cũng có thể được
          hoàn lại toàn bộ tiền.{' '}
        </li>
        <li className="li-tag-inside">
          Không được hoàn tiền đối với trường hợp gói học đã sử dụng hơn một nửa thời lượng gói học. Đối với các gói học
          có lịch sử sử dụng khác, chúng tôi sẽ xử lý hoàn trả một phần theo Điều khoản 13 và 15 của Điều khoản sử dụng.
        </li>
        <li className="li-tag-inside">
          {' '}
          Không thể hoàn lại tiền cho gói học có lịch sử gia hạn thời gian học hoặc đã cấp giấy chứng nhận.
        </li>
        <li className="li-tag-inside">
          Số tiền hoàn lại có thể đã được dùng để trừ đi phí của ngân hàng, công ty thẻ tín dụng, PayPal và các phương
          thức thanh toán khác.
        </li>
      </>,
    ],
    new_list6_title: [
      `수업 증명서를 발급 받을 수 있나요?`,
      `Can I get certificates for Ringle lessons?`,
      's_pricing.qna.new_list6_title',
      `可以发放上课证明吗？`,
      `可以發放上課證明嗎？`,
      `レッスン証明書を発行できますか？`,
      `Có được nhận chứng chỉ đã được cấp không?`,
    ],
    new_list6_content: [
      <>
        <li>
          증명서는 웹(PC)을 통해서만 발급 가능하며, 로그인 후 우상단{' '}
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/ko/student/portal/mypage/credit"
          >
            [프로필] {'>'} [수업권/증명서류] {'>'} [증명서 발급]
          </a>
          에서 수강증, 출석증, 또는 영수증을 직접 발급할 수 있습니다.
        </li>
        <li>수강증 및 출석증은 월별로 나누어 발급 할 수 있습니다.</li>
        <li>
          증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 발급 할 수 있습니다.{' '}
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificates'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            증명서류 예시 상세보기
          </a>
        </li>
      </>,
      <>
        <li>
          You can get certificates issued directly at
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/en/student/portal/mypage/credit"
          >
            [Profile] - [Credits/Certificates] - [Issue Certificate].
          </a>
        </li>
        <li>Lesson certificates and proof of attendance can be issued monthly.</li>
        <li>
          Certificates can only be issued for purchased credits and lessons completed without anomalies.
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificates-en'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            View certificate samples
          </a>
        </li>
      </>,
      's_pricing.qna.new_list6_content',
      <>
        <li>
          证明文件的发放可在
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/en/student/portal/mypage/credit"
          >
            [个人资料] - [课程券/证明] - [开立证明/收据]
          </a>
          中直接发放学习证明、出席证明或收据。
        </li>
        <li>学习证明和出席证明可以按月发放。</li>
        <li>
          证明书只能针对实际支付的课程券和正常进行的课程发放。
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificates-en'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            请参照证明文件范例
          </a>
        </li>
      </>,
      <>
        <li>
          證明文件的發放可在
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/en/student/portal/mypage/credit"
          >
            [個人資料] - [課程券/證明] - [開立證明/收據]
          </a>
          中直接發放學習證明、出席證明或收據。
        </li>
        <li>學習證明和出席證明可以按月發放。</li>
        <li>
          證明書只能針對實際支付的課程券和正常進行的課程發放。
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificatesp-en'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            請參照證明文件範例。
          </a>
        </li>
      </>,
      <>
        <li>
          証明書の発行は、
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/en/student/portal/mypage/credit"
          >
            【プロフィール】 - 【レッスンチケット/証明書類】 - [証明書発行]
          </a>
          から、受講証、出席証、または領収書を発行することができます。
        </li>
        <li>受講証および出席証は月ごとに分けて発行することができます。</li>
        <li>
          証明書は実際に支払ったレッスンチケットおよび正常に進行された授業に対してのみ発行することができます。
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificatesp-ja'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            証明書類の例を詳細を見る
          </a>
        </li>
      </>,
      <>
        <li>
          Có thể nhận chứng chỉ tại mục
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/en/student/portal/mypage/credit"
          >
            [Profile] - [Gói khóa học/Chứng chỉ] - [Cấp chứng chỉ]
          </a>
          ở đây có thể nhận được chứng chỉ Tham dự học, chứng chỉ chuyên cần hoặc Hóa đơn.
        </li>
        <li>Có chứng chỉ Tham dự học và chứng chỉ chuyên cần theo từng tháng.</li>
        <li>
          Chứng chỉ chỉ được cấp cho các gói học phí đã thanh toán và các khóa học đã diễn ra bình thường.
          <a
            href={'https://www.ringleplus.com/ko/student/landing/blog/faq-attendance-certificatesp-ja'}
            target="_blank"
            className="ml-[4px] text-purple-500"
            rel="noreferrer"
          >
            Xem chi tiết mẫu chứng chỉ tại đây.
          </a>
        </li>
      </>,
    ],
  },
  see_detail: {
    credit_info: [
      `수업권 정보`,
      `Credits detail`,
      's_pricing.see_detail.credit_info',
      `课程券详情`,
      `課程券詳情`,
      `チケット料金内訳`,
      `Chi tiết thẻ học`,
    ],
    total_credits: [
      `최종 수업권 횟수`,
      `Total credits`,
      's_pricing.see_detail.total_credits',
      `最终课程券次数`,
      `最終課程券次數`,
      `合計レッスン回数`,
      `Tổng số buổi học`,
    ],
    regular: [
      `정규수업권`,
      `Regular Credits`,
      's_pricing.see_detail.regular',
      `我的英语实时可视化`,
      `即時將我的英語視覺化`,
      `自分の英語がその場で校正される`,
      `Xem phần sửa lỗi, tài liệu học và gia sư cùng lúc`,
    ],
    sameday: [
      `당일수업권`,
      `Last-minute Credits`,
      's_pricing.see_detail.sameday',
      `24小时以内的补充课程`,
      `24小時以內的補充課程`,
      `24時間以内の当日レッスン`,
      `Buổi học trong 24 giờ tới`,
    ],
    credit_price: [
      `가격 상세 내역`,
      `Price detail`,
      's_pricing.see_detail.credit_price',
      `价格详情`,
      `價格詳情`,
      `価格の詳細`,
      `Chi tiết giá cả`,
    ],
    normal_price: [
      `정가`,
      `Regular price`,
      's_pricing.see_detail.normal_price',
      `定价`,
      `定價`,
      `通常価格`,
      `Học phí tiêu chuẩn`,
    ],
    discount_price: [
      `총 할인 금액`,
      `Total discount`,
      's_pricing.see_detail.discount_price',
      `总优惠金额`,
      `總折扣金額`,
      `割引分合計`,
      `Tổng giảm giá`,
    ],
    discount_promo: [
      `프로모션 할인`,
      `Promo discount`,
      's_pricing.see_detail.discount_promo',
      `促销折扣`,
      `促銷折扣`,
      `プロモーション割引`,
      `Khuyến mãi giảm giá`,
    ],
    discount_secret: [
      `시크릿 쿠폰 할인`,
      `Secret offer`,
      's_pricing.see_detail.discount_secret',
      `Secret offer`,
      `Secret offer`,
      `Secret offer`,
      `Secret offer`,
    ],
    discount_welcome: [
      `웰컴 쿠폰 할인`,
      `Welcome coupon`,
      's_pricing.see_detail.discount_welcome',
      `Welcome优惠券`,
      `Welcome優惠券`,
      `ウェルカムクーポン`,
      `Coupon chào mừng`,
    ],
    final_price: [
      `최종 결제 금액`,
      `Your total`,
      's_pricing.see_detail.final_price',
      `实际付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      `Tổng cộng`,
    ],
    unit_price: [
      `수업 1회당 가격`,
      `Final price per credit`,
      's_pricing.see_detail.unit_price',
      `实际课程单价`,
      `實際單次單價`,
      `1レッスンあたりの単価`,
      `Giá cuối cho một buổi học`,
    ],
    my_points: [
      `사용 가능 포인트`,
      `My points`,
      's_pricing.see_detail.my_points',
      `持有积分`,
      `持有點數`,
      `保有ポイント`,
      `Điểm của tôi`,
    ],
    discount_package: [
      `패키지 할인`,
      `Package discount`,
      's_pricing.see_detail.discount_package',
      `礼包折扣`,
      `禮包折扣`,
      `パッケージ割引`,
      `Giảm giá gói hàng`,
    ],
    discount_first: [
      `첫 구매 혜택`,
      `First purchase benefit`,
      's_pricing.see_detail.discount_first',
      `首次购买优惠`,
      `首次購買折扣優惠`,
      `初回購入割引特典`,
      `Giảm giá cho học viên mới`,
    ],
    discount_re: [
      `재구매 쿠폰 할인`,
      `Next purchase coupon`,
      's_pricing.see_detail.discount_re',
      `下次购买优惠券`,
      `下次購買優惠券`,
      `次の購入クーポン`,
      `Phiếu mua hàng tiếp theo`,
    ],
    discount_special: [
      `특별 할인`,
      `Special offer`,
      's_pricing.see_detail.discount_special',
      ``,
      ``,
      `特別割引`,
      `Special offer`,
    ],
    discount_student_2023: [
      `대학생 할인`,
      `Student discount`,
      's_pricing.see_detail.discount_student_2023',
      `Student discount`,
      `Student discount`,
      `大学生割引`,
      `Student discount`,
    ],
    discount_weekly_one_lesson: [
      `매주 수업 듣기 할인`,
      `Every Week discount`,
      's_pricing.see_detail.discount_weekly_one_lesson',
      `每週上课折扣`,
      `每週上課折扣`,
      `毎週授業を受ける割引`,
      `Giảm giá tham dự lớp học hàng tuần`,
    ],
    discount_6_lessons_per_month: [
      `월 6회 할인`,
      `6 lessons/month discount`,
      's_pricing.see_detail.discount_6_lessons_per_month',
      `一个月上课6次折扣`,
      `一個月上課6次折扣`,
      `月6回割引`,
      `Giảm giá 6 buổi học/ tháng`,
    ],
    discount_point_use: [
      `포인트 사용`,
      `Points applied`,
      's_pricing.see_detail.discount_point_use',
      `使用积分`,
      `使用點數`,
      `ポイント利用`,
      `Điểm được áp dụng`,
    ],
    cancel: [`취소`, `Cancel`, 's_pricing.see_detail.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
    purchase: [`결제`, `Purchase`, 's_pricing.see_detail.purchase', `付款`, `付款`, `決済する`, `Thanh toán`],
    actual_payment_unit_price_desc_for_mobile: [
      `정규수업권과 당일수업권을 더한 횟수를 최종 결제 금액으로 나눈 단가입니다.`,
      `The final payment total divided by the total number of regular and last-minute credits`,
      's_pricing.see_detail.actual_payment_unit_price_desc_for_mobile',
      `这是将正規课程券和当日课程券的总次数除以最终支付金额得出的单价。`,
      `這是將正規課程券和當日課程券的總次數除以最終支付金額得出的單價。`,
      `定期レッスンチケットと当日レッスンチケットの回数を合計して最終支払金額で割った単価です。`,
      `Đây là giá đơn vị được tính bằng cách chia tổng số lần mua gói học thường và gói học trong ngày cho số tiền thanh toán cuối cùng`,
    ],
    based_on: (number) => [
      `${number}회 기준`,
      `for ${number} lessons`,
      's_pricing.see_detail.based_on',
      `以 ${number}次为基准`,
      `以 ${number}次為準`,
      `${number}回を基準`,
      `Theo ${number} buổi`,
    ],
    expected_point_desc_for_mobile: [
      `정규 수업 완료 7일 후 실 결제 금액의 3% 적립`,
      `3% of the per lesson price rewarded as points 7 days after completing a regular lesson`,
      's_pricing.see_detail.expected_point_desc_for_mobile',
      `在完成正規课程后的7天内，将实际支付金额的3% 轉換为积分累積。`,
      `在完成正規課程後的7天內，將實際支付金額的3% 轉換為點數累積。`,
      `レギュラーレッスンの受講7日後に支払金額の3％分を獲得`,
      `3% số tiền thanh toán thực tế được tích lũy sau 7 ngày hoàn thành gói học thường`,
    ],
  },
  label_first: [`첫 구매`, `First`, 's_pricing.label_first', `First`, `First`, `First`, `First`],
  label_size: [
    `장기권`,
    `Size Benefits`,
    's_pricing.label_size',
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
  ],
  total_sale_rate: [
    `총 할인율`,
    `Total discount`,
    's_pricing.total_sale_rate',
    `总贴现率`,
    `總貼現率`,
    `総割引率`,
    `chiết khấu`,
  ],
  by_month: [`월`, `mo`, 's_pricing.by_month', `月`, `月`, `月`, `tháng`],
  promo_best_price: [
    `프로모션 최대 할인 적용`,
    `Biggest discount appplied`,
    's_pricing.promo_best_price',
    `Biggest discount appplied`,
    `Biggest discount appplied`,
    `Biggest discount appplied`,
    `Biggest discount appplied`,
  ],
  welcome_best_price: [
    `웰컴 쿠폰 최대 혜택`,
    `Welcome Coupon`,
    's_pricing.welcome_best_price',
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
  ],
  first_price: [
    `첫 구매 혜택`,
    `First Purchase`,
    's_pricing.first_price',
    `First Purchase`,
    `First Purchase`,
    `First Purchase`,
    `First Purchase`,
  ],
  next_best_price: [
    `재구매 쿠폰 혜택`,
    `Next Purchase Coupon`,
    's_pricing.next_best_price',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  total_best_price: [
    `최대 혜택 적용가`,
    `Final dicounted price`,
    's_pricing.total_best_price',
    `最终价格`,
    `最終價格`,
    `最終価格`,
    `giá niêm yết`,
  ],
  card_recommended: [
    `추천`,
    `Recommended`,
    's_pricing.card_recommended',
    `Recommended`,
    `Recommended`,
    `Recommended`,
    `Recommended`,
  ],
  season_best_price: [
    `8월･9월 한정 수업권`,
    `Seasonal offer`,
    's_pricing.season_best_price',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  label_season: [`기간한정`, `Season`, 's_pricing.label_season', `Season`, `Season`, `Season`, `Season`],
  card_size: [
    `장기권 혜택`,
    `Size Benefits`,
    's_pricing.card_size',
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
  ],
  season_discount: [
    `8월･9월 한정 특가 할인`,
    `Seasonal offer`,
    's_pricing.season_discount',
    `追加优惠`,
    `額外折扣`,
    `追加割引`,
    `Ưu đãi bổ sung`,
  ],
  notice_usd: [
    `결제는 미국 달러로 진행됩니다. 괄호안의 통화별 금액은 당일 환율이 반영됩니다.`,
    <>Payments will be processed in USD. The price shown in your local currency reflects today's exchange rate.</>,
    's_pricing.notice_usd',
    `支付方式以美元为准。括号内的各货币金额以当天汇率为准。`,
    `付款將以美金支付。括號內的各貨幣金額以當天匯率計算。`,
    `米ドルでの決済になります。カッコ内金額は当日のレートが適用されます。`,
    `Được thanh toán bằng USD. Số tiền theo tiền tệ trong ngoặc là tỷ giá hối đoái hôm nay.`,
  ],
  notice_exchange: [
    `실결제 금액은 카드사 적용 환율 및 수수료에 따라 차이가 발생할 수 있습니다.`,
    <>
      The final transaction price may differ due to the currency exchange rate and processing fee applied by your credit
      card company.
    </>,
    's_pricing.notice_exchange',
    `根据信用卡公司的适用汇率和手续费的不同，实际结算金额可能会有所差异。`,
    `根據信用卡公司的適用匯率和手續費的不同，實際結算金額可能會有所差異。`,
    <>決済額はカード会社が適用するレートや手数料によって差が生じます。</>,
    <>Số tiền thanh toán thực tế có thể khác nhau tùy theo tỷ giá hối đoái và lệ phí của công ty thẻ.</>,
  ],
  newbie_best_price: [
    `신규회원 추가 수업권 혜택`,
    `For Newcomers`,
    's_pricing.newbie_best_price',
    `新注册会员额外课程券优惠`,
    `新註冊會員額外課程券優惠`,
    `新規加入者特典`,
    `Ưu đãi thêm thẻ học chào mừng`,
  ],
  label_newbie: [`신규회원`, `Newcomer`, 's_pricing.label_newbie', `Newcomer`, `Newcomer`, `Newcomer`, `Newcomer`],
  benefit_newbie: [
    `0만원 추가 할인 적용`,
    `Additional ₩0 off`,
    's_pricing.benefit_newbie',
    `Additional ₩0 off`,
    `Additional ₩0 off`,
    `Additional ₩0 off`,
    `Additional ₩0 off`,
  ],
  label_trial_event: [
    `체험 수업 이벤트`,
    `Special Trial Lesson`,
    's_pricing.label_trial_event',
    `Special Trial Lesson`,
    `Special Trial Lesson`,
    `Special Trial Lesson`,
    `Special Trial Lesson`,
  ],
  newbie_discount: [
    `체험 특가 할인`,
    `Trial Discount`,
    's_pricing.newbie_discount',
    `Trial Discount`,
    `Trial Discount`,
    `Trial Discount`,
    `Trial Discount`,
  ],
  label_trial: [`체험수업`, `Trial`, 's_pricing.label_trial', `Trial`, `Trial`, `Trial`, `Trial`],
  benefit_trial: [
    `1회 수업권 95% 할인`,
    `95% discount`,
    's_pricing.benefit_trial',
    `95% discount`,
    `95% discount`,
    `95% discount`,
    `95% discount`,
  ],
  total_discount: [
    `총 할인율`,
    `Total discount`,
    's_pricing.total_discount',
    `总共折价`,
    `總共折價`,
    `総割引率`,
    `chiết khấu`,
  ],
  welcome_new_comer_best_price: [
    `신규 회원 최대 혜택`,
    `Newcomer Offer`,
    's_pricing.welcome_new_comer_best_price',
    `Newcomer Offer`,
    `Newcomer Offer`,
    `Newcomer Offer`,
    `Newcomer Offer`,
  ],
  season_best_price_2: [
    `10월･11월 한정 판매`,
    `Seasonal offer`,
    's_pricing.season_best_price_2',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  season_discount_2: [
    `10월･11월 한정 특가 할인`,
    `Seasonal offer`,
    's_pricing.season_discount_2',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  note_usd: {
    purchase_now: [
      `최종 결제는 미국 달러로 결제됩니다.`,
      `Payments will be processed in USD.`,
      's_pricing.note_usd.purchase_now',
      `支付方式以美元为准。`,
      `付款將以美金支付。`,
      `米ドルでの決済になります。`,
      `Được thanh toán bằng USD.`,
    ],
  },
  label_secret: [`시크릿쿠폰`, `Secret`, 's_pricing.label_secret', `Secret`, `Secret`, `Secret`, `Secret`],
  secret: [
    `시크릿 쿠폰 할인`,
    `Secret Offer`,
    's_pricing.secret',
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
  ],
  benefit_list_secret: (amount) => [
    `${amount} 추가 할인 적용`,
    `Extra ${amount} off`,
    's_pricing.benefit_list_secret',
    `Extra ${amount} off`,
    `Extra ${amount} off`,
    `Extra ${amount} off`,
    `Extra ${amount} off`,
  ],
  input_company_code_verified: [
    `등록한 기업 맞춤 수업권을 확인해 보세요.`,
    `Check out your B2B Partner credits.`,
    's_pricing.input_company_code_verified',
    `请确认您所属企业课程券`,
    `請確認您所屬企業課程券`,
    `貴社の受講券を確認してください。`,
    `Hãy xem buổi học phù hợp với doanh nghiệp đã đăng ký.`,
  ],
  verify_code: [`인증`, `Verify`, 's_pricing.verify_code', `验证`, `验证`, `Verify`, `Verify`],
  sameday_benefit: [
    `장기권 - 당일수업권 혜택`,
    `Last-minute Credits`,
    's_pricing.sameday_benefit',
    `当日课程券优惠`,
    `Last-minute Credits`,
    `補充レッスンチケット`,
    `Thẻ học bù`,
  ],
  promo_23_1st: {
    earlybirdbanner: [
      `지금 보고 있는 얼리버드 혜택이 곧 사라져요!`,
      `The Early Bird Offer will disappear soon!`,
      's_pricing.promo_23_1st.earlybirdbanner',
      `现在看到的早鸟特价优惠即将结束!`,
      `The Early Bird Offer will disappear soon!`,
      `The Early Bird Offer will disappear soon!`,
      `The Early Bird Offer will disappear soon!`,
    ],
    newcomerbanner: [
      `지금 적용된 신규회원 혜택,  nn:nn:nn 뒤에 사라져요!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      's_pricing.promo_23_1st.newcomerbanner',
      `此次新入会员优惠将于 nn:nn:nn 后结束！`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
    ],
    datechip_early: [
      `얼리버드 혜택`,
      `Early Bird`,
      's_pricing.promo_23_1st.datechip_early',
      `早鸟特价优惠`,
      `Early Bird`,
      `Early Bird`,
      `Early Bird`,
    ],
    datechip_regular: [
      `신년 프로모션`,
      `New Year Promotion`,
      's_pricing.promo_23_1st.datechip_regular',
      `正式活动`,
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
    ],
    date_early: [
      `22.12.23 (금) - 23.02.02 (목)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_early',
      `2022.12.23(周五)~2023.01.05(周四)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
    ],
    date_regular: [
      `23.01.06 (금) - 23.02.02 (목)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_regular',
      `2023.01.06(周五)~2023.02.02(周四)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    ],
    herotitle: [
      `가장 저렴하게 시작하는 23년 영어 정복`,
      `Study English at the Lowest Prices`,
      's_pricing.promo_23_1st.herotitle',
      `以全年最低的价格购买的机会`,
      `Study English at the Lowest Prices`,
      `Study English at the Lowest Prices`,
      `Study English at the Lowest Prices`,
    ],
    herosubtitle: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해 링글이 준비했어요.`,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle',
      `为了不能再推迟英语学习计划的你，我们准备好了。`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    herocard1_subtext: [
      `프로모션 한정 할인`,
      `New Year Promotion Only`,
      's_pricing.promo_23_1st.herocard1_subtext',
      `早鸟特价优惠`,
      `New Year Promotion Only`,
      `New Year Promotion Only`,
      `New Year Promotion Only`,
    ],
    herocard1_text: [
      `최대 39% 할인`,
      `Up to 39% off`,
      's_pricing.promo_23_1st.herocard1_text',
      `Up to 39% off`,
      `Up to 39% off`,
      `Up to 39% off`,
      `Up to 39% off`,
    ],
    n_herocard2_subtext: [
      `3시간 한정 신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.n_herocard2_subtext',
      `仅限于新入会员`,
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
    ],
    n_herocard2_text: [
      `수업권 추가 증정`,
      `Bonus Credits`,
      's_pricing.promo_23_1st.n_herocard2_text',
      `额外赠送课程券`,
      `Bonus Credits`,
      `Bonus Credits`,
      `Bonus Credits`,
    ],
    herocard2_subtext: [
      `보스턴&뉴욕 챌린지 혜택`,
      `Boston & NYC Challenge`,
      's_pricing.promo_23_1st.herocard2_subtext',
      `波士顿 & 纽约挑战赛优惠`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    herocard2_text: [
      `수업권 추가 증정`,
      `Bonus Credits`,
      's_pricing.promo_23_1st.herocard2_text',
      `额外赠送课程券`,
      `Bonus Credits`,
      `Bonus Credits`,
      `Bonus Credits`,
    ],
    herocard3_subtext: [
      `프로모션 구매자 한정`,
      `For purchasers only`,
      's_pricing.promo_23_1st.herocard3_subtext',
      `限于优惠活动购买者`,
      `For purchasers only`,
      `For purchasers only`,
      `For purchasers only`,
    ],
    herocard3_text: [
      `스터디 클럽`,
      `Join Study Club`,
      's_pricing.promo_23_1st.herocard3_text',
      `学习俱乐部`,
      `Join Study Club`,
      `Join Study Club`,
      `Join Study Club`,
    ],
    n_herorow1: [
      <>
        첫 2회 수업
        <br />
        불만족 시 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      's_pricing.promo_23_1st.n_herorow1',
      <>
        前 2 次课程
        <br />
        如果不满意 100% 退款
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
    ],
    herorow1: [
      <>
        수업권 구매 시<br />
        수강기간 연장 쿠폰 증정
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      's_pricing.promo_23_1st.herorow1',
      <>
        购买课程券时
        <br />
        赠送延长使用期限优惠券
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
    ],
    herorow2: [
      <>
        첫 수업 시작 전<br />
        수강 기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.promo_23_1st.herorow2',
      <>
        第一节课开始之前
        <br />
        不扣除使用期限
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
    ],
    herorow3: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      's_pricing.promo_23_1st.herorow3',
      <>
        截止到课程前一天
        <br />
        可变更日程
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
    ],
    pricecard_n_chip: [
      `시간 한정 신규회원 혜택`,
      `Bonus Credits for Newcomers`,
      's_pricing.promo_23_1st.pricecard_n_chip',
      `新入会员课程券额外优惠`,
      `Bonus Credits for Newcomers`,
      `Bonus Credits for Newcomers`,
      `Bonus Credits for Newcomers`,
    ],
    pricecard_chip: [
      `당일수업권 혜택`,
      `Last-minute Credits`,
      's_pricing.promo_23_1st.pricecard_chip',
      `当日课程券优惠`,
      `Last-minute Credits`,
      `Last-minute Credits`,
      `Last-minute Credits`,
    ],
    cta_early: [
      `얼리버드 프로모션 마감`,
      `Early Bird Promotion`,
      's_pricing.promo_23_1st.cta_early',
      `早鸟特价活动`,
      `Early Bird Promotion`,
      `Early Bird Promotion`,
      `Early Bird Promotion`,
    ],
    cta_regular: [
      `정규 프로모션 마감`,
      `New Year Promotion`,
      's_pricing.promo_23_1st.cta_regular',
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
    ],
    mo_herocard1_text: [
      `최대 39% 할인`,
      `Up to 39% off`,
      's_pricing.promo_23_1st.mo_herocard1_text',
      `Up to 39% off`,
      `Up to 39% off`,
      `Up to 39% off`,
      `Up to 39% off`,
    ],
    mo_n_herocard2_subtext: [
      `신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.mo_n_herocard2_subtext',
      `仅限于新入会员`,
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
    ],
    datechip_regular_mW: [
      `신년 프로모션`,
      `Promotion`,
      's_pricing.promo_23_1st.datechip_regular_mW',
      `正式活动`,
      `Promotion`,
      `Promotion`,
      `Promotion`,
    ],
    herotitle_mW: [
      <>
        가장 저렴하게 시작하는
        <br />
        23년 영어 정복
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      's_pricing.promo_23_1st.herotitle_mW',
      `以全年最低的价格购买的机会`,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
    ],
    herosubtitle_mW: [
      <>
        더 이상 영어 공부를 미룰 수 없는 당신을 위해
        <br />
        링글이 준비했어요.
      </>,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle_mW',
      `为了不能再推迟英语学习计划的你，我们准备好了。`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    main_logout1: [
      `최대 25만원 추가 할인에`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_logout1',
      `Up to $192 extra off`,
      `Up to $192 extra off`,
      `Up to $192 extra off`,
      `Up to $192 extra off`,
    ],
    main_logout2: [
      <>
        신규회원 한정
        <br />
        수업권 증정 혜택까지
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      's_pricing.promo_23_1st.main_logout2',
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
    ],
    main_countdown1: [
      `신년 프로모션 종료까지`,
      `Promotion Ends in`,
      's_pricing.promo_23_1st.main_countdown1',
      `Promotion Ends in`,
      `Promotion Ends in`,
      `Promotion Ends in`,
      `Promotion Ends in`,
    ],
    main_countdown2: [
      `일 남았어요`,
      `Days left`,
      's_pricing.promo_23_1st.main_countdown2',
      `Days left`,
      `Days left`,
      `Days left`,
      `Days left`,
    ],
    main_nocoupon1: [
      `최대 25만원 추가 할인`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_nocoupon1',
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
    ],
    main_nocoupon2: [
      <>
        신년 프로모션 혜택이
        <br />곧 사라져요.
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      's_pricing.promo_23_1st.main_nocoupon2',
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
    ],
  },
  cta_empty: [
    `수업권을 선택해 주세요.`,
    `Please select credit type.`,
    's_pricing.cta_empty',
    `请选择课程券`,
    `Please select credit type.`,
    `Please select credit type.`,
    `Please select credit type.`,
  ],
  modal_company_code1: [
    `로그인이 필요합니다.`,
    `You must log in to proceed.`,
    's_pricing.modal_company_code1',
    `You must log in to proceed.`,
    `You must log in to proceed.`,
    `You must log in to proceed.`,
    `You must log in to proceed.`,
  ],
  modal_company_code2: [
    `기업 맞춤 코드를 입력하시려면 로그인을 먼저 진행해주세요.`,
    `Please log in first to enter the B2B code.`,
    's_pricing.modal_company_code2',
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
  ],
  btn_company_code: [`로그인`, `Log in`, 's_pricing.btn_company_code', `Log in`, `Log in`, `Log in`, `Log in`],
  early_discount: [
    `기간 한정 할인율`,
    `Promotion Offer`,
    's_pricing.early_discount',
    `Promotion Offer`,
    `Promotion Offer`,
    `Promotion Offer`,
    `Promotion Offer`,
  ],
  card_newbie: [
    `신규회원 추천`,
    `For Newcomers`,
    's_pricing.card_newbie',
    `For Newcomers`,
    `For Newcomers`,
    `For Newcomers`,
    `For Newcomers`,
  ],
  newbie_best_price2: [
    <>추가 수업권 혜택 마감</>,
    <>Newcomers Benefit</>,
    's_pricing.newbie_best_price2',
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
  ],
  hour_timer: {
    label: (hour, min, sec) => [
      <>
        {hour}:{min}:{sec} 남음
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      's_pricing.hour_timer.label',
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
    ],
  },
  card_promotion_only: [
    `신년 프로모션 한정`,
    `Promotion Only`,
    's_pricing.card_promotion_only',
    `Promotion Only`,
    `Promotion Only`,
    `Promotion Only`,
    `Promotion Only`,
  ],
  popupcheck: [
    `브라우저 팝업이 차단된 경우 결제가 진행되지 않을 수 있습니다. 팝업 설정을 미리 확인해 주세요.`,
    `You may not be able to proceed with the purchase if pop-ups are blocked. Please make sure that pop-ups are enabled beforehand.`,
    's_pricing.popupcheck',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_subtitle_1: [
    `하나의 주제에 대해 심도있게`,
    `Topical & In-depth`,
    's_pricing.pricing_subtitle_1',
    `一个主题深度学习`,
    `一個主題深度學習`,
    `一つのテーマに関しより深く`,
    `chủ đề, chuyên sâu`,
  ],
  pricing_subtitle_2: [
    `40분 수업`,
    `40-min lessons`,
    's_pricing.pricing_subtitle_2',
    `40分钟课程`,
    `40分鐘課程`,
    `40分授業`,
    `các tiết học 40 phút`,
  ],
  pricing_subtitle_3: [
    `바쁜 일상 속 짧지만 확실하게`,
    `Short & Compact`,
    's_pricing.pricing_subtitle_3',
    `在繁忙的日常效率学习`,
    `在繁忙的日常效率學習`,
    `忙しい日常、短いけど確実に`,
    `ngắn gọn, súc tích`,
  ],
  pricing_subtitle_4: [
    `20분 수업`,
    `20-min lessons`,
    's_pricing.pricing_subtitle_4',
    `20分钟课程`,
    `20分鐘課程`,
    `20分授業`,
    `các tiết học 20 phút`,
  ],
  point_message: [
    `보유 포인트를 사용하면 할인된 가격으로 구매할 수 있어요.`,
    `Use your points toward this purchase.`,
    's_pricing.point_message',
    `使用持有点數取得優惠价格`,
    `使用持有點數獲得優惠價格`,
    `保有ポイントを使えば割引価格で購入できます。`,
    `Nếu sử dụng điểm tích lũy, bạn có thể được giảm giá.`,
  ],
  total: [
    `최종 결제 금액`,
    `Your total`,
    's_pricing.total',
    `实际付款金额`,
    `最終付款金額`,
    `お支払い総額`,
    `Tổng cộng`,
  ],
  next_purchase: {
    label: (benefit) => [
      <>재구매 쿠폰 {benefit} 추가 할인</>,
      <>Extra {benefit} off</>,
      's_pricing.next_purchase.label',
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
    ],
  },
  welcome: {
    label: (benefit) => [
      <>신규회원 ${benefit} 추가 할인</>,
      <>Extra ${benefit} off</>,
      's_pricing.welcome.label',
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
    ],
    label_new: (benefit) => [
      <>신규회원 {benefit} 추가 할인</>,
      <>Extra {benefit} off</>,
      's_pricing.welcome.label_new',
      <>新會員特價 {benefit} </>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
    ],
  },
  promotion_dday: {
    label: [
      <>신년 프로모션 마감까지 </>,
      <>Promotion ends in </>,
      's_pricing.promotion_dday.label',
      <>Promotion ends in </>,
      <>Promotion ends in </>,
      <>Promotion ends in </>,
      <>Promotion ends in </>,
    ],
  },
  specialoffer_price: {
    label: [
      `특별 할인 종료까지`,
      `Special offer ends in`,
      's_pricing.specialoffer_price.label',
      ``,
      ``,
      `特別割引終了まで`,
      `Special offer ends in`,
    ],
  },
  card_recommend: [
    `추천 수업권`,
    `Recommended`,
    's_pricing.card_recommend',
    `Recommended`,
    `Recommended`,
    `紹介レッスンチケット`,
    `Recommended`,
  ],
  student_price: [
    `학생 할인 수업권`,
    `Student discount`,
    's_pricing.student_price',
    `Student discount`,
    `Student discount`,
    `学生割引レッスンチケット`,
    `Student discount`,
  ],
  student_code: [
    `학생 할인 코드`,
    `Student discount code`,
    's_pricing.student_code',
    `Student discount code`,
    `Student discount code`,
    `学生割引コード`,
    `Student discount code`,
  ],
  input_student_code: [
    <>
      학생 할인 코드를 입력해보세요.
      <br />
      궁금한 점이 있다면 아래 문의하기로 알려주세요.
    </>,
    `Please enter student discount code.`,
    's_pricing.input_student_code',
    `Please enter student discount code.`,
    `Please enter student discount code.`,
    <>
      学割引コードを入力してください。
      <br />
      ご不明な点は下記よりお問い合わせください。
    </>,
    `Please enter student discount code.`,
  ],
  card_2023_student: [
    `2023 대학생 할인 수업권`,
    `2023 Student Discount`,
    's_pricing.card_2023_student',
    `2023 Student Discount`,
    `2023 Student Discount`,
    `2023大学生割引レッスンチケット`,
    `2023 Student Discount`,
  ],
  student_2023: {
    label: (benefit) => [
      <>대학생 {benefit} 추가 할인</>,
      <>Extra {benefit} off</>,
      's_pricing.student_2023.label',
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
    ],
  },
  label_size_student_2023: [
    `추가혜택`,
    `Special`,
    's_pricing.label_size_student_2023',
    `Special`,
    `Special`,
    `追加特典`,
    `Special`,
  ],
  student_package: {
    free_subsidy: (free) => [
      `${free}회 당일수업권 제공`,
      `${free} Last-minute Credits`,
      's_pricing.student_package.free_subsidy',
      `${free} Last-minute Credits`,
      `${free} Last-minute Credits`,
      `${free}回 当日レッスンチケット提供`,
      `${free} Last-minute Credits`,
    ],
    expirationstarts: [
      `구매 직후부터 수강기간 차감`,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      's_pricing.student_package.expirationstarts',
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
    ],
  },
  code: [
    `할인 코드를 입력해 주세요.`,
    `Please enter a discount code.`,
    's_pricing.code',
    `Please enter a discount code.`,
    `Please enter a discount code.`,
    `割引コードを入力してください。`,
    `Please enter a discount code.`,
  ],
  channel_talk_mobile: [
    `링글 할인 수업권 문의하기`,
    `Contact us`,
    's_pricing.channel_talk_mobile',
    `Contact us`,
    `Contact us`,
    `Ringle割引レッスンチケットのお問い合わせ`,
    `Contact us`,
  ],
  tossPayment: [`토스페이`, `Toss Pay`, 's_pricing.tossPayment', `Toss Pay`, `Toss Pay`, `Toss Pay`, `Toss Pay`],
  event: [`Event`, `Event`, 's_pricing.event', `Event`, `Event`, `Event`, `Event`],
  marchsale: {
    label: [
      `5월 한정 속성수업권 할인`,
      `May Special Sale`,
      's_pricing.marchsale.label',
      `May Special Sale`,
      `May Special Sale`,
      `May Special Sale`,
      `May Special Sale`,
    ],
    count: (credit) => [
      `${credit}회 수업권 (속성)`,
      `${credit} Credits (EXPED)`,
      's_pricing.marchsale.count',
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
    ],
    label_size: [`참고`, `Note`, 's_pricing.marchsale.label_size', `Note`, `Note`, `Note`, `Note`],
  },
  discount: {
    marchsale: [
      `5월 한정 할인`,
      `May special sale`,
      's_pricing.discount.marchsale',
      `May special sale`,
      `May special sale`,
      `May special sale`,
      `May special sale`,
    ],
  },
  april_promo: {
    label: [
      `Reboot 프로모션 한정 판매`,
      `Spring Promo Special`,
      's_pricing.april_promo.label',
      `Spring Promo Special`,
      `Spring Promo Special`,
      `Spring Promo Special`,
      `Spring Promo Special`,
    ],
    label_new: [`신규회원 추천`, `For Newcomers`, 's_pricing.april_promo.label_new', ``, ``, ``, ``],
    label_popular: [`인기 수업권`, `Most Popular`, 's_pricing.april_promo.label_popular', ``, ``, ``, ``],
    label_note: [`참고`, `Note`, 's_pricing.april_promo.label_note', `Note`, `Note`, `Note`, `Note`],
    note: [
      `구매 직후부터 수강기간 차감`,
      <>
        Credits valid for 15 days
        <br /> from purchase
      </>,
      's_pricing.april_promo.note',
      <>
        Credits valid for 15 days
        <br /> from purchase
      </>,
      <>
        Credits valid for 15 days
        <br /> from purchase
      </>,
      <>
        Credits valid for 15 days
        <br /> from purchase
      </>,
      <>
        Credits valid for 15 days
        <br /> from purchase
      </>,
    ],
    title_reboot: [`속성 수업권`, `Expedite`, 's_pricing.april_promo.title_reboot', ``, ``, ``, ``],
    title_regular: [`정규 수업권`, `Regular`, 's_pricing.april_promo.title_regular', ``, ``, ``, ``],
    subtitle_reboot: [
      `올해의 목표를 위해 Reboot!`,
      `Reboot your study routine!`,
      's_pricing.april_promo.subtitle_reboot',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_regular: [
      `나만의 페이스를 지키면서 꾸준히`,
      `Keep yourself on track`,
      's_pricing.april_promo.subtitle_regular',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_discount: [
      `Reboot 프로모션 할인`,
      `Spring Promo Discount`,
      's_pricing.april_promo.desc_discount',
      ``,
      ``,
      ``,
      ``,
    ],
    label_promo_only: [`한정판매`, `Limited offer`, 's_pricing.april_promo.label_promo_only', ``, ``, ``, ``],
    label_extra_discount: [`추가할인`, `Extra discount`, 's_pricing.april_promo.label_extra_discount', ``, ``, ``, ``],
  },
  package_option_title: [
    `수업권 선택 옵션`,
    `Lesson Credit Options`,
    's_pricing.package_option_title',
    <>
      请选择
      <br />
      适合自己的课程券
    </>,
    <>
      請選擇 <br />
      適合自己的課程券
    </>,
    <>
      あなたに合った
      <br />
      レッスンチケットのオプションを選択してください。
    </>,
    <>
      Lựa chọn gói khóa học
      <br />
      phù hợp với bạn!
    </>,
  ],
  package_option_1: {
    title: [
      `수업 길이`,
      `Lesson duration`,
      's_pricing.package_option_1.title',
      `课时长度`,
      `課程長度`,
      `レッスン時間`,
      `Thời lượng buổi học`,
    ],
    forty_min: [`40분`, `40 minutes`, 's_pricing.package_option_1.forty_min', `40分钟`, `40分鐘`, `40分`, `40 phút`],
    twenty_min: [`20분`, `20 minutes`, 's_pricing.package_option_1.twenty_min', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  },
  package_option_2: {
    title: [
      `평균 수업 횟수`,
      `Average frequency`,
      's_pricing.package_option_2.title',
      `平均上课次数`,
      `平均上課次數`,
      `平均授業回数`,
      `Số buổi học trung bình`,
    ],
    four_lessons_for_month: [
      `월 4회`,
      `4 lessons/month`,
      's_pricing.package_option_2.four_lessons_for_month',
      `一个月4次`,
      `一個月4次`,
      `月4回`,
      `4 buổi / tháng`,
    ],
    six_lessons_for_month: [
      `월 6회`,
      `6 lessons/month`,
      's_pricing.package_option_2.six_lessons_for_month',
      `一个月6次`,
      `一個月6次`,
      `月6回`,
      `6 buổi / tháng`,
    ],
  },
  package_option_3: {
    title: [
      `수강 방식`,
      `Routine`,
      's_pricing.package_option_3.title',
      `上课计画`,
      `上課計畫`,
      `受講方法`,
      `Cách thức`,
    ],
    book_free: [
      `자유 수강`,
      `None`,
      's_pricing.package_option_3.book_free',
      `无固定时间`,
      `無固定時間`,
      `自由受講`,
      `Lớp tự do`,
    ],
    book_weekly_one_lesson: [
      `매주 수업 듣기`,
      `Every week`,
      's_pricing.package_option_3.book_weekly_one_lesson',
      `每週`,
      `每週`,
      `毎週授業を受ける`,
      `Lớp hàng tuần`,
    ],
  },
  option_2_2_discount: [
    `3% 추가할인`,
    `3% extra off`,
    's_pricing.option_2_2_discount',
    `追加 3% 折扣`,
    `追加 3% 折扣`,
    `追加3%割引`,
    `Giảm thêm 3%`,
  ],
  option_3_2_discount: [
    `5% 추가할인`,
    `5% extra off`,
    's_pricing.option_3_2_discount',
    `追加 5% 折扣`,
    `追加 5% 折扣`,
    `追加5%割引`,
    `Giảm thêm 5%`,
  ],
  option_3_1_explanation: [
    <>
      <li className="li-tag-inside">자유로운 일정으로 수업을 듣고자 하는 분께 추천합니다. </li>
      <li className="li-tag-inside">
        첫 수업 시작 전 수강기간이 차감되지 않습니다. (구매 1년 후에는 자동 차감 됩니다.)
      </li>
    </>,
    <>
      <li className="li-tag-inside">Recommended for users who do not wish to be tied to any fixed schedule.</li>
      <li className="li-tag-inside">
        Credits will only start to expire after you complete your first lesson (or one year after the purchase date if
        left unused).
      </li>
    </>,
    's_pricing.option_3_1_explanation',
    <>
      <li className="li-tag-inside">推荐给希望自由安排时间学习英语的人。</li>
      <li className="li-tag-inside">在第一堂课开始之前不会扣除课程期限（购买后1年后会自动扣除）。</li>
    </>,
    <>
      <li className="li-tag-inside">推薦給希望自由安排時間學習英文的人。</li>
      <li className="li-tag-inside">在第一堂課開始之前不會扣除課程期限（購買後1年後會自動扣除）。</li>
    </>,
    <>
      <li className="li-tag-inside">自分のスケジュールに合わせて英語の勉強を希望する方におすすめです。</li>
      <li className="li-tag-inside">
        初回の授業開始前に受講期間は差し引かれません。（購入から1年後に自動に差し引かれます。）
      </li>
    </>,
    <>
      <li className="li-tag-inside">Phù hợp cho những ai muốn học tiếng Anh theo lộ trình tự do</li>
      <li className="li-tag-inside">
        Thời lượng học sẽ không trừ trước khi bắt đầu buổi học đầu tiên. (Hệ thống tự động trừ sau 1 năm mua gói học).
      </li>
    </>,
  ],
  option_3_2_2_1_explanation: [
    <>
      <li className="li-tag-inside">매주 꾸준한 영어 학습을 희망하는 경우 추천합니다.</li>
      <li className="li-tag-inside">구매 즉시 수강기간이 차감됩니다.</li>
      <li className="li-tag-inside">매주 1회 이상 수업을 듣지 않는 경우 수업권 1회가 자동 차감됩니다.</li>
    </>,
    <>
      <li className="li-tag-inside">Recommended for users who wish to study English consistently every week.</li>
      <li className="li-tag-inside">Credits will start expiring immediately after purchase.</li>
      <li className="li-tag-inside">
        You must take at least one lesson per week. One credit will be automatically deducted each week even if you
        don’t take any lessons.
      </li>
    </>,
    's_pricing.option_3_2_2_1_explanation',
    <>
      <li className="li-tag-inside">推荐给每周都持续学习英语的人。</li>
      <li className="li-tag-inside">购买后立即开始扣除课程券使用时间。</li>
      <li className="li-tag-inside">每周必须进行1次以上的课程，未进行课程将自动扣除课程券。</li>
    </>,
    <>
      <li className="li-tag-inside">推薦給每周都持續學習英語的人。</li>
      <li className="li-tag-inside">購買後立即開始扣除課程券使用時間。</li>
      <li className="li-tag-inside">每周必須進行1次以上的課程，未進行課程將自動扣除課程券。</li>
    </>,
    <>
      <li className="li-tag-inside">週ごとに英語の勉強を継続したい方におすすめです。</li>
      <li className="li-tag-inside">購入後、直ちに受講期間が差し引かれます。</li>
      <li className="li-tag-inside">
        毎週1回以上の授業を受けなければならず、授業を受けない場合はレッスンチケットが自動的に差し引かれます。
      </li>
    </>,
    <>
      <li className="li-tag-inside">Phù hợp cho những ai muốn học tiếng Anh đều đặn mỗi tuần.</li>
      <li className="li-tag-inside">Sau khi mua gói học, thời lượng học sẽ được tính ngay lập tức.</li>
      <li className="li-tag-inside">
        Phải tham gia ít nhất 1 buổi học/ tuần, dù không tham gia khóa học thì hệ thống vẫn sẽ tự động trừ thời lượng
        học.{' '}
      </li>
    </>,
  ],
  option_3_2_2_2_explanation: [
    <>
      <li className="li-tag-inside">매주 꾸준한 영어 학습을 희망하는 경우 추천합니다.</li>
      <li className="li-tag-inside">
        신년 프로모션 기간 동안 구매 시, 24년 5월 1일까지 수강 기간이 차감되지 않습니다. (23.12.27 ~ 24.2.1)
      </li>
      <li className="li-tag-inside">매주 1회 이상 수업을 듣지 않는 경우 수업권 1회가 자동 차감됩니다.</li>
      <li className="li-tag-inside">
        매주 수업 듣기가 어려운 경우 수업 연기 혜택을 사용할 수 있습니다. 우측 수업권 혜택을 확인해주세요.
      </li>
      <li className="li-tag-inside">
        월 6회 수업권의 경우, 수강기간 이내 수업권을 모두 사용하기 위해서는 특정 주에는 일주일에 2회 이상 수업을
        진행해야 합니다.
      </li>
    </>,
    <>
      <li className="li-tag-inside">Recommended for users who wish to study English consistently every week.</li>
      <li className="li-tag-inside">
        For purchases made during the promotion period (Dec 27, 2023 - Feb 1, 2024), the countdown towards the
        expiration of lesson credtis will start on May 1, 2024.
      </li>
      <li className="li-tag-inside">
        You must take at least one lesson per week. One credit will be automatically deducted each week even if you
        don’t take any lessons.
      </li>
      <li className="li-tag-inside">
        If you have difficulty taking lessons every week, you can use the deferral benefit. (Please check the specific
        details on the right side.)
      </li>
      <li className="li-tag-inside">
        If you choose 6 times/month, you will need to take at least 2 lessons on certain weeks to use all of the credits
        within the validity period.
      </li>
    </>,
    's_pricing.option_3_2_2_2_explanation',
    <>
      <li className="li-tag-inside">如果您希望每周进行英语学习，我建议您购买此课程卷。</li>
      <li className="li-tag-inside">于促销期间(2023/12/27 - 2024/02/01)购买的课程券将于2024/05/01开始计算使用期限。</li>
      <li className="li-tag-inside">购买后每周若无进行课程，将自动扣除一次课程。</li>
      <li className="li-tag-inside">如果无法每週上课，可以使用延长的方式延长使用期限，请参考右侧说明</li>
      <li className="li-tag-inside">如果选择一个月6次课程，为了于期限内完成所有课程，有时候一週号要上2堂课。</li>
    </>,
    <>
      <li className="li-tag-inside">如果您希望每周進行英語學習，我建議您購買此課程卷。</li>
      <li className="li-tag-inside">於促銷期間(2023/12/27 - 2024/02/01)購買的課程券將於2024/05/01開始計算使用期限。</li>
      <li className="li-tag-inside">購買後每周若無進行課程，將自動扣除一次課程。</li>
      <li className="li-tag-inside">如果無法每週上課，可以使用延長的方式延長使用期限，請參考右側說明</li>
      <li className="li-tag-inside">如果選擇一個月6次課程，為了於期限內完成所有課程，有時候一週號要上2堂課。</li>
    </>,
    <>
      <li className="li-tag-inside">毎週コンスタントに英語を学習したい方におすすめです。</li>
      <li className="li-tag-inside">
        キャンペーン期間中（2023年12月27日～2024年2月1日）にご購入いただいた場合、レッスンクレジットの有効期限は2024年5月1日からカウントダウンされます。
      </li>
      <li className="li-tag-inside">
        1週間に最低1回のレッスンを受講する必要があります。レッスンを受講されなくても、毎週1クレジットが自動的に差し引かれます。
      </li>
      <li className="li-tag-inside">
        毎週レッスンを受講することが難しい場合は、受講繰延の特典をご利用いただけます。(具体的な内容は右記をご確認ください)
      </li>
      <li className="li-tag-inside">
        月6回を選択された場合、有効期間内にすべてのクレジットを使用するには、特定の週に最低2回レッスンを受講する必要があります。
      </li>
    </>,
    <>
      <li className="li-tag-inside">Recommended for users who wish to study English consistently every week.</li>
      <li className="li-tag-inside">
        For purchases made during the promotion period (Dec 27, 2023 - Feb 1, 2024), the countdown towards the
        expiration of lesson credtis will start on May 1, 2024.
      </li>
      <li className="li-tag-inside">
        You must take at least one lesson per week. One credit will be automatically deducted each week even if you
        don’t take any lessons.
      </li>
      <li className="li-tag-inside">
        If you have difficulty taking lessons every week, you can use the deferral benefit. (Please check the specific
        details on the right side.)
      </li>
      <li className="li-tag-inside">
        If you choose 6 times/month, you will need to take at least 2 lessons on certain weeks to use all of the credits
        within the validity period.
      </li>
    </>,
  ],
  apply_coupon_and_points: [
    `보유 쿠폰 및 포인트 최대 적용`,
    `Redeem max. coupons & points`,
    's_pricing.apply_coupon_and_points',
    `使用所有持有的优惠券和积分`,
    `使用所有持有的優惠券和點數`,
    `所持クーポンおよびポイントの最大適用`,
    `Áp dụng voucher & điểm`,
  ],
  coupon_applied: (amount) => [
    `${amount}원 쿠폰 적용됨`,
    `${amount} coupon applied`,
    's_pricing.coupon_applied',
    `使用 ${amount} 美元的优惠券`,
    `使用 ${amount} 美元的優惠券`,
    `${amount}ウォンのクーポンが適用されました。`,
    `Đã áp dụng phiếu giảm giá ${amount}`,
  ],
  wanna_see_all_package: [
    `모든 수업권을 한눈에 보고 싶다면?`,
    `Wish to browse all offered credits?`,
    's_pricing.wanna_see_all_package',
    `如果您想一次确认所有课程券，该怎么做？`,
    `如果您想一次確認所有課程券，該怎麽做？`,
    `すべてのレッスンチケットを一目で見たい場合は？`,
    `Xem tất cả gói khóa học một cách toàn diện?`,
  ],
  see_all_package: [
    `전체 수업권 보기`,
    `View all credits`,
    's_pricing.see_all_package',
    `确认所有课程券`,
    `確認所有課程券`,
    `全てのレッスンチケットを見る`,
    `Xem tất cả gói khóa học`,
  ],
  point_info: {
    title: [
      `포인트 안내`,
      `Points info`,
      's_pricing.point_info.title',
      `积分详情`,
      `點數詳情`,
      `ポイントの案内`,
      `Điểm tích lũy`,
    ],
    confirm: [`확인`, `Ok`, 's_pricing.point_info.confirm', `确认`, `確定`, `OK`, `Ok`],
  },
  point_info_desc: (point, availablePoint) => [
    <>
      내 보유 포인트: {point}P<br />
      <div className="flex">
        <div className="mr-[2px]">사용 가능 포인트: </div>
        <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      포인트 종류에 따라 수업권 구매 시 사용 가능 여부가 달라집니다.
      <br />
      <li className="li-tag-inside">
        친구 추천 및 이벤트를 통해 받은 포인트 : 결제 금액이 30만원 이상 되는 경우 사용 가능
      </li>
      <br />
      결제사 정책에 의해 1,000원 이하의 결제는 불가능합니다. 보유하신 포인트에서 최소 1,000원을 남겨두고 사용해 주세요.
    </>,
    <>
      Your points total: {point}P<br />
      <div className="flex">
        Redeemable points: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      Points can be redeemed toward purchasing credits as follows.
      <br />
      <li className="li-tag-inside">
        Points received through referrals and events: Can be redeemed when the payment total is over $230.76
      </li>
    </>,
    's_pricing.point_info_desc',
    <>
      我的积分：{point}P<br />
      <div className="flex">
        本次支付可用积分：<div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      根据积分类型，购买课程券时可用积分的情况有所不同。
      <br />
      <li className="li-tag-inside">通过朋友推荐和活动获得的积分：仅在支付金额超过30万韩元时可使用</li>
    </>,
    <>
      我的點數：{point}P<br />
      <div className="flex">
        本次支付可用點數：<div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      根據點數類型，購買課程券時可用點數的情況有所不同。
      <br />
      <li className="li-tag-inside">通過朋友推薦和活動獲得的點數：僅在支付金額超過30萬韓元時可使用</li>
    </>,
    <>
      所持ポイント: {point}P<br />
      <div className="flex">
        この支払いで利用可能なポイント: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      ポイントの種類によって、レッスンチケットの購入時に使用可能かどうかが異なります。.
      <br />
      <li className="li-tag-inside">
        友達の紹介やイベントでもらったポイント：支払金額が30万ウォン以上の場合に使用可能です。
      </li>
    </>,
    <>
      Điểm tích lũy của tôi: Điểm tích lũy có sẵn cho khoản thanh toán {point}P<br />
      Điểm khả dụng cho{' '}
      <div className="flex">
        khoản thanh toán này: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      Tùy thuộc vào loại điểm tích lũy khác nhau có thể dùng khi mua gói học khác nhau
      <br />
      <li className="li-tag-inside">
        Điểm nhận được thông qua giới thiệu bạn bè và sự kiện: Dùng khi thanh toán trên 300.000 won
      </li>
    </>,
  ],
  scroll_down_to_check_packages: [
    `내려서 수업권 확인하기`,
    `Scroll down to view credit`,
    's_pricing.scroll_down_to_check_packages',
    `往下滑看更多课程券`,
    `往下滑看更多課程券`,
    `スクロールしてチケットを見る`,
    `Scroll để kiểm tra phiếu học`,
  ],
  lesson_credit: [
    `상품명`,
    `Package`,
    's_pricing.lesson_credit',
    `课程券名称`,
    `課程券名稱`,
    `レッスンチケット`,
    `Gói`,
  ],
  expiration_date: [`수강 기간`, <></>, 's_pricing.expiration_date', <></>, <></>, <></>, <></>],
  sale: {
    title: [`할인`, `Off`, 's_pricing.sale.title', `优惠`, `折扣`, `割引`, `Off`],
  },
  autumn_event: {
    event_text: [
      `가을 맞이 특별 편성`,
      `Fall Special`,
      's_pricing.autumn_event.event_text',
      `Fall Special`,
      `Fall Special`,
      `Fall Special`,
      `Fall Special`,
    ],
    credit_label: [
      `~10월 31일 까지`,
      `until Oct. 31`,
      's_pricing.autumn_event.credit_label',
      `until Oct. 31`,
      `until Oct. 31`,
      `until Oct. 31`,
      `until Oct. 31`,
    ],
    special_discount: [
      `특별 할인가 적용`,
      `Special discount`,
      's_pricing.autumn_event.special_discount',
      `Special discount`,
      `Special discount`,
      `Special discount`,
      `Special discount`,
    ],
    option_label: [
      `가을 이벤트`,
      `Fall Event`,
      's_pricing.autumn_event.option_label',
      `Fall Event`,
      `Fall Event`,
      `Fall Event`,
      `Fall Event`,
    ],
    benefit: [
      `특별 혜택`,
      `Bonus benefit`,
      's_pricing.autumn_event.benefit',
      `Bonus benefit`,
      `Bonus benefit`,
      `Bonus benefit`,
      `Bonus benefit`,
    ],
    benefit_explanation: [
      `영어 원서 혼자 읽기 프로그램 (29,900원) 무료 제공`,
      `Free registration to 'Read With Me: Books`,
      's_pricing.autumn_event.benefit_explanation',
      `Free registration to 'Read With Me: Books`,
      `Free registration to 'Read With Me: Books`,
      `Free registration to 'Read With Me: Books`,
      `Free registration to 'Read With Me: Books`,
    ],
    benefit_popup_title: [
      `특별 혜택: 영어 원서 혼자 읽기 프로그램 제공`,
      `Special offer: "Self Study: Books" program for free`,
      's_pricing.autumn_event.benefit_popup_title',
      `特别优惠，免费  "Self Study: Books" 课程`,
      `特別優惠，免費  "Self Study: Books" 課程`,
      `Special offer: "Self Study: Books" program for free`,
      `Special offer: "Self Study: Books" program for free`,
    ],
    benefit_popup_content: [
      <>
        <li className="li-tag-inside">
          8회 수업권 구매시, 링글에서 진행하는 29,900원 상당의 "영어 원서 혼자 읽기 프로그램 - H마트에서 울다"에 무료로
          참여하실 수 있습니다.
        </li>
        <li className="li-tag-inside">
          영어 원서 혼자 읽기 프로그램은 4주간 1권의 원서를 완독할 수 있도록 매일 학습 자료를 보내드리고, 학습 계획표에
          맞춰 혼자 원서를 읽고 공부하는 방식으로 운영됩니다.
        </li>
        <li className="li-tag-inside">원서는 별도로 구매해야 합니다.</li>
        <li className="li-tag-inside">
          수업권 구매 시 자동으로 해당 프로그램에 신청되며 11월 6일부터 12월 1일까지 진행됩니다.
        </li>
        <li className="li-tag-inside">
          영어 원서 혼자 읽기 프로그램 시작 전, 이메일을 통해 프로그램에 대한 자세한 안내를 보내드립니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          With the purchase of 8 lesson credits, you can participate in the "Self Study: Books - Crying at H-Mart" for
          free.
        </li>
        <li className="li-tag-inside">
          The program consists of sending you daily study materials to complete one book in 4 weeks and reading and
          studying
        </li>
        <li className="li-tag-inside">the book on your own according to the study plan.</li>
        <li className="li-tag-inside">The original book must be purchased separately.</li>
        <li className="li-tag-inside">
          You'll be automatically enrolled in the program when you purchase a class, and it runs from November 6 to
          December 1.
        </li>
        <li className="li-tag-inside">
          We will send you more information about the program via email before it starts.
        </li>
      </>,
      's_pricing.autumn_event.benefit_popup_content',
      <>
        <li className="li-tag-inside">
          With the purchase of 8 lesson credits, you can participate in the "Self Study: Books - Crying at H-Mart" for
          free.
        </li>
        <li className="li-tag-inside">
          The program consists of sending you daily study materials to complete one book in 4 weeks and reading and
          studying
        </li>
        <li className="li-tag-inside">the book on your own according to the study plan.</li>
        <li className="li-tag-inside">The original book must be purchased separately.</li>
        <li className="li-tag-inside">
          You'll be automatically enrolled in the program when you purchase a class, and it runs from November 6 to
          December 1.
        </li>
        <li className="li-tag-inside">
          We will send you more information about the program via email before it starts.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          With the purchase of 8 lesson credits, you can participate in the "Self Study: Books - Crying at H-Mart" for
          free.
        </li>
        <li className="li-tag-inside">
          The program consists of sending you daily study materials to complete one book in 4 weeks and reading and
          studying
        </li>
        <li className="li-tag-inside">the book on your own according to the study plan.</li>
        <li className="li-tag-inside">The original book must be purchased separately.</li>
        <li className="li-tag-inside">
          You'll be automatically enrolled in the program when you purchase a class, and it runs from November 6 to
          December 1.
        </li>
        <li className="li-tag-inside">
          We will send you more information about the program via email before it starts.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          With the purchase of 8 lesson credits, you can participate in the "Self Study: Books - Crying at H-Mart" for
          free.
        </li>
        <li className="li-tag-inside">
          The program consists of sending you daily study materials to complete one book in 4 weeks and reading and
          studying
        </li>
        <li className="li-tag-inside">the book on your own according to the study plan.</li>
        <li className="li-tag-inside">The original book must be purchased separately.</li>
        <li className="li-tag-inside">
          You'll be automatically enrolled in the program when you purchase a class, and it runs from November 6 to
          December 1.
        </li>
        <li className="li-tag-inside">
          We will send you more information about the program via email before it starts.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          With the purchase of 8 lesson credits, you can participate in the "Self Study: Books - Crying at H-Mart" for
          free.
        </li>
        <li className="li-tag-inside">
          The program consists of sending you daily study materials to complete one book in 4 weeks and reading and
          studying
        </li>
        <li className="li-tag-inside">the book on your own according to the study plan.</li>
        <li className="li-tag-inside">The original book must be purchased separately.</li>
        <li className="li-tag-inside">
          You'll be automatically enrolled in the program when you purchase a class, and it runs from November 6 to
          December 1.
        </li>
        <li className="li-tag-inside">
          We will send you more information about the program via email before it starts.
        </li>
      </>,
    ],
  },
  tab_promotion: [`프로모션`, `Promotion`, 's_pricing.tab_promotion', `促销`, `促銷`, `プロモーション`, `Promotion`],
  d_day_count: (day) => [
    <>D-{day}</>,
    `D-#{day}`,
    's_pricing.d_day_count',
    `D-#{day}`,
    `D-#{day}`,
    <>D-{day}</>,
    `D-#{day}`,
  ],
  label_bf: (price) => [
    <>블프 추가할인 {price}</>,
    <>BF Sale {price}</>,
    's_pricing.label_bf',
    <>额外优惠 {price}</>,
    <>額外優惠 {price}</>,
    <>ブルプ追加割引{price}</>,
    <>BF Sale {price}</>,
  ],
  label_pacakge: (price) => [
    <>패키지 할인 {price}</>,
    <>Package discount {price}</>,
    's_pricing.label_pacakge',
    <>套餐优惠 {price}</>,
    <>套餐優惠 {price}</>,
    <>Package Discount {price}</>,
    <>Package discount {price}</>,
  ],
  internationalPayment: [
    `해외 카드 결제`,
    `Credit card`,
    's_pricing.internationalPayment',
    `海外信用卡付款`,
    `海外信用卡付款`,
    `海外カード決済`,
    `Thẻ tín dụng`,
  ],
  first_buy_banner: {
    title: [
      `첫 구매 혜택`,
      `First Purchase Benefits`,
      's_pricing.first_buy_banner.title',
      `首次购买优惠`,
      `首次購買優惠`,
      `初回購入特典`,
      ``,
    ],
    content: [
      `체험 없이 첫 구매 시 추가 수업권 증정!`,
      `Purchase without a trial for free credits!`,
      's_pricing.first_buy_banner.content',
      `不体验直接购买额外赠送课程券！`,
      `不體驗直接購買額外贈送課程券！`,
      `体験なしで初回購入時に追加レッスンチケットプレゼント!`,
      ``,
    ],
    pc_refund_added: [
      `체험 없이 첫 구매 시 추가 수업권 증정!`,
      `Purchase without a trial for free credits!`,
      's_pricing.first_buy_banner.pc_refund_added',
      `不体验直接购买额外赠送课程券！`,
      `不體驗直接購買額外贈送課程券！`,
      `体験なしで初回購入時に追加レッスンチケットプレゼント!`,
      ``,
    ],
    mo_refund_added: [
      `체험 없이 첫 구매 시 추가 수업권 증정!`,
      `Purchase without a trial for free credits!`,
      's_pricing.first_buy_banner.mo_refund_added',
      `不体验直接购买额外赠送课程券！`,
      `不體驗直接購買額外贈送課程券！`,
      `体験なしで初回購入時に追加レッスンチケットプレゼント!`,
      ``,
    ],
  },
  first_buy_modal: {
    title: [
      `체험 없이 첫 구매 시 추가 수업권 증정!`,
      `Purchase without a trial for free credits!`,
      's_pricing.first_buy_modal.title',
      `不体验直接购买额外赠送课程券！`,
      `不體驗直接購買額外贈送課程券！`,
      `体験なしで初回購入時に追加レッスンチケットプレゼント!`,
      ``,
    ],
    content: [
      <>
        1회 체험 수업을 신청하지 않고 수업권을 구매하는 경우, 첫 구매 혜택으로 수업권을 추가로 드립니다.
        <br />
        <br />
        <b>[자유 수강 수업권 1회 추가 증정]</b>
        <br />
        4회, 12회 수업권 구매한 경우
        <br />
        <br />
        <b>[자유 수강 수업권 2회 추가 증정]</b>
        <br />
        24회 수업권 구매한 경우
        <br />
        <br />
        <b>[자유 수강 수업권 3회 추가 증정]</b>
        <br />
        48회 수업권 구매한 경우
      </>,
      <>
        If you purchase lesson credits without signing up for a trial lesson, you will receive additional credits as a
        first-time purchase benefit.
        <br />
        <br />
        <b>[1 additional credit (none routine)]</b>
        <br />
        For purchases of 4 or 12 credits.
        <br />
        <br />
        <b>[2 additional credits (none routine)]</b>
        <br />
        For purchases of 24 credits.
        <br />
        <br />
        <b>[3 additional credits (none routine)]</b>
        <br />
        For purchases of 48 credits.
      </>,
      's_pricing.first_buy_modal.content',
      <>
        不体验直接购买课程时，将根据购买的课程券赠送课程券。
        <br />
        <br />
        <b>赠送 1次课程券</b>
        <br />
        4次, 12次课程券
        <br />
        <br />
        <b>赠送 2次课程券</b>
        <br />
        24次课程券
        <br />
        <br />
        <b>赠送 3次课程券</b>
        <br />
        48次课程券
        <br />
        <br />
        *如果购买前进行体验，以上优惠将不适用。
      </>,
      <>
        不體驗直接購買課程時，將根據購買的課程券贈送課程券。
        <br />
        <br />
        <b>贈送 1次課程券</b>
        <br />
        4次, 12次課程券
        <br />
        <br />
        <b>贈送 2次課程券</b>
        <br />
        24次課程券
        <br />
        <br />
        <b>贈送 3次課程券</b>
        <br />
        48次課程券
        <br />
        <br />
        *如果購買前進行體驗，以上優惠將不適用。
      </>,
      <>
        1回体験レッスンを申し込まずにレッスンチケットを購入された場合、初回購入特典としてレッスンチケットを追加でプレゼントします
        <br />
        <br />
        <b>[フリー受講券1回追加プレゼント]</b>
        <br />
        4回、12回レッスンチケットを購入した場合
        <br />
        <br />
        <b>[無料受講券2回追加プレゼント]</b>
        <br />
        24回受講券を購入した場合
        <br />
        <br />
        <b>[無料受講券3回追加プレゼント]</b>
        <br />
        <br />
        48回レッスンチケットを購入した場合
      </>,
      ``,
    ],
  },
  package: {
    more_credit_first: (number) => [
      <>+ {number}회 (첫 구매 혜택)</>,
      <>+ {number} Credit(s)</>,
      's_pricing.package.more_credit_first',
      <>+ ${number}次 (首购优惠)</>,
      <>+ ${number}次 (首購優惠)</>,
      <>+ {number}回 (初回購入特典)</>,
      ``,
    ],
  },
  option_3_2_2_1_explanation_new_year: [
    <>
      <li className="li-tag-inside">꾸준한 영어 학습을 위해 주 1회 이상 필수로 수강해야 합니다.</li>
      <li className="li-tag-inside">수업권 구매 즉시 수강 기간이 차감됩니다.</li>
      <li className="li-tag-inside">주 1회 이상 수업을 듣지 않는 경우 수업권이 차감됩니다.</li>
      <li className="li-tag-inside">주 1회만 필수이며, 주 2회 이상 수강도 가능합니다.</li>
      <li className="li-tag-inside">매주 영어 공부 습관을 위해 수업 독려 알림을 드립니다.</li>
      <li className="li-tag-inside">수업 연기 혜택을 사용할 수 있습니다. (링글 고객 센터 문의)</li>
    </>,
    <>
      <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
      <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
      <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
      <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
      <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
      <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
    </>,
    's_pricing.option_3_2_2_1_explanation_new_year',
    <>
      <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
      <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
      <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
      <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
      <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
      <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
    </>,
    <>
      <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
      <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
      <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
      <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
      <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
      <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
    </>,
    <>
      <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
      <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
      <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
      <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
      <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
      <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
    </>,
    <>
      <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
      <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
      <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
      <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
      <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
      <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
    </>,
  ],
  n_year_promo: {
    benefit_default: (number) => [
      <>수업권 혜택 {number}개</>,
      <>{number} Lesson credit benefit(s)</>,
      's_pricing.n_year_promo.benefit_default',
      <>课程券优惠{number}个 </>,
      <>課程券優惠{number}個 </>,
      <>{number} Lesson credit benefit(s)</>,
      <>{number} Lesson credit benefit(s)</>,
    ],
    benefit_promo: (creditNumer, promotionNumber) => [
      <>
        <span className="text-gray-500">수업권 혜택 {creditNumer}개</span> + 프로모션 혜택 {promotionNumber}개{' '}
      </>,
      <>
        {promotionNumber} Promotional benefit(s) <span className="text-gray-500">and more</span>{' '}
      </>,
      's_pricing.n_year_promo.benefit_promo',
      <>
        {promotionNumber} Promotional benefit(s) <span className="text-gray-500">and more</span>{' '}
      </>,
      <>
        {promotionNumber} Promotional benefit(s) <span className="text-gray-500">and more</span>{' '}
      </>,
      <>
        {promotionNumber} Promotional benefit(s) <span className="text-gray-500">and more</span>{' '}
      </>,
      <>
        {promotionNumber} Promotional benefit(s) <span className="text-gray-500">and more</span>{' '}
      </>,
    ],
  },
  benefit: {
    credit: [
      `수업권 혜택`,
      `Lesson credit benefits`,
      's_pricing.benefit.credit',
      `课程券优惠`,
      `課程券優惠`,
      `Lesson credit benefits`,
      `Lesson credit benefits`,
    ],
    holding: [
      `수강 기간 시작 3개월 홀딩`,
      `Pause expiration countdown for 3 months`,
      's_pricing.benefit.holding',
      `课程券使用期限将从3个月后开始计算`,
      `課程券使用期限將從3個月後開始計算`,
      `受講券の使用期間は3ヵ月後から計算されます。`,
      `Pause expiration countdown for 3 months`,
    ],
    delay: (number) => [
      <>수업 연기 ({number}회) </>,
      <>Deferral benefits {number} time(s)</>,
      's_pricing.benefit.delay',
      <>课程券延长 ({number}次)</>,
      <>課程券延長 ({number}次)</>,
      <>受講券延長（{number}回)</>,
      <>Deferral benefits ({number} time(s))</>,
    ],
    first_booking: [
      `튜터 우선 예약`,
      `Priority Booking`,
      's_pricing.benefit.first_booking',
      `优先预约教师`,
      `優先預約教師`,
      `チューター優先予約`,
      `Priority Booking`,
    ],
    promo: [
      `프로모션 혜택`,
      `Promotional benefits`,
      's_pricing.benefit.promo',
      `促销优惠`,
      `促銷優惠`,
      `販促特典`,
      `Promotional benefits`,
    ],
    promo_more: [
      `프로모션 혜택 더 알아보기`,
      `See details`,
      's_pricing.benefit.promo_more',
      `看更多优惠`,
      `看更多優惠`,
      `See details`,
      `See details`,
    ],
    first_booking_desc: [
      `찜한 튜터의 7일 후 수업 스케줄 오픈 알림을 앱푸시로 먼저 받고 24시간 동안 우선 예약을 할 수 있는 서비스입니다.`,
      `A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and enables priority booking for 24 hours.`,
      's_pricing.benefit.first_booking_desc',
      `当收藏的教师开放7天后课程时间时，您将优先于Ringle APP收到通知，您可以在接收通知后的24小时内优先预约课程。`,
      `當收藏的教師開放7天後課程時間時，您將優先於Ringle APP收到通知，您可以在接收通知後的24小時內優先預約課程。`,
      `A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and enables priority booking for 24 hours.`,
      `A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and enables priority booking for 24 hours.`,
    ],
    delay_desc: [
      `개인적인 사정으로 주 1회 수강이 어려울 때, 수업을 미룰 수 있는 기능으로 차감된 수업권을 복구해드립니다. (혜택 사용을 위해서는 링글팀에 문의를 남겨주세요.)`,
      `If you have difficulty taking lessons every week, we offer deferral benefits that restore the deducted lesson credits. Please contact the Ringle team to utilize this benefit.`,
      's_pricing.benefit.delay_desc',
      `如果您无法每週上课，我们提供延长课程券使用时间的优惠，恢復已被扣除的课程券。 请联繫Ringle团队以利用此优惠。`,
      `如果您無法每週上課，我們提供延長課程券使用時間的優惠，恢復已被扣除的課程券。 請聯繫Ringle團隊以利用此優惠。`,
      `毎週授業ができない場合、受講券の使用期間を延長して控除された受講券を復元する特典を提供します。 この特典を利用するには、Ringleチームに連絡してください。`,
      `If you have difficulty taking lessons every week, we provide deferral benefits that restore the deducted lesson credits. Please contact the Ringle team to utilize this benefit.`,
    ],
    holding_desc: [
      `구매 시점으로부터 첫 3개월 동안 수강기간이 차감되지 않도록 홀드 (24.5.1 부터 수강기간 자동으로 시작)`,
      `For purchases made during the promotion period (Dec 22, 2023 - Feb 1, 2024),we provide a grace period of 3 months. The countdown towards the expiration of lesson credits will start on May 1, 2024.`,
      's_pricing.benefit.holding_desc',
      `于促销期间（2023年12月22日至2024年2月1日）购买之课程券，我们提供3个月的缓冲期。 课程券使用期限将从2024年5月1日开始扣除。`,
      `於促銷期間（2023年12月22日至2024年2月1日）購買之課程券，我們提供3個月的緩衝期。 課程券使用期限將從2024年5月1日開始扣除。`,
      `セール期間(2023年12月22日から2024年2月1日まで)に購入したコース券は3ヵ月間の緩衝期間を提供します。 受講券の利用期間は2024年5月1日から差し引かれます。`,
      `We provide a grace period of 3 months for purchases made during the promotion period (Dec 22, 2023 - Feb 1, 2024). The expiration countdown will begin on Feb 1, 2024.`,
    ],
    first_booking_desc_b2b2c: [
      <>
        찜한 튜터의 7일 후 수업 스케줄 오픈 알림을 앱푸시로 먼저 받고 24시간 동안 우선 예약을 할 수 있는 서비스 입니다.
        <br />
        (24년 2월 2일 이후부터 사용 가능함)
      </>,
      <>
        A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and
        enables priority booking for 24 hours.
        <br /> (Available from February 2, 2024, onwards.)
      </>,
      's_pricing.benefit.first_booking_desc_b2b2c',
      <>
        A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and
        enables priority booking for 24 hours.
        <br /> (Available from February 2, 2024, onwards.)
      </>,
      <>
        A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and
        enables priority booking for 24 hours.
        <br /> (Available from February 2, 2024, onwards.)
      </>,
      <>
        A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and
        enables priority booking for 24 hours.
        <br /> (Available from February 2, 2024, onwards.)
      </>,
      <>
        A service that allows you to receive a 7-day advance notice for the schedule opening of "Favorite Tutors" and
        enables priority booking for 24 hours.
        <br /> (Available from February 2, 2024, onwards.)
      </>,
    ],
  },
  b2b2c_banner: {
    label: [
      `첫 구매 혜택`,
      `First Purchase Benefits`,
      's_pricing.b2b2c_banner.label',
      `首次购买优惠`,
      `首次購買優惠`,
      `初回購入特典`,
      ``,
    ],
    promo_first_buy: [
      `체험 없이 첫 구매 시 추가 수업권 증정!`,
      `Purchase without a trial for free credits!`,
      's_pricing.b2b2c_banner.promo_first_buy',
      `不体验直接购买额外赠送课程券！`,
      `不體驗直接購買額外贈送課程券！`,
      `体験なしで初回購入時に追加レッスンチケットプレゼント!`,
      ``,
    ],
    promo_buy: [
      `1억 원 상품부터 다양한 신년 프로모션 혜택`,
      `Don’t miss $76K prizes and more benefits`,
      's_pricing.b2b2c_banner.promo_buy',
      `Don’t miss $76K prizes and more benefits`,
      `Don’t miss $76K prizes and more benefits`,
      `Don’t miss $76K prizes and more benefits`,
      `Don’t miss $76K prizes and more benefits`,
    ],
    promo_buy_title: [
      `다양한 신년 프로모션 혜택`,
      `New Year's Promotional Benefits`,
      's_pricing.b2b2c_banner.promo_buy_title',
      `New Year's Promotional Benefits`,
      `New Year's Promotional Benefits`,
      `New Year's Promotional Benefits`,
      `New Year's Promotional Benefits`,
    ],
    label_first_purchase: [
      `첫 구매 혜택`,
      `First Purchase Benefits`,
      's_pricing.b2b2c_banner.label_first_purchase',
      `首次购买优惠`,
      `首次購買優惠`,
      `初回購入特典`,
      ``,
    ],
  },
  default_benefit: {
    title: [
      `수업권 기본 제공 내용`,
      `Ringle 1:1 lesson includes:`,
      's_pricing.default_benefit.title',
      `Ringle 1:1课程包含`,
      `Ringle 1:1課程包含`,
      `Ringle 1:1 lesson includes:`,
      `Ringle 1:1 lesson includes:`,
    ],
    book: [
      `1,000개 교재 무료`,
      `1,000 lesson materials`,
      's_pricing.default_benefit.book',
      `1,000 篇教材`,
      `1,000 篇教材`,
      `1,000 lesson materials`,
      `1,000 lesson materials`,
    ],
    ai: [
      `영어 AI 분석 결과 및 점수`,
      `AI analysis and scores for each lesson`,
      's_pricing.default_benefit.ai',
      `每堂课后提供AI分析报告`,
      `每堂課後提供AI分析報告`,
      `AI Analysis and scores for each lesson`,
      `AI analysis and scores for each lesson`,
    ],
    feedback: [
      `튜터 수업 피드백 및 교정 노트`,
      `Tutor’s lesson feedback and corrections`,
      's_pricing.default_benefit.feedback',
      `教师提供评语和纠正笔记`,
      `教師提供評語和糾正筆記`,
      `Tutor’s lesson feedback and corrections`,
      `Tutor’s lesson feedback and corrections`,
    ],
    script: [
      `수업 녹음 파일 및 스크립트`,
      `Lesson recordings and scripts`,
      's_pricing.default_benefit.script',
      `课程录音和文字档案`,
      `課程錄音和文字檔案`,
      `Lesson recordings and scripts`,
      `Lesson recordings and scripts`,
    ],
    quiz: [
      `데일리 학습 퀴즈`,
      `Daily study quizzes`,
      's_pricing.default_benefit.quiz',
      `每日学习问题`,
      `每日學習問題`,
      `Daily study quizzes`,
      `Daily study quizzes`,
    ],
    webinar: [
      `글로벌 커리어 웨비나 다시보기`,
      `Replays of global career webinars`,
      's_pricing.default_benefit.webinar',
      `全球职业线上研讨会重播`,
      `全球職業線上研討會重播`,
      `Replays of global career webinars`,
      `Replays of global career webinars`,
    ],
  },
  label: {
    promo_point: (amount) => [
      `즉시 ${amount}P 포인트백`,
      `${amount}P point back`,
      's_pricing.label.promo_point',
      `${amount}积分回馈`,
      `${amount}點數回饋`,
      `${amount}P point back`,
      `${amount}P point back`,
    ],
    feb_promo: (amount) => [
      `2월 ${amount} 추가 할인`,
      `Feb. ${amount} discount`,
      's_pricing.label.feb_promo',
      `Feb. ${amount} discount`,
      `二月 ${amount} 特價`,
      `Feb. ${amount} discount`,
      `Feb. ${amount} discount`,
    ],
    discount: [
      `5% 추가할인`,
      `5% extra off`,
      's_pricing.label.discount',
      `追加 5% 折扣`,
      `追加 5% 折扣`,
      `追加5%割引`,
      `Giảm thêm 5%`,
    ],
  },
  promo_benefit: {
    point: [
      `수업권 구매 즉시 포인트가 적립됩니다. 수업권 구매 등에 사용할 수 있습니다. (포인트 유효기간:~ 24/3/31)`,
      `Points are instantly earned upon purchasing it and can be used until March 31st).`,
      's_pricing.promo_benefit.point',
      ``,
      ``,
      ``,
      ``,
    ],
    conference: [
      `글로벌 커리어를 가진 마케팅부터 IT까지 다양한 직무의 연사가 강연하는 컨퍼런스 티켓 증정 (24년 3월 예정)`,
      `A conference ticket for featuring speakers from diverse fields such as marketing to IT with international careers. (Scheduled for Mar. 2024)`,
      's_pricing.promo_benefit.conference',
      ``,
      ``,
      ``,
      ``,
    ],
    read_together: [
      `매달 신규 영어 원서/신문을 튜터와 함께 읽는 Read Together 프로그램 6개 참여권 증정 (24년 2월부터 7월 참여 가능)`,
      `A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a tutor (Available from Feb 2024).`,
      's_pricing.promo_benefit.read_together',
      ``,
      ``,
      ``,
      ``,
    ],
    study_group: [
      `2월 한 달간 운영될 예정인 링글 고객들과 함께 영어 스피킹을 연습하는 소규모 온라인 스터디 모임 참여 가능`,
      `Participation in a online study group with Ringle customers to practice English throughout February.`,
      's_pricing.promo_benefit.study_group',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  discount_label: {
    normal: (number) => [
      `정규 프로모션 ${number} 할인`,
      `Promotion ${number} off`,
      's_pricing.discount_label.normal',
      `Promotion ${number} off`,
      `Promotion ${number} off`,
      `Promotion ${number} off`,
      `Promotion ${number} off`,
    ],
    new: (amount) => [
      `신년 ${amount} 할인`,
      `New Year Discount ${amount}`,
      's_pricing.discount_label.new',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  option_label: {
    feb_promo: [
      `2월 특별 할인`,
      `Feb. Discount`,
      's_pricing.option_label.feb_promo',
      `二月特价`,
      `二月特價`,
      `Feb. Discount`,
      `Feb. Discount`,
    ],
  },
  option_desc: {
    feb_promo_free: [
      <>
        <li className="li-tag-inside">자유로운 일정으로 수업을 듣고자 하는 분께 추천합니다.</li>
        <li className="li-tag-inside">
          첫 수업 시작 전 수강기간이 차감되지 않습니다. (구매 1년 후에는 자동 차감 됩니다.)
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[2월 특별 할인]</span> 12회 수업권이 추가 5% 할인됩니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">Recommended for users who do not wish to be tied to any fixed schedule.</li>
        <li className="li-tag-inside">
          Credits will only start to expire after you complete your first lesson (or one year after the purchase date if
          left unused).
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[Feb.Special]</span> An additional 5% discount is available on the 12
          credits.
        </li>
      </>,
      's_pricing.option_desc.feb_promo_free',
      <>
        <li className="li-tag-inside">推荐给希望自由安排上课时间的用户</li>
        <li className="li-tag-inside">
          课程券使用期限将从您完成第一堂课后开始计算(如一年后课程券都未使用，将自动开始计算使用期限）
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[二月特价]</span> 购买12次课程券多享5%折扣
        </li>
      </>,
      <>
        <li className="li-tag-inside">推薦給希望自由安排上課時間的用戶</li>
        <li className="li-tag-inside">
          課程券使用期限將從您完成第一堂課後開始計算(如一年後課程券都未使用，將自動開始計算使用期限）
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[二月特價]</span> 購買12次課程券多享5%折扣
        </li>
      </>,
      <>
        <li className="li-tag-inside">Recommended for users who do not wish to be tied to any fixed schedule.</li>
        <li className="li-tag-inside">
          Credits will only start to expire after you complete your first lesson (or one year after the purchase date if
          left unused).
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[Feb.Special]</span> An additional 5% discount is available on the 12
          credits.
        </li>
      </>,
      <>
        <li className="li-tag-inside">Recommended for users who do not wish to be tied to any fixed schedule.</li>
        <li className="li-tag-inside">
          Credits will only start to expire after you complete your first lesson (or one year after the purchase date if
          left unused).
        </li>
        <li className="li-tag-inside">
          <span className="text-[#F53428]">[Feb.Special]</span> An additional 5% discount is available on the 12
          credits.
        </li>
      </>,
    ],
    once_a_week: [
      <>
        <li className="li-tag-inside">꾸준한 영어 학습을 위해 주 1회 이상 필수로 수강해야 합니다.</li>
        <li className="li-tag-inside">수업권 구매 즉시 수강 기간이 차감됩니다.</li>
        <li className="li-tag-inside">주 1회 이상 수업을 듣지 않는 경우 수업권이 차감됩니다.</li>
        <li className="li-tag-inside">주 1회만 필수이며, 주 2회 이상 수강도 가능합니다.</li>
        <li className="li-tag-inside">매주 영어 공부 습관을 위해 수업 독려 알림을 드립니다.</li>
        <li className="li-tag-inside">수업 연기 혜택을 사용할 수 있습니다. (링글 고객 센터 문의)</li>
      </>,
      <>
        <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
        <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
        <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
        <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
        <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
        <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
      </>,
      's_pricing.option_desc.once_a_week',
      <>
        <li className="li-tag-inside">适合持续学习的用户.</li>
        <li className="li-tag-inside">从购买后开始计算使用期限.</li>
        <li className="li-tag-inside">不管上课与否，每週扣除一张课程券</li>
        <li className="li-tag-inside">每周至少上一堂课，也可以上超过一堂课</li>
        <li className="li-tag-inside">我们将提醒您每週上课</li>
        <li className="li-tag-inside">提供课程券延长优惠 (请联络Ringle客服团队).</li>
      </>,
      <>
        <li className="li-tag-inside">適合持續學習的用戶.</li>
        <li className="li-tag-inside">從購買後開始計算使用期限.</li>
        <li className="li-tag-inside">不管上課與否，每週扣除一張課程券</li>
        <li className="li-tag-inside">每周至少上一堂課，也可以上超過一堂課</li>
        <li className="li-tag-inside">我們將提醒您每週上課</li>
        <li className="li-tag-inside">提供課程券延長優惠 (請聯絡Ringle客服團隊).</li>
      </>,
      <>
        <li className="li-tag-inside">Ideal for consistent weekly English learners.</li>
        <li className="li-tag-inside">Expiration begins immediately upon purchase.</li>
        <li className="li-tag-inside">One credit is deducted weekly, regardless of lesson attendance.</li>
        <li className="li-tag-inside">Once a week is mandatory, but you can take more than once a week if you wish.</li>
        <li className="li-tag-inside">We provide reminders to encourage your weekly English study habits.</li>
        <li className="li-tag-inside">Deferral benefits available (contact the Ringle team).</li>
      </>,
      <>
        <li className="li-tag-inside">継続的な英語学習のために、週1回以上の受講が必須のレッスンチケットです。</li>
        <li className="li-tag-inside">レッスンチケットを購入するとすぐに受講期間が差し引かれます。</li>
        <li className="li-tag-inside">週1回以上のレッスンを受講しない場合、レッスンチケットが差し引かれます。</li>
        <li className="li-tag-inside">週1回のみ必須で、希望すれば週2回以上の受講も可能です。</li>
        <li className="li-tag-inside">毎週、英語学習習慣を身につけるために、受講を促すお知らせをお送りします。</li>
        <li className="li-tag-inside">
          授業延期の特典を利用することができます。 (Ringleカスタマーセンターにお問い合わせください)
        </li>
      </>,
    ],
  },
  secret_promo_feb: [
    `게릴라 할인`,
    `Flash Sale`,
    's_pricing.secret_promo_feb',
    `Flash Sale`,
    `Flash Sale`,
    `Flash Sale`,
    `Flash Sale`,
  ],
  foreign_event: {
    tab: [
      `이직/채용 특별전`,
      `Promotion`,
      's_pricing.foreign_event.tab',
      `Promotion`,
      `Promotion`,
      `Promotion`,
      `Promotion`,
    ],
    option_40min: [
      <>
        <li className="li-tag-inside">외국계 이직/채용 특별전은 40분 수업만 구매 가능합니다.</li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is only available for 40-minute lessons.</li>
      </>,
      's_pricing.foreign_event.option_40min',
      <>
        <li className="li-tag-inside">The global career event is only available for 40-minute lessons.</li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is only available for 40-minute lessons.</li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is only available for 40-minute lessons.</li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is only available for 40-minute lessons.</li>
      </>,
    ],
    option_none: [
      <>
        <li className="li-tag-inside">외국계 이직/채용 특별전은 자유수강만 구매 가능합니다.</li>
        <li className="li-tag-inside">
          첫 수업 시작 전 수강기간이 차감되지 않습니다. (구매 1년 후에는 자동 차감 됩니다.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is available for flexible scheduling only.</li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      's_pricing.foreign_event.option_none',
      <>
        <li className="li-tag-inside">The global career event is available for flexible scheduling only.</li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is available for flexible scheduling only.</li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is available for flexible scheduling only.</li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">The global career event is available for flexible scheduling only.</li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
    ],
    benefit_webinar: [
      `영문 이력서/인터뷰 웨비나`,
      `Resume/Interview Webinar`,
      's_pricing.foreign_event.benefit_webinar',
      `Resume/Interview Webinar`,
      `Resume/Interview Webinar`,
      `Resume/Interview Webinar`,
      `Resume/Interview Webinar`,
    ],
    benefit_email: [
      `이력서 이메일 피드백 (1회)`,
      `Email feedback on English resume (1 time)`,
      's_pricing.foreign_event.benefit_email',
      `Email feedback on English resume (1 time)`,
      `Email feedback on English resume (1 time)`,
      `Email feedback on English resume (1 time)`,
      `Email feedback on English resume (1 time)`,
    ],
    benefit_one_on_one: [
      `이력서 1:1 화상 피드백 (1회)`,
      `1:1 video feedback on English resume (1 time)`,
      's_pricing.foreign_event.benefit_one_on_one',
      `1:1 video feedback on English resume (1 time)`,
      `1:1 video feedback on English resume (1 time)`,
      `1:1 video feedback on English resume (1 time)`,
      `1:1 video feedback on English resume (1 time)`,
    ],
    tooltip_webinar: [
      `해외 MBA 및 취업 경험 있는 연사들이 진행한 7개의 영문 이력서/ 인터뷰 웨비나 무제한 열람 가능`,
      `Unlimited access to 7 webinars on English resumes/interviews conducted by speakers with overseas work experience`,
      's_pricing.foreign_event.tooltip_webinar',
      `Unlimited access to 7 webinars on English resumes/interviews conducted by speakers with overseas work experience`,
      `Unlimited access to 7 webinars on English resumes/interviews conducted by speakers with overseas work experience`,
      `Unlimited access to 7 webinars on English resumes/interviews conducted by speakers with overseas work experience`,
      `Unlimited access to 7 webinars on English resumes/interviews conducted by speakers with overseas work experience`,
    ],
    tooltip_email: [
      <>
        이메일로 영문 이력서 1회 피드백
        <br /> (*신청기한 : 2024.4.19(금) 23:59 KST)
      </>,
      <>
        Email feedback on English resume once
        <br /> (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      's_pricing.foreign_event.tooltip_email',
      <>
        Email feedback on English resume once
        <br /> (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        Email feedback on English resume once
        <br /> (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        Email feedback on English resume once
        <br /> (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        Email feedback on English resume once
        <br /> (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
    ],
    tooltip_one_on_one: [
      <>
        1:1 화상으로 영문 이력서 1회 피드백
        <br />
        (*신청기한 : 2024.4.19(금) 23:59 KST)
      </>,
      <>
        1:1 video feedback on English resume once
        <br />
        (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      's_pricing.foreign_event.tooltip_one_on_one',
      <>
        1:1 video feedback on English resume once
        <br />
        (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        1:1 video feedback on English resume once
        <br />
        (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        1:1 video feedback on English resume once
        <br />
        (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
      <>
        1:1 video feedback on English resume once
        <br />
        (*Application deadline: 2024.4.19 (Fri) 23:59 KST)
      </>,
    ],
    discount_label: (amount) => [
      `특별전 할인 ${amount}`,
      `Special discount ${amount}`,
      's_pricing.foreign_event.discount_label',
      `Special discount ${amount}`,
      `Special discount ${amount}`,
      `Special discount ${amount}`,
      `Special discount ${amount}`,
    ],
    test_title: [
      `이직/채용 특별전 수업권`,
      `Global Career Event`,
      's_pricing.foreign_event.test_title',
      `Global Career Event`,
      `Global Career Event`,
      `Global Career Event`,
      ``,
    ],
    test_desc: [
      <>
        <li className="li-tag-inside">외국계 채용/이직 특별전 수업권은 40분 자유 수강 수업으로 구성되어 있습니다.</li>
        <li className="li-tag-inside">
          첫 수업 시작 전 수강기간이 차감되지 않습니다. (구매 1년 후에는 자동 차감 됩니다.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          The global career event is only available for 40-minute and flexible scheduling lessons.
        </li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      's_pricing.foreign_event.test_desc',
      <>
        <li className="li-tag-inside">
          The global career event is only available for 40-minute and flexible scheduling lessons.
        </li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          The global career event is only available for 40-minute and flexible scheduling lessons.
        </li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          The global career event is only available for 40-minute and flexible scheduling lessons.
        </li>
        <li className="li-tag-inside">
          Lesson duration is not deducted before the first lesson starts. (Automatic deduction starts after 1 year of
          purchase.)
        </li>
      </>,
      ``,
    ],
  },
  twentyfour_hour: {
    title: [
      `가입 후 24시간 내 구매 시 추가 수업권 증정`,
      `Get free credits with purchase within 24 hrs of sign-up.`,
      's_pricing.twentyfour_hour.title',
      `加入会员后24小时内购买课程时，额外赠送课程券！`,
      `加入會員後24小時內購買課程時，額外贈送課程券！`,
      `入会後24時間以内にレッスンチケットを購入すると、追加のレッスンチケットがもらえます！`,
      ``,
    ],
    content: [
      <>
        <br />
        [자유 수강 수업권 1회 추가 증정]
        <br />
        4회, 10회 수업권
        <br />
        <br />
        [자유 수강 수업권 2회 추가 증정]
        <br />
        20회 수업권
        <br />
        <br />
        [자유 수강 수업권 3회 추가 증정]
        <br />
        30회 수업권
      </>,
      <>
        <br />
        <b>[1 additional Flexible credit]</b>
        <br />
        For purchases of 4 or 10 credits.
        <br />
        <br />
        <b>[2 additional Flexible credits]</b>
        <br />
        For purchases of 20 credits.
        <br />
        <br />
        <b>[3 additional Flexible credits]</b>
        <br />
        For purchases of 30 credits.
      </>,
      's_pricing.twentyfour_hour.content',
      <>
        <br />
        [赠送1张正规课程券]
        <br />
        购买4次, 10次课程券
        <br />
        <br />
        [赠送2张正规课程券]
        <br />
        购买20次课程券
        <br />
        <br />
        [赠送3张正规课程券]
        <br />
        购买30次课程券
      </>,
      <>
        <br />
        [贈送1張正規課程券]
        <br />
        購買4次, 10次課程券
        <br />
        <br />
        [贈送2張正規課程券]
        <br />
        購買20次課程券
        <br />
        <br />
        [贈送3張正規課程券]
        <br />
        購買30次課程券
      </>,
      <>
        <br />
        [追加の無料レッスンチケット1回分]
        <br />
        レッスンチケット4回分 or 10回分購入
        <br />
        <br />
        [追加の無料レッスンチケット2回分]
        <br />
        レッスンチケット20回分購入
        <br />
        <br />
        [追加の無料レッスンチケット3回分]
        <br />
        レッスンチケット30回分購入
      </>,
      ``,
    ],
    add_on: (number) => [
      <>+ {number}회 (24시간 내 구매시)</>,
      <>+ {number} Credit(s) (buying within 24hrs)</>,
      's_pricing.twentyfour_hour.add_on',
      <>+ {number} Credit(s) (24小时内购买时)</>,
      <>+ {number} Credit(s) (24小時內購買時)</>,
      <>+ {number} Credit(s) (24時間以内に購入)</>,
      ``,
    ],
  },
  ten_anni: {
    discount_label: (amount) => [
      `10주년 ${amount}원 할인`,
      `Special ${amount} discount`,
      's_pricing.ten_anni.discount_label',
      ``,
      ``,
      `Special ${amount} discount`,
      ``,
    ],
    study_club: [
      `링글 스터디 클럽`,
      `Ringle Study Club`,
      's_pricing.ten_anni.study_club',
      ``,
      ``,
      `Ringle Study Club`,
      ``,
    ],
    study_club_tooltip: [
      `링글러와 함께하는 스터디클럽 참여 기회 제공 (이벤트 페이지에서 바로 신청가능))`,
      `Join the Study Club with Ringlers (apply via Event page).`,
      's_pricing.ten_anni.study_club_tooltip',
      ``,
      ``,
      `Join the Study Club with Ringlers (apply via email after buying credits).`,
      ``,
    ],
    title: [`24시간 내 구매 시 추가 수업권 증정`, ``, 's_pricing.ten_anni.title', ``, ``, ``, ``],
    content: [
      <>
        24시간 이내 수업권을 구매하면 수업권을 추가로 드립니다. <br />
        [자유 수강 수업권 1회 추가 증정]
        <br />
        4회, 10회 수업권
        <br />
        <br />
        [자유 수강 수업권 3회 추가 증정]
        <br />
        20회, 30회 수업권
      </>,
      ``,
      's_pricing.ten_anni.content',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  b2b2c_banner_thankyou: {
    label: [
      `기업 코드 고객 감사제 (~3.29)`,
      `B2B Code Event (until Mar. 29th)`,
      's_pricing.b2b2c_banner_thankyou.label',
      `B2B Code Event (until Mar. 29th)`,
      `B2B Code Event (until Mar. 29th)`,
      `B2B Code Event (until Mar. 29th)`,
      ``,
    ],
    content: [
      `추가 수업권 증정 or 추가 할인 진행 중!`,
      `Extra credits or additional discounts available now!`,
      's_pricing.b2b2c_banner_thankyou.content',
      `Extra credits or additional discounts available now!`,
      `Extra credits or additional discounts available now!`,
      `Extra credits or additional discounts available now!`,
      ``,
    ],
  },
  b2b2c: {
    label_thankyou: [
      `감사제 할인 (~3/29)`,
      `Special Discount (until Mar. 29th)`,
      's_pricing.b2b2c.label_thankyou',
      `Special Discount (until Mar. 29th)`,
      `Special Discount (until Mar. 29th)`,
      `Special Discount (until Mar. 29th)`,
      ``,
    ],
  },
  career_up: {
    secret_chat: {
      title: [
        `커리어 시크릿 채팅방`,
        `Career Chat Room Access`,
        's_pricing.career_up.secret_chat.title',
        ``,
        ``,
        ``,
        ``,
      ],
      tooltip: [
        `직군별 정보 교환부터 비공개 세미나까지 들을 수 있는 커리어 시크릿 채팅방 참여 기회 (5/2 목요일 링크 개별 발송)`,
        `Join for job tips and private seminars. (Links sent 5/2)`,
        's_pricing.career_up.secret_chat.tooltip',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    networking: {
      title: [`직군별 네트워킹`, `Sector-Specific Networking`, 's_pricing.career_up.networking.title', ``, ``, ``, ``],
      tooltip: [
        `IT / 마케팅 / 경영 등 직군별 오프라인 네트워킹 행사 참여 기회 제공 (5/2 목요일 신청서 개별 발송)`,
        `Join events for IT, marketing, management, etc. (Forms sent 5/2)`,
        's_pricing.career_up.networking.tooltip',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    coffeechat: {
      title: [`1:1 커리어 커피챗`, `1:1 Career Coffee Chats`, 's_pricing.career_up.coffeechat.title', ``, ``, ``, ``],
      tooltip: [
        `직군별 / 외국계 경험자들과의 1:1 커피챗 기회 제공 (5/2 목요일 신청서 개별 발송)`,
        `Chat with experts in your field or from international firms. (Forms sent 5/2)`,
        's_pricing.career_up.coffeechat.tooltip',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const s_menu = {
  not_supported_features_in_english_mode: [
    `언어를 영어로 변경하면, 지원되지 않는 기능이 있을 수 있습니다.`,
    `Some features may not be available in English mode.`,
    's_menu.not_supported_features_in_english_mode',
    `将语言变更为英文时，可能不支持部分功能。`,
    `將語言變更為英文時，可能不支持部分功能。`,
    `言語設定を英語に変更すると、対応していない機能がある場合があります。`,
    `Một số tính năng sẽ không hiển thị tiếng Anh.`,
  ],
  portal: {
    conversation: [
      `1:1 회화`,
      `1:1 Lessons`,
      's_menu.portal.conversation',
      `1:1会话`,
      `1:1會話`,
      `1on1レッスン`,
      `Buổi học`,
    ],
    editing_service: [
      `영문서 교정`,
      `Editing&proof reading`,
      's_menu.portal.editing_service',
      `英文书面文本校正`,
      `糾正英文文件`,
      `英文校正`,
      `Editing&proof reading`,
    ],
    webinar: [`웨비나`, `Webinar`, 's_menu.portal.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinar`],
    webinar_and_course: [
      `웨비나/교재`,
      `Webinar/Materials`,
      's_menu.portal.webinar_and_course',
      `网络研讨会/教材`,
      `網路研討會／教材`,
      `ウェビナー/教材`,
      `Webinar/Tài liệu`,
    ],
    purchase_lessons: [`구매`, `Buy now`, 's_menu.portal.purchase_lessons', `购买`, `購買`, `購入`, `Mua ngay`],
    invite: [`친구초대`, `Invite`, 's_menu.portal.invite', `邀请朋友`, `邀請朋友`, `友達紹介`, `Mời bạn`],
    faq: [`Help Center`, `Help`, 's_menu.portal.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
    notice: [`공지사항`, `Updates`, 's_menu.portal.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
    mypage: [`내 페이지`, `My Page`, 's_menu.portal.mypage', `我的页面`, `我的頁面`, `マイページ`, `Trang của tôi`],
    index: [`번호`, `Index`, 's_menu.portal.index', `编号`, `編號`, `見出し`, ``],
    title: [`제목`, `Title`, 's_menu.portal.title', `标题`, `標題`, `タイトル`, ``],
    date: [`등록일`, `Date`, 's_menu.portal.date', `日期`, `日期`, `日付`, ``],
  },
  portalv2: {
    home: [`홈`, `Home`, 's_menu.portalv2.home', `主页`, `主頁`, `Home`, `Trang chủ`],
    conversation: [
      `1:1 수업`,
      `1:1 Lessons`,
      's_menu.portalv2.conversation',
      `1:1课程`,
      `1:1課程`,
      `1on1レッスン`,
      `Buổi học`,
    ],
    original: [`콘텐츠`, `Content`, 's_menu.portalv2.original', `内容`, `內容`, `コンテンツ`, `Nội dung`],
    history: [`학습 활동`, `Activities`, 's_menu.portalv2.history', `学习活动`, `學習活動`, `学習内容`, `Hoạt động`],
    editing_service: [
      `영문서 교정`,
      `Editing&proof reading`,
      's_menu.portalv2.editing_service',
      `英文书面文本校正`,
      `糾正英文文件`,
      `英文校正`,
      `Editing&proof reading`,
    ],
  },
  mypage: {
    my_info: [
      `계정 관리`,
      `My Account`,
      's_menu.mypage.my_info',
      `账户管理`,
      `帳戶管理`,
      `アカウント管理`,
      `Tài khoản của tôi`,
    ],
    coupon_certificates: [
      `수업권/증명서류`,
      `Credits`,
      's_menu.mypage.coupon_certificates',
      `课程券/证明材料`,
      `課程券／證明`,
      `レッスンチケット/証明書類`,
      `Thẻ học`,
    ],
    coupon_box: [`쿠폰함`, `Coupons`, 's_menu.mypage.coupon_box', `优惠券`, `優惠券`, `クーポン`, `Coupons`],
    my_point: [`포인트`, `Points`, 's_menu.mypage.my_point', `积分`, `點數`, `ポイント`, `Điểm`],
    faq: [`고객센터`, `Help Center`, 's_menu.mypage.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Trợ giúp`],
    notice: [`공지사항`, `Updates`, 's_menu.mypage.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
    zoom_install: [
      `Zoom 설치 가이드`,
      `How to Install Zoom`,
      's_menu.mypage.zoom_install',
      `安装Zoom指南`,
      `Zoom安裝指南`,
      `Zoomインストールガイド`,
      `Hướng dẫn cài đặt Zoom`,
    ],
    sign_out: [`로그아웃`, `Logout`, 's_menu.mypage.sign_out', `退出`, `登出`, `ログアウト`, `Đăng xuất`],
    sign_out_message: [
      `로그아웃 하시겠습니까?`,
      `Are you sure you want to log out?`,
      's_menu.mypage.sign_out_message',
      `确定要退出吗?`,
      `確定要登出嗎？`,
      `ログアウトしますか？`,
      `Bạn có chắc muốn thoát ra?`,
    ],
    info: [
      `계정관리`,
      `My Account`,
      's_menu.mypage.info',
      `账户管理`,
      `帳戶管理`,
      `アカウント管理`,
      `Tài khoản của tôi`,
    ],
    timezone_language: [
      <>타임존 & 언어</>,
      <>Time zone & Language</>,
      's_menu.mypage.timezone_language',
      <>时区 & 语言</>,
      <>時區 & 語言</>,
      <>タイムゾーン & 言語</>,
      <>Múi giờ & Ngôn ngữ</>,
    ],
    change: [`변경`, `Update`, 's_menu.mypage.change', `变更`, `變更`, `変更`, `Cập nhật`],
    account: [`계정`, `Account`, 's_menu.mypage.account', `账户`, `帳戶`, `アカウント`, `Tài khoản`],
  },
  conversation: {
    trial_lesson: [
      `체험 신청`,
      `Trial Lesson`,
      's_menu.conversation.trial_lesson',
      `申请体验`,
      `申請體驗`,
      `体験レッスン予約`,
      `Buổi học thử`,
    ],
    schedule_lesson: [`예약`, `Book`, 's_menu.conversation.schedule_lesson', `预约`, `預約`, `予約`, `Đặt lịch`],
    upcoming_lessons: (count) => [
      `예습 & 수업 (${count})`,
      `Prep & Lesson (${count})`,
      's_menu.conversation.upcoming_lessons',
      `预习&课程 (${count})`,
      `預習 & 課程（${count}）`,
      `予習 & レッスン (${count})`,
      `Tài liệu Chuẩn bị & buổi học (${count})`,
    ],
    lesson_statistics: [
      `수업 통계`,
      `Stats`,
      's_menu.conversation.lesson_statistics',
      `课程统计`,
      `課程統計`,
      `レッスン統計`,
      `Thống kê`,
    ],
    review_lessons: [`복습`, `Review`, 's_menu.conversation.review_lessons', `复习`, `複習`, `復習`, `Đánh giá`],
    lesson_materials: [`교재`, `Materials`, 's_menu.conversation.lesson_materials', `教材`, `教材`, `教材`, `Tài liệu`],
    tutors: [`튜터`, `Tutors`, 's_menu.conversation.tutors', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
    ringle_guide: [
      `링글 이용방법`,
      `How Ringle works`,
      's_menu.conversation.ringle_guide',
      `Ringle教学方法`,
      `Ringle教學法`,
      `Ringleのレッスン方法`,
      `Cách thức Ringle hoạt động`,
    ],
    testimonials: [
      `후기`,
      `Testimonials`,
      's_menu.conversation.testimonials',
      `评价`,
      `心得`,
      `レビュー`,
      `Chia sẻ từ khách hàng`,
    ],
    memo: [`메모`, `Memo`, 's_menu.conversation.memo', ``, ``, ``, ``],
    homework: [`숙제`, `Homework`, 's_menu.conversation.homework', `作业`, `作業`, `宿題`, `Bài tập`],
    new_upcoming_lessons: [
      `예정된 수업`,
      `Upcoming Lesson`,
      's_menu.conversation.new_upcoming_lessons',
      `已预约的课程`,
      `已預約的課程`,
      `予定されたレッスン`,
      `Các buổi học theo lịch trình`,
    ],
    new_review_lessons: [
      `지난 수업`,
      `Review`,
      's_menu.conversation.new_review_lessons',
      `已完成的课程`,
      `已完成的課程`,
      `前回のレッスン`,
      ``,
    ],
  },
  writing: {
    edit_request: [
      `교정 요청`,
      `Requested Edits`,
      's_menu.writing.edit_request',
      `校正申请`,
      `申請糾正`,
      `校正リクエスト`,
      `Yêu cầu chỉnh sửa`,
    ],
    ongoing_edits: [
      `진행 중인 교정`,
      `Edits in Progress`,
      's_menu.writing.ongoing_edits',
      `进行中的校正`,
      `正在進行的糾正`,
      `進行中の校正`,
      `Đang chỉnh sửa`,
    ],
    completed_edits: [
      `완료된 교정`,
      `Completed Edits`,
      's_menu.writing.completed_edits',
      `已完成的校正`,
      `已完成的糾正`,
      `完了した校正`,
      `Hoàn tất chỉnh sửa`,
    ],
  },
  original: {
    webinar: [`웨비나`, `Webinars`, 's_menu.original.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinars`],
    course: [`교재`, `Materials`, 's_menu.original.course', `教材`, `教材`, `教材`, `Tài liệu`],
    my: [`내 콘텐츠`, `My Content`, 's_menu.original.my', `我的收藏`, `我的收藏`, `マイコンテンツ`, `Nội dung của tôi`],
    dailybrief: [
      `데일리 브리프`,
      `Daily Brief`,
      's_menu.original.dailybrief',
      `Daily Brief`,
      `Daily Brief`,
      `Daily Brief`,
      `Daily Brief`,
    ],
  },
  landing: {
    why_ringle: [
      `What is Ringle`,
      `What is Ringle`,
      's_menu.landing.why_ringle',
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
    ],
    by_customer: [
      `수강 대상`,
      `By Customer`,
      's_menu.landing.by_customer',
      `课程对象`,
      `課程對象`,
      `対象別`,
      `Theo khách hàng`,
    ],
    by_purpose: [`수강 목적`, `By Need`, 's_menu.landing.by_purpose', `课程目标`, `課程目標`, `目的別`, `Theo nhu cầu`],
    ringle_tip: [
      `Ringle Tip`,
      `Testimonials`,
      's_menu.landing.ringle_tip',
      `Ringle Tip`,
      `Ringle Tip`,
      `Ringle Tip`,
      `Khách hàng`,
    ],
    beginner: [
      `영어 초중급자`,
      `Beginner-Intermediate`,
      's_menu.landing.beginner',
      `英语初/中级学员`,
      `英語初／中級者`,
      `英語初中級者向け`,
      `Cơ bản-Trung cấp`,
    ],
    signin: [`로그인`, `Log in`, 's_menu.landing.signin', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    free_trial: [`무료 체험`, `Sign up`, 's_menu.landing.free_trial', `免费体验`, `免費體驗`, `無料体験`, `Đăng ký`],
    free_trial_btn: [
      `무료 체험`,
      `Free Trial`,
      's_menu.landing.free_trial_btn',
      `免费体验`,
      `免費體驗`,
      `無料体験`,
      `Học thử miễn phí`,
    ],
    service_intro: [
      `전체보기`,
      `Overview`,
      's_menu.landing.service_intro',
      `服务介绍`,
      `服務介紹`,
      `サービス紹介`,
      `Tổng quan`,
    ],
    portal: [`내 강의실`, `My Lessons`, 's_menu.landing.portal', `我的教室`, `我的教室`, `マイレッスン`, `Buổi học`],
    conversation: [
      `What is Ringle`,
      `What is Ringle`,
      's_menu.landing.conversation',
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
    ],
    tutor: [`튜터`, `Tutors`, 's_menu.landing.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
    lesson_materials: [`교재`, `Materials`, 's_menu.landing.lesson_materials', `教材`, `教材`, `教材`, `Tài liệu`],
    review: [`후기`, `Testimonials`, 's_menu.landing.review', `评价`, `心得`, `レビュー`, `Khách hàng`],
    lesson_process: [
      `사용법`,
      `How Ringle works`,
      's_menu.landing.lesson_process',
      `使用方法`,
      `使用方法`,
      `受講の流れ`,
      `Cách thức hoạt động`,
    ],
    lesson_system: [
      `수업 시스템`,
      `Features`,
      's_menu.landing.lesson_system',
      `课程系统`,
      `課程系統`,
      `レッスンシステム`,
      `Tính năng nổi bật`,
    ],
    solution: [
      `Ringle for`,
      `Ringle for`,
      's_menu.landing.solution',
      `Ringle for`,
      `Ringle for`,
      `Ringle for`,
      `Ringle for`,
    ],
    pricing: [`가격`, `Pricing`, 's_menu.landing.pricing', `Pricing`, `Pricing`, `料金`, `Pricing`],
    business: [
      `비즈니스 영어`,
      `Business English`,
      's_menu.landing.business',
      `商务英语`,
      `商務英語`,
      `ビジネス英語`,
      <>
        Tiếng Anh
        <br /> thương mại
      </>,
    ],
    correction: [
      `이메일/에세이 교정`,
      `Resume & Essay Editing`,
      's_menu.landing.correction',
      `电子邮件/短文校正`,
      `糾正電子郵件／申論`,
      `文書添削`,
      `Chỉnh sửa Resume & Bài luận`,
    ],
    prep_interview: [
      `인터뷰 준비`,
      `Interview Prep`,
      's_menu.landing.prep_interview',
      `英文面试准备`,
      `英語面試準備`,
      `英語面接準備`,
      <>
        Chuẩn bị
        <br /> phỏng vấn
      </>,
    ],
    pronounce: [
      <>발음 교정 (미국/영국)</>,
      <>Pronunciation</>,
      's_menu.landing.pronounce',
      <>发音矫正(美式/英式)</>,
      <>矯正發音（英美式）</>,
      <>発音矯正 (米/英国)</>,
      <>Phát âm</>,
    ],
    office_worker: [
      `직장인/전문직`,
      `Global Professionals`,
      's_menu.landing.office_worker',
      `公司职员/专业人士`,
      `上班族／專業人員`,
      `会社員/専門職向け`,
      `Nhân viên toàn cầu`,
    ],
    professional: [`전문직 종사자`, ``, 's_menu.landing.professional', ``, ``, ``, ``],
    study_abroad: [
      `유학생`,
      `International Students`,
      's_menu.landing.study_abroad',
      `留学生`,
      `留學生`,
      `海外留学生向け`,
      `Du học sinh`,
    ],
    junior: [
      `초중고 학생`,
      `K-12`,
      's_menu.landing.junior',
      `小学/初中/高中学生`,
      `國小、國中、高中生`,
      `小中高生向け`,
      `K-12`,
    ],
    prep_exam: [
      `공인영어시험 대비`,
      `Exam Prep`,
      's_menu.landing.prep_exam',
      `专业英语备考`,
      `準備英語檢定`,
      `英語資格試験対策`,
      `Luyện thi`,
    ],
    live_abroad: [
      `해외 거주자`,
      `Overseas Residents`,
      's_menu.landing.live_abroad',
      `海外居住者`,
      `海外居住者`,
      `海外在住者向け`,
      `Người định cư ở nước ngoài`,
    ],
    b2b: [`기업제휴`, `Business`, 's_menu.landing.b2b', `企业合作`, `企業合作`, `業務提携`, `Business`],
    event: [`이벤트`, `Event`, 's_menu.landing.event', `活动`, `活動`, `イベント`, `Sự kiện`],
    faq: [`Help Center`, `Help Center`, 's_menu.landing.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
    language: [`언어`, `Language`, 's_menu.landing.language', `语言`, `語言`, `言語`, `Ngôn ngữ`],
    free_trial_btn_2: [
      `무료체험`,
      `Free Trial`,
      's_menu.landing.free_trial_btn_2',
      `注册`,
      `註冊`,
      `無料会員登録`,
      `Đăng ký`,
    ],
    signup: [`회원가입`, `Sign-up`, 's_menu.landing.signup', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
    login: [`로그인`, `Log in`, 's_menu.landing.login', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    bosnychallenge: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_menu.landing.bosnychallenge',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    event_title: [`이벤트`, ``, 's_menu.landing.event_title', ``, ``, ``, ``],
    event_list_1: [`봄맞이 대학생 할인`, ``, 's_menu.landing.event_list_1', ``, ``, ``, ``],
    event_list_2: [`제4회 링글 공모전`, ``, 's_menu.landing.event_list_2', ``, ``, ``, ``],
    blog: [`블로그`, ``, 's_menu.landing.blog', ``, ``, ``, ``],
  },
  statistics: {
    all: [`전체`, `All`, 's_menu.statistics.all', `全部`, `全部`, `すべて`, `Tất cả`],
    read: [`읽기`, `Reading`, 's_menu.statistics.read', `阅读`, `閱讀`, `読み`, `Đọc`],
    listen: [`듣기`, `Listening`, 's_menu.statistics.listen', `听力`, `聽力`, `聞取り`, `Nghe`],
    write: [`쓰기`, `Writing`, 's_menu.statistics.write', `写作`, `寫作`, `書き`, `Viết`],
    speak: [`말하기`, `Speaking`, 's_menu.statistics.speak', `口语`, `口說`, `話す`, `Nói`],
  },
  return_prev: [
    `이전 페이지로 돌아가기`,
    `Return to previous page`,
    's_menu.return_prev',
    `返回上一页`,
    `返回上一頁`,
    `前のページに戻る`,
    `Trở về trang trang trước`,
  ],
  sir: [`님`, ``, 's_menu.sir', `用户`, `先生／小姐`, `さん`, ``],
  change_language: [
    `언어 변경`,
    `Change Language`,
    's_menu.change_language',
    `变换语言`,
    `變更語言`,
    `言語変更`,
    `Đổi ngôn ngữ`,
  ],
}
export const s_landing = {
  get_started: [
    `무료체험 수업 신청하기`,
    `Sign up`,
    's_landing.get_started',
    `申请免费咨询`,
    `申請免費諮詢`,
    `無料相談お申込み`,
    `Đăng ký`,
  ],
  get_started_free_trial: [
    <>
      지금 링글을 시작해 보세요!
      <br />
      회원가입 시 1회 체험 수업 무료
    </>,
    <>Start now with a free trial</>,
    's_landing.get_started_free_trial',
    <>
      现在就请使用Ringle吧!
      <br />
      首次课程不满意时, 可100%退款
    </>,
    <>
      現在就使用Ringle吧！
      <br />
      首次課程不滿意時，可全額退款
    </>,
    <>
      さあ、Ringleを始めましょう！
      <br />
      ご満足いただけなければ全額返金保証
    </>,
    <>Học thử miễn phí</>,
  ],
  signup_check_out: [
    `무료체험 신청하기`,
    `Register for free trial`,
    's_landing.signup_check_out',
    `注册`,
    `註冊`,
    `Đăng ký`,
    `無料会員登録`,
  ],
  get_started_signup: [
    <>첫 수업 불만족 시, 100% 환불 가능</>,
    <>100% Money-back guarantee.</>,
    's_landing.get_started_signup',
    <>首次课程不满意时, 承诺100%退款</>,
    <>首次課程不滿意時，可全額退款</>,
    <>初回レッスン後にご満足いただけない場合、全額返金</>,
    <>Hoàn tiền 100%.</>,
  ],
  get_signup: [
    `무료체험 신청하기`,
    `Register for free trial`,
    's_landing.get_signup',
    `注册`,
    `註冊`,
    `Đăng ký`,
    `無料会員登録`,
  ],
  get_started_counsel: [<>무료 상담 신청</>, <>Book 1:1 Advising</>, 's_landing.get_started_counsel', ``, ``, ``, ``],
  gnb: {
    welcome_coupon: [
      <>
        지금 가입하면{' '}
        <Div spanTag fontBold>
          3만원 웰컴쿠폰
        </Div>
        을 드려요
      </>,
      <>
        Join now for{' '}
        <Div spanTag fontBold>
          extra $25
        </Div>{' '}
        off
      </>,
      's_landing.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome_coupon_otherpage_btn: [
    `지금 신규가입 혜택받기`,
    `Get max.$77 off Newcomer Offer`,
    's_landing.welcome_coupon_otherpage_btn',
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
  ],
  button: {
    title: [`한국어`, `영어`, 's_landing.button.title', ``, ``, ``, ``],
  },
}
export const s_home = {
  more_than_offline: [
    <>
      링글,
      <br />
      No.1 일대일 화상영어
    </>,
    <>
      The <Span primary>New Standard</Span>
      <br />
      in Online English Learning
    </>,
    's_home.more_than_offline',
    <>
      Ringle,
      <br />
      No.1的1对1线上英语
    </>,
    <>
      Ringle，
      <br />
      No.1的1:1視訊英語
    </>,
    <>
      1on1オンライン英会話の
      <br />
      新時代を駆けるRingle
    </>,
    <>
      <Span primary>Nâng tầm</Span>
      <br />
      việc học tiếng Anh Online
    </>,
  ],
  from_stanford: [
    <>
      아이비리그 출신 원어민 튜터와 함께하는
      <br />
      나만을 위한 1:1 화상영어 수업!
    </>,
    <>1:1 English lessons with top university students & graduates</>,
    's_home.from_stanford',
    <>
      携手常青藤联盟出身的英文母语教师
      <br />
      专为我打造的1:1线上英语课程!
    </>,
    <>
      與常春藤名校的母語任課教師
      <br />
      一起進行專屬於我的1:1視訊英語課程！
    </>,
    <>
      アイビーリーグ大学出身のネイティブチューターが教える
      <br />
      マンツーマンのオンライン英会話レッスン！
    </>,
    <>Học tiếng Anh 1:1 cùng các sinh viên và cựu sinh viên các trường đại học hàng đầu</>,
  ],
  not_just_english: [
    <>
      당신이 꿈꾸던 영어실력과 커리어,
      <br />
      링글에선 모두 현실이 됩니다
    </>,
    <>
      Unlock your global potential
      <br />
      Learn about Ringle's top use cases
    </>,
    's_home.not_just_english',
    <>
      您希望的英文实力及职业,
      <br />
      在Ringle中均将梦想成真。
    </>,
    <>
      您夢寐以求的英文實力及職涯，
      <br />
      將在Ringle全部實現。
    </>,
    <>
      あなたが夢見ていた英語力とキャリア、
      <br />
      Ringleですべて実現できます。
    </>,
    <>
      Khai mở tiềm năng của bạn
      <br />
      Tìm hiểu những đối tượng thường sử dụng Ringle
    </>,
  ],
  tutors: [
    <Fragment>
      <b>똑똑한 튜터와의 맞춤 수업</b>
      <br />
      <br />
      1000+명 이상의 검증된
      <br />
      영미권 명문대 원어민 튜터
    </Fragment>,
    <>
      <b>Manually vetted and trained tutors</b>
      <br />
      1000+ tutors from top universities{' '}
    </>,
    's_home.tutors',
    <Fragment>
      <b>和优秀教师间的定制型课程</b>
      <br />
      <br />
      经1000人以上的验证
      <br />
      英美地区, 名校母语教师
    </Fragment>,
    <Fragment>
      <b>與優秀任課教師的客製化課程</b>
      <br />
      <br />
      1000名以上眾所公認的
      <br />
      英美名校母語任課教師
    </Fragment>,
    <Fragment>
      <b>精鋭揃いのチューターとカスタマイズレッスン</b>
      <br />
      <br />
      英米名門校出身のネイティブチューター
      <br />
      1000名以上在籍
    </Fragment>,
    <>
      <b>Được đào tạo và hướng dẫn bài bản</b>
      <br />
      1000+ gia sư từ các trường đại học hàng đầu{' '}
    </>,
  ],
  tutors_title: [
    <Fragment>똑똑한 튜터와의 맞춤 수업</Fragment>,
    <>Manually vetted and trained tutors</>,
    's_home.tutors_title',
    <Fragment>和优秀教师间的定制型课程</Fragment>,
    <Fragment>與優秀任課教師的客製化課程</Fragment>,
    <Fragment>精鋭揃いのチューターとカスタマイズレッスン</Fragment>,
    <>Được đào tạo và hướng dẫn bài bản</>,
  ],
  tutors_subtitle: [
    <Fragment>
      2,000명 이상의 검증된
      <Br md down /> 영미권 명문대 원어민 튜터
    </Fragment>,
    <>2,000+ Tutors from top universities</>,
    's_home.tutors_subtitle',
    <Fragment>
      经过验证的
      <Br md down /> 英美地区名校母语教师2000多位
    </Fragment>,
    <Fragment>
      2000名以上眾所公認的
      <Br md down />
      英美名校母語任課教師
    </Fragment>,
    <Fragment>
      英米名門校出身のネイティブチューター
      <Br md down /> 2000名以上在籍
    </Fragment>,
    <>2000+ gia sư từ các trường đại học hàng đầu</>,
  ],
  with_a_major_of_your_chice: [
    <>
      전공/경력/관심사/발음 등을 미리 확인하고&nbsp;
      <Br md down />
      원하는 튜터를 직접 선택하세요
    </>,
    ``,
    's_home.with_a_major_of_your_chice',
    <>
      提前确认专业/经历/兴趣/发音等内容&nbsp;
      <Br md down />
      可以直接选择您希望的教师
    </>,
    <>
      請事先確認主修／經歷／感興趣的領域／發音等內容，&nbsp;
      <Br md down />
      親自選擇您希望的任課教師。
    </>,
    <>
      専攻・経歴・興味/関心・アクセントなどから&nbsp;
      <Br md down />
      お好みのチューターを選んでみましょう。
    </>,
    ``,
  ],
  ai_platform: [
    <>
      <b>말하고 사라지던 영어의 완전한 시각화</b>
      <br />
      <br />
      링글만의 차별화된 화상영어 시스템
    </>,
    <>
      <b>Comprehensive Feedback </b>
      <br />
      Review key corrections and receive personalized feedback
    </>,
    's_home.ai_platform',
    <>
      <b>将出口无形的英语可视化</b>
      <br />
      <br />
      别具一格的线上英语系统, 只在Ringle
    </>,
    <>
      <b>將一說即逝的英語完全視覺化</b>
      <br />
      <br />
      Ringle與眾不同的視訊英語系統
    </>,
    <>
      <b>視覚化された英会話学習を体験</b>
      <br />
      <br />
      Ringle独自のオンライン英会話システム
    </>,
    <>
      <b>Đánh giá toàn diện </b>
      <br />
      Xem lại những chỉnh sửa chính và nhận phản hồi được cá nhân hoá
    </>,
  ],
  ai_platform_title: [
    <>말하고 사라지던 영어의 완전한 시각화</>,
    <>Comprehensive Feedback</>,
    's_home.ai_platform_title',
    <>将出口无形的英语可视化</>,
    <>將一說即逝的英語完全視覺化</>,
    <>視覚化された英会話学習を体験</>,
    <>Đánh giá toàn diện</>,
  ],
  ai_platform_subtitle2: [
    <>링글만의 차별화된 화상영어 시스템</>,
    <>4 Personalized feedback reports after each lesson</>,
    's_home.ai_platform_subtitle2',
    <>别具一格的线上英语系统, 只在Ringle</>,
    <>Ringle與眾不同的視訊英語系統</>,
    <>Ringle独自のオンライン英会話システム</>,
    <>4 báo cáo đánh giá được cá nhân hoá sau mỗi buổi học</>,
  ],
  ai_platform_subtitle: [
    <>
      내 영어를 눈으로 보며&nbsp;
      <Br md down />
      실시간 교정을 받아보세요
    </>,
    ``,
    's_home.ai_platform_subtitle',
    <>
      我的英语可视直观&nbsp;
      <Br md down />
      随时获取纠正吧!
    </>,
    <>
      親眼確認您的英語，&nbsp;
      <Br md down />
      獲得即時糾正吧！
    </>,
    <>
      自分の英語がその場で校正される &nbsp;
      <Br md down /> 可視化されたプロセス
    </>,
    ``,
  ],
  change_experience: [
    <>
      영어 실력의 성장,
      <Br md /> 그로 인해 찾아온 인생의 변화
    </>,
    <>Start a new chapter of your life with Ringle</>,
    's_home.change_experience',
    <>
      英语实力的增长,
      <Br md /> 由此引发的人生变化
    </>,
    <>
      由於英語實力增長，
      <Br md />
      因此出現人生的變化
    </>,
    <>
      英語力の成長が
      <Br md /> もたらした訪れた人生の変化
    </>,
    <>Khởi đầu hành trình mới cùng Ringle</>,
  ],
  listen_voice: [
    `수강생들의 솔직한 이야기를 들어보세요`,
    ``,
    's_home.listen_voice',
    `来听一听学生们的真实说法吧`,
    `來聽一聽學生們的誠實心得吧！`,
    `受講生たちの正直な感想をぜひご覧ください。`,
    ``,
  ],
  six_years: [
    <>
      <b>당신의 현실에 맞춘 교재</b>
      <br />
      <br />
      일상 회화부터 시사, <Br md down />
      실전 비즈니스 영어까지
      <br />
      Top MBA 출신 집필진과 튜터들이 매주 업데이트하는 1,000+개의 교재
    </>,
    <>
      <b>Exclusive lesson materials </b>
      <br />
      1,000+ materials written by subject experts{' '}
    </>,
    's_home.six_years',
    <>
      <b>贴合您现实生活的教材</b>
      <br />
      <br />
      从日常生活对话, <Br md down />
      到实战商务英语
      <br />
      出身Top MBA的编纂人员及教师们将每周更新超过1,000篇的教材内容
    </>,
    <>
      <b>符合您的現實生活的教材</b>
      <br />
      <br />
      從日常對話到時事、
      <Br md down />
      實戰商務英語
      <br />
      Top MBA出身的執筆團隊及任課教師們每週更新1,000種以上的教材
    </>,
    <>
      <b>あなたのニーズに合わせた教材</b>
      <br />
      <br />
      日常会話から時事や
      <Br md down />
      実践ビジネス英語まで
      <br />
      MBAトップ校出身のライターとチューターが毎週アップデートする、1,000以上の教材から選ぶことができます。
    </>,
    <>
      <b>Tài liệu độc quyền cho buổi học </b>
      <br />
      1,000+ tài liệu được viết bởi các chuyên gia{' '}
    </>,
  ],
  six_years_title: [
    <>당신의 현실에 맞춘 교재</>,
    <>Exclusive lesson materials</>,
    's_home.six_years_title',
    <>贴合您现实生活的教材</>,
    <>符合您的現實生活的教材</>,
    <>あなたのニーズに合わせた教材</>,
    <>Tài liệu độc quyền cho buổi học</>,
  ],
  six_years_subtitle: [
    <>
      일상 회화부터 시사, <Br md down />
      실전 비즈니스 영어까지
    </>,
    <>1,000+ Materials written by subject experts </>,
    's_home.six_years_subtitle',
    <>
      从日常生活对话, <Br md down />
      到实战商务英语
    </>,
    <>
      從日常對話到時事、
      <Br md down />
      實戰商務英語
    </>,
    <>
      日常会話から時事や
      <Br md down />
      実践ビジネス英語まで
    </>,
    <>1,000+ Tài liệu được viết bởi các chuyên gia </>,
  ],
  six_years_text: [
    <>
      Top MBA 출신 집필진과 튜터들이 <Br md down />
      매주 업데이트하는 1,000+개의 교재
    </>,
    ``,
    's_home.six_years_text',
    <>
      出身Top MBA的编纂人员及教师们
      <Br md down />
      将每周更新超过1,000篇的教材内容
    </>,
    <>
      Top MBA出身的執筆團隊及任課教師們
      <Br md down />
      每週更新1,000種以上的教材
    </>,
    <>
      MBAトップ校出身のライターとチューターが
      <Br md down />
      毎週アップデートする、1,000以上の教材から選ぶことができます。
    </>,
    ``,
  ],
  anytime_anywhere: [
    <>영어 PT, Resume, 이메일, 면접 준비 등 맞춤 수업도 가능합니다</>,
    <></>,
    's_home.anytime_anywhere',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  no_fake: (n) => [
    <Fragment>
      {n}개의 거짓 없는
      <br />
      실시간 수업 후기가 증명합니다
    </Fragment>,
    <Fragment>{n} reviews </Fragment>,
    's_home.no_fake',
    <Fragment>
      {n}条真实的
      <br />
      实时课程评价足可以证明。
    </Fragment>,
    <Fragment>
      {n}條真實的
      <br />
      即時課程心得向您保證。
    </Fragment>,
    <Fragment>
      {n}件の受講生たちの
      <br />
      リアルタイムレッスンレビューが証明します。
    </Fragment>,
    <Fragment>{n} đánh giá </Fragment>,
  ],
  realtime_edits: [
    `실시간 교정`,
    `Real-time Correction`,
    's_home.realtime_edits',
    `实时纠正`,
    `即時糾正`,
    `リアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  realtime_edits_desc: [
    <>
      말하면 눈에 보이는 실시간 화상영어
      <br />
      튜터와 대화하며 실시간 교정과 피드백을 받아보세요
    </>,
    <>Follow along your tutor's corrections real-time</>,
    's_home.realtime_edits_desc',
    <>
      可以直观的实时会话线上英语
      <br />
      和教师展开对话, 获取实时纠正和反馈吧。
    </>,
    <>
      說出口便能即時確認的視訊英語
      <br />
      與任課教師對話，確認即時糾正與意見回饋吧！
    </>,
    <>
      その場で理解を深められるオンライン学習システム
      <br />
      チューターと会話しながらリアルタイム校正やフィードバックがもらえます。
    </>,
    <>Theo dõi những chỉnh sửa trực tiếp từ gia sư</>,
  ],
  conversation_script: [
    `대화 스크립트`,
    `Recording & Transcript`,
    's_home.conversation_script',
    `对话脚本`,
    `對話腳本`,
    `会話スクリプト`,
    `Ghi âm & Bản ghi`,
  ],
  conversation_script_desc: [
    <>
      AI가 기록하는 녹음 스크립트
      <br />
      튜터와 나의 대화를 다시 확인하며 언제든 복습해보세요
    </>,
    <>Revisit your lesson by audio or by text</>,
    's_home.conversation_script_desc',
    <>
      AI记录的录音脚本
      <br />
      课后通过再次确认, 随时进行复习吧
    </>,
    <>
      AI記錄的錄音腳本
      <br />
      再次確認任課教師與我的對話，隨時進行複習吧！
    </>,
    <>
      AIが記録する会話スクリプト
      <br />
      レッスン中の会話を音声とスクリプトからいつでも復習できます。
    </>,
    <>Xem lại buổi học qua file âm thanh hoặc chữ</>,
  ],
  ai_analytics: [
    `AI 분석`,
    `AI Analysis`,
    's_home.ai_analytics',
    `AI分析`,
    `AI分析`,
    `AI Analysis`,
    `Phân tích bằng AI`,
  ],
  ai_analytics_desc: [
    <>
      AI가 분석한 영어회화 패턴
      <br />
      말하기 속도, 자주 쓰는 단어, Filler Word 등 나의 영어 습관을 체크해보세요
    </>,
    <>Review speech pace, vocabulary range and filler words</>,
    's_home.ai_analytics_desc',
    <>
      采用AI技术进行分析的英语会话模式
      <br />
      可确认会话速度, 常用词, Filler Word等有关本人的英文会话习惯
    </>,
    <>
      AI分析的英語會話模式
      <br />
      確認口說速度、常用單字、Filler Word等自己的英文習慣吧！
    </>,
    <>
      AIが分析する会話パターン
      <br />
      話すスピード、よく使う単語、つなぎ言葉(Filler Word)などから自分の傾向をチェックしてみましょう。
    </>,
    <>Đánh giá tốc độ nói, vốn từ và các fillers words</>,
  ],
  mutli_feedback: [
    `다각도 피드백`,
    `Tutor Feedback`,
    's_home.mutli_feedback',
    `多角度反馈`,
    `全方位意見回饋`,
    `多角的フィードバック`,
    `Đánh giá từ gia sư`,
  ],
  mutli_feedback_desc: [
    <>
      수업 후 제공되는 튜터의 맞춤 피드백
      <br />
      발음, 문법, 어휘 등 나만을 위한 피드백으로 영어 실력을 키워보세요
    </>,
    <>Receive thorough evaluations on pronunciations, grammar, vocabulary and fluency</>,
    's_home.mutli_feedback_desc',
    <>
      课程结束后提供的教师针对性反馈
      <br />
      发音, 语法, 词汇等专属于我的反馈内容, 努力提升您的英语实力吧。
    </>,
    <>
      課程結束後任課教師提供客製化意見回饋
      <br />
      利用發音、文法、詞彙等專屬於我的意見回饋，提升您的英文實力吧！
    </>,
    <>
      レッスン後にチューターから提供されるフィードバック
      <br />
      発音、文法、語彙など、あなたのためだけのフィードバックで英語力を伸ばしましょう。
    </>,
    <>Nhận đánh giá đầy đủ về phát âm. ngữ pháp, từ vựng và mức độ trôi chảy</>,
  ],
  office_worker: [
    <>직장인/전문직</>,
    `Global Professionals`,
    's_home.office_worker',
    <>公司职员/专业人士</>,
    <>上班族／專業人員</>,
    <>会社員/専門職向け</>,
    `Nhân viên toàn cầu`,
  ],
  study_abroad: [
    <>유학생</>,
    <>International Students</>,
    's_home.study_abroad',
    <>留学生</>,
    <>留學生</>,
    `海外留学生向け`,
    <>Du học sinh</>,
  ],
  junior: [
    <>초중고 학생</>,
    `K-12`,
    's_home.junior',
    <>小学/初中/高中学生</>,
    <>國小、國中、高中學生</>,
    <>小中高生向け</>,
    `K-12`,
  ],
  live_abroad: [
    <>해외 거주자</>,
    <>Overseas Residents</>,
    's_home.live_abroad',
    <>海外居住者</>,
    <>海外居住者</>,
    <>海外在住者向け</>,
    <>Người định cư ở nước ngoài</>,
  ],
  correction: [
    <>이메일/에세이 교정</>,
    `Resume & Essay Editing`,
    's_home.correction',
    <>电子邮件/短文校正</>,
    <>糾正電子郵件／申論</>,
    <>履歴書/エッセー校正</>,
    `Chỉnh sửa Resume & Bài luận`,
  ],
  prep_interview: [
    <>인터뷰 준비</>,
    `Interview Prep`,
    's_home.prep_interview',
    <>英语面试准备</>,
    <>英語面試準備</>,
    <>英語面接準備</>,
    `Chuẩn bị phỏng vấn`,
  ],
  pronounce: [
    <>발음 교정 (미국/영국)</>,
    <>Pronunciation</>,
    's_home.pronounce',
    <>发音矫正(美式/英式)</>,
    <>矯正發音（美式／英式）</>,
    <>発音矯正 (アメリカ/イギリス)</>,
    <>Phát âm</>,
  ],
  prep_exam: [
    <>공인영어시험 대비</>,
    `Exam Prep`,
    's_home.prep_exam',
    <>专业英语备考</>,
    <>準備英語檢定</>,
    <>英語資格試験対策</>,
    `Luyện thi`,
  ],
  webinar_free_title: [
    <>고퀄리티 영상 강의 링글 웨비나</>,
    <>Live Webinars</>,
    's_home.webinar_free_title',
    <>高质量视频课程Ringle网络研讨会</>,
    <>高品質視訊課程Ringle網路研討會</>,
    <>高品質のオリジナルオンライン講義</>,
    <>Webinar trực tuyến</>,
  ],
  webinar_free_subtitle: [
    <>
      링글 회원에게만 제공되는 <Br md down />
      300+개의 무료 영어 웨비나
    </>,
    <>300+ Webinars hosted by Ringle's best tutors</>,
    's_home.webinar_free_subtitle',
    <>
      只向Ringle会员提供的
      <Br md down />
      300多个免费英文网络研讨会
    </>,
    <>
      只提供Ringle會員的
      <Br md down />
      300個以上免費英語網路研討會
    </>,
    <>
      Ringle会員だけが視聴できる
      <Br md down />
      300本超の無料英語ウェビナー
    </>,
    `300+ webinar dẫn dắt bởi các gia sư hàng đầu Ringle`,
  ],
  webinar_free_text: [
    <>
      매주 1회, 링글 인기 튜터와 함께하는 <Br md down />
      1시간 LIVE 강의도 들어보세요
    </>,
    <></>,
    's_home.webinar_free_text',
    <>
      每周一次, 参与Ringle人气教师开办的
      <Br md down />
      1小时LIVE课程
    </>,
    <>
      一週聆聽一次Ringle人氣任課教師開辦的
      <Br md down />
      1小時LIVE課程吧！
    </>,
    <>
      毎週1回開催、Ringle人気チューターによる
      <Br md down />
      1時間ライブ講義も受けてみましょう。
    </>,
    <></>,
  ],
  promotion: {
    head: (dday) => [
      <>하반기 프로모션 얼리버드 마감까지 D-{dday}</>,
      <>D-{dday} 2nd Half Early Bird Promotion</>,
      's_home.promotion.head',
      <>下半期促销活动早鸟截止前D-{dday}</>,
      <>距離下半期宣傳活動早鳥截止剩下D-{dday}</>,
      <>下半期プロモーションアーリーバード締め切りまでD-{dday}</>,
      <>D-{dday} Ưu đãi sớm nửa cuối năm </>,
    ],
    title: [
      <>
        정규 할인 전, 기존 고객분들에게만
        <br />
        얼리버드 한정 혜택을 드려요!
      </>,
      <>
        Early Bird offers only for existing users,
        <br />
        before the regular discount
      </>,
      's_home.promotion.title',
      <>
        正规优惠前, 仅对现有用户
        <br />
        提供早鸟限定优惠!
      </>,
      <>
        正規折扣之前，僅提供給既有學生
        <br />
        早鳥限定優惠！
      </>,
      <>
        正規割引前、既存のお客様にだけ
        <br />
        アーリーバード限定特典を差し上げます！
      </>,
      <></>,
    ],
    content: (amount) => [
      <>
        최대 <span style={{ color: '#b4caff' }}>{amount}만원</span> 추가 할인
        <br />
        <span style={{ color: '#b4caff' }}>미국 항공권</span> 지원 혜택을 놓치지 마세요!
      </>,
      <>
        Don’t miss out on the early bird
        <br />
        discount up to <span style={{ color: '#b4caff' }}>$344.83</span> +{' '}
        <span style={{ color: '#b4caff' }}>Int’l airline tickets</span>{' '}
      </>,
      's_home.promotion.content',
      <>
        高达<span style={{ color: '#b4caff' }}>${amount}</span>追加优惠
        <br />
        <span style={{ color: '#b4caff' }}>美国机票</span>优惠千万不要错过!
      </>,
      <>
        最高<span style={{ color: '#b4caff' }}>${amount}</span>額外優惠
        <br />
        並支援<span style={{ color: '#b4caff' }}>美國機票</span>，千萬不要錯過！
      </>,
      <>
        最大 <span style={{ color: '#b4caff' }}>{amount}</span> 追加割引
        <br />
        <span style={{ color: '#b4caff' }}>アメリカ航空券</span> サポート特典をお見逃しなく！
      </>,
      <>
        Đừng bỏ lỡ ưu đãi early bird
        <br />
        giảm giá lên đến <span style={{ color: '#b4caff' }}>$344.83</span> +{' '}
        <span style={{ color: '#b4caff' }}>Int’l airline tickets</span>{' '}
      </>,
    ],
    button: [
      <>지금 바로 혜택 받기</>,
      <>Receive benefits now</>,
      's_home.promotion.button',
      <>现在就获得优惠</>,
      <>現在立即領取優惠</>,
      <>今すぐ特典を受け取る</>,
      <>Nhận ưu đãi ngay</>,
    ],
  },
  bn_challenge: {
    banner: [
      `링글과 함께하는 세 번째 도전, 보스턴&뉴욕 챌린지가 1월 6일부터 시작됩니다!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      's_home.bn_challenge.banner',
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
    ],
    title: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_home.bn_challenge.title',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    participants: (number) => [
      <>🚩{number}명이 함께하고 있어요.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      's_home.bn_challenge.participants',
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
    ],
    view_more: [`더 보기`, `See more`, 's_home.bn_challenge.view_more', `See more`, `See more`, `See more`, `See more`],
    desc: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      's_home.bn_challenge.desc',
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
    ],
    btn: [
      `챌린지 도전하기`,
      `Join immediately`,
      's_home.bn_challenge.btn',
      `Join immediately`,
      `Join immediately`,
      `Join immediately`,
      `Join immediately`,
    ],
    title_monthly: [
      `월별 수업 미션`,
      `Monthly Mission`,
      's_home.bn_challenge.title_monthly',
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
    ],
    timer_end: (day) => [
      <>{day}일 후 종료</>,
      <>Ends in {day} day(s)</>,
      's_home.bn_challenge.timer_end',
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
    ],
    tooltip: [
      `[나의 챌린지 현황]에서 포인트를 받으세요!`,
      `Receive points from [My Challenge Board]!`,
      's_home.bn_challenge.tooltip',
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
    ],
    desc_point: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      's_home.bn_challenge.desc_point',
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
    ],
  },
  hero: {
    title: {
      desktop: [
        `아이비리그 출신\n원어민 튜터와 함께하는\n1:1 맞춤 화상영어`,
        `1:1 online English\nlessons with tutors\nfrom top universities`,
        's_home.hero.title.desktop',
        `和来自常春藤大学的\n母语教师\n进行1:1视讯英文课`,
        `和來自長春藤大學的\n母語教師\n進行1:1視訊英文課`,
        `アイビー・リーグ出身の\nチューターが教える\n1on1オンライン英会話`,
        `Học tiếng Anh trực tuyến 1:1\n cùng các gia sư\ntừ các trường đại học hàng đầu`,
      ],
      mobile: [
        `아이비리그 출신\n원어민 튜터와 함께하는\n1:1 맞춤 화상영어`,
        `1:1 online English\nlessons with tutors\nfrom top universities`,
        's_home.hero.title.mobile',
        `和来自常春藤大学的\n母语教师\n进行1:1视讯英文课`,
        `和來自長春藤大學的\n母語教師\n進行1:1視訊英文課`,
        `アイビー・リーグ出身の\nチューターが教える\n1on1オンライン英会話`,
        `Học tiếng Anh trực tuyến 1:1\nvới các gia sư\ntừ các trường đại học hàng đầu`,
      ],
    },
    subtitle: {
      desktop: [
        `당신이 꿈꾸던 영어 실력과 커리어를 링글에서`,
        `Unlock your potential with English`,
        's_home.hero.subtitle.desktop',
        `在Ringle提升英语实力实现梦想职涯`,
        `在Ringle提升英語實力實現夢想職涯`,
        `あなたの夢見た英語力とキャリアをRingleで`,
        `Phát triển tiếng Anh và nghề nghiệp mơ ước của bạn ở Ringle`,
      ],
      mobile: [
        `당신이 꿈꾸던 영어 실력과 커리어를 링글에서`,
        `Unlock your potential with English`,
        's_home.hero.subtitle.mobile',
        `在Ringle提升英语实力实现梦想职涯`,
        `在Ringle提升英語實力實現夢想職涯`,
        `あなたの夢見た英語力とキャリアをRingleで`,
        `Phát triển tiếng Anh và nghề nghiệp mơ ước của bạn ở Ringle`,
      ],
    },
    cta: {
      desktop: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.hero.cta.desktop',
        `以$3的价格参加第一堂课程`,
        `以$3的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        `Trải nghiệm lớp học đầu tiên với 4,000 won`,
      ],
      desktop_20: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.hero.cta.desktop_20',
        `以$3.46的价格参加第一堂课程`,
        `以$3.46的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        `Trải nghiệm lớp học đầu tiên với 4,000 won`,
      ],
      mobile: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.hero.cta.mobile',
        `以$3的价格参加第一堂课程`,
        `以$3的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        `Trải nghiệm lớp học đầu tiên với 4,000 won`,
      ],
      mobile_20: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.hero.cta.mobile_20',
        `以$3的价格参加第一堂课程`,
        `以$3的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        `Trải nghiệm lớp học đầu tiên với 4,000 won`,
      ],
    },
    cta2: {
      mobile: [
        `수업권 가격 확인하기`,
        `View Price`,
        's_home.hero.cta2.mobile',
        `查看价格`,
        `查看價格`,
        `価格を見る`,
        `View promotional offers`,
      ],
    },
  },
  tutor_title: {
    subtitle: {
      desktop: [
        `똑똑하고 성실한 튜터`,
        `Intellectual and hard-working tutors`,
        's_home.tutor_title.subtitle.desktop',
        `充满教学热诚的教师`,
        `充滿教學熱誠的教師`,
        `知的で勤勉なチューター`,
        `Gia sư trí tuệ và tận tâm`,
      ],
      mobile: [
        `똑똑하고 성실한 튜터`,
        `Intellectual and hard-working tutors`,
        's_home.tutor_title.subtitle.mobile',
        `充满教学热诚的教师`,
        `充滿教學熱誠的教師`,
        `知的で勤勉なチューター`,
        `Gia sư trí tuệ và tận tâm`,
      ],
    },
    title: {
      desktop: [
        `영어로 생각을 나누는\n명문대 원어민 튜터`,
        `In-depth discussions with\ntutors from top universities`,
        's_home.tutor_title.title.desktop',
        `用英语和来自\n顶尖大学的教师进行深度交流`,
        `用英文和來自\n頂尖大學的教師進行深度討論`,
        `名門大卒ネイティブチューターと\n英語で意見交換`,
        `Thảo luận chuyên sâu\ncùng các gia sư từ trường Đại học top đầu`,
      ],
      mobile: [
        `영어로 생각을 나누는\n명문대 원어민 튜터`,
        `In-depth discussions with\ntutors from top universities`,
        's_home.tutor_title.title.mobile',
        `用英语和来自\n顶尖大学的教师进行深度交流`,
        `用英文和來自\n頂尖大學的教師進行深度討論`,
        `名門大卒ネイティブチューターと\n英語で意見交換`,
        `Thảo luận chuyên sâu\ncùng các gia sư từ trường Đại học top đầu`,
      ],
    },
    bodytxt: {
      desktop: [
        `원어민은 많지만 고급 표현과 영어로\n생각하는 법을 배울 수 있는 튜터는 따로 있죠.`,
        `Work with tutors who can help you\nexpress more eloquently`,
        's_home.tutor_title.bodytxt.desktop',
        `虽然母语者很多，但并不是每个母语者都能用高级且专业的词彙沟通，\nRinlge的教师具备这样的能力。`,
        `雖然母語者很多，但並不是每個母語者都能用高級且專業的詞彙溝通，\nRinlge的教師具備這樣的能力。`,
        `上級表現や英語での思考法を学べる\nネイティブチューターはココだけ`,
        `Học cùng các gia sư \ngiúp bạn diễn đạt trôi chảy và thuyết phục hơn`,
      ],
      mobile: [
        `원어민은 많지만 고급 표현과 영어로\n생각하는 법을 배울 수 있는 튜터는 따로 있죠.`,
        `Work with tutors who can help you\nexpress more eloquently`,
        's_home.tutor_title.bodytxt.mobile',
        `虽然母语者很多，但并不是每个母语者都能用高级且专业的词彙沟通，\nRinlge的教师具备这样的能力。`,
        `雖然母語者很多，但並不是每個母語者都能用高級且專業的詞彙溝通，\nRinlge的教師具備這樣的能力。`,
        `上級表現や英語での思考法を学べる\nネイティブチューターはココだけ`,
        `Học cùng các gia sư \ngiúp bạn diễn đạt trôi chảy và thuyết phục hơn`,
      ],
    },
  },
  tutor_txt_docs: {
    title_white: {
      desktop: [
        `머릿 속에서 번역하고\n말하느라 영어가\n부자연스럽나요?`,
        `Is your English awkward\nbecause you're trying to\ndirectly translate it from another language?`,
        's_home.tutor_txt_docs.title_white.desktop',
        `英语表达总是很不自然?`,
        `英文表達總是很不自然?`,
        `脳内で翻訳してから口に出すと\n不自然な英語に\nなっていませんか？`,
        `Bạn chưa biết dùng tiếng anh tự nhiên\nvì bạn đang cố gắng dịch trực tiếp \ntừ một ngôn ngữ khác không?`,
      ],
      mobile: [
        `머릿 속에서 번역하고\n말하느라 영어가\n부자연스럽나요?`,
        `Is your English awkward\nbecause you're trying to\ndirectly translate it from\nanother language?`,
        's_home.tutor_txt_docs.title_white.mobile',
        `英语表达总是很不自然?`,
        `英文表達總是很不自然?`,
        `脳内で翻訳してから口に出すと\n不自然な英語に\nなっていませんか？`,
        `Bạn chưa biết dùng tiếng anh tự nhiên\nvì bạn đang cố gắng dịch trực tiếp \ntừ một ngôn ngữ khác không?`,
      ],
    },
    title_purple: {
      desktop: [
        `원어민의 영어를 알려주는\n튜터와 연습하세요.`,
        `Practice your English with \ntutors who can teach you \nto talk like a native speaker`,
        's_home.tutor_txt_docs.title_purple.desktop',
        `向母语教师学习\n说一口道地的英语`,
        `向母語教師學習\n說一口道地的英文`,
        `ネイティブの使う英語を\nチューターと共に練習`,
        `Hãy thực hành cùng gia sư/n để sử dụng tiếng anh như người bản xứ`,
      ],
      mobile: [
        `원어민의 영어를 알려주는\n튜터와 연습하세요.`,
        `Practice your English with \ntutors who can teach you \nto talk like a native speaker`,
        's_home.tutor_txt_docs.title_purple.mobile',
        `向母语教师学习\n说一口道地的英语`,
        `向母語教師學習\n說一口道地的英文`,
        `ネイティブの使う英語を\nチューターと共に練習`,
        `Hãy thực hành cùng gia sư/n để sử dụng tiếng anh như người bản xứ`,
      ],
    },
    bodytxt: {
      desktop: [
        `단순한 문법 교정을 넘어 현지 원어민의 고급 표현과 논리적인 문장 구조를 링글 튜터에게 배워보세요. 훨씬 자연스러운 영어가 가능해집니다.`,
        `Go beyond learning grammatical corrections. Find out how you can communicate more logically and effectively in English.`,
        's_home.tutor_txt_docs.bodytxt.desktop',
        `不只纠正文法，\n和Ringle的母语教师学习\n专业且有效率的沟通方法。`,
        `不只糾正文法，\n和Ringle的母語教師學習\n專業且有效率的溝通方法。`,
        `単純な文法校正だけでなくネイティブの上級表現や論理的な文章構造を学ぶことで、より自然な英語が話せるように。`,
        `Không chỉ đơn giản là sửa lỗi ngữ pháp mà hãy\nhọc các cách diễn đạt hiệu quả và logic của người bản xứ với gia sư Ringle`,
      ],
      mobile: [
        `단순한 문법 교정을 넘어 현지 원어민의 고급 표현과 논리적인 문장 구조를 링글 튜터에게 배워보세요. 훨씬 자연스러운 영어가 가능해집니다.`,
        `Go beyond learning grammatical corrections. Find out how you can communicate more logically and effectively in English.`,
        's_home.tutor_txt_docs.bodytxt.mobile',
        `不只纠正文法，\n和Ringle的母语教师学习\n专业且有效率的沟通方法。`,
        `不只糾正文法，\n和Ringle的母語教師學習\n專業且有效率的溝通方法。`,
        `単純な文法校正だけでなくネイティブの上級表現や論理的な文章構造を学ぶことで、より自然な英語が話せるように。`,
        `Không chỉ đơn giản là sửa lỗi ngữ pháp mà hãy\nhọc các cách diễn đạt hiệu quả và logic của người bản xứ với gia sư Ringle`,
      ],
    },
    caption: {
      desktop: [
        `수업 중 사용하는 링글 닥스. 튜터가 교정을 적으며 알려줘요.`,
        `Tutors provide real-time corrections\nduring lessons using Ringle docs.`,
        's_home.tutor_txt_docs.caption.desktop',
        `教师於课堂中使用Ringle doc.，即时修改文章。`,
        `教師於課堂中使用Ringle doc.，即時修改文章。`,
        `レッスン中に使用するRingle Docsでは\n実際にチューターの校正が受けられます。`,
        `Gia sư giúp bạn sửa lỗi trực tiếp trên Ringle docs`,
      ],
      mobile: [
        `수업 중 사용하는 링글 닥스. 튜터가 교정을 적으며 알려줘요.`,
        `Ringle tutors provide real-time corrections\nduring lessons using Ringle docs.`,
        's_home.tutor_txt_docs.caption.mobile',
        `教师於课堂中使用Ringle doc.，即时修改文章。`,
        `教師於課堂中使用Ringle doc.，即時修改文章。`,
        `レッスン中に使用するRingle Docsでは\n実際にチューターの校正が受けられます。`,
        `Gia sư viết bài sửa chữa và thông báo cho bạn bằng tài liệu Ringle.`,
      ],
    },
  },
  tutor_txt_tutorlist: {
    title_white: {
      desktop: [
        `영어 대화 주제가 바뀌면\n꿀먹은 벙어리가 되나요?`,
        `Struggling to converse \nin English about various topics?`,
        's_home.tutor_txt_tutorlist.title_white.desktop',
        `想要拥有在不同主题\n都能用英文轻鬆应付的能力吗？`,
        `想要擁有在不同主題\n都能用英文輕鬆應對的能力嗎？`,
        `話題が変わると\n英語が喋れない？`,
        `Bạn gặp khó khăn khi nói\nvề các chủ đề khác nhau?`,
      ],
      mobile: [
        `영어 대화 주제가 바뀌면\n꿀먹은 벙어리가 되나요?`,
        `Struggling to converse \nin English about various topics?`,
        's_home.tutor_txt_tutorlist.title_white.mobile',
        `想要拥有在不同主题\n都能用英文轻鬆应付的能力吗？`,
        `想要擁有在不同主題\n都能用英文輕鬆應對的能力嗎？`,
        `話題が変わると\n英語が喋れない？`,
        `Bạn gặp khó khăn khi diễn đạt bằng Tiếng Anh\nvề các chủ đề khác nhau?`,
      ],
    },
    title_purple: {
      desktop: [
        `진짜 실력을 만들어주는\n튜터를 만나세요.`,
        `Meet tutors who can help \nyou reach your full potential`,
        's_home.tutor_txt_tutorlist.title_purple.desktop',
        `和真的能帮助您的教师学习吧！`,
        `和真的能幫助您的教師學習吧！`,
        `本物の実力を\n身につけさせてくれる\nチューターがここに`,
        `Hãy gặp gia sư có thể giúp bạn\n phát huy tối đa tiếng Anh của mình`,
      ],
      mobile: [
        `진짜 실력을 만들어주는\n튜터를 만나세요.`,
        `Meet tutors who can help \nyou reach your full potential`,
        's_home.tutor_txt_tutorlist.title_purple.mobile',
        `和真的能帮助您的教师学习吧！`,
        `和真的能幫助您的教師學習吧！`,
        `本物の実力を\n身につけさせてくれる\nチューターがここに`,
        `Hãy gặp gia sư có thể giúp bạn\nbiến tiếng Anh thành của riêng mình`,
      ],
    },
    bodytxt_1: {
      desktop: [
        `외국인이 케이팝 말고 한국 정치와 경제에 대해서 이야기하면 우리 모두 감탄하죠. 영어도 마찬가지입니다. 진짜 영어 실력은 다양한 상황에서 대처하는 능력에서 나옵니다.`,
        `You know that your English is improving when you start to feel confident conversing about various topics.`,
        's_home.tutor_txt_tutorlist.bodytxt_1.desktop',
        `用专业的英语词彙和句子，\n和母语人士讨论各种主题。\n当你发现自己能用英文应对不同的场合及需求，\n您就具备良好的英文能力。`,
        `用專業的英語詞彙和句子，\n和母語人士討論各種主題。\n當你發現自己能用英文應對不同的場合及需求，\n您就具備良好的英文能力。`,
        `外国人が日本の政治や経済について流暢に話すと感嘆するように、様々な状況に対応できる実力こそが本当の英語力です。`,
        `Tiếng Anh của bạn đang được cải thiện \nkhi bạn bắt đầu cảm thấy tự tin khi trò chuyện về \ncác chủ đề khác nhau.`,
      ],
      mobile: [
        `외국인이 케이팝 말고 한국 정치와 경제에 대해서 이야기하면 우리 모두 감탄하죠. 영어도 마찬가지입니다. 진짜 영어 실력은 다양한 상황에서 대처하는 능력에서 나옵니다.`,
        `You know that your English is improving when you start to feel confident conversing about various topics.`,
        's_home.tutor_txt_tutorlist.bodytxt_1.mobile',
        `用专业的英语词彙和句子，\n和母语人士讨论各种主题。\n当你发现自己能用英文应对不同的场合及需求，\n您就具备良好的英文能力。`,
        `用專業的英語詞彙和句子，\n和母語人士討論各種主題。\n當你發現自己能用英文應對不同的場合及需求，\n您就具備良好的英文能力。`,
        `外国人が日本の政治や経済について流暢に話すと感嘆するように、様々な状況に対応できる実力こそが本当の英語力です。`,
        `Tiếng Anh của bạn đang được cải thiện \nkhi bạn bắt đầu cảm thấy tự tin khi trò chuyện về \ncác chủ đề khác nhau.`,
      ],
    },
    bodytxt_2: {
      desktop: [
        `영어가 모국어인 사람은 많지만 가벼운 일상 주제부터  비즈니스 케이스, 깊이 있는 토론을 함께 연습할 수 있는 원어민은 많지 않습니다. 다양한 전공과 관심사를 가진 똑똑한 튜터는 링글에만 있습니다.`,
        `There are many native English speakers, but only a few can have in-depth discussions about a range of topics. On Ringle, you can meet intelligent tutors who are trained in various fields and have diverse interests.`,
        's_home.tutor_txt_tutorlist.bodytxt_2.desktop',
        `在Ringle和聪明且充满教学热诚的教师一起，\n用英文练习日常主题、商业案例、\n进行深度讨论，还能准备面试和报告，高度客制化的课程满足您所有需求。`,
        `在Ringle和聰明且充滿教學熱誠的教師一起，\n用英文練習日常主題、商業案例、\n進行深度討論，還能準備面試和報告，高度客製化的課程滿足您所有需求。`,
        `英語ネイティブは沢山いますが、日常会話からビジネスケース、討論の練習まで可能なネイティブは多くありません。様々な分野やトピックに対応できるインテリチューターに出会えるのはRingleだけ。`,
        `Có rất nhiều người nói tiếng Anh bản ngữ, nhưng \nchỉ ít người có thể thảo luận sâu về nhiều chủ đề. Trên Ringle, bạn có thể gặp những gia sư thông minh\nđược đào tạo và mối quan tâm trong nhiều lĩnh vực khác nhau.`,
      ],
      mobile: [
        `영어가 모국어인 사람은 많지만 가벼운 일상 주제부터  비즈니스 케이스, 깊이 있는 토론을 함께 연습할 수 있는 원어민은 많지 않습니다. 다양한 전공과 관심사를 가진 똑똑한 튜터는 링글에만 있습니다.`,
        `There are many native English speakers, but only a few can have in-depth discussions about a range of topics. On Ringle, you can meet intelligent tutors who are trained in various fields and have diverse interests.`,
        's_home.tutor_txt_tutorlist.bodytxt_2.mobile',
        `在Ringle和聪明且充满教学热诚的教师一起，\n用英文练习日常主题、商业案例、\n进行深度讨论，还能准备面试和报告，高度客制化的课程满足您所有需求。`,
        `在Ringle和聰明且充滿教學熱誠的教師一起，\n用英文練習日常主題、商業案例、\n進行深度討論，還能準備面試和報告，高度客製化的課程滿足您所有需求。`,
        `英語ネイティブは沢山いますが、日常会話からビジネスケース、討論の練習まで可能なネイティブは多くありません。様々な分野やトピックに対応できるインテリチューターに出会えるのはRingleだけ。`,
        `Có nhiều người nói tiếng Anh bản xứ, nhưng chỉ ít người có thể thực hành các cuộc thảo luận chuyên sâu về các chủ đề khác nhau, từ các vấn đề hàng ngày đến xu hướng kinh doanh. Chỉ Ringle mới có những gia sư thông minh với nhiều chuyên ngành và sở thích khác nhau.`,
      ],
    },
    totalnumber: {
      desktop: [
        `전체 튜터 수\n1,500명`,
        `Total # of tutors\n1,500`,
        's_home.tutor_txt_tutorlist.totalnumber.desktop',
        `教师总数\n1,500`,
        `教師總數\n1,500`,
        `全体チューター数\n1,500`,
        `Tổng số gia sư\n1,500`,
      ],
      mobile: [
        `전체 튜터 수\n1,500명`,
        `Total # of tutors\n1,500`,
        's_home.tutor_txt_tutorlist.totalnumber.mobile',
        `教师总数\n1,500`,
        `教師總數\n1,500`,
        `全体チューター数\n1,500`,
        `Tổng số \n gia sư 1,500`,
      ],
    },
    graduates: {
      desktop: [
        `직장인/대학원생\n574명`,
        `Graduates/graduate students\n574`,
        's_home.tutor_txt_tutorlist.graduates.desktop',
        `上班族/研究生\n574`,
        `上班族/研究所學生\n574`,
        `会社員／大学院生\n574`,
        `Người đi làm / Sinh viên sau đại học\n574`,
      ],
      mobile: [
        `직장인/대학원생\n574명`,
        `Graduates/graduate students\n574`,
        's_home.tutor_txt_tutorlist.graduates.mobile',
        `上班族/研究生\n574`,
        `上班族/研究所學生\n574`,
        `会社員／大学院生\n574`,
        `Người đi làm/ \nSinh viên sau đại học\n574`,
      ],
    },
    economics: {
      desktop: [
        `경영/경제 전공\n200명`,
        `Business/economics majors\n200`,
        's_home.tutor_txt_tutorlist.economics.desktop',
        `经营/经济 专业\n200`,
        `管理/經濟主修\n200`,
        `経営／経済専攻\n200`,
        `Ngành Kinh doanh/ Kinh tế\n200`,
      ],
      mobile: [
        `경영/경제 전공\n200명`,
        `Business/economics majors\n200`,
        's_home.tutor_txt_tutorlist.economics.mobile',
        `经营/经济 专业\n200`,
        `管理/經濟主修\n200`,
        `経営／経済専攻\n200`,
        `Ngành Kinh doanh/ Kinh tế\n200`,
      ],
    },
    law: {
      desktop: [
        `법학 전공\n60명`,
        `Law majors\n60`,
        's_home.tutor_txt_tutorlist.law.desktop',
        `法学 专业\n60`,
        `法律 主修\n60`,
        `法学専攻\n60`,
        `Ngành Luật\n60`,
      ],
      mobile: [
        `법학 전공\n60명`,
        `Law majors\n60`,
        's_home.tutor_txt_tutorlist.law.mobile',
        `法学 专业\n60`,
        `法律 主修\n60`,
        `法学専攻\n60`,
        `Ngành Luật\n60`,
      ],
    },
    engineering: {
      desktop: [
        `공학 전공\n150명`,
        `Engineering majors\n150`,
        's_home.tutor_txt_tutorlist.engineering.desktop',
        `工学 专业\n150`,
        `工程 主修\n150`,
        `工学専攻\n150`,
        `Ngành Kỹ thuật\n150`,
      ],
      mobile: [
        `공학 전공\n150명`,
        `Engineering majors\n150`,
        's_home.tutor_txt_tutorlist.engineering.mobile',
        `工学 专业\n150`,
        `工程 主修\n150`,
        `工学専攻\n150`,
        `Ngành Kỹ thuật\n150`,
      ],
    },
    medical: {
      desktop: [
        `의학 전공\n50명`,
        `Medical majors\n50`,
        's_home.tutor_txt_tutorlist.medical.desktop',
        `医学 专业\n50`,
        `醫學 主修\n50`,
        `医学専攻\n50`,
        `Ngành Y tế\n50`,
      ],
      mobile: [
        `의학 전공\n50명`,
        `Medical majors\n50`,
        's_home.tutor_txt_tutorlist.medical.mobile',
        `医学 专业\n50`,
        `醫學 主修\n50`,
        `医学専攻\n50`,
        `Ngành Y tế\n50`,
      ],
    },
  },
  tutor_txt_ivyleague: {
    title_white: {
      desktop: [
        `아이비리그 학생들의\n영어, 경험을 훔칠 수 있다면?`,
        `Want to learn from \nIvy-league students' experiences?`,
        's_home.tutor_txt_ivyleague.title_white.desktop',
        `想要向来自常春藤大学的学生\n学习英文和吸取经验吗？`,
        `想要向來自長春藤大學的學生\n學習英文和吸取經驗嗎？`,
        `アイビー・リーグの学生の\n英語力や経験を盗めるなら？`,
        `Bạn muốn học hỏi kinh nghiệm từ các sinh viên Ivy-League?`,
      ],
      mobile: [
        `아이비리그 학생들의\n영어, 경험을 훔칠 수 있다면?`,
        `Want to learn from \nIvy-league students' experiences?`,
        's_home.tutor_txt_ivyleague.title_white.mobile',
        `想要向来自常春藤大学的学生\n学习英文和吸取经验吗？`,
        `想要向來自長春藤大學的學生\n學習英文和吸取經驗嗎？`,
        `アイビー・リーグの学生の\n英語力や経験を盗めるとしたら？`,
        `Bạn muốn học  hỏi kinh nghiệm \n từ các sinh viên Ivy - League?`,
      ],
    },
    title_purple: {
      desktop: [
        `상위 1% 실력의\n튜터를 만나보세요.`,
        `Meet tutors from \ntop universities`,
        's_home.tutor_txt_ivyleague.title_purple.desktop',
        `現在就和優秀的教师们见面！`,
        `現在就和優秀的教師們見面！`,
        `実力上位1％の\nチューターに出会える場所`,
        `Gặp gỡ các gia sư đến từ trường Đại học top đầu`,
      ],
      mobile: [
        `상위 1% 실력의\n튜터를 만나보세요.`,
        `Meet tutors from \ntop universities`,
        's_home.tutor_txt_ivyleague.title_purple.mobile',
        `現在就和優秀的教师们见面！`,
        `現在就和優秀的教師們見面！`,
        `実力上位1％の\nチューターに出会える場所`,
        `Gặp gỡ các gia sư hàng đầu`,
      ],
    },
    bodytxt: {
      desktop: [
        `링글의 튜터들은 대학 입학을 준비할 때부터 최고 수준의 논리적 사고력과 에세이 실력을 매일 갈고닦습니다. 영∙미 상위 1% 학생들이 가진 고급 표현력, 글쓰기 역량은 오직 링글에서만 배울 수 있습니다.`,
        `Our tutors have been trained to think logically and write persuasively ever since they started preparing for college admissions. On Ringle, you can learn these valuable skills through our tutors.`,
        's_home.tutor_txt_ivyleague.bodytxt.desktop',
        `Ringle的教师们从准备大学入学开始,\n接受逻辑思考能力和写作训练。\n只有在Ringle才能和他们学习这些珍贵的技巧。`,
        `Ringle的教師們從準備大學入學開始,\n接受邏輯思考能力和寫作訓練。\n只有在Ringle才能和他們學習這些珍貴的技巧。`,
        `Ringleのチューターは皆大学入試の準備段階から最高レベルの論理的思考力や文章力を育んでいます。英・米上位1％の学生が持つ上級表現力、文章力を学べるのはRingleだけです。`,
        `Gia sư của chúng tôi đã được đào tạo tư duy logic và kỹ năng viết luận trong quá trình chuẩn bị hồ sơ tuyển sinh đại học. Bạn có thể học những kỹ năng này tại Ringle cùng các gia sư của chúng tôi.`,
      ],
      mobile: [
        `링글의 튜터들은 대학 입학을 준비할 때부터 최고 수준의 논리적 사고력과 에세이 실력을 매일 갈고닦습니다. 영∙미 상위 1% 학생들이 가진 고급 표현력, 글쓰기 역량은 오직 링글에서만 배울 수 있습니다.`,
        `Our tutors have been trained to think logically and write persuasively ever since they started preparing for college admissions. On Ringle, you can learn these valuable skills through our tutors.`,
        's_home.tutor_txt_ivyleague.bodytxt.mobile',
        `Ringle的教师们从准备大学入学开始,\n接受逻辑思考能力和写作训练。\n只有在Ringle才能和他们学习这些珍贵的技巧。`,
        `Ringle的教師們從準備大學入學開始,\n接受邏輯思考能力和寫作訓練。\n只有在Ringle才能和他們學習這些珍貴的技巧。`,
        `Ringleのチューターは皆大学入試の準備段階から最高レベルの論理的思考力や文章力を育んでいます。英・米上位1％の学生が持つ上級表現力、文章力を学べるのはRingleだけです。`,
        `Gia sư của chúng tôi đã được đào tạo tư duy logic và kỹ năng viết luận trong quá trình chuẩn bị hồ sơ tuyển sinh đại học. Bạn có thể học những kỹ năng này tại Ringle cùng các gia sư của chúng tôi.`,
      ],
    },
  },
  tutor_txt_business: {
    title_white: {
      desktop: [
        `대학생 튜터는\n비즈니스 영어를 못한다?`,
        `Think that university \nstudents can't teach \nbusiness English?`,
        's_home.tutor_txt_business.title_white.desktop',
        `你觉得大学生不会教商用英语？`,
        `你覺得大學生不會教商用英文？`,
        `大学生のチューターは\nビジネス英語が話せない？`,
        `Bạn nghĩ rằng sinh viên Đại học \nthì không thể dạy tiếng Anh thương mại?`,
      ],
      mobile: [
        `대학생 튜터는\n비즈니스 영어를 못한다?`,
        `Think that university \nstudents can't teach \nbusiness English?`,
        's_home.tutor_txt_business.title_white.mobile',
        `你觉得大学生不会教商用英语？`,
        `你覺得大學生不會教商用英文？`,
        `大学生のチューターは\nビジネス英語が話せない？`,
        `Bạn nghĩ rằng sinh viên Đại học \nthì không thể dạy tiếng Anh thương mại?`,
      ],
    },
    title_purple: {
      desktop: [
        `나이보다 논리적 설득력이\n더 중요합니다.`,
        `Logical thinking and \npersuasiveness are more \nimportant than age.`,
        's_home.tutor_txt_business.title_purple.desktop',
        `教师们用充满逻辑及说服力\n的表达方式，解除您的疑虑。`,
        `教師們用充滿邏輯及說服力\n的表達方式，解除您的疑慮。`,
        `大切なのは年齢より\n論理的な説得力です。`,
        `Vấn đề không phải là tuổi tác\nmà là khả năng thuyết phục một cách logic.`,
      ],
      mobile: [
        `나이보다 논리적 설득력이\n더 중요합니다.`,
        `Logical thinking and  \npersuasiveness are \nmore important than age`,
        's_home.tutor_txt_business.title_purple.mobile',
        `教师们用充满逻辑及说服力\n的表达方式，解除您的疑虑。`,
        `教師們用充滿邏輯及說服力\n的表達方式，解除您的疑慮。`,
        `大切なのは年齢より\n論理的な説得力です`,
        `Vấn đề không phải là tuổi tác\nmà là khả năng thuyết phục một cách logic.`,
      ],
    },
    bodytxt_1: {
      desktop: [
        `사회생활 경험이 비즈니스 영어를 잘하기 위한 필수 조건일까요? 비즈니스 환경의 커뮤니케이션에서 중요한 것은 영어에 설득력을 더하는 일입니다.`,
        `Exceptional command of English outweighs career experience for high level business English`,
        's_home.tutor_txt_business.bodytxt_1.desktop',
        `在实际商务场合中，需要用具逻辑及说服力的表达方式应对。\n在Ringle和教师们一起，不只学习商业用语，\n也培养用英文完整表达自己的能力。`,
        `在實際商務場合中，需要用有邏輯及說服力的表達方式應對。\n在Ringle和教師們一起，不只學習商業用語，\n也培養用英文完整表達自己的能力。`,
        `社会経験だけがビジネス英語を伸ばすのに必要な条件ではありません。ビジネスでのコミュニケーションで重要なのは\n説得力のある英語を使うことです。`,
        `Trình độ tiếng Anh vượt trội quan trọng hơn kinh nghiệm với tiếng Anh thương mại cấp cao`,
      ],
      mobile: [
        `사회생활 경험이 비즈니스 영어를 잘하기 위한 필수 조건일까요? 비즈니스 환경의 커뮤니케이션에서 중요한 것은 영어에 설득력을 더하는 일입니다.`,
        `Exceptional command of English outweighs career experience for high level business English`,
        's_home.tutor_txt_business.bodytxt_1.mobile',
        `在实际商务场合中，需要用具逻辑及说服力的表达方式应对。\n在Ringle和教师们一起，不只学习商业用语，\n也培养用英文完整表达自己的能力。`,
        `在實際商務場合中，需要用有邏輯及說服力的表達方式應對。\n在Ringle和教師們一起，不只學習商業用語，\n也培養用英文完整表達自己的能力。`,
        `社会経験だけがビジネス英語を伸ばすのに必要な条件ではありません。ビジネスでのコミュニケーションで重要なのは\n説得力のある英語を使うことです。`,
        `Bạn có nghĩ rằng kinh nghiệm nghề nghiệp là điều cần thiết cho tiếng Anh thương mại?Trên thực tế, điều quan trọng hơn là biết cách thuyết phục bằng tiếng Anh.`,
      ],
    },
    bodytxt_2: {
      desktop: [
        `영∙미 명문대 학생들은 교수와 동료를 설득해야하는 치열한 환경 속에서 어떤 상황에서도 논리적인 설득과 생각 구조화를 할 수 있도록 훈련 받습니다.`,
        `Students from top universities are trained to think and speak logically in any given situation.`,
        's_home.tutor_txt_business.bodytxt_2.desktop',
        `顶尖大学的学生们都受过逻辑思考训练，在任何情况下，都能用具有逻辑和说服力的方式沟通。`,
        `頂尖大學的學生們都受過邏輯思考訓練，在任何情況下，都能用具有邏輯和說服力的方式溝通。`,
        `英・米名門大学の学生たちは教授や学生を説得せねばならない厳しい環境の中、どんな状況でも論理的な説得や思考の構造化ができるよう訓練を受けています。`,
        `Sinh viên các trường đại học danh tiếng của Anh và Mỹ đều được đào tạo tư duy logic và kỹ năng thuyết phục trong mọi tình huống`,
      ],
      mobile: [
        `영∙미 명문대 학생들은 교수와 동료를 설득해야하는 치열한 환경 속에서 어떤 상황에서도 논리적인 설득과 생각 구조화를 할 수 있도록 훈련 받습니다.`,
        `Students from top universities are trained to think and speak logically in any given situation.`,
        's_home.tutor_txt_business.bodytxt_2.mobile',
        `顶尖大学的学生们都受过逻辑思考训练，在任何情况下，都能用具有逻辑和说服力的方式沟通。`,
        `頂尖大學的學生們都受過邏輯思考訓練，在任何情況下，都能用具有邏輯和說服力的方式溝通。`,
        `英・米名門大学の学生たちは教授や学生を説得せねばならない厳しい環境の中、どんな状況でも論理的な説得や思考の構造化ができるよう訓練を受けています。`,
        `Sinh viên các trường đại học danh tiếng của Anh và Mỹ đều được đào tạo tư duy logic và kỹ năng thuyết phục trong mọi tình huống`,
      ],
    },
    bodytxt_3: {
      desktop: [
        `단순히 비즈니스 용어만 배우는 게 아니라, 영어로 내 생각을 잘 전달하는 법을 배울 수 있는 곳은 링글밖에 없습니다.`,
        `Ringle is the perfect platform to learn how you can express more persuasively.`,
        's_home.tutor_txt_business.bodytxt_3.desktop',
        `只有在Ringle能在学习商业英文的同时，\n也学习用英文有效沟通的方法！`,
        `只有在Ringle能在學習商業英文的同時，\n也學習用英文有效溝通的方法！`,
        `Ringleでは単なるビジネス用語だけでなく、自分の考えを英語で伝える力を学ぶことができます。`,
        `Ringle là nền tảng hoàn hảo nơi bạn học được cách truyền đạt suy nghĩ của mình một cách hiệu quả bằng tiếng Anh.`,
      ],
      mobile: [
        `단순히 비즈니스 용어만 배우는 게 아니라, 영어로 내 생각을 잘 전달하는 법을 배울 수 있는 곳은 링글밖에 없습니다.`,
        `Ringle is the perfect platform to learn how you can express more persuasively.`,
        's_home.tutor_txt_business.bodytxt_3.mobile',
        `只有在Ringle能在学习商业英文的同时，\n也学习用英文有效沟通的方法！`,
        `只有在Ringle能在學習商業英文的同時，\n也學習用英文有效溝通的方法！`,
        `Ringleでは単なるビジネス用語だけでなく、自分の考えを英語で伝える力を学ぶことができます。`,
        `Ringle là nền tảng hoàn hảo nơi bạn học được cách truyền đạt suy nghĩ của mình một cách hiệu quả bằng tiếng Anh.`,
      ],
    },
  },
  tutor_txt_transit: {
    desktop: [
      `링글의 수업 시스템이\n튜터와의 시간을\n더욱 가치있게 해줍니다.`,
      `Ringle's learning system \nguarantees the highest return \nfor your time with tutors.`,
      's_home.tutor_txt_transit.desktop',
      `Ringle的授课系统\n让你和教师的时间\n显得更有价值！`,
      `Ringle的授課系統\n讓你和教師的時間\n顯得更有價值！`,
      `Ringleのレッスンシステムで\nチューターとの時間を\nより価値あるものに`,
      `Hệ thống học tập của Ringle\nmang lại giá trị tối đa\ncho thời gian của bạn với gia sư`,
    ],
    mobile: [
      `링글의 수업 시스템이\n튜터와의 시간을\n더욱 가치있게 해줍니다.`,
      `Ringle's learning system \nguarantees the highest return \nfor your time with tutors.`,
      's_home.tutor_txt_transit.mobile',
      `Ringle的授课系统\n让你和教师的时间\n显得更有价值！`,
      `Ringle的授課系統\n讓你和教師的時間\n顯得更有價值！`,
      `Ringleのレッスンシステムで\nチューターとの時間を\nより価値あるものに`,
      `Hệ thống học tập của Ringle\nmang lại giá trị cao nhất\ncho thời gian của bạn với gia sư`,
    ],
  },
  lesson_title: {
    subtitle: {
      desktop: [
        `나만을 위한 맞춤형 수업`,
        `Fully personalized lessons`,
        's_home.lesson_title.subtitle.desktop',
        `为我量身打造的客制化课程`,
        `為我量身打造的客製化課程`,
        `自分に合ったレッスン`,
        `Các buổi học được cá nhân hóa`,
      ],
      mobile: [
        `나만을 위한 맞춤형 수업`,
        `Fully personalized lessons`,
        's_home.lesson_title.subtitle.mobile',
        `为我量身打造的客制化课程`,
        `為我量身打造的客製化課程`,
        `自分に合ったレッスン`,
        `Các buổi học được cá nhân hóa`,
      ],
    },
    title: {
      desktop: [
        `수업 시간부터 방식까지,\n나에게 딱 맞게`,
        `From lesson schedule to style`,
        's_home.lesson_title.title.desktop',
        `自己决定\n上课时间及上课方式`,
        `自己決定\n上課時間及上課方式`,
        `レッスン時間から方法まで、\n自分に合わせたやり方で`,
        `Từ việc lên lịch học đến phương pháp\nđều phù hợp với bạn`,
      ],
      mobile: [
        `수업 시간부터 방식까지,\n나에게 딱 맞게`,
        `From lesson schedule to style`,
        's_home.lesson_title.title.mobile',
        `自己决定\n上课时间及上课方式`,
        `自己決定\n上課時間及上課方式`,
        `レッスン時間から方法まで、\n自分に合わせたやり方で`,
        `Từ việc lên lịch học đến phương pháp\nđều phù hợp với bạn`,
      ],
    },
    bodytxt: {
      desktop: [
        `나의 상황이나 공부 목적, 영어 실력에 맞춰\n수업을 유연하게 조정할 수 있어요.`,
        `Set lesson schedule, request, \nand learning style based on your needs.`,
        's_home.lesson_title.bodytxt.desktop',
        `依据自己的情况、学习目的、\n英语能力灵活安排课程。`,
        `依照自己的情況、學習目的、\n英語能力靈活安排課程。`,
        `状況や目的、英語力に合わせて\nレッスンを自由に調整。`,
        `Điều chỉnh buổi học linh hoạt \nphù hợp với công việc, mục đích và trình độ tiếng Anh của bạn.`,
      ],
      mobile: [
        `나의 상황이나 공부 목적, 영어 실력에 맞춰\n수업을 유연하게 조정할 수 있어요.`,
        `Set lesson schedule, request, \nand learning style based on your needs.`,
        's_home.lesson_title.bodytxt.mobile',
        `依据自己的情况、学习目的、\n英语能力灵活安排课程。`,
        `依照自己的情況、學習目的、\n英語能力靈活安排課程。`,
        `状況や目的、英語力に合わせて\nレッスンを自由に調整。`,
        `Điều chỉnh buổi học một cách linh hoạt \nđể phù hợp với tình huống, mục đích và trình độ tiếng Anh của bạn.`,
      ],
    },
  },
  lesson_txt_time: {
    title: {
      desktop: [
        `야근, 출장 때문에\n수업 놓친적 있으시죠?`,
        `Need a flexible schedule because \nof work or travel?`,
        's_home.lesson_txt_time.title.desktop',
        `因为加班、出差\n没办法上课的时候怎麽办？`,
        `因為加班、出差\n沒辦法上課的時候怎麼辦？`,
        `残業や出張で\nレッスンを逃してしまった\n経験、ありませんか？`,
        `Bạn cần lịch học linh động vì bận công việc và di chuyển liên tục?`,
      ],
      mobile: [
        `야근, 출장 때문에\n수업 놓친적 있으시죠?`,
        `Need a flexible schedule \nbecause of work or travel?`,
        's_home.lesson_txt_time.title.mobile',
        `因为加班、出差\n没办法上课的时候怎麽办？`,
        `因為加班、出差\n沒辦法上課的時候怎麼辦？`,
        `残業や出張で\nレッスンを逃してしまった\n経験、ありませんか？`,
        `Bạn cần lịch học linh động vì bận công việc và di chuyển liên tục?`,
      ],
    },
    bodytxt: {
      desktop: [
        `정해진 수업 시간에 얽매일 필요 없어요. 20분/40분 수업 중 선택하여 내가 원하는 시간과 요일에 편하게 수업을 들으세요.`,
        `Enjoy flexible lesson schedule with 20 or 40 minute lessons during hours that best suit you.`,
        's_home.lesson_txt_time.bodytxt.desktop',
        `没有固定的上课时间，\n在20分钟/40分钟的课中选择，\n在自己希望的上课日期和时间上课。`,
        `沒有固定的上課時間，\n在20分鐘/40分鐘的課中選擇，\n在自己希望的上課日期和時間上課。`,
        `決まったレッスン時間に縛られる必要はありません。\n20分/40分レッスンから選び希望の時間と曜日に気軽にレッスンが受けられます。`,
        `Tận hưởng lịch học linh hoạt với các bài học 20 hoặc 40 phút trong những khung giờ phù hợp nhất với bạn.`,
      ],
      mobile: [
        `정해진 수업 시간에 얽매일 필요 없어요. 20분/40분 수업 중 선택하여 내가 원하는 시간과 요일에 편하게 수업을 들으세요.`,
        `Enjoy flexible lesson schedule with 20 or 40 minute lessons during hours that best suit you.`,
        's_home.lesson_txt_time.bodytxt.mobile',
        `没有固定的上课时间，\n在20分钟/40分钟的课中选择，\n在自己希望的上课日期和时间上课。`,
        `沒有固定的上課時間，\n在20分鐘/40分鐘的課中選擇，\n在自己希望的上課日期和時間上課。`,
        `決まったレッスン時間に縛られる必要はありません。\n20分/40分レッスンから選び希望の時間と曜日に気軽にレッスンが受けられます。`,
        `Tận hưởng lịch học linh hoạt với các bài học 20 hoặc 40 phút trong những khung giờ phù hợp nhất với bạn.`,
      ],
    },
    caption: {
      desktop: [
        `*수업시간: 오전 5:00~오후 2:00 / 오후 19:00~새벽 3:00 KST`,
        `*Lessons available during 5 AM - 2 PM / 7 PM - 3 AM KST`,
        's_home.lesson_txt_time.caption.desktop',
        `*上课时间:上午5点~下午2点/下午7点~凌晨3点( KST)`,
        `*上課時間:上午5點~下午2點/下午7點~淩晨3點( KST)`,
        `*レッスン時間：午前5時～午後2時／午後7時～夜中3時（JST）`,
        `*Giờ học: 7:00 - 16:00 / 21:00 - 5:00 (ICT)`,
      ],
      mobile: [
        `*수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
        `*Lessons available during 5 AM - 2 PM / 7 PM - 3 AM KST`,
        's_home.lesson_txt_time.caption.mobile',
        `*上课时间:上午5点~下午2点/下午7点~凌晨3点( KST)`,
        `*上課時間:上午5點~下午2點/下午7點~淩晨3點( KST)`,
        `*レッスン時間：午前5時～午後2時／午後7時～夜中3時（JST）`,
        `*Giờ học: 7:00 - 16:00 / 21:00 - 5:00 (ICT)`,
      ],
    },
  },
  lesson_txt_material: {
    title: {
      desktop: [
        `지금 나에게 필요한\n교재를 직접 골라요.`,
        `Choose a lesson topic \nof your interest`,
        's_home.lesson_txt_material.title.desktop',
        `挑选感兴趣的教材`,
        `挑選自己喜歡的教材`,
        `今の自分に合った\n教材を選択`,
        `Tự do lựa chọn chủ đề học \nphù hợp với bạn.`,
      ],
      mobile: [
        `지금 나에게 필요한\n교재를 직접 골라요.`,
        `Choose a lesson topic \nof your interest`,
        's_home.lesson_txt_material.title.mobile',
        `挑选感兴趣的教材`,
        `挑選自己喜歡的教材`,
        `今の自分に合った\n教材を選択`,
        `Tự do lựa chọn chủ đề học \nphù hợp với bạn.`,
      ],
    },
    bodytxt: {
      desktop: [
        `현재 주목받는 주제부터 비즈니스 케이스, 실전 이메일 연습 등 매일 발행되는 1,000개 이상의 교재로 내가 원하는 주제의 수업을 만들어보세요.`,
        `With new trending topics added on a daily basis, Ringle has more than 1,000 lesson topics you can choose from.`,
        's_home.lesson_txt_material.bodytxt.desktop',
        `Ringle 每天更新教材，从最新流行时事、商业案例到电子邮件书写。\n从1000个以上的教材中，选自己感兴趣的主题上课！`,
        `Ringle 每天更新教材，從最新流行時事、商業案例到電子郵件書寫。\n從1000個以上的教材中，選自己喜歡的主題上課！`,
        `最新の時事問題や経済、ビジネスメールの練習など\n毎日アップデートされる1,000以上の教材を使いレッスンを自分のものに！`,
        `Với các chủ đề xu hướng mới được bổ sung hàng ngày, Ringle có hơn 1.700 chủ đề bài học mà bạn có thể chọn.`,
      ],
      mobile: [
        `현재 주목받는 주제부터 비즈니스 케이스, 실전 이메일 연습 등 매일 발행되는 1,000개 이상의 교재로 내가 원하는 주제의 수업을 만들어보세요.`,
        `With new trending topics added on a daily basis, Ringle has more than 1,000 lesson topics you can choose from.`,
        's_home.lesson_txt_material.bodytxt.mobile',
        `Ringle 每天更新教材，从最新流行时事、商业案例到电子邮件书写。\n从1000个以上的教材中，选自己感兴趣的主题上课！`,
        `Ringle 每天更新教材，從最新流行時事、商業案例到電子郵件書寫。\n從1000個以上的教材中，選自己喜歡的主題上課！`,
        `最新の時事問題や経済、ビジネスメールの練習など毎日アップデートされる1,000以上の教材を使いレッスンを自分のものに！`,
        `Với các chủ đề xu hướng mới được bổ sung hàng ngày, Ringle có hơn 1.700 chủ đề bài học mà bạn có thể chọn.`,
      ],
    },
  },
  lesson_txt_resume: {
    title: {
      desktop: [
        `당장 영어 인터뷰가\n코앞이라면?`,
        `Preparing for \nan English interview?`,
        's_home.lesson_txt_resume.title.desktop',
        `面试日期就要到来？`,
        `面試日期即將到來？`,
        `英語面接が\n迫っている時も！`,
        `Bạn đang chuẩn bị phỏng vấn Tiếng Anh?`,
      ],
      mobile: [
        `당장 영어 인터뷰가\n코앞이라면?`,
        `Preparing for \nan English interview?`,
        's_home.lesson_txt_resume.title.mobile',
        `面试日期就要到来？`,
        `面試日期即將到來？`,
        `英語面接が\n迫っている時も！`,
        `Bạn đang chuẩn bị phỏng vấn Tiếng Anh?`,
      ],
    },
    bodytxt: {
      desktop: [
        `영어 인터뷰 연습부터 레쥬메 교정, 발표 자료 준비 등 내가  원하는 자료를 직접 업로드하여 연습/교정 수업을 할 수 있어요.`,
        `Get help from Ringle tutors for CV updates, presentation practice, and mock interviews.`,
        's_home.lesson_txt_resume.bodytxt.desktop',
        `直接上传英语简历、发表资料，\n让教师协助纠正及进行练习。`,
        `直接上傳英語履歷、報告資料，\n讓教師協助糾正及進行練習。`,
        `英語面接の練習からレジュメ校正、発表資料の準備など必要な資料を直接アップロードして練習/校正レッスンを受けられます。`,
        `Hỗ trợ từ các gia sư Ringle để cập nhật CV, thực hành thuyết trình và luyện phỏng vấn .`,
      ],
      mobile: [
        `영어 인터뷰 연습부터 레쥬메 교정, 발표 자료 준비 등 내가  원하는 자료를 직접 업로드하여 연습/교정 수업을 할 수 있어요.`,
        `Get help from Ringle tutors for CV updates, presentation practice, and mock interviews.`,
        's_home.lesson_txt_resume.bodytxt.mobile',
        `直接上传英语简历、发表资料，\n让教师协助纠正及进行练习。`,
        `直接上傳英文履歷、報告資料，\n讓教師協助糾正及進行練習。`,
        `英語面接の練習からレジュメ校正、発表資料の準備など必要な資料を実際にアップロードして練習/校正レッスンを受けられます。`,
        `Hỗ trợ từ các gia sư Ringle để cập nhật CV, thực hành thuyết trình và luyện phỏng vấn .`,
      ],
    },
  },
  lesson_txt_profile: {
    title: {
      desktop: [
        `당신에게 필요한\n대화 상대를 찾고 있나요?`,
        `Looking for the perfect \nEnglish-speaking \nconversation partner?`,
        's_home.lesson_txt_profile.title.desktop',
        `您在寻找英语会话练习对象吗？`,
        `您在尋找英文會話練習對象嗎？`,
        `あなたにピッタリな\n話し相手をお探しですか？`,
        `Bạn đang tìm kiếm đối tác luyện tập tiếng anh hoàn hảo?`,
      ],
      mobile: [
        `당신에게 필요한\n대화 상대를 찾고 있나요?`,
        `Looking for the perfect \nEnglish-speaking conversation partner?`,
        's_home.lesson_txt_profile.title.mobile',
        `您在寻找英语会话练习对象吗？`,
        `您在尋找英文會話練習對象嗎？`,
        `あなたにピッタリな\n話し相手をお探しですか？`,
        `Tìm kiếm đối tác đàm thoại tiếng anh hoàn hảo?`,
      ],
    },
    bodytxt: {
      desktop: [
        `한국어든 영어든 대화가 잘 통해야 지루하지 않죠. 관심사, 자기소개, 그리고 전공을 바탕으로 나와 잘 맞는 튜터를 골라보세요. 아직 잘 모르겠다면 링글팀이 보장하는 추천 튜터로 쉽게 고를 수 있습니다.`,
        `Regardless of the language, the quality of the conversation heavily depends on who you are talking to. You can find your perfect tutor based on their majors, interests, and experiences. Too many to select from? Try searching from Ringle's recommended tutors.`,
        's_home.lesson_txt_profile.bodytxt.desktop',
        `不论是用什么语言沟通，双方要有共同语言才能让对话更有趣！ 可以利用兴趣、自我介绍\n和专业选择适合自己的教师，也可以轻鬆从Ringle团队推荐的教师中挑选。`,
        `不管用什麼語言溝通，雙方要有共同語言才能讓對話更有趣！ 可以利用興趣、自我介紹\n和主修選擇符合需求的教師，也可以輕鬆從Ringle團隊推薦的教師中挑選。`,
        `日本語でも英語でもスムーズに会話したいもの。好きなこと、自己紹介、そして専攻を基に自分にピッタリのチューターをお選びいただけます。\nRingleチームがおススメするチューターから選んでもOK。`,
        `Bất kể ngôn ngữ nào, chất lượng của cuộc trò chuyện phụ thuộc rất nhiều vào người mà bạn đang nói chuyện. Bạn có thể tìm thấy gia sư lý tưởng của mình dựa trên chuyên ngành, sở thích và kinh nghiệm của họ. Bạn có quá nhiều lựa chọn? Hãy thử tìm kiếm từ các gia sư được đề xuất bởi Ringle.`,
      ],
      mobile: [
        `한국어든 영어든 대화가 잘 통해야 지루하지 않죠. 관심사, 자기소개, 그리고 전공을 바탕으로 나와 잘 맞는 튜터를 골라보세요. 아직 잘 모르겠다면 링글팀이 보장하는 추천 튜터로 쉽게 고를 수 있습니다.`,
        `Regardless of the language, the quality of the conversation heavily depends on who you are talking to. You can find your perfect tutor based on their majors, interests, and experiences. Too many to select from? Try searching from Ringle's recommended tutors.`,
        's_home.lesson_txt_profile.bodytxt.mobile',
        `不论是用什么语言沟通，双方要有共同语言才能让对话更有趣！ 可以利用兴趣、自我介绍\n和专业选择适合自己的教师，也可以轻鬆从Ringle团队推荐的教师中挑选。`,
        `不管用什麼語言溝通，雙方要有共同語言才能讓對話更有趣！ 可以利用興趣、自我介紹\n和主修選擇符合需求的教師，也可以輕鬆從Ringle團隊推薦的教師中挑選。`,
        `日本語でも英語でもスムーズに会話したいもの。好きなこと、自己紹介、そして専攻を基に自分にピッタリのチューターをお選びいただけます。\nRingleチームがおススメするチューターから選んでもOK。`,
        `Bất kể ngôn ngữ nào, chất lượng của cuộc trò chuyện phụ thuộc rất nhiều vào người mà bạn đang nói chuyện. Bạn có thể tìm thấy gia sư lý tưởng của mình dựa trên chuyên ngành, sở thích và kinh nghiệm của họ. Bạn có quá nhiều lựa chọn? Hãy thử tìm kiếm từ các gia sư được đề xuất bởi Ringle.`,
      ],
    },
  },
  lesson_txt_lessonmode: {
    title: {
      desktop: [
        `수업 방식도\n내가 원하는대로 정해요.`,
        `Set your own \nlesson style`,
        's_home.lesson_txt_lessonmode.title.desktop',
        `设定适合自己的上课方式`,
        `設定適合自己的上課方式`,
        `レッスン方法も\n自分のタイプに合わせて`,
        `Chia sẻ phong cách học của bạn`,
      ],
      mobile: [
        `수업 방식도\n내가 원하는대로 정해요.`,
        `Set your own \nlesson style`,
        's_home.lesson_txt_lessonmode.title.mobile',
        `设定适合自己的上课方式`,
        `設定適合自己的上課方式`,
        `レッスン方法も\n自分のタイプに合わせて`,
        `Chia sẻ phong cách học của bạn`,
      ],
    },
    bodytxt: {
      desktop: [
        `수업 모드부터 집중 교정 영역까지 꼼꼼히 설정할 수 있어요. 짧은 수업 시간 동안 나에게 꼭 필요한 것에 집중하세요.`,
        `Personalize your lesson by setting lesson styles and sharing specific lesson requests.`,
        's_home.lesson_txt_lessonmode.bodytxt.desktop',
        `设定授课模式及希望教师集中纠正的领域， 在有限的上课时间裡，将时间利用最大化，确实接受教师的纠正与协助！`,
        `設定授課模式及希望教師集中糾正的領域， 在有限的上課時間裡，將時間利用最大化，確實接受教師的糾正與協助！`,
        `レッスンモードから集中校正領域まで\n細かく設定が可能。短い時間の中で\n自分に必要な部分だけに集中できます。`,
        `Cá nhân hóa bài học bằng cách chia sẻ phương pháp học tập và các yêu cầu cụ thể cho tiết học`,
      ],
      mobile: [
        `수업 모드부터 집중 교정 영역까지 꼼꼼히 설정할 수 있어요. 짧은 수업 시간 동안 나에게 꼭 필요한 것에 집중하세요.`,
        `Personalize your lesson by setting lesson styles and sharing specific lesson requests.`,
        's_home.lesson_txt_lessonmode.bodytxt.mobile',
        `设定授课模式及希望教师集中纠正的领域， 在有限的上课时间裡，将时间利用最大化，确实接受教师的纠正与协助！`,
        `設定授課模式及希望教師集中糾正的領域， 在有限的上課時間裡，將時間利用最大化，確實接受教師的糾正與協助！`,
        `レッスンモードから集中校正領域まで細かく設定が可能。短い時間の中で自分に必要な部分だけに集中できます。`,
        `Cá nhân hóa bài học bằng cách chia sẻ phương pháp học tập và các yêu cầu cụ thể cho tiết học`,
      ],
    },
  },
  system_title: {
    subtitle: {
      desktop: [
        `내 실력을 눈으로 확인하는 시스템`,
        `Check your progress regularly`,
        's_home.system_title.subtitle.desktop',
        `能即时确认英语实力的系统`,
        `能即時確認英文實力的系統`,
        `自分の英語力を目で見て確認できるシステム`,
        `Kiểm tra tiến độ đều đặn`,
      ],
      mobile: [
        `내 실력을 눈으로 확인하는 시스템`,
        `Check your progress regularly`,
        's_home.system_title.subtitle.mobile',
        `能即时确认英语实力的系统`,
        `能即時確認英文實力的系統`,
        `自分の英語力を目で見て確認できるシステム`,
        `Kiểm tra tiến độ đều đặn`,
      ],
    },
    title: {
      desktop: [
        `실시간 피드백부터\n수업 상세 분석까지`,
        `From real-time corrections \nto detailed post-lesson feedback`,
        's_home.system_title.title.desktop',
        `从课堂即时反馈到\n课后分析`,
        `從課堂即時回饋到\n課後分析`,
        `リアルタイムのフィードバックから\nレッスン詳細分析まで`,
        `Từ phản hồi trực tiếp trong giờ học đến đánh giá sau buổi học`,
      ],
      mobile: [
        `실시간 피드백부터\n수업 상세 분석까지`,
        `From real-time corrections to\ndetailed post-lesson feedback`,
        's_home.system_title.title.mobile',
        `从课堂即时反馈到\n课后分析`,
        `從課堂即時回饋到\n課後分析`,
        `リアルタイムのフィードバックから\nレッスン詳細分析まで`,
        `Từ phản hồi trực tiếp trong giờ học đến đánh giá sau buổi học`,
      ],
    },
    bodytxt: {
      desktop: [
        `매 수업 향상되는 실력을\n수업 기록과 결과치로 직접 확인하세요.`,
        `Monitor your progress through \ndetailed evaluations and charts`,
        's_home.system_title.bodytxt.desktop',
        `利用课程记录和课后分析\n掌握每堂课的学习情况`,
        `利用課程記錄和課後分析\n掌握每堂課的學習情況`,
        `レッスンごとに伸びる実力を\nレッスン記録や結果値で直接確認。`,
        `Quản lý tiến độ của bạn thông qua biểu đồ phân tích chi tiểt`,
      ],
      mobile: [
        `매 수업 향상되는 실력을\n수업 기록과 결과치로 직접 확인하세요.`,
        `Monitor your progress through \ndetailed evaluations and charts`,
        's_home.system_title.bodytxt.mobile',
        `利用课程记录和课后分析\n掌握每堂课的学习情况`,
        `利用課程記錄和課後分析\n掌握每堂課的學習情況`,
        `レッスンごとに伸びる実力を\nレッスン記録や結果値で直接確認。`,
        `Quản lý tiến độ của bạn thông qua biểu đồ phân tích chi tiểt`,
      ],
    },
  },
  system_txt_docsedit: {
    title: {
      desktop: [
        `튜터가 내 영어를\n실시간으로 고쳐줘요.`,
        `Receive real-time \nfeedback from tutors`,
        's_home.system_txt_docsedit.title.desktop',
        `教师即时\n纠正我的英语`,
        `教師即時\n糾正我的英文`,
        `チューターが\nその場で添削`,
        `Nhận phản hồi trực tiếp từ gia sư`,
      ],
      mobile: [
        `튜터가 내 영어를\n실시간으로 고쳐줘요.`,
        `Receive real-time \nfeedback from tutors`,
        's_home.system_txt_docsedit.title.mobile',
        `教师即时\n纠正我的英语`,
        `教師即時\n糾正我的英文`,
        `チューターが\nその場で添削`,
        `Nhận phản hồi trực tiếp từ gia sư`,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 수업은 튜터와 화면을 공유하여 보면서 진행됩니다. 수업 중 교정이 필요한 내 영어를 튜터가 실시간으로 수업 노트에 받아 적고, 더 나은 표현, 틀린 문법을 제안하면서 이유를 설명해 줍니다.`,
        `With a shared screen during lessons, you can see how your tutor corrects your English with alternative expressions.`,
        's_home.system_txt_docsedit.bodytxt.desktop',
        `上课时和老师使用共享画面，即时看见教师修改的句子。教师除了纪录修改内容，也说明修改原因及提供建议。`,
        `上課時和老師使用共享畫面，即時看見教師修改的句子。教師除了紀錄修改內容，也說明修改原因及提供建議。`,
        `レッスンは画面を共有しながら\n行われます。校正が必要な部分はチューターがレッスン中にその場で授業ノートに書き留め、より良い表現、正しい文法を提案しながら理由を説明します。`,
        `Với màn hình chia sẻ xuyên suốt buổi học, bạn có thể xem cách gia sư sửa lỗi tiếng Anh cho bạn bằng cách sử dụng những cụm từ thay thế.`,
      ],
      mobile: [
        `링글 수업은 튜터와 화면을 공유하여 보면서 진행됩니다. 수업 중 교정이 필요한 내 영어를 튜터가 실시간으로 수업 노트에 받아 적고, 더 나은 표현, 틀린 문법을 제안하면서 이유를 설명해 줍니다.`,
        `With a shared screen during lessons, you can see how your tutor corrects your English with alternative expressions.`,
        's_home.system_txt_docsedit.bodytxt.mobile',
        `上课时和老师使用共享画面，即时看见教师修改的句子。教师除了纪录修改内容，也说明修改原因及提供建议。`,
        `上課時和老師使用共享畫面，即時看見教師修改的句子。教師除了紀錄修改內容，也說明修改原因及提供建議。`,
        `レッスンは画面を共有しながら行われます。校正が必要な部分はチューターがレッスン中にその場で授業ノートに書き留め、より良い表現、正しい文法を提案しながら理由を説明します。`,
        `Với màn hình chia sẻ xuyên suốt buổi học, bạn có thể xem cách gia sư sửa lỗi tiếng Anh cho bạn bằng cách sử dụng những cụm từ thay thế.`,
      ],
    },
  },
  system_txt_feedback: {
    title: {
      desktop: [
        `원어민 튜터가 집중해서 \n고칠 점을 알려줘요.`,
        `Personalized feedback\n from a native-speaker tutor.`,
        's_home.system_txt_feedback.title.desktop',
        `母语教师\n提供最专业的修改建议`,
        `母語教師\n提供最專業的修改建議`,
        `ネイティブのチューターが\nあなたの改善すべき点を教えてくれます。`,
        ``,
      ],
      mobile: [
        `원어민 튜터가 집중해서 \n고칠 점을 알려줘요.`,
        `Personalized feedback\n from a native-speaker tutor.`,
        's_home.system_txt_feedback.title.mobile',
        `母语教师\n提供最专业的修改建议`,
        `母語教師\n提供最專業的修改建議`,
        `ネイティブのチューターが\nあなたの改善すべき点を教えてくれます。`,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `튜터에게 이번 수업에 대한 메세지와 함께 다음 수업에서 집중하고 개선하면 좋을 영역에 대한 피드백을 받아요.`,
        `Receive tutor feedback on how you did in the lesson and the areas you should focus on and improve in your next lesson.`,
        's_home.system_txt_feedback.bodytxt.desktop',
        `在教师提供的课后评价报告中了解自己这堂课表现好的地方 以及下堂课可以加强的部分。`,
        `在教師提供的課後評價報告中了解自己這堂課表現好的地方 以及下堂課可以加強的部分。`,
        `チューターに今回のレッスンについてのメッセージと一緒に、次のレッスンで集中して改善した方が良い分野についてのフィードバックを受け取ります。`,
        ``,
      ],
      mobile: [
        `튜터에게 이번 수업에 대한 메세지와 함께 다음 수업에서 집중하고 개선하면 좋을 영역에 대한 피드백을 받아요.`,
        `Receive tutor feedback on how you did in the lesson and the areas you should focus on and improve in your next lesson.`,
        's_home.system_txt_feedback.bodytxt.mobile',
        `在教师提供的课后评价报告中了解自己这堂课表现好的地方 以及下堂课可以加强的部分。`,
        `在教師提供的課後評價報告中了解自己這堂課表現好的地方 以及下堂課可以加強的部分。`,
        `チューターに今回のレッスンについてのメッセージと一緒に、次のレッスンで集中して改善した方が良い分野についてのフィードバックを受け取ります。`,
        ``,
      ],
    },
  },
  system_txt_conversation: {
    title: {
      desktop: [
        `대화 내용이\n사라지지 않고 남아요.`,
        `Don't worry about \ntaking notes`,
        's_home.system_txt_conversation.title.desktop',
        `对话内容\n不会消失`,
        `對話內容\n不會消失`,
        `会話内容は\nそのまま保存`,
        `Nội dung trò chuyện được lưu lại`,
      ],
      mobile: [
        `대화 내용이\n사라지지 않고 남아요.`,
        `Don't worry about \ntaking notes.`,
        's_home.system_txt_conversation.title.mobile',
        `对话内容\n不会消失`,
        `對話內容\n不會消失`,
        `会話内容は\nそのまま保存`,
        `Nọi dung trò chuyện được lưu lại`,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 수업 시간 동안 이야기한 내용은 모두 녹음되어 자동으로 스크립트 처리됩니다. 수업이 끝나도 튜터가 교정해 준 내용을 다시 듣고, 읽어보면서 복습할 수 있어요.`,
        `Your conversation with the tutor during the lesson will be automatically recorded and transcribed so that you can review the lesson and learn from your tutor's corrections.`,
        's_home.system_txt_conversation.bodytxt.desktop',
        `Ringle课程进行时会录音，课后系统将录音档案转成文字档，课后可以重複聆听複习。`,
        `Ringle課程進行時會錄音，課後系統將錄音檔案轉成文字檔，課後可以重複聆聽複習。`,
        `レッスン中の会話内容は全て録音され\n自動でスクリプト処理されます。\nレッスンが終わってもチューターの校正内容を\nもう一度聞き、読みながら復習できます。`,
        `Cuộc trò chuyện của bạn với gia sư trong suốt buổi học được ghi âm và sao chép tự động. Sau khi buổi học kết thúc, bạn vẫn có thể xem lại nội dung mà gia sư đã chỉnh sửa để ôn tập.`,
      ],
      mobile: [
        `링글 수업 시간 동안 이야기한 내용은 모두 녹음되어 자동으로 스크립트 처리됩니다. 수업이 끝나도 튜터가 교정해 준 내용을 다시 듣고, 읽어보면서 복습할 수 있어요.`,
        `Your conversation with the tutor during the lesson will be automatically recorded and transcribed so that you can review the lesson and learn from your tutor's corrections.`,
        's_home.system_txt_conversation.bodytxt.mobile',
        `Ringle课程进行时会录音，课后系统将录音档案转成文字档，课后可以重複聆听複习。`,
        `Ringle課程進行時會錄音，課後系統將錄音檔案轉成文字檔，課後可以重複聆聽複習。`,
        `レッスン中の会話内容は全て録音され自動でスクリプト処理されます。レッスンが終わってもチューターの校正内容をもう一度聞き、読みながら復習できます。`,
        `Cuộc trò chuyện của bạn với gia sư trong suốt buổi học được ghi âm và sao chép tự động. Ngay cả sau khi kết thúc buổi học, bạn vẫn có thể nghe lại nội dung mà gia sư đã chỉnh sửa, đọc và ôn tập lại.`,
      ],
    },
  },
  system_txt_analysis: {
    title: {
      desktop: [
        `수업 후 링글 AI 분석으로\n말하기 습관을 개선해요.`,
        `Correct your speaking habits\n with Ringle AI Analysis.`,
        's_home.system_txt_analysis.title.desktop',
        `利用Ringle AI分析\n了解并改善自己常犯的错误`,
        `利用Ringle AI分析\n了解並改善自己常犯的錯誤`,
        `AI Analysisを使って\nスピーキングを改善しよう`,
        `Xác định những lỗi lặp từ tiếng Anh của bạn \nbằng tính năng phân tích tự động`,
      ],
      mobile: [
        `수업 후 링글 AI 분석으로\n말하기 습관을 개선해요.`,
        `Correct your speaking habits\n with Ringle AI Analysis.`,
        's_home.system_txt_analysis.title.mobile',
        `利用Ringle AI分析\n了解并改善自己常犯的错误`,
        `利用Ringle AI分析\n了解並改善自己常犯的錯誤`,
        `AI Analysisを使って\nスピーキングを改善しよう`,
        `Xác định những lỗi lặp từ tiếng Anh của bạn \nbằng tính năng phân tích tự động`,
      ],
    },
    bodytxt: {
      desktop: [
        `매 수업 후 링글 AI 분석을 통해 수업 중 사용한 영어에서 영역별 점수와 상세 내용을 보여줍니다. 링글 AI 분석에서 보여주는 복잡성, 정확성, 유창성, 발음 영역에 대한 개선점을 다음 수업에서 집중 연습하여 빠르게 영어 실력을 키울 수  있습니다.`,
        `Ringle AI Analysis provides scores for the complexity, accuracy, fluency, and pronunciation of your English speech and identifies what you should focus on so that you can make improvements in your next lesson.`,
        's_home.system_txt_analysis.bodytxt.desktop',
        `Ringle AI分析报告针对每堂课学生的发言内容进行详细的分析，其中包含複杂度、正确度、流畅度和发音。通过报告，学习者了解自己的优势和需要改进的领域，并提供改善建议，让你在下一堂课中表现得更好。`,
        `Ringle AI分析報告針對每堂課學生的發言內容進行詳細的分析，其中包含複雜度、正確度、流暢度和發音。通過報告，學習者了解自己的優勢和需要改進的領域，並提供改善建議，讓你在下一堂課中表現得更好。`,
        `毎回のレッスン後、RingleのAI Analysisにより、レッスン中に使用した英語の分野別のスコアと詳細が表示されます。RingleのAI Analysisで示された複雑さ、正確さ、流暢さ、発音の改善点を次のレッスンで集中的に練習することで、スピーディーに英語力を伸ばすことができます。`,
        `Kiểm tra và cải thiện thông qua phân tích các thói quen nói tiếng Anh chưa tốt như lặp từ nhiều lần và sử dụng các từ đệm không cần thiết.`,
      ],
      mobile: [
        `매 수업 후 링글 AI 분석을 통해 수업 중 사용한 영어에서 영역별 점수와 상세 내용을 보여줍니다. 링글 AI 분석에서 보여주는 복잡성, 정확성, 유창성, 발음 영역에 대한 개선점을 다음 수업에서 집중 연습하여 빠르게 영어 실력을 키울 수  있습니다.`,
        `Ringle AI Analysis provides scores for the complexity, accuracy, fluency, and pronunciation of your English speech and identifies what you should focus on so that you can make improvements in your next lesson.`,
        's_home.system_txt_analysis.bodytxt.mobile',
        `Ringle AI分析报告针对每堂课学生的发言内容进行详细的分析，其中包含複杂度、正确度、流畅度和发音。通过报告，学习者了解自己的优势和需要改进的领域，并提供改善建议，让你在下一堂课中表现得更好。`,
        `Ringle AI分析報告針對每堂課學生的發言內容進行詳細的分析，其中包含複雜度、正確度、流暢度和發音。通過報告，學習者了解自己的優勢和需要改進的領域，並提供改善建議，讓你在下一堂課中表現得更好。`,
        `毎回のレッスン後、RingleのAI Analysisにより、レッスン中に使用した英語の分野別のスコアと詳細が表示されます。RingleのAI Analysisで示された複雑さ、正確さ、流暢さ、発音の改善点を次のレッスンで集中的に練習することで、スピーディーに英語力を伸ばすことができます。`,
        `Kiểm tra và cải thiện thông qua phân tích các thói quen nói tiếng Anh chưa tốt như lặp từ nhiều lần và sử dụng các từ đệm không cần thiết.`,
      ],
    },
  },
  content_title: {
    subtitle: {
      desktop: [
        `링글과 함께하는 하루`,
        `A day with Ringle`,
        's_home.content_title.subtitle.desktop',
        `与Ringle一起度过的一天`,
        `和Ringle一起開啟每一天`,
        `Ringleとの1日`,
        `Một ngày với Ringle`,
      ],
      mobile: [
        `링글과 함께하는 하루`,
        `A day with Ringle`,
        's_home.content_title.subtitle.mobile',
        `与Ringle一起度过的一天`,
        `和Ringle一起開啟每一天`,
        `Ringleとの1日`,
        `Một ngày với Ringle`,
      ],
    },
    title: {
      desktop: [
        `수업 밖에서도\n영어를 놓치지 않도록`,
        `Don't miss out on your English \noutside of lessons`,
        's_home.content_title.title.desktop',
        `就算不在课堂中\n也不能错过英文阅读`,
        `就算不是上課時間\n也不能錯過英文閱讀`,
        `レッスン以外でも\n英語がそばに`,
        `Để không bỏ lỡ tiếng Anh\nngay cả ngoài giờ học`,
      ],
      mobile: [
        `수업 밖에서도\n영어를 놓치지 않도록`,
        `Don't miss out on your English \noutside of lessons`,
        's_home.content_title.title.mobile',
        `就算不在课堂中\n也不能错过英文阅读`,
        `就算不是上課時間\n也不能錯過英文閱讀`,
        `レッスン以外でも\n英語がそばに`,
        `Để không bỏ lỡ tiếng Anh\nngay cả ngoài giờ học`,
      ],
    },
  },
  content_txt_morning: {
    subtitle: {
      desktop: [
        `아침 9시`,
        `9 AM`,
        's_home.content_txt_morning.subtitle.desktop',
        `早上九点`,
        `早上九點`,
        `朝9時`,
        `9 giờ sáng`,
      ],
      mobile: [
        `아침 9시`,
        `9 PM`,
        's_home.content_txt_morning.subtitle.mobile',
        `早上九点`,
        `早上九點`,
        `朝9時`,
        `9 giờ sáng`,
      ],
    },
    title: {
      desktop: [
        `출근길 아침\n새로운 교재로 리딩 연습`,
        `Read Ringle's latest lesson topics\nduring commute time`,
        's_home.content_txt_morning.title.desktop',
        `利用通勤时间\n阅读最新教材`,
        `利用通勤時間\n閱讀最新教材`,
        `出勤中は\n新しい教材で\nリーディング練習`,
        `Đọc các chủ đề bài học mới nhất của Ringle\ntrong thời gian di chuyển`,
      ],
      mobile: [
        `출근길 아침\n새로운 교재로 리딩 연습`,
        `Read Ringle's latest lesson \ntopics during commute time`,
        's_home.content_txt_morning.title.mobile',
        `利用通勤时间\n阅读最新教材`,
        `利用通勤時間\n閱讀最新教材`,
        `出勤中は\n新しい教材で\nリーディング練習`,
        `Buổi sáng trên đường đi làm,\nluyện đọc tài liệu mới`,
      ],
    },
    bodytxt: {
      desktop: [
        `가장 트렌디한 사회 이슈부터, 정치, 경제 비즈니스 케이스, 일상 대화 주제까지. 유료 뉴스레터 부럽지 않은 링글의 무료 교재를 링글앱으로 매일 받아보세요.`,
        `Learn about the latest trending topics on politics, economics, business, and more through the Ringle app.`,
        's_home.content_txt_morning.bodytxt.desktop',
        `从最热门的流行话题，到政治、经济、商业和日常会话主题。\n每天在Ringle APP，用英文接收新知！`,
        `從最熱門的流行話題，到政治、經濟、商業和日常會話主題。\n每天在Ringle APP，用英文接收新知！`,
        `最新の社会的イシューから政治、経済ビジネスケース、日常会話のテーマまで。\n有料ニュースレターに劣らないRingleの無料教材をアプリで毎日お届けします。`,
        `Tìm hiểu về các chủ đề thịnh hành mới nhất như chính trị, kinh tế và kinh doanh thông qua ứng dụng Ringle.`,
      ],
      mobile: [
        `가장 트렌디한 사회 이슈부터, 정치, 경제 비즈니스 케이스, 일상 대화 주제까지. 유료 뉴스레터 부럽지 않은 링글의 무료 교재를 링글앱으로 매일 받아보세요.`,
        `Learn about the latest trending topics on politics, economics, business, and more through the Ringle app.`,
        's_home.content_txt_morning.bodytxt.mobile',
        `从最热门的流行话题，到政治、经济、商业和日常会话主题。\n每天在Ringle APP，用英文接收新知！`,
        `從最熱門的流行話題，到政治、經濟、商業和日常會話主題。\n每天在Ringle APP，用英文接收新知！`,
        `最新の社会的イシューから政治、経済ビジネスケース、日常会話のテーマまで。有料ニュースレターに劣らないRingleの無料教材をアプリで毎日お届けします。`,
        `Tìm hiểu về các chủ đề thịnh hành mới nhất như chính trị, kinh tế và kinh doanh thông qua ứng dụng Ringle.`,
      ],
    },
  },
  content_txt_afternoon: {
    subtitle: {
      desktop: [
        `오후 1시`,
        `1 PM`,
        's_home.content_txt_afternoon.subtitle.desktop',
        `下午一点`,
        `下午一點`,
        `午後1時`,
        `1h chiều`,
      ],
      mobile: [
        `오후 1시`,
        `1 PM`,
        's_home.content_txt_afternoon.subtitle.mobile',
        `下午一点`,
        `下午一點`,
        `午後1時`,
        `1h chiều`,
      ],
    },
    title: {
      desktop: [
        `데일리 학습으로\n매일 5분씩 영어 퀴즈 풀기`,
        `Study daily with\n 5-minute quizzes`,
        's_home.content_txt_afternoon.title.desktop',
        `在每日学习 \n 每天花5分钟完成英文练习`,
        `在每日學習 \n 每天花5分鐘完成英文練習`,
        `5分間の英語クイズで\n毎日勉強しましょう`,
        ``,
      ],
      mobile: [
        `데일리 학습으로\n매일 5분씩 영어 퀴즈 풀기`,
        `Study daily with\n 5-minute quizzes`,
        's_home.content_txt_afternoon.title.mobile',
        `在每日学习 \n 每天花5分钟完成英文练习`,
        `在每日學習 \n 每天花5分鐘完成英文練習`,
        `5分間の英語クイズで\n毎日勉強しましょう`,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 데일리 학습을 통해 문법, 스피킹, 라이팅 등 일주일 간 고객들이 가장 많이 틀린 영어에 대해퀴즈를 풀면서 공부할 수 있어요.`,
        `Ringle's Daily Study feature provides daily quizzes for English grammar, speaking, writing, and more, based on the most common mistakes our users made over the week.`,
        's_home.content_txt_afternoon.bodytxt.desktop',
        `透过Ringle每日学习活动学习文法/口说和写作，每週的每日学习内容都是Ringle学习者错误率最高的部分`,
        `透過Ringle每日學習活動學習文法/口說和寫作，每週的每日學習內容都是Ringle學習者錯誤率最高的部分`,
        `RingleのDaily Study機能では、ユーザーが1週間の間にした最も一般的な間違いをもとに、英文法、スピーキング、ライティングなどのクイズを毎日出題しています。`,
        ``,
      ],
      mobile: [
        `링글 데일리 학습을 통해 문법, 스피킹, 라이팅 등 일주일 간 고객들이 가장 많이 틀린 영어에 대해퀴즈를 풀면서 공부할 수 있어요.`,
        `Ringle's Daily Study feature provides daily quizzes for English grammar, speaking, writing, and more, based on the most common mistakes our users made over the week.`,
        's_home.content_txt_afternoon.bodytxt.mobile',
        `透过Ringle每日学习活动学习文法/口说和写作，每週的每日学习内容都是Ringle学习者错误率最高的部分`,
        `透過Ringle每日學習活動學習文法/口說和寫作，每週的每日學習內容都是Ringle學習者錯誤率最高的部分`,
        `RingleのDaily Study機能では、ユーザーが1週間の間にした最も一般的な間違いをもとに、英文法、スピーキング、ライティングなどのクイズを毎日出題しています。`,
        ``,
      ],
    },
  },
  content_txt_evening: {
    subtitle: {
      desktop: [
        `저녁 9시`,
        `9 PM`,
        's_home.content_txt_evening.subtitle.desktop',
        `晚上九点`,
        `晚上九點`,
        `夜9時`,
        `9 giờ tối`,
      ],
      mobile: [
        `저녁 9시`,
        `9 PM`,
        's_home.content_txt_evening.subtitle.mobile',
        `晚上九点`,
        `晚上九點`,
        `夜9時`,
        `9 giờ tối`,
      ],
    },
    title: {
      desktop: [
        `매달 새로운 영어 원서를\n함께 읽으며 영어 학습 폭 넓히기`,
        `Finish a book in English\n together every month`,
        's_home.content_txt_evening.title.desktop',
        `每个月阅读一本英文小说\n增进英文实力`,
        `每個月閱讀一本英文小說\n增進英文實力`,
        `毎月新しい英語の書籍を一緒に読んで、英語学習の幅を広げましょう。`,
        ``,
      ],
      mobile: [
        `매달 새로운 영어 원서를\n함께 읽으며 영어 학습 폭 넓히기`,
        `Finish a book in English\n together every month`,
        's_home.content_txt_evening.title.mobile',
        `每个月阅读一本英文小说\n增进英文实力`,
        `每個月閱讀一本英文小說\n增進英文實力`,
        `毎月新しい英語の書籍を一緒に読んで、英語学習の幅を広げましょう。`,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글의 인기 튜터들과 함께 매달 새로운 원서를 읽으면서 새로운 영어 표현과 문장을 배우며 영어 학습에 재미를 늘려나가보세요.`,
        `Read a new book every month with our favorite tutors and learn new English expressions and sentences to make learning English more enjoyable.`,
        's_home.content_txt_evening.bodytxt.desktop',
        `和Ringle的人气教师一起阅读英文原文书，学习新的英文表达和文章，学习英文更有趣！`,
        `和Ringle的人氣教師一起閱讀英文原文書，學習新的英文表達和文章，學習英文更有趣！`,
        `人気チューターと一緒に毎月新しい書籍を読みながら、新しい英語表現や文章を学び、英語学習をもっと楽しみましょう。`,
        ``,
      ],
      mobile: [
        `링글의 인기 튜터들과 함께 매달 새로운 원서를 읽으면서 새로운 영어 표현과 문장을 배우며 영어 학습에 재미를 늘려나가보세요.`,
        `Read a new book every month with our favorite tutors and learn new English expressions and sentences to make learning English more enjoyable.`,
        's_home.content_txt_evening.bodytxt.mobile',
        `和Ringle的人气教师一起阅读英文原文书，学习新的英文表达和文章，学习英文更有趣！`,
        `和Ringle的人氣教師一起閱讀英文原文書，學習新的英文表達和文章，學習英文更有趣！`,
        `人気チューターと一緒に毎月新しい書籍を読みながら、新しい英語表現や文章を学び、英語学習をもっと楽しみましょう。`,
        ``,
      ],
    },
    forfree_title: {
      desktop: [
        `교재부터 데일리 학습까지\n이 모든게 가입만해도 무료.`,
        `Free access to all \nlesson materials and daily study features`,
        's_home.content_txt_evening.forfree_title.desktop',
        `只要加入会员\n就能免费浏览教材和进行每日学习`,
        `只要加入會員\n就能免費瀏覽教材和進行每日學習`,
        `教材から毎日の学習機能まで\n登録するだけで無料使用できます。`,
        `Free access to all \nlesson materials and daily study features`,
      ],
      mobile: [
        `교재부터 데일리 학습까지\n이 모든게 가입만해도 무료.`,
        `Free access to all \nlesson materials and daily study features`,
        's_home.content_txt_evening.forfree_title.mobile',
        `只要加入会员\n就能免费浏览教材和进行每日学习`,
        `只要加入會員\n就能免費瀏覽教材和進行每日學習`,
        `教材から毎日の学習機能まで\n登録するだけで無料使用できます。`,
        `Free access to all \nlesson materials and daily study features`,
      ],
    },
    forfree_caption: {
      desktop: [
        `*원서 읽기 웨비나 유료 진행`,
        `*A fee will be charged for Read Together & Read Alone webinars.`,
        's_home.content_txt_evening.forfree_caption.desktop',
        `* Read Together和Read Alone为付费活动（限韩国）`,
        `* Read Together和Read Alone為付費活動（限韓國）`,
        `*一部のウェビナーは有料です`,
        `*A fee will be charged for Read Together & Read Alone webinars.`,
      ],
      mobile: [
        `*원서 읽기 웨비나 유료 진행`,
        `*A fee will be charged for Read Together & Read Alone webinars.`,
        's_home.content_txt_evening.forfree_caption.mobile',
        `* Read Together和Read Alone为付费活动（限韩国）`,
        `* Read Together和Read Alone為付費活動（限韓國）`,
        `*一部のウェビナーは有料です`,
        `*A fee will be charged for Read Together & Read Alone webinars.`,
      ],
    },
  },
  review_title: {
    subtitle: {
      desktop: [
        `고객후기`,
        `Customers' Testimonials`,
        's_home.review_title.subtitle.desktop',
        `用戶心得`,
        `用戶心得`,
        `受講生の声`,
        `Phản hồi khách hàng`,
      ],
      mobile: [
        `고객후기`,
        `Customers' Testimonials`,
        's_home.review_title.subtitle.mobile',
        `用戶心得`,
        `用戶心得`,
        `受講生の声`,
        `Phản hồi khách hàng`,
      ],
    },
    seoyoung_title: {
      desktop: [
        `“링글로 반복되는 영어 실수를\n잡았더니 실력이 확 늘었어요.”`,
        `"My English improved immensely \nafter I learned about my repeated \nmistakes through Ringle.`,
        's_home.review_title.seoyoung_title.desktop',
        `"在Ringle改正了自己常犯的英語失誤，\n实力明显提升。`,
        `"在Ringle改正了自己常犯的英語錯誤，\n實力明顯提升。`,
        `「Ringleで繰り返していたミスを\n直したら実力がグッと伸びました」`,
        `"Tiếng Anh của tôi đã tiến bộ rất nhiều\nkhi được sửa các lỗi lặp từ với Ringle.`,
      ],
      mobile: [
        `“링글로 반복되는 영어 실수를\n잡았더니 실력이 확 늘었어요.”`,
        `"My English improved immensely \nafter I learned about my repeated \nmistakes through Ringle.`,
        's_home.review_title.seoyoung_title.mobile',
        `"在Ringle改正了自己常犯的英語失誤，\n实力明显提升。`,
        `"在Ringle改正了自己常犯的英語錯誤，\n實力明顯提升。`,
        `「Ringleで繰り返していたミスを\n直したら実力がグッと伸びました」`,
        `"Tiếng Anh của tôi đã tiến bộ rất nhiều\nkhi được sửa các lỗi lặp từ với Ringle.`,
      ],
    },
    seoyoung_caption: {
      desktop: [
        `장서영님, 디지털 마케터`,
        `Seoyoung Jang, Digital Marketer`,
        's_home.review_title.seoyoung_caption.desktop',
        `张瑞英，数码营销`,
        `張瑞英，數位行銷`,
        `チャン・ソヨンさん、デジタルマーケター`,
        `Seoyoung Jang, Digital Marketer`,
      ],
      mobile: [
        `장서영님, 디지털 마케터`,
        `Seoyoung Jang, Digital Marketer`,
        's_home.review_title.seoyoung_caption.mobile',
        `张瑞英，数码营销`,
        `張瑞英，數位行銷`,
        `チャン・ソヨンさん、デジタルマーケター`,
        `Seoyoung Jang, Digital Marketer`,
      ],
    },
    hyunseok_title: {
      desktop: [
        `“링글을 통해 자신감이 생기고\n영어 공포증을 극복했어요.”`,
        `"I overcame my fear of English \nthrough Ringle and now feel \nmore confident speaking English.`,
        's_home.review_title.hyunseok_title.desktop',
        `"在Ringle产生了自信，\n克服了英语恐惧症。`,
        `"在Ringle產生了自信，\n克服了英文恐懼症。`,
        `「Ringleのおかげで自信がつき\n英語恐怖症を克服しました」`,
        `"Tôi đã trở nên tự tin \nvà vượt qua nỗi sợ tiếng Anh với Ringle.`,
      ],
      mobile: [
        `“링글을 통해 자신감이 생기고\n영어 공포증을 극복했어요.”`,
        `"I overcame my fear of English \nthrough Ringle and now feel \nmore confident speaking English.`,
        's_home.review_title.hyunseok_title.mobile',
        `"在Ringle产生了自信，\n克服了英语恐惧症。`,
        `"在Ringle產生了自信，\n克服了英文恐懼症。`,
        `「Ringleのおかげで自信がつき\n英語恐怖症を克服しました」`,
        `"Tôi đã trở nên tự tin \nvà vượt qua nỗi sợ tiếng Anh với Ringle.`,
      ],
    },
    hyunseok_caption: {
      desktop: [
        `정현석님, 소프트웨어 개발자`,
        `Hyunseok Jung, Software Developer`,
        's_home.review_title.hyunseok_caption.desktop',
        `郑贤硕，软件开发`,
        `鄭賢碩，軟體開發`,
        `チョン・ヒョンソクさん、ソフトウェア開発者`,
        `Hyunseok Jung, Nhà phát triển phần mềm`,
      ],
      mobile: [
        `정현석님, 소프트웨어 개발자`,
        `Hyunseok Jung, Software Developer`,
        's_home.review_title.hyunseok_caption.mobile',
        `郑贤硕，软件开发`,
        `鄭賢碩，軟體開發`,
        `チョン・ヒョンソクさん、ソフトウェア開発者`,
        `Hyunseok Jung, Nhà phát triển phần mềm`,
      ],
    },
    yoonjin_title: {
      desktop: [
        `“40대 워킹맘의 도전, 링글로\n영어 슬럼프없이 진행 중이에요.”`,
        `"Thanks to Ringle, \nI can study English consistently \neven as a working mom.`,
        's_home.review_title.yoonjin_title.desktop',
        `"40多岁的职场妈妈，\n用Ringle克服英语障碍。`,
        `"40多歲的職場媽媽，\n用Ringle克服英語障礙。`,
        `「40代働く母の挑戦、Ringleなら\nスランプに陥らず学べます」`,
        `Nhờ Ringle, tôi có thể học Tiếng anh đều đặn trong khi vừa đi làm vừa là nội trợ`,
      ],
      mobile: [
        `“40대 워킹맘의 도전, 링글로\n영어 슬럼프없이 진행 중이에요.”`,
        `"Thanks to Ringle, \nI can study English consistently \neven as a working mom.`,
        's_home.review_title.yoonjin_title.mobile',
        `"40多岁的职场妈妈，\n用Ringle克服英语障碍。`,
        `"40多歲的職場媽媽，\n用Ringle克服英語障礙。`,
        `「40代働く母の挑戦、Ringleなら\nスランプに陥らず学べます」`,
        `Nhờ Ringle, tôi có thể học Tiếng anh đều đặn trong khi vừa đi làm vừa là nội trợ`,
      ],
    },
    yoonjin_caption: {
      desktop: [
        `이윤진님, 프리랜서 마케터`,
        `Yoonjin Lee, Freelance Marketer`,
        's_home.review_title.yoonjin_caption.desktop',
        `李允真，自由职业营销人员`,
        `李允真，接案行銷人員`,
        `イ・ユンジンさん、フリーランサーマーケター`,
        `Yoonjin Lee, Freelance Marketer`,
      ],
      mobile: [
        `이윤진님, 프리랜서 마케터`,
        `Yoonjin Lee, Freelance Marketer`,
        's_home.review_title.yoonjin_caption.mobile',
        `李允真，自由职业营销人员`,
        `李允真，接案行銷人員`,
        `イ・ユンジンさん、フリーランサーマーケター`,
        `Yoonjin Lee, Freelance Marketer`,
      ],
    },
    yewon_title: {
      desktop: [
        `“외국인 팀원과 일할 때 필요한\n원어민의 늬앙스를 배워요.”`,
        `"I learn to better understand \nthe nuances when working \nwith international colleagues.`,
        's_home.review_title.yewon_title.desktop',
        `"学习与外国同事\n一起工作时需要的英语。`,
        `"學習與外國同事\n一起工作時需要的英文。`,
        `「外国人の同僚との会話で必要な\nネイティブ表現を学びました」`,
        `"Tôi học để hiểu rõ hơn \ncác sắc thái khi làm việc \nvới các đồng nghiệp quốc tế."`,
      ],
      mobile: [
        `“외국인 팀원과 일할 때 필요한\n원어민의 늬앙스를 배워요.”`,
        `"I learn to better understand \nthe nuances when working \nwith international colleagues.`,
        's_home.review_title.yewon_title.mobile',
        `"学习与外国同事\n一起工作时需要的英语。`,
        `"學習與外國同事\n一起工作時需要的英文。`,
        `「外国人の同僚との会話で必要な\nネイティブ表現を学びました」`,
        `"Tôi học để hiểu rõ hơn \ncác sắc thái khi làm việc \nvới các đồng nghiệp quốc tế."`,
      ],
    },
    yewon_caption: {
      desktop: [
        `최예원님, 제약회사 클리니컬 디렉터`,
        `Yewon Choi, Clinical Director`,
        's_home.review_title.yewon_caption.desktop',
        `崔艺媛，制药公司总监`,
        `崔藝媛，制藥公司總監`,
        `チェ・イェウォンさん、製薬会社クリニカルディレクター`,
        `Yewon Choi, Clinical Director`,
      ],
      mobile: [
        `최예원님, 제약회사 클리니컬 디렉터`,
        `Yewon Choi, Clinical Director`,
        's_home.review_title.yewon_caption.mobile',
        `崔艺媛，制药公司总监`,
        `崔藝媛，制藥公司總監`,
        `チェ・イェウォンさん、製薬会社クリニカルディレクター`,
        `Yewon Choi, Clinical Director`,
      ],
    },
  },
  pricing_title: {
    subtitle: {
      desktop: [`가격`, `Pricing`, 's_home.pricing_title.subtitle.desktop', `价格`, `價格`, `料金`, `Giá`],
      mobile: [`가격`, `Pricing`, 's_home.pricing_title.subtitle.mobile', `价格`, `價格`, `料金`, `Giá`],
    },
    title: {
      desktop: [
        `월 12만원으로 이루는\n글로벌을 향한 성장`,
        `Reach your potential with membership \nstarting at $99 per month`,
        's_home.pricing_title.title.desktop',
        `一个月美金99元，\n让自己成为国际人才`,
        `一個月美金99元，\n讓自己成為國際人才`,
        `月$99からの\nグローバルに向けた成長`,
        `Phát triển khả năng Tiếng Anh của bạn chỉ từ $99 mỗi tháng đăng ký thành viên`,
      ],
      mobile: [
        `월 11만원으로 이루는\n글로벌을 향한 성장`,
        `Reach your potential \nwith membership starting \nat $84 per month`,
        's_home.pricing_title.title.mobile',
        `一个月美金84元，\n让自己成为国际人才`,
        `一個月美金84元，\n讓自己成為國際人才`,
        `月$84からの\nグローバルに向けた成長`,
        `Phát triển khả năng Tiếng Anh của bạn chỉ từ $84 mỗi tháng đăng ký thành viên`,
      ],
    },
    caption: {
      desktop: [
        `*20분 4회 신규회원 할인 적용 시`,
        `*For four 20-minute lessons with newcomer discount`,
        's_home.pricing_title.caption.desktop',
        `*4次 20分钟課程折扣金額`,
        `*4次 20分鐘課程折扣金額`,
        `*20分4回新規会員割引適用の場合`,
        `Giảm giá gói học 4 buổi học 20 phút cho thành viên mới`,
      ],
      mobile: [
        `*20분 4회 신규회원 할인 적용 시`,
        `*For four 20-minute lessons with newcomer discount`,
        's_home.pricing_title.caption.mobile',
        `*4次 20分钟課程折扣金額`,
        `*4次 20分鐘課程折扣金額`,
        `*20分4回新規会員割引適用の場合`,
        `Giảm giá gói học 4 buổi học 20 phút cho thành viên mới`,
      ],
    },
    bodytxt: {
      desktop: [
        `자신의 상황과 영어 실력에 따라\n수업권 패키지를 선택하세요.`,
        `Choose a credit package that works for your English.`,
        's_home.pricing_title.bodytxt.desktop',
        `请根据自己的需求及規劃\n选择課程券`,
        `請根據自己的需求及規劃\n選擇課程券`,
        `状況と英語力に合わせて\nレッスンチケットのパッケージをお選びいただけます。`,
        `Chọn gói học phù hợp với bạn`,
      ],
      mobile: [
        `자신의 상황과 영어 실력에 따라
수업권 패키지를 선택하세요.`,
        `Choose a credit package \nthat works for your English.`,
        's_home.pricing_title.bodytxt.mobile',
        `请根据自己的需求及規劃\n选择課程券`,
        `請根據自己的需求及規劃\n選擇課程券`,
        `状況と英語力に合わせて\nレッスンチケットのパッケージをお選びいただけます。`,
        `Chọn gói học phù hợp với bạn`,
      ],
    },
    cta: {
      desktop: [
        `가격 더 알아보기`,
        `Find out more`,
        's_home.pricing_title.cta.desktop',
        `了解更多`,
        `了解更多`,
        `料金をもっと見る`,
        `Xem thêm`,
      ],
      mobile: [
        `가격 더 알아보기`,
        `Find out more`,
        's_home.pricing_title.cta.mobile',
        `了解更多`,
        `了解更多`,
        `料金をもっと見る`,
        `Xem thêm`,
      ],
    },
  },
  footer_button: {
    content: {
      desktop: [`교재`, `Content`, 's_home.footer_button.content.desktop', `教材`, `教材`, `教材`, `Tài liệu`],
      mobile: [`교재`, `Content`, 's_home.footer_button.content.mobile', `教材`, `教材`, `教材`, `Tài liệu`],
    },
    tutors: {
      desktop: [`튜터`, `Tutors`, 's_home.footer_button.tutors.desktop', `教师`, `教師`, `チューター`, `Gia sư`],
      mobile: [`튜터`, `Tutors`, 's_home.footer_button.tutors.mobile', `教师`, `教師`, `チューター`, `Gia sư`],
    },
    pricing: {
      desktop: [`가격`, `Pricing`, 's_home.footer_button.pricing.desktop', `价格`, `價格`, `料金`, `Học phí`],
      mobile: [`가격`, `Pricing`, 's_home.footer_button.pricing.mobile', `价格`, `價格`, `料金`, `Học phí`],
    },
    b2b: {
      desktop: [
        `기업제휴`,
        `B2B Partnerships`,
        's_home.footer_button.b2b.desktop',
        `企业合作`,
        `企業合作`,
        `業務提携`,
        `Hợp tác B2B`,
      ],
      mobile: [
        `기업제휴`,
        `B2B Partnerships`,
        's_home.footer_button.b2b.mobile',
        `企业合作`,
        `企業合作`,
        `業務提携`,
        `Hợp tác B2B`,
      ],
    },
    careers: {
      desktop: [
        `인재 채용`,
        `Careers`,
        's_home.footer_button.careers.desktop',
        `人才招募`,
        `人才招募`,
        `採用情報`,
        `Sự nghiệp`,
      ],
      mobile: [
        `인재 채용`,
        `Careers`,
        's_home.footer_button.careers.mobile',
        `人才招募`,
        `人才招募`,
        `採用情報`,
        `Sự nghiệp`,
      ],
    },
    team: {
      desktop: [
        `링글 팀`,
        `Ringle Team`,
        's_home.footer_button.team.desktop',
        `Ringle团队`,
        `Ringle團隊`,
        `Ringleチーム`,
        `Ringle Team`,
      ],
      mobile: [
        `링글 팀`,
        `Ringle Team`,
        's_home.footer_button.team.mobile',
        `Ringle团队`,
        `Ringle團隊`,
        `Ringleチーム`,
        `Ringle Team`,
      ],
    },
    blog: {
      desktop: [
        `링글 블로그`,
        `자세히 보기`,
        's_home.footer_button.blog.desktop',
        `Ringle部落格`,
        `Ringle部落格`,
        `Ringleブログ`,
        `Ringle Blog`,
      ],
      mobile: [
        `링글 블로그`,
        `Ringle Blog`,
        's_home.footer_button.blog.mobile',
        `Ringle部落格`,
        `Ringle部落格`,
        `Ringleブログ`,
        `Ringle Blog`,
      ],
    },
    faq: {
      desktop: [
        `자주 묻는 질문`,
        `FAQs`,
        's_home.footer_button.faq.desktop',
        `常见问题`,
        `常見問題`,
        `よくあるご質問`,
        `FAQs`,
      ],
      mobile: [
        `자주 묻는 질문`,
        `FAQs`,
        's_home.footer_button.faq.mobile',
        `常见问题`,
        `常見問題`,
        `よくあるご質問`,
        `FAQs`,
      ],
    },
    kakao: {
      desktop: [
        `카카오톡 문의`,
        `Inquire via KakaoTalk`,
        's_home.footer_button.kakao.desktop',
        `与我们联络`,
        `與我們聯絡`,
        `お問い合わせ`,
        `Để lại câu hỏi`,
      ],
      mobile: [
        `카카오톡 문의`,
        `Inquire via KakaoTalk`,
        's_home.footer_button.kakao.mobile',
        `与我们联络`,
        `與我們聯絡`,
        `お問い合わせ`,
        `Để lại câu hỏi`,
      ],
    },
  },
  footer_txt: {
    helpcenter: {
      desktop: [
        `고객센터`,
        `Help Center`,
        's_home.footer_txt.helpcenter.desktop',
        `客服中心`,
        `客服中心`,
        `ヘルプセンター`,
        `Trung tâm hỗ trợ`,
      ],
      mobile: [
        `고객센터`,
        `Help Center`,
        's_home.footer_txt.helpcenter.mobile',
        `客服中心`,
        `客服中心`,
        `ヘルプセンター`,
        `Trung tâm hỗ trợ`,
      ],
    },
    weekday: {
      desktop: [
        `평일 오전 10:00 - 오후 6:00 (KST)`,
        `Weekdays 10 AM- 6 PM KST`,
        's_home.footer_txt.weekday.desktop',
        `週一～週五 10 AM- 6 PM (KST)`,
        `週一～週五 10 AM- 6 PM (KST)`,
        `平日午前10:00 - 午後6:00(JST)`,
        `Các ngày trong tuần 12:00 - 20:00 (ICT)`,
      ],
      mobile: [
        `평일 오전 10:00 - 오후 6:00(KST)`,
        `Weekdays 10 AM- 6 PM KST`,
        's_home.footer_txt.weekday.mobile',
        `週一～週五 10 AM- 6 PM (KST)`,
        `週一～週五 10 AM- 6 PM (KST)`,
        `平日午前10:00 - 午後6:00(JST)`,
        `Các ngày trong tuần 12:00 - 20:00 (ICT)`,
      ],
    },
    break: {
      desktop: [
        `점심시간: 오후 1:00 - 오후 2:00 (KST)`,
        `Lunch Break 1PM - 2PM (KST)`,
        's_home.footer_txt.break.desktop',
        `午休 1 PM ～2 PM (KST)`,
        `午休 1PM ～ 2PM (KST)`,
        `昼休業：午後 1:00 - 午後2:00 (JST)`,
        `Nghỉ trưa 1PM ～ 2PM (KST)`,
      ],
      mobile: [
        `점심시간: 오후 1:00 - 오후 2:00(KST)`,
        `Lunch Break 1 - 2 PM KST`,
        's_home.footer_txt.break.mobile',
        `午休 1PM ～2PM (KST)`,
        `午休 1PM ～2PM (KST)`,
        `昼休業：午後 1:00 - 午後 2:00(JST)`,
        `Nghỉ trưa`,
      ],
    },
    companyname: {
      desktop: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.desktop',
        ``,
        ``,
        `ⓒRingle English Education Service`,
        `ⓒRingle English Education Service`,
      ],
      mobile: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.mobile',
        ``,
        ``,
        `ⓒRingle English Education Service`,
        `ⓒRingle English Education Service`,
      ],
    },
    address: {
      desktop: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
        's_home.footer_txt.address.desktop',
        ``,
        ``,
        `大韓民国ソウル特別市瑞草区瑞草路78ギル-22`,
        `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
      ],
      mobile: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu,/nSeoul, Republic of Korea`,
        's_home.footer_txt.address.mobile',
        ``,
        ``,
        `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
        `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
      ],
    },
    businessnumber: {
      desktop: [
        `사업자등록번호: 876-81-00110`,
        ``,
        's_home.footer_txt.businessnumber.desktop',
        ``,
        ``,
        `事業者登録番号 876-81-00110`,
        ``,
      ],
      mobile: [
        `사업자등록번호: 876-81-00110`,
        ``,
        's_home.footer_txt.businessnumber.mobile',
        ``,
        ``,
        `事業者登録番号 876-81-00110`,
        ``,
      ],
    },
    purchasenumber: {
      desktop: [
        `통신판매신고번호: 2019-서울강남-04831`,
        ``,
        's_home.footer_txt.purchasenumber.desktop',
        ``,
        ``,
        `通信販売申告番号 2019-ソウル江南-04831`,
        ``,
      ],
      mobile: [
        `통신판매신고번호: 2019-서울강남-04831`,
        ``,
        's_home.footer_txt.purchasenumber.mobile',
        ``,
        ``,
        `通信販売申告番号 2019-ソウル江南-04831`,
        ``,
      ],
    },
    phonenumber: {
      desktop: [
        `대표전화: 02-6204-8880`,
        ``,
        's_home.footer_txt.phonenumber.desktop',
        ``,
        ``,
        `代表番号 02-6204-8880`,
        ``,
      ],
      mobile: [
        `대표전화: 02-6204-8880`,
        ``,
        's_home.footer_txt.phonenumber.mobile',
        ``,
        ``,
        `代表番号 +82 02-6204-8880`,
        ``,
      ],
    },
    ceo: {
      desktop: [
        `대표이사: 이성파, 이승훈`,
        ``,
        's_home.footer_txt.ceo.desktop',
        ``,
        ``,
        `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ`,
        ``,
      ],
      mobile: [
        `대표이사: 이성파, 이승훈. 개인정보책임자: 이성파`,
        ``,
        's_home.footer_txt.ceo.mobile',
        ``,
        ``,
        `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ`,
        ``,
      ],
    },
    email: {
      desktop: [
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        's_home.footer_txt.email.desktop',
        ``,
        ``,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
      ],
      mobile: [
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        's_home.footer_txt.email.mobile',
        ``,
        ``,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
      ],
    },
    security_manager: {
      desktop: [
        `개인정보 보호책임자: 이성파`,
        `Privacy Management Officer: Sungpah Lee`,
        's_home.footer_txt.security_manager.desktop',
        `个人资料保护负责人：李圣播`,
        `個人資料保護負責人：李聖播`,
        `個人情報保護管理者：Lee Sungpah`,
        ``,
      ],
    },
    inquiry_security: {
      desktop: [
        `보안 관련 문의`,
        `Security-related inquiries`,
        's_home.footer_txt.inquiry_security.desktop',
        `关于资料安全问题`,
        `關於資料安全問題`,
        `資料保護について`,
        ``,
      ],
    },
    privacy_security: {
      desktop: [
        `개인정보보호문의`,
        `Privacy-related inquiries`,
        's_home.footer_txt.privacy_security.desktop',
        `关于个人资料保护问题`,
        `關於個人資料保護問題`,
        `個人情報保護について`,
        ``,
      ],
    },
  },
  faq: {
    title: {
      desktop: [
        `자주 묻는 질문`,
        `Frequently Asked Questions`,
        's_home.faq.title.desktop',
        `常见问题`,
        `常見問題`,
        `よくあるご質問`,
        `Các câu hỏi thường gặp`,
      ],
      mobile: [
        `자주 묻는 질문`,
        `Frequently Asked Questions`,
        's_home.faq.title.mobile',
        `常见问题`,
        `常見問題`,
        `よくあるご質問`,
        `Các câu hỏi thường gặp`,
      ],
    },
  },
  faq_1: {
    question: {
      desktop: [
        `영어 실력 초급인데, 수강할 수 있을까요?`,
        `Can I take Ringle lessons even if I'm a beginner?`,
        's_home.faq_1.question.desktop',
        `英文初级程度也适合用Ringle吗？`,
        `英文初級程度也適合用Ringle嗎？`,
        `英語初級者ですが、受講できますか？`,
        `Tiếng Anh của tôi ở trình độ sơ cấp, tôi có thể tham gia học không?`,
      ],
      mobile: [
        `영어 실력 초급인데, 수강할 수 있을까요?`,
        `Can I take Ringle lessons even if I'm a beginner?`,
        's_home.faq_1.question.mobile',
        `英文初级程度也适合用Ringle吗？`,
        `英文初級程度也適合用Ringle嗎？`,
        `英語初級者ですが、受講できますか？`,
        `Tiếng Anh của tôi ở trình độ sơ cấp, tôi có thể tham gia học không?`,
      ],
    },
    answer: {
      desktop: [
        `링글 수강생 분들의 50% 이상이 초/중급 레벨에 속하는 분으로 영어로 자기 소개를 할 수 있는 정도라면 충분히 수강할 수 있습니다. 특히 일상 교재를 활용하여 수업하면 도움이 될거에요.`,
        `More than 50% of our users are beginner/intermediate-level learners. If you could introduce yourself in English, Ringle will work for you! For beginners, we recommend using "Small Talk" materials for lessons.`,
        's_home.faq_1.answer.desktop',
        `Ringle一半以上的用户都是属于初/中级英文程度，只要能够用英文自我介绍就能上课，建议使用与日常生活主题相关的教材。`,
        `Ringle一半以上的用戶都是屬於初/中級英文程度，只要能夠用英文自我介紹就能上課，建議使用與日常生活主題相關的教材。`,
        `Ringle受講生の50％以上が初/中級レベルであり
英語で自己紹介ができる程度であれば問題ありません。
特に日常的な教材を活用してレッスンを受けることをおすすめします。`,
        `Hơn 50% người dùng của chúng tôi là người học ở trình độ mới bắt đầu/trung cấp. Chỉ cần bắt đầu với việc giới thiệu bản thân bằng tiếng Anh, Ringle sẽ giúp bạn tiến bộ! Đối với người mới bắt đầu, chúng tôi khuyên bạn nên sử dụng tài liệu "Small Talk" cho các bài học.`,
      ],
      mobile: [
        `링글 수강생 분들의 50% 이상이 초/중급 레벨에 속하는 분으로 영어로 자기 소개를 할 수 있는 정도라면 충분히 수강할 수 있습니다. 특히 일상 교재를 활용하여 수업하면 도움이 될거에요.`,
        `More than 50% of our users are beginner/intermediate-level learners. If you could introduce yourself in English, Ringle will work for you! For beginners, we recommend using "Small Talk" materials for lessons.`,
        's_home.faq_1.answer.mobile',
        `Ringle一半以上的用户都是属于初/中级英文程度，只要能够用英文自我介绍就能上课，建议使用与日常生活主题相关的教材。`,
        `Ringle一半以上的用戶都是屬於初/中級英文程度，只要能夠用英文自我介紹就能上課，建議使用與日常生活主題相關的教材。`,
        `Ringle受講生の50％以上が初/中級レベルであり
英語で自己紹介ができる程度であれば問題ありません。
特に日常的な教材を活用してレッスンを受けることをおすすめします。`,
        `Hơn 50% người dùng của chúng tôi là người học ở trình độ mới bắt đầu/trung cấp. Chỉ cần bắt đầu với việc giới thiệu bản thân bằng tiếng Anh, Ringle sẽ giúp bạn tiến bộ! Đối với người mới bắt đầu, chúng tôi khuyên bạn nên sử dụng tài liệu "Small Talk" cho các bài học.`,
      ],
    },
    cta: {
      desktop: [
        `초급자를 위한 활용법을 더 알아보시려면`,
        `More tips for beginner-level learners`,
        's_home.faq_1.cta.desktop',
        `如果想了解更多初学者们的使用方法的话`,
        `如果想了解更多初學者們的使用方法的話`,
        `初級者のための活用法についてもっと知りたいなら`,
        `Tìm hiểu thêm cách sử dụng cho người mới bắt đầu học`,
      ],
      mobile: [
        `초급자를 위한 활용법을 더 알아보시려면`,
        `More tips for beginner-level learners`,
        's_home.faq_1.cta.mobile',
        `如果想了解更多初学者们的使用方法的话`,
        `如果想了解更多初學者們的使用方法的話`,
        `初級者のための活用法についてもっと知りたいなら`,
        `Tìm hiểu thêm cách sử dụng cho người mới bắt đầu học`,
      ],
    },
  },
  faq_2: {
    question: {
      desktop: [
        `링글 1:1 영어 수업은 학원과 어떻게 다른지 궁금해요.`,
        `How does Ringle's 1:1 lessons differ from taking a class at a private academy?`,
        's_home.faq_2.question.desktop',
        `我想知道Ringle1:1英语和补习班有什么不同。`,
        `我想知道Ringle1:1英文和補習班有什麽不同。`,
        `Ringle　1on1英語レッスンは塾での授業とどう違うのですか？`,
        `Các bài học 1:1 của Ringle khác với việc tham gia một lớp học tại học viện tư nhân như thế nào?`,
      ],
      mobile: [
        `링글 1:1 영어 수업은 학원과 어떻게 다른지 궁금해요.`,
        `How does Ringle's 1:1 lessons differ from taking a class at a private academy?`,
        's_home.faq_2.question.mobile',
        `我想知道Ringle1:1英语和补习班有什么不同。`,
        `我想知道Ringle1:1英文和補習班有什麽不同。`,
        `Ringle1on1英語レッスンは塾での授業とどう違うですか？`,
        `Các bài học 1:1 của Ringle khác với việc tham gia một lớp học tại học viện tư nhân như thế nào?`,
      ],
    },
    answer: {
      desktop: [
        `학생 한 명에게만 집중하는 1:1 수업으로 원하는 시간, 튜터, 교재를 자유롭게 선택할 수 있습니다. 링글의 엄선된 명문대 원어민 튜터, 직접 만드는 교재, 효과적인 학습을 위한 시스템은 공부만 하면 되는 환경을 제공합니다.`,
        `At Ringle, you can learn from tutors on a one-on-one basis and freely choose when, from whom, and what you wish to study. Our carefully-selected tutors from top universities, curated lesson materials, and effective learning system provide an environment where you can purely concentrate on learning.`,
        's_home.faq_2.answer.desktop',
        `1:1课程,學生自由选择想要的时间、教師和教材。 Ringle严选顶尖教师，提供亲自編寫的教材以及高效率的学习系统。`,
        `1:1課程,學生自由選擇想要的時間、教師和教材。 Ringle嚴選的頂尖教師，提供親自編寫的教材以及高效率的學習系統。`,
        `受講生一人だけに集中する1on1レッスンは、ご希望の時間、
チューター、教材を自由にお選びいただけます。
Ringleが厳選した名門大学ネイティブチューター、オリジナル教材、
効果的な学習のためのシステムにより勉強に集中できる環境を提供します。`,
        `Tại Ringle, bạn có thể học trực tiếp với các gia sư và tự do lựa chọn thời gian, gia sư và nội dung bạn muốn học. Các gia sư được lựa chọn cẩn thận của chúng tôi từ các trường đại học hàng đầu, tài liệu bài học được tuyển chọn và hệ thống học tập hiệu quả mang đến một môi trường mà bạn có thể hoàn toàn tập trung vào việc học.`,
      ],
      mobile: [
        `학생 한 명에게만 집중하는 1:1 수업으로 원하는 시간, 튜터, 교재를 자유롭게 선택할 수 있습니다. 링글의 엄선된 명문대 원어민 튜터, 직접 만드는 교재, 효과적인 학습을 위한 시스템은 공부만 하면 되는 환경을 제공합니다.`,
        `At Ringle, you can learn from tutors on a one-on-one basis and freely choose when, from whom, and what you wish to study. Our carefully-selected tutors from top universities, curated lesson materials, and effective learning system provide an environment where you can purely concentrate on learning.`,
        's_home.faq_2.answer.mobile',
        `1:1课程,學生自由选择想要的时间、教師和教材。 Ringle严选顶尖教师，提供亲自編寫的教材以及高效率的学习系统。`,
        `1:1課程,學生自由選擇想要的時間、教師和教材。 Ringle嚴選的頂尖教師，提供親自編寫的教材以及高效率的學習系統。`,
        `受講生一人だけに集中する1on1レッスンは、ご希望の時間、
チューター、教材を自由にお選びいただけます。
Ringleが厳選した名門大学ネイティブチューター、オリジナル教材、
効果的な学習のためのシステムにより勉強に集中できる環境を提供します。`,
        `Tại Ringle, bạn có thể học trực tiếp với các gia sư và tự do lựa chọn thời gian, gia sư và nội dung bạn muốn học. Các gia sư được lựa chọn cẩn thận của chúng tôi từ các trường đại học hàng đầu, tài liệu bài học được tuyển chọn và hệ thống học tập hiệu quả mang đến một môi trường mà bạn có thể hoàn toàn tập trung vào việc học.`,
      ],
    },
    cta: {
      desktop: [
        `차별화된 튜터와 시스템을 더 알아보려면`,
        `Learn more about Ringle's unique tutors and system`,
        's_home.faq_2.cta.desktop',
        `更了解我們的教師和系統`,
        `更了解我們的教師和系統`,
        `チューターやシステムについてもっと知りたいなら`,
        `Tìm hiểu thêm về các gia sư và hệ thống độc quyền`,
      ],
      mobile: [
        `차별화된 튜터와 시스템을 더 알아보려면`,
        `Learn more about Ringle's unique tutors and system`,
        's_home.faq_2.cta.mobile',
        `更了解我們的教師和系統`,
        `更了解我們的教師和系統`,
        `チューターやシステムについてもっと知りたいなら`,
        `Tìm hiểu thêm về các gia sư và hệ thống độc quyền`,
      ],
    },
  },
  faq_3: {
    question: {
      desktop: [
        `커리큘럼은 어떻게 되나요?`,
        `What is the curriculum like?`,
        's_home.faq_3.question.desktop',
        `Ringle的课程是怎麽安排的呢？`,
        `Ringle的課程是怎麼安排的呢？`,
        `カリキュラムについて知りたいです。`,
        `Chương trình giảng dạy như thế nào?`,
      ],
      mobile: [
        `커리큘럼은 어떻게 되나요?`,
        `What is the curriculum like?`,
        's_home.faq_3.question.mobile',
        `Ringle的课程是怎麽安排的呢？`,
        `Ringle的課程是怎麼安排的呢？`,
        `カリキュラムについて知りたいです。`,
        `Chương trình giảng dạy như thế nào?`,
      ],
    },
    answer: {
      desktop: [
        `링글은 학생이 직접 커리큘럼을 구성합니다. 다양한 튜터와 교재를 자유롭게 선택하고 수업방식도 필요에 따라 변경할 수 있어요. 학생이 직접 자료를 업로드 할 수도 있고 자유주제를 선택하여 인터뷰나 회화 연습도 가능합니다.`,
        `Ringle users create their own curriculum by choosing from a wide selection of tutors and materials and adjusting teaching style as needed. You can also upload your own material for the lesson or choose "Free Topic" to focus on practicing interviews or conversational English.`,
        's_home.faq_3.answer.desktop',
        `Ringle由学生自己安排课程，自由选择教材，授课方式也可以依照需求设定。 学生可以直接上传资料,也可以选择自由主题进行面试或会话练习。`,
        `Ringle由學生自己安排課程，自由選擇教材，授課方式也可以依照需求設定。 學生可以直接上傳資料,也可以選擇自由主題進行面試或會話練習。`,
        `Ringleは受講生自らカリキュラムを構成します。
チューターや教材を自由に選択し、レッスン方法も必要に
応じて変更できます。受講生が直接資料をアップロードすることもでき
自由にインタビューや会話の練習が可能です。`,
        `Học viên Ringle có thể tạo chương trình học cho riêng mình bằng cách lựa chọn gia sư và tài liệu cũng như điều chỉnh phong cách giảng dạy khi cần thiết. Bạn cũng có thể tải lên tài liệu của riêng mình cho bài học hoặc chọn "Chủ đề tự do" để tập trung vào thực hành phỏng vấn hoặc đàm thoại tiếng Anh.`,
      ],
      mobile: [
        `링글은 학생이 직접 커리큘럼을 구성합니다. 다양한 튜터와 교재를 자유롭게 선택하고 수업방식도 필요에 따라 변경할 수 있어요. 학생이 직접 자료를 업로드 할 수도 있고 자유주제를 선택하여 인터뷰나 회화 연습도 가능합니다.`,
        `Ringle users create their own curriculum by choosing from a wide selection of tutors and materials and adjusting teaching style as needed. You can also upload your own material for the lesson or choose "Free Topic" to focus on practicing interviews or conversational English.`,
        's_home.faq_3.answer.mobile',
        `Ringle由学生自己安排课程，自由选择教材，授课方式也可以依照需求设定。 学生可以直接上传资料,也可以选择自由主题进行面试或会话练习。`,
        `Ringle由學生自己安排課程，自由選擇教材，授課方式也可以依照需求設定。 學生可以直接上傳資料,也可以選擇自由主題進行面試或會話練習。`,
        `Ringleは受講生自らカリキュラムを構成します。
チューターや教材を自由に選択し、レッスン方法も必要に
応じて変更できます。受講生が直接資料をアップロードすることもでき
自由にインタビューや会話の練習が可能です。`,
        `Học viên Ringle có thể tạo chương trình học cho riêng mình bằng cách lựa chọn gia sư và tài liệu cũng như điều chỉnh phong cách giảng dạy khi cần thiết. Bạn cũng có thể tải lên tài liệu của riêng mình cho bài học hoặc chọn "Chủ đề tự do" để tập trung vào thực hành phỏng vấn hoặc đàm thoại tiếng Anh.`,
      ],
    },
  },
  faq_4: {
    question: {
      desktop: [
        `링글 튜터의 강점은 무엇인지 궁금해요.`,
        `What's special about Ringle tutors?`,
        's_home.faq_4.question.desktop',
        `我想知道Ringle教师的优势是什麽`,
        `我想知道Ringle教師的優勢是什麼`,
        `Ringleチューターの強みを知りたいです。`,
        `Điểm đặc biệt của gia sư Ringle là gì?`,
      ],
      mobile: [
        `링글 튜터의 강점은 무엇인지 궁금해요.`,
        `What's special about Ringle tutors?`,
        's_home.faq_4.question.mobile',
        `我想知道Ringle教师的优势是什麽`,
        `我想知道Ringle教師的優勢是什麼`,
        `Ringleチューターの強みを知りたいです。`,
        `Điểm đặc biệt của gia sư Ringle là gì?`,
      ],
    },
    answer: {
      desktop: [
        `튜터들이 미국/영국 명문 대학에서 토론과 글쓰기 훈련을 받아 온 만큼 격식에 맞는 영어를 하실 수 있도록 수업을 진행합니다. 양질의 수업을 위하여 링글은 튜터를 4차례에 걸쳐 엄격하게 선발하고 관리합니다.`,
        `Ringle tutors are trained in holding discussions and writing at the US and UK's top universities. So, they are well-equipped to teach you how to communicate effectively in any situation. Ringle further ensures the quality of tutors through a four-stage screening process.`,
        's_home.faq_4.answer.desktop',
        `教师们在美国/英国顶尖大学接受讨论和写作训练,拥有高水平的英语实力协助学生。 为了提供优质的课程,Ringle严格选拔教师。`,
        `教師們在美國/英國頂尖大學接受討論和寫作訓練,擁有高水準的英語能力協助學生。 為了提供優質的課程,Ringle嚴格選拔教師。`,
        `チューターはアメリカ/イギリスの名門大学で討論や作文訓練を
受けており、フォーマルな英会話ができるようレッスンを行います。
Ringleでは良質なレッスンのためにチューターを4回に渡って選抜し管理しています。`,
        `Các gia sư Ringle được đào tạo về tổ chức thảo luận và viết tại các trường đại học hàng đầu của Hoa Kỳ và Vương quốc Anh. Vì vậy, các gia sư được trang bị đầy đủ để dạy bạn cách giao tiếp hiệu quả trong mọi tình huống. Ringle sẽ đảm bảo chất lượng của gia sư thông qua 4 vòng sàng lọc hồ sơ.`,
      ],
      mobile: [
        `튜터들이 미국/영국 명문 대학에서 토론과 글쓰기 훈련을 받아 온 만큼 격식에 맞는 영어를 하실 수 있도록 수업을 진행합니다. 양질의 수업을 위하여 링글은 튜터를 4차례에 걸쳐 엄격하게 선발하고 관리합니다.`,
        `Ringle tutors are trained in holding discussions and writing at the US and UK's top universities. So, they are well-equipped to teach you how to communicate effectively in any situation. Ringle further ensures the quality of tutors through a four-stage screening process.`,
        's_home.faq_4.answer.mobile',
        `教师们在美国/英国顶尖大学接受讨论和写作训练,拥有高水平的英语实力协助学生。 为了提供优质的课程,Ringle严格选拔教师。`,
        `教師們在美國/英國頂尖大學接受討論和寫作訓練,擁有高水準的英語能力協助學生。 為了提供優質的課程,Ringle嚴格選拔教師。`,
        `チューターはアメリカ/イギリスの名門大学で討論や作文訓練を
受けており、フォーマルな英会話ができるようレッスンを行います。
Ringleでは良質なレッスンのためにチューターを4回に渡って選抜し管理しています。`,
        `Các gia sư Ringle được đào tạo về tổ chức thảo luận và viết tại các trường đại học hàng đầu của Hoa Kỳ và Vương quốc Anh. Vì vậy, các gia sư được trang bị đầy đủ để dạy bạn cách giao tiếp hiệu quả trong mọi tình huống. Ringle sẽ đảm bảo chất lượng của gia sư thông qua 4 vòng sàng lọc hồ sơ.`,
      ],
    },
  },
  faq_5: {
    question: {
      desktop: [
        `어느 정도 공부를 해야 영어 실력이 향상될지 고민이에요.`,
        `I don't know how much I need to study to improve my English.`,
        's_home.faq_5.question.desktop',
        `对于要多努力学习英文才能提升能力觉得很苦恼`,
        `對於要多努力學習英文才能提升能力覺得很苦惱`,
        `どれくらい勉強すれば英語力がアップするのかと悩んでいます。`,
        `Tôi không biết mình cần phải học bao nhiêu để cải thiện tiếng Anh của mình.`,
      ],
      mobile: [
        `어느 정도 공부를 해야 영어 실력이 향상될지 고민이에요.`,
        `I don't know how much I need to study \nto improve my English.`,
        's_home.faq_5.question.mobile',
        `对于要多努力学习英文才能提升能力觉得很苦恼`,
        `對於要多努力學習英文才能提升能力覺得很苦惱`,
        `どれくらい勉強すれば英語力がアップするのかと悩んでいます。`,
        `Tôi không biết mình cần phải học bao nhiêu để cải thiện tiếng Anh của mình.`,
      ],
    },
    answer: {
      desktop: [
        `링글에서 영어 실력이 향상한 고객분들은 보통 2주에 최소 3회씩 수업합니다. 꾸준히 수업을 듣고 링글의 다양한 복습 자료를 통해 매 수업을 내 것으로 만든다면 영어 실력을 더욱 향상시킬 수 있습니다.`,
        `We find that Ringle users who become better at English take at least three lessons in two weeks (6 lessons/month). In addition to taking lessons regularly, your English will improve even more if you make what you learned your own by reviewing the post-lesson notes and feedback we provide.`,
        's_home.faq_5.answer.desktop',
        `在Ringle提升英文能力的学员一般两週最少上三次课，通过持续上课以及利用Ringle提供的複习内容，将学习内容确实吸收，英文实力才得以提升。`,
        `在Ringle提升英文能力的學員一般兩週最少上三次課，透過持續上課以及利用Ringle提供的複習內容，將學習內容確實吸收，英文實力才得以提升。`,
        `Ringleで英語力がアップした方は普通2週間に最低3回ずつレッスンを行います。
継続してレッスンを受け、Ringleの様々な復習資料を通して毎レッスンを
自分のものにすることができれば、英語力はもっと向上するでしょう。`,
        `Chúng tôi nhận thấy rằng những người dùng Ringle trở nên giỏi tiếng Anh hơn sẽ học ít nhất ba bài học trong hai tuần (6 bài học/tháng). Ngoài việc tham gia các bài học thường xuyên, tiếng Anh của bạn sẽ tiến bộ hơn nữa nếu bạn biến những gì bạn học được thành của riêng mình bằng cách xem lại các ghi chú sau bài học và phản hồi mà chúng tôi cung cấp.`,
      ],
      mobile: [
        `링글에서 영어 실력이 향상한 고객분들은 보통 2주에 최소 3회씩 수업합니다. 꾸준히 수업을 듣고 링글의 다양한 복습 자료를 통해 매 수업을 내 것으로 만든다면 영어 실력을 더욱 향상시킬 수 있습니다.`,
        `We find that Ringle users who become better at English take at least three lessons in two weeks (6 lessons/month). In addition to taking lessons regularly, your English will improve even more if you make what you learned your own by reviewing the post-lesson notes and feedback we provide.`,
        's_home.faq_5.answer.mobile',
        `在Ringle提升英文能力的学员一般两週最少上三次课，通过持续上课以及利用Ringle提供的複习内容，将学习内容确实吸收，英文实力才得以提升。`,
        `在Ringle提升英文能力的學員一般兩週最少上三次課，透過持續上課以及利用Ringle提供的複習內容，將學習內容確實吸收，英文實力才得以提升。`,
        `Ringleで英語力がアップした方は普通2週間に最低3回ずつレッスンを行います。
継続してレッスンを受け、Ringleの様々な復習資料を通して毎レッスンを
自分のものにすることができれば、英語力はもっと向上するでしょう。`,
        `Chúng tôi nhận thấy rằng những người dùng Ringle trở nên giỏi tiếng Anh hơn sẽ học ít nhất ba bài học trong hai tuần (6 bài học/tháng). Ngoài việc tham gia các bài học thường xuyên, tiếng Anh của bạn sẽ tiến bộ hơn nữa nếu bạn biến những gì bạn học được thành của riêng mình bằng cách xem lại các ghi chú sau bài học và phản hồi mà chúng tôi cung cấp.`,
      ],
    },
  },
  faq_6: {
    question: {
      desktop: [
        `영문서 교정이 궁금해요.`,
        `I need someone to read over what I wrote in English.`,
        's_home.faq_6.question.desktop',
        `我需要有人帮我修改我的英文写作`,
        `我需要有人幫我修改我的英文寫作`,
        `英文書の校正について知りたいです。`,
        `Tôi cần chỉnh sửa bài luận.`,
      ],
      mobile: [
        `영문서 교정이 궁금해요.`,
        `I need someone to read over what I wrote in English.`,
        's_home.faq_6.question.mobile',
        `我需要有人帮我修改我的英文写作`,
        `我需要有人幫我修改我的英文寫作`,
        `英文書の校正について知りたいです。`,
        `Tôi cần chỉnh sửa bài luận.`,
      ],
    },
    answer: {
      desktop: [
        `영문서 교정 서비스를 이용하여 링글 에디터에게 교정을 받을 수도 있고 1:1 수업을 통해 튜터와 직접 이야기하며 고칠 수도 있습니다. 특히 레쥬메는 1:1 수업으로 교정하시기를 추천 드립니다.`,
        `You could use Ringle's proofreading service to have your document reviewed by a Ringle editor or take a lesson and go over the document together with a tutor. For resumes, we especially recommend the latter method.`,
        's_home.faq_6.answer.desktop',
        `利用英文写作修改课程和教师进行1:1文章修改，特别推荐履历修改课程。`,
        `利用英文寫作修改課程和教師進行1:1文章修改，特別推薦履歷修改課程。`,
        `英文書校正サービスではRingleエディターの校正を受けることができ、
1on1レッスンにてチューターと直接会話しながらの校正も可能です。
特にレジュメは1on1レッスンにて校正を受けることをおすすめします。`,
        `Bạn có thể sử dụng dịch vụ Proofreading để Ringle editors giúp bạn chỉnh sửa hoặc bạn có thể tham gia một tiết học để chỉnh sửa trực tiếp cùng với gia sư. Đối với sửa CV, chúng tôi đặc biệt khuyên dùng phương pháp sửa trực tiếp cùng gia sư.`,
      ],
      mobile: [
        `영문서 교정 서비스를 이용하여 링글 에디터에게 교정을 받을 수도 있고 1:1 수업을 통해 튜터와 직접 이야기하며 고칠 수도 있습니다. 특히 레쥬메는 1:1 수업으로 교정하시기를 추천 드립니다.`,
        `You could use Ringle's proofreading service to have your document reviewed by a Ringle editor or take a lesson and go over the document together with a tutor. For resumes, we especially recommend the latter method.`,
        's_home.faq_6.answer.mobile',
        `利用英文写作修改课程和教师进行1:1文章修改，特别推荐履历修改课程。`,
        `利用英文寫作修改課程和教師進行1:1文章修改，特別推薦履歷修改課程。`,
        `英文書校正サービスではRingleエディターの校正を受けることができ、
1on1レッスンにてチューターと直接会話しながらの校正も可能です。
特にレジュメは1on1レッスンにて校正を受けることをおすすめします。`,
        `Bạn có thể sử dụng dịch vụ Proofreading để Ringle editors giúp bạn chỉnh sửa hoặc bạn có thể tham gia một tiết học để chỉnh sửa trực tiếp cùng với gia sư. Đối với sửa CV, chúng tôi đặc biệt khuyên dùng phương pháp sửa trực tiếp cùng gia sư.`,
      ],
    },
    cta: {
      desktop: [
        `영문서 교정 받는 법 더 알아보려면`,
        `Learn more about Ringle's proofreading service`,
        's_home.faq_6.cta.desktop',
        `了解更多英文文章修改服务`,
        `了解更多英文文章修改服務`,
        `英文書の校正についてもっと知りたいなら`,
        `Tìm hiểu thêm về dịch vụ proofreading tại Ringle`,
      ],
      mobile: [
        `영문서 교정 받는 법 더 알아보려면`,
        `Learn more about Ringle's proofreading service`,
        's_home.faq_6.cta.mobile',
        `了解更多英文文章修改服务`,
        `了解更多英文文章修改服務`,
        `英文書の校正についてもっと知りたいなら`,
        `Tìm hiểu thêm về dịch vụ proofreading tại Ringle`,
      ],
    },
  },
  tutor_page: {
    title: {
      desktop: [
        `다양한 전공과 관심사를 가진 \n명문대 원어민 튜터`,
        `Meet intelligent tutors,\ntrained in various subjects\nhave diverse interests`,
        's_home.tutor_page.title.desktop',
        `拥有多种专业和兴趣的\n名牌大学外教`,
        `網羅各種主修和興趣的\n頂尖大學教師`,
        `様々な分野に対応できる\n名門大学ネイティブチューター`,
        `Gặp gỡ gia sư ưu tú, được đào tạo trên nhiều lĩnh vực đa dạng.`,
      ],
      mobile: [
        `다양한 전공과 관심사를 \n가진 명문대 원어민 튜터`,
        `Meet intelligent tutors,\ntrained in various subjects\nhave diverse interests`,
        's_home.tutor_page.title.mobile',
        `拥有多种专业和兴趣的\n名牌大学外教`,
        `網羅各種主修和興趣的\n頂尖大學教師`,
        `様々な分野に対応できる名門大学ネイティブチューター`,
        `Gặp gỡ gia sư ưu tú, được đào tạo trên nhiều lĩnh vực đa dạng.`,
      ],
    },
    cta: {
      desktop: [
        `체험수업으로 튜터 직접 만나기`,
        `Meet our tutor with a trial lesson`,
        's_home.tutor_page.cta.desktop',
        `马上和教师见面`,
        `馬上和教師見面`,
        `無料でチューターを見る`,
        `Gặp gỡ gia sư của chúng tôi`,
      ],
      mobile: [
        `체험 수업으로 튜터 직접 만나보기`,
        `Meet our tutor with a trial lesson`,
        's_home.tutor_page.cta.mobile',
        `马上和教师见面`,
        `馬上和教師見面`,
        `無料でチューターを見る`,
        `Gặp gỡ gia sư của chúng tôi`,
      ],
    },
    subtitle: {
      desktop: [
        `당신에게 꼭 맞는 튜터를 찾아보세요.`,
        `Find the perfect tutor \n for you`,
        's_home.tutor_page.subtitle.desktop',
        ``,
        ``,
        `あなたにぴったりのチューターを見つけよう`,
        `Tìm kiếm gia sư hoàn hảo dành riêng cho bạn`,
      ],
      mobile: [
        `당신에게 꼭 맞는 \n 튜터를 찾아보세요.`,
        `Find the perfect tutor \n for you`,
        's_home.tutor_page.subtitle.mobile',
        ``,
        ``,
        `あなたにぴったりの\nチューターを見つけよう`,
        `Tìm kiếm gia sư hoàn hảo dành riêng cho bạn`,
      ],
    },
    subtitle2: {
      desktop: [
        <>
          어떤 튜터가 잘 맞을지 고민되나요? <br /> 
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            링글팀에 알려주세요.
          </a>{' '}
          딱 맞는 튜터를 찾아드립니다.{' '}
        </>,
        <>
          Unsure which tutor suits you?
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            Tell Ringle
          </a>
          , we'll find your match.
        </>,
        's_home.tutor_page.subtitle2.desktop',
        <>
          不确定哪位导师适合您？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            让 Lingle 团队知道。
          </a>
          我们会为你找到最合适的导师。
        </>,
        <>
          不确定哪位导师适合您？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            让 Lingle 团队知道。
          </a>
          我们会为你找到最合适的导师。
        </>,
        <>
          どのチューターが合うか悩んでいますか？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            {' '}
            リングルチームにご連絡ください。
          </a>
          <br /> あなたにぴったりのチューターをご紹介します。
        </>,
        <>
          Unsure which tutor suits you?
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            Tell Ringle
          </a>
          , we'll find your match.
        </>,
      ],
      mobile: [
        <>
          어떤 튜터가 잘 맞을지 고민되나요?
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            링글팀에 알려주세요.
          </a>
          <br />딱 맞는 튜터를 찾아드립니다.{' '}
        </>,
        <>
          Unsure which tutor suits you?
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            Tell Ringle,
          </a>{' '}
          we'll find your match.
        </>,
        's_home.tutor_page.subtitle2.mobile',
        <>
          不确定哪位导师适合您？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            让 Lingle 团队知道。
          </a>
          <br /> 我们会为你找到最合适的导师。
        </>,
        <>
          不确定哪位导师适合您？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            {' '}
            让 Lingle 团队知道。
          </a>
          <br /> 我们会为你找到最合适的导师。
        </>,
        <>
          どのチューターが合うか悩んでいますか？
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            {' '}
            リングルチームにご連絡ください。
          </a>
          <br /> あなたにぴったりのチューターをご紹介します。
        </>,
        <>
          Unsure which tutor suits you?
          <br />
          <a href="http://ringleplus.channel.io/" target="_blank" className="text-purple-500">
            Tell Ringle,
          </a>{' '}
          we'll find your match.
        </>,
      ],
    },
  },
  content_page: {
    cta: {
      desktop: [
        `체험수업으로 교재 직접 보기`,
        `Browse our contents with a trial lesson`,
        's_home.content_page.cta.desktop',
        `看更多教材`,
        `看更多教材`,
        `今すぐ教材を見る`,
        `Đọc trước tài liệu`,
      ],
      mobile: [
        `체험 수업으로 교재 직접 확인하기`,
        `Browse our contents with a trial lesson`,
        's_home.content_page.cta.mobile',
        `看更多教材`,
        `看更多教材`,
        `今すぐ教材を見る`,
        `Đọc trước tài liệu`,
      ],
    },
    subtitle: {
      desktop: [
        `당신이 말하고 싶은 주제를 찾아보세요.`,
        `Talk about what you care about`,
        's_home.content_page.subtitle.desktop',
        ``,
        ``,
        `話したいテーマを見つけよう`,
        `Thảo luận chủ đề bạn yêu thích`,
      ],
      mobile: [
        `당신이 말하고 싶은 \n 주제를 찾아보세요.`,
        `Talk about what you care about`,
        's_home.content_page.subtitle.mobile',
        ``,
        ``,
        `話したいテーマを\n見つけよう`,
        `Thảo luận chủ đề bạn yêu thích`,
      ],
    },
  },
  business_faq_1: {
    question: [`tbd`, ``, 's_home.business_faq_1.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_1.answer', ``, ``, ``, ``],
  },
  business_faq_2: {
    question: [`tbd`, ``, 's_home.business_faq_2.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_2.answer', ``, ``, ``, ``],
  },
  business_faq_3: {
    question: [`tbd`, ``, 's_home.business_faq_3.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_3.answer', ``, ``, ``, ``],
  },
  business_faq_4: {
    question: [`tbd`, ``, 's_home.business_faq_4.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_4.answer', ``, ``, ``, ``],
  },
  business_faq_5: {
    question: [`tbd`, ``, 's_home.business_faq_5.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_5.answer', ``, ``, ``, ``],
  },
  business_faq_6: {
    question: [`tbd`, ``, 's_home.business_faq_6.question', ``, ``, ``, ``],
    answer: [`tbd`, ``, 's_home.business_faq_6.answer', ``, ``, ``, ``],
  },
  b2b: {
    title: {
      desktop: [
        `이미 많은 고객분들이 링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed countless dreams to come true`,
        's_home.b2b.title.desktop',
        ``,
        ``,
        `既に多くのお客様がRingleと共に\n夢を現実に変えています。`,
        `Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ`,
      ],
      mobile: [
        `이미 많은 고객분들이\n링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed\ncountless dreams to come true`,
        's_home.b2b.title.mobile',
        ``,
        ``,
        `既に多くのお客様が\nRingleと共に\n夢を現実に変えています。`,
        `Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ`,
      ],
    },
  },
  business: {
    title: {
      desktop: [
        `200개 이상 기업의 임직원들이 \n링글과 함께 꿈을 현실로 만들고 있습니다.`,
        `Employees of over 200 firms are \nrealizing their dreams through Ringle.`,
        's_home.business.title.desktop',
        `200多家企业的员工与Ringle一起将梦想变成现实。`,
        `200多家企業的員工與Ringle一起將夢想變成現實。`,
        `200以上の企業の皆様がRingleと共に夢を現実に変えています。`,
        `Nhân viên của hơn 200 công ty đang biến giấc mơ thành hiện thực với Ringle.`,
      ],
      mobile: [
        `200개 이상 기업의 임직원들이 링글과 함께 꿈을 현실로 만들고 있습니다.`,
        `Employees of over 200 firms are realizing their dreams through Ringle.`,
        's_home.business.title.mobile',
        `200多家企业的员工与Ringle一起将梦想变成现实。`,
        `200多家企業的員工與Ringle一起將夢想變成現實。`,
        `200以上の企業の皆様がRingleと共に夢を現実に変えています。`,
        `Nhân viên của hơn 200 công ty đang biến giấc mơ thành hiện thực với Ringle.`,
      ],
    },
    subtitle: {
      desktop: [
        `국내외 스타트업부터 외국계, 대기업까지`,
        `From startups to global corporations`,
        's_home.business.subtitle.desktop',
        `从国内外初创企业到外企、大企业`,
        `從國內外初創企業到外企、大企業`,
        `国内外スタートアップから外資系、大手企業まで`,
        `Từ các khởi nghiệp trong và ngoài nước đến các tập đoàn lớn nước ngoài`,
      ],
      mobile: [
        `국내외 스타트업부터 외국계, 대기업까지`,
        `From startups to global corporations`,
        's_home.business.subtitle.mobile',
        `从国内外初创企业到外企、大企业`,
        `從國內外初創企業到外企、大企業`,
        `国内外スタートアップから外資系、大手企業まで`,
        `Từ các khởi nghiệp trong và ngoài nước đến các tập đoàn lớn nước ngoài`,
      ],
    },
    cta: {
      desktop: [
        `담당자에게 문의하기`,
        `Send us an inquiry`,
        's_home.business.cta.desktop',
        `咨询负责人`,
        `諮詢負責人`,
        `担当者へ問い合わせ`,
        `Liên hệ người phụ trách`,
      ],
      mobile: [
        `담당자에게 문의하기`,
        `Send us an inquiry`,
        's_home.business.cta.mobile',
        `咨询负责人`,
        `諮詢負責人`,
        `担当者へ問い合わせ`,
        `Liên hệ người phụ trách`,
      ],
    },
  },
  business_review: {
    subtitle: {
      desktop: [
        `기업 고객 후기`,
        `Customers' Testimonials`,
        's_home.business_review.subtitle.desktop',
        `企业用户评价`,
        `企業用戶評價`,
        `法人のお客様の声`,
        `Đánh giá của khách hàng doanh nghiệp`,
      ],
      mobile: [
        `기업 고객 후기`,
        `Customers' Testimonials`,
        's_home.business_review.subtitle.mobile',
        `企业用户评价`,
        `企業用戶評價`,
        `法人のお客様の声`,
        `Đánh giá của khách hàng doanh nghiệp`,
      ],
    },
    title: {
      desktop: [
        `링글을 도입한 기업 담당자들의
솔직한 후기를 확인하세요.`,
        `Hear from companies that have
partnered with Ringle.`,
        's_home.business_review.title.desktop',
        `请阅览引进了Ringle的企业负责人的
真实使用评价。`,
        `請閱覽引進了Ringle的企業負責人的
真實使用評價。`,
        `Ringle導入企業の担当者様の
リアルレビュー`,
        `Đánh giá trung thực từ những người phụ trách các doanh nghiệp hợp tác với Ringle`,
      ],
      mobile: [
        `링글을 도입한 기업 담당자들의
솔직한 후기를 확인하세요.`,
        `Hear from companies that have
partnered with Ringle.`,
        's_home.business_review.title.mobile',
        `请阅览引进了Ringle的企业负责人的
真实使用评价。`,
        `請閱覽引進了Ringle的企業負責人的
真實使用評價。`,
        `Ringle導入企業の担当者様の
リアルレビュー`,
        `Đánh giá trung thực từ những người phụ trách các doanh nghiệp hợp tác với Ringle`,
      ],
    },
    toss: {
      desktop: [
        `개인 역량 개발을 위한 지원을 아낌없이 제공하는 토스는 임직원의 자유도를 중요시합니다. 
수업 시간, 교재, 튜터를 자유롭게 선택해 수업할 수 있는 링글의 유연함이 토스의 이런 문화와 잘 맞는 것 같습니다.`,
        `At Toss, we're all about personal development and employee freedom. 
Ringle's flexibility in scheduling and choosing materials and tutors complements our corporate culture.`,
        's_home.business_review.toss.desktop',
        `毫不吝啬地提供个人能力开发支援的toss非常重视员工的自由度。 
Ringle可以自由选择上课时间、教材、老师进行授课的的灵活性与Toss的企业文化不谋而合。`,
        `毫不吝嗇地提供個人能力開發支援的toss非常重視員工的自由度。 
Ringle可以自由選擇上課時間、教材、老師進行授課的的靈活性與Toss的企業文化不謀而合。`,
        `個人の能力向上のため惜しみなくサポートを提供するTossは、全社員の自由度を重視しています。
レッスン時間、教材、チューターを自由に選べるRingleの柔軟性がTossの企業文化とマッチしていると思います。`,
        `Với sự hỗ trợ không ngừng nghỉ để phát triển năng lực cá nhân, Toss coi trọng sự tự do của nhân viên.
Sự linh hoạt của Ringle cho phép tự do lựa chọn giờ học, tài liệu, gia sư phù hợp với văn hóa này của Toss.`,
      ],
      mobile: [
        `개인 역량 개발을 위한 지원을 아낌없이 제공하는 토스는 임직원의 자유도를 중요시합니다. 
수업 시간, 교재, 튜터를 자유롭게 선택해 수업할 수 있는 링글의 유연함이 토스의 이런 문화와 잘 맞는 것 같습니다.`,
        `At Toss, we're all about personal development and employee freedom. 
Ringle's flexibility in scheduling and choosing materials and tutors complements our corporate culture.`,
        's_home.business_review.toss.mobile',
        `毫不吝啬地提供个人能力开发支援的toss非常重视员工的自由度。 
Ringle可以自由选择上课时间、教材、老师进行授课的的灵活性与Toss的企业文化不谋而合。`,
        `毫不吝嗇地提供個人能力開發支援的toss非常重視員工的自由度。 
Ringle可以自由選擇上課時間、教材、老師進行授課的的靈活性與Toss的企業文化不謀而合。`,
        `個人の能力向上のため惜しみなくサポートを提供するTossは、全社員の自由度を重視しています。
レッスン時間、教材、チューターを自由に選べるRingleの柔軟性がTossの企業文化とマッチしていると思います。`,
        `Với sự hỗ trợ không ngừng nghỉ để phát triển năng lực cá nhân, Toss coi trọng sự tự do của nhân viên.
Sự linh hoạt của Ringle cho phép tự do lựa chọn giờ học, tài liệu, gia sư phù hợp với văn hóa này của Toss.`,
      ],
    },
    sk: {
      desktop: [
        `실무에 영어를 많이 쓰는 임직원의 링글 활용도가 높아 3년 이상 꾸준히 사내 어학 프로그램으로 제공하고 있습니다. 
특히 원하는 때에 앱과 웹으로 학습할 수 있어 시간과 공간의 제약이 적고, 바쁜 임직원들의 만족도가 높은 것 같습니다.`,
        `As our in-house language program for more than three years, Ringle is utilized heavily by employees whose jobs require English proficiency. 
It's especially popular among employees with busy schedules because they could take lessons without being constrained by time and space.`,
        's_home.business_review.sk.desktop',
        `在实务中经常使用英语的职员们使用Ringle的频率很高，因此3年以来Ringle一直被用作公司内部语言培训项目。 
特别是可以在需要的时候通过APP和网页学习，不受时间和空间的限制，平日忙碌的员工们的满意度很高。`,
        `在實務中經常使用英語的職員們使用Ringle的頻率很高，因此3年以來Ringle一直被用作公司內部語言培訓專案。 
特別是可以在需要的時候通過APP和網頁學習，不受時間和空間的限制，平日忙碌的員工們的滿意度很高。`,
        `業務で英語を多く使う社員のRingle活用度が高く、3年以上継続して社内の語学プログラムとして提供しています。
いつでもアプリやウェブで学習できるので時間や空間の制限が少なく、忙しい社員たちにとっても満足度が高いと思われます。`,
        `Vì những nhân viên sử dụng tiếng Anh nhiều trong công việc có độ ứng dụng Ringle cao nên chúng tôi đã cung cấp Ringle như một chương trình ngôn ngữ nội bộ trong hơn 3 năm qua.
Đặc biệt, vì có thể học thông qua ứng dụng và web bất cứ khi nào, ít bị hạn chế về thời gian và không gian nên các nhân viên bận rộn dường như rất hài lòng`,
      ],
      mobile: [
        `실무에 영어를 많이 쓰는 임직원의 링글 활용도가 높아 3년 이상 꾸준히 사내 어학 프로그램으로 제공하고 있습니다. 
특히 원하는 때에 앱과 웹으로 학습할 수 있어 시간과 공간의 제약이 적고, 바쁜 임직원들의 만족도가 높은 것 같습니다.`,
        `As our in-house language program for more than three years, Ringle is utilized heavily by employees whose jobs require English proficiency. 
It's especially popular among employees with busy schedules because they could take lessons without being constrained by time and space.`,
        's_home.business_review.sk.mobile',
        `在实务中经常使用英语的职员们使用Ringle的频率很高，因此3年以来Ringle一直被用作公司内部语言培训项目。 
特别是可以在需要的时候通过APP和网页学习，不受时间和空间的限制，平日忙碌的员工们的满意度很高。`,
        `在實務中經常使用英語的職員們使用Ringle的頻率很高，因此3年以來Ringle一直被用作公司內部語言培訓專案。 
特別是可以在需要的時候通過APP和網頁學習，不受時間和空間的限制，平日忙碌的員工們的滿意度很高。`,
        `業務で英語を多く使う社員のRingle活用度が高く、3年以上継続して社内の語学プログラムとして提供しています。
いつでもアプリやウェブで学習できるので時間や空間の制限が少なく、忙しい社員たちにとっても満足度が高いと思われます。`,
        `Vì những nhân viên sử dụng tiếng Anh nhiều trong công việc có độ ứng dụng Ringle cao nên chúng tôi đã cung cấp Ringle như một chương trình ngôn ngữ nội bộ trong hơn 3 năm qua.
Đặc biệt, vì có thể học thông qua ứng dụng và web bất cứ khi nào, ít bị hạn chế về thời gian và không gian nên các nhân viên bận rộn dường như rất hài lòng`,
      ],
    },
    yanolja: {
      desktop: [
        `기존에 사용하던 전화 영어보다 더 다양한 튜터와 교재가 제공되어 200명 이상의 교육 대상자들이 만족할거란 
확신이 링글을 선택하게 된 가장 큰 이유입니다. 예산 내에서 좋은 서비스를 구성원들에게 제공할 수 있어 무엇보다 기쁘게 생각합니다.`,
        `We chose Ringle because it offers a greater variety of tutors and materials than our previous phone-based English program. 
We knew that we will be able to satisfy our 200+ employees better, we're pleased that such a good program can be offered within our budget.`,
        's_home.business_review.yanolja.desktop',
        `与之前使用的电话英语相比，Ringle提供了更多的导师选择和教材，选择Ringle最重要的原因就是确信200多名培训生会满意的这份自信。 没有什么比能够在预算内向员工们提供好的服务更令人高兴的了。`,
        `與之前使用的電話英語相比，Ringle提供了更多的導師選擇和教材，選擇Ringle最重要的原因就是確信200多名培訓生會滿意的這份自信。 沒有什麼比能夠在預算內向員工們提供好的服務更令人高興的了。`,
        `以前利用していた電話英会話よりも、豊富なチューター数と教材により200名以上の教育対象者が満足できるだろうという
確信がRingleを選択した一番大きな理由です。予算内で良質のサービスを提供できることを何よりも嬉しく思います。`,
        `Lý do lớn nhất để chúng tôi chọn Ringle vì chắc chắn rằng hơn 200 đối tượng đào tạo sẽ hài lòng với việc được cung cấp gia sư và tài liệu nhiều hơn chương trình tiếng Anh qua điện thoại trước đây. Chúng tôi rất vui mừng vì có thể cung cấp dịch vụ tốt như vậy trong phạm vi ngân sách của mình.`,
      ],
      mobile: [
        `기존에 사용하던 전화 영어보다 더 다양한 튜터와 교재가 제공되어 200명 이상의 교육 대상자들이 만족할거란 
확신이 링글을 선택하게 된 가장 큰 이유입니다. 예산 내에서 좋은 서비스를 구성원들에게 제공할 수 있어 무엇보다 기쁘게 생각합니다.`,
        `We chose Ringle because it offers a greater variety of tutors and materials than our previous phone-based English program. 
We knew that we will be able to satisfy our 200+ employees better, we're pleased that such a good program can be offered within our budget.`,
        's_home.business_review.yanolja.mobile',
        `与之前使用的电话英语相比，Ringle提供了更多的导师选择和教材，选择Ringle最重要的原因就是确信200多名培训生会满意的这份自信。 没有什么比能够在预算内向员工们提供好的服务更令人高兴的了。`,
        `與之前使用的電話英語相比，Ringle提供了更多的導師選擇和教材，選擇Ringle最重要的原因就是確信200多名培訓生會滿意的這份自信。 沒有什麼比能夠在預算內向員工們提供好的服務更令人高興的了。`,
        `以前利用していた電話英会話よりも、豊富なチューター数と教材により200名以上の教育対象者が満足できるだろうという
確信がRingleを選択した一番大きな理由です。予算内で良質のサービスを提供できることを何よりも嬉しく思います。`,
        `Lý do lớn nhất để chúng tôi chọn Ringle vì chắc chắn rằng hơn 200 đối tượng đào tạo sẽ hài lòng với việc được cung cấp gia sư và tài liệu nhiều hơn chương trình tiếng Anh qua điện thoại trước đây. Chúng tôi rất vui mừng vì có thể cung cấp dịch vụ tốt như vậy trong phạm vi ngân sách của mình.`,
      ],
    },
    bcg: {
      desktop: [
        `당사는 4개의 링글 수업 과정을 개설해두고, 목표 출석률을 달성한 임직원들에게 매달 링글 수업권을 제공하고 있습니다. 
수강 신청부터 출석률 확인의 모든 과정에서 링글팀의 지원을 받아 최소한의 운영 리소스만 투입하고 있습니다.`,
        `We offer four Ringle courses and provide monthly credits to employees who meet the target attendance rate. 
The Ringle team provides comprehensive support from registration to checking attendance, minimizing the work required on our side.`,
        's_home.business_review.bcg.desktop',
        `本公司开设了4个Ringle课程，每月向达成目标出席率的职员提供Ringle课程券。 从听课申请到确认出席率的所有过程，都得到了Ringle团队的帮助，我们只需要投入最少的运营资源。`,
        `本公司開設了4個Ringle課程，每月向達成目標出席率的職員提供Ringle課程券。 從課程申請到確認出席率的所有過程，都得到了Ringle團隊的幫助，我們只需要投入最少的營運資源。`,
        `当社では4つのRingleレッスン課程を開設し、目標出席率を達成した社員に毎月Ringleレッスンチケットを提供しています。 
受講申請から出席率確認まで全過程においてRingleチームのサポートを受け、最小限の運営リソースのみを投入しています。`,
        `Chúng tôi đã mở 4 khóa học Ringle và cấp phiếu buổi học Ringle hàng tháng cho nhân viên đạt tỷ lệ chuyên cần theo chỉ tiêu. Chúng tôi nhận được sự hỗ trợ từ nhóm Ringle trong tất cả các quy trình, từ đăng ký học đến kiểm tra điểm danh và chỉ đầu tư các nguồn lực vận hành tối thiểu.”`,
      ],
      mobile: [
        `당사는 4개의 링글 수업 과정을 개설해두고, 목표 출석률을 달성한 임직원들에게 매달 링글 수업권을 제공하고 있습니다. 
수강 신청부터 출석률 확인의 모든 과정에서 링글팀의 지원을 받아 최소한의 운영 리소스만 투입하고 있습니다.`,
        `We offer four Ringle courses and provide monthly credits to employees who meet the target attendance rate. 
The Ringle team provides comprehensive support from registration to checking attendance, minimizing the work required on our side.`,
        's_home.business_review.bcg.mobile',
        `本公司开设了4个Ringle课程，每月向达成目标出席率的职员提供Ringle课程券。 从听课申请到确认出席率的所有过程，都得到了Ringle团队的帮助，我们只需要投入最少的运营资源。`,
        `本公司開設了4個Ringle課程，每月向達成目標出席率的職員提供Ringle課程券。 從課程申請到確認出席率的所有過程，都能獲得Ringle團隊的幫助。`,
        `当社では4つのRingleレッスン課程を開設し、目標出席率を達成した社員に毎月Ringleレッスンチケットを提供しています。 
受講申請から出席率確認まで全過程においてRingleチームのサポートを受け、最小限の運営リソースのみを投入しています。`,
        `Chúng tôi đã mở 4 khóa học Ringle và cấp phiếu buổi học Ringle hàng tháng cho nhân viên đạt tỷ lệ chuyên cần theo chỉ tiêu. Chúng tôi nhận được sự hỗ trợ từ nhóm Ringle trong tất cả các quy trình, từ đăng ký học đến kiểm tra điểm danh và chỉ đầu tư các nguồn lực vận hành tối thiểu.”`,
      ],
    },
    devsisters: {
      desktop: [
        `기본적인 비즈니스 뿐만 아니라 시사/정치/경제 등 다양한 주제로 영어를 학습할 수 있고, 희망하는 튜터와 시간을 자유롭게 선택할 수 있다는 장점이 있어 많은 
구성원이 이용하고 있습니다. 튜터들의 교정 능력과 수업 태도 또한 우수하여, 튜터 만족도 역시 높은 편입니다.`,
        `Ringle is used by many of our employees because they can learn English on diverse topics from business to current affairs, politics, and economics, with the tutor they like at their convenient time. They also find that the tutors are highly competent and engaging.`,
        's_home.business_review.devsisters.desktop',
        `除了基本的商务，还有时事、政治、经济等多种英语学习主题，而且具有可以自由选择时间及自己喜爱的导师的优点，因此很多员工都在使用Ringle。导师们的纠错能力和授课态度也非常优秀，导师的满意度也相当高。`,
        `除了基本的商務，還有時事、政治、經濟等多種英語學習主題，而且具有可以自由選擇時間及自己喜愛的導師的優點，因此很多員工都在使用Ringle。導師們的糾錯能力和授課態度也非常優秀，導師的滿意度也相當高。`,
        `基本的なビジネスだけでなく時事/政治/経済など様々な英語を学べ、希望するチューターや時間を自由に選択できるという長所があるため多くの社員が利用しています。チューターの校正能力やレッスン態度もまた優秀で、チューター満足度もやはり高いです。`,
        `Nhiều nhân viên đang sử dụng Ringle vì ưu điểm là có thể học tiếng Anh theo nhiều chủ đề khác nhau như thời sự, chính trị, kinh tế cũng như kinh doanh cơ bản, đồng thời có thể tự do chọn gia sư và thời gian mình mong muốn. Kỹ năng hiệu chỉnh và thái độ của gia sư trong buổi học cũng rất xuất sắc nên mức độ hài lòng cao.`,
      ],
      mobile: [
        `기본적인 비즈니스 뿐만 아니라 시사/정치/경제 등 다양한 주제로 영어를 학습할 수 있고, 희망하는 튜터와 시간을 자유롭게 선택할 수 있다는 장점이 있어 많은 
구성원이 이용하고 있습니다. 튜터들의 교정 능력과 수업 태도 또한 우수하여, 튜터 만족도 역시 높은 편입니다.`,
        `Ringle is used by many of our employees because they can learn English on diverse topics from business to current affairs, politics, and economics, with the tutor they like at their convenient time. They also find that the tutors are highly competent and engaging.`,
        's_home.business_review.devsisters.mobile',
        `除了基本的商务，还有时事、政治、经济等多种英语学习主题，而且具有可以自由选择时间及自己喜爱的导师的优点，因此很多员工都在使用Ringle。导师们的纠错能力和授课态度也非常优秀，导师的满意度也相当高。`,
        `除了基本的商務，還有時事、政治、經濟等多種英語學習主題，而且具有可以自由選擇時間及自己喜愛的導師的優點，因此很多員工都在使用Ringle。導師們的糾錯能力和授課態度也非常優秀，導師的滿意度也相當高。`,
        `基本的なビジネスだけでなく時事/政治/経済など様々な英語を学べ、希望するチューターや時間を自由に選択できるという長所があるため多くの社員が利用しています。チューターの校正能力やレッスン態度もまた優秀で、チューター満足度もやはり高いです。`,
        `Nhiều nhân viên đang sử dụng Ringle vì ưu điểm là có thể học tiếng Anh theo nhiều chủ đề khác nhau như thời sự, chính trị, kinh tế cũng như kinh doanh cơ bản, đồng thời có thể tự do chọn gia sư và thời gian mình mong muốn. Kỹ năng hiệu chỉnh và thái độ của gia sư trong buổi học cũng rất xuất sắc nên mức độ hài lòng cao.`,
      ],
    },
    celltrion: {
      desktop: [
        `전사 임직원에게 복지 혜택으로 링글을 제공하고 있습니다. 다양한 튜터, 매일 업데이트 되는 
학습 콘텐츠와 커스텀 가능한 수업 방식 덕분에 각기 다른 업무를 하는 임직원들이 자신에게 맞는 영어 학습을 진행할 수 있어 좋습니다.`,
        `Ringle is perfect as an education benefit for all employees because it can be customized 
for any employee, regardless of role, to learn English at their own pace, from tutors, materials, and lesson style.`,
        's_home.business_review.celltrion.desktop',
        `我们正在向全体员工提供Ringle作为一种公司福利。得益于不同类型的导师、每天更新的学习内容和可定制的授课方式，从事不同业务的员工可以选择适合自己的方式内容进行英语学习，真是太棒了。`,
        `我們正在向全體員工提供Ringle作為一種公司福利。得益於不同類型的導師、每天更新的學習內容和可定制的授課方式，從事不同業務的員工可以選擇適合自己的方式內容進行英語學習，真是太棒了。`,
        `全社員に福祉特典としてRingleを提供しています。 様々なチューター、毎日アップデートされる 
学習コンテンツやカスタム可能なレッスン方式のおかげで、それぞれ業務の異なる社員が各自に合った英語学習を進めることができる点が気に入っています。`,
        `Chúng tôi đang cung cấp Ringel như một lợi ích phúc lợi cho nhân viên của công ty. Nhờ có nhiều gia sư khác nhau, nội dung học tập được cập nhật hàng ngày và phương pháp học có thể tùy chỉnh nên các nhân viên làm việc khác nhau có thể tiến hành học tiếng Anh phù hợp với bản thân.`,
      ],
      mobile: [
        `전사 임직원에게 복지 혜택으로 링글을 제공하고 있습니다. 다양한 튜터, 매일 업데이트 되는 
학습 콘텐츠와 커스텀 가능한 수업 방식 덕분에 각기 다른 업무를 하는 임직원들이 자신에게 맞는 영어 학습을 진행할 수 있어 좋습니다.`,
        `Ringle is perfect as an education benefit for all employees because it can be customized 
for any employee, regardless of role, to learn English at their own pace, from tutors, materials, and lesson style.`,
        's_home.business_review.celltrion.mobile',
        `我们正在向全体员工提供Ringle作为一种公司福利。得益于不同类型的导师、每天更新的学习内容和可定制的授课方式，从事不同业务的员工可以选择适合自己的方式内容进行英语学习，真是太棒了。`,
        `我們正在向全體員工提供Ringle作為一種公司福利。得益於不同類型的導師、每天更新的學習內容和可定制的授課方式，從事不同業務的員工可以選擇適合自己的方式內容進行英語學習，真是太棒了。`,
        `全社員に福祉特典としてRingleを提供しています。 様々なチューター、毎日アップデートされる 
学習コンテンツやカスタム可能なレッスン方式のおかげで、それぞれ業務の異なる社員が各自に合った英語学習を進めることができる点が気に入っています。`,
        `Chúng tôi đang cung cấp Ringel như một lợi ích phúc lợi cho nhân viên của công ty. Nhờ có nhiều gia sư khác nhau, nội dung học tập được cập nhật hàng ngày và phương pháp học có thể tùy chỉnh nên các nhân viên làm việc khác nhau có thể tiến hành học tiếng Anh phù hợp với bản thân.`,
      ],
    },
  },
  business_b2bservice: {
    subtitle: {
      desktop: [
        `맞춤형 기업 서비스 제공`,
        `Customized B2B Services`,
        's_home.business_b2bservice.subtitle.desktop',
        `为企业提供量身定制的服务`,
        `為企業提供量身定制的服務`,
        `個別型企業サービス提供`,
        `Cung cấp dịch vụ tùy chỉnh cho doanh nghiệp`,
      ],
      mobile: [
        `맞춤형 기업 서비스 제공`,
        `Customized B2B Services`,
        's_home.business_b2bservice.subtitle.mobile',
        `为企业提供量身定制的服务`,
        `為企業提供量身定制的服務`,
        `個別型企業サービス提供`,
        `Cung cấp dịch vụ tùy chỉnh cho doanh nghiệp`,
      ],
    },
    title: {
      desktop: [
        `링글 B2B 전담팀이 
최적화된 기업 서비스를 제공합니다.`,
        `Ringle's B2B team creates 
the optimal service for your company`,
        's_home.business_b2bservice.title.desktop',
        `Ringle B2B专业团队
为企业提供最优质的服务。`,
        `Ringle B2B專業團隊
為企業提供最優質的服務。`,
        `RingleのB2B専任チームが
最適化された法人向けサービスを提供します。`,
        `Nhóm chuyên trách Ringel B2B cung cấp dịch vụ tối ưu hóa cho doanh nghiệp`,
      ],
      mobile: [
        `링글 B2B 전담팀이 
최적화된 기업 서비스를 제공합니다.`,
        `Ringle's B2B team creates 
the optimal service for your company`,
        's_home.business_b2bservice.title.mobile',
        `Ringle B2B专业团队
为企业提供最优质的服务。`,
        ``,
        ``,
        ``,
      ],
    },
    nolimit_title: {
      desktop: [
        `기업 맞춤형 수업권 제안`,
        `Customized\nB2B Credits`,
        's_home.business_b2bservice.nolimit_title.desktop',
        `企业定制型课程券提案`,
        `企業定制型課程券提案`,
        `企業に合わせたレッスンチケット提案`,
        `Đề xuất phiếu buổi học tùy chỉnh cho doanh nghiệp`,
      ],
      mobile: [
        `기업 맞춤형 수업권 제안`,
        `Customized B2B Credits`,
        's_home.business_b2bservice.nolimit_title.mobile',
        `企业定制型课程券提案`,
        `企業定制型課程券提案`,
        `企業ごとのレッスンチケット提案`,
        `Đề xuất phiếu buổi học tùy chỉnh cho doanh nghiệp`,
      ],
    },
    nolimit_subtitle: {
      desktop: [
        `기업의 예산과 임직원 지원 방식에 따라
커스텀된 가격과 형식을 제안합니다.`,
        `Pricing and credit packages can be
customized to your budget and program`,
        's_home.business_b2bservice.nolimit_subtitle.desktop',
        `根据企业的预算和员工支援方式，
我们为您提案量身定制的授课形式和合理的价格。`,
        `根據企業的預算和員工支援方式，
我們為您提案量身定制的授課形式和合理的價格。`,
        `企業の予算や社員サポート方式に合わせた
お値段と形式を提案します。`,
        `Chúng tôi đề xuất mức giá và định dạng tùy chỉnh theo ngân sách của doanh nghiệp và phương pháp hỗ trợ nhân viên`,
      ],
      mobile: [
        `기업의 예산과 임직원 지원 방식에 따라
커스텀된 가격과 형식을 제안합니다.`,
        `Pricing and credit packages can be
customized to your budget and program`,
        's_home.business_b2bservice.nolimit_subtitle.mobile',
        `根据企业的预算和员工支援方式，
我们为您提案量身定制的授课形式和合理的价格。`,
        `根據企業的預算和員工支援方式，
我們為您提案量身定制的授課形式和合理的價格。`,
        `企業の予算や社員サポート方式に合わせた
お値段と形式を提案します。`,
        `Chúng tôi đề xuất mức giá và định dạng tùy chỉnh theo ngân sách của doanh nghiệp và phương pháp hỗ trợ nhân viên`,
      ],
    },
    consult_title: {
      desktop: [
        `수업 컨설팅 가능`,
        `Personalized\nConsultations`,
        's_home.business_b2bservice.consult_title.desktop',
        `可进行课程咨询`,
        `可進行課程諮詢`,
        `レッスンコンサルティング可能`,
        `Tư vấn buổi học`,
      ],
      mobile: [
        `수업 컨설팅 가능`,
        `Personalized Consultations`,
        's_home.business_b2bservice.consult_title.mobile',
        `可进行课程咨询`,
        ``,
        ``,
        ``,
      ],
    },
    consult_subtitle: {
      desktop: [
        `교재 선정부터 수업 내용에 대한
상세한 컨설팅을 진행할 수 있습니다.`,
        `We provide 1:1 advising to all B2B customers to further guide their English learning journey`,
        's_home.business_b2bservice.consult_subtitle.desktop',
        `从教材选定到对授课内容的讲解，
我们为您提供详细的咨询服务。`,
        `從教材選定到對授課內容的講解，
我們為您提供詳細的諮詢服務。`,
        `教材選びからレッスン内容における
細かいコンサルティングが可能です。`,
        `Chúng tôi cung cấp tư vấn từ việc lựa chọn tài liệu đến nội dung chi tiết buổi học`,
      ],
      mobile: [
        `교재 선정부터 수업 내용에 대한
상세한 컨설팅을 진행할 수 있습니다.`,
        `We provide 1:1 advising to all B2B customers to further guide their English learning journey`,
        's_home.business_b2bservice.consult_subtitle.mobile',
        `从教材选定到对授课内容的讲解，
我们为您提供详细的咨询服务。`,
        `從教材選定到對授課內容的講解，
我們為您提供詳細的諮詢服務。`,
        `教材選びからレッスン内容における
細かいコンサルティングが可能です。`,
        `Chúng tôi cung cấp tư vấn từ việc lựa chọn tài liệu đến nội dung chi tiết buổi học`,
      ],
    },
  },
  business_manage: {
    subtitle: {
      desktop: [
        `맞춤형 관리 시스템 제공`,
        `All-Inclusive Support`,
        's_home.business_manage.subtitle.desktop',
        `提供定制管理系统`,
        `提供定制管理系統`,
        `個別型管理システム提供`,
        `Cung cấp hệ thống quản lý tùy chỉnh`,
      ],
      mobile: [
        `맞춤형 관리 시스템 제공`,
        `All-Inclusive Support`,
        's_home.business_manage.subtitle.mobile',
        `提供定制管理系统`,
        `提供定制管理系統`,
        `個別型管理システム提供`,
        `Cung cấp hệ thống quản lý tùy chỉnh`,
      ],
    },
    title: {
      desktop: [
        `수업 알림부터 학습 현황 파악까지
실시간으로 관리해드립니다.`,
        `Our team provides real-time support
from lesson alerts to monitoring progress`,
        's_home.business_manage.title.desktop',
        `从上课提醒到学习进度掌握，
我们进行实时的管理。`,
        `從上課提醒到學習進度掌握，
我們進行即時的管理。`,
        `レッスン通知から学習現況把握まで
リアルタイムでの管理が可能です。`,
        `Chúng tôi quản lý theo thời gian thực từ thông báo buổi học đến nắm bắt tình hình học tập`,
      ],
      mobile: [
        `수업 알림부터 학습 현황 파악까지
실시간으로 관리해드립니다.`,
        `Our team provides real-time support from lesson alerts to monitoring progress`,
        's_home.business_manage.title.mobile',
        `从上课提醒到学习进度掌握，
我们进行实时的管理。`,
        `從上課提醒到學習進度掌握，
我們進行即時的管理。`,
        `レッスン通知から学習現況把握まで
リアルタイムでの管理が可能です。`,
        `Chúng tôi quản lý theo thời gian thực từ thông báo buổi học đến nắm bắt tình hình học tập`,
      ],
    },
    system_title: {
      desktop: [
        `기업과 임직원 모두를 위한
전담 관리시스템`,
        `Streamlined System`,
        's_home.business_manage.system_title.desktop',
        `为企业和全体员工提供
专门管理系统`,
        `為企業和全體員工提供
專門管理系統`,
        `企業と社員全員のための
専担管理システム`,
        `Hệ thống quản lý chuyên trách cho cả doanh nghiệp và nhân viên`,
      ],
      mobile: [
        `기업과 임직원 모두를 위한
전담 관리시스템`,
        `Streamlined System`,
        's_home.business_manage.system_title.mobile',
        `为企业和全体员工提供
专门管理系统`,
        `為企業和全體員工提供
專門管理系統`,
        `企業と社員全員のための
専担管理システム`,
        `Hệ thống quản lý chuyên trách cho cả doanh nghiệp và nhân viên`,
      ],
    },
    system_bodytxt_1: {
      desktop: [
        `기업 HR팀을 위한 맞춤관리 페이지 제공 및 임직원 
실시간 학습 현황과 엑셀 파일 다운로드를 제공합니다.`,
        `Ringle's B2B platform allows HR and managers to view and download team's lesson progress`,
        's_home.business_manage.system_bodytxt_1.desktop',
        `为企业HR团队提供量身定做的管理页面及员工 
实时学习进度和Excel文件下载。`,
        `為企業HR團隊提供量身定做的管理頁面及員工 
即時學習進度和Excel檔下載。`,
        `企業HRチームのための管理ページ提供及び社員の
リアルタイム学習状況やエクセルファイルダウンロードを提供します。`,
        `Cung cấp trang quản lý tùy chỉnh cho nhân sự của doanh nghiệp, cung cấp tình hình học tập của nhân viên theo thời gian thực và tải tệp Excel`,
      ],
      mobile: [
        `기업 HR팀을 위한 맞춤관리 페이지 제공 및 임직원 
실시간 학습 현황과 엑셀 파일 다운로드를 제공합니다.`,
        `Ringle's B2B platform allows HR and managers to view
and download team's lesson progress`,
        's_home.business_manage.system_bodytxt_1.mobile',
        `为企业HR团队提供量身定做的管理页面及员工 
实时学习进度和Excel文件下载。`,
        `為企業HR團隊提供量身定做的管理頁面及員工 
即時學習進度和Excel檔下載。`,
        `企業HRチームのための管理ページ提供及び社員の
リアルタイム学習状況やエクセルファイルダウンロードを提供します。`,
        `Cung cấp trang quản lý tùy chỉnh cho nhân sự của doanh nghiệp, cung cấp tình hình học tập của nhân viên theo thời gian thực và tải tệp Excel`,
      ],
    },
    system_bodytxt_2: {
      desktop: [
        `수강생 전원 1:1 상담부터 수업알림 제공을 통해 
정해진 기간 충분한 학습 효과를 누릴 수 있도록 
도움을 드리고 있습니다.`,
        `From 1:1 advising to lesson alerts, 
Ringle takes care that everyone achieves
maximum learning within the given period.`,
        's_home.business_manage.system_bodytxt_2.desktop',
        `全体学生可进行1:1咨询商谈，通过提供上课提醒服务，帮助学生在规定的时间内达成充分的学习效果。`,
        `全體學生可進行1:1諮詢商談，通過提供上課提醒服務，幫助學生在規定的時間內達成充分的學習效果。`,
        `全受講生向け1on1相談やレッスン通知提供を通して
決められた期間内に十分な学習効果を得られるよう
お手伝いしております。`,
        `Chúng tôi đang giúp tất cả học viên tận hưởng hiệu quả học tập đầy đủ trong thời gian quy định thông qua việc cung tư vấn 1:1 đến thông báo buổi học.`,
      ],
      mobile: [
        `수강생 전원 1:1 상담부터 수업알림 제공을 통해 
정해진 기간 충분한 학습 효과를 누릴 수 있도록 
도움을 드리고 있습니다.`,
        `From 1:1 advising to lesson alerts, 
Ringle takes care that everyone achieves
maximum learning within the given period.`,
        's_home.business_manage.system_bodytxt_2.mobile',
        `全体学生可进行1:1咨询商谈，通过提供上课提醒服务，帮助学生在规定的时间内达成充分的学习效果。`,
        `全體學生可進行1:1諮詢商談，通過提供上課提醒服務，幫助學生在規定的時間內達成充分的學習效果。`,
        `全受講生向け1on1相談やレッスン通知提供を通して
決められた期間内に十分な学習効果を得られるよう
お手伝いしております。`,
        `Chúng tôi đang giúp tất cả học viên tận hưởng hiệu quả học tập đầy đủ trong thời gian quy định thông qua việc cung tư vấn 1:1 đến thông báo buổi học.`,
      ],
    },
  },
  business_inquiry: {
    title: {
      desktop: [
        `기업 맞춤 컨설팅, 지금 바로 문의하세요.`,
        `Improve your team's English communications with Ringle`,
        's_home.business_inquiry.title.desktop',
        `敬请在Ringle确认英语实力的显著提高。`,
        `敬請在Ringle確認英語實力的顯著提高。`,
        `確実な英語力アップを
Ringleで実現。`,
        `Hãy kiểm tra sự cải thiện rõ rệt khả năng tiếng Anh của bạn với Ringle`,
      ],
      mobile: [
        `확실한 영어 실력향상을
링글로 확인하세요.`,
        `Improve your team's English communications with Ringle`,
        's_home.business_inquiry.title.mobile',
        `在Ringle确认自己的英语实力显著提高。`,
        `在Ringle確認自己的英文能力提升。`,
        `確実な英語力アップを
Ringleで実現。`,
        `Hãy kiểm tra sự cải thiện rõ rệt khả năng tiếng Anh của bạn với Ringle`,
      ],
    },
    subtitle: {
      desktop: [
        `문의를 남겨주시면 기업 담당자용 1회 체험 수업권을 드립니다.`,
        `Leave an inquiry and get a free lesson!`,
        's_home.business_inquiry.subtitle.desktop',
        `留言咨询，赠送1张企业负责人专用正规课程券。`,
        `留言諮詢，贈送1張企業負責人專用正規課程券。`,
        `お問い合わせをいただいた方に企業担当者用レギュラーレッスンチケット1回分を差し上げます。`,
        `Hãy để lại câu hỏi, chúng tôi sẽ cung cấp phiếu học 1 lần cho người phụ trách doanh nghiệp.`,
      ],
      mobile: [
        `문의를 남겨주시면 기업 담당자용 1회 정규 수업권을 드립니다.`,
        `Leave an inquiry and get a free lesson!`,
        's_home.business_inquiry.subtitle.mobile',
        `留言咨询，赠送1张企业负责人专用正规课程券。`,
        `留言諮詢，贈送1張企業負責人專用正規課程券。`,
        `お問い合わせをいただいた方に企業担当者様にレギュラーレッスンチケット１回分を差し上げます。`,
        `Hãy để lại câu hỏi, chúng tôi sẽ cung cấp phiếu học 1 lần cho người phụ trách doanh nghiệp.`,
      ],
    },
  },
  business_export: {
    subtitle: {
      desktop: [
        <>
          혹시 수출바우처 지원 회사에 근무 중이신가요?
          <br />
          든든한 지원과 함께 링글을 시작하세요.
        </>,
        ``,
        's_home.business_export.subtitle.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        <>
          혹시 수출바우처 지원 회사에 근무 중이신가요?
          <br />
          든든한 지원과 함께 링글을 시작하세요.
        </>,
        ``,
        's_home.business_export.subtitle.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`수출바우처 이용 알아보기`, ``, 's_home.business_export.cta.desktop', ``, ``, ``, ``],
      mobile: [`수출바우처 이용 알아보기`, ``, 's_home.business_export.cta.mobile', ``, ``, ``, ``],
    },
  },
  benefit_title: {
    subtitle: {
      desktop: [
        `링글이 처음이라면`,
        `New to Ringle?`,
        's_home.benefit_title.subtitle.desktop',
        `如果是第一次报名Ringle`,
        `New to Ringle?`,
        `New to Ringle?`,
        `New to Ringle?`,
      ],
      mobile: [
        `링글이 처음이라면`,
        `New to Ringle?`,
        's_home.benefit_title.subtitle.mobile',
        `如果是第一次报名Ringle`,
        `New to Ringle?`,
        `New to Ringle?`,
        `New to Ringle?`,
      ],
    },
    title: {
      desktop: [
        <>
          가입 즉시 받는
          <br />
          신규회원 한정 혜택
        </>,
        <>
          Special Offer
          <br />
          for Newcomers
        </>,
        's_home.benefit_title.title.desktop',
        `新用戶特别优惠`,
        `新用戶特別優惠`,
        <>
          登録後すぐに貰える
          <br />
          新規会員限定特典
        </>,
        <>
          Nhận ngay sau khi đăng ký
          <br />
          Quyền lợi dành riêng cho thành viên mới
        </>,
      ],
      mobile: [
        <>
          가입 즉시 받는
          <br />
          신규회원 한정 혜택
        </>,
        <>
          Special Offer
          <br />
          for Newcomers
        </>,
        's_home.benefit_title.title.mobile',
        `新用戶特别优惠`,
        `新用戶特別優惠`,
        <>
          登録後すぐに貰える
          <br />
          新規会員限定特典
        </>,
        <>
          Nhận ngay sau khi đăng ký
          <br />
          Quyền lợi dành riêng cho thành viên mới
        </>,
      ],
    },
  },
  benefit_txt_trial: {
    subtitle: {
      desktop: [
        `혜택 1.`,
        `Benefit 1.`,
        's_home.benefit_txt_trial.subtitle.desktop',
        `优惠1.`,
        `優惠1.`,
        `特典 1.`,
        `Ưu đãi 1`,
      ],
      mobile: [
        `혜택 1.`,
        `Benefit 1.`,
        's_home.benefit_txt_trial.subtitle.mobile',
        `优惠1.`,
        `優惠1.`,
        `特典 1.`,
        `Ưu đãi 1`,
      ],
    },
    title: {
      desktop: [
        <>
          링글의 첫 수업,
          <br />
          커피 한 잔 가격으로 체험하세요.
        </>,
        <>
          Your first lesson with Ringle,
          <br />
          just for the price of a cup of coffee.
        </>,
        's_home.benefit_txt_trial.title.desktop',
        <>
          您的第一堂Ringle课程，
          <br />
          仅需一杯咖啡的价格。
        </>,
        <>
          您的第一堂Ringle課程，
          <br />
          僅需一杯咖啡的價格。
        </>,
        <>
          Ringleでの初レッスン、
          <br />
          コーヒー1杯の値段で体験できます。
        </>,
        <>
          Bài học đầu tiên của Ringle,
          <br />
          trải nghiệm với mức giá hạt dẻ
        </>,
      ],
      mobile: [
        <>
          링글의 첫 수업,
          <br />
          커피 한 잔 가격으로 체험하세요.
        </>,
        <>
          Your first lesson with Ringle,
          <br />
          just for the price of a cup of coffee.
        </>,
        's_home.benefit_txt_trial.title.mobile',
        <>
          您的第一堂Ringle课程，
          <br />
          仅需一杯咖啡的价格。
        </>,
        <>
          您的第一堂Ringle課程，
          <br />
          僅需一杯咖啡的價格。
        </>,
        <>
          Ringleでの初レッスン、
          <br />
          コーヒー1杯の値段で体験できます。
        </>,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `가입 후 24시간 동안 1회 수업권을 구매할 수 있어요.`,
        `Get a trial lesson credit within 24 hours of signing up.`,
        's_home.benefit_txt_trial.bodytxt.desktop',
        `在注册后的24小时内可以购买一堂课程`,
        `在註冊後的24小時內可以購買一堂課程`,
        `登録後24時間以内に1回分のレッスンチケットを購入できます。`,
        `Mua thẻ học 1 lần trong vòng 24 giờ sau khi đăng ký`,
      ],
      mobile: [
        <>
          가입 후 24시간 동안
          <br />
          1회 수업권을 구매할 수 있어요.
        </>,
        <>
          Get a trial lesson credit
          <br />
          within 24 hours of signing up.
        </>,
        's_home.benefit_txt_trial.bodytxt.mobile',
        `在注册后的24小时内可以购买一堂课程`,
        `在註冊後的24小時內可以購買一堂課程`,
        `登録後24時間以内に1回分のレッスンチケットを購入できます。`,
        `Mua thẻ học 1 lần trong vòng 24 giờ sau khi đăng ký`,
      ],
    },
    caption: {
      desktop: [
        `*가입 후 최대 혜택을 위한 요건 달성 시 해당 가격으로 구매 가능`,
        <>
          If requirements for max. benefits are met after signing up,
          <br />
          purchase at the corresponding price.
        </>,
        's_home.benefit_txt_trial.caption.desktop',
        <>
          如果在注册后完成指定条件，
          <br />
          即可以优惠价格购买。
        </>,
        <>
          如果在註冊後完成指定條件，
          <br />
          即可以優惠價格購買。
        </>,
        <>
          ※登録後に最大特典の条件を達成した場合
          <br />
          該当の価格にて購入可能
        </>,
        <>
          *Sau khi đăng ký, bạn có thể mua với mức giá này
          <br />
          khi đáp ứng các yêu cầu để nhận được lợi ích tối đa
        </>,
      ],
      mobile: [
        <>
          *가입 후 최대 혜택을 위한 요건 달성 시<br />
          해당 가격으로 구매 가능
        </>,
        <>
          If requirements for max. benefits are met after signing up,
          <br />
          purchase at the corresponding price.
        </>,
        's_home.benefit_txt_trial.caption.mobile',
        <>
          如果在注册后完成指定条件，
          <br />
          即可以优惠价格购买。
        </>,
        <>
          如果在註冊後完成指定條件，
          <br />
          即可以優惠價格購買。
        </>,
        <>
          ※登録後に最大特典の条件を達成した場合
          <br />
          該当の価格にて購入可能
        </>,
        <>
          *Sau khi đăng ký, bạn có thể mua với mức giá này
          <br />
          khi đáp ứng các yêu cầu để nhận được lợi ích tối đa
        </>,
      ],
    },
  },
  benefit_txt_coupon: {
    subtitle: {
      desktop: [
        `혜택 2.`,
        `Benefit 2.`,
        's_home.benefit_txt_coupon.subtitle.desktop',
        `优惠2.`,
        `優惠2.`,
        `特典 2.`,
        `Ưu đãi 2`,
      ],
      mobile: [
        `혜택 2.`,
        `Benefit 2.`,
        's_home.benefit_txt_coupon.subtitle.mobile',
        `优惠2.`,
        `優惠2.`,
        `特典 2.`,
        `Ưu đãi 2`,
      ],
    },
    title: {
      desktop: [
        <>
          신규회원 쿠폰으로
          <br />
          링글 수업을 가볍게 시작하세요.
        </>,
        <>
          Start your Ringle journey
          <br />
          with our Welcome Coupon.
        </>,
        's_home.benefit_txt_coupon.title.desktop',
        `用我们的欢迎优惠券开启您的Ringle之旅。`,
        `用我們的歡迎優惠券開始您的Ringle之旅`,
        <>
          新規会員クーポンで
          <br />
          Ringleレッスンを気軽にスタート。
        </>,
        <>
          Khởi động với lớp học Ringle
          <br />
          bằng voucher dành cho thành viên mới
        </>,
      ],
      mobile: [
        <>
          신규회원 쿠폰으로
          <br />
          링글 수업을 가볍게 시작하세요.
        </>,
        <>
          Start your Ringle journey
          <br />
          with our Welcome Coupon.
        </>,
        's_home.benefit_txt_coupon.title.mobile',
        `用我们的欢迎优惠券开启您的Ringle之旅。`,
        `用我們的歡迎優惠券開始您的Ringle之旅`,
        <>
          新規会員クーポンで
          <br />
          Ringleレッスンを気軽にスタート。
        </>,
        <>
          Khởi động với lớp học Ringle
          <br />
          bằng voucher dành cho thành viên mới
        </>,
      ],
    },
    bodytxt: {
      desktop: [
        <>
          모든 수업권에 사용가능한 할인 쿠폰팩이 제공되며,
          <br />
          7일 동안 사용할 수 있어요.
        </>,
        <>
          Discount coupon pack for all lesson packages,
          <br />
          valid for 7 days.
        </>,
        's_home.benefit_txt_coupon.bodytxt.desktop',
        `所有课程套餐折扣券礼包，有效期为7天`,
        `所有課程折扣券禮包，有效期限為7天`,
        <>
          全てのレッスンチケットにて使用可能な割引クーポンが支給され、
          <br />
          7日間ご使用いただけます。
        </>,
        <>
          Phiếu giảm giá có thể dùng cho tất cả lớp học
          <br />
          hạn dùng 7 ngày
        </>,
      ],
      mobile: [
        <>
          모든 수업권에 사용가능한 할인 쿠폰팩이
          <br />
          제공되며, 7일 동안 사용할 수 있어요.
        </>,
        <>
          Discount coupon pack
          <br />
          for all lesson packages, valid for 7 days.
        </>,
        's_home.benefit_txt_coupon.bodytxt.mobile',
        `所有课程套餐折扣券礼包，有效期为7天`,
        `所有課程折扣券禮包，有效期限為7天`,
        <>
          全てのレッスンチケットにて使用可能な割引クーポンが支給され、
          <br />
          7日間ご使用いただけます。
        </>,
        <>
          Phiếu giảm giá có thể dùng cho tất cả lớp học
          <br />
          hạn dùng 7 ngày
        </>,
      ],
    },
  },
  benefit_txt_trial_free: {
    subtitle: {
      desktop: [
        `프로모션 기간 한정 (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        's_home.benefit_txt_trial_free.subtitle.desktop',
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
      ],
      mobile: [
        `프로모션 기간 한정 (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        's_home.benefit_txt_trial_free.subtitle.mobile',
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
        `Promotion Only (8/21 - 9/7)`,
      ],
    },
    title: {
      desktop: [
        <>
          링글의 첫 수업,
          <br />
          무료로 체험하세요.
        </>,
        <>
          Try your first lesson
          <br />
          with Ringle for free.
        </>,
        's_home.benefit_txt_trial_free.title.desktop',
        `申请免费体验课程`,
        `申請免費體驗`,
        `無料体験を予約する`,
        `Bắt đầu học thử miễn phí`,
      ],
      mobile: [
        <>
          링글의 첫 수업,
          <br />
          무료로 체험하세요.
        </>,
        <>
          Try your first lesson
          <br />
          with Ringle for <span className="text-[#f53428]">free</span>.
        </>,
        's_home.benefit_txt_trial_free.title.mobile',
        `申请免费体验课程`,
        `申請免費體驗`,
        `無料体験を予約する`,
        `Bắt đầu học thử miễn phí`,
      ],
    },
    bodytxt: {
      desktop: [
        `가입 후 24시간 동안 1회 수업권을 신청할 수 있어요.`,
        `Get a trial lesson credit within 24 hours of signing up.`,
        's_home.benefit_txt_trial_free.bodytxt.desktop',
        `在注册后的24小时内可以购买一堂课程`,
        `在註冊後的24小時內可以購買一堂課程`,
        `登録後24時間以内に1回分のレッスンチケットを購入できます。`,
        `Mua thẻ học 1 lần trong vòng 24 giờ sau khi đăng ký`,
      ],
      mobile: [
        `가입 후 24시간 동안 1회 수업권을 신청할 수 있어요.`,
        `Get a trial lesson credit within 24 hours of signing up.`,
        's_home.benefit_txt_trial_free.bodytxt.mobile',
        `在注册后的24小时内可以购买一堂课程`,
        `在註冊後的24小時內可以購買一堂課程`,
        `登録後24時間以内に1回分のレッスンチケットを購入できます。`,
        `Mua thẻ học 1 lần trong vòng 24 giờ sau khi đăng ký`,
      ],
    },
    caption: {
      desktop: [
        `*가입 후 최대 혜택을 위한 요건 달성 시 해당 가격으로 신청 가능`,
        `*If requirements for max. benefits are met after signing up, purchase at the corresponding price.`,
        's_home.benefit_txt_trial_free.caption.desktop',
        `*如果在注册后完成指定条件，即可以优惠价格购买。`,
        `*如果在註冊後完成指定條件，即可以優惠價格購買。`,
        `*登録後に最大特典の条件を達成した場合該当の価格にて購入可能`,
        `*Sau khi đăng ký, bạn có thể mua với mức giá này khi đáp ứng các yêu cầu để nhận được lợi ích tối đa`,
      ],
      mobile: [
        `*가입 후 최대 혜택을 위한 요건 달성 시 해당 가격으로 신청 가능`,
        `*If requirements for max. benefits are met after signing up, purchase at the corresponding price.`,
        's_home.benefit_txt_trial_free.caption.mobile',
        `*如果在注册后完成指定条件，即可以优惠价格购买。`,
        `*如果在註冊後完成指定條件，即可以優惠價格購買。`,
        `*登録後に最大特典の条件を達成した場合該当の価格にて購入可能`,
        `*Sau khi đăng ký, bạn có thể mua với mức giá này khi đáp ứng các yêu cầu để nhận được lợi ích tối đa`,
      ],
    },
  },
  floating: {
    cta: {
      desktop: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.floating.cta.desktop',
        `以$3的价格参加第一堂课程`,
        `以$3的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        ``,
      ],
      desktop_20: [
        `4,000원으로 첫 수업 체험하기`,
        `Take your first lesson for $3`,
        's_home.floating.cta.desktop_20',
        `以$3的价格参加第一堂课程`,
        `以$3的價格參加第一堂課程`,
        `$3で初レッスンを体験`,
        ``,
      ],
    },
  },
  content_txt_sevenpm: {
    subtitle: [`저녁 7시`, `7 PM`, 's_home.content_txt_sevenpm.subtitle', `晚上七点`, `晚上七點`, `午後7時`, ``],
    title: [
      `링글 AI 스피킹 파트너와\n영어 말하기 연습하기`,
      <>
        Practice talking in English with <br /> an AI Speaking Partner
      </>,
      's_home.content_txt_sevenpm.title',
      `和Ringle AI口说伙伴\n练习口说`,
      `和Ringle AI口說夥伴\n練習口說`,
      `AI Speaking Partnerと\n英会話の練習をしましょう。`,
      ``,
    ],
    bodytxt: [
      `AI 스피킹 파트너와 하루 10분, 교재에 대해 이야기 나누며 영어 발화량을 늘려보세요. 대화를 마친 후 AI 분석까지 받을 수 있어요.`,
      `Spend 10 mins a day talking in English with Ringle's AI Speaking Partner about a material and even have the talk analyzed by Ringle's AI Analysis.`,
      's_home.content_txt_sevenpm.bodytxt',
      `每天花10分钟和AI口语伙伴以教材为主题练习口说，增加口说练习的机会，结束后还能获得AI分析报告`,
      `每天花10分鐘和AI口說夥伴以教材為主題練習口說，增加口說練習的機會，結束後還能獲得AI分析報告`,
      `AI Speaking Partnerと1日10分、教材について英語で会話し、さらにその会話をRingleのAIが分析します。`,
      ``,
    ],
    caption: [
      `*대화 설정 옵션은 추후 릴리즈 예정`,
      `*Conversation mode setting option will be updated soon.`,
      's_home.content_txt_sevenpm.caption',
      `*对话设定即将更新`,
      `*對話設定即將更新`,
      `*会話モード設定は後日リリース予定`,
      ``,
    ],
  },
}
export const s_landing_tutors = {
  ivy_league_tutors: [
    <>
      영∙미 Top30 대학 출신
      <br />
      똑똑한 튜터와의
      <br />
      맞춤형 1:1 영어수업
    </>,
    <>
      Engaging conversations
      <br />
      with outstanding tutors
    </>,
    's_landing_tutors.ivy_league_tutors',
    <>
      充分了解您的
      <br />
      智能对话伙伴
    </>,
    <>
      了解您的
      <br />
      優質對話夥伴
    </>,
    <>
      選りすぐりのチューターと
      <br />
      興味・関心をそそる会話をしてみませんか
    </>,
    <>
      Các buổi thảo luận sâu
      <br />
      cùng các gia sư xuất sắc
    </>,
  ],
  see_video: [
    `가입하고 튜터 더 알아보기`,
    `Meet our tutors`,
    's_landing_tutors.see_video',
    `查看教师简介`,
    `查看任課教師簡介`,
    `チュータープロフィールを見る`,
    `Gặp gỡ gia sư của chúng tôi`,
  ],
  not_all_foreigner_good: [
    <>하고 싶은 말을 더 세련된 영어로</>,
    <>Exceptionally qualified tutors with a range of specialties</>,
    's_landing_tutors.not_all_foreigner_good',
    <>渴望表达的内容, 选用更为精练的英语</>,
    <>以更幹練的英語表達您想說的話</>,
    <>幅広い専門知識を有するチューターが所属しています</>,
    <>Những gia sư xuất sắc với chuyên môn khác nhau</>,
  ],
  ratio_na_tutors: [
    `아이비리그 & Top 30 대학 출신`,
    `Tutors from Top 30 Universities`,
    's_landing_tutors.ratio_na_tutors',
    `出身常春藤联盟&Top 30大学`,
    `常春藤名校 & Top 30大學出身`,
    `アイビーリーグ & TOP30の名門校出身`,
    `Gia sư từ các Trường Đại học Top 30`,
  ],
  native_speaker: [
    `직장 경력 보유자`,
    `Tutors with work experience`,
    's_landing_tutors.native_speaker',
    `具有工作经验`,
    `具備工作經驗`,
    `実務経験あり`,
    `Gia sư với kinh nghiệm làm việc`,
  ],
  variety_majors: [
    `경영, 개발, 의학 등 다양한 전공`,
    <>Majors including Business, Pre-Med, STEM, and more</>,
    's_landing_tutors.variety_majors',
    `经营, 开发, 医学等多种专业`,
    `經營、開發、醫學等各種主修`,
    `専攻は経営、開発、医学など多岐に渡る`,
    <>Chuyên ngành: Kinh doanh, Y khoa, STEM và nhiều ngành khác</>,
  ],
  register_and_check_profiles_out: [
    `당신에게 꼭 맞는 튜터를 찾아보세요`,
    `Find the perfect tutor \n for you`,
    's_landing_tutors.register_and_check_profiles_out',
    `一定可以找到最适合您的教师`,
    `請尋找絕對適合您的任課教師`,
    `あなたにぴったりのチューターを探してみよう`,
    `Tìm kiếm gia sư hoàn hảo`,
  ],
  register_and_check_it_out: [
    `내게 맞는 튜터 찾기`,
    `Browse 1700+ tutors`,
    's_landing_tutors.register_and_check_it_out',
    `查看1700名以上的教师简介`,
    `查看1700名以上任課教師的簡介`,
    `1700名のチュータープロフィールを見る`,
    `Xem 1700+ gia sư`,
  ],
  ringle_tutor_different: [
    <>링글 튜터는 다릅니다</>,
    <>Ringle tutors are different</>,
    's_landing_tutors.ringle_tutor_different',
    <>Ringle的教师与众不同</>,
    <>Ringle的任課教師與眾不同</>,
    <>Ringleのチューターが他と違う理由</>,
    <>Các gia sư của Ringle rất khác biệt</>,
  ],
  tutor_review_1: [
    <>
      링글 튜터는 아이비리그나 MBA 출신이라 더 검증됐다고 생각해요. 실제로 원어민들이 사용하는 고급 어휘나 세련된
      문장들을 체득할 수 있었어요.
    </>,
    <>
      I think Ringle tutors' credentials set them apart. Having attended top schools or MBA programs, they have the
      capability to advise me on advanced vocabularies and sophisticated sentences.
    </>,
    's_landing_tutors.tutor_review_1',
    <>Ringle的教师们出身常青藤联盟或MBA， 所以更能够让我学习到以英文为母语的人们实际使用的高级词汇及精炼的语句。</>,
    <>
      我覺得Ringle的任課教師出自常春藤名校或MBA，因此更加值得信賴。
      讓我可以學到母語人士們實際使用的高級詞彙及幹練的句子。
    </>,
    <>
      Ringleのチューターはその経歴から他と差をつけていると思います。トップ校やMBAで
      ネイティブが使う高レベル語彙や洗練された文章を教わることができました。
    </>,
    <>
      Tôi nghĩ xuất phát điểm của các gia sư Ringle khiến họ khác biệt. Họ theo học các trường đại học hoặc các chương
      trình MBA hàng đầu, họ có khả năng hướng dẫn tôi các từ vựng nâng cao hoặc sử dụng các câu phức tạp.
    </>,
  ],
  tutor_review_1_writer: [
    <>마이크로소프트 마케팅팀, Lyn</>,
    <>Lyn, Marketing at Microsoft</>,
    's_landing_tutors.tutor_review_1_writer',
    <>Microsoft市场推广组, Lyn</>,
    <>Microsoft行銷團隊，Lyn</>,
    <>Microsoft マーケティングチーム、Lyn</>,
    <>Lyn, Marketing tại Microsoft</>,
  ],
  tutor_review_2: [
    <>
      링글 튜터들은 논리적으로 생각하고 대화해요. 그래서 영어를 공식처럼 알려주는 게 아니라, 내 생각을 남들에게 전하기
      쉽게 논리적인 영어를 가르쳐줘요.
    </>,
    <>
      Ringle tutors think critically when they speak. They don't teach English by grammatical rules; they teach how to
      deliver your ideas logically so that everyone can understand you.
    </>,
    's_landing_tutors.tutor_review_2',
    <>
      Ringle的教师能够提供给我们具有逻辑性的对话, 不像官方英语那样
      死板。而且教给我如何能够运用简练及逻辑性强的英语将自身想法传递给他人。
    </>,
    <>
      Ringle的任課教師們會邏輯性地思考並對話，所以不是像教公式一樣死板，而是教我如何以具邏輯性的英語輕鬆表達我的想法。
    </>,
    <>
      Ringleのチューターは論理的に考えて会話します。だから英語を公式のように
      教えてくれるのではなく、自分の考えを他人に伝えやすく論理的な英語を教えてくれます。
    </>,
    <>
      Các gia sư Ringle suy nghĩ sâu sắc. Họ không dạy bạn những quy tắc ngữ pháp; họ dạy bạn cách truyền đạt ý tưởng
      một cách logic để mọi người có thể nắm được ý bạn.
    </>,
  ],
  tutor_review_2_writer: [
    <>Deloitte 컨설턴트, Kim</>,
    <>Kim, Consultant at Deloitte</>,
    's_landing_tutors.tutor_review_2_writer',
    <>Deloitte顾问, Kim</>,
    <>Deloitte顧問，Kim</>,
    <>Deloitte コンサルタント、Kim</>,
    <>Kim, Consultant tại Deloitte</>,
  ],
  tutor_review_3: [
    <>
      튜터들의 백그라운드가 굉장히 다양해서 나의 성향과 목표에 맞는 분들이 많아요. 선택의 폭이 넓다는 것이 가장
      좋습니다.
    </>,
    <>
      Ringle tutors come from diverse backgrounds, so I can find tutors who match my personality and share my career
      goals. My favorite thing about Ringle is the big pool of tutors to choose from.
    </>,
    's_landing_tutors.tutor_review_3',
    <>教师们的文化背景丰富, 符合我要求及目标的教师很多。 选择余地大, 这一点我很满意。</>,
    <>任課教師們的背景豐富，有許多任課教師符合我的性向及目標。選擇多元這點最棒了。</>,
    <>
      チューターのバックグラウンドが非常に多様なので、私の性格や目標に合う方が
      多いです。選択の幅が広いというのが一番いいですね。
    </>,
    <>
      Gia sư Ringle làm việc trong những môi trường khác nhau, nên tôi có thể chọn người phù hợp với tính cách và chia
      sẻ cùng mục tiêu nghề nghiệp. Điều tôi yêu thích nhất ở Ringle chính là sự lựa chọn đa dạng.
    </>,
  ],
  tutor_review_3_writer: [
    <>Ebay 엔지니어, Claire</>,
    <>Claire, Engineer at Ebay</>,
    's_landing_tutors.tutor_review_3_writer',
    <>Ebay工程师, Claire</>,
    <>Ebay工程師，Claire</>,
    <>Ebay エンジニア、Claire</>,
    <>Claire, Engineer tại Ebay</>,
  ],
  tutor_review_4: [
    <>
      전문성은 링글 튜터를 따라갈 수가 없어요. 현직 기자로 일하며, 같은 분야의 튜터와 준비한 덕분에 클라이언트와의 영어
      인터뷰에서 큰 성과를 냈습니다.
    </>,
    <>
      Ringle tutors come with deep, professional expertise at their respective area of study or career. I met a tutor
      who is also a journalist and, thanks to our lessons, I nailed an English interview with an important client.
    </>,
    's_landing_tutors.tutor_review_4',
    <>
      在专业性方面Ringle的教师真是无人能及, 我现在作为一名记者，
      多亏了和具备相同专业背景的教师一起准备，让我在和客户的访谈中游刃有余。
    </>,
    <>
      就專業性而言，Ringle的任課教師實在無人能及，我目前是一名記者，多虧與相同領域的任課教師一起準備，讓我在以英語採訪客戶時得到不錯的成果。
    </>,
    <>
      専門性はRingleのチューターに追いつけません。現職の記者として働きながら、同じ分野の
      チューターと準備したおかげで、クライアントとの英語会議で成果をあげました。
    </>,
    <>
      Các gia sư Ringle có chuyên môn sâu trong lĩnh vực học tập hoặc nghề nghiệp của họ. Tôi từng gặp một gia sư hiện
      đang làm phóng viên, và nhờ những buổi học đó, tôi xuất sắc vượt qua buổi phỏng vấn tiếng Anh với một khách hàng
      quan trọng.
    </>,
  ],
  tutor_review_4_writer: [
    <>한국경제신문, Lee</>,
    <>Lee, The Korea Economic Daily</>,
    's_landing_tutors.tutor_review_4_writer',
    <>韩国经济新闻, Lee</>,
    <>韓國經濟新聞，Lee</>,
    <>韓国経済新聞、Lee</>,
    <>Lee, báo The Korea Economic Daily</>,
  ],
  free_start: [
    <>부담없이 시작하세요</>,
    <>Start learning!</>,
    's_landing_tutors.free_start',
    <>赶快体验Ringle吧！</>,
    <>趕快體驗Ringle吧！</>,
    <>気軽に始めてみましょう。。</>,
    <>Học thử miễn phí</>,
  ],
  free_start_btn: [
    <>무료 체험 수업 신청하기</>,
    <>Sign up for a free trial</>,
    's_landing_tutors.free_start_btn',
    <>申请免费体验课程</>,
    <>申請免費體驗課程</>,
    <>無料体験レッスンを予約する</>,
    <>Đăng ký học thử miễn phí</>,
  ],
}
export const s_topic = {
  you_disappointed: [
    `Ringle Materials`,
    ``,
    's_topic.you_disappointed',
    `Ringle Materials`,
    `Ringle Materials`,
    `Ringle Materials`,
    ``,
  ],
  ringle_high_quality: [
    <>
      일상 대화부터 시사, <br />
      실전 비즈니스 영어까지
    </>,
    <>
      Lead a discussion
      <br />
      on a real-world topic
    </>,
    's_topic.ringle_high_quality',
    <>
      从日常生活对话, <br />
      到实战商务英语
    </>,
    <>
      從日常對話到時事、
      <br />
      實戰商務英語
    </>,
    <>
      日常会話から時事、
      <br />
      実践ビジネス英語まで
    </>,
    <>
      Thảo luận
      <br />
      chủ đề đời thực
    </>,
  ],
  ringle_various_materials: [
    <>
      당신이 영어로 <Br md down />
      말하고 싶은 이야기
    </>,
    <>Learning materials for real-world speaking</>,
    's_topic.ringle_various_materials',
    <>
      您渴望使用英文
      <Br md down />
      表达的全部内容
    </>,
    <>
      您渴望使用英文
      <Br md down />
      表達的內容
    </>,
    <>本物の英語を学べる教材</>,
    <>Tài liệu học cho bối cảnh giao tiếp hằng ngày</>,
  ],
  not_only_english: [
    <>
      매일 마주하는 상황과 연결되는 <Br md down />
      쓸모있는 대화에 집중합니다
    </>,
    ``,
    's_topic.not_only_english',
    <>
      专注于每日所见所闻的
      <Br md down />
      实用性对话内容
    </>,
    <>
      專注於每日所見所聞的
      <Br md down />
      實用性對話內容
    </>,
    <>日常会話に役立つヒントが盛りだくさん</>,
    ``,
  ],
  various_materials: [
    <>
      비즈니스, 정치/경제, 문화 등
      <br />
      <Div spanTag textPrimary>
        +1,000개의 다양한 교재
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        1,000+ Materials
      </Div>
      <br />
      on casual to advanced topics
    </>,
    's_topic.various_materials',
    <>
      商业, 政治/经济, 文化等
      <br />
      <Div spanTag textPrimary>
        1,000种以上的多元化教材
      </Div>
    </>,
    <>
      商業、政治／經濟、文化等
      <br />
      <Div spanTag textPrimary>
        1,000多種教材
      </Div>
    </>,
    <>
      ビジネス、政治・経済、文化など
      <br />
      <Div spanTag textPrimary>
        1,000を超える多様な教材
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        1,000+ tài liệu
      </Div>
      <br />
      với chủ đề từ cơ bản đến nâng cao
    </>,
  ],
  topics_example: [``, ``, 's_topic.topics_example', ``, ``, ``, ``],
  from_MBA: [
    <>
      <Div spanTag textPrimary>
        Top MBA 출신 집필진과 튜터들
      </Div>
      의
      <br />
      오리지널 교재
    </>,
    <>
      <Div spanTag textPrimary>
        Expert writers
      </Div>
      <br />
      from top universities & MBA
    </>,
    's_topic.from_MBA',
    <>
      <Div spanTag textPrimary>
        Top MBA的编纂人员及教师们共同打造
      </Div>
      的
      <br />
      原版教材
    </>,
    <>
      <Div spanTag textPrimary>
        Top MBA的執筆團隊及任課教師們
      </Div>
      的
      <br />
      原創教材
    </>,
    <>
      <Div spanTag textPrimary>
        MBAトップ校出身のライターとチューターたち
      </Div>
      監修の
      <br />
      オリジナル教材
    </>,
    <>
      <Div spanTag textPrimary>
        Người viết chuyên nghiệp
      </Div>
      <br />
      từ các trường Đại học & MBA hàng đầu
    </>,
  ],
  MBA_ivy_league: [``, ``, 's_topic.MBA_ivy_league', ``, ``, ``, ``],
  recent_trend: [
    <>
      매주 업데이트되는
      <br />
      <Div spanTag textPrimary>
        최신 콘텐츠
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        New content
      </Div>
      <br />
      released every week
    </>,
    's_topic.recent_trend',
    <>
      每周更新的
      <br />
      <Div spanTag textPrimary>
        全新内容
      </Div>
    </>,
    <>
      每週更新的
      <br />
      <Div spanTag textPrimary>
        最新內容
      </Div>
    </>,
    <>
      毎週アップデートされる
      <br />
      <Div spanTag textPrimary>
        最新コンテンツ
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        Nội dung mới
      </Div>
      <br />
      mỗi tuần
    </>,
  ],
  weekly_update: [``, ``, 's_topic.weekly_update', ``, ``, ``, ``],
  ringle_material_systematic: [
    `영어 대화에 최적화된 구성`,
    `4 Parts to guide meaningful conversations`,
    's_topic.ringle_material_systematic',
    `最适合英语对话的结构`,
    `英語對話最佳架構`,
    `英会話に最適化された教材構成`,
    `4 phần giúp dẫn dắt cuộc hội thoại ý nghĩa`,
  ],
  topic_think: [
    <Fragment>
      생각을 자극하는 본질적인 질문과 <Br md down />
      시청각 자료로 깊이 있는 대화를 이끕니다
    </Fragment>,
    ``,
    's_topic.topic_think',
    <Fragment>
      用激发思维的本质性提问及
      <Br md down />
      视听资料引发深度对话
    </Fragment>,
    <Fragment>
      用激發思維的問題及
      <Br md down />
      視聽資料引導深度對話
    </Fragment>,
    <Fragment>
      思考を刺激する本質的な質問と <Br md down />
      視聴覚資料で深みのある会話をリードします。
    </Fragment>,
    ``,
  ],
  insight: [`인사이트`, `Insight`, 's_topic.insight', `正文`, `正文`, `インサイト`, `Insight`],
  insight_detail: [
    `주제의 핵심 내용 요약 (국문/영문 제공)`,
    <>Summary article on each topic written by Ringle</>,
    's_topic.insight_detail',
    `主题核心内容摘要(提供英文)`,
    `主題的核心內容摘要（提供英文）`,
    `各テーマの要約`,
    <>Các bài báo tóm tắt ở mỗi chủ để viết bởi chính Ringle</>,
  ],
  debate_question: [
    `토론 질문`,
    `Discussion Questions`,
    's_topic.debate_question',
    `讨论问题`,
    `討論問題`,
    `質問`,
    `Câu hỏi thảo luận`,
  ],
  prepare_logical_answer: [
    `심도있는 대화를 이끄는 다양한 질문들`,
    `Ranging in light-hearted to deeper-dive questions`,
    's_topic.prepare_logical_answer',
    `引发深入对话的问题`,
    `引導深度對話的各種問題`,
    `深みのある会話をリードする様々な質問`,
    `Từ các câu hỏi cơ bản đến chuyên sâu`,
  ],
  related_video: [
    `주제 관련 영상/기사`,
    `Videos & News`,
    's_topic.related_video',
    `主题相关视频/新闻报道`,
    `影片／報導`,
    `動画＆ニュース記事`,
    `Videos & Tin tức`,
  ],
  high_understanding: [
    `다양한 배경지식으로 주제 이해도 향상`,
    `Additional resources for a full download on a topic`,
    's_topic.high_understanding',
    `根据多样的背景知识加深对内容的理解`,
    `透過豐富的背景知識提升主題的理解度`,
    `多様な予備知識でテーマの理解度向上`,
    `Tài liệu bổ sung miễn phí liên quan đến chủ đề`,
  ],
  key_expression: [
    `핵심 표현`,
    `Key expressions`,
    's_topic.key_expression',
    `核心生詞`,
    `核心單字`,
    `キーフレーズ`,
    `Mẫu câu chính`,
  ],
  practice_key_expression: [
    `실전에 바로 쓸 수 있는 유용한 영어 표현 제공`,
    <>Idioms and sayings to immediately boost fluency</>,
    's_topic.practice_key_expression',
    `提供真正实用的英语表达方式`,
    `提供可立即用於實戰的實用英語用法`,
    `すぐに使える役立つ英語表現`,
    <>Các thành ngữ và mẫu câu giúp bạn nói trôi chảy hơn,</>,
  ],
  register_and_check_it_out: [
    `당신이 말하고 싶은 주제를 찾아보세요`,
    `Talk about what you care about`,
    's_topic.register_and_check_it_out',
    `请寻找您希望进行的会话主题`,
    `尋找您想說的主題吧！`,
    `話してみたいテーマを見つけよう`,
    `Thảo luận chủ đề bạn thích`,
  ],
  find_out_more_material: [
    <>1,000+ 개의 교재 살펴보기</>,
    <>View 1,000+ lesson materials</>,
    's_topic.find_out_more_material',
    <>查看1,000种以上的教材</>,
    <>查看1,000多種教材</>,
    <>1,000種類以上の教材を見る</>,
    <>Xem 1,000+ tài liệu cho buổi học</>,
  ],
  ringle_because_material: [
    `링글 교재는 다릅니다`,
    <>Ringle materials are unlike others</>,
    's_topic.ringle_because_material',
    `Ringle的教材与众不同`,
    `Ringle的教材與眾不同`,
    `Ringleの教材は他とは違います。`,
    <>Tài liệu của Ringle là duy nhất</>,
  ],
  topic_review_1: [
    `트렌드에 잘 맞는 최신 교재를 직접 골라서 제가 관심 있는 주제로 대화할 수 있어서 정말 좋아요. 영어를 배우면서 동시에 시사를 배울 수도 있어서 외국 팀원들과 대화하기가 훨씬 수월해졌어요.`,
    <>
      I love that I can choose materials on a topic I'm interested in. After practicing speaking English on current
      events, I found it a lot easier to hold conversations with my colleagues at work.
    </>,
    's_topic.topic_review_1',
    `能够直接选择符合当前趋势的教材主题进行对话, 这一点真的很满意。一边学习英文的同时又能了解时事, 让我和外籍教师间的对话变得容易了很多。`,
    `可以親自選擇符合趨勢的最新教材，以我感興趣的主題進行對話，真的很棒。一邊學習英文的同時一邊了解時事，讓我和外籍組員之間的對話變得容易許多。`,
    `トレンドに合う最新教材を直接選び、自分の興味のあるテーマで話せるのは本当にいいです。英語を学びながら時事も学ぶことができるので、外国のチームメンバーたちとの話し合いがかなり円滑になりました。`,
    <>
      Tôi thích được chọn tài liệu dựa trên chủ đề yêu thích. Sau khi luyện nói về những tin tức thời sự, tôi cảm thấy
      dễ dàng hơn trong việc nói chuyện với các đồng nghiệp tại công ty.
    </>,
  ],
  topic_review_1_writer: [
    `HBS Class of 2019 , Dylan`,
    `Dylan, HBS Class of 2019`,
    's_topic.topic_review_1_writer',
    `HBS Class of 2019, Dylan`,
    `HBS Class of 2019，Dylan`,
    `HBS 2019年卒、Dylan`,
    `Dylan, Trường Kinh doanh Harvard khoá 2019`,
  ],
  topic_review_2: [
    `링글 교재는 바쁜 직장인들이 소화하기 좋은 형식으로 구성되어 있어요. 시사 전문 잡지보다는 더 쉽게 읽히고 회사에서 보는 보고서처럼 요점이 잘 요약되어 있어서 부담 없이 읽을 수 있어요.`,
    `Ringle materials are easy to digest. I find them more like a corporate report - they are easier to read than most investigative magazine pieces and provides clear concise summaries on each topic.`,
    's_topic.topic_review_2',
    `Ringle教材针对日常工作繁忙的上班族采用便于理解的形式。比起时事杂志, 阅读更加简单, 同时参考公司报告的形式添加有要点概述, 更加减轻了学习负担。`,
    `Ringle教材以繁忙的上班族容易消化的形式編撰。比時事專業雜誌更容易閱讀，就如公司的報告書一樣，重點簡潔二要，閱讀起來更無負擔。`,
    `Ringleの教材は、忙しい社会人が使いやすいよう構成されています。時事専門誌よりもっと手軽に読めて、会社で見る報告書のように要点がしっかりまとめられているので簡単に読むことができます。`,
    `Tài liệu của Ringle dễ tiếp thu. Tôi thấy nó giống như báo cáo ở các tập đoàn - dễ hiểu hơn các bài phóng sự trên tạp chí và cung cấp những tóm tắt súc tích, chi tiết cho từng chủ đề.`,
  ],
  topic_review_2_writer: [
    `구글 시니어 엔지니어, Daniel`,
    `Daniel, Senior Engineer at Google`,
    's_topic.topic_review_2_writer',
    `谷歌高级工程师, Daniel`,
    `Google資深工程師，Daniel`,
    `Google シニアエンジニア、Daniel`,
    `Daniel, Senior Engineer tại Google`,
  ],
  topic_review_3: [
    `시의성 있는 비즈니스 관련 주제이고 추가 영상/기사 자료도 같이 제공되어 다양한 방식으로 공부할 수 있었습니다. 그만큼 얘기할 게 많아서 토론하기에 적합한 교재라고 생각합니다.`,
    `I appreciate that Ringle constantly uploads materials on current events, which also include additional resources like videos and news articles. It gives me plenty to discuss with my tutors.`,
    's_topic.topic_review_3',
    `具备适宜性的商业主题, 同时提供视频/新闻等附加资料, 通过多样化的方式进行学习。能够充分引发对话的主题，我认为教材内容非常适用于讨论。`,
    `不僅是合時宜的商務主題，同時提供額外影片／報導資料，讓我可以透過各種方式學習。因此，可討論的話題也更多，我認為是很適合討論的教材。`,
    `時事的なビジネス関連のテーマで追加の動画やニュース記事資料も一緒に提供されるので、さまざまな角度から勉強することができました。それだけ話す材料が増えるので、ディスカッションに適した教材だと思います。`,
    `Tôi đánh giá cao việc Ringle liên tục cập nhật các tài liệu về những sự kiện đang diễn ra, bao gồm các video và tin tức. Việc này giúp tôi có nhiều thông tin để thảo luận cùng các gia sư của mình.`,
  ],
  topic_review_3_writer: [
    `아마존 인사팀, Jimmy`,
    `Jimmy, HR at Amazon`,
    's_topic.topic_review_3_writer',
    `亚马逊人事部, Jimmy`,
    `亞馬遜人事組，Jimmy`,
    `Amazon 人事チーム、Jimmy`,
    `Jimmy, HR tại Amazon`,
  ],
  topic_review_4: [
    `교재마다 양질의 질문이 준비되어 있어 튜터와 심도있는 대화를 나누는 데 도움이 됐습니다. 질문의 난이도와 목적도 잘 배치되어 있어 효과적으로 시간을 쓸 수 있었습니다.`,
    `Each material comes with a set of high-quality discussion questions, which helps me have a meaningful conversation with my tutors. Since the questions are deliberately ordered by purpose and difficulty level, I can also hand-pick the questions that are best for me and use my lesson time to the fullest.`,
    's_topic.topic_review_4',
    `每个教材都准备有高质量的提问, 有助于和教师进行深度对话。问题的难易度也进行了合理性搭配, 让课程时间能够更为有效的利用。`,
    `每個教材均準備了優質的問題，有助於和任課教師進行深度對話。問題設有不同的難度與目的，可以有效地利用時間。`,
    `教材ごとに良質な質問が用意されており、チューターと深みのある会話をするのに役立ちました。質問の難易度と目的もしっかり設定されていて、効果的に時間を使うことができました。`,
    `Mỗi tài liệu đi kèm với bộ câu hỏi thảo luận chất lượng giúp tôi trao đổi tốt hơn với gia sư của mình. Những câu hỏi được sắp xếp theo mục đích nhất định và theo độ khó, tôi có thể dễ dàng chọn ra câu hỏi phù hợp nhất cho mình để sử dụng thời gian học một cách tối ưu nhất`,
  ],
  topic_review_4_writer: [
    `SAP 사업전략, Sam`,
    `Sam, Business strategies at SAP`,
    's_topic.topic_review_4_writer',
    `SAP产业战略, Sam`,
    `SAP事業策略，Sam`,
    `SAP 事業戦略、Sam`,
    `Sam, Business strategies tại SAP`,
  ],
}
export const s_b2b = {
  better_english_for_company: [
    <>
      임직원에게
      <br />더 좋은 영어교육을
    </>,
    <>
      Globalize your team's
      <br />
      communication
    </>,
    's_b2b.better_english_for_company',
    <>
      提供给职员们
      <br />
      更好的英文教育
    </>,
    <>
      讓職員接受
      <br />
      更好的英語教育
    </>,
    <>
      社内の
      <br />
      より優れた英語教育を
    </>,
    <>
      Toàn cầu hoá
      <br />
      giao tiếp của team
    </>,
  ],
  experience_ringle: [
    <>국내외 스타트업부터 외국계, 대기업까지</>,
    <></>,
    's_b2b.experience_ringle',
    <>从国内外初创公司, 到国际化大型企业</>,
    <>從國內外新創公司至外資公司、大型企業</>,
    <>国内外のスタートアップ企業から外資系、大企業まで</>,
    <></>,
  ],
  ask_b2b: [
    `기업 수강 문의하기`,
    `Send us an inquiry`,
    's_b2b.ask_b2b',
    `咨询企业课程`,
    `諮詢企業課程`,
    `法人向けサービスのお問い合わせ`,
    `Gửi yêu cầu cho chúng tôi`,
  ],
  oneclick_system: [
    <>원클릭 수업 예약</>,
    ``,
    's_b2b.oneclick_system',
    <>预约一键式课程</>,
    <>預約一鍵式課程</>,
    <>ワンクリックレッスン予約</>,
    ``,
  ],
  oneclick_easy: [
    <>
      연중무휴로 원하는 일정의 수업을
      <br />
      원클릭으로 간편하게 예약
    </>,
    <></>,
    's_b2b.oneclick_easy',
    <>
      全年无休, 选择您希望的日期
      <br />
      一键式便捷预约
    </>,
    <>
      我們全年無休，您可一鍵預約
      <br />
      希望日程的課程
    </>,
    <>
      年中無休で好きな日程のレッスンを
      <br />
      ワンクリックで予約可能
    </>,
    <></>,
  ],
  case_materials: [
    `실무중심 교재`,
    ``,
    's_b2b.case_materials',
    `以实务为主的教材`,
    `以實務為主的教材`,
    `実務中心の教材`,
    ``,
  ],
  written_by_MBA: [
    <Fragment>
      최신 시사부터 비즈니스 영어까지
      <br />
      MBA출신 집필진의 퀄리티 높은 교재
    </Fragment>,
    <Fragment></Fragment>,
    's_b2b.written_by_MBA',
    <Fragment>
      从最新时事到商务英语
      <br />
      出身MBA编纂人员全心打造的高质量教材
    </Fragment>,
    <Fragment>
      從最新時事到商務英語
      <br />
      MBA出身的執筆團隊打造的高品質教材
    </Fragment>,
    <Fragment>
      最新時事からビジネス英語まで
      <br />
      MBA出身ライター監修の高品質教材
    </Fragment>,
    <Fragment></Fragment>,
  ],
  top_20_tutors: [
    `Top30 명문대 출신 튜터`,
    ``,
    's_b2b.top_20_tutors',
    `出身Top30名校的教师`,
    `來自Top 30名校的任課教師`,
    `TOP30の名門大学出身のチューター`,
    ``,
  ],
  choose_tutors: [
    <>
      전공/직장경력을 확인하고
      <br />
      직접 선택하는 튜터
    </>,
    <></>,
    's_b2b.choose_tutors',
    <>
      对专业/职场经历进行确认
      <br />
      直接选择教师
    </>,
    <>
      確認主修／工作經驗後
      <br />
      親自選擇任課教師
    </>,
    <>
      専攻・経歴から
      <br />
      お好みのチューターを選べる
    </>,
    <></>,
  ],
  manage_study: [`학습관리`, ``, 's_b2b.manage_study', `学习管理`, `學習管理`, `学習サポート`, ``],
  b2b_team_manage_study: [
    `B2B 전담팀이 체계적으로 관리합니다`,
    `A dedicated team and portal for B2B clients`,
    's_b2b.b2b_team_manage_study',
    `由B2B专职部门进行系统管理`,
    `由B2B專職團隊進行系統管理`,
    `B2B専門チームがワンストップで対応します。`,
    `Đội ngũ tận tình và cổng thông tin riêng cho khách hàng B2B`,
  ],
  fit_b2b_service: [
    <>
      프로그램 도입 컨설팅부터 학습종료 결과 보고서까지
      <Br md up />
      &nbsp;최적화된 기업서비스를 제공합니다
    </>,
    <>We'll be with you every step of the way, from implementation to reporting</>,
    's_b2b.fit_b2b_service',
    <>
      从项目引进咨询到学习结果报告书
      <Br md up />
      &nbsp;提供最优化的企业服务
    </>,
    <>
      從引進計畫諮詢至學習結果報告書
      <Br md up />
      &nbsp;提供最合適的企業服務
    </>,
    <>
      プログラム導入に関するご相談から学習成果報告書まで
      <Br md up />
      &nbsp;一括で法人向けサービスを提供します。
    </>,
    <>Chúng tôi đồng hành cùng bạn, từ việc triển khai đến báo cáo</>,
  ],
  ringle_b2b_companies: [
    `링글과 함께하는 B2B 제휴사`,
    `Ringle's B2B Partners`,
    's_b2b.ringle_b2b_companies',
    `Ringle携手的B2B合作企业`,
    `Ringle攜手的B2B合作企業`,
    `RingleのB2B提携企業`,
    `Đối tác B2B của Ringle`,
  ],
  name: [`이름`, `Name`, 's_b2b.name', `姓名`, `姓名`, `お名前`, `Tên`],
  enter_name: [
    `이름을 입력해주세요`,
    `Enter name`,
    's_b2b.enter_name',
    `请输入姓名`,
    `請輸入姓名`,
    `お名前を入力してください。`,
    `Vui lòng nhập tên`,
  ],
  email: [`이메일`, `Email`, 's_b2b.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_email: [
    `이메일을 입력해주세요`,
    `Enter email`,
    's_b2b.enter_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập email`,
  ],
  ask_partner: [
    `제휴문의`,
    `How can we help?`,
    's_b2b.ask_partner',
    `合作咨询`,
    `合作諮詢`,
    `お問い合わせ内容`,
    `Chúng tôi có thể giúp gì cho bạn?`,
  ],
  enter_question: [
    `기업수강 / 제휴할인 등 제휴형태를 포함한 문의사항을 남겨주세요`,
    `Leave us an inquiry about our B2B partnerships and discounts.`,
    's_b2b.enter_question',
    `请留下企业课程/合作优惠等有关合作形态内容的咨询事项`,
    `請留下企業課程／合作優惠等包含合作型態的諮詢事項`,
    `法人契約・割引など、ご希望の内容を明記の上、お問い合わせ内容を入力してください。`,
    `Để lại yêu cầu cho chúng tôi về chương trình đối tác B2B và ưu đãi.`,
  ],
  start_with_ringle: [
    <>
      임직원 영어교육
      <br />
      학습효과를 보장하는 링글과 시작하세요
    </>,
    <>Improve your team's English communications with Ringle</>,
    's_b2b.start_with_ringle',
    <>
      职员英语教育
      <br />
      学习效果能够保障, 携手Ringle!
    </>,
    <>
      職員英語教育
      <br />
      與保障學習效果的Ringle一起開始
    </>,
    <>
      社内のより優れた英語教育を
      <br />
      確実な学習成果をお約束するRingleにお任せください。
    </>,
    <>Cải thiện kỹ năng giao tiếp tiếng Anh của đội ngũ với Ringle</>,
  ],
  one_for_free: [
    <>
      문의를 남겨주시면 <Br md down />
      기업 담당자용 1회 정규수업권을 제공합니다
    </>,
    <>Leave an inquiry and get a free lesson!</>,
    's_b2b.one_for_free',
    <>
      留下咨询事项, 将提供
      <Br md down />
      企业负责人1次正规课程券
    </>,
    <>
      留下諮詢事項時，將提供
      <Br md down />
      企業負責人1次正規課程券
    </>,
    <>
      お問い合わせいただいたご担当者には
      <Br md down />
      レギュラーレッスンチケット1枚を差し上げます。
    </>,
    <>Để lại yêu cầu và nhận một buổi học miễn phí!</>,
  ],
  ringle_b2b_program: [
    `링글 B2B 프로그램`,
    ``,
    's_b2b.ringle_b2b_program',
    `Ringle B2B项目`,
    `Ringle B2B計畫`,
    `Ringle B2Bプログラム`,
    ``,
  ],
  serve_high_quality_education: [
    <>
      시공간의 제약 없이
      <br />
      수준 높은 영어 교육을 제공합니다
    </>,
    <>Sophisticated lessons and a specialized platform team leaders</>,
    's_b2b.serve_high_quality_education',
    <>
      不受时间空间限制
      <br />
      致力于提供给您高水平的英语教育
    </>,
    <>
      為您提供不受時空限制的
      <br />
      高水準英語教育
    </>,
    <>
      いつでもどこでも
      <br />
      レベルの高い英語教育を提供します。
    </>,
    <>Buổi học thực tiễn và nền tảng chuyên môn cho lãnh đạo</>,
  ],
  propose_b2b: [`B2B 제안`, `B2B Contract`, 's_b2b.propose_b2b', `B2B提案`, `B2B提案`, `B2B契約`, `Hợp đồng B2B`],
  run_b2b: [`B2B 운영`, `B2B Operation`, 's_b2b.run_b2b', `B2B运营`, `B2B營運`, `B2B管理`, `Vận hành B2B`],
  manage_b2b: [`B2B 관리`, `B2B Management`, 's_b2b.manage_b2b', `B2B管理`, `B2B管理`, `B2Bサポート`, `Quản lý B2B`],
  no_limit_proposal: [
    <>
      최소 수강인원 제한없이
      <br />
      기업수강/제휴할인 등<br />
      예산과 형식에 따라 맞춤 제안
    </>,
    <>
      B2B contracts service an unlimited number of students in each organization and can be further customized to fit
      your budget
    </>,
    's_b2b.no_limit_proposal',
    <>
      无最低学员数限制
      <br />
      企业课程/合作优惠等
      <br />
      将根据公司预算及形式提供定制型方案
    </>,
    <>
      無最低學生限制
      <br />
      企業課程／合作優惠等
      <br />
      依據預算及形式，提供客製化方案
    </>,
    <>
      無制限の受講者数
      <br />
      法人契約/提携割引など
      <br />
      予算やニーズに合わせてご提案
    </>,
    <>
      Hợp đồng B2B cung cấp không giới hạn số lượng người học cho công ty bạn và nhiều lợi ích khác phù hợp với ngân
      sách đề ra
    </>,
  ],
  download_excel: [
    <>
      HR 맞춤 관리 페이지 제공
      <br />
      실시간 학습현황과
      <br />
      엑셀파일 다운로드 가능
    </>,
    <>Ringle's B2B platform allows HR and managers to view and download team's lesson progress</>,
    's_b2b.download_excel',
    <>
      提供HR定制管理页面
      <br />
      可实时下载学习现状
      <br />
      及相关Excel文件
    </>,
    <>
      提供人資客製化管理頁面
      <br />
      可即時下載學習現況及
      <br />
      Excel檔
    </>,
    <>
      人事部専用の管理ページを提供
      <br />
      リアルタイムで受講社員の学習状況の確認、
      <br />
      エクセルファイルダウンロード可能
    </>,
    <>Nền tảng B2B cho phép Nhân sự và Quản lý xem và tải xuống quá trình học của thành viên</>,
  ],
  b2b_one_on_one: [
    <>
      B2B 수강생 전원 1:1 상담
      <br />
      알림톡/푸시/이메일 채널
      <br />
      밀착관리로 수업 지원
    </>,
    <>We provide 1:1 advising to all B2B customers to further guide their English learning journey</>,
    's_b2b.b2b_one_on_one',
    <>
      B2B学员全部1:1咨询
      <br />
      通过提醒/推送/电子邮件等形式
      <br />
      对课程进行密集式辅助管理
    </>,
    <>
      B2B學生全員1:1諮詢
      <br />
      透過訊息通知／推播通知／電子信箱等形式
      <br />
      對課程進行密集式管理
    </>,
    <>
      B2B受講者向けのマンツーマン相談受付け
      <br />
      チャット/プッシュ通知/メール
      <br />
      丁寧に寄り添う英語学習サポート
    </>,
    <>Chúng tôi cung cấp buổi tư vấn 1:1 cho khách hàng B2B giúp định hướng việc học tiếng Anh</>,
  ],
  submit: [`제출하기`, `Submit`, 's_b2b.submit', `提交`, `提交`, `送信する`, `Gửi`],
  total_number_tutees: [
    `누적 수강생`,
    `Students`,
    's_b2b.total_number_tutees',
    `累计学员`,
    `累積學生`,
    `累計受講生数`,
    `Học sinh`,
  ],
  partners: [`제휴사`, `Partners`, 's_b2b.partners', `合作企业`, `合作公司`, `提携企業数`, `Đối tác`],
  satisfaction: [
    `만족도`,
    `Customer satisfaction`,
    's_b2b.satisfaction',
    `满意度`,
    `滿意度`,
    `満足度`,
    `Mức độ hài lòng của khách hàng`,
  ],
  please_fill_in_blank: [
    `성함, 이메일과 제휴문의를 적어주세요.`,
    `Where is this`,
    's_b2b.please_fill_in_blank',
    `请填写姓名, 电子邮箱及合作咨询事项。`,
    `請填寫姓名、電子信箱及合作諮詢事項。`,
    `氏名、メールアドレス、提携に関するお問い合わせを入力してください。`,
    `Để lại yêu cầu cho chúng tôi về chương trình đối tác B2B và ưu đãi.`,
  ],
  phone: [`전화번호`, `Phone`, 's_b2b.phone', `电话号码`, `電話號碼`, `電話番号`, `số điện thoại`],
  enter_phone: [
    `전화번호를 입력해주세요`,
    `Enter your mobile number`,
    's_b2b.enter_phone',
    `请输入电话号码。`,
    `請輸入電話號碼。`,
    `電話番号を入力してください。`,
    `Vui lòng nhập số điện thoại của bạn.`,
  ],
}
export const s_process = {
  simple_ringle_usage: [
    `간단한 링글 사용법`,
    `Ringle lesson in 7 steps`,
    's_process.simple_ringle_usage',
    `Ringle便捷使用方法`,
    `簡單的Ringle使用方法`,
    `Ringleの受講の流れ`,
    `Tham gia buổi học của Ringle với 7 bước`,
  ],
  select_date: [
    `일정 선택`,
    `Select Date & Time`,
    's_process.select_date',
    `选择日期`,
    `選擇日程`,
    `日時を選択`,
    `Chọn Ngày & Giờ`,
  ],
  select_tutor: [
    `튜터 선택`,
    `Select a tutor`,
    's_process.select_tutor',
    `选择教师`,
    `選擇任課教師`,
    `チューターを選択`,
    `Chọn gia sư`,
  ],
  desc_select_tutor: [
    <>
      전공/학교/경력/발음 등을 확인하고
      <br />
      <Div spanTag textPrimary>
        나에게 꼭 맞는 튜터
      </Div>
      와 수업해보세요.
    </>,
    `Choose a tutor whose expertise matches your interests.`,
    's_process.desc_select_tutor',
    <>
      对专业/学校/经历/发音等内容进行确认后
      <br />
      <Div spanTag textPrimary>
        请选择适合的
      </Div>
      教师开始您的课程。
    </>,
    <>
      確認主修／學校／經歷／發音等，
      <br />與
      <Div spanTag textPrimary>
        適合自己的任課教師
      </Div>
      上課吧！
    </>,
    <>
      専攻・出身校・経歴・アクセントなどから
      <br />
      <Div spanTag textPrimary>
        自分にぴったり合うチューター
      </Div>
      を見つけましょう。
    </>,
    `Lựa chọn gia sư với chuyên môn thuộc lĩnh vực bạn yêu thích.`,
  ],
  select_material: [
    `교재 선택`,
    `Select materials`,
    's_process.select_material',
    `选择教材`,
    `選擇教材`,
    `教材を選択`,
    `Chọn tài liệu`,
  ],
  desc_select_material: [
    `원하는 토픽을 자유롭게 선택하세요. 자료를 직접 업로드할 수 있는 자유주제 수업도 가능합니다.`,
    <>
      Pick out materials on a topic of your interest or have a "Free Topic" discussion.
      <br />
      Or choose Free Topic or upload your own materials ,
    </>,
    's_process.desc_select_material',
    `请自由选择希望的主题, 也可直接上传资料进行主题自选课程`,
    `請自由選擇希望的主題，也可自行上傳資料的自由主題課程。`,
    `好きなトピックを自由に選んでください。資料を直接アップロードすることができる自由テーマレッスンも可能です。`,
    <>
      Chọn tài liệu liên quan đến chủ đề yêu thích hoặc thảo luận "Tự do".
      <br />
      Hoặc chọn Chủ đề Tự do hoặc đăng tải tài liệu của bạn ,
    </>,
  ],
  select_question: [
    `교재 읽고, 질문 선택`,
    `Read the materials and select questions`,
    's_process.select_question',
    `阅读教材, 选择问题`,
    `閱讀教材、選擇問題`,
    `教材を読み、レッスン質問を選択`,
    `Đọc tài liệu và chọn các câu hỏi`,
  ],
  desc_select_question: [
    <>
      <Div spanTag textPrimary>
        교재를 예습
      </Div>
      하고, 수업에서 다룰 <Br md down />
      <Div spanTag textPrimary>
        질문을 선택
      </Div>
      해 답변을 준비해보세요.
    </>,
    `Mark questions you'd like to focus on during the lesson.`,
    's_process.desc_select_question',
    <>
      <Div spanTag textPrimary>
        请预习教材
      </Div>
      , 选择课程中进行的
      <Br md down />
      <Div spanTag textPrimary>
        问题
      </Div>
      然后准备答案。
    </>,
    <>
      請
      <Div spanTag textPrimary>
        預習教材
      </Div>
      ，選擇上課時要討論的
      <Br md down />
      <Div spanTag textPrimary>
        問題
      </Div>
      後，準備回答。
    </>,
    <>
      <Div spanTag textPrimary>
        教材を予習
      </Div>
      しレッスンで扱う <Br md down />
      <Div spanTag textPrimary>
        質問を選んだら、
      </Div>
      回答を作成してみましょう。
    </>,
    `Đánh dấu vào câu hỏi bạn muốn tập trung trong buổi học.`,
  ],
  require_correction: [
    `수업 방식 설정`,
    `Set Lesson Style`,
    's_process.require_correction',
    `设定课程形式`,
    `上課方式設定`,
    `レッスン方式の設定`,
    `Chọn Hình thức học`,
  ],
  desc_require_correction: [
    <>
      토론 중심/교정 중심 등{' '}
      <Div spanTag textPrimary>
        맞춤 수업 방식을 설정
      </Div>
      하세요.
      <Br md up /> "제 영어를 비즈니스 영어로 바꿔주세요." 등{' '}
      <Div spanTag textPrimary>
        상세 요청사항
      </Div>{' '}
      작성도 가능합니다.
    </>,
    `Choose either correction or discussion-focused lesson and leave other requests.`,
    's_process.desc_require_correction',
    <>
      请选择以讨论为主/纠正为主等
      <Div spanTag textPrimary>
        多种定制型课程
      </Div>
      形式。
      <Br md up />
      也可提出"请将我的英语变化成商务英语。"等
      <Div spanTag textPrimary>
        符合自身特点的
      </Div>{' '}
      具体要求。
    </>,
    <>
      請設定以討論／糾正為主等的
      <Div spanTag textPrimary>
        客製化上課方式
      </Div>
      。
      <Br md up />
      也可填寫「請將我的英語變成商務英語。」等
      <Div spanTag textPrimary>
        具體要求事項
      </Div>
      。{' '}
    </>,
    <>
      ディスカッション中心/添削中心等
      <Div spanTag textPrimary>
        ご希望レッスン方式を設定
      </Div>
      します。
      <Br md up /> 「私の英語をビジネス英語に直して」といった
      <Div spanTag textPrimary>
        追加リクエスト事項
      </Div>
      にも対応しています。
    </>,
    `Lựa chọn tập trung sửa lỗi hoặc tập trung thảo luận và những yêu cầu khác.`,
  ],
  enter_lesson: [
    `수업 입장`,
    `Join the lesson`,
    's_process.enter_lesson',
    `课程入场`,
    `進入課程`,
    `レッスン開始`,
    `Tham gia buổi học`,
  ],
  desc_enter_lesson: [
    <>
      수업 입장은{' '}
      <Div spanTag textPrimary>
        [수업 입장]
      </Div>{' '}
      버튼을 통해 수업 시작 15분 전부터 가능합니다.
      <br />한 화면으로 교재/튜터화면/실시간 교정 모두 확인 가능한 PC 사용을 권장드립니다.
    </>,
    <>
      Log in to Ringle to view upcoming lessons and enter lesson on time.
      <br />
      You'll see your tutor, materials, and discussion questions all on the same screen.
    </>,
    's_process.desc_enter_lesson',
    <>
      点击
      <Div spanTag textPrimary>
        [课程入场]
      </Div>
      按钮, 可从课程开始前15分钟进场准备。
      <br />
      建议使用可在同一画面对教材/教师画面/实时纠正进行查看的PC终端。
    </>,
    <>
      透過
      <Div spanTag textPrimary>
        [進入課程]
      </Div>
      按鈕，可於課程開始15分鐘前進入課程。
      <br />
      建議使用電腦於同一個畫面確認教材／任課教師畫面／即時糾正。
    </>,
    <>
      <Div spanTag textPrimary>
        [レッスン開始]
      </Div>
      ボタンでレッスン15分前から入室可能です。
      <br />
      一つの画面で教材・チューター画面・リアルタイム校正全てを確認できるPCの使用を推奨します。
    </>,
    <>
      Đăng nhập vào Ringle để xem các buổi học sắp tới và vào học đúng giờ.
      <br />
      Bạn sẽ thấy thông tin gia sư, tài liệu và các câu hỏi thảo luận trong cùng một trang.
    </>,
  ],
  visible_correction: [
    `바로 보이는 실시간 교정`,
    `Real-time corrections`,
    's_process.visible_correction',
    `即时可看的即时纠正`,
    `可立即確認的即時糾正`,
    `その場で見れるリアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  desc_visible_correction: [
    `수업 중 튜터가 교정해주는 내용을 실시간으로 볼 수 있습니다. 수업 노트는 튜터와 나 모두 사용할 수 있습니다.`,
    `Follow along your tutor's corrections and comments real-time.`,
    's_process.desc_visible_correction',
    `课程中可即时查看教师的纠正内容。同时课堂笔记教师及本人均可使用。`,
    `上課時可即時確認任課教師糾正的內容，任課教師與您均可使用課堂筆記。`,
    `レッスン中にチューターが校正してくれる内容をリアルタイムで見ることができます。レッスンノートはチューターと自分の両者が使用可能です。`,
    `Theo dõi những lỗi được sửa và bình luận của gia sư trực tiếp.`,
  ],
  script_and_recording: [
    `2. 대화 스크립트`,
    `2. Recording & Transcript`,
    's_process.script_and_recording',
    `2. 对话脚本`,
    `2. 對話腳本`,
    `2. 録音＆会話スクリプト`,
    `2. Ghi âm & Bản ghi`,
  ],
  desc_script_and_recording: [
    `AI가 기록한 스크립트를 보고 들으며 복습할 수 있어요. 튜터와 나의 대화를 녹음 파일 & 스크립트로 언제든 다시 확인하세요.`,
    `You can revisit the lesson by listening to audio recording and reading conversation transcript`,
    's_process.desc_script_and_recording',
    `可查看及收听AI记录的脚本内容, 对课程进行复习。请随时确认教师与本人的对话录音&脚本文件。`,
    `可一邊聽看AI記錄的腳本一邊複習。隨時可利用錄音 & 腳本再次確認任課教師與我的對話。`,
    `AIが記録したスクリプトを読み聞き直すことができます。レッスン中の会話を音声とスクリプトから復習しましょう。`,
    `Bạn có thể xem lại buổi học bằng cách nghe ghi âm và đọc bản ghi cuộc hội thoại`,
  ],
  based_on_AI: [
    `3. AI 분석`,
    `3. AI Analysis`,
    's_process.based_on_AI',
    `3. AI分析`,
    `3. AI分析`,
    `AI 分析`,
    `3. Phân tích bằng AI`,
  ],
  desc_based_on_AI: [
    `AI가 분석한 영어회화 패턴을 확인하세요. 말하기 속도, 자주 쓰는 단어, Filler Word 빈도 등을 분석하고, 대체 가능한 표현도 확인 가능합니다.`,
    `Ringle offers AI-generated analysis on suggestions on speech pace and vocabulary range.`,
    's_process.desc_based_on_AI',
    `请确认AI分析的英语会话模式。同时可对口语速度, 常用词汇, Filler Word频率等内容进行分析并了解相关替代表达方式。`,
    `請確認AI分析的英語會話模式。也可確認分析口說速度、常用單字、Filler Word頻率等後可取代的用法。`,
    `AIが分析した英会話パターンを確認しましょう。話すスピード、よく使う単語、つなぎ言葉(Filler Word)の頻度などを分析し、代わりとなる表現もここから確認できます。`,
    `Ringle cung cấp phân tích thực hiện bởi AI với những gợi ý về tốc độ nói và từ vựng.`,
  ],
  multi_feedback: [
    `4. 다각도 피드백`,
    `4. Comprehensive Feedback`,
    's_process.multi_feedback',
    `4. 多角度反馈`,
    `4. 全方位意見回饋`,
    `4. 多角的フィードバック`,
    `4. Đánh giá toàn diện`,
  ],
  desc_multi_feedback: [
    `발음, 문법, 어휘 등 영역별로 분석된 튜터의 피드백을 받아볼 수 있습니다. 나만을 위한 피드백으로 영어 실력을 키워보세요.`,
    `After each lesson, your tutor will leave a detailed feedback on vocabulary, fluency, grammar, and pronunciation.`,
    's_process.desc_multi_feedback',
    `可获得教师提供的包含发音, 语法, 词汇等各方面内容的意见反馈。通过针对自身的学习反馈, 提升您的英语水平吧。`,
    `可取得任課教師分析發音、文法、詞彙等各領域的意見回饋，藉由專屬於我的意見回饋提升英語實力吧！`,
    `発音、文法、語彙など分野別に分析されたチューターのフィードバックを受けることができます。あなたのためだけのフィードバックで英語力を伸ばしましょう。`,
    `Sau mỗi buổi học, gia sư của bạn sẽ cung cấp phản hồi chi tiết về từ vựng, sự trôi chảy, ngữ pháp và phát âm..`,
  ],
  correction_note: [
    `1. 실시간 교정`,
    `1. Correction notes from lesson`,
    's_process.correction_note',
    `1.实时纠正`,
    `1.即時糾正`,
    `1. レッスンノート`,
    `1. Ghi chú sửa lỗi của buổi học`,
  ],
  desc_correction_note: [
    `튜터와 대화하며 실시간 교정과 피드백을 확인합니다. 수업 후에도 언제든 다시 노트를 복습할 수 있습니다.`,
    `You can review the tutor correction doc that you used during the lesson.`,
    's_process.desc_correction_note',
    `与教师进行对话, 可实时确认纠正内容及反馈。同时课程结束后可随时查阅课程笔记进行复习。`,
    `與任課教師對話，確認即時糾正與意見回饋。課程結束後，隨時可再次複習筆記。`,
    `チューターと会話しながらリアルタイム校正とフィードバックが書き込まれます。レッスン後にはいつでもノートで復習できます。`,
    `Bạn có thể xem lại bản sửa lỗi của gia sư trong buổi học.`,
  ],
  tutor_i_selected: [
    `언제 어디서나`,
    `Design your own lessons`,
    's_process.tutor_i_selected',
    `何时何地`,
    `隨時隨地`,
    `いつでもどこでも`,
    `Thiết kế buổi học`,
  ],
  just_for_you: [
    `원하는 수업을 자유롭게`,
    `wherever and whenever`,
    's_process.just_for_you',
    `自由选择希望的课程`,
    `自由進行想要的課程`,
    `好きなレッスンを自由に`,
    `bất cứ đâu và bất cứ khi nào`,
  ],
  what_offered_after_lesson: [
    `수업이 끝나고 제공되는 것들`,
    `What’s offered after the lesson`,
    's_process.what_offered_after_lesson',
    `课程结束后即可提供`,
    `課程結束後提供的內容`,
    `レッスン後に提供されるもの`,
    `Bạn được cung cấp sau buổi học`,
  ],
  real_lesson_video: [
    `실제 수업 영상`,
    `Here's a recording of an actual Ringle lesson`,
    's_process.real_lesson_video',
    `实际课程视频`,
    `實際課程影片`,
    `実際のレッスン動画`,
    `Đây là bản ghi âm một buổi học cùa Ringle`,
  ],
  desc_real_lesson_video: [
    <>
      실제 링글 수업을 수강 중인 모습입니다.
      <br />
      질문 2-3개로 대화하다 보면 생각보다 40분 수업이 금방 끝납니다.
    </>,
    ``,
    's_process.desc_real_lesson_video',
    <>
      Ringle课程实际状态。
      <br />
      针对2-3个问题进行对话, 40分钟的课程完全没有想象中漫长。
    </>,
    <>
      Ringle實際上課方式
      <br />
      利用2～3個問題進行課程，40分鐘課程比想像中更快結束。
    </>,
    <>
      実際のRingleレッスン受講中の様子です。
      <br />
      レッスン質問を2、3個で会話してみると、40分のレッスンもあっという間に終わります。
    </>,
    ``,
  ],
  free_lesson: [
    `무료 체험 수업 신청하기`,
    `Sign up now!`,
    's_process.free_lesson',
    `申请免费体验课程`,
    `申請免費體驗課程`,
    `無料体験レッスンを予約する`,
    `Học thử miễn phí`,
  ],
  qa: [
    <>첫 수업 불만족 시 즉시 환불</>,
    `100% Money-back guarantee`,
    's_process.qa',
    <>首次课程不满意时可立即退款</>,
    <>首次課程不滿意時可立即退款</>,
    <>初回レッスンにご満足いただけない場合、全額返金保証</>,
    `Đảm bảo hoàn tiền 100%`,
  ],
  first_experience: [
    <>경험하고 결정하세요. 수업권 구매 후, 첫 수업에 만족하지 못하시면 즉시 환불해드립니다.</>,
    <>We promise a full refund if you're not 100% satisfied with your first paid lesson.</>,
    's_process.first_experience',
    <>请先体验后再决定。购买课程券后，如对首次课程不满意，将会立即为您进行退款。</>,
    <>請先體驗再決定。購買課程券後，首次課程不滿意時，會立即退款給您。</>,
    <>
      体験してから決めてください。レッスンチケット購入後、初回レッスンにご満足いただけなければ\nすぐに返金いたします。
    </>,
    <>Chúng tôi đảm bảo hoàn tiền đầy đủ nếu bạn không hài lòng 100% với buổi học tính phí đầu tiên.</>,
  ],
  only_for_first: [
    <>* 체험 수업도 첫 수업에 포함됨</>,
    <>*Includes trial lesson</>,
    's_process.only_for_first',
    <>*试听课程也包含在首次课程中</>,
    <>* 首次課程也包含體驗課程</>,
    <>* 初回レッスン、体験レッスンが対象</>,
    ``,
  ],
  time_assurance: [
    <>첫 수업 시작 전까지 수강기간 미차감</>,
    `Start Whenever`,
    's_process.time_assurance',
    <>首次课程开始前不扣除使用期限</>,
    <>首次課程開始之前不扣除使用期限</>,
    <>自分のペースで始められる </>,
    `Bắt đầu bất cứ khi nào`,
  ],
  start_as_you_like: [
    <>원하는 일정에 맞춰 시작하세요.</>,
    <Fragment>You can purchase lesson credits now and start later.</Fragment>,
    's_process.start_as_you_like',
    <>选择希望的时间, 开始您的课程吧。</>,
    <>請在您希望的日程開始。</>,
    <>ご希望の日時に合わせて始めましょう。</>,
    <Fragment>Bạn có thể mua gói học trước và bắt đầu sau.</Fragment>,
  ],
  can_extend: [
    <>* 구매 후 1년 이후 수강기간 자동 차감 </>,
    <>* Unused credits' expiration dates will start counting down a year after the purchase date.</>,
    's_process.can_extend',
    <>* 购买后一年期间，于完成首次课程时扣除使用期限。</>,
    <>* 購買後一年期間，於完成首次課程時扣除使用期限。</>,
    <>* レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。</>,
    <>* Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.</>,
  ],
  finish_assurance: [
    <>수업 하루 전까지 변경 가능</>,
    `Flexible Cancellation`,
    's_process.finish_assurance',
    <>截止于课程开始前一天可进行变更</>,
    <>最晚可於課程開始1天前變更</>,
    <>レッスン前日まで予約変更できる</>,
    `Huỷ linh hoạt`,
  ],
  dont_miss: [
    <>일정이 생겨도 끝까지 수강하세요.</>,
    <Fragment>You can cancel or reschedule your lesson up to 24 hours before the lesson.</Fragment>,
    's_process.dont_miss',
    <>即使有其他日程, 也请尽可能完成课程。</>,
    <>即使有其他日程，也能聽完所有課程！</>,
    <>万一予定が入っても柔軟に対応します。</>,
    <Fragment>Bạn có thể huỷ hoặc dời buổi học tối thiểu 24 giờ trước buổi học.</Fragment>,
  ],
  unlimited_provided: [
    <>
      * 수업 시작 기준 24~2시간 이전 변경 시,
      <br />
      당일수업권 제공
    </>,
    ``,
    's_process.unlimited_provided',
    <>
      * 截止于课程开始前两小时变更时
      <br />
      将提供补充课程券
    </>,
    <>
      * 最晚於課程開始2小時前變更時間時，
      <br />
      將提供補充課程券
    </>,
    <>
      * 開始2時間前までの変更は
      <br />
      当日レッスンチケットに交換
    </>,
    ``,
  ],
  try_lesson: [
    `무료체험 수업 신청하기`,
    `Start now with a free trial`,
    's_process.try_lesson',
    `申请免费咨询`,
    `申請免費諮詢`,
    `無料体験レッスンのお申込み`,
    `Đăng ký`,
  ],
  always: [
    <>
      365일 연중무휴 & 하루에도 여러 번<br />
      <Div spanTag textPrimary>
        원하는 일정을 선택
      </Div>
      할 수 있습니다.
    </>,
    `Choose a time that best fits your schedule.`,
    's_process.always',
    <>
      365天全年无休&一天内可选择
      <br />
      <Div spanTag textPrimary>
        多个希望的时间
      </Div>
      开展课程。
    </>,
    <>
      365日全年無休&一天可選擇
      <br />
      數個
      <Div spanTag textPrimary>
        希望的時間
      </Div>
      。
    </>,
    <>
      365日年中無休 & 1日に何回でも
      <br />
      <Div spanTag textPrimary>
        好きな時間帯を選択
      </Div>
      することができます。
    </>,
    `Chọn thời gian phù hợp với lịch của bạn.`,
  ],
  ringle_application: [
    `링글 어플리케이션`,
    `Ringle application`,
    's_process.ringle_application',
    `Ringle应用程序`,
    `Ringle APP`,
    `Ringleアプリ`,
    `Ứng dụng Ringle`,
  ],
  desc_enter_lesson_title: [
    <>
      수업 입장은{' '}
      <Div spanTag textPrimary>
        [수업 입장]
      </Div>{' '}
      버튼을 통해 수업 시작 15분 전부터 가능합니다.
    </>,
    <>
      Log in to Ringle and enter lesson on time.
      <br />
      See your tutor, materials, and questions all on the same screen.
    </>,
    's_process.desc_enter_lesson_title',
    <>
      课程入场
      <Div spanTag textPrimary>
        可通过点击[课程入场]
      </Div>
      按钮, 从课程开始前15分钟可以进场准备。
      <br />
      建议使用可在同一画面对教材/教师画面/实时纠正等进行查看的PC终端。
    </>,
    <>
      透過
      <Div spanTag textPrimary>
        [進入課程]
      </Div>
      按鈕，可於課程開始15分鐘前進入課程。
    </>,
    <>
      <Div spanTag textPrimary>
        [レッスン開始]
      </Div>{' '}
      ボタンでレッスン開始15分前から入室可能です。
    </>,
    <>
      Đăng nhập vào Ringle và tham gia buổi học đúng giờ.
      <br />
      Xem thông tin gia sư, tài liệu và tất cả các câu hỏi trên cùng một trang.
    </>,
  ],
  desc_enter_lesson_sub: [
    <>한 화면으로 교재/튜터 화면/실시간 교정 모두 확인 가능한 PC 사용을 권장드립니다.</>,
    <></>,
    's_process.desc_enter_lesson_sub',
    <>建议使用可于同一画面对教材/教师画面/实时纠正等内容进行同时查看的PC终端设备。</>,
    <>建議使用電腦於同一個畫面確認教材／任課教師畫面／即時糾正。</>,
    <>一つの画面で教材・チューター・リアルタイム校正を確認できるPCの使用を推奨します。</>,
    <></>,
  ],
  desc_visible_correction_title: [
    <>
      수업 중 튜터가 교정해주는 내용을{' '}
      <Div spanTag textPrimary>
        실시간
      </Div>
      으로 볼 수 있습니다.
    </>,
    <>Follow along your tutor's corrections and comments real-time.</>,
    's_process.desc_visible_correction_title',
    <>
      课程中教师纠正的内容
      <Div spanTag textPrimary>
        可实时
      </Div>
      进行查看。
    </>,
    <>
      上課時可
      <Div spanTag textPrimary>
        即時
      </Div>
      確認任課教師糾正的內容。
    </>,
    <>
      レッスン中にチューターが校正をしている画面を{' '}
      <Div spanTag textPrimary>
        リアルタイムで
      </Div>
      見ることができます。
    </>,
    <> Theo dõi những lỗi được sửa và bình luận của gia sư trực tiếp.</>,
  ],
  desc_visible_correction_sub: [
    <>
      교정 노트는 튜터와 나 모두 <Br md down />
      사용할 수 있습니다.
    </>,
    <></>,
    's_process.desc_visible_correction_sub',
    <>
      教师及学生本人
      <Br md down />
      均可使用纠正笔记。
    </>,
    <>
      任課教師與您均可使用
      <Br md down />
      糾正筆記。
    </>,
    <>
      レッスンノートはチューターと自分どちらも <Br md down />
      アクセスできます。
    </>,
    <></>,
  ],
  all_device: [
    <>
      PC, 태블릿, 스마트폰으로 <Br md down />
      예약 가능합니다.
    </>,
    <></>,
    's_process.all_device',
    <>
      PC, 平板, 智能手机
      <Br md down />
      均可预约课程。
    </>,
    <>
      可使用電腦、平板電腦、智慧型手機
      <Br md down />
      預約。
    </>,
    <>
      PC、タブレット、スマートフォンから <Br md down />
      予約可能
    </>,
    <></>,
  ],
  desc_select_material_title: [
    <>
      <Div spanTag textPrimary>
        원하는 교재
      </Div>
      를 자유롭게 선택하세요.
    </>,
    `Pick out lesson materials on a topic of your interest.`,
    's_process.desc_select_material_title',
    <>
      <Div spanTag textPrimary>
        请自由选择
      </Div>
      您感兴趣的教材。
    </>,
    <>
      請自由選擇
      <Div spanTag textPrimary>
        希望的教材
      </Div>
      。
    </>,
    <>
      <Div spanTag textPrimary>
        好きな教材
      </Div>
      を自由に選ぶことができます。
    </>,
    `Chọn tài liệu liên quan đến chủ đề yêu thích.`,
  ],
  desc_select_material_title_sub: [
    `자료를 직접 업로드할 수 있는 자유주제 수업도 가능합니다.`,
    <>
      Pick out materials on a topic of your interest or have a "Free Topic" discussion.
      <br />
      Or choose Free Topic or upload your own materials
    </>,
    's_process.desc_select_material_title_sub',
    `同时也可自主上传资料进行自由主题课程。`,
    `也可進行親自上傳資料的自由主題課程。`,
    `自分でアップロードした資料を用いた自由テーマレッスンも対応。`,
    <>Hoặc chọn Chủ đề tự do hoặc đăng tải tài liệu của bạn.</>,
  ],
  ringle_platform: [
    `Ringle Platform`,
    ``,
    's_process.ringle_platform',
    `Ringle Platform`,
    `Ringle Platform`,
    `Ringle Platform`,
    ``,
  ],
}
export const s_system = {
  for_better_study: [
    <>
      오직 당신을 위한
      <br />
      일대일 맞춤 수업 시스템
    </>,
    <>
      The best-in-class
      <br />
      learning platform
    </>,
    's_system.for_better_study',
    <>
      专为您量身打造的
      <br />
      1:1定制型课程系统
    </>,
    <>
      專為您量身打造的
      <br />
      1:1客製化課程系統
    </>,
    <>
      あなた専用の
      <br />
      1on1カスタムレッスンシステム
    </>,
    <>
      Nền tảng học tập
      <br />
      tốt nhất
    </>,
  ],
  reservation_system: [`예약`, `Book`, 's_system.reservation_system', `预约`, `預約`, `予約`, `Đặt lịch`],
  reservation_subtitle: [
    <>
      365일 원클릭으로 <br />
      직접 정하는 수업 일정
    </>,
    ``,
    's_system.reservation_subtitle',
    <>
      365天, 一键式
      <br />
      自主便捷安排课程
    </>,
    <>
      365天一鍵
      <br />
      親自決定課程日程
    </>,
    <>
      365日ワンクリックで <br />
      自由に決められるレッスン日程
    </>,
    ``,
  ],
  tutor_i_want: [
    `원하는 튜터 직접 선택`,
    `Searchable Tutor Profiles`,
    's_system.tutor_i_want',
    `直接选择满意的教师`,
    `親自選擇希望的任課教師`,
    `好きなチューターを自由に選択`,
    `Tìm kiếm hồ sơ gia sư dễ dàng`,
  ],
  tutor_i_want_detail_1: [
    `1000+명의 튜터 상세 프로필 제공`,
    `View detailed profiles of 1000+ tutors`,
    's_system.tutor_i_want_detail_1',
    `提供1000名以上的教师详细简介`,
    `提供1000名以上任課教師的詳細簡介`,
    `1000名超えるチューターの詳細なプロフィールが見られる`,
    `Xem hồ sơ chi tiết của 1000+ gia sư`,
  ],
  tutor_i_want_detail_2: [
    `전공/경력/커리어/발음 등 맞춤 검색`,
    `Search by tutor's major, experience, career`,
    's_system.tutor_i_want_detail_2',
    `进行专业/经历/职业/发音等针对性搜索`,
    `主修／經歷／職業／發音等個人化搜尋`,
    `専攻・経歴・アクセントなどから詳細検索`,
    `Tìm kiếm theo chuyên ngành, kinh nghiệm, công việc của gia sư`,
  ],
  tutor_i_want_detail_3: [
    `찜한 튜터 수업 오픈 시 알림 제공`,
    `Set notifications for your favorite tutors`,
    's_system.tutor_i_want_detail_3',
    `提供指定教师的开课提醒`,
    `我收藏的任課教師公開課程時提供通知`,
    `お気に入りのチューターの予定が空いたら通知でお知らせ`,
    `Đặt thông báo cho gia sư yêu thích của bạn`,
  ],
  auto_matching: [
    `자동 매칭 시스템`,
    `Ringle Auto Matching`,
    's_system.auto_matching',
    `自动匹配系统`,
    `自動配對系統`,
    `自動マッチングシステム`,
    `Ringle xếp ngẫu nhiên`,
  ],
  auto_matching_detail_1: [
    `원하는 일정만 선택하면 최적의 튜터 매칭`,
    `We'll match you to the best tutor available`,
    's_system.auto_matching_detail_1',
    `只要选择希望的日期, 即可为您提供最佳的匹配教师`,
    `選擇希望日程便為您配對最適合的任課教師。`,
    `希望日程を選択するだけで最適なチューターとマッチング`,
    `Chúng tôi chọn gia sư tốt nhất cho bạn`,
  ],
  free_scheduling: [
    `자유로운 일정 변경`,
    `Flexible Booking`,
    's_system.free_scheduling',
    `自由变更日期`,
    `自由變更日程`,
    `柔軟に日程変更できる`,
    `Đặt lịch linh hoạt`,
  ],
  free_scheduling_detail_1: [
    `연중무휴로 하루에도 횟수 제한 없이 예약 가능`,
    `Book as many or as little lessons as you like`,
    's_system.free_scheduling_detail_1',
    `全年无休, 每日预约次数无上限`,
    `全年無休，每日預約次數無上限`,
    `年中無休でレッスン予約可能、回数制限なし`,
    `Đặt số lượng buổi học theo nhu cầu`,
  ],
  free_scheduling_detail_2: [
    `수업 시작 24시간 전까지 변경/취소 가능`,
    `Pick a time that works for you`,
    's_system.free_scheduling_detail_2',
    `变更/取消截止于课程开始前24小时`,
    `最晚可於課程開始24小時前變更／取消`,
    `レッスン開始24時間前まで変更・キャンセル可能`,
    `Chọn thời gian phù hợp với bạn`,
  ],
  free_scheduling_detail_3: [
    `수업 시작 기준 24~2시간 이전 취소 시, 당일수업권 제공`,
    `Flexible cancellations`,
    's_system.free_scheduling_detail_3',
    `当天取消将提供补充课程券(截止于课程开始前两小时)`,
    `當天取消提供補課券 (最晚於課程開始2小時前)`,
    `当日キャンセル時は、当日レッスンチケット付与(レッスン開始2時間前まで)`,
    `Huỷ linh hoạt`,
  ],
  prestudy_system: [`예습`, `Lesson Prep`, 's_system.prestudy_system', `预习`, `預習`, `予習`, `Chuẩn bị Buổi học`],
  prestudy_subtitle: [
    <>
      일대일 수업에 최적화된 <br />
      학습 인터페이스
    </>,
    ``,
    's_system.prestudy_subtitle',
    <>
      最佳的1:1课程
      <br />
      学习平台
    </>,
    <>
      最適合1:1課程的
      <br />
      學習介面
    </>,
    <>
      1on1レッスンに特化した
      <br />
      学習インターフェース
    </>,
    ``,
  ],
  course: [
    <>온라인 교재와 오디오북</>,
    <>Lesson Materials</>,
    's_system.course',
    <>在线教材及有声读物</>,
    <>線上教材與有聲書</>,
    <>オンライン教材とオーディオブック</>,
    <>Tài liệu học</>,
  ],
  course_detail_1: [
    <>PC/모바일 앱에서 무제한 열람</>,
    <>Search by topic or difficulty</>,
    's_system.course_detail_1',
    <>PC/手机app无限制浏览</>,
    <>電腦／手機APP無限閱覽</>,
    <>PC/モバイルアプリから無制限閲覧</>,
    <>Xem trên điện thoại hoặc máy tính</>,
  ],
  course_detail_2: [
    <>PDF/MP3로 다운로드</>,
    <>Read on Ringle mobile app or PC</>,
    's_system.course_detail_2',
    <>下载PDF/MP3</>,
    <>下載PDF／MP3</>,
    <>PDF又はMP3でダウンロード可能</>,
    <>Tải sách nói định dạng MP3</>,
  ],
  course_detail_3: [
    <>국/영문 통합본 제공</>,
    <>Download audiobook in MP3</>,
    's_system.course_detail_3',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  pre_qna: [
    `사전 질문 선택 및 답변 작성`,
    `Discussion Questions`,
    's_system.pre_qna',
    `课前选择提问及填写答案`,
    `事先選擇問題及寫下回答`,
    `レッスンの質問`,
    `Câu hỏi thảo luận`,
  ],
  can_choose_question: [
    `수준별 질문 선택으로 난이도 조절`,
    `Choose questions of desired difficulty level`,
    's_system.can_choose_question',
    `选择不同水平的问题调节难易度`,
    `選擇各種程度的問題調整難度`,
    `レベル別の質問から自分に合った難易度を選べる`,
    `Chọn câu hỏi theo mức độ khó mong muốn`,
  ],
  answer_to_study: [
    `질문별 답변 작성으로 영작문 연습`,
    `Prep responses in advance`,
    's_system.answer_to_study',
    `回答问题练习英文写作`,
    `藉由回答問題練習英文寫作`,
    `回答の事前作成で英作文の練習`,
    `Chuẩn bị trước câu trả lời`,
  ],
  share_answers: [
    `학습자와 튜터의 답변 공유 가능`,
    `Request proofreading on written response`,
    's_system.share_answers',
    `学员及教师们可共享回答`,
    `學生與任課教師可共享回答`,
    `他の受講生と回答を共有する`,
    `Yêu cầu hiệu đính câu trả lời đã viết`,
  ],
  can_upload_my_data: [
    `개인 수업 자료 업로드`,
    `Upload Your Own Materials`,
    's_system.can_upload_my_data',
    `上传个人课程材料`,
    `上傳個人課程資料`,
    `自分専用のレッスン資料アップロード`,
    `Đăng tài liệu của bạn`,
  ],
  free_topic_material: [
    `영어 인터뷰, 발표 준비, 이메일 교정 등 맞춤 수업 가능`,
    <>Upload resume, presentation, articles, etc.</>,
    's_system.free_topic_material',
    `可选择英语面试, 发表准备, 校对邮件等各种定制型课程`,
    `可選擇英語面試、發表準備、糾正電子郵件等各種客製化課程`,
    `英語面接、発表準備、メール校正などニーズに合わせたカスタマイズレッスン可能`,
    <>Đăng resume, bài thuyết trình, bài báo,...</>,
  ],
  format_support: [
    `PDF, Docx 등 다양한 포맷 지원`,
    <>Upload in PDF, DOC, or PPT</>,
    's_system.format_support',
    `支持PDF, Docx等多种格式`,
    `支援PDF、Docx等各種格式`,
    `PDF、DOC、PPTなど様々なフォーマットに対応`,
    <>Đăng ở định dạng PDF, DOC, hoặc PPT</>,
  ],
  security_system: [
    `개인정보 보호 기능`,
    `Security system protects confidential info`,
    's_system.security_system',
    `个人信息保护功能`,
    `個人資料保護功能`,
    `厳重なセキュリティシステムで個人情報を守る`,
    `Hệ thống bảo mật thông tin của bạn`,
  ],
  setting: [`설정`, `Lesson Style`, 's_system.setting', `设定`, `設定`, `設定`, `Hình thức học`],
  setting_subtitle: [
    <>
      원하는 수업을 <br />
      수준에 맞춰 설계
    </>,
    ``,
    's_system.setting_subtitle',
    <>
      按照自身水平
      <br />
      设计理想课程
    </>,
    <>
      依照程度
      <br />
      設計希望的課程
    </>,
    <>
      好きなレベルに合わせた <br />
      レッスン設計
    </>,
    ``,
  ],
  lesson_style: [
    <>수업 방식 설정</>,
    <>Customizable Lesson Style</>,
    's_system.lesson_style',
    <>设定课程形式</>,
    <>上課方式設定</>,
    <>レッスン形式設定</>,
    <>Tùy chỉnh Hình thức học</>,
  ],
  debate_talk: [
    <>교정 중심 vs 토론 중심</>,
    <>Discussion Focused vs Paraphrasing Focused</>,
    's_system.debate_talk',
    <>以纠正为主 vs 以讨论为主</>,
    <>以糾正為主 vs 以討論為主</>,
    <>校正中心 or ディスカッション中心</>,
    <>Tập trung thảo luận vs Tập trung sửa lỗi</>,
  ],
  intensity: [
    <>대화 비중 및 교정 강도 조절</>,
    <>Control discussion engagement</>,
    's_system.intensity',
    <>调整对话比重及纠正强度</>,
    <>調整對話比重及糾正強度</>,
    <>会話の比重および校正レベル調節</>,
    <>Kiểm soát tần suất sửa lỗi</>,
  ],
  focusing_area: [
    <>집중 교정 영역 선택</>,
    <>Main Area of Focus</>,
    's_system.focusing_area',
    <>选择集中纠正的方面</>,
    <>選擇集中糾正領域</>,
    <>集中校正分野が選べる</>,
    <>Phần sửa lỗi</>,
  ],
  detail_area: [
    <>어휘, 문법, 표현, 발음 등 세분화된 교정 영역</>,
    <>Choose from 4 categories</>,
    's_system.detail_area',
    <>词汇, 语法, 表达, 发音等详细纠正方面</>,
    <>詞彙、文法、用法、發音等詳細糾正領域</>,
    <>流暢性、語彙、文法、発音の細分化された4の校正分野</>,
    <>Lựa chọn từ 4 mục sau</>,
  ],
  area_feedback: [
    <>선택 영역 기반 피드백 제공</>,
    <>Feedback provided based on your selection</>,
    's_system.area_feedback',
    <>提供基于选择方面的针对性反馈</>,
    <>針對所選領域提供意見回饋</>,
    <>選択分野をもとにフィードバック提供</>,
    <>Đánh giá dựa trên lựa chọn của bạn</>,
  ],
  detail_request: [
    <>상세 요청사항 작성</>,
    <>Other Requests</>,
    's_system.detail_request',
    <>填写详细的要求事项</>,
    <>填寫具體要求事項</>,
    <>追加リクエスト事項の入力</>,
    <>Những yêu cầu khác</>,
  ],
  ask_before: [
    <>원하는 수업 방향을 튜터에게 전달</>,
    <>Specify any other requests to your tutor</>,
    's_system.ask_before',
    <>将希望的课程方向传达给教师</>,
    <>向任課教師傳達希望的課程方式</>,
    <>希望するレッスンの方向性を事前に伝えられる</>,
    <>Nêu rõ những yêu cầu khác cho gia sư</>,
  ],
  lesson_design: [
    <>학습 목적에 맞는 수업 설계</>,
    <>Further customize your lessons to fit learning objectives</>,
    's_system.lesson_design',
    <>设计符合学习目的的课程</>,
    <>設計符合學習目的的課程</>,
    <>学習目的に沿ったレッスン設計</>,
    <>Tuỳ chỉnh thêm cho buổi học để phù hợp với mục tiêu của bạn</>,
  ],
  lesson_system: [`수업 진행 요일`, `Lessons`, 's_system.lesson_system', `课程`, `課程`, `レッスン`, `Buổi học`],
  lesson_subtitle: [
    <>
      말하면 눈에 보이는 <br />
      실시간 교정 화면
    </>,
    ``,
    's_system.lesson_subtitle',
    <>
      对话时可进行直观确认的
      <br />
      实时纠正画面
    </>,
    <>
      說出口便能確認的
      <br />
      即時糾正畫面
    </>,
    <>
      その場で理解を深められる <br />
      オンライン学習画面
    </>,
    ``,
  ],
  realtime_correction: [
    `실시간 교정 제공`,
    `Real-time corrections`,
    's_system.realtime_correction',
    `提供实时纠正`,
    `提供即時糾正`,
    `リアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  realtime_correction_detail_1: [
    `나의 영어를 실시간으로 시각화`,
    `View corrections, lesson materials, and tutor simultaneously`,
    's_system.realtime_correction_detail_1',
    `我的英语实时可视化`,
    `即時將我的英語視覺化`,
    `自分の英語がその場で校正される`,
    `Xem phần sửa lỗi, tài liệu học và gia sư cùng lúc`,
  ],
  realtime_correction_detail_2: [
    `영어 글쓰기 공동작업`,
    ``,
    's_system.realtime_correction_detail_2',
    `英语写作共同进行`,
    `共同進行英文寫作`,
    `チューターと一緒に英作文作成・直し`,
    ``,
  ],
  realtime_correction_detail_3: [
    `수업 후에도 수업 노트 확인 가능`,
    <>You can view correction notes after lessons</>,
    's_system.realtime_correction_detail_3',
    `课程结束后可确认课堂笔记`,
    `課程結束後也可確認課堂筆記`,
    `レッスン後にレッスンノートが見られる`,
    <>Bạn có thể xem bản ghi sửa lỗi sau buổi học</>,
  ],
  translation_function: [
    `번역기/사전 탑재`,
    `Translator & Dictionary`,
    's_system.translation_function',
    `安装有翻译器/字典`,
    `附有翻譯機／字典`,
    `翻訳・辞書機能搭載`,
    `Phiên dịch & Từ điển`,
  ],
  google_translator: [
    `수업 중 번역기 사용 가능`,
    `Translating tools available during lessons`,
    's_system.google_translator',
    `课程中可使用翻译器`,
    `上課時可使用翻譯機`,
    `レッスン中に翻訳機能が使える`,
    `Công cụ dịch có sẵn trong quá trình học`,
  ],
  dictionary: [`영/한 사전 탑재`, ``, 's_system.dictionary', ``, ``, ``, ``],
  realtime_video: [
    `끊김없는 화상환경`,
    `1:1 Video conferencing`,
    's_system.realtime_video',
    `无中断的视频环境`,
    `不中斷的網路環境`,
    `マンツーマンのオンラインレッスン`,
    `Học trực tuyến 1:1`,
  ],
  realtime_video_detail_1: [
    `최신 화상 프로그램(Zoom) 사용`,
    `Lesson conducted via Zoom`,
    's_system.realtime_video_detail_1',
    `使用最新视频软件(Zoom)`,
    `使用最新視訊軟體（Zoom）`,
    `最新のWeb会議アプリ(Zoom)使用`,
    `Buổi học diễn ra trên Zoom`,
  ],
  realtime_video_detail_2: [
    `운영팀 실시간 지원`,
    `Support team available real-time`,
    's_system.realtime_video_detail_2',
    `运营组实时在线服务`,
    `營運團隊即時支援`,
    `リアルタイムで技術サポート`,
    `Đội ngũ hỗ trợ luôn sẵn sàng`,
  ],
  realtime_video_detail_3: [
    `네트워크 문제 시 해결 및 보상`,
    ``,
    's_system.realtime_video_detail_3',
    `出现网络问题时的解决及补偿`,
    `出現網路問題時的解決及補償`,
    `ネットワークトラブル発生時は解決支援＆補償あり`,
    ``,
  ],
  review_system: [`복습`, `Lesson Review`, 's_system.review_system', `复习`, `複習`, `復習`, `Đánh giá buổi học`],
  review_system_subtitle: [
    <>
      수업이 끝나도
      <br />
      기록에 남는 수업
    </>,
    ``,
    's_system.review_system_subtitle',
    <>
      即使课程结束
      <br />
      仍留有记录
    </>,
    <>
      即使課程結束，
      <br />
      仍留有紀錄
    </>,
    <>
      レッスンが終わっても
      <br />
      記録に残るレッスン
    </>,
    ``,
  ],
  review_script: [
    <>녹음파일 & 대화 스크립트</>,
    <>Recording & Transcript</>,
    's_system.review_script',
    <>录音文件&对话脚本</>,
    <>錄音檔 & 對話腳本</>,
    <>録音 & 会話スクリプト</>,
    <>Ghi âm & Bản ghi</>,
  ],
  review_script_detail_1: [
    <>수업 전체 녹음파일 지원</>,
    <>Audio-recording of your lesson</>,
    's_system.review_script_detail_1',
    <>提供全部课程录音文件</>,
    <>提供所有課程錄音檔</>,
    <>全レッスンの録音ファイルを提供</>,
    <>File ghi âm buổi học của bạn</>,
  ],
  review_script_detail_2: [
    <>튜터와 나눈 대화를 스크립트로 제공</>,
    <>Conversation script of your lesson</>,
    's_system.review_script_detail_2',
    <>提供与教师间的对话脚本</>,
    <>提供與任課教師的對話腳本</>,
    <>チューターとの会話をスクリプトで提供</>,
    <>Văn bản hội thoại của buổi học</>,
  ],
  review_script_detail_3: [
    <>수업 노트 다시 보기 가능</>,
    <>Correction notes</>,
    's_system.review_script_detail_3',
    <>可重新查看课堂笔记</>,
    <>可重新確認課堂筆記</>,
    <>レッスンノートを読み返しできる</>,
    <>Ghi chú sửa lỗi</>,
  ],
  review_feedback: [
    <>다각도 피드백</>,
    <>Tutor Feedback</>,
    's_system.review_feedback',
    <>多角度反馈</>,
    <>全方位意見回饋</>,
    <>多角的フィードバック</>,
    <>Đánh giá từ gia sư</>,
  ],
  review_feedback_detail_1: [
    <>공인 영어 스피킹 점수 예측</>,
    <>Estimated scores for English proficiency exams</>,
    's_system.review_feedback_detail_1',
    <>官方英语口语分数预测</>,
    <>預測英語檢定口說分數</>,
    <>英語試験スピーキング点数予測</>,
    <>Ước tính điểm trong các bài đánh giá năng lực tiếng Anh</>,
  ],
  review_feedback_detail_2: [
    <>어휘, 문법, 발음, 유창성 등 영역별 진단</>,
    <>Thorough evaluations on pronunciations, grammar, vocabulary and fluency</>,
    's_system.review_feedback_detail_2',
    <>对词汇, 语法, 发音及流畅性等各方面进行分别评价</>,
    <>診斷詞彙、文法、發音及流暢性等各領域</>,
    <>語彙、文法、発音、流暢性など分野別診断</>,
    <>Đánh giá chi tiết về phát âm, ngữ pháp, từ vựng và mức độ trôi chảy</>,
  ],
  review_ai: [
    <>AI 분석</>,
    <>AI Analysis</>,
    's_system.review_ai',
    <>AI分析</>,
    <>AI分析</>,
    <>AI Analysis</>,
    <>Phân tích bằng AI</>,
  ],
  review_ai_detail_1: [
    <>Filler Word, 자주 쓰는 단어 등 영어 습관 분석</>,
    <>Review speech pace, vocab range and filler words</>,
    's_system.review_ai_detail_1',
    <>对Filler Word, 常用词汇等英语习惯进行分析</>,
    <>分析Filler Word、常用單字等英語習慣</>,
    <>つなぎ言葉(Filler Word)、よく使う単語等から自分の傾向分析</>,
    <>Đánh giá tốc độ nói, vốn từ và các fillers words</>,
  ],
  review_ai_detail_2: [
    <>말하기 속도 및 발화량 분석</>,
    <>Measure your performance against other Ringlers</>,
    's_system.review_ai_detail_2',
    <>对口语速度及说话量进行分析</>,
    <>分析口說速度及說話量</>,
    <>話すスピードおよび発話量分析</>,
    <>Đánh giá khả năng của bạn so với các học viên Ringle khác</>,
  ],
  all_this_mobile: [
    <>
      이 모든 것을 <br />
      앱에서 제약없이 <br />
      <br />
      PC - 앱 연동으로 모든 기능을
      <br />
      자유롭게 이동하며 이용할 수 있습니다
    </>,
    <>Access all of Ringle on your phone</>,
    's_system.all_this_mobile',
    <>
      这一切均
      <br />
      可在应用程序中无限制使用
      <br />
      <br />
      通过PC - APP联动
      <br />
      可自由移动, 便捷使用全部功能
    </>,
    <>
      這一切
      <br />
      均可在APP中無限使用
      <br />
      <br />
      透過綁定電腦及APP
      <br />
      可自由移動並使用所有功能
    </>,
    <>
      PCとアプリ併用で全機能を
      <br />
      ご自由にご利用いただけます。
    </>,
    <>Truy cập tất cả tính năng của Ringle trên điện thoại</>,
  ],
  app_down: [
    <>앱 다운로드</>,
    <>Download App</>,
    's_system.app_down',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  ringle_platform: [
    `Ringle's Approach`,
    `Ringle's Approach`,
    's_system.ringle_platform',
    `Ringle's Approach`,
    `Ringle's Approach`,
    `Ringle's Approach`,
    `Cách tiếp cận của Ringle`,
  ],
  lesson_style_caf: [
    `CAF 진단이란?`,
    `What is CAF?`,
    's_system.lesson_style_caf',
    `What is CAF?`,
    `What is CAF?`,
    `What is CAF?`,
    `What is CAF?`,
  ],
}
export const s_correction = {
  beyond_the_wall: [
    <>
      언어의 장벽을 넘어
      <br />더 큰 세계로 나아간 링글러 이야기
    </>,
    <>Ringlers break through language barriers and join the bigger world</>,
    's_correction.beyond_the_wall',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  not_sentence: [
    <>
      문장이 아닌
      <br />
      글을 교정합니다
    </>,
    <>
      We don't just fix sentences
      <br />
      We make you a great writer
    </>,
    's_correction.not_sentence',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  pro_editor: [
    <>
      하버드, 아이비리그 출신 <br />
      분야별 전문 에디터를 바로 연결해드립니다
    </>,
    <></>,
    's_correction.pro_editor',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  best_editor: [
    `최고의 에디터에게 맡기세요`,
    `Have 'your writing reviewed by the best editors`,
    's_correction.best_editor',
    ``,
    ``,
    ``,
    ``,
  ],
  harvard_harsh_edu: [
    <>
      혹독한 글쓰기 교육을 받은 <Br md down />
      하버드, 아이비리그 튜터들의 영문서 교정
      <br />
      단순히 문법에 맞는 문장이 아닌, <Br md down />
      우수하고 뛰어난 글로 교정해 드립니다
    </>,
    <></>,
    's_correction.harvard_harsh_edu',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_categories: [
    `영문서 교정 카테고리`,
    `Professional editing for all purposes`,
    's_correction.correction_categories',
    ``,
    ``,
    ``,
    ``,
  ],
  prepare_abroad: [`유학준비`, `Study Abroad`, 's_correction.prepare_abroad', ``, ``, ``, ``],
  admission_essay: [
    <>
      어드미션 에세이
      <br />
      MBA 에세이
      <br />
      SOP/학업계획서
      <br />
      추천서 등
    </>,
    <>
      Application Essays
      <br />
      Writing Sample
      <br />
      Statement of Purpose
      <br />
      Recommendation Letters, etc.
    </>,
    's_correction.admission_essay',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  career_business: [`커리어/비즈니스`, `Career & Business`, 's_correction.career_business', ``, ``, ``, ``],
  english_resume: [
    <>
      영어 레주메
      <br />
      영어 인터뷰 스크립트
      <br />
      커버레터
      <br />
      비즈니스 이메일
      <br />
      발표 자료 등
    </>,
    <>
      Resume & CV
      <br />
      Cover Letters
      <br />
      Business Emails
      <br />
      Presentation Materials, etc.
    </>,
    's_correction.english_resume',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  study_data: [`학업/학술 자료`, `Academics`, 's_correction.study_data', ``, ``, ``, ``],
  paper_first: [
    <>
      논문 초록
      <br />
      저널 커버레터 <br />
      컨퍼런스 페이퍼
      <br />
      레포트 등
    </>,
    <>
      Abstract
      <br />
      Presentation Scripts
      <br />
      Conference Papers
      <br />
      Research Reports, etc.
    </>,
    's_correction.paper_first',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  program_cost: [
    `프로그램 비용 및 절차 안내`,
    `Guide for pricing and process`,
    's_correction.program_cost',
    ``,
    ``,
    ``,
    ``,
  ],
  cost_info: [
    <>
      기본료: 7,700원 ( ~ 100단어 까지)
      <br />
      추가비용: 단어 당 77원 <Br md down />
      [예: 500단어 기준 38,500원]
      <br />
      소요시간: 영문서 제출 후 48시간 이내
    </>,
    <>
      Basic fee: 7,700 KRW (up to 100 words)
      <br />
      Additional fee: 77 KRW per word
      <br />
      (ex. 500-words essay will be 38,500 KRW)
      <br />
      Estimated time: within 48 hours of submission
    </>,
    's_correction.cost_info',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_and_pay: [
    <>
      교정 요청 & 결제
      <br />
      (자동 견적 제공)
    </>,
    <>Submit document and pay</>,
    's_correction.correction_and_pay',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  connect_editor: [
    <>
      원어민 에디터 연결
      <br />
      (에디터 지정 시 1.3배 과금)
    </>,
    <>Connect with editor</>,
    's_correction.connect_editor',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  get_result: [
    <>
      교정 결과물 빠르게 받기
      <br />
      (48시간 이내)
    </>,
    <>Get your edits in 48 hours</>,
    's_correction.get_result',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  more_correction: [
    <>
      후속 교정 요청 시 진행
      <br />
      (추가 부분결제)
    </>,
    <>Iterate at additional cost</>,
    's_correction.more_correction',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  important_ringle: [
    `중요한 영문서는 링글과 함께하세요!`,
    <>
      Ringle will perfect
      <br />
      your English writings!
    </>,
    's_correction.important_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  more_accurate: [
    <>
      한글을 영문으로 번역하거나 영어를 대필하진 않지만
      <Br md up />
      &nbsp;영문서의 의도와 목적을 이해하고 누구보다 정확히 전달합니다
    </>,
    <>
      We do not translate KOR-ENG or ghostwrite, <br />
      but we will help you express your ideas most effectively
    </>,
    's_correction.more_accurate',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  see_estimation: [`교정 견적 보기`, `See estimated price`, 's_correction.see_estimation', ``, ``, ``, ``],
  for_your_intention: [
    <>목적에 맞는 글로 교정합니다</>,
    <>Edit your piece technically and holistically</>,
    's_correction.for_your_intention',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_mode: [`Correction Mode`, `Correction Mode`, 's_correction.correction_mode', ``, ``, ``, ``],
  paraphrase_mode: [`Paraphrase Mode`, `Paraphrase Mode`, 's_correction.paraphrase_mode', ``, ``, ``, ``],
  correct_grammar: [
    `문법을 교정하고 문맥에 맞는 표현으로 교정`,
    `Correct grammar and edit short phrases`,
    's_correction.correct_grammar',
    ``,
    ``,
    ``,
    ``,
  ],
  more_natural: [
    `문장과 문단을 더 자연스럽게 새로 작성`,
    <>Rewrite sentences and paragraphs</>,
    's_correction.more_natural',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_landing_review = {
  you_think_same: [
    <>
      영어 실력의 성장, <Br md down />
      그로 인해 찾아온 인생의 변화
    </>,
    ``,
    's_landing_review.you_think_same',
    <>
      因英语实力的增长
      <Br md down />
      而引发的人生改变
    </>,
    <>
      由於英語實力增長，
      <Br md down />
      因此出現人生的變化
    </>,
    <>
      英語力の成長
      <Br md down />
      がもたらした人生の変化
    </>,
    ``,
  ],
  check_out_reviews: [
    <>
      {isMobile ? (
        <>
          수강생들의
          <br />
          솔직한 이야기를
          <br />
          들어보세요
        </>
      ) : (
        <>
          수강생들의 솔직한
          <br />
          이야기를 들어보세요
        </>
      )}
    </>,
    <>
      Join the community
      <br />
      of learners & dreamers
    </>,
    's_landing_review.check_out_reviews',
    <>
      {isMobile ? (
        <>
          请听一下
          <br />
          学生们的
          <br />
          真实想法吧
        </>
      ) : (
        <>
          请听一下
          <br />
          学生们的真实想法吧
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          來聽一聽
          <br />
          學生們的
          <br />
          誠實心得吧！
        </>
      ) : (
        <>
          來聽一聽學生們的
          <br />
          誠實心得吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          受講生たちの
          <br />
          正直な感想を
          <br />
          聴いてみてください。
        </>
      ) : (
        <>
          受講生たちの正直な
          <br />
          感想を聴いてみてください。
        </>
      )}
    </>,
    <>
      Tham gia cùng những người
      <br />
      dám nghĩ và dám làm
    </>,
  ],
  find_out_interested: [
    `관심 있는 대화 주제를 찾아보세요`,
    <>
      <b>
        Look for a conversation topic
        <br />
        of your interest.
      </b>
    </>,
    's_landing_review.find_out_interested',
    `请寻找您感兴趣的会话主题`,
    `請尋找您感興趣的對話主題`,
    `関心のある会話テーマを探してみましょう。`,
    <>
      <b>
        Tìm chủ đề giao tiếp
        <br />
        theo sở thích.
      </b>
    </>,
  ],
  whenever_wherever: [
    `MBA 출신 집필진이 만든 온라인 교재를 언제 어디서나!`,
    `Access online materials made by MBA tutors!`,
    's_landing_review.whenever_wherever',
    `随时随地均可阅览MBA出身的编纂人员全心打造的在线教材!`,
    `隨時隨地觀看MBA出身的執筆團隊打造的線上教材！`,
    `MBA出身ライターが作ったオンライン教材をいつでもどこでも！`,
    `Truy cập tài liệu trực tuyến viết bởi các gia sư MBA!`,
  ],
  companies_using_english: [
    `영어 쓰는 회사`,
    `Global Career`,
    's_landing_review.companies_using_english',
    `使用英语的公司`,
    `使用英語的公司`,
    `英語を使う会社`,
    `Sự nghiệp toàn cầu`,
  ],
  presentation_report_paper: [
    `발표/보고/논문`,
    `Presentation/Report/Paper`,
    's_landing_review.presentation_report_paper',
    `发表/报告/论文`,
    `發表／報告／論文`,
    `発表/報告/論文`,
    `Thuyết trình/Báo cáo/Nghiên cứu`,
  ],
  interview_essay: [
    `인터뷰/에세이`,
    `Interview/Essay`,
    's_landing_review.interview_essay',
    `面试/短文`,
    `面試／申論`,
    `面接/エッセイ`,
    `Phỏng vấn/Bài luận`,
  ],
  study_abroad_immigrant: [
    `유학/해외거주`,
    `Study Abroad/Immigration`,
    's_landing_review.study_abroad_immigrant',
    `留学/海外居住`,
    `留學／海外居住`,
    `留学/海外居住`,
    `Du học/Định cư`,
  ],
  tutors_who_fit_me: [
    `핏이 맞는 튜터`,
    `Tutors who fit me`,
    's_landing_review.tutors_who_fit_me',
    `适合自身的教师`,
    `適合自己的任課教師`,
    `自分に合うチューター`,
    `Gia sư phù hợp với tôi`,
  ],
  free_schedule: [
    `자유로운 스케쥴`,
    `Flexible schedule`,
    's_landing_review.free_schedule',
    `自由随心的时间表`,
    `自由自在的日程`,
    `自由なスケジュール`,
    `Thời khoá biểu linh hoạt`,
  ],
  overcome_fear: [
    `두려움 극복`,
    `Overcome fear`,
    's_landing_review.overcome_fear',
    `克服内心的恐惧`,
    `克服恐懼`,
    `恐怖克服`,
    `Vượt qua nỗi sợ`,
  ],
  business_kdy: [
    `비즈니스 / 김도엽님`,
    `Business / Doyup Kim`,
    's_landing_review.business_kdy',
    `商务人士 / Mr. Kim`,
    `商務人士／Mr. Kim`,
    `ビジネス / キム・ドヨプさん`,
    `Kinh doanh / Doyup Kim`,
  ],
  mba_cdh: [
    `MBA / 최다혜님`,
    `MBA / Dahye Choi`,
    's_landing_review.mba_cdh',
    `MBA / Ms. Choi`,
    `MBA／Ms. Choi`,
    `MBA / チェ・ダヘさん`,
    `MBA / Dahye Choi`,
  ],
  study_abroad_wjh: [
    `해외취업 / 우지혜님`,
    `Applying for Overseas Jobs / Jihye Woo`,
    's_landing_review.study_abroad_wjh',
    `海外就业 / Ms. Woo`,
    `海外就業／Ms. Woo`,
    `海外就職 / ウ・ジヘさん`,
    `Ứng tuyển các công việc ở nước ngoài / Jihye Woo`,
  ],
  interview_jhs: [
    `인터뷰 / 정희선님`,
    `Applying for Overseas Jobs / Jihye Woo`,
    's_landing_review.interview_jhs',
    `面试 / Ms. Chung`,
    `面試／Ms. Chung`,
    `面接 / チョン・ヒソンさん`,
    `Ứng tuyển các công việc ở nước ngoài / Jihye Woo`,
  ],
  correction_lsy: [
    `영문서교정 / 이상윤님`,
    `Editing Service / Sangyoon Lee`,
    's_landing_review.correction_lsy',
    `英文书面文本校正 / Mr. Lee`,
    `糾正英文文件／Mr. Lee`,
    `英文校正 / イ・サンユンさん`,
    `Dịch vụ chỉnh sửa / Sangyoon Lee`,
  ],
  study_aboard_yhs: [
    `유학생 / 양희승님`,
    `International Student / Heeseung Yang`,
    's_landing_review.study_aboard_yhs',
    `留学生 / Mr. Yang`,
    `留學生／Mr. Yang`,
    `留学生 / ヤン・ヒスンさん`,
    `Sinh viên quốc tế / Heeseung Yang`,
  ],
  junior_hsm: [
    `Junior / 홍상민님`,
    `Junior / Sangmin Hong`,
    's_landing_review.junior_hsm',
    `Junior / Mr. Hong`,
    `Junior／Mr. Hong`,
    `Junior / ホン・サンミンさん`,
    `Sinh viên năm 3 / Sangmin Hong`,
  ],
  live_abroad_psy: [
    `해외거주 / 박서영님`,
    `Overseas Resident / Seoyoung Park`,
    's_landing_review.live_abroad_psy',
    `海外居住 / Ms. Park`,
    `海外居住／Ms. Park`,
    `海外居住 / パク・ソヨンさん`,
    `Định cư nước ngoài / Seoyoung Park`,
  ],
  only_one: [
    <>정말 유창한 수준까지 올리기 위해 대체할 수 없는, 지금 한국에서는 유일한 서비스라고 생각해요.</>,
    <>Ringle is an irreplaceable service that helps people to speak English at a native speaker's level.&nbsp;</>,
    's_landing_review.only_one',
    <>为了能够达到英语流畅水平, 我认为这是韩国当前独一无二的英语学习服务平台, 无可替代。</>,
    <>我認為這是目前韓國唯一可以真正提升至流暢程度的英語教學服務。</>,
    <>本当に流暢なレベルまで上げるためには他に代わるもののない、今韓国に唯一のサービスだと思います。</>,
    <>Ringle là dịch vụ không thể thay thế giúp bạn nói tiếng Anh như người bản xứ.&nbsp;</>,
  ],
  hbs_kdy: [
    `HBS class of 2019 / 김도엽님`,
    `HBS Class of 2019/ Doyup Kim`,
    's_landing_review.hbs_kdy',
    `HBS class of 2019 / Mr. Kim`,
    `HBS class of 2019／Mr. Kim`,
    `HBS class of 2019 / キム・ドヨプさん`,
    `Trường Kinh doanh Harvard khoá 2019/ Doyup Kim`,
  ],
  opportunity: [
    <>영어를 잘함으로써 얻을 수 있는 기회는 100배 아니, 1000배쯤 되는 것 같아요.</>,
    <>
      Speaking fluent English opens the door to <b>infinite opportunities.</b>
    </>,
    's_landing_review.opportunity',
    <>学好英语能够获得机会的可能性应该是100倍, 不对, 应该1000倍。</>,
    <>學好英語可以獲得的機會好像是100倍，不對，應該是1000倍。</>,
    <>英語が上手なことで得られる機会は100倍、いや、1000倍くらいになると思います。</>,
    <>
      Giao tiếp tiếng Anh tốt mở ra cảnh cửa đến <b>vô vàn cơ hội.</b>
    </>,
  ],
  hbs_cdh: [
    `HBS class of 2020 / 최다혜님`,
    `HBS Class of 2020/ Dahye Choi`,
    's_landing_review.hbs_cdh',
    `HBS class of 2020 / Ms. Choi`,
    `HBS class of 2020／Ms. Choi`,
    `HBS class of 2020 / チェ・ダヘさん`,
    `Trường Kinh doanh Harvard khoá 2020/ Dahye Choi`,
  ],
  ringle_can_change: [
    <>미국에서도 교정해주지 않던 영어, 링글에서 바꿀 수 있었어요.</>,
    <>
      My Ringle tutor corrected my English <b>like no one else.</b>
    </>,
    's_landing_review.ringle_can_change',
    <>即使在美国也没能得到纠正的英语问题，在Ringle得到了改正。</>,
    <>即使在美國也未能獲得糾正的英語，我在Ringle得到改善。</>,
    <>アメリカでも正してくれなかった英語、Ringleで変えることができました。</>,
    <>
      Gia sư của tôi ở Ringle sửa lỗi tiếng Anh cho tôi <b>theo một cách đặc biệt.</b>
    </>,
  ],
  pratt_wjh: [
    `Pratt Institute 석사과정 / 우지혜님`,
    `M.A Candidate, Pratt Institute/ Jihye Woo`,
    's_landing_review.pratt_wjh',
    `Pratt Institute硕士研究生 / Ms. Woo`,
    `Pratt Institute碩士班／Ms. Woo`,
    `Pratt Institute 修士課程 / ウ・ジヘさん`,
    `Thạc sĩ Khoa học xã hội, Học viện Pratt/ Jihye Woo`,
  ],
  resume_correction: [
    <>레주메 교정부터 영어 인터뷰까지 링글에서 준비하고 목표하는 회사에 입사했어요.</>,
    <>I prepared every step of my job application process with Ringle and got a position in my dream company.</>,
    's_landing_review.resume_correction',
    <>从履历校正到准备英语面试, 通过在Ringle的学习我顺利进入了希望的公司。</>,
    <>從糾正簡歷到英語面試，我在Ringle準備並進入理想的公司。</>,
    <>レジュメ校正から英語面接までRingleで準備し、目標の会社に入社しました。</>,
    <>Tôi chuẩn bị cho việc ứng tuyển từ đầu đến cuối cùng Ringle và đã được nhận vị trí tại công ty mơ ước.</>,
  ],
  japan_jhs: [
    `일본 외국계 10년차 직장인 / 정희선님`,
    `Working in a Japanese multinational company for 10 years/ Heeseon Jung`,
    's_landing_review.japan_jhs',
    `日本外企10年职员 / Ms. Chung`,
    `進入日資公司9年餘的上班族／Ms. Chung`,
    `日本外資系10年目会社員 / チョン・ヒソンさん`,
    `Làm việc trong công ty đa quốc gia của Nhật hơn 10 năm/ Heeseon Jung`,
  ],
  from_school: [
    <>그냥 영어 선생님이 아닌, 제가 목표하는 학교 출신 에디터라서 굉장히 도움을 많이 받았습니다.</>,
    <>
      My tutor was <b>from my dream school</b> and gave me the best advice.
    </>,
    's_landing_review.from_school',
    <>不仅是一名英语教师, 而是出身我梦想学校的编辑, 课程让我受益匪浅。</>,
    <>不僅是英語教師，編輯來自我嚮往的學校，讓我受益匪淺。</>,
    <>ただの英語の先生ではなく、私が目標とする学校出身のエディターなので、とても助けてもらいました。</>,
    <>
      Gia sư của tôi <b>đến từ ngôi trường tôi mơ ước</b> và đưa ra những lời khuyên tuyệt vời.
    </>,
  ],
  hbs_lsy: [
    `HBS class of 2020 / 이상윤님`,
    `HBS Class of 2020/ Sangyoon Lee`,
    's_landing_review.hbs_lsy',
    `HBS class of 2020 / Mr. Lee`,
    `HBS class of 2020／Mr. Lee`,
    `HBS class of 2020 / イ・サンユンさん`,
    `Trường Kinh doanh Harvard khoá 2020/ Sangyoon Lee`,
  ],
  american_view: [
    <>미국인 관점에서도 영어로 말을 잘 하고 글을 잘 쓰는 튜터들이 링글 튜터들이에요.</>,
    <>Ringle tutors are great speakers and writers, even for natives</>,
    's_landing_review.american_view',
    <>Ringle教师的英语会话及写作水平, 在美国人中都是佼佼者。</>,
    <>Ringle任課教師的英語會話及寫作水準在美國人眼中也都很高。</>,
    <>アメリカ人の観点でも英語で話すのが上手で文章力のあるチューターがRingleのチューターです。</>,
    <>Gia sư Ringle là những diễn giả và cây viết xuất sắc, kể cả cho người bản xứ</>,
  ],
  vanderbilt_yhs: [
    `Vanderbilt University 유학생 / 양희승님`,
    `Vanderbilt University/ Heeseung yang`,
    's_landing_review.vanderbilt_yhs',
    `Vanderbilt University留学生 /  Mr. Yang`,
    `Vanderbilt University留學生／Mr. Yang`,
    `Vanderbilt University 留学生 / ヤン・ヒスンさん`,
    `Trường Đại học Vanderbilt/ Heeseung yang`,
  ],
  not_in_korea: [
    <>저희 아들이 하버드 출신 튜터를 만나 대화를 나눈적은 없었죠. 한국에서 이런 기회 잡기는 어렵습니다.</>,
    <>
      Before Ringle, son had never studied with a tutor from Harvard. It's hard to have such opportunity in Korea.&nbsp;
    </>,
    's_landing_review.not_in_korea',
    <>我儿子没有和哈佛出身的教师进行过对话, 在韩国这种机会是很难得的。</>,
    <>我兒子不曾和哈佛出身的任課教師對話，在韓國很難有這種機會。</>,
    <>
      私の息子はハーバード出身のチューターに出会って会話したことがありませんでした。韓国でこんなチャンスを掴むのは難しいです。
    </>,
    <>
      Trước Ringle, con trai tôi chưa bao giờ học cùng một gia sư từ Hardvard. Rất khó để tìm một cơ hội như vậy ở Hàn
      Quốc.&nbsp;
    </>,
  ],
  nextrans_hsm: [
    `Nextrans 대표 / 홍상민님`,
    `CEO, Nextrans/ Sangmin Hong`,
    's_landing_review.nextrans_hsm',
    `Nextrans代表 / Mr. Hong`,
    `Nextrans代表／Mr. Hong`,
    `Nextrans 代表 / ホン・サンミンさん`,
    `CEO, Nextrans/ Sangmin Hong`,
  ],
  mba_intern: [
    <>2년간 180회 이상 수업하면서 MBA 지원부터 미국생활 그리고 인턴십까지 도움 받았어요.</>,
    <>
      I took more than 180 lessons for 2 years, and got help with my MBA applications, life in the US, and internship.
    </>,
    's_landing_review.mba_intern',
    <>两年时间听了180堂课以上, 从帮我准备MBA开始到去美国生活, 经历工作实习等过程都给我提供了很多的帮助。</>,
    <>兩年之間上了180次以上的課程，從我報名MBA、美國生活至工作實習，都得到了許多幫助。</>,
    <>2年間で180回以上レッスンしながらMBAのサポートからアメリカ生活、そしてインターンシップまで助けてもらいました。</>,
    <>
      Tôi đã học hơn 180 buổi học trong 2 năm qua, nhận được sự giúp đỡ cho việc nộp đơn học MBA, cuộc sống ở Mỹ và công
      việc thực tập.
    </>,
  ],
  nyu_psy: [
    `NYU Stern MBA / 박서영님`,
    `NYU Stern MBA/ Seoyoung Park`,
    's_landing_review.nyu_psy',
    `NYU Stern MBA / Ms. Park`,
    `NYU Stern MBA／Ms. Park`,
    `NYU Stern MBA / パク・ソヨンさん`,
    `NYU Stern MBA/ Seoyoung Park`,
  ],
}
export const s_case_professional = {
  who_need_pro_eng: [
    <>
      <Div spanTag textSecondary>
        외국계/해외관련 직무
      </Div>{' '}
      또는
      <br />
      의학/법학/디자인 등
      <br />
      <Div spanTag textSecondary>
        전문 영어
      </Div>
      가 필요하신 분
    </>,
    <>Build an international career</>,
    's_case_professional.who_need_pro_eng',
    <>
      <Div spanTag textSecondary>
        外国企业/海外相关工作
      </Div>
      或是从事于
      <br />
      医学/法学/设计等
      <br />
      <Div spanTag textSecondary>
        需要专业英语水平
      </Div>
      的人员
    </>,
    <>
      <Div spanTag textSecondary>
        外資公司／海外相關職務
      </Div>
      或從事於
      <br />
      醫學／法學／設計等
      <br />
      需要
      <Div spanTag textSecondary>
        專業英語
      </Div>
      的人士
    </>,
    <>
      <Div spanTag textSecondary>
        外資系/海外関連職務
      </Div>{' '}
      または
      <br />
      医学/法学/デザインなど
      <br />
      <Div spanTag textSecondary>
        専門英語
      </Div>
      が必要な方
    </>,
    <>Xây dựng sự nghiệp quốc tế</>,
  ],
  specific_part: [
    <>
      전문적이고 품격 있는 영어를
      <br />
      자신있게 구사하며
      <br />
      글로벌 커리어를 꿈꿉니다
    </>,
    <>Hone your business English and learn sector-specific terminologies</>,
    's_case_professional.specific_part',
    <>
      充满自信掌握
      <br />
      专业且高品质的英语
      <br />
      梦想成为国际化人才
    </>,
    <>
      充滿自信地說出
      <br />
      專業且具有品格的英語，
      <br />
      夢想國際化職涯。
    </>,
    <>
      専門的で品格のある英語を
      <br />
      自信を持って駆使しながら
      <br />
      グローバルキャリアを夢見ています。
    </>,
    <>Rèn giũa kỹ năng tiếng Anh trong công việc và học các từ chuyên ngành</>,
  ],
  pro: [
    `#외국계회사`,
    `#Professional_English`,
    's_case_professional.pro',
    `#外资企业`,
    `#外資公司`,
    `#外資系企業`,
    `#Tiếng_Anh_cho_công_việc`,
  ],
  major: [
    `#전문영어`,
    `#Meetings`,
    's_case_professional.major',
    `#专业英语`,
    `#專業英語`,
    `#専門英語`,
    `#Các_buổi_họp`,
  ],
  conference: [
    `#비즈니스영어`,
    `#Business_English`,
    's_case_professional.conference',
    `#商务英语`,
    `#商務英語`,
    `#ビジネス英語`,
    `#Tiếng_Anh_thương_mại`,
  ],
  presentation: [
    `#글로벌커리어`,
    `#Conferences`,
    's_case_professional.presentation',
    `#海外岗位`,
    `#國際化職涯`,
    `#グローバルキャリア`,
    `#Hội_nghị`,
  ],
  are_there_pros: [
    `일할 때 영어가 필요한데, 링글이 도움이 될까요?`,
    `I use English at work. Will studying with Ringle help?`,
    's_case_professional.are_there_pros',
    `工作时需要使用英语, Ringle能否助您一臂之力?`,
    `工作時需要使用英語，Ringle會有幫助嗎？`,
    `働く時に英語が必要なのですが、Ringleは役立ちますか？`,
    `Tôi sử dụng tiếng Anh trong công việc. Liệu học ở Ringle có giúp ích cho tôi?`,
  ],
  many_pros: [
    <>
      네, 링글 수강생의 약 50%는 외국계, 글로벌 회사에 재직 중이시거나 해외 출장, 영어 회의, 발표, 이메일 작성 등으로
      업무 중 영어 사용이 잦은 직장인이십니다. 뿐만 아니라 LLM을 준비하시는 변호사, MBA 및 해외 클라이언트와의 미팅을
      준비하시는 컨설턴트, 회계사, 영어 강의 및 해외연수, 콘퍼런스를 준비하시는 의사, 교수 등 다양한 전문 분야
      종사자들께서 링글을 사용하고 계십니다.
      <br />
      <br />
      영어로 일하는 직장인 분들은 링글 수강 후 "정말 내가 필요한 말을 영어로 명확하게 전달하는 능력을 키울 수 있어
      좋았다.", "영어가 항상 마음의 짐처럼 느껴졌는데, 이제는 자신 있게 내 역량을 영어로 보여줄 수 있게 되었다."라고
      말씀하십니다.
    </>,
    <>
      Yes! About half of Ringlers are working professionals who often need to communicate in English.
      <br />
      <br />
      Lawyers use Ringle to prepare for LLM, consultants to prepare for meetings with clients, professors to conduct
      courses in English, and doctors to prepare for overseas training programs or conferences.
      <br />
      <br />
      The list goes on: our students are engineers, investors, accountants, designers, and more, whose job entails
      English communications or who are looking to make a transition into a more global role.
    </>,
    's_case_professional.many_pros',
    <>
      当然, Ringle中有超过50%的学员就职于外资企业, 跨国公司, 当然也包含那些经常使用英语进行会话, 发表,
      邮件书写等业务的职场人士。同时学员中也不乏一些正在准备LLM的律师, MBA或预备和海外客户进行会议的顾问人员, 会计师,
      准备英语授课, 海外研修以及需要进行电话会议的医生, 教授等多领域的从业人员。
      <br />
      <br />
      使用英语工作的职场人士们在体验过Ringle的课程后, 经常能够听到他们发出‘能够提升我的英语会话实力,
      用英语准确表达自身想法的感觉真是太好了’, ‘英语一直是我的一种心理负担,
      现在终于具备使用英语表达自我的能力了’等这样的感叹。
    </>,
    <>
      是的，Ringle超過50%的學生於外資公司、跨國企業工作，或到國外出差、進行英語會議、發表、撰寫電子郵件等業務上經常使用英語的上班族。不僅如此，學生中也不乏一些準備LLM的律師、準備MBA或與海外客戶開會的顧問或會計師、準備英語課程、海外研修或研討會的醫生或教授等各界領域人士。
      <br />
      <br />
      使用英語工作的上班族在上過Ringle課程後表示：「能夠提升使用英語明確表達我需要說的話的能力，真的很棒。」、「英語在我心中一直是一種心理負擔，現在終於可以充滿自信地使用英語展現我的能力。」
    </>,
    <>
      はい。Ringle受講生の約50%は外資系やグローバル企業に在職中であったり、海外出張や英語会議、発表、メール作成など、仕事中に英語の使用が多い会社員です。それだけでなく、LLMを準備中の弁護士、MBAおよび海外クライアントとのミーティングを準備するコンサルタント、会計士、英語講義および海外研修、カンファレンスを準備している医師や教授など、多様な専門分野の従事者がRingleを使用されています。
      <br />
      <br />
      英語で働く会社員はRingle受講後「本当に自分が必要な言葉を英語で明確に伝える能力を育てることができてよかった」「英語がいつも心の重荷だったが、今では自信を持って自分の力を英語で発揮することができるようになった」とおっしゃっています。
    </>,
    <>
      Có chứ! Gần một nửa học viên Ringle đang đi làm và thường xuyên sử dụng tiếng Anh trong giao tiếp.
      <br />
      <br />
      Luật sư học Ringle để chuẩn bị cho Chương trình Thạc sĩ Luật, các tư vấn viên chuẩn bị cho buổi họp với khách
      hàng, giáo sư chuẩn bị cho các lớp học giảng dạy bằng tiếng Anh, và các bác sĩ chuẩn bị cho các chương trình tập
      huấn và hội nghị ở nước ngoài.
      <br />
      <br />
      Và còn rất nhiều trường hợp khác: học sinh của chúng tôi là kỹ sư, nhà đầu tư, kế toán, designer và những ngành
      nghề khác sử dụng tiếng Anh trong giao tiếp hoặc những ai đang tìm kiếm cơ hội cho một môi trường quốc tế.
    </>,
  ],
  question: [
    <>어떻게 링글로 직장 및 전문 분야 영어 실력을 향상시키나요?</>,
    `Here’s how Ringle is the best solution for global professionals. At Ringle, you can:`,
    's_case_professional.question',
    <>如何通过Ringle提升职场及专业领域的英语水平呢?</>,
    <>如何透過Ringle提升職場及專業領域的英語實力呢？</>,
    <>どうやってRingleで仕事および専門分野の英語力を向上させるんですか？</>,
    `Ringle cung cấp giải pháp tốt nhất cho người đi làm. Tại Ringle, bạn có thể:`,
  ],
  bullet_1: [
    <>
      실시간 교정 화면을 통해 제공되는 링글의 ‘눈에 보이는 교정’은 단순 귀로만 들리는 피드백 대비 기억에 남는 교정을
      제공합니다. 링글의 체계적인 수업 시스템으로 영어 실력을 빠르게 성장시키세요. 수업 후 제공되는 녹음 파일, 스크립트,
      AI 분석, 튜터 피드백으로 영어 실력을 점검하고 언제든 복습할 수 있습니다.
    </>,
    `Select a tutor who share your areas of study. Medical doctors can select tutors with a biology major, lawyers can select tutors with a law or social science major, and consultants can select tutors a business or economics major`,
    's_case_professional.bullet_1',
    <>
      通过实时纠正画面, 打造Ringle'可直观地纠正’这一方式, 比单纯只依靠听力进行的纠错方式, 能够给学员留下更深的印象,
      同时Ringle采用系统化的教学体系, 有助于学员快速提升英语水平。课程结束后所提供的录音文件, 脚本,
      AI分析及教师反馈等内容, 均可让学员对自身英语水平进行准确判断, 同时有助于随时进行复习。
    </>,
    <>
      Ringle透過即時糾正畫面提供「看得到的糾正」，比起單純只依靠聽力的糾正更能留下深刻的印象。同時，Ringle採用系統化的教學系統，有助於學生快速提升英語實力。利用課程結束後提供的錄音檔、腳本、AI分析、任課教師意見回饋，可檢驗自己的英語實力並隨時複習。
    </>,
    <>
      リアルタイム添削画面で提供されるRingleの「目に見える校正」は、単に耳で聞くフィードバックに比べて記憶に残る添削を提供します。Ringleの体系的なレッスンシステムで英語力を早く成長させましょう。レッスン後に提供される録音ファイル、スクリプト、AI
      Analysis、チューターからのフィードバックで英語力をチェックして、いつでも復習することができます。
    </>,
    `Chọn gia sư học cùng lĩnh vực với bạn. Bác sĩ có thể chọn gia sư chuyên ngành về sinh học, luật sư chọn gia sư liên quan đến luật hoặc các ngành khoa học xã hội, và tư vấn viên chọn gia sư thuộc chuyên ngành kinh doanh hoặc kinh tế`,
  ],
  bullet_2: [
    `매번 똑같은 일상 회화 대신, 비즈니스/전문 분야 관련 주제로 대화하세요. 자유주제 수업으로 대화나 교정을 원하는 자료를 업로드할 수도 있으며, 나의 전문 분야를 이해하는 특정 전공의 튜터와 깊이 있는 대화를 나누고 영어를 교정받을 수도 있습니다.`,
    `Find lesson materials that can guide an in-depth discussion the latest current events in your sector`,
    's_case_professional.bullet_2',
    `相比于不断重复的日常对话，选择商务/专业领域的主题进行英语对话吧。通过自由主题课程, 可上传自己希望进行的对话及纠正材料, 同时和了解自身专业领域的特定教师进行深入对话, 能够更加有效的对英语问题进行修正及理解。`,
    `選擇商務／專業領域的相關主題對話，取代每次一樣的日常對話吧！也可透過自由主題課程，上傳自己希望對話或糾正的資料。也能和了解您專業領域的特定主修任課教師進行深度對話，糾正您的英語。`,
    `毎回同じ日常会話の代わりに、ビジネス/専門分野に関するテーマで会話をしましょう。自由テーマレッスンでは、会話や校正してもらいたい資料をアップロードすることができ、自分の専門分野を理解する特定専攻のチューターと深みのある会話をして、英語の校正を受けることもできます。`,
    `Tìm kiếm những tài liệu có thể giúp bạn thảo luận sâu về tin tức thời sự trong ngành của bạn`,
  ],
  bullet_3: [
    `바쁜 일정 틈틈이, 주말, 주중 새벽/늦은 시간을 활용해보세요. 링글 수업은 연중무휴로 주말 포함 새벽 5시~오후 2시, 오후 7시~새벽 3시 수업까지 자유롭게 예약 가능합니다.`,
    `Take lessons around your busy schedule, be it over the weekend, early morning, or late night`,
    's_case_professional.bullet_3',
    `灵活应用繁忙日常的零散时间, 周末及工作日的清晨/夜间吧。Ringle课程全年无休, 从清晨5点~下午2点, 晚7点~凌晨3点, 均可自由预约课程。`,
    `活用繁忙日常的零散時間、週末、平日凌晨／深夜吧！Ringle課程全年無休，週末也不例外，您可自由預約從凌晨5點～下午2點、晚上7點～凌晨3點的課程。`,
    `忙しいスケジュールの合間に、週末、平日の早朝/遅い時間を活用してみましょう。Ringleのレッスンは年中無休で、週末を含め午前5時〜午後2時、午後7時〜午前3時のレッスンまで自由に予約可能です。.`,
    `Lựa chọn buổi học vào thời gian rảnh, có thể là vào cuối tuần, sáng sớm hoặc khuya`,
  ],
  quote: [
    <>
      "제 꿈은 제약 없이 경쟁할 수 있게 되는 것입니다. 내가 가진 역량이 영어 때문에 표현되지 못하는 일이 없도록 하는
      것이 영어를 공부하는 가장 큰 이유입니다. 정말 유창한 수준까지 올리기 위해서는 대체될 수 없는, 한국에서는 유일한
      서비스라고 생각해요."
    </>,
    <>
      "I wanted to compete in the global job market without barriers. I wanted to make sure that my competence isn't
      capped by my lack of English fluency... Ringle was the only service that could help me get there."
    </>,
    's_case_professional.quote',
    <>
      "我的梦想就是没有短板地进行竞争。学习英语最大的理由就是可以让我尽情用英语表达我希望表达的一切。我认为这里是能够让英语达到流畅水平,
      无可替代的服务平台。"
    </>,
    <>
      「我的夢想就是可以不受限制地競爭。學習英語最大的理由就是為了希望可以利用我的英語實力表達我想表達的一切。我認為這是目前韓國唯一可以真正提升至流暢程度的英語教學服務。」
    </>,
    <>
      "私の夢は、制約されずに競争できるようになることです。自分の力が英語のせいで表現できないことのないようにすることが、英語を勉強する最大の理由です。本当に流暢なレベルまで上げるためには他に代わるもののない、韓国では唯一のサービスだと思います。"
    </>,
    <>
      "Tôi muốn cạnh tranh trong thị trường làm việc toàn cầu mà không gặp bất kì rào cản nào. Tôi muốn chắc chắn rằng
      năng lực của mình không bị giới hạn bởi khả năng tiếng Anh... Ringle là dịch vụ duy nhất giúp tôi đạt được điều
      này."
    </>,
  ],
  quote_writer: [
    `- HBS class of 2019 / 김도엽님`,
    `- Dylan, HBS Class of 2019`,
    's_case_professional.quote_writer',
    `- HBS class of 2019 / Mr. Kim`,
    `- HBS class of 2019／Mr. Kim`,
    `- HBS class of 2019 / キム・ドヨプさん`,
    `- Dylan, Trường Kinh doanh Harvard Khoá 2019`,
  ],
}
export const s_case_interview = {
  mba_abroad: [
    <>
      MBA/유학
      <br />
      해외 취업/외국계 기업
      <br />
      <Div spanTag textSecondary>
        영어 인터뷰
      </Div>{' '}
      준비
    </>,
    <>Nail your interview and get into your dream job or school</>,
    's_case_interview.mba_abroad',
    <>
      MBA/留学
      <br />
      海外就业/外资企业
      <br />
      <Div spanTag textSecondary>
        英语面试
      </Div>
      准备
    </>,
    <>
      MBA／留學
      <br />
      海外就業／外資公司
      <br />
      <Div spanTag textSecondary>
        英語面試
      </Div>
      準備
    </>,
    <>
      MBA/留学
      <br />
      海外就職/外資系企業
      <br />
      <Div spanTag textSecondary>
        英語面接
      </Div>{' '}
      準備
    </>,
    <>Vượt qua buổi phỏng vấn công việc hoặc vào ngôi trường mơ ước</>,
  ],
  like_real: [
    <>
      실전과 유사한 환경에서
      <br />
      경력 있는 원어민의 피드백을 통해
      <br />
      성공적인 인터뷰를 준비합니다
    </>,
    <>Speak to a tutor who has been at your place</>,
    's_case_interview.like_real',
    <>
      仿佛置身实战现场
      <br />
      通过经验丰富的母语教师的反馈
      <br />
      准备一场成功的面试吧
    </>,
    <>
      在接近實戰的環境中，
      <br />
      透過具有經歷的母語人士的意見回饋，
      <br />
      準備成功的面試吧！
    </>,
    <>
      実際と似た環境で
      <br />
      経歴のあるネイティブのフィードバックを通じて
      <br />
      面接が成功するように準備します。
    </>,
    <>Trò chuyện cùng gia sư đã ở vị trí giống như bạn</>,
  ],
  interview: [
    `#영어인터뷰`,
    `#MBA_Interview`,
    's_case_interview.interview',
    `#英语面试`,
    `#英語面試`,
    `#英語面接`,
    `#Phỏng_vấn_MBA`,
  ],
  MBA: [`#MBA`, `#Job_Interview`, 's_case_interview.MBA', `#MBA`, `#MBA`, `#MBA`, `#Phỏng_vấn_công_việc`],
  move_job: [
    `#외국계기업`,
    `#Case_Interview`,
    's_case_interview.move_job',
    `#外资企业`,
    `#外資公司`,
    `#外資系企業`,
    `#Phỏng_vấn_khác`,
  ],
  job_abroad: [
    `#해외취업`,
    `#Tell_your_story`,
    's_case_interview.job_abroad',
    `#海外就业`,
    `#海外就業`,
    `#海外就職`,
    `#Chia_sẻ_câu_chuyện`,
  ],
  can_ringle_interview: [
    `영어 인터뷰를 링글 화상영어로 준비할 수 있나요?`,
    `Can I prepare for an English interview with Ringle?`,
    's_case_interview.can_ringle_interview',
    `英语面试可通过Ringle线上英语进行准备吗?`,
    `可透過Ringle的視訊英語準備英語面試嗎？`,
    `英語面接をRingleオンライン英会話で準備することはできますか？`,
    `Tôi có thể chuẩn bị cho buổi phỏng vấn tiếng Anh cùng Ringle không?`,
  ],
  many_interview: [
    <>
      네, 링글 수강생의 10-15%는 유학, 케이스 인터뷰, 해외 취업 및 이직을 위한 영어 인터뷰 준비 등에 링글을 활용하고
      계십니다.
      <br />
      <br />
      영어 인터뷰는 결국 면접관과 대화하며 주어진 시간 안에 '나'라는 사람을 드러내는 과정입니다. 링글을 통해 인터뷰를
      준비하셨던 분들은, "말하고자 하는 바를 간결하고 격식 있는 영어로 표현하는 능력을 키우는 데에 있어 큰 도움이
      되었다."라고 말씀하셨습니다.
    </>,
    <>
      Sure thing! About 10-15% of our customers prepare for interviews with us, whether it is an interview for school
      admission, case interview for consultant positions, online interviews for getting an overseas job, and even
      negotiations for a promotion.
      <br />
      <br />
      Our tutors have been there too - on a hot seat under a lot of pressure to nail every question thrown at them.
      <br />
      <br />
      Since interviews can carry a lot of weight in shaping your near future, they deserve an intensive preparation and
      practice.
    </>,
    's_case_interview.many_interview',
    <>
      当然, Ringle有10-15%的学员正在准备留学, 案例面试以及为海外应聘或跳槽准备面试。
      <br />
      <br />
      英语面试究其根本是和面试官间的一场对话,
      是在规定的时间内展现'自我'的过程。通过Ringle准备面试的学员经常会说‘学习对于培养简洁有格调的英语表达能力具有很大的帮助’。
    </>,
    <>
      當然，Ringle有10～15%是正在為了留學、個案面試、海外就業或換工作而準備英語面試等的學生。
      <br />
      <br />
      英語面試終究是和面試官對話，在規定時間內展現「自我」的一個過程。曾透過Ringle準備面試的學生表示：「在培養簡潔且具有格調的英語表達能力時有很大的幫助。」
    </>,
    <>
      はい。Ringle受講生の10-15%は留学、ケース面接、海外就職および転職のための英語面接の準備などにRingleを活用していらっしゃいます。
      <br />
      <br />
      英語面接は、つまり面接官と会話しながら与えられた時間内に「自分」という人を表す過程です。Ringleを通じて面接を準備した方々は「言いたいことを簡潔にしてフォーマルな英語で表現する能力を育てるのに大いに役立った」とおっしゃっています。
    </>,
    <>
      Chắn chắn rồi! Khoảng 10-15% học viên chuẩn bị buổi phỏng vấn cùng chúng tôi, đó có thể là buổi phỏng vấn với
      trường đại học, buổi phỏng vấn với vai trò tư vấn viên, phỏng vấn online cho các công việc ở nước ngoài và kể cả
      buổi đàm phán liên quan đến việc thăng chức.
      <br />
      <br />
      Gia sư của chúng tôi đã từng trong tình huống như vậy - vị trí với áp lực cao khi rất nhiều câu hỏi đặt ra cho họ.
      <br />
      <br />
      Buổi phỏng vấn rất quan trọng vì nó sẽ định hình tương lai gần của bạn, chính vì vậy nó đòi hỏi cho sự chuẩn bị và
      luyện tập kỹ càng.
    </>,
  ],
  question: [
    <>어떻게 링글을 활용해 영어 면접을 대비하나요?</>,
    `Here’s how you can leverage Ringle to make your next interview as successful as possible:`,
    's_case_interview.question',
    <>如何运用Ringle来准备英语面试呢?</>,
    <>如何運用Ringle來準備英語面試呢？</>,
    <>どうやってRingleを活用して英語面接に備えるんですか？</>,
    `Dưới đây là cách bạn tận dụng Ringle để chuẩn bị cho một buổi phỏng vấn thành công:`,
  ],
  bullet_1: [
    `레주메와 CV, 인터뷰 예상 질문 등을 업로드하고 링글 튜터와 실전처럼 영어 인터뷰를 진행해보세요. 말하기 연습은 물론, 튜터의 실시간 교정으로 원어민 관점에서 나의 답변이 어떻게 들리는지 피드백 받고, 내 답변을 한층 더 고급스러운 표현으로 업그레이드할 수 있습니다.`,
    `Upload your resume or CV as well as a description of the position you’re interviewing for and request a mock interview`,
    's_case_interview.bullet_1',
    `上传履历, CV及面试预设问题, 和Ringle的教师们进行实战般的英语面试练习。不光是单纯的口语练习, 而是通过教师们的实时纠正, 以母语使用者的角度审视我的回答并提出建设性建议, 帮助本人对答案进行完善, 实现高水品的英语表达。`,
    `請上傳簡歷、CV及面試預想問題，和Ringle的任課教師們進行如實戰ㄧ般的英語面試。不僅口說練習，透過教師的即時糾正，以母語人士的觀點聽取我的回覆並獲得意見回饋，可將我的回覆升級為更高級的用法。`,
    `レジュメやCV、面接予想質問などをアップロードし、Ringleのチューターと本番のように英語で面接を行ってみてください。スピーキングの練習はもちろん、チューターのリアルタイム校正によりネイティブの観点からあなたの回答がどのように聞こえるのかフィードバックしてもらい、あなたの回答をさらに高級な表現にアップグレードすることができます。`,
    `Tải lên resume hoặc CV và thông tin mô tả vị trí bạn ứng tuyển. Sau đó yêu cầu buổi phỏng thử`,
  ],
  bullet_2: [
    `튜터에게 예상 질문에 대한 답변 스크립트를 실시간으로 첨삭 받거나, 링글 튜터라면 해당 질문에 어떻게 답변할지를 참고해 스크립트를 작성해보세요.`,
    `You can bring your own set of expected questions or request a tutor with a relevant experience to ask questions instead`,
    's_case_interview.bullet_2',
    `请让任课教师协助修改准备好的回答，或是请教师根据您提供的履历进行模拟面试。`,
    `請讓任課教師協助修改準備好的回答，或是請教師根據您提供的履歷進行模擬面試。`,
    `チューターに予想質問の回答スクリプトをリアルタイムで添削してもらったり、Ringleのチューターなら質問にどう答えるのかを参考にスクリプトを作成してみましょう。`,
    `Bạn có thể chuẩn bị những câu hỏi dự kiến hoặc yêu cầu một gia sư với kinh nghiệm tương đương để đặt câu hỏi cho bạn`,
  ],
  bullet_3: [
    <>
      실시간 화상영어 수업으로 내 생각을 영어로 명확하고 간결하게 표현하는 연습을 해보세요. 링글 튜터는 여러분이
      강조하고 싶은 포인트를 격식 있는 영어로 표현할 수 있도록 도울 거예요. 수업이 끝난 뒤에도 녹음 파일, 스크립트, 수업
      노트 등으로 언제든 복습할 수 있습니다.
    </>,
    `Ringle tutors will not only help you deliver your ideas clearly and effectively, but comment on how to craft a persona and tell a story unique to you`,
    's_case_interview.bullet_3',
    <>
      通过实时线上英语课程,
      练习使用英语对自身想法进行明确及简洁的表达吧。Ringle教师帮助您运用高水平的英语。同时课程结束后还可随时对录音文件,
      脚本及课堂笔记等内容进行确认及复习。
    </>,
    <>
      透過即時線上英語課程，練習使用英語明確且簡潔地表達自身想法吧！Ringle任課教師將會幫助您使用專業的英文，表達您想強調的部分。您也能在課程結束後，隨時利用錄音檔、腳本及課堂筆記等內容進行複習。
    </>,
    <>
      リアルタイムオンライン英会話レッスンであなたの考えを英語で明確かつ簡潔に表現する練習をしてみましょう。Ringleのチューターは、皆さんが強調したいポイントをフォーマルな英語で表現できるようにサポートします。レッスンが終わった後も録音ファイルやスクリプト、レッスンノートなどでいつでも復習することができます。
    </>,
    `Gia sư Ringle không chỉ giúp bạn truyền đạt thông điệp của mình rõ ràng và hiệu quả, mà còn đưa ra nhận xét giúp bạn xây dựng một câu chuyện đặc biệt cho riêng mình`,
  ],
  quote: [
    <>
      "링글을 수강하는 2년 동안 약 180회 정도의 수업을 들었습니다. MBA를 준비하던 당시에는 에세이 교정과 인터뷰 준비를
      목적으로, 또 여름 인턴십 인터뷰 준비를 위해 링글을 사용했습니다. 그 덕분에 이렇게 MBA도 오고, 여름 인턴십도
      성공적으로 구해 열심히 미국 생활을 하고 있습니다."
    </>,
    <>
      "I've taken about 180 lessons on Ringle over two years. I used Ringle to prepare for MBA admission interviews and
      application essays, then again to prepare for interviews to land a summer internship. And so here I am, at an MBA
      with a summer internship!"
    </>,
    's_case_interview.quote',
    <>
      "在Ringle上课大约有两年的时间, 听了不下180堂的课程。当时在准备MBA为了校正短文,
      准备面试及当时夏季的一次实习开始使用Ringle。当然也得益于此, 我的MBA课程顺利走到今天, 也成功争取到了那次实习机会,
      当前正在美国努力生活中。"
    </>,
    `「在Ringle上課的2年期間，我聽了180次左右的課程。準備MBA的當時，我是為了糾正申論和準備面試，並在準備夏季實習的面試使用了Ringle。多虧如此，我才能來唸MBA，並成功取得夏季實習的機會，目前努力在美國生活中。」`,
    <>
      "Ringleを受講している2年間で180回ほどレッスンを受けました。MBAに向けて準備していた当時はエッセイ校正と面接準備を目的に、また夏のインターンシップの面接準備のためにRingleを使いました。そのおかげでこうしてMBAを取得しに来て夏のインターンシップにも参加し、頑張ってアメリカ生活をしています。"
    </>,
    <>
      "Tôi đã học 180 buổi với Ringle trong hai năm. Tôi dùng Ringle để chuẩn bị cho buổi phỏng vấn MBA và bài luận, và
      sau đó lại chuẩn bị cho buổi phỏng vấn vị trí thực tập hè. Và giờ thì tôi đang học MBA cùng vị trí thực tập đó!"
    </>,
  ],
  quote_writer: [
    `-NYU Stern MBA / 박서영 님`,
    `- Seoyeong, NYU Stern`,
    's_case_interview.quote_writer',
    `-NYU Stern MBA /  Ms. Park`,
    `-NYU Stern MBA／Ms. Park`,
    `-NYU Stern MBA / パク・ソヨンさん`,
    `- Seoyeong, NYU Stern`,
  ],
}
export const s_case_business = {
  for_business: [
    <>
      영어 이메일/보고서 작성
      <br />
      영어 발표, 회의 등
      <br />
      <Div spanTag textSecondary>
        비즈니스 영어
      </Div>{' '}
      대비
    </>,
    <>Communicate fluently in a corporate environment</>,
    's_case_business.for_business',
    <>
      编写英文邮件/报告书
      <br />
      英语发表及会议等
      <br />
      准备
      <Div spanTag textSecondary>
        商务英语
      </Div>
    </>,
    <>
      撰寫英文電子郵件／報告
      <br />
      準備英語發表、會議等
      <br />
      <Div spanTag textSecondary>
        商務英語
      </Div>
    </>,
    <>
      英語メール/報告書作成
      <br />
      英語発表、会議など
      <br />
      <Div spanTag textSecondary>
        ビジネス英語
      </Div>{' '}
      対策
    </>,
    <>Giao tiếp thành thạo trong môi trường chuyên nghiệp</>,
  ],
  not_everyday_english: [
    <>
      일상 회화가 아닌
      <br />
      비즈니스 상황에서 쓰이는
      <br />
      격식 있는 영어를 배웁니다
    </>,
    <>Express your thoughts intelligently everywhere you go, from formal presentations to water cooler chats</>,
    's_case_business.not_everyday_english',
    <>
      并非单纯的日常对话
      <br />
      而是学习可运用于商务场合
      <br />
      且具有深度的英语表达方式
    </>,
    <>
      並非學習日常對話，
      <br />
      而是學習可運用於商務場合
      <br />
      並具有格調的英語
    </>,
    <>
      日常会話ではなく
      <br />
      ビジネスの現場で使われる
      <br />
      フォーマルな英語を学びます。
    </>,
    <>Thể hiện suy nghĩ bất cứ nơi đâu, từ bài thuyết trình chuyên nghiệp đến buổi trò chuyện cùng đồng nghiệp</>,
  ],
  business_english: [
    `#비즈니스영어`,
    `#Business_English`,
    's_case_business.business_english',
    `#商务英语`,
    `#商務英語`,
    `#ビジネス英語`,
    `#Tiếng_Anh_thương_mại`,
  ],
  english_email: [
    `#영어이메일`,
    `#Email_writing`,
    's_case_business.english_email',
    `#英文邮件`,
    `#英文電子郵件`,
    `#英語メール`,
    `#Viết_Email`,
  ],
  conference_call: [
    `#영어회의`,
    `#Presentations`,
    's_case_business.conference_call',
    `#英语会议`,
    `#英語會議`,
    `#英語会議`,
    `#Thuyết_trình`,
  ],
  english_presentation: [
    `#영어발표`,
    `#Water_cooler_chat`,
    's_case_business.english_presentation',
    `#英语发表`,
    `#英語發表`,
    `#英語発表`,
    `#Trò_chuyện_thân_mật`,
  ],
  can_ringle_business: [
    `비즈니스 영어를 링글 화상영어로 배울 수 있나요?`,
    `Can I learn business English through Ringle?`,
    's_case_business.can_ringle_business',
    `商务英语可以通过Ringle线上英语进行学习吗?`,
    `可透過Ringle視訊英語學習商務英語嗎？`,
    `ビジネス英語をRingleオンライン英会話で学べますか？`,
    `Tôi có thể học tiếng Anh thương mại với Ringle không?`,
  ],
  many_ringle_business: [
    <>
      네, 50% 이상의 링글 수강생은 구글, 아마존, 마이크로소프트, P&G, PwC 등 외국계 회사 재직자 또는 업무 중 영어 사용이
      잦은 직장인이십니다.
      <br />
      <br />
      영어 이메일, 보고서 작성, 발표, 화상 회의 준비 등 업무에서 영어가 필요한 경우 또는 외국인 직장 동료와의 소통을
      위해, 수준 높은 영어를 구사하는 링글 튜터와 비즈니스 영어 실력을 키워보세요. 링글 튜터는 영미권 Top 30 대학
      출신으로 70% 이상이 글로벌 회사 인턴, 재직 경험을 보유하고 있습니다.
    </>,
    <>
      By all means! Over 50% of our customers work at multinational companies including Google, Amazon, Microsoft, P&G,
      and PwC and use Ringle specifically to improve their business English.
      <br />
      <br />
      Business English is not just about nailing your next formal meeting or presentations. Speaking English at a
      corporate environment means you must be ready for all kinds of interactions – from written communications like
      emails to a casual watercooler chats and a networking event.
    </>,
    's_case_business.many_ringle_business',
    <>
      当然, 50%以上的Ringle学员来自谷歌, 亚马逊, 微软,
      P&G及PwC等外资企业的在职职员以及那些在工作中经常使用英语的职场人士。
      <br />
      <br />
      为编写邮件, 报告书, 准备发表及视频会议等工作中需要英文的诸多情况以及能够更好的与外国同事进行沟通,
      借助具备高水平英语能力的Ringle教师提升自身商务英语实力吧。Ringle教师均出身Top30的英美名校,
      其中更是有超过70%教师拥有跨国企业实习或在职的工作经验。
    </>,
    <>
      當然，50%以上的Ringle的學生是Google、亞馬遜、微軟、P&G及PwC等外資公司的員工或工作時經常使用英語的上班族。
      <br />
      <br />
      為了撰寫英文電子郵件或報告書、準備發表或視訊會議等業務上需要英語的情況，或為了能和外國同事溝通，請與使用高水準英語的Ringle任課教師培養商務英語的實力。Ringle任課教師均來自於英美Top
      30名校，其中超過70%擁有在跨國企業實習或在職的經驗。
    </>,
    <>
      はい。50%以上のRingle受講生はGoogle、Amazon、Microsoft、P&G、PwCなど外資系企業の在職者、または仕事中に英語の使用が多い会社員です。
      <br />
      <br />
      英語メールや報告書の作成、発表、Web会議の準備など、業務において英語が必要な場合や職場の外国人同僚とのコミュニケーションのために、レベルの高い英語を駆使するRingleのチューターとビジネス英語力を伸ばしましょう。Ringleのチューターは英米圏Top30大学出身で、70%以上がグローバル企業のインターン、在職経験があります。
    </>,
    <>
      Chắc chắn rồi! Hơn 50% học viên của chúng tôi làm việc ở các tập đoàn đa quốc gia như Google, Amazon, Microsoft,
      P&G và PwC. Tất cả mọi người đến với Ringle để cải thiện kỹ năng tiếng Anh trong công việc.
      <br />
      <br />
      Tiếng Anh thương mại không chỉ đơn giản là vượt qua buổi họp hay thuyết trình quan trọng. Giao tiếp tiếng Anh
      trong doanh nghiệp đòi hỏi bạn sẵn sàng trong mọi trường hợp - từ kỹ năng viết như soạn email đến các cuộc trò
      chuyện thân mật hay tham dự các sự kiện networking.
    </>,
  ],
  question: [
    <>링글 화상영어로 어떻게 비즈니스 영어 실력을 키울 수 있나요?</>,
    `Ringle can help you prepare for all uses of Business English:`,
    's_case_business.question',
    <>如何运用Ringle线上英语提升自身的商务英语水平?</>,
    <>如何運用Ringle視訊英語提升商務英語的實力？</>,
    <>Ringleオンライン英会話でどうやってビジネス英語力を伸ばすことができますか？</>,
    `Ringle có thể giúp bạn chuẩn bị cho mọi trường hợp sử dụng tiếng Anh trong công việc:`,
  ],
  bullet_1: [
    `링글 튜터들은 여러분의 답변의 핵심은 유지한 채, 원어민 관점에서 어색한 표현, 뉘앙스 등을 고려해 실제 비즈니스 상황에서 쓰이는 표현으로 교정합니다. 내가 말한 영어가 실시간으로 교정되는 모습을 눈으로 확인하며 영어 실력을 한 단계 더 업그레이드 해보세요.`,
    `Taking Ringle lessons consistently improves your overall English fluency, so that you can be prepared for even the most unexpected occasion that requires English communication. For the best results, we recommend taking 6 40-minute lessons or 12 20-minute lessons a month for at least 6 months`,
    's_case_business.bullet_1',
    `Ringle教师将抓住学员回答中的核心内容, 从母语者的角度, 将表达中出现的生涩部分及语调问题, 用正宗商务表达形式进行替换。让您能够实时直观确认自身英语会话中的问题, 有助于您的英语水平进一步提升。`,
    `Ringle任課教師會維持回覆的核心內容，以母語人士的觀點，考量不自然的用法、語感等，糾正為實際商務情況中會使用的用法。親眼確認您所說的英語即時得到糾正，將您的英語實力更升一級吧！`,
    `Ringleのチューターは皆さんの回答の核心は維持したまま、ネイティブの観点から不自然な表現やニュアンスなどを考慮し、実際のビジネス現場で使われる表現に校正します。あなたが話した英語がリアルタイムで校正される様子を目で確認しながら、英語力をもう一段階アップグレードしましょう。`,
    `Tham gia đều đặn các buổi học cùng Ringle giúp cải thiện việc sử dụng tiếng Anh một cách trôi chảy, từ đó bạn sẵn sàng cho tất cả các tình huống bất ngờ yêu cầu sử dụng tiếng Anh. Để có kết quả tốt nhất, chúng tôi khuyến khích bạn học 6 buổi 40 phút hoặc 12 buổi 20 phút mỗi tháng, và liên tục như vậy 6 tháng`,
  ],
  bullet_2: [
    `자유주제 수업을 통해 영어 이메일, 보고서, 발표 스크립트 등 원하는 자료를 업로드하고, 튜터의 실시간 첨삭을 받아보세요. 업로드한 자료를 직접 교정받으며 비즈니스 상황에 맞는 영어를 익힐 수 있습니다.`,
    `Ringle tutors understand that business communications, all else equal, prefer succinct and concise sentences and will provide feedback accordingly`,
    's_case_business.bullet_2',
    `通过自由主题课程, 上传希望校正的英文邮件, 报告书及发表脚本, 于课程中接受教师的实时纠正。通过此种对自身资料直接修改的形式, 逐渐掌握商务英语的精髓所在。`,
    `透過自由主題課程，上傳英文電子郵件、報告書、發表腳本等您希望的資料，由任課教師為您即時增刪吧！透過直接糾正上傳的資料，您將能掌握符合商務情況的英語。`,
    `自由テーマレッスンで英語メール、報告書、発表スクリプトなど希望する資料をアップロードし、チューターのリアルタイム校正を受けてみましょう。アップロードした資料を直接校正してもらい、ビジネスの現場に合わせた英語を身につけることができます。`,
    `Gia sư Ringle hiểu rằng, sử dụng tiếng Anh thương mại ở các hình thức đều quan trọng như nhau, nên dùng các mẫu câu ngắn gọn và súc tích. Họ sẽ đưa ra những nhận xét phù hợp cho bạn`,
  ],
  bullet_3: [
    `회의 또는 업무 상황에서는 나의 의견을 간결하고 정확하게 전달하는 것이 중요합니다. 비즈니스 및 전문 분야 관련 교재로 수업하며 실전 업무 환경에 적용할 수 있는 영어 말하기를 연습하세요.`,
    `Ringle tutors also pay special attention to the nuance and context of your speaking and offer business idioms and expressions`,
    's_case_business.bullet_3',
    `在会议或工作情况下能够运用简洁准确的英语表达自身想法是至关重要的。Ringle选用商务或与专业领域相关的教材, 能够对运用于实际工作环境中的英语口语进行更具针对性的练习。`,
    `在會議或工作情況中，必須能夠簡潔精確地傳達自身的意見。使用商務及專業領域相關教材上課，練習可運用於實戰工作環境的英語口說吧！`,
    `会議または仕事現場では、自分の意見を簡潔かつ正確に伝えることが重要です。ビジネスや専門分野に関する教材でレッスンをしながら、実際の業務環境で適用できる英語のスピーキングを練習しましょう。`,
    `Gia sư Ringle cũng đặc biết chú ý đến sắc thái và ngữ cảnh sử dụng để cung cấp những mẫu câu và cách nói thông dụng`,
  ],
  quote: [
    <>
      "아무래도 외국계 회사에 다니다 보니 업무도 항상 영어로 해야 했고 개인적으로도 영어에 많은 투자를 했었죠. 나만을
      위해 일대일로 퀄리티 높은 선생님이 가르쳐주는 기회는 많지 않거든요, 직장인 분들이나 외국계 근무하시는 분들이
      링글을 경험하시면 만족도가 아주 높으실 겁니다."
    </>,
    <>
      "I have to speak English for work on a daily basis. It's not often that you get the opportunity to have a 1:1
      lesson with high quality tutors. I believe a lot of working professionals would find Ringle extremely helpful."
    </>,
    's_case_business.quote',
    <>
      "不管在什么样的外资企业工作, 经常使用英语是不可避免的, 所以对于英语方面的投资一定会很多。对我而言,
      这种1:1形式和高水平的教师一起学习的机会并不是很多,
      所以我相信对于公司职员或那些就职于外资企业的职场人士而言如果体验到Ringle课程，一定会十分满意。"
    </>,
    `「因為在外資公司工作，工作時經常需要使用英語，我私底下也在英語方面投資了許多。這種1:1由高水準的老師專為我授課的機會並不多，如果上班族或在外資公司工作的人士能體驗Ringle的課程，一定會十分滿意。」`,
    <>
      "やはり外資系企業に勤めていると業務も常に英語でしなければならず、個人的にも英語にたくさん投資しました。私だけのために1on1でクオリティの高い先生が教えてくれる機会は多くありません。会社員の方や外資系に勤務されている方がRingleを体験すれば、満足度がとても高いと思います。"
    </>,
    <>
      "Tôi phải sử dụng tiếng Anh trong công việc hằng ngày. Bạn sẽ không thường xuyên có cơ hội tham gia những buổi học
      1:1 với các gia sư giỏi. Tôi tin rằng những người đi làm sẽ thấy Ringle hữu ích."
    </>,
  ],
  quote_writer: [
    `- 김정은 님`,
    `- Jungeun, Engineer`,
    's_case_business.quote_writer',
    `- Mr. Kim`,
    `- Mr. Kim`,
    `- キム・ジョンウンさん`,
    `- Jungeun, Kỹ sư`,
  ],
}
export const s_case_correction = {
  who_need_correction: [
    <>
      에세이/레주메/CV
      <br />
      <Div spanTag textSecondary>
        영문서 교정
      </Div>
    </>,
    <>Perfect your Resume, CV, essay, and more</>,
    's_case_correction.who_need_correction',
    <>
      短文/履历/CV
      <br />
      <Div spanTag textSecondary>
        英文书面文本校正
      </Div>
    </>,
    <>
      申論／簡歷／CV
      <br />
      <Div spanTag textSecondary>
        糾正英文文件
      </Div>
    </>,
    <>
      エッセイ/レジュメ/CV
      <br />
      <Div spanTag textSecondary>
        英文校正
      </Div>
    </>,
    <>Hoàn thiện Resume, CV, bài luận và các tài liệu khác</>,
  ],
  important_document: [
    <>
      문서별 목적에 맞는
      <br />
      전공과 배경을 가진 튜터와 대화하며
      <br />
      수준 높은 영문서를 작성합니다
    </>,
    <>Get advice from a tutor with a relevant background for a detailed fine-tuning</>,
    's_case_correction.important_document',
    <>
      根据不同文件的使用目的
      <br />
      与具备相关专业背景的教师进行对话
      <br />
      编写高水平的英文书面文本
    </>,
    <>
      與具備符合各文件目的的
      <br />
      專業背景的任課教師對話，
      <br />
      撰寫高水準的英文文件。
    </>,
    <>
      文書別目的に合った
      <br />
      専攻と背景を持ったチューターと会話しながら
      <br />
      レベルの高い英文書を作成します。
    </>,
    <>Lời khuyên từ gia sư có nền tảng kiến thức phù hợp cho những điều chỉnh chi tiết</>,
  ],
  essay: [`#에세이`, `#Resume`, 's_case_correction.essay', `#短文`, `#申論`, `#エッセイ`, `#Resume`],
  resume: [`#레주메`, `#Essay`, 's_case_correction.resume', `#履历`, `#簡歷`, `#レジュメ`, `#Bài_luận`],
  CV: [`#CV`, `#CV`, 's_case_correction.CV', `#CV`, `#CV`, `#CV`, `#CV`],
  SOP: [`#SOP`, `#SOP`, 's_case_correction.SOP', `#SOP`, `#SOP`, `#SOP`, `#SOP`],
  can_ringle_correction: [
    `영문서를 링글 화상영어로 교정 받을 수 있나요?`,
    `Can I have my Resume, Essays, and other documents edited with Ringle?`,
    's_case_correction.can_ringle_correction',
    `英文书面文本可以通过Ringle线上英语进行校正吗?`,
    `可透過Ringle視訊英語糾正英文文件嗎？`,
    `英文書をRingleオンライン英会話で校正してもらえますか？`,
    `Tôi có thể sủa Resume, bài luận và các tài liệu khác với Ringle không?`,
  ],
  many_ringle_correction: [
    <>
      네, 약 10-15% 정도의 링글 수강생 분들이 영문서 교정에 링글 수업을 활용하고 계십니다. 유학 및 취업 준비를 위한
      레주메, 에세이, CV, SOP 등은 물론, 영어 이메일, PT 스크립트, 발표 자료 등 영어로 된 모든 문서는 링글 튜터와 함께
      대화하며 교정할 수 있습니다.
    </>,
    <>
      Of course! About 10-15% of our customers have editing focused lessons on documents of their choosing, be it a CV,
      resume, cover letter, essay for a class or for a grad school application, statement of purpose, research abstract,
      thesis, presentation scripts, blog posts, or even a particularly important email.
    </>,
    's_case_correction.many_ringle_correction',
    <>
      当然, Ringle学员中约有10-15%通过Ringle课程对英文书面文本进行校正。其中包含为留学及就业准备的履历, 短文,
      CV及SOP等内容, 同时诸如英文邮件, PT脚本, 发表材料等任何英文形式的书面文本均可通过与Ringle教师的视频会话进行修正。
    </>,
    <>
      當然，Ringle學生中約有10～15%透過Ringle課程糾正英文文件。不僅是為了留學及就業而準備的簡歷、申論、CV及SOP等，英文電子郵件、報告腳本、發表資料等任何以英文撰寫的文件均可透過與Ringle任課教師對話並進行糾正。
    </>,
    <>
      はい。約10-15%のRingle受講生が英文書の校正にRingleのレッスンを活用しています。留学および就職準備のためのレジュメやエッセイ、CV、SOPなどはもちろん、英語メール、PTスクリプト、発表資料など、英語で書かれたすべての文書はRingleのチューターと一緒に会話しながら校正することができます。
    </>,
    <>
      Hoàn toàn được! Khoảng 10-15% học viên chọn tập trung vào phần chỉnh sửa các tài liệu của họ trong buổi học. Đó có
      thể là CV, resume, cover letter, bài luận tại lớp hoặc đơn ứng tuyển sau đại học, statement of purpose, bản tóm
      tắt nghiên cứu, luận văn, kịch bản thuyết trình, bài blog hoặc kể cả email cho những mục đích quan trọng.
    </>,
  ],
  question: [
    <>링글 튜터의 영문서 교정은 무엇이 다른가요?</>,
    `Ringle has three major advantages when it comes to editing:`,
    's_case_correction.question',
    <>Ringle教师的英文书面文本校正有何不同之处?</>,
    <>Ringle任課教師的糾正英文文件有何不同之處？</>,
    <>Ringleのチューターの英文校正は何が違いますか？</>,
    `Ringle có 3 ưu điểm chính trong việc chỉnh sửa:`,
  ],
  bullet_1: [
    `링글 튜터는 대부분 영국/미국 명문대 출신으로 영어 글쓰기에 대한 강도 높은 훈련을 받은 원어민입니다. 이들은 풍부한 영문서 작성 및 첨삭 경험으로 상황과 목적에 맞는 글쓰기에 능숙하며, 원어민 관점에서 영문서에 적합한 문체와 표현으로 글을 수정할 수 있습니다.`,
    `Ringle tutors are excellent editors and writers who have developed effective communication skills at prestigious universities`,
    's_case_correction.bullet_1',
    `绝大多数的Ringle教师出自英/美名牌大学, 均是接受过英文写作高强度训练的母语教师。他们具备丰富的英文书写及修改经验, 擅长根据情况及具体目的撰写文章, 同时能够从母语者的角度提出适用于英文书面文本的文法及表达方式, 对其进行纠正及提升。`,
    `Ringle的任課教師大部分出自英國／美國的名校，是曾接受過高強度英文寫作訓練的母語人士。他們具備豐富的英文文件撰寫及增刪經驗，擅長依據情況及目的撰寫文章，能以母語人士的觀點，使用適合英文文件的文體與用法為您修改文章。`,
    `Ringleのチューターは大半がイギリス/アメリカの名門大学出身で、英語のライティングについて厳しい訓練を受けたネイティブスピーカーです。彼らは豊富な英文書の作成および添削経験があり、状況と目的に合わせたライティングに長けており、ネイティブの観点から英文書に適した文体と表現で文章を修正することができます。`,
    `Gia sư Ringle là những biên tập viên và cây viết xuất sắc, đã tôi luyện kỹ năng giao tiếp tại những trường Đại học danh tiếng`,
  ],
  bullet_2: [
    `링글 튜터들은 각 문서의 목적과 의도를 이해하고 교정합니다. 단순히 틀린 문장을 수정하는 것이 아니라, 수강생이 전달하려는 핵심이 간결하고 명쾌하게 전달될 수 있도록, 또 문서에 맞는 톤으로 작성될 수 있도록 실시간으로 대화하며 교정합니다.`,
    `Ringle tutors understand that each document serves a unique purpose and always edit holistically, prioritizing the content and messaging of your document above anything else. They might make your sentences more concise or more complex, more direct or more subtle, more humorous or more serious, all depending on what is more appropriate for your case`,
    's_case_correction.bullet_2',
    `Ringle的教师将根据不同文本的目的及意图进行理解及校正。不仅仅是单纯地对文章错句进行修改，而是试图将学生希望表达的核心内容运用更为简洁及准确的英文进行表达，同时为准确掌握文章语气，可通过实时对话进行及时纠正。`,
    `Ringle的任課教師會理解各文件的目的與意圖進行糾正。不僅修改錯誤的句子，更會即時對話並糾正，使學生能簡潔明快地傳達核心內容，或以符合文件的語氣撰寫。`,
    `Ringleのチューターは、各文書の目的と意図を理解して校正します。単に間違った文章を修正するのではなく、受講生が伝えようとする核心が簡潔かつ明快に伝わるよう、また文書に合ったトーンで作成されるようにリアルタイムで会話をして校正します。`,
    `Gia sư Ringle hiểu rằng mỗi tài liệu phục vụ những mục đích khác nhau và cần được chỉnh sửa một cách toàn diện, đặc biệt chú trọng nội dung và thông điệp của tài liệu. Họ có thể biến câu văn của bạn trở nên ngắn gọn hơn hoặc phức tạp hơn, trực tiếp hơn hoặc tinh tế hơn, hài hước hơn hoặc nghiêm túc hơn, tất cả sẽ tuỳ thuộc vào trường hợp của bạn`,
  ],
  bullet_3: [
    `링글에는 경영/경제/영문학/의학/공학/법학 등 다양한 전공을 가진 튜터들이 있습니다. 이에 특정 분야에 대한 일정 수준 이상 전문 지식이 필요한 교정의 경우, 관련 분야 튜터를 검색해 수업 진행이 가능합니다.`,
    `Ringle tutors vary in area of specialties from business & economics to English literature to engineering, and can cater your writing to carry the right terms and expressions specific to your sector`,
    's_case_correction.bullet_3',
    `Ringle的教师中不乏出身经营/经济/英文/医学/工学/法学等专业的高端人才, 因此需特定领域的专业知识对文件进行校正的情况可通过搜索功能对相关领域教师进行查找后预约课程。`,
    `Ringle擁有經營／經濟／英文文學／醫學／工學／法學等各種主修的任課教師。因此，如需擁有特定領域一定水準以上的專業知識以利糾正時，可透過搜尋相關領域的任課教師後進行課程。`,
    `Ringleには経営/経済/英文学/医学/工学/法学など、多様な専攻のチューターがいます。そのため、特定分野に対する一定レベル以上の専門知識が必要な校正の場合、関連分野のチューターを検索してレッスンを行うことができます。`,
    `Chuyên môn của các gia sư tại Ringle khá đa dạng, từ Kinh tế đến Ngữ văn Anh hay Kỹ sư. Họ có thể hỗ trợ bạn sử dụng thuật ngữ và cách diễn đạt phù hợp cho bài viết chuyên ngành của bạn.`,
  ],
  quote: [
    <>
      "그냥 영어 선생님이 아니라, 제가 가고자 하는 학교에서 실제로 공부를 하는, 실제 지원자 입장에서 문서를 작성했었던
      튜터분들을 만날 수 있었던 것이 정말 행운이었습니다."
    </>,
    <>
      "I felt so lucky that I was able to meet with tutors that currently study in the schools I was applying to and
      have also written application essays for those schools."
    </>,
    's_case_correction.quote',
    <>
      "不仅是英语教师, 而是作为我梦想学校的实际在校生能够以申请者的角度设身处地地对文件进行编写,
      我觉得能够和教师们一起学习真是非常幸运。"
    </>,
    `「不僅是英語老師，而是實際在我夢想的學校就學的在校生，能夠遇見曾經以申請者立場撰寫文件的任課教師真的很幸運。」`,
    <>
      "ただの英語の先生ではなく、私が行こうとしている学校で実際に勉強している、実際に志願者の立場で文書を作成していたチューターの方々にお会いできて本当に幸運でした。"
    </>,
    <>
      "Tôi cảm thấy rất may mắn khi có thể gặp được những gia sư đang theo học tại những ngôi trường tôi đang viết bài
      luận để nộp đơn vào."
    </>,
  ],
  quote_writer: [
    `- HBS Class of 2020 / 이상윤님`,
    `- Sangyoon, HBS Class of 2020`,
    's_case_correction.quote_writer',
    `- HBS Class of 2020 / Mr. Lee`,
    `- HBS Class of 2020／Mr. Lee`,
    `- HBS Class of 2020 / イ・サンユンさん`,
    `- Sangyoon, Trường Kinh doanh Harvard khoá 2020`,
  ],
}
export const s_case_exam = {
  toefl_ielts: [
    <>
      토플/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        공인영어시험 대비
      </Div>
    </>,
    <>Prepare for TOEFL, TOEIC, IELTS and more</>,
    's_case_exam.toefl_ielts',
    <>
      TOEFL/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        专业英语考试应对
      </Div>
    </>,
    <>
      TOEFL／IELTS
      <br />
      Speaking／Writing
      <br />
      <Div spanTag textSecondary>
        準備英語檢定
      </Div>
    </>,
    <>
      TOEFL/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        公認英語試験対策
      </Div>
    </>,
    <>Chuẩn bị cho các kỳ thi TOEFL, TOEIC, IELTS và nhiều kỳ thi khác</>,
  ],
  for_exam: [
    <>
      기존 학습법, 템플릿 암기의
      <br />
      한계에 부딪혔을 때,
      <br />
      원어민 튜터의 교정으로 고득점을 달성합니다
    </>,
    <>Go beyond rote memorization to improve your fundamental English fluency</>,
    's_case_exam.for_exam',
    <>
      现有学习方法, 模板背诵
      <br />
      遇到瓶颈时,
      <br />
      通过母语教师的指点取得更好的成绩
    </>,
    <>
      當原本的學習方法、模板背誦
      <br />
      遇到瓶頸時，
      <br />
      透過母語任課教師的糾正取得高分
    </>,
    <>
      従来の学習方法、テンプレート暗記の
      <br />
      限界にぶつかった時、
      <br />
      ネイティブチューターの校正で高得点を達成します。
    </>,
    <>Vượt qua học thuộc lòng để cải thiện khả năng sử dụng tiếng Anh thành thạo</>,
  ],
  can_ringle_for_exam: [
    <>공인영어시험을 링글 화상영어로 준비할 수 있나요?</>,
    `Can I prepare for English proficiency tests with Ringle?`,
    's_case_exam.can_ringle_for_exam',
    <>可以通过Ringle线上英语准备专业英语考试吗?</>,
    <>可以透過Ringle準備準備英語檢定嗎？</>,
    <>公認英語試験の勉強をRingleオンライン英会話で準備することはできますか？</>,
    `Tôi có thể chuẩn bị cho các kỳ thi năng lực tiếng Anh với Ringle không?`,
  ],
  many_ringlers_for_exam: [
    <>
      네, 링글로 토플/IELTS/토익 스피킹/오픽 등 공인영어시험을 준비하시는 수강생이 약 10-15% 계십니다. 대부분은 학원 및
      강의를 통해 템플릿 암기 위주로 공부하시다가 더 이상 점수가 오르지 않았던 분들이었습니다. 특히 토플 스피킹 21-25점,
      IELTS speaking 3-5점에서 점수가 잘 오르지 않는다는 고민으로 링글 수강을 시작하신 분이 많습니다.
      <br />
      <br />
      고득점을 위해서는 무의식적 실수와 Filler Word 사용은 줄이고, 다양한 어휘를 사용하면서도 말하고자 하는 바를
      간결하게 전달해야 합니다. 링글에서 실전처럼 영어를 연습하고 본질적인 영어 소통 능력을 키워 고득점을 달성하세요.
    </>,
    <>
      Absolutely! About 10-15% of our customers use Ringle to prepare for English proficiency exams such as TOEFL,
      TOEIC, IELTS and more.
      <br />
      <br />
      Plenty of coaching services and learning materials for these exams exist, but most tend to harp on rote
      memorizations of vocabs and grammar rules.
      <br />
      <br />
      This can be effective, but only to a certain degree. For example, our customers often find their score stagnate
      around 21-25 in TOEFL speaking and 3-5 in IELTS speaking before they started learning with Ringle.
      <br />
      <br />
      At Ringle, we focus on improving fundamental fluency that can bring a better result on exams by making you a
      better speaker overall.
    </>,
    's_case_exam.many_ringlers_for_exam',
    <>
      当然,
      Ringle中大约有10-15%的学员在准备托福/IELTS/托业口语/Opic等专业英语考试。其中大部分学生都有在其他补习平台通过模板背诵的方式进行学习后,
      出现分数上升瓶颈的问题, 特别是托福口语21-25分, IELTS Speaking3-5分的学员因分数提升困难开始使用Ringle的大有人在。
      <br />
      <br />
      为取得高分, 应减少无意识的失误及使用Filler Word的频率,
      即使是使用多样化的词汇也要以简洁传达自身想法为基础进行会话。于Ringle中进行实战会话练习,
      提升自身英语水平从而获得高分数吧。
    </>,
    <>
      當然，Ringle中大約有10～15%的學生準備托福／雅思／多益口說／Opic等英語檢定。其中大部分學生都是在補習或課程透過模板背誦的方式學習後，分數再也無法提升。尤其是很多學生的成績停留在托福口說21～25分、雅思口說3～5分，所以開始聆聽Ringle的課程。
      <br />
      <br />
      為取得高分，應減少無意識的失誤及使用Filler
      Word的頻率，即使是使用各種詞彙，也必須簡潔傳達自身的想法。請在Ringle進行實戰英語練習，培養實質的英語溝通能力並取得高分。
    </>,
    <>
      はい。RingleでTOEFL/IELTS/TOEIC
      SPEAKING/OPIcなど公認英語試験の勉強をされる受講生は約10-15%いらっしゃいます。大半の方は塾や講義を通してテンプレート暗記中心の勉強をされていましたが、それ以上点数が上がらなかった方々でした。特にTOEFL
      SPEAKINGが21-25点、IELTS
      speakingが3-5点と点数があまり上がらないという悩みをお持ちで、Ringleを受講し始めた方が多いです。
      <br />
      <br />
      高得点のためには無意識的なミスとFiller
      Wordの使用を減らし、さまざまな語彙を使いながらも言いたいことを簡潔に伝えなければなりません。Ringleで実際のように英語を練習し、本質的な英語コミュニケーション能力を伸ばして高得点を達成しましょう。
    </>,
    <>
      Hoàn toàn được! Khoảng 10-15% học viên cùng Ringle chuẩn bị cho các kỳ thi tiếng Anh như TOEFL, TOEIC, IELTS và
      các kỳ thi khác.
      <br />
      <br />
      Có rất nhiều dịch vụ và tài liệu để chuẩn bị cho các kỳ thi, tuy nhiên đa phần tập trung vào việc ghi nhớ từ vựng
      và cấu trúc ngữ pháp.
      <br />
      <br />
      Điều này vẫn hiệu quả nhưng chỉ ở một mức độ nào đó. Ví dụ, học viên của chúng tôi thường có điểm số nằm trong
      khoảng 21-25 ở bài nói TOEFL và 3-5 cho bài thi nói IELTS trước khi họ học Ringle
      <br />
      <br />
      Tại Ringle, chúng tôi tập trung cải thiện khả năng sử dụng tiếng Anh trôi chảy giúp mang đến kết quả tốt hơn trong
      các kỳ thi và giúp bạn giao tiếp tốt hơn.
    </>,
  ],
  question: [
    <>링글이 어떻게 공인영어시험 고득점에 도움이 되나요?</>,
    `Here are different ways you can customize your Ringle lessons to prepare for English proficiency exams:`,
    's_case_exam.question',
    <>如何通过Ringle提升专业英语考试的分数?</>,
    <>Ringle有助於英語檢定取得高分嗎？</>,
    <>Ringleはどのように公認英語試験において高得点に役立ちますか？</>,
    `Đây là những cách điều chỉnh buổi học ở Ringle để giúp bạn chuẩn bị cho kỳ thi tiếng Anh:`,
  ],
  bullet_1: [
    `Speaking 시험의 경우, 자유주제(Speaking Focused) 수업으로 원하는 자료를 업로드하신 뒤 튜터와 실전처럼 연습할 수 있습니다. 고급 영어를 구사하는 영미권 아이비리그 튜터의 실시간 피드백으로 내가 말한 답안을 눈으로 보며 교정받으세요. 튜터의 교정으로 어휘력과 문장력을 넓히고 새로 배운 표현은 다음 답안에 바로 적용할 수 있습니다.`,
    `Come to your lesson with 4-5 speaking prompts and practice timed speaking with your tutor`,
    's_case_exam.bullet_1',
    `Speaking考试的情况, 可通过自由主题(Speaking Focused)课程上传需要的资料和Ringle教师开展实战性练习。通过使用高级英语的英美圈常春藤教师的实时反馈, 对纠正的问题进行直观确认。教师的纠正有助于拓展学员的词汇量及文章表现力, 同时将所学到的表达方式应用于今后的答题之中。`,
    `Speaking測驗的情況，透過自由主題（Speaking Focused）課程，可於上傳希望的資料後，和Ringle任課教師進行如實戰一般的練習。透過使用高級英語的英美常春藤任課教師的即時意見回饋，親眼確認如何糾正您所說的回覆吧！任課教師的糾正有助於學生增進詞彙及句子的能力，同時可將剛學到的用法馬上應用於下一個回覆。`,
    `Speaking試験の場合、自由テーマ(Speaking Focused)レッスンで好きな資料をアップロードし、チューターと実際のように練習することができます。高級英語を駆使する英米圏アイビーリーグチューターのリアルタイムフィードバックで、あなたが言った答えを目で見ながら校正してもらいましょう。チューターの校正で語彙力と文章力を高め、新しく学んだ表現は次の答えですぐに適用することができます。`,
    `Tham gia buổi học với 4-5 gợi ý và luyện tập cùng gia sư`,
  ],
  bullet_2: [
    <>
      고득점을 목표하신다면, 문법/발음 실수를 바로잡고, Filler word를 줄이는 등 나의 말하기 습관을 객관적으로 점검해야
      합니다. 수업 후 제공되는 녹음 파일, 스크립트, AI 분석으로 말하기 속도, Filler word 사용 빈도, 자주 사용하는 어휘나
      구문을 꼼꼼히 복습해보세요.
    </>,
    `You can also have a writing-focused lesson by bringing a script written on a prompt of your choice and have your tutor review them`,
    's_case_exam.bullet_2',
    <>
      如果考试的目标是获得高分数, 那么就要抓住文法/发音中的问题，减少Filler
      word的频率等对自身英语进行客观性的判断。课程结束后，通过Ringle所提供的录音文件, 脚本, AI分析等内容,
      对自身语言速度, Filler word使用频率, 常用词及语句等问题进行仔细复习。
    </>,
    <>
      如果希望取得高分，就要糾正文法／發音的失誤，減少Filler
      word的頻率等，客觀檢查自己的口說習慣。課程結束後，請利用Ringle所提供的錄音檔、腳本、AI分析的口說速度、Filler
      word使用頻率、常用詞彙或句型仔細複習。
    </>,
    <>
      高得点が目標なら、文法/発音ミスを正してFiller
      wordを減らすなど、自分のスピーキング習慣を客観的にチェックしなければなりません。レッスン後に提供される録音ファイル、スクリプト、AI
      Analysisで話すスピードやFiller wordの使用頻度、よく使用する語彙や構文をしっかり復習しましょう。
    </>,
    `Bạn có thể chọn buổi học tập trung vào viết và mang theo bài viết dựa trên chủ đề bạn chọn để cùng gia sư đánh giá bài của mình`,
  ],
  bullet_3: [
    `Writing 시험의 경우, 자유주제(Writing Focused) 수업으로 원하는 자료를 업로드해 실시간 교정을 받을 수 있습니다. Academic Writing에 능한 링글 튜터들과 함께 표현력을 넓히고 실전 답안 작성에 바로 적용해보세요. 단순한 어휘로 만든 내 답변이 고급 영어로 재탄생합니다.`,
    `Ringle tutors will provide real-time feedback on both technical errors and content improvement. Feel free to repeat the same prompt after receiving the feedback to drive home key corrections!`,
    's_case_exam.bullet_3',
    `Writing考试的情况, 可通过自由主题(Writing Focused)课程上传所需的资料, 接受实时纠正。将与擅长Academic Writing的Ringle教师一起提升表达能力, 应用于实战答题中。将那些只是单纯词汇堆叠的文章变身为高级英语表达吧。`,
    `Writing測驗的情況，可透過自由主題（Writing Focused）的課程，上傳您希望的資料，並接受即時糾正。與擅長Academic Writing的Ringle任課教師一起增強表達能力，並立即用於實戰撰寫回覆吧！僅由單純詞彙組成的回覆將以高級英語重新誕生。`,
    `Writing試験の場合、自由テーマ(Writing Focused)レッスンで好きな資料をアップロードし、リアルタイム校正してもらうことができます。Academic Writingが上手なRingleのチューターと共に表現力を高め、実際の答案作成にすぐに適用しましょう。単純な語彙で作ったあなたの答えが高級英語に生まれ変わります。`,
    `Gia sư Ringle sẽ đánh giá bài viết trực tiếp cho bạn cả về lỗi sai và cách cải thiện nội dung. Hãy viết lại bài lần nữa với những đánh giá của gia sư để nắm được trọn vẹn.`,
  ],
  quote: [
    <>
      "제가 링글을 사용하며 느낀 건, 링글에서는 내가 쓰는 영어가 한 단계 스킬업이 된다는 거였어요. 단어, 문장, 뉘앙스,
      발음 그 구체적인 하나 하나들을 튜터들이 다 캐치해줘요."
    </>,
    <>
      "My English definitely stepped up with Ringle. Ringle tutors pay attention to every details on my vocabulary,
      sentences, nuances, and pronunciation."
    </>,
    's_case_exam.quote',
    <>
      "我在使用Ringle时感到我的英语水平提升了一个档次。教师会针对我使用的单词, 语句, 语气, 发音等具体内容进行详细纠正。"
    </>,
    `「進行Ringle課程時，我感覺到我的英語提升了一個等級。任課教師會針對我使用的單字、句子、語感、發音等具體內容進行詳細糾正。」`,
    <>
      "私がRingleを使って感じたのは、Ringleでは私が使う英語が一段階スキルアップするということでした。単語、文章、ニュアンス、発音、具体的なひとつひとつをチューターがすべてキャッチしてくれます。"
    </>,
    <>
      "Khả năng tiếng Anh của tôi cải thiện vượt bậc nhờ Ringle. Gia sư của Ringle chú ý đến từng chi tiết về từ vựng,
      câu, sắc thái và phát âm."
    </>,
  ],
  quote_writer: [
    `- 패션 스타트업 CHELEC 대표 임선영님`,
    `- Sunyoung, CHELEC CEO`,
    's_case_exam.quote_writer',
    `- 时尚创业公司CHELEC代表 Ms Im`,
    `- 時尚新創公司CHELEC代表 Ms. Im`,
    `- ファッションスタートアップ CHELEC代表 イム・ソンヨンさん`,
    `- Sunyoung, CHELEC CEO`,
  ],
  ielts: [`#아이엘츠`, `#TOEIC`, 's_case_exam.ielts', `#雅思`, `#雅思`, `#IELTS`, `#TOEIC`],
  toefl: [`#토플`, `#TOEFL`, 's_case_exam.toefl', `#托福`, `#托福`, `#TOEFL`, `#TOEFL`],
  toeic_speaking: [
    `#토익스피킹`,
    `#IELTS`,
    's_case_exam.toeic_speaking',
    `#托业口语`,
    `#多益口說`,
    `#TOPICスピーキング`,
    `#IELTS`,
  ],
  opic: [`#오픽`, `#OPIC`, 's_case_exam.opic', `#Opic`, `#Opic`, `#OPIc`, `#OPIC`],
}
export const s_case_pronunciation = {
  korean_accent: [
    <>
      한국식{' '}
      <Div spanTag textSecondary>
        영어 발음
      </Div>
      을
      <br />
      자연스럽게 교정하기
    </>,
    <>You can sound like a native speaker</>,
    's_case_pronunciation.korean_accent',
    <>
      将中式
      <Div spanTag textSecondary>
        英语发音
      </Div>
      <br />
      进行自然矫正
    </>,
    <>
      將不自然的
      <Div spanTag textSecondary>
        英語發音
      </Div>
      <br />
      矯正成自然的發音
    </>,
    <>
      日本式{' '}
      <Div spanTag textSecondary>
        英語発音
      </Div>
      を
      <br />
      自然に矯正する
    </>,
    <>Giao tiếp như người bản xứ</>,
  ],
  naturally_correct: [
    <>
      굳어진 한국식 영어 발음,
      <br />
      억양, 인토네이션을
      <br />
      대화를 통해 자연스럽게 교정합니다
    </>,
    <>Pronounce and intonate correctly for an effective communication</>,
    's_case_pronunciation.naturally_correct',
    <>
      固化的中式英语发音
      <br />
      口音及语调,
      <br />
      通过对话自然修正。
    </>,
    <>
      透過對話自然矯正
      <br />
      不自然的英語發音、
      <br />
      口音及語調。
    </>,
    <>
      固まった日本式の英語発音、
      <br />
      イントネーション、アクセントを
      <br />
      会話を通じて自然に矯正します。
    </>,
    <>Phát âm và ngữ điệu chính xác để giao tiếp hiệu quả</>,
  ],
  american_accent: [
    `#미국식발음`,
    `#Accent`,
    's_case_pronunciation.american_accent',
    `#美式发音`,
    `#美式發音`,
    `#アメリカ式発音`,
    `#Giọng`,
  ],
  british_accent: [
    `#영국식발음`,
    `#Intonation`,
    's_case_pronunciation.british_accent',
    `#英式发音`,
    `#英式發音`,
    `#イギリス式発音`,
    `#Ngữ_điệu`,
  ],
  accent: [
    `#억양`,
    `#Enunciation`,
    's_case_pronunciation.accent',
    `#口音`,
    `#口音`,
    `#アクセント`,
    `#Tròn_vành_rõ_chữ`,
  ],
  intonation: [
    `#인토네이션`,
    `#Pronunciation`,
    's_case_pronunciation.intonation',
    `#语调`,
    `#語調`,
    `#イントネーション`,
    `#Phát_âm`,
  ],
  can_ringle_correct_pronunciation: [
    `영어 발음도 링글 화상영어로 교정할 수 있나요?`,
    `Can I correct my pronunciation using Ringle lessons?`,
    's_case_pronunciation.can_ringle_correct_pronunciation',
    `英语发音也可以通过Ringle线上英语进行纠正吗?`,
    `英語發音也可以透過Ringle視訊英語矯正嗎？`,
    `英語の発音もRingleオンライン英会話で矯正できますか？`,
    `Tôi có thể chỉnh phát âm của mình trong buổi học của Ringle không?`,
  ],
  many_ringlers_pronunciation: [
    <>
      네, 링글 수강생 중 발음 및 인토네이션을 중점적으로 연습하시는 분들이 많이 계십니다. 영국 발음, 미국 발음 등 배우고
      싶은 발음이 있거나, 발음 때문에 내 역량이 다 표현되지 않을까 고민이라면, 링글의 일대일 수업으로 원어민 튜터의
      실시간 피드백을 받으며 발음을 교정해보세요!
    </>,
    <>
      Sure thing! We understand pronunciations and intonations can be hard. It certainly takes a good tutor and lots of
      practice to sound like a native speaker.
    </>,
    's_case_pronunciation.many_ringlers_pronunciation',
    <>
      当然, Ringle学员中将发音及语调作为英语学习重点的人很多。如果想学习正宗的美式,
      英式发音或是担心因发音上的不足出现限制自身英语能力表达的问题时, 都可通过Ringle 1:1课程,
      在母语教师实时纠正的情况下对发音问题进行改善。
    </>,
    <>
      當然，Ringle有許多學生以發音及語調為主進行練習。如果想學習正統的英式發音、美式發音等，或擔心因為發音而無法展現所有實力的話，就透過Ringle
      1:1課程取得母語任課教師的即時意見回饋，並矯正發音吧！
    </>,
    <>
      はい。Ringle受講生のうち、発音とイントネーションを重点的に練習される方が多いです。イギリス発音やアメリカ発音など学びたい発音があったり、発音のせいであなたの力がすべて表現できないのではないかとお悩みなら、Ringleの1on1のレッスンでネイティブチューターのリアルタイムフィードバックを受けながら発音を矯正しましょう！
    </>,
    <>
      Hoàn toàn được! Chúng tôi hiểu rằng phát âm và ngữ điệu không dễ. Điều này đòi hỏi một gia sư giỏi và nhiều thời
      gian tập luyện để nói như người bản ngữ.
    </>,
  ],
  question: [
    <>링글을 통해 어떻게 영어 발음을 교정할 수 있나요?</>,
    `Here’s how Ringle lessons can help you improve your pronunciations:`,
    's_case_pronunciation.question',
    <>如何通过Ringle对英语发音进行纠正?</>,
    <>如何透過Ringle矯正英語發音呢？</>,
    <>Ringleでどのように英語の発音を矯正できますか？</>,
    `Đây là cách buổi học của Ringle giúp bạn cải thiện việc phát âm:`,
  ],
  bullet_1: [
    `수업 전 수업 방식 설정에서 발음을 집중 교정영역으로 선택하세요. '대화 중 어색한 발음은 바로 교정해달라', '어려운 발음은 여러 번 반복해달라'는 등의 구체적인 요청사항 작성도 가능합니다.`,
    `Ringle makes it easy for you to set the lesson preference to focus on pronunciation. Simply leave a lesson request like "Please correct my pronunciation after each time I speak”`,
    's_case_pronunciation.bullet_1',
    `请在课程开始之前选择以‘发音'作为集中纠正部分。同时还可以提出 '立即纠正对话中生涩的发音', '发音困难部分进行反复练习‘等课程具体要求事项。`,
    `請在課程開始前選擇「發音」作為集中糾正領域，也可寫下「對話時請立即糾正不自然的發音」、「請重複多次困難的發音」等具體要求事項。`,
    `レッスン前のレッスン方式設定で発音を集中校正分野に設定してください。「会話中の不自然な発音はすぐに矯正してほしい」「難しい発音は何度か繰り返してほしい」などの具体的なリクエスト事項の作成も可能です。`,
    `Missing " at the end of the sentences`,
  ],
  bullet_2: [
    `닮고 싶은 발음을 구사하는 튜터를 선택해 수업을 들어보세요. 자기소개 영상을 시청하거나 검색을 통해 원하는 발음의 튜터와의 수업을 예약할 수 있습니다.`,
    `Ringle offers 1,000+ lesson materials various topics including politics, and culture, so you can focus on perfecting pronunciations of sector-specific terminologies`,
    's_case_pronunciation.bullet_2',
    `明确自身想学习的发音类型, 选择一名使用此种发音语调的教师开始会话课程吧。通过观看教师自我介绍视频或进行搜索, 预约符合自身要求的教师课程。`,
    `請選擇使用您想學習的發音的任課教師進行課程，您可透過觀看任課教師的自我介紹影片或搜尋，預約您想要的發音的任課教師。`,
    `真似したい発音のチューターを選んでレッスンを受けましょう。自己紹介動画の視聴や検索を通して希望する発音のチューターとのレッスンを予約することができます。`,
    `Ringle cung cấp 1,000+ tài liệu với những chủ đề khác như như chính trị và văn hoá. Chính vì vậy, bạn có thể tập trung hoàn thiện việc phát âm với các từ theo chuyên ngành`,
  ],
  bullet_3: [
    <>
      수업이 끝나도 언제든지 복습 탭에서 수업 녹음 파일을 다시 들을 수 있습니다. 녹음을 들으며 나와 튜터의 발음을
      비교해보고, 수업 중 연습했던 발음을 다시 들어보세요.
    </>,
    `Each tutor has a short introduction video so you can find the tutor who has the accent you’d like to emulate, be it a standard American accent or a British accent`,
    's_case_pronunciation.bullet_3',
    <>
      课程结束后, 可随时通过复习页面对课程录音文件进行回顾。通过重新收听录音, 将本人发音与教师进行比较,
      对课程中练习的发音内容重新进行确认复习。
    </>,
    <>
      課程結束後，可隨時於複習分頁重新聆聽課程錄音檔。請聆聽錄音檔，比較自己和任課教師的發音，重新確認上課時練習過的發音。
    </>,
    <>
      レッスンが終わってもいつでも復習タブでレッスンの録音ファイルをまた聴くことができます。録音を聴きながら自分とチューターの発音を比較し、レッスン中に練習した発音をもう一度聴いてみましょう。
    </>,
    `Mỗi gia sư có một video ngắn giới thiệu bản thân nên bạn có thể tìm gia sư có giọng bạn muốn học theo như là giọng Anh hay giọng Mỹ`,
  ],
  quote: [
    <>
      "일주일에 한 번씩 뵙는 선생님인데도 자연스럽게 그 선생님의 말하는 습관이나 악센트를 따라가게 되었습니다. 링글
      수업의 임팩트가 정말 크다고 느꼈고, 그렇다면 제가 롤모델로 삼을 수 있는 선생님께 배우는 게 좋다고 생각했어요."
    </>,
    <>
      "I only take Ringle lessons once a week but I already catch myself copying tutors' accents and intonations, which
      speaks to the size of the impact of these lessons, and makes me want to choose a tutor I want to emulate."
    </>,
    's_case_pronunciation.quote',
    <>
      "虽然每周仅和教师学习一次, 但是你会很自然地模仿老师说话的习惯和重音使用。我觉得Ringle课程对我的影响真的很大,
      我认为能够和自己心目中的英语标杆人物一起学习才是明智之选。"
    </>,
    `「儘管一週只見一次老師，卻自然而然地模仿了那位老師說話的習慣或口音。我感覺到Ringle課程的後勁很強，能夠把老師當作我的學習榜樣，我覺得很棒。」`,
    <>
      "一週間に一度しかお会いしない先生なのに、自然とその先生の話し方やアクセントが身につきました。Ringleのレッスンのインパクトは本当に大きいと感じ、それなら私がロールモデルにしたい先生に教わるのが良いと思いました。"
    </>,
    <>
      "Tôi chỉ học Ringle một buổi một tuần mà đã có thể bắt chước giọng và ngữ điệu của gia sư, nghĩa là lợi ích của
      buổi học rất lớn, và tôi muốn chọn gia sư có giọng điệu mà mình muốn học theo."
    </>,
  ],
  quote_writer: [
    `- K 게임사 심정하님`,
    `- Jungha`,
    's_case_pronunciation.quote_writer',
    `- K 游戏公司 Mr Sim`,
    `- K遊戲公司 Mr. Sim`,
    `- K ゲーム会社 シム・ジョンハさん`,
    `- Jungha`,
  ],
}
export const s_case_lived_abroad = {
  who_lived_abroad: [
    <>
      영어권 국가에서
      <br />
      <Div spanTag textSecondary>
        유학 중
      </Div>
      이시거나
      <br />
      <Div spanTag textSecondary>
        유학 경험
      </Div>
      이 있으신 분
    </>,
    <>Make the most of your time spent studying abroad</>,
    's_case_lived_abroad.who_lived_abroad',
    <>
      于英语圈国家
      <br />
      <Div spanTag textSecondary>
        留学中
      </Div>
      或
      <br />
      <Div spanTag textSecondary>
        有过留学
      </Div>
      经验的学员
    </>,
    <>
      於英語圈國家
      <br />
      <Div spanTag textSecondary>
        留學中
      </Div>
      或
      <br />有
      <Div spanTag textSecondary>
        留學經驗
      </Div>
      的人
    </>,
    <>
      英語圏の国に
      <br />
      <Div spanTag textSecondary>
        留学中
      </Div>
      や
      <br />
      <Div spanTag textSecondary>
        留学経験
      </Div>
      のある方
    </>,
    <>Tận dụng tối đa thời gian du học của bạn</>,
  ],
  maintain_english: [
    <>
      영어가 불편하진 않아도
      <br />
      지속적으로 감각을 유지하고
      <br />
      한단계 높은 영어 수준을 목표합니다
    </>,
    <>Excel in classes and remove barriers to make lifelong friends</>,
    's_case_lived_abroad.maintain_english',
    <>
      虽然没有因为英语感到不便
      <br />
      但希望语感能够一直维持
      <br />
      目标是更上一层楼
    </>,
    <>
      雖然沒有對英語感到不自在，
      <br />
      但希望持續維持語感，
      <br />
      讓英語實力更升一級。
    </>,
    <>
      英語に不自由していなくても
      <br />
      持続的に感覚を維持して
      <br />
      一段階高い英語レベルを目指します。
    </>,
    <>Trở nên xuất sắc hơn và vượt qua những rào cản để kết nối với mọi người</>,
  ],
  experience_abroad: [
    `#유학`,
    `#International_students`,
    's_case_lived_abroad.experience_abroad',
    `#留学`,
    `#留學`,
    `#留学`,
    `#Sinh_viên_quốc_tế`,
  ],
  lived_abroad: [
    `#어학연수`,
    `#Foreign_exchange`,
    's_case_lived_abroad.lived_abroad',
    `#语言研修`,
    `#語言研修`,
    `#語学研修`,
    `#Sinh_viên_trao_đổi`,
  ],
  real_english: [
    `#영어실력유지`,
    `#Study_abroad`,
    's_case_lived_abroad.real_english',
    `#英语实力维持`,
    `#維持英語實力`,
    `#英語力維持`,
    `#Du_học`,
  ],
  high_english: [
    `#고급영어`,
    `#Higher_education`,
    's_case_lived_abroad.high_english',
    `#高级英语`,
    `#高級英語`,
    `#高級英語`,
    `#Cao_học`,
  ],
  specifically_what: [
    `현재 유학 중이거나 유학 후 귀국한 사람들도 링글을 수강하나요?`,
    `I am currently studying or have previously studied abroad. Will I find Ringle helpful?`,
    's_case_lived_abroad.specifically_what',
    `正在留学或留学后归国的人群也需要Ringle课程吗?`,
    `正在留學或留學後歸國的人也聽Ringle的課程嗎？`,
    `現在留学中や留学後に帰国した人もRingleを受講しますか？`,
    `Tôi đang đi học hoặc đã đi du học. Liệu Ringle có thể hỗ trợ tôi?`,
  ],
  writing: [
    <>
      네, 해외 유학 준비를 위해, 실제 유학 중 필요한 수준 높은 글쓰기/말하기 실력을 키우기 위해, 혹은 귀국 후 영어
      실력이 떨어지는 것을 방지하기 위해 링글을 수강하고 계신 분들이 많습니다.
      <br />
      <br />
      또한 "현재 유학 중이지만 지금 쓰는 영어를 아무도 교정해주지 않아 제대로 된 영어를 쓰고 있는지 모르겠다.", "내 영어
      실력이 일정 수준 이상으로 더 성장하는 것 같지 않았다."라는 고민에서 링글 수강을 시작하신 분들이 많이 계십니다.
    </>,
    <>
      Yes, of course! Whether it’s for a semester or for several years, studying abroad is a unique experience that
      combines culture and education, and to make the most of it often requires that you speak fluently the language of
      your destination.
      <br />
      <br />
      And for those who have previously studied abroad and learned to speak the language fluently while there, you might
      want to continuously brush up on your language skills so as not to lose the fluency you earned.
    </>,
    's_case_lived_abroad.writing',
    <>
      当然, 现在Ringle的学员中不乏有准备留学, 正在留学中需要高水平的写作/会话能力以及留学回国后担心英语水平下降的人士。
      <br />
      <br />
      同时那些心存‘虽然正在留学之中, 但没有人纠正现在正在使用的英语,
      不知道自己的英语是否存在问题’以及‘我的英语水平停留在某一水平后始终无法提升’等诸如此类烦恼的学员也不在少数。
    </>,
    <>
      當然，目前有許多正在準備留學、於留學中需要培養高水準寫作／口說實力或留學回國後為避免英語實力下滑而聆聽Ringle課程的學生。
      <br />
      <br />
      同時，也有許多學生因為煩惱「雖然正在留學，但沒有人糾正現在使用的英語，不知道我的英語是否正確。」、「我的英語實力好像停留在某一個程度無法再提升。」而開始聆聽Ringle的課程。
    </>,
    <>
      はい。海外留学に向けて、実際の留学中に必要なレベルの高いライティング/スピーキング力を育むため、あるいは帰国後の英語力の低下を防ぐためにRingleを受講されている方が多いです。
      <br />
      <br />
      また「現在留学中だが、今使っている英語を誰も正してくれないので、きちんとした英語を使っているのかわからない」「私の英語力は一定レベル以上に向上しそうになかった」というお悩みからRingleを受講し始めた方がたくさんいらっしゃいます。
    </>,
    <>
      Chắc chắn rồi! Dù là một học kỳ hay vài năm, việc đi du học là một trải nghiệm đặc biệt kết hợp giữa văn hoá và
      giáo dục, và để tận dụng tối đa trải nghiệm này đòi hỏi khả năng nói trôi chảy ngôn ngữ bạn sử dụng tại đất nước
      đó.
      <br />
      <br />
      Và kể cả những ai đã từng du học và học cách sử dụng trôi chảy ngôn ngữ khi còn ở đó, bạn vẫn nên tiếp tục mài
      giũa khả năng ngôn ngữ của mình để không đánh mất những gì đã học được.
    </>,
  ],
  question: [
    <>유학생/유학 후 귀국자는 링글을 어떻게 활용할 수 있나요?</>,
    `In either case, Ringle is here to help:`,
    's_case_lived_abroad.question',
    <>留学生/留学后归国人员将如何使用Ringle呢?</>,
    <>留學生／留學後回國者如何活用Ringle呢？</>,
    <>留学生/留学後の帰国者はRingleをどのように活用できますか？</>,
    `Dù bất kì trường hợp nào, Ringle có thể giúp bạn:`,
  ],
  bullet_1: [
    `원어민이라고 해서 모두 격조 높은 표현을 구사하는 것은 아닙니다. 링글 튜터들은 미국, 영국 명문대 출신으로 혹독한 글쓰기/토론 교육을 받고 있습니다. 발표 스크립트나 에세이 교정, 영어 토론 대비, 실전 말하기까지, 원하시는 목적에 따라 수강하며 한 단계 더 높은 영어 실력을 목표하세요.`,
    `Ringle tutors have attended top universities in the U.S. and in the U.K. and specialize in a wide variety of majors, so you can always find one that match your area of study`,
    's_case_lived_abroad.bullet_1',
    `即使是母语人群也不是所有人都能掌握高水平的表达能力。Ringle的教师均出身美英知名学府, 接受过严格的写作/讨论教育。无论是对发表脚本或是对短文进行校正, 还是准备英语讨论, 实战演讲, 只要按照自身希望的目标选择适合的课程, 您的英语实力必将得到大幅度提升。`,
    `即使是母語人士也不是所有人都能使用高格調的用法。Ringle的任課教師均來自美國、英國名校，接受嚴格的寫作／討論訓練。無論是發表腳本、糾正申論至實戰口說，請按照希望的目標上課，將您的英語實力更升一級。`,
    `ネイティブスピーカーだからといって全員が格調の高い表現を使うわけではありません。Ringleのチューターはアメリカ、イギリスの名門大学出身で、厳しいライティング/ディスカッション教育を受けています。発表スクリプトやエッセイ校正、英語ディスカッションの準備、実践スピーキングなど、ご希望の目的に合わせて受講して一段階上の英語力を目指しましょう。`,
    `Gia sư Ringle theo học tại các trường Đại học hàng đầu ở Mỹ và Anh cũng như có chuyên môn đa dạng, nên bạn sẽ luôn tìm được một gia sư phù hợp với ngành bạn học`,
  ],
  bullet_2: [
    <>
      링글에는 다양한 전공, 학교, 경력의 튜터들이 있습니다. 유학을 희망하는 혹은 전공 분야의 튜터를 선택해 해당 분야
      에세이를 첨삭받거나, 관련 주제로 토론하는 등 깊이 있는 수업 수강이 가능합니다.
    </>,
    `Ringle tutors provide detailed feedback on all scopes of English speaking, from small technical errors like articles and prepositions to crafting elegant sentences to communicate complex ideas`,
    's_case_lived_abroad.bullet_2',
    <>
      Ringle的教师来自不同的专业和学校，拥有丰富多样的经验。希望留学或选择专业的学员可通过挑选有经验的教师,
      接受相关专业的短文校正或是通过主题讨论的方式开展深层次的学习课程。
    </>,
    <>
      Ringle有許多來自不同主修、學校、經歷的任課教師。您可選擇希望留學或主修領域的任課教師增刪申論，或透過相關主題討論等，聆聽深度課程。
    </>,
    <>
      Ringleには多様な専攻、学校、経歴のチューターがいます。留学を希望する、または専攻分野のチューターを選択してその分野のエッセイを添削してもらったり、関連テーマでディスカッションしたりするなど、深みのあるレッスンを受けることができます。
    </>,
    `Gia sư Ringle cung cấp đánh giá chi tiết về mọi mặt trong tiếng Anh giao tiếp, từ những lỗi sai nhỏ như sử dụng mạo từ và giới từ để câu văn mượt mà hơn đến biểu đạt những ý phức tạp`,
  ],
  bullet_3: [
    <>
      유학 후 귀국했거나 유학 중이라도 제대로 된 영어를 배울 기회가 적다고 느낀다면, 링글 수업으로 영어로 말할 기회를
      마련해 감각을 유지하세요. 원어민 관점에서 나의 영어가 어떤 느낌으로 다가왔는지 실시간으로 확인하며 영어 실력을
      정말 유창한 수준으로 끌어올릴 수 있습니다.
    </>,
    `Ringle tutors can distinguish between academic English and casual English and offer tips appropriate for each situation`,
    's_case_lived_abroad.bullet_3',
    <>
      留学后归国或正在留学中, 深感英语使用时间少,
      请通过Ringle获取英语会话机会维持现有语感吧。从母语教师的观点中实时感受自身英语水平, 并将其提升至非常流畅的高度。
    </>,
    <>
      就算留學歸國或正在留學中，若覺得可以好好學習英語的機會不多的話，那就利用Ringle課程取得英語口說機會並維持語感吧！您可即時確認母語人士對您的英語有什麼感覺，並將英語實力提升至流暢的水準。
    </>,
    <>
      留学後帰国したり留学中でもまともに英語を学ぶ機会が少ないと感じたら、Ringleのレッスンで英語で話す機会を設けて感覚を維持しましょう。ネイティブの観点で自分の英語がどんな感じで向上しているのかをリアルタイムで確認しながら、英語力を本当に流暢なレベルに引き上げることができます。
    </>,
    `Gia sư Ringle có thể phân biệt giữa tiếng Anh học thuật và tiếng Anh đời thường. Từ đó đưa ra những gợi ý phù hợp với mỗi tình huống`,
  ],
  quote: [
    <>
      "링글을 통해 영어를 잘하게 되면서 얻을 수 있는 기회가 무한하게 느껴졌습니다. 만날 수 있는 사람이 10배, 100배,
      1,000배가 되고, 내가 만들어나갈 수 있는 네트워크도 기하급수적으로 늘어나더라고요."
    </>,
    <>
      "Speaking English is linked to limitless amount of opportunities. It expands the circle of people I can network
      with by 10, 100, even 1,000 times and grows my network exponentially."{' '}
    </>,
    's_case_lived_abroad.quote',
    <>
      "通过Ringle将英语学好, 我感觉能够因此获得无限的机会, 能够结识比之前多10倍, 100倍甚至1000倍的人,
      而我借此所建立起的人脉网络也呈现几何式增长。"
    </>,
    `「我感覺到我的英語透過Ringle變好，並能獲得無限的機會。因此，我能夠認識比之前多10倍、100倍、1000倍的人，而我可建立的人脈網絡也加倍成長。」`,
    <>
      "Ringleを通じて英語が上手になり、得られる機会は無限に感じました。出会える人が10倍、100倍、1,000倍になり、私が作っていけるネットワークも幾何級数的に増えました。"
    </>,
    <>
      "Khả năng nói tiếng Anh mang đến cho bạn vô vàn cơ hội. Nó mở rộng vòng tròn xã hội giúp bạn kết nối với mọi người
      gấp 10, 100 hay 1000 lần và phát triển mạng lưới của mình theo cấp số nhân."{' '}
    </>,
  ],
  quote_writer: [
    `- HBS Class of 2020 / 최다혜님`,
    `- Dahye, HBS Class of 2020`,
    's_case_lived_abroad.quote_writer',
    `- HBS Class of 2020 / Ms. Choi`,
    `- HBS Class of 2020／Ms. Choi`,
    `- HBS Class of 2020 / チェ・ダヘさん`,
    `- Dahye, Trường Kinh doanh Harvard2020`,
  ],
}
export const s_case_junior = {
  who_prepare_study: [
    <Fragment>
      국제학교/유학 준비 또는
      <br />
      영어 실력 향상을 원하시는
      <br />
      <Div spanTag textSecondary>
        초중고 학생분
      </Div>
    </Fragment>,
    <>Get a head start on mastering English fluency</>,
    's_case_junior.who_prepare_study',
    <Fragment>
      国际学校/留学准备中
      <br />
      希望提升自身英语实力的
      <br />
      <Div spanTag textSecondary>
        小学/初中/高中学生
      </Div>
    </Fragment>,
    <Fragment>
      準備國際學校／留學或
      <br />
      希望提升英語實力的
      <br />
      <Div spanTag textSecondary>
        國小、國中、高中學生
      </Div>
    </Fragment>,
    <Fragment>
      インターナショナルスクール/留学準備または
      <br />
      英語力を向上したい
      <br />
      <Div spanTag textSecondary>
        小中高生
      </Div>
    </Fragment>,
    <>Khởi đầu thuận lợi cho việc sử dụng tiếng Anh thành thạo</>,
  ],
  want_higher_level: [
    <>
      유학을 가지 않아도
      <br />
      아이비리그 원어민 튜터와 대화하며
      <br />
      영어 공부의 재미를 느낍니다
    </>,
    <>Go beyond classrooms for a real world exposure</>,
    's_case_junior.want_higher_level',
    <>
      即使不去留学
      <br />
      就能够与出身常青藤联盟的母语教师对话
      <br />
      培养英语学习的兴趣。
    </>,
    <>
      即使不去留學，
      <br />
      也能與出身常春藤名校的母語任課教師對話，
      <br />
      感受學習英語的樂趣。
    </>,
    <>
      留学しなくても
      <br />
      アイビーリーグのネイティブチューターと会話しながら
      <br />
      英語の勉強の面白さを感じます。
    </>,
    <>Vượt qua những căn phòng để tiếp xúc với thế giới thực</>,
  ],
  international_school: [
    `#국제학교`,
    `#International_School`,
    's_case_junior.international_school',
    `#国际学校`,
    `#國際學校`,
    `#インターナショナルスクール`,
    `#Trường_Quốc_tế`,
  ],
  study_abroad: [
    `#영어조기교육`,
    `#Study_Abroad`,
    's_case_junior.study_abroad',
    `#英语早期教育`,
    `#英語早期教育`,
    `#英語早期教育`,
    `#Du_học`,
  ],
  kindergarten: [
    `#영어유치원`,
    `#SAT`,
    's_case_junior.kindergarten',
    `#英语幼儿园`,
    `#英語幼稚園`,
    `#英語幼稚園`,
    `#SAT`,
  ],
  native: [
    `#원어민회화`,
    `#K-12`,
    's_case_junior.native',
    `#母语教师会话`,
    `#母語人士會話`,
    `#ネイティブ会話`,
    `#K-12`,
  ],
  can_junior_ringle: [
    <>초중고 학생도 링글을 이용할 수 있나요?</>,
    `Can pre-college students in elementary, middle, or high school use Ringle?`,
    's_case_junior.can_junior_ringle',
    <>小学/初中/高中学生也可以使用Ringle吗?</>,
    <>國小、國中、高中學生也可以使用Ringle嗎？</>,
    <>小中高生もRIngleを利用することはできますか？</>,
    `Học sinh tiểu học, trung học cơ sở và phổ thông có thể sử dụng Ringle không?`,
  ],
  many_junior: [
    <>
      네, 링글 수강생 중 초중고 학생분은 대부분 기초적인 영어 회화는 낯설지 않지만, 조금 더 심도 있는 영어 대화/글쓰기
      능력을 키우고 싶으신 분들이었습니다.
      <br />
      <br />
      특히, 수강생의 부모님들은 영어 전문 강사보다 미국, 영국 명문대 진학 후 다양한 경험을 쌓아가고 있는 링글 튜터와의
      수업을 통해 "영어 실력 향상은 물론, 아이가 집에서 아이비리그 튜터와 대화하며 사고를 확장하고 영어 공부의
      동기부여와 흥미를 느끼고 있다."라는 말씀을 하셨습니다.
    </>,
    <>
      Certainly! Some of our most dedicated users are these future leaders of the world, whose grades can range anywhere
      from elementary to high school.
      <br />
      <br />
      These students may have learned English in a classroom, have attended English-speaking schools, or lived in
      English-speaking countries.
      <br />
      <br />
      Ringle offers an environment where students can go beyond classrooms and have an engaging conversation with native
      speakers.
      <br />
    </>,
    's_case_junior.many_junior',
    <>
      当然, Ringle学员中的小学/初中/高中学生, 他们大部分对基础英语会话并不陌生,
      很多人都是希望能够培养更具深度的英语对话/写作能力。
      <br />
      <br />
      特别的是学员的家长们发现比起一般英语专业的讲师,
      与出身美英知名学府拥有丰富经验的Ringle教师们一起学习‘不仅能提升孩子们的英语水平,
      而且让他们足不出户就能和出身常青藤联盟的教师们进行对话, 在拓展思维的同时找到学习英语的动力及兴趣’。
    </>,
    <>
      當然，Ringle學生中的國小、國中、高中學生大部分對基礎英語會話並不陌生，但希望培養更具深度的英語對話／寫作能力。
      <br />
      <br />
      尤其，比起英語專業講師，與來自美國、英國名校並累積豐富經驗的Ringle任課教師上課，學生家長們表示：「不僅能提升英語實力，而且孩子在家中就能和出身常春藤名校的任課教師們對話，拓展思維的同時找到了學習英語的動機及樂趣。」
    </>,
    <>
      はい。Ringle受講生のうち、小中高生の大半は基礎的な英会話に慣れていませんが、もう少し踏み込んだ英会話/ライティング能力を身につけたいという方々でした。
      <br />
      <br />
      特に、受講生の親は英語の専門講師よりもアメリカやイギリスの名門大学進学後に多様な経験を積んでいるRingleのチューターとのレッスンを通じて「英語力の向上はもちろん、子供が家でアイビーリーグのチューターと会話しながら思考を広げ、英語の勉強のモチベーションアップと興味を感じている」とおっしゃいました。
    </>,
    <>
      Được chứ! Những học sinh chăm chỉ nhất của chúng tôi - những lãnh đạo tương lai, có thể đang học ở bất kì cấp nào.
      <br />
      <br />
      Những học sinh này đã học tiếng Anh trên trường, tham gia các lớp học nói và có cả những học sinh sống ở các nước
      nói tiếng Anh.
      <br />
      <br />
      Ringle mang đến môi trường nơi mà học sinh vượt ra khỏi giới hạn của lớp học để luyện nói và giao tiếp tốt hơn với
      người bản xứ.
      <br />
    </>,
  ],
  question: [
    <>주니어 수강생은 링글을 어떻게 활용하면 좋을까요?</>,
    `Here’s how you can make the most out of Ringle as a K-12 learner:`,
    's_case_junior.question',
    <>青少年学员应该如何使用Ringle呢?</>,
    <>青少年學生如何使用Ringle呢？</>,
    <>ジュニア受講生はRingleをどのように活用すればいいですか？</>,
    `Đây là cách các học sinh K-12 có thể học tập tốt nhất với Ringle:`,
  ],
  bullet_1: [
    `뉴욕타임즈 제휴 교재를 비롯한 다양한 교재를 활용한 수업으로 튜터와 대화하며 영어로 생각을 표현하는 연습을 해보세요. 튜터의 실시간 피드백과 교정으로 의견을 정확하게 영어로 표현하는 방식을 익힐 수 있습니다.`,
    `Upload essays, presentations, or any other learning materials from your classes to practice what you learned`,
    's_case_junior.bullet_1',
    `请选用包含纽约时报合作教材在内的多种教材开展的课程, 通过和教师的对话练习表达自身想法吧。同时教师提供的实时反馈及纠正内容, 有助于掌握更为准确的英语表达方式。`,
    `請利用包含紐約時報合作教材在內的各種教材上課，和任課教師對話並練習使用英語表達自身想法。可利用任課教師提供的即時意見回饋及糾正，熟悉精確使用英語表達意見的方式。`,
    `ニューヨークタイムズ提携教材をはじめ、多様な教材を活用したレッスンでチューターと会話しながら英語で考えを表現する練習をしてみましょう。チューターのリアルタイムフィードバックと校正で、意見を正確に英語で表現する方法を身につけることができます。`,
    `Tải lên các bài luận, thuyết trình hoặc bất cứ tài liệu học tập nào ở lớp học mà bạn muốn luyện tập thêm`,
  ],
  bullet_2: [
    <>
      영어 토론 준비, 에세이나 발표자료 교정 등 특정 주제/자료에 대한 교정이 필요하신 경우 자유주제 수업으로 튜터와
      자료를 함께 보며 상황과 목적에 맞는 수업 진행이 가능합니다.
    </>,
    `Speak with a tutor attending your dream school and use the lesson to practice English speaking and to ask questions about their experience`,
    's_case_junior.bullet_2',
    <>
      如需准备英语讨论或对短文/发表材料等特定主题/资料进行校正时, 可通过自由主题课程与教师共同查看,
      开展符合情况及有目的性的针对化课程。
    </>,
    <>
      如需準備英語討論或針對申論、發表資料等特定主題／資料進行糾正時，可透過自由主題課程，與任課教師共同確認資料，進行符合情況及目的的課程。
    </>,
    <>
      英語でのディスカッション準備、エッセイや発表資料の校正など、特定のテーマ/資料の校正が必要な場合は、自由テーマレッスンでチューターと資料を一緒に見ながら、状況と目的に合わせたレッスンが可能です。
    </>,
    `Trò chuyện cùng gia sư đang học tại ngôi trường mơ ước của bạn và tận dụng buổi học này để hỏi về trải nghiệm của họ`,
  ],
  bullet_3: [
    <>
      다양한 전공과 배경을 가진 튜터들 중 원하는 튜터를 선택하여 수업을 진행해보세요. 여러 명의 튜터와 다양한 주제로
      대화하며, 실력 향상은 물론 영어 자체에 대한 흥미를 키우고 넓은 시야를 가질 수 있습니다.
    </>,
    `Speak with a tutor pursuing a major you’re curious about or rocking the career you dreamed of to get an insider view of their world`,
    's_case_junior.bullet_3',
    <>
      在具备不同专业及背景的众多教师中, 选择自己满意的课程。与多位教师进行不同主题的对话, 不仅能提升自身英语能力,
      同时还能扩展视野培养对英语学习的兴趣。
    </>,
    <>
      從具備不同主修及背景的任課教師中，選擇自己希望的任課教師上課吧！與多位任課教師使用各種主題對話，不僅能夠提升實力，還能培養對於英語的興趣及拓展視野。
    </>,
    <>
      多様な専攻と背景を持つチューターたちの中から好きなチューターを選んでレッスンを行いましょう。複数のチューターと多様なテーマで会話しながら、実力向上はもちろん、英語そのものに対する興味を高めて広い視野を持つことができます。
    </>,
    `Nói chuyện cùng gia sư đang theo đuổi chuyên ngành bạn muốn tìm hiểu thêm hoặc chuẩn bị cho sự nghiệp trong mơ của bạn bằng những góc nhìn từ người trong cuộc`,
  ],
  quote: [
    <>"저희 아들이 하버드 출신 튜터를 만나 대화를 나눈 적은 없었죠. 한국에서 이런 기회를 잡기는 어렵습니다."</>,
    <>
      "I didn't imagine that my son would get a chance to talk with a Harvard graduate. It's a rare opportunity,
      especially outside the U.S."{' '}
    </>,
    's_case_junior.quote',
    <>"我的儿子以前没有和出身哈佛的教师一起对话的经历, 说实话这种机会在韩国并不多见。"</>,
    `「我兒子不曾和哈佛出身的任課教師對話，在韓國很難有這種機會。」`,
    <>
      "私の息子はハーバード出身のチューターに出会って会話したことがありませんでした。韓国でこんなチャンスを掴むのは難しいです。"
    </>,
    <>
      Tôi không nghĩ là con trai mình sẽ có cơ hội nói chuyện cùng một sinh viên tốt nghiệp từ Harvard. Đây là một cơ
      hội hiếm có, đặc biệt khi bạn không ở Mỹ.
    </>,
  ],
  quote_writer: [
    `- Nextrans 대표 / 홍상민님`,
    `- Sangmin Hong, CEO of Nextrans`,
    's_case_junior.quote_writer',
    `- Nextrans代表 / Mr. Hong`,
    `- Nextrans代表／Mr. Hong`,
    `- Nextrans 代表 / ホン・サンミンさん`,
    `- Sangmin Hong, Nextrans CEO`,
  ],
}
export const s_case_beginner = {
  uncomfortable: [
    <>
      <Div spanTag textSecondary>
        초중급 실력
      </Div>
      으로
      <br />
      고급 영어를
      <br />
      구사하고 싶으신 분
    </>,
    <Fragment>Lay the groundwork for advanced discussions</Fragment>,
    's_case_beginner.uncomfortable',
    <>
      <Div spanTag textSecondary>
        拥有初/中级英语实力
      </Div>
      <br />
      渴望学习掌握
      <br />
      高等英语的学员
    </>,
    <>
      希望以
      <Div spanTag textSecondary>
        初／中級英語實力
      </Div>
      <br />
      述說
      <br />
      高級英語的人
    </>,
    <>
      <Div spanTag textSecondary>
        初中級の実力
      </Div>
      で
      <br />
      高級英語を
      <br />
      駆使したい方
    </>,
    <Fragment>Đặt nền tảng các buổi thảo luận nâng cao</Fragment>,
  ],
  still_uncomfortable: [
    <>
      원어민 튜터의 실시간 교정으로
      <br />
      영어 실력을 빠르게 성장시켜
      <br />
      영어가 훨씬 편해집니다
    </>,
    <Fragment>
      Let’s first get you comfortable
      <br />
      with the idea of speaking in English
    </Fragment>,
    's_case_beginner.still_uncomfortable',
    <>
      通过母语教师实时纠正
      <br />
      快速提升您的英语水平
      <br />
      让英语学习游刃有余
    </>,
    <>
      透過母語任課教師的即時糾正，
      <br />
      快速提升英語實力，
      <br />
      讓英語變得不再困難重重。
    </>,
    <>
      ネイティブチューターのリアルタイム校正で
      <br />
      英語力を素早く成長させて
      <br />
      英語がはるかに楽になります。
    </>,
    <Fragment>
      Trước hết hãy cùng thoải mái
      <br />
      với ý tưởng nói tiếng Anh
    </Fragment>,
  ],
  elementary: [
    `#영어초중급`,
    `#Beginner_English`,
    's_case_beginner.elementary',
    `#英语初/中级`,
    `#英語初／中級`,
    `#英語初中級`,
    `#Tiếng_Anh_cơ_bản`,
  ],
  fundamental: [
    `#기초영어`,
    `#Intermediate_English`,
    's_case_beginner.fundamental',
    `#基础英语`,
    `#基礎英語`,
    `#基礎英語`,
    `#Tiếng_Anh_trung_cấp`,
  ],
  level: [
    `#초급레벨`,
    `#Casual_Conversations`,
    's_case_beginner.level',
    `#初级水平`,
    `#初級程度`,
    `#初級レベル`,
    `#Giao_tiếp_hằng_ngày`,
  ],
  daily_talk: [
    `#일상회화`,
    `#Basics`,
    's_case_beginner.daily_talk',
    `#日常会话`,
    `#日常會話`,
    `#日常会話`,
    `#Kiến_thức_căn_bản`,
  ],
  how_good_to_ringle: [
    `영어 초중급 실력인데, 링글을 잘 이용할 수 있을까요?`,
    `Can people at a beginner or intermediate level of English also take Ringle lessons?`,
    's_case_beginner.how_good_to_ringle',
    `英语只有初/中级水平, 适用于Ringle吗?`,
    `英語只有初／中級程度，能夠活用Ringle嗎？`,
    `英語は初中級レベルですが、Ringleを上手く利用できるでしょうか？`,
    `Với trình độ cơ bản hoặc trung cấp có thể tham gia buổi học của Ringle không?`,
  ],
  many_beginners: [
    <>
      네, 링글 수강생의 50% 이상은 초중급 레벨에 속하는 분들입니다. 초중급 레벨 수강생들은 20분, 40분 동안 원어민과
      대화할 수 있을지, 아이비리그 튜터의 영어가 너무 어렵지는 않을지 등을 고민하시기도 합니다.
      <br />
      <br />
      그러나 실제 초중급 레벨로 시작하여 링글을 수강하고 계신 분들은, "시간이 생각보다 훨씬 빠르게 지나간다.", "새로운
      표현/어휘, 발음, 문법 등 원하는 영역에 맞춰 수업이 진행된다.", "무엇보다 내가 말한 영어를 눈으로 보며 실시간으로
      교정을 확인할 수 있어, 훨씬 더 빠른 영어 실력의 성장을 체감한다."라고 말씀하십니다. 영어 실력의 정체감을 느끼거나,
      한 단계 더 높은 영어 실력을 목표하시는 초중급 수강생이시라면 링글을 시작해보세요!
    </>,
    <>
      They sure can! Over half of Ringle customers speak beginner or intermediate level English, meaning they do not
      have much problem with reading, but feel less comfortable with speaking and writing.
      <br />
      <br />
      If you are a beginner or intermediate level speaker, you might be worried that you lack the fluency to talk to a
      native speaker for 20 or 40 minutes straight.
    </>,
    's_case_beginner.many_beginners',
    <>
      当然, Ringle学员中超过50%属于初/中英语水平。因此必然出现诸如初/中水平的学生在20分种或40分钟的课程期间,
      能否和母语教师进行良好的对话沟通以及出身常青藤联盟的教师们使用的英语会不会难以理解等担心的问题。
      <br />
      <br />
      但是, 在实际课程中英语水平初/中水平的学员们普遍感到‘时间比想象中过得快’, ‘可以针对新学习的表达方式/词汇,
      发音及语法等希望的部分进行定制型课程’以及‘最重要的是能够实时确认对自身英语问题的纠正,
      切实体会到英语实力的快速提升’等积极作用。想要了解自身英语的整体实力或以更高水平的英语作为学习目标的初/中水平的学员们,
      请尽快开始您的Ringle课程吧!
    </>,
    <>
      當然，Ringle學生之中超過50%屬於初／中級程度。初／中級程度的學生也很煩惱20分鐘或40分鐘期間能否和母語人士對話，或擔心來自常春藤名校的任課教師的英語會不會太難。
      <br />
      <br />
      但實際上以初／中級程度的英語開始聆聽Ringle的學生們表示：「時間比想像中快很多。」、「可以針對全新用法／詞彙、發音、文法等希望的領域進行課程。」、「最重要的是，能夠親眼確認我說的英語即時獲得糾正，確實體會到英語實力快速提升。」若您是感覺到英語實力停滯不前或想將英語實力提升一級的初／中級學生，那就開始您的Ringle課程吧！
    </>,
    <>
      はい。Ringle受講生の50%以上は初級レベルの方です。初中級レベルの受講生は20分、40分間ネイティブと会話できるか、アイビーリーグのチューターの英語が難しすぎないかなど、悩まれたりもします。
      <br />
      <br />
      しかし、実際に初中級レベルから始めてRingleを受講している方は「時間が思ったよりはるかに早く過ぎる」「新しい表現/語彙、発音、文法など希望する分野に合わせてレッスンが進む」「何よりも自分が話した英語を目で見ながらリアルタイムで校正を確認することができ、はるかに速い英語力の成長を体感する」とおっしゃいます。英語力の停滞感を感じたり、もう一段階高い英語力を目指す初中級の方なら、Ringleを始めましょう！
    </>,
    <>
      Tất cả đều có thể học! Hơn một nửa học viên Ringle có kỹ năng nói cơ bản hoặc trung cấp, họ không có vấn đề về
      việc đọc nhưng chưa hoàn toàn thoải mái trong việc nói và viết.
      <br />
      <br />
      Nếu kỹ năng nói ở mức cơ bản hoặc trung cấp, bạn có thể đang lo lắng về khả năng sử dụng tiếng Anh một cách trôi
      chảy khi nói chuyện với người bản xứ liên tục trong 20 hoặc 40 phút.
    </>,
  ],
  question: [
    <>영어 초중급자는 링글을 어떻게 활용해야 할까요?</>,
    `Fear not! Here are some ways that Ringle can be catered to your needs of honing the basics and getting your feet wet with English speaking:`,
    's_case_beginner.question',
    <>英语初/中水平者应该如何使用Ringle呢?</>,
    <>英語初／中級者如何活用Ringle呢？</>,
    <>英語の初中級者はRingleをどのように活用すべきですか？</>,
    `Đừng sợ! Đây là một số cách Ringle làm để đáp ứng nhu cầu trau dồi kiến thức và làm quen với việc nói tiếng Anh của bạn:`,
  ],
  bullet_1: [
    <>
      나와 맞는 튜터와 정기적으로 수업해보세요. 매번 새로운 튜터를 만나는 것이 조금은 부담스럽다면, 내가 원하는
      수업방식과 나의 영어 고민을 이해해주는 2-3명의 튜터와 주기적으로 수업하며 영어 실력을 향상시키세요.
    </>,
    `Try having regular lessons with one tutor you like, instead of meeting with a different tutor each time, so that the familiar face will help ease any existing tension or fear about English speaking`,
    's_case_beginner.bullet_1',
    <>
      请坚持定期和适合自己的教师一起学习吧。 如果每周要和全新的教师一起学习让您感到负担的话,
      可定期和2-3位能够按照本人希望的授课形式进行课程安排且能够理解学员英语学习烦恼的教师一起学习, 提升您的英语水平吧。
    </>,
    <>
      請定期和適合自己的任課教師學習。如果每週要和全新的任課教師上課讓您感到負擔的話，那就定期和2～3位能夠按照您希望的上課方式並理解您的英語煩惱的任課教師上課，提升英語實力吧！
    </>,
    <>
      自分に合ったチューターと定期的にレッスンしましょう。毎回新しいチューターに出会うのは少し気が重いなら、あなたが望むレッスン方式とあなたの英語の悩みを理解してくれる2-3人のチューターと周期的にレッスンをして、英語力を向上させましょう。
    </>,
    `Hãy học đều đặn với một gia sư bạn yêu thích thay vì học với những gia sư khác nhau. Sự quen thuộc sẽ giúp bạn giảm căng thẳng và lo sợ trong quá trình luyện nói tiếng Anh`,
  ],
  bullet_2: [
    `나에게 익숙한 주제의 교재, 혹은 일상 대화 주제로 수업한 후, 조금 더 난이도 있는 교재에 도전하세요. 하나의 교재로 여러 번 수업하며 교재와 관련된 영어 표현을 완전히 익히는 방법도 있습니다.`,
    `Try using the same lesson materials for 2 or 3 lessons so you can really absorb all the relevant expressions`,
    's_case_beginner.bullet_2',
    `在使用过自身熟悉的主题教材或以日常对话为主题进行课程后, 可逐渐挑战具有一定难度的内容进行学习。同时还有针对同一教材反复学习, 将其中的英语表达方式彻底掌握的学习形式供您选择。`,
    `使用您熟悉的主題教材或以日常對話為主題進行課程後，逐漸挑戰難度更高的教材吧！使用一個教材進行多次課程，完全熟悉與教材相關的用法也是一個方法。`,
    `あなたにとって身近なテーマの教材や日常会話をテーマにレッスンした後、より難易度の高い教材に挑戦しましょう。一つの教材で何度もレッスンを行いながら、教材に関する英語表現を完全に身につける方法もあります。`,
    `Thử dùng cùng một tài liệu học cho 2, 3 buổi học liên tục để có thể nắm rõ tất cả những cách diễn đạt tương tự`,
  ],
  bullet_3: [
    <>
      예습, 복습을 적극 활용하세요. 수업 전 예습을 위해 제공되는 다양한 자료를 미리 공부하고, 수업이 끝나면 녹음 파일과
      스크립트, 수업 노트를 언제 어디서든 다시 확인해 배운 내용을 완전히 내 것으로 만들 수 있습니다.
    </>,
    `Take advantage of tutor’s detailed feedback, audio-recording of the lesson, and AI analysis to further make notes of your room for improvement, and apply them at your next lesson!`,
    's_case_beginner.bullet_3',
    <>
      请积极对学习内容进行预习及复习。上课前对提供的资料进行预习, 同时课程结束后可对提供的录音文件, 脚本,
      课程笔记等进行确认及复习, 确保课堂中学习的内容能够真正的融会贯通。
    </>,
    <>
      請積極活用預習及複習。課程開始前事先預習各種資料，課程結束後隨時隨地重新確認錄音檔、腳本、課堂筆記，可完全將所學的內容變成您的東西。
    </>,
    <>
      予習、復習を積極的に活用しましょう。レッスン前に予習のために提供される多様な資料で事前に勉強し、レッスン後は録音ファイル、スクリプト、レッスンノートをいつでもどこでも確認して学んだ内容を完全に自分のものにすることができます。
    </>,
    `Hãy tận dụng phản hồi chi tiết của gia sư, bản ghi âm buổi học và phân tích từ AI để ghi lại những điều cần cải thiện và áp dụng những điều đó cho buổi học tiếp theo!`,
  ],
  quote: [
    <>
      다른 플랫폼에서는 영어로 말해도 말한 것이 사라지는 느낌이었는데, 링글에서는 말한게 저에게 남는 느낌이에요. 링글을
      더 일찍 알았다면, 지금보다 영어를 더 잘했을 것 같아요.
    </>,
    <>
      "My English would have been better if I had started Ringle earlier." <br />
    </>,
    's_case_beginner.quote',
    <>
      在其他平台学习英语时, 感觉虽然在使用英语但没有任何的存在感,
      而在Ringle我觉得我说的每一句都清晰可见印象深刻。如果早一点知道Ringle, 我想我的英语水平应该不止如此。
    </>,
    <>
      在其他平台學習英語時，感覺我說的英語一說即逝，但在Ringle，我說的英語都留下來了。如果早一點知道Ringle，我想我現在的英語實力應該不止如此。
    </>,
    <>
      他のプラットフォームでは英語で言っても話したことが消える感じでしたが、Ringleでは話したことが自分に残る感じです。Ringleをもっと早く知っていたら、今よりもっと英語が上手だったと思います。
    </>,
    <>
      "Tiếng Anh của tôi có lẽ đã tốt hơn nếu tôi bắt đầu với Ringle sớm hơn." <br />
    </>,
  ],
  quote_writer: [
    `- 영문과 이소정님`,
    `- Sojung, English major`,
    's_case_beginner.quote_writer',
    `- 英文系 Ms Lee`,
    `- 英文系 Ms. Lee`,
    `- 英文科 イ・ソジョンさん`,
    `- Sojung, chuyên ngành Tiếng Anh`,
  ],
}
export const s_case_live_abroad = {
  who_live_abroad: [
    <>
      현재{' '}
      <Div spanTag textSecondary>
        해외에 거주
      </Div>
      하시면서
      <br />
      영어 실력의 한계를 느껴
      <br />
      빠른 성장을 원하는 분
    </>,
    <>Go beyond basic conversations</>,
    's_case_live_abroad.who_live_abroad',
    <>
      目前
      <Div spanTag textSecondary>
        虽在
      </Div>
      海外生活
      <br />
      但是感觉英语水平进入瓶颈期
      <br />
      希望能够快速进步的人士
    </>,
    <>
      目前
      <Div spanTag textSecondary>
        居住海外
      </Div>
      ，
      <br />
      但感覺英語實力達極限，
      <br />
      希望能快速進步的人
    </>,
    <>
      現在{' '}
      <Div spanTag textSecondary>
        海外に居住
      </Div>
      していて
      <br />
      英語力の限界を感じて
      <br />
      早く成長したい方
    </>,
    <>Vượt qua những cuộc trò chuyện cơ bản</>,
  ],
  precise_comm: [
    <>
      매일 영어를 사용하지만
      <br />
      조금 더 정확하고 세련된
      <br />
      커뮤니케이션 능력을 목표합니다
    </>,
    <>Unlock the missing fluency to immerse yourself in the new culture</>,
    's_case_live_abroad.precise_comm',
    <>
      虽然每天都在使用英语
      <br />
      但还是希望能够掌握更为准确精炼的
      <br />
      沟通交流能力
    </>,
    <>
      雖然每天都在使用英語，
      <br />
      但希望能夠培養更精確幹練的
      <br />
      溝通能力。
    </>,
    <>
      毎日英語を使用しますが
      <br />
      もう少し正確で洗練された
      <br />
      コミュニケーション能力を目指します。
    </>,
    <>Hoàn thiện khả năng nói trôi chảy để hòa nhập với nền văn hóa mới</>,
  ],
  foreign_job: [
    `#해외거주`,
    `#Casual_conversations`,
    's_case_live_abroad.foreign_job',
    `#海外居住`,
    `#海外居住`,
    `#海外居住`,
    `#Hội_thoại_hằng_ngày`,
  ],
  business_eng: [
    `#원어민표현`,
    `#Idioms`,
    's_case_live_abroad.business_eng',
    `#母语表达`,
    `#母語人士用法`,
    `#ネイティブ表現`,
    `#Thành_ngữ`,
  ],
  student: [`#영어회화`, `#Expressions`, 's_case_live_abroad.student', `#英语会话`, `#英語會話`, `#英会話`, `#Mẫu_câu`],
  live_abroad: [
    `#실전영어`,
    `#Real_Life_English`,
    's_case_live_abroad.live_abroad',
    `#实战英语`,
    `#實戰英語`,
    `#実践英語`,
    `#Tiếng_Anh_trong_đời_sống`,
  ],
  other_than_korea: [
    `한국이 아닌 다른 나라에서도 링글을 수강하나요?`,
    `Will I find Ringle helpful if I currently live or have previously lived in an English-speaking country?`,
    's_case_live_abroad.other_than_korea',
    `不在韩国身处其他国家也可以使用Ringle吗?`,
    `即使不在韓國，而是在其他國家也能使用Ringle嗎？`,
    `日本ではない他国でもRingleを受講していますか？`,
    `Liệu Ringle có ích với tôi không nếu tôi đang sống hoặc đã từng sống ở các nước nói tiếng Anh?`,
  ],
  english_not_good: [
    <>
      네, 링글 수강생의 25% 정도는 해외에 거주하고 계십니다. 특히 "해외에 살아도 실제로 영어를 사용할 기회가 생각보다
      많지 않다.", "외국에 살고 있지만 아직 영어가 완벽하지 않아 한계를 느꼈다.", "나의 영어를 교정해주는 사람이 없어
      제대로 영어를 구사하고 있는지 잘 모르겠다." 등의 고민으로 링글을 수강하는 분들이 많습니다.
      <br />
      <br />
      어느 정도 영어로 소통은 가능하지만, 내가 표현하고자 하는 바가 영어로 인해 다 전달되지 못해 영어 실력의 한계를
      느끼신다면, 링글과 함께 정말 유창한 영어 실력을 목표해보세요.
    </>,
    <Fragment>
      Definitely! 25% of our customers are overseas residents. Many of them find it surprisingly difficult to improve
      their English, either because they interact with other immigrants of similar fluency levels or because their
      native speaker friends or colleagues do not correct their English.
      <br />
      <br />
      Interacting with locals to make friends, get advice, and experience the culture fully requires more than basic
      English.
    </Fragment>,
    's_case_live_abroad.english_not_good',
    <>
      当然, 目前Ringle学员中有将近25%居住在海外。其中深感‘即使生活在国外,
      但实际使用英语的机会并没有想象中那么多’以及‘虽然在外国, 但英语水平还不够完美, 感到进入了上升瓶颈期’,
      ‘平时并没有人帮我纠正英文, 因此我也不能确定我的英语使用是否正确’的人其实很多。
      <br />
      <br />
      虽然具备一定程度的英语沟通能力, 但还是会出现英语表达上的限制, 感到英语实力上升存在瓶颈,
      那么请携手Ringle一起实现流畅英语水平的目标吧。
    </>,
    <>
      當然，目前Ringle有25%左右的學生居住海外。有許多學生因為煩惱「即使在海外生活，但實際使用英語的機會並沒有想像中那麼多。」、「雖然住國外，但是英語尚不完美，感覺達到了極限。」、「因為沒人糾正我的英語，我不曉得我說的英語是否正確。」而聆聽Ringle的課程。
      <br />
      <br />
      雖然具備一定程度的英語溝通能力，但因為無法使用英語完全表達您的意思，而感覺到英語實力極限的話，那就與Ringle一起實現流暢英語實力的目標吧。
    </>,
    <>
      はい。Ringle受講生の約25%は海外に居住しています。特に「海外に住んでいても実際に英語を使う機会が思ったより多くない」「外国に住んでいるが、まだ英語が完璧ではなく限界を感じた」「私の英語を正してくれる人がいないので、きちんとした英語を駆使しているかどうかわからない」などのお悩みでRingleを受講する方が多いです。
      <br />
      <br />
      ある程度英語でのコミュニケーションは可能ですが、自分が表現したいことを英語ですべて伝えられず、英語力の限界を感じているなら、Ringleと共に流暢な英語を目指しましょう。
    </>,
    <Fragment>
      Chắc chắn rồi! 25% học viên của chúng tôi định cư ở nước ngoài. Rất nhiều người trong số họ gặp khó khăn trong
      việc cải thiện tiếng Anh. Có thể là vì họ chỉ tiếp xúc với những người định cư với trình độ tiếng Anh giống họ
      hoặc những người bạn bản xứ không sửa lỗi tiếng Anh cho họ.
      <br />
      <br />
      Tiếp xúc với người bản xứ để kết bạn, nhận lời khuyên và trải nghiệm nền văn hoá một cách trọn vẹn đòi hỏi trình
      độ tiếng Anh hơn mức cơ bản.
    </Fragment>,
  ],
  question: [
    <>해외 거주자인데 링글이 제게 어떻게 도움이 될까요?</>,
    `Here’s how Ringle can get you to the next level of fluency:`,
    's_case_live_abroad.question',
    <>作为海外居住者Ringle能带给我什么帮助?</>,
    <>我是海外居住者，Ringle能帶給我什麼幫助？</>,
    <>海外居住者ですが、Ringleは私にどう役立ちますか？</>,
    `Đây là cách Ringle nâng cấp mức độ thành thạo của bạn:`,
  ],
  bullet_1: [
    <>
      외국에 살아도 나의 영어를 교정받을 수 있는 상황은 많지 않습니다. 링글 수업으로 원어민 관점에서 내 표현이 어색한지,
      그렇다면 대체할 표현은 무엇이 있을지, 자주 하는 문법 실수가 있거나 발음이 어색하지는 않은지 등 원어민 튜터에게
      구체적이고 실질적인 피드백과 교정을 받아보세요.
    </>,
    `Ringle tutors come from a diverse background so you can select the ones that share yours, or are from a background that you’re curious about`,
    's_case_live_abroad.bullet_1',
    <>
      即使身处国外, 但对自身英语进行纠正的机会并不是很多。通过Ringle课程从母语教师的角度了解自身英语表达中的生涩之处,
      学习针对以上问题的替代表达方法。洞察自身习惯性语法错误, 发音偏差等问题, 从母语教师处获得具体实用的反馈及纠正吧。
    </>,
    <>
      雖然住在國外，但自身英語能夠獲得糾正的機會並不多。透過Ringle的課程，以母語人士的觀點確認您的用法是否不自然、那可以使用什麼用法、是否有常犯的文法錯誤或發音不自然等，取得母語任課教師具體且實質的意見回饋及糾正吧！
    </>,
    <>
      外国に住んでいても、自分の英語を正してもらえることは多くありません。Ringleのレッスンでネイティブの観点から自分の表現が不自然なのか、その場合代替する表現は何があるのか、よくする文法ミスや発音が不自然ではないかなど、ネイティブチューターに具体的かつ実質的なフィードバックと校正をしてもらいましょう。
    </>,
    `Gia sư Ringle có những nền tảng khác nhau. Bạn nên chọn người chia sẻ cùng một nền tảng giống bạn hoặc người có câu chuyện mà bạn tò mò muốn hiểu thêm.`,
  ],
  bullet_2: [
    <>
      발음 교정, 문법, 고급 영어 표현 등 구체적인 요청사항을 적어 맞춤 수업을 진행하세요. 매 수업마다 원하는 튜터와
      교재로, 원하는 수업방식을 설정해 효율적인 수업을 진행할 수 있습니다.
    </>,
    `Unlike native speaker friends who might feel sheepish about correcting your English, Ringle tutors will point out every mistake in grammar, pronunciation and vocabulary, and offer a more fluent paraphrasing`,
    's_case_live_abroad.bullet_2',
    <>
      请填写发音矫正, 语法, 高级英语表达等具体要求事项, 以便能够开展更具针对性的课程。每堂课都可选择满意的教师及教材,
      设定具体的课程形式, 有助于课程能够更加有效进行。
    </>,
    <>
      請寫下發音矯正、文法、高級英語用法等具體要求事項，進行客製化課程吧！每堂課程都可選擇您希望的任課教師及教材，設定希望的上課方式，有效率地進行課程。
    </>,
    <>
      発音矯正、文法、高級英語表現など、具体的なリクエスト事項を書いてカスタマイズレッスンを行いましょう。レッスンごとに希望するチューターと教材で、希望するレッスン方式を設定して効率的なレッスンを行うことができます。
    </>,
    `Những người bạn bản xứ có thể sẽ thấy ngại khi chỉnh phát âm cho bạn, nhưng gia sư Ringle sẽ chỉ ra những lỗi sai trong ngữ pháp, phát âm, từ vựng và giúp bạn chỉnh câu trôi chảy hơn`,
  ],
  bullet_3: [
    <>
      다양한 교재를 활용해 튜터와 대화하며 문장력과 표현력을 넓혀보세요. 어떤 상황에서도 내 생각을 영어로 명확히
      전달하는 능력을 키울 수 있습니다. 또한 여러 명의 튜터와 수업하며 다양한 배경을 가진 튜터들과 생각을 공유할 수도
      있습니다.
    </>,
    `Ringle tutors also teach the cultural contexts of various native expressions so that you can start using them at the right place and time`,
    's_case_live_abroad.bullet_3',
    <>
      请选用多样化的教材,
      和教师们展开对话开拓您的写作能力及表达能力吧。提升无论在何种情况下都能灵活运用英语表达自身想法的能力。此外可选择不同教师的课程和具有丰富知识背景的教师们分享您的想法。
    </>,
    <>
      請活用各種教材並和任課教師對話，拓展您的句子與用法實力。您可培養無論在什麼情況下，都能使用英文明確表達想法的能力。此外，您也可與數名任課教師上課，與具有豐富背景的任課教師們分享您的想法。
    </>,
    <>
      多様な教材を活用してチューターと会話しながら、文章力と表現力を高めましょう。どんな状況でも自分の考えを英語で明確に伝える能力を育みます。また、複数のチューターとレッスンをしながら、さまざまな背景を持つチューターと考えを共有することもできます。
    </>,
    `Gia sư Ringle hướng dẫn những cách diễn đạt khác nhau đặt trong bối cảnh về văn hoá để giúp bạn sử dụng chúng đúng nơi và đúng thời điểm`,
  ],
  quote: [
    <>
      "미국에 와서 사는 사람들 중에 영어로부터 자유로울 수 있는 분은 사실 많지 않은 것 같아요. 그래서 저의 영어를 조금
      더 파인튜닝 할 수 있는 그런 서비스를 찾았습니다."
    </>,
    <>
      "As an immigrant myself, I don’t think I’ve met a lot of other immigrants who are completely free of concerns when
      it comes to English. So I was looking for a service that can help me fine-tune my English."{' '}
    </>,
    's_case_live_abroad.quote',
    <>"其实来到美国生活的人当中无法自由使用英语的人还有很多。为了让我的英语水平能够更加精准, 我找到了Ringle。"</>,
    `「其實來到美國生活的人之中，有很多人還是無法自由使用英語，我也尋找了可以改善我的英語的服務。」`,
    <>
      "アメリカに来て暮らす人の中で、英語から自由になれる方は実際多くないようです。なので私の英語がもう少しファインチューニングできる、そんなサービスを見つけました。"
    </>,
    <>
      "Bản thân tôi là một người nhập cư và đã gặp nhiều người nhập cư khác cũng lo lắng về vấn đề tiếng Anh cuả họ.
      Chính vì vậy tôi đã tìm kiếm một dịch vụ có thể giúp tôi cải thiện tiếng Anh của mình."{' '}
    </>,
  ],
  quote_writer: [
    `- 정남원 님`,
    `- Namwon`,
    's_case_live_abroad.quote_writer',
    `- Mr. Jeong`,
    `- Mr. Jeong`,
    `- チョン・ナムウォンさん`,
    `- Namwon`,
  ],
}
export const s_trial = {
  noTimeBanner: {
    text_1: [
      <>
        <div>
          <Span bold>바로 구매하면 추가 혜택을 드립니다.</Span>
        </div>
        <div>
          <Span size={14}>1만 원 추가할인+최대 3회 수업권</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            첫 수업 후 환불 요청 시, 100% 환불 제공
          </Span>
        </div>
      </>,
      <>
        Buy now without a Free Trial Lesson
        <br />
        And get up to three free lesson credits
      </>,
      's_trial.noTimeBanner.text_1',
      <>
        <div>
          <Span bold>立即购买可享受额外优惠。</Span>
        </div>
        <div>
          <Span size={14}>$8.62额外优惠+最多四次课程券</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            首次课程后申请退款时, 可100%返还
          </Span>
        </div>
      </>,
      <>
        <div>
          <Span bold>立即購買就送您額外優惠。</Span>
        </div>
        <div>
          <Span size={14}>$8.62額外折扣+最多4次課程券</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            首次課程後申請退款時，可全額退款
          </Span>
        </div>
      </>,
      <>
        <div>
          <Span bold>今すぐご購入で追加特典を差し上げます。</Span>
        </div>
        <div>
          <Span size={14}>$8.62追加割引+最大3回レッスンチケット</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            初回レッスン後にご満足いただけない場合、全額返金
          </Span>
        </div>
      </>,
      <>
        Mua ngay không cần buổi học thử miễn phí
        <br />
        Cơ hội nhận được tới 3 thẻ học miễn phí
      </>,
    ],
    go: [
      <>바로 구매하기</>,
      <>Buy Now</>,
      's_trial.noTimeBanner.go',
      <>立即购买</>,
      <>立即購買</>,
      <>すぐに購入する</>,
      <>Mua ngay</>,
    ],
  },
  purchase_message: [
    <>
      무료 체험 수업은 시간과 교재 선택이 제한적일 수 있습니다.
      <br />
      체험없이 구매 시, 10,000원 추가 할인 및 최대 3회 추가 수업이 제공됩니다. (첫 수업 불만족 시 100% 환불 보장)
    </>,
    <>
      Time slots and materials may be limited for trial lessons.
      <br />
      Buy now without a Free trial for additional promo or 3 free lesson credits.
      <br />
      100% Money-back guarantee.
    </>,
    's_trial.purchase_message',
    <>
      免费体验课程的时间及教材可能存在选择限制。
      <br />
      不体验直接购买时可享受$8.62的额外优惠以及最多4次的课程券。 (首次课程不满意时, 承诺100%退款)
    </>,
    <>
      免費體驗課程的時間與教材選擇上可能有限。
      <br />
      未先體驗即購買時，提供$8.62額外折扣及最多3次額外課程。 （首次課程不滿意時，可全額退款）
    </>,
    <>
      無料体験レッスンは時間と教材選択が制限されることがあります。
      <br />
      体験なしでご購入時、$8.62追加割引および最大3回追加レッスンが提供されます。
      (初回レッスン後にご満足いただけない場合、全額返金)
    </>,
    <>
      Thời gian học và tài liệu có thể giới hạn trong buổi học thử.
      <br />
      Mua ngay không cần học thử để nhận thêm ưu đãi hoặc 3 thẻ học miễn phí.
      <br />
      Hoàn tiền 100%.
    </>,
  ],
  select_date: [`날짜 선택`, `Select date`, 's_trial.select_date', `选择日期`, `選擇日期`, `日付選択`, `Chọn ngày`],
  select_time: [`시간 선택`, `Select time`, 's_trial.select_time', `选择时间`, `選擇時間`, `時間選択`, `Chọn giờ`],
  request_verification_code: [
    `인증 요청`,
    `Request verification code`,
    's_trial.request_verification_code',
    `申请验证`,
    `傳送驗證碼`,
    `認証リクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  _1_title: [
    `체험 수업 시간 선택`,
    <>Select Date & Time</>,
    's_trial._1_title',
    `选择体验课程的时间`,
    `選擇體驗課程時間`,
    `体験レッスン時間選択`,
    <>Chọn Ngày & Giờ</>,
  ],
  _1_subTitle: [
    `시간은 수업 시작 24시간 전까지 변경하실 수 있습니다.`,
    `You may reschedule as late as 24 hours before the lesson starts.`,
    's_trial._1_subTitle',
    `时间变更截止于课程开始前24小时`,
    `最晚可於課程開始24小時前更改時間。`,
    `時間はレッスン開始24時間前まで変更することができます。`,
    `Bạn có thể dời buổi học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  _1_description: [
    `무료 체험 수업은 각 시간대에서 선착순으로 제공되고 있기 때문에 시간 선택이 제한적일 수 있습니다.`,
    ``,
    's_trial._1_description',
    `免费体验课程将于各时间段按先后顺序进行, 因此时间选择上存在局限。`,
    `免費體驗課程為各時段先搶先贏，時間選擇上可能有限。`,
    `無料体験レッスンは各時間帯に先着順で提供されるため、時間選択が制限されることがあります。`,
    ``,
  ],
  _2_title: [
    `교재 선택`,
    `Select Lesson Materials`,
    's_trial._2_title',
    `选择教材`,
    `選擇教材`,
    `教材選択`,
    `Chọn Tài liệu học`,
  ],
  _2_subTitle: [
    <>
      수업에서 튜터와 다룰 교재는&nbsp;
      <Span className="font-weight-medium">수업 시작 24시간 전까지</Span>&nbsp; 변경하실 수 있습니다.
    </>,
    `You may change Lesson Materials as late as 24 hours before the lesson starts.`,
    's_trial._2_subTitle',
    <>
      课程中与教师讨论的教材&nbsp;
      <Span className="font-weight-medium">变更截止于课程</Span>&nbsp; 开始前24小时。
    </>,
    <>
      &nbsp; <Span className="font-weight-medium">最晚可於課程開始24小時前</Span>
      &nbsp;變更上課時要與任課教師進行的教材。
    </>,
    <>
      レッスンでチューターと扱う教材は&nbsp;
      <Span className="font-weight-medium">レッスン開始24時間前まで</Span>&nbsp; 変更することができます。
    </>,
    `Bạn có thể đổi Tài liệu học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  _2_description: [
    `무료 체험 수업은 일부 교재만 선택할 수 있습니다. 정규수업에서는 인터뷰/프리젠테이션 준비 등을 위한 맞춤 수업이 가능합니다.`,
    ``,
    's_trial._2_description',
    `免费体验课程只能选择特定教材。正规课程可针对面试/报告书等内容进行定制型课程。`,
    `免費體驗課程只能選擇部分教材，正規課程可針對面試／報告準備等進行客製化課程。`,
    `無料体験レッスンは一部の教材のみ選択することができます。レギュラーレッスンでは面接/プレゼンテーション準備などのためのカスタマイズレッスンが可能です。`,
    ``,
  ],
  _3_title: [
    `휴대폰 인증`,
    `Verify Mobile Number`,
    's_trial._3_title',
    `手机验证`,
    `手機驗證`,
    `SMS認証`,
    `Xác nhận số điện thoại`,
  ],
  _3_subTitle: [
    `무료 체험 수업 신청 마지막 단계입니다. 인증 중 문제가 발생하면 [문의]를 클릭하여 연락해주세요.`,
    ``,
    's_trial._3_subTitle',
    `申请免费体验课程的最后一步。如在验证过程中出现问题, 请点击[咨询]按钮进行咨询。`,
    `申請免費體驗課程的最後步驟。如驗證過程中出現問題，請點擊[諮詢]聯絡我們。`,
    `無料体験レッスン予約の最終ステップです。認証中に問題が発生した場合は [お問い合わせ]をクリックしてご連絡ください。`,
    ``,
  ],
  there_is_no_available_time: [
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          해당 날짜 체험수업은 마감되었습니다.
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        [날짜 선택] 탭을 통해 다른 일정을 확인해 보세요.
        <br />
        체험수업은 모든 정규수업이 선 배정된 후 선착순으로 배정됩니다.
        <br />
        현재 모든 수업 수가 동시 증가하여, 체험수업 시간이 충분하지 않음을 양해 부탁드립니다.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          수업 관련 문의는 홈페이지 하단의 [1:1 문의]를 이용해 주세요.
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span size={18}>There is no availability on the selected date.</Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        Ringle assigns regular lessons first, then allocates the remaining time slots for free trial lessons.
        <br />
        Please understand that there may not be enough available time slots due to high demands.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          If you have any questions, please contact us via chat below.
        </Span>
      </div>
    </>,
    's_trial.there_is_no_available_time',
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          所选时间的体验课程已经截止。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        请通过[选择日期]弹窗确认其他日程。
        <br />
        体验课程在所有正规课程排课后, 按先后顺序进行分配。
        <br />
        当前所有课程的数量同步增加, 体验课程的时间 不足, 敬请谅解。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          有关课程的其他问题, 可通过主页下方的在线咨询联系我们。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          該日期的體驗課程已截止。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        請透過[選擇日期]分頁確認其他日程。
        <br />
        體驗課程將於所有正規課程分配結束後，按照先後順序進行分配。
        <br />
        目前所有課程數同時增加，體驗課程時間不足，敬請見諒。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          如有課程相關疑問，請透過網頁下方的聊天聯絡我們。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          この日の体験レッスンは締め切りました。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        [日付選択] タブから他の日程を確認してください。
        <br />
        体験レッスンはすべてのレギュラーレッスンが先に割り当てられた後、先着順で割り当てられます。
        <br />
        現在、すべてのレッスン数が同時に増え、体験レッスン時間が十分ではありません。 ご了承ください。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          レッスンに関する質問はホームページ下のチャネルトークよりお問い合わせください。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span size={18}>Không có buổi học nào trong ngày bạn chọn.</Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        Ringle sắp xếp buổi học thông thường trước, sau đó sẽ sắp xếp thời gian còn lại cho buổi học thử.
        <br />
        Mong bạn thông cảm do nhu cầu tăng cao nên có thể không đủ sự lựa chọn.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          Nếu bạn có bất kì câu hỏi nào, hãy chat với chúng tôi.
        </Span>
      </div>
    </>,
  ],
  not_select_time: [
    `시간 선택되지 않음`,
    `Time unselected`,
    's_trial.not_select_time',
    `未选择时间`,
    `未選擇時間`,
    `時間未選択`,
    `Thời gian chưa chọn`,
  ],
  selected_time: [
    `시간 선택 완료`,
    `Time selected`,
    's_trial.selected_time',
    `完成时间选择`,
    `時間選擇完畢`,
    `時間選択完了`,
    `Thời gian đã được chọn`,
  ],
  not_verify_phone: [
    `번호 인증되지 않음`,
    `Mobile number not verified`,
    's_trial.not_verify_phone',
    `号码未验证`,
    `未驗證號碼`,
    `携帯電話番号未認証`,
    `Số điện thoại chưa được xác minh`,
  ],
  verify_phone: [
    `번호 인증 됨`,
    `Mobile number verified`,
    's_trial.verify_phone',
    `号码已验证`,
    `號碼驗證成功`,
    `携帯電話番号認証済み`,
    `Số điện thoại đã được xác minh`,
  ],
  not_select_course: [
    `교재 선택되지 않음`,
    `Materials unselected`,
    's_trial.not_select_course',
    `未选择教材`,
    `未選擇教材`,
    `教材未選択`,
    `Tài liệu chưa được chọn`,
  ],
  selected_course: [
    `교재 선택 완료`,
    `Materials selected`,
    's_trial.selected_course',
    `完成教材选择`,
    `教材選擇完畢`,
    `教材選択完了`,
    `Tài liệu đã chọn`,
  ],
  enter_your_phone_number: [
    `휴대폰 번호를 입력해주세요`,
    `Enter your phone number.`,
    's_trial.enter_your_phone_number',
    `请输入您的手机号码`,
    `請輸入手機號碼`,
    `携帯電話番号を入力してください。`,
    `Nhập số điện thoại của bạn.`,
  ],
  verification_is_incorrect_plz_try_again: [
    `인증 번호가 틀렸습니다. 다시 입력해 주세요`,
    `Verification is incorrect. Please try again.`,
    's_trial.verification_is_incorrect_plz_try_again',
    `验证码出现错误, 请重新输入`,
    `驗證碼錯誤，請重新輸入。`,
    `認証コードが間違っています。もう一度入力してください。`,
    `Mã xác minh không chính xác. Vui lòng thử lại.`,
  ],
  view_material: [
    `교재 보기`,
    `View Lesson Materials`,
    's_trial.view_material',
    `查看教材`,
    `查看教材`,
    `教材を見る`,
    `Xem Tài liệu học`,
  ],
  you_have_already_requested: [
    `이미 체험신청이 완료되었습니다.`,
    `You already booked your trial lesson.`,
    's_trial.you_have_already_requested',
    `体验申请已经完成`,
    `已完成體驗申請。`,
    `すでに体験予約が完了しました。`,
    `Bạn đã đặt lịch buổi học thử.`,
  ],
  please_select_a_date_and_time: [
    `날짜와 시간을 선택해주세요`,
    `Please select date and time.`,
    's_trial.please_select_a_date_and_time',
    `请选择日期和时间`,
    `請選擇日期和時間`,
    `日付と時間を選択してください。`,
    `Vui lòng chọn ngày và giờ.`,
  ],
  please_select_material: [
    `교재를 선택해주세요`,
    `Please select lesson materials.`,
    's_trial.please_select_material',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください。`,
    `Vui lòng chọn tài liệu học.`,
  ],
  please_complete_phone_number_verification: [
    `휴대폰 인증을 완료해주세요`,
    `Please verify your mobile number.`,
    's_trial.please_complete_phone_number_verification',
    `请完成手机验证`,
    `請完成手機驗證`,
    `SMS認証を完了してください。`,
    `Vui lòng xác minh số điện thoại của bạn.`,
  ],
  submit: [`체험 수업 신청`, `Submit`, 's_trial.submit', `申请体验课程`, `申請體驗課程`, `体験レッスン予約`, `Gửi`],
  incomplete_alert: [
    `수업시간 선택, 교재 선택, 번호 인증 등의 3단계를 모두 완료하셔야만 체험 수업을 신청하실 수 있습니다.`,
    `Please complete all three steps to book your trial lesson: 1) select date & time, 2) select lesson materials, 3) verifying mobile number.`,
    's_trial.incomplete_alert',
    `需完成课程时间选择, 教材选择及手机验证三个步骤后,体验课程申请才能成功。`,
    `必須選擇課程時間、選擇教材及驗證號碼等完成3個步驟，才能申請體驗課程。`,
    `レッスン時間選択、教材選択、SMS認証などの3ステップをすべて完了しなければ、体験レッスンを予約することができません。`,
    `Vui lòng hoàn thành hết ba bước để đăng ký học thử: 1) chọn ngày & giờ, 2) chọn tài liệu, 3) xác minh số điện thoại.`,
  ],
  you_can_view_list_of_available_tutors_after_entering_self_intro: [
    `짧게 시간내어 소개를 남기고, 수업에서 만날 튜터의 프로필을 확인해 보세요!`,
    `Complete your profile and check out your tutor's profile!`,
    's_trial.you_can_view_list_of_available_tutors_after_entering_self_intro',
    `请抽出时间留下您的自我介绍并确认将开始课程的教师简介!`,
    `請抽出一點時間寫下介紹，並確認將在課程見面的任課教師簡介！`,
    `短い時間で自己紹介を作成し、レッスンで出会うチューターのプロフィールを確認しましょう！`,
    `Hoàn thành thông tin cá nhân và xem hồ sơ gia sư của bạn!`,
  ],
  everybody_conversation: [
    `일상 대화`,
    `Casual Conversations`,
    's_trial.everybody_conversation',
    `日常会话`,
    `日常會話`,
    `日常会話`,
    `Hội thoại hằng ngày`,
  ],
  freetalking: [`자유주제`, `Free Topic`, 's_trial.freetalking', `自由主题`, `自由主題`, `自由テーマ`, `Chủ đề tự do`],
  completed_title: [
    <>
      체험 수업 신청이 {isMobile && <br />}
      완료되었습니다.
    </>,
    <>Your free trial lesson {isMobile && <br />}has been successfully scheduled</>,
    's_trial.completed_title',
    <>
      已完成{isMobile && <br />}
      体验课程申请。
    </>,
    <>
      體驗課程申請{isMobile && <br />}
      完成。
    </>,
    <>
      体験レッスンの予約が {isMobile && <br />}
      完了しました。
    </>,
    <>Buổi học thử miễn phí của bạn {isMobile && <br />}đã được lên lịch thành công</>,
  ],
  completed_description: [
    <>수업 시작 10분 전까지 노트북으로 링글 웹사이트에 로그인 하신 후, 수업을 준비해 주세요.</>,
    <>
      Please log into Ringle's website 10 minutes before the lesson starts. For a better learning experience, please
      enter Ringle lessons on a web browser.
    </>,
    's_trial.completed_description',
    <>请于课程开始前10分钟, 使用笔记本电脑登录Ringle网站, 准备开始您的课程。</>,
    <>請最晚於課程開始10分鐘前使用筆記型電腦登入Ringle網站並準備上課。</>,
    <>レッスン開始10分前までにノートパソコンでRingleウェブサイトにログインした後、レッスンの 準備をしてください。</>,
    <>
      Vui lòng đăng nhập vào website Ringle 10 phút trước buổi học. Để có trải nghiệm học tốt nhất, vui lòng vào lớp
      bằng trình duyệt web.
    </>,
  ],
  check_before_lesson: [
    `수업 전 필수 확인 사항`,
    `Before your lesson`,
    's_trial.check_before_lesson',
    `课程开始前务必进行确认`,
    `課程開始前必須確認事項`,
    `レッスン前の必須確認事項`,
    `Trước buổi học`,
  ],
  need_to_zoom: [
    `화상수업을 위해 Zoom 설치가 필요합니다.`,
    `Ringle lessons are run on Zoom. Please install Zoom.`,
    's_trial.need_to_zoom',
    `视频课程需安装Zoom软件。`,
    `為進行視訊課程，需安裝Zoom。`,
    `オンラインレッスンを行うにはZoomのインストールが必要です。`,
    `Buổi học của Ringle diễn ra trên Zoom. Vui lòng cài đặt Zoom.`,
  ],
  need_to_chrome: [
    `링글 서비스는 크롬 브라우저에서 최적화되어 되어 있습니다.`,
    `Please use Google Chrome or Microsoft Edge to access Ringle. Some features may not be available in other browsers.`,
    's_trial.need_to_chrome',
    `建议使用Chrome浏览器, 获得最佳Ringle课程效果`,
    `建議使用Chrome瀏覽器，以獲得最佳Ringle服務瀏覽體驗。`,
    `RingleサービスはChromeブラウザに最適化されています。`,
    `Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle. Một số tính năng có thể không hiển thị trên các trình duyệt khác.`,
  ],
  not_support_ie: [
    `익스플로러 브라우저는 지원하지 않습니다.`,
    `Browser not supported.`,
    's_trial.not_support_ie',
    `不支持Explorer浏览器。`,
    `不支援IE瀏覽器。`,
    `Explorerブラウザには対応していません。`,
    `Trình duyệt không hỗ trợ.`,
  ],
  laptop: [
    `1:1 영어 화상 수업은 노트북 사용을 권장드립니다.`,
    `For a better learning experience, please enter Ringle lessons on a web browser.`,
    's_trial.laptop',
    `1:1英语视频课程, 建议使用笔记本电脑。`,
    `建議使用筆記型電腦進行1:1英語視訊課程。`,
    `1:1オンライン英会話レッスンはノートパソコンの使用を推奨します。`,
    `Để có trải nghiệm học tốt nhất, vui lòng vào lớp bằng trình duyệt web.`,
  ],
  trial_guide: [
    `체험 수업 가이드 확인 하기`,
    `How it works: Ringle Trial Lessons`,
    's_trial.trial_guide',
    `请确认体验课程指南`,
    `查看體驗課程指南`,
    `体験レッスンガイドを確認する`,
    `Cách hoạt động: Buổi học thử của Ringle`,
  ],
  go_prep: [`예습하러 가기`, `Lesson Prep`, 's_trial.go_prep', `去预习`, `前往預習`, `予習する`, `Chuẩn bị Buổi học`],
  download_app: [
    `링글 모바일앱을 설치해 보세요`,
    `Download Ringle Mobile App`,
    's_trial.download_app',
    `请安装Ringle应用程序`,
    `請安裝Ringle APP`,
    `Ringleモバイルアプリをインストールしましょう。`,
    `Tải ứng dụng Ringle trên điện thoại`,
  ],
  stay_connected: [
    `예습-수업-복습을 언제 어디서나 할 수 있어요.`,
    ``,
    's_trial.stay_connected',
    `预习-课程-复习, 随时随地学习英语。`,
    `可隨時隨地預習-上課-複習。`,
    `予習-レッスン-復習をいつでもどこでもすることができます。`,
    ``,
  ],
  completed_guide_1: [
    `수업 취소 및 시간 및 교재 변경은 수업 시작 24시간 전까지 예정된 수업 페이지에서 가능합니다.`,
    `You may reschedule or cancel the lesson or change lesson materials as late as 24 hours before the lesson starts.`,
    's_trial.completed_guide_1',
    `课程取消或更改时间, 教材时, 最迟可于课程开始前24小时于预约页面进行。`,
    `最晚可於課程開始24小時前於預定的課程頁面取消課程及變更時間與教材。`,
    `レッスンのキャンセルおよび時間・教材の変更は、レッスン開始24時間前までにレッスン予定ページで可能です。`,
    `Bạn có thể dời, huỷ buổi học hoặc đổi tài liệu học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  completed_guide_2: [
    `급한 사정으로 수업 시작 24시간 이내 일정 변경이 필요한 경우, 수업을 취소하고 홈페이지의 [문의]를 통해 연락주세요.`,
    `If you must reschedule less than 24 hours before the lesson, please contact us via chat below.`,
    's_trial.completed_guide_2',
    `因紧急情况需要在课程开始前24小时内变更日期时，请取消课程后与主页在线[咨询]联系。`,
    `於課程開始前24小時以內緊急需要變更日程時，請取消課程並透過首頁的[諮詢]聯絡我們。`,
    `急な事情によりレッスン開始24時間以内の日程変更が必要な場合はレッスンをキャンセルし、ホームページの[お問い合わせ]からご連絡ください。`,
    `Nếu bạn phải dời lịch trong vòng 24 giờ trước khi buổi học bắt đầu, vui lòng chat với chúng tôi.`,
  ],
  no_available_lesson: [
    `선착순으로 금일 수업은 마감되었습니다. 다른 일정을 확인해 보세요!`,
    `There is no available lesson on the selected date`,
    's_trial.no_available_lesson',
    `按照先后顺序, 今日的课程预约已经结束, 请确认其他日期。`,
    `今日的課程已截止，請確認其他日程。`,
    `先着順で本日のレッスンは締め切られました。他の日程をご確認ください！`,
    `Không có buổi học nào trong ngày bạn chọn`,
  ],
  view_all_material: [
    `교재 전체 보기`,
    `View All Materials`,
    's_trial.view_all_material',
    `查看全部教材`,
    `查看全部教材`,
    `すべての教材を見る`,
    `Xem Tất cả Tài liệu`,
  ],
  freetalking_message: [
    <>
      체험수업에서는 <Span danger>자유주제 수업을 신청할 수 없습니다.</Span>
      <br />
      정규수업 시, 내가 원하는 주제로 토론 및 교정 수업을 진행할 수 있습니다.
    </>,
    <>
      <Span danger>Free talking lessons are unavailable</Span> for the trial.
      <br />
      During the regular lesson, you can take correction or discussion-focused lessons with any topic you’re interested
      in.
    </>,
    's_trial.freetalking_message',
    <>
      体验课程<Span danger>无法申请自由主题课程。</Span>
      <br />
      正规授课时, 可选择自身希望的主题内容进行讨论及纠正。
    </>,
    <>
      進行體驗課程時，<Span danger>無法申請自由主題。</Span>
      <br />
      進行正規課程時，能以自己希望的主題進行討論與糾正課程。
    </>,
    <>
      体験レッスンでは <Span danger>自由テーマレッスンを予約することができません。</Span>
      <br />
      レギュラーレッスンの際、あなたの好きなテーマでディスカッションおよび校正レッスンを行うことができます。
    </>,
    <>
      <Span danger>Không có buổi học nói tự do</Span> khi học thử.
      <br />
      Trong buổi học tiêu chuẩn, bạn có thể chọn tập trung vào sửa lỗi hay tập trung thảo luận với bất kì chủ đề nào bạn
      hứng thú.
    </>,
  ],
  material_guide: [
    <>
      체험수업 시,&nbsp;
      <span className="font-medium">각 주제마다 가장 인기 있는 교재 10개가 제공됩니다.</span>
      <br />
      정규수업 시, 링글에서 제공하는 1,000개 이상 교재를 자유롭게 무료로 이용 가능합니다.
      <br />
    </>,
    <>
      Only the 10 most popular Ringle materials in each section are available for the trial lesson. During the regular
      lesson, you can select more than 1,000 materials for free.
    </>,
    's_trial.material_guide',
    <>
      体验课程时&nbsp;
      <span className="font-medium">每个主题将提供10个最受欢迎的教材。</span>
      <br />
      正规课程可免费自由使用Ringle所提供的1,000个以上的教材。
      <br />
    </>,
    <>
      進行體驗課程時，&nbsp;
      <span className="font-medium">將提供各主題最受歡迎的10個教材。</span>
      <br />
      進行正規課程時，可自由利用Ringle提供的1,000個以上免費教材。
      <br />
    </>,
    <>
      体験レッスン時&nbsp;
      <span className="font-medium">テーマごとに最も人気のある教材10個が提供されます。</span>
      <br />
      レギュラーレッスン時、Ringleが提供する1,000個以上の教材を自由に無料で利用 可能です。
      <br />
    </>,
    <>
      Chỉ 10 tài liệu phổ biến nhất của Ringle của mỗi phần có sẵn cho buổi học thử. Trong buổi học thông thường, bạn có
      thể chọn hơn 1,000 tài liệu miễn phí.
    </>,
  ],
  buy_without_trial: [
    `무료 체험 없이 바로 구매`,
    `Buy without trial`,
    's_trial.buy_without_trial',
    `不进行免费体验立即购买`,
    `未先免費體驗即購買`,
    `無料体験なしで購入`,
    `Mua mà không cần học thử`,
  ],
  trial_can_be_limited: [
    `무료체험: 정규수업보다 수업 시간, 교재, 튜터 선택이 자유롭지 않습니다.`,
    `Free trial offers limited options for time slots, materials, and tutors.`,
    's_trial.trial_can_be_limited',
    `免费体验课程于时间和教材选择上存在一定局限。`,
    `免費體驗課程的時間與教材選擇上可能有限。`,
    `無料体験レッスンは時間と教材の選択が制限されることがあります。`,
    `Buổi học thử sẽ giới hạn về thời gian & tài liệu để lựa chọn.`,
  ],
  purchase_message_new: [
    <>무료체험 없이 구매 시, 최대 3회 수업권 + 1만원 추가 할인 혜택을 드려요! (첫 수업 불만족 시, 100% 환불 보장)</>,
    <>
      Skip trial and get up to 3 free credits & $8 off (full refund available for unsatisfied customers after first
      lesson)
    </>,
    's_trial.purchase_message_new',
    <>
      <b>不进行体验课程直接购买时, </b>可追加提供$8.62的优惠及最多4次的额外 课程。(首次课程不满意时, 承诺100%退款)
    </>,
    <>
      <b>未先體驗即購買時，</b>提供$8.62額外折扣及最多4次 額外課程。（首次課程不滿意時，可全額退款）
    </>,
    <>
      <b>体験レッスンなしでご購入時、</b> $8.62追加割引および最大3回追加
      レッスンが提供されます。(初回レッスンにご満足いただけない場合、全額返金保証)
    </>,
    <>
      <b>Mua mà không cần học thử</b> giảm $8.26 + tới 4 buổi học miễn phí (Hoàn tiền 100% ).
    </>,
  ],
  promotion_message: [
    `체험수업 일시 부족으로 추가 혜택을 제공합니다.`,
    `Offering extra benefits due to limited free trial`,
    's_trial.promotion_message',
    `体验课程暂时不足, 将提供额外优惠。`,
    `由於體驗課程暫時不足，故提供額外優惠。`,
    `体験レッスンの日時不足により、追加特典を提供します。`,
    `Thêm nhiều ưu đãi vì buổi học thử miễn phí có hạn`,
  ],
}
export const s_products = {
  dollarDescription: [
    <>
      <ul>
        <li>현재 환율에 의거하여 달러($)로 결제됩니다.</li>
        <li>
          PayPal을 로그인하지 않아도 사용가능합니다(PayPal Guest Checkout 또는 Pay with Debit or Credit Card로
          결제하시면 됩니다)
        </li>
        <li>영수증은 등록하신 이메일로 자동 발송됩니다. (링글 사이트 내에서 확인이 불가합니다.)</li>
        <li>
          청구주소가 한국이면 이용이 <Span danger>불가능</Span>합니다.
        </li>
        <li>일부 국내법인카드는 Paypal을 지원하지 않습니다. 이 경우, 국내 &gt; 비인증 결제 진행부탁드립니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>
          You can complete your purchase without logging into PayPal. Select PayPal Guest Checkout or Pay with Debit or
          Credit Card.
        </li>
        <li>Your receipt will be emailed to you. You cannot view it on Ringle website.</li>
        <li>
          If your billing address is in South Korea, you <Span danger>cannot</Span> use PayPal.
        </li>
        <li>
          Some South Korean corporate credit cards may not be compatible with PayPal. Please use personal credit cards
          or Non-certificate pay
        </li>
      </ul>
    </>,
    's_products.dollarDescription',
    <>
      <ul>
        <li>按照当前汇率, 可使用美金($)进行付款。</li>
        <li>无需登录PayPal也可使用。(可用PayPal Guest Checkout或 Pay with Debit or Credit Card进行付款)</li>
        <li>收据将自动发送至登录的邮箱。(无法于Ringle网站进行确认。)</li>
        <li>
          申请地址是韩国时, 将无法<Span danger>使用</Span>。
        </li>
        <li>部分韩国法人卡不支持Paypal。这种情况时请使用韩国&gt; 非验证付款方式。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>將依照目前匯率以美元（$）付款。</li>
        <li>不必登入PayPal即可付款。（只需使用PayPal Guest Checkout或Pay with Debit or Credit Card付款即可。）</li>
        <li>收據將自動發送至您登錄的電子信箱。（無法於Ringle網站進行確認。）</li>
        <li>
          請款地址為韓國的情況，將<Span danger>無法</Span>使用。
        </li>
        <li>部分韓國國內公司卡不支援PayPal。這種情況請使用韓國國內&gt;非驗證付款。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>現在の為替レートにもとづきドル($)で決済されます。</li>
        <li>
          PayPalでログインしなくても使用可能です。(PayPal Guest Checkoutまたは Pay with Debit or Credit
          Cardで決済してください)
        </li>
        <li>領収証は登録されたメールアドレスに自動的に送信されます。(Ringleサイト内では確認 できません)</li>
        <li>
          請求住所が日本なら利用が <Span danger>不可能</Span>です。
        </li>
        <li>一部の国内法人カードはPaypalに対応していません。その場合、国内 &gt; 非認証決済を行ってください。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>
          Bạn có thể hoàn thành giao dịch mà không cần phải đăng nhập vào PayPal. Chọn PayPal Guest Checkout hoặc trả
          bằng thẻ ghi nợ hoặc thẻ tín dụng.
        </li>
        <li>Biên nhận sẽ được gửi qua email của bạn. Bạn không thể kiểm tra biên nhận trên website Ringle.</li>
        <li>
          Nếu địa chỉ thanh toán của bạn ở Hàn Quốc, bạn <Span danger>không thể</Span> sử dụng PayPal.
        </li>
        <li>
          Một số thẻ tín dụng của doanh nghiệp Hàn Quốc không tương thích với PayPal. Vui lòng sử dụng thẻ tín dụng cá
          nhân.
        </li>
      </ul>
    </>,
  ],
  dollarConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>${price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected credits will be purchased with&nbsp;
        <Span primary>${price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.dollarConfirm',
    <>
      <b>
        该商品使用<Span primary>${price}</Span>付款。
        <br />
        确定要进行吗?
      </b>
    </>,
    <>
      <b>
        該商品將以<Span primary>${price}</Span>付款。
        <br />
        確定要進行嗎？
      </b>
    </>,
    <>
      <b>
        この商品は <Span primary>${price}</Span>で決済されます。
        <br />
        実行しますか？
      </b>
    </>,
    <>
      <b>
        Thẻ tín dụng được chọn sẽ thanh toán với&nbsp;
        <Span primary>${price}</Span>. Bạn có muốn thực hiện?
      </b>
    </>,
  ],
  no: [`아니오`, `No`, 's_products.no', `不`, `否`, `いいえ`, `Không`],
  yes: [`네`, `Yes`, 's_products.yes', `是`, `是`, `はい`, `Có`],
  wonDescription: [
    <>
      <ul>
        <li>원화(￦)로 결제됩니다.</li>
        <li>결제 내역에는 링글의 결제 대행사인 ‘JTNET’이 상점명으로 표시됩니다.</li>
        <li>링글 사이트 내에서 영수증을 확인할 수 있습니다.</li>
        <li>소량의 환전 수수료가 추가될 수 있습니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>The purchase will be made in Korean Won (KRW).</li>
        <li>The receipt will be issued under 'JTNET,' Ringle's payment agency.</li>
        <li>You can view your receipt on the Ringle website.</li>
        <li>A small amount of currency exchange fee may be charged.</li>
      </ul>
    </>,
    's_products.wonDescription',
    <>
      <ul>
        <li>使用韩元(￦)进行付款。</li>
        <li>付款内容中Ringle所显示的代理公司为‘JTNET’。</li>
        <li>可在Ringle网站内确认收据。</li>
        <li>可能会产生附加的小额换汇手续费。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>將以韓元（￦）付款。</li>
        <li>付款明細的商店名稱將標示Ringle的代收業者「JTNET」。</li>
        <li>可在Ringle網站確認收據。</li>
        <li>可能會產生小額的換匯手續費。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>$（ドル）で決済されます。</li>
        <li>決済履歴はRingleの決済代行会社の「JTNET」がショップ名で表示されます。</li>
        <li>Ringleサイト内で領収証を確認することができます。</li>
        <li>少額の両替手数料が追加されることがあります。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>Giao dịch sẽ được thực hiện bằng tiền Won (KRW).</li>
        <li>Biên nhận sẽ được xuất dưới tên 'JTNET',' công ty thanh toán của Ringle.</li>
        <li> Bạn có thể xem biên nhận trên website Ringle.</li>
        <li>Một phần nhỏ phí chuyển đổi ngoại tệ có thể được tính vào.</li>
      </ul>
    </>,
  ],
  wonConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>￦{price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected package will be purchased with&nbsp;
        <Span primary>￦{price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.wonConfirm',
    <>
      <b>
        该商品使用<Span primary>￦{price}</Span>付款。
        <br />
        确定要进行吗?
      </b>
    </>,
    <>
      <b>
        該商品將以<Span primary>￦{price}</Span>付款。
        <br />
        確定要進行嗎？
      </b>
    </>,
    <>
      <b>
        この商品は <Span primary>￦{price}</Span>で決済されます。
        <br />
        実行しますか？
      </b>
    </>,
    <>
      <b>
        Gói học bạn chọn sẽ thanh toán với&nbsp;
        <Span primary>￦{price}</Span>. Bạn có muốn thực hiện?
      </b>
    </>,
  ],
  lumpPayment: [`일시불`, `Pay Upfront`, 's_products.lumpPayment', `一次付清`, `一次付清`, `一括払い`, `Trả trước`],
  month: (month) => [
    <>{month}개월</>,
    <>{month} months</>,
    's_products.month',
    <>{month}个月</>,
    <>{month}個月</>,
    <>{month}か月</>,
    <>{month} tháng</>,
  ],
  totalPrice: (price) => [
    <>총 결제 금액: {price}원</>,
    <>Your Total: {price} KRW</>,
    's_products.totalPrice',
    <>总付款金额: ${price}</>,
    <>總付款金額：${price}</>,
    <>合計決済金額: {price}</>,
    <>Tổng cộng: {price} KRW</>,
  ],
  firstCard: [
    `첫번째 카드 결제 금액`,
    `Charge on the first card`,
    's_products.firstCard',
    `第一张银行卡付款金额`,
    `第一張信用卡付款金額`,
    `1枚目のカード決済金額`,
    `Tính phí thẻ đầu tiên`,
  ],
  secondCard: [
    `두번째 카드 결제 금액`,
    `Charge on the second card`,
    's_products.secondCard',
    `第二张银行卡付款金额`,
    `第二張信用卡付款金額`,
    `2枚目のカード決済金額`,
    `Tính phí thẻ thứ hai`,
  ],
  person: [
    `일시불 / 개인`,
    `Pay Now`,
    's_products.person',
    `一次付清 / 个人`,
    `一次付清／個人`,
    `一括払い / 個人`,
    `Thanh toán ngay`,
  ],
  secondCardPrice: (price) => [
    <>두번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your second card.</>,
    's_products.secondCardPrice',
    <>第二张银行卡付款金额为${price}。</>,
    <>第二張信用卡付款金額為${price}。</>,
    <>2枚目のカード決済金額は${price}です。</>,
    <>{price} KRW sẽ tính vào thẻ thứ hai của bạn.</>,
  ],
  firstCardPrice: (price) => [
    <>첫번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your first card.</>,
    's_products.firstCardPrice',
    <>第一张银行卡付款金额为${price}。</>,
    <>第一張信用卡付款金額為${price}。</>,
    <>1枚目のカード決済金額は${price}です。</>,
    <>{price} KRW sẽ tính vào thể đầu tiên của bạn.</>,
  ],
  totalAmount: [`결제 금액`, `Your total`, 's_products.totalAmount', `付款金额`, `付款金額`, `決済金額`, `Tổng cộng`],
  realAmount: (n) => [<>{n}원</>, <>${n}</>, 's_products.realAmount', <>${n}</>, <>${n}</>, <>${n}</>, <>${n}</>],
  cardInstallmentInfo: [
    `카드 할부 안내`,
    ``,
    's_products.cardInstallmentInfo',
    `银行卡分期付款说明`,
    `信用卡分期付款說明`,
    `カード分割払いのご案内`,
    ``,
  ],
  installment: [
    `할부`,
    `Pay in installations`,
    's_products.installment',
    `分期付款`,
    `分期付款`,
    `分割払い`,
    `Trả góp`,
  ],
  inputCard: [
    `카드번호 입력`,
    `Enter card number`,
    's_products.inputCard',
    `输入银行卡号码`,
    `輸入卡片號碼`,
    `カード番号入力`,
    `Nhập số thẻ`,
  ],
  personal: [`개인`, `Personal`, 's_products.personal', `个人`, `個人`, `個人`, `Cá nhân`],
  corporation: [`법인`, `Corporate`, 's_products.corporation', `法人`, `法人`, `法人`, `Công ty`],
  expiryDate: [
    `유효기간(mm/YY)`,
    `Expiration date (mm/YY)`,
    's_products.expiryDate',
    `有效期(mm/YY)`,
    `有效期限（mm／YY）`,
    `有効期間(mm/YY)`,
    `Ngày hết hạn (mm/YY)`,
  ],
  passwordTwoDigit: [
    `비밀번호 앞 2자리`,
    `First 2 digits of the PIN`,
    's_products.passwordTwoDigit',
    `密码前两位`,
    `密碼前2碼`,
    `暗証番号の前2桁`,
    `2 số đầu của mã PIN`,
  ],
  birthPersonal: [
    `생년월일(6자리)`,
    `Birth Date (6 digits, YYmmdd)`,
    's_products.birthPersonal',
    `出生日期(6位数字)`,
    `出生年月日（6位數）`,
    `生年月日(6桁)`,
    `Ngày sinh (6 chữ số, YYmmdd)`,
  ],
  birthCompany: [
    `사업자등록번호(10자리)`,
    `Business registration number (10 digits)`,
    's_products.birthCompany',
    `营业执照号码(10位数字)`,
    `營利事業統一編號（10位數）`,
    `法人番号(13桁)`,
    `Mã đăng ký kinh doanh (10 chữ số)`,
  ],
  limitPromotionPoint: [
    `해당 수업권은 첫 구매 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $8.62 points can be used for purchasing this lesson credit.`,
    's_products.limitPromotionPoint',
    `该课程券已享受首次购买优惠, 仅可使用$8.62积分。`,
    `該課程券已套用首次購買優惠，僅可使用$8.62P。`,
    `このレッスンチケットは初回購入割引が適用されているため、$8.62ポイントのみ使用可能です。`,
    `Chỉ được sử dụng tối đa $8.62 điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedPoint: [
    `해당 수업권은 최대 할인이 적용되어 추가 포인트 사용은 불가합니다.`,
    `You cannot use points for purchasing this lesson credit.`,
    's_products.notAllowedPoint',
    `该课程券已使用了最大折扣, 不能再额外使用积分。`,
    `該課程券已套用最高折扣，無法額外使用點數。`,
    `このレッスンチケットは最大割引が適用されているため、追加ポイントを使用することはできません。`,
    `Bạn không thể sử dụng điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedTimelimitPoint: [
    `해당 수업권은 시간한정 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $48.62 points can be used for purchasing this lesson credit.`,
    's_products.notAllowedTimelimitPoint',
    `该课程券已享受时间限定优惠, 仅可使用$8.62积分。`,
    `該課程券已套用時間限定優惠，僅可使用$8.62P。`,
    `このレッスンチケットは時間限定割引が適用されているため、$8.62ポイントのみ使用可能です。`,
    `Chỉ được sử dụng tối đa $48.62 điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedGivingBack: [
    `Giving Back 포인트는 18회 이상 수업권 구매 시에만 사용 가능합니다.`,
    <>&apos;Giving Back Promotion&apos; point can be used only when purchasing more than 18 lesson credits.</>,
    's_products.notAllowedGivingBack',
    `Giving Back积分仅可在购买18次以上课程时使用`,
    `Giving Back點數僅可於購買18次以上課程券時使用。`,
    `Giving Backポイントは18回以上のレッスンチケット購入時にのみ使用可能です。`,
    <>&apos;Ưu đãi tri ân&apos; điểm chỉ có thể sử dụng khi thanh toán từ 18 buổi học trở lên.</>,
  ],
  payment: [`결제하기`, `Purchase`, 's_products.payment', `付款`, `付款`, `決済する`, `Thanh toán`],
  cardInfo: [`카드사 무이자 할부 안내`, `0% monthly payments`, 's_products.cardInfo', ``, ``, ``, ``],
  cardInfoContent: [
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    's_products.cardInfoContent',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  firstTimePurchase: [
    `첫구매가`,
    `First-time Purchase`,
    's_products.firstTimePurchase',
    `首次购买价格`,
    `首次購買價`,
    `初回購入価格`,
    `Thanh toán lần đầu`,
  ],
  purchase_must_be: [
    `('결제사 정책에 의해 1,000원 이하 결제는 불가합니다')`,
    `(Purchases must be greater than $1.)`,
    's_products.purchase_must_be',
    `('根据支付公司的政策要求，1000元以下无法结算')`,
    `（「依據支付公司的政策，低於$1無法付款。」）`,
    `「決済会社の規約により1,000ウォン以下の決済はできません」`,
    `(Thanh toán phải lớn hơn $1.)`,
  ],
  corporate_donot_allow_installments: [
    `법인카드 사용시, 할부 결제가 불가능합니다.`,
    `Corporate cards do not allow installments.`,
    's_products.corporate_donot_allow_installments',
    `公司信用卡无法使用分期付款.`,
    `使用公司卡時無法分期付款。`,
    `法人カードをご利用の場合、分割払いはできません。`,
    `Thẻ công ty sẽ không trả góp được.`,
  ],
  how_to_use: {
    title: [
      `수업권 사용 안내`,
      `How to Use Credits`,
      's_products.how_to_use.title',
      `课程券使用介绍`,
      `課程券使用說明`,
      `レッスンチケットの使用方法`,
      `Chính sách thẻ học`,
    ],
    list1: [
      `수업권은 수강기간 내 자유롭게 이용할 수 있습니다. (일 별 수업 예약 횟수에 제한이 없습니다.)`,
      `You can book lessons during business hours, on any date before the credits expire. (You can freely book the lesson at any date and time.)`,
      's_products.how_to_use.list1',
      `课程券按购买次数, 可于使用期限内自由使用。`,
      `可於使用期限內自由使用課程券。（每日課程預約次數不限。）`,
      `購入したレッスンチケットは有効期限内に自由に使うことができます。`,
      `Bạn có thể sử dụng thẻ học trước khi hết hạn.`,
    ],
    list2: [
      `수업 시간은 연중무휴로 한국 시간 기준 오전 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
      `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
      's_products.how_to_use.list2',
      `可在Ringle的课程时间内自由使用正规课程券。凌晨5点～下午2点（韩国标准时间，以开始时间为准）， 晚上7点～凌晨3点（韩国标准时间，以开始时间为准）`,
      `課程時間為全年無休，可於韓國時間凌晨5點～下午2點、晚上7點～凌晨3點上課。`,
      `レギュラーレッスンチケットは、Ringleのレッスン時間内で自由に利用可能です。 午前5時～午後2時 (開始時間基準)、 午後7時～午前3時 (開始時間基準)`,
      `Bạn có thể sử dụng Thẻ học tiêu chuẩn bất cứ khi nào trong giờ hành chính: 05:00-14:00 (Giờ Hàn Quốc, tính từ giờ bắt đầu học), 19:00-03:00 (Giờ Hàn Quốc, tính từ giờ bắt đầu học)`,
    ],
    list3: [
      `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
      `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
      's_products.how_to_use.list3',
      `请注意课程券的使用期限不是从购买日开始扣除，而是从完成首次课程时开始扣除。`,
      `請註意課程券的使用期限不是從購買日開始扣除，而是從完成首次課程時開始扣除。`,
      `受講期間が始まる時点は購入日ではなく、初回レッスンを完了した時点からであることをご了承ください。`,
      `Ngày hết hạn được tính bắt đầu từ ngày bạn hoàn thành buổi học đầu tiên trong gói học đã thanh toán.`,
    ],
    list4: [
      `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
      `Unused credits' expiration dates will start counting down a year after the purchase date.`,
      's_products.how_to_use.list4',
      `购买后一年期间，于完成首次课程时扣除使用期限。`,
      `購買後一年期間，於完成首次課程時扣除使用期限。`,
      `レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。`,
      `Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.`,
    ],
    list5: [
      `포인트 사용 혹은 소액 결제를 통해 수강기간을 연장할 수 있습니다.`,
      `You can extend credit expiration dates with your points or by paying a small fee.`,
      's_products.how_to_use.list5',
      `使用期限可通过使用积分/小额付款的方式进行延长。`,
      `使用期限可使用點數／小額付款延長。`,
      `有効期限はポイント又は少額手数料で延長することができます。`,
      `Bạn có thể gia hạn bằng cách sử dụng điểm hoặc trả một khoản phí nhỏ.`,
    ],
    list6: [
      `실결제가 18만원 이상 수업권을 구매하신 후 링글 팀으로 연락주시면, 보유하신 수업권의 남은 기간에 최초 수강 기간을 더해 연장해드립니다. (예: 90일 수업권 구매 후, 수강기간 10일 남은 시점에 추가 수업권 구매 시, 해당 수업권에 90일 수강기간을 추가 연장 조치함)`,
      `If you contact our Help Center after purchasing a package worth $155.17 or more, we will extend your old credits by the initial validity period (e.g. 90-day credits expiring in 10 days will be extended by 90 days after the purchase of 180,000KRW worth of new credits).`,
      's_products.how_to_use.list6',
      `购买实际付款金额达₩180,000以上的套餐后告诉Ringle团队，将为您回复之前付款购买的套餐原本的使用期限1次。`,
      `額外購買₩180,000以上的課程券後，透過1:1諮詢告知Ringle團隊，可將先前課程券的使用期限復原至最初狀態1次。`,
      `18万ウォン以上レッスンチケットを購入された後、Ringleチームにご連絡いただくと、保有されたレッスンチケットの残りの期間に最初の受講期間を加えて延長いたします。（例：90日レッスンチケットをご購入後、受講期間残り10日の時点で追加レッスンチケットをご購入した場合、該当のレッスンチケットに90日受講期間を追加で延長させていただきます）`,
      `Liên hệ với chúng tôi để thực hiện thanh toán khoác trên ₩180,000 để gia hạn cho thẻ học cũ có ngày hết hạn cùng với thẻ học mới.`,
    ],
    list7: [
      `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      's_products.how_to_use.list7',
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
    ],
    list8: [
      `수업권 구매 즉시 수강기간 차감`,
      `Expiration begins immediately upon purchase`,
      's_products.how_to_use.list8',
      `购买后立即開始扣除课程券使用時間`,
      `購買後立即開始扣除課程券使用時間`,
      `購入直後、受講期間が差し引かれます。`,
      `Thời gian khóa học được khấu trừ ngay khi mua`,
    ],
    list9: [
      `매주 1회 수업 필수 (미 수강 시 수업권 자동 차감)`,
      `Must take at least one lesson per week (1 credit automatically deducted each week)`,
      's_products.how_to_use.list9',
      `每周必须上课1次（未上课将自动扣除课程券）`,
      `每周必須上課1次（未上課將自動扣除課程券）`,
      `週に1回の授業は必須（受講しない場合は自動にレッスンチケットが差し引かれます）`,
      `Lớp học bắt buộc mỗi tuần một lần`,
    ],
  },
  refund_policy: {
    title: [
      `수업권 환불 정책`,
      `Refund Policy`,
      's_products.refund_policy.title',
      `课程券退款政策`,
      `課程券退款政策`,
      `レッスンチケット返金ポリシー`,
      `Chính sách hoàn tiền`,
    ],
    list1: [
      `구매 후 1년 이내 사용하지 않은 수업권은 100% 환불 가능합니다.`,
      `Credits that have not been used within a year after purchase are eligible for a full refund.`,
      's_products.refund_policy.list1',
      `购买后, 未使用的课程券将享受全额退款。`,
      `購買後，可全額退還未使用的課程券。`,
      `未使用のレッスンチケットは全額返金可能です。`,
      `Các Thẻ học chưa sử dụng có thể được hoàn lại.`,
    ],
    list2: [
      `첫 수업 불만족 시, 전액 환불 가능합니다. (첫 수업에 체험 수업 포함)`,
      `We offer a 100% money-back guarantee for your first lesson (including trial lessons).`,
      's_products.refund_policy.list2',
      `首次课程结束后,  不满意可全额退款。`,
      `首次課程不滿意時，可全額退款。`,
      `体験レッスン含む初回レッスンをご利用いただき、ご満足いただけなかった場合は全額返金いたします。`,
      `Chúng tôi đảm bảo hoàn tiền 100% cho buổi học đầu tiên.`,
    ],
    list3: [
      `수강기간이 절반 이하 남거나, 수업 횟수가 절반 이하 남은 수업권은 환불이 불가능합니다.`,
      `No refund is offered for lesson credits starting halfway through the expiration dates or for packages that are half-used or more.`,
      's_products.refund_policy.list3',
      `剩余使用期限不足一半或课程次数剩余不足一半时，无法退费。`,
      `使用期限少於一半或課程次數少於一半的課程券恕不退款。`,
      `有効期限が半分以下のレッスンチケットおよび残りの回数が半分以下のレッスンパックは払戻できません。`,
      `Hoàn tiền không áp dụng cho thẻ học có ngày hết hạn được một nửa, hoặc thẻ học đã được dùng hơn một nửa.`,
    ],
    list4: [
      `수강 이력이 있는 수업권은 이용약관(13조, 15조)에 의거하여 처리합니다.`,
      `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
      's_products.refund_policy.list4',
      `已有课程使用记录的课程券, 退款时将按照使用条款中的(13条, 15条)进行处理!`,
      `曾使用過的課程券將依據使用條款（第13條、第15條）進行退款。`,
      `受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づいて処理されます。`,
      `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
    ],
  },
  makeup: (number) => [
    <>당일 수업권 {number}회 증정</>,
    <>{number} extra last-minute credits</>,
    's_products.makeup',
    <>{number}次补充课程</>,
    <>{number}次補充課程</>,
    <>{number}回分の当日レッスン</>,
    <>{number} Buổi học bù</>,
  ],
}
export const s_lesson_style = {
  record: {
    title: [
      `수업 녹음`,
      `Lesson Audio-Recording`,
      's_lesson_style.record.title',
      `课程录音`,
      `課程錄音`,
      `レッスン録音`,
      `Bản ghi âm buổi học`,
    ],
    note: [
      `*수업 녹음 MP3파일은 수업 종료 후 파일 제작이 완료되면 "복습" 메뉴에서 확인하실 수 있습니다.`,
      `Your audio file (.MP3) will be available under "Review" after your lesson.`,
      's_lesson_style.record.note',
      `*课程录音MP3文件, 可于课程结束后文件制作完成时于"复习”菜单中进行确认。`,
      `*課程錄音MP3檔可於課程結束後檔案製作完成時，於「複習」選單確認。`,
      `*レッスンの録音MP3ファイルは、レッスン終了後に「復習」メニューから確認することができます。`,
      `File âm thanh của bạn (.MP3) sẽ có ở phần "Xem lại" sau buổi học của bạn.`,
    ],
    noteTrial: [
      `수업 후, 복습을 위한 녹음파일과 Beta 서비스로 운영하는 스크립트 자료 제공를 위해 수업 내용은 녹음됩니다.`,
      `Trial Lessons are recorded to provide AI Analysis and audio playback & transcript.`,
      's_lesson_style.record.noteTrial',
      `为在课程结束后提供复习所需要的录音文件及运用Beta服务的脚本资料, 我们将对您的课程进行录音。`,
      `為了於課程結束後提供複習所需的錄音檔及Beta服務的腳本資料，我們將對課程進行錄音。`,
      `レッスン後に復習のための録音ファイルとBetaサービスで運営するスクリプト資料の提供のため、レッスン内容は録音されます。`,
      `Buổi học thử được thu âm để cung cấp phân tích từ AI, file âm thanh & bản ghi.`,
    ],
    yes: [`수업 녹음하기`, `Record`, 's_lesson_style.record.yes', `进行录音`, `進行錄音`, `録音する`, `Thu âm`],
    no: [
      `수업 녹음하지 않기`,
      `Do Not Record`,
      's_lesson_style.record.no',
      `不进行录音`,
      `不進行錄音`,
      `録音しない`,
      `Không thu âm`,
    ],
  },
  intro: {
    title: [
      `자기 소개`,
      `Tutor & Student Introductions`,
      's_lesson_style.intro.title',
      `自我介绍`,
      `自我介紹`,
      `自己紹介`,
      `Gia sư & Học viên giới thiệu`,
    ],
    placeholder: [
      `소개 없이 바로 수업할 경우 미리 튜터가 알 수 있도록 자신을 소개하는 글을 적어주세요.`,
      `If you'd like to start the lesson without introductions, please write a brief intro on yourself here.
E.g. My name is Kildong Hong and I am a business developer at Ringle, a startup providing 1:1 online English education services.`,
      's_lesson_style.intro.placeholder',
      `在无任何介绍的情况下直接开始课程, 请提前写下您的简介, 帮助教师对您建立初步了解吧。`,
      `未先自我介紹便開始上課的情況，請先寫下介紹自己的短文，以利任課教師先認識您。`,
      `自己紹介なしですぐにレッスンを受ける場合は、チューターがわかるように事前に自己紹介文を作成してください。`,
      `Nếu bạn muốn bắt đầu buổi học mà không cần giới thiệu, vui lòng viết phần giới thiệu ngắn về bạn tại đây.
Ví dụ: My name is Ahn and I am a business developer at Ringle, a startup providing 1:1 online English education services.`,
    ],
    skip: [
      `소개 없이 바로 시작`,
      `Start lesson without introductions`,
      's_lesson_style.intro.skip',
      `无自我介绍直接开始课程`,
      `未先自我介紹便開始`,
      `自己紹介なしですぐに開始`,
      `Bắt đầu buổi học bỏ qua phần giới thiệu`,
    ],
    do: [
      `서로 소개 3분 후 시작`,
      `Spend first 3 minutes exchanging introductions`,
      's_lesson_style.intro.do',
      `彼此介绍3分钟后开始课程`,
      `各自我介紹3分鐘後開始`,
      `3分間お互いに自己紹介してから開始`,
      `Dành 3 phút đầu để giới thiệu`,
    ],
  },
  lesson_mode: {
    title: [
      `수업 모드`,
      `Lesson Mode`,
      's_lesson_style.lesson_mode.title',
      `课程模式`,
      `課程模式`,
      `レッスンモード`,
      `Kiểu bài học`,
    ],
    note: [
      <>
        체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업 모드를 교정 중심과
        토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.
      </>,
      <>
        All trial lessons are discussion-focused.
        <br />
        Paid lessons can either be discussion-focused or correction focused.
      </>,
      's_lesson_style.lesson_mode.note',
      <>
        进行体验课程时，在针对各问题讨论后，以纠正方式进行。上课前，可于课程模式选择以纠正为主或以讨论为主后，选择希望的上课模式。
      </>,
      <>
        進行體驗課程時，在針對各問題討論後，以糾正方式進行。上課前，可於課程模式選擇以糾正為主或以討論為主後，選擇希望的上課模式。
      </>,
      <>
        体験レッスンは各質問の会話が終わった後に校正してもらう方式で行われます。レギュラーレッスンでは、レッスンモードを校正中心とディスカッション中心から選択して細かいレッスン方式を選択することができます。
      </>,
      <>
        Tất cả buổi học thử sẽ tập trung thảo luận.
        <br />
        Buổi học trả phí có thể tùy chọn tập trung thảo luận hoặc tập trung sửa lỗi.
      </>,
    ],
    note_short: [
      `15분 대화 - 5분 피드백`,
      `15m conversation - 5m feedback`,
      's_lesson_style.lesson_mode.note_short',
      `15分钟对话 - 5分钟反馈`,
      `15分鐘對話 - 5分鐘意見回饋`,
      `15分会話 - 5分フィードバック`,
      `15 phút hội thoại - 5 phút feedback`,
    ],
    correction: [
      `교정 중심`,
      `Paraphrasing-Focused`,
      's_lesson_style.lesson_mode.correction',
      `以纠正为主`,
      `以糾正為主`,
      `校正中心`,
      `Tập trung sửa lỗi`,
    ],
    discussion: [
      `토론 중심`,
      `Discussion-Focused`,
      's_lesson_style.lesson_mode.discussion',
      `以讨论为主`,
      `以討論為主`,
      `ディスカッション中心`,
      `Tập trung thảo luận`,
    ],
  },
  mode_detail: {
    correction: {
      instant: [
        `상시교정(틀린 영어 표현을 할 때마다 교정 받음)`,
        `Rephrase my English as soon as possible`,
        's_lesson_style.mode_detail.correction.instant',
        `随时纠正(每当出现英语错误表达时均予以纠正)`,
        `隨時糾正（每當出現錯誤的英語用法時進行糾正）`,
        `常時校正(間違った英語表現をするたびに校正してもらう)`,
        `Sửa ngay khi tôi mắc lỗi`,
      ],
      instant_short: [
        `상시교정`,
        `Instant Paraphrasing`,
        's_lesson_style.mode_detail.correction.instant_short',
        `随时纠正`,
        `隨時糾正`,
        `常時校正`,
        `Sửa lỗi ngay`,
      ],
      intermittent: [
        `대화 교정모드(각 질문에 대한 대화가 끝난 후 교정 받음)`,
        `Rephrase my English after each of my answers`,
        's_lesson_style.mode_detail.correction.intermittent',
        `对话纠正模式(各问题对话结束后进行纠正)`,
        `對話糾正模式（針對各問題討論後進行糾正）`,
        `会話訂正モード(各質問の会話が終わった後に訂正してもらう)`,
        `Sửa cho tôi sau khi thảo luận câu hỏi`,
      ],
      intermittent_short: [
        `대화 교정모드`,
        `Intermittent Paraphrasing`,
        's_lesson_style.mode_detail.correction.intermittent_short',
        `对话纠正模式`,
        `對話糾正模式`,
        `会話訂正モード`,
        `Sửa lỗi ngắt quãng`,
      ],
    },
    discussion: {
      mode55: [
        `5:5 토론(튜터와 내가 절반씩 이야기를 주고 받음)`,
        `I want my tutor to speak as much as I do (5:5)`,
        's_lesson_style.mode_detail.discussion.mode55',
        `5:5讨论(教师和本人听说各占一半)`,
        `5:5討論（任課教師與我各發言一半）`,
        `5:5 ディスカッション(チューターと受講生が半分ずつ話す)`,
        `Tôi muốn gia sư nói nhiều như tôi (5:5)`,
      ],
      mode55_short: [
        `5:5 토론`,
        `5:5 Discussion`,
        's_lesson_style.mode_detail.discussion.mode55_short',
        `5:5讨论`,
        `5:5討論`,
        `5:5 ディスカッション`,
        `Thảo luận 5:5`,
      ],
      mode82: [
        `8:2 토론(튜터는 듣고 내가 주로 이야기함)`,
        `I want to speak more than than my tutor (8:2)`,
        's_lesson_style.mode_detail.discussion.mode82',
        `8:2讨论(教师以听为主, 侧重于学员会话)`,
        `8:2討論（任課教師聆聽，主要由我發言）`,
        `8:2 ディスカッション(チューターは聴いて受講生が主に話す)`,
        `Tôi muốn nói nhiều hơn gia sư (8:2)`,
      ],
      mode82_short: [
        `8:2 토론`,
        `8:2 Discussion`,
        's_lesson_style.mode_detail.discussion.mode82_short',
        `8:2讨论`,
        `8:2討論`,
        `8:2 ディスカッション`,
        `Thảo luận 8:2`,
      ],
    },
  },
  intensive_focus: {
    title: [
      `집중 교정 영역 (다중 선택 가능)`,
      `Focus Areas (Multi-selectable)`,
      's_lesson_style.intensive_focus.title',
      `集中纠正部分(可进行多选)`,
      `集中糾正領域（可複選）`,
      `集中校正分野 (複数選択可)`,
      `Phần sửa lỗi (Có thể chọn nhiều)`,
    ],
    detail: [
      `집중 교정`,
      `Focus Areas`,
      's_lesson_style.intensive_focus.detail',
      `集中纠正`,
      `集中糾正`,
      `集中校正`,
      `Phần sửa lỗi`,
    ],
  },
  additional_request: {
    title: [
      `상세 요청 사항 (다중 선택 가능)`,
      `Additional Requests (Multi-selectable)`,
      's_lesson_style.additional_request.title',
      `具体要求事项(可进行多选)`,
      `具體要求事項（可複選）`,
      `リクエスト項目 (複数選択可)`,
      `Yêu cầu thêm (Có thể chọn nhiều)`,
    ],
    detail: [
      `상세 요청`,
      `Additional requests`,
      's_lesson_style.additional_request.detail',
      `具体要求`,
      `具體要求`,
      `詳細リクエスト`,
      `Yêu cầu thêm`,
    ],
    placeholder: [
      `튜터에게 구체적으로 원하는 수업 방식을 영어로 남겨주세요!`,
      `Feel free to enter any other requests.`,
      's_lesson_style.additional_request.placeholder',
      `请用英文向教师提出课程希望的具体形式!`,
      `請以英文具體寫下希望任課教師進行的上課方式！`,
      `チューターに具体的に希望するレッスン方式を英語で入力してください！`,
      `Thoải mái bổ sung thêm yêu cầu khác.`,
    ],
    other_request: [
      `추가 요청`,
      `Specific request`,
      's_lesson_style.additional_request.other_request',
      `附加要求`,
      `額外要求`,
      `追加リクエスト`,
      `Yêu cầu khác`,
    ],
  },
}
export const s_portal_webinar = {
  lesson_enter_modal_title: [
    `웨비나 입장 안내`,
    `Enter Live Webinar`,
    's_portal_webinar.lesson_enter_modal_title',
    `网络研讨会入场介绍`,
    `網路研討會進場說明`,
    `ウェビナー入室方法のご案内`,
    `Tham gia Webinar trực tuyến`,
  ],
  speed: [
    `재생 속도`,
    `Playback Speed`,
    's_portal_webinar.speed',
    `加载速度`,
    `播放速度`,
    `再生速度`,
    `Tốc độ phát lại`,
  ],
  normal: [`(보통)`, `(normal)`, 's_portal_webinar.normal', `(普通)`, `（普通）`, `(普通)`, `(bình thường)`],
  lesson_enter_modal_content: [
    <>
      <Span size={12}>웨비나 시작 15분 전부터 입장하실 수 있습니다.</Span>
    </>,
    <>
      <Span size={12}>You can enter live webinars up to 15 minutes before the scheduled start time.</Span>
    </>,
    's_portal_webinar.lesson_enter_modal_content',
    <>
      <Span size={12}>网络研讨会开始前15分钟可进场。</Span>
    </>,
    <>
      <Span size={12}>網路研討會開始15分鐘前可進場。</Span>
    </>,
    <>
      <Span size={12}>ウェビナー開始15分前から入室することができます。</Span>
    </>,
    <>
      <Span size={12}>Bạn có thể tham dự webinar sớm hơn 15 phút trước giờ bắt đầu.</Span>
    </>,
  ],
  viewAll: [`전체보기`, `View All`, 's_portal_webinar.viewAll', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  clips: [`클립 목록`, `Clips`, 's_portal_webinar.clips', `视频片段`, `片段清單`, `再生リスト`, `Clips`],
  currentApply: (count) => [
    <>현재 신청 {count}명</>,
    <>{count} people RSVP-ed</>,
    's_portal_webinar.currentApply',
    <>当前申请 {count}名</>,
    <>目前申請：{count}人</>,
    <>現在予約済 {count}名</>,
    <>{count} người tham gia</>,
  ],
  totalAttendee: (total) => [
    <>전체 정원 {total}명</>,
    <>Up to {total} attendees</>,
    's_portal_webinar.totalAttendee',
    <>总名额 {total}名</>,
    <>總名額：{total}人</>,
    <>定員 {total}名</>,
    <>{total} chỗ trống</>,
  ],
  doc: {
    search: {
      placeholder: [
        `튜터명과 주제등을 검색하세요`,
        `Enter keyword(s) to search.`,
        's_portal_webinar.doc.search.placeholder',
        `请搜索教师姓名或主题等关键词`,
        `請搜尋任課教師姓名與主題等`,
        `チューター名やテーマなどを検索してください。`,
        `Nhập từ khóa để tìm kiếm.`,
      ],
      label: [`검색하기`, `Search`, 's_portal_webinar.doc.search.label', `搜索`, `搜尋`, `検索する`, `Tìm kiếm`],
    },
  },
  register: [
    `자세히 보기`,
    `View detail`,
    's_portal_webinar.register',
    `详细查看`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  lucky_draw_enroll: [
    `이벤트 참여`,
    `Enter Event`,
    's_portal_webinar.lucky_draw_enroll',
    `参与活动`,
    `參與活動`,
    `イベント参加`,
    `Tham dự sự kiện`,
  ],
  related_material: [
    `관련 교재`,
    `Related Materials`,
    's_portal_webinar.related_material',
    `相关教材`,
    `相關教材`,
    `関連教材`,
    `Tài liệu liên quan`,
  ],
  related_article: [
    `관련 링크`,
    `Related Links`,
    's_portal_webinar.related_article',
    `相关新闻报道`,
    `相關報導`,
    `関連記事`,
    `Tin tức liên quan`,
  ],
  related_video: [
    `관련 기사`,
    `Related Videos`,
    's_portal_webinar.related_video',
    `相关新闻报道`,
    `相關報導`,
    `関連記事`,
    `Video liên quan`,
  ],
  lesson_cancel_modal_title: [
    `링글 라이브 신청 취소`,
    `Cancel Ringle LIVE RSVP`,
    's_portal_webinar.lesson_cancel_modal_title',
    `取消Ringle LIVE的申请`,
    `取消Ringle LIVE的申請`,
    `Ringle LIVEの申請を取り消し`,
    `Hủy đăng ký Ringle LIVE`,
  ],
  lesson_cancel_modal_content: [
    `신청한 링글 라이브를 취소하시겠습니까?`,
    `Do you want to cancel Ringle LIVE RSVP?`,
    's_portal_webinar.lesson_cancel_modal_content',
    `确定要取消Ringle LIVE的申请吗？`,
    `確定要取消Ringle LIVE的申請嗎？`,
    `申請したRingle LIVEを取り消ししますか？`,
    `Bạn có muốn hủy đăng ký Ringle LIVE?`,
  ],
  lesson_cancel_modal_subject: [
    `주제`,
    `Topic`,
    's_portal_webinar.lesson_cancel_modal_subject',
    `主题`,
    `主題`,
    `テーマ`,
    `Chủ đề`,
  ],
  lesson_cancel_modal_time: [
    `시간`,
    `Time`,
    's_portal_webinar.lesson_cancel_modal_time',
    `时间`,
    `時間`,
    `時間`,
    `Thời gian`,
  ],
}
export const s_webinar = {
  like: [`찜하기`, `Save`, 's_webinar.like', `收藏`, `收藏`, `お気に入り登録`, `Lưu`],
  apply_description: {
    li_1: [
      `구글 계정을 사용하시면 '내 구글 캘린더'에 자동으로 일정이 추가되며 웨비나 당일 1시간 전 앱 푸시 알림을 받아볼 수 있습니다.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      's_webinar.apply_description.li_1',
      `如果您使用谷歌账号，日程将自动添加到"我的谷歌日历”中，您可以在网络研讨会当天1小时前收到应用推送通知。`,
      `如果您使用谷歌賬號，日程將自動添加到"我的谷歌日曆”中，您可以在網絡研討會當天1小時前收到應用推送通知。`,
      `Googleアカウントを使用すると「Googleカレンダー」に自動でスケジュールが追加され、ウェビナー当日開始1時間前に通知を受け取る設定ができます。`,
      `Nếu bạn xác nhận qua gmail, buổi Webinar sẽ được thêm trực tiếp vào Google Calendar của bạn và sẽ gửi thông báo 10 phút trước khi bắt đầu.`,
    ],
    li_2: [
      `링글 LIVE 수업 녹화 영상은 세션 종료 후 최대 7일 이내로 ‘[링글 홈] > [콘텐츠] > [웨비나]’에 업데이트됩니다.`,
      `Live webinars recordings are uploaded on Ringle's website and app under '[Contents] > [Webinar]' within 7 days from live streaming.`,
      's_webinar.apply_description.li_2',
      `Ringle 直播课程的录像视频将在会话结束后最多7天内更新到 "[主页] > [内容] > [网络研讨会]” 上。`,
      `Ringle 直播課程的錄像視頻將在會話結束後最多7天內更新到 "[主頁]>[內容]>[網絡研討會]” 上。`,
      `リンガルLIVEレッスンの録画映像はセッション終了後、最大7日以内に[リンガルホーム]>[コンテンツ]>[ウェビナー]内にアップデートされます。`,
      `Video buổi học LIVE trên Ringle sẽ được Update trong mục ‘[Ringle HOME] > [Nội dung] > [Hội thảo trực tuyến]’ trong vòng 7 ngày sau khi kết thúc buổi học.`,
    ],
    li_3: [
      `수업 녹화 영상 링크와 자료는 수업 후 2~3일 내로 링글 LIVE를 신청한 링글 계정 이메일로 공유드립니다.`,
      `The materials used during the webinar and the link to the webinar recording will be shared to the RSVPed email addresses within 2-3 days from live streaming.`,
      's_webinar.apply_description.li_3',
      `课程录像视频链接和资料将在课后 2~3 天内发送到申请直播课的 Ringle 账号邮箱内。`,
      `課程錄像視頻鏈接和資料將在課後 2~3 天內發送到申請直播課的 Ringle 賬號郵箱內。`,
      `レッスン録画映像リンクと資料はレッスン後2～3日以内にリンガルLIVEを申し込んだリンガルアカウントのメールを通して共有いたします。`,
      `Link Video buổi học và tài liệu sẽ được chia sẻ trong vòng 2 đến 3 ngày qua địa chỉ Email bạn đã dùng để đăng ký Ringle LIVE.`,
    ],
    li_4: [
      `링글 LIVE와 관련하여 문의사항이 있으시면, [홈페이지 1:1 문의창 > 링글 콘텐츠 > 웨비나] 를 통해 문의사항을 남겨주세요! (* 1:1 문의 : 홈페이지 화면 우측 하단 보라색 말풍선)`,
      `If you have any questions about Ringle LIVE, please leave a message via the 1:1 chat support at the bottom right of the Ringle homepage (click 'Ringle contents' > 'Webinars').`,
      's_webinar.apply_description.li_4',
      `如果您对 Ringle 直播课有疑问，请通过 [主页 1:1 咨询窗口 > Ringle 内容 > 网络研讨会] 留下咨询事项！（* 1:1 咨询: 主页右下角紫色对话框）`,
      `如果您對 Ringle 直播課有疑問，請通過 [主頁 1:1 諮詢窗口 > Ringle 內容 > 網絡研討會] 留下諮詢事項！（* 1:1 諮詢: 主頁右下角紫色對話框）`,
      `リンガルLIVEと関連してお問い合わせがありましたら、[ホームページ１：１問い合わせチャット>リンガルコンテンツ>ウェビナー]を通してお問い合わせ内容をお送りください！（※１：１問い合わせ：ホームページ画面右下の紫のふきだし）`,
      `Nếu có điều gì thắc mắc về Ringle LIVE, vui lòng để lại câu hỏi cho chúng tôi trong mục [Trang Tư vấn 1:1 tại Homepage > Nội dung Ringle > Hội thảo trực tuyến]! (* Tư vấn 1:1 : Biểu tượng Chat ở góc phải bên dưới Homepage)`,
    ],
    li_5: [
      `수업 녹화 영상과 자료는 연사의 요청에 따라 공개되지 않을 수 있습니다.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      's_webinar.apply_description.li_5',
      `根据讲师的要求，录像视频和资料有可能不被公开。`,
      `根據講師的要求，錄像視頻和資料有可能不被公開。`,
      `レッスン録画映像と資料は講師の要望により公開されない場合があります。`,
      `Video buổi học và tài liệu có thể không được công khai tùy theo yêu cầu của diễn giả.`,
    ],
    sign_up_now: [
      `링글 웨비나 안내`,
      `Ringle Webinar Info`,
      's_webinar.apply_description.sign_up_now',
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
    ],
    sign_up_now_paid: [
      `링글 웨비나 안내`,
      `Ringle Webinar Info`,
      's_webinar.apply_description.sign_up_now_paid',
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
    ],
    li_1_paid: [
      `신청 및 결제는 외부 플랫폼(이벤터스)을 통해 진행됩니다.`,
      `Registration and payment can be completed through the third-party platform EventUs.`,
      's_webinar.apply_description.li_1_paid',
      `Registration and payment can be completed through the third-party platform EventUs.`,
      `Registration and payment can be completed through the third-party platform EventUs.`,
      `Registration and payment can be completed through the third-party platform EventUs.`,
      `Registration and payment can be completed through the third-party platform EventUs.`,
    ],
    li_2_paid: [
      `신청 마감 일시는 페이지의 상단에서 확인할 수 있습니다.`,
      `The deadline for registration can be found at the top of this page.`,
      's_webinar.apply_description.li_2_paid',
      `The deadline for registration can be found at the top of this page.`,
      `The deadline for registration can be found at the top of this page.`,
      `The deadline for registration can be found at the top of this page.`,
      `The deadline for registration can be found at the top of this page.`,
    ],
    li_3_paid: [
      `취소 및 환불은 신청 마감 전까지 가능합니다. 관련 문의는 유료 웨비나의 제목과 함께 webinar@ringleplus.com으로 보내 주세요.`,
      `Cancellation and refund can be processed until the deadline for registration. Please forward your relevant inquiries to webinar@ringleplus.com with the webinar title to which your inquiry regards.`,
      's_webinar.apply_description.li_3_paid',
      `Cancellation and refund can be processed until the deadline for registration. Please forward your relevant inquiries to webinar@ringleplus.com with the webinar title to which your inquiry regards.`,
      `Cancellation and refund can be processed until the deadline for registration. Please forward your relevant inquiries to webinar@ringleplus.com with the webinar title to which your inquiry regards.`,
      `Cancellation and refund can be processed until the deadline for registration. Please forward your relevant inquiries to webinar@ringleplus.com with the webinar title to which your inquiry regards.`,
      `Cancellation and refund can be processed until the deadline for registration. Please forward your relevant inquiries to webinar@ringleplus.com with the webinar title to which your inquiry regards.`,
    ],
    li_4_paid: [
      `실시간 LIVE 강의가 있는 유료 웨비나의 경우, 강의 입장용 줌 링크는 신청 시 입력한 이메일과 휴대폰 번호로 웨비나 시작 2시간 전에 보내드립니다.`,
      `As for the premium webinars that include LIVE lectures, Zoom meeting links to join the lecture will be sent via email and SMS 2 hours prior to the lecture.`,
      's_webinar.apply_description.li_4_paid',
      `As for the premium webinars that include LIVE lectures, Zoom meeting links to join the lecture will be sent via email and SMS 2 hours prior to the lecture.`,
      `As for the premium webinars that include LIVE lectures, Zoom meeting links to join the lecture will be sent via email and SMS 2 hours prior to the lecture.`,
      `As for the premium webinars that include LIVE lectures, Zoom meeting links to join the lecture will be sent via email and SMS 2 hours prior to the lecture.`,
      `As for the premium webinars that include LIVE lectures, Zoom meeting links to join the lecture will be sent via email and SMS 2 hours prior to the lecture.`,
    ],
    li_5_paid: [
      `학습자료 및 실시간 LIVE 강의 다시보기 링크는 강의 종료 후 2~3일 이내로 신청 시 입력한 이메일로 보내드립니다.`,
      `Materials and replay will be shared with the registrants via email within 2~3 days after the LIVE lecture.`,
      's_webinar.apply_description.li_5_paid',
      `Materials and replay will be shared with the registrants via email within 2~3 days after the LIVE lecture.`,
      `Materials and replay will be shared with the registrants via email within 2~3 days after the LIVE lecture.`,
      `Materials and replay will be shared with the registrants via email within 2~3 days after the LIVE lecture.`,
      `Materials and replay will be shared with the registrants via email within 2~3 days after the LIVE lecture.`,
    ],
  },
  apply_float: {
    download: [
      `자료 받기`,
      `Download Materials`,
      's_webinar.apply_float.download',
      `下载资料`,
      `下載資料`,
      `資料ダウンロード`,
      `Tải Tài liệu`,
    ],
    register: [`신청하기`, `RSVP`, 's_webinar.apply_float.register', `申请`, `申請`, `予約`, `Xác nhận tham gia`],
    completed: [
      `신청 완료`,
      `RSVP-ed`,
      's_webinar.apply_float.completed',
      `完成申请`,
      `申請完成`,
      `予約完了`,
      `Đã xác nhận tham gia`,
    ],
    cancel: [
      `신청 취소`,
      `Cancel RSVP`,
      's_webinar.apply_float.cancel',
      `取消申请`,
      `取消申請`,
      `予約キャンセル`,
      `Hủy đăng ký tham gia`,
    ],
    enter: [`입장하기`, `Enter`, 's_webinar.apply_float.enter', `入场`, `進場`, `入室する`, `Tham gia`],
    enterLong: [
      `입장하기`,
      `Enter Live Webinar`,
      's_webinar.apply_float.enterLong',
      `入场`,
      `進場`,
      `入室する`,
      `Tham gia buổi webinar trực tuyến`,
    ],
    register_paid: (amount) => [
      <>신청하러 가기 ({amount}원)</>,
      <>Go Register ({amount}KRW) </>,
      's_webinar.apply_float.register_paid',
      <>Go Register ({amount}KRW) </>,
      <>Go Register ({amount}KRW) </>,
      <>Go Register ({amount}KRW) </>,
      <>Go Register ({amount}KRW) </>,
    ],
    promotion_2309_invalid: [
      `프로모션 구매자 한정`,
      `Only for promotional credits purchasers`,
      's_webinar.apply_float.promotion_2309_invalid',
      `Only for promotional credits purchasers`,
      `Only for promotional credits purchasers`,
      `Only for promotional credits purchasers`,
      `Only for promotional credits purchasers`,
    ],
    inactive: [
      `웨비나 시작 15분 전부터 입장 가능합니다.`,
      `Link activates 15 mins before the webinar starts`,
      's_webinar.apply_float.inactive',
      `Link activates 15 mins before the webinar starts`,
      `Link activates 15 mins before the webinar starts`,
      `Link activates 15 mins before the webinar starts`,
      `Link activates 15 mins before the webinar starts`,
    ],
  },
  a_tutor_will_be_added_soon: [
    `튜터가 곧 추가될 예정입니다.`,
    `A tutor will be added soon.`,
    's_webinar.a_tutor_will_be_added_soon',
    `即将添加教师。`,
    `預計新增任課教師。`,
    `チューターがまもなく追加される予定です。`,
    `Gia sư sẽ sớm được thêm vào.`,
  ],
  tab_summary: [`개요`, `Overview`, 's_webinar.tab_summary', `概要`, `概要`, `概要`, `Tổng quan`],
  tab_tutor: (name) => [
    `${name}의 다른 웨비나`,
    `More webinars by ${name}`,
    's_webinar.tab_tutor',
    `${name}的其他网络研讨会`,
    `${name}的其他網路研討會`,
    `${name}さんの他のウェビナー`,
    `Các webinar khác với ${name}`,
  ],
  introduce_of_out_tutor: [`Tutor`, `Tutor`, 's_webinar.introduce_of_out_tutor', `Tutor`, `Tutor`, `Tutor`, `Gia sư`],
  search_result: [
    `웨비나 검색 결과`,
    `Search Results`,
    's_webinar.search_result',
    `网络研讨会搜索结果`,
    `網路研討會搜尋結果`,
    `ウェビナー検索結果`,
    `Kết quả tìm kiếm`,
  ],
  no_result: [
    `😣  키워드에 맞는 검색 결과가 없습니다.`,
    `😣  No Result`,
    's_webinar.no_result',
    `😣  没有符合关键词的搜索结果。`,
    `😣  沒有符合關鍵字的搜尋結果。`,
    `😣  キーワードに合う検索結果はありません。`,
    `😣  Không có kết quả`,
  ],
  search_webinar: [
    `웨비나 검색`,
    `Search`,
    's_webinar.search_webinar',
    `搜索网络研讨会`,
    `搜尋網路研討會`,
    `ウェビナー検索`,
    `Tìm kiếm`,
  ],
  apply: [
    `웨비나 신청`,
    `RSVP`,
    's_webinar.apply',
    `申请网络研讨会`,
    `申請網路研討會`,
    `ウェビナー予約`,
    `Xác nhận đăng ký`,
  ],
  need_login: [
    <>
      선택하신 웨비나를 보시려면 로그인이 필요합니다.
      <br />
      링글 회원에게만 제공되는 300+개의 무료 웨비나를 확인해보세요.
    </>,
    <>
      Please log in to watch the webinar you selected.
      <br />
      Check 300+ webinars hosted by Ringe's best tutors.
    </>,
    's_webinar.need_login',
    <>
      如果想观看选择的网络研讨会, 请先登录。
      <br />
      请确认超过300部提供给Ringle会员的免费网络研讨会资料。
    </>,
    <>
      欲觀看選擇的網路研討會需先登入。
      <br />
      請確認300多個只提供給Ringle會員的免費網路研討會。
    </>,
    <>
      選択したウェビナーを見るにはログインが必要です。
      <br />
      Ringle会員にのみ提供される300+本の無料ウェビナーを確認してみましょう。
    </>,
    <>
      Vui lòng đăng nhập để xem webinar bạn đã chọn.
      <br />
      Khám phá 300+ webinar từ những gia sư giỏi nhất của Ringle.
    </>,
  ],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_webinar.login_desc',
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
  ],
  login: [`로그인`, `Log in`, 's_webinar.login', `Log in`, `Log in`, `Log in`, `Log in`],
  view_detail: [
    `더 알아보기`,
    `View detail`,
    's_webinar.view_detail',
    `View detail`,
    `View detail`,
    `View detail`,
    `View detail`,
  ],
  register_until: (date) => [
    `${date}까지 신청`,
    `Until ${date}`,
    's_webinar.register_until',
    `Until ${date}`,
    `Until ${date}`,
    `Until ${date}`,
    `Until ${date}`,
  ],
  apply_description_promotion: {
    li_1: [
      `이번 웨비나는 프로모션 기간 중 수업권을 구매한 고객에 한하여 시청이 가능합니다. (프로모션 기간 8/1 00:00 - 9/8 14:00)`,
      `This webinar is only available to customers who purchased a class during the promotional period. (Promotional period Aug 1 00:00 KST - Sep 8 14:00 KST)`,
      's_webinar.apply_description_promotion.li_1',
      `This webinar is only available to customers who purchased a class during the promotional period. (Promotional period Aug 1 00:00 KST - Sep 8 14:00 KST)`,
      `This webinar is only available to customers who purchased a class during the promotional period. (Promotional period Aug 1 00:00 KST - Sep 8 14:00 KST)`,
      `This webinar is only available to customers who purchased a class during the promotional period. (Promotional period Aug 1 00:00 KST - Sep 8 14:00 JST)`,
      `This webinar is only available to customers who purchased a class during the promotional period. (Promotional period Aug 1 00:00 KST - Sep 8 14:00 KST)`,
    ],
    li_2: [
      `구글 계정을 사용하시면 '내 구글 캘린더'에 자동으로 일정이 추가되며 웨비나 당일 1시간 전 앱 푸시 알림을 받아볼 수 있습니다.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      's_webinar.apply_description_promotion.li_2',
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
    ],
    li_3: [
      `링글 LIVE 수업 녹화 영상은 세션 종료 후 각 프로그램의 카카오 오픈채팅방을 통해 전달드립니다.`,
      `Recordings of Ringle LIVE classes will be delivered via Kakao Open Chat for each program after the session.`,
      's_webinar.apply_description_promotion.li_3',
      `Recordings of Ringle LIVE classes will be delivered via Kakao Open Chat for each program after the session.`,
      `Recordings of Ringle LIVE classes will be delivered via Kakao Open Chat for each program after the session.`,
      `Recordings of Ringle LIVE classes will be delivered via Kakao Open Chat for each program after the session.`,
      `Recordings of Ringle LIVE classes will be delivered via Kakao Open Chat for each program after the session.`,
    ],
    li_4: [
      `링글 LIVE와 관련하여 문의사항이 있으시면, 카카오 링글 채널을 통해 남겨주세요.`,
      `If you have any questions about Ringle LIVE, please contact us through the Kakao Ringle channel.`,
      's_webinar.apply_description_promotion.li_4',
      `If you have any questions about Ringle LIVE, please contact us through the Kakao Ringle channel.`,
      `If you have any questions about Ringle LIVE, please contact us through the Kakao Ringle channel.`,
      `If you have any questions about Ringle LIVE, please contact us through the Kakao Ringle channel.`,
      `If you have any questions about Ringle LIVE, please contact us through the Kakao Ringle channel.`,
    ],
    li_5: [
      `수업 녹화 영상과 자료는 연사의 요청에 따라 공개되지 않을 수 있습니다.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      's_webinar.apply_description_promotion.li_5',
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
    ],
    sign_up_now: [
      `링글 웨비나 안내`,
      `Ringle Webinar Info`,
      's_webinar.apply_description_promotion.sign_up_now',
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
      `Ringle Webinar Info`,
    ],
  },
  banner_register_until: (date, timezone) => [
    `${date} (${timezone})까지 신청`,
    `Until ${date} (${timezone})`,
    's_webinar.banner_register_until',
    ``,
    ``,
    ``,
    ``,
  ],
  register_until_promotion_2309: (date) => [
    `${date}`,
    `${date}`,
    's_webinar.register_until_promotion_2309',
    `${date}`,
    `${date}`,
    `${date}`,
    `${date}`,
  ],
  banner_register_until_promotion_2309: (date, timezone) => [
    `${date} (${timezone})`,
    `${date} (${timezone})`,
    's_webinar.banner_register_until_promotion_2309',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_schedule_enter = {
  go: [`예약하기`, `Go`, 's_schedule_enter.go', `预约`, `預約`, `予約する`, `Kiểm tra`],
  purchase: [
    `수업권 구매하러 가기`,
    `Buy now`,
    's_schedule_enter.purchase',
    `购买课程券`,
    `前往購買課程券`,
    `レッスンチケットを購入する`,
    `Mua ngay`,
  ],
  challenge_book_title: (date) => [
    <>도전 기간: {date}</>,
    <>Challenge Period: {date}</>,
    's_schedule_enter.challenge_book_title',
    <>挑战期间: {date}</>,
    <>挑戰期間：{date}</>,
    <>挑戦期間: {date}</>,
    <>Thời gian thử thách: {date}</>,
  ],
  challenge_book_duration: (duration) => [
    <>도전 기간: {duration}일 (첫 수업 완료 후 시작)</>,
    <>Challenge Period: {duration} days (Countdown will start once you complete your first lesson)</>,
    's_schedule_enter.challenge_book_duration',
    <>挑战期间: {duration}日 (首次课程结束后开始)</>,
    <>挑戰期間：{duration}日（首次課程結束後開始）</>,
    <>挑戦期間: {duration}日 (初回レッスン完了後開始)</>,
    <>Thời gian thử thách: {duration} ngày (Đếm ngược bắt đầu khi bạn hoàn thành buổi học đầu tiên)</>,
  ],
  challenge_book_content: (goal, bonus) => [
    `${goal}회 수업권을 도전 기간 내 모두 사용하면 ${bonus}회 추가 정규수업권이 제공됩니다. (*수업 노쇼/취소도 수업권 [사용]으로 인정)`,
    `If you use up all your credits during the Challenge Period, we will provide you with additional credits.`,
    's_schedule_enter.challenge_book_content',
    `${goal}次课程券于挑战期间全部使用后将提供${bonus}次正规课程券。 (*旷课/取消时也被视为[使用])`,
    `於挑戰期間內將${goal}次課程券全數使用時，額外提供${bonus}次正規課程券。（*上課未出現／取消也視同[使用]課程券）`,
    `${goal}回レッスンチケットを挑戦期間内にすべて使用すると、${bonus}回追加でレギュラーレッスンチケットが提供されます。 (*レッスン無断欠席/キャンセルもレッスンチケット [使用]として認定)`,
    `Nếu bạn dùng hết các thẻ học trong khoảng Thời gian thử thách, chúng tôi sẽ tặng thêm thẻ học cho bạn.`,
  ],
  lesson_book_title: [
    <>[수업권 챌린지] 장기권 혜택으로 당일수업권 제공</>,
    <>Last-minute Credits</>,
    's_schedule_enter.lesson_book_title',
    <>提供24小时补充课程</>,
    <>提供24小時補充課程</>,
    <>24時間当日レッスン進呈</>,
    <>Thẻ học bù</>,
  ],
  lesson_book_content: [
    `매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공해 드립니다.`,
    `For every four completed lessons, you’ll get one Last-minute Credit that can be used for [Last-minute Lessons].`,
    's_schedule_enter.lesson_book_content',
    `每使用六次正规课程券后, 将获得可于24小时内使用的补充课程券。`,
    `每耗盡4次正規課程，提供24小時以內可使用的補充課程券。`,
    `レギュラーレッスンチケット4回使用で、24時間以内に受講可能な当日レッスン1回分が付与されます。`,
    `Cứ 4 buổi học bạn hoàn thành, bạn sẽ nhận được một thẻ học bù và có thể sử dụng trong vòng 24 giờ.`,
  ],
  used_challenge: (used) => [
    `${used}회 달성`,
    `Achieved ${used}`,
    's_schedule_enter.used_challenge',
    `完成${used}次`,
    `達成${used}次`,
    `${used}回達成`,
    `${used} buổi học`,
  ],
  used: (used) => [
    `${used}회 사용`,
    `${used}`,
    's_schedule_enter.used',
    `使用${used}次`,
    `使用${used}次`,
    `${used}回使用`,
    `${used}`,
  ],
  received: (received) => [
    `${received}회 받음`,
    `Received ${received}`,
    's_schedule_enter.received',
    `领取${received}次`,
    `領取${received}次`,
    `${received}回付与`,
    `Được tặng ${received} buổi học`,
  ],
  available: [
    `남은 수업권`,
    `Remaining`,
    's_schedule_enter.available',
    `所剩课程券`,
    `所剩課程券`,
    `残りのレッスンチケット`,
    `Thẻ học còn lại`,
  ],
  select: [
    `수업 선택`,
    `Select Lesson`,
    's_schedule_enter.select',
    `选择课程`,
    `選擇課程`,
    `レッスン選択`,
    `Chọn buổi học`,
  ],
  expired: [`수강 기간`, `Expires in`, 's_schedule_enter.expired', `使用期限`, `使用期限`, `有効期限`, `Ngày kết thúc`],
  expiredIn: (days) => [
    <>{days}일 남음</>,
    <>{days} days</>,
    's_schedule_enter.expiredIn',
    <>剩余{days}日</>,
    <>剩下{days}日</>,
    <>残り{days}日</>,
    <>trong {days} ngày</>,
  ],
  select_time_first: {
    heading: [
      `시간 먼저 선택`,
      `Sort by time`,
      's_schedule_enter.select_time_first.heading',
      `请先选择时间`,
      `先選擇時間`,
      `時間帯を選ぶ`,
      `Chọn theo giờ`,
    ],
    i_will_choose_a_time_slot_first: [
      <>원하는 수업 시간을 먼저 선택할 수 있습니다.</>,
      `See available tutors for selected time`,
      's_schedule_enter.select_time_first.i_will_choose_a_time_slot_first',
      <>请先选择希望的课程时间。</>,
      <>
        可先選擇
        <br />
        希望的課程時間。
      </>,
      <>
        希望する受講時間帯から
        <br />
        レッスンを選びます。
      </>,
      `Xem những gia sư trống lịch trong thời gian được chọn`,
    ],
  },
  select_tutor_first: {
    heading: [
      `튜터 먼저 선택`,
      `Sort by tutor`,
      's_schedule_enter.select_tutor_first.heading',
      `请先选择教师`,
      `先選擇任課教師`,
      `チューターを選ぶ`,
      `Chọn gia sư`,
    ],
    i_will_choose_a_tutor_first: [
      <>
        원하는 튜터를
        <br />
        먼저 선택할 수 있습니다.
      </>,
      `See available slots of selected tutors`,
      's_schedule_enter.select_tutor_first.i_will_choose_a_tutor_first',
      <>
        请先选择
        <br />
        满意的教师。
      </>,
      <>
        可先選擇
        <br />
        希望的任課教師。
      </>,
      <>
        希望するチューターの
        <br />
        空き状況から選びます。
      </>,
      `Xem các ca trống của những gia sư được chọn`,
    ],
  },
  my_coupons: [
    `내 수업권`,
    `My Credits`,
    's_schedule_enter.my_coupons',
    `我的课程券`,
    `我的課程券`,
    `マイレッスンチケット`,
    `Thẻ học của tôi`,
  ],
  no_coupons: [
    `수업을 예약할 수 있는 수업권이 없습니다.`,
    `You have no lesson credit`,
    's_schedule_enter.no_coupons',
    `没有可预约的课程券。`,
    `無可預約課程的課程券。`,
    `レッスンを予約できるレッスンチケットはありません。`,
    `Bạn không còn thẻ học nào.`,
  ],
  min: [`분`, `min`, 's_schedule_enter.min', `分钟`, `分鐘`, `分`, `phút`],
  free: [`무료`, `Free`, 's_schedule_enter.free', `免费`, `免費`, `無料`, `Miễn phí`],
  regular_credits: [
    `정규수업권`,
    `Regular`,
    's_schedule_enter.regular_credits',
    `正规课程券`,
    `正規課程券`,
    `レギュラーレッスンチケット`,
    `Thẻ học tiêu chuẩn`,
  ],
  limited_window_credits: [
    `시간한정수업권`,
    `Limited window credits`,
    's_schedule_enter.limited_window_credits',
    `时间限定课程券`,
    `時間限定課程券`,
    `時間限定レッスンチケット`,
    `Thẻ học giờ đặc biệt`,
  ],
  makeup_credits: [
    `당일수업권`,
    `Last-minute`,
    's_schedule_enter.makeup_credits',
    `当日课程券`,
    `當日課程券`,
    `補充レッスンチケット`,
    `Thẻ học bù`,
  ],
  regular_tooltip: [
    `정규수업권 1회로 정규 시간 내 1:1 수업을 1회 자유롭게 이용가능하며, [당일수업] 예약에도 사용할 수 있습니다.`,
    `Regular Credits can be used to book any regular lessons. You can also book a lesson available in 24 hours with Regular Credits.`,
    's_schedule_enter.regular_tooltip',
    `一张正规课程券可以预约一次1:1课程，也可以预约[当日课程] 。`,
    `一張正規課程券可以預約一次1:1課程，也可以預約[當日課程] 。`,
    `レギュラーレッスンチケット1つで1回の1on1レッスンを受講できます。
また、24時間前のレッスンもレギュラーレッスンチケットで予約／受講できます。`,
    `Bạn có thể đặt lịch học bình thường với thẻ học tiêu chuẩn. Bạn cũng có thể đặt lịch học bù trong 24 giờ tới`,
  ],
  limited_tooltip: [
    `시간한정수업권은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited window credits can only be used for certain time slots.
Morning: Lessons starting between 5am & 7am (KST)
Midday: Lessons starting between 11am & 1pm (KST)
Late night: Lessons starting between 12am & 2am (KST)`,
    's_schedule_enter.limited_tooltip',
    `时间限定课程券只能于以下时间使用
凌晨班 - 上午5点～8点（韩国标准时间）
深夜班 - 晚上12点 ~ 凌晨3点 (韩国时间)`,
    `時間限定課程券只能於以下時間使用
早鳥班 - 上午5點～8點（韓國標準時間）
深夜班 - 晚上12點 ~ 凌晨3點 (韓國時間)`,
    `時間限定レッスンチケットは時間帯による３つのレッスンチケットで構成されています。
早朝：午前5時～午前7時（開始時間基準）
ランチタイム：午前11時～午後1時（開始時間基準）
深夜：午前0時～午前2時（開始時間基準）`,
    `Thẻ học giờ đặt biệt chỉ có thể đặt lịch học cho khoảng thời gian nhất định Sáng: các buổi học bắt đầu từ 5-7 giờ sáng (Giờ Hàn Quốc) Trưa: các buổi học bắt đầu từ 11 giờ sáng-13 giờ chiều (Giờ Hàn Quốc) Khuya: các buổi học bắt đầu từ 12 giờ khuya-2 giờ sáng (Giờ Hàn Quốc)`,
  ],
  makeup_tooltip: [
    `당일수업권이란 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있는 수업권으로, [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
    `[Last-minute Credits] are lesson credits that can only be used to reserve [Lessons starting within 24 hours] from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
    's_schedule_enter.makeup_tooltip',
    `当日课程券只能预约24小时内开始的课程，[1:1 课程] > [预约] > [可预约的当天课程]。`,
    `當日課程券只能預約24小時內開始的課程，[1:1 課程] > [預約] > [可預約的當天課程]。`,
    `補充レッスンは24時間以内に受講可能なレッスンです。
補充レッスンチケットは予約ページで[24時間以内の授業]を予約するときに利用できます。`,
    `Buổi học bù là những buổi học diễn ra trong 24 giờ tới. Bạn sẽ sử dụng thẻ học bù để đặt.`,
  ],
  selected_material: [
    `찜한 교재에서 확인할 수 있어요.`,
    `Check in Favorite Materials.`,
    's_schedule_enter.selected_material',
    `View in [Select Materials > Favorites]`,
    `View in [Select Materials > Favorites]`,
    `[教材選択＞お気に入りの教材]で確認することができます。`,
    `Xem tại [Chọn Tài liệu > Yêu thích]`,
  ],
  text_week: (n) => [
    <>{n}주차</>,
    <>Week {n}</>,
    's_schedule_enter.text_week',
    <>{n}週</>,
    <>{n}週</>,
    <>{n}週目</>,
    <>Tuần {n}</>,
  ],
  msg: (n) => [
    <>{n}일 남음</>,
    <>{n}일 남음</>,
    's_schedule_enter.msg',
    <>剩馀 {n}日</>,
    <>剩餘 {n}日</>,
    <>残り{n}日</>,
    <>Còn {n} ngày</>,
  ],
  btn_weekly: [
    `매주 수업 듣기 현황`,
    `Weekly Lesson Status`,
    's_schedule_enter.btn_weekly',
    `每週上课情况`,
    `每週上課情況`,
    `毎週レッスンを受ける進捗状況`,
    `Tình trạng học hàng tuần`,
  ],
  size_benefit: [
    `받은 당일수업권`,
    `Size-benefits`,
    's_schedule_enter.size_benefit',
    `Size-benefits`,
    `Size-benefits`,
    `Size-benefits`,
    `Size-benefits`,
  ],
}
export const s_unassigned_lessons = {
  n_left: (n) => [
    <>{n}개</>,
    <>{n} left</>,
    's_unassigned_lessons.n_left',
    <>{n}个</>,
    <>{n}個</>,
    <>{n}回</>,
    <>{n} buổi học</>,
  ],
  credit_choice: [
    `수업권 선택하기`,
    `Select Lesson Credit`,
    's_unassigned_lessons.credit_choice',
    `选择课程券`,
    `選擇課程券`,
    `レッスンチケットを選択`,
    `Chọn thẻ học`,
  ],
  credits: {
    regular_credit: [
      `정규수업권`,
      `Regular Credit`,
      's_unassigned_lessons.credits.regular_credit',
      `正规课程券`,
      `正規課程券`,
      `レギュラーレッスンチケット`,
      `Thẻ học tiêu chuẩn`,
    ],
    makeup_credit: [
      `당일수업권`,
      `Last-minute Credit`,
      's_unassigned_lessons.credits.makeup_credit',
      `补充课程券`,
      `補充課程券`,
      `当日レッスンチケット`,
      `Thẻ học bù`,
    ],
    urgent_credit: [
      `긴급편성수업권`,
      `FastBook Lesson Credit`,
      's_unassigned_lessons.credits.urgent_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    no_credits_available: [
      `사용할 수 있는 수업권이 없습니다.`,
      `No credits available`,
      's_unassigned_lessons.credits.no_credits_available',
      `没有可使用的课程券。`,
      `無可使用的課程券。`,
      `使用できるレッスンチケットはありません。`,
      `Không còn thẻ học nào`,
    ],
  },
  expires_in_n_days: (n) => [
    <>수강기간: {n}일</>,
    <>Expires in: {n} days</>,
    's_unassigned_lessons.expires_in_n_days',
    <>使用期限: {n}日</>,
    <>使用期限：{n}日</>,
    <>受講期間: {n}日</>,
    <>Hết hạn trong: {n} ngày</>,
  ],
  info: {
    title: [
      `예약 가능한 당일수업`,
      `Last-minute lessons available`,
      's_unassigned_lessons.info.title',
      `可预约的当日课程`,
      `可預約的當日課程`,
      `予約可能な当日レッスン`,
      `Buổi học trong ngày có thể đặt`,
    ],
    what_is_makeup_lesson: [
      `당일수업이란?`,
      `What are lessons available in 24 hours?`,
      's_unassigned_lessons.info.what_is_makeup_lesson',
      `24小时以内的课程?`,
      `24小時以內的課程意指？`,
      `24時間以内のレッスンとは？`,
      `Những buổi học nào trống trong 24 giờ tới?`,
    ],
    makeup_lesson_desc: [
      <>
        - 예약 시점 기준 24시간 이내에 시작하는 수업입니다. (타 수강생 취소 시 발생)
        <br />- 정규수업권으로 예약하는 경우 포인트가 추가 적립됩니다. (40분: ￦3,000P / 20분: ￦1,000P){' '}
      </>,
      `These are lessons that opened up due to last-minute cancellations by other students.`,
      's_unassigned_lessons.info.makeup_lesson_desc',
      `指被其他学生取消, 将于24小时以内开始的课程。`,
      `意指其他學生所取消且距離課程開始不到24小時的課程。`,
      `他の受講生がキャンセルした、レッスン開始まで24時間以内のレッスンを意味します。`,
      `Đây là những buổi học được mở vì học viên khác đã huỷ sát giờ.`,
    ],
    label_info: [
      `2. 라벨 설명`,
      `2. Label Description`,
      's_unassigned_lessons.info.label_info',
      `2.等级说明`,
      `2.等級說明`,
      `2.ラベル説明`,
      `2. Giải thích nhãn`,
    ],
    makeup_lesson_info: [
      `당일수업권으로 예약 가능한 24시간 이내 수업을 의미합니다.`,
      `Lessons available in 24 hours that can be booked with Last-minute Credits.`,
      's_unassigned_lessons.info.makeup_lesson_info',
      `可使用补充课程券申请的24小时以内的课程。`,
      `可使用補充課程券申請的24小時以內的課程。`,
      `当日レッスンチケットで予約可能な24時間以内のレッスンを意味します。`,
      `Những buổi học trống trong vòng 24 giờ mà bạn có thể đặt lịch bằng Thẻ Học bù.`,
    ],
    urgent_lesson_info: [
      `긴급편성수업권으로 2시간 이내 시작하는 수업을 예약할 수 있습니다.`,
      `Lesson starting in two hours or less can be booked using Fastbook Credit(s).`,
      's_unassigned_lessons.info.urgent_lesson_info',
      `使用紧急编排课程券可以预约2小时之内开始的课程。`,
      `使用緊急編排課程券可以預約2小時之內開始的課程。`,
      `緊急編成レッスンチケットで2時間以内に開始するレッスンを予約することができます。`,
      `Bạn có thể đặt buổi học bắt đầu trong vòng 2 giờ với Thẻ mở buổi học gấp`,
    ],
    n_minute_lesson40: [
      `40분 수업입니다.`,
      `This is a 40-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson40',
      `40分钟课程`,
      `40分鐘課程`,
      `40分レッスンです。`,
      `Buổi học 40 phút.`,
    ],
    n_minute_lesson20: [
      `20분 수업입니다.`,
      `This is a 20-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson20',
      `20分钟课程`,
      `20分鐘課程`,
      `20分レッスンです。`,
      `Buổi học 20 phút.`,
    ],
  },
  inform: {
    topic_of_this_lesson_will_be: (topic) => [
      <>
        <b>{topic}</b> 교재로 진행됩니다.
      </>,
      <>
        This lesson will be on <strong>{topic}</strong>.
      </>,
      's_unassigned_lessons.inform.topic_of_this_lesson_will_be',
      <>
        <b>使用{topic}</b>教材进行。
      </>,
      <>
        <b>使用{topic}</b>教材進行。
      </>,
      <>
        <b>{topic}</b> 教材で行います。
      </>,
      <>
        Buổi học sẽ về <strong>{topic}</strong>.
      </>,
    ],
    available_lessons: [
      `1. 당일수업이란?`,
      `1. What is a Last-minute Lesson?`,
      's_unassigned_lessons.inform.available_lessons',
      `1.什么是当日课程？`,
      `1.什麼是當日課程？`,
      `1.当日レッスンとは？`,
      `1. Buổi học trong ngày là?`,
    ],
    available_lessons_count: (n) => [
      <>예약 가능한 당일수업 ({n})</>,
      <>Last-minute lessons available for booking ({n}) </>,
      's_unassigned_lessons.inform.available_lessons_count',
      <>可预约的当日课程({n})</>,
      <>可預約的當日課程({n})</>,
      <>予約可能な当日レッスン({n})</>,
      <>Buổi học trong ngày có thể đặt ({n})</>,
    ],
  },
  makeup_lesson: [
    `당일수업`,
    `Last-minute`,
    's_unassigned_lessons.makeup_lesson',
    `补充课程`,
    `補充課程`,
    `当日レッスン`,
    `Học bù`,
  ],
  lessons_in_24: [
    `당일수업 예약`,
    `Book Last-minute Lesson`,
    's_unassigned_lessons.lessons_in_24',
    `当日课程预约`,
    `當日課程預約`,
    `当日レッスン予約`,
    `Đặt buổi học trong ngày`,
  ],
  noti: {
    title_notifications: [
      `알림 설정`,
      `Notification Settings`,
      's_unassigned_lessons.noti.title_notifications',
      `设置提醒`,
      `通知設定`,
      `通知設定`,
      `Cài đặt thông báo`,
    ],
    get_noti_all: [
      `모든 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me every time a lesson becomes available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_all',
      `接收全部的补充课程提醒`,
      `接收所有補充課程通知`,
      `当日レッスン通知をすべて受け取る`,
      `Thông báo tôi mỗi khi có buổi học trống lịch trong 24 giờ tới`,
    ],
    get_noti_of_my_tutors_only: [
      `찜한 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me only when my favorite tutor has a lesson available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_of_my_tutors_only',
      `选择接收的补充课程提醒('仅限收藏的教师)`,
      `選擇性接收補充課程通知（僅接收「我收藏的任課教師」）`,
      `当日レッスン通知を選んで受け取る (「お気に入りチューター」のみ)`,
      `Chỉ thông báo khi gia sư yêu thích của tôi có buổi học trống trong 24 giờ tới`,
    ],
    set_off: [
      `[당일수업] 업데이트 알림 받지 않기`,
      `Turn notifications off`,
      's_unassigned_lessons.noti.set_off',
      `不接收提醒`,
      `不接收通知`,
      `通知を受け取らない`,
      `Tắt thông báo`,
    ],
  },
  lesson_in_24_hours: [
    `예약 가능한 당일수업`,
    `Last-minute Lessons`,
    's_unassigned_lessons.lesson_in_24_hours',
    `24小时内的课程`,
    `24小時以內的課程`,
    `24時間以内のレッスン`,
    `Buổi học trong 24 giờ tới`,
  ],
  lesson_in_24_description: [
    <>
      24시간 이내에 시작하는 수업을 예약할 수 있습니다.
      <br />
      정규/당일/긴급편성수업권으로 사용 가능합니다.
    </>,
    `You can book with Regular, Last-minute, or FastBook Lesson Credits.`,
    's_unassigned_lessons.lesson_in_24_description',
    `预约从当前起24小时以内开始的课程。正规/补充/紧急编辑可使用课程券进行预约。`,
    `預約從現在起24小時以內開始的課程。可利用正規／補充／緊急分班課程券預約。`,
    `これから24時間以内に始まるレッスンを予約します。レギュラー/補填/直前予約レッスンチケットで予約することができます。`,
    `Bạn có thể đặt bằng Thẻ học Tiêu chuẩn hoặc Thẻ Học bù.`,
  ],
  register: [`신청하기`, `Book`, 's_unassigned_lessons.register', `申请`, `申請`, `予約する`, `Đặt lịch`],
  urgent_lesson: [
    `긴급편성수업권 사용 가능`,
    `Can be booked using Fastbook Credit(s).`,
    's_unassigned_lessons.urgent_lesson',
    `可使用紧急编排课程券`,
    `可使用緊急編排課程券`,
    `緊急編成レッスンチケット使用可能`,
    `Có thể sử dụng Thẻ mở buổi học gấp`,
  ],
  credit_to_use: [
    `사용 수업권`,
    `Selected Credit`,
    's_unassigned_lessons.credit_to_use',
    `使用课程券`,
    `使用的課程券`,
    `使用レッスンチケット`,
    `Thẻ học đã chọn`,
  ],
  tutor: [`튜터`, `Tutor:`, 's_unassigned_lessons.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư:`],
  confirm: {
    title_register_for_lessons: [
      `수업 신청`,
      `Book Lesson`,
      's_unassigned_lessons.confirm.title_register_for_lessons',
      `申请课程`,
      `申請課程`,
      `レッスン予約`,
      `Đặt lịch học`,
    ],
    registration_has_been_successful: [
      `수업이 신청되었습니다.`,
      `Your booking has been submitted`,
      's_unassigned_lessons.confirm.registration_has_been_successful',
      `课程已申请。`,
      `課程申請完成。`,
      `レッスンを予約しました。`,
      `Đăng ký của bạn đã được gửi`,
    ],
    has_failed: [
      `수업 신청에 실패하였습니다.`,
      `Your booking has failed.`,
      's_unassigned_lessons.confirm.has_failed',
      `课程申请失败。`,
      `課程申請失敗。`,
      `レッスン予約に失敗しました。`,
      `Đăng ký của bạn không thành công.`,
    ],
    no_available_credit: [
      `사용 가능한 수업권이 없습니다.`,
      `We're sorry - your booking didn't go through. Please try again.`,
      's_unassigned_lessons.confirm.no_available_credit',
      `没有可使用的课程券。`,
      `無可使用的課程券。`,
      `使用可能なレッスンチケットはありません。`,
      `Xin lỗi bạn - đăng ký chưa hoàn thành. Vui lòng thử lại.`,
    ],
    ok: [`확인`, `Ok`, 's_unassigned_lessons.confirm.ok', `确认`, `確認`, `確認`, `Ok`],
  },
  warning: {
    check_if_you_agree: [
      `유의사항`,
      `Terms and Conditions`,
      's_unassigned_lessons.warning.check_if_you_agree',
      `注意事项`,
      `注意事項`,
      `注意事項`,
      `Chú ý`,
    ],
    not_restore_if_you_cancel: [
      `당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.`,
      `The credit used for booking the last-minute lesson will not be returned even if you cancel or miss the lesson.`,
      's_unassigned_lessons.warning.not_restore_if_you_cancel',
      `取消当日课程或缺席时，将扣除课程券。`,
      `取消當日課程或缺席時，將扣除課程券。`,
      `当日レッスンはレッスンキャンセルおよび無断欠席時にレッスンチケットが差し引かれます。`,
      `Với Buổi học trong ngày, thẻ học vẫn bị trừ khi hủy buổi học hoặc không tham gia.`,
    ],
    cannot_change_tutor_or_time: [
      `당일수업은 튜터 및 시간 변경이 불가합니다.`,
      `You cannot change the tutor or time slot for last-minute lessons.`,
      's_unassigned_lessons.warning.cannot_change_tutor_or_time',
      `当日课程不可以更改课教师和时间。`,
      `當日課程不可以更改課教師和時間。`,
      `当日レッスンはチューターおよび時間の変更ができません。`,
      `Với Buổi học trong ngày, bạn không thể thay đổi gia sư và thời gian học.`,
    ],
    auto_matching_after_tutor_canceled: [
      `당일수업의 튜터는 상황에 따라 변경될 수 있습니다.`,
      `The tutor for the last-minute lesson is subject to change.`,
      's_unassigned_lessons.warning.auto_matching_after_tutor_canceled',
      `当日课程的任课教师根据情况会有所变动。`,
      `當日課程的任課教師根據情況會有所變動。`,
      `当日レッスンのチューターは状況によって変更されることがあります。`,
      `Gia sư của buổi học trong ngày có thể bị thay đổi tùy tình huống.`,
    ],
    use_a_regular_credit: (credit) => [
      <>해당 수업은 정규수업권으로 신청됩니다. (+{credit} 포인트가 추가됩니다.)</>,
      <>This lesson will use a regular lesson credit. +${financial(credit / DOLLARS_TO_KRW)}P will be added.</>,
      's_unassigned_lessons.warning.use_a_regular_credit',
      <>该课程使用正规课程券进行申请。(追加+{credit}积分。)</>,
      <>該課程使用正規課程券申請。（新增+${credit}P。）</>,
      <>このレッスンは、レギュラーレッスンチケットで予約します。(+{credit} ポイントが付与されます。)</>,
      <>Buổi học này sử dụng Thẻ học tiêu chuẩn. +${financial(credit / DOLLARS_TO_KRW)}P sẽ được thêm vào.</>,
    ],
    use_a_regular_credit_mulcam: [
      <>해당 수업은 정규수업권으로 신청됩니다.</>,
      <>This lesson will use a regular lesson credit.</>,
      's_unassigned_lessons.warning.use_a_regular_credit_mulcam',
      <>该课程使用正规课程券进行申请。</>,
      <>該課程使用正規課程券申請。</>,
      <>このレッスンは、レギュラーレッスンチケットで予約します。</>,
      <>Buổi học này sử dụng Thẻ học tiêu chuẩn.</>,
    ],
  },
  has_not_unassigned_lessons: [
    `현재 예약 가능한 당일수업이 없습니다.`,
    `Sorry, there are currently no lessons available within 24 hours.`,
    's_unassigned_lessons.has_not_unassigned_lessons',
    `当前没有可预约的24小时内课程。`,
    `目前沒有可預約的24小時以內的課程。`,
    `現在予約可能な24時間以内のレッスンはありません。`,
    `Xin lỗi, hiện tại không có buổi học nào trống trong 24 giờ tới.`,
  ],
  remaining_time: (n) => [
    <>{n}분 후 수업 시작</>,
    <>Lesson begins in {n} minutes</>,
    's_unassigned_lessons.remaining_time',
    <>{n}分后开始课程</>,
    <>{n}分后開始課程</>,
    <>{n}分後レッスン開始</>,
    <>Bắt đầu buổi học sau {n} phút</>,
  ],
  step_info_1: [
    `STEP 1. 수업 선택`,
    `STEP 1. Select Lesson`,
    's_unassigned_lessons.step_info_1',
    `STEP 1.课程选择`,
    `STEP 1.課程選擇`,
    `STEP 1.レッスンを選択`,
    `BƯỚC 1. Chọn buổi học`,
  ],
  step_info_2: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select Lesson Material`,
    's_unassigned_lessons.step_info_2',
    `STEP 2.教材选择`,
    `STEP 2.教材選擇`,
    `STEP 2.教材を選択`,
    `BƯỚC 2. Chọn tài liệu`,
  ],
  step_info_3: [
    `STEP 3. 수업권 선택  & 예약 확인`,
    `STEP 3. Select Lesson Credit & Confirm Booking`,
    's_unassigned_lessons.step_info_3',
    `STEP 3.选择课程券&确认预约`,
    `STEP 3.選擇課程券&確認預約`,
    `STEP 3.レッスンチケット選択＆予約確認`,
    `BƯỚC 3. Chọn thẻ học & Xác nhận đặt`,
  ],
  regular_credit_choice_40_min: [
    `정규수업권으로 수업 예약 시 ￦3,000P 추가 적립`,
    `Additional $2.31P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_40_min',
    `使用正规课程券预约课程时，额外累计积分$2.31P`,
    `使用正規課程券預約課程時，額外累計點數$2.31P`,
    `レギュラーレッスンチケットでレッスン予約時$2.31P追加`,
    `Khi đặt buổi học bằng thẻ học tiêu chuẩn bạn tích lũy được $2.31P`,
  ],
  regular_credit_choice_20_min: [
    `정규수업권으로 수업 예약 시 ￦1,000P 추가 적립`,
    `Additional $0.77P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_20_min',
    `使用正规课程券预约课程时，额外累计积分$0.77P`,
    `使用正規課程券預約課程時，額外累計點數$0.77P`,
    `レギュラーレッスンチケットでレッスン予約時$0.77P追加`,
    `Khi đặt buổi học bằng thẻ học tiêu chuẩn bạn tích lũy được $0.77P`,
  ],
  regular_credit_choice_point_info_40_min: [
    <>
      정규수업권으로 사용으로 <span className="text-purple-500">￦3,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-purple-500">$2.31P</span> rewarded for using a regular credit.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_40_min',
    <>
      额外累积积分<span className="text-purple-500">$2.31P</span>
    </>,
    <>
      額外累積點數<span className="text-purple-500">$2.31P</span>
    </>,
    <>
      <span className="text-purple-500">$2.31P</span>が追加されました。
    </>,
    <>
      Bạn đã tích lũy thêm <span className="text-purple-500">$2.31P</span>
    </>,
  ],
  regular_credit_choice_point_info_20_min: [
    <>
      정규수업권으로 사용으로 <span className="text-purple-500">￦1,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-purple-500">$0.77P</span> rewarded for using a regular credit.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_20_min',
    <>
      额外累积积分<span className="text-purple-500">$0.77P</span>
    </>,
    <>
      額外累積點數<span className="text-purple-500">$0.77P</span>
    </>,
    <>
      <span className="text-purple-500">$0.77P</span>が追加されました。
    </>,
    <>
      Bạn đã tích lũy thêm <span className="text-purple-500">$0.77P</span>
    </>,
  ],
  exit: [`나가기`, `Leave`, 's_unassigned_lessons.exit', `关闭`, `關閉`, `退出`, `Thoát`],
  credit_select: [
    `수업권 선택`,
    `Select Lesson Credit`,
    's_unassigned_lessons.credit_select',
    `课程券选择`,
    `課程券選擇`,
    `レッスンチケット選択`,
    `Chọn thẻ học`,
  ],
  alarm_setting: [
    `예약 가능시 알림 받기`,
    `Notify me about updates`,
    's_unassigned_lessons.alarm_setting',
    `可预约时接收通知提醒。`,
    `可預約時接收通知提醒。`,
    `予約可能な際にお知らせを受け取る`,
    `Thông báo thời gian đặt lớp`,
  ],
  ask_unassigned_alarm_turned_on: [
    `당일수업이 예약 가능할 때 앱 푸시를 받으시겠습니까?`,
    `Would you like to receive an app push notification when a last-minute lesson becomes available?`,
    's_unassigned_lessons.ask_unassigned_alarm_turned_on',
    `可预约当天课程时，需要接收 App 消息推送吗？`,
    `可預約當天課程時，需要接收 App 消息推送嗎？`,
    `当日レッスン予約が可能な時にプッシュ通知を受け取りますか？`,
    `Nhận thông báo khi có thể đặt lớp trong ngày?`,
  ],
  already_unassigned_alarm_turned_on: [
    `이미 당일수업 업데이트 알림이 설정되어 있습니다.`,
    `You are already receiving notifications on last-minute lessons.`,
    's_unassigned_lessons.already_unassigned_alarm_turned_on',
    `已设置当天课程更新提醒。`,
    `已設置當天課程更新提醒。`,
    `すでに当日レッスンのアップデートのお知らせが設定されています。`,
    `Bạn đã cài đặt nhận thông báo lớp trong ngày.`,
  ],
  alrarm_turned_on_succ_toast: [
    `당일 수업 알림을 신청했어요.`,
    `Enabled Notifications on last-minute lessons`,
    's_unassigned_lessons.alrarm_turned_on_succ_toast',
    `已完成当天课程提醒申请。`,
    `已完成當天課程提醒申請。`,
    `当日レッスンのお知らせの申し込みが完了しました。`,
    `当日レッスンのお知らせの申し込みが完了しました。`,
  ],
}
export const s_schedule = {
  exit: [`나가기`, `Leave`, 's_schedule.exit', `关闭`, `關閉`, `戻る`, `Thoát`],
  change_timezone: [
    `타임존 변경`,
    `Change time zone`,
    's_schedule.change_timezone',
    `时区变更`,
    `變更時區`,
    `タイムゾーン変更`,
    `Đổi múi giờ`,
  ],
  valid_n_days: (n) => [
    <>수강 기간: {n}일 남음</>,
    <>Expires in: {n} days</>,
    's_schedule.valid_n_days',
    <>上课时间：还有{n}天</>,
    <>上課時間：還有{n}天</>,
    <>レッスン時間: 残り{n}日</>,
    <>Thời gian học: Còn {n} ngày</>,
  ],
  valid_from_to_days: (from, to) => [
    `수강기간 ${from} ~ ${to}일`,
    `Valid from ${from} to ${to}`,
    's_schedule.valid_from_to_days',
    `使用期限${from} ~ ${to}日`,
    `使用期限：${from} ~ ${to}日`,
    `有効期限${from} ~ ${to}日`,
    `Có hiệu lực từ ${from} đến ${to}`,
  ],
  unused_credits: [
    `미사용 수업권`,
    `Unused Lesson Credits`,
    's_schedule.unused_credits',
    `未使用的课程券`,
    `未使用的課程券`,
    `未使用レッスンチケット`,
    `Số thẻ học chưa dùng`,
  ],
  please_click_button_to_select_material: [
    `다음을 눌러 교재를 선택하세요`,
    ``,
    's_schedule.please_click_button_to_select_material',
    `请点击下一步选择教材`,
    `請點擊繼續選擇教材`,
    `次へを押して教材を選択してください。`,
    ``,
  ],
  please_select_more_than_one_lesson: [
    `1개 이상 수업을 신청하세요`,
    `Please select more than one lesson.`,
    's_schedule.please_select_more_than_one_lesson',
    `请申请至少1个以上的课程`,
    `請申請1個以上的課程`,
    `1回以上レッスンを予約してください。`,
    `Vui lòng chọn nhiều hơn một buổi học.`,
  ],
  select_credit: [
    `수업권 선택`,
    `Select Credits`,
    's_schedule.select_credit',
    `选择课程券`,
    `選擇課程券`,
    `レッスンチケット選択`,
    `Lựa chọn Thẻ học`,
  ],
  please_select_the_time_and_tutors: [
    `시간과 튜터를 선택해 주세요.`,
    `Please select time and tutors.`,
    's_schedule.please_select_the_time_and_tutors',
    `请选择时间及教师。`,
    `請選擇時間及任課教師。`,
    `時間とチューターを選択してください。`,
    `Lựa chọn thời gian và gia sư.`,
  ],
  automatic_matching_for_tutor: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_schedule.automatic_matching_for_tutor',
    `自动匹配教师`,
    `自動配對任課教師`,
    `チューター自動マッチング`,
    `Xếp ngẫu nhiên tôi với một gia sư`,
  ],
  ringle_automatic_matching: [
    `링글 자동 매칭`,
    `Auto matching`,
    's_schedule.ringle_automatic_matching',
    `Ringle自动匹配`,
    `Ringle自動配對`,
    `Ringle自動マッチング`,
    `Xếp ngẫu nhiên`,
  ],
  ringle_will_match_appropriate_tutor: [
    `예약 가능한 튜터 중, 평점이 높고 성실한 튜터와 자동으로 매칭해 드려요.`,
    `We'll find the best tutor available for you.`,
    's_schedule.ringle_will_match_appropriate_tutor',
    `在可预约的任课教师中，自动为您分配安排评分高、诚实可信的任课教师。`,
    `在可預約的任課教師中，自動為您分配安排評分高、誠實可信的任課教師。`,
    `予約可能な最高のチューターとマッチング`,
    `‘Chúng tôi sẽ ghép bạn vơi gia sư tốt nhất có khả năng hẹn sẵn trước.’`,
  ],
  recommend: [`추천`, `Featured`, 's_schedule.recommend', `推荐`, `推薦`, `おすすめ`, `Nổi bật`],
  please_select_material: [
    `교재를 선택해 주세요`,
    `Please select lesson materials.`,
    's_schedule.please_select_material',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください。`,
    `Vui lòng chọn tài liệu học.`,
  ],
  click_button_to_go_to_the_next_step: [
    `버튼을 누르면 다음 단계로 넘어갑니다.`,
    ``,
    's_schedule.click_button_to_go_to_the_next_step',
    `点击按键, 进行下一步。`,
    `點擊按鈕時，前往下一步驟。`,
    `ボタンを押すと次のステップに進みます。`,
    ``,
  ],
  previous: [`이전`, `Back`, 's_schedule.previous', `上一步`, `上一步`, `戻る`, `Trước`],
  next: [`다음`, `Next`, 's_schedule.next', `下一步`, `下一步`, `次へ`, `Tiếp theo`],
  do_you_want_to_cancel_lesson: [
    `이 수업을 취소하시겠습니까?`,
    `Do you want to cancel this lesson?`,
    's_schedule.do_you_want_to_cancel_lesson',
    `您确认要取消此课程吗?`,
    `確認要取消此課程嗎？`,
    `このレッスンをキャンセルしますか？`,
    `Bạn có muốn huỷ buổi học này?`,
  ],
  the_material_is_not_selected: [
    `교재를 선택해주세요`,
    `Please select the lesson material you wish to use.`,
    's_schedule.the_material_is_not_selected',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください`,
    `Hãy chọn tài liệu`,
  ],
  go_to_the_previous_step_cuz_no_lessons: [
    `신청한 수업이 없어서 이전 단계로 이동합니다`,
    `Since there is no booked lesson, you're moving to the previous step.`,
    's_schedule.go_to_the_previous_step_cuz_no_lessons',
    `没有申请的课程, 请返回上一步。`,
    `未申請課程，前往上一步驟。`,
    `予約したレッスンがないため、前のステップに移動します。`,
    `Vì chưa có buổi học nào được đặt, bạn sẽ được chuyển về trang trước.`,
  ],
  counter_of_selected_materials: (materialCount, completedCount) => [
    `교재 선택 : ${materialCount} / ${completedCount} 개`,
    `Selected materials: ${materialCount} / ${completedCount}`,
    's_schedule.counter_of_selected_materials',
    `选择教材 : ${materialCount} / ${completedCount} 个`,
    `選擇教材：${materialCount}／${completedCount}個`,
    `選択した教材 : ${materialCount} / ${completedCount} 個`,
    `Tài liệu đã chọn: ${materialCount} / ${completedCount}`,
  ],
  an_error_occurred_during_the_process: [
    `신청 과정에서 오류가 발생했습니다.`,
    `An error occurred during the process.`,
    's_schedule.an_error_occurred_during_the_process',
    `申请过程中出现错误。`,
    `申請過程中發生錯誤。`,
    `予約過程でエラーが発生しました。`,
    `Đã có lỗi xảy ra.`,
  ],
  btn_register: [`신청하기`, `Book`, 's_schedule.btn_register', `申请`, `申請`, `予約する`, `Đặt lịch`],
  complete: [
    `신청 완료`,
    `Book Lesson`,
    's_schedule.complete',
    `申请完成`,
    `申請完成`,
    `申請完了`,
    `Đăng ký thành công`,
  ],
  after_cancel_auto_matching_unavailable: [
    `현재 가능한 튜터가 없어서, 취소 후 자동 매칭 기능은 제공되지 않습니다.`,
    `We couldn't match you to a tutor.`,
    's_schedule.after_cancel_auto_matching_unavailable',
    `目前没有符合条件的教师, 取消后将不再提供自动匹配功能。`,
    `目前沒有可以的教師，取消後將不提供自動配對功能。`,
    `現在予約可能なチューターがいないため、キャンセル後に自動マッチング機能は提供されません。`,
    `Chúng tôi không thể sắp xếp bạn với gia sư.`,
  ],
  the_past_time: [
    `지난 시간 입니다.`,
    `Sorry, you cannot book a past lesson.`,
    's_schedule.the_past_time',
    `已错过预约时间。`,
    `已過的時間。`,
    `予約可能時間ではありません。`,
    `Vui lòng chọn thời gian trong tương lai.`,
  ],
  you_cannot_register_within_26_hours: [
    `현재 시간 기준 26시간 안에는 신청 할 수 없습니다`,
    `Book lessons starting in 26h or later.`,
    's_schedule.you_cannot_register_within_26_hours',
    `以当前时间为基准, 24小时内无法提供申请。`,
    `以目前時間為準，24小時內無法申請。`,
    `現在時刻から24時間以内は予約することができません。`,
    `Bạn không thể đặt trước 24 giờ kể từ bây giờ.`,
  ],
  you_cannet_register_with_the_selected_credits: [
    `선택된 수업권으로는 신청하실 수 없습니다.`,
    `You cannot use selected lesson credit.`,
    's_schedule.you_cannet_register_with_the_selected_credits',
    `选择的课程券无法进行申请。`,
    `無法使用選擇的課程券申請。`,
    `選択したレッスンチケットでは予約することができません。`,
    `Bạn không thể sử dụng thẻ học này.`,
  ],
  you_are_out_of_credits_please_choose_another_credit: [
    `남은 수업권이 없습니다. 다른 수업권을 선택해 주세요.`,
    `You are out of lesson credits.`,
    's_schedule.you_are_out_of_credits_please_choose_another_credit',
    `没有剩余的课程券。请选择其他课程券。`,
    `沒有剩餘的課程券。請選擇其他課程券。`,
    `残っているレッスンチケットはありません。他のレッスンチケットを選択してください。`,
    `Bạn đã hết thẻ học.`,
  ],
  credit: [`수업권`, `Credit`, 's_schedule.credit', `课程券`, `課程券`, `レッスンチケット`, `Thẻ học`],
  change_registration_mode: [
    `신청 방식 변경`,
    `Change sorting`,
    's_schedule.change_registration_mode',
    `申请方式变更`,
    `變更申請方式`,
    `予約方式変更`,
    `Đổi cách sắp xếp`,
  ],
  select_time_first: [
    `시간 먼저 선택`,
    `Sort by time`,
    's_schedule.select_time_first',
    `请先选择时间`,
    `先選擇時間`,
    `時間帯から選ぶ`,
    `Sắp xếp theo thời gian`,
  ],
  select_tutor_first: [
    `튜터 먼저 선택`,
    `Sort by tutor`,
    's_schedule.select_tutor_first',
    `请先选择教师`,
    `先選擇任課教師`,
    <>チューターから選ぶ</>,
    `Sắp xếp theo gia sư`,
  ],
  are_you_sure_you_want_to_delete_this_lesson: [
    `이 수업을 삭제하시겠습니까?`,
    `Are you sure you want to cancel this lesson?`,
    's_schedule.are_you_sure_you_want_to_delete_this_lesson',
    `您确定要删除此课程吗?`,
    `確定要刪除此課程嗎？`,
    `このレッスン予約を取り消しますか？`,
    `Bạn có chắc muốn huỷ buổi học này?`,
  ],
  lesson_reserved: [
    `수업 예정`,
    `Booked`,
    's_schedule.lesson_reserved',
    `预定课程`,
    `預定的課程`,
    `レッスン予定あり`,
    `Đã đặt lịch`,
  ],
  selected: [`선택 완료`, `Selected`, 's_schedule.selected', `选择完成`, `選擇完畢`, `選択済`, `Đã chọn`],
  select_tutor: [
    `튜터 선택`,
    `Select a tutor`,
    's_schedule.select_tutor',
    `选择教师`,
    `選擇教師`,
    `チューター選択`,
    `Lựa chọn gia sư`,
  ],
  selectable: [`선택 가능`, `Available`, 's_schedule.selectable', `可进行选择`, `可選擇`, `選択可能`, `Lịch trống`],
  unavailable: [`신청 불가`, `Unavailable`, 's_schedule.unavailable', `无法申请`, `無法申請`, `予約不可`, `Không có`],
  reserve_closed: [
    `예약 마감`,
    <>Booking Closed</>,
    's_schedule.reserve_closed',
    `结束预约`,
    `預約截止`,
    `予約締切`,
    <>Đã đóng đặt lịch</>,
  ],
  not_service_hours: [
    `서비스 운영 시간이 아닙니다`,
    `Ringle is not open yet.`,
    's_schedule.not_service_hours',
    `非服务运营时间`,
    `非服務營運時間`,
    `サービスご利用時間外です`,
    `Hiện đã hết giờ làm việc của Ringle.`,
  ],
  view_profiles: [
    `프로필 보기`,
    `View profiles`,
    's_schedule.view_profiles',
    `查看简介`,
    `查看簡介`,
    `プロフィールを見る`,
    `Xem thông tin cá nhân`,
  ],
  please_register_for_lessons_by_select_the_desired_time_on_calender: [
    <>
      캘린더에서 원하시는 시간을 눌러
      <br />
      수업을 신청해 주세요.
    </>,
    `Please select time on calendar.`,
    's_schedule.please_register_for_lessons_by_select_the_desired_time_on_calender',
    `请点击日历上希望的时间进行课程申请。`,
    `請點擊希望的時間申請課程。`,
    `カレンダー上でご希望の時間帯を選んでください`,
    `Hãy chọn thời gian trên lịch.`,
  ],
  not_able_to_fetch_tutors_info: [
    `튜터 정보를 가져오지 못했습니다.`,
    `We were not able to fetch this tutor's information.`,
    's_schedule.not_able_to_fetch_tutors_info',
    `无法获取教师信息。`,
    `無法讀取任課教師資訊。`,
    `チューター情報を読み込めませんでした。`,
    `Chúng tôi không thể lấy thông tin từ gia sư này.`,
  ],
  gender: [`성별`, `Gender`, 's_schedule.gender', `性别`, `性別`, `性別`, `Giới tính`],
  accent: [`억양`, `Accent`, 's_schedule.accent', `口音`, `口音`, `アクセント`, `Giọng`],
  major: [`전공`, `Major`, 's_schedule.major', `专业`, `主修`, `専攻`, `Chuyên môn`],
  available_tutor: [
    `예약 가능`,
    `Available`,
    's_schedule.available_tutor',
    `预约可能`,
    `可預約`,
    `予約可能`,
    `Lịch trống`,
  ],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_schedule.recommended_tutor',
    `教师推荐`,
    `推薦的教師`,
    `おすすめ`,
    `Nổi bật`,
  ],
  saved_tutor: [
    `찜한 튜터`,
    `Favorite Tutors`,
    's_schedule.saved_tutor',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入り`,
    `Yêu thích`,
  ],
  tutors: [`튜터 수`, `Tutors`, 's_schedule.tutors', `教师人数`, `任課教師人數`, `チューター数`, `Gia sư`],
  my_tutors: [
    `찜한 튜터`,
    `Favorite Tutors`,
    's_schedule.my_tutors',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入り`,
    `Yêu thích`,
  ],
  tutors_available_in_this_time: [
    `선택한 시간에 수업 가능한 튜터들입니다.`,
    `Tutors available at selected time`,
    's_schedule.tutors_available_in_this_time',
    `于选择的时间可进行课程的教师。`,
    `您選擇的時間可授課的任課教師。`,
    `選択した時間帯にレッスン可能なチューターです。`,
    `Gia sư trống lịch trong thời gian được chọn`,
  ],
  tutors_unavailable_in_this_time: [
    `선택한 시간에 수업 예약이 마감된 튜터들입니다.`,
    `Tutors unavailable at selected time`,
    's_schedule.tutors_unavailable_in_this_time',
    `于选择的时间已结束预约的教师。`,
    `您選擇的時間課程預約已截止的任課教師。`,
    `選択した時間帯のレッスン予約が締め切られたチューターです。`,
    `Gia sư không thể dạy trong thời gian được chọn`,
  ],
  tutors_available_you_gave_a_good_rating: [
    <>
      추천 튜터는 최대 5명까지만 노출됩니다.
      <br />더 많은 튜터를 [예약 가능] 탭에서 확인해보세요!
    </>,
    <>
      Shows up to five featured tutors.
      <br />
      Please check out the [Available] tab to view more tutors!
    </>,
    's_schedule.tutors_available_you_gave_a_good_rating',
    `<>
最多只显示5名推荐任课教师。<br />请在【可预约】标签中查看更多的任课教师！
</>`,
    `<>
最多隻顯示5名推薦任課教師。<br />請在【可預約】標籤中查看更多的任課教師！
</>`,
    `<>おすすめチューターは最大５名まで見ることが出来ます。<br />より多くのチューターを［予約可能］タブからご覧ください！</>`,
    `<>Chỉ hiển thị tối đa 5 gia sư được gợi ý.<br />Hãy tìm hiểu nhiều gia sư khác trong mục [Có thể đặt]!</>`,
  ],
  tutors_youve_liked_that_are_available: [
    `선택한 시간에 수업 가능한 찜한 튜터들입니다.`,
    `Favorite tutors available at selected time`,
    's_schedule.tutors_youve_liked_that_are_available',
    `于选择的时间, 可进行课程的收藏教师。`,
    `您選擇的時間可授課且是您收藏的任課教師。`,
    `選択した時間にレッスン可能なお気に入りチューターです。`,
    `Gia sư yêu thích trống lịch trong thời gian được chọn`,
  ],
  add_more_tutor_my_tutor: [
    `찜한 튜터입니다. 더 다양한 튜터를 찾아보세요!`,
    `Browse more Ringle tutors!`,
    's_schedule.add_more_tutor_my_tutor',
    `收藏的教师列表。 请查找更多的教师吧!`,
    `您收藏的任課教師清單，請尋找更多任課教師！`,
    `お気に入りチューターリストです。より多様なチューターを探してみましょう！`,
    `Xem thêm gia sư của Ringle!`,
  ],
  add_more_tutor_available: [
    `수업 가능한 튜터 중, 내게 꼭 맞는 튜터를 찾아보세요!`,
    `Browse more available tutors to find your perfect fit!`,
    's_schedule.add_more_tutor_available',
    `于可进行课程的教师中, 找到最适合我的教师吧!`,
    `從可授課的任課教師中，尋找最適合我的任課教師吧！`,
    `レッスン可能なチューターのうち、自分にぴったり合うチューターを探しましょう！`,
    `Tìm thêm các gia sư đang trống lịch để chọn người phù hợp với bạn!`,
  ],
  find_a_tutor: [
    `튜터 찾기`,
    `Search tutors`,
    's_schedule.find_a_tutor',
    `查找教师`,
    `尋找任課教師`,
    `チューター検索`,
    `Tìm gia sư`,
  ],
  the_tutors_you_gave_a_good_raitings: [
    `링글에서 추천하는 신규 튜터들입니다.`,
    `Featured tutors`,
    's_schedule.the_tutors_you_gave_a_good_raitings',
    `Ringle推荐的新任教师。`,
    `Ringle推薦的最新任課教師。`,
    `Ringleがおすすめする新規チューターです。`,
    `Gia sư nổi bật`,
  ],
  there_is_no_credit_available_you_can_register_for_lesson_after_purchase: [
    `신청 가능한 수업권이 없습니다. 수업권 구매 후 수업 신청하실 수 있습니다.`,
    `You have no lesson credits. Please book lessons after adding more credits.`,
    's_schedule.there_is_no_credit_available_you_can_register_for_lesson_after_purchase',
    `没有可申请的课程券。购买课程券后可完成课程申请。`,
    `沒有可申請的課程券，購買課程券後可申請課程。`,
    `予約可能なレッスンチケットはありません。レッスンチケット購入後にレッスンを予約することができます。`,
    `Bạn không còn thẻ học. Vui lòng đặt lịch học sau khi mua thêm thẻ học mới.`,
  ],
  the_selected_lesson_starts_within_48_hours: [
    `선택하신 수업은 48시간 이내에 시작하는 수업입니다. 튜터와의 시차 및 응답 속도에 따라 매칭되지 않을 가능성이 높습니다.`,
    `This lesson starts in less than 48 hours. Tutor may not confirm in time.`,
    's_schedule.the_selected_lesson_starts_within_48_hours',
    `选择的课程于48小时内开始。根据与教师所在地区的时差和应答速度, 无法进行匹配的可能性较大。`,
    `您選擇的課程為48小時以內即將開始的課程，依據與任課教師的時差與回覆速度，很有可能配對失敗。`,
    `選択したレッスンは48時間以内に始まるレッスンです。チューターとの時差および返答スピードからマッチングしない可能性が高いです。`,
    `Buổi học này bắt đầu trong vòng 48 giờ nữa. Gia sư có thể không kịp xác nhận.`,
  ],
  AM_hour: (h) => [`오전 ${h}시`, `AM ${h}`, 's_schedule.AM_hour', `上午 ${h}点`, `上午${h}點`, `午前 ${h}`, `AM ${h}`],
  PM_hour: (h) => [`오후 ${h}시`, `PM ${h}`, 's_schedule.PM_hour', `下午 ${h}点`, `下午${h}點`, `午後 ${h}`, `PM ${h}`],
  thirty_min: [`30분`, `:30`, 's_schedule.thirty_min', `30分钟`, `30分`, `30分`, `:30`],
  o_clock: [`시`, `:00`, 's_schedule.o_clock', ``, ``, `時`, `:00`],
  select_available_tutor: [
    `튜터 직접 선택`,
    `Tutors available at selected time`,
    's_schedule.select_available_tutor',
    `直接选择教师`,
    `直接選擇教師`,
    `チューターを選択`,
    `Những gia sư trống lịch vào thời gian được chọn`,
  ],
  modal: {
    no_credit: [
      `수업권 미보유`,
      `No Credit`,
      's_schedule.modal.no_credit',
      `未持有课程券`,
      `未持有課程券`,
      `レッスンチケット未保有`,
      `Không có thẻ học`,
    ],
    after_purchasing: [
      `수업권 구매 후 이용 가능합니다.`,
      `Available after purchasing lesson credits.`,
      's_schedule.modal.after_purchasing',
      `购买课程券后可使用。`,
      `購買課程券後可使用。`,
      `レッスンチケット購入後に利用可能です。`,
      `Chỉ có sau khi thanh toán thẻ học.`,
    ],
    purchase: [
      `구매하러 가기`,
      `Purchase`,
      's_schedule.modal.purchase',
      `前往购买`,
      `前往購買`,
      `購入する`,
      `Thanh toán`,
    ],
    title: [
      `추천 튜터 수업 안내`,
      `Lessons with featured tutors`,
      's_schedule.modal.title',
      `推荐任课教师课程介绍`,
      `推薦任課教師課程介紹`,
      `おすすめチューターレッスン案内`,
      `Hướng dẫn buổi học gia sư được đề xuất`,
    ],
  },
  get_matched_with_other_tutors: [
    `다른 튜터 매칭 받기`,
    `Match me with another tutor`,
    's_schedule.get_matched_with_other_tutors',
    `接受与其他教师配对`,
    `接受與其他教師配對`,
    `他のチューターとマッチングしてもらう`,
    `Tự động xếp tôi với một gia sư khác.`,
  ],
  check_other_times_for_the_selected_tutor: [
    `선택한 튜터의 다른 시간 확인하기`,
    `Show availability of this tutor`,
    's_schedule.check_other_times_for_the_selected_tutor',
    `确认所选教师的其他时间`,
    `確認所選的任課教師其他時間`,
    `選択したチューターの別時間帯を確認する`,
    `Xem lịch trống của gia sư này`,
  ],
  recovering_a_used_class_ticket_after_canceling: [
    `수업 취소 후 사용한 수업권 복구받기`,
    `Restore my lesson credit`,
    's_schedule.recovering_a_used_class_ticket_after_canceling',
    `课程取消后课程券返还`,
    `取消課程後復原已使用的課程券`,
    `予約をキャンセルし、使用したレッスンチケットを復元する`,
    `Khôi phục thẻ học của tôi`,
  ],
  tutor_matching_options: [
    `튜터 매칭 옵션`,
    `Tutor matching options`,
    's_schedule.tutor_matching_options',
    `教师配对选项`,
    `教師配對選項`,
    `チューターマッチングオプション`,
    `Lựa chọn sắp xếp gia sư`,
  ],
  check_class_reservation_history: [
    `수업 예약 내역 확인`,
    `Lesson Booking Details`,
    's_schedule.check_class_reservation_history',
    `Lesson Booking Details`,
    `Lesson Booking Details`,
    `Lesson Booking Details`,
    `Lesson Booking Details`,
  ],
  choose_how_to_handle_the_class_in_the_tutor_matching: [
    <>
      <Div textPrimary>수업 정보</Div>와{' '}
      <Div textPrimary mx4>
        튜터 매칭 옵션
      </Div>
      확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please review{' '}
      <Div textPrimary mx4>
        the lesson information
      </Div>
      and{' '}
      <Div textPrimary ml4>
        tutor matching option
      </Div>
      .{' '}
    </>,
    's_schedule.choose_how_to_handle_the_class_in_the_tutor_matching',
    <>
      请确认<Div textPrimary>课程信息</Div>和<Div textPrimary>教师配对选项</Div>之后完成预约.
    </>,
    <>
      請確認<Div textPrimary>課程信息</Div>和<Div textPrimary>教師配對選項</Div>之後完成預約.
    </>,
    <>
      <Div textPrimary>レッスン内容</Div>と<Div textPrimary>チューターマッチングのオプション</Div>
      を確認後、予約を完了してください。
    </>,
    <>
      Vui lòng kiểm tra lại các{' '}
      <Div textPrimary mx4>
        thông tin buổi học
      </Div>{' '}
      và{' '}
      <Div textPrimary mx4>
        sự lựa chọn gia sư.
      </Div>
    </>,
  ],
  classes_for_which_reservations: [
    `예약 신청한 수업`,
    `Selected Lesson`,
    's_schedule.classes_for_which_reservations',
    `预约申请的课程`,
    `已申請預約的課程`,
    `予約確定前レッスン`,
    `Buổi học đã chọn`,
  ],
  class_reservation: [
    `수업 예약`,
    `Book lesson`,
    's_schedule.class_reservation',
    `课程预约`,
    `預約課程`,
    `レッスン予約`,
    `Đặt lịch học`,
  ],
  book_another_class: [
    `다른 수업 예약하기`,
    `Book Another Lesson`,
    's_schedule.book_another_class',
    `预约其它课程`,
    `預約其它課程`,
    `次のレッスンを予約`,
    `Đặt buổi học khác`,
  ],
  set_up_a_class: [
    `수업 방식 설정하기`,
    `Select Lesson Style`,
    's_schedule.set_up_a_class',
    `设置课程方式`,
    `設置課程方式`,
    `レッスン方式を設定`,
    `Cài đặt phương thức học`,
  ],
  class_reservation_results: [
    `수업 예약 결과`,
    `Lesson Booking Details`,
    's_schedule.class_reservation_results',
    `课程预约结果`,
    `課程預約結果`,
    `レッスン予約結果`,
    `Kết quả đặt buổi học`,
  ],
  recommended_to_prepare_a_lesson_before_class: [
    `학습 효과 및 맞춤 수업을 위해 수업 전에 예습 및 수업 방식 설정을 추천합니다.`,
    `Set 'Lesson Styles' before the lesson for the best learning experience and customized lesson.`,
    's_schedule.recommended_to_prepare_a_lesson_before_class',
    `为达到最佳学习效果及体现定制型课程的优势, 建议课程开始前进行预习及设定希望的课程形式。`,
    `為了更佳的學習效果與客製化課程，建議於課程開始前設定預習及上課方式。`,
    `学習効果およびカスタマイズレッスンのために、レッスン前に予習とレッスン方式の設定をすることをおすすめします。`,
    `Cài đặt 'Hình thức học' trước buổi học để có trải nghiệm học tốt nhất và điều chỉnh buổi học phù hợp.`,
  ],
  classes_that_have_been_booked: [
    `예약 완료한 수업`,
    `Lesson(s) Successfully Booked`,
    's_schedule.classes_that_have_been_booked',
    `预约成功的课程`,
    `預約成功的課程`,
    `予約完了したレッスン`,
    `Buổi học đã đặt thành công`,
  ],
  classes_that_have_not_been_booked: [
    `예약 실패한 수업`,
    `Booking Failure`,
    's_schedule.classes_that_have_not_been_booked',
    `预约失败的课程`,
    `預約失敗的課程`,
    `予約に失敗したレッスン`,
    `Buổi học đã đặt không thành công.`,
  ],
  step3_heck_class_reservation_history: [
    `STEP 3. 수업 예약 내역 확인`,
    `STEP3. Check My Booked Lessons`,
    's_schedule.step3_heck_class_reservation_history',
    `STEP 3. 课程预约内容确认`,
    `STEP 3. 確認課程預約明細`,
    `STEP 3. レッスン予約内容を確認`,
    `BƯỚC 3. Kiểm tra những buổi học đã đặt`,
  ],
  lesson_reservation_cancel: [
    `수업 예약 취소`,
    `Cancel reservation`,
    's_schedule.lesson_reservation_cancel',
    `取消课程预约`,
    `取消課程預約`,
    `レッスン予約キャンセル`,
    `Huỷ đặt chỗ`,
  ],
  want_to_cancel_the_reservation: [
    <>
      현재까지 예약 신청중인 내역이 삭제됩니다.
      <br />
      진행중인 수업 예약을 취소하시겠습니까?
    </>,
    `Want to continue booking the lesson?`,
    's_schedule.want_to_cancel_the_reservation',
    <>
      删除至今为止预约申请中的内容。
      <br />
      确定要取消正在进行中的课程预约吗?
    </>,
    <>
      將刪除至今申請預約的明細，
      <br />
      確定要取消正在進行的課程預約嗎？
    </>,
    <>
      現在までの予約確定前の内容が削除されます。
      <br />
      予約確定前のレッスン予約を取消しますか？
    </>,
    `Bạn có muốn tiếp tục đặt buổi học?`,
  ],
  keep_booking: [
    `예약 계속하기`,
    `Continue scheduling`,
    's_schedule.keep_booking',
    `继续预约`,
    `繼續預約`,
    `予約を続ける`,
    `Tiếp tục lên lịch`,
  ],
  choose_tutor_first: [
    `튜터를 먼저 선택하세요`,
    `Select the tutor first`,
    's_schedule.choose_tutor_first',
    `请先选择教师`,
    `請先選擇任課教師`,
    `チューターを先に選択してください。`,
    `Đầu tiên hãy chọn gia sư.`,
  ],
  available_times: [
    `캘린더에 신청 가능한 시간이 표시됩니다.`,
    `Available time slots will be displayed on the calendar`,
    's_schedule.available_times',
    `日历上显示可申请的时间。`,
    `日曆上顯示可申請的時間。`,
    `カレンダーに予約可能な時間が表示されます。`,
    ``,
  ],
  tutor_and_time_selection: [
    `STEP 1. 튜터 및 시간 선택`,
    `STEP 1. Select tutor & time`,
    's_schedule.tutor_and_time_selection',
    `STEP 1. 教师及时间选择`,
    `STEP 1. 選擇任課教師及時間`,
    `STEP 1. チューターおよび時間選択`,
    `BƯỚC 1. Chọn gia sư & Thời gian`,
  ],
  left_count: [`회 남음`, `left`, 's_schedule.left_count', `次`, `次`, `回残り`, `Buổi học còn lại`],
  there_is_no_saved_tutor: [
    `찜한 튜터가 없습니다.`,
    `You haven't added any tutors to Favorite Tutors.`,
    's_schedule.there_is_no_saved_tutor',
    `没有收藏的教师`,
    `無收藏的任課教師。`,
    `お気に入りチューターはいません。`,
    `Bạn chưa chọn gia sư yêu thích!`,
  ],
  choose_material: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select lesson materials`,
    's_schedule.choose_material',
    `STEP 2. 教材选择`,
    `STEP 2. 選擇教材`,
    `STEP 2. 教材選択`,
    `BƯỚC 2. Chọn Tài liệu`,
  ],
  class_reservations_are_not_available: [
    `수업 예약 가능한 수업권이 없습니다.`,
    `You have no lesson credit.`,
    's_schedule.class_reservations_are_not_available',
    `没有可预约课程的课程券。`,
    `無可預約課程的課程券。`,
    `レッスンが予約可能なレッスンチケットはありません。`,
    `Bạn không có thẻ học còn hạn!`,
  ],
  go_to_home: [`홈으로 가기`, `Home`, 's_schedule.go_to_home', `返回主页`, `前往首頁`, `ホームに移動`, `Trang chủ`],
  check_slots: [
    `스케쥴 보기`,
    `View slots`,
    's_schedule.check_slots',
    `查看日程`,
    `查看日程`,
    `スケジュールを見る`,
    `Kiểm tra chỗ`,
  ],
  additional_available_tutors: [
    `예약 가능한 튜터가 추가될 예정입니다.`,
    `More tutors will be available soon.`,
    's_schedule.additional_available_tutors',
    `预计新增可预约的任课教师。`,
    `預計新增可預約的任課教師。`,
    `予約可能なチューターが追加される予定です。`,
    `Các gia sư trống lịch sẽ hiển thị sớm.`,
  ],
  appreciate_if_you_could_wait_for: [
    <>
      오늘 기준 2주뒤 수업은 <Span fontBold>순차적으로 오픈될 예정</Span>입니다. 조금만 기다려 주시면 감사하겠습니다.
    </>,
    <>
      We would appreciate if you could wait for <Span fontBold>more lesson slots to be opened soon.</Span>
    </>,
    's_schedule.appreciate_if_you_could_wait_for',
    <>
      以今天为基准2周后的课程 <Span fontBold>将依次开放。</Span> 希望您耐心等待.
    </>,
    <>
      從今日起2週後的課程<Span fontBold>將依序開啟，</Span>敬請耐心等待。
    </>,
    <>
      2週間後以降のレッスン予定は{' '}
      <Span font Bold>
        {' '}
        順次オープンされます。
      </Span>{' '}
      しばらくお待ちください。
    </>,
    <>
      Chúng tôi rất vui nếu bạn có thể đợi <Span fontBold>đến khi nhiều buổi học hơn được mở.</Span>
    </>,
  ],
  scheduling_progress: [
    `스케쥴 오픈 중`,
    `Scheduling in progress`,
    's_schedule.scheduling_progress',
    `日程表开放中`,
    `日程表開放中`,
    `スケジュールオープン中`,
    `Đang lên lịch`,
  ],
  temporaily_closed: [
    `임시 마감`,
    `Temporaily Closed`,
    's_schedule.temporaily_closed',
    `临时关闭`,
    `臨時關閉`,
    `臨時締め切り`,
    `Tạm thời đóng`,
  ],
  tutors_generally_open_their_lesson: [
    <>
      링글 튜터들의 경우, 주로 <Span fontBold>향후 2주까지 스케쥴을 오픈</Span>하고 있습니다.
    </>,
    <>
      Ringle <Span fontBold>tutors generally open their lesson</Span> avaliability no more than{' '}
      <Span bold>two weeks in advance.</Span>
    </>,
    's_schedule.tutors_generally_open_their_lesson',
    <>
      Ringle的教师们的日程表 <Span fontBold>只显示到2周后</Span>
    </>,
    <>
      Ringle的任課教師們通常<Span fontBold>只開啟未來2週的日程</Span>。
    </>,
    <>
      Ringleチューターは基本的に
      <Span font Bold>
        今から2週間後までのスケジュールを公開
      </Span>
      しています。
    </>,
    <>
      Gia sư <Span fontBold>Ringle thường mở lịch</Span> không quá <Span bold>hai tuần.</Span>
    </>,
  ],
  tutor_unavailable: [
    `수업 마감 안내`,
    `Tutor unavailable`,
    's_schedule.tutor_unavailable',
    `课程无法预约提示`,
    `課程無法預約提示`,
    `レッスン締め切りのご案内`,
    `Gia sư không trống lịch`,
  ],
  schedule_closed: (tutor_name) => [
    <>{tutor_name}튜터의 이번주 수업이 마감되었습니다.</>,
    <>{tutor_name}'s schedule is now closed for:</>,
    's_schedule.schedule_closed',
    `{tutor_name}教师这周的课程已全部被预约`,
    `{tutor_name}教師這周的課程已全部被預約`,
    `{tutor_name}チューターの今週のレッスンは締め切りました。`,
    `Lịch của {tutor_name} đã đóng vào:`,
  ],
  closed_time: [
    `마감된 이번주 수업기간:`,
    ``,
    's_schedule.closed_time',
    `无法预约的时间段：`,
    `無法預約的時間段：`,
    `締め切った今週のレッスン期間:`,
    ``,
  ],
  select_different_date: [
    `다른  기간의 스케쥴을 신청해주세요.`,
    `Please select a different date & time.`,
    's_schedule.select_different_date',
    `请选择其他时间段`,
    `請選擇其他時間段`,
    `別の期間のスケジュールを申請してください。`,
    `Vui lòng chọn ngày & giờ khác.`,
  ],
  slot_unavailable: [
    `수업 마감`,
    `Unavailable`,
    's_schedule.slot_unavailable',
    `无法预约`,
    `無法預約`,
    `予約締切`,
    `Không có`,
  ],
  select_tutor_matching_option: [
    `튜터와 매칭이 안 될 경우 어떻게 진행해 드릴까요?`,
    `What should we do if the tutor cancels?`,
    's_schedule.select_tutor_matching_option',
    `请选择匹配失败时的处理方式`,
    `請選擇匹配失敗時的處理方式`,
    `チューターマッチングが出来ない場合はオプション選択をしてください。`,
    `Chúng tôi nên giải quyết thế nào nếu gia sư hủy?`,
  ],
  check_tutor_matching_option: [
    `튜터 매칭 옵션을 선택해주세요.`,
    `Select the tutor matching option.`,
    's_schedule.check_tutor_matching_option',
    `请选择教师配对选项来完成预约`,
    `請選擇教師配對選項來完成預約`,
    `マッチングオプションを選択してください。`,
    `Vui lòng chọn Sắp xếp gia sư để hoàn thành đặt lịch học`,
  ],
  final_step_for_booking: [
    `수업 예약 마지막 단계입니다.`,
    `This is the final step for booking the lesson.`,
    's_schedule.final_step_for_booking',
    `现在进行预约课程的最后一步.`,
    `現在進行預約課程的最後一步.`,
    `レッスン予約の最終段階です。`,
    `Đây là bước cuối cùng để đặt lịch.`,
  ],
  select_credit_b2b: {
    b2b_title: (name, count) => [
      <>
        {name} {count}회 수업권
      </>,
      <>
        {name} {count}Lessons
      </>,
      's_schedule.select_credit_b2b.b2b_title',
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
    ],
    b2b_vaild_n_day: (day, n) => [
      <>수강기간: {day}일 남음</>,
      <>Expires in: {n}days</>,
      's_schedule.select_credit_b2b.b2b_vaild_n_day',
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
    ],
    unused_b2b_credits_1: [
      `남은 정규 수업권`,
      `Available Credits`,
      's_schedule.select_credit_b2b.unused_b2b_credits_1',
      `Available Credits`,
      `Available Credits`,
      `Available Credits`,
      `Available Credits`,
    ],
    count: (count) => [
      <>{count}</>,
      <>{count}</>,
      's_schedule.select_credit_b2b.count',
      <>{count}</>,
      <>{count}</>,
      <>{count}</>,
      <>{count}</>,
    ],
    attandance: (percent) => [
      <>출석률: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      's_schedule.select_credit_b2b.attandance',
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
    ],
    unused_b2b_credits_2: (count) => [
      <>남은 정규 수업권 {count}회</>,
      <>Available Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_credits_2',
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
    ],
    unused_b2b_makeup_credits: (count) => [
      <>남은 당일 수업권 {count}회</>,
      <>Last-minute Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_makeup_credits',
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
    ],
    attandance_notice: [
      `*출석률 반영을 위해 예약 가능한 당일수업에서 남은 당일 수업권을 사용해주세요.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice',
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
    ],
    attandance_notice_2: [
      `*출석률 반영을 위해 남은 당일 수업권을 사용해주세요.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice_2',
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
    ],
    book_makeup_lesson: [
      `당일수업 예약하기`,
      `Make-up Lessons`,
      's_schedule.select_credit_b2b.book_makeup_lesson',
      `Make-up Lessons`,
      `Make-up Lessons`,
      `Make-up Lessons`,
      `Make-up Lessons`,
    ],
  },
  refund: [
    `불만족 시 수업권 복구`,
    `Get Credit Back`,
    's_schedule.refund',
    `不满意课程时恢复课程券使用`,
    `不滿意課程時重新補發課程券`,
    `レッスンチケット復元`,
    `Đổi lại quyền buổi học`,
  ],
  refund_notice: [
    `자동 매칭 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해드립니다. (튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
    `If you are not satified with the auto-match tutor, we'll return your lesson credit. (Rate as 3 stars or lower and must submit reason for low rating, limited to twice per month)`,
    's_schedule.refund_notice',
    `若您对自动安排的任课教师不满意，我们将为您恢复课程券的使用。（任课教师评价3分以下并提交事由，每月限定2次）`,
    `若您對自動安排的任課教師不滿意，我們將為您新補發課程券。（任課教師評價3分以下並提出事由，每月限定2次）`,
    `自動マッチングチューターとのレッスン満足度が低かった場合、レッスンチケットを返還いたします。 (チューター評価が3点以下および理由を提出、月2回まで)`,
    `Nếu bạn có mức độ hài lòng thấp với một gia sư tự động kết hợp, chúng tôi sẽ khôi phục phiếu giảm vé buổi học. (Đánh giá của gia sư từ 3 điểm trở xuống và lý do được gửi, giới hạn ở 2 lần mỗi tháng)`,
  ],
  recommended_notice: [
    <>
      링글이 추천하는 튜터와 수업해보세요!
      <br />
      #높은평점 #불만족시수업권복구
    </>,
    <>
      Learn from Ringle-recommended tutors!
      <br />
      #HighlyRated #GetCreditBack
    </>,
    's_schedule.recommended_notice',
    <>
      和Ringle为您推荐的任课教师上课!
      <br />
      #高评分 #不满意课程时恢复课程券使用
    </>,
    <>
      和Ringle為您推薦的任課教師上課！
      <br />
      #高評分 #不滿意課程時重新補發課程券
    </>,
    <>
      おすすめのチューターとレッスン！
      <br />
      #HighlyRated #GetCreditBack
    </>,
    <>
      Các bạn hãy tham gia lớn học cùng với gia sư chúng tôi đã giới thiệu đi ạ!
      <br />
      #HighlyRated #GetCreditBack
    </>,
  ],
  btn_detail: [
    <>
      <span className="text-purple-500">자세히 보기</span>
    </>,
    <>
      <span className="text-purple-500">See details</span>
    </>,
    's_schedule.btn_detail',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  automatic_matching_notice: [
    <>
      <span className="text-purple-500">튜터 매칭 옵션</span>에서 [다른 튜터 매칭 받기]를 선택하시면,
      <br />
      튜터와 매칭이 안될 경우 평점이 높은 튜터와 다시 매칭해 드려요.
    </>,
    <>
      If you select [Match me with another tutor] in <span className="text-purple-500">the Tutor Matching Options</span>
      , we'll find another highly-rated tutor for you when a tutor matching falls through.
    </>,
    's_schedule.automatic_matching_notice',
    `在"安排教师”选项中选择【接受其他教师配对】，
 教师未配对成功时，重新为您安排评分高的教师。`,
    `在「安排教師」選項中選擇【接受其他教師配對】
 教師未配對成功時，重新為您安排評分高的教師。`,
    `チューターマッチングオプションから[他のチューターとマッチングしてもらう]を選ぶと
 チューターとマッチングされない場合に評価の高いチューターと再びマッチングいたします。`,
    `Nếu bạn chọn [Tự động xếp tôi với một gia sư khác.] từ các tùy chọn kết hợp gia sư, Nếu bạn không thể kết hợp với một gia sư, chúng tôi sẽ kết hợp bạn một lần nữa với một gia sư có xếp hạng cao hơn.`,
  ],
  automatic_matching_desc: [
    <>
      <span className="text-red-500">*</span>매칭된 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.
    </>,
    <>
      <span className="text-red-500">*</span>We'll give your credit back if you are not satisfied with the assigned
      tutor.
    </>,
    's_schedule.automatic_matching_desc',
    <>
      <span className="text-red-500">*</span>对安排的任课教师不满意时，将为您恢复课程券的使用。
    </>,
    <>
      <span className="text-red-500">*</span>對安排的任課教師不滿意時，將為您重新補發課程券。
    </>,
    <>
      <span className="text-red-500">*</span>
      マッチングされたチューターとのレッスン満足度が低かった場合、レッスンチケットを返還いたします。
    </>,
    <>
      <span className="text-red-500">*</span>Nếu bạn có mức độ hài lòng thấp với một gia sư phù hợp, chúng tôi sẽ khôi
      vé buổi học giá của bạn.
    </>,
  ],
  choose_automatic_matching: [
    <>
      <span className="text-purple-500">수업 정보</span> 확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please check <span className="text-purple-500">the lesson details</span> and complete the booking.
    </>,
    's_schedule.choose_automatic_matching',
    `请确认信息后完成预约。`,
    `請確認資訊後完成預約。`,
    `レッスン情報を確認後、予約を完了してください。`,
    `Vui lòng kiểm tra thông tin khóa học của bạn và hoàn tất đặt chỗ của bạn.`,
  ],
  tutors_unavailable_recommended: [
    <>
      해당 시간에 수업 가능한 추천 튜터가 없습니다.
      <br />
      [예약 가능] 탭을 확인해주세요.
    </>,
    <>
      There are no featured tutors available for the chosen time slot.
      <br />
      Please check out the [Available] tab.
    </>,
    's_schedule.tutors_unavailable_recommended',
    <>
      没有可以在该时间上课的推荐任课教师。
      <br />
      请确认【可预约】标签。
    </>,
    <>
      沒有可以在該時間上課的推薦任課教師。
      <br />
      請確認【可預約】標籤。
    </>,
    <>
      該当時間にレッスン可能なおすすめチューターはいません。
      <br />
      ［予約可能］タブを確認してください。
    </>,
    <>
      Không có gia sư được gợi ý nào có thể dạy trong thời gian này.
      <br />
      Hãy xem trong mục [Có thể đặt].
    </>,
  ],
  get_matched_with_other_tutors_label: [
    <div className="flex items-center">
      다른 튜터 매칭 받기{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">추천</span>
    </div>,
    <div className="flex items-center">
      Match me with another tutor{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">Featured</span>
    </div>,
    's_schedule.get_matched_with_other_tutors_label',
    <div className="flex items-center">
      接受其他教师配对 <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">推荐</span>
    </div>,
    <div className="flex items-center">
      接受其他教師配對 <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">推薦</span>
    </div>,
    <div className="flex items-center">
      他のチューターとマッチングしてもらう{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">おすすめ</span>
    </div>,
    <div className="flex items-center">
      Tự động xếp tôi với một gia sư khác.{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">Nổi bật</span>
    </div>,
  ],
  ringle_tips: [
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    's_schedule.ringle_tips',
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    `Ringle’s Tip`,
  ],
  tips_message_1: [
    `튜터의 수업이 빠르게 마감될 수 있습니다. 미리 예약해보세요.`,
    `Tutors' slots may fill up quickly!`,
    's_schedule.tips_message_1',
    `教师的课很快就会没有了！`,
    `教師的課很快就會沒有了！`,
    `チューター授業の締切が迫っています！`,
    `Các lịch trống của gia sư sẽ được bổ sung nhanh chóng`,
  ],
  tips_message_2: [
    `수업을 미리 예약하면 나와 더 잘 맞는 튜터를 만날 수 있어요.`,
    `Book lessons in advance to learn from tutors that suit you best.`,
    's_schedule.tips_message_2',
    `提早预约课程确保和喜欢的教师上课`,
    `提早預約課程確保和喜歡的教師上課`,
    `授業を事前予約すれば最適なチューターに出会えます。`,
    `Đặt lớp học để tìm gia sư phù hợp với bạn nhất`,
  ],
  tips_message_3: [
    `주 1회 이상 꾸준히 공부해야 영어 실력이 늘어요.`,
    `Study English at least once a week to enhance your English proficiency.`,
    's_schedule.tips_message_3',
    `一週至少进行一次课程英文实力才会增加`,
    `一週至少進行一次課程英文實力才會增加`,
    `週1回以上しっかり勉強すれば実力が向上します。`,
    `Học tiếng anh ít nhất một lần một tuần để nâng cao trình độ tiếng anh của bạn`,
  ],
  tips_message_4: [
    `3~4주 뒤 수업도 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_4',
    `现在提早预约往后一个月的课程吧`,
    `現在提早預約往後一個月的課程吧`,
    `今すぐ一カ月授業を予約しませんか。`,
    `Đăng ký các bài học của bạn trước một tháng.`,
  ],
  tips_message_5: [
    `지금 한 달 수업을 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_5',
    `现在提早预约往后一个月的课程吧`,
    `現在提早預約往後一個月的課程吧`,
    `今すぐ一カ月授業を予約しませんか。`,
    `Đăng ký các bài học của bạn trước một tháng.`,
  ],
  popup_pointback1: [
    <>
      선택한 튜터의 [50% 포인트백]혜택이 마감되었습니다.
      <br />
      혜택을 받으려면 [다시 예약하기]로 다른 새로 온 튜터를 예약해주세요.
    </>,
    <>
      This tutor's [50% Value Back] has ended.
      <br />
      To get 50% value back, click [Change Tutor] and choose another [Rookie Tutor].
    </>,
    's_schedule.popup_pointback1',
    <>
      所选教师 [50% 积分回馈] 结束.
      <br />
      如想获得优惠，请 [重新预约] 其他新教师.
    </>,
    <>
      所選教師 [50% 點數回饋] 結束.
      <br />
      如想獲得優惠，請 [重新預約] 其他新教師.
    </>,
    <>
      現チューターの[50%ポイントバック]は終了しました。
      <br />
      特典受け取りは[予約し直す]より新規チューターを選択してください。
    </>,
    <>
      {' '}
      Gia sư này không thuộc chương trình hoàn điểm 50%. <br /> Để nhận hoàn điểm, click [Đổi gia sư] và chọn [Gia sư
      mới]{' '}
    </>,
  ],
  popup_pointback2: [
    <>
      무료 수업권으로 예약 시 [50% 포인트백]혜택이 적용되지 않고 예약이 진행됩니다.
      <br />
      50% 포인트백을 받으시려면 유료 수업권으로 예약해 주세요.
    </>,
    <>
      [50% Value Back] is not applicable to lessons booked with a free credit.
      <br />
      To get 50% value back, please book the lesson with a paid credit.
    </>,
    's_schedule.popup_pointback2',
    <>
      使用免费课程券预约时，不适用[50%积分回馈活动]。想要获得
      <br />
      50%积分回馈，请用付费课程券预约。
    </>,
    <>
      使用免費課程券預約時，不適用[50%點數回饋活動]。想要獲得
      <br />
      50%點數回饋，請用付費課程券預約。
    </>,
    <>
      無料レッスンチケット出予約した場合［50％ポイントバック］は適応されず予約が進行されます。
      <br />
      50%ポイントバックを受け取るには有料レッスンチケットで予約してください。
    </>,
    <>
      [Hoàn lại 50% điểm] không áp dụng cho phiếu buổi học miễn phí.
      <br />
      Để nhận lại 50% điểm, vui lòng đặt lịch bằng phiếu buổi học trả phí.
    </>,
  ],
  popup_pointback3: [
    <>
      선택한 튜터의 [50% 포인트백]이 마감되었습니다.
      <br />
      50% 포인트백을 받기 위해서는 다른 [새로 온 튜터]와 예약해 주세요.
    </>,
    <>
      This tutor's [50% Value Back] has ended.
      <br />
      To receive 50% value back in points, please choose another [Rookie Tutor].
    </>,
    's_schedule.popup_pointback3',
    <>
      所选教师的[50%积分回馈活动]已截止。想获得
      <br />
      50%积分回馈，请预约其他[新教师]。
    </>,
    <>
      所選教師的[50%點數回饋活動]已截止。想獲得
      <br />
      50%點數回饋，請預約其他[新教師]。
    </>,
    <>
      選択したチューターの［50％ポイントバック］が終了しました。
      <br />
      50% ポイントバックを受け取るためには他の［新規チューター］と予約してください。
    </>,
    <>
      Chương trình [Hoàn lại 50% điểm] của gia sư này đã kết thúc.
      <br />
      Để nhận lại 50% điểm, vui lòng chọn một [Gia sư mới] khác.
    </>,
  ],
  popup_pointback1_btn1: [
    `다시 예약하기`,
    `Change Tutor`,
    's_schedule.popup_pointback1_btn1',
    `重新預約`,
    `重新预约`,
    `予約し直す`,
    `Đổi gia sư`,
  ],
  popup_pointback1_btn2: [`확인`, `Ok`, 's_schedule.popup_pointback1_btn2', `确认`, `確認`, `確認`, `Ok`],
  popup_pointback2_btn: [`확인`, `Ok`, 's_schedule.popup_pointback2_btn', `确认`, `確認`, `確認`, `Ok`],
  popup_pointback4: (total, succeed, failed) => [
    `예약된 ${total}의 수업 중 ${succeed}개 수업만 [50% 포인트백] 혜택을 받을 수 있습니다. 나머지 ${failed}개 수업도 혜택을 받으려면 [다시 예약하기]로 다른 튜터와 예약해주세요.`,
    `[50% value back] is applicable for only ${succeed} out of the ${total} lessons you have booked. For 50% value back on the other ${failed} lesson(s), please change the tutor(s).`,
    's_schedule.popup_pointback4',
    `[50% value back] is applicable for only ${succeed} out of the ${total} lessons you have booked. For 50% value back on the other ${failed} lesson(s), please change the tutor(s).`,
    `[50% value back] is applicable for only ${succeed} out of the ${total} lessons you have booked. For 50% value back on the other ${failed} lesson(s), please change the tutor(s).`,
    `[50% value back] is applicable for only ${succeed} out of the ${total} lessons you have booked. For 50% value back on the other ${failed} lesson(s), please change the tutor(s).`,
    `[50% value back] is applicable for only ${succeed} out of the ${total} lessons you have booked. For 50% value back on the other ${failed} lesson(s), please change the tutor(s).`,
  ],
  every_week_banner_title: (n) => [
    <>매주 수업 듣기 {n}주차</>,
    `Week n`,
    's_schedule.every_week_banner_title',
    `每週课程, 第 n 週`,
    `每週課程, 第 n 週`,
    `毎週レッスン受講n週目`,
    `Tuần n`,
  ],
  every_week_banner_msg: (date_time) => [
    <>{date_time}까지 수업을 완료하지 않으면 수업권 1회가 차감됩니다.</>,
    <>Complete a lesson by {date_time} to fulfill your Every Week commitment.</>,
    's_schedule.every_week_banner_msg',
    <>若在{date_time}前未完成课程，课程券将被扣除。</>,
    <>若在 {date_time}前未完成課程，課程券將被扣除。</>,
    <>{date_time}までにレッスンを完了しないと、レッスンチケットが1回差し引かれます。</>,
    <>Hoàn thành bài học trước {date_time}, để hoàn thành tiến độ học của bạn.</>,
  ],
}
export const s_history = {
  read: [`읽기`, `Reading`, 's_history.read', `阅读`, `閱讀`, `リーディング`, `Đọc`],
  listen: [`듣기`, `Listening`, 's_history.listen', `听力`, `聽力`, `リスニング`, `Nghe`],
  watch: [`보기`, `Watching`, 's_history.watch', `查看`, `查看`, `ウォッチング`, `Xem`],
  only_ringle_logs_from_mar_1_2021_can_be_checked_: [
    `2021년 3월 30일부터의 학습 활동만 확인 가능합니다.`,
    `Ringle logs are available from Mar 30, 2021.`,
    's_history.only_ringle_logs_from_mar_1_2021_can_be_checked_',
    `仅可确认自2021年3月30日开始的学习活动。`,
    `僅可確認2021年3月30日之後的學習活動。`,
    `2021年3月30日からの学習内容のみ確認可能です。`,
    `Nhật ký Ringle có sẵn từ ngày 30/03/2021.`,
  ],
  haven_t_done_ringle_yet: [
    <>아직 링글 콘텐츠를 경험하지 않으셨네요!</>,
    <>Hi, you haven't explored Ringle content yet!</>,
    's_history.haven_t_done_ringle_yet',
    <>现在还没有体验过Ringle的内容吗!</>,
    <>您尚未體驗Ringle內容！</>,
    <>まだRingleのコンテンツを体験していませんね！</>,
    <>Xin chào, bạn vẫn chưa khám phá các nội dung của Ringle!</>,
  ],
  haven_t_done_ringle_yet_2: [
    <>
      지금 <b>콘텐츠</b> 메뉴에서 다양한 <b>Live 동영상, VOD, 교재</b>를 확인하고 <b>수업</b>을 시작해 보세요.
    </>,
    <>
      Go to <b>Contents</b> to explore our archive of&nbsp;
      <b>webinars and materials</b> and start your study log.
    </>,
    's_history.haven_t_done_ringle_yet_2',
    <>
      现在就在<b>内容</b>菜单中对多样化的<b>Live视频, VOD, 教材</b>等 进行确认后, 开始您的<b>课程</b>吧。
    </>,
    <>
      現在就於<b>內容</b>選單確認各種<b>Live影片、VOD、教材</b>， 開始<b>上課</b>吧！
    </>,
    <>
      今すぐ <b>コンテンツ</b> メニューから多様な <b>Live動画、VOD、教材</b>を 確認して <b>レッスン</b>
      を始めてみましょう。
    </>,
    <>
      Vào phần <b>Nội dung</b> để khám phá kho tàng&nbsp;
      <b>webinars và tài liệu </b> và bắt đầu chuỗi nhật ký học tập.
    </>,
  ],
  haven_t_done_ringle_yet_3: [
    <>여러분이 살펴본 모든 콘텐츠와 수업 학습 데이터까지 모두 기록해서 알려드려요!</>,
    <></>,
    's_history.haven_t_done_ringle_yet_3',
    <>从所有查看内容到课程学习数据, 我们都将为您记录下来并告知予您!</>,
    <>我們會記錄您看過的所有內容與課程學習數據並告訴您！</>,
    <>皆さんがご覧になったすべてのコンテンツからレッスン学習データまですべて記録して お知らせします！</>,
    <></>,
  ],
  haven_t_done_ringle_yet_this_week_all: [
    <>
      이번 주는 아직 링글에서 <b>영어 공부를 시작하지 않으셨네요!</b>
    </>,
    <>You haven't studied English with Ringle this week!</>,
    's_history.haven_t_done_ringle_yet_this_week_all',
    <>
      这周还没有开始您的<b>Ringle英语学习吗!</b>
    </>,
    <>
      這週您尚未於Ringle<b>開始學習英語！</b>
    </>,
    <>
      今週はまだRingleで <b>英語の勉強を始めていませんね！</b>
    </>,
    <>Bạn chưa học buổi nào cùng Ringle trong tuần này!</>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_all: [
    `지금, 영역별 학습을 시작하고 학습 활동을 쌓아보세요.`,
    `Start learning and add an entry to study log.`,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_all',
    `立即开始各方面的学习, 累积学习活动吧。`,
    `現在就開始學習各領域，累積學習活動吧！`,
    `今すぐ分野別学習を始めて、学習内容を積み重ねてみましょう。`,
    `Bắt đầu việc học và thêm mục đầu tiên trong nhật ký học tập.`,
  ],
  haven_t_done_ringle_yet_this_week_read: [
    <>
      이번 주는 아직 링글에서 <b>읽기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't <b>read</b> any lesson materials this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_read',
    <>
      这周还没开始您的Ringle<b>阅读</b>专项学习吗!
    </>,
    <>
      這週您尚未於Ringle開始學習<b>閱讀</b>領域！
    </>,
    <>
      今週はまだRingleで <b>リーディング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa <b>đọc</b> tài liệu buổi học nào trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_read: [
    `교재 인사이트, 핵심 표현, 관련 기사 등을 읽고 읽기 영역 학습을 시작해 보세요.`,
    <>Read lesson materials to gain insights and learn new expressions.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_read',
    `请阅读教材正文, 核心表达及相关报道, 开始您的阅读专项学习吧。`,
    `閱讀教材正文、核心用法、相關報導等，開始學習閱讀領域吧！`,
    `教材インサイト、キーフレーズ、関連記事などを読んでリーディング分野の学習を
 始めてみましょう。`,
    <>Đọc tài liệu buổi học để nắm được các insights và học cách diễn đạt mới.</>,
  ],
  haven_t_done_ringle_yet_this_week_listen: [
    <>
      이번 주는 아직 링글에서 <b>듣기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>listening skills</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_listen',
    <>
      这周还没开始您的Ringle <b>听力</b>专项学习吗!
    </>,
    <>
      這週您尚未於Ringle開始學習<b>聽力</b>領域！
    </>,
    <>
      今週はまだRingleで <b>リスニング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành<b> kỹ năng nghe</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_listen: [
    <>
      <b>교재 MP3, 웨비나, 수업 녹음파일</b> 등을 듣고 듣기 영역 학습을 시작해 보세요.
    </>,
    <>Listen to audio books, Webinars, and lesson recordings.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_listen',
    <>
      <b>请收听教材MP3, 网络研讨会及课程录音文件等</b>内容, 开始您的听力专项学习吧!
    </>,
    <>
      請聆聽<b>教材MP3、網路研討會、課程錄音檔</b>等， 開始學習聽力領域吧！
    </>,
    <>
      <b>教材MP3、ウェビナー、レッスン録音ファイル</b> などを聴いてリスニング分野の学習を始めて みましょう。
    </>,
    <>Nghe sách nói, webinar và các bản ghi âm buổi học.</>,
  ],
  haven_t_done_ringle_yet_this_week_write: [
    <>
      이번 주는 아직 링글에서 <b>쓰기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>writing</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_write',
    <>
      这周还没开始您的Ringle<b>写作</b>专项学习吗!
    </>,
    <>
      這週您尚未於Ringle開始學習<b>寫作</b>領域！
    </>,
    <>
      今週はまだRingleで <b>ライティング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành <b>bài viết của mình</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_write: [
    <>교재 토론질문에 대한 의견을 작성하고 쓰기 영역의 학습을 시작해 보세요.</>,
    <>Write your response to discussion questions!</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_write',
    <>针对教材讨论问题提出意见, 开始您的写作专项学习吧。</>,
    <>請針對教材課程問題撰寫意見，開始學習寫作領域吧！</>,
    <>教材の質問に対する意見を作成して、ライティング分野の 学習を始めてみましょう。</>,
    <>Viết phần trả lời của bạn cho câu hỏi thảo luận!</>,
  ],
  haven_t_done_ringle_yet_this_week_speak: [
    <>
      이번 주는 아직 링글에서 <b>말하기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>speaking</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_speak',
    <>
      这周还没开始您的Ringle<b>口语</b>专项学习吗!
    </>,
    <>
      這週您尚未於Ringle開始學習<b>口說</b>領域！
    </>,
    <>
      今週はまだRingleで <b>スピーキング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành <b>kỹ năng nói</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_speak: [
    <>
      <b>1:1 화상영어 수업</b>에서 영어 말하기를 연습하고 말하기 영역 학습을 시작해 보세요.
    </>,
    <>Book a lesson to practice speaking with a tutor.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_speak',
    <>
      <b>在1:1线上英语课程</b>中练习英语会话, 开始您的口语专项学习吧。
    </>,
    <>
      請於<b>1:1視訊英語課程</b>練習英語口說，開始學習口說領域吧！
    </>,
    <>
      <b>1:1オンライン英会話レッスン</b>で英語のスピーキングを練習してスピーキング分野の学習を 始めてみましょう。
    </>,
    <>Đặt lịch học để luyện nói với gia sư.</>,
  ],
  ringle_ing_in_mmm_days: (m, n) => [
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}월 링글 학습
      <Span primary className="pl-2">
        {n}일
      </Span>
    </Span>,
    <>
      <Span className="display-9 pl-2">Ringling in {format(m, 'mmM')}:</Span>
      <Span primary className="display-9 pl-2">
        {n} days
      </Span>
    </>,
    's_history.ringle_ing_in_mmm_days',
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月Ringle学习
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月Ringle學習
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月のRingle学習
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <>
      <Span className="display-9 pl-2">Học với Ringle {format(m, 'mmM')}:</Span>
      <Span primary className="display-9 pl-2">
        {n} ngày
      </Span>
    </>,
  ],
  this_week: [`이번 주로 가기`, `This week`, 's_history.this_week', `前往本周`, `前往本週`, `今週に移動`, `Tuần này`],
  packet: [`교재`, `Materials`, 's_history.packet', `教材`, `教材`, `教材`, `Tài liệu`],
  lesson: [`수업시간`, `Lesson`, 's_history.lesson', `课程时间`, `課程時間`, `時間`, `Buổi học`],
  webinar: [`웨비나`, `Webinars`, 's_history.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinars`],
  ok: [`확인`, `OK`, 's_history.ok', `确认`, `確認`, `確認`, `OK`],
  tutor: [`튜터`, `Tutor`, 's_history.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  minutes: [`분`, `-min`, 's_history.minutes', `分钟`, `分鐘`, `分`, `-phút`],
  this_week_all_checked: [
    `이 주의 모든 학습 활동을 확인하셨습니다.`,
    `You've checked this week's log.`,
    's_history.this_week_all_checked',
    `已确认本周所有学习活动。`,
    `您已確認本週所有學習活動。`,
    `今週のすべての学習内容を確認しました。`,
    `Bạn đã đánh dấu nhật ký tuần này.`,
  ],
  answer: [`답변`, `Response`, 's_history.answer', `回答`, `回覆`, `回答`, `Phản hồi`],
  matching: [
    `매칭 중`,
    `We're finding a tutor for you`,
    's_history.matching',
    `匹配中`,
    `配對中`,
    `マッチング中`,
    `Chúng tôi đang tìm gia sư cho bạn`,
  ],
  answer_edit: [
    <>
      답변
      <br />
      (첨삭신청)
    </>,
    <>
      Response
      <br />
      (Request edits)
    </>,
    's_history.answer_edit',
    <>
      回答
      <br />
      (申请修改)
    </>,
    <>
      回覆
      <br />
      （申請增刪）
    </>,
    <>
      回答
      <br />
      (添削申し込み)
    </>,
    <>
      Phản hồi
      <br />
      (Yêu cầu chỉnh sửa)
    </>,
  ],
  help_modal_title: [`학습 활동`, `Log`, 's_history.help_modal_title', `学习活动`, `學習活動`, `学習内容`, `Nhật ký`],
  help_modal_sub1: [
    `학습 활동 페이지에서는 링글에서 학습한 데이터를 읽기/듣기/쓰기/말하기 영역별로 기록하고 분석한 데이터를 제공합니다.`,
    `Log keeps record of your reading, listening, writing, and speaking practices.`,
    's_history.help_modal_sub1',
    `学习活动页面将提供您的Ringle学习数据, 我们将按照阅读/听力/写作/口语四方面分别进行记录, 并提供给您相关分析数据。`,
    `學習活動頁面將您於Ringle學習的數據，依照閱讀／聽力／寫作／口說領域記錄並提供分析數據。`,
    `学習内容では、Ringleで学習したデータを読み/聞取り/書き/話す分野別に記録して分析したデータを提供します。`,
    `Nhật ký lưu lại phần thực hành đọc, nghe, viết và nói của bạn.`,
  ],
  help_modal_sub2: [
    `학습 활동 기록 항목`,
    ``,
    's_history.help_modal_sub2',
    `学习活动记录项目`,
    `學習活動紀錄項目`,
    `学習内容記録項目`,
    ``,
  ],
  help_modal_sub3: [
    <>교재 인사이트, 핵심 표현, 관련 기사, 다른 링글러의 답변, 수업 노트, 튜터 피드백</>,
    <>Lesson materials, key expressions, linked articles, Ringlers' responses, class notes, and tutor's feedback</>,
    's_history.help_modal_sub3',
    <>教材正文, 核心表达, 相关新闻报道, 其他Ringle用户的回答, 课程笔记及教师 反馈</>,
    <>教材正文、核心用法、相關報導、其他Ringler的回覆、課堂筆記、任課教師意見回饋</>,
    <>教材インサイト、キーフレーズ、関連記事、他のRinglerの回答、レッスンノート、チューター フィードバック</>,
    <>
      Tài liệu buổi học, những mẫu câu chính, bài báo liên quan, phản hồi của học viên, ghi chú lớp học và đánh giá của
      gia sư
    </>,
  ],
  help_modal_sub4: [
    <>교재 MP3, 핵심표현 듣기, 관련 영상, 웨비나 Live/VOD</>,
    <>Lesson materials audio book, linked videos, webinars</>,
    's_history.help_modal_sub4',
    <>教材MP3, 核心表达听力, 相关视频, 网络研讨会Live/VOD</>,
    <>教材MP3、聆聽核心用法、相關影片、網路研討會Live／VOD</>,
    <>教材MP3、キーフレーズリスニング、関連動画、ウェビナーLive/VOD</>,
    <>Tài liệu buổi học, sách nói, video liên quan, webinars</>,
  ],
  help_modal_sub5: [
    <>교재 토론 질문작성</>,
    <>Submit responses to discussion questions</>,
    's_history.help_modal_sub5',
    <>回答教材讨论问题</>,
    <>教材討論問題的回覆</>,
    <>教材の質問作成</>,
    <>Gửi câu trả lời cho câu hỏi thảo luận</>,
  ],
  help_modal_sub6: [
    <>수업 진행</>,
    <>Lessons</>,
    's_history.help_modal_sub6',
    <>课程进行</>,
    <>課程進行</>,
    <>レッスン進行</>,
    <>Buổi học</>,
  ],
  help_modal_reading: [
    <b>읽기:</b>,
    <b>Reading:</b>,
    's_history.help_modal_reading',
    <b>阅读:</b>,
    <b>閱讀：</b>,
    <b>読み:</b>,
    <b>Đọc:</b>,
  ],
  help_modal_listening: [
    <b>듣기:</b>,
    <b>Listening:</b>,
    's_history.help_modal_listening',
    <b>听力:</b>,
    <b>聽力：</b>,
    <b>聞取り:</b>,
    <b>Nghe:</b>,
  ],
  help_modal_writing: [
    <b>쓰기:</b>,
    <b>Writing:</b>,
    's_history.help_modal_writing',
    <b>写作:</b>,
    <b>寫作：</b>,
    <b>書き:</b>,
    <b>Viết:</b>,
  ],
  help_modal_speaking: [
    <b>말하기:</b>,
    <b>Speaking:</b>,
    's_history.help_modal_speaking',
    <b>口语:</b>,
    <b>口說：</b>,
    <b>話す:</b>,
    <b>Nói:</b>,
  ],
  read_the_packet_again: [
    `교재 다시 읽기`,
    `Read the materials again`,
    's_history.read_the_packet_again',
    `重新阅读教材`,
    `重新閱讀教材`,
    `教材をもう一度読む`,
    `Đọc lại tài liệu`,
  ],
  listen_to_the_mp3_again_: [
    `교재 다시 듣기`,
    `Listen to audiobook again`,
    's_history.listen_to_the_mp3_again_',
    `重新收听教材`,
    `重新聆聽教材`,
    `教材をもう一度聴く`,
    `Nghe lại sách nói`,
  ],
  continue_to_listent_to_the_mp3: [
    `교재 이어서 듣기`,
    `Continue listening`,
    's_history.continue_to_listent_to_the_mp3',
    `继续收听教材`,
    `繼續聆聽教材`,
    `教材を続けて聴く`,
    `Tiếp tục nghe`,
  ],
  clip: [`영상`, `Clip`, 's_history.clip', `视频`, `影片`, `動画`, `Clip`],
  continue_to_watch_the_video: [
    `영상 이어서 보기`,
    `Continue watch clip`,
    's_history.continue_to_watch_the_video',
    `继续观看视频`,
    `繼續觀看影片`,
    `動画を続けて見る`,
    `Tiếp tục xem clip`,
  ],
  watch_the_video_again: [
    `영상 다시 보기`,
    `Watch the clip again`,
    's_history.watch_the_video_again',
    `重新观看视频`,
    `重新觀看影片`,
    `動画をもう一度見る`,
    `Xem lại clip`,
  ],
  article: [`기사`, `News Article`, 's_history.article', `新闻报道`, `報導`, `記事`, `Bài báo`],
  reac_the_article_again: [
    `기사 다시 읽기`,
    `Read news articles again`,
    's_history.reac_the_article_again',
    `重新阅读新闻报道`,
    `重新閱讀報導`,
    `記事をもう一度読む`,
    `Đọc lại bài báo một lần nữa`,
  ],
  expressions: [`핵심표현`, `Expressions`, 's_history.expressions', `核心表达`, `核心用法`, `キーフレーズ`, `Mẫu câu`],
  study_the_expressions_again: [
    `핵심표현 다시 학습하기`,
    `Study the expressions again`,
    's_history.study_the_expressions_again',
    `重新学习核心表达`,
    `重新學習核心用法`,
    `キーフレーズをもう一度学習する`,
    `Học lại mẫu câu`,
  ],
  expression_example: [
    `핵심표현 및 예문`,
    `Expression & Example`,
    's_history.expression_example',
    `核心表达及例句`,
    `核心用法與例句`,
    `キーフレーズと例文`,
    `Mẫu câu & Ví dụ`,
  ],
  listen_to_the_example_again: [
    `핵심표현 예문 다시 듣기`,
    `Listen to the example again`,
    's_history.listen_to_the_example_again',
    `重新收听核心表达的例句`,
    `重新聆聽核心用法例句`,
    `キーフレーズの例文をもう一度聴く`,
    `Nghe lại ví dụ`,
  ],
  question: [`토론질문`, `Questions`, 's_history.question', `讨论问题`, `討論問題`, `質問`, `Câu hỏi`],
  prep_the_lesson: [
    `수업 예습하기`,
    `Lesson Prep`,
    's_history.prep_the_lesson',
    `预习课程`,
    `預習課程`,
    `レッスンの予習をする`,
    `Chuẩn bị Buổi học`,
  ],
  listen_to_lesson_recording_again: [
    `수업 녹음 다시 듣기`,
    `Listen to lesson recording again`,
    's_history.listen_to_lesson_recording_again',
    `重新收听课程录音`,
    `重新聆聽課程錄音`,
    `レッスンの録音をもう一度聴く`,
    `Nghe bản ghi âm buổi học một lần nữa`,
  ],
  review_the_lesson: [
    `수업 복습하기`,
    `Review Lesson`,
    's_history.review_the_lesson',
    `复习课程`,
    `複習課程`,
    `レッスンの復習をする`,
    `Xem lại buổi học`,
  ],
  read_the_feedback_again: [
    `튜터 피드백 다시 확인하기`,
    `Read tutor feedback again`,
    's_history.read_the_feedback_again',
    `重新确认教师反馈`,
    `重新確認任課教師的意見回饋`,
    `チューターフィードバックをもう一度確認する`,
    `Xem lại đánh giá của gia sư`,
  ],
  continue_to_watch_the_clip: [
    `클립 이어서 보기`,
    `Continue watching the clip`,
    's_history.continue_to_watch_the_clip',
    `继续观看短片`,
    `繼續觀看片段`,
    `動画を続けて見る`,
    `Tiếp tục xem clip`,
  ],
  watch_the_clip_again: [
    `클립 다시 보기`,
    `Watch the clip again`,
    's_history.watch_the_clip_again',
    `重新观看短片`,
    `重新觀看片段`,
    `動画をもう一度見る`,
    `Xem lại clip`,
  ],
  watch_the_webinar_again: [
    `웨비나 다시 보기`,
    `Watch the webinar again`,
    's_history.watch_the_webinar_again',
    `重新观看网络研讨会`,
    `重新觀看網路研討會`,
    `ウェビナーをもう一度見る`,
    `Xem lại webinar`,
  ],
  canceled_lesson: [
    `취소된 수업`,
    `Cancelled Lesson`,
    's_history.canceled_lesson',
    `取消的课程`,
    `已取消的課程`,
    `キャンセルしたレッスン`,
    `Buổi học đã huỷ`,
  ],
}
export const s_common = {
  year_month: (n) => [
    <DateComponent format="yyyy년 MM월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_common.year_month',
    <DateComponent format="yyyy年 MM月" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 MM月" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 MM月" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="MM [năm] yyyy" locale="vi">
      {n}
    </DateComponent>,
  ],
  ringle: [`Ringle`, `Ringle`, 's_common.ringle', `Ringle`, `Ringle`, `Ringle`, `Ringle`],
  minutes: [`분`, `-min`, 's_common.minutes', `分钟`, `分鐘`, `分`, `-phút`],
  minutesNoDash: [`분`, `min`, 's_common.minutesNoDash', `分钟`, `分鐘`, `分`, `phút`],
  ok: [`확인`, `Ok`, 's_common.ok', `确认`, `確定`, `確認`, `Ok`],
  confirm: [`확인`, `Confirm`, 's_common.confirm', `确认`, `確定`, `確認`, `Xác nhận`],
  cancel: [`취소`, `Cancel`, 's_common.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  exit: [`나가기`, `Exit`, 's_common.exit', `离开`, `離開`, `閉じる`, `Thoát ra`],
  send: [`보내기`, `Send`, 's_common.send', `发送`, `傳送`, `送る`, `Gửi`],
  save: [`저장`, `Save`, 's_common.save', `保存`, `儲存`, `保存`, `Lưu`],
  dont_show_again: [
    `다시 보지 않기`,
    `Don't show again`,
    's_common.dont_show_again',
    `不再显示`,
    `不再顯示`,
    `今後表示しない`,
    `Đừng hiển thị lại`,
  ],
  select: [`선택하기`, `Select`, 's_common.select', `选择`, `選擇`, `選択する`, `Chọn`],
  timezone: [`타임존`, `Time zone`, 's_common.timezone', `时区`, `時區`, `タイムゾーン`, `Múi giờ`],
  today: [`오늘`, `Today`, 's_common.today', `今日`, `今日`, `今日`, `Hôm nay`],
  _40min: [`40분`, `40min`, 's_common._40min', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  _20min: [`20분`, `20min`, 's_common._20min', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  you_can_search_by_keyboard: [
    `도시를 직접 입력하시거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list.`,
    's_common.you_can_search_by_keyboard',
    `请直接输入您所在的城市或从列表中进行选择。`,
    `請手動輸入城市或從清單中選擇。`,
    `都市を直接入力するか、リストから選択してください。`,
    `Nhập tên thành phố hoặc chọn từ danh sách.`,
  ],
  locale: (userLocale, routerLocale) => [
    <>
      현재 설정된 언어({userLocale})와 접속하려는 페이지의 언어({routerLocale}
      )가 다릅니다. 언어설정을 {routerLocale}(으)로 변경하시겠습니까?
    </>,
    <>
      The page you're about to enter has a different language setting ({routerLocale}) from your current language (
      {userLocale}). Do you want to change your current language setting to {routerLocale}?
    </>,
    's_common.locale',
    <>
      当前设定的语言({userLocale})和您要连接的页面语言({routerLocale}
      )存在差异。您要将语言变更为{routerLocale}(으)吗?
    </>,
    <>
      目前設定的語言（{userLocale}）和您欲連線的頁面語言 （{routerLocale}）不同。您要將語言變更為{routerLocale}嗎？
    </>,
    <>
      現在設定されている言語({userLocale})と接続しようとしているページの言語({routerLocale}
      )が異なります。言語設定を {routerLocale}に変更しますか？
    </>,
    <>
      Trang bạn chuẩn bị vào có cài đặt ngôn ngữ khác ({routerLocale}) ngôn ngữ bạn sử dụng ({userLocale}). Bạn có muốn
      đổi cài đặt ngôn ngữ hiện tại của bạn qua {routerLocale}?
    </>,
  ],
  save_and_enter: [
    `저장 & 수업 입장`,
    `Save & Enter`,
    's_common.save_and_enter',
    `保存 & 课程入场`,
    `儲存 & 進入課程`,
    `保存 & レッスン入室`,
    `Lưu & Enter`,
  ],
  mon: [`월`, `Mon`, 's_common.mon', `一`, `一`, `月`, `Thứ hai`],
  tue: [`화`, `Tue`, 's_common.tue', `二`, `二`, `火`, `Thứ ba`],
  wed: [`수`, `Wed`, 's_common.wed', `三`, `三`, `水`, `Thứ tư`],
  thu: [`목`, `Thu`, 's_common.thu', `四`, `四`, `木`, `Thứ năm`],
  fri: [`금`, `Fri`, 's_common.fri', `五`, `五`, `金`, `Thứ sáu`],
  sat: [`토`, `Sat`, 's_common.sat', `六`, `六`, `土`, `Thứ bảy`],
  sun: [`일`, `Sun`, 's_common.sun', `日`, `日`, `日`, `Chủ nhật`],
  please_contact_us_via_chat: [
    `페이지가 로드 되지 않으면, 아래 챗으로 문의주세요`,
    `If the page doesn't load, please contact us via chat.`,
    's_common.please_contact_us_via_chat',
    `如果网页不显示，请使用下面的方式联系我们`,
    `如果網頁不顯示，請使用下面的方式聯係我們`,
    `ページがロードされない場合は、下記のチャットへお問い合わせください`,
    `Nếu trang không tải được, vui lòng liên hệ Ringle qua chat.`,
  ],
  cookiebanner: {
    first: [
      `이 웹사이트를 계속 사용하시면 쿠키 사용에 동의하시는 것으로 간주됩니다.`,
      `By continuing to use this site, you accept our`,
      's_common.cookiebanner.first',
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
    ],
    second: [
      `쿠키 사용 여부`,
      `use of cookies.`,
      's_common.cookiebanner.second',
      `use of cookies.`,
      `use of cookies.`,
      `use of cookies.`,
      `use of cookies.`,
    ],
    third: [
      `는 설정을 통해 언제든 변경하실 수 있습니다.`,
      `You can change your cookie settings at any time.`,
      's_common.cookiebanner.third',
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
    ],
  },
  label_weekly: [
    `매주 수업 듣기`,
    `Every Week`,
    's_common.label_weekly',
    `一週一次`,
    `一週一次`,
    `毎週レッスンを受ける`,
    `Lớp học hàng tuần`,
  ],
}
export const s_curation = {
  source: [`출처`, `Source`, 's_curation.source', `出处`, `出處`, `出典`, `Nguồn`],
  go_to_prev: [`홈으로 돌아가기`, `Home`, 's_curation.go_to_prev', `返回主页`, `返回首頁`, `ホームに戻る`, `Trang chủ`],
  example: [`예문`, `Example`, 's_curation.example', `例句`, `例句`, `例文`, `Ví dụ`],
  topic: [`주제`, `Topic`, 's_curation.topic', `主题`, `主題`, `テーマ`, `Chủ đề`],
  today_s_expression: [
    `오늘의 expression`,
    `Today's expression`,
    's_curation.today_s_expression',
    `今日expression`,
    `今日expression`,
    `今日のexpression`,
    `Mẫu câu hôm nay`,
  ],
  want_to_read_the_packet_related_to_this_brief_: [
    `이 Daily Brief가 마음에 드셨다면?`,
    `View Lesson Materials related to this brief`,
    's_curation.want_to_read_the_packet_related_to_this_brief_',
    `想阅读此Brief相关的教材吗?`,
    `如果想閱讀這則Brief的相關教材？`,
    `このBrief関連教材を読みたいなら？`,
    `Xem lại Tài liệu học liên quan đến bản tóm tắt này`,
  ],
  want_to_watch_the_webinar_related_to_this_brief_: [
    `이 Brief 관련 웨비나를 보고 싶다면?`,
    `View Webinars related to this brief`,
    's_curation.want_to_watch_the_webinar_related_to_this_brief_',
    `想观看此Brief相关的网络研讨会吗?`,
    `如果想觀看這則Brief的相關網路研討會？`,
    `このBrief関連ウェビナーを見たいなら？`,
    `Xem Webinars liên quan đến bản tóm tắt này`,
  ],
  go_to_read_the_packet: [
    `교재 읽으러 가기`,
    `Go to Lesson Materials`,
    's_curation.go_to_read_the_packet',
    `前往阅读教材`,
    `前往閱讀教材`,
    `教材を読む`,
    `Truy cập Tài liệu học`,
  ],
  go_to_watch_the_webinar: [
    `웨비나 보러 가기`,
    `Go to Webinars`,
    's_curation.go_to_watch_the_webinar',
    `前往观看网络研讨会`,
    `前往觀看網路研討會`,
    `ウェビナーを見る`,
    `Truy cập Webinars`,
  ],
  this_week_s_briefs: [
    `이번 주의 Briefs`,
    `This Week's Briefs`,
    's_curation.this_week_s_briefs',
    `本周的Briefs`,
    `本週Briefs`,
    `今週のBriefs`,
    `Tin tức tuần này`,
  ],
  reading_now: [
    `지금 읽고 있음`,
    `Reading now`,
    's_curation.reading_now',
    `正在阅读中`,
    `目前正在閱讀`,
    `今読んでいます`,
    `Đọc ngay bây giờ`,
  ],
  modal_body: (day, hour, min) => [
    <>
      이 Brief는 {day <= 0 ? '' : day + '일'} {hour <= 0 ? '' : hour + '시간'}&nbsp;
      {min <= 0 ? '' : min + '분'} 후에 확인할 수 있어요!
    </>,
    <>
      This Daily Brief will be availalbe in {day <= 0 ? '' : day + ' days'}&nbsp;
      {hour <= 0 ? '' : hour + ' hours'} {min <= 0 ? '' : min + ' mins'}.
    </>,
    's_curation.modal_body',
    <>
      本Brief可于{day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '小时'}&nbsp;
      {min <= 0 ? '' : min + '分钟'}后进行确认!
    </>,
    <>
      這則Brief可於{day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '小時'}&nbsp;
      {min <= 0 ? '' : min + '分鐘'}後確認！
    </>,
    <>
      このBriefは {day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '時間'}&nbsp;
      {min <= 0 ? '' : min + '分'} 後に確認することができます！
    </>,
    <>
      Daily Brief sẽ có trong {day <= 0 ? '' : day + ' days'}&nbsp;
      {hour <= 0 ? '' : hour + ' hours'} {min <= 0 ? '' : min + ' mins'}.
    </>,
  ],
}
export const s_lesson_edit = {
  do_you_want_to_exit: [
    `수업 시간 변경 페이지를 나가겠습니까?`,
    `Do you want to exit rescheduling?`,
    's_lesson_edit.do_you_want_to_exit',
    `您确定要离开课程时间变更页面吗?`,
    `確定要離開變更課程時間頁面嗎？`,
    `レッスン時間変更ページを閉じますか？`,
    `Bạn có muốn thoát phần dời lịch?`,
  ],
  change_time_of_lesson: [
    `수업 시간 변경`,
    `Reschedule`,
    's_lesson_edit.change_time_of_lesson',
    `课程时间变更`,
    `變更課程時間`,
    `レッスン時間変更`,
    `Dời lịch`,
  ],
  before_change: [
    `변경 전`,
    `Before change`,
    's_lesson_edit.before_change',
    `变更前`,
    `變更前`,
    `変更前`,
    `Trước khi thay đổi`,
  ],
  after_change: [
    `변경 후`,
    `After change`,
    's_lesson_edit.after_change',
    `变更后`,
    `變更後`,
    `変更後`,
    `Sau khi thay đổi`,
  ],
  title_cancel_lesson: [
    `수업 취소`,
    `Cancel Lesson`,
    's_lesson_edit.title_cancel_lesson',
    `课程取消`,
    `取消課程`,
    `レッスンキャンセル`,
    `Huỷ buổi học`,
  ],
  cancel_lessons: [
    `수업 취소`,
    `Cancel Lesson`,
    's_lesson_edit.cancel_lessons',
    `课程取消`,
    `取消課程`,
    `レッスンキャンセル`,
    `Huỷ buổi học`,
  ],
  select_the_reason_for_cancellation: [
    `수업 취소 이유를 선택해 주세요.`,
    `Select reason for cancellation.`,
    's_lesson_edit.select_the_reason_for_cancellation',
    `请选择您取消课程的理由。`,
    `請選擇取消課程的理由。`,
    `レッスンのキャンセル理由を選択してください。`,
    `Chọn lí do huỷ lớp.`,
  ],
  change_lessons: [
    `수업 변경`,
    `Change Lessons`,
    's_lesson_edit.change_lessons',
    `课程变更`,
    `變更課程`,
    `レッスン変更`,
    `Đổi buổi học`,
  ],
  select_the_category_you_want_to_change: [
    `변경을 원하시는 메뉴를 클릭해주세요.`,
    `Select category you want to change.`,
    's_lesson_edit.select_the_category_you_want_to_change',
    `请点击希望变更的菜单。`,
    `請點擊希望變更的選單。`,
    `変更したいメニューをクリックしてください。`,
    `Chọn mục bạn muốn thay đổi.`,
  ],
  request_cancellation: [
    `수업 취소 요청`,
    `Cancel Lesson`,
    's_lesson_edit.request_cancellation',
    `申请课程取消`,
    `要求取消課程`,
    `レッスンキャンセルリクエスト`,
    `Huỷ buổi học`,
  ],
  change_lesson_materials: [
    `교재 변경`,
    `Change lesson materials`,
    's_lesson_edit.change_lesson_materials',
    `教材变更`,
    `變更教材`,
    `教材変更`,
    `Đổi tài liệu học`,
  ],
  btn_change_lesson_material: [
    `교재 변경하기`,
    `Change lesson materials`,
    's_lesson_edit.btn_change_lesson_material',
    `教材变更`,
    `變更教材`,
    `教材を変更する`,
    `Đổi tài liệu học`,
  ],
  title_change_time: [
    `시간 변경`,
    `Reschedule`,
    's_lesson_edit.title_change_time',
    `时间变更`,
    `變更時間`,
    `時間変更`,
    `Dời lịch`,
  ],
  time_has_been_changed: [
    `수업 시간이 변경되었습니다.`,
    `The lesson has been rescheduled.`,
    's_lesson_edit.time_has_been_changed',
    `时间已变更。`,
    `時間變更成功。`,
    `時間が変更されました。`,
    `Buổi học này đã được dời lịch.`,
  ],
  select_time_to_change: [
    `변경할 시간을 선택해 주세요`,
    `Select time.`,
    's_lesson_edit.select_time_to_change',
    `请选择希望变更的时间`,
    `請選擇欲變更的時間`,
    `変更する時間を選択してください。`,
    `Lựa chọn thời gian.`,
  ],
  btn_changing_time: [
    `시간 변경하기`,
    `Reschedule`,
    's_lesson_edit.btn_changing_time',
    `时间变更`,
    `變更時間`,
    `時間を変更する`,
    `Dời lịch`,
  ],
  not_prepared_for_the_lesson: [
    `수업을 들을 준비가 부족합니다.`,
    `I am not prepared for this lesson.`,
    's_lesson_edit.not_prepared_for_the_lesson',
    `课程准备不足。`,
    `上課準備不足。`,
    `レッスンの準備が足りません`,
    `Tôi chưa chuẩn bị cho buổi học.`,
  ],
  urgent_personal_matter: [
    `급한 개인 사정이 생겨서 수업을 취소합니다.`,
    `I have a conflict.`,
    's_lesson_edit.urgent_personal_matter',
    `因紧急个人事务取消课程。`,
    `突然有私人急事，故取消課程。`,
    `急な個人的な都合によりレッスンをキャンセルします`,
    `Tôi có việc gấp.`,
  ],
  other_reasons: [
    `기타 사유`,
    `Others`,
    's_lesson_edit.other_reasons',
    `其他原因`,
    `其他原因`,
    `その他の理由`,
    `Lí do khác`,
  ],
  let_us_know_the_reason_for_cancelling: [
    `기타 취소 사유를 입력해주세요.`,
    `Type in your reason for cancellation.`,
    's_lesson_edit.let_us_know_the_reason_for_cancelling',
    `请输入其他取消的原因。`,
    `請輸入其他取消原因。`,
    `その他のキャンセル理由を入力してください。`,
    `Chọn lý do huỷ.`,
  ],
  automatic_matching_for_tutors: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_lesson_edit.automatic_matching_for_tutors',
    `教师自动匹配`,
    `自動配對任課教師`,
    `チューター自動マッチング`,
    `Xếp ngẫu nhiên tôi với một gia sư`,
  ],
  select_the_materials_to_change: [
    `변경할 교재를 선택해 주세요`,
    `Select lesson materials.`,
    's_lesson_edit.select_the_materials_to_change',
    `请选择要变更的教材`,
    `請選擇要變更的教材`,
    `変更する教材を選択してください。`,
    `Chọn tài liệu học.`,
  ],
  click_to_change_material: [
    `버튼을 누르면 교재가 변경 됩니다`,
    ``,
    's_lesson_edit.click_to_change_material',
    `点击按钮, 即可变更教材`,
    `點擊按鈕時，將變更教材。`,
    `ボタンを押すと教材が変更されます。`,
    ``,
  ],
  material_has_been_changed: [
    `교재가 변경되었습니다.`,
    `Your lesson materials have been changed.`,
    's_lesson_edit.material_has_been_changed',
    `教材已变更。`,
    `教材變更成功。`,
    `教材が変更されました。`,
    `Tài liệu học đã thay đổi.`,
  ],
  do_you_want_to_cancel_changing_materials: [
    `교재 변경을 취소하시겠습니까?`,
    `Do you want to cancel changing lesson materials?`,
    's_lesson_edit.do_you_want_to_cancel_changing_materials',
    `您确定要取消教材变更吗?`,
    `確定要取消教材變更嗎？`,
    `教材変更をキャンセルしますか？`,
    `Bạn có muốn huỷ việc thay đổi tài liệu học không?`,
  ],
  select_material: [
    `교재 선택`,
    `Select`,
    's_lesson_edit.select_material',
    `教材选择`,
    `教材選擇`,
    `教材を選択`,
    `Chọn tài liệu`,
  ],
  cancel_material: [
    `선택 취소`,
    `Cancel`,
    's_lesson_edit.cancel_material',
    `选择取消`,
    `選擇取消`,
    `選択をキャンセル`,
    `Hủy chọn`,
  ],
  info_change_material_more_than_1h: [
    `수업 예약 후 교재를 변경할 수 있습니다. 부담 없이 교재를 선택해주세요.`,
    `You can change the lesson material after you complete booking the lesson, so please go ahead and choose what interests you most.`,
    's_lesson_edit.info_change_material_more_than_1h',
    `预约课程后可以更改教材。请自由选择教材`,
    `預約課程后可以更改教材。請自由選擇教材`,
    `レッスン予約後も教材を変更することができます。気軽に教材を選択してください。`,
    `Bạn có thể thay đổi tài liệu sau khi đặt buổi học. Hãy thoải mái lựa chọn tài liệu.`,
  ],
  info_change_material_less_than_1h: [
    `수업 시작 1시간 이내인 경우 '자유주제' 또는 '튜터가 이전에 수업한 교재'만 선택 가능합니다.`,
    `If the lesson begins in less than one hour, you can either select 'Free Topic' or choose from 'Tutor's Previously Taught Materials.`,
    's_lesson_edit.info_change_material_less_than_1h',
    `如果课程开始时间不超过1小时，则只能选择"自由主题”或"任课教师先前授课的教材”。`,
    `如果課程開始時間不超過1小時，則只能選擇"自由主題”或"任課教師先前授課的教材”。`,
    `レッスン開始1時間以内である場合「自由な主題」または「チューターが以前レッスンした教材」のみ選択可能です。`,
    `Trong vòng 1 tiếng trước khi bắt đầu buổi học, bạn chỉ có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử dụng trước đây”`,
  ],
  change_material_modal_title: [
    `교재 변경 확인`,
    `Change Lesson Material`,
    's_lesson_edit.change_material_modal_title',
    `教材更改确认`,
    `教材更改確認`,
    `教材の変更確認`,
    `Xác nhận thay đổi tài liệu`,
  ],
  change_material_modal_content: [
    <>
      새로 선택한 교재로 변경하시겠습니까?
      <br />
      교재 변경 시 진행한 예습 목록은 초기화됩니다.
    </>,
    <>
      Would you like to change to the selected lesson material?
      <br />
      Changing the lesson material will also reset your lesson prep list.
    </>,
    's_lesson_edit.change_material_modal_content',
    <>
      是否要更改为新选择的教材？
      <br />
      更改教材时预习列表将被初始化。
    </>,
    <>
      是否要更改為新選擇的教材？
      <br />
      更改教材時預習清單將被初始化。
    </>,
    <>
      新しく選択した教材に変更しますか？
      <br />
      教材を変更すると進めていた予習リストが初期化されます。
    </>,
    <>
      Đổi thành tài liệu bạn vừa mới chọn?
      <br />
      Khi thay đổi tài liệu, mục lục học trước đã thực hiện sẽ khởi tạo lại.
    </>,
  ],
  change_material_modal_content_80_correction_info: [
    `80단어 첨삭 요청을 한 경우, 기존 요청 건은 취소가 되며 새로 첨삭을 신청해야 합니다.`,
    `Any previous request for 80-word response editing will be cancelled. If you wish to request 80-word response editing, please submit your response for the new lesson material.`,
    's_lesson_edit.change_material_modal_content_80_correction_info',
    `如果要求增删80个词，既有的增删请求将被取消，您必须申请新的增删要求。`,
    `如果要求增刪80個詞，既有的增刪請求將被取消，您必須申請新的增刪要求。`,
    `80単語添削リクエストをした場合、既存のリクエストはキャンセルされ、新しく添削を申請する必要があります。`,
    `Nếu bạn đã yêu cầu chỉnh sửa 80 từ, yêu cầu đó sẽ bị hủy và bạn phải gửi lại yêu cầu mới.`,
  ],
  modal_cancel: [`취소`, `Cancel`, 's_lesson_edit.modal_cancel', `取消`, `取消`, `キャンセル`, `Hủy`],
  modal_proceed: [
    `교재 변경`,
    `Confirm`,
    's_lesson_edit.modal_proceed',
    `教材更改`,
    `教材更改`,
    `教材を変更`,
    `Thay đổi tài liệu`,
  ],
}
export const s_coupon_box = {
  subtitle: [
    `각종 혜택을 누릴 수 있는 쿠폰을 확인하세요`,
    ``,
    's_coupon_box.subtitle',
    `请确认可享受各种优惠的优惠券。`,
    `請確認可享受各種優惠的優惠券。`,
    `各種特典を受けられるクーポンをご確認ください。`,
    ``,
  ],
  coupon_count: (count) => [
    <>
      쿠폰 <span className="text-purple-500">{count}개</span>
    </>,
    <>
      You have <span className="text-purple-500">{count} coupons</span>
    </>,
    's_coupon_box.coupon_count',
    <>
      优惠券<span className="text-purple-500">{count}张</span>
    </>,
    <>
      優惠券<span className="text-purple-500">{count}張</span>
    </>,
    <>
      クーポン <span className="text-purple-500">{count}枚</span>
    </>,
    <>
      Bạn có <span className="text-purple-500">{count} coupon</span>
    </>,
  ],
  redeem: [`사용하기`, `Redeem`, 's_coupon_box.redeem', `使用`, `使用`, `使用する`, `Đổi thưởng`],
  used: [`사용완료`, `Used`, 's_coupon_box.used', `使用完成`, `已使用`, `使用済み`, `Đã sử dụng`],
  bonus_coupon_type_1_title: [
    `3회 수업권 45% 할인 쿠폰`,
    `45% off 3 lessons credits`,
    's_coupon_box.bonus_coupon_type_1_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_1: [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은 <b>3회 수업권</b> 구매 시 첫 구매 가격으로 할인해드리는 쿠폰입니다. (정가 대비 45% 할인)
        </li>
        <li>본 쿠폰은 20분, 40분 수업 모두 사용 가능합니다.</li>
        <li>
          본 쿠폰을 적용하여 20분 또는 40분 <b>3회 수업권</b> 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.
        </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is valid until January 28th, 2021.</li>
        <li>
          Redeeming this coupon applies a 45% discount from the regular price for the <b>First 3 Lessons package</b>.
        </li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>
          If you have further questions, please contact us via chat below or through @Ringle on Kakao Talk channel.
        </li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_1',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_2_title: (amount) => [
    `재등록 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `Returning Customer Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_2_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_2: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_2',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_3_title: (amount) => [
    `신년 특가 추가 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `New Year’s Resolution Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_3_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_3: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_3',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_4_title: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_4_title',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  bonus_coupon_type_4: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_4',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  use_confirm: [
    `사용하시겠습니까?`,
    `Submit?`,
    's_coupon_box.use_confirm',
    `确定要使用吗?`,
    `確定要使用嗎？`,
    `使用しますか？`,
    `Gửi?`,
  ],
  expired: [`기간만료`, `Expired`, 's_coupon_box.expired', `已到期`, `已到期`, `期間満了`, `Hết hạn`],
  bonus_coupon_type_5_title: [
    `재구매 할인쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_5_title',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  bonus_coupon_type_5: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_5',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  bonus_coupon_type_11_title: [
    `신규회원 쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.bonus_coupon_type_11_title',
    `Welcome优惠券`,
    `Welcome優惠券`,
    `ウェルカムクーポン`,
    `Coupon chào mừng`,
  ],
  bonus_coupon_type_11: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 쿠폰 발급 후 7일 이내 사용 가능합니다
          <br />
          • 타 쿠폰과 중복하여 사용할 수 없습니다.
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Valid for 7 days after issuance
          <br />
          • Cannot be combined with any other coupons
          <br />
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_11',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 领取优惠券后7天之内可使用
          <br />
          • 无法和其他优惠券一起使用
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 領取優惠券后7天之内可使用
          <br />
          • 無法和其他優惠券一起使用
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • クーポン発行後7日以内に使用可能です
          <br />
          • 他のクーポンと重複しているため、使用できません
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Thời hạn sử dụng 7 ngày
          <br />
          • Không áp dụng cùng lúc với coupon khác
          <br />
        </Div>
      </Div>
    </>,
  ],
  bonus_coupon_type_12_title: (duration, number) => [
    <>
      시크릿 할인 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_12_title',
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
  ],
  bonus_coupon_type_12: (duration, number) => [
    <>
      <ul>
        <li>본 쿠폰은 2022년 6월 21일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은{' '}
          <b>
            {duration}분/{number}회 정규 수업권
          </b>{' '}
          구매 시 적용 가능합니다.{' '}
        </li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_12',
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
  ],
  bonus_coupon_type_15_title: (duration, number) => [
    <>
      블랙프라이데이 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_15_title',
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
  ],
  bonus_coupon_type_15: (duration, number) => [
    <>
      <ul>
        <li>
          {duration}분 {number}회 수업권 구매 시 적용 가능합니다.{' '}
        </li>
        <li>2022년 12월 2일 오후 14:00(KST)에 만료됩니다.</li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_15',
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
  ],
  title_secret: [
    `시크릿 쿠폰`,
    `Secret Coupon`,
    's_coupon_box.title_secret',
    `Secret Coupon`,
    `Secret Coupon`,
    `Secret Coupon`,
    `Secret Coupon`,
  ],
  title_welcome: [
    `신규회원 쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.title_welcome',
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
  ],
  title_next_1: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next_1',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  title_next_2: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next_2',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  title2: (duration, number, type) => [
    <>
      {duration}분 {number}회 수업권 ({type}) 구매 시 적용 가능
    </>,
    <>
      For {duration}-min {number} credits ({type})
    </>,
    's_coupon_box.title2',
    <>
      For {duration}-min {number} credits ({type})
    </>,
    <>
      For {duration}-min {number} credits ({type})
    </>,
    <>
      For {duration}-min {number} credits ({type})
    </>,
    <>
      For {duration}-min {number} credits ({type})
    </>,
  ],
  expiration: (date) => [
    <>{date}까지</>,
    <>Valid till {date}</>,
    's_coupon_box.expiration',
    <>截止到{date}</>,
    <>截止到{date}</>,
    <>{date}まで</>,
    <>Đến {date}</>,
  ],
  discount: (amount) => [
    <>{amount} 할인</>,
    <>${amount} off</>,
    's_coupon_box.discount',
    <>${amount} off</>,
    <>${amount} off</>,
    <>${amount} off</>,
    <>${amount} off</>,
  ],
  desc_secret: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능합니다.
          <br />
          • 2022년 12월 22일 저녁 23시 59분에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_secret',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_next_1: (duration, number, type) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 ({type}) 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_1',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_next_2: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_2',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_welcome: (duration, number, type) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 ({type}) 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_welcome',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits ({type}).
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  title_new: [
    `신규회원 쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.title_new',
    `新注册优惠券`,
    `新註冊優惠券`,
    `新規入会クーポン`,
    `Coupon chào mừng`,
  ],
  title2_new: (min, credits) => [
    <>
      {min}분 {credits}회 수업권 구매 시 적용 가능
    </>,
    <>
      Redeemable for {min}-min {credits} credits
    </>,
    's_coupon_box.title2_new',
    <>
      购买{credits}次{min}分钟课程券时可使用
    </>,
    <>
      購買{credits}次{min}分鐘課程券時可使用
    </>,
    <>
      {min}分{credits}回 購入時適用可能
    </>,
    <>
      Lớp {min}p {credits} buổi
    </>,
  ],
  desc_new: (duration, credits, year, month, day, hour, min) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {credits}회 수업권 구매 시 자동 적용됩니다.
          <br />• {year}년 {month}월 {day}일 {hour}:{min}에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeems automatically when purchasing {duration}-min {credits} credits.
          <br />• Expires on {month} {day}, {year} at {hour}:{min}
          <br />• Cannot be combined with other coupons.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_new',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 购买{credits}次{duration}分钟课程券时可自动使用。
          <br />• 于{year}年{month}月{day}日到期。
          <br />• 不可以和其它优惠券重复使用。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 購買{credits}次{duration}分鐘課程券時可自動使用。
          <br />• 於{year}年{month}月{day}日到期。
          <br />• 不可以和其它優惠券重複使用。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}分{credits}回レッスンチケット購入時自動適用されます。
          <br />• {year}年{month}月{day}日{hour}:{min}に満了します。
          <br />• その他クーポンと同時に使用できません。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Tự động áp dụng khi mua thẻ học {duration} phút {credits} buổi.
          <br />• Hết hạn vào {hour}:{min}, ngày {day} tháng {month} năm {year}.<br />• Không thể dùng đồng thời với
          Coupon khác.
        </Div>
      </Div>
    </>,
  ],
  benefit_new: (min, credits) => [
    <>
      {min}분 수업권 {credits}회 증정
    </>,
    <>
      Free {min}-min {credits} credits
    </>,
    's_coupon_box.benefit_new',
    <>
      赠送{credits}次{min}分钟课程券
    </>,
    <>
      贈送{credits}次{min}分鐘課程券
    </>,
    <>
      {min}分レッスン{credits}回贈呈
    </>,
    <>
      Free {min}-min {credits} credits
    </>,
  ],
  title_extend: [
    `수강기간 연장 쿠폰`,
    `Extension Coupon`,
    's_coupon_box.title_extend',
    `延长使用期限优惠券`,
    `延長使用期限優惠券`,
    `受講期間延長クーポン`,
    `Coupon gia hạn`,
  ],
  title2_extend: [
    `보유한 수업권의 수강기간 연장 가능`,
    `For extending lesson days`,
    's_coupon_box.title2_extend',
    `可以延长所持有课程券的使用期限`,
    `可以延長所持有課程券的使用期限`,
    `レッスンチケット期間延長可能`,
    `Gia hạn thẻ đang có`,
  ],
  desc_extend: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 수강기간이 남아 있는 수업권에만 적용 가능합니다. (만료된 수업권에 사용할 수 없습니다.)
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />
          • 쿠폰 사용 후, 수업권 환불 시 수강기간 연장에 대한 비용 차감 후 환불됩니다.
          <br />• 매주 수업 듣기 수업권에는 수강기간 연장 쿠폰 적용이 불가합니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to extend the lesson days of a chosen credit.
          <br />
          • Expires in 60 days after issuance
          <br />
          • The regular price for extending lesson days will be deducted if you choose to refund this credit in the
          future after using the extension coupon. <br />• Extension coupons cannot be applied to Every Week credits.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_extend',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 选择课程券可以延长使用期限。
          <br />
          • 可以在优惠券发放60天内使用。
          <br />
          • 若延长后再申请课程券退款，将扣除延长使用期限的费用后退款。 <br />• Extension coupons cannot be applied to
          Every Week credits.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 選擇課程券可以延長使用期限。
          <br />
          • 可以在優惠券發放60天內使用。
          <br />
          • 若延長後再申請課程券退款，將扣除延長使用期限的費用後退款。 <br />• Extension coupons cannot be applied to
          Every Week credits.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • レッスンチケットを選択して受講期間の延長が可能です。
          <br />
          • クーポン発行後60日以内に使用可能です。
          <br />
          • 延長後に該当レッスンチケットを返金する際は、受講期間の延長にかかった費用を差し引いた金額を返金致します。{' '}
          <br />• Extension coupons cannot be applied to Every Week credits.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Có thể chọn thẻ học để gia hạn.
          <br />
          • Hạn sử dụng 60 ngày kể từ ngày cấp Coupon.
          <br />
          • Nếu yêu cầu hoàn tiền sau khi gia hạn, học phí sẽ được hoàn lại sau khi trừ phí gia hạn. <br />• Extension
          coupons cannot be applied to Every Week credits.
        </Div>
      </Div>
    </>,
  ],
  time: (hh, mm) => [
    <>
      {hh}시간 {mm}분 남음
    </>,
    <>
      {hh}:{mm} left
    </>,
    's_coupon_box.time',
    <>
      还剩{hh}小时{mm}分钟
    </>,
    <>
      還剩{hh}小時{mm}分鐘
    </>,
    <>
      残り{hh}時間{mm}分
    </>,
    <>
      Còn lại {hh} giờ {mm} phút
    </>,
  ],
  use_new: [
    `신규가입 쿠폰 사용`,
    `Redeem Welcome Coupon`,
    's_coupon_box.use_new',
    `新注册优惠券使用`,
    `新註冊優惠券使用`,
    `新規入会クーポン使用`,
    `Dùng Coupon chào mừng`,
  ],
  btn_new: [
    `구매페이지 이동`,
    `Go to purchase page`,
    's_coupon_box.btn_new',
    `前往购买页面`,
    `前往購買頁面`,
    `購入ページを移動`,
    `Chuyển đến trang mua hàng`,
  ],
  use_extend: [
    `수강기간 연장 쿠폰 사용`,
    `Redeem Extension Coupon`,
    's_coupon_box.use_extend',
    `使用延长使用期限优惠券`,
    `使用延長使用期限優惠券`,
    `受講期間延長クーポン使用`,
    `Dùng Coupon gia hạn`,
  ],
  notice1_extend: [
    <>
      수강기간을 연장할 수업권을 선택해 주세요.
      <br />
      매주 수업 듣기 수업권은 수강기간 연장 쿠폰이 적용되지 않습니다.{' '}
    </>,
    <>
      Please select the credit you wish to extend.
      <br />
      Extension coupons cannot be applied to Every Week credits.
    </>,
    's_coupon_box.notice1_extend',
    <>
      请选择要延长使用期限的课程券。
      <br />
      Extension coupons cannot be applied to Every Week credits.。
    </>,
    <>
      請選擇要延長使用期限的課程券。
      <br />
      Extension coupons cannot be applied to Every Week credits.
    </>,
    <>
      受講期間を延長するレッスンチケットを選択してください。
      <br />
      Extension coupons cannot be applied to Every Week credits.
    </>,
    <>
      Hãy chọn thẻ học cần gia hạn.
      <br />
      Sau khi chọn hết các nội dung lưu ý, nhấp vào [Gia hạn ngày kết thúc].
    </>,
  ],
  select_credit: [
    `수업권을 선택하세요.`,
    `Please select a credit.`,
    's_coupon_box.select_credit',
    `请选择课程券。`,
    `請選擇課程券。`,
    <>
      レッスンチケットを
      <br />
      選択してください
    </>,
    `Hãy chọn thẻ học.`,
  ],
  terms: [`유의사항`, `Terms`, 's_coupon_box.terms', `注意事项`, `注意事項`, `注意事項`, `Chú ý`],
  notice2_extend: [
    `수강기간을 연장할 수업권 정보를 확인했습니다.`,
    `I have checked the information on the credit I chose to extend using the coupon.`,
    's_coupon_box.notice2_extend',
    `已确认要延长使用期限的课程券信息。`,
    `已確認要延長使用期限的課程券信息。`,
    `受講期間延長クーポンを適用するレッスンチケットを確認しました。`,
    `Hãy kiểm tra thông tin thẻ học sử dụng Coupon gia hạn.`,
  ],
  notice3_extend: [
    <>연장 후 해당 수업권 환불 시, 수강기간 연장에 대한 비용 차감 후 환불됩니다.</>,
    `I understand that the regular price for extending lesson days will be deducted if I choose to refund this credit in the future after applying the coupon.`,
    's_coupon_box.notice3_extend',
    `若延长后再申请课程券退款，将扣除延长使用期限的费用后退款。`,
    `若延長後再申請課程券退款，將扣除延長使用期限的費用後退款。`,
    `延長後に該当レッスンチケットを返金する際は、受講期間延長にかかった費用を差し引いた金額を返金致します。`,
    `Nếu yêu cầu hoàn tiền sau khi gia hạn, học phí sẽ được hoàn lại sau khi trừ phí gia hạn.`,
  ],
  btn_extend: [
    `수강기간 연장`,
    `Extend`,
    's_coupon_box.btn_extend',
    `使用期限延长`,
    `延長使用期限`,
    `受講期間延長`,
    `Gia hạn ngày kết thúc`,
  ],
  toast_extend: [
    `수강기간을 연장했어요.`,
    `Extended credit expiration.`,
    's_coupon_box.toast_extend',
    `使用期限已延长。`,
    `使用期限已延長。`,
    `受講期間が延長されました。`,
    `Đã gia hạn`,
  ],
  left_credit: [
    `남은 수업권`,
    `Remaining`,
    's_coupon_box.left_credit',
    `所剩课程券`,
    `所剩課程券`,
    `使用可能`,
    `Có thể dùng`,
  ],
  left_date: (n) => [
    <>수강기간 {n}일 남음</>,
    <>{n} days left</>,
    's_coupon_box.left_date',
    <>使用期限还剩{n}天</>,
    <>使用期限還剩{n}天</>,
    <>残り{n}日</>,
    <>Còn {n} ngày</>,
  ],
  extend_date: (n) => [
    <>+{n}일 연장</>,
    <>+add {n} days</>,
    's_coupon_box.extend_date',
    <>+延长{n}天</>,
    <>+延長{n}天</>,
    <>+{n}日延長</>,
    <>+{n} ngày</>,
  ],
  add: (n) => [
    <div className="flex items-center">
      추가 연장 <div className="ml-[4px] text-red-500">+{n}일</div>
    </div>,
    <div className="flex items-center">
      add <div className="text-red-500 ml-[4px]">+{n} days</div>
    </div>,
    's_coupon_box.add',
    <>额外延长+{n}天</>,
    <>額外延長+{n}天</>,
    <>追加延長+{n}日</>,
    <>Thêm +{n} ngày</>,
  ],
  title_next: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next',
    `回购优惠券`,
    `回購優惠券`,
    `再購入クーポン`,
    `Coupon mua lại`,
  ],
  benefit_price: (price) => [
    <>{price} 할인</>,
    <>Extra {price} off</>,
    's_coupon_box.benefit_price',
    <>{price}优惠</>,
    <>{price}折扣</>,
    <>{price}割引</>,
    <>{price} Off</>,
  ],
  use_next: [
    `재구매 쿠폰 사용`,
    `Redeem Next Purchase Coupon`,
    's_coupon_box.use_next',
    `新注册优惠券使用`,
    `新註冊優惠券使用`,
    `新規入会クーポン使用`,
    `Dùng Coupon chào mừng`,
  ],
  title_trial: [
    `체험수업 할인 쿠폰`,
    `Trial Lesson Coupon`,
    's_coupon_box.title_trial',
    `Trial Lesson Coupon`,
    `Trial Lesson Coupon`,
    `Trial Lesson Coupon`,
    `Trial Lesson Coupon`,
  ],
  title2_trial: [
    `체험수업 결제 시 사용 가능`,
    `For purchasing a trial lesson`,
    's_coupon_box.title2_trial',
    `For purchasing a trial lesson`,
    `For purchasing a trial lesson`,
    `For purchasing a trial lesson`,
    `For purchasing a trial lesson`,
  ],
  desc_trial: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 체험수업 구매 시 적용 가능합니다.
          <br />• 쿠폰은 발급 시점으로부터 24시간 이내 사용 가능합니니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to pay for a trial lesson.
          <br />• Expires in 24 hours after issuance.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_trial',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to pay for a trial lesson.
          <br />• Expires in 24 hours after issuance.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to pay for a trial lesson.
          <br />• Expires in 24 hours after issuance.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to pay for a trial lesson.
          <br />• Expires in 24 hours after issuance.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to pay for a trial lesson.
          <br />• Expires in 24 hours after issuance.
        </Div>
      </Div>
    </>,
  ],
  subtitle2: [
    `매주 수업 듣기 수업권에는 수강기간 연장 쿠폰 적용이 불가합니다`,
    `Extension coupons cannot be applied to Every Week credits`,
    's_coupon_box.subtitle2',
    `Extension coupons cannot be applied to Every Week credits`,
    `Extension coupons cannot be applied to Every Week credits`,
    `Extension coupons cannot be applied to Every Week credits`,
    `Extension coupons cannot be applied to Every Week credits`,
  ],
}
export const s_preparation = {
  exit: [`나가기`, `Exit`, 's_preparation.exit', `离开`, `離開`, `閉じる`, `Thoát ra`],
  prep: [`예습`, `Prep`, 's_preparation.prep', `预习`, `預習`, `予習`, `Chuẩn bị`],
  save: [`저장`, `Save`, 's_preparation.save', `保存`, `儲存`, `保存`, `Lưu`],
  free_editing: [
    `80단어 답변 첨삭`,
    `80-word Response Editing`,
    's_preparation.free_editing',
    `80单词回答修改`,
    `80個單字的增刪回覆`,
    `80単語回答添削`,
    `Chỉnh sửa câu trả lời 80 từ`,
  ],
  correction_placeholder: [
    `수업시간 하루 전까지, 교정 받고 싶은 문장을 넣어주세요. 단 Confidential한 내용은 넣지 마세요!`,
    `Submit your response in 80 words or less at least 24 hours before the lesson starts. Please be sure not to share anything confidential!`,
    's_preparation.correction_placeholder',
    `最晚于课程开始前一天, 填写好希望修改的句子。但请勿涉及Confidential内容!`,
    `最晚請於課程時間開始一天之前，寫下希望糾正的句子。但請勿寫下Confidential的內容！`,
    `レッスン開始24時前までに、添削してもらいたい文章を80単語まで入力してください。個人情報や機密事項は入力しないでください`,
    `Gừi câu trả lời của bạn trong 80 từ hoặc ít nhất 24 giờ trước khi buổi học bắt đầu. Hãy chắc chắn bạn không chia sẻ những thông tin tuyệt mật!`,
  ],
  click_if_you_want_this_question: [
    `수업에서 다룰 질문을 선택해 주세요!`,
    `Select question to discuss in lesson`,
    's_preparation.click_if_you_want_this_question',
    `请选择希望于课程中讨论的问题!`,
    `請選擇上課時要討論的問題！`,
    `レッスンで扱う質問を選択してください！`,
    `Chọn câu hỏi để thảo luận trong buổi học`,
  ],
  show_only_to_me: [
    `튜터와 나만보기`,
    `Only visible to me & tutor`,
    's_preparation.show_only_to_me',
    `仅教师和本人可见`,
    `僅任課教師與我查看`,
    `チューターと自分だけ見る`,
    `Chỉ hiển thị cho tôi & gia sư`,
  ],
  my_answer: [
    `내 답변`,
    `My Response`,
    's_preparation.my_answer',
    `我的回答`,
    `我的回覆`,
    `あなたの回答`,
    `Câu trả lời của tôi`,
  ],
  my_answer_placeholder: [
    `질문에 대한 생각을 작성해 주세요. 작성하신 내용은 수업 직전까지 언제든 수정하실 수 있습니다.`,
    `Please write your response. You can revise it any time before the lesson.`,
    's_preparation.my_answer_placeholder',
    `请写下您对问题的回答, 同时编写的内容于课程前可随时进行修改。`,
    `請寫下您對於問題的回答。課程開始前，您隨時可以修改您寫的內容。`,
    `質問に対する考えを作成してください。作成する内容はレッスン直前までいつでも修正することができます。`,
    `Vui lòng viết câu trả lời của bạn. Bạn có thể sửa lại bất cứ lúc nào trước buổi học.`,
  ],
  upload_your_file_to_share: [
    <>
      [Free Topic 수업 유의사항]
      <br />
      1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 문서 업로드를 권장 드립니다.
      <br />
      2) <Span className="font-bold">문서 교정은 수업 시간에 실시간으로 진행</Span>됩니다.
      <br />
      3) 개인 정보가 노출될 수 있는 <Span className="font-bold">민감한 내용은 사전 수정 요청</Span>드립니다.
      <br />
      4) 수업 중 영문 교정을 위해 개인 자료를 업로드 시,{' '}
      <Span className="font-bold">20분 수업은 300단어(A4 1/2장), 40분 수업은 600단어(A4 1장)</Span> 분량을 권장
      드립니다.
      <br /> (문서의 성격 및 튜터의 주제 친숙도에 따라 수업 내 진행할 수 있는 교정량이 상이할 수 있습니다.)
      <br />
      5) Writing-focused 위주의 수업은 튜터 피드백 리포트가 제공되지 않습니다.{' '}
    </>,
    <>
      Please upload any materials you'd like to discuss in this class.
      <br />
      <br />
      1) Please upload your document at least 24 hours before the lesson.
      <br />
      2) Writing corrections will be conducted live during the lesson.
      <br />
      3) Please be sure to delete or redact any personal or confidential information.
      <br />
      4) The recommended length for self-uploaded materials is 300 words (1/2 page) for a 20-minute lesson and 600 words
      (1 page) for a 40-minute lesson.
      <br /> (How much of the material is covered during the lesson depends on the nature of the material and tutor's
      familiarity with the topic.)
      <br />
      5) A separate tutor's feedback report is not provided after Writing Focused Lessons.
    </>,
    's_preparation.upload_your_file_to_share',
    <>
      [Free Topic课程注意事项]
      <br />
      1) <Span className="font-bold">建议最晚于课程开始前24小时</Span>完成文件上传。
      <br />
      2) <Span className="font-bold">文件可在课程中实时</Span>进行修改。
      <br />
      3) 可能会暴露个人信息的<Span className="font-bold">敏感内容, 请提前</Span>予以删除。
      <br />
      4)
      上传资料时，20分钟的课程，建议份量为300字（A4纸半页），40分钟的课程，建议份量为600字（A4纸一页）（根据文件内容题材及教师对主题的熟悉程度的不同，于课程中能修改的份量可能有差异。）"
      <br />
      5) Writing-focused 课程不提供任课教师反馈报告。
    </>,
    <>
      [Free Topic課程注意事項]
      <br />
      1) 建議最晚於<Span className="font-bold">課程開始24小時前</Span>上傳文件。
      <br />
      2) <Span className="font-bold">將在課程中即時糾正文件</Span>。
      <br />
      3) 可能洩漏個人資料的
      <Span className="font-bold">
        敏感內容，請您事先修改
        <br />
        4)
        上傳資料時，20分鐘的課程建議為300字（A4紙半頁），40分鐘的課程建議為600字（A4紙一頁）。(根據文件內容題材及教師對主題的熟悉程度的不同，於課程中能修改的份量可能會有差異。)
        <br />
        5) Writing-focused 課程不會提供任課教師的意見回饋報告書，敬請留意。
      </Span>
      。
    </>,
    <>
      [自由テーマレッスンの注意事項]
      <br />
      1) <Span className="font-bold">レッスン開始24時間前</Span>までに資料をアップロードすることを推奨します。
      <br />
      2) <Span className="font-bold">文書校正はレッスン時間にリアルタイムで行い</Span>ます。
      <br />
      3) 個人情報が流出する恐れのある <Span className="font-bold">デリケートな内容は、事前に修正リクエスト</Span>
      します。
      <br />
      4) 個人資料のアップロード分量は20分レッスンでは300単語(A4 1/2枚)、40分レッスンでは600単語(A4 1枚)を推奨します。
      <br />
      (文書の内容及びチューターのテーマに対する理解度に応じてレッスン内で行える校正量が異なる場合があります。)
      <br />
      5) Writing-focused レッスンでは、チューターフィードバックレポートは提供されません。
    </>,
    <>
      Vui lòng tải lên bất cứ tài liệu nào bạn muốn thảo luận trong buổi học.
      <br />
      <br />
      1) Vui lòng tải tài liệu của bạn ít nhất 24 tiếng trước buổi học.
      <br />
      2) Chỉnh sửa bài viết sẽ diễn ra trong buổi học.
      <br />
      3) Hãy đảm bảo xoá hoặc biên tập lại những thông tin cá nhân hoặc tuyệt mật.
      <br />
      4) Khi giảng dạy bằng tài liệu cá nhân, chúng tôi khuyến khích tiết học 20 phút 300 từ (một nửa A4 1/2 trang) và
      tiết 40 phút 600 từ (một trang A4) <br />
      (Số lượng hiệu chuẩn có thể khác nhau trong lớp tùy thuộc vào tính chất của tài liệu và mức độ quen thuộc chủ đề
      của người hướng dẫn.)
      <br />
      5) Các buổi học tập trung viết không cung cấp báo cáo nhận xét từ gia sư.
    </>,
  ],
  correct_policy1: [
    `교정 요청 원문 및 교정된 문장은 익명으로 공개되어, 링글 수강생들의 학습용도로 사용될 예정입니다.
이에, 교정 요청 전 수강생의 신분을 노출시킬 수 있는 단어, 사내 기밀 내용이 담긴 문장이 있는지 살펴주시면 감사하겠습니다`,
    `Submitted document and tutor's edits may be used as an educational reference for other Ringlers. We will not reveal your name.
Please make sure that your document does not contain any personal or confidential information.`,
    's_preparation.correct_policy1',
    `申请校正的原文及校正后的句子将以匿名的形式公开, 用于Ringle学员们共同学习。
因此在请求校正前, 请确认是否有暴露学员身份的敏感词汇及可能涉及公司机密的内容, 非常感谢。`,
    `要求糾正的原文及糾正過的句子將以匿名方式公開，預計使用於Ringle學生們的學習用途。
因此，請於要求糾正前注意句子是否包含洩漏學生身分的單字或公司機密。`,
    `添削リクエストの原文および添削された文章は匿名で公開され、Ringle受講生の学習用途に使われる予定です。したがって、添削リクエスト前に、受講生の個人情報が流出するおそれのある単語や機密事項が記された文章が入っていないことを確認してください。`,
    `Tài liệu được gửi và chỉnh sửa từ gia sư có thể được sử dụng làm tài liệu tham khảo cho các học viên khác. Chúng tôi sẽ không tiết lộ tên của bạn. Hãy đảm bảo tài liệu của bạn không bao gồm thông tin cá nhân và tuyệt mật nào.`,
  ],
  answer_history: [
    `지난 수업에 작성한 답변`,
    `My Previous Response`,
    's_preparation.answer_history',
    `之前课程书写的回答`,
    `上次課程撰寫的回覆`,
    `過去レッスンでの回答履歴`,
    `Câu trả lời trước của tôi`,
  ],
  correct_policy2: [
    <Fragment>
      <li>
        예습 답안교정 베타 서비스는 수업 당 80단어 (80 words) 까지 제공되며, 교정된 답은 수업 종료 후 피드백 레포트를
        통해 확인 가능합니다.
      </li>
      <li>
        교정 요청하신 원문 및 튜터가 제공한 교정본은 향후 익명으로 공개될 예정이며, Ringle 의 수강생분들의 영어 학습
        콘텐츠로 제공될 예정입니다.
      </li>
      <li>
        각 질문 별 답을 작성하시는 고객 분들께, 다른 수강생이 해당 질문에 대한 예습 시 남긴 교정 요청 원문 및 튜터가
        제공한 교정본을 익명으로 open 하여, 다른 수강생 분들께서 영어 공부 content 로 활용하실 수 있게 제공될
        예정입니다.
      </li>
      <li>
        이에, 수강생 분의 신분이 노출될 수 있는 내용, 기밀 유지가 필요한 내용에 대해서는 교정 요청을 하지 말아주시길
        부탁드립니다.
      </li>
      <li>
        Ringle 은 수강생 분들의 영어 학습 과정이, 다른 수강생 분들께도 영어 학습을 위한 content 로 제공될 수 있는 환경을
        조성하고자 합니다. 단, 모든 content 는 익명으로 제공하여 수강생 분들의 privacy 보호에 가장 큰 중점을 둘
        예정입니다.
      </li>
      <li>
        관련하여 의견 및 피드백 있으시면 언제든 contact@ringleplus.com 을 통해 연락주십시오. 저희가 바로 회신드리고
        소통하고, 의견을 적극 반영하겠습니다. 감사합니다.
      </li>
    </Fragment>,
    <Fragment>
      <li>
        The Response Editing Service (beta) provides free editing of 80 words per lesson. You can view the edits on the
        Feedback Report after the lesson.
      </li>
      <li>
        The submitted document and its edited version will be used for educational purposes without revealing the
        writer's name.
      </li>
      <li>
        Your answers and your tutor's corrections will be shown to other Ringlers that are answering the same questions
        to help with their learning process. All of the disclosed answers will be anonymous.
      </li>
      <li>
        Therefore, please do not submit a document that contains personal or confidential information for the editing
        service.
      </li>
      <li>
        Ringle aims to establish a learning atmosphere where your learning experience can help other Ringlers. However,
        we will protect your privacy by posting everything anonymously.
      </li>
      <li>
        If you have any suggestions or feedback regarding this, please do not hesitate to contact us at
        contact@ringleplus.com. We will respond as soon as possible, and do our best to reflect your opinions on our
        services. Thank you.
      </li>
    </Fragment>,
    's_preparation.correct_policy2',
    <Fragment>
      <li>
        预习答案校正的服务, 每个课程仅可提供80字(80 words), 同时校正后的结果可于课程结束后通过反馈报告书进行确认。
      </li>
      <li>申请校正的原文及教师提供的修改文之后可能会以匿名的方式 进行公开, 作为Ringle学员们英语学习的共享内容。</li>
      <li>
        对于各问题的回答者, 我们将以匿名的方式提供其他学员对该问题的
        回答和校正结果。以这种方式为学员提供互相学习的平台。
      </li>
      <li>因此, 对于那些可能会暴露学员身份及需要保密的内容, 请不要提出 校正请求。</li>
      <li>
        Ringle希望提供一个良好的英语学习共享环境给有学习者。所有内容 均以匿名方式提供，我们将致力保护各位学习者的隐私。
      </li>
      <li>
        相关意见及反馈, 可随时通过contact@ringleplus.com与我们联系。 我们会及时给予您回复, 积极沟通妥善处理。非常感谢。
      </li>
    </Fragment>,
    <Fragment>
      <li>預習答案糾正測試版服務每堂課最多80個單字（80 words）， 並可於課程結束後透過反饋報告書確認糾正的結果。</li>
      <li>要求糾正的原文及任課教師提供的糾正版本之後 以匿名方式公開，提供作為Ringle學生們的英語學習內容。</li>
      <li>
        我們為針對各問題撰寫回答的學生，以匿名方式 公開其他學生針對該問題預習時寫下的回答及 任課教師提供的修正版本。
      </li>
      <li>因此，請勿針對可能洩漏學生身分的內容或需要保密的內容 要求糾正。</li>
      <li>
        Ringle希望提供一個良好的英語學習共享環境給有學習者。所有內容 均以匿名方式提供，我們將致力保護各位學習者的隱私。
      </li>
      <li>如有相關意見回饋，歡迎隨時透過contact@ringleplus.com聯絡我們， 我們會立即回覆並積極反映您的意見，感謝。</li>
    </Fragment>,
    <Fragment>
      <li>
        予習回答添削Betaサービスは1レッスンあたり80単語 (80 words)
        まで対応し、添削されたものは授業終了後、フィードバックレポートを通して確認可能です。
      </li>
      <li>
        添削をリクエストした原文およびチューターが提供した添削内容は、今後匿名で公開される予定であり、Ringle受講生たちの英語学習コンテンツとして提供される予定です。
      </li>
      <li>
        各質問に回答を作成される受講生の皆様へ。他の受講生がその質問に対する予習時に提出した添削リクエストの原文およびチューターが提供した添削内容を匿名で公開し、他の受講生たちに英語学習コンテンツとして活用できるように提供される予定です。
      </li>
      <li>
        したがって、受講生の個人情報が流出するおそれのある内容、機密事項などは添削リクエストに入力しないようお願いします。
      </li>
      <li>
        Ringleでは、受講生の英語学習成果を他の受講生の英語学習のためのコンテンツとして提供できる環境を設ける取組みをしています。すべてのコンテンツは匿名で提供され、受講生の方のプライバシー保護を最優先することに努めます。
      </li>
      <li>
        \n\nこの件に関してご意見やフィードバックがございましたら、いつでもcontact@ringleplus.comまでご連絡ください。迅速にご返信し、積極的にご意見を取り入れていきたいと考えております。どうぞよろしくお願いいたします。
      </li>
    </Fragment>,
    <Fragment>
      <li>
        Dịch vụ chỉnh sửa câu trả lời phiên bản Beta, bạn có thể gửi tài liệu tối đa 80 chữ để chỉnh sửa miễn phí trước
        buổi học.
      </li>
      <li>
        Tài liệu được gửi và phiên bản đã chỉnh sửa sẽ được dùng cho mục đích giáo dục. Tên người viết sẽ được giữ kín.
      </li>
      <li>
        Câu trả lời và phần chỉnh sửa của gia sư sẽ hiển thị cho những học viên khác có cùng câu hỏi để giúp đỡ mọi
        người trong quá trình học. Tất cả câu trả lời hiển thị sẽ được ẩn danh.
      </li>
      <li>Vậy nên, vui lòng không gửi tài liệu có chứa thông tin cá nhân hay thông tin tuyệt mật để chỉnh sửa.</li>
      <li>
        Ringle mong muốn tạo ra một môi trường học tập nơi mà trải nghiệm học của bạn có thể giúp những học viên khác.
        Tuy nhiên, chúng tôi sẽ bảo vệ quyền riêng tư bằng cách đăng tải mọi thứ ở trạng thái ẩn danh.
      </li>
      <li>
        Nếu bạn có bất cứ đề xuất hoặc phản hồi liên quan đến vấn đề này, hãy liên hệ với chúng tôi qua email
        contact@ringleplus.com. Chúng tôi sẽ phản hồi sớm nhất có thể, và cố gắng hết sức để thể hiện sự tiếp thu ý kiến
        trong dịch vụ của mình. Trân trọng.
      </li>
    </Fragment>,
  ],
  terms: [
    `이용약관`,
    `Terms of Use`,
    's_preparation.terms',
    `使用条款`,
    `使用條款`,
    `添削利用規約`,
    `Điều khoản sử dụng`,
  ],
  intro: [`인트로`, `Intro`, 's_preparation.intro', `介绍`, `開頭`, `イントロ`, `Giới thiệu`],
  insight: [`인사이트`, `Insight`, 's_preparation.insight', `正文`, `正文`, `インサイト`, `Insight`],
  clip: [`영상`, `Video`, 's_preparation.clip', `视频`, `影片`, `動画`, `Video`],
  article: [`기사`, `News`, 's_preparation.article', `新闻报道`, `報導`, `記事`, `News`],
  clipComplete: [
    `영상 1개 보기를 완료했어요!`,
    `Done watching one video!`,
    's_preparation.clipComplete',
    `已看完1个视频!`,
    `您已看完1個影片！`,
    `動画を1本見るを完了しました！`,
    `Bạn đã xem một video.`,
  ],
  toastInsight: [
    `인사이트 읽기를 완료했어요!`,
    `Done reading Insight!`,
    's_preparation.toastInsight',
    `已阅读完正文!`,
    `您已看完正文！`,
    `インサイトを読み終わりました！`,
    `Bạn đã hoàn thành phần insight bài đọc!`,
  ],
  listenMp3: [
    `MP3 듣기를 완료했어요!`,
    `Done listening to audiobook!`,
    's_preparation.listenMp3',
    `已收听完MP3!`,
    `您已聽完MP3！`,
    ``,
    `Bạn đã nghe xong sách nói!`,
  ],
  readInsight: [
    `인사이트 읽기 완료`,
    `Done reading Insight`,
    's_preparation.readInsight',
    `阅读完毕`,
    `正文閱讀完畢`,
    `インサイト読み完了`,
    `Đã đọc xong insight`,
  ],
  keyQuestion: [
    `수업 질문`,
    `Questions`,
    's_preparation.keyQuestion',
    `课程提问`,
    `課程問題`,
    `レッスンの質問`,
    `Câu hỏi`,
  ],
  keyQeustionSelected: (length) => [
    `수업 질문 ${length}개 선택하기를 완료하셨습니다!`,
    `You selected ${length} discussion questions!`,
    's_preparation.keyQeustionSelected',
    `已选择 ${length}个课程问题!`,
    `您已選擇${length}個課程問題！`,
    `レッスンの質問を ${length}つ選択するを完了しました！`,
    `Bạn đã chọn ${length} câu hỏi thảo luận!`,
  ],
  googleDocs: [
    `수업 노트`,
    `Lesson Notes`,
    's_preparation.googleDocs',
    `课程笔记`,
    `課堂筆記`,
    `レッスンノート`,
    `Ghi chú buổi học`,
  ],
  saved: [`저장됨`, `Saved`, 's_preparation.saved', `已保存`, `已儲存`, `保存済み`, `Đã lưu`],
  you_can_only_select_up_to_three_questions: [
    `20분 수업의 경우 최대 3개의 질문만 선택 가능합니다.`,
    `You can only choose up to 3 questions for 20-minute lessons.`,
    's_preparation.you_can_only_select_up_to_three_questions',
    `20分钟的课程最多只能选择3个问题。`,
    `20分鐘的課程最多只能選擇3個問題。`,
    `20分レッスンの場合、最大3つの質問のみ選択可能です。`,
    `Bạn chỉ có thể chọn tối đa 3 câu hỏi cho buổi học 20 phút.`,
  ],
  you_can_view_previous_answers: [
    `질문에 남기신 답변은 저장되어 다음 예습 시 열람하실 수 있습니다.`,
    `You'll be able to view your responses at Lesson Prep.`,
    's_preparation.you_can_view_previous_answers',
    `有关问题的回答已保存, 下次预习时可进行阅览。`,
    `針對問題留下的回覆將會儲存，並可於下次預習時閱覽。`,
    `質問への回答は保存され、次の予習時に閲覧することができます。`,
    `Bạn có thể xem câu trả lời ở phần Chuẩn bị Buổi học.`,
  ],
  your_upload_has_failed: [
    `서류 업로드가 정상적으로 되지 않았습니다. 다시 업로드해주세요`,
    `Your upload has failed. Please try again.`,
    's_preparation.your_upload_has_failed',
    `文件上传出现问题, 请重新尝试`,
    `文件上傳失敗，請重新上傳。`,
    `書類のアップロードに失敗しました。もう一度アップロードしてください。`,
    `Tải lên không thành công. Vui lòng thử lại.`,
  ],
  the_size_of_file_is_less_than_10mb: [
    `크기가 10MB를 넘지 않는 파일만 업로드가 가능합니다.`,
    `You cannot upload files larger than 10MB.`,
    's_preparation.the_size_of_file_is_less_than_10mb',
    `只能上传不超过10MB的文件。`,
    `僅可上傳不超過10MB的檔案。`,
    `アップロードできるファイルのサイズは10MBまでです。`,
    `Bạn không thể tải files lớn hơn 10MB.`,
  ],
  unsupported_file_format: [
    `지원되지 않는 파일 형식입니다. 확인후 다시 업로드해주세요.`,
    `This file type is not supported. Please check that your file is PPT, PPTX, DOC, DOCX, or PDF and re-upload.`,
    's_preparation.unsupported_file_format',
    `尚未支持的文件格式, 请确认后重新上传。`,
    `不支援的檔案格式，請確認後重新上傳。`,
    `対応していないファイル形式です。ファイルがPPT, PPTX, DOC, DOCX, PDFのいずれかであることを確認し、再度アップロードしてください。`,
    `Định dạng này không thể tải lên. Vui lòng sử dụng file PPT, PPTX, DOC, DOCX, hoặc PDF  tải lên lại.`,
  ],
  upload_your_file_first: [
    `파일을 먼저 업로드해주세요!`,
    `Please first upload your file.`,
    's_preparation.upload_your_file_first',
    `请先上传文件!`,
    `請先上傳檔案！`,
    `ファイルを先にアップロードしてください！`,
    `Vui lòng tải file của bạn trước.`,
  ],
  document_submission: [
    `자료 업로드`,
    `Upload`,
    's_preparation.document_submission',
    `上传材料`,
    `上傳資料`,
    `資料アップロード`,
    `Tải lên`,
  ],
  delete_documents: [
    `자료 삭제`,
    `Delete document`,
    's_preparation.delete_documents',
    `删除材料`,
    `刪除資料`,
    `資料削除`,
    `Xoá tài liệu`,
  ],
  reuploading: [
    `다른 자료 업로드`,
    `Re-upload`,
    's_preparation.reuploading',
    `上传其他材料`,
    `上傳其他資料`,
    `他の資料アップロード`,
    `Tải lên lại`,
  ],
  download_template: [
    `템플릿 다운로드`,
    `Download Templates`,
    's_preparation.download_template',
    `下载模板`,
    `下載模板`,
    `テンプレートダウンロード`,
    `Tải xuống template`,
  ],
  displayed_after_file_upload: [
    `업로드한 자료가 없습니다.`,
    ``,
    's_preparation.displayed_after_file_upload',
    `没有上传的材料。`,
    `無上傳的資料。`,
    `アップロードした資料はありません。`,
    ``,
  ],
  google_docs_is_being_generated: [
    `Google Docs 생성 중입니다.`,
    `Generating Google Docs...`,
    's_preparation.google_docs_is_being_generated',
    `Google Docs加载中。`,
    `Google Docs生成中。`,
    `Google Docs作成中です。`,
    `Đang tạo Google Docs...`,
  ],
  if_no_screen: [
    <>
      업로드한 자료가 보이지 않으면 새로고침해주세요. <br />
      직접 교정받고 싶은 내용은 수업 노트에 붙여보세요.
    </>,
    `If the material doesn't show up, please try refreshing the page.`,
    's_preparation.if_no_screen',
    <>
      无法看到上传的材料, 请重新刷新。 <br />
      希望直接进行校正的内容请粘贴到课程笔记上。
    </>,
    <>
      如未看到上傳的資料，請重新整理。
      <br />
      請將想直接糾正的內容貼於課堂筆記。
    </>,
    <>
      アップロードした資料が表示されない場合、ページを更新してください。 <br />
      直接校正してほしい内容はレッスンノートにコピーしてください。
    </>,
    `Nếu tài liệu không hiển thị, vui lòng làm mới trang.`,
  ],
  other_people_ans: [
    `다른 링글러들의 답변`,
    `Ringlers’ Response`,
    's_preparation.other_people_ans',
    `其他Ringle用户的回答`,
    `其他Ringler的回覆`,
    `他のRinglerの回答`,
    `Phản hồi học viên Ringle`,
  ],
  share_answer: [
    `선택 해제시 다른 수강생에게 내 답변이 공유됩니다.`,
    `If you deselect, other Ringlers can view your response.`,
    's_preparation.share_answer',
    `解除选择时, 我的回答将共享给其他学员。`,
    `解除選擇時，其他學生將會看到我的回覆。`,
    `選択解除すると、他の受講生にあなたの回答が共有されます。`,
    `Nếu bạn bỏ chọn, những học viên Ringle khác có thể xem trả lời của bạn.`,
  ],
  select_question: [
    `수업 질문으로 선택하기`,
    `Select question to discuss in lesson`,
    's_preparation.select_question',
    `选择课程问题`,
    `選為課程問題`,
    `レッスンの質問に選択する`,
    `Chọn câu hỏi thảo luận cho buổi học`,
  ],
  upload_material: [
    <>
      수업에서 다루고 싶은 자료를 업로드 해보세요.
      <br />
      직접 교정받고 싶은 내용은 오른쪽 수업 노트에 붙여보세요.
    </>,
    <>
      Upload your lesson materials.
      <br />
      If you have a written sample that need proofreading, please try pasting it on Lesson Notes.
    </>,
    's_preparation.upload_material',
    <>
      请上传希望于课程中学习的资料。
      <br />
      希望直接纠正的内容, 请粘贴在右边的课程笔记上。
    </>,
    <>
      請將想於課程中進行的資料上傳。
      <br />
      請將想直接糾正的內容貼於右側的課堂筆記。
    </>,
    <>
      レッスンで扱いたい資料をアップロードしてみましょう。
      <br />
      直接校正してほしい内容は、右のレッスンノートにペーストしてください。
    </>,
    <>
      Tải lên học liệu của bạn.
      <br />
      Nếu bạn có bài viết mẫu cần hiệu đính, hãy sao chép vào phần Ghi chú buổi học.
    </>,
  ],
  no_saved_answer: [
    `저장된 답변이 없습니다.`,
    `No answer saved.`,
    's_preparation.no_saved_answer',
    `没有保存的回答。`,
    `沒有儲存的回覆。`,
    `保存した回答はありません。`,
    `Không có câu trả lời nào được lưu.`,
  ],
  no_shared_answer: [
    `다른 링글러들의 답변이 아직 없습니다. 첫번째 답변 작성자가 되어 보세요.`,
    `Be the first one to share a response to this question!`,
    's_preparation.no_shared_answer',
    `其他Ringle使用者还没有回答。快成为第一个回答问题的人吧。`,
    `還沒有其他Ringler回覆，快成為第一個回覆的人吧！`,
    `他のRinglerたちの回答はまだありません。最初の回答作成者になってみましょう。`,
    `Hãy là người đầu tiên chia sẻ câu trả lời cho câu hỏi này!`,
  ],
  open_note_new_tab: [
    `수업 노트 새 탭으로 열기 (1hr)`,
    `Open in a new tab (1hr)`,
    's_preparation.open_note_new_tab',
    `于新页面中打开课程笔记 (1hr)`,
    `開啟課堂筆記新分頁 (1hr)`,
    `レッスンノートを新しいタブで開く (1hr)`,
    `Mở một tab mới (1hr)`,
  ],
  upload_material_notice: [
    `10mb 미만 PPT, PPTX, DOC, DOCX, PDF 파일 형식만 지원하며, 1개 파일만 업로드 할 수 있습니다.`,
    `You can only upload one file with PPT, PPTX, DOC, DOCX, PDF format less than 10 MB.`,
    's_preparation.upload_material_notice',
    `仅支持10mb以下的PPT, PPTX, DOC, DOCX, PDF文件形式, 并且仅可上传1个文件。`,
    `僅支援小於10MB的PPT、PPTX、DOC、DOCX、PDF檔，且僅可1個檔案。`,
    `アップロードに対応するファイルはサイズが10MB未満、形式はPPT、PPTX、DOC、DOCX、PDFのいずれかのファイル1つのみです。`,
    ``,
  ],
  upload_youtube_link: [
    `수업에서 다루고 싶은 유튜브 영상의 링크를 공유해주세요.`,
    `Upload the YouTube link you want to discuss with the tutor.`,
    's_preparation.upload_youtube_link',
    `请共享课程中希望使用的Youtube视频链接。`,
    `請分享上課時希望進行的YouTube影片連結。`,
    `レッスンで扱いたいYouTube動画のリンクを共有してください。`,
    ``,
  ],
  notice_youtube_link: {
    one: [
      `분 미만의 유튜브 영상 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one YouTube video link less than ( ) minute.`,
      's_preparation.notice_youtube_link.one',
      `分钟以下的Youtube视频链接仅可共享1个。`,
      `僅可分享少於____分鐘的1個YouTube影片連結。`,
      `分未満のYouTube動画1本のリンクのみ共有可能です。`,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_youtube_link.two',
      `希望将课程相关内容留言给教师时, 可将内容粘贴到课程笔记上。`,
      `請於課堂筆記寫下課程相關內容與任課教師分享。`,
      `レッスン内容に関して、チューターに伝えたいことをレッスンノートに入力してください。`,
      ``,
    ],
  },
  upload_article_link: [
    `수업에서 다루고 싶은 아티클의 링크를 공유해주세요.`,
    `Upload the article link you want to discuss with the tutor.`,
    's_preparation.upload_article_link',
    `请共享课程中希望使用的文章链接。`,
    `請分享上課時希望進行的文章連結。`,
    `レッスンで扱いたいアーティクルのリンクを共有してください。`,
    ``,
  ],
  notice_article_link: {
    one: [
      `단어 이내의 아티클 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one article link less than () words.`,
      's_preparation.notice_article_link.one',
      `单词以下的文章链接仅可共享1个。`,
      `僅可分享____個單字以內的1篇文章連結。`,
      `単語以内のアーティクル1つのリンクのみ共有可能です。`,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_article_link.two',
      `希望将课程相关内容留言给教师时, 可将内容粘贴到课程笔记上`,
      `請於課堂筆記寫下課程相關內容與任課教師分享。`,
      `レッスン内容に関して、チューターに伝えたいことをレッスンノートに入力してください。`,
      ``,
    ],
  },
  upload_your_link_to_share: {
    article: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 아티클 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the article link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.article',
      <>
        [Free Topic课程注意事项]
        <br />
        1) 建议最晚于<Span className="font-bold">课程开始前24小时</Span>提供共享的文章链接。
        <br />
        2) 请选择<Span className="font-bold">适合的</Span>内容进行共享。如存在不合理或敏感内容, 可能无法于课程中进行。
      </>,
      <>
        [Free Topic課程注意事項]
        <br />
        1) 建議<Span className="font-bold">最晚於課程開始24小時前</Span>分享文章連結。
        <br />
        2) 請分享<Span className="font-bold">適合上課時進行的內容</Span>。課堂上可能無法進行不適當或敏感的內容。
      </>,
      <>
        [Free Topicレッスンの注意事項]
        <br />
        1) <Span className="font-bold">レッスン開始24時間前</Span>までにアーティクルのリンクを共有することを推奨します。
        <br />
        2) レッスンで <Span className="font-bold">扱うのに適した内容</Span>
        を共有してください。不適合またはデリケートな内容の場合、レッスンで扱うことはできません。
      </>,
      ``,
    ],
    youtube: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 유튜브 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the Youtube link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.youtube',
      <>
        [Free Topic课程注意事项]
        <br />
        1) 建议最晚于<Span className="font-bold">课程开始前24小时</Span>提供共享的Youtube链接。
        <br />
        2) 请选择<Span className="font-bold">适合的</Span>内容进行共享。如存在不合理或敏感内容, 可能无法于课程中进行。
      </>,
      <>
        [Free Topic課程注意事項]
        <br />
        1) 建議<Span className="font-bold">最晚於課程開始24小時前</Span>分享YouTube影片連結。
        <br />
        2) 請分享<Span className="font-bold">適合上課時進行的內容</Span>。課堂上可能無法進行不適當或敏感的內容。
      </>,
      <>
        [Free Topicレッスンの注意事項]
        <br />
        1) <Span className="font-bold">レッスン開始24時間前</Span>までにYouTubeのリンクを共有することを推奨します。
        <br />
        2) レッスンで <Span className="font-bold">扱うのに適した内容</Span>
        を共有してください。不適合またはデリケートな内容の場合、レッスンで扱うことはできません。
      </>,
      ``,
    ],
  },
  btn_delete_link: [`삭제`, ``, 's_preparation.btn_delete_link', `删除`, `刪除`, `削除`, ``],
  btn_upload_link: [`링크 공유`, ``, 's_preparation.btn_upload_link', `链接共享`, `分享連結`, `リンク共有`, ``],
  youtube_link_error: {
    title: [
      `유튜브 링크 형식 오류`,
      `YouTube link error`,
      's_preparation.youtube_link_error.title',
      `Youtube链接格式错误`,
      `YouTube連結格式錯誤`,
      `YouTubeリンク形式エラー`,
      ``,
    ],
    body: [
      `입력하신 링크는 올바른 유튜브 링크 형식이 아닙니다. 링크를 확인해주세요.`,
      `The uploaded YouTube link is in wrong form. Please check it again.`,
      's_preparation.youtube_link_error.body',
      `您提供的Youtube视频链接地址不正确。请进行确认。`,
      `您輸入的連結並非正確的YouTube連結格式，請確認連結。`,
      `入力したリンクは正しいYouTubeのリンクの形式ではありません。リンクを確認してください。`,
      ``,
    ],
  },
  please_connect_directly: [
    `공유한 링크의 내용이 여기서 바로  보이지 않으면,  새 창에서 직접 접속해서 아티클을 보면서 튜터와 수업해주세요.`,
    `If the uploaded link doesn't show up, please open a new window to share it with the tutor.`,
    's_preparation.please_connect_directly',
    `共享链接的内容如果不能即时查看, 请直接连接新的窗口, 边看文章边和教师开始课程。`,
    `無法在這裡看到分享連結的內容時，請另開連結的視窗，一邊觀看文章一邊與任課教師上課。`,
    `共有したリンクの内容がここにすぐ表示されない場合は、新しいウィンドウで直接アクセスし、アーティクルを見ながらチューターとレッスンしてください。`,
    ``,
  ],
  upload_your_link: [
    `링크를 입력하거나 붙여 주세요.`,
    `Enter or paste the webpage link on Lesson Notes.`,
    's_preparation.upload_your_link',
    `请输入或复制链接`,
    `請輸入或貼上連結。`,
    `リンクを入力またはペーストしてください。`,
    ``,
  ],
  ringle_docs_is_being_generated: [
    `Ringle Docs 생성 중입니다.`,
    `Generating Ringle Docs...`,
    's_preparation.ringle_docs_is_being_generated',
    `Ringle Docs生成中`,
    `Ringle Docs生成中`,
    `Ringle Docs作成中です。`,
    `Đang tạo Ringle Docs...`,
  ],
  no_link: [
    `공유된 링크가 없습니다.`,
    `No link has been shared.`,
    's_preparation.no_link',
    `没有共享的网址.`,
    `無分享的連結。`,
    `共有されたリンクがありません。`,
    `Không có link nào được chia sẻ.`,
  ],
  due_to_technical_issues: [
    `해당 수업은 테크 이슈로 구글닥으로 진행되며, 수업권이 보상 지급 됩니다.`,
    `Due to technical issues, this lesson will be conducted with Google Docs. As an apology, we have sent you an excusal credit.`,
    's_preparation.due_to_technical_issues',
    `因系统问题，本课程将使用Google Docs进行，作为补偿将为您提供额外的课程劵.`,
    `因系統問題，本課程將使用Google Docs進行，作爲補償將爲您提供額外的課程劵.`,
    `この授業は、エラーでGoogle Docsで行われ、授業権が補償されます`,
    `Vì vấn đề kĩ thuật, buổi học này sẽ diễn ra trên Google Docs. Chúng tôi gửi bạn một thẻ học thay cho lời xin lỗi.`,
  ],
}
export const s_my_original = {
  saved_webinars: [
    `찜한 웨비나`,
    `Saved Webinars`,
    's_my_original.saved_webinars',
    `收藏的网络研讨会`,
    `我收藏的網路研討會`,
    `お気に入り登録したウェビナー`,
    `Những webinar được lưu`,
  ],
  saved_packets: [
    `찜한 교재`,
    `Favorite Materials`,
    's_my_original.saved_packets',
    `收藏的教材`,
    `我收藏的教材`,
    `お気に入り教材`,
    `Tài liệu của tôi`,
  ],
  save_the_webinar_that_you_want_to_watch_again_: [
    <>
      다시 보고 싶은
      <br />
      웨비나를 찜해보세요.
    </>,
    <>Add your favorite webinars!</>,
    's_my_original.save_the_webinar_that_you_want_to_watch_again_',
    <>
      请收藏希望重新收看的
      <br />
      网络研讨会。
    </>,
    <>
      請收藏想再次觀看的
      <br />
      網路研討會。
    </>,
    <>
      もう一度見たい
      <br />
      ウェビナーをお気に入り登録しましょう。
    </>,
    <>Thêm buổi webinar yêu thích!</>,
  ],
  save_the_packet_that_you_want_to_read_again_: [
    <>
      다시 보고 싶은
      <br />
      교재를 찜해보세요.
    </>,
    <>Add your favorite materials!</>,
    's_my_original.save_the_packet_that_you_want_to_read_again_',
    <>
      请收藏希望重新阅读的
      <br />
      教材。
    </>,
    <>
      請收藏想再次
      <br />
      觀看的教材。
    </>,
    <>
      もう一度見たい
      <br />
      教材をお気に入り登録しましょう。
    </>,
    <>Thêm tài liệu yêu thích!</>,
  ],
  no_packets_saved_yet: [
    `찜한 교재가 없습니다.`,
    `You haven't added any materials to Favorite Materials.`,
    's_my_original.no_packets_saved_yet',
    `没有收藏的教材。`,
    `無收藏的教材。`,
    `お気に入り登録した教材はありません。`,
    `Bạn chưa lưu tài liệu nào.`,
  ],
  no_packets: [
    `선택 가능한 교재가 없습니다.`,
    `No materials available`,
    's_my_original.no_packets',
    `没有可以选择的教材。`,
    `無可選擇的教材。`,
    `選択可能な教材はありません。`,
    `Không có tài liệu nào`,
  ],
  save_daily_briefs: [
    <>
      다시 보고 싶은
      <br /> 데일리 브리프를 찜해보세요.
    </>,
    `Add your favorite daily briefs!`,
    's_my_original.save_daily_briefs',
    <>
      请收藏希望重看的
      <br />
      Daily Brief吧。
    </>,
    <>
      請收藏想再次觀看的
      <br />
      Daily Brief。
    </>,
    <>
      もう一度見たい
      <br /> Daily Briefをお気に入り登録しましょう。
    </>,
    `Thêm daily brief yêu thích của bạn!`,
  ],
  dailybrief: [
    `찜한 데일리 브리프`,
    `Favorite Daily Briefs`,
    's_my_original.dailybrief',
    `收藏的Daily Brief`,
    `我收藏的Daily Brief`,
    `お気に入りDaily Brief`,
    `Daily Brief yêu thích`,
  ],
  favorite_webinar: [
    `찜한 웨비나`,
    `Favorite Webinars`,
    's_my_original.favorite_webinar',
    `收藏的网络研讨会`,
    `我收藏的網路研討會`,
    `お気に入りウェビナー`,
    `Webinar yêu thích`,
  ],
}
export const s_notices = {
  notice: [`공지사항`, `Updates`, 's_notices.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
  notice_list: [
    `공지사항 목록`,
    `Updates List`,
    's_notices.notice_list',
    `通知事项目录`,
    `公告事項清單`,
    `お知らせリスト`,
    `Danh sách cập nhật`,
  ],
}
export const s_invite = {
  recommender_count: [
    `어제 추천한 링글러의 수`,
    `Ringlers who have been recommended`,
    's_invite.recommender_count',
    `昨天推荐的Ringle用户人数`,
    `昨天推薦的Ringler人數`,
    `昨日までに招待したRingler`,
    `Những học viên được giới thiệu đến Ringle`,
  ],
  recommendee_count: [
    `어제 추천으로 가입한 링글러의 수`,
    `Ringlers who signed up with recommendation`,
    's_invite.recommendee_count',
    `昨天通过推荐注册Ringle的用户人数`,
    `昨天因推薦而註冊的Ringler人數`,
    `昨日までに会員登録したRingler`,
    `Học viên Ringle đăng ký qua lời giới thiệu`,
  ],
  people: [`명`, `People`, 's_invite.people', `名`, `名`, `名`, `người`],
  doc: {
    saved_clipboard: [
      <>
        초대 링크가 복사되었습니다.
        <br />
        친구에게 바로 링글을 선물하세요!
      </>,
      `The link has been copied. Invite your friend to Ringle now!`,
      's_invite.doc.saved_clipboard',
      `邀请链接已复制。请复制粘贴至您希望的位置。`,
      `邀請連結複製成功，請於想要的地方複製貼上。`,
      `招待リンクがコピーされました。さっそくお友達に送ってみよう`,
      `Link mời của bạn đã được sao chép vào clipboard.`,
    ],
    thank_you: [
      `링글을 추천해주셔서 감사합니다.`,
      `Thank you for recommending Ringle.`,
      's_invite.doc.thank_you',
      `非常感谢您对Ringle的推荐。`,
      `感謝您推薦Ringle。`,
      `Ringleをご紹介いただきありがとうございます。`,
      `Cảm ơn vì đã giới thiệu Ringle.`,
    ],
    fail_to_post: [
      `포스트가 올라가지 않았습니다.`,
      `Failed to post.`,
      's_invite.doc.fail_to_post',
      `发布失败。`,
      `發文失敗。`,
      `招待できませんでした。`,
      `Không đăng được.`,
    ],
  },
  body_header: {
    title: [
      `친구 초대 이벤트`,
      `Referral Event`,
      's_invite.body_header.title',
      `朋友邀请活动`,
      `邀請朋友活動`,
      `友達紹介イベント`,
      `Sự kiện giới thiệu`,
    ],
    subtitle: [
      `무제한 포인트 적립 & 최대 20회 수업권 혜택`,
      `Unlimited points & max 20 credits`,
      's_invite.body_header.subtitle',
      `无限积分收集&最多20次课程券优惠`,
      `無限累積點數 & 最多20次課程券優惠`,
      `無制限ポイント獲得 & 最大20回レッスンチケット特典`,
      `Không giới hạn điểm & tối đa 20 thẻ học`,
    ],
    description: [
      `아직도 링글러가 아닌 친구가 있다면 지금 바로 추천하세요!!`,
      `Recommend Ringle to your friend!!`,
      's_invite.body_header.description',
      `现在还有不是Ringle用户的朋友, 快去向他推荐吧!!`,
      `如果還有朋友不是Ringler，快去推薦吧！！`,
      `まだRinglerじゃない友達がいたら、今すぐ招待してみよう！`,
      `Giới thiệu Ringle đến bạn bè!`,
    ],
    subheader: [
      <>
        친구를 초대하고
        <br />
        최대 36만 원+α 선물 받으세요!
      </>,
      <>
        Invite your friends
        <br />
        and earn up to $310.34 & more!
      </>,
      's_invite.body_header.subheader',
      <>
        邀请朋友
        <br />
        最多可获得$310.34+α礼物!
      </>,
      <>
        邀請朋友
        <br />
        最多領取$310.34+α禮物！
      </>,
      <>
        お友達を紹介すると
        <br />
        最大$310.34＋他にもプレゼントがもらえる！
      </>,
      <>
        Mời bạn bè
        <br />
        và nhận đến $310.34 & nhiều phần thưởng khác!
      </>,
    ],
  },
  section1: {
    headline: [
      <>
        더 수준 높은 영어가 필요한
        <br />
        모든 분들을 위해
      </>,
      <>
        For everyone
        <br />
        who needs a more sophisticated English
      </>,
      's_invite.section1.headline',
      <>
        为需要更高英文水平的
        <br />
        所有人
      </>,
      <>
        為了需要更高水準英語的
        <br />
        所有人
      </>,
      <>
        よりレベルの高い英語を求める
        <br />
        すべての方のために
      </>,
      <>
        Cho bất kỳ ai
        <br />
        muốn nâng cao trình độ tiếng Anh
      </>,
    ],
  },
  section2: {
    display: [
      <>
        친구에게
        <br />
        링글을 알리기만 해도!
      </>,
      <>
        Just
        <br />
        Tell your friend!
      </>,
      's_invite.section2.display',
      <>
        即使只是让
        <br />
        朋友们知道Ringle!
      </>,
      <>
        光是讓
        <br />
        朋友知道Ringle！
      </>,
      <>
        お友達に
        <br />
        Ringleを広めるだけでも！
      </>,
      <>
        Hãy thoải mái
        <br />
        chia sẻ với bạn bè!
      </>,
    ],
    title: [`스타벅스 (선착순 100명)`, `Starbucks (First 100 people)`, 's_invite.section2.title', ``, ``, ``, ``],
    body: [
      `*해쉬태그 #링글을 남겨주셔야 당첨을 확인할 수 있어요!`,
      `*Hashtag #Ringle to see if you've won!`,
      's_invite.section2.body',
      `*标记#Ringle标签才能确认中奖情况!`,
      `*使用標籤#Ringle才能確認中獎！`,
      `*ハッシュタグ #Ringle をつけると当選確率がアップします！`,
      `*Hashtag #Ringle để xem bạn có thắng không!`,
    ],
  },
  section3: {
    display: [
      <>
        친구가 링글러 되면
        <br />
        나에게 돌아오는 혜택!
      </>,
      <>
        Invite your friend to Ringle
        <br />
        and get rewards!
      </>,
      's_invite.section3.display',
      <>
        朋友成为Ringle用户后
        <br />
        回馈给本人的优惠!
      </>,
      <>
        朋友成為Ringler後，
        <br />
        我能得到的優惠！
      </>,
      <>
        お友達がRinglerになったら
        <br />
        もらえる特典！
      </>,
      <>
        Mời bạn bè
        <br />
        và nhận thưởng!
      </>,
    ],
    blue: [
      `체험만해도`,
      `That includes when your friend just takes a trial lesson`,
      's_invite.section3.blue',
      `仅仅是体验`,
      `光是體驗`,
      `体験するだけでも`,
      `Ngay cả khi bạn bè của bạn chỉ học buổi học thử`,
    ],
    pink: [
      `링글러가 되면`,
      `If you friend becomes a Ringler`,
      's_invite.section3.pink',
      `仅仅是成为Ringle用户`,
      `成為Ringler的話`,
      `Ringlerになったら`,
      `Nếu bạn của bạn trở thành học viên Ringle`,
    ],
    description_box: [
      `5만포인트(최대3명) / 3만포인트(7명) / 이후 1만포인트 🍋`,
      `$43.10 Points (First 3 people)/ $25.86 Points (Next 7 people)/ $8.62 Points (Everyone else) 🍋`,
      's_invite.section3.description_box',
      `$43.1积分(限3名) / $25.86积分(7名) / 之后$8.62积分 🍋`,
      `$43.1P（限3名）／$25.86P（7名）／之後$8.62P🍋`,
      `$43.1ポイント(最大3名様) / $25.86ポイント(7名様) / 以降$8.62ポイント 🍋`,
      `$43.10 điểm (3 người đầu tiên)/ $25.86 điểm (7 người tiếp theo)/ $8.62 điểm (cho tất cả người khác) 🍋`,
    ],
  },
  section4: {
    display: [
      <>
        링글러가 된 친구에게
        <br />
        선물하는 혜택!
      </>,
      <>
        Send gifts to your friend
        <br />
        who just became a Ringler!
      </>,
      's_invite.section4.display',
      <>
        给予成为Ringle用户的朋友,
        <br />
        优惠礼物!
      </>,
      <>
        送給成為Ringler的朋友的
        <br />
        優惠！
      </>,
      <>
        Ringlerになった友達に
        <br />
        プレゼントする特典！
      </>,
      <>
        Gửi quà tặng cho bạn bè
        <br />
        mới trở thành học viên Ringle!
      </>,
    ],
  },
  section5: {
    display: [
      <>
        친구가 링글을 오래할수록
        <br />더 커지는 혜택
      </>,
      <>
        Receive more gifts
        <br />
        if your friend stays with Ringle longer!
      </>,
      's_invite.section5.display',
      <>
        朋友使用Ringle的时间越长
        <br />
        所获得优惠就越多
      </>,
      <>
        朋友使用Ringle的時間越長，
        <br />
        優惠就越多。
      </>,
      <>
        お友達がRingleを長く利用するほど
        <br />
        もらえる特典が増えていく
      </>,
      <>
        Nhận nhiều quà hơn
        <br />
        nếu bạn của bạn học ở Ringle càng lâu!
      </>,
    ],
    count: (n) => [
      <>{n}회</>,
      <>{n} times</>,
      's_invite.section5.count',
      <>{n}次</>,
      <>{n}次</>,
      <>{n}回</>,
      <>{n} lần</>,
    ],
    package: (n) => [
      <>{n}회 패키지 이상 결제시</>,
      <>If your friend purchases packages of more than {n} lessons</>,
      's_invite.section5.package',
      <>{n}次以上礼包购买时</>,
      <>購買{n}次以上禮包時</>,
      <>{n}回以上決済時</>,
      <>Nếu bạn bè của bạn mua gói học với hơn {n} buổi học</>,
    ],
    additional_coupon: [
      `수업권 추가 증정`,
      `You will get additional credits`,
      's_invite.section5.additional_coupon',
      `追加赠送课程券`,
      `額外贈送課程券`,
      `レッスンチケット追加プレゼント`,
      `Bạn sẽ có thêm các thẻ học`,
    ],
    max_coupon: [
      `최대 20회 수업권 증정`,
      `Get max 20 additional credits`,
      's_invite.section5.max_coupon',
      `最多赠送20次的课程券`,
      `最多贈送20次課程券`,
      `最大20回分のレッスンチケットプレゼント`,
      `Nhận thêm tối đa 20 thẻ học`,
    ],
  },
  section6: {
    display: [
      <>
        추천 포인트는
        <br />
        이렇게 쓸 수 있어요!
      </>,
      <>
        You can get gifts
        <br />
        with your referral points&nbsp;
      </>,
      's_invite.section6.display',
      <>
        推荐积分
        <br />
        可以这样使用!
      </>,
      <>
        推薦點數
        <br />
        可以這樣使用！
      </>,
      <>
        招待ポイントは
        <br />
        このように使うことができます！
      </>,
      <>
        Bạn có thể nhận quà
        <br />
        với điểm giới thiệu&nbsp;
      </>,
    ],
    img_alt_1: [
      `링글 수업권 결제`,
      `Purchase Ringle lessons`,
      's_invite.section6.img_alt_1',
      `Ringle课程券付款`,
      `購買Ringle課程券`,
      `Ringleレッスンチケット決済`,
      `Thanh toán buổi học Ringle`,
    ],
    img_alt_2: [
      `스타벅스 카드로 교환`,
      `Exchange with Starbucks Gift Card`,
      's_invite.section6.img_alt_2',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_3: [
      `신세계 상품권 교환`,
      `Exchange with Shinsegae Gift Card`,
      's_invite.section6.img_alt_3',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_4: [
      `링글 수업권 교환`,
      `Exchange with Ringle lessons`,
      's_invite.section6.img_alt_4',
      `兑换为Ringle课程券`,
      `兌換Ringle課程券`,
      `Ringleレッスンチケットに交換`,
      `Đổi lấy buổi học Ringle`,
    ],
  },
  section7: {
    title: [
      <>
        지금 친구에게
        <br />
        링글 수업권을 보내주세요!
      </>,
      <>
        Send your friend
        <br />a Ringle lesson right now!
      </>,
      's_invite.section7.title',
      <>
        现在就给您的朋友
        <br />
        发送Ringle课程券吧!
      </>,
      <>
        現在發送Ringle課程券
        <br />
        給您的朋友吧！
      </>,
      <>
        今すぐ友達に
        <br />
        Ringleのレッスンチケットを贈ってみよう！
      </>,
      <>
        Gửi bạn bè của bạn
        <br />a một buổi học cùng Ringle ngay thôi!
      </>,
    ],
    subtitle: [
      `10초면 끝 ! 지금 바로 보내주세요 !`,
      `It only takes 10 seconds! Send right now!`,
      's_invite.section7.subtitle',
      `仅需10秒! 现在立即发送吧!`,
      `10秒即可！現在立即發送吧！`,
      `たった10秒！今すぐ送ってみよう`,
      `Chỉ mất 10 giây! Gửi ngay thôi!`,
    ],
  },
  banner: {
    normal_title: (name) => [
      <>{name}님 이 적극 추천한 링글</>,
      <>{name} has invited you to Ringle</>,
      's_invite.banner.normal_title',
      <>{name}用户送来了特别的优惠礼物!</>,
      <>{name}送您特別優惠！</>,
      <>{name} さんから特別なプレゼントが届きました！</>,
      <>Món quà đặc biệt từ {name}</>,
    ],
    sub_title: [
      <>영상보고, 프로필 완성하면 무료 체험 수업 가능! </>,
      <>Watch tutorial & complete profile for a free trial!</>,
      's_invite.banner.sub_title',
      <>
        现在就注册会员, 马上进行确认吧 <FeatherChevronRight inline />
      </>,
      <>
        現在就註冊會員，馬上確認吧！
        <FeatherChevronRight inline />
      </>,
      <>
        今すぐ会員登録して確認してみましょう。 <FeatherChevronRight inline />
      </>,
      <>
        Đăng ký để trải nghiệm thử <FeatherChevronRight inline />
      </>,
    ],
    my_code: [`나의코드`, `My Code`, 's_invite.banner.my_code', `我的代码`, `我的代碼`, `マイコード`, `Mã của tôi`],
    share_link: [
      `초대링크 공유`,
      `Share invitation link`,
      's_invite.banner.share_link',
      `分享邀请链接`,
      `分享邀請連結`,
      `招待リンク共有`,
      `Chia sẻ link mời`,
    ],
    button: [
      <>지금 가입하고 무료 체험하러 가기</>,
      <>Join now and get a free lesson</>,
      's_invite.banner.button',
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
    ],
    button2: [
      <>100,000P 받고 링글 시작하기</>,
      <>Sign up & Earn $76 Points</>,
      's_invite.banner.button2',
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
    ],
  },
  kakao: {
    title: [
      `친구 초대 - 링글로 영어 공부하는 친구가 초대했어요!`,
      `Personalized Ringle lessons--Just for me!`,
      's_invite.kakao.title',
      `邀请朋友 - 使用Ringle学习英语的朋友邀请了您!`,
      `邀請朋友 - 使用Ringle學習英語的朋友邀請您！`,
      `友達紹介- Ringleで英語を勉強している友達から紹介が届きました！`,
      `Buổi học được cá nhân hoá tại Ringle--Dành riêng cho bạn!`,
    ],
    description: [
      `지금 초대장으로 가입하시면 바로 드려요 🙂`,
      `Experience 40 minutes of intellectual conversation`,
      's_invite.kakao.description',
      `现在通过邀请函进行注册的话, 马上提供给您🙂`,
      `現在透過邀請函註冊的話，就馬上送您！🙂`,
      `この招待状から会員登録で今すぐプレゼント 🙂`,
      `Trải nghiệm 40 phút trò chuyện sâu sắc`,
    ],
  },
  footer: {
    title: [
      <>10초만에 링글로 친구를 초대해 보세요!</>,
      <>Invite your friend to Ringle in 10 seconds!</>,
      's_invite.footer.title',
      <>仅用10秒, 通过Ringle邀请您的朋友吧!</>,
      <>10秒即可，邀請朋友註冊Ringle吧！</>,
      <>たった10秒で友達にRingleを紹介してみよう！</>,
      <>Mời bạn bè tham gia Ringle chỉ trong 10 giây!</>,
    ],
    copy: [
      `친구에게 초대장 보내기`,
      `Send Invitation`,
      's_invite.footer.copy',
      `向朋友发送邀请函`,
      `向朋友發送邀請函`,
      `招待状を送る`,
      `Gửi lời mời`,
    ],
  },
  sign_up_after_benefit: [
    `회원가입하고 혜택받기`,
    `Sign up and get benefits`,
    's_invite.sign_up_after_benefit',
    `注册会员获得优惠`,
    `註冊會員並領取優惠`,
    `会員登録して特典を受け取る`,
    `Đăng ký và nhận nhiều ưu đãi`,
  ],
  item_list_1: [
    `링글에 대한 솔직한 수업 후기를 남겨주세요!`,
    `Tell us how you felt about the lesson!`,
    's_invite.item_list_1',
    `请留下您对Ringle课程的真实评价!`,
    `請留下您對Ringle課程的真實心得！`,
    `Ringleに対する正直なレッスンレビューを作成してください！`,
    `Hãy chia sẻ cảm nhận của bạn về buổi học nhé!`,
  ],
  item_list_2: [
    `인스타그램에서 #링글 #Ringle 태그`,
    `#Ringle on Instagram`,
    's_invite.item_list_2',
    `于Instagram使用#Ringle标签`,
    `在Instagram使用標籤#Ringle`,
    `Instagramで#リングル #Ringle をタグ付け`,
    `#Ringle trên Instagram`,
  ],
  item_list_3: [
    `추천코드링크 공유와 함께 추천할 친구 @ 소환`,
    `@Tag a friend with an invitation code`,
    's_invite.item_list_3',
    `分享推荐代码链接, @想推荐的朋友`,
    `分享推薦代碼連結並@標記想推薦的朋友`,
    `紹介コードリンクの共有と一緒に紹介した友達を@メンション`,
    `@Tag một người bạn với mã giảm giá`,
  ],
  join_ringle_and_start_to_excel: [
    `영어가 경쟁력이 되는 일대일 화상영어, 링글. 지금 시작하세요!`,
    `Join Ringle and start to excel in English!`,
    's_invite.join_ringle_and_start_to_excel',
    `提高英语竞争力的1对1线上英语，现在开始使用Ringle吧!`,
    `提高英語競爭力的1對1綫上英語，現在開始使用Ringle吧!`,
    `英語を磨くための1on1英会話、Ringle。 今、始めてください`,
    `Học cùng Ringle và phát triển tiếng Anh của bạn!`,
  ],
  portal: {
    subtitle: [
      `‘똑똑한 영어’를 선물하는 방법`,
      `Share the smartest way to improve English fluency`,
      's_invite.portal.subtitle',
      `贈送‘智慧型英语’的方法`,
      `贈送「智慧型英語」的方法`,
      `「賢い英語」をプレゼントする方法`,
      `Chia sẻ bạn bè cách học tiếng Anh thông minh`,
    ],
    title1: [
      `링글을 친구에게`,
      `Invite Your Friends`,
      's_invite.portal.title1',
      `请向朋友`,
      `請向朋友`,
      `Ringleをお友達に`,
      `Mời bạn bè`,
    ],
    title2: [
      `소개해 주세요!`,
      `to Ringle`,
      's_invite.portal.title2',
      `介绍Ringle`,
      `介紹Ringle`,
      `紹介してください。`,
      `học Ringle`,
    ],
    benefit1: {
      title1: [
        `초대장을 받은 친구는`,
        `When your friend signs up with your invite code`,
        's_invite.portal.benefit1.title1',
        `收到邀请函`,
        `收到邀請函的朋友`,
        `招待状を受け取り`,
        `Khi bạn của bạn đăng ký bằng mã mời của bạn`,
      ],
      title2: (amount) => [
        <>회원가입하면 {amount}를 받아요</>,
        <>they’ll receive {amount} in points</>,
        's_invite.portal.benefit1.title2',
        <>注册会员时可领取${amount}</>,
        <>註冊會員時，可領取${amount}。</>,
        <>会員登録すると${amount}もらえます。</>,
        <>họ sẽ nhận được {amount} điểm</>,
      ],
      desc: (amount) => [
        <>({amount}만원 이상 수업권 결제 시, 즉시 할인 가능)</>,
        <>(Can be applied towards purchases over {amount})</>,
        's_invite.portal.benefit1.desc',
        <>购买{amount}以上的课程劵时可立即获得优惠</>,
        <>（購買{amount}以上的課程券時，可立即享折扣。）</>,
        <>(購入額{amount}以上の決済時に適用されます)</>,
        <>(Có thể áp dụng cho lần thanh toán trên {amount})</>,
      ],
      caption: [
        `* 회원 가입 기준: ‘본인 인증’ 완료`,
        `Must complete identity verification to sign up`,
        's_invite.portal.benefit1.caption',
        `*注册会员标准：完成‘本人认证’`,
        `*註冊會員標準：完成「身分驗證」`,
        `*会員登録には本人認証が必要です`,
        `*Phải hoàn thành xác minh danh tính để đăng ký`,
      ],
    },
    benefit2: {
      title1: [
        `친구가 첫 수업을 완료하면`,
        `When your friend completes the first paid lesson`,
        's_invite.portal.benefit2.title1',
        `朋友完成首次课程时`,
        `朋友完成首次課程時，`,
        `招待した友達が最初のレッスンを完了すると`,
        `Khi bạn của bạn hoàn thành buổi học đã thanh toán đầu tiên`,
      ],
      title2: (amount) => [
        <>나도 {amount}를 받아요</>,
        <>you'll earn {amount} in points</>,
        's_invite.portal.benefit2.title2',
        <>您也可领取${amount}</>,
        <>您也可領取${amount}。</>,
        <>あなたにも${amount}付与されます。</>,
        <>bạn nhận được {amount} điểm</>,
      ],
      desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Can be exchanged to Ringle credits)`,
        's_invite.portal.benefit2.desc',
        `（可兑换新世界商品劵，星巴克饮料劵，Ringle课程劵）`,
        `（可兌換新世界商品券、星巴克飲料券、Ringle課程券）`,
        `(Ringleレッスンチケットに交換可)`,
        `(Có thể đổi lấy thẻ học Ringle)`,
      ],
      caption: (amount) => [
        <>* 첫 수업 기준: ‘{amount}만원 이상’ 유료 수업 완료</>,
        <>First purchased lesson must be from a package over {amount}</>,
        's_invite.portal.benefit2.caption',
        <>首次课程标准：完成‘{amount}以上’付费课程</>,
        <>*首次課程標準：完成「{amount}以上」付費課程</>,
        <>*初回レッスンとは、{amount}以上の購入されたパックを指します。</>,
        <>*Gói học thanh toán đầu tiên phải trị giá hơn {amount}</>,
      ],
    },
    guide: {
      title: [
        `꼭 읽어주세요!`,
        `Please read the terms!`,
        's_invite.portal.guide.title',
        `必读！`,
        `必讀！`,
        `必ずお読みください。`,
        `Vui lòng đọc điều khoản!`,
      ],
      list_1: [
        `신규 회원 가입한 대상에게만 포인트가 제공됩니다.`,
        `Points will be provided only for new Ringle students.`,
        's_invite.portal.guide.list_1',
        `积分只提供给新注册的会员`,
        `僅提供新註冊會員的學生點數。`,
        `新規会員登録した方のみを対象にポイントが付与されます。`,
        `Điểm chỉ dành cho học viên mới.`,
      ],
      list_2: (amount, normal_price) => [
        <>
          포인트는 초대받은 친구에게는 가입 즉시 지급, 나에게는 친구가 실결제가 {amount}만원 이상 수업권 구매 후, 첫
          수업을 완료하는 시점에 지급됩니다.
        </>,
        <>
          Your friend will earn {amount} points right after they sign up. You will earn {amount} points when your friend
          completes the first lesson of the credits priced over {normal_price}.
        </>,
        's_invite.portal.guide.list_2',
        <>
          当受邀的朋友注册时，我们会立刻发送积分给朋友。当朋友实际付款购买{normal_price}
          以上的课程并完成首次课程时，我们将发送积分给您。
        </>,
        <>
          當受邀的朋友註冊時，我們將立即發送點數給朋友。當朋友實際付款購買{normal_price}
          以上的課程券並完成首次課程時，我們將發送點數給您。
        </>,
        <>
          紹介された友達は会員登録するとその場で{amount}ポイントが付与されます。あなた(紹介者)は、友達が購入額
          {normal_price}以上のレッスンチケットを購入し初めてのレッスンを完了した時点で{amount}ポイントが付与されます。
        </>,
        <>
          Bạn của bạn sẽ nhận được {amount} điểm ngay khi đăng ký. Bạn sẽ nhận được {amount} điểm khi bạn của bạn hoàn
          thành buổi học đầu tiên trong gói học trị giá hơn {normal_price}.
        </>,
      ],
      list_3: (amount, benefit) => [
        <>
          친구초대 혜택은 매 월 1명까지 {amount}, 이후 {benefit}이 제공됩니다
        </>,
        <>
          The {amount} referral points will be provided once a month. Then, earn {benefit} for each additional friend.
        </>,
        's_invite.portal.guide.list_3',
        <>
          每月邀请1名朋友时提供{amount}，之后提供{benefit}。
        </>,
        <>
          每月邀請1名朋友時提供{amount}，之後提供{benefit}。
        </>,
        <>
          友達紹介特典の{amount}ポイントは毎月1人分までとし、以降{benefit}ポイントが付与されます
        </>,
        <>
          {amount} điểm giới thiệu sẽ được nhận một lần mỗi tháng. Mỗi người bạn mới bạn sẽ nhận thêm {benefit} điểm.
        </>,
      ],
      list_4: [
        `제공받은 친구초대 포인트는 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged to Ringle credits.`,
        's_invite.portal.guide.list_4',
        `领取的邀请朋友积分可兑换Ringle课程劵。`,
        `可使用領取的邀請朋友點數兌換Ringle課程券。`,
        `友達紹介ポイントはRingleレッスンチケットに交換できます。`,
        `Điểm giới thiệu có thể đổi lấy thẻ học Ringle.`,
      ],
      list_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Inviting duplicate accounts may result in no points or points cancellation.`,
        's_invite.portal.guide.list_5',
        `若发现账户重复邀请等非正当参与行为时，将不会发送积分或撤回积分。`,
        `判斷帳戶重複邀請等異常參與時，將可能不發送點數或撤回。`,
        `同一人物の重複招待など不適切な会員登録が判明した場合、ポイントの付与対象外となる又は没収されることがあります。`,
        `Những bất thường như mở tài khoản trùng lặp sẽ không nhận dược điểm hoặc bị hủy điểm.`,
      ],
    },
    floating: {
      title: (N) => [
        <>{N}월 친구초대 최대 혜택</>,
        <>{N} Invite & Earn Offer</>,
        's_invite.portal.floating.title',
        <>{N}月邀请朋友最高优惠</>,
        <>{N}月邀請朋友最高優惠</>,
        <>{N}月の友達紹介最大特典</>,
        <>Tháng {N} Mời & nhận thưởng</>,
      ],
      btn1: [
        `초대장 보내기`,
        `Invite Friends`,
        's_invite.portal.floating.btn1',
        `发送邀请函`,
        `發送邀請函`,
        `招待状を送る`,
        `Mời bạn bè`,
      ],
      btn2: [
        `링크 복사`,
        `Copy Link`,
        's_invite.portal.floating.btn2',
        `复制链接`,
        `複製連結`,
        `リンクをコピー`,
        `Sao chép Link`,
      ],
    },
    event: {
      subtitle: [
        `링글 친구 초대 이벤트`,
        `Ringle Referral Event`,
        's_invite.portal.event.subtitle',
        `Ringle Referral Event`,
        `Ringle Referral Event`,
        `Ringle Referral Event`,
        `Ringle Referral Event`,
      ],
      title: [
        <>
          지금, <span className="font-bold underline underline-offset-[10px]">링글 추천하기</span>
          <br />
          가장 좋은 타이밍!
        </>,
        <>
          Now is the best time
          <br />
          to <span className="font-bold underline underline-offset-[10px]">invite your friends!</span>
        </>,
        's_invite.portal.event.title',
        <>
          现在立即
          <br />
          <span className="font-bold underline underline-offset-[10px]">向好友推荐 Ringle！</span>
        </>,
        <>
          現在馬上
          <br />
          <span className="font-bold underline underline-offset-[10px]">向好友推薦 Ringle ！</span>
        </>,
        <>
          今こそ<span className="font-bold underline underline-offset-[10px]">Ringleを</span>
          <br />
          オススメする
        </>,
        <>
          Đã đến lúc
          <br />
          <span className="font-bold underline underline-offset-[10px]">giới thiệu Ringle!</span>
        </>,
      ],
      desc: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
          <br />
          <span className="underline text-h4 font-bold underline-offset-[6px]">오직 3주간의 특별한 기회</span>를 놓치지
          마세요!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc',
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
      ],
      date: [
        `22.08.23 (화) - 09.08 (목)`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        's_invite.portal.event.date',
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
      ],
      desc2: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
        </>,
        <>
          Get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc2',
        <>
          邀请人和被邀请好友
          <br />
          Ringle 好友推荐活动
        </>,
        <>
          邀請人和被邀請好友
          <br />
          Ringle 好友推薦活動
        </>,
        <>
          招待した人もされた人もみんな得する
          <br />
          Ringle招待イベント
        </>,
        <>
          Event dành
          <br />
          cho cả bạn và bạn bè
        </>,
      ],
      section_subtitle_1: [
        `더 많이 알릴수록`,
        `The More You Reach Out`,
        's_invite.portal.event.section_subtitle_1',
        `宣传得越多`,
        `分享越多`,
        `広めれば広めるほど`,
        `Càng giới thiệu rộng rãi`,
      ],
      section_title_1: [
        `쌓이는 나의 혜택`,
        `The More Rewards
You Get!`,
        's_invite.portal.event.section_title_1',
        `优惠就越多`,
        `優惠就越多`,
        `貯まる特典`,
        `Ưu đãi càng nhiều`,
      ],
      section_list_1_subtitle: [
        <>
          내 초대 코드로 가입한 친구가 <span className="text-purple-500">첫 수업을 완료</span> 하면
        </>,
        <>
          When your friend <span className="text-purple-500">completes the first paid lesson*</span>
        </>,
        's_invite.portal.event.section_list_1_subtitle',
        <>
          只要通过邀请代码加入会员的好友<span className="text-purple-500">完成第一节课，</span>
        </>,
        <>
          只要透過邀請代碼加入會員的好友<span className="text-purple-500">完成第一堂課，</span>
        </>,
        <>
          招待コードで入会したフレンドが<span className="text-purple-500">初回レッスンを完了</span>すると
        </>,
        <>
          Khi người học do bạn giới thiệu <span className="text-purple-500">hoàn thành buổi học đầu tiên</span>
        </>,
      ],
      section_list_1_title: [
        `5만 링글 포인트를 드려요.`,
        `You get $38p referral points`,
        's_invite.portal.event.section_list_1_title',
        `邀请人即可获取 5 万 Ringle 积分。`,
        `邀請人即可獲得 5 萬 Ringle點數。`,
        `5万ポイントを贈呈します。`,
        `bạn sẽ nhận được 50,000 Ringle Point.`,
      ],
      section_list_1_desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Exchange for SSG/Starbucks or Ringle credits)`,
        's_invite.portal.event.section_list_1_desc',
        `（可以换取 SSG 商品券、星巴克商品券、Ringle 课程券）`,
        `（可以兌換 SSG 商品券、星巴克商品券、Ringle 課程券）`,
        `（SSG、スターバックス商品券、レッスンチケットへ交換可能）`,
        `(Có thể hoán đổi thành phiếu mua hàng SSG, Starbucks, thẻ học Ringle)`,
      ],
      section_desc_1: [
        `* 첫 수업 완료 기준 : 30만원 이상 수업권 구매 후 수업 완료`,
        `* First lesson completed from a package worth $231+`,
        's_invite.portal.event.section_desc_1',
        `* 第一节课完成标准：购买 30 万韩元以上课程券后并完成课程。`,
        `* 第一堂課完成標準：購買 30 萬韓元以上課程券後並完成課程。`,
        `* 初回レッスン完了基準：30万ウォン以上のレッスンチケットを購入後、レッスンを完了`,
        `*Tiêu chuẩn hoàn thành buổi học đầu tiên: Đã mua thẻ học trên 300,000 won và hoàn thành buổi học`,
      ],
      section_subtitle_2: [
        `내 친구에게도`,
        `Give Your Friends`,
        's_invite.portal.event.section_subtitle_2',
        `给好友`,
        `給好友`,
        `フレンドにも`,
        `Ưu đãi cần thiết nhất`,
      ],
      section_title_2: [
        `가장 필요한 혜택`,
        `What They Need
the Most`,
        's_invite.portal.event.section_title_2',
        `最需要的优惠`,
        `最需要的優惠`,
        `最も必要な特典`,
        `cho cả bạn của bạn`,
      ],
      section_list_2_subtitle: [
        `내 초대 코드로 가입한 친구는`,
        `When your friends sign up`,
        's_invite.portal.event.section_list_2_subtitle',
        `通过邀请代码加入会员后即可`,
        `通過邀請代碼加入會員後即可`,
        `招待コードで入会すると即`,
        `Khi đăng ký thành viên bằng mã giới thiệu`,
      ],
      section_list_2_title: [
        `5만 링글 포인트를 받아요.`,
        `They get $38p referral points`,
        's_invite.portal.event.section_list_2_title',
        `获取 5 万 Ringle 积分。`,
        `獲取 5 萬 Ringle點數。`,
        `5万ポイントを受け取れます。`,
        `sẽ được cấp ngay 50,000 Ringle Point.`,
      ],
      section_desc_2: [
        `* 30만원 이상 수업권 구매 시 사용 가능`,
        `* Can be used toward packages worth $231+`,
        's_invite.portal.event.section_desc_2',
        `* 购买 30 万韩元以上课程券时可以使用。`,
        `* 購買 30 萬韓元以上課程券時可以使用。`,
        `* 30万ウォン以上のレッスンチケットを購入時使用可能`,
        `* Có thể sử dụng khi mua thẻ học trên 300,000 won.`,
      ],
      terms_title: [
        `유의 사항`,
        `Terms and Conditions`,
        's_invite.portal.event.terms_title',
        `注意事项`,
        `注意事項`,
        `注意事項`,
        `Lưu ý`,
      ],
      terms_subtitle: [
        `[포인트 지급 및 사용 안내]`,
        `[Issuance and use of points]`,
        's_invite.portal.event.terms_subtitle',
        `【积分支付和使用指南】`,
        `【獲得點數及使用指南】`,
        `[ポイント支給および使用上のご案内]`,
        `[Hướng dẫn cấp và sử dụng Point]`,
      ],
      terms_1: [
        `초대받은 친구에게는 가입 즉시 포인트가 지급됩니다.`,
        `Your friend will earn referral points right after they sign up.`,
        's_invite.portal.event.terms_1',
        `被邀请好友加入会员后即可获取积分。`,
        `被邀請好友加入會員後即可獲得點數。`,
        `招待を受けたフレンドには入会後、即時ポイントが支給されます。`,
        `Người được giới thiệu sẽ nhận được Point ngay khi đăng ký thành viên.`,
      ],
      terms_2: [
        `나에게는 친구가 실결제가 30만원 이상 수업권 구매 후, 첫 수업을 완료하는 시점에 지급됩니다.`,
        `You will earn referral points when your friend completes the first lesson after purchasing packages worth $230 or more excluding discounts or points.`,
        's_invite.portal.event.terms_2',
        `被邀请人购买 30 万韩元以上课程券，并完成第一节课后，邀请人即可获取积分。`,
        `被邀請人購買 30 萬韓元以上課程券，並完成第一堂課後，邀請人即可獲得點數。`,
        `招待者はフレンドが30万ウォン以上のレッスンチケットを購入、決済完了後、初回レッスンを完了した時点でポイントが支給されます。`,
        `Đối với người giới thiệu, Point sẽ được cấp sau khi người được giới thiệu mua thẻ học trên 300,000 won và hoàn thành buổi học.`,
      ],
      terms_3: [
        `제공받은 친구 초대 포인트는 SSG, 스타벅스 상품권, 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged for SSG or Starbucks gift cards or Ringle credits.`,
        's_invite.portal.event.terms_3',
        `获取的好友推荐活动积分可以换取 SSG 商品券、星巴克商品券、Ringle 课程券。`,
        `獲得的好友推薦活動點數可以換取 SSG 商品券、星巴克商品券、Ringle 課程券。`,
        `受け取ったフレンド招待ポイントはSSG、スターバックス商品券、リンガルレッスンチケットへ交換可能です。`,
        `Point nhận được khi giới thiệu người dùng có thể được hoán đổi thành phiếu mua hàng SSG, Starbuck, thẻ học Ringle.`,
      ],
      terms_4: [
        `친구 초대 혜택은 매 월 1명까지 ₩50,000, 이후 ₩10,000이 제공됩니다.`,
        `You can earn $38p referral points once a month when a friend signs up with your referral code and $8p referral points for each additional friend who signs up. `,
        's_invite.portal.event.terms_4',
        `好友推荐活动优惠每月第 1 人为5万积分，之后为1万积分。`,
        `好友推薦活動優惠每月第 1 人爲 5 萬點數，之後爲 1 萬點數。`,
        `フレンド招待特典は毎月1名まで5万ウォン分、以降1万ウォン分が提供されます。`,
        `Ưu đãi giới thiệu mỗi tháng ₩50,000 cho người đầu tiên, ₩10,000 từ người thứ hai trở đi.`,
      ],
      terms_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Invalid participation, such as inviting duplicate accounts, may result in no points or points cancellation.`,
        's_invite.portal.event.terms_5',
        `如果被判定为非正常参与，如使用本人账号重复邀请等，积分有可能中断支付或被收回。`,
        `如果被判定爲非正常參與，如使用本人賬號重複邀請等，可能無法獲得點數或被收回。`,
        `本人アカウントから重複招待などの異常な参加と判断した場合は、ポイントの不支給や回収をすることがあります。`,
        `Trường hợp phát hiện sử dụng nhiều tài khoản để dùng mã mời của bản thân, Point được cấp có thể bị thu hồi.`,
      ],
      terms_6: [
        `지급된 포인트의 유효기간은 30일입니다.`,
        `Points issued to your account for this event are valid for 30 days after the issue date.`,
        's_invite.portal.event.terms_6',
        `获取的积分有效期为 30 天。`,
        `獲得點數的有效期限爲 30 天。`,
        `支給されたポイントの有効期間は30日間です。`,
        `Point được cấp có thời hạn sử dụng trong vòng 30 ngày.`,
      ],
    },
    dashboard: {
      friend: [
        `내 초대 코드로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend',
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
      ],
      point: [
        `누적된 친구 초대 포인트`,
        `Referral points earned`,
        's_invite.portal.dashboard.point',
        `邀请朋友积分`,
        `邀請朋友點數`,
        `友達紹介ポイント`,
        `Điểm giới thiệu`,
      ],
      friend_count: (count) => [
        <>{count}명</>,
        <>{count}</>,
        's_invite.portal.dashboard.friend_count',
        <>{count}</>,
        <>{count}</>,
        <>{count}</>,
        <>{count}</>,
      ],
      point_count: (point) => [
        <>{point}</>,
        <>{point}</>,
        's_invite.portal.dashboard.point_count',
        <>{point}</>,
        <>{point}</>,
        <>{point}</>,
        <>{point}</>,
      ],
      friend_promo: [
        `이벤트 기간 중 초대로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend_promo',
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
      ],
      point_promo: [
        `이벤트 기간 중 받은 친구 초대 포인트`,
        `Referral points earned`,
        's_invite.portal.dashboard.point_promo',
        `邀请朋友积分`,
        `邀請朋友點數`,
        `友達紹介ポイント`,
        `Điểm giới thiệu`,
      ],
    },
    referral: {
      code: (referralCode) => [
        <>내 초대 코드 : {referralCode}</>,
        <>My referral code : {referralCode}</>,
        's_invite.portal.referral.code',
        <>我的推荐代码 : {referralCode}</>,
        <>我的推薦代碼 : {referralCode}</>,
        <>マイ紹介コード : {referralCode}</>,
        <>Mã giới thiệu của tôi : {referralCode}</>,
      ],
    },
    my_benefit: [
      `나의 혜택 보기`,
      `Rewards for You`,
      's_invite.portal.my_benefit',
      `Rewards for You`,
      `Rewards for You`,
      `Rewards for You`,
      `Rewards for You`,
    ],
    friend_benefit: [
      `친구 혜택 보기`,
      `Rewards for Friends`,
      's_invite.portal.friend_benefit',
      `Rewards for Friends`,
      `Rewards for Friends`,
      `Rewards for Friends`,
      `Rewards for Friends`,
    ],
    btn: {
      kakao_short: [
        `카카오톡 공유하기`,
        `Share via KakaoTalk`,
        's_invite.portal.btn.kakao_short',
        `分享至 Kakao Talk`,
        `分享至 Kakao Talk`,
        `カカオトークで共有`,
        `Chia sẻ qua KakaoTalk`,
      ],
      kakao_long: [
        `카카오톡으로 초대장 공유하기`,
        `Share invite via KakaoTalk`,
        's_invite.portal.btn.kakao_long',
        `用 Kakao Talk 分享邀请函`,
        `用 Kakao Talk 分享邀請函`,
        `カカオトークで招待状を共有`,
        `Chia sẻ thư mời qua KakaoTalk`,
      ],
      link_short: [
        `초대장 공유하기`,
        `Copy link`,
        's_invite.portal.btn.link_short',
        `分享邀请函`,
        `分享邀請函`,
        `招待状を共有`,
        `Chia sẻ thư mời`,
      ],
      link_long: [
        `친구 초대장 링크 복사하기`,
        `Copy invite link`,
        's_invite.portal.btn.link_long',
        `复制好友邀请链接`,
        `複製好友邀請連結`,
        `フレンドの招待状リンクをコピー`,
        `Sao chép thư mời bạn bè`,
      ],
      kakao_long_promo: [
        `카카오톡으로 초대장 공유하기`,
        `Send invitation via KakaoTalk`,
        's_invite.portal.btn.kakao_long_promo',
        `用 Kakao Talk 分享邀请函`,
        `用 Kakao Talk 分享邀請函`,
        `カカオトークで招待状を共有`,
        `Chia sẻ thư mời qua KakaoTalk`,
      ],
      link_long_promo: [
        `초대장 링크 복사하기`,
        `Copy invite link`,
        's_invite.portal.btn.link_long_promo',
        `复制好友邀请链接`,
        `複製好友邀請連結`,
        `フレンドの招待状リンクをコピー`,
        `Sao chép thư mời bạn bè`,
      ],
    },
    share: {
      desc: [
        <>
          공유하기/복사하기를 클릭하면
          <br />내 초대 코드가 공유돼요!
        </>,
        <>
          Simply click the buttons below
          <br />
          to share your referral code!
        </>,
        's_invite.portal.share.desc',
        `点击分享/复制按钮即可分享邀请代码！`,
        `點擊分享/複製按鈕即可分享邀請代碼！`,
        <>
          共有/コピーをクリックで
          <br />
          招待コードが共有されます！
        </>,
        <>
          Khi nhấp vào Chia sẻ/Sao chép
          <br />
          Mã giới thiệu sẽ được chia sẻ!
        </>,
      ],
    },
  },
}
export const s_lesson = {
  example: [`예문`, `Example`, 's_lesson.example', `例句`, `例句`, `例文`, `Ví dụ`],
  does_not_provide_expressions: [
    `해당 교재는 핵심 표현이 제공되지 않습니다.`,
    `This lesson material does not have expressions.`,
    's_lesson.does_not_provide_expressions',
    `该教材不提供核心表达。`,
    `該教材未提供核心用法。`,
    `この教材はキーフレーズを提供していません。`,
    `Tài liệu này không có các mẫu câu.`,
  ],
  google_translator: [
    `구글 번역기`,
    `Google Translate`,
    's_lesson.google_translator',
    `谷歌翻译器`,
    `Google翻譯機`,
    `Google翻訳`,
    `Google Dịch`,
  ],
  translate: [`번역하기`, `Translate`, 's_lesson.translate', `翻译`, `翻譯`, `翻訳する`, `Dịch`],
}
export const s_evaluation = {
  thank_you: [
    `평가가 완료되었습니다. 감사합니다.`,
    `Your evaluation has been completed. Thank you.`,
    's_evaluation.thank_you',
    `评价已完成。非常感谢。`,
    `已完成評論，謝謝。`,
    `評価が完了しました。ありがとうございました。`,
    `Bạn đã hoàn thành đánh giá. Cảm ơn bạn.`,
  ],
  help: [`지원 요청`, `Help`, 's_evaluation.help', `请求支持`, `要求支援`, `サポートリクエスト`, `Hỗ trợ`],
}
export const s_review = {
  lesson_review: [
    `수업 후기`,
    `Lesson Reviews`,
    's_review.lesson_review',
    `课程评价`,
    `課程心得`,
    `レッスンのレビュー`,
    `Đánh giá buổi học`,
  ],
  customer_review: [
    `고객 후기`,
    `Testimonials`,
    's_review.customer_review',
    `用户评价`,
    `學生心得`,
    `お客様のレビュー`,
    `Chia sẻ của khách hàng`,
  ],
  so_far_n_reviews_written: (n) => [
    <>
      현재까지
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      개의 수업 후기가 작성되었습니다.
    </>,
    <>
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      reviews
    </>,
    's_review.so_far_n_reviews_written',
    <>
      当前为止, 已完成
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      个课程的评价。
    </>,
    <>
      至目前為止已填寫
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      個課程心得。
    </>,
    <>
      現在まで
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      件のレッスンレビューが作成されました。
    </>,
    <>
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      đánh giá
    </>,
  ],
  view_all: [`전체보기`, `View all`, 's_review.view_all', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  company_using_english: [
    `영어 쓰는 회사`,
    `Global companies`,
    's_review.company_using_english',
    `使用英语的公司`,
    `使用英語的公司`,
    `英語を使う会社`,
    `Công ty toàn cầu`,
  ],
  presentation_report_paper: [
    `발표/보고/논문`,
    `Presentation/Report/Paper`,
    's_review.presentation_report_paper',
    `发表/报告/论文`,
    `發表／報告／論文`,
    `発表/報告/論文`,
    `Thuyết trình/Báo cáo/Nghiên cứu`,
  ],
  interview_essay: [
    `인터뷰/에세이`,
    `Interview/Essay`,
    's_review.interview_essay',
    `面试/短文`,
    `面試／申論`,
    `面接/エッセイ`,
    `Phỏng vấn/Bài luận`,
  ],
  study_abroad_immigrant: [
    `유학/해외거주`,
    `Study Abroad/Immigration`,
    's_review.study_abroad_immigrant',
    `留学/海外居住`,
    `留學／海外居住`,
    `留学/海外居住`,
    `Du học/Định cư`,
  ],
  tutors_who_fit_me: [
    `핏이 맞는 튜터`,
    `Tutors who fit me`,
    's_review.tutors_who_fit_me',
    `适合自身的教师`,
    `適合自己的任課教師`,
    `よく合うチューター`,
    `Gia sư phù hợp với tôi`,
  ],
  free_schedule: [
    `자유로운 스케쥴`,
    `Flexible schedule`,
    's_review.free_schedule',
    `自由随心的时间表`,
    `自由自在的日程`,
    `自由なスケジュール`,
    `Thời khoá biểu linh hoạt`,
  ],
  overcome_fear: [
    `두려움 극복`,
    `Overcome fear`,
    's_review.overcome_fear',
    `克服内心的恐惧`,
    `克服恐懼`,
    `恐怖克服`,
    `Vượt qua nỗi sợ`,
  ],
  most_frequency: [
    `내가 사용한 단어`,
    `View your words`,
    's_review.most_frequency',
    `我使用的单词`,
    `我使用的單字`,
    `自分が使用した単語`,
    `Xem các từ của bạn`,
  ],
  filler_word_desc: [
    `필러워드(문장 사이를 채워 주는 영어 추임새, ex)Hmm~, I mean~)`,
    `Filler words (words used to mark a pause or hesitation, ex. Hmm, like)`,
    's_review.filler_word_desc',
    `填充词(填写句子中间的英语助词, ex)Hmm~, I mean~)`,
    `填補詞（句子中間的語助詞，ex) Hmm~, I mean~）`,
    `つなぎ言葉(Filler Word)会話の間を埋める言葉 例)Hmm や I meanなど)`,
    `Filler words (những từ dùng cho khoảng ngừng khi cần suy nghĩ để tìm ý, như Hmm, like)`,
  ],
  see_recommended_synonyms_list: [
    `추천 동의어 리스트`,
    `View suggested synonyms`,
    's_review.see_recommended_synonyms_list',
    `推荐的同义词列表`,
    `推薦的同義詞清單`,
    `おすすめ同義語リスト`,
    `Xem các từ đồng nghĩa được đề xuất`,
  ],
  recorded_script: [
    <>
      수업 녹음 및 스크립트는 Zoom 기능을 활용하고 있습니다.
      <br />
      유의사항
      <br /> 동시에 말하는 경우 스크립트가 정확하지 않을 수 있습니다.
      <br /> 원어민 관점에서 영어 발음이 분석되어 일부 스크립트가 정확하지 않을 수 있습니다.
    </>,
    <>
      Ringle provides lesson recordings and script through Zoom's features.
      <br />
      Notice
      <br /> When the student and the tutor speak at the same time, the script may be inaccurate.
      <br />- Some parts of the script may not be accurate, as the conversation is processed based on native speakers
      pronunciation.
    </>,
    's_review.recorded_script',
    <>
      使用zoom记录并生成课堂录音和文本。
      <br />
      注意事项
      <br /> 教师和本人同时说话时, 有可能发生文本不准确的情况。
      <br /> 因以母语使用者为基准分析英语发音, 部分文本可能出现误差。
    </>,
    <>
      使用zoom記錄並生成課堂錄音和文本。
      <br />
      注意事項
      <br />- 教師和本人同時說話時，有可能發生文本不準確的情況。
      <br />- 因以母語人士為基準分析英語發音，部分文本可能有誤。
    </>,
    <>
      レッスンの録音及びスクリプトはZoomの機能を使用しています。
      <br />
      注意事項
      <br />- 同時に話す場合、スクリプトが的確でない可能性があります。
      <br />- ネイティブの観点から英語の発音が分析されるため、一部のスクリプトが正確では ないことがあります。
    </>,
    <>
      Ringle cung cấp ghi âm và bản ghi buổi học thông qua tính năng của Zoom.
      <br />
      Lưu ý
      <br />- Nếu gia sư và học viên nói cùng một lúc, bản ghi có thể không chính xác.
      <br />- Một số phần của bản ghi có thể không chính xác vì hội thoại được xử lý dựa trên cách phát âm của người bản
      xứ.
    </>,
  ],
  suggestions: [
    <>
      Filler Word는 like, um과 같이 의미 없이 문장에서 사용되는 단어들을 말합니다.
      <br />
      주로 사용한 단어의 동의어를 확인하고, 다음 수업에서 활용해보세요.
    </>,
    <>
      In this part, you can view the analysis of your speech. You can check which vocabularies are most frequently used
      by native speakers, see synonyms, and spot filler words you've used. Filler words refer to words spoken in
      conversation without meaning, in order to signal a pause or hesitation.
      <br />
      Also, check out synonyms of your most frequently used words, and use them in your next lesson.
      <br />
      The percentage of filler word shows the probability of you using filler words more than once, for every sentence
      you speak. Appropriate rate for this category is about 30%.
    </>,
    's_review.suggestions',
    <>
      Filler Word是指诸如like, um等于文章中不具备任何意义的词汇。
      <br />
      请对主要使用的单词同义词进行确认, 并将其运用于之后的课程中。
    </>,
    <>
      Filler Word意指句子中所使用如like、um等無意義的單字。
      <br />
      請確認主要使用的單字同義詞，並運用於下一次課程。
    </>,
    <>
      つなぎ言葉(Filler Word)は「like」「um」のような会話の間を埋める用途の意味を持たない言葉のことです。
      <br />
      頻繫に使ったつなぎ言葉の同義語を確認し、次のレッスンで活用してみましょう。
    </>,
    <>
      Trong phần này, bạn có thể xem phân tích bài nói. Bạn có thể kiểm tra từ vựng nào được dùng nhiều bởi người bản
      xứ, xem từ đồng nghĩa, và những filler words bạn đã dùng. Filler words là những từ sử dụng khi nói nhưng không có
      nghĩa gì hết. Nó thường dùng để thể hiện một khoảng dừng hoặc ngập ngừng.
      <br />
      Đừng quên xem từ những đồng nghĩa thay thế cho từ bạn thường dùng và áp dụng những từ đồng nghĩa này trong buổi
      học sau.
      <br />
      Phần trăm filler word thể hiện xác suất bạn sử dụng những từ này nhiều hơn một lần trong mỗi câu nói. Tỷ lệ phù
      hợp là khoảng 30%.
    </>,
  ],
  feedback: [
    `튜터가 남긴 나의 영어 실력에 대한 평가 점수, 피드백 및 교정결과를 확인할 수 있습니다. 평가 점수를 바탕으로 IELTS, TOEIC SPEAKING, TOEFL 예상 점수도 확인할 수 있습니다.`,
    `You can view your tutor's evaluation, feedback, and corrections. You can also get expected scores for standardized English exams, such as IELTS, TOEIC SPEAKING, and TOEFL.`,
    's_review.feedback',
    `可确认教师对本人英语实力的评价分数, 反馈及校正结果。同时以评价分数为基础, 可对IELTS, TOEIC SPEAKING, TOEFL等考试项目进行分数预测。`,
    `可確認任課教師對您的英語實力留下的評分、意見回饋及糾正結果。您可以評分為基礎，確認IELTS、TOEIC SPEAKING、TOEFL的預測分數。`,
    `チューターが作成した英語力に対する評価点数、フィードバックおよび校正結果を確認することができます。評価点数にもとづき、IELTS、TOEIC SPEAKING、TOEFLの予想点数も確認することができます。`,
    `Bạn có thể xem đánh giá, nhận xét và sửa lỗi của gia sư. Bạn sẽ thấy điểm của bạn hiện giờ so sánh với các kỳ thi năng lực tiếng Anh như IELTS, TOEIC SPEAKING, và TOEFL.`,
  ],
  summary_graph: [
    `수업 시간 동안 사용한 단어의 종류, 분당 말하기 속도를 확인할 수 있습니다. 튜터와 비교하여, 원어민 대비 나의 실력이 어느 정도인지 체크해보세요.`,
    `You can view types of vocabularies used in your lessons and your speech pace. Compare these information with native speakers' and find out how you compare.`,
    's_review.summary_graph',
    `您可以对课程中使用的单词种类, 每分钟口语速度等进行确认。同时与教师进行比较, 了解自身英语水平和母语使用者间的差距。`,
    `您可以確認課程中使用的單字種類、每分鐘的口說速度。請與任課教師相比，確認您的英語實力與母語人士的程度差別。`,
    `レッスン中に使用した単語の種類、1分当たりの話すスピードを確認することができます。チューターと比較して、ネイティブに比べて自分の実力がどの程度なのかチェックしてみましょう。`,
    `Bạn có thể xem các kiểu từ vựng bạn dùng trong buổi học và tốc độ nói. So sánh những thông tin này với người bản xứ để hiểu khả năng của bạn.`,
  ],
  script_you_can_setting_record: [
    <>
      해당 수업은 [수업 녹음] 미설정으로 녹음 파일과 스크립트가 제공되지 않습니다.
      <br />
      <br />
      수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터 수업 녹음파일과 스크립트를 확인할 수
      있습니다.
    </>,
    <>
      You did not record this lesson. Audio recording and transcripts are only available for lessons that allowed audio
      recording. You can change this setting under Lesson Style for each lesson.
    </>,
    's_review.script_you_can_setting_record',
    <>
      该课程未设置[课程录音], 因此无法提供录音文件及课程脚本。
      <br />
      <br />
      课程开始前, 请于[课程模式设定]菜单中设定[课程录音] 您可从下次课程开始对课程录音及脚本内容进行确认。
    </>,
    <>
      由於該課程未設定[課程錄音]，故不提供錄音檔及腳本。
      <br />
      <br />
      若於課程開始前，於[上課方式設定]選單設定[課程錄音]， 您就可從下一次上課起確認錄音檔及腳本。
    </>,
    <>
      このレッスンは [レッスン録音] 未設定のため、録音ファイルとスクリプトは提供され ません。
      <br />
      <br />
      レッスン前に [レッスン方式設定] メニューで [レッスン録音]を設定すると、次のレッスンから
      レッスンの録音ファイルとスクリプトを確認することができます。
    </>,
    <>
      Bạn đã không thu âm buổi học. Ghi âm và bản ghi buổi học chỉ có khi bạn đồng ý cho thu âm. Bạn có thể chỉnh lại
      cài đặt ở phần Hình thức học của mỗi buổi học.
    </>,
  ],
  noshow: [
    `노쇼 수업으로 수업 분석이 제공되지 않습니다.`,
    `No-show lessons do not have audio recording and scripts.`,
    's_review.noshow',
    `旷课的情况, 将无法提供课程分析。`,
    `由於課程未出現，故不提供課程分析。`,
    `無断欠席したレッスンのため、レッスン分析は提供されません。`,
    `Các buổi học vắng mặt không có ghi âm và bản ghi.`,
  ],
  analyzing_patterns: [
    `스피킹 패턴(구문/표현) 분석 중`,
    `Analyzing speaking patterns`,
    's_review.analyzing_patterns',
    `口语模式(语法/表达)正在分析中`,
    `正在分析口說模式（句型／用法）`,
    `スピーキングパターン(構文/表現) 分析中`,
    `Phân tích các đặc điểm nói`,
  ],
  wpm: [
    `1분당 말하는 단어수를 의미하며 학습자의 말하기 속도를 측정합니다.`,
    `Speech pace is estimated using average words uttered per minute.`,
    's_review.wpm',
    `是指每分钟话语内所包含的单词数量, 从而推测学员说话的速度。`,
    `意指每分鐘使用的單字數，測量學生的口說速度。`,
    `1分間あたりの発話単語数を指し、受講生の話すスピードを測定します。`,
    `Tốc độ nói được ước tính bằng trung bình từ sử dụng trong một phút.`,
  ],
  vocabulary_range: [
    `수업 중 학습자가 사용한 단어를 기반으로 현재 학습자가 구사할 수 있는 영어 구사력을 측정합니다.`,
    `Measures fluency based on words spoken during lesson.`,
    's_review.vocabulary_range',
    `是指以课程中学员使用的单词为基础, 从而推测学员能够掌握的英语应用能力。`,
    `以上課時學生使用的單字為基礎，測量目前學生的英語口說能力。`,
    `レッスン中に使った単語をもとに、現時点での受講生の英語運用能力を測定します。`,
    `Đo lường mức độ trôi chảy dựa trên số từ bạn nói trong buổi học.`,
  ],
  script_being_prepared: [
    <>
      수업 녹음파일과 스크립트를 생성중입니다.
      <br />
      수업 종료 후, 최대 24시간 정도 소요될 수 있습니다. 조금만 기다려 주세요!
    </>,
    <>
      Generating lesson audio recordings and transcripts.
      <br />
      This process may take up to 24 hours after lesson. Thank you for your patience!
    </>,
    's_review.script_being_prepared',
    <>
      课程录音文件及脚本正在生成中。
      <br />
      课程结束后, 最多可需要24小时, 请您耐心等待!
    </>,
    <>
      正在生成課程錄音檔與腳本。
      <br />
      課程結束後，最長需要24小時，請稍候！
    </>,
    <>
      レッスンの録音ファイルとスクリプトを作成中です。
      <br />
      レッスン終了後、最長24時間ほどかかることがあります。もうしばらくお待ちください！
    </>,
    <>
      Đang tạo ghi âm và bản ghi buổi học.
      <br />
      Quy trình này có thể mất tới 24 giờ sau buổi học. Cảm ơn sự kiên nhẫn của bạn!
    </>,
  ],
  script_being_delayed: [
    <>
      수업 녹음파일과 스크립트 생성이 지연되고 있습니다. 아래 문의 버튼을 통해 링글팀에 연락 주시면, 빠르게 확인 후
      조치하겠습니다.
      <br />
      복습 자료 제공이 늦어진 점에 사과드립니다.
    </>,
    <>
      We're experiencing a delay in generating your lesson audio recording and transcripts.
      <br />
      We apologize for the inconvenience.
    </>,
    's_review.script_being_delayed',
    <>
      课程录音文件及脚本生成出现延迟。点击咨询按钮联系Ringle团队 我们将尽快为您解决。
      <br />
      我们对复习材料的延迟提供深表歉意。
    </>,
    <>
      課程錄音檔與腳本生成延誤中，如點擊以下諮詢按鈕 聯絡Ringle團隊，將盡快確認後進行處理。
      <br />
      我們為延誤提供複習資料向您致歉。
    </>,
    <>
      レッスンの録音ファイルとスクリプトの作成に時間がかかっています。下のお問い合わせボタンから
      Ringleチームにご連絡いただくと、速やかに確認して処理いたします。
      <br />
      復習資料のご提供が遅くなり申し訳ございません。
    </>,
    <>
      Chúng tôi gặp phải sự chậm trễ trong việc xuất ghi âm và bản ghi.
      <br />
      Chúng tôi xin lỗi vì sự bất tiện này.
    </>,
  ],
  inquiry: [
    `링글팀에게 알리기`,
    `Report to Ringle team`,
    's_review.inquiry',
    `通知Ringle团队`,
    `告知Ringle團隊`,
    `Ringleチームに知らせる`,
    `Báo lỗi cho đội ngũ Ringle`,
  ],
  successfully_submitted: [
    `링글팀에 복습 자료를 아직 제공받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.successfully_submitted',
    `已通知Ringle团队您尚未收到复习材料, 我们将尽快确认后给予您回复。`,
    `您已告知Ringle團隊尚未收到複習資料，我們將盡快確認後連絡您。`,
    `Ringleチームに復習資料がまだ提供されていないことを知らせました。速やかに確認後、ご連絡いたします。`,
    `Báo lỗi đã được gửi đến Ringle. Chúng tôi sẽ đánh giá và phản hồi lại cho bạn.`,
  ],
  only_available_allowed_recording: [
    `수업 녹음파일 미설정으로 CAF 진단 기능은 제공되지 않습니다.`,
    `The CAF analysis service is not available due to "Do not record" state.`,
    's_review.only_available_allowed_recording',
    `未设置课程录音, 因此无法提供AI分析功能。`,
    `由於未設定課程錄音檔，故不提供AI分析功能。`,
    `レッスンの録音ファイル未設定のため、AI Analysis機能は提供されません。`,
    `Dịch vụ phân tích bằng AI hiện thời không khả dụng do trạng thái "Không thu âm".`,
  ],
  analyzing: [
    `말하기 속도 & 사용 단어 수 분석 중`,
    `Analyzing Speech Pace & Vocabulary Range`,
    's_review.analyzing',
    `口语速度&使用单词量正在分析中`,
    `正在分析口說速度 & 使用的單字數`,
    `話すスピード & 使用単語数分析中`,
    `Phân tích tốc độ nói & Vốn từ vựng`,
  ],
  feedback_waiver: [
    <>
      해당 수업의 피드백은 아래의 사유 중 하나로 제공되지 않습니다.
      <br />
      문의사항이 있으시면 홈페이지 내 [문의] 채널로 알려주세요!
      <br />
      <br />
      - 수강생의 요청
      <br />
      - 작문 위주로 진행된 수업
      <br />
      - 인터뷰 형식으로 진행된 수업
      <br />
    </>,
    <>
      Feedback from this class is not available due to one of the following reasons:
      <br />
      If you have any questions, please contact us via chat below
      <br />
      <br />
      - Student confirmation - no feedback required
      <br />
      - Writing-oriented class
      <br />
      - Interview-oriented class
      <br />
    </>,
    's_review.feedback_waiver',
    <>
      该课程的反馈, 因以下原因无法提供。
      <br />
      如有疑问, 可通过主页的在线[咨询]联系我们!
      <br />
      <br />
      - 学员请求
      <br />
      - 以写作为主的课程
      <br />
      - 以面试形式进行的课程
      <br />
    </>,
    <>
      該課程的意見回饋由於以下原因之一未提供。
      <br />
      如有疑問，請透過首頁的[諮詢]聯絡我們！
      <br />
      <br />
      - 學生的要求
      <br />
      - 以寫作為主進行的課程
      <br />
      - 以面試為主進行的課程
      <br />
    </>,
    <>
      このレッスンのフィードバックは、以下のいずれかの理由により提供されません。
      <br />
      お問い合わせはホームページ内の [お問い合わせ] チャンネルからご連絡ください。
      <br />
      <br />
      - 受講生のリクエスト
      <br />
      - 作文中心に行われたレッスン
      <br />
      - 面接形式で行われたレッスン
      <br />
    </>,
    <>
      Đánh giá của lớp học hiện thời không có do những lí do sau:
      <br />
      Nếu bạn có bất kỳ câu hỏi nào, vui lòng chat với chúng tôi
      <br />
      <br />
      - Học viên xác nhận - không yêu cầu đánh giá
      <br />
      - Lớp học tập trung viết
      <br />
      - Lớp học tập trung phần phỏng vấn
      <br />
    </>,
  ],
  contact: [
    `링글팀에게 알리기`,
    `Report to Ringle team`,
    's_review.contact',
    `通知Ringle小组`,
    `告知Ringle團隊`,
    `Ringleチームに知らせる`,
    `Báo lỗi cho đội ngũ Ringle`,
  ],
  alertMessage: [
    `링글팀에 튜터 피드백 리포트를 아직 받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.alertMessage',
    `已通知Ringle团队您尚未收到教师的Feedback Report, 我们将尽快确认后给予您回复。`,
    `您已告知Ringle團隊尚未收到任課教師的意見回饋報告書，我們將盡快確認後連絡您。`,
    `RingleチームにチューターからのFeedback Reportをまだ受け取っていないことを知らせました。速やかに確認後、ご連絡いたします。`,
    `Báo lỗi đã được gửi đến Ringle. Chúng tôi sẽ xem lại và phản hồi lại cho bạn.`,
  ],
  worst: [`최악이었어요!`, `Worst`, 's_review.worst', `真是糟糕透了!`, `超級糟糕！`, `最悪でした！`, `Rất tệ`],
  bad: [`그냥 별로였어요`, `Bad`, 's_review.bad', `不怎么样`, `普通`, `イマイチでした。`, `Tệ`],
  dissatisfied: [
    `전반적으로 아쉬웠어요`,
    `Dissatisfied`,
    's_review.dissatisfied',
    `整体而言不是很满意`,
    `整體上很可惜`,
    `全般的に残念でした。`,
    `Chưa hài lòng`,
  ],
  fine: [
    `좋았는데, 조금 아쉬웠어요`,
    `Fine`,
    's_review.fine',
    `还不错, 但有点小问题`,
    `還不錯，但有點可惜`,
    `よかったけど、少し残念でした。`,
    `Tốt`,
  ],
  great: [`좋았어요!`, `Great`, 's_review.great', `很不错!`, `很不錯！`, `よかったです！`, `Tuyệt vời`],
  what_did_you_have_problem: [
    `어떤 점이 불만이셨나요?`,
    `What did you have problems with?`,
    's_review.what_did_you_have_problem',
    `哪方面让您不满意?`,
    `什麼部分讓您不滿意呢？`,
    `どの点が不満でしたか？`,
    `Bạn đã gặp vấn đề gì?`,
  ],
  what_small_improvements: [
    `어떤 점만 개선하면 좋을까요?`,
    `What improvements would you suggest?`,
    's_review.what_small_improvements',
    `哪方面需要进行改善?`,
    `您希望改善哪一部分呢？`,
    `どの点を改善すればいいですか？`,
    `Điều gì bạn cần chúng tôi cải thiện?`,
  ],
  what_did_you_like_the_most: [
    `특히 어떤 점이 좋으셨나요?`,
    `What did you like the most?`,
    's_review.what_did_you_like_the_most',
    `哪方面特别满意?`,
    `您特別喜歡哪一部分呢？`,
    `特にどの点がよかったですか？`,
    `Điều gì bạn thích nhất?`,
  ],
  placeholder_1: [
    `수업 이용에 불편을 드려 죄송합니다. 튜터와 진행한 수업에서 겪으셨던 불편 사항을 남겨주세요.`,
    `We are sorry to hear that. Please note what you found to be unsatisfactory.`,
    's_review.placeholder_1',
    `很抱歉课程给您造成的不便, 请阐述和教师的课程中您所遇到的不便之处。`,
    `抱歉造成您在進行課程上的不便，請留下與任課教師進行課程時遭遇的不便事項。`,
    `レッスンのご利用にご不便をおかけして申し訳ございません。チューターと行ったレッスンで感じた不満点を入力してください。`,
    `Chúng tôi rất tiếc. Xin ghi rõ điều bạn chưa hài lòng.`,
  ],
  placeholder_2: [
    `기대하셨던 것과는 달리 진행된 수업에서 튜터의 부족했던 부분을 남겨주세요.`,
    `Has your tutor missed your expectations at all? How so?`,
    's_review.placeholder_2',
    `未能达到您期待的课程, 请阐述教师的不足之处。`,
    `請留下低於您所期待的課程中任課教師的不足之處。`,
    `期待とは異なったレッスンでのチューターの足りない部分を入力してください。`,
    `Có phải gia sư của bạn không đáp ứng được những kỳ vọng?`,
  ],
  placeholder_3: [
    `만족도 높은 수업을 제공하지 못해 아쉽습니다. 튜터가 개선할 수 있는 부분을 남겨주세요.`,
    `We are sorry to hear that. Please note where the tutor could improve`,
    's_review.placeholder_3',
    `未能提供给您满意的课程, 我们深表遗憾。请阐述希望教师进行改善的部分。`,
    `抱歉未能提供滿意度高的課程，請留下任課教師可以改善的部分。`,
    `満足度の高いレッスンを提供することができず、申し訳ございません。チューターが改善できる部分を入力してください。`,
    `Chúng tôi rất tiếc. Mong bạn nêu rõ những điểm gia sư có thể cải thiện`,
  ],
  placeholder_4: [
    `튜터에 대한 자세한 수업 평가는 튜터의 역량 개선 및 수업 퀄리티 증진에 큰 도움이 됩니다.
(네트워크/교재 평가는 아래 선택사항에 남겨주세요. 수업과 무관한 내용, 욕설 등이 포함된 악의적인 평가는 사전 안내 없이 삭제될 수 있습니다.)`,
    `Your detailed feedback on tutor helps improving our tutor’s ability and class quality.
(Leave feedback on network or lesson materials through options below. Ill-intended feedback irrelevant to lessons or containing swearwords may be deleted without prior notice.)`,
    's_review.placeholder_4',
    `有关教师的详细课程评价, 有助于改善教师执教能力提升课程质量。
(但网络, 教材等与教师无关的情况请通过以下选择事项进行留言。)`,
    `有關任課教師的詳細課程評論有助於任課教師改善能力與增進課程品質。
(網路、教材等與任課教師無關的項目請透過以下選填事項留言。)`,
    `チューターへの詳しいレッスン評価は、チューターの能力改善とレッスンクオリティの向上に大いに役立ちます。
(ネットワーク／教材評価は下記の選択事項に残してください。レッスンに関係なく、暴言を含む悪質的な評価は事前案内なしに削除されます。)`,
    `Đánh giá chi tiết của bạn dành cho gia sư sẽ giúp chúng tôi cải thiện khả năng của gia sư và chất lượng lớp học.
(Viết nhận xét về đường truyền hoặc tài liệu học ở phía dưới)`,
  ],
  submit: [`제출하기`, `Submit`, 's_review.submit', `提交`, `提交`, `送信する`, `Gửi`],
  shareRingleTeam: [
    `링글팀과 튜터에게만 공유하기`,
    `Visible only to Ringle Team and Tutor.`,
    's_review.shareRingleTeam',
    `仅限共享给Ringle团队及教师`,
    `僅分享給Ringle團隊與任課教師`,
    `Ringleチームとチューターにのみ共有する`,
    `Chỉ hiển thị cho đội ngũ Ringle và Gia sư.`,
  ],
  ringleTeamComment: [
    `링글팀에게 남길 의견이 있으신가요?`,
    `Do you have any feedback for the Ringle Team?`,
    's_review.ringleTeamComment',
    `您有建议向Ringle团队提出吗?`,
    `您有意見要和Ringle團隊分享嗎？`,
    `Ringleチームへのご意見はありますか？`,
    `Bạn có đánh giá nào dành cho đội ngũ Ringle?`,
  ],
  submit_late: [`나중에 하기`, `Skip for now`, 's_review.submit_late', `下次再说`, `下次再說`, `今はしない`, `Bỏ qua`],
  required_field: [
    `필수로 입력해야하는 항목을 입력해주세요`,
    `Please enter the required fields`,
    's_review.required_field',
    `请输入必需项目。`,
    `請輸入必填項目`,
    `必須項目を入力してください。`,
    `Vui lòng điền vào các ô bắt buộc`,
  ],
  preparation: [
    `수업 준비도`,
    `Preparation`,
    's_review.preparation',
    `课程准备度`,
    `課程準備度`,
    `レッスン準備度`,
    `Sự chuẩn bị`,
  ],
  tech_environment: [
    `수업 환경`,
    `Teaching Environment`,
    's_review.tech_environment',
    `课程环境`,
    `上課環境`,
    `レッスン環境`,
    `Môi trường giảng dạy`,
  ],
  tech_quality: [
    `비디오/마이크 상태`,
    `Audio/Video Quality`,
    's_review.tech_quality',
    `视频/麦克风状态`,
    `影像／麥克風狀態`,
    `ビデオ/マイクの状態`,
    `Chất lượng âm thanh/video`,
  ],
  punctuality: [
    `지각/조기종료`,
    `Punctuality`,
    's_review.punctuality',
    `迟到/提前结束`,
    `遲到／提早結束`,
    `遅刻/早期終了`,
    `Đúng giờ`,
  ],
  correction_quality: [
    `교정 능력`,
    `Correction Quality`,
    's_review.correction_quality',
    `纠正能力`,
    `糾正能力`,
    `校正能力`,
    `Chất lượng phần sửa lỗi`,
  ],
  tailoring: [
    `수업 설정 미반영`,
    `Tailoring to Lesson Requests`,
    's_review.tailoring',
    `未响应课程设置`,
    `未反映課程設定`,
    `レッスン設定未反映`,
    `Xây dựng buổi học theo yêu cầu`,
  ],
  lesson_direction: [
    `수업 진행 능력`,
    `Lesson Direction`,
    's_review.lesson_direction',
    `课程进行能力`,
    `課程進行能力`,
    `レッスン進行能力`,
    `Định hướng của buổi học`,
  ],
  attitude: [
    `수업 태도`,
    `Attitude and Tone`,
    's_review.attitude',
    `课程进行态度`,
    `授課態度`,
    `レッスン態度`,
    `Thái độ và tông giọng`,
  ],
  punctualityRight: [
    `수업시간 엄수`,
    `Punctuality`,
    's_review.punctualityRight',
    `严守课程时间`,
    `嚴守課程時間`,
    `レッスン時間厳守`,
    `Đúng giờ`,
  ],
  tailoringRight: [
    `수업 설정 숙지`,
    `Tailoring to Lesson Requests`,
    's_review.tailoringRight',
    `熟知课程设置`,
    `清楚課程設定`,
    `レッスン設定熟知`,
    `Xây dựng buổi học theo yêu cầu`,
  ],
  writeThisPlace: [
    `소중한 의견을 작성해주세요. 더 나은 서비스를 위해 노력하겠습니다.`,
    `Please leave a comment.`,
    's_review.writeThisPlace',
    `请留下您的宝贵意见。我们将努力为您提供更优质的服务。`,
    `請撰寫寶貴的意見，我們將努力提供更好的服務。`,
    `大切なご意見をお願いいたします。より良いサービスのために努力いたします。`,
    `Vui lòng để lại bình luận.`,
  ],
  material: [`교재`, `Materials`, 's_review.material', `教材`, `教材`, `教材`, `Tài liệu`],
  network: [`네트워크`, `Network`, 's_review.network', `网络`, `網路`, `ネットワーク`, `Đường truyền`],
  overall: [`총평`, `Overall`, 's_review.overall', `总评价`, `總評`, `総評`, `Nhận xét chung`],
  doNotWantToMeetAgain: [
    `이 튜터와 다시 만나지 않기`,
    `I do not want to meet this tutor again`,
    's_review.doNotWantToMeetAgain',
    `不再与此教师学习`,
    `不再和此教師上課`,
    `このチューターと二度と会わない`,
    `Tôi không muốn gặp lại gia sư này`,
  ],
  howWasYourLessonWith: (tutor) => [
    <>{tutor} 튜터와의 수업은 어떠셨나요?</>,
    <>How was your lesson with {tutor}?</>,
    's_review.howWasYourLessonWith',
    <>和{tutor}教师的课程如何?</>,
    <>您與{tutor}任課教師的課程進行得如何呢？</>,
    <>{tutor} チューターとのレッスンはいかがでしたか？</>,
    <>Buổi học của bạn cùng với {tutor} như thế nào?</>,
  ],
  leaveTutorComment: [
    `튜터에게 수업에 대한 평가를 남겨주세요.`,
    `Please leave a comment about this tutor.`,
    's_review.leaveTutorComment',
    `请告知教师您对课程的评价。`,
    `請對任課教師留下課程評論。`,
    `チューターへのレッスンの評価を作成してください。`,
    `Vui lòng để lại nhận xét về gia sư này.`,
  ],
  lessonOverall: [
    `전반적인 수업 만족도는 어떠셨나요?`,
    `What did you think about the lesson overall?`,
    's_review.lessonOverall',
    `课程整体的满意程度如何?`,
    `整體的課程滿意度如何呢？`,
    `全般的なレッスンの満足度はいかがでしたか？`,
    `Bạn nghĩ sao về buổi học?`,
  ],
  optional: [`선택사항`, `Optional`, 's_review.optional', `选择事项`, `選填事項`, `選択事項`, `Không bắt buộc`],
  placeholder_5: [
    <>수업과 관련 없거나 부적절한 표현이 포함된 평가는 숨김 처리 될 수 있습니다.</>,
    <>Feedbacks containing irrelevant facts or offensive language may be hidden.</>,
    's_review.placeholder_5',
    <>如评价中出现和课程无关或不恰当的内容时, 相关评价可能会被隐藏。</>,
    <>評論中包含與課程無關或不當的言論時，將可能被隱藏。</>,
    <>レッスンと関連がない、または不適切な表現が含まれた評価は、非表示になることがあります。</>,
    <>Đánh giá bao gồm những sự thật không liên quan hoặc ngôn ngữ xúc phạm sẽ bị ẩn.</>,
  ],
  entire: [`전체 수업`, `All`, 's_review.entire', `全部`, `全部`, `すべて`, `Tất cả`],
  no_show_lesson: [
    `노쇼한 수업`,
    `No-show`,
    's_review.no_show_lesson',
    `未出席的课程`,
    `未出現的課程`,
    `無断欠席したレッスン`,
    `Vắng mặt`,
  ],
  all_viewed: [
    `모두 조회했습니다.`,
    `Review complete`,
    's_review.all_viewed',
    `全部查询完毕。`,
    `已全部查詢。`,
    `すべて照会しました。`,
    `Hoàn tất xem lại`,
  ],
  all: [`전체`, `All`, 's_review.all', `全部`, `全部`, `すべて`, `Tất cả`],
  no_reviewed: [
    `복습을 완료한 수업이 없습니다.`,
    `You haven't reviewed any lessons.`,
    's_review.no_reviewed',
    `没有完成复习的课程。`,
    `無完成複習的課程。`,
    `復習を完了したレッスンはありません。`,
    `Bạn chưa xem lại buổi học.`,
  ],
  lesson_eval_submit: [
    `수업 평가가 제출되었습니다.`,
    `Submitted.`,
    's_review.lesson_eval_submit',
    `已经提交了课程评价。`,
    `已提交課程評論。`,
    `レッスン評価が送信されました。`,
    `Đã gửi.`,
  ],
  placeholder_refund: [
    `만족스러운 수업을 제공해 드리지 못해 죄송합니다. 튜터가 개선할 수 있는 부분을 구체적으로 남겨주시면, 담당팀에서 확인 후 수업권 복구에 대해 안내해 드리겠습니다.`,
    `We are sorry to hear that you were unsatisfied with the lesson. If you tell us what areas the tutor can work on in detail, the Ringle team will review your comments and contact you about reissuing the credit.`,
    's_review.placeholder_refund',
    `很抱歉未能给您提供满意的课程。如果您留下任课教师的具体改善建议，负责团队确认后，将为您介绍关于课程券恢复使用的相关事宜。`,
    `很抱歉未能給您提供滿意的課程。如果您留下任課教師的具體改善建議，負責團隊確認后，將為您介紹關於課程券恢復使用的相關事宜 。`,
    `ご満足のいくレッスンを提供することができず申し訳ございませんでした。チューターが改善できる部分を具体的にご記入いただけますと、担当チームが確認後レッスンチケットの返還についてご案内いたします。`,
    `Chúng tôi xin lỗi vì không thể cung cấp cho bạn một buổi học thỏa đáng. Nếu bạn viết chi tiết những gì gia sư có thể cải thiện, nhóm phụ trách sẽ kiểm tra lại và hướng dẫn bạn khôi phục lại phiếu buổi học.`,
  ],
  current_month: [
    `이번 달로 가기`,
    `Show Current Month`,
    's_review.current_month',
    `返回本月`,
    `返回本月`,
    `今月へ`,
    `Về tháng này`,
  ],
  full_date: (year, month, date) => [
    <>
      {year}.{month}.{date}
    </>,
    <>
      {month}.{date}.{year}
    </>,
    's_review.full_date',
    <>
      {year}.{month}.{date}
    </>,
    <>
      {year}.{month}.{date}
    </>,
    <>
      {year}.{month}.{date}
    </>,
    <>
      {date}.{month}.{year}
    </>,
  ],
  no_list_desc: [
    `이 달의 수업기록이 없습니다.`,
    `You did not take any lessons during this period.`,
    's_review.no_list_desc',
    `查询月份没有学习记录`,
    `查詢月份沒有學習記錄`,
    `ここ一ヶ月の学習記録がありません`,
    `Bạn không học trong khoảng thời gian này`,
  ],
  view_recent_lessons: [
    `최근 수업 기록 보기`,
    `View Recent Lessons`,
    's_review.view_recent_lessons',
    `查看最近课程记录`,
    `查看最近課程記錄`,
    `最近のレッスン`,
    `Lịch sử học gần đây`,
  ],
  full_month: (month) => [
    `${month}월`,
    `${getMonthName(month - 1)[1]}`,
    's_review.full_month',
    `${month}月`,
    `${month}月`,
    `${month}月`,
    `${getMonthName(month - 1)[1]}`,
  ],
  keyword_title_1: [
    `수업 진행능력`,
    `Teaching Ability`,
    's_review.keyword_title_1',
    `课程进行能力`,
    `課程進行能力`,
    `レッスン進行能力`,
    `Khả năng giảng dạy`,
  ],
  keyword_title_2: [
    `수업 참여도/준비도`,
    `Engagement/Preperation`,
    's_review.keyword_title_2',
    `课程参与度/准备度`,
    `課程參與度/準備度`,
    `レッスン参加度・準備度`,
    `Tham gia/ chuẩn bị buổi học`,
  ],
  keyword_title_3: [
    `수업 태도`,
    `Attitude`,
    's_review.keyword_title_3',
    `上课态度`,
    `上課態度`,
    `レッスン態度`,
    `Thái độ trong buổi học`,
  ],
  keyword_title_4: [
    `수업 진행능력`,
    `Teaching Ability`,
    's_review.keyword_title_4',
    `课程进行能力`,
    `課程進行能力`,
    `レッスン進行能力`,
    `Khả năng giảng dạy`,
  ],
  keyword_title_5: [
    `튜터의 태도/환경`,
    `Attitude/Environment`,
    's_review.keyword_title_5',
    `导师的态度/环境`,
    `導師的態度/環境`,
    `チューターの態度／環境`,
    `Thái độ của gia sư/ Môi trường`,
  ],
  keyword_1_1: [
    `교정의 양이 많아요`,
    `Provides sufficient paraphrasing`,
    's_review.keyword_1_1',
    `给予很多纠正`,
    `給予很多糾正`,
    `教材の量が多いです`,
    `Hiệu chỉnh nhiều`,
  ],
  keyword_1_2: [
    `새로운 문장 형식을 알려줘요`,
    `Teaches sentence structures`,
    's_review.keyword_1_2',
    `教授新的句型`,
    `教授新的句型`,
    `新しい形式を教えてくれます`,
    `Dạy cấu trúc câu mới`,
  ],
  keyword_1_3: [
    `새로운 단어를 많이 알려줘요`,
    `Teaches many new expressions`,
    's_review.keyword_1_3',
    `教授很多新的单词`,
    `教授很多新的單詞`,
    `新しい単語を沢山教えてくれます`,
    `Dạy nhiều từ vựng mới`,
  ],
  keyword_1_4: [
    `추가 질문을 많이 해줬어요`,
    `Asks many questions`,
    's_review.keyword_1_4',
    `提出了很多相关的问题`,
    `提出了很多相關的問題`,
    `追加で質問を多くしてくれました`,
    `Đặt thêm nhiều câu hỏi`,
  ],
  keyword_1_5: [
    `대화를 많이 할 수 있어요`,
    `Actively engages conversation`,
    's_review.keyword_1_5',
    `可以进行更多交流`,
    `可以進行更多交流`,
    `会話を沢山できました`,
    `Có thể đối thoại nhiều`,
  ],
  keyword_1_6: [
    `교정에 대한 이유를 잘 설명해요`,
    `Provides good explanations`,
    's_review.keyword_1_6',
    `对纠正的部分进行详细的说明`,
    `對糾正的部分進行詳細的說明`,
    `教材に対する理由をよく説明します`,
    `Giải thích kỹ lý do hiệu chỉnh`,
  ],
  keyword_1_7: [
    `반복되는 실수를 잘 짚어줘요`,
    `Corrects repetitive mistakes`,
    's_review.keyword_1_7',
    `指出常犯的错误`,
    `指出常犯的錯誤`,
    `繰り返されるミスをよく指摘してくれます`,
    `Sửa lỗi lặp đi lặp lại`,
  ],
  keyword_2_1: [
    `수업을 잘 리드해요`,
    `Leads lessons well`,
    's_review.keyword_2_1',
    `很好地引导课程`,
    `很好地引導課程`,
    `レッスンをよくリードしてくれます`,
    `Dẫn dắt buổi học tốt`,
  ],
  keyword_2_2: [
    `학생의 요청사항을 잘 들어줘요`,
    `Responds willingly to requests`,
    's_review.keyword_2_2',
    `认真聆听学生的要求`,
    `認真聆聽學生的要求`,
    `学生の要請事項をよく聞いてくれます`,
    `Sẵn sàng trả lời các yêu cầu của học viên`,
  ],
  keyword_2_3: [
    `수업 시간을 충분히 활용해요`,
    `Makes full use of time`,
    's_review.keyword_2_3',
    `充分利用课上时间`,
    `充分利用課上時間`,
    `レッスン時間を十分に活用します`,
    `Tận dụng triệt để thời gian buổi học`,
  ],
  keyword_2_4: [
    `수업 준비를 잘 해줘요`,
    `Comes to lessons prepared`,
    's_review.keyword_2_4',
    `准备得很充分`,
    `準備得很充分`,
    `レッスンの準備がよくできています`,
    `Chuẩn bị buổi học tốt`,
  ],
  keyword_2_5: [
    `수업 노트를 잘 정리해요`,
    `Provides good written feedback`,
    's_review.keyword_2_5',
    `课程笔记整理的很好`,
    `課程筆記整理的很好`,
    `レッスンのーとをよく整理しています`,
    `Cung cấp phản hồi bằng văn bản tốt`,
  ],
  keyword_2_6: [
    `교재를 정확히 이해하고 있어요`,
    `Understood material well`,
    's_review.keyword_2_6',
    `对教材的理解度很高`,
    `對教材的理解度很高`,
    `教材を正確に理解しています`,
    `Hiểu chính xác tài liệu`,
  ],
  keyword_3_1: [
    `수업 분위기가 활기차요`,
    `Energetic and lively`,
    's_review.keyword_3_1',
    `课堂氛围活跃`,
    `課堂氛圍活躍`,
    `レッスンの雰囲気が活気的です`,
    `Không khí buổi học sôi nổi`,
  ],
  keyword_3_2: [
    `차분하게 수업을 진행해요`,
    `Calm and composed`,
    's_review.keyword_3_2',
    `授课风格冷静`,
    `授課風格冷靜`,
    `落ち着いてレッスンを進行します`,
    `Tiến hành buổi học điềm đạm`,
  ],
  keyword_3_3: [
    `답변에 대한 리액션이 좋아요`,
    `Good reactions`,
    's_review.keyword_3_3',
    `对回答的反应很好`,
    `對回答的反應很好`,
    `返事に対するリアクションがいいです`,
    `Phản ứng tốt đối với câu trả lời`,
  ],
  keyword_3_4: [
    `수준에 맞게 수업을 진행해줘요`,
    `Teaches at my level`,
    's_review.keyword_3_4',
    `因材施教`,
    `因材施教`,
    `レベルに合わせてレッスンを進めてくれます`,
    `Tiến hành buổi học đúng trình độ`,
  ],
  keyword_3_5: [
    `냉철한 피드백을 해줘요`,
    `Gives objective feedback`,
    's_review.keyword_3_5',
    `冷静的反馈`,
    `冷靜的回饋`,
    `冷徹なフィードバックをしてくれます`,
    `Đưa ra phản hồi khách quan`,
  ],
  keyword_4_1: [
    `교정이 부족했어요`,
    `Not enough paraphrasing`,
    's_review.keyword_4_1',
    `纠正不充分`,
    `糾正不充分`,
    `教材が不足しています`,
    `Hiệu chỉnh không nhiều`,
  ],
  keyword_4_2: [
    `단순 문법 교정만 진행됐어요`,
    `Only corrects simple grammar`,
    's_review.keyword_4_2',
    `只对语法进行纠正`,
    `只對語法進行糾正`,
    `単純な文法校正だけ行います`,
    `Chỉ hiệu chỉnh ngữ pháp đơn giản`,
  ],
  keyword_4_3: [
    `설명이 부족했어요`,
    `Not enough explanations`,
    's_review.keyword_4_3',
    `解释不充分`,
    `解釋不充分`,
    `説明が不足しています`,
    `Giải thích không đầy đủ`,
  ],
  keyword_4_4: [
    `교정 외에 대화가 부족했어요`,
    `Not enough conversation`,
    's_review.keyword_4_4',
    `除纠正外，交流不充分`,
    `除糾正外，交流不充分`,
    `教材以外に会話が不足しています`,
    `Không có nhiều đối thoại ngoài việc hiệu chỉnh`,
  ],
  keyword_4_5: [
    `학생과 소통이 부족했어요`,
    `Not communicative`,
    's_review.keyword_4_5',
    `与学生的沟通不充分`,
    `與學生的溝通不充分`,
    `学生とのコミュニケーションが不足していました`,
    `Thiếu giao tiếp với học viên`,
  ],
  keyword_4_6: [
    `수업을 리드하지 못했어요`,
    `Not good at leading lessons`,
    's_review.keyword_4_6',
    `引领课堂能力不足`,
    `引領課堂能力不足`,
    `レッスンをリードしてくれませんでした`,
    `Dẫn dắt buổi học chưa tốt`,
  ],
  keyword_4_7: [
    `수업 준비가 아쉬웠어요`,
    `Did not come prepared`,
    's_review.keyword_4_7',
    `课前准备不足`,
    `課前準備不足`,
    `レッスンの準備が残念でした`,
    `Chuẩn bị buổi học chưa tốt`,
  ],
  keyword_5_1: [
    `수업 시간을 잘 지키지 않았어요`,
    `Came late/ended early`,
    's_review.keyword_5_1',
    `不能遵守上课时间`,
    `不能遵守上課時間`,
    `レッスン時間をきちんと守りませんでした`,
    `Không giữ đúng thời gian buổi học`,
  ],
  keyword_5_2: [
    `수업에 집중하지 못했어요`,
    `Lacked focus`,
    's_review.keyword_5_2',
    `对上课不集中`,
    `對上課不集中`,
    `レッスンに集中できませんでした`,
    `Không thể tập trung vào buổi học`,
  ],
  keyword_5_3: [
    `수업 진행 환경이 어수선해요`,
    `Distracting environment`,
    's_review.keyword_5_3',
    `上课环境嘈杂`,
    `上課環境嘈雜`,
    `レッスン進行環境が散らかっています`,
    `Môi trường buổi học lộn xộn`,
  ],
  keyword_5_4: [
    `불친절해서 대화가 어려웠어요`,
    `Unfriendly and difficult to talk to`,
    's_review.keyword_5_4',
    `不亲切，难以进行交流`,
    `不親切，難以進行交流`,
    `不親切で会話が難しかったです`,
    `Thật khó để nói chuyện vì không thân thiện.`,
  ],
  title: [
    `수업 평가`,
    `Lesson Evaluation`,
    's_review.title',
    `课程评价`,
    `課程評價`,
    `レッスン評価`,
    `Đánh giá buổi học`,
  ],
  subtitle_1: [
    `이번 수업은 어떠셨나요?`,
    `How was the lesson?`,
    's_review.subtitle_1',
    `这节课感觉怎么样？`,
    `這節課感覺怎麼樣？`,
    `今回のレッスンはどうでしたか？`,
    `Buổi học này như thế nào?`,
  ],
  subtitle_2: (name) => [
    `이번 수업에서 ${name}의 아쉬웠던 점을 알려주세요. (1개 이상)`,
    `Please check every item that describes what you didn't like about ${name} or this lesson. (Please choose at least one item.)`,
    's_review.subtitle_2',
    `请告诉我们${name}在这节课上令您感到不太满意的表现。（一个以上）`,
    `請告訴我們${name}在這節課上令您感到不太滿意的表現。（一個以上）`,
    `今回のレッスンで${name}の残念だった点を教えてください。（１個以上）`,
    `Vui lòng cho biết điểm đáng tiếc của ${name} trong buổi học này. (nhiều hơn 1 điểm)`,
  ],
  subtitle_3: (name) => [
    `${name}튜터와 다음에도 수업을 하고 싶으신가요?`,
    `Do you wish to learn from ${name} again?`,
    's_review.subtitle_3',
    `下次还想和${name}导师一起上课吗？`,
    `下次還想和${name}導師一起上課嗎？`,
    `${name}チューターと次もレッスンをしたいですか？`,
    `Bạn có muốn tham gia buổi học sau với gia sư ${name} không?`,
  ],
  subtitle_4: [
    `자세한 수업 후기를 알려주세요. (선택)`,
    `Please give a detailed review about the lesson. (Optional)`,
    's_review.subtitle_4',
    `请对课程进行详细的课后评价（可选）`,
    `請對課程進行詳細的課後評價（可選）`,
    `詳しいレッスンレビューを教えてください（選択）`,
    `Vui lòng cho biết đánh giá chi tiết buổi học. (lựa chọn)`,
  ],
  subtitle_5: [
    `교재가 수업에 도움이 되었나요? (선택)`,
    `Was this material useful? (Optional)`,
    's_review.subtitle_5',
    `教材对课程有帮助吗？（可选）`,
    `教材對課程有幫助嗎？（可選）`,
    `教材がレッスンに役立ちましたか？（選択）`,
    `Tài liệu có hữu ích cho buổi học không? (lựa chọn)`,
  ],
  subtitle_6: [
    `수업 중 네트워크 환경은 어땠나요? (선택)`,
    `How was your internet connection during the lesson? (Optional)`,
    's_review.subtitle_6',
    `上课期间的网络环境怎么样？（可选）`,
    `上課期間的網路環境怎麼樣？（可選）`,
    `レッスン中のネットワーク環境はどうでしたか？（選択）`,
    `Mạng máy tính trong buổi học như thế nào? (lựa chọn)`,
  ],
  rating_0: [
    `수업 만족도를 평가해주세요.`,
    `Please tell us how satisfied you were with the lesson.`,
    's_review.rating_0',
    `请对课程进行满意度评价。`,
    `請對課程進行滿意度評價。`,
    `レッスン満足度を評価してください。`,
    `Vui lòng đánh giá mức độ hài lòng đối với buổi học.`,
  ],
  rating_1: [`최악이었어요.`, `Very dissatisfied`, 's_review.rating_1', `非常差劲`, `非常差勁`, `最悪でした`, `Rất tệ`],
  rating_2: [
    `너무 별로였어요.`,
    `Dissatisfied`,
    's_review.rating_2',
    `不怎么样`,
    `不怎麼樣`,
    `微妙でした`,
    `Không hay lắm`,
  ],
  rating_3: [
    `아쉬웠어요.`,
    `Neither satisfied nor dissatisfied`,
    's_review.rating_3',
    `有些不足`,
    `有些不足`,
    `残念でした`,
    `Đáng tiếc`,
  ],
  rating_4: [`좋았어요.`, `Satisfied`, 's_review.rating_4', `很好。`, `很好。`, `良かったです`, `Tốt`],
  rating_5: [`최고에요!`, `Very satisfied`, 's_review.rating_5', `太棒了！`, `太棒了！`, `最高です！`, `Tuyệt vời`],
  report: [
    `수업이 진행되지 않았다면 링글팀에게 알려주세요.`,
    `Please let us know if the lesson wasn't held.`,
    's_review.report',
    `如果没有上课，请通知我们。`,
    `如果沒有上課，請通知我們。`,
    `レッスンが開催されない場合はRingleチームにご連絡ください。`,
    `Nếu buổi học chưa được tiến hành, vui lòng thông báo cho nhóm Ringle.`,
  ],
  btn_1: [`나중에 하기`, `Skip for now`, 's_review.btn_1', `下次再说`, `下次再說`, `今はしない`, `Bỏ qua`],
  btn_2: [`다음`, `Next`, 's_review.btn_2', `下一步`, `下一步`, `次へ`, `Tiếp theo`],
  btn_3: [`선택 완료`, `Selected`, 's_review.btn_3', `选择完成`, `選擇完畢`, `選択済`, `Đã chọn`],
  btn_4: [`평가 완료`, `Submit`, 's_review.btn_4', `完成评价`, `完成評價`, `評価完了`, `Kết thúc đánh giá`],
  btn_5: [`제출하기`, `Submit`, 's_review.btn_5', `提交`, `提交`, `提出する`, `Nộp`],
  btn_6: [`확인`, `Confirm`, 's_review.btn_6', `确认`, `確定`, `確認`, `Xác nhận`],
  btn_7: [`평가 완료하기`, `Submit`, 's_review.btn_7', ``, ``, ``, ``],
  btn_8: [`추가 작성하기`, `Edit review`, 's_review.btn_8', ``, ``, ``, ``],
  btn_9: [`확인`, `Confirm`, 's_review.btn_9', `确认`, `確定`, `確認`, `Xác nhận`],
  prefer_0: [
    `튜터 선호도는 링글팀에게만 공개됩니다.`,
    `This tutor evaluation is shown only to the Ringle Team.`,
    's_review.prefer_0',
    `教师评价仅对Ringle Team可见。`,
    `教師評價僅對Ringle Team可見。`,
    `チューター評価はRingleチームにのみ公開されます。`,
    `Mức độ yêu thích gia sư chỉ được tiết lộ cho nhóm Ringle`,
  ],
  prefer_1: [
    `아니요. 다시 만나고 싶지 않아요.`,
    `No. I do not wish to learn from this tutor again.`,
    's_review.prefer_1',
    `不，再也不想见到这位教师。`,
    `不，再也不想見到這位教師。`,
    `いいえ。もう会いたくありません。`,
    `Không. Tôi không muốn gặp lại.`,
  ],
  prefer_2: [
    `괜찮았어요. 기회가 되면 또 하고 싶어요.`,
    `The tutor was okay. I wouldn't mind learning from this tutor again if the chance arises.`,
    's_review.prefer_2',
    `还可以，有机会还想一起学习。`,
    `還可以，有機會還想一起學習。`,
    `良かったです。機会があればまたしたいです。`,
    `Cũng ổn. Tôi muốn gặp lại nếu có cơ hội`,
  ],
  prefer_3: [
    `최고의 튜터! 꼭 다시 만나고 싶어요.`,
    `The tutor was great! I want to continue learning from this tutor.`,
    's_review.prefer_3',
    `最棒的教师！还想和他一起学习。`,
    `最棒的教師！還想和他一起學習。`,
    `最高のチューター！是非また会いたいです。`,
    `Gia sư tốt nhất! Tôi thực sự muốn gặp lại`,
  ],
  placeholder_textreview: [
    `자세한 수업 후기는 튜터의 역량 개선 및 수업의 만족도 증진에 큰 도움이 됩니다. 
부적절한 단어/표현이 포함된 경우,  별도의 통지없이 삭제될 수 있습니다.`,
    `Your review will help us enhance our tutors' capacity and provide better lessons.
The review may be deleted without notice if it includes inappropriate language.`,
    's_review.placeholder_textreview',
    `"详细的课后评价记有助于提高教师能力和增加授课满意度。如含有不文明的词语/表述，将在无另行通知的情况下删除。`,
    `"詳細的課後評價記有助於提高教師能力和增加授課滿意度。如含有不文明的詞語/表述，將在無另行通知的情況下刪除。`,
    `詳しいレッスンレビューはチューターの力量改善及び満足度増進に大きく役立ちます。
不適切な単語/表現が含まれている場合、余儀なく削除されることがあります。`,
    `Đánh giá chi tiết buổi học giúp ích rất nhiều trong việc cải thiện khả năng của gia sư và nâng cao sự hài lòng của buổi học.
Nếu có từ ngữ/ cách diễn đạt không phù hợp, có thể bị xóa mà không cần báo trước`,
  ],
  word_count: [`/최소 20자`, `/At least 20 characters`, 's_review.word_count', ``, ``, ``, ``],
  option_share_1: [
    `전체공개`,
    `Share to public`,
    's_review.option_share_1',
    `全部公开`,
    `全部公開`,
    `全体公開`,
    `Công bố công khai`,
  ],
  option_share_2: [
    `링글팀에게만 공유하기`,
    `Share to Ringle Team only`,
    's_review.option_share_2',
    `仅分享给Ringle Team`,
    `僅分享給Ringle Team`,
    `Ringleチームにのみ共有する`,
    `Chỉ chia sẻ với nhóm Ringle`,
  ],
  option_share_3: [
    `링글팀과 튜터에게만 공유하기`,
    `Share to Ringle Team and Tutor only`,
    's_review.option_share_3',
    `仅分享给Ringle Team和教师`,
    `僅分享給Ringle Team和教師`,
    `Ringleチームとチューターにのみ共有する`,
    `Chỉ chia sẻ với nhóm Ringle và gia sư`,
  ],
  placeholder_optional: [
    `어떤점이 아쉬웠는지 의견을 보내주세요. (선택)`,
    `Are there any areas where we could improve more? (Optional)`,
    's_review.placeholder_optional',
    `告诉我们还有哪些值得改进的部分（可选）`,
    `告訴我們還有哪些值得改進的部分（可選）`,
    `どんな点が残念だったか意見を送ってください（選択）`,
    `Vui lòng cho biết điều gì còn đáng tiếc. (lựa chọn)`,
  ],
  desc_error: [
    `자세한 수업 후기는 20자 이상으로 입력해주세요.`,
    `The review must be at least 20 characters long.`,
    's_review.desc_error',
    `请输入20字以上的详细课后点评。`,
    `請輸入20字以上的詳細課後點評。`,
    `詳しいレッスンレビューは20字以上で入力してください。`,
    `Vui lòng nhập đánh giá buổi học chi tiết từ 20 ký tự trở lên.`,
  ],
  toast: [
    `리뷰를 남겨주셔서 감사합니다.`,
    `Thank you for your feedback!`,
    's_review.toast',
    `感谢您的评价。`,
    `感謝您的評價。`,
    `レビューを入力して頂きありがとうございます。`,
    `Cảm ơn bạn đã để lại nhận xét.`,
  ],
  title_pointback: [
    `새로 온 튜터 후기 작성`,
    `Review for the rookie tutor`,
    's_review.title_pointback',
    `新教師評價`,
    `新教师评价`,
    `新規チューターレビュー`,
    `Đánh giá gia sư mới`,
  ],
  desc_pointback: (point) => [
    <>수업 후기를 100자 이상 입력해야 {point}포인트를 돌려 받을 수 있어요. 지금 수업 평가를 완료하시겠어요?</>,
    <>You must write at least 100 characters to receive {point}P. Do you want to submit the review now?</>,
    's_review.desc_pointback',
    <>寫下100字以上的課後評價，獲得 {point}點數. 請問現在要撰寫評價嗎?</>,
    <>写下100字以上的课后评价，获得 {point}点数. 请问现在要撰写评价吗?</>,
    <>授業レビューを100文字以上入力すると、{point}ポイントが返されます! 授業評価を完了しますか？</>,
    <> Bạn phải viết ít nhất 100 ký tự để nhận {point} điểm. Bạn có muốn gửi nhận xét bây giờ? </>,
  ],
  label_pointback: [
    `50% 포인트백`,
    `50% Value Back`,
    's_review.label_pointback',
    `50% 点数返点`,
    `50% 點數回饋`,
    `50% Point Back`,
    `50% Point Back`,
  ],
  info_pointback: (point) => [
    <>100자 이상의 수업 후기를 남기면 {point}P를 돌려드려요!</>,
    <>Write a review over 100 characters and receive {point}P!</>,
    's_review.info_pointback',
    <>留下100字以上的回饋，獲得 {point}P點數!</>,
    <>留下100字以上的回馈，获得 {point}P点数!</>,
    <>100文字以上のレビューを残したら{point}Pを返します!</>,
    <>Viết đánh giá hơn 100 ký tự và nhận thưởng</>,
  ],
  toast_pointback: [
    `새로 온 튜터 후기 작성 포인트가 적립되었습니다.`,
    `Points have been rewarded for your rookie tutor review.`,
    's_review.toast_pointback',
    `已獲得為新教師評價回饋點數`,
    `已获得为新教师评价回馈点数`,
    `新規チューターレビューポイントが貯まりました。`,
    `Điểm thưởng cho bài đánh giá gia sư mới.`,
  ],
  title_report: [
    `수업에 어떤 문제가 있었나요?`,
    `What was the problem?`,
    's_review.title_report',
    `上课期间发生了什么问题？`,
    `上課期間發生了什麼問題？`,
    `レッスンで何か問題がありましたか？`,
    `Buổi học có vấn đề gì không?`,
  ],
  report_option_1: [
    `줌이 제대로 실행되지 않았어요.`,
    `Zoom didn't launch properly.`,
    's_review.report_option_1',
    `ZOOM软件没有正常启动。`,
    `ZOOM軟體沒有正常啟動。`,
    `ZOOMがきちんと実行できませんでした。`,
    `Zoom không hoạt động bình thường.`,
  ],
  report_option_2: [
    `비디오/오디오가 연결되지 않았어요.`,
    `Couldn't connect video/audio.`,
    's_review.report_option_2',
    `视频/ 音频无法连接。`,
    `視頻/ 音頻無法連接。`,
    `ビデオ／オーディオが連携されませんでした。`,
    `Video/âm thanh không kết nối được.`,
  ],
  report_option_3: [
    `튜터가 입장하지 않았어요.`,
    `The tutor didn't show up.`,
    's_review.report_option_3',
    `教师没有到场。`,
    `教師沒有到場。`,
    `チューターが入場しませんでした。`,
    `Gia sư không vào`,
  ],
  report_option_4: [
    `정규 수업 시간을 충분히 채우지 못했어요.`,
    `The tutor was late.`,
    's_review.report_option_4',
    `教师迟到了。`,
    `教師遲到了。`,
    `チューターがレッスンに遅刻しました。`,
    `Gia sư vào buổi học muộn`,
  ],
  report_option_5: [
    `수업이 자꾸 끊겼어요.`,
    `The internet connection was bad.`,
    's_review.report_option_5',
    `课程卡顿。`,
    `課程卡頓。`,
    `レッスンが頻繁に切れます。`,
    `Buổi học liên tục bị gián đoạn`,
  ],
  subtitle_report: [
    `문제 상황을 설명해 주세요.`,
    `Can you describe the problem(s) you experienced?`,
    's_review.subtitle_report',
    `请说明具体问题。`,
    `請說明具體問題。`,
    `問題状況を説明してください。`,
    ``,
  ],
  placeholder_report: [
    `불편을 겪으신 사항을 자세히 알려주세요. 
링글에 등록된 이메일로 3영업일 이내 답변 드리겠습니다.`,
    `Please tell us about the problem(s) you experienced in detail. We will get back to you via your registered email address within 3 business days.`,
    's_review.placeholder_report',
    `请详细说明您的不便事项。我们将在 3个工作日内透过您注册Ringle的邮件地址告知您处理结果。`,
    `請詳細說明您的不便事項。我們將在 3個工作日內透過您註冊Ringle的Email告知您處理結果。`,
    `ご不便をおかけした内容を詳しく教えてください。
登録されたメールアドレスへ3日以内にご案内いたします。`,
    `Vui lòng cho biết chi tiết về sự bất tiện mà bạn gặp phải. Chúng tôi sẽ thông báo cho bạn qua địa chỉ email đăng ký trong vòng 3 ngày làm việc.`,
  ],
  desc_report: [
    `접수가 완료되었습니다. 수업 이용에 불편을 드려 죄송합니다. 링글에 등록된 이메일로 3영업일 이내 답변 드리겠습니다.`,
    `Your experience has been reported. We apologize for the trouble you had. We will get back to you via your registered email address within 3 business days.`,
    's_review.desc_report',
    `已收到您的问题，为您带来不便我们感到非常抱歉，我们将在 3个工作日内透过您注册Ringle的邮件地址告知您处理结果。`,
    `已收到您的問題，為您帶來不便我們感到非常抱歉，我們將在 3個工作日內透過您註冊Ringle的Email告知您處理結果。`,
    `受付完了しました。ご迷惑をおかけし申し訳ございません。登録されたメールアドレスへ3日以内にご案内いたします。`,
    `Chúng tôi đã nhận được báo cáo. Chúng tôi xin lỗi về sự cố này. Chúng tôi sẽ thông báo cho bạn qua địa chỉ email đăng ký trong vòng 3 ngày làm việc.`,
  ],
  title_quit: [
    `수업 나가기`,
    `Leave lesson`,
    's_review.title_quit',
    `退出课程`,
    `退出課程`,
    `レッスン退出`,
    `Ra khỏi buổi học`,
  ],
  desc_quit: [
    `수업을 중단하시겠습니까?`,
    `Would you like to leave the lesson?`,
    's_review.desc_quit',
    `要中断本节课吗？`,
    `要中斷本節課嗎？`,
    `レッスンを中断しますか？`,
    `Bạn có muốn dừng buổi học không?`,
  ],
  btn_quit_1: [`취소`, `Cancel`, 's_review.btn_quit_1', `取消`, `取消`, `キャンセル`, `Hủy`],
  btn_quit_2: [`나가기`, `Leave`, 's_review.btn_quit_2', `退出`, `退出`, `退出`, `Ra ngoài`],
  btn_10: [`평가 완료하기`, `Complete`, 's_review.btn_10', `Complete`, `Complete`, `評価を完了`, `Complete`],
  btn_11: [`추가 작성하기`, `Write more`, 's_review.btn_11', `Write more`, `Write more`, `追加作成`, `Write more`],
  subtitle_7: (name) => [
    `이번 수업에서 ${name}의 좋았던 점을 알려주세요. (1개 이상)`,
    `Please check every item that describes what you liked about ${name} or this lesson. (Please choose at least one item.)`,
    's_review.subtitle_7',
    `请告诉我们${name}在这节课上令您感到满意的表现。（一个以上）`,
    `請告訴我們${name}在這節課上令您感到滿意的表現。（一個以上）`,
    `今回のレッスンで${name}の良かった点を教えてください。(1つ以上)`,
    `Vui lòng cho biết điểm tốt của ${name} trong buổi học này. (nhiều hơn 1 điểm)`,
  ],
  placeholder_optional_material: [
    `교재가 수업에 얼마나 도움이 되었는지 의견을 남겨주세요. (선택)`,
    `How helpful was the learning material? (Optional)`,
    's_review.placeholder_optional_material',
    `教材对课程的帮助程度（选填）`,
    `教材對課程的幫助程度（選填）`,
    `レッスン教材についてのご意見をお願いします。(任意)`,
    `Đánh giá tài liệu học (không bắt buộc)`,
  ],
  placeholder_optional_network: [
    `네트워크 환경에 대한 의견을 남겨주세요. (선택)`,
    `Did you have any network issues? (Optional)`,
    's_review.placeholder_optional_network',
    `请留下对于连线品质的意见（选填）`,
    `請留下對於連線品質的意見（選填）`,
    `ネットワークについてのご意見をお願いします。(任意)`,
    `Đánh giá kết nối mạng (không bắt buộc)`,
  ],
  modal_title_exit: [`수업 평가 중단`, `Postpone Lesson Evaluation`, 's_review.modal_title_exit', ``, ``, ``, ``],
  modal_desc_exit_1: [
    `고객님의 솔직한 평가는 다른 사람들에게 큰 도움이 됩니다.
48시간 안에 [1:1 수업] > [복습]에서 수업 평가를 완료해주세요.`,
    `Your frank evaluation will help other Ringlers greatly. 
Please finish evaluating the lesson within 48 hours by going to [1:1 lessons] > [Review].`,
    's_review.modal_desc_exit_1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_contibue_1: [`계속하기`, `Go back`, 's_review.btn_contibue_1', ``, ``, ``, ``],
  btn_exit_1: [`나중에 하기`, `Postpone for now`, 's_review.btn_exit_1', ``, ``, ``, ``],
  modal_desc_exit_2: [
    `자세한 수업 후기는 더 나은 링글 서비스를 위해 큰 도움이 됩니다.
지금까지 작성한 내용을 삭제하고 나가시려면 [나가기]를 클릭하세요.`,
    `A detailed review will help us improve our services. If you wish to delete what you have written so far and leave this page, please click [Leave].`,
    's_review.modal_desc_exit_2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_contibue_2: [`계속 작성하기`, `Go back`, 's_review.btn_contibue_2', ``, ``, ``, ``],
  btn_exit_2: [`나가기`, `Leave`, 's_review.btn_exit_2', ``, ``, ``, ``],
}
export const s_convert_credit = {
  change_credit: [
    `수업권 변경`,
    `Exchange Lesson Credits`,
    's_convert_credit.change_credit',
    `课程券变更`,
    `變更課程券`,
    `レッスンチケット変更`,
    `Quy đổi Thẻ học`,
  ],
  select_the_package_to_change: [
    `변경 할 패키지를 선택해주세요`,
    `Select lesson credits to exchange`,
    's_convert_credit.select_the_package_to_change',
    `请选择要变更的礼包`,
    `請選擇欲變更的禮包`,
    `変更するパッケージを選択してください。`,
    `Chọn Thẻ học bạn muốn đổi`,
  ],
  select_more_than_zero: [
    `수업권 종류 및 0개 이상의 갯수를 선택해주세요`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.select_more_than_zero',
    `请选择课程券种类及1张以上的课程券`,
    `請選擇課程券種類及1張以上`,
    `レッスンチケットの種類および1枚以上の枚数を選択してください。`,
    `Vui lòng chọn ít nhất một thẻ học bạn muốn đổi.`,
  ],
  plz_agree_to_terms: [
    `변경 이용 약관 동의를 해주세요`,
    `Please agree to the exchange terms of service.`,
    's_convert_credit.plz_agree_to_terms',
    `请同意变更使用条款`,
    `請同意變更使用條款`,
    `変更利用規約に同意してください。`,
    `Vui lòng đồng ý với điều khoản quy đổi.`,
  ],
  the_change_has_been_successful: [
    `변환이 완료되었습니다. 수업권 관리 페이지에서 확인해보세요!`,
    `We have successfully exchanged your lesson credits. You can see the change under My Account.`,
    's_convert_credit.the_change_has_been_successful',
    `已完成变换。请在课程券管理页面进行确认!`,
    `轉換成功，請於課程券管理頁面確認！`,
    `変更が完了しました。レッスンチケット管理ページで確認してみましょう！`,
    `Bạn đã quy đổi thẻ học thành công. Bạn có thể xem thay đổi dưới phần Tài khoản của tôi.`,
  ],
  u_cannot_change_to_the_same_type: [
    `같은 타입으로 변경이 불가능합니다.`,
    `You cannot exchange to the same lesson type.`,
    's_convert_credit.u_cannot_change_to_the_same_type',
    `不能变更为相同类型。`,
    `無法變更為相同類型。`,
    `同じタイプに変更することはできません。`,
    `Bạn không thể đổi sang buổi học có cùng hình thức.`,
  ],
  u_cannot_change_to_the_same_target: [
    `같은 시간으로 변경이 불가능합니다.`,
    `You cannot exchange to the same duration.`,
    's_convert_credit.u_cannot_change_to_the_same_target',
    `不能变更为相同时间。`,
    `無法變更為相同時間。`,
    `同じ時間に変更することはできません。`,
    `Bạn không thể đổi sang buổi học có cùng thời lượng.`,
  ],
  u_cannot_change_to_the_zero_credit: [
    `변경 되는 수업권 수가 0개 입니다. 변경 할 수업권 갯수를 다시 선택해주세요.`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.u_cannot_change_to_the_zero_credit',
    `变更的课程券为0张。请重新选择要进行变更的课程券数量。`,
    `可變更的課程券為0張。請重新選擇欲變更的課程券數量。`,
    `変更できるレッスンチケット数は0枚です。変更するレッスンチケットの枚数をもう一度選択してください。`,
    `Vui lòng chọn ít nhất một thẻ học bạn muốn đổi.`,
  ],
  notice: [
    `수업권 변경 유의사항`,
    `Terms`,
    's_convert_credit.notice',
    `课程券变更的注意事项`,
    `課程券變更注意事項`,
    `レッスンチケット変更の注意事項`,
    `Điều khoản`,
  ],
  strong_1: [
    `1. 변경 할 패키지를 선택해주세요.`,
    `Select a package to exchange`,
    's_convert_credit.strong_1',
    `1. 请选择要变更的礼包。`,
    `1. 請選擇欲變更的禮包。`,
    `1. 変更するパッケージを選択してください。`,
    `Vui lòng chọn gói học muốn đổi`,
  ],
  dropdown_1: [
    `패키지를 선택해주세요`,
    `Select a package`,
    's_convert_credit.dropdown_1',
    `请选择课程券`,
    `請選擇課程券`,
    `パッケージを選択してください。`,
    `Vui lòng chọn gói học`,
  ],
  strong_2: [
    `2. 변경 할 수업권 개수를 선택해주세요`,
    `Select number of credits`,
    's_convert_credit.strong_2',
    `2. 请选择要变更的课程券数量`,
    `2. 請選擇欲變更的課程券數量。`,
    `2. 変更するレッスンチケットの枚数を選択してください。`,
    `Vui lòng chọn số thẻ học`,
  ],
  strong_3: [
    `3. 변경 할 수업권 타입을 선택해주세요`,
    `Select credit type`,
    's_convert_credit.strong_3',
    `3. 请选择要变更的课程券类型`,
    `3. 請選擇欲變更的課程券類型。`,
    `3. 変更するレッスンチケットのタイプを選択してください。`,
    `Vui lòng chọn loại thẻ học`,
  ],
  dropdown_3: [
    `변경할 패키지`,
    `Package to be converted`,
    's_convert_credit.dropdown_3',
    `要变更的礼包`,
    `欲變更的禮包`,
    `変更するパッケージ`,
    `Gói học chuẩn bị chuyển đổi`,
  ],
  confirm_convert: [
    `변경 수업권 확인`,
    `Check the exchanged credit`,
    's_convert_credit.confirm_convert',
    `确认变更的课程券`,
    `確認變更的課程券`,
    `変更レッスンチケット確認`,
    `Kiểm tra thẻ học đã quy đổi`,
  ],
  count: [`개`, `Credits`, 's_convert_credit.count', `张`, `張`, `枚`, `Thẻ học`],
  point: [`포인트`, `Points`, 's_convert_credit.point', `积分`, `點數`, `ポイント`, `Điểm`],
  no_revert: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_convert_credit.no_revert',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  understood: [
    `이를 숙지 및 동의하였고, 변경을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to exchange my credits.`,
    's_convert_credit.understood',
    `已了解以上内容并同意, 进行变更。`,
    `已清楚並同意內容，進行變更。`,
    `これについて熟知および同意し、変更を実行します。`,
    `Tôi hiểu và đồng ý với điều này, tôi muốn đổi thẻ học.`,
  ],
  convert: [`변경 진행`, `Exchange`, 's_convert_credit.convert', `进行变更`, `進行變更`, `変更実行`, `Quy đổi`],
  list_1: [
    `유료로 구매한 수업권만 수업권 변경 가능합니다. (예외: 매주 수업 듣기 수업권, 기업맞춤 수업권, 시간한정 수업권 변경 불가)`,
    `Exchanges between lesson credits is only available for paid credits. (but not for Every Week credits, B2B partner credits and limited window credits.)`,
    's_convert_credit.list_1',
    `只有付费购买的课程券才能进行变更。( 例外：每週课程券、企业专用课程券、限时课程券）`,
    `只有付費購買的課程券才能進行變更。( 例外：每週課程券、企業專用課程券、限時課程券）`,
    `有料で購入したレッスンチケットのみレッスンチケットの変更が可能です。（例外：毎週レッスンを受けるレッスンチケット、企業オーダーメイドレッスンチケット、時間限定レッスンチケットは変更不可）`,
    `Bạn chỉ có thể thay đổi thẻ học đã mua. (Ngoại trừ: Thẻ học hàng tuần, Thẻ học dành cho doanh nghiệp, thẻ học giới hạn về thời gian)`,
  ],
  list_2: [
    <Span danger>수업권 변경은 패키지 당 1회만 가능하며, 변경한 수업권은 원상복구 불가합니다.</Span>,
    <Span danger>Credit exchange is available only once per package and cannot be reversed.</Span>,
    's_convert_credit.list_2',
    <Span danger>课程券只限每套餐变更一次，变更后的课程券无法恢复。</Span>,
    <Span danger>課程券只限每套餐變更一次，變更後的課程券無法恢復。</Span>,
    <Span danger>
      レッスンチケットの変更はパッケージにつき1回まで変更可能であり、変更したレッスンチケットは元に戻すことができません。
    </Span>,
    <Span danger>
      {' '}
      Bạn chỉ có thể thay đổi thẻ học một lần cho mỗi Gói, thẻ học đã thay đổi sẽ không thể phục hồi lại.
    </Span>,
  ],
  list_3: [
    <Span danger>변경된 수업권은 환불 불가합니다.</Span>,
    <Span danger>No refund is available for exchanged credits.</Span>,
    's_convert_credit.list_3',
    <Span danger>变更后的课程券不可退款。</Span>,
    <Span danger>變更後的課程券不可退款。</Span>,
    <Span danger>変更したレッスンチケットは返金を承ることができません。</Span>,
    <Span danger>Không thể hoàn tiền đối với thẻ học đã thay đổi.</Span>,
  ],
  list_4: [
    <Span danger>수업권 변경 이력이 있는 수업권은 증명서 발급이 불가합니다.</Span>,
    <Span danger>Certificates cannot be issued for exchanged credits.</Span>,
    's_convert_credit.list_4',
    <Span danger>变更过课程券不可开具证明书。</Span>,
    <Span danger>變更過課程券不可開具證明書。</Span>,
    <Span danger>変更履歴のあるレッスンチケットは証明書の発行ができません。</Span>,
    <Span danger>Không thể cấp chứng nhận đối với thẻ học đã có lịch sử thay đổi.</Span>,
  ],
  list_5: [
    `증명서 발급 이력이 있는 수업권은 수업권 변경이 불가합니다.`,
    `Credit exchange is not available for credits for which certificate(s) have been issued in the past.`,
    's_convert_credit.list_5',
    `开具过证明书的课程券不可变更。`,
    `開具過證明書的課程券不可變更。`,
    `証明書の発行履歴のあるレッスンチケットは変更ができません。`,
    `Thẻ học đã cấp chứng nhận sẽ không thể thay đổi.`,
  ],
  complete_converted: [
    `이관이 완료되었습니다. 수업권/증명서류 페이지에서 확인해보세요`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    's_convert_credit.complete_converted',
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
  ],
  list_6: [
    `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다. (수업권 변경 전 수업 길이와 동일한 당일수업권 제공| 수강기간: 1년)`,
    `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once, instead of issuing one Last-minute Credit after completing four lessons. (All Last-minute Credits will expire after one year from issuance.)`,
    's_convert_credit.list_6',
    `使用长期券优惠获取的当日课程券在变更时统一提供。（提供与变更前课程券时间长短相同的当日课程券。/授课时间：1年）`,
    `使用長期券優惠獲取的當日課程券在變更時統一提供。（提供與變更前課程券時間長短相同的當日課程券。/授課時間：1年）`,
    `長期チケットの特典として提供される当日レッスンチケットは変更時に一括提供されます。（レッスンチケット変更前のレッスンと同じ長さの当日レッスンチケットを提供| 受講期間：1年）`,
    `Thẻ học trong ngày được cấp dưới dạng ưu đãi của thẻ học định kỳ sẽ được cấp đồng thời khi thay đổi thẻ học.
(Chiều dài của thẻ học trong ngày được cấp sẽ tương ứng với độ dài của thẻ học trước khi thay đổi)`,
  ],
  popup: {
    title: [
      `유의사항`,
      `Terms`,
      's_convert_credit.popup.title',
      `课程券变更的注意事项`,
      `課程券變更注意事項`,
      `レッスンチケット変更の注意事項`,
      `Điều khoản`,
    ],
    content_1: [
      `변경된 수업권은 원상복구 불가합니다.`,
      `Credit exchange is available only once per package and cannot be reversed.`,
      's_convert_credit.popup.content_1',
      `课程券只限每套餐变更一次，变更后的课程券无法恢复。`,
      `課程券只限每套餐變更一次，變更後的課程券無法恢復。`,
      `レッスンチケットの変更はパッケージにつき1回まで変更可能であり、変更したレッスンチケットは元に戻すことができません。`,
      `Bạn chỉ có thể thay đổi thẻ học một lần cho mỗi Gói, thẻ học đã thay đổi sẽ không thể phục hồi lại.`,
    ],
    content_2: [
      `수업권 변경 후 증명서 발급이 불가합니다.`,
      `Once exchanged, the credit(s) will not be eligible for certificate issuance.`,
      's_convert_credit.popup.content_2',
      `课程券变更后不可开具证明书。`,
      `課程券變更後不可開具證明書。`,
      `レッスンチケット変更後は証明書の発行ができません。`,
      `Không thể cấp chứng nhận sau khi thay đổi thẻ học.`,
    ],
    content_3: [
      `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다.`,
      `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once.`,
      's_convert_credit.popup.content_3',
      `使用长期券优惠获取的当日课程券在变更时统一提供。`,
      `使用長期券優惠獲取的當日課程券在變更時統一提供。`,
      `長期チケットの特典として提供される当日レッスンチケットは変更時に一括提供されます。`,
      `Thẻ học trong ngày được cấp dưới dạng ưu đãi của thẻ học định kỳ sẽ được cấp đồng thời khi thay đổi thẻ học.`,
    ],
    cancel: [`취소`, `Cancel`, 's_convert_credit.popup.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
    confirm: [`확인`, `Confirm`, 's_convert_credit.popup.confirm', `确认`, `確定`, `確認`, `Xác nhận`],
  },
  popup2: {
    title: [
      `수강기간이 연장되었어요.`,
      `Extensions applied`,
      's_convert_credit.popup2.title',
      `已延长课程券使用时间`,
      `已延長課程券使用時間`,
      `受講期間が延長されました。`,
      `Thời gian đã được gia hạn.`,
    ],
    content_1: [
      `수업권 상세 내역에서 연장된 수강기간을 확인하세요.`,
      `Please check the extended dates of the credit(s).`,
      's_convert_credit.popup2.content_1',
      `请于课程券详情确认延长时间`,
      `請於課程券詳情確認延長時間`,
      `レッスンチケット/証明書類ページで延長されたレッスンチケットの期間をご確認ください。`,
      `Vui lòng kiểm tra thời gian trên trang học.`,
    ],
    confirm: [`확인하기`, `View details`, 's_convert_credit.popup2.confirm', `确认`, `確認`, `確認する`, `Xác nhận`],
  },
}
export const s_materials = {
  title: [`교재`, `Lesson Materials`, 's_materials.title', `教材`, `教材`, `教材`, `Tài liệu học`],
  search_placeholder: [
    `원하시는 교재의 키워드를 입력해주세요. ex) Economy`,
    `Search for lesson materials ex) Economy`,
    's_materials.search_placeholder',
    `请输入您希望学习的教材关键词。ex) Economy`,
    `請輸入您希望的教材的關鍵字。ex) Economy`,
    `好きな教材のキーワードを入力してください。 ex) Economy`,
    `Tìm kiếm tài liệu buổi học. Ví dụ: Economy`,
  ],
  material_download: [`PDF`, `PDF`, 's_materials.material_download', `PDF`, `PDF`, `PDF`, `PDF`],
  view_material: [`교재 보기`, `View`, 's_materials.view_material', `查看教材`, `查看教材`, `教材を見る`, `Xem`],
  select_material_you_want_to_change: [
    `변경할 수업 교재를 선택해 주세요.`,
    `Select the lesson material you want to change.`,
    's_materials.select_material_you_want_to_change',
    `请选择要变等的课程教材。`,
    `請選擇欲變更的課程教材。`,
    `変更するレッスン教材を選択してください。`,
    `Chọn tài liệu học bạn muốn đổi.`,
  ],
  feedback_not_provided: [
    `* 튜터의 Feedback Report 미제공`,
    `* This option does not provide tutor's feedback report`,
    's_materials.feedback_not_provided',
    `*未提供教师的Feedback Report`,
    `* 未提供任課教師的Feedback Report`,
    `* チューターのFeedback Report未提供`,
    `* Lựa chọn này sẽ không cung cấp báo cáo đánh giá từ gia sư`,
  ],
  no_result: [
    `키워드에 맞는 검색 결과가 없습니다.`,
    `No Result`,
    's_materials.no_result',
    `未找到相关搜索结果`,
    `無符合關鍵字的搜尋結果。`,
    `キーワードに一致する検索結果はありません。`,
    `Không có kết quả`,
  ],
  search_result: [
    `교재 검색 결과`,
    `Search Results`,
    's_materials.search_result',
    `教材搜索结果`,
    `教材搜尋結果`,
    `教材内検索結果`,
    `Kết quả tìm kiếm`,
  ],
  insight: [`인사이트`, `Insight`, 's_materials.insight', `正文`, `인사이트`, `インサイト`, `Insight`],
  clip_article: [
    `영상 / 기사`,
    `Video Clip / Article`,
    's_materials.clip_article',
    `视频 / 新闻报道`,
    `影片／報導`,
    `動画 / 記事`,
    `Video / Bài báo`,
  ],
  key_questions: [
    `수업 질문`,
    `Questions`,
    's_materials.key_questions',
    `课程问题`,
    `課程問題`,
    `レッスンの質問`,
    `Câu hỏi`,
  ],
  expressions: [
    `핵심 표현`,
    `Expressions`,
    's_materials.expressions',
    `核心表达`,
    `核心用法`,
    `キーフレーズ`,
    `Mẫu câu`,
  ],
  does_not_include_video_article: [
    `해당 교재는 영상 및 기사가 제공되지 않습니다.`,
    `This lesson material does not include a video or an article.`,
    's_materials.does_not_include_video_article',
    `该教材不提供视频及新闻报道。`,
    `該教材未提供影片及報導。`,
    `この教材は動画および記事が提供されません。`,
    `Học liệu này không có video hoặc bài báo.`,
  ],
  new: [`새 교재`, `New material`, 's_materials.new', `新教材`, `新教材`, `新しい教材`, `Tài liệu mới`],
  like: [`찜하기`, `Save`, 's_materials.like', `收藏`, `收藏`, `お気に入り登録`, `Lưu`],
  categoryDescription_1: [
    <>
      <ul className="pl-4">
        <li>20분 수업용으로 좋습니다.</li>
        <li>영어 초중급 - 토플 iBT 19-24점, OPIC IL 이상, 토익스피킹 5-6점 - 인 분들께 추천해 드립니다.</li>
        <li>예습은 30분 이내면 충분합니다.</li>
        <li>교정 위주 수업 보다는 대화 중심 수업에 적합합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 20-minute lessons</li>
      <li>Best for beginner-intermediate level speakers (TOEFL iBT 19-24, OPIC IL and up, TOEIC Speaking 5-6)</li>
      <li>Lesson Prep takes less than 30 minutes</li>
      <li>Best for discussion-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_1',
    <>
      <ul className="pl-4">
        <li>推荐20分钟的课程。</li>
        <li>英语初中级 - 托福iBT19-24分, OPIC IL以上, 托业口语5-6分 - 推荐给以上人群。</li>
        <li>预习时间30分钟以内。</li>
        <li>比起纠正为主的课程形式, 更适用于侧重会话。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>適合20分鐘課程。</li>
        <li>推薦給英語初中級 - 托福iBT 19～24分、OPIC IL以上、多益口說5～6分的人</li>
        <li>預習不必30分鐘。</li>
        <li>比起以糾正為主的課程，更適合以對話為主的課程。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>20分レッスン用におすすめです。</li>
        <li>英語初中級 - TOEFL iBT 19-24点、OPIC IL以上、TOEIC SPEAKING 5-6点 - の 方におすすめです。</li>
        <li>予習は30分以内で十分です。</li>
        <li>校正中心レッスンよりは会話中心レッスンに適しています。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Phù hợp nhất cho buổi học 20 phút</li>
      <li>Phù hợp cho trình độ nói sơ và trung cấp (TOEFL iBT 19-24, OPIC IL trở lên, TOEIC Speaking 5-6)</li>
      <li>Chuẩn bị buổi học chưa tới 30 phút</li>
      <li>Phù hợp nhất cho buổi học tập trung thảo luận</li>
    </ul>,
  ],
  categoryDescription_2: [
    <>
      <ul className="pl-4">
        <li>40분 수업용으로 좋습니다.</li>
        <li>영어 중급 이상 - 토플 iBT +25점, OPIC IH 이상, 토익스피킹 +7점 - 분들께 추천해 드립니다.</li>
        <li>30분 이상 예습이 필요합니다.</li>
        <li>비즈니스 토론, 심층 교정 모두 가능합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 40-minute lessons</li>
      <li>Best for advanced speakers (TOFLE iBT 25+, OPIC IH and up, TOEIC Speaking 7+)</li>
      <li>Lesson Prep takes 30 minutes or more</li>
      <li>Good for both discussion and correction-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_2',
    <>
      <ul className="pl-4">
        <li>推荐40分钟的课程。</li>
        <li>英语中级以上 - 托福 iBT+25分, OPIC IH以上, 托业口语+7分 - 推荐给以上人群。</li>
        <li>需要30分钟以上的预习。</li>
        <li>商业讨论, 深度纠正均可进行。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>適合40分鐘課程。</li>
        <li>推薦給英語中級以上 - 托福 iBT +25分、OPIC IH以上、多益口說+7分的人</li>
        <li>需要30分鐘以上的預習。</li>
        <li>商務討論、深度糾正均可。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>40分レッスン用におすすめです。</li>
        <li>英語中級以上 - TOEFL iBT +25点、OPIC IH以上、TOEIC SPEAKING +7点 - の 方におすすめです。</li>
        <li>30分以上の予習が必要です。</li>
        <li>ビジネスディスカッション、詳細校正すべて可能です。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Phù hợp nhất cho buổi học 40 phút</li>
      <li>Phù hợp với trình độ nâng cao (TOFLE iBT 25+, OPIC IH và hơn, TOEIC Speaking 7+)</li>
      <li>Chuẩn bị cho buổi học cần 30 phút trở lên</li>
      <li>Phù hợp cho cả buổi học tập trung thảo luận và tập trung sửa lỗi</li>
    </ul>,
  ],
  categoryDescription_3: [
    <>
      <ul className="pl-4">
        <li>뉴욕타임즈에서 학생들을 위해 특별히 만든 교재입니다.</li>
        <li>초등 4학년 ~ 중등 3학년에게 추천해드립니다.</li>
        <li>20분, 40분 수업 모두 사용하기에 적합합니다.</li>
        <li>시사/과학 관련 토론수업을 원하는 성인 분들께도 추천합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Designed for teens & junior users</li>
      <li>Best for 4th-9th graders</li>
      <li>Good for adult discussions on current events</li>
      <li>Good for both 20 and 40-minute lessons</li>
    </ul>,
    's_materials.categoryDescription_3',
    <>
      <ul className="pl-4">
        <li>这是纽约时报专为学生们编写的教材。</li>
        <li>推荐给小学4年级 ~ 初中3年级。</li>
        <li>20分钟, 40分钟课程全部适用。</li>
        <li>同时也推荐给对时事/科学内容感兴趣的成人学员。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>紐約時報為學生們特別編寫的教材。</li>
        <li>推薦給小學4年級～國中3年級。</li>
        <li>20分鐘、40分鐘課程均適用。</li>
        <li>也推薦給希望進行時事／科學相關討論課程的成人學生。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>ニューヨークタイムズが学生のために特別に作った教材です。</li>
        <li>小学4年生 ~ 中学3年生におすすめです。</li>
        <li>20分、40分レッスン両方の使用に適しています。</li>
        <li>時事/科学関連ディスカッションレッスンをご希望の成人の方にもおすすめです。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Thiết kế riêng cho học viên tuổi teen</li>
      <li>Phù hợp cho học sinh lớp 4-lớp 9</li>
      <li>Phù hợp cho các buổi thảo luận về các sự kiện thời sự</li>
      <li>Phù hợp cho cả buổi học 20 và 40 phút</li>
    </ul>,
  ],
  categoryDescription_4: [
    <>
      <ul className="pl-4">
        <li>내가 올린 자료 - pdf, ppt, docs 형식 문서 가능 - 를 튜터와 함께 보며 수업할 수 있습니다.</li>
        <li>이메일 교정, 프레젠테이션 발표연습, 인터뷰 연습 등을 할 수 있습니다.</li>
        <li>문서 교정 위주의 수업은 40분이 적합합니다.</li>
        <li>자유주제 수업 관련 도움이 필요하시면 1:1 문의로 연락하실 수 있습니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>You can upload your own lesson materials for your lesson (PDF, ppt, docs formats).</li>
      <li>You can get corrections on your writing piece, practice presentations, or prepare for an interview.</li>
      <li>If you want to get edits for your document, we recommend you to take a 40-minute lesson.</li>
      <li>If you have any questions, please contact us!</li>
    </ul>,
    's_materials.categoryDescription_4',
    <>
      <ul className="pl-4">
        <li>我上传的资料 - 支持pdf, ppt, docs格式 - 可与教师们一起 开展针对性课程。</li>
        <li>可进行邮件校对, 练习发表报告及面试等多种形式。</li>
        <li>以文本校正为主的课程更适合40分钟。</li>
        <li>如需有关自由主题课程的帮助, 请进行在线咨询。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>可與任課教師一起觀看我上傳的資料 - 支援pdf、ppt、docs格式的文件 - 並上課。</li>
        <li>可糾正電子郵件、練習報告發表、練習面試等。</li>
        <li>以糾正文件為主的課程適合40分鐘課程。</li>
        <li>如需有關自由主題課程相關協助，您可透過聊天聯絡我們。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>
          あなたがアップロードした資料 - pdf、ppt、docs形式の文書可能 - をチューターと一緒に見ながら
          レッスンを受けることができます。
        </li>
        <li>メール校正、プレゼンテーション発表練習、面接練習などをすることができます。</li>
        <li>文書校正中心のレッスンは40分が適しています。</li>
        <li>自由テーマレッスンに関してヘルプが必要な場合は、チャネルトークでご連絡ください。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Bạn có thể tải lên học liệu của riêng bạn (PDF, ppt, docs formats).</li>
      <li>Bạn sẽ được chỉnh sửa bài viết, thực hành thuyết trình và chuẩn bị cho buổi phỏng vấn.</li>
      <li>Nếu bạn cần chỉnh sửa tài liệu, chúng tôi khuyến khích bạn đặt buổi học 40 phút.</li>
      <li>Nếu bạn có câu hỏi, hãy liên hệ với chúng tôi!</li>
    </ul>,
  ],
  speaking_focused_guide_title: [
    <>
      모의 인터뷰, 프레젠테이션 연습, 자유주제 말하기 연습 등 원하는 주제로 수업할 수 있습니다.
      <br />
      준비한 자료를 수업에서 튜터와 함께 보고 목적에 맞는 수업을 진행해 보세요.
    </>,
    <>
      You can take a lesson on any topic through the Free Speaking lesson by uploading the relevant documents, such as
      mock interviews, practicing for presentations, etc. <br /> Students can upload their own resume, presentation,
      etc. before the lesson and then tutors will go through the material with students during the lesson and cover the
      topics for the lesson style you have selected.
    </>,
    's_materials.speaking_focused_guide_title',
    <>
      模拟面试, 报告发表练习, 自由主题会话练习等任何希望的内容 均可放入课程之中。
      <br />
      将准备好的资料于课程中使用, 和教师一起开展针对性的高效课程吧!
    </>,
    <>
      能以模擬面試、報告練習、自由主題口說練習等希望的主題上課。
      <br />
      請與任課教師一起觀看您準備的資料，進行符合目的的課程。
    </>,
    <>
      模擬面接、プレゼンテーション練習、自由テーマのスピーキング練習などご希望のテーマで
      レッスンを受けることができます。
      <br />
      準備した資料をレッスンでチューターと一緒に見ることで、目的に合ったレッスンを行うことができます。
    </>,
    <>
      Bạn có thể chọn bất kỳ chủ đề nào trong buổi học nói tự do bằng việc tải lên những tài liệu liên quan, như buổi
      phỏng vấn thử, thực hành thuyết trình,.... <br /> Học viên có thể tải lên resume, bài thuyết trình,... trước buổi
      học và gia sư sẽ cùng học viên xem qua các tài liệu và nói về các chủ đề theo kiểu học đã được bạn chọn.
    </>,
  ],
  speaking_focused_guide_text1: [
    <>[Speaking Focused 수업 유의사항]</>,
    <>[Please note for Speaking Focused Lesson]</>,
    's_materials.speaking_focused_guide_text1',
    <>[Speaking Focused课程注意事项]</>,
    <>[Speaking Focused課程注意事項]</>,
    <>[Speaking Focusedレッスンの注意事項]</>,
    <>[Lưu ý cho buổi tập trung luyện nói]</>,
  ],
  speaking_focused_guide_text2: [
    <>
      1) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      2) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다. <br />
      3) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      4) 개인 자료 업로드 시, 20분 수업은 300단어(A4 1/2장), 40분 수업은 600단어(A4 1장) 분량을 권장 드립니다. (문서의
      성격 및 튜터의 주제 친숙도에 따라 수업 내 진행할 수 있는 교정량이 상이할 수 있습니다.)
    </>,
    <>
      1) After scheduling the lesson, you can upload the material on the upcoming lesson page.
      <br />
      2) It is recommended to upload your document at least 24 hours before the lesson starts.
      <br />
      3) Writing corrections will be conducted live during the lesson.
      <br />
      4) The recommended length for self-uploaded materials is 300 words (1/2 page) for a 20-minute lesson and 600 words
      (1 page) for a 40-minute lesson. (How much of the material is covered during the lesson depends on the nature of
      the material and tutor's familiarity with the topic.)
    </>,
    's_materials.speaking_focused_guide_text2',
    <>
      1) 完成课程预约后, 可在预约课程的页面上传您的资料。
      <br />
      2) 建议最晚于课程开始前24小时完成资料上传。
      <br />
      3)
      上传资料时，20分钟的课程，建议份量为300字（A4纸半页），40分钟的课程，建议份量为600字（A4纸一页）。（根据文件内容题材及教师对主题的熟悉程度的不同，于课程中能修改的份量可能有差异。）
      <br />
      4) 文本校正可在课程中随堂进行。
    </>,
    <>
      1) 預約課程後，可於預定的課程頁面上傳資料。
      <br />
      2) 建議最晚於課程開始24小時前上傳資料。
      <br />
      3)
      上傳資料時，20分鐘的課程建議為300字（A4紙半頁），40分鐘的課程建議為600字（A4紙一頁）。（根據文件內容題材及教師對主題的熟悉程度的不同，於課程中能修改的份量可能會有差異。）
      <br />
      4) 將於上課時間即時糾正文件。
    </>,
    <>
      1) レッスン予約後、レッスンページから資料をアップロード できます。
      <br />
      2) レッスン開始24時間前までに文書をアップロードすることを推奨します。 <br />
      3) 個人資料のアップロード分量は20分レッスンでは300単語(A4 1/2枚)、40分レッスンでは600単語(A4 1枚)を推奨します。
      <br />
      (文書の内容及びチューターのテーマに対する理解度に応じてレッスン内で行える校正量が異なる場合があります。) <br />
      4)
      レッスン中の英文添削のため個人の材料をアップロードする場合、20分レッスンは500字（A41枚）、40分レッスンは1000字（A42枚）の分量をお勧めします。
    </>,
    <>
      1) Sau khi đặt lịch học, bạn có thể tải lên tài liệu học trên trang tiếp theo của buổi học.
      <br />
      2) Chúng tôi khuyến khích tải tài liệu lên tối thiểu 24 giờ trước khi buổi học bắt đầu.
      <br />
      3) Khi giảng dạy bằng tài liệu cá nhân, chúng tôi khuyến khích tiết học 20 phút 300 từ (một nửa A4 1/2 trang) và
      tiết 40 phút 600 từ (một trang A4) <br />
      (Số lượng hiệu chuẩn có thể khác nhau trong lớp tùy thuộc vào tính chất của tài liệu và mức độ quen thuộc chủ đề
      của người hướng dẫn.)
      <br />
      4) Sau khi tải tài liệu của bạn để sử dụng trong buổi học Chủ đề tự do, lưu ý về số lượng từ trong tài liệu: 500
      từ (khoảng 1 trang A4) với buổi học 20 phút và 1,000 từ (2 tờ A4) cho buổi học 40 phút.
    </>,
  ],
  writing_focused_guide_title: [
    <>
      직접 작성한 에세이, 이력서, 이메일 등 영문서를 업로드하고 수업에서 교정 받을 수 있습니다.
      <br />
      업로드한 자료를 수업에서 튜터와 함께 보고 상황과 문맥에 맞는 문장으로 교정해 보세요.
    </>,
    <>
      Students can upload their own essay, resume, e-mail, etc. before the lesson and then tutors will go through the
      material with students during the lesson and provide appropriate corrections.
    </>,
    's_materials.writing_focused_guide_title',
    <>
      您可上传亲自撰写的申论、简历、电子邮件等英文文件，在上课时接受纠正。
      <br />
      上课时请与任课教师一起观看您上传的资料，修改为符合情境的句子。
    </>,
    <>
      您可上傳親自撰寫的申論、簡歷、電子郵件等英文文件，在上課時接受糾正。
      <br />
      上課時請與任課教師一起觀看您上傳的資料，修改為符合情境的句子。
    </>,
    <>
      直接作成したエッセイ、履歴書、メールなどの英文書をアップロードして、レッスンで校正 してもらうことができます。
      <br />
      アップロードした資料をレッスンでチューターと一緒に見て、状況や文脈に合う文章に 校正してみましょう。
    </>,
    <>
      Học viên có thể tải lên bài luận, resume, email,... của chính họ trước buổi học và gia sư sẽ hướng dẫn học viên
      dựa trên những tài liệu này và cung cấp những điều chỉnh thích hợp.
    </>,
  ],
  writing_focused_guide_text_1: [
    <>[Writing Focused 수업 유의사항]</>,
    <>[Please note for Writing Focused Lesson] </>,
    's_materials.writing_focused_guide_text_1',
    <>[Writing Focused课程注意事项]</>,
    <>[Writing Focused課程注意事項]</>,
    <>[Writing Focusedレッスンの注意事項]</>,
    <>[Lưu ý cho buổi tập trung viết] </>,
  ],
  writing_focused_guide_text_2: [
    <>
      1) Writing 위주의 수업으로&nbsp; 튜터 Feedback Report는 제공되지 않습니다.
      <br />
      2) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      3) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다.
      <br />
      4) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      5) 개인 자료 업로드 시, 20분 수업은 300단어(A4 1/2장), 40분 수업은 600단어(A4 1장) 분량을 권장 드립니다. (문서의
      성격 및 튜터의 주제 친숙도에 따라 수업 내 진행할 수 있는 교정량이 상이할 수 있습니다.)
    </>,
    <>
      1) Feedback reports are not available for Writing Focused lesson. upcoming lesson page.
      <br />
      2) After scheduling the lesson, you can upload the material on the lesson starts.
      <br />
      3) It is recommended to upload your document at least 24 hours before the
      <br />
      4) Writing corrections will be conducted live during the lesson.
      <br />
      5) The recommended length for self-uploaded materials is 300 words (1/2 page) for a 20-minute lesson and 600 words
      (1 page) for a 40-minute lesson. (How much of the material is covered during the lesson depends on the nature of
      the material and tutor's familiarity with the topic.)
    </>,
    's_materials.writing_focused_guide_text_2',
    <>
      1) 完成课程预约后, 可在预约课程的页面上传您的资料。
      <br />
      2) 建议最晚于课程开始前24小时完成资料上传。
      <br />
      3) 文本校正可在课程中随堂进行。
      <br />
      4) Writing为主的课程, &nbsp; 教师将不提供Feedback Report。
      <br />
      5)
      上传资料时，20分钟的课程，建议份量为300字（A4纸半页），40分钟的课程，建议份量为600字（A4纸一页）。（根据文件内容题材及教师对主题的熟悉程度的不同，于课程中能修改的份量可能有差异。）
    </>,
    <>
      1) 預約課程後，可於預定的課程頁面上傳資料。
      <br />
      2) 建議最晚於課程開始24小時前上傳資料。
      <br />
      3) 將於上課時間即時糾正文件。
      <br />
      4) 以Writing為主的課程&nbsp; 將不提供任課教師的Feedback Report。
      <br />
      5)
      上傳資料時，20分鐘的課程建議為300字（A4紙半頁），40分鐘的課程建議為600字（A4紙一頁）。（根據文件內容題材及教師對主題的熟悉程度的不同，於課程中能修改的份量可能會有差異。）
    </>,
    <>
      1) レッスン予約後、レッスン予定ページから資料をアップロードすることが できます。
      <br />
      2) レッスン開始24時間前までに文書をアップロードすることを推奨します。
      <br />
      3) 文書校正はレッスン時間にリアルタイムで行われます。
      <br />
      4) Writing中心のレッスンでは&nbsp; チューターのFeedback Reportは提供されません。 <br />
      5) 個人資料のアップロード分量は20分レッスンでは300単語(A4 1/2枚)、40分レッスンでは600単語(A4 1枚)を推奨します。
      <br />
      (文書の内容及びチューターのテーマに対する理解度に応じてレッスン内で行える校正量が異なる場合があります。)
    </>,
    <>
      1) Sau khi đặt lịch học, bạn có thể tải lên tài liệu học trên trang tiếp theo của buổi học.
      <br />
      2) Chúng tôi khuyến khích tải tài liệu lên tối thiểu 24 giờ trước khi buổi học bắt đầu.
      <br />
      3) Chỉnh lỗi bài viết sẽ diễn ra trong quá trình học.
      <br />
      4) Báo cáo đánh giá sẽ không có trong buổi tập trung viết.
      <br />
      5) Khi giảng dạy bằng tài liệu cá nhân, chúng tôi khuyến khích tiết học 20 phút 300 từ (một nửa A4 1/2 trang) và
      tiết 40 phút 600 từ (một trang A4) <br />
      (Số lượng hiệu chuẩn có thể khác nhau trong lớp tùy thuộc vào tính chất của tài liệu và mức độ quen thuộc chủ đề
      của người hướng dẫn.)
    </>,
  ],
  search_material: [
    `교재 검색`,
    `Search`,
    's_materials.search_material',
    `教材搜索`,
    `教材搜索`,
    `教材を検索`,
    `Tìm tài liệu`,
  ],
  search_results: [
    `교재 검색 결과`,
    `Search Results`,
    's_materials.search_results',
    `教材搜寻结果`,
    `教材搜尋結果`,
    `教材検索結果`,
    `Kết quả tìm kiếm`,
  ],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_materials.login_desc',
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
  ],
  login: [`로그인`, `Log in`, 's_materials.login', `Log in`, `Log in`, `Log in`, `Log in`],
  more_from_ringle: [
    `링글 관련 콘텐츠`,
    `More from Ringle`,
    's_materials.more_from_ringle',
    `More from Ringle`,
    `More from Ringle`,
    `More from Ringle`,
    `More from Ringle`,
  ],
  download_material: [
    `다운로드하기`,
    `Download Materials`,
    's_materials.download_material',
    `Download Materials`,
    `Download Materials`,
    `ダウンロードをする`,
    `Tải tài liệu`,
  ],
  notice_desc: [
    <>* 라이팅 중심 수업은 튜터 피드백 리포트가 제공되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.notice_desc',
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
  ],
  banner_title: [
    <>Writing-focused 수업은 튜터 Feedback Report가 제출되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.banner_title',
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
  ],
  copyright_desc1: [
    `*본 교재는 당사 편집진이 제작하는 링글의 자산으로, 저작권법에 의해 보호됩니다. 링글 플랫폼 외에서 자료를 활용하시는 경우, 당사와 사전 협의가 필요합니다.`,
    `*This material is designed for the exclusive use of Ringle students on the Ringle platform.`,
    's_materials.copyright_desc1',
    `*本教材是专爲使用Ringle学习英文的学员设计。`,
    `*本教材是專爲使用Ringle學習英文的學員設計。`,
    `*本教材は、Ringleを使用して英語を学ぶ学習者向けに設計されています。`,
    `*This material is designed for the exclusive use of Ringle students on the Ringle platform.`,
  ],
  action_title: [
    `지금 보신 교재가 마음에 드셨다면?`,
    `Like this material?`,
    's_materials.action_title',
    `Like this material?`,
    `Like this material?`,
    `この教材が気に入りましたか？`,
    `Like this material?`,
  ],
  action_subtitle: [
    `이 교재로 수업해 보세요.`,
    `Use this material for your next lesson.`,
    's_materials.action_subtitle',
    `Use this material for your next lesson.`,
    `Use this material for your next lesson.`,
    `次回のレッスンでぜひご使用ください。`,
    `Use this material for your next lesson.`,
  ],
  btn1: [
    `수업 예약하기`,
    `Book lessons`,
    's_materials.btn1',
    `Book lessons`,
    `Book lessons`,
    `レッスンを予約する`,
    `Book lessons`,
  ],
  copyright_desc2: [
    <>
      [연습/Practice]교재는 다음과 같이 활용해보세요. <br />
      1. 인사이트의 주요 표현 및 문구 혼자서 따라하며 연습하기
      <br />
      2. “수업 질문” 를 선택하여 1:1 수업에서 연습한 표현을 직접 써보기
      <br />
      3. 원어민과 수업하며 상황에 적절한 또다른 표현 배워보기
    </>,
    <>
      Here’s how you can use the [Practice] material effectively: <br />
      1. Practice key expressions and phrases from the Ringle material.
      <br />
      2. Choose “Questions” to apply the expressions you’ve practiced in a lesson. <br />
      3. Learn other appropriate expressions while practicing with a tutor during a lesson.
    </>,
    's_materials.copyright_desc2',
    <>
      以下是如何有效使用[实战练习]教材的说明： 1. 练习 Ringle 教材中的核心表达和单字片语。
      <br />
      2. 选择“课程问题”运用事先练习过的核心表达。
      <br />
      3. 透过上课时和教师练习学习其他的表达方式。
    </>,
    <>
      Here’s how you can use the [Practice] material effectively: <br />
      1. Practice key expressions and phrases from the Ringle material.
      <br />
      2. Choose “Questions” to apply the expressions you’ve practiced in a lesson. <br />
      3. Learn other appropriate expressions while practicing with a tutor during a lesson.
    </>,
    <>
      Here’s how you can use the [Practice] material effectively: <br />
      1. Practice key expressions and phrases from the Ringle material.
      <br />
      2. Choose “Questions” to apply the expressions you’ve practiced in a lesson. <br />
      3. Learn other appropriate expressions while practicing with a tutor during a lesson.
    </>,
    ``,
  ],
}
export const s_upcoming_lessons = {
  to_do_list: [
    `예습 목록`,
    `Lesson Prep List`,
    's_upcoming_lessons.to_do_list',
    `预习目录`,
    `預習清單`,
    `予習リスト`,
    `Danh sách Chuẩn bị Buổi học`,
  ],
  not_confirmed: [
    `확정 대기중`,
    `Not Confirmed`,
    's_upcoming_lessons.not_confirmed',
    `等待确定中`,
    `等待確定中`,
    `確定待ち`,
    `Chưa xác nhận`,
  ],
  confirmed: [
    `확정 완료`,
    `Confirmed`,
    's_upcoming_lessons.confirmed',
    `任课教师已确定`,
    `任課教師已確定`,
    `確定済み`,
    `Đã xác nhận`,
  ],
  compatible_tutor: [
    `고객님께 적합한 튜터를 매칭 중입니다.`,
    `We are finding the best tutor for you.`,
    's_upcoming_lessons.compatible_tutor',
    `正在为您正在为您配对适合的任课教师适合的任课教师。`,
    `正在為您配對適合的任課教師。`,
    `お客様に適したチューターをマッチング中です。`,
    `Chúng tôi đang tìm gia sư tốt nhất cho bạn.`,
  ],
  option_not_change: [
    `튜터가 확정되어 옵션 변경이 불가능합니다.`,
    `Option cannot be changed since the lesson has a tutor assigned with confirmation.`,
    's_upcoming_lessons.option_not_change',
    `已确定教师, 无法更改选项。`,
    `已確定任課教師，無法變更選項。`,
    `チューターが確定したため、オプション変更は不可能です。`,
    `Lựa chọn không thể thay đổi vì buổi học đã có gia sư xác nhận.`,
  ],
  option_not_change_auto: [
    `자동매칭은 옵션 변경이 불가능합니다.`,
    `Option cannot be changed with auto tutor matching`,
    's_upcoming_lessons.option_not_change_auto',
    `自动配对无法更改选项。`,
    `自動配對無法變更選項。`,
    `自動マッチングはオプション変更が不可能です。`,
    `Lựa chọn không thể thay đổi khi sử dụng sắp xếp gia sư ngẫu nhiên`,
  ],
  matching_after: {
    auto: [
      `자동으로 매칭해주세요`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.matching_after.auto',
      `请自动进行配对`,
      `請自動為我配對`,
      `自動的にマッチングしてください。`,
      `Xếp ngẫu nhiên tôi với một gia sư khác.`,
    ],
    credit: [
      `수업을 취소하고 수업권을 복구해주세요`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.matching_after.credit',
      `请取消课程, 恢复课程券`,
      `請取消課程並為我復原課程券`,
      `レッスンをキャンセルしてレッスンチケットを復元してください。`,
      `Huỷ và khôi phục các thẻ học của tôi.`,
    ],
  },
  options: {
    option: {
      title: [
        `튜터 매칭 옵션 변경`,
        `In case of tutor cancellation`,
        's_upcoming_lessons.options.option.title',
        `变更教师配对选项`,
        `變更教師配對選項`,
        `チューターマッチングオプション変更`,
        `Trong trường hợp gia sư huỷ`,
      ],
      description: [
        `선택한 튜터와 수업이 이뤄지지 않을 때 처리 방법을 설정합니다.`,
        `Please select how you'd like to resolve cancellation by your tutor.`,
        's_upcoming_lessons.options.option.description',
        `请设置与选择的教师无法进行课程时的处理方式。`,
        `設定我選擇的任課教師與課程未搭配成功時的處理方式。`,
        `選択したチューターとレッスンが行われない時の処理方法を設定します。`,
        `Vui lòng lựa chọn hướng giải quyết cho buổi học bị huỷ bởi gia sư.`,
      ],
    },
    time: {
      title: [
        `수업시간 변경`,
        `Reschedule Lesson`,
        's_upcoming_lessons.options.time.title',
        `课程时间变更`,
        `變更課程時間`,
        `レッスン時間変更`,
        `Dời lịch Buổi học`,
      ],
      description: [
        `수업이 확정되기 전까지만 가능합니다.`,
        `Lesson time can only be changed before the lesson is confirmed by tutor`,
        's_upcoming_lessons.options.time.description',
        `仅可于课程确定前进行。`,
        `僅可於課程確定之前進行。`,
        `レッスンが確定する前まで可能です。`,
        `Giờ học chỉ có thể thay đổi trước khi gia sư xác nhận buổi học`,
      ],
    },
    course: {
      title: [
        `교재 변경`,
        `Change Materials`,
        's_upcoming_lessons.options.course.title',
        `教材变更`,
        `更改教材`,
        `教材変更`,
        `Thay đổi Tài liệu`,
      ],
      description: [
        `수업 교재를 변경할 수 있습니다.`,
        `You can change the lesson material.`,
        's_upcoming_lessons.options.course.description',
        `可变更课程教材。`,
        `可更改課程教材。`,
        `レッスンの教材を変更することができます。`,
        `Bạn có thể thay đổi tài liệu buổi học.`,
      ],
    },
    setting: {
      title: [
        `수업 방식 설정`,
        `Set Lesson Style`,
        's_upcoming_lessons.options.setting.title',
        `课程模式设定`,
        `課程模式設定`,
        `レッスン方式の設定`,
        `Chọn Hình thức học`,
      ],
      check_title: [
        `수업 방식`,
        `Lesson Style`,
        's_upcoming_lessons.options.setting.check_title',
        `课程形式`,
        `課程模式`,
        `レッスン方式`,
        `Hình thức học`,
      ],
      description: [
        `수업녹음, 수업모드 등에 대해 설정할 수 있습니다.`,
        `Set Lesson Style for audio recording and customized lesson.`,
        's_upcoming_lessons.options.setting.description',
        `可设定课程录音、课程模式。`,
        `可設定課程錄音、課程模式。`,
        `録音設定、レッスンモードなどを設定することができます。`,
        `Chọn Hình thức học để thu âm và điều chỉnh buổi học.`,
      ],
    },
    cancel: {
      title: [
        `수업 취소`,
        `Cancel Lesson`,
        's_upcoming_lessons.options.cancel.title',
        `取消课程`,
        `取消課程`,
        `レッスンキャンセル`,
        `Huỷ buổi học`,
      ],
      description: [
        `수업 시작 24시간 이내 취소하면 수업권이 복구되지 않습니다.`,
        `Cancellations made less than 24 hours before the lesson will be non-refundable.`,
        's_upcoming_lessons.options.cancel.description',
        `于课程开始前24小时以内取消时，将不復原课程券。`,
        `於課程開始前24小時以內取消時，將不復原課程券。`,
        `レッスン開始24時間以内のキャンセルはレッスンチケットが復元されません。`,
        `Huỷ lớp trong vòng 24 giờ trước buổi học sẽ không được hoàn lại thẻ học.`,
      ],
      description2: [
        `튜터가 배정되지 않아 취소시 수업권이 자동으로 복구됩니다.`,
        `Since a tutor hasn't been assigned, if you cancel now we'll restore your leson credit.`,
        's_upcoming_lessons.options.cancel.description2',
        `若因无法配对教师导致课程取消时，将復原课程券。`,
        `若因無法配對教師導致課程取消時，將復原課程券。`,
        `チューターが割り当てられずキャンセルになる場合、レッスンチケットは自動的に復元します。`,
        `Vì gia sư chưa xác nhận, nếu bạn huỷ bây giờ chúng tôi sẽ hoàn lại thẻ học cho bạn.`,
      ],
    },
  },
  banner_zoom: {
    test_for_video_call: [
      `Zoom 설치 가이드`,
      `Test Zoom`,
      's_upcoming_lessons.banner_zoom.test_for_video_call',
      `Zoom安装指南`,
      `Zoom安裝指南`,
      `Zoomインストールガイド`,
      `Kiểm tra Zoom`,
    ],
  },
  no_schedule_ahead: [
    `현재 예정되어 있는 수업이 없습니다.`,
    `You have no upcoming lessons.`,
    's_upcoming_lessons.no_schedule_ahead',
    `目前没有预定的课程。`,
    `目前無預定的課程。`,
    `現在予定されているレッスンはありません。`,
    `Bạn không có buổi học nào sắp tới.`,
  ],
  go_to_registration_page: [
    `수업 예약하러 가기`,
    `Book Lessons`,
    's_upcoming_lessons.go_to_registration_page',
    `前去预约课程`,
    `前往預約課程`,
    `レッスンを予約する`,
    `Đặt lịch học`,
  ],
  lesson_enter_modal_title: [
    `수업 입장 안내`,
    `Enter Lesson`,
    's_upcoming_lessons.lesson_enter_modal_title',
    `进入课程`,
    `進入課程`,
    `レッスン入室方法のご案内`,
    `Vào học`,
  ],
  lesson_style_btn: [
    `수업 방식 설정`,
    `Set Lesson Style`,
    's_upcoming_lessons.lesson_style_btn',
    `课程模式设定`,
    `課程模式設定`,
    `レッスン方式の設定`,
    `Chọn Hình thức học`,
  ],
  change_style_btn: [
    `수업 방식 변경`,
    `Set Lesson Style`,
    's_upcoming_lessons.change_style_btn',
    `变更课程模式`,
    `變更課程模式`,
    `レッスン方式の変更`,
    `Chọn Hình thức học`,
  ],
  enter_btn: [
    `수업 입장`,
    `Enter Lesson`,
    's_upcoming_lessons.enter_btn',
    `进入课程`,
    `進入課程`,
    `レッスン開始`,
    `Vào học`,
  ],
  zoom_modal: {
    title: [
      `수업 전 프로그램 설치 확인`,
      `Wait!`,
      's_upcoming_lessons.zoom_modal.title',
      `课程前确认程序安装`,
      `上課前確認安裝軟體`,
      `レッスン前にアプリのインストール確認`,
      `Đợi!`,
    ],
    wait: [`잠시만요!`, ``, 's_upcoming_lessons.zoom_modal.wait', `请稍等!`, `請稍等！`, `お待ちください！`, ``],
    have_you_installed_zoom: [
      `수업에 꼭 필요한 Zoom 프로그램 설치하셨나요?`,
      `Ringle lessons are run on Zoom. Have you installed Zoom?`,
      's_upcoming_lessons.zoom_modal.have_you_installed_zoom',
      `是否已安装课程必需的Zoom程序?`,
      `您安裝上課必備的Zoom軟體了嗎？`,
      `レッスンに必要なZoomはインストールしましたか？`,
      `Buổi học của Ringle diễn ra trên Zoom. Bạn đã cài đặt Zoom chưa?`,
    ],
    i_have_installed_zoom: [
      `이미 설치했습니다.`,
      `I have installed Zoom`,
      's_upcoming_lessons.zoom_modal.i_have_installed_zoom',
      `已经安装`,
      `已安裝。`,
      `すでにインストールしました。`,
      `Tôi đã cài đặt Zoom`,
    ],
    go_to_installation_page: [
      `설치 페이지 바로가기`,
      `I need to install Zoom`,
      's_upcoming_lessons.zoom_modal.go_to_installation_page',
      `前往安装页面`,
      `前往安裝頁面`,
      `インストールページに移動`,
      `Tôi cần cài đặt Zoom`,
    ],
  },
  after_tutor_cancel: {
    automatic_match_me: [
      `다른 튜터 매칭 받기`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.after_tutor_cancel.automatic_match_me',
      `接受与其他教师配对`,
      `接受與其他教師配對`,
      `他のチューターとマッチングしてもらう`,
      `Xếp ngẫu nhiên tôi với một gia sư khác.`,
    ],
    plz_let_me_know_available_times: [
      <>선택한 튜터의 다른 시간 확인하기</>,
      <>Show me this tutor's availability. </>,
      's_upcoming_lessons.after_tutor_cancel.plz_let_me_know_available_times',
      <>确认所选教师的其他时间</>,
      <>確認所選教師的其他時間</>,
      `選択したチューターの別時間帯を確認する`,
      <>Cho tôi xem lịch trống của gia sư. </>,
    ],
    cancel_and_restore_credit: [
      `수업 취소 후 사용한 수업권 복구 받기`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.after_tutor_cancel.cancel_and_restore_credit',
      `取消课程后退还课程券`,
      `取消課程後退還課程券`,
      `予約をキャンセルし、使用したレッスンチケットを復元する`,
      `Huỷ và hoàn lại thẻ học.`,
    ],
    your_option_change_has_been_completed: [
      `변경한 내용이 저장되었습니다.`,
      `Saved`,
      's_upcoming_lessons.after_tutor_cancel.your_option_change_has_been_completed',
      `变更的内容已保存`,
      `變更的內容已儲存。`,
      `変更した内容が保存されました。`,
      `Đã lưu`,
    ],
    lesson_canceled: [
      `수업이 취소되었습니다.`,
      `Lesson Canceled`,
      's_upcoming_lessons.after_tutor_cancel.lesson_canceled',
      `课程已取消。`,
      `已取消課程。`,
      `レッスンがキャンセルされました。`,
      `Buổi học bị huỷ`,
    ],
  },
  matching_after_options: {
    view_tutors_schedule: [
      `튜터의 다른 시간 보기`,
      `View tutor's availability`,
      's_upcoming_lessons.matching_after_options.view_tutors_schedule',
      `查看教师的其他时间`,
      `查看任課教師的其他時間`,
      `チューターの他の時間を見る`,
      `Xem lịch trống của gia sư`,
    ],
  },
  notice: {
    title: [
      `[화상프로그램 (Zoom) 오디오 연결 가이드]`,
      `[Zoom Audio Connection Guide]`,
      's_upcoming_lessons.notice.title',
      `[视频程序 (Zoom) 音频连接指南]`,
      `[視訊軟體（Zoom）音訊連接指南]`,
      `[ビデオ会議アプリ (Zoom) オーディオ接続ガイド]`,
      `[Hướng dẫn kết nối âm thanh trong Zoom]`,
    ],
    content: [
      <>
        수업이 시작되고 오디오 연결이 원활하지 않을 경우,
        <br />꼭 아래 이미지 위치에 있는 <b>오디오 연결</b> 버튼을 클릭해 주세요.
      </>,
      <>
        If you or the student can't hear one another on the Zoom meeting,
        <br />
        Please click on the <b>"Join Audio"</b> button.
      </>,
      's_upcoming_lessons.notice.content',
      <>
        课程开始后，音频功能无法使用时
        <br />
        请务必点击下方图案<b>音频连接</b>按钮。
      </>,
      <>
        課程開始後，音訊連接不順時，
        <br />
        請務必點擊下方圖片的<b>加入音訊</b>按鈕。
      </>,
      <>
        レッスンが始まりオーディオ接続が円滑ではない場合、
        <br />
        必ず下の画像の位置にある <b>オーディオに接続</b> ボタンをクリックして ください。
      </>,
      <>
        Nếu bạn hoặc học viên không nghe được người còn lại trên Zoom,
        <br />
        Vui lòng chọn nút <b>"Join Audio"</b>.
      </>,
    ],
    footer: [
      <>
        문제가 해결되지 않을 경우,
        <br />
        오른쪽 상단에 위치한 <b>[실시간 Help]</b> 버튼을 클릭하여 링글팀에 도움을 요청하세요.
      </>,
      <>
        If the problem persists, please contact us at <b>[Help button]</b> on the top-right corner.
      </>,
      's_upcoming_lessons.notice.footer',
      <>
        问题无法解决时
        <br />
        请点击右上方的<b>[在线Help]</b> 按钮 向Ringle团队申请帮助
      </>,
      <>
        問題未能解決時，
        <br />
        請點擊右上方的<b>[即時Help]</b> 按鈕， 向Ringle團隊尋求協助。
      </>,
      <>
        問題が解決しない場合、
        <br />
        右上にある <b>[リアルタイムHelp]</b> ボタンをクリックしてRingleチームに ヘルプをリクエストしてください。
      </>,
      <>
        Nếu vấn đề tiếp diễn, vui lòng liên hệ chúng tôi qua <b>[nút Help]</b> phía bên phải trên cùng.
      </>,
    ],
  },
  welcomeBackModal: {
    main: (name) => [
      <>
        Welcome Back {name}님<br />
        반갑습니다!
      </>,
      <>
        Welcome Back {name},<br />
        Good to see you again!
      </>,
      's_upcoming_lessons.welcomeBackModal.main',
      <>
        Welcome Back {name}用户
        <br />
        感谢您回来!
      </>,
      <>
        Welcome Back {name}，<br />
        歡迎您！
      </>,
      <>
        Welcome Back {name}さん
        <br />
        ようこそ！
      </>,
      <>
        Chào mừng {name} trở lại,
        <br />
        Rất vui lại được thấy bạn!
      </>,
    ],
    content: [
      `첫 3회 수업 45% 할인가로 다시 시작해보세요.`,
      <>Start anew with a 45% discount on first 3 Lessons.</>,
      's_upcoming_lessons.welcomeBackModal.content',
      `前3次课程享受45%优惠, 立即重新开始吧。`,
      `請以首3次課程55折優惠價重新開始吧！`,
      `初回3回レッスンを45%割引価格でもう一度始めましょう。`,
      <>Bắt đầu hành trình mới với 3 buổi học đầu được giảm giá 45%.</>,
    ],
    getDiscount: [
      `할인가로 구매하기`,
      `Buy now`,
      's_upcoming_lessons.welcomeBackModal.getDiscount',
      `以优惠价购买`,
      `以優惠價購買`,
      `割引価格で購入する`,
      `Mua ngay`,
    ],
    threeLessons: [
      `3회 수업권`,
      `3-Lesson Credits`,
      's_upcoming_lessons.welcomeBackModal.threeLessons',
      `3次课程券`,
      `3次課程券`,
      `3回レッスンチケット`,
      `Thẻ học 3 buổi`,
    ],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.welcomeBackModal.footerLeft',
      `一周内不显示`,
      `一週內不再顯示`,
      `1週間表示しない`,
      `Nhắc tôi vào tuần tới`,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.welcomeBackModal.footerRight', `关闭`, `關閉`, `閉じる`, `Đóng`],
  },
  inviteModal: {
    title: [
      <>
        친구초대 이벤트
        <br /> 2만 포인트가 적립되었습니다.
      </>,
      <>
        Referral Event
        <br /> You earned $17.24
      </>,
      's_upcoming_lessons.inviteModal.title',
      <>
        朋友邀请活动
        <br /> 获得$17.24积分。
      </>,
      <>
        邀請朋友活動
        <br />
        已累積$17.24P。
      </>,
      <>
        友達紹介イベント
        <br /> $17.24ポイントが付与されました。
      </>,
      <>
        Sự kiện giới thiệu
        <br /> Bạn nhận được $17.24
      </>,
    ],
    subtitle: [
      <>
        20분 수업을 무료로 체험하고,
        <br />첫 3회 특가로 시작해보세요.
      </>,
      <>
        Have a free 20-min Trial Lesson
        <br />
        and start Ringle at a special price
      </>,
      's_upcoming_lessons.inviteModal.subtitle',
      <>
        免费体验20分钟课程，
        <br />
        前3次课程享有优惠, 开始您的学习吧。
      </>,
      <>
        免費體驗20分鐘課程，
        <br />
        以首3次特價開始吧！
      </>,
      <>
        20分レッスンを無料で体験し、
        <br />
        最初の3回を特価で始めましょう。
      </>,
      <>
        Nhận 20 phút học thử miễn phí
        <br />
        và bắt đầu học cùng Ringle với mức giá đặc biệt
      </>,
    ],
    freeTrial: [
      `무료 체험 신청하기`,
      `Start Free Trial`,
      's_upcoming_lessons.inviteModal.freeTrial',
      `申请免费体验课程`,
      `申請免費體驗`,
      `無料体験を予約する`,
      `Bắt đầu học thử miễn phí`,
    ],
  },
  firstCouponModal: {
    main: [
      <>
        첫 3회 패키지
        <br />
        구매 고객께
        <br />
        링글이 드리는 선물
      </>,
      <>A Gift from Ringle:</>,
      's_upcoming_lessons.firstCouponModal.main',
      <>
        前3次课程礼包
        <br />
        的购买用户
        <br />
        将获得Ringle的礼物
      </>,
      <>
        Ringle贈送
        <br />
        購買首3次禮包的學生
        <br />
        禮物
      </>,
      <>
        初回3回パッケージ
        <br />
        ご購入のお客様への
        <br />
        Ringleからのプレゼント
      </>,
      <>Món quà từ Ringle:</>,
    ],
    getDiscount: [
      `쿠폰함 바로 가기`,
      `Buy now`,
      's_upcoming_lessons.firstCouponModal.getDiscount',
      `前往优惠券页面`,
      `立即前往優惠券`,
      `クーポンに移動`,
      `Mua ngay`,
    ],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.firstCouponModal.footerLeft',
      `一周内不显示`,
      `一週內不再顯示`,
      `1週間表示しない`,
      `Nhắc tôi vào tuần tới`,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.firstCouponModal.footerRight', `关闭`, `關閉`, `閉じる`, `Đóng`],
  },
  forPromotionModal: {
    main: [
      <>
        2021년에도
        <br />
        멈추지 않는 당신에게
        <br />
        드리는 혜택
      </>,
      <>
        Never Stop Studying:
        <br />
        An exclusive offer
      </>,
      's_upcoming_lessons.forPromotionModal.main',
      <>
        2021年给予
        <br />
        持续拼搏的您
        <br />
        特别的优惠
      </>,
      <>
        送給2021年
        <br />
        也不停歇的您
        <br />
        優惠
      </>,
      <>
        2021年も
        <br />
        止まらないあなたへ
        <br />
        差し上げる特典
      </>,
      <>
        Không ngừng học hỏi:
        <br /> Ưu đãi đặc biệt
      </>,
    ],
    getDiscount: [
      `할인가로 구매하기`,
      `Buy now`,
      's_upcoming_lessons.forPromotionModal.getDiscount',
      `以优惠价购买`,
      `以優惠價購買`,
      `割引価格で購入する`,
      `Mua ngay`,
    ],
  },
  firstPurchaseModal: {
    main: (name) => [
      <>
        {name}님, 첫 3회 수업권을 <br />
        구매해주셔서 감사합니다.
      </>,
      <>Thank you for buying your first 3 lessons, #{name}.</>,
      's_upcoming_lessons.firstPurchaseModal.main',
      <>
        {name}, 非常感谢您
        <br />
        购买前3次课程券。
      </>,
      <>
        {name}， 感謝您
        <br />
        購買首3次課程券。
      </>,
      <>
        {name}さん、初回3回レッスンチケットを <br />
        ご購入いただきありがとうございます。
      </>,
      <>Cảm ơn #{name} đã thanh toán 3 buổi học đầu tiên.</>,
    ],
    content: (amount) => [
      <>
        재구매 시 사용 가능한
        <br />
        {amount} 포인트가 지급되었습니다.
      </>,
      <>
        you have been awarded
        <br />
        {amount} points.
      </>,
      's_upcoming_lessons.firstPurchaseModal.content',
      <>
        已发送给您再购买时
        <br />
        {amount}可使用的积分。
      </>,
      <>
        已為您發送再次購買時
        <br />
        可使用的${amount}P。
      </>,
      <>
        再購入時に使用可能な
        <br />
        {amount} ポイントが付与されました。
      </>,
      <>
        bạn được thưởng
        <br />
        {amount} điểm.
      </>,
    ],
    getDiscount: [
      `내 포인트 확인하기`,
      `View My Points`,
      's_upcoming_lessons.firstPurchaseModal.getDiscount',
      `确认我的积分`,
      `確認我的點數`,
      `マイポイントを確認する`,
      `Xem Điểm của tôi`,
    ],
  },
  givingBackVipModal: {
    dday: [
      <>
        <Span size={14} white>
          프로모션이 <Span warning>오늘</Span> 마감됩니다!
        </Span>
      </>,
      <>
        <Span size={14} white>
          Giving Back Promotion ends <Span warning>today</Span>.
        </Span>
      </>,
      's_upcoming_lessons.givingBackVipModal.dday',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    main: [
      <>
        링글이 100억 투자를 유치하며
        <br />A 라운드를 시작했습니다!
      </>,
      <>
        Ringle has secured
        <br />
        10 billion won in Series A!
      </>,
      's_upcoming_lessons.givingBackVipModal.main',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    content: (name) => [
      <>
        감사의 마음을 담아, {name}님께
        <br />
        10만 포인트를 지급해드렸습니다.
        <br />
        포인트는 재구매 시 사용 가능합니다.
      </>,
      <>
        As a token of our appreciation,
        <br />
        you have been awarded 86.20 Points.
      </>,
      's_upcoming_lessons.givingBackVipModal.content',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    getDiscount: [`지금 구매하기`, `Buy now`, 's_upcoming_lessons.givingBackVipModal.getDiscount', ``, ``, ``, ``],
    footerLeft: [
      `다시 보지 않기`,
      `Don't show again`,
      's_upcoming_lessons.givingBackVipModal.footerLeft',
      ``,
      ``,
      ``,
      ``,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.givingBackVipModal.footerRight', ``, ``, ``, ``],
  },
  upcoming_lesson_enter_modal_content: (lesson, timezone, remainText) => [
    <>
      <Span>
        수업 시간: {lesson.start_time} ({timezone})
        <br />
        <Span textDanger>{remainText}</Span> 남았습니다.
        <br />
        <br />
        수업 입장은 수업 시작 15분 전부터 가능합니다.
      </Span>
    </>,
    <>
      <Span size={12}>
        Lesson time : {lesson.start_time} ({timezone})
        <br />
        The lesson will begin in {remainText}.
        <br />
        <br />
        You can enter the lesson 15 minutes before the lesson begins.
      </Span>
    </>,
    's_upcoming_lessons.upcoming_lesson_enter_modal_content',
    <>
      <Span>
        课程时间: {lesson.start_time} ({timezone})
        <br />
        剩余<Span textDanger>{remainText}</Span>
        <br />
        <br />
        课程开始前15分钟可入场。
      </Span>
    </>,
    <>
      <Span>
        課程時間：{lesson.start_time}（{timezone}）
        <br />
        剩下<Span textDanger>{remainText}</Span>
        <br />
        <br />
        可於課程開始15分鐘前進入課程。
      </Span>
    </>,
    <>
      <Span>
        レッスン時間: {lesson.start_time} ({timezone})
        <br />
        <Span textDanger>{remainText}</Span> 残っています。
        <br />
        <br />
        レッスン入室はレッスン開始15分前から可能です。
      </Span>
    </>,
    <>
      <Span size={12}>
        Thời gian học : {lesson.start_time} ({timezone})
        <br />
        Buổi học sẽ bắt đầu trong {remainText}.
        <br />
        <br />
        Bạn có thể vào lớp trước 15 phút.
      </Span>
    </>,
  ],
  change_cancel: [
    `변경/ 취소`,
    `Change/Cancel`,
    's_upcoming_lessons.change_cancel',
    `变更/取消`,
    `變更／取消`,
    `予約変更/ キャンセル`,
    `Đổi/Huỷ`,
  ],
  addi_request: [
    `추가 요청 사항`,
    `Specific Requests`,
    's_upcoming_lessons.addi_request',
    `要求事项`,
    `要求事項`,
    `リクエスト事項`,
    `Yêu cầu thêm`,
  ],
  plz_choose_impossible_lesson_option: [
    `선택한 튜터와 매칭되지 않을 경우, 수업 처리 방식을 선택해주세요.`,
    `Please select how you'd like to resolve cancellation by your tutor.`,
    's_upcoming_lessons.plz_choose_impossible_lesson_option',
    `与选择的教师匹配失败时, 请选择处理课程的方式。`,
    `與選擇的任課教師配對失敗時，請選擇課程處理方式。`,
    `選択したチューターとマッチングしない場合、レッスン処理方式を選択してください。`,
    `Vui lòng lựa chọn hướng giải quyết cho buổi học bị huỷ bởi gia sư`,
  ],
  desc_about_tiral_lesson: [
    `*체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업모드를 교정 중심과 토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.`,
    `*All trial lessons are discussion-focused. Paid lessons can either be discussion-focused or correction focused.`,
    's_upcoming_lessons.desc_about_tiral_lesson',
    `*体验课程的形式为完成各问题对话后, 以纠正教学收尾。而正规课程则可选择纠正为主或讨论为主的课程模式以及其他详细方式。`,
    `*進行體驗課程時，在針對各問題結束對話後，以糾正方式進行。進行課程時，可於課程模式選擇以糾正為主或以討論為主後，選擇詳細的上課方式。`,
    `*体験レッスンは各質問の会話が終わった後に校正してもらう方式で行われます。レギュラーレッスンでは、レッスンモードを校正中心とディスカッション中心から選択して細かいレッスン方式を選択することができます。`,
    `*Tất cả buổi học thử sẽ tập trung thảo luận. Buổi học trả phí có thể tùy chọn tập trung thảo luận hoặc tập trung sửa lỗi.`,
  ],
  recording_notice: [
    <>
      수업 시작 시, "Recording In Progress" 안내멘트가 나오고 [● Recording]가 표기됩니다. (수업화면은 녹화되지
      않습니다.)
    </>,
    <>You'll hear "Recording In Progress" and see [● Recording] sign (lesson screen isn't recorded).</>,
    's_upcoming_lessons.recording_notice',
    <>课程开始时, 出现 "Recording In Progress"指示及显示[● Recording]时。 (课程画面将不进行录制)</>,
    <>課程開始時，出現「Recording In Progress」指示聲，並標示[● Recording]。 （不錄製上課畫面。）</>,
    <>
      レッスン開始時「Recording In Progress」という案内メッセージが出て[● Recording]と
      表示されます。(レッスン画面は録画されません)
    </>,
    <>
      Bạn sẽ nghe "Recording In Progress" và thấy biểu tượng [● Recording] (màn hình buổi học sẽ không được ghi lại).
    </>,
  ],
  mp3_warning_danger: [
    <>
      수업 녹음 설정은 <Span danger> 수업 시작 15분 전까지만 변경 가능</Span>합니다.
    </>,
    <>
      You can change recording preferences<Span danger> up to 15 minutes before the lesson</Span>.
    </>,
    's_upcoming_lessons.mp3_warning_danger',
    <>
      *课程录音设定, 最晚可于<Span danger>课程开始前15分钟进行</Span>变更。
    </>,
    <>
      *課程錄音設定<Span danger>最晚可於課程開始15分鐘前變更</Span>。
    </>,
    <>
      *レッスン録音設定は <Span danger> レッスン開始15分前まで変更可能</Span>です。
    </>,
    <>
      *Bạn có thể đổi tuỳ chọn ghi âm<Span danger> tối thiểu 15 phút trước buổi học</Span>.
    </>,
  ],
  in_upcoming_page_what_you_can_do: [
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        예습 & 수업 페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 수업 전 준비, 예정된 수업의 변경 및 취소,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        그리고 수업 방식을 설정할 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can set lesson styles, cancel, or reschedule under
      </Div>
      <Div spanTag fontBold textGray700 fontSize18 ml4>
        Lessons
      </Div>
    </>,
    's_upcoming_lessons.in_upcoming_page_what_you_can_do',
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        于预习 & 课程页面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        可进行课程前准备, 对预约课程进行变更或取消
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        以及对课程形式进行设定。
      </Div>
    </>,
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        可於預習 & 課程頁面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        進行課程開始前的準備、變更或取消預定的課程，
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        以及設定上課方式。
      </Div>
    </>,
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        予習 & レッスンページ
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        ではレッスン前の準備、レッスン予定の変更およびキャンセル、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        そしてレッスン方式を設定することができます。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        Bạn có thể chọn kiểu học, huỷ hoặc dời lịch dưới phần
      </Div>
      <Div spanTag fontBold textGray700 fontSize18>
        Buổi học
      </Div>
    </>,
  ],
  ringle_usage: [
    `링글 사용법`,
    `How Ringle works`,
    's_upcoming_lessons.ringle_usage',
    `Ringle使用方法`,
    `Ringle使用方法`,
    `Ringleの使用方法`,
    `Cách Ringle hoạt động`,
  ],
  zoom_usage: [
    `화상 프로그램 (Zoom) 설치 가이드`,
    `How to Install Zoom`,
    's_upcoming_lessons.zoom_usage',
    `视频程序(Zoom)安装指南`,
    `視訊軟體（Zoom）安裝指南`,
    `ビデオ会議アプリ (Zoom) インストールガイド`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  wrong_expression: [
    `틀린 영어 표현을 할 때마다 교정 받음`,
    `Correct and paraphrase my errors as soon as I make them`,
    's_upcoming_lessons.wrong_expression',
    `每当出现英语错误表达时立即进行纠正`,
    `每當出現錯誤的英語用法時接受糾正`,
    `間違った表現をしたらその場で校正してもらう`,
    `Sửa ngay khi tôi mắc lỗi`,
  ],
  after_conversation: [
    `각 질문에 대한 대화가 끝난 후 교정 받음`,
    `Correct and paraphrase my errors after each question`,
    's_upcoming_lessons.after_conversation',
    `针对各问题的讨论结束后接受纠正`,
    `針對各問題的討論結束後接受糾正`,
    `各質問についての会話が終わった後に校正してもらう`,
    `Sửa cho tôi sau khi thảo luận câu hỏi`,
  ],
  half_talk: [
    `튜터와 내가 절반씩 이야기를 주고 받음`,
    `I want my tutor to speak as much as I do`,
    's_upcoming_lessons.half_talk',
    `教师和本人听说各占一半`,
    `任課教師與我各發言一半`,
    `チューターと自分が半分ずつ話す`,
    `Tôi muốn gia sư nói nhiều như tôi`,
  ],
  i_mainly_talk: [
    `튜터는 듣고 내가 주로 이야기함`,
    `I want to speak more than my tutor`,
    's_upcoming_lessons.i_mainly_talk',
    `教师以听为主, 侧重于学员会话`,
    `任課教師聆聽，主要由學生發言`,
    `チューターは聴く側で自分が主に話す`,
    `Tôi muốn nói nhiều hơn gia sư`,
  ],
  regular_calibration: [
    `상시교정`,
    `Instant Paraphrasing`,
    's_upcoming_lessons.regular_calibration',
    `随时纠正`,
    `隨時糾正`,
    `常時修正モード`,
    `Sửa lỗi ngay`,
  ],
  conversation_mode: [
    `대화 교정모드`,
    `Intermittent Paraphrasing`,
    's_upcoming_lessons.conversation_mode',
    `对话纠正模式`,
    `對話糾正模式`,
    `会話優先モード`,
    `Sửa lỗi ngắt quãng`,
  ],
  discussion_55: [
    `5:5 토론`,
    `5:5 Discussion`,
    's_upcoming_lessons.discussion_55',
    `5:5讨论`,
    `5:5討論`,
    `5:5ディスカッション`,
    `Thảo luận 5:5`,
  ],
  discussion_82: [
    `8:2 토론`,
    `8:2 Discussion`,
    's_upcoming_lessons.discussion_82',
    `8:2讨论`,
    `8:2討論`,
    `8:2ディスカッション`,
    `Thảo luận 8:2`,
  ],
  tutor_canceled: [
    `튜터 취소`,
    `Tutor cancelled`,
    's_upcoming_lessons.tutor_canceled',
    `教师取消`,
    `任課教師取消`,
    `チューターキャンセル`,
    `Gia sư huỷ`,
  ],
  tutor_another_time: {
    select: [
      <>튜터의 다른 시간을 선택해 주세요.</>,
      <>Please select a different time for tutor's schedule.</>,
      's_upcoming_lessons.tutor_another_time.select',
      <>请选择教师的其他时间。</>,
      <>請選擇任課教師的其他時間。</>,
      <>チューターの他の時間を選択してください。</>,
      <>Vui lòng chọn thời gian khác trong thời khoá biểu của gia sư.</>,
    ],
    restored: [
      <>수업 취소 시 자동으로 수업권은 복구됩니다.</>,
      <>Cancel the lesson to restore the lesson credit.</>,
      's_upcoming_lessons.tutor_another_time.restored',
      <>课程取消后, 课程券将自动恢复。</>,
      <>課程取消時，自動復原課程券。</>,
      <>レッスンキャンセル時、自動的にレッスンチケットは復元されます。</>,
      <>Huỷ buổi học và khôi phục lại thẻ học.</>,
    ],
  },
  lesson_style: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parent,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_style.auto_delete_in_24',
      <Div>
        为保护教师及学员, 所有
        <Div spanTag textBlack fontMedium>
          课程均会录像, 且课程视频内容将保存24小时。
        </Div>
        24小时过后将自动删除。
      </Div>,
      <Div>
        為保護學生與任課教師，所有
        <Div spanTag textBlack fontMedium>
          課程影片將會錄影，並儲存一個工作天共24小時
        </Div>
        。24小時後將自動刪除。
      </Div>,
      <Div>
        受講生とチューターを保護するため、すべての{' '}
        <Div spanTag textBlack fontMedium>
          レッスン動画は録画され、営業日基準で24時間保存
        </Div>
        されます。24時間が過ぎると自動的に削除されます。
      </Div>,
      <Div>
        Để bảo vệ học sinh & phụ huynh,{' '}
        <Div spanTag textBlack fontMedium>
          tất cả buổi học sẽ được ghi hình.
        </Div>{' '}
        Tất cả video buổi học sẽ được xoá sau 24 giờ nếu không có bất kì báo cáo nào liên quan đến buổi học.
      </Div>,
    ],
    not_be_deleted_for_lesson: [
      `단, 수업 분석 확인과 수업 다시 듣기를 위해 수업 녹음 파일은 삭제되지 않습니다. (파일 제작은 최대 24시간이 
소요됩니다.)`,
      `Audio recordings won't be deleted for lesson statistics and replay, which may take up to 24 hours after each lesson to process.`,
      's_upcoming_lessons.lesson_style.not_be_deleted_for_lesson',
      `但, 为了能确认课程分析内容及重听课程, 课程录音文件将不会被删除。(文件制作时间最多需要24小时。)`,
      `但為了確認課程分析與重新聆聽課程，將不會刪除課程錄音檔。（製作檔案最長需要24小時。）`,
      `ただし、レッスン分析確認とレッスンをもう一度聴くことができるように、レッスン録音ファイルは削除されません。(ファイル制作は最長24時間
所要します)`,
      `Ghi âm sẽ không bị xoá để sử dụng cho thống kê sau buổi học và xem lại, điều này sẽ cần tới 24 giờ để xử lý.`,
    ],
    class_style: [
      `수업 스타일`,
      `Lesson Style`,
      's_upcoming_lessons.lesson_style.class_style',
      `课程类型`,
      `課程風格`,
      `レッスンスタイル`,
      `Hình thức học`,
    ],
    discussion_type: [
      `토론형`,
      `Debate Mode`,
      's_upcoming_lessons.lesson_style.discussion_type',
      `讨论型`,
      `討論型`,
      `ディスカッション型`,
      `Tranh luận`,
    ],
    able_to_communicate: [
      `영어로 토론을 잘하는 것이 목표입니다. 아이의 답변에 챌린지 해주시고, 튜터분의 의견도 적극 표현해 주세요.`,
      `Please ask thought-provoking follow-up questions for in-depth discussions.`,
      's_upcoming_lessons.lesson_style.able_to_communicate',
      `将能够用英语进行良好讨论作为目标。请鼓励孩子们积极进行回答, 同时请教师提出意见。`,
      `目標是變得擅長英語討論。請多鼓勵孩子回覆，並請任課教師積極表達意見。`,
      `英語でディスカッションを上手にするのが目標です。子供も回答できるように誘導し、チューターの意見も積極的に表現してください。`,
      `Vui lòng đặt ra các câu hỏi đáng suy ngẫm cho các buổi thảo luận sâu.`,
    ],
    speech_guided: [
      `발언 유도형`,
      `Student-Centered Mode`,
      's_upcoming_lessons.lesson_style.speech_guided',
      `发言诱导型`,
      `發言誘導型`,
      `発言誘導型`,
      `Nói`,
    ],
    increase_confidence: [
      `영어에 대한 자신감을 높여주고 싶습니다. 적극적 경청 및 질문을 통해, 아이가 말할 수 있는 기회를 많이 주세요.`,
      `Please ask as many follow-up questions as you can to maximize the student's speaking opportunities.`,
      's_upcoming_lessons.lesson_style.increase_confidence',
      `我想提升孩子对英语方面的信心。通过积极的倾听及提问, 给孩子提供更多的开口机会。`,
      `我想提升孩子對英語的信心。請積極傾聽並提出問題，多提供孩子們開口的機會。`,
      `英語に対する自信を高めたいです。積極的な傾聴および質問で、子供が話せる機会をたくさんあげてください。`,
      `Vui lòng đặt ra càng nhiều câu hỏi bổ sung càng tốt để học viên có thể nói nhiều hơn.`,
    ],
    corrective_type: [
      `교정형`,
      `Correction Mode`,
      's_upcoming_lessons.lesson_style.corrective_type',
      `纠正型`,
      `糾正型`,
      `校正型`,
      `Sửa lỗi`,
    ],
    develop_expressive_vocabulary_skills: [
      `표현력/어휘력을 키워주는 것이 목표입니다. 아이의 틀린/어색한 영어 표현을 적극 교정해 주세요.`,
      `Please correct the student's incorrect or awkward grammar and vocabulary.`,
      's_upcoming_lessons.lesson_style.develop_expressive_vocabulary_skills',
      `将提升表达力/词汇力作为目标。请积极纠正孩子在表达中出现的错误/生涩之处。`,
      `目標是提升表達能力／詞彙能力。請積極糾正孩子錯誤／不自然的英語用法。`,
      `表現力/語彙力を高めることが目標です。子供の間違った/不自然な英語表現を積極的に校正してください。`,
      `Vui lòng chỉnh lỗi khi học viên mắc lỗi hoặc những từ vựng và cấu trúc ngữ pháp chưa tự nhiên.`,
    ],
    private_to_your_children: [
      `자녀에게는 비공개됩니다.`,
      `Not shown to student.`,
      's_upcoming_lessons.lesson_style.private_to_your_children',
      `不对子女进行公开。`,
      `不對子女公開。`,
      `子供には非公開となります。`,
      `Không hiển thị cho học viên.`,
    ],
    teaching_style: [
      `티칭 스타일 (3개까지 선택 가능)`,
      `Teaching Style (Select up to 3)`,
      's_upcoming_lessons.lesson_style.teaching_style',
      `授课类型(可选择3种)`,
      `授課風格（最多可選擇3個）`,
      `ティーチングスタイル (3つまで選択可能)`,
      `Hình thức dạy (Tối thiểu 3 lựa chọn)`,
    ],
  },
  lesson_confirm: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parents,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_confirm.auto_delete_in_24',
      <Div>
        为保护教师及学员, 所有
        <Div spanTag textBlack fontMedium>
          课程视频内容将保存24小时。
        </Div>
        24小时后将自动删除。
      </Div>,
      <Div>
        為保護學生與任課教師，所有
        <Div spanTag textBlack fontMedium>
          課程影片將會錄影，並儲存一個工作天共24小時
        </Div>
        。24小時後將自動刪除。
      </Div>,
      <Div>
        受講生とチューターの保護のため、{' '}
        <Div spanTag textBlack fontMedium>
          レッスン動画は録画され、営業日基準で24時間保存
        </Div>
        されます。24時間が過ぎると自動的に削除されます。
      </Div>,
      <Div>
        Để bảo vệ học sinh & phụ huynh,{' '}
        <Div spanTag textBlack fontMedium>
          tất cả buổi học sẽ được ghi hình.
        </Div>{' '}
        Tất cả video sẽ xoá trong vòng 24 giờ nếu không có bất kỳ báo cáo nào liên quan đến buổi học.
      </Div>,
    ],
    teaching_style: [
      `티칭 스타일`,
      `Teaching Style`,
      's_upcoming_lessons.lesson_confirm.teaching_style',
      `授课类型`,
      `授課風格`,
      `ティーチングスタイル`,
      `Hình thức dạy`,
    ],
  },
  lesson_cancellation: [
    `수업 취소 결과`,
    `Lesson Cancellation Results`,
    's_upcoming_lessons.lesson_cancellation',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_confirmed: [
    `튜터의 배정이 확정되었으므로, 시간 변경이 불가능합니다. 
수업 취소 후 다시 신청해주세요.`,
    `As tutor confirmed the lesson, schedule cannot be changed.
Please book again after canceling the lesson.`,
    's_upcoming_lessons.tutor_confirmed',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_beta: [`CAF Beta`, `CAF Beta`, 's_upcoming_lessons.caf_beta', `CAF Beta`, `CAF Beta`, `CAF Beta`, `CAF Beta`],
  caf_beta_info: [
    `CAF 진단을 받아보기 위해서는 '수업 녹음하기' > ‘진단 받기'를 선택해 주세요.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    's_upcoming_lessons.caf_beta_info',
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
  ],
  caf_beta_record: [
    `CAF 진단을 받아보기 위해서는 '수업 녹음하기' > ‘진단 받기'를 선택해 주세요.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    's_upcoming_lessons.caf_beta_record',
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
    `To perform CAF analysis, please select "Analyze" under "Record Lesson.`,
  ],
  caf_beta_writing: [
    `‘라이팅 중심’ 수업은 CAF 진단이 제공되지 않습니다.`,
    `CAF analysis is unavailable for 'writing-focused' lessons.`,
    's_upcoming_lessons.caf_beta_writing',
    `CAF analysis is unavailable for 'writing-focused' lessons.`,
    `CAF analysis is unavailable for 'writing-focused' lessons.`,
    `CAF analysis is unavailable for 'writing-focused' lessons.`,
    `CAF analysis is unavailable for 'writing-focused' lessons.`,
  ],
  caf_menu: {
    title: [
      `CAF 진단`,
      `CAF Analysis`,
      's_upcoming_lessons.caf_menu.title',
      `CAF Analysis`,
      `CAF Analysis`,
      `CAF Analysis`,
      `CAF Analysis`,
    ],
    optn1: [`진단 받기`, `Analyze`, 's_upcoming_lessons.caf_menu.optn1', `Analyze`, `Analyze`, `Analyze`, `Analyze`],
    optn2: [
      `진단 받지 않기`,
      `Don't analyze`,
      's_upcoming_lessons.caf_menu.optn2',
      `Don't analyze`,
      `Don't analyze`,
      `Don't analyze`,
      `Don't analyze`,
    ],
  },
  prepare_btn: [
    `수업 준비하기`,
    `Prepare Lesson`,
    's_upcoming_lessons.prepare_btn',
    `准备上课`,
    `準備上課`,
    `レッスンの準備`,
    ``,
  ],
  upload_writing_content_btn: [
    `관련 자료 업로드 하기`,
    `Upload Material`,
    's_upcoming_lessons.upload_writing_content_btn',
    `上传文件`,
    `上傳文件`,
    `資料をアップロードする`,
    ``,
  ],
  new_change_cancel: [
    `수정/삭제`,
    `Change/Cancel`,
    's_upcoming_lessons.new_change_cancel',
    `变更/删除`,
    `變更/刪除`,
    `変更・削除`,
    ``,
  ],
}
export const s_tutors = {
  schedule: [`예약하기`, `Book lessons`, 's_tutors.schedule', `预约`, `預約`, `予約する`, `Đặt lịch học`],
  find_a_tutor: [
    `튜터 찾기`,
    `Find a tutor`,
    's_tutors.find_a_tutor',
    `寻找教师`,
    `尋找任課教師`,
    `チューター検索`,
    `Tìm gia sư`,
  ],
  placeholder: [
    `원하는 튜터의 키워드를 입력해주세요. ex) Finance`,
    `Find tutors with a keyword (E.g. Finance)`,
    's_tutors.placeholder',
    `请输入您希望查找的教师关键词。ex) Finance`,
    `請輸入希望的任課教師的關鍵字。ex) Finance`,
    `希望するチューターのキーワードを入力してください。 ex) Finance`,
    `Tìm gia sư với từ khoá (Ví dụ: Finance)`,
  ],
  see_more: [`더보기`, `See more`, 's_tutors.see_more', `查看更多`, `查看更多`, `もっと見る`, `Xem thêm`],
  any: [`상관없음`, `Any`, 's_tutors.any', `不介意`, `皆可`, `こだわらない`, `Bất kỳ`],
  gender: {
    label: [`성별`, `Gender`, 's_tutors.gender.label', `性别`, `性別`, `性別`, `Giới tính`],
    female: [`여자`, `Female`, 's_tutors.gender.female', `女性`, `女性`, `女性`, `Nữ`],
    male: [`남자`, `Male`, 's_tutors.gender.male', `男性`, `男性`, `男性`, `Nam`],
  },
  accent: {
    label: [`액센트`, `Accent`, 's_tutors.accent.label', `口音`, `口音`, `アクセント`, `Giọng`],
    accent_american: [`미국식`, `American`, 's_tutors.accent.accent_american', `美式`, `美式`, `アメリカ`, `Giọng Mỹ`],
    accent_british: [`영국식`, `British`, 's_tutors.accent.accent_british', `英式`, `英式`, `イギリス`, `Giọng Anh`],
  },
  major: {
    label: [`전공`, `Major`, 's_tutors.major.label', `专业`, `主修`, `専攻`, `Chuyên môn`],
    social_sciences: [
      `사회과학경영`,
      `Social Sciences/Business`,
      's_tutors.major.social_sciences',
      `社会科学/经营`,
      `社會科學／經營`,
      `社会科学・経営`,
      `Khoa học xã hội/Kinh doanh`,
    ],
    liberal_arts: [
      `인문계`,
      `Liberal Arts`,
      's_tutors.major.liberal_arts',
      `人文系`,
      `人文`,
      `人文系`,
      `Giáo dục khai phóng`,
    ],
    engineering: [`공과계열`, `Engineering`, 's_tutors.major.engineering', `工学系`, `理工`, `工科系`, `Kỹ sư`],
    natural_science: [
      `자연과학계열`,
      `Natural Science`,
      's_tutors.major.natural_science',
      `自然科学系`,
      `自然科學`,
      `自然科学系`,
      `Khoa học tự nhiên`,
    ],
    fine_arts_and_athletics: [
      `예체능`,
      `Fine Arts and Athletics`,
      's_tutors.major.fine_arts_and_athletics',
      `艺体专业`,
      `藝術／體育`,
      `芸能・芸術・体育`,
      `Mỹ thuật và Thể dục`,
    ],
  },
  search: [`검색`, `Search`, 's_tutors.search', `搜索`, `搜尋`, `検索`, `Tìm kiếm`],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_tutors.recommended_tutor',
    `推荐的教师`,
    `推薦的教師`,
    `おすすめチューター`,
    `Nổi bật`,
  ],
  all_tutor: [`전체 튜터`, `All`, 's_tutors.all_tutor', `全部教师`, `所有任課教師`, `すべてのチューター`, `Tất cả`],
  saved_tutor: [
    `찜한 튜터`,
    `Favorite Tutors`,
    's_tutors.saved_tutor',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入りチューター`,
    `Yêu thích`,
  ],
  search_result: [
    `튜터 검색 결과`,
    `Search Results`,
    's_tutors.search_result',
    `教师搜索结果`,
    `任課教師搜尋結果`,
    `チューター検索結果`,
    `Kết quả tìm kiếm`,
  ],
  no_result: [
    `😣  검색 결과가 없습니다`,
    `😣  No Result`,
    's_tutors.no_result',
    `😣 无搜索结果`,
    `😣 無搜尋結果`,
    `😣  検索結果はありません。`,
    `😣  Không có kết quả`,
  ],
  accept_rate: [`수락률`, `Acceptance rate`, 's_tutors.accept_rate', `接受率`, `接受率`, `承諾率`, `Tỷ lệ nhận`],
  accept_rate_meaning: [
    <>
      튜터에게 수업 요청 시 튜터가 수업을 수락할 확률 입니다. <br />
      요청하실 튜터의 수락률이 높지 않다면, 신청하는 것을 고려해보세요!
    </>,
    <>
      Indicates probability that a tutor will accept your lesson booking. <br />
      If a tutor has a low acceptance rate, try finding a different tutor!
    </>,
    's_tutors.accept_rate_meaning',
    <>
      向教师提出课程申请时, 教师接受课程的概率。 <br />
      如果您申请的教师接受率不高, 请慎重考虑!
    </>,
    <>
      為向任課教師申請課程時，任課教師接受申請的機率。
      <br />
      若您申請的任課教師接受率不高，請考慮是否申請！
    </>,
    <>
      チューターにレッスンをリクエストした際に、チューターがレッスンを承諾する確率です。 <br />
      リクエストするチューターの承諾率が高くなければ、別のチューターを予約することを検討してみてください。
    </>,
    <>
      Xác suất cho thấy khả năng gia sư sẽ nhận đặt lịch học của bạn. <br />
      Nếu một gia sư có tỷ lệ nhận thấp, thử tìm gia sư khác!
    </>,
  ],
  mark_tutor: [`찜하기`, `Favorite`, 's_tutors.mark_tutor', `收藏`, `收藏`, `お気に入り`, `Yêu thích`],
  tutor_profile: [
    `튜터 프로필`,
    `Tutor Profile`,
    's_tutors.tutor_profile',
    `教师简介`,
    `任課教師簡介`,
    `チュータープロフィール`,
    `Hồ sơ gia sư`,
  ],
  student_profile: [
    `Student Info`,
    `Student Profile`,
    's_tutors.student_profile',
    `Student Info`,
    `Student Info`,
    `Student Info`,
    `Hồ sơ học viên`,
  ],
  video_preparing: [
    <>
      튜터가 영상을 준비중입니다.
      <br />
      조금만 기다려주세요!
    </>,
    <>Introduction video will be provided soon!</>,
    's_tutors.video_preparing',
    <>
      教师正在准备视频。
      <br />
      请耐心等待!
    </>,
    <>
      任課教師正在準備影片，
      <br />
      請稍候！
    </>,
    <>
      チューターが動画を準備中です。
      <br />
      もう少しお待ちください！
    </>,
    <>Video giới thiệu sẽ được cung cấp sớm!</>,
  ],
  schedule_available: [
    `수업 예약 가능`,
    `Available`,
    's_tutors.schedule_available',
    `可预约课程`,
    `可預約課程`,
    `レッスン予約可能`,
    `Trống lịch`,
  ],
  schedule_fully_booked: [
    `수업 예약 마감`,
    `Fully booked`,
    's_tutors.schedule_fully_booked',
    `课程预约结束`,
    `課程預約截止`,
    `レッスン予約締切`,
    `Đã đặt hết`,
  ],
  not_schedule_yet: [
    `스케쥴 오픈 전`,
    `Not available`,
    's_tutors.not_schedule_yet',
    `日程未公开`,
    `尚未公開日程`,
    `スケジュールオープン前`,
    `Không trống lịch`,
  ],
  my_tutor: [
    `내 튜터`,
    `My Tutors`,
    's_tutors.my_tutor',
    `我的教师`,
    `我的任課教師`,
    `マイチューター`,
    `Gia sư của tôi`,
  ],
  intro: [`자기소개`, `Intro`, 's_tutors.intro', `自我介绍`, `自我介紹`, `自己紹介`, `Giới thiệu`],
  review: [`수업 평가`, `Review`, 's_tutors.review', `课程评价`, `課程評論`, `レッスン評価`, `Nhận xét`],
  annoucement: [
    `튜터 공지사항`,
    `Tutor’s Announcement`,
    's_tutors.annoucement',
    `教师通知事项`,
    `任課教師公告事項`,
    `チューターからのお知らせ`,
    `Thông báo từ gia sư`,
  ],
  experience: [`핵심 경험`, `Experience`, 's_tutors.experience', `核心经验`, `核心經驗`, `主な経歴`, `Kinh nghiệm`],
  interests: [`관심사`, `Interests`, 's_tutors.interests', `关注之事`, `感興趣的領域`, `興味/関心`, `Sở thích`],
  no_junior_desc: [
    <>
      성인 수강생 대상 수업만 진행하는 튜터입니다.
      <br />
      초/중/고 수강생 예약 신청 시, 매칭되지 않을 가능성이 높습니다.
    </>,
    <>
      This tutor prefers lessons with working professionals.
      <br />
      Hence, the lesson may not be matched with juniors.
    </>,
    's_tutors.no_junior_desc',
    <>
      只接受成人学员的教师。
      <br />
      小/初/高中学员预约课程时, 不成功的可能性很高。
    </>,
    <>
      僅為成人學生授課的任課教師。
      <br />
      國小／國中／高中學生申請預約時，很有可能配對失敗。
    </>,
    <>
      成人受講生対象レッスンのみ行うチューターです。
      <br />
      小/中/高生の受講生が予約すると、マッチングしない可能性が高いです。{' '}
    </>,
    <>
      Gia sư này phù hợp hơn với những người đi làm.
      <br />
      Nên buổi học có thể sẽ không hợp với các bạn học sinh.
    </>,
  ],
  save_tutor_like: [
    `같이 수업하고 싶은\n튜터를 찜해보세요.`,
    <>Favorite a tutor.</>,
    's_tutors.save_tutor_like',
    `请收藏希望一起\n学习的教师吧。`,
    `請收藏想一起上課的\n任課教師。`,
    `レッスンを受けたい\nチューターをお気に入り登録しましょう。`,
    <>Chọn làm gia sư yêu thích.</>,
  ],
  check_exp_tutor: [
    `나와 수업한 튜터를\n여기에서 확인할 수 있습니다.`,
    <>See your previous tutors.</>,
    's_tutors.check_exp_tutor',
    `您可以在这里确认\n和我一起学习的教师。`,
    `可以在這裡確認\n和我上過課的任課教師。`,
    `あなたとレッスンをしたチューターを\nここで確認することができます。`,
    <>Xem những gia sư từng học.</>,
  ],
  experienced_tutor: [
    `수업한 튜터`,
    `Prev. Tutors`,
    's_tutors.experienced_tutor',
    `任课教师`,
    `和我上過課的任課教師`,
    `レッスンしたチューター`,
    `Gia sư từng học`,
  ],
  apply: [`적용`, `Apply`, 's_tutors.apply', `套用`, `套用`, `適用`, `Áp dụng`],
  search_tutor: [
    `튜터 검색`,
    `Search`,
    's_tutors.search_tutor',
    `搜索教师`,
    `搜尋任課教師`,
    `チューター検索`,
    `Tìm kiếm`,
  ],
  k12_lesson: [
    `초중고생 수업`,
    `K-12`,
    's_tutors.k12_lesson',
    `小学/初中/高中学生课程`,
    `國小、國中、高中學生課程`,
    `小中高生レッスン`,
    `K-12`,
  ],
  no_review_yet: [
    `No reviews yet.`,
    `No reviews yet.`,
    's_tutors.no_review_yet',
    `No reviews yet.`,
    `No reviews yet.`,
    `No reviews yet.`,
    `Chưa có nhận xét.`,
  ],
  accept_rate_requested: [
    `수업 요청 시 튜터가 수락한 비율입니다.`,
    `Indicates rate that a tutor will accept your lesson booking.`,
    's_tutors.accept_rate_requested',
    ``,
    ``,
    ``,
    ``,
  ],
  no_history: [
    `지난 2주간 수업 이력이 없어 수락률이 표기되지 않습니다.`,
    `The acceptance rate is not indicated since the tutor has not conducted lessons for the past two weeks.`,
    's_tutors.no_history',
    ``,
    ``,
    ``,
    ``,
  ],
  adults_only: [
    `성인만 가능`,
    `Adults only`,
    's_tutors.adults_only',
    `仅限成人`,
    `僅限成人`,
    `成人のみ可能`,
    `Cho người lớn`,
  ],
  k12ok: [
    `초중고생 가능`,
    `K-12 OK`,
    's_tutors.k12ok',
    `小学，中学，高中生可以`,
    `國小、國中、高中學生可以`,
    `小中高生可能`,
    `K-12 OK`,
  ],
  review_complete: [
    `모두 조회했습니다.`,
    `Review complete`,
    's_tutors.review_complete',
    `全部查询完毕。`,
    `已全部查詢。`,
    `すべて照会しました。`,
    `Hoàn tất xem lại`,
  ],
  no_reviews_yet: [
    `아직 등록된 수업 평가가 없습니다.`,
    `No reviews yet`,
    's_tutors.no_reviews_yet',
    `尚无课程评价。`,
    `尚無課程評論。`,
    `まだ登録されたレッスン評価がありません。`,
    `Không có đánh giá`,
  ],
  new_tutor: [
    `새로 온 튜터`,
    `Rookie Tutors`,
    's_tutors.new_tutor',
    `新教师`,
    `新教師`,
    `新しく来たチューター`,
    `Gia sư mới`,
  ],
  new_tutor_detail_info: {
    title: [
      `수업후기 남기고 수업료 50%를 돌려받으세요.`,
      `Write a review and get 50% value back.`,
      's_tutors.new_tutor_detail_info.title',
      `提交课程评价，领取課程費费用50%积分。`,
      `撰寫課程評價，領取50%課程費用回饋點數。`,
      `レビューを書くとレッスン料の50%を！`,
      `Viết đánh giá và nhận lại 50% học phí.`,
    ],
    desc0: [
      `새로 온 튜터와 수업 후 수업후기를 남기면 수업료의 50%를 포인트로 돌려드려요.`,
      `Get 50% of the lesson fee back in points by leaving a review.`,
      's_tutors.new_tutor_detail_info.desc0',
      `提交与新教師上课的课后评价，可获得課程費费用50%积分回馈。`,
      `撰寫與新教師上課的課後評價，可獲得50%課程費用回饋點數。`,
      `新規チューターのレビューを残すと料金の50％をポイントで受け取れます。`,
      `Nhận 50% hoàn học phí bằng Điểm khi viết nhận xét.`,
    ],
    desc1: [
      `유료 수업권으로 예약한 수업 중 새로 온 튜터와의 수업에 한해 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
      `Applicable to paid lessons with Rookie Tutors only / Eligible points will be calculated based on billed payment.`,
      's_tutors.new_tutor_detail_info.desc1',
      `仅限用付费课程券预约新教师的课程，以最终结算金额为基准，1次课程费用的50%将用积分回馈。`,
      `僅限用付費課程券預約新教師的課程，以最終結算金額為基準，1次課程費用的50%將用點數回饋。`,
      `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
      `50% điểm được cộng 1 lần theo hóa đơn thanh toán gần nhất, chỉ áp dụng cho buổi học trả phí với gia sư mới.`,
    ],
    desc2: [
      `새로 온 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
      `Credit restored if unsatisfied with the Rookie Tutor. (Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit)`,
      's_tutors.new_tutor_detail_info.desc2',
      `如果对新教师的授课表现不满意，我们将退还课程券。 （用付费课程券预约后，教师评价不足3分时提出原因，每月限2次）`,
      `如果對新教師的授課表現不滿意，我們將退還課程券。 （用付費課程券預約後，教師評價不足3分時提出原因，每月限2次）`,
      `新規チューターに満足できなかったら、レッスンチケットを復旧いたします。（有料レッスン/評価３点以下/理由提出、月２回限定）`,
      `Phiếu học được hoàn nếu không hài lòng với tiết học cùng gia sư mới (tối đa 2 lần/ tháng cho phiếu học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
    ],
  },
  tutor_profile_title: [
    `새로 온 튜터 50% 포인트백`,
    `Rookie Tutors 50% Value Back`,
    's_tutors.tutor_profile_title',
    `新教师50% 积分回馈`,
    `新教師50% 點數回饋`,
    `新しく来たチューター50% Point Back`,
    `Gia sư mới50% Point Back`,
  ],
  tutor_profile_desc1: (name) => [
    <>{name} 튜터와 수업 후 수업평가를 남기면 수업료의 50%를 포인트로 돌려드려요.</>,
    <>Take a lesson from tutor {name} and leave a review to get 50% value back in points.</>,
    's_tutors.tutor_profile_desc1',
    <>留下{name}教师的课后评价，即可获得课程费用的50％积分回馈。</>,
    <>留下{name}教師的課後評價，即可獲得課程費用的50％點數回饋。</>,
    <>{name}チューターとレッスン後レッスン評価を残したらレッスン料の50％をポイントで差し上げます。</>,
    <>Sau buổi học với gia sư {name}, để lại đánh giá để nhận lại 50% học phí dưới dạng điểm.</>,
  ],
  tutor_profile_desc2: [
    `유료 수업권으로 예약한 수업에 한하여 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
    `Book the lesson using a paid credit to receive 50% of the lesson fee (based on billed payment) back in points.`,
    's_tutors.tutor_profile_desc2',
    `仅限于用付费课程券预约的课程，以最终结算金额为基准，返还1次课费的50%积分。`,
    `僅限於用收費課程券預約的課程，以最終結算金額為基準，返還1次課費的50%點數。`,
    `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
    `50% điểm sẽ được cộng 1 lần dựa trên số tiền thanh toán cuối cùng, chỉ áp dụng cho buổi học được đặt lịch bằng phiếu trả phí.`,
  ],
  new_tutor_desc: [
    `10대 1의 경쟁률을 뚫고 선발된 튜터와 만나보세요.`,
    `Meet our highly-qualified Rookie Tutors.`,
    's_tutors.new_tutor_desc',
    `和新教师上课`,
    `和新教師上課`,
    `10対1の競争率を勝ち抜いた新規チューターはこちら。`,
    `Gặp gỡ nhóm gia sư mới chất lượng cao`,
  ],
  recommended_tutor_desc: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터에요.`,
    `These tutors have received high ratings and positive reviews.`,
    's_tutors.recommended_tutor_desc',
    `最近一个月获得高评分及正面评价的教师`,
    `最近一個月獲得高評分及正面評價的教師`,
    `ここ一ヶ月、高い評価とレビューを維持しているチューターです。`,
    `Gia sư được xếp hạng cao và phản hồi tích cực.`,
  ],
  new_tutor_viewall: [
    `전체보기`,
    `View All`,
    's_tutors.new_tutor_viewall',
    `查看全部`,
    `查看全部`,
    `すべて見る`,
    `Xem tất cả`,
  ],
  search_all: [`전체`, `All`, 's_tutors.search_all', `全部`, `全部`, `すべて`, `Tất cả`],
  profile_overview: [`요약`, `Summary`, 's_tutors.profile_overview', `简介`, `簡介`, `要約`, `Tóm tắt`],
  read_more_about_intro: [
    `자기소개 더 읽기`,
    `Read more`,
    's_tutors.read_more_about_intro',
    `自我介绍`,
    `自我介紹`,
    `もっと見る`,
    `Đọc thêm`,
  ],
  read_more_about_review: [
    `수업 평가 전체 보기`,
    `View all`,
    's_tutors.read_more_about_review',
    `课程评价`,
    `課程評價`,
    `全体を見る`,
    `Xem toàn bộ`,
  ],
  video_updated_at: (year, month, day) => [
    <>
      {year}.{month}.{day} 촬영
    </>,
    <>
      Filmed on {month}.{day}.{year}
    </>,
    's_tutors.video_updated_at',
    <>
      拍摄日期 {month}.{day}.{year}
    </>,
    <>
      拍攝日期 {month}.{day}.{year}
    </>,
    <>
      {year}.{month}.{day} 撮影
    </>,
    <>
      Ghi hình ngày {month}.{day}.{year}
    </>,
  ],
  review_explanation: [
    `이 튜터의 수업을 직접 수강한 고객님들이 선택한 키워드 중 상위 5개에 대한 통계입니다.`,
    `Top 5 keywords students have used to describe the tutor's lesson style`,
    's_tutors.review_explanation',
    `学生给老师的Top 5课程特色形容词`,
    `學生給老師的Top 5課程特色形容詞`,
    `当チューターのレッスンを実際に受講したお客様が選んだキーワードのうち上位5つに対する統計です。`,
    `Top 5 từ khoá học viên dùng để miêu tả phong cách giảng dạy của gia sư`,
  ],
  keyword_title: [`키워드`, `Keyword`, 's_tutors.keyword_title', `Keyword`, `Keyword`, `Keyword`, ``],
}
export const s_portal_home = {
  reserve_lessons: [`수업예약`, `Book`, 's_portal_home.reserve_lessons', `预约课程`, `預約課程`, `予約`, `Đặt lịch`],
  time_left: (days, hours, minutes) => [
    <>
      {days <= 0 ? '' : days + '일 '}
      {hours <= 0 ? '' : hours + '시간 '}
      {minutes <= 0 ? '' : minutes + '분 '}남음
    </>,
    <>
      {days <= 0 ? '' : days + 'd '}
      {hours <= 0 ? '' : hours + 'h '}
      {minutes <= 0 ? '' : minutes + 'm '}left
    </>,
    's_portal_home.time_left',
    <>
      剩余{days <= 0 ? '' : days + '日'}
      {hours <= 0 ? '' : hours + '小时 '}
      {minutes <= 0 ? '' : minutes + '分钟 '}
    </>,
    <>
      剩下{days <= 0 ? '' : days + '日'}
      {hours <= 0 ? '' : hours + '小時'}
      {minutes <= 0 ? '' : minutes + '分鐘'}
    </>,
    <>
      残り{days <= 0 ? '' : days + '日 '}
      {hours <= 0 ? '' : hours + '時間 '}
      {minutes <= 0 ? '' : minutes + '分 '}
    </>,
    <>
      {days <= 0 ? '' : days + 'd '}
      {hours <= 0 ? '' : hours + 'h '}
      {minutes <= 0 ? '' : minutes + 'm '}còn lại
    </>,
  ],
  start_after_min: (time) => [
    `${time}분 후 시작`,
    `Starts in ${time} minutes`,
    's_portal_home.start_after_min',
    `${time}分钟后开始`,
    `${time}分鐘後開始`,
    `${time}分後開始`,
    `Bắt đầu trong ${time} phút`,
  ],
  lesson_start_past_min: (time) => [
    `수업시간 ${time} 분 지남`,
    `Started ${time} min ago`,
    's_portal_home.lesson_start_past_min',
    `课程时间已超过${time}分钟`,
    `課程時間已過${time}分鐘`,
    `レッスン時間 ${time} 分経過`,
    `Bắt đầu ${time} phút trước`,
  ],
  webinar_start_past_min: (time) => [
    `${time} 분 전부터 진행 중`,
    `Started ${time} min ago`,
    's_portal_home.webinar_start_past_min',
    `${time}分钟前开始进行`,
    `自${time}分鐘前開始`,
    `${time} 分前から進行中`,
    `Bắt đầu ${time} phút trước`,
  ],
  hello: (name) => [
    <>안녕하세요 {name}님!</>,
    <>Hello {name}!</>,
    's_portal_home.hello',
    <>您好, {name}用户!</>,
    <>您好，{name}用戶！</>,
    <>こんにちは、 {name}さん！</>,
    <>Xin chào {name}!</>,
  ],
  trial_card_title: [
    `선착순 마감 혜택`,
    `First-Come First-Served`,
    's_portal_home.trial_card_title',
    `限量优惠`,
    `限量優惠`,
    `先着順特典`,
    `Ưu tiên đăng ký sớm`,
  ],
  trial_card: [
    <>
      20분 무료수업
      <br />
      예약하기
    </>,
    <>
      Try 20-min <br />
      lesson now!
    </>,
    's_portal_home.trial_card',
    <>
      预约20分钟
      <br />
      免费课程
    </>,
    <>
      預約20分鐘
      <br />
      免費課程
    </>,
    <>
      20分無料レッスンを
      <br />
      予約する
    </>,
    <>
      Học thử 20 phút <br />
      ngay bây giờ!
    </>,
  ],
  benefit_1: [
    <>
      최대 3회 수업 제공
      <br />+ 10,000원 추가 할인
    </>,
    <>
      Max 3 extra lessons
      <br />+ ₩10,000 Discount{' '}
    </>,
    's_portal_home.benefit_1',
    <>
      最多提供3次课程
      <br />+ ₩10,000 额外优惠
    </>,
    <>
      最多提供3次課程
      <br />+ ₩10,000 額外折扣{' '}
    </>,
    <>
      最大3回レッスン提供
      <br />+ ₩10,000 追加割引
    </>,
    <>
      Tối đa 3 buổi học thêm
      <br />+ ₩10,000 ưu đãi
    </>,
  ],
  benefit_2: [
    <>첫 수업 불만족 시 100% 환불 보장</>,
    <>100% Money-back guarantee</>,
    's_portal_home.benefit_2',
    <>首次课程不满意时可100%退款</>,
    <>首次課程不滿意時，可全額退款</>,
    <>初回レッスンにご満足いただけない場合、全額返金保証</>,
    <>100% hoàn tiền</>,
  ],
  no_coupons: [
    <>
      체험 없이 구매하고
      <br />
      <span className="text-purple-500">최대 혜택</span> 받기
    </>,
    <>
      <span className="text-purple-500">Best offer</span> available without trial lesson
    </>,
    's_portal_home.no_coupons',
    <>
      不体验直接购买时
      <br />
      <span className="text-purple-500">可获得</span> 最大优惠
    </>,
    <>
      不體驗直接購買時
      <br />
      <span className="text-purple-500">領取</span>最高優惠
    </>,
    <>
      体験せず購入して
      <br />
      <span className="text-purple-500">最大特典</span> を受け取る
    </>,
    <>
      <span className="text-purple-500">Ưu đãi tốt nhất</span> bây giờ không bao gờm học thử
    </>,
  ],
  purchase: [`구매하기`, `Buy now`, 's_portal_home.purchase', `购买`, `購買`, `購入する`, `Mua ngay`],
  purchaseCredit: [
    <>
      첫 구매{' '}
      <Div spanTag textSecondary>
        할인 혜택
      </Div>
    </>,
    <>
      Benefits on{' '}
      <Div spanTag textSecondary>
        first purchase
      </Div>
    </>,
    's_portal_home.purchaseCredit',
    `首次购买优惠`,
    `首次購買優惠折扣`,
    `初回購入割引特典`,
    `Ưu đãi cho lần thanh toán đầu tiên`,
  ],
  upcoming: [
    `예정된 수업`,
    `Upcoming Lessons`,
    's_portal_home.upcoming',
    `预约的课程`,
    `預約的課程`,
    `レッスン予定`,
    `Buổi học sắp tới`,
  ],
  reviewed_yet2: [
    `복습 미완료 수업`,
    `Lessons to review`,
    's_portal_home.reviewed_yet2',
    `未复习的课程`,
    `未完成複習的課程`,
    `復習未完了のレッスン`,
    `Xem lại buổi học`,
  ],
  liveWebinar2: [
    `웨비나 라이브`,
    `Live Webinar`,
    's_portal_home.liveWebinar2',
    `Live网络研讨会`,
    `即時網路研討會`,
    `Live Webinar`,
    `Webinar trực tuyến`,
  ],
  tutor: [`튜터`, `Tutors`, 's_portal_home.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  tutor_like_added: [
    `찜한 튜터에 추가했어요.`,
    `Added to Favorite Tutors.`,
    's_portal_home.tutor_like_added',
    `添加至[教师 > 我的教师] 收藏的教师。`,
    `新增[任課教師 > 我的任課教師]我收藏的任課教師。`,
    `[チューター > マイチューター] お気に入りチューターに追加されました。`,
    `Thêm [Tutors > My Tutors] vào Yêu thích.`,
  ],
  tutor_like_deleted: [
    `찜한 튜터에서 삭제했어요.`,
    `Removed from Favorite Tutors.`,
    's_portal_home.tutor_like_deleted',
    `移出[教师 > 我的教师] 收藏的教师`,
    `從[任課教師 > 我的任課教師]我收藏的任課教師刪除。`,
    `[チューター > マイチューター] お気に入りチューターから削除されました。`,
    `Bỏ [Tutors > My Tutors] .`,
  ],
  confirm_delete: [
    `콘텐츠 > 내 콘텐츠에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from Content > My Content?`,
    's_portal_home.confirm_delete',
    `确定要从内容 > 我的收藏中删除吗?`,
    `確定要從內容 > 我的收藏刪除嗎？`,
    `コンテンツ > マイコンテンツから削除しますか？`,
    `Bạn có chắc muốn bỏ khỏi Nội dung >  Nội dung của tôi?`,
  ],
  cancel: [`취소`, `Cancel`, 's_portal_home.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  remove: [`삭제`, `Remove`, 's_portal_home.remove', `删除`, `刪除`, `削除`, `Bỏ`],
  every_minute_dose_of_insight_english_everyday: [
    `하루 5분으로 쌓는 상식과 영어`,
    `5-minute dose of insight & English`,
    's_portal_home.every_minute_dose_of_insight_english_everyday',
    `每天5分钟积累的常识和英语`,
    `一天5分鐘累積常識與英語`,
    `1日5分で積み重ねる知見と英語`,
    `5 phút đọc thêm insight & tiếng Anh`,
  ],
  this_week_s_topic: [
    `이번 주 주제`,
    `Weekly Topic`,
    's_portal_home.this_week_s_topic',
    `本周主题`,
    `本週主題`,
    `今週のテーマ`,
    `Chủ đề hằng tuần`,
  ],
  today_s_tutor: [
    `오늘의 튜터`,
    `Tutor Highlights`,
    's_portal_home.today_s_tutor',
    `今日教师`,
    `今日任課教師`,
    `今日のチューター`,
    `Gia sư nổi bật`,
  ],
  check_out_tutors_recommended_by_ringle_users_: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터에요.`,
    `These tutors have received high ratings and positive reviews.`,
    's_portal_home.check_out_tutors_recommended_by_ringle_users_',
    `最近一个月获得高评分及正面评价的教师`,
    `最近一個月獲得高評分及正面評價的教師`,
    `ここ一ヶ月、高い評価とレビューを維持しているチューターです。`,
    `Gia sư được xếp hạng cao và phản hồi tích cực.`,
  ],
  view_all: [`전체 보기`, `See All`, 's_portal_home.view_all', `查看全部`, `查看全部`, `全体を見る`, `Xem toàn bộ`],
  latest_packets: [
    `새로나온 교재`,
    `Latest Materials`,
    's_portal_home.latest_packets',
    `最新教材`,
    `最新教材`,
    `最新の教材`,
    `Tài liệu mới nhất`,
  ],
  read_new_articles_released_every_week_: [
    `매일 업데이트되는 교재를 읽고, 수업을 바로 예약해 보세요!`,
    `Read new materials released every week.`,
    's_portal_home.read_new_articles_released_every_week_',
    `请确认每天更新的新教材。`,
    `請確認每天更新的新教材。`,
    `毎日アップデートされる新しい教材をチェックしてみましょう。`,
    `Đọc tài liệu mới ra mỗi ngày.`,
  ],
  featured_webinars: [
    `신청 가능한 웨비나`,
    `Webinars now open`,
    's_portal_home.featured_webinars',
    `可以申请的网路研讨会`,
    `可以申請的網路研討會`,
    `申込み可能なウェビナー`,
    `Webinar có thể đăng ký`,
  ],
  watch_video_lectures_taught_by_handpicked_tutors_: [
    `링글에서 진행하는 다양한 웨비나를 신청해보세요.`,
    `Check out the various webinars offered by Ringle.`,
    's_portal_home.watch_video_lectures_taught_by_handpicked_tutors_',
    `在Ringle参与各种主题的网路研讨会`,
    `在Ringle參與各種主題的網路研討會`,
    `"Ringleの様々なウェビナーをチェック。`,
    `Vui lòng kiểm tra các Webinar đa dạng được tiến hành trong Ringle.`,
  ],
  confirm_delete_tutor: [
    `찜한 튜터에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from Favorite Tutors?`,
    's_portal_home.confirm_delete_tutor',
    `确定要从[教师 > 我的教师] 收藏的教师中删除吗?`,
    `確定要從[任課教師 > 我的任課教師]我收藏的任課教師刪除嗎？`,
    `[チューター > マイチューター] お気に入りチューターから削除しますか？`,
    `Bạn có chắc muốn bỏ khỏi [Gia sư > Gia sư của tôi] danh sách yêu thích?`,
  ],
  unlike: [
    `찜하기 해제`,
    `Remove from Favorites`,
    's_portal_home.unlike',
    `移出收藏`,
    `取消收藏`,
    `お気に入り削除`,
    `Bỏ khỏi Mục yêu thích`,
  ],
  material_like_added: [
    `찜한 교재에 추가했어요.`,
    `Added to Favorite Materials.`,
    's_portal_home.material_like_added',
    `添加至[内容>我的收藏]收藏的教材。`,
    `已新增於[內容 > 我的收藏]我收藏的教材。`,
    `[コンテンツ > マイコンテンツ] お気に入り教材に追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Tài liệu Yêu thích.`,
  ],
  material_like_deleted: [
    `찜한 교재에서 삭제했어요.`,
    `Removed from Favorite Materials.`,
    's_portal_home.material_like_deleted',
    `移出[内容>我的收藏]收藏的教材。`,
    `已從[內容 > 我的收藏]我收藏的教材刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入り教材から削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Tài liệu Yêu thích.`,
  ],
  brief_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에 추가되었습니다.`,
    `Added to [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_added',
    `添加至[内容>我的收藏]收藏的Daily Brief。`,
    `已新增於[內容 > 我的收藏]我收藏的Daily Brief。`,
    `[コンテンツ > マイコンテンツ] お気に入りDaily Briefに追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Daily Brief Yêu thích.`,
  ],
  brief_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_deleted',
    `移出[内容>我的收藏]收藏的Daily Brief。`,
    `已從[內容 > 我的收藏]我收藏的Daily Brief刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入りDaily Briefから削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Daily Brief Yêu thích.`,
  ],
  webinar_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에 추가되었습니다.`,
    `Added to [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_added',
    `添加至[内容>我的收藏]收藏的网络研讨会。`,
    `已新增於[內容 > 我的收藏]我收藏的網路研討會。`,
    `[コンテンツ > マイコンテンツ] お気に入りウェビナーに追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Webinar Yêu thích.`,
  ],
  webinar_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_deleted',
    `移出[内容>我的收藏] 收藏的网络研讨会。`,
    `已從[內容 > 我的收藏]我收藏的網路研討會刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入りウェビナーから削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Webinar Yêu thích.`,
  ],
  title_ot: [
    `오리엔테이션 신청하기`,
    `Register Orientation`,
    's_portal_home.title_ot',
    `申请使用教学`,
    `申請使用教學`,
    `Register Orientation`,
    `Register Orientation`,
  ],
  subtitle_ot: [
    `링글 수업 200% 활용법`,
    `Make the best of Ringle`,
    's_portal_home.subtitle_ot',
    `Make the best of Ringle`,
    `Make the best of Ringle`,
    `Make the best of Ringle`,
    `Make the best of Ringle`,
  ],
  benefit_3: [
    <>최대 100,000원 추가 할인</>,
    <>Up to $77 Discount</>,
    's_portal_home.benefit_3',
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
  ],
  purchase_welcome: [
    <>
      신규회원{' '}
      <Div spanTag textSecondary>
        웰컴 쿠폰
      </Div>
    </>,
    <Div textSecondary>Welcome Coupon</Div>,
    's_portal_home.purchase_welcome',
    `首次购买优惠`,
    `首次購買折扣優惠`,
    `初回購入割引特典`,
    `Ưu đãi cho lần thanh toán đầu tiên`,
  ],
  gnb: {
    welcome_coupon: (day) => [
      <>
        신규회원 한정 3만원 웰컴쿠폰이{' '}
        <Div spanTag fontBold>
          {day}
        </Div>{' '}
        사라집니다
      </>,
      <>
        Last {day} for{' '}
        <Div spanTag fontBold>
          $25 off welcome offers
        </Div>
      </>,
      's_portal_home.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    welcome_coupon: {
      title_1: [`신규회원 한정`, `Welcome Gift`, 's_portal_home.popup.welcome_coupon.title_1', ``, ``, ``, ``],
      title_2: [
        `웰컴쿠폰 발급 안내`,
        `for new members only`,
        's_portal_home.popup.welcome_coupon.title_2',
        ``,
        ``,
        ``,
        ``,
      ],
      footer: [
        <>
          *쿠폰 발급 후 7일 이내 사용 가능합니다.
          <br />
        </>,
        <>
          * Valid for 7 days after issuance.
          <br />* Only redeemable toward regular packages worth $115 or more.
        </>,
        's_portal_home.popup.welcome_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`혜택 확인하기`, `See Details`, 's_portal_home.popup.welcome_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`닫기`, `Close`, 's_portal_home.popup.welcome_coupon.btn_2', ``, ``, ``, ``],
      btn_3: [`일주일간 보지 않기`, `Don't show again`, 's_portal_home.popup.welcome_coupon.btn_3', ``, ``, ``, ``],
    },
  },
  silicon_challenge: {
    title: [
      `실리콘밸리 챌린지`,
      `Silicon Valley Challenge`,
      's_portal_home.silicon_challenge.title',
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
    ],
    time_left: (day, number) => [
      <>🔥 {day}일 남음</>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.time_left',
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
    ],
    detail: (participants) => [
      <>🎯 {participants}명이 함께하고 있어요.</>,
      <>🎯 {participants} Ringlers participating</>,
      's_portal_home.silicon_challenge.detail',
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
    ],
    silicon_percent: (percent) => [
      <>{percent}%</>,
      <>{percent}%</>,
      's_portal_home.silicon_challenge.silicon_percent',
      <>{percent}%</>,
      <>{percent}%</>,
      <>{percent}%</>,
      <>{percent}%</>,
    ],
    silicon_lessons: (number, count) => [
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count}회 달성
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.silicon_lessons',
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
    ],
    monthly_mission: (month) => [
      <>{month}월 미션</>,
      <>{month} Mission</>,
      's_portal_home.silicon_challenge.monthly_mission',
      <>{month} Mission</>,
      <>{month} Mission</>,
      <>{month} Mission</>,
      <>{month} Mission</>,
    ],
    monthly_lessons: (number, count) => [
      <>
        <span className="text-black">{number}</span>/{count}회 완료
      </>,
      <>
        {number}/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.monthly_lessons',
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
    ],
    last_week: [
      `마지막 주`,
      `Last Week`,
      's_portal_home.silicon_challenge.last_week',
      `Last Week`,
      `Last Week`,
      `Last Week`,
      `Last Week`,
    ],
    last_week_msg: [
      `챌린지 기간이 얼마 안남았어요!`,
      `There's not much time left!`,
      's_portal_home.silicon_challenge.last_week_msg',
      `There's not much time left!`,
      `There's not much time left!`,
      `There's not much time left!`,
      `There's not much time left!`,
    ],
    start_challenge: [
      `챌린지 시작하기!`,
      `Start Challenge!`,
      's_portal_home.silicon_challenge.start_challenge',
      `Start Challenge!`,
      `Start Challenge!`,
      `Start Challenge!`,
      `Start Challenge!`,
    ],
    menu_tooltip: [
      `무료 수업권`,
      `Free Credit`,
      's_portal_home.silicon_challenge.menu_tooltip',
      `Free Credit`,
      `Free Credit`,
      `Free Credit`,
      `Free Credit`,
    ],
    msg: [
      <>
        수업도 듣고,
        <br />
        <Div spanTag fontBold>
          챌린지 혜택
        </Div>
        도 받으세요.
      </>,
      <>
        <Div spanTag fontBold>
          Earn rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      's_portal_home.silicon_challenge.msg',
      <>
        <Div spanTag fontBold>
          Earn rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
    ],
    btn_join_challenge: [
      `1초만에 신청하기`,
      `Start now`,
      's_portal_home.silicon_challenge.btn_join_challenge',
      `Start now`,
      `Start now`,
      `Start now`,
      `Start now`,
    ],
    see_detail: [
      `자세히 보기`,
      `See details`,
      's_portal_home.silicon_challenge.see_detail',
      `See details`,
      `See details`,
      `See details`,
      `See details`,
    ],
    forty: [
      `40분 수업권`,
      `40min credit`,
      's_portal_home.silicon_challenge.forty',
      `40min credit`,
      `40min credit`,
      `40min credit`,
      `40min credit`,
    ],
    twenty: [
      `20분 수업권`,
      `20min credit`,
      's_portal_home.silicon_challenge.twenty',
      `20min credit`,
      `20min credit`,
      `20min credit`,
      `20min credit`,
    ],
    point: [
      `참여 혜택 포인트`,
      `Points earned`,
      's_portal_home.silicon_challenge.point',
      `Points earned`,
      `Points earned`,
      `Points earned`,
      `Points earned`,
    ],
    benefit_left: [
      `다음 포인트 획득까지`,
      `Next points`,
      's_portal_home.silicon_challenge.benefit_left',
      `Next points`,
      `Next points`,
      `Next points`,
      `Next points`,
    ],
    benefit_ticket: [
      `여행 응모권 획득까지`,
      `Raffle ticket`,
      's_portal_home.silicon_challenge.benefit_ticket',
      `Raffle ticket`,
      `Raffle ticket`,
      `Raffle ticket`,
      `Raffle ticket`,
    ],
    left: (number) => [
      <>{number}회 남음</>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.left',
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
    ],
    date: [
      `06.08(수) - 10.06(목)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      's_portal_home.silicon_challenge.date',
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
    ],
    msg1: (point) => [
      <>
        수업만 들어도 챌린지 혜택을 드립니다! 지금 참여하시면{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point}P
        </span>
        가 즉시 적립됩니다.
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      's_portal_home.silicon_challenge.msg1',
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
    ],
    msg2: [
      `수업만 들어도 챌린지 혜택을 드립니다!`,
      `Earn rewards just by taking Ringle lessons!`,
      's_portal_home.silicon_challenge.msg2',
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
    ],
    mission: (number) => [
      <>{number}회 완강 시 무료 수업권 증정</>,
      <>Free credit rewarded for completing {number} lessons</>,
      's_portal_home.silicon_challenge.mission',
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
    ],
    msg3: [
      `수업만 들어도 챌린지 혜택을 드립니다! 수업권을 구매하고 챌린지에 참여하세요.`,
      `Buy credits now and join the challenge.`,
      's_portal_home.silicon_challenge.msg3',
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
    ],
    oct_details: [
      `스탬프 6개 채우고 수업권 받기`,
      `Collect 6 stamps!`,
      's_portal_home.silicon_challenge.oct_details',
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
    ],
  },
  label_today: [`Today`, `Today`, 's_portal_home.label_today', `Today`, `Today`, `Today`, `Today`],
  coupon: {
    title_welcome: [
      `신규회원 쿠폰`,
      `Welcome Coupon`,
      's_portal_home.coupon.title_welcome',
      `欢迎新会员优惠券`,
      `歡迎優新會員惠券`,
      `新規会員ウェルカムクーポン`,
      `Phiếu giảm giá chào mừng thành viên mới`,
    ],
    desc_welcome: [
      <>
        <Div spanTag fontBold>
          최대 15만원 할인 쿠폰
        </Div>
        을<br />
        지금 확인하세요!
      </>,
      <>
        Don't forget to
        <br />
        <Div spanTag fontBold>
          get up to $115 off!
        </Div>
      </>,
      's_portal_home.coupon.desc_welcome',
      <>
        <Div spanTag fontBold>
          现在开始Ringle课程最多优惠$115
        </Div>
        ，<br />
        不要错过此次最佳时机！
      </>,
      <>
        <Div spanTag fontBold>
          現在開始Ringle課程最多優惠$115
        </Div>
        ，<br />
        不要錯過此次最佳時機！
      </>,
      `最大$115割引を受けられる今がRingleを始めるグッドタイミング！`,
      <>
        Don't forget to
        <br />
        <Div spanTag fontBold>
          get up to $115 off!
        </Div>
      </>,
    ],
    btn: [
      `수업권 구매하기`,
      `Buy Credits`,
      's_portal_home.coupon.btn',
      `购买课程券`,
      `購買課程券`,
      `レッスンチケットを購入`,
      `Mua vé buổi học`,
    ],
    title_next: [
      `재구매 쿠폰`,
      `Next Purchase`,
      's_portal_home.coupon.title_next',
      `回购折扣券`,
      `回購折扣券`,
      `再購入割引クーポン`,
      `Mua lại phiếu giảm giá`,
    ],
    desc_next: [
      <>
        <b>최대 15만원 추가 할인</b>된<br />
        가격으로 수업권을 구매하세요!
      </>,
      <>
        Buy credits at prices
        <br />
        <b>discounted up to $115!</b>
      </>,
      's_portal_home.coupon.desc_next',
      `以最多再优惠$115的价格购买课程券吧！`,
      `以最多再優惠$115的價格購買課程券吧！`,
      `最大$115がさらに割引された価格でレッスンチケットを購入しましょう！`,
      `Mua vé buổi học với mức giảm thêm lên đến $115!`,
    ],
    title_secret: [
      `시크릿 쿠폰 할인`,
      `Secret Offer`,
      's_portal_home.coupon.title_secret',
      `Secret Offer`,
      `Secret Offer`,
      `Secret Offer`,
      `Secret Offer`,
    ],
    desc_secret: [
      <>
        23년 가격 인상 전 마지막
        <br />
        최대 30만원 추가 할인!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      's_portal_home.coupon.desc_secret',
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
    ],
  },
  default_stat: {
    title: (name, n) => [
      <>
        {name}님의
        <br />
        링글 학습 {n}일째
      </>,
      <>
        {name}'s
        <br />
        Day {n} with Ringle
      </>,
      's_portal_home.default_stat.title',
      <>
        {name}的Ringle学习第{n}天
      </>,
      <>
        {name}的Ringle學習第{n}天
      </>,
      <>
        {name}様のRingle学習 {n}日目
      </>,
      <>
        Ngày thứ {n} của {name} với Ringle
      </>,
    ],
    desc1: [
      `지금까지 링글 수업`,
      `Completed`,
      's_portal_home.default_stat.desc1',
      `至今为止的课程`,
      `至今為止的課程`,
      `今までのレッスン`,
      `Buổi học cho đến nay`,
    ],
    desc2: [
      `이번 달 링글 수업`,
      `This month`,
      's_portal_home.default_stat.desc2',
      `本月的课程`,
      `本月的課程`,
      `今月のレッスン`,
      `Buổi học trong tháng này`,
    ],
    desc3: [
      `연속 수업 진행`,
      `In a row`,
      's_portal_home.default_stat.desc3',
      `连续课程学习`,
      `連續課程學習`,
      `連続でレッスン進行`,
      `Hướng dẫn liên tục`,
    ],
    total: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.total',
      <>{n} 课程</>,
      <>{n} 課程</>,
      <>{n} 回</>,
      <>{n} </>,
    ],
    this_month: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.this_month',
      <>{n} 课程</>,
      <>{n} 課程</>,
      <>{n} 回</>,
      <>{n} </>,
    ],
    week: (n) => [
      <>{n}주</>,
      <>
        {n} {n > 1 ? 'weeks' : 'week'}
      </>,
      's_portal_home.default_stat.week',
      <>{n} 週</>,
      <>{n} 週</>,
      <>{n} 週</>,
      <>{n} tuần</>,
    ],
  },
  to_do: {
    msg_list: [
      `링글이 처음이라면 아래 목록을 진행해보세요!`,
      `If you're new to Ringle, check out the list below!`,
      's_portal_home.to_do.msg_list',
      `如果您是第一次使用Ringle，请按照下面目录进行操作。`,
      `如果您是第一次使用Ringle，請按照下面目錄進行操作。`,
      `Ringleが初めてなら下のリストを進めてみましょう！`,
      `Nếu bạn mới sử dụng Ringle, hãy tiếp tục với danh sách bên dưới!`,
    ],
    btn1: [
      `링글 사용법 알아보기`,
      `How to use Ringle`,
      's_portal_home.to_do.btn1',
      `了解Ringle使用方法`,
      `瞭解Ringle使用方法`,
      `Ringleの使用方法を調べる`,
      `Tìm hiểu cách sử dụng Ringle`,
    ],
    btn2: [
      `OT 신청하기`,
      `Sign up for Orientation`,
      's_portal_home.to_do.btn2',
      `申请OT`,
      `申請OT`,
      `OTを申請する`,
      `Đăng ký OT`,
    ],
    btn3: [
      `튜터 찜하기`,
      `Add tutors to [My Tutors]`,
      's_portal_home.to_do.btn3',
      `收藏想上课的任课教师`,
      `收藏想上課的任課教師`,
      `レッスンを受けたいチューターに"イイね"する`,
      `Hấp dẫn gia sư bạn muốn dạy`,
    ],
    btn4: [
      `교재 찜하기`,
      <>
        Add materials
        <br />
        to [My Content]
      </>,
      's_portal_home.to_do.btn4',
      `收藏想申请的课程`,
      `收藏想申請的課程`,
      `レッスンしたい教材に"イイね"する`,
      `Hấp các tài liệu bạn muốn dạy`,
    ],
    placeholder_book: [
      `예정된 수업이 없습니다. 수업을 예약해 보세요!`,
      `You have no scheduled lesson. Why don't you book one?`,
      's_portal_home.to_do.placeholder_book',
      `没有预定的课程。请预约课程。`,
      `沒有預定的課程。請預約課程。`,
      `予定されたレッスンはありません。レッスンを予約してみましょう！`,
      `Tôi không có một buổi học theo lịch trình. Đặt một buổi học!`,
    ],
    btn_book: [
      `수업 예약하기`,
      `Book a Lesson`,
      's_portal_home.to_do.btn_book',
      `预约课程`,
      `預約課程`,
      `レッスンを予約する`,
      `Đặt buổi học`,
    ],
    placeholder_purchase: [
      `수업권을 구매하고 수업을 시작해 보세요!`,
      `Buy credits and start taking lessons!`,
      's_portal_home.to_do.placeholder_purchase',
      `购买课程券开始上课`,
      `購買課程券開始上課`,
      `レッスンチケットを購入してレッスンを始めましょう！`,
      `Mua vé buổi học của bạn và bắt đầu buổi học của bạn!`,
    ],
    btn_purchase: [
      `수업권 구매하기`,
      `Buy Credits`,
      's_portal_home.to_do.btn_purchase',
      `购买课程券`,
      `購買課程券`,
      `レッスンチケットを購入`,
      `Mua vé buổi học`,
    ],
    msg_prep: [
      `예습을 진행하고 더 효과적인 수업을 경험해 보세요.`,
      `Check and prep for your upcoming lesson.`,
      's_portal_home.to_do.msg_prep',
      `请确认预定的课程并进行预习。`,
      `請確認預定的課程並進行預習。`,
      `予定されたレッスンを確認して予習を進めてください。`,
      `Kiểm tra các buổi học sắp tới và trước buổi học của bạn.`,
    ],
    title_lesson_crad: [
      `예정된 수업`,
      `Upcoming Lesson`,
      's_portal_home.to_do.title_lesson_crad',
      `预定课程`,
      `預定課程`,
      `予定されたレッスン`,
      `Các buổi học theo lịch trình`,
    ],
    btn_prep: [`예습하기`, `Lesson Prep`, 's_portal_home.to_do.btn_prep', `预习`, `預習`, `予習する`, `Học trước`],
    msg_lesson: [
      `곧 수업이 시작됩니다! 수업에 입장해 주세요.`,
      `The lesson is about to start! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson',
      `课程马上开始！请入场上课`,
      `課程馬上開始！請入場上課`,
      `すぐにレッスンが始まります！レッスンに入場してください。`,
      `Các buổi học sẽ sớm bắt đầu! Vui lòng tham gia buổi học.`,
    ],
    btn_lesson: [
      `입장하기`,
      `Enter Lesson`,
      's_portal_home.to_do.btn_lesson',
      `课程入场`,
      `進入課程`,
      `レッスン開始`,
      `Vào học`,
    ],
    msg_cancel: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 진심으로 사과드립니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled due to the tutor’s unforeseen circumstance. </>,
      's_portal_home.to_do.msg_cancel',
      <>由于任课教师不可避免的原因，{time}课程被取消。非常抱歉。</>,
      <>由於任課教師不可避免的原因，{time}課程被取消。非常抱歉。</>,
      <>チューターの避けられない事情により {time} レッスンがキャンセルされました。申し訳ございません。</>,
      <>Do gia sư không thể chào hàng, buổi học {time} đã bị hủy bỏ. Tôi chân thành xin lỗi.</>,
    ],
    msg_automatch: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 빠르게 다른 튜터를 찾아 매칭하겠습니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled. We will assign a new tutor for you.</>,
      's_portal_home.to_do.msg_automatch',
      <>由于任课教师不可避免的原因，{time}课程被取消，将尽快为您安排其他任课教师。</>,
      <>由於任課教師不可避免的原因，{time}課程被取消，將儘快為您安排其他任課教師。</>,
      <>
        チューターの避けられない事情により {time}{' '}
        レッスンがキャンセルされました。すぐに他のチューターをマッチングさせていただきます。
      </>,
      <>
        Do gia sư không thể chào hàng, buổi học {time} đã bị hủy bỏ. Tôi sẽ nhanh chóng tìm và kết hợp các gia sư khác.
      </>,
    ],
    msg_start_lesson: [
      `효과적인 수업을 위해 미리 교재를 읽고 수업 질문을 선택해 주세요.`,
      `Please read the lesson material and choose questions in advance.`,
      's_portal_home.to_do.msg_start_lesson',
      `为了提高课程效率，请提前阅读教材内容，选择课程问题。`,
      `爲了提高課程效率，請提前閱讀教材內容，選擇課程問題。`,
      `あらかじめ教材を読んで質問を選択してください。`,
      `Hãy đọc trước tài liệu và chọn trước câu hỏi thảo luận.`,
    ],
    msg_prep_freetopic: [
      `효과적인 수업을 위해 수업 자료를 미리 업로드해 주세요.`,
      `Please upload the lesson materials in advance.`,
      's_portal_home.to_do.msg_prep_freetopic',
      `为了提高课程效率，请提前上传课程资料。`,
      `爲了提高課程效率，請提前上傳課程資料。`,
      `あらかじめレッスン資料をアップロードしてください。`,
      `Hãy tải lên trước tài liệu để có một buổi học hiệu quả.`,
    ],
    tutor_not_matched_yet: [
      `튜터가 배정 중입니다.`,
      `We're finding a tutor for you.`,
      's_portal_home.to_do.tutor_not_matched_yet',
      `正在分配任课教师。`,
      `正在分配任課教師。`,
      `チューターを割り当て中です。`,
      `Chúng tôi đang tìm gia sư cho bạn`,
    ],
    msg_lesson_started: [
      `수업이 시작되었습니다! 수업에 입장해 주세요.`,
      `The lesson has already begun! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson_started',
      `课程已经开始，请入场上课。`,
      `課程已經開始，請入場上課。`,
      `レッスンが始まりました！レッスンに入ってください。`,
      `Buổi học đã bắt đầu! Hãy tham gia buổi học.`,
    ],
    btn5: [
      <>
        링글 수업 알아보고
        <br />
        5,000 포인트 받기
      </>,
      <>
        Learn about Ringle
        <br />
        and get $3.84 points
      </>,
      's_portal_home.to_do.btn5',
      `了解课程并获得价值$3.84积分`,
      `了解課程並獲得價值$3.84點數`,
      <>
        レッスンをチェックして
        <br />
        5,000ポイントゲット
      </>,
      <>
        Tìm hiểu về các lớp Ringle
        <br />
        nhận 5.000 điểm
      </>,
    ],
    btn6: [
      <>
        프로필 입력하고
        <br />
        10,000 포인트 받기
      </>,
      <>
        Complete your profile
        <br />
        and get $7.69 points
      </>,
      's_portal_home.to_do.btn6',
      `完成您的个人资料并获得价值$7.69积分`,
      `完成您的個人資料並獲得價值$7.69點數`,
      <>
        プロフィール入力して
        <br />
        10,000ポイントゲット
      </>,
      <>
        Hoàn thiện hồ sơ
        <br />
        nhận 10.000 điểm
      </>,
    ],
  },
  monthly_challenge: {
    msg: [
      `오늘부터 링글팀과 영어 공부 루틴을 만들어보세요!`,
      `Make a routine of studying English with Ringle!`,
      's_portal_home.monthly_challenge.msg',
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
    ],
    btn_join: [
      `챌린지 참여하기`,
      `Join Challenge`,
      's_portal_home.monthly_challenge.btn_join',
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
    ],
    title_oct: [
      `10월 수업 챌린지`,
      `October Challenge`,
      's_portal_home.monthly_challenge.title_oct',
      `October Challenge`,
      `October Challenge`,
      `October Challenge`,
      `October Challenge`,
    ],
    title_nov: [
      `11월 수업 챌린지`,
      `November Challenge`,
      's_portal_home.monthly_challenge.title_nov',
      `November Challenge`,
      `November Challenge`,
      `November Challenge`,
      `November Challenge`,
    ],
    title_dec: [
      `12월 수업 챌린지`,
      `December Challenge`,
      's_portal_home.monthly_challenge.title_dec',
      `December Challenge`,
      `December Challenge`,
      `December Challenge`,
      `December Challenge`,
    ],
    mission: [
      `보너스 스탬프 미션`,
      `Bonus Mission`,
      's_portal_home.monthly_challenge.mission',
      `Bonus Mission`,
      `Bonus Mission`,
      `Bonus Mission`,
      `Bonus Mission`,
    ],
    mission_board: [
      `4주 연속 수업 듣기 현황`,
      `Status for taking lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.mission_board',
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
    ],
    streak: [
      `이번 달 4주 연속 수업 듣기`,
      `Take lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.streak',
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
    ],
    fast_lesson: (date) => [
      <>{date}까지 첫 수업 듣기</>,
      <>Take first lesson by {date}</>,
      's_portal_home.monthly_challenge.fast_lesson',
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
    ],
    see_detail: [
      `자세히 보기`,
      `See details`,
      's_portal_home.monthly_challenge.see_detail',
      `See details`,
      `See details`,
      `See details`,
      `See details`,
    ],
    modal_title_mkt: [
      `챌린지 참여 신청`,
      `Join the challenge`,
      's_portal_home.monthly_challenge.modal_title_mkt',
      `Join the challenge`,
      `Join the challenge`,
      `Join the challenge`,
      `Join the challenge`,
    ],
    modal_desc: [
      `챌린지에 참여하게 되면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      's_portal_home.monthly_challenge.modal_desc',
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
    ],
    modal_btn1: [
      `나중에 다시 보기`,
      `Skip for Now`,
      's_portal_home.monthly_challenge.modal_btn1',
      `Skip for Now`,
      `Skip for Now`,
      `Skip for Now`,
      `Skip for Now`,
    ],
    modal_btn2: [
      `챌린지 참여하기`,
      `Join Challenge`,
      's_portal_home.monthly_challenge.modal_btn2',
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
    ],
    toast: (date) => [
      <>{date} 마케팅 수신에 동의하셨습니다.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      's_portal_home.monthly_challenge.toast',
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
    ],
    modal_title: [
      `수업 챌린지`,
      `Monthly Challenge`,
      's_portal_home.monthly_challenge.modal_title',
      `Monthly Challenge`,
      `Monthly Challenge`,
      `Monthly Challenge`,
      `Monthly Challenge`,
    ],
    oct: [`10월 챌린지`, `October`, 's_portal_home.monthly_challenge.oct', `October`, `October`, `October`, `October`],
    date_oct: [
      `2022. 10. 1 - 2022. 10. 31`,
      `2022. 10. 1 - 2022. 10. 31`,
      's_portal_home.monthly_challenge.date_oct',
      `2022. 10. 1 - 2022. 10. 32`,
      `2022. 10. 1 - 2022. 10. 33`,
      `2022. 10. 1 - 2022. 10. 34`,
      `2022. 10. 1 - 2022. 10. 35`,
    ],
    nov: [
      `11월 챌린지`,
      `November`,
      's_portal_home.monthly_challenge.nov',
      `November`,
      `November`,
      `November`,
      `November`,
    ],
    date_nov: [
      `2022. 11. 1 - 2022. 11. 30`,
      `2022. 11. 1 - 2022. 11. 30`,
      's_portal_home.monthly_challenge.date_nov',
      `2022. 11. 1 - 2022. 11. 31`,
      `2022. 11. 1 - 2022. 11. 32`,
      `2022. 11. 1 - 2022. 11. 33`,
      `2022. 11. 1 - 2022. 11. 34`,
    ],
    dec: [
      `12월 챌린지`,
      `December`,
      's_portal_home.monthly_challenge.dec',
      `December`,
      `December`,
      `December`,
      `December`,
    ],
    date_dec: [
      `2022. 12. 1 - 2022. 12. 31`,
      `2022. 12. 1 - 2022. 12. 31`,
      's_portal_home.monthly_challenge.date_dec',
      `2022. 12. 1 - 2022. 12. 32`,
      `2022. 12. 1 - 2022. 12. 33`,
      `2022. 12. 1 - 2022. 12. 34`,
      `2022. 12. 1 - 2022. 12. 35`,
    ],
    credit_20min: [
      `20분 수업권`,
      `20-min credit`,
      's_portal_home.monthly_challenge.credit_20min',
      `20-min credit`,
      `20-min credit`,
      `20-min credit`,
      `20-min credit`,
    ],
    week: [`주차`, `Week`, 's_portal_home.monthly_challenge.week', `Week`, `Week`, `Week`, `Week`],
    date: [`기간`, `Period`, 's_portal_home.monthly_challenge.date', `Period`, `Period`, `Period`, `Period`],
    lesson: [
      `수업 수`,
      `Lessons`,
      's_portal_home.monthly_challenge.lesson',
      `Lessons`,
      `Lessons`,
      `Lessons`,
      `Lessons`,
    ],
    done: [`달성 여부`, `Status`, 's_portal_home.monthly_challenge.done', `Status`, `Status`, `Status`, `Status`],
    oct_week1: [
      `10. 3(월) - 10. 9(일)`,
      `Oct 3(M) - Oct 9(S)`,
      's_portal_home.monthly_challenge.oct_week1',
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
    ],
    oct_week2: [
      `10. 10(월) - 10. 16(일)`,
      `Oct 10(M) - Oct 16(S)`,
      's_portal_home.monthly_challenge.oct_week2',
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
    ],
    oct_week3: [
      `10.17(월) - 10. 23(일)`,
      `Oct 17(M) - Oct 23(S)`,
      's_portal_home.monthly_challenge.oct_week3',
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
    ],
    oct_week4: [
      `10. 24(월) - 10. 30(일)`,
      `Oct 24(M) - Oct 30(S)`,
      's_portal_home.monthly_challenge.oct_week4',
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
    ],
    nov_week1: [
      `10. 31(월) - 11. 6(일)`,
      `Oct 31(M) - Nov 6(S)`,
      's_portal_home.monthly_challenge.nov_week1',
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
    ],
    nov_week2: [
      `11. 7(월) - 11. 13(일)`,
      `Nov 7(M) - Nov 13(S)`,
      's_portal_home.monthly_challenge.nov_week2',
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
    ],
    nov_week3: [
      `11. 14(월) - 11. 20(일)`,
      `Nov 14(M) - Nov 20(S)`,
      's_portal_home.monthly_challenge.nov_week3',
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
    ],
    nov_week4: [
      `11. 21(월) - 11. 27(일)`,
      `Nov 21(M) - Nov 27(S)`,
      's_portal_home.monthly_challenge.nov_week4',
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
    ],
    dec_week1: [
      `11. 28(월) - 12. 4(일)`,
      `Nov 28(M) - Dec 4(S)`,
      's_portal_home.monthly_challenge.dec_week1',
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
    ],
    dec_week2: [
      `12. 5(월) - 12. 11(일)`,
      `Dec 5(M) - Dec 11(S)`,
      's_portal_home.monthly_challenge.dec_week2',
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
    ],
    dec_week3: [
      `12. 12(월) - 12. 18(일)`,
      `Dec 12(M) - Dec 18(S)`,
      's_portal_home.monthly_challenge.dec_week3',
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
    ],
    dec_week4: [
      `12. 19(월) - 12. 25(일)`,
      `Dec 19(M) - Dec 25(S)`,
      's_portal_home.monthly_challenge.dec_week4',
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
    ],
    note: [
      `챌린지 진행 방식 자세히 보기`,
      `About the Monthly Challenge`,
      's_portal_home.monthly_challenge.note',
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
    ],
    note_body: [
      <>
        <b>STEP 1.</b> 링글 수업 듣고 스탬프 받기
        <br />
        <b>STEP 2.</b> 매달 스탬프 채우고 20분 무료 수업권 받기
        <br />
        + 보너스 스탬프와 서프라이즈 혜택도 놓치지 마세요!
        <br />
        <br />
        <b>혜택 🎁</b>
        <br />
        • 챌린지 달성자 전원에게 링글 계정으로 20분 수업권을 증정합니다. (수강기간: 지급일 기준 14일)
        <br />
        • [12월 한정] 챌린지 달성자 전원에게 1월 라이브 유료 서비스 이용권을 증정합니다.
        <br />
        • 챌린지 달성자 중 10명을 추첨하여 매달 5만원 상당의 서프라이즈 선물을 증정합니다. (1/6(금) 예정)
        <br />
        <br />
        <b>공통 안내</b>
        <br />
        • 모든 일정은 한국 시간 기준입니다.
        <br />
        • 혜택 당첨자는 발표일에 개별 안내해 드립니다.
        <br />
        • 잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.
        <br />
        • 본 이벤트는 월별 챌린지로 매달 새로 신청해야 합니다.
        <br />
        • 본 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.
        <br />
        <br />
        <b>수업 기준</b>
        <br />
        • 모든 수업권으로 매월 마지막 일 23:30PM에 시작한 수업까지 해당 월의 수업으로 인정됩니다.
        <br />
        • 수업 정상 수강, 튜터 노쇼, 수업 정각에 튜터 미배정이 발생한 경우에도 스탬프를 제공합니다.
        <br />• 40분/20분 수업권을 교차 수강할 경우, 40분/20분 수업 챌린지가 개별 적용됩니다.
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      's_portal_home.monthly_challenge.note_body',
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
    ],
    acheived: [
      `성공`,
      `Accomplished`,
      's_portal_home.monthly_challenge.acheived',
      `Accomplished`,
      `Accomplished`,
      `Accomplished`,
      `Accomplished`,
    ],
    fail: [`미달성`, `Skipped`, 's_portal_home.monthly_challenge.fail', `Skipped`, `Skipped`, `Skipped`, `Skipped`],
    in_progress: [
      `진행중`,
      `Ongoing`,
      's_portal_home.monthly_challenge.in_progress',
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
    ],
    min_40: [`40분`, `40min`, 's_portal_home.monthly_challenge.min_40', `40min`, `40min`, `40min`, `40min`],
    min_20: [`20분`, `20min`, 's_portal_home.monthly_challenge.min_20', `20min`, `20min`, `20min`, `20min`],
    crm_msg_20min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_20min_1',
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
    ],
    crm_msg_20min_2: [
      `💯 축하합니다! 두 번째 스탬프가 성공적으로 추가되었어요.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      's_portal_home.monthly_challenge.crm_msg_20min_2',
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
    ],
    crm_msg_20min_3: [
      `🔥 세 번째 스탬프 추가! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_20min_3',
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
    ],
    crm_msg_20min_4: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_20min_4',
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
    ],
    crm_msg_20min_5: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_20min_5',
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
    ],
    crm_msg_20min_6: [
      `🏆 꾸준히 수업해서 벌써 여섯 번째 도장을 받으셨어요!`,
      `🏆 You already received 6 stamps!`,
      's_portal_home.monthly_challenge.crm_msg_20min_6',
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
    ],
    crm_msg_20min_7: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_20min_7',
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
    ],
    crm_msg_20min_8: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_20min_8',
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
    ],
    crm_msg_40min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_40min_1',
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
    ],
    crm_msg_40min_2: [
      `🔥 두 번째 스탬프 완료! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_40min_2',
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
    ],
    crm_msg_40min_3: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_40min_3',
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
    ],
    crm_msg_40min_4: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_40min_4',
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
    ],
    crm_msg_40min_5: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_40min_5',
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
    ],
    crm_msg_40min_6: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_40min_6',
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
    ],
    modal_checkbox: [
      `(선택) 매일 새로운 영어 표현을 배우면서 응원받기💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      's_portal_home.monthly_challenge.modal_checkbox',
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
    ],
    modal_desc2: [
      `응원 알림을 추가로 신청 하신 분들에 한하여 성공적인 챌린지 완주를 위한 영어 표현과 응원 메시지를  카카오톡으로 보내드려요. (링글에 가입한 번호의 카카오톡 계정을 통해 한국어로 제공됩니다.)`,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      's_portal_home.monthly_challenge.modal_desc2',
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
    ],
    crm_msg_20min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_20min_0',
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
    ],
    crm_msg_40min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_40min_0',
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
    ],
    week_number: (n) => [
      `${n}주`,
      `Week ${n}`,
      's_portal_home.monthly_challenge.week_number',
      `Week ${n}`,
      `Week ${n}`,
      `Week ${n}`,
      `Week ${n}`,
    ],
    week2: [`2주`, `Week 2`, 's_portal_home.monthly_challenge.week2', `Week 2`, `Week 2`, `Week 2`, `Week 2`],
    week3: [`3주`, `Week 3`, 's_portal_home.monthly_challenge.week3', `Week 3`, `Week 3`, `Week 3`, `Week 3`],
    week4: [`4주`, `Week 4`, 's_portal_home.monthly_challenge.week4', `Week 4`, `Week 4`, `Week 4`, `Week 4`],
    modal_placeholder_nov: [
      `11월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Nov 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov',
      `Starts on Nov 1, 2023`,
      `Starts on Nov 1, 2024`,
      `Starts on Nov 1, 2025`,
      `Starts on Nov 1, 2026`,
    ],
    modal_placeholder_dec: [
      `12월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Dec 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_dec',
      `Starts on Dec 1, 2023`,
      `Starts on Dec 1, 2024`,
      `Starts on Dec 1, 2025`,
      `Starts on Dec 1, 2026`,
    ],
    modal_placeholder_oct2: [
      `10월 챌린지는 종료되었습니다.`,
      `Ended Oct 31, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_oct2',
      `Ended Oct 31, 2023`,
      `Ended Oct 31, 2024`,
      `Ended Oct 31, 2025`,
      `Ended Oct 31, 2026`,
    ],
    modal_placeholder_nov2: [
      `11월 챌린지는 종료되었습니다.`,
      `Ended Nov 30, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov2',
      `Ended Nov 30, 2023`,
      `Ended Nov 30, 2024`,
      `Ended Nov 30, 2025`,
      `Ended Nov 30, 2026`,
    ],
  },
  point: [
    `사용 가능한 포인트`,
    `Available points`,
    's_portal_home.point',
    `可使用的积分`,
    `可使用的點數`,
    `使用可能なポイント`,
    `Available points`,
  ],
  point_alert: [
    `2월 3일 50,000포인트 소멸 예정`,
    `$38 Points valid until Feb 2`,
    's_portal_home.point_alert',
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
  ],
  point_tooltip: [
    `곧 사라져요!`,
    `Don’t forget!`,
    's_portal_home.point_tooltip',
    `Don’t forget!`,
    `Don’t forget!`,
    `Don’t forget!`,
    `Don’t forget!`,
  ],
  point_event: [
    `5만 포인트 잊으셨나요?`,
    `Remember $38P gift?`,
    's_portal_home.point_event',
    `Remember $38P gift?`,
    `Remember $38P gift?`,
    `Remember $38P gift?`,
    `Remember $38P gift?`,
  ],
  point_desc: [
    <>
      설 선물로 드린
      <br />
      5만 포인트가 아직
      <br />
      잠자고 있어요.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    's_portal_home.point_desc',
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
  ],
  point_btn: [
    `포인트로 구매하기`,
    `Buy Credits`,
    's_portal_home.point_btn',
    `Buy Credits`,
    `Buy Credits`,
    `Buy Credits`,
    `Buy Credits`,
  ],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_portal_home.recommended_tutor',
    `推荐教师`,
    `推薦的教師`,
    `おすすめ`,
    `Nổi bật`,
  ],
  recommended_banner: {
    title: [
      <>
        튜터가 너무 많아 고민되나요? 링글러들이 선호하는 <span className="text-purple-500">추천 튜터</span>를
        만나보세요!
      </>,
      <>
        Too many tutors to choose from? Check out our <span className="text-purple-500">Featured Tutors!</span>
      </>,
      's_portal_home.recommended_banner.title',
      `不知道怎麽选教师？可以参考其他Ringle学员推荐的教师！`,
      `不知道怎麼選教師？可以參考其他Ringle學員推薦的教師！`,
      `チューター選びにお困りですか？人気のおすすめチューターをチェック！`,
      `Lo lắng vì không biết chọn gia sư nào? Kiểm tra danh sách gia sư nổi bật!`,
    ],
    desc: [
      `추천 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.`,
      `We'll restore your credit if you are unsatisfied with a Featured Tutor.`,
      's_portal_home.recommended_banner.desc',
      `如果对于推荐教师的课程不满意，我们将退还您的课程券。`,
      `如果對於推薦教師的課程不滿意，我們將退還您的課程券。`,
      `レッスンにご満足いただけない場合、レッスンチケットの復元が可能です。`,
      `Phiếu học sẽ được hoàn nếu bạn không hài lòng với tiết học.`,
    ],
  },
  banner_modal: {
    title: [
      `전체 보기`,
      `View All`,
      's_portal_home.banner_modal.title',
      `查看全部`,
      `查看全部`,
      `すべて見る`,
      `Xem tất cả`,
    ],
  },
  banner_all: {
    btn: [`전체 보기`, `View All`, 's_portal_home.banner_all.btn', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  },
  new_tutor: {
    title: [
      `새로 온 튜터 50% 포인트백`,
      `Rookie Tutor 50% Value Back`,
      's_portal_home.new_tutor.title',
      `50% 积分返还`,
      `50% 點數回饋`,
      `50%相当のポイント入手`,
      `50% Point Back`,
    ],
    desc: [
      <>
        <p>10대 1의 경쟁률을 뚫은 새로 온 튜터들을 만나보세요.</p>
        <p>
          수업 후기를 남기면 <span className="highlight">수업료의 50%</span>를 포인트로 돌려드려요.
        </p>
      </>,
      <>
        <p>Meet our highly-qualified Rookie Tutors.</p>
        <p>
          Leave a review to get <span className="hightlight">50% value back</span> in points.
        </p>
      </>,
      's_portal_home.new_tutor.desc',
      <>
        <p>和新教师上课</p>
        <p>
          留下课后评价，<span className="highlight">获得课程价值50% 积分返还</span>
        </p>
      </>,
      <>
        <p>和新教師上課</p>
        <p>
          留下課後評價，<span className="highlight">獲得課程價值50% 點數回饋</span>
        </p>
      </>,
      <>
        <p>10対1の競争率を勝ち抜いた新規チューターはこちら</p>
        <p>
          レビューを残すと<span className="highlight">料金の50％</span>をポイントで受け取れます。
        </p>
      </>,
      <>
        <p>Gặp gỡ nhóm gia sư mới chất lượng cao</p>
        <p>
          Viết nhận xét nhận hoàn điểm trị giá <span className="highlight">50% học phí</span>
        </p>
      </>,
    ],
  },
  menu_home: [`홈`, ``, 's_portal_home.menu_home', ``, ``, ``, ``],
  menu_material: [`교재`, ``, 's_portal_home.menu_material', ``, ``, ``, ``],
  menu_tutor: [`튜터`, ``, 's_portal_home.menu_tutor', ``, ``, ``, ``],
  menu_price: [`가격`, ``, 's_portal_home.menu_price', ``, ``, ``, ``],
  menu_cs: [`고객센터`, ``, 's_portal_home.menu_cs', ``, ``, ``, ``],
  ringle_pick: {
    title: [
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      's_portal_home.ringle_pick.title',
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      ``,
    ],
    subtitle: [
      <>
        내 목적에 맞는
        <br />
        공부방법을
        <br />
        골라봤어요.
      </>,
      <>
        How Ringle
        <br />
        Can Meet
        <br />
        Your Needs
      </>,
      's_portal_home.ringle_pick.subtitle',
      <>
        选择适合
        <br />
        自己的
        <br />
        学习方式
      </>,
      <>
        選擇適合
        <br />
        自己的
        <br />
        學習方式
      </>,
      <>
        目的に合った
        <br />
        学習方法を
        <br />
        ピックアップ
      </>,
      <>
        Ringle có thể <br />
        đáp ứng
        <br />
        nhu cầu của bạn.
      </>,
    ],
  },
  ringle_pick_1: {
    tag1: [
      `#초∙중급`,
      `#Starters`,
      's_portal_home.ringle_pick_1.tag1',
      `#初/中级学习者`,
      `#初/中級學習者`,
      `#初・中級`,
      `#Beginners`,
    ],
    tag2: [
      `#일상회화`,
      `#DailyEnglish`,
      's_portal_home.ringle_pick_1.tag2',
      `#日常对话`,
      `#日常對話`,
      `#日常会話`,
      `#DailyEnglish`,
    ],
    title: [
      <>
        원어민만 만나면
        <br />
        손에 땀이 나요.
      </>,
      <>
        English makes
        <br />
        me nervous.
      </>,
      's_portal_home.ringle_pick_1.title',
      <>
        和母语人士对话
        <br />
        感到紧张
      </>,
      <>
        和母語人士對話
        <br />
        覺得緊張
      </>,
      <>
        英語で話す時
        <br />
        緊張しすぎる…
      </>,
      <>
        Tiếng anh khiến
        <br />
        tôi lo lắng.
      </>,
    ],
    btn: [
      <>
        가벼운 주제로
        <br />
        영어에 익숙해지기
      </>,
      <>
        Get use to
        <br />
        speaking English
      </>,
      's_portal_home.ringle_pick_1.btn',
      <>
        用轻鬆主题
        <br />
        练习英语对话
      </>,
      <>
        用輕鬆主題
        <br />
        練習英文對話
      </>,
      <>
        簡単なテーマで
        <br />
        英語に慣れよう
      </>,
      <>
        Làm quen với
        <br />
        nói tiếng anh
      </>,
    ],
  },
  ringle_pick_2: {
    tag1: [
      `#비즈니스 영어`,
      `#BusinessEnglish`,
      's_portal_home.ringle_pick_2.tag1',
      `#商用英语`,
      `#商用英文`,
      `#ビジネス英語`,
      `#tiếnganhthươngmại`,
    ],
    title: [
      <>
        내일 바로 회사에서
        <br />
        영어를 써야해요.
      </>,
      <>
        I use English
        <br />
        every day at work.
      </>,
      's_portal_home.ringle_pick_2.title',
      <>
        每天在单位
        <br />
        都要使用英语
      </>,
      <>
        每天在公司
        <br />
        都要使用英文
      </>,
      <>
        明日会社で
        <br />
        英語が必要…
      </>,
      <>
        Tôi cần Tiếng Anh
        <br />
        trong công việc.
      </>,
    ],
    btn: [
      <>
        이메일 표현부터
        <br />
        비즈니스 트렌드까지
      </>,
      <>
        From writing emails
        <br />
        to biz trends
      </>,
      's_portal_home.ringle_pick_2.btn',
      <>
        从撰写英语邮件
        <br />
        到商业趋势
      </>,
      <>
        從撰寫英文郵件
        <br />
        到商業趨勢
      </>,
      <>
        メールでの表現から
        <br />
        ビジネストレンドまで
      </>,
      <>
        Viết email hoặc <br />
        tìm hiểu kinh doanh
      </>,
    ],
  },
  ringle_pick_3: {
    tag1: [
      `#유학/해외 취업 준비`,
      `#GoingAbroad`,
      's_portal_home.ringle_pick_3.tag1',
      `#海外留学/就业`,
      `#海外留學/就業`,
      `#留学/海外就職準備`,
      `#duhọc/địnhcưnướcngoài`,
    ],
    title: [
      <>
        글로벌에서 더 큰 꿈을
        <br />
        펼치고 싶어요.
      </>,
      <>
        I dream of
        <br />
        living globally.
      </>,
      's_portal_home.ringle_pick_3.title',
      <>
        在世界舞台
        <br />
        展现最好的自己
      </>,
      <>
        在世界舞台
        <br />
        展現最好的自己
      </>,
      <>
        グローバルの舞台で
        <br />
        大きな夢を叶えたい
      </>,
      <>
        Trở thành
        <br />
        công toàn cầu.
      </>,
    ],
    btn: [
      <>
        레쥬메 작성부터
        <br />
        인터뷰 준비까지
      </>,
      <>
        From resumes
        <br />
        to interviews
      </>,
      's_portal_home.ringle_pick_3.btn',
      <>
        从撰写履历
        <br />
        到准备面试
      </>,
      <>
        從撰寫履歷
        <br />
        到準備面試
      </>,
      <>
        レジュメから
        <br />
        面接準備まで
      </>,
      <>
        từ hồ sơ
        <br />
        đến phỏng vấn
      </>,
    ],
  },
  todo: {
    btn_modal: [
      `포인트 받기`,
      `Get points`,
      's_portal_home.todo.btn_modal',
      `获取积分`,
      `獲得點數`,
      `ポイントゲット`,
      `Nhận điểm`,
    ],
    toast: [
      `5,000 포인트가 적립되었습니다.`,
      `You have received $3.84 points.`,
      's_portal_home.todo.toast',
      `您已获得$3.84积分`,
      `您已獲得$3.84點數`,
      `5,000ポイントが支給されました。`,
      `Bạn đã được 5,000 điểm`,
    ],
  },
  trial_lesson: {
    title: [
      <>
        링글의 첫 수업,
        <br />
        커피 한 잔 가격으로 체험하세요.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      's_portal_home.trial_lesson.title',
      <>
        您的第一堂Ringle课程，
        <br />
        仅需一杯咖啡的价格。
      </>,
      <>
        您的第一堂Ringle課程，
        <br />
        僅需一杯咖啡的價格。
      </>,
      <>
        Ringleでの初レッスン、
        <br />
        コーヒー1杯の値段で体験できます。
      </>,
      <>
        Bài học đầu tiên của Ringle,
        <br />
        trải nghiệm với mức giá hạt dẻ
      </>,
    ],
    time: (time) => [
      <>체험권은 {time} 후 사라져요. </>,
      <>Trial lesson credit disappears in {time}.</>,
      's_portal_home.trial_lesson.time',
      <>体验券将在 {time} 后消失。</>,
      <>體驗券將在 {time} 後消失。</>,
      <>体験券は {time} 後に消えます。</>,
      <>Vé trải nghiệm sẽ mất sau {time}.</>,
    ],
    btn: [
      `체험수업 신청하기`,
      `Start now with a trial lesson`,
      's_portal_home.trial_lesson.btn',
      `申请体验课程`,
      `申請體驗課程`,
      `体験授業の申し込み`,
      `Đăng ký trải nghiệm`,
    ],
  },
  jul_challenge: {
    join: [
      `7월 챌린지에 도전하세요!`,
      `Join the July Challenge!`,
      's_portal_home.jul_challenge.join',
      `Join the July Challenge!`,
      `Join the July Challenge!`,
      `Join the July Challenge!`,
      `Join the July Challenge!`,
    ],
    join_modal: [
      <>
        6월보다 수업 2회 더 하고 챌린지 달성 혜택을 받으세요.
        <br />
        <br />
        <b>기간:</b> 7.1(토) 자정 00:00 KST - 7.31(월) 저녁 23:59 KST
        <br />
        <b>달성 조건: </b>챌린지 기간 동안 6월에 완료한 수업 수에 2회 추가로 완료
        <br />
        <b>달성 혜택: </b> 스타벅스 아메리카노 기프티콘 & 8월 링글 유료 LIVE 무료 참여 (Read with Me: Crying in H Mart)
        <br />
        <br />
        <b>유의 사항</b>
        <br />
        <div>
          <li className="li-tag-inside">챌린지 신청과 동시에 이벤트 및 혜택 소식 수신에 동의하게 됩니다.</li>
          <li className="li-tag-inside">알림 수신 설정한 이메일로 매주 완료한 수업 수를 알려 드립니다.</li>
          <li className="li-tag-inside">
            정상 완료된 수업만 챌린지 수업 완료 조건으로 인정됩니다. (당일 수업, 무료 수업권으로 진행한 수업 포함)
          </li>
          <li className="li-tag-inside">
            모든 일정은 한국 시간을 기준으로 운영되며, 7.31(월) 저녁 23:30 KST에 시작한 수업까지만 챌린지 수업으로
            인정됩니다.
          </li>
          <li className="li-tag-inside">
            스타벅스 아메리카노 기프티콘은 고객님 계정에 등록된 휴대폰 번호로 8.9(수) 오후 3시에 보내드립니다. 해외에
            거주할 경우, 5,000 포인트로 대체됩니다.
          </li>
          <li className="li-tag-inside">8월 유료 라이브 정보는 이메일로 8.1(화)에 보내드립니다.</li>
        </div>
      </>,
      <>
        This July, earn special rewards by taking two more lessons than June!
        <br />
        <br />
        <b>Challenge Period:</b> July 1(Sat) 00:00 AM (KST) - July 31(Mon) 23:59PM KST
        <br />
        <b>Requirements:</b> Complete two additional lessons than the number you completed in June during the challenge
        period <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            By signing up for the challenge, you are agreeing to receive [Events & Offers] notifications to let you know
            how many lessons you have completed each week.
          </li>
          <li className="li-tag-inside">
            Only the lessons attended by both the student and tutor will be counted oward the challenge. This includes
            lessons booked with last-minute credits and free credits.
          </li>
          <li className="li-tag-inside">
            All schedules are based on KST, and the lesson must start on or before Monday, July 31 at 23:30 KST to count
            towards the challenge.
          </li>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      's_portal_home.jul_challenge.join_modal',
      <>
        This July, earn special rewards by taking two more lessons than June!
        <br />
        <br />
        <b>Challenge Period:</b> July 1(Sat) 00:00 AM (KST) - July 31(Mon) 23:59PM KST
        <br />
        <b>Requirements:</b> Complete two additional lessons than the number you completed in June during the challenge
        period <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            By signing up for the challenge, you are agreeing to receive [Events & Offers] notifications to let you know
            how many lessons you have completed each week.
          </li>
          <li className="li-tag-inside">
            Only the lessons attended by both the student and tutor will be counted oward the challenge. This includes
            lessons booked with last-minute credits and free credits.
          </li>
          <li className="li-tag-inside">
            All schedules are based on KST, and the lesson must start on or before Monday, July 31 at 23:30 KST to count
            towards the challenge.
          </li>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        This July, earn special rewards by taking two more lessons than June!
        <br />
        <br />
        <b>Challenge Period:</b> July 1(Sat) 00:00 AM (KST) - July 31(Mon) 23:59PM KST
        <br />
        <b>Requirements:</b> Complete two additional lessons than the number you completed in June during the challenge
        period <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            By signing up for the challenge, you are agreeing to receive [Events & Offers] notifications to let you know
            how many lessons you have completed each week.
          </li>
          <li className="li-tag-inside">
            Only the lessons attended by both the student and tutor will be counted oward the challenge. This includes
            lessons booked with last-minute credits and free credits.
          </li>
          <li className="li-tag-inside">
            All schedules are based on KST, and the lesson must start on or before Monday, July 31 at 23:30 KST to count
            towards the challenge.
          </li>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        This July, earn special rewards by taking two more lessons than June!
        <br />
        <br />
        <b>Challenge Period:</b> July 1(Sat) 00:00 AM (JST) - July 31(Mon) 23:59PM JST
        <br />
        <b>Requirements:</b> Complete two additional lessons than the number you completed in June during the challenge
        period <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            By signing up for the challenge, you are agreeing to receive [Events & Offers] notifications to let you know
            how many lessons you have completed each week.
          </li>
          <li className="li-tag-inside">
            Only the lessons attended by both the student and tutor will be counted oward the challenge. This includes
            lessons booked with last-minute credits and free credits.
          </li>
          <li className="li-tag-inside">
            All schedules are based on KST, and the lesson must start on or before Monday, July 31 at 23:30 KST to count
            towards the challenge.
          </li>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        This July, earn special rewards by taking two more lessons than June!
        <br />
        <br />
        <b>Challenge Period:</b> July 1(Sat) 00:00 AM (KST) - July 31(Mon) 23:59PM KST
        <br />
        <b>Requirements:</b> Complete two additional lessons than the number you completed in June during the challenge
        period <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            By signing up for the challenge, you are agreeing to receive [Events & Offers] notifications to let you know
            how many lessons you have completed each week.
          </li>
          <li className="li-tag-inside">
            Only the lessons attended by both the student and tutor will be counted oward the challenge. This includes
            lessons booked with last-minute credits and free credits.
          </li>
          <li className="li-tag-inside">
            All schedules are based on KST, and the lesson must start on or before Monday, July 31 at 23:30 KST to count
            towards the challenge.
          </li>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
    ],
    join_btn1: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_portal_home.jul_challenge.join_btn1',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    join_btn2: [`닫기`, `Close`, 's_portal_home.jul_challenge.join_btn2', `Close`, `Close`, `Close`, `Close`],
    toast: [
      `챌린지 참여를 완료했어요.`,
      `Thanks for joing the July Challenge.`,
      's_portal_home.jul_challenge.toast',
      `Thanks for joing the July Challenge.`,
      `Thanks for joing the July Challenge.`,
      `Thanks for joing the July Challenge.`,
      `Thanks for joing the July Challenge.`,
    ],
    in_progress: [
      `7월 챌린지 도전 중`,
      `Challenge in progress`,
      's_portal_home.jul_challenge.in_progress',
      `Challenge in progress`,
      `Challenge in progress`,
      `Challenge in progress`,
      `Challenge in progress`,
    ],
    success: [
      `7월 챌린지 도전 성공!`,
      `Challenge success!`,
      's_portal_home.jul_challenge.success',
      `Challenge success!`,
      `Challenge success!`,
      `Challenge success!`,
      `Challenge success!`,
    ],
    success_modal_title: [
      `7월 챌린지 달성 혜택을 확인하세요!`,
      `Check out the rewards for July challenge!`,
      's_portal_home.jul_challenge.success_modal_title',
      `Check out the rewards for July challenge!`,
      `Check out the rewards for July challenge!`,
      `Check out the rewards for July challenge!`,
      `Check out the rewards for July challenge!`,
    ],
    success_modal: [
      <>
        7월 챌린지 도전 성공을 축하드려요.
        <br />
        <br />
        <b>달성 혜택:</b> 스타벅스 아메리카노 기프티콘 & 8월 링글 유료 LIVE 무료 참여 (Read with Me: Crying in H Mart)
        <br />
        <br />
        <b>유의 사항</b>
        <br />
        <div>
          <li className="li-tag-inside">
            스타벅스 아메리카노 기프티콘은 고객님 계정에 등록된 휴대폰 번호로 8.9(수) 오후 3시에 보내드립니다. 해외에
            거주할 경우, 5,000 포인트로 대체됩니다.
          </li>
          <li>8월 유료 라이브 정보는 이메일로 8.1(화)에 보내드립니다.</li>
        </div>
      </>,
      <>
        Congratulations on completing the July challenge!
        <br />
        <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      's_portal_home.jul_challenge.success_modal',
      <>
        Congratulations on completing the July challenge!
        <br />
        <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        Congratulations on completing the July challenge!
        <br />
        <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        Congratulations on completing the July challenge!
        <br />
        <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
      <>
        Congratulations on completing the July challenge!
        <br />
        <br />
        <b>Rewards:</b> Starbucks Americano giftcard & Free slot in the August Ringle LIVE paid webinar (Read with Me:
        Crying in H Mart)
        <br />
        <br />
        <b>Terms</b>
        <br />
        <div>
          <li className="li-tag-inside">
            The Starbucks Americano giftcard will be sent to the mobile phone number registered in your account on
            Wednesday, August 9 at 3pm. If you live overseas, this reward will be replaced with 5,000 points.
          </li>
          <li className="li-tag-inside">
            Information about the August Ringle LIVE paid webinar will be sent to your registered email account on
            August 1 (Tue).
          </li>
        </div>
      </>,
    ],
  },
  banner_title: [
    `매주 수업 듣기`,
    `Every Week`,
    's_portal_home.banner_title',
    `一週一次`,
    `一週一次`,
    `毎週レッスンを受ける`,
    `Lớp học hàng tuần`,
  ],
  banner_msg: (n) => [
    <>{n}주차 수업을 예약하세요.</>,
    <>Please book a week {n} lesson.</>,
    's_portal_home.banner_msg',
    <>请预约{n}週的课程</>,
    <>請預約{n}週的課程</>,
    <>{n}週目のレッスンを予約してください。</>,
    <>Đăng ký bài học cho tuần {n}.</>,
  ],
  banner_caption: (date_time) => [
    <>{date_time}에 수업권이 차감됩니다.</>,
    <>1 Credit expires on {date_time}</>,
    's_portal_home.banner_caption',
    <>请在{date_time}前完成课程</>,
    <>請在{date_time}前完成課程</>,
    <>{date_time}までにレッスンを完了してください。</>,
    <>Hoàn thành bài học trước {date_time}</>,
  ],
}
export const s_lesson_review = {
  three_month_summary: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.three_month_summary',
    `最近3个月的课程摘要`,
    `最近3個月的課程概要`,
    `最近3か月のレッスン統計まとめ`,
    `Tổng kết 3 tháng qua`,
  ],
  by_section: [
    `항목별 최신 결과`,
    `Latest Evaluations`,
    's_lesson_review.by_section',
    `各项目最新结果`,
    `各項目最新結果`,
    `項目別最新データ`,
    `Đánh giá mới nhất`,
  ],
  see_example: [
    `예시보기`,
    `View example`,
    's_lesson_review.see_example',
    `查看事例`,
    `查看例子`,
    `例を見る`,
    `Xem ví dụ`,
  ],
  no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_: (name, day) => [
    <>
      최근 3개월 동안 완료한 수업이 없습니다. <br />
      {name}님의 마지막 수업: {day}일 전
    </>,
    <>
      No lessons in the last 3 months.
      <br />
      Your last lesson was {day} days ago.
    </>,
    's_lesson_review.no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_',
    <>
      最近3个月没有完成的课程。
      <br />
      {name}用户的最后课程: {day}天前
    </>,
    <>
      最近3個月沒有完成的課程。
      <br />
      {name}最後一次上課：{day}天前
    </>,
    <>
      直近3か月間に完了したレッスンはありません。 <br />
      {name}さんの最後のレッスン: {day}日前
    </>,
    <>
      Không có buổi học nào trong 3 tháng qua.
      <br />
      Buổi học cuối cùng của bạn là {day} ngày trước.
    </>,
  ],
  are_you_curious_about_how_the_ringlers_do_lessons_: [
    <span>
      월별 수업 통계에서 <br />
      어떤 통계 데이터가 나오는지 궁금하신가요?
    </span>,
    <span>Sample Monthly Summary</span>,
    's_lesson_review.are_you_curious_about_how_the_ringlers_do_lessons_',
    <span>
      想知道每月课程统计中
      <br />
      会得出怎样的结果吗?
    </span>,
    <span>
      想知道每月課程統計
      <br />
      的結果嗎？
    </span>,
    <span>
      月別レッスン統計では
      <br />
      どのような統計データが出ますか？
    </span>,
    <span>Mẫu tóm tắt hằng tháng</span>,
  ],
  top_5_ringler_lesson_num: (num) => [
    <span>상위 20% 고객의 수업 수는 {num} 회입니다.</span>,
    <span>Top 20%: {num} lessons</span>,
    's_lesson_review.top_5_ringler_lesson_num',
    <span>前20%的学员, 课程量为{num}次。</span>,
    <span>前20%學生的課程數是{num}次。</span>,
    <span>上位20%のRinglerのレッスン数は {num} 回です。</span>,
    <span>Top 20%: {num} buổi học</span>,
  ],
  Summary: [`개요`, `Overview`, 's_lesson_review.Summary', `概要`, `概要`, `概要`, `Tổng quan`],
  lesson: [`1:1 수업`, `1:1 Lesson`, 's_lesson_review.lesson', `1:1课程`, `1:1課程`, `1on1レッスン`, `Buổi học 1:1`],
  work_in_progress: [
    `집계중`,
    `Work in Progress..`,
    's_lesson_review.work_in_progress',
    `统计中`,
    `統計中`,
    `集計中`,
    `Đang tiến hành..`,
  ],
  We_are_working_on_it: [
    `계산 중에 있습니다`,
    `This data is not yet available`,
    's_lesson_review.We_are_working_on_it',
    `计算中`,
    `計算中`,
    `計算中です。`,
    `Dữ liệu hiện chưa có`,
  ],
  review: [`복습`, `Review`, 's_lesson_review.review', `复习`, `複習`, `復習`, `Xem lại`],
  reviewed_yet: [
    `복습을 완료하지 않은 수업`,
    `Not reviewed`,
    's_lesson_review.reviewed_yet',
    `未完成复习的课程`,
    `未完成複習的課程`,
    `復習未完了`,
    `Buổi học bạn chưa xem lại`,
  ],
  reviews_lesson: [
    `복습을 완료한 수업`,
    `Reviewed`,
    's_lesson_review.reviews_lesson',
    `已完成复习的课程`,
    `已完成複習的課程`,
    `復習を完了`,
    `Buổi học bạn đã xem lại`,
  ],
  rate_lesson: [
    `수업 평가하기`,
    `Rate Lesson`,
    's_lesson_review.rate_lesson',
    `前往课程评价`,
    `評論課程`,
    `レッスンを評価する`,
    `Xếp hạng buổi học`,
  ],
  to_do_list: [
    `복습 목록`,
    `To-Do List`,
    's_lesson_review.to_do_list',
    `复习目录`,
    `複習清單`,
    `復習リスト`,
    `Những việc cần làm`,
  ],
  preparing: [`준비중`, `Preparing`, 's_lesson_review.preparing', `准备中`, `準備中`, `準備中`, `Đang chuẩn bị`],
  options: {
    study: {
      title: [
        `예습 내역`,
        `My Lesson Prep`,
        's_lesson_review.options.study.title',
        `预习内容`,
        `預習內容`,
        `予習履歴`,
        `Chuẩn bị Buổi học`,
      ],
    },
    record: {
      title: [
        `수업 녹음`,
        `Lesson Recording`,
        's_lesson_review.options.record.title',
        `课程录音`,
        `課程錄音`,
        `レッスン録音`,
        `Ghi âm buổi học`,
      ],
      desc: [
        `대화를 클릭하면 해당하는 녹음 파트를 바로 재생할 수 있습니다.`,
        `Click on the conversation to play the corresponding part of the recording.`,
        's_lesson_review.options.record.desc',
        `点击对话播放录音。`,
        `點擊對話播放錄音。`,
        `録音パートを再生するには、会話をクリックしてください。`,
        `Nhấp vào một cuộc đối thoại để phát bản ghi tương ứng.`,
      ],
    },
    analysis: {
      title: [
        `수업 분석`,
        `Lesson Analysis`,
        's_lesson_review.options.analysis.title',
        `课程分析`,
        `課程分析`,
        `レッスン分析`,
        `Phân tích buổi học`,
      ],
    },
    feedback: {
      title: [
        `튜터 피드백`,
        `Tutor’s Feedback`,
        's_lesson_review.options.feedback.title',
        `教师反馈`,
        `任課教師意見回饋`,
        `チューターフィードバック`,
        `Đánh giá của gia sư`,
      ],
    },
    note: {
      title: [
        `수업 노트`,
        `Lesson Notes`,
        's_lesson_review.options.note.title',
        `课程笔记`,
        `課堂筆記`,
        `レッスンノート`,
        `Ghi chú buổi học`,
      ],
    },
    speakingAnalysis: {
      title: [
        `스피킹 분석 차트`,
        `Speaking Analysis Charts`,
        's_lesson_review.options.speakingAnalysis.title',
        `口语分析图`,
        `口說分析圖表`,
        `スピーキング分析チャート`,
        `Biểu đồ phân tích nói`,
      ],
    },
    suggestion: {
      title: [
        `스피킹 분석 & 제안`,
        `Patterns & Suggestions`,
        's_lesson_review.options.suggestion.title',
        `口语分析 & 提案`,
        `口說分析 & 建議`,
        `スピーキング分析 & ご提案`,
        `Đặc điểm & Đề xuất`,
      ],
    },
    homework: {
      title: [`숙제`, `Homework`, 's_lesson_review.options.homework.title', `作业`, `作業`, `宿題`, `Bài tập`],
    },
  },
  feedback_notice: [
    `* 노란 선은 영역별 점수 상위 20% 구간의 평균값입니다.`,
    `*The yellow line indicates average scores of top 20% Ringlers.`,
    's_lesson_review.feedback_notice',
    `* 黄线为各方面分数前20%的区间平均值`,
    `* 黃線意指各領域前20%分數區間的平均值。`,
    `* 黄色い線は分野別点数の上位20%区間の平均値です。`,
    `*Đường màu vàng thể hiện điểm trung bình của top 20% học viên Ringle.`,
  ],
  completeMp3: [
    `수업 다시 듣기를 완료하셨습니다.`,
    `You’ve done listening to lesson recording.`,
    's_lesson_review.completeMp3',
    `已完成课程重听。`,
    `您已重新聽完課程。`,
    `レッスンをもう一度聴くを完了しました。`,
    `Bạn đã nghe xong ghi âm buổi học.`,
  ],
  voca_range: [
    `사용 단어 범위`,
    `Range of Words`,
    's_lesson_review.voca_range',
    `使用的单词范围`,
    `使用單字範圍`,
    `使用単語範囲`,
    `Vốn từ vựng`,
  ],
  wpm: [`말하기 속도`, `Speech Pace`, 's_lesson_review.wpm', `口语速度`, `口說速度`, `話すスピード`, `Tốc độ nói`],
  wpm_unit: [
    `단어/분 (WPM)`,
    `words/min(WPM)`,
    's_lesson_review.wpm_unit',
    `单词/分钟(WPM)`,
    `單字／分鐘（WPM）`,
    `単語/分 (WPM)`,
    `từ/phút (WPM)`,
  ],
  me: [`나`, `Me`, 's_lesson_review.me', `我`, `我`, `Me`, `Tôi`],
  tutor: [`튜터`, `Tutor`, 's_lesson_review.tutor', `任课教师`, `任課教師`, `Tutor`, `Gia sư`],
  ringler: [`상위 20%`, `Top 20%`, 's_lesson_review.ringler', `前20%`, `前20%`, `Top 20%`, `Top 20%`],
  fillerWord: [
    `필러워드 사용 빈도`,
    `Patterns & Suggestions`,
    's_lesson_review.fillerWord',
    `赘词使用频率`,
    `填補詞使用頻率`,
    `つなぎ言葉使用頻度`,
    `Đặc điểm & Đề xuất`,
  ],
  speaking_pattern: [
    `수업 중 사용한 표현 관련`,
    `Speaking Pattern & Suggestions`,
    's_lesson_review.speaking_pattern',
    `课程中使用的相关表达`,
    `上課時使用的用法相關內容`,
    `レッスン中に使用した表現関連`,
    `Đặc điểm & Đề xuất`,
  ],
  completeAnalysis: [
    `수업 분석 확인하기를 완료하셨습니다.`,
    `You’ve done checking the lesson analysis.`,
    's_lesson_review.completeAnalysis',
    `已完成课程分析确认。`,
    `您已確認完課程分析。`,
    `レッスン分析確認を完了しました。`,
    `Bạn đã kiểm tra xong phân tích buổi học.`,
  ],
  completeFeedback: [
    `튜터 피드백 확인을 완료했어요.`,
    `You’ve done checking and evaluating feedback.`,
    's_lesson_review.completeFeedback',
    `已确认教师反馈。`,
    `您已確認任課教師的意見回饋。`,
    `チューターフィードバック確認を完了しました。`,
    `Bạn đã kiểm tra và đánh giá nhận xét.`,
  ],
  completeAnalysisButton: [
    `수업 분석 확인하기 완료`,
    `Done checking lesson analysis`,
    's_lesson_review.completeAnalysisButton',
    `已确认课程分析`,
    `已確認完課程分析`,
    `レッスン分析確認完了`,
    `Kiểm tra xong phân tích buổi học`,
  ],
  completeFeedbackButton: [
    `튜터 피드백 확인 및 평가 완료`,
    `Done checking and evaluating feedback`,
    's_lesson_review.completeFeedbackButton',
    `已确认教师反馈及完成评价`,
    `已確認任課教師的意見回饋並完成評論`,
    `チューターフィードバック確認および評価完了`,
    `Kiểm tra và đánh giá xong nhận xét`,
  ],
  not_provide_feedback: [
    <>
      Writing Focused 주제 수업의 피드백은 수업 노트(Ringle Doc)에 포함되어 별도의 피드백 리포트는 제공되지 않습니다.
    </>,
    <>
      Feedback from Writing Focused Lessons is included in the Lesson Note(Ringle Doc), so a separate feedback report is
      not available.
    </>,
    's_lesson_review.not_provide_feedback',
    <>Writing Focused主题的课程反馈包含在课程笔记(Ringle Doc)中 因此不另行提供Feedback Report。</>,
    <>Writing Focused主題課程的意見回饋包含於課堂筆記（Ringle Doc）， 故不額外提供意見回饋報告書。</>,
    <>
      Writing Focusedテーマレッスンのフィードバックはレッスンノート(Ringle Doc)に含まれており、 別途のFeedback
      Reportは提供されません。
    </>,
    <>
      Nhận xét của Buổi học Tập trung Viết nằm trong Ghi chú Buổi học (Ringle Doc). Vì vậy không có báo cáo đánh giá
      riêng.
    </>,
  ],
  after_rating_the_lesson: [
    <>
      수업평가 완료 후에
      <br />
      복습하실 수 있습니다.
    </>,
    <>
      You can review a lesson
      <br />
      after rating the lesson.
    </>,
    's_lesson_review.after_rating_the_lesson',
    <>
      完成课程评价后
      <br />
      可进行复习。
    </>,
    <>
      完成課程評論後
      <br />
      可進行複習。
    </>,
    <>
      レッスン評価完了後に
      <br />
      復習することができます。
    </>,
    <>
      Bạn có thể xem lại buổi học
      <br />
      sau khi đánh giá.
    </>,
  ],
  my_lesson_count: [
    `내 수업 수`,
    `Your Lessons`,
    's_lesson_review.my_lesson_count',
    `我的课程数`,
    `我的課程數`,
    `マイレッスン数`,
    `Buổi học của bạn`,
  ],
  do_you_want_delete_uploaded_file: [
    `한 번 자료를 삭제 하시면 복원이 불가능합니다. 삭제하시겠습니까?`,
    `This action cannot be undone. Are you sure you want to delete this document?`,
    's_lesson_review.do_you_want_delete_uploaded_file',
    `材料删除后将无法复原。确定要删除吗?`,
    `一旦刪除資料將無法復原。確定要刪除嗎？`,
    `一度資料を削除すると復元できません。削除しますか？`,
    `Hành động này không thể hoàn tác. Bạn có chắc muốn xoá tài liệu này không?`,
  ],
  please_evaluate_feedback: [
    `이 피드백 보고서를 평가해주세요`,
    `Please evaluate this feedback report`,
    's_lesson_review.please_evaluate_feedback',
    `请评价此份回馈报告t`,
    `請評論這份意見回饋`,
    `このフィードバックレポートを評価してください。`,
    `Vui lòng đánh giá bản nhận xét này`,
  ],
  please_write_reason: [
    `평가에 대한 이유를 적어주세요.\n고객님의 소중한 의견은 튜터가 양질의 피드백 보고서를 제공하는데 도움이 됩니다.`,
    `Please write down the reason for your evaluation.\nYour honest feedback will be of tremendous help for the tutor to grow further.`,
    's_lesson_review.please_write_reason',
    `请写下评价的理由。\n 用户们的宝贵意见有助于教师们提供更为优质的回馈报告。`,
    `請針對評論寫下理由。\n學生的寶貴意見有助於任課教師提供優質的意見回饋。`,
    `評価の理由を入力してください。\nお客様の大切なご意見は、チューターが効果的なフィードバックレポートを提供するのに役立ちます。`,
    `Vui lòng viết lí do cho đánh giá của bạn. Những nhận xét chân thành của bạn sẽ cực kì hữu ích để giúp gia sư phát triển hơn nữa.`,
  ],
  score_criteria: [
    `점수 기준`,
    `Score Criteria`,
    's_lesson_review.score_criteria',
    `分数标准`,
    `分數標準`,
    `採点基準`,
    `Tiêu chí chấm điểm`,
  ],
  see_frequently_used_phrases: [
    `내가 사용한 구문`,
    `View your expressions`,
    's_lesson_review.see_frequently_used_phrases',
    `我使用的句型`,
    `我使用的句型`,
    `自分が使用した構文`,
    `Xem mẫu câu bạn dùng`,
  ],
  present: [`현재`, `today`, 's_lesson_review.present', `当前`, `目前`, `現在`, `hôm nay`],
  top_5: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5', `前20%`, `前20%`, `上位20%`, `Top 20%`],
  top_5_ringler: [
    `상위 20% 링글러`,
    `Top 20%`,
    's_lesson_review.top_5_ringler',
    `前20%的Ringle用户`,
    `前20%的Ringle用戶`,
    `上位20%のRingler`,
    `Top 20%`,
  ],
  modal_overall_this_month_40_20: [
    `해당 월에 완료된 40분 및 20분 수업 수를 분석합니다. 일주일 평균 수업 수는 완료된 총 수업 수를 주로 나누어 일주일 평균 몇 회의 수업을 완료하였는지 분석합니다.`,
    <span>Counts all lessons. Weekly average lessons are calculated on a monthly basis.&nbsp;</span>,
    's_lesson_review.modal_overall_this_month_40_20',
    `分析该月完成的20分钟及40分钟的课程数量。一周平均课程数是指完成的总课程数按周进行划分后得出的一周平均值。`,
    `分析該月完成的40分鐘及20分鐘課程數。一週平均課程數是完成的總課程數按週數平均分配，分析一週平均完成幾次課程。`,
    `その月に完了した40分および20分のレッスン数を分析します。1週間の平均レッスン数は、完了した合計レッスン数を週に分け、1週間に平均何回のレッスンを完了したかを分析します。`,
    <span>Đếm toàn bộ buổi học. Số buổi học trung bình mỗi tuần sẽ được tính theo từng tháng.&nbsp;</span>,
  ],
  modal_overall_this_month_top5: [
    `상위 20%: 상위 20%의 모집단은 해당 월에 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의 평균값입니다.`,
    <span>Top 20% calculated based on monthly completed lessons by all Ringle users.</span>,
    's_lesson_review.modal_overall_this_month_top5',
    `前20%: 前20%的对象仅限该月内完成的总课程数, 为总课程数前20%区间的平均值。`,
    `前20%：前20%族群僅限於該月完成的總課程數，為總課程數前20%區間的平均值。`,
    `上位20%: 上位20%の母集団は、その月に完了した合計レッスン数に限り、合計レッスン数の上位20%区間の平均値です。`,
    <span>Top 20% được tính dựa trên buổi học hoàn thành trong tháng của các học viên Ringle.</span>,
  ],
  modal_feedback_this_month: [
    <span>
      튜터 피드백 평균 점수는 아래 Fluency, Vocabulary, Grammar, Pronunication 네가지 항목의 평균 점수를 나타냅니다.
    </span>,
    `Shows monthly average score across four categories: Fluency, Vocabulary, Grammar, Pronunciation.`,
    's_lesson_review.modal_feedback_this_month',
    <span>教师反馈的平均分数为Fluency, Vocabulary, Grammar, Pronunication 四部分的平均分数</span>,
    <span>任課教師意見反饋的平均分數為下方Fluency、Vocabulary、Grammar、Pronunication 四項的平均分數。</span>,
    <span>
      チューターフィードバック平均点数は以下のFluency、Vocabulary、Grammar、Pronunicationの
      4つの項目の平均点数を表します。
    </span>,
    `Hiển thị điểm trung bình tháng dựa trên bốn kỹ năng: Độ trôi chảy, Từ vựng, Ngữ pháp, Phát âm.`,
  ],
  modal_wpm_this_month: [
    <span>
      해당 수업에서 내가 말한 속도입니다.
      <br /> 말하기 속도는 수업 시간 동안 내가 말한 단어 수를 기준으로 계산하며, 1분당 말한 단어 수입니다.
    </span>,
    `Total number of words uttered divided by the total number of minutes in lesson.`,
    's_lesson_review.modal_wpm_this_month',
    <span>
      该课程中本人的口语速度。
      <br />
      口语速度是指课程中以本人说出的单词数量为基准 于1分钟内所说的单词数量。
    </span>,
    <span>
      該課程中我的口說速度。
      <br />
      以上課時我說的單字數為準， 為每分鐘所說的單字數。
    </span>,
    <span>
      このレッスンであなたが話したスピードです。
      <br /> 話すスピードはレッスン中のにあなたが話した単語数を基準に計算され、 1分あたりの発話単語数を示します。
    </span>,
    `Tổng số từ đã nói chia ra tổng số phút của buổi học.`,
  ],
  modal_top5_wpm_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 수업에 한하며, 말하기 속도 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_wpm_selected',
    `前20%的对象仅限相同日期内完成的课程中, 口语速度为前20%区间的平均值。`,
    `前20%用戶僅限於相同日期完成的課程，為口說速度前20%區間的平均值。`,
    `上位20%の母集団データは同日内に完了したレッスンに限り、話すスピードの上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số các buổi học trên Ringle trong một ngày.`,
  ],
  modal_feedback_this_month_avg: [
    `튜터 피드백 평균 점수가 모든 사용자들 중 상위 20% 지점의 수치입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_feedback_this_month_avg',
    `教师反馈平均分数是指所有用户中位于前20%的数值。`,
    `任課教師意見反饋的平均分數為所有學生中位於前面20%的數值。`,
    `チューターフィードバック平均点数は全ユーザーのうち上位20%地点の数値です。`,
    `Hiển thị top 20% trong số các buổi học trên Ringle trong một ngày.`,
  ],
  modal_word_selected: [
    `해당 수업에서 내가 사용한 단어 수이며, 중복값을 제외합니다.`,
    `Number of different words you used in a lesson.`,
    's_lesson_review.modal_word_selected',
    `该课程中本人使用的单词数量, 不包含重复值。`,
    `該課程中我使用的單字數，扣除重複值。`,
    `このレッスンであなたが使用した単語数であり、重複値を除きます。`,
    `Số lượng những từ khác nhau sử dụng trong buổi học.`,
  ],
  modal_phrase_selected: [
    `해당 수업에서 내가 사용한 구문 수이며, 중복값을 제외합니다.`,
    `Number of different expressions you used in a lesson.`,
    's_lesson_review.modal_phrase_selected',
    `该课程中本人使用的句型数量, 不包含重复值。`,
    `該課程中我使用的句型數，扣除重複值。`,
    `このレッスンであなたが使用した構文数であり、重複値を除きます。`,
    `Những mẫu câu khác nhau sử dụng trong buổi học.`,
  ],
  modal_top5_word_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected',
    `前20%的对象仅限相同日期内完成的20分钟课程中, 使用单词数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的20分鐘課程，為使用的單字數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した20分レッスンに限り、使用単語数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 20 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_phrase_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected',
    `前20%的对象仅限相同日期内完成的20分钟课程中, 使用句型数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的20分鐘課程，為使用的句型數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した20分レッスンに限り、使用構文数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 20 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_word_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected40',
    `前20%的对象仅限相同日期内完成的40分钟课程中, 使用单词数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的40分鐘課程，為使用的單字數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した40分レッスンに限り、使用単語数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 40 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_phrase_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected40',
    `前20%的对象仅限相同日期内完成的40分钟课程中, 使用句型数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的40分鐘課程，為使用的句型數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した40分レッスンに限り、使用構文数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 40 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  times: [`회`, `Lessons`, 's_lesson_review.times', `次`, `次`, `回`, `Buổi học`],
  following_are_sample_data: [
    `하단의 차트와 통계 내용은 샘플 데이터입니다.`,
    `The charts & stats below are examples.`,
    's_lesson_review.following_are_sample_data',
    `下端图表及统计内容为样本数据。`,
    `下方的圖表與統計內容僅為樣本數據。`,
    `下のチャートと統計内容はサンプルデータです。`,
    `Ví dụ các biểu đồ & thống kê dưới.`,
  ],
  average_points: [
    `평균 점수`,
    `Avg. Score`,
    's_lesson_review.average_points',
    `平均分数`,
    `平均分數`,
    `平均点数`,
    `Điểm trung bình`,
  ],
  points: [`점`, ``, 's_lesson_review.points', `分`, `分`, `点`, ``],
  year_and_month_statistics: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[1]}, ${year}`,
    's_lesson_review.year_and_month_statistics',
    `${year}年${month}月`,
    `${year}年${month}月`,
    `${year}年 ${month}月`,
    `${getMonthName(month - 1)[1]}, ${year}`,
  ],
  year: (year) => [`${year}년`, `${year}`, 's_lesson_review.year', `${year}年`, `${year}年`, `${year}年`, `${year}`],
  to_You: (user) => [`${user}`, `You`, 's_lesson_review.to_You', `${user}`, `${user}`, `${user}`, `Bạn`],
  You_with_standard: (user) => [
    `${user}님은 총 수업 수 기준`,
    `Regarding total number of lessons, you are in the`,
    's_lesson_review.You_with_standard',
    `${user}用户, 以总课程数为基准`,
    `以總課程數為準，${user}`,
    `${user}さんは合計レッスン数基準`,
    `Xét theo tổng số buổi học, bạn đang ở`,
  ],
  are: [`님은`, `are`, 's_lesson_review.are', `属于`, `屬於`, `さんは`, `là`],
  apart_of: [`에 속합니다.`, ``, 's_lesson_review.apart_of', `之中。`, `。`, `に属しています。`, ``],
  tutors: [`튜터`, `Tutor`, 's_lesson_review.tutors', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  overview_modal_title: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.overview_modal_title',
    `最近3个月的课程信息摘要`,
    `最近3個月的課程資訊概要`,
    `最近3か月のレッスン統計まとめ`,
    `Tổng kết 3 tháng qua`,
  ],
  overview_modal_secTitle1: [
    `최근 3개월간 완료한 수업 데이터의 통계입니다.`,
    ``,
    's_lesson_review.overview_modal_secTitle1',
    `最近3个月间完成课程的数据统计`,
    `最近3個月期間完成的課程次數統計。`,
    `直近3か月間に完了したレッスンデータの統計です。`,
    ``,
  ],
  overview_modal_paragraph: [
    <span>
      수업 수: 40분 및 20분 수업의 총합
      <br />
      튜터 수: 함께한 튜터 수 (중복 튜터 제외)
      <br />
      교재 수: 학습한 교재 수 (중복 교재 제외)
      <br />
      <br />
      상위 20%: 상위 20%의 모집단은 각 링글러가 최근 3개월간 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의
      평균값입니다.
      <br />
    </span>,
    <span>Top 20%: Ringlers who have taken the highest number of lessons in a selected month (40min and 20min)</span>,
    's_lesson_review.overview_modal_paragraph',
    <span>
      课程数: 40分钟及20分钟课程总和
      <br />
      教师人数: 共同学习的教师数(重复教师除外)
      <br />
      教材数: 学习的教材数(重复教材除外)
      <br />
      <br />
      前20%: 前20%的对象是指各Ringle用户最近3个月间完成的 课程总和, 课程数前20%区间的平均值。
      <br />
    </span>,
    <span>
      課程數：40分鐘及20分鐘課程的總和
      <br />
      任課教師人數：一起學習的任課教師人數（扣除重複的任課教師）
      <br />
      教材數：學習的教材數（扣除重複的教材）
      <br />
      <br />
      前20%：前20%族群僅限於各Ringle用戶於最近3個月期間完成的 總課程數，為總課程數前20%區間的平均值。
      <br />
    </span>,
    <span>
      レッスン数: 受講した40分/20分レッスン数合計
      <br />
      チューター数: レッスンを受けたチューター数 (重複は除く)
      <br />
      教材数: 学習した教材数 (重複は除く)
      <br />
      <br />
      上位20%: 上位20%の母集団は各Ringlerが直近3か月間に完了した合計レッスン数に
      限り、合計レッスン数の上位20%区間の平均値です。
      <br />
    </span>,
    <span>Top 20%: học viên Ringle học nhiểu buổi nhất trong tháng được chọn (40 phút và 20 phút)</span>,
  ],
  overview_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview_modal_extra',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Những buổi học vừa hoàn thành trong 48 giờ có thể sẽ không hiển thị.`,
  ],
  overview2_modal_title: [
    `항목별 최신 결과`,
    `Latest Evaluations`,
    's_lesson_review.overview2_modal_title',
    `各项目最新结果`,
    `各項目最新結果`,
    `項目別最新データ`,
    `Đánh giá mới nhất`,
  ],
  overview2_modal_secTitle1: [
    `항목별 가장 최근 수치와 상위 20% 수치를 비교 분석합니다.`,
    `Compare your performance against top 20% Ringlers.`,
    's_lesson_review.overview2_modal_secTitle1',
    `比较分析各项目最新数值与前20%数值。`,
    `比較分析各項目的最新數值與前20%數值。`,
    `項目別の直近の成果データと上位20%の数値を比較分析します。`,
    `So sánh hiệu suất của bạn với top 20% học viên Ringle.`,
  ],
  overview2_modal_paragraph: [
    <span>
      항목별 수치는 동일 수업의 결과가 아닐 수 있으며, 데이터 분석이 완료된 가장 최근 수업의 결과입니다.
      <br />
      상위 20%의 모집단은 동일 날짜에 완료된 수업에 한합니다.
    </span>,
    <span>
      Each score may be calculated from the most relevant lesson for that statistic.
      <br />
      Top 20% is calculated independently for each category.
      <br />
    </span>,
    's_lesson_review.overview2_modal_paragraph',
    <span>
      各项目数值可能非同一课程的结果, 而是完成数据 分析的最新课程结果。
      <br />
      前20%的对象仅限于相同日期完成的课程。
    </span>,
    <span>
      各項目數值可能並非相同課程的結果，而是完成數據分析的 最新課程結果。
      <br />
      前20%族群僅限於相同日期完成的課程。
    </span>,
    <span>
      項目別数値は同日のレッスンの結果ではない場合があり、データ分析が完了した最も 直近のレッスンの結果です。
      <br />
      上位20%の母集団は同日内に完了したレッスンに限ります。
    </span>,
    <span>
      Mỗi điểm số được tính dựa trên buổi học liên quan nhất trong thống kê.
      <br />
      Top 20% được tính độc lập cho mỗi phần.
      <br />
    </span>,
  ],
  overview2_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview2_modal_extra',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Những buổi học vừa hoàn thành trong 48 giờ có thể sẽ không hiển thị.`,
  ],
  feedback_modal_extra: [
    `*수업 녹음을 설정하지 않아 분석이 불가능한 수업은 해당 페이지에서 조회되지 않습니다.`,
    `*Unrecorded lessons are not included in analysis.`,
    's_lesson_review.feedback_modal_extra',
    `*因未设置课程录音而无法分析的课程, 相关页面将不提供查询。`,
    `*無法於該頁面查詢由於未設定課程錄音而無法分析的課程。`,
    `*レッスン録音を設定していないレッスンは分析ができないため、レッスン統計ページでは照会できません。`,
    `*Những buổi học không thu âm sẽ không đưa vào phân tích.`,
  ],
  overall_statistics: [
    `월별 수업 통계`,
    `Monthly Summary`,
    's_lesson_review.overall_statistics',
    `每月课程统计`,
    `每月課程統計`,
    `月別レッスン統計`,
    `Tổng kết tháng`,
  ],
  overall_statistics_graph: [
    `월별 수업 수`,
    `Number of Lessons per Month`,
    's_lesson_review.overall_statistics_graph',
    `每月课程数`,
    `每月課程數`,
    `月別レッスン数`,
    `Số buổi học mỗi tháng`,
  ],
  lessons: [`수업 수`, `Lessons`, 's_lesson_review.lessons', `课程数`, `課程數`, `レッスン数`, `Buổi học`],
  _: [`회`, ``, 's_lesson_review._', `次`, `次`, `回`, ``],
  forty_min_used_word_count: [
    `40분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_word_count',
    `40分钟课程使用的单词数量`,
    `40分鐘課程使用的單字數`,
    `40分レッスンの使用単語数`,
    `Số từ dùng trong buổi học 40 phút`,
  ],
  twenty_min_used_word_count: [
    `20분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.twenty_min_used_word_count',
    `20分钟课程使用的单词数量`,
    `20分鐘課程使用的單字數`,
    `20分レッスンの使用単語数`,
    `Số từ dùng trong buổi học 20 phút`,
  ],
  my_used_word_count: [
    `내 단어 수`,
    `You`,
    's_lesson_review.my_used_word_count',
    `我的单词数量`,
    `我的單字數`,
    `あなたの単語数`,
    `Bạn`,
  ],
  used_word_count: [
    `사용 단어 수`,
    `Used Words`,
    's_lesson_review.used_word_count',
    `使用的单词数量`,
    `使用的單字數`,
    `使用単語数`,
    `Từ đã dùng`,
  ],
  forty_min_used_phrase_count: [
    `40분 수업 사용 구문 수`,
    `Expressions used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_phrase_count',
    `40分钟课程使用的句型数量`,
    `40分鐘課程使用的句型數`,
    `40分レッスンの使用構文数`,
    `Mẫu câu sử dụng trong buổi học 40 phút của bạn`,
  ],
  twenty_min_used_phrase_count: [
    `20분 수업 사용 구문 수`,
    `Expressions used in your 20 minute lessons`,
    's_lesson_review.twenty_min_used_phrase_count',
    `20分钟课程使用的句型数量`,
    `20分鐘課程使用的句型數`,
    `20分レッスンの使用構文数`,
    `Mẫu câu sử dụng trong buổi học 20 phút của bạn`,
  ],
  used_phrase_count: [
    `사용 구문 수`,
    `Used Expressions`,
    's_lesson_review.used_phrase_count',
    `使用的句型数量`,
    `使用的句型數`,
    `使用構文数`,
    `Mẫu câu đã dùng`,
  ],
  my_used_phrase_count: [
    `내 구문 수`,
    `You`,
    's_lesson_review.my_used_phrase_count',
    `我的句型数量`,
    `我的句型數`,
    `あなたの構文数`,
    `Bạn`,
  ],
  word_list: [
    `단어 리스트`,
    `Word List`,
    's_lesson_review.word_list',
    `单词列表`,
    `單字清單`,
    `単語リスト`,
    `Danh sách từ`,
  ],
  phrase_list: [
    `구문 리스트`,
    `Expressions List`,
    's_lesson_review.phrase_list',
    `句型列表`,
    `句型清單`,
    `構文リスト`,
    `Danh sách mẫu câu`,
  ],
  my_speaking_rate: [
    `내 속도`,
    `You`,
    's_lesson_review.my_speaking_rate',
    `我的语速`,
    `我的速度`,
    `あなたのスピード`,
    `Bạn`,
  ],
  speaking_rate: [
    `말하기 속도`,
    `Speech Pace`,
    's_lesson_review.speaking_rate',
    `口语速度`,
    `口說速度`,
    `話すスピード`,
    `Tốc độ nói`,
  ],
  convert_month_day_year: (month, day) => [
    `${month}월 ${day}일`,
    `${getMonthName(month - 1)[0]} ${day}`,
    's_lesson_review.convert_month_day_year',
    `${month}月 ${day}日`,
    `${month}月${day}日`,
    `${month}月 ${day}日`,
    `${getMonthName(month - 1)[0]} ${day}`,
  ],
  convert_year: (year) => [
    `${year}년`,
    `${year}`,
    's_lesson_review.convert_year',
    `${year}年`,
    `${year}年`,
    `${year}年`,
    `${year}`,
  ],
  packets: [`교재 수`, `Materials`, 's_lesson_review.packets', `教材数量`, `教材數`, `教材数`, `Tài liệu`],
  points_only_kr: [`내 점수`, `You`, 's_lesson_review.points_only_kr', `我的分数`, `我的分數`, `あなたの点数`, `Bạn`],
  wpm_only_kr: [`내 속도`, `You`, 's_lesson_review.wpm_only_kr', `我的语速`, `我的速度`, `あなたのスピード`, `Bạn`],
  words_only_kr: [
    `내 단어 수`,
    `You`,
    's_lesson_review.words_only_kr',
    `我的单词数量`,
    `我的單字數`,
    `あなたの単語数`,
    `Bạn`,
  ],
  phrases_only_kr: [
    `내 구문 수`,
    `You`,
    's_lesson_review.phrases_only_kr',
    `我的句型数量`,
    `我的句型數`,
    `あなたの構文数`,
    `Bạn`,
  ],
  period_kr_time_to_US: (time) => [
    <>{time}</>,
    <>{getMonthName(time.split('.')[1] - 1)[1] + ' ' + time.split('.')[2] + ', ' + '20' + time.split('.')[0]}</>,
    's_lesson_review.period_kr_time_to_US',
    <>{time}</>,
    <>{time}</>,
    <>{time}</>,
    <>{getMonthName(time.split('.')[1] - 1)[1] + ' ' + time.split('.')[2] + ', ' + '20' + time.split('.')[0]}</>,
  ],
  tutor_s_feedback_score: [
    `튜터 피드백 점수`,
    `Tutor's Feedback Score`,
    's_lesson_review.tutor_s_feedback_score',
    `教师反馈分数`,
    `任課教師意見回饋分數`,
    `チューターフィードバック点数`,
    `Điểm đánh giá từ gia sư`,
  ],
  used_expressions: [
    `사용 구문 수`,
    `Used Expressions`,
    's_lesson_review.used_expressions',
    `使用的句型数量`,
    `使用的句型數`,
    `使用構文数`,
    `Mẫu câu đã dùng`,
  ],
  top_5_: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5_', `前20%`, `前20%`, `上位20%`, `Top 20%`],
  in_the_top_5_period: [
    `상위 20%`,
    `in the Top 20%.`,
    's_lesson_review.in_the_top_5_period',
    `前20%`,
    `前20%`,
    `上位20%`,
    `ở trong Top 20%.`,
  ],
  avg_per_week: [
    `일주일 평균`,
    `Avg. Per Week`,
    's_lesson_review.avg_per_week',
    `一周平均`,
    `一週平均`,
    `1週間の平均`,
    `Trung bình mỗi tuần`,
  ],
  you_are_in_the_top_5_: (user) => [
    <div>
      {user}님은 <span className="text-yellow-500">상위 20%</span>에 속합니다.
    </div>,
    <div>
      You are in the <span className="text-yellow-500">top 20%.</span>
    </div>,
    's_lesson_review.you_are_in_the_top_5_',
    <div>
      用户{user}属于<span className="text-yellow-500">前20%</span>。
    </div>,
    <div>
      {user}屬於<span className="text-yellow-500">前20%</span>。
    </div>,
    <div>
      {user}さんは <span className="text-yellow-500">上位20%</span>に属しています。
    </div>,
    <div>
      Bạn đang ở trong <span className="text-yellow-500">top 20%.</span>
    </div>,
  ],
  lesson_prep_review: [
    `예습 및 복습`,
    `Lesson Prep & Review`,
    's_lesson_review.lesson_prep_review',
    `预习和复习`,
    `預習與複習`,
    `予習および復習`,
    `Chuẩn bị Buổi học & Xem lại`,
  ],
  average_prep_review_percentages_per_lesson: [
    `수업별 평균 완료율`,
    `Average % Completed Per Lesson`,
    's_lesson_review.average_prep_review_percentages_per_lesson',
    `各课程平均完成率`,
    `各課程平均完成率`,
    `レッスン毎平均完了率`,
    `% Trung bình Hoàn thành một Buổi học`,
  ],
  no_data_in_2020: [
    <span>
      예습 및 복습의 데이터는
      <br />
      2021년 1월의 수업부터 확인하실 수 있습니다
    </span>,
    `Lesson Prep. & Review Data is available from January of 2021.`,
    's_lesson_review.no_data_in_2020',
    <span>
      可从2021年1月课程起进行确认
      <br />
      预习及复习数据
    </span>,
    <span>
      您可確認2021年1月以後的
      <br />
      課程預習與複習數據。
    </span>,
    <span>
      予習および復習データは
      <br />
      2021年1月のレッスンから確認することができます。
    </span>,
    `Chuẩn bị Buổi học & Số liệu Đánh giá có từ tháng 1 năm 2021.`,
  ],
  lesson_distribution_based_on_completion: [
    `예습 및 복습 완료 수업 수`,
    `Lessons you Prepped & Reviewed`,
    's_lesson_review.lesson_distribution_based_on_completion',
    `完成预习及复习的课程数量`,
    `完成預習與複習的課程數`,
    `予習および復習完了レッスン数`,
    `Buổi học bạn Đã chuẩn bị & Đã xem lại`,
  ],
  prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons:
    [
      `평균 예습률 및 복습률은 해당 월에 완료된 수업들에 대해 각각 예습률 및 복습률 총합을 완료된 수업 수로 나눈 수치입니다.`,
      `Calculated by dividing lessons that you prepped and reviewed by total lessons you had on a monthly basis.`,
      's_lesson_review.prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons',
      `平均预习率及复习率是指当月完成课程的各预习率及复习率的总和除以总完成课程数的数值。`,
      `平均預習率與複習率係將該月完成的課程各自的平均預習率與複習率的總和除以完成的課程數所得到的數值。`,
      `平均予習率および復習率は、その月に完了したレッスンをそれぞれ予習率と復習率の合計を完了したレッスン数で割った数値です。`,
      `Được tính bằng cách chia số buổi học bạn đã chuẩn bị và xem lại với tổng số buổi học đã học trong tháng.`,
    ],
  these_statistics_represent_the_disparities_between_completion_rates_: [
    `해당 월에 완료된 수업들에 대해 예습만 완료, 복습만 완료, 예습과 복습 모두 완료 및 예습과 복습 모두 미완료한 수업 수를 제공합니다.`,
    `Shows how many lessons you have prepped, reviewed, or both on a monthly basis.`,
    's_lesson_review.these_statistics_represent_the_disparities_between_completion_rates_',
    `对于当月完成的课程, 将为您提供仅完成预习, 仅完成复习, 预习及复习均完成以及预习及复习均未完成的数值。`,
    `針對該月完成的課程，提供僅完成預習、僅完成複習、預習與複習全部完成及預習與複習全部未完成的課程數。`,
    `その月に完了したレッスンについて、予習のみ完了、復習のみ完了、予習と復習すべて完了および予習と復習すべて未完了のレッスン数を提供します。`,
    `Hiển thị số buổi học bạn đã chuẩn bị, xem lại, hoặc cả hai trong tháng.`,
  ],
  encouraging_more_lessons_on_ringle: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
    </span>,
    <span>You haven't taken any lessons this month.</span>,
    's_lesson_review.encouraging_more_lessons_on_ringle',
    <span>
      本月未进行Ringle课程。
      <br /> 只要坚持Ringle课程 英语实力一定会得到提升。
    </span>,
    <span>
      本月您未進行Ringle課程，
      <br />
      只有按時進行Ringle課程 才能提升英語實力。
    </span>,
    <span>
      この月はRingleのレッスンを受けませんでした。
      <br /> 地道にRingleのレッスンを受けることだけが英語 力を向上することができます。
    </span>,
    <span>Bạn chưa học buổi nào trong tháng này.</span>,
  ],
  encouraging_more_lessons_on_ringle_48_hour_exception: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
      <br /> 48시간 이내 완료된 수업은 조회되지 않을 수 있습니다.
    </span>,
    <span>
      You haven't taken any lessons this month.
      <br />
      Lessons completed within 48 hours may not be shown.
      <br />
    </span>,
    's_lesson_review.encouraging_more_lessons_on_ringle_48_hour_exception',
    <span>
      本月未进行Ringle课程。 <br /> 只要坚持Ringle课程 英语实力一定会得到提升。
      <br /> 可能无法查询到48小时内完成的课程。
    </span>,
    <span>
      本月您未進行Ringle課程，
      <br />
      只有按時進行Ringle課程 才能提升英語實力。
      <br />
      可能無法查詢48小時以內完成的課程。
    </span>,
    <span>
      この月は受講されたRingleレッスンはありませんでした。 <br /> 英語力の向上は地道なレッスン受講が鍵となります。
      <br /> 48時間以内に完了したレッスンは照会できないことがあります。
    </span>,
    <span>
      Bạn chưa học buổi nào trong tháng này.
      <br />
      Buổi học hoàn thành trong 48 giờ có thể không hiển thị.
      <br />
    </span>,
  ],
  showAllTutors: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllTutors',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllPackets: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPackets',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllWordss: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllWordss',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllPhrasess: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPhrasess',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  okay: [`확인`, `Ok`, 's_lesson_review.okay', `确认`, `確認`, `確認`, `Ok`],
  review_rate: [
    `평균 복습률`,
    `Review`,
    's_lesson_review.review_rate',
    `平均复习率`,
    `平均複習率`,
    `平均復習率`,
    `Xem lại`,
  ],
  prep_: [`평균 예습률`, `Prep`, 's_lesson_review.prep_', `平均预习率`, `平均預習率`, `平均予習率`, `Chuẩn bị`],
  lessson_distribution_based_on_completion: [
    `예습 및 복습 완료한 수업 수`,
    `Lesson Prepped & Reviewed`,
    's_lesson_review.lessson_distribution_based_on_completion',
    `完成预习及复习的课程数量`,
    `完成預習與複習的課程數`,
    `予習および復習が完了したレッスン数`,
    `Bài học Đã chuẩn bị & Đã xem lại`,
  ],
  prep_only: [
    `예습만 완료`,
    `Prep only`,
    's_lesson_review.prep_only',
    `仅完成预习`,
    `僅完成預習`,
    `予習のみ完了`,
    `Đã chuẩn bị`,
  ],
  review_only: [
    `복습만 완료`,
    `Rev. only`,
    's_lesson_review.review_only',
    `仅完成复习`,
    `僅完成複習`,
    `復習のみ完了`,
    `Đã xem lại`,
  ],
  both_completed: [
    `모두 완료`,
    `Both`,
    's_lesson_review.both_completed',
    `全部完成`,
    `全部完成`,
    `すべて完了`,
    `Cả hai`,
  ],
  both_incomplete: [
    `모두 미완료`,
    `Neither`,
    's_lesson_review.both_incomplete',
    `全部未完成`,
    `全部未完成`,
    `すべて未完了`,
    `Không cái nào`,
  ],
  total: [`총`, `Total`, 's_lesson_review.total', `总`, `一共`, `合計`, `Tổng cộng`],
  gae: [`개`, ``, 's_lesson_review.gae', `个`, `個`, `個`, ``],
  gae_words: [`개`, `words`, 's_lesson_review.gae_words', `个`, `個`, `個`, `từ`],
  gae_phrases: [`개`, `expressions`, 's_lesson_review.gae_phrases', `个`, `個`, `個`, `mẫu câu`],
  myung: [`명`, ``, 's_lesson_review.myung', `名`, `名`, `名`, ``],
  _3_tutors_you_did_a_lesson_with_the_most: [
    `제일 많이 함께한 튜터 3명`,
    `3 Tutors you had the most lessons with`,
    's_lesson_review._3_tutors_you_did_a_lesson_with_the_most',
    `选择次数最多的3名教师`,
    `最常一起上課的3名任課教師`,
    `最も多くレッスンを受けたチューター3名`,
    `3 gia sư bạn học cùng nhiều nhất`,
  ],
  _3_packets_you_did_a_lesson_with_the_most: [
    `제일 많이 학습한 교재 3개`,
    `3 Materials you selected the most`,
    's_lesson_review._3_packets_you_did_a_lesson_with_the_most',
    `学习次数最多的3个教材`,
    `最常使用的3個教材`,
    `最も多く学習した教材3つ`,
    `3 tài liệu bạn chọn nhiều nhất`,
  ],
  convert_num_to_month: (num) => [
    `${num}월`,
    `${getMonthName(num - 1)[1]}`,
    's_lesson_review.convert_num_to_month',
    `${num}月`,
    `${num}月`,
    `${num}月`,
    `Tháng ${num}`,
  ],
  convert_nums_to_month_and_year: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[0]}, ${year}`,
    's_lesson_review.convert_nums_to_month_and_year',
    `${year}年${month}月`,
    `${year}年${month}月`,
    `${year}年 ${month}月`,
    `Tháng ${month} năm ${year}`,
  ],
  last_3_months: [
    `최근 3개월`,
    `Last 3 months`,
    's_lesson_review.last_3_months',
    `最近3个月`,
    `最近3個月`,
    `最近3か月`,
    `3 tháng qua`,
  ],
  lessons_within_last_3_months: [
    `최근 3개월 수업 수`,
    `Lessons of the last 3 months`,
    's_lesson_review.lessons_within_last_3_months',
    `最近3个月的课程数量`,
    `最近3個月的課程數`,
    `最近3か月のレッスン数`,
    `Buổi học trong 3 tháng qua`,
  ],
  lessons_within_last_3_months_sample_data: [
    <span>
      최근 3개월 수업 수<span className="text-muted font-size-sm"> 샘플 데이터 입니다.</span>
    </span>,
    <span>
      Lessons of the last 3 months
      <span className="text-muted font-size-sm"> sample chart</span>
    </span>,
    's_lesson_review.lessons_within_last_3_months_sample_data',
    <span>
      最近3个月课程数量的
      <span className="text-muted font-size-sm"> 样本数据。</span>
    </span>,
    <span>
      最近3個月課程數的
      <span className="text-muted font-size-sm">樣本數據。</span>
    </span>,
    <span>
      最近3か月のレッスン数
      <span className="text-muted font-size-sm"> サンプルデータです。</span>
    </span>,
    <span>
      Buổi học trong 3 tháng qua
      <span className="text-muted font-size-sm"> sample chart</span>
    </span>,
  ],
  last_6_months: [
    `최근 6개월`,
    `Last 6 months`,
    's_lesson_review.last_6_months',
    `最近6个月`,
    `最近6個月`,
    `直近6か月`,
    `6 tháng qua`,
  ],
  last_1_year: [`최근 1년`, `Last 1 year`, 's_lesson_review.last_1_year', `最近1年`, `最近1年`, `直近1年`, `1 năm qua`],
  avg_score: [
    `평균 점수`,
    `Avg. Score`,
    's_lesson_review.avg_score',
    `平均分数`,
    `平均分數`,
    `平均点数`,
    `Điểm trung bình`,
  ],
  feedback_avg_score: [
    `튜터 피드백 평균 점수`,
    `Your Avg. Tutor Feedback Score`,
    's_lesson_review.feedback_avg_score',
    `教师反馈平均分数`,
    `任課教師意見回饋的平均分數`,
    `チューターフィードバック平均点数`,
    `Điểm trung bình đánh giá của gia sư`,
  ],
  feedback_avg: [
    `튜터 피드백: 평균`,
    `Tutor Feedback Score`,
    's_lesson_review.feedback_avg',
    `教师反馈: 平均`,
    `任課教師意見回饋：平均`,
    `チューターフィードバック: 平均`,
    `Điểm đánh giá của gia sư`,
  ],
  tutor_and_packet_for_this_lesson: [
    `이 수업의 튜터와 교재`,
    `Tutor and Materials for This Lesson`,
    's_lesson_review.tutor_and_packet_for_this_lesson',
    `此课程的教师及教材`,
    `此課程的任課教師與教材`,
    `このレッスンのチューターと教材`,
    `Gia sư và Tài liệu cho Buổi học này`,
  ],
  packet: [`교재`, `Materials`, 's_lesson_review.packet', `教材`, `教材`, `教材`, `Tài liệu`],
  packetSingular: [`교재`, `Materials`, 's_lesson_review.packetSingular', `教材`, `教材`, `教材`, `Tài liệu`],
  detailed_score: [
    `영역별 점수`,
    `Score by Area`,
    's_lesson_review.detailed_score',
    `各方面分数`,
    `各領域分數`,
    `分野別点数`,
    `Điểm từng phần`,
  ],
  my: [`내`, `Your`, 's_lesson_review.my', `本人`, `我的`, `あなたの`, `Của bạn`],
  last_10_lessons: [
    `최근 수업 10회`,
    `Last 10 Lessons`,
    's_lesson_review.last_10_lessons',
    `最近10次课程`,
    `最近10次課程`,
    `直近10回のレッスン`,
    `10 buổi học qua`,
  ],
  last_30_lessons: [
    `최근 수업 30회`,
    `Last 30 Lessons`,
    's_lesson_review.last_30_lessons',
    `最近30次课程`,
    `最近30次課程`,
    `直近30回のレッスン`,
    `30 buổi học qua`,
  ],
  last_50_lessons: [
    `최근 수업 50회`,
    `Last 50 Lessons`,
    's_lesson_review.last_50_lessons',
    `最近50次课程`,
    `最近50次課程`,
    `直近50回のレッスン`,
    `50 buổi học qua`,
  ],
  last_100_lessons: [
    `최근 수업 100회`,
    `Last 100 Lessons`,
    's_lesson_review.last_100_lessons',
    `最近100次课程`,
    `最近100次課程`,
    `直近100回のレッスン`,
    `100 buổi học qua`,
  ],
  my_score: [`내 점수`, `You`, 's_lesson_review.my_score', `我的分数`, `我的分數`, `あなたの点数`, `Bạn`],
  number_of_used_words: [
    `사용 단어 수`,
    `Used Words`,
    's_lesson_review.number_of_used_words',
    `使用的单词数量`,
    `使用的單字數`,
    `使用単語数`,
    `Từ đã dùng`,
  ],
  _40_min_lesson: [
    `40분 수업`,
    `40min`,
    's_lesson_review._40_min_lesson',
    `40分钟课程`,
    `40分鐘課程`,
    `40分レッスン`,
    `40 phút`,
  ],
  all_lesson: [
    `전체 수업`,
    `Total`,
    's_lesson_review.all_lesson',
    `全部课程`,
    `全部課程`,
    `すべてのレッスン`,
    `Tổng cộng`,
  ],
  packets_info1: [
    `해당 월에 학습한 교재 수를 분석하며, 중복 교재는 제외합니다.`,
    `Counts unique materials used in the selected month.`,
    's_lesson_review.packets_info1',
    `分析当月学习的教材数量, 重复教材除外。`,
    `分析該月學習的教材數，扣除重複的教材。`,
    `その月に学習した教材数の合計で、重複教材は除きます。`,
    `Đếm các tài liệu khác nhau bạn sử dụng trong tháng được chọn.`,
  ],
  packets_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.packets_info2',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Buổi học hoàn thành trong 48 giờ có thể không hiển thị.`,
  ],
  tutor_info1: [
    `해당 월에 함께한 튜터 수를 분석하며, 중복 튜터는 제외합니다.`,
    `Counts number of different tutors you had lessons with on a monthly basis.`,
    's_lesson_review.tutor_info1',
    `分析当月一起学习的教师人数, 重复教师除外。`,
    `分析該月一起學習的任課教師人數，扣除重複的任課教師。`,
    `その月にレッスンを受けたチューター数の合計で、重複チューターは除きます。`,
    `Đếm số lượng gia sư khác nhau bạn học cùng trong một tháng.`,
  ],
  tutor_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.tutor_info2',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Buổi học hoàn thành trong 48 giờ có thể không hiển thị.`,
  ],
  tutors_number: [
    `튜터 수`,
    `Tutors`,
    's_lesson_review.tutors_number',
    `教师人数`,
    `任課教師人數`,
    `チューター数`,
    `Gia sư`,
  ],
  see_review_list: [
    `복습 목록 전체보기`,
    `View all`,
    's_lesson_review.see_review_list',
    `查看全部复习目录`,
    `查看所有複習清單`,
    `復習リストをすべて見る`,
    `Xem tất cả`,
  ],
  no_completed_lesson: [
    `현재  완료한 수업이 없습니다.`,
    `You haven't completed any lessons.`,
    's_lesson_review.no_completed_lesson',
    `当前没有完成的课程。`,
    `目前無已完成的課程。`,
    `現在完了したレッスンはありません。`,
    `Bạn chưa hoàn thành buổi học nào.`,
  ],
  recorded_script: [
    `녹음파일`,
    `Recording file`,
    's_lesson_review.recorded_script',
    `录音文件`,
    `錄音檔`,
    `録音ファイル`,
    `File thu âm`,
  ],
  in_review_page_what_you_can_do: [
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        복습페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 완료한 수업의 스크립트 및 분석 내용,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        튜터의 피드백 리포트 등을 확인하거나 수업을 다시 들을 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can replay lesson recording, read tutor feedback, or check AI Analysis under
      </Div>
      <Div spanTag textGray700 fontSize18 fontBold ml4>
        Lesson Review
      </Div>
    </>,
    's_lesson_review.in_review_page_what_you_can_do',
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        于复习页面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        可对完成的课程脚本及分析内容,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        教师的Feedback Report等进行确认或重听课程。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        可於複習頁面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        確認完成的課程腳本及分析內容、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        任課教師的意見回饋報告書等，或再次聆聽課程。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        復習ページ
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        では完了したレッスンのスクリプトおよび分析内容、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        チューターのFeedback Reportなどを確認したりレッスンをもう一度聴いたりすることができます。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        Bạn có thể nghe lại thu âm, đọc đánh giá của gia sư hoặc kiểm tra phân tích AI ở phần
      </Div>
      <Div spanTag textGray700 fontSize18 fontBold>
        Xem lại buổi học
      </Div>
    </>,
  ],
  ringle_usage: [
    `링글 사용법`,
    `How Ringle works`,
    's_lesson_review.ringle_usage',
    `Ringle使用方法`,
    `Ringle使用方法`,
    `Ringleの使用方法`,
    `Cách Ringle hoạt động`,
  ],
  zoom_usage: [
    `화상 프로그램 (Zoom) 설치 가이드`,
    `How to Install Zoom`,
    's_lesson_review.zoom_usage',
    `视频程序(Zoom)安装指南`,
    `視訊軟體（Zoom）安裝指南`,
    `ビデオ会議アプリ (Zoom) インストールガイド`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  no_noshow_lesson: [
    `노쇼한 수업이 없습니다.`,
    `You showed up to all lessons.`,
    's_lesson_review.no_noshow_lesson',
    `没有旷课记录。`,
    `無未出現的課程。`,
    `無断欠席したレッスンはありません。`,
    `Bạn tham gia tất cả buổi học.`,
  ],
  tooltip: [
    `다운로드할 수업 녹음 파일이 없습니다.`,
    `There are no downloadable audio files.`,
    's_lesson_review.tooltip',
    `没有可下载的课程录音。`,
    `沒有可下載的課程錄音檔。`,
    `ダウンロードするレッスン録音ファイルはありません。`,
    `Không có tập âm thanh nào có thể tải.`,
  ],
  teens_complete_feedback: [
    `튜터 피드백 확인하기 완료`,
    `Done checking feedback`,
    's_lesson_review.teens_complete_feedback',
    `已完成教师反馈的确认`,
    `已確認任課教師的意見回饋`,
    `チューターフィードバック確認完了`,
    `Đã xem xong đánh giá`,
  ],
  caf_check: [
    `CAF 진단 확인하기`,
    `View CAF analysis`,
    's_lesson_review.caf_check',
    `View CAF analysis`,
    `View CAF analysis`,
    `View CAF analysis`,
    `View CAF analysis`,
  ],
  caf_tab: [
    `CAF 진단`,
    `CAF Analysis`,
    's_lesson_review.caf_tab',
    `CAF Analysis`,
    `CAF Analysis`,
    `CAF Analysis`,
    `CAF Analysis`,
  ],
  caf_menu: [
    `CAF 진단`,
    `CAF Analysis`,
    's_lesson_review.caf_menu',
    `CAF Analysis`,
    `CAF Analysis`,
    `CAF Analysis`,
    `CAF Analysis`,
  ],
  caf_msg: {
    noshow: [
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `CAF analysis is unavailable for no-show lessons.`,
      's_lesson_review.caf_msg.noshow',
      `CAF analysis is unavailable for no-show lessons.`,
      `CAF analysis is unavailable for no-show lessons.`,
      `CAF analysis is unavailable for no-show lessons.`,
      `CAF analysis is unavailable for no-show lessons.`,
    ],
    noaudio: [
      `해당 수업은 CAF 진단이 제공되지 않습니다.

수업 전 [수업 방식 설정] 메뉴에서 [수업 녹음 하기 > 진단 받기]를 설정하면
다음 수업부터 CAF 진단을 확인할 수 있습니다.`,
      `CAF analysis is unavailable for this lesson.

You can receive CAF analysis from the next lesson by going to the [Set Lesson Style] menu and selecting [Analyze] under the [Record] tab before the lesson.`,
      's_lesson_review.caf_msg.noaudio',
      `CAF analysis is unavailable for this lesson.

You can receive CAF analysis from the next lesson by going to the [Set Lesson Style] menu and selecting [Analyze] under the [Record] tab before the lesson.`,
      `CAF analysis is unavailable for this lesson.

You can receive CAF analysis from the next lesson by going to the [Set Lesson Style] menu and selecting [Analyze] under the [Record] tab before the lesson.`,
      `CAF analysis is unavailable for this lesson.

You can receive CAF analysis from the next lesson by going to the [Set Lesson Style] menu and selecting [Analyze] under the [Record] tab before the lesson.`,
      `CAF analysis is unavailable for this lesson.

You can receive CAF analysis from the next lesson by going to the [Set Lesson Style] menu and selecting [Analyze] under the [Record] tab before the lesson.`,
    ],
    freetopic: [
      `라이팅 중심 수업에는 CAF 진단이 제공되지 않습니다.`,
      `CAF analysis is unavailable for writing-focused lessons.`,
      's_lesson_review.caf_msg.freetopic',
      `CAF analysis is unavailable for writing-focused lessons.`,
      `CAF analysis is unavailable for writing-focused lessons.`,
      `CAF analysis is unavailable for writing-focused lessons.`,
      `CAF analysis is unavailable for writing-focused lessons.`,
    ],
    prep: [
      `CAF 진단 준비중입니다.`,
      `CAF analysis is being prepared.`,
      's_lesson_review.caf_msg.prep',
      `CAF analysis is being prepared.`,
      `CAF analysis is being prepared.`,
      `CAF analysis is being prepared.`,
      `CAF analysis is being prepared.`,
    ],
    gotoapp: [
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `The CAF analysis can only be viewed on the Ringle app.`,
      's_lesson_review.caf_msg.gotoapp',
      `The CAF analysis can only be viewed on the Ringle app.`,
      `The CAF analysis can only be viewed on the Ringle app.`,
      `The CAF analysis can only be viewed on the Ringle app.`,
      `The CAF analysis can only be viewed on the Ringle app.`,
    ],
  },
  after_rating_the_lesson_pointback: (point) => [
    <>
      새로 온 튜터와 수업하셨군요! 수업 후기를 남기면 <span className="text-[#FF6712]">{point}P</span>를 바로 받을 수
      있어요!
    </>,
    <>
      How was your lesson with the Rookie Tutor? Leave a review and receive{' '}
      <span className="text-[#FF6712]">{point}</span>P!
    </>,
    's_lesson_review.after_rating_the_lesson_pointback',
    <>
      和新教师一起完成了第一堂课！提交课后评价，即可获得 <span className="text-[#FF6712]">{point}P</span>！
    </>,
    <>
      和新教師一起完成了第一課！撰寫課後評價，即可獲得 <span className="text-[#FF6712]">{point}P</span>！
    </>,
    <>
      新しく来たチューターとレッスンしましたね！レッスンのレビューを残していただけたら{' '}
      <span className="text-[#FF6712]">{point}</span>Pをすぐに受け取れます！
    </>,
    <>
      Buổi học với gia sư mới thế nào? Hãy để lại đánh giá và nhận điểm <span className="text-[#FF6712]">{point}</span>!
    </>,
  ],
  lesson_recoding_msg: {
    msg: [
      `수업 분석과 CAF 진단은 무엇이 다른가요?`,
      `How is CAF analysis different?`,
      's_lesson_review.lesson_recoding_msg.msg',
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
    ],
    btn: [
      `자세히 보기`,
      `See details`,
      's_lesson_review.lesson_recoding_msg.btn',
      `See details`,
      `See details`,
      `See details`,
      `See details`,
    ],
    modal_title: [
      `수업 분석과 CAF 진단은 무엇이 다른가요?`,
      `How is CAF analysis different?`,
      's_lesson_review.lesson_recoding_msg.modal_title',
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
      `How is CAF analysis different?`,
    ],
    modal_body: [
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF 진단은 링글에서 새롭게 제작한 AI 엔진으로 기존 수업 분석보다 개선된 기능입니다.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          기존 수업 녹음의 텍스트보다 정확하게 인식이 가능해졌고, 기존 수업 분석보다 다각도에서 분석이 진행됩니다.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>베타 서비스가 종료된 후 하반기에 기존 기능들과 병합될
          예정입니다.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>CAF 진단에 대해 구체적인 가이드가 궁금하시다면?
        </p>
      </div>,
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis uses Ringle's new AI engine to provide more advanced insights.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis employs improved speech recognition and provides a more multidimensional analysis of your
          performance.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>After the beta service, CAF analysis will be merged with
          lesson analysis later this year.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>Want to know more about CAF analylsis?
        </p>
      </div>,
      's_lesson_review.lesson_recoding_msg.modal_body',
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis uses Ringle's new AI engine to provide more advanced insights.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis employs improved speech recognition and provides a more multidimensional analysis of your
          performance.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>After the beta service, CAF analysis will be merged with
          lesson analysis later this year.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>Want to know more about CAF analylsis?
        </p>
      </div>,
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis uses Ringle's new AI engine to provide more advanced insights.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis employs improved speech recognition and provides a more multidimensional analysis of your
          performance.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>After the beta service, CAF analysis will be merged with
          lesson analysis later this year.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>Want to know more about CAF analylsis?
        </p>
      </div>,
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis uses Ringle's new AI engine to provide more advanced insights.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis employs improved speech recognition and provides a more multidimensional analysis of your
          performance.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>After the beta service, CAF analysis will be merged with
          lesson analysis later this year.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>Want to know more about CAF analylsis?
        </p>
      </div>,
      <div className="pl-[10px]">
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis uses Ringle's new AI engine to provide more advanced insights.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>
          CAF analysis employs improved speech recognition and provides a more multidimensional analysis of your
          performance.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>After the beta service, CAF analysis will be merged with
          lesson analysis later this year.
        </p>
        <p className="relative">
          <span className="absolute left-[-10px] top-0">•</span>Want to know more about CAF analylsis?
        </p>
      </div>,
    ],
    modal_btn: [
      `확인`,
      `Got it`,
      's_lesson_review.lesson_recoding_msg.modal_btn',
      `Got it`,
      `Got it`,
      `Got it`,
      `Got it`,
    ],
    modal_body2: [
      `CAF 진단 가이드`,
      `See "Introduction to CAF Analysis`,
      's_lesson_review.lesson_recoding_msg.modal_body2',
      `See "Introduction to CAF Analysis`,
      `See "Introduction to CAF Analysis`,
      `See "Introduction to CAF Analysis`,
      `See "Introduction to CAF Analysis`,
    ],
  },
}
export const s_classroom = {
  help: [`지원 요청`, `Help`, 's_classroom.help', `在线帮助`, `即時Help`, `リアルタイムHelp`, `Hỗ trợ`],
  audio_problem: [
    `오디오 연결 오류`,
    `Audio issue`,
    's_classroom.audio_problem',
    `听不到声音`,
    `聽不到聲音`,
    `声が聞こえません`,
    `Vấn đề về âm thanh`,
  ],
  video_problem: [
    `Zoom 비디오 오류`,
    `Video issue`,
    's_classroom.video_problem',
    `看不到视频`,
    `看不到影像`,
    `ビデオが映りません`,
    `Vấn đề về video`,
  ],
  write_your_message: [
    `메시지를 입력해주세요`,
    `Write your message`,
    's_classroom.write_your_message',
    `请输入信息`,
    `請輸入訊息`,
    `メッセージを入力してください`,
    `Viết lời nhắn của bạn`,
  ],
  docs_problem: [
    `Ringle Docs 오류`,
    `Ringle Docs issue`,
    's_classroom.docs_problem',
    `看不到Ringle Docs`,
    `看不到Ringle Docs`,
    `Ringle Docsが見えません`,
    `Vấn đề về Ringle Docs`,
  ],
  zoom_problem: [
    `Zoom 실행 오류`,
    `Cannot open Zoom`,
    's_classroom.zoom_problem',
    `Zoom无法运行`,
    `無法執行Zoom`,
    `Zoomが起動しません`,
    `Không thể mở Zoom`,
  ],
  zoom_guide: [
    `Zoom 가이드`,
    `Zoom Guide`,
    's_classroom.zoom_guide',
    `Zoom指南`,
    `Zoom指南`,
    `Zoomガイド`,
    `Hướng dẫn dùng Zoom`,
  ],
  minimize_room_drage_it_here: [
    `Zoom을 최소화하여 이곳에 놓아주세요`,
    `Minimize ‘Zoom’ and drag it here.`,
    's_classroom.minimize_room_drage_it_here',
    `请将Zoom最小化放置于这里`,
    `請將Zoom最小化後放置於這裡。`,
    `Zoomを最小化してここにペーストしてください。`,
    `Thu nhỏ ‘Zoom’ và kéo vào đây.`,
  ],
  click_on_join_audio_button: [
    `소리가 들리지 않을 경우, Zoom화면 왼쪽 하단의 '오디오 연결' 또는 'Join audio' 아이콘을 눌러 [컴퓨터 오디오로 참가] 버튼을 클릭해주세요.`,
    `If you cannot hear the audio, place your mouse cursor on Zoom. Click on the "Join audio" button to start.`,
    's_classroom.click_on_join_audio_button',
    `如果没有声音, 请将鼠标移动至Zoom上方。点击左下方'参与视频'即'Join audio'图标进行尝试。`,
    `如果聽不到聲音，請將滑鼠移至Zoom上方。點擊左下方「加入音訊」或「Join audio」圖示進行。`,
    `音が聞こえない場合は、Zoomにマウスをのせてください。左下の「オーディオに参加」または「Join audio」アイコンを押してください。`,
    `Nếu bạn không thể nghe âm thanh, đưa chuột về màn hình Zoom. Chọn "Join audio" để nghe.`,
  ],
  plz_click_if_u_cannot_open_zoom: [
    `Zoom이 실행되지 않을때는 아래의 버튼을 클릭해주세요`,
    `If you cannot open Zoom, please click on the link below.`,
    's_classroom.plz_click_if_u_cannot_open_zoom',
    `Zoom无法运行时, 请点击下方按钮。`,
    `無法執行Zoom時，請點擊下方按鈕。`,
    `Zoomが開かない場合は下のボタンをクリックしてください。`,
    `Nếu bạn không thể mở Zoom, nhấn vào link dưới đây.`,
  ],
  zoom_force_run: [
    `Zoom 강제 실행`,
    `Force zoom to run`,
    's_classroom.zoom_force_run',
    `Zoom强制执行`,
    `強制執行Zoom`,
    `Zoom強制実行`,
    `Bắt buộc khởi chạy Zoom`,
  ],
  lesson_style: [
    `수업 방식`,
    `Lesson Style`,
    's_classroom.lesson_style',
    `课程形式`,
    `上課方式`,
    `レッスン方式`,
    `Hình thức học`,
  ],
  my_answer: [
    `내 답변`,
    `My Response`,
    's_classroom.my_answer',
    `我的回答`,
    `我的回覆`,
    `あなたの回答`,
    `Phản hồi của tôi`,
  ],
  leave_classroom: [
    `수업 나가기`,
    `Exit`,
    's_classroom.leave_classroom',
    `离开课程`,
    `離開課程`,
    `レッスンから退室する`,
    `Thoát ra`,
  ],
  leave_classroom_confirm: [
    `수업 화면에서 나가시겠습니까?`,
    `Are you sure you want to exit?`,
    's_classroom.leave_classroom_confirm',
    `您确定要离开课程画面吗?`,
    `確定要離開課程畫面嗎？`,
    `レッスン画面を閉じますか？`,
    `Bạn có chắc muốn thoát ra?`,
  ],
  popup_block_title: [
    `팝업 허용 요청`,
    `Please allow pop-ups on this page.`,
    's_classroom.popup_block_title',
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
  ],
  popup_block_subtitle: [
    `수업 프로그램인 Zoom을 실행하기 위해 팝업을 허용해주세요`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    's_classroom.popup_block_subtitle',
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
  ],
  modal_title: [
    `Zoom 화상수업 시작하기`,
    `Connecting to Zoom for Your Lesson`,
    's_classroom.modal_title',
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
  ],
  modal_desc: [
    `곧 자동으로 열리는 Zoom 오디오는 이렇게 연결할 수 있어요.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    's_classroom.modal_desc',
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
  ],
  btn_1: [
    `다시 보지 않기`,
    `Do not show again`,
    's_classroom.btn_1',
    `Do not show again`,
    `Do not show again`,
    `Do not show again`,
    `Do not show again`,
  ],
  btn_2: [`확인`, `Got it`, 's_classroom.btn_2', `Got it`, `Got it`, `Got it`, `Got it`],
  modal_zoom_title: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    's_classroom.modal_zoom_title',
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
  ],
  modal_zoom_tap_1: [
    `소리가 들리지 않을 때`,
    `No sound is coming out`,
    's_classroom.modal_zoom_tap_1',
    `No sound is coming out`,
    `No sound is coming out`,
    `No sound is coming out`,
    `No sound is coming out`,
  ],
  modal_zoom_tap_2: [
    `Zoom이 실행되지 않을 때`,
    `Zoom won't launch`,
    's_classroom.modal_zoom_tap_2',
    `Zoom won't launch`,
    `Zoom won't launch`,
    `Zoom won't launch`,
    `Zoom won't launch`,
  ],
  modal_zoom_desc_1: [
    <>
      Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
      <br />
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>{' '}
      에서 카메라와 오디오를 테스트 할 수 있습니다.
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    's_classroom.modal_zoom_desc_1',
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
  ],
  modal_zoom_desc_2: [
    <>
      아래의 <span className="font-medium">[Zoom 다시 열기]를 클릭</span>하거나 링글 모바일 앱을 통해 접속해 주세요.{' '}
    </>,
    <>
      Please <span className="font-medium">click the [Relaunch Zoom] button</span> below or try joining via the Ringle
      app.
    </>,
    's_classroom.modal_zoom_desc_2',
    <>
      Please <span className="font-medium">click the [Relaunch Zoom] button</span> below or try joining via the Ringle
      app.
    </>,
    <>
      Please <span className="font-medium">click the [Relaunch Zoom] button</span> below or try joining via the Ringle
      app.
    </>,
    <>
      Please <span className="font-medium">click the [Relaunch Zoom] button</span> below or try joining via the Ringle
      app.
    </>,
    <>
      Please <span className="font-medium">click the [Relaunch Zoom] button</span> below or try joining via the Ringle
      app.
    </>,
  ],
  btn: [
    `Zoom 다시 열기`,
    `Relaunch Zoom`,
    's_classroom.btn',
    `Relaunch Zoom`,
    `Relaunch Zoom`,
    `Relaunch Zoom`,
    `Relaunch Zoom`,
  ],
  zoom_help: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    's_classroom.zoom_help',
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
  ],
  zoom_tooltip: [
    `Zoom이 열리지 않는다면 이곳을 클릭하세요.`,
    `If Zoom doesn't launch automatically,  
please click here.`,
    's_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_desc_3: (helpLink) => [
    <>만약 문제가 해결되지 않는다면, 페이지 상단의&nbsp;{helpLink}을 통해 문의를 남겨주세요.</>,
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top.</>,
    's_classroom.modal_zoom_desc_3',
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top.</>,
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top.</>,
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top.</>,
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top.</>,
  ],
  btn_3: (meeting_number) => [
    <>회의 ID {meeting_number} 복사</>,
    <>Copy meeting ID {meeting_number}</>,
    's_classroom.btn_3',
    <>Copy meeting ID {meeting_number}</>,
    <>Copy meeting ID {meeting_number}</>,
    <>Copy meeting ID {meeting_number}</>,
    <>Copy meeting ID {meeting_number}</>,
  ],
  link_copied: [
    <>미팅ID가 복사되었습니다.</>,
    <>Meeting ID is copied to your clipboard.</>,
    's_classroom.link_copied',
    <>Meeting ID is copied to your clipboard.</>,
    <>Meeting ID is copied to your clipboard.</>,
    <>Meeting ID is copied to your clipboard.</>,
    <>Meeting ID is copied to your clipboard.</>,
  ],
}
export const s_head = {
  student: {
    title: [
      `No.1 일대일 화상영어 링글`,
      `Ringle: Premium Private English Tutoring`,
      's_head.student.title',
      `Ringle: 1对1视频英语会话`,
      `Ringle: 1對1視訊英語會話`,
      `Ringle: プレミアムマンツーマン英語レッスン`,
      `Ringle: Học Tiếng Anh trực tuyến 1:1`,
    ],
    desc: [
      `스탠퍼드 MBA출신이 만든 1:1 화상 영어 명문대 원어민 튜터의 똑똑한 수업을 경험하세요!`,
      `Have an in-depth discussion with tutors from the top 30 universities in the U.S. and the U.K.`,
      's_head.student.desc',
      `请与常春藤名校任课教师用高品质教材进行讨论来纠正我的英语吧！`,
      `與常春藤名校任課教師以高品質教材進行討論，糾正我的英語吧！`,
      `アメリカ・イギリスの上位30大学に在籍のチューターとレベルの高いディスカッションをしましょう。`,
      `Thảo luận chuyên sâu cùng các gia sư đến từ top 30 trường đại học hàng đầu Anh và Mỹ.`,
    ],
    keywords: [
      `유학, 영어, 원어민, 일대일영어, 화상영어, 미국대학, 미국유학, 전화영어, 원어민 화상영어, 일대일 영어 회화, 화상영어 사이트, 미국대학교 순위`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.student.keywords',
      `留学, 英语, 母语人士, 1对1英语, 线上英语, 美国大学, 美国留学, 电话英语, 母语视频英语, 1对1英语会话, 视频英语网站, 美国大学排名`,
      `留學、英語、母語人士、1:1英語、視訊英語、美國大學、美國留學、電話英語、母語人士視訊英語、1:1英語會話、視訊英語網站、美國大學排名`,
      `留学、英語、ネイティブスピーカー、1:1英語、オンライン英会話、アメリカ大学、アメリカ留学、電話英語、ネイティブオンライン英会話、1:1英会話、オンライン英会話サイト、アメリカ大学ランキング`,
      `Đây là đâu`,
    ],
    author: [
      `Ringle Team`,
      `Ringle Team`,
      's_head.student.author',
      `Ringle Team`,
      `Ringle Team`,
      `Ringle Team`,
      `Đội ngũ Ringle`,
    ],
    noscript: [
      `귀하가 사용하고 계신 브라우저는 HTML5를 지원하고 있지 않아서, 할 수 없이 JScript를 써서 몇몇 요소가 제대로 보이도록 구현했습니다.
그런데 안타깝게도 귀하의 브라우저에선 스크립트 기능이 꺼져있습니다. 이 페이지가 제대로 표시되려면 스크립트 기능을 켜주셔야만 합니다.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.student.noscript',
      `您当前使用的浏览器无法支持HTML5, 因此只能使用JScript呈现部分要素。
但很抱歉您浏览器中的脚本功能已经关闭。您必须开启脚本功能, 才能完整显示此页面。`,
      `您目前使用的瀏覽器不支援HTML5，我們只能使用JScript呈現一些要素。
遺憾的是，在您的瀏覽器已關閉腳本功能。您必須打開腳本功能，才能完整顯示此頁面。`,
      `現在お使いのブラウザはHTML5に対応していないため、JScriptを使用していくつかの要素がきちんと見えるように実現しました。
しかし、残念ながらあなたのブラウザではスクリプト機能がオフになっています。このページを正しく表示するためには、スクリプト機能をオンにしてください。`,
      `Trình duyệt không được hỗ trợ. Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle.`,
    ],
    title_error_page: [
      `No.1 일대일 화상영어 링글.`,
      `Ringle: Premium Private English Tutoring.`,
      's_head.student.title_error_page',
      `Ringle: 1对1视频英语会话.`,
      `Ringle: 1對1視訊英語會話.`,
      `Ringle: プレミアムマンツーマン英語レッスン.`,
      `Ringle: Học Tiếng Anh trực tuyến 1:1.`,
    ],
  },
  tutor: {
    title: [
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      's_head.tutor.title',
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
    ],
    desc: [
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      's_head.tutor.desc',
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
    ],
    keywords: [
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.tutor.keywords',
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
    ],
    author: [
      `Ringle Team`,
      `Ringle Team`,
      's_head.tutor.author',
      `Ringle Team`,
      `Ringle Team`,
      `Ringle Team`,
      `Đội ngũ Ringle`,
    ],
    noscript: [
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.tutor.noscript',
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Trình duyệt không được hỗ trợ. Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle.`,
    ],
  },
}
export const s_footer = {
  beyond_offline_new_standards: [
    `No.1 일대일 화상영어 링글`,
    `The New Standard in Online English Learning`,
    's_footer.beyond_offline_new_standards',
    `No.1的Ringle1对1线上英语`,
    `No.1的1:1視訊英語—Ringle`,
    `1on1オンライン英会話の新時代を駆けるRingle`,
    `Nâng tầm việc học tiếng Anh Online`,
  ],
  companay: [`Company`, `Company`, 's_footer.companay', `Company`, `Company`, `Company`, `Company`],
  b2b: [`Business`, `Business`, 's_footer.b2b', `Business`, `Business`, `Business`, `Business`],
  business_inquiry: [
    `제휴문의`,
    `B2B Overview`,
    's_footer.business_inquiry',
    `合作咨询`,
    `合作諮詢`,
    `法人向けサービスに関するお問い合わせ`,
    `Tổng quan B2B`,
  ],
  terms_of_privacy: [
    `개인정보처리방침`,
    `Privacy Policy`,
    's_footer.terms_of_privacy',
    `个人信息处理方针`,
    `個人資料處理方針`,
    `プライバシーポリシー`,
    `Chính sách bảo mật`,
  ],
  terms_of_service: [
    `이용약관`,
    `Terms of Service`,
    's_footer.terms_of_service',
    `使用条款`,
    `使用條款`,
    `利用規約`,
    `Điều khoản dịch vụ`,
  ],
  policy: [`정책`, `Policy`, 's_footer.policy', `政策`, `政策`, `ポリシー`, `Chính sách`],
  ringle_company: [
    `(주)링글잉글리시에듀케이션서비스`,
    `ⓒ Ringle English Education Service`,
    's_footer.ringle_company',
    `(株)Ringle English Education Service`,
    `Ringle英語教育服務股份有限公司`,
    `ⓒ Ringle English Education Service`,
    `ⓒ Ringle English Education Service`,
  ],
  ringle_address: [
    `서울 서초구 서초대로78길 22 (홍우제2빌딩) 11층`,
    `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_footer.ringle_address',
    `首尔江南区德黑兰路5街7号12层`,
    `首爾江南區德黑蘭路5街7號12樓`,
    `大韓民国ソウル特別市瑞草区瑞草路78ギル-22`,
    `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
  ],
  ringle_phone: [
    <>
      사업자등록번호 876-81-00110 통신판매신고번호 2022-서울서초-3045
      <br />
      대표전화 02-6204-8880
    </>,
    ``,
    's_footer.ringle_phone',
    `营业执照号码: 876-81-00110 通信销售申告号码: 2019-首尔江南-04831 代表电话: 02-6204-8880`,
    `營利事業統一編號：876-81-00110 通訊販賣申報編號：2019-首爾江南-04831 公司代表號：02-6204-8880`,
    `事業者登録番号 876-81-00110 通信販売申告番号 2019-ソウル江南-04831 代表電話 02-6204-8880`,
    ``,
  ],
  ringle_ceo: [
    `대표이사 : 이성파, 이승훈. 개인정보책임자 : 이성파 contact@ringleplus.com`,
    `Email : contact@ringleplus.com`,
    's_footer.ringle_ceo',
    `代表理事: Lee Seongpa, Lee Seunghun。个人信息负责人: Lee Seongpa contact@ringleplus.com`,
    `代表理事：Lee Seongpa、Lee Seunghun。個人資料負責人：Lee Seongpa contact@ringleplus.com`,
    `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ contact@ringleplus.com`,
    `Email : contact@ringleplus.com`,
  ],
  pricing: [`가격`, `Pricing`, 's_footer.pricing', `价格`, `價格`, `料金`, `Học phí`],
  ringle_team: [
    `링글 팀`,
    `Ringle Team`,
    's_footer.ringle_team',
    `Ringle团队`,
    `Ringle團隊`,
    `Ringleチーム`,
    `Ringle Team`,
  ],
  help_center: [`헬프센터`, `Help Center`, 's_footer.help_center', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
  career: [`링글 채용`, ``, 's_footer.career', ``, ``, ``, ``],
}
export const s_privacy = {
  heading: [
    `개인정보처리방침`,
    `Privacy Policy`,
    's_privacy.heading',
    `个人信息处理方针`,
    `個人資料處理方針`,
    `個人情報保護方針`,
    `Chính sách bảo mật`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    's_privacy.text',
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
  ],
}
export const s_ip_policy = {
  heading: [
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    's_ip_policy.heading',
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    's_ip_policy.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
  ],
}
export const s_lesson_policy = {
  heading: [
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    's_lesson_policy.heading',
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    's_lesson_policy.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
  ],
}
export const s_community_guide = {
  heading: [
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    's_community_guide.heading',
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    's_community_guide.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
  ],
}
export const s_terms = {
  heading: [
    `이용 약관`,
    `Terms of Service`,
    's_terms.heading',
    `使用条款`,
    `使用條款`,
    `利用規約`,
    `Điều khoản dịch vụ`,
  ],
  text: [
    `
제1조(목적)
본 약관은 "서비스" 이용자가 (주)링글잉글리시에듀케이션서비스(이하 '회사')가 제공하는 온라인 영어교육 플랫폼(www.ringleplus.com) 및 제공되는 일체의 서비스(이하 '서비스’)를 이용함에 있어 회사와 회원의 권리와 의무 및 책임 사항, 이용 절차, 그리고 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조(용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
"서비스": 회사가 제공하는 수업과 교정 및 이용가능한 콘텐츠 일체
"회원" : 회사와 "서비스" 이용 계약을 체결하고 회사가 제공하는 "서비스"를 이용하는 자
"이메일(ID)" : 인터넷 메일 계정. 회사는 "회원"의 "이메일”을 "회원” 식별과 "회원"의 "서비스" 이용을 위한 고유의 아이디(ID)로 사용함
"비밀번호" : "회원"의 "이메일(ID)”과 일치된 "회원"임을 확인하고, "회원" 자신의 비밀을 보호하기 위하여 "회원"이 정한 문자와 숫자의 조합
"관리자" : "서비스"의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람
"수업권(Credit)” : 링글 1:1 영어회화 또는 인터뷰 수업 1회 단위를 수업권이라 함. 수업권 1개는 수업 1회를 의미함.
"패키지” : 링글이 2개 이상의 수업권을 묶음으로 판매하는 단위를 의미함. 예로, 6회 패키지는 곧 수업 6회(수업권 6개)의 묶음을 의미함.

제3조(약관의 효력과 변경)
회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 "서비스" 사이트 초기 화면에 게시합니다.
회사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.
회사는 약관을 개정하는 경우 개정 사유 및 시행일자를 명시하여 현행약관과 함께 시행일로부터 최소 7일 전에 제1항의 방법으로 공지합니다. 단, "회원"의 권리 또는 의무에 관한 중요한 규정의 변경은 최소 30일전에 공지하고 개정약관을 "회원"이 등록한 "이메일”로 발송하여 통지합니다.
회사의 제3항에 따른 개정약관 공지 또는 통지 후 "회원"이 약관 변경 적용일까지 명시적으로 거부의사를 표시하지 않은 경우 "회원"이 개정약관에 동의한 것으로 봅니다. 단, "회원"은 변경된 약관에 동의하지 않는 경우 "서비스"의 이용을 중단하고 이용계약을 해지할 수 있습니다.
"회원"은 약관의 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 "회원"의 피해는 회사가 책임지지 않습니다.

제4조(약관의 준칙)
이 약관에 명시되지 않은 사항과 이 약관의 해석에 관하여는 전기통신기본법, 전기통신사업법, 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호 관련 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 문화체육관광부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.

제5조(이용 계약의 성립)
이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.
"회원"에 가입하여 "서비스"를 이용하고자 하는 자는 회사에서 요청하는 개인신상정보를 제공해야 합니다.
회사는 다음 각 호에 해당하는 이용 신청에 대하여는 승낙하지 아니할 수 있습니다.
1) 이름이 실명이 아니거나 다른 사람의 명의를이용한 경우
2) 이용 신청 시 필요 내용을 허위로 기재하여 신청한 경우
3) 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
4) 기타 회사가 정한 이용 신청요건이 미비 된 경우

제6조(계약 사항의 변경)
"회원"은 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, "서비스" 관리를 위해 필요한 이메일(ID)는 수정이 불가능합니다.
"회원"은 가입시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 변경사항을 알려야 합니다.
제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제7조(개인정보보호 의무)
회사는 이용자의 정보 수집 시 "서비스" 이용을 위한 최소한의 정보를 수집합니다.
회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. 제공된 개인정보는 당해 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며 이에 대한 모든 책임은 회사가 집니다.
이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집 목적 및 이용 목적, 제3자에 대한 정보 제공 관련 사항(제공 받는 자, 제공 목적 및 제공할 정보의 내용), 수탁업체에 대한 정보제공 관련사항(수탁자, 위탁업무 내용 및 정보제공 항목) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’이라 함)에 규정한 사항을 미리 명시하거나 고지해야 하며 "회원”은 언제든지 이 동의를 철회할 수 있습니다.
회사는 개인정보 보호를 위하여 "관리자"를 최소한의 수로 한정하며 이용자 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
회사 또는 그로부터 개인정보를 제공 받는 제3자는 개인 정보의 수집목적 또는 제공 받는 목적을 달성할 때에는 당해 개인정보를 지체 없이 파괴합니다.

제8조 ("회원"의 "이메일(ID)" 및 "비밀번호" 관리 의무)
"회원"의 "이메일(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
회사는 "회원"의 "이메일(ID)"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 "이메일(ID)"의 이용을 제한할 수 있습니다.
"회원"은 "이메일(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
제3항의 경우에 해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제9조(정보 제공 및 "회원"에 대한 통지)
회사는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 전자우편, 유선, 서신우편 등의 방법으로 "회원"에게 제공할 수 있습니다.

제10조(회사의 의무)
회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 "서비스"를 제공할 의무가 있습니다.
회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않습니다.
"서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.
회사는 "회원"의 개인정보(신용 정보 보호) 보호를 위해 보안 시스템을 갖추며, 본인 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신 기본법 등 법률의 규정에 의해 국가 기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보 통신 윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그리하지 않습니다.

제11조("회원"의 의무)
"회원"은 "서비스"를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.
1) 신청 또는 변경 시 허위내용의 등록
2) 타인의 정보도용
3) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
4) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
5) "서비스" 이용시 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 언행
6) 회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
7) 기타 불법적이거나 부당한 행위
"회원"은 내용별로 회사가 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다
"회원"은 회사의 "서비스"를 이용하여 얻은 정보를 가공, 판매하는 행위 등 "서비스"에 기재된 자료를 상업적으로 사용하거나 "서비스"를 이용해 영업활동을 할 수 없습니다. 단, 비상업적 목적일 경우에는 회사의 사전 동의를 얻어 사용할 수 있습니다.

제12조("서비스" 제공 및 중단)
"서비스"의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.
회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
컴퓨터 등 시스템 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "관리자"가 임의로 날짜와 시간을 정해 "서비스"의 제공을 일시 중단할 수 있습니다. 이 경우 "회원"에게 사전 공지를 원칙으로 하며, 부득이한 사유가 있을 경우 사후에 통지할 수 있습니다.
회사는 제공하고 있는 "서비스"의 일부 또는 전부를 변경할 수 있습니다. "서비스" 이용 방법과 내용, 이용 시간 등에 변경이 있는 경우에는 변경 사유와 변경 내용, 제공일자 등을 변경 전 7일 이상 "서비스" 초기 화면에 게시하여야 합니다.

제13조(수업 진행, 일정 변경 및 취소)
수업 일정 변경 및 취소는 튜터가 배정되어 있는 수업의 경우, 수업 시작 24시간 이전까지는 (예: 수업 시작 28시간 전) 언제든 가능합니다. 튜터가 배정되어 있는 수업에 대해, 수업 시작 시간 24시간 이내 수업을 취소하는 경우 (예: 수업 시작 16시간 전) 수업권이 차감됩니다. 단, 튜터가 미배정되어 있는 수업의 경우, 언제든 수업 취소/변경이 가능합니다.

또한, 수업권 유효기간이 만료되는 경우에도 수업권이 자동 소멸됩니다. 삭제 또는 소멸된 수업권에 대해서는 환불을 제공하지 않습니다.
고객의 잘못으로 인한 수업권 삭제 관련하여, 이의가 존재하는 경우  "회원"은 회사 측에 통보해야 하며,  해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
"회원"의 수업 지각시 지각분을 보충해드리지 않습니다. 수업은 정시 시작, 종료를 원칙으로 합니다. 단, "튜터" 지각시 수업 시작 시점부터 40분 간 수업을 진행해 드립니다.

제 14조(포인트 규정)
링글이 수강생 및 가입자 분들께 지급한 포인트는 링글 서비스 가격을 할인받는 적립금으로 사용될 수 있습니다. 포인트는, 피추천인이 4회 이상 수업 결제 시 추천인에게 제공하는 추천 포인트, 링글 서비스에 하자 발생 시(예: 수업 24시간 이전 튜터 취소 시) 수강생에게 제공하는 보상 포인트, 돈을 주고 구매한 수업을 완강했을 시 제공되는 수업 포인트, 링글이 이벤트 목적으로 가입자 대상으로 제공하는 이벤트 포인트로 구분됩니다.
추천 포인트는, 피추천인이 링글이 정의하는 일정금액 이상 결제 시 추천인에게 제공하는 리워드 성격의 포인트로, 링글 수업 할인 목적 외, 추천 리워드 상품으로 교환 가능합니다.
수업 포인트는, 링글이 정의하는 미션을 달성한 수강생에게 제공하는 포인트로, 수업 포인트 적립 요건은 포인트 페이지에 명시되어 있습니다.
보상 포인트는, 링글 서비스의 하자 발생 시, 수강생에게 보상의 목적으로 제공하는 포인트로, 적립 요건은 포인트 페이지에 명시되어 있습니다.
이벤트 포인트는, 링글이 마케팅 및 프로모션 목적으로, 링글팀이 정한 가입자에게 제공되는 포인트 입니다.
추천 포인트 외 다른 포인트는 링글 수업 할인 목적으로만 이용 가능하며, 추천 리워드 상품으로의 교환은 불가능합니다.
모든 포인트의 유효기간은 생성일로부터 1년이며, 1년 뒤 포인트는 소멸됩니다.

제 15조(환불규정)

회사는 서울시간대 기준 2019년 1월 31일까지의 구매건에 대해서는, "회원"의 환불 요청이 있을시, 다음 조항에 의거해 환불을 처리합니다.
1) 수업료를 지불하고 미수강한 수업에 대해 전액 환급함
2) 이미 진행된 수업에 대해서는 환급하지 않음
3) 무료로 획득한 수업에 대해서는 환급하지 않음
4) 특별 패키지로 구성되어 할인가로 결제한 "회원"의 경우, 이미 수강한 수업은 할인가가 아닌 정상가로 계산하여 제한 후 잔액을 환급함
5) 유효기간이 연장된 수업권은 환불이 불가함
6) 수강증, 출석증이 발급된 구매건은 환불이 불가함

회사는 서울시간대 기준 2019년 2월 1일 구매건부터 아래 원칙에 의거하여 회원에게 환불을 제공합니다.

1) 무료로 제공된 수업 또는 진행 완료된 수업 또는 수업권이 차감된 수업 (예: 수업 시작 24시간 이내에 취소된 수업, 수강생이 노쇼한 수업 등)에 대해서는 환불이 불가능합니다.
2) 회원이 구매한 유료수업에 대한 환불은 아래의 기준에 해당하지 않은 경우 진행 가능합니다. 유료수업 중 환불 불가능한 수업권은 아래와 같습니다.
2-1) 회사는 유효기간이 절반 이하 남은 수업에 대해서는 환불을 제공하지 않습니다. (예: 유효기간 30일 수업의 경우, 유효기간이 15일 되는 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘링글 유효기간 연장 규정’에 의거하여 제공합니다.
2-2) 회사는 회원이 구입한 패키지의 수업이 절반 이하 되는 시점부터 환불이 불가능합니다 (예: 회원이 6회 패키지를 구입한 경우, 수업이 3회 남은 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘유효기간 연장 규정’에 의거하여 제공합니다.
2-3) 회사는 유효기간이 연장된 수업권(수업)은 환불이 불가능합니다.
2-4) 회사는 수강증/출석확인증이 발급된 수업에 대해서는 환불이 불가능합니다

3) 회사는 환불 가능한 수업권에 대해 회사의 환불 계산 공식에 의거하여 환불 가능 금액을 산정 후 환불을 제공합니다.
3-1) 회원이 아직 수업을 개시하지 않은 패키지에 대해서는, 위의 15조 2월 1일부터의 환불규정 2-4)에 해당하지 않는 한 전액 환불을 제공합니다 (예: 6회 패키지 구매 후, 한 개의 수업도 수강하지 않아서 유효기간이 계속 멈춰있는 패키지에 대한 요청 시, 수강증/출석확인증 발급이 안된 경우에는 전액 환불 제공)
3-2) 회원이 수강을 시작한 패키지에 대한 환불을 요청하는 경우 (예: 6회 패키지에 대해 2회 수업을 수강한 후 나머지 4회에 대해 환불 요청), 15조 환불규정의 ‘환불 불가능 수업’에 해당하지 않는 수업에 한해, 실결제 금액에서 이미 진행한 수업에 대한 ‘정가 수업료’를 차감한 금액 만큼 환불해 드립니다 (환불 가능 금액 = 결제 금액 - 정가 수업료 * 기 진행한 수업 수). 이미 진행한 수업에 대한 ‘정가 수업료’는 수업권 관리 페이지에서 확인 가능합니다.
3-3) 환불 금액 지급 방식은, 회원이 신용카드로 결제했을 시 결제 취소를 통해, 계좌 이체로 결제했을 시 송금의 방식으로 제공드립니다.

제16조(수업권 유효기간 차감, 연장 및 소멸한 수업권에 대한 복구 규정)

회사는 아래조항에 의거하여 유효기간 차감 및 연장을 진행합니다.
1) 패키지의 유효기간은 첫 수업을 완료한 시점부터 패키지 내 모든 수업권의 유효기간이 자동으로 차감되기 시작합니다. (예: 20회 패키지 이용 시, 첫 수업 완료 시점부터 나머지 19개 수업권의 유효기간이 자동 차감되기 시작함). 또는, 패키지 구매 후 5년이 되는 시점부터, 유효기간이 자동으로 차감되기 시작합니다.
2) 패키지 유효기간 연장 신청은 유효기간이 처음으로 절반 이하로 떨어지는 시점부터 가능합니다 (예: 6회 30일 패키지의 경우, 유효기간이 15일 이하가 되는 시점부터, 유효기간 연장이 가능함). 패키지의 유효기간 연장을 위한 금액은 ‘유효기간 연장 금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.
3) 유효기간이 소멸된 수업권은, 소멸이 된 후 3개월 이내만 부활이 가능합니다. 수업권 부활을 위한 금액은 ‘수업권 부활금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.

제17조(저작권의 귀속 및 이용제한)
회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
이용자는 회사를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송하거나 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

서울 시간대 기준 2019년 2월 1일부터 진행되는 수업 및 서비스 결과로 발생한 자료 및 데이터 저작권은 회사 및 회원 양측에 귀속됩니다. 회사는 수업 및 서비스 도중 발생한 결과물에 대해 non-disclosure agreement (NDA)를 이행하여, 데이터/자료가 고객 정보와 함께 그대로 외부에 노출되지 않도록 최선을 의무를 이행합니다. 단, 데이터/자료는 개인식별이 불가능한 형태로 저장되며, 회사는 해당 데이터/자료를 연구 및 제품개발 목적으로 이용할 수 있는 권한을 갖습니다. 회원의 동의가 있을 시, 또는 무료로 제공되는 기능에 대해서는 마케팅 및 홍보 목적으로 결과물을 이용 가능합니다.

제18조(계약 해지 및 "서비스" 이용 제한)
"회원"이 이용 계약을 해지하고자 하는 때에는 "회원" 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.
회사는 "회원"이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용 계약을 해지 하거나 또는 기간을 정하여 "서비스" 이용을 중지할 수 있습니다.
1) 타인의 "이메일(ID)" 및 "비밀번호"를 도용한 경우
2) "서비스" 운영을 고의로 방해한 경우
3) 가입한 이름이 실명이 아닌 경우
4) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
5) "회원"이 국익 또는 공익 저해 목적으로 "서비스" 이용을 계획 또는 실행하는 경우
6) 회사나 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
회사는 제2항의 규정에 의하여 이용 제한을 하고자 하는 경우에는 그 사유, 일시 및 기타사항을 정하여 이메일 또는 기타 방법에 의하여 해당 이용자 또는 대리인에게 통지 합니다. 다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니 합니다.
제2항의 규정에 의하여 이용 정지의 통지를 받은 이용자 또는 그 대리인은 그 이용 정지의 통지에 대하여 이의가 있을 때에는 이의 신청을 할 수 있습니다.
회사는 제3항의 규정에 의한 이의 신청에 대하여 그 확인을 위한 기간까지 이용 정지를 일시 연기 할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
회사는 이용정지 기간 중에 그 이용 정지 사유가 해소된 것이 확인된 경우에는 이용 정지 조치를 즉시 해제 합니다.

제19조(손해배상)
회사는 일체의 무료로 제공되는 "서비스"의 이용과 관련하여 "회원"에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.

제20조(면책조항)
회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
회사는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여 책임을 지지 않습니다.
회사는 "회원"이 "서비스"에 기재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.

제21조(관할 법원)
"서비스"의 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    's_terms.text',
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
  ],
}
export const s_password_reset = {
  reset_password: [
    `비밀번호 재설정`,
    `Reset Password`,
    's_password_reset.reset_password',
    `密码重置`,
    `重設密碼`,
    `パスワード再設定`,
    `Đặt lại mật khẩu`,
  ],
  enter_email_to_reset_your_password: [
    `이메일을 입력하시면, 비밀번호를 재설정 하실 수 있는 링크를 보내드립니다.`,
    `Enter your email address to receive a link to reset your password.`,
    's_password_reset.enter_email_to_reset_your_password',
    `输入电子邮箱, 将发送给您密码重置链接。`,
    `如輸入電子信箱，將為您發送重設密碼連結。`,
    `メールアドレスを入力すると、パスワードを再設定できるリンクを送信します。`,
    `Nhập địa chỉ email để nhận link đặt lại mật khẩu.`,
  ],
  email: [`이메일`, `Email`, 's_password_reset.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_your_email: [
    `이메일을 입력하세요`,
    `Please enter your email`,
    's_password_reset.enter_your_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập email`,
  ],
  submit: [`제출 하기`, `Submit`, 's_password_reset.submit', `提交`, `提交`, `送信する`, `Gửi`],
  did_you_remember_your_password: [
    <>비밀번호가 생각나셨나요?</>,
    `Did you remember your password?`,
    's_password_reset.did_you_remember_your_password',
    <>您找回密码了吗?</>,
    <>想起密碼了嗎？</>,
    <>パスワードを思い出しましたか？</>,
    `Bạn có nhớ mật khẩu của mình?`,
  ],
  sign_in: [`로그인`, `Log in`, 's_password_reset.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
}
export const s_complete = {
  title: [
    isMobile ? (
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>
    ) : (
      <>진짜 영어를 배울 수 있는 링글 수업을 시작해보세요.</>
    ),
    isMobile ? (
      <>
        Learn Real English
        <br />
        with Ringle
      </>
    ) : (
      <>Learn Real English with Ringle</>
    ),
    's_complete.title',
    isMobile ? (
      <>
        通过Ringle的课程
        <br />
        学习正宗的英语
      </>
    ) : (
      <>通过Ringle的课程学习正宗的英语。</>
    ),
    isMobile ? (
      <>
        請開始可以學習
        <br />
        真正英語的Ringle課程吧！
      </>
    ) : (
      <>請開始可以學習真正英語的Ringle課程吧！</>
    ),
    isMobile ? (
      <>
        リアルな英語を学ぶことができる
        <br />
        Ringleでレッスンを始めましょう。
      </>
    ) : (
      <>リアルな英語を学ぶことができるRingleでレッスンを始めましょう。</>
    ),
    isMobile ? (
      <>
        Học tiếng Anh thực tế
        <br />
        với Ringle
      </>
    ) : (
      <>Học tiếng Anh thực tế với Ringle</>
    ),
  ],
  welcome_to_ringle: [
    <>
      {isMobile ? (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      ) : (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      ) : (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      )}
    </>,
    's_complete.welcome_to_ringle',
    <>
      {isMobile ? (
        <>
          欢迎!
          <br />
          非常感谢您的注册!
        </>
      ) : (
        <>
          很高兴认识您!
          <br />
          非常感谢您的注册!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          歡迎您！
          <br />
          感謝您的註冊！
        </>
      ) : (
        <>
          歡迎您！
          <br />
          感謝您的註冊！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          ようこそ！
          <br />
          ご登録ありがとうございます。
        </>
      ) : (
        <>
          ようこそRingleへ！
          <br />
          ご登録していただき誠にありがとうございます。
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Chúc mừng bạn!
          <br />
          Tham gia cùng Ringle.
        </>
      ) : (
        <>
          Chúc mừng bạn!
          <br />
          YTham gia cùng Ringle.
        </>
      )}
    </>,
  ],
  trial_lesson: [
    <>
      {isMobile ? (
        <>체험 수업 무료로 신청하기</>
      ) : (
        <>
          체험 수업
          <br />
          무료로 신청하기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up for a Free Trial</>
      ) : (
        <>
          Sign up for a
          <br />
          Free Trial
        </>
      )}
    </>,
    's_complete.trial_lesson',
    <>
      {isMobile ? (
        <>请申请免费体验课程</>
      ) : (
        <>
          体验课程
          <br />
          免费申请
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>免費申請體驗課程</>
      ) : (
        <>
          免費申請
          <br />
          體驗課程
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>体験レッスンを無料で予約する</>
      ) : (
        <>
          体験レッスンを
          <br />
          無料で予約する
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Đăng kí để nhận buổi học thử miễn phí</>
      ) : (
        <>
          Đăng kí để nhận
          <br />
          Buổi học thử miễn phí
        </>
      )}
    </>,
  ],
  apply_for_20_min_lesson: [
    <>
      {isMobile ? (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로 경험해보세요!
        </>
      ) : (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로
          <br />
          경험해보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up now to browse our tutors & materials!</>
      ) : (
        <>
          Sign up now to browse
          <br />
          our tutors & materials!
        </>
      )}
    </>,
    's_complete.apply_for_20_min_lesson',
    <>
      {isMobile ? (
        <>
          请先
          <Div spanTag textPrimary>
            免费
          </Div>
          体验与众不同的教师及教材吧!
        </>
      ) : (
        <>
          请先
          <Div spanTag textPrimary>
            免费
          </Div>
          体验与众不同的
          <br />
          教师及教材吧!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          請先
          <Div spanTag textPrimary>
            免費
          </Div>
          體驗與眾不同的任課教師及教材吧！
        </>
      ) : (
        <>
          請先
          <Div spanTag textPrimary>
            免費
          </Div>
          體驗與眾不同的
          <br />
          任課教師及教材吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          並外れたチューターと教材を先に{' '}
          <Div spanTag textPrimary>
            無料
          </Div>
          で体験してみましょう！
        </>
      ) : (
        <>
          並外れたチューターと教材を先に{' '}
          <Div spanTag textPrimary>
            無料
          </Div>
          で
          <br />
          体験してみましょう！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Đăng ký ngay để cùng khám phá những gia sư & kho tài liệu của chúng tôi!</>
      ) : (
        <>
          Đăng ký ngay để khám phá
          <br />
          những gia sư & kho tài liệu của chúng tôi!
        </>
      )}
    </>,
  ],
  applyTrial: [
    <>바로 신청하기</>,
    <>Sign up now</>,
    's_complete.applyTrial',
    <>立即申请</>,
    <>立即申請</>,
    <>今すぐ予約する</>,
    <>Đăng kí ngay</>,
  ],
  dailyBrief: [
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    's_complete.dailyBrief',
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
  ],
  dailyBriefSub: [
    <>
      링글 회원이라면 누구나 무료로
      <br />볼 수 있는{' '}
      <Div spanTag textPrimary>
        하루 5분
      </Div>{' '}
      상식과 영어
    </>,
    <>5-minute dose of insight & English</>,
    's_complete.dailyBriefSub',
    <>
      只要是Ringle会员, 都可每天
      <br />
      免费浏览
      <Div spanTag textPrimary>
        5分钟的
      </Div>
      常识及英语
    </>,
    <>
      只要是Ringle會員，都可以
      <br />
      <Div spanTag textPrimary>
        一天5分鐘
      </Div>
      免費閱讀常識與英文
    </>,
    <>
      Ringle会員なら誰でも無料で
      <br />
      見ることができる{' '}
      <Div spanTag textPrimary>
        1日5分の
      </Div>{' '}
      知見と英語
    </>,
    <>5 phút tìm hiểu Insight & tiếng Anh</>,
  ],
  goDailyBrief: [
    <>Daily Brief 보러가기</>,
    <>Learn More</>,
    's_complete.goDailyBrief',
    <>前往浏览Daily Brief</>,
    <>前往查看Daily Brief</>,
    <>Daily Briefに移動</>,
    <>Tìm hiểu thêm</>,
  ],
  purchase_now: (amount) => [
    <>
      {isMobile ? (
        <>최대 3회 수업권 추가 + {amount}만원 할인</>
      ) : (
        <>
          최대 3회 수업권 +
          <br />
          최대 {amount}만원 할인
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Bonus Lesson + Up to {amount} OFF</>
      ) : (
        <>
          Bonus Lesson +
          <br />
          Up to {amount} OFF
        </>
      )}
    </>,
    's_complete.purchase_now',
    <>
      {isMobile ? (
        <>获取额外课程券 + {amount}优惠</>
      ) : (
        <>
          获取额外课程券 +
          <br />
          {amount}优惠
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>領取額外課程券 + {amount}優惠</>
      ) : (
        <>
          領取額外課程券 +
          <br />
          {amount}優惠
        </>
      )}
    </>,
    <>{isMobile ? <>追加レッスンチケット + {amount}追加割引</> : <>追加レッスンチケット + {amount}追加割引</>}</>,
    <>
      {isMobile ? (
        <>Buổi học tặng thêm + giảm giá {amount}</>
      ) : (
        <>
          Buổi học tặng thêm +
          <br />
          giảm giá {amount}
        </>
      )}
    </>,
  ],
  full_refund_guaranteed: [
    <>
      {isMobile ? (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      ) : (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      ) : (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      )}
    </>,
    's_complete.full_refund_guaranteed',
    <>
      {isMobile ? (
        <>
          首次购买优惠!
          <br />
          <Div textInfo>首次课程不满意时, 承诺100%退款</Div>
        </>
      ) : (
        <>
          首次购买优惠!
          <br />
          <Div textInfo>首次课程不满意时, 承诺100%退款</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          首次購買折扣優惠!
          <br />
          <Div textInfo>首次課程不滿意時，可全額退款</Div>
        </>
      ) : (
        <>
          首次購買折扣優惠!
          <br />
          <Div textInfo>首次課程不滿意時，可全額退款</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          初回購入割引特典!
          <br />
          初回レッスンにご満足いただ
          <br />
          <Div textInfo>けない場合、全額返金 </Div>
        </>
      ) : (
        <>
          初回購入割引特典!
          <br />
          <Div textInfo>
            初回レッスンで満足いただけ
            <br />
            なければ全額返金保証
          </Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Ưu đãi cho lần thanh toán đầu tiên!
          <br />
          <Div textInfo>Hoàn tiền 100%</Div>
        </>
      ) : (
        <>
          Ưu đãi cho lần thanh toán đầu tiên!
          <br />
          <Div textInfo>Hoàn tiền 100%</Div>
        </>
      )}
    </>,
  ],
  view_course_material: [
    `콘텐츠 교재 읽어보기`,
    `View lesson materials`,
    's_complete.view_course_material',
    `阅读教材内容`,
    `閱讀內容教材`,
    `価格を見る`,
    `Xem tài liệu học`,
  ],
  purchaseNow: [
    `지금 구매하기`,
    `Purchase Now`,
    's_complete.purchaseNow',
    `立即购买`,
    `立即購買`,
    `今すぐ購入する`,
    `Thanh toán ngay`,
  ],
  if_ringle_is_first_time: [
    <>링글이 처음이라면?</>,
    <>New to Ringle?</>,
    's_complete.if_ringle_is_first_time',
    <>您是初次使用Ringle吗?</>,
    <>如果您是第一次使用Ringle？</>,
    <>Ringleが初めてなら？</>,
    <>Lần đầu bạn học Ringle?</>,
  ],
  tutor: [
    <>
      {isMobile ? (
        <>링글 사용법 바로가기</>
      ) : (
        <>
          튜터 프로필
          <br />
          보러가기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>How it works: Ringle Lessons</>
      ) : (
        <>
          Browse tutors
          <br />
        </>
      )}
    </>,
    's_complete.tutor',
    <>
      {isMobile ? (
        <>查看Ringle使用方法</>
      ) : (
        <>
          前往查看
          <br />
          教师简介
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>立即前往Ringle使用方法</>
      ) : (
        <>
          前往查看
          <br />
          任課教師簡介
        </>
      )}
    </>,
    <>{isMobile ? <>Ringleの使用方法を見る</> : <>チュータープロフィール を見る</>}</>,
    <>
      {isMobile ? (
        <>Cách thức hoạt động: Buổi học của Ringle</>
      ) : (
        <>
          Xem gia sư
          <br />
        </>
      )}
    </>,
  ],
  tutorSub: [
    <>
      {isMobile ? (
        <>언제 어디서나, 원하는 수업을 자유롭게</>
      ) : (
        <>
          1000+명 이상의 검증된 영미권 명문대
          <br />
          <Div spanTag textPrimary>
            원어민 튜터
          </Div>
          를 만나보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Learn around your schedule</>
      ) : (
        <>
          Meet 1000+ Tutors!
          <br />{' '}
        </>
      )}
    </>,
    's_complete.tutorSub',
    <>
      {isMobile ? (
        <>自由进行希望的课程</>
      ) : (
        <>
          1000名以上, 出身享誉全球的英美名校
          <br />
          <Div spanTag textPrimary>
            与母语教师
          </Div>
          一起学习吧!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>隨時隨地自由進行想要的課程</>
      ) : (
        <>
          與1000名以上眾所公認的英美名校
          <br />
          <Div spanTag textPrimary>
            母語任課教師
          </Div>
          相見歡吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>いつでもどこでも、好きなレッスンを自由に</>
      ) : (
        <>
          1000名以上の検証された
          <br />
          英米圏の
          <Div spanTag textPrimary>
            ネイティブチューター
          </Div>
          <br />
          と共にレッスンをしましょう。
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Tìm hiểu thời khoá biểu của bạn</>
      ) : (
        <>
          Gặp gỡ 1000+ gia sư!
          <br />{' '}
        </>
      )}
    </>,
  ],
  goTutor: [
    <>{isMobile ? <>간단한 사용법 보러가기</> : <>맞춤 튜터 찾아보기</>}</>,
    <>{isMobile ? <>How it works: Ringle Lessons</> : <>Browse tutors</>}</>,
    's_complete.goTutor',
    <>{isMobile ? <>查看便捷使用方法</> : <>查找适合的教师</>}</>,
    <>{isMobile ? <>前往查看簡單的使用方法</> : <>尋找合適的任課教師</>}</>,
    <>{isMobile ? <>簡単な使用方法を見る</> : <>チューターを探す</>}</>,
    <>{isMobile ? <>Cách thức hoạt động: Buổi học của Ringle</> : <>Xem gia sư</>}</>,
  ],
  check_out_material: [
    <>교재 둘러보기</>,
    <>Check out Materials</>,
    's_complete.check_out_material',
    <>查看教材</>,
    <>查看教材</>,
    <>教材を見る</>,
    <>Xem tài liệu</>,
  ],
  check_out_material_content: [
    <>
      Top MBA 출신과 명문대 튜터들이 직접 집필하는 <span className="text-purple-500">프로페셔널한 교재</span>를
      읽어보세요!
    </>,
    <>Check out curriculum written by top MBA grads and elite tutors! </>,
    's_complete.check_out_material_content',
    <>
      名牌大学教师亲自编写
      <br />
      赶快阅读Ringle的教材吧！
    </>,
    <>
      名牌大學導師親自執筆
      <br />
      趕快閲讀Ringle的教材吧！
    </>,
    <>
      Top MBA出身と名門大学の
      <br />
      チューターが直に執筆する
      <br />
      教材を読んでみてください！
    </>,
    <>
      Hãy đọc sách giáo khoa chuyên nghiệp do các giáo viên xuất thân từ Top MBA và các trường đại học danh tiếng trực
      tiếp viết!
    </>,
  ],
  check_out_material_content_btn: [
    <>교재 보러가기</>,
    <>View lesson materials</>,
    's_complete.check_out_material_content_btn',
    <>阅读教材内容</>,
    <>閱讀內容教材</>,
    <>コンテンツ教材を読んでみる</>,
    <>Xem tài liệu học</>,
  ],
  free_trial_lesson: [
    <>
      20분 무료체험
      <br />
      신청하기
    </>,
    <>
      Free 20-Min <br />
      Lesson
    </>,
    's_complete.free_trial_lesson',
    <>请申请免费体验课程</>,
    <>免費申請體驗課程</>,
    <>体験レッスンを無料で予約する</>,
    <>Đăng kí để nhận buổi học thử miễn phí</>,
  ],
  free_trial_lesson_content: [
    <>
      수업권 구매 전 링글을
      <br />
      먼저 경험할 수 있는 기회!
      <br />
      (~5월 31일까지)
    </>,
    <>Want to try before you buy? Register now! (through May 31)</>,
    's_complete.free_trial_lesson_content',
    <>
      请先 免费 体验与众不同的
      <br />
      教师及教材吧!
    </>,
    <>
      {' '}
      請先 免費 體驗與眾不同的
      <br />
      任課教師及教材吧！
    </>,
    <>
      並外れたチューターと教材を先に 無料 で<br />
      体験してみましょう！
    </>,
    <>Đăng ký ngay để khám phá những gia sư & kho tài liệu của chúng tôi!</>,
  ],
  free_trial_lesson_content_btn: [
    <>무료체험 신청하기</>,
    <>Register for free trial</>,
    's_complete.free_trial_lesson_content_btn',
    <>立即申请</>,
    <>立即申請</>,
    <>今すぐ予約する</>,
    <>Đăng kí ngay</>,
  ],
}
export const s_signin = {
  kakao_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.kakao_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  facebook_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.facebook_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  instagram_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.instagram_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  sign_in: [`로그인`, `Log in`, 's_signin.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  sign_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.sign_in_with_facebook',
    `使用Facebook账号登录`,
    `使用Facebook帳戶登入`,
    `Facebookでログイン`,
    `Đăng nhập bằng Facebook`,
  ],
  enter_your_email: [
    `이메일 주소(ID)를 입력하세요`,
    `Enter your email address (ID)`,
    's_signin.enter_your_email',
    `请输入电子邮箱(ID)`,
    `請輸入電子信箱（帳號）`,
    `メールアドレス(ID)を入力してください。`,
    `Nhập địa chỉ email của bạn (ID)`,
  ],
  enter_your_password: [
    `비밀번호를 입력하세요`,
    `Enter your password`,
    's_signin.enter_your_password',
    `请输入密码`,
    `請輸入密碼`,
    `パスワードを入力してください。`,
    `Nhập mật khẩu`,
  ],
  forgot_password: [
    `비밀번호를 잊으셨나요?`,
    `Forgot password?`,
    's_signin.forgot_password',
    `您忘记密码了吗?`,
    `忘記密碼？`,
    `パスワードを忘れた方はこちら`,
    `Quên mật khẩu?`,
  ],
  no_ringle_account: [
    `아직 링글 계정이 없으신가요?`,
    `Don't have a Ringle account?`,
    's_signin.no_ringle_account',
    `您还没有Ringle账号吗?`,
    `您還沒有Ringle帳戶嗎？`,
    `まだRingleアカウントがありませんか？`,
    `Bạn không có tài khoản Ringle?`,
  ],
  sign_up: [`회원가입`, `Sign Up`, 's_signin.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  sign_in_others: [
    <>소셜 계정으로 로그인</>,
    `Log in with`,
    's_signin.sign_in_others',
    <>使用社交账号登录</>,
    <>使用社群媒體帳戶登入</>,
    <>ソーシャルアカウントでログイン</>,
    `Đăng nhập bằng`,
  ],
  sns_inapp: [
    <>
      외부 채널을 통한 진입 시 간편 로그인이 제한됩니다. <br />
      링글앱으로 로그인 해주세요!
    </>,
    `You will not be able to sign in if you have accessed this page from a different app.
Please log in through the Ringle App!`,
    's_signin.sns_inapp',
    <>
      通过外部频道进入时，将限制简单登录。
      <br />
      请用Ringle APP登录!{' '}
    </>,
    <>
      透過外部頻道進入時，將限制簡易登入。
      <br />
      請利用Ringle APP登入！
    </>,
    <>
      別のアプリ経由でアクセスした場合、ログインが制限されます。 <br />
      Ringleアプリでログインしてください！
    </>,
    <>
      Bạn không thể đăng nhập nếu đã truy cập trang này bằng ứng dụng khác.
      <br />
      Vui lòng đăng nhập bằng ứng dụng Ringle!
    </>,
  ],
  install_app: [
    `링글 앱 설치하기`,
    `Download App`,
    's_signin.install_app',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  log_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.log_in_with_facebook',
    `使用Facebook账号登录`,
    `使用Facebook帳戶登入`,
    `Facebookでログイン`,
    `Đăng nhập bằng Facebook`,
  ],
  log_in: [`로그인`, `Log in`, 's_signin.log_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  log_in_others: [
    <>소셜 계정으로 로그인</>,
    `Log in with`,
    's_signin.log_in_others',
    <>使用社交账号登录</>,
    <>使用社群媒體帳戶登入</>,
    <>ソーシャルアカウントでログイン</>,
    `Đăng nhập bằng`,
  ],
  title: [`Log in`, `Log in`, 's_signin.title', `Log in`, `Log in`, `Log in`, `Log in`],
  inapp_alert: [
    `Gmail로 로그인하려면 PC 또는 링글앱을 사용해 주세요.`,
    `For Gmail social login, please use a PC or the Ringle app.`,
    's_signin.inapp_alert',
    `如果想用Gmail登入请使用PC或是Ringle APP`,
    `如果想用Gmail登入請使用PC或是Ringle APP`,
    `GmailログインはPCまたはRingleアプリをご利用ください。`,
    `Vui lòng đăng nhập vào Gmail bằng PC hoặc ứng dụng Ringle.`,
  ],
  enter_valid_email: [
    `유효한 이메일을 입력해 주세요`,
    `Please enter a valid email address`,
    's_signin.enter_valid_email',
    `请输入有效的电子邮箱地址。`,
    `請輸入有效的電子信箱。`,
    `有効なメールアドレスを入力してください。`,
    `Vui lòng nhập địa chỉ email hợp lệ`,
  ],
  password_length: [
    `비밀번호를 6자 이상 입력해주세요`,
    `Password has to be at least 6 characters`,
    's_signin.password_length',
    `请输入6位字符以上的密码`,
    `請輸入6字以上的密碼`,
    `パスワードを6文字以上入力してください。`,
    `Mật khẩu cần ít nhất 6 ký tự`,
  ],
  password_space: [
    `띄어쓰기 없이 입력해 주세요.`,
    `Please enter without spaces.`,
    's_signin.password_space',
    `输入时中间不能有空格`,
    `輸入時中間不能有空格`,
    `スペース（空白）なしで入力してください。`,
    `vui lòng nhập không có dấu cách`,
  ],
  dormant_info: [
    `고객님의 계정이 휴면상태입니다.`,
    `Your Account is Dormant.`,
    's_signin.dormant_info',
    `您的帐号现在为休眠状态`,
    `您的帳號現在為休眠狀態`,
    `お客様のアカウントは休眠アカウントです。`,
    `Tài khoản của bạn đang ở trạng thái ngưng hoạt động.`,
  ],
  dormant_subinfo: [
    <>
      고객님은 링글 계정으로 1년 이상 로그인하지 않아
      <br />
      휴면 계정으로 전환되었습니다.
      <br />
      다시 링글 서비스를 이용하시려면 <strong>[해제하기]를 선택해주세요.</strong>
    </>,
    <>
      As you have not logged in for 1 year or more, your account has been labelled dormant. To use Ringle services,{' '}
      <strong>please click on the [Reactivate] button below.</strong>
    </>,
    's_signin.dormant_subinfo',
    `因为已经1年以上没有登入，帐号转为休眠状态. 如果想要继续使用Ringle，请点选 [解除休眠]`,
    `因為已經1年以上沒有登入，帳號轉為休眠狀態. 如果想要繼續使用Ringle，請點選 [解除休眠].`,
    `Ringleアカウントを1年以上ログインしていないため、休眠アカウントになりました。再度Ringleサービスを利用する場合は、[アカウントを有効にする]を選択してください。`,
    `Tài khoản của bạn đã được chuyển thành tài khoản ngưng hoạt động vì bạn đã không đăng nhập vào tài khoản Ringle của mình trong hơn một năm. Để tiếp tục sử dụng dịch vụ Ringle, vui lòng chọn [Hủy].`,
  ],
  dormant_reactivate_btn: [
    `해제하기`,
    `Reactivate`,
    's_signin.dormant_reactivate_btn',
    `解除休眠`,
    `解除休眠`,
    `アカウントを有効にする`,
    `Hủy`,
  ],
  dormant_back_btn: [
    `이전 화면으로 돌아가기`,
    `Go back`,
    's_signin.dormant_back_btn',
    `回到上一页`,
    `回到上一頁`,
    `戻る`,
    `Về trang trước`,
  ],
}
export const s_signup = {
  sign_up_with_sns: [
    <>소셜 계정으로 회원가입</>,
    `Sign up with`,
    's_signup.sign_up_with_sns',
    <>使用社交账号注册会员</>,
    <>使用社群媒體註冊會員</>,
    <>ソーシャルアカウントで会員登録</>,
    `Đăng ký với`,
  ],
  sign_up_with_email: [
    <>이메일로 가입</>,
    `Sign up with email`,
    's_signup.sign_up_with_email',
    <>使用电子邮箱注册会员</>,
    <>使用電子信箱註冊會員</>,
    <>メールアドレスで登録</>,
    `Đăng ký bằng email`,
  ],
  select_all: [
    <>모두 선택</>,
    `Select all`,
    's_signup.select_all',
    <>全部选择</>,
    <>全部選擇</>,
    <>すべて選択</>,
    `Chọn tất cả`,
  ],
  kakao_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.kakao_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  facebook_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.facebook_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  instagram_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.instagram_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  sign_up: [`회원가입`, `Sign Up`, 's_signup.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  email: [`이메일`, `Email`, 's_signup.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_email: [
    `이메일을 입력하세요`,
    `Enter your email`,
    's_signup.enter_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Nhập email`,
  ],
  password: [`비밀번호`, `Password`, 's_signup.password', `密码`, `密碼`, `パスワード`, `Mật khẩu`],
  enter_password: [
    `비밀번호를 입력해주세요`,
    `Enter your password`,
    's_signup.enter_password',
    `请输入密码`,
    `請輸入密碼`,
    `パスワードを入力してください。`,
    `Nhập mật khẩu`,
  ],
  password_length: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    's_signup.password_length',
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Mật khẩu cần ít nhất 6 ký tự.`,
  ],
  confirm_password: [
    `비밀번호 확인`,
    `Confirm Password`,
    's_signup.confirm_password',
    `密码确认`,
    `確認密碼`,
    `パスワード確認`,
    `Xác nhận mật khẩu`,
  ],
  re_enter_your_password: [
    `비밀번호를 한번 더 입력해주세요`,
    `Re-enter your password`,
    's_signup.re_enter_your_password',
    `请再次输入密码`,
    `請再次輸入密碼`,
    `パスワードを再度入力してください。`,
    `Nhập lại mật khẩu của bạn`,
  ],
  enter_your_name: [
    `이름을 입력해주세요`,
    `Enter your name`,
    's_signup.enter_your_name',
    `请输入姓名`,
    `請輸入姓名`,
    `氏名を入力してください。`,
    `Nhập tên của bạn`,
  ],
  enter_additional_info: [
    `기업맞춤 / 친구 코드 입력 [선택]`,
    `Enter referral code`,
    's_signup.enter_additional_info',
    `输入企业专用／朋友代码 [选填]`,
    `輸入企業專用／朋友代碼 [選填]`,
    `企業専用 / 友達コード入力 [任意]`,
    `Nhập mã giới thiệu`,
  ],
  phone_number: [
    `전화 번호`,
    `Phone number`,
    's_signup.phone_number',
    `电话号码`,
    `電話號碼`,
    `電話番号`,
    `Số điện thoại`,
  ],
  sign_in: [`로그인`, `Log In`, 's_signup.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  already_have_account: [
    `이미 계정이 있으신가요?`,
    `Already have a Ringle account?`,
    's_signup.already_have_account',
    `已经注册有账户?`,
    `您已經有帳戶嗎？`,
    `既にアカウントをお持ちですか？`,
    `Bạn đã có tài khoản Ringle?`,
  ],
  i_want_to_receive_promotions: [
    `이벤트 및 혜택 소식을 수신하고 싶습니다`,
    `I want to receive marketing communications on events and offers.`,
    's_signup.i_want_to_receive_promotions',
    `希望接收营销及宣传促销信息`,
    `我想接收行銷與宣傳活動消息`,
    `マーケティング及びプロモーションのお知らせを受け取りたいです。`,
    `Tôi muốn nhận ưu đãi đặc biệt và những tin tức mới nhất từ Ringle`,
  ],
  optional: [`선택`, `Optional`, 's_signup.optional', `选择`, `可選`, `任意`, `Không bắt buộc`],
  required: [`필수`, `Required`, 's_signup.required', `必需`, `必選`, `必須`, `Bắt buộc`],
  i_older_14: [
    `만 14세 이상입니다.`,
    `I am 14 years old or older.`,
    's_signup.i_older_14',
    `满14周岁以上`,
    `已滿14歲`,
    `満14歳以上ですか？`,
    `Tôi đã trên 14 tuổi.`,
  ],
  i_agree_to_privacy: [
    `개인정보 취급 방침에 동의합니다.`,
    `I agree to the privacy policy`,
    's_signup.i_agree_to_privacy',
    `同意个人信息处理方针`,
    `同意個人資料處理方針`,
    `個人情報保護方針に同意します。`,
    `Tôi đồng ý với chính sách bảo mật`,
  ],
  i_agree_to_terms: [
    `이용약관에 동의합니다.`,
    `I agree to the terms of service`,
    's_signup.i_agree_to_terms',
    `同意使用条款`,
    `同意使用條款`,
    `利用規約に同意します。`,
    `Tôi đồng ý với điều khoản dịch vụ`,
  ],
  company_code: [
    `기업 맞춤 코드(선택)`,
    `B2B Partner code (optional)`,
    's_signup.company_code',
    `企业专用代码（选填）`,
    `企業專用代碼（選填）`,
    `企業専用コード(任意)`,
    `Mã code đối tác B2B (không bắt buộc)`,
  ],
  verify: [`인증`, `Verify`, 's_signup.verify', `验证`, `驗證`, `認証`, `Xác minh`],
  refferal_code: [
    `친구 코드(선택)`,
    `Referral Code (optional)`,
    's_signup.refferal_code',
    `朋友代码(选择)`,
    `朋友代碼（選填）`,
    `招待コード(任意)`,
    `Mã giới thiệu (không bắt buộc)`,
  ],
  verified: [`인증 완료`, `Verified`, 's_signup.verified', `验证完成`, `驗證成功`, `認証完了`, `Đã xác minh`],
  sign_up_with_facebook: [
    `페이스북으로 가입하기`,
    `Sign up with Facebook`,
    's_signup.sign_up_with_facebook',
    `使用Facebook注册`,
    `使用Facebook註冊`,
    `Facebookで登録する`,
    `Đăng ký bằng Facebook`,
  ],
  privacy_consent: [
    `개인정보 취급 방침에 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy.`,
    's_signup.privacy_consent',
    `请同意个人信息处理方针`,
    `請同意個人資料處理方針`,
    `個人情報保護方針に同意してください。`,
    `Để hoàn thành đăng ký, bạn phải đồng ý Chính sách bảo mật của Ringle.`,
  ],
  privacy_terms_consent: [
    `개인정보 취급 방침과 이용 약관에 모두 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy and terms of service.`,
    's_signup.privacy_terms_consent',
    `请同意个人信息处理方针及使用条款`,
    `請同意個人資料處理方針及使用條款`,
    `個人情報保護方針と利用規約に同意してください。`,
    `Để hoàn thành đăng ký, bạn phải đồng ý với Chính sách bảo mật và Điều khoản dịch vụ của Ringle.`,
  ],
  please_enter_email: [
    `이메일을 입력해주세요.`,
    `Please enter e-mail.`,
    's_signup.please_enter_email',
    `请输入电子邮箱。`,
    `請輸入電子信箱。`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập e-mail.`,
  ],
  password_check: [
    `비밀번호 일치 여부를 확인해주세요`,
    `Please confirm your password`,
    's_signup.password_check',
    `请确认密码输入是否一致`,
    `請確認密碼是否一致`,
    `パスワードを確認してください。`,
    `Vui lòng xác nhận mật khẩu của bạn`,
  ],
  please_enter_name: [
    `이름을 입력해주세요.`,
    `Please enter your name.`,
    's_signup.please_enter_name',
    `请输入姓名。`,
    `請輸入姓名。`,
    `氏名を入力してください。`,
    `Vui lòng nhập tên của bạn`,
  ],
  please_enter_name_more: [
    `이름은 최소 2자 이상이어야 합니다.`,
    `Please enter at least 2 characters in the name.`,
    's_signup.please_enter_name_more',
    `请输入不少于2个字符的姓名。`,
    `請輸入2字以上的姓名。`,
    `氏名は2文字以上入力してください。`,
    `Vui lòng nhập ít nhất 2 ký tự trong tên của bạn`,
  ],
  additional_info: [
    `필수정보 입력`,
    `Required Information`,
    's_signup.additional_info',
    `输入必需信息`,
    `輸入必填資料`,
    `必須情報入力`,
    `Thông tin bắt buộc`,
  ],
  already_signed_up: [
    `이미 가입된 계정입니다.`,
    `This account already exists.`,
    's_signup.already_signed_up',
    `已经注册的账户`,
    `已註冊的帳戶。`,
    `すでに登録されているアカウントです。`,
    `Tài khoản đã tạo.`,
  ],
  basic_info: [
    `기본 정보`,
    `Basic Info`,
    's_signup.basic_info',
    `基本信息`,
    `基本資料`,
    `基本情報`,
    `Thông tin cơ bản`,
  ],
  veri_number: [
    `인증번호`,
    `Verification code`,
    's_signup.veri_number',
    `验证码`,
    `驗證碼`,
    `認証コード`,
    `Mã xác minh`,
  ],
  verified_success: [`인증됨`, `Verified`, 's_signup.verified_success', `已验证`, `已驗證`, `認証済み`, `Đã xác minh`],
  plz_veri_phone: [
    `휴대폰을 인증해주세요`,
    `Please verify your phone number`,
    's_signup.plz_veri_phone',
    `请进行手机验证`,
    `請驗證手機`,
    `電話番号を認証してください。`,
    `Vui lòng xác minh số điện thoại của bạn`,
  ],
  select_all_free_lesson: [
    `전체 동의 시 20분 무료체험 수업 제공`,
    `Select all for a free 20-min lesson`,
    's_signup.select_all_free_lesson',
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
  ],
  trial_modal: {
    title: [
      `20분 무료체험 혜택은 전체 마케팅 수신에 동의하신 분들께만 드리고 있어요.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      's_signup.trial_modal.title',
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
    ],
    content: [
      `전체 마케팅 수신에 동의하고 혜택을 받아보세요!`,
      `Opt in and win the offer!`,
      's_signup.trial_modal.content',
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
    ],
    btn: [`전체 동의하고 혜택받기`, `Opt in`, 's_signup.trial_modal.btn', `Opt in`, `Opt in`, `Opt in`, `Opt in`],
  },
  trial_modal_after_agree: {
    title: [
      <>
        전체 마케팅 수신동의로 변경되었습니다. <br />
        지금 바로 20분 수업을 체험해보세요.
      </>,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      's_signup.trial_modal_after_agree.title',
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
    ],
    btn: [`무료체험 신청하기`, `Register for free trial`, 's_signup.trial_modal_after_agree.btn', ``, ``, ``, ``],
  },
  i_want_to_receive_promotions_list: {
    item1: [
      `이메일`,
      `Receive email list`,
      's_signup.i_want_to_receive_promotions_list.item1',
      `接收电子邮箱`,
      `接收電子信箱`,
      `Email`,
      `Email`,
    ],
    item2: [
      `SMS·알림톡`,
      `Receive text/Kakao messaging`,
      's_signup.i_want_to_receive_promotions_list.item2',
      `接收SMS·短信通知`,
      `接收簡訊·訊息通知`,
      `SMS・お知らせトーク`,
      `Tin nhắn/Kakao`,
    ],
    item3: [
      `앱 푸시`,
      `Receive app push notifications`,
      's_signup.i_want_to_receive_promotions_list.item3',
      `接收软件推送`,
      `接收APP推播通知`,
      `アプリのプッシュ`,
      `Thông báo điện thoại`,
    ],
  },
  sns_inapp: [
    <>
      외부 채널에서 접속한 경우 간편 회원가입이 제한됩니다. <br />
      링글 앱을 설치하고 링글의 다양한 콘텐츠를 경험하세요!{' '}
    </>,
    `You will not be able to sign up if you have accessed this page from a different app.
Download the Ringle App to see more contents!`,
    's_signup.sns_inapp',
    <>
      {' '}
      通过外部频道进入时，将限制简单会员注册。
      <br />
      安装Ringle APP， 请体验Ringle的服务于内容吧！{' '}
    </>,
    <>
      透過外部頻道進入時，將限制簡易會員註冊。
      <br />
      安裝Ringle APP，體驗Ringle的服務與內容吧！
    </>,
    <>
      別のアプリを使用した場合は、会員登録が制限されます。
      <br />
      RingleアプリをDLして、多様なコンテンツを経験してみてください！
    </>,
    <>
      Bạn không thể đăng ký nếu đã truy cập trang này bằng ứng dụng khác. <br />
      Tải ứng dụng Ringle để xem các nội dung khác!{' '}
    </>,
  ],
  install_app: [
    `링글 앱 설치하기`,
    `Download App`,
    's_signup.install_app',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  log_in: [`로그인`, `로그인`, 's_signup.log_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  enter_valid_email: [
    `이메일을 올바르게 입력해주세요.`,
    `Please enter a valid email address.`,
    's_signup.enter_valid_email',
    `请输入有效的电子邮箱地址。`,
    `請輸入有效的電子信箱。`,
    `有効なメールアドレスを入力してください。`,
    `Vui lòng nhập địa chỉ email hợp lệ`,
  ],
}
export const s_point = {
  your_points: [
    `보유 포인트`,
    `Your points`,
    's_point.your_points',
    `持有积分`,
    `持有點數`,
    `保有ポイント`,
    `Điểm của bạn`,
  ],
  policy_note: [
    `링글 포인트 유의사항`,
    `Terms and conditions for Points`,
    's_point.policy_note',
    `Ringle积分注意事项`,
    `Ringle點數注意事項`,
    `Ringleポイントの注意事項`,
    `Điều khoản`,
  ],
  point_history: [
    <>전체 포인트</>,
    <>Total Points</>,
    's_point.point_history',
    <>全部积分</>,
    <>全部點數</>,
    <>すべてのポイント</>,
    <>Tổng số điểm</>,
  ],
  invite: [`초대하기`, `Invite`, 's_point.invite', `邀请`, `邀請`, `紹介する`, `Mời`],
  my_friend_point: [
    `친구 초대 포인트`,
    `Referral Points`,
    's_point.my_friend_point',
    `我的朋友邀请积分`,
    `我的邀請朋友點數`,
    `友達紹介ポイント`,
    `Điểm giới thiệu`,
  ],
  shipped_at_the_beginning_of_month: [
    `상품 전달은 매월 말일에 문자 메세지로 일괄 전달됩니다.`,
    `The gift card will be shipped at the end of every month`,
    's_point.shipped_at_the_beginning_of_month',
    `商品发送将于每月最后一天通过短信形式进行。`,
    `商品將於每月最後一天透過簡訊一併發送。`,
    `ギフトカードは毎月末日にメールで一括送信されます。`,
    `Thẻ quà tặng sẽ được chuyển vào cuối tháng`,
  ],
  use_point: [
    `포인트 사용하기`,
    `Use my points`,
    's_point.use_point',
    `使用积分`,
    `使用點數`,
    `ポイントを使用する`,
    `Sử dụng điểm của tôi`,
  ],
  total_point_earned: [
    `누적 포인트 내역`,
    `Total points earned`,
    's_point.total_point_earned',
    `积分累计明细`,
    `累積點數明細`,
    `合計獲得ポイント`,
    `Tổng số điểm bạn có`,
  ],
  total_use: [`총 사용`, `Total use`, 's_point.total_use', `总计使用`, `一共使用`, `合計使用ポイント`, `Tổng sử dụng`],
  total_points_used: [
    `사용 포인트 내역`,
    `Total points used`,
    's_point.total_points_used',
    `积分使用明细`,
    `使用點數明細`,
    `使用ポイント履歴`,
    `Tổng điểm đã sử dụng`,
  ],
  total_points: [`총 누적`, `Total points`, 's_point.total_points', `总累计`, `一共累積`, `総額`, `Tổng điểm`],
  invite_friend_for_secret_offer: [
    `시크릿 친구초대`,
    `Secret Offer Referrals`,
    's_point.invite_friend_for_secret_offer',
    `秘密邀请朋友`,
    `祕密邀請朋友`,
    `友達紹介(シークレット)`,
    `Ưu đãi giới thiệu bí mật`,
  ],
  invite_friend: [
    `일반 친구초대`,
    `Regular Referrals`,
    's_point.invite_friend',
    `一般邀请朋友`,
    `一般邀請朋友`,
    `友達紹介(通常)`,
    `Giới thiệu tiêu chuẩn`,
  ],
  all_invite_history: [
    `전체 현황`,
    `Total Status`,
    's_point.all_invite_history',
    `总体现状`,
    `整體現況`,
    `紹介履歴`,
    `Tổng trạng thái`,
  ],
  exclude_event: [
    `이벤트 기간 제외 상시`,
    `Applicable any time other than event period`,
    's_point.exclude_event',
    `活动期间外`,
    `活動期間以外的期間`,
    `イベント期間を除く`,
    `Áp dụng bất kì khoảng thời gian nào ngoại trừ các sự kiện khác`,
  ],
  invite_history: [
    <>친구 초대 포인트</>,
    <>Referral Points</>,
    's_point.invite_history',
    <>朋友邀请积分</>,
    <>邀請朋友點數</>,
    <>友達紹介ポイント</>,
    <>Điểm giới thiệu</>,
  ],
  received_point: (n) => [
    <>₩ {n} 받음</>,
    <>Received {n}</>,
    's_point.received_point',
    <>领取${n}</>,
    <>領取{n}</>,
    <>{n} 獲得済み</>,
    <>Nhận được {n}</>,
  ],
  ringle_coupon: [
    `1:1 회화 수업권(40분) 1회`,
    `1 Lesson Credit (40min)`,
    's_point.ringle_coupon',
    `1:1会话课程券(40分钟)1次`,
    `1:1會話課程券（40分鐘）1次`,
    `1on1レッスンのレッスンチケット(40分) 1回`,
    `Thẻ 1 Buổi (40 phút)`,
  ],
  no_use_point_history: [
    `사용 포인트 내역이 없습니다.`,
    `No point usage history.`,
    's_point.no_use_point_history',
    `不存在积分使用明细`,
    `無使用點數明細`,
    `使用ポイント履歴はありません。`,
    `Không có lịch sử sử dụng điểm.`,
  ],
  shinsegae_coupon: [`신세계 상품권`, `Shinsegae Gift Card`, 's_point.shinsegae_coupon', ``, ``, ``, ``],
  starbucks_coupon: [`스타벅스 상품권`, `Starbucks Gift Card`, 's_point.starbucks_coupon', ``, ``, ``, ``],
  has_been_chagned: [
    `선물로 변경이 완료 되었습니다.`,
    `You have successfully exchanged your points for the gift.`,
    's_point.has_been_chagned',
    `已变更为礼物。`,
    `已兌換禮物。`,
    `ギフトに変更が完了しました。`,
    `Bạn đã đổi điểm thành công để nhận quà.`,
  ],
  one_o_one_coversation_credit: [
    `추천 포인트 1:1 회화 수업권`,
    `Referral Point Credit`,
    's_point.one_o_one_coversation_credit',
    `推荐积分1:1会话课程券`,
    `推薦點數1:1會話課程券`,
    `おすすめポイント1on1レッスンのレッスンチケット`,
    `Điểm giới thiệu`,
  ],
  you_can_exchange_for_shinsegae_starbucks_coupon: [
    `신세계/스타벅스 쿠폰으로 교환 가능합니다.`,
    `You can exchange for Shinsegae or Starbucks Gift Card`,
    's_point.you_can_exchange_for_shinsegae_starbucks_coupon',
    ``,
    ``,
    ``,
    ``,
  ],
  coupons_are_shiped_to_mobile: [
    `상품권 및 쿠폰은, 매달 말일에 모바일로 일괄 배송해드립니다.`,
    `Gifts exchanged using Referral Points will be send at the end of every month.`,
    's_point.coupons_are_shiped_to_mobile',
    `商品券及优惠券将于每月最后一天统一发送至您的手机。`,
    `將於每月最後一天一併發送商品券或優惠券至手機。`,
    `商品券およびクーポンは、毎月末日にモバイルで一括送信いたします。`,
    `Quà tặng được đổi từ Điểm giới thiệu sẽ được gửi vào cuối mỗi tháng.`,
  ],
  exchange_for_gifts: [
    `선물로 변경하기`,
    `Exchange my points for gifts`,
    's_point.exchange_for_gifts',
    `变更为礼物`,
    `兌換禮物`,
    `ギフトに変更する`,
    `Đổi điểm lấy quà`,
  ],
  send_again: [`다시 보내기`, `Send again`, 's_point.send_again', `重新发送`, `重新發送`, `再送信`, `Gửi lại`],
  given_date: [`적립일`, `Date added`, 's_point.given_date', `积累日期`, `累積日`, `付与日`, `Ngày thêm vào`],
  expired_date: [`만료일`, `Expiration date`, 's_point.expired_date', `截止日期`, `到期日`, `満了日`, `Ngày hết hạn`],
  valid_until: [
    `유효기간`,
    `Validity period`,
    's_point.valid_until',
    `有效期限`,
    `有效期限`,
    `有効期間`,
    `Thời gian hiệu lực`,
  ],
  list_1: [
    `링글 포인트는 종류에 따라 사용처 및 사용조건, 유효기간 등이 다릅니다.`,
    `By type of Ringle Points, where to use, conditions of use, and validation date may vary.`,
    's_point.list_1',
    `Ringle积分可在1:1英语会话, 延长使用期限及付款时使用。`,
    `Ringle點數可用於1:1英語會話、延長使用期限或付款。`,
    `Ringleポイントは1on1レッスン、受講期間延長、決済時に使用することができます。`,
    <>
      Bạn có thể sử dụng điểm để đặt lịch học hoặc gia hạn thời gian
      <br />
      kết thúc.
    </>,
  ],
  list_2: [
    `수업 포인트는 유료 수업 완료 7일 후 실결제 금액의 3%가 자동 적립되고, 수업권 구매 및 수강기간 연장 등에 사용할 수 있습니다.`,
    `Lesson points are automatically awarded at 3% of the actual payment amount in 7 days after the completion of the paid lesson. And it can be used to purchase lesson credits and extend the validation period.`,
    's_point.list_2',
    `积分的有效期限为获得之日起365天。(促销活动的积分于发行时将规定不同的有效期限。)`,
    `點數的有效期限自累積日起365天。（宣傳活動的點數於發送時各有不同的有效期限。）`,
    `ポイントの有効期間は付与日から365日です。(プロモーションポイントは発行されるたびに有効期間が異なります。)`,
    `Điểm sẽ hết hạn sau 365 ngày kể từ ngày phát hành. Điểm nhận được trong khoảng thời gian ưu đãi sẽ có ngày kết thúc khác nhau.`,
  ],
  list_3: [
    `친구 추천 포인트는 수업권 구매 및 수강기간 연장 이외에 상품권으로 교환할 수도 있습니다. 상품권은 입력된 휴대폰 번호로 매월 말 일괄 발송됩니다.`,
    `In addition to purchasing lesson credits and extending the course period, Referral points can be exchanged with gift certificates on the PC web. Gift certificates are sent at the end of each month to the mobile phone number registered.`,
    's_point.list_3',
    `课程积分将于付费购买正规课程后7日起累计。 (全部累计数额: 购买课程时实际付款金额的3%)`,
    `課程點數將於完成付費購買的正規課程7天後累積。（全部累積金額：購買課程券時實際付款金額的3%）`,
    `レッスンポイントは、有料で購入したレギュラーレッスン完了7日後に付与されます。(全体付与額:購入したレッスンチケットの実際の決済金額の3%)`,
    `Điểm thưởng tương đương 3% giá trị thanh toán của mỗi buổi học, và sẽ được nhận trong 7 ngày sau khi hoàn thành buổi học.`,
  ],
  list_4: [
    `수업권 구매를 위해 특별 지급된 경우, 최소 결제 금액이 있을 수 있습니다.`,
    `In the case of points awarded only for purchasing lesson credits, there may be a minimum payment amount.`,
    's_point.list_4',
    `使用免费课程券或复原非正常情况结束的课程时, 课程积分将不予累计。`,
    `使用免費課程券或復原異常結束的課程時，不累積課程點數。`,
    `無料レッスンチケットの使用または非正常的に終了したレッスンが復旧される場合は、レッスンポイントは付与されません。`,
    `Buổi học miễn phí hoặc buổi học bị ngừng vì những trường hợp bất thường sẽ không được thưởng điểm.`,
  ],
  list_5: [
    `친구 추천 혜택으로 적립된 포인트는 상품권 등 선물로 교환할 수 있습니다.`,
    `Only Points earned through referrals can be exchanged for gift cards.`,
    's_point.list_5',
    `通过推荐朋友所获得的积分, 可进行商品券等礼品兑换。`,
    `透過推薦朋友累積的點數可兌換商品券等禮物。`,
    `友達紹介特典で付与されたポイントは、商品券などギフトに交換することができます。`,
    `Chỉ có Điểm nhận được qua việc giới thiệu bạn bè mới được đổi thành thẻ quà tặng.`,
  ],
  list_6: [
    `친구 추천 포인트로 변경한 선물은 매 월말 일괄 배송됩니다. (선물은 계정에 입력된 휴대폰 번호로 발송됩니다.)`,
    `Gifts exchanged using Referral Points will be sent at the end of the month.`,
    's_point.list_6',
    `使用推荐朋友所获得的积分兑换的礼物, 将于每月底统一发送。 (礼物将发送至账户验证的手机号码上。)`,
    `使用推薦朋友點數兌換的禮物將於每月底一併發送。（禮物將發送至輸入於帳戶的手機號碼。）`,
    `友達紹介ポイントで変更したギフトは、毎月末に一括送信されます。(ギフトはアカウントに入力された携帯電話番号に送信されます。)`,
    `Thẻ quà tặng quy đổi từ Điểm Giới thiệu sẽ được gửi vào cuối tháng.`,
  ],
  expiration_points_within_30_days: [
    <>30일 이내 만료 예정 포인트</>,
    <>The points will expire within 30 days</>,
    's_point.expiration_points_within_30_days',
    <>30日以内到期的积分</>,
    <>30天內將到期的點數</>,
    <>30日以内の満了予定ポイント</>,
    <>Điểm sẽ hết hạn trong 30 ngày tới</>,
  ],
  policy_faq: [
    <>링글 포인트 적립과 사용법이 궁금하신가요?</>,
    <>How to earn and use Ringle points</>,
    's_point.policy_faq',
    <>想了解Ringle积分的累计及使用方法吗?</>,
    <>想了解Ringle點數的累積及使用方法嗎？</>,
    <>Ringleポイント付与と使用方法が知りたいです。</>,
    <>Cách kiếm và sử dụng điểm của Ringle</>,
  ],
  korean_name_number_required: [
    <>
      신세계/ 스타벅스 상품권의 경우, 수령자 본인의{' '}
      <span className="text-red-500">한국이름과 한국번호가 아닌 경우 수령에 제한</span>이 있을 수 있음을 안내드립니다.
      개인정보는 마이메뉴{'>'} 내 계정관리{'>'} 기본정보에서 확인/변경할 수 있습니다.
    </>,
    <>
      If the recipient is not registered as a Korean name and phone number, there might be restrictions in terms of
      receiving Shinsegae/Starbucks gift cards. Personal information can be viewed and varied in My Page{'>'} My Account
      {'>'} Basic info.
    </>,
    's_point.korean_name_number_required',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  how_to_use: [
    <>
      [링글 포인트 사용법]
      <br />
      1. 링글 수업권 구매시 포인트만큼 할인
      <br />
      2. 수업권 수강기간을 연장시 사용
      <br />
      <br />
      *포인트의 유효기간은 적립일로부터 365일입니다.
      <br />
      *추천한 친구가 결제 시, 제공되는 추천 포인트만 선물로 교환 가능합니다.
      <br />
      <br />
      [수강기간/포인트 확인법]
      <br />
      PC 웹: 오른쪽 상단 [아이콘] → [수업권/증명서류] OR [포인트]
      <br />
      모바일 앱: [나의 설정] → [나의 계정] → [포인트 관리]
    </>,
    <>
      You can use your Ringle Points towards your next purchase or use them to pay for extending your credits'
      expiration dates.
      <br />
      Points are valid for 365 days from the time of issuance (except for the first-purchase points,
      <br /> which expire 48 hours following the time of issuance).
      <br />
      <br />
      You can check under "Points" to check your points and their expiration dates.
    </>,
    's_point.how_to_use',
    <>
      [Ringle积分使用方法]
      <br />
      1. 购买Ringle课程券时, 将按积分获得优惠
      <br />
      2. 可在延长课程券使用期限时使用
      <br />
      <br />
      *积分有效期限为获得之日起365天。
      <br />
      *只能使用推荐的朋友付款时获得的积分兑换礼物。
      <br />
      <br />
      [使用期限/积分确认方法]
      <br />
      PC网页: 右侧上方[图标] → [课程券/证明材料] OR [积分]
      <br />
      应用程序: [我的设置] → [我的账户] → [积分管理]
    </>,
    <>
      [Ringle點數使用方法]
      <br />
      1. 購買Ringle課程券時，可獲得相當於點數的折扣
      <br />
      2. 用於延長課程券使用期限
      <br />
      <br />
      *點數的有效期限自累積日起365天。
      <br />
      *僅可使用推薦的朋友付款時提供的推薦點數兌換禮物。
      <br />
      <br />
      [使用期限／點數確認方法]
      <br />
      電腦版網站：右上方[圖示] → [課程券／證明] OR [點數]
      <br />
      手機APP：[我的設定] → [我的帳戶] → [點數管理]
    </>,
    <>
      [Ringleポイントの使用方法]
      <br />
      1. Ringleレッスンチケット購入時、ポイント分割引
      <br />
      2. レッスンチケットの受講期間を延長時に使用
      <br />
      <br />
      *ポイントの有効期間は付与日から365日です。
      <br />
      *紹介した友達が決済すると、提供される紹介ポイント分ギフトに交換可能です。
      <br />
      <br />
      [受講期間/ポイントの確認方法]
      <br />
      PCウェブ: 右上の[アイコン] → [レッスンチケット/証明書類] OR [ポイント]
      <br />
      モバイルアプリ: [マイページ] → [マイアカウント] → [ポイント管理]
    </>,
    <>
      Bạn có thể sử dụng điểm Ringle cho lần thanh toán tiếp theo hoặc gia hạn ngày kết thúc của thẻ học.
      <br />
      Điểm có giá trị trong 365 ngày kể từ ngày phát hành <br />
      <br />
      Bạn có thể kiểm tra dưới phần "Điểm" để xem điểm và ngày hết hạn.
    </>,
  ],
  usage: [
    `Q. 포인트는 어떻게 사용하나요? 보유한 포인트의 유효기간은 어디서 확인하나요?`,
    `Q. How can I use Ringle points? How long are they good for?`,
    's_point.usage',
    `Q. 积分如何使用? 持有中的积分有效期限如何确认?`,
    `Q. 如何使用點數？要在哪裡確認持有的點數有效期限呢？`,
    `Q. ポイントはどのように使いますか？保有しているポイントの有効期間はどこで確認できますか？`,
    `Q. Làm cách nào để sử dụng điểm Ringle? Và điểm có giá trị bao lâu?`,
  ],
  point_alert: [
    `설 선물로 드린 포인트가 곧 사라져요! (2023.02.03만료)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    's_point.point_alert',
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
  ],
  btn: [
    `사용하기`,
    `Use my points`,
    's_point.btn',
    `使用积分`,
    `使用點數`,
    `ポイントを使用する`,
    `Sử dụng điểm của tôi`,
  ],
  modal_title: [
    `새로 온 튜터 수업 포인트`,
    `Rookie Tutor Value-Back Points`,
    's_point.modal_title',
    `新教师课程积分`,
    `新導師課程點數`,
    `新規チューターポイント`,
    `Điểm buổi học với gia sư mới`,
  ],
  modal_desc: [
    `해당 포인트는 유료수업권으로 예약한 수업에 한하여 드리는 혜택으로 최종 결제 금액 기준 1회 수업료의 50%를 포인트로 드립니다.`,
    `50% of the lesson fee rewarded in points for paid lessons with Rookie Tutors.`,
    's_point.modal_desc',
    `仅限于用付费课程券预约的课程，以最终结算金额为准，1次课费的50%将作为积分返还。`,
    `僅限於用付費課程券預約的課程，以最終結算金額為准，回饋1次課程費用的50%點數。`,
    `該当ポイントは有料レッスンチケットで予約したレッスンに限り差し上げる特典であり最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
    `Đây là điểm ưu đãi chỉ áp dụng cho buổi học được đặt lịch bằng phiếu trả phí, 50% điểm sẽ được cộng 1 lần dựa trên số tiền thanh toán cuối cùng.`,
  ],
  modal_subtitle: [`포인트`, `Points`, 's_point.modal_subtitle', `积分`, `點數`, `ポイント`, `Điểm`],
  modal_list1: [
    `수업권 최종 결제 금액`,
    `Total payment billed`,
    's_point.modal_list1',
    `课程券最终结算金额`,
    `課程券最終結算金額`,
    `レッスンチケット最終決済金額`,
    `Tổng học phí`,
  ],
  modal_list2: [
    `1회 수업료 (최종 결제 금액 / 수업수)`,
    `Lesson fee (Payment per lesson)`,
    's_point.modal_list2',
    `1次课程费用（最终结算金额/课程数量）`,
    `1次課程費用（最終結算金額/課程數量）`,
    `一回のレッスンの金額`,
    `Học phí 1 buổi`,
  ],
  modal_list3: [
    `최종 적립 포인트`,
    `Total points earned`,
    's_point.modal_list3',
    `最终积累积分`,
    `最終累積點數`,
    `最終獲得ポイント`,
    `Tổng điểm tích lũy`,
  ],
  modal_list1_1: (price) => [
    <>{price}</>,
    <>{price}</>,
    's_point.modal_list1_1',
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
  ],
  modal_list2_1: (price) => [
    <>{price}</>,
    <>{price}</>,
    's_point.modal_list2_1',
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
  ],
  modal_list3_1: (point) => [
    <>{point}P</>,
    <>{point}P</>,
    's_point.modal_list3_1',
    <>{point}P</>,
    <>{point}P</>,
    <>{point}P</>,
    <>{point}P</>,
  ],
}
export const s_change_password = {
  new_password: [
    `새 비밀번호`,
    `New password`,
    's_change_password.new_password',
    `新的密码`,
    `新密碼`,
    `新しいパスワード`,
    `Mật khẩu mới`,
  ],
  confirm_password: [
    `비밀번호 확인`,
    `Confirm password`,
    's_change_password.confirm_password',
    `确认密码`,
    `重新輸入密碼`,
    `パスワード確認`,
    `Xác nhận mật khẩu`,
  ],
  save: [`저장`, `save`, 's_change_password.save', `保存`, `儲存`, `保存`, `lưu`],
  password_placeholder: [
    `6글자 이상 입력해주세요.`,
    `Enter at least 6 characters`,
    's_change_password.password_placeholder',
    `请输入6个字符以上。`,
    `請輸入6個字以上。`,
    `6文字以上入力してください。`,
    `Nhập ít nhất 6 ký tự`,
  ],
  confirm_password_placeholder: [
    `새 비밀번호를 다시 입력해주세요.`,
    `Re-enter your new password`,
    's_change_password.confirm_password_placeholder',
    `请重新输入密码。`,
    `請再次輸入新密碼。`,
    `新しいパスワードを再度入力。`,
    `Nhập lại mật khẩu của bạn`,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_change_password.your_option_change_has_been_completed',
    `变更内容已保存。`,
    `變更的內容儲存成功。`,
    `変更した内容が保存されました。`,
    `Đã lưu`,
  ],
}
export const s_mypage_nav = {
  credit: [
    `수업권/증명서류`,
    `Credits`,
    's_mypage_nav.credit',
    `课程券/证明材料`,
    `課程券／證明`,
    `レッスンチケット/証明書類`,
    `Thẻ học`,
  ],
  point: [`포인트`, `Points`, 's_mypage_nav.point', `积分`, `點數`, `ポイント`, `Điểm`],
  coupon: [`내 쿠폰함`, `Coupons`, 's_mypage_nav.coupon', `我的优惠券`, `我的優惠券`, `マイクーポン`, `Coupon`],
  basic_profile: [
    `기본 정보`,
    `Basic Info`,
    's_mypage_nav.basic_profile',
    `基本信息`,
    `基本資料`,
    `基本情報`,
    `Thông tin cơ bản`,
  ],
  set_profile: [
    `프로필`,
    `Profile`,
    's_mypage_nav.set_profile',
    `设置简介`,
    `簡介設定`,
    `プロフィール設定`,
    `Chỉnh sửa hồ sơ`,
  ],
  notification: [
    `알림 수신`,
    `Notifications`,
    's_mypage_nav.notification',
    `设置接收提醒`,
    `通知接收設定`,
    `通知設定`,
    `Cài đặt thông báo`,
  ],
  password: [`비밀번호`, `Password`, 's_mypage_nav.password', `变更密码`, `變更密碼`, `パスワード変更`, `Đổi mật khẩu`],
  timezone: [`타임존`, `Time Zone`, 's_mypage_nav.timezone', `变更时区`, `變更時區`, `タイムゾーン変更`, `Đổi múi giờ`],
  news_special_offers: [
    `혜택 정보 알림`,
    `Ringle Newsletter`,
    's_mypage_nav.news_special_offers',
    `优惠信息提醒`,
    `優惠資訊通知`,
    `特典情報のお知らせ`,
    `Ringle Newsletter`,
  ],
  receive_news_special_offers: [
    `마케팅 정보 알림 수신동의 안내`,
    ``,
    's_mypage_nav.receive_news_special_offers',
    `营销信息提醒接收同意说明`,
    `行銷資訊通知接收同意說明`,
    `マーケティング情報通知受信同意のご案内`,
    ``,
  ],
  sender_ringle: [
    `전송자: 링글잉글리시에듀케이션 서비스`,
    `Sender: Ringle Education Service`,
    's_mypage_nav.sender_ringle',
    `发送人: Ringle English Education Service`,
    `傳送者：Ringle英語教育服務`,
    `送信者: Ringle, Inc.`,
    `Người gửi: Dịch vụ giáo dục Ringle`,
  ],
}
export const s_credit = {
  your_credits: [`FALSE`, `Your credits`, 's_credit.your_credits', ``, ``, ``, `Thẻ học của bạn`],
  credit_note: [
    `수업권 유의사항`,
    `Terms`,
    's_credit.credit_note',
    `课程券注意事项`,
    `課程券注意事項`,
    `レッスンチケットの注意事項`,
    `Điều khoản`,
  ],
  points_history: [
    `포인트 내역`,
    `Points History`,
    's_credit.points_history',
    `积分明细`,
    `點數明細`,
    `ポイント詳細`,
    `Lịch sử điểm`,
  ],
  invited_history: [
    `초대 현황`,
    `Referral History`,
    's_credit.invited_history',
    `邀请现状`,
    `邀請現況`,
    `紹介の現況`,
    `Lịch sử giới thiệu`,
  ],
  enter_gift_code: [
    `선물 코드 입력`,
    `Enter gift code`,
    's_credit.enter_gift_code',
    `输入礼物代码`,
    `輸入禮物代碼`,
    `ギフトコード入力`,
    `Nhập mã quà tặng`,
  ],
  please_enter_the_gift_code_here: [
    `여기에 선물 코드를 입력하세요`,
    `Please enter gift code here`,
    's_credit.please_enter_the_gift_code_here',
    `请在这里输入礼物代码`,
    `請在這裡輸入禮物代碼`,
    `ここにギフトコードを入力してください。`,
    `Vui lòng nhập mã quà tặng tại đây`,
  ],
  input: [`입력`, `Enter`, 's_credit.input', `输入`, `輸入`, `入力`, `Nhập`],
  exchanging_credit: [
    `수업권 변경하기`,
    `Exchange Credits`,
    's_credit.exchanging_credit',
    `变更课程券`,
    `變更課程券`,
    `レッスンチケットを変更する`,
    `Thẻ học quy đổi`,
  ],
  nonrefundable: [
    `환불 불가`,
    `Nonrefundable`,
    's_credit.nonrefundable',
    `不能退款`,
    `無法退款`,
    `返金不可`,
    `Không hoàn lại`,
  ],
  total_credits: [
    `총 수업권 수`,
    `Total Credits`,
    's_credit.total_credits',
    `总课程券数量`,
    `總課程券數`,
    `合計レッスンチケット数`,
    `Tổng số thẻ học`,
  ],
  unused_credits: [
    `미사용 수업권`,
    `Credits remaining`,
    's_credit.unused_credits',
    `未使用的课程券`,
    `未使用的課程券`,
    `未使用レッスンチケット`,
    `Thẻ học còn lại`,
  ],
  extended_valid_days: [
    `수강기간 연장`,
    `Extend expiration dates`,
    's_credit.extended_valid_days',
    `延长使用期限`,
    `延長使用期限`,
    `受講期間延長`,
    `Gia hạn ngày kết thúc`,
  ],
  see_details: [
    `자세히 보기`,
    `See details`,
    's_credit.see_details',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  lesson_book: [
    `수업 관리북`,
    `Lesson book`,
    's_credit.lesson_book',
    `课程管理本`,
    `課程管理本`,
    `レッスン管理ブック`,
    `Tổng hợp buổi học`,
  ],
  lesson_count: (count) => [
    <>{count}회</>,
    <>
      {count} {count > 1 ? 'Credits' : 'Credit'}
    </>,
    's_credit.lesson_count',
    <>{count}次</>,
    <>{count}次</>,
    <>{count}回</>,
    <>
      {count} {count > 1 ? 'Lessons' : 'Lesson'}
    </>,
  ],
  valid_n_days: (exp_days) => [
    <>수강 기간 : {exp_days}일</>,
    <>Expires in: {exp_days} Days </>,
    's_credit.valid_n_days',
    <>使用期限,: {exp_days}日</>,
    <>使用期限：{exp_days}日</>,
    <>受講期間 : {exp_days}日</>,
    <>Kết thúc trong: {exp_days} ngày </>,
  ],
  alert_deadline: (deadline) => [
    <>(~{deadline}까지)</>,
    <> (on {deadline})</>,
    's_credit.alert_deadline',
    <>(~{deadline}为止)</>,
    <>（至{deadline}）</>,
    <>(~{deadline}まで)</>,
    <> (Until ~{deadline})</>,
  ],
  not_started_credit: [
    `(첫 수업 완료 전까지 수강기간이 차감되지 않습니다)`,
    `(from your first completed lesson)`,
    's_credit.not_started_credit',
    `(首次课程完成前, 不扣除使用期限)`,
    `（在完成首次課程之前，不扣除使用期限。）`,
    `(初回レッスン完了後から受講期間が始まります。 )`,
    `(kể từ ngày hoàn thành buổi học đầu tiên)`,
  ],
  using_credit: [
    `사용 수업권 :`,
    `Used credit:`,
    's_credit.using_credit',
    `使用课程券:`,
    `使用的課程券：`,
    `使用レッスンチケット :`,
    `Thẻ học đã dùng:`,
  ],
  credit_has_expired: [
    `수업권 기간이 만료되었습니다`,
    `Credit(s) expired`,
    's_credit.credit_has_expired',
    `课程券已过期`,
    `課程券已過期`,
    `レッスンチケットの期間が満了しました。`,
    `Thẻ học hết hạn`,
  ],
  lessons_cancelled_within_n_hours: (h) => [
    <>수업 {h}시간 이내에 취소한 수업입니다</>,
    <>This lesson has been cancelled within {h} hours before it starts</>,
    's_credit.lessons_cancelled_within_n_hours',
    <>课程开始前{h}小时内, 取消的课程。</>,
    <>於課程開始前{h}小時以內取消的課程</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです。</>,
    <>Buổi học này bị huỷ trong vòng {h} giờ trước khi lớp bắt đầu</>,
  ],
  free_credit: [
    `당일수업권`,
    `Last-minute Credit`,
    's_credit.free_credit',
    `补充课程券`,
    `補充課程券`,
    `当日レッスンチケット`,
    `Thẻ Học bù`,
  ],
  no_show_lesson: [
    `노쇼한 수업입니다`,
    `You did not show up for this lesson`,
    's_credit.no_show_lesson',
    `未出席的课程`,
    `未出現的課程`,
    `無断欠席したレッスンです。`,
    `Bạn đã vắng mặt ở buổi học này`,
  ],
  credit_details: [
    `상세 수업권 현황`,
    `Purchase history`,
    's_credit.credit_details',
    `详细课程券现状`,
    `詳細課程券現況`,
    `詳細レッスンチケットの現況`,
    `Chi tiết thẻ học`,
  ],
  course_work_includes: [
    `수강일 포함`,
    `Include lesson days`,
    's_credit.course_work_includes',
    `包含课程日`,
    `包含課程日`,
    `受講日を含む`,
    `Hiển thị số buổi học`,
  ],
  without_taking_one: [
    `수강일 미포함`,
    `Do not include lesson days`,
    's_credit.without_taking_one',
    `未包含课程日`,
    `未包含課程日`,
    `受講日を含まない`,
    `Không hiển thị số buổi học`,
  ],
  display_full_price: [
    `전체 금액 표기`,
    `Display full price`,
    's_credit.display_full_price',
    `显示全部金额`,
    `標示全部金額`,
    `全金額表示`,
    `Hiển thị giá đầy đủ`,
  ],
  display_dividing_price: [
    `분할 금액 표기`,
    `Display installations`,
    's_credit.display_dividing_price',
    `显示部分金额`,
    `標示分批金額`,
    `分割金額表示`,
    `Hiển thị giá trả góp`,
  ],
  hangul_certificate: [
    `한글 증명서`,
    `Certificate in Korean`,
    's_credit.hangul_certificate',
    `中文证明`,
    `中文證明`,
    `証明書（日本語）`,
    `Chứng nhận bằng tiếng Hàn`,
  ],
  english_certificate: [
    `영문 증명서`,
    `Certificate in English`,
    's_credit.english_certificate',
    `英文证明`,
    `英文證明`,
    `証明書（英語）`,
    `Chứng nhận bằng tiếng Anh`,
  ],
  lesson_certificate: [
    `수강증`,
    `Lesson certificate`,
    's_credit.lesson_certificate',
    `上课证明`,
    `上課證明`,
    `受講証`,
    `Chứng nhận buổi học`,
  ],
  there_is_no_receipt_please_contact_through_channeltalk: [
    `PayPal 결제 내역은 사용하시는 PayPal 계정을 통해 확인할 수 있습니다`,
    `PayPal payment receipts can be checked through the PayPal account that you use.`,
    's_credit.there_is_no_receipt_please_contact_through_channeltalk',
    `PayPal付款明细可通过PayPal账户进行确认`,
    `PayPal付款明細可透過PayPal帳戶確認`,
    `PayPal決済履歴は、ご使用のPayPalアカウントからご確認いただけます。`,
    `Kiểm tra biên nhận thanh toán PayPal thông qua tài khoản PayPal bạn dùng.`,
  ],
  attendance_certificate: [
    `출석증`,
    `Attendance certificate`,
    's_credit.attendance_certificate',
    `出席证`,
    `出席證明`,
    `出席証`,
    `Chứng nhận tham gia`,
  ],
  attendance_required_option: [
    `출석증 출력 시, 수강일이 포함됩니다.`,
    `The duration of the class will be included when the attendance certification is printed.`,
    's_credit.attendance_required_option',
    `打印出席证时, 将包含课程日。`,
    `列印出席證明時，將包含課程日。`,
    `出席証の印刷時、受講日が含まれます。`,
    `Thời lượng buổi học sẽ có trong bản in chứng nhận tham gia.`,
  ],
  receipt: [`영수증`, `Receipt`, 's_credit.receipt', `收据`, `收據`, `領収証`, `Biên nhận`],
  select_form_of_certificate: [
    `증명서류 종류 선택`,
    `Select a type of certificate`,
    's_credit.select_form_of_certificate',
    `选择证明材料的种类`,
    `選擇證明種類`,
    `証明書類の種類選択`,
    `Chọn loại chứng nhận`,
  ],
  info_select_form_of_certificate: [
    `증명서류 종류를 선택해주세요`,
    `Select a type of certificate`,
    's_credit.info_select_form_of_certificate',
    `请选择证明材料的种类`,
    `請選擇證明種類`,
    `証明書類の種類を選択してください。`,
    `Chọn loại chứng nhận`,
  ],
  specified_lesson_period: [
    `수강 기간 지정`,
    `Select a lesson period`,
    's_credit.specified_lesson_period',
    `指定使用期限`,
    `指定使用期限`,
    `受講期間指定`,
    `Chọn thời gian học`,
  ],
  select_form: [
    `양식 선택`,
    `Select a form`,
    's_credit.select_form',
    `选择样式`,
    `選擇格式`,
    `様式選択`,
    `Chọn một biểu mẫu`,
  ],
  preview_output: [
    `출력 내용 미리 보기`,
    `Print preview`,
    's_credit.preview_output',
    `预览打印内容`,
    `預覽列印`,
    `印刷プレビュー`,
    `Xem trước khi in`,
  ],
  issue_certificate: [
    `증명서 발급`,
    `Issue certificate`,
    's_credit.issue_certificate',
    `发放证明`,
    `核發證明`,
    `証明書発行`,
    `Xuất chứng nhận`,
  ],
  issue_receipt: [
    `영수증 발급`,
    `Issue receipt`,
    's_credit.issue_receipt',
    `发放收据`,
    `核發收據`,
    `領収証発行`,
    `Xuất biên nhận`,
  ],
  issue_date: [`발급 시점`, `Issue date`, 's_credit.issue_date', `签发时间`, `核發時間`, `発行時点`, `Ngày phát hành`],
  receipt_of_first_purchase: [
    `첫번째 결제 영수증`,
    `First payment receipt`,
    's_credit.receipt_of_first_purchase',
    `第一笔付款收据`,
    `第一次付款收據`,
    `1枚目の決済領収証`,
    `Biên nhận thanh toán đầu`,
  ],
  receipt_of_second_purchase: [
    `두번째 결제 영수증`,
    `Second payment receipt`,
    's_credit.receipt_of_second_purchase',
    `第二笔付款收据`,
    `第二次付款收據`,
    `2枚目の決済領収証`,
    `Biên nhận thanh toán thứ 2`,
  ],
  received: [`지급 완료`, `Received`, 's_credit.received', `已发送`, `發送成功`, `支給完了`, `Đã nhận`],
  lesson_recommend_duration: [
    `링글의 추천: 5일에 1회 수업`,
    `Ringle's recommendation: 1 lesson per 5 days`,
    's_credit.lesson_recommend_duration',
    `Ringle的推荐: 5天1次课程`,
    `Ringle推薦：5天1次課程`,
    `Ringleのおすすめ: 5日に1回レッスン`,
    `Ringle khuyến khích: 1 buổi học mỗi 5 ngày`,
  ],
  receipt_guide: [
    <>
      카카오페이 및 페이팔 결제 시, 링글 홈페이지 내 영수증 발급이 불가합니다.
      <br />
      해당 결제 사이트에서 직접 영수증 발급 가능한 점 안내드립니다
    </>,
    <>
      Receipt on PayPal is not provided on the Ringle webpage.
      <br />
      Please visit the official website for the receipt issued.
    </>,
    's_credit.receipt_guide',
    <>
      使用KakaoPay或PayPal付款时, 将无法在Ringle主页申请收据。
      <br />
      您可前往相关付款网站直接领取收据。
    </>,
    <>
      使用Kakao Pay或PayPal付款時，無法在Ringle首頁申請收據。
      <br />
      您可於該付款網站直接核發收據。
    </>,
    <>
      KakaoPayおよびPayPalで決済される場合、Ringleホームページ内から領収証の発行はできません。
      <br />
      各決済サイトから直接領収証の発行が可能です。ご了承ください。
    </>,
    <>
      Biên nhận trên PayPal không được cung cấp qua website Ringle.
      <br />
      Vui lòng vào website của PayPal để xuất biên nhận.
    </>,
  ],
  list_1: [
    `정규수업권 1회로 링글 1:1 수업 1회를 수강할 수 있으며, 정규 시간 내 자유롭게 이용가능합니다.`,
    `You can take one lesson per Credit.`,
    's_credit.list_1',
    `使用1张正规课程券, 可进行1次1:1课程。`,
    `可使用1張正規課程券進行1次1:1課程。`,
    `レギュラーレッスンチケット1枚で1回1on1レッスンを受講することができます。`,
    `Mỗi thẻ học dùng cho một buổi học.`,
  ],
  list_2: [
    `정규수업권은 [당일수업] 예약에도 사용할 수 있습니다.`,
    `You can book a lesson available in 24 hours with a Regular Credit.`,
    's_credit.list_2',
    `可用正规课程券预约/进行课程开始24小时前的课程。`,
    `可使用正規課程券預約／進行課程開始24小時前的課程。`,
    `24時間前のレッスンをレギュラーレッスンチケットで予約/受講することができます。`,
    `Bạn có thể đặt buổi học đang trống trong vòng 24 giờ bằng Thẻ Tiêu chuẩn.`,
  ],
  list_3: [
    `정규수업권으로 수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
    `Cancellation made on a lesson booked with Regular Credit 2-24 hours before the lesson will replace the used Regular Credit with a Last-minute Credit.`,
    's_credit.list_3',
    `24小时~2小时以内取消使用正规课程券预约的课程时, 将提供补充课程券。`,
    `取消使用正規課程券預約的課程開始24小時～2小時前的課程時，將提供補充課程券。`,
    `レギュラーレッスンチケットでレッスン開始の24時間前までにキャンセルされた場合、そのレッスンチケットは自動的に回復されます。`,
    `Buổi học đặt bằng Thẻ Tiêu chuẩn bị huỷ từ 2-24 giờ trước khi bắt đầu sẽ được thay thế bằng Thẻ Học bù.`,
  ],
  list_4: [
    `정규수업권으로 수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 당일수업권이 제공됩니다.`,
    `Cancellations made 2-24 hours before the lesson will replace the credit used with a Last-minute Credit.`,
    's_credit.list_4',
    `补充课程券是指可预约/进行其他学员取消的24小时以内的课程。`,
    `補充課程券是指可預約／進行其他學生取消之24小時以內課程的課程券。`,
    `24時間~2時間以内のレギュラーレッスンチケットで予約したレッスンをキャンセルする場合、当日レッスンチケットが提供されます。`,
    `Thẻ Học bù có thể đặt buổi học đang trống trong 24 giờ.`,
  ],
  list_5: [
    `정규수업권으로 수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 당일수업권은 제공되지 않습니다.`,
    `Cancellations made less than 2 hours before the lesson will not restore the lesson credit.`,
    's_credit.list_5',
    `课程开始前2小时内取消时, 算作已经使用了课程券。`,
    `於課程開始前2小時以內取消預約的課程時，將消耗課程券。`,
    `2時間以内に予約されたレッスンをキャンセルする場合、レッスンチケットは消滅します。`,
    `Huỷ trong vòng 2 giờ trước buổi học sẽ không được hoàn lại.`,
  ],
  list_6: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `You must book and complete lessons before your credits expire.`,
    's_credit.list_6',
    `使用期限内应完成课程, 而非预约完课程。`,
    `請於使用期限內進行課程，而非預約課程。`,
    `受講期間内にレッスン予約ではなく、レッスン受講を完了しなければなりません。`,
    `Bạn phải đặt lịch và hoàn thành buổi học trước khi Thẻ học hết hạn.`,
  ],
  list_7: [
    `수업권 관련 문의사항이 있을 경우 [1:1 문의]로 연락해 주십시오.`,
    `If you have any questions regarding, please feel free to contact us via chat below.`,
    's_credit.list_7',
    `有关课程券的疑问, 可通过在线[咨询]方式联系我们。`,
    `如有課程券相關疑問，請透過[諮詢]聯絡我們。`,
    `レッスンチケットに関するお問い合わせは[お問い合わせ]チャンネルからご連絡ください。`,
    `Nếu bạn có bất kì câu hỏi liên quan, cứ liên lạc với chúng tôi qua chat.`,
  ],
  remaining: [`남음`, `Remaining`, 's_credit.remaining', ``, ``, `回残り`, `Buổi học còn lại`],
  transfer_credit: [
    `틴즈로 수업권 이관하기`,
    `Transfer Credits to Teens`,
    's_credit.transfer_credit',
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
  ],
  teens_credits: [
    `링글 틴즈로 이관한 수업권 입니다.`,
    `This credit has been transferred to Ringle Teens.`,
    's_credit.teens_credits',
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
  ],
  title_1: [
    `내 수업권`,
    `My Credits`,
    's_credit.title_1',
    `我的课程券`,
    `我的課程券`,
    `レッスンチケット`,
    `Phiếu học tập của tôi`,
  ],
  regular: [
    `정규수업권`,
    `Regular`,
    's_credit.regular',
    `正规课程券`,
    `正規課程券`,
    `レギュラーレッスン`,
    `Buổi học tiêu chuẩn`,
  ],
  makeup: [
    `당일수업권`,
    `Last-minute`,
    's_credit.makeup',
    `当日课程券`,
    `當日課程券`,
    `当日レッスン`,
    `phiếu học trong ngày`,
  ],
  limited: [
    `시간한정수업권`,
    `Limited Window`,
    's_credit.limited',
    `限时课程券`,
    `限時課程券`,
    `時間限定レッスンチケット`,
    `phiếu học giới hạn thời gian`,
  ],
  urgent: [
    `긴급편성수업권`,
    `FastBook`,
    's_credit.urgent',
    `紧急编排课程券`,
    `緊急編排課程券`,
    `サプライズチケット`,
    `Phiếu học tập khẩn cấp`,
  ],
  min_20: [`20분`, `20 minutes`, 's_credit.min_20', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  min_40: [`40분`, `40 minutes`, 's_credit.min_40', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  note_title: [
    `수업권 유의사항`,
    `Notice`,
    's_credit.note_title',
    `课程券注意事项`,
    `課程券注意事項`,
    `注意事項`,
    `Lưu ý`,
  ],
  note_1: [
    `수강기간은 수업권 구매 이후 첫 수업을 완료하거나, 수업권을 사용하지 않고 1년이 지나는 경우 차감이 시작됩니다.`,
    `Credits will begin to expire when you complete your first lesson using the credits or, if unused, one year after the purchase date.`,
    's_credit.note_1',
    `课程券使用时间将于上完第一堂课后计算。在未使用的状态下将于购买后一年开始计算。`,
    `課程券使用時間將於上完第一堂課後計算。在未使用的狀態下將於購買後一年開始計算。`,
    `受講期間は、レッスンチケットを購入された後、最初のレッスンを完了したり、レッスンチケットを使用せずに1年が経過する場合に差引きされます。`,
    `Thời lượng khóa học được tính kể từ lúc hoàn thành bài học đầu tiên sau khi mua gói học hoặc nếu quá 1 năm không sử dụng.`,
  ],
  note_2: [
    `단, 무료 수업권은 수업권 지급 시점부터 수강기간이 차감됩니다.`,
    `However, free credits will begin to expire from the date they were issued.`,
    's_credit.note_2',
    `免费课程券的使用时间将于发送当下开始计算。`,
    `免費課程券的使用時間將於發送當下開始計算。`,
    `ただし、無料レッスンチケットは、レッスンチケットの付与時点から受講期間が差し引かれます。`,
    `Tuy nhiên, các bài học miễn phí sẽ bắt đầu hết hạn từ ngày được cấp.`,
  ],
  note_3: [
    `매주 수업 듣기 수업권은 결제 시점부터 수강기간이 시작되고, 수업을 진행하지 않은 주에는 수업권 1회가 자동 차감됩니다. (수업 24시간 이내 튜터로 인해 수업이 진행되지 않은 경우는 예외처리함)`,
    `Every Week credits will begin to expire from the purchase date, and you will lose one credit every week even if you don't take any lessons. (Exception: if the lesson was cancelled due to the tutor within 24 hours from the scheduled starting time.)`,
    's_credit.note_3',
    `一週一次课程券于购买当下起算使用时间，当週未使用的课程券也将自动扣除。（如果在课程进行前24小时因为教师的原因没有进行课程时将不适用于此规定）`,
    `一週一次課程券於購買當下起算使用時間，當週未使用的課程券也將自動扣除。（如果在課程進行前24小時因為教師的原因沒有進行課程時將不適用於此規定）`,
    `毎週レッスンを受けるレッスンチケットは、決済時点から受講期間が開始されます。レッスンが行われなかった週にはレッスンチケットが1回自動的に差し引かれます（レッスンが24時間以内にチューターにより行われなかった場合は例外となります）`,
    `Phiếu học hàng tuần sẽ bắt đầu hết hạn tính từ ngày mua, trừ một lần học ngay cả khi không hoàn thành một buổi học trong tuần. (Ngoại trừ trường hợp buổi học bị hủy bởi gia sư trong vòng 24 giờ kể từ thời gian bắt đầu đã được lên lịch)`,
  ],
  free: [`무료`, `Free`, 's_credit.free', `免费`, `免費`, `無料`, `Miễn phí`],
  no_refund: [
    `환불 불가`,
    `Non-Refundable`,
    's_credit.no_refund',
    `不可退款`,
    `不可退款`,
    `返金不可`,
    `Không thể hoàn lại tiền`,
  ],
  status_1: [`수강 기간`, `Expiration date`, 's_credit.status_1', `听课时间`, `上課時間`, `受講期間`, `Thời gian học`],
  status_2: [`남은 횟수`, `Credits`, 's_credit.status_2', `所剩次数`, `所剩次數`, `残り回数`, `Số lần còn sót lại`],
  status_3: [`발급 시점`, `Issue date`, 's_credit.status_3', `发放时间`, `發放時間`, `発行時点`, `Thời gian cấp`],
  date_not_started: (remainingDays) => [
    `${remainingDays}일 (미시작)`,
    `${remainingDays} days (frozen)`,
    's_credit.date_not_started',
    `${remainingDays}天 (尚未开始)`,
    `${remainingDays}天 (尚未開始)`,
    `${remainingDays}日（始める前）`,
    `${remainingDays} (frozen)`,
  ],
  remain: (remainingCredits) => [
    `${remainingCredits}회`,
    `${remainingCredits} credits`,
    's_credit.remain',
    `${remainingCredits}次`,
    `${remainingCredits}次`,
    `${remainingCredits}回`,
    `${remainingCredits}Lần`,
  ],
  date: (remainingDays) => [
    `${remainingDays}일 남음`,
    `${remainingDays} days left`,
    's_credit.date',
    `剩下${remainingDays}天`,
    `剩下${remainingDays}天`,
    `${remainingDays}日残っています`,
    `${remainingDays}Ngày còn lại`,
  ],
  date_remain_days: (count) => [
    `${count}일`,
    `${count}day(s)`,
    's_credit.date_remain_days',
    `${count}天`,
    `${count}天`,
    `${count}日`,
    `${count}Ngày`,
  ],
  date_remain_hours: (count) => [
    `${count}시간`,
    `${count}hour(s)`,
    's_credit.date_remain_hours',
    `${count}小时`,
    `${count}小時`,
    `${count}時間`,
    `${count}Thời gian`,
  ],
  date_remain_msg: (remainDate) => [
    `${remainDate} 남음`,
    `${remainDate} left`,
    's_credit.date_remain_msg',
    `剩下 ${remainDate}`,
    `剩下 ${remainDate}`,
    `${remainDate}残っています`,
    `${remainDate}còn lại`,
  ],
  btn_1: [
    `수업 관리북`,
    `Lesson Book`,
    's_credit.btn_1',
    `课程管理手册`,
    `課程管理手冊`,
    `レッスン管理ブック`,
    `Sách quản lý tiết học`,
  ],
  btn_2: [`수강기간 연장`, `Extend dates`, 's_credit.btn_2', `延长使用期限`, `延長使用期限`, `期間延長`, `Gia hạn`],
  btn_3: [
    `증명서/영수증 발급`,
    `Certificate/Receipt`,
    's_credit.btn_3',
    `开具证明/收据`,
    `開立證明/收據`,
    `証明書/領収書`,
    `Chứng nhận/hóa đơn`,
  ],
  see_detail: [`자세히 보기`, `See details`, 's_credit.see_detail', `了解详情`, `瞭解詳情`, `詳しく見る`, `Nhìn kĩ`],
  msg_refund: [
    `환불 완료`,
    `Refunded`,
    's_credit.msg_refund',
    `退款已完成`,
    `退款已完成`,
    `返金完了`,
    `Hoàn tất việc hoàn trả tiền`,
  ],
  filter: [`상세조회`, `Search`, 's_credit.filter', `查看详情`, `查看詳情`, `詳細を見る`, `Kiểm tra chi tiết`],
  option1_default: [
    `사용 가능 여부`,
    `Status`,
    's_credit.option1_default',
    `是否可以使用`,
    `是否可以使用`,
    `使用可否`,
    `Có hay không có khả năng sử dụng`,
  ],
  option1_1: [`전체보기`, `All`, 's_credit.option1_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option1_2: [`사용 가능`, `Unused`, 's_credit.option1_2', `可以使用`, `可以使用`, `使用可能`, `Có khả năng sử dụng`],
  option1_3: [
    `사용 완료/만료/환불`,
    `Used/Expired/Refunded`,
    's_credit.option1_3',
    `使用完毕/已过期/退款`,
    `使用完畢/已過期/退款`,
    `使用完了/満了/返金`,
    `Hoàn thành việc sử dụng/Hết hạn/Hoàn trả tiền`,
  ],
  option2_default: [
    `수업권 종류`,
    `Credit type`,
    's_credit.option2_default',
    `课程券种类`,
    `課程券種類`,
    `レッスンチケットの種類`,
    `Loại phiếu học tập`,
  ],
  option2_1: [`전체보기`, `All`, 's_credit.option2_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option2_2: [`정규수업권`, `Regular`, 's_credit.option2_2', `正规课程券`, `正規課程券`, `レギュラー`, `Tiêu chuẩn`],
  option2_3: [`당일수업권`, `Last-minute`, 's_credit.option2_3', `当日课程券`, `當日課程券`, `補填`, `Trong ngày`],
  option2_4: [
    `시간한정수업권`,
    `Limited Window`,
    's_credit.option2_4',
    `限时课程券`,
    `限時課程券`,
    `時間限定`,
    `Giới hạn`,
  ],
  option2_5: [
    `긴급편성수업권`,
    `FastBook`,
    's_credit.option2_5',
    `紧急编排课程券`,
    `緊急編排課程券`,
    `サプライズチケット`,
    `Mở gấp`,
  ],
  option3_default: [
    `수업 길이`,
    `Lesson Duration`,
    's_credit.option3_default',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời gian tiết học`,
  ],
  option3_1: [`전체 보기`, `All`, 's_credit.option3_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option3_2: [`20분`, `20 minutes`, 's_credit.option3_2', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  option3_3: [`40분`, `40 minutes`, 's_credit.option3_3', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  apply: [`적용`, `Apply`, 's_credit.apply', `查询`, `查詢`, `適用`, `áp dụng`],
  clear: [`초기화`, `Reset`, 's_credit.clear', `初始化`, `重置`, `初期化`, `Khởi tạo lại`],
  placeholder: [
    `찾으시는 수업권이 없습니다.`,
    `You have no credits that fit this criteria.`,
    's_credit.placeholder',
    `您要查找的课程券不存在。`,
    `您要查詢的課程券不存在。`,
    `お探しのレッスンチケットはありません。`,
    `Phiếu học tập mà anh/ chị đang cần tìm không có ạ.`,
  ],
  buy: [`구매하러 가기`, `Purchase now`, 's_credit.buy', `前往购买`, `前往購買`, `購入する`, `Đi mua`],
  title_2: [
    `수업권 상세 내역`,
    `Credit Details`,
    's_credit.title_2',
    `课程券详细信息`,
    `課程券詳細信息`,
    `レッスンチケットの詳細内容`,
    `Nội dung chi tiết phiếu học tập`,
  ],
  title_3: [
    `수업권 현황`,
    `Credit Summary`,
    's_credit.title_3',
    `教学券现状`,
    `課程券現狀`,
    `レッスンチケットの状況`,
    `Tình hình hiện tại của phiếu học tập`,
  ],
  title_4: [
    `수강 내역`,
    `Lesson History`,
    's_credit.title_4',
    `听课记录`,
    `課程記錄`,
    `レッスンの内訳`,
    `Nội dung chi tiết phiếu học tập`,
  ],
  available: [`사용 가능`, `Unused`, 's_credit.available', `可以使用`, `可以使用`, `使用可能`, `Nội dung khóa học`],
  number_available: (remainingCredits) => [
    `${remainingCredits}`,
    `${remainingCredits}`,
    's_credit.number_available',
    `${remainingCredits}`,
    `${remainingCredits}`,
    `${remainingCredits}`,
    `${remainingCredits}`,
  ],
  expired: [
    `만료/환불`,
    `Expired/Refunded`,
    's_credit.expired',
    `已到期/退款`,
    `已到期/退款`,
    `満了/返金`,
    `Hết hạn/ hoàn trả tiền`,
  ],
  number_expired: (expiredCredits) => [
    `${expiredCredits}`,
    `${expiredCredits}`,
    's_credit.number_expired',
    `${expiredCredits}`,
    `${expiredCredits}`,
    `${expiredCredits}`,
    `${expiredCredits}`,
  ],
  number_history: (completedCount) => [
    `${completedCount}`,
    `${completedCount}`,
    's_credit.number_history',
    `${completedCount}`,
    `${completedCount}`,
    `${completedCount}`,
    `${completedCount}`,
  ],
  table_header_1: [
    `수업권 종류`,
    `Credit type`,
    's_credit.table_header_1',
    `课程券种类`,
    `課程券種類`,
    `レッスンチケットの種類`,
    `các loại phiếu học tập`,
  ],
  table_header_2: [
    `수업권 수`,
    `Credits`,
    's_credit.table_header_2',
    `课程券数量`,
    `課程券數量`,
    `レッスンチケットの数`,
    `Số lượng phiếu học tập`,
  ],
  table_header_3: [
    `수강 기간`,
    `Expiration dates`,
    's_credit.table_header_3',
    `听课时间`,
    `課程時間`,
    `レッスン期間`,
    `Thời gian khóa học`,
  ],
  table_header_4: [`No.`, `No.`, 's_credit.table_header_4', `No.`, `No.`, `No.`, `No.`],
  table_header_5: [
    `수업 내용`,
    `Lesson topic`,
    's_credit.table_header_5',
    `课程内容`,
    `課程內容`,
    `レッスン内容`,
    `Nội dung tiết học`,
  ],
  table_header_6: [
    `수업 시간`,
    `Lesson date`,
    's_credit.table_header_6',
    `课程时间`,
    `課程時間`,
    `レッスン時間`,
    `Thời gian tiết học`,
  ],
  msg_date: (date) => [
    `${date}일`,
    `${date}day(s)`,
    's_credit.msg_date',
    `${date}日`,
    `${date}日`,
    `${date}日`,
    `Ngày`,
  ],
  msg_expire: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm까지" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_expire',
    <DateComponent format="截止到yyyy.MM.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="截止到yyyy.MM.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mmまで" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="cho đến yyyy.MM.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  msg_expired: [
    `기간 만료`,
    `Expired`,
    's_credit.msg_expired',
    `时间已过期`,
    `時間已過期`,
    `期間満了`,
    `Thời gian hết hạn`,
  ],
  msg_date_expired: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_date_expired',
    <DateComponent format="yyyy.MM.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  list_latest: [
    `최신순`,
    `Newest`,
    's_credit.list_latest',
    `按最新的课程排序`,
    `按最新的課程排序`,
    `最新順`,
    `Thứ tự mới nhất`,
  ],
  list_late: [
    `오래된순`,
    `Oldest`,
    's_credit.list_late',
    `按之前的课程排序`,
    `按之前的課程排序`,
    `投稿順`,
    `Thứ tự cũ nhất`,
  ],
  lesson_date: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.lesson_date',
    <DateComponent format="yyyy.MM.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.MM.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  view_more: [`더보기`, `See more`, 's_credit.view_more', `查看更多`, `查看更多`, `もっと見る`, `Nhìn thêm`],
  tooltip_regular: [
    `정규수업권은 정규 시간 내 1:1 수업을 자유롭게 예약 가능한
수업권이며, 당일 수업 예약에도 사용할 수 있습니다.`,
    `Regular credits can be used to book 1:1 lessons for any time within regular hours, including last-minute lessons that begin within 24 hours from booking.`,
    's_credit.tooltip_regular',
    `正规课程券是可以在正规时间内自由预约1对1课程的课程券，也可以用于预约当天的课程。`,
    `正規課程券是可以在正規時間內自由預約1對1課程的課程券，也可以用於預約當天的課程。`,
    `レギュラーレッスンチケットは正規時間内に1on1のレッスンを自由に予約できるレッスンチケットであり、当日のレッスン予約にも使用できます。`,
    `Phiếu học tiêu chuẩn là phiếu có thể tự do đặt trước lớp học 1:1 trong thời gian chính quy và cũng có thể sử dụng cho việc đặt giờ học trong cùng một ngày.`,
  ],
  tooltip_makeup: [
    `당일수업권은 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있습니다.`,
    `Last-minute credits can only be used to book lessons that begin within 24 hours from booking.`,
    's_credit.tooltip_makeup',
    `以预约时间为准，当日课程券是只能预约24小时内开始课程的课程券，可在【1对1课程】>【预约】>【可预约的当天课程】中使用。`,
    `以預約時間爲準，當日課程券是只能預約24小時內開始課程的課程券，可在【1對1課程】>【預約】>【可預約的當天課程】中使用。`,
    `当日レッスンチケットは予約時点24時間以内に開始するレッスンのみ予約が可能なため、[1on1レッスン]>[予約]>[予約可能な当日レッスン]から使用できます。`,
    `Phiếu học trong ngày chỉ có thể đặt trước được các lớp học bắt đầu trong vòng 24 giờ trong thời gian tiêu chuẩn đặt chỗ, với phiếu này thì có thể sử dụng tại [Tiết học 1:1] > [Tiết học đặt trước] > [Tiết học cùng ngày có thể đặt trước]`,
  ],
  tooltip_limited: [
    `시간한정수업권은 정해진 시간대의 수업만 예약할 수 있는 수업권입니다.
(새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited Window credits can only be used to book lessons for certain time periods. (Early bird: 5:00-8:00 am (KST), Night owl: Midnight-03:00 am (KST))`,
    's_credit.tooltip_limited',
    `限时课程券是只能预约规定时间段课程的课程券。

（凌晨班：上午5点至上午8点（KST），深夜班：凌晨0点至凌晨3点（KST））`,
    `限時課程券是只能預約規定時間段課程的課程券。

（凌晨班：上午5點至上午8點（KST），深夜班：凌晨0點至凌晨3點（KST））`,
    `時間限定レッスンチケットは決まった時間帯のレッスンのみ予約可能なレッスンチケットです。
（早朝クラス：午前5時～8時(JST)、深夜クラス：午前0時～午前3時(JST)）`,
    `Phiếu học giới hạn thời gian là phiếu học chỉ có thể đặt trước các lớp học trong thời gian quy định.
 (Lớp sáng: 5h - 8h (KST), Lớp tối: 12h - 3h (KST)`,
  ],
  tooltip_urgent: [
    `긴급편성수업권은 예약 시점 기준 2시간 이내 시작하는 수업만
예약할 수 있는 수업권으로, [1:1수업]>[예약]>[예약 가능한
당일수업] 에서 사용하실 수 있습니다.`,
    `FastBook credits can only be used to book lessons that begin within 2 hours from booking. You can use FastBook credits by going to [1:1 Lessons] > [Book Lessons] > [Last-minute Lessons].`,
    's_credit.tooltip_urgent',
    `以预约时间为准，紧急编排课程券是只能预约2小时内开始课程的课程券，可在【1对1课程】>【预约】>【可预约的当天课程】中使用。`,
    `以預約時間爲準，緊急編排課程券是只能預約2小時內開始課程的課程券，可在【1對1課程】>【預約】>【可預約的當天課程】中使用。`,
    `サプライズチケットは予約時点基準の2時間以内に開始するレッスンのみ予約可能なレッスンチケットのため、[1on1レッスン]>[予約]>[予約可能な当日レッスン]から使用できます。`,
    `Phiếu học tập khẩn cấp chỉ có thể đặt trước các lớp học bắt đầu trong vòng 2 giờ tính theo thời gian đặt chỗ, có thể sử dụng trong [Lớp học 1:1>>[Lớp học đặt trước]>[Lớp học cùng ngày có thể đặt trước]`,
  ],
  tooltip_not_started: [
    <>
      첫 수업 수강 전까지는 수강기간이 차감되지 않습니다.
      <br />
      (단, 구매 1년 뒤 자동 차감 시작)
    </>,
    `Expiration dates are frozen until you complete your first lesson. (However, expiration dates will begin to diminish automatically after one year from purchase date.)`,
    's_credit.tooltip_not_started',
    `开始听第一节课之前不扣除听课时间。（但是，在购买课程券一年后将自动扣除使用期限。）`,
    `開始第一節課之前不扣除使用期限。（但是，在購買課程券一年後將自動扣除使用期限。）`,
    `初回レッスン受講前までは受講期間がカウントされません。
（ただし、購入1年後は自動的にカウント開始）`,
    `Thời gian học sẽ không bị trừ trước tiết học đầu tiên.
 (Tuy nhiên, một năm sau khi mua sẽ bắt đầu khấu trừ tự động)`,
  ],
  tooltip_btn_2: [
    <>
      1. 매주 수업 듣기 수업권과 무료 수업권은 수강기간을 연장할 수 없습니다.
      <br />
      2. 수업권 만료 시 수강기간을 연장할 수 없습니다.
      <br />
      3. 수강기간 연장은 수강기간이 절반 이하 남은 시점부터 가능합니다.
    </>,
    <>
      1. Expiration dates of Every Week credits and free credits cannot be extended.
      <br />
      2. Expiration dates cannot be extended after the credit has expired.
      <br />
      3. Expiration dates can be extended when there are less than half of the lesson days left.{' '}
    </>,
    's_credit.tooltip_btn_2',
    <>
      1.一週一次课程券以及免费课程券都无法延长使用时间。
      <br />
      2.听课时间到期后不可延长。
      <br />
      3.听课时间自还剩不到一半时起可以延长听课时间。
    </>,
    <>
      1.一週一次課程券以及免費課程券都無法延長使用時間。
      <br />
      2.使用期限到期後不可延長。
      <br />
      3.使用期限自還剩不到一半時起可以延長使用時間。
    </>,
    <>
      1.毎週レッスンを受けるレッスンチケットと無料のレッスンチケットの受講期間は延長することはできません。
      <br />
      2.受講期間満了時の延長はできません。
      <br />
      3.受講期間の延長は受講期間が半分よりも少なくなった時点から可能です。
    </>,
    <>
      1. Thẻ học hàng tuần và thẻ học miễn phí không thể gia hạn thời gian.
      <br />
      2. Không thể gia hạn thời gian học khi hết hạn.
      <br />
      3. Có thể gia hạn thời gian học từ thời điểm thời gian học còn lại dưới một nửa.
    </>,
  ],
  lessons_cancelled_within_2_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.lessons_cancelled_within_2_hours',
    <>课程 {h}小时内取消的课程。</>,
    <>課程 {h}小時內取消的課程。</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです</>,
    <>Đây là tiết học đã bị hủy trong thời gian học tiết học.</>,
  ],
  no_show: [
    `수업 노쇼로 수업권이 차감되었습니다.`,
    `A credit was deducted for the no-show lesson.`,
    's_credit.no_show',
    `由于课程缺席，课程券被扣除了。`,
    `由於缺席課程，課程券被扣除了。`,
    `レッスンの無断欠席でレッスンチケットが差し引かれました。`,
    `Phiếu học tập đã bị trừ vì lý do bùng tiết học.`,
  ],
  lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 당일수업권이 제공되었습니다.</>,
    <>
      A last-minute credit has been issued for the lesson that was cancelled within {h} hours before it was scheduled to
      start.
    </>,
    's_credit.lessons_cancelled_within_24_hours',
    <>由于{h}小时内取消，您获得了当日课程券。</>,
    <>由於{h}小時內取消，您獲得了當日課程券。</>,
    <>{h}時間以内のキャンセルで当日レッスンチケットがプレゼントされました</>,
    <>Vé học trong ngày đã được cung cấp do hủy trong thời gian {h}.</>,
  ],
  hours: [`시간`, `Time`, 's_credit.hours', `时间`, `時間`, `時間`, `Giờ`],
  last_minute_lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.last_minute_lessons_cancelled_within_24_hours',
    <>课程 {h}小时内取消的课程。</>,
    <>課程 {h}小時內取消的課程。</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです</>,
    <>Đây là tiết học đã bị hủy trong thời gian học tiết học.</>,
  ],
  note: [
    `새로워진 [수업권/증명서류] 페이지를 확인해보세요.`,
    `Check out our renewed [Credits] page.`,
    's_credit.note',
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
  ],
  note_btn: [`안내문 보기`, `Learn more`, 's_credit.note_btn', `Learn more`, `Learn more`, `Learn more`, `Learn more`],
  changed: [`(변경됨)`, `(Exchanged)`, 's_credit.changed', `(Exchanged)`, `(Exchanged)`, `(Exchanged)`, `(Exchanged)`],
  date_expired: [`-`, `-`, 's_credit.date_expired', `-`, `-`, `-`, `-`],
  done: [`사용 완료`, `Used`, 's_credit.done', `使用完毕`, `使用完畢`, `使用完了`, `Hoàn thành việc sử dụng`],
}
export const s_mypage_noti = {
  n_hours_ago: (n) => [
    `${n} 시간 전`,
    `${n} hour before lesson`,
    's_mypage_noti.n_hours_ago',
    `${n}小时前`,
    `${n}小時前`,
    `${n} 時間前`,
    `${n} giờ trước buổi học`,
  ],
  email: [`이메일`, `Email`, 's_mypage_noti.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  appo_push_noti: [
    `앱 푸시`,
    `App Push`,
    's_mypage_noti.appo_push_noti',
    `应用程序推送`,
    `APP推播通知`,
    `アプリプッシュ通知`,
    `Thông báo trên ứng dụng`,
  ],
  sms: [`SMS`, `SMS (Text message)`, 's_mypage_noti.sms', `SMS`, `簡訊`, `SMS`, `SMS (Text message)`],
  select_noti_times: [
    `알림 시간 (2개까지 설정 가능)`,
    `Notification Time (Up to 2)`,
    's_mypage_noti.select_noti_times',
    `提醒时间(最多可设定2个)`,
    `通知時間（最多可設定2個）`,
    `通知時間 (2つまで設定可能)`,
    `Số lần thông báo (Tối đa 2)`,
  ],
  saved: [`저장됨`, `Saved`, 's_mypage_noti.saved', `已保存`, `已儲存`, `保存済み`, `Đã lưu`],
  ringle_benefit: [
    `링글 소식 & 혜택`,
    `Ringle Newsletter`,
    's_mypage_noti.ringle_benefit',
    `Ringle消息 & 优惠`,
    `Ringle消息 & 優惠`,
    `Ringleニュース & 特典`,
    `Ringle Newsletter`,
  ],
  ringle_benefit_desc: [
    `링글 뉴스레터, 할인 혜택, 이벤트, 콘텐츠 업데이트 소식 등을 받으실 수 있습니다.`,
    `You will receive Ringle's exclusive promotions and latest news.`,
    's_mypage_noti.ringle_benefit_desc',
    `可接收到Ringle新闻邮件, 优惠信息, 活动, 内容更新等信息`,
    `您可接收Ringle電子報、折扣優惠、活動、內容更新等。`,
    `Ringleニュースレター、割引特典、イベント、コンテンツアップデートのお知らせなどをお受け取りいただけます。`,
    `Bạn sẽ nhận ưu đãi đặc biệt từ Ringle và những tin tức mới nhất.`,
  ],
  lesson_reminder: [
    `1:1 수업 리마인더`,
    `Lesson Reminder`,
    's_mypage_noti.lesson_reminder',
    `1:1课程提醒`,
    `1:1課程備忘錄`,
    `1on1レッスンリマインダー`,
    `Nhắc buổi học`,
  ],
  lesson_reminder_desc: [
    `1:1 수업의 학습 효과를 높일 수 있도록 다양한 리마인더를 받을 수 있습니다.`,
    `You will receive various reminders to help you stay on top of your lessons.`,
    's_mypage_noti.lesson_reminder_desc',
    `为提升1:1 课程的学习效果, 可接收多种提醒。`,
    `為提升1:1課程的學習效果，您可接收各種提醒通知。`,
    `1on1レッスンの学習効果を高めることができるように多様なリマインダーをお受け取りいただけます。`,
    `Bạn sẽ nhận được các thông báo nhắc khác nhau để đảm bảo sự chuyên cần.`,
  ],
  google_calendar: [
    `구글 캘린더 초대장`,
    `Google Calendar Invites`,
    's_mypage_noti.google_calendar',
    `谷歌日历邀请函`,
    `Google日歷邀請`,
    `Googleカレンダー招待状`,
    `Mời bằng Google Calendar`,
  ],
  calendar_schedule: [
    `신청한 1:1 수업의 일정`,
    `1:1 Lesson schedule`,
    's_mypage_noti.calendar_schedule',
    `申请的1:1 课程日程`,
    `申請的1:1課程日程`,
    `予約した1on1レッスンの日程`,
    `Lịch Buổi học 1:1`,
  ],
  calendar_desc: [
    `알림 수신 방법으로 선택한 이메일 계정으로 신청한 수업의 일정이 구글 캘린더 초대장으로 발송됩니다.`,
    `Lessons will automatically be added to your Google Calendar.`,
    's_mypage_noti.calendar_desc',
    `谷歌日历邀请函将申请的课程日程发送至提醒接收方式中设定的电子邮箱。`,
    `申請的課程日程將藉由Google日歷邀請發送至設為通知接收方式的電子信箱。`,
    `通知受信方法で選択したメールアドレスに、予約したレッスンの日程がGoogleカレンダーの招待状として送信されます。`,
    `Buổi học sẽ được tự động thêm vào Google Calendar của bạn.`,
  ],
  lesson_schedule: [
    `1:1 수업 스케줄 업데이트`,
    `Lesson Availability`,
    's_mypage_noti.lesson_schedule',
    `1:1 课程日程更新`,
    `1:1上課日程更新`,
    `1on1レッスンスケジュールアップデート`,
    `Buổi học trống lịch`,
  ],
  lesson_schedule_desc: [
    `찜한 튜터의 수업 스케줄이 업데이트되었을 때, 24시간 이내 시작하는 수업이 이용 가능할 때 알림을 받으실 수 있습니다.`,
    `You will be notified when your favorite tutors update their availabilities or a slot opens up.`,
    's_mypage_noti.lesson_schedule_desc',
    `收藏教师的课程日程更新或出现24小时内可进行的课程时, 将获得提醒。`,
    `當收藏的任課教師更新上課日程或有24小時以內開始的課程時，您可接收通知。`,
    `お気に入りチューターのレッスンスケジュールがアップデートされた時や、24時間以内に始まるレッスンの予約が可能な時にお知らせをお受け取りいただけます。`,
    `Bạn sẽ được thông báo khi gia sư yêu thích của bạn cập nhật lịch trống hoặc có vị trí được mở.`,
  ],
  notification_my_tutor: [
    `찜한 튜터의 수업 스케줄 업데이트`,
    `Updates to my Favorite Tutors' availability`,
    's_mypage_noti.notification_my_tutor',
    `收藏教师的课程日程更新`,
    `我收藏的任課教師上課日程更新`,
    `お気に入りチューターのレッスンスケジュールアップデート`,
    `Cập nhật lịch trống gia sư yêu thích của tôi`,
  ],
  lesson_in_24: [
    `24시간 이내 가능한 수업`,
    `Available Lessons in 24 Hours`,
    's_mypage_noti.lesson_in_24',
    `24小时以内可进行的课程`,
    `24小時以內可聆聽的課程`,
    `24時間以内に可能なレッスン`,
    `Buổi học trống lịch trong 24 giờ`,
  ],
  lesson_in_24_my_tutor: [
    `찜한 튜터의 수업만 알림 받기`,
    `Only notify me when my favorite tutors have a new availability in 24 hours`,
    's_mypage_noti.lesson_in_24_my_tutor',
    `只接收收藏教师的提醒`,
    `僅接收我收藏的任課教師課程通知`,
    `お気に入りチューターのレッスンのみお知らせを受け取る`,
    `Chỉ thông báo khi gia sư yêu thích của tôi trống lịch trong 24 giờ`,
  ],
  receive_email: [
    `이메일`,
    `Receive Email`,
    's_mypage_noti.receive_email',
    `电子邮箱`,
    `電子信箱`,
    `メールアドレス`,
    `Nhận Email`,
  ],
  sms_info: [
    `휴대폰 번호`,
    `Receive SMS (Text Message)`,
    's_mypage_noti.sms_info',
    `手机号码`,
    `手機號碼`,
    `携帯電話番号`,
    `Nhận tin nhắn điện thoại`,
  ],
  verified: [
    `인증 및 휴대폰 번호 변경이 완료 되었습니다.`,
    `Verification complete.`,
    's_mypage_noti.verified',
    `已完成验证及手机号码变更。`,
    `驗證及手機號碼變更成功。`,
    `認証および携帯電話番号の変更が完了しました。`,
    `Xác minh hoàn tất.`,
  ],
  phone: [
    `휴대폰 번호`,
    `Phone number`,
    's_mypage_noti.phone',
    `手机号码`,
    `手機號碼`,
    `携帯電話番号`,
    `Số điện thoại`,
  ],
  new_db_update: [
    `신규 Daily Brief 업데이트`,
    `New Daily Brief update`,
    's_mypage_noti.new_db_update',
    `最新的Daily Brief更新`,
    `全新Daily Brief更新`,
    `新規Daily Briefアップデート`,
    `Daily Brief mới cập nhật`,
  ],
  live_webinar_invitation: [
    `예약한 LIVE 웨비나의 일정`,
    `RSVP-ed LIVE Webinars`,
    's_mypage_noti.live_webinar_invitation',
    `预约的LIVE网络研讨会日程`,
    `已預約的LIVE網路研討會日程`,
    `予約したLIVE Webinarの日程`,
    `Đã xác nhận đăng ký Webinar trực tuyến`,
  ],
  live_webinar_app_push: [
    `리마인더 수신 방법`,
    `Delivery`,
    's_mypage_noti.live_webinar_app_push',
    `提醒接收方法`,
    `備忘錄接收方式`,
    `リマインダー受信方法`,
    `Gửi thông báo`,
  ],
  before_hour_and_two_min: [
    `LIVE 당일 1시간 전, 그리고 당일 시작 2분 후 제공됩니다.`,
    `Will be sent 1 hour before and 2 minutes after the LIVE Webinar stars.`,
    's_mypage_noti.before_hour_and_two_min',
    `LIVE当日1小时前及开始2分钟后提供。`,
    `於LIVE當天1小時前及當天開始2分鐘後提供。`,
    `LIVE当日の1時間前と当日の開始2分後に提供されます。`,
    `Sẽ được gửi trước 1 giờ và 2 phút sau khi Webinar trực tuyến bắt đầu.`,
  ],
  notification_detail: [
    `알림 세부 설정`,
    `Notification Settings`,
    's_mypage_noti.notification_detail',
    `设置提醒细节`,
    `詳細通知設定`,
    `通知詳細設定`,
    `Cài đặt thông báo`,
  ],
  change: [`변경`, `Update`, 's_mypage_noti.change', `变更`, `變更`, `変更`, `Cập nhật`],
  daily_brief: {
    title: [
      `Daily Brief 업데이트`,
      `Daily Brief`,
      's_mypage_noti.daily_brief.title',
      `Daily Brief更新`,
      `Daily Brief更新`,
      `Daily Briefアップデート`,
      `Daily Brief`,
    ],
    desc: [
      `월~금 매일 업데이트되는 Daily Brief 알림을 받으실 수 있습니다.`,
      `You can receive Daily Briefs every weekday.`,
      's_mypage_noti.daily_brief.desc',
      `周一~五, 可每日接收Daily Brief的更新提醒。`,
      `您可接收週一～五每日更新的Daily Brief通知。`,
      `月~金まで毎日アップデートされるDaily Briefのお知らせをお受け取りいただけます。`,
      `Bạn có thể nhận Daily Brief mỗi tuần.`,
    ],
  },
  live_webinar: {
    title: [
      `Live Webinar 리마인더`,
      `Live Webinar`,
      's_mypage_noti.live_webinar.title',
      `Live Webinar提醒`,
      `Live Webinar備忘錄`,
      `Live Webinarリマインダー`,
      `Webinar trực tuyến`,
    ],
    desc: [
      `매 주말 진행되는 링글 웹세미나 리마인더를 받으실 수 있습니다.`,
      `You can receive weekly notifications about Live Webinars.`,
      's_mypage_noti.live_webinar.desc',
      `每周末可接收Ringle网络研讨会提醒。`,
      `您可接收每週末進行的Ringle網路研討會備忘錄。`,
      `毎週末行われるRingleウェブセミナーのリマインダーをお受け取りいただけます。`,
      `Bạn sẽ nhận thông báo hằng tuần về Webinar trực tuyến.`,
    ],
  },
  change_email: [
    `이메일 변경`,
    `Change email`,
    's_mypage_noti.change_email',
    `电子邮箱变更`,
    `變更電子信箱`,
    `メールアドレス変更`,
    `Đổi email`,
  ],
  not_selected: [
    `선택하지 않음`,
    `Not Selected`,
    's_mypage_noti.not_selected',
    `未选`,
    `未選擇`,
    `未選択`,
    `Không chọn`,
  ],
  save: [`저장`, `Save`, 's_mypage_noti.save', `保存`, `儲存`, `保存`, `Lưu`],
  cancel: [`취소`, `Cancel`, 's_mypage_noti.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  enroll_phone_number: [
    `휴대폰 번호 등록`,
    `Register your mobile number`,
    's_mypage_noti.enroll_phone_number',
    `手机号码登录`,
    `登錄手機號碼`,
    `携帯電話番号登録`,
    `Đăng ký số điện thoại của bạn`,
  ],
  phone_number_verification: [
    `전화번호 인증`,
    `Phone number verification`,
    's_mypage_noti.phone_number_verification',
    `电话号码验证`,
    `電話號碼驗證`,
    `SMS認証`,
    `Số điện thoại xác minh`,
  ],
  veri_number: [
    `인증번호`,
    `Verification code`,
    's_mypage_noti.veri_number',
    `验证码`,
    `驗證碼`,
    `認証コード`,
    `Mã xác minh`,
  ],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_re_request',
    `重新发送验证码`,
    `重新傳送驗證碼`,
    `認証コード再リクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_request',
    `发送验证码`,
    `傳送驗證碼`,
    `認証コードリクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  lesson_reminder_settings: [
    `수업 리마인더 설정`,
    `Lesson Reminder Settings`,
    's_mypage_noti.lesson_reminder_settings',
    `设置课程提醒`,
    `課程備忘錄設定`,
    `レッスンリマインダー設定`,
    `Cài đặt nhắc buổi học`,
  ],
  notification_channel: [
    `알림 채널`,
    `Notification Channel`,
    's_mypage_noti.notification_channel',
    `提醒频道`,
    `通知頻道`,
    `お知らせチャンネル`,
    `Kênh thông báo`,
  ],
  db_update_time: [
    `업데이트 시간 설정`,
    `App Push Schedule`,
    's_mypage_noti.db_update_time',
    `更新时间设定`,
    `更新時間設定`,
    `アップデート時間設定`,
    `Thông báo thời khoá biểu`,
  ],
  verify: [`입력 완료`, `Verify`, 's_mypage_noti.verify', `输入完成`, `輸入完畢`, `入力完了`, `Xác minh`],
  sms_kakao: [
    `SMS·알림톡`,
    `Text/Kakao`,
    's_mypage_noti.sms_kakao',
    `SMS·短信通知`,
    `簡訊·訊息通知`,
    `SMS・お知らせトーク`,
    `Tin nhắn/Kakao`,
  ],
  app_push: [
    `앱 푸시`,
    `App Push`,
    's_mypage_noti.app_push',
    `软件推送`,
    `APP推播通知`,
    `アプリのプッシュ`,
    `Thông báo điện thoại`,
  ],
  googlecalendar: [`이메일`, `Email`, 's_mypage_noti.googlecalendar', `电子邮箱`, `電子信箱`, `Email`, `Email`],
  googlecalendar_guide: [
    `구글 가입 계정에 한하여 예약한 수업 일정을 구글 캘린더 초대장으로 받을 수 있습니다.`,
    `Get calendar invites for scheduled lessons (only for users signed up with Google)`,
    's_mypage_noti.googlecalendar_guide',
    `限于谷歌账号，可通过谷歌日历邀请函收到预约的课程。`,
    `僅限使用Google註冊的帳戶可藉由Google日曆邀請接收預約的課程日程。`,
    `Googleの加入アカウントに限って予約したレッスンの日程をGoogleカレンダーの招待状から受け取ることができます。`,
    `Nhận thông báo trên lịch cho những buổi học đã đặt (chỉ áp dụng cho học viên đăng ký bằng tài khoản Google)`,
  ],
  creditinfo: {
    title: [`수업권/포인트/쿠폰 정보`, ``, 's_mypage_noti.creditinfo.title', ``, ``, ``, ``],
    credit_info: [
      `수업권·포인트`,
      `Credits & points`,
      's_mypage_noti.creditinfo.credit_info',
      `课程券，积分`,
      `課程券·點數`,
      `レッスンチケット・ポイント`,
      `Thẻ học & điểm`,
    ],
    credit_chanel: [
      `수업권`,
      `Credits`,
      's_mypage_noti.creditinfo.credit_chanel',
      `课程券`,
      `課程券`,
      `レッスンチケット・ポイント`,
      `Thẻ học`,
    ],
    credit_guide: [`FALSE`, ``, 's_mypage_noti.creditinfo.credit_guide', ``, ``, `お客様の設定価格`, ``],
    point_info: [
      `수업권의 수강기간 만료 알림을 받을 수 있습니다.`,
      `Get alerts for expiring credits`,
      's_mypage_noti.creditinfo.point_info',
      `可收到课程券的使用期限到期通知。`,
      `可接收課程券的使用期限到期通知。`,
      `レッスンチケットの利用期間満了のお知らせを受け取ることができます。`,
      `Nhận thông báo khi thẻ học sắp hết hạn`,
    ],
    point_chanel: [`포인트`, `Points`, 's_mypage_noti.creditinfo.point_chanel', `积分`, `點數`, `ポイント`, `Điểm`],
    point_guide: [`FALSE`, ``, 's_mypage_noti.creditinfo.point_guide', ``, ``, `お客様の設定価格`, ``],
  },
  lesson: {
    title: [
      `포인트 적립과 만료 알림을 받을 수 있습니다.`,
      `Get alerts for earned or expiring points`,
      's_mypage_noti.lesson.title',
      `可收到积分累积和积分到期通知。`,
      `可接收點數累積與到期通知。`,
      `ポイント確認のお知らせを受け取ることができます。`,
      `Nhận thông báo khi có điểm mới hoặc điểm hết hạn`,
    ],
    lesson_issue: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_issue', ``, ``, ``, ``],
    lesson_issue_chanel: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_issue_chanel', ``, ``, ``, ``],
    lesson_issue_guide: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_issue_guide', ``, ``, ``, ``],
    lesson_reminder: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_reminder', ``, ``, ``, ``],
    lesson_reminder_chanel: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_reminder_chanel', ``, ``, ``, ``],
    lesson_reminder_guide: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_reminder_guide', ``, ``, ``, ``],
    lessonalarm_time: [`알림 시간 (2개까지 설정가능)`, ``, 's_mypage_noti.lesson.lessonalarm_time', ``, ``, ``, ``],
    selectedtime1: [`FALSE`, ``, 's_mypage_noti.lesson.selectedtime1', ``, ``, ``, ``],
    selectedtime2: [`FALSE`, ``, 's_mypage_noti.lesson.selectedtime2', ``, ``, ``, ``],
    lesson_help: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_help', ``, ``, ``, ``],
    lesson_help_chanel: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_help_chanel', ``, ``, ``, ``],
    lesson_help_guide: [`FALSE`, ``, 's_mypage_noti.lesson.lesson_help_guide', ``, ``, ``, ``],
    tutorschedule: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule', ``, ``, ``, ``],
    tutorschedule_chanel: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule_chanel', ``, ``, ``, ``],
    tutorschedule_guide: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule_guide', ``, ``, ``, ``],
    tutorschedule1: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule1', ``, ``, ``, ``],
    tutorschedule2: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule2', ``, ``, ``, ``],
    tutorschedule3: [`FALSE`, ``, 's_mypage_noti.lesson.tutorschedule3', ``, ``, ``, ``],
    modal1: {
      title: [
        `알림 필수 항목`,
        `Notification required`,
        's_mypage_noti.lesson.modal1.title',
        `通知必要项目`,
        `通知必要項目`,
        `通知必須項目`,
        `Thông báo bắt buộc`,
      ],
      guide: [
        `하나 이상의 알림 채널을 꼭 설정해 주셔야 합니다.`,
        `Choose at least one notification channel.`,
        's_mypage_noti.lesson.modal1.guide',
        `请务必设定一个以上的通知频道。`,
        `必須設定一個以上的通知頻道。`,
        `一つ以上のお知らせチャンネルを必ず設定しなければなりません。`,
        `Chọn ít nhất một kênh thông báo.`,
      ],
      ok: [`확인`, `Confirm`, 's_mypage_noti.lesson.modal1.ok', `确认`, `確定`, `確認`, `Xác nhận`],
    },
  },
  content: {
    contentweekly: [`FALSE`, ``, 's_mypage_noti.content.contentweekly', ``, ``, ``, ``],
    contentweekly_chanel: [`FALSE`, ``, 's_mypage_noti.content.contentweekly_chanel', ``, ``, ``, ``],
    contentweekly_guide: [`FALSE`, ``, 's_mypage_noti.content.contentweekly_guide', ``, ``, ``, ``],
    dailybrief: [`FALSE`, ``, 's_mypage_noti.content.dailybrief', ``, ``, ``, ``],
    dailybrief_chanel: [`FALSE`, ``, 's_mypage_noti.content.dailybrief_chanel', ``, ``, ``, ``],
    dailybrief_guide: [`FALSE`, ``, 's_mypage_noti.content.dailybrief_guide', ``, ``, ``, ``],
    dbalarm_time: [`FALSE`, ``, 's_mypage_noti.content.dbalarm_time', ``, ``, ``, ``],
    timezone: [`FALSE`, ``, 's_mypage_noti.content.timezone', ``, ``, ``, ``],
    selecteddbtime: [`FALSE`, ``, 's_mypage_noti.content.selecteddbtime', ``, ``, ``, ``],
    ringlelive: [`FALSE`, ``, 's_mypage_noti.content.ringlelive', ``, ``, ``, ``],
    ringlelive_chanel: [`FALSE`, ``, 's_mypage_noti.content.ringlelive_chanel', ``, ``, ``, ``],
    ringlelive_guide: [`FALSE`, ``, 's_mypage_noti.content.ringlelive_guide', ``, ``, ``, ``],
    modal1: {
      title: [
        `Content Weekly 구독 취소 사유`,
        `Unsubscribe from Content Weekly`,
        's_mypage_noti.content.modal1.title',
        `Content Weekly 取消关注理由`,
        `Content Weekly訂閱取消原因`,
        `Content Weekly購読取り消しの理由`,
        `Hủy theo dõi Nội dung tuần`,
      ],
      guide: [
        `그동안 Content Weekly를 구독해 주셔서 감사합니다.
구독을 취소하는 이유를 알려주세요. (복수 선택 가능)`,
        `Before you go, would you be willing to tell us why you want to unsubscribe? (Choose one or more)`,
        's_mypage_noti.content.modal1.guide',
        `感谢您一直以来关注Content Weekly。
 请选择取消关注的理由。 (可多选)`,
        `感謝您一直以來訂閱Content Weekly，
請告訴我們取消訂閱的理由。（可複選）`,
        `今までContent Weeklyを購読していただきありがとうございます。
購読を取り消しする理由を教えてください。（複数選択可）`,
        `Trước khi hủy theo dõi, bạn có thể cho Ringle biết lí do tại sao không? (Có thể chọn nhiều đáp án)`,
      ],
      category1: [
        `영어 공부에 관심이 없어졌습니다.`,
        `I'm not interested in learning English anymore.`,
        's_mypage_noti.content.modal1.category1',
        `对英语没有兴趣了。`,
        `對英語學習不感興趣了。`,
        `英語の勉強に関心がなくなりました。`,
        `Tôi không hứng thú với việc học tiếng Anh.`,
      ],
      category2: [
        `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
        `Ringle content is not very effective for learning English or building insights.`,
        's_mypage_noti.content.modal1.category2',
        `Ringle内容对英语学习或Insight开发没有帮助。`,
        `Ringle內容對學習英語或開發見解沒有幫助。`,
        `Ringleのコンテンツが英語の勉強やインサイトの習得に貢献しませんでした。`,
        `Nội dung của Ringle không mang lại hiệu quả trong việc học tiếng Anhs.`,
      ],
      category3: [
        `시간이나 비용을 들이고 싶지 않습니다.`,
        `I don't want to invest any time or money into Ringle content.`,
        's_mypage_noti.content.modal1.category3',
        `不想花时间和费用。`,
        `不想花時間或費用。`,
        `時間や費用をかけたくありませんでした。`,
        `Tôi không muốn bỏ tiền để đọc những nội dung của Ringle.`,
      ],
      category4: [
        `다른 좋은 콘텐츠를 찾았습니다.`,
        `I have found better content somewhere else.`,
        's_mypage_noti.content.modal1.category4',
        `找到了其他好的项目。`,
        `已找到其他好內容。`,
        `他に良いコンテンツを探しました。`,
        `Tôi tìm được những nguồn nội dung tốt hơn.`,
      ],
      category5: [`기타`, `Others`, 's_mypage_noti.content.modal1.category5', `其他`, `其他`, `その他`, `Lí do khác`],
      placeholder: [
        `무엇인지 알려주세요!`,
        `Please specify.`,
        's_mypage_noti.content.modal1.placeholder',
        `请写下详细内容。`,
        `請告訴我們原因！`,
        `理由を教えてください、`,
        `Vui lòng nêu rõ.`,
      ],
      submit: [`제출하기`, `Submit`, 's_mypage_noti.content.modal1.submit', `提交`, `儲存`, `提出する`, `Gửi`],
      toast: [
        `Content Weekly 구독 완료`,
        `You have successfully subscribed to Content Weekly`,
        's_mypage_noti.content.modal1.toast',
        `Content Weekly 关注完毕`,
        `Content Weekly訂閱成功`,
        `Content Weeklyの購読を完了`,
        `Bạn đã đăng ký theo dõi Nội dung tuần`,
      ],
      toast2: [
        `Content Weekly 구독 취소 사유 제출 완료`,
        `You have successfully unsubscribed from Content Weekly`,
        's_mypage_noti.content.modal1.toast2',
        `Content Weekly 取消关注理由提交完毕`,
        `已提出Content Weekly訂閱取消原因`,
        `Content Weeklyの購読取り消し理由の提出完了`,
        `Bạn đã hủy theo dõi Nội dung tuần`,
      ],
    },
  },
  newsnbenfit: {
    title: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.title', ``, ``, ``, ``],
    ringlenewsletter: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter', ``, ``, ``, ``],
    ringlenewsletter_chanel: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter_chanel', ``, ``, ``, ``],
    ringlenewsletter_guide: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter_guide', ``, ``, ``, ``],
    marketinginfo: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.marketinginfo', ``, ``, ``, ``],
    marketinginfo_chanel: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.marketinginfo_chanel', ``, ``, ``, ``],
    marketinginfo_guide: [`FALSE`, ``, 's_mypage_noti.newsnbenfit.marketinginfo_guide', ``, ``, ``, ``],
  },
}
export const s_edit_profile = {
  timezone: [`타임존`, `Time zone`, 's_edit_profile.timezone', `时区`, `時區`, `タイムゾーン`, `Múi giờ`],
  change: [`변경`, `Change`, 's_edit_profile.change', `变更`, `變更`, `変更`, `Đổi`],
  korea_name: [`한국 이름`, `Name`, 's_edit_profile.korea_name', `中文姓名`, `中文姓名`, `氏名（日本語）`, `Tên`],
  company_name: [`회사 이름`, `Company`, 's_edit_profile.company_name', `公司名称`, `公司名稱`, `会社名`, `Công ty`],
  role: [`직책(역할)`, `Role`, 's_edit_profile.role', `职务(职责)`, `職責（角色）`, `役職(役割)`, `Chức vụ`],
  company_code: [
    `기업 맞춤 코드`,
    `B2B Partner code`,
    's_edit_profile.company_code',
    `企业专用代码`,
    `企業專用代碼`,
    `企業専用コード`,
    `Mã code đối tác B2B`,
  ],
  company_code_only_once_it_can_be_entered: [
    `기업 맞춤 코드는 1회에 한하여, 입력이 가능합니다. 기업 맞춤 코드 변경을 원하신다면 하단의 1:1 문의 해주시면 됩니다.`,
    `You can only enter one B2B Partner code. If you need to change your code, please contact us via chat below.`,
    's_edit_profile.company_code_only_once_it_can_be_entered',
    `企业特定代码仅限输入1次, 如想变更企业特定代码, 请联系下方的在线咨询。`,
    `企業專用代碼僅限輸入1次。如欲變更企業專用代碼，請透過下方的聊天聯絡我們。`,
    `企業専用コードは1回に限り入力可能です。企業専用コードの変更をご希望の場合は、下のチャネルトークからお問い合わせください。`,
    `Bạn chỉ có thể nhập một mã đối tác B2B. Nếu bạn cần đổi mã, vui lòng chat với chúng tôi.`,
  ],
  inquiries_about_the_company_codes_are_let_us_know_by_channeltalk: [
    `기업 맞춤 코드에 대한 문의는 1) 재직중인 회사 2) 링글 아이디와 함께 홈페이지 하단의 1:1 문의로 남겨주시면 됩니다.`,
    `For B2B Partner code related inquiries, please contact us via chat below and leave us your current company name and Ringle ID.`,
    's_edit_profile.inquiries_about_the_company_codes_are_let_us_know_by_channeltalk',
    `有关企业特定代码的问题, 请联系主页下方在线咨询, 同时提供您的1) 在职中的公司 2) Ringle ID`,
    `有關企業專用代碼的相關諮詢，請於下方的聊天連同1) 任職的公司 2) Ringle的帳號一併留言。`,
    `企業専用コードに関するお問い合わせは、1)在職中の会社、2)Ringle IDと共にホームページ下のチャネルトークからお願いいたします。`,
    `Đối với các câu hỏi liên quan đến mã đối tác B2B, vui lòng chat với chúng tôi và để lại tên công ty bạn đang làm và Ringle ID.`,
  ],
  channel_talk_contact: [
    `1:1 문의`,
    `Contact us via Chat`,
    's_edit_profile.channel_talk_contact',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi qua chat`,
  ],
  save: [`저장`, `Save`, 's_edit_profile.save', `保存`, `儲存`, `保存`, `Lưu`],
  set_time_zone: [
    `타임존 설정`,
    `Set time zone`,
    's_edit_profile.set_time_zone',
    `设置时区`,
    `設定時區`,
    `タイムゾーン設定`,
    `Cài đặt múi giờ`,
  ],
  it_has_been_saved: [
    `저장되었습니다.`,
    `Saved`,
    's_edit_profile.it_has_been_saved',
    `已保存。`,
    `儲存成功。`,
    `保存されました。`,
    `Đã lưu`,
  ],
  complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_: [
    <>
      프로필을 완성하고, <span style={{ color: '#d3c8fc' }}>10,000P</span>를 받으세요! 튜터가 고객님을 위한 맞춤 수업을
      하는데 도움이 됩니다.
    </>,
    <>
      Complete your profile and earn&nbsp;
      <span style={{ color: '#d3c8fc' }}>$8.62P</span>!
    </>,
    's_edit_profile.complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_',
    <>
      完成简介, 获得<span style={{ color: '#d3c8fc' }}>$8.62P</span>
      吧! 有助于教师为学员提供更好的定制型课程。
    </>,
    <>
      完成簡介，領取<span style={{ color: '#d3c8fc' }}>$8.62P</span>
      吧！有助於任課教師為您提供客製化課程。
    </>,
    <>
      プロフィールを完成させて、 <span style={{ color: '#d3c8fc' }}>$8.62P</span>を
      受け取りましょう！チューターがお客様のためのカスタマイズレッスンをするのに役立ちます。
    </>,
    <>
      Hoàn thành hồ sơ của bạn và nhận&nbsp;
      <span style={{ color: '#d3c8fc' }}>$8.62P</span>!
    </>,
  ],
  haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_: [
    <>
      효과적인 맞춤 수업 위해
      <span className="text-purple-500"> 프로필 완성</span>하고
      <span className="text-blue-500"> 10,000 포인트</span> 적립 받으세요.
    </>,
    <>
      <span className="text-purple-500">Complete your profile</span> for a better Ringle class experience &
      <span className="text-blue-500"> receive $8.62 points</span>!
    </>,
    's_edit_profile.haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_',
    <>
      为打造更加有效的定制型课程
      <span className="text-purple-500">完成简介</span>领取
      <span className="text-blue-500">$8.62积分</span>吧。
    </>,
    <>
      為提供更有效果的客製化課程， 請<span className="text-purple-500">完成簡介</span>， 並領取
      <span className="text-blue-500">$8.62P</span>吧！
    </>,
    <>
      効果的なカスタマイズレッスンのために
      <span className="text-purple-500"> プロフィールを完成</span>させて
      <span className="text-blue-500">$8.62ポイント</span> を獲得しましょう。
    </>,
    <>
      <span className="text-purple-500">Hoàn thành thông tin cá nhân</span> cho trải nghiệm lớp học Ringle tốt hơn &
      <span className="text-blue-500">nhận $8.62 điểm</span>!
    </>,
  ],
  english_name: [
    `영어 이름`,
    `English Name`,
    's_edit_profile.english_name',
    `英文姓名`,
    `英文姓名`,
    `氏名（英語）`,
    `Tên Tiếng Anh`,
  ],
  occupation: [`직업`, `Occupation`, 's_edit_profile.occupation', `职业`, `職業`, `職業/職種`, `Nghề nghiệp`],
  purpose_of_usaing_ringle: [
    `링글 사용 목적`,
    `Purpose of Using Ringle`,
    's_edit_profile.purpose_of_usaing_ringle',
    `选择使用Ringle的目的`,
    `Ringle使用目的`,
    `Ringleの使用目的`,
    `Lí do đến với Ringle`,
  ],
  self_introduction_in_english: [
    `영어 자기 소개`,
    `About Me`,
    's_edit_profile.self_introduction_in_english',
    `英文自我介绍`,
    `英文自我介紹`,
    `英語で自己紹介`,
    `Về tôi`,
  ],
  profile_movie: [
    `프로필 영상`,
    `Profile Video`,
    's_edit_profile.profile_movie',
    `简介短片`,
    `簡介影片`,
    `プロフィール動画`,
    `Video thông tin cá nhân`,
  ],
  required: [`필수`, `Required`, 's_edit_profile.required', `必需`, `必填`, `必須`, `Bắt buộc`],
  optional: [`선택`, `Optional`, 's_edit_profile.optional', `选择`, `選填`, `選択`, `Không bắt buộc`],
  enter_your_basic_info_in_english_: [
    `영어로 고객님에 대한 기본 소개를 입력해주세요.`,
    `Please tell us about yourself in English.`,
    's_edit_profile.enter_your_basic_info_in_english_',
    `请使用英文填写您的基本介绍`,
    `請以英文簡單介紹您自己。`,
    `英語であなたについて基本的な紹介を入力してください。`,
    `Vui lòng chia sẻ về bản thân bạn bằng tiếng Anh.`,
  ],
  change_video: [`변경하기`, `Change video`, 's_edit_profile.change_video', `变更`, `變更`, `変更する`, `Đổi video`],
  shoot_video: [
    `동영상 촬영`,
    `Record video`,
    's_edit_profile.shoot_video',
    `拍摄视频`,
    `拍攝影片`,
    `動画撮影`,
    `Quay video`,
  ],
  profile_photo: [
    `프로필 사진`,
    `Profile Photo`,
    's_edit_profile.profile_photo',
    `简介头像`,
    `大頭照`,
    `プロフィール写真`,
    `Ảnh đại diện`,
  ],
  occupation_company: [
    `직업`,
    `Occupation`,
    's_edit_profile.occupation_company',
    `职业`,
    `職業`,
    `職業/職種`,
    `Nghề nghiệp`,
  ],
  complete_my_profile_for_a_lesson: [
    <>
      수업 전 튜터가 내게 꼭 맞는 수업을 준비할 수 있도록&nbsp;
      <Span primary>내 프로필</Span>을 완성해 보세요!
    </>,
    <>
      Please complete <Span primary>your profile</Span> for a better class experience!
    </>,
    's_edit_profile.complete_my_profile_for_a_lesson',
    <>
      课程开始前为帮助教师准备更适合您的课程内容&nbsp; 请完成<Span primary>我的简介</Span>!
    </>,
    <>
      為使任課教師於課程開始前能為我準備合適的課程，&nbsp; 請完成<Span primary>我的簡介</Span>！
    </>,
    <>
      レッスン前にチューターがあなたにぴったり合うレッスンを準備できるように&nbsp;
      <Span primary>マイプロフィール</Span>を完成させましょう！
    </>,
    <>
      Hãy hoàn thiện <Span primary>hồ sơ của bạn</Span> để có trải nghiệm tốt nhất!
    </>,
  ],
  more_effective_lessons: [
    <>
      여러분을 잘 알고 있는 튜터와 <Span primary>보다 더 효과적인 수업</Span>을 진행할 수 있어요.
    </>,
    ``,
    's_edit_profile.more_effective_lessons',
    <>
      和熟知您的教师一起<Span primary>开展更为有效的</Span>课程吧。
    </>,
    <>
      可與熟悉您的任課教師<Span primary>進行更有效果的課程</Span>。
    </>,
    <>
      皆さんをよく知っているチューターと <Span primary>より効果的なレッスン</Span>を 行いましょう。
    </>,
    ``,
  ],
  event_profile_points: [
    <>
      <span className="text-blue-500">[Event]</span> 성공적으로 내 프로필을 완성하면
      <span className="text-blue-500">10,000포인트</span>가 적립됩니다.
    </>,
    <>
      <span>[Event]</span> Complete your profile and earn $8.62P.
    </>,
    's_edit_profile.event_profile_points',
    <>
      <span className="text-blue-500">[Event]</span>成功完成我的简介时
      <span className="text-blue-500">可获得$8.62积分</span>。
    </>,
    <>
      <span className="text-blue-500">[Event]</span>成功完成我的簡介時，
      <span className="text-blue-500">將累積$8.62P</span>。
    </>,
    <>
      <span className="text-blue-500">[Event]</span> マイプロフィールを完成させると
      <span className="text-blue-500">$8.62ポイント</span>が付与されます。
    </>,
    <>
      <Span warning>[Event]</Span> Hoàn thiện hồ sơ của bạn và nhận $8.62 điểm.
    </>,
  ],
  event_only_tutor: [
    <>작성한 프로필은 수업 진행을 확정한 튜터만 확인할 수 있고, 언제든 수정할 수 있습니다.</>,
    <>Only tutors can see your profile and it can be modified at any time.&nbsp;</>,
    's_edit_profile.event_only_tutor',
    <>您的简介仅限确定授课的教师进行浏览, 同时您可随时对简介进行修改。</>,
    <>僅確定授課的任課教師可確認您填寫的簡介， 並且您隨時可以修改。</>,
    <>
      作成したプロフィールはレッスン進行が確定したチューターのみ確認することができ、いつでも編集することが できます。
    </>,
    <>Chỉ gia sư của bạn thấy hồ sơ của bạn và có thể thay đổi bất cứ lúc nào.&nbsp;</>,
  ],
  required_menu: [
    `필수 항목`,
    `Required`,
    's_edit_profile.required_menu',
    `必需项目`,
    `必填項目`,
    `必須項目`,
    `Bắt buộc`,
  ],
  optional_menu: [
    `선택 항목`,
    `Optional`,
    's_edit_profile.optional_menu',
    `选择项目`,
    `選填項目`,
    `選択項目`,
    `Không bắt buộc`,
  ],
  btn_rerecord: [
    `다시 촬영`,
    `Record again`,
    's_edit_profile.btn_rerecord',
    `重新拍摄`,
    `重新拍攝`,
    `再撮影`,
    `Quay lại`,
  ],
  info: [`안내`, `Info`, 's_edit_profile.info', `说明`, `說明`, `ご案内`, `Thông tin`],
  rerecord: [
    `다시 촬영하시면 기존에 촬영하신 동영상은 삭제됩니다. 다시 촬영하시겠습니까?`,
    `If you record again, previously recorded video will be deleted. Would you like to record again?`,
    's_edit_profile.rerecord',
    `重新拍摄时, 之前拍摄的视频将会删除。您确定要重新拍摄吗?`,
    `重新拍攝時，將刪除原本拍攝的影片。確定要重新拍攝嗎？`,
    `再撮影すると、すでに撮影した動画は削除されます。再撮影しますか？`,
    `Nếu bạn quay lại, video trước đó của bạn sẽ bị xoá. Bạn có muốn quay lại không?`,
  ],
  start_record: [
    `* 버튼을 누르는 순간 촬영이 시작됩니다.`,
    `Recording will start immediately after you push the button.`,
    's_edit_profile.start_record',
    `*点击按钮, 即可开始拍摄。`,
    `* 點擊按鈕便開始拍攝。`,
    `* ボタンを押した瞬間から撮影が始まります。`,
    `Quay hình sẽ bắt đầu ngay lập tức sau khi bạn nhấn nút.`,
  ],
  please_push_your_btn: [
    <>
      <Span primary>녹화 시작하기 </Span>를 눌러
      <br />
      촬영을 시작해주세요
    </>,
    <>
      When you're ready,
      <br />
      Please click Start Recording button below.
    </>,
    's_edit_profile.please_push_your_btn',
    <>
      <Span primary>点击拍摄</Span>,
      <br />
      即刻开始录制吧
    </>,
    <>
      <Span primary>點擊開始錄製</Span>，
      <br />
      開始拍攝吧！
    </>,
    <>
      <Span primary>録画開始 </Span>を押して
      <br />
      撮影を始めてください。
    </>,
    <>Nhấn "Bắt đầu quay” để quay video giới thiệu</>,
  ],
  study_goal: [
    `영어 공부 목표`,
    `Learning objectives`,
    's_edit_profile.study_goal',
    `英语学习目标`,
    `「英語學習目標」`,
    `英語学習の目標`,
    `Mục tiêu học tập`,
  ],
  more_than_50: [
    `(50 단어 이상 입력)`,
    `(enter min. 50 words)`,
    's_edit_profile.more_than_50',
    `(输入50单词以上)`,
    `（輸入50個單字以上）`,
    `(50単語以上入力)`,
    `(nhập tối thiểu 50 từ)`,
  ],
  name: [`이름`, `Name`, 's_edit_profile.name', `姓名`, `姓名`, `氏名`, `Tên`],
  word_count: [
    `입력된 단어 수`,
    `Word count:`,
    's_edit_profile.word_count',
    `输入的单词数量`,
    `輸入的單字數`,
    `入力した単語数`,
    `Tổng số từ:`,
  ],
  submitted: [
    `제출되었습니다.`,
    `Submitted`,
    's_edit_profile.submitted',
    `已提交。`,
    `提交成功。`,
    `送信されました。`,
    `Đã gửi`,
  ],
  check_point: [
    `포인트 확인`,
    `View Points`,
    's_edit_profile.check_point',
    `确认积分`,
    `確認點數`,
    `ポイント確認`,
    `Xem điểm`,
  ],
  close: [`닫기`, `Close`, 's_edit_profile.close', `关闭`, `關閉`, `閉じる`, `Đóng`],
  complete: [`작성 완료`, `Complete`, 's_edit_profile.complete', `填写完成`, `填寫完成`, `作成完了`, `Hoàn thành`],
  upload_picture: [
    `사진 업로드`,
    `Upload picture`,
    's_edit_profile.upload_picture',
    `上传照片`,
    `上傳照片`,
    `写真アップロード`,
    `Tải hình ảnh lên`,
  ],
  video_popup_title: [
    `프로필 영상 촬영`,
    `Self-introduction Video`,
    's_edit_profile.video_popup_title',
    `拍摄简介短片`,
    `拍攝簡介影片`,
    `プロフィール動画撮影`,
    `Video tự giới thiệu bản thân`,
  ],
  browser_media_permission: [
    <>
      카메라와 마이크 접근이 혀용되지 않아 동영상 촬영이 안됩니다.
      <br />
      브라우저 설정에서 카메라와 마이크 권한을 허용해주세요.
    </>,
    <>
      Access to camera and microphone was blocked so you can't record.
      <br />
      Please update your browser settings to allow access.
    </>,
    's_edit_profile.browser_media_permission',
    <>
      摄像头及麦克风未被允许连接, 因此不能进行视频的拍摄。
      <br />
      请在浏览器设置中允许使用摄像头及麦克风。
    </>,
    <>
      由於未允許相機與麥克風的存取權限，無法錄製影片。
      <br />
      請於瀏覽器設定允許相機與麥克風的權限。
    </>,
    <>
      カメラとマイクへのアクセスが許可されていないため、動画撮影ができません。
      <br />
      ブラウザ設定でカメラとマイクの権限を許可してください。
    </>,
    <>
      Truy cập vào camera và mic đã bị khoá nên bạn không thể thu âm.
      <br />
      Vui lòng cập nhật cài đặt trình duyệt để cấp quyền truy cập.
    </>,
  ],
  preview: [
    `프로필 미리보기`,
    `Preview`,
    's_edit_profile.preview',
    `预览简介`,
    `預覽簡介`,
    `プロフィールプレビュー`,
    `Xem trước`,
  ],
  go: [`바로가기`, `Go`, 's_edit_profile.go', `立即前往`, `立即前往`, `移動`, `Go`],
  start_recording: [
    `녹화 시작하기`,
    `Start Recording`,
    's_edit_profile.start_recording',
    `开始录製`,
    `開始錄製`,
    `録画開始`,
    `Bắt đầu quay`,
  ],
  submit: [`제출하기`, `Submit`, 's_edit_profile.submit', `提交`, `儲存`, `送信する`, `Gửi`],
  congratulations: [
    <>
      <Span textPrimary>축하합니다!</Span>
      <br />
      <Span textPrimary>1만 포인트</Span>가 지급되었습니다.
    </>,
    <>
      <Span primary>Congrats!</Span>
      <br />
      You got <Span primary>$8.62P.</Span>
    </>,
    's_edit_profile.congratulations',
    <>
      <Span primary>祝贺您!</Span>
      <br />
      <Span primary>$8.62</Span>已发送。
    </>,
    <>
      <Span primary>恭喜您！</Span>
      <br />
      已發送<Span primary>$8.62</Span>。
    </>,
    <>
      <Span primary>おめでとうございます！</Span>
      <br />
      <Span primary>$8.62ポイント</Span>が付与されました。
    </>,
    <>
      <Span primary>Chúc mừng bạn!</Span>
      <br />
      Bạn nhận được <Span primary>$8.62 điểm.</Span>
    </>,
  ],
  about_point: [
    <>
      발급된 포인트는 마이 페이지 - 포인트에서 확인 가능합니다.
      <br />* 최초 3회 수업권 구매시 사용 불가합니다.
    </>,
    <>
      Check your points under My Page - Points
      <br />
      *Can't apply points to first 3 lessons.
    </>,
    's_edit_profile.about_point',
    <>
      发送的积分, 可在我的主页 - 积分中进行确认。
      <br />
      *前3次课程券购买时无法使用。
    </>,
    <>
      可於我的頁面 - 點數確認發送的點數。
      <br />* 無法用於購買首3次課程券。
    </>,
    <>
      発行されたポイントはマイページ - ポイントから確認可能です。
      <br />* 初回3回レッスンチケット購入時は使用することができません。
    </>,
    <>
      Kiểm tra điểm của bạn dưới phần Trang của tôi - Điểm
      <br />
      *Không áp dụng điểm cho 3 buổi học đầu tiên.
    </>,
  ],
  use_towards_your_next_purchase: [
    `수업권 구매 시 사용가능합니다.`,
    `Use it towards your next purchase!`,
    's_edit_profile.use_towards_your_next_purchase',
    `可于课程券购买时使用。`,
    `可用於購買課程券。`,
    `レッスンチケット購入時に使用可能です。`,
    `Sử dụng trong lần thanh toán tiếp theo!`,
  ],
  stop_recording: [
    `녹화 종료하기`,
    `Stop Recording`,
    's_edit_profile.stop_recording',
    `结束录像`,
    `結束錄像`,
    `録画終了`,
    `Dừng ghi âm`,
  ],
  record_again: [
    `다시 촬영하기`,
    `Record Again`,
    's_edit_profile.record_again',
    `重新拍摄`,
    `重新拍攝`,
    `再撮影`,
    `Record Again`,
  ],
  delete_video: [`동영상 삭제`, `Delete video`, 's_edit_profile.delete_video', `删除视频`, `刪除影片`, ``, ``],
  click_button_to_save: {
    default: [
      `변경 사항이 있습니다. 저장하기 버튼을 눌러 저장하세요.`,
      ``,
      's_edit_profile.click_button_to_save.default',
      `存在变更事项, 请点击按钮进行保存。`,
      `您有變更事項，請點擊儲存按鈕儲存。`,
      `変更事項があります。保存するボタンを押して保存してください。`,
      ``,
    ],
    with_welcome_coupon: [
      `언어 변환 시, 제공된 웰컴쿠폰 사용이 제한됩니다.`,
      `The Welcome Coupon will be unavailable if you change your language setting.`,
      's_edit_profile.click_button_to_save.with_welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  goal: [
    `영어 공부 목적 (최대 1개)`,
    `English Learning Goals (Select up to 1)`,
    's_edit_profile.goal',
    `英语学习目标（最多选择1项）`,
    `英文學習目標（最多選擇1項）`,
    `英語学習の目的(複数選択可、最大1つ)`,
    `Mục đích học tiếng anh (Có thể chọn nhiều, tối đa 1)`,
  ],
  improvement: [
    `집중 개선 영역 (복수선택 가능, 최대 3개)`,
    `Focus areas (Select up to 3)`,
    's_edit_profile.improvement',
    `重点领域（最多选择3项）`,
    `重點領域（最多選擇3項）`,
    `集中改善ポイント(複数選択可、最大3つ)`,
    `Kỹ năng cần cải thiện (Có thể chọn nhiều, tối đa 3)`,
  ],
  concern: [
    `관심주제 (복수선택 가능, 최대 3개)`,
    `Topics of interest (Select up to 3)`,
    's_edit_profile.concern',
    `兴趣话题（最多选择3项）`,
    `興趣話題（最多選擇3項）`,
    `興味のあるテーマ(複数選択可、最大3つ)`,
    `Chủ đề quan tâm (Chọn tối đa 3)`,
  ],
  required_info: [
    `필수 항목을 모두 입력해야 포인트 지급이 완료됩니다.`,
    `You must fill in all essential information to receive points.`,
    's_edit_profile.required_info',
    `请填写所有必要信息以获取积分`,
    `請填寫所有必要信息以獲得點數`,
    `必須項目を全て入力するとポイントが支給されます。`,
    `Vui lòng điền tất cả thông tin bắt buộc để được điểm`,
  ],
  goal_caterogy1: [
    `비즈니스`,
    `Work-related`,
    's_edit_profile.goal_caterogy1',
    `与工作相关`,
    `與工作相關`,
    `ビジネス`,
    `Kinh doanh`,
  ],
  goal_1: [
    `영어 발표/미팅 능력 향상`,
    `Better presentations/meetings in English`,
    's_edit_profile.goal_1',
    `提高用英语进行演讲/会议的能力`,
    `增加用英文進行演講/會議的能力`,
    `英語でのプレゼン/ミーティング力向上`,
    `Nâng cao kỹ năng thuyết trình/hội họp bằng tiếng Anh`,
  ],
  goal_2: [
    `해외 컨퍼런스 및 출장 대비`,
    `Prepare for overseas business trips`,
    's_edit_profile.goal_2',
    `为海外商务旅行做准备`,
    `為海外商務旅行做準備`,
    `海外カンファレンス及び出張準備`,
    `Chuẩn bị cho các hội nghị và công tác nước ngoài`,
  ],
  goal_3: [
    `회사 동료/상사와 스몰토크 스킬 키우기`,
    `Better daily communication at work`,
    's_edit_profile.goal_3',
    `提高工作中的日常沟通能力`,
    `提升日常工作時的溝通能力`,
    `同僚/上司とのスモールトーク力向上`,
    `Phát triển kỹ năng giao tiếp tại nơi làm việc`,
  ],
  goal_4: [
    `업무 상 영어 작문 능력 향상`,
    `Better English writing skills for work`,
    's_edit_profile.goal_4',
    `提升工作中的英语写作能力`,
    `提升工作中的英文寫作能力`,
    `業務における英作文力の向上`,
    `Cải thiện kỹ năng viết tiếng Anh trong công việc`,
  ],
  goal_caterogy2: [
    `취업/이직/유학`,
    `Employment/study abroad`,
    's_edit_profile.goal_caterogy2',
    `就业/留学海外`,
    `就業/海外留學`,
    `就職/転職/留学`,
    `Việc làm/ du học`,
  ],
  goal_5: [
    `해외로 이직 준비`,
    `Get employed overseas`,
    's_edit_profile.goal_5',
    `在海外就业`,
    `在海外就業`,
    `海外転職の準備`,
    `Làm việc ở nước ngoài`,
  ],
  goal_6: [
    `외국계 회사로 이직 준비`,
    `Get employed at a foreign company`,
    's_edit_profile.goal_6',
    `在外企就业`,
    `在外國公司就業`,
    `外資系企業への転職準備`,
    `Xin việc tại công ty nước ngoài`,
  ],
  goal_7: [
    `해외로 유학 준비`,
    `Prepare for studying abroad`,
    's_edit_profile.goal_7',
    `为留学做准备`,
    `為留學做準備`,
    `海外留学の準備`,
    `Chuẩn bị cho việc du học`,
  ],
  goal_8: [
    `업무 상 영어 작문 능력 향상`,
    `Better English writing skills for work`,
    's_edit_profile.goal_8',
    `提升工作中的英语写作能力`,
    `提升工作中的英文寫作能力`,
    `業務における英作文力の向上`,
    `Cải thiện kỹ năng viết tiếng Anh trong công việc`,
  ],
  goal_9: [
    `영어 에세이/이력서 작성`,
    `Write English essays/resumes`,
    's_edit_profile.goal_9',
    `撰写英文论文/履历`,
    `撰寫英文論文/履歷`,
    `英文エッセイ/履歴書作成`,
    `Viết luận/sơ yếu lý lịch bằng tiếng Anh`,
  ],
  goal_10: [
    `영어 인터뷰 준비`,
    `Prepare for English interviews`,
    's_edit_profile.goal_10',
    `为英语面试做准备`,
    `為英文面試做準備`,
    `英語インタビューの準備`,
    `Chuẩn bị cho phỏng vấn bằng tiếng Anh`,
  ],
  goal_11: [
    `어학 시험 준비`,
    `Prepare for language proficiency tests`,
    's_edit_profile.goal_11',
    `为语言能力考试做准备`,
    `為語言能力考試做準備`,
    `語学試験の準備`,
    `Chuẩn bị cho các bài kiểm tra trình độ ngôn ngữ`,
  ],
  goal_caterogy3: [
    `자기계발`,
    `Personal development`,
    's_edit_profile.goal_caterogy3',
    `个人发展`,
    `個人發展`,
    `自己啓発`,
    `Phát triển cá nhân`,
  ],
  goal_12: [
    `원어민과 스몰토크 스킬 키우기`,
    `Enjoy small talk with foreigners`,
    's_edit_profile.goal_12',
    `与外国人轻松交谈`,
    `與外國人輕鬆交談`,
    `ネイティブとのスモールトーク力向上`,
    `Phát triển kỹ năng giao tiếp với người nước ngoài`,
  ],
  goal_13: [
    `영어로 대화/토론하는 것을 즐김`,
    `Hold discussions in English`,
    's_edit_profile.goal_13',
    `用英语进行讨论`,
    `用英文進行討論`,
    `英語での会話/討論が好き`,
    `Giao tiếp, thảo luận bằng tiếng Anh tự nhiên`,
  ],
  goal_14: [
    `현재 영어 실력 유지`,
    `Maintain English proficiency`,
    's_edit_profile.goal_14',
    `保持英语能力`,
    `保持英語能力`,
    `現在の英語力の維持`,
    `Duy trì trình độ tiếng Anh`,
  ],
  goal_15: [
    `영어권 국가의 문화 이해`,
    `Understand English-based culture`,
    's_edit_profile.goal_15',
    `了解英语文化圈的文化`,
    `了解英語文化圈的文化`,
    `英語圏の国の文化の理解`,
    `Tìm hiều văn hóa nước ngoài`,
  ],
  goal_16: [
    `여행/휴가 시 더 유창한 영어 구사`,
    `English for travelling overseas`,
    's_edit_profile.goal_16',
    `旅行英语`,
    `旅行英語`,
    `旅行/休暇での流暢な英語力`,
    `Tiếng Anh du lịch nước ngoài`,
  ],
  goal_17: [`기타(주관식)`, `Other`, 's_edit_profile.goal_17', `其他`, `其他`, `その他(記述式)`, `Khác`],
  goal_17_input: [
    `영어 공부 목적을 입력해 주세요.`,
    `Please tell us why you wish to learn English.`,
    's_edit_profile.goal_17_input',
    `请告诉我们您为什麽想学习英语`,
    `請告訴我們您為什麼想學習英文`,
    `英語学習の目的を入力してください。`,
    `Vui lòng nhập mục đích học tiếng Anh`,
  ],
  improvement_1: [
    `기초 어휘부터 배우고 싶어요.`,
    `Basic vocabulary`,
    's_edit_profile.improvement_1',
    `基础词彙`,
    `基礎詞彙`,
    `基礎的な語彙から学びたい`,
    `Từ vựng căn bản`,
  ],
  improvement_2: [
    `새로운 고급 표현을 배우고 싶어요.`,
    `New advanced expressions`,
    's_edit_profile.improvement_2',
    `新的高级表达方式`,
    `新的高級表達方式`,
    `新しい上級表現を学びたい`,
    `Biểu hiện nâng cao mới`,
  ],
  improvement_3: [
    `원어민이 자주 쓰는 자연스러운 표현을 배우고 싶어요.`,
    <>
      Common expressions that
      <br />
      native English speakers use
    </>,
    's_edit_profile.improvement_3',
    `英语母语者常用的表达方式`,
    `英文母語者常用的表達方式`,
    `ネイティブが使う自然な表現を学びたい`,
    `Giao tiếp tự nhiên như người bản xứ`,
  ],
  improvement_4: [
    `영어로 간결하고 정확하게 표현하고 싶어요.`,
    `Speaking concisely and clearly in English`,
    's_edit_profile.improvement_4',
    `用正确且清晰的英语表达`,
    `用正確且清晰的英文表達`,
    `英語で簡潔で正確に表現したい`,
    `Nói tiếng Anh ngắn gọn và rõ ràng`,
  ],
  improvement_5: [
    `원어민 같은 발음/액센트로 말하고 싶어요.`,
    `Pronunciation/accent`,
    's_edit_profile.improvement_5',
    `发音/口音`,
    `發音/口音`,
    `発音/アクセントの上達`,
    `Phát âm/ ngữ điệu`,
  ],
  improvement_6: [
    `막힘없이 영어로 대화하고 싶어요.`,
    `Conversing fluidly in English`,
    's_edit_profile.improvement_6',
    `流畅地用英文对话`,
    `流暢地用英文對話`,
    `英語でスラスラ話したい`,
    `Giao tiếp tiếng Anh trôi chảy`,
  ],
  improvement_7: [
    `영어로 논리적으로 대화하고 싶어요.`,
    `Logical English speaking`,
    's_edit_profile.improvement_7',
    `用精准且有逻辑的英文交谈`,
    `用精準且有邏輯的英文交談`,
    `英語で論理的に話したい`,
    `Nói tiếng Anh logic`,
  ],
  improvement_8: [
    `영어로 논리적으로 글을 작성하고 싶어요.`,
    `Logical English writing`,
    's_edit_profile.improvement_8',
    `用精准且有逻辑的英文书写`,
    `用精準且有邏輯的英文書寫`,
    `英語で論理的に作文したい`,
    `Viết tiếng Anh logic`,
  ],
  concern_1: [
    `Business Case`,
    `Business Case`,
    's_edit_profile.concern_1',
    `商业案例`,
    `商業案例`,
    `Business Case`,
    `Business Case`,
  ],
  concern_2: [`Economy`, `Economy`, 's_edit_profile.concern_2', `经济`, `經濟`, `Economy`, `Economy`],
  concern_3: [
    `Society/Politics`,
    `Society/Politics`,
    's_edit_profile.concern_3',
    `社会/政治`,
    `社會/政治`,
    `Society/Politics`,
    `Society/Politics`,
  ],
  concern_4: [`Tech`, `Tech`, 's_edit_profile.concern_4', `科技`, `科技`, `Tech`, `Tech`],
  concern_5: [
    `Science/Environment`,
    `Science/Environment`,
    's_edit_profile.concern_5',
    `科学/环境`,
    `科學/環境`,
    `Science/Environment`,
    `Science/Environment`,
  ],
  concern_6: [
    `Office Life`,
    `Office Life`,
    's_edit_profile.concern_6',
    `办公室生活`,
    `辦公室生活`,
    `Office Life`,
    `Office Life`,
  ],
  concern_7: [
    `Culture/Entertainment`,
    `Culture/Entertainment`,
    's_edit_profile.concern_7',
    `文化/娱乐`,
    `文化/娛樂`,
    `Culture/Entertainment`,
    `Culture/Entertainment`,
  ],
  concern_8: [`Sports`, `Sports`, 's_edit_profile.concern_8', `体育`, `體育`, `Sports`, `Sports`],
  optional_info: [
    `입력한 정보는 튜터가 맞춤 수업을 준비하는데 도움이 됩니다.`,
    `The info you provide will help the tutor prepare custom lessons.`,
    's_edit_profile.optional_info',
    `您提供的信息将帮助导师为您准备定制课程。`,
    `您提供的信息將幫助導師為您準備定制課程。`,
    `入力された情報はチューターがレッスン準備の際に活用します。`,
    `Thông tin bạn cung cấp sẽ giúp gia sư điều chỉnh bài học phù hợp`,
  ],
  occupation_company_1: [
    `마케팅`,
    `Marketing`,
    's_edit_profile.occupation_company_1',
    `市场营销`,
    `市場營銷`,
    `マーケティング`,
    `Marketing`,
  ],
  occupation_company_2: [
    `사업 전략`,
    `Business strategy`,
    's_edit_profile.occupation_company_2',
    `商业策略`,
    `商業策略`,
    `ビジネス戦略`,
    `Chiến lược kinh doanh`,
  ],
  occupation_company_3: [`영업`, `Sales`, 's_edit_profile.occupation_company_3', `销售`, `銷售`, `営業`, `Sales`],
  occupation_company_4: [
    `개발`,
    `Developer`,
    's_edit_profile.occupation_company_4',
    `开发人员`,
    `開發人員`,
    `開発`,
    `Programming`,
  ],
  occupation_company_5: [
    `PM/UX/서비스기획`,
    `PM/UX/UI`,
    's_edit_profile.occupation_company_5',
    `产品经理/UX/UI设计师`,
    `產品經理/UX/UI設計師`,
    `PM/UX/サービス企画`,
    `PM/UX/UI`,
  ],
  occupation_company_6: [
    `연구원/교수`,
    `Researcher/Professor`,
    's_edit_profile.occupation_company_6',
    `研究员/教授`,
    `研究員/教授`,
    `研究員/教授`,
    `Nhà nghiên cứu / giáo sư`,
  ],
  occupation_company_7: [
    `의료인`,
    `Medical professional`,
    's_edit_profile.occupation_company_7',
    `医疗专业人员`,
    `醫療專業人員`,
    `医療従事者`,
    `Nhân viên y tế`,
  ],
  occupation_company_8: [
    `법조인`,
    `Legal professional`,
    's_edit_profile.occupation_company_8',
    `法律专业人员`,
    `法律專業人員`,
    `法律関係`,
    `Luật sư`,
  ],
  occupation_company_9: [
    `예술/디자인`,
    `Artist/designer`,
    's_edit_profile.occupation_company_9',
    `艺术家/设计师`,
    `藝術家/設計師`,
    `芸術/デザイン`,
    `Thiết kế mỹ thuật`,
  ],
  occupation_company_10: [
    `재무/회계/인사`,
    `Finance/Accounting/HR`,
    's_edit_profile.occupation_company_10',
    `财务/会计/人力资源`,
    `財務/會計/人力資源`,
    `財務/会計/人事`,
    `Tài chính/Kế toán/Nhân sự`,
  ],
  occupation_company_11: [
    `대학(원)생`,
    `University/graduate student`,
    's_edit_profile.occupation_company_11',
    `大学/研究生`,
    `大學/研究生`,
    `大学(院)生`,
    `Sinh viên sau đại học`,
  ],
  occupation_company_12: [
    `학생 (고등/중등/초등)`,
    `Primary/secondary school student`,
    's_edit_profile.occupation_company_12',
    `小学/中学生`,
    `小學/中學生`,
    `学生(高校/中学/小学生)`,
    `Học sinh (THPT/THCS/Tiểu học)`,
  ],
  occupation_company_13: [`기타`, `Other`, 's_edit_profile.occupation_company_13', `其他`, `其他`, `その他`, `Khác`],
}
export const s_zoom = {
  title: [
    `화상 프로그램 (Zoom) 설치`,
    `How to Install Zoom`,
    's_zoom.title',
    `安装视频程序(Zoom)`,
    `安裝視訊軟體（Zoom）`,
    `ビデオ会議アプリ (Zoom) インストール`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  subtitle: [
    `링글 수업을 이용하기 위해서는, 줌(Zoom)설치가 필요합니다`,
    `You need to install Zoom in order to use Ringle`,
    's_zoom.subtitle',
    `Ringle课程需安装(Zoom)程序`,
    `為進行Ringle課程，需安裝Zoom。`,
    `Ringleのレッスンを行うためには、Zoomのインストールが必要です。`,
    `Bạn cần cài đặt Zoom để sử dụng Ringle`,
  ],
  step3: [
    <>
      3. 다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.
      <br />
      수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.
      <br />
      <br />
      *수업 시작 전 별도 줌 링크가 제공되지 않습니다.
    </>,
    <>
      3.After downloading, you do not need to make a new Zoom account. You just have to have the program installed on
      your computer.
    </>,
    's_zoom.step3',
    <>
      3. 完成下载后, 无需进行Zoom会员注册/登录/参与会议。
      <br />
      连接时, 可按照Ringle主页 - 预约的课程 - 点击课程入场按钮的顺序开始课程。
      <br />
      <br />
      *课程开始前, 不另行提供Zoom链接。
    </>,
    <>
      3. 下載完畢後，不需註冊Zoom會員／登入／加入會議。
      <br />
      進行課程連線時，可透過Ringle網頁 - 預定的課程 - 進入課程按鈕進場。
      <br />
      <br />
      *課程開始前不另外提供Zoom連結。
    </>,
    <>
      3. ダウンロード完了後、Zoom会員登録/ ログイン/ ミーティングに参加する必要はありません。
      <br />
      レッスンの際は、Ringleホームページ - レッスン予定 - レッスン入室ボタンから入室可能です。
      <br />
      <br />
      *レッスン開始前に別途Zoomのリンクは提供されません。
    </>,
    <>3.Sau khi tải xuống, bạn không cần có tài khoản Zoom. Bạn chỉ cần cài chương trình vào máy tính.</>,
  ],
  step4: [
    `4. Zoom Test 하기 (약 2분 소요)`,
    `4. Test Zoom (takes about 2 minutes)`,
    's_zoom.step4',
    `4. 进行Zoom Test(大约需要2分钟)`,
    `4. Test Zoom（約2分鐘）`,
    `4. Zoom Testする (約2分所要)`,
    `4. Kiểm tra Zoom (mất khoảng 2 phút)`,
  ],
  zoom_test_btn: [
    `Zoom Test 하기`,
    `Test Zoom`,
    's_zoom.zoom_test_btn',
    `进行Zoom Test`,
    `Test Zoom`,
    `Zoom Testする`,
    `Kiểm tra Zoom`,
  ],
  steps_are_following: [
    `단계는 아래와 같습니다.`,
    `For testing, follow these steps:`,
    's_zoom.steps_are_following',
    `步骤如下。`,
    `步驟如下。`,
    `手順は次のとおりです。`,
    `Để kiểm tra, làm theo các bước sau:`,
  ],
  step4_1_click_join_button: [
    `1. Zoom Test 입장 후, 참가 버튼을 클릭합니다.`,
    <>1) After entering the Zoom Test, click the "Join".</>,
    's_zoom.step4_1_click_join_button',
    `1. Zoom Test入场后, 点击参与按键。`,
    `1. 進入Zoom Test後，點擊加入按鈕。`,
    `1. Zoom Test 入室後、参加ボタンをクリックします。`,
    <>1) Sau khi mở Zoom Test, bấm "Join".</>,
  ],
  step4_2: [
    `2. Zoom. us 열기를 클릭합니다.`,
    <>2) Click "Open Zoom.us".</>,
    's_zoom.step4_2',
    `2. 点击打开Zoom. us。`,
    `2. 點擊開啟Zoom. us。`,
    `2. Zoom. us を開くをクリックします。`,
    <>2) Chọn "Open Zoom.us".</>,
  ],
  setp4_4_node: [
    <>만약, 줌 이용이 어려우시다면 언제든지 하단 1:1 문의/카카오톡을 통해 링글팀에게 알려주세요.</>,
    `If you have any problem using Zoom, please contact us. Thank you.`,
    's_zoom.setp4_4_node',
    <>如在Zoom的使用过程中出现问题, 可随时点击下方在线咨询/KakaoTalk 按钮, 联系Ringle团队。</>,
    <>使用Zoom時如有疑問，可隨時透過下方聊天／KakaoTalk告訴Ringle團隊。</>,
    <>もしZoomの利用が難しい場合、いつでも下のチャネルトーク/KakaoTalkから Ringleチームにご連絡ください。</>,
    `Nếu bạn gặp bất kỳ vấn đề về sử dụng Zoom, vui lòng liên hệ với chúng tôi. Cảm ơn.`,
  ],
  go_to_lesson_page_btn: [
    `예습 & 수업 페이지 가기`,
    `Go to Lesson`,
    's_zoom.go_to_lesson_page_btn',
    `前往预习 & 课程页面`,
    `前往預習 & 課程頁面`,
    `予習 & レッスンページに移動`,
    `Vào học`,
  ],
  new_guide_step_3: [
    <>
      3. 오디오 연결 방식을 선택 후<br />
      카메라, 스피커 및 마이크 테스트를 진행합니다.
    </>,
    <>
      3. Select audio option and <br />
      test camera, speaker and mic.
    </>,
    's_zoom.new_guide_step_3',
    <>
      3. 选择音频连接方式后, <br />
      对摄像头, 扬声器及麦克风进行测试。
    </>,
    <>
      3. 選擇音訊連接方式後，
      <br />
      進行相機、喇叭、麥克風測試。
    </>,
    <>
      3. オーディオ接続方式を選択後
      <br />
      カメラ、スピーカーおよびマイクテストを行います。
    </>,
    <>
      3. Chọn phần âm thanh và <br />
      kiểm tra camera, loa và mic.
    </>,
  ],
  new_guide_step_4: [
    <>
      4. 만약 카메라가 보이지 않으면,
      <br /> 하단 버튼을 클릭 후 카메라 설정을 변경해보세요.
    </>,
    <>
      4. If you're having video issues,
      <br /> please check your camera settings.
    </>,
    's_zoom.new_guide_step_4',
    <>
      4. 如果摄像头无法工作时, <br /> 请点击下端按钮对摄像头设定进行变更。
    </>,
    <>
      4. 如果看不到相機畫面，
      <br />
      請點擊下方按鈕變更相機設定。
    </>,
    <>
      4. もしカメラが映らなければ、
      <br /> 下のボタンをクリックしてカメラ設定を変更してみてください。
    </>,
    <>
      4. Nếu bạn có vấn đề về video,
      <br /> vui lòng kiểm tra cài đặt camera.
    </>,
  ],
  new_guide_step_5: [
    <>
      5. 만약 벨소리가 들리지 않으면,
      <br /> 하단 버튼을 클릭 후 스피커 설정을 변경해보세요.
    </>,
    <>
      5. If you don't hear the ringtone,
      <br /> please check your speaker settings.
    </>,
    's_zoom.new_guide_step_5',
    <>
      5. 如果听不到声音, <br /> 请点击下端按钮对扬声器设定进行变更。
    </>,
    <>
      5. 如果聽不到鈴聲，
      <br />
      請點擊下方按鈕變更喇叭設定。
    </>,
    <>
      5. もしベルの音が聞こえなければ、
      <br /> 下のボタンをクリックしてスピーカー設定を変更してみてください。
    </>,
    <>
      5. Nếu bạn không nghe được âm thanh,
      <br /> vui lòng chọn cài đặt loa.
    </>,
  ],
  new_guide_step_6: [
    <>
      6. 만약 마이크가 작동하지 않으면,
      <br /> 하단 버튼을 클릭 후 마이크 설정을 변경해보세요.
    </>,
    <>
      6. If your mic doesn't work,
      <br /> please check your input.
    </>,
    's_zoom.new_guide_step_6',
    <>
      6. 如果麦克风无法工作时, <br /> 请点击下端按钮对麦克风设定进行变更。
    </>,
    <>
      6. 如果麥克風未啟動，
      <br />
      請點擊下方按鈕變更麥克風設定。
    </>,
    <>
      6. もしマイクが作動しなければ、
      <br /> 下のボタンをクリックしてマイク設定を変更してみてください。
    </>,
    <>
      6. Nếu mic của bạn không hoạt động,
      <br /> vui lòng kiểm tra thiết bị thu.
    </>,
  ],
  new_guide_step_7: [
    <>
      7. 다음과 동일한 화면이 나오면,
      <br /> 카메라,스피커와 마이크가 정상적으로 작동중입니다.
    </>,
    <>
      7. If you see below screen,
      <br /> you're all good to go.
    </>,
    's_zoom.new_guide_step_7',
    <>
      7. 如果出现以下画面, <br /> 说明摄像头, 扬声器及麦克风均正常运转。
    </>,
    <>
      7. 如果出現以下畫面，
      <br />
      表示相機、喇叭、麥克風正常啟動中。
    </>,
    <>
      7. 次と同じ画面が出たら、
      <br /> カメラ、スピーカー、マイクが正常に作動中です。
    </>,
    <>
      7. Nếu bạn thấy màn hình phía dưới, <br /> mọi thứ đều sẵn sàng.
    </>,
  ],
  new_guide_step_8: [
    <>
      8. 수업 입장 시, "오디오 연결” 버튼을
      <br /> 클릭하여 오디오가 잘 연결되었는지 확인해보세요.
    </>,
    <>
      8. When you enter your lesson, <br />
      please "Join With Computer Audio".
    </>,
    's_zoom.new_guide_step_8',
    <>
      8. 课程入场时, 点击"音频连接” 按钮
      <br />
      确认音频连接是否正常。
    </>,
    <>
      8. 進入課程時，請點擊「加入音訊」按鈕，
      <br />
      確認音訊是否連接成功。
    </>,
    <>
      8. レッスン入室時、「オーディオに接続」ボタンを
      <br /> クリックしてオーディオが正常に接続されているか確認してください。
    </>,
    <>
      8. Khi vào học, <br />
      chọn "Join With Computer Audio".
    </>,
  ],
  new_guide_step_9: [
    `9. 수업 전 스피커와 비디오가 켜져 있는지 확인해보세요.`,
    `9. Please double check that your audio & video is on.`,
    's_zoom.new_guide_step_9',
    `9. 课程前请确认扬声器及视频装置是否开启。`,
    `9. 課程開始前請先確認喇叭與影像是否開啟。`,
    `9. レッスン前にスピーカーとビデオがオンになっているか確認してください。`,
    `9. Hãy kiểm tra kỹ âm thanh và video đã bật.`,
  ],
  step_1_zoom_download: [
    `Step 1. Zoom 다운로드`,
    `Step 1. Download Zoom`,
    's_zoom.step_1_zoom_download',
    `Step 1. 下载Zoom`,
    `Step 1. 下載Zoom`,
    `Step 1. Zoomをダウンロード`,
    `Bước 1. Tải ứng dụng Zoom`,
  ],
  plz_download_zoom: [
    `링글의 수업은 Zoom 으로 진행됩니다. 아래의 Zoom 파일을 다운로드 해주세요.`,
    `All Ringle lessons run on zoom.`,
    's_zoom.plz_download_zoom',
    `Ringle课程将使用Zoom程序进行, 请下载Zoom安装文件。`,
    `Ringle的課程透過Zoom進行，請下載下方的Zoom檔案。`,
    `RingleのレッスンはZoomで行われます。下のZoomファイルをダウンロードしてください。`,
    `Tất cả buổi học của Ringle diễn ra trên Zoom`,
  ],
  zoom_download: [
    `Zoom 다운로드`,
    `Download Zoom`,
    's_zoom.zoom_download',
    `Zoom下载`,
    `下載Zoom`,
    `Zoomをダウンロード`,
    `Tải ứng dụng Zoom`,
  ],
  step_2_install: [
    `Step 2. 설치`,
    `Step 2. Install Zoom`,
    's_zoom.step_2_install',
    `Step 2. 安装`,
    `Step 2. 安裝`,
    `Step 2. インストール`,
    `Bước 2. Cài đặt Zoom`,
  ],
  run_file_install_in_computer: [
    `다운 받은 파일을 실행 후, 내 컴퓨터에 설치해주세요.`,
    `After download, please install.`,
    's_zoom.run_file_install_in_computer',
    `运行下载的文件, 安装在我的电脑中。`,
    `執行下載的檔案後，安裝至您的電腦。`,
    `ダウンロードしたファイルを開き、自分のコンピューターにインストールしてください。`,
    `Sau khi tải xuống, hãy cài đặt.`,
  ],
  step3_class_access: [
    `Step 3. 수업접속`,
    `Step 3. Enter Lesson`,
    's_zoom.step3_class_access',
    `Step 3. 连接课程`,
    `Step 3. 連接課程`,
    `Step 3. レッスン接続`,
    `Bước 3. Vào học`,
  ],
  after_download_do_not_need_participation: [
    `다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.`,
    `You don't need a Zoom account to enter Ringle lessons.`,
    's_zoom.after_download_do_not_need_participation',
    `下载完成后, 无需进行Zoom会员注册/登录/参与会议。`,
    `下載完畢後，不需註冊Zoom會員／登入／加入會議。`,
    `ダウンロード完了後、Zoom会員登録/ログイン/ミーティングに参加する必要はありません。`,
    `Bạn không cần tài khoản Zoom để vào học Ringle.`,
  ],
  class_can_enter_with_button: [
    `수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.`,
    `Please sign in to Ringle and go to Upcoming Lessons > Enter Lesson to enter your lesson.`,
    's_zoom.class_can_enter_with_button',
    `连接时, 可按照Ringle主页 - 预约的课程 - 点击课程入场按钮的顺序开始课程`,
    `進行課程連線時，可透過Ringle網頁 - 預定的課程 - 進入課程按鈕進場。`,
    `レッスン接続時、Ringleホームページ - レッスン予定 - レッスン入室ボタンで入室可能です。`,
    `Vui lòng đăng nhập vào Ringle và đến Buổi học sắp tới > Nhấn nút Vào học.`,
  ],
  before_start_class_no_zoom_link: [
    `*수업 시작 전 별도 줌 링크가 제공되지 않습니다.`,
    `*We do not provide zoom link prior to lessons.`,
    's_zoom.before_start_class_no_zoom_link',
    `*课程开始前, 不另行提供Zoom链接。`,
    `*課程開始前不另外提供Zoom連結。`,
    `*レッスン開始前に別途Zoomのリンクは提供されません。`,
    `*Chúng tôi không cung cấp link zoom trước buổi học.`,
  ],
  step4_zoom_test: [
    `Step 4. Zoom Test`,
    `Step 4. Test Zoom`,
    's_zoom.step4_zoom_test',
    `Step 4. Zoom Test`,
    `Step 4. Zoom Test`,
    `Step 4. Zoom Test`,
    `Bước 4. Kiểm tra Zoom`,
  ],
  after_install_push_below_link: [
    `설치 후, 아래 링크를 눌러서 테스트를 해주세요. (약 2분 소요)`,
    `Please go to link below to test your audio & video on Zoom (takes 2 min).`,
    's_zoom.after_install_push_below_link',
    `安装后, 请点击以下链接进行测试。(大约需要2分钟)`,
    `安裝後請點擊以下連結測試。（約需2分鐘）`,
    `インストール後、下のリンクを押してテストしてください。(約2分所要)`,
    `Vui lòng vào link dưới đây để kiểm tra âm thanh & video trên Zoom (khoảng 2 phút).`,
  ],
  before_class_check_audio_video: [
    `수업 전에 오디오와 비디오가 올바로 동작하시는지 확인하실 수 있습니다.`,
    ``,
    's_zoom.before_class_check_audio_video',
    `课程开始前可对音频及视频是否处于正常状态进行确认。`,
    `課程開始前可確認音訊與影像是否正常啟動。`,
    `レッスン前にオーディオとビデオが正しく作動するか確認することができます。`,
    ``,
  ],
}
export const s_trial_completed = {
  completed: (name) => [
    <>
      {' '}
      {name}님
      <br />
      <Div spanTag textPrimary>
        체험 수업 신청 완료
      </Div>
      되었습니다.
    </>,
    <>
      {' '}
      Congrats {name}
      <br />
      <Div spanTag textPrimary>
        You booked your trial lesson
      </Div>
    </>,
    's_trial_completed.completed',
    <>
      {' '}
      {name}用户
      <br />
      <Div spanTag textPrimary>
        体验课程的申请
      </Div>
      已经完成。
    </>,
    <>
      {' '}
      {name}的
      <br />
      <Div spanTag textPrimary>
        體驗課程申請完成
      </Div>
      。
    </>,
    <>
      {' '}
      {name}さん
      <br />
      <Div spanTag textPrimary>
        体験レッスンの予約が完了
      </Div>
      しました。
    </>,
    <>
      {' '}
      Chúc mừng {name}
      <br />
      <Div spanTag textPrimary>
        Bạn đã đặt lịch buổi học thử
      </Div>
    </>,
  ],
  check_guide: [
    `자세한 체험 수업 가이드를 확인하고 싶다면?`,
    `How Ringle works`,
    's_trial_completed.check_guide',
    `如果想确认详细的体验课程指南?`,
    `如果想確認詳細的體驗課程指南？`,
    `詳しい体験レッスンガイドを確認したいなら？`,
    `Cách Ringle hoạt động`,
  ],
  time_completed: [`시간`, `Date & Time`, 's_trial_completed.time_completed', `时间`, `時間`, `時間`, `Ngày & Giờ`],
  material_completed: [`교재`, `Materials`, 's_trial_completed.material_completed', `教材`, `教材`, `教材`, `Tài liệu`],
  automaching: [
    `체험 수업 시 튜터는 자동으로 매칭됩니다.`,
    `We'll match you to a tutor`,
    's_trial_completed.automaching',
    `体验课程的教师将自动匹配。`,
    `進行體驗課程時，將自動配對任課教師。`,
    `体験レッスン時、チューターは自動的にマッチングされます。`,
    `Chúng tôi sẽ sắp xếp bạn với gia sư`,
  ],
  checking_three: [
    `링글 체험 수업 전 준비 사항 3가지`,
    `Prep for your lesson in 3 steps`,
    's_trial_completed.checking_three',
    `Ringle体验课程前需准备的三点`,
    `進行Ringle體驗課程前的3大準備事項`,
    `Ringle体験レッスン前に準備すること3つ`,
    `Chuẩn bị cho buổi học với 3 bước`,
  ],
  step_one: [
    `Step1. 교재 읽기 / 질문 선택`,
    `Step1. Read Materials & Select Questions`,
    's_trial_completed.step_one',
    `Step1. 阅读教材 / 选择问题`,
    `Step1. 閱讀教材／選擇問題`,
    `Step1. 教材を読む / 質問選択`,
    `Bước 1. Đọc Tài liệu & Chọn Câu hỏi`,
  ],
  step_two: [
    `Step2. 1:1 맞춤 수업 설정`,
    `Step2. Set Lesson Styles`,
    's_trial_completed.step_two',
    `Step2. 设置1:1定制型课程`,
    `Step2. 設定1:1客製化課程`,
    `Step2. 1:1カスタマイズレッスン設定`,
    `Bước 2. Chọn Hình thức học`,
  ],
  step_three: [
    `Step3. 카메라가 설치된 PC로 수업을 입장해주세요`,
    `Step3. Enter Lesson with Camera On`,
    's_trial_completed.step_three',
    `Step3. 使用设置有摄像头的电脑进入课程`,
    `Step3. 使用附有相機的電腦進入課程`,
    `Step3. カメラがあるPCでレッスンに入室してください。`,
    `Bước 3. Vào học và bật Camera`,
  ],
  step_one_desc: [
    `교재를 미리 읽어보고, 수업에서 다룰 질문을 선택한 후 답변을 준비해보세요.`,
    `Read your materials and choose which questions you'd like your tutor to ask you.`,
    's_trial_completed.step_one_desc',
    `请提前阅读教材, 选择于课堂上想要回答的问题并进行准备。`,
    `請事先閱讀教材，選擇上課時要討論的問題後，準備回答。`,
    `教材を事前に読み、レッスンで扱う質問を選択して回答を準備しましょう。`,
    `Đọc tài liệu và chọn câu hỏi mà bạn muốn gia sư sẽ hỏi mình.`,
  ],
  step_two_desc: [
    `집중적으로 교정 받고 싶은 영역을 선택하여 나만의 수업을 만들어보세요.`,
    `Customize your lesson by selecting your correction areas.`,
    's_trial_completed.step_two_desc',
    `请选择希望集中进行校正的部分, 打造属于自己的完美课程吧。`,
    `選擇希望集中糾正的領域，打造專屬於自己的課程吧！`,
    `集中的に校正してもらいたい分野を選択し、自分だけのレッスンを作りましょう。`,
    `Điều chỉnh buổi học bằng cách chọn phần bạn muốn tập trung sửa lỗi.`,
  ],
  step_one_detail: [
    `교재 듣기 MP3, 핵심표현 등 다양한 자료를 수업 전 확인 가능합니다.`,
    `Also check out key expressions and related videos & news!`,
    's_trial_completed.step_one_detail',
    `教材听力MP3, 核心表达等多种资料,  于课前均可进行确认。`,
    `可於課程開始前聆聽教材MP3，並確認核心用法等各種資料。`,
    `教材のMP3ファイル、キーフレーズなど、さまざまな資料をレッスン前に確認可能です。`,
    `Và kiểm tra cả những mẫu câu chính và video & tin tức liên quan!`,
  ],
  step_two_detail: [
    `체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로만 진행합니다.
정규수업에서는 토론 중심 수업 설정도 가능합니다.`,
    `Your trial lesson will be comprised of 15-minute discussion and 5-minute corrections.`,
    's_trial_completed.step_two_detail',
    `体验课程的形式为完成各问题的对话后, 以纠正教学收尾。
正规课程可将内容设置为以讨论为主。`,
    `進行體驗課程時，在針對各問題結束對話後，以糾正方式進行。
進行正規課程時，可設定以討論為主的課程。`,
    `体験レッスンは、各質問の会話が終わった後に校正を受ける方式で行われます。
レギュラーレッスンではディスカッション中心レッスンに設定可能です。`,
    `Buổi học thử của bạn sẽ bao gồm 15 phút thảo luận và 5 phút sửa lỗi.`,
  ],
  step_three_detail: [
    `교재/실시간 교정화면은 모바일 환경에서 제한적으로 보일 수 있습니다.`,
    `You may not see real-time corrections if you join via mobile.`,
    's_trial_completed.step_three_detail',
    `教材/实时纠正画面, 于使用移动设备时可能会出现限制。`,
    `使用手機觀看教材／即時糾正畫面時可能有所限制。`,
    `教材/リアルタイム校正画面は、モバイル環境で制限的に表示されることがあります。`,
    `Bạn có thể sẽ không thấy sửa lỗi trực tiếp nếu bạn học bằng điện thoại`,
  ],
  before_lesson: [
    `수업 전 알아두세요`,
    `Please note:`,
    's_trial_completed.before_lesson',
    `课程前请务必了解`,
    `課程開始前請注意！`,
    `レッスン前にご確認ください。`,
    `Lưu ý:`,
  ],
  before_one: [
    `PC로 수업 입장을 권장드리며, Zoom 프로그램 설치가 필요합니다.`,
    `Please install Zoom prior to lesson and enter lesson via web browser.`,
    's_trial_completed.before_one',
    `建议使用PC进行课程, 同时必须安装Zoom程序。`,
    `建議使用電腦進入課程，並需安裝Zoom軟體。`,
    `PCでのレッスン入室を推奨しており、Zoomのインストールが必要です。`,
    `Vui lòng cài đặt Zoom trước buổi học và vào lớp bằng trình duyệt web.`,
  ],
  before_two: [
    `수업 후 제공되는 수업 녹음 파일 / 스크립트 / CAF 진단 / 튜터 피드백으로 복습 가능합니다.`,
    `You can review lesson using lesson recording, audio transcript, CAF analysis and tutor feedback.`,
    's_trial_completed.before_two',
    `课程后提供的课程录音 / 脚本 / AI分析 / 教师反馈均可于复习时使用。`,
    `可利用課程結束後提供的課程錄音檔／腳本／AI分析／任課教師意見回饋進行複習。`,
    `レッスン後に提供されるレッスン録音ファイル / スクリプト / AI Analysis / チューターフィードバックで復習可能です。`,
    `Bạn có thể xem lại buổi học thông qua bản ghi âm, bản ghi thoại, phân tích AI và nhận xét của gia sư.`,
  ],
  before_three: [
    `교재/ 체험 수업 시간 변경 및 취소는 수업 시작 24시간 이전까지 가능합니다.`,
    `You may reschedule or change materials up to 24 hours before the lesson.`,
    's_trial_completed.before_three',
    `教材/体验课程的时间变更及取消, 需在课程开始前24小时进行。`,
    `最晚可於課程開始24小時前變更或取消教材／體驗課程時間。`,
    `教材/体験レッスンの時間変更およびキャンセルは、レッスン開始24時間前まで可能です。`,
    `Bạn có thể dời lịch hoặc thay đổi tài liệu tối thiểu 24 giờ trước buổi học..`,
  ],
  before_three_gray: [
    `*수업 시작 24시간 이내 변경 및 취소 희망 시 오른쪽 하단 1:1 문의를 통해 연락 바랍니다.`,
    `*If you need to reschedule or change materials in less than 24 hours before the lesson, please contact us.`,
    's_trial_completed.before_three_gray',
    `*课程开始前24小时内希望进行变更或取消时, 可通过右下方的在线咨询联系我们。`,
    `*於課程開始前24小時以內希望變更或取消時，請透過右下方的聊天聯絡我們。`,
    `*レッスン開始24時間以内の変更およびキャンセルをご希望の際は、右下のチャネルトークからご連絡ください。`,
    `*Nếu bạn phải dời lịch hoặc thay đổi tài liệu trong vòng 24 giờ trước khi buổi học bắt đầu, vui lòng chat với chúng tôi.`,
  ],
  go_home: [`홈으로 가기`, `Home`, 's_trial_completed.go_home', `前往主页`, `前往首頁`, `ホームに移動`, `Trang chủ`],
  button: [
    `체험 수업 준비하기`,
    `Lesson Prep`,
    's_trial_completed.button',
    `准备体验课程`,
    `準備體驗課程`,
    `体験レッスンを準備する`,
    `Chuẩn bị Buổi học`,
  ],
}
export const s_auth = {
  modify: [`수정`, `Update`, 's_auth.modify', `修改`, `修改`, `編集`, `Cập nhật`],
  phone_verification: [
    `휴대폰 인증`,
    `Phone Verification`,
    's_auth.phone_verification',
    `手机验证`,
    `手機驗證`,
    `SMS認証`,
    `Xác minh số điện thoại`,
  ],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_auth.verification_code_request',
    `申请验证码`,
    `傳送驗證碼`,
    `認証コードを受け取る`,
    `Yêu cầu mã xác minh`,
  ],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_auth.verification_code_re_request',
    `重新申请验证码`,
    `重新傳送驗證碼`,
    `認証コードを再送する`,
    `Yêu cầu mã xác minh`,
  ],
  submit_code: [`입력 완료`, `Verify`, 's_auth.submit_code', `输入完成`, `輸入完畢`, `入力完了`, `Xác minh`],
  kakao: [
    `카카오로 5초만에 시작`,
    `Sign up with Kakao`,
    's_auth.kakao',
    `使用Kakao账户5秒完成注册`,
    `使用Kakao 5秒註冊`,
    `KakaoTalkで5秒で登録`,
    `Đăng ký bằng Kakao`,
  ],
  with_email: [
    `이메일로 시작`,
    `Sign up with email`,
    's_auth.with_email',
    `使用电子邮箱注册`,
    `使用電子信箱註冊`,
    `メールアドレスで登録`,
    `Đăng ký bằng email`,
  ],
  naver: [
    `네이버로 가입`,
    `Sign up with Naver`,
    's_auth.naver',
    `使用Naver注册`,
    `使用NAVER註冊`,
    `Naverで登録`,
    `Đăng ký bằng Naver`,
  ],
  choose: [
    `회원가입 방법을 선택해주세요`,
    `Sign up with`,
    's_auth.choose',
    `请选择会员注册方法`,
    `請選擇會員註冊方式`,
    `会員登録方法を選択してください。`,
    `Đăng ký bằng`,
  ],
  send_verification: [
    `인증번호 전송`,
    `Send Verification Code`,
    's_auth.send_verification',
    `发送验证码`,
    `傳送驗證碼`,
    `認証コード送信`,
    `Gửi Mã xác minh`,
  ],
  ok: [`확인`, `OK`, 's_auth.ok', `确认`, `確認`, `確認する`, `Xác nhận`],
  phone_check: [
    `휴대폰 번호를 올바르게 입력해주세요.`,
    `Invalid phone number. Please try again.`,
    's_auth.phone_check',
    `请输入正确的手机号码。`,
    `請輸入正確的手機號碼。`,
    `正しい電話番号を入力してください。`,
    `Số điện thoại không hợp lệ. Vui lòng thử lại.`,
  ],
  google: [
    `구글로 가입`,
    `Sign up with Google`,
    's_auth.google',
    `使用谷歌注册`,
    `使用Google註冊`,
    `Googleで登録`,
    `Đăng ký bằng Google`,
  ],
  facebook: [
    `페이스북으로 가입`,
    `Sign up with Facebook`,
    's_auth.facebook',
    `使用Facebook注册`,
    `使用Facebook註冊`,
    `Facebookで登録`,
    `Đăng ký bằng Facebook`,
  ],
  native_languaged: [
    `모국어`,
    `Native language`,
    's_auth.native_languaged',
    `母语`,
    `母語`,
    `母国語`,
    `Ngôn ngữ mẹ đẻ`,
  ],
  native_language_placeholder: [
    `모국어를 선택해주세요`,
    `Select your native language`,
    's_auth.native_language_placeholder',
    `请选择母语`,
    `請選擇母語`,
    `母国語を選択してください。`,
    `Chọn ngôn ngữ mẹ đẻ của bạn`,
  ],
  this_number_already_exists: [
    `이미 해당 번호로 가입된 계정이 있습니다. 링글팀에 문의해주세요.`,
    `An account with this number already exists. Please contact us with any questions.`,
    's_auth.this_number_already_exists',
    `已有用此号码加入的账号.如有疑问请联系客服.`,
    `以有用此號碼加入的賬號. 如有疑問請聯係客服.`,
    `登録済みのアカウントがあります。 Ringleチームにお問い合わせください。`,
    `Tài khoản với số điện thoại này đã tồn tại. Vui lòng liên hệ với chúng tôi nếu bạn có thắc mắc.`,
  ],
  title: [`Sign up`, `Sign up`, 's_auth.title', `Sign up`, `Sign up`, `Sign up`, `Sign up`],
  apply_tutor: [
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    's_auth.apply_tutor',
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    `Apply to become a tutor`,
  ],
}
export const s_purchase = {
  header: [`결제하기`, `Purchase`, 's_purchase.header', `付款`, `付款`, `お支払い内容`, `Thanh toán`],
  name_ko: [`한글 이름`, `Name`, 's_purchase.name_ko', `中文姓名`, `中文姓名`, `氏名（日本語）`, `Tên`],
  lesson_length: [
    `수업길이`,
    `Duration`,
    's_purchase.lesson_length',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng`,
  ],
  time: [`시간대`, `Times`, 's_purchase.time', `时间段`, `時段`, `受講時間帯`, `Thời gian`],
  point: [`포인트`, `Points`, 's_purchase.point', `积分`, `點數`, `ポイント`, `Điểm`],
  price: [`결제금액`, `Subtotal`, 's_purchase.price', `付款金额`, `付款金額`, `決済金額`, `Tổng`],
  discount_price: [
    `총 할인 금액`,
    `Total discount`,
    's_purchase.discount_price',
    `总优惠金额`,
    `總折扣金額`,
    `割引分合計`,
    `Tổng giảm giá`,
  ],
  won: (price) => [`${price}원`, `$${price}`, 's_purchase.won', `${price}`, `${price}`, `${price}`, `$${price}`],
  total_price: [
    `최종 결제 금액`,
    `Your total`,
    's_purchase.total_price',
    `实际付款金额`,
    `最終付款金額`,
    `お支払い総額`,
    `Tổng cộng`,
  ],
  directPayment: [
    `카드 직접 입력`,
    `Enter card information`,
    's_purchase.directPayment',
    `直接输入银行卡`,
    `手動輸入信用卡`,
    `カード直接入力`,
    `Nhập thông tin thẻ`,
  ],
  virtual_account: [
    `무통장 입금`,
    `Wire Transfer`,
    's_purchase.virtual_account',
    `无存折汇款`,
    `無摺存款`,
    `口座振込`,
    `Chuyển khoản`,
  ],
  paypal: [`Paypal 사용`, `via PayPal`, 's_purchase.paypal', `使用Paypal`, `使用PayPal`, `PayPal使用`, `qua PayPal`],
  use_point: [
    `포인트 사용`,
    `Points applied`,
    's_purchase.use_point',
    `使用积分`,
    `使用點數`,
    `ポイント利用`,
    `Điểm được áp dụng`,
  ],
  verify: [`본인 인증`, `Verify`, 's_purchase.verify', `身份验证`, `身分驗證`, `本人認証`, `Xác minh`],
  verify_explanation: [
    `타인 명의 도용, 중복 가입 방지를 위해 본인을 인증합니다.`,
    `Please verify to prevent fraud.`,
    's_purchase.verify_explanation',
    `为防止盗用及重复注册的现象出现, 需要进行身份验证。`,
    `為防止盜用他人名義或重複註冊，進行身分驗證。`,
    `他人名義の不正利用および重複登録防止の目的で本人認証を行います。`,
    `Vui lòng xác minh để ngăn gian lận.`,
  ],
  usual_price: [`정가 가격`, `Regular price`, 's_purchase.usual_price', `定价`, `定價`, `通常価格`, `Giá tiêu chuẩn`],
  discount_sum: [
    `총 할인금액`,
    `Total discount`,
    's_purchase.discount_sum',
    `总优惠金额`,
    `總折扣金額`,
    `割引分合計`,
    `Tổng giảm giá`,
  ],
  account_transfer: [
    `실시간 계좌 이체`,
    `Wire Transfer`,
    's_purchase.account_transfer',
    `实时转账`,
    `即時轉帳`,
    `リアルタイム口座振替`,
    `Chuyển tiền`,
  ],
  naver_pay: [
    `네이버페이(카드결제)`,
    `NaverPay`,
    's_purchase.naver_pay',
    `Naver Pay(银行卡付款)`,
    `Naver Pay（信用卡付款）`,
    `NaverPay(カード決済)`,
    `NaverPay`,
  ],
  virtual_account_make: [
    `가상 계좌 발급`,
    `Virtual Account`,
    's_purchase.virtual_account_make',
    `提供虚拟账号`,
    `提供虛擬帳號`,
    `仮想口座発行`,
    `Virtual Account`,
  ],
  pay_pal: [`PayPal`, `PayPal`, 's_purchase.pay_pal', `PayPal`, `PayPal`, `PayPal`, `PayPal`],
  warning: [`주의사항`, `Terms`, 's_purchase.warning', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
  second_card: [
    `분할 결제 (두번째 카드)`,
    `Second card`,
    's_purchase.second_card',
    `分别付款 (银行卡二)`,
    `分批付款（第二張信用卡）`,
    `分割決済 (2枚目のカード)`,
    `Thẻ thứ hai`,
  ],
  first_card: [
    `분할 결제 (첫번째 카드)`,
    `First card`,
    's_purchase.first_card',
    `分别付款 (银行卡一)`,
    `分批付款（第一張信用卡）`,
    `分割決済 (1枚目のカード)`,
    `Thẻ thứ nhất`,
  ],
  two_digit: [`2자리`, `2 Digit`, 's_purchase.two_digit', `2个字符`, `2位數`, `2桁`, `2 chữ số`],
  only_domestic: [
    `국내 카드만 가능`,
    `Only available for Korean cards.`,
    's_purchase.only_domestic',
    `仅限韩国银行卡`,
    `僅限韓國銀行卡`,
    `国内(韓国)カード限定利用可能`,
    `Chỉ có sẵn cho thẻ Hàn Quốc`,
  ],
  purchase_agreement: [
    <>
      결제 내역을 확인하였으며{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline underline-offset-1 text-purple-500">전자상거래법 및 링글 정책</span>
      </LinkLocale>
      에 의거하여 환불이 진행되는 것에 동의합니다.
    </>,
    <>
      I confirm my purchase and agree to{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline underline-offset-1 text-purple-500">Ringle's terms and conditions</span>
      </LinkLocale>
      .
    </>,
    's_purchase.purchase_agreement',
    <>
      已确认付款明细，同意依据{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">电子商务交易法及Ringle政策</span>
      </LinkLocale>
      进行退款。{' '}
    </>,
    <>
      已確認付款明細，同意依據{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">電子商務交易法及Ringle政策</span>
      </LinkLocale>
      進行退款。
    </>,
    <>
      決済履歴を確認し、{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">電子商取引法およびRingleポリシー</span>
      </LinkLocale>
      に基づいて返金が行われることに同意します。
    </>,
    <>
      Tôi xác nhận thanh toán của mình và đồng ý với{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">Điều khoản và điều kiện của Ringle</span>
      </LinkLocale>
      .
    </>,
  ],
  switch_to_won: [
    `한국 결제 방식으로 바꾸기`,
    `Switch to Korean payment method`,
    's_purchase.switch_to_won',
    `转换为韩国支付方式`,
    `轉換為韓國支付方式`,
    `韓国の決済方式に変更する`,
    `Chuyển phương thức thanh toán Hàn Quốc`,
  ],
  switch_to_other: [
    `해외 결제 방식으로 바꾸기`,
    `Switch to local payment method`,
    's_purchase.switch_to_other',
    `转换为本地支付方式`,
    `轉換為本地支付方式`,
    `現地の決済方式に変更する`,
    `Chuyển phương thức thanh toán địa phương`,
  ],
  process: {
    title: [
      `결제가 진행중입니다.`,
      `Processing your payment...`,
      's_purchase.process.title',
      `正在结算`,
      `正在結算`,
      `決済が行われています。`,
      `Đang tiến hành thanh toán...`,
    ],
    subtitle1: [
      `최대 1분이 소요될 수 있습니다. 잠시만 기다려주세요.`,
      `This may take up to a minute to complete.`,
      's_purchase.process.subtitle1',
      `可能需要1分钟，请耐心等待。`,
      `可能需要1分鐘，請耐心等待。`,
      `最大1分の所要時間があります。しばらくお待ちください。`,
      `Quy trình diễn ra trong vòng 1 phút.`,
    ],
    subtitle2: [
      `브라우저를 닫거나 새로고침 하시면 결제 오류가 발생할 수 있습니다.`,
      `Please stay on the page while the payment is being processed.`,
      's_purchase.process.subtitle2',
      `关闭或刷新页面可能会导致结算失败`,
      `關閉或刷新網頁可能會導致結算失敗`,
      `ブラウザを閉じたり更新したりすると決済エラーが発生する場合があります。`,
      `Vui lòng không thoát khỏi trang trong khi thah toán.`,
    ],
  },
  fail: {
    title: [
      <>
        결제를 완료할 수 없습니다.
        <br />
        다시 시도해주세요.
      </>,
      <>
        Your payment didn't go through.
        <br />
        Please try again.
      </>,
      's_purchase.fail.title',
      <>
        无法结算。
        <br />
        请再试一次。
      </>,
      <>
        無法結算。
        <br />
        請再試一次。
      </>,
      <>
        決済を完了することができません。
        <br />
        もう一度お試しください。
      </>,
      <>
        Thanh toán không thành công. <br />
        Vui lòng thử lại.
      </>,
    ],
    error: [
      `결제 오류`,
      `Transaction failed`,
      's_purchase.fail.error',
      `结算失败`,
      `結算失敗`,
      `決済エラー`,
      `Giao dịch thất bại`,
    ],
    history: [
      `결제 시도 내역`,
      `Payment attempts`,
      's_purchase.fail.history',
      `尝试结算记录`,
      `嘗試結算記錄`,
      `決済内訳`,
      `Thông tin thanh toán`,
    ],
    amount: [
      `결제 요청 금액`,
      `Amount due`,
      's_purchase.fail.amount',
      `结算金额`,
      `結算金額`,
      `決済要求金額`,
      `Số tiền đến hạn`,
    ],
    help1: [
      `결제 오류가 반복되신다면`,
      `Having trouble?`,
      's_purchase.fail.help1',
      `如果多次结算失败，`,
      `如果多次結算失敗，`,
      `決済エラーが繰り返される場合は、`,
      `Nếu bạn gặp sự cố`,
    ],
    help2: [
      `여기를 눌러 문의 해주세요.`,
      `Let us help you.`,
      's_purchase.fail.help2',
      `请点击此次咨询。`,
      `請點擊此處咨詢。`,
      `ここをクリックしてお問い合わせください。`,
      `vui lòng liên hệ với chúng tôi.`,
    ],
    btn: [`다시 결제하기`, `Try again`, 's_purchase.fail.btn', `重新结算`, `重新結算`, `もう一度決済する`, `Thử lại`],
    popup_title: [
      `팝업이 차단되어 결제할 수 없습니다. 팝업 허용 후 다시 시도해 주세요.`,
      `Payment could not be completed because pop-ups are blocked by the browser. Please try again after enabling pop-ups.`,
      's_purchase.fail.popup_title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice_1credit: [
    `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    's_purchase.notice_1credit',
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
  ],
  ticket_name: [`상품명`, `Ticket`, 's_purchase.ticket_name', ``, ``, ``, ``],
  ticket_date: [`참여날짜`, `Date`, 's_purchase.ticket_date', ``, ``, ``, ``],
  discount_secret: [
    `시크릿 쿠폰 할인`,
    `Secret Offer`,
    's_purchase.discount_secret',
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
  ],
  total_price_card: {
    price: [
      `최종 결제 금액`,
      `Total`,
      's_purchase.total_price_card.price',
      `实际付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      `Tổng cộng`,
    ],
    promo: [
      `프로모션 특가`,
      `Promotional Deal`,
      's_purchase.total_price_card.promo',
      `活动特价`,
      `活動特價`,
      `キャンペーン特価`,
      `Giá đặc biệt`,
    ],
  },
  tosspay: {
    title: [
      `토스페이 첫 결제 이벤트`,
      `First-time Toss Pay Users Event`,
      's_purchase.tosspay.title',
      `First-time Toss Pay Users Event`,
      `First-time Toss Pay Users Event`,
      `First-time Toss Pay Users Event`,
      `First-time Toss Pay Users Event`,
    ],
    text_btn: [`유의사항`, `Details`, 's_purchase.tosspay.text_btn', `Details`, `Details`, `Details`, `Details`],
    desc_1: [
      `토스페이로 생애 첫 결제 시 5천원 캐시백`,
      `$3.84 cash-back for first-time Toss Pay users`,
      's_purchase.tosspay.desc_1',
      `$3.84 cash-back for first-time Toss Pay users`,
      `$3.84 cash-back for first-time Toss Pay users`,
      `$3.84 cash-back for first-time Toss Pay users`,
      `$3.84 cash-back for first-time Toss Pay users`,
    ],
    desc_2: [
      `5월 4일 까지, 4000명 선착순`,
      `For the first 4,000 users until May 4`,
      's_purchase.tosspay.desc_2',
      `For the first 4,000 users until May 4`,
      `For the first 4,000 users until May 4`,
      `For the first 4,000 users until May 4`,
      `For the first 4,000 users until May 4`,
    ],
    modal_title: [
      `토스페이 이벤트 유의사항`,
      `Terms and Conditions`,
      's_purchase.tosspay.modal_title',
      `Terms and Conditions`,
      `Terms and Conditions`,
      `Terms and Conditions`,
      `Terms and Conditions`,
    ],
    modal_desc_1: [
      `결제 시 토스페이 간편결제 선택 시에만 할인이 적용됩니다. (기간 내 ID당 1회)`,
      `Only applies when using Toss Pay for purchasing credits. (Applicable once per ID during the promotional period)`,
      's_purchase.tosspay.modal_desc_1',
      `Only applies when using Toss Pay for purchasing credits. (Applicable once per ID during the promotional period)`,
      `Only applies when using Toss Pay for purchasing credits. (Applicable once per ID during the promotional period)`,
      `Only applies when using Toss Pay for purchasing credits. (Applicable once per ID during the promotional period)`,
      `Only applies when using Toss Pay for purchasing credits. (Applicable once per ID during the promotional period)`,
    ],
    modal_desc_2: [
      `첫 충전 즉시 캐시백은 토스 전체 가맹점에서 결제 이력이 없는 경우 적용됩니다. (토스 ID당 1회)`,
      `User must not have any history of using Toss Pay at Toss-affiliated shops or brands. (Applicable once per Toss ID)`,
      's_purchase.tosspay.modal_desc_2',
      `User must not have any history of using Toss Pay at Toss-affiliated shops or brands. (Applicable once per Toss ID)`,
      `User must not have any history of using Toss Pay at Toss-affiliated shops or brands. (Applicable once per Toss ID)`,
      `User must not have any history of using Toss Pay at Toss-affiliated shops or brands. (Applicable once per Toss ID)`,
      `User must not have any history of using Toss Pay at Toss-affiliated shops or brands. (Applicable once per Toss ID)`,
    ],
    modal_desc_3: [
      `결제 금액은 쿠폰 할인 및 적립금 적용 후 실 결제 금액입니다.`,
      `The payment total shown on Toss Pay is the amount due after deducting applicable coupon discounts and points.`,
      's_purchase.tosspay.modal_desc_3',
      `The payment total shown on Toss Pay is the amount due after deducting applicable coupon discounts and points.`,
      `The payment total shown on Toss Pay is the amount due after deducting applicable coupon discounts and points.`,
      `The payment total shown on Toss Pay is the amount due after deducting applicable coupon discounts and points.`,
      `The payment total shown on Toss Pay is the amount due after deducting applicable coupon discounts and points.`,
    ],
    modal_desc_4: [
      `할인 혜택은 1개 주문건 당 결제 금액을 기준으로 적용됩니다.`,
      `The cash-back will be applied to the purchase made when using Toss Pay for the first time.`,
      's_purchase.tosspay.modal_desc_4',
      `The cash-back will be applied to the purchase made when using Toss Pay for the first time.`,
      `The cash-back will be applied to the purchase made when using Toss Pay for the first time.`,
      `The cash-back will be applied to the purchase made when using Toss Pay for the first time.`,
      `The cash-back will be applied to the purchase made when using Toss Pay for the first time.`,
    ],
    modal_desc_5: [
      `토스 이외의 간편결제수단 (네이버페이, 차이, 페이코 등) 이용 혹은 신용카드 선택 시, 즉시할인 적용이 불가합니다.`,
      `The cash-back will not be applied if the purchase is made using another service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
      's_purchase.tosspay.modal_desc_5',
      `The cash-back will not be applied if the purchase is made using another service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
      `The cash-back will not be applied if the purchase is made using another service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
      `The cash-back will not be applied if the purchase is made using another service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
      `The cash-back will not be applied if the purchase is made using another service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
    ],
    modal_desc_6: [
      `결제 전체 취소 후 기간 내 재결제 시 혜택 적용되며, 결제 부분 취소 후 재결제 시 혜택 미적용 됩니다.`,
      `The cash-back can be reclaimed within the promotional period upon cancelling the purchase for which it was applied, but not after a partial cancellation or refund.`,
      's_purchase.tosspay.modal_desc_6',
      `The cash-back can be reclaimed within the promotional period upon cancelling the purchase for which it was applied, but not after a partial cancellation or refund.`,
      `The cash-back can be reclaimed within the promotional period upon cancelling the purchase for which it was applied, but not after a partial cancellation or refund.`,
      `The cash-back can be reclaimed within the promotional period upon cancelling the purchase for which it was applied, but not after a partial cancellation or refund.`,
      `The cash-back can be reclaimed within the promotional period upon cancelling the purchase for which it was applied, but not after a partial cancellation or refund.`,
    ],
    modal_desc_7: [
      `04월 04일부터 05월 4일까지 한정된 고객에게 선착순으로 적용되는 즉시 할인 행사로, 조기 종료될 수 있습니다.`,
      `The cash-back is offered on a first come, first served basis to a set number of users from April 4 to May 4 and may close early depending on users' participation.`,
      's_purchase.tosspay.modal_desc_7',
      `The cash-back is offered on a first come, first served basis to a set number of users from April 4 to May 4 and may close early depending on users' participation.`,
      `The cash-back is offered on a first come, first served basis to a set number of users from April 4 to May 4 and may close early depending on users' participation.`,
      `The cash-back is offered on a first come, first served basis to a set number of users from April 4 to May 4 and may close early depending on users' participation.`,
      `The cash-back is offered on a first come, first served basis to a set number of users from April 4 to May 4 and may close early depending on users' participation.`,
    ],
    modal_desc_8: [
      `행사 내용은 토스 및 링글의 사정으로 중단 또는 변동될 수 있습니다. 관련 문의는 토스 고객센터(1599-4905)로 문의 부탁드립니다.`,
      `The event may be subject to changes or cancellation. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
      's_purchase.tosspay.modal_desc_8',
      `The event may be subject to changes or cancellation. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
      `The event may be subject to changes or cancellation. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
      `The event may be subject to changes or cancellation. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
      `The event may be subject to changes or cancellation. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
    ],
  },
  newcomer_discount: [
    `신규회원 한정 할인`,
    `Newcomers discount`,
    's_purchase.newcomer_discount',
    `Newcomers discount`,
    `Newcomers discount`,
    `Newcomers discount`,
    `Newcomers discount`,
  ],
  lastminute_lesson: [
    `24시간 이내 수업 예약 할인`,
    `Book a lesson within 24h`,
    's_purchase.lastminute_lesson',
    `Book a lesson within 24h`,
    `Book a lesson within 24h`,
    `Book a lesson within 24h`,
    `Book a lesson within 24h`,
  ],
}
export const s_purchase_completed = {
  done: [`결제완료`, `Complete`, 's_purchase_completed.done', `付款完成`, `付款成功`, `決済完了`, `Hoàn thành`],
  done_desc: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
      <Div fontBold textD5>
        이제 링글 수업을 시작해보세요!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}用户
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
      </Div>
      <Div fontBold textD5>
        现在就开始您的Ringle课程吧!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
        。
      </Div>
      <Div fontBold textD5>
        現在就開始Ringle課程吧！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}さん
        <br />
        <Div spanTag textPrimary>
          決済が完了
        </Div>
        しました。
      </Div>
      <Div fontBold textD5>
        Ringleのレッスンを始めましょう！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Chào {name}
        <br />
        <Div spanTag textPrimary>
          Chúc mừng bạn!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        Chúng tôi đã nhận được thanh toán của bạn.
      </Div>
    </>,
  ],
  purchase_info: [
    `결제 내역`,
    `Purchase history`,
    's_purchase_completed.purchase_info',
    `结算内容`,
    `付款明細`,
    `決済履歴`,
    `Lịch sử mua hàng`,
  ],
  point_use: [
    `포인트 사용 내역`,
    `Points history`,
    's_purchase_completed.point_use',
    `积分使用内容`,
    `點數使用明細`,
    `ポイント使用履歴`,
    `Lịch sử điểm`,
  ],
  total: [
    `최종 결제 금액`,
    `Your total`,
    's_purchase_completed.total',
    `最终付款金额`,
    `最終付款金額`,
    `最終決済金額`,
    `Tổng cộng`,
  ],
  order: [
    `주문상품`,
    `Your cart`,
    's_purchase_completed.order',
    `订购的商品`,
    `訂購商品`,
    `購入内容`,
    `Giỏ hàng của bạn`,
  ],
  lesson_credit: [
    `수업권명`,
    `Package`,
    's_purchase_completed.lesson_credit',
    `课程券名称`,
    `課程券名稱`,
    `レッスンチケット`,
    `Gói`,
  ],
  lesson_length: [
    `수업길이`,
    `Duration`,
    's_purchase_completed.lesson_length',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng`,
  ],
  minutes: (minute) => [
    `${minute}분`,
    `${minute} min`,
    's_purchase_completed.minutes',
    `${minute}分钟`,
    `${minute}分鐘`,
    `${minute}分`,
    `${minute} phút`,
  ],
  exp_dates: [`시간대`, `Times`, 's_purchase_completed.exp_dates', `时间段`, `時段`, `時間帯`, `Thời gian`],
  time_desc: [
    `수업 시작 기준입니다.`,
    `Lesson starting times`,
    's_purchase_completed.time_desc',
    `以课程开始为准。`,
    `以課程開始時間為準。`,
    `レッスン開始時間`,
    `Thời gian bắt đầu buổi học`,
  ],
  duration: [
    `수강기간`,
    `Expiration dates`,
    's_purchase_completed.duration',
    `使用期限`,
    `使用期限`,
    `有効期限`,
    `Ngày hết hạn`,
  ],
  duration_desc: [
    `첫 수업시작 전까지 수강기간은 미차감됩니다.`,
    `Calculated from the date you complete your first lesson using the purchased package.`,
    's_purchase_completed.duration_desc',
    `首次课程开始前, 不扣除使用期限。`,
    `首次課程開始之前，不扣除使用期限。`,
    `有効期限は初回レッスン受講後から起算されます。`,
    `Tính toán dựa vào ngày bạn hoàn thành buổi học đầu tiên đã thanh toán.`,
  ],
  challenge: [
    `도전기간`,
    `Challenge period`,
    's_purchase_completed.challenge',
    `挑战期间`,
    `挑戰期間`,
    `挑戦期間`,
    `Thời gian thử thách`,
  ],
  challenge_desc: (day, count) => [
    `첫수업 후 ${day}일내 정규수업권 소진 시, +${count}회가 지급됩니다.`,
    `Use all your credits in ${day} days and get +${count} bonus lessons.`,
    's_purchase_completed.challenge_desc',
    `首次课程后${day}日内, 正规课程券全部使用时, 将追加提供+${count}次。`,
    `首次課程結束後，於${day}天內將正規課程券全數使用時，將發送+${count}次。`,
    `初回レッスン後、${day}日以内にレギュラーレッスンチケット消費時、 +${count}回が支給されます。`,
    `Sử dụng tất cả thẻ học trong ${day} ngày và nhận +${count} buổi học tặng thêm.`,
  ],
  new_to_ringle: [
    `링글 수업 전 꼭 확인해 주세요!`,
    `New to Ringle?`,
    's_purchase_completed.new_to_ringle',
    `您是第一次使用Ringle吗?`,
    `如果您是第一次使用Ringle？`,
    `Ringleが初めてなら？`,
    `Lần đầu dùng Ringle?`,
  ],
  go_to_home: [
    `홈으로 가기`,
    `Home`,
    's_purchase_completed.go_to_home',
    `前往主页`,
    `前往首頁`,
    `ホームに移動`,
    `Trang chủ`,
  ],
  vbank_name: [
    `입금 가상 계좌번호`,
    `Virtual account number`,
    's_purchase_completed.vbank_name',
    `Virtual account number`,
    `Virtual account number`,
    `入金仮想口座番号`,
    `Số tài khoản ảo (Virtual account number)`,
  ],
  vbank_date: [
    `입금 기한`,
    `Payment due by`,
    's_purchase_completed.vbank_date',
    `Payment due by`,
    `Payment due by`,
    `入金期間`,
    `Hạn thanh toán`,
  ],
  vbank_title: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        아래 계좌로 입금해 주세요.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below
      </Div>{' '}
    </>,
    's_purchase_completed.vbank_title',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}様 <br /> 下記の口座に入金してください{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name} <br /> vui lòng thanh toán theo thông tin bên dưới{' '}
      </Div>{' '}
    </>,
  ],
  existing_customer: [
    `링글 수업, 이렇게도 활용할 수 있어요!`,
    ``,
    's_purchase_completed.existing_customer',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_booking: [
    `수업 예약하기`,
    `Book a lesson`,
    's_purchase_completed.go_to_booking',
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
  ],
  go_to_OT: [
    `OT 신청하기`,
    `Register Orientation`,
    's_purchase_completed.go_to_OT',
    `Register Orientation`,
    `Register Orientation`,
    `Register Orientation`,
    `Register Orientation`,
  ],
  go_to_booking_app: [
    `앱으로 수업 예약하기`,
    `Book a lesson`,
    's_purchase_completed.go_to_booking_app',
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
  ],
  go_to_study_week: [
    `영어학습 세미나 신청하기`,
    `Sign up: 
How to Study English Seminar`,
    's_purchase_completed.go_to_study_week',
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
  ],
  email_desc: [
    `컨퍼런스 신청이 완료되었습니다. 자세한 내용은
가입 시 입력하신 이메일로 보내드립니다.`,
    ``,
    's_purchase_completed.email_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`컨퍼런스 페이지로 돌아가기`, `Conference Page`, 's_purchase_completed.btn1', ``, ``, ``, ``],
  btn2: [`링글 알아보기`, `Ringle Home`, 's_purchase_completed.btn2', ``, ``, ``, ``],
  done_desc_conference: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc_conference',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}用户
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
      </Div>
      <Div fontBold textD5>
        现在就开始您的Ringle课程吧!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
        。
      </Div>
      <Div fontBold textD5>
        現在就開始Ringle課程吧！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}さん
        <br />
        <Div spanTag textPrimary>
          決済が完了
        </Div>
        しました。
      </Div>
      <Div fontBold textD5>
        Ringleのレッスンを始めましょう！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Chào {name}
        <br />
        <Div spanTag textPrimary>
          Chúc mừng bạn!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        Chúng tôi đã nhận được thanh toán của bạn.
      </Div>
    </>,
  ],
  duration_desc_2: [
    `수업권 결제 직후부터 수강기간 차감이 시작됩니다.`,
    `Credits begin expiring from purchase date.`,
    's_purchase_completed.duration_desc_2',
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
  ],
  lesson: [`수업시간`, `Lesson`, 's_purchase_completed.lesson', `Lesson`, `Lesson`, `Lesson`, `Lesson`],
  receipt_certificate: {
    desc: [
      `영수증/출석증은 PC에서 오른쪽 상단 프로필 > 수업권/증명서류 탭에서 PDF로 다운로드 가능합니다.`,
      `You can download your lesson certificates & proof of attendance under Credits.`,
      's_purchase_completed.receipt_certificate.desc',
      `收据/出席证可在PC右上方简介 > 课程券/证明文件页面中下载PDF文本。`,
      `收據／出席證明可於電腦版右上方的簡介 > 課程券／證明分頁下載PDF檔。`,
      `領収証/出席証はPCの右上のプロフィール > レッスンチケット/証明書類タブからPDFでダウンロード可能です。`,
      `Bạn có thể tải chứng nhận buổi học & chứng nhận tham dự dưới phần Thẻ học.`,
    ],
    go: [`바로가기`, `Go`, 's_purchase_completed.receipt_certificate.go', `立即前往`, `立即前往`, `移動`, `Go`],
  },
  alltime_time: [
    `오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)에 수강 가능합니다`,
    `Regular lesson hours are 5:00 am - 2:00 pm / 7:00 pm - 3:00 am (KST).`,
    's_purchase_completed.alltime_time',
    `可在上午5点至下午2点 / 下午7点至凌晨3点（KST）上课。`,
    `可在上午5點至下午2點 / 下午7點至淩晨3點（KST）上課。`,
    `午前5時〜午後2時　/　19時〜03時（JST）に受講可能です。`,
    `Có thể tham gia lớp học vào các khung giờ 5H sáng đến 2H chiều / 19H đến 3H sáng (KST)`,
  ],
  expiration_time: {
    title: [
      `yyyy.mm.dd HH:mm (n일)까지 사용 가능`,
      `Valid until HH:mm, mm.dd.yyyy (n days left)`,
      's_purchase_completed.expiration_time.title',
      `可使用直到 yyyy.mm.dd HH:mm（n天）为止`,
      `可使用直到 yyyy.mm.dd HH:mm（n天）為止`,
      `yyyy.mm.dd HH:mm（n日）まで使用可能`,
      `Hạn dùng đến (Ngày...) HH:mm dd.mm.yyyy`,
    ],
    explantion: [
      `yyyy.mm.dd HH:mm까지 첫 수업을 진행해 주세요!`,
      `Please complete your first lesson by HH:mm, mm.dd.yyyy!`,
      's_purchase_completed.expiration_time.explantion',
      `请在 yyyy.mm.dd HH:mm 之前安排第一堂课！`,
      `請在 yyyy.mm.dd HH:mm 之前安排第一堂課！`,
      `yyyy.mm.dd HH:mmまで最初の授業を行ってください！`,
      `Hãy bắt đầu lớp học đầu tiên đến HH:mm dd.mm.yyyy!`,
    ],
  },
}
export const s_mypage = {
  ringle_email: [
    `링글 가입 계정 이메일`,
    `Email (Ringle account)`,
    's_mypage.ringle_email',
    `注册Ringle账户的电子邮箱`,
    `註冊Ringle帳戶的電子信箱`,
    `Ringle登録アカウントのメールアドレス`,
    `Email (tài khoản Ringle)`,
  ],
  email2: [
    `추가 이메일`,
    `Secondary email`,
    's_mypage.email2',
    `其他电子邮箱`,
    `其他電子信箱`,
    `追加メールアドレス`,
    `Email thứ 2`,
  ],
  choose_email: [
    `알람 수신용 이메일을 입력하세요.`,
    `Please enter email for notifications.`,
    's_mypage.choose_email',
    `请输入接收提醒的电子邮箱。`,
    `請輸入接收通知的電子信箱。`,
    `通知受信用のメールアドレスを入力してください。`,
    `Nhập email để nhận thông báo.`,
  ],
  can_only_change_email2: [
    `링글 가입 계정 이메일은 변경되지 않으며, 알림 수신 이메일만 변경됩니다.`,
    `We'll send any notifications to this email. Your sign in (account) email won't change.`,
    's_mypage.can_only_change_email2',
    `Ringle账户的注册邮箱不能进行变更, 只能修改接收提醒的邮箱。`,
    `註冊Ringle帳戶的電子信箱將不變動，僅變更接收通知的電子信箱。`,
    `Ringle登録アカウントのメールアドレスは変更されず、通知受信メールアドレスのみ変更されます。`,
    `Chúng tôi sẽ gửi bạn bất kì thông báo nào đến email. Email đăng nhập (tài khoản) của bạn sẽ không thay đổi.`,
  ],
  timezone_desc: [
    `변경할 타임존을 검색 또는 선택하세요.`,
    `Enter a city name or select from the list to change the time zone.`,
    's_mypage.timezone_desc',
    `当前位置与时区不符时, 请输入城市名称或从列表中选择。`,
    `目前位置與時區不符時，請輸入城市名稱或從清單中選擇。`,
    `現在地とタイムゾーンが異なる場合、都市名を入力またはリストから選択してください。`,
    `Nhập tên thành phố hoặc chọn từ danh sách để đổi múi giờ.`,
  ],
  save: [`저장`, `Save`, 's_mypage.save', `保存`, `儲存`, `保存`, `lưu`],
  leave: {
    title: [
      `링글 탈퇴하기`,
      `Delete my account`,
      's_mypage.leave.title',
      `注销Ringle账号`,
      `註銷Ringle會員`,
      `Ringleを退会する`,
      `Xoá tài khoản của tôi`,
    ],
    description: [
      `유의사항에 모두 체크하고, 탈퇴를 누르면  계정 내용이 모두 삭제 됩니다.`,
      `Account information will be deleted when you confirm the terms and delete the account.`,
      's_mypage.leave.description',
      `确认全部注意事项, 点击注销后账户所有内容将被删除。`,
      `勾選所有注意事項並點擊註銷時，將刪除所有帳戶內容。`,
      `注意事項をすべてチェックして退会を押すと、アカウント内容がすべて削除されます。`,
      `Thông tin tài khoản sẽ bị xoá ngay sau khi bạn xác nhận điều khoản và xoá tài khoản.`,
    ],
    important_title: [
      `유의사항`,
      `Terms`,
      's_mypage.leave.important_title',
      `注意事项`,
      `注意事項`,
      `注意事項`,
      `Điều khoản`,
    ],
    important_title_1: [
      `수업권, 포인트 및 쿠폰 모두 삭제`,
      `Deleted accounts cannot be restored.`,
      's_mypage.leave.important_title_1',
      `注销的账户无法撤回。`,
      `無法復原註銷的帳戶。`,
      `退会するアカウントは戻すことができません。`,
      `Tài khoản đã xoá không thể khôi phục.`,
    ],
    important_title_2: [
      `탈퇴 후 수업권 환불 불가`,
      `Remained credits and points will all be deleted.`,
      's_mypage.leave.important_title_2',
      `剩余的课程券及优惠券也将全部删除。`,
      `將刪除所有剩餘的課程券及優惠券。`,
      `残っているレッスンチケットおよびクーポンはすべて削除されます。`,
      `Tất cả những thẻ học còn lại và điểm sẽ bị xoá.`,
    ],
    important_title_3: [
      `피드백 리포트, 수업 내역 등의 학습 기록의 재열람 불가`,
      `All lesson history will be deleted.`,
      's_mypage.leave.important_title_3',
      `全部学习记录将被删除。`,
      `將刪除所有學習紀錄。`,
      `すべての学習記録は削除されます。`,
      `Tất cả lịch sử buổi học sẽ bị xoá.`,
    ],
    important_title_4: [
      `재가입 시 다른 이메일 계정 필요`,
      `You will have to make a new account with a different email address if you want to sign up again.`,
      's_mypage.leave.important_title_4',
      `如果您想重新注册, 需提供其他电子邮箱。`,
      `如欲重新註冊，必須使用其他電子信箱。`,
      `再登録したい場合、他のメールアドレスを使用してください。`,
      `Bạn sẽ phải tạo tài khoản mới với một địa chỉ email khác nếu muốn đăng ký lại.`,
    ],
    submit: [`탈퇴`, `Leave`, 's_mypage.leave.submit', `注销`, `註銷`, `退会`, `Rời khỏi`],
    cancel: [`취소`, `Cancel`, 's_mypage.leave.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  },
  does_not_match: [
    `새 비밀번호와 일치하지 않습니다.`,
    `Password does not match.`,
    's_mypage.does_not_match',
    `与新密码不一致。`,
    `與新密碼不一致。`,
    `新しいパスワードと一致しません。`,
    `Mật khẩu không khớp.`,
  ],
  at_least_six_char: [
    `비밀번호를 6글자 이상 입력해주세요.`,
    `Your password must be at least 6 characters.`,
    's_mypage.at_least_six_char',
    `请输入6位字符以上的密码。`,
    `請輸入6字以上的密碼。`,
    `パスワードを6文字以上入力してください。`,
    `Mật khẩu của bạn cần ít nhất 6 ký tự.`,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_mypage.your_option_change_has_been_completed',
    `变更的内容已保存。`,
    `變更的內容儲存成功。`,
    `変更した内容が保存されました。`,
    `Đã lưu`,
  ],
  please_select_language: [
    `변경할 언어를 선택하세요.`,
    `Please select language.`,
    's_mypage.please_select_language',
    `请选择主要使用语言.`,
    `請選擇主要使用的語言。`,
    `主な使用言語を選択してください。`,
    `Vui lòng chọn ngôn ngữ.`,
  ],
}
export const s_moportal = {
  group_a: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_a.welcome_to_ringle',
      <>
        您好,
        <br />
        尊贵的{name}用户!
      </>,
      <>
        您好，
        <br />
        {name}！
      </>,
      <>
        こんにちは、
        <br />
        {name}さん！{' '}
      </>,
      <>Xin chào {name}!</>,
    ],
    title: [
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>,
      <>
        Learn Real English
        <br />
        with Ringle
      </>,
      's_moportal.group_a.title',
      <>
        赶快开启学习正宗英语的
        <br />
        Ringle课程吧。
      </>,
      <>
        請開始可以學習
        <br />
        真正英語的Ringle課程吧！
      </>,
      <>
        リアルな英語を学ぶことができる
        <br />
        Ringleのレッスンを始めましょう。
      </>,
      <>
        Học tiếng Anh thực tế
        <br />
        với Ringle
      </>,
    ],
  },
  group_b: {
    welcome_to_ringle: [
      <>
        체험 수업은
        <br />
        만족스러우셨나요?
      </>,
      <>How was your trial lesson?</>,
      's_moportal.group_b.welcome_to_ringle',
      <>
        您对体验课程
        <br />
        满足吗?
      </>,
      <>
        您還滿意
        <br />
        體驗課程嗎？
      </>,
      <>
        体験レッスンは
        <br />
        ご満足いただけましたか？
      </>,
      <>Buổi học thử của bạn sao rồi?</>,
    ],
    title: [
      <>
        이제 원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_b.title',
      <>
        现在选择您满意的教师和教材
        <br />
        开始正规课程吧!
      </>,
      <>
        現在就選擇希望的任課教師和教材，
        <br />
        開始進行正規課程吧！
      </>,
      <>
        これからは好きなチューターと教材を選択して
        <br />
        レギュラーレッスンを始めましょう！
      </>,
      <>
        Trong buổi học thông thường bạn có thể
        <br />
        chọn gia sư & tài liệu
      </>,
    ],
    purchase: [
      <>수업권 보러가기</>,
      <>See pricing</>,
      's_moportal.group_b.purchase',
      <>查看课程券</>,
      <>前往查看課程券</>,
      <>レッスンチケットを見る</>,
      <>Xem học phí</>,
    ],
    purchase_sub: [
      <>
        나에게 맞는 수업권을 찾아보기!
        <br />
        심도 깊은 40분, 효율적인 20분
      </>,
      <>
        Find the right package for you
        <br />{' '}
      </>,
      's_moportal.group_b.purchase_sub',
      <>
        查找满足我需求的课程券!
        <br />
        深度解析40分钟, 高效便捷20分钟
      </>,
      <>
        尋找適合我的課程券！
        <br />
        具有深度的40分鐘、充滿效率的20分鐘
      </>,
      <>
        自分に合うレッスンチケットを探しましょう！
        <br />
        深く学べる40分、効率的な20分
      </>,
      <>
        Tìm gói học phù hợp cho bạn
        <br />{' '}
      </>,
    ],
    go_purchase: [
      <>가격 보러가기</>,
      <>See pricing</>,
      's_moportal.group_b.go_purchase',
      <>查看价格</>,
      <>前往查看價格</>,
      <>価格を見る</>,
      <>Xem học phí</>,
    ],
    review: [
      <>AI & 튜터가 분석해주는 복습</>,
      <>Feedback from your tutor & AI</>,
      's_moportal.group_b.review',
      <>经AI & 教师分析的复习内容</>,
      <>AI & 任課教師為您分析的複習</>,
      <>AI & チューターが分析してくれる復習</>,
      <>Đánh giá từ gia sư & AI</>,
    ],
    review_sub: [
      <>
        수업이 끝나도 사라지지 않는 영어,
        <br />
        링글을 끝까지 경험해보세요!
      </>,
      <>Review your lesson</>,
      's_moportal.group_b.review_sub',
      <>
        课程结束后也不会消失的英语
        <br />
        携手Ringle, 坚持到底!
      </>,
      <>
        即使課程結束也不會消失的英語，
        <br />
        攜手Ringle，堅持到底！
      </>,
      <>
        レッスンが終わっても消えない英語、
        <br />
        Ringleを最後までご体験ください！
      </>,
      <>Xem lại buổi học</>,
    ],
    go_review: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_b.go_review',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    how_to_ringle: [
      <>링글 사용법 바로가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.how_to_ringle',
      <>前往Ringle使用方法</>,
      <>立即前往Ringle使用方法</>,
      <>Ringleの使用方法を見る</>,
      <>Cách Ringle hoạt động</>,
    ],
    how_to_ringle_sub: [
      <>언제 어디서나, 원하는 수업을 자유롭게</>,
      <>Learn whenever and wherever</>,
      's_moportal.group_b.how_to_ringle_sub',
      <>自由进行希望的课程</>,
      <>隨時隨地自由進行想要的課程</>,
      <>いつでもどこでも、好きなレッスンを自由に</>,
      <>Học bất cứ khi nào và bất cứ nơi đâu</>,
    ],
    go_how_to_ringle: [
      <>간단한 사용법 보러가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.go_how_to_ringle',
      <>查看便捷使用方法</>,
      <>前往查看簡單的使用方法</>,
      <>簡単な使用方法を見る</>,
      <>Cách Ringle hoạt động</>,
    ],
  },
  group_c: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_c.welcome_to_ringle',
      <>
        您好,
        <br />
        尊贵的{name}用户!
      </>,
      <>
        您好，
        <br />
        {name}！
      </>,
      <>
        こんにちは、
        <br />
        {name}さん！{' '}
      </>,
      <>Xin chào {name}!</>,
    ],
    title: [
      <>
        링글 앱을 사용하면
        <br />
        손쉽게 수업을 예습하고 복습할 수 있어요!
      </>,
      <>Prep for your lessons via Ringe App!</>,
      's_moportal.group_c.title',
      <>
        使用Ringle应用程序,
        <br />
        可轻松对课程进行预习及复习!
      </>,
      <>
        使用Ringle APP的話，
        <br />
        可輕鬆預習及複習課程！
      </>,
      <>
        Ringleアプリを使用すると
        <br />
        手軽にレッスンを予習して復習することができます！
      </>,
      <>Chuẩn bị cho buổi học với ứng dụng Ringle!</>,
    ],
    schedule: [
      <>수업 예약하기</>,
      <>Book Lessons</>,
      's_moportal.group_c.schedule',
      <>课程预约</>,
      <>預約課程</>,
      <>レッスンを予約する</>,
      <>Đặt lịch học</>,
    ],
    schedule_sub: [
      <>
        원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_c.schedule_sub',
      <>
        选择自己满意的教师及教材
        <br />
        开始正规课程吧!
      </>,
      <>
        選擇希望的任課教師和教材，
        <br />
        開始進行正規課程吧！
      </>,
      <>
        好きなチューターと教材を選択して
        <br />
        レギュラーレッスンを始めましょう！
      </>,
      <>
        Trong buổi học thông thường bạn có thể
        <br />
        chọn gia sư & tài liệu
      </>,
    ],
    go_schedule: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_schedule',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    tutor: [
      <>튜터 프로필 보러가기</>,
      <>View Tutors</>,
      's_moportal.group_c.tutor',
      <>查看教师简介</>,
      <>前往查看任課教師簡介</>,
      <>チュータープロフィールを見る</>,
      <>Xem gia sư</>,
    ],
    tutor_sub: [
      <>
        1000+명 이상 검증된 <span className="text-purple-500">영미권 명문대 원어민 튜터</span>를 만나보세요!
      </>,
      <>Choose from 1000+ Tutors!</>,
      's_moportal.group_c.tutor_sub',
      <>
        1000名以上, 出身享誉全球的英美名校
        <br />
        携手母语教师一起学习吧!
      </>,
      <>
        與1000名以上眾所公認的英美名校
        <br />
        母語任課教師相見歡吧！
      </>,
      <>
        1000+名以上の検証された英米圏の名門大学の
        <br />
        ネイティブチューターをチェックしましょう！
      </>,
      <>Chọn từ 1000+ gia sư!</>,
    ],
    go_tutor: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_tutor',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    materials: [
      <>링글 교재 보러가기</>,
      <>View Materials</>,
      's_moportal.group_c.materials',
      <>查看Ringle教材</>,
      <>前往查看Ringle教材</>,
      <>Ringleの教材を見る</>,
      <>Xem Tài liệu</>,
    ],
    materials_sub: [
      <>일상 대화부터 시사, 실전 비즈니스 영어까지</>,
      <>Master your English from casual to advanced.</>,
      's_moportal.group_c.materials_sub',
      <>从日常生活到时事, 实战商务英语</>,
      <>從日常生活到時事、實戰商務英語</>,
      <>日常会話から時事、実践ビジネス英語まで</>,
      <>Thành thạo khả năng tiếng Anh của bạn dù giao tiếp đời thường hay nâng cao.</>,
    ],
    go_materials: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_materials',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
  },
}
export const s_web_auth = {
  verify: [`인증`, `Verify`, 's_web_auth.verify', `验证`, `驗證`, `認証`, `Xác minh`],
}
export const t_my_original = {
  favorite_material: [
    `찜한 교재`,
    `Favorite Materials`,
    't_my_original.favorite_material',
    ``,
    ``,
    ``,
    `Tài liệu Yêu thích`,
  ],
  check_exp_course: [
    <>
      내가 수업한 교재를 <br /> 여기서 확인할 수 있습니다.
    </>,
    `See your previous materials.`,
    't_my_original.check_exp_course',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
}
export const t_home = {
  todo_book_lesson: [
    `수업을 예약하고 영어 실력을 키워보아요! 💪`,
    `Book a Lesson and develop your 4Cs!💪`,
    't_home.todo_book_lesson',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  todo_all_done: [
    <>
      최고!
      <br />
      해야할 일을 모두 끝냈어요! 🥳{' '}
    </>,
    <>
      Woohoo!
      <br />
      You're done for today! 🥳{' '}
    </>,
    't_home.todo_all_done',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
}
export const s_lessons_schedule = {
  rules: {
    check_rules: [
      `수업 에티켓을 확인해 주세요!`,
      `Check Our Rules of Conduct`,
      's_lessons_schedule.rules.check_rules',
      `请确认课程规范!`,
      `請確認上課禮儀！`,
      `レッスンエチケットをご確認ください！`,
      `Kiểm tra Quy tắc ứng xử`,
    ],
    set_up: [
      `알맞은 수업 환경 준비하기`,
      `Set up an Environment for Learning`,
      's_lessons_schedule.rules.set_up',
      `打造舒适的课程环境`,
      `準備合適的上課環境`,
      `適切なレッスン環境を準備する`,
      `Thiết lập môi trường học tập`,
    ],
    do_not_drive: [
      `운전 중 / 이동 중 수업은 삼가 주세요.`,
      `Don’t Zoom and drive.`,
      's_lessons_schedule.rules.do_not_drive',
      `请勿于驾驶/移动中进行课程。`,
      `請避免於開車／移動中上課。`,
      `運転中 / 移動中のレッスンはお控えください。`,
      `Không dùng Zoom trong khi lái xe.`,
    ],
    no_drinking: [
      `음주 후 수업은 자제해 주세요.`,
      `No drinking during lessons.`,
      's_lessons_schedule.rules.no_drinking',
      `请勿饮酒后上课。`,
      `請避免於飲酒後上課。`,
      `飲酒後のレッスンはご遠慮ください。`,
      `Không sử dụng đồ uống có cồn trong buổi học.`,
    ],
    punctual: [
      `약속된 시간 지키기`,
      `Be Punctual`,
      's_lessons_schedule.rules.punctual',
      `请遵守约定的时间`,
      `遵守約定的時間`,
      `約束の時間を守る`,
      `Đúng giờ`,
    ],
    keep_schdules: [
      `정해진 수업 시간을 지켜주세요.`,
      `Keep track of your lesson schedules.`,
      's_lessons_schedule.rules.keep_schdules',
      `请遵守规定的课程时间。`,
      `請遵守指定上課時間。`,
      `決められたレッスン時間を守ってください。`,
      `Theo dõi thời khoá biểu của bạn`,
    ],
    no_external_request: [
      `수업 종료 이후 요청은 자제해 주세요.`,
      `Tutors aren’t responsible for\nany external lesson requests.`,
      's_lessons_schedule.rules.no_external_request',
      `课程结束后, 请勿提出要求。`,
      `課程結束後，請勿提出要求。`,
      `レッスン終了以降のリクエストはご遠慮ください。`,
      `Gia sư không chịu tránh nhiệm cho\nbất kỳ yêu cầu nào ngoài buổi học.`,
    ],
    with_respect: [
      `튜터 존중하기`,
      `Treat Your Tutor with Respect`,
      's_lessons_schedule.rules.with_respect',
      `请尊师重教`,
      `尊重任課教師`,
      `チューターを尊重する`,
      `Tôn trọng gia sư của bạn`,
    ],
    no_photos: [
      `수업 중 사적인 질문, 사진 촬영 등은 삼가 주세요.`,
      `Refrain from asking\nsensitive questions or taking photographs.`,
      's_lessons_schedule.rules.no_photos',
      `课程中请避免隐私问题及拍照。`,
      `上課時請勿提出私人問題或拍照。`,
      `レッスン中の私的な質問や写真撮影などはお控えください。`,
      `Không hỏi những câu hỏi\nnhạy cảm hoặc chụp hình.`,
    ],
    no_discriminatory_remarks: [
      `의도하지 않은 혐오 발언을 주의해 주세요.`,
      `Do not make discriminatory remarks.`,
      's_lessons_schedule.rules.no_discriminatory_remarks',
      `请注意无意识的污言秽语。`,
      `請避免無意中發表厭惡言論。`,
      `不用意な嫌悪発言にご注意ください。`,
      `Không đưa ra những nhận xét phân biệt đối xử.`,
    ],
    focus_on_learning: [
      `영어 학습 목적에 충실하기`,
      `Focus on Learning English`,
      's_lessons_schedule.rules.focus_on_learning',
      `秉持英语学习的初衷`,
      `忠於英語學習目的`,
      `英語学習の目的に忠実に`,
      `Tập trung vào việc học tiếng Anh`,
    ],
    avoid_unrelated: [
      `튜터에게 수업과 관련 없는 요청은 삼가 주세요.`,
      `Avoid making requests unrelated to the lesson.`,
      's_lessons_schedule.rules.avoid_unrelated',
      `请避免向教师提出与课程无关的请求。`,
      `請避免向任課教師提出與課程無關的要求。`,
      `チューターへのレッスンに関係のないリクエストはお控えください。`,
      `Tránh đưa ra những yêu cầu không liên quan đến buổi học.`,
    ],
    turn_on_video: [
      `학습 효과를 위해 카메라는 꼭 켜주세요.`,
      `Turn on video for effective communication.`,
      's_lessons_schedule.rules.turn_on_video',
      `为保障学习效果, 请务必开启摄像头。`,
      `為了學習效果，請務必開啟相機。`,
      `学習効果のためにカメラは必ずオンにしてください。`,
      `Bật video để giao tiếp hiệu quả hơn.`,
    ],
    follow_guidelines: [
      `수업 에티켓을 지키지 않으면, 링글 수업이 제한될 수 있습니다.`,
      `If the rules of conduct are not followed, future lessons may be limited.`,
      's_lessons_schedule.rules.follow_guidelines',
      `如果不能遵守相关规定, 您的Ringle学习可能会受到限制。`,
      `未遵守上課禮儀時，Ringle的課程可能受限。`,
      `レッスンエチケットを守らない場合、Ringleレッスンが制限されることがあります。`,
      `Nếu các hướng dẫn dưới đây không được tuân thủ, các buổi học trong tương lai sẽ bị hạn chế.`,
    ],
    check: [
      `잠깐! 수업 에티켓`,
      `Check Our Rules of Conduct!`,
      's_lessons_schedule.rules.check',
      `稍等! 课程规范`,
      `等一下！上課禮儀`,
      `ちょっと待って！レッスンエチケット`,
      `Kiểm tra Quy tắc ứng xử`,
    ],
    done_checking: [
      `확인 완료`,
      `Done checking`,
      's_lessons_schedule.rules.done_checking',
      `已确认`,
      `確認完畢`,
      `確認完了`,
      `Đã kiểm tra xong`,
    ],
    next: [`다음`, `Next`, 's_lessons_schedule.rules.next', `下一步`, `繼續`, `次へ`, `Tiếp theo`],
  },
  failure_reason: [
    `실패사유:`,
    `Reason:`,
    's_lessons_schedule.failure_reason',
    `失败理由：`,
    `失敗理由：`,
    `失敗理由:`,
    `Nguyên nhân không thành công:`,
  ],
  dont_zoom_travelling: [
    `이동하면서 줌에 접속하지 마세요`,
    `Don’t zoom when traveling`,
    's_lessons_schedule.dont_zoom_travelling',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_certificate = {
  number_of_completed_lessons: (lessonCount, purchaseCount) => [
    <>
      (수강한 수업 수: {lessonCount}회/ 구매한 수업 수: {purchaseCount}회)
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    's_certificate.number_of_completed_lessons',
    <>
      (完成课程数 : {lessonCount} 次 / 付费课程数 : {purchaseCount}次)
    </>,
    <>
      (完成課程數 : {lessonCount} 次 / 付費課程數 : {purchaseCount}次)
    </>,
    <>
      (完了した授業数:{lessonCount}回/有料授業数:{purchaseCount}回)
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
  ],
  ringle_english_education_service: [
    `㈜링글잉글리시에듀케이션서비스`,
    `Ringle English Education Service (Ringle Inc.)`,
    's_certificate.ringle_english_education_service',
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
  ],
  select_the_issue_priod: (firstPeriod, secondPeriod) => [
    <>
      {firstPeriod}~{secondPeriod} 내 증빙이 필요하신 기간을 선택해주세요.
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    's_certificate.select_the_issue_priod',
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
  ],
  set_the_beginning_date: [
    `1. 결제일 이후 시작일을 설정할 수 있습니다.`,
    `1. You can set the beginning date after the payment date.`,
    's_certificate.set_the_beginning_date',
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
  ],
  be_at_least_1_won: [
    `1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least ₩1.`,
    's_certificate.be_at_least_1_won',
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
  ],
  be_at_least_1_dollar: [
    `1. 증빙 금액은 최소 1$ 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least $1.`,
    's_certificate.be_at_least_1_dollar',
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
  ],
  one_hundred_thousand_won: [
    `+10만원`,
    `+ ₩100,000`,
    's_certificate.one_hundred_thousand_won',
    `+ ₩100,000`,
    `+ ₩100,000`,
    `+ ₩100,000`,
    `+ ₩100,000`,
  ],
  ten_thousand_won: [
    `+1만원`,
    `+ ₩10,000`,
    's_certificate.ten_thousand_won',
    `+ ₩10,000`,
    `+ ₩10,000`,
    `+ ₩10,000`,
    `+ ₩10,000`,
  ],
  cannot_set_been_issued: [
    `2. 이미 증빙했던 기간 재설정은 불가합니다.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    's_certificate.cannot_set_been_issued',
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
  ],
  issue_certificate_once_lesson: [
    `2. 전체 금액 증빙은 수강증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_lesson ',
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
  ],
  issue_certificate_once_attend: [
    `2. 전체 금액 증빙은 출석증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_attend',
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
  ],
  set_atleast_more_than_two_days: [
    `3. 당일 증빙은 불가하며, 최소 2일 이상 증빙 기간을 설정해야 합니다.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    's_certificate.set_atleast_more_than_two_days',
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
  ],
  fifty_thousand_won: [
    `+5만원`,
    `+ ₩50,000`,
    's_certificate.fifty_thousand_won',
    `+ ₩50,000`,
    `+ ₩50,000`,
    `+ ₩50,000`,
    `+ ₩50,000`,
  ],
  subtotal: [`결제금액`, `Subtotal`, 's_certificate.subtotal', `付费金额`, `付費金額`, `支払金額`, `Subtotal`],
  payment_date_small: [
    `결제일`,
    `Payment date`,
    's_certificate.payment_date_small',
    `付费日期`,
    `付費日期`,
    `支払日`,
    `Payment date`,
  ],
  payment_date_large: [
    `결제일자`,
    `Payment Date`,
    's_certificate.payment_date_large',
    `付费日期`,
    `付費日期`,
    `支払日`,
    `Payment Date`,
  ],
  leave_the_possible_amount_for_the_next_issuance: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 1원으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance',
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
  ],
  leave_the_possible_amount_for_the_next_issuance_dollar: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 $1으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance_dollar',
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
  ],
  provider: [`공급자`, `Co.`, 's_certificate.provider', `Co.`, `Co.`, `Co.`, `Co.`],
  course: [`과정명`, `Course`, 's_certificate.course', `Course`, `Course`, `Course`, `Course`],
  education_service: [
    `교육서비스`,
    `Education Service`,
    's_certificate.education_service',
    `Education Service`,
    `Education Service`,
    `Education Service`,
    `Education Service`,
  ],
  choose_in_the_price_of_purchased_credits: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits',
    `"请选输入不超过购买课程总价的金额
1. 金额应至少$1以上
2. 欲发行金额为购买课程总价时，发行次数请选1`,
    `请选輸入不超过购买課程總價的金額
1. 金额应至少$1以上
2. 欲發行金額為購買課程總價時，發行次數請選1`,
    `"受講料の合計額を超えない金額を選択して入力します。
1. 金額は最低$1以上でなければなりません。
2. 発行希望金額が購入コース総価格の場合、発行回数は1を選択します。`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
  ],
  choose_in_the_price_of_purchased_credits_dollar: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 $1 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits_dollar',
    `"请选输入不超过购买课程总价的金额
1. 金额应至少$1以上
2. 欲发行金额为购买课程总价时，发行次数请选1`,
    `请选輸入不超过购买課程總價的金額
1. 金额应至少$1以上
2. 欲發行金額為購買課程總價時，發行次數請選1`,
    `"受講料の合計額を超えない金額を選択して入力します。
1. 金額は最低$1以上でなければなりません。
2. 発行希望金額が購入コース総価格の場合、発行回数は1を選択します。`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
  ],
  maximum_number_of_issuance_for_the_purchased_credits: [
    `구매한 수업권에 따라 최대 발급 횟수를 선택할 수 있습니다. 최초 설정한 발급 횟수는 변경이 불가하오니 신중히 결정해 주세요. (최대 분할 발급 횟수 = 수강기간/30일)`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    's_certificate.maximum_number_of_issuance_for_the_purchased_credits',
    `您可以根据您的课程时间除以30天发行证明文件。 请仔细选择，因为此设定无法更改。`,
    `您可以根據您的課程時間除以30天發行證明文件。 請仔細選擇，因為此設定無法更改。`,
    `受講時間を30日で割って証明書を発行できます。 この設定は変更できませんので、慎重に選択してください。`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
  ],
  can_choose_the_issued_period: [
    `구매한 수업권의 수강기간 만큼 증빙기간을 선택할 수 있습니다.`,
    `Please select a period up to the length of the lesson period.`,
    's_certificate.can_choose_the_issued_period',
    `请于有效的课程时间内选择日期`,
    `請於有效的課程時間內選擇日期`,
    `有効なコース時間内に日付を選択します`,
    `Please select a period up to the length of the lesson period.`,
  ],
  price: [`금 액`, `Price`, 's_certificate.price', `金额`, `金額`, `金額`, `Price`],
  enter_price: [`금액 입력`, `Enter`, 's_certificate.enter_price', `输入金额`, `輸入金額`, `入力金額`, `Enter`],
  issued_amount_large: [
    `금액 증빙`,
    `Issued amount`,
    's_certificate.issued_amount_large',
    `发行文件所需金额`,
    `發行文件所需金額`,
    `ファイル発行所要金額`,
    `Issued amount`,
  ],
  number_of_remained_lessons: [
    `남은 수업 수 :`,
    `Remaining lessons :`,
    's_certificate.number_of_remained_lessons',
    `剩馀课程次数`,
    `剩餘課程次數`,
    `残りコース数`,
    `Remaining lessons :`,
  ],
  cumulative_attendance: [
    `누적 출석률`,
    `Cumulative`,
    's_certificate.cumulative_attendance',
    `累积出席率`,
    `累積出席率`,
    `累積出席率`,
    `Cumulative`,
  ],
  terms: [`링글 증명서 발급 유의사항`, `Terms`, 's_certificate.terms', `注意事项`, `注意事項`, `注意事項`, `Terms`],
  not_included_in_the_attendance: [
    `마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    's_certificate.not_included_in_the_attendance',
    `免费课程将不计算出席率`,
    `免費課程將不計算出席率`,
    `無料コースは出席率を計算しません`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
  ],
  paid_lessons_or_completed_lessons: [
    `모든 증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 제공됩니다.`,
    `All certificates are provided only for paid and completed lessons.`,
    's_certificate.paid_lessons_or_completed_lessons',
    `只有付费以及完成的课程可以发放证明。`,
    `只有付費以及完成的課程可以發放證明。`,
    `有料や完成した授業は証明書を発行することができます。`,
    `All certificates are provided only for paid and completed lessons.`,
  ],
  preview_and_issue: [
    `미리보기 & 발급하기`,
    `Preview & Issue`,
    's_certificate.preview_and_issue',
    `预览&发行`,
    `預覽&發行`,
    `Preview & Issue`,
    `Preview & Issue`,
  ],
  issue_date_small: [
    `발급 일시`,
    `Issue date`,
    's_certificate.issue_date_small',
    `发行日期`,
    `發行日期`,
    `発行日`,
    `Issue date`,
  ],
  issuance_period_small: [
    `발급 증빙 기간`,
    `Issued period`,
    's_certificate.issuance_period_small',
    `发行期间`,
    `發行期間`,
    `発行期間`,
    `Issuance period`,
  ],
  Issuance_number: [
    `발급 횟수`,
    `Certificate count`,
    's_certificate.Issuance_number',
    `证明数量`,
    `證明數量`,
    `証明数量`,
    `Certificate count`,
  ],
  set_Issuance_number: [
    `발급 횟수 설정`,
    `Set Certificate count`,
    's_certificate.set_Issuance_number',
    `设定数量`,
    `設定數量`,
    `設定数量`,
    `Set Certificate count`,
  ],
  issue_date_large: [
    `발급일자`,
    `Issue Date`,
    's_certificate.issue_date_large',
    `发行日期`,
    `發行日期`,
    `発行日`,
    `Issue Date`,
  ],
  number_of_issuance_large: [
    `발급횟수`,
    `Count`,
    's_certificate.number_of_issuance_large',
    `数量`,
    `數量`,
    `数量`,
    `Count`,
  ],
  business_regristration_number: [
    `사업자 등록번호`,
    `Business Reg No.`,
    's_certificate.business_regristration_number',
    `公司登记号码`,
    `公司登記號碼`,
    `会社登録番号`,
    `Business Reg No.`,
  ],
  business_location: [
    `사업자 소재지`,
    `Business Location`,
    's_certificate.business_location',
    `公司位置`,
    `公司位置`,
    `会社の位置`,
    `Business Location`,
  ],
  name_of_company: [
    `상 호`,
    `Name of company`,
    's_certificate.name_of_company',
    `公司名称`,
    `公司名稱`,
    `会社名`,
    `Name of company`,
  ],
  ringle_address: [
    `서울 서초구 서초대로78길 22 홍우 2빌딩 11층 링글`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_certificate.ringle_address',
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
  ],
  select: [`선택해 주세요.`, `Please select`, 's_certificate.select', `请选择`, `請選擇`, `選択してください`, `Select`],
  from_the_selected_beginning_date: (period) => [
    <>선택하신 시작일로부터 {period}일동안 증빙할 수 있습니다.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
    's_certificate.from_the_selected_beginning_date',
    <>从选择的时间开始可选择 {period}天发行证明.</>,
    <>從選擇的時間開始可選擇 {period}天發行證明.</>,
    <>選択した時刻から{period}日発行証明書を選択できます。</>,
    <>You can issue for {period} days from the selected beginning date.</>,
  ],
  the_issuance_number_attendance_certificate: (maxCount, leftCount) => [
    <Div flex flexRow>
      선택한 발급 횟수는 총{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}회
      </Div>
      이고, 남은 발급 횟수는 <Div fontMedium>&nbsp;{leftCount}회</Div>입니다.
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    's_certificate.the_issuance_number_attendance_certificate',
    <Div flex flexRow>
      总选择次数{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}次
      </Div>
      , 剩馀次数 <Div fontMedium>&nbsp;{leftCount}次</Div>.
    </Div>,
    <Div flex flexRow>
      總選擇次數{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}次
      </Div>
      , 剩餘次數 <Div fontMedium>&nbsp;{leftCount}次</Div>.
    </Div>,
    <Div flex flexRow>
      總選擇次數{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}次
      </Div>
      , 剩餘次數 <Div fontMedium>&nbsp;{leftCount}次</Div>.
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
  ],
  compared_completed_lessons: [
    `선택한 증빙 기간 내 수강한 수업 수 대비 선택한 출석 기준 수업 수를 기준으로 산정됩니다. (증빙 기간 내 출석률=수강한 수업 수/출석 기준 수업수*100)`,
    `Calculated using lessons completed within the selected issue period.`,
    's_certificate.compared_completed_lessons',
    `用所选时间内完成的课程数计算`,
    `用所選時間內完成的課程數計算`,
    `選択した時間内に完了したコース数で計算します。`,
    `Calculated using lessons completed within the selected issue period.`,
  ],
  calculated_based_on_the_total_lessons: [
    `선택한 증빙 기간과 관계없이 수강한 총 수업 수 대비 구매한 총 수업 수를 기준으로 산정됩니다. (누적 출석률=수강한 총 수업 수/구매한 총 수업 수*100)`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    's_certificate.calculated_based_on_the_total_lessons',
    `用所有课程计算，忽略所选时间`,
    `用所有課程計算，忽略所選時間`,
    `選択した時間を無視して、すべてのコースで計算します。`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
  ],
  name_of_representative: [
    `성 명`,
    `Representative`,
    's_certificate.name_of_representative',
    `姓名`,
    `姓名`,
    `氏名`,
    `Representative`,
  ],
  calculated_including_all_completed_lessons: [
    `수강 기간 내 진행한 모든 수업을 포함하여 출석률을 산정합니다.
포함되는 수업권의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등) 
마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    's_certificate.calculated_including_all_completed_lessons',
    `"用所选时间内完成的课程数计算
计算所有付费课程
免费课程将不计算`,
    `用所選時間內完成的課程數計算
計算所有付費課程
免費課程將不計算`,
    `選択した時間内に完了したコース数で計算します。
すべての有料コースを計算します。
無料コースは計算されません`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
  ],
  lessons_arttendance_percentage: [
    `수강 횟수 (출석률)`,
    `Attendance rate`,
    's_certificate.lessons_arttendance_percentage',
    `出席率`,
    `出席率`,
    `出席率`,
    `Attendance rate`,
  ],
  lesson_period: [
    `수강기간`,
    `Lesson Period`,
    's_certificate.lesson_period',
    `上课期间`,
    `上課期間`,
    `授業期間`,
    `Lesson Period`,
  ],
  student: [`수강자`, `Student`, 's_certificate.student', `学生`, `學生`, `学生`, `Student`],
  lesson_certificate: [
    `수강증`,
    `Lesson Certificate`,
    's_certificate.lesson_certificate',
    `上课证明`,
    `上課證明`,
    `授業証明書`,
    `Lesson Certificate`,
  ],
  lesson_certificate_issuance_history: [
    `수강증 발급 내역`,
    `Certificate issuance history`,
    's_certificate.lesson_certificate_issuance_history',
    `上课证明发行纪录`,
    `上課證明發行紀錄`,
    `授業証明書発行記録`,
    `Certificate issuance history`,
  ],
  number_of_installations_for_lesson: [
    `수강증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_lesson',
    `发行次数`,
    `發行次數`,
    `発行回数`,
    `Certificate Count`,
  ],
  issuance_number_will_be_deducted_once_lesson: [
    `수강증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. 동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    's_certificate.issuance_number_will_be_deducted_once_lesson',
    `发行上课证明,发行次数扣除1次,该课程券无法退款。 如果您同意并打印，请按"PDF下载/打印"，否则请按"否"。`,
    `發行上課證明,發行次數扣除1次,該課程券無法退款。 如果您同意並列印，請按"PDF下載/列印"，否則請按"否"。`,
    `授業証明書を発行し、発行回数から1回を差し引いて、該当授業券は払い戻しできません。 同意して印刷する場合は、「PDFダウンロード/印刷」を押します。そうでない場合は、「いいえ」を押します。`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
  ],
  number_of_completed_lessons_without_func: [
    `수강한 수업 수`,
    `Completed Lessons`,
    's_certificate.number_of_completed_lessons_without_func',
    `完成的课程数`,
    `完成的課程數`,
    `完了した授業数`,
    `Completed Lessons`,
  ],
  lessons_count: [`수강횟수`, `Lessons`, 's_certificate.lessons_count', `次`, `次`, `次`, `Lessons`],
  credits: [`수업권 정보`, `Credits`, 's_certificate.credits', `课程券`, `課程券`, `授業券`, `Credits`],
  no: [`아니오`, `No`, 's_certificate.no', `否`, `否`, `No`, `No`],
  select_language: [
    `언어 선택`,
    `Select Language`,
    's_certificate.select_language',
    `选择语言`,
    `選擇語言`,
    `言語選択`,
    `Select Language`,
  ],
  type_of_business: [
    `업 태`,
    `Type of Business`,
    's_certificate.type_of_business',
    `业种`,
    `業種`,
    `業種`,
    `Type of Business`,
  ],
  receipt: [`영수증`, `Receipt`, 's_certificate.receipt', `收据`, `收據`, `領収書`, `Receipt`],
  issue_receipt: [
    `영수증 발급 안내`,
    `Issue Receipt`,
    's_certificate.issue_receipt',
    `发行收据`,
    `發行收據`,
    `領収書発行`,
    `Issue Receipt`,
  ],
  cannot_be_issued_in_installments: [
    `영수증은 카드사를 통해 발급되어 분할 발급은 불가합니다.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    's_certificate.cannot_be_issued_in_installments',
    `收据由发卡公司提供并不可分段发行`,
    `收據由發卡公司提供並不可分段發行`,
    `領収書はカード発行会社が提供するもので、分割発行はできません。`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
  ],
  english: [`영어`, `English`, 's_certificate.english', `英文`, `英文`, `英語`, `English`],
  online_english_education: [
    `온라인 어학교육`,
    `Online English Education`,
    's_certificate.online_english_education',
    `线上英文教育`,
    `線上英文教育`,
    `オンライン英語教育`,
    `Online English Education`,
  ],
  certify_that_the_above_mentioned_fact: [
    `* 본 화면은 미리 보기 용으로 증빙 효력이 없습니다.`,
    `* This screen is a preview and has no effect as a certificate.`,
    's_certificate.certify_that_the_above_mentioned_fact',
    `＊此画面为预览无实际证明之效力`,
    `＊此畫面為預覽無實際證明之效力`,
    `*この画面はプレビューの実証明なしの効力です`,
    `* This screen is a preview and has no effect as a certificate.`,
  ],
  representatives_names: [
    `이성파, 이승훈`,
    `Hoon Lee, Sungpah Lee`,
    's_certificate.representatives_names',
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
  ],
  days: [`일`, `days`, 's_certificate.days', `天`, `天`, `日`, `days`],
  contact_number: [
    `전화번호`,
    `Contact Number`,
    's_certificate.contact_number',
    `电话号码`,
    `電話號碼`,
    `電話番号`,
    `Contact Number`,
  ],
  regular_lessons: [
    `정규수업 수강일`,
    `Regular Lessons`,
    's_certificate.regular_lessons',
    `正规课程`,
    `正規課程`,
    `正規コース`,
    `Regular Lessons`,
  ],
  items_of_nusiness: [
    `종 목`,
    `Items of Business`,
    's_certificate.items_of_nusiness',
    `Items of Business`,
    `Items of Business`,
    `Items of Business`,
    `Items of Business`,
  ],
  issue_certificate: [
    `증명서 발급`,
    `Certificate`,
    's_certificate.issue_certificate',
    `发行证明`,
    `發行證明`,
    `発行証明書`,
    `Certificate`,
  ],
  enter_all_information_required: [
    `증명서 발급에 필요한 모든 정보를 입력해주세요.`,
    `Please enter all information required to issue the certificate.`,
    's_certificate.enter_all_information_required',
    `请填写以下资料以利发行证明`,
    `請填寫以下資料以利發行證明`,
    `証明書を発行しやすいように以下の資料を記入してください。`,
    `Please enter all information required to issue the certificate.`,
  ],
  restricted_from_using_the_function: [
    `수강증/출석증이 발급된 수업권은 수업권 변경 기능 이용이 제한됩니다.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    's_certificate.restricted_from_using_the_function',
    `发行上课证明/出席证明的课程券不可变更。`,
    `發行上課證明/出席證明的課程券不可變更。`,
    `授業証明書/出席証明書を発行する授業券は変更できません。`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
  ],
  certificates_not_be_eligible_for_refunds: [
    `수강증/출석증이 발급된 수업권은 환불 대상에서 제외됩니다.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    's_certificate.certificates_not_be_eligible_for_refunds',
    `发行上课证明/出席证明的课程券不可退款。`,
    `發行上課證明/出席證明的課程券不可退款。`,
    `授業証明書/出席証明書を発行した授業券は払い戻しできません。`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
  ],
  select_the_language: [
    `증명서의 언어를 선택해주세요`,
    `Select the language for the certificate.`,
    's_certificate.select_the_language',
    `选取证明需要的语言`,
    `選取證明需要的語言`,
    `証明に必要な言語を選択します。`,
    `Select the language for the certificate.`,
  ],
  maximum_of_issued_amount: [
    `증빙 가능 금액 :`,
    `Maximum of Issued Amount :`,
    's_certificate.maximum_of_issued_amount',
    `最多发行数量`,
    `最多發行數量`,
    `最大発行部数`,
    `Maximum of Issued Amount :`,
  ],
  select_the_issuance_period_again: [
    `증빙 가능한 기간을 초과하여 선택하셨습니다. 증빙 기간을 다시 선택해주세요.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    's_certificate.select_the_issuance_period_again',
    `已超过可以申请的时间，请重新选择`,
    `已超過可以申請的時間，請重新選擇`,
    `申請可能期間が過ぎました。もう一度選択してください`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
  ],
  you_cannot_issue_on_the_day: [
    `증빙 가능한 기간이 아닙니다. 증빙 기간을 다시 선택해주세요. 1회 발급 시 최소 증빙 기간은 2일이며, 당일 증빙은 불가합니다.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    's_certificate.you_cannot_issue_on_the_day',
    `无效的日期，请重新选择，一次最少发行的日期为两天，当天不可发行`,
    `無效的日期，請重新選擇，一次最少發行的日期為兩天，當天不可發行`,
    `無効な日付、再選択してください。一度に最低発行日は2日で、当日発行不可です。`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
  ],
  issued_amount: [
    `증빙 금액`,
    <>Issued amount</>,
    's_certificate.issued_amount',
    <>发行数量</>,
    <>發行數量</>,
    <>発行数量</>,
    <>Issued amount</>,
  ],
  issuance_period: [
    `증빙 기간`,
    <>Issued Period</>,
    's_certificate.issuance_period',
    <>发行日期</>,
    <>發行日期</>,
    <>発行日</>,
    <>Issued Period</>,
  ],
  attendance_percentage_during_the_issue_period: [
    `증빙 기간 내 출석률`,
    `During the issue period`,
    's_certificate.attendance_percentage_during_the_issue_period',
    `发行时间的出席率`,
    `發行時間的出席率`,
    `発行時間出席率`,
    `During the issue period`,
  ],
  include_all_completed_lessons: [
    `진행한 수업 모두 포함하기 (e.g.당일수업권, 보상수업권, 보너스 수업권 등)`,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    's_certificate.include_all_completed_lessons',
    <>包含所有已完成的课程</>,
    <>包含所有已完成的課程</>,
    <> 完了したすべてのコースが含まれています</>,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
  ],
  extra_lessons: [
    `추가 수업 수강일`,
    `Extra Lessons`,
    's_certificate.extra_lessons',
    `Extra Lessons`,
    `Extra Lessons`,
    `Extra Lessons`,
    `Extra Lessons`,
  ],
  attendance_standard_of_lessons: [
    `출석 기준 수업 수`,
    `Eligible Lessons`,
    's_certificate.attendance_standard_of_lessons',
    `可列入出席率计算的课程`,
    `可列入出席率計算的課程`,
    `出席率計算可能コース`,
    `Eligible Lessons`,
  ],
  lessons_cannot_be_entered_zero: [
    <>
      출석 기준 수업 수는 [남은 증빙 가능 수업 수~0] 회 이상으로 입력해야 합니다. <br />
      출석 기준 수업 수는 0회는 입력 불가합니다.
    </>,
    `Must be entered at least over [Number of classes that can be verified~0].`,
    's_certificate.lessons_cannot_be_entered_zero',
    `请输入至少获超过 [可以被计算的课程数~0].`,
    `請輸入至少獲超過 [可以被計算的課程數~0].`,
    `少なくとも[計算可能な授業数~0]を入力してください。`,
    `Must be entered at least over [Number of classes that can be verified~0].`,
  ],
  attendance_percentage: [
    `출석률`,
    `Attendance Rate`,
    's_certificate.attendance_percentage',
    `出席率`,
    `出席率`,
    `出席率`,
    `Attendance Rate`,
  ],
  standard_number_of_lessons: [
    `출석률 산정 시 기준이 되는 수업 수 입니다. 수강생이 직접 입력 가능하며, 최대 입력 가능한 수업 수는 구매한 수업 횟수입니다. (출석률=수강 수업 수/출석 기준 수업 수) <br/> 마지막 회차 증빙은 남은 출석기준 수업 수가 자동으로 산정되며, 이는 변경이 불가합니다.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    's_certificate.standard_number_of_lessons',
    <>
      计算出席率时以课程数为基准。 学员可以直接输入希望的课程数，最多可输入的课程数爲总购买的课程数。
      （出席率=出席数/总课程数）
      <br />
      最后一次发行的出席证明为以剩下的课程数来计算，无法更改。
    </>,
    <>
      計算出席率時以課程數為基準。 學員可以直接輸入希望的課程數，最多可輸入的課程數爲總購買的課程數。
      （出席率=出席數/總課程數）
      <br />
      最後一次發行的出席證明為以剩下的課程數來計算，無法更改。
    </>,
    <>
      出席率は授業数を基準に計算されます。
      受講生は希望するコース数を直接入力することができ、最大入力可能なコース数は総購入したコース数です。
      （出席率=出席数/総授業数）
      <br />
      最後に発行された出席証明書は残りの授業数で計算するため変更できません。
    </>,
    <>
      This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible
      lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br />{' '}
      The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot
      be reversed.
    </>,
  ],
  attendance_certificate: [
    `출석증`,
    `Attendance Certificate`,
    's_certificate.attendance_certificate',
    `出席证明`,
    `出席證明`,
    `出席証明書`,
    `Attendance Certificate`,
  ],
  attendance_certificate_issuance_history: [
    `출석증 발급 내역`,
    `History`,
    's_certificate.attendance_certificate_issuance_history',
    `发行纪录`,
    `發行紀錄`,
    `発行記録`,
    `History`,
  ],
  select_attendance_percentage: [
    `출석증 발급 시, 표기될 출석률을 선택해주세요`,
    `Select the rate you'd like to show on the certificate.`,
    's_certificate.select_attendance_percentage',
    `请选择想要的出席率`,
    `請選擇想要的出席率`,
    `ご希望の出席率をお願いします`,
    `Select the rate you'd like to show on the certificate.`,
  ],
  number_of_installations_for_attendance: [
    `출석증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_attendance',
    `发行次数`,
    `發行次數`,
    `発行回数`,
    `Certificate Count`,
  ],
  issuance_number_will_be_deducted_once_lesson_attendance: [
    <>
      출석증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. <br />
      동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    's_certificate.issuance_number_will_be_deducted_once_lesson_attendance',
    `发行上课证明,发行次数扣除1次,该课程券无法退款。 如果您同意并打印，请按"PDF下载/打印"，否则请按"否"。`,
    `發行上課證明,發行次數扣除1次,該課程券無法退款。 如果您同意並列印，請按"PDF下載/列印"，否則請按"否"。`,
    `授業証明書を発行し、発行回数から1回を差し引いて、該当授業券は払い戻しできません。 同意して印刷する場合は、「PDFダウンロード/印刷」を押します。そうでない場合は、「いいえ」を押します。`,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
  ],
  cash_receipts_kakao_naver: [
    `카카오페이/네이버페이 결제 시 현금영수증은 해당 플랫폼에서 신청 가능합니다.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    's_certificate.cash_receipts_kakao_naver',
    `使用KAKAO PAY/NAVER PAY时,可以在平臺上申请现金收据。`,
    `使用KAKAO PAY/NAVER PAY時,可以在平臺上申請現金收據。`,
    `カカオペイ/ネイバーペイをご利用の際、プラットフォームで現金領収証の申請が可能です。`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
  ],
  types_of_classes_included: [
    `포함되는 수업의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    's_certificate.types_of_classes_included',
    `包含的课程券:付费课程券（当日授课券、赠送的授课券、补偿课程券等）`,
    `包含的課程券:付費課程券（當日授課券、贈送的授課券、補償課程券等）`,
    `授業券:有料授業券(当日授業券、贈与した授業券、補償課程券)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
  ],
  korean: [`한글`, `Korean`, 's_certificate.korean', `韩文`, `韓文`, `韓国語`, `Korean`],
  you_cannot_change_in_future: [
    `현재 설정한 발급 횟수로 [수강증/출석증] 발급을 완료하면 차후 변경이 불가하오니 신중히 결정해주세요.`,
    `Please choose carefully as this setting cannot be reversed.`,
    's_certificate.you_cannot_change_in_future',
    `请小心选择之后将无法更改`,
    `請小心選擇之後將無法更改`,
    `慎重に選択すると変更できません`,
    `Please choose carefully as this setting cannot be reversed.`,
  ],
  confirm: [`확인`, `Confirm`, 's_certificate.confirm', `确认`, `確認`, `確認`, `Confirm`],
  classes: [`회`, ``, 's_certificate.classes', `次`, `次`, `回`, ``],
  paypal_kakao_payment_receipts: [
    `Paypal/카카오페이 결제 영수증은 사용하시는 PayPal/카카오페이 계정을 통해 확인 가능합니다.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    's_certificate.paypal_kakao_payment_receipts',
    `使用PayPal/Kakaopay付款者可于该平台取得收据。`,
    `使用PayPal/Kakaopay付款者可於該平台取得收據。`,
    `PayPal/カカオペイをご利用の方は、このプラットフォームでレシートをお受け取りいただけます。`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
  ],
  can_be_viewed_from_website: [
    `Paypal/카카오페이 결제 영수증은 Paypal/ 카카오페이에서 직접 확인 가능합니다.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    's_certificate.can_be_viewed_from_website',
    `使用PayPal/Kakaopay付款者可于该平台取得收据。`,
    `使用PayPal/Kakaopay付款者可於該平台取得收據。`,
    `PayPal/カカオペイをご利用の方は、このプラットフォームでレシートをお受け取りいただけます。`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
  ],
  download_print_pdf: [
    `PDF 다운로드 / 출력`,
    `Download / Print PDF`,
    's_certificate.download_print_pdf',
    `下载/ 打印 PDF`,
    `下載/ 列印 PDF`,
    `PDF をダウンロード/印刷します`,
    `Download / Print PDF`,
  ],
  lessons: [`수업횟수`, `Lessons`, 's_certificate.lessons', `课程数`, `課程數`, `コース数`, `Lessons`],
  payment_receipt_first: [
    `첫번째 영수증`,
    `Payment Receipt 1`,
    's_certificate.payment_receipt_first',
    `收据 1`,
    `收據 1`,
    `レシート 1`,
    `Payment Receipt 1`,
  ],
  payment_receipt_second: [
    `두번째 영수증`,
    `Payment Receipt 2`,
    's_certificate.payment_receipt_second',
    `收据 2`,
    `收據 2`,
    `レシート2`,
    `Payment Receipt 2`,
  ],
  cumulative_lessons: [
    `누적 수강횟수`,
    `Cumulative Lessons`,
    's_certificate.cumulative_lessons',
    `累积课程数`,
    `累積課程數`,
    `累積コース数`,
    `Cumulative Lessons`,
  ],
  cumulative_attendance_percentage: [
    `누적 출석률`,
    `Cumulative Percentage`,
    's_certificate.cumulative_attendance_percentage',
    `累积出席率`,
    `累積出席率`,
    `累積出席率`,
    `Cumulative Percentage`,
  ],
  dropdown_certificates: [`회`, `certificate`, 's_certificate.dropdown_certificates', `次`, `次`, `次`, `certificate`],
  notification: [`알림`, `Notification`, 's_certificate.notification', `通知`, `通知`, `お知らせ`, `Notification`],
  select_period_tooltip: [
    `구매한 수업권의 수강기간 만큼 증빙기간을 선택할 수 있습니다. 1. 결제일 이후 시작일을 설정할 수 있습니다. 2. 이미 증빙했던 기간 재설정은 불가합니다. 3. 당일 증빙은 불가하며, 최소 2일 이상 증빙 기간을 설정해야 합니다.`,
    `You can choose the issue period as much as the lesson period 1. You can set the beginning date after the payment date. 2. You cannot set the period that has already been issued. 3. You cannot issue on the day and you must set at least more than 2 days.`,
    's_certificate.select_period_tooltip',
    `可以根据购买的课程券的使用期限选择证明期限。 1. 付款日期之后可以设置起始日期。 2. 已发行证明的期限不能再次设定。 3. 当日课程无法发行证明，证明发行时间不得少于2天。`,
    `可以根據購買的課程券的使用期限選擇證明期限。 1. 付款日期之後可以設置起始日期。 2. 已發行證明的期限不能再次設定。 3. 當日課程無法發行證明，證明發行時間不得少於2天。`,
    `購入した受講券の使用期限に合わせて証明期間を選択できます。 1. 支払日の後に開始日を設定できます。 2. 発行済み証明書の期限は再設定できません。 3. 当日の授業は証明書の発行が不可能で、証明書の発行期間は2日以上でなければなりません。`,
    ``,
  ],
  enter_full_name: [
    `증명서 출력 시 [수강자명]이 비어 있는 경우 기본 정보 항목에 국문 이름을 입력해 주세요.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    's_certificate.enter_full_name',
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
  ],
  set_full_name: [
    `수강증/출석증 내 표기되는 수강자명은 계정관리에서 설정할 수 있습니다. [한글 이름 수정] / [영문 이름 수정]`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    's_certificate.set_full_name',
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
  ],
  name: [
    `수강생 정보`,
    `Student Information`,
    's_certificate.name',
    `学生资料`,
    `學生資料`,
    `学生資料`,
    `Student Information`,
  ],
  name_notice: [
    `이름이 정확하게 입력되어 있는지 확인해 주세요.`,
    `Please enter your name`,
    's_certificate.name_notice',
    `请输入姓名`,
    `請輸入姓名`,
    `氏名を記入してください`,
    `Please enter your name`,
  ],
  name_korean: [`한글 이름`, ``, 's_certificate.name_korean', ``, ``, ``, ``],
  name_english: [
    `영어 이름`,
    `English Name`,
    's_certificate.name_english',
    `英文名`,
    `英文名`,
    `英語名`,
    `English Name`,
  ],
  name_korean_placeholder: [`한글 이름을 입력해 주세요.`, ``, 's_certificate.name_korean_placeholder', ``, ``, ``, ``],
  name_english_placeholder_1: [
    `First Name`,
    `First Name`,
    's_certificate.name_english_placeholder_1',
    `名字`,
    `名字`,
    `名前`,
    `First Name`,
  ],
  name_english_placeholder_2: [
    `Last Name`,
    `Last Name`,
    's_certificate.name_english_placeholder_2',
    `姓氏`,
    `姓氏`,
    `姓`,
    `Last Name`,
  ],
  preview: [`미리보기`, `Preview`, 's_certificate.preview', `Preview`, `Preview`, `Preview`, `Preview`],
  fact_cerify: [
    `위의 수강 사실을 증명합니다.`,
    `This is to certify that the above mentioned fact is true.`,
    's_certificate.fact_cerify',
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
  ],
  maximum_number_of_issuance_everyweek: [
    `구매한 수업권에 따라 최대 발급 횟수를 선택할 수 있습니다. 최초 설정한 발급 횟수는 변경이 불가하오니 신중히 결정해 주세요. (최대 분할 발급 횟수 = 수강기간/28일)`,
    `You can issue as many certificates as your lesson period divided by 28 days. Please choose carefully as this setting cannot be reversed.`,
    's_certificate.maximum_number_of_issuance_everyweek',
    `You can issue as many certificates as your lesson period divided by 28 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 28 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 28 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 28 days. Please choose carefully as this setting cannot be reversed.`,
  ],
}
export const s_subscribe = {
  message: {
    success: [
      `구독을 축하드립니다!`,
      `Congratulations, you have successfully subscribed!`,
      's_subscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    already_subscribed: [
      `이미 구독하고 계신 이메일 입니다. 스팸함을 확인해주세요.`,
      `You have already subscribed with this email. Please check your spam.`,
      's_subscribe.message.already_subscribed',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while subscribing to the newsletter. Please contact the administrator.`,
      's_subscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
    invalidName: [`이름을 적어주세요.`, `Please input your name.`, 's_subscribe.message.invalidName', ``, ``, ``, ``],
    invalidEmail: [
      `잘못된 이메일 형식입니다.`,
      `Not a valid email address`,
      's_subscribe.message.invalidEmail',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    name_input: [`이름`, `Name`, 's_subscribe.body_header.name_input', ``, ``, ``, ``],
    email_input: [`이메일`, `Email`, 's_subscribe.body_header.email_input', ``, ``, ``, ``],
    header_button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.header_button',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `매주 목요일, 금주의 가장 인기 있는 링글 콘텐츠를 받아보세요.`,
      `Subscribe to get Ringle's hottest contents every Thursday.`,
      's_subscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.button',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  success_header: {
    title: [
      `Content Weekly 구독 완료되었습니다!`,
      `You have successfully subscribed to the newsletter!`,
      's_subscribe.success_header.title',
      `您已经成功订阅了新闻简报!`,
      `您已經成功訂閱了新聞簡報!`,
      `メールマガジンの登録が完了しました`,
      `Bạn đã đăng ký thành công newsletter!`,
    ],
    subtitle: [
      `매주 금요일, 금주의 가장 인기 있는 링글 콘텐츠를 보내드릴게요.`,
      `Every Friday, you will get Ringle's hottest content.`,
      's_subscribe.success_header.subtitle',
      `可在每周五收到Ringle推荐的内容邮件。`,
      `每週五可透過電子信箱接收Ringle推薦內容。`,
      `毎週金曜日にRingleのお勧めコンテンツをeメールで受け取ることができます。`,
      `Gửi nội dung mới của Ringle vào email thứ 6 mỗi tuần`,
    ],
    button: [
      `링글 컨텐츠 둘러보기`,
      `Check out Ringle Content`,
      's_subscribe.success_header.button',
      `Check out Ringle Content`,
      `Check out Ringle Content`,
      `Check out Ringle Content`,
      `Check out Ringle Content`,
    ],
    email_change_button: [
      `이메일 주소 변경하기`,
      `Change email address`,
      's_subscribe.success_header.email_change_button',
      `Change email address`,
      `Change email address`,
      `Change email`,
      `Change email address`,
    ],
    email_subscribed: [
      `발송되는 이메일 주소:`,
      `delivered to:`,
      's_subscribe.success_header.email_subscribed',
      `delivered to:`,
      `delivered to:`,
      `delivered to:`,
      `delivered to:`,
    ],
    content1: [
      `발송되는 이메일 주소:`,
      `delivered to:`,
      's_subscribe.success_header.content1',
      `delivered to:`,
      `delivered to:`,
      `delivered to:`,
      `delivered to:`,
    ],
    content2: [
      `메일을 받지 못한 경우 스팸함이나 프로모션 카테고리를 확인해주세요.`,
      `Check out this week's Content Weekly`,
      's_subscribe.success_header.content2',
      `Please check your spam box.`,
      `Please check your spam box.`,
      `Please check your spam box.`,
      `Please check your spam box.`,
    ],
  },
  body: {
    example: [
      `이번주 Content Weekly 보기`,
      `Check out this week's Content Weekly`,
      's_subscribe.body.example',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subs_btn: [
    `Content Weekly 구독하기`,
    `Subscribe to Content Weekly`,
    's_subscribe.subs_btn',
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
  ],
  failure_modal: {
    title: [
      `이미 구독중인 이메일입니다.`,
      `Your email is already on the subscription list.`,
      's_subscribe.failure_modal.title',
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
    ],
    close: [`확인`, `Close`, 's_subscribe.failure_modal.close', `Close`, `Close`, `Close`, `Close`],
  },
}
export const s_unsubscribe = {
  message: {
    success: [
      `답변을 제출하셨습니다. 감사합니다.`,
      `Your response has been submitted. Thank you.`,
      's_unsubscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    submit_failure: [
      `답변 제출중 문제가 발생하셨습니다.`,
      `There was a problem while submitting your answers.`,
      's_unsubscribe.message.submit_failure',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독 취소중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while unsubscribing to the newsletter. Please contact the administrator.`,
      's_unsubscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    title: [
      `그동안 콘텐츠 뉴스레터를 구독해 주셔서 정말 감사합니다.`,
      `Thank you for your time as our subscriber.`,
      's_unsubscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `구독을 취소한 이유를 알려주세요. (복수 선택 가능)`,
      `Please tell us why you decided to unsubscribe.`,
      's_unsubscribe.body_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  reasons: {
    not_interested: [
      `영어 공부에 관심이 없어졌습니다.`,
      `I lost interest in studying English.`,
      's_unsubscribe.reasons.not_interested',
      ``,
      ``,
      ``,
      ``,
    ],
    contents_not_good: [
      `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
      `Ringle Contents did not help me in learning English or getting insights on topics.`,
      's_unsubscribe.reasons.contents_not_good',
      ``,
      ``,
      ``,
      ``,
    ],
    no_time: [
      `시간이나 비용을 들이고 싶지 않습니다.`,
      `I do not have time for the newsletters.`,
      's_unsubscribe.reasons.no_time',
      ``,
      ``,
      ``,
      ``,
    ],
    other_contents: [
      `다른 좋은 콘텐츠를 찾았습니다.`,
      `Found other helpful contents.`,
      's_unsubscribe.reasons.other_contents',
      ``,
      ``,
      ``,
      ``,
    ],
    other: [`기타`, `Other`, 's_unsubscribe.reasons.other', ``, ``, ``, ``],
    specify: [
      `좀 더 자세히 알려주세요!`,
      `Please tell us what it is!`,
      's_unsubscribe.reasons.specify',
      ``,
      ``,
      ``,
      ``,
    ],
    tell_us: [`무엇인지 알려주세요!`, `Tell us what it is!`, 's_unsubscribe.reasons.tell_us', ``, ``, ``, ``],
    no_email: [
      `링크에 문제가 있습니다. 이메일에 있는 링크를 사용해 주세요.`,
      `This is a corrupted link. Please use the link in the email.`,
      's_unsubscribe.reasons.no_email',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  button: [`제출하기`, `Submit`, 's_unsubscribe.button', ``, ``, ``, ``],
  disabled_button: [`제출 완료`, `Submitted`, 's_unsubscribe.disabled_button', ``, ``, ``, ``],
  resubscribe: [`다시 구독하기`, `Subscribe again`, 's_unsubscribe.resubscribe', ``, ``, ``, ``],
}
export const s_content = {
  mobile_web: {
    access_on_app: [
      `앱을 다운 받고, 링글의 컨텐츠와 수업을 경험해 보세요.`,
      `Access all of Ringle's content for free
on Ringle App`,
      's_content.mobile_web.access_on_app',
      `下载APP观看更多免费内容`,
      `下載APP觀看更多免費内容`,
      `アプリをインストールして、無料でRingleのコンテンツを体験してみましょう。`,
      `Miễn phí tất cả nội dung trên ứng dụng Ringle`,
    ],
    view_in_app: [
      `앱으로 보기`,
      `Open app`,
      's_content.mobile_web.view_in_app',
      `使用APP观看`,
      `使用APP觀看`,
      `アプリで見る`,
      `Mở ứng dụng`,
    ],
    no_thanks: [
      `괜찮습니다, 모바일 웹으로 볼게요.`,
      `No thanks, I want to stay on this page.`,
      's_content.mobile_web.no_thanks',
      `使用Mobile Web观看`,
      `使用Mobile Web觀看`,
      `いいえ、モバイルアプリで見ます。`,
      `Tôi muốn ở lại trang này, cảm ơn.`,
    ],
    continue_on_app: [
      `앱에서 이어보기`,
      `Continue on app`,
      's_content.mobile_web.continue_on_app',
      `在APP上观看`,
      `在APP上觀看`,
      `アプリで続けて見る`,
      `Tiếp tục trên ứng dụng`,
    ],
    finished_preview: [
      `1분 미리듣기가 완료되었습니다.`,
      `You finished the 1-minute preview.`,
      's_content.mobile_web.finished_preview',
      `1分钟试听已结束`,
      `1分鐘試聽以結束`,
      `1分のプレビューが完了しました。`,
      `Bạn hoàn thành 1 phút ôn lại.`,
    ],
    RSVP_on_app: [
      `앱에서 웨비나 신청하기`,
      `RSVP on App`,
      's_content.mobile_web.RSVP_on_app',
      `在APP上申请网络研讨会`,
      `在APP上申請網路研討會`,
      `アプリでウェビナーを申請する`,
      `Giữ chỗ trên ứng dụng`,
    ],
    sign_in_to_view_webinar_clips: [
      `웨비나의 다른 클립은 로그인 후에 시청 가능합니다.`,
      `Please log in to view other webinar clips.`,
      's_content.mobile_web.sign_in_to_view_webinar_clips',
      `登录后可观看更多网络研讨会`,
      `登入后觀看更多網路研討會`,
      `ウェビナーの他のクリップはログインをした後に申請可能です。`,
      `Vui lòng đăng nhập để xem các video webinar.`,
    ],
    please_note: [`안내`, `Please note`, 's_content.mobile_web.please_note', `说明`, `説明`, `ご案内`, `Lưu ý`],
    can_access_after_sign_in: [
      `로그인 후 링글 앱과 PC 웹에서만 제공되는 기능입니다.`,
      `This feature can be accessed after signing in on Ringle website or App.`,
      's_content.mobile_web.can_access_after_sign_in',
      `只在APP和电脑上提供的功能`,
      `只在APP和電腦上提供的功能`,
      `ログイン後にRingleのアプリとPCアプリだけに提供される機能です。`,
      `Nội dung này có thể truy cập khi đăng nhập vào website hoặc ứng dụng.`,
    ],
    cancel: [`취소`, `Cancel`, 's_content.mobile_web.cancel', `取消`, `取消`, `取り消し`, `Hủy`],
    open_app: [
      `앱으로 이동`,
      `Open app`,
      's_content.mobile_web.open_app',
      `移动至APP`,
      `移動至APP`,
      `アプリに移動`,
      `Mở ứng dụng`,
    ],
  },
  web: {
    sign_in_to_access: [
      `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
      `Log in to access all of Ringle's content for free`,
      's_content.web.sign_in_to_access',
      `登录后查看更多免费内容`,
      `登入后查看更多免費内容`,
      `ログインをして無料でRingleのコンテンツを体験してみてください。`,
      `Đăng nhập để xem toàn bộ nội dung Ringle miễn phí`,
    ],
    sign_in_to_continue: [
      `로그인하고 이어보기`,
      `Log in to continue`,
      's_content.web.sign_in_to_continue',
      `登录后继续观看`,
      `登入后繼續觀看`,
      `ログインをして続けてみる`,
      `Đăng nhập để tiếp tục`,
    ],
    sign_in_to_RSVP: [
      `로그인 후 신청하기`,
      `Log in to RSVP`,
      's_content.web.sign_in_to_RSVP',
      `登录后申请`,
      `登入后申請`,
      `ログイン後に申請する`,
      `Đăng nhập để giữ chỗ`,
    ],
    sign_in_to_download_MP3: [
      <>
        MP3파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the MP3.`,
      's_content.web.sign_in_to_download_MP3',
      `MP3下载需登录后进行。是否登录后继续下载？`,
      `MP3下載需登入后進行。是否登入后繼續下載？`,
      `MP3ファイルのダウンロード機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải file MP3.`,
    ],
    sign_in_to_download_PDF: [
      <>
        PDF파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the PDF.`,
      's_content.web.sign_in_to_download_PDF',
      `PDF下载需登录后进行。是否登录后继续下载？`,
      `PDF下載需登入后進行。是否登入后繼續下載？`,
      `PDFファイルのダウンロード機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải PDF.`,
    ],
    save: [`찜하기`, `Save`, 's_content.web.save', `收藏`, `收藏`, `お気に入りにする`, `Lưu`],
    sign_in_to_save: [
      <>
        찜하기 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to save.`,
      's_content.web.sign_in_to_save',
      `登录后可使用收藏功能。是否登录？`,
      `登入后可使用收藏功能。是否登入？`,
      `お気に入り機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để lưu lại.`,
    ],
    download_materials: [
      `자료 받기`,
      `Download materials`,
      's_content.web.download_materials',
      `获取资料`,
      `獲取資料`,
      `材料を貰う`,
      `Tải tài liệu`,
    ],
    sign_in_to_download_materials: [
      `자료 받기 기능은 로그인 후에 제공되는 기능입니다. 로그인하고 계속 진행하시겠습니까?`,
      `Please sign in to download webinar materials.`,
      's_content.web.sign_in_to_download_materials',
      `登录后可使用获取资料功能。是否登录？`,
      `登入后可使用獲取資料功能。是否登入？`,
      `材料を貰う機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải tài liệu webinar.`,
    ],
    sign_in: [`로그인`, `Log in`, 's_content.web.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    sign_up: [`회원가입 하기`, `Sign up`, 's_content.web.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  },
  do_you_like: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like',
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
  ],
  related_material_read: [
    `이 Daily Brief를 공유하거나 관련 교재를 읽어보세요.`,
    `Share it with your friends or view related materials.`,
    's_content.related_material_read',
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
  ],
  sharing: [`공유하기`, `Share`, 's_content.sharing', `Share`, `Share`, `Share`, `Share`],
  related_material: [
    `관련 교재 읽기`,
    `View Insights`,
    's_content.related_material',
    `View Insights`,
    `View Insights`,
    `View Insights`,
    `View Insights`,
  ],
  do_you_like_daily_brief: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like_daily_brief',
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
  ],
  kakakotalk: [
    `카카오톡`,
    `Kakao Talk`,
    's_content.kakakotalk',
    `Kakao Talk`,
    `Kakao Talk`,
    `Kakao Talk`,
    `Kakao Talk`,
  ],
  facebook: [`페이스북`, `Facebook`, 's_content.facebook', `Facebook`, `Facebook`, `Facebook`, `Facebook`],
  url_copy: [`URL복사`, `URL Copy`, 's_content.url_copy', `URL Copy`, `URL Copy`, `URL Copy`, `URL Copy`],
  link_copied: [
    `링크를 복사했어요.`,
    `Link is copied to your clipboard.`,
    's_content.link_copied',
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
  ],
}
export const s_transfer_credit = {
  title: [
    `틴즈로 수업권 이관`,
    `Transfer Lesson Credits to Ringle Teens`,
    's_transfer_credit.title',
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
  ],
  terms: [`유의사항`, `Terms`, 's_transfer_credit.terms', `Terms`, `Terms`, `Terms`, `Terms`],
  select_package: [
    `이관할 패키지를 선택해주세요.`,
    `Select a package to transfer`,
    's_transfer_credit.select_package',
    `Select a package to transfer`,
    `Select a package to transfer`,
    `Select a package to transfer`,
    `Select a package to transfer`,
  ],
  select_number: [
    `이관할 수업권 개수를 선택해주세요.`,
    `Select number of credits`,
    's_transfer_credit.select_number',
    `Select number of credits`,
    `Select number of credits`,
    `Select number of credits`,
    `Select number of credits`,
  ],
  check_credits: [
    `이관 수업권 확인`,
    `Check the transferred credits`,
    's_transfer_credit.check_credits',
    `Check the transferred credits`,
    `Check the transferred credits`,
    `Check the transferred credits`,
    `Check the transferred credits`,
  ],
  plus: [`플러스`, `Plus`, 's_transfer_credit.plus', `Plus`, `Plus`, `Plus`, `Plus`],
  teens: [`틴즈`, `Teens`, 's_transfer_credit.teens', ``, ``, ``, ``],
  view_terms: [
    `틴즈 이용 약관 및 개인정보 처리방침 보기`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    's_transfer_credit.view_terms',
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
  ],
  terms_title: [`수업권 변경 유의 사항`, `Terms`, 's_transfer_credit.terms_title', `Terms`, `Terms`, `Terms`, `Terms`],
  terms_agree: [
    `유의 사항, 틴즈 이용약관 및 개인정보 처리방침을 숙지 및 동의하였고, 이관을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    's_transfer_credit.terms_agree',
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
  ],
  transfer: [`이관 진행`, `Transfer`, 's_transfer_credit.transfer', `Transfer`, `Transfer`, `Transfer`, `Transfer`],
  terms_detail: [
    <>
      수업권 이관은 수업 패키지 별 1회만 가능합니다. 이미 이관된 수업권은 원상복구할 수 없으니 신중히 선택하시기
      바랍니다!
      <br />
      수업권 이관 후, 적립되는 수업 포인트는 기존 구매한 수업권의 회당 금액의 3%로 적립됩니다. (수업권 이관 이후,
      제공되는 총 포인트는 동일함) 이관 후 링글 틴즈 내에서 사용하신 수업권에 대한 포인트는 링글에서 사용 가능합니다.
      <br />
      정규수업권 4개 소진 시, 1회 당일수업권이 제공되는 수업권은 4개 이관할 때마다 1회 당일 수업권이 링글 계정으로
      지급됩니다.
    </>,
    <>
      IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and used within Ringle.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle account.{' '}
    </>,
    's_transfer_credit.terms_detail',
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
  ],
  terms_1: [
    `링글 틴즈 수업 운영시간: 7pm~다음날 1pm KST (3:00am ~ 9:00pm PST, 6:00am ~ 12:00am EST)`,
    `Please note that Ringle Teens operating hours are: 12pm~11pm EST (7pm~1pm KST, 3:00am~9:00pm PST)`,
    's_transfer_credit.terms_1',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_2: [
    `기존 링글 고객이 이관을 함으로써 링글 틴즈 계정이 자동으로 생성되며, 해당 링글 틴즈 계정으로 신청하신 수량의 수업권이 이관됩니다.`,
    `A Ringle Teens account is automatically generated once you transfer credits. The transferred lesson credits can be found in your Ringle Teens account.`,
    's_transfer_credit.terms_2',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_3: [
    `수업권 이관은 보유하신 패키지 당 1번씩 가능하며, 2022년 12월 31일까지 링글 정규 수업권에 한해 수업권 1매를 틴즈 수업권 1매로 이관할 수 있습니다.`,
    `Until December 31st, 2022, customers are eligible to transfer Ringle lesson credits for an equivalent amount of Ringle Teens credits. You are only able to conduct one transfer per credit package purchased.`,
    's_transfer_credit.terms_3',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_4: [
    `이관된 수업권의 잔여 수강 기간은 링글 틴즈에서 동일하게 적용됩니다.`,
    `Transferring lesson credits will not affect the expiry date.`,
    's_transfer_credit.terms_4',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_5: [
    `수업권 10개 이상 이관 시 40분 수업권을 추가로 지급드리며, 이관 기간 동안 수업권 1개 이상 이관하신 모든 분들 중 추첨을 통해 20가정에게 링글 스타트업 탐방 기회를 제공합니다. 혜택 당첨자에게는 이메일로 개별 안내드릴 예정입니다.`,
    `Customers who may receive the benefits of our promotion will be contacted via email.`,
    's_transfer_credit.terms_5',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_6: [
    `링글에서 장기 수업권 혜택으로 "정규 수업 4회 소진 시마다, 당일 수업권 1회 발급 혜택"을 받고 계신 경우, 장기 패키지 내 4회 수업권을 이관할 때마다 1회의 당일 수업권이 링글 계정으로 지급됩니다.`,
    `For the long-term package that provides a last-minute lesson credit(Make-up Credit) for every four regular lesson credits used, customers receive the following: For every four regular lesson credits transferred to Ringle Teens from the package, receive a last-minute lesson credit on their Ringle account.`,
    's_transfer_credit.terms_6',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_7: [
    `이관한 수업권은 환불이 불가하며, 이관한 수업권은 링글 내 원상 복구 불가합니다.`,
    `No refund is available for transferred credits and transferred credits can not be transferred back to Ringle Plus.`,
    's_transfer_credit.terms_7',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_8: [
    `링글 틴즈로 이관된 수업권에 대한 "예상 적립 포인트"는 링글에서 구매하신 수업권 실결제 금액의 3%입니다. 해당 금액이 매 수업 종료 7일 후 "링글 포인트"로 적립되며, 이관 수업권으로 받으신 포인트는 링글에서 사용 가능합니다.`,
    `Points for each lesson credit are valued at 3% of the total amount paid for the credit package. Lesson points will be calculated 7 days after the end of each lesson. Points accumulated through Ringle Teens can be used on Ringle.`,
    's_transfer_credit.terms_8',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_9: [
    `추가 문의 사항은 카카오톡 채널에서 1:1 상담 혹은 teens@ringleplus.com을 통해 문의하시기 바랍니다.`,
    `For further inquiries, book a call with our advisor here or contact us at teens@ringleplus.com.`,
    's_transfer_credit.terms_9',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_landing_home = {
  title1: {
    default: [
      <>
        당신이 꿈꾸던
        <br />
        영어실력과 커리어를
        <br />
        현실로
      </>,
      <>
        Improve your English,
        <br />
        Unlock your potential
        <br />
      </>,
      's_landing_home.title1.default',
      <>
        您即将可实现
        <br />
        梦寐以求的
        <br />
        英语实力及生涯
      </>,
      <>
        您夢寐以求的
        <br />
        英文實力及職涯
        <br />
        即將實現
      </>,
      <>
        目標とする
        <br />
        キャリアと
        <br />
        理想の英語力を
        <br />
        実現する
      </>,
      <>
        Cải thiện <br />
        tiếng Anh <br />
        Khai mở <br />
        tiềm năng
        <br />
      </>,
    ],
    desc: [
      <>
        아이비리그 출신 원어민 튜터와 함께하는
        <br />
        1:1 화상영어 수업
      </>,
      <>
        1:1 online English lessons
        <br />
        with top university students & graduates
      </>,
      's_landing_home.title1.desc',
      <>
        与常春藤名校母语任课教师一起进行
        <br />
        1:1视频英语课程
      </>,
      <>
        與常春藤名校的母語任課教師一起進行
        <br />
        1:1視訊英語課程
      </>,
      <>
        世界トップ大学出身のチューターが
        <br />
        1on1であなたの英語学習をサポート
      </>,
      <>
        Học tiếng Anh 1-1 cùng sinh viên các
        <br />
        trường Đại học hàng đầu
      </>,
    ],
  },
  btn1: [
    `링글 시작하기`,
    `Get Started`,
    's_landing_home.btn1',
    `开始Ringle`,
    `開始Ringle`,
    `Ringleをスタート`,
    `Bắt đầu`,
  ],
  title2: {
    mobile: [
      <>
        이미 많은 고객분들이
        <br />
        링글과 함께 꿈을 현실로
        <br />
        만들고 있습니다.
      </>,
      <>
        Ringle has allowed
        <br />
        countless dreams
        <br />
        to come true
      </>,
      's_landing_home.title2.mobile',
      <>
        已有很多学生
        <br />
        正在与Ringle一起
        <br />
        实现梦想。
      </>,
      <>
        已經有許多學生
        <br />
        正與Ringle一起
        <br />
        實現夢想。
      </>,
      <>
        既に多くの生徒達がRingleと一緒に
        <br />
        夢を現実のものとしています。
      </>,
      <>
        Ringle mở ra
        <br />
        những cơ hội mới
        <br />
        để bạn đạt được giấc mơ
      </>,
    ],
    desktop: [
      <>이미 많은 고객분들이 링글과 함께 꿈을 현실로 만들고 있습니다.</>,
      <>Ringle has allowed countless dreams to come true</>,
      's_landing_home.title2.desktop',
      <>已有很多学生正在与Ringle一起实现梦想。</>,
      <>已經有許多學生正與Ringle一起實現夢想。</>,
      <>既に多くの生徒達がRingleを利用して夢を現実のものとしています。</>,
      <>Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ</>,
    ],
    caption: [
      `*링글 제휴 기업`,
      `*Companies partnering with Ringle`,
      's_landing_home.title2.caption',
      `*Ringle合作公司`,
      `*Ringle合作公司`,
      `*Ringleと提携する企業`,
      `*Công ty đối tác`,
    ],
    pc: [
      `이미 많은 고객 분들이 링글과 함께 꿈을 현실로 만들고 있습니다`,
      `Ringle has allowed countless dreams to come true`,
      's_landing_home.title2.pc',
      <>已有很多学生正在与Ringle一起实现梦想。</>,
      <>已經有許多學生正與Ringle一起實現夢想。</>,
      <>既に多くのユーザーが Ringleと共に夢を現実にしています。</>,
      <>Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ</>,
    ],
  },
  title3: {
    default: [
      <>
        영어로 생각을 나눌 수 있는
        <br />
        명문대 원어민 튜터
      </>,
      <>
        In-depth discussions
        <br />
        with exceptional tutors
      </>,
      's_landing_home.title3.default',
      <>
        可帮您提高英语思维的
        <br />
        名校母语任课教师
      </>,
      <>
        助您使用英語思考的
        <br />
        名校母語任課教師
      </>,
      <>
        英語力向上をサポートする
        <br />
        一流大学出身のチューター
      </>,
      <>
        Những buổi thảo luận sâu
        <br />
        cùng các gia sư hàng đầu
      </>,
    ],
    desc: [
      `영어가 모국어인 사람은 많지만 정돈된 영어를 쓰는 사람은 따로 있죠. 내 생각을 영어로 효과적으로 표현하게 도와주는 1,000명 이상의 아이비리그 출신 튜터들과 지적인 대화를 나누세요.`,
      `Speaking eloquently is more an art than science. Learn from 1000+ intellectual tutors from top universities to express your thoughts effectively.`,
      's_landing_home.title3.desc',
      `虽然使用英语母语者很多，但使用有条的英语者却不多。请与1000名以上的常春藤名校任课教师一起进行可帮您有效的使用英语的对话吧！`,
      `雖然英語母語人士多的是，但部分人士使用的是有條理的英語。與1,000名以上的常春藤名校任課教師一起展開知識性的對話，他們將助您更有效果地使用英語表達您的想法。`,
      `英語を母国語とする人は数多くいれど、正しい英語を使用することが出来る人は少数です。自らの考えを英語で効果的に表現する実力を、1,000名以上の一流大学出身のチューターとの知的対話を通じて、磨き上げましょう。`,
      `Hùng biện là một môn nghệ thuật. Cải thiện khả năng của bạn với sự giúp đỡ từ 1000+ gia sư xuất sắc ở các trường đại học hàng đầu.`,
    ],
    more_btn: [
      `튜터 더 알아보기`,
      `Meet our tutors`,
      's_landing_home.title3.more_btn',
      `查看更多任课教师`,
      `查看更多任課教師`,
      `Ringleのチューターをもっと見てみる`,
      `Gặp gỡ các gia sư`,
    ],
  },
  review1: {
    default: [
      `글로벌 기업에서 일할 때의 영어는 누군가를 설득해야 하는 경우가 많아서 한 단계 높은 비즈니스 영어가 필요하죠. 링글 튜터들의 피드백을 받으면서 영어로 일하는 자신감을 가지게 됐습니다.`,
      `In the workplace, you need strong communication skills to be persuasive. Since joining Ringle, I’ve been a better communicator and negotiator at work.`,
      's_landing_home.review1.default',
      `在国企工作时，经常需要用英语去说服他人，因此需使用高端的商务英语。与Ringle的任课教师们学习后，现在我才感觉到我终于可以尽我所能了。`,
      `在國際企業工作時，很常需要使用英語說服他人，因此需要高一層次的商務英語。與Ringle的任課教師們學習後，現在我才感覺到我終於能利用英語盡我所能了。`,
      `グローバル企業で仕事をする際には、英語で誰かを説得する必要のある場面も多く、レベルの高いビジネス英語が要求されます。Ringleのチューターとの練習を通じて獲得した英会話力によって、本当の意味でグローバルに活躍するビジネスマンになることができました。`,
      `Trong công việc, bạn cần có kỹ năng giao tiếp tốt để thuyết phục. Kể từ khi học cùng Ringle, tôi giao tiếp và đàm phán tốt hơn trong công việc.`,
    ],
    writer: [
      `서주영 / 파트너 엔지니어, Google HQ`,
      `Juyung Seo / Partner Engineer, Google HQ`,
      's_landing_home.review1.writer',
      `Juyung Seo／合作工程师，Google HQ`,
      `Juyung Seo／Google HQ的夥伴工程師`,
      `ソ・ジュヨン／パートナーエンジニア 、Google本社`,
      `Juyung Seo / Kỹ sư tại Google`,
    ],
    tag: {
      default: [
        `해외 유학`,
        `Studying Abroad`,
        's_landing_home.review1.tag.default',
        `海外留学`,
        `海外留學`,
        `海外留学`,
        `Du học`,
      ],
    },
    title: {
      default: [
        <>
          우지혜님의
          <br />
          미국 석사에서 커리어 점프까지
        </>,
        <>
          Jihye's journey
          <br />
          to getting an MBA and making a career change
        </>,
        's_landing_home.review1.title.default',
        <>
          Jihye
          <br />
          从读美国硕士到成功转职
        </>,
        <>
          Jihye
          <br />
          從唸美國碩士到成功轉職
        </>,
        <>
          ウ・ジヘさん
          <br />
          アメリカでのMBA取得とキャリアチェンジ
        </>,
        <>Hành trình học MBA và thay đổi nghề nghiệp</>,
      ],
    },
    desc1: {
      default: [
        `프로덕트 디자이너, ZipRecruiter`,
        `Product Designer, Zip Recruiter`,
        's_landing_home.review1.desc1.default',
        `产品设计师，ZipRecruiter`,
        `ZipRecruiter的產品設計師`,
        `プロダクトデザイナー、ZipRecruiter`,
        `Product Designer, nhà tuyển dụng Zip`,
      ],
    },
    desc2: {
      default: [
        `Pratt Institute 석사`,
        `Pratt Institute, Information Design Master's degree`,
        's_landing_home.review1.desc2.default',
        `Pratt Institute硕士`,
        `Pratt Institute碩士`,
        `Pratt Institute,情報デザイン修士課程卒業`,
        `Học viện Pratt Institute, Thạc sĩ Thiết kế thông tin`,
      ],
    },
  },
  title4: {
    default: [
      <>
        눈에 보이는 교정,
        <br />
        기억에 남는 맞춤 피드백
      </>,
      <>
        Real-time corrections and
        <br />
        Personalized feedback
      </>,
      's_landing_home.title4.default',
      <>
        看得到的纠正
        <br />
        深刻留下记忆的针对性反馈
      </>,
      <>
        看得到的糾正、
        <br />
        留下印象的客製化意見回饋
      </>,
      <>
        ビジュアル化される英語力
        <br />
        英語力を磨く
        <br />
        詳細なフィードバック
      </>,
      <>
        Sửa lỗi trực tiếp và
        <br />
        nhận xét được
        <br />
        cá nhân hoá
      </>,
    ],
    desc: [
      `링글의 수업은 말하고 사라지는 영어가 아닌 교정되는 내 영어를 눈으로 보고, 기록으로 남깁니다. 수업 후에는 영어 사용에 대한 AI 분석과 튜터의 피드백 리포트를 통해 성장하는 실력까지 확인하세요.`,
      `What you learn stays with you on Ringle. Real-time corrections are visualized and saved in your class notes. You can also monitor your growth through AI analysis and tutor reports.`,
      's_landing_home.title4.desc',
      `Ringle的课程不是只说不记的英语，而是可以确认并留下记录的英语。课程结束后为提升英语能力可通过AI分析和任课教师的意见反馈来确认您的英语能力。`,
      `Ringle的課程不會一說即逝，而是可以親眼確認自己的英語得到糾正並留下紀錄。請於課後透過針對英語使用的AI分析及任課教師的意見回饋報告書確認您成長的實力。`,
      `Ringleのレッスンは従来の英会話レッスンとは異なり、ただ単に話して終わりません。チューターにより添削された表現は、全て文章化されます。
レッスンの後は、チューターからのアドバイスをレポートの形式で受領出来るだけでなく、話した内容をAIが分析して定量的にフィードバックされます。`,
      `Tất cả những buổi học đều được lưu lại. Chỉnh sửa lỗi từ gia sư lưu trong ghi chú buổi học. Bạn có thể theo dõi sự tiến bộ qua phân tích AI và nhận xét của gia sư.`,
    ],
    more_btn: [
      `수업 시스템 더 알아보기`,
      `Browse our learning system`,
      's_landing_home.title4.more_btn',
      `查看更多课程系统`,
      `查看更多課程系統`,
      `レッスンシステムを詳しく知る`,
      `Trải nghiệm hệ thống học tập`,
    ],
  },
  review2: {
    default: [
      `최근에 영어 발표 연습을 하면서 교정 받은 표현은 절대 잊지 않고 계속 쓸 것 같아요. 다른 플랫폼에서는 수업이 끝나고 내용이 사라지는 느낌이 들었다면 링글은 내가 말하는 것을 눈으로 보면서 고쳐주고 저장까지 되니까 확실히 머릿속에 남는 느낌이 들어요.`,
      `Unlike other platforms, Ringle's system makes it easier to retain and implement what I learned. I can see what I said exactly and how it was corrected by the tutor. It's intuitive!`,
      's_landing_home.review2.default',
      `最近练习英语发表时，我发现我真的不忘记纠正过的英语，而且还可正确的使用英语。之前进行电话英语时，下课后总感觉都忘了自己说了什么，但在Ringle能亲自确认我说的英语，纠正我的错误并储存，感觉英语真的实实在在的留在脑海里。`,
      `最近練習英語發表時，感覺到我絕對不會忘記獲得糾正的用法並能持續使用。電話英語在下課後，感覺上課內容就消失了，但在Ringle能親眼確認我所說的話、獲得糾正並儲存，感覺實實在在地留在腦海中。`,
      `英語でのプレゼンテーションを練習をする際、Ringleのチューターから指摘を受けた表現は直ぐに自分のものとすることができます。通常の英会話レッスンでは、指摘を受けたとしても、そのまま記憶に留まらずに流れてしまうことも多くあります。Ringleのレッスンでは、話した内容と指摘が記録化されるため、確実に英語力の強化に繋がります。`,
      `Không giống những nền tảng khác, hệ thống Ringle giúp bạn lưu trữ và áp dụng những gì mình đã học dễ dàng hơn. Tôi có thể xem lại những cuộc trò chuyện cũ và cách gia sư sửa lỗi theo một cách trực quan nhất!`,
    ],
    writer: [
      `김도엽 / 북미총괄 컨트롤러, 삼성물산`,
      `Doyeop Kim / General Manager, Samsung C&T`,
      's_landing_home.review2.writer',
      `Doyeop Kim／三星物产北美总经理`,
      `Doyeop Kim／三星物產的北美總經理`,
      `キム・ドヨン／北米総括コントローラー、サムスン物産`,
      `Doyeop Kim / Giám đốc tại Americas, Samsung C&T`,
    ],
    tag: {
      default: [
        `직장인 MBA`,
        `MBA for Professionals`,
        's_landing_home.review2.tag.default',
        `上班族MBA`,
        `上班族MBA`,
        `ビジネスエリートのためのMBA`,
        `MBA cho sự nghiệp`,
      ],
    },
    title: {
      default: [
        <>
          이상윤님의
          <br />
          성공적인 MBA 에세이 준비
        </>,
        <>
          How Sangyun
          <br />
          wrote a successful MBA application essay
        </>,
        's_landing_home.review2.title.default',
        <>
          Sangyun
          <br />
          成功准备MBA申论
        </>,
        <>
          Sangyun
          <br />
          成功準備MBA申論
        </>,
        <>
          イ・サンユンさん
          <br />
          質の高いEssayの作成・成功裡に終えたMBA準備
        </>,
        <>
          Câu chuyện thành công của
          <br />
          Sangyun và bài luận MBA
        </>,
      ],
    },
    desc1: {
      default: [
        `공동 창업자, NextChapter`,
        `Co-founder, NextChapter`,
        's_landing_home.review2.desc1.default',
        `共同创办人，NextChapter`,
        `NextChapter的共同創辦人`,
        `共同創業者、NextChapter`,
        `Đồng sáng lập NextChapter`,
      ],
    },
    desc2: {
      default: [
        `HBS MBA Class of 2020`,
        `HBS MBA Class of 2020`,
        's_landing_home.review2.desc2.default',
        `HBS MBA Class of 2020`,
        `HBS MBA Class of 2020`,
        `Harvard Business School MBA Class of 2020`,
        `Trường kinh doanh Harvard Khoá 2020`,
      ],
    },
  },
  title5: {
    default: [
      <>
        지금 가장 주목받는 주제의
        <br />
        교재로 익히는 실전 영어
      </>,
      <>
        Lesson Materials
        <br />
        on Trending Topics
      </>,
      's_landing_home.title5.default',
      <>
        利用最受瞩目的主题教材
        <br />
        熟练实战英语
      </>,
      <>
        利用目前最夯的主題教材
        <br />
        熟練的實戰英語
      </>,
      <>
        実践的な英語を
        <br />
        最新トレンドに
        <br />
        沿って学ぶ
      </>,
      <>
        Tài liệu học
        <br />
        với chủ đề thời sự
      </>,
    ],
    desc: [
      `시사∙경제, 비즈니스, 자기 계발까지. 아이비리그 출신 원어민과 MBA 졸업생 등으로 구성된 콘텐츠 팀이 직접 만드는 1,000개 이상의 교재와 영상을 활용하여 내가 관심 있는 주제의 영어 표현을 익히세요.`,
      `Gain access to 1,000+ articles and videos on politics, economics, business, and self-improvement, produced by Ivy-League and MBA graduates.`,
      's_landing_home.title5.desc',
      `从时事·经济、商务到自我开发。请利用常春藤名校母语任课教师及MBA毕业生等所组成的内容团队亲自编写的1000多个教材及视频，来学习您感兴趣的主题,熟悉英语用法吧！`,
      `從時事·經濟、商務到自我開發，善用常春藤名校的母語人士及MBA畢業生等所組成的內容團隊親自打造的1,000多個教材及影片，利用您感興趣的主題熟悉英語用法吧！`,
      `一流大学の出身者やMBAホルダーによって作成された、最新の最新の時事トピックや経済、自己啓発等、多岐に渡る1,000個以上の教材とビデオを活用し、自分自身が関心を持つテーマに沿った英語表現を学びましょう！`,
      `Tiếp cận 1,000+ bài báo và video về chính trị, kinh tế, kinh doanh và phát triển bản thân được xây dựng bởi các sinh viên Ivy League và MBA.`,
    ],
    more_btn: [
      `교재 더 알아보기`,
      `See more Materials`,
      's_landing_home.title5.more_btn',
      `查看更多教材`,
      `查看更多教材`,
      `教材をもっと見てみる`,
      `Xem thêm Tài liệu`,
    ],
  },
  review3: {
    default: [
      `영어로 대화를 잘 하려면 말하고 싶은 주제에 대해 영어로 익숙해지는 게 더 중요하더라고요. 한국어도 할 말이 없으면 대화가 잘 안되잖아요. 링글 수업 후에는 외국 팀원들과 날씨 이야기 말고도 다양한 주제로 대화하기가 훨씬 수월해졌어요.`,
      `Having a good conversation depends on your familiarity with the topic in any language, and English is no exception. Since I started using Ringle, I've found it easier to talk to my English-speaking colleagues.`,
      's_landing_home.review3.default',
      `我发现如想用英语很好的进行沟通，重要的是需先用英语来熟悉想要说的主题。就像我们用韩语对话时，如没有想要聊的主题，就聊不下去！ 自从我上完Ringle课程后，除了天气话题外，和外国同事也可讨论各种主题了。`,
      `我發現如果想要擅長英語會話，更重要的是利用自己想說的主題熟悉英語。韓語也是，如果無話可說，就聊不下去！上完Ringle課程後，除了天氣話題之外，我更能輕鬆和外國組員們討論各種主題了。`,
      `英語でスムーズに会話をするためには、自分が話をしたいテーマに関連する英語表現に慣れることが、何より大切でした。母国語でも話す内容がないと会話は長く続きません。Ringleのレッスンを通じ、海外チームとも天気の話に加え、多様なテーマで会話をすることが容易になりました。`,
      `Cuộc hội thoại tốt hay không tuỳ thuộc vào việc bạn quen thuộc thế nào với chủ đề. Giao tiếp bằng tiếng Anh cũng vậy. Từ khi học Ringle, tôi cảm thấy tự tin hơn khi trò chuyện với các đồng nghiệp bản ngữ.`,
    ],
    writer: [
      `정수지 / 프로그램 리드, SAP`,
      `Suji Jeong / Program Lead, SAP`,
      's_landing_home.review3.writer',
      `Suji Jeong／项目负责人，SAP`,
      `Suji Jeong／SAP的計畫主管`,
      `ジョン・スジ／プログラムリード、SAP`,
      `Suji Jeong / Trường nhóm dự án, SAP`,
    ],
    tag: {
      default: [
        `글로벌 취업`,
        `Working Abroad`,
        's_landing_home.review3.tag.default',
        `国际就业`,
        `國際就業`,
        `海外での就職`,
        `Làm việc ở nước ngoài`,
      ],
    },
    title: {
      default: [
        <>
          최예원님의
          <br />
          글로벌 커리어로의 전환
        </>,
        <>
          Yewon's story
          <br />
          of restarting her career abroad
        </>,
        's_landing_home.review3.title.default',
        <>
          Yewon
          <br />
          展开她的国际职业生涯
        </>,
        <>
          Yewon
          <br />
          展開她的國際職涯
        </>,
        <>
          チェ・エウォンさんの
          <br />
          海外で働く真の意味でのグローバル人材成長
        </>,
        <>
          Câu chuyện của Yewon
          <br />
          bắt đầu công việc mới ở nước ngoài
        </>,
      ],
    },
    desc1: {
      default: [
        `클리니컬 디렉터, Merck`,
        `Clinical Director, Merck`,
        's_landing_home.review3.desc1.default',
        `临床主任，Merck`,
        `Merck的臨床主任`,
        `クリニカルディレクター、Merck`,
        `Clinical Director, Merck`,
      ],
    },
    desc2: {
      default: [
        `서울대 의대, 임상병리 박사`,
        `MD, PhD in Clinical Pharmacology`,
        's_landing_home.review3.desc2.default',
        `首尔大医学院临床病例博士`,
        `首爾大醫學院臨床病理博士`,
        `ソウル大学医学部、臨床病理博士`,
        `Thạc sĩ, Tiến sĩ Dược lâm sàng`,
      ],
    },
  },
  title6: {
    default: [
      <>
        더 이상 막연한 꿈이 아닌
        <br />
        현실로
      </>,
      <>
        No longer a dream,
        <br />
        but a reality
      </>,
      's_landing_home.title6.default',
      <>
        渺茫的梦想，
        <br />
        将成为现实
      </>,
      <>
        不再是渺茫的夢想，
        <br />
        而將化為現實
      </>,
      <>
        もはや夢で終わらせない
        <br />
        夢を現実に
      </>,
      <>
        Không phải một giấc mơ, <br />
        đó là hiện thực
      </>,
    ],
    desc: [
      <>
        해외 유학, 글로벌 취업 준비, 영어 실력 향상 등.
        <br />늘 마음속에만 담아둔 목표, 이제는 링글과 함께 현실로 만드세요.
      </>,
      <>
        Studying or working abroad
        <br />
        The dreams you've always aspired to realize them with Ringle
      </>,
      's_landing_home.title6.desc',
      <>
        海外留学、国际就业准备、提升英语实力等，
        <br />
        现在就请与Ringle一起实现很久以来深藏心中的梦想吧！
      </>,
      <>
        海外留學、國際就業準備、提升英語實力等，
        <br />
        現在就與Ringle一起實現長久以來深埋心中的夢想吧！
      </>,
      `海外留学やグローバル企業への就職準備、英語力の向上など、あなたが漠然と抱いていた目標をRingleを通じて現実のものとしましょう。`,
      <>
        Học hoặc làm việc ở nước ngoài <br />
        Giấc mơ bạn hằng theo đuổi, Ringle sẽ giúp bạn
      </>,
    ],
    more_btn: [
      `고객 스토리 더 알아보기`,
      `Check Out Testimonials`,
      's_landing_home.title6.more_btn',
      `查看更多学生故事`,
      `查看更多學生故事`,
      `Ringle利用者の体験談をもっと見てみる`,
      `Xem thêm đánh giá khách hàng`,
    ],
  },
  title7: {
    default: {
      mobile: [
        <>
          가입만으로도
          <br />
          주어지는 성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.mobile',
        <>
          只需注册，
          <br />
          就能获得成长的机会
        </>,
        <>
          光是註冊，
          <br />
          就能獲得成長的機會
        </>,
        <>
          Ringleへ登録し、
          <br />
          新たな英語力向上の旅を始めましょう
        </>,
        <>
          Đăng ký và bắt đầu hành trình của riêng bạn
          <br />
        </>,
      ],
      pc: [
        <>
          가입만으로도 주어지는
          <br />
          성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.pc',
        <>
          只需注册，
          <br />
          就能获得成长的机会
        </>,
        <>
          光是註冊，
          <br />
          就能獲得成長的機會
        </>,
        `無料で登録
自ら獲得する成長の機会`,
        <>
          Đăng ký và bắt đầu hành trình của riêng bạn
          <br />
        </>,
      ],
    },
    desc: {
      default: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          링글 앱 다운 받고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Download the Ringle app to view free content
        </>,
        's_landing_home.title7.desc.default',
        <>
          一直迟疑并拖延的英语学习！ <br />
          现在就下载Ringle APP利用免费内容开始学习英语吧！
        </>,
        <>
          一直遲疑並拖延的英語學習！
          <br />
          現在就下載Ringle APP利用免費內容開始吧！
        </>,
        <>
          これまでためらっていた英語学習
          <br />
          Ringleのアプリをダウンロードして、無料コンテンツから始めてみましょう。
        </>,
        <>
          Đừng trì hoãn nữa <br />
          Tải ứng dụng Ringle để xem nội dung miễn phí
        </>,
      ],
      pc: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          회원 가입하고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Sign up Ringle to view free content
        </>,
        's_landing_home.title7.desc.pc',
        <>
          一直迟疑并拖延的英语学习！ <br />
          请现在注册会员后，利用免费内容开始学习英语吧！
        </>,
        <>
          一直遲疑並拖延的英語學習！
          <br />
          現在就註冊會員並利用免費內容開始吧！
        </>,
        <>
          ためらい、伸ばしていた英語の勉強
          <br />
          会員登録をして無料コンテンツで始めてみましょう。
        </>,
        <>
          Đừng trì hoãn nữa <br />
          Tải ứng dụng Ringle để xem nội dung miễn phí
        </>,
      ],
    },
    brief: {
      mobile: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits <br />
          with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.mobile',
        <>
          在指定的时间接收消息通知，
          <br />
          每天培养学习好习惯。
          <br />
          <b>最新教材</b>
        </>,
        <>
          在指定的時間接收通知，
          <br />
          每天培養學習好習慣。
          <br />
          <b>最新教材</b>
        </>,
        <>
          指定時間にお知らせ
          <br />
          毎日の勉強
          <br />
          <b>最新の教材</b>
        </>,
        <>
          Nhận thông báo vào thời gian mong muốn <br />
          tạo thói quen học tập mỗi ngày..
          <br />
          <b>Tài liệu mới nhất</b>
        </>,
      ],
      pc: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.pc',
        <>
          在指定的时间接收消息通知，
          <br />
          每天培养学习好习惯。
          <br />
          <b>最新教材</b>
        </>,
        <>
          在指定的時間接收通知，
          <br />
          每天培養學習好習慣。
          <br />
          <b>最新教材</b>
        </>,
        <>
          指定時間にお知らせ毎日の勉強
          <br />
          <b>最新の教材</b>
        </>,
        <>
          Nhận thông báo vào thời gian mong muốn <br />
          tạo thói quen học tập mỗi ngày..
          <br />
          <b>Tài liệu mới nhất</b>
        </>,
      ],
    },
    webinars: {
      mobile: [
        <>
          인기 튜터와 <br />
          다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors
          <br />
          and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.mobile',
        <>
          与人气任课教师
          <br />
          及其他学生一起进行的课程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          與人氣任課教師
          <br />
          及其他學生一起進行的課程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          人気のRingleチューターと
          <br />
          他の生徒とともに作り上げるグループレッスン
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          Tham gia cùng những gia sư xuất sắc <br />
          và các học viên Ringle
          <br />
          <b>trên Ringle LIVE</b>
        </>,
      ],
      pc: [
        <>
          인기 튜터와 다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.pc',
        <>
          与人气任课教师
          <br />
          及其他学生一起进行的课程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          與人氣任課教師
          <br />
          及其他學生一起進行的課程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          人気のRingleチューターと
          <br />
          他の生徒とともに作り上げるグループレッスン
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          Tham gia cùng những gia sư xuất sắc <br />
          và các học viên Ringle
          <br />
          <b>trên Ringle LIVE</b>
        </>,
      ],
    },
    events: {
      mobile: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>
            각종 이벤트와
            <br />
            수업 관련 알림까지
          </b>
        </>,
        <>
          Successfully form a new habit through
          <br />
          <b>
            our event updates and
            <br />
            class notifications
          </b>
        </>,
        's_landing_home.title7.events.mobile',
        <>
          有助于英语学习习惯的
          <br />
          <b>
            各种活动及
            <br />
            课程相关通知
          </b>
        </>,
        <>
          有助於英語學習習慣的
          <br />
          <b>
            各種活動及
            <br />
            課程相關通知
          </b>
        </>,
        <>
          英語学習の習慣を助けてくれる
          <b>
            各種のイベントと
            <br />
            レッスンに関連したお知らせまで
          </b>
        </>,
        <>
          Tạo thói quen thành công
          <br />
          <b>qua những thông báo sự kiện và lớp học</b>
        </>,
      ],
      pc: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>각종 챌린지와 이벤트까지</b>
        </>,
        <>
          Successfully form a new habit
          <br />
          <b>through events and challenges</b>
        </>,
        's_landing_home.title7.events.pc',
        <>
          有助于英语学习习惯的
          <br />
          <b>各种挑战及活动</b>
        </>,
        <>
          有助於英語學習習慣的
          <br />
          <b>各種挑戰及活動</b>
        </>,
        <>
          英語学習を習慣化する
          <br />
          <b>イベントや各種チャレンジ</b>
        </>,
        <>
          Tạo thói quen thành công
          <br />
          <b>qua những sự kiện và thử thách</b>
        </>,
      ],
    },
  },
  btn2: [
    `링글 앱 다운받기`,
    `Download the Ringle App`,
    's_landing_home.btn2',
    `下载Ringle APP`,
    `下載Ringle APP`,
    `Ringleのアプリをダウンロードする`,
    `Tải ngay ứng dụng Ringle`,
  ],
  title8: {
    mobile: [
      <>
        누구나 영어의 장벽을 넘어,
        <br />
        더 큰 기회에 도전하는 세상을
        <br />
        만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.mobile',
      <>
        Ringle将陪伴所有人
        <br />
        跨越英语障碍，直到打造
        <br />
        挑战更大机会的舞台。
      </>,
      <>
        Ringle會陪伴所有人
        <br />
        跨越英語的障礙，直到打造出
        <br />
        可以挑戰更大機會的世界。
      </>,
      <>
        誰もが英語のハードルを超えて、
        <br />
        より大きな機会に挑戦する世界まで
        <br />
        Ringleがご一緒します。
      </>,
      <>
        Một thế giới không rào cản tiếng Anh
        <br />
        Một thế giới để bạn mơ lớn
        <br />
        Và Ringle sẽ giúp bạn đạt được
      </>,
    ],
    subtitle1: [
      `지금까지 진행된 수업`,
      <>
        Total number
        <br />
        of lessons
      </>,
      's_landing_home.title8.subtitle1',
      `至今进行的课程`,
      `至今進行的課程`,
      `今までに完了したレッスン数`,
      `Tổng số buổi học`,
    ],
    count1: [`건`, ``, 's_landing_home.title8.count1', `件`, `件`, `回`, ``],
    subtitle2: [
      `수업 및 튜터 평균 만족도`,
      <>
        Average rating
        <br />
        of lessons and tutors
      </>,
      's_landing_home.title8.subtitle2',
      `对课程及教师的平均满意度`,
      `課程及任課教師平均滿意度`,
      `レッスンの平均満足度`,
      `Đánh giá trung bình`,
    ],
    count2: [`점`, ``, 's_landing_home.title8.count2', `分`, `分`, `点`, ``],
    count2_1: [`/5점`, `/5`, 's_landing_home.title8.count2_1', `／5分`, `／5分`, `／5点`, `/5`],
    subtitle3: [
      `최근 1개월간 수업한 튜터`,
      <>
        Active tutors
        <br />
        in the past month
      </>,
      's_landing_home.title8.subtitle3',
      `对课程及教师的平均满意度`,
      `最近1個月上過課的任課教師`,
      `アクティブチューター数`,
      `Gia sư dạy học trong tháng`,
    ],
    count3: [`명`, ``, 's_landing_home.title8.count3', `名`, `名`, `名`, ``],
    subtitle4: [
      `지금까지 등록된 교재`,
      <>
        Total number
        <br />
        of materials
      </>,
      's_landing_home.title8.subtitle4',
      `至今登录的教材`,
      `至今登錄的教材`,
      `これまでに登録された教材数`,
      `Tổng số tài liệu`,
    ],
    count4: [`개`, ``, 's_landing_home.title8.count4', `个`, `個`, `個`, ``],
    caption: [
      `*2022.11.07 내부 데이터 기준`,
      `*As of Nov 07, 2022`,
      's_landing_home.title8.caption',
      `*以2022.11.07内部数据为准`,
      `*以2022.11.07內部數據為準`,
      `*2022.11.07 Ringleの内部データ基準`,
      `Từ ngày 07/11/2022`,
    ],
    pc: [
      <>
        누구나 영어의 장벽을 넘어, 더 큰 기회에 도전하는
        <br />
        세상을 만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.pc',
      <>
        Ringle将陪伴所有人跨越英语障碍，
        <br />
        直到打造挑战更大机会的舞台。
      </>,
      <>
        Ringle會陪伴所有人跨越英語的障礙，
        <br />
        直到打造出可以挑戰更大機會的世界。
      </>,
      <>
        誰もが支障なく英語を駆使し
        <br />
        自由に夢を描くことができる世界の実現を
        <br />
        Ringleは目指しています。
      </>,
      <>
        Một thế giới không rào cản tiếng Anh
        <br />
        Một thế giới để bạn mơ lớn
        <br />
        Và Ringle sẽ giúp bạn đạt được
      </>,
    ],
  },
  btn3: [
    `링글 수업권 확인하기`,
    `Check Ringle Credits`,
    's_landing_home.btn3',
    `确认Ringle课程券`,
    `確認Ringle課程券`,
    `Ringleのレッスンチケットを確認する`,
    `Kiểm tra thẻ học Ringle`,
  ],
  btn4: [
    `링글 시작하기`,
    `Get Started`,
    's_landing_home.btn4',
    `开始Ringle`,
    `開始Ringle`,
    `Ringleを始める`,
    `Bắt đầu`,
  ],
  review4: {
    tag: {
      default: [
        `영어 실력 향상`,
        `Improve Your English`,
        's_landing_home.review4.tag.default',
        `提升英语实力`,
        `提升英語實力`,
        `根本的な英語力の向上を実感`,
        `Cải thiện tiếng Anh`,
      ],
    },
    title: {
      default: [
        <>
          김정은님의
          <br />
          해외 이직을 자극하는 영어 공부
        </>,
        <>
          Studying with Ringle
          <br />
          inspired Jeongeun to move abroad
        </>,
        's_landing_home.review4.title.default',
        <>
          Jeongeun
          <br />
          刺激准备转职海外的英语学习
        </>,
        <>
          Jeongeun
          <br />
          刺激轉職海外的英語學習
        </>,
        <>
          キム・ジョンさんの
          <br />
          Ringleで刺激を受け、海外転勤を決意
        </>,
        <>
          Học cùng Ringle truyền <br />
          cảm hứng để Jeoheun ra nước ngoài
        </>,
      ],
    },
    desc1: {
      default: [
        `주임 연구원, 에릭슨 - LG`,
        `Research Engineer, Ericsson-LG`,
        's_landing_home.review4.desc1.default',
        `主任研究员，Ericsson-LG`,
        `Ericsson-LG的研究工程師`,
        `主任研究員、エリクソン- LG`,
        `Kỹ sư nghiên cứu, Ericsson-LG`,
      ],
    },
  },
}
export const s_landing_promo = {
  hero_subtitle: [
    <>
      더 이상 영어 공부를
      <br />
      미룰 수 없는 당신을 위해
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    's_landing_promo.hero_subtitle',
    `为了不能再推迟英语学习的你`,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
  ],
  subtitle2: [
    <>
      원어민의 영어에 한단계
      <br />더 가까워지고 싶다면?
    </>,
    `Want to speak more naturally?`,
    's_landing_promo.subtitle2',
    <>
      如果想朝着母语者英语水平
      <br />
      更近一步, 怎么办?
    </>,
    `Want to speak more naturally?`,
    `Want to speak more naturally?`,
    `Want to speak more naturally?`,
  ],
  title2: [
    <>
      당신만을 위한 영어수업
      <br />
      1:1로 만나세요.
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    's_landing_promo.title2',
    <>
      为你量身定制的英语课程
      <br />
      以1对1的方式开始吧。
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
  ],
  desc2: [
    <>
      단순 회화를 넘어 상황에 맞는
      <br />
      자연스러운 영어로 레벨 업하려면
      <br />내 영어에만 집중한 실시간 교정이 필요합니다.
    </>,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    's_landing_promo.desc2',
    <>
      如果不只学习单纯的会话，
      <br />
      还想提高水平，学习自然的、符合语境的英语，
      <br />
      那就需要只针对自己英语的实时纠正反馈。
    </>,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
  ],
  subtitle4: [
    <>
      22년 여름, 6천명이 참여한
      <br />
      실리콘밸리 챌린지에 이은 또 다른 도전
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    's_landing_promo.subtitle4',
    ``,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
  ],
  desc4: [
    <>
      4개월 동안 24회 이상 수강한 5명에게
      <br />
      주어지는 보스턴&뉴욕 투어 기회!
      <br />
      글로벌 기업 현직자의 생생한 후기와 현지 분위기를
      <br />
      경험하고 더 넓은 세계를 향한 성장을 시작하세요.
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    's_landing_promo.desc4',
    ``,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
  ],
  subtitle6: (n) => [
    <>
      지금까지 <span className="text-[#FF6712]">{n}회</span> 공유했어요.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    's_landing_promo.subtitle6',
    ``,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
  ],
  btn1: [
    `나에게 맞는 수업권 더 보기`,
    `Find the perfect plan for you`,
    's_landing_promo.btn1',
    `了解更多适合自己的课程券`,
    `Find the perfect plan for you`,
    `Find the perfect plan for you`,
    `Find the perfect plan for you`,
  ],
  btn3: [
    `수업권 구매 후 신청할 수 있어요.`,
    `You can apply after purchasing credits.`,
    's_landing_promo.btn3',
    ``,
    `You can apply after purchasing credits.`,
    `You can apply after purchasing credits.`,
    `You can apply after purchasing credits.`,
  ],
  btn4: [`챌린지 더 알아보기`, `Learn more`, 's_landing_promo.btn4', ``, `Learn more`, `Learn more`, `Learn more`],
  notice: {
    title: [
      `프로모션 안내 및 유의 사항`,
      `Terms and conditions`,
      's_landing_promo.notice.title',
      `优惠活动介绍和注意事项`,
      `Terms and conditions`,
      `Terms and conditions`,
      `Terms and conditions`,
    ],
    title1: [
      `[공통 안내]`,
      `[All events]`,
      's_landing_promo.notice.title1',
      `【通用指南】`,
      `[All events]`,
      `[All events]`,
      `[All events]`,
    ],
    desc1: [
      <>
        1. 모든 일정은 KST 기준입니다.
        <br />
        2. 신년 프로모션 패키지는 기간 내 중복 구매가 가능합니다.
        <br />
        3. 기타 문의는 링글 카카오 채널@ringle로 문의 바랍니다.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      's_landing_promo.notice.desc1',
      <>
        1.所有日程以韩国时间为准。
        <br />
        2.在活动期间，新年优惠活动套餐可重複购买。
        <br />
        3.如有疑问，请通过Kakao频道@ringle进行咨询。
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
    ],
    title2: [
      `[링글 스터디클럽]`,
      `[Ringle Study Club]`,
      's_landing_promo.notice.title2',
      ``,
      `[Ringle Study Club]`,
      `[Ringle Study Club]`,
      `[Ringle Study Club]`,
    ],
    desc2: [
      <>
        1. 이벤트 기간: 22.12.23 ~ 23.03.02
        <br />
        2. 대상자 발표: 각 팀별 모집 종료 후 개별 연락
        <br />
        3. 참여 방법: 프로모션 기간 내 정규수업권 구매 시 참여 자격 부여 / 자격 부여 받은 인원 중 신청 대상자에 한해
        선착순 250명 한정 / 아이디 1개당 1회만 참여 가능합니다.
        <br />
        4. 유의사항: 신청 완료 후 수업권 환불 시, 스터디클럽 신청이 취소될 수 있습니다.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      's_landing_promo.notice.desc2',
      ``,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
    ],
    title3: [
      `[영어공부 목표 공유하기 이벤트]`,
      `[New Year's Resolution Event]`,
      's_landing_promo.notice.title3',
      ``,
      `[New Year's Resolution Event]`,
      `[New Year's Resolution Event]`,
      `[New Year's Resolution Event]`,
    ],
    desc3: [
      <>
        1. 이벤트 기간: 22.12.28 ~ 23.02.02
        <br />
        2. 혜택 대상자 안내: 23.02.10(금)에 스타벅스 기프티콘 일괄 지급 / 링글 수업권 4회 당첨자는 개별 연락
        <br />
        3. 참여 방법: 프로모션 기간 중 카톡으로 목표 공유하기 버튼으로 카카오 채팅방에 목표를 공유 하면 성공 / 공유한
        횟수에 따라 혜택 차등 지급
        <br />
        4. 유의사항: 동일한 채팅방에 2회 이상 공유시 1회만 횟수로 인정됩니다. / 나와의 채팅방에 공유시 인정되지
        않습니다. / 부정한 방법으로 이벤트 참여 이력이 확인되는 경우 선물이 지급되지 않습니다.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      's_landing_promo.notice.desc3',
      ``,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
    ],
  },
  toast_msg1: [
    `신청했어요. 이메일을 확인해 주세요!`,
    `Thanks for applying! Please check your email.`,
    's_landing_promo.toast_msg1',
    ``,
    `Thanks for applying! Please check your email.`,
    `Thanks for applying! Please check your email.`,
    `Thanks for applying! Please check your email.`,
  ],
  toast_msg2: [
    `이미 신청하셨습니다. 이메일을 확인해 주세요!`,
    `Already applied. Please check your email!`,
    's_landing_promo.toast_msg2',
    ``,
    `Already applied. Please check your email!`,
    `Already applied. Please check your email!`,
    `Already applied. Please check your email!`,
  ],
  btn5: [
    `스터디클럽 신청하기`,
    `Apply to Join`,
    's_landing_promo.btn5',
    ``,
    `Apply to Join`,
    `Apply to Join`,
    `Apply to Join`,
  ],
  btn6: [
    `얼리버드 진행 중`,
    `Early Bird Offer`,
    's_landing_promo.btn6',
    `早鸟特价优惠正在进行中`,
    `Early Bird Offer`,
    `Early Bird Offer`,
    `Early Bird Offer`,
  ],
  btn7: [
    `지금 가격 알아보기`,
    `See all price offers`,
    's_landing_promo.btn7',
    `早鸟优惠进行中`,
    `See all price offers`,
    `See all price offers`,
    `See all price offers`,
  ],
  title5_pc: [
    <>
      영어에만 집중하세요.
      <br />
      특별한 여행은 저희가 책임질게요.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    's_landing_promo.title5_pc',
    ``,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
  ],
  title6_pc: [
    `아직 망설여지나요? 링글팀과 직접 상담하세요.`,
    ``,
    's_landing_promo.title6_pc',
    `还在犹豫吗？和Ringle团队聊聊`,
    ``,
    ``,
    ``,
  ],
  promo_banner: [
    `1년에 단 2번, 최대 49% 할인 받고 지금 링글 시작해 보세요!`,
    `Only twice a year, all lesson credits on sale!`,
    's_landing_promo.promo_banner',
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
  ],
  materials: {
    item1: {
      title: [
        `은행이 된 스타벅스`,
        `Starbucks is Also a Bank`,
        's_landing_promo.materials.item1.title',
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
      ],
      category: [
        `#Retail`,
        `#Retail`,
        's_landing_promo.materials.item1.category',
        `#Retail`,
        `#Retail`,
        `#Retail`,
        `#Retail`,
      ],
      subtitle: [
        `기프트 카드의 경제`,
        `The economy of gift cards`,
        's_landing_promo.materials.item1.subtitle',
        `The economy of gift cards`,
        `The economy of gift cards`,
        `The economy of gift cards`,
        `The economy of gift cards`,
      ],
    },
    item2: {
      title: [
        `픽사 애니메이션 스튜디오`,
        `Pixar Animation Studios`,
        's_landing_promo.materials.item2.title',
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
      ],
      category: [
        `#Business`,
        `#Business`,
        's_landing_promo.materials.item2.category',
        `#Business`,
        `#Business`,
        `#Business`,
        `#Business`,
      ],
      subtitle: [
        `창조적 영혼이 살아있는 회사`,
        `Creativity in action`,
        's_landing_promo.materials.item2.subtitle',
        `Creativity in action`,
        `Creativity in action`,
        `Creativity in action`,
        `Creativity in action`,
      ],
    },
    item3: {
      title: [
        `일터에서 인생의 반을 보내는
당신에게`,
        `Half Your Life at Work`,
        's_landing_promo.materials.item3.title',
        `Half Your Life at Work`,
        `Half Your Life at Work`,
        `Half Your Life at Work`,
        `Half Your Life at Work`,
      ],
      category: [
        `#Small Talk`,
        `#Small Talk`,
        's_landing_promo.materials.item3.category',
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
      ],
      subtitle: [
        `유일한 삶에 대한 지침서`,
        `A guide to being mortal`,
        's_landing_promo.materials.item3.subtitle',
        `A guide to being mortal`,
        `A guide to being mortal`,
        `A guide to being mortal`,
        `A guide to being mortal`,
      ],
    },
    item4: {
      title: [
        `가볍고 소소한 잡담`,
        `Small Talk`,
        's_landing_promo.materials.item4.title',
        `Small Talk`,
        `Small Talk`,
        `Small Talk`,
        `Small Talk`,
      ],
      category: [
        `#Small Talk`,
        `#Small Talk`,
        's_landing_promo.materials.item4.category',
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
      ],
      subtitle: [
        `깊은 우정을 예고하는 전주곡`,
        `A prelude to deep friendships`,
        's_landing_promo.materials.item4.subtitle',
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
      ],
    },
    item5: {
      title: [
        `비즈니스 이메일 쓰기`,
        `Writing Business Emails`,
        's_landing_promo.materials.item5.title',
        `Writing Business Emails`,
        `Writing Business Emails`,
        `Writing Business Emails`,
        `Writing Business Emails`,
      ],
      category: [
        `#Business`,
        `#Business`,
        's_landing_promo.materials.item5.category',
        `#Business`,
        `#Business`,
        `#Business`,
        `#Business`,
      ],
      subtitle: [
        `최대한 간결하게`,
        `Err on the side of simplicity`,
        's_landing_promo.materials.item5.subtitle',
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
      ],
    },
  },
  page_title: [
    `2022 링글 하반기 프로모션`,
    `2022 Ringle Bi-annual Promo`,
    's_landing_promo.page_title',
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
  ],
  page_description: [
    `연 최대할인 부터 영어 학습법까지`,
    `Biggest promotion of the year`,
    's_landing_promo.page_description',
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
  ],
  bonus1_info2: {
    desc2: [
      `FALSE`,
      `The Zoom link will be sent to you before your orientation date.`,
      's_landing_promo.bonus1_info2.desc2',
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
    ],
  },
  hero_promo: [
    `23년 영어 공부, 지금 준비하세요.`,
    `For you to get a jump-start on studying English`,
    's_landing_promo.hero_promo',
    `Ringle新年优惠活动`,
    `For you to get a jump-start on studying English`,
    `For you to get a jump-start on studying English`,
    `For you to get a jump-start on studying English`,
  ],
  promo_date1: [
    `22.12.23 (금) - 23.02.02 (목)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date1',
    `2022.12.23(周五)~2023.02.02(周四)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
  ],
  promo_date2: [
    `23.01.06 (금) - 23.02.02 (목)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date2',
    `2023.01.06(五) - 2023.02.02(四）`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
  ],
  label_earlybird: [
    `얼리버드 혜택`,
    `Early bird`,
    's_landing_promo.label_earlybird',
    `早鸟特价活动`,
    `Early bird`,
    `Early bird`,
    `Early bird`,
  ],
  label_regular: [
    `신년 프로모션`,
    `New Year Promotion`,
    's_landing_promo.label_regular',
    `正式活动`,
    `New Year Promotion`,
    `New Year Promotion`,
    `New Year Promotion`,
  ],
  label_firstcome: [
    `선착순 마감`,
    `First come, first served`,
    's_landing_promo.label_firstcome',
    ``,
    `First come, first served`,
    `First come, first served`,
    `First come, first served`,
  ],
  label_recruiting: [
    `250명 선착순`,
    `250 spots only`,
    's_landing_promo.label_recruiting',
    ``,
    `250 spots only`,
    `250 spots only`,
    `250 spots only`,
  ],
  label_comingsoon: [
    `모집 전`,
    `Coming Soon`,
    's_landing_promo.label_comingsoon',
    ``,
    `Coming Soon`,
    `Coming Soon`,
    `Coming Soon`,
  ],
  label_closed: [`모집 마감`, `Closed`, 's_landing_promo.label_closed', ``, `Closed`, `Closed`, `Closed`],
  title1_mobile: [
    <>
      신년, 영어 정복을 위해
      <br />
      링글이 준비했습니다.
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_mobile',
    ``,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
  ],
  title1_pc: [
    `신년, 영어 정복을 위해 링글이 준비했습니다.`,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_pc',
    ``,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
  ],
  subtitle1: [
    `원어민과의 대화가 두렵다면?`,
    `Do you have English anxiety?`,
    's_landing_promo.subtitle1',
    <>
      如果害怕和英语母语者对话, <br />
      怎么办？
    </>,
    `Do you have English anxiety?`,
    `Do you have English anxiety?`,
    `Do you have English anxiety?`,
  ],
  desc1: [
    <>
      외국인과 편하게 대화하는 것이 모두의 목표죠.
      <br />
      영어 표현만 외우기보다는 원어민과 <br />
      실전을 연습해야 진짜 실력이 됩니다.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    's_landing_promo.desc1',
    <>
      能和外国人轻松对话是每个人的目标。
      <br />
      比起只背英语表达，
      <br />
      和英语母语者进行实战练习才能培养真正的实力。
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
  ],
  vedio_desc: [
    `소리를 켜고 실제 링글 수업을 확인해보세요!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    's_landing_promo.vedio_desc',
    `实际感受一下真正的Ringle课程吧！`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
  ],
  qna1: {
    title_pc: [
      `튜터의 말을 못 알아들어서 수업이 어려울까봐 걱정돼요.`,
      `I'm worried I won't follow what the tutor says.`,
      's_landing_promo.qna1.title_pc',
      `因为害怕听不懂任课教师的话，担心课程太难。`,
      `I'm worried I won't follow what the tutor says.`,
      `I'm worried I won't follow what the tutor says.`,
      `I'm worried I won't follow what the tutor says.`,
    ],
    title_mobile: [
      <>
        튜터의 말을 못 알아들어서
        <br />
        수업이 어려울까봐 걱정돼요.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      's_landing_promo.qna1.title_mobile',
      <>
        因为害怕听不懂任课教师的话，
        <br />
        担心课程太难。
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
    ],
    review: [
      `해외 입국 심사도 못 알아듣고 긴장할 정도여서 링글 결제 전에 고민이 많았어요. 하지만 막상 수업을 들어보니 섣부른 걱정이었어요. 튜터들이 제 수준에 맞춰 수업을 진행하니 불편함이 없었고, 오히려 원어민과 대화를 할 수 있다는 자신감을 가지게 해준 것만으로 링글의 수업료가 아깝지 않습니다.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      's_landing_promo.qna1.review',
      `连海外入境审查都听不懂，甚至都会紧张，所以在Ringle付费之前很犹豫。但是真正听完课后发现是不必要的担心。任课教师根据我的水平进行授课，没有不便之处，反而让我有了和英语母语者一起对话的自信心，所以Ringle的学费一点都不觉得可惜。`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
    ],
    reviewer_desc: [
      `링글 56회 수강, 디자이너`,
      `Took 56 lessons with Ringle`,
      's_landing_promo.qna1.reviewer_desc',
      `在Ringleㄕ56次 设计师`,
      `Took 56 lessons with Ringle`,
      `Took 56 lessons with Ringle`,
      `Took 56 lessons with Ringle`,
    ],
    reviewer: [
      `김미정 님`,
      `Kim Mi-jeong, Designer`,
      's_landing_promo.qna1.reviewer',
      `金美静`,
      `Kim Mi-jeong, Designer`,
      `Kim Mi-jeong, Designer`,
      `Kim Mi-jeong, Designer`,
    ],
  },
  qna2: {
    title_pc: [
      `왜 초중급자에게 원어민 화상영어가 좋은가요?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      's_landing_promo.qna2.title_pc',
      `为什么对于初中级学习者来说，母语视频英语课程好呢？`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
    ],
    title_mobile: [
      <>
        왜 초중급자에게
        <br />
        원어민 화상영어가 좋은가요?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      's_landing_promo.qna2.title_mobile',
      <>
        为什么对于初中级学习者来说，
        <br />
        母语视频英语课程好呢？
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
    ],
    review: [
      `실시간 교정 덕분에 내가 지금 무엇을 잘하고 못하는지 판단이 돼요. 특히 튜터들에게 반복적으로 피드백을 받는 부분은 더 빨리 신경 써서 고치게 돼요. 제가 자주 쓰는 잘못된 문법도요. 이렇게 나에게 맞춘 피드백을 주니 빠른 시간에 진짜 영어 실력을 늘리는 기분이에요.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      's_landing_promo.qna2.review',
      `通过进行实时纠正，我能判断自己现在哪里做得好哪里做得不好。特别是任课教师反复给与反馈的地方，我能更留心去更正。我经常用错的语法也是如此。这样给我有针对性的反馈，感觉真的是在很短的时间内提高了英语水平。`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
    ],
    reviewer_desc: [
      `링글 60회 수강, 개발자`,
      `Took 60 lessons with Ringle`,
      's_landing_promo.qna2.reviewer_desc',
      `在Ringle上课60次`,
      `Took 60 lessons with Ringle`,
      `Took 60 lessons with Ringle`,
      `Took 60 lessons with Ringle`,
    ],
    reviewer: [
      `정현석 님`,
      `Jeong Hyeon-seok, Developer`,
      's_landing_promo.qna2.reviewer',
      `郑贤硕`,
      `Jeong Hyeon-seok, Developer`,
      `Jeong Hyeon-seok, Developer`,
      `Jeong Hyeon-seok, Developer`,
    ],
  },
  qna3: {
    title_pc: [
      `다른 영어 서비스와 가격차이가 크게 나나요?`,
      `Is Ringle more expensive than other English learning services?`,
      's_landing_promo.qna3.title_pc',
      `和其它英语服务相比，价格差异大吗？`,
      `Is Ringle more expensive than other English learning services?`,
      `Is Ringle more expensive than other English learning services?`,
      `Is Ringle more expensive than other English learning services?`,
    ],
    title_mobile: [
      <>
        다른 영어 서비스와
        <br />
        가격차이가 크게 나나요?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      's_landing_promo.qna3.title_mobile',
      <>
        和其它英语服务相比，
        <br />
        价格差异大吗？
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
    ],
    review: [
      `회화 학원도 다녀보고, 영어 앱 서비스도 결제해봤어요. 하지만 학원은 오히려 더 비싼 적도 있었고, 앱 서비스는 직접 대면으로 연습하지 못해서 아쉬움이 있더라고요. 운동 1:1 PT가 좋듯이 영어도 1:1로 하니까 확실한 효과가 보였어요. PT 가격 생각하면 링글 회당 가격이 합리적이라고 생각해요.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      's_landing_promo.qna3.review',
      `我上过英语学院，也使用过收费的英语应用程序服务，但是，有时候英语学院的价格更贵，应用程度服务不能进行面对面的练习，所以有点遗憾。就像1对1的健身运动一样，英语也是以1对1的方式上课才看到了更确切的效果。考虑到健身运动价格，我认为Ringle的每次课程价格是合理的。`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
    ],
    reviewer_desc: [
      `링글 30회 수강, 마케터`,
      `Took 30 lessons with Ringle`,
      's_landing_promo.qna3.reviewer_desc',
      `在Ringle上课30次 营销人员`,
      `Took 30 lessons with Ringle`,
      `Took 30 lessons with Ringle`,
      `Took 30 lessons with Ringle`,
    ],
    reviewer: [
      `장서경 님`,
      `Jang Seo-young, Marketer`,
      's_landing_promo.qna3.reviewer',
      ``,
      `Jang Seo-young, Marketer`,
      `Jang Seo-young, Marketer`,
      `Jang Seo-young, Marketer`,
    ],
  },
  qna4: {
    title_pc: [
      `초중급자에게 맞는 수업권이 있을까요?`,
      `Which credit do you recommend for beginners/intermediates?`,
      's_landing_promo.qna4.title_pc',
      `有适合初中级学习者的课程券吗？`,
      `Which credit do you recommend for beginners/intermediates?`,
      `Which credit do you recommend for beginners/intermediates?`,
      `Which credit do you recommend for beginners/intermediates?`,
    ],
    title_mobile: [
      <>
        초중급자에게 맞는
        <br />
        수업권이 있을까요?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      's_landing_promo.qna4.title_mobile',
      <>
        有适合初中级学习者的
        <br />
        课程券吗？
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
    ],
    review: [
      `처음에는 원어민과의 대화가 두려워서 20분 수업을 해봤는데 제가 영어를 잘하지 못하니까 교정할 게 많아서 시간이 짧더라고요. 40분 12회 혹은 24회 수업권이 저와 잘 맞아요. 주 1-2회 수업이라고 계산하면 한 달에 어학교육으로 쓰는 비용에 부담이 없더라고요.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      's_landing_promo.qna4.review',
      `一开始我害怕和英语母语者对话，所以只上了20分钟的课，但是因为我的英语不太好,需要纠正的东西很多,所以感觉时间很短。40分钟的课上12次或者24次的课程券比较适合我。如果以每周上1-2次课程计算，一个月用于语言学习的费用并没有负担。`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
    ],
    reviewer_desc: [
      `링글 50회 수강, 개발자`,
      `Took 50 lessons with Ringle`,
      's_landing_promo.qna4.reviewer_desc',
      `在Ringle上课50次`,
      `Took 50 lessons with Ringle`,
      `Took 50 lessons with Ringle`,
      `Took 50 lessons with Ringle`,
    ],
    reviewer: [
      `김현모 님`,
      `Kim Hyeon-mo, Developer`,
      's_landing_promo.qna4.reviewer',
      `金贤模`,
      `Kim Hyeon-mo, Developer`,
      `Kim Hyeon-mo, Developer`,
      `Kim Hyeon-mo, Developer`,
    ],
  },
  qna5: {
    title_pc: [
      `초중급자에게 적합한 교재와 튜터가 있나요?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      's_landing_promo.qna5.title_pc',
      `有适合初中级学习者的教材和任课教师吗？`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
    ],
    title_mobile: [
      <>
        초중급자에게 적합한
        <br />
        교재와 튜터가 있나요?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      's_landing_promo.qna5.title_mobile',
      <>
        有适合初中级学习者的
        <br />
        教材和任课教师吗？
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
    ],
    review: [
      `주로 "Small Talk” 카테고리 교재를 많이 선택해요. 외국인과 날씨 이야기만 할 수 없잖아요. 일상 대화에서 자주 쓰는 영어 표현도 많이 배우고, 말할 거리를 미리 연습해요. 튜터는 친절하다는 후기가 많은 튜터가 저와 잘 맞았어요. 영어를 잘하지 못해도 무한 칭찬으로 자신감을 키워주거든요.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      's_landing_promo.qna5.review',
      `我主要是在"Small Talk” 目录上选择教材。总不能和外国人只聊天气吧，既能学习在日常生活中经常使用的英语表达，也能提前练习要说的内容。有很多评价说亲切的任课教师很适合我。即使英语不太好，也会经常表扬我，培养我的自信心。`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
    ],
    reviewer_desc: [
      `링글 40회 수강, 기획자`,
      `Took 40 lessons with Ringle`,
      's_landing_promo.qna5.reviewer_desc',
      `在Ringle上课40次 项目经理`,
      `Took 40 lessons with Ringle`,
      `Took 40 lessons with Ringle`,
      `Took 40 lessons with Ringle`,
    ],
    reviewer: [
      `서연우 님`,
      `Seo Yeon-wu, Project Manager`,
      's_landing_promo.qna5.reviewer',
      `徐延宇(音)`,
      `Seo Yeon-wu, Project Manager`,
      `Seo Yeon-wu, Project Manager`,
      `Seo Yeon-wu, Project Manager`,
    ],
  },
  qna6: {
    title_pc: [
      `영어로 의사소통은 가능한데 링글이 어떻게 도움이 될까요?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      's_landing_promo.qna6.title_pc',
      `如果已经可以用英语进行沟通，那Ringle有什么帮助呢？`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
    ],
    title_mobile: [
      <>
        영어로 의사 소통은 가능한데
        <br />
        링글이 어떻게 도움이 될까요?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      's_landing_promo.qna6.title_mobile',
      <>
        如果已经可以用英语进行沟通，
        <br />
        那Ringle有什么帮助呢？
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
    ],
    review: [
      `영어로 이야기하는 데 큰 불편함은 없지만 누군가가 고쳐주지 않으니까 혹시 어색한 표현을 쓰고 있지는 않은지 또는 같은 표현만 쓰는지 알기 어렵더라고요. 링글 수업은 실시간으로 제 영어를 교정받고 수업 후 튜터 피드백 리포트와 통계 자료를 통해 분명하게 고칠 점을 파악할 수 있어요.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      's_landing_promo.qna6.review',
      `虽然用英语交流没有太大的不便，但是没人给纠正，所以很难知道自己是不是使用了不自然的表达方式，或者只用同样的表达方式。Ringle课程中，我的英语可以得到实时纠正，课后通过任课教师的反馈报告和统计资料，清晰得掌握自己需要改进的地方。`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 마케터`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna6.reviewer_desc',
      `在Ringle上课100次 营销人员`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `이윤진 님`,
      `Lee Yoon-jin, Marketer`,
      's_landing_promo.qna6.reviewer',
      `李允真`,
      `Lee Yoon-jin, Marketer`,
      `Lee Yoon-jin, Marketer`,
      `Lee Yoon-jin, Marketer`,
    ],
  },
  qna7: {
    title_pc: [
      `업무를 영어로 많이 하는데 링글을 어떻게 활용하면 좋은가요?`,
      `Why should I use Ringle? I use English every day at work.`,
      's_landing_promo.qna7.title_pc',
      `如果在工作中经常使用英语，那应该怎么利用好Ringle呢？`,
      `Why should I use Ringle? I use English every day at work.`,
      `Why should I use Ringle? I use English every day at work.`,
      `Why should I use Ringle? I use English every day at work.`,
    ],
    title_mobile: [
      <>
        업무를 영어로 많이 하는데
        <br />
        링글을 어떻게 활용하면 좋은가요?
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      's_landing_promo.qna7.title_mobile',
      <>
        如果在工作中经常使用英语，
        <br />
        那应该怎么利用好Ringle呢？
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
    ],
    review: [
      `계약서 등 중요한 문서를 작성할 때는 자유 주제 수업 중 문서 교정 수업을 잘 활용하고 있어요. 몇 번 문서 교정을 받으니 지금은 계약서를 쓰고 보는데 확실히 편해졌어요. 그 밖에 중요한 세일즈 프레젠테이션이나, 해외 출장 전에 링글로 미리 스피킹 연습을 해서 자신감을 키우고 나선답니다.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      's_landing_promo.qna7.review',
      `在撰写合同等重要文件时，我很好地利用了自由主题课程中的文件修改课程。经过几次文件修改，现在写合同和看合同确实方便了。除此之外，在做重要的销售陈述或者去海外出差前都用Ringle提前练习口语，培养自信心。`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 해외영업`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna7.reviewer_desc',
      `在Ringle上课100次 海外销售`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `Sandy 님`,
      `Sandy, Overseas Sales`,
      's_landing_promo.qna7.reviewer',
      `Sandy`,
      `Sandy, Overseas Sales`,
      `Sandy, Overseas Sales`,
      `Sandy, Overseas Sales`,
    ],
  },
  qna8: {
    title_pc: [
      `해외 취업을 준비 중입니다. 링글로 도움을 받을 수 있나요?`,
      `I want to work abroad. How can Ringle help?`,
      's_landing_promo.qna8.title_pc',
      `正在准备海外就业，可以得到Ringle的帮助吗？`,
      `I want to work abroad. How can Ringle help?`,
      `I want to work abroad. How can Ringle help?`,
      `I want to work abroad. How can Ringle help?`,
    ],
    title_mobile: [
      <>
        해외 취업을 준비 중입니다.
        <br />
        링글로 도움을 받을 수 있나요?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      's_landing_promo.qna8.title_mobile',
      <>
        正在准备海外就业，
        <br />
        可以得到Ringle的帮助吗？
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
    ],
    review: [
      `해외 취업 준비 중에 링글로 인터뷰 연습을 밀도 있게 진행했어요. 인터뷰가 잡히면 하루에 2-3개씩 수업을 잡고 다양한 튜터들과 인터뷰를 연습하면서 최적의 인터뷰 스크립트를 만들어 나갔습니다. 반복적으로 스크립트를 연습하고, 다양한 튜터들과 연습하고 나니 실전에서도 긴장감이 줄었어요.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      's_landing_promo.qna8.review',
      `在准备海外就业时，可以通过Ringle进行集中面试练习。如果有面试日程，可以每天安排2-3节课程，和不一样的任课教师练习面试，准备最佳的面试脚本。反复练习脚本，并和多位导师进行练习后，会减少实战中的紧张感。`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
    ],
    reviewer_desc: [
      `링글 65회 수강, UX 디자이너`,
      `Took 65 lessons with Ringle`,
      's_landing_promo.qna8.reviewer_desc',
      `在Ringle上课65次 UX设计师`,
      `Took 65 lessons with Ringle`,
      `Took 65 lessons with Ringle`,
      `Took 65 lessons with Ringle`,
    ],
    reviewer: [
      `김창수 님`,
      `Kim Chang-su, UX Designer`,
      's_landing_promo.qna8.reviewer',
      `金昌洙`,
      `Kim Chang-su, UX Designer`,
      `Kim Chang-su, UX Designer`,
      `Kim Chang-su, UX Designer`,
    ],
  },
  qna9: {
    title_pc: [
      `유학 준비도 링글로 가능한가요?`,
      `I want to study abroad. How can Ringle help?`,
      's_landing_promo.qna9.title_pc',
      `留学也可以通过Ringle来做准备吗？`,
      `I want to study abroad. How can Ringle help?`,
      `I want to study abroad. How can Ringle help?`,
      `I want to study abroad. How can Ringle help?`,
    ],
    title_mobile: [
      <>
        유학 준비도
        <br />
        링글로 가능한가요?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      's_landing_promo.qna9.title_mobile',
      <>
        留学
        <br />
        也可以通过Ringle来做准备吗？
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
    ],
    review: [
      `저는 링글로 미국 MBA 유학을 준비했어요. 레쥬메와 에세이를 쓸 때 문서 교정 수업을 통해 튜터와 이야기를 나누니 의미가 훼손되지 않으면서도 자연스러운 표현으로 간결하게 정리할 수 있었어요. 인터뷰 준비 역시 여러 명의 튜터와 10회 이상 실전 연습을 한 덕분에 좋은 결과를 얻을 수 있었습니다.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      's_landing_promo.qna9.review',
      `我通过Ringle准备了美国MBA留学。在写简历和报告时，通过文件修改课程，和任课教师一起讨论，用既不破坏原意又自然的表达方式进行了简洁的整理。面试准备也是和多位任课教师一起进行10多次实战练习之后，才能够得到了一个好的结果。`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
    ],
    reviewer_desc: [
      `링글 80회 수강, 마케터`,
      `Took 80 lessons with Ringle`,
      's_landing_promo.qna9.reviewer_desc',
      `在Ringle上课80次 营销人员`,
      `Took 80 lessons with Ringle`,
      `Took 80 lessons with Ringle`,
      `Took 80 lessons with Ringle`,
    ],
    reviewer: [
      `강수연 님`,
      `Kang Su-yeon, Marketer`,
      's_landing_promo.qna9.reviewer',
      `姜秀妍`,
      `Kang Su-yeon, Marketer`,
      `Kang Su-yeon, Marketer`,
      `Kang Su-yeon, Marketer`,
    ],
  },
  qna10: {
    title_pc: [
      `상급자에게 도움이 되는 교재와 튜터가 있나요?`,
      `Any recommended tutors/materials for advanced learners?`,
      's_landing_promo.qna10.title_pc',
      `有对英语高级学习者有帮助的课程方式和任课教师吗？`,
      `Any recommended tutors/materials for advanced learners?`,
      `Any recommended tutors/materials for advanced learners?`,
      `Any recommended tutors/materials for advanced learners?`,
    ],
    title_mobile: [
      <>
        상급자에게 도움이 되는
        <br />
        교재와 튜터가 있나요?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      's_landing_promo.qna10.title_mobile',
      <>
        有对英语高级学习者有帮助的
        <br />
        课程方式和任课教师吗？
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
    ],
    review: [
      `회화에 익숙한 분이라면 다양한 악센트의 튜터와 토론 모드로 수업하는 것을 추천해요. 미국식 영어를 넘어 어떤 나라에서든, 어떤 국적의 상대방과도 영어로 의사소통이 가능하려면 반드시 필요한 과정이라고 생각해요. 또한 심도 있는 대화를 주고받다 보면 영어로 복잡한 생각도 정리하고 이야기하는 게 가능해져요.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      's_landing_promo.qna10.review',
      `对于熟悉会话的人，推荐多种口音的任课教师和讨论模式的课程。不仅仅是美式英语，如果想在任何一个国家和任何国籍的人用英语对话，我认为这是一个必需的过程。另外，通过进行深度的对话，可以用英语整理复杂的想法并进行交谈。`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 연구원`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna10.reviewer_desc',
      `在Ringle上课100次 研究员`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `이석연 님`,
      `Lee Seok-yeon, Researcher`,
      's_landing_promo.qna10.reviewer',
      `李锡渊`,
      `Lee Seok-yeon, Researcher`,
      `Lee Seok-yeon, Researcher`,
      `Lee Seok-yeon, Researcher`,
    ],
  },
  title3_pc: [
    `신년 영어 공부, 가장 저렴하게 준비하세요.`,
    `The Best Deals For Studying English Available Now.`,
    's_landing_promo.title3_pc',
    `以2023年最优惠的价格报名Ringle吧。`,
    `The Best Deals For Studying English Available Now.`,
    `The Best Deals For Studying English Available Now.`,
    `The Best Deals For Studying English Available Now.`,
  ],
  title3_mobile: [
    <>
      신년 영어 공부, <br />
      가장 저렴하게
      <br />
      준비하세요.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    's_landing_promo.title3_mobile',
    <>
      以2023年
      <br />
      最优惠的价格
      <br />
      报名Ringle吧。
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
  ],
  month: [`/월`, `/Month`, 's_landing_promo.month', `/月`, `/Month`, `/Month`, `/Month`],
  nomal: [`정가`, `Regular:`, 's_landing_promo.nomal', `定价`, `Regular:`, `Regular:`, `Regular:`],
  won: [`원`, `KRW`, 's_landing_promo.won', `元`, `KRW`, `KRW`, `KRW`],
  discount: [
    `프로모션 할인가`,
    `Discounted:`,
    's_landing_promo.discount',
    `特价`,
    `Discounted:`,
    `Discounted:`,
    `Discounted:`,
  ],
  info_min: [
    `1회 수업 시간`,
    `Time per Lesson`,
    's_landing_promo.info_min',
    `一次课程时间`,
    `Time per Lesson`,
    `Time per Lesson`,
    `Time per Lesson`,
  ],
  info_count: [
    `수업 횟수`,
    `No. of credits`,
    's_landing_promo.info_count',
    `课程次数`,
    `No. of credits`,
    `No. of credits`,
    `No. of credits`,
  ],
  info_day: [
    `수강 기간`,
    `Expiration dates`,
    's_landing_promo.info_day',
    `上课时间`,
    `Expiration dates`,
    `Expiration dates`,
    `Expiration dates`,
  ],
  min: [`분`, `Min`, 's_landing_promo.min', `分`, `Min`, `Min`, `Min`],
  count: [`회`, ``, 's_landing_promo.count', `次`, ``, ``, ``],
  day: [`일`, `Days`, 's_landing_promo.day', `日`, `Days`, `Days`, `Days`],
  credit_desc: [
    `수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    's_landing_promo.credit_desc',
    `课程券在课程期间可以自由使用`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
  ],
  credit_desc2: [
    `100회 수업권은 25회 당일수업권이 추가 제공됩니다.`,
    `25 extra last-minute credits will be given`,
    's_landing_promo.credit_desc2',
    `追加赠送25张当日课程券`,
    `25 extra last-minute credits will be given`,
    `25 extra last-minute credits will be given`,
    `25 extra last-minute credits will be given`,
  ],
  benefit4_subtitle: [
    `링글이 처음이라면?`,
    `New to Ringle?`,
    's_landing_promo.benefit4_subtitle',
    `如果是第一次报名Ringle`,
    `New to Ringle?`,
    `New to Ringle?`,
    `New to Ringle?`,
  ],
  benefit4_title: [
    `신규회원 수업권 최대 3회 추가 증정`,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    's_landing_promo.benefit4_title',
    `最多赠送3次新会员优惠课程券`,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
  ],
  benefit5_subtitle: [
    `이미 링글 고객이라면?`,
    `Already a Ringler?`,
    's_landing_promo.benefit5_subtitle',
    `如果已经是Ringle会员`,
    `Already a Ringler?`,
    `Already a Ringler?`,
    `Already a Ringler?`,
  ],
  benefit5_title: [
    `보유 수업권 수강기간 연장 혜택`,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    's_landing_promo.benefit5_title',
    `延长持有课程券使用期限的优惠`,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
  ],
  title4_pc: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도 함께하면 가능해요.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_pc',
    ``,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
  ],
  title4_mobile: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도
      <br />
      함께하면 가능해요.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_mobile',
    ``,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
  ],
  studyclub: [
    `추가혜택 1. 스터디클럽 2기`,
    `Study Club Round 2`,
    's_landing_promo.studyclub',
    ``,
    `Study Club Round 2`,
    `Study Club Round 2`,
    `Study Club Round 2`,
  ],
  subtitle3: [
    <>
      실력을 만드는 영어 공부 습관,
      <br />
      4주 동안 함께 만들어요.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    's_landing_promo.subtitle3',
    ``,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
  ],
  desc3: [
    <>
      1. 매주 1시간 다른 수강생들과 영어 스피킹 연습
      <br />
      2. 매주 1회 이상 링글 수업 의무 수강
      <br />
      3. 링글팀의 1:1 목표관리
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    's_landing_promo.desc3',
    ``,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
  ],
  info1: [
    <>
      <b>참여 방법:</b>
      <br />
      프로모션 기간 내 정규수업권 구매 시 신청 가능
      <br />
      선착순 250명씩 총 4개 팀 운영 (총 1,000명)
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    's_landing_promo.info1',
    ``,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
  ],
  info2: [
    <>
      <b>운영 기간:</b>
      <br />
      각 팀별 운영 기간 하단 참고
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    's_landing_promo.info2',
    ``,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
  ],
  info3: [
    <>
      <b>운영 방식:</b>
      <br />
      주 1회 1시간씩 줌 화상 미팅으로
      <br />
      소그룹 영어 스피킹 연습 진행 (총 4주)
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    's_landing_promo.info3',
    ``,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
  ],
  info4: [
    <>
      <b>완주 조건:</b>
      <br />
      스터디 모임 3회 이상 참석, 수업 3회 이상 수강
      <br />
      미완주시 다음 스터디클럽 신청 불가
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    's_landing_promo.info4',
    ``,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
  ],
  info5: [
    <>
      <b>완주자 혜택:</b>
      <br />
      40분 정규 수업 1년 패키지 (1명 추첨)
      <br />
      10만원 상당의 깜짝 선물 (10명 추첨)
      <br />
      오피스 이벤트 초대
      <br />
      수강생 네트워킹 오픈채팅방 초대
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    's_landing_promo.info5',
    ``,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
  ],
  studyclub_apply: [`모집:`, `Apply:`, 's_landing_promo.studyclub_apply', ``, `Apply:`, `Apply:`, `Apply:`],
  studyclub_meetings: [
    `스터디 기간:`,
    `Meetings:`,
    's_landing_promo.studyclub_meetings',
    ``,
    `Meetings:`,
    `Meetings:`,
    `Meetings:`,
  ],
  studyclub_a: {
    default: [`A팀`, `A team`, 's_landing_promo.studyclub_a.default', ``, `A team`, `A team`, `A team`],
    date1: [
      `12/23 (금) 14:00 - 12/30 (금) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      's_landing_promo.studyclub_a.date1',
      ``,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
    ],
    date2: [
      `1/5 - 2/2 매주 목요일 저녁 8시`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      's_landing_promo.studyclub_a.date2',
      ``,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
    ],
  },
  studyclub_b: {
    default: [`B팀`, `B team`, 's_landing_promo.studyclub_b.default', ``, `B team`, `B team`, `B team`],
    date1: [
      `12/31 (토) 00:00 - 1/13 (금) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      's_landing_promo.studyclub_b.date1',
      ``,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
    ],
    date2: [
      `1/19 - 2/16 매주 목요일 저녁 8시`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      's_landing_promo.studyclub_b.date2',
      ``,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
    ],
  },
  studyclub_c: {
    default: [`C팀`, `C team`, 's_landing_promo.studyclub_c.default', ``, `C team`, `C team`, `C team`],
    date1: [
      `1/14 (토) 00:00 - 1/27 (금) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      's_landing_promo.studyclub_c.date1',
      ``,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
    ],
    date2: [
      `2/2 - 2/23 매주 목요일 저녁 8시`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      's_landing_promo.studyclub_c.date2',
      ``,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
    ],
  },
  studyclub_d: {
    default: [`D팀`, `D team`, 's_landing_promo.studyclub_d.default', ``, `D team`, `D team`, `D team`],
    date1: [
      `1/28 (토) 00:00 - 2/2 (목) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      's_landing_promo.studyclub_d.date1',
      ``,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
    ],
    date2: [
      `2/9 - 3/2 매주 목요일 저녁 8시`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      's_landing_promo.studyclub_d.date2',
      ``,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
    ],
  },
  study_en: [
    `FALSE`,
    `Study club meetings will be held in Korean.`,
    's_landing_promo.study_en',
    ``,
    `Study club meetings will be held in Korean.`,
    `Study club meetings will be held in Korean.`,
    `Study club meetings will be held in Korean.`,
  ],
  btn2: [
    `로그인하고 신청하기`,
    `Log in and Apply`,
    's_landing_promo.btn2',
    ``,
    `Log in and Apply`,
    `Log in and Apply`,
    `Log in and Apply`,
  ],
  title5_mobile: [
    <>
      영어에만 집중하세요.
      <br />
      특별한 여행은 저희가
      <br />
      책임질게요.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    's_landing_promo.title5_mobile',
    ``,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
  ],
  Challenge: [
    `추가혜택 2. 보스턴&뉴욕 챌린지 3기`,
    `Boston & NYC Challenge`,
    's_landing_promo.Challenge',
    ``,
    `Boston & NYC Challenge`,
    `Boston & NYC Challenge`,
    `Boston & NYC Challenge`,
  ],
  challenge_begins: [
    `1월 6일부터 도전이 시작돼요.`,
    `The Challenge starts on Jan 6, 2023`,
    's_landing_promo.challenge_begins',
    ``,
    `The Challenge starts on Jan 6, 2023`,
    `The Challenge starts on Jan 6, 2023`,
    `The Challenge starts on Jan 6, 2023`,
  ],
  btn_txt1: [
    `지금이 신년 영어 공부 준비하기 딱 좋은 타이밍 ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
    's_landing_promo.btn_txt1',
    `2023年用最低价格购买的机会`,
    `The Perfect Time to Start Ringle! ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
  ],
  btn_txt2: [
    `링글 신년 프로모션`,
    `New Year Promotion`,
    's_landing_promo.btn_txt2',
    `一年只有两次的特价`,
    `New Year Promotion`,
    `New Year Promotion`,
    `New Year Promotion`,
  ],
  desc5_pc: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지, 어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_pc',
    <>
      想要知道在不同的情况下要怎麽使用Ringle, 想知道怎麽学英文吗？
      <br />
      <b>和Ringle团队聊聊吧.</b>
    </>,
    ``,
    ``,
    ``,
  ],
  title6_mobile: [
    <>
      아직 망설여지나요?
      <br />
      링글팀과 직접 상담하세요.
    </>,
    ``,
    's_landing_promo.title6_mobile',
    <>
      还在犹豫吗？
      <br />
      和Ringle团队聊聊
    </>,
    ``,
    ``,
    ``,
  ],
  desc5_mobile: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지,
      <br />
      어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_mobile',
    <>
      想要知道在不同的情况下要怎麽使用Ringle, 想知道怎麽学英文吗？
      <br />
      <br />
      <br />
      <b>和Ringle团队聊聊吧.</b>
    </>,
    ``,
    ``,
    ``,
  ],
  btn8: [`궁금한 점을 남겨주세요`, ``, 's_landing_promo.btn8', `留下您的问题`, ``, ``, ``],
  tab1: [`23년 최저가`, `Best Deal`, 's_landing_promo.tab1', ``, `Best Deal`, `Best Deal`, `Best Deal`],
  tab2: [`스터디클럽`, `Study Club`, 's_landing_promo.tab2', ``, `Study Club`, `Study Club`, `Study Club`],
  tab3: [`보스턴 챌린지`, `Challenge`, 's_landing_promo.tab3', ``, `Challenge`, `Challenge`, `Challenge`],
  benefit1_subtitle: [
    `가장 저렴하게 신년 영어공부 준비`,
    `Our Lowest Prices in 2023`,
    's_landing_promo.benefit1_subtitle',
    ``,
    `Our Lowest Prices in 2023`,
    `Our Lowest Prices in 2023`,
    `Our Lowest Prices in 2023`,
  ],
  benefit1_title: [
    `최대 39% 할인`,
    `Up to 39% off`,
    's_landing_promo.benefit1_title',
    ``,
    `Up to 39% off`,
    `Up to 39% off`,
    `Up to 39% off`,
  ],
  benefit2_subtitle: [
    `신규회원 구매 시`,
    `Special Offer for Newcomers`,
    's_landing_promo.benefit2_subtitle',
    ``,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
  ],
  benefit2_title: [
    `수업권 최대 3회 추가 증정`,
    `Up to 3 free credits`,
    's_landing_promo.benefit2_title',
    ``,
    `Up to 3 free credits`,
    `Up to 3 free credits`,
    `Up to 3 free credits`,
  ],
  benefit3_subtitle: [
    `링글러들과 함께 목표 달성`,
    `Learn with Ringlers`,
    's_landing_promo.benefit3_subtitle',
    ``,
    `Learn with Ringlers`,
    `Learn with Ringlers`,
    `Learn with Ringlers`,
  ],
  benefit3_title: [
    `스터디클럽 2기`,
    `Study Club Season 2`,
    's_landing_promo.benefit3_title',
    ``,
    `Study Club Season 2`,
    `Study Club Season 2`,
    `Study Club Season 2`,
  ],
  d_day_early: [
    `프로모션 마감까지`,
    `Early Bird Ends In:`,
    's_landing_promo.d_day_early',
    `早鸟优惠截止`,
    `Early Bird Ends In:`,
    `Early Bird Ends In:`,
    `Early Bird Ends In:`,
  ],
  regular: [
    `신년 프로모션 할인 마감까지`,
    `Promotion Ends In:`,
    's_landing_promo.regular',
    `优惠活动截止`,
    `Promotion Ends In:`,
    `Promotion Ends In:`,
    `Promotion Ends In:`,
  ],
  cowntdown: [`마감까지`, `Ends in`, 's_landing_promo.cowntdown', `优惠截止`, `Ends in`, `Ends in`, `Ends in`],
  hrs: [`시간`, `Hours`, 's_landing_promo.hrs', `时`, `Hours`, `Hours`, `Hours`],
  mins: [`분`, `Minutes`, 's_landing_promo.mins', `分`, `Minutes`, `Minutes`, `Minutes`],
  secs: [`초`, `Seconds`, 's_landing_promo.secs', `秒`, `Seconds`, `Seconds`, `Seconds`],
  d_day_txt1: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt1',
    <>
      Ringle 2023年
      <br />
      以最低价格购买的机会
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
  ],
  d_day_txt2: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt2',
    <>
      一年只有两次的
      <br />
      Ringle课程券优惠
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
  ],
  subtitle_1: [
    `초중급자를 위한 링글`,
    `For Beginners/Intermediates`,
    's_landing_promo.subtitle_1',
    `给初/中级英语学习者的Ringle`,
    `For Beginners/Intermediates`,
    `For Beginners/Intermediates`,
    `For Beginners/Intermediates`,
  ],
  subtitle_2: [
    `중상급자를 위한 링글`,
    `For Advanced Learners`,
    's_landing_promo.subtitle_2',
    `给中高级英语学习者的Ringle`,
    `For Advanced Learners`,
    `For Advanced Learners`,
    `For Advanced Learners`,
  ],
  title7_pc: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면 선물이 커져요!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    's_landing_promo.title7_pc',
    ``,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
  ],
  title7_mobile: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면
      <br />
      선물이 커져요!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    's_landing_promo.title7_mobile',
    ``,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
  ],
  btn9: [
    `목표 공유하기`,
    `Share Your Study Goals`,
    's_landing_promo.btn9',
    ``,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
  ],
  subtitle7: [`참여 혜택`, `Rewards`, 's_landing_promo.subtitle7', ``, `Rewards`, `Rewards`, `Rewards`],
  share: {
    three_times: [
      <>
        <span className="text-[#FF6712]">3회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      's_landing_promo.share.three_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
    ],
    subtitle1: [
      `추운 겨울에 딱 좋은`,
      `To fend off the winter cold`,
      's_landing_promo.share.subtitle1',
      ``,
      `To fend off the winter cold`,
      `To fend off the winter cold`,
      `To fend off the winter cold`,
    ],
    title1: [
      <>
        스타벅스
        <br />
        기프티콘 1장
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      's_landing_promo.share.title1',
      ``,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
    ],
    six_times: [
      <>
        <span className="text-[#FF6712]">6회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      's_landing_promo.share.six_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
    ],
    subtitle2: [
      `이미 받은 1장에`,
      `Let's double the stakes!`,
      's_landing_promo.share.subtitle2',
      ``,
      `Let's double the stakes!`,
      `Let's double the stakes!`,
      `Let's double the stakes!`,
    ],
    title2: [
      <>
        스타벅스
        <br />
        기프티콘 추가로 2장 더
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      's_landing_promo.share.title2',
      ``,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
    ],
    ten_times: [
      <>
        <span className="text-[#FF6712]">10회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      's_landing_promo.share.ten_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
    ],
    subtitle3: [
      `위 모든 혜택에`,
      `All the rewards above PLUS`,
      's_landing_promo.share.subtitle3',
      ``,
      `All the rewards above PLUS`,
      `All the rewards above PLUS`,
      `All the rewards above PLUS`,
    ],
    title3: [
      <>
        링글 수업 4회권
        <br />
        당첨 기회까지
        <br />
      </>,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      's_landing_promo.share.title3',
      ``,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      <>
        A Chance to Win
        <br />4 Lesson Credits
      </>,
    ],
    desc: [
      `(총 2명)`,
      `(Limited to two winners)`,
      's_landing_promo.share.desc',
      ``,
      `(Limited to two winners)`,
      `(Limited to two winners)`,
      `(Limited to two winners)`,
    ],
  },
  card_subtitle: [
    `2023년 영어 공부 목표`,
    `Study Goals for 2023`,
    's_landing_promo.card_subtitle',
    ``,
    `Study Goals for 2023`,
    `Study Goals for 2023`,
    `Study Goals for 2023`,
  ],
  card_title1: [
    <>
      umm...
      <br />
      나 올훼 한국말
      <br />몬 해도 놀라지 뫄
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    's_landing_promo.card_title1',
    ``,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
  ],
  card_title2: [
    <>
      한 30년 한국말 하니까
      <br />
      좀 지겹더라구...
      <br />
      영어로 넘어가게
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    's_landing_promo.card_title2',
    ``,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
  ],
  card_title3: [
    <>
      영유 다니는 조카보다는
      <br />
      잘해보려구
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    's_landing_promo.card_title3',
    ``,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
  ],
  card_title4: [
    <>
      보여줄게
      <br />
      완전히 달라진
      <br />
      (영어 잘하는) 나
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    's_landing_promo.card_title4',
    ``,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
  ],
  card_title5: [
    <>
      실리콘밸리에 먼저
      <br />
      자리 잡고 있을게
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    's_landing_promo.card_title5',
    ``,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
  ],
  card_title6: [
    <>
      이제 파X고랑
      <br />
      헤어지려고
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    's_landing_promo.card_title6',
    ``,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
  ],
  label_earlybird_2: [
    `할인 혜택`,
    `Discounts`,
    's_landing_promo.label_earlybird_2',
    `Discounts`,
    `Discounts`,
    `Discounts`,
    `Discounts`,
  ],
  label_earlybird_3: [
    `프로모션 특가`,
    `Promotional Deal`,
    's_landing_promo.label_earlybird_3',
    `新年特价`,
    `Promotional Deal`,
    `Promotional Deal`,
    `Promotional Deal`,
  ],
  challenge_info1: [
    <>
      <b>투어 내용:</b>
      <br />
      - 항공권 + 숙박 + 프로그램 비용 포함
      <br />
      - 글로벌 테크/서비스 기업 오피스 투어 및 현직자 네트워킹
      <br />- Harvard 등에서 재학 중인 튜터와의 만남
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    's_landing_promo.challenge_info1',
    ``,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
  ],
  challenge_info2: [
    <>
      <b>챌린지 기간:</b> 23.01.06 ~ 23.04.30
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    's_landing_promo.challenge_info2',
    ``,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
  ],
  challenge_info3: [
    <>
      <b>참여 방법:</b>
      <br />
      - 챌린지 기간 내 20분 정규수업 32회, 40분 정규수업 24회 이상 정상 수강 시 챌린지 완주
      <br />
      - 챌린지 완주자에 한해 보스턴&뉴욕 여행 지원 자격 부여
      <br />
      <br />
      자세한 내용은 하단 링크 통해서 상세 페이지 확인
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    's_landing_promo.challenge_info3',
    ``,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
  ],
  btn_txt1_1: [`프로모션 가격에 추가 30만원 할인`, ``, 's_landing_promo.btn_txt1_1', ``, ``, ``, ``],
  benefit1_subtitle_1: [`가장 저렴하게 신년 영어공부 준비`, ``, 's_landing_promo.benefit1_subtitle_1', ``, ``, ``, ``],
  benefit1_title_1: [`최대 39% 할인`, ``, 's_landing_promo.benefit1_title_1', ``, ``, ``, ``],
  hero_promo_moW: [
    `23년 영어 공부, 지금 준비하세요.`,
    `Get Ready to Upgrade Your English`,
    's_landing_promo.hero_promo_moW',
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
  ],
  btn_update: [`새로고침`, `Update`, 's_landing_promo.btn_update', `Update`, `Update`, `Update`, `Update`],
  subtitle6_desc1: [
    `[목표 공유하기 버튼]으로 공유한 경우만 인정돼요.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    's_landing_promo.subtitle6_desc1',
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
  ],
  subtitle6_desc2: [
    `나와의 채팅방/같은 채팅방에 여러 번 공유는 안 돼요.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    's_landing_promo.subtitle6_desc2',
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
  ],
  subtitle6_desc3: [
    `횟수가 안 올라간다면 새로고침 버튼을 눌러주세요.`,
    `To update share count, click the [Update] button.`,
    's_landing_promo.subtitle6_desc3',
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
  ],
  label_endingsoon: [
    `마감 임박`,
    `Ending Soon`,
    's_landing_promo.label_endingsoon',
    ``,
    `Ending Soon`,
    `Ending Soon`,
    `Ending Soon`,
  ],
  benefit6_subtitle: [
    `튜터도 만나고 글로벌 회사 탐방까지`,
    `Meet Tutors and Visit Global Companies`,
    's_landing_promo.benefit6_subtitle',
    ``,
    `Meet Tutors and Visit Global Companies`,
    `Meet Tutors and Visit Global Companies`,
    `Meet Tutors and Visit Global Companies`,
  ],
  benefit6_title: [
    `보스턴&뉴욕 챌린지 3기`,
    `Boston&NYC Challenge`,
    's_landing_promo.benefit6_title',
    ``,
    `Boston&NYC Challenge`,
    `Boston&NYC Challenge`,
    `Boston&NYC Challenge`,
  ],
  benefit7_subtitle: [
    `공유할수록 선물이 더 커지는`,
    `Share Your Study Goals`,
    's_landing_promo.benefit7_subtitle',
    ``,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
  ],
  benefit7_title: [
    `영어 목표 공유 이벤트`,
    `New Year's Event`,
    's_landing_promo.benefit7_title',
    `New Year's Event`,
    `New Year's Event`,
    `New Year's Event`,
    `New Year's Event`,
  ],
  title8: {
    mobile: [
      <>
        링글이 처음이라면? <br />
        수업권 최대 3회 <br />
        받아가세요.
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      's_landing_promo.title8.mobile',
      ``,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
    ],
    pc: [
      <>
        링글이 처음이라면? <br />
        수업권 최대 3회 받아가세요.
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      's_landing_promo.title8.pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome: [`신규회원 한정 혜택`, ``, 's_landing_promo.welcome', ``, ``, ``, ``],
  desc6: [
    <>
      <li className="li-tag-inside">가입 즉시 수업권 추가 증정 쿠폰 발급</li>
      <li className="li-tag-inside">12회 이상 수업권부터 적용 가능</li>
      <li className="li-tag-inside">수업권 별로 차등 적용</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    's_landing_promo.desc6',
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
  ],
  label_regular_2: [`기간 한정`, `Season`, 's_landing_promo.label_regular_2', ``, `Season`, `Season`, `Season`],
  welcome_btn: [
    `지금 신규회원 쿠폰 확인하기`,
    `View more`,
    's_landing_promo.welcome_btn',
    `View more`,
    `View more`,
    `View more`,
    `View more`,
  ],
  welcome_title: [
    `신규회원 한정 혜택`,
    `Special Offer for Newcomers`,
    's_landing_promo.welcome_title',
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
  ],
}
export const s_app_download = {
  label: [
    <>
      링글 앱에서 <b>수업을 준비</b>하고
      <br />
      <b>다양한 콘텐츠</b>를 확인해보세요.
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    's_app_download.label',
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
  ],
  button: [
    `앱에서 링글 시작하기`,
    `Continue in App`,
    's_app_download.button',
    `Continue in App`,
    `Continue in App`,
    `Continue in App`,
    `Continue in App`,
  ],
  footer: [
    `오늘은 모바일 웹을 더 볼래요.`,
    `Continue in Browser.`,
    's_app_download.footer',
    `Continue in Browser.`,
    `Continue in Browser.`,
    `Continue in Browser.`,
    `Continue in Browser.`,
  ],
  title: [
    <>
      링글 앱에서 <b>매일 새로운 영어 콘텐츠</b>를<br />
      <b>무료로</b> 받아보실 수 있어요!
    </>,
    ``,
    's_app_download.title',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_1: [`앱에서 더보기`, ``, 's_app_download.btn_1', ``, ``, ``, ``],
  btn_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_app_download.btn_2', ``, ``, ``, ``],
}
export const s_guide_modal = {
  title: [
    `링글 수업이 어떻게 진행되는지 궁금하다면?`,
    `Curious how Ringle lessons are conducted?`,
    's_guide_modal.title',
    `您若对Ringle课程感兴趣，可以观看`,
    `您若對Ringle課程感興趣，可以查看`,
    `Ringleのレッスンについて知りたい方は`,
    `Nếu bạn đang muốn biết về buổi học của Ringle
Hãy xem trước buổi học`,
  ],
  btn: [
    `수업 미리보기`,
    `Lesson Preview`,
    's_guide_modal.btn',
    `课程预览`,
    `課程預覽`,
    `レッスンプレビュー`,
    `Hãy xem trước buổi học`,
  ],
}
export const s_guide_player = {
  title: [
    `링글 수업 가이드`,
    `Ringle Lesson Guide`,
    's_guide_player.title',
    `Ringle课程介绍`,
    `Ringle課程介紹`,
    `Ringleレッスンガイド`,
    `và hướng dẫn buổi học`,
  ],
  subtitle1: [
    `Step1. 수업 예약`,
    `Step 1. Book Lesson`,
    's_guide_player.subtitle1',
    `Step1. 预约`,
    `Step1. 預約`,
    `Step1. レッスン予約`,
    `Step1. Đặt lịch`,
  ],
  subtitle2: [
    `Step2. 예습 & 수업`,
    `Step 2. Prep & Lesson`,
    's_guide_player.subtitle2',
    `Step2. 预习`,
    `Step2. 預習`,
    `Step2. 予習 & レッスン`,
    `Step2. Chuẩn bị & buổi học`,
  ],
  subtitle3: [
    `Step3. 복습`,
    `Step 3. Review`,
    's_guide_player.subtitle3',
    `Step3. 复习`,
    `Step3. 複習`,
    `Step3. 復習`,
    `Step3. Đánh giá buổi học`,
  ],
  desc: [
    `가이드 영상은 Help Center에서도 시청이 가능합니다.`,
    `You can also watch the guide video at the Help Center.`,
    's_guide_player.desc',
    `介绍视频也可以在Help Center中观看。`,
    `介紹視頻也可以在Help Center中觀看。`,
    `ガイド映像はHelpCenterでもご覧いただけます。`,
    `hay bạn có thể xem video hướng dẫn trong Help Center`,
  ],
}
export const s_guide_dialog = {
  desc: [
    <>
      가이드 영상은 도움이 되셨나요? 이제 링글 수업을 시작해보세요!
      <br />
      해당 영상은 Help Center 에서 다시 시청 할 수 있습니다.
    </>,
    <>Was the guide video helpful? Start a Ringle lesson now! You can watch this video again at the Help Center.</>,
    's_guide_dialog.desc',
    <>
      观看介绍视频，对您是否有帮助呢？那就开始Ringle课程吧！
      <br /> 该视频可以在 Help Center中再次观看。
    </>,
    <>
      看完介紹視頻之後，對您是否有幫助呢？那就開始Ringle課程吧！
      <br /> 該視頻可以在 Help Center中再次觀看。
    </>,
    <>
      ガイド映像は役に立ちましたか？ではリRingleのレッスンを始めてみましょう。
      <br />
      この映像はヘルプセンターから再視聴が可能です。
    </>,
    <>
      Video hướng dẫn có bổ ích không? Bây giờ thử bắt đầu buổi học Ringle nhé. <br /> Bạn có thể xem lại video này
      trong Help Center
    </>,
  ],
  btn: [`확인`, `Next`, 's_guide_dialog.btn', `确认`, `確認`, `確認`, `Ok`],
}
export const s_b2b_credit_attandance = {
  b2b_titile: [
    `B2B 수업권 출석률`,
    `B2B Lessons Attendance Rate`,
    's_b2b_credit_attandance.b2b_titile',
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
  ],
  b2b_card_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance.b2b_card_title',
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
  ],
  attandance: [
    `출석률`,
    `Attendance Rate`,
    's_b2b_credit_attandance.attandance',
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
  ],
  rate: (percent) => [
    <>{percent}%</>,
    <>{percent}%</>,
    's_b2b_credit_attandance.rate',
    <>{percent}%</>,
    <>{percent}%</>,
    <>{percent}%</>,
    <>{percent}%</>,
  ],
  off_track: [
    `출석률 미달`,
    `Off Track`,
    's_b2b_credit_attandance.off_track',
    `Off Track`,
    `Off Track`,
    `Off Track`,
    `Off Track`,
  ],
  see_detail: [
    `상세현황`,
    `See detail`,
    's_b2b_credit_attandance.see_detail',
    `See detail`,
    `See detail`,
    `See detail`,
    `See detail`,
  ],
  desc1: [
    `출석 수업 수`,
    `Number of lessons attended`,
    's_b2b_credit_attandance.desc1',
    `Number of lessons attended`,
    `Number of lessons attended`,
    `Number of lessons attended`,
    `Number of lessons attended`,
  ],
  count_1: (count) => [
    <>{count}</>,
    <>{count}</>,
    's_b2b_credit_attandance.count_1',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  complete: (count) => [
    <>회 완료/{count}회</>,
    <>/{count}</>,
    's_b2b_credit_attandance.complete',
    <>/{count}</>,
    <>/{count}</>,
    <>/{count}</>,
    <>/{count}</>,
  ],
  desc2: [
    `이수 기준 수업 수`,
    `Minimum number of lessons required`,
    's_b2b_credit_attandance.desc2',
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
  ],
  count_2: (count) => [
    <>{count}</>,
    `{count}`,
    's_b2b_credit_attandance.count_2',
    `{count}`,
    `{count}`,
    `{count}`,
    `{count}`,
  ],
  credits: [`회`, ``, 's_b2b_credit_attandance.credits', ``, ``, ``, ``],
  desc3: [
    `남은 수업권`,
    `Available`,
    's_b2b_credit_attandance.desc3',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  regular_credits: (count) => [
    <>정규{count}회</>,
    <>Regular {count}</>,
    's_b2b_credit_attandance.regular_credits',
    <>Regular {count}</>,
    <>Regular {count}</>,
    <>Regular {count}</>,
    <>Regular {count}</>,
  ],
  free_credits: (count) => [
    <>당일{count}회</>,
    <>Last-minute {count}</>,
    's_b2b_credit_attandance.free_credits',
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
  ],
  desc4: [
    `수강 기간`,
    `Expires in`,
    's_b2b_credit_attandance.desc4',
    `Expires in`,
    `Expires in`,
    `Expires in`,
    `Expires in`,
  ],
  count_3: (day) => [
    <>{day}</>,
    <>{day}</>,
    's_b2b_credit_attandance.count_3',
    <>{day}</>,
    <>{day}</>,
    <>{day}</>,
    <>{day}</>,
  ],
  days: [`일 남음`, `days`, 's_b2b_credit_attandance.days', `days`, `days`, `days`, `days`],
  modal_title: [
    `출석률 계산법`,
    `Calculation of attendance rate`,
    's_b2b_credit_attandance.modal_title',
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
  ],
  modal_desc: [
    <>
      1. 수업 시간 50% 이상을 진행 해야만 출석으로 인정됩니다.
      <br />
      예) 40분 수업의 경우 20분 진행
      <br />
      <br />
      2. 해당 과정의 정규 수업권 및 당일 수업권으로 진행한 수업만 출석률 계산에 반영합니다.
      <br />
      보상 수업권*, 이전 과정에서 발급된 수업권, 개인적으로 구매한 수업권 등을 이용해 진행한 수업은 해당 과정의 출석률
      계산에 반영하지 않습니다.
      <br />
      <br />
      3. 튜터가 불가피한 사유로 수업 시작 24시간 이내 취소한 수업과 노쇼한 수업은 출석 인정됩니다.
      <br />
      <span className="text-[14px]">
        *보상 수업권은 튜터가 노쇼하거나 24시간 이내 취소한 수업, 링글 시스템 이슈로 원활하게 진행이 안된 수업 등에 대해
        보상의 의미로 지급하는 무료 수업권입니다.
      </span>
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    's_b2b_credit_attandance.modal_desc',
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
  ],
  btn: [`확인`, `OK`, 's_b2b_credit_attandance.btn', `OK`, `OK`, `OK`, `OK`],
}
export const s_b2b_credit_attandance_detail = {
  b2b_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance_detail.b2b_title',
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
  ],
  subtitle_1: (week) => [
    <>{week}주차 출석률 현황</>,
    <>Attendance rate as of {week} </>,
    's_b2b_credit_attandance_detail.subtitle_1',
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
  ],
  label_1: [
    `OFF Track`,
    `OFF Track`,
    's_b2b_credit_attandance_detail.label_1',
    `OFF Track`,
    `OFF Track`,
    `OFF Track`,
    `OFF Track`,
  ],
  label_2: [
    `On Track`,
    `On Track`,
    's_b2b_credit_attandance_detail.label_2',
    `On Track`,
    `On Track`,
    `On Track`,
    `On Track`,
  ],
  label_3: [
    `Complete!`,
    `Complete!`,
    's_b2b_credit_attandance_detail.label_3',
    `Complete!`,
    `Complete!`,
    `Complete!`,
    `Complete!`,
  ],
  comment_1: (count) => [
    <>수강 첫 주입니다! 매주 {count}회 이상의 수업을 권장하고 있어요.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    's_b2b_credit_attandance_detail.comment_1',
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
  ],
  comment_2: [
    `잘하고 있어요!  꾸준히 수업하는 것이 영어 실력 향상의 지름길이에요.`,
    `You're doing great! Consistency is the key to improving your English.`,
    's_b2b_credit_attandance_detail.comment_2',
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
  ],
  comment_3: (count) => [
    <>기간 내 완강을 위해 {count}회 이상의 권장 수업 횟수를 채워주세요. </>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    's_b2b_credit_attandance_detail.comment_3',
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
  ],
  comment_4: [
    `완강을 축하드립니다! 노력한 만큼 영어 실력이 성장했을 거예요.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    's_b2b_credit_attandance_detail.comment_4',
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
  ],
  bar_title: [
    `수업 완료 횟수`,
    `Number of lessons completed`,
    's_b2b_credit_attandance_detail.bar_title',
    `Number of lessons completed`,
    `Number of lessons completed`,
    `Number of lessons completed`,
    `Number of lessons completed`,
  ],
  my_attandance: (percent) => [
    <>현재 출석률 {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    's_b2b_credit_attandance_detail.my_attandance',
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
  ],
  b2b_attandance: (week, percent) => [
    <>
      {week}주차 권장 출석률 {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    's_b2b_credit_attandance_detail.b2b_attandance',
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
  ],
  bar_complete: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    's_b2b_credit_attandance_detail.bar_complete',
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
  ],
  subtitle_2: [
    `수업 예약하기`,
    `Book a Lesson`,
    's_b2b_credit_attandance_detail.subtitle_2',
    `Book a Lesson`,
    `Book a Lesson`,
    `Book a Lesson`,
    `Book a Lesson`,
  ],
  desc1: (count) => [
    <>주당 {count}회 이상 수업 권장</>,
    <>You should complete at least {count} lessons per week</>,
    's_b2b_credit_attandance_detail.desc1',
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
  ],
  expire_day: (exp_days, deadline) => [
    <>
      수강기간: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    's_b2b_credit_attandance_detail.expire_day',
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
  ],
  b2b_lessons_credit: (name) => [
    <>{name} 정규 수업권</>,
    <>{name}</>,
    's_b2b_credit_attandance_detail.b2b_lessons_credit',
    <>{name}</>,
    <>{name}</>,
    <>{name}</>,
    <>{name}</>,
  ],
  available_1: [
    `남은 횟수`,
    `Available`,
    's_b2b_credit_attandance_detail.available_1',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  count_1: (count) => [
    <>{count}회</>,
    <>{count}</>,
    's_b2b_credit_attandance_detail.count_1',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  btn_1: [
    `시간 먼저 선택`,
    `Sort by time`,
    's_b2b_credit_attandance_detail.btn_1',
    `Sort by time`,
    `Sort by time`,
    `Sort by time`,
    `Sort by time`,
  ],
  btn_2: [
    `튜터 먼저 선택`,
    `Sort by tutor`,
    's_b2b_credit_attandance_detail.btn_2',
    `Sort by tutor`,
    `Sort by tutor`,
    `Sort by tutor`,
    `Sort by tutor`,
  ],
  b2b_lessons_free_credit: [
    `B2B 당일 수업권(수업취소)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    's_b2b_credit_attandance_detail.b2b_lessons_free_credit',
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
  ],
  available_2: [
    `남은 횟수`,
    `Available`,
    's_b2b_credit_attandance_detail.available_2',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  count_2: (count) => [
    <>{count}회</>,
    <>{count}</>,
    's_b2b_credit_attandance_detail.count_2',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  btn_3: [
    `당일 수업 예약`,
    `Last-minute Lessons`,
    's_b2b_credit_attandance_detail.btn_3',
    `Last-minute Lessons`,
    `Last-minute Lessons`,
    `Last-minute Lessons`,
    `Last-minute Lessons`,
  ],
}
export const s_daily_brief = {
  notice: [
    `Daily Brief가 ‘새로나온 교재'로 병합되었습니다. 홈 화면의 '새로나온 교재'로 매일 신규 콘텐츠를 만나보세요!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] on the homepage to access new contents every day!`,
    's_daily_brief.notice',
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
  ],
  button: [`안내문 보기`, `Learn more`, 's_daily_brief.button', `Learn more`, `Learn more`, `Learn more`, `Learn more`],
}
export const s_landing_webinar = {
  register_modal_title: [
    `신청이 완료되었어요!`,
    `Your application is complete!`,
    's_landing_webinar.register_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  register_modal_text: [
    `LIVE 웨비나는 앱을 설치하시거나 PC에서 접속하신 후 입장하실 수 있어요.`,
    `You can enter the LIVE webinar from your computer or the Ringle mobile app`,
    's_landing_webinar.register_modal_text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_unassigned_lesson = {
  tooltip: [
    `당일수업 예약 시에도 교재를 선택할 수 있습니다.`,
    `You can also select the lesson material for last-minute lessons.`,
    's_unassigned_lesson.tooltip',
    `预约当日课程也可以选择教材。`,
    `預約當日課程也可以選擇教材。`,
    `当日レッスン予約時にも教材を選択することができます。`,
    `Bạn có thể chọn tài liệu kể cả khi đặt buổi học trong ngày.`,
  ],
  inform: {
    topic_of_this_lesson_will_be: [
      <>
        당일 수업은 24시간 이내 시작하는 수업으로, 수업까지 남은 시간에 따라 선택할 수 있는 교재가 달라집니다.
        <br />- 수업 시작 기준 1시간 이전 : 모든 교재
        <br />- 수업 시작 기준 1시간 이내: '자유주제' 또는 '튜터가 이전에 수업한 교재'
      </>,
      <>
        For last-minute lessons, you can select lesson materials as follows:
        <br />
        -Up until 1 hour before lesson begins: Any available lesson material
        <br />
        -Within one hour before lesson begins: 'Free Topic' or 'Tutor's Previously Taught Materials'
      </>,
      's_unassigned_lesson.inform.topic_of_this_lesson_will_be',
      <>
        24小时内开始的课程，可以选择教材。
        <br />
        -上课前1小时:可选择所有教材。
        <br />
        -上课前1小时内：可选择"自由主题”或"任课教师先前授课的教材”。
      </>,
      <>
        24小時內開始的課程，可以選擇教材。
        <br />
        -授業開始1時間前:すべての教材。
        <br />- 上課前1小時內：可選擇"自由主題”或"任課教師先前授課的教材”。
      </>,
      <>
        24時間以内に開始するレッスンとして教材の選択が可能です。
        <br />
        -授業開始1時間前:すべての教材。
        <br />
        ‐レッスン開始1時間以内：「自由な主題」または「チューターが以前レッスンした教材」を選択可能。
      </>,
      <>
        Bạn có thể chọn tài liệu với buổi học bắt đầu trong vòng 24 giờ.
        <br />- Trước giờ học 1 tiếng: tất cả sách giáo khoa.
        <br />- Trong vòng 1 giờ trước khi bắt đầu buổi học: bạn có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử
        dụng trước đây”.
      </>,
    ],
    title: [
      `당일수업 관련 안내`,
      `About Last-minute Lessons`,
      's_unassigned_lesson.inform.title',
      `关于当日课程`,
      `關於當日課程`,
      `当日レッスンのご案内`,
      `Về tiết học thay đổi phút chót`,
    ],
    topic_of_this_lesson_will_be_new: [
      <>
        당일 수업은 24시간 이내 시작하는 수업으로, 수업까지 남은 시간에 따라 선택할 수 있는 교재가 달라집니다.
        <li>수업 시작 기준 1시간 이전 : 모든 교재</li>
        <li> 수업 시작 기준 1시간 이내: '자유주제' 또는 '튜터가 이전에 수업한 교재'</li>
        <li> 정규수업권으로 예약하는 경우 포인트가 추가 적립됩니다. (40분: ￦3,000P / 20분: ￦1,000P) </li>
      </>,
      <>
        For last-minute lessons, you can select lesson materials as follows:
        <li>Up until 1 hour before lesson begins: Any available lesson material</li>
        <li>Within one hour before lesson begins: 'Free Topic' or 'Tutor's Previously Taught Materials'</li>
      </>,
      's_unassigned_lesson.inform.topic_of_this_lesson_will_be_new',
      <>
        24小时内开始的课程，可以选择教材。<li>上课前1小时:可选择所有教材。</li>
        <li>上课前1小时内：可选择"自由主题”或"任课教师先前授课的教材”。</li>
      </>,
      <>
        24小時內開始的課程，可以選擇教材。<li>授業開始1時間前:すべての教材。</li>
        <li>上課前1小時內：可選擇"自由主題”或"任課教師先前授課的教材”。</li>
      </>,
      <>
        当日レッスンは24時間以内に開始するレッスンであり。、レッスンまでの時間により選べる教材が異なります。
        <li>レッスン開始1時間以前：すべての教材</li>
        <li>レッスン開始1時間以内：「自由テーマ」又は「チューターが以前レッスンした教材」</li>
        <li>レギュラーレッスンチケットで予約の場合、ポイントが追加されます。(40分：￦3,000P / 20分：￦1,000P) </li>
      </>,
      <>
        Bạn có thể chọn tài liệu với buổi học bắt đầu trong vòng 24 giờ.
        <li> Trước giờ học 1 tiếng: tất cả sách giáo khoa.</li>
        <li>
          Trong vòng 1 giờ trước khi bắt đầu buổi học: bạn có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử dụng
          trước đây”.
        </li>
      </>,
    ],
  },
  reservation_success_info: [
    <>
      당일수업은 튜터 및 시간 변경이 불가합니다.
      <br />
      당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.
    </>,
    <>
      You cannot change the tutor or time slot for last-minute lessons.
      <br />
      The credit used for booking the last-minute lesson will not be returned even if the lesson is cancelled or missed
      (no-show).
    </>,
    's_unassigned_lesson.reservation_success_info',
    <>
      当日课程不可以更改课教师和时间。
      <br />
      取消当日课程或缺席时，将扣除课程券。
    </>,
    <>
      當日課程不可以更改課教師和時間。
      <br />
      取消當日課程或缺席時，將扣除課程券。
    </>,
    <>
      当日レッスンはチューターおよび時間の変更ができません。
      <br />
      当日レッスンはレッスンキャンセルおよび無断欠席時にレッスンチケットが差し引かれます。
    </>,
    <>
      Với Buổi học trong ngày, bạn không thể thay đổi gia sư và thời gian học.
      <br />
      Với Buổi học trong ngày, thẻ học vẫn bị trừ khi hủy buổi học hoặc không tham gia.
    </>,
  ],
  reservation_failure_info: [
    `예약을 실패하였습니다. 다시 수업을 예약해주세요.`,
    `Your booking didn't go through. Please try booking a different lesson.`,
    's_unassigned_lesson.reservation_failure_info',
    `预约未成功。请重新预约课程。`,
    `預約未成功。請重新預約課程。`,
    `予約に失敗しました。もう一度レッスンを予約してください。`,
    `Đặt buổi học không thành công. Hãy đặt lại buổi học.`,
  ],
  time_filter_title: [
    `수업 시작 시간`,
    `Time Slot`,
    's_unassigned_lesson.time_filter_title',
    `课程开始时间`,
    `課程開始時間`,
    `レッスンの開始時間`,
    `Lịch trống`,
  ],
  time_filter_all: [`전체`, `All`, 's_unassigned_lesson.time_filter_all', `全部`, `全部`, `全て`, `Tất cả`],
  lesson_list_blank_info: [
    <>
      해당 시간에 신청 가능한 수업이 없습니다.
      <br />
      다른 수업 시작 시간을 선택해주세요.
    </>,
    <>
      There are no lessons available in this time slot. <br />
      Please try choosing a different time slot.
    </>,
    's_unassigned_lesson.lesson_list_blank_info',
    <>
      所选时间已无课程，
      <br /> 请重新选择开始时间。
    </>,
    <>
      所選時間已無課程，
      <br />
      請重新選擇開始時間。
    </>,
    <>
      この時間には予約可能なレッスンがありません。
      <br />
      別の時間を選択してください。
    </>,
    <>
      Không có thời gian trống, <br />
      mời bạn chọn lịch học khác.{' '}
    </>,
  ],
}
export const s_recommended_tutor = {
  notice: [
    `어떤 튜터와 수업할지 고민 중이라면?`,
    `Searching for a tutor?`,
    's_recommended_tutor.notice',
    `还在苦恼和哪位教师上课？`,
    `還在苦惱要和哪位教師上課？`,
    `どんなチューターと会うか悩んでいるなら`,
    `Bạn không biết chọn gia sư nào?`,
  ],
  tag1: [`#높은평점`, `#HighlyRated`, 's_recommended_tutor.tag1', `#高评分`, `#高評分`, `#HighlyRated`, `#HighlyRated`],
  tag2: [
    `#링글팀pick`,
    `#RingleTeamPick`,
    's_recommended_tutor.tag2',
    `#Ringle团队推荐`,
    `#Ringle團隊推薦`,
    `#RingleTeamPick`,
    `#RingleTeamPick`,
  ],
  tag3: [
    `#불만족시수업권복구`,
    `#GetCreditBack`,
    's_recommended_tutor.tag3',
    `#不满意课程时恢复课程券使用`,
    `#不滿意課程時重新補發課程券`,
    `#GetCreditBack`,
    `#GetCreditBack`,
  ],
  desc1: [
    `일정 기간 높은 평점과 긍정적인 리뷰를 유지하는 튜터입니다.`,
    `These tutors have maintained high ratings and received positive reviews from students for a given period.`,
    's_recommended_tutor.desc1',
    `在一定的时间内保持较高评分和积极评价的任课教师。`,
    `在一定的時間內維持較高評分和正面評價的任課教師。`,
    `一定期間高い評価と良いレビューを維持しているチューターです。`,
    `Một gia sư duy trì xếp hạng cao và đánh giá tích cực trong một khoảng thời gian.`,
  ],
  desc2: [
    `링글팀이 직접 수업을 듣고 수업 진행 및 교정 능력이 뛰어난 튜터를 선발했습니다.`,
    `The list also includes tutors recommended by members of the Ringle team who personally took lessons from them.`,
    's_recommended_tutor.desc2',
    `Ringle团队亲自上课，筛选了授课和纠正能力强的任课教师。`,
    `Ringle團隊親自上課，篩選授課及糾正能力強的任課教師。`,
    `Ringleチームがレッスンを受け、優れているチューターを選定。`,
    `Đội Ringle chúng tôi đã tham gia tiết học và tuyển chọn được vị gia sư xuất sắc.`,
  ],
  desc3: [
    `새로 온 튜터 또는 추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
    `Credit restored if unsatisfied with the Rookie/Featured Tutor. (Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit)`,
    's_recommended_tutor.desc3',
    `对新教师或推荐教师的课程不满意时，将退还课程券。 （用付費课程券预约后，教师评价分数不足3分时提交原因，每月限2次）`,
    `對新教師或推教師的課程不滿意時，將退還課程券。 （用付費課程券預約後，教師評價分數不足3分時並提出原因，每月限2次）`,
    `おすすめもしくは新規チューターのレッスンに満足できなかったら、レッスンチケットを復旧いたします。（有料レッスンチケットで予約、評価３点以下および理由提出、月２回限定）`,
    `Nếu không hài lòng với buổi học của gia sư mới/ gia sư đề cử, phiếu học sẽ được hoàn. (Tối đa 2 lần/ tháng cho buổi học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
  ],
  desc0: [
    `링글에 새로 온 튜터 또는 링글팀의 추천 튜터를 만나보세요.`,
    `Try out our Rookie/Featured Tutors.`,
    's_recommended_tutor.desc0',
    `不妨来试试新教师Ringle的推荐教師。`,
    `不妨來試試新教師或Ringle的推薦教師。`,
    `おすすめの/新規チューターと一緒に!`,
    `Chọn gia sư mới/ gia sư Ringle đề cử`,
  ],
  desc4: [
    `새로 온 튜터와 추천 튜터와의 수업 혜택은 현재 베타 운영중입니다.`,
    `The Rookie/Featured Tutor benefits are currently being beta-tested.`,
    's_recommended_tutor.desc4',
    `新教师和推荐教師的课程优惠进行中`,
    `新教師和推薦教師的課程優惠進行中！`,
    `新しく来たチューターとおすすめチューターとのレッスン特典は現在ベータ運営中です。`,
    `Ưu đãi buổi học với gia sư mới và gia sư đề cử hiện đang được thử nghiệm`,
  ],
}
export const s_contents_material_ad = {
  title_1: [
    `지금 무료로 회원가입 하고
콘텐츠를 끝까지 읽어보세요!`,
    ``,
    's_contents_material_ad.title_1',
    ``,
    ``,
    ``,
    ``,
  ],
  title_2: [
    `지금 무료로 회원가입 하고
한글 번역본으로 읽어보세요!`,
    ``,
    's_contents_material_ad.title_2',
    ``,
    ``,
    ``,
    ``,
  ],
  title_3: [
    `지금 무료로 회원가입 하고
수업질문을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_3',
    ``,
    ``,
    ``,
    ``,
  ],
  title_4: [
    `지금 무료로 회원가입 하고
핵심표현을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_4',
    ``,
    ``,
    ``,
    ``,
  ],
  title_5: [
    `링글 관련 콘텐츠는
앱에서 이용하실 수 있습니다.`,
    ``,
    's_contents_material_ad.title_5',
    ``,
    ``,
    ``,
    ``,
  ],
  title_6: [
    `링글 앱에서 더 많은 교재를
무료로 확인해보세요.`,
    ``,
    's_contents_material_ad.title_6',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_sign_up: [`회원가입 하기`, ``, 's_contents_material_ad.btn_sign_up', ``, ``, ``, ``],
  btn_login: [`로그인`, ``, 's_contents_material_ad.btn_login', ``, ``, ``, ``],
  subtitle: [`이미 회원가입 하셨다면?`, ``, 's_contents_material_ad.subtitle', ``, ``, ``, ``],
  subtitle_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_contents_material_ad.subtitle_2', ``, ``, ``, ``],
  tooltip: [
    `지금 무료로 회원가입 하고, 한글 번역본과 함께 읽어보세요!`,
    ``,
    's_contents_material_ad.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  see_detail: [`자세히 보기`, ``, 's_contents_material_ad.see_detail', ``, ``, ``, ``],
  btn_start_app: [`앱에서 링글 시작하기`, ``, 's_contents_material_ad.btn_start_app', ``, ``, ``, ``],
  title_7: [
    `지금 무료로 회원가입하고
링글의 콘텐츠를 이용해 보세요.`,
    ``,
    's_contents_material_ad.title_7',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_purcahse = {
  note_2card: [`2개 카드로 분할 결제시 일시불 결제만 가능`, ``, 's_purcahse.note_2card', ``, ``, ``, ``],
}
export const s_bn_challenge = {
  hero: {
    title: [
      `링글 보스턴&뉴욕 챌린지`,
      `Ringle Boston & NYC Challenge`,
      's_bn_challenge.hero.title',
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
    ],
    subtitle_1: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해`,
      `For you to get a jump-start on studying English`,
      's_bn_challenge.hero.subtitle_1',
      `为了不能再推迟英语学习的你`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    subtitle_2: [
      `링글과 함께하는 세 번째 도전`,
      `The 3rd Challenge with Ringle`,
      's_bn_challenge.hero.subtitle_2',
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
    ],
    date_start: [
      `1. 6(금)`,
      `Jan 6 (Fri)`,
      's_bn_challenge.hero.date_start',
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
    ],
    date_end: [
      `4. 30 (일)`,
      `Apr 30 (Sun)`,
      's_bn_challenge.hero.date_end',
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
    ],
    participants: (number) => [
      <>{number}명 참여중</>,
      <>{number} challengers</>,
      's_bn_challenge.hero.participants',
      <>{number} challengers</>,
      <>{number} challengers</>,
      <>{number} challengers</>,
      <>{number} challengers</>,
    ],
    btn_1: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.hero.btn_1',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    btn_2: [
      `챌린지 참여 중`,
      `Challenge in Progress`,
      's_bn_challenge.hero.btn_2',
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
    ],
    btn_3: [
      `챌린지 종료`,
      `Challenge Closed`,
      's_bn_challenge.hero.btn_3',
      `Challenge Closed`,
      `Challenge Closed`,
      `Challenge Closed`,
      `Challenge Closed`,
    ],
  },
  modal: {
    title: [
      `보스턴&뉴욕 챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.modal.title',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    desc: [
      `[챌린지 참여하기]를 클릭하면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      's_bn_challenge.modal.desc',
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
    ],
    btn_1: [`취소`, `Cancel`, 's_bn_challenge.modal.btn_1', `Cancel`, `Cancel`, `Cancel`, `Cancel`],
    btn_2: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.modal.btn_2',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
  },
  progress: {
    title: [
      `나의 챌린지 현황`,
      `My Challenge Board`,
      's_bn_challenge.progress.title',
      `My Challenge Board`,
      `My Challenge Board`,
      `My Challenge Board`,
      `My Challenge Board`,
    ],
    toggle_40: [`40분`, `40 min`, 's_bn_challenge.progress.toggle_40', `41 min`, `42 min`, `43 min`, `44 min`],
    toggle_20: [`20분`, `20 min`, 's_bn_challenge.progress.toggle_20', `21 min`, `22 min`, `23 min`, `24 min`],
    subtitle_1: [
      `챌린지 목표`,
      `The Challenge`,
      's_bn_challenge.progress.subtitle_1',
      `The Challenge`,
      `The Challenge`,
      `The Challenge`,
      `The Challenge`,
    ],
    number_1_40: (number) => [
      <>{number} /24회 수업</>,
      <>{number} /24 lessons</>,
      's_bn_challenge.progress.number_1_40',
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
    ],
    number_1_20: (number) => [
      <>{number} /32회 수업</>,
      <>{number} /32 lessons</>,
      's_bn_challenge.progress.number_1_20',
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
    ],
    number_1_complete: [
      `챌린지 달성!`,
      `Completed!`,
      's_bn_challenge.progress.number_1_complete',
      `Completed!`,
      `Completed!`,
      `Completed!`,
      `Completed!`,
    ],
    number_2_40: (number) => [
      <>{number} /6회 수업</>,
      <>{number} /6 lessons</>,
      's_bn_challenge.progress.number_2_40',
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
    ],
    subtitle_2: [
      `월별 수업 미션`,
      `Monthly Mission`,
      's_bn_challenge.progress.subtitle_2',
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
    ],
    number_2_20: (number) => [
      <>{number} /8회 수업</>,
      <>{number} /8 lessons</>,
      's_bn_challenge.progress.number_2_20',
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
    ],
    number_2_complete: [
      `미션 성공`,
      `Completed!`,
      's_bn_challenge.progress.number_2_complete',
      `Completed!`,
      `Completed!`,
      `Completed!`,
      `Completed!`,
    ],
    subtitle_3: [
      `함께 도전중인 링글러`,
      `Total Challengers`,
      's_bn_challenge.progress.subtitle_3',
      `Total Challengers`,
      `Total Challengers`,
      `Total Challengers`,
      `Total Challengers`,
    ],
    number_3: (number) => [
      <>{number}명</>,
      <>{number} Ringlers</>,
      's_bn_challenge.progress.number_3',
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
    ],
    point_gained: [
      `받은 포인트`,
      `Points received`,
      's_bn_challenge.progress.point_gained',
      `Points received`,
      `Points received`,
      `Points received`,
      `Points received`,
    ],
    point_40: (point) => [
      <>{point}/40,000 P</>,
      <>{point}/$30.77P</>,
      's_bn_challenge.progress.point_40',
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
    ],
    point_20: (point) => [
      <>{point}/40,000 P</>,
      <>{point}/$30.77P</>,
      's_bn_challenge.progress.point_20',
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
      <>{point}/$30.77P</>,
    ],
    date_end: [
      `챌린지 종료일 2023년 4월 30일 KST`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      's_bn_challenge.progress.date_end',
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
    ],
    btn_1: [
      `포인트 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2: [`적립 완료`, `Awarded`, 's_bn_challenge.progress.btn_2', `Awarded`, `Awarded`, `Awarded`, `Awarded`],
    btn_500: [
      `500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_1000: [
      `1000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_1500: [
      `1500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2000: [
      `2000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_2000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2500: [
      `2500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_2500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_3000: [
      `3000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_3000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_3500: [
      `3500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_3500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_4000: [
      `4000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_4000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    complete: [
      `신청서 제출`,
      `Submit Form`,
      's_bn_challenge.progress.complete',
      `Submit Form`,
      `Submit Form`,
      `Submit Form`,
      `Submit Form`,
    ],
    btn_point: (point) => [
      <>{point}P 받기</>,
      `Get points`,
      's_bn_challenge.progress.btn_point',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
  },
  extra_mission: {
    title: [
      `추가 미션도 놓치지 마세요!`,
      `Extra missions!`,
      's_bn_challenge.extra_mission.title',
      `Extra missions!`,
      `Extra missions!`,
      `Extra missions!`,
      `Extra missions!`,
    ],
    subtitle_1: [
      `2023 영어 목표 남기기`,
      `Submit Your 2023 Study Goals`,
      's_bn_challenge.extra_mission.subtitle_1',
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
    ],
    desc_1: [
      `전원) 5,000 포인트 100% 증정, 추첨 100명) 스타벅스 기프티콘 증정`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      's_bn_challenge.extra_mission.desc_1',
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
    ],
    subtitle_2: [
      `SNS 후기 인증하기`,
      `Post a Review on SNS`,
      's_bn_challenge.extra_mission.subtitle_2',
      `Post a Review on SNS`,
      `Post a Review on SNS`,
      `Post a Review on SNS`,
      `Post a Review on SNS`,
    ],
    desc_2: [
      `최대 100명) 2만원 교보문고 상품권 증정, 추첨 5명) 20분 수업권 3회 증정`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      's_bn_challenge.extra_mission.desc_2',
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.extra_mission.btn_1', `Submit`, `Submit`, `Submit`, `Submit`],
    btn_2: [`인증하기`, `Upload`, 's_bn_challenge.extra_mission.btn_2', `Upload`, `Upload`, `Upload`, `Upload`],
    btn3: [`완료`, `Complete`, 's_bn_challenge.extra_mission.btn3', `Complete`, `Complete`, `Complete`, `Complete`],
    btn4: [`인증완료`, `Complete`, 's_bn_challenge.extra_mission.btn4', `Complete`, `Complete`, `Complete`, `Complete`],
  },
  goal: {
    title: [
      `2023년 영어를 정복한다면 무엇을 하고 싶나요?`,
      `What are your dreams after mastering English in 2023?`,
      's_bn_challenge.goal.title',
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
    ],
    title_input: [
      `지금 영어 목표를 남기고 현실로 만들어봐요!`,
      `Submit your study goals and make it come true!`,
      's_bn_challenge.goal.title_input',
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
    ],
    placeholder: [
      `한 번 남긴 다짐은 수정할 수 없어요. (최소 10자)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      's_bn_challenge.goal.placeholder',
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.goal.btn_1', `Submit`, `Submit`, `Submit`, `Submit`],
    title_participate: [
      `챌린지 참여 후 나의 영어 목표를 남길 수 있어요.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.goal.title_participate',
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
    ],
    btn_2: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.goal.btn_2',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    reward_1: [
      `영어 목표를 남기면 5,000 포인트를 드려요.`,
      `Get 4.00P by submitting your study goals.`,
      's_bn_challenge.goal.reward_1',
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
    ],
    reward_2: [
      `100분을 추첨하여 스타벅스 기프티콘도 드려요.`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      's_bn_challenge.goal.reward_2',
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
    ],
    title_my_box: [
      `나의 영어 목표`,
      `My Study Goals`,
      's_bn_challenge.goal.title_my_box',
      `My Study Goals`,
      `My Study Goals`,
      `My Study Goals`,
      `My Study Goals`,
    ],
    title_box: (number) => [
      <>{number}번째 영어 목표</>,
      <>Study Goal {number}</>,
      's_bn_challenge.goal.title_box',
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
    ],
    title_admin: [
      `링글팀이 선정한 최고의 영어 목표!`,
      `Ringle Team's Choice`,
      's_bn_challenge.goal.title_admin',
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
    ],
    view_more: [`더 보기`, `See more`, 's_bn_challenge.goal.view_more', `See more`, `See more`, `See more`, `See more`],
    msg_error: [
      `10자 이상 200자 이내로 입력해 주세요.`,
      `Please enter between 10 and 200 characters.`,
      's_bn_challenge.goal.msg_error',
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
    ],
  },
  last_challenge: {
    title: [
      `링글 실리콘밸리 챌린지 1기`,
      `Ringle Silicon Valley Challenge`,
      's_bn_challenge.last_challenge.title',
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
    ],
    subtitle: [
      `이전 챌린지 돌아보기`,
      `Take a look at previous challenges`,
      's_bn_challenge.last_challenge.subtitle',
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
    ],
    btn_1: [
      `챌린지 영상 더 보기`,
      `View more`,
      's_bn_challenge.last_challenge.btn_1',
      `View more`,
      `View more`,
      `View more`,
      `View more`,
    ],
    btn_2: [
      `챌린지 2기 후기 읽기`,
      `Read more interviews`,
      's_bn_challenge.last_challenge.btn_2',
      `Read more interviews`,
      `Read more interviews`,
      `Read more interviews`,
      `Read more interviews`,
    ],
  },
  share: {
    title: [
      <>
        혼자 공부하기는 외롭잖아요.
        <br />
        지금 친구와 같이 도전해 볼까요?
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      's_bn_challenge.share.title',
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
    ],
    subtitle: [
      `링글 보스턴&뉴욕 챌린지 공유하기`,
      `Spread the word about the Boston & NYC Challenge`,
      's_bn_challenge.share.subtitle',
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
    ],
  },
  floating: {
    title_1: [
      `챌린지에 참여만 해도 100% 혜택을 드려요.`,
      `Get rewards just by joining the challenge.`,
      's_bn_challenge.floating.title_1',
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
    ],
    title_2: [
      `꾸준함은 영어를 극복하는 가장 빠른 길입니다!`,
      `Consistency is the key to mastering English!`,
      's_bn_challenge.floating.title_2',
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
    ],
    title_3: [
      `지금이 영어 공부하기 가장 좋은 타이밍!`,
      `Now is the best time to study English!`,
      's_bn_challenge.floating.title_3',
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
    ],
    title_4: [
      `챌린지 달성을 축하드려요! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      's_bn_challenge.floating.title_4',
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
    ],
    btn_1: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.floating.btn_1',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    btn_2: [
      `수업 예약하기`,
      `Book a lesson`,
      's_bn_challenge.floating.btn_2',
      `Book a lesson`,
      `Book a lesson`,
      `Book a lesson`,
      `Book a lesson`,
    ],
    btn_3: [
      `수업권 구매하기`,
      `Purchase credits`,
      's_bn_challenge.floating.btn_3',
      `Purchase credits`,
      `Purchase credits`,
      `Purchase credits`,
      `Purchase credits`,
    ],
    subtitle: [
      `[나의 챌린지 현황] > [신청서 제출]을 클릭해 2023년 5월 10일 (수) 23:59PM까지 신청서를 작성해 주세요.`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.floating.subtitle',
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
    ],
  },
  terms: {
    title: [
      `보스턴&뉴욕 챌린지 안내 및 유의 사항`,
      `Terms`,
      's_bn_challenge.terms.title',
      `Terms`,
      `Terms`,
      `Terms`,
      `Terms`,
    ],
    subtitle_1: [
      `[공통 안내]`,
      `[General Notice]`,
      's_bn_challenge.terms.subtitle_1',
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
    ],
    desc_1_1: [
      `모든 일정은 한국 시간 기준입니다.`,
      `All dates and times are in Korean Standard Time (KST).`,
      's_bn_challenge.terms.desc_1_1',
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
    ],
    desc_1_2: [
      `혜택 당첨자 공지는 발표일에 링글 계정에 등록된 연락처 및 이메일로 개별 안내해 드립니다.`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      's_bn_challenge.terms.desc_1_2',
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
    ],
    desc_1_3: [
      `잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      's_bn_challenge.terms.desc_1_3',
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
    ],
    desc_1_4: [
      `당첨자 발표 후 7일 이내에 당첨자와 연락이 닿지 않는 경우 혜택 당첨이 취소되며 다른 사람에게 이관될 수 있습니다.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      's_bn_challenge.terms.desc_1_4',
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
    ],
    desc_1_5: [
      `제공된 혜택은 양도 및 다른 상품으로 교환이 불가합니다.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      's_bn_challenge.terms.desc_1_5',
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
    ],
    desc_1_6: [
      `해당 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      's_bn_challenge.terms.desc_1_6',
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
    ],
    subtitle_2: [
      `[보스턴&뉴욕 챌린지]`,
      `[Boston & NYC Challenge]`,
      's_bn_challenge.terms.subtitle_2',
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
    ],
    desc_2_1: [
      `챌린지 기간 내 40분 24회 또는 20분 32회 수업을 완료한 분들은 [나의 챌린지 현황] > [신청서 제출]을 통해 보스턴/뉴욕 투어를 신청할 수 있습니다.`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.terms.desc_2_1',
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
    ],
    desc_2_2: [
      `챌린지 기간 1/6(금) 00:00부터 4/30(일) 23:59까지 진행한 모든 수업이 포함됩니다. (수업권 종류 제한 없음, 4/30(금) 저녁 23:30 시작하는 수업 포함)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      's_bn_challenge.terms.desc_2_2',
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
    ],
    desc_2_3: [
      `수업 시작 시간에 튜터 노쇼 또는 미배정이 발생한 경우도 수업 진행으로 인정됩니다.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      's_bn_challenge.terms.desc_2_3',
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
    ],
    desc_2_4: [
      `보스턴&뉴욕 투어 신청서는 챌린지 기간 내 챌린지 조건 달성 후 5/10(수) 23:59까지 제출할 수 있습니다.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      's_bn_challenge.terms.desc_2_4',
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
    ],
    desc_2_5: [
      `보스턴&뉴욕 투어 최종 선발자 5명은 5/31(수)에 별도 연락 후 링글 공식 SNS 채널을 통해 공지됩니다.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      's_bn_challenge.terms.desc_2_5',
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
    ],
    desc_2_6: [
      `보스턴&뉴욕 투어는 2023년 하반기에 예정되어 있습니다.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      's_bn_challenge.terms.desc_2_6',
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
    ],
    subtitle_3: [
      `[포인트 혜택]`,
      `[Bonus Points]`,
      's_bn_challenge.terms.subtitle_3',
      `[Bonus Points]`,
      `[Bonus Points]`,
      `[Bonus Points]`,
      `[Bonus Points]`,
    ],
    desc_3_1: [
      `수업 완료 후 [나의 챌린지 현황] > [포인트 받기]를 누르면 포인트가 적립됩니다.`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_3_1',
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
    ],
    desc_3_2: [
      `챌린지 참여 시점과 무관하게 챌린지 기간 내 진행한 수업이 있다면 혜택은 소급 적용됩니다.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      's_bn_challenge.terms.desc_3_2',
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
    ],
    desc_3_3: [
      `40분/20분 수업권을 교차 수강할 경우, 40분/20분 포인트 혜택이 개별 적용됩니다.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      's_bn_challenge.terms.desc_3_3',
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
    ],
    subtitle_4: [
      `[영어 목표 남기기 미션]`,
      `[Submit Your 2023 Study Goals Mission]`,
      's_bn_challenge.terms.subtitle_4',
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
    ],
    desc_4_1: [
      `영어 목표는 챌린지 참여 후 남길 수 있습니다.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.terms.desc_4_1',
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
    ],
    desc_4_2: [
      `한번 남긴 의견은 수정 또는 삭제할 수 없으니 주의해 주시기 바랍니다.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      's_bn_challenge.terms.desc_4_2',
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
    ],
    desc_4_3: [
      `이벤트와 무관한 내용, 욕설 등이 포함된 영어 목표는 사전 안내 없이 삭제될 수 있습니다.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      's_bn_challenge.terms.desc_4_3',
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
    ],
    desc_4_4: [
      `10자에서 80자 내 영어 목표를 남기는 즉시 전원 5,000 포인트를 드립니다.`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_4_4',
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
    ],
    desc_4_5: [
      `영어 목표를 남겨주신 분들 중 100명을 추첨하여 5/10(수)에 스타벅스 기프티콘을 보내드립니다.`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      's_bn_challenge.terms.desc_4_5',
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
    ],
    subtitle_5: [
      `[SNS 후기 인증 미션]`,
      `[Post a Review on SNS Mission]`,
      's_bn_challenge.terms.subtitle_5',
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
    ],
    desc_5_1: [
      `SNS 후기 인증 미션은 1/6(금)부터 4/30(일)까지 진행됩니다.`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      's_bn_challenge.terms.desc_5_1',
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
    ],
    desc_5_2: [
      `SNS 후기를 남겨주신 분들 중 최대 100명에게 20,000원 교보문고 상품권을 증정합니다.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      's_bn_challenge.terms.desc_5_2',
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
    ],
    desc_5_3: [
      `정성스럽게 쓴 SNS 후기 5명을 추첨하여 20분 수업권 3회를 증정합니다.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      's_bn_challenge.terms.desc_5_3',
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
    ],
    desc_5_4: [
      `SNS 후기 인증 혜택은 5/10(수)에 일괄 발송됩니다.`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      's_bn_challenge.terms.desc_5_4',
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
    ],
    subtitle_6: [`[월별 수업 미션]`, `[Monthly Mission]`, 's_bn_challenge.terms.subtitle_6', ``, ``, ``, ``],
    desc_6_1: [
      `매달 40분 수업 6회 또는 20분 수업 8회를 완료하면 같은 수업 길이의 무료 수업권을 제공합니다.`,
      `Get a free`,
      's_bn_challenge.terms.desc_6_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_6_2: [
      `1월 - 4월 월별 수업 미션 종료일은 매월 마지막 일 저녁 23:59입니다. (수업권 종류 제한 없음, 전날 저녁 23:30 시작하는 수업 포함)`,
      ``,
      's_bn_challenge.terms.desc_6_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_6_3: [
      `월별 수업 미션 달성 시, 2/1(수), 3/1(수), 4/1(토), 5/1(월) 링글 계정으로 보너스 수업권을 일괄 제공합니다. (수강기간: 지급일 기준 30일)`,
      ``,
      's_bn_challenge.terms.desc_6_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_tutor = {
  label_recommended: [
    `불만족 시 100% 수업권 복구`,
    `100% credit-back guarantee`,
    's_tutor.label_recommended',
    `不滿意時可100%退還課程券`,
    `不滿意時可100%退還課程券`,
    `ご不満時100％レッスンチケット復元`,
    `Hoàn 100% phiếu học nếu không hài lòng`,
  ],
}
export const ringle_pick_1 = {
  title: [
    `가벼운 주제로 영어에 익숙해지기`,
    `Get used to speaking in English`,
    'ringle_pick_1.title',
    `Get used to speaking in English`,
    `Get used to speaking in English`,
    `Get used to speaking in English`,
    `Get used to speaking in English`,
  ],
  tip_title: [
    `원어민만 보면 긴장되고 낯가리게 되나요?`,
    `Do you get nervous in front of foreigners?`,
    'ringle_pick_1.tip_title',
    `Do you get nervous in front of foreigners?`,
    `Do you get nervous in front of foreigners?`,
    `Do you get nervous in front of foreigners?`,
    `Do you get nervous in front of foreigners?`,
  ],
  tip_1: {
    title: [
      `나에 대해 이야기하기부터 시작해봐요.`,
      `Start by talking about yourself.`,
      'ringle_pick_1.tip_1.title',
      `Start by talking about yourself.`,
      `Start by talking about yourself.`,
      `Start by talking about yourself.`,
      `Start by talking about yourself.`,
    ],
    desc: [
      <>
        1. 나, 가족, 친구 같은 가장 익숙한 소재로 편하게 영어대화를 시작할 수 있어요.
        <br />
        2. 수업 요청에서 ‘서로 소개하기’를 체크하고 반복 연습하면서 자신의 것으로 만들어보세요.
        <br />
        3. 영어권에서는 자기소개 할 기회가 많답니다. 준비된 자기소개는 미래에 자산이 될 수 있어요.
      </>,
      <>
        1. Talking about yourself is great for getting used to conversing in English.
        <br />
        2. Spend the first 3 minutes exchanging introductions at the start of each lesson.
        <br />
        3. A well-prepared introduction will come in handy in the future.
      </>,
      'ringle_pick_1.tip_1.desc',
      <>
        1. Talking about yourself is great for getting used to conversing in English.
        <br />
        2. Spend the first 3 minutes exchanging introductions at the start of each lesson.
        <br />
        3. A well-prepared introduction will come in handy in the future.
      </>,
      <>
        1. Talking about yourself is great for getting used to conversing in English.
        <br />
        2. Spend the first 3 minutes exchanging introductions at the start of each lesson.
        <br />
        3. A well-prepared introduction will come in handy in the future.
      </>,
      <>
        1. Talking about yourself is great for getting used to conversing in English.
        <br />
        2. Spend the first 3 minutes exchanging introductions at the start of each lesson.
        <br />
        3. A well-prepared introduction will come in handy in the future.
      </>,
      <>
        1. Talking about yourself is great for getting used to conversing in English.
        <br />
        2. Spend the first 3 minutes exchanging introductions at the start of each lesson.
        <br />
        3. A well-prepared introduction will come in handy in the future.
      </>,
    ],
  },
  tip_2: {
    title: [
      `익숙한 튜터와 교재로 반복 연습해요.`,
      `Repeat lessons in the same format.`,
      'ringle_pick_1.tip_2.title',
      `Repeat lessons in the same format.`,
      `Repeat lessons in the same format.`,
      `Repeat lessons in the same format.`,
      `Repeat lessons in the same format.`,
    ],
    desc: [
      <>
        1. 나와 잘맞았던 튜터와 3~4번 정도 함께 수업을 한 후에 새로운 튜터와 만나보세요. 익숙한 튜터와 여러번 만나다보면
        영어 긴장감이 줄어들거에요.
        <br />
        2. 같은 교재를 가지고 2~3회 연속으로 수업해 보세요. 전 수업에서 배운 표현도 써보고, 질문을 바꿔가며 충분히
        내것으로 만드는 시간을 가지세요.
      </>,
      <>
        1. Take 3-4 lessons with the same tutor to help lessen your anxiety about talking in English.
        <br />
        2. Use the same material for 2-3 lessons in a row to make it your own. Try using the expressions you learnt in
        the last lesson and work on new questions.
      </>,
      'ringle_pick_1.tip_2.desc',
      <>
        1. Take 3-4 lessons with the same tutor to help lessen your anxiety about talking in English.
        <br />
        2. Use the same material for 2-3 lessons in a row to make it your own. Try using the expressions you learnt in
        the last lesson and work on new questions.
      </>,
      <>
        1. Take 3-4 lessons with the same tutor to help lessen your anxiety about talking in English.
        <br />
        2. Use the same material for 2-3 lessons in a row to make it your own. Try using the expressions you learnt in
        the last lesson and work on new questions.
      </>,
      <>
        1. Take 3-4 lessons with the same tutor to help lessen your anxiety about talking in English.
        <br />
        2. Use the same material for 2-3 lessons in a row to make it your own. Try using the expressions you learnt in
        the last lesson and work on new questions.
      </>,
      <>
        1. Take 3-4 lessons with the same tutor to help lessen your anxiety about talking in English.
        <br />
        2. Use the same material for 2-3 lessons in a row to make it your own. Try using the expressions you learnt in
        the last lesson and work on new questions.
      </>,
    ],
  },
  tip_3: {
    title: [
      `많은 질문을 다룰 필요는 없어요.`,
      `Focus on just a few questions.`,
      'ringle_pick_1.tip_3.title',
      `Focus on just a few questions.`,
      `Focus on just a few questions.`,
      `Focus on just a few questions.`,
      `Focus on just a few questions.`,
    ],
    desc: [
      <>
        1. 교재의 모든 질문을 한 수업에서 다 답하고 교정받지 않아도 돼요. 수업당 2-3개도 충분해요. 시간이 남는다면
        주제에 대한 생각을 서로 나눠보세요.
        <br />
        2. 교재 질문에는 어려운 질문과 쉬운 질문이 모두 있어요. 여러번 반복 수업을 한다면 쉬운 질문과 어려운 질문을
        섞으면 지루하지 않아요.
      </>,
      <>
        1. For each lesson, focus on just 2-3 questions about the material. In the remaining time, share your thoughts
        on the topic.
        <br />
        2. Each material has a mixture of easy and difficult questions. Keep the lessons interesting by alternating
        them.
      </>,
      'ringle_pick_1.tip_3.desc',
      <>
        1. For each lesson, focus on just 2-3 questions about the material. In the remaining time, share your thoughts
        on the topic.
        <br />
        2. Each material has a mixture of easy and difficult questions. Keep the lessons interesting by alternating
        them.
      </>,
      <>
        1. For each lesson, focus on just 2-3 questions about the material. In the remaining time, share your thoughts
        on the topic.
        <br />
        2. Each material has a mixture of easy and difficult questions. Keep the lessons interesting by alternating
        them.
      </>,
      <>
        1. For each lesson, focus on just 2-3 questions about the material. In the remaining time, share your thoughts
        on the topic.
        <br />
        2. Each material has a mixture of easy and difficult questions. Keep the lessons interesting by alternating
        them.
      </>,
      <>
        1. For each lesson, focus on just 2-3 questions about the material. In the remaining time, share your thoughts
        on the topic.
        <br />
        2. Each material has a mixture of easy and difficult questions. Keep the lessons interesting by alternating
        them.
      </>,
    ],
  },
  lesson_msg: [
    `튜터와 가벼운 주제로 대화하며 영어를 주고 받는 상황에 익숙해지는게 중요해요.`,
    `The goal is to get comfortable talking in English with the tutor.`,
    'ringle_pick_1.lesson_msg',
    `The goal is to get comfortable talking in English with the tutor.`,
    `The goal is to get comfortable talking in English with the tutor.`,
    `The goal is to get comfortable talking in English with the tutor.`,
    `The goal is to get comfortable talking in English with the tutor.`,
  ],
  subtitle1: [
    `많은 사람들이 선택하는 가벼운 주제의 교재로 시작해볼까요.`,
    `Start with the materials many Ringlers choose for light topics.`,
    'ringle_pick_1.subtitle1',
    `Start with the materials many Ringlers choose for light topics.`,
    `Start with the materials many Ringlers choose for light topics.`,
    `Start with the materials many Ringlers choose for light topics.`,
    `Start with the materials many Ringlers choose for light topics.`,
  ],
  desc1: [
    <>
      스몰토크는 원어민들에게 일상대화를 이끌어나가는 중요한 주제에요.
      <br />
      가벼운 질문들로 자유롭게 대화를 나눠보세요.
    </>,
    <>
      Small talk is important in everyday communication.
      <br />
      Keep the conversation flowing with light questions.
    </>,
    'ringle_pick_1.desc1',
    <>
      Small talk is important in everyday communication.
      <br />
      Keep the conversation flowing with light questions.
    </>,
    <>
      Small talk is important in everyday communication.
      <br />
      Keep the conversation flowing with light questions.
    </>,
    <>
      Small talk is important in everyday communication.
      <br />
      Keep the conversation flowing with light questions.
    </>,
    <>
      Small talk is important in everyday communication.
      <br />
      Keep the conversation flowing with light questions.
    </>,
  ],
  desc2: [
    <>
      많은 분이 선택하는 링글의 인기 교재도 추천해드려요.
      <br />
      요즘 트렌드와 잘 맞는 주제인 만큼 읽고 대화하는 재미가 있을 거에요.
    </>,
    <>
      Also check out our popular materials.
      <br />
      They're about trending topics and fun to read and talk about.
    </>,
    'ringle_pick_1.desc2',
    <>
      Also check out our popular materials.
      <br />
      They're about trending topics and fun to read and talk about.
    </>,
    <>
      Also check out our popular materials.
      <br />
      They're about trending topics and fun to read and talk about.
    </>,
    <>
      Also check out our popular materials.
      <br />
      They're about trending topics and fun to read and talk about.
    </>,
    <>
      Also check out our popular materials.
      <br />
      They're about trending topics and fun to read and talk about.
    </>,
  ],
  subtitle2: [
    `추천 튜터와 수업을 시작해 보세요.`,
    `Try a lesson with a Featured Tutor.`,
    'ringle_pick_1.subtitle2',
    `Try a lesson with a Featured Tutor.`,
    `Try a lesson with a Featured Tutor.`,
    `Try a lesson with a Featured Tutor.`,
    `Try a lesson with a Featured Tutor.`,
  ],
  desc3: [
    `링글팀에서 수강생의 리뷰를 바탕으로 직접 선별한 튜터들을 추천해 드리고 있어요.`,
    `Featured Tutors are curated by the Ringle team based on student reviews.`,
    'ringle_pick_1.desc3',
    `Featured Tutors are curated by the Ringle team based on student reviews.`,
    `Featured Tutors are curated by the Ringle team based on student reviews.`,
    `Featured Tutors are curated by the Ringle team based on student reviews.`,
    `Featured Tutors are curated by the Ringle team based on student reviews.`,
  ],
  subtitle3: [
    `링글러들의 링글 활용 방법이 궁금하다면?`,
    `Want to know how other Ringlers study?`,
    'ringle_pick_1.subtitle3',
    `Want to know how other Ringlers study?`,
    `Want to know how other Ringlers study?`,
    `Want to know how other Ringlers study?`,
    `Want to know how other Ringlers study?`,
  ],
  desc4: [
    `1:1 수업 외에 영어 실력을 향상할 방법을 찾고 계신다면 웨비나도 추천드려요.`,
    `Outside of lessons, Ringle offers webinars that can help you improve your English.`,
    'ringle_pick_1.desc4',
    `Outside of lessons, Ringle offers webinars that can help you improve your English.`,
    `Outside of lessons, Ringle offers webinars that can help you improve your English.`,
    `Outside of lessons, Ringle offers webinars that can help you improve your English.`,
    `Outside of lessons, Ringle offers webinars that can help you improve your English.`,
  ],
  subtitle4: [
    `영어 공부로 고민하는 다른 사람들의 이야기도 들어보세요.`,
    `Hear from other people struggling with English.`,
    'ringle_pick_1.subtitle4',
    `Hear from other people struggling with English.`,
    `Hear from other people struggling with English.`,
    `Hear from other people struggling with English.`,
    `Hear from other people struggling with English.`,
  ],
  desc5: [
    `영어 공부법부터 링글 고객분들의 후기까지. 링글 블로그에서 확인하세요.`,
    `From tips on learning English to user reviews. Check out the Ringle blog.`,
    'ringle_pick_1.desc5',
    `From tips on learning English to user reviews. Check out the Ringle blog.`,
    `From tips on learning English to user reviews. Check out the Ringle blog.`,
    `From tips on learning English to user reviews. Check out the Ringle blog.`,
    `From tips on learning English to user reviews. Check out the Ringle blog.`,
  ],
}
export const ringle_pick = {
  btn_lesson: [
    `수업 예약하기`,
    `Book a lesson`,
    'ringle_pick.btn_lesson',
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
  ],
}
export const ringle_pick_2 = {
  title: [
    `이메일 표현부터 비즈니스 트렌드까지`,
    `From writing emails to discussing biz trends`,
    'ringle_pick_2.title',
    `From writing emails to discussing biz trends`,
    `From writing emails to discussing biz trends`,
    `From writing emails to discussing biz trends`,
    `From writing emails to discussing biz trends`,
  ],
  tip_title: [
    `회사에서 매일 영어와 사투 중인가요?`,
    `Are you struggling with English at work?`,
    'ringle_pick_2.tip_title',
    `Are you struggling with English at work?`,
    `Are you struggling with English at work?`,
    `Are you struggling with English at work?`,
    `Are you struggling with English at work?`,
  ],
  tip_1: {
    title: [
      `직접 자료를 올리고 튜터와 수업해요.`,
      `Upload your own materials.`,
      'ringle_pick_2.tip_1.title',
      `Upload your own materials.`,
      `Upload your own materials.`,
      `Upload your own materials.`,
      `Upload your own materials.`,
    ],
    desc: [
      <>
        1. 내가 직접 사용할 발표자료/이메일 등으로 영어 공부를 하는 것만큼 효과적인 건 없어요.
        <br />
        2. 튜터가 팀원/고객이라고 생각하고 효과적으로 설득하는 방법과 표현을 배워볼 수 있습니다.
        <br />
        3. 평소 회사에서 자주 쓰는 표현 중 더 나은 표현이 있는지를 물어보는 것도 좋은 방법이에요.
      </>,
      <>
        1. You can maximize your learning by using your own materials, such as PPT or emails.
        <br />
        2. Learn how to communicate effectively by role-playing with the tutor.
        <br />
        3. Ask the tutor to suggest alternative expressions for those you often use at work.
      </>,
      'ringle_pick_2.tip_1.desc',
      <>
        1. You can maximize your learning by using your own materials, such as PPT or emails.
        <br />
        2. Learn how to communicate effectively by role-playing with the tutor.
        <br />
        3. Ask the tutor to suggest alternative expressions for those you often use at work.
      </>,
      <>
        1. You can maximize your learning by using your own materials, such as PPT or emails.
        <br />
        2. Learn how to communicate effectively by role-playing with the tutor.
        <br />
        3. Ask the tutor to suggest alternative expressions for those you often use at work.
      </>,
      <>
        1. You can maximize your learning by using your own materials, such as PPT or emails.
        <br />
        2. Learn how to communicate effectively by role-playing with the tutor.
        <br />
        3. Ask the tutor to suggest alternative expressions for those you often use at work.
      </>,
      <>
        1. You can maximize your learning by using your own materials, such as PPT or emails.
        <br />
        2. Learn how to communicate effectively by role-playing with the tutor.
        <br />
        3. Ask the tutor to suggest alternative expressions for those you often use at work.
      </>,
    ],
  },
  tip_2: {
    title: [
      `다양한 튜터와 수업해보세요.`,
      `Take lessons with different tutors.`,
      'ringle_pick_2.tip_2.title',
      `Take lessons with different tutors.`,
      `Take lessons with different tutors.`,
      `Take lessons with different tutors.`,
      `Take lessons with different tutors.`,
    ],
    desc: [
      <>
        1. 실제로 영어를 쓰는 환경에서는 절대 한명의 원어민과 이야기할 수 없어요. 다양한 발음과 억양에 익숙해지기 위해
        주기적으로 새로운 튜터와 수업을 해보세요.
        <br />
        2. 한국인에게 익숙치 않은 영국식 발음과 표현을 쓰는 튜터와도 만나서 연습해보세요.
      </>,
      <>
        1. In real life, you'll be communicating with many different people. Switch up the tutor periodically to get use
        to different pronunciations and accents.
        <br />
        2. Familiarize yourself with British English by practicing with tutors in the UK.
      </>,
      'ringle_pick_2.tip_2.desc',
      <>
        1. In real life, you'll be communicating with many different people. Switch up the tutor periodically to get use
        to different pronunciations and accents.
        <br />
        2. Familiarize yourself with British English by practicing with tutors in the UK.
      </>,
      <>
        1. In real life, you'll be communicating with many different people. Switch up the tutor periodically to get use
        to different pronunciations and accents.
        <br />
        2. Familiarize yourself with British English by practicing with tutors in the UK.
      </>,
      <>
        1. In real life, you'll be communicating with many different people. Switch up the tutor periodically to get use
        to different pronunciations and accents.
        <br />
        2. Familiarize yourself with British English by practicing with tutors in the UK.
      </>,
      <>
        1. In real life, you'll be communicating with many different people. Switch up the tutor periodically to get use
        to different pronunciations and accents.
        <br />
        2. Familiarize yourself with British English by practicing with tutors in the UK.
      </>,
    ],
  },
  tip_3: {
    title: [
      `웨비나에서 실전 팁을 배워보세요.`,
      `Explore Ringle Webinars.`,
      'ringle_pick_2.tip_3.title',
      `Explore Ringle Webinars.`,
      `Explore Ringle Webinars.`,
      `Explore Ringle Webinars.`,
      `Explore Ringle Webinars.`,
    ],
    desc: [
      <>
        1. 링크드인 쓰는 법부터, 인터뷰 준비, 비즈니스 이메일 작성법까지. 내일 당장 쓸 수 있는 실전 팁을 유경험자들이
        알려줘요.
        <br />
        2. 영어뿐만 아니라 영어권 업무 환경에서의 문화적 차이에 대한 이해도 넓혀보세요.
      </>,
      <>
        1. From using LinkedIn, acing interviews, to writing emails. Get practical tips from experience that you can
        apply right away.
        <br />
        2. Get a glimpse into the cultural aspects of workplaces in English-speaking countries.
      </>,
      'ringle_pick_2.tip_3.desc',
      <>
        1. From using LinkedIn, acing interviews, to writing emails. Get practical tips from experience that you can
        apply right away.
        <br />
        2. Get a glimpse into the cultural aspects of workplaces in English-speaking countries.
      </>,
      <>
        1. From using LinkedIn, acing interviews, to writing emails. Get practical tips from experience that you can
        apply right away.
        <br />
        2. Get a glimpse into the cultural aspects of workplaces in English-speaking countries.
      </>,
      <>
        1. From using LinkedIn, acing interviews, to writing emails. Get practical tips from experience that you can
        apply right away.
        <br />
        2. Get a glimpse into the cultural aspects of workplaces in English-speaking countries.
      </>,
      <>
        1. From using LinkedIn, acing interviews, to writing emails. Get practical tips from experience that you can
        apply right away.
        <br />
        2. Get a glimpse into the cultural aspects of workplaces in English-speaking countries.
      </>,
    ],
  },
  lesson_msg: [
    `이메일 표현부터 비즈니스 트렌드까지. 링글로 실전을 위한 영어를 준비해보세요.`,
    `From writing emails to biz trends. Practice real English with Ringle.`,
    'ringle_pick_2.lesson_msg',
    `From writing emails to biz trends. Practice real English with Ringle.`,
    `From writing emails to biz trends. Practice real English with Ringle.`,
    `From writing emails to biz trends. Practice real English with Ringle.`,
    `From writing emails to biz trends. Practice real English with Ringle.`,
  ],
  subtitle1: [
    `실제 상황을 미리 연습해 볼까요?`,
    `Prepare yourself for various workplace situations.`,
    'ringle_pick_2.subtitle1',
    `Prepare yourself for various workplace situations.`,
    `Prepare yourself for various workplace situations.`,
    `Prepare yourself for various workplace situations.`,
    `Prepare yourself for various workplace situations.`,
  ],
  desc1: [
    `이메일 작성, 그래프 설명하기 등 업무에 활용할 수 있는 필수 표현을 익혀보세요.`,
    `Learn key expressions that can be used in different workplace situations.`,
    'ringle_pick_2.desc1',
    `Learn key expressions that can be used in different workplace situations.`,
    `Learn key expressions that can be used in different workplace situations.`,
    `Learn key expressions that can be used in different workplace situations.`,
    `Learn key expressions that can be used in different workplace situations.`,
  ],
  subtitle2: [
    `프로페셔널이라면 최신 비즈니스 트렌드도 놓칠 수 없죠.`,
    `As a professional, keeping up with business trends is a must.]`,
    'ringle_pick_2.subtitle2',
    `As a professional, keeping up with business trends is a must.]`,
    `As a professional, keeping up with business trends is a must.]`,
    `As a professional, keeping up with business trends is a must.]`,
    `As a professional, keeping up with business trends is a must.]`,
  ],
  desc2: [
    `뉴스에서 다루는 주요 이슈들을 영어로 전달드립니다.`,
    `Read about up-to-date trends in English.`,
    'ringle_pick_2.desc2',
    `Read about up-to-date trends in English.`,
    `Read about up-to-date trends in English.`,
    `Read about up-to-date trends in English.`,
    `Read about up-to-date trends in English.`,
  ],
  subtitle3: [
    `미리 외워두면 도움이 되는 표현들도 있어요.`,
    `And also learn useful expressions.`,
    'ringle_pick_2.subtitle3',
    `And also learn useful expressions.`,
    `And also learn useful expressions.`,
    `And also learn useful expressions.`,
    `And also learn useful expressions.`,
  ],
  desc3: [
    `웨비나에서 비즈니스 상황에서 활용할 수 있는 영어 표현들을 준비했어요.`,
    `Watch webinars on how to communicate professionally in English.`,
    'ringle_pick_2.desc3',
    `Watch webinars on how to communicate professionally in English.`,
    `Watch webinars on how to communicate professionally in English.`,
    `Watch webinars on how to communicate professionally in English.`,
    `Watch webinars on how to communicate professionally in English.`,
  ],
  subtitle4: [
    `영어로 업무를 하는 경험담도 도움이 될거에요.`,
    `Hear the experiences of professionals who use English daily.`,
    'ringle_pick_2.subtitle4',
    `Hear the experiences of professionals who use English daily.`,
    `Hear the experiences of professionals who use English daily.`,
    `Hear the experiences of professionals who use English daily.`,
    `Hear the experiences of professionals who use English daily.`,
  ],
  desc4: [
    `링글 블로그에서 글로벌 환경에서의 경험담을 확인해보세요.`,
    `Visit the Ringle blog to learn what it's like to work globally.`,
    'ringle_pick_2.desc4',
    `Visit the Ringle blog to learn what it's like to work globally.`,
    `Visit the Ringle blog to learn what it's like to work globally.`,
    `Visit the Ringle blog to learn what it's like to work globally.`,
    `Visit the Ringle blog to learn what it's like to work globally.`,
  ],
}
export const ringle_pick_3 = {
  title: [
    `레쥬메 작성부터 인터뷰 준비까지`,
    `From resumes to interviews`,
    'ringle_pick_3.title',
    `From resumes to interviews`,
    `From resumes to interviews`,
    `From resumes to interviews`,
    `From resumes to interviews`,
  ],
  tip_title: [
    `글로벌에서 더 큰 꿈을 펼치고 싶나요?`,
    `Do you dream of going abroad?`,
    'ringle_pick_3.tip_title',
    `Do you dream of going abroad?`,
    `Do you dream of going abroad?`,
    `Do you dream of going abroad?`,
    `Do you dream of going abroad?`,
  ],
  tip_1: {
    title: [
      `튜터와 함께 레쥬메를 준비해보세요.`,
      `Prepare your resume with our tutors.`,
      'ringle_pick_3.tip_1.title',
      `Prepare your resume with our tutors.`,
      `Prepare your resume with our tutors.`,
      `Prepare your resume with our tutors.`,
      `Prepare your resume with our tutors.`,
    ],
    desc: [
      <>
        1. 레쥬메는 단순한 영어 번역을 넘어 나의 경험을 원어민도 이해할 수 있게 쓰는게 중요해요. 튜터에게 나의 경험을
        영어로 설명하고 가장 적합한 단어와 표현을 찾아보세요.
        <br />
        2. 인턴 경험 혹은 커리어 경력이 있는 튜터와 함께 준비해보세요.
      </>,
      <>
        1. Translating your resume isn't enough - you need to convey your experience in a way that convinces the
        employer. Find the most suitable words and expressions with a tutor.
        <br />
        2. Work with a tutor with internship or career experience.
      </>,
      'ringle_pick_3.tip_1.desc',
      <>
        1. Translating your resume isn't enough - you need to convey your experience in a way that convinces the
        employer. Find the most suitable words and expressions with a tutor.
        <br />
        2. Work with a tutor with internship or career experience.
      </>,
      <>
        1. Translating your resume isn't enough - you need to convey your experience in a way that convinces the
        employer. Find the most suitable words and expressions with a tutor.
        <br />
        2. Work with a tutor with internship or career experience.
      </>,
      <>
        1. Translating your resume isn't enough - you need to convey your experience in a way that convinces the
        employer. Find the most suitable words and expressions with a tutor.
        <br />
        2. Work with a tutor with internship or career experience.
      </>,
      <>
        1. Translating your resume isn't enough - you need to convey your experience in a way that convinces the
        employer. Find the most suitable words and expressions with a tutor.
        <br />
        2. Work with a tutor with internship or career experience.
      </>,
    ],
  },
  tip_2: {
    title: [
      `튜터에게 입학 팁을 물어보세요.`,
      `Ask our tutors for admission tips.`,
      'ringle_pick_3.tip_2.title',
      `Ask our tutors for admission tips.`,
      `Ask our tutors for admission tips.`,
      `Ask our tutors for admission tips.`,
      `Ask our tutors for admission tips.`,
    ],
    desc: [
      <>
        1. 영미 top 30 학교 출신의 튜터들이 여러분을 기다리고 있어요. 유학 준비 중 목표로 하는 학교에 다니는 튜터가
        있다면 실제 재학생에게 입학 팁을 물어볼 수 있어요.
        <br />
        2. 같은 전공의 튜터와 전공 관련 이야기를 나눠보세요. 유학 전 영어 연습에 도움이 될 거에요.
      </>,
      <>
        1. Tutors from the top 30 universities in the US and UK are here to help. There may even be a tutor who attends
        the school you're applying for to get tips from.
        <br />
        2. Find tutors majoring in the field you wish to study to practice relevant English in advance.
      </>,
      'ringle_pick_3.tip_2.desc',
      <>
        1. Tutors from the top 30 universities in the US and UK are here to help. There may even be a tutor who attends
        the school you're applying for to get tips from.
        <br />
        2. Find tutors majoring in the field you wish to study to practice relevant English in advance.
      </>,
      <>
        1. Tutors from the top 30 universities in the US and UK are here to help. There may even be a tutor who attends
        the school you're applying for to get tips from.
        <br />
        2. Find tutors majoring in the field you wish to study to practice relevant English in advance.
      </>,
      <>
        1. Tutors from the top 30 universities in the US and UK are here to help. There may even be a tutor who attends
        the school you're applying for to get tips from.
        <br />
        2. Find tutors majoring in the field you wish to study to practice relevant English in advance.
      </>,
      <>
        1. Tutors from the top 30 universities in the US and UK are here to help. There may even be a tutor who attends
        the school you're applying for to get tips from.
        <br />
        2. Find tutors majoring in the field you wish to study to practice relevant English in advance.
      </>,
    ],
  },
  tip_3: {
    title: [
      `인터뷰 연습은 짧고 굵게 연습해요.`,
      `Hone your interview skills.`,
      'ringle_pick_3.tip_3.title',
      `Hone your interview skills.`,
      `Hone your interview skills.`,
      `Hone your interview skills.`,
      `Hone your interview skills.`,
    ],
    desc: [
      <>
        1. 인터뷰 준비가 처음이라면 튜터에게 인터뷰 답변을 교정받는 것으로 시작해보세요.
        <br />
        2. 답변 준비가 끝났다면 다양한 튜터와 실전 연습을 하면서 다양한 억양과 발음에 대비해요.
        <br />
        3. 실제 링글러들은 인터뷰 직전 3-4번의 수업을 매일 신청해서 실전 인터뷰 감각을 키웠어요.
      </>,
      <>
        1. Have a tutor proofread the answers you prepared for the interview questions.
        <br />
        2. Rehearse the interview with diverse tutors to get used to different accents and styles.
        <br />
        3. Some Ringlers take 3-4 lessons every day up to the D-day to hone their interview skills.
      </>,
      'ringle_pick_3.tip_3.desc',
      <>
        1. Have a tutor proofread the answers you prepared for the interview questions.
        <br />
        2. Rehearse the interview with diverse tutors to get used to different accents and styles.
        <br />
        3. Some Ringlers take 3-4 lessons every day up to the D-day to hone their interview skills.
      </>,
      <>
        1. Have a tutor proofread the answers you prepared for the interview questions.
        <br />
        2. Rehearse the interview with diverse tutors to get used to different accents and styles.
        <br />
        3. Some Ringlers take 3-4 lessons every day up to the D-day to hone their interview skills.
      </>,
      <>
        1. Have a tutor proofread the answers you prepared for the interview questions.
        <br />
        2. Rehearse the interview with diverse tutors to get used to different accents and styles.
        <br />
        3. Some Ringlers take 3-4 lessons every day up to the D-day to hone their interview skills.
      </>,
      <>
        1. Have a tutor proofread the answers you prepared for the interview questions.
        <br />
        2. Rehearse the interview with diverse tutors to get used to different accents and styles.
        <br />
        3. Some Ringlers take 3-4 lessons every day up to the D-day to hone their interview skills.
      </>,
    ],
  },
  lesson_msg: [
    `글로벌에서 더 큰 꿈을 이루기 위해서, 링글로 차근차근 준비하세요.`,
    `Prepare to spread your wings globally step by step with Ringle.`,
    'ringle_pick_3.lesson_msg',
    `Prepare to spread your wings globally step by step with Ringle.`,
    `Prepare to spread your wings globally step by step with Ringle.`,
    `Prepare to spread your wings globally step by step with Ringle.`,
    `Prepare to spread your wings globally step by step with Ringle.`,
  ],
  subtitle1: [
    `라이팅 혹은 스피킹 중심의 수업으로 준비를 시작하세요.`,
    `Start by taking lessons focused on writing or speaking.`,
    'ringle_pick_3.subtitle1',
    `Start by taking lessons focused on writing or speaking.`,
    `Start by taking lessons focused on writing or speaking.`,
    `Start by taking lessons focused on writing or speaking.`,
    `Start by taking lessons focused on writing or speaking.`,
  ],
  desc1: [
    `레쥬메 초안, 인터뷰 예상 질문을 직접 올리고 튜터와 함께 완성해 나가보세요.`,
    `Upload your resume and expected questions and go through them with a tutor.`,
    'ringle_pick_3.desc1',
    `Upload your resume and expected questions and go through them with a tutor.`,
    `Upload your resume and expected questions and go through them with a tutor.`,
    `Upload your resume and expected questions and go through them with a tutor.`,
    `Upload your resume and expected questions and go through them with a tutor.`,
  ],
  subtitle2: [
    `글로벌 취업/유학을 준비한 분들의 실전 팁을 들어볼까요.`,
    `Get practical tips from people working or studying overseas.`,
    'ringle_pick_3.subtitle2',
    `Get practical tips from people working or studying overseas.`,
    `Get practical tips from people working or studying overseas.`,
    `Get practical tips from people working or studying overseas.`,
    `Get practical tips from people working or studying overseas.`,
  ],
  desc2: [
    `경험한 사람만이 알고 있는 유용한 방법들을 확인해보세요.`,
    `Get valuable advice from people with first-hand experience.`,
    'ringle_pick_3.desc2',
    `Get valuable advice from people with first-hand experience.`,
    `Get valuable advice from people with first-hand experience.`,
    `Get valuable advice from people with first-hand experience.`,
    `Get valuable advice from people with first-hand experience.`,
  ],
  subtitle3: [
    `글로벌 생활은 어떤지 미리 알아봐요.`,
    `Learn what living abroad is like in advance.`,
    'ringle_pick_3.subtitle3',
    `Learn what living abroad is like in advance.`,
    `Learn what living abroad is like in advance.`,
    `Learn what living abroad is like in advance.`,
    `Learn what living abroad is like in advance.`,
  ],
  desc3: [
    `해외 생활에서 겪을 고민과 새로운 문화에 대해 튜터들과 이야기 나눠 볼까요?`,
    `Share your worries and expectations about living abroad with the tutors.`,
    'ringle_pick_3.desc3',
    `Share your worries and expectations about living abroad with the tutors.`,
    `Share your worries and expectations about living abroad with the tutors.`,
    `Share your worries and expectations about living abroad with the tutors.`,
    `Share your worries and expectations about living abroad with the tutors.`,
  ],
  subtitle4: [
    `해외취업/유학에 성공한 링글러들의 이야기도 확인해보세요.`,
    `Find out how other Ringlers found jobs or got into schools abroad.`,
    'ringle_pick_3.subtitle4',
    `Find out how other Ringlers found jobs or got into schools abroad.`,
    `Find out how other Ringlers found jobs or got into schools abroad.`,
    `Find out how other Ringlers found jobs or got into schools abroad.`,
    `Find out how other Ringlers found jobs or got into schools abroad.`,
  ],
  desc4: [
    `글로벌이라는 넓은 세계에 먼저 나간 링글러들의 이야기를 만나보세요.`,
    `Hear the stories of Ringlers who are already living your dream.`,
    'ringle_pick_3.desc4',
    `Hear the stories of Ringlers who are already living your dream.`,
    `Hear the stories of Ringlers who are already living your dream.`,
    `Hear the stories of Ringlers who are already living your dream.`,
    `Hear the stories of Ringlers who are already living your dream.`,
  ],
}
export const s_april_promo = {
  april_only: {
    title_main: [
      <>
        <span className="font-bold">오직 Reboot 프로모션에서만</span>
        <br />
        만날 수 있는 혜택
      </>,
      <>
        <span className="font-bold">Ringle Spring Promotion</span>
        <br />
        Special Benefits
      </>,
      's_april_promo.april_only.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_1: [`혜택 1.`, `Benefit 1.`, 's_april_promo.april_only.benefit_1', ``, ``, ``, ``],
    desc_1: [
      `더 저렴하게 영어 공부 시작하세요!`,
      `Start studying at a lower price!`,
      's_april_promo.april_only.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_1: [`수업권 최대 39%할인`, `Up to 39% Off`, 's_april_promo.april_only.title_1', ``, ``, ``, ``],
    benefit_2: [`혜택 2.`, `Benefit 2.`, 's_april_promo.april_only.benefit_2', ``, ``, ``, ``],
    desc_2: [
      `카톡으로 공부 다짐 공유하면`,
      `Share your study goals & get`,
      's_april_promo.april_only.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [`스타벅스 쿠폰 증정`, `Starbucks Giftcards`, 's_april_promo.april_only.title_2', ``, ``, ``, ``],
    benefit_3: [`혜택 3.`, `Benefit 3.`, 's_april_promo.april_only.benefit_3', ``, ``, ``, ``],
    desc_3: [
      `링글을 가장 잘 쓸 수 있도록`,
      `Make the most out of Ringle`,
      's_april_promo.april_only.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [`링글 LIVE 오리엔테이션`, `LIVE Orientation`, 's_april_promo.april_only.title_3', ``, ``, ``, ``],
  },
  bonus_benefit: {
    title_main: [
      <>
        잠깐!
        <br />
        <span className="font-bold">매주 새로운 추가 혜택을 드려요!</span>
      </>,
      <>
        Wait!
        <br />
        <span className="font-bold">
          Also Grab
          <br />
          Weekly Bonuses!
        </span>
      </>,
      's_april_promo.bonus_benefit.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    title_week_1: [
      `Week 1. 4/4(화)~4/14(금)`,
      `Week 1. Apr. 3 (Tue) - Apr. 14 (Fri)`,
      's_april_promo.bonus_benefit.title_week_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1: [
      `기간 내 30만원 이상 결제 시`,
      `For purchases worth $231 or more`,
      's_april_promo.bonus_benefit.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_1: [`40분 1회 100% 증정`, `One free 40-min credit`, 's_april_promo.bonus_benefit.title_1', ``, ``, ``, ``],
    title_week_2: [
      `Week 2. 4/15(토)~4/21(금)`,
      `Week 2. Apr. 15 (Sat) - Apr. 21 (Fri)`,
      's_april_promo.bonus_benefit.title_week_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `기간 내 30만원 이상 결제 시`,
      `For purchases worth $231 or more`,
      's_april_promo.bonus_benefit.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [`20분 1회 100% 증정`, `One free 20-min credit`, 's_april_promo.bonus_benefit.title_2', ``, ``, ``, ``],
    title_week_3: [
      `Week 3. 4/22 (토)~4/28(금)`,
      `Week 3. Apr. 22 (Sat) - Apr. 28 (Fri)`,
      's_april_promo.bonus_benefit.title_week_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3: [
      `기간 내 30만원 이상 결제 시`,
      `For purchases worth $231 or more`,
      's_april_promo.bonus_benefit.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [`1만원 즉시 할인`, `$7.69P off on payment`, 's_april_promo.bonus_benefit.title_3', ``, ``, ``, ``],
    title_blocked: [`Coming Soon`, `Coming Soon`, 's_april_promo.bonus_benefit.title_blocked', ``, ``, ``, ``],
    title_expired: [`혜택이 마감되었습니다.`, `Closed`, 's_april_promo.bonus_benefit.title_expired', ``, ``, ``, ``],
  },
  what_is_ringle: {
    title: [
      <>
        1:1 화상영어 링글은
        <br />
        어떤 서비스인가요?
      </>,
      <>
        What kind of service
        <br />
        does Ringle provide?
      </>,
      's_april_promo.what_is_ringle.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글은 영미 Top 대학교 출신의 원어민 튜터와 함께 화상으로 영어 공부를 하는 서비스입니다. 

1:1 PT처럼 오로지 내 영어에 집중해서 튜터와 다양한 교재를 바탕으로 대화하고, 실시간으로 내 영어를 교정받으며 진짜 영어 실력을 밀도 있게 쌓아갈 수 있습니다.`,
      `Ringle provides 1:1 English lessons taught by tutors from the world's top universities.

Ringle lessons are like personal training sessions focused solely on improving your English. On Ringle, you can learn real English by conversing with tutors using diverse materials and receiving real-time feedback.`,
      's_april_promo.what_is_ringle.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  how_to_use: {
    title: [
      <>
        링글로 <br />
        무엇을 할 수 있나요?
      </>,
      <>
        What can I do
        <br />
        on Ringle?
      </>,
      's_april_promo.how_to_use.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        유학 준비, 해외 취업 준비, 일상 회화 공부 등<br />
        영어를 잘하고 싶은데 무엇부터 시작할지
        <br />
        모르겠나요?
        <br />
        <br />
        링글은 내가 원하는 방식으로 수업을 설정하고,
        <br />
        나에게 딱 맞는 방법으로 활용할 수 있어요.
        <br />
        <br />
        실제 링글 고객들이 활용해서 성공한 방법,
        <br />
        누적 3,000회 이상 수강한 링글 팀원들이 소개하는 링글로 할 수 있는 모든 것을 알려드릴게요.{' '}
      </>,
      <>
        Want to improve your English to study or work abroad, or just to talk with foreigners, but don't know where to
        start?
        <br />
        <br />
        On Ringle, you can personalize your lessons to suit your needs perfectly.
        <br />
        <br />
        We'll show you everything you can do on Ringle based on the success stories of our users and the Ringle Team's
        cumulative experience of 3,000+ lessons.
      </>,
      's_april_promo.how_to_use.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_1: [
      <>
        미리 준비된 영어 레쥬메는 <br />
        성공적인 해외 취업을 위해 필수죠.
      </>,
      <>
        A well-refined resume <br />
        is a must for getting <br />
        hired overseas.
      </>,
      's_april_promo.how_to_use.card_title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_1_clicked: [
      <>
        링글로 레쥬메를
        <br />
        이렇게 준비해보세요.
      </>,
      <>
        Prepare your resume <br />
        with Ringle.
      </>,
      's_april_promo.how_to_use.card_title_1_clicked',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_title_desc_1: [
      `Step 1. 수업 1~3회차 :`,
      `Step 1. First 3 lessons :`,
      's_april_promo.how_to_use.card_1_title_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_desc_1: [
      `Writing-focused 수업을 통해 직접 쓴 레쥬메를 교정받아요. 이때 일방적으로 틀린 문장을 고쳐달라고 하기보다는 튜터에게 나의 경험을 영어로 설명하고 가장 적합한 단어와 표현으로 나만의 스토리를 만들어보세요.`,
      `Go over your resume with the tutor. Rather than having it proofread, talk about your experiences with the tutor to find the best words and expressions to describe your story.`,
      's_april_promo.how_to_use.card_1_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_title_desc_2: [
      `Step 2. 수업 4~5회차 :`,
      `Step 2. Next 2 lessons :`,
      's_april_promo.how_to_use.card_1_title_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_desc_2: [
      `새로운 튜터와 만나 레쥬메를 보여주며 쓰여있는 내용에서 내가 어떤 사람인지, 지원하고자 하는 회사에 적합한지가 잘 드러나는지 이야기나누고 추가 교정을 받습니다.`,
      `Show your resume to a new tutor and discuss how well it conveys you and your experiences for further refinement.`,
      's_april_promo.how_to_use.card_1_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_title_desc_3: [
      `Step 3. 수업 6+:`,
      `Step 3. Extra lessons:`,
      's_april_promo.how_to_use.card_1_title_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_1_desc_3: [
      `다른 회사/직무를 지원 할 때마다 지원하는 직무와 회사에 더 적합한 내용으로 조금씩 변경합니다.`,
      `Take additional lessons when you need to modify your resume for a different company or position.`,
      's_april_promo.how_to_use.card_1_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_text: [
      `더 자세한 내용은 링글 블로그에서`,
      `Continue reading on Ringle Blog`,
      's_april_promo.how_to_use.btn_text',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_2: [
      <>
        확실한 영어 인터뷰 준비로
        <br />
        나를 보여주세요.
      </>,
      <>
        Show your confident self
        <br />
        in interviews through practice.
      </>,
      's_april_promo.how_to_use.card_title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_2_clicked: [
      <>
        링글러는 영어 인터뷰를
        <br />
        이렇게 준비해요
      </>,
      <>
        Prepare for interviews <br />
        in English with Ringle
      </>,
      's_april_promo.how_to_use.card_title_2_clicked',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_title_desc_1: [
      `Step 1. 수업 1~3회차 :`,
      `Step 1. First 3 lessons :`,
      's_april_promo.how_to_use.card_2_title_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_desc_1: [
      `인터뷰 예상 질문에 대한 답변을 미리 적어서 준비합니다. Speaking-focused 수업을 통해 튜터에게 답변을 교정받습니다.`,
      `Prepare the answers to expected interview questions in advance and refine them with the tutor in a speaking-focused lesson.`,
      's_april_promo.how_to_use.card_2_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_title_desc_2: [
      `Step 2. 수업 4~5회차 :`,
      `Step 2. Next 2 lessons :`,
      's_april_promo.how_to_use.card_2_title_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_desc_2: [
      `완성된 답변을 숙지한 후 튜터와 실제 인터뷰처럼 질문하고 답변하는 연습을 합니다. 이때 튜터에게 돌발질문을 미리 요청하는 것도 좋습니다.`,
      `Practice the prepared answers with the tutor like a real interview. Also, request the tutor to ask questions out of the blue.`,
      's_april_promo.how_to_use.card_2_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_title_desc_3: [
      `Step 3. 수업 6~8회차 :`,
      `Step 3. Extra lessons:`,
      's_april_promo.how_to_use.card_2_title_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_2_desc_3: [
      `새로운 튜터 2-3명과 실전처럼 인터뷰 연습을 합니다. 이를 통해 다양한 면접관 스타일에 대비할 수 있습니다.`,
      `Practice the interview with 2-3 other tutors to get ready for different interviewer styles.`,
      's_april_promo.how_to_use.card_2_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_3: [
      <>
        가벼운 일상 회화 연습으로
        <br />
        영어 울렁증을 극복하세요.
      </>,
      <>
        Get used to English by
        <br />
        practicing small talk.
      </>,
      's_april_promo.how_to_use.card_title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_3_clicked: [
      <>
        초중급 링글러들은
        <br />
        이렇게 링글을 이용해요.
      </>,
      <>
        How less advanced <br />
        learners start Ringling{' '}
      </>,
      's_april_promo.how_to_use.card_title_3_clicked',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_title_desc_1: [
      `Step 1. 나에 대해 이야기하기부터 시작해봐요.`,
      `Step 1. Talk about yourself.`,
      's_april_promo.how_to_use.card_3_title_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_desc_1: [
      `나, 가족, 친구 같은 가장 익숙한 소재로 먼저 연습을 시작하세요. 수업 요청에서 ‘서로 소개하기’를 체크하고 반복 연습하면서 자신의 것으로 만들어보세요.`,
      `Start by talking about you, your family, and friends. Spend the first 3 minutes exchanging introductions for the first few lessons.`,
      's_april_promo.how_to_use.card_3_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_title_desc_2: [
      `Step 2. 익숙한 튜터와 교재로 반복 연습해요.`,
      `Step 2. Repeat the same lesson.`,
      's_april_promo.how_to_use.card_3_title_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_desc_2: [
      `나와 잘맞았던 튜터와 최소 3~4번 정도 만나요. 익숙한 외국인과 이야기하면 영어 긴장감이 줄어들거에요.`,
      `Take 3-4 classes with a tutor who suits you to lessen your anxiety about speaking in English.`,
      's_april_promo.how_to_use.card_3_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_title_desc_3: [
      `Step 3. 같은 교재로 반복 연습해요.`,
      `Step 3. Repeat the same material.`,
      's_april_promo.how_to_use.card_3_title_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_3_desc_3: [
      `같은 교재로 2~3회 수업해 보세요. 전 수업에서 배운 표현도 써보고, 질문을 바꿔가며 충분히 내것으로 만드는 시간이 중요해요. 질문은 수업당 2-3개도 충분해요.`,
      `Take 2-3 lessons on the same material, using the expressions you learned to make it your own. Focus on just 2-3 questions per lesson.`,
      's_april_promo.how_to_use.card_3_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_4: [
      <>
        매일 전쟁같은 실전을 위한
        <br />
        비즈니스 영어를 익혀요.
      </>,
      <>
        Real workplace English <br />
        to get you through the day.
      </>,
      's_april_promo.how_to_use.card_title_4',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_4_clicked: [
      <>
        링글러는 업무를 위한 영어를
        <br />
        이렇게 준비해요
      </>,
      <>
        How Ringlers brush up <br />
        their English for work{' '}
      </>,
      's_april_promo.how_to_use.card_title_4_clicked',
      ``,
      ``,
      ``,
      ``,
    ],
    card_4_title_desc_1: [`Step 1.`, `Step 1.`, 's_april_promo.how_to_use.card_4_title_desc_1', ``, ``, ``, ``],
    card_4_desc_1: [
      `평소에 꾸준히 비즈니스 트렌드/스몰톡 교재로 링글 수업 하면서 영어 회화 감각을 잊지 않아요.`,
      `Take lessons regularly using Biz or Small Talk materials to keep English close.`,
      's_april_promo.how_to_use.card_4_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_4_title_desc_2: [`Step 2.`, `Step 2.`, 's_april_promo.how_to_use.card_4_title_desc_2', ``, ``, ``, ``],
    card_4_desc_2: [
      `중요한 발표나, 미팅 혹은 이메일을 보내기 전에는 자료를 튜터에게 직접 교정받아서 준비해요.`,
      `Get help from a tutor before an important presentation, meeting, or email.`,
      's_april_promo.how_to_use.card_4_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_4_title_desc_3: [`Step 3.`, `Step 3.`, 's_april_promo.how_to_use.card_4_title_desc_3', ``, ``, ``, ``],
    card_4_desc_3: [
      `외국인 팀원의 이메일이나, 보고서의 표현을 따라 하거나, 튜터에게 배운 표현을 매일 꾸준히 연습해요.`,
      `Make new expressions from emails, documents, or lessons their own through daily practice.`,
      's_april_promo.how_to_use.card_4_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_4_title_desc_4: [`Step 4.`, `Step 4.`, 's_april_promo.how_to_use.card_4_title_desc_4', ``, ``, ``, ``],
    card_4_desc_4: [
      `링글 웨비나 중 비즈니스 표현들과 문화적인 차이에 대한 주제가 있으면 놓치지 않고 들어요.`,
      `Catch Ringle Webinars on topics related to Business English and cultural differences.`,
      's_april_promo.how_to_use.card_4_desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_5: [
      <>
        어느샌가 멈춰버린 영어 실력을
        <br />
        한단계 업그레이드!
      </>,
      <>
        Jumpstart your English
        <br />
        from slow stagnation!
      </>,
      's_april_promo.how_to_use.card_title_5',
      ``,
      ``,
      ``,
      ``,
    ],
    card_title_5_clicked: [
      <>
        링글러들은 이렇게
        <br />
        영어 정체기를 극복해요.
      </>,
      <>
        How Ringlers overcome
        <br />
        slumps in learning English
      </>,
      's_april_promo.how_to_use.card_title_5_clicked',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_title_desc_1: [
      `Step 1. 사소하지만 반복되는 실수에 집중해요.`,
      `Step 1. Focus on repetitive mistakes.`,
      's_april_promo.how_to_use.card_5_title_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_desc_1: [
      `원어민에 가까운 영어를 위해선 사소한 실수를 고쳐야해요. 특히 관사, 복수, 시제 표현 등 튜터들에게 사소한 실수도 알려달라고 해서 반복되는 실수를 찾아 고쳐요.`,
      `Habitual mistakes need to be corrected to sound like a native. Ask the tutor to correct every mistake you make, esp. the use of articles, plurals, and tenses.`,
      's_april_promo.how_to_use.card_5_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_title_desc_2: [
      `Step 2. 구조적인 영어 말하기를 연습해요.`,
      `Step 2. Practice speaking logically.`,
      's_april_promo.how_to_use.card_5_title_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_desc_2: [
      `토론모드 수업을 통해 내 주장이 두괄식인지, 반복되지 않는지, 설득력이 있는지를 염두해두고 중점적으로 튜터에게 피드백을 받아요.`,
      `Engage in discussions with the tutor and receive feedback on how persuasively and logically you structure your arguments.`,
      's_april_promo.how_to_use.card_5_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_title_desc_3: [
      `Step 3. 현지의 문화와 트렌디한 주제를 익혀요.`,
      `Step 3. Choose trending topics.`,
      's_april_promo.how_to_use.card_5_title_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    card_5_desc_3: [
      `원어민과 언제 어디서든 만나도 대화가 시작될 수 있도록 최신 트렌드를 영어로 말할 수 있는 연습을 해요.`,
      `Practice talking about latest trends so that you can spark up a conversation with anyone, anywhere.`,
      's_april_promo.how_to_use.card_5_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  timer: {
    title_main: [
      <>
        가장 합리적인 가격으로
        <br />
        가장 효과적인 영어 공부를 준비할 수 있는 시간
      </>,
      <>
        Your chance to study{isMobile && <br />} English effectively
        <br />
        at the most reasonable price
      </>,
      's_april_promo.timer.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    title_date: [`프로모션 마감까지`, `Ends in`, 's_april_promo.timer.title_date', ``, ``, ``, ``],
    date: [`일`, `day(s)`, 's_april_promo.timer.date', ``, ``, ``, ``],
    hours: [`시간`, `hour(s)`, 's_april_promo.timer.hours', ``, ``, ``, ``],
    min: [`분`, `min(s)`, 's_april_promo.timer.min', ``, ``, ``, ``],
    sec: [`초`, `sec(s)`, 's_april_promo.timer.sec', ``, ``, ``, ``],
  },
  pricing: {
    title_main: [
      <>
        짧은 기간, 밀도 있게 공부할 수 있도록
        <br />
        <span className="font-bold">Reboot 프로모션 한정 속성 수업권</span>
      </>,
      <>
        For Short-term Intensive Learning
        <br />
        <span className="font-bold">Expedite Credits</span>
      </>,
      's_april_promo.pricing.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    toggle_40: [`40분 수업`, `40-min Lesson`, 's_april_promo.pricing.toggle_40', ``, ``, ``, ``],
    toggle_20: [`20분 수업`, `20-min Lesson`, 's_april_promo.pricing.toggle_20', ``, ``, ``, ``],
    label_popular: [`Popular`, `Popular`, 's_april_promo.pricing.label_popular', ``, ``, ``, ``],
    won: [`원`, `$`, 's_april_promo.pricing.won', ``, ``, ``, ``],
    count: [`회`, `credit(s)`, 's_april_promo.pricing.count', ``, ``, ``, ``],
    price_regular: [`정가`, `Total`, 's_april_promo.pricing.price_regular', ``, ``, ``, ``],
    price_promo: [`프로모션 할인가격`, `Promotional Price`, 's_april_promo.pricing.price_promo', ``, ``, ``, ``],
    duration: [`1회 수업 시간`, `Duration`, 's_april_promo.pricing.duration', ``, ``, ``, ``],
    lesson_count: [`수업 횟수`, `Lessons`, 's_april_promo.pricing.lesson_count', ``, ``, ``, ``],
    lesson_period: [`수강 기간`, `Valid for`, 's_april_promo.pricing.lesson_period', ``, ``, ``, ``],
    min: [`분`, `mins`, 's_april_promo.pricing.min', ``, ``, ``, ``],
    date: [`일`, `days`, 's_april_promo.pricing.date', ``, ``, ``, ``],
    desc_1: [
      `3회 수업권은 구매 직후 수강기간이 차감됩니다.`,
      `Credits valid for 15 days from purchase`,
      's_april_promo.pricing.desc_1',
      `Credits valid for 15 days from purchase`,
      `Credits valid for 15 days from purchase`,
      `Credits valid for 15 days from purchase`,
      `Credits valid for 15 days from purchase`,
    ],
    desc_2: [
      `당일수업권 12회가 추가 제공됩니다.`,
      `12 extra last-minute credits`,
      's_april_promo.pricing.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_benefit_1: [
      `링글이 처음이라면?`,
      `New to Ringle?`,
      's_april_promo.pricing.subtitle_benefit_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_benefit_1: [
      `첫 수업 후 100% 환불 가능`,
      `100% money-back guarantee after first lesson`,
      's_april_promo.pricing.title_benefit_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_benefit_2: [
      `이미 링글 고객이라면?`,
      `Already a member?`,
      's_april_promo.pricing.subtitle_benefit_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_benefit_2: [
      `보유 수업권 수강기간 연장 혜택`,
      `Special validity extension for existing credits`,
      's_april_promo.pricing.title_benefit_2',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [
      `프로모션 할인 수업권 모두 보기`,
      `View all promotional discounts`,
      's_april_promo.pricing.btn',
      ``,
      ``,
      ``,
      ``,
    ],
    title_main_moW: [
      <>
        짧은 기간 밀도있는 영어 공부
        <br />
        <span className="font-bold">Reboot 속성 수업권</span>
      </>,
      <>
        For Short-term Intensive Learning
        <br />
        <span className="font-bold">Expedite Credits</span>
      </>,
      's_april_promo.pricing.title_main_moW',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tosspay: {
    title_main: [
      <>
        이번 프로모션 기간 동안, 어떤 수업권이든
        <br />
        토스페이로 생애 첫 결제시 추가 5천원 할인!
      </>,
      <>
        First-time Toss Pay users get an extra $3.84 off <br />
        on any purchase during Spring Promotion!
      </>,
      's_april_promo.tosspay.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  event_viral: {
    title_main: [
      <>
        더 많이 소문낼수록 더 커지는 혜택
        <br />
        <span className="font-bold">프로모션 공유 이벤트</span>{' '}
      </>,
      <>
        The More You Share,
        <br />
        <span className="font-bold">The Bigger Your Rewards!</span>
      </>,
      's_april_promo.event_viral.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      <>
        친구에게 카톡으로 영어 공부 다짐을 공유해보세요.
        <br />
        공부 다짐을 공유하면 내 친구 초대 코드도 같이 공유돼요!
      </>,
      <>
        Share your study goals via KakaoTalk!
        <br />
        Your friends will also receive your referral code.
      </>,
      's_april_promo.event_viral.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title_1: (n) => [
      <>
        지금까지 <span className="text-[#503CC8]">{n}회</span> 공유했어요.
      </>,
      <>
        You have shared <br />
        your study goals <span className="text-[#503CC8]">{n}</span> times.
      </>,
      's_april_promo.event_viral.title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [`참여 혜택`, `Rewards`, 's_april_promo.event_viral.title_2', ``, ``, ``, ``],
    title_3: [`알려드려요!`, `Pleaes take note!`, 's_april_promo.event_viral.title_3', ``, ``, ``, ``],
    btn_refresh: [`새로고침`, `Update`, 's_april_promo.event_viral.btn_refresh', ``, ``, ``, ``],
    title_card_1: [
      <>
        다음 달의 나에게
        <br />
        영어 공부를 미루고 있나요?{' '}
      </>,
      <>
        Hoping the future you
        <br />
        will deal with English?
      </>,
      's_april_promo.event_viral.title_card_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_card_2: [
      <>
        자책하지 마세요, <br />
        영어, 오늘부터 시작하면 돼요.
      </>,
      <>
        Don't beat yourself up.
        <br />
        You can always start today.
      </>,
      's_april_promo.event_viral.title_card_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_card_3: [
      <>
        오늘 할 일 목록에
        <br />
        영어공부... 있나요?{' '}
      </>,
      <>
        Is "Studying English"
        <br />
        on your To-Do list for today?
      </>,
      's_april_promo.event_viral.title_card_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_card_4: [
      `영어에 적당히는 없어요.`,
      `Learning English requires commitment.`,
      's_april_promo.event_viral.title_card_4',
      ``,
      ``,
      ``,
      ``,
    ],
    title_card_5: [
      <>
        Chat GPT도 영어 미팅에서
        <br />
        대신 말해주지는 못해요.
      </>,
      <>
        "Chat GPT can't speak for you <br />
        in English meetings."
      </>,
      's_april_promo.event_viral.title_card_5',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_card: [`공부 다짐 공유하기`, `Share to KakaoTalk`, 's_april_promo.event_viral.btn_card', ``, ``, ``, ``],
    benefit_title_1: [
      <>
        <span className="text-[#503CC8]">3회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#503CC8]">3 times</span>
      </>,
      's_april_promo.event_viral.benefit_title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_dsec_1: [
      `봄나들이에 딱 좋은`,
      `Perfect for Spring`,
      's_april_promo.event_viral.benefit_dsec_1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_1: [`스타벅스 쿠폰 1장`, `1 Starbucks Giftcard`, 's_april_promo.event_viral.benefit_1', ``, ``, ``, ``],
    benefit_title_2: [
      <>
        <span className="text-[#503CC8]">6회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#503CC8]">6 times</span>
      </>,
      's_april_promo.event_viral.benefit_title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_dsec_2: [
      `이미 받은 1장에 추가로`,
      `Let's add on more`,
      's_april_promo.event_viral.benefit_dsec_2',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_2: [`스타벅스 쿠폰 2장`, `2 Starbucks Giftcards`, 's_april_promo.event_viral.benefit_2', ``, ``, ``, ``],
    benefit_title_3: [
      <>
        <span className="text-[#503CC8]">10회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#503CC8]">10 times</span> (Raffle - 2 winners)
      </>,
      's_april_promo.event_viral.benefit_title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_dsec_3: [
      `위 모든 혜택에`,
      `All the rewards above PLUS`,
      's_april_promo.event_viral.benefit_dsec_3',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_3: [`수업권 4회 (2명 추첨)`, `4 Credits`, 's_april_promo.event_viral.benefit_3', ``, ``, ``, ``],
    note_1: [
      `로그인 후에 [목표 공유하기 버튼]으로 공유한 경우만 인정돼요.`,
      `Only shares via the [Share Your Study Goals] button are counted.`,
      's_april_promo.event_viral.note_1',
      ``,
      ``,
      ``,
      ``,
    ],
    note_2: [
      `나와의 채팅방/같은 채팅방에 여러 번 공유는 안 돼요.`,
      `Sharing to yourself or to the same chatroom do not count.`,
      's_april_promo.event_viral.note_2',
      ``,
      ``,
      ``,
      ``,
    ],
    note_3: [
      `횟수가 안 올라간다면 새로고침 버튼을 눌러주세요.`,
      `To update share count, click the [Update] button.`,
      's_april_promo.event_viral.note_3',
      ``,
      ``,
      ``,
      ``,
    ],
    note_4: [
      `내가 공유한 링크로 친구가 링글에 가입할 경우 친구 초대 코드가 적용되어 친구가 5만포인트를 즉시 받아요.`,
      `The link you shared activates your referral code, and any friend joining Ringle via the link will receive $38.46P.`,
      's_april_promo.event_viral.note_4',
      ``,
      ``,
      ``,
      ``,
    ],
    note_5: [
      `내 초대 코드로 가입한 친구가 첫 수업을 완료 하면 나에게 5만포인트가 생겨요!`,
      `You will receive $38.46P if a friend who joined Ringle with your referral code completes the first lesson!`,
      's_april_promo.event_viral.note_5',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  event_ot: {
    title_main: [
      <>
        링글을 가장 잘 쓰는 방법을 알려드려요
        <br />
        <span className="font-bold">링글 LIVE 오리엔테이션</span>
      </>,
      <>
        Make the Most Out of Ringle
        <br />
        <span className="font-bold">Ringle LIVE Orientation</span>
      </>,
      's_april_promo.event_ot.title_main',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_main: [
      <>
        링글팀에게 영어 실력을 늘리는 가장 효과적인 방법도 배우고 <br />
        다른 링글러들에게 동기부여도 받으세요.
      </>,
      <>
        Learn how to improve your English most effectively <br />
        and get motivated by other Ringlers.
      </>,
      's_april_promo.event_ot.subtitle_main',
      ``,
      ``,
      ``,
      ``,
    ],
    label_offline: [`요일별 선착순 30명`, `30 people/session`, 's_april_promo.event_ot.label_offline', ``, ``, ``, ``],
    title_1: [
      `오프라인 LIVE 오리엔테이션`,
      `Offline LIVE Orientation`,
      's_april_promo.event_ot.title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_1: [
      `링글 오피스에서 링글팀원과 링글러들을 직접 만나보세요!`,
      `Meet the Ringle Team and Ringlers at Ringle!`,
      's_april_promo.event_ot.subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_offline_1: [
      <>
        <span className="font-bold">참여 방법 :</span> 오프라인 OT 신청하기 버튼으로 신청
      </>,
      <>
        <span className="font-bold">To join: </span>[Sign Up for Offline OT] button below (first come, first served)
      </>,
      's_april_promo.event_ot.desc_offline_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_offline_2: [
      <>
        <span className="font-bold">운영 시간 :</span> 매주 화, 목 저녁 7시 30분 - 9시 (총 8회)
      </>,
      <>
        <span className="font-bold">When:</span> Every Tue & Thu, 7:30-9:00 PM (8 sessions in total)
      </>,
      's_april_promo.event_ot.desc_offline_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_offline_3: [
      <>
        <span className="font-bold">장소 :</span> 강남역 링글 오피스
      </>,
      <>
        <span className="font-bold">Where:</span> Ringle Office near Gangnam Station
      </>,
      's_april_promo.event_ot.desc_offline_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_offline_4: [
      <>
        <span className="font-bold">운영 내용 :</span> 링글 사용법, 수업 안내 및 간단한 네트워킹
      </>,
      <>
        <span className="font-bold">Program:</span> How to Use Ringle, Guide to Lessons, Networking
      </>,
      's_april_promo.event_ot.desc_offline_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_offline_5: [
      <>
        <span className="font-bold">참여 혜택 :</span> 간단한 식사 및 링글 굿즈 제공
      </>,
      <>
        <span className="font-bold">Benefits:</span> Dinner & Ringle MD
      </>,
      's_april_promo.event_ot.desc_offline_5',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_offline: [
      `오프라인 오리엔테이션 신청하기`,
      `Sign Up for Offline OT`,
      's_april_promo.event_ot.btn_offline',
      ``,
      ``,
      ``,
      ``,
    ],
    label_online: [
      `원하는 시간, 원하는 장소에서`,
      `When You Want, Wherever You Are`,
      's_april_promo.event_ot.label_online',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [`온라인 LIVE 오리엔테이션`, `Online LIVE Orientation`, 's_april_promo.event_ot.title_2', ``, ``, ``, ``],
    subtitle_2: [
      `편리한 Zoom에서 링글 활용법을 배워요!`,
      `Learn how to use Ringle via Zoom!`,
      's_april_promo.event_ot.subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_online_1: [
      <>
        <span className="font-bold">참여 방법 :</span> 온라인 OT 신청하기 버튼으로 신청
      </>,
      <>
        <span className="font-bold">To join:</span> [Sign Up for Online OT] button below (first-come, first served)
      </>,
      's_april_promo.event_ot.desc_online_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_online_2: [
      <>
        <span className="font-bold">운영 시간 :</span>
        매주 월 오후 12시 - 1시 (총 4회) 매주 수 저녁 7시 30분 - 8시 30분 (총 4회)
      </>,
      <>
        <span className="font-bold">When:</span>
        Every Mon 12:00-1:00 PM (4 sessions in total) Every Thu 07:30-08:30 PM (4 sessions in total)
      </>,
      's_april_promo.event_ot.desc_online_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_online_3: [
      <>
        <span className="font-bold">장소 :</span> 화상 Zoom 미팅 (링크 개별 전달)
      </>,
      <>
        <span className="font-bold">Where:</span> Zoom meeting (Link sent individually)
      </>,
      's_april_promo.event_ot.desc_online_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_online_4: [
      <>
        <span className="font-bold">운영 내용 :</span> 링글 사용법, 수업 안내 및 간단한 네트워킹
      </>,
      <>
        <span className="font-bold">Program: </span>How to Use Ringle, Guide to Lessons, Networking
      </>,
      's_april_promo.event_ot.desc_online_4',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_online: [
      `온라인 오리엔테이션 신청하기`,
      `Sign Up for Online OT`,
      's_april_promo.event_ot.btn_online',
      ``,
      ``,
      ``,
      ``,
    ],
    title_main_moW: [
      <>
        링글을 가장 잘 쓰는 방법
        <br />
        <span className="font-bold">링글 LIVE 오리엔테이션</span>
      </>,
      <>
        Make the Most Out of Ringle
        <br />
        <span className="font-bold">Ringle LIVE Orientation</span>
      </>,
      's_april_promo.event_ot.title_main_moW',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  floating: {
    title_1: [`상반기 마지막 프로모션`, `Spring Promotion`, 's_april_promo.floating.title_1', ``, ``, ``, ``],
    title_1_1: [
      `최대 39% 할인받고 영어 공부 다시 시작하세요!`,
      `Reboot Your English with Our ~39% Discount!`,
      's_april_promo.floating.title_1_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: (day) => [
      <>⏰ 프로모션 마감까지 {day}일</>,
      <>⏰ {day} days left</>,
      's_april_promo.floating.title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2_1: [
      `최대 39% 할인이 곧 종료되요!`,
      `~39% Discount ENDS SOON!`,
      's_april_promo.floating.title_2_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [`🔥 프로모션 마감까지`, `🔥Promotion in`, 's_april_promo.floating.title_3', ``, ``, ``, ``],
    title_3_1: [`최대 39% 할인 오늘 종료!`, `Discount ENDS TODAY!`, 's_april_promo.floating.title_3_1', ``, ``, ``, ``],
    btn: [`지금 가격 알아보기`, `View Prices Now`, 's_april_promo.floating.btn', ``, ``, ``, ``],
  },
  note: {
    title_main: [`프로모션 안내 및 유의 사항`, `Terms and conditions`, 's_april_promo.note.title_main', ``, ``, ``, ``],
    title_1: [`[공통 안내]`, `[For All Offers & Events]`, 's_april_promo.note.title_1', ``, ``, ``, ``],
    desc_1: [
      `모든 일정은 KST 기준입니다.`,
      `All schedules are based on KST.`,
      's_april_promo.note.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `Reboot 프로모션 수업권은 기간 내 중복 구매가 가능합니다.`,
      `The credits discounted or offered during Spring Promotion can be purchased more than once during the promotional period.`,
      's_april_promo.note.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3: [
      `기타 문의는 링글 카카오톡 채널@ringle로 문의 바랍니다.`,
      `Please contact Ringle's KakaoTalk channel @ringle for any other inquiries.`,
      's_april_promo.note.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [`[위클리 혜택 안내]`, `[Weekly Bonus Benefits]`, 's_april_promo.note.title_2', ``, ``, ``, ``],
    desc_4: [
      `Week 1 혜택 : 4/4(화) 14:00 ~ 4/14(금) 23:59까지 30만원 이상 결제한 대상에 한하여 40분 수업권 1회가 지급됩니다.`,
      `Week 1 Benefits: One (1) 40-min credit will be issued to users who have completed a purchase of $231 or more (based on actual payment) between 14:00, April 4 (Tue) - 23:59, April 14 (Fri).`,
      's_april_promo.note.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5: [
      `Week 2 혜택 : 4/15(토) 00:00 ~ 4/21(금) 23:59까지 30만원 이상 결제한 대상에 한하여 20분 수업권 1회가 지급됩니다.`,
      `Week 2 Benefits: One (1) 20-min credit will be issued to users who have completed a purchase of $231 or more (based on actual payment) between 00:00, April 15 (Sat) - 23:59, April 21 (Fri).`,
      's_april_promo.note.desc_5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_6: [
      `Week 3 혜택 : 4/22(토) 00:00 ~ 4/28(금) 23:59까지 30만원 이상 결제 시 지급된 1만 포인트 사용이 가능합니다.`,
      `Week 3 Benefits: Users will be able to use the $7.69P issued for purchases of $231 or more towards the actual payment between 00:00, April 22 (Sat) - 23:59, April 28 (Fri).`,
      's_april_promo.note.desc_6',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_7: [
      `Week 3 혜택 1만 포인트는 4/22 (토) 이후 모든 고객에게 일괄 지급되며, 해당 포인트의 유효기간은 4/28(금) 23:59까지입니다.`,
      `The credits (40-min/20-min) issued as weekly bonus benefits during Week 1 and Week 2 will have a validity period of 15 days.`,
      's_april_promo.note.desc_7',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_8: [
      `매주 추가되는 혜택 중 week1, 2에 지급되는 수업권 (40분/20분)의 수강기간은 15일입니다.`,
      `Weekly bonus benefits will be issued once per ID for the stated "Week" periods. The eligibility for weekly bonus beneifts will be renewed at the beginning of each "Week.`,
      's_april_promo.note.desc_8',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_9: [
      `매주 추가되는 혜택은 해당 위크기간 중 ID당 1회만 지급 됩니다. 새로운 위크에는 새로운 혜택을 받을 수 있습니다.`,
      ``,
      's_april_promo.note.desc_9',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [`[토스페이 이벤트]`, `[First-time Toss Pay Users Event]`, 's_april_promo.note.title_3', ``, ``, ``, ``],
    desc_10: [
      `결제 시 토스페이 간편결제 선택 시에만 할인이 적용됩니다. (기간 내 ID당 1회)`,
      `The discount will only apply when using Toss Pay for purchasing credits. (Discount available once per ID during the promotional period)`,
      's_april_promo.note.desc_10',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_11: [
      `첫 충전 즉시 캐시백은 토스 전체 가맹점에서 결제 이력이 없는 경우 적용됩니다. (토스 ID당 1회)`,
      `The immediate cash-back event for first-time users only apply when the user does not have any history of making payments via Toss Pay at any Toss-affiliated shops or brands.`,
      's_april_promo.note.desc_11',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_12: [
      `결제 금액은 쿠폰 할인 및 적립금 적용 후 실 결제 금액입니다.`,
      `The payment amount shown on Toss Pay is the actual payment you will be making after deducting applicable coupon discounts and points.`,
      's_april_promo.note.desc_12',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_13: [
      `토스 이외의 간편결제수단 (네이버페이, 차이, 페이코 등) 이용 혹은 신용카드 선택 시, 즉시할인 적용이 불가합니다.`,
      `The immediate discount will not be applied if you use any other simple payment/e-wallet service (Naver Pay, Chai, Payco, etc.) or a credit card instead of Toss Pay.`,
      's_april_promo.note.desc_13',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_14: [
      `결제 전체 취소 후 기간 내 재결제 시 혜택 적용되며, 결제 부분취소 후 재결제 시 혜택 미적용 됩니다.`,
      `In case of payment cancellations, the total payment must be cancelled for you to be eligible for the discount again when making a purchase within the promotional period. You will not be eligible for the discount if you make a partial cancellation of the payment you made.`,
      's_april_promo.note.desc_14',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_15: [
      `선착순으로 적용되는 즉시할인 행사로, 조기 종료될 수 있습니다.`,
      `The immediate discount is given on a first-come, first served basis and may end before the end of the stated promotional period.`,
      's_april_promo.note.desc_15',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_16: [
      `행사 내용은 토스 및 링글의 사정으로 중단 또는 변동될 수 있습니다. 관련 문의는 토스 고객센터(1599-4905)로 문의 부탁드립니다.`,
      `The details of the event may be subject to changes or cancellation due to circumstances. Please contact Toss Customer Help Center (1599-4905) for further inquiries.`,
      's_april_promo.note.desc_16',
      ``,
      ``,
      ``,
      ``,
    ],
    title_4: [
      `[영어공부 목표 공유하기 이벤트]`,
      `[Share Study Goals Event]`,
      's_april_promo.note.title_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_17: [
      `이벤트 기간: 23.04.04 ~ 23.05.04`,
      `Event period: April 4 - May 4, 2023`,
      's_april_promo.note.desc_17',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_18: [
      `혜택 대상자 안내: 스타벅스 쿠폰 및 링글 수업권 4회 당첨자는 23.05.09 이후 개별 연락 후 일괄 지급됩니다.`,
      `Notifications on rewards: Users eligible for the Starbucks Giftcard and/or 4 Credit rewards will be notified individually after May 9, 2023, and the rewards will be issued to everyone at the same time.`,
      's_april_promo.note.desc_18',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_19: [
      `참여 방법: 프로모션 기간 중 ‘공부다짐 공유하기’ 버튼으로 카카오 채팅방에 다짐을 공유하면 됩니다. / 공유한 횟수에 따라 혜택이 지급됩니다.`,
      `How to participate: You can participate by sharing a study goal via KakaoTalk using the [Share Your Study Goals] button during the promotional period. / The reward(s) for sharing is determined based on the number of shares you make.`,
      's_april_promo.note.desc_19',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_20: [
      `유의 사항: 동일한 채팅방에 2회 이상 공유시 1회만 횟수로 인정됩니다. / 부정한 방법으로 이벤트 참여 이력이 확인되는 경우 선물이 지급되지 않습니다.`,
      `Important notice: Only the first share to a chatroom wiill be counted. Any additional shares to the same chatroom will not be counted. / Rewards will not be issued to users who have engaged wrongful methods during their participation in this event.`,
      's_april_promo.note.desc_20',
      ``,
      ``,
      ``,
      ``,
    ],
    title_5: [`[링글 LIVE 오리엔테이션]`, `[Ringle LIVE Orientation]`, 's_april_promo.note.title_5', ``, ``, ``, ``],
    desc_21: [
      `진행 기간: 23.04.04 ~ 23.05.04`,
      `Session period: April 4 - May 4, 2023`,
      's_april_promo.note.desc_21',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_22: [
      `참여 방법: 오리엔테이션 신청하기 버튼을 통해 원하는 시간에 신청한 후 해당 시간에 참석하면 됩니다.`,
      `How to participate: You can take the session by clicking on the [Sign Up for Online/Offline OT] button and selecting the desired date/time you wish to participate, then by showing up at the session.`,
      's_april_promo.note.desc_22',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_23: [
      `유의 사항: 신청시 입력한 연락처로 참석 안내 및 온라인 화상 미팅 링크를 전달 드리며 잘못된 정보로 안내를 받지 못하는 경우 링글 카카오톡으로 문의바랍니다.`,
      `Important notice: All notices regarding the OT session, including the links to the online Zoom OT session, will be sent to the contact number you enter in the Sign-up form. Please contact us via Ringle KakaoTalk channel if you did not receive any messages because you left incorrect information when signing up.`,
      's_april_promo.note.desc_23',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_extend_coupons = {
  title: [
    `수강기간 연장`,
    `Extend Credits`,
    's_extend_coupons.title',
    `延长使用期限`,
    `延長使用期限`,
    `受講期間延長`,
    `Gia hạn phiếu học`,
  ],
  precaution: {
    title: [`유의사항`, `Notice`, 's_extend_coupons.precaution.title', `注意事项`, `注意事項`, `注意事項`, `Thông báo`],
    list1: [
      `수강기간 연장 비용은 기본요금 2,000원에 해당 수업권의 1회 실결제액과 연장기간에 따른 비용이 더해집니다.`,
      `A $2 base fee plus additional fees based on the per day price of the credit you wish to extend will be charged as an extension fee.`,
      's_extend_coupons.precaution.list1',
      `延长费用将包括2美元的基础费用，加上根据您希望延长的天数，以一堂课的价格去计算的额外费用。`,
      `延長費用將包括2美元的基礎費用，加上根據您希望延長的天數，以一堂課的價格去計算的額外費用。`,
      `受講期間延長料は基本料金$2に該当レッスンチケットの1回決済額と延長期間分の料金がプラスされます。`,
      `Khoản phí cơ bản $2 cộng với các khoản phí bổ sung dựa trên giá mỗi ngày của phiếu học bạn muốn gia hạn sẽ được tính là 'phí gia hạn.`,
    ],
    list2: [
      `결제 대행사 정책상 최소 결제 금액은 1,000원으로 소액결제가 필요합니다. (최소 결제 금액은 포인트 사용 불가)`,
      `The minimum payment that can be processed by the payment gateway is $1. (Points cannot be used toward the $1 minimum payment.)`,
      's_extend_coupons.precaution.list2',
      `根据金流代理公司的政策，最低支付金额为1美元。（点数不可用于折抵最低支付金额）`,
      `根據金流代理公司的政策，最低支付金額為1美元。（點數不可用於折抵最低支付金額）`,
      `決済代行会社のポリシー上、決済最低額は$1で少額決済が必要となります。(決済最低額はポイント使用不可)`,
      `Theo chính sách của cơ quan thanh toán, số tiền thanh toán tối thiểu là 1 đô la và cần phải thanh toán nhỏ. (Số tiền thanh toán tối thiểu không được sử dụng điểm)`,
    ],
    list3: [
      `수강기간 연장 후에는 신규 구매한 수업권, 연장 적용된 수업권 모두 환불이 불가합니다.`,
      `After extending the expiration date, both new and extended credits are non-refundable.`,
      's_extend_coupons.precaution.list3',
      `上課時間延長後，新購買的課程券和延長適用的課程券均不可退款。`,
      `听课时间延长后，新购买的课程券和延长适用的课程券均不可退款。`,
      `受講期間延長後、新規と延長のレッスンチケットは返金不可です。`,
      `*Bạn sẽ được cấp [Phiếu gia hạn] để kéo dài hạn sử dụng gói học trên các phiếu học hiện có của bạn khi mua lại gói học trị giá ₩300.000 trở lên.`,
    ],
    list4: [
      `*실결제가 30만원 이상의 수업권을 재구매하시면, 보유한 수업권의 처음 수강기간을 추가로 연장할 수 있는 [수강기간 연장 쿠폰]이 발급됩니다.`,
      `*You will be issued an [Extension Coupon] for extending the lesson days on your existing credits upon repurchasing a credit package worth $231 or more.`,
      's_extend_coupons.precaution.list4',
      `*重新购买实际结算金额30万韩元以上的课程券时，将发放【延长使用期限优惠券】，该券可以追加延长现有课程券的第一次课时。`,
      `*重新購買實際結算金額30萬韓元以上的課程券時，將發放【延長使用期限優惠券】，該券可以追加延長現有課程券的第一次課時。`,
      `*決済金額が30万ウォン以上のレッスンチケットを再購入されると、保有しているレッスンチケットの初めの受講期間を追加で延長できる［受講期間延長クーポン］が発給されます。`,
      `*Bạn sẽ được cấp [Phiếu gia hạn] để kéo dài hạn sử dụng gói học trên các phiếu học hiện có của bạn khi mua lại gói học trị giá ₩300.000 trở lên.`,
    ],
  },
  cost_per_coupon: [
    `해당 수업권의 1회 실결제액`,
    `Per day price (based on purchased price)`,
    's_extend_coupons.cost_per_coupon',
    `该课程券一堂的实际购买金额`,
    `該課程券一堂的實際購買金額`,
    `該当レッスンチケットの1回決済額`,
    `Giá trong ngày ( dựa trên giá mua)`,
  ],
  extend_cost_per_count: [
    `수업권 1개의 1일 연장비용`,
    `Fee for extending 1 credit for 1 day`,
    's_extend_coupons.extend_cost_per_count',
    `一张课程券延长一天的费用`,
    `一張課程券延長一天的費用`,
    `レッスンチケット1枚の1日延長料`,
    `Chi phí gia hạn 1 phiếu học 1 ngày.`,
  ],
  extend_cost_per_count_desc: [
    `1회 실결제액의 2.1%`,
    `2.1% of the per day price`,
    's_extend_coupons.extend_cost_per_count_desc',
    `1堂实际购买金额的 2.1%`,
    `1堂實際購買金額的 2.1%`,
    `1回決済額の2.1％`,
    `2.1 % trên giá trong ngày`,
  ],
  all_select: [
    `전체 선택`,
    `Select all`,
    's_extend_coupons.all_select',
    `选择全部`,
    `選擇全部`,
    `全体を選択`,
    `Chọn toàn bộ`,
  ],
  per_count: (number) => [
    <>{number}개</>,
    <>{number} credit(s)</>,
    's_extend_coupons.per_count',
    <>{number} 堂</>,
    <>{number} 堂</>,
    <>{number}個</>,
    <>{number} phiếu học </>,
  ],
  remain_exp_dates: [
    `남은 수강기간`,
    `Remaining lesson days`,
    's_extend_coupons.remain_exp_dates',
    `剩馀的使用期限`,
    `剩餘的使用期限`,
    `残りの受講期間`,
    `Hạn phiếu học còn lại`,
  ],
  days: (day) => [
    <>{day}일</>,
    <>{day} day(s)</>,
    's_extend_coupons.days',
    <>{day}天</>,
    <>{day}天</>,
    <>{day}日</>,
    <>{day} ngày</>,
  ],
  extend_days: [
    `연장기간`,
    `Extended lesson days`,
    's_extend_coupons.extend_days',
    `延长时间`,
    `延長時間`,
    `延長期間`,
    `Số ngày gia hạn`,
  ],
  amount: (amount) => [
    <>{amount}원</>,
    <>$ {amount}</>,
    's_extend_coupons.amount',
    <>$ {amount}</>,
    <>$ {amount}</>,
    <>{amount}$</>,
    <>$ {amount}</>,
  ],
  alert: {
    stage1: [
      `상품 구매 관련 환불 규정에 동의 후 결제를 진행할 수 있습니다.`,
      `You must agree to the refund policy in order to proceed with the payment.`,
      's_extend_coupons.alert.stage1',
      `请先同意退款政策以进行付款程序。`,
      `請先同意退款政策以進行付款程序。`,
      `商品購入に関する返金ポリシーへの同意後、決済が可能です。`,
      `Bạn phải đồng ý với chính sách hoàn trả để tiến hành thanh toán`,
    ],
    stage2: [
      `연장할 쿠폰을 선택해주세요.`,
      `Choose a coupon.`,
      's_extend_coupons.alert.stage2',
      `选择优惠券`,
      `選擇優惠券`,
      `延長するクーポンを選択`,
      `Chọn coupon`,
    ],
    stage3: [
      `결제 방식을 선택해주세요.`,
      `Choose a payment method.`,
      's_extend_coupons.alert.stage3',
      `请选择支付方式`,
      `請選擇支付方式`,
      `決済方法を選択`,
      `Chọn cách thanh toán`,
    ],
    stage4: [
      `선택하신 수업권의 연장기간을 선택해 주세요.`,
      `Set a new expiration date for the chosen credit.`,
      's_extend_coupons.alert.stage4',
      `请选择希望延长的时间`,
      `請選擇希望延長的時間`,
      `選んだレッスンチケットの延長期間を選択`,
      `Đặt ngày hết hạn mới cho phiếu học đã chọn.`,
    ],
  },
}
export const s_trial_lesson = {
  lading: {
    welcome_banner: [
      `단 7일간 신규회원 이벤트 최대00만원 할인!`,
      `For 7 days only - Up to $00 off for Newcomers!`,
      's_trial_lesson.lading.welcome_banner',
      ``,
      ``,
      ``,
      ``,
    ],
    welcome_join: [
      `회원가입 완료!`,
      `Thanks for joining!`,
      's_trial_lesson.lading.welcome_join',
      `Thanks for joining!`,
      `Thanks for joining!`,
      `Thanks for joining!`,
      `Thanks for joining!`,
    ],
    welcome_hello: (name) => [
      <>
        {name}님,
        <br />
        링글 가입을 환영해요!
      </>,
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
      's_trial_lesson.lading.welcome_hello',
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
    ],
    welcome_event: [
      <>
        신규 회원에게만 제공되는 <br />
        혜택을 확인해보세요.
      </>,
      <>
        Check out the benefits available
        <br />
        to our newcomers only.
      </>,
      's_trial_lesson.lading.welcome_event',
      <>
        Check out the benefits available
        <br />
        to our newcomers only.
      </>,
      <>
        Check out the benefits available
        <br />
        to our newcomers only.
      </>,
      <>
        Check out the benefits available
        <br />
        to our newcomers only.
      </>,
      <>
        Check out the benefits available
        <br />
        to our newcomers only.
      </>,
    ],
    title: [
      <>
        링글의 첫 수업,
        <br />
        커피 한 잔 가격으로 체험하세요.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      's_trial_lesson.lading.title',
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
    ],
    label1: [
      `신규회원 한정 혜택`,
      `For Newcomers Only`,
      's_trial_lesson.lading.label1',
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
    ],
    label2: [`남음`, `left`, 's_trial_lesson.lading.label2', `left`, `left`, `left`, `left`],
    credit_title: [
      `1회 수업권 (40분)`,
      `Trial Lesson Credit (40min)`,
      's_trial_lesson.lading.credit_title',
      `免费体验课程券 (40分钟)`,
      `免費體驗課程券 (40分鐘)`,
      `無料体験コース券(40分)`,
      `Trial Lesson Credit (40min)`,
    ],
    credit_title_20: [
      `1회 수업권 (20분)`,
      `Trial Lesson Credit (20min)`,
      's_trial_lesson.lading.credit_title_20',
      `免费体验课程券 (20分钟)`,
      `免費體驗課程券 (20分鐘)`,
      `無料体験コース券(20分)`,
      `Trial Lesson Credit (20min)`,
    ],
    price_regular: [
      `60,000원`,
      `$46.15`,
      's_trial_lesson.lading.price_regular',
      `$46.15`,
      `$46.15`,
      `$46.15`,
      `$46.15`,
    ],
    price_regular_20: [`36,000원`, `$28`, 's_trial_lesson.lading.price_regular_20', `$28`, `$28`, `$28`, `$28`],
    price_discount_title: [
      `60% 할인가`,
      `60% off`,
      's_trial_lesson.lading.price_discount_title',
      `60% off`,
      `60% off`,
      `60% off`,
      `$18.46`,
    ],
    price_discount: [
      `24,000원`,
      `$18.46`,
      's_trial_lesson.lading.price_discount',
      `$18.46`,
      `$18.46`,
      `$18.46`,
      `$18.50`,
    ],
    price_discount_20: [`24,000원`, `$19`, 's_trial_lesson.lading.price_discount_20', `$19`, `$19`, `$19`, `$19`],
    price_trial_title: [
      `최대 혜택 적용가`,
      `With max. discount`,
      's_trial_lesson.lading.price_trial_title',
      `With max. discount`,
      `With max. discount`,
      `With max. discount`,
      `With max. discount`,
    ],
    price_trial: [`4,000원`, `$3.08`, 's_trial_lesson.lading.price_trial', `$3.08`, `$3.08`, `$3.08`, `$3.08`],
    price_trial_20: [`4,000원`, `$3.46`, 's_trial_lesson.lading.price_trial_20', `$3.46`, `$3.46`, `$3.46`, `$3.46`],
    benefit: [
      `최대 혜택 받는 법`,
      `How to get max. discount`,
      's_trial_lesson.lading.benefit',
      `How to get max. discount`,
      `How to get max. discount`,
      `How to get max. discount`,
      `How to get max. discount`,
    ],
    benefit1: [
      `링글 수업 알아보기`,
      `Learn about Ringle lessons`,
      's_trial_lesson.lading.benefit1',
      `Learn about Ringle lessons`,
      `Learn about Ringle lessons`,
      `Learn about Ringle lessons`,
      `Learn about Ringle lessons`,
    ],
    benefit1_point: [
      `5,000 포인트 할인`,
      `Get $3.84 points`,
      's_trial_lesson.lading.benefit1_point',
      `Get $3.84 points`,
      `Get $3.84 points`,
      `Get $3.84 points`,
      `Get $3.84 points`,
    ],
    benefit1_point_done: [
      `5,000 포인트 할인 완료!`,
      `$3.84 points applied!`,
      's_trial_lesson.lading.benefit1_point_done',
      `$3.84 points applied!`,
      `$3.84 points applied!`,
      `$3.84 points applied!`,
      `$3.84 points applied!`,
    ],
    benefit1_point_20: [
      `5,000 포인트 할인`,
      `Get $3.85 points`,
      's_trial_lesson.lading.benefit1_point_20',
      `Get $3.85 points`,
      `Get $3.85 points`,
      `Get $3.85 points`,
      `Get $3.85 points`,
    ],
    benefit1_point_done_20: [
      `5,000 포인트 할인 완료!`,
      `$3.85 points applied!`,
      's_trial_lesson.lading.benefit1_point_done_20',
      `$3.85 points applied!`,
      `$3.85 points applied!`,
      `$3.85 points applied!`,
      `$3.85 points applied!`,
    ],
    benefit2: [
      `프로필 입력하기`,
      `Complete your profile`,
      's_trial_lesson.lading.benefit2',
      `Complete your profile`,
      `Complete your profile`,
      `Complete your profile`,
      `Complete your profile`,
    ],
    benefit2_point: [
      `10,000 포인트 할인`,
      `Get $7.69 points`,
      's_trial_lesson.lading.benefit2_point',
      `Get $7.69 points`,
      `Get $7.69 points`,
      `Get $7.69 points`,
      `Get $7.69 points`,
    ],
    benefit2_point_done: [
      `10,000 포인트 할인 완료!`,
      `$7.69 points applied!`,
      's_trial_lesson.lading.benefit2_point_done',
      `$7.69 points applied!`,
      `$7.69 points applied!`,
      `$7.69 points applied!`,
      `$7.69 points applied!`,
    ],
    benefit3: [
      `24시간 이내 시작하는 수업 예약하기`,
      `Book & take a lesson within 24h`,
      's_trial_lesson.lading.benefit3',
      `Book & take a lesson within 24h`,
      `Book & take a lesson within 24h`,
      `Book & take a lesson within 24h`,
      `Book & take a lesson within 24h`,
    ],
    benefit3_discount: [
      `결제 시 5000원 즉시 할인`,
      `$3.84 off upon purchase`,
      's_trial_lesson.lading.benefit3_discount',
      `$3.84 off upon purchase`,
      `$3.84 off upon purchase`,
      `$3.84 off upon purchase`,
      `$3.84 off upon purchase`,
    ],
    benefit3_discount_done: [
      `5,000원 즉시 할인 완료!`,
      `$3.84 discount applied!`,
      's_trial_lesson.lading.benefit3_discount_done',
      `$3.84 discount applied!`,
      `$3.84 discount applied!`,
      `$3.84 discount applied!`,
      `$3.84 discount applied!`,
    ],
    benefit3_discount_20: [
      `결제 시 5000원 즉시 할인`,
      `$4 off upon purchase`,
      's_trial_lesson.lading.benefit3_discount_20',
      `$4 off upon purchase`,
      `$4 off upon purchase`,
      `$4 off upon purchase`,
      `$4 off upon purchase`,
    ],
    benefit3_discount_done_20: [
      `5,000원 즉시 할인 완료!`,
      `$4 discount applied!`,
      's_trial_lesson.lading.benefit3_discount_done_20',
      `$4 discount applied!`,
      `$4 discount applied!`,
      `$4 discount applied!`,
      `$4 discount applied!`,
    ],
    benefit_info1: [
      `1회 수업권은 가입 기준 24시간 이내에만 구매할 수 있어요.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      's_trial_lesson.lading.benefit_info1',
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
    ],
    benefit_info2: [
      `1회 수업권은 예약 시점 기준 7일 이내 수업만 선택할 수 있어요.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      's_trial_lesson.lading.benefit_info2',
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
    ],
    benefit_info3: [
      `1회 수업권은 한 번만 구매할 수 있어요.`,
      `The trial lesson credit can only be purchased once per user.`,
      's_trial_lesson.lading.benefit_info3',
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
    ],
    benefit_info4: [
      `링글 포인트는 1회 수업권 또는 일반 수업권 결제에 사용할 수 있어요.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      's_trial_lesson.lading.benefit_info4',
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
    ],
    btn_trial: [
      `혜택받고 첫 수업 예약하기`,
      `Get benefits and book first lesson`,
      's_trial_lesson.lading.btn_trial',
      `Get benefits and book first lesson`,
      `Get benefits and book first lesson`,
      `Get benefits and book first lesson`,
      `Get benefits and book first lesson`,
    ],
    welcome_hello_free: (name) => [
      <>
        {name}님,
        <br />
        링글 첫 수업을 <br />
        <span className="text-[#f53428]">무료</span>로 체험하세요!
      </>,
      <>
        {name},<br />
        Try your first lesson
        <br />
        with Ringle for free!
      </>,
      's_trial_lesson.lading.welcome_hello_free',
      <>
        {name},<br />
        Try your first lesson
        <br />
        with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson
        <br />
        with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson
        <br />
        with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson
        <br />
        with Ringle for free!
      </>,
    ],
    welcome_hello_free_PC: (name) => [
      <>
        {name}님,
        <br />
        링글 첫 수업을 <span className="text-[#f53428]">무료</span>로 체험하세요!
      </>,
      <>
        {name},<br />
        Try your first lesson with Ringle for free!
      </>,
      's_trial_lesson.lading.welcome_hello_free_PC',
      <>
        {name},<br />
        Try your first lesson with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson with Ringle for free!
      </>,
      <>
        {name},<br />
        Try your first lesson with Ringle for free!
      </>,
    ],
    label1_free: [
      `신규회원 특별 혜택`,
      `Newcomers Only`,
      's_trial_lesson.lading.label1_free',
      `Newcomers Only`,
      `Newcomers Only`,
      `Newcomers Only`,
      `Newcomers Only`,
    ],
    price_discount_title_free: [
      `특별 할인가`,
      `Special offer`,
      's_trial_lesson.lading.price_discount_title_free',
      `Special offer`,
      `Special offer`,
      `Special offer`,
      `Special offer`,
    ],
    price_discount_free: [
      `20,000원`,
      `$15.38`,
      's_trial_lesson.lading.price_discount_free',
      `$15.38`,
      `$15.38`,
      `$15.38`,
      `$15.38`,
    ],
    price_trial_title_free: [
      `최대 혜택 적용가`,
      `With max. discount`,
      's_trial_lesson.lading.price_trial_title_free',
      `With max. discount`,
      `With max. discount`,
      `With max. discount`,
      `With max. discount`,
    ],
    price_total_free: [`무료`, `Free`, 's_trial_lesson.lading.price_total_free', `Free`, `Free`, `Free`, `Free`],
    benefit_info1_free: [
      `1회 수업권은 가입 기준 24시간 이내에만 신청할 수 있어요.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      's_trial_lesson.lading.benefit_info1_free',
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
      `The trial lesson credit is only available for 24 hours from the time you join Ringle.`,
    ],
    benefit_info2_free: [
      `1회 수업권은 예약 시점 기준 7일 이내 수업만 선택할 수 있어요.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      's_trial_lesson.lading.benefit_info2_free',
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
      `You can only book lessons starting within 7 days using the trial lesson credit.`,
    ],
    benefit_info3_free: [
      `1회 수업권은 한 번만 신청할 수 있어요.`,
      `The trial lesson credit can only be purchased once per user.`,
      's_trial_lesson.lading.benefit_info3_free',
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
      `The trial lesson credit can only be purchased once per user.`,
    ],
    benefit_info4_free: [
      `링글 포인트는 1회 수업권 또는 일반 수업권 결제에 사용할 수 있어요.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      's_trial_lesson.lading.benefit_info4_free',
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
      `Ringle points can be used for purchasing the trial lesson credit or regular credits.`,
    ],
    btn_trial_free: [
      `혜택 받고 무료 체험하기`,
      `Earn points and try your free trial`,
      's_trial_lesson.lading.btn_trial_free',
      `Earn points and try your free trial`,
      `Earn points and try your free trial`,
      `Earn points and try your free trial`,
      `Earn points and try your free trial`,
    ],
    benefit_info5_free: [
      `체험수업과 신규회원 혜택 안내를 위해 마케팅 수신에 동의가 필요해요.`,
      `To book a trial lesson, please agree to receive marketing information.`,
      's_trial_lesson.lading.benefit_info5_free',
      `To book a trial lesson, please agree to receive marketing information.`,
      `To book a trial lesson, please agree to receive marketing information.`,
      `To book a trial lesson, please agree to receive marketing information.`,
      `To book a trial lesson, please agree to receive marketing information.`,
    ],
    info_title: [
      `보너스 혜택`,
      `Bonus benefit`,
      's_trial_lesson.lading.info_title',
      `Bonus benefit`,
      `Bonus benefit`,
      `Bonus benefit`,
      `Bonus benefit`,
    ],
    benefit_info_title: [
      `1회 수업권 안내`,
      `Terms and conditions`,
      's_trial_lesson.lading.benefit_info_title',
      `Terms and conditions`,
      `Terms and conditions`,
      `Terms and conditions`,
      `Terms and conditions`,
    ],
    benefit_info5: [
      `1회 수업권과 신규회원 혜택 안내를 위해 마케팅 수신에 동의가 필요해요.`,
      `Consent to receive marketing information is required to receive information about trial lesson and new members benefits.`,
      's_trial_lesson.lading.benefit_info5',
      `Consent to receive marketing information is required to receive information about trial lesson and new members benefits.`,
      `Consent to receive marketing information is required to receive information about trial lesson and new members benefits.`,
      `Consent to receive marketing information is required to receive information about trial lesson and new members benefits.`,
      `Consent to receive marketing information is required to receive information about trial lesson and new members benefits.`,
    ],
    benefit_info6: [
      `1회 수업권으로 수업을 완료하면 24시간 동안 사용할 수 있는 10,000 포인트가 자동 적립돼요.`,
      `You will automatically receive $7.69 points when you complete the trial lesson.`,
      's_trial_lesson.lading.benefit_info6',
      `You will automatically receive 7.69 points when you complete the trial lesson.`,
      `You will automatically receive 7.69 points when you complete the trial lesson.`,
      `You will automatically receive 7.69 points when you complete the trial lesson.`,
      `You will automatically receive 7.69 points when you complete the trial lesson.`,
    ],
    benefit_info7: [
      `1회 수업권으로 수업을 완료하면 24시간 동안 사용할 수 있는 50,000 포인트가 자동 적립돼요.`,
      `You will automatically receive $38 points when you complete the trial lesson.`,
      's_trial_lesson.lading.benefit_info7',
      `You will automatically receive $38 points when you complete the trial lesson.`,
      `You will automatically receive $38 points when you complete the trial lesson.`,
      `You will automatically receive $38 points when you complete the trial lesson.`,
      `You will automatically receive $38 points when you complete the trial lesson.`,
    ],
    welcome_hello_free2: [
      <>
        링글 첫 수업을 <br />
        <span className="text-[#f53428]">무료</span>로 체험하세요!
      </>,
      <>
        Try your first lesson
        <br />
        on Ringle for <span className="text-[#f53428]">free!</span>
      </>,
      's_trial_lesson.lading.welcome_hello_free2',
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
      <>
        Ringle初レッスンを
        <br />
        体験してみよう
        <span className="text-[#f53428]"> 無料</span>でお試しください!
      </>,
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
    ],
    welcome_event_detail: [
      <>
        친구 초대로 가입한 회원님에게 제공되는 <br />
        혜택을 확인해보세요.{' '}
      </>,
      <>
        Check out the special benefits
        <br />
        available to you.
      </>,
      's_trial_lesson.lading.welcome_event_detail',
      <>
        Check out the special benefits
        <br />
        available to you.
      </>,
      <>
        Check out the special benefits
        <br />
        available to you.
      </>,
      <>
        友達紹介で入会した会員に提供される
        <br />
        特典をご覧ください。 特典をご確認ください。{' '}
      </>,
      <>
        Check out the special benefits
        <br />
        available to you.
      </>,
    ],
    welcome_event_detail1: [
      <>
        친구 초대 혜택으로 제공되는 <br />
        무료 체험 수업을 신청하세요.{' '}
      </>,
      <>
        Try out the free trial
        <br />
        available to invited newcomers.
      </>,
      's_trial_lesson.lading.welcome_event_detail1',
      <>
        进行免费体验
        <br />
        经由朋友邀请的新会员限定
      </>,
      <>
        進行免費體驗
        <br />
        經由朋友邀請的新會員限定
      </>,
      <>
        友達紹介で入会した会員に提供される
        <br />
        特典をご覧ください。 無料体験レッスンをお申し込みください。{' '}
      </>,
      <>
        Try out the free trial
        <br />
        available to invited newcomers.
      </>,
    ],
    label2_free: [
      `친구 초대 특별 혜택`,
      `Referral Event`,
      's_trial_lesson.lading.label2_free',
      `Referral Event`,
      `Referral Event`,
      `友達紹介特典`,
      `Referral Event`,
    ],
  },
  point: {
    btn_skip: [`건너뛰기`, `Skip`, 's_trial_lesson.point.btn_skip', `Skip`, `Skip`, `Skip`, `Skip`],
    indecator1: [
      `포인트 받기 1/2`,
      `Receive points 1/2`,
      's_trial_lesson.point.indecator1',
      `Receive points 1/3`,
      `Receive points 1/4`,
      `Receive points 1/5`,
      `Receive points 1/6`,
    ],
    title1: [
      <>
        링글 수업을 위한
        <br />
        4가지 준비 영상을 모두 확인하면
        <br />
        <span className="text-[#503CC8]">5,000 포인트</span>를 드려요.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
      's_trial_lesson.point.title1',
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
    ],
    title1_20: [
      <>
        링글 수업을 위한
        <br />
        4가지 준비사항을 확인하세요.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.85 points</span>.
      </>,
      's_trial_lesson.point.title1_20',
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.85 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.85 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.85 points</span>.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.85 points</span>.
      </>,
    ],
    btn_done: [`완료`, `bDone`, 's_trial_lesson.point.btn_done', `bDone`, `bDone`, `bDone`, `bDone`],
    desc1: [
      `포인트는 수업권 구매 시 바로 사용할 수 있어요.`,
      `The points can be used for purchasing credits.`,
      's_trial_lesson.point.desc1',
      `The points can be used for purchasing credits.`,
      `The points can be used for purchasing credits.`,
      `The points can be used for purchasing credits.`,
      `The points can be used for purchasing credits.`,
    ],
    btn_next: [`다음`, `Next`, 's_trial_lesson.point.btn_next', `Next`, `Next`, `Next`, `Next`],
    indecator2: [
      `포인트 받기 2/2`,
      `Receive points 2/2`,
      's_trial_lesson.point.indecator2',
      `Receive points 2/3`,
      `Receive points 2/4`,
      `Receive points 2/5`,
      `Receive points 2/6`,
    ],
    title2: [
      <>
        프로필을 완성하면
        <br />
        <span className="text-[#503CC8]">10,000 포인트</span>를 드려요.
      </>,
      <>
        Complete your profile
        <br />
        and get <span className="text-[#503CC8]">$7.69 points</span>.
      </>,
      's_trial_lesson.point.title2',
      <>
        Complete your profile
        <br />
        and get <span className="text-[#503CC8]">$7.69 points</span>.
      </>,
      <>
        Complete your profile
        <br />
        and get <span className="text-[#503CC8]">$7.69 points</span>.
      </>,
      <>
        Complete your profile
        <br />
        and get <span className="text-[#503CC8]">$7.69 points</span>.
      </>,
      <>
        Complete your profile
        <br />
        and get <span className="text-[#503CC8]">$7.69 points</span>.
      </>,
    ],
    desc2: [
      `튜터가 더 알찬 맞춤 수업을 준비할 수 있어요.`,
      `This helps tutors to customize lessons for you.`,
      's_trial_lesson.point.desc2',
      `This helps tutors to customize lessons for you.`,
      `This helps tutors to customize lessons for you.`,
      `This helps tutors to customize lessons for you.`,
      `This helps tutors to customize lessons for you.`,
    ],
    info1: [
      `프로필 페이지에서 수정할 수 있습니다.`,
      `You can make changes later at your profile page.`,
      's_trial_lesson.point.info1',
      `You can make changes later at your profile page.`,
      `You can make changes later at your profile page.`,
      `You can make changes later at your profile page.`,
      `You can make changes later at your profile page.`,
    ],
    profile_maximum1: [
      `(복수선택 가능, 최대 5개)`,
      `(Select up to 5)`,
      's_trial_lesson.point.profile_maximum1',
      `(Select up to 5)`,
      `(Select up to 5)`,
      `(Select up to 5)`,
      `(Select up to 5)`,
    ],
    profile_maximum2: [
      `(복수선택 가능, 최대 3개)`,
      `(Select up to 3)`,
      's_trial_lesson.point.profile_maximum2',
      `(Select up to 3)`,
      `(Select up to 3)`,
      `(Select up to 3)`,
      `(Select up to 3)`,
    ],
    tip1: [`Tip1`, `Tip1`, 's_trial_lesson.point.tip1', `Tip1`, `Tip1`, `Tip1`, ``],
    tip2: [`Tip2`, `Tip2`, 's_trial_lesson.point.tip2', `Tip2`, `Tip2`, `Tip2`, ``],
    tip3: [`Tip3`, `Tip3`, 's_trial_lesson.point.tip3', `Tip3`, `Tip3`, `Tip3`, ``],
    tip4: [`Tip4`, `Tip4`, 's_trial_lesson.point.tip4', `Tip4`, `Tip4`, `Tip4`, ``],
    profile1: [
      `1. 어떤 목적으로 영어 공부를 시작하나요?`,
      `1. What are your goals for learning English?`,
      's_trial_lesson.point.profile1',
      `1. What are your goals for learning English?`,
      `1. What are your goals for learning English?`,
      `1. What are your goals for learning English?`,
      `1. What are your goals for learning English?`,
    ],
    profile1_category1: [
      `비즈니스`,
      `Work-related`,
      's_trial_lesson.point.profile1_category1',
      `Work-related`,
      `Work-related`,
      `Work-related`,
      `Work-related`,
    ],
    profile1_select1: [
      `영어 발표/미팅 능력 향상`,
      `Better presentations/meetings in English`,
      's_trial_lesson.point.profile1_select1',
      `Better presentations/meetings in English`,
      `Better presentations/meetings in English`,
      `Better presentations/meetings in English`,
      `Better presentations/meetings in English`,
    ],
    profile1_select2: [
      `해외 컨퍼런스 및 출장 대비`,
      `Prepare for overseas business trips`,
      's_trial_lesson.point.profile1_select2',
      `Prepare for overseas business trips`,
      `Prepare for overseas business trips`,
      `Prepare for overseas business trips`,
      `Prepare for overseas business trips`,
    ],
    profile1_select3: [
      `회사 동료/상사와 스몰토크 스킬 키우기`,
      `Better daily communication at work`,
      's_trial_lesson.point.profile1_select3',
      `Better daily communication at work`,
      `Better daily communication at work`,
      `Better daily communication at work`,
      `Better daily communication at work`,
    ],
    profile1_select4: [
      `업무 상 영어 작문 능력 향상`,
      `Better English writing skills for work`,
      's_trial_lesson.point.profile1_select4',
      `Better English writing skills for work`,
      `Better English writing skills for work`,
      `Better English writing skills for work`,
      `Better English writing skills for work`,
    ],
    profile1_category2: [
      `취업/이직/유학`,
      `Employment/study abroad`,
      's_trial_lesson.point.profile1_category2',
      `Employment/study abroad`,
      `Employment/study abroad`,
      `Employment/study abroad`,
      `Employment/study abroad`,
    ],
    profile1_select5: [
      `해외로 이직 준비`,
      `Get employed overseas`,
      's_trial_lesson.point.profile1_select5',
      `Get employed overseas`,
      `Get employed overseas`,
      `Get employed overseas`,
      `Get employed overseas`,
    ],
    profile1_select6: [
      `외국계 회사로 이직 준비`,
      `Get employed at a foreign company`,
      's_trial_lesson.point.profile1_select6',
      `Get employed at a foreign company`,
      `Get employed at a foreign company`,
      `Get employed at a foreign company`,
      `Get employed at a foreign company`,
    ],
    profile1_select7: [
      `해외로 유학 준비`,
      `Prepare for studying abroad`,
      's_trial_lesson.point.profile1_select7',
      `Prepare for studying abroad`,
      `Prepare for studying abroad`,
      `Prepare for studying abroad`,
      `Prepare for studying abroad`,
    ],
    profile1_select8: [
      `업무 상 영어 작문 능력 향상`,
      `Better English writing skills for work`,
      's_trial_lesson.point.profile1_select8',
      `Better English writing skills for work`,
      `Better English writing skills for work`,
      `Better English writing skills for work`,
      `Better English writing skills for work`,
    ],
    profile1_select9: [
      `영어 에세이/이력서 작성`,
      `Write English essays/resumes`,
      's_trial_lesson.point.profile1_select9',
      `Write English essays/resumes`,
      `Write English essays/resumes`,
      `Write English essays/resumes`,
      `Write English essays/resumes`,
    ],
    profile1_select10: [
      `영어 인터뷰 준비`,
      `Prepare for English interviews`,
      's_trial_lesson.point.profile1_select10',
      `Prepare for English interviews`,
      `Prepare for English interviews`,
      `Prepare for English interviews`,
      `Prepare for English interviews`,
    ],
    profile1_select11: [
      `어학 시험 준비`,
      `Prepare for language proficiency tests`,
      's_trial_lesson.point.profile1_select11',
      `Prepare for language proficiency tests`,
      `Prepare for language proficiency tests`,
      `Prepare for language proficiency tests`,
      `Prepare for language proficiency tests`,
    ],
    profile1_category3: [
      `자기계발`,
      `Personal development`,
      's_trial_lesson.point.profile1_category3',
      `Personal development`,
      `Personal development`,
      `Personal development`,
      `Personal development`,
    ],
    profile1_select12: [
      `원어민과 스몰토크 스킬 키우기`,
      `Enjoy small talk with foreigners`,
      's_trial_lesson.point.profile1_select12',
      `Enjoy small talk with foreigners`,
      `Enjoy small talk with foreigners`,
      `Enjoy small talk with foreigners`,
      `Enjoy small talk with foreigners`,
    ],
    profile1_select13: [
      `영어로 대화/토론하는 것을 즐김`,
      `Hold discussions in English`,
      's_trial_lesson.point.profile1_select13',
      `Hold discussions in English`,
      `Hold discussions in English`,
      `Hold discussions in English`,
      `Hold discussions in English`,
    ],
    profile1_select14: [
      `현재 영어 실력 유지`,
      `Maintain English proficiency`,
      's_trial_lesson.point.profile1_select14',
      `Maintain English proficiency`,
      `Maintain English proficiency`,
      `Maintain English proficiency`,
      `Maintain English proficiency`,
    ],
    profile1_select15: [
      `영어권 국가의 문화 이해`,
      `Understand English-based culture`,
      's_trial_lesson.point.profile1_select15',
      `Understand English-based culture`,
      `Understand English-based culture`,
      `Understand English-based culture`,
      `Understand English-based culture`,
    ],
    profile1_select16: [
      `여행/휴가 시 더 유창한 영어 구사`,
      `English for travelling overseas`,
      's_trial_lesson.point.profile1_select16',
      `English for travelling overseas`,
      `English for travelling overseas`,
      `English for travelling overseas`,
      `English for travelling overseas`,
    ],
    profile1_select17: [
      `기타(주관식)`,
      `Other`,
      's_trial_lesson.point.profile1_select17',
      `Other`,
      `Other`,
      `Other`,
      `Other`,
    ],
    profile1_input: [
      `영어 공부 목적을 입력해 주세요.`,
      `Please tell us why you wish to learn English.`,
      's_trial_lesson.point.profile1_input',
      `Please tell us why you wish to learn English.`,
      `Please tell us why you wish to learn English.`,
      `Please tell us why you wish to learn English.`,
      `Please tell us why you wish to learn English.`,
    ],
    profile2: [
      `2. 집중적으로 개선하고 싶은 영역이 무엇인가요?`,
      `2. Which area(s) do you wish to improve?`,
      's_trial_lesson.point.profile2',
      `2. Which area(s) do you wish to improve?`,
      `2. Which area(s) do you wish to improve?`,
      `2. Which area(s) do you wish to improve?`,
      `2. Which area(s) do you wish to improve?`,
    ],
    profile2_select1: [
      `기초 어휘부터 배우고 싶어요.`,
      `Basic vocabulary`,
      's_trial_lesson.point.profile2_select1',
      `Basic vocabulary`,
      `Basic vocabulary`,
      `Basic vocabulary`,
      `Basic vocabulary`,
    ],
    profile2_select2: [
      `새로운 고급 표현을 배우고 싶어요.`,
      `New advanced expressions`,
      's_trial_lesson.point.profile2_select2',
      `New advanced expressions`,
      `New advanced expressions`,
      `New advanced expressions`,
      `New advanced expressions`,
    ],
    profile2_select3_mobile: [
      <>
        원어민이 자주 쓰는 자연스러운 표현을
        <br />
        배우고 싶어요.
      </>,
      <>
        Common expressions that
        <br />
        native English speakers use
      </>,
      's_trial_lesson.point.profile2_select3_mobile',
      <>
        Common expressions that
        <br />
        native English speakers use
      </>,
      <>
        Common expressions that
        <br />
        native English speakers use
      </>,
      <>
        Common expressions that
        <br />
        native English speakers use
      </>,
      <>
        Common expressions that
        <br />
        native English speakers use
      </>,
    ],
    profile2_select3_pc: [
      `원어민이 자주 쓰는 자연스러운 표현을 배우고 싶어요.`,
      `Common expressions that native English speakers use`,
      's_trial_lesson.point.profile2_select3_pc',
      `Common expressions that native English speakers use`,
      `Common expressions that native English speakers use`,
      `Common expressions that native English speakers use`,
      `Common expressions that native English speakers use`,
    ],
    profile2_select4: [
      `영어로 간결하고 정확하게 표현하고 싶어요.`,
      `Speaking concisely and clearly in English`,
      's_trial_lesson.point.profile2_select4',
      `Speaking concisely and clearly in English`,
      `Speaking concisely and clearly in English`,
      `Speaking concisely and clearly in English`,
      `Speaking concisely and clearly in English`,
    ],
    profile2_select5: [
      `원어민 같은 발음/액센트로 말하고 싶어요.`,
      `Pronunciation/accent`,
      's_trial_lesson.point.profile2_select5',
      `Pronunciation/accent`,
      `Pronunciation/accent`,
      `Pronunciation/accent`,
      `Pronunciation/accent`,
    ],
    profile2_select6: [
      `막힘없이 영어로 대화하고 싶어요.`,
      `Conversing fluidly in English`,
      's_trial_lesson.point.profile2_select6',
      `Conversing fluidly in English`,
      `Conversing fluidly in English`,
      `Conversing fluidly in English`,
      `Conversing fluidly in English`,
    ],
    profile2_select7: [
      `영어로 논리적으로 대화하고 싶어요.`,
      `Logical English speaking`,
      's_trial_lesson.point.profile2_select7',
      `Logical English speaking`,
      `Logical English speaking`,
      `Logical English speaking`,
      `Logical English speaking`,
    ],
    profile2_select8: [
      `영어로 논리적으로 글을 작성하고 싶어요.`,
      `Logical English writing`,
      's_trial_lesson.point.profile2_select8',
      `Logical English writing`,
      `Logical English writing`,
      `Logical English writing`,
      `Logical English writing`,
    ],
    profile3: [
      `3. 어떤 주제로 영어 공부를 하고 싶나요?`,
      `3. What are the topics that interest you?`,
      's_trial_lesson.point.profile3',
      `3. What are the topics that interest you?`,
      `3. What are the topics that interest you?`,
      `3. What are the topics that interest you?`,
      `3. What are the topics that interest you?`,
    ],
    profile3_select1: [
      `Business Case`,
      `Business Case`,
      's_trial_lesson.point.profile3_select1',
      `Business Case`,
      `Business Case`,
      `Business Case`,
      `Business Case`,
    ],
    profile3_select2: [
      `Economy`,
      `Economy`,
      's_trial_lesson.point.profile3_select2',
      `Economy`,
      `Economy`,
      `Economy`,
      `Economy`,
    ],
    profile3_select3_mobile: [
      <>
        Society/
        <br />
        Politics
      </>,
      <>
        Society/
        <br />
        Politics
      </>,
      's_trial_lesson.point.profile3_select3_mobile',
      <>
        Society/
        <br />
        Politics
      </>,
      <>
        Society/
        <br />
        Politics
      </>,
      <>
        Society/
        <br />
        Politics
      </>,
      <>
        Society/
        <br />
        Politics
      </>,
    ],
    profile3_select3_pc: [
      `Society/Politics`,
      `Society/Politics`,
      's_trial_lesson.point.profile3_select3_pc',
      `Society/Politics`,
      `Society/Politics`,
      `Society/Politics`,
      `Society/Politics`,
    ],
    profile3_select4: [`Tech`, `Tech`, 's_trial_lesson.point.profile3_select4', `Tech`, `Tech`, `Tech`, `Tech`],
    profile3_select5_mobile: [
      <>
        Science/
        <br />
        Environment
      </>,
      <>
        Science/
        <br />
        Environment
      </>,
      's_trial_lesson.point.profile3_select5_mobile',
      <>
        Science/
        <br />
        Environment
      </>,
      <>
        Science/
        <br />
        Environment
      </>,
      <>
        Science/
        <br />
        Environment
      </>,
      <>
        Science/
        <br />
        Environment
      </>,
    ],
    profile3_select5_pc: [
      `Science/Environment`,
      `Science/Environment`,
      's_trial_lesson.point.profile3_select5_pc',
      `Science/Environment`,
      `Science/Environment`,
      `Science/Environment`,
      `Science/Environment`,
    ],
    profile3_select6: [
      `Office Life`,
      `Office Life`,
      's_trial_lesson.point.profile3_select6',
      `Office Life`,
      `Office Life`,
      `Office Life`,
      `Office Life`,
    ],
    profile3_select7_mobile: [
      <>
        Culture/
        <br />
        Entertainment
      </>,
      <>
        Culture/
        <br />
        Entertainment
      </>,
      's_trial_lesson.point.profile3_select7_mobile',
      <>
        Culture/
        <br />
        Entertainment
      </>,
      <>
        Culture/
        <br />
        Entertainment
      </>,
      <>
        Culture/
        <br />
        Entertainment
      </>,
      <>
        Culture/
        <br />
        Entertainment
      </>,
    ],
    profile3_select7_pc: [
      `Culture/Entertainment`,
      `Culture/Entertainment`,
      's_trial_lesson.point.profile3_select7_pc',
      `Culture/Entertainment`,
      `Culture/Entertainment`,
      `Culture/Entertainment`,
      `Culture/Entertainment`,
    ],
    profile3_select8: [
      `Sports`,
      `Sports`,
      's_trial_lesson.point.profile3_select8',
      `Sports`,
      `Sports`,
      `Sports`,
      `Sports`,
    ],
    won: (price) => [
      `${price}원`,
      `$${price}`,
      's_trial_lesson.point.won',
      `$${price}`,
      `$${price}`,
      `$${price}`,
      `$${price}`,
    ],
  },
  price: {
    info: [
      `1회 수업권 결제 금액`,
      `Trial lesson credit`,
      's_trial_lesson.price.info',
      `免费体验课程券`,
      `免費體驗課程券`,
      `無料体験コース券`,
      `Trial lesson credit`,
    ],
  },
  modal: {
    point1: [
      `5,000 포인트 적립 완료!`,
      `$3.84 points applied!`,
      's_trial_lesson.modal.point1',
      `$3.84 points applied!`,
      `$3.84 points applied!`,
      `$3.84 points applied!`,
      `$3.84 points applied!`,
    ],
    point1_20: [
      `5,000 포인트 적립 완료!`,
      `$3.85 points applied!`,
      's_trial_lesson.modal.point1_20',
      `$3.85 points applied!`,
      `$3.85 points applied!`,
      `$3.85 points applied!`,
      `$3.85 points applied!`,
    ],
    btn_ok: [`확인`, `Confirm`, 's_trial_lesson.modal.btn_ok', `Confirm`, `Confirm`, `Confirm`, `Confirm`],
    point2: [
      `10,000 포인트 적립 완료!`,
      `$7.69 points applied!`,
      's_trial_lesson.modal.point2',
      `$7.69 points applied!`,
      `$7.69 points applied!`,
      `$7.69 points applied!`,
      `$7.69 points applied!`,
    ],
    skip: (point) => [
      <>
        {point} 포인트를 더 받을 수 있어요.
        <br />
        그래도 수업을 바로 예약하시겠어요?
      </>,
      <>
        You can get ${point} more points.
        <br />
        Do you wish to skip and book a lesson now?
      </>,
      's_trial_lesson.modal.skip',
      <>
        You can get ${point} more points.
        <br />
        Do you wish to skip and book a lesson now?
      </>,
      <>
        You can get ${point} more points.
        <br />
        Do you wish to skip and book a lesson now?
      </>,
      <>
        You can get ${point} more points.
        <br />
        Do you wish to skip and book a lesson now?
      </>,
      <>
        You can get ${point} more points.
        <br />
        Do you wish to skip and book a lesson now?
      </>,
    ],
    todo1: [
      `링글 수업 알아보기 (5,000 포인트)`,
      `Watch Ringle lesson tips ($3.84 P)`,
      's_trial_lesson.modal.todo1',
      `Watch Ringle lesson tips ($3.84 P)`,
      `Watch Ringle lesson tips ($3.84 P)`,
      `Watch Ringle lesson tips ($3.84 P)`,
      `Watch Ringle lesson tips ($3.84 P)`,
    ],
    todo1_20: [
      `링글 수업 알아보기 (5,000 포인트)`,
      `Watch Ringle lesson tips ($3.85 P)`,
      's_trial_lesson.modal.todo1_20',
      `Watch Ringle lesson tips ($3.85 P)`,
      `Watch Ringle lesson tips ($3.85 P)`,
      `Watch Ringle lesson tips ($3.85 P)`,
      `Watch Ringle lesson tips ($3.85 P)`,
    ],
    todo2: [
      `프로필 입력하기 (10,000 포인트)`,
      `Complete profile ($7.69 P)`,
      's_trial_lesson.modal.todo2',
      `Complete profile ($7.69 P)`,
      `Complete profile ($7.69 P)`,
      `Complete profile ($7.69 P)`,
      `Complete profile ($7.69 P)`,
    ],
    btn_back: [
      `포인트 받으러 가기`,
      `Get more points`,
      's_trial_lesson.modal.btn_back',
      `Get more points`,
      `Get more points`,
      `Get more points`,
      `Get more points`,
    ],
    btn_skip: [
      `예약하기`,
      `Book lessons`,
      's_trial_lesson.modal.btn_skip',
      `Book lessons`,
      `Book lessons`,
      `Book lessons`,
      `Book lessons`,
    ],
    exit_pc: (time) => [
      <>1회 수업권이 {time} 후 사라져요.</>,
      <>Trial lesson credit disappears in {time}.</>,
      's_trial_lesson.modal.exit_pc',
      <>Trial lesson credit disappears in {time}.</>,
      <>Trial lesson credit disappears in {time}.</>,
      <>Trial lesson credit disappears in {time}.</>,
      <>Trial lesson credit disappears in {time}.</>,
    ],
    exit_mobile: (time) => [
      <>
        1회 수업권이 {time} 후<br />
        사라져요.
      </>,
      <>
        Trial lesson credit disappears in
        <br />
        {time}.
      </>,
      's_trial_lesson.modal.exit_mobile',
      <>
        Trial lesson credit disappears in
        <br />
        {time}.
      </>,
      <>
        Trial lesson credit disappears in
        <br />
        {time}.
      </>,
      <>
        Trial lesson credit disappears in
        <br />
        {time}.
      </>,
      <>
        Trial lesson credit disappears in
        <br />
        {time}.
      </>,
    ],
    exit_desc: [
      <>
        지금 취소하면 현재까지의 예약 내역이 삭제되지만,
        <br />
        남은 시간 내에 언제든지 다시 예약할 수 있어요.
      </>,
      <>
        Your booking info will be deleted if you quit here.
        <br />
        Please book a lesson within the deadline above.
      </>,
      's_trial_lesson.modal.exit_desc',
      <>
        Your booking info will be deleted if you quit here.
        <br />
        Please book a lesson within the deadline above.
      </>,
      <>
        Your booking info will be deleted if you quit here.
        <br />
        Please book a lesson within the deadline above.
      </>,
      <>
        Your booking info will be deleted if you quit here.
        <br />
        Please book a lesson within the deadline above.
      </>,
      <>
        Your booking info will be deleted if you quit here.
        <br />
        Please book a lesson within the deadline above.
      </>,
    ],
    btn_close: [
      `예약 계속하기`,
      `Continue`,
      's_trial_lesson.modal.btn_close',
      `Continue`,
      `Continue`,
      `Continue`,
      `Continue`,
    ],
    btn_exit: [`예약 취소하기`, `Quit`, 's_trial_lesson.modal.btn_exit', `Quit`, `Quit`, `Quit`, `Quit`],
    receive: [
      `이벤트 및 혜택 소식 수신 동의`,
      <>
        Receive notifications on events & <br />
        offers
      </>,
      's_trial_lesson.modal.receive',
      <>
        Receive notifications on events & <br />
        offers
      </>,
      <>
        Receive notifications on events & <br />
        offers
      </>,
      <>
        Receive notifications on events & <br />
        offers
      </>,
      <>
        Receive notifications on events & <br />
        offers
      </>,
    ],
    receive_desc: [
      `체험 수업과 신규 회원 할인 쿠폰에 대한 소식을 수신하고 싶습니다.`,
      `I want to receive receive updates about trial lessons and discount coupons for newcomers.`,
      's_trial_lesson.modal.receive_desc',
      `I want to receive receive updates about trial lessons and discount coupons for newcomers.`,
      `I want to receive receive updates about trial lessons and discount coupons for newcomers.`,
      `I want to receive receive updates about trial lessons and discount coupons for newcomers.`,
      `I want to receive receive updates about trial lessons and discount coupons for newcomers.`,
    ],
    btn_cancel: [`취소`, `Cancel`, 's_trial_lesson.modal.btn_cancel', `Cancel`, `Cancel`, `Cancel`, `Cancel`],
    btn_agree: [`동의`, `Accept`, 's_trial_lesson.modal.btn_agree', `Accept`, `Accept`, `Accept`, `Accept`],
    other_customer_took: [
      `이미 다른 고객분이 신청하셨습니다.`,
      `Sorry, this slot is taken.`,
      's_trial_lesson.modal.other_customer_took',
      `Sorry, this slot is taken.`,
      `Sorry, this slot is taken.`,
      `Sorry, this slot is taken.`,
      `Sorry, this slot is taken.`,
    ],
    lesson_title: (time) => [
      <>
        {time}에<br />첫 수업이 예약되어 있어요.
      </>,
      <>
        Your first lesson is scheduled
        <br />
        for {time}
      </>,
      's_trial_lesson.modal.lesson_title',
      <>
        Your first lesson is scheduled
        <br />
        for {time}
      </>,
      <>
        Your first lesson is scheduled
        <br />
        for {time}
      </>,
      <>
        Your first lesson is scheduled
        <br />
        for {time}
      </>,
      <>
        Your first lesson is scheduled
        <br />
        for {time}
      </>,
    ],
    lesson_desc: [
      `링글 앱이나 PC에서 수업정보를 확인하고 수업준비를 해보세요.`,
      `You can check your lesson info and prepare for your lesson on the Ringle app or PC.`,
      's_trial_lesson.modal.lesson_desc',
      `You can check your lesson info and prepare for your lesson on the Ringle app or PC.`,
      `You can check your lesson info and prepare for your lesson on the Ringle app or PC.`,
      `You can check your lesson info and prepare for your lesson on the Ringle app or PC.`,
      `You can check your lesson info and prepare for your lesson on the Ringle app or PC.`,
    ],
  },
  book: {
    navi_title: [
      `수업 예약`,
      `Book lessons`,
      's_trial_lesson.book.navi_title',
      `Book lessons`,
      `Book lessons`,
      `Book lessons`,
      `Book lessons`,
    ],
    title1: [
      `수업 시간 선택`,
      `Select Date & Time`,
      's_trial_lesson.book.title1',
      `Select Date & Time`,
      `Select Date & Time`,
      `Select Date & Time`,
      `Select Date & Time`,
    ],
    desc1: [
      `24시간 이내 시작되는 수업은 5,000원이 즉시 할인돼요.`,
      `Get $3.84 off for lessons starting within 24 hours.`,
      's_trial_lesson.book.desc1',
      `Get $3.84 off for lessons starting within 24 hours.`,
      `Get $3.84 off for lessons starting within 24 hours.`,
      `Get $3.84 off for lessons starting within 24 hours.`,
      `Get $3.84 off for lessons starting within 24 hours.`,
    ],
    discount_slot: [
      `5,000원 할인되는 수업`,
      `Get $3.84 off`,
      's_trial_lesson.book.discount_slot',
      `Get $3.84 off`,
      `Get $3.84 off`,
      `Get $3.84 off`,
      `Get $3.84 off`,
    ],
    desc1_20: [
      `24시간 이내 시작되는 수업은 5,000원이 즉시 할인돼요.`,
      `Get $4 off for lessons starting within 24 hours.`,
      's_trial_lesson.book.desc1_20',
      `Get $4 off for lessons starting within 24 hours.`,
      `Get $4 off for lessons starting within 24 hours.`,
      `Get $4 off for lessons starting within 24 hours.`,
      `Get $4 off for lessons starting within 24 hours.`,
    ],
    discount_slot_20: [
      `5,000원 할인되는 수업`,
      `Get $4 off`,
      's_trial_lesson.book.discount_slot_20',
      `Get $4 off`,
      `Get $4 off`,
      `Get $4 off`,
      `Get $4 off`,
    ],
    am_slot: [`오전`, `AM`, 's_trial_lesson.book.am_slot', `AM`, `AM`, `AM`, `AM`],
    pm_slot: [`오후`, `PM`, 's_trial_lesson.book.pm_slot', `PM`, `PM`, `PM`, `PM`],
    title2: [
      `튜터 선택`,
      `Select a tutor`,
      's_trial_lesson.book.title2',
      `Select a tutor`,
      `Select a tutor`,
      `Select a tutor`,
      `Select a tutor`,
    ],
    btn_edit: [`변경`, `Change`, 's_trial_lesson.book.btn_edit', `Change`, `Change`, `Change`, `Change`],
    active_tutor: [
      `링글 추천 튜터`,
      `Featured`,
      's_trial_lesson.book.active_tutor',
      `Featured`,
      `Featured`,
      `Featured`,
      `Featured`,
    ],
    btn_profile: [
      `튜터 프로필`,
      `Tutor Profile`,
      's_trial_lesson.book.btn_profile',
      `Tutor Profile`,
      `Tutor Profile`,
      `Tutor Profile`,
      `Tutor Profile`,
    ],
    btn_tutor: [`튜터 선택`, `Select`, 's_trial_lesson.book.btn_tutor', `Select`, `Select`, `Select`, `Select`],
    btn_tutor_cancel: [
      `튜터 선택됨`,
      `Selected`,
      's_trial_lesson.book.btn_tutor_cancel',
      `Selected`,
      `Selected`,
      `Selected`,
      `Selected`,
    ],
    title3: [
      `교재 선택`,
      `Select Lesson Material`,
      's_trial_lesson.book.title3',
      `Select Lesson Material`,
      `Select Lesson Material`,
      `Select Lesson Material`,
      `Select Lesson Material`,
    ],
    desc3_mobile: [
      <>
        교재는 수업 예약 후에도 변경할 수 있어요.
        <br />
        부담 없이 교재를 선택해 주세요!
      </>,
      <>
        You can change the material later,
        <br />
        even after completing the booking.
      </>,
      's_trial_lesson.book.desc3_mobile',
      <>
        You can change the material later,
        <br />
        even after completing the booking.
      </>,
      <>
        You can change the material later,
        <br />
        even after completing the booking.
      </>,
      <>
        You can change the material later,
        <br />
        even after completing the booking.
      </>,
      <>
        You can change the material later,
        <br />
        even after completing the booking.
      </>,
    ],
    desc3_pc: [
      `교재는 수업 예약 후에도 변경할 수 있어요. 부담 없이 교재를 선택해 주세요!`,
      `You can change the material later, even after completing the booking.`,
      's_trial_lesson.book.desc3_pc',
      `You can change the material later, even after completing the booking.`,
      `You can change the material later, even after completing the booking.`,
      `You can change the material later, even after completing the booking.`,
      `You can change the material later, even after completing the booking.`,
    ],
    btn_insight: [
      `자세히 보기`,
      `View details`,
      's_trial_lesson.book.btn_insight',
      `View details`,
      `View details`,
      `View details`,
      `View details`,
    ],
    btn_material: [`교재 선택`, `Select`, 's_trial_lesson.book.btn_material', `Select`, `Select`, `Select`, `Select`],
    btn_material_cancel: [
      `교재 선택됨`,
      `Selected`,
      's_trial_lesson.book.btn_material_cancel',
      `Selected`,
      `Selected`,
      `Selected`,
      `Selected`,
    ],
    title4: [
      `예약 내역 확인`,
      `Lesson Booking Details`,
      's_trial_lesson.book.title4',
      `Lesson Booking Details`,
      `Lesson Booking Details`,
      `Lesson Booking Details`,
      `Lesson Booking Details`,
    ],
    desc4: [
      `수업 정보와 튜터 매칭 동의 확인 후 예약을 완료해 주세요!`,
      `Please check your booking details and the tutor auto-matching option.`,
      's_trial_lesson.book.desc4',
      `Please check your booking details and the tutor auto-matching option.`,
      `Please check your booking details and the tutor auto-matching option.`,
      `Please check your booking details and the tutor auto-matching option.`,
      `Please check your booking details and the tutor auto-matching option.`,
    ],
    tutor_matching: [
      `튜터 매칭 옵션`,
      `Tutor matching options`,
      's_trial_lesson.book.tutor_matching',
      `Tutor matching options`,
      `Tutor matching options`,
      `Tutor matching options`,
      `Tutor matching options`,
    ],
    tutor_matching1: (name) => [
      <>{name} 튜터의 부득이한 사정으로 매칭이 어려울 경우 평점이 높은 다른 튜터가 매칭됩니다.</>,
      <>
        In the case where tutor {name} is unable to teach the lesson, I agree to be matched with another highly-rated
        tutor.
      </>,
      's_trial_lesson.book.tutor_matching1',
      <>
        In the case where tutor {name} is unable to teach the lesson, I agree to be matched with another highly-rated
        tutor.
      </>,
      <>
        In the case where tutor {name} is unable to teach the lesson, I agree to be matched with another highly-rated
        tutor.
      </>,
      <>
        In the case where tutor {name} is unable to teach the lesson, I agree to be matched with another highly-rated
        tutor.
      </>,
      <>
        In the case where tutor {name} is unable to teach the lesson, I agree to be matched with another highly-rated
        tutor.
      </>,
    ],
    btn_purchase: [
      `결제하기`,
      `Purchase`,
      's_trial_lesson.book.btn_purchase',
      `Purchase`,
      `Purchase`,
      `Purchase`,
      `Purchase`,
    ],
    tutor_info: [
      `정규 수업에서는 더 많은 튜터를 만나볼 수 있어요`,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      's_trial_lesson.book.tutor_info',
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
    ],
    tutor_info_cx: [
      <>
        찾고 있는 튜터가 없나요?
        <br />
        <a className="text-purple-500" target="_blank" href="https://ringleplus.channel.io/">
          링글팀에 문의
        </a>
        를 남기면 원하는 튜터와 연결해 드려요.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      's_trial_lesson.book.tutor_info_cx',
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
      <>
        More tutors are available
        <br />
        for lessons using regular credits.
      </>,
    ],
    tutor_info_cx_new: [
      <>
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          링글팀에 문의
        </a>
        를 남기면 원하는 튜터와 연결해 드려요.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will connect you with your desired tutor.
      </>,
      's_trial_lesson.book.tutor_info_cx_new',
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will connect you with your desired tutor.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will connect you with your desired tutor.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will connect you with your desired tutor.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will connect you with your desired tutor.
      </>,
    ],
    material_info: [
      `정규 수업에서는 1,300개 이상의 교재 중에서 자유롭게 선택할 수 있어요.`,
      `In regular lessons, you can freely choose from over 1,300 materials.`,
      's_trial_lesson.book.material_info',
      `In regular lessons, you can freely choose from over 1,300 materials.`,
      `In regular lessons, you can freely choose from over 1,300 materials.`,
      `In regular lessons, you can freely choose from over 1,300 materials.`,
      `In regular lessons, you can freely choose from over 1,300 materials.`,
    ],
    material_info_cx: [
      <>
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          링글팀에 문의
        </a>
        를 남기면 원하는 교재를 찾아 드려요.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will help you find the materials you want.
      </>,
      's_trial_lesson.book.material_info_cx',
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will help you find the materials you want.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will help you find the materials you want.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will help you find the materials you want.
      </>,
      <>
        If you{' '}
        <a className="underline" target="_blank" href="https://ringleplus.channel.io/">
          leave an inquiry
        </a>{' '}
        with the Ringle team, we will help you find the materials you want.
      </>,
    ],
    tooltip: [
      <>
        최근 한 달간 높은 수업 평점과
        <br />
        긍정적인 후기를 받은 튜터에요.
      </>,
      `These tutors have received high ratings and positive reviews.`,
      's_trial_lesson.book.tooltip',
      `These tutors have received high ratings and positive reviews.`,
      `These tutors have received high ratings and positive reviews.`,
      `These tutors have received high ratings and positive reviews.`,
      `These tutors have received high ratings and positive reviews.`,
    ],
    _40min: [`40분 수업`, `40min`, 's_trial_lesson.book._40min', `40min`, `40min`, `40min`, `40min`],
    _20min: [`20분 수업`, `20min`, 's_trial_lesson.book._20min', `20min`, `20min`, `20min`, `20min`],
    back: [`뒤로가기`, `Back`, 's_trial_lesson.book.back', `Back`, `Back`, `Back`, `Back`],
    marketing_on: [
      `혜택 안내를 위한 마케팅 수신 동의`,
      `Marketing consent`,
      's_trial_lesson.book.marketing_on',
      `Marketing consent`,
      `Marketing consent`,
      `Marketing consent`,
      `Marketing consent`,
    ],
    marketing_on1: [
      `체험수업과 신규회원 혜택 안내를 위해 전체 마케팅 수신에 동의합니다.`,
      `I give my consent to receive marketing information.`,
      's_trial_lesson.book.marketing_on1',
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
    ],
  },
  completed: {
    title: [
      `수업 예약 및 결제가 완료되었습니다.`,
      <>
        Your booking and payment
        <br />
        has been processed.
      </>,
      's_trial_lesson.completed.title',
      <>
        Your booking and payment
        <br />
        has been processed.
      </>,
      <>
        Your booking and payment
        <br />
        has been processed.
      </>,
      <>
        Your booking and payment
        <br />
        has been processed.
      </>,
      <>
        Your booking and payment
        <br />
        has been processed.
      </>,
    ],
    purchase_info: [
      `결제 내역`,
      `Purchase history`,
      's_trial_lesson.completed.purchase_info',
      `Purchase history`,
      `Purchase history`,
      `Purchase history`,
      `Purchase history`,
    ],
    purchase_total: [
      `최종 결제 금액`,
      `Your total`,
      's_trial_lesson.completed.purchase_total',
      `Your total`,
      `Your total`,
      `Your total`,
      `Your total`,
    ],
    receive_news_mobile: [
      <>
        체험 수업과 신규 회원 할인 쿠폰에 대한 알림을
        <br />
        놓치지 않고 받아보세요.
      </>,
      <>
        Get alerted on trial lessons and
        <br />
        discount coupons for newcomers.
      </>,
      's_trial_lesson.completed.receive_news_mobile',
      <>
        Get alerted on trial lessons and
        <br />
        discount coupons for newcomers.
      </>,
      <>
        Get alerted on trial lessons and
        <br />
        discount coupons for newcomers.
      </>,
      <>
        Get alerted on trial lessons and
        <br />
        discount coupons for newcomers.
      </>,
      <>
        Get alerted on trial lessons and
        <br />
        discount coupons for newcomers.
      </>,
    ],
    receive_news_pc: [
      `체험 수업과 신규 회원 할인 쿠폰에 대한 알림을 놓치지 않고 받아보세요.`,
      `Get alerted on trial lessons and discount coupons for newcomers.`,
      's_trial_lesson.completed.receive_news_pc',
      `Get alerted on trial lessons and discount coupons for newcomers.`,
      `Get alerted on trial lessons and discount coupons for newcomers.`,
      `Get alerted on trial lessons and discount coupons for newcomers.`,
      `Get alerted on trial lessons and discount coupons for newcomers.`,
    ],
    btn_receive: [
      `알림 받기`,
      `Receive notifications`,
      's_trial_lesson.completed.btn_receive',
      `Receive notifications`,
      `Receive notifications`,
      `Receive notifications`,
      `Receive notifications`,
    ],
    checking: [
      `링글 체험 수업 전 준비 사항 4가지`,
      `What to Prepare for the Lesson`,
      's_trial_lesson.completed.checking',
      `What to Prepare for the Lesson`,
      `What to Prepare for the Lesson`,
      `What to Prepare for the Lesson`,
      `What to Prepare for the Lesson`,
    ],
    checking1_title_mobile: [
      <>
        수업은 카메라가 설치된 PC로
        <br />
        진행해 주세요.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      's_trial_lesson.completed.checking1_title_mobile',
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
    ],
    checking1_title_pc: [
      `수업은 카메라가 설치된 PC로 진행해 주세요.`,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      's_trial_lesson.completed.checking1_title_pc',
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
      <>
        Please conduct the lesson on a PC
        <br />
        with a camera installed.
      </>,
    ],
    checking1_desc1: [
      `홈페이지 [로그인] > [1:1 수업] > [예습 & 수업]을 통해 수업 시작 15분 전부터 [수업 입장] 버튼을 클릭하면 수업에 입장할 수 있어요.`,
      `You can join the lesson by logging in on the Ringle website and going to [1:1 Lessons] > [Prep & Lesson]. The [Enter Lesson] button becomes activated 15 minutes before the scheduled lesson.`,
      's_trial_lesson.completed.checking1_desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    checking1_desc2: [
      `모바일 환경에서는 교재 및 실시간 교정 화면을 확인할 수 없어 PC/노트북 사용을 권장해요.`,
      `On mobile devices, textbooks and real-time correction screens may not be visible, so we recommend using a PC or laptop.`,
      's_trial_lesson.completed.checking1_desc2',
      `On mobile devices, textbooks and real-time correction screens may not be visible, so we recommend using a PC or laptop.`,
      `On mobile devices, textbooks and real-time correction screens may not be visible, so we recommend using a PC or laptop.`,
      `On mobile devices, textbooks and real-time correction screens may not be visible, so we recommend using a PC or laptop.`,
      `On mobile devices, textbooks and real-time correction screens may not be visible, so we recommend using a PC or laptop.`,
    ],
    checking2_title: [
      `Zoom 프로그램을 설치해 주세요.`,
      `Install Zoom on your computer.`,
      's_trial_lesson.completed.checking2_title',
      `Install Zoom on your computer.`,
      `Install Zoom on your computer.`,
      `Install Zoom on your computer.`,
      `Install Zoom on your computer.`,
    ],
    checking2_desc: [
      `[프로필] > [Zoom 설치 가이드] > [Zoom 다운로드]를 통해 수업 전에 Zoom 프로그램을 미리 설치해 주세요.`,
      `Download and install Zoom before the lesson by going to [Profile] > [How to install Zoom] > [Download Zoom].`,
      's_trial_lesson.completed.checking2_desc',
      `Download and install Zoom before the lesson by going to [Profile] > [How to install Zoom] > [Download Zoom].`,
      `Download and install Zoom before the lesson by going to [Profile] > [How to install Zoom] > [Download Zoom].`,
      `Download and install Zoom before the lesson by going to [Profile] > [How to install Zoom] > [Download Zoom].`,
      `Download and install Zoom before the lesson by going to [Profile] > [How to install Zoom] > [Download Zoom].`,
    ],
    checking3_title: [
      `교재를 예습하고 질문을 선택해 주세요.`,
      `Complete the lesson prep.`,
      's_trial_lesson.completed.checking3_title',
      `Complete the lesson prep.`,
      `Complete the lesson prep.`,
      `Complete the lesson prep.`,
      `Complete the lesson prep.`,
    ],
    checking3_desc1: [
      `[1:1 수업] > [예습 & 수업] > [인사이트 읽기]를 통해 교재를 읽고 수업에서 공부할 질문 2-3개 선택 후 [수업 질문으로 선택하기]를 클릭해 주세요.`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Read Insight] to read the material in advance. Then, choose 2-3 questions to talk about during the lesson and click on [Select question to discuss in lesson].`,
      's_trial_lesson.completed.checking3_desc1',
      `Go to [1:1 lessons] > [Prep & Lesson] > [Read Insight] to read the material in advance. Then, choose 2-3 questions to talk about during the lesson and click on [Select question to discuss in lesson].`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Read Insight] to read the material in advance. Then, choose 2-3 questions to talk about during the lesson and click on [Select question to discuss in lesson].`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Read Insight] to read the material in advance. Then, choose 2-3 questions to talk about during the lesson and click on [Select question to discuss in lesson].`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Read Insight] to read the material in advance. Then, choose 2-3 questions to talk about during the lesson and click on [Select question to discuss in lesson].`,
    ],
    checking3_desc2: [
      `링글 교재는 인사이트, MP3 파일, 핵심표현 등 다양한 수업 자료가 준비되어 있어요.`,
      `Ringle materials contain diverse learning tools, such as Insight, MP3 files, Expressions, etc.`,
      's_trial_lesson.completed.checking3_desc2',
      `Ringle materials contain diverse learning tools, such as Insight, MP3 files, Expressions, etc.`,
      `Ringle materials contain diverse learning tools, such as Insight, MP3 files, Expressions, etc.`,
      `Ringle materials contain diverse learning tools, such as Insight, MP3 files, Expressions, etc.`,
      `Ringle materials contain diverse learning tools, such as Insight, MP3 files, Expressions, etc.`,
    ],
    checking4_title_mobile: [
      <>
        1:1 맞춤 수업을 위해 수업 방식을
        <br />
        설정해 주세요.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      's_trial_lesson.completed.checking4_title_mobile',
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
    ],
    checking4_title_pc: [
      `1:1 맞춤 수업을 위해 수업 방식을 설정해 주세요.`,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      's_trial_lesson.completed.checking4_title_pc',
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
    ],
    checking4_desc: [
      `[1:1 수업] > [예습 & 수업] > [수업 방식 설정]을 통해 수업 전에 선호하는 수업 방식과 요청 사항을 작성해 주세요.`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Set Lesson Style] to choose the lesson style you prefer and leave any requests you have.`,
      's_trial_lesson.completed.checking4_desc',
      `Go to [1:1 lessons] > [Prep & Lesson] > [Set Lesson Style] to choose the lesson style you prefer and leave any requests you have.`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Set Lesson Style] to choose the lesson style you prefer and leave any requests you have.`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Set Lesson Style] to choose the lesson style you prefer and leave any requests you have.`,
      `Go to [1:1 lessons] > [Prep & Lesson] > [Set Lesson Style] to choose the lesson style you prefer and leave any requests you have.`,
    ],
    policy: [
      `수업 취소 정책`,
      `Lesson Cancellation Policy`,
      's_trial_lesson.completed.policy',
      `Lesson Cancellation Policy`,
      `Lesson Cancellation Policy`,
      `Lesson Cancellation Policy`,
      `Lesson Cancellation Policy`,
    ],
    policy1: [
      `체험 수업 시작 기준 24시간 이전 취소 시, 7일간 사용 가능한 1회 수업권이 복구됩니다.`,
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
      's_trial_lesson.completed.policy1',
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
    ],
    policy2: [
      <>
        체험 수업 시작 기준 24~2시간 이전 취소 시, 7일간 사용 가능한 [당일수업권]이 제공됩니다.
        <br />
        [당일수업권]은 예약 시점 기준 24시간 이내에 시작하는 수업을 예약할 수 있는 수업권입니다.
      </>,
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
      's_trial_lesson.completed.policy2',
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
    ],
    policy3: [
      `체험 수업 시작 전 2시간 이내 취소 시, 1회 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
      's_trial_lesson.completed.policy3',
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
    ],
    btn_app_download: [
      `앱에서 수업 준비하기`,
      `Go to lesson prep on App`,
      's_trial_lesson.completed.btn_app_download',
      `Go to lesson prep on App`,
      `Go to lesson prep on App`,
      `Go to lesson prep on App`,
      `Go to lesson prep on App`,
    ],
    btn_prep: [
      `수업 준비하기`,
      `Go to lesson prep`,
      's_trial_lesson.completed.btn_prep',
      `Go to lesson prep`,
      `Go to lesson prep`,
      `Go to lesson prep`,
      `Go to lesson prep`,
    ],
    mail: [
      `링글에 가입한 이메일로 결제 영수증을 보내드렸습니다.`,
      `We've sent the payment receipt to your Ringle account's email.`,
      's_trial_lesson.completed.mail',
      `We've sent the payment receipt to your Ringle account's email.`,
      `We've sent the payment receipt to your Ringle account's email.`,
      `We've sent the payment receipt to your Ringle account's email.`,
      `We've sent the payment receipt to your Ringle account's email.`,
    ],
    title_free: [
      `수업 예약이 완료되었습니다.`,
      `Your lesson is successfully booked`,
      's_trial_lesson.completed.title_free',
      `Your lesson is successfully booked`,
      `Your lesson is successfully booked`,
      `Your lesson is successfully booked`,
      `Your lesson is successfully booked`,
    ],
    purchase_info_free: [
      `예약 내역`,
      `Lesson Booking Details`,
      's_trial_lesson.completed.purchase_info_free',
      `Lesson Booking Details`,
      `Lesson Booking Details`,
      `Lesson Booking Details`,
      `Lesson Booking Details`,
    ],
    checking1_desc11: [
      `홈페이지 [로그인] > [수업] > [예정된 수업]을 통해 수업 시작 15분 전부터 [수업 입장] 버튼을 클릭하면 수업에 입장할 수 있어요.`,
      `To join the lesson, log in to Ringle website, then go to [Lessons] > [Upcoming Lessons]. The [Enter Lesson] button will activate 15 minutes before the lesson is scheduled to start.`,
      's_trial_lesson.completed.checking1_desc11',
      `To join the lesson, log in to Ringle website, then go to [Lessons] > [Upcoming Lessons]. The [Enter Lesson] button will activate 15 minutes before the lesson is scheduled to start.`,
      `To join the lesson, log in to Ringle website, then go to [Lessons] > [Upcoming Lessons]. The [Enter Lesson] button will activate 15 minutes before the lesson is scheduled to start.`,
      `ホームページの[ログイン] > [レッスン] > [予定されたレッスン]からレッスン開始15分前から[レッスン入場]ボタンをクリックすると、レッスンに入場できます。`,
      `To join the lesson, log in to Ringle website, then go to [Lessons] > [Upcoming Lessons]. The [Enter Lesson] button will activate 15 minutes before the lesson is scheduled to start.`,
    ],
    checking1_desc21: [
      `모바일 환경에서는 교재 및 실시간 교정 화면을 확인할 수 없어 PC/노트북 사용을 권장해요.`,
      `We recommend using a PC or laptop, since the screens showing the material and real-time paraphrasing cannot be viewed simultaneously on a mobile phone.`,
      's_trial_lesson.completed.checking1_desc21',
      `We recommend using a PC or laptop, since the screens showing the material and real-time paraphrasing cannot be viewed simultaneously on a mobile phone.`,
      `We recommend using a PC or laptop, since the screens showing the material and real-time paraphrasing cannot be viewed simultaneously on a mobile phone.`,
      `モバイル環境では、教材やリアルタイムの添削画面を確認することができないため、PC/ノートパソコンの使用をお勧めします。`,
      `We recommend using a PC or laptop, since the screens showing the material and real-time paraphrasing cannot be viewed simultaneously on a mobile phone.`,
    ],
    checking2_desc1: [
      `[수업] > [예정된 수업] > [Zoom 설치 및 사용 안내] > [Zoom 다운로드]를 통해 수업 전에 Zoom 프로그램을 미리 설치해 주세요.`,
      `Please install Zoom before the lesson. You can do this by going to [Lessons] > [Upcoming Lessons] > [How to Use Zoom] > [Download & Install].`,
      's_trial_lesson.completed.checking2_desc1',
      `Please install Zoom before the lesson. You can do this by going to [Lessons] > [Upcoming Lessons] > [How to Use Zoom] > [Download & Install].`,
      `Please install Zoom before the lesson. You can do this by going to [Lessons] > [Upcoming Lessons] > [How to Use Zoom] > [Download & Install].`,
      `[授業] > [予定された授業] > [Zoomのインストールと使用方法] > [Zoomのダウンロード]から、授業前にZoomをインストールしてください。`,
      `Please install Zoom before the lesson. You can do this by going to [Lessons] > [Upcoming Lessons] > [How to Use Zoom] > [Download & Install].`,
    ],
    checking3_desc11: [
      `[수업] > [예정된 수업] > [수업 준비]를 통해 교재를 읽고 수업에서 공부할 질문 2-3개 선택 후 [수업 질문으로 선택하기]를 클릭해 주세요.`,
      `Go to [Lessons] > [Upcoming Lessons] > [Lesson Prep], read the material, select 2-3 questions to discuss during the lesson, then click [Select as lesson question].`,
      's_trial_lesson.completed.checking3_desc11',
      `Go to [Lessons] > [Upcoming Lessons] > [Lesson Prep], read the material, select 2-3 questions to discuss during the lesson, then click [Select as lesson question].`,
      `Go to [Lessons] > [Upcoming Lessons] > [Lesson Prep], read the material, select 2-3 questions to discuss during the lesson, then click [Select as lesson question].`,
      `[授業] > [予定された授業] > [授業の準備]から教材を読み、授業で勉強する質問を2-3個選択し、[授業の質問として選択]をクリックしてください。`,
      `Go to [Lessons] > [Upcoming Lessons] > [Lesson Prep], read the material, select 2-3 questions to discuss during the lesson, then click [Select as lesson question].`,
    ],
    checking3_desc21: [
      `링글 교재는 인사이트, MP3 파일, 핵심표현 등 다양한 수업 자료가 준비되어 있어요.`,
      `Ringle offers a variety of learning materials containing insights, audio files, key expressions, and more.`,
      's_trial_lesson.completed.checking3_desc21',
      `Ringle offers a variety of learning materials containing insights, audio files, key expressions, and more.`,
      `Ringle offers a variety of learning materials containing insights, audio files, key expressions, and more.`,
      `Ringleの教材は、インサイト、MP3ファイル、キーフレーズなど様々なレッスンコンテンツが用意されています。`,
      `Ringle offers a variety of learning materials containing insights, audio files, key expressions, and more.`,
    ],
    checking4_desc1: [
      `[수업] > [예정된 수업] > [수업 방식 설정]을 통해 수업 전에 선호하는 수업 방식과 요청 사항을 작성해 주세요.`,
      `Select your preferred lesson styles and leave requests before the lesson by going to [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      's_trial_lesson.completed.checking4_desc1',
      `Select your preferred lesson styles and leave requests before the lesson by going to [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      `Select your preferred lesson styles and leave requests before the lesson by going to [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      `[授業] > [予定された授業] > [授業方法の設定]から、授業前にご希望の授業方法とご要望をご記入ください。`,
      `Select your preferred lesson styles and leave requests before the lesson by going to [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
    ],
  },
  error: {
    title: (name) => [
      <>
        {name} 튜터 수업 진행이 어려워
        <br />
        새로운 튜터를 찾고 있어요.
      </>,
      <>
        Tutor {name} cannot teach.
        <br />
        We'll assign you a new tutor.
      </>,
      's_trial_lesson.error.title',
      <>
        Tutor {name} cannot teach.
        <br />
        We'll assign you a new tutor.
      </>,
      <>
        Tutor {name} cannot teach.
        <br />
        We'll assign you a new tutor.
      </>,
      <>
        Tutor {name} cannot teach.
        <br />
        We'll assign you a new tutor.
      </>,
      <>
        Tutor {name} cannot teach.
        <br />
        We'll assign you a new tutor.
      </>,
    ],
    desc: [
      <>
        수업 예약에 불편을 드려 죄송합니다.
        <br />
        다른 튜터를 선택해서 수업을 예약하고 싶다면, 아래 순서로 다시 수업을 예약해 주세요.
        <br />
        1.[예습&수업] 페이지로 이동
        <br />
        2. [변경/취소] &gt; [수업 취소] 클릭
        <br />
        3. [예약] 메뉴에서 새로운 수업 예약
      </>,
      <>
        We apologize for the inconvenience caused.
        <br />
        If you wish to choose another tutor for the lesson, you can renew your booking as follows.
        <br />
        1. Go to [Prep & Lesson]
        <br />
        2. Click [Change/Cancel] &gt; [Cancel Lesson]
        <br />
        3. Go to [Book] to book a new lesson
      </>,
      's_trial_lesson.error.desc',
      <>
        We apologize for the inconvenience caused.
        <br />
        If you wish to choose another tutor for the lesson, you can renew your booking as follows.
        <br />
        1. Go to [Prep & Lesson]
        <br />
        2. Click [Change/Cancel] &gt; [Cancel Lesson]
        <br />
        3. Go to [Book] to book a new lesson
      </>,
      <>
        We apologize for the inconvenience caused.
        <br />
        If you wish to choose another tutor for the lesson, you can renew your booking as follows.
        <br />
        1. Go to [Prep & Lesson]
        <br />
        2. Click [Change/Cancel] &gt; [Cancel Lesson]
        <br />
        3. Go to [Book] to book a new lesson
      </>,
      <>
        We apologize for the inconvenience caused.
        <br />
        If you wish to choose another tutor for the lesson, you can renew your booking as follows.
        <br />
        1. Go to [Prep & Lesson]
        <br />
        2. Click [Change/Cancel] &gt; [Cancel Lesson]
        <br />
        3. Go to [Book] to book a new lesson
      </>,
      <>
        We apologize for the inconvenience caused.
        <br />
        If you wish to choose another tutor for the lesson, you can renew your booking as follows.
        <br />
        1. Go to [Prep & Lesson]
        <br />
        2. Click [Change/Cancel] &gt; [Cancel Lesson]
        <br />
        3. Go to [Book] to book a new lesson
      </>,
    ],
  },
  watched: [`시청 완료`, `Watched`, 's_trial_lesson.watched', `Watched`, `Watched`, `Watched`, `Watched`],
  btn_purchase: {
    free: [
      `예약하기`,
      `Book your lesson`,
      's_trial_lesson.btn_purchase.free',
      `Book your lesson`,
      `Book your lesson`,
      `Book your lesson`,
      `Book your lesson`,
    ],
  },
  complete_lessonstyle: {
    title_app: [
      <>
        1:1 맞춤 수업을 위해 <span className="text-purple-500">수업 방식</span>을<br />
        설정해 주세요.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      's_trial_lesson.complete_lessonstyle.title_app',
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
    ],
    title_pc: [
      <>
        1:1 맞춤 수업을 위해 <span className="text-purple-500">수업 방식</span>을 설정해 주세요.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      's_trial_lesson.complete_lessonstyle.title_pc',
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
      <>
        Choose your preferred lesson style
        <br />
        for personalized 1:1 lessons.
      </>,
    ],
    label: [
      `링글 추천`,
      `Recommend`,
      's_trial_lesson.complete_lessonstyle.label',
      `Recommend`,
      `Recommend`,
      `Recommend`,
      `Recommend`,
    ],
    mode1: [
      `교정 중심`,
      `Paraphrasing Focused`,
      's_trial_lesson.complete_lessonstyle.mode1',
      `Paraphrasing Focused`,
      `Paraphrasing Focused`,
      `Paraphrasing Focused`,
      `Paraphrasing Focused`,
    ],
    mode1_detail: [
      `대화를 나누고 교정을 받는 교정 집중 수업`,
      `Lesson focused on correction`,
      's_trial_lesson.complete_lessonstyle.mode1_detail',
      `Lesson focused on correction`,
      `Lesson focused on correction`,
      `Lesson focused on correction`,
      `Lesson focused on correction`,
    ],
    mode1_detail_app: [
      <>
        대화를 나누고 교정을
        <br /> 받는 교정 집중 수업
      </>,
      <>
        Lesson focused on
        <br /> correction
      </>,
      's_trial_lesson.complete_lessonstyle.mode1_detail_app',
      `Lesson focused on correction`,
      `Lesson focused on correction`,
      `Lesson focused on correction`,
      `Lesson focused on correction`,
    ],
    mode2: [
      `토론 중심`,
      `Discussion Focused`,
      's_trial_lesson.complete_lessonstyle.mode2',
      `Discussion Focused`,
      `Discussion Focused`,
      `Discussion Focused`,
      `Discussion Focused`,
    ],
    mode2_detail: [
      `교재 질문/주제에 대한 대화 집중 수업`,
      `Lesson focused on conversation`,
      's_trial_lesson.complete_lessonstyle.mode2_detail',
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
    ],
    mode2_detail_app: [
      <>
        교재 질문/주제에 대한
        <br /> 대화 집중 수업
      </>,
      <>
        Lesson focused on
        <br /> conversation
      </>,
      's_trial_lesson.complete_lessonstyle.mode2_detail_app',
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
      `Lesson focused on conversation`,
    ],
    select_btn: [
      `모드 선택`,
      `Select`,
      's_trial_lesson.complete_lessonstyle.select_btn',
      `Select`,
      `Select`,
      `Select`,
      `Select`,
    ],
    selected_btn: [
      `모드 선택됨`,
      `Selected`,
      's_trial_lesson.complete_lessonstyle.selected_btn',
      `Selected`,
      `Selected`,
      `Selected`,
      `Selected`,
    ],
    description: [
      `상세한 수업 설정은 [수업] > [예정된 수업] > [수업 방식 설정]에서 할 수 있어요.`,
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      's_trial_lesson.complete_lessonstyle.description',
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessonss] > [Set Lesson Style].`,
    ],
  },
}
export const s_potal_home = {
  to_do: {
    btn5: [
      <>
        링글 수업 알아보고
        <br />
        5,000 포인트 받기
      </>,
      <>
        Learn about Ringle
        <br />
        and get $3.84 points
      </>,
      's_potal_home.to_do.btn5',
      `了解课程并获得价值$3.84积分`,
      `了解課程並獲得價值$3.84點數`,
      <>
        レッスンをチェックして
        <br />
        5,000ポイントゲット
      </>,
      <>
        Tìm hiểu về các lớp Ringle
        <br />
        nhận 5.000 điểm
      </>,
    ],
    btn6: [
      <>
        프로필 입력하고
        <br />
        10,000 포인트 받기
      </>,
      <>
        Complete your profile
        <br />
        and get $7.69 points
      </>,
      's_potal_home.to_do.btn6',
      `完成您的个人资料并获得价值$7.69积分`,
      `完成您的個人資料並獲得價值$7.69點數`,
      <>
        プロフィール入力して
        <br />
        10,000ポイントゲット
      </>,
      <>
        Hoàn thiện hồ sơ
        <br />
        nhận 10.000 điểm
      </>,
    ],
  },
  todo: {
    btn_modal: [
      `포인트 받기`,
      `Get points`,
      's_potal_home.todo.btn_modal',
      `获取积分`,
      `獲得點數`,
      `ポイントゲット`,
      `Nhận điểm`,
    ],
    toast: [
      `5,000 포인트가 적립되었습니다.`,
      `You have received $3.84 points.`,
      's_potal_home.todo.toast',
      `您已获得$3.84积分`,
      `您已獲得$3.84點數`,
      `5,000ポイントが支給されました。`,
      `Bạn đã được 5,000 điểm`,
    ],
  },
  trial_lesson: {
    title: [
      <>
        링글의 첫 수업,
        <br />
        커피 한 잔 가격으로 체험하세요.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      's_potal_home.trial_lesson.title',
      <>
        您的第一堂Ringle课程，
        <br />
        仅需一杯咖啡的价格。
      </>,
      <>
        您的第一堂Ringle課程，
        <br />
        僅需一杯咖啡的價格。
      </>,
      <>
        Ringleでの初レッスン、
        <br />
        コーヒー1杯の値段で体験できます。
      </>,
      <>
        Bài học đầu tiên của Ringle,
        <br />
        trải nghiệm với mức giá hạt dẻ
      </>,
    ],
    time: (time) => [
      <>체험권은 {time} 후 사라져요. </>,
      <>Trial lesson credit disappears in {time}.</>,
      's_potal_home.trial_lesson.time',
      <>体验券将在 {time} 后消失。</>,
      <>體驗券將在 {time} 後消失。</>,
      <>体験券は {time} 後に消えます。</>,
      <>Vé trải nghiệm sẽ mất sau {time}.</>,
    ],
    btn: [
      `체험수업 신청하기`,
      `Start now with a trial lesson`,
      's_potal_home.trial_lesson.btn',
      `申请体验课程`,
      `申請體驗課程`,
      `体験授業の申し込み`,
      `Đăng ký trải nghiệm`,
    ],
    title_free: [
      <>
        링글의 첫 수업,
        <br />
        <span className="text-[#f53428]">무료</span>로 체험하세요.
      </>,
      <>
        Try your first lesson
        <br />
        with Ringle for <span className="text-[#f53428]">free</span>.
      </>,
      's_potal_home.trial_lesson.title_free',
      `申请免费体验课程`,
      `申請免費體驗`,
      `無料体験を予約する`,
      `Bắt đầu học thử miễn phí`,
    ],
    label_free: [
      `프로모션 기간 한정`,
      `Promotion Only (8/21 - 9/7)`,
      's_potal_home.trial_lesson.label_free',
      `Promotion Only (8/21 - 9/7)`,
      `Promotion Only (8/21 - 9/7)`,
      `Promotion Only (8/21 - 9/7)`,
      `Promotion Only (8/21 - 9/7)`,
    ],
    title_free2: [
      <>
        친구 초대 혜택으로 제공되는 <br />
        <span className="text-[#f53428]">무료</span> 체험 수업을 신청하세요.
      </>,
      <>
        Try out the <span className="text-[#f53428]">free</span> trial
        <br />
        available to invited newcomers!
      </>,
      's_potal_home.trial_lesson.title_free2',
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
      <>
        友達紹介特典をご利用ください。
        <span className="text-[#f53428]">무료</span> 体験レッスンをお申し込みください。
      </>,
      <>
        Try out the <span className="text-[#f53428]">free trial</span>
        <br />
        available to invited newcomers.
      </>,
    ],
  },
  reactivate_popup: {
    title: [
      `다시 돌아온 걸 환영해요!`,
      `Welcome back to Ringle!`,
      's_potal_home.reactivate_popup.title',
      `欢迎您回来Ringle!`,
      `歡迎您回來Ringle!`,
      `Ringleへようこそ`,
      `Chào bạn quay trở lại!`,
    ],
    info: [
      <>
        휴면이 해제되어 알림 설정이 모두 ON 되었습니다.
        <br />
        설정을 변경하려면 [알림 수신 설정] 을 선택해주세요.
      </>,
      `Your account has been reactivated, including all notifications. To change your notifications setting, please click on [Change settings].`,
      's_potal_home.reactivate_popup.info',
      `解除休眠之后所有提醒皆为ON，如果想要更改设定请于
[设置接收提醒]做更改`,
      `解除休眠之後所有提醒皆為ON，如果想要更改設定請於
[通知接收設定]做更改`,
      `アカウントが有効化され、通知設定がすべてONになりました。
設定を変更するには、[通知設定]を選択してください。`,
      `Tài khoản đã được kích hoạt lại, tất cả cài đặt thông báo đã được bật.
Để thay đổi cài đặt, vui lòng chọn [Cài thông báo]`,
    ],
    notisetting: [
      `알림 수신 설정`,
      `Change settings`,
      's_potal_home.reactivate_popup.notisetting',
      `设置接收提醒`,
      `通知接收設定`,
      `通知設定`,
      `Cài thông báo`,
    ],
    ok: [`확인`, `Close`, 's_potal_home.reactivate_popup.ok', `确认`, `確認`, `OK`, `Xác nhận`],
  },
}
export const s_portal_welcome = {
  logout: [`로그아웃`, ``, 's_portal_welcome.logout', ``, ``, ``, ``],
  hero_coupon: {
    label: [
      `오직 단 한번! 신규회원 첫 구매 이벤트`,
      `Just This Once! First Purchase Discount`,
      's_portal_welcome.hero_coupon.label',
      `只有一次机会！新会员首购活动`,
      `只有一次機會！新會員首購活動`,
      `1回限り！新規会員初回購入キャンペーン`,
      `Cơ hội duy nhất! Ưu đãi mua hàng đặc biệt cho khách hàng mới!`,
    ],
    text1: [
      `최대 15만원 할인`,
      `Up to $115 off`,
      's_portal_welcome.hero_coupon.text1',
      `最高折价美金 $115`,
      `最高折價美金$115`,
      `最大$115割引`,
      `Giảm giá đến $115`,
    ],
    text2: [
      `혜택이 곧 사라져요!`,
      `Offer ends soon!`,
      's_portal_welcome.hero_coupon.text2',
      `优惠很快就会结束！`,
      `優惠很快就會結束！`,
      `もうすぐ特典終了！`,
      `Ưu đãi sẽ sớm kết thúc!`,
    ],
    text3: [
      `혜택 종료까지`,
      `Ends in`,
      's_portal_welcome.hero_coupon.text3',
      `至优惠结束还有`,
      `至優惠結束還有`,
      `特典終了まで`,
      `Còn`,
    ],
    hour: [`시간`, `hour(s)`, 's_portal_welcome.hero_coupon.hour', `小时`, `小時`, `時間`, `giờ`],
    min: [`분`, `min(s)`, 's_portal_welcome.hero_coupon.min', `分`, `分`, `分`, `phút`],
    sec: [`초`, `sec(s)`, 's_portal_welcome.hero_coupon.sec', `秒`, `秒`, `秒`, `giây`],
    btn1: [
      `혜택 확인하기`,
      `View offer`,
      's_portal_welcome.hero_coupon.btn1',
      `确认优惠`,
      `確認優惠`,
      `特典をチェック`,
      `Xem ưu đãi`,
    ],
  },
  hero_nocoupon: {
    label: [
      `걱정마세요! 링글이 보장합니다`,
      `Don't worry, we've got you covered!`,
      's_portal_welcome.hero_nocoupon.label',
      `不用担心！Ringle保证`,
      `不用擔心！Ringle保證`,
      `心配ご無用！Ringleが保障します`,
      `Đừng lo! Ringle luôn bên bạn!`,
    ],
    text1: [
      `링글팀이 추천하는`,
      `Start Ringle now`,
      's_portal_welcome.hero_nocoupon.text1',
      `现在就开始Ringle`,
      `現在就開始Ringle`,
      `Ringleチームおすすめの`,
      `Bắt đầu với Ringle!`,
    ],
    text2: [
      <>
        튜터와 함께
        <br />
        수업을 시작하세요!
      </>,
      <>
        by taking lessons with our Featured Tutors!
        <br />
      </>,
      's_portal_welcome.hero_nocoupon.text2',
      <>
        和推荐教师一起
        <br />
        开始课程
      </>,
      <>
        和推薦教師一起
        <br />
        開始課程
      </>,
      <>
        チューターと
        <br />
        レッスンスタート！
      </>,
      `Tham gia lớp học
 cùng gia sư!`,
    ],
    text3: [
      <>
        추천 튜터와 수업 후<br />
        불만족시 100% 환불 가능!
      </>,
      <>
        100% money-back guarantee
        <br />
        if you're unsatisfied with a featured tutor!
      </>,
      's_portal_welcome.hero_nocoupon.text3',
      <>与推荐的教师上课后， 如对课程不满意，可享100%退款！</>,
      <>與推薦的教師上課後， 如對課程不滿意，可享100%退款！</>,
      <>
        おすすめチューターのレッスン後
        <br />
        ご満足いただけない場合100％返金！
      </>,
      `Hoàn tiền 100%
 Nếu không hài lòng`,
    ],
    btn1: [
      `수업권 더 알아보기`,
      `See more credits`,
      's_portal_welcome.hero_nocoupon.btn1',
      `看更多课程券`,
      `看更多課程券`,
      `レッスンチケットをもっと見る`,
      `Xem thêm các gói học`,
    ],
  },
  select_recommend: {
    title: [
      `딱 맞는 수업을 알려드릴게요.`,
      `Lessons that Meet Your Needs`,
      's_portal_welcome.select_recommend.title',
      `推荐最适合您的课程`,
      `推薦最適合您的課程`,
      `ピッタリのレッスンを推薦します。`,
      `Lựa chọn khóa học phù hợp!`,
    ],
    detail: [
      <>
        영어 공부 목적을 선택해 보세요.
        <br />
        가장 적합한 튜터와 교재를 추천드립니다.
      </>,
      <>
        Choose what describes you best
        <br />
        to see out our recommendations.
      </>,
      's_portal_welcome.select_recommend.detail',
      <>
        请选择您的英文学习目的
        <br />
        我们将推荐您最适合的教材和教师
      </>,
      <>
        請選擇您的英文學習目的
        <br />
        我們將推薦您最適合的教材和教師
      </>,
      <>
        英語学習の目的を選択してください。
        <br />
        最適なチューター・教材を推薦します。
      </>,
      `Mục đích học tiếng Anh của bạn là gì?
 Gia sư và khóa học phù hợp nhất sẽ được đề xuất cho bạn!`,
    ],
    optn1: [
      `초·중급의 영어 실력을 더 키우고 싶어요.`,
      `I'm a beginner/intermediate level learner.`,
      's_portal_welcome.select_recommend.optn1',
      `我想提高初、中级英语水平`,
      `我想提高初、中級英文能力`,
      `初・中級の英語力をもっと伸ばしたい`,
      `Cải thiện kỹ năng tiếng Anh trình độ sơ cấp và trung cấp`,
    ],
    optn2: [
      `업무 등의 실전 영어가 필요해요.`,
      `I need practical English skills for work.`,
      's_portal_welcome.select_recommend.optn2',
      `需要在工作场合使用英文`,
      `需要在工作場合使用英文`,
      `仕事などでの実践英語が必要`,
      `Cải thiện kỹ năng tiếng Anh thực tế cho công việc.`,
    ],
    optn3: [
      `해외 유학을 위한 준비가 필요해요.`,
      `I plan to study abroad.`,
      's_portal_welcome.select_recommend.optn3',
      `为了留学做准备`,
      `為了留學做準備`,
      `海外留学のための準備が必要`,
      `Chuẩn bị cho việc du học`,
    ],
    optn4: [
      `외국계/해외 취업을 목표로 하고 있어요.`,
      `I want to build a global career.`,
      's_portal_welcome.select_recommend.optn4',
      `想要到国际企业/海外工作`,
      `想要到國際企業/海外工作`,
      `外資系/海外就職を目標にしている`,
      `Mong muốn làm việc ở công ty đa quốc gia`,
    ],
  },
  section1: {
    title: [
      `초·중급의 영어 레벨이라면`,
      `1) If you're a beginner/intermediate`,
      's_portal_welcome.section1.title',
      `如果您的英语水平是初、中级`,
      `如果您的英文程度是初、中級`,
      `初・中級レベルなら`,
      `Mới bắt đầu/ trung cấp`,
    ],
    detail: [
      <>
        외국인과의 대화가 겁이 나고 익숙치 않다면
        <br />
        튜터와 좀 더 편안한 주제로 먼저 시작해보세요.
      </>,
      `If you're not used to talking in English, choose light topics you are familiar with.`,
      's_portal_welcome.section1.detail',
      `如果您不习惯用英语交谈，建议先从更轻松的话题与教师进行课程。`,
      `如果您不習慣用英文交談，建議先從更輕鬆的話題與教師進行課程。`,
      `外国人との会話に慣れず不安なら気軽なテーマから初めてみましょう。`,
      `Bạn không tự tin khi nói chuyện với người nước ngoài?Bắt đầu bằng một chủ đề mà bạn tự tin cùng với gia sư!`,
    ],
    tutor: [
      `튜터 추천`,
      `Recommended Tutors`,
      's_portal_welcome.section1.tutor',
      `推荐教师`,
      `推薦教師`,
      `おすすめチューター`,
      ``,
    ],
    tutor_text: [
      <>
        한국에 관심이 많은 튜터와 <br />
        먼저 시작해보면 어떨까요?
      </>,
      `Ease into Ringle lessons with tutors who are interested in Korea.`,
      's_portal_welcome.section1.tutor_text',
      `和喜欢韩国的教师进行课程如何呢`,
      `和喜歡韓國的教師進行課程如何呢`,
      `韓国が好きなチューターとお話してみませんか？`,
      `Bắt đầu cùng gia sư quan tâm đến Hàn Quốc`,
    ],
    material: [
      `교재 추천`,
      `Recommended Materials`,
      's_portal_welcome.section1.material',
      `推荐教材`,
      `推薦教材`,
      `お気に入り教材`,
      ``,
    ],
    material_text: [
      <>
        가벼운 주제로 연습하면서 <br />
        영어 자신감을 키워요.
      </>,
      `Build your confidence first by practicing small talk.`,
      's_portal_welcome.section1.material_text',
      `通过轻松的主题练习 
增加您對英语的自信`,
      `用輕鬆的主題練習增加您對英文的自信`,
      `簡単なテーマで練習して自信をつけましょう。`,
      `Bắt đầu bằng chủ đề đơn giản để nâng cao sự tự tin khi nói tiếng Anh.`,
    ],
    credit: [
      `수업권 추천`,
      `Recommended Credits`,
      's_portal_welcome.section1.credit',
      `推荐课程券`,
      `推薦課程券`,
      `おすすめレッスンチケット`,
      `Gói học đề xuất`,
    ],
    credit_text: [
      <>
        처음이라 망설여진다면
        <br />
        먼저 4회 수업으로 시작해보세요.
      </>,
      `If you're feeling hesitant, try out 4 credits first.`,
      's_portal_welcome.section1.credit_text',
      `如果您还在犹豫可以先从购买4次课程券开始`,
      `如果您還在猶豫可以先從購買4次課程券開始`,
      `初めてでお悩みならまずは4回から初めてみましょう。`,
      `Bạn đang phân vân?Hãy thử bắt đầu với gói 4 bài học`,
    ],
    credit_name: (n) => [
      <>{n}회 수업권</>,
      <>{n} Credits</>,
      's_portal_welcome.section1.credit_name',
      <>{n}次</>,
      <>{n}次</>,
      <>{n}回</>,
      <>{n} Buổi học</>,
    ],
    credit_type: [
      `월 평균 4회 수업 / 매주 수업 듣기`,
      `4 lessons/month & Every Week`,
      's_portal_welcome.section1.credit_type',
      `一个月4次 & 每週`,
      `一個月4次 & 每週`,
      `月4回 & 毎週授業を受ける`,
      `4 buổi / tháng & Lớp hàng tuần`,
    ],
    credit_discount: (amount) => [
      <>신규회원 한정 {amount} 추가 할인</>,
      <>Newcomers only: extra {amount} off</>,
      's_portal_welcome.section1.credit_discount',
      <>Newcomers only: extra {amount} off</>,
      <>Newcomers only: extra {amount} off</>,
      <>Newcomers only: extra {amount} off</>,
      <>Newcomers only: extra {amount} off</>,
    ],
    credit_purchase: [
      `수업권 바로 구매하기`,
      `Purchase Now`,
      's_portal_welcome.section1.credit_purchase',
      `立即购买`,
      `立即購買`,
      `すぐに購入する`,
      `Mua ngay`,
    ],
    view_more: [
      `수업권 더 알아보러 가기`,
      `View other credit packages`,
      's_portal_welcome.section1.view_more',
      `看更多课程券`,
      `看更多課程券`,
      `レッスンチケットをもっと見る`,
      `Xem thêm các gói học`,
    ],
    credit_time: (n) => [
      <>{n}분 수업권</>,
      <>{n} minutes</>,
      's_portal_welcome.section1.credit_time',
      <>{n}分</>,
      <>{n}分</>,
      <>{n}分</>,
      <>{n} phút</>,
    ],
  },
  section2: {
    title: [
      `실전에서 영어가 당장 필요하다면`,
      `If you need practical English skills`,
      's_portal_welcome.section2.title',
      `如果需要实战英文`,
      `如果需要實戰英文`,
      `実践で今すぐ英語が必要なら`,
      `Bạn muốn nâng cao kỹ năng tiếng Anh thực tế`,
    ],
    detail: [
      <>
        비즈니스 환경에서 영어를 써야한다면 전공자와의
        <br />
        대화를 통해 실력을 키우세요.
      </>,
      `Take lessons with tutors majoring in your field to learn English you can apply right away.`,
      's_portal_welcome.section2.detail',
      `如果在商务环境需要使用英文可以透过与不同主修的教师练习对话提升实力`,
      `如果在商務環境需要使用英文可以透過與不同主修的教師練習對話提升實力`,
      `ビジネスの場で英語が必要なら専攻者との会話を通して実力アップさせましょう。`,
      `Học cùng chuyên gia đê áp dụng tiếng An ngay trong lĩnh vực của bạn`,
    ],
    tutor: [
      `튜터 추천`,
      `Recommended Tutors`,
      's_portal_welcome.section2.tutor',
      `推荐教师`,
      `推薦教師`,
      `おすすめチューター`,
      `Gia sư đề xuất`,
    ],
    tutor_text: [
      <>
        달러 강세부터 최신 테크까지.
        <br />
        분야별 전공 튜터와 깊이 있는
        <br />
        대화를 나누면서 실력을 키워요.
      </>,
      `Choose tutors majoring in fields related to trending topics to get in-depth feedback.`,
      's_portal_welcome.section2.tutor_text',
      `从美元汇率走势到最新科技与各领域专业的教師进行深入对话，提升您的英語實力`,
      `從美元匯率走勢到最新科技與各領域主修的教師進行深入對話，提升您的英文能力`,
      `ドル高から最新テクまで。分野別専攻のチューターとの会話で実力アップさせましょう。`,
      `Chọn những gia sư chuyên ngành liên quan đến các chủ đề đang thịnh hành để nhận được phản hồi chi tiết`,
    ],
    material: [
      `교재 추천`,
      `Recommended Materials`,
      's_portal_welcome.section2.material',
      `推荐教材`,
      `推薦教材`,
      `おすすめ教材`,
      `Giáo trình đề xuất`,
    ],
    material_text: [
      <>
        가장 최신의 비즈니스 주제부터
        <br />
        실전에 필요한 연습까지 가능해요.
      </>,
      `Choose topics from recent biz trends to practical business English.`,
      's_portal_welcome.section2.material_text',
      `从最新的商业主题到实际练习，满足您所有需求`,
      `從最新的商業主題到實際練習，滿足您所有需求`,
      `最新のビジネステーマから実践に必要な練習までOK。`,
      `Chọn chủ đề từ các xu hướng kinh doanh mới nhất đến tiếng Anh thương mại trong thực tế`,
    ],
    credit: [
      `수업권 추천`,
      `Recommended Credits`,
      's_portal_welcome.section2.credit',
      `推荐课程券`,
      `推薦課程券`,
      `おすすめレッスンチケット`,
      `Gói bài học đề xuất`,
    ],
    credit_text: [
      <>
        바쁜 일상 속 짧지만 꾸준한
        <br />
        학습을 돕는 20분 수업권을
        <br />
        추천해요.
      </>,
      `20-min lessons are perfect for boosting your English skills amid your busy schedule.`,
      's_portal_welcome.section2.credit_text',
      `20分钟课程券讓您在忙碌的日常中持续且有效率地学习`,
      `20分鐘課程券讓您在忙碌的日常中持續且有效率地學習`,
      `忙しい日々、短くても継続した学習をサポートする20分レッスンチケットがおすすめです。`,
      `20 phút học mỗi buổi là lựa chọn hoàn hảo để cải thiện kỹ năng tiếng Anh giữa lịch trình bận rộn`,
    ],
    credit_type: [
      `월 평균 6회 수업 / 매주 수업 듣기`,
      `6 lessons/month & Every Week`,
      's_portal_welcome.section2.credit_type',
      `一个月6次 & 每週`,
      `一個月6次 & 每週`,
      `月6回 & 毎週授業を受ける`,
      `6 buổi / tháng & Lớp hàng tuần`,
    ],
  },
  section3: {
    title: [
      `해외 유학 준비 중이라면`,
      `If you're planning to study abroad`,
      's_portal_welcome.section3.title',
      `如果正准备海外留学`,
      `如果正準備海外留學`,
      `海外留学準備中なら`,
      `Bạn cần tiếng Anh để du học?`,
    ],
    detail: [
      <>
        입학 에세이부터 인터뷰까지 링글의 튜터들과 함께 <br /> 준비해서 좋은 결과를 만들어요.{' '}
      </>,
      `Prepare each step with Ringle tutors to get the best results.`,
      's_portal_welcome.section3.detail',
      `从撰写Essay到面试为止和Ringle教师一起准备取得好的成果`,
      `從撰寫Essay到面試為止和Ringle教師一起準備取得好的成果`,
      <>
        入学エッセイから面接までRingleチューターと共に
        <br />
        対策して良い結果を残しましょう。
      </>,
      `Cùng các gia sư Ringle chuẩn bị tưng bước để đạt kết quả tốt nhất`,
    ],
    tutor: [
      `튜터 추천`,
      `Recommended Tutors`,
      's_portal_welcome.section3.tutor',
      `推荐教师`,
      `推薦教師`,
      `おすすめチューター`,
      `Gia sư đề xuất`,
    ],
    tutor_text: [
      <>
        유명 대학교 지원 경험이 있는
        <br /> 대학원생 튜터와 지원 과정을 <br /> 함께 준비 할 수 있어요.
      </>,
      `Prepare your applications with grad school students who've been through the process.`,
      's_portal_welcome.section3.tutor_text',
      `和有申请名校研究所经验的教师一起准备申请文件`,
      `和有申請名校研究所經驗的教師一起準備申請文件`,
      <>
        有名大学志願経験のある院生チューターが志願の
        <br />
        サポートをします。
      </>,
      `Chuẩn bị hồ sơ đăng ký  vào các trường đại học nổi tiếng cùng các gia sư có kinh nghiệm`,
    ],
    material: [
      `교재 추천`,
      `Recommended Materials`,
      's_portal_welcome.section3.material',
      `推荐教材`,
      `推薦教材`,
      `おすすめ教材`,
      `Giáo trình đề xuất`,
    ],
    material_text: [
      <>
        라이팅/스피킹 중심 교재로
        <br />
        지원 과정에 필요한 도움을 받아요.
      </>,
      `Get ready to apply for schools by focusing on writing/speaking.`,
      's_portal_welcome.section3.material_text',
      `使用以写作/口说为主的教材准备申请并取得帮助`,
      `使用以寫作/口說為主的教材準備申請並取得幫助`,
      `ライティング/スピーキング中心の教材で志願のサポートが受けられます。`,
      `Tập trung vào kỹ năng viết/ nói sẵn sàng nộp đơn đăng ký`,
    ],
    credit: [
      `수업권 추천`,
      `Recommended Credits`,
      's_portal_welcome.section3.credit',
      `推荐课程券`,
      `推薦課程券`,
      `おすすめレッスンチケット`,
      `Gói học đề xuất`,
    ],
    credit_text: [
      <>
        차근차근 준비할 수 있는 <br /> 24회 수업권을 추천해요.{' '}
      </>,
      <>
        Prepare step by step
        <br /> with a 24-credit package.
      </>,
      's_portal_welcome.section3.credit_text',
      <>
        推荐可以循序渐进准备的 <br />
        24次课程券{' '}
      </>,
      <>
        推薦可以循序漸進準備的 <br />
        24次課程券{' '}
      </>,
      <>
        じっくり準備ができる
        <br />
        24回レッスンチケットがおすすめです。{' '}
      </>,
      `Sẵn sàng để nhập học
 với gói 24 bài học`,
    ],
    credit_type: [
      `월 평균 6회 수업 / 매주 수업 듣기`,
      `6 lessons/month & Every Week`,
      's_portal_welcome.section3.credit_type',
      `一个月6次 & 每週`,
      `一個月6次 & 每週`,
      `月6回 & 毎週授業を受ける`,
      `6 buổi / tháng & Lớp hàng tuần`,
    ],
  },
  section4: {
    title: [
      `외국계/해외 취업이 목표라면`,
      `If you want a global career`,
      's_portal_welcome.section4.title',
      `如果想要到外国企业/海外工作`,
      `如果想要到國際企業/海外工作`,
      `外資系/海外就職が目標なら`,
      `Bạn muốn làm việc ở công ty đa quốc gia?`,
    ],
    detail: [
      <>
        새로운 기회를 위한 레쥬메부터 인터뷰 준비까지.
        <br />
        경험이 있는 튜터들과 함께 준비할 수 있어요.
      </>,
      `Brush up your resume and practice interviews with experienced tutors.`,
      's_portal_welcome.section4.detail',
      `从准备履历到面试练习都可以和有经验的教师一起准备`,
      `從準備履歷到面試練習都可以和有經驗的教師一起準備`,
      `新たなチャンスのためのレジュメから面接対策まで。経験のあるチューターが準備をサポートします。`,
      `Hoàn thiện CV và thực hành phỏng vấn cùng các gia sư giàu kinh nghiệm`,
    ],
    tutor: [
      `튜터 추천`,
      `Recommended Tutors`,
      's_portal_welcome.section4.tutor',
      `推荐教师`,
      `推薦教師`,
      `おすすめチューター`,
      `Gia sư đề xuất`,
    ],
    tutor_text: [
      <>
        컨설팅부터 은행까지. <br /> 다양한 인턴십/직장 경험을 가진 <br /> 튜터에게 경험을 나눠 받아요.{' '}
      </>,
      `Get tips from tutors with intership/work experience in diverse fields.`,
      's_portal_welcome.section4.tutor_text',
      `从顾问业到银行业，从拥有各种产业实习/工作经验的教师获得经验分享`,
      `從顧問業到銀行，業從擁有各種產業實習/工作經驗的教師獲得經驗分享`,
      `コンサルティングから銀行まで。様々なインターン/社会経験を持つチューターの話が聞けます。`,
      `Nhận lời khuyên từ các gia sư có kinh nghiệm thực tập/làm việc trong các lĩnh vực khác nhau`,
    ],
    material: [
      `교재 추천`,
      `Recommended Materials`,
      's_portal_welcome.section4.material',
      `推荐教材`,
      `推薦教材`,
      `おすすめ教材`,
      `Giáo trình đề xuất`,
    ],
    material_text: [
      <>
        레쥬메 교정부터 인터뷰 준비까지
        <br />
        나에게 필요한 것을 골라봐요.
      </>,
      `From resumes to interviews, choose what you need to work on.`,
      's_portal_welcome.section4.material_text',
      `从修改履历到面试准备选择自己需要的`,
      `從修改履歷到面試準備選擇自己需要的`,
      `レジュメ校正から面接対策まで自分に必要なものをチョイス。`,
      `Chọn yêu cầu của bạn từ việc hoàn thiện CV và chuẩn bị phỏng vấn`,
    ],
    credit: [
      `수업권 추천`,
      `Recommended Credits`,
      's_portal_welcome.section4.credit',
      `推荐课程券`,
      `推薦課程券`,
      `おすすめレッスンチケット`,
      `Gói học đề xuất`,
    ],
    credit_type: [
      `월 평균 6회 수업 / 자유수강`,
      `6 lessons/month & None`,
      's_portal_welcome.section4.credit_type',
      `一个月6次 & 无固定时间`,
      `一個月6次 & 無固定時間`,
      `月6回 & 自由受講`,
      `6 buổi / tháng & Lớp tự do`,
    ],
  },
}
export const s_modal_weekly = {
  header_1: [`주차/기간`, `Week/Period`, 's_modal_weekly.header_1', `周/期间`, `週/期間`, `週/期間`, `tuần/kỳ`],
  header_2: [
    `매주 수업 듣기`,
    `Every Week`,
    's_modal_weekly.header_2',
    `一週一次`,
    `一週一次`,
    `毎週レッスンを受ける`,
    `Lớp học hàng tuần`,
  ],
  week: (n) => [
    <>{n}주차</>,
    <>Week {n}</>,
    's_modal_weekly.week',
    <>{n}週</>,
    <>{n}週</>,
    <>{n}週目</>,
    <>Tuần {n}</>,
  ],
  msg: [
    `수업권 복구됨`,
    `Credit restored`,
    's_modal_weekly.msg',
    `恢復课程券`,
    `恢復課程券`,
    `レッスンチケットが復元されました。`,
    `Đã khôi phục`,
  ],
}
export const s_aug_promo = {
  floating_btn: {
    caption_main: [
      `링글 여름 정규 프로모션`,
      `Promotional period`,
      's_aug_promo.floating_btn.caption_main',
      `Promotional period`,
      `Promotional period`,
      `Promotional period`,
      `Promotional period`,
    ],
    title_main: [
      `23.8.11(금) - 9.7(목)`,
      `Aug 11(Fri) - Sep 7(Thu)`,
      's_aug_promo.floating_btn.title_main',
      `Aug 11(Fri) - Sep 7(Thu)`,
      `Aug 11(Fri) - Sep 7(Thu)`,
      `Aug 11(Fri) - Sep 7(Thu)`,
      `Aug 11(Fri) - Sep 7(Thu)`,
    ],
    btn_main: [
      `지금 가격 알아보기`,
      `View prices now`,
      's_aug_promo.floating_btn.btn_main',
      `View prices now`,
      `View prices now`,
      `View prices now`,
      `View prices now`,
    ],
    caption_eb: [
      `얼리버드 할인 중`,
      `Early Bird prices`,
      's_aug_promo.floating_btn.caption_eb',
      `Early Bird prices`,
      `Early Bird prices`,
      `Early Bird prices`,
      `Early Bird prices`,
    ],
    title_eb: [
      `23.8.1(화) - 8.10(목)`,
      `Aug 1(Tue) - 10(Thu)`,
      's_aug_promo.floating_btn.title_eb',
      `Aug 1(Tue) - 10(Thu)`,
      `Aug 1(Tue) - 10(Thu)`,
      `Aug 1(Tue) - 10(Thu)`,
      `Aug 1(Tue) - 10(Thu)`,
    ],
    btn_program_1: [
      `수업권 구매하고 프로그램 신청하기`,
      `Purchase credits and Sign up`,
      's_aug_promo.floating_btn.btn_program_1',
      `Purchase credits and Sign up`,
      `Purchase credits and Sign up`,
      `Purchase credits and Sign up`,
      `Purchase credits and Sign up`,
    ],
    btn_program_2: [
      `프로그램 신청하기`,
      `Sign up for this program`,
      's_aug_promo.floating_btn.btn_program_2',
      `Sign up for this program`,
      `Sign up for this program`,
      `Sign up for this program`,
      `Sign up for this program`,
    ],
    btn_program_3: [
      `신청 완료 되었습니다.`,
      `You're all signed up!`,
      's_aug_promo.floating_btn.btn_program_3',
      `You're all signed up!`,
      `You're all signed up!`,
      `You're all signed up!`,
      `You're all signed up!`,
    ],
    btn_main_mo: [
      `가격 알아보기`,
      `View prices`,
      's_aug_promo.floating_btn.btn_main_mo',
      `View prices`,
      `View prices`,
      `View prices`,
      `View prices`,
    ],
  },
  modal_title: [
    `프로그램 신청을 위한 마케팅 수신 동의`,
    `Marketing consent for custom programs`,
    's_aug_promo.modal_title',
    `Marketing consent for custom programs`,
    `Marketing consent for custom programs`,
    `Marketing consent for custom programs`,
    `Marketing consent for custom programs`,
  ],
  modal_checklist_1: [
    `[필수] 프로그램 안내를 위해 전체 마케팅 수신에 동의합니다.`,
    `I give my consent to receive marketing information.`,
    's_aug_promo.modal_checklist_1',
    `I give my consent to receive marketing information.`,
    `I give my consent to receive marketing information.`,
    `I give my consent to receive marketing information.`,
    `I give my consent to receive marketing information.`,
  ],
  modal_checklist_2: [
    `[필수] 로그인된 링글 이메일 계정으로 프로그램 안내가 발송됩니다.`,
    `Send me information about the program(s) to my registered email.`,
    's_aug_promo.modal_checklist_2',
    `Send me information about the program(s) to my registered email.`,
    `Send me information about the program(s) to my registered email.`,
    `Send me information about the program(s) to my registered email.`,
    `Send me information about the program(s) to my registered email.`,
  ],
  modal_btn_1: [
    `취소`,
    `Sign up later`,
    's_aug_promo.modal_btn_1',
    `Sign up later`,
    `Sign up later`,
    `Sign up later`,
    `Sign up later`,
  ],
  modal_btn_2: [
    `신청하기`,
    `Sign up now`,
    's_aug_promo.modal_btn_2',
    `Sign up now`,
    `Sign up now`,
    `Sign up now`,
    `Sign up now`,
  ],
  modal_msg: [
    `신청 완료 되었습니다.`,
    `You're all signed up!`,
    's_aug_promo.modal_msg',
    `You're all signed up!`,
    `You're all signed up!`,
    `You're all signed up!`,
    `You're all signed up!`,
  ],
  modal_btn_3: [`확인`, `Okay`, 's_aug_promo.modal_btn_3', `Okay`, `Okay`, `Okay`, `Okay`],
  section_timer: {
    title: [
      `새로운 도전을 위한
준비 마감까지`,
      <>
        Ringle Summer Promotion
        <br />
        ENDS IN
      </>,
      's_aug_promo.section_timer.title',
      <>
        Ringle Summer Promotion
        <br />
        ENDS IN
      </>,
      <>
        Ringle Summer Promotion
        <br />
        ENDS IN
      </>,
      <>
        Ringle Summer Promotion
        <br />
        ENDS IN
      </>,
      <>
        Ringle Summer Promotion
        <br />
        ENDS IN
      </>,
    ],
    days: [`일`, `day(s)`, 's_aug_promo.section_timer.days', `day(s)`, `day(s)`, `day(s)`, `day(s)`],
    hours: [`시간`, `hour(s)`, 's_aug_promo.section_timer.hours', `hour(s)`, `hour(s)`, `hour(s)`, `hour(s)`],
    min: [`분`, `min`, 's_aug_promo.section_timer.min', `min`, `min`, `min`, `min`],
    sec: [`초`, `sec`, 's_aug_promo.section_timer.sec', `sec`, `sec`, `sec`, `sec`],
    title_eb: [
      <>
        새로운 도전을 위한
        <br />
        얼리버드 마감까지
      </>,
      <>
        Early Bird period
        <br />
        ENDS IN
      </>,
      's_aug_promo.section_timer.title_eb',
      <>
        Early Bird period
        <br />
        ENDS IN
      </>,
      <>
        Early Bird period
        <br />
        ENDS IN
      </>,
      <>
        Early Bird period
        <br />
        ENDS IN
      </>,
      <>
        Early Bird period
        <br />
        ENDS IN
      </>,
    ],
  },
  section_program: {
    caption: [
      `도전 성공을 위한`,
      `Curated for Success`,
      's_aug_promo.section_program.caption',
      `Curated for Success`,
      `Curated for Success`,
      `Curated for Success`,
      `Curated for Success`,
    ],
    title: [
      `맞춤형 프로그램`,
      `Custom Programs`,
      's_aug_promo.section_program.title',
      `Custom Programs`,
      `Custom Programs`,
      `Custom Programs`,
      `Custom Programs`,
    ],
    subtitle: [
      <>
        프로모션 기간에 수업권을 구매하고
        <br />
        맞춤형 프로그램을 신청하세요.
      </>,
      <>
        Purchase credits during the promotion period
        <br />
        and sign up for our custom program(s).
      </>,
      's_aug_promo.section_program.subtitle',
      <>
        Purchase credits during the promotion period
        <br />
        and sign up for our custom program(s).
      </>,
      <>
        Purchase credits during the promotion period
        <br />
        and sign up for our custom program(s).
      </>,
      <>
        Purchase credits during the promotion period
        <br />
        and sign up for our custom program(s).
      </>,
      <>
        Purchase credits during the promotion period
        <br />
        and sign up for our custom program(s).
      </>,
    ],
    card_1_title: [
      `외국계 기업 채용 준비`,
      `Global Career track`,
      's_aug_promo.section_program.card_1_title',
      `Global Career track`,
      `Global Career track`,
      `Global Career track`,
      `Global Career track`,
    ],
    card_1_benefit: [
      `Top MBA 출신들의 영문 이력서 피드백`,
      `Get English resume reviewed
by Top MBA graduates`,
      's_aug_promo.section_program.card_1_benefit',
      `Get English resume reviewed
by Top MBA graduates`,
      `Get English resume reviewed
by Top MBA graduates`,
      `Get English resume reviewed
by Top MBA graduates`,
      `Get English resume reviewed
by Top MBA graduates`,
    ],
    card_1_list_1: [
      `- 영어 이력서/면접 준비 맞춤형 웨비나`,
      `- Webinars for English resume/interview prep`,
      's_aug_promo.section_program.card_1_list_1',
      `- Webinars for English resume/interview prep`,
      `- Webinars for English resume/interview prep`,
      `- Webinars for English resume/interview prep`,
      `- Webinars for English resume/interview prep`,
    ],
    card_1_list_2: [
      `- 외국계 기업 채용 준비를 위한 교재 모음`,
      `- Curated materials on finding jobs abroad`,
      's_aug_promo.section_program.card_1_list_2',
      `- Curated materials on finding jobs abroad`,
      `- Curated materials on finding jobs abroad`,
      `- Curated materials on finding jobs abroad`,
      `- Curated materials on finding jobs abroad`,
    ],
    card_2_title: [
      `석/박사, MBA 유학 준비`,
      `Study Abroad track`,
      's_aug_promo.section_program.card_2_title',
      `Study Abroad track`,
      `Study Abroad track`,
      `Study Abroad track`,
      `Study Abroad track`,
    ],
    card_2_benefit: [
      `유학 준비 가이드 PDF 제공`,
      `Guidebook on prepping for study abroad (PDF)`,
      's_aug_promo.section_program.card_2_benefit',
      `Guidebook on prepping for study abroad (PDF)`,
      `Guidebook on prepping for study abroad (PDF)`,
      `Guidebook on prepping for study abroad (PDF)`,
      `Guidebook on prepping for study abroad (PDF)`,
    ],
    card_2_list_1: [
      `- SOP/에세이 준비를 위한 웨비나`,
      `- Webinars for writing SOP/essays`,
      's_aug_promo.section_program.card_2_list_1',
      `- Webinars for writing SOP/essays`,
      `- Webinars for writing SOP/essays`,
      `- Webinars for writing SOP/essays`,
      `- Webinars for writing SOP/essays`,
    ],
    card_2_list_2: [
      `- 유학 준비를 위한 교재 모음`,
      `- Curated materials on studying abroad`,
      's_aug_promo.section_program.card_2_list_2',
      `- Curated materials on studying abroad`,
      `- Curated materials on studying abroad`,
      `- Curated materials on studying abroad`,
      `- Curated materials on studying abroad`,
    ],
    card_3_title: [
      `비즈니스 영어 향상`,
      `Business English track`,
      's_aug_promo.section_program.card_3_title',
      `Business English track`,
      `Business English track`,
      `Business English track`,
      `Business English track`,
    ],
    card_3_benefit: [
      `비즈니스 표현 배달 서비스 (8주)`,
      `Regular delivery of useful expressions (8 weeks)`,
      's_aug_promo.section_program.card_3_benefit',
      `Regular delivery of useful expressions (8 weeks)`,
      `Regular delivery of useful expressions (8 weeks)`,
      `Regular delivery of useful expressions (8 weeks)`,
      `Regular delivery of useful expressions (8 weeks)`,
    ],
    card_3_list_1: [
      `- 비즈니스 영어 맞춤형 웨비나`,
      `- Webinars on English for work`,
      's_aug_promo.section_program.card_3_list_1',
      `- Webinars on English for work`,
      `- Webinars on English for work`,
      `- Webinars on English for work`,
      `- Webinars on English for work`,
    ],
    card_3_list_2: [
      `- 비즈니스 영어 향상을 위한 교재 모음`,
      `- Curated materials on business English`,
      's_aug_promo.section_program.card_3_list_2',
      `- Curated materials on business English`,
      `- Curated materials on business English`,
      `- Curated materials on business English`,
      `- Curated materials on business English`,
    ],
    card_4_title: [
      `공인 영어 시험 준비`,
      `IELTS/OPIC track`,
      's_aug_promo.section_program.card_4_title',
      `IELTS/OPIC track`,
      `IELTS/OPIC track`,
      `IELTS/OPIC track`,
      `IELTS/OPIC track`,
    ],
    card_4_benefit: [
      `IELTS/오픽 스피킹 스터디 클럽 (8주)`,
      `Join IELTS/OPIC Study Club (8 weeks)`,
      's_aug_promo.section_program.card_4_benefit',
      `Join IELTS/OPIC Study Club (8 weeks)`,
      `Join IELTS/OPIC Study Club (8 weeks)`,
      `Join IELTS/OPIC Study Club (8 weeks)`,
      `Join IELTS/OPIC Study Club (8 weeks)`,
    ],
    card_4_list_1: [
      `- IELTS/오픽 준비 맞춤형 웨비나`,
      `- Webinars for IELTS/OPIC prep`,
      's_aug_promo.section_program.card_4_list_1',
      `- Webinars for IELTS/OPIC prep`,
      `- Webinars for IELTS/OPIC prep`,
      `- Webinars for IELTS/OPIC prep`,
      `- Webinars for IELTS/OPIC prep`,
    ],
    card_4_list_2: [
      `- 영어 시험 관련 교재 모음`,
      `- Curated materials on IELTS/OPIC`,
      's_aug_promo.section_program.card_4_list_2',
      `- Curated materials on IELTS/OPIC`,
      `- Curated materials on IELTS/OPIC`,
      `- Curated materials on IELTS/OPIC`,
      `- Curated materials on IELTS/OPIC`,
    ],
    card_5_title: [
      `영어 자신감 극복`,
      `English Skill-up track`,
      's_aug_promo.section_program.card_5_title',
      `English Skill-up track`,
      `English Skill-up track`,
      `English Skill-up track`,
      `English Skill-up track`,
    ],
    card_5_benefit: [
      <>
        영자 신문/원서 읽기 웨비나 무료 제공 <br />
        (10만원 상당)
      </>,
      `Join 'Read with Me' webinars for free (worth $77)`,
      's_aug_promo.section_program.card_5_benefit',
      `Join 'Read with Me' webinars for free (worth $77)`,
      `Join 'Read with Me' webinars for free (worth $77)`,
      `Join 'Read with Me' webinars for free (worth $77)`,
      `Join 'Read with Me' webinars for free (worth $77)`,
    ],
    card_5_list_1: [
      `- 영어 학습법 맞춤형 웨비나`,
      `- Webinars on English study tips`,
      's_aug_promo.section_program.card_5_list_1',
      `- Webinars on English study tips`,
      `- Webinars on English study tips`,
      `- Webinars on English study tips`,
      `- Webinars on English study tips`,
    ],
    card_5_list_2: [
      `- 영어 학습법 관련 교재 모음`,
      `- Curated materials for learning English`,
      's_aug_promo.section_program.card_5_list_2',
      `- Curated materials for learning English`,
      `- Curated materials for learning English`,
      `- Curated materials for learning English`,
      `- Curated materials for learning English`,
    ],
    label_fcfs: [
      `한정 혜택`,
      `Special benefit`,
      's_aug_promo.section_program.label_fcfs',
      `Special benefit`,
      `Special benefit`,
      `Special benefit`,
      `Special benefit`,
    ],
    label_free: [
      `한정 혜택`,
      `Special benefit`,
      's_aug_promo.section_program.label_free',
      `Special benefit`,
      `Special benefit`,
      `Special benefit`,
      `Special benefit`,
    ],
    label_contents: [
      `프로그램 구성`,
      `Contents`,
      's_aug_promo.section_program.label_contents',
      `Contents`,
      `Contents`,
      `Contents`,
      `Contents`,
    ],
    btn: [
      `프로그램 자세히 보기`,
      `View details`,
      's_aug_promo.section_program.btn',
      `View details`,
      `View details`,
      `View details`,
      `View details`,
    ],
  },
  section_pricing: {
    btn: [
      `가격 자세히 알아보기`,
      `View prices in detail`,
      's_aug_promo.section_pricing.btn',
      `View prices in detail`,
      `View prices in detail`,
      `View prices in detail`,
      `View prices in detail`,
    ],
  },
  note: {
    btn_dropdown: [`유의사항`, `Terms`, 's_aug_promo.note.btn_dropdown', `Terms`, `Terms`, `Terms`, `Terms`],
    webinar_1: [
      `수업권 구매 후 프로그램을 신청한 고객 한정으로 참여가 가능합니다.`,
      `Only the users who have signed up for this program after purchasing credits can participate.`,
      's_aug_promo.note.webinar_1',
      `Only the users who have signed up for this program after purchasing credits can participate.`,
      `Only the users who have signed up for this program after purchasing credits can participate.`,
      `Only the users who have signed up for this program after purchasing credits can participate.`,
      `Only the users who have signed up for this program after purchasing credits can participate.`,
    ],
    webinar_2: [
      `프로그램 신청시 웨비나가 자동으로 신청됩니다.`,
      `Participants of this program will also be automatically signed up for the webinars.`,
      's_aug_promo.note.webinar_2',
      `Participants of this program will also be automatically signed up for the webinars.`,
      `Participants of this program will also be automatically signed up for the webinars.`,
      `Participants of this program will also be automatically signed up for the webinars.`,
      `Participants of this program will also be automatically signed up for the webinars.`,
    ],
    webinar_3: [
      `수업권 구매 후 환불시 웨비나 참석이 불가합니다.`,
      `Refunds of purcahsed credits may limit your participation in the webinars.`,
      's_aug_promo.note.webinar_3',
      `Refunds of purcahsed credits may limit your participation in the webinars.`,
      `Refunds of purcahsed credits may limit your participation in the webinars.`,
      `Refunds of purcahsed credits may limit your participation in the webinars.`,
      `Refunds of purcahsed credits may limit your participation in the webinars.`,
    ],
    webinar_4: [
      `온라인 라이브로 약 60분간 한국어로 진행됩니다.`,
      `The webinars will be held live online for 60 minutes in Korean.`,
      's_aug_promo.note.webinar_4',
      `The webinars will be held live online for 60 minutes in Korean.`,
      `The webinars will be held live online for 60 minutes in Korean.`,
      `The webinars will be held live online for 60 minutes in Korean.`,
      `The webinars will be held live online for 60 minutes in Korean.`,
    ],
    webinar_5: [`FALSE`, ``, 's_aug_promo.note.webinar_5', ``, ``, ``, ``],
    webinar_6: [
      `주최자의 사정으로 세부 일정 및 주제,연사가 변경될 수 있습니다.`,
      `The detailed schedule, topic, and speaker are subject to change due to circumstances.`,
      's_aug_promo.note.webinar_6',
      `The detailed schedule, topic, and speaker are subject to change due to circumstances.`,
      `The detailed schedule, topic, and speaker are subject to change due to circumstances.`,
      `The detailed schedule, topic, and speaker are subject to change due to circumstances.`,
      `The detailed schedule, topic, and speaker are subject to change due to circumstances.`,
    ],
    global_1: [
      `전달받은 안내사항에 따라 요청한 형식으로 제출해주세요.`,
      `Please submit your resume in the format given in the guidelines.`,
      's_aug_promo.note.global_1',
      `Please submit your resume in the format given in the guidelines.`,
      `Please submit your resume in the format given in the guidelines.`,
      `Please submit your resume in the format given in the guidelines.`,
      `Please submit your resume in the format given in the guidelines.`,
    ],
    global_2: [
      `이력서 파일 1개만 제출 가능합니다.`,
      `Only one (1) file can be submitted per participant.`,
      's_aug_promo.note.global_2',
      `Only one (1) file can be submitted per participant.`,
      `Only one (1) file can be submitted per participant.`,
      `Only one (1) file can be submitted per participant.`,
      `Only one (1) file can be submitted per participant.`,
    ],
    global_3: [
      `커버레터/포트폴리오 피드백은 불가능합니다.`,
      `Feedback will not be given on cover letters/portfolios.`,
      's_aug_promo.note.global_3',
      `Feedback will not be given on cover letters/portfolios.`,
      `Feedback will not be given on cover letters/portfolios.`,
      `Feedback will not be given on cover letters/portfolios.`,
      `Feedback will not be given on cover letters/portfolios.`,
    ],
    global_4: [
      `영문으로 작성된 이력서만 피드백을 드립니다.`,
      `Feedback will be provided on English resumes only.`,
      's_aug_promo.note.global_4',
      `Feedback will be provided on English resumes only.`,
      `Feedback will be provided on English resumes only.`,
      `Feedback will be provided on English resumes only.`,
      `Feedback will be provided on English resumes only.`,
    ],
    study_abroad_1: [
      `각 PDF 파일은 프로그램 신청자에 한하여 9월 8일 금요일에 이메일로 전달됩니다.`,
      `Each PDF file will be sent via email to program participants on Sep. 8 (Fri).`,
      's_aug_promo.note.study_abroad_1',
      `Each PDF file will be sent via email to program participants on Sep. 8 (Fri).`,
      `Each PDF file will be sent via email to program participants on Sep. 8 (Fri).`,
      `Each PDF file will be sent via email to program participants on Sep. 8 (Fri).`,
      `Each PDF file will be sent via email to program participants on Sep. 8 (Fri).`,
    ],
    study_abroad_2: [
      `해당 문서에 대한 저작권은 각 저자와 링글에 있으며 무단 복제, 배포 확인시 법적인 조치를 취할 수 있습니다.`,
      `The authors and Ringle hold the copyrights to these works. Unauthorized copying, reuse, or distribution may lead to legal action.`,
      's_aug_promo.note.study_abroad_2',
      `The authors and Ringle hold the copyrights to these works. Unauthorized copying, reuse, or distribution may lead to legal action.`,
      `The authors and Ringle hold the copyrights to these works. Unauthorized copying, reuse, or distribution may lead to legal action.`,
      `The authors and Ringle hold the copyrights to these works. Unauthorized copying, reuse, or distribution may lead to legal action.`,
      `The authors and Ringle hold the copyrights to these works. Unauthorized copying, reuse, or distribution may lead to legal action.`,
    ],
    business_1: [
      `표현 배달 서비스를 받기 위해서는 링글 카카오 채널을 추가해야 합니다.`,
      `You must add Ringle KakaoTalk channel as a friend to receive this service.`,
      's_aug_promo.note.business_1',
      `You must add Ringle KakaoTalk channel as a friend to receive this service.`,
      `You must add Ringle KakaoTalk channel as a friend to receive this service.`,
      `You must add Ringle KakaoTalk channel as a friend to receive this service.`,
      `You must add Ringle KakaoTalk channel as a friend to receive this service.`,
    ],
    ielts_1: [
      `프로그램 신청자 한정 카카오 오픈 채팅방 미 참여시 공부 인증 및 혜택 증정이 어렵습니다 .`,
      `Participants must join the KakaoTalk open chat group for daily progress check and weekly rewards.`,
      's_aug_promo.note.ielts_1',
      `Participants must join the KakaoTalk open chat group for daily progress check and weekly rewards.`,
      `Participants must join the KakaoTalk open chat group for daily progress check and weekly rewards.`,
      `Participants must join the KakaoTalk open chat group for daily progress check and weekly rewards.`,
      `Participants must join the KakaoTalk open chat group for daily progress check and weekly rewards.`,
    ],
    ielts_2: [
      `자세한 내용은 프로그램 신청자 대상 9월 8일 금요일에 안내 이메일을 보내드립니다.`,
      `A detailed guideline will be sent to program participants on Sep. 8 (Fri) via email.`,
      's_aug_promo.note.ielts_2',
      `A detailed guideline will be sent to program participants on Sep. 8 (Fri) via email.`,
      `A detailed guideline will be sent to program participants on Sep. 8 (Fri) via email.`,
      `A detailed guideline will be sent to program participants on Sep. 8 (Fri) via email.`,
      `A detailed guideline will be sent to program participants on Sep. 8 (Fri) via email.`,
    ],
    self_study_1: [
      `원서 읽기 웨비나에서 원서는 제공되지 않습니다.`,
      `The English book is not provided to participants.`,
      's_aug_promo.note.self_study_1',
      `The English book is not provided to participants.`,
      `The English book is not provided to participants.`,
      `The English book is not provided to participants.`,
      `The English book is not provided to participants.`,
    ],
    self_study_2: [
      `자세한 내용은 프로그램 신청자 대상으로 9월 5일부터 개별 안내 이메일을 보내드립니다.`,
      `A detailed guideline will be sent to program participants from Sep. 5 (Tue) via email.`,
      's_aug_promo.note.self_study_2',
      `A detailed guideline will be sent to program participants from Sep. 5 (Tue) via email.`,
      `A detailed guideline will be sent to program participants from Sep. 5 (Tue) via email.`,
      `A detailed guideline will be sent to program participants from Sep. 5 (Tue) via email.`,
      `A detailed guideline will be sent to program participants from Sep. 5 (Tue) via email.`,
    ],
    self_study_3: [
      `프로그램 미신청시 해당 웨비나는 유료로 (49,900원) 신청이 가능합니다.`,
      `Users who have not signed up for this program can also join 'Read with Me' webinars at a fee ($38).`,
      's_aug_promo.note.self_study_3',
      `Users who have not signed up for this program can also join 'Read with Me' webinars at a fee ($38).`,
      `Users who have not signed up for this program can also join 'Read with Me' webinars at a fee ($38).`,
      `Users who have not signed up for this program can also join 'Read with Me' webinars at a fee ($38).`,
      `Users who have not signed up for this program can also join 'Read with Me' webinars at a fee ($38).`,
    ],
    self_study_4: [
      `10월 4일 수요일은 웨비나가 진행되지 않습니다.`,
      `There will be no webinar on Wednesday, October 4.`,
      's_aug_promo.note.self_study_4',
      `There will be no webinar on Wednesday, October 4.`,
      `There will be no webinar on Wednesday, October 4.`,
      `There will be no webinar on Wednesday, October 4.`,
      `There will be no webinar on Wednesday, October 4.`,
    ],
    self_study_5: [
      `9월 6일 첫 세션에 참석을 하지 못하는 경우 녹화본이 제공됩니다.`,
      `If you are unable to attend the first session on Sep. 6, a recording will be available.`,
      's_aug_promo.note.self_study_5',
      `If you are unable to attend the first session on Sep. 6, a recording will be available.`,
      `If you are unable to attend the first session on Sep. 6, a recording will be available.`,
      `If you are unable to attend the first session on Sep. 6, a recording will be available.`,
      `If you are unable to attend the first session on Sep. 6, a recording will be available.`,
    ],
  },
  section_blog_global: {
    title_section: [
      `링글과 함께 외국계 기업
채용 준비를 시작하세요.`,
      `Prepare for your global
career with Ringle.`,
      's_aug_promo.section_blog_global.title_section',
      `Prepare for your global
career with Ringle.`,
      `Prepare for your global
career with Ringle.`,
      `Prepare for your global
career with Ringle.`,
      `Prepare for your global
career with Ringle.`,
    ],
    subtitle_section: [
      `링글 활용법`,
      `How to Ringle`,
      's_aug_promo.section_blog_global.subtitle_section',
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
    ],
    title_1: [
      `영어 레쥬메 준비를 위한 
링글 수업 방법`,
      `How to use Ringle lessons 
to prepare your English resume`,
      's_aug_promo.section_blog_global.title_1',
      `How to use Ringle lessons 
to prepare your English resume`,
      `How to use Ringle lessons 
to prepare your English resume`,
      `How to use Ringle lessons 
to prepare your English resume`,
      `How to use Ringle lessons 
to prepare your English resume`,
    ],
    title_2: [
      `영어 인터뷰 준비를 위한 
링글 수업 방법`,
      `How to use Ringle lessons
to prepare for English interviews`,
      's_aug_promo.section_blog_global.title_2',
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
    ],
    caption_1: [
      `외국계 기업 채용 필수템`,
      `The first and essential step`,
      's_aug_promo.section_blog_global.caption_1',
      `The first and essential step`,
      `The first and essential step`,
      `The first and essential step`,
      `The first and essential step`,
    ],
    caption_2: [
      `외국계 기업 채용의 완성`,
      `Ace the final stage`,
      's_aug_promo.section_blog_global.caption_2',
      `Ace the final stage`,
      `Ace the final stage`,
      `Ace the final stage`,
      `Ace the final stage`,
    ],
  },
  section_blog_study_abroad: {
    title_section: [
      `링글과 함께 유학 준비를
시작하세요.`,
      `Prepare for study abroad 
with Ringle.`,
      's_aug_promo.section_blog_study_abroad.title_section',
      `Prepare for study abroad 
with Ringle.`,
      `Prepare for study abroad 
with Ringle.`,
      `Prepare for study abroad 
with Ringle.`,
      `Prepare for study abroad 
with Ringle.`,
    ],
    subtitle_section: [
      `링글 활용법`,
      `How to Ringle`,
      's_aug_promo.section_blog_study_abroad.subtitle_section',
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
    ],
    title_1: [
      <>
        IELTS / TOEFL / OPIc <br />
        스피킹 시험 준비방법
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      's_aug_promo.section_blog_study_abroad.title_1',
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
    ],
    title_2: [
      <>
        영어 에세이/SOP 작성을 위한
        <br />
        링글 수업 방법
      </>,
      <>
        How to use Ringle lessons
        <br />
        to prepare your SOP/essays
      </>,
      's_aug_promo.section_blog_study_abroad.title_2',
      <>
        How to use Ringle lessons
        <br />
        to prepare your SOP/essays
      </>,
      <>
        How to use Ringle lessons
        <br />
        to prepare your SOP/essays
      </>,
      <>
        How to use Ringle lessons
        <br />
        to prepare your SOP/essays
      </>,
      <>
        How to use Ringle lessons
        <br />
        to prepare your SOP/essays
      </>,
    ],
    title_3: [
      `해외 유학 인터뷰를 위한 
링글 수업 방법`,
      `How to use Ringle lessons
to prepare for English interviews`,
      's_aug_promo.section_blog_study_abroad.title_3',
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
      `How to use Ringle lessons
to prepare for English interviews`,
    ],
    caption_1: [
      `유학 준비의 시작`,
      `The starting point`,
      's_aug_promo.section_blog_study_abroad.caption_1',
      `The starting point`,
      `The starting point`,
      `The starting point`,
      `The starting point`,
    ],
    caption_2: [
      `유학 지원의 필수템`,
      `Build your story`,
      's_aug_promo.section_blog_study_abroad.caption_2',
      `Build your story`,
      `Build your story`,
      `Build your story`,
      `Build your story`,
    ],
    caption_3: [
      `유학 준비의 완성`,
      `Ace the final stage`,
      's_aug_promo.section_blog_study_abroad.caption_3',
      `Ace the final stage`,
      `Ace the final stage`,
      `Ace the final stage`,
      `Ace the final stage`,
    ],
  },
  section_blog_business: {
    title_section: [
      `링글과 함께 비즈니스
영어 공부를 시작하세요.`,
      `Master business English
with Ringle.`,
      's_aug_promo.section_blog_business.title_section',
      `Master business English
with Ringle.`,
      `Master business English
with Ringle.`,
      `Master business English
with Ringle.`,
      `Master business English
with Ringle.`,
    ],
    subtitle_section: [
      `링글 활용법`,
      `How to Ringle`,
      's_aug_promo.section_blog_business.subtitle_section',
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
    ],
    title_1: [
      <>
        비즈니스 교재를 이용한
        <br />
        링글 수업 방법
      </>,
      <>
        How to use Ringle lessons <br />
        for latest business English{' '}
      </>,
      's_aug_promo.section_blog_business.title_1',
      <>
        How to use Ringle lessons <br />
        for latest business English{' '}
      </>,
      <>
        How to use Ringle lessons <br />
        for latest business English{' '}
      </>,
      <>
        How to use Ringle lessons <br />
        for latest business English{' '}
      </>,
      <>
        How to use Ringle lessons <br />
        for latest business English{' '}
      </>,
    ],
    title_2: [
      <>
        영어 이메일, 프레젠테이션 교정을 위한
        <br />
        링글 수업 방법
      </>,
      <>
        How to use Ringle lessons
        <br />
        for excelling at work
      </>,
      's_aug_promo.section_blog_business.title_2',
      <>
        How to use Ringle lessons
        <br />
        for excelling at work
      </>,
      <>
        How to use Ringle lessons
        <br />
        for excelling at work
      </>,
      <>
        How to use Ringle lessons
        <br />
        for excelling at work
      </>,
      <>
        How to use Ringle lessons
        <br />
        for excelling at work
      </>,
    ],
    caption_1: [
      `트렌디한 주제와 표현을 영어로`,
      `Trending topics and expressions`,
      's_aug_promo.section_blog_business.caption_1',
      `Trending topics and expressions`,
      `Trending topics and expressions`,
      `Trending topics and expressions`,
      `Trending topics and expressions`,
    ],
    caption_2: [
      `내 능력을 마음껏 펼칠 준비`,
      `Get ready to shine`,
      's_aug_promo.section_blog_business.caption_2',
      `Get ready to shine`,
      `Get ready to shine`,
      `Get ready to shine`,
      `Get ready to shine`,
    ],
  },
  section_blog_ielts: {
    title_section: [
      `링글과 함께 공인 영어
시험 준비를 시작하세요.`,
      `Prepare for IELTS/OPIC/TOEFL with Ringle`,
      's_aug_promo.section_blog_ielts.title_section',
      `Prepare for IELTS/OPIC/TOEFL with Ringle`,
      `Prepare for IELTS/OPIC/TOEFL with Ringle`,
      `Prepare for IELTS/OPIC/TOEFL with Ringle`,
      `Prepare for IELTS/OPIC/TOEFL with Ringle`,
    ],
    subtitle_section: [
      `링글 활용법`,
      `How to Ringle`,
      's_aug_promo.section_blog_ielts.subtitle_section',
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
    ],
    title_1: [
      <>
        아이엘츠 / 토플 / 오픽
        <br />
        모의고사로 점수 미리보기
      </>,
      <>
        How to use CAF Analysis
        <br />
        to predict your score
      </>,
      's_aug_promo.section_blog_ielts.title_1',
      <>
        How to use CAF Analysis
        <br />
        to predict your score
      </>,
      <>
        How to use CAF Analysis
        <br />
        to predict your score
      </>,
      <>
        How to use CAF Analysis
        <br />
        to predict your score
      </>,
      <>
        How to use CAF Analysis
        <br />
        to predict your score
      </>,
    ],
    title_2: [
      <>
        아이엘츠 / 토플 / 오픽
        <br />
        스피킹 시험 준비방법
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      's_aug_promo.section_blog_ielts.title_2',
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
      <>
        IELTS / TOEFL / OPIc <br />
        How to Prepare for the Speaking Test
      </>,
    ],
    caption_1: [
      `시험 비용을 아끼는 꿀팁`,
      `Save your time and money`,
      's_aug_promo.section_blog_ielts.caption_1',
      `Save your time and money`,
      `Save your time and money`,
      `Save your time and money`,
      `Save your time and money`,
    ],
    caption_2: [
      `시험 당일처럼 실전 준비`,
      `Practice like the real thing`,
      's_aug_promo.section_blog_ielts.caption_2',
      `Practice like the real thing`,
      `Practice like the real thing`,
      `Practice like the real thing`,
      `Practice like the real thing`,
    ],
  },
  section_blog_self_study: {
    title_section: [
      <>
        링글과 함께 영어 자신감
        <br />
        높이기를 시작하세요
      </>,
      `Build confidence in English
with Ringle.`,
      's_aug_promo.section_blog_self_study.title_section',
      `Build confidence in English
with Ringle.`,
      `Build confidence in English
with Ringle.`,
      `Build confidence in English
with Ringle.`,
      `Build confidence in English
with Ringle.`,
    ],
    subtitle_section: [
      `링글 활용법`,
      `How to Ringle`,
      's_aug_promo.section_blog_self_study.subtitle_section',
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
      `How to Ringle`,
    ],
    title_1: [
      <>
        초급자를 위한
        <br />
        링글 수업 방법
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're a beginner
      </>,
      's_aug_promo.section_blog_self_study.title_1',
      <>
        How to use Ringle lessons
        <br />
        if you're a beginner
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're a beginner
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're a beginner
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're a beginner
      </>,
    ],
    title_2: [
      <>
        중급자를 위한
        <br />
        링글 수업 방법
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're intermediate level
      </>,
      's_aug_promo.section_blog_self_study.title_2',
      <>
        How to use Ringle lessons
        <br />
        if you're intermediate level
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're intermediate level
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're intermediate level
      </>,
      <>
        How to use Ringle lessons
        <br />
        if you're intermediate level
      </>,
    ],
    title_3: [
      <>
        링글 원서/영자 신문 읽기 웨비나
        <br />
        이용 가이드
      </>,
      <>
        How to make the most of <br />
        'Read with Me' webinars
      </>,
      's_aug_promo.section_blog_self_study.title_3',
      <>
        How to make the most of <br />
        'Read with Me' webinars
      </>,
      <>
        How to make the most of <br />
        'Read with Me' webinars
      </>,
      <>
        How to make the most of <br />
        'Read with Me' webinars
      </>,
      <>
        How to make the most of <br />
        'Read with Me' webinars
      </>,
    ],
    caption_1: [
      `영어 스피킹에 대한 두려움 극복`,
      `Overcome your fears`,
      's_aug_promo.section_blog_self_study.caption_1',
      `Overcome your fears`,
      `Overcome your fears`,
      `Overcome your fears`,
      `Overcome your fears`,
    ],
    caption_2: [
      `한단계 실력을 올리는`,
      `Level up your English`,
      's_aug_promo.section_blog_self_study.caption_2',
      `Level up your English`,
      `Level up your English`,
      `Level up your English`,
      `Level up your English`,
    ],
    caption_3: [
      `문법과 어휘력 향상에 탁월한`,
      `Boost up your English`,
      's_aug_promo.section_blog_self_study.caption_3',
      `Boost up your English`,
      `Boost up your English`,
      `Boost up your English`,
      `Boost up your English`,
    ],
  },
  section_blog: {
    btn: [
      `링글 블로그에서 보기`,
      `View on Ringle blog`,
      's_aug_promo.section_blog.btn',
      `View on Ringle blog`,
      `View on Ringle blog`,
      `View on Ringle blog`,
      `View on Ringle blog`,
    ],
    caption: [
      `FALSE`,
      `Only Korean versions available for all articles below.`,
      's_aug_promo.section_blog.caption',
      `Only Korean versions available for all articles below.`,
      `Only Korean versions available for all articles below.`,
      `Only Korean versions available for all articles below.`,
      `Only Korean versions available for all articles below.`,
    ],
  },
  section_pricing_main: {
    btn: [
      `가격 자세히 알아보기`,
      `View prices in detail`,
      's_aug_promo.section_pricing_main.btn',
      `View prices in detail`,
      `View prices in detail`,
      `View prices in detail`,
      `View prices in detail`,
    ],
  },
  section_pricing_detail: {
    btn: [
      `수업권 모두 보기`,
      `View all credits`,
      's_aug_promo.section_pricing_detail.btn',
      `View all credits`,
      `View all credits`,
      `View all credits`,
      `View all credits`,
    ],
  },
  section_hero_landing: {
    caption: [
      `영어 때문에 미뤄 온 
도전을 이루는 순간`,
      `Challenge yourself to realize your dreams`,
      's_aug_promo.section_hero_landing.caption',
      `Challenge yourself to realize your dreams`,
      `Challenge yourself to realize your dreams`,
      `Challenge yourself to realize your dreams`,
      `Challenge yourself to realize your dreams`,
    ],
    date_title: [
      `2023 링글 여름 프로모션`,
      `Ringle Summer Promotion`,
      's_aug_promo.section_hero_landing.date_title',
      `Ringle Summer Promotion`,
      `Ringle Summer Promotion`,
      `Ringle Summer Promotion`,
      `Ringle Summer Promotion`,
    ],
    date_desc: [
      `정규할인 : 23.8.11(금) - 9.7(목)`,
      `Regular: Aug 11- Sep 7, 2023`,
      's_aug_promo.section_hero_landing.date_desc',
      `Regular: Aug 11- Sep 7, 2023`,
      `Regular: Aug 11- Sep 7, 2023`,
      `Regular: Aug 11- Sep 7, 2023`,
      `Regular: Aug 11- Sep 7, 2023`,
    ],
  },
  section_hero_global: {
    caption: [
      `외국계 기업 채용 준비 프로그램`,
      `Spread Your Wings`,
      's_aug_promo.section_hero_global.caption',
      `Spread Your Wings`,
      `Spread Your Wings`,
      `Spread Your Wings`,
      `Spread Your Wings`,
    ],
    date_title: [
      `프로그램 신청 기간`,
      `Application Period`,
      's_aug_promo.section_hero_global.date_title',
      `Application Period`,
      `Application Period`,
      `Application Period`,
      `Application Period`,
    ],
    date_desc: [
      `23.8.1(화) - 9.7(목)`,
      `Aug 1 - Sep 7, 2023`,
      's_aug_promo.section_hero_global.date_desc',
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
    ],
  },
  section_hero_study_abroad: {
    caption: [
      `석/박사, MBA 유학 준비 프로그램`,
      `Reach Higher`,
      's_aug_promo.section_hero_study_abroad.caption',
      `Reach Higher`,
      `Reach Higher`,
      `Reach Higher`,
      `Reach Higher`,
    ],
    date_title: [
      `프로그램 신청 기간`,
      `Application Period`,
      's_aug_promo.section_hero_study_abroad.date_title',
      `Application Period`,
      `Application Period`,
      `Application Period`,
      `Application Period`,
    ],
    date_desc: [
      `23.8.1(화) - 9.7(목)`,
      `Aug 1 - Sep 7, 2023`,
      's_aug_promo.section_hero_study_abroad.date_desc',
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
    ],
  },
  section_hero_business: {
    caption: [
      `비즈니스 영어 향상 프로그램`,
      `No more stress`,
      's_aug_promo.section_hero_business.caption',
      `No more stress`,
      `No more stress`,
      `No more stress`,
      `No more stress`,
    ],
    date_title: [
      `프로그램 신청 기간`,
      `Application Period`,
      's_aug_promo.section_hero_business.date_title',
      `Application Period`,
      `Application Period`,
      `Application Period`,
      `Application Period`,
    ],
    date_desc: [
      `23.8.1(화) - 9.7(목)`,
      `Aug 1 - Sep 7, 2023`,
      's_aug_promo.section_hero_business.date_desc',
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
    ],
  },
  section_hero_ielts: {
    caption: [
      `공인 영어 시험 준비 프로그램`,
      `Aim for Perfect`,
      's_aug_promo.section_hero_ielts.caption',
      `Aim for Perfect`,
      `Aim for Perfect`,
      `Aim for Perfect`,
      `Aim for Perfect`,
    ],
    date_title: [
      `프로그램 신청 기간`,
      `Application Period`,
      's_aug_promo.section_hero_ielts.date_title',
      `Application Period`,
      `Application Period`,
      `Application Period`,
      `Application Period`,
    ],
    date_desc: [
      `23.8.1(화) - 9.7(목)`,
      `Aug 1 - Sep 7, 2023`,
      's_aug_promo.section_hero_ielts.date_desc',
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
    ],
  },
  section_hero_self_study: {
    caption: [
      `영어 자신감 극복 프로그램`,
      `For Confident English`,
      's_aug_promo.section_hero_self_study.caption',
      `For Confident English`,
      `For Confident English`,
      `For Confident English`,
      `For Confident English`,
    ],
    date_title: [
      `프로그램 신청 기간`,
      `Application Period`,
      's_aug_promo.section_hero_self_study.date_title',
      `Application Period`,
      `Application Period`,
      `Application Period`,
      `Application Period`,
    ],
    date_desc: [
      `23.8.1(화) - 9.7(목)`,
      `Aug 1 - Sep 7, 2023`,
      's_aug_promo.section_hero_self_study.date_desc',
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
      `Aug 1 - Sep 7, 2023`,
    ],
  },
  purchase_completed: {
    sign_up_label: [`주목`, `Attn`, 's_aug_promo.purchase_completed.sign_up_label', `Attn`, `Attn`, `Attn`, `Attn`],
    sign_up_banner: [
      `여름 프로모션 구매자 한정 혜택!`,
      `For Summer Promotion Purchases Only!`,
      's_aug_promo.purchase_completed.sign_up_banner',
      `For Summer Promotion Purchases Only!`,
      `For Summer Promotion Purchases Only!`,
      `For Summer Promotion Purchases Only!`,
      `For Summer Promotion Purchases Only!`,
    ],
  },
  sign_up_programs: {
    title1: [
      `도전 성공을 위한`,
      `Challenge yourself`,
      's_aug_promo.sign_up_programs.title1',
      `Challenge yourself`,
      `Challenge yourself`,
      `Challenge yourself`,
      `Challenge yourself`,
    ],
    title2: [
      `맞춤형 프로그램을 선택해주세요.`,
      `Choose the program(s) you wish to take.`,
      's_aug_promo.sign_up_programs.title2',
      `Choose the program(s) you wish to take.`,
      `Choose the program(s) you wish to take.`,
      `Choose the program(s) you wish to take.`,
      `Choose the program(s) you wish to take.`,
    ],
    subtitle: [
      `- 각 프로그램을 신청하면, 관련 교재, 웨비나 등 다양한 혜택을 드립니다.
- 동시에 여러 프로그램 신청이 가능합니다.`,
      `- Each track provides different benefits targeting the desired goal.
- You can sign up for more than one track.`,
      's_aug_promo.sign_up_programs.subtitle',
      `- Each track provides different benefits targeting the desired goal.
- You can sign up for more than one track.`,
      `- Each track provides different benefits targeting the desired goal.
- You can sign up for more than one track.`,
      `- Each track provides different benefits targeting the desired goal.
- You can sign up for more than one track.`,
      `- Each track provides different benefits targeting the desired goal.
- You can sign up for more than one track.`,
    ],
    marketing_title: [
      `맞춤형 프로그램을 위한 마케팅 수신 동의`,
      `Marketing consent for custom programs`,
      's_aug_promo.sign_up_programs.marketing_title',
      `Marketing consent for custom programs`,
      `Marketing consent for custom programs`,
      `Marketing consent for custom programs`,
      `Marketing consent for custom programs`,
    ],
    marketing_check1: [
      `프로그램 안내를 위해 전체 마케팅 수신에 동의합니다.`,
      `I give my consent to receive marketing information.`,
      's_aug_promo.sign_up_programs.marketing_check1',
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
      `I give my consent to receive marketing information.`,
    ],
    marketing_check2: [
      `로그인된 링글 이메일 계정으로 프로그램 안내가 발송됩니다.`,
      `Send me information about the program(s) to my registered email.`,
      's_aug_promo.sign_up_programs.marketing_check2',
      `Send me information about the program(s) to my registered email.`,
      `Send me information about the program(s) to my registered email.`,
      `Send me information about the program(s) to my registered email.`,
      `Send me information about the program(s) to my registered email.`,
    ],
    btn_later: [
      `나중에 신청하기`,
      `Sign up later`,
      's_aug_promo.sign_up_programs.btn_later',
      `Sign up later`,
      `Sign up later`,
      `Sign up later`,
      `Sign up later`,
    ],
    btn_sign_up: [
      `프로그램 신청`,
      `Sign up now`,
      's_aug_promo.sign_up_programs.btn_sign_up',
      `Sign up now`,
      `Sign up now`,
      `Sign up now`,
      `Sign up now`,
    ],
  },
  sign_up_comp: {
    title: (number) => [
      `${number}개 프로그램 신청이 완료되었습니다.`,
      `You're signed up for ${number} program(s).`,
      's_aug_promo.sign_up_comp.title',
      `You're signed up for ${number} program(s).`,
      `You're signed up for ${number} program(s).`,
      `You're signed up for ${number} program(s).`,
      `You're signed up for ${number} program(s).`,
    ],
  },
  section_share: {
    caption: [
      `나만 알기 아쉽다면?`,
      `Want to spread the word?`,
      's_aug_promo.section_share.caption',
      `Want to spread the word?`,
      `Want to spread the word?`,
      `Want to spread the word?`,
      `Want to spread the word?`,
    ],
    title: [
      `링글 프로모션 공유하기`,
      `Share with friends`,
      's_aug_promo.section_share.title',
      `Share with friends`,
      `Share with friends`,
      `Share with friends`,
      `Share with friends`,
    ],
  },
}
export const s_blog = {
  title: {
    blog: [`블로그`, `Blog`, 's_blog.title.blog', `部落格`, `部落格`, `Ringleブログ`, `Blog`],
    help_center: [
      `고객센터`,
      `Help Center`,
      's_blog.title.help_center',
      `客服中心`,
      `客服中心`,
      `ヘルプセンター`,
      `Trợ giúp`,
    ],
  },
  search_bar: {
    placeholder_default: [
      `궁금한 점을 검색해보세요.`,
      `Enter a keyword`,
      's_blog.search_bar.placeholder_default',
      `请输入问题关键字`,
      `請輸入問題關鍵字`,
      `気になる点を検索してみてください`,
      `Tìm kiếm những gì bạn tò mò.`,
    ],
    placeholder_loading: [
      `불러오는 중...`,
      `Loading...`,
      's_blog.search_bar.placeholder_loading',
      `正在为您准备答案`,
      `正在為您準備答案`,
      `読み込み中...`,
      `Đang tải...`,
    ],
    placeholder_no_result: [
      `검색 결과가 없습니다.`,
      `No results`,
      's_blog.search_bar.placeholder_no_result',
      `没有找到适合的答案`,
      `沒有找到適合的答案`,
      `検索結果はありません。`,
      `Không có kết quả`,
    ],
  },
  search_reult: {
    title: [`게시물 검색`, `Search`, 's_blog.search_reult.title', `搜索`, `搜尋`, `検索`, `Tìm kiếm`],
    text_btn: [
      `이전 페이지로 돌아가기`,
      `Back to list`,
      's_blog.search_reult.text_btn',
      `返回上一页`,
      `返回上一頁`,
      `前のページに戻る`,
      `Trở về trang trang trước`,
    ],
    msg_result: (search_keyword, n) => [
      <>
        “{search_keyword}”에 대한 {n}개의 검색 결과
      </>,
      <>
        {n} result for “{search_keyword}”
      </>,
      's_blog.search_reult.msg_result',
      <>
        {n} 结果 “{search_keyword}”
      </>,
      <>
        {n} 結果 “{search_keyword}”
      </>,
      <>
        “{search_keyword}”について{n}個の検索結果
      </>,
      `{n} kết quả cho A “{search_keyword}”`,
    ],
    btn: [`자세히 보기`, `View detail`, 's_blog.search_reult.btn', `了解详情`, `瞭解詳情`, `詳しく見る`, `Nhìn kĩ`],
    desc_no_result: (search_keyword) => [
      <>
        “{search_keyword}”에 대한 검색 결과가 없어요.
        <br />
        단어의 철자를 확인 하거나 일반적인 검색어로 다시 검색해보세요.
      </>,
      <>There are no results for “{search_keyword}”. Please enter a different keyword.</>,
      's_blog.search_reult.desc_no_result',
      <>没有关于 “{search_keyword}” 的答案 请用其他关键字重新搜索</>,
      <>沒有關於 “{search_keyword}” 的答案 請用其他關鍵字重新搜尋</>,
      <>
        “{search_keyword}”についた検索結果がありません。
        単語のスペルを確認していただくか、一般的に使われる検索語で再度検索してください。
      </>,
      <>
        Không có kết quả tìm kiếm cho “{search_keyword}”. Kiểm tra chính tả của từ hoặc thử tìm kiếm lại bằng cụm từ tìm
        kiếm chung.
      </>,
    ],
    cateory_1: [`전체`, `All`, 's_blog.search_reult.cateory_1', `全部`, `全部`, `すべて`, `Tất cả`],
    cateory_2: [
      `자주 묻는 질문`,
      `FAQ`,
      's_blog.search_reult.cateory_2',
      `常见问题`,
      `常見問題`,
      `よくある質問`,
      `Những câu hỏi thường gặp`,
    ],
    cateory_3: [
      `공지사항`,
      `Announcements`,
      's_blog.search_reult.cateory_3',
      `通知事项`,
      `公告事項`,
      `お知らせ`,
      `Cập nhật`,
    ],
  },
  section_contact_us: {
    title: [
      `더 궁금한 것이 있다면
링글 고객센터로 연락해주세요.`,
      `Need help or want to share feedback
with our team?`,
      's_blog.section_contact_us.title',
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
    ],
    btn: [
      `링글팀에 문의하기`,
      `Contact Us`,
      's_blog.section_contact_us.btn',
      `Contact Us`,
      `Contact Us`,
      `Contact Us`,
      `Contact Us`,
    ],
    caption_1: [
      `대표전화`,
      `Call Us`,
      's_blog.section_contact_us.caption_1',
      `Call Us`,
      `Call Us`,
      `Call Us`,
      `Call Us`,
    ],
    number: [
      `02-6204-8880`,
      `02-6204-8880`,
      's_blog.section_contact_us.number',
      `02-6204-8880`,
      `02-6204-8880`,
      `02-6204-8880`,
      `02-6204-8880`,
    ],
    caption_2: [
      `운영시간`,
      `Operating Hours`,
      's_blog.section_contact_us.caption_2',
      `Operating Hours`,
      `Operating Hours`,
      `Operating Hours`,
      `Operating Hours`,
    ],
    desc_1: [
      `평일 오전 10:00 - 오후 6:00 KST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      's_blog.section_contact_us.desc_1',
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      `Mon to Fri 10:00 AM - 6:00 PM JST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
    ],
    desc_2: [
      `점심시간 : 오후 1:00 - 오후 2:00 KST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
      's_blog.section_contact_us.desc_2',
      `Lunchtime : 1:00  - 2:00 PM KST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
      `Lunchtime : 1:00 - 2:00 PM JST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
    ],
  },
  detail_page: {
    text_btn: [`이전`, `Back`, 's_blog.detail_page.text_btn', `上一步`, `上一步`, `戻る`, `Trước`],
    btn: [
      `목록으로 돌아가기`,
      `Back to the list`,
      's_blog.detail_page.btn',
      `回到主页`,
      `回到主頁`,
      `一覧に戻る`,
      `Đi đến danh sách`,
    ],
  },
  header_title: {
    search: [`검색`, `Search`, 's_blog.header_title.search', `搜索`, `搜尋`, `検索する`, `Tìm kiếm`],
    announcements: [
      `공지사항`,
      `Announcements`,
      's_blog.header_title.announcements',
      `通知事项`,
      `公告事項`,
      `お知らせ`,
      `Cập nhật`,
    ],
  },
  category: {
    ringle_guide: [
      `링글 가이드`,
      `How to Ringle`,
      's_blog.category.ringle_guide',
      `Ringle课程介绍`,
      `Ringle課程介紹`,
      `Ringleレッスンガイド`,
      `và hướng dẫn buổi học`,
    ],
    ringle_use: [
      `링글 활용법`,
      `Ringle Tips`,
      's_blog.category.ringle_use',
      `Ringle使用方法`,
      `Ringle使用方法`,
      `RingleのTips`,
      `Ringle Tips`,
    ],
    see_more: [`더보기`, `See more`, 's_blog.category.see_more', `查看更多`, `查看更多`, `もっと見る`, `Xem thêm`],
    home: [`홈`, `Home`, 's_blog.category.home', `主页`, `主頁`, `Home`, `Trang chủ`],
  },
  recommen_post: [
    `추천 포스트`,
    `Featured Posts`,
    's_blog.recommen_post',
    `推荐文章`,
    `推薦文章`,
    `おすすめ記事`,
    `Nổi bật`,
  ],
}
export const n_common = {
  btn_back_list: [`뒤로 가기`, `Back`, 'n_common.btn_back_list', `上一步`, `上一步`, `戻る`, ``],
  btn_book: [`예약하기`, `Book`, 'n_common.btn_book', `课程预约`, `課程預約`, `予約する`, `Đặt lịch học`],
  toast_link: [
    `링크를 복사했어요.`,
    `Link is copied to your clipboard.`,
    'n_common.toast_link',
    `链结已複製`,
    `連結已複製`,
    `リンクをコピーしました。`,
    `Link is copied to your clipboard.`,
  ],
  btn_back: [`이전`, `Back`, 'n_common.btn_back', `上一步`, `上一步`, `戻る`, `Trước`],
  btn_next: [`다음`, `Next`, 'n_common.btn_next', `下一步`, `下一步`, `次へ`, `Tiếp theo`],
  label_20min: [`20분`, `20min`, 'n_common.label_20min', `20分钟`, `20分鐘`, `20分`, ``],
  label_40min: [`40분`, `40min`, 'n_common.label_40min', `40分钟`, `40分鐘`, `40分`, ``],
  btn_book_full: [
    `수업 예약하기`,
    `Book a lesson`,
    'n_common.btn_book_full',
    `预约课程`,
    `預約課程`,
    `レッスンを予約する`,
    ``,
  ],
  btn_cancel: [`취소`, `Cancel`, 'n_common.btn_cancel', `取消`, `取消`, `キャンセル`, ``],
  btn_ok: [`확인`, `Ok`, 'n_common.btn_ok', `确认`, `確認`, `確認した`, ``],
  btn_confirm: [`확인`, `Confirm`, 'n_common.btn_confirm', `确认`, `確認`, `確認する`, ``],
  btn_back_page: (page) => [
    `${page} 로 가기`,
    `Go to ${page}`,
    'n_common.btn_back_page',
    `前往 ${page}`,
    `前往 ${page}`,
    `${page} へ移動`,
    ``,
  ],
  btn_more: [`더 보기`, `View more`, 'n_common.btn_more', `看更多`, `看更多`, `もっと見る`, ``],
  btn_delete: [`삭제`, `Delete`, 'n_common.btn_delete', `删除`, `刪除`, `削除`, ``],
  label_note_full: [
    `꼭 확인해주세요!`,
    `Please remember!`,
    'n_common.label_note_full',
    `一定要确认！`,
    `一定要確認！`,
    `是非ご覧ください！`,
    ``,
  ],
  label_faq: [`FAQ`, `FAQ`, 'n_common.label_faq', `FAQ`, `FAQ`, `FAQ`, `FAQ`],
  btn_cancel_full: [`취소하기`, `Cancel`, 'n_common.btn_cancel_full', `取消`, `取消`, `キャンセルする`, ``],
  btn_ok_full: [`확인하기`, `Confirm`, 'n_common.btn_ok_full', `确认`, `確認`, `確認する`, ``],
  btn_confirm_full: [`확인하기`, `Confirm`, 'n_common.btn_confirm_full', `确认`, `確認`, `確認する`, ``],
  btn_agree: [`동의`, `Agree`, 'n_common.btn_agree', `同意`, `同意`, `同意する`, ``],
  btn_agree_full: [`동의하기`, `Agree`, 'n_common.btn_agree_full', `同意`, `同意`, `同意する`, ``],
  btn_close: [`닫기`, `Close`, 'n_common.btn_close', `关闭`, `關閉`, `閉じる`, ``],
  btn_leave: [`나가기`, `Leave`, 'n_common.btn_leave', `离开`, `離開`, `ページを離れる`, ``],
  btn_continue: [`계속하기`, `Continue`, 'n_common.btn_continue', `继续`, `繼續`, `続行する`, ``],
  btn_refresh: [`초기화`, `Refresh`, 'n_common.btn_refresh', `重新整理`, `重新整理`, `更新する`, ``],
  btn_all: [`전체보기`, `View all`, 'n_common.btn_all', `看全部`, `看全部`, `もっと見る`, ``],
  btn_detail: [`자세히 보기`, `View detail`, 'n_common.btn_detail', `看更多`, `看更多`, `詳細を見る`, ``],
  btn_share: [`공유하기`, `Share`, 'n_common.btn_share', `分享`, `分享`, `共有する`, ``],
  btn_filter: [`필터 적용`, `Apply Filter`, 'n_common.btn_filter', `套用筛选器`, `套用篩選器`, `フィルターの適用`, ``],
  label_weekly: [`매주 수업 듣기`, `Every Week`, 'n_common.label_weekly', `每週`, `每週`, `毎週レッスンを受ける`, ``],
  label_hold: [
    `첫 수업 시작 전`,
    `Lesson days are frozen`,
    'n_common.label_hold',
    `无法选择的日期`,
    `無法選擇的日期`,
    `初回のレッスン開始前`,
    ``,
  ],
  label_no_refund: [`환불 불가`, `No Refund`, 'n_common.label_no_refund', `无法退款`, `無法退款`, `返金不可`, ``],
  label_pointback: [
    `50% 포인트백`,
    `50% Value Back`,
    'n_common.label_pointback',
    `50% 积分返点`,
    `50% 點數回饋`,
    `50%ポイント還元`,
    ``,
  ],
  label_featured: [
    `링글 추천`,
    `Featured`,
    'n_common.label_featured',
    `Ringle推荐`,
    `Ringle推薦`,
    `Ringleのおすすめ`,
    ``,
  ],
  label_adults_only: [
    `성인만 가능`,
    `Adults only`,
    'n_common.label_adults_only',
    `只限成人`,
    `只限成人`,
    `成人のみ可`,
    ``,
  ],
  label_duration: [`수강기간`, `Expiration dates`, 'n_common.label_duration', `到期日`, `到期日`, `受講期間`, ``],
  label_payment: [`결제일`, `Payment date`, 'n_common.label_payment', `付款日`, `付款日`, `お支払い日`, ``],
  label_lessons: [`수업 횟수`, `Lessons`, 'n_common.label_lessons', `课程次数`, `課程次數`, `レッスン回数`, ``],
  label_length: [`수업 길이`, `Lesson Duration`, 'n_common.label_length', `课程长度`, `課程長度`, `レッスンの長さ`, ``],
  label_inprogress: [`진행 중`, `In Progress`, 'n_common.label_inprogress', `进行中`, `進行中`, `進行中`, ``],
  label_ongoing: [`진행 중`, `On going`, 'n_common.label_ongoing', `进行中`, `進行中`, `進行中`, ``],
  label_end: [`종료`, `End`, 'n_common.label_end', `结束`, `結束`, `終了`, ``],
  label_noshow: [`노쇼`, `No-show`, 'n_common.label_noshow', `未出席`, `未出席`, `ノーショー`, ``],
  credit_regular: [
    `정규수업권`,
    `Regular`,
    'n_common.credit_regular',
    `一般课程券`,
    `一般課程券`,
    `通常レッスンチケット`,
    ``,
  ],
  credit_lastmin: [
    `당일수업권`,
    `Last-minute`,
    'n_common.credit_lastmin',
    `当日课程券`,
    `當日課程券`,
    `当日レッスンチケット`,
    ``,
  ],
  credit_fastbook: [
    `긴급편성수업권`,
    `Fastbook`,
    'n_common.credit_fastbook',
    `紧急补充课程券`,
    `緊急補充課程券`,
    `緊急編成レッスンチケット`,
    ``,
  ],
  credit_window: [
    `시간한정수업권`,
    `Limited Window`,
    'n_common.credit_window',
    `时间限定课程券`,
    `時間限定課程券`,
    `時間限定レッスンチケット`,
    ``,
  ],
  label_note: [`유의사항`, `Note`, 'n_common.label_note', `注意事项`, `注意事項`, `注意事項`, ``],
  mycredit_note_mobile: [
    <>
      증명서 발급, 수강기간 연장, 수업권 변경은
      <br />
      PC 웹사이트를 이용해주세요.
    </>,
    <>
      Please access the RIngle website via a PC
      <br />
      to issue certificates or extend/exchange your credits.
    </>,
    'n_common.mycredit_note_mobile',
    `发行证明文件、延长课程使用期限和课程券变更，请使用Ringle网页。`,
    `發行證明文件、延長課程使用期限和課程券變更，請使用Ringle網頁。`,
    <>
      証明書の発行、受講期間の延長、チケットの変更はできません。
      <br />
      PCサイトをご利用ください。
    </>,
    ``,
  ],
  btn_save: [`저장`, `Save`, 'n_common.btn_save', `保存`, `儲存`, `保存`, ``],
  btn_save_full: [`저장하기`, `Save`, 'n_common.btn_save_full', `保存`, `儲存`, `保存する`, ``],
  btn_save_img: [
    `이미지 저장`,
    `Save image`,
    'n_common.btn_save_img',
    `储存图片`,
    `儲存圖片`,
    `画像を保存する`,
    `Save image`,
  ],
  btn_link: [`링크 복사`, `Copy link`, 'n_common.btn_link', `複製连结`, `複製連結`, `リンクをコピーする`, `Copy link`],
  toast_img: [
    `이미지를 저장했어요.`,
    `Saved image.`,
    'n_common.toast_img',
    `图片已储存`,
    `圖片已儲存`,
    `画像は保存されました`,
    `Image saved.`,
  ],
  add_favorites: [
    `찜한 튜터에 추가했어요.`,
    `Added to Favorite Tutors.`,
    'n_common.add_favorites',
    `已加入我收藏的教师`,
    `已加入我收藏的教師`,
    `お気に入りのチューターに追加しました。`,
    ``,
  ],
  delete_favorites: [
    `찜한 튜터에서 삭제했어요.`,
    `Removed from Favorite Tutors.`,
    'n_common.delete_favorites',
    `从收藏的教师中移除`,
    `從收藏的教師中移除`,
    `お気に入りチューターから削除しました。`,
    ``,
  ],
  btn_recommend: [`추천`, `Recommend`, 'n_common.btn_recommend', `推荐`, `推薦`, `おすすめ`, ``],
  disconnected: {
    placeholder: {
      title: [
        `인터넷에 연결되어 있지 않습니다.`,
        `You are not connected to the internet.`,
        'n_common.disconnected.placeholder.title',
        `您没有网路连线`,
        `您沒有網路連線`,
        `インターネットに接続されていません。`,
        ``,
      ],
      desc: [
        `데이터를 이용하거나 Wi-fi 연결을 확인하세요.
인터넷에 연결되면 새로고침을 눌러주세요.`,
        `Please use data or check your Wi-Fi connection.
Refresh the page when you connected to the internet`,
        'n_common.disconnected.placeholder.desc',
        `请确认Wi-Fi连线状态
并重新整理页面`,
        `請確認Wi-Fi連線狀態
並重新整理頁面`,
        `データを利用するか、Wi-fi接続を確認してください。
インターネットに接続されたら、「更新」を押してください。`,
        ``,
      ],
      btn: [`새로고침`, `Refresh`, 'n_common.disconnected.placeholder.btn', `重新整理`, `重新整理`, `リフレッシュ`, ``],
    },
  },
  btn_sign_up_full: [
    `회원가입 하기`,
    `Sign up`,
    'n_common.btn_sign_up_full',
    `会员注册`,
    `會員註冊`,
    `会員登録`,
    `Đăng ký`,
  ],
  dont_show_again: [
    `다시 보지 않기`,
    `Don't show again`,
    'n_common.dont_show_again',
    `不再显示`,
    `不再顯示`,
    `今後表示しない`,
    ``,
  ],
}
export const n_content = {
  label_premium: [`#premium`, `#premium`, 'n_content.label_premium', `#premium`, `#premium`, `#premium`, `#premium`],
  rwm: {
    info_deadline: [
      `신청 마감`,
      `Deadline`,
      'n_content.rwm.info_deadline',
      `Deadline`,
      `申請截止日`,
      `申し込み締め切り`,
      `Deadline`,
    ],
    info_period: [`진행 기간`, `Period`, 'n_content.rwm.info_period', `Date`, `活動日期`, `実施期間`, `Date`],
    info_format: [`진행 방식`, `Method`, 'n_content.rwm.info_format', `Method`, `進行方式`, `進行方法`, `Method`],
    note_title: [
      `꼭 확인해주세요!`,
      `Please check!`,
      'n_content.rwm.note_title',
      `重要公告`,
      `重要公告`,
      `ぜひチェックしてみてください`,
      `Important notice`,
    ],
    note_desc1: [
      <>
        <b>결제는 국내 카드를 통해서만 가능합니다.</b> 해외 결제 희망 시 [관련 링크]의 해외 결제 페이지에서
        신청해주세요.
      </>,
      <>
        <b>Payment can only be processed via domestic cards.</b> If you wish to process your payment via international
        cards, please register through the registration page in the [Related Links] section.
      </>,
      'n_content.rwm.note_desc1',
      <>
        <b>支付仅可使用韩国信用卡。</b> 如果您希望使用国际信用卡进行支付，请通过网站右下角的Channel.io与我们联系。
      </>,
      <>
        <b>僅可使用韓國信用卡付款。</b> 如果您希望使用國際信用卡付款，請使用網站右下角的Channel.io與我們聯絡。
      </>,
      <>
        <b>決済は国内カードでのみ可能です。</b>
        海外決済をご希望の場合は、ホームページ左下のチャンネルトークでお問い合わせください。
      </>,
      <>
        <b>Payment can only be processed via domestic cards.</b> If you wish to process your payment via international
        cards, please reach to us through the Channel.io at the bottom left corner of the website.
      </>,
    ],
    note_desc2: [
      `결제 시 링글 포인트 사용은 불가합니다.`,
      `You cannot use Ringle points for payment.`,
      'n_content.rwm.note_desc2',
      `您不能使用Ringle积分付款`,
      `您不能使用Ringle點數付款`,
      `決済時にリンクルポイントの使用はできません。`,
      `You cannot use Ringle points for payment.`,
    ],
    note_desc3: [
      <>
        신청 및 결제 완료 시 <b>링글 계정의 알림 수신 설정한 이메일 주소로 관련 메일을 보내드려요.</b> 이메일 주소
        변경은 [계정 관리] {`>`} [알림 수신 설정] {`>`} [이메일]에서 할 수 있습니다.
      </>,
      <>
        Upon completion of registration and payment,{' '}
        <b>you will receive a confirmation email to the address saved for receiving email notifications.</b> You can
        modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
      </>,
      'n_content.rwm.note_desc3',
      <>
        注册和支付完成后，<b>您将收到一封确认邮件，发送至您用于接收电子邮件通知的地址。</b> 您可以在​​[我的帐户] {`>`}{' '}
        [通知设定] {`>`} [接收Email]修改邮箱地址
      </>,
      <>
        註冊和付款完成後，<b>您將收到一封確認郵件，發送至您用於接收電子郵件通知的地址。</b> 您可以在​​[我的帳戶] {`>`}{' '}
        [通知設定] {`>`} [接收Email]修改郵件
      </>,
      <>
        申請および決済完了時に<b>Ringleアカウントの通知受信設定したメールアドレスに関連メールをお送りします。 </b>{' '}
        メールアドレスの変更は[アカウント管理] {`>`}で行えます。[通知受信設定] {`>`}
        から変更できます。[メール]から変更することができます。.
      </>,
      <>
        Upon completion of registration and payment,{' '}
        <b>you will receive a confirmation email to the address saved for receiving email notifications.</b> You can
        modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
      </>,
    ],
    note_desc4: [
      <>
        <b>취소 및 환불은 프로그램 진행 시작일 전까지 가능합니다.</b> 다음 정보와 함께 고객센터 (채널톡)으로 문의해
        주세요.
        <br />
        1) 신청 프로그램명, 2) 성함, 3) 링글 계정 이메일 주소
      </>,
      <>
        <b>Cancellation and refund are only available until the starting day of the program.</b> Please reach to us
        through the Help Center at the bottom left corner of the website with the following information.
        <br />
        1) Program title, 2) Name, 3) Ringle account email address
      </>,
      'n_content.rwm.note_desc4',
      <>
        <b>取消和退款仅在注册期间提供。</b> 请通过网站右下角的Channel.io与我们联系，并提供以下信息。
        <br />
        1) 课程标题，2) 姓名，3) Ringle 账户邮箱
      </>,
      <>
        <b>取消和退款僅可在註冊期間進行。</b> 請使用網站右下角的Channel.io與我們聯絡，並提供以下資料。
        <br />
        1) 課程標題，2) 姓名，3) Ringle 賬戶郵件地址
      </>,
      <>
        <b>キャンセルおよび払い戻しは、プログラム開始日までに可能です</b>
        <b>次の情報と一緒に、ホームページ左下のチャンネルトークにお問い合わせください</b>
        <br />
        1)申請プログラム名、2)氏名、3)リンクルアカウントのメールアドレス
        <br />{' '}
      </>,
      <>
        <b>Cancellation and refund are only available until the starting day of the program.</b> Please reach to us
        through the Channel.io at the bottom left corner of the website with the following information.
        <br />
        1) Program title, 2) Name, 3) Ringle account email address
      </>,
    ],
    detail: [
      `상세 정보`,
      `Details`,
      'n_content.rwm.detail',
      `详细资料`,
      `詳細資料`,
      `詳細情報`,
      `Detailed information`,
    ],
    faq: [`자주 묻는 질문`, `FAQ`, 'n_content.rwm.faq', `常见问题`, `常見問題`, `よくある質問`, `FAQ`],
    related: [
      `관련 링크`,
      `Related Links`,
      'n_content.rwm.related',
      `有关的连结`,
      `有關的連結`,
      `関連リンク`,
      `Related Links`,
    ],
    speaker: [
      `함께할 튜터`,
      `Tutors`,
      'n_content.rwm.speaker',
      `教师团队`,
      `教師團隊`,
      `一緒に働くチューター`,
      `Tutors`,
    ],
    btn: [`신청하기`, `Register`, 'n_content.rwm.btn', `前往申请`, `前往申請`, `お申し込みはこちら`, `Register`],
    faq_list1: [
      `‘Read Together (함께 읽기)’와 ‘Read Alone (혼자 읽기)’ 프로그램은 어떻게 다르나요?`,
      `How are 'Read Together' and 'Read Alone' programs different?`,
      'n_content.rwm.faq_list1',
      `"一起阅读（Read Together）"和"独自阅读（Read Alone）"活动有什么区别呢？`,
      `"一起閱讀（Read Together）"和"獨自閱讀（Read Alone）"活動有什麽差別呢？`,
      `Read Together(一緒に読む)」と「Read Alone(一人で読む)」プログラムの違いは何ですか？`,
      `How are 'Read Together' and 'Read Alone' programs different?`,
    ],
    faq_list2: [
      `프로그램에 신청했는데, 신청 확정 안내 이메일을 따로 보내주나요?`,
      `Do I get a confirmation email when I complete my registration?`,
      'n_content.rwm.faq_list2',
      `在完成注册后，我会收到确认邮件吗？`,
      `在完成註冊後，我會收到確認郵件嗎？`,
      `プログラムに申し込んだのですが、申し込み確定案内メールを別途送ってもらえますか？`,
      `Do I get a confirmation email when I complete my registration?`,
    ],
    faq_desc1: [
      <>
        결제 완료 시 링글 계정의 알림 수신 설정한 이메일 주소로 메일을 보내드려요. <br />
        해당 메일을 통해 세부 프로그램 일정 및 참여 방법 등을 확인할 수 있어요. <br />
        *이메일 주소 변경은 [계정 관리] {`>`} [알림 수신 설정] {`>`} [이메일]에서 할 수 있습니다. <br />
        *결제 후 신청 확인 메일을 받지 못했다면, 다음 정보와 함께 고객센터 (채널톡)로 문의해 주세요. <br />
        1) 신청 프로그램명, 2) 성함, 3) 링글 계정 이메일 주소 <br />
      </>,
      <>
        Upon completion of registration and payment, you will receive a confirmation email to the address saved for
        receiving email notifications. <br />
        *You can modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
        <br />
        *If you haven’t received any confirmation email from us, please reach to us through the Help Center with the
        following information.
        <br />
        1) Program title, 2) Name, 3) Ringle account email address
        <br />
      </>,
      'n_content.rwm.faq_desc1',
      <>
        付款完成后，我们将发送邮件至您在Ringle帐户的通知设置中提供的电子邮件地址。 <br />
        在该封电子邮件中，您可以确认详细的课程时间表和参与方式等讯息。 <br /> *您可以在[帐户管理] {`>`} [通知设置] {`>`}{' '}
        [电子邮件]中更改电子邮件地址。 <br />{' '}
        *如果在付款后未收到确认邮件，请使用Ringle网站左下角的Channel.io并提供以下信息查询。 <br />
        1) 申请活动名称，2) 姓名，3) Ringle帐户电子邮件地址 <br />{' '}
      </>,
      <>
        付款完成後，我們將發送郵件至您在Ringle帳戶的通知設置中提供的電子郵件地址。 <br />
        在該封電子郵件中，您可以確認詳細的課程時間表和參與方式等訊息。 <br /> *您可以在[帳戶管理] {`>`} [通知設置] {`>`}{' '}
        [電子郵件]中更改電子郵件地址。 <br />{' '}
        *如果在付款後未收到確認郵件，請使用Ringle網站左下角的Channel.io並提供以下信息查詢。 <br />
        1) 申請活動名稱，2) 姓名，3) Ringle帳戶電子郵件地址 <br />{' '}
      </>,
      <>
        お支払い 決済完了時、Ringleアカウントの通知受信設定したメールアドレスにメールをお送りします。 <br />{' '}
        *メールアドレスの変更は[アカウント管理] {`>`}で行えます。
        そのメールから詳細なプログラムスケジュールや参加方法などを確認することができます。 <br />{' '}
        *メールアドレスの変更は[アカウント管理] {`>`}で行えます。[通知の受信設定] {`>`}[<br />{' '}
        *決済後、申請確認メールが届かない場合は、以下の情報を添えて、Ringleホームページ左下のチャンネルトークにお問い合わせください。{' '}
        <br />
        1) 申請プログラム名、2) 氏名、3) Ringleアカウントのメールアドレス
        <br />
      </>,
      <>
        Upon completion of registration and payment, you will receive a confirmation email to the address saved for
        receiving email notifications. <br />
        *You can modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
        <br />
        *If you haven’t received any confirmation email from us, please reach to us through the Channel.io at the bottom
        left corner of the website with the following information.
        <br />
        1) Program title, 2) Name, 3) Ringle account email address
        <br />
      </>,
    ],
    faq_list3: [
      `프로그램에 신청했는데, 관련 안내 및 자료를 받는 이메일 주소를 변경하고 싶어요.`,
      `I completed my registration to the program, but I would like to change the email address to receive email notifications.`,
      'n_content.rwm.faq_list3',
      `我已经完成注册，但是想要更改接收通知的邮箱。`,
      `我已經完成註冊，但是想要更改接收通知的郵件地址。`,
      `プログラムに申し込みましたが、関連案内や資料を受け取るメールアドレスを変更したいです。`,
      `I completed my registration to the program, but I would like to change the email address to receive email notifications.`,
    ],
    faq_desc2: [
      <>
        프로그램 관련 안내 및 학습 자료는 링글 계정에 등록된 알림 수신 설정한 이메일 주소로 보내드립니다. <br />
        프로그램 관련 안내를 받는 이메일 주소 변경을 원하시면, [계정 관리] {`>`} [알림 수신 설정] {`>`} [이메일]에서 할
        수 있습니다. <br />
      </>,
      <>
        All guidelines and materials will be provided via email to the address you saved for receiving email
        notifications. <br />
        *You can modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
        <br />
      </>,
      'n_content.rwm.faq_desc2',
      <>
        有关课程的通知和学习资料将发送到您在Ringle帐户的通知设置中注册的电子邮箱。 <br />
        如果您想更改接收与课程相关的通知的电子邮件地址，请在[帐户管理] {`>`} [通知设置] {`>`} [电子邮件]中进行更改。{' '}
        <br />{' '}
      </>,
      <>
        有關課程的通知和學習資料將發送到您在Ringle帳戶的通知設置中註冊的電子信箱。 <br />
        如果您想更改接收與課程相關的通知的電子信箱，請在[帳戶管理] {`>`} [通知設置] {`>`} [電子郵件]中進行更改。 <br />{' '}
      </>,
      <>
        {' '}
        お知らせ
        プログラム関連案内及び学習資料は、Ringleアカウントに登録された通知受信設定されたメールアドレスに送信されます。
        <br />
        変更する プログラム関連の案内を受け取るメールアドレスを変更したい場合は、[アカウント管理] {`>`} {`>`}
        [通知受信設定] {`>`}から変更できます。[メール]で変更することができます。 <br />
      </>,
      <>
        All guidelines and materials will be provided via email to the address you saved for receiving email
        notifications. <br />
        *You can modify the address on [My Account] {`>`} [Notification Settings] {`>`} [Receive Email].
        <br />
      </>,
    ],
    faq_list4: [
      `해외에 체류 중인데, 해외 카드 통해 결제 가능한가요?`,
      `I am currently residing abroad. Can I pay via international cards or other methods including via PayPal or Stripe?`,
      'n_content.rwm.faq_list4',
      `"我目前居住在国外，可以使用国外的信用卡进行支付吗？`,
      `"我目前居住在國外，可以使用國外的信用卡進行支付嗎？`,
      `海外に滞在中ですが、海外カードで決済できますか？`,
      `I am currently residing abroad. Can I pay via international cards or other methods including via PayPal or Stripe?`,
    ],
    faq_desc3: [
      <>
        결제는 국내 카드를 통해서만 가능합니다. 해외 카드 통한 결제는 페이지 하단의 [관련 링크]에 있는 해외 결제
        페이지에서 해주세요. <br />
        결제 확인 시, 상세페이지에 안내드린 신청 확인 안내 일정에 맞춰 관련 이메일을 발송해 드립니다. (프로그램 시작일
        전 2~3일 이내) <br />
        *프로그램 등록 후 ‘마이링글’ {`>`} ‘학습한 함께 읽기 프로그램’ 또는 ‘학습한 혼자 읽기 프로그램’에서 신청한
        프로그램을 조회할 수 있습니다. <br />
      </>,
      <>
        Payment can only be processed via domestic cards. If you wish to process your payment via international cards,
        please register through the registration page in the [Related Links] section.
        <br />
        Upon confirmation of your registration, we will send a confirmation email according to the schedule noted in the
        Description page. (2~3 days prior to the program start date) <br />
        *After confirmation and enrollment, you can check your registration status to the program in the [My Ringle]{' '}
        {`>`} [My Read Together] or [My Read Alone] section. <br />
      </>,
      'n_content.rwm.faq_desc3',
      <>
        支付仅可通过韩国信用卡进行。如果您希望通过国际卡进行支付，请通过网站左下角的Channel.io与我们联系，并提供以下信息。
        <br />
        1) 课程标题，2) 姓名，3) Ringle账户电子邮件地址。
        <br />
      </>,
      <>
        支付僅可通過韓國信用卡進行。如果您希望通過國際卡付款，請通過網站左下角的Channel.io與我們聯系，並提供以下資訊。
        <br />
        1) 課程標題，2) 姓名，3) Ringle賬戶電子郵件地址。
        <br />
      </>,
      <>
        お支払い方法
        お支払いは国内カードでのみ可能です。海外決済を希望する場合は、次の情報と一緒にリンクルホームページの左下のチャンネルトークにお問い合わせください。
        お支払い方法を別途ご案内いたします。 <br />
        1) 申請プログラム名、2) 氏名、3) リンクルアカウントのメールアドレス
        <br />
      </>,
      <>
        Payment can only be processed via domestic cards. If you wish to process your payment via international cards,
        please reach to us through the Channel.io at the bottom left corner of the website with the following
        information.
        <br />
        1) Program title, 2) Name, 3) Ringle account email address
        <br />
      </>,
    ],
    faq_list5: [
      `환급용 증빙 서류 제출을 위해 수강증 발급이 필요해요.`,
      `I need a certificate of registration/participation to receive reimbursement from my company.`,
      'n_content.rwm.faq_list5',
      `为了申请学习补助金，我需要申请学习证明文件。`,
      `為了申請學習補助款，我需要申請上課證明。`,
      `返金用の証明書類提出のために受講証の発行が必要です。`,
      `I need a certificate of registration/participation to receive reimbursement from my company.`,
    ],
    faq_desc4: [
      <>
        수강증 발급은 다음 정보와 함께 고객센터 (채널톡)로 문의해 주세요. <br />
        1) 신청 프로그램명, 2) 성함, 3) 링글 계정 이메일 주소, 4) 요청사항 <br />
        문의 확인 후 영업일 기준 2~3일 이내에, 수강증 파일 (pdf)을 이메일로 보내드립니다. <br />
      </>,
      <>
        Please reach to us via through the Help Center with the following information. <br />
        1) Program title, 2) Name, 3) Ringle account email address, 4) Requests
        <br />
        We will send you a copy of the certificate (pdf) via email within 2~3 business days.
      </>,
      'n_content.rwm.faq_desc4',
      <>
        发放学习证明，请通过网站左下角的Channel.io并提供以下讯息。
        <br />
        1) 申请课程名称，2) 姓名，3) Ringle账户电子邮件地址，4) 要求事项。
        <br />
        我们将与您确认后的2-3个工作日内，通过电子邮件发送学习证明文件（pdf）。
        <br />
      </>,
      <>
        發放上課證明，請通過網站左下角的Channel.io並提供以下訊息。
        <br />
        1) 申請課程名稱，2) 姓名，3) Ringle賬戶電子郵件地址，4) 要求事項。
        <br />
        我們將與您確認後的2-3個工作日內，通過電子郵件發送上課證明文件（pdf）。
        <br />
      </>,
      <>
        受講証の発行 受講証の発行は、以下の情報と一緒にホームページ左下のチャンネルトークでお問い合わせください。 <br />
        1) 申請プログラム名、2) 氏名、3) リンクルアカウントのメールアドレス、4) ご要望事項
        <br /> <br /> <br /> <br /> <br />{' '}
        お問い合わせ確認後、2～3営業日以内に受講証ファイル(PDF)をメールでお送りします。
        <br />
      </>,
      <>
        Please reach to us via through the Channel.io at the bottom left corner of the website with the following
        information. <br />
        1) Program title, 2) Name, 3) Ringle account email address, 4) Requests
        <br />
        We will send you a copy of the certificate (pdf) via email within 2~3 business days.
      </>,
    ],
    faq_list6: [
      `신청한 프로그램의 강의 영상과 학습 자료는 언제까지 어떻게 이용할 수 있나요?`,
      `Until when can I access the replays and materials provided by the programs I registered?`,
      'n_content.rwm.faq_list6',
      `我申请的上课影片回放和资料可以使用多久？`,
      `我申請的上課影片回放和資料可以使用多久？`,
      `申請したプログラムの講義映像や学習資料はいつまでどのように利用できますか？`,
      `Until when can I access the replays and materials provided by the programs I registered?`,
    ],
    faq_desc5: [
      <>
        프로그램의 모든 강의 영상과 수업 자료는 신청 월 포함 3개월 동안만 접근 가능합니다. 영상 및 자료는 [마이 링글]{' '}
        {`>`} ‘학습한 함께 읽기 프로그램’ 또는 ‘학습한 혼자 읽기 프로그램’ 페이지에서 확인할 수 있습니다. <br />
        특히 함께 읽기 프로그램에서 진행하는 모든 실시간 강의는 해당 페이지를 통해 실시간 강의 참석 여부 및 신청 시점과
        상관없이 모든 신청자분들께 제공 드립니다. <br />
      </>,
      <>
        All replays and materials are accessible for the duration of 3 months, including the month that the program
        starts. You can find the replays and materials in the [My Ringle] {`>`} [My Read Together] or [My Read Alone]
        section.
        <br />
        All replays and materials from the live lectures from the Read Together Program will be provided to all
        registrants no matter the time of registration or entrance to the live lecture. <br />
      </>,
      'n_content.rwm.faq_desc5',
      <>
        所有的回放和资料在课程开始的当月起，可访问3个月。您可以在【我的Ringle】{`>`}
        【一起阅读】或【独自阅读】找到回放和资料。
        <br />
        所有来自一起阅读课程的直播讲座的回放和资料将提供给所有注册课程的学员，无论注册时间或参与直播课程与否。
        <br />
      </>,
      <>
        所有的重播和資料在課程開始的當月起，可訪問3個月。您可以在【我的Ringle】{`>`}
        【一起閱讀】或【獨自閱讀】找到重播和資料。
        <br />
        所有來自一起閱讀課程的直播課程的重播和資料將提供給所有註冊課程的學員，無論註冊時間或參與直播課程與否。
        <br />
      </>,
      <>
        {' '}
        アクセスについて
        プログラムのすべての講義映像と授業資料は、申請月を含む3ヶ月間のみアクセス可能です。映像及び資料は[マイリング]{' '}
        {`>`} '一緒に読むプログラム'または'一人で読むプログラム'ページで確認することができます。 <br /> <br /> 特に
        特に、一緒に読むプログラムで進行するすべてのリアルタイム講義は、そのページを通じて、リアルタイム講義参加の有無や申請時点に関係なく、すべての申請者に提供されます。
        <br />
      </>,
      <>
        All replays and materials are accessible for the duration of 3 months, including the month that the program
        starts. You can find the replays and materials in the [My Ringle] {`>`} [My Read Together] or [My Read Alone]
        section.
        <br />
        All replays and materials from the live lectures from the Read Together Program will be provided to all
        registrants no matter the time of registration or entrance to the live lecture. <br />
      </>,
    ],
    faq_list7: [
      `함께 읽기 프로그램에 신청했는데 실시간 강의에 참석하는 줌 링크는 어디서 확인할 수 있나요?`,
      `I registered to the Read Together program. Where can I find the link to enter the Zoom meeting for the live lecture?`,
      'n_content.rwm.faq_list7',
      `“我已报名参加一起阅读（Read Together）活动，何时可以收到参加讲座的Zoom链接以及在线社群（Naver BAND或KakaoTalk开放聊天室）的加入信息？”`,
      `“我已報名參加一起閱讀（Read Together）活動，何時可以收到參加講座的Zoom連結以及社群（Naver BAND或KakaoTalk開放聊天室）的加入訊息？”`,
      `一緒に読むプログラムに申し込んだのですが、リアルタイム講義に参加するズームリンクとオンラインコミュニティ(Naver BANDまたはカカオトークのオープンチャット)への参加案内はいつ届きますか？`,
      `I registered to the Read Together program. When can I expect to receive guidelines on entering live lectures on Zoom and online reading communities (Naver BAND or KakaoTalk Open Chat)?`,
    ],
    faq_desc6: [
      <>
        실시간 강의는 강의 시작 15분 전부터 프로그램 상세페이지 우측 상단의 ‘입장하기’ 버튼을 통해 Zoom 강의실로 입장할
        수 있습니다. <br />
      </>,
      <>
        Registrants can enter the Zoom meeting for the live lecture via the ‘Enter’ button on the upper right corner of
        the website 15 minutes prior to the start time.
        <br />
      </>,
      'n_content.rwm.faq_desc6',
      <>
        “实时讲座可以在开始前15分钟通过课程页面右上角的‘入场’按钮进入Zoom教室。
        <br />
        加入在线社群（Naver BAND或KakaoTalk开放聊天室）可以通过我们通知的电子邮件中提供的加入方法完成。
        通知邮件的发送时间请于课程页面确认。
        <br />
      </>,
      <>
        “線上課程可以在開始前15分鐘通過課程頁面右上角的‘入場’按鈕進入Zoom教室。
        <br />
        加入社群（Naver BAND或KakaoTalk聊天室）可以通過我們發送的電子郵件中提供的加入方法完成。
        通知郵件的發送時間請於課程頁面確認。
        <br />
      </>,
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        リアルタイム講義は、講義開始15分前からプログラム詳細ページ右上の'入場する'ボタンからZoom講義室に入場することができます。{' '}
        <br /> <br />
        オンラインコミュニティ(Naver
        BANDまたはカカオトークのオープンチャット)加入は、加入/活用ガイドブックと一緒にお送りする事前案内メールを介して行うことができます。事前案内メールの送信時期は、プログラム詳細ページでご確認ください。{' '}
        <br />
      </>,
      <>
        Registrants can enter the Zoom meeting for the live lecture via the ‘Enter’ button on the upper right corner of
        the website 15 minutes prior to the start time.
        <br />
        In case of the online communities in Read Together programs (Naver BAND or KakaoTalk Open Chat), registrants
        will receive specific guideline through a separate confirmation email. Please refer to the description page to
        find the expected date and time of receiving the confirmation email.
        <br />
      </>,
    ],
    faq_list8: [`FALSE`, ``, 'n_content.rwm.faq_list8', ``, ``, ``, ``],
    faq_desc7: [`FALSE`, ``, 'n_content.rwm.faq_desc7', ``, ``, ``, ``],
    faq_list9: [`FALSE`, ``, 'n_content.rwm.faq_list9', ``, ``, ``, ``],
    faq_desc8: [`FALSE`, ``, 'n_content.rwm.faq_desc8', ``, ``, ``, ``],
    faq_list10: [`FALSE`, ``, 'n_content.rwm.faq_list10', ``, ``, ``, ``],
    faq_desc9: [`FALSE`, ``, 'n_content.rwm.faq_desc9', ``, ``, ``, ``],
    faq_more: [
      `찾는 질문이 없다면?`,
      `Can't find what you want to know?`,
      'n_content.rwm.faq_more',
      `没有你想要问的问题吗？`,
      `沒有你想要問的問題嗎？`,
      `お探しの質問がない場合は？`,
      `Can't find what you want to know?`,
    ],
    note: [
      <>
        실시간 강의는 <b>매주</b> 진행됩니다.
      </>,
      `Live lectures are held every week.`,
      'n_content.rwm.note',
      `每週三进行 LIVE课程`,
      `每週三進行 LIVE課程`,
      <>
        ライブ講義は<b>毎週水曜日</b>に行われます。
      </>,
      `Live lectures are held every Wednesday.`,
    ],
    note_desc5: [
      <>
        실시간 강의가 진행되는 함께 읽기 프로그램의 경우, <b>강의 시작 15분 전부터</b> 프로그램 상세페이지 우측 상단의{' '}
        <b>‘입장하기’ 버튼을 통해 Zoom 강의실로 입장할 수 있습니다.</b>
      </>,
      <>
        In case of Read Together programs that include live lectures, registrants can enter the Zoom meeting{' '}
        <b>via the ‘Enter’ button on the upper right corner of the website 15 minutes prior to the start time.</b>
      </>,
      'n_content.rwm.note_desc5',
      <>
        <b>直播进行的阅读活动</b>从<b>于课程开始前15分钟起</b>，可以通过页面右上角的<b>‘进入’按钮</b>进入Zoom教室。
      </>,
      <>
        <b>直播進行的閱讀活動</b>從<b>於課程開始前15分鐘起</b>，可以通過頁面右上角的<b>‘進入’按鈕</b>進入Zoom教室。
      </>,
      <>
        リアルタイム講義が行われる一緒に読むプログラムの場合、<b>講義開始15分前から</b>プログラム詳細ページ右上の
        <b>'入場する'ボタンからZoom教室に入場することができます</b>
      </>,
      <>
        In case of Read Together programs that include live lectures, registrants can enter the Zoom meeting{' '}
        <b>via the ‘Enter’ button on the upper right corner of the website 15 minutes prior to the start time.</b>
      </>,
    ],
    note_desc6: [
      <>
        프로그램의 모든 강의 영상과 수업 자료는 <b>신청 월 포함 3개월 동안만 접근 가능합니다.</b> 영상 및 자료는 [마이
        링글] {`>`} ‘학습한 함께 읽기 프로그램’ 또는 ‘학습한 혼자 읽기 프로그램’ 페이지에서 확인할 수 있습니다.
      </>,
      <>
        All replays and materials are{' '}
        <b>accessible for the duration of 3 months, including the month that the program starts.</b> You can find the
        replays and materials in the [My Ringle] {`>`} [My Read Together] or [My Read Alone] section.
      </>,
      'n_content.rwm.note_desc6',
      <>
        <b>该项目的所有课程视频和课程资料仅在申请月份起的三个月内可访问。</b> 您可以在[我的Ringle] {`>`}{' '}
        ‘一起阅读’或‘独自阅读’活动页面中查看视频和资料。
      </>,
      <>
        <b>該項目的所有課程影片和課程資料僅在申請月份起的三個月內可查看。</b> 您可以在[我的Ringle] {`>`}{' '}
        ‘一起閱讀’或‘獨自閱讀’活動頁面中查看影片和資料。
      </>,
      <>
        プログラムのすべての講義映像と授業資料は<b>申請月を含む3ヶ月間のみアクセス可能です</b>映像と資料は[マイリング]{' '}
        {`>`} '学習した一緒に読むプログラム'または'学習した一人で読むプログラム'ページで確認することができます。
      </>,
      <>
        All replays and materials are{' '}
        <b>accessible for the duration of 3 months, including the month that the program starts.</b> You can find the
        replays and materials in the [My Ringle] {`>`} [My Read Together] or [My Read Alone] section.
      </>,
    ],
    materials: [
      `프로그램 자료 보기`,
      `See Program Materials`,
      'n_content.rwm.materials',
      `确认活动资料`,
      `確認活動資料`,
      `プログラム資料を見る`,
      `See Program Materials`,
    ],
    recorded: [
      `강의 영상`,
      `Recorded Lectures`,
      'n_content.rwm.recorded',
      `活动录製影片`,
      `活動錄製影片`,
      `講義映像`,
      `Recorded Lectures`,
    ],
    day: (date) => [
      `마감 D-${date}`,
      `Ending Soon D-${date}`,
      'n_content.rwm.day',
      `即将结束 D-${date}`,
      `即將結束 D-${date}`,
      `締切間近 D-${date}`,
      ``,
    ],
  },
  label_available: [`신청 가능`, `Open`, 'n_content.label_available', `Open`, `Open`, `Now open`, `Open`],
  btn: {
    end: [`입장하기`, `Enter live lecture.`, 'n_content.btn.end', `入场`, `入場`, `参加する`, ``],
    enter: [
      `종료되었습니다.`,
      `Program is now closed.`,
      'n_content.btn.enter',
      `已结束`,
      `已結束`,
      `締め切りました。`,
      ``,
    ],
    n_session_upcoming: (session) => [
      `${session}회차 진행 예정`,
      `Session ${session} scheduled`,
      'n_content.btn.n_session_upcoming',
      `预计进行${session}次`,
      `預計進行${session}次`,
      `${session}回目開催予定`,
      ``,
    ],
    enroll_finished: [
      `마감되었습니다.`,
      `Registration is closed.`,
      'n_content.btn.enroll_finished',
      `已结束申请`,
      `已結束申請`,
      `締め切りました。`,
      ``,
    ],
    program_ongoing: [
      `프로그램 진행중`,
      `Program in progress`,
      'n_content.btn.program_ongoing',
      `活动进行中`,
      `活動進行中`,
      `プログラム進行中`,
      `Program in progress`,
    ],
    program_will_going: [
      `프로그램 진행 예정`,
      `Program scheduled`,
      'n_content.btn.program_will_going',
      `预计进行的活动`,
      `預計進行的活動`,
      `プログラム待機中`,
      `Program scheduled`,
    ],
    session_upcoming: [
      `진행 예정`,
      `Session scheduled`,
      'n_content.btn.session_upcoming',
      `预计进行`,
      `預計進行`,
      `目開催予定`,
      ``,
    ],
  },
  sign_in_to_access: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Log in to access all of Ringle's content for free`,
    'n_content.sign_in_to_access',
    `登录后查看更多免费内容`,
    `登入后查看更多免費内容`,
    `ログインをして無料でRingleのコンテンツを体験してみてください。`,
    `Đăng nhập để xem toàn bộ nội dung Ringle miễn phí`,
  ],
  sign_in_to_continue: [
    `로그인하고 이어보기`,
    `Log in to continue`,
    'n_content.sign_in_to_continue',
    `登录后继续观看`,
    `登入后繼續觀看`,
    `ログインをして続けてみる`,
    `Đăng nhập để tiếp tục`,
  ],
  finished_preview: [
    <>
      1분 미리듣기가 완료되었어요.
      <br />
      전체 듣기는 로그인 후 이용할 수 있어요.
    </>,
    <>
      You finished the 1-minute preview.
      <br />
      Full listening is available after logging in.
    </>,
    'n_content.finished_preview',
    <>
      1分钟试听已结束
      <br />
      登录后即可使用完整收听。
    </>,
    <>
      1分鐘試聽以結束
      <br />
      登入後即可使用完整收聽。
    </>,
    <>
      1分のプレビューが完了しました。
      <br />
      ログイン後にフル再生が利用可能です。
    </>,
    `Bạn hoàn thành 1 phút ôn lại.`,
  ],
  webinar: {
    note_desc1: [
      `웨비나 시작 15분 전부터 페이지 우측 상단의 ‘입장하기’ 버튼을 통해 입장할 수 있습니다.`,
      `You can enter via the "Join" button in the top right corner of the page starting 15 minutes before the webinar starts.`,
      'n_content.webinar.note_desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc2: [
      `다시보기는 웨비나 종료 당일 2시간 이내 업로드되며, 업로드 일로부터 7일 동안 보실 수 있습니다.`,
      `The replay will be uploaded within 2 hours of the webinar ending and will be available for viewing for 7 days from the date of upload.`,
      'n_content.webinar.note_desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc3: [
      `결제는 국내 카드와 해외 카드 모두 가능합니다.`,
      `Both domestic and international cards are accepted for payment.`,
      'n_content.webinar.note_desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc4: [
      `결제 시 링글 포인트 사용은 불가합니다.`,
      `Payment cannot be made using Ringle Points.`,
      'n_content.webinar.note_desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc5: [
      `신청 완료 시 링글 가입 시 설정한 이메일로 안내해 드립니다. 이메일 주소 변경은 [계정 관리] > [알림 수신 설정] > [이메일]에서 할 수 있습니다.`,
      `Upon completion of the application, you will be notified via the email address you set when you signed up for Ringle. You can change your email address in [Account Management] > [Notification Settings] > [Email].`,
      'n_content.webinar.note_desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc6: [
      `웨비나 당일 1시간 전 이메일과 문자로 시청 알림을 보내드립니다. 링글 가입 계정 이메일과 전화번호로 보내드리니, 개인 정보를 정확하게 입력해주세요.`,
      `We'll send you a reminder via email and text one hour before the webinar. Make sure to enter your personal information correctly.`,
      'n_content.webinar.note_desc6',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc7: [
      `환불은 웨비나 전일 23:59:59까지 가능하며 웨비나 당일 00:00:00 이후에는 환불이 불가합니다. 환불문의는 링글 고객센터로 문의해 주세요.`,
      `Refunds are available until 23:59:59 the day before the webinar, but not after 00:00:00 on the webinar day. For refund inquiries, please contact Ringle customer center`,
      'n_content.webinar.note_desc7',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const n_point = {
  title: [`포인트`, `Points`, 'n_point.title', `积分`, `點數`, `ポイント`, `Điểm`],
  my_point: [`내 포인트`, `My points`, 'n_point.my_point', `持有积分`, `持有點數`, `マイポイント`, `Điểm của bạn`],
  btn: [
    `포인트 사용하기`,
    `Use my points`,
    'n_point.btn',
    `使用积分`,
    `使用點數`,
    `ポイントを使う`,
    `Sử dụng điểm của tôi`,
  ],
  invite_point: [
    `친구초대 포인트`,
    `Referral points earned`,
    'n_point.invite_point',
    `邀请朋友积分`,
    `邀請朋友點數`,
    `友達紹介ポイント`,
    `Điểm giới thiệu`,
  ],
  expiration_point: [
    `30일 이내 소멸 예정`,
    `Expire within 30 days`,
    'n_point.expiration_point',
    `30日以内到期的积分`,
    `30天內將到期的點數`,
    `30日以内に消滅予定`,
    `Điểm sẽ hết hạn trong 30 ngày tới`,
  ],
  filter_all: [`전체`, `All`, 'n_point.filter_all', `全部`, `全部`, `全体`, `Tất cả`],
  filter_earn: [`적립`, `Earned`, 'n_point.filter_earn', `累积`, `累積`, `獲得`, `Earned`],
  filter_expire: [
    `사용/ 소멸`,
    `Used/expired`,
    'n_point.filter_expire',
    `已使用/过期`,
    `已使用/過期`,
    `使用/消滅`,
    `Used/expired`,
  ],
  tabel_date: [`적용일`, `Date`, 'n_point.tabel_date', `日期`, `日期`, `適用日`, `Date`],
  tabel_situation: [`상태`, `Status`, 'n_point.tabel_situation', `状态`, `狀態`, `状態`, `Status`],
  tabel_point: [`포인트`, `Points`, 'n_point.tabel_point', `积分`, `點數`, `ポイント`, `Points`],
  tabel_detail: [`적용 내용`, `Details`, 'n_point.tabel_detail', `详细内容`, `詳細內容`, `適用内容`, `Details`],
  until: [`유효기간`, `Valid until`, 'n_point.until', `有效期限`, `有效期限`, `有効期限`, `Valid until`],
  activity_title: [
    `다양한 활동을 통해 포인트를 받을 수 있어요.`,
    `You can earn points in many ways.`,
    'n_point.activity_title',
    `您可以用许多方式获得积分`,
    `您可以用許多方式獲得點數`,
    `様々な活動を通じてポイントを獲得することができます。`,
    `You can earn points in many ways.`,
  ],
  activity_1: [
    <>
      친구초대하고
      <br />
      포인트 받기
    </>,
    `Invite friends`,
    'n_point.activity_1',
    <>
      邀请朋友
      <br />
      获得积分
    </>,
    <>
      邀請朋友
      <br />
      獲得點數
    </>,
    <>
      友達紹介して
      <br />
      ポイントゲット
    </>,
    <>
      Invite friends
      <br />
      and earn points
    </>,
  ],
  activity_2: [
    <>
      수업듣고
      <br />
      포인트 받기
    </>,
    `Take lessons`,
    'n_point.activity_2',
    <>
      上课
      <br />
      获得积分
    </>,
    <>
      上課
      <br />
      獲得點數
    </>,
    <>
      授業を受けて
      <br />
      ポイントをもらう
    </>,
    <>
      Take lessons
      <br />
      and earn points
    </>,
  ],
  activity_3: [
    <>
      챌린지 참여하고
      <br />
      포인트 받기
    </>,
    `Join challenges`,
    'n_point.activity_3',
    <>
      参与挑战
      <br />
      获得积分
    </>,
    <>
      參與挑戰
      <br />
      獲得點數
    </>,
    <>
      チャレンジに参加して
      <br />
      ポイントをゲット
    </>,
    <>
      Join challenges
      <br />
      and earn points
    </>,
  ],
  popup_info: [
    `포인트는 수업권 구매 및 수강기간 연장 등에 사용 가능하며, 종류에 따라 사용조건 및 유효기간 등이 다를 수 있습니다.`,
    `Points can be used toward purchasing or extending credits and may have different conditions and expiry dates depending on their type.`,
    'n_point.popup_info',
    `积分可用于购买课程券以及延长课程有效期等，其使用条件和有效期视其种类而定。`,
    `點數可用於購買課程券以及延長課程有效期等，其使用條件和有效期視其種類而定。`,
    `ポイントはレッスンチケットの購入や受講期間の延長などに使用可能で、種類によって使用条件や有効期間などが異なる場合があります。`,
    `Points can be used toward purchasing or extending credits and may have different conditions and expiry dates depending on their type.`,
  ],
  popup_title1: [`수업`, `Take lessons`, 'n_point.popup_title1', `上课`, `上課`, `授業`, `Take lessons`],
  popup_desc1: [
    <>
      <li className="li-tag-inside">유료 수업 완료 7일 후 실결제 금액의 3%가 자동 적립</li>
    </>,
    <>
      <li className="li-tag-inside">3% of purchased price rewarded as points 7 days after completing a paid lesson</li>
    </>,
    'n_point.popup_desc1',
    <>
      <li className="li-tag-inside">在完成付费课程后7天内，将自动累积相当于支付金额的3%的积分。</li>
    </>,
    <>
      <li className="li-tag-inside">在完成付費課程後7天內，將自動累積相當於支付金額的3%的點數。</li>
    </>,
    <>
      <li className="li-tag-inside">有料授業完了後7日後に実결제金額の3%が自動加算されます</li>
    </>,
    <>
      <li className="li-tag-inside">3% of purchased price rewarded as points 7 days after completing a paid lesson</li>
    </>,
  ],
  popup_title2: [
    `친구초대`,
    `Invite friends`,
    'n_point.popup_title2',
    `邀请朋友`,
    `邀請朋友`,
    `友達紹介`,
    `Invite friends`,
  ],
  popup_desc2: [
    <>
      <li className="li-tag-inside">친구가 실결제 30만원 이상 수업권 구매 후, 첫 수업을 완료하는 시점에 적립</li>
      <li className="li-tag-inside">친구초대 페이지에서 링글 수업권, 신세계 상품권, 스타벅스 쿠폰으로 교환 가능</li>
    </>,
    <>
      <li className="li-tag-inside">
        Points rewarded when the friend purchases credits above $00 and completes the first lesson
      </li>
      <li className="li-tag-inside">Points can be exchanged with credits or Shinsegae/Starbucks gift cards</li>
    </>,
    'n_point.popup_desc2',
    <>
      <li className="li-tag-inside">在朋友完成实际支付30万韩元以上的课程后，并在第一次课程完成时，将获得积分。</li>
      <li className="li-tag-inside">可以在好友邀请页面上将积分兑换成Ringle课程券、新世界礼品券或星巴克优惠券。</li>
    </>,
    <>
      <li className="li-tag-inside">在朋友完成實際支付30萬韓元以上的課程後，並在第一次課程完成時，將獲得點數。</li>
      <li className="li-tag-inside">您可以在好友邀請頁面上將點數兌換成Ringle課程券、新世界禮品券或星巴克優惠券。</li>
    </>,
    <>
      <li className="li-tag-inside">
        友達が30万ウォン以上のレッスンチケットを購入した後、最初の授業を完了した時点で獲得できます
      </li>
      <li className="li-tag-inside">
        友達紹介ページでRingleレッスンチケット、新世界商品券、スターバックスクーポンと交換可能
      </li>
    </>,
    <>
      <li className="li-tag-inside">
        Points rewarded when the friend purchases credits above $00 and completes the first lesson
      </li>
      <li className="li-tag-inside">Points can be exchanged with credits or Shinsaegye/Starbucks gift cards</li>
    </>,
  ],
  popup_title3: [
    `이벤트/ 기타`,
    `Events/others`,
    'n_point.popup_title3',
    `活动/其他`,
    `活動/其他`,
    `イベント他`,
    `Events/others`,
  ],
  popup_desc3: [
    <>
      <li className="li-tag-inside">그 외 이벤트 등의 적립</li>
    </>,
    <>
      <li className="li-tag-inside">Points can also be earned through events, etc.</li>
    </>,
    'n_point.popup_desc3',
    <>
      <li className="li-tag-inside">可以通过其他活动获得积分</li>
    </>,
    <>
      <li className="li-tag-inside">可以透過其他活動獲得點數。</li>
    </>,
    <>
      <li className="li-tag-inside">その他、特定のイベントなどでも獲得できます</li>
    </>,
    <>
      <li className="li-tag-inside">Points can also be earned through events, etc.</li>
    </>,
  ],
  no_list: [
    `포인트 내역이 없어요`,
    `There is no point history`,
    'n_point.no_list',
    `没有积分历史资料`,
    `沒有點數歷史資料`,
    `ポイント履歴がありません`,
    `There is no point history`,
  ],
  status_earn: [`적립`, `Earned`, 'n_point.status_earn', `获得`, `獲得`, `獲得`, ``],
  status_use: [`사용`, `Used`, 'n_point.status_use', `使用`, `使用`, `使用済`, ``],
  status_expire: [`소멸`, `Expired`, 'n_point.status_expire', `过期`, `過期`, `有功期限切れ`, ``],
  activity_title_mobile: [
    <>
      다양한 활동을 통해
      <br />
      포인트를 받을 수 있어요.
    </>,
    `You can earn points in many ways.`,
    'n_point.activity_title_mobile',
    <>
      透过各种活动
      <br />
      获得积分
    </>,
    <>
      透過各種活動
      <br />
      獲得點數
    </>,
    <>
      様々な方法で
      <br />
      ポイントを獲得できます。
    </>,
    ``,
  ],
}
export const n_material = {
  tab_insight: [`인사이트`, `Insight`, 'n_material.tab_insight', `文章`, `文章`, `インサイト`, `Insight`],
  tab_question: [
    `교재 질문`,
    `Questions`,
    'n_material.tab_question',
    `课程问题`,
    `課程問題`,
    `教材に関する質問`,
    `Câu hỏi`,
  ],
  tab_clip: [
    `영상/ 기사`,
    `Video Clip/ Article`,
    'n_material.tab_clip',
    `视频/ 新闻报道`,
    `影片／報導`,
    `動画/記事`,
    `Video/ Bài báo`,
  ],
  tutor: [
    `최근 이 교재로 수업한 튜터`,
    `Tutors who used this material`,
    'n_material.tutor',
    `最近用此教材授课过的教师`,
    `最近用此教材授課過的教师`,
    `この教材で最近授業した講師`,
    `Tutors who used this material`,
  ],
  related: [
    `관련 교재`,
    `Related Materials`,
    'n_material.related',
    `相关教材`,
    `相關教材`,
    `関連教材`,
    `Tài liệu liên quan`,
  ],
  toast_book_mark: [
    `찜한 교재에 추가했어요.`,
    `Added to Favorite Materials.`,
    'n_material.toast_book_mark',
    `收藏至我喜爱的教材`,
    `收藏至我喜愛的教材`,
    `お気に入り教材に追加しました。`,
    `Added to [MyRingle > My Materials]`,
  ],
  setting_view: [
    `한국어 같이 보기`,
    `Include Korean`,
    'n_material.setting_view',
    `Include Korean`,
    `Include Korean`,
    `ハングルのように見る`,
    `Include Korean`,
  ],
  setting_size: [`글자크기`, `Font size`, 'n_material.setting_size', `字体大小`, `字體大小`, `文字サイズ`, `Font size`],
  smsetting_sm: [`작게`, `Small`, 'n_material.smsetting_sm', `小`, `小`, `小さく`, `Small`],
  setting_md: [`기본`, `Default`, 'n_material.setting_md', `预设`, `預設`, `基本`, `Default`],
  setting_lg: [`크게`, `Large`, 'n_material.setting_lg', `大`, `大`, `大きく`, `Large`],
  expressions_title: [
    `핵심 표현`,
    `Expressions`,
    'n_material.expressions_title',
    `核心生詞`,
    `核心單字`,
    `キーフレーズ`,
    `Mẫu câu`,
  ],
  view_tooltip: [
    <>
      “한글 같이 보기”와 “글자 크기”를
      <br />
      설정할 수 있어요!
    </>,
    `You can use the “Include Korean” and the “Font size”`,
    'n_material.view_tooltip',
    <>“您可选择一起显示韩文以及文字大小</>,
    <>“您可選擇一起顯示韓文以及文字大小</>,
    <>
      “韓国語を表示”と “文字サイズ”を
      <br />
      設定できます!
    </>,
    ``,
  ],
  select: [`교재 선택`, `Select`, 'n_material.select', `选择教材`, `選擇教材`, `教材の選択`, ``],
  toast_delete_book_mark: [
    `찜한 교재에서 삭제했어요.`,
    `Removed from Favorite Materials.`,
    'n_material.toast_delete_book_mark',
    `从我收藏的教师移除`,
    `從我收藏的教師移除`,
    `お気に入り教材から削除しました。`,
    ``,
  ],
  font_size: [`글자 크기`, `Font Size`, 'n_material.font_size', `字体大小`, `字體大小`, `文字サイズ`, ``],
  font_size_small: [`작게`, `Small`, 'n_material.font_size_small', `小`, `小`, `小さく`, ``],
  font_size_normal: [`기본`, `Normal`, 'n_material.font_size_normal', `普通`, `普通`, `ノーマル`, ``],
  font_size_big: [`크게`, `Big`, 'n_material.font_size_big', `大`, `大`, `大きく`, ``],
  search_result: (keywords, resultCnt) => [
    `${keywords}'에 대한 ${resultCnt}개의 검색 결과`,
    `${resultCnt} search result about '${keywords}`,
    'n_material.search_result',
    `${keywords}'有 ${resultCnt}个搜寻结果`,
    `${keywords}'有 ${resultCnt}個搜尋結果`,
    `${keywords}'に関する ${resultCnt}件の検索結果`,
    ``,
  ],
  mp3_download: [
    `MP3 다운받기`,
    `Download MP3`,
    'n_material.mp3_download',
    `下载MP3`,
    `下載MP3`,
    `MP3ダウンロード`,
    ``,
  ],
  pdf_download: [
    `PDF 다운받기`,
    `Download PDF`,
    'n_material.pdf_download',
    `下载PDF`,
    `下載PDF`,
    `PDFダウンロード`,
    ``,
  ],
  stop: [`듣기 종료`, `Stop Listening`, 'n_material.stop', `停止聆听`, `停止聆聽`, `試聴終了`, ``],
  title: [`교재`, `Material`, 'n_material.title', `教材`, `教材`, `教材`, ``],
}
export const n_lesson = {
  tab_upcoming: (n) => [
    `예정된 수업 (${n})`,
    `Upcoming Lesson`,
    'n_lesson.tab_upcoming',
    `已预约的课程`,
    `已預約的課程`,
    `予定されたレッスン`,
    `Các buổi học theo lịch trình`,
  ],
  tab_preview: [
    `지난 수업`,
    `Past Lessons`,
    'n_lesson.tab_preview',
    `已完成的课程`,
    `已完成的課程`,
    `前回のレッスン`,
    ``,
  ],
  title: [`수업`, `Lessons`, 'n_lesson.title', `课程`, `課程`, `レッスン`, ``],
  popup_class_info: {
    title: [`수업 정보`, `Lesson Info`, 'n_lesson.popup_class_info.title', `课程资讯`, `課程資訊`, `レッスン情報`, ``],
  },
  popup_class_enter: {
    title: [
      `수업 입장`,
      `Enter Lesson`,
      'n_lesson.popup_class_enter.title',
      `进入课程`,
      `進入課程`,
      `レッスンに参加`,
      ``,
    ],
  },
  popup_class: {
    record: [`수업 녹음`, `Record`, 'n_lesson.popup_class.record', `课程录音`, `課程錄音`, `レッスンの録音`, ``],
    analysis: [`AI 분석`, `AI Analysis`, 'n_lesson.popup_class.analysis', `AI 分析`, `AI 分析`, `AI Analysis`, ``],
    intro: [`자기 소개`, `Self-introductions`, 'n_lesson.popup_class.intro', `自我介绍`, `自我介紹`, `自己紹介`, ``],
    mode: [`수업 모드`, `Lesson Mode`, 'n_lesson.popup_class.mode', `课程模式`, `課程模式`, `レッスンモード`, ``],
    intensive_focus_title: [
      `집중 교정`,
      `Focus area`,
      'n_lesson.popup_class.intensive_focus_title',
      `学习领域`,
      `學習領域`,
      `重点添削`,
      ``,
    ],
    additional_request: [
      `상세 요청`,
      `Requests`,
      'n_lesson.popup_class.additional_request',
      `要求事项`,
      `要求事項`,
      `詳細リクエスト`,
      ``,
    ],
    other_request: [
      `추가 요청`,
      `Additional
requests`,
      'n_lesson.popup_class.other_request',
      `额外要求事项`,
      `額外要求事項`,
      `追加リクエスト`,
      ``,
    ],
    enter_info: [
      `수업입장이 안될 경우, 앱을 완전히 종료 후 다시 시도해주세요.`,
      `If you have trouble entering the lesson, please close the app completely then try again.`,
      'n_lesson.popup_class.enter_info',
      `如果无法进入课程，请将APP关闭重新打开`,
      `如果無法進入課程，請將APP關閉重新打開`,
      `レッスンに入れない場合は、アプリを完全に終了してから再試行してください。`,
      ``,
    ],
    btn_lesson_style: [
      `수업방식 설정`,
      `Set lesson style`,
      'n_lesson.popup_class.btn_lesson_style',
      `设定课程模式`,
      `設定課程模式`,
      `レッスン方式の設定`,
      ``,
    ],
    btn_enter_lesson: [
      `수업 입장`,
      `Enter Lesson`,
      'n_lesson.popup_class.btn_enter_lesson',
      `进入课程`,
      `進入課程`,
      `レッスンに参加`,
      ``,
    ],
    record_yes: [`녹음 하기`, `On`, 'n_lesson.popup_class.record_yes', `录音`, `錄音`, `録音する`, ``],
    record_no: [`녹음하지 않기`, `Off`, 'n_lesson.popup_class.record_no', `不录音`, `不錄音`, `録音しない`, ``],
    record_info1: [
      `수업 녹음 파일은 수업 종료 후 [지난 수업] 메뉴에서 확인할 수 있습니다. (파일 제작은 최대 24시간이 소요됩니다.)`,
      `The audio recording of the lesson will become available under the [Past Lessons] menu after the lesson. (It may take up to 24 hours to process the audio file.)`,
      'n_lesson.popup_class.record_info1',
      `课程录音可以在课后于 [已完成的课程] 中确认。(录音档案会于24小时后提供)`,
      `課程錄音可以在課後於 [已完成的課程] 中確認。(錄音檔案會於24小時後提供)`,
      `レッスンの録音ファイルは、レッスン終了後に[過去のレッスン]メニューから確認することができます。 (ファイルの作成には最大24時間かかります。)`,
      ``,
    ],
    record_info2: [
      `수업 녹음 설정은 수업 시작 15분 전까지만 변경 가능합니다.`,
      `Changes must be made by 15 mins before the lesson to be applied successfully.`,
      'n_lesson.popup_class.record_info2',
      `课程录音与否可于课程开始前15分钟进行变更。`,
      `課程錄音與否可於課程開始前15分鐘進行變更。`,
      `レッスンの録音設定は、レッスン開始15分前までしか変更できません。`,
      ``,
    ],
    record_info3: [
      `복습을 위한 녹음 파일과 대화 스크립트 제공을 위해 수업이 녹음됩니다.`,
      `Lessons will be recorded to provide audio recordings and transcripts for reviewing the lesson.`,
      'n_lesson.popup_class.record_info3',
      `课程录音的目的在于提供课后複习时的录音档案和文字档案。`,
      `課程錄音的目的在於提供課後複習時的錄音檔案和文字檔案。`,
      `復習のための録音ファイルと会話スクリプトを提供するために、レッスンは録音されます。`,
      ``,
    ],
    analysis_yes: [`진단 받기`, `On`, 'n_lesson.popup_class.analysis_yes', `进行分析`, `進行分析`, `診断を受ける`, ``],
    analysis_no: [
      `진단 받지 않기`,
      `Off`,
      'n_lesson.popup_class.analysis_no',
      `不进行分析`,
      `不進行分析`,
      `診断を受けない`,
      ``,
    ],
    analysis_info: [
      `AI 분석이란?`,
      `What is AI analysis?`,
      'n_lesson.popup_class.analysis_info',
      `什麽是AI分析？`,
      `什麼是AI分析？`,
      `AI Analysisとは？`,
      ``,
    ],
    intro_no: [
      `소개 없이 바로  시작`,
      `Skip`,
      'n_lesson.popup_class.intro_no',
      `不进行自我介绍`,
      `不進行自我介紹`,
      `自己紹介なしですぐにスタート`,
      ``,
    ],
    intro_yes: [
      `서로 소개 3분 후 시작`,
      `Exchange for 3 mins`,
      'n_lesson.popup_class.intro_yes',
      `进行自我介绍3分钟`,
      `進行自我介紹3分鐘`,
      `お互いの自己紹介（3分）後に開始`,
      ``,
    ],
    mode_correction: [
      `교정 중심`,
      `Writing-focused`,
      'n_lesson.popup_class.mode_correction',
      `以写作为主`,
      `以寫作為主`,
      `添削中心`,
      ``,
    ],
    mode_correction_instant: [
      `답변 후 즉시 교정 받기`,
      `Instant paraphrasing`,
      'n_lesson.popup_class.mode_correction_instant',
      `即时纠正`,
      `即時糾正`,
      `回答後、すぐに添削を受ける`,
      ``,
    ],
    mode_correction_instant_info: [
      `틀린 영어 표현을 할 때마다 교정 받아요.`,
      `Correct my English as soon as I make a mistake.`,
      'n_lesson.popup_class.mode_correction_instant_info',
      `在我犯错时，教师即时纠正`,
      `在我犯錯時，教師即時糾正`,
      `間違えた都度、添削してもらいます。`,
      ``,
    ],
    mode_correction_intermittent: [
      `질문에 대한 대화 후 교정 받기`,
      `Intermittent paraphrasing`,
      'n_lesson.popup_class.mode_correction_intermittent',
      `段落纠正`,
      `段落糾正`,
      `質問に対する会話の後、添削を受ける。`,
      ``,
    ],
    mode_correction_intermittent_info: [
      `각 질문에 대한 대화가 끝난 후 교정 받아요.`,
      `Correct my English after we finish talking about a question.`,
      'n_lesson.popup_class.mode_correction_intermittent_info',
      `当课程进行一个段落时，进行纠正`,
      `當課程進行一個段落時，進行糾正`,
      `各質問の会話が終わった後、添削してもらいます。`,
      ``,
    ],
    mode_discussion: [
      `토론 중심`,
      `Speaking-focused`,
      'n_lesson.popup_class.mode_discussion',
      `以口说为主`,
      `以口說為主`,
      `ディスカッション中心`,
      ``,
    ],
    mode_discussion_mode55: [
      `5:5 토론하기`,
      `Talk equally (5:5)`,
      'n_lesson.popup_class.mode_discussion_mode55',
      `发言比例(5:5)`,
      `發言比例(5:5)`,
      `5:5ディスカッション`,
      ``,
    ],
    mode_discussion_mode55_info: [
      `튜터와 내가 절반씩 이야기를 주고 받아요.`,
      `I want to speak as well as hear what my tutor has to say.`,
      'n_lesson.popup_class.mode_discussion_mode55_info',
      `老师和学生各发言一半的时间`,
      `老師和學生各發言一半的時間`,
      `チューターとあなたが半分ずつ話をします。`,
      ``,
    ],
    mode_discussion_mode82: [
      `8:2 토론하기`,
      `Talk more (8:2)`,
      'n_lesson.popup_class.mode_discussion_mode82',
      `发言比例(8:2)`,
      `發言比例(8:2)`,
      `8:2 ディスカッションをする`,
      ``,
    ],
    mode_discussion_mode82_info: [
      `튜터는 듣고 내가 주로 이야기해요.`,
      `I want to speak as much as possible.`,
      'n_lesson.popup_class.mode_discussion_mode82_info',
      `让学生多发言`,
      `讓學生多發言`,
      `あなたが主に話します。`,
      ``,
    ],
    mode_info1: [
      `체험 수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규 수업에서는 수업모드를 교정 중심과 토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.`,
      `For trial lessons, the tutor will provide intermittent paraphrasing after you talk about a question. For regular lessons, you can choose between writing-focused and speaking-focused lessons and select detailed lesson styles.`,
      'n_lesson.popup_class.mode_info1',
      `体验课採用段落纠正的方式进行。在正式课程中，您可以选择写作为主或口说为主的课程方式，并设定想要的课程模式。`,
      `體驗課採用段落糾正的方式進行。在正式課程中，您可以選擇寫作為主或口說為主的課程方式，並設定想要的課程模式。`,
      `体験レッスンは、各質問に対する会話が終わった後に添削を受ける方式で行います。通常レッスンでは、添削中心かディスカッション中心のいずれかを選択することができます。`,
      ``,
    ],
    intensive_focus_choice1: [
      `Fluency`,
      `Fluency`,
      'n_lesson.popup_class.intensive_focus_choice1',
      `流畅度`,
      `流暢度`,
      `流暢さ`,
      ``,
    ],
    intensive_focus_choice2: [
      `Vocabulary`,
      `Vocabulary`,
      'n_lesson.popup_class.intensive_focus_choice2',
      `生词`,
      `單字`,
      `語彙`,
      ``,
    ],
    intensive_focus_choice3: [
      `Grammar`,
      `Grammar`,
      'n_lesson.popup_class.intensive_focus_choice3',
      `文法`,
      `文法`,
      `文法`,
      ``,
    ],
    intensive_focus_choice4: [
      `Pronunciation`,
      `Pronunciation`,
      'n_lesson.popup_class.intensive_focus_choice4',
      `发音`,
      `發音`,
      `発音`,
      ``,
    ],
    additional_request_choice1: [
      `제 답변에서 어색한 부분을 상황에 맞는 단어와 문장으로 교정해주세요.`,
      `Please correct and rephrase any awkward words and sentences.`,
      'n_lesson.popup_class.additional_request_choice1',
      `请将我的英语修改成接近母语人士的用法。`,
      `請將我的英文修改成接近母語人士的用法。`,
      `私の回答のぎこちない部分を、状況に合った単語や文章に修正してください。`,
      ``,
    ],
    additional_request_choice2: [
      `문법 실수는 수업이 끝나고 수업노트(Ringle Docs)에 따로 적어주세요.`,
      `Please write up my grammar mistakes in the lesson notes (Ringle Docs) separately after the lesson.`,
      'n_lesson.popup_class.additional_request_choice2',
      `请勿另外说明错误的文法，请帮我写在课堂笔记。`,
      `請勿另外說明錯誤的文法，請幫我寫在課堂筆記。`,
      `文法の間違いは、レッスン終了後、レッスンノート(Ringle Docs)に書き込んでください。`,
      ``,
    ],
    additional_request_choice3: [
      `답변과 이어지는 꼬리 질문을 1-2개 더 해주세요.`,
      `Please ask 1-2 follow-up questions after I finish talking about a question.`,
      'n_lesson.popup_class.additional_request_choice3',
      `请于我回答结束后，额外询问我1～2个问题。`,
      `請於我回答結束後，額外詢問我1～2個問題。`,
      `ディスカッション後、1-2回フォローアップの質問をしてください。`,
      ``,
    ],
    additional_request_choice4: [
      `일상 대화체보다 Formal English로 교정받고 싶어요.`,
      `Please correct me in formal English rather than everyday English.`,
      'n_lesson.popup_class.additional_request_choice4',
      `请协助将我的英文纠正成正式的英语用法。`,
      `請協助將我的英文糾正成正式的英文用法。`,
      `日常英語ではなく、フォーマルな英語で添削してください。`,
      ``,
    ],
    additional_request_choice5: [
      `영어 초급자예요. 수업을 조금 천천히 진행해 주세요.`,
      `Please go slowly during the lesson since I am a beginner.`,
      'n_lesson.popup_class.additional_request_choice5',
      `我是英语初级学习者，请放慢进行速度。`,
      `我是英文初級學習者，請放慢進行速度。`,
      `英語初心者なので、少しゆっくり授業を進めてください。`,
      ``,
    ],
    info_empty: [`-`, ``, 'n_lesson.popup_class.info_empty', ``, ``, `-`, ``],
  },
  msg_material_auto2: [
    `* 교재가 선택되지 않아 자동으로 선택되었습니다.`,
    `Automatically chosen as no material was selected.`,
    'n_lesson.msg_material_auto2',
    `*因为未选择教材，系统已自动选择`,
    `*因為未選擇教材，系統已自動選擇`,
    `* 教材が選択されていないため、自動的に選択されました。`,
    ``,
  ],
  msg_material_auto3: [
    `교재가 선택되지 않았습니다.`,
    `Lesson material is not selected yet.`,
    'n_lesson.msg_material_auto3',
    `尚未选择教材`,
    `尚未選擇教材`,
    `教材が選択されていません。`,
    ``,
  ],
  trial: {
    remain_time: (time) => [
      <>{time} 후 사라져요.</>,
      <>Offer ends in {time}.</>,
      'n_lesson.trial.remain_time',
      <>Offer ends in {time}.</>,
      <>Offer ends in {time}.</>,
      <>Offer ends in {time}.</>,
      ``,
    ],
    title: [
      `4,000원에 1:1 수업 체험하기`,
      `Try a 1:1 lesson for $3.46`,
      'n_lesson.trial.title',
      `Try a 1:1 lesson for $3.46`,
      `Try a 1:1 lesson for $3.46`,
      `Try a 1:1 lesson for $3.46`,
      ``,
    ],
  },
  common: {
    tutor: [`튜터`, `Tutor`, 'n_lesson.common.tutor', `Tutor`, `Tutor`, `Tutor`, ``],
    material: [`교재`, `Material`, 'n_lesson.common.material', `Material`, `Material`, `Material`, ``],
  },
  title1: [`1:1 수업`, `1:1 Lessons`, 'n_lesson.title1', `1:1 课程`, `1:1 課程`, `1:1 レッスン`, ``],
}
export const n_upcoming_lessons = {
  btn_lesson_style: [
    `수업 방식 설정`,
    `Select Lesson Style`,
    'n_upcoming_lessons.btn_lesson_style',
    `选择上课方式`,
    `選擇上課方式`,
    `レッスン方式の設定`,
    ``,
  ],
  btn_lesson_preparation: [
    `수업 준비`,
    `Prep for lesson`,
    'n_upcoming_lessons.btn_lesson_preparation',
    `准备课程`,
    `準備課程`,
    `レッスンの準備`,
    ``,
  ],
  btn_enter_lesson: [
    `수업 입장`,
    `Enter Lesson`,
    'n_upcoming_lessons.btn_enter_lesson',
    `进入课程`,
    `進入課程`,
    `レッスンに参加`,
    ``,
  ],
  prep_insight: [
    `인사이트 읽기`,
    `Read the insight`,
    'n_upcoming_lessons.prep_insight',
    `阅读文章`,
    `閱讀文章`,
    `インサイトを読む`,
    ``,
  ],
  prep_completed_insight: [
    `인사이트 읽기 완료`,
    `Done reading Insight`,
    'n_upcoming_lessons.prep_completed_insight',
    `阅读文章完毕`,
    `閱讀文章完畢`,
    `インサイトを読む完了`,
    ``,
  ],
  prep_question: (n) => [
    `질문 ${n}개 선택`,
    `Select ${n} questions`,
    'n_upcoming_lessons.prep_question',
    `选择 ${n}个问题`,
    `選擇 ${n}個問題`,
    `質問 ${n}個選択`,
    ``,
  ],
  prep_completed_question: (n) => [
    `질문 ${n}개 선택 완료`,
    `Select ${n} questions - Done`,
    'n_upcoming_lessons.prep_completed_question',
    `选择 ${n}个问题完成`,
    `選擇 ${n}個問題完成`,
    `質問${n}個選択完了`,
    ``,
  ],
  msg_automatch: [
    `* 튜터 취소로 다른 튜터가 매칭 되었습니다.`,
    `* This tutor has been auto-matched due to tutor cancellation.`,
    'n_upcoming_lessons.msg_automatch',
    `因教师取消课程，已为您重新配对其他教师。`,
    `因教師取消課程，已為您重新配對其他教師。`,
    `* チューターのキャンセルによって他のチューターがマッチングされました。`,
    ``,
  ],
  msg_material_auto: [
    `* 수업 시작 1시간 전까지 선택되지 않으면 자동으로 교재가 선택됩니다.`,
    `* If no material is chosen by 1 hour before the lesson, a material will be auto-selected.`,
    'n_upcoming_lessons.msg_material_auto',
    `* 课程开始前1小时未选择教材，系统将自动为您选择。`,
    `* 課程開始前1小時未選擇教材，系統將自動為您選擇。`,
    `* 授業開始1時間前までに選択されていない場合、自動的に教材が選択されます。`,
    ``,
  ],
  btn_lesson_insight: [
    `교재 선택`,
    `Select material`,
    'n_upcoming_lessons.btn_lesson_insight',
    `选择教材`,
    `選擇教材`,
    `教材の選択`,
    ``,
  ],
  btn_view_schedule: [
    `튜터의 다른 시간 예약`,
    `View tutor's availability`,
    'n_upcoming_lessons.btn_view_schedule',
    `预约教师的其他时间`,
    `預約教師的其他時間`,
    `チューターの他の時間帯を予約する`,
    ``,
  ],
  btn_book_another_class: [
    `다른 수업 예약`,
    `Book lesson`,
    'n_upcoming_lessons.btn_book_another_class',
    `预约其他课程`,
    `預約其他課程`,
    `他のレッスンを予約`,
    ``,
  ],
  prep_upload: [
    `관련 자료 업로드`,
    `Upload material`,
    'n_upcoming_lessons.prep_upload',
    `上传文件`,
    `上傳文件`,
    `関連資料のアップロード`,
    ``,
  ],
  prep_completedupload: [
    `관련 자료 업로드 완료`,
    `Upload material - Done`,
    'n_upcoming_lessons.prep_completedupload',
    `上传文件 - 完成`,
    `上傳文件 - 完成`,
    `関連資料のアップロード完了`,
    ``,
  ],
  msg_writing: [
    `* 라이팅 중심 수업은 AI 분석과 튜터 피드백 리포트가 제공되지 않습니다.`,
    `* AI analysis and tutor's feedback report are not provided for writing-focused lessons.`,
    'n_upcoming_lessons.msg_writing',
    `* 以写作为中心的课程将不提供AI分析报告及教师回馈。`,
    `* 以寫作為中心的課程將不提供AI分析報告及教師回饋。`,
    `* ライティング中心のレッスンでは、AI Analysisとチューターのフィードバックレポートは提供されません。`,
    ``,
  ],
  btn_prep_upload: [
    `관련 자료 업로드`,
    `Upload material`,
    'n_upcoming_lessons.btn_prep_upload',
    `上传文件`,
    `上傳文件`,
    `関連資料のアップロード`,
    ``,
  ],
  menu_option1: [
    `튜터 매칭 옵션 변경`,
    `Change tutor matching option`,
    'n_upcoming_lessons.menu_option1',
    `更改教师配对选项`,
    `更改教師配對選項`,
    `チューターマッチングオプションの変更`,
    ``,
  ],
  menu_option2: [
    `수업 시간 변경`,
    `Reschedule Lesson`,
    'n_upcoming_lessons.menu_option2',
    `更改上课时间`,
    `更改上課時間`,
    `レッスン時間の変更`,
    ``,
  ],
  menu_option3: [
    `교재 선택`,
    `Select material`,
    'n_upcoming_lessons.menu_option3',
    `选择教材`,
    `選擇教材`,
    `教材の選択`,
    ``,
  ],
  menu_option3_2: [
    `교재 변경`,
    `Change Materials`,
    'n_upcoming_lessons.menu_option3_2',
    `更改教材`,
    `更改教材`,
    `教材の変更`,
    ``,
  ],
  menu_option4: [
    `수업 방식 설정`,
    `Select Lesson Style`,
    'n_upcoming_lessons.menu_option4',
    `设定课程模式`,
    `設定課程模式`,
    `レッスン方式の設定`,
    ``,
  ],
  menu_option5: [
    `수업 취소`,
    `Cancel Lesson`,
    'n_upcoming_lessons.menu_option5',
    `取消课程`,
    `取消課程`,
    `レッスンをキャンセル`,
    ``,
  ],
  blank_title: [
    `예정된 수업이 없어요.`,
    `You have no upcoming lesson.`,
    'n_upcoming_lessons.blank_title',
    `您没有预约的课程`,
    `您沒有預約的課程`,
    `予定されているレッスンはありません。`,
    ``,
  ],
  blank_desc: [
    `지금 바로 수업을 예약해보세요!`,
    `Book a lesson now!`,
    'n_upcoming_lessons.blank_desc',
    `现在预约课程！`,
    `現在預約課程！`,
    `今すぐレッスンを予約しましょう！`,
    ``,
  ],
  popup_tutor_maching_option: {
    title: [
      `튜터 매칭 옵션 변경`,
      `In case of tutor cancellation`,
      'n_upcoming_lessons.popup_tutor_maching_option.title',
      `变更教师配对选项`,
      `變更教師配對選項`,
      `チューターマッチングオプション変更`,
      `Trong trường hợp gia sư huỷ`,
    ],
    subtitle: [
      `이 튜터와 매칭되지 않을 경우 어떻게 할까요?`,
      ``,
      'n_upcoming_lessons.popup_tutor_maching_option.subtitle',
      `如果与教师配对失败的话想要怎麽处理？`,
      `如果與教師配對失敗的話想要怎麼處理？`,
      `このチューターとマッチングが成立しなかった場合はどのようにしますか？`,
      ``,
    ],
    maching_another_tutors: [
      `다른 튜터 매칭 받기`,
      `Automatically match me with another tutor.`,
      'n_upcoming_lessons.popup_tutor_maching_option.maching_another_tutors',
      `接受与其他教师配对`,
      `接受與其他教師配對`,
      `他のチューターとマッチングしてもらう`,
      `Xếp ngẫu nhiên tôi với một gia sư khác.`,
    ],
    restore_credit: [
      `수업 취소 후 사용한 수업권 복구 받기`,
      `Cancel and restore my lesson credit.`,
      'n_upcoming_lessons.popup_tutor_maching_option.restore_credit',
      `取消课程后退还课程券`,
      `取消課程後退還課程券`,
      `予約をキャンセルし、使用したレッスンチケットを復元する`,
      `Huỷ và hoàn lại thẻ học.`,
    ],
    toast: [
      `튜터 매칭 옵션을 변경했어요.`,
      `Changed tutor matching options.`,
      'n_upcoming_lessons.popup_tutor_maching_option.toast',
      `已变更教师配对选项`,
      `已變更教師配對選項`,
      `チューターマッチングオプションを変更しました。`,
      ``,
    ],
  },
  edit_materials: {
    tab_time: [
      `시간`,
      `Date & Time`,
      'n_upcoming_lessons.edit_materials.tab_time',
      `日期/时间`,
      `日期/時間`,
      `時間`,
      ``,
    ],
    tab_tutor: [`튜터`, `Tutor`, 'n_upcoming_lessons.edit_materials.tab_tutor', `教师`, `教師`, `チューター`, ``],
    tab_materials: [`교재`, `Material`, 'n_upcoming_lessons.edit_materials.tab_materials', `教材`, `教材`, `教材`, ``],
    tab_desc: [
      `교재를 선택해주세요.`,
      `Please select a material.`,
      'n_upcoming_lessons.edit_materials.tab_desc',
      `请选择教材`,
      `請選擇教材`,
      `教材を選択してください。`,
      ``,
    ],
    tab_btn_done: [
      `교재 선택 완료`,
      `Save`,
      'n_upcoming_lessons.edit_materials.tab_btn_done',
      `储存`,
      `儲存`,
      `教材選択完了`,
      ``,
    ],
    popuop_title1: [
      `교재 선택을 그만하시겠어요?`,
      `Would you like to leave without saving?`,
      'n_upcoming_lessons.edit_materials.popuop_title1',
      `您确定不储存就离开吗？`,
      `您確定不儲存就離開嗎？`,
      `教材の選択をやめますか？`,
      ``,
    ],
    popuop_title2: [
      `교재 변경을 그만하시겠어요?`,
      `Would you like to leave without saving?`,
      'n_upcoming_lessons.edit_materials.popuop_title2',
      `您确定不储存就离开吗？`,
      `您確定不儲存就離開嗎？`,
      `教材の変更をやめますか？`,
      ``,
    ],
    change_title: [
      `교재 변경`,
      `Change Material`,
      'n_upcoming_lessons.edit_materials.change_title',
      `更换教材`,
      `更換教材`,
      `教材の変更`,
      ``,
    ],
    select_title: [
      `교재 선택`,
      `Select Material`,
      'n_upcoming_lessons.edit_materials.select_title',
      `选择教材`,
      `選擇教材`,
      `教材の選択`,
      ``,
    ],
    search_placeholder: [
      `교재를 검색해보세요.`,
      `Search for materials`,
      'n_upcoming_lessons.edit_materials.search_placeholder',
      `搜寻教材`,
      `搜尋教材`,
      `教材を選択してください。`,
      ``,
    ],
    toast_select: [
      `교재를 선택했어요.`,
      `Selected your lesson material.`,
      'n_upcoming_lessons.edit_materials.toast_select',
      `已选择教材`,
      `已選擇教材`,
      `教材を選択しました。`,
      ``,
    ],
    toast_change: [
      `교재를 변경했어요.`,
      `Changed your lesson material.`,
      'n_upcoming_lessons.edit_materials.toast_change',
      `已变更教材`,
      `已變更教材`,
      `教材を変更しました。`,
      ``,
    ],
    search_title: [
      `{검색어}에 대한 n개의 검색 결과`,
      `n results for {검색어}`,
      'n_upcoming_lessons.edit_materials.search_title',
      `{关键字}搜索结果`,
      `{關鍵字}搜尋結果`,
      `{検索キー}に対するn個の検索結果`,
      ``,
    ],
    txt: [
      `수업 1시간 전에는 자유교재 & 튜터가 진행했던 교재에서 선택 가능합니다`,
      `When there's 1 hour left, you can use your own materials or the tutor's past materials.`,
      'n_upcoming_lessons.edit_materials.txt',
      `课程开始前1小时您可选择自由主题教材或是教师过去使用过的教材。`,
      `課程開始前1小時您可選擇自由主題教材或是教師過去使用過的教材。`,
      `レッスンまで残り1時間になったら、自分の教材か、チューターが過去に扱った教材のみ使用可能です。`,
      ``,
    ],
    free_writing_material: [
      `내가 만드는 수업`,
      `Custom Class`,
      'n_upcoming_lessons.edit_materials.free_writing_material',
      `自由主题课程`,
      `自由主題課程`,
      `自由テーマレッスン`,
      ``,
    ],
    tutor_prev_material: [
      `튜터가 수업했던 교재`,
      `Materials that teacher has used.`,
      'n_upcoming_lessons.edit_materials.tutor_prev_material',
      `教师使用过的教材`,
      `教師使用過的教材`,
      `教師が使用した教材`,
      ``,
    ],
    featured: [
      `링글팀 추천`,
      `Ringle Team's Pick`,
      'n_upcoming_lessons.edit_materials.featured',
      `Ringle推荐`,
      `Ringle推薦`,
      `Ringleチーム厳選`,
      ``,
    ],
    course: [
      `링글 수업이 익숙하지 않다면 공부 목적에 맞는 교재로 한 달 수업을 진행해 보세요.`,
      `If you're not used to Ringle lessons yet, try taking a one-month course that fits your English needs.`,
      'n_upcoming_lessons.edit_materials.course',
      `如果还不习惯Ringle课程，尝试使用符合学习目标的教材进行一个月的课程`,
      `如果還不習慣Ringle課程，嘗試使用符合學習目標的教材進行一個月的課程`,
      `まだRingleのレッスンに慣れていない方は、ご自身の英語力に合った1ヶ月コースを受講してみてください`,
      ``,
    ],
  },
  lesson_style: {
    title: [
      `수업 방식 설정`,
      `Set Lesson Style`,
      'n_upcoming_lessons.lesson_style.title',
      `设定课程模式`,
      `設定課程模式`,
      `レッスン方式の設定`,
      ``,
    ],
    record_title: [
      `수업 녹음`,
      `Record Lesson`,
      'n_upcoming_lessons.lesson_style.record_title',
      `课程录音`,
      `課程錄音`,
      `レッスンの録音`,
      ``,
    ],
    record_yes: [`녹음하기`, `On`, 'n_upcoming_lessons.lesson_style.record_yes', `录音`, `錄音`, `録音する`, ``],
    record_no: [
      `녹음하지 않기`,
      `Off`,
      'n_upcoming_lessons.lesson_style.record_no',
      `不录音`,
      `不錄音`,
      `録音しない`,
      ``,
    ],
    record_info1: [
      `수업 녹음 파일은 수업 종료 후 [지난 수업] 메뉴에서 확인할 수 있습니다. (파일 제작은 최대 24시간이 소요됩니다.)`,
      `The audio recording of the lesson will become available under the [Past Lessons] menu after the lesson. (It may take up to 24 hours to process the audio file.)`,
      'n_upcoming_lessons.lesson_style.record_info1',
      `您可以在课程结束后，于[已完成的课程]中查看课程录音。（文件制作最多需要24小时。）`,
      `您可以在課程結束後，於[已完成的課程]中確認課程錄音。（錄音檔案製作最多需要24小時。）`,
      `レッスンの録音ファイルは、レッスン終了後に[過去レッスン]メニューから確認することができます。 (ファイルの作成には最大24時間かかります。)`,
      ``,
    ],
    record_info2: [
      `수업 녹음 설정은 수업 시작 15분 전까지만 변경 가능합니다.`,
      `Changes must be made by 15 mins before the lesson to be applied successfully.`,
      'n_upcoming_lessons.lesson_style.record_info2',
      `课程录音与否设定可在课前15分钟更改。`,
      `課程錄音與否設定可在課前15分鐘更改。`,
      `レッスンの録音設定は、授業開始15分前までしか変更できません。`,
      ``,
    ],
    record_info3: [
      `복습을 위한 녹음 파일과 대화 스크립트 제공을 위해 수업이 녹음됩니다.`,
      `Lessons will be recorded to provide audio recordings and transcripts for reviewing the lesson.`,
      'n_upcoming_lessons.lesson_style.record_info3',
      `为了课后提供录音档和转录的对话文字档进行录音。`,
      `為了課後提供錄音檔和轉錄的對話文字檔進行錄音。`,
      `復習のための録音ファイルと会話スクリプトを提供するために、レッスンは録音されます。`,
      ``,
    ],
    analysis_title: [
      `AI 분석`,
      `AI Analysis`,
      'n_upcoming_lessons.lesson_style.analysis_title',
      `AI分析(选择进行分析将于课后获得分析报告）`,
      `AI分析(選擇進行分析將於課後獲得分析報告）`,
      `AI Analysis`,
      ``,
    ],
    analysis_yes: [
      `분석 받기`,
      `On`,
      'n_upcoming_lessons.lesson_style.analysis_yes',
      `进行分析`,
      `進行分析`,
      `分析を受ける`,
      ``,
    ],
    analysis_no: [
      `분석 받지 않기`,
      `Off`,
      'n_upcoming_lessons.lesson_style.analysis_no',
      `不进行分析`,
      `不進行分析`,
      `分析を受けない`,
      ``,
    ],
    analysis_info: [
      `AI 분석이란?`,
      `What is AI analysis?`,
      'n_upcoming_lessons.lesson_style.analysis_info',
      `什麽是AI分析？`,
      `什麼是AI分析？`,
      `AI Analysisとは？`,
      ``,
    ],
    intro_title: [
      `자기소개`,
      `Self-introductions`,
      'n_upcoming_lessons.lesson_style.intro_title',
      `自我介绍`,
      `自我介紹`,
      `自己紹介`,
      ``,
    ],
    intro_no: [
      `소개없이 바로 시작`,
      `Skip`,
      'n_upcoming_lessons.lesson_style.intro_no',
      `不进行自我介绍`,
      `不進行自我介紹`,
      `自己紹介なしですぐにスタート`,
      ``,
    ],
    intro_yes: [
      `서로 소개 3분 후 시작`,
      `Exchange for 3 mins`,
      'n_upcoming_lessons.lesson_style.intro_yes',
      `进行自我介绍3分钟`,
      `進行自我介紹3分鐘`,
      `お互いの紹介3分後に開始`,
      ``,
    ],
    intro_placeholder: [
      `소개 없이 바로 수업할 경우 미리 튜터가 알 수 있도록 자신을 소개하는 글을 적어주세요.`,
      `If you choose to skip self-introductions, please write a short introduction of yourself here so that the tutor can get to know you before the lesson.`,
      'n_upcoming_lessons.lesson_style.intro_placeholder',
      `如果不进行自我介绍，请于课前留下简短的自我介绍供教师确认。`,
      `如果不進行自我介紹，請於課前留下簡短的自我介紹供教師確認。`,
      `自己紹介がない場合は、事前にチューターがわかるように自己紹介文を書いてください。`,
      ``,
    ],
    intro_info: [
      `AI 분석을 받아보기 위해서는 '수업 녹음하기'를 선택해 주세요.`,
      `Please set [Record the lesson] to ON to receive the AI analysis.`,
      'n_upcoming_lessons.lesson_style.intro_info',
      `为了进行AI分析，请进行课程录音。`,
      `為了進行AI分析，請進行課程錄音。`,
      `AI Analysisを受けるには、「レッスンを録音する」を選択してください。`,
      ``,
    ],
    mode_title: [
      `수업 모드`,
      `Lesson Mode`,
      'n_upcoming_lessons.lesson_style.mode_title',
      `课程模式`,
      `課程模式`,
      `レッスンモード`,
      ``,
    ],
    mode_correction: [
      `교정 중심`,
      `Writing-focused`,
      'n_upcoming_lessons.lesson_style.mode_correction',
      `以写作为主`,
      `以寫作為主`,
      `添削中心`,
      ``,
    ],
    mode_correction_instant: [
      `답변 후 즉시 교정 받기`,
      `Instant paraphrasing`,
      'n_upcoming_lessons.lesson_style.mode_correction_instant',
      `即时纠正`,
      `即時糾正`,
      `回答後、すぐに添削を受ける`,
      ``,
    ],
    mode_correction_instant_info: [
      `틀린 영어 표현을 할 때마다 교정 받아요.`,
      `Correct my English as soon as I make a mistake.`,
      'n_upcoming_lessons.lesson_style.mode_correction_instant_info',
      `在我犯错时，教师即时纠正`,
      `在我犯錯時，教師即時糾正`,
      `間違った英語表現をするたびに添削してもらいます。`,
      ``,
    ],
    mode_correction_intermittent: [
      `질문에 대한 대화 후 교정 받기`,
      `Intermittent paraphrasing`,
      'n_upcoming_lessons.lesson_style.mode_correction_intermittent',
      `段落纠正`,
      `段落糾正`,
      `質問に対する会話の後、添削を受ける`,
      ``,
    ],
    mode_correction_intermittent_info: [
      `각 질문에 대한 대화가 끝난 후 교정 받아요.`,
      `Correct my English after we finish talking about a question.`,
      'n_upcoming_lessons.lesson_style.mode_correction_intermittent_info',
      `当课程进行一个段落时，进行纠正`,
      `當課程進行一個段落時，進行糾正`,
      `各質問の会話が終わった後、添削してもらいます。`,
      ``,
    ],
    mode_discussion: [
      `토론 중심`,
      `Speaking-focused`,
      'n_upcoming_lessons.lesson_style.mode_discussion',
      `以口说为主`,
      `以口說為主`,
      `ディスカッション中心`,
      ``,
    ],
    mode_discussion_mode55: [
      `5:5 토론하기`,
      `Talk equally (5:5)`,
      'n_upcoming_lessons.lesson_style.mode_discussion_mode55',
      `发言比例(5:5)`,
      `發言比例(5:5)`,
      `5:5 ディスカッション`,
      ``,
    ],
    mode_discussion_mode55_info: [
      `튜터와 내가 절반씩 이야기를 주고 받아요.`,
      `I want to speak as well as hear what my tutor has to say.`,
      'n_upcoming_lessons.lesson_style.mode_discussion_mode55_info',
      `老师和学生各发言一半的时间`,
      `老師和學生各發言一半的時間`,
      `チューターとあなたが半分ずつ話します。`,
      ``,
    ],
    mode_discussion_mode82: [
      `8:2 토론하기`,
      `Talk more (8:2)`,
      'n_upcoming_lessons.lesson_style.mode_discussion_mode82',
      `发言比例(8:2)`,
      `發言比例(8:2)`,
      `8:2 ディスカッション`,
      ``,
    ],
    mode_discussion_mode82_info: [
      `튜터는 듣고 내가 주로 이야기해요.`,
      `I want to speak as much as possible.`,
      'n_upcoming_lessons.lesson_style.mode_discussion_mode82_info',
      `让学生多发言`,
      `讓學生多發言`,
      `あなたが主に話します。`,
      ``,
    ],
    mode_info1: [
      `체험 수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규 수업에서는 수업모드를 교정 중심과 토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.`,
      `For trial lessons, the tutor will provide intermittent paraphrasing after you talk about a question. For regular lessons, you can choose between writing-focused and speaking-focused lessons and select detailed lesson styles.`,
      'n_upcoming_lessons.lesson_style.mode_info1',
      `体验课採用段落纠正的方式进行。在正式课程中，您可以选择写作为主或口说为主的课程方式，并设定想要的课程模式。`,
      `體驗課採用段落糾正的方式進行。在正式課程中，您可以選擇寫作為主或口說為主的課程方式，並設定想要的課程模式。`,
      `体験レッスンでは、各質問に対する会話が終わった後、添削を受ける方式で行います。通常レッスンでは、レッスンモードを添削中心かディスカッション中心のどちらかを選択し、詳細なレッスン方式を選択することができます。`,
      ``,
    ],
    mode_info2: [
      <>
        <li>분석 결과는 수업 종료 후 [수업 {'>'} 지난 수업] 메뉴에서 확인할 수 있습니다.</li>
        <li>AI 분석은 제공은 최대 24시간 소요될 수 있습니다.</li>
      </>,
      <>
        <li>Results can be checked after class in [Lessons {'>'} Review].</li>
        <li>AI analysis may take up to 24 hours.</li>
      </>,
      'n_upcoming_lessons.lesson_style.mode_info2',
      <>
        <li>分析结果可在课程结束后于[课程 {'>'} 过往课程]中查看。</li>
        <li>AI分析可能需要最多24小时。</li>
      </>,
      <>
        <li>分析結果可在課程結束後於[課程 {'>'} 過往課程]中查看。</li>
        <li>AI分析可能需要最多24小時。</li>
      </>,
      <>
        <li>分析結果は授業終了後に[授業 {'>'} 過去の授業]で確認できます。</li>
        <li>AI分析は最大で24時間かかる場合があります。</li>
      </>,
      ``,
    ],
    intensive_focus_title: [
      `집중 교정 영역 (다중 선택 가능)`,
      `Focus Areas (multiple selection)`,
      'n_upcoming_lessons.lesson_style.intensive_focus_title',
      `学习领域(多选）`,
      `學習領域(多選）`,
      `重点添削箇所（複数選択可）`,
      ``,
    ],
    intensive_focus_choice1: [
      `Fluency`,
      `Fluency`,
      'n_upcoming_lessons.lesson_style.intensive_focus_choice1',
      `Fluency 流畅度`,
      `Fluency 流暢度`,
      `Fluency 流暢さ`,
      ``,
    ],
    intensive_focus_choice2: [
      `Vocabulary`,
      `Vocabulary`,
      'n_upcoming_lessons.lesson_style.intensive_focus_choice2',
      `Vocabulary 词彙`,
      `Vocabulary 詞彙`,
      `Vocabulary 語彙`,
      ``,
    ],
    intensive_focus_choice3: [
      `Grammar`,
      `Grammar`,
      'n_upcoming_lessons.lesson_style.intensive_focus_choice3',
      `Grammar 文法`,
      `Grammar 文法`,
      `Grammar 文法`,
      ``,
    ],
    intensive_focus_choice4: [
      `Pronunciation`,
      `Pronunciation`,
      'n_upcoming_lessons.lesson_style.intensive_focus_choice4',
      `Pronunciation 发音`,
      `Pronunciation 發音`,
      `Pronunciation 発音`,
      ``,
    ],
    additional_request_title: [
      `상세 요청 사항 (다중 선택 가능)`,
      `Detailed requests (multiple selection)`,
      'n_upcoming_lessons.lesson_style.additional_request_title',
      `其他要求（多选）`,
      `其他要求（多選）`,
      `要求事項（複数選択可能）`,
      ``,
    ],
    additional_request_choice1: [
      `제 답변에서 어색한 부분을 상황에 맞는 단어와 문장으로 교정해주세요.`,
      `Please correct and rephrase any awkward words and sentences.`,
      'n_upcoming_lessons.lesson_style.additional_request_choice1',
      `请将我的英语修改成接近母语人士的用法。`,
      `請將我的英文修改成接近母語人士的用法。`,
      `私の回答のぎこちない部分を、状況に合った単語や文章に修正してください。`,
      ``,
    ],
    additional_request_choice2: [
      `문법 실수는 수업이 끝나고 수업노트(Ringle Docs)에 따로 적어주세요.`,
      `Please write up my grammar mistakes in the lesson notes (Ringle Docs) separately after the lesson.`,
      'n_upcoming_lessons.lesson_style.additional_request_choice2',
      `请勿另外说明错误的文法，请帮我写在课堂笔记。`,
      `請勿另外說明錯誤的文法，請幫我寫在課堂筆記。`,
      `文法の間違いは、レッスン終了後、授業ノート(Ringle Docs)に書き込んでください。`,
      ``,
    ],
    additional_request_choice3: [
      `답변과 이어지는 꼬리 질문을 1-2개 더 해주세요.`,
      `Please ask 1-2 follow-up questions after I finish talking about a question.`,
      'n_upcoming_lessons.lesson_style.additional_request_choice3',
      `请于我回答结束后，额外询问我1～2个问题。`,
      `請於我回答結束後，額外詢問我1～2個問題。`,
      `ディスカッション後、1-2個フォローアップの質問をしてください。`,
      ``,
    ],
    additional_request_choice4: [
      `일상 대화체보다 Formal English로 교정받고 싶어요.`,
      `Please correct me in formal English rather than everyday English.`,
      'n_upcoming_lessons.lesson_style.additional_request_choice4',
      `请协助将我的英文纠正成正式的英语用法。`,
      `請協助將我的英文糾正成正式的英文用法。`,
      `日常会話体ではなく、Formal Englishで校正してもらいたいです。`,
      ``,
    ],
    additional_request_choice5: [
      `영어 초급자예요. 수업을 조금 천천히 진행해 주세요.`,
      `Please go slowly during the lesson since I am a beginner.`,
      'n_upcoming_lessons.lesson_style.additional_request_choice5',
      `我是英语初级学习者，请放慢进行速度。`,
      `我是英文初級學習者，請放慢進行速度。`,
      `英語初心者なので、少しゆっくりレッスンを進めてください。`,
      ``,
    ],
    other_request_title: [
      `추가 요청사항`,
      `Additional requests`,
      'n_upcoming_lessons.lesson_style.other_request_title',
      `额外要求`,
      `額外要求`,
      `その他のご要望`,
      ``,
    ],
    other_request_placeholder: [
      `튜터에게 구체적으로 원하는 수업 방식을 영어로 남겨주세요.`,
      `Leave any additional requests you have in English.`,
      'n_upcoming_lessons.lesson_style.other_request_placeholder',
      `请用英语写下您的课堂需求`,
      `請用英文寫下您的課堂需求`,
      `どのようなレッスンを希望するか、チューターに詳細を英語で伝えましょう。`,
      ``,
    ],
    toast: [
      `수업방식을 설정했어요.`,
      `Saved your lesson settings.`,
      'n_upcoming_lessons.lesson_style.toast',
      `储存课程模式设定`,
      `儲存課程模式設定`,
      `レッスン方式を設定しました。`,
      ``,
    ],
  },
  lesson_cancel: {
    title: [
      `수업 취소`,
      `Cancel Lesson`,
      'n_upcoming_lessons.lesson_cancel.title',
      `取消课程`,
      `取消課程`,
      `レッスンのキャンセル`,
      ``,
    ],
    info1: [
      `[수업 시작 기준 24시간 이전 취소]`,
      `[Cancellations more than 24 hours before the lesson]`,
      'n_upcoming_lessons.lesson_cancel.info1',
      `[课程开始前24小时取消课程]`,
      `[課程開始前24小時取消課程]`,
      `[レッスン開始24時間前までのキャンセル］`,
      ``,
    ],
    info2: [
      `해당 수업권이 복구 됩니다.`,
      `The credit you used to book the lesson will be restored.`,
      'n_upcoming_lessons.lesson_cancel.info2',
      `您的课程券将会復原`,
      `您的課程券將會復原`,
      `該当のレッスンが回復されます。`,
      ``,
    ],
    reason_desc: [
      `수업 취소 이유를 선택해주세요.`,
      `Please tell us why you wish to cancel the lesson.`,
      'n_upcoming_lessons.lesson_cancel.reason_desc',
      `请告诉我们取消课程的原因`,
      `請告訴我們取消課程的原因`,
      `キャンセル理由を選択してください。`,
      ``,
    ],
    reason_not_prepared: [
      `수업 준비가 아직 부족해요.`,
      `I am not prepared for the lesson.`,
      'n_upcoming_lessons.lesson_cancel.reason_not_prepared',
      `我还没有准备好要上课`,
      `我還沒有準備好要上課`,
      `レッスンの準備ができていません。`,
      ``,
    ],
    reason_tech_issue: [
      `수업에 필요한 장비, 인터넷 등의 사용이 어려워요`,
      `I am facing technical difficulties(lack of equipment, no internet connection, etc.).`,
      'n_upcoming_lessons.lesson_cancel.reason_tech_issue',
      `上课需要的设备有问题（电脑／网路问题等）`,
      `上課需要的設備有問題（電腦／網路問題等）`,
      `レッスンに必要な機材、インターネットなどの使用が難しいです。`,
      ``,
    ],
    reason_personal_issue: [
      `급한 개인사정이 생겼어요.`,
      `An urgent personal matter came up.`,
      'n_upcoming_lessons.lesson_cancel.reason_personal_issue',
      `有急事无法上课`,
      `有急事無法上課`,
      `個人的な事情が急遽発生しました。`,
      ``,
    ],
    reason_health_issue: [
      `건강상의 이유로 수업 참여가 어려워요.`,
      `I cannot take the lesson due to health issues.`,
      'n_upcoming_lessons.lesson_cancel.reason_health_issue',
      `因为健康问题无法上课`,
      `因為健康問題無法上課`,
      `健康上の理由でレッスン参加が難しいです。`,
      ``,
    ],
    reason_matching_issue: [
      `튜터가 수업 확정을 아직까지 안하고 있어요.`,
      `The tutor still has not confirmed the lesson.`,
      'n_upcoming_lessons.lesson_cancel.reason_matching_issue',
      `教师尚未确认课程`,
      `教師尚未確認課程`,
      `チューターがまだレッスンの確定をしていません。`,
      ``,
    ],
    reason_tutor_issue: [
      `변경된 튜터가 맘에 들지 않아요.`,
      `I don't like the auto-matched tutor.`,
      'n_upcoming_lessons.lesson_cancel.reason_tutor_issue',
      `我不满意自动配对的教师`,
      `我不滿意自動配對的教師`,
      `変更されたチューターが気に入らない。`,
      ``,
    ],
    reason_time_issue: [
      `수업 시간을 착각해 잘못 예약했어요.`,
      `I booked the wrong time slot by mistake.`,
      'n_upcoming_lessons.lesson_cancel.reason_time_issue',
      `我预约错上课时间`,
      `我預約錯上課時間`,
      `レッスン時間を間違えて予約してしまいました。`,
      ``,
    ],
    reason_changetime_issue: [
      `시간 변경이 필요해요.`,
      `I need to change the time slot.`,
      'n_upcoming_lessons.lesson_cancel.reason_changetime_issue',
      `我需要变更上课时间`,
      `我需要變更上課時間`,
      `時間を変更したいです。`,
      ``,
    ],
    reason_other_issue: [
      `기타`,
      `Other`,
      'n_upcoming_lessons.lesson_cancel.reason_other_issue',
      `其他`,
      `其他`,
      `その他`,
      ``,
    ],
    reason_other_issue_placeholder: [
      `기타 취소사유를 입력해주세요.`,
      `Please enter any other reason for cancelling this lesson.`,
      'n_upcoming_lessons.lesson_cancel.reason_other_issue_placeholder',
      `请输入您需要取消课程的原因`,
      `請輸入您需要取消課程的原因`,
      `その他のキャンセル理由を入力してください。`,
      ``,
    ],
  },
  banner_title: [
    `링글 수업을 진행하기 위해 Zoom 설치가 필요합니다.`,
    `You must install Zoom to take Ringle lessons.`,
    'n_upcoming_lessons.banner_title',
    `请安装Zoom以进行Ringle课程`,
    `請安裝Zoom以進行Ringle課程`,
    `Ringleのレッスンを行うためには、Zoomのインストールが必要です。`,
    ``,
  ],
  banner_desc: [
    `링글이 처음이라면, 우측의 [Zoom 설치 및 사용 안내]를 클릭하여 줌을 설치 해 주세요.`,
    `If this is your first time using Ringle, please follow our instructions on installing and using Zoom.`,
    'n_upcoming_lessons.banner_desc',
    `如果您是第一次使用Ringle，请按照Zoom的安装和使用指南准备课程。`,
    `如果您是第一次使用Ringle，請按照Zoom的安裝和使用指南準備課程。`,
    `Ringleが初めての方は、Zoomのインストールと使用方法に従ってレッスンの準備をしましょう。`,
    ``,
  ],
  banner_btn: [
    `Zoom 설치 및 사용 안내`,
    `How to use Zoom`,
    'n_upcoming_lessons.banner_btn',
    `Zoom 安装和使用指南`,
    `Zoom 安裝和使用指南`,
    `Zoomのインストールと使用方法`,
    ``,
  ],
  tutor_cancel_info: (credit) => [
    `* 튜터 취소로 ${credit}이 복구되었습니다.`,
    `* The ${credit} has been restored due to tutor cancellation.`,
    'n_upcoming_lessons.tutor_cancel_info',
    `* 因为教师取消课程 ${credit}课程券已被復原。`,
    `* 因為教師取消課程 ${credit}課程券已被復原。`,
    `* チューターのキャンセルで${credit}が回復されました。`,
    ``,
  ],
  not_confirmed_tutor: [
    `튜터 확정 전`,
    `Not Confirmed`,
    'n_upcoming_lessons.not_confirmed_tutor',
    `教师尚未确认`,
    `教師尚未確認`,
    `チューター未確定`,
    ``,
  ],
  msg_material_auto2: [
    `* 교재가 선택되지 않아 자동으로 선택되었습니다.`,
    `Automatically chosen as no material was selected.`,
    'n_upcoming_lessons.msg_material_auto2',
    `*因为未选择教材，系统已自动选择`,
    `*因為未選擇教材，系統已自動選擇`,
    `* 教材が選択されていないため、自動的に選択されました。`,
    ``,
  ],
  msg_automatch2: [
    `* 튜터 취소로 다른 튜터가 매칭 중입니다.`,
    `* Another tutor is being matched due to a tutor cancellation.`,
    'n_upcoming_lessons.msg_automatch2',
    `* 因为教师取消，正在配对其他教师`,
    `* 因為教師取消，正在配對其他教師`,
    `* チューターがキャンセルしたため他のチューターがマッチングされます。`,
    ``,
  ],
  btn_list: [`다른 시간 예약하기`, `Book a lesson at a different time`, 'n_upcoming_lessons.btn_list', ``, ``, ``, ``],
}
export const n_review_lessons = {
  full_month: (month) => [
    `${month}월`,
    `${getMonthName(month - 1)[1]}`,
    'n_review_lessons.full_month',
    `${month}月`,
    `${month}月`,
    `${month}月`,
    `${getMonthName(month - 1)[1]}`,
  ],
  entire: [`전체`, `All`, 'n_review_lessons.entire', `全部`, `全部`, `すべて`, `Tất cả`],
  reviewed_yet: [
    `리뷰를 완료하지 않은 수업`,
    `Unreviewed lessons`,
    'n_review_lessons.reviewed_yet',
    `未完成複习的课程`,
    `未完成複習的課程`,
    `レビュー未完了のレッスン`,
    ``,
  ],
  reviews_lesson: [
    `리뷰를 완료한 수업`,
    `Reviewed lessons`,
    'n_review_lessons.reviews_lesson',
    `已複习的课程`,
    `已複習的課程`,
    `レビューを完了したレッスン`,
    ``,
  ],
  no_show: [
    `노쇼`,
    `No-show`,
    'n_review_lessons.no_show',
    `未出席的课程`,
    `未出席的課程`,
    `無断欠席したレッスン`,
    `Vắng mặt`,
  ],
  calender_caption: [`수업`, `Lesson`, 'n_review_lessons.calender_caption', `课程`, `課程`, `レッスン`, ``],
  calender_btn: [
    `이번 달로 가기`,
    `Show Current Month`,
    'n_review_lessons.calender_btn',
    `显示当月时间`,
    `顯示當月時間`,
    `今月のレッスンに進む`,
    ``,
  ],
  after_lesson_evaluation: [
    `수업 평가 완료 후에 확인할 수 있습니다.`,
    `You can view this after evaluating the lesson.`,
    'n_review_lessons.after_lesson_evaluation',
    `评论课程后可确认`,
    `評論課程後可確認`,
    `レッスン評価完了後に確認できます。`,
    ``,
  ],
  btn_lesson_evaluation: [
    `수업 평가하기`,
    `Rate Lesson`,
    'n_review_lessons.btn_lesson_evaluation',
    `前往评论课程`,
    `前往評論課程`,
    `レッスンを評価する`,
    `Xếp hạng buổi học`,
  ],
  btn_lesson_review: [
    `수업 리뷰`,
    `Lesson Review`,
    'n_review_lessons.btn_lesson_review',
    `课程複习`,
    `課程複習`,
    `レッスンレビュー`,
    ``,
  ],
  label_preparing: [
    `준비 중`,
    `Preparing`,
    'n_review_lessons.label_preparing',
    `准备中`,
    `準備中`,
    `準備中`,
    `Đang chuẩn bị`,
  ],
  review_caf: [
    `AI 분석 확인`,
    `Check AI analysis`,
    'n_review_lessons.review_caf',
    `确认AI分析报告`,
    `確認AI分析報告`,
    `AI Analysisの確認`,
    ``,
  ],
  review_completed_caf: [
    `AI 분석 확인 완료`,
    `Done checking AI analysis`,
    'n_review_lessons.review_completed_caf',
    `完成确认AI分析报告`,
    `完成確認AI分析報告`,
    `AI Analysisの確認完了`,
    ``,
  ],
  review_feedback: [
    `튜터 피드백 확인`,
    `Check tutor's feedback`,
    'n_review_lessons.review_feedback',
    `确认教师评语`,
    `確認教師評語`,
    `チューターのフィードバックを確認`,
    ``,
  ],
  review_completed_feedback: [
    `튜터 피드백 확인 완료`,
    `Done checking tutor's feedback`,
    'n_review_lessons.review_completed_feedback',
    `完成确认教师评语`,
    `完成確認教師評語`,
    `チューターのフィードバックの確認完了`,
    ``,
  ],
  msg_waiver: [
    `* 해당수업은 AI 분석과 튜터 피드백 리포트가 제공되지 않습니다.`,
    `* AI analysis and tutor's feedback report will not be provided after this lesson.`,
    'n_review_lessons.msg_waiver',
    `* 该课程不提供AI分析报告及教师回馈`,
    `* 該課程不提供AI分析報告及教師回饋。`,
    `* 該当レッスンは、AI Analysisとチューターフィードバックレポートが提供されません。`,
    ``,
  ],
  msg_writing: [
    `* 라이팅 중심 수업은 AI 분석과 튜터 피드백 리포트가 제공되지 않습니다.`,
    `* AI analysis and tutor's feedback report are not available for writing-focused lessons.`,
    'n_review_lessons.msg_writing',
    `* 以写作为中心的课程将不提供AI分析报告及教师回馈。`,
    `* 以寫作為中心的課程將不提供AI分析報告及教師回饋。`,
    `* ライティング中心のレッスンは、AI Analysisとチューターのフィードバックレポートが提供されません。`,
    ``,
  ],
  menu_option1: [
    `AI 분석`,
    `AI Analysis`,
    'n_review_lessons.menu_option1',
    `AI分析报告`,
    `AI分析報告`,
    `AI Analysis`,
    ``,
  ],
  menu_option2: [
    `튜터 피드백`,
    `Tutor's Feedback`,
    'n_review_lessons.menu_option2',
    `教师回馈`,
    `教師回饋`,
    `チューターのフィードバック`,
    ``,
  ],
  menu_option3: [`수업 녹음`, `Recording`, 'n_review_lessons.menu_option3', `课程录音`, `課程錄音`, `レッスン録音`, ``],
  menu_option4: [
    `수업 노트`,
    `Lesson Notes`,
    'n_review_lessons.menu_option4',
    `课程笔记`,
    `課程筆記`,
    `レッスンノート`,
    ``,
  ],
  menu_option5: [
    `수업 준비`,
    `Lesson Prep`,
    'n_review_lessons.menu_option5',
    `课前准备`,
    `課前準備`,
    `レッスンの準備`,
    ``,
  ],
  menu_option6: [
    `수업 방식`,
    `Lesson Style`,
    'n_review_lessons.menu_option6',
    `课程模式`,
    `課程模式`,
    `レッスン方式`,
    ``,
  ],
  blank_title1: [
    `이 달의 수업 기록이 없어요.`,
    `You have not taken any lessons this month.`,
    'n_review_lessons.blank_title1',
    `您这个月没有上课。`,
    `您這個月沒有上課。`,
    `今月のレッスン履歴はありません。`,
    ``,
  ],
  btn_recent_lessons: [
    `최근 수업 기록 보기`,
    `View recent lessons`,
    'n_review_lessons.btn_recent_lessons',
    `浏览最近的课程`,
    `瀏覽最近的課程`,
    `最近のレッスン履歴を見る`,
    ``,
  ],
  blank_title2: [
    `수업 기록이 없어요.`,
    `You have no history of lessons.`,
    'n_review_lessons.blank_title2',
    `您没有完成的课程`,
    `您沒有完成的課程`,
    `レッスン履歴はありません。`,
    ``,
  ],
  blank_desc: [
    `지금 바로 수업을 예약해보세요!`,
    `Book a lesson now!`,
    'n_review_lessons.blank_desc',
    `现在预约课程！`,
    `現在預約課程！`,
    `今すぐレッスンを予約しましょう！`,
    ``,
  ],
  tutor_noshow_info: (credit) => [
    `* 튜터 노쇼로 ${credit}이 복구되었습니다.`,
    `* The ${credit} has been restored due to tutor no-show.`,
    'n_review_lessons.tutor_noshow_info',
    `* 因为教未出席课程 ${credit}课程券已被復原。`,
    `* 因為教未出席課程 ${credit}課程券已被復原。`,
    `* チューターがノーショーだったため ${credit}が回復されました。`,
    ``,
  ],
  student_noshow_info: [
    `* 학생이 노쇼한 수업입니다.`,
    `* This lesson is a student no-show.`,
    'n_review_lessons.student_noshow_info',
    `*学生未出席课程。`,
    `*學生未出席課程。`,
    `* 生徒がノーショーだったレッスンです。`,
    ``,
  ],
  blank_title3: [
    `리뷰를 완료하지 않은 수업이 없어요.`,
    `You have no unreviewed lessons lessons.`,
    'n_review_lessons.blank_title3',
    `您已複习所有课程`,
    `您已複習所有課程`,
    `You have no unreviewed lessons lessons.`,
    `You have no unreviewed lessons lessons.`,
  ],
  blank_title4: [
    `리뷰를 완료한 수업이 없어요.`,
    `You have no reviewed lessons lessons.`,
    'n_review_lessons.blank_title4',
    `您尚未複习课程`,
    `您尚未複習課程`,
    `You have no reviewed lessons lessons.`,
    `You have no reviewed lessons lessons.`,
  ],
  blank_title5: [
    `노쇼한 수업이 없어요.`,
    `You have no no-show lessons.`,
    'n_review_lessons.blank_title5',
    `你没有未出席的课程`,
    `你沒有未出席的課程`,
    `You have no no-show lessons.`,
    `You have no no-show lessons.`,
  ],
}
export const n_history = {
  year_month: (n) => [
    <DateComponent format="yyyy년 mm월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mmM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    'n_history.year_month',
    <DateComponent format="yyyy년 mm月" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy년 mm月" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年mm月" locale="ja">
      {n}
    </DateComponent>,
    ``,
  ],
}
export const n_favorite_tutors = {
  title: (number) => [
    `찜한 튜터 (${number})`,
    `Favorite (${number})`,
    'n_favorite_tutors.title',
    `收藏的教师 (${number})`,
    `收藏的教師 (${number})`,
    `おすすめ (${number})`,
    ``,
  ],
  tutor_all: [
    `전체 튜터 보기`,
    `View all tutors`,
    'n_favorite_tutors.tutor_all',
    `看全部教师`,
    `看全部教師`,
    `全てのチューターを見る`,
    ``,
  ],
  delete_tutor: {
    content: [
      `이 튜터를 찜한 튜터에서 삭제합니다.`,
      `Remove this tutor from Favorite Tutors?`,
      'n_favorite_tutors.delete_tutor.content',
      `要将教师从收藏教师中移除吗？`,
      `要將教師從收藏教師中移除嗎？`,
      `お気に入りから削除しました。`,
      ``,
    ],
    toast_succeed: [
      `찜한 튜터에서 삭제했어요.`,
      `Removed from Favorite Tutors.`,
      'n_favorite_tutors.delete_tutor.toast_succeed',
      `将教师从收藏教师中移除`,
      `將教師從收藏教師中移除`,
      `お気に入りから削除しました。`,
      ``,
    ],
  },
  empty_state: {
    content: [
      <>
        아직 찜한 튜터가 없어요. <br />
        찜한 튜터를 추가하면 예약 가능한 시간을 빠르게 확인할 수 있어요.
      </>,
      <>
        You haven't added any tutors to Favorite Tutors yet.
        <br />
        Add tutors to Favorite Tutors to check their availability at a glance.
      </>,
      'n_favorite_tutors.empty_state.content',
      <>
        您还没有收藏的教师 <br />
        收藏教师之后可以快速确认可以预约的时间。
      </>,
      <>
        您還沒有收藏的教師 <br />
        收藏教師之後可以快速確認可以預約的時間。
      </>,
      <>
        まだお気に入りのチューターがいません
        <br />。 気になるチューターを追加すると、予約可能な時間を素早く確認することができます。
      </>,
      ``,
    ],
  },
  add_tutor: {
    toast_succeed: [
      `찜한 튜터에 추가했어요.`,
      `Added to Favorite Tutors.`,
      'n_favorite_tutors.add_tutor.toast_succeed',
      `已加入我收藏的教师`,
      `已加入我收藏的教師`,
      `お気に入りのチューターに追加しました。`,
      ``,
    ],
  },
}
export const n_prev_tutors = {
  title: (number) => [
    `수업한 튜터 (${number})`,
    `Previous (${number})`,
    'n_prev_tutors.title',
    `和我上过课的教师 (${number})`,
    `和我上過課的教師 (${number})`,
    `レッスン受講済(${number})`,
    ``,
  ],
}
export const n_myringle = {
  title: [`마이링글`, `MyRingle`, 'n_myringle.title', `我的Ringle`, `我的Ringle`, `My Ringle`, ``],
  stat: {
    title: (name, n) => [
      <>
        {name}님의 링글 학습 {n}일째
      </>,
      <>
        {name}'s Day {n} with Ringle
      </>,
      'n_myringle.stat.title',
      <>
        {name}在Ringle已经学习了{n}日
      </>,
      <>
        {name}在Ringle已經學習了{n}日
      </>,
      <>
        {name}さんのRingle学習 {n} 日目
      </>,
      ``,
    ],
    total: (n) => [<>{n}회</>, <>{n} lesson(s)</>, 'n_myringle.stat.total', <>{n}次</>, <>{n}次</>, <>{n} 回</>, ``],
    desc1: [
      `지금까지 수업`,
      `Completed`,
      'n_myringle.stat.desc1',
      `至今完成的课程`,
      `至今完成的課程`,
      `これまでのレッスン`,
      ``,
    ],
    lesson_streak: (n) => [
      <>{n}주</>,
      <>{n} week(s)</>,
      'n_myringle.stat.lesson_streak',
      <>{n}週</>,
      <>{n}週</>,
      <>{n}週</>,
      ``,
    ],
    desc2: [
      `연속 수업`,
      `Lesson streak`,
      'n_myringle.stat.desc2',
      `连续进行课程`,
      `連續進行課程`,
      `レッスン継続期間`,
      ``,
    ],
    study_streak: (n) => [
      <>{n}주</>,
      <>{n} week(s)</>,
      'n_myringle.stat.study_streak',
      <>{n}週</>,
      <>{n}週</>,
      <>{n}週</>,
      ``,
    ],
    desc3: [`연속 학습`, `Study streak`, 'n_myringle.stat.desc3', `连续学习`, `連續學習`, `学習継続期間`, ``],
    material_title: [`내 교재`, `My materials`, 'n_myringle.stat.material_title', `我的教材`, `我的教材`, `教材`, ``],
    tutor_title: [`내 튜터`, `My tutors`, 'n_myringle.stat.tutor_title', `我的教师`, `我的教師`, `マイチューター`, ``],
    title2: [`1:1 수업`, `1:1 Lessons`, 'n_myringle.stat.title2', `1:1 Lessons`, `1:1 Lessons`, `1:1 Lessons`, ``],
  },
  calender: {
    desc1: (month) => [
      <>{month}월 링글 수업</>,
      <>Lessons in {month}</>,
      'n_myringle.calender.desc1',
      <>{month}月的课程</>,
      <>{month}月的課程</>,
      <>{month}月Ringleレッスン</>,
      ``,
    ],
    lesson: (n) => [<>{n}회</>, <>{n}</>, 'n_myringle.calender.lesson', <>{n}次</>, <>{n}次</>, <>{n} 回</>, ``],
    desc2: (month) => [
      <>{month}월 학습</>,
      <>Daily Study in {month}</>,
      'n_myringle.calender.desc2',
      <>{month}月的每日学习</>,
      <>{month}月的每日學習</>,
      <>{month}月学習</>,
      ``,
    ],
    study: (n) => [<>{n}회</>, <>{n}</>, 'n_myringle.calender.study', <>{n}次</>, <>{n}次</>, <>{n} 回</>, ``],
  },
  ai_analytics: {
    caption: [
      `*최대 12개의 AI 분석 기록이 표시됩니다.`,
      `Up to 12 AI Analysis records will be displayed.`,
      'n_myringle.ai_analytics.caption',
      `*一次最多显示12次AI分析报告`,
      `*一次最多顯示12次AI分析報告`,
      `*最大12個のAI Analysis記録が表示されます。`,
      ``,
    ],
    blank: [
      <>
        AI 분석 기록이 없습니다.
        <br />
        수업을 듣고 AI 분석을 받아보세요.
      </>,
      <>
        There is no AI analysis record.
        <br />
        Book a lesson and receive AI analysis.
      </>,
      'n_myringle.ai_analytics.blank',
      <>
        没有AI分析报告记录.
        <br />
        请上课并获得AI分析报告
      </>,
      <>
        沒有AI分析報告記錄.
        <br />
        請上課並獲得AI分析報告
      </>,
      <>
        AI Analysisの記録がありません。
        <br />
        レッスンを受けてAI Analysisを受けましょう。
      </>,
      ``,
    ],
  },
  badge_count: (number) => [
    `내 배지 (${number})`,
    `My Badges (${number})`,
    'n_myringle.badge_count',
    `我的徽章 (${number})`,
    `我的徽章 (${number})`,
    `マイバッジ (${number})`,
    ``,
  ],
  challenge_title: [
    `내 챌린지`,
    `My Challenges`,
    'n_myringle.challenge_title',
    `我的挑战`,
    `我的挑戰`,
    `マイチャレンジ`,
    ``,
  ],
  lesson_stat: {
    title: [
      `수업 통계`,
      `Lesson Stats`,
      'n_myringle.lesson_stat.title',
      `课程统计`,
      `課程統計`,
      `レッスンステータス`,
      ``,
    ],
    history: [
      `이전 버전 보기`,
      `View old version`,
      'n_myringle.lesson_stat.history',
      `看过去版本`,
      `看過去版本`,
      `以前のバージョンを表示`,
      ``,
    ],
    dropdown1: [
      `최근 3개월`,
      `Past 3 months`,
      'n_myringle.lesson_stat.dropdown1',
      `过去3个月`,
      `過去3個月`,
      `直近3ヶ月`,
      ``,
    ],
    dropdown2: [`6개월`, `6 months`, 'n_myringle.lesson_stat.dropdown2', `6个月`, `6個月`, `6ヶ月`, ``],
    dropdown3: [`12개월`, `12 months`, 'n_myringle.lesson_stat.dropdown3', `12个月`, `12個月`, `12ヶ月`, ``],
    prep_title: [
      `수업 준비 완료율`,
      `Lesson prep rate`,
      'n_myringle.lesson_stat.prep_title',
      `课程准备完成率`,
      `課程準備完成率`,
      `レッスン準備完了率`,
      ``,
    ],
    prep_tooltip: [
      `선택한 기간 동안 수업 준비를 완료하고 수업을 진행한 수업의 비율입니다.`,
      `The ratio of lessons for which you have completed lesson prep before the lesson during the given period.`,
      'n_myringle.lesson_stat.prep_tooltip',
      `在您选择的时间裡完成课前准备的比率`,
      `在您選擇的時間裡完成課前準備的比率`,
      `選択した期間中にレッスン準備を完了し、レッスンを行った割合です。`,
      ``,
    ],
    review_title: [
      `수업 리뷰 완료율`,
      `Lesson review rate`,
      'n_myringle.lesson_stat.review_title',
      `确认课后评价完成率`,
      `確認課後評價完成率`,
      `レッスンレビュー完了率`,
      ``,
    ],
    review_tooltip: [
      `선택한 기간 동안 수업 리뷰를 완료한 수업의 비율입니다.`,
      `The ratio of lessons for which you have completed lesson review during the given period.`,
      'n_myringle.lesson_stat.review_tooltip',
      `在您选择的时间裡确认课后评价的比率`,
      `在您選擇的時間裡確認課後評價的比率`,
      `選択した期間中にレッスンレビューを完了した割合です。`,
      ``,
    ],
    blank1: [
      `해당 기간에 완료한 수업이 없습니다.`,
      `You have not completed any lessons during this period.`,
      'n_myringle.lesson_stat.blank1',
      `你在所选期间未进行课程`,
      `你在所選期間未進行課程`,
      `該当期間に完了したレッスンがありません。`,
      ``,
    ],
    title2: [
      `1:1 수업 통계`,
      `1:1 Lesson Statistics`,
      'n_myringle.lesson_stat.title2',
      `1:1 Lesson Statistics`,
      `1:1 Lesson Statistics`,
      `1:1 Lesson Statistics`,
      ``,
    ],
  },
  rwm: {
    title: (number) => [
      `학습한 함께 읽기 프로그램(${number})`,
      `My Read Together(${number})`,
      'n_myringle.rwm.title',
      `一起阅读（Read Together）次数 (${number})`,
      `一起閱讀（Read Together）次數 (${number})`,
      `学習したRead Togetherプログラム(${number})`,
      ``,
    ],
    blank: [
      `학습한 함께 읽기 프로그램이 없어요.`,
      `There isn't any Read Together program history yet.`,
      'n_myringle.rwm.blank',
      `尚未有一起阅读（Read Together）课程纪录`,
      `尚未有一起閱讀（Read Together）課程紀錄`,
      `学習したRead Togetherプログラムがありません。`,
      ``,
    ],
  },
  self: {
    title: (number) => [
      `학습한 혼자 읽기 프로그램(${number})`,
      `My Read Alone(${number})`,
      'n_myringle.self.title',
      `独自阅读（Read Alone）次数 (${number})`,
      `獨自閱讀（Read Alone）次數 (${number})`,
      `学習したRead Aloneプログラム(${number})`,
      ``,
    ],
    blank: [
      `학습한 혼자 읽기 프로그램이 없어요.`,
      `There isn't any Read Alone program history yet.`,
      'n_myringle.self.blank',
      `尚未有独自阅读（Read Alone）课程纪录`,
      `尚未有獨自閱讀（Read Alone）課程紀錄`,
      `学習したRead Aloneプログラムがありません。`,
      ``,
    ],
  },
  calendar: [`달력 보기`, `See calendar`, 'n_myringle.calendar', `查看日曆`, `查看日曆`, `カレンダーを見る`, ``],
  challenge_blank_new: [
    <>
      진행 중인 챌린지가 있어요.
      <br />
      챌린지를 달성하고 배지를 획득하세요!
    </>,
    <>
      You have an on-going challenge.
      <br />
      Complete the challenge and receive the merit badge!
    </>,
    'n_myringle.challenge_blank_new',
    <>
      已有进行中的挑战.
      <br />
      请完成挑战获得徽章!
    </>,
    <>
      已有進行中的挑戰.
      <br />
      請完成挑戰獲得徽章!
    </>,
    <>
      進行中のチャレンジがあります。
      <br />
      チャレンジを達成してバッジを獲得しましょう
    </>,
    ``,
  ],
  ai_tutor: {
    title: [`AI 튜터`, `AI Tutor`, 'n_myringle.ai_tutor.title', `AI Tutor`, `AI Tutor`, `AI Tutor`, ``],
    date: (date) => [
      `${date}일`,
      `Day ${date}`,
      'n_myringle.ai_tutor.date',
      `Day ${date}`,
      `Day ${date}`,
      `Day ${date}`,
      ``,
    ],
    count: [`개`, ``, 'n_myringle.ai_tutor.count', ``, ``, ``, ``],
    category1: [
      `전체 학습일`,
      `days studied`,
      'n_myringle.ai_tutor.category1',
      `days studied`,
      `days studied`,
      `days studied`,
      ``,
    ],
    category2: [
      `완료한 학습`,
      `AI studies`,
      'n_myringle.ai_tutor.category2',
      `AI studies`,
      `AI studies`,
      `AI studies`,
      ``,
    ],
    category3: [`완료한 대화`, `AI talks`, 'n_myringle.ai_tutor.category3', `AI talks`, `AI talks`, `AI talks`, ``],
    btn1: [
      `대화 기록 보기`,
      `View talk record`,
      'n_myringle.ai_tutor.btn1',
      `View talk record`,
      `View talk record`,
      `View talk record`,
      ``,
    ],
    store: [`내 학습`, `My AI Study`, 'n_myringle.ai_tutor.store', `My AI Study`, `My AI Study`, `My AI Study`, ``],
    expression: [
      `저장한 표현`,
      `My Expressions`,
      'n_myringle.ai_tutor.expression',
      `My Expressions`,
      `My Expressions`,
      `My Expressions`,
      ``,
    ],
    vocab: [`저장한 단어`, `My Vocabs`, 'n_myringle.ai_tutor.vocab', `My Vocabs`, `My Vocabs`, `My Vocabs`, ``],
    roleplay: [
      `저장한 롤플레잉`,
      `My Roleplays`,
      'n_myringle.ai_tutor.roleplay',
      `My Roleplays`,
      `My Roleplays`,
      `My Roleplays`,
      ``,
    ],
  },
  ai_tutor_expression: {
    title: [
      `저장한 표현 (#)`,
      `My Expressions (#)`,
      'n_myringle.ai_tutor_expression.title',
      `My Expressions (#)`,
      `My Expressions (#)`,
      `My Expressions (#)`,
      ``,
    ],
    empty1: [
      `저장한 표현이 없어요`,
      `No expressions saved.`,
      'n_myringle.ai_tutor_expression.empty1',
      `No expressions saved.`,
      `No expressions saved.`,
      `No expressions saved.`,
      ``,
    ],
    btn1: [
      `학습하기`,
      `Start AI study`,
      'n_myringle.ai_tutor_expression.btn1',
      `Start AI study`,
      `Start AI study`,
      `Start AI study`,
      ``,
    ],
    btn2: [`발음 연습`, `Practice`, 'n_myringle.ai_tutor_expression.btn2', `Practice`, `Practice`, `Practice`, ``],
    modal_text: [
      `이 표현을 저장된 표현 목록에서 삭제합니다.`,
      `Delete this phrase from My Expressions?`,
      'n_myringle.ai_tutor_expression.modal_text',
      `Delete this phrase from My Expressions?`,
      `Delete this phrase from My Expressions?`,
      `Delete this phrase from My Expressions?`,
      ``,
    ],
    toast: [
      `저장한 표현 목록에서 삭제했어요.`,
      `The phrase has been deleted from My Expressions.`,
      'n_myringle.ai_tutor_expression.toast',
      `The phrase has been deleted from My Expressions.`,
      `The phrase has been deleted from My Expressions.`,
      `The phrase has been deleted from My Expressions.`,
      ``,
    ],
  },
  ai_tutor_vocab: {
    title: [
      `저장한 단어 (#)`,
      `My Vocabs (#)`,
      'n_myringle.ai_tutor_vocab.title',
      `My Vocabs (#)`,
      `My Vocabs (#)`,
      `My Vocabs (#)`,
      `My Vocabs (#)`,
    ],
    empty1: [
      `저장한 단어가 없어요`,
      `No vocabs saved.`,
      'n_myringle.ai_tutor_vocab.empty1',
      `No vocabs saved.`,
      `No vocabs saved.`,
      `No vocabs saved.`,
      `No vocabs saved.`,
    ],
    modal_text: [
      `이 단어를 저장한 단어 목록에서 삭제합니다.`,
      `Delete this word from My Vocabs?`,
      'n_myringle.ai_tutor_vocab.modal_text',
      `Delete this word from My Vocabs?`,
      `Delete this word from My Vocabs?`,
      `Delete this word from My Vocabs?`,
      `Delete this word from My Vocabs?`,
    ],
    toast: [
      `저장한 단어 목록에서 삭제했어요.`,
      `The word has been deleted from My Vocabs.`,
      'n_myringle.ai_tutor_vocab.toast',
      `The word has been deleted from My Vocabs.`,
      `The word has been deleted from My Vocabs.`,
      `The word has been deleted from My Vocabs.`,
      `The word has been deleted from My Vocabs.`,
    ],
  },
  ai_tutor_roleplay: {
    title: [
      `저장한 롤플레잉 (#)`,
      `My Roleplays (#)`,
      'n_myringle.ai_tutor_roleplay.title',
      `My Roleplays (#)`,
      `My Roleplays (#)`,
      `My Roleplays (#)`,
      `My Roleplays (#)`,
    ],
    empty1: [
      `저장한 롤플레이가 없어요`,
      `No role-playing talk saved.`,
      'n_myringle.ai_tutor_roleplay.empty1',
      `No role-playing talk saved.`,
      `No role-playing talk saved.`,
      `No role-playing talk saved.`,
      `No role-playing talk saved.`,
    ],
    modal_title: [
      `롤플레이 하기`,
      `Role play with AI Tutor`,
      'n_myringle.ai_tutor_roleplay.modal_title',
      `Role play with AI Tutor`,
      `Role play with AI Tutor`,
      `Role play with AI Tutor`,
      `Role play with AI Tutor`,
    ],
    modal_text: [
      `이 롤플레잉을 저장한 롤플레잉 목록에서 삭제합니다.`,
      `Delete this talk from My Roleplays?`,
      'n_myringle.ai_tutor_roleplay.modal_text',
      `Delete this talk from My Roleplays?`,
      `Delete this talk from My Roleplays?`,
      `Delete this talk from My Roleplays?`,
      `Delete this talk from My Roleplays?`,
    ],
    toast: [
      `저장한 롤플레잉 목록에서 삭제했어요.`,
      `The talk has been deleted from My Roleplays.`,
      'n_myringle.ai_tutor_roleplay.toast',
      `The talk has been deleted from My Roleplays.`,
      `The talk has been deleted from My Roleplays.`,
      `The talk has been deleted from My Roleplays.`,
      `The talk has been deleted from My Roleplays.`,
    ],
  },
}
export const n_mycredit = {
  title: [
    `내 수업권`,
    `My Credits`,
    'n_mycredit.title',
    `我的课程券`,
    `我的課程券`,
    `レッスンチケット`,
    `Phiếu học tập của tôi`,
  ],
  title_page: [
    `내 수업권`,
    `My Credits`,
    'n_mycredit.title_page',
    `我的课程券`,
    `我的課程券`,
    `マイレッスンチケット`,
    `Phiếu học tập của tôi`,
  ],
  title_btn: [
    `수업권 변경하기`,
    `Exchange Credits`,
    'n_mycredit.title_btn',
    `变更课程券`,
    `變更課程券`,
    `レッスンチケットを変更する`,
    `Thẻ học quy đổi`,
  ],
  note_title: [
    `수업권 유의사항`,
    `Terms`,
    'n_mycredit.note_title',
    `课程券注意事项`,
    `課程券注意事項`,
    `レッスンチケットの注意事項`,
    `Điều khoản`,
  ],
  note_1: [
    `수강기간은 수업권 구매 이후 첫 수업을 완료하거나, 수업권을 사용하지 않고 1년이 지나는 경우 차감이 시작됩니다.`,
    `Credits will begin to expire when you complete your first lesson using the credits or, if unused, one year after the purchase date.`,
    'n_mycredit.note_1',
    `课程券使用时间将于上完第一堂课后计算。在未使用的状态下将于购买后一年开始计算。`,
    `課程券使用時間將於上完第一堂課後計算。在未使用的狀態下將於購買後一年開始計算。`,
    `受講期間は、レッスンチケットを購入された後、最初のレッスンを完了したり、レッスンチケットを使用せずに1年が経過する場合に差引きされます。`,
    `Thời lượng khóa học được tính kể từ lúc hoàn thành bài học đầu tiên sau khi mua gói học hoặc nếu quá 1 năm không sử dụng.`,
  ],
  note_2: [
    `단, 무료 수업권은 수업권 지급 시점부터 수강기간이 차감됩니다.`,
    `However, free credits will begin to expire from the date they were issued.`,
    'n_mycredit.note_2',
    `免费课程券的使用时间将于发送当下开始计算。`,
    `免費課程券的使用時間將於發送當下開始計算。`,
    `ただし、無料レッスンチケットは、レッスンチケットの付与時点から受講期間が差し引かれます。`,
    `Tuy nhiên, các bài học miễn phí sẽ bắt đầu hết hạn từ ngày được cấp.`,
  ],
  note_3: [
    `매주 수업 듣기 수업권은 결제 시점부터 수강기간이 시작되고, 수업을 진행하지 않은 주에는 수업권 1회가 자동 차감됩니다. (수업 24시간 이내 튜터로 인해 수업이 진행되지 않은 경우는 예외처리함)`,
    `Every Week credits will begin to expire from the purchase date, and you will lose one credit every week even if you don't take any lessons. (Exception: if the lesson was cancelled due to the tutor within 24 hours from the scheduled starting time.)`,
    'n_mycredit.note_3',
    `一週一次课程券于购买当下即开始扣除使用时间，当週未使用的课程券也将自动扣除。（如果在课程进行前24小时因为教师的原因没有进行课程时将不适用于此规定）`,
    `一週一次課程券於購買當下即開始扣除使用時間，當週未使用的課程券也將自動扣除。（如果在課程進行前24小時因為教師的原因沒有進行課程時將不適用於此規定）`,
    `毎週レッスンを受けるレッスンチケットは、決済時点から受講期間が開始されます。レッスンが行われなかった週にはレッスンチケットが1回自動的に差し引かれます（レッスンが24時間以内にチューターにより行われなかった場合は例外となります）`,
    `Phiếu học hàng tuần sẽ bắt đầu hết hạn tính từ ngày mua, trừ một lần học ngay cả khi không hoàn thành một buổi học trong tuần. (Ngoại trừ trường hợp buổi học bị hủy bởi gia sư trong vòng 24 giờ kể từ thời gian bắt đầu đã được lên lịch)`,
  ],
  filter_1: {
    placeholder: [
      `사용 가능 여부`,
      `Status`,
      'n_mycredit.filter_1.placeholder',
      `是否可以使用`,
      `是否可以使用`,
      `使用可否`,
      `Có hay không có khả năng sử dụng`,
    ],
    option_1: [`전체보기`, `All`, 'n_mycredit.filter_1.option_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
    option_2: [
      `사용가능`,
      `Unused`,
      'n_mycredit.filter_1.option_2',
      `可以使用`,
      `可以使用`,
      `使用可能`,
      `Có khả năng sử dụng`,
    ],
    option_3: [
      `사용 완료/만료/환불`,
      `Used/Expired/Refunded`,
      'n_mycredit.filter_1.option_3',
      `使用完毕/已过期/退款`,
      `使用完畢/已過期/退款`,
      `使用完了/満了/返金`,
      `Hoàn thành việc sử dụng/Hết hạn/Hoàn trả tiền`,
    ],
  },
  filter_2: {
    placeholder: [
      `수업권 종류`,
      `Credit type`,
      'n_mycredit.filter_2.placeholder',
      `课程券种类`,
      `課程券種類`,
      `レッスンチケットの種類`,
      `Loại phiếu học tập`,
    ],
    option_1: [`전체보기`, `All`, 'n_mycredit.filter_2.option_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
    option_2: [
      `정규수업권`,
      `Regular`,
      'n_mycredit.filter_2.option_2',
      `一般课程券`,
      `一般課程券`,
      `レギュラー`,
      `Tiêu chuẩn`,
    ],
    option_3: [
      `당일수업권`,
      `Last-minute`,
      'n_mycredit.filter_2.option_3',
      `当日课程券`,
      `當日課程券`,
      `補充`,
      `Trong ngày`,
    ],
    option_4: [
      `시간한정수업권`,
      `Limited Window`,
      'n_mycredit.filter_2.option_4',
      `限时课程券`,
      `限時課程券`,
      `時間限定`,
      `Giới hạn`,
    ],
    option_5: [
      `긴급편성수업권`,
      `FastBook`,
      'n_mycredit.filter_2.option_5',
      `紧急编排课程券`,
      `緊急編排課程券`,
      `サプライズチケット`,
      `Mở gấp`,
    ],
  },
  filter_3: {
    placeholder: [
      `수업 길이`,
      `Lesson Duration`,
      'n_mycredit.filter_3.placeholder',
      `课时长度`,
      `課程長度`,
      `レッスン時間`,
      `Thời gian tiết học`,
    ],
    option_1: [`전체보기`, `All`, 'n_mycredit.filter_3.option_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
    option_2: [`20분`, `20 minutes`, 'n_mycredit.filter_3.option_2', `20分钟`, `20分鐘`, `20分`, `20 phút`],
    option_3: [`40분`, `40 minutes`, 'n_mycredit.filter_3.option_3', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  },
  module: {
    credit_remain: [
      `남은 횟수`,
      `Credits`,
      'n_mycredit.module.credit_remain',
      `所剩次数`,
      `所剩次數`,
      `残り回数`,
      `Số lần còn sót lại`,
    ],
    duration: [
      `수강기간`,
      `Expiration date`,
      'n_mycredit.module.duration',
      `使用期限`,
      `使用期限`,
      `受講期間`,
      `Thời gian học`,
    ],
    issue_date: [
      `발급시점`,
      `Issue date`,
      'n_mycredit.module.issue_date',
      `发放时间`,
      `發放時間`,
      `発行時点`,
      `Thời gian cấp`,
    ],
    btn_1: [
      `수업 관리북`,
      `Lesson Book`,
      'n_mycredit.module.btn_1',
      `课程管理`,
      `課程管理`,
      `レッスン管理ブック`,
      `Sách quản lý tiết học`,
    ],
    btn_2: [
      `수강기간 연장`,
      `Extend dates`,
      'n_mycredit.module.btn_2',
      `延长使用期限`,
      `延長使用期限`,
      `期間延長`,
      `Gia hạn`,
    ],
    btn_3: [
      `증명서 발급`,
      `Certificate/Receipt`,
      'n_mycredit.module.btn_3',
      `发放证明/收据`,
      `發行證明/收據`,
      `証明書/領収書`,
      `Chứng nhận/hóa đơn`,
    ],
    btn_text: [
      `수업권 사용 내역`,
      `See details`,
      'n_mycredit.module.btn_text',
      `了解详情`,
      `瞭解詳情`,
      `詳しく見る`,
      `Nhìn kĩ`,
    ],
  },
  detail: {
    title_1: [
      `수업권 보유 현황`,
      `Credit Summary`,
      'n_mycredit.detail.title_1',
      `课程券现状`,
      `課程券狀態`,
      `レッスンチケットの状況`,
      `Tình hình hiện tại của phiếu học tập`,
    ],
    caption_1: [
      `사용 가능`,
      `Unused`,
      'n_mycredit.detail.caption_1',
      `可以使用`,
      `可以使用`,
      `使用可能`,
      `Nội dung khóa học`,
    ],
    caption_2: [
      `만료/환불`,
      `Expired/Refunded`,
      'n_mycredit.detail.caption_2',
      `已到期/退款`,
      `已到期/退款`,
      `満了/返金`,
      `Hết hạn/ hoàn trả tiền`,
    ],
    column_1: [
      `수업권 종류`,
      `Credit type`,
      'n_mycredit.detail.column_1',
      `课程券种类`,
      `課程券種類`,
      `レッスンチケットの種類`,
      `các loại phiếu học tập`,
    ],
    column_2: [
      `수업권 수`,
      `Credit quantity`,
      'n_mycredit.detail.column_2',
      `课程券数量`,
      `課程券數量`,
      `レッスンチケットの数`,
      `Số lượng phiếu học tập`,
    ],
    column_3: [
      `수강 기간`,
      `Expiration dates`,
      'n_mycredit.detail.column_3',
      `使用期限`,
      `使用期限`,
      `レッスン期間`,
      `Thời gian khóa học`,
    ],
    title_2: [
      `수강 내역 :`,
      `Lesson History`,
      'n_mycredit.detail.title_2',
      `课程记录`,
      `課程記錄`,
      `レッスンの内訳`,
      `1`,
    ],
    column_4: [`No.`, `No.`, 'n_mycredit.detail.column_4', `No.`, `No.`, `No.`, `No.`],
    column_5: [
      `수업 내용`,
      `Lesson topic`,
      'n_mycredit.detail.column_5',
      `课程内容`,
      `課程內容`,
      `レッスン内容`,
      `Nội dung tiết học`,
    ],
    column_6: [
      `수업 시간`,
      `Lesson date`,
      'n_mycredit.detail.column_6',
      `课程时间`,
      `課程時間`,
      `レッスン時間`,
      `Thời gian tiết học`,
    ],
  },
  exchange: {
    title: [
      `수업권 변경`,
      `Exchange Credit`,
      'n_mycredit.exchange.title',
      `变更课程券`,
      `變更課程券`,
      `レッスンチケットを変更する`,
      `Thẻ học quy đổi`,
    ],
    alret: [
      `기업 맞춤 수업권, 시간 한정 수업권, 매주 수업 듣기 수업권, 증명서 발급 이력이 있는 수업권, 보상으로 받은 당일수업권은 변경할 수 없습니다.`,
      `The following types of credits cannot be exchanged: B2B, Limited Window, Every Week, credits`,
      'n_mycredit.exchange.alret',
      `企业专属课程券、限时课程券、每周上课课程券、已发放证书的课程券、作为奖励获得的当日课程券均无法进行变更。`,
      `企業專屬課程券、限時課程券、每週上課課程券、已發行證書的課程券、作為獎勵獲得的當日課程券均無法進行變更。`,
      `企業向けレッスンチケット、時間限定レッスンチケット、毎週受講できるレッスンチケット、証明書発行履歴のあるレッスンチケット、特典で受け取った当日レッスンチケットは変更できません。`,
      ``,
    ],
    section_1_title: [
      `변경할 수업권을 선택하세요.`,
      `Select lesson credits to exchange`,
      'n_mycredit.exchange.section_1_title',
      `请选择欲变更的课程券`,
      `請選擇欲變更的課程券`,
      `変更するパッケージを選択してください。`,
      `Chọn Thẻ học bạn muốn đổi`,
    ],
    section_1_placeholder: [
      `수업권을 선택하세요.`,
      `Please select a credit.`,
      'n_mycredit.exchange.section_1_placeholder',
      `请选择课程券`,
      `請選擇課程券`,
      `レッスンチケットを選択してください。`,
      ``,
    ],
    section_2_title: [
      `변경할 수업권 개수를 선택하세요.`,
      `Select the number of credits to exchange.`,
      'n_mycredit.exchange.section_2_title',
      `请选择课程券数量`,
      `請選擇課程券數量`,
      `変更するレッスンチケットの枚数を選択してください。`,
      ``,
    ],
    section_2_placeholder: [
      `변경할 수업권을 먼저 선택하세요.`,
      `Please select which credits you wish to exchange first.`,
      'n_mycredit.exchange.section_2_placeholder',
      `请选择欲变更的课程券`,
      `請選擇欲變更的課程券`,
      `まず、変更するクラスを選択してください。`,
      ``,
    ],
    section_3_title: [
      `결과 미리보기`,
      `Result preview`,
      'n_mycredit.exchange.section_3_title',
      `预览结果`,
      `預覽結果`,
      `結果プレビュー`,
      ``,
    ],
    section_3_caption_1: [
      `변경 전`,
      `Before`,
      'n_mycredit.exchange.section_3_caption_1',
      `变更前`,
      `變更前`,
      `変更前`,
      ``,
    ],
    section_3_caption_2: [
      `수업권 변경 후`,
      `After`,
      'n_mycredit.exchange.section_3_caption_2',
      `变更后`,
      `變更後`,
      `変更後`,
      ``,
    ],
    section_3_placeholder_1: [
      `변경할 수업권을 선택하세요.`,
      `Select lesson credits to exchange`,
      'n_mycredit.exchange.section_3_placeholder_1',
      `请选择欲变更的课程券`,
      `請選擇欲變更的課程券`,
      `変更したいレッスンを選択してください。`,
      ``,
    ],
    section_3_placeholder_2: [
      `변경할 수업권 개수를 선택하세요.`,
      `Select number of credits`,
      'n_mycredit.exchange.section_3_placeholder_2',
      `请选择课程券数量`,
      `請選擇課程券數量`,
      `変更するレッスンチケットの枚数を選択してください。`,
      `Vui lòng chọn số thẻ học`,
    ],
    section_4_title: [
      `수업권 변경 안내사항을 확인해주세요.`,
      `Please check the terms for exchanging credits below.`,
      'n_mycredit.exchange.section_4_title',
      `请确认课程券变更注意事项`,
      `請確認課程券變更注意事項`,
      `レッスンチケットの変更内容をご確認ください。`,
      ``,
    ],
    section_4_desc_1: [
      `수업권을 변경하면 증명서를 발급할 수 없습니다.`,
      `Once exchanged, the credit(s) will not be eligible for certificate issuance.`,
      'n_mycredit.exchange.section_4_desc_1',
      `课程券变更后不可发行证明文件`,
      `課程券變更後不可發行證明文件`,
      `レッスンチケット変更後は証明書の発行ができません。`,
      `Không thể cấp chứng nhận sau khi thay đổi thẻ học.`,
    ],
    section_4_desc_2: [
      `이미 변경한 수업권은 환불 받을 수 없습니다.`,
      `No refund is available for exchanged credits.`,
      'n_mycredit.exchange.section_4_desc_2',
      `已经变更的课程券将无法退款`,
      `已經變更的課程券將無法退款`,
      `既に変更されたレッスンチケットは返金できません。`,
      ``,
    ],
    section_4_desc_3: [
      `수업권 변경은 패키지당 1회만 가능합니다.`,
      `Credit exchange is available only once per package.`,
      'n_mycredit.exchange.section_4_desc_3',
      `课程券变更只限1次`,
      `課程券變更只限1次`,
      `レッスンチケットの変更は1パッケージにつき1回のみ可能です。`,
      ``,
    ],
    section_4_desc_4: [
      `변경한 수업권은 원상 복구할 수 없습니다.`,
      `Credit exchange is available only once per package and cannot be reversed.`,
      'n_mycredit.exchange.section_4_desc_4',
      `课程券只限每套餐变更一次，变更后的课程券无法恢复。`,
      `課程券變更只限1次，變更後的課程券無法恢復。`,
      `レッスンチケットの変更はパッケージにつき1回まで変更可能であり、変更したレッスンチケットは元に戻すことができません。`,
      `Bạn chỉ có thể thay đổi thẻ học một lần cho mỗi Gói, thẻ học đã thay đổi sẽ không thể phục hồi lại.`,
    ],
    section_4_desc_5: [
      `장기권 혜택은 수업권 변경 시 일괄 제공되며, 변경 되기 전 수업 길이로 제공됩니다. (수강기간 : 1년)`,
      `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once.`,
      'n_mycredit.exchange.section_4_desc_5',
      `长期课程券的优惠在变更课程券时将一起提供，并以更改之前的课程时间为准。 (使用期限：1年)`,
      `長期課程券的優惠在變更課程券時將一起提供，並以更改之前的課程時間為準。 (使用期限：1年)`,
      `長期チケットの特典として提供される当日レッスンチケットは変更時に一括提供されます。`,
      `Thẻ học trong ngày được cấp dưới dạng ưu đãi của thẻ học định kỳ sẽ được cấp đồng thời khi thay đổi thẻ học.`,
    ],
    section_4_checkbox: [
      `모두 확인했습니다.`,
      `I have read and understand the above.`,
      'n_mycredit.exchange.section_4_checkbox',
      `已确认并了解上述内容`,
      `已確認並了解上述內容`,
      `すべて確認しました。`,
      ``,
    ],
    btn: [
      `수업권 변경하기`,
      `Exchage Credit`,
      'n_mycredit.exchange.btn',
      `变更课程券`,
      `變更課程券`,
      `レッスンチケットの変更`,
      ``,
    ],
    modal_title: [
      `수업권을 변경했어요.`,
      `The credits have been exchanged.`,
      'n_mycredit.exchange.modal_title',
      `变更课程券已完成`,
      `變更課程券已完成`,
      `レッスンチケットを変更しました。`,
      ``,
    ],
    modal_desc: [
      `변경된 수업권은 [내 수업권]에서, 지급 받은 포인트는 [포인트]에서 확인할 수 있습니다.`,
      `You can check the exchanged credits under [Credits] and the points you received under [Points].`,
      'n_mycredit.exchange.modal_desc',
      `变更后的课程券可在“我的课程券”中确认，获得的点数可在“点数”中确认。`,
      `變更後的課程券可在“我的課程券”中確認，獲得的點數可在“點數”中確認。`,
      `変更されたチケットは[マイチケット]で、支給されたポイントは[ポイント]で確認できます。`,
      ``,
    ],
  },
  extend: {
    title: [
      `수강기간 연장`,
      `Extend dates`,
      'n_mycredit.extend.title',
      `延长使用期限`,
      `延長使用期限`,
      `期間延長`,
      `Gia hạn`,
    ],
    section_1_title: [
      `수업권 정보 확인`,
      `Credits detail`,
      'n_mycredit.extend.section_1_title',
      `确认课程券内容`,
      `確認課程券內容`,
      `チケット料金内訳`,
      `Chi tiết thẻ học`,
    ],
    caption_1: [`수업 횟수`, `Lessons`, 'n_mycredit.extend.caption_1', `课程次数`, `課程次數`, `レッスン回数`, ``],
    caption_2: [
      `수업 시간`,
      `Lesson Duration`,
      'n_mycredit.extend.caption_2',
      `课程时间`,
      `課程時間`,
      `レッスン時間`,
      ``,
    ],
    caption_3: [`수강기간`, `Lesson Period`, 'n_mycredit.extend.caption_3', `使用期限`, `使用期限`, `受講期間`, ``],
    caption_4: [
      `결제 금액`,
      `Payment Total`,
      'n_mycredit.extend.caption_4',
      `付款金额`,
      `付款金額`,
      `お支払い金額`,
      ``,
    ],
    caption_5: [`결제일`, `Payment Date`, 'n_mycredit.extend.caption_5', `付款日期`, `付款日期`, `お支払い日`, ``],
    caption_6: [
      `결제 방식`,
      `Payment Method`,
      'n_mycredit.extend.caption_6',
      `付款方式`,
      `付款方式`,
      `お支払い方法`,
      ``,
    ],
    section_2_title: [
      `수강기간 연장 비용 확인`,
      `Credit Extension Fee`,
      'n_mycredit.extend.section_2_title',
      `确认延长使用期限费用`,
      `確認延長使用期限費用`,
      `チケット延長費用`,
      ``,
    ],
    desc_1: [`기본 요금 :`, `Base fee :`, 'n_mycredit.extend.desc_1', `基本费用`, `基本費用`, `基本料金 ：`, ``],
    desc_2: [
      `이 수업권 1개의 수강기간 1일 연장 요금 :`,
      `Per day fee for extending one credit :`,
      'n_mycredit.extend.desc_2',
      `延长一天的费用`,
      `延長一天的費用`,
      `このレッスンチケット1枚の受講期間1日延長料金：`,
      ``,
    ],
    modal_title: [
      `수강기간 연장 요금 계산 방법`,
      `Extension Fee Calculation`,
      'n_mycredit.extend.modal_title',
      `延长使用期限費用计算方法`,
      `延長使用期限費用計算方法`,
      `受講期間延長料金の計算方法`,
      ``,
    ],
    modal_desc: [
      `수업권 1개의 1회 연장 요금은 총 결제 금액을 제공하는 수업권 수로 나눈 금액의 2.1%입니다.`,
      `The per day fee for extending one credit is calculated as 2.1% of the purchased price of the credit (i.e., payment total divided by the total number of lessons).`,
      'n_mycredit.extend.modal_desc',
      `延长课程券1次的费用为总支付金额除以课程券数量后金额的2.1%`,
      `延長課程券1次的費用為總支付金額除以課程券數量後金額的2.1%`,
      `1レッスンチケットの1回の延長料金は、お支払い総額を提供するレッスンチケット数で割った金額の2.1%です。`,
      ``,
    ],
    modal_caption_1: [
      `내가 결제한 금액`,
      `Payment total`,
      'n_mycredit.extend.modal_caption_1',
      `付款金额`,
      `付款金額`,
      `支払金額`,
      ``,
    ],
    modal_caption_2: [
      `수업권 수`,
      `Number of credits`,
      'n_mycredit.extend.modal_caption_2',
      `课程券数量`,
      `課程券數量`,
      `レッスンチケット数`,
      ``,
    ],
    modal_caption_3: [
      `계산식`,
      `Calculation`,
      'n_mycredit.extend.modal_caption_3',
      `计算方式`,
      `計算方式`,
      `計算式`,
      ``,
    ],
    modal_caption_4: [
      `수업권 1개의 1회 연장 요금`,
      `Per day extension fee for one credit`,
      'n_mycredit.extend.modal_caption_4',
      `一张课程券延长一天的费用`,
      `一張課程券延長一天的費用`,
      `レッスンチケット1枚の1回延長料金`,
      ``,
    ],
    section_3_title: [
      `연장할 수업권 선택`,
      `Select Credit(s)`,
      'n_mycredit.extend.section_3_title',
      `选择欲延长的课程券`,
      `選擇欲延長的課程券`,
      `延長するレッスンの選択`,
      ``,
    ],
    column_1: [
      `수업권 / 남은 수강기간`,
      `Credit/Days to expiration`,
      'n_mycredit.extend.column_1',
      `剩馀使用期限`,
      `剩餘使用期限`,
      `受講レッスン / 残り受講期間`,
      ``,
    ],
    column_2: [
      `연장 기간`,
      `Extended lesson days`,
      'n_mycredit.extend.column_2',
      `延长使用期限`,
      `延長使用期限`,
      `延長期間`,
      `Số ngày gia hạn`,
    ],
    column_3: [`연장 비용`, `Fee`, 'n_mycredit.extend.column_3', `延长费用`, `延長費用`, `延長費用`, ``],
    section_4_title: [`결제하기`, `Payment`, 'n_mycredit.extend.section_4_title', `付款`, `付款`, `支払いをする`, ``],
    note: [
      `결제 대행사 정책으로 포인트 사용 없이 1,000원 이상 결제 해야 합니다.`,
      `Payments must be $1 or above (without using points) due to the payment agency's policies.`,
      'n_mycredit.extend.note',
      `根据金流供应商的政策，必须支付1,000韩元或以上的金额，才能使用积分。`,
      `根據金流供應商的政策，必須支付1,000韓元或以上的金額，才能使用點數。`,
      `決済代行会社のポリシー上、ポイントを使用せずに1,000ウォン以上決済する必要があります。`,
      ``,
    ],
    step_1_title: [
      `포인트 사용`,
      `Points applied`,
      'n_mycredit.extend.step_1_title',
      `使用积分`,
      `使用點數`,
      `ポイント利用`,
      `Điểm được áp dụng`,
    ],
    step_1_placeholder: [
      `사용할 포인트를 입력하세요.`,
      `Enter amount`,
      'n_mycredit.extend.step_1_placeholder',
      `请输入欲使用的积分`,
      `請輸入欲使用的點數`,
      `使用するポイントを入力してください。`,
      ``,
    ],
    step_1_caption: [
      `보유 포인트`,
      `My points`,
      'n_mycredit.extend.step_1_caption',
      `持有积分`,
      `持有點數`,
      `保有ポイント`,
      `Điểm của bạn`,
    ],
    step_1_btn: [
      `최대 사용`,
      `Use all`,
      'n_mycredit.extend.step_1_btn',
      `全部使用`,
      `全部使用`,
      `すべて利用`,
      `Sử dụng hết`,
    ],
    step_2_title: [
      `결제 방식`,
      `Payment type`,
      'n_mycredit.extend.step_2_title',
      `付款方式`,
      `付款方式`,
      `お支払い方法`,
      ``,
    ],
    step_2_option_1: [
      `국내결제`,
      `Domestic (Korean)`,
      'n_mycredit.extend.step_2_option_1',
      `韩国国内付款`,
      `韓國國內付款`,
      `国内決済`,
      ``,
    ],
    step_2_option_2: [
      `해외결제`,
      `Overseas (Local)`,
      'n_mycredit.extend.step_2_option_2',
      `海外付款`,
      `海外付款`,
      `海外決済`,
      ``,
    ],
    step_3_title: [
      `결제 수단`,
      `Payment method`,
      'n_mycredit.extend.step_3_title',
      `付款方式`,
      `付款方式`,
      `お支払い方法`,
      ``,
    ],
    step_3_option_1: [
      `카드 일반 결제`,
      `Credit card`,
      'n_mycredit.extend.step_3_option_1',
      `银行卡一般付款`,
      `一般信用卡`,
      `カード一般決済`,
      `Thẻ tín dụng`,
    ],
    step_3_option_2: [
      `카드 직접 입력`,
      `Credit card (manual)`,
      'n_mycredit.extend.step_3_option_2',
      `银行卡直接输入`,
      `手動輸入信用卡號碼`,
      `カード直接入力`,
      `Thẻ tín dụng`,
    ],
    step_3_option_3: [
      `카카오페이`,
      `KakaoPay`,
      'n_mycredit.extend.step_3_option_3',
      `KakaoPay`,
      `KakaoPay`,
      `KakaoPay`,
      `KakaoPay`,
    ],
    step_3_option_4: [
      `삼성페이`,
      `SamsungPay`,
      'n_mycredit.extend.step_3_option_4',
      `SamsungPay`,
      `SamsungPay`,
      `SamsungPay`,
      `SamsungPay`,
    ],
    step_3_option_5: [`PAYCO`, `PAYCO`, 'n_mycredit.extend.step_3_option_5', `PAYCO`, `PAYCO`, `PAYCO`, `PAYCO`],
    step_3_option_6: [
      `토스페이`,
      `Toss Pay`,
      'n_mycredit.extend.step_3_option_6',
      `Toss Pay`,
      `Toss Pay`,
      `Toss Pay`,
      ``,
    ],
    step_3_option_7: [
      `네이버페이(카드결제)`,
      `Naver Pay`,
      'n_mycredit.extend.step_3_option_7',
      `Naver Pay`,
      `Naver Pay`,
      `Naver Pay(カード決済)`,
      ``,
    ],
    step_4_title: [
      `결제 금액`,
      `Subtotal`,
      'n_mycredit.extend.step_4_title',
      `付款金额`,
      `付款金額`,
      `決済金額`,
      `Tổng`,
    ],
    step_4_caption_1: [
      `수업권 연장 비용`,
      `Credit extension fee`,
      'n_mycredit.extend.step_4_caption_1',
      `延长课程券费用`,
      `延長課程券費用`,
      `チケット延長費用`,
      ``,
    ],
    step_4_caption_2: [
      `기본 비용`,
      `Base fee`,
      'n_mycredit.extend.step_4_caption_2',
      `基本费用`,
      `基本費用`,
      `基本費用`,
      ``,
    ],
    step_4_caption_3: [
      `포인트 사용`,
      `Points used`,
      'n_mycredit.extend.step_4_caption_3',
      `使用积分`,
      `使用點數`,
      `ポイント使用`,
      ``,
    ],
    step_4_caption_4: [
      `최종 결제 금액`,
      `Your total`,
      'n_mycredit.extend.step_4_caption_4',
      `最终付款金额`,
      `最終付款金額`,
      `最終決済金額`,
      `Tổng cộng`,
    ],
    step_4_checkbox: [
      <>
        결제 내역을 확인하였으며{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline underline-offset-1 text-purple-500">전자상거래법 및 링글 정책</span>
        </LinkLocale>
        에 의거하여 환불이 진행되는 것에 동의합니다.
      </>,
      <>
        I confirm my purchase and agree to{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline underline-offset-1 text-purple-500">Ringle's terms and conditions</span>
        </LinkLocale>
        .
      </>,
      'n_mycredit.extend.step_4_checkbox',
      <>
        已确认付款明细，同意依据{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">电子商务交易法及Ringle政策</span>
        </LinkLocale>
        进行退款。{' '}
      </>,
      <>
        已確認付款明細，同意依據{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">電子商務交易法及Ringle政策</span>
        </LinkLocale>
        進行退款。
      </>,
      <>
        決済履歴を確認し、{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">電子商取引法およびRingleのポリシー</span>
        </LinkLocale>
        に基づいて返金が行われることに同意します。
      </>,
      <>
        Tôi xác nhận thanh toán của mình và đồng ý với{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">Điều khoản và điều kiện của Ringle</span>
        </LinkLocale>
        .
      </>,
    ],
    btn: [`결제하기`, ``, 'n_mycredit.extend.btn', `进行付款`, `進行付款`, `支払いをする`, ``],
    popup_title: [
      `수강기간이 연장되었어요.`,
      `Extensions applied`,
      'n_mycredit.extend.popup_title',
      `已延长课程券使用期限`,
      `已延長課程券使用期限`,
      `受講期間が延長されました。`,
      `Thời gian đã được gia hạn.`,
    ],
    popup_desc: [
      `수업권 상세 내역에서 연장된 수강기간을 확인하세요.`,
      `Please check the extended dates of the credit(s).`,
      'n_mycredit.extend.popup_desc',
      `请于课程券详情确认延长期限`,
      `請於課程券詳情確認延長期限`,
      `レッスンチケット/証明書類ページで延長されたレッスンチケットの期間をご確認ください。`,
      `Vui lòng kiểm tra thời gian trên trang học.`,
    ],
  },
}
export const n_invite = {
  title: [`친구초대`, `Invite`, 'n_invite.title', `邀请朋友`, `邀請朋友`, `友達に紹介`, ``],
}
export const n_home = {
  title: [`홈`, `Home`, 'n_home.title', `首页`, `首頁`, `ホーム画面`, ``],
  trial_lesson: {
    title: [
      <>
        링글의 첫 수업,
        <br />
        커피 한 잔 가격으로 체험하세요.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      'n_home.trial_lesson.title',
      <>
        您的第一堂Ringle课程，
        <br />
        仅需一杯咖啡的价格。
      </>,
      <>
        您的第一堂Ringle課程，
        <br />
        僅需一杯咖啡的價格。
      </>,
      <>
        Ringleでの初レッスン、
        <br />
        コーヒー1杯の値段で体験できます。
      </>,
      <>
        Bài học đầu tiên của Ringle,
        <br />
        trải nghiệm với mức giá hạt dẻ
      </>,
    ],
    time: (time) => [
      <>체험권은 {time} 후 사라져요. </>,
      <>Trial lesson disappears in {time}.</>,
      'n_home.trial_lesson.time',
      <>体验券将在 {time} 后消失。</>,
      <>體驗課程券將在 {time} 後消失。</>,
      <>体験券は {time} 後に消えます。</>,
      <>Vé trải nghiệm sẽ mất sau {time}.</>,
    ],
    msg: [`수업을 완료하면 추가 포인트를 받아요.`, ``, 'n_home.trial_lesson.msg', ``, ``, ``, ``],
    btn: [
      `체험수업 신청하기`,
      `Start now with a trial lesson`,
      'n_home.trial_lesson.btn',
      `申请体验课程`,
      `申請體驗課程`,
      `体験授業の申し込み`,
      `Đăng ký trải nghiệm`,
    ],
  },
  lesson: {
    lesson: [
      <>
        예정된 수업이 없어요.
        <br />
        지금 바로 수업을 예약해보세요!
      </>,
      <>
        You have no scheduled lesson.
        <br />
        Why don't you book one?
      </>,
      'n_home.lesson.lesson',
      <>
        没有预定的课程。
        <br />
        请预约课程。
      </>,
      <>
        沒有預約的課程。
        <br />
        請預約課程。
      </>,
      <>
        予定されたレッスンはありません。
        <br />
        レッスンを予約してみましょう！
      </>,
      <>
        Tôi không có một buổi học theo lịch trình. <br />
        Đặt một buổi học!
      </>,
    ],
    msg_prep_title: [
      `예정된 수업`,
      `Upcoming Lesson`,
      'n_home.lesson.msg_prep_title',
      `即将开始的课程`,
      `即將開始的課程`,
      `予定されたレッスン`,
      `Các buổi học theo lịch trình`,
    ],
    msg_prep: [
      `효과적인 수업을 위해 미리 준비해보세요.`,
      `Prepare in advance for effective learning.`,
      'n_home.lesson.msg_prep',
      `请进行课前准备以便有好的学习效率`,
      `請進行課前準備以便有好的學習效率`,
      `効果的なレッスンのために事前に準備しておきましょう。`,
      ``,
    ],
    msg_enter_title: [
      `곧 수업 시작`,
      `The lesson is about to start`,
      'n_home.lesson.msg_enter_title',
      `课程即将开始`,
      `課程即將開始`,
      `もうすぐレッスン開始`,
      ``,
    ],
    msg_enter: [
      `수업에 입장해주세요.`,
      `Please enter the lesson.`,
      'n_home.lesson.msg_enter',
      `请进入课程`,
      `請進入課程`,
      `レッスンに入ってください。`,
      `Hãy tham gia buổi học.`,
    ],
    title: [`1:1 수업`, `1:1 Lesson`, 'n_home.lesson.title', `1:1 Lesson`, `1:1 Lesson`, `1:1 Lesson`, `1:1 Lesson`],
    trial: [
      <>
        커피 한 잔 가격에
        <br /> 1:1 수업을 체험해보세요.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      'n_home.lesson.trial',
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
      <>
        Your first lesson with Ringle,
        <br />
        just for the price of a cup of coffee.
      </>,
    ],
    purchase: [
      <>
        최대 33% 할인부터
        <br />
        웨비나까지
      </>,
      ``,
      'n_home.lesson.purchase',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_trial: [
      `체험 수업 예약하기`,
      `Book a trial lesson`,
      'n_home.lesson.btn_trial',
      `Book a trial lesson`,
      `Book a trial lesson`,
      `Book a trial lesson`,
      ``,
    ],
    btn_purchase: [
      `수업권 둘러보기`,
      `View deals`,
      'n_home.lesson.btn_purchase',
      `View deals`,
      `View deals`,
      `View deals`,
      ``,
    ],
    empty: [
      `예정된 수업이 없어요.`,
      `You have no upcoming lessons.`,
      'n_home.lesson.empty',
      `You have no upcoming lessons.`,
      `You have no upcoming lessons.`,
      `You have no upcoming lessons.`,
      ``,
    ],
  },
  live: {
    btn: [`라이브 입장`, `Enter Live lecture`, 'n_home.live.btn', `进入LIVE课程`, `進入LIVE課程`, `LIVEに参加`, ``],
  },
  to_do: {
    title: [
      `링글이 처음이라면 아래 목록을 진행해보세요!`,
      `If you're new to Ringle, check out the list below!`,
      'n_home.to_do.title',
      `如果您是第一次使用Ringle，请按照下面指示进行操作。`,
      `如果您是第一次使用Ringle，請按照下面指示進行操作。`,
      `Ringleを初めてご利用であれば、以下をリストご確認ください！`,
      `Nếu bạn mới sử dụng Ringle, hãy tiếp tục với danh sách bên dưới!`,
    ],
    btn1: [
      `링글 사용법 알아보기`,
      `How to use Ringle`,
      'n_home.to_do.btn1',
      `了解Ringle使用方法`,
      `了解Ringle使用方法`,
      `Ringleの使用方法を調べる`,
      `Tìm hiểu cách sử dụng Ringle`,
    ],
    label1: [
      `5,000P 적립`,
      `Get $3.84 points`,
      'n_home.to_do.label1',
      `获得价值$3.84积分`,
      `獲得價值$3.84點數`,
      `5,000P獲得`,
      ``,
    ],
    label1_20: [
      `5,000P 적립`,
      `Get $3.85 points`,
      'n_home.to_do.label1_20',
      `获得价值$3.85积分`,
      `獲得價值$3.85點數`,
      `5,000P獲得`,
      ``,
    ],
    btn2: [
      `관심 주제 설정하기`,
      `Set your topics of interest`,
      'n_home.to_do.btn2',
      `设定您有兴趣的主题`,
      `設定您有興趣的主題`,
      `興味のあるトピックを設定する`,
      ``,
    ],
    label2: [
      `10,000P 적립`,
      `Get $7.69 points`,
      'n_home.to_do.label2',
      `获得价值$7.69积分`,
      `獲得價值$7.69點數`,
      `10,000P獲得`,
      ``,
    ],
    btn3: [
      `OT 신청하기`,
      `Register Orientation`,
      'n_home.to_do.btn3',
      `申请课前说明会`,
      `申請課前說明會`,
      `OTを申請する`,
      `Đăng ký OT`,
    ],
    btn4: [
      `튜터 찜하기`,
      `Add tutors to Favorite Tutors`,
      'n_home.to_do.btn4',
      `将老师加入收藏的教师`,
      `將老師加入收藏的教師`,
      `レッスンを受けたいチューターをいいねする`,
      `Hấp dẫn gia sư bạn muốn dạy`,
    ],
    trial_lesson_title: [
      <>
        링글 수업을 위한
        <br />
        4가지 준비 영상을 모두 확인하면
        <br />
        <span className="text-[#503CC8]">5,000 포인트</span>를 드려요.
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
      'n_home.to_do.trial_lesson_title',
      <>
        观看Ringle上课介绍影片
        <br />
        <br />
        获得 <span className="text-[#503CC8]">价值$3.84积分</span>.
      </>,
      <>
        觀看Ringle上課介紹影片
        <br />
        <br />
        獲得 <span className="text-[#503CC8]">價值$3.84點數</span>.
      </>,
      <>
        Ringleレッスンのための
        <br />
        4つの準備映像を全て確認すると
        <br />
        <span className="text-[#503CC8]">5,000 ポイント</span>をプレゼントします。
      </>,
      <>
        Watch four videos
        <br />
        on tips for Ringle lessons
        <br />
        and get <span className="text-[#503CC8]">$3.84 points</span>.
      </>,
    ],
    trial_lesson_tip1: [`Tip1`, `Tip1`, 'n_home.to_do.trial_lesson_tip1', `Tip1`, `Tip1`, `Tip1`, `Tip1`],
    trial_lesson_tip2: [`Tip2`, `Tip2`, 'n_home.to_do.trial_lesson_tip2', `Tip2`, `Tip2`, `Tip2`, `Tip2`],
    trial_lesson_tip3: [`Tip3`, `Tip3`, 'n_home.to_do.trial_lesson_tip3', `Tip3`, `Tip3`, `Tip3`, `Tip3`],
    trial_lesson_tip4: [`Tip4`, `Tip4`, 'n_home.to_do.trial_lesson_tip4', `Tip4`, `Tip4`, `Tip4`, `Tip4`],
    trial_lesson_btn: [
      `포인트 받기`,
      `Get points`,
      'n_home.to_do.trial_lesson_btn',
      `获取积分`,
      `獲得點數`,
      `ポイントゲット`,
      `Nhận điểm`,
    ],
    trial_lesson_toast: [
      `5,000 포인트가 적립되었습니다.`,
      `You have received $3.84 points.`,
      'n_home.to_do.trial_lesson_toast',
      `您已获得$3.84积分`,
      `您已獲得$3.84點數`,
      `5,000ポイントが支給されました。`,
      `Bạn đã được 5,000 điểm`,
    ],
  },
  material_topics: {
    title: [
      `관심 있는 주제로 수업해보세요.`,
      `Take lessons on your topics of interest.`,
      'n_home.material_topics.title',
      `挑选您有兴趣的主题进行课程`,
      `挑選您有興趣的主題進行課程`,
      `興味のあるテーマでレッスンを受けてみてください。`,
      ``,
    ],
  },
  material_guide: {
    title: [
      `링글이 처음이신가요?`,
      `Recommended first-reads`,
      'n_home.material_guide.title',
      `为第一次使用Ringle的新手推荐`,
      `為第一次使用Ringle的新手推薦`,
      `はじめての方におすすめ`,
      ``,
    ],
  },
  material_top: {
    title: [
      `링글러가 많이 선택한 교재로 수업해보세요.`,
      `Check out Ringler's Top Pick.`,
      'n_home.material_top.title',
      `确认Ringler推荐的内容`,
      `確認Ringler推薦的內容`,
      `多くのRinglerが選んだ教材でレッスンしてみましょう。`,
      ``,
    ],
  },
  info: {
    hello: (name) => [
      <>안녕하세요 {name}님!</>,
      <>Hello {name}!</>,
      'n_home.info.hello',
      <>您好，{name}用户！</>,
      <>您好，{name}用戶！</>,
      <>こんにちは、 {name}さん！</>,
      <>Xin chào {name}!</>,
    ],
    btn_profile: [
      `프로필 설정`,
      `Edit Profile`,
      'n_home.info.btn_profile',
      `设定个人档案`,
      `設定個人檔案`,
      `プロフィール設定`,
      `Chỉnh sửa hồ sơ`,
    ],
  },
  favorites_tutor: {
    title: [
      `찜한 튜터가 스케줄을 업데이트 했어요.`,
      `Your favorite tutor has updated their schedule.`,
      'n_home.favorites_tutor.title',
      `您收藏的教师已更新开课时间`,
      `您收藏的教師已更新開課時間`,
      `お気に入りのチューターのスケジュールが更新されました。`,
      ``,
    ],
    btn: [
      `찜한 튜터`,
      `Favorite Tutors`,
      'n_home.favorites_tutor.btn',
      `收藏的教师`,
      `收藏的教師`,
      `お気に入りのチューター`,
      ``,
    ],
    slot: [
      `예약 가능 슬랏:`,
      `Available slots:`,
      'n_home.favorites_tutor.slot',
      `可预约的时段`,
      `可預約的時段`,
      `予約可能枠：`,
      ``,
    ],
    tooltip: [
      `튜터 프로필에서 바로 수업을 예약할 수 있어요.`,
      `Book your lesson directly from the tutor's profile.`,
      'n_home.favorites_tutor.tooltip',
      `从教师个人档案预约课程`,
      `從教師個人檔案預約課程`,
      `講師のプロフィールから直接レッスンを予約することができます。`,
      ``,
    ],
  },
  featured_tutor: {
    title: [
      `링글러가 선호하는 추천 튜터를 만나보세요.`,
      `Check out our Featured Tutors.`,
      'n_home.featured_tutor.title',
      `请确认我们推荐的教师`,
      `請確認我們推薦的教師`,
      `Ringlerが選ぶおすすめのチューターをご紹介します。`,
      ``,
    ],
    btn: [
      `전체 튜터`,
      `View all Tutors`,
      'n_home.featured_tutor.btn',
      `全部教师`,
      `全部教師`,
      `すべてのチューター`,
      ``,
    ],
  },
  reading_programs: (number) => [
    `신청 가능한 웨비나(${number})`,
    `Webinars you can join(${number})`,
    'n_home.reading_programs',
    `Webinars you can join(${number})`,
    `Webinars you can join(${number})`,
    `Webinars you can join(${number})`,
    ``,
  ],
  quick: [`바로가기`, `Quick menu`, 'n_home.quick', `快速传送门`, `快速傳送門`, `クイックメニュー`, ``],
  challenge: [
    `전체 챌린지`,
    `View All Challenges`,
    'n_home.challenge',
    `确认所有挑战`,
    `確認所有挑戰`,
    `すべてのチャレンジを見る`,
    ``,
  ],
  tutor: (number) => [
    `전체 링글 튜터(${number})`,
    `View All Tutors(${number})`,
    'n_home.tutor',
    `确认所有教师(${number})`,
    `確認所有教師(${number})`,
    `すべてのチューターを見る(${number})`,
    ``,
  ],
  tutor_tab: {
    favorites: [`찜한 튜터`, `Favorite`, 'n_home.tutor_tab.favorites', `收藏的教师`, `收藏的教師`, `お気に入り`, ``],
    featured: [
      `추천 튜터`,
      `Featured`,
      'n_home.tutor_tab.featured',
      `Ringle推荐教师`,
      `Ringle推薦教師`,
      `Ringleのおすすめ`,
      ``,
    ],
    new: [`신규 튜터`, `Rookie`, 'n_home.tutor_tab.new', `Ringle新进教师`, `Ringle新進教師`, `新規チューター`, ``],
  },
  tutor_btn: {
    favorites: [
      `찜한 튜터 보기`,
      `View Favorite`,
      'n_home.tutor_btn.favorites',
      `确认收藏的教师`,
      `確認收藏的教師`,
      `お気に入りを見る`,
      ``,
    ],
    featured: [
      `추천 튜터 보기`,
      `View Featured`,
      'n_home.tutor_btn.featured',
      `确认Ringle推荐教师`,
      `確認Ringle推薦教師`,
      `Ringleのおすすめを見る`,
      ``,
    ],
  },
  tutor_placeholder: {
    favorites: [
      <>
        아래 ‘전체 링글 튜터’에서 마음에 드는 튜터를 찜해보세요!
        <br />
        스케줄이 업데이트되면 알려드려요.
      </>,
      <>
        Click 'View All Tutors' below to add tutors you like to Favorite
        <br />
        and get notified when they update their schedules!
      </>,
      'n_home.tutor_placeholder.favorites',
      <>
        在“确认所有教师”中收藏教师！
        <br />
        教师的开课日期更新时将发送提醒通知
      </>,
      <>
        在“確認所有教師”中收藏教師！
        <br />
        教師的開課日期更新時將發送提醒通知
      </>,
      `「すべてのチューターを見る」をクリックすると、好きな講師を「お気に入り」に追加でき、スケジュールが更新されると通知が届きます！`,
      ``,
    ],
    new: [
      <>
        지금은 신규 튜터가 없어요.
        <br />
        아래 ‘튜터 모두 보기’에서 모든 튜터를 만나보세요.
      </>,
      <>
        There are no rookie tutors available.
        <br />
        Click 'View All Tutors' below to meet all tutors.
      </>,
      'n_home.tutor_placeholder.new',
      <>
        暂时没有新进教师可用。
        <br />
        点击下面的“查看所有导师”来见到所有的导师。
      </>,
      <>
        目前沒有新進教師可用。
        <br />
        點擊下方的「查看所有導師」來見到所有的導師。
      </>,
      <>
        新規チューターは現在利用できません。
        <br />
        すべてのチューターに会いに行くには、下の「すべてのチューターを見る」をクリックしてください。
      </>,
      ``,
    ],
  },
  tutor_desc: {
    featured: [
      `추천 튜터와 수업 후 불만족 시 수업권을 100% 복구 해드려요.`,
      `100% money-back guarantee if unsatisfied with a featured tutor!`,
      'n_home.tutor_desc.featured',
      `与推荐的教师上课后，如对课程不满意，可退还课程券！`,
      `與推薦的教師上課後，如對課程不滿意，可退還課程券！`,
      `レッスン後にご満足いただけない場合、100%返金いたします！`,
      ``,
    ],
    new: [
      `새로 온 튜터와 수업하고 후기를 남기면 수업료의 50%를 포인트로 돌려드려요.`,
      `Take a lesson with a rookie tutor and get 50% value back after leaving a review.`,
      'n_home.tutor_desc.new',
      `与新进教师上课后，如对课程不满意，留下课后评论后，将获得与课程价格50%相同价值的积分`,
      `與新進教師上課後，如對課程不滿意，留下課後評論後，將獲得與課程價格50%相同價值的點數`,
      `新規チューターのレッスンを受講し、レビューを残すとレッスンチケット代の50％分のポイントが還元されます。`,
      ``,
    ],
  },
  material: (number) => [
    `전체 링글 교재(${number})`,
    `View All Materials(${number})`,
    'n_home.material',
    `‘确认所有教材(${number})`,
    `‘確認所有教材(${number})`,
    `すべての教材を見る(${number})`,
    ``,
  ],
  material_tab: {
    today: [`새로나온 교재`, `Latest Materials`, 'n_home.material_tab.today', `最新教材`, `最新教材`, `最新の教材`, ``],
    top: [
      `링글러 Top pick`,
      `Ringlers' Top Pick`,
      'n_home.material_tab.top',
      `Ringle用户推荐`,
      `Ringle用戶推薦`,
      `Ringlerの一番人気`,
      ``,
    ],
    interest: [
      `관심 주제`,
      `Topics of interest`,
      'n_home.material_tab.interest',
      `有兴趣的主题`,
      `有興趣的主題`,
      `興味のあるトピックス`,
      ``,
    ],
  },
  material_desc: {
    today: [
      `매일 업데이트 되는 교재로 수업해보세요.`,
      `Read new materials released every week.`,
      'n_home.material_desc.today',
      `请用每天更新的教材进行课程。`,
      `請用每天更新的教材進行課程。`,
      `毎日アップデートされる新しい教材をチェックしてみましょう。`,
      ``,
    ],
  },
  material_btn: {
    today: [
      `새로나온 교재 보기`,
      `View Latest Materials`,
      'n_home.material_btn.today',
      `确认最新教材`,
      `確認最新教材`,
      `最新の教材を見る`,
      ``,
    ],
    top: [
      `링글러 Top pick 보기`,
      `View Ringlers' Top Pick`,
      'n_home.material_btn.top',
      `确认Ringle用户推荐`,
      `確認Ringle用戶推薦`,
      `Ringlerの一番人気を見る`,
      ``,
    ],
  },
  material_placeholder: {
    interest: [
      <>
        프로필에서 관심 주제 설정하고
        <br />꼭 맞는 교재를 추천 받아보세요!
      </>,
      <>
        Set up your profile with topics of interest
        <br />
        to get material recommendations!
      </>,
      'n_home.material_placeholder.interest',
      <>
        在个人档案设定有兴趣的主题
        <br />
        获得与有兴趣的主题相关的教材推荐!
      </>,
      <>
        在個人檔案設定有興趣的主題
        <br />
        獲得與有興趣的主題相關的教材推薦!
      </>,
      `おすすめの教材を提案してもらうために、プロフィールの興味のあるトピックスを設定しましょう！`,
      ``,
    ],
  },
  challenges: {
    title: [
      `내 챌린지를 확인하세요.`,
      `Check out my challenges`,
      'n_home.challenges.title',
      `确认我的挑战`,
      `確認我的挑戰`,
      `マイチャレンジをご確認ください。`,
      ``,
    ],
    btn: [`챌린지`, `Challenges`, 'n_home.challenges.btn', `挑战`, `挑戰`, `チャレンジ`, ``],
  },
  materials: {
    title: [
      `교재 전체보기`,
      `View all Materials`,
      'n_home.materials.title',
      `看全部教材`,
      `看全部教材`,
      `教材一覧`,
      ``,
    ],
  },
  lesson_cancel: {
    tutor: {
      title: [
        `새로운 튜터가 매칭됐어요.`,
        `A new tutor has been matched.`,
        'n_home.lesson_cancel.tutor.title',
        `A new tutor has been matched.`,
        `A new tutor has been matched.`,
        `A new tutor has been matched.`,
        ``,
      ],
      desc: (time) => [
        <>튜터의 사정으로 {time} 수업이 취소되어 새로운 튜터가 매칭됐어요.</>,
        <>The lesson scheduled for {time} has been canceled, and a new tutor has been matched.</>,
        'n_home.lesson_cancel.tutor.desc',
        <>The lesson scheduled for {time} has been canceled, and a new tutor has been matched.</>,
        <>The lesson scheduled for {time} has been canceled, and a new tutor has been matched.</>,
        <>The lesson scheduled for {time} has been canceled, and a new tutor has been matched.</>,
        ``,
      ],
    },
    credit: {
      title: [
        `수업권이 복구됐어요.`,
        `Your credit have been restored.`,
        'n_home.lesson_cancel.credit.title',
        `Your credit have been restored.`,
        `Your credit have been restored.`,
        `Your credit have been restored.`,
        ``,
      ],
      desc: (time) => [
        <>튜터의 사정으로 {time} 수업이 취소되어 수업권이 복구됐어요.</>,
        <>The lesson scheduled for {time} has been canceled, and credit have been restored.</>,
        'n_home.lesson_cancel.credit.desc',
        <>The lesson scheduled for {time} has been canceled, and credit have been restored.</>,
        <>The lesson scheduled for {time} has been canceled, and credit have been restored.</>,
        <>The lesson scheduled for {time} has been canceled, and credit have been restored.</>,
        ``,
      ],
    },
  },
  todo_credit: {
    title: [
      `지금 링글 수업을 시작해 보세요`,
      `You have an unused credit`,
      'n_home.todo_credit.title',
      `有可以马上预约课程的课程券`,
      `有可以馬上預約課程的課程券`,
      `有可以馬上預約課程的課程券`,
      ``,
    ],
    sub: [
      `포인트 받고 첫 수업까지 예약해 보세요!`,
      `Get points and even book your first lesson!`,
      'n_home.todo_credit.sub',
      `领取点数并预约第一堂课！`,
      `領取點數並預約第一堂課！`,
      `ポイントを獲得して、最初のレッスンを予約してみましょう！`,
      ``,
    ],
    list_1: [
      `링글 사용법 보고 5,000P 받기`,
      `Read [How to use Ringle] and get $7.69`,
      'n_home.todo_credit.list_1',
      `确认Ringle使用方法获得$7.69积分`,
      `確認Ringle使用方法獲得$7.69點數`,
      `Ringleの使い方を確認して$7.69獲得`,
      ``,
    ],
    list_2: [
      `나와 딱 맞는 튜터와 수업 예약하기`,
      `Book first lesson with a perfect-fit tutor`,
      'n_home.todo_credit.list_2',
      `预约可协助我达到学习目标的教师`,
      `預約可協助我達到學習目標的教師`,
      `自分にピッタリの講師を選んで初回のレッスンを予約する`,
      ``,
    ],
    list_3: [
      `추천 코스로 한 달 수업 예약하기`,
      `Book curated lessons for first month`,
      'n_home.todo_credit.list_3',
      `预约一个月的课程进行持续学习`,
      `預約一個月的課程進行持續學習`,
      `継続学習のために1ヶ月分のレッスンを予約する`,
      ``,
    ],
    caption: [
      `첫 수업 예약 후 완료할 수 있어요!`,
      `Book your first lesson first!`,
      'n_home.todo_credit.caption',
      `预约第一堂课后可进行`,
      `預約第一堂課後可進行`,
      `初回レッスン終了後に完了になります`,
      ``,
    ],
    complete_title: [
      `꾸준한 영어 공부로 원하는 목표를 이루세요!`,
      `Study English consistently to achieve your goals!`,
      'n_home.todo_credit.complete_title',
      `持续学习英文，实现自己的目标！`,
      `持續學習英文，實現自己的目標！`,
      `目標達成するために学習を継続しましょう！`,
      ``,
    ],
  },
  new: {
    tutor_title_1: [
      <>
        영어 공부 목적을 선택하면
        <br />딱 맞는 튜터를 찾아드려요!
      </>,
      <>
        Find the perfect tutor
        <br />
        for your English needs!
      </>,
      'n_home.new.tutor_title_1',
      <>
        根据英语学习目标
        <br />
        选择适合的教师！
      </>,
      <>
        根據英文學習目標
        <br />
        選擇適合的教師！
      </>,
      <>
        あなたのニーズに合わせて
        <br />
        最適なチューターを探しましょう！
      </>,
      ``,
    ],
    tutor_btn_1: [`튜터 찾기`, `Find tutor`, 'n_home.new.tutor_btn_1', `搜寻教师`, `搜尋教師`, `検索する`, ``],
    title: [`신규 고객 한정`, `Newcomers Only`, 'n_home.new.title', `只限新学员`, `只限新學員`, `新規ユーザー限定`, ``],
    reward: [
      <>
        수업 4회 완료 시<br />
        AI 스피킹 파트너 이용권 무료 증정
      </>,
      <>
        Complete 4 lessons and get
        <br />
        free AI Speaking Partner tickets!
      </>,
      'n_home.new.reward',
      <>
        完成4堂课时
        <br />
        即可获得免费AI口说练习券
      </>,
      <>
        完成4堂課時
        <br />
        即可獲得免費AI口說練習券
      </>,
      <>
        AI Speaking Partnerの無料チケットを
        <br />
        4回レッスンを受講して手に入れよう！
      </>,
      ``,
    ],
    tutor_title_2: (user_name) => [
      <>
        {user_name}님께 딱 맞는
        <br />
        튜터를 찾았어요!
      </>,
      <>
        {user_name}, we found
        <br />
        the perfect tutor for you!
      </>,
      'n_home.new.tutor_title_2',
      <>
        推荐给{user_name}
        <br />
        符合学习需求的教师！
      </>,
      <>
        推薦給{user_name}
        <br />
        符合學習需求的教師！
      </>,
      <>
        {user_name}さん,あなたに
        <br />
        最適なチューターを見つけました
      </>,
      ``,
    ],
    tutor_btn_2: [`확인하기`, `View`, 'n_home.new.tutor_btn_2', `开始寻找`, `開始尋找`, `見る`, ``],
  },
  webinar_label: [
    `2024 MBA, 해외 유학전`,
    `2024 MBA, Study Abroad`,
    'n_home.webinar_label',
    `2024 MBA, Study Abroad`,
    `2024 MBA, Study Abroad`,
    `2024 MBA, Study Abroad`,
    ``,
  ],
  daily_study: {
    title: [`데일리 학습`, `Daily Study`, 'n_home.daily_study.title', `Daily Study`, `Daily Study`, `Daily Study`, ``],
    title_n: [
      `데일리 학습`,
      `Daily Study`,
      'n_home.daily_study.title_n',
      `Daily Study`,
      `Daily Study`,
      `Daily Study`,
      ``,
    ],
  },
  message: {
    rest_crd: [
      `1:1 수업이 취소되어, 수업권이 복구됐어요.`,
      `Your credit for the cancelled lesson has been restored.`,
      'n_home.message.rest_crd',
      `Your credit for the cancelled lesson has been restored.`,
      `Your credit for the cancelled lesson has been restored.`,
      `Your credit for the cancelled lesson has been restored.`,
      `Your credit for the cancelled lesson has been restored.`,
    ],
    cha_tu: [
      `1:1 수업 튜터가 변경되었어요.`,
      `Your tutor has been changed.`,
      'n_home.message.cha_tu',
      `Your tutor has been changed.`,
      `Your tutor has been changed.`,
      `Your tutor has been changed.`,
      `Your tutor has been changed.`,
    ],
  },
  aispa: {
    title: [`AI 튜터`, `AI Tutor`, 'n_home.aispa.title', `AI Tutor`, `AI Tutor`, `AI Tutor`, ``],
    label_trial: [
      `맞춤 추천 학습`,
      `Ringle's Pick`,
      'n_home.aispa.label_trial',
      `Ringle's Pick`,
      `Ringle's Pick`,
      `Ringle's Pick`,
      ``,
    ],
    label_study: [`표현 학습`, `Expression`, 'n_home.aispa.label_study', `Expression`, `Expression`, `Expression`, ``],
    btn_start: [`시작하기`, `Start`, 'n_home.aispa.btn_start', `Start`, `Start`, `Start`, ``],
    date: (N) => [`${N}일`, `Day ${N}`, 'n_home.aispa.date', `Day ${N}`, `Day ${N}`, `Day ${N}`, ``],
    do: [
      `오늘의 학습을 해주세요!`,
      `Let's do today's AI study!`,
      'n_home.aispa.do',
      `Let's do today's AI study!`,
      `Let's do today's AI study!`,
      `Let's do today's AI study!`,
      ``,
    ],
    done: [
      `오늘의 학습을 완료했어요!`,
      `Today's AI study completed!`,
      'n_home.aispa.done',
      `Today's AI study completed!`,
      `Today's AI study completed!`,
      `Today's AI study completed!`,
      ``,
    ],
    remain_time: (amount) => [
      `${amount} 후 자동 종료`,
      `Ends in ${amount}`,
      'n_home.aispa.remain_time',
      `Ends in ${amount}`,
      `Ends in ${amount}`,
      `Ends in ${amount}`,
      ``,
    ],
    continue: [
      `대화 이어하기`,
      `Resume talk`,
      'n_home.aispa.continue',
      `Resume talk`,
      `Resume talk`,
      `Resume talk`,
      ``,
    ],
  },
  event: {
    title: [`이벤트`, `Event`, 'n_home.event.title', `Event`, `Event`, `Event`, ``],
    view_all: [
      `전체 이벤트 보기`,
      `View all events`,
      'n_home.event.view_all',
      `View all events`,
      `View all events`,
      `View all events`,
      ``,
    ],
  },
}
export const n_event = {
  title: [`이벤트`, `Events`, 'n_event.title', `活动`, `活動`, `イベント`, ``],
  section_event: {
    title: [
      `진행중인 이벤트`,
      `Events`,
      'n_event.section_event.title',
      `进行中的活动`,
      `進行中的活動`,
      `開催中のイベント`,
      ``,
    ],
  },
  section_blog: {
    title: [
      `링글 블로그`,
      `Ringle Blog`,
      'n_event.section_blog.title',
      `Ringle部落格`,
      `Ringle部落格`,
      `Ringleブログ`,
      ``,
    ],
    btn: [
      `링글 블로그에서 더보기`,
      `Read more on the Ringle Blog`,
      'n_event.section_blog.btn',
      `到Ringle部落格看更多`,
      `到Ringle部落格看更多`,
      `Ringleブログをもっと見る`,
      ``,
    ],
  },
  section_upcoming: {
    title: (month) => [
      `${month}월의 이벤트`,
      `This Month's Events`,
      'n_event.section_upcoming.title',
      `${month}月的活动`,
      `${month}月的活動`,
      `${month}月のイベント`,
      ``,
    ],
    value: (number) => [
      `${number}개`,
      `${number}`,
      'n_event.section_upcoming.value',
      `${number}个`,
      `${number}個`,
      `${number}個`,
      ``,
    ],
    label_1: [
      `함께&혼자 읽기`,
      `Reading Program`,
      'n_event.section_upcoming.label_1',
      `阅读活动`,
      `閱讀活動`,
      `リーディングプログラム`,
      ``,
    ],
    label_2: [
      `링글 LIVE`,
      `Ringle LIVE`,
      'n_event.section_upcoming.label_2',
      `Ringle LIVE`,
      `Ringle LIVE`,
      `RingleLIVE`,
      ``,
    ],
    label_3: [`프로모션`, `Promotion`, 'n_event.section_upcoming.label_3', `促销活动`, `促銷活動`, `キャンペーン`, ``],
    label_4: [`이벤트`, `Event`, 'n_event.section_upcoming.label_4', `活动`, `活動`, `イベント`, ``],
    label_5: [`챌린지`, `Challenge`, 'n_event.section_upcoming.label_5', `挑战`, `挑戰`, `チャレンジ`, ``],
    hide: [`접기`, `Hide`, 'n_event.section_upcoming.hide', `隐藏`, `隱藏`, `隠す`, ``],
    see_more: (number) => [
      `${number}개의 이벤트 전체보기`,
      `View ${number} more`,
      'n_event.section_upcoming.see_more',
      `多看${number}个`,
      `多看${number}個`,
      `もっと見る ${number}個`,
      ``,
    ],
  },
  banner: {
    title: [
      `이벤트 웨비나 진행 안내`,
      `Event Webinar`,
      'n_event.banner.title',
      `研讨会活动`,
      `研討會活動`,
      `イベントウェビナー開催のご案内`,
      ``,
    ],
    desc_1: (event_webinar_name) => [
      `${event_webinar_name}이(가) 진행 중입니다. 지금 입장해 주세요!`,
      `${event_webinar_name} is currently in progress. Please join now!`,
      'n_event.banner.desc_1',
      `${event_webinar_name}正在进行中，请入场！`,
      `${event_webinar_name}正在進行中，請入場！`,
      `${event_webinar_name}が開催中です。今すぐご参加ください！`,
      ``,
    ],
    desc_2: (event_webinar_name) => [
      `${event_webinar_name}이(가) 곧 시작합니다! 지금 입장해 주세요.`,
      `${event_webinar_name} will start shortly! Join now.`,
      'n_event.banner.desc_2',
      `${event_webinar_name}即将开始，请入场！`,
      `${event_webinar_name}即將開始，請入場！`,
      `只今${event_webinar_name}が開催中です！今すぐご参加ください。`,
      ``,
    ],
    desc_3: (event_webinar_name, time) => [
      `${event_webinar_name}이(가) ${time} 후 시작할 예정입니다.`,
      `${event_webinar_name} will begin in ${time}.`,
      'n_event.banner.desc_3',
      `${event_webinar_name}将在 ${time} 开始。`,
      `${event_webinar_name}將在 ${time} 開始。`,
      `${event_webinar_name}が${time}後に開始する予定です。`,
      ``,
    ],
    btn_1: [`입장하기`, `Join`, 'n_event.banner.btn_1', `入场`, `入場`, `参加する`, ``],
    btn_2: [`자세히 보기`, `View detail`, 'n_event.banner.btn_2', `看更多`, `看更多`, `詳細を見る`, ``],
  },
  list: {
    tap_1: [
      `진행중인 이벤트`,
      `In Progress`,
      'n_event.list.tap_1',
      `进行中的活动`,
      `進行中的活動`,
      `開催中のイベント`,
      ``,
    ],
    tap_2: [`종료된 이벤트`, `Closed`, 'n_event.list.tap_2', `已结束的活动`, `已結束的活動`, `終了したイベント`, ``],
    menu_1: [`전체`, `All`, 'n_event.list.menu_1', `全部`, `全部`, `すべて`, ``],
    menu_2: [`라이브`, `LIVE`, 'n_event.list.menu_2', `LIVE`, `LIVE`, `ライブ`, ``],
    menu_3: [`다시보기`, `VOD`, 'n_event.list.menu_3', `重新观看`, `重新觀看`, `録画`, ``],
    menu_4: [`할인/혜택`, `Benefits`, 'n_event.list.menu_4', `折扣/优惠`, `折扣/優惠`, `割引/特典`, ``],
    menu_5: [`기타`, `Others`, 'n_event.list.menu_5', `其他`, `其他`, `その他`, ``],
    menu_6: [`프로모션`, `Promotion`, 'n_event.list.menu_6', `促销`, `促銷`, `キャンペーン`, ``],
    main_title: [
      `링글 이벤트`,
      `Ringle Event`,
      'n_event.list.main_title',
      `Ringle活动`,
      `Ringle活動`,
      `Ringleイベント`,
      ``,
    ],
  },
  card: {
    label_1: [`LIVE`, `LIVE`, 'n_event.card.label_1', `LIVE`, `LIVE`, `ライブ`, ``],
    label_2: [`이벤트`, `Event`, 'n_event.card.label_2', `活动`, `活動`, `イベント`, ``],
    label_3: [`진행중`, `In Progress`, 'n_event.card.label_3', `进行中`, `進行中`, `進行中`, ``],
    label_4: [`종료`, `Closed`, 'n_event.card.label_4', `结束`, `結束`, `終了`, ``],
    label_5: [`다시보기`, `VOD`, 'n_event.card.label_5', `重新观看`, `重新觀看`, `録画`, ``],
    label_6: [`신청 마감`, `Closed`, 'n_event.card.label_6', `申请截止`, `申請截止`, `申し込み締め切り`, ``],
    label_7: [`오늘 마감`, `ENDS Today`, 'n_event.card.label_7', `今天截止`, `今天截止`, `本日締め切り`, ``],
    label_8: (date) => [
      `마감 임박 D-${date}`,
      `Ending Soon D-${date}`,
      'n_event.card.label_8',
      `即将结束 D-${date}`,
      `即將結束 D-${date}`,
      `締切間近 D-${date}`,
      ``,
    ],
    label_9: [`신청 가능`, `Now open`, 'n_event.card.label_9', `接受申请中`, `接受申請中`, `申請可能`, ``],
    caption_1: (date) => [
      `신청마감 : ${date}까지`,
      `Deadline : ${date}`,
      'n_event.card.caption_1',
      `申请截止 : 到${date}为止`,
      `申請截止 : 到${date}為止`,
      `申し込み締め切り : ${date} まで`,
      ``,
    ],
    caption_2: (date) => [
      `진행일시 : ${date}`,
      `Date : ${date}`,
      'n_event.card.caption_2',
      `活动日期 : ${date}`,
      `活動日期 : ${date}`,
      `開催日時 : ${date}`,
      ``,
    ],
    caption_3: [`상시진행`, `Ongoing`, 'n_event.card.caption_3', `进行中`, `進行中`, `進行中`, ``],
  },
  button: {
    label_1: [
      `이벤트가 종료되었습니다.`,
      `The event has ended.`,
      'n_event.button.label_1',
      `活动已经结束`,
      `活動已經結束`,
      `イベントは終了しました。`,
      ``,
    ],
    label_2: [`입장하기`, `Join`, 'n_event.button.label_2', `入场`, `入場`, `参加する`, ``],
    label_3: [
      `신청이 완료되었습니다.`,
      `Your application has been submitted.`,
      'n_event.button.label_3',
      `已送出申请`,
      `已送出申請`,
      `応募が完了しました。`,
      ``,
    ],
    label_4: [
      `모집이 마감되었습니다`,
      `The application period has ended.`,
      'n_event.button.label_4',
      `申请时间已结束`,
      `申請時間已結束`,
      `募集は終了しました`,
      ``,
    ],
    label_5: [`신청하기`, `Sign up`, 'n_event.button.label_5', `申请`, `申請`, `お申し込みはこちら`, ``],
  },
  toast: {
    lable_1: [
      `신청했어요.`,
      `Successfully signed up.`,
      'n_event.toast.lable_1',
      `已申请`,
      `已申請`,
      `お申し込み完了`,
      ``,
    ],
  },
  detail_menu: {
    title_1: [`웨비나 소개`, `Introduction`, 'n_event.detail_menu.title_1', `介绍`, `介紹`, `紹介`, ``],
    title_2: [`이벤트 안내`, `Information`, 'n_event.detail_menu.title_2', `活动介绍`, `活動介紹`, `イベント案内`, ``],
    title_3: [`FAQ`, `FAQ`, 'n_event.detail_menu.title_3', `常见问题`, `常見問題`, `注意事項`, ``],
    title_4: [
      `추천 컨텐츠`,
      `Contents`,
      'n_event.detail_menu.title_4',
      `推荐教材`,
      `推薦教材`,
      `おすすめコンテンツ`,
      ``,
    ],
  },
  view_more_event: (number) => [
    <>진행 중인 이벤트 {number}개 더 보기</>,
    <>View {number} More Events</>,
    'n_event.view_more_event',
    <>多看{number} 活动</>,
    <>多看{number} 活動</>,
    <>{number}アクティビティを参照</>,
    <>View {number} More Events</>,
  ],
  prev_event: {
    section_title: [`지난 링글 라이브`, `Past Ringle Live`, 'n_event.prev_event.section_title', ``, ``, ``, ``],
  },
}
export const n_certificate = {
  page_title: [
    `증명서 발급`,
    `Issue certificate`,
    'n_certificate.page_title',
    `申请证明文件`,
    `申請證明文件`,
    `証明書発行`,
    ``,
  ],
  title: [
    `증명서를 발급할 수업권을 선택하세요.`,
    `Please choose the credits you wish to get certificates for.`,
    'n_certificate.title',
    `请选择想要申请证明文件的课程`,
    `請選擇想要申請證明文件的課程`,
    `証明書を発行するレッスンチケットを選択してください。`,
    ``,
  ],
  caption_1: [`수업 횟수`, `Lessons`, 'n_certificate.caption_1', `课程次数`, `課程次數`, `レッスン回数`, ``],
  caption_2: [`수업 시간`, `Lesson Duration`, 'n_certificate.caption_2', `课程时间`, `課程時間`, `レッスン時間`, ``],
  caption_3: [`수강기간`, `Lesson Period`, 'n_certificate.caption_3', `上课期间`, `上課期間`, `受講期間`, ``],
  caption_4: [`결제 금액`, `Payment Total`, 'n_certificate.caption_4', `总付款金额`, `總付款金額`, `お支払い金額`, ``],
  caption_5: [`결제일`, `Payment Date`, 'n_certificate.caption_5', `付款日期`, `付款日期`, `お支払い日`, ``],
  caption_6: [`결제 방식`, `Payment Method`, 'n_certificate.caption_6', `付款方法`, `付款方法`, `お支払い方法`, ``],
  btn_1: [
    `내 수업권 전체보기`,
    `My Credits`,
    'n_certificate.btn_1',
    `我的课程券`,
    `我的課程券`,
    `あなたのレッスンチケット一覧`,
    ``,
  ],
  btn_2: [`선택완료`, `Next`, 'n_certificate.btn_2', `下一步`, `下一步`, `選択完了`, ``],
  btn_lesson: [
    `수강증 발급하기`,
    `Lesson Certificate`,
    'n_certificate.btn_lesson',
    `申请上课证明`,
    `申請上課證明`,
    `受講証を発行する`,
    ``,
  ],
  btn_attendance: [
    `출석증 발급하기`,
    `Attendance Certificate`,
    'n_certificate.btn_attendance',
    `申请出席证明`,
    `申請出席證明`,
    `出席証を発行する`,
    ``,
  ],
  btn_recipt: [
    `영수증 출력하기`,
    `Receipt`,
    'n_certificate.btn_recipt',
    `申请收据`,
    `申請收據`,
    `領収書を発行する`,
    ``,
  ],
  callout_title: [
    `영수증 발급을 지원하지 않는 결제 방식이에요.`,
    `A receipt cannot be issued for the payment method you used.`,
    'n_certificate.callout_title',
    `收据发行不支援此付款方式`,
    `收據發行不支援此付款方式`,
    `領収書発行に対応していない決済方法です。`,
    ``,
  ],
  callout_desc_1: [
    `Paypal/카카오페이 결제 영수증은 PayPal/카카오페이 계정을 통해 확인 가능합니다.`,
    `You can check the receipt for payments made through PayPal/Kakao Pay by going to their website.`,
    'n_certificate.callout_desc_1',
    `您可以在PayPal/Kakao Pay网站确认您的收据`,
    `您可以在PayPal/Kakao Pay網站確認您的收據`,
    `Paypal/KakaoPay決済の領収書はPayPal/KakaoPeyのアカウントから確認できます。`,
    ``,
  ],
  callout_desc_2: [
    `카카오페이/네이버페이로 결제 하셨다면 현금영수증은 해당 플랫폼에서 신청 가능합니다.`,
    `You can request receipts to be issued for Kakao Pay/Naver Pay payments at the respective platforms.`,
    'n_certificate.callout_desc_2',
    `您可以向 Naver Pay/Kakao Pay素取您的收据`,
    `您可以向 Naver Pay/Kakao Pay素取您的收據`,
    `KakaoPay/NaverPayで決済された場合、現金領収書は本サイトで申請可能です。？`,
    ``,
  ],
  list_title: [
    `증명서 발급 내역`,
    `Certificate issuance history`,
    'n_certificate.list_title',
    `已发行的证明明细`,
    `已發行的證明明細`,
    `証明書発行履歴`,
    ``,
  ],
  list_chip_1: [`수강증`, `Lesson`, 'n_certificate.list_chip_1', `上课证明`, `上課證明`, `受講証`, ``],
  list_chip_2: [`출석증`, `Attendance`, 'n_certificate.list_chip_2', `出席证明`, `出席證明`, `出席証`, ``],
  column_1: [`발급 회차`, `Count`, 'n_certificate.column_1', `上课次数`, `上課次數`, `発行回数`, ``],
  column_2: [`발급 일시`, `Issue Date`, 'n_certificate.column_2', `发行日期`, `發行日期`, `発行日時`, ``],
  column_3: [`증빙 금액`, `Amount`, 'n_certificate.column_3', `金额`, `金額`, `証明金額`, ``],
  column_4: [`증빙 기간`, `Period`, 'n_certificate.column_4', `期间`, `期間`, `証明期間`, ``],
  column_5: [`수강 횟수`, `Attendance Rate`, 'n_certificate.column_5', `出席率`, `出席率`, `受講回数`, ``],
  list_placeholder_1: [
    `출석증 발급 내역이 없어요.`,
    `No lesson certificates have been issued.`,
    'n_certificate.list_placeholder_1',
    `没有出席证明发放纪录`,
    `沒有出席證明發行紀錄`,
    `出席証の発行履歴がありません。`,
    ``,
  ],
  list_placeholder_2: [
    `수강증 발급 내역이 없어요.`,
    `No attendance certificates have been issued.`,
    'n_certificate.list_placeholder_2',
    `没有上课证明纪录`,
    `沒有上課證明紀錄`,
    `受講証の発行履歴がありません。`,
    ``,
  ],
  modal_title: [
    `발급 전에 꼭 확인해주세요.`,
    `Please read the following before issuing the certificate.`,
    'n_certificate.modal_title',
    `发放前请确认`,
    `發行前請確認`,
    `発行前に必ずご確認ください。`,
    ``,
  ],
  modal_desc_1: [
    `재직중인 회사에 필요한 증빙 조건을 확인하세요.`,
    `Please check your company's policies on documentation for reimbursement.`,
    'n_certificate.modal_desc_1',
    `请确认贵公司要求的附加文件要求`,
    `請確認貴公司要求的附加文件要求`,
    `在職中の会社に必要な証明条件を確認してください。`,
    ``,
  ],
  modal_desc_2: [
    `정상적으로 완료되지 않은 수업은 증명 내용에 포함되지 않습니다.`,
    `Abnormally completed lessons are not accounted for in the certificates.`,
    'n_certificate.modal_desc_2',
    `上课证明不包含没有正常完成的课程`,
    `上課證明不包含沒有正常完成的課程`,
    `正常に完了していないレッスンは証明内容に含まれません。`,
    ``,
  ],
  modal_desc_3: [
    `모든 증명서는 결제한 수업권에 대해서만 제공됩니다.`,
    `Certificates can only be issued for credits you have purchased.`,
    'n_certificate.modal_desc_3',
    `只针对购买的课程提供证明文件`,
    `只針對購買的課程提供證明文件`,
    `すべての証明書は、購入したレッスンチケットに対してのみ提供されます。`,
    ``,
  ],
  page_title_1: [
    `출석증 발급하기`,
    `Issue Attendance Certificate`,
    'n_certificate.page_title_1',
    `发放出席证明`,
    `發行出席證明`,
    `出席証を発行する`,
    ``,
  ],
  page_title_2: [
    `수강증 발급하기`,
    `Issue Lesson Certificate`,
    'n_certificate.page_title_2',
    `发放上课证明`,
    `發行上課證明`,
    `受講証を発行する`,
    ``,
  ],
  indicatior_1: [
    `정보 확인`,
    `Check Information`,
    'n_certificate.indicatior_1',
    `确认资讯`,
    `確認資訊`,
    `内容確認`,
    ``,
  ],
  indicatior_2: [
    `증빙 기간 및 금액 입력`,
    `Set Period & Amount`,
    'n_certificate.indicatior_2',
    `请设定需要的时间和金额`,
    `請設定需要的時間和金額`,
    `証明期間と金額を入力`,
    ``,
  ],
  indicatior_3: [
    `출석률 계산 방식 선택`,
    `Set Attendance Rate`,
    'n_certificate.indicatior_3',
    `选择出席率的计算方式`,
    `選擇出席率的計算方式`,
    `出席率計算方法の選択`,
    ``,
  ],
  indicatior_4: [
    `증명서 미리보기, 출력`,
    `Preview & Issue`,
    'n_certificate.indicatior_4',
    `证明文件预览/打印`,
    `證明文件預覽/列印`,
    `証明書のプレビュー、出力`,
    ``,
  ],
  step_1: {
    title_1: [
      `어떤 언어로 발급할까요?`,
      `Select Language`,
      'n_certificate.step_1.title_1',
      `选择需要的语言`,
      `選擇需要的語言`,
      `どの言語で発行しますか？`,
      ``,
    ],
    radio_1: [`한국어`, `Korean`, 'n_certificate.step_1.radio_1', `韩文`, `韓文`, `韓国語`, ``],
    radio_2: [`영어`, `English`, 'n_certificate.step_1.radio_2', `英文`, `英文`, `英語`, ``],
    title_2: [
      `수강생 정보를 확인하세요.`,
      `Student Information`,
      'n_certificate.step_1.title_2',
      `学生资讯`,
      `學生資訊`,
      `受講者情報をご確認ください。`,
      ``,
    ],
    input_kr_label: [
      `한글 이름`,
      `Korean Name`,
      'n_certificate.step_1.input_kr_label',
      `韩文姓名`,
      `韓文姓名`,
      `韓国名`,
      ``,
    ],
    input_kr_placeholder: [`이름`, `Name`, 'n_certificate.step_1.input_kr_placeholder', `姓名`, `姓名`, `氏名`, ``],
    input_kr_caption: [
      `이름이 정확한지 확인하세요.`,
      `Please check your name.`,
      'n_certificate.step_1.input_kr_caption',
      `请确认您的姓名`,
      `請確認您的姓名`,
      `氏名が正しいことを確認してください。`,
      ``,
    ],
    input_en_label: [
      `영어 이름`,
      `English Name`,
      'n_certificate.step_1.input_en_label',
      `英文姓名`,
      `英文姓名`,
      `英語名`,
      ``,
    ],
    input_en_placeholder_1: [
      `First Name`,
      `First Name`,
      'n_certificate.step_1.input_en_placeholder_1',
      `名字`,
      `名字`,
      `名`,
      ``,
    ],
    input_en_placeholder_2: [
      `Last Name`,
      `Last Name`,
      'n_certificate.step_1.input_en_placeholder_2',
      `姓氏`,
      `姓氏`,
      `姓`,
      ``,
    ],
    input_en_caption: [
      `이름이 정확한지 확인하세요.`,
      `Please check your name.`,
      'n_certificate.step_1.input_en_caption',
      `请确认姓名`,
      `請確認姓名`,
      `氏名が正しいことを確認してください。`,
      ``,
    ],
  },
  step_2: {
    section_1: {
      title_lesson: [
        `수강증 발급 방식을 선택하세요.`,
        `Please choose how you wish to have the certificate issued.`,
        'n_certificate.step_2.section_1.title_lesson',
        `请选择上课证明发放方式`,
        `請選擇上課證明發行方式`,
        `受講証の発行方法を選択してください。`,
        ``,
      ],
      title_attendance: [
        `출석증 발급 방식을 선택하세요.`,
        `Please choose how you wish to have the certificate issued.`,
        'n_certificate.step_2.section_1.title_attendance',
        `请选择出席证明发放方式`,
        `請選擇出席證明發行方式`,
        `出席証の発行方法を選択してください。`,
        ``,
      ],
      radio_1_title: [
        `한 번만 발급`,
        `Issue one certificate for the whole period`,
        'n_certificate.step_2.section_1.radio_1_title',
        `申请所有日期证明`,
        `申請所有日期證明`,
        `1回のみ発行`,
        ``,
      ],
      radio_1_desc: [
        `전체 수강 기간을 전체 금액으로 증빙하는 경우에 선택하세요.`,
        `One certificate for the chosen lesson period and payment total`,
        'n_certificate.step_2.section_1.radio_1_desc',
        `申请所有上课记录和付款金额证明`,
        `申請所有上課記錄和付款金額證明`,
        `受講期間全体を全額で証明する場合に選択してください。`,
        ``,
      ],
      radio_2_title: [
        `여러번 나누어 발급`,
        `Divide lesson period for separate certification`,
        'n_certificate.step_2.section_1.radio_2_title',
        `分别申请不同期间的证明`,
        `分別申請不同期間的證明`,
        `複数回に分けて発行`,
        ``,
      ],
      radio_2_desc: [
        `수강기간에 따라 나눌 수 있는 최대 횟수가 달라집니다.`,
        `The number of certificates that can be issued depends on the length of the lesson period.`,
        'n_certificate.step_2.section_1.radio_2_desc',
        `可发放的证明文件数量以课程使用期间为准`,
        `可發行的證明文件數量以課程使用期間為準`,
        `受講期間によって分割できる最大回数が異なります。`,
        ``,
      ],
      placeholder: [
        `나눠서 발급할 횟수를 선택하세요.`,
        `Select the number of times`,
        'n_certificate.step_2.section_1.placeholder',
        `请选择欲发行的次数`,
        `請選擇欲發行的次數`,
        `分割して発行する回数を選択してください。`,
        ``,
      ],
    },
    section_2: {
      title: [
        `이번에 증빙이 필요한 기간을 선택하세요.`,
        `Enter the period you wish to include in the certificate.`,
        'n_certificate.step_2.section_2.title',
        `选择需要的时间范围`,
        `選擇需要的時間範圍`,
        `今回証明が必要な期間を選択してください。`,
        ``,
      ],
      caption_1: [
        `시작 날짜`,
        `Start date`,
        'n_certificate.step_2.section_2.caption_1',
        `开始日期`,
        `開始日期`,
        `開始日`,
        ``,
      ],
      desc_1: (date) => [
        `수업권을 결제한 날 ${date} 이후부터 선택 가능해요.`,
        `Choose a date after the day you purchased the credits ${date}.`,
        'n_certificate.step_2.section_2.desc_1',
        `请选择你一个您购买课程券 ${date}之后的日期`,
        `請選擇你一個您購買課程券 ${date}之後的日期`,
        `レッスン料を支払った日の${date}以降から選択可能です。`,
        ``,
      ],
      placeholde: [
        `날짜를 선택하세요.`,
        `Choose date`,
        'n_certificate.step_2.section_2.placeholde',
        `选择日期`,
        `選擇日期`,
        `日付を選択してください。`,
        ``,
      ],
      caption_2: [
        `종료 날짜`,
        `End date`,
        'n_certificate.step_2.section_2.caption_2',
        `结束日期`,
        `結束日期`,
        `終了日`,
        ``,
      ],
      desc_2: (date) => [
        <>
          선택한 시작 날짜 ${date} 이후부터 ${date}까지 선택 가능해요.
        </>,
        <>
          Choose a date between the start date ${date} to ${date}.
        </>,
        'n_certificate.step_2.section_2.desc_2',
        <>
          请选择起始时间 ${date} 到 ${date}之间日期.
        </>,
        <>
          請選擇起始時間 ${date} 到 ${date}之間日期.
        </>,
        <>
          選択した開始日${date}以降から${date}まで選択可能です。
        </>,
        ``,
      ],
      btn_1: [
        <>시작 날짜부터 30일</>,
        <>30 days from start date</>,
        'n_certificate.step_2.section_2.btn_1',
        <>从开始时间后30天</>,
        <>從開始時間後30天</>,
        <>開始日から30日</>,
        ``,
      ],
      btn_2: [
        <>전체기간</>,
        <>Whole period</>,
        'n_certificate.step_2.section_2.btn_2',
        <>全部时间</>,
        <>全部時間</>,
        <>全期間</>,
        ``,
      ],
      label: (n) => [
        <>선택한 증빙 기간 : ${n}일</>,
        <>Days : ${n} days</>,
        'n_certificate.step_2.section_2.label',
        <>天数 : ${n} days</>,
        <>天數 : ${n} days</>,
        <>選択した証明期間 : ${n}日</>,
        ``,
      ],
      placeholder: [
        <>시작 날짜를 먼저 선택하세요.</>,
        `First, select the start date.`,
        'n_certificate.step_2.section_2.placeholder',
        <>请先选择开始日期.</>,
        <>請先選擇開始日期.</>,
        <>開始日を先に選択してください。</>,
        ``,
      ],
      date: (n, date, nth) => [
        <>
          발급 {n}회차의 증빙 종료 날짜 {date}부터 선택 가능해요.
        </>,
        `You can choose from {date} for the {nth} issuance.`,
        'n_certificate.step_2.section_2.date',
        <>
          你可从{date}开始选择第{n}次证明文件的日期
        </>,
        <>
          你可從{date}開始選擇第{n}次證明文件的日期
        </>,
        <>
          {n}回目の発行は {date}から選択できます。
        </>,
        ``,
      ],
    },
    section_3: {
      title: [
        <>이번에 증빙이 필요한 금액을 입력하세요.</>,
        <>Next, enter the payment amount you wish to include in the certificate.</>,
        'n_certificate.step_2.section_3.title',
        <>请输入证明文件需要标示的金额</>,
        <>請輸入證明文件需要標示的金額</>,
        <>今回証明が必要な金額を入力してください。</>,
        ``,
      ],
      placeholder: [
        `최소 1원 부터 입력`,
        `Enter any amount over $1`,
        'n_certificate.step_2.section_3.placeholder',
        `请输入任何超过 $1的金额`,
        `請輸入任何超過 $1的金額`,
        `最低1円からの入力`,
        ``,
      ],
      btn_1: [`+10만원`, `+100$`, 'n_certificate.step_2.section_3.btn_1', `+100$`, `+100$`, `＋100$`, ``],
      btn_2: [`+50만원`, `+300$`, 'n_certificate.step_2.section_3.btn_2', `+300$`, `+300$`, `＋300$`, ``],
      btn_3: [`전액입력`, `Total`, 'n_certificate.step_2.section_3.btn_3', `输入金额`, `輸入金額`, `合計金額`, ``],
      label: (amount) => [
        `수업권 결제 금액 : ${amount}원`,
        `Payment total : ${amount}`,
        'n_certificate.step_2.section_3.label',
        `购买课程券金额 : ${amount}元`,
        `購買課程券金額 : ${amount}元`,
        `支払い金額合計: ${amount}円`,
        ``,
      ],
    },
    callout: {
      lesson: (n) => [
        `수강증 분할 발급 횟수가 ${n}회 남았어요.`,
        `You have ${n} remaining lesson certificates that can be issued for this lesson period.`,
        'n_certificate.step_2.callout.lesson',
        `“分次发放的上课证明剩馀发放次数为${n}次。`,
        `“分次發放的上課證明剩餘發放次數為${n}次。`,
        `このレッスン期間に発行できる受講証が${n}回残っています。`,
        ``,
      ],
      attendance: (n) => [
        `출석증 분할 발급 횟수가 ${n}회 남았어요.`,
        `You have ${n} remaining attendance certificates that can be issued for this lesson period.`,
        'n_certificate.step_2.callout.attendance',
        `“分次发放的出席证明剩馀发放次数为${n}次。`,
        `“分次發放的出席證明剩餘發放次數為${n}次。`,
        `このレッスン期間に発行できる出席証が${n}回残っています。`,
        ``,
      ],
    },
    btn: {
      lesson: [
        `증명서 미리보기`,
        `See preview`,
        'n_certificate.step_2.btn.lesson',
        `预览`,
        `預覽`,
        `証明書プレビュー`,
        ``,
      ],
    },
  },
  step_3: {
    section_1: {
      title: [
        `출석률 계산 방식을 선택하세요.`,
        `Choose the method for calculating attendance rate.`,
        'n_certificate.step_3.section_1.title',
        `请选择出席率计算方式`,
        `請選擇出席率計算方式`,
        `出席率の計算方法を選択してください。`,
        ``,
      ],
      option_1: [
        <>
          이번에 증빙이 필요한 기간동안
          <br />
          완료한 수업 수로 계산
        </>,
        <>
          Calculate based on the number of lessons
          <br />
          completed during the chosen period.
        </>,
        'n_certificate.step_3.section_1.option_1',
        <>
          所选日期中
          <br />
          完成的课程数
        </>,
        <>
          所選日期中
          <br />
          完成的課程數
        </>,
        <>
          今回証明が必要な期間中に
          <br />
          完了した授業数で計算
        </>,
        ``,
      ],
      option_2: [
        `지금까지 완료한 수업 수로 계산`,
        `Calculate based on the number of lessons so far.`,
        'n_certificate.step_3.section_1.option_2',
        `计算到目前为止完成的课程数`,
        `計算到目前為止完成的課程數`,
        `これまでに完了したレッスン数で計算`,
        ``,
      ],
      caption_1: [
        `이번에 선택한 증빙 기간`,
        `Lesson period`,
        'n_certificate.step_3.section_1.caption_1',
        `这次选择的课程期间`,
        `這次選擇的課程期間`,
        `今回選択した証明期間`,
        ``,
      ],
      caption_2: [
        `내가 완료한 수업 수`,
        `Lessons completed`,
        'n_certificate.step_3.section_1.caption_2',
        `我完成的课程数`,
        `我完成的課程數`,
        `完了したレッスン数`,
        ``,
      ],
    },
    section_2: {
      title: [
        `증빙이 필요한 수업 횟수를 입력하세요.`,
        `Enter the number of lessons you wish to include in the certificate.`,
        'n_certificate.step_3.section_2.title',
        `请输入需要证明文件的课程次数`,
        `請輸入需要證明文件的課程次數`,
        `証明が必要な授業回数を入力してください。`,
        ``,
      ],
      callout: [
        `최소 1회 이상 입력`,
        `Number should be 1 or above`,
        'n_certificate.step_3.section_2.callout',
        `最少输入1次`,
        `最少輸入1次`,
        `最低1回以上入力`,
        ``,
      ],
      placeholder: (n) => [
        `이번에 입력할 수 있는 최대 횟수 : ${n}회`,
        `Maximum number you can enter : ${n}`,
        'n_certificate.step_3.section_2.placeholder',
        `这次能申请的最多次数 : ${n}次`,
        `這次能申請的最多次數 : ${n}次`,
        `今回入力できる最大回数 : ${n}回`,
        ``,
      ],
      label: [
        `내 출석률 :`,
        `Attendance Rate :`,
        'n_certificate.step_3.section_2.label',
        `我的出席率`,
        `我的出席率`,
        `出席率`,
        ``,
      ],
    },
    section_3: {
      title: (name, n) => [
        <>
          {name}님은 지금까지 {n}회 수업 중 <span className="text-purple-500">{n}회</span> 완료했어요.
        </>,
        <>
          {name}, you have completed {n} lessons out of <span className="text-purple-500">{n}</span>in total.
        </>,
        'n_certificate.step_3.section_3.title',
        <>
          {name}, 你已在<span className="text-purple-500">{n}</span> 次课程中完成{n}次
        </>,
        <>
          {name}, 你已在<span className="text-purple-500">{n}</span> 次課程中完成{n}次
        </>,
        <>
          {name} さんはこれまでに {n} 回のレッスンのうち <span className="text-purple-500">{n} 回</span>完了しました。
        </>,
        ``,
      ],
    },
    modal: {
      title: [
        `출석률 계산 방법을 알려드려요.`,
        `Here's how we calculate the attendance rate.`,
        'n_certificate.step_3.modal.title',
        `这是计算出席率的方式`,
        `這是計算出席率的方式`,
        `出席率の計算方法を教えてください。`,
        ``,
      ],
      desc_1: [
        `완료한 수업 수를 증빙이 필요한 수업 수로 나눈 값에100을 곱하여 계산합니다.`,
        `The number of lessons completed is divided by the number of lessons you wish to include in the certificate, then the resulting value is multiplied by 100.`,
        'n_certificate.step_3.modal.desc_1',
        `用已完成的课程数量除以需要证明文件的课程数，将结果乘以100得到出席率。`,
        `用已完成的課程數量除以需要證明文件的課程數，將結果乘以100得到出席率。`,
        `完了したレッスン数を証明が必要なレッスン数で割った値に100を掛けて計算します。`,
        ``,
      ],
      desc_2: [
        `지금까지 완료한 수업 수를 수업권의 전체 수업 수로 나눈 값에100을 곱하여 계산합니다.`,
        `The number of lessons you have completed so far is divided by the number of lessons included in the credits you purchased, then the resulting value is multiplied by 100.`,
        'n_certificate.step_3.modal.desc_2',
        `将到目前为止您完成的课程数除以您购买的课程券包含的课程数，将得到的值乘以100。`,
        `將到目前為止您完成的課程數除以您購買的課程券包含的課程數，將得到的值乘以100。`,
        `これまでに完了したレッスン数を、購入したレッスン数で割った値に100を掛けて計算します。`,
        ``,
      ],
      caption_1: [
        `완료한 수업 수`,
        `Lessons completed`,
        'n_certificate.step_3.modal.caption_1',
        `完成的课程数`,
        `完成的課程數`,
        `完了したレッスン数を証明が必要なレッスン数で割った値に100を掛けて計算します。`,
        ``,
      ],
      caption_2: [
        `전체 수업 수`,
        `Total lessons`,
        'n_certificate.step_3.modal.caption_2',
        `所有课程数`,
        `所有課程數`,
        `全レッスン数`,
        ``,
      ],
      caption_3: [
        `계산식`,
        `Calculation method`,
        'n_certificate.step_3.modal.caption_3',
        `计算方式`,
        `計算方式`,
        `計算式`,
        ``,
      ],
      caption_4: [
        `내 출석률`,
        `Attendance Rate`,
        'n_certificate.step_3.modal.caption_4',
        `出席率`,
        `出席率`,
        `出席率`,
        ``,
      ],
      caption_5: [
        `증빙이 필요한 수업 수`,
        `Lessons requiring proof`,
        'n_certificate.step_3.modal.caption_5',
        `需要证明文件的课程数`,
        `需要證明文件的課程數`,
        `証明が必要なレッスン数`,
        ``,
      ],
    },
    btn: {
      attendance: (n) => [
        `출석증 분할 발급 횟수가 ${n}회 남았어요.`,
        `You have ${n} remaining attendance certificates that can be issued for this lesson period.`,
        'n_certificate.step_3.btn.attendance',
        `“分次发放的出席证明剩馀发行次数为${n}次。`,
        `“分次發放的出席證明剩餘發行次數為${n}次。`,
        `出席証の分割発行回数が${n}回残っています。`,
        ``,
      ],
    },
  },
  step_4: {
    title: [
      `내용을 확인하고 발급하기를 누르세요.`,
      `Please double-check the contents of the certificate, then click [Issue].`,
      'n_certificate.step_4.title',
      `请确认内容无误后点选发放`,
      `請確認內容無誤後點選發行`,
      `内容を確認して発行を押してください。`,
      ``,
    ],
    checkbox_1: [
      `증명서의 내용은 발급 이후 수정할 수 없습니다.`,
      `The contents of the certificate cannot be changed after issuance.`,
      'n_certificate.step_4.checkbox_1',
      `证明发放后将无法修改内容`,
      `證明發行後將無法修改內容`,
      `証明書の内容は発行後に修正することはできません。`,
      ``,
    ],
    checkbox_2: [
      `증명서를 발급하면 수업권을 환불 할 수 없습니다.`,
      `Once you get the certificate issued, the chosen credits cannot be refunded.`,
      'n_certificate.step_4.checkbox_2',
      `证明发放后课程券将无法退款`,
      `證明發行後課程券將無法退款`,
      `証明書を発行すると、受講券の返金はできません。`,
      ``,
    ],
    checkbox_3: [
      `증명서를 발급하면 다른 수업권으로 변경할 수 없습니다.`,
      `Once you get the certificate issued, the chosen credits cannot be exchanged.`,
      'n_certificate.step_4.checkbox_3',
      `证明发放后课程券将无法变更`,
      `證明發行後課程券將無法變更`,
      `証明書を発行すると、他の受講券に変更することはできません。`,
      ``,
    ],
    btn: [
      `발급하기 / PDF다운로드`,
      `Issue/Download PDF`,
      'n_certificate.step_4.btn',
      `发行/下载PDF`,
      `發行/下載PDF`,
      `発行する / PDFダウンロード`,
      ``,
    ],
  },
  modal_1: {
    title: [
      `한 번만 발급하기를 선택했어요.`,
      `Chose to issue it only once.`,
      'n_certificate.modal_1.title',
      `已选择只发行一次`,
      `已選擇只發行一次`,
      `一度だけ発行することを選択しました。`,
      ``,
    ],
    desc: [
      `처음 설정한 발급 방식은 나중에 변경할 수 없습니다.`,
      `The issuance method initially selected cannot be changed later.`,
      'n_certificate.modal_1.desc',
      `首次选择的发行方式日后无法更改`,
      `首次選擇的發行方式日後無法更改`,
      `最初に設定した発行方法は、後で変更することはできません。`,
      ``,
    ],
  },
  modal_2: {
    title: [
      `여러번 나누어 발급하기를 선택했어요.`,
      `Chose to issue it multiple times.`,
      'n_certificate.modal_2.title',
      `已选择发行多次`,
      `已選擇發行多次`,
      `複数回に分けて発行することを選択しました。`,
      ``,
    ],
    desc: [
      `발급 횟수를 선택하세요. 처음 설정한 발급 횟수는 나중에 변경할 수 없습니다.`,
      `Please choose the number of issuances. The initially selected issuance count cannot be changed later.`,
      'n_certificate.modal_2.desc',
      `首次选择的发行次数日后无法更改`,
      `首次選擇的發行次數日後無法更改`,
      `発行回数を選択してください。 最初に設定した発行回数は後で変更することはできません。`,
      ``,
    ],
  },
  count: (n) => [<>{n}회</>, <>{n}</>, 'n_certificate.count', <>{n}次</>, <>{n}次</>, <>{n} 回</>, ``],
  responsive: {
    placeholder: [
      <>
        증명서 발급, 수강기간 연장, 수업권 변경은
        <br />
        PC 웹사이트를 이용해주세요.
      </>,
      `Please access the RIngle website via a PC to issue certificates or extend/exchange your credits.`,
      'n_certificate.responsive.placeholder',
      <>
        发行证明文件, 延长课程券使用时间, 便腾课程券
        <br />
        请于PC网页进行
      </>,
      <>
        發行證明文件, 延長課程券使用時間, 便騰課程券
        <br />
        請於PC網頁進行
      </>,
      <>
        証明書の発行、受講期間の延長、レッスンチケットの変更は
        <br />
        PCサイトをご利用ください。
      </>,
      ``,
    ],
  },
  quit_title: [
    `증명서 발급을 중단할까요?`,
    `Do you want to stop the certificate issuance?`,
    'n_certificate.quit_title',
    ``,
    `您要中斷發行證明文件嗎`,
    `証明書の発行を中止しますか？`,
    ``,
  ],
  quit_desc: [
    `작성중인 내용은 저장되지 않습니다. 이전 단계로 돌아가려면 페이지 아래의 [이전]버튼을 누르세요.`,
    `What you entered will not be saved. To return to the previous step, please click the [Back] button below.`,
    'n_certificate.quit_desc',
    `"正在撰写的内容不会被保存。如果要返回到前一步，请点击页面下方的[返回]按钮。`,
    `"正在撰寫的內容不會被儲存。如果要返回到前一步，請點頁面下方的[返回]按鈕。`,
    `作成中の内容は保存されません。 前のステップに戻るには、ページ下の[戻る]ボタンを押してください。`,
    ``,
  ],
  placeholder: [
    `증명서를 발급할 수 있는 수업권이 없습니다.`,
    `There is no eligible credit for certificate issuance.`,
    'n_certificate.placeholder',
    `无法发行证明文件的课程券。`,
    `無法發行證明文件的課程券。`,
    `証明書を発行できるレッスンチケットがありません。`,
    ``,
  ],
  placeholder_box: [
    `선택한 수업권의 정보가 노출됩니다.`,
    `The information for the selected credit will be displayed.`,
    'n_certificate.placeholder_box',
    `选择的课程券信息将被显示。`,
    `選擇的課程券信息將被顯示。`,
    `選択したレッスンチケットの情報が公開されます。`,
    ``,
  ],
  certificate_inquiry: [`수강증 문의`, `Certificate Inquiry`, 'n_certificate.certificate_inquiry', ``, ``, ``, ``],
}
export const n_book_lessons = {
  title: [`수업 예약`, `Book Lessons`, 'n_book_lessons.title', `预约课程`, `預約課程`, `レッスン予約`, ``],
  my_credit: [
    `내 수업권`,
    `My credits`,
    'n_book_lessons.my_credit',
    `我的课程券`,
    `我的課程券`,
    `あなたのレッスンチケット`,
    ``,
  ],
  buy_credit: [`구매하기`, `Buy`, 'n_book_lessons.buy_credit', `购买`, `購買`, `購入する`, ``],
  total_tutor_number: (number) => [
    `총 ${number}명`,
    `${number} tutors`,
    'n_book_lessons.total_tutor_number',
    `共 ${number}名教师`,
    `共 ${number}名教師`,
    `合計${number}人`,
    ``,
  ],
  filter: {
    title: [`필터`, `Filter`, 'n_book_lessons.filter.title', `筛选`, `篩選`, `フィルター`, ``],
    gender_title: [`성별`, `Gender`, 'n_book_lessons.filter.gender_title', `性别`, `性別`, `性別`, `Giới tính`],
    gender_male: [`남자`, `Male`, 'n_book_lessons.filter.gender_male', `男性`, `男性`, `男性`, `Nam`],
    gender_female: [`여자`, `Female`, 'n_book_lessons.filter.gender_female', `女性`, `女性`, `女性`, `Nữ`],
    accent_title: [`억양`, `Accent`, 'n_book_lessons.filter.accent_title', `口音`, `口音`, `アクセント`, `Giọng`],
    accent_usa: [`미국식`, `American`, 'n_book_lessons.filter.accent_usa', `美式`, `美式`, `アメリカ`, `Giọng Mỹ`],
    accent_british: [
      `영국식`,
      `British`,
      'n_book_lessons.filter.accent_british',
      `英式`,
      `英式`,
      `イギリス`,
      `Giọng Anh`,
    ],
    major_title: [`전공`, `Major`, 'n_book_lessons.filter.major_title', `专业`, `主修`, `専攻`, `Chuyên môn`],
    major_social_science: [
      `사회과학경영`,
      `Social Sciences/Business`,
      'n_book_lessons.filter.major_social_science',
      `社会科学/经营`,
      `社會科學 / 商管`,
      `社会科学・経営`,
      `Khoa học xã hội/Kinh doanh`,
    ],
    major_liberal_arts: [
      `인문계`,
      `Liberal Arts`,
      'n_book_lessons.filter.major_liberal_arts',
      `人文系`,
      `人文`,
      `人文系`,
      `Giáo dục khai phóng`,
    ],
    major_engineering: [
      `공과계열`,
      `Engineering`,
      'n_book_lessons.filter.major_engineering',
      `工学系`,
      `理工`,
      `工科系`,
      `Kỹ sư`,
    ],
    major_natural_science: [
      `자연과학계열`,
      `Natural Science`,
      'n_book_lessons.filter.major_natural_science',
      `自然科学系`,
      `自然科學`,
      `自然科学系`,
      `Khoa học tự nhiên`,
    ],
    major_fine_arts: [
      `예체능`,
      `Fine Arts and Athletics`,
      'n_book_lessons.filter.major_fine_arts',
      `艺体专业`,
      `藝術／體育`,
      `芸能・芸術・体育`,
      `Mỹ thuật và Thể dục`,
    ],
    etc_title: [`기타`, `Other`, 'n_book_lessons.filter.etc_title', `其他`, `其他`, `その他`, ``],
    etc_tutors_met: [
      `내가 수업한 튜터만 보기`,
      `My Prev. Tutors`,
      'n_book_lessons.filter.etc_tutors_met',
      `和我上过课的教师`,
      `和我上過課的教師`,
      `自分が受講したチューターだけを見る`,
      ``,
    ],
    empty_desc_title: [
      `조건을 만족하는 튜터가 없어요.`,
      `No tutors match this criteria.`,
      'n_book_lessons.filter.empty_desc_title',
      `没有符合关键字的教师`,
      `沒有符合關鍵字的教師`,
      `希望に合うチューターがいません。`,
      ``,
    ],
    empty_desc_subitle: [
      `필터를 다시 설정해보세요.`,
      `Please adjust the filter and try again.`,
      'n_book_lessons.filter.empty_desc_subitle',
      `请调整删选条件并再试一次`,
      `請調整刪選條件並再試一次`,
      `絞込条件を変更して再度お試しください。`,
      ``,
    ],
    all: [`전체`, `All`, 'n_book_lessons.filter.all', `全部`, `全部`, `すべて`, ``],
  },
  last_min_time: [
    `당일수업`,
    `Last-minute Lessons`,
    'n_book_lessons.last_min_time',
    `当日课程`,
    `當日課程`,
    `当日レッスン`,
    ``,
  ],
  selected_lessons: [
    `선택한 수업`,
    `Selected`,
    'n_book_lessons.selected_lessons',
    `已选课程`,
    `已選課程`,
    `選択したレッスン`,
    ``,
  ],
  label_restore: [
    `불만족시 수업권 복구`,
    `Credit-back guarantee`,
    'n_book_lessons.label_restore',
    `对课程不满意时退还课程券`,
    `對課程不滿意時退還課程券`,
    `満足いただけない場合のレッスンチケット再発行`,
    ``,
  ],
  auto_maching: {
    title: [
      `링글 자동 매칭`,
      `Auto matching`,
      'n_book_lessons.auto_maching.title',
      `Ringle自动配对`,
      `Ringle自動配對`,
      `自動マッチング`,
      ``,
    ],
    content: [
      `예약 가능한 튜터 중, 평점이 높고 성실한 튜터와 자동으로 매칭해 드려요`,
      `We'll find the best tutor available for you.`,
      'n_book_lessons.auto_maching.content',
      `我们将为您配对最好的教师`,
      `我們將為您配對最好的教師`,
      `予約可能なチューターの中から、評価が高い講師と自動的にマッチングします。`,
      ``,
    ],
  },
  time_picker: {
    title: [
      `시간 선택`,
      `Select a time slot`,
      'n_book_lessons.time_picker.title',
      `选择时间`,
      `選擇時間`,
      `時間選択`,
      ``,
    ],
    see_liked_tutors_schedule: [
      `찜한 튜터의 예약 가능 시간만 보기`,
      `Favorite Tutors only`,
      'n_book_lessons.time_picker.see_liked_tutors_schedule',
      `只显示我收藏的教师`,
      `只顯示我收藏的教師`,
      `お気に入りのチューターのみの予約可能時間を見る`,
      ``,
    ],
    date: [`날짜`, `Date`, 'n_book_lessons.time_picker.date', `日期`, `日期`, `日付`, ``],
    time: [`시간`, `Time`, 'n_book_lessons.time_picker.time', `时间`, `時間`, `時間`, ``],
    already_booked: [
      `수업 예정`,
      `Already booked`,
      'n_book_lessons.time_picker.already_booked',
      `已经预约的课程`,
      `已經預約的課程`,
      `レッスン予定`,
      ``,
    ],
    placeholder: [
      `날짜 및 시간을 선택하세요`,
      `Please select a date and a time`,
      'n_book_lessons.time_picker.placeholder',
      `请选择日期与时间`,
      `請選擇日期與時間`,
      `日時を選択してください。`,
      ``,
    ],
  },
  no_credit: {
    title: [
      `수업권이 없습니다.`,
      `No credits left.`,
      'n_book_lessons.no_credit.title',
      `没有可用的课程券`,
      `沒有可用的課程券`,
      `レッスンチケットはありません。`,
      ``,
    ],
    subtitle: [
      `수업권을 구매하러 갈까요?`,
      `Would you like to buy credits now?`,
      'n_book_lessons.no_credit.subtitle',
      `要购买课程券吗？`,
      `要購買課程券嗎？`,
      `レッスンチケットを購入しませんか？`,
      ``,
    ],
    btn_purchase_credit: [
      `수업권 구매하기`,
      `Buy now`,
      'n_book_lessons.no_credit.btn_purchase_credit',
      `前往购买课程券`,
      `前往購買課程券`,
      `レッスンチケットを購入する`,
      ``,
    ],
  },
  alert_48_hours_below: [
    `선택하신 수업은 48시간 이내에 시작하는 수업입니다. 튜터와의 시차 및 응답 속도에 따라 매칭되지 않을 가능성이 높습니다.`,
    `This lesson starts in less than 48 hours. Tutor may not confirm in time.`,
    'n_book_lessons.alert_48_hours_below',
    `您选择的课程为48小时以内即将开始的课程，依据与任课教师的时差与回复速度，很有可能预约失败。`,
    `您選擇的課程為48小時以內即將開始的課程，依據與任課教師的時差與回覆速度，很有可能預約失敗。`,
    `選択したレッスンは48時間以内に始まるレッスンです。チューターとの時差および返答スピードからマッチングしない可能性が高いです。`,
    `Buổi học này bắt đầu trong vòng 48 giờ nữa. Gia sư có thể không kịp xác nhận.`,
  ],
  select_credit: {
    title: [
      `수업권 선택`,
      `Select credit`,
      'n_book_lessons.select_credit.title',
      `选择课程券`,
      `選擇課程券`,
      `レッスンチケットの選択`,
      ``,
    ],
    time_booked: [
      `수업 시간`,
      `Lesson date/time`,
      'n_book_lessons.select_credit.time_booked',
      `课程时间`,
      `課程時間`,
      `授業時間`,
      ``,
    ],
    expired: (n) => [
      <>수강 기간: {n}일 남음</>,
      <>Expires in: {n} days</>,
      'n_book_lessons.select_credit.expired',
      <>距离课程：还有{n}天</>,
      <>距離課程：還有{n}天</>,
      <>レッスン時間: 残り{n}日</>,
      <>Thời gian học: Còn {n} ngày</>,
    ],
    remaining_credit: [
      `남은 수업권`,
      `Unused Credits`,
      'n_book_lessons.select_credit.remaining_credit',
      `剩馀的课程券`,
      `剩餘的課程券`,
      `残りのレッスンチケット`,
      ``,
    ],
    credit_number: (number) => [
      `${number}회`,
      `${number}`,
      'n_book_lessons.select_credit.credit_number',
      `${number}`,
      `${number}`,
      `${number}回`,
      ``,
    ],
    label_free: [`무료`, `Free`, 'n_book_lessons.select_credit.label_free', `免费`, `免費`, `無料`, ``],
    btn_selected: [`선택`, `Apply`, 'n_book_lessons.select_credit.btn_selected', `选择`, `選擇`, `選択`, ``],
    alert_50_pointback: [
      `50% 포인트백 혜택은 유료 수업권으로 신청 시 받을 수 있습니다.`,
      `50% point-back only applies to lessons taken using paid credits.`,
      'n_book_lessons.select_credit.alert_50_pointback',
      `50%返还积分优惠仅适用于付费课程。`,
      `50% 點數回饋優惠僅適用於付費課程。`,
      `50%ポイントバックの特典は、有料レッスンチケットでお申し込みの際に受けることができます。`,
      ``,
    ],
  },
  tutor_maching_option: {
    title: [
      `튜터 매칭 옵션`,
      `Tutor matching options`,
      'n_book_lessons.tutor_maching_option.title',
      `教师配对选项`,
      `教師配對選項`,
      `チューターマッチングオプション`,
      `Lựa chọn sắp xếp gia sư`,
    ],
    subtitle: [
      `이 튜터와 매칭되지 않을 경우 어떻게 할까요?`,
      `If matching fails, what should we do?`,
      'n_book_lessons.tutor_maching_option.subtitle',
      `如果配对失败，你想要如何处理？`,
      `如果配對失敗，你想要如何處理？`,
      `このチューターとマッチングが成立しなかった場合はどのようにしますか？`,
      ``,
    ],
    maching_another_tutors: [
      `다른 튜터 매칭 받기`,
      `Match me with another tutor`,
      'n_book_lessons.tutor_maching_option.maching_another_tutors',
      `接受与其他教师配对`,
      `接受與其他教師配對`,
      `他のチューターとマッチングしてもらう`,
      `Tự động xếp tôi với một gia sư khác.`,
    ],
    restore_credit: [
      `수업 취소 후 사용한 수업권 복구 받기`,
      `Restore my lesson credit`,
      'n_book_lessons.tutor_maching_option.restore_credit',
      `取消课程后退还已使用的课程券`,
      `取消課程後退還已使用的課程券`,
      `予約をキャンセルし、使用したレッスンチケットを復元する`,
      `Khôi phục thẻ học của tôi`,
    ],
  },
  add_more_lesson: [
    `추가 수업 선택`,
    `Add another lesson`,
    'n_book_lessons.add_more_lesson',
    `增加另一堂课`,
    `增加另一堂課`,
    `追加レッスンの選択`,
    ``,
  ],
  booking_complete: [
    `수업 예약 완료`,
    `Finalize booking`,
    'n_book_lessons.booking_complete',
    `完成课程预约`,
    `完成課程預約`,
    `レッスン予約完了`,
    ``,
  ],
  tooltip_another_tutor_maching_1: [
    `튜터 매칭옵션에서 [다른 튜터 매칭 받기]를 선택하면, 튜터와 매칭이 안될 경우 평점이 높은 튜터와 다시 매칭해 드립니다.`,
    `Choosing [Match me with another tutor] will match you with a high-rated tutor if the current matching fails.`,
    'n_book_lessons.tooltip_another_tutor_maching_1',
    `如果在教师配对选项中选择[重新配对]，并且配对不成功，系统将再次为您配对评分较高的教师。`,
    `如果在教師配對選項中選擇[重新配對]，並且配對不成功，系統將再次為您配對評分較高的教師。`,
    `チューターマッチングオプションで[他のチューターマッチングを受ける]を選択すると、チューターとマッチングができない場合、評価の高いチューターと再マッチングします。`,
    ``,
  ],
  tooltip_another_tutor_maching_2: [
    `*매칭된 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.`,
    `*Credit-back guaranteed if unsatisfied with the auto-matched tutor.`,
    'n_book_lessons.tooltip_another_tutor_maching_2',
    `如果对与配对教师进行的课程不满意，我们将退还您相应的课程券。`,
    `如果對與配對教師進行的課程不滿意，我們將退還您相應的課程券。`,
    `*マッチングされたチューターとのレッスン満足度が低い場合は、レッスンチケットを再発行します。`,
    ``,
  ],
  modal_confirm: {
    title: [
      `예약 전 확인해주세요.`,
      `Please check before finalizing your booking.`,
      'n_book_lessons.modal_confirm.title',
      `请在预约确认前进行最后确认`,
      `請在預約確認前進行最後確認`,
      `ご予約前にご確認ください。`,
      ``,
    ],
    last_min_time_caution_no_restore: [
      `예약하려는 수업은 당일수업입니다.  해당 수업은 수업 신청 후 취소시 수업권이 복구되지 않습니다.`,
      `Your booking includes a last-minute lesson. The credit you used to book this lesson will not be restored if you cancel the lesson after booking.`,
      'n_book_lessons.modal_confirm.last_min_time_caution_no_restore',
      `预约的课程是当天课程。在预约课程后，如果取消该课程，课程券将无法退还。`,
      `預約的課程是當天課程。在預約課程後，如果取消該課程，課程券將無法退還。`,
      `予約を検討されているレッスンは、当日レッスンです。当該レッスンは、レッスン申し込み後にキャンセルした場合、レッスンチケットの再発行はできません。`,
      ``,
    ],
    last_min_time_caution_15_min: [
      `예약하려는 수업이 1시간 이내 시작합니다. 원활한 수업 진행을 위해 Small Talk 교재로 자동 선택됩니다.`,
      `Lesson is scheduled to start in less than 1 hour. Lesson material will be auto-selected.`,
      'n_book_lessons.modal_confirm.last_min_time_caution_15_min',
      `您预订的课程将在1小时内开始。为了顺利进行课程，教材将自动选择Small Talk教材。`,
      `您預訂的課程將在1小時內開始。為了順利進行課程，教材將自動選擇Small Talk教材。`,
      `予約されているレッスンは1時間以内に始まります。スムーズな授業進行のために、Small Talk教材が自動的に選択されます。`,
      ``,
    ],
    pointback_no_point: [
      `무료 수업권으로 예약했습니다. 50% 포인트백이 적용되지 않습니다.`,
      ``,
      'n_book_lessons.modal_confirm.pointback_no_point',
      `用免费课券预约课程，将不适用50％积分返点活动。`,
      `用免費課券預約課程，將不適用50％點數活動。`,
      `フリーレッスンチケットで予約しました。 50% ポイントバックは適用されま`,
      ``,
    ],
  },
  complete_lesson: {
    title: [
      `수업을 예약했어요`,
      `Lesson has been successfully booked`,
      'n_book_lessons.complete_lesson.title',
      `课程预约已完成`,
      `課程預約已完成`,
      `レッスンの予約が完了しました。`,
      ``,
    ],
    list_title: [
      `예약 내역 확인`,
      `Booking details`,
      'n_book_lessons.complete_lesson.list_title',
      `确认课程预约内容`,
      `確認課程預約內容`,
      `予約履歴の確認`,
      ``,
    ],
    guide_title: [
      `수업 준비 안내`,
      `Before the lesson`,
      'n_book_lessons.complete_lesson.guide_title',
      `课前准备`,
      `課前準備`,
      `レッスン準備のご案内`,
      ``,
    ],
    guide_content: [
      <>
        <li className="li-tag-inside">교재 선택 후, 인사이트를 읽고 수업 질문을 선택하세요.</li>
        <li className="li-tag-inside">맞춤형 수업을 원한다면, 수업 방식도 설정해 주세요.</li>
        <li className="li-tag-inside">
          수업 시작 15분 전까지 교재가 선택되지 않은 경우, 링글러 Top Pick 교재로 선택됩니다.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Please choose a material, read the insight, and choose the questions for the lesson.
        </li>
        <li className="li-tag-inside">
          If no material is chosen by 1 hour before the lesson, a 'Small Talk' material will be auto-selected.
        </li>
        <li className="li-tag-inside">
          Set the lesson style in advance to let the tutor know of your lesson preferences.
        </li>
      </>,
      'n_book_lessons.complete_lesson.guide_content',
      <>
        <li className="li-tag-inside">选择教材后，请阅读文章并选择课程中要讨论的问题。</li>
        <li className="li-tag-inside">如果在课程开始前1小时内未选择教材，将自动选择Small Talk教材。</li>
        <li className="li-tag-inside">有任何想要的上课方式吗？请设定课程模式让教师确认。</li>
      </>,
      <>
        <li className="li-tag-inside">選擇教材後，請閱讀文章並選擇課程中要討論的問題。</li>
        <li className="li-tag-inside">如果在課程開始前1小時內未選擇教材，將自動選擇Small Talk教材。</li>
        <li className="li-tag-inside">有任何想要的上課方式嗎？請設定課程模式讓教師確認。</li>
      </>,
      <>
        <li className="li-tag-inside"> 教材を選択した後、インサイトを読み、授業で扱う質問を選択します。</li>
        <li className="li-tag-inside">
          レッスン開始1時間前までに教材を選択しない場合、Small Talkの教材が自動的に選択されます。
        </li>
        <li className="li-tag-inside">
          「レッスン方式の設定」から、ご希望のレッスン方式をチューターにお知らせください。
        </li>
      </>,
      ``,
    ],
    btn_prepare: [
      `교재 선택하기`,
      `Select material(s)`,
      'n_book_lessons.complete_lesson.btn_prepare',
      `选择教材`,
      `選擇教材`,
      `教材を選ぶ`,
      ``,
    ],
    btn_book_more: [
      `추가 수업 예약하기`,
      `Book more lessons`,
      'n_book_lessons.complete_lesson.btn_book_more',
      `预约更多课程`,
      `預約更多課程`,
      `追加レッスンを予約する`,
      ``,
    ],
    book_more_caption: [
      `꾸준한 수업으로 영어 실력 키우고 싶다면?`,
      `Wish to improve your English with consistent lessons?`,
      'n_book_lessons.complete_lesson.book_more_caption',
      `持续进行课程快速提升英文实力`,
      `持續進行課程快速提升英文實力`,
      `継続受講して英語力を向上させたい方向け`,
      ``,
    ],
    title_more: [
      `추가 수업을 예약했어요`,
      `Lessons booked successfully`,
      'n_book_lessons.complete_lesson.title_more',
      `已完成追加课程预约`,
      `已完成追加課程預約`,
      `レッスンを予約しました`,
      ``,
    ],
    btn_lesson_prep: [
      `수업 준비하기`,
      `Prep for lesson`,
      'n_book_lessons.complete_lesson.btn_lesson_prep',
      `准备课程`,
      `準備課程`,
      `レッスンの準備をする`,
      ``,
    ],
    book_more_caption_2: [
      <>
        <span className="text-[#503cc8]">매주 수업 듣기 도전 중이네요!</span>
      </>,
      <>
        <span className="text-[#503cc8]">You chose Every Week credits!</span>
      </>,
      'n_book_lessons.complete_lesson.book_more_caption_2',
      <>
        <span className="text-[#503cc8]">您正挑战每週上一堂课！</span>
      </>,
      <>
        <span className="text-[#503cc8]">您正挑戰每週上一堂課！</span>
      </>,
      `毎週レッスンに挑戦しています！`,
      ``,
    ],
    book_more_caption_3: [
      `수업권 차감 없이 수업하고 싶다면?`,
      `If you wish to avoid credit deductions?`,
      'n_book_lessons.complete_lesson.book_more_caption_3',
      `如果您希望避免扣除积分？`,
      `如果您希望避免扣除積分？`,
      `クレジットの減算を避けたい場合は？`,
      ``,
    ],
  },
  rules: {
    check_rules: [
      `링글 수업 에티켓`,
      `Lesson Rules`,
      'n_book_lessons.rules.check_rules',
      `Ringle上课守则`,
      `Ringle上課守則`,
      `レッスンのルール`,
      ``,
    ],
    follow_guidelines: [
      `수업 에티켓을 지키지 않으면, 링글 수업이 제한될 수 있습니다.`,
      `If the rules of conduct are not followed, future lessons may be limited.`,
      'n_book_lessons.rules.follow_guidelines',
      `未遵守上课礼仪时，可能导致无法进行Ringle课程。`,
      `未遵守上課禮儀時，可能導致無法進行Ringle課程。`,
      `レッスンエチケットを守らない場合、Ringleレッスンが制限されることがあります。`,
      `Nếu các hướng dẫn dưới đây không được tuân thủ, các buổi học trong tương lai sẽ bị hạn chế.`,
    ],
    set_up: [
      `알맞은 수업 환경 준비하기`,
      `Set up an Environment for Learning`,
      'n_book_lessons.rules.set_up',
      `准备合适的学习环境`,
      `準備合適的學習環境`,
      `適切なレッスン環境を準備する`,
      `Thiết lập môi trường học tập`,
    ],
    do_not_drive: [
      `운전 중 / 이동 중 수업은 삼가 주세요.`,
      `Don’t Zoom and drive.`,
      'n_book_lessons.rules.do_not_drive',
      `请勿于驾驶/移动中进行课程。`,
      `請勿於開車／移動中進行課程。`,
      `運転中 / 移動中のレッスンはお控えください。`,
      `Không dùng Zoom trong khi lái xe.`,
    ],
    no_drinking: [
      `음주 후 수업은 자제해 주세요.`,
      `No drinking during lessons.`,
      'n_book_lessons.rules.no_drinking',
      `请勿饮酒后上课。`,
      `請勿於飲酒後上課。`,
      `飲酒後のレッスンはご遠慮ください。`,
      `Không sử dụng đồ uống có cồn trong buổi học.`,
    ],
    punctual: [
      `약속된 시간 지키기`,
      `Be Punctual`,
      'n_book_lessons.rules.punctual',
      `请准时`,
      `請準時`,
      `約束の時間を守る`,
      `Đúng giờ`,
    ],
    keep_schdules: [
      `정해진 수업 시간을 지켜주세요.`,
      `Keep track of your lesson schedules.`,
      'n_book_lessons.rules.keep_schdules',
      `请遵守预约的上课时间。`,
      `請遵守預約的上課時間。`,
      `決められたレッスン時間を守ってください。`,
      `Theo dõi thời khoá biểu của bạn`,
    ],
    no_external_request: [
      `수업 종료 이후 요청은 자제해 주세요.`,
      `Tutors aren’t responsible for\nany external lesson requests.`,
      'n_book_lessons.rules.no_external_request',
      `课程结束后，请勿向教师提出要求。`,
      `課程結束後，請勿向教師提出要求。`,
      `レッスン終了以降のリクエストはご遠慮ください。`,
      `Gia sư không chịu tránh nhiệm cho\nbất kỳ yêu cầu nào ngoài buổi học.`,
    ],
    with_respect: [
      `튜터 존중하기`,
      `Treat Your Tutor with Respect`,
      'n_book_lessons.rules.with_respect',
      `尊重教师`,
      `尊重教師`,
      `チューターを尊重する`,
      `Tôn trọng gia sư của bạn`,
    ],
    no_photos: [
      `사적인 질문, 사진 촬영 등은 삼가 주세요.`,
      `Refrain from asking\nsensitive questions or taking photographs.`,
      'n_book_lessons.rules.no_photos',
      `上课时请勿提出私人问题或拍照。`,
      `上課時請勿提出私人問題或拍照。`,
      `レッスン中の私的な質問や写真撮影などはお控えください。`,
      `Không hỏi những câu hỏi\nnhạy cảm hoặc chụp hình.`,
    ],
    no_discriminatory_remarks: [
      `의도치 않은 혐오 발언을 주의해 주세요.`,
      `Do not make discriminatory remarks.`,
      'n_book_lessons.rules.no_discriminatory_remarks',
      `请欲于课堂中使用不恰当的言语。`,
      `請欲於課堂中使用不恰當的言語。`,
      `不用意な嫌悪発言にご注意ください。`,
      `Không đưa ra những nhận xét phân biệt đối xử.`,
    ],
    focus_on_learning: [
      `영어 학습 목적에 충실하기`,
      `Focus on Learning English`,
      'n_book_lessons.rules.focus_on_learning',
      `专注于英语学习`,
      `專注於英文學習`,
      `英語学習の目的に忠実に`,
      `Tập trung vào việc học tiếng Anh`,
    ],
    avoid_unrelated: [
      `수업 중 수업과 관련 없는 요청은 삼가 주세요.`,
      `Avoid making requests unrelated to the lesson.`,
      'n_book_lessons.rules.avoid_unrelated',
      `请避免向教师提出与课程无关的请求。`,
      `請避免向教師提出與課程無關的要求。`,
      `チューターへのレッスンに関係のないリクエストはお控えください。`,
      `Tránh đưa ra những yêu cầu không liên quan đến buổi học.`,
    ],
    turn_on_video: [
      `학습 효과를 위해 카메라는 꼭 켜주세요.`,
      `Turn on video for effective communication.`,
      'n_book_lessons.rules.turn_on_video',
      `为了提升学习效果，请务必开启摄像头。`,
      `為了提升學習效果，請務必開啟相機。`,
      `学習効果のためにカメラは必ずオンにしてください。`,
      `Bật video để giao tiếp hiệu quả hơn.`,
    ],
  },
  exit_modal: {
    title: [
      `선택한 수업이 있습니다.`,
      `You have an unbooked lesson.`,
      'n_book_lessons.exit_modal.title',
      `已选择课程。`,
      `已選擇課程。`,
      `選択したレッスンがあります。`,
      ``,
    ],
    content: [
      <>
        페이지를 이동하면, 선택한 수업이 모두 초기화 돼요.
        <br />
        <br />
        다른 페이지로 이동하시겠어요?
      </>,
      <>
        If you leave this page, you will have to start again from the beginning.
        <br />
        <br />
        Leave this page anyway?
      </>,
      'n_book_lessons.exit_modal.content',
      <>
        离开页面后，所选的课程将消失。
        <br />
        <br />
        是否要离开页面？
      </>,
      <>
        離開頁面後，所選的課程將消失。
        <br />
        <br />
        是否要離開頁面？
      </>,
      <>
        ページを移動すると、選択したレッスンがすべて初期化されます。
        <br />
        <br />
        他のページに移動しますか？
      </>,
      ``,
    ],
    btn_cancel: [
      `수업 예약 마저 하기`,
      `Continue booking`,
      'n_book_lessons.exit_modal.btn_cancel',
      `继续预约课程`,
      `繼續預約課程`,
      `レッスンの予約を完了する`,
      ``,
    ],
    btn_move: [
      `페이지 이동`,
      `Leave page`,
      'n_book_lessons.exit_modal.btn_move',
      `离开页面`,
      `離開頁面`,
      `ページの移動`,
      ``,
    ],
  },
  AM_hours: [`오전`, `AM`, 'n_book_lessons.AM_hours', `AM`, `AM`, `AM`, `AM`],
  PM_hours: [`오후`, `PM`, 'n_book_lessons.PM_hours', `PM`, `PM`, `PM`, `PM`],
  empty_selected_lessons: [
    `선택한 수업이 없어서 이전 단계로 이동합니다.`,
    ``,
    'n_book_lessons.empty_selected_lessons',
    `没有您选择的课程，将回到上一步`,
    `沒有您選擇的課程，將回到上一步`,
    `選択したレッスンがないので、前のステップに移動します。`,
    ``,
  ],
  delete_modal: {
    title: [
      `선택한 수업을 삭제하시겠습니까?`,
      `Are you sure you want to cancel this lesson?`,
      'n_book_lessons.delete_modal.title',
      `您确定要删除所选课程吗？`,
      `您確定要刪除所選課程嗎？`,
      `選択したレッスンを削除しますか？`,
      ``,
    ],
  },
  time: {
    empty_desc_title: [
      <>
        선택한 시간에 수업 가능한 튜터가 없습니다.
        <br />
        다른 시간을 선택해주세요.
      </>,
      <>
        There are no tutors available for the chosen time slot.
        <br />
        Please select another time.
      </>,
      'n_book_lessons.time.empty_desc_title',
      <>
        您所选的时间没有教师可以预约
        <br />
        请选择其他时间
      </>,
      <>
        您所選的時間沒有教師可以預約
        <br />
        請選擇其他時間
      </>,
      <>
        選択した時間帯に対応可能なチューターがいません。
        <br />
        他の時間を選択してください。
      </>,
      ``,
    ],
  },
  book_favorites: [
    `찜한 튜터 예약하기`,
    `Book my favorite tutors`,
    'n_book_lessons.book_favorites',
    `预约我收藏的教师`,
    `預約我收藏的教師`,
    `お気に入りチューター予約`,
    ``,
  ],
  last_min_time_new: [
    `당일수업 (24시간 이내 수업)`,
    `Last-minute lessons (start within 24hrs)`,
    'n_book_lessons.last_min_time_new',
    `当天课程(24小时以内开始的课程）`,
    `當天課程(24小時以內開始的課程）`,
    `当日コース(24時間以内開始)`,
    ``,
  ],
  favorites_tutor_empty: [
    <>
      내 찜한 튜터의
      <br />
      예약 가능한 시간이 없어요
    </>,
    <>
      There are no available slots
      <br />
      with your favorite tutor.
    </>,
    'n_book_lessons.favorites_tutor_empty',
    <>
      我收藏的教师
      <br />
      没有可预约的时间
    </>,
    <>
      我收藏的教師
      <br />
      沒有可預約的時間
    </>,
    <>
      お気に入りのチューターの <br />
      予約可能な時間がありません。
    </>,
    ``,
  ],
  empty_credit: {
    title: [
      `수업권이 없습니다.`,
      `No credits left.`,
      'n_book_lessons.empty_credit.title',
      `没有可用的课程券`,
      `沒有可用的課程券`,
      `レッスンチケットはありません。`,
      ``,
    ],
    case1: (exp_date) => [
      <>보유한 수업권으로는 {exp_date}까지 진행하는 수업만 예약할 수 있습니다.</>,
      <>You can only book lessons up to {exp_date} with your existing credits.</>,
      'n_book_lessons.empty_credit.case1',
      <>您持有的课程券只能预约到${exp_date}为止的课程。</>,
      <>您持有的課程券只能預約到${exp_date}為止的課程。</>,
      <>保有しているレッスンチケットでは、{exp_date}までのレッスンのみ予約可能です。</>,
      ``,
    ],
    case2: [
      `보유한 당일수업권은 24시간 이내 시작하는 수업만 예약할 수 있습니다.`,
      `You can only book lessons that start in 24 hours with your existing credits.`,
      'n_book_lessons.empty_credit.case2',
      `您持有的课程券只能预约24小时内开始的课程。`,
      `您持有的課程券只能預約24小時內開始的課程。`,
      `保有しているレッスンチケットでは、24時間以内に開始するレッスンしか予約できません。`,
      ``,
    ],
    case3: [
      `보유한 40분 수업권으로는 40분 수업만 예약할 수 있습니다.`,
      `You can only book 40-min lessons with your existing credits.`,
      'n_book_lessons.empty_credit.case3',
      `您持有的课程券只能预约40分钟的课程。`,
      `您持有的課程券只能預約40分鐘的課程。`,
      `保有しているレッスンチケットでは、40分のレッスンしか予約できません。`,
      ``,
    ],
    case4: [
      `보유한 20분 수업권으로는 20분 수업만 예약할 수 있습니다.`,
      `You can only book 20-min lessons with your existing credits.`,
      'n_book_lessons.empty_credit.case4',
      `您持有的课程券只能预约20分钟的课程。`,
      `您持有的課程券只能預約20分鐘的課程。`,
      `保有しているレッスンチケットでは、20分のレッスンしか予約できません。`,
      ``,
    ],
  },
  schedule_span_available: (exp_date) => [
    `${exp_date}까지 진행하는 수업 예약 가능`,
    `You can book lessons up to ${exp_date}.`,
    'n_book_lessons.schedule_span_available',
    `可预约到${exp_date}为止的课程`,
    `可預約到${exp_date}為止的課程`,
    `${exp_date}までのレッスン予約が可能です。`,
    ``,
  ],
  schedule_span_unavailable_20min: [
    `예약 가능한 20분 수업권 없음`,
    `You have no 20-min credits.`,
    'n_book_lessons.schedule_span_unavailable_20min',
    `没有可使用的20分钟课程券`,
    `沒有可使用的20分鐘課程券`,
    `予約可能な20分レッスンチケットはありません。`,
    ``,
  ],
  schedule_span_unavailable_40min: [
    `예약 가능한 40분 수업권 없음`,
    `You have no 40-min credits.`,
    'n_book_lessons.schedule_span_unavailable_40min',
    `没有可使用的40分钟课程券`,
    `沒有可使用的40分鐘課程券`,
    `予約可能な40分レッスンチケットはありません。`,
    ``,
  ],
  schedule_span_last_minute: [
    `24시간 이내 시작하는 수업 예약 가능`,
    `You can book lessons that start in 24 hours.`,
    'n_book_lessons.schedule_span_last_minute',
    `24小时内可以预约的课程`,
    `24小時內可以預約的課程`,
    `24時間以内に開始するレッスンの予約が可能です。`,
    ``,
  ],
  book_more_popup: {
    caption_1: [
      `영어 실력을 크게 향상시키고 싶다면?`,
      `Wish to take your English to the next level?`,
      'n_book_lessons.book_more_popup.caption_1',
      `如果想要快速提升英语实力`,
      `如果想要快速提升英文能力`,
      `短期間で英語力を向上させたいですか？`,
      ``,
    ],
    title_1: [
      <>
        한 달 수업 예약으로 매주 1회
        <br />
        꾸준히 수업해 보세요.
      </>,
      <>
        Book regular weekly lessons
        <br />
        for the whole month.
      </>,
      'n_book_lessons.book_more_popup.title_1',
      <>
        以月为单位预约，每週一次
        <br />
        学习不间断
      </>,
      <>
        以月為單位預約，每週一次
        <br />
        學習不間斷
      </>,
      <>
        毎週1回、1ヵ月のレッスンを予約して
        <br />
        継続的に学習をしましょう。
      </>,
      ``,
    ],
    desc_1: [
      <>
        영어 실력이 가장 많이 오른 상위 8% 링글러들은
        <br />
        매주 수업을 꾸준히 들었어요!
      </>,
      <>
        The top 8% of Ringlers who improved their
        <br />
        English the most took lessons every week!
      </>,
      'n_book_lessons.book_more_popup.desc_1',
      <>
        Ringle学习者中进步最快的8%
        <br />
        都坚持每週上课！
      </>,
      <>
        Ringle學習者中進步最快的8%
        <br />
        都堅持每週上課！
      </>,
      <>
        英語が上達したRingle学習者の上位8%は
        <br />
        毎週レッスンを受講していました！
      </>,
      ``,
    ],
    btn_1: [
      `한 달 수업 예약하기`,
      `Book lessons for a month`,
      'n_book_lessons.book_more_popup.btn_1',
      `预约一个月课程`,
      `預約一個月課程`,
      `1ヶ月のレッスンを予約する`,
      ``,
    ],
    btn_2: [
      `하나만 예약할래요`,
      `Book just one lesson`,
      'n_book_lessons.book_more_popup.btn_2',
      `只预约一堂`,
      `只預約一堂`,
      `1レッスンのみ予約する`,
      ``,
    ],
    caption_2: [
      `매주 수업 듣기 도전 중이네요!`,
      `You chose Every Week credits!`,
      'n_book_lessons.book_more_popup.caption_2',
      `您正挑战每週上一堂课！`,
      `您正挑戰每週上一堂課！`,
      `毎週レッスンに挑戦しています！`,
      ``,
    ],
    title_2: [
      <>
        수업권 차감 없이 수업하고 싶다면?
        <br />한 달 수업을 미리 예약해 보세요.
      </>,
      <>
        Wish to take lessons consistently
        <br />
        without wasting your credits?
        <br />
        Book lessons for the whole month.
      </>,
      'n_book_lessons.book_more_popup.title_2',
      <>
        如果想完成每週挑战
        <br />
        就先预约一个月的课程吧
      </>,
      <>
        如果想完成每週挑戰
        <br />
        就先預約一個月的課程吧
      </>,
      <>
        チケットを無駄なく
        <br />
        定期的にレッスンを受講したいですか？
        <br />
        1ヶ月分のレッスンを事前に予約しましょう
      </>,
      ``,
    ],
    desc_2: [
      `수업권 차감없이 완강하는 링글러는 한 달 수업을 계획해 미리 예약하고 있어요.`,
      `Ringlers who use up their Every Week credits without deductions book the month's lessons in advance.`,
      'n_book_lessons.book_more_popup.desc_2',
      `完成每週课程挑战的学习者都提前预约一个月的课程`,
      `完成每週課程挑戰的學習者都提前預約一個月的課程`,
      `毎週分のチケットを使いきったRingle学習者は、1ヶ月のレッスンを事前に計画して予約しています。`,
      ``,
    ],
  },
}
export const n_coupon = {
  title: [`쿠폰`, `Coupons`, 'n_coupon.title', `优惠券`, `優惠券`, `クーポン`, `Coupons`],
  subtitle: [
    `쿠폰으로 수업권 할인과 수강기간 연장 혜택을 받으세요.`,
    `Check out the coupons that offer various benefits.`,
    'n_coupon.subtitle',
    `可以使用优惠券延长课程券使用期限`,
    `可以使用優惠券延長課程券使用期限`,
    `クーポンでレッスン料割引や受講期間の延長が可能です。`,
    ``,
  ],
  label: [`New`, `New`, 'n_coupon.label', `New`, `New`, `New`, ``],
  btn: [`사용하기`, `Reedem`, 'n_coupon.btn', `使用`, `使用`, `使用する`, ``],
  placeholder: [
    `지금 사용할 수 있는 쿠폰이 없어요.`,
    `There are no available coupons at the moment.`,
    'n_coupon.placeholder',
    `现在没有可使用的优惠券`,
    `現在沒有可使用的優惠券`,
    `現在利用できるクーポンがありません。`,
    ``,
  ],
  count: (n) => [<>{n}개</>, <>{n}</>, 'n_coupon.count', <>{n}个</>, <>{n}個</>, <>{n}個</>, ``],
  modal_title: [
    `수강기간 연장 쿠폰을 사용할 수 있는 수업권이 없어요.`,
    `Extension Coupon Unavailable`,
    'n_coupon.modal_title',
    `没有可以使用[延长使用期限优惠券]延长使用期限的课程。`,
    `沒有可以使用[延長使用期限優惠券]延長使用期限的課程。`,
    `受講期間延長チケットを使用できるレッスンチケットがありません。`,
    ``,
  ],
  modal_desc_1: [
    `매주 수업 듣기 수업권은 수강기간 연장 쿠폰을 사용할 수 없습니다.`,
    `Extension coupons cannot be applied to Every Week credits.`,
    'n_coupon.modal_desc_1',
    `每週课程券无法用[延长使用期限优惠券]延长使用期限。`,
    `每週課程券無法用[延長使用期限優惠券]延長使用期限。`,
    `受講期間延長チケットは毎週受講するレッスンチケットには適用できません。`,
    ``,
  ],
  modal_desc_2: [
    `수강기간 연장 쿠폰은 자유 수강 수업권에 적용할 수 있습니다.`,
    `Extension coupons can be applied to Flexible credits.`,
    'n_coupon.modal_desc_2',
    `[延长使用期限优惠券]适用于无固定时间课程券`,
    `[延長使用期限優惠券]適用於無固定時間課程券`,
    `受講期間延長チケットはフリーレッスンチケットに適用できます。`,
    ``,
  ],
  extend: {
    title: [
      `수강기간 연장 쿠폰을 사용할
수업권을 선택해주세요.`,
      `Please select a credits that allows for the use of a course extension coupon.`,
      'n_coupon.extend.title',
      `请选择欲使用 [延长使用期限优惠券] 延长使用时间的课程券。`,
      `請選擇欲使用 [延長使用期限優惠券] 延長使用時間的課程券。`,
      `受講期間延長チケットを使用する
 レッスンチケットを選択してください。`,
      ``,
    ],
    subtitle: [
      `매주 수업 듣기 수업권은 수강기간 연장 쿠폰이 적용되지 않습니다.`,
      `Extension coupons cannot be applied to Every Week credits.`,
      'n_coupon.extend.subtitle',
      `每週课程券无法用[延长使用期限优惠券]延长使用时间。`,
      `每週課程券無法用[延長使用期限優惠券]延長使用時間。`,
      `受講期間延長チケットは毎週受講するレッスンチケットには適用できません。`,
      ``,
    ],
    placeholder: [
      `수업권을 선택하세요.`,
      `Please select a credit`,
      'n_coupon.extend.placeholder',
      `请选择课程券`,
      `請選擇課程券`,
      `レッスンチケットを選択してください。`,
      ``,
    ],
    checkbox_1: [
      `수강기간을 연장할 수업권 정보를 확인했습니다.`,
      `I have checked the information on the credit I chose to extend using the coupon.`,
      'n_coupon.extend.checkbox_1',
      `已确认可以延长使用期限的课程券`,
      `已確認可以延長使用期限的課程券`,
      `受講期間を延長するレッスンチケット情報を確認しました。`,
      ``,
    ],
    checkbox_2: [
      `수강기간 연장 후에는 신규 구매한 수업권, 연장 적용된 수업권 모두 환불이 불가합니다.`,
      `I understand that the regular price for extending lesson days will be deducted if I choose to refund this credit in the future after applying the coupon.`,
      'n_coupon.extend.checkbox_2',
      `延长课程券的使用期限后若欲进行退款，将扣除延长使用期限费用后退款。`,
      `延長課程券的使用期限後若欲進行退款，將扣除延長使用期限費用後退款。`,
      `受講期間延長後、当該レッスンチケットを返金する場合、受講期間延長にかかる費用を差し引いて返金されます。`,
      ``,
    ],
    btn: [
      `수강기간 연장`,
      `Extend dates`,
      'n_coupon.extend.btn',
      `延长课程券使用期限`,
      `延長課程券使用期限`,
      `受講期間延長`,
      ``,
    ],
    modal_title: [
      `수강기간이 연장되었어요.`,
      `Extensions applied`,
      'n_coupon.extend.modal_title',
      `已延长延长课程券使用期限`,
      `已延長延長課程券使用期限`,
      `受講期間が延長されました。`,
      ``,
    ],
    modal_dsec: [
      `수업권 상세 내역에서 연장된 수강기간을 확인하세요.`,
      `Please check the extended dates of the credit(s).`,
      'n_coupon.extend.modal_dsec',
      `请确认已延长的课程券使用期限`,
      `請確認已延長的課程券使用期限`,
      `レッスンチケットの詳細から延長された受講期間をご確認ください。`,
      ``,
    ],
  },
  date_remain: (amount) => [
    `${amount}일 남음`,
    `${amount}day(s) left`,
    'n_coupon.date_remain',
    `剩下${amount}天`,
    `剩下${amount}天`,
    `残り${amount}日`,
    ``,
  ],
  hrs: (hrs) => [`${hrs}남음`, `${hrs} left`, 'n_coupon.hrs', `剩下${hrs}小时`, `剩下${hrs}小時`, `残り${hrs}`, ``],
  amount: (amount) => [
    `${amount}원 할인`,
    `Discount ${amount}`,
    'n_coupon.amount',
    `折价 ${amount}`,
    `折價 ${amount}`,
    `${amount}円割引`,
    ``,
  ],
  date: (date) => [`${date}까지`, `Until ${date}`, 'n_coupon.date', `到 ${date}止`, `到 ${date}止`, `${date}まで`, ``],
  use_extend: [
    `유료 수업권의 수강기간 연장시 사용`,
    `For extending lesson days`,
    'n_coupon.use_extend',
    `可以使用于延长付费课程券使用时间`,
    `可以使用於延長付費課程券使用時間`,
    `有料のレッスンチケットの受講期間延長時に使用`,
    ``,
  ],
  use_credit: (duration, number, type) => [
    <div>
      {duration}분 {number}회 {type} 수업권 구매 시 자동 적용
    </div>,
    <div>
      Redeemable for {duration}-min {number} credits {type}
    </div>,
    'n_coupon.use_credit',
    <div>
      于购买{duration}分 {number}次 {type} 课程券时自动使用
    </div>,
    <div>
      於購買{duration}分 {number}次 {type} 課程券時自動使用
    </div>,
    <div>
      {duration}分{number}回{type}レッスンチケット購入時に自動適用
    </div>,
    ``,
  ],
  subtitle_mobile: [
    <>
      쿠폰으로 수업권 할인과
      <br />
      수강기간 연장 혜택을 받으세요.
    </>,
    `Check out the coupons that offer various benefits.`,
    'n_coupon.subtitle_mobile',
    ``,
    ``,
    <>
      クーポンでレッスン料割引や
      <br />
      受講期間の延長が可能です。
    </>,
    ``,
  ],
}
export const n_noti = {
  title: [`알림`, `Noticifaction`, 'n_noti.title', `通知`, `通知`, `お知らせ`, ``],
  chip_1: [`전체`, `All`, 'n_noti.chip_1', `全部`, `全部`, `すべて`, ``],
  chip_2: [`1:1 수업`, `1:1 Lessons`, 'n_noti.chip_2', `1:1课程`, `1:1課程`, `1on1レッスン`, ``],
  chip_3: [
    `스케줄 업데이트`,
    `Lesson Availability`,
    'n_noti.chip_3',
    `可预约的日期已更新`,
    `可預約的日期已更新`,
    `スケジュール更新`,
    ``,
  ],
  chip_4: [`콘텐츠`, `Contents`, 'n_noti.chip_4', `教材`, `教材`, `コンテンツ`, ``],
  chip_5: [
    `수업권·포인트`,
    `Credits & Points`,
    'n_noti.chip_5',
    `课程券 & 积分`,
    `課程券 & 點數`,
    `レッスン・ポイント`,
    ``,
  ],
  chip_6: [`이벤트·혜택`, `Events & Offer`, 'n_noti.chip_6', `活动 & 优惠`, `活動 & 優惠`, `イベント・特典`, ``],
  placeholder: [`알림이 없어요.`, `Empty`, 'n_noti.placeholder', `没有通知`, `沒有通知`, `通知がありません。`, ``],
  modal_list_1: [
    `알림 설정 변경`,
    `Change notification setting`,
    'n_noti.modal_list_1',
    `更改通知设定`,
    `更改通知設定`,
    `通知設定の変更`,
    ``,
  ],
  modal_list_2: [
    `피드 삭제하기`,
    `Delete feed`,
    'n_noti.modal_list_2',
    `删除Feed`,
    `刪除Feed`,
    `フィードを削除する`,
    ``,
  ],
  toast: (count) => [
    `알림을 ${count}개 삭제했어요.`,
    `Deleted ${count} notification.`,
    'n_noti.toast',
    `已删除${count}个通知`,
    `已刪除${count}個通知`,
    `通知を${count}個削除しました。`,
    ``,
  ],
}
export const n_material_list = {
  bookmark: {
    title: (number) => [
      `찜한 교재 (${number})`,
      `Favorite (${number})`,
      'n_material_list.bookmark.title',
      `收藏的教材 (${number})个`,
      `收藏的教材 (${number})個`,
      `お気に入り教材 (${number})`,
      ``,
    ],
    toast: [
      `찜한 교재에서 삭제했어요.`,
      `Removed from Favorite Materials.`,
      'n_material_list.bookmark.toast',
      `从收藏的教材中移除`,
      `從收藏的教材中移除`,
      `お気に入り教材から削除しました。`,
      ``,
    ],
    placeholder: [
      `아직 찜한 교재가 없어요.`,
      `You haven't added any materials to Favorite Materials yet.`,
      'n_material_list.bookmark.placeholder',
      `您尚未收藏任何教材`,
      `您尚未收藏任何教材`,
      `まだお気に入りの教材がありません。`,
      ``,
    ],
    btn: [
      `전체 교재 보기`,
      `View all`,
      'n_material_list.bookmark.btn',
      `看全部教材`,
      `看全部教材`,
      `全ての教材を見る`,
      ``,
    ],
    delete_title: [
      `이 교재를 찜한 교재에서 삭제합니다.`,
      `Do you want to remove this material from Favorite Materials?`,
      'n_material_list.bookmark.delete_title',
      `将教材从我收藏的教材中移除`,
      `將教材從我收藏的教材中移除`,
      `この教材をお気に入りから削除します。`,
      ``,
    ],
  },
  took: {
    title: (number) => [
      `수업한 교재(${number})`,
      `Learned(${number})`,
      'n_material_list.took.title',
      `已经使用过的教材(${number})个`,
      `已經使用過的教材(${number})個`,
      `使用した教材(${number})`,
      ``,
    ],
    toast: [
      `찜한 교재에서 삭제했어요.`,
      `Removed from Favorite Materials.`,
      'n_material_list.took.toast',
      `从收藏的教材中移除`,
      `從收藏的教材中移除`,
      `お気に入り教材から削除しました。`,
      ``,
    ],
    placeholder: [
      `선택한 기간 동안 수업한 이력이 없어요.`,
      `There is no record of lessons taken during the selected period.`,
      'n_material_list.took.placeholder',
      `所选日期中无上课记录`,
      `所選日期中無上課記錄`,
      `選択した期間中に受講した履歴がありません。`,
      ``,
    ],
    btn: [
      `기간 설정 초기화`,
      `Reset`,
      'n_material_list.took.btn',
      `时间设定初始化`,
      `時間設定初始化`,
      `期間設定の初期化`,
      ``,
    ],
    date_picker: [`기간 설정`, `Set`, 'n_material_list.took.date_picker', `设定时间`, `設定時間`, `期間設定`, ``],
  },
  all: {
    title: [`링글 교재`, `Ringle Materials`, 'n_material_list.all.title', `Ringle教材`, `Ringle教材`, `Ringle教材`, ``],
    search_placeholder: [
      `교재를 검색해보세요.`,
      `Search for materials`,
      'n_material_list.all.search_placeholder',
      `请试着搜索教材`,
      `請試著尋找教材`,
      `教材を検索しましょう。`,
      ``,
    ],
    title_search: [`교재 검색`, `Search`, 'n_material_list.all.title_search', `搜索教材`, `尋找教材`, `教材検索`, ``],
    search_title: [
      `{검색어}에 대한 n개의 검색 결과`,
      `n results for {검색어}`,
      'n_material_list.all.search_title',
      `{关键字} 有 n 个搜索结果`,
      `{關鍵字} 有 n 個搜尋結果`,
      `{検索キー}に対するn個の検索結果`,
      ``,
    ],
  },
}
export const n_rwm = {
  modal_login: {
    title: [
      `로그인이 필요한 서비스입니다.`,
      `Please log in to register.`,
      'n_rwm.modal_login.title',
      `需要登入才能使用服务`,
      `需要登入才能使用服務`,
      `ログインが必要なサービスです。`,
      ``,
    ],
    desc: [
      `로그인 후 신청 또는 참여해주세요.`,
      `Please log in to register or participate.`,
      'n_rwm.modal_login.desc',
      `请登入后提出申请`,
      `請登入後提出申請`,
      `ログイン後、申請または参加してください。`,
      ``,
    ],
    btn: [`로그인하기`, `Log in`, 'n_rwm.modal_login.btn', `登入`, `登入`, `ログインする`, ``],
  },
  modal_card: {
    title: [
      `현재는 국내 카드 결제만 가능해요.`,
      `Only domestic card payments are accepted at the moment.`,
      'n_rwm.modal_card.title',
      `如欲使用海外信用卡请联络客服中心`,
      `如欲使用海外信用卡請聯絡客服中心`,
      `海外カード決済については、Ringleお客様センターまでお問い合わせください。`,
      `Only domestic card payments are accepted at the moment.`,
    ],
    desc: [
      `해외 카드 결제는 고객센터 (채널톡)를 통해 문의해주세요. ‘결제 계속하기'를 누르면 국내 카드 결제창이 열립니다.`,
      `Please contact Ringle's Help Center for international card payment. Press on ‘Proceed to Payment’ button to proceed with domestic card payments.`,
      'n_rwm.modal_card.desc',
      `目前只支持国内信用卡支付，我们计划很快增加更多支付方式。`,
      `目前只支援國內信用卡支付，我們計劃很快增加更多支付方式。`,
      `現在は国内カード決済のみですが、近日中に決済可能な手段が拡大される予定です。`,
      `Please contact Ringle's Help Center for international card payment. Press on ‘Proceed to Payment’ button to proceed with domestic card payments.`,
    ],
    keep_pay: [
      `결제 계속하기`,
      `Proceed to Payment`,
      'n_rwm.modal_card.keep_pay',
      `继续付款`,
      `繼續付款`,
      `支払を続ける`,
      ``,
    ],
  },
  modal_payment: {
    title: [
      `결제가 정상적으로 진행되지 않았습니다.`,
      `Payment is not processed successfully.`,
      'n_rwm.modal_payment.title',
      `付款失败`,
      `付款失敗`,
      `プログラム案内及び全ての資料をメールでお送りします。メールアドレスは[通知受信設定]で変更することができます。`,
      `Payment is not processed successfully.`,
    ],
    desc: [
      `다시 시도해 주세요. 계속 오류가 발생한다면 카드사에 문의해 주세요.`,
      `Please try again. Contact the card issuer if errors persist.`,
      'n_rwm.modal_payment.desc',
      `请虫是，如果问题持续发生请联络发卡机构`,
      `請蟲是，如果問題持續發生請聯絡發卡機構`,
      `もう一度お試しください。引き続きエラーが発生する場合は、カード会社にお問い合わせください。`,
      `Please try again. Contact the card issuer if errors persist.`,
    ],
  },
  modal_rwm_done: {
    title: [
      `Read Together 프로그램 신청이 완료되었습니다.`,
      `Registration to the Read Together program is complete.`,
      'n_rwm.modal_rwm_done.title',
      `您已完成Read Together(一起阅读）申请`,
      `您已完成Read Together(一起閱讀）申請`,
      `Read Togetherの申し込みが完了しました。`,
      ``,
    ],
    desc: (date) => [
      `이메일로 보내드리는 안내 메일을 꼭 확인하세요. 첫번째 실시간 강의는 ${date} KST에 시작합니다.

자료를 받을 이메일 주소는 [알림 수신 설정]에서 변경할 수 있습니다.`,
      `Make sure to check the confirmation email. The first live lecture is scheduled to start on ${date} KST.

You can change the email address to receive instructions and materials in [Notification Settings].`,
      'n_rwm.modal_rwm_done.desc',
      `请务必查看我们发送的通知邮件。第一次的直播课程将于 ${date} 韩国时间开始。
您可以在 [通知接收设置] 中更改接收资料的邮箱地址。`,
      `請務必查看我們發送的通知郵件。第一次的直播課程將於 ${date} 韓國時間開始。
您可以在 [通知接收設置] 中更改接收資料的郵件地址。`,
      `メールでお送りする案内メールを必ずご確認ください。最初のLive Webinarは ${date}に開始します。
  
 資料を受け取るメールアドレスは[通知受信設定]で変更することができます。`,
      ``,
    ],
    btn: [`알림 수신 설정`, `Notif. Settings`, 'n_rwm.modal_rwm_done.btn', `通知设定`, `通知設定`, `通知受信設定`, ``],
  },
  modal_ss_done: {
    title: [
      `Read Alone 프로그램 신청이 완료되었습니다.`,
      `Registration to the Read Alone program is complete.`,
      'n_rwm.modal_ss_done.title',
      `您已完成Read Alone（独自阅读）申请`,
      `您已完成Read Alone（獨自閱讀）申請`,
      `Read Aloneの申し込みが完了しました。`,
      ``,
    ],
    desc: [
      `이메일로 보내드리는 안내 메일을 꼭 확인하세요.

자료를 받을 이메일 주소는 [알림 수신 설정]에서 변경할 수 있습니다.`,
      `Make sure to check the confirmation email.

You can change the email address to receive instructions and materials in [Notification Settings].`,
      'n_rwm.modal_ss_done.desc',
      `请务必查看我们发送的通知邮件。第一次的直播课程将于 韩国时间开始。
您可以在 [通知接收设置] 中更改接收资料的邮箱地址。`,
      `請務必查看我們發送的通知郵件。第一次的直播課程將於 韓國時間開始。
您可以在 [通知接收設置] 中更改接收資料的郵件地址。`,
      `メールでお送りする案内メールを必ずご確認ください。
  
 資料を受け取るメールアドレスは[通知受信設定]で変更することができます。`,
      ``,
    ],
    btn: [`알림 수신 설정`, `Notif. Settings`, 'n_rwm.modal_ss_done.btn', `通知设定`, `通知設定`, `通知受信設定`, ``],
  },
  msg_15min: (minutes) => [
    `${minutes}분 후 실시간 강의가 시작됩니다.`,
    `${minutes} minutes until the live lecture.`,
    'n_rwm.msg_15min',
    `${minutes}分之后课程即将开始。`,
    `${minutes}分之後課程即將開始。`,
    `ライブウェビナーは ${minutes}分後に開始します。`,
    ``,
  ],
  msg_24hrs: (hours, minutes) => [
    `${hours}시간 ${minutes}분 후 실시간 강의가 시작됩니다.`,
    `${hours} hours ${minutes} minutes until the live lecture.`,
    'n_rwm.msg_24hrs',
    `${hours}小时 ${minutes}分后直播课程即将开始。`,
    `${hours}小時 ${minutes}分後直播課程即將開始。`,
    `${hours}時間 ${minutes}分後にライブウェビナーが始まります。`,
    ``,
  ],
  msg_start: [
    `실시간 강의가 곧 시작됩니다. 입장해 주세요.`,
    `The live lecture is about to start. Please enter the Zoom meeting.`,
    'n_rwm.msg_start',
    `直播课程即将开始，请入场。`,
    `直播課程即將開始，請入場。`,
    `ライブウェビナーが間もなく始まります。ご参加ください。`,
    ``,
  ],
  msg_late: [
    `실시간 강의가 진행 중입니다. 입장해 주세요.`,
    `The live lecture is in progress. Please enter the Zoom meeting.`,
    'n_rwm.msg_late',
    `直播课程即已经开始，请入场。`,
    `直播課程即已經開始，請入場。`,
    `ライブウェビナーを開催中です。ご参加ください。`,
    ``,
  ],
  msg_pop_up: [
    `팝업이 차단되어 결제할 수 없습니다. 팝업 허용 후 다시 시도해 주세요.`,
    `Popup blocked, unable to proceed with the payment. Please allow popups and try again.`,
    'n_rwm.msg_pop_up',
    `"弹出窗口被阻止，无法进行支付。请允许弹出窗口后再次尝试。`,
    `"彈出視窗被禁止，無法進行付款。請允許彈出視窗後再次嘗試。`,
    `ポップアップがブロックされ、決済できません。 ポップアップを許可してから再試行してください'。`,
    ``,
  ],
  msg_retry: [
    `다시 시도해 주세요. 계속 오류가 발생한다면 카드사에 문의해 주세요.`,
    `Please try again. Contact the card issuer if errors persist.`,
    'n_rwm.msg_retry',
    `请再试一次，若重複发生问题，请与发卡机构联络。`,
    `請再試一次，若重複發生問題，請與發卡機構聯絡。`,
    `もう一度お試しください。引き続きエラーが発生する場合は、カード会社にお問い合わせください。`,
    ``,
  ],
  msg_ra_done_1: [
    `Read Alone 프로그램 신청이 완료되었습니다. 프로그램 안내 및 모든 자료를 이메일로 보내드립니다. 이메일 주소는 [알림 수신 설정]에서 변경할 수 있습니다.`,
    `Registration to the Read Alone program is complete. Make sure to check the confirmation email. You can change the email address to receive instructions and materials in [Notification Settings].`,
    'n_rwm.msg_ra_done_1',
    `已成功申请独自阅读(Read Alone)。我们将通过电子邮件发送活动说明和资料。您可以在[通知接收设置]中更改接收消息的邮箱地址。`,
    `已成功申請獨自閱讀(Read Alone)。我們將通過電子郵件發送活動說明和資料。您可以在[通知接收設置]中更改接收消息的郵件地址。`,
    `Read Aloneプログラムへのお申し込みが完了しました。お申し込みいただいたメールアドレスに、プログラムの詳細と資料をお送りします。メールアドレスは「お知らせ設定」で変更できます。`,
    ``,
  ],
  msg_ra_done_2: (date) => [
    `첫 번째 실시간 강의는 ${date}에 시작합니다. 이메일 주소는 [알림 수신 설정]에서 변경할 수 있습니다.`,
    `The first live lecture is scheduled to start on ${date} KST. You can change the email address to receive instructions and materials in [Notification Settings].`,
    'n_rwm.msg_ra_done_2',
    `"第一堂直播课程将于${date}开始。您可以在[通知接收设置]中更改邮箱地址。`,
    `"第一堂直播課程將於${date}開始。您可以在[通知接收設置]中更改郵件地址。`,
    `最初のライブウェビナーは${date}に始まります。メールアドレスは「通知受信設定」で変更することができます。`,
    ``,
  ],
  msg_error: [
    `수업이 할당되지 않았습니다.`,
    `No classes have been assigned.`,
    'n_rwm.msg_error',
    `没有课程`,
    `沒有課程`,
    `レッスンが確定していません。`,
    ``,
  ],
  download: [
    `프로그램 자료 보기`,
    `See Program Materials`,
    'n_rwm.download',
    `确认课程材料`,
    `確認課程資料`,
    `レッスン資料のダウンロード`,
    ``,
  ],
  replay: [`강의 영상`, `Recorded Lectures`, 'n_rwm.replay', `课程录影`, `課程錄影`, `再生`, ``],
  prev_read_alone: {
    title: [
      `학습한 혼자 읽기 프로그램`,
      `My Read Alone`,
      'n_rwm.prev_read_alone.title',
      `我的[独自阅读Read Alone]`,
      `我的[獨自閱讀Read Alone]`,
      `学習したRead Aloneプログラム`,
      ``,
    ],
  },
  prev_read_together: {
    title: [
      `학습한 함께 읽기 프로그램`,
      `My Read Together`,
      'n_rwm.prev_read_together.title',
      `我的[一起阅读Read Together]`,
      `我的[一起閱讀Read Together]`,
      `学習したRead Togetherプログラム`,
      ``,
    ],
  },
  app_payment_done: {
    title: (user_name, program_type) => [
      `${user_name}님, ${program_type} 프로그램 신청이 완료되었습니다.`,
      <>
        Hi {user_name},<br />
        your registration to the {program_type} program is complete.
      </>,
      'n_rwm.app_payment_done.title',
      `${user_name}已完成${program_type} 申请`,
      `${user_name}已完成${program_type} 申請`,
      `${user_name}さん
 ${program_type}申請が完了しました。`,
      ``,
    ],
    subtitle_1: [
      `결제 내역`,
      `Purchase history`,
      'n_rwm.app_payment_done.subtitle_1',
      `购买明细`,
      `購買明細`,
      `支払い履歴`,
      ``,
    ],
    caption_1: [
      `최종 결제 금액`,
      `Your total`,
      'n_rwm.app_payment_done.caption_1',
      `最终付款金额`,
      `最終付款金額`,
      `お支払い額合計`,
      ``,
    ],
    subtitle_2: [
      `주문상품`,
      `Your cart`,
      'n_rwm.app_payment_done.subtitle_2',
      `订购商品`,
      `訂購商品`,
      `カートの内容`,
      ``,
    ],
    caption_2: [`상품명`, `Program`, 'n_rwm.app_payment_done.caption_2', `商品名`, `商品名`, `プログラム`, ``],
    caption_3: [`진행 기간`, `Period`, 'n_rwm.app_payment_done.caption_3', `期间`, `期間`, `実施期間`, ``],
    subtitle_3: [
      `꼭 읽어주세요!`,
      `Please read the terms!`,
      'n_rwm.app_payment_done.subtitle_3',
      `请仔细阅读！`,
      `請仔細閱讀！`,
      `注意事項をお読みください！`,
      ``,
    ],
    caption_4: (date) => [
      `이메일로 보내드리는 안내 메일을 꼭 확인세요. 첫 번째 실시간 강의는 ${date}에 시작합니다.`,
      `Please make sure to check the notification emails we send to you. 
The first live session starts at ${date}.`,
      'n_rwm.app_payment_done.caption_4',
      `请确认我们寄出的电子邮件，第一次LIVE课程将于${date}开始`,
      `請確認我們寄出的電子郵件，第一次LIVE課程將於${date}開始`,
      `メールでお送りする案内メールを必ずご確認ください。最初のLIVEは${date}に開始します。`,
      ``,
    ],
    caption_5: (user_email) => [
      `자료를 받을 이메일 주소는 [알림 수신 설정]에서 변경할 수 있습니다. (현재 등록된 이메일 : ${user_email})`,
      `You can update the email address for receiving in [Notification Settings].`,
      'n_rwm.app_payment_done.caption_5',
      `欲接收资料的邮件地址可以在 [通知设定]中变更(现在使用中的邮件 : ${user_email})`,
      `欲接收資料的郵件地址可以在 [通知設定]中變更(現在使用中的郵件 : ${user_email})`,
      `資料を受け取るメールアドレスは[通知受信設定]で変更することができます。 (現在登録されているメールアドレス : ${user_email})`,
      ``,
    ],
  },
  app_payment_fail: {
    title: [
      `결제를 완료할 수 없습니다.
다시 시도해주세요.`,
      <>
        Your payment is not processed successfully.
        <br />
        Please try again.
      </>,
      'n_rwm.app_payment_fail.title',
      `付款失败，请再试一次`,
      `付款失敗，請再試一次`,
      `決済が完了できません。
 もう一度お試しください。`,
      ``,
    ],
    callout_title: [
      `결제오류`,
      `Transaction failed`,
      'n_rwm.app_payment_fail.callout_title',
      `付款失败`,
      `付款失敗`,
      `決済エラー`,
      ``,
    ],
    callout_desc: (error_msg) => [
      `${error_msg}`,
      `${error_msg}`,
      'n_rwm.app_payment_fail.callout_desc',
      `${error_msg}`,
      `${error_msg}`,
      `${error_msg}`,
      ``,
    ],
    subtitle_1: [
      `결제 요청 금액`,
      `Amount due`,
      'n_rwm.app_payment_fail.subtitle_1',
      `付款金额`,
      `付款金額`,
      `お支払い額`,
      ``,
    ],
    subtitle_2: [
      `주문상품`,
      `Your cart`,
      'n_rwm.app_payment_fail.subtitle_2',
      `订购商品`,
      `訂購商品`,
      `カートの内容`,
      ``,
    ],
    caption_2: [`상품명`, `Program Title`, 'n_rwm.app_payment_fail.caption_2', `商品名`, `商品名`, `プログラム`, ``],
    caption_3: [`진행 기간`, `Date`, 'n_rwm.app_payment_fail.caption_3', `活动时间`, `活動時間`, `実施期間`, ``],
    msg: [
      <>
        결제 오류가 반복된다면 <span className="text-purple-500 underline">이곳을 눌러</span> 문의해 주세요.
      </>,
      `Having trouble? Let us help you.`,
      'n_rwm.app_payment_fail.msg',
      <>
        如果重複发生问题请于 <span className="text-purple-500 underline">点选</span> 与我们联繫.
      </>,
      <>
        如果重複發生問題請於 <span className="text-purple-500 underline">點選</span> 與我們聯繫.
      </>,
      <>
        繰り返し決済エラーが起こる場合 <span className="text-purple-500 underline">こちらから</span>{' '}
        お問い合わせください。
      </>,
      ``,
    ],
    btn: [`다시 결제하기`, `Try again.`, 'n_rwm.app_payment_fail.btn', `再试一次`, `再試一次`, `再度決済する`, ``],
  },
  program_label: {
    upcoming: [`진행 예정`, `Upcoming`, 'n_rwm.program_label.upcoming', `即将进行`, `即將進行`, `Upcoming`, ``],
    inprogress: [`진행중`, `In progress`, 'n_rwm.program_label.inprogress', `进行中`, `進行中`, `進行中`, ``],
    completed: [`진행 종료`, `Completed`, 'n_rwm.program_label.completed', `已结束`, `已結束`, `完了`, ``],
  },
}
export const n_review = {
  step_1: {
    title: (tutor_name) => [
      `${tutor_name} 튜터와 수업은 어땠나요?`,
      `How was your lesson with ${tutor_name}?`,
      'n_review.step_1.title',
      `您和 ${tutor_name}?教师的课程如何呢？`,
      `您和 ${tutor_name}?教師的課程如何呢？`,
      `${tutor_name}チューターとのレッスンはいかがでしたか？`,
      ``,
    ],
    tutor_name: (tutor_name) => [
      `${tutor_name}`,
      `${tutor_name}`,
      'n_review.step_1.tutor_name',
      `${tutor_name}`,
      `${tutor_name}`,
      `${tutor_name}`,
      `${tutor_name}`,
    ],
    rate_placeholder: [
      `수업 만족도를 평가해주세요.`,
      `Please tell us how satisfied you were with the lesson.`,
      'n_review.step_1.rate_placeholder',
      `请对课程进行满意度评价。`,
      `請對課程進行滿意度評價。`,
      `レッスン満足度を評価してください。`,
      `Vui lòng đánh giá mức độ hài lòng đối với buổi học.`,
    ],
    rate_1: [
      `최악이었어요`,
      `Very dissatisfied`,
      'n_review.step_1.rate_1',
      `非常不满意`,
      `非常不滿意`,
      `最悪でした`,
      `Rất tệ`,
    ],
    rate_2: [
      `너무 별로였어요`,
      `Dissatisfied`,
      'n_review.step_1.rate_2',
      `不满意`,
      `不滿意`,
      `微妙でした`,
      `Không hay lắm`,
    ],
    rate_3: [
      `조금 아쉬웠어요`,
      `Neither satisfied nor dissatisfied`,
      'n_review.step_1.rate_3',
      `有些不满意`,
      `有些不滿意`,
      `残念でした`,
      `Đáng tiếc`,
    ],
    rate_4: [`좋았어요`, `Satisfied`, 'n_review.step_1.rate_4', `满意`, `滿意`, `良かったです`, `Tốt`],
    rate_5: [
      `최고에요!`,
      `Very satisfied`,
      'n_review.step_1.rate_5',
      `非常满意`,
      `非常滿意`,
      `最高です！`,
      `Tuyệt vời`,
    ],
    report: [
      `링글 팀에게 문제상황 알리기`,
      `Please let us know if the lesson wasn't held.`,
      'n_review.step_1.report',
      `如果没有上课，请通知我们。`,
      `如果沒有上課，請通知我們。`,
      `レッスンが進んでいない場合はRingleチームにご連絡ください。`,
      `Nếu buổi học chưa được tiến hành, vui lòng thông báo cho nhóm Ringle.`,
    ],
    btn_1: [`다음에 하기`, `Skip for now`, 'n_review.step_1.btn_1', `下次再说`, `下次再說`, `今はしない`, `Bỏ qua`],
    btn_2: [`평가하기`, `Next`, 'n_review.step_1.btn_2', `下一步`, `下一步`, `次へ`, `Tiếp theo`],
  },
  step_2: {
    title_positive: (tutor_name) => [
      `${tutor_name} 튜터의 어떤 점이 좋았나요?`,
      `What did you like about ${tutor_name}?`,
      'n_review.step_2.title_positive',
      `${tutor_name} 教师的哪一点很好呢？`,
      `${tutor_name} 教師的哪一點很好呢？`,
      `${tutor_name}チューターのどのような点が良かったですか？`,
      ``,
    ],
    category_1: [
      `수업 진행능력`,
      `Teaching Ability`,
      'n_review.step_2.category_1',
      `教学能力`,
      `教學能力`,
      `レッスン進行能力`,
      `Khả năng giảng dạy`,
    ],
    keyword_1: [
      `교정의 양이 많아요`,
      `Provides sufficient paraphrasing`,
      'n_review.step_2.keyword_1',
      `给予很多纠正`,
      `給予很多糾正`,
      `教材の量が多いです`,
      `Hiệu chỉnh nhiều`,
    ],
    keyword_2: [
      `새로운 문장 형식을 알려줘요`,
      `Teaches sentence structures`,
      'n_review.step_2.keyword_2',
      `教授新的句型`,
      `教授新的句型`,
      `新しい形式を教えてくれます`,
      `Dạy cấu trúc câu mới`,
    ],
    keyword_3: [
      `새로운 단어를 많이 알려줘요`,
      `Teaches many new expressions`,
      'n_review.step_2.keyword_3',
      `教授很多新的生词`,
      `教授很多新的單詞`,
      `新しい単語を沢山教えてくれます`,
      `Dạy nhiều từ vựng mới`,
    ],
    keyword_4: [
      `추가 질문을 많이 해줬어요`,
      `Asks many questions`,
      'n_review.step_2.keyword_4',
      `问了很多相关的问题`,
      `問了很多相關的問題`,
      `追加で質問を多くしてくれました`,
      `Đặt thêm nhiều câu hỏi`,
    ],
    keyword_5: [
      `대화를 많이 할 수 있어요`,
      `Actively engages conversation`,
      'n_review.step_2.keyword_5',
      `积极地参与对话及讨论`,
      `積極地參與對話及討論`,
      `会話を沢山できました`,
      `Có thể đối thoại nhiều`,
    ],
    keyword_6: [
      `교정에 대한 이유를 잘 설명해요`,
      `Provides good explanations`,
      'n_review.step_2.keyword_6',
      `对纠正的部分进行详细的说明`,
      `對糾正的部分進行詳細的說明`,
      `教材に対する理由をよく説明します`,
      `Giải thích kỹ lý do hiệu chỉnh`,
    ],
    keyword_7: [
      `반복되는 실수를 잘 짚어줘요`,
      `Corrects repetitive mistakes`,
      'n_review.step_2.keyword_7',
      `指出常犯的错误`,
      `指出常犯的錯誤`,
      `繰り返されるミスをよく指摘してくれます`,
      `Sửa lỗi lặp đi lặp lại`,
    ],
    category_2: [
      `수업 참여도/준비도`,
      `Engagement/Preperation`,
      'n_review.step_2.category_2',
      `课程参与度/准备度`,
      `課程參與度/準備度`,
      `レッスン参加度・準備度`,
      `Tham gia/ chuẩn bị buổi học`,
    ],
    keyword_8: [
      `수업을 잘 리드해요`,
      `Leads lessons well`,
      'n_review.step_2.keyword_8',
      `很好地引导课程`,
      `很好地引導課程`,
      `レッスンをよくリードしてくれます`,
      `Dẫn dắt buổi học tốt`,
    ],
    keyword_9: [
      `학생의 요청사항을 잘 들어줘요`,
      `Responds willingly to requests`,
      'n_review.step_2.keyword_9',
      `认真聆听学生的要求`,
      `認真聆聽學生的要求`,
      `学生の要請事項をよく聞いてくれます`,
      `Sẵn sàng trả lời các yêu cầu của học viên`,
    ],
    keyword_10: [
      `수업 시간을 충분히 활용해요`,
      `Makes full use of time`,
      'n_review.step_2.keyword_10',
      `充分利用课上时间`,
      `充分利用課上時間`,
      `レッスン時間を十分に活用します`,
      `Tận dụng triệt để thời gian buổi học`,
    ],
    keyword_11: [
      `수업 준비를 잘 해줘요`,
      `Comes to lessons prepared`,
      'n_review.step_2.keyword_11',
      `课前准备得很充分`,
      `課前準備得很充分`,
      `レッスンの準備がよくできています`,
      `Chuẩn bị buổi học tốt`,
    ],
    keyword_12: [
      `수업 노트를 잘 정리해요`,
      `Provides good written feedback`,
      'n_review.step_2.keyword_12',
      `课程笔记整理的很好`,
      `課程筆記整理的很好`,
      `レッスンのーとをよく整理しています`,
      `Cung cấp phản hồi bằng văn bản tốt`,
    ],
    keyword_13: [
      `교재를 정확히 이해하고 있어요`,
      `Understood material well`,
      'n_review.step_2.keyword_13',
      `对教材的理解度很高`,
      `對教材的理解度很高`,
      `教材を正確に理解しています`,
      `Hiểu chính xác tài liệu`,
    ],
    category_3: [
      `수업 태도`,
      `Attitude`,
      'n_review.step_2.category_3',
      `上课态度`,
      `上課態度`,
      `レッスン態度`,
      `Thái độ trong buổi học`,
    ],
    keyword_15: [
      `수업 분위기가 활기차요`,
      `Energetic and lively`,
      'n_review.step_2.keyword_15',
      `课堂氛围活跃`,
      `課堂氛圍活躍`,
      `レッスンの雰囲気が活気的です`,
      `Không khí buổi học sôi nổi`,
    ],
    keyword_16: [
      `차분하게 수업을 진행해요`,
      `Calm and composed`,
      'n_review.step_2.keyword_16',
      `授课风格沉稳`,
      `授課風格沈穩`,
      `落ち着いてレッスンを進行します`,
      `Tiến hành buổi học điềm đạm`,
    ],
    keyword_17: [
      `답변에 대한 리액션이 좋아요`,
      `Good reactions`,
      'n_review.step_2.keyword_17',
      `对学生的回答给予积极回应`,
      `對學生的回答給予積極回應`,
      `返事に対するリアクションがいいです`,
      `Phản ứng tốt đối với câu trả lời`,
    ],
    keyword_18: [
      `수준에 맞게 수업을 진행해요`,
      `Teaches at my level`,
      'n_review.step_2.keyword_18',
      `根据学生的程度调整教学方式`,
      `根據學生的程度調整教學方式`,
      `レベルに合わせてレッスンを進めてくれます`,
      `Tiến hành buổi học đúng trình độ`,
    ],
    keyword_19: [
      `냉철한 피드백을 해줘요`,
      `Gives objective feedback`,
      'n_review.step_2.keyword_19',
      `提供回应时很客观`,
      `提供回應時很客觀`,
      `冷徹なフィードバックをしてくれます`,
      `Đưa ra phản hồi khách quan`,
    ],
    title_negative: (tutor_name) => [
      `${tutor_name} 튜터의 아쉬웠던 점을 알려주세요.`,
      `What did you not like about ${tutor_name}?`,
      'n_review.step_2.title_negative',
      `${tutor_name} 教师还可以加强的部分？`,
      `${tutor_name} 教師還可以加強的部分？`,
      `${tutor_name}チューターの改善すべき点を教えてください。`,
      ``,
    ],
    category_4: [
      `수업 진행능력`,
      `Teaching Ability`,
      'n_review.step_2.category_4',
      `教学能力`,
      `教學能力`,
      `レッスン進行能力`,
      `Khả năng giảng dạy`,
    ],
    keyword_20: [
      `교정이 부족했어요`,
      `Not enough paraphrasing`,
      'n_review.step_2.keyword_20',
      `纠正不足`,
      `糾正不足`,
      `教材が不足しています`,
      `Hiệu chỉnh không nhiều`,
    ],
    keyword_21: [
      `단순한 문법 교정만 진행했어요`,
      `Only corrects simple grammar`,
      'n_review.step_2.keyword_21',
      `只对语法进行纠正`,
      `只對文法進行糾正`,
      `単純な文法校正だけ行います`,
      `Chỉ hiệu chỉnh ngữ pháp đơn giản`,
    ],
    keyword_22: [
      `설명이 부족했어요`,
      `Not enough explanations`,
      'n_review.step_2.keyword_22',
      `解释说明不足`,
      `解釋說明不足`,
      `説明が不足しています`,
      `Giải thích không đầy đủ`,
    ],
    keyword_23: [
      `교정외에 대화가 부족했어요`,
      `Not enough conversation`,
      'n_review.step_2.keyword_23',
      `除纠正外，对话稍嫌不足`,
      `除糾正外，對話稍嫌不足`,
      `教材以外に会話が不足しています`,
      `Không có nhiều đối thoại ngoài việc hiệu chỉnh`,
    ],
    keyword_24: [
      `학생과 소통이 부족했어요`,
      `Not communicative`,
      'n_review.step_2.keyword_24',
      `与学生的沟通不足`,
      `與學生的溝通不足`,
      `学生とのコミュニケーションが不足していました`,
      `Thiếu giao tiếp với học viên`,
    ],
    keyword_25: [
      `수업을 리드하지 못했어요`,
      `Not good at leading lessons`,
      'n_review.step_2.keyword_25',
      `引领课堂能力不足`,
      `引領課堂能力不足`,
      `レッスンをリードしてくれませんでした`,
      `Dẫn dắt buổi học chưa tốt`,
    ],
    keyword_26: [
      `수업 준비가 아쉬웠어요`,
      `Did not come prepared`,
      'n_review.step_2.keyword_26',
      `课前准备不足`,
      `課前準備不足`,
      `レッスンの準備が残念でした`,
      `Chuẩn bị buổi học chưa tốt`,
    ],
    category_5: [
      `튜터의 태도/환경`,
      `Attitude/Environment`,
      'n_review.step_2.category_5',
      `教师的态度/环境`,
      `教師的態度/環境`,
      `チューターの態度／環境`,
      `Thái độ của gia sư/ Môi trường`,
    ],
    keyword_27: [
      `수업 시간을 잘 지키지 않았어요`,
      `Came late/ended early`,
      'n_review.step_2.keyword_27',
      `未遵守上课时间`,
      `未遵守上課時間`,
      `レッスン時間をきちんと守りませんでした`,
      `Không giữ đúng thời gian buổi học`,
    ],
    keyword_28: [
      `수업에 집중하지 못했어요`,
      `Lacked focus`,
      'n_review.step_2.keyword_28',
      `上课注意力不集中`,
      `上課注意力不集中`,
      `レッスンに集中できませんでした`,
      `Không thể tập trung vào buổi học`,
    ],
    keyword_29: [
      `수업 진행 환경이 어수선해요`,
      `Distracting environment`,
      'n_review.step_2.keyword_29',
      `上课环境嘈杂`,
      `上課環境嘈雜`,
      `レッスン進行環境が散らかっています`,
      `Môi trường buổi học lộn xộn`,
    ],
    keyword_30: [
      `불친절해서 대화가 어려웠어요`,
      `Unfriendly and difficult to talk to`,
      'n_review.step_2.keyword_30',
      `不亲切，难以进行交流`,
      `不親切，進行對話有困難`,
      `不親切で会話が難しかったです`,
      `Thật khó để nói chuyện vì không thân thiện.`,
    ],
  },
  step_3: {
    title: (name) => [
      `${name}튜터와 다음에도 수업을 하고 싶으신가요?`,
      `Do you wish to learn from ${name} again?`,
      'n_review.step_3.title',
      `下次还想和${name}教师一起上课吗？`,
      `下次還想和${name}教師一起上課嗎？`,
      `${name}チューターと次もレッスンをしたいですか？`,
      `Bạn có muốn tham gia buổi học sau với gia sư ${name} không?`,
    ],
    callout: [
      `튜터 선호도는 링글 팀에게만 공개됩니다.`,
      `This tutor evaluation is shown only to the Ringle Team.`,
      'n_review.step_3.callout',
      `教师评价仅提供给 Ringle Team。`,
      `教師評價僅提供給 Ringle Team。`,
      `チューター好感度はRingleチームにのみ公開されます。`,
      `Mức độ yêu thích gia sư chỉ được tiết lộ cho nhóm Ringle`,
    ],
    rate_placeholder: [
      `튜터 선호도를 알려주세요.`,
      `Please rate the tutor.`,
      'n_review.step_3.rate_placeholder',
      `请对教师进行喜好度评价。`,
      `請對教師進行喜好度評價。`,
      `チューターの評価をお聞かせください。`,
      ``,
    ],
    rate_1: [
      `이 튜터와 다시 만나고 싶지 않아요.`,
      `No. I do not wish to learn from this tutor again.`,
      'n_review.step_3.rate_1',
      `不再与此教师上课`,
      `不再與此教師上課`,
      `いいえ。もう会いたくありません。`,
      `Không. Tôi không muốn gặp lại.`,
    ],
    rate_2: [
      `보통이에요`,
      `The tutor was okay. I wouldn't mind learning from this tutor again if the chance arises.`,
      'n_review.step_3.rate_2',
      `还可以，有机会还想一起学习。`,
      `還可以，有機會還想一起學習。`,
      `良かったです。機会があればまたしたいです。`,
      `Cũng ổn. Tôi muốn gặp lại nếu có cơ hội`,
    ],
    rate_3: [
      `이 튜터와 다음에 또 수업하고 싶어요.`,
      `The tutor was great! I want to continue learning from this tutor.`,
      'n_review.step_3.rate_3',
      `最棒的教师！还想和他一起学习。`,
      `最棒的教師！還想和他一起學習。`,
      `最高のチューター！是非また会いたいです。`,
      `Gia sư tốt nhất! Tôi thực sự muốn gặp lại`,
    ],
  },
  step_4: {
    title: (tutor_name) => [
      `${tutor_name} 튜터의 자세한 수업 후기를 알려주세요.`,
      `Please leave a review of your lesson with ${tutor_name}.`,
      'n_review.step_4.title',
      `请留下对 ${tutor_name}教师的评价。`,
      `請留下對 ${tutor_name}教師的評價。`,
      `${tutor_name}チューターとのレッスンについて、感想をお聞かせください。`,
      ``,
    ],
    title_review: [
      `솔직한 후기를 남겨주세요. (선택)`,
      `Please give a detailed review about the lesson. (Optional)`,
      'n_review.step_4.title_review',
      `请对课程进行课后评价（可选）`,
      `請對課程進行課後評價（選擇）`,
      `詳しいレッスンレビューを教えてください（選択）`,
      `Vui lòng cho biết đánh giá chi tiết buổi học. (lựa chọn)`,
    ],
    dropdown: [
      `전체공개`,
      `Share to public`,
      'n_review.step_4.dropdown',
      `全部公开`,
      `全部公開`,
      `全体公開`,
      `Công bố công khai`,
    ],
    option_1: [
      `링글 팀에게만`,
      `Share to Ringle Team only`,
      'n_review.step_4.option_1',
      `仅分享给Ringle Team`,
      `僅分享給Ringle Team`,
      `Ringleチームにのみ共有する`,
      `Chỉ chia sẻ với nhóm Ringle`,
    ],
    option_2: [
      `링글 팀과 튜터에게만`,
      `Share to Ringle Team and Tutor only`,
      'n_review.step_4.option_2',
      `仅分享给Ringle Team和教师`,
      `僅分享給Ringle Team和教師`,
      `Ringleチームとチューターにのみ共有する`,
      `Chỉ chia sẻ với nhóm Ringle và gia sư`,
    ],
    palceholder_review: [
      `자세한 수업 후기는 튜터의 역량 개선 및 수업의 만족도 증진에 큰 도움이 됩니다.
부적절한 단어/표현이 포함된 경우, 별도의 통지없이 삭제될 수 있습니다. (최소 20자 이상)`,
      `Your review will help us enhance our tutors' capacity and provide better lessons.
The review may be deleted without notice if it includes inappropriate language.`,
      'n_review.step_4.palceholder_review',
      `"详细的课后评价记有助于提高教师能力和增加授课满意度。如含有不适当的内容，将在不另行通知的情况下删除。`,
      `"詳細的課後評價記有助於提高教師能力和增加授課滿意度。如含有不適當的內容，將在不另行通知的情況下刪除。`,
      `詳しいレッスンレビューはチューターの力量改善及び満足度増進に大きく役立ちます。
 不適切な単語/表現が含まれている場合、余儀なく削除されることがあります。`,
      `Đánh giá chi tiết buổi học giúp ích rất nhiều trong việc cải thiện khả năng của gia sư và nâng cao sự hài lòng của buổi học.
Nếu có từ ngữ/ cách diễn đạt không phù hợp, có thể bị xóa mà không cần báo trước`,
    ],
    title_material: [
      `교재가 수업에 도움이 되었나요? (선택)`,
      `Was this material useful? (Optional)`,
      'n_review.step_4.title_material',
      `教材对课程有帮助吗？（可选）`,
      `教材對課程有幫助嗎？（選擇）`,
      `教材がレッスンに役立ちましたか？（選択）`,
      `Tài liệu có hữu ích cho buổi học không? (lựa chọn)`,
    ],
    placeholder_comment: [
      `의견을 남겨주세요. (선택)`,
      `Please tell us your thoughts. (optional)`,
      'n_review.step_4.placeholder_comment',
      `请留下您的意见（选择）`,
      `請留下您的意見（選擇）`,
      `ご意見をお聞かせください(選択)`,
      ``,
    ],
    title_network: [
      `수업 중 네트워크 환경은 어땠나요? (선택)`,
      `How was your internet connection during the lesson? (Optional)`,
      'n_review.step_4.title_network',
      `上课期间的网络环境怎么样？（可选）`,
      `上課期間的網路連線怎麼樣？（選擇）`,
      `レッスン中のネットワーク環境はどうでしたか？（選択）`,
      `Mạng máy tính trong buổi học như thế nào? (lựa chọn)`,
    ],
    caption_network: [
      `네트워크 환경`,
      `Network connection`,
      'n_review.step_4.caption_network',
      `网络环境`,
      `網路連線`,
      `ネットワーク環境`,
      ``,
    ],
    btn_2: [`제출하기`, `Submit`, 'n_review.step_4.btn_2', `提交`, `儲存`, `提出する`, `Nộp`],
  },
  toast: {
    submit: [
      `수업 평가를 제출했어요. 감사합니다.`,
      `Submitted your evaluation. Thank you.`,
      'n_review.toast.submit',
      `已提交您的课程评价，谢谢！`,
      `已儲存您的課程評價，謝謝！`,
      `レッスンレビューを提出しました。ありがとうございました。`,
      ``,
    ],
  },
  report: {
    title: (tutor_name) => [
      `${tutor_name}의 수업에 어떤 문제가 있었나요?`,
      `What was the problem?`,
      'n_review.report.title',
      `上课期间发生了什么问题？`,
      `上課時發生了什麼問題？`,
      `レッスンで何か問題がありましたか？`,
      `Buổi học có vấn đề gì không?`,
    ],
    radio_1: [
      `줌이 제대로 실행되지 않았어요.`,
      `Zoom didn't launch properly.`,
      'n_review.report.radio_1',
      `ZOOM软件没有正常启动。`,
      `ZOOM軟體沒有正常啟動。`,
      `ZOOMがきちんと実行できませんでした。`,
      `Zoom không hoạt động bình thường.`,
    ],
    radio_2: [
      `비디오/오디오가 연결되지 않았어요.`,
      `Couldn't connect video/audio.`,
      'n_review.report.radio_2',
      `视频/ 音频无法连接。`,
      `視訊/ 音效無法連接。`,
      `ビデオ／オーディオが連携されませんでした。`,
      `Video/âm thanh không kết nối được.`,
    ],
    radio_3: [
      `튜터가 입장하지 않았어요.`,
      `The tutor didn't show up.`,
      'n_review.report.radio_3',
      `教师未出席。`,
      `教師未出席。`,
      `チューターが入場しませんでした。`,
      `Gia sư không vào`,
    ],
    radio_4: [
      `정규 수업 시간을 충분히 채우지 못했어요.`,
      `The tutor was late.`,
      'n_review.report.radio_4',
      `教师迟到了。`,
      `教師遲到了。`,
      `チューターがレッスンに遅刻しました。`,
      `Gia sư vào buổi học muộn`,
    ],
    radio_5: [
      `수업이 자꾸 끊겼어요.`,
      `The internet connection was bad.`,
      'n_review.report.radio_5',
      `课程卡顿。`,
      `課程卡頓。`,
      `レッスンが頻繁に切れます。`,
      `Buổi học liên tục bị gián đoạn`,
    ],
    title_comment: [
      `문제 상황을 자세히 설명해주세요.`,
      `Can you describe the problem(s) you experienced?`,
      'n_review.report.title_comment',
      `请说明具体问题。`,
      `請說明具體問題。`,
      `問題状況を説明してください。`,
      `Can you describe the problem(s) you experienced?`,
    ],
    placeholder: [
      `불편을 겪으신 상황을 자세히 알려주세요.
링글팀이 1 영업일 이내로 확인 후 연락드립니다.`,
      `Please tell us about the problem(s) you experienced in detail. We will get back to you via your registered email address within 3 business days.`,
      'n_review.report.placeholder',
      `请详细说明您的不便事项。我们将在 3个工作日内透过您注册Ringle的邮件地址告知您处理结果。`,
      `請詳細說明您的不便事項。我們將在 3個工作日內透過您註冊Ringle的Email告知您處理結果。`,
      `ご不便をおかけした内容を詳しく教えてください。
 登録されたメールアドレスへ3日以内にご案内いたします。`,
      `Vui lòng cho biết chi tiết về sự bất tiện mà bạn gặp phải. Chúng tôi sẽ thông báo cho bạn qua địa chỉ email đăng ký trong vòng 3 ngày làm việc.`,
    ],
    modal_title: [
      `링글팀에 전달했어요.`,
      `Your experience has been reported.`,
      'n_review.report.modal_title',
      `已收到您的问题`,
      `已收到您的問題`,
      `受付完了しました。`,
      `Chúng tôi đã nhận được báo cáo.`,
    ],
    modal_desc: [
      `수업에 불편을 드려 죄송합니다. 제보해주신 내용은 1영업일 내로 처리 결과를 알려 드리겠습니다.`,
      `We apologize for the trouble you had. We will get back to you via your registered email address within 3 business days.`,
      'n_review.report.modal_desc',
      `为您带来不便我们感到非常抱歉，我们将在 3个工作日内透过您注册Ringle的邮件地址告知您处理结果。`,
      `為您帶來不便我們感到非常抱歉，我們將在 3個工作日內透過您註冊Ringle的Email告知您處理結果。`,
      `ご迷惑をおかけし申し訳ございません。登録されたメールアドレスへ3日以内にご案内いたします。`,
      `Chúng tôi xin lỗi về sự cố này. Chúng tôi sẽ thông báo cho bạn qua địa chỉ email đăng ký trong vòng 3 ngày làm việc.`,
    ],
  },
  modal_note: {
    desc: [
      `자세한 수업 후기는 20자 이상 입력해주세요.`,
      `The review must be at least 20 characters long.`,
      'n_review.modal_note.desc',
      `请输入20字以上的详后点评。`,
      `請輸入20字以上的課後評價。`,
      `詳しいレッスンレビューは20字以上で入力してください。`,
      `Vui lòng nhập đánh giá buổi học chi tiết từ 20 ký tự trở lên.`,
    ],
  },
  modal_exit: {
    title: [
      `수업을 그만두고 나갈까요?`,
      `Would you like to leave the lesson?`,
      'n_review.modal_exit.title',
      `要中断本节课吗？`,
      `要中斷本節課嗎？`,
      `レッスンを中断しますか？`,
      `Bạn có muốn dừng buổi học không?`,
    ],
    desc: [
      `수업이 진행되지 않았다면 링글 팀에게 알려주세요.`,
      `Please let us know if the lesson wasn't held.`,
      'n_review.modal_exit.desc',
      `如果没有上课，请通知我们。`,
      `如果沒有上課，請通知我們。`,
      `レッスンが進んでいない場合はRingleチームにご連絡ください。`,
      `Nếu buổi học chưa được tiến hành, vui lòng thông báo cho nhóm Ringle.`,
    ],
  },
  modal_leave: {
    title: [
      `수업 평가 작성을 그만할까요?`,
      `Postpone Lesson Evaluation`,
      'n_review.modal_leave.title',
      `要停止留下课后评价吗？`,
      `要停止留下課後評價嗎？`,
      `Postpone Lesson Evaluation`,
      `Postpone Lesson Evaluation`,
    ],
    desc: [
      `고객님의 솔직한 평가는 다른 링글러들에게 큰 도움이 됩니다. [레슨] > [지난 수업]에서 48시간 안에 수업 평가를 완료해주세요.`,
      `Your frank evaluation will help other Ringlers greatly. 
Please finish evaluating the lesson within 48 hours by going to [1:1 lessons] > [Review].`,
      'n_review.modal_leave.desc',
      `请在[课程] > [已完成的课程]中的 48 小时内完成对课程的评价。您的真实反馈对其他 Ringle 学员有很大帮助。`,
      `請在[課程] > [已完成的課程]中的 48 小時內完成對課程的評價。您的真實反饋對其他 Ringle 學員有很大幫助。`,
      `Your frank evaluation will help other Ringlers greatly. 
 Please finish evaluating the lesson within 48 hours by going to [1:1 lessons] > [Review].`,
      `Your frank evaluation will help other Ringlers greatly. 
Please finish evaluating the lesson within 48 hours by going to [1:1 lessons] > [Review].`,
    ],
    btn_1: [
      `계속 작성하기`,
      `Go back`,
      'n_review.modal_leave.btn_1',
      `继续撰写评价`,
      `繼續撰寫評價`,
      `Go back`,
      `Go back`,
    ],
  },
  point_back: {
    callout_1: [
      `수업 후기를 남기면 수업료의 50%를 포인트로 돌려드려요!`,
      `Leave a review and receive 50% value back in points!`,
      'n_review.point_back.callout_1',
      `留下课后评价，我们将提供相当于课程价格50%的积分给您。`,
      `留下課後評價，我們將提供相當於課程價格50%的點數給您。`,
      `レッスンレビューを投稿すると、レッスン料の50％をポイントで還元します！`,
      ``,
    ],
    callout_2: (point) => [
      `100자 이상의 수업 후기를 남기고 {point}포인트를 받으세요.`,
      <>Write a review over 100 characters and receive {point}P!</>,
      'n_review.point_back.callout_2',
      <>留下100字以上的评价，获得 {point}P积分!</>,
      <>留下100字以上的評價，獲得 {point}P點數!</>,
      <>100文字以上のレビューを残したら{point}Pを返します!</>,
      <>Write a review over 100 characters and receive {point}P!</>,
    ],
    modal_title: [
      `새로 온 튜터 후기 작성 안내`,
      `Review for the rookie tutor`,
      'n_review.point_back.modal_title',
      `给新教师评价`,
      `給新教師評價`,
      `新規チューターレビュー`,
      `Đánh giá gia sư mới`,
    ],
    modal_desc: (point) => [
      `수업 후기를 100자 이상 입력해야 {point}포인트를 돌려 받을 수 있어요. 지금 수업 평가를 완료하시겠어요?`,
      <>You must write at least 100 characters to receive {point}P. Do you want to submit the review now?</>,
      'n_review.point_back.modal_desc',
      <>写下100字以上的课后评价，获得 {point}积分. 请问现在要撰写评价吗?</>,
      <>寫下100字以上的課後評價，獲得 {point}點數. 請問現在要撰寫評價嗎?</>,
      <>授業レビューを100文字以上入力すると、{point}ポイントが返されます! 授業評価を完了しますか？</>,
      <> Bạn phải viết ít nhất 100 ký tự để nhận {point} điểm. Bạn có muốn gửi nhận xét bây giờ? </>,
    ],
    modal_btn_1: [
      `지금 평가 완료하기`,
      `Complete`,
      'n_review.point_back.modal_btn_1',
      `评价完成`,
      `評價完成`,
      `評価を完了`,
      `Complete`,
    ],
    modal_btn_2: [
      `추가 작성하기`,
      `Write more`,
      'n_review.point_back.modal_btn_2',
      `写更多评价`,
      `寫更多評價`,
      `追加作成`,
      `Write more`,
    ],
  },
  noshow_audio: [
    `노쇼 수업은 수업 스크립트가 제공되지 않습니다.`,
    `No-show lessons do not have audio recording and scripts.`,
    'n_review.noshow_audio',
    `旷课的情况, 将无法提供课程分析。`,
    `未出席的課程將無法提供課程錄音。`,
    `無断欠席したレッスンのため、レッスン分析は提供されません。`,
    `Các buổi học vắng mặt không có ghi âm và bản ghi.`,
  ],
  noshow_ai: [
    `노쇼 수업은 AI분석이 제공되지 않습니다.`,
    `AI analysis is unavailable for no-show lessons.`,
    'n_review.noshow_ai',
    `AI analysis is unavailable for no-show lessons.`,
    `未出席的課程將無法提供AI分析報告。`,
    `AI Analysis is unavailable for no-show lessons.`,
    `AI analysis is unavailable for no-show lessons.`,
  ],
  noshow_tutor: [
    `노쇼 수업은 튜터 피드백이 제공되지 않습니다.`,
    `Tutor feedback report is unavailable for no-show lessons.`,
    'n_review.noshow_tutor',
    `Tutor feedback report is unavailable for no-show lessons.`,
    `未出席的課程將無法提供教師課後評價報告。`,
    `Tutor feedback report is unavailable for no-show lessons.`,
    `Tutor feedback report is unavailable for no-show lessons.`,
  ],
}
export const n_account = {
  page_title: [`계정 관리`, `My Account`, 'n_account.page_title', `我的帐户`, `我的帳戶`, `アカウント管理`, ``],
  tap_1: [`기본 정보`, `Basic Info`, 'n_account.tap_1', `基本资料`, `基本資料`, `基本情報`, ``],
  tap_2: [`프로필 설정`, `Profile`, 'n_account.tap_2', `个人档案`, `個人檔案`, `プロフィール設定`, ``],
  tap_3: [`알림 수신 설정`, `Notification`, 'n_account.tap_3', `通知设定`, `通知設定`, `通知受信設定`, ``],
  tap_4: [`비밀번호 변경`, `Password`, 'n_account.tap_4', `变更密码`, `變更密碼`, `パスワード変更`, ``],
  tap_5: [`타임존 변경`, `Timezone`, 'n_account.tap_5', `变更时区`, `變更時區`, `タイムゾーンの変更`, ``],
  tap_6: [`언어 변경`, `Language`, 'n_account.tap_6', `变更语言`, `變更語言`, `言語の変更`, ``],
  toast: [
    `변경 사항을 저장했어요.`,
    `Successfully saved.`,
    'n_account.toast',
    `已成功保存变更内容`,
    `已成功儲存變更內容`,
    `変更を保存しました。`,
    ``,
  ],
  basic_info: {
    label_1: [`이메일`, `Email`, 'n_account.basic_info.label_1', `Email`, `Email`, `Eメール`, ``],
    label_2: [
      `휴대폰 번호`,
      `Phone number`,
      'n_account.basic_info.label_2',
      `电话号码`,
      `電話號碼`,
      `携帯電話番号`,
      ``,
    ],
    label_2_btn: [`변경`, `Update`, 'n_account.basic_info.label_2_btn', `更新`, `更新`, `変更`, ``],
    label_3: [`이름`, `Name`, 'n_account.basic_info.label_3', `中文姓名`, `中文姓名`, `氏名（日本語）`, `Tên`],
    label_3_placeholder: [
      `이름을 입력하세요.`,
      `Please enter your name`,
      'n_account.basic_info.label_3_placeholder',
      `请输入姓名`,
      `請輸入姓名`,
      `氏名を入力してください。`,
      ``,
    ],
    label_4: [`회사 이름`, `Company`, 'n_account.basic_info.label_4', `单位名称`, `公司名稱`, `会社名`, `Công ty`],
    label_4_placeholder: [
      `회사 이름을 입력하세요.`,
      `Company name`,
      'n_account.basic_info.label_4_placeholder',
      `请输入单位名称`,
      `請輸入公司名稱`,
      `会社名を入力してください。`,
      ``,
    ],
    label_5: [`직책(역할)`, `Role`, 'n_account.basic_info.label_5', `职称`, `職稱`, `役職(役割)`, `Chức vụ`],
    label_5_placeholder: [
      `직책 또는 역할을 입력하세요.`,
      `Role`,
      'n_account.basic_info.label_5_placeholder',
      `职称及工作部门`,
      `職稱及工作部門`,
      `役職を入力してください。`,
      ``,
    ],
    label_6: [
      `기업 맞춤 코드`,
      `B2B Partner code`,
      'n_account.basic_info.label_6',
      `企业专属代码`,
      `企業專屬代碼`,
      `企業専用コード`,
      `Mã code đối tác B2B`,
    ],
    label_6_placeholder: [
      `기업 코드를 입력하세요.`,
      `CODE`,
      'n_account.basic_info.label_6_placeholder',
      `请输入企业专属代码`,
      `請輸入企業專屬代碼`,
      `CODE`,
      `CODE`,
    ],
    label_6_desc: [
      `기업 맞춤 코드에 대한 문의는 고객센터를 통해 남겨주세요.`,
      `For B2B Partner code related inquiries, please contact us via chat below and leave us your current company name and Ringle ID.`,
      'n_account.basic_info.label_6_desc',
      `企业专属代码谘询，请连络Ringle团队，并留下您的单位名称及联繫方式。`,
      `企業專屬代碼諮詢，請連絡Ringle團隊，並留下您的公司名稱及聯絡資訊。`,
      `企業カスタムコードに関するお問い合わせは、カスタマーセンターまでご連絡ください。`,
      ``,
    ],
    btn_delete: [
      `링글 탈퇴하기`,
      `Delete my account`,
      'n_account.basic_info.btn_delete',
      `删除帐户`,
      `刪除帳戶`,
      `Ringleを退会する`,
      ``,
    ],
    preview_title: [
      `프로필 미리보기`,
      `Preview`,
      'n_account.basic_info.preview_title',
      `预览个人档案`,
      `預覽個人檔案`,
      `プロフィールプレビュー`,
      ``,
    ],
    preview_caption_1: [
      `기본 정보 소개`,
      `About Me`,
      'n_account.basic_info.preview_caption_1',
      `自我介绍`,
      `自我介紹`,
      `基本情報`,
      ``,
    ],
    preview_caption_2: [
      `경력 및 커리어 목표`,
      `Career Goals`,
      'n_account.basic_info.preview_caption_2',
      `职涯目标`,
      `職涯目標`,
      `キャリアゴール`,
      ``,
    ],
    preview_caption_3: [
      `취미 및 관심사`,
      `Hobbies & Interests`,
      'n_account.basic_info.preview_caption_3',
      `兴趣＆有兴趣的主题`,
      `興趣＆有興趣的主題`,
      `趣味・関心事`,
      ``,
    ],
  },
  change_num: {
    caption: [
      `새로 등록할 번호로 인증하세요.`,
      `Register your mobile number.`,
      'n_account.change_num.caption',
      `请验证您的手机号码`,
      `請驗證您的手機號碼`,
      `新しく登録する番号で認証してください。`,
      ``,
    ],
    placeholder: [
      `인증번호를 입력하세요.`,
      `Verification code`,
      'n_account.change_num.placeholder',
      `请输入验证号码`,
      `請輸入驗證號碼`,
      `認証番号を入力してください。`,
      ``,
    ],
    btn_1: [
      `인증번호 다시 요청`,
      `Request verification code`,
      'n_account.change_num.btn_1',
      `传送验证码`,
      `傳送驗證碼`,
      `認証コードを受け取る`,
      `Yêu cầu mã xác minh`,
    ],
    btn_2: [
      `인증번호 요청`,
      `Request verification code`,
      'n_account.change_num.btn_2',
      `重新传送验证码`,
      `重新傳送驗證碼`,
      `認証コードを再送する`,
      `Yêu cầu mã xác minh`,
    ],
    btn_3: [`인증`, `Verify`, 'n_account.change_num.btn_3', `输入完成`, `輸入完成`, `入力完了`, `Xác minh`],
    modal_desc_1: [
      `이미 등록된 번호입니다. 다른 번호를 입력해주세요.`,
      `Invalid phone number. Please try again.`,
      'n_account.change_num.modal_desc_1',
      `无效的手机号码，请再试一次！`,
      `無效的手機號碼，請再試一次！`,
      `すでに登録済みの番号です。別の番号を入力してください。`,
      ``,
    ],
    modal_desc_2: [
      `인증번호가 틀렸습니다. 받은 인증 번호를 정확히 입력해주세요.`,
      `Invalid verification code. Please try again.`,
      'n_account.change_num.modal_desc_2',
      `无效的验证号码，请再试一次！`,
      `無效的驗證號碼，請再試一次！`,
      `認証番号が間違っています。 受信した認証番号を正確に入力してください。`,
      ``,
    ],
  },
  del_account: {
    title: [
      `계정 삭제`,
      `Delete Account`,
      'n_account.del_account.title',
      `删除帐户`,
      `刪除帳戶`,
      `アカウントの削除`,
      ``,
    ],
    desc: [
      `링글 계정을 삭제하기 전에 반드시 유의사항을 확인하세요.`,
      `Terms`,
      'n_account.del_account.desc',
      `在删除Ringl账户前，请务必查看注意事项。`,
      `在刪除Ringl賬戶前，請務確認注意事項。`,
      `Ringleのアカウントを削除する前に必ず注意事項をご確認ください。`,
      ``,
    ],
    callout: [
      `한 번 삭제한 계정의 데이터는 복구할 수 없습니다.`,
      `Once an account is deleted, its data cannot be recovered.`,
      'n_account.del_account.callout',
      `删除帐户之后资料将无法復原。`,
      `刪除帳戶之後資料將無法復原。`,
      `一度削除したアカウントのデータは復元できません。`,
      ``,
    ],
    checkbox_1: [
      `보유중인 수업권과 포인트, 쿠폰을 모두 삭제합니다. 삭제한 수업권, 포인트, 쿠폰은 복구할 수 없습니다.`,
      `Remained credits and points will all be deleted.`,
      'n_account.del_account.checkbox_1',
      `持有的课程券、积分和优惠券都将删除。删除的课程券、积分和优惠券将无法恢复。`,
      `持有的課程券、點數和優惠券都將刪除。刪除的課程券、點數和優惠券將無法復原。`,
      `保有しているレッスンチケットとポイント、クーポンをすべて削除します。削除したレッスンチケット、ポイント、クーポンは復元できません。`,
      ``,
    ],
    checkbox_2: [
      `피드백 리포트, CAF 진단 기록, 수업 내역 등의 학습 기록을 다시 확인할 수 없습니다.`,
      `All lesson history will be deleted.`,
      'n_account.del_account.checkbox_2',
      `课后教师回馈报告、CAF诊断记录、课程历史记录等学习记录都将删除。`,
      `課後教師回饋報告、CAF診斷記錄、課程歷史記錄等學習記錄都將刪除。`,
      `フィードバックレポート、CAF診断記録、レッスン履歴などの学習記録を再度確認することはできません。`,
      ``,
    ],
    checkbox_3: [
      `삭제했던 이메일 주소로 다시 회원가입 할 수 없습니다.`,
      `You will have to make a new account with a different email address if you want to sign up again.`,
      'n_account.del_account.checkbox_3',
      `不能使用已删除的邮箱重新注册。`,
      `不能使用已刪除的郵件地址重新註冊。`,
      `削除したメールアドレスで再登録することはできません。`,
      ``,
    ],
  },
  profile: {
    banner: {
      title: [
        `내 프로필 완성하고 10,000포인트 받기`,
        `Complete your profile and receive points.`,
        'n_account.profile.banner.title',
        `完成个人档案获得10,000积分。`,
        `完成個人檔案獲得10,000點數。`,
        `プロフィール完成で10,000ポイントプレゼント！`,
        ``,
      ],
      desc: [
        `내 프로필을 모두 입력하면 튜터가 수업을 더 잘 준비해 줄 수 있어요!`,
        `Completing your profile allows the tutor to better prepare for your lessons!`,
        'n_account.profile.banner.desc',
        `填写完整的个人档案，教师可以更好地准备课程！`,
        `填寫完整的個人檔案，教師可以更好地準備課程！`,
        `自身のプロフィールをすべて入力することで、チューターがより良いレッスンの準備をすることができます！`,
        ``,
      ],
      callout: [
        `작성한 프로필은 수업 진행을 확정한 튜터만 확인할 수 있고, 언제든 수정할 수 있습니다.`,
        `The profile you've created can only be viewed by confirmed tutors, and you can modify it at any time.`,
        'n_account.profile.banner.callout',
        `填写的个人档案只有预计一起上课的教师可以查看，随时可以进行修改。`,
        `填寫的個人檔案只有預計一起上課的教師可以查看，隨時可以進行修改。`,
        `作成したプロフィールは、レッスンが確定したチューターのみが確認でき、いつでも修正することができます。`,
        ``,
      ],
    },
    section_1: {
      title: [`필수 항목`, `Required`, 'n_account.profile.section_1.title', `必填项目`, `必填項目`, `必須項目`, ``],
      desc: [
        `필수 항목을 모두 입력해야 포인트 지급이 완료됩니다.`,
        `You must fill in all essential information to receive points.`,
        'n_account.profile.section_1.desc',
        `完成所有必填项目才能获得积分`,
        `完成所有必填項目才能獲得點數`,
        `必須項目を全て入力しないとポイント付与が完了しません。`,
        ``,
      ],
      category_1: [
        `영어 공부 목적 (복수선택 가능, 최대 5개)`,
        `English Learning Goals (Select up to 5)`,
        'n_account.profile.section_1.category_1',
        `英语学习目的（最多选择5项）`,
        `英文學習目的（最多選擇5項）`,
        `英語学習の目的(複数選択可、最大5つ)`,
        `Mục đích học tiếng anh (Có thể chọn nhiều, tối đa 5)`,
      ],
      label_1: [
        `비즈니스`,
        `Work-related`,
        'n_account.profile.section_1.label_1',
        `工作需要`,
        `工作需要`,
        `ビジネス`,
        ``,
      ],
      checkbox_1: [
        `영어 발표/미팅 능력 향상`,
        `Better presentations/meetings in English`,
        'n_account.profile.section_1.checkbox_1',
        `增加英文报告/会议能力`,
        `增加英文報告/會議能力`,
        `英語プレゼン/ミーティング能力向上`,
        ``,
      ],
      checkbox_2: [
        `해외 컨퍼런스 및 출장 대비`,
        `Prepare for overseas business trips`,
        'n_account.profile.section_1.checkbox_2',
        `国外出差`,
        `國外出差`,
        `海外会議や出張の準備`,
        ``,
      ],
      checkbox_3: [
        `회사 동료/상사와 스몰토크 스킬 키우기`,
        `Better daily communication at work`,
        'n_account.profile.section_1.checkbox_3',
        `增加工作英文沟通能力`,
        `增加工作英文溝通能力`,
        `会社の同僚・上司との日常的なコミュニケーションスキルを磨く。`,
        ``,
      ],
      checkbox_4: [
        `업무 상 영어 작문 능력 향상`,
        `Better English writing skills for work`,
        'n_account.profile.section_1.checkbox_4',
        `增加工作英文写作能力`,
        `增加工作英文寫作能力`,
        `業務上の英語ライティング能力の向上`,
        ``,
      ],
    },
    section_2: {
      label_2: [
        `취업/이직/유학`,
        `Employment/study abroad`,
        'n_account.profile.section_2.label_2',
        `求职/转职/留学`,
        `求職/轉職/留學`,
        `就職・転職・留学`,
        ``,
      ],
      checkbox_5: [
        `해외로 이직 준비`,
        `Get employed overseas`,
        'n_account.profile.section_2.checkbox_5',
        `海外求职`,
        `海外求職`,
        `海外への転職準備`,
        ``,
      ],
      checkbox_6: [
        `외국계 회사로 이직 준비`,
        `Get employed at a foreign company`,
        'n_account.profile.section_2.checkbox_6',
        `国际企业求职`,
        `國際企業求職`,
        `外資系企業への転職準備`,
        ``,
      ],
      checkbox_7: [
        `해외로 유학 준비`,
        `Prepare for studying abroad`,
        'n_account.profile.section_2.checkbox_7',
        `国外留学`,
        `國外留學`,
        `海外への留学準備`,
        ``,
      ],
      checkbox_8: [
        `영어 에세이/이력서 작성`,
        `Write English essays/resumes`,
        'n_account.profile.section_2.checkbox_8',
        `英文履历／论文写作`,
        `英文履歷／論文寫作`,
        `英文エッセイ・履歴書作成`,
        ``,
      ],
      checkbox_9: [
        `영어 인터뷰 준비`,
        `Prepare for English interviews`,
        'n_account.profile.section_2.checkbox_9',
        `英文面试`,
        `英文面試`,
        `英語面接の準備`,
        ``,
      ],
      checkbox_10: [
        `어학 시험 준비`,
        `Prepare for language proficiency tests`,
        'n_account.profile.section_2.checkbox_10',
        `英文能力考试`,
        `英文能力考試`,
        `語学試験対策`,
        ``,
      ],
      label_3: [
        `자기계발`,
        `Personal development`,
        'n_account.profile.section_2.label_3',
        `自我成长`,
        `自我成長`,
        `自己啓発`,
        ``,
      ],
      checkbox_11: [
        `원어민과 스몰토크 스킬 키우기`,
        `Enjoy small talk with foreigners`,
        'n_account.profile.section_2.checkbox_11',
        `与母语人士沟通`,
        `與母語人士溝通`,
        `ネイティブスピーカーとの対話スキルを身につける`,
        ``,
      ],
      checkbox_12: [
        `영어로 대화/토론하는 것을 즐김`,
        `Hold discussions in English`,
        'n_account.profile.section_2.checkbox_12',
        `用英文自在地交谈`,
        `用英文自在地交談`,
        `英語で会話・ディスカッションを楽しむ`,
        ``,
      ],
      checkbox_13: [
        `현재 영어 실력 유지`,
        `Maintain English proficiency`,
        'n_account.profile.section_2.checkbox_13',
        `维持英文能力`,
        `維持英文能力`,
        `現在の英語力の維持`,
        ``,
      ],
      checkbox_14: [
        `영어권 국가의 문화 이해`,
        `Understand English-based culture`,
        'n_account.profile.section_2.checkbox_14',
        `了解英文文化圈文化`,
        `了解英文文化圈文化`,
        `英語圏の国の文化を理解する`,
        ``,
      ],
      checkbox_15: [
        `여행/휴가 시 더 유창한 영어 구사`,
        `English for travelling overseas`,
        'n_account.profile.section_2.checkbox_15',
        `旅游英文`,
        `旅遊英文`,
        `海外旅行使える英語力の習得`,
        ``,
      ],
      checkbox_16: [
        `기타(주관식)`,
        `Other`,
        'n_account.profile.section_2.checkbox_16',
        `其他（请自行填写）`,
        `其他（請自行填寫）`,
        `その他(自由記述)`,
        ``,
      ],
      category_2: [
        `집중 개선 영역 (복수선택 가능, 최대 3개)`,
        `Focus areas (Select up to 3)`,
        'n_account.profile.section_2.category_2',
        `集中改善领域（最多选择3项）`,
        `集中改善領域（最多選擇3項）`,
        `集中改善ポイント(複数選択可、最大3つ)`,
        `Kỹ năng cần cải thiện (Có thể chọn nhiều, tối đa 3)`,
      ],
      checkbox_17: [
        `기초 어휘부터 배우고 싶어요.`,
        `Basic vocabulary`,
        'n_account.profile.section_2.checkbox_17',
        `基础词彙`,
        `基礎詞彙`,
        `基礎的な語彙から学びたい`,
        `Từ vựng căn bản`,
      ],
      checkbox_18: [
        `새로운 고급 표현을 배우고 싶어요.`,
        `New advanced expressions`,
        'n_account.profile.section_2.checkbox_18',
        `新的高级表达方式`,
        `新的高級表達方式`,
        `新しい上級表現を学びたい`,
        `Biểu hiện nâng cao mới`,
      ],
      checkbox_19: [
        `원어민이 자주 쓰는 자연스러운 표현을 배우고 싶어요.`,
        <>
          Common expressions that
          <br />
          native English speakers use
        </>,
        'n_account.profile.section_2.checkbox_19',
        `英语母语者常用的表达方式`,
        `英文母語者常用的表達方式`,
        `ネイティブが使う自然な表現を学びたい`,
        `Giao tiếp tự nhiên như người bản xứ`,
      ],
      checkbox_20: [
        `영어로 간결하고 정확하게 표현하고 싶어요.`,
        `Speaking concisely and clearly in English`,
        'n_account.profile.section_2.checkbox_20',
        `用正确且清晰的英语表达`,
        `用正確且清晰的英文表達`,
        `英語で簡潔で正確に表現したい`,
        `Nói tiếng Anh ngắn gọn và rõ ràng`,
      ],
      checkbox_21: [
        `원어민 같은 발음/액센트로 말하고 싶어요.`,
        `Pronunciation/accent`,
        'n_account.profile.section_2.checkbox_21',
        `发音/口音`,
        `發音/口音`,
        `発音/アクセントの上達`,
        `Phát âm/ ngữ điệu`,
      ],
      checkbox_22: [
        `막힘없이 영어로 대화하고 싶어요.`,
        `Conversing fluidly in English`,
        'n_account.profile.section_2.checkbox_22',
        `流畅地用英语对话`,
        `流暢地用英文對話`,
        `英語でスラスラ話したい`,
        `Giao tiếp tiếng Anh trôi chảy`,
      ],
      checkbox_23: [
        `영어로 논리적으로 대화하고 싶어요.`,
        `Logical English speaking`,
        'n_account.profile.section_2.checkbox_23',
        `用精准且有逻辑的英文交谈`,
        `用精準且有邏輯的英文交談`,
        `英語で論理的に話したい`,
        `Nói tiếng Anh logic`,
      ],
      checkbox_24: [
        `영어로 논리적으로 글을 작성하고 싶어요.`,
        `Logical English writing`,
        'n_account.profile.section_2.checkbox_24',
        `用精准且有逻辑的英文书写`,
        `用精準且有邏輯的英文書寫`,
        `英語で論理的に作文したい`,
        `Viết tiếng Anh logic`,
      ],
      category_3: [
        `관심주제 (복수선택 가능, 최대 3개)`,
        `Topics of interest (Select up to 3)`,
        'n_account.profile.section_2.category_3',
        `兴趣话题（最多选择3项）`,
        `有興趣的話題（最多選擇3項）`,
        `興味のあるテーマ(複数選択可、最大3つ)`,
        `Chủ đề quan tâm (Chọn tối đa 3)`,
      ],
      checkbox_25: [
        `Business Case`,
        `Business Case`,
        'n_account.profile.section_2.checkbox_25',
        `商业案例`,
        `商業案例`,
        `Business Case`,
        `Business Case`,
      ],
      checkbox_26: [
        `Economy`,
        `Economy`,
        'n_account.profile.section_2.checkbox_26',
        `经济`,
        `經濟`,
        `Economy`,
        `Economy`,
      ],
      checkbox_27: [
        `Society/Politics`,
        `Society/Politics`,
        'n_account.profile.section_2.checkbox_27',
        `社会/政治`,
        `社會/政治`,
        `Society/Politics`,
        `Society/Politics`,
      ],
      checkbox_28: [`Tech`, `Tech`, 'n_account.profile.section_2.checkbox_28', `科技`, `科技`, `Tech`, `Tech`],
      checkbox_29: [
        `Science/Environment`,
        `Science/Environment`,
        'n_account.profile.section_2.checkbox_29',
        `科学/环境`,
        `科學/環境`,
        `Science/Environment`,
        `Science/Environment`,
      ],
      checkbox_30: [
        `Office Life`,
        `Office Life`,
        'n_account.profile.section_2.checkbox_30',
        `办公室英文`,
        `辦公室英文`,
        `Office Life`,
        `Office Life`,
      ],
      checkbox_31: [
        `Culture/Entertainment`,
        `Culture/Entertainment`,
        'n_account.profile.section_2.checkbox_31',
        `文化/娱乐`,
        `文化/娛樂`,
        `Culture/Entertainment`,
        `Culture/Entertainment`,
      ],
      checkbox_32: [`Sports`, `Sports`, 'n_account.profile.section_2.checkbox_32', `体育`, `體育`, `Sports`, `Sports`],
    },
    section_3: {
      title: [`선택 항목`, `Optional`, 'n_account.profile.section_3.title', `选择项目`, `選擇項目`, `選択項目`, ``],
      category_1: [
        `영어 이름`,
        `English Name`,
        'n_account.profile.section_3.category_1',
        `英文名字`,
        `英文名字`,
        `英語名`,
        ``,
      ],
      placeholder_1: [
        `First name`,
        `First name`,
        'n_account.profile.section_3.placeholder_1',
        `名字`,
        `名字`,
        `First name`,
        `First name`,
      ],
      placeholder_2: [
        `Last name`,
        `Last name`,
        'n_account.profile.section_3.placeholder_2',
        `姓氏`,
        `姓氏`,
        `Last name`,
        `Last name`,
      ],
      category_2: [`직업`, `Occupation`, 'n_account.profile.section_3.category_2', `职业`, `職業`, `職業/職種`, ``],
      radio_1: [
        `마케팅`,
        `Marketing`,
        'n_account.profile.section_3.radio_1',
        `市场营销`,
        `行銷`,
        `マーケティング`,
        `Marketing`,
      ],
      radio_2: [
        `사업 전략`,
        `Business strategy`,
        'n_account.profile.section_3.radio_2',
        `商业策略`,
        `策略`,
        `ビジネス戦略`,
        `Chiến lược kinh doanh`,
      ],
      radio_3: [`영업`, `Sales`, 'n_account.profile.section_3.radio_3', `销售`, `業務`, `営業`, `Sales`],
      radio_4: [
        `개발`,
        `Developer`,
        'n_account.profile.section_3.radio_4',
        `开发人员`,
        `程式開發人員`,
        `開発`,
        `Programming`,
      ],
      radio_5: [
        `PM/UX/서비스기획`,
        `PM/UX/UI`,
        'n_account.profile.section_3.radio_5',
        `产品经理/UX/UI设计师`,
        `產品經理/UX/UI設計師`,
        `PM/UX/サービス企画`,
        `PM/UX/UI`,
      ],
      radio_6: [
        `연구원/교수`,
        `Researcher/Professor`,
        'n_account.profile.section_3.radio_6',
        `研究员/教授`,
        `研究員/教授`,
        `研究員/教授`,
        `Nhà nghiên cứu / giáo sư`,
      ],
      radio_7: [
        `의료인`,
        `Medical professional`,
        'n_account.profile.section_3.radio_7',
        `医疗专业人员`,
        `醫療專業人員`,
        `医療従事者`,
        `Nhân viên y tế`,
      ],
      radio_8: [
        `법조인`,
        `Legal professional`,
        'n_account.profile.section_3.radio_8',
        `法律专业人员`,
        `法律專業人員`,
        `法律関係`,
        `Luật sư`,
      ],
      radio_9: [
        `예술/디자인`,
        `Artist/designer`,
        'n_account.profile.section_3.radio_9',
        `艺术家/设计师`,
        `藝術家/設計師`,
        `芸術/デザイン`,
        `Thiết kế mỹ thuật`,
      ],
      radio_10: [
        `재무/회계/인사`,
        `Finance/Accounting/HR`,
        'n_account.profile.section_3.radio_10',
        `财务/会计/人力资源`,
        `財務/會計/人力資源`,
        `財務/会計/人事`,
        `Tài chính/Kế toán/Nhân sự`,
      ],
      radio_11: [
        `대학(원)생`,
        `University/graduate student`,
        'n_account.profile.section_3.radio_11',
        `大学/研究生`,
        `大學/碩士生`,
        `大学(院)生`,
        `Sinh viên sau đại học`,
      ],
      radio_12: [
        `학생 (고등/중등/초등)`,
        `Primary/secondary school student`,
        'n_account.profile.section_3.radio_12',
        `小学/中学生`,
        `小學/中學生`,
        `学生(高校/中学/小学生)`,
        `Học sinh (THPT/THCS/Tiểu học)`,
      ],
      radio_13: [`기타`, `Other`, 'n_account.profile.section_3.radio_13', `其他`, `其他`, `その他`, `Khác`],
    },
    section_4: {
      title: [
        `영어 자기 소개`,
        `About Me`,
        'n_account.profile.section_4.title',
        `英文自我介绍`,
        `英文自我介紹`,
        `英語で自己紹介`,
        `Về tôi`,
      ],
      label_1: [
        `기본 정보 소개 (50 단어 이상)`,
        `About Me (enter min. 50 words)`,
        'n_account.profile.section_4.label_1',
        `自我介绍 (最少50字）`,
        `自我介紹 (最少50字）`,
        `基本情報（50語以上）`,
        ``,
      ],
      placeholder_1: [
        `자기소개를 영어로 입력해주세요.`,
        `Please tell us about yourself in English.`,
        'n_account.profile.section_4.placeholder_1',
        `请用英文撰写自我介绍`,
        `請用英文撰寫自我介紹`,
        `自己紹介を英語で入力してください。`,
        ``,
      ],
      label_2: [
        `경력 및 커리어 목표`,
        `Career Goals`,
        'n_account.profile.section_4.label_2',
        `职业目标`,
        `職涯目標`,
        `キャリアとキャリア目標`,
        ``,
      ],
      placeholder_2: [
        `경력 및 커리어 목표를 영어로 입력해주세요.`,
        `Please tell us about yourself in English.`,
        'n_account.profile.section_4.placeholder_2',
        `请用英文撰写职涯目标`,
        `請用英文撰寫職涯目標`,
        `キャリアとキャリア目標を英語で入力してください。`,
        ``,
      ],
      label_3: [
        `취미 및 관심사`,
        `Hobbies & Interests`,
        'n_account.profile.section_4.label_3',
        `兴趣＆有兴趣的主题`,
        `興趣＆有興趣的主題`,
        `趣味・関心事`,
        ``,
      ],
      placeholder_3: [
        `취미 및 관심사를 영어로 입력해주세요.`,
        `Please tell us about yourself in English.`,
        'n_account.profile.section_4.placeholder_3',
        `请用英文撰写兴趣＆有兴趣的主题`,
        `請用英文撰寫興趣＆有興趣的主題`,
        `趣味・関心事を英語で入力してください。`,
        ``,
      ],
      label_req: [`필수`, `Required`, 'n_account.profile.section_4.label_req', `必填`, `必填`, `必須`, ``],
      label_opt: [`선택`, `Optional`, 'n_account.profile.section_4.label_opt', `选填`, `選填`, `選択`, ``],
    },
    section_5: {
      title: [
        `프로필 영상`,
        `Profile Video`,
        'n_account.profile.section_5.title',
        `自我介绍影片`,
        `自我介紹影片`,
        `プロフィール動画`,
        ``,
      ],
      btn: [`동영상 촬영`, `Record Video`, 'n_account.profile.section_5.btn', `拍摄影片`, `拍攝影片`, `動画撮影`, ``],
    },
    section_6: {
      title: [
        `프로필 사진`,
        `Profile Photo`,
        'n_account.profile.section_6.title',
        `个人档案照片`,
        `個人檔案照片`,
        `プロフィール写真`,
        ``,
      ],
      btn: [
        `사진 업로드`,
        `Upload picture`,
        'n_account.profile.section_6.btn',
        `上传照片`,
        `上傳照片`,
        `写真のアップロード`,
        ``,
      ],
    },
  },
  noti: {
    section_1: {
      title: [`필수 항목`, `Required`, 'n_account.noti.section_1.title', `必填`, `必填`, `必須項目`, ``],
      label_1: [`이메일`, `Email`, 'n_account.noti.section_1.label_1', `选填`, `選填`, `Eメール`, ``],
      label_2: [
        `휴대폰 번호`,
        `Phone number`,
        'n_account.noti.section_1.label_2',
        `手机号码`,
        `手機號碼`,
        `携帯電話番号`,
        ``,
      ],
    },
    id: {
      radio_1: [
        `링글에 가입한 이메일 주소로 받기`,
        `Email (Ringle account)`,
        'n_account.noti.id.radio_1',
        `Email(注册Ringle的邮箱）`,
        `Email(註冊Ringle的信箱）`,
        `Ringleに登録したメールアドレスで受け取る`,
        ``,
      ],
      label_1: [`내 아이디`, `ID/Email`, 'n_account.noti.id.label_1', `ID/Email`, `ID/Email`, `私のID`, ``],
      radio_2: [
        `다른 이메일 주소로 받기`,
        `Secondary email`,
        'n_account.noti.id.radio_2',
        `备用Email`,
        `備用Email`,
        `別のメールアドレスで受け取る`,
        ``,
      ],
      placeholder: [
        `이메일 주소를 입력하세요.`,
        `Please enter email`,
        'n_account.noti.id.placeholder',
        `请输入Email`,
        `請輸入Email`,
        `メールアドレスを入力してください。`,
        ``,
      ],
      desc: [
        `알림을 수신할 이메일 주소를 입력하세요.`,
        `Please enter email for notifications.`,
        'n_account.noti.id.desc',
        `请输入欲接收通知的 Email`,
        `請輸入欲接收通知的 Email`,
        `通知を受け取るメールアドレスを入力してください。`,
        ``,
      ],
    },
    section_2: {
      title: [
        `수업권/포인트`,
        `Credits & points`,
        'n_account.noti.section_2.title',
        `课程券/积分`,
        `課程券/點數`,
        `レッスン料/ポイント`,
        ``,
      ],
      label_1: [`수업권`, `Credits`, 'n_account.noti.section_2.label_1', `课程券`, `課程券`, `レッスン料/ポイント`, ``],
      desc_1: [
        `Please`,
        `Get alerts for expiring credits`,
        'n_account.noti.section_2.desc_1',
        `接收课程券到期通知`,
        `接收課程券到期通知`,
        `お願いします`,
        ``,
      ],
      label_2: [`포인트`, `Points`, 'n_account.noti.section_2.label_2', `积分`, `點數`, `ポイント`, ``],
      desc_2: [
        `포인트 만료 알림을 받을 수 있습니다.`,
        `Get alerts for earned or expiring points`,
        'n_account.noti.section_2.desc_2',
        `接收积分到期通知`,
        `接收點數到期通知`,
        `ポイント有効期限のお知らせを受け取ることができます。`,
        ``,
      ],
    },
    section_3: {
      title: [`1:1 수업`, `1:1 Lesson`, 'n_account.noti.section_3.title', `1:1课程`, `1:1課程`, `1on1レッスン`, ``],
      label_1: [
        `수업 관련 중요 업데이트`,
        `Key updates to lessons`,
        'n_account.noti.section_3.label_1',
        `课程更新资讯`,
        `課程更新資訊`,
        `授業に関する重要なお知らせ`,
        ``,
      ],
      desc_1: [
        `수업 관련 중요하게 업데이트된 알림(튜터 매칭, 튜터 수업 취소, 수업 지각 등)을 받을 수 있습니다. 하나 이상의 알림 채널을 꼭 설정해 주셔야 합니다.`,
        `Get alerts for key updates to your lessons (tutor matching, cancellation, tardiness, etc.). Choose at least one notification channel.`,
        'n_account.noti.section_3.desc_1',
        `"您可以接收与课程相关的重要更新通知（教師配對、教師取消课程、教師遲到等）。请务必设置一个或多个通知渠道。`,
        `"您可以接收與課程相關的重要更新通知（教師配對、教師取消課程、教師遲到等）。請務必設定一個或多個通知管道。`,
        `レッスンに関する重要なお知らせ(チューターマッチング、チューターのレッスンキャンセル、レッスンの遅刻など)を受け取ることができます。1つ以上の通知チャンネルを必ず設定してください。`,
        ``,
      ],
      label_2: [
        `수업 리마인더`,
        `Lesson Reminders`,
        'n_account.noti.section_3.label_2',
        `课程提醒`,
        `課程提醒`,
        `レッスンリマインダー`,
        ``,
      ],
      desc_2: [
        `예정된 수업 리마인더 알림을 받을 수 있습니다.`,
        `Get alerts for lesson schedule`,
        'n_account.noti.section_3.desc_2',
        `接受课程提醒通知`,
        `接受課程提醒通知`,
        `予定されているレッスンのリマインダーを受け取ることができます。`,
        ``,
      ],
      label_dropdown: [
        `알림 시간`,
        `Notification Time`,
        'n_account.noti.section_3.label_dropdown',
        `通知时间`,
        `通知時間`,
        `通知時間`,
        ``,
      ],
      label_3: [
        `학습 활동`,
        `Learning activities`,
        'n_account.noti.section_3.label_3',
        `学习活动`,
        `學習活動`,
        `学習活動`,
        ``,
      ],
      desc_3: [
        `학습 활동에 도움을 주는 알림(수업 독려, 복습 등)을 앱 푸시로 받을 수 있습니다.`,
        `Get push notifications for continued learning (for lesson review, booking, etc.)`,
        'n_account.noti.section_3.desc_3',
        `通过APP 推送接收有助于学习活动的通知（上课提醒、课后复习等）。`,
        `透過APP 推播接收有助於學習活動的通知（上課提醒、課後複習等）。`,
        `学習活動に役立つ通知（レッスンの奨励、復習など）をアプリプッシュで受け取ることができます。`,
        ``,
      ],
      label_4: [
        `튜터 수업 스케줄 업데이트`,
        `New lesson slots`,
        'n_account.noti.section_3.label_4',
        `教师开课时间更新`,
        `教師開課時間更新`,
        `チューターのレッスンスケジュールの更新`,
        ``,
      ],
      desc_4: [
        `새로 업데이트된 수업 스케줄 알림을 앱 푸시로 받을 수 있습니다.`,
        `Get push notifications for new lesson slots`,
        'n_account.noti.section_3.desc_4',
        `透过APP 推播接收教师开课时间更新。`,
        `透過APP 推播接收教師開課時間更新。`,
        `新しく更新されたレッスンスケジュールの通知をアプリプッシュで受け取ることができます。`,
        ``,
      ],
      toggle: [
        `찜한 튜터 스케줄 업데이트`,
        `Updates to Favorite Tutors' availability`,
        'n_account.noti.section_3.toggle',
        `收藏的教师开课时间更新`,
        `收藏的教師開課時間更新`,
        `お気に入りのチューターのスケジュールの更新`,
        ``,
      ],
      toggle_desc: [
        `찜한 튜터의 정규수업 및 당일수업 업데이트를 받을 수 있습니다.`,
        `Get push notifications for Favorite Tutors' lesson slots`,
        'n_account.noti.section_3.toggle_desc',
        `透过APP 推播接收收藏教师开课时间更新。`,
        `透過APP 推播接收收藏教師開課時間更新。`,
        `お気に入りのチューターの定期レッスンや日替わりレッスンの最新情報を受け取ることができます。`,
        ``,
      ],
    },
    section_4: {
      title: [`콘텐츠`, `Contents`, 'n_account.noti.section_4.title', `教材`, `教材`, `コンテンツ`, ``],
      label_1: [
        `Content Weekly`,
        `Content Weekly`,
        'n_account.noti.section_4.label_1',
        `每週教材`,
        `每週教材`,
        `ウィークリーコンテンツ`,
        ``,
      ],
      desc_1: [
        `매주 금요일에 링글 추천 콘텐츠를 이메일로 받을 수 있습니다.`,
        `Get alerts for Ringle`,
        'n_account.noti.section_4.desc_1',
        `每週五接收由Ringle发送的推荐教材内容。`,
        `每週五接收由Ringle發送的推薦教材內容。`,
        `毎週金曜日にRingleのおすすめコンテンツをメールで受け取ることができます。`,
        ``,
      ],
      label_2: [
        `새로나온 교재 업데이트`,
        `Latest Material Update`,
        'n_account.noti.section_4.label_2',
        `最新教材更新`,
        `最新教材更新`,
        `最新の教材`,
        ``,
      ],
      desc_2: [
        `매주 월-금요일 아침 8시에 업데이트되는 신규 콘텐츠에 대한 알림을 받을 수 있습니다.`,
        `Get push notifications for new releases every weekday`,
        'n_account.noti.section_4.desc_2',
        `每天透过APP 推播接收每天教材更新资讯。`,
        `每天透過APP 推播接收每天教材更新資訊。`,
        `毎週月～金曜日の朝8時に更新される新コンテンツの通知を受け取ることができます。`,
        ``,
      ],
      label_3: [
        `링글 LIVE 리마인더`,
        `Ringle LIVE reminder`,
        'n_account.noti.section_4.label_3',
        `Ringle LIVE提醒`,
        `Ringle LIVE提醒`,
        `Ringle LIVEリマインダー`,
        ``,
      ],
      desc_3: [
        `매주 진행되는 링글 LIVE 알림을 받을 수 있습니다.`,
        `Get reminders for the weekly Ringle LIVE`,
        'n_account.noti.section_4.desc_3',
        `透过APP 推播接收Ringle LIVE提醒。`,
        `透過APP 推播接收Ringle LIVE提醒。`,
        `毎週行われるRingle LIVEのお知らせを受け取ることができます。`,
        ``,
      ],
    },
    section_5: {
      title: [
        `링글 소식 & 혜택`,
        `News & promotions`,
        'n_account.noti.section_5.title',
        `Ringle最新消息＆特价`,
        `Ringle最新消息＆特價`,
        `Ringleニュース＆特典`,
        ``,
      ],
      label_1: [
        `링글 뉴스레터`,
        `Ringle Newsletter`,
        'n_account.noti.section_5.label_1',
        `Ringle新闻`,
        `Ringle新聞`,
        `Ringleニュースレター`,
        ``,
      ],
      desc_1: [
        `격주 금요일에 링글 팀, 튜터, 수강생의 소식을 이메일로 받을 수 있습니다.`,
        `Get our latest news in your inbox every other Friday`,
        'n_account.noti.section_5.desc_1',
        `每隔週接收由Ringle发送的Ringle新闻。`,
        `每隔週接收由Ringle發送的Ringle新聞。`,
        `隔週金曜日にRingleチーム、チューター、受講生からのお知らせをメールで受け取ることができます。`,
        ``,
      ],
      label_2: [
        `이벤트·혜택`,
        `Events & offers`,
        'n_account.noti.section_5.label_2',
        `活动＆优惠`,
        `活動＆優惠`,
        `イベント・特典`,
        ``,
      ],
      desc_2: [
        `다양한 이벤트·혜택 알림을 받을 수 있습니다.`,
        `Get alerts for new events and offers`,
        'n_account.noti.section_5.desc_2',
        `接收Ringle最新消息通知。`,
        `接收Ringle最新消息通知。`,
        `様々なイベント・特典のお知らせを受け取ることができます。`,
        ``,
      ],
    },
    option: {
      sms: [`SMS·알림톡`, `Text/Kakao`, 'n_account.noti.option.sms', `简讯/Kakao`, `簡訊/Kakao`, `SMS-通知トーク`, ``],
      app: [`앱 푸시`, `App Push`, 'n_account.noti.option.app', `APP推播`, `APP推播`, `プッシュ通知`, ``],
      email: [`이메일`, `Email`, 'n_account.noti.option.email', `Email`, `Email`, `Eメール`, ``],
      cal: [
        `구글 캘린더`,
        `Google Calendar`,
        'n_account.noti.option.cal',
        `Google日曆`,
        `Google日曆`,
        `Googleカレンダー`,
        ``,
      ],
      cal_desc: [
        `구글 가입 계정에 한하여 예약한 수업 일정을 구글 캘린더 초대장으로 받을 수 있습니다.`,
        `Get calendar invites for scheduled lessons (only for users signed up with Google)`,
        'n_account.noti.option.cal_desc',
        `只限定于用Google帐号加入的用户，预约的课程将会直接汇入Google日曆。`,
        `只限定於用Google帳號加入的用戶，預約的課程將會直接匯入Google日曆。`,
        `Googleに登録したアカウントに限り、予約したレッスンスケジュールをGoogleカレンダーの招待状で受け取ることができます。`,
        ``,
      ],
    },
  },
  password: {
    title: [
      `변경할 비밀 번호를 입력하세요.`,
      `Please re-enter the new password.`,
      'n_account.password.title',
      `请输入新密码`,
      `請輸入新密碼`,
      `変更するパスワードを入力してください。`,
      ``,
    ],
    label_1: [`새 비밀번호`, `New Password`, 'n_account.password.label_1', `新密码`, `新密碼`, `新しいパスワード`, ``],
    label_2: [
      `비밀번호 확인`,
      `Confirm Password`,
      'n_account.password.label_2',
      `确认密码`,
      `確認密碼`,
      `パスワード確認`,
      ``,
    ],
    error_msg: [
      `비밀번호를 다시 확인해주세요.`,
      `Re-enter your password`,
      'n_account.password.error_msg',
      `重新输入密码`,
      `重新輸入密碼`,
      `パスワードを再確認してください。`,
      ``,
    ],
  },
  timezone: {
    title: [
      `사용할 타임존을 검색하거나 선택하세요.`,
      `Select Time zone`,
      'n_account.timezone.title',
      `选择时区`,
      `選擇時區`,
      `タイムゾーン`,
      `Múi giờ`,
    ],
  },
  language: {
    title: [
      `사용할 언어를 선택하세요.`,
      `Please select language.`,
      'n_account.language.title',
      `选择语言`,
      `選擇語言`,
      `使用する言語を選択してください。`,
      ``,
    ],
  },
  logout: [`로그아웃`, `Logout`, 'n_account.logout', `登出`, `登出`, `ログアウト`, ``],
}
export const n_lesson_prep = {
  overview: {
    title: [
      `효과적인 수업을 위해\n미리 준비해보세요.`,
      `Prepare in advance for effective learning.`,
      'n_lesson_prep.overview.title',
      `请预习课程以利有效率地进行课程`,
      `請預習課程以利有效率地進行課程`,
      `効果的なレッスンにするために\n事前準備を行いましょう。`,
      ``,
    ],
    info_insight: [
      `인사이트 읽고 내용 파악하기`,
      `Read the insight and get a feel of the material.`,
      'n_lesson_prep.overview.info_insight',
      `阅读文章并了解大意`,
      `閱讀文章並了解大意`,
      `インサイトを読んで内容を把握する`,
      ``,
    ],
    info_insight_desc: [
      `영어 버전을 바로 이해하기 어렵다면 한국어 버전을 먼저 읽은 후 오디오를 들으면서 교재 내용을 파악해 보세요.`,
      `If you can't understand the English version right away, try reading the Korean version first, then read the English version again while listening to the audio.`,
      'n_lesson_prep.overview.info_insight_desc',
      `如果觉得文章有很多地方不了解，可以先使用翻译软体了解大意之后，聆听文章朗读的同时再次阅读文章。`,
      `如果覺得文章有很多地方不了解，可以先使用翻譯軟體了解大意之後，聆聽文章朗讀的同時再次閱讀文章。`,
      `英語版をすぐに理解するのが難しい場合は、韓国語版を先に読んでから音声を聞きながら教材の内容を把握してください。`,
      ``,
    ],
    info_question: [
      `튜터와 수업할 질문 선택하기`,
      `Choose the questions you want to cover with the tutor.`,
      'n_lesson_prep.overview.info_question',
      `请选择想要和教师讨论的问题`,
      `請選擇想要和教師討論的問題`,
      `チューターとレッスンする質問を選択する`,
      ``,
    ],
    info_question_desc: [
      `20분 수업에서는 2개의 질문, 40분 수업에서는 3개의 질문을 선택해서 진행해보세요.`,
      `Choose 1-2 questions for a 20-min lesson and 2-3 questions for a 40-min lesson.`,
      'n_lesson_prep.overview.info_question_desc',
      `40分钟课程请选2-3个问题；20分钟课程请选1-2个问题`,
      `40分鐘課程請選2-3個問題；20分鐘課程請選1-2個問題`,
      `20分のレッスンでは2つの質問、40分のレッスンでは3つの質問を選んで進めてみましょう。`,
      ``,
    ],
    info_answer: [
      `수업 질문에 대한 생각 적어보기`,
      `Write down your thoughts for each question.`,
      'n_lesson_prep.overview.info_answer',
      `请写下您对问题的想法`,
      `請寫下您對問題的想法`,
      `レッスンの質問に対する考えを書いてみる`,
      ``,
    ],
    info_answer_desc: [
      `완벽한 답변이 아니어도 괜찮아요.  키워드 중심으로 답변을 준비해보고 튜터에게 교정받아 보세요.`,
      `You don't have to write in complete sentences. Think of what you want to say and write down the keywords. The tutor will help you find the right words during the lesson.`,
      'n_lesson_prep.overview.info_answer_desc',
      `不用担心回答不完美，用关键字尝试写下回答，让老师协助修改`,
      `不用擔心回答不完美，用關鍵字嘗試寫下回答，讓老師協助修改`,
      `完璧な答えでなくても大丈夫です。 キーワードを中心に答えを用意して、チューターに添削してもらいましょう。`,
      ``,
    ],
    info_free: [
      `자유 교재 수업(Speaking-focused / Writing-focused)의 경우 수업에 필요한 자료가 있다면 수업 전 관련 자료를 꼭 업로드해주세요.`,
      `For Free Topic lessons (Speaking-focused/Writing-focused), be sure to upload any material you want to use before the lesson.`,
      'n_lesson_prep.overview.info_free',
      `如果选择自由主题课程（口语为主/写作为主），请记得于上课前上传材料。`,
      `如果選擇自由主題課程（口說為主/寫作為主），請記得於上課前上傳文件資料。`,
      `フリートピックスレッスン（Speaking-focused / Writing-focused）の場合、レッスンに必要な資料があれば、レッスン前に必ずアップロードしてください。`,
      ``,
    ],
    info_tip: [
      `더 많은 수업준비 팁이 궁금하다면?`,
      `Want more tips on lesson prep?`,
      'n_lesson_prep.overview.info_tip',
      `想要获得更多课前预习的技巧吗？`,
      `想要獲得更多課前預習的技巧嗎？`,
      `レッスンの準備に関するTipsを知りたい方はこちら。`,
      ``,
    ],
    info_start: [
      `이제 수업 준비를 시작해볼까요?`,
      `Ready to prepare for your lesson?`,
      'n_lesson_prep.overview.info_start',
      `准备好要预习了吗？`,
      `準備好要預習了嗎？`,
      `それでは、レッスンの準備を始めましょう。`,
      ``,
    ],
    tooltip: [
      `수업 준비 내용을 모두 확인해주세요!`,
      `Please read to the end of the page!`,
      'n_lesson_prep.overview.tooltip',
      `请阅读完整页面`,
      `請閱讀完整頁面`,
      `レッスンの準備内容を全てご確認ください。`,
      ``,
    ],
    btn_lesson_prep: [
      `수업 준비하기`,
      `Prep for lesson`,
      'n_lesson_prep.overview.btn_lesson_prep',
      `准备课程`,
      `準備課程`,
      `レッスンの準備`,
      ``,
    ],
    title2: [
      `수업 준비 전에 참고해주세요.`,
      `Reminders before lesson prep`,
      'n_lesson_prep.overview.title2',
      `准备课程前的提醒`,
      `準備課程前的提醒`,
      `レッスンの準備の前に参考にしてください。`,
      ``,
    ],
    desc: [
      <>
        아직 수업의 AI 분석이나 튜터 피드백 기록이 없어요.
        <br />
        기록이 쌓이면 수업준비할때 참고할 내용들을 알려드릴거예요!
      </>,
      <>
        You have not received any AI analysis or tutor feedback yet.
        <br />
        We'll start giving you reminders once you've received them!
      </>,
      'n_lesson_prep.overview.desc',
      <>
        目前还没有课程的AI分析报告或教师评价报告。
        <br />
        如果报告准备好我们会通知您！
      </>,
      <>
        Y目前還沒有課程的AI分析報告或教師評價報告。
        <br />
        如果報告準備好我們會通知您！
      </>,
      <>
        まだレッスンのAI Analysisやチューターのフィードバック記録がありません。
        <br />
        記録が蓄積されると、レッスンの準備の際に参考になる内容をお知らせします！
      </>,
      ``,
    ],
    study_category1: [
      `개선 학습 영역`,
      `Improved areas`,
      'n_lesson_prep.overview.study_category1',
      `想要提升的领域`,
      `想要進步的領域`,
      `改善学習領域`,
      ``,
    ],
    study_category2: (name) => [
      `${name}님께 추천하는 추천학습 영역`,
      `Recommended focus areas`,
      'n_lesson_prep.overview.study_category2',
      `推荐给 ${name}的学习领域`,
      `推薦給 ${name}的學習領域`,
      `${name}さんにおすすめの学習領域`,
      ``,
    ],
    feedback_category1: (name) => [
      `직전 수업에서 받은 ${name}튜터의 피드백`,
      `Feedback from your last lesson with ${name}`,
      'n_lesson_prep.overview.feedback_category1',
      `从${name}教师获得的课程评价报告`,
      `從${name}教師獲得的課程評價報告`,
      `直前のレッスンで受けた${name}チューターからのフィードバック`,
      ``,
    ],
    feedback_category2: (name) => [
      `${name}튜터의 피드백`,
      `${name}'s feedback`,
      'n_lesson_prep.overview.feedback_category2',
      `${name}教师的评价`,
      `${name}教師的評價`,
      `${name}チューターからのフィードバック`,
      ``,
    ],
    info_class_count: (n) => [
      `${n}번째 수업`,
      `Lesson ${n}`,
      'n_lesson_prep.overview.info_class_count',
      `第${n}次课程`,
      `第${n}次課程`,
      `${n}番目のレッスン`,
      ``,
    ],
    info_class_desc: (name) => [
      `${name}튜터와의 최근 수업기준 데이터에요.`,
      `Most recent feedback from ${name}`,
      'n_lesson_prep.overview.info_class_desc',
      `和${name}教师的课程纪录`,
      `和${name}教師的課程紀錄`,
      `${name}チューターとの最近のレッスンデータです。`,
      ``,
    ],
    btn_done: [
      `수업 준비 완료하기`,
      `End lesson prep`,
      'n_lesson_prep.overview.btn_done',
      `课前准备完成`,
      `課前準備完成`,
      `レッスンの準備を完了する`,
      ``,
    ],
    tooltip_done: (n) => [
      `인사이트를 읽고, 질문 ${n}개를 선택해야해요.`,
      `Read the insight and choose ${n} questions.`,
      'n_lesson_prep.overview.tooltip_done',
      `阅读文章，选择 ${n}个课程问题`,
      `閱讀文章，選擇 ${n}個課程問題`,
      `インサイトを読んで、${n}個の質問を選択する必要があります。`,
      ``,
    ],
    study_category3: (name) => [
      `${name}님이 설정해둔 집중학습 영역`,
      `Your current focus areas`,
      'n_lesson_prep.overview.study_category3',
      `${name}的学习领域`,
      `${name}的學習領域`,
      `${name}さんが設定した学習エリア`,
      ``,
    ],
    tooltip_insight: [
      `인사이트를 읽고 완료버튼을 눌러주세요!`,
      `Read the insight and click done!`,
      'n_lesson_prep.overview.tooltip_insight',
      `阅读文章并点选完成！`,
      `閱讀文章並點選完成！`,
      `インサイトを読んで完了ボタンを押してください。`,
      ``,
    ],
  },
  popup_save_title: (n) => [
    <>
      작성중인 답변이 {n}개 있습니다.
      <br />
      저장을 눌러 답변을 모두 저장해주세요.
    </>,
    <>
      You have {n} unsaved memo(s).
      <br />
      Press [Save] to save all memo(s).
    </>,
    'n_lesson_prep.popup_save_title',
    <>
      你有{n}个正在撰写的草稿
      <br />
      请点保存内容以免遗失.
    </>,
    <>
      你有{n}個正在撰寫的草稿
      <br />
      請點儲存以免內容消失.
    </>,
    <>
      作成中の回答が{n}個あります。
      <br />
      保存を押して回答をすべて保存してください。
    </>,
    ``,
  ],
  popup_save_info: [
    `지금까지 작성한 내용이 선택해주신 공개범위로 저장됩니다.`,
    `All note(s) will be saved and shared according to the 'who can see this' setting.`,
    'n_lesson_prep.popup_save_info',
    `撰写的内容会依照您的公开设定保存`,
    `撰寫的內容會依照您的公開設定儲存`,
    `今まで作成した内容が選択した公開範囲に保存されます。`,
    ``,
  ],
  popup_leave_title: [
    `수업 준비를 그만할까요?`,
    <>
      You have not completed the lesson prep.
      <br />
      Quit and leave anyway?
    </>,
    'n_lesson_prep.popup_leave_title',
    <>
      您尚未完成课程准备
      <br />
      确定要离开不保存任何内容吗？
    </>,
    <>
      您尚未完成課程準備
      <br />
      確定要離開不儲存任何內容嗎？
    </>,
    `レッスンの準備をやめますか？`,
    ``,
  ],
  btn_back: [
    `뒤로가기`,
    `Upcoming lessons`,
    'n_lesson_prep.btn_back',
    `即将开始的课程`,
    `即將開始的課程`,
    `予定されているレッスン`,
    ``,
  ],
  popup_delete_title: [
    `작성한 답변을 삭제하시겠어요?`,
    `Do you wish to delete the memo?`,
    'n_lesson_prep.popup_delete_title',
    `您要删除回答吗？`,
    `您要刪除回答嗎？`,
    `作成した回答を削除しますか？`,
    ``,
  ],
  popup_edit_title: [
    <>
      작성중인 내용이 있습니다.
      <br />
      나가시겠어요?
    </>,
    <>
      You have unsaved memo(s).
      <br />
      Leave anyway?
    </>,
    'n_lesson_prep.popup_edit_title',
    <>
      您没有保存回答
      <br />
      确定要离开吗?
    </>,
    <>
      您沒有儲存回答
      <br />
      確定要離開嗎?
    </>,
    <>
      作成中の回答があります。
      <br />
      保存せずに画面から離れますか？
    </>,
    ``,
  ],
  popup_edit_info: [
    `저장하지 않고 벗어날 경우, 지금까지 작성한 내용이 사라집니다.`,
    `The  memo(s) will be lost if you leave without saving.`,
    'n_lesson_prep.popup_edit_info',
    `如果没有保存就离开，内容将消失。`,
    `如果沒有儲存就離開，內容將消失。`,
    `保存せずにページから離れた場合、作成していた内容が消えてしまいます。`,
    ``,
  ],
  insight_done: [
    `인사이트 읽기 완료`,
    `Check as done`,
    'n_lesson_prep.insight_done',
    `文章阅读完毕`,
    `文章閱讀完畢`,
    `インサイト読み込み完了`,
    ``,
  ],
  related_video: [`관련 영상`, `Video clip(s)`, 'n_lesson_prep.related_video', `相关视频`, `相關影片`, `関連動画`, ``],
  related_news: [`관련 기사`, `Article(s)`, 'n_lesson_prep.related_news', `相关报导`, `相關報導`, `関連記事`, ``],
  related_material: [
    `관련 교재`,
    `More from Ringle`,
    'n_lesson_prep.related_material',
    `相关教材`,
    `相關教材`,
    `関連教材`,
    ``,
  ],
  tab_insight: [`인사이트`, `Insight`, 'n_lesson_prep.tab_insight', `文章`, `文章`, `インサイト`, ``],
  tab_question: [`질문 선택`, `Questions`, 'n_lesson_prep.tab_question', `选择问题`, `選擇問題`, `質問を選択`, ``],
  tab_note: [`수업 노트`, `Lesson notes`, 'n_lesson_prep.tab_note', `课堂笔记`, `課堂筆記`, `レッスンノート`, ``],
  tab_note_newtab: [
    `노트 새탭으로 열기`,
    `Open in a new tab`,
    'n_lesson_prep.tab_note_newtab',
    `在新视窗打开`,
    `在新視窗打開`,
    `新しいタブで開く`,
    ``,
  ],
  select_question: [
    `수업 질문으로 선택하기`,
    `Select this question`,
    'n_lesson_prep.select_question',
    `选择为课程问题`,
    `選擇為課程問題`,
    `この質問を選択する`,
    ``,
  ],
  view_kr: [
    `질문 한글로 보기`,
    `Korean translation`,
    'n_lesson_prep.view_kr',
    `韩文翻译`,
    `韓文翻譯`,
    `質問を日本語で確認する`,
    ``,
  ],
  translation: [`구글 번역`, `Translate`, 'n_lesson_prep.translation', `Google翻译`, `Google翻譯`, `翻訳`, ``],
  answer_placeholder: [
    `질문에 대한 생각을 남겨보세요.\n혹시 작성이 어렵다면 한글로 작성하고 영어로 다시 고쳐보세요.`,
    `Write down your thoughts on the question.`,
    'n_lesson_prep.answer_placeholder',
    `请写下您对问题的想法`,
    `請寫下您對問題的想法`,
    `質問に対する考えを入力してください。\nもし書くのが難しい場合は、まず日本語で書いてから、英語に直してみてください。`,
    ``,
  ],
  answer_scope: [
    `공개범위`,
    `Who can see this`,
    'n_lesson_prep.answer_scope',
    `谁可以看到您的回答`,
    `誰可以看到您的回答`,
    `公開範囲`,
    ``,
  ],
  answer_scope_option1: [
    `모두보기`,
    `Everyone`,
    'n_lesson_prep.answer_scope_option1',
    `所有人`,
    `所有人`,
    `全ユーザー`,
    ``,
  ],
  answer_scope_option2: [
    `나만보기`,
    `Only Me`,
    'n_lesson_prep.answer_scope_option2',
    `仅自己`,
    `僅自己`,
    `自分のみ`,
    ``,
  ],
  answer_scope_option3: [
    `튜터와 나만 보기`,
    `Tutor and Me`,
    'n_lesson_prep.answer_scope_option3',
    `教师和我`,
    `教師和我`,
    `チューターと自分のみ`,
    ``,
  ],
  answer_my: [`내 답변`, `My answers`, 'n_lesson_prep.answer_my', `我的回答`, `我的回答`, `あなたの回答`, ``],
  answer_other: [
    `다른 링글러의 답변`,
    `Answers by others`,
    'n_lesson_prep.answer_other',
    `其他人的回答`,
    `其他人的回答`,
    `他者回答`,
    ``,
  ],
  answer_tag1: [`모두보기`, `Everyone`, 'n_lesson_prep.answer_tag1', `所有人`, `所有人`, `全ユーザー`, ``],
  answer_tag2: [
    `튜터와 나만 보기`,
    `Tutor and Me`,
    'n_lesson_prep.answer_tag2',
    `教师和我`,
    `教師和我`,
    `チューターと自分のみ`,
    ``,
  ],
  answer_tag3: [`나만보기`, `Only Me`, 'n_lesson_prep.answer_tag3', `仅自己`, `僅自己`, `自分のみ`, ``],
  answer_tag4: [
    `데일리학습`,
    `Daily Activity`,
    'n_lesson_prep.answer_tag4',
    `每日活动`,
    `每日活動`,
    `デイリー学習`,
    ``,
  ],
  answer_blank_desc: [
    `아직 답변이 없어요.`,
    `No answers have been written yet.`,
    'n_lesson_prep.answer_blank_desc',
    `尚未填写回答`,
    `尚未填寫回答`,
    `まだ回答がありません。`,
    ``,
  ],
  answer_menu_edit: [
    `답변 수정하기`,
    `Edit this answer`,
    'n_lesson_prep.answer_menu_edit',
    `编辑回答`,
    `編輯回答`,
    `回答を修正する`,
    ``,
  ],
  answer_menu_delete: [
    `답변 삭제하기`,
    `Delete this answer`,
    'n_lesson_prep.answer_menu_delete',
    `删除回答`,
    `刪除回答`,
    `回答を削除する`,
    ``,
  ],
  answer_edit_title: [
    `답변 수정하기`,
    `Edit answer`,
    'n_lesson_prep.answer_edit_title',
    `编辑回答`,
    `編輯回答`,
    `回答を修正する`,
    ``,
  ],
  translation_title: [
    `구글 번역`,
    `Google Translate`,
    'n_lesson_prep.translation_title',
    `Google翻译`,
    `Google翻譯`,
    `Google翻訳`,
    ``,
  ],
  translation_placeholder: [
    `모르는 단어나 질문에 대한 답변을 적어보세요.`,
    `Write words or thoughts you wish to translate into English.`,
    'n_lesson_prep.translation_placeholder',
    `写下您希望翻译的单字`,
    `寫下您希望翻譯的單字`,
    `わからない単語や質問に対する答えを書いてみましょう。`,
    ``,
  ],
  translation_btn_reset: [
    `답변 초기화`,
    `Delete all`,
    'n_lesson_prep.translation_btn_reset',
    `删除全部`,
    `刪除全部`,
    `回答の初期化`,
    ``,
  ],
  translation_btn_translate: [
    `번역하기`,
    `Translate`,
    'n_lesson_prep.translation_btn_translate',
    `翻译`,
    `翻譯`,
    `翻訳する`,
    ``,
  ],
  translation_btn_rewrite: [
    `다시 작성하기`,
    `Rewrite`,
    'n_lesson_prep.translation_btn_rewrite',
    `重新填写`,
    `重新填寫`,
    `再作成`,
    ``,
  ],
  translation_btn_use: [
    `이대로 사용하기`,
    `Use this`,
    'n_lesson_prep.translation_btn_use',
    `使用`,
    `使用`,
    `このまま使用する`,
    ``,
  ],
  complete: {
    btn_back: [
      `수업준비로 돌아가기`,
      `Return to lesson prep`,
      'n_lesson_prep.complete.btn_back',
      `回到课程准备页面`,
      `回到課程準備頁面`,
      `レッスン準備に戻る`,
      ``,
    ],
    btn_lesson_style: [
      `수업방식 설정`,
      `Set lesson style`,
      'n_lesson_prep.complete.btn_lesson_style',
      `设定课程模式`,
      `設定課程模式`,
      `レッスン方式の設定`,
      ``,
    ],
    title: [
      `수업준비 모두 완료`,
      `End lesson prep`,
      'n_lesson_prep.complete.title',
      `结束课程准备`,
      `結束課程準備`,
      `レッスン準備完了`,
      ``,
    ],
    desc: (name) => [
      <>
        {name}님,
        <br />
        수업시간에 만나요!
      </>,
      <>
        {name},<br />
        we'll see you in class!
      </>,
      'n_lesson_prep.complete.desc',
      <>
        {name},<br />
        我们课堂见!
      </>,
      <>
        {name},<br />
        我們課堂見!
      </>,
      <>
        {name}さん、
        <br />
        レッスンで会いましょう！
      </>,
      ``,
    ],
    tab_video: [
      `영상/기사`,
      `Video Clip/Article`,
      'n_lesson_prep.complete.tab_video',
      `影片/报导`,
      `影片/報導`,
      `動画/記事`,
      ``,
    ],
    tab_contents: [
      `링글 관련 콘텐츠`,
      `More from Ringle`,
      'n_lesson_prep.complete.tab_contents',
      `相关教材`,
      `相關教材`,
      `Ringl関連コンテンツ`,
      ``,
    ],
    tab_expression: [
      `핵심표현`,
      `Expressions`,
      'n_lesson_prep.complete.tab_expression',
      `重点生词`,
      `重點單字`,
      `表現集`,
      ``,
    ],
    btn_insight: [
      `인사이트 읽기`,
      `Insight`,
      'n_lesson_prep.complete.btn_insight',
      `阅读文章`,
      `閱讀文章`,
      `インサイトを読む`,
      ``,
    ],
    related_video: [
      `관련 영상`,
      `Video clip(s)`,
      'n_lesson_prep.complete.related_video',
      `相关视频`,
      `相關影片`,
      `関連動画`,
      ``,
    ],
    related_news: [
      `관련 기사`,
      `Article(s)`,
      'n_lesson_prep.complete.related_news',
      `相关报导`,
      `相關報導`,
      `関連記事`,
      ``,
    ],
    related_material: [
      `관련 교재`,
      `More from Ringle`,
      'n_lesson_prep.complete.related_material',
      `相关教材`,
      `相關教材`,
      `関連教材`,
      ``,
    ],
    title_done: [
      `수업 준비 모두 완료!`,
      `Completed lesson prep!`,
      'n_lesson_prep.complete.title_done',
      `课前准备完成！`,
      `課前準備完成！`,
      `レッスンの準備ができました！`,
      ``,
    ],
  },
  tab_upload: [
    `관련 자료 업로드`,
    `Upload Material`,
    'n_lesson_prep.tab_upload',
    `上传档案`,
    `上傳檔案`,
    `関連資料のアップロード`,
    ``,
  ],
  upload_desc1: [
    `업로드 형식을 확인해주세요.`,
    `Please check the format and size of the file you wish to upload.`,
    'n_lesson_prep.upload_desc1',
    `请确认档案格式`,
    `請確認檔案格式`,
    `アップロード形式をご確認ください。`,
    ``,
  ],
  upload_desc2: [
    `10mb 미만 PPT, PPTX, DOC, DOCX, PDF 파일 형식만 지원하며, 1개 파일만 업로드 할 수 있습니다.`,
    `You can only upload one file (in PPT, PPTX, DOC, DOCX, or PDF format) under 10 MB in size.`,
    'n_lesson_prep.upload_desc2',
    `您可以上传档案大小小于10MB的 PPT, PPTX, DOC, DOCX, 或 PDF 文件`,
    `您可以上傳檔案大小小於10MB的 PPT, PPTX, DOC, DOCX, 或 PDF 文件`,
    `10mb未満のPPT、PPTX、DOC、DOCX、PDFファイル形式のみ利用可能です。1つのファイルのみアップロードできます。`,
    ``,
  ],
  upload_guide: [
    `이곳에 DOC, DOCX 파일을 놓아주세요.`,
    `Please place the DOC/DOCX file here.`,
    'n_lesson_prep.upload_guide',
    `请在此上传DOC/DOCX档案`,
    `請在此上傳DOC/DOCX檔案`,
    `ここにDOC、DOCXファイルをアップロードしてください。`,
    ``,
  ],
  upload_select: [
    `파일 선택하기`,
    `Choose file`,
    'n_lesson_prep.upload_select',
    `选择档案`,
    `選擇檔案`,
    `ファイルの選択`,
    ``,
  ],
  upload_info_title: [
    `꼭 확인해주세요!`,
    `Please remember!`,
    'n_lesson_prep.upload_info_title',
    `请务必确认！`,
    `請務必確認！`,
    `是非ご覧ください！`,
    ``,
  ],
  upload_info1: [
    `수업 시작 24시간 전까지 문서 업로드를 권장드립니다.`,
    `We recommend uploading the material at least 24 hours before the lesson starts.`,
    'n_lesson_prep.upload_info1',
    `我们建议至少上课前24小时上传文件`,
    `我們建議至少上課前24小時上傳文件`,
    `レッスン開始の24時間前までに書類をアップロードすることをお勧めします。`,
    ``,
  ],
  upload_info2: [
    `문서 교정은 수업 시간에 실시간으로 진행됩니다.`,
    `Proofreading will be done in real time during the lesson.`,
    'n_lesson_prep.upload_info2',
    `文件修改将于课堂中即时进行`,
    `文件修改將於課堂中即時進行`,
    `文書の添削はレッスン中にリアルタイムで行われます。`,
    ``,
  ],
  upload_info3: [
    `개인 정보가 노출될 수 있는 민감한 내용은 사전 수정 요청드립니다.`,
    `We ask that you edit any sensitive content that may expose your personal information in advance.`,
    'n_lesson_prep.upload_info3',
    `上传前请先删除个人资料及机密资讯`,
    `上傳前請先刪除個人資料及機密資訊`,
    `個人情報が公開される可能性のある機密性の高い内容は、事前に修正をお願いします。`,
    ``,
  ],
  upload_info4: [
    `수업 중 영문 교정을 위해 개인 자료를 업로드 시, 20분 수업은 300단어(A4 1/2장), 40분 수업은 600단어(A4 1장) 분량을 권장 드립니다.(문서의 성격 및 튜터의 주제 친숙도에 따라 수업 내 진행할 수 있는 교정량이 상이할 수 있습니다.)`,
    `For a proofreading lesson, we recommend 300 words (1/2 A4 page) for a 20-minute class and 600 words (1 A4 page) for a 40-minute class. (The amount that can be covered during the lesson may vary depending on the nature of the material and the tutor's familiarity with the topic.)`,
    'n_lesson_prep.upload_info4',
    `在课堂上为了进行文件修改，建议上文件时，20分钟课程300字（A4纸半页），40分钟课程建议600字（A4纸1页）。实际的修改量可能会根据文件性质和教师对主题的熟悉程度而有所不同。`,
    `在課堂上為了進行文件修改，建議上文件時，20分鐘課程300字（A4紙半頁），40分鐘課程建議600字（A4紙1頁）。實際的修改量可能會根據文件性質和教師對主題的熟悉程度而有所不同。`,
    `添削レッスンの場合、20分のレッスンで300ワード（A4 1/2ページ）、40分のレッスンで600ワード（A4 1ページ）を推奨しています。(レッスン中にカバーできる分量は、教材の性質や講師のトピックへの慣れによって異なる場合があります)。`,
    ``,
  ],
  upload_info5: [
    `Writing-focused 수업은 튜터 피드백 리포트가 제공되지 않습니다.`,
    `Tutor's feedback report is not provided for writing-focused lessons.`,
    'n_lesson_prep.upload_info5',
    ``,
    `以寫作為中心的課程將不提供教師課後評價報告`,
    `Writing-focusedのレッスンでは、チューターのフィードバックレポートは提供されません。`,
    ``,
  ],
  upload_info6: [
    <>
      업로드한 자료가 보이지 않으면 새로고침 해주세요.
      <br />
      직접 교정받고 싶은 내용은 수업 노트에 붙여보세요.
    </>,
    <>
      If you can't see the uploaded material, please refresh the page.
      <br />
      Copy and paste the text you want proofread in the lesson note.
    </>,
    'n_lesson_prep.upload_info6',
    <>
      如果看不到上传的文件，请刷新页面。
      <br />
      可以将希望教师修改的内容直接黏贴于课堂笔记。
    </>,
    <>
      如果看不到上傳的文件，請重整頁面。
      <br />
      可以將希望教師修改的內容直接貼在課堂筆記。
    </>,
    <>
      アップロードした資料が表示されない場合は、ページを更新してください。
      <br />
      直接添削してもらいたい内容は、レッスンノートに貼り付けてください。
    </>,
    ``,
  ],
  upload_btn_delete: [
    `자료 삭제`,
    `Delete file`,
    'n_lesson_prep.upload_btn_delete',
    `删除档案`,
    `刪除檔案`,
    `資料を削除`,
    ``,
  ],
  upload_btn_other: [
    `다른 자료 업로드`,
    `Upload new file`,
    'n_lesson_prep.upload_btn_other',
    `上传新档案`,
    `上傳新檔案`,
    `他の資料のアップロード`,
    ``,
  ],
  upload_popup_save_title: [
    `자료를 삭제하시겠어요?`,
    `Do you wish to delete the file?`,
    'n_lesson_prep.upload_popup_save_title',
    `您希望删除档案吗？`,
    `您希望刪除檔案嗎？`,
    `資料を削除しますか？`,
    ``,
  ],
  upload_popup_save_info: [
    `삭제한 자료는 다시 재업로드해주셔야해요.`,
    `If you delete this file, you will need to re-upload a file.`,
    'n_lesson_prep.upload_popup_save_info',
    `如果您删除此档案，您将需要上传一个新的档案`,
    `如果您刪除此檔案，您將需要上傳一個新的檔案`,
    `資料を削除した場合、新たな資料をアップロードしてください。`,
    ``,
  ],
  popup_leave_info: [
    `수업 전까지 언제든지 다시 진행할 수 있어요.`,
    ``,
    'n_lesson_prep.popup_leave_info',
    `上课前随时可以进行`,
    `上課前隨時可以進行`,
    `レッスンが始まるまでやり直すことができます。`,
    ``,
  ],
  answer_edit_answer: [`수정하기`, `Edit`, 'n_lesson_prep.answer_edit_answer', `修改`, `修改`, `編集する`, ``],
  translation_placeholder_result: [
    `번역된 문장을 확인해주세요.`,
    `Please check translated result.`,
    'n_lesson_prep.translation_placeholder_result',
    `请确认翻译`,
    `請確認翻譯`,
    `翻訳された文章を確認してください。`,
    ``,
  ],
  translation_btn_translate_result: [
    `번역 결과`,
    `Translate Result`,
    'n_lesson_prep.translation_btn_translate_result',
    `翻译结果`,
    `翻譯結果`,
    `翻訳結果`,
    ``,
  ],
  upload_desc3: [
    `관련 자료 업로드 및 삭제는 PC 웹 브라우저에서 가능해요`,
    `Uploading file is only available in PC.`,
    'n_lesson_prep.upload_desc3',
    `只能于PC网页上传文件档案`,
    `只能於PC網頁上傳文件檔案`,
    `関連資料のアップロードと削除はPCのウェブブラウザで可能です。`,
    ``,
  ],
  popup_leave_default: [
    `변경사항이 저장되지 않을 수 있습니다. 정말로 이 페이지를 떠나시겠습니까?`,
    `Changes may not be saved. Are you sure you want to leave this page?`,
    'n_lesson_prep.popup_leave_default',
    `变更内容未储存，你确定要离开此页面吗？`,
    `變更內容未儲存，你確定要離開此頁面嗎？`,
    `変更内容が保存されない場合があります。本当にこのページを離れますか？`,
    ``,
  ],
  insight_done_toast: [
    `인사이트 읽기를 완료했어요.`,
    `Done reading Insight!`,
    'n_lesson_prep.insight_done_toast',
    `文章阅读完毕！`,
    `文章閱讀完畢！`,
    `インサイトの読み込みを完了しました。`,
    ``,
  ],
}
export const n_challenge = {
  title: [`챌린지`, `Challenges`, 'n_challenge.title', `挑战`, `挑戰`, ``, ``],
  monthly_status: (month) => [
    <>{month}월의 챌린지 현황</>,
    <>Challenges in {month}</>,
    'n_challenge.monthly_status',
    <>{month}月的挑战</>,
    <>{month}月的挑戰</>,
    <>{month}月の挑戦</>,
    <>Challenges in {month}</>,
  ],
  btn_badge: [`내 배지`, `My Badges`, 'n_challenge.btn_badge', `我的徽章`, `我的徽章`, `私のバッジ`, `My Badges`],
  tab_in_progress: [
    `진행 중인 챌린지`,
    `Active`,
    'n_challenge.tab_in_progress',
    `进行中的挑战`,
    `進行中的挑戰`,
    `進行中のチャレンジ`,
    `Active`,
  ],
  tab_open: [
    `진행 예정 챌린지`,
    `Upcoming`,
    'n_challenge.tab_open',
    `即将到来的挑战`,
    `即將到來的挑戰`,
    `進行予定のチャレンジ`,
    `Upcoming`,
  ],
  blank1: [
    `다음 달 챌린지를 준비 중이에요.`,
    `Next month's challenge is in the works.`,
    'n_challenge.blank1',
    `下个月的挑战正在准备中`,
    `下個月的挑戰正在準備中`,
    `来月のチャレンジを準備中です。`,
    `Next month's challenge is in the works.`,
  ],
  blank1_desc: [
    `조금만 기다려주세요!`,
    `Please hold on just a while longer.`,
    'n_challenge.blank1_desc',
    `请再等等！`,
    `請再等等！`,
    `しばらくお待ちください！`,
    `Please hold on just a while longer.`,
  ],
  label_inprogress: [`참여 중`, `Joined`, 'n_challenge.label_inprogress', `参与中`, `參與中`, `参加中`, `Joined`],
  label_success: [`달성`, `Success`, 'n_challenge.label_success', `达成`, `達成`, `達成済み`, `Success`],
  btn_join: [`참여하기`, `Join`, 'n_challenge.btn_join', `参与`, `參與`, `参加する`, `Join`],
  label_open: [`진행 예정`, `Open`, 'n_challenge.label_open', `即将开始`, `即將開始`, `進行予定`, `Open`],
  label_ended: [`종료`, `Ended`, 'n_challenge.label_ended', `结束`, `結束`, `終了`, `Ended`],
  label_fail: [`실패`, `Fail`, 'n_challenge.label_fail', `失败`, `失敗`, `失敗`, `Fail`],
  level_title: [
    `챌린지 단계`,
    `Challenge Level`,
    'n_challenge.level_title',
    `挑战等级`,
    `挑戰等級`,
    `チャレンジ段階`,
    `Challenge Level`,
  ],
  note1: [
    `선택한 챌린지 목표는 변경할 수 없습니다.`,
    `You cannot change the goal you set for the challenge.`,
    'n_challenge.note1',
    `无法变更挑战目标`,
    `無法變更挑戰目標`,
    `選択したチャレンジ目標は変更できません。`,
    `You cannot change the goal you set for the challenge.`,
  ],
  note2: [
    `수업권 종류와 관계없이 튜터와 학생 모두 입장한 수업은 챌린지 수업으로 인정됩니다.`,
    `Only lessons where both the tutor and student have entered are counted. (All types of lesson credits are accepted.)`,
    'n_challenge.note2',
    `不限制课程类型，只要教师与学生正常参与的课程都被认可。`,
    `不限制課程類型，只要教師與學生正常參與的課程都被認可。`,
    `レッスンチケットの種類に関わらず、チューターと生徒が共に参加したレッスンはチャレンジレッスンとみなされます。`,
    `Only lessons where both the tutor and student have entered are counted. (All types of lesson credits are accepted.)`,
  ],
  note3: [
    `챌린지 신청일 이전에 완료한 수업은 챌린지 수업으로 인정되지 않습니다.`,
    `Lessons completed before joining the challenge are not counted.`,
    'n_challenge.note3',
    `在挑战申请日前完成的课不视为挑战课程。`,
    `在挑戰申請日前完成的課不視為挑戰課程。`,
    `チャレンジ申請日以前に完了した授業は、チャレンジ授業として認められません。`,
    `Lessons completed before joining the challenge are not counted.`,
  ],
  note4: [
    `챌린지는 한국 시간을 기준으로 운영되며, 챌린지 종료일 저녁 23:30 KST에 시작한 수업까지 인정됩니다.`,
    `The challenge period is based on Korea Standard Time (KST). Lessons need to start at 23:30PM KST at the latest on the final day of the challenge to count toward the challenge.`,
    'n_challenge.note4',
    `挑战活动以韩国时间为基准进行，挑战截止日晚上23:30 KST以前开始的课程都将视为挑战课程。`,
    `挑戰活動以韓國時間為基準進行，挑戰截止日晚上23:30 KST以前開始的課程都將視為挑戰課程。`,
    `チャレンジは日本時間を基準に運営され、チャレンジ終了日の夜23:30に開始されたレッスンまで認定されます。`,
    `The challenge period is based on Korea Standard Time (KST). Lessons need to start at 23:30PM KST at the latest on the final day of the challenge to count toward the challenge.`,
  ],
  note5: [
    `챌린지 안내를 위한 [이벤트 및 혜택 소식] 수신에 동의해야만 챌린지가 신청됩니다.`,
    `To join the challenge, you must consent to receiving [Events & Offers] notifications, which is necessary for getting notices and updates on challenges.`,
    'n_challenge.note5',
    `参与挑战活动必须同意接受 [活动&优惠] 通知以利接受活动讯息。`,
    `參與挑戰活動必須同意接受 [活動&優惠] 通知以利接受活動訊息。`,
    `チャレンジ活動への参加は、イベントメッセージを受け取るために[イベント&特典]通知を受け入れることに同意する必要があります。`,
    `To join the challenge, you must consent to receiving [Events & Offers] notifications, which is necessary for getting notices and updates on challenges.`,
  ],
  note6: [
    `챌린지 신청일과 무관하게 7.1(월)부터 7.31(수)까지 진행한 수업은 모두 챌린지 달성 수업에 포함됩니다. (챌린지 신청일 이후의 수업만 인정되었던 챌린지 달성 조건이 7.8(월)에 변경되었습니다.)`,
    `All lessons conducted from July 1 (Mon) to July 31 (Wed) will count towards challenge completion, regardless of the date you joined. (The challenge completion criteria, which previously only recognized lessons taken after the date you joined, were changed on July 8 (Mon).)`,
    'n_challenge.note6',
    `All lessons conducted from July 1 (Mon) to July 31 (Wed) will count towards challenge completion, regardless of the date you joined. (The challenge completion criteria, which previously only recognized lessons taken after the date you joined, were changed on July 8 (Mon).)`,
    `All lessons conducted from July 1 (Mon) to July 31 (Wed) will count towards challenge completion, regardless of the date you joined. (The challenge completion criteria, which previously only recognized lessons taken after the date you joined, were changed on July 8 (Mon).)`,
    `All lessons conducted from July 1 (Mon) to July 31 (Wed) will count towards challenge completion, regardless of the date you joined. (The challenge completion criteria, which previously only recognized lessons taken after the date you joined, were changed on July 8 (Mon).)`,
    `All lessons conducted from July 1 (Mon) to July 31 (Wed) will count towards challenge completion, regardless of the date you joined. (The challenge completion criteria, which previously only recognized lessons taken after the date you joined, were changed on July 8 (Mon).)`,
  ],
  note7: [
    `링글 포인트와 수업권은 8.2(금)에 추첨을 통해 링글 계정에 자동 지급되며, 링글 계정에 등록된 연락처로 문자 안내를 드립니다.`,
    `Ringle points and lesson credits will be automatically credited to your Ringle account through a draw on August 2 (Fri). You will receive a text message to the contact number registered on your Ringle account.`,
    'n_challenge.note7',
    `Ringle points and lesson credits will be automatically credited to your Ringle account through a draw on August 2 (Fri). You will receive a text message to the contact number registered on your Ringle account.`,
    `Ringle points and lesson credits will be automatically credited to your Ringle account through a draw on August 2 (Fri). You will receive a text message to the contact number registered on your Ringle account.`,
    `Ringle points and lesson credits will be automatically credited to your Ringle account through a draw on August 2 (Fri). You will receive a text message to the contact number registered on your Ringle account.`,
    `Ringle points and lesson credits will be automatically credited to your Ringle account through a draw on August 2 (Fri). You will receive a text message to the contact number registered on your Ringle account.`,
  ],
  btn_goal: [
    `목표 선택하기`,
    `Set my goal`,
    'n_challenge.btn_goal',
    `设定挑战目标`,
    `設定挑戰目標`,
    `目標を選択する`,
    `Set my goal`,
  ],
  goal_title: [
    <>
      이번 달에 수업을
      <br />
      몇번 완료할까요?
    </>,
    <>
      Set your goal for
      <br />
      this month's challenge.
    </>,
    'n_challenge.goal_title',
    <>
      为这个月的挑战
      <br />
      设定目标。
    </>,
    <>
      為這個月的挑戰
      <br />
      設定目標。
    </>,
    <>
      目標 今月のレッスンを
      <br />
      何回 何回完了しますか？
    </>,
    <>
      Set your goal for
      <br />
      this month's challenge.
    </>,
  ],
  btn_join_full: [`목표 선택 완료`, `Done`, 'n_challenge.btn_join_full', `完成`, `完成`, `目標選択完了`, `Done`],
  reward: [`달성 리워드`, `Reward`, 'n_challenge.reward', `达成奖励`, `達成獎勵`, `達成報酬`, `Reward`],
  toast: [
    `챌린지에 참여했어요.`,
    `Joined challenge.`,
    'n_challenge.toast',
    `已经参与挑战`,
    `已經參與挑戰`,
    `チャレンジに参加しました。`,
    `Joined`,
  ],
  fail_desc: [
    `종료된 챌린지로 수업 수가 집계되지 않아요.`,
    `The deadline for this challenge has passed, and lessons are no longer counted toward this challenge.`,
    'n_challenge.fail_desc',
    `挑战已结束，课程将不列入计算。`,
    `挑戰已結束，課程將不列入計算。`,
    `終了したチャレンジはレッスン数がカウントされません。`,
    `The deadline for this challenge has passed, and lessons are no longer counted toward this challenge.`,
  ],
  badge: [`내 배지`, `My Badges`, 'n_challenge.badge', `我的徽章`, `我的徽章`, `マイバッジ`, `My Badges`],
  status_participating: [
    `참여`,
    `Joined`,
    'n_challenge.status_participating',
    `进行中的挑战`,
    `進行中的挑戰`,
    `参加`,
    `Joined`,
  ],
  status_success: [`달성`, `Success`, 'n_challenge.status_success', `成功`, `成功`, `達成`, `Success`],
  btn_history: [
    `종료된 챌린지`,
    `Past Challenges`,
    'n_challenge.btn_history',
    `过去的挑战`,
    `過去的挑戰`,
    `過去のチャレンジ`,
    `Past Challenges`,
  ],
  blank2: [
    `아직 획득한 배지가 없어요.`,
    `You have not won any badges yet.`,
    'n_challenge.blank2',
    `您还没有获得任何徽章`,
    `您還沒有獲得任何徽章`,
    `まだ獲得したバッジがありません。`,
    `You have not won any badges yet.`,
  ],
  blank2_btn: [
    `진행 중인 챌린지 보기`,
    `View open challeges`,
    'n_challenge.blank2_btn',
    `确认进行中的挑战`,
    `確認進行中的挑戰`,
    `進行中のチャレンジを見る`,
    ``,
  ],
  history: [
    `종료된 챌린지`,
    `Past Challenges`,
    'n_challenge.history',
    `过去的挑战`,
    `過去的挑戰`,
    `過去のチャレンジ`,
    `Past Challenges`,
  ],
  switch: [
    `달성한 챌린지`,
    `Success only`,
    'n_challenge.switch',
    `完成挑战`,
    `完成挑戰`,
    `達成したチャレンジ`,
    `Success only`,
  ],
  history_caption: [
    `최근 1년간 참여한 챌린지만 제공됩니다.`,
    <>
      Only the challenges you have joined
      <br />
      over the past year are shown.
    </>,
    'n_challenge.history_caption',
    <>只显示过去一年的挑战纪录</>,
    <>只顯示過去一年的挑戰紀錄</>,
    `最近1年間に参加したチャレンジのみ提供されます。`,
    <>
      Only the challenges you have joined
      <br />
      over the past year are shown.
    </>,
  ],
  blank3: [
    `참여한 종료 챌린지가 없어요.`,
    `You have not joined any challenges yet.`,
    'n_challenge.blank3',
    `您尚未参与任何挑战`,
    `您尚未參與任何挑戰`,
    `参加した過去チャレンジがありません。`,
    `You have not joined any challenges yet.`,
  ],
  blank3_btn: [
    `진행 중인 챌린지 보기`,
    `View open challeges`,
    'n_challenge.blank3_btn',
    `确认进行中的挑战`,
    `確認進行中的挑戰`,
    `進行中のチャレンジを見る`,
    `View open challeges`,
  ],
  agree_title: [
    <>
      챌린지 참여를 위해
      <br />
      아래 항목에 동의해주세요.
    </>,
    <>
      Please agree to the following
      <br />
      to join the challenge.
    </>,
    'n_challenge.agree_title',
    <>
      为了参与挑战
      <br />
      请同意以下事项
    </>,
    <>
      為了參與挑戰
      <br />
      請同意以下事項
    </>,
    <>
      チャレンジ チャレンジに参加するために
      <br />
      以下の項目に同意してください。
    </>,
    <>
      Please agree to the following
      <br />
      to join the challenge.
    </>,
  ],
  agree_option1: [
    `선택한 챌린지 목표는 변경할 수 없습니다.`,
    `You cannot change the goal you set for the challenge.`,
    'n_challenge.agree_option1',
    `您无法更改挑战目标`,
    `您無法更改挑戰目標`,
    `選択したチャレンジ目標は変更できません。`,
    `You cannot change the goal you set for the challenge.`,
  ],
  agree_option2: [
    `챌린지 진행 상황 및 혜택 안내를 위해 [이벤트 및 혜택 소식] 수신에 동의합니다.`,
    `I consent to receiving [Events & Offers] notifications.`,
    'n_challenge.agree_option2',
    `我同意接受 [活动&优惠] 通知`,
    `我同意接受 [活動&優惠] 通知`,
    `チャレンジの進行状況や特典案内のため、[イベントや特典のお知らせ]の受信に同意します。`,
    `I consent to receiving [Events & Offers] notifications.`,
  ],
  goal_op1: [
    <>
      Top 링글러를 향해 <br />월 8회 목표를 도전하세요.
    </>,
    <>
      Complete 8 lessons and
      <br />
      become a Top Ringler.
    </>,
    'n_challenge.goal_op1',
    <>
      成为Top Ringler
      <br />
      完成八堂课
    </>,
    <>
      成為Top Ringler
      <br />
      完成八堂課
    </>,
    <>
      , トップリンガーを目指して
      <br />, 月8回目標に挑戦してください。
    </>,
    <>
      Complete 8 lessons and
      <br />
      become a Top Ringler.
    </>,
  ],
  goal_op2: [
    <>
      월 6회 목표를 도전하고
      <br />
      영어 실력 변화를 느껴보세요.
    </>,
    <>
      Complete 6 lessons
      <br />
      and get tangible results.
    </>,
    'n_challenge.goal_op2',
    <>
      有效提升英语能力
      <br />
      完成六堂课
    </>,
    <>
      有效提升英文能力
      <br />
      完成六堂課
    </>,
    <>
      目標達成 月6回目標にチャレンジして
      <br />
      英語力の変化を実感してください。
    </>,
    <>
      Complete 6 lessons
      <br />
      and get tangible results.
    </>,
  ],
  goal_op3: [
    <>
      월 4회 목표를 도전하고
      <br />
      영어 실력 변화를 느껴보세요.
    </>,
    <>
      Complete 4 lessons
      <br />
      and get tangible results.
    </>,
    'n_challenge.goal_op3',
    <>
      踏出学习英语的第一步
      <br />
      完成四堂课
    </>,
    <>
      有效提升英文能力
      <br />
      完成四堂課
    </>,
    <>
      月4回 月4回目標にチャレンジして
      <br />
      英語力の変化を実感してください。
    </>,
    <>
      Complete 4 lessons
      <br />
      and get tangible results.
    </>,
  ],
  dday1: (dday) => [
    <>종료 D-{dday}</>,
    <>End: D-{dday}</>,
    'n_challenge.dday1',
    <>结束; D-{dday}</>,
    <>結束; D-{dday}</>,
    <>終了 D-{dday}</>,
    ``,
  ],
  dday2: (dday) => [
    <>시작 D-{dday}</>,
    <>Start: D-{dday}</>,
    'n_challenge.dday2',
    <>开始 D-{dday}</>,
    <>開始 D-{dday}</>,
    <>開始 D-{dday}</>,
    ``,
  ],
  success_date: (date) => [
    <>{date} 달성</>,
    <>Completed on {date}</>,
    'n_challenge.success_date',
    <>{date} 达成</>,
    <>{date} 達成</>,
    <>{date}に完了</>,
    ``,
  ],
  reward_badge: [
    `기간 한정 배지`,
    `Merit badge`,
    'n_challenge.reward_badge',
    `期间限定徽章`,
    `期間限定徽章`,
    `期間限定バッジ`,
    ``,
  ],
  info: [
    `챌린지는 한국 시간을 기준으로 운영됩니다.`,
    `The challenge period is based on Korea Standard Time (KST).`,
    'n_challenge.info',
    `挑战时间以韩国标准时间为准(KST)`,
    `挑戰時間以韓國標準時間為準(KST)`,
    `チャレンジは日本時間を基準に運営されます。`,
    ``,
  ],
  card_number1: (n) => [
    <>{n}회 완료</>,
    <>{n}</>,
    'n_challenge.card_number1',
    <>完成{n}次</>,
    <>完成{n}次</>,
    <>{n}回完了</>,
    ``,
  ],
  card_number2: (n) => [
    <>/{n}회</>,
    <>/{n} complete</>,
    'n_challenge.card_number2',
    <>/{n}次</>,
    <>/{n}次</>,
    <>/{n}回</>,
    ``,
  ],
  challengers: (number) => [
    <>{number}명 참여중</>,
    <>{number} challengers</>,
    'n_challenge.challengers',
    <>{number}位参与中</>,
    <>{number}位參與中</>,
    <>{number}人参加中</>,
    ``,
  ],
  goal: (n) => [
    <>수업 {n}회 듣기</>,
    <>Complete {n} lessons</>,
    'n_challenge.goal',
    <>完成 {n}次课程</>,
    <>完成 {n}次課程</>,
    <>{n}個のレッスンを完了する</>,
    ``,
  ],
  success: {
    title: [
      `축하합니다!`,
      `Congratulations!`,
      'n_challenge.success.title',
      `恭喜你！`,
      `恭喜你！`,
      `おめでとうございます！`,
      ``,
    ],
    desc: (title) => [
      <>{title}를 달성했어요.</>,
      <>{title} Successfully Completed.</>,
      'n_challenge.success.desc',
      <>达成了{title}</>,
      <>達成了{title}</>,
      <>{title}を達成しました。</>,
      ``,
    ],
  },
  count: (n) => [<>{n}회</>, <>{n}</>, 'n_challenge.count', <>{n}次</>, <>{n}次</>, <>{n} 回</>, ``],
  title_new: [`챌린지`, `Challenges`, 'n_challenge.title_new', `挑战`, `挑戰`, `チャレンジ`, ``],
  popup_setting: {
    title: [
      `사진 접근 권한이 필요해요`,
      `Photos access required`,
      'n_challenge.popup_setting.title',
      `需要读取照片权限`,
      `需要讀取照片權限`,
      `写真へのアクセス権が必要です`,
      ``,
    ],
    info: [
      <>
        이미지를 저장하려면
        <br />
        기기 설정에서 사진 접근을 ‘허용'해주세요.
      </>,
      `Please allow access to photos to continue saving the image.`,
      'n_challenge.popup_setting.info',
      <>
        如果要储存图片
        <br />
        请开启读取照片权限
      </>,
      <>
        如果要儲存圖片
        <br />
        請開啟讀取照片權限
      </>,
      <>
        画像を保存するには
        <br />
        デバイスの設定で写真へのアクセスを「許可」してください。
      </>,
      ``,
    ],
    btn1: [
      `기기 설정하기`,
      `Go to device settings`,
      'n_challenge.popup_setting.btn1',
      `前往设定`,
      `前往設定`,
      `端末の設定`,
      ``,
    ],
  },
  promotion_desc1: [
    `달성자 전원`,
    `For all achievers`,
    'n_challenge.promotion_desc1',
    `达成成就的所有人`,
    `達成成就的所有人`,
    `達成者全員`,
    ``,
  ],
  promotion_reward2: [
    `4박 5일 실리콘밸리 투어`,
    `5-day Silicon Valley tour`,
    'n_challenge.promotion_reward2',
    `5天4夜硅谷之旅`,
    `5天4夜矽谷之旅`,
    `4泊5日シリコンバレーツアー`,
    ``,
  ],
  promotion_desc2: [
    `600만원 상당 (신청자 중 10명 추첨)`,
    `Worth $4,615 (10 winners)`,
    'n_challenge.promotion_desc2',
    `一人价值USD4,600(抽10名）`,
    `一人價值USD4,600(抽10名）`,
    `$4,615相当(応募者の中から10名様を抽選)`,
    ``,
  ],
  promotion_reward3: [
    <>
      맹그로브 고성
      <br />
      2박 3일 워케이션 숙박권
    </>,
    `3-day stay in Mangrove Goseong`,
    'n_challenge.promotion_reward3',
    <>
      Mangrove(韩国）
      <br />
      3天2夜住宿券
    </>,
    <>
      Mangrove(韓國）
      <br />
      3天2夜住宿券
    </>,
    <>
      マングローブ古城
      <br />
      2泊3日ワーケーション宿泊券
    </>,
    ``,
  ],
  promotion_desc3: [
    `32만원 상당 (20명 추첨)`,
    `Worth $246 (Raffle for 20 winners)`,
    'n_challenge.promotion_desc3',
    `价值USD300(抽20名）`,
    `價值USD300(抽20名）`,
    `$246 相当(20名様抽選)`,
    ``,
  ],
  promotion_reward4: [
    <>
      링글 공동창업자의
      <br />
      1:1 영문 레주메 첨삭 및 커리어챗
    </>,
    `Career chat & resume review with Ringle's co-founder`,
    'n_challenge.promotion_reward4',
    <>
      与Ringle创办人进行
      <br />
      1:1 英文履历修改和职涯谘询
    </>,
    <>
      與Ringle創辦人進行
      <br />
      1:1 英文履歷修改和職涯諮詢
    </>,
    <>
      マングローブ古城 Ringle共同創業者の
      <br />
      1:1英文レジュメ添削及びキャリアチャット
    </>,
    ``,
  ],
  promotion_desc4: [
    `(30명 추첨)`,
    `(Raffle for 30 winners)`,
    'n_challenge.promotion_desc4',
    `价值USD230 (抽30名）`,
    `價值USD230 (抽30名）`,
    `(30名様抽選)`,
    ``,
  ],
  promotion_reward5: [
    `HBR 디지털+매거진 1년 구독권`,
    `HBR Digital & Print 1-year subscription`,
    'n_challenge.promotion_reward5',
    `哈佛商业评论杂誌电子版一年订阅`,
    `哈佛商業評論雜誌電子版一年訂閱`,
    `HBRデジタル+マガジン1年購読権`,
    ``,
  ],
  promotion_desc5: [
    `20만원 상당 (50명 추첨)`,
    `Worth $154 (Raffle for 50 winners)`,
    'n_challenge.promotion_desc5',
    `价值USD154 (抽50名）`,
    `價值USD154 (抽50名）`,
    `$154 相当 (50名様抽選)`,
    ``,
  ],
  promotion_reward6: [
    `밀리의 서재 1년 구독권`,
    `Millie's Library 1-year subscription`,
    'n_challenge.promotion_reward6',
    `Mille的书房（韩国）一年订阅`,
    `Mille的書房（韓國）一年訂閱`,
    `ミリーの書斎1年購読権`,
    ``,
  ],
  promotion_desc6: [
    `12만원 상당 (100명 추첨)`,
    `Worth $92 (Raffle for 100 winners)`,
    'n_challenge.promotion_desc6',
    `价值USD 92 (抽100名）`,
    `價值USD 92 (抽100名）`,
    `$92相当(100名様抽選)`,
    ``,
  ],
  promotion_title: [
    <>
      신년 프로모션 수업권(12회권 이상)을
      <br />
      구매한 고객만 참여할 수 있어요.
    </>,
    `Only customers who purchase the New Year Promotion credits (12 credits or more) can participate.`,
    'n_challenge.promotion_title',
    <>
      只有购买新年特价课程券(12次以上)
      <br />
      的用户才能参与
    </>,
    <>
      只有購買新年特價課程券(12次以上)
      <br />
      的用戶才能參與
    </>,
    <>
      プレゼント 新年プロモーションレッスンチケット(12回券以上)を
      <br />
      プレゼント 購入されたお客様のみ参加できます。
    </>,
    ``,
  ],
  promotion_desc: [
    `수업권 구매 시 자동으로 챌린지에 참여됩니다.`,
    `You will automatically join the challenge upon purchasing the credits.`,
    'n_challenge.promotion_desc',
    `只要购买课程券就自动参与挑战`,
    `只要購買課程券就自動參與挑戰`,
    `レッスンチケット購入時に自動的にチャレンジに参加されます。`,
    ``,
  ],
  promotion_btn: [`구매하러 가기`, `Buy now`, 'n_challenge.promotion_btn', `前往购买`, `前往購買`, `購入する`, ``],
  promotion: [
    `프로모션 추첨 상품`,
    `Promotional Draw Prizes`,
    'n_challenge.promotion',
    `促销活动抽奖商品`,
    `促銷活動抽獎商品`,
    `プロモーション抽選商品`,
    ``,
  ],
  goal_op4: [
    <>
      영어 공부의 첫걸음을
      <br />월 2회 목표로 차근차근 시작하세요.
    </>,
    <>
      Make a solid step forward
      <br />
      by completing 2 lessons.
    </>,
    'n_challenge.goal_op4',
    <>
      英语学习的第一步
      <br />
      从一个月上2次课开始吧
    </>,
    <>
      英文學習的第一步
      <br />
      從一個月上2次課開始吧
    </>,
    <>
      英語学習の第一歩
      <br />
      月に2回授業から始めましょう
    </>,
    ``,
  ],
  promotion_note: [
    <>
      <b>[3개월 이상 수업권 구매 시]</b>
      <li className="li-tag-inside">
        실리콘밸리 커리어 투어 포함 총 1억원 상당 상품 추첨: 기업 방문 및 현직자 네트워킹으로 구성된 4박5일 미국 투어를
        포함한 총 1억원 상당 상품 응모권 (24년 3월 추첨 예정)
      </li>
      <br />
      <b>[6개월 이상 수업권 구매 시]</b>
      <br />
      1. 글로벌 커리어 컨퍼런스 티켓 (7만원 상당)
      <li className="li-tag-inside">
        글로벌 커리어를 가진 마케팅부터 IT까지 다양한 직무의 연사가 강연하는 컨퍼런스 티켓 증정 (24년 3월 예정)
      </li>
      2. 원서/신문 읽기 프로그램 6개 (32만원 상당)
      <li className="li-tag-inside">
        매달 신규 영어 원서/신문을 튜터와 함께 읽는 Read Together 프로그램 6개 참여권 증정 (24년 2월부터 7월 참여 가능)
      </li>
      3. 링글 스터디 클럽 (4주)
      <li className="li-tag-inside">
        1월 한 달간 운영될 예정인 링글 고객들과 함께 영어 스피킹을 연습하는 소규모 온라인 스터디 모임 참여 가능
      </li>
    </>,
    <>
      <b>[When purchasing credits for a period longer than 3 months]</b>
      <li className="li-tag-inside">
        A Raffle Worth $76,000, including U.S. Career Tour: A chance to win a prize worth a total of $76,000, including
        5 days tour to the U.S., which includes visits to Silicon Valley companies and networking with professionals.
        (raffle scheduled for Mar. 2024).
      </li>
      <br />
      <b>[When purchasing credits for a period longer than 6 months]</b>
      <br />
      1. Global Career Conference Ticket (worth $54)
      <li className="li-tag-inside">
        A conference ticket for featuring speakers from diverse fields such as marketing to IT with international
        careers. (Scheduled for Mar. 2024)
      </li>
      2. Read Together Program (6 Sessions, worth $246)
      <li className="li-tag-inside">
        A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a
        tutor(Available from Feb 2024).
      </li>
      3. Ringle Study Club (4 Weeks)
      <li className="li-tag-inside">
        Participation in a online study group with Ringle customers to practice English throughout February.
      </li>
    </>,
    'n_challenge.promotion_note',
    <>
      <b>[When purchasing credits for a period longer than 3 months]</b>
      <li className="li-tag-inside">
        A Raffle Worth $76,000, including U.S. Career Tour: A chance to win a prize worth a total of $76,000, including
        5 days tour to the U.S., which includes visits to Silicon Valley companies and networking with professionals.
        (raffle scheduled for Mar. 2024).
      </li>
      <br />
      <b>[When purchasing credits for a period longer than 6 months]</b>
      <br />
      1. Global Career Conference Ticket (worth $54)
      <li className="li-tag-inside">
        A conference ticket for featuring speakers from diverse fields such as marketing to IT with international
        careers. (Scheduled for Mar. 2024)
      </li>
      2. Read Together Program (6 Sessions, worth $246)
      <li className="li-tag-inside">
        A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a
        tutor(Available from Feb 2024).
      </li>
      3. Ringle Study Club (4 Weeks)
      <li className="li-tag-inside">
        Participation in a online study group with Ringle customers to practice English throughout February.
      </li>
    </>,
    <>
      <b>[When purchasing credits for a period longer than 3 months]</b>
      <li className="li-tag-inside">
        A Raffle Worth $76,000, including U.S. Career Tour: A chance to win a prize worth a total of $76,000, including
        5 days tour to the U.S., which includes visits to Silicon Valley companies and networking with professionals.
        (raffle scheduled for Mar. 2024).
      </li>
      <br />
      <b>[When purchasing credits for a period longer than 6 months]</b>
      <br />
      1. Global Career Conference Ticket (worth $54)
      <li className="li-tag-inside">
        A conference ticket for featuring speakers from diverse fields such as marketing to IT with international
        careers. (Scheduled for Mar. 2024)
      </li>
      2. Read Together Program (6 Sessions, worth $246)
      <li className="li-tag-inside">
        A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a
        tutor(Available from Feb 2024).
      </li>
      3. Ringle Study Club (4 Weeks)
      <li className="li-tag-inside">
        Participation in a online study group with Ringle customers to practice English throughout February.
      </li>
    </>,
    <>
      <b>[When purchasing credits for a period longer than 3 months]</b>
      <li className="li-tag-inside">
        A Raffle Worth $76,000, including U.S. Career Tour: A chance to win a prize worth a total of $76,000, including
        5 days tour to the U.S., which includes visits to Silicon Valley companies and networking with professionals.
        (raffle scheduled for Mar. 2024).
      </li>
      <br />
      <b>[When purchasing credits for a period longer than 6 months]</b>
      <br />
      1. Global Career Conference Ticket (worth $54)
      <li className="li-tag-inside">
        A conference ticket for featuring speakers from diverse fields such as marketing to IT with international
        careers. (Scheduled for Mar. 2024)
      </li>
      2. Read Together Program (6 Sessions, worth $246)
      <li className="li-tag-inside">
        A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a
        tutor(Available from Feb 2024).
      </li>
      3. Ringle Study Club (4 Weeks)
      <li className="li-tag-inside">
        Participation in a online study group with Ringle customers to practice English throughout February.
      </li>
    </>,
    <>
      <b>[When purchasing credits for a period longer than 3 months]</b>
      <li className="li-tag-inside">
        A Raffle Worth $76,000, including U.S. Career Tour: A chance to win a prize worth a total of $76,000, including
        5 days tour to the U.S., which includes visits to Silicon Valley companies and networking with professionals.
        (raffle scheduled for Mar. 2024).
      </li>
      <br />
      <b>[When purchasing credits for a period longer than 6 months]</b>
      <br />
      1. Global Career Conference Ticket (worth $54)
      <li className="li-tag-inside">
        A conference ticket for featuring speakers from diverse fields such as marketing to IT with international
        careers. (Scheduled for Mar. 2024)
      </li>
      2. Read Together Program (6 Sessions, worth $246)
      <li className="li-tag-inside">
        A voucher for participating in the monthly "Read Together" program, reading English books or newspapers with a
        tutor(Available from Feb 2024).
      </li>
      3. Ringle Study Club (4 Weeks)
      <li className="li-tag-inside">
        Participation in a online study group with Ringle customers to practice English throughout February.
      </li>
    </>,
  ],
  guide: {
    apple: [
      `Apple은 이 이벤트의 스폰서가 아닙니다.`,
      `Apple is not a sponsor to this event.`,
      'n_challenge.guide.apple',
      `Apple is not a sponsor to this event.`,
      `Apple is not a sponsor to this event.`,
      `Apple is not a sponsor to this event.`,
      `Apple is not a sponsor to this event.`,
    ],
  },
  aispeaking_event_label: [
    `챌린지 달성자 대상 이벤트`,
    `Event for Challenge Achievers`,
    'n_challenge.aispeaking_event_label',
    `Event for Challenge Achievers`,
    `Event for Challenge Achievers`,
    `Event for Challenge Achievers`,
    ``,
  ],
  aispeaking_event_title: [
    <>
      AI 스피킹 파트너를 활용해
      <br />
      올라간 영어 실력을 자랑해주세요!
    </>,
    <>
      {' '}
      Show off your improved English skills
      <br /> using the AI Speaking Partner!{' '}
    </>,
    'n_challenge.aispeaking_event_title',
    <>
      {' '}
      Show off your improved English skills
      <br /> using the AI Speaking Partner!{' '}
    </>,
    <>
      {' '}
      Show off your improved English skills
      <br /> using the AI Speaking Partner!{' '}
    </>,
    <>
      {' '}
      Show off your improved English skills
      <br /> using the AI Speaking Partner!{' '}
    </>,
    ``,
  ],
  aispeaking_event_caption1: [
    `참여 방법`,
    `How to Participate`,
    'n_challenge.aispeaking_event_caption1',
    `How to Participate`,
    `How to Participate`,
    `How to Participate`,
    ``,
  ],
  aispeaking_event_desc1: [
    `챌린지 달성 후 9.11(수)까지 AI 스피킹 파트너 후기를 남겨주시면 추첨을 통해 무료 수업권을 드려요.`,
    `After completing the challenge, leave a short review for the AI Speaking Partner by September 11 (Wed) for a chance to win free lesson credits through a draw.`,
    'n_challenge.aispeaking_event_desc1',
    `After completing the challenge, leave a short review for the AI Speaking Partner by September 11 (Wed) for a chance to win free lesson credits through a draw.`,
    `After completing the challenge, leave a short review for the AI Speaking Partner by September 11 (Wed) for a chance to win free lesson credits through a draw.`,
    `After completing the challenge, leave a short review for the AI Speaking Partner by September 11 (Wed) for a chance to win free lesson credits through a draw.`,
    ``,
  ],
  aispeaking_event_caption2: [
    `당첨자 발표`,
    `Winner Announcement`,
    'n_challenge.aispeaking_event_caption2',
    `Winner Announcement`,
    `Winner Announcement`,
    `Winner Announcement`,
    ``,
  ],
  aispeaking_event_desc2: [
    `9.13(금) 링글 계정에 자동 지급`,
    `A free credit will be automatically added to Ringle account on September 13 (Fri)`,
    'n_challenge.aispeaking_event_desc2',
    `A free credit will be automatically added to Ringle account on September 13 (Fri)`,
    `A free credit will be automatically added to Ringle account on September 13 (Fri)`,
    `A free credit will be automatically added to Ringle account on September 13 (Fri)`,
    ``,
  ],
  aispeaking_event_caption3: [
    `당첨 상품`,
    `Prize`,
    'n_challenge.aispeaking_event_caption3',
    `Prize`,
    `Prize`,
    `Prize`,
    ``,
  ],
  aispeaking_event_desc3: [
    `20분 수업권 (50명 추첨)`,
    `20-minute lesson credit (50 winners selected by draw)`,
    'n_challenge.aispeaking_event_desc3',
    `20-minute lesson credit (50 winners selected by draw)`,
    `20-minute lesson credit (50 winners selected by draw)`,
    `20-minute lesson credit (50 winners selected by draw)`,
    ``,
  ],
  aispeaking_usage_title: [
    <>
      링글팀이 추천하는
      <br />
      AI 스피킹 파트너 활용법
    </>,
    <>
      {' '}
      Ringle team's recommendation for <br /> ways to use the AI Speaking Partner{' '}
    </>,
    'n_challenge.aispeaking_usage_title',
    <>
      {' '}
      Ringle team's recommendation for <br /> ways to use the AI Speaking Partner{' '}
    </>,
    <>
      {' '}
      Ringle team's recommendation for <br /> ways to use the AI Speaking Partner{' '}
    </>,
    <>
      {' '}
      Ringle team's recommendation for <br /> ways to use the AI Speaking Partner{' '}
    </>,
    ``,
  ],
  aispeaking_usage_sub_1: [`Step1`, `Step1`, 'n_challenge.aispeaking_usage_sub_1', `Step2`, `Step3`, `Step4`, ``],
  aispeaking_usage_1: [
    <>
      AI 스피킹 파트너는 <span className="text-[#503CC8]">링글 앱</span>에서만 사용할 수 있어요. 꼭 링글 앱을 다운로드
      받아주세요.
    </>,
    <>
      {' '}
      The AI Speaking Partner is available only on the <span className="text-[#503CC8]">Ringle app</span>. Make sure to
      download the Ringle app.{' '}
    </>,
    'n_challenge.aispeaking_usage_1',
    <>
      {' '}
      The AI Speaking Partner is available only on the <span className="text-[#503CC8]">Ringle app</span>. Make sure to
      download the Ringle app.{' '}
    </>,
    <>
      {' '}
      The AI Speaking Partner is available only on the <span className="text-[#503CC8]">Ringle app</span>. Make sure to
      download the Ringle app.{' '}
    </>,
    <>
      {' '}
      The AI Speaking Partner is available only on the <span className="text-[#503CC8]">Ringle app</span>. Make sure to
      download the Ringle app.{' '}
    </>,
    ``,
  ],
  aispeaking_usage_sub_2: [`Step2`, `Step2`, 'n_challenge.aispeaking_usage_sub_2', `Step3`, `Step4`, `Step5`, ``],
  aispeaking_usage_2: [
    <>
      링글 튜터와 1:1 수업 전 AI 스피킹 파트너와 연습해 보세요.
      <br />
      또는 수업 후 AI 스피킹 파트너와 다시 대화하며 수업 중 배운 표현을 완전히 내 것으로 만들어보세요.
      <br />딱 10분만 연습해도 다음 1:1 수업에서 영어가 술술 나올 거예요.
    </>,
    <>
      {' '}
      Practice with the AI Speaking Partner before your 1:1 lesson with a Ringle tutor.
      <br /> Or, after the lesson, chat with the AI Speaking Partner again to fully internalize the expressions you
      learned during the lesson.
      <br /> Just 10 minutes of practice will make your English flow smoothly in your next 1:1 lesson.{' '}
    </>,
    'n_challenge.aispeaking_usage_2',
    <>
      {' '}
      Practice with the AI Speaking Partner before your 1:1 lesson with a Ringle tutor.
      <br /> Or, after the lesson, chat with the AI Speaking Partner again to fully internalize the expressions you
      learned during the lesson.
      <br /> Just 10 minutes of practice will make your English flow smoothly in your next 1:1 lesson.{' '}
    </>,
    <>
      {' '}
      Practice with the AI Speaking Partner before your 1:1 lesson with a Ringle tutor.
      <br /> Or, after the lesson, chat with the AI Speaking Partner again to fully internalize the expressions you
      learned during the lesson.
      <br /> Just 10 minutes of practice will make your English flow smoothly in your next 1:1 lesson.{' '}
    </>,
    <>
      {' '}
      Practice with the AI Speaking Partner before your 1:1 lesson with a Ringle tutor.
      <br /> Or, after the lesson, chat with the AI Speaking Partner again to fully internalize the expressions you
      learned during the lesson.
      <br /> Just 10 minutes of practice will make your English flow smoothly in your next 1:1 lesson.{' '}
    </>,
    ``,
  ],
  aispeaking_usage_sub_3: [`Tips!`, `Tips!`, 'n_challenge.aispeaking_usage_sub_3', `Tips!`, `Tips!`, `Tips!`, ``],
  aispeaking_usage_tip: [
    <>
      1. 수업하지 않은 링글 교재 중에서도 자유롭게 선택해 AI 스피킹 파트너와 대화할 수 있어요.
      <br />
      2. AI 스피킹 파트너와 회화 연습을 잊지 않도록 공부할 시간에 앱 푸시 알림도 받아보세요.
    </>,
    <>
      {' '}
      1. You can freely choose from Ringle materials you haven't studied yet to converse with the AI Speaking Partner.
      <br /> 2. Don't forget to set app push notifications for your study time to practice conversation with the AI
      Speaking Partner.{' '}
    </>,
    'n_challenge.aispeaking_usage_tip',
    <>
      {' '}
      1. You can freely choose from Ringle materials you haven't studied yet to converse with the AI Speaking Partner.
      <br /> 2. Don't forget to set app push notifications for your study time to practice conversation with the AI
      Speaking Partner.{' '}
    </>,
    <>
      {' '}
      1. You can freely choose from Ringle materials you haven't studied yet to converse with the AI Speaking Partner.
      <br /> 2. Don't forget to set app push notifications for your study time to practice conversation with the AI
      Speaking Partner.{' '}
    </>,
    <>
      {' '}
      1. You can freely choose from Ringle materials you haven't studied yet to converse with the AI Speaking Partner.
      <br /> 2. Don't forget to set app push notifications for your study time to practice conversation with the AI
      Speaking Partner.{' '}
    </>,
    ``,
  ],
  agree_option3: [
    `AI 스피킹 파트너는 앱에서 사용할 수 있으며, 10턴 이상 대화한 경우 1회로 인정됩니다.`,
    `The AI Speaking Partner is available on the app, and a conversation of 10 turns or more is counted as one session.`,
    'n_challenge.agree_option3',
    `The AI Speaking Partner is available on the app, and a conversation of 10 turns or more is counted as one session.`,
    `The AI Speaking Partner is available on the app, and a conversation of 10 turns or more is counted as one session.`,
    `The AI Speaking Partner is available on the app, and a conversation of 10 turns or more is counted as one session.`,
    ``,
  ],
}
export const n_lesson_review = {
  btn_back: [
    `지난 수업으로가기`,
    `Past lessons`,
    ' n_lesson_review.btn_back',
    `已完成的课程`,
    `已完成的課程`,
    `過去のレッスンに戻る`,
    ``,
  ],
  tab1: [`AI 분석`, `AI Analysis`, ' n_lesson_review.tab1', `AI分析报告`, `AI分析報告`, `AI Analysis`, ``],
  tab2: [
    `튜터 피드백`,
    `Tutor's Feedback`,
    ' n_lesson_review.tab2',
    `教师的评价报告`,
    `教師的評價報告`,
    `チューターからのフィードバック`,
    ``,
  ],
  tab3: [`수업 녹음`, `Recording`, ' n_lesson_review.tab3', `课程录音`, `課程錄音`, `レッスンの録音`, ``],
  tab4: [`수업 노트`, `Lesson Notes`, ' n_lesson_review.tab4', `课堂笔记`, `課堂筆記`, `レッスンノート`, ``],
  btn_lesson_prep: [
    `수업 준비 다시보기`,
    `View lesson prep`,
    ' n_lesson_review.btn_lesson_prep',
    `查看课程准备内容`,
    `查看課程準備內容`,
    `レッスン準備の振り返り`,
    ``,
  ],
  ai_status_title1: [
    `AI 분석 준비중입니다.`,
    `AI analysis is being prepared.`,
    ' n_lesson_review.ai_status_title1',
    `您选择不获得 [AI分析报告]`,
    `您選擇不獲得 [AI分析報告]`,
    `AI Analysis準備中です。`,
    ``,
  ],
  ai_status_title2: [
    `이번 수업은 AI 분석 받지 않기를 선택했어요.`,
    `You chose to turn [AI analysis] off for this lesson.`,
    ' n_lesson_review.ai_status_title2',
    `您可以在[课程模式设定]中更改AI分析设定`,
    `您可以在[課程模式設定]中更改AI分析設定`,
    `今回のレッスンはAI Analysisを受けないことを選択しました。`,
    ``,
  ],
  ai_status_detail2: [
    `수업 방식 설정에서 진단 받기 여부를 설정할 수 있어요.`,
    `You can change the setting for AI analysis under [Set Lesson Style].`,
    ' n_lesson_review.ai_status_detail2',
    `您可以在[课程模式设定]中更改AI分析的设定`,
    `您可以在[課程模式設定]中更改AI分析的設定`,
    `レッスン方式設定で診断を受けるかどうかを設定できます。`,
    ``,
  ],
  ai_status_title3: [
    `이번 수업은 녹음하지 않기를 선택했어요.`,
    `You chose to turn [Record Lesson] off for this lesson.`,
    ' n_lesson_review.ai_status_title3',
    `您选择在此课程中关闭[课程录音]`,
    `您選擇在此課程中關閉[課程錄音]`,
    `今回のレッスンは録音しないことにしました。`,
    ``,
  ],
  ai_status_detail3: [
    `수업 방식 설정에서 수업 녹음과 AI 분석 받기 여부를 설정할 수 있어요.`,
    `You can change the settings for recording lessons and receiving AI analyses under [Set Lesson Style].`,
    ' n_lesson_review.ai_status_detail3',
    `您可以在[课程模式设定]中更改课程录音和AI分析报告的设定`,
    `您可以在[課程模式設定]中更改課程錄音和AI分析報告的設定`,
    `レッスン方式の設定で、レッスンの録音とAI Analysisを受けるかどうかを設定できます。`,
    ``,
  ],
  ai_status_title4: [
    `라이팅 중심 수업은 AI 분석이
제공되지 않습니다.`,
    `AI analysis is not available for writing-focused lessons.`,
    ' n_lesson_review.ai_status_title4',
    `写作课程不提供AI分析报告`,
    `寫作課程不提供AI分析報告`,
    `ライティング中心のレッスンは、AI Analysisが
 提供されません。`,
    ``,
  ],
  feedback_report: [
    `Tutor’s Feedback Report`,
    `Tutor’s Feedback Report`,
    ' n_lesson_review.feedback_report',
    `教师的评价报告`,
    `教師的評價報告`,
    `チューターのフィードバックレポート`,
    ``,
  ],
  btn_print: [`프린트`, `Print`, ' n_lesson_review.btn_print', `打印`, `列印`, `印刷`, ``],
  feedback_time: [`시간`, `Time slot`, ' n_lesson_review.feedback_time', `时间`, `時間`, `時間`, ``],
  feedback_tutor: [`튜터`, `Tutor`, ' n_lesson_review.feedback_tutor', `教师`, `教師`, `チューター`, ``],
  feedback_material: [`교재`, `Material`, ' n_lesson_review.feedback_material', `教材`, `教材`, `教材`, ``],
  feedback_section_title1: (name) => [
    `Message to ${name}`,
    `Message to ${name}`,
    ' n_lesson_review.feedback_section_title1',
    `给${name}的讯息`,
    `給${name}的訊息`,
    `Message to ${name}`,
    ``,
  ],
  feedback_section_title2: [
    `What you did great`,
    `What you did great`,
    ' n_lesson_review.feedback_section_title2',
    `课堂中表现好的部分`,
    `課堂中表現好的部分`,
    `良かった点`,
    ``,
  ],
  feedback_section_detail2: (name) => [
    `튜터가 선택한 ${name}님이 이번 수업에서 잘한 영역이에요.`,
    `These are the areas the tutor felt you showed improvement in this lesson.`,
    ' n_lesson_review.feedback_section_detail2',
    `课堂中教师觉得您表现好的部分`,
    `課堂中教師覺得您表現好的部分`,
    `チューターから見て、このレッスンで${name} さんが上達した部分です。`,
    ``,
  ],
  feedback_section_title3: [
    `What you can improve`,
    `What you can improve`,
    ' n_lesson_review.feedback_section_title3',
    `您可以提升的地方`,
    `您可以加強的地方`,
    `改善点`,
    ``,
  ],
  feedback_section_detail3: [
    `수업에서 다룬 내용 중에 튜터가 선택한 다음 수업에서 집중하면 좋은 피드백과 설명이에요.`,
    `These are the tutor's detailed feedback on some of the points covered in this lesson for you to keep in mind in your next lesson.`,
    ' n_lesson_review.feedback_section_detail3',
    `根据这次课程教师提供您于下次课程可以使用的学习建议`,
    `根據這次課程教師提供您於下次課程可以使用的學習建議`,
    `このレッスンで言及されたポイントについての詳細なフィードバックです。`,
    ``,
  ],
  feedback_done: [
    `튜터 피드백 확인 완료`,
    `Check as done`,
    ' n_lesson_review.feedback_done',
    `完成确认教师评价报告`,
    `完成確認教師評價報告`,
    `チューターのフィードバック確認完了`,
    ``,
  ],
  feedback_status_title1: [
    <>
      수업 피드백 준비중입니다.
      <br />
      튜터 피드백은 최대 24시간 소요됩니다.
    </>,
    <>
      Tutor's feedback report is being prepared.
      <br />
      The report will be ready within 24 hours.
    </>,
    ' n_lesson_review.feedback_status_title1',
    `教师评价报告准备中`,
    `教師評價報告準備中`,
    `レッスンフィードバック準備中です。`,
    ``,
  ],
  feedback_status_title2: [
    <>
      튜터 피드백 리포트가 제공되지 않는 수업입니다.
      <li className="li-tag-inside">튜터와 상호 합의 하에 피드백이 제공되지 않는 수업</li>
      <li className="li-tag-inside">Writing Focused으로 진행된 수업</li>
    </>,
    <>
      Tutor's feedback report is not available in the following cases:
      <li className="li-tag-inside">
        Lessons for which the tutor's feedback report is not provided based on a mutual agreement between the student
        and the tutor.
      </li>
      <li className="li-tag-inside">Writing focused lessons</li>
    </>,
    ' n_lesson_review.feedback_status_title2',
    <>
      这堂课不提供教师评价报告
      <li className="li-tag-inside">和老师讨论后决定不提供课后评价的课程</li>
      <li className="li-tag-inside">以写作为主(Writing Focused)进行的课程</li>
    </>,
    <>
      這堂課不提供教師評價報告
      <li className="li-tag-inside">和老師討論後決定不提供課後評價的課程</li>
      <li className="li-tag-inside">以寫作為主(Writing Focused)進行的課程</li>
    </>,
    `今回の授業では、チューターのフィードバックレポートが
 提供されません。`,
    ``,
  ],
  feedback_status_title3: [
    `라이팅 중심 수업은 튜터 피드백 리포트가 
제공되지 않습니다.`,
    `Tutor's feedback report is not available for writing-focused lessons.`,
    ' n_lesson_review.feedback_status_title3',
    `以写作为主的课程不提供教师评价报告`,
    `以寫作為主的課程不提供教師評價報告`,
    `今回のレッスンでは、チューターのフィードバックレポートが
 提供されません。`,
    ``,
  ],
  script: [`스크립트`, `Transcript`, ' n_lesson_review.script', `课程录音文字档`, `課程錄音文字檔`, `スクリプト`, ``],
  script_status_title1: [
    `수업 녹음파일과 스크립트를 준비중입니다.`,
    `Audio file is being prepared.`,
    ' n_lesson_review.script_status_title1',
    `课程录音已可确认`,
    `課程錄音已可確認`,
    `レッスンの録音ファイルとスクリプトを準備中です。`,
    ``,
  ],
  script_status_title2: [
    `이번 수업은 녹음하지 않기를 선택했어요.`,
    `You chose to turn [Record Lesson] off for this lesson.`,
    ' n_lesson_review.script_status_title2',
    `您选择在此课程中关闭[课程录音]`,
    `您選擇在此課程中關閉[課程錄音]`,
    `今回のレッスンは録音しないことにしました。`,
    ``,
  ],
  script_status_detail2: [
    `수업 방식 설정에서 수업 녹음 여부를 설정할 수 있어요.`,
    `You can change the setting for recording lessons under [Set Lesson Style].`,
    ' n_lesson_review.script_status_detail2',
    `您可以在[课程模式设定]中选择是否进行课程录音`,
    `您可以在[課程模式設定]中選擇是否進行課程錄音`,
    `レッスン方式設定でレッスンの録画の有無を設定することができます。`,
    ``,
  ],
  script_status_detail1: [
    `수업 종료 후, 최대 24시간 정도 소요될 수 있습니다.`,
    `This process may take up to 24 hours after lesson.`,
    ' n_lesson_review.script_status_detail1',
    `课程结束后约需24小时`,
    `課程結束後約需24小時`,
    `授業終了後、最大24時間程度かかる場合があります。`,
    `This process may take up to 24 hours after lesson.`,
  ],
}
export const n_b2b = {
  title: (week) => [
    <>{week}주차 출석률</>,
    <>Attendance rate as of {week} </>,
    'n_b2b.title',
    <>第{week}週出席率</>,
    <>第{week}週出席率</>,
    <>{week}週の出席率</>,
    ``,
  ],
  my_attandance: (percent) => [
    <>현재 출석률 {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    'n_b2b.my_attandance',
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
  ],
  b2b_attandance: (week, percent) => [
    <>
      {week}주차 권장 출석률 {percent}%
    </>,
    <>Recommended for Week: {percent}%</>,
    'n_b2b.b2b_attandance',
    <>Recommended for Week: {percent}%</>,
    <>Recommended for Week: {percent}%</>,
    <>Recommended for Week: {percent}%</>,
    <>Recommended for Week: {percent}%</>,
  ],
  desc1: [
    `수업 완료 횟수`,
    `Lessons completed`,
    'n_b2b.desc1',
    `Number of lessons completed`,
    `完成的課程數`,
    `授業完了回数`,
    `Number of lessons completed`,
  ],
  count1: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    <>
      {complete_count} out of {count}
    </>,
    'n_b2b.count1',
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}回完了/{count}回 {complete_count}回
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
  ],
  desc2: [
    `이수 기준 수업 수`,
    `Minimum number of lessons required`,
    'n_b2b.desc2',
    `Minimum number of lessons required`,
    `必須完成的最低課程數`,
    `修了基準授業数`,
    `Minimum number of lessons required`,
  ],
  count2: (count) => [
    <>{count}회</>,
    <>{count}</>,
    'n_b2b.count2',
    <>{count}</>,
    <>{count}</>,
    <>{count} 回</>,
    <>{count}</>,
  ],
  desc3: [`남은 수업권`, `Available`, 'n_b2b.desc3', `有效课程券`, `有效課程券`, `残りの授業権`, `Available`],
  regular_credits: [`정규`, `Regular`, 'n_b2b.regular_credits', `一般课程券`, `一般課程券`, `正規`, `Regular`],
  count3: (count) => [
    <>{count}회</>,
    <>{count}</>,
    'n_b2b.count3',
    <>{count}</>,
    <>{count}</>,
    <>{count} 回</>,
    <>{count}</>,
  ],
  free_credits: [`당일`, `Last-minute`, 'n_b2b.free_credits', `当日课程券`, `當日課程券`, `当日`, `Last-minute`],
  count4: (count) => [
    <>{count}회</>,
    <>{count}</>,
    'n_b2b.count4',
    <>{count}</>,
    <>{count}</>,
    <>{count} 回</>,
    <>{count}</>,
  ],
  desc4: [`수강 기간`, `Expires in`, 'n_b2b.desc4', `使用期限还剩下`, `使用期限還剩下`, `受講期間`, `Expires in`],
  count5: (day) => [
    <>{day}일 남음</>,
    <>{day} days</>,
    'n_b2b.count5',
    <>{day} days</>,
    <>{day} days</>,
    <>
      {day}日残り {day}日
    </>,
    <>{day} days</>,
  ],
  coment1: (count) => [
    <>
      수강 첫 주입니다!
      <br />
      매주 {count}회 이상의 수업을 권장하고 있어요.
    </>,
    <>
      This is the first week of the course!
      <br />
      We recommend you to take at least {count} lesson(s) each week
    </>,
    'n_b2b.coment1',
    <>
      这是课程开始的第一週
      <br />
      我没推荐您每週至少完成{count} 堂课
    </>,
    <>
      這是課程開始的第一週
      <br />
      我沒推薦您每週至少完成{count} 堂課
    </>,
    <>
      受講初週です！
      <br />
      毎週{count}回以上の受講を推奨しています。
    </>,
    <>
      This is the first week of the course!
      <br />
      We recommend you to take at least {count} lesson(s) each week
    </>,
  ],
  coment2: [
    <>
      잘하고 있어요!
      <br />
      꾸준히 수업이 영어 실력 향상의 지름길이에요.
    </>,
    <>
      You're doing great!
      <br />
      Consistency is the key to improving your English.
    </>,
    'n_b2b.coment2',
    <>
      您的学习状况良好！
      <br />
      只有不断地学习英文才能进步
    </>,
    <>
      您的學習狀況良好！
      <br />
      只有不斷地學習英文才能進步
    </>,
    <>
      頑張っています！
      <br />
      継続的なレッスンが英語力アップの近道です。
    </>,
    <>
      You're doing great!
      <br />
      Consistency is the key to improving your English.
    </>,
  ],
  coment3: (count) => [
    <>
      기간 내 완강을 위해
      <br />
      {count}회 이상의 권장 수업 횟수를 채워주세요.
    </>,
    <>
      You should take at least {count} lesson(s) a week
      <br />
      to complete all lessons in time.
    </>,
    'n_b2b.coment3',
    <>
      每周至少上 {count} lesson(s) 堂课
      <br />
      才能在期限内完成所有课程
    </>,
    <>
      每周至少上 {count} lesson(s) 堂課
      <br />
      才能在期限內完成所有課程
    </>,
    <>
      期間内修了のために
      <br />
      {count}回以上の推奨レッスン回数を満たしてください。
    </>,
    <>
      You should take at least {count} lesson(s) a week
      <br />
      to complete all lessons in time.
    </>,
  ],
  coment4: [
    <>
      완강을 축하드립니다!
      <br />
      노력한 만큼 영어 실력이 성장했을 거예요.
    </>,
    <>
      Congratulations on completing all of the lessons!
      <br />
      We're sure your hard work has paid off.
    </>,
    'n_b2b.coment4',
    <>
      恭喜完成所有课程！
      <br />
      我们相信您的努力一定会有收穫。
    </>,
    <>
      恭喜完成所有課程！
      <br />
      我們相信您的努力一定會有收穫。
    </>,
    <>
      修了おめでとうございます 修了おめでとうございます！
      <br />
      頑張った分だけ英語力が伸びたと思います。
    </>,
    <>
      Congratulations on completing all of the lessons!
      <br />
      We're sure your hard work has paid off.
    </>,
  ],
  title_lesson: (percent) => [
    `1:1 수업 출석률 ${percent}%`,
    `1:1 Lesson Progress ${percent}%`,
    'n_b2b.title_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  title_asp: (percent) => [
    `AI 튜터 출석률 ${percent}%`,
    `AI Tutor Progress ${percent}%`,
    'n_b2b.title_asp',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_count_asp: [`AI 스피킹 파트너 대화 횟수`, ``, 'n_b2b.desc_count_asp', ``, ``, ``, ``],
  count_total_asp: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    ``,
    'n_b2b.count_total_asp',
    ``,
    ``,
    ``,
    ``,
  ],
  caption_count_asp: [`이수 기준 대화 수`, ``, 'n_b2b.caption_count_asp', ``, ``, ``, ``],
  count_require_asp: (count) => [<>{count}회</>, ``, 'n_b2b.count_require_asp', ``, ``, ``, ``],
  desc_remain_asp: [`남은 AI 스피킹 파트너 이용권`, ``, 'n_b2b.desc_remain_asp', ``, ``, ``, ``],
  count_remain_asp: (count) => [<>{count}회</>, ``, 'n_b2b.count_remain_asp', ``, ``, ``, ``],
  tooltip_seperate: [
    `이수 조건 달성을 위해 1:1 수업과 AI 스피킹 파트너에 모두 출석해야 합니다.`,
    ``,
    'n_b2b.tooltip_seperate',
    ``,
    ``,
    ``,
    ``,
  ],
  analysis_tooltip: [
    `이수 조건 달성을 위해 1:1 수업과 AI튜터 내 대화 (롤플레잉/디스커션) 종료 후 AI 분석 횟수를 모두 충족해야 합니다.`,
    `To complete the program, you must fulfill both the minimum requirements for 1:1 lessons and for running AI analysis after talking with the AI tutor (role playing/discussion).`,
    'n_b2b.analysis_tooltip',
    `To complete the program, you must fulfill both the minimum requirements for 1:1 lessons and for running AI analysis after talking with the AI tutor (role playing/discussion).`,
    `To complete the program, you must fulfill both the minimum requirements for 1:1 lessons and for running AI analysis after talking with the AI tutor (role playing/discussion).`,
    `To complete the program, you must fulfill both the minimum requirements for 1:1 lessons and for running AI analysis after talking with the AI tutor (role playing/discussion).`,
    ``,
  ],
  title_ai_tutor: (percent) => [
    `AI 튜터 출석률 ${percent}%`,
    `AI Tutor Progress ${percent}%`,
    'n_b2b.title_ai_tutor',
    `AI Tutor Progress ${percent}%`,
    `AI Tutor Progress ${percent}%`,
    `AI Tutor Progress ${percent}%`,
    ``,
  ],
  analysis_number: [
    `롤플레잉/디스커션 AI 분석 횟수`,
    `AI analysis received`,
    'n_b2b.analysis_number',
    `AI analysis received`,
    `AI analysis received`,
    `AI analysis received`,
    ``,
  ],
  analysis_standard: [
    `이수 기준 분석 횟수`,
    `Minimum requirement`,
    'n_b2b.analysis_standard',
    `Minimum requirement`,
    `Minimum requirement`,
    `Minimum requirement`,
    ``,
  ],
  remaing_analysis: [
    `남은 AI 분석 이용권`,
    `AI analysis tickets left`,
    'n_b2b.remaing_analysis',
    `AI analysis tickets left`,
    `AI analysis tickets left`,
    `AI analysis tickets left`,
    ``,
  ],
  infinite: [`무제한`, `Unlimited`, 'n_b2b.infinite', `Unlimited`, `Unlimited`, `Unlimited`, ``],
  both_tooltip: [
    `1:1 수업 완료 횟수와 AI 튜터의 롤플레잉 /디스커션 후 AI 분석 횟수를 합산하여 출석률을 계산합니다.`,
    `Overall progress is calculated based on the sum of 1:1 lessons completed and AI analysis for AI role playing/discussion.`,
    'n_b2b.both_tooltip',
    `Overall progress is calculated based on the sum of 1:1 lessons completed and AI analysis for AI role playing/discussion.`,
    `Overall progress is calculated based on the sum of 1:1 lessons completed and AI analysis for AI role playing/discussion.`,
    `Overall progress is calculated based on the sum of 1:1 lessons completed and AI analysis for AI role playing/discussion.`,
    ``,
  ],
  analysis_lesson_both_standard: [
    `이수 기준 수업 · 분석 횟수`,
    `Min. requirement fulfulled`,
    'n_b2b.analysis_lesson_both_standard',
    `Min. requirement fulfulled`,
    `Min. requirement fulfulled`,
    `Min. requirement fulfulled`,
    ``,
  ],
  lesson_standard: [
    `수업 완료 횟수`,
    `Lessons completed`,
    'n_b2b.lesson_standard',
    `Lessons completed`,
    `Lessons completed`,
    `Lessons completed`,
    ``,
  ],
  remaining_analysis: [`남은 AI 분석 이용권`, ``, 'n_b2b.remaining_analysis', ``, ``, ``, ``],
  ai_only_tooltip: [
    `AI 튜터의 롤플레잉 /디스커션 후 AI 분석 횟수로 출석률을 계산합니다.`,
    `Your progress is calculated based on the number of AI analysis for AI role playing/discussion.`,
    'n_b2b.ai_only_tooltip',
    `Your progress is calculated based on the number of AI analysis for AI role playing/discussion.`,
    `Your progress is calculated based on the number of AI analysis for AI role playing/discussion.`,
    `Your progress is calculated based on the number of AI analysis for AI role playing/discussion.`,
    ``,
  ],
}
export const n_credit = {
  desc1: [
    `수업 완료 횟수`,
    `Number of lessons completed`,
    'n_credit.desc1',
    `已完成课程数`,
    `已完成課程數`,
    `レッスン完了回数`,
    `Number of lessons completed`,
  ],
  count1: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    'n_credit.count1',
    <>
      已完成{complete_count}堂/{count}堂
    </>,
    <>
      已完成{complete_count}堂/{count}堂
    </>,
    <>
      {complete_count}回完了/{count}回
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
  ],
  desc2: [`수강 기간`, `Expires in`, 'n_credit.desc2', `使用期限`, `使用期限`, `受講期間`, `Expires in`],
  count2: (day) => [
    <>{day}일 남음</>,
    <>{day} days</>,
    'n_credit.count2',
    <>{day} days</>,
    <>{day} days</>,
    <>{day}日残り</>,
    <>{day} days</>,
  ],
  weekly: {
    banner_title: [
      <>수업권 차감 전에 예약하세요!</>,
      <>Book now to meet the deadline!</>,
      'n_credit.weekly.banner_title',
      <>课程券到期前赶快预约!</>,
      <>課程券到期前趕快預約!</>,
      <>バウチャーの有効期限が切れる前に、今すぐご予約ください！ </>,
      <>Đăng ký bài học cho tuần.</>,
    ],
    banner_desc: (date_time) => [
      <>{date_time}까지 수업을 완료하세요!</>,
      <>Complete a lesson by {date_time}!</>,
      'n_credit.weekly.banner_desc',
      <>请在{date_time}前完成课程!</>,
      <>請在{date_time}前完成課程!</>,
      <>レッスンを{date_time}までに受講しましょう!</>,
      <>Hoàn thành bài học trước {date_time}</>,
    ],
    title: [
      `매주 수업 듣기 현황`,
      `Weekly Lesson Status`,
      'n_credit.weekly.title',
      `週上课情况`,
      ``,
      `毎週レッスンを受ける進捗状況`,
      `Tình trạng học hàng tuần`,
    ],
    week: (n) => [
      <>{n} 주차 진행중</>,
      <>Currently on Week {n}</>,
      'n_credit.weekly.week',
      <>第{n}週进行中</>,
      <>第{n}週進行中</>,
      <>第{n}週目</>,
      ``,
    ],
    desc1: [`달성 주차`, `Achieved`, 'n_credit.weekly.desc1', `完成週数`, `完成週數`, `達成済`, ``],
    count1: (n) => [<>{n}</>, <>{n}</>, 'n_credit.weekly.count1', <>{n}</>, <>{n}</>, <>{n}</>, ``],
    desc2: [`실패 주차`, `Failed`, 'n_credit.weekly.desc2', `失败週数`, `失敗週數`, `未達成`, ``],
    count2: (n) => [<>{n}</>, <>{n}</>, 'n_credit.weekly.count2', <>{n}</>, <>{n}</>, <>{n}</>, ``],
    btn: [
      `주차별 현황 보기`,
      `View all weeks`,
      'n_credit.weekly.btn',
      `确认每週进度`,
      `確認每週進度`,
      `全ての週を確認する`,
      ``,
    ],
    popup_header1: [
      `주차/기간`,
      `Week/Period`,
      'n_credit.weekly.popup_header1',
      `週/期间`,
      `週/期間`,
      `週/期間`,
      `tuần/kỳ`,
    ],
    popup_header2: [
      `매주 수업 듣기`,
      `Every Week`,
      'n_credit.weekly.popup_header2',
      `每週上课`,
      `每週上課`,
      `毎週レッスンを受ける`,
      ``,
    ],
    popup_week: (n) => [
      <>{n}주차</>,
      <>Week {n}</>,
      'n_credit.weekly.popup_week',
      <>{n}週</>,
      <>{n}週</>,
      <>{n}週目</>,
      <>Tuần {n}</>,
    ],
    popup_msg: [
      `수업권 복구됨`,
      `Credit restored`,
      'n_credit.weekly.popup_msg',
      `恢復课程券`,
      `恢復課程券`,
      `レッスンチケットが復元されました。`,
      `Đã khôi phục`,
    ],
    caption: [
      `이번 주차에 수업 1회를 완료해야 달성돼요.`,
      `You have yet to complete this week's lesson.`,
      'n_credit.weekly.caption',
      `本週一定要完成一堂课`,
      `本週一定要完成一堂課`,
      `当該期間に1回のレッスンを受ける必要があります。`,
      ``,
    ],
    total_title: [`전체 현황`, `My Record`, 'n_credit.weekly.total_title', `总览`, `總覽`, `全体概要`, ``],
    txt: [
      `매주 수업 듣기 안내`,
      `What is Every Week?`,
      'n_credit.weekly.txt',
      `每週课程说明`,
      `每週課程說明`,
      `毎週受講とは？`,
      ``,
    ],
    popup1: {
      title: [
        `예약 전 확인해주세요.`,
        `Important Reminders`,
        'n_credit.weekly.popup1.title',
        `预约前请确认`,
        `預約前請確認`,
        `予約前にご確認ください。`,
        ``,
      ],
      subhead1: [
        `‘매주 수업 듣기 수업권’으로 수업을 예약하세요.`,
        `Book the lesson with a ‘Every Week' credit.`,
        'n_credit.weekly.popup1.subhead1',
        `课程预约时请确认是否正确使用"每週课程券`,
        `課程預約時請確認是否正確使用"每週課程券`,
        `毎週受講するチケットでレッスンを予約してください。`,
        ``,
      ],
      desc1: [
        `첫 구매 혜택이나 수업 취소 등 무료로 생긴 수업권 (보너스 수업권, 당일 수업권 등)으로 수강한 수업은 포함되지 않아요.`,
        `Keep in mind that lessons taken using free credits (bonus credits, last-minute credits, etc.) do not count toward the Every Week status.`,
        'n_credit.weekly.popup1.desc1',
        `使用活动免费获得的课程券或是当日课程券预约课程，
不计入每週须完成的课程`,
        `使用活動免費獲得的課程券或是當日課程券預約課程，
不計入每週須完成的課程`,
        `初回購入特典や授業の取消など、無料で発生したチケット（ボーナスチケット、当日チケットなど）で受講した授業は含まれません。`,
        ``,
      ],
      subhead2: [
        `일주일은 구매 요일로부터 7일입니다.`,
        `A week starts from credit purchase, lasts 7 days.`,
        'n_credit.weekly.popup1.subhead2',
        `一週为7天，购买课程券的当天为第1天`,
        `一週為7天，購買課程券的當天為第1天`,
        `1週間は購入日から7日間です。`,
        ``,
      ],
      desc2_pc: [
        `[홈] - [매주 수업 듣기 현황] 에서 이번 주차의 마감일을 확인해주세요.`,
        `Check the deadline for this week under [Weekly Lesson Status].`,
        'n_credit.weekly.popup1.desc2_pc',
        `请于[首页]-[每週上课情况]确认该週的上课日期`,
        `請於[首頁]-[每週上課情況]確認該週的上課日期`,
        `[ホーム画面] - [レッスン受講状況]で今週の締め切りをご確認ください。`,
        ``,
      ],
      desc2_mobile: [
        `화면 우측 상단 [햄버거 메뉴] - [매주 수업 듣기 현황] 에서 이번 주차의 마감일을 확인해주세요.`,
        `You can check the deadline by going to [Menu (three bars on the top right)] - [Weekly Lesson Status].`,
        'n_credit.weekly.popup1.desc2_mobile',
        `请利用画面右上方[三]图示中的[每週上课情况]
确认每週课程日期`,
        `請利用畫面右上方[三]圖示中的[每週上課情況]
確認每週課程日期`,
        `画面右上の[ハンバーガーメニュー] - [毎週のレッスン受講状況]で当週の締め切りをご確認ください。`,
        ``,
      ],
      caption1: [
        `매주 수업 진행을 잊지 않도록 한 달 수업을 미리 예약하는 것을 추천해요!`,
        `Try booking lessons for the whole month in advance to make things easier!`,
        'n_credit.weekly.popup1.caption1',
        `建议一次预约一个月内需完成的所有课程，避免忘记
上课。`,
        `建議一次預約一個月內需完成的所有課程，避免忘記
上課。`,
        `毎週のレッスンの進行を忘れないように、1ヶ月前までに予約しておくことをおすすめします!`,
        ``,
      ],
      subhead3: [
        `수업을 완료한 경우 이번 주차 달성!`,
        `Complete a lesson to achieve this week's Every Week status!`,
        'n_credit.weekly.popup1.subhead3',
        `请记得每週必须完成一堂课才能达成该週的上课目标`,
        `請記得每週必須完成一堂課才能達成該週的上課目標`,
        `その週の出席目標を達成するためには、1週間に1レッスンの受講が必要です。`,
        ``,
      ],
      desc3: [
        <>
          <li className="li-tag-inside">매주 1회 이상 수업을 하지 않는 경우 수업권 1회가 자동 차감돼요.</li>
          <li className="li-tag-inside">
            수업 시작 24시간 이내 부득이하게 튜터의 수업 취소/ 노쇼/ 미배정이 발생할 경우에는, 해당 주차에 수업을
            수강하지 않아도 수업권이 자동 차감이 되지 않아요.
          </li>
          <li className="li-tag-inside">
            다만, 수업 시작 24시간 이전에 튜터의 수업 취소가 발생하고, 수업을 진행하지 않은 경우에는 자동 차감돼요.
          </li>
           
        </>,
        <>
          <li className="li-tag-inside">
            One credit is automatically deducted each week even if no lessons are taken.
          </li>
          <li className="li-tag-inside">
            There will be no auto-deduction of credit if the lesson is cancelled within 24 hours of its scheduled start
            time due to tutor cancellation, tutor no-show, or failure to assign tutors.
          </li>
          <li className="li-tag-inside">
            However, if the tutor cancels the lesson more than 24 hours before the scheduled start time and you fail to
            complete a lesson during the week, one credit will be auto-deducted.
          </li>
        </>,
        'n_credit.weekly.popup1.desc3',
        <>
          <li className="li-tag-inside">一週没有完成一堂以上课程时，扣除1张课程券</li>
          <li className="li-tag-inside">
            课程开始前24以内，教师取消课程/未出席或是无成功配对教师，导致课程无法进行时，不扣除课程券。
          </li>
          <li className="li-tag-inside">
            然而，当课程开始前24小时，教师取消课程且无重新预约课程导致课程未完成时，将扣除课程券。.
          </li>
        </>,
        <>
          <li className="li-tag-inside">一週沒有完成一堂以上課程時，扣除1張課程券</li>
          <li className="li-tag-inside">
            課程開始前24以內，教師取消課程/未出席或是無成功配對教師，導致課程無法進行時，不扣除課程券。
          </li>
          <li className="li-tag-inside">
            然而，當課程開始前24小時，教師取消課程且無重新預約課程導致課程未完成時，將扣除課程券。.
          </li>
        </>,
        <>
          <li className="li-tag-inside">
            購入時点から週1回以上レッスンを受講しない場合、レッスンチケット1枚が自動的に差し引かれます。
          </li>
          <li className="li-tag-inside">
            授業開始から24時間以内に不可抗力によりチューターのレッスンがキャンセル/欠席/未配属となった場合、その週に授業を受けなかったとしても、レッスンチケットは自動的に引き落とされません。
          </li>
          <li className="li-tag-inside">
            ただし、授業開始の24時間前にチューターのレッスン取消が発生し、レッスンを行わなかった場合は自動引き落としされます。
          </li>
        </>,
        ``,
      ],
      caption2: [
        `수업 예약에서 튜터 매칭 옵션으로 “다른 튜터 매칭 받기"를 선택해 최대한 수업이 진행될 수 있도록 해주세요.`,
        `Choose "Match me with another tutor" for the Tutor Matching Option to minimize lesson cancellations.`,
        'n_credit.weekly.popup1.caption2',
        `在预约课程时，请选择接受其他教师配对，确保课程
能在期限内顺利进行。`,
        `在預約課程時，請選擇接受其他教師配對，確保課程
能在期限內順利進行。`,
        `レッスンの予約でチューターマッチングオプションの「他のチューターをマッチングしてもらう」を選択し、可能な限りレッスンが進行できるようにしてください。`,
        ``,
      ],
    },
    popup2: {
      title: [
        `매주 수업 듣기 안내`,
        `Every Week Credits`,
        'n_credit.weekly.popup2.title',
        `每週课程说明`,
        `每週課程說明`,
        `週間コース説明`,
        ``,
      ],
      subhead1: [
        `수업권 사용`,
        `How the credits work`,
        'n_credit.weekly.popup2.subhead1',
        `使用课程券`,
        `使用課程券`,
        `コースバウチャーの利用`,
        ``,
      ],
      desc1: [
        <>
          <li className="li-tag-inside">
            구매 요일로부터 7일을 주차로 계산하며, 매주 1회 이상 수업을 수강하지 않을 경우 수업권 1회가 자동으로
            차감됩니다.
          </li>
          <li className="li-tag-inside">매주 주 1회만 필수이며, 주 2회 이상 수강도 가능합니다.</li>
          <li className="li-tag-inside">
            첫 구매 혜택이나 수업 취소 등 무료로 생긴 수업권 (보너스 수업권, 당일 수업권 등)으로 수강한 수업은 포함되지
            않습니다.
          </li>
           
        </>,
        <>
          <li className="li-tag-inside">
            {' '}
            One week is counted as 7 days, starting from the credit purchase date, and one credit is automatically
            deducted each week even if no lessons are taken.
          </li>
          <li className="li-tag-inside">
            Once a week is mandatory, but you can take more than once a week if you wish.
          </li>
          <li className="li-tag-inside">
            Lessons taken using free credits (bonus credits, last-minute credits, etc.) do not count toward the Every
            Week status.
          </li>
        </>,
        'n_credit.weekly.popup2.desc1',
        <>
          <li className="li-tag-inside">
            从购买后开始计算(7天为一个週期)，每週若无完成一堂以上的课程，课程券将被扣除。
          </li>
          <li className="li-tag-inside">一週一定要完成一堂课，也可以上超过两堂课。</li>
          <li className="li-tag-inside">首购或是因为课程取消获得的免费课程券不在此限。</li>
        </>,
        <>
          <li className="li-tag-inside">
            從購買後開始計算(7天為一個週期)，每週若無完成一堂以上的課程，課程券將被扣除。
          </li>
          <li className="li-tag-inside">一週一定要完成一堂課，也可以上超過兩堂課。</li>
          <li className="li-tag-inside">首購或是因為課程取消獲得的免費課程券不在此限。</li>
        </>,
        <>
          <li className="li-tag-inside">
            購入日から7日間を起算し、毎週1回以上授業を受けない場合は、1回分の授業券が自動で差し引かれます。
          </li>
          <li className="li-tag-inside">週1回のみ必須で、週2回以上の受講も可能です。</li>
          <li className="li-tag-inside">
            回購入特典や授業のキャンセルなど、無料で発生した授業券（ボーナス授業券、当日授業券など）で受講した授業は含まれません。
          </li>
        </>,
        ``,
      ],
      subhead2: [
        `수업권 차감 및 복구`,
        `Credit deduction & restoration`,
        'n_credit.weekly.popup2.subhead2',
        `课程券到期及恢復`,
        `課程券到期及恢復`,
        `授業料の引き落としと回収`,
        ``,
      ],
      desc2: [
        <>
          <li className="li-tag-inside">매주 1회 이상 수업을 하지 않는 경우 수업권 1회가 자동 차감됩니다.</li>
          <li className="li-tag-inside">
            수업 시작 24시간 이내 부득이하게 튜터의 수업 취소/ 노쇼/ 미배정이 발생할 경우에는 해당 주차에 수업을
            수강하지 않아도 수업권이 자동 차감이 되지 않습니다. (해당 주차 종료 이후, 주차별 현황이 수업권 복구로 자동
            변경됩니다.)
          </li>
          <li className="li-tag-inside">
            다만, 수업 시작 24시간 이전에 튜터의 수업 취소가 발생하고, 수업을 진행하지 않은 경우에는 자동 차감됩니다.
          </li>
           
        </>,
        <>
          <li className="li-tag-inside">
            One credit is automatically deducted each week even if no lessons are taken.
          </li>
          <li className="li-tag-inside">
            There is no auto-deduction of credit if the lesson is cancelled within 24 hours of its scheduled start time
            due to tutor cancellation, tutor no-show, or failure to assign tutors. (The credit will be automatically
            restored and reflected in the weekly status after the end of the given week.)
          </li>
          <li className="li-tag-inside">
            However, if the tutor cancels the lesson more than 24 hours before the scheduled start time and you fail to
            complete a lesson during the week, one credit will be auto-deducted.
          </li>
        </>,
        'n_credit.weekly.popup2.desc2',
        <>
          <li className="li-tag-inside">每週若无完成一堂以上的课程，课程券将被扣除。</li>
          <li className="li-tag-inside">
            如于课程开始24小时前有教师取消课程、教师未出席或是没有配对教师的情形，该週课程券将不被扣除。(该週结束后，可于确认每週课程进度中确认课程券是否被恢復）
          </li>
          <li className="li-tag-inside">
            但是，如果于课程开始前24小时有教师取消课程之情形，且未重新预约上课时，该课程券将被扣除。
          </li>
        </>,
        <>
          <li className="li-tag-inside">每週若無完成一堂以上的課程，課程券將被扣除。</li>
          <li className="li-tag-inside">
            如於課程開始24小時前有教師取消課程、教師未出席或是沒有配對教師的情形，該週課程券將不被扣除。(該週結束後，可於確認每週課程進度中確認課程券是否被恢復）
          </li>
          <li className="li-tag-inside">
            但是，如果於課程開始前24小時有教師取消課程之情形，且未重新預約上課時，該課程券將被扣除。
          </li>
        </>,
        <>
          <li className="li-tag-inside">毎週1回以上授業を行わない場合、1回分の授業券が自動的に差し引かれます。</li>
          <li className="li-tag-inside">
            授業開始から24時間以内にやむを得ず教師の授業のキャンセル/欠席/未配置が発生した場合、その週に授業を受講しなくても、授業権は自動引き落とされません。
            (その週の終了後、週ごとの状況が授業権の回復に自動変更されます).）
          </li>
          <li className="li-tag-inside">
            ただし、授業開始の24時間前に教師からの授業のキャンセルが発生し、授業が行われなかった場合は自動引き落としされます。
          </li>
        </>,
        ``,
      ],
      subhead3: [
        `수강기간`,
        `Extensions & exchanges`,
        'n_credit.weekly.popup2.subhead3',
        `使用期限`,
        `使用期限`,
        `受講期間`,
        ``,
      ],
      desc3: [
        <>
          <li className="li-tag-inside">
            매주 수업 듣기가 어려운 경우 수업권 구매 시 제공되었던 수업 연기 혜택이 자동 적용됩니다. 수업을 듣지 못한
            주차가 지나면 수업권이 차감되지 않고, 수강기간이 7일 연장됩니다.
          </li>
          <li className="li-tag-inside">수강기간 연장 기능 및 20분/40분 수업 길이 변경 기능은 지원하지 않습니다.</li> 
        </>,
        <>
          <li className="li-tag-inside">
            If you have difficulty taking lessons every week, the deferral benefit will be automatically applied. When a
            week passes without attending a lesson, no credit will be deducted, and the expiration date will be extended
            by 7 days.
          </li>
          <li className="li-tag-inside">
            Validity period extensions or exchanges between 20-min and 40-min lessons are not supported for Every Week
            credits.
          </li>
        </>,
        'n_credit.weekly.popup2.desc3',
        <>
          <li className="li-tag-inside">
            如果每周上课有困难，购买课程时提供的课程延期福利将自动适用。未上课的那一周过后，不会扣除课时，学习期限将延长7天。
          </li>
          <li className="li-tag-inside">每週课程券不适用于延长整体使用期限以及更换课程券种类。</li>
        </>,
        <>
          <li className="li-tag-inside">
            如果每週上課有困難，購買課程時提供的課程延期福利將自動適用。未上課的那一週過後，不會扣除課時，學習期限將延長7天。
          </li>
          <li className="li-tag-inside">每週課程券不適用於延長整體使用期限以及更換課程券種類。</li>
        </>,
        <>
          <li className="li-tag-inside">
            毎週授業を受けるのが難しい場合、授業権購入時に提供された授業延期特典が自動的に適用されます。授業を受けられなかった週が過ぎても、授業権は差し引かれず、受講期間が7日間延長されます。
          </li>
          <li className="li-tag-inside">受講期間延長機能および20分/40分授業の長さ変更機能は対応しておりません。</li>
        </>,
        ``,
      ],
    },
    banner_title1: [
      `수업권 차감 예정`,
      `You'll be losing a credit`,
      'n_credit.weekly.banner_title1',
      `课程券即将被扣除`,
      `課程券即將被扣除`,
      `クレジットが差し引かれます`,
      ``,
    ],
    banner_title2: [
      `수업 예정`,
      `Lesson booked`,
      'n_credit.weekly.banner_title2',
      `預約的课程`,
      `預約的課程`,
      `予約したレッスン`,
      ``,
    ],
    banner_title3: [
      `주 1회 수업 달성`,
      `Every Week fulfilled`,
      'n_credit.weekly.banner_title3',
      `课程已完成`,
      `課程已完成`,
      `受講したレッスン`,
      ``,
    ],
    credit_btn: [
      `다른 수업권`,
      `View other credits`,
      'n_credit.weekly.credit_btn',
      `确认其他课程券`,
      `確認其他課程券`,
      `他のクレジットを見る`,
      ``,
    ],
    banner_desc3: [
      `이번 주차 매주 수업 듣기 달성!`,
      `Completed a lesson this week!`,
      'n_credit.weekly.banner_desc3',
      `Completed a lesson this week!`,
      `Completed a lesson this week!`,
      `Completed a lesson this week!`,
      ``,
    ],
    banner_title4: [
      `주 1회 수업 달성 (튜터 취소/노쇼)`,
      `Every Week fulfilled`,
      'n_credit.weekly.banner_title4',
      `Every Week fulfilled`,
      `Every Week fulfilled`,
      `Every Week fulfilled`,
      ``,
    ],
    banner_desc4: [
      `이번 주에 수업을 듣지 못해도 추가로 차감되지 않습니다.`,
      `There will be no extra deduction for missed lesson this week.`,
      'n_credit.weekly.banner_desc4',
      `There will be no extra deduction for missed lesson this week.`,
      `There will be no extra deduction for missed lesson this week.`,
      `There will be no extra deduction for missed lesson this week.`,
      ``,
    ],
    banner_title5: [
      `수업권 사용됨 (학생 취소/노쇼)`,
      `Credit used`,
      'n_credit.weekly.banner_title5',
      `Credit used`,
      `Credit used`,
      `Credit used`,
      ``,
    ],
    banner_desc5: [
      `이번 주에 수업을 듣지 못해도 추가로 차감되지 않습니다.`,
      `There will be no extra deduction for missed lesson this week.`,
      'n_credit.weekly.banner_desc5',
      `There will be no extra deduction for missed lesson this week.`,
      `There will be no extra deduction for missed lesson this week.`,
      `There will be no extra deduction for missed lesson this week.`,
      ``,
    ],
  },
  count: (count) => [
    <>{count}회</>,
    <>
      {count} {count > 1 ? 'Credits' : 'Credit'}
    </>,
    'n_credit.count',
    <>{count}次</>,
    <>{count}次</>,
    <>{count}回</>,
    <>
      {count} {count > 1 ? 'Lessons' : 'Lesson'}
    </>,
  ],
  credit_select: {
    title: [
      `매주 수업 듣기 수업권`,
      `Every Week Credits`,
      'n_credit.credit_select.title',
      `每週课程券`,
      `每週課程券`,
      `毎週受講のクレジット`,
      ``,
    ],
  },
  banner: {
    title: [
      `보유한 스피킹 파트너 이용권이 AI 튜터 멤버십으로 변경되었습니다.`,
      `Your AI Speaking Partner tickets have been transferred toward AI Tutor membership.`,
      'n_credit.banner.title',
      `Your AI Speaking Partner tickets have been transferred toward AI Tutor membership.`,
      `Your AI Speaking Partner tickets have been transferred toward AI Tutor membership.`,
      `Your AI Speaking Partner tickets have been transferred toward AI Tutor membership.`,
      ``,
    ],
    btn: [`자세히 보기`, `View details`, 'n_credit.banner.btn', `View details`, `View details`, `View details`, ``],
  },
  info_change: {
    title: [
      `멤버십 변경 안내`,
      `About AI Tutor Membership`,
      'n_credit.info_change.title',
      `About AI Tutor Membership`,
      `About AI Tutor Membership`,
      `About AI Tutor Membership`,
      ``,
    ],
    content: (amount) => [
      <>
        2024년 10월 17일부터 AI 스피킹 파트너가 AI 튜터로 개편됩니다. 이에 따라 보유한 AI 스피킹 파트너 이용권은 AI 튜터
        멤버십으로 전환됩니다.
        <br />
        전환 비율은 다음과 같습니다.
        <br />
        <li className="li-tag-inside">유료 이용권 {amount}회당 멤버십 1개월</li>
        <li className="li-tag-inside">무료 이용권 {amount}회당 멤버십 1개월</li>
        <br />
        고객님의 이전 보유 내역
        <br />
        <li className="li-tag-inside">유료 이용권: {amount}회</li>
        <li className="li-tag-inside">무료 이용권: {amount}회</li>
        <br />
        고객님의 변경 후 멤버십
        <br />
        <li className="li-tag-inside">AI 튜터 멤버십: {amount}개월</li>
        <br />
        문의 사항은 고객센터 {'>'} 채널톡으로 연락해 주세요.
      </>,
      <>
        On October 17, 2024, AI Speaking Partner (ASP) has been updated as AI Tutor. Accordingly, your AI Speaking
        Partner tickets have been transferred toward to AI Tutor membership as follows.
        <br />
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} paid ASP tickets</li>
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} free ASP tickets</li>
        <br />
        Your existing ASP ticket details
        <br />
        <li className="li-tag-inside">Paid tickets: {amount}</li>
        <li className="li-tag-inside">Free tickets: {amount}</li>
        <br />
        Your AI Tutor membership after transfer
        <br />
        <li className="li-tag-inside">AI Tutor membership: {amount} months</li>
        <br />
        If you have any inquiries, please contact us via Customer Center {'>'} Talk channel.
      </>,
      'n_credit.info_change.content',
      <>
        On October 17, 2024, AI Speaking Partner (ASP) has been updated as AI Tutor. Accordingly, your AI Speaking
        Partner tickets have been transferred toward to AI Tutor membership as follows.
        <br />
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} paid ASP tickets</li>
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} free ASP tickets</li>
        <br />
        Your existing ASP ticket details
        <br />
        <li className="li-tag-inside">Paid tickets: {amount}</li>
        <li className="li-tag-inside">Free tickets: {amount}</li>
        <br />
        Your AI Tutor membership after transfer
        <br />
        <li className="li-tag-inside">AI Tutor membership: {amount} months</li>
        <br />
        If you have any inquiries, please contact us via Customer Center {'>'} Talk channel.
      </>,
      <>
        On October 17, 2024, AI Speaking Partner (ASP) has been updated as AI Tutor. Accordingly, your AI Speaking
        Partner tickets have been transferred toward to AI Tutor membership as follows.
        <br />
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} paid ASP tickets</li>
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} free ASP tickets</li>
        <br />
        Your existing ASP ticket details
        <br />
        <li className="li-tag-inside">Paid tickets: {amount}</li>
        <li className="li-tag-inside">Free tickets: {amount}</li>
        <br />
        Your AI Tutor membership after transfer
        <br />
        <li className="li-tag-inside">AI Tutor membership: {amount} months</li>
        <br />
        If you have any inquiries, please contact us via Customer Center {'>'} Talk channel.
      </>,
      <>
        On October 17, 2024, AI Speaking Partner (ASP) has been updated as AI Tutor. Accordingly, your AI Speaking
        Partner tickets have been transferred toward to AI Tutor membership as follows.
        <br />
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} paid ASP tickets</li>
        <li className="li-tag-inside">1 month of AI Tutor membership per {amount} free ASP tickets</li>
        <br />
        Your existing ASP ticket details
        <br />
        <li className="li-tag-inside">Paid tickets: {amount}</li>
        <li className="li-tag-inside">Free tickets: {amount}</li>
        <br />
        Your AI Tutor membership after transfer
        <br />
        <li className="li-tag-inside">AI Tutor membership: {amount} months</li>
        <br />
        If you have any inquiries, please contact us via Customer Center {'>'} Talk channel.
      </>,
      ``,
    ],
  },
}
export const n_schedule_enter = {
  available: [
    `남은 수업권`,
    `Remaining`,
    'n_schedule_enter.available',
    `剩馀课程券`,
    `剩餘課程券`,
    `残りのレッスンチケット`,
    `Thẻ học còn lại`,
  ],
  expired: [
    `남은 수강기간`,
    `Remaining lesson days`,
    'n_schedule_enter.expired',
    `剩馀的使用期限`,
    `剩餘的使用期限`,
    `残りの受講期間`,
    `Hạn phiếu học còn lại`,
  ],
  expired_count: (days) => [
    <>{days}일 남음</>,
    <>{days} days</>,
    'n_schedule_enter.expired_count',
    <>剩余{days}日</>,
    <>剩下{days}日</>,
    <>残り{days}日</>,
    <>trong {days} ngày</>,
  ],
  size_benefit: [
    `받은 당일수업권`,
    `Size-benefits`,
    'n_schedule_enter.size_benefit',
    `获得的当日课程券`,
    `獲得的當日課程券`,
    `受け取った当日レッスンチケット`,
    `Size-benefits`,
  ],
  size_benefit_count: (count, complete_count) => [
    <>
      {count}회/{count}회
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    'n_schedule_enter.size_benefit_count',
    <>
      已完成{complete_count}堂/{count}堂
    </>,
    <>
      已完成{complete_count}堂/{count}堂
    </>,
    <>
      {complete_count}回/{count}回
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
  ],
}
export const n_classroom = {
  tab_google_translator: [
    `구글 번역`,
    `Translate`,
    'n_classroom.tab_google_translator',
    `Google翻译`,
    `Google翻譯`,
    `Google翻訳`,
    ``,
  ],
  tab_zoom_help: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    'n_classroom.tab_zoom_help',
    `解决Zoom问题`,
    `解決Zoom問題`,
    `Zoomのエラー解決`,
    ``,
  ],
  tab_help_chat: [`지원 요청`, `Help`, 'n_classroom.tab_help_chat', `寻求帮助`, `尋求幫助`, `ヘルプ`, ``],
  tab_insight: [`인사이트`, `Insight`, 'n_classroom.tab_insight', `教材文章`, `教材文章`, `インサイト`, ``],
  tab_question: [`수업 질문`, `Questions`, 'n_classroom.tab_question', `课程问题`, `課程問題`, `レッスンの質問`, ``],
  tab_video: [`영상/기사`, `Video Clip/Article`, 'n_classroom.tab_video', `影片/报导`, `影片/報導`, `動画/記事`, ``],
  tab_note: [`수업 노트`, `Lesson Notes`, 'n_classroom.tab_note', `课程笔记`, `課程筆記`, `レッスンノート`, ``],
  tab_note_newtab: [
    `노트 새탭으로 열기`,
    `Open in a new tab`,
    'n_classroom.tab_note_newtab',
    `在新的视窗打开笔记`,
    `在新的視窗打開筆記`,
    `新規タブで開く`,
    ``,
  ],
  google_translator_title: [
    `구글 번역`,
    `Google Translate`,
    'n_classroom.google_translator_title',
    `Google翻译`,
    `Google翻譯`,
    `Google翻訳`,
    ``,
  ],
  btn_translate_kr: [`한국어`, `Korean`, 'n_classroom.btn_translate_kr', `韩文`, `韓文`, `韓国語`, ``],
  btn_translate_en: [`영어`, `English`, 'n_classroom.btn_translate_en', `英文`, `英文`, `英語`, ``],
  translate_result1: [`한국어`, `Korean`, 'n_classroom.translate_result1', `韩文`, `韓文`, `韓国語`, ``],
  translate_result2: [
    `번역({번역어})`,
    `Translate(to {번역어})`,
    'n_classroom.translate_result2',
    `翻译成(to {语言})`,
    `翻譯成(to {語言})`,
    `翻訳({言語})`,
    ``,
  ],
  btn_translate: [`번역하기`, `Translate`, 'n_classroom.btn_translate', `翻译`, `翻譯`, `翻訳する`, ``],
  popup_zoom_help_title: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    'n_classroom.popup_zoom_help_title',
    `解决Zoom问题`,
    `解決Zoom問題`,
    `Zoomのエラー解決`,
    ``,
  ],
  popup_zoom_help_subtitle1: [
    `Zoom이 실행되지 않을 때`,
    `Zoom won't launch`,
    'n_classroom.popup_zoom_help_subtitle1',
    `Zoom无法启动`,
    `Zoom無法啟動`,
    `Zoomが起動しない場合`,
    ``,
  ],
  popup_zoom_help_desc1: [
    <>
      아래의 <span className="font-medium">[Zoom 다시 열기]를 클릭</span>하거나 링글 모바일 앱을 통해 접속해 주세요.
    </>,
    <>
      Click <span className="font-medium">[Relaunch Zoom]</span> below or access Zoom via the Ringle mobile app.
    </>,
    'n_classroom.popup_zoom_help_desc1',
    <>
      请点击下方的<span className="font-medium">[重新打开 Zoom]</span>，或通过 Ringle APP登录。
    </>,
    <>
      請點擊下方的<span className="font-medium">[重新打開 Zoom]</span>，或透過 Ringle APP登入。
    </>,
    <>
      下記の<span className="font-medium">[Zoomを再開]をクリック</span>
      するか、Ringleモバイルアプリから接続してください。
    </>,
    ``,
  ],
  popup_zoom_help_btn1: (meeting_number) => [
    <>회의 ID {meeting_number} 복사</>,
    <>Copy meeting ID {meeting_number}</>,
    'n_classroom.popup_zoom_help_btn1',
    <>複製会议ID {meeting_number}</>,
    <>複製會議ID {meeting_number}</>,
    <>ミーティングID {meeting_number} をコピー</>,
    ``,
  ],
  popup_zoom_help_btn2: [
    `Zoom 다시 열기`,
    `Relaunch Zoom`,
    'n_classroom.popup_zoom_help_btn2',
    `重新打开Zoom`,
    `重新打開Zoom`,
    `Zoomを再度開く`,
    ``,
  ],
  popup_zoom_help_subtitle2: [
    `소리가 들리지 않을 때`,
    `No sound is coming out`,
    'n_classroom.popup_zoom_help_subtitle2',
    `听不到声音`,
    `聽不到聲音`,
    `音が聞こえないとき`,
    ``,
  ],
  popup_zoom_help_desc2: [
    <>
      Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
      <br /> 
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>{' '}
      에서 카메라와 오디오를 테스트 할 수 있습니다.
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    'n_classroom.popup_zoom_help_desc2',
    <>
      在运行 Zoom 后，请按照以下步骤连接音频。
      <br />
      您可以在
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>
      上测试摄像头和音频。
    </>,
    <>
      在打開 Zoom 後，請按照以下步驟連接音訊。
      <br />
      您可以在
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>
      上測試鏡頭和音訊。
    </>,
    <>
      Zoomに接続した後、以下のようにオーディオを接続してください。
      <br />{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>{' '}
      でカメラとオーディオをテストすることができます。
    </>,
    ``,
  ],
  popup_zoom_help_desc3: (helpLink) => [
    <>만약 문제가 해결되지 않는다면, 페이지 상단의&nbsp;{helpLink}을 통해 문의를 남겨주세요.</>,
    <>If the problem is not resolved, please leave an inquiry using the &nbsp;{helpLink} at the top of the page.</>,
    'n_classroom.popup_zoom_help_desc3',
    `如果问题没有解决，请通过页面顶部的{helpLink}留下您的问题。`,
    `如果問題沒有解決，請通過頁面頂部的{helpLink}留下您的問題。`,
    <>もし問題が解決しない場合は、ページ上部の&nbsp;{helpLink}からお問い合わせください。</>,
    ``,
  ],
  popup_help_chat_title: [
    `지원 요청`,
    `Request for help`,
    'n_classroom.popup_help_chat_title',
    `寻求帮助`,
    `尋求幫助`,
    `お問い合わせ`,
    ``,
  ],
  help_chat_audio_problem: [
    `오디오 연결 오류`,
    `Problem with audio connection`,
    'n_classroom.help_chat_audio_problem',
    `无法连接音频`,
    `無法連接音訊`,
    `オーディオ接続エラー`,
    ``,
  ],
  help_chat_zoom_problem: [
    `Zoom 실행 오류`,
    `Problem with launching Zoom`,
    'n_classroom.help_chat_zoom_problem',
    `Zoom启动问题`,
    `Zoom啟動問題`,
    `Zoom接続エラー`,
    ``,
  ],
  help_chat_video_problem: [
    `Zoom 비디오 오류`,
    `Problem with video on Zoom`,
    'n_classroom.help_chat_video_problem',
    `Zoom视频问题`,
    `Zoom影像問題`,
    `Zoomビデオエラー`,
    ``,
  ],
  help_chat_note_problem: [
    `수업 노트 오류`,
    `Problem with lesson notes`,
    'n_classroom.help_chat_note_problem',
    `课堂笔记有问题`,
    `課堂筆記有問題`,
    `レッスンノートエラー`,
    ``,
  ],
  help_chat_placeholder: [
    `pnp`,
    `Please enter your message.`,
    'n_classroom.help_chat_placeholder',
    `请输入您的问题`,
    `請輸入您的問題`,
    `メッセージを入力してください。`,
    ``,
  ],
  help_chat_send: [`제출하기`, `Send`, 'n_classroom.help_chat_send', `提交问题`, `提出問題`, ``, ``],
}
export const n_referral = {
  tap_1: [`초대하기`, `Invite`, 'n_referral.tap_1', `邀请`, `邀請`, `紹介する`, ``],
  tap_2: [`나의 초대 현황`, `My Rewards`, 'n_referral.tap_2', `我的邀请回馈`, `我的邀請回饋`, `紹介状況`, ``],
  invite: {
    section_1: {
      caption: (referralCode) => [
        <>나의 초대 코드 : {referralCode}</>,
        <>My referral code : {referralCode}</>,
        'n_referral.invite.section_1.caption',
        <>我的邀请码 : {referralCode}</>,
        <>我的邀請碼 : {referralCode}</>,
        <>紹介コード : {referralCode}</>,
        ``,
      ],
      btn_1: [
        `초대 링크 복사하기`,
        `Copy link`,
        'n_referral.invite.section_1.btn_1',
        `複製邀请码`,
        `複製邀請碼`,
        `紹介リンクをコピーする`,
        ``,
      ],
      btn_2: [
        `카카오로 초대하기`,
        `Share invite via KakaoTalk`,
        'n_referral.invite.section_1.btn_2',
        `在Kakaotalk分享邀请码`,
        `在Kakaotalk分享邀請碼`,
        `Kakao Talkで紹介する`,
        ``,
      ],
    },
    section_2: {
      title: [
        `친구에게 첫 수업 선물하는 방법`,
        `Gift a free Ringle lesson to your friends`,
        'n_referral.invite.section_2.title',
        `送朋友一堂免费课`,
        `送朋友一堂免費課`,
        `友達に初回レッスンをプレゼントする方法`,
        ``,
      ],
      desc_1: [
        `친구에게 초대장을 공유해요.`,
        `Share the invite to Ringle to your friends.`,
        'n_referral.invite.section_2.desc_1',
        `分享Ringle邀请函给朋友`,
        `分享Ringle邀請函給朋友`,
        `お友達に紹介リンクを送信`,
        ``,
      ],
      desc_2: [
        `초대 받은 친구가 링크로 가입해요.`,
        `If your friends join Ringle via the invite link`,
        'n_referral.invite.section_2.desc_2',
        `朋友使用您的邀请连结加入Ringle`,
        `朋友使用您的邀請連結加入Ringle`,
        `お友達がRingleの紹介リンクからSign inを実施`,
        ``,
      ],
      desc_3: [
        `친구가 첫 수업을 무료로 체험해요.`,
        `Your friends will receive a free trial lesson.`,
        'n_referral.invite.section_2.desc_3',
        `您的朋友可以获得免费体验`,
        `您的朋友可以獲得免費體驗`,
        `お友達が無料体験レッスンチケットを受領`,
        ``,
      ],
    },
    section_3: {
      title: [
        <>
          지금, 친구에게 선물하면{isMobile && <br />}
          <span className="font-bold text-white"> 추가 할인 혜택</span>🎉
        </>,
        `Give more to your friend by gifting now 🎉`,
        'n_referral.invite.section_3.title',
        <>
          现在送礼物给朋友的话{isMobile && <br />}
          <span className="font-bold text-white"> 还可以获得特别优惠</span>🎉
        </>,
        <>
          現在送禮物給朋友的話{isMobile && <br />}
          <span className="font-bold text-white"> 還可以獲得特別優惠</span>🎉
        </>,
        <>
          今すぐ、友達にプレゼントすると{isMobile && <br />}
          <span className="font-bold text-white">追加割引特典</span>🎉
        </>,
        ``,
      ],
      desc_1: [
        `초대로 가입한 친구에게 5만 링글 포인트를 바로 드려요!`,
        `Friends who join via the invite link will receive $38 points right away!`,
        'n_referral.invite.section_3.desc_1',
        `使用邀请连结加入Ringle的朋友可获得价值美金$38的点数！`,
        `使用邀請連結加入Ringle的朋友可獲得價值美金$38的點數！`,
        `紹介で入会したお友達に、50,000Ringleポイントをプレゼントします！`,
        ``,
      ],
      desc_2: [
        `가입 시 30일간 사용가능한 5만 포인트 지급`,
        `$38 points valid for 30 days rewarded upon joining Ringle`,
        'n_referral.invite.section_3.desc_2',
        `价值美金$38的点数使用期限为30天！`,
        `價值美金$38的點數使用期限為30天！`,
        `入会時に30日間使用可能な50,000Ringleポイントを支給`,
        ``,
      ],
    },
    section_4: {
      title: [
        `친구에게 선물하면, 나도 혜택을 받아요`,
        `Get rewarded for inviting your friends`,
        'n_referral.invite.section_4.title',
        `邀请朋友获得优惠`,
        `邀請朋友獲得優惠`,
        `お友達を紹介すると、あなたもお得`,
        ``,
      ],
      desc_1: [
        `초대받은 친구가 수업권을 30만원 이상 결제해요.`,
        `If your invited friend purchases credits worth $231 or above`,
        'n_referral.invite.section_4.desc_1',
        `如果被邀请的朋友购买超过美金$231以上的课程券`,
        `如果被邀請的朋友購買超過美金$231以上的課程券`,
        `紹介された友達が3万円以上のレッスン料を支払った場合`,
        ``,
      ],
      desc_2: [
        `내 초대 코드로 가입한 친구가 첫 수업을 완료하면 5만 링글 포인트를 받아요.`,
        `You get $38 points when they complete their first lesson on Ringle.`,
        'n_referral.invite.section_4.desc_2',
        `您将会在他们完成第一堂课后，获得价值美金$38的点数`,
        `您將會在他們完成第一堂課後，獲得價值美金$38的點數`,
        `あなたが送った紹介コードから入会したお友達が初回レッスンを完了すると、50,000Ringleポイントを獲得`,
        ``,
      ],
      desc_3: [
        `내가 받은 포인트를 선물로 교환할 수 있어요.`,
        `You can redeem the points for gifts.`,
        'n_referral.invite.section_4.desc_3',
        `您可以使用点数兑换礼物`,
        `您可以使用點數兌換禮物`,
        `受け取ったポイントをプレゼントと交換が可能`,
        ``,
      ],
      label: [
        `신세계, 스타벅스 상품권 또는 링글 수업권으로 교환 가능`,
        `Can be exchanged for Shinsegae/Starbucks gift card or Ringle credits.`,
        'n_referral.invite.section_4.label',
        `可以兑换新世界百货/星巴克礼券`,
        `可以兌換新世界百貨/星巴克禮券`,
        `新世界百貨店の商品券、スターバックスのギフトカード、Ringleレッスンチケットを交換可能。`,
        ``,
      ],
    },
    section_5: {
      title: [
        `기간 한정 초대 이벤트`,
        `Referral Event`,
        'n_referral.invite.section_5.title',
        `邀请活动`,
        `邀請活動`,
        `期間限定友達紹介イベント`,
        ``,
      ],
      desc_1: [
        `기간 내 초대 코드로 가입 및 수업권 구매까지 한 친구가  가장 많은 고객 3분께 20분 수업권 12회를 증정합니다. (43만원 상당)`,
        `Top three referrers inviting the most friends using a referral code, who also purchase credits within the period, win twelve 20min credits (valued at $332).`,
        'n_referral.invite.section_5.desc_1',
        `提供活动期间邀请最多朋友并购买课程券的3名用户将获得价值332美元的12次20分钟课程券。`,
        `提供活動期間邀請最多朋友並購買課程券的3名用戶將獲得價值332美元的12次20分鐘課程券。`,
        `期間中、紹介コードから登録し、レッスンチケットの購入まで行ったお友達が一番多いお客様3名様に、20分レッスンチケット12回分をプレゼントします。 (＄332相当)`,
        ``,
      ],
      desc_2: [
        `기간 : 12.7 (목) - 12.21(목)
추첨 및 혜택 증정 : 12. 28 (목)
자세한 내용은 공지사항을 확인해주세요.`,
        `Period: Dec 7 (Thu) - Dec 21 (Thu)
Benefit awarding: Dec 28 (Thu)
Check announcements for more details`,
        'n_referral.invite.section_5.desc_2',
        `时间 : 12.7 (四) - 12.21(四)
抽奖及发奖 : 12. 28 (四)
详情请见公告事项`,
        `時間 : 12.7 (四) - 12.21(四)
抽獎及發獎 : 12. 28 (四)
詳情請見公告事項`,
        `期間 : 12.7(木) - 12.21(木)
 抽選会及び特典プレゼント : 12. 28 (木)
 詳しくはお知らせをご確認ください。`,
        ``,
      ],
    },
  },
  status: {
    caption_1: [
      `회원가입 완료`,
      `Friends who joined Ringle`,
      'n_referral.status.caption_1',
      `成功加入会员的朋友`,
      `成功加入會員的朋友`,
      `会員登録完了`,
      ``,
    ],
    caption_2: [
      `첫 수업 완료`,
      `Friends who completed their first lesson`,
      'n_referral.status.caption_2',
      `成功完成第一堂课的朋友`,
      `成功完成第一堂課的朋友`,
      `初回レッスン完了`,
      ``,
    ],
    count: (count) => [
      `${count}명`,
      `${count}`,
      'n_referral.status.count',
      `${count}名`,
      `${count}名`,
      `${count}人`,
      ``,
    ],
    column_1: [`이름`, `Name`, 'n_referral.status.column_1', `姓名`, `姓名`, `氏名`, ``],
    column_2: [`진행 현황`, `Activity`, 'n_referral.status.column_2', `进行状态`, `進行狀態`, `進捗状況`, ``],
    column_3: [
      `친구초대 포인트`,
      `Referral points`,
      'n_referral.status.column_3',
      `邀请朋友点数`,
      `邀請朋友點數`,
      `友達紹介ポイント`,
      ``,
    ],
    placeholder: [
      `아직 초대한 친구가 없어요.`,
      `You haven't invited any friends yet.`,
      'n_referral.status.placeholder',
      `您尚未邀请任何朋友`,
      `您尚未邀請任何朋友`,
      `まだ紹介した友達はいません。`,
      ``,
    ],
    btn_text_1: [
      `선물로 교환하기`,
      `Redeem`,
      'n_referral.status.btn_text_1',
      `兑换礼物`,
      `兌換禮物`,
      `ギフトへの交換`,
      ``,
    ],
    btn_text_2: [
      `선물 발송 완료`,
      `Gift delivered`,
      'n_referral.status.btn_text_2',
      `礼物已发送完成`,
      `禮物已發送完成`,
      `ギフト発送完了`,
      ``,
    ],
    btn_text_3: [
      `선물 발송 대기중`,
      `In queue`,
      'n_referral.status.btn_text_3',
      `礼物即将发送`,
      `禮物即將發送`,
      `ギフト発送待ち`,
      ``,
    ],
    caption_3: [
      `선물로 교환 가능한 포인트`,
      `Redeemable Points`,
      'n_referral.status.caption_3',
      `可以用来兑换礼物的积分。`,
      `可以用來兌換禮物的點數。`,
      `ギフトと交換可能なポイント`,
      ``,
    ],
    point_1: [`적립 대기중`, `Not applied`, 'n_referral.status.point_1', `尚未获得积分`, `尚未獲得點數`, `適用外`, ``],
    point_2: (number) => [
      `${number}P 적립`,
      `Received ${number}P`,
      'n_referral.status.point_2',
      `获得${number}积分`,
      `獲得${number}點數`,
      `${number}P を獲得`,
      ``,
    ],
    point_3: [
      `수업권 할인 적용`,
      `Use points for discount`,
      'n_referral.status.point_3',
      `用来折扣课程券`,
      `用來折扣課程券`,
      `レッスン料割引適用`,
      ``,
    ],
    point_4: [`사용 기간 만료`, `Expired`, 'n_referral.status.point_4', `已过期`, `已過期`, `使用期間満了`, ``],
    caption_4: [
      `체험 수업 완료`,
      `Completed Trial Lesson`,
      'n_referral.status.caption_4',
      `Completed Trial Lesson`,
      `Completed Trial Lesson`,
      `Completed Trial Lesson`,
      ``,
    ],
  },
  modal: {
    change_points: {
      title: [
        `선물로 교환하기`,
        `Redeem`,
        'n_referral.modal.change_points.title',
        `兑换礼物`,
        `兌換禮物`,
        `ギフトと交換する`,
        ``,
      ],
      caption_1: [
        `받는 사람`,
        `Recipient`,
        'n_referral.modal.change_points.caption_1',
        `收件人`,
        `收件人`,
        `受取人`,
        ``,
      ],
      caption_2: [
        `선물로 교환할 포인트`,
        `Points to redeem`,
        'n_referral.modal.change_points.caption_2',
        `用来兑换的点数`,
        `用來兌換的點數`,
        `ギフトと交換できるポイント`,
        ``,
      ],
      desc_1: [
        `신세계 상품권과 스타벅스 쿠폰은 받는 사람의 정보가 한국 이름 및 한국 번호로 정확하게 입력되어 있어야 합니다.`,
        `To receive Shinsega/Starbucks gift cards, you must input the recipient's Korean name and mobile phone number in Korea accurately.`,
        'n_referral.modal.change_points.desc_1',
        `获得新世界百货及星巴克礼券的人请输入韩文姓名及韩国手机号码`,
        `獲得新世界百貨及星巴克禮券的人請輸入韓文姓名及韓國手機號碼`,
        `新世界百貨店の商品券/スターバックスのギフトカードを受け取るには、受取人の氏名と携帯電話番号を正確に入力する必要があります。`,
        ``,
      ],
      desc_2: [
        `교환 신청한 선물은 매달 말일에 보내드립니다.`,
        `The redeemed gift will be sent on the last day of each month.`,
        'n_referral.modal.change_points.desc_2',
        `兑换的礼物将会于每月的最后一日发送`,
        `兌換的禮物將會於每月的最後一日發送`,
        `交換申請したギフトは、毎月末日にお送りします。`,
        ``,
      ],
      option_1: [
        `링글 40분 수업권 (1회)`,
        `1 Ringle credit (40-min lesson)`,
        'n_referral.modal.change_points.option_1',
        `一般课程券1张`,
        `一般課程券1張`,
        `Ringle40分レッスンチケット（1回）`,
        ``,
      ],
      option_2: [
        `신세계 상품권`,
        `Shinsegae gift card`,
        'n_referral.modal.change_points.option_2',
        `新世界百货礼券`,
        `新世界百貨禮券`,
        `新世界百貨店の商品券`,
        ``,
      ],
      option_3: [
        `스타벅스 쿠폰`,
        `Starbucks coupon`,
        'n_referral.modal.change_points.option_3',
        `星巴克礼券`,
        `星巴克禮券`,
        `スターバックスのギフトカード`,
        ``,
      ],
      btn_1: [`취소하기`, `Cancel`, 'n_referral.modal.change_points.btn_1', `取消`, `取消`, `キャンセルする`, ``],
      btn_2: [
        `선물 교환 신청하기`,
        `Redeem`,
        'n_referral.modal.change_points.btn_2',
        `兑换礼物`,
        `兌換禮物`,
        `ギフトへの交換の申請`,
        ``,
      ],
      btn_text: [
        `정보 변경하기`,
        `Change number`,
        'n_referral.modal.change_points.btn_text',
        `变更号码`,
        `變更號碼`,
        `情報を変更する`,
        ``,
      ],
    },
    ready_to_oredr: {
      title: [
        `선물 발송 대기 중`,
        `In queue`,
        'n_referral.modal.ready_to_oredr.title',
        `礼物即将发送`,
        `禮物即將發送`,
        `発送待機中`,
        ``,
      ],
      dsec: [
        `입력된 휴대폰 번호로 월말에 보내드려요.`,
        `Gift will be sent to the recipient's mobile phone number at the end of the month.`,
        'n_referral.modal.ready_to_oredr.dsec',
        `我们将在月底把讯息发送至您留下的韩国手机号码`,
        `我們將在月底把訊息發送至您留下的韓國手機號碼`,
        `入力された電話番号に月末にお送りします。`,
        ``,
      ],
      caption_1: [
        `받는 사람`,
        `Recipient`,
        'n_referral.modal.ready_to_oredr.caption_1',
        `收件人`,
        `收件人`,
        `受取人`,
        ``,
      ],
      caption_2: [
        `사용한 포인트`,
        `Points used`,
        'n_referral.modal.ready_to_oredr.caption_2',
        `使用的点数`,
        `使用的點數`,
        `使用したポイント`,
        ``,
      ],
      caption_3: [
        `변경 상품`,
        `Gift exchanged`,
        'n_referral.modal.ready_to_oredr.caption_3',
        `兑换商品`,
        `兌換商品`,
        `交換したギフト`,
        ``,
      ],
      caption_4: [
        `선물 교환 신청일`,
        `Gift exchange application date`,
        'n_referral.modal.ready_to_oredr.caption_4',
        `兑换日期`,
        `兌換日期`,
        `ギフト交換申請日`,
        ``,
      ],
    },
    complete: {
      title: [
        `선물 발송 완료`,
        `Gift delivered`,
        'n_referral.modal.complete.title',
        `礼物已发送完成`,
        `禮物已發送完成`,
        `ギフト発送完了`,
        ``,
      ],
      dsec: [
        `입력된 번호로 선물을 보내드렸어요.`,
        `The gift has been sent to the phone number you entered.`,
        'n_referral.modal.complete.dsec',
        `已将讯息发送至您留下的韩国手机号码`,
        `已將訊息發送至您留下的韓國手機號碼`,
        `入力された電話番号にプレゼントを送りました。`,
        ``,
      ],
      caption_1: [`받는 사람`, `Recipient`, 'n_referral.modal.complete.caption_1', `收件人`, `收件人`, `受取人`, ``],
      caption_2: [
        `사용한 포인트`,
        `Points used`,
        'n_referral.modal.complete.caption_2',
        `使用的点数`,
        `使用的點數`,
        `使用したポイント`,
        ``,
      ],
      caption_3: [
        `변경 상품`,
        `Gift exchanged`,
        'n_referral.modal.complete.caption_3',
        `兑换商品`,
        `兌換商品`,
        `交換したギフト`,
        ``,
      ],
      caption_4: [
        `선물 교환 신청일`,
        `Redeemed date`,
        'n_referral.modal.complete.caption_4',
        `兑换日期`,
        `兌換日期`,
        `ギフト交換申請日`,
        ``,
      ],
    },
  },
  toast_2: [
    `선물 교환을 신청했어요.`,
    `Done requesting gift.`,
    'n_referral.toast_2',
    `礼物兑换完成`,
    `禮物兌換完成`,
    `ギフト交換を申請しました。`,
    ``,
  ],
  toast_1: [
    `초대 링크를 복사했어요.`,
    `Copied the invite link.`,
    'n_referral.toast_1',
    `已複製邀请连结`,
    `已複製邀請連結`,
    `招待リンクをコピーしました。`,
    ``,
  ],
  terms_1: {
    title: {
      desc_2: [
        `추천인 유의사항`,
        `Event Terms & Conditions`,
        'n_referral.terms_1.title.desc_2',
        `活动注意事项`,
        `活動注意事項`,
        `友達紹介に関する規約`,
        ``,
      ],
    },
    desc_1: {
      desc_3: [
        `친구가 30만원 이상 결제하고 첫 수업을 완료하는 시점에 친구초대 포인트가 지급됩니다.`,
        `Points will be rewarded when your invited friend makes a purchase in the amount of $231 or above and completes their first lesson.`,
        'n_referral.terms_1.desc_1.desc_3',
        `朋友在支付超过美金 $231 并完成第一堂课时，您将获得朋友邀请积分。`,
        `朋友在支付超過美金 $231 並完成第一堂課時，您將獲得朋友邀請點數。`,
        `お友達が3万円以上決済し、最初のレッスンを完了した時点で友達紹介ポイントが付与されます。`,
        ``,
      ],
    },
    desc_2: {
      title: [
        `친구초대 포인트는 매 월 1명까지 50,000포인트, 이후 10,000포인트가 제공됩니다. (친구의 첫 수업 완료 날짜 기준)`,
        `You can earn $38p referral points once a month when a friend signs up with your referral code and $8p referral points for each additional friend who signs up.`,
        'n_referral.terms_1.desc_2.title',
        `每月可经由邀请一位朋友获得价值美金 $38 的积分，第二位朋友之后皆为价值美金 $8的积分（以朋友的第一堂课完成日期为准）`,
        `每月可經由邀請一位朋友獲得價值美金 $38 的點數，第二位朋友之後皆為價值美金 $8的點數（以朋友的第一堂課完成日期為準）`,
        `友達紹介ポイントは、毎月1人まで50,000ポイント、その後10,000ポイントが提供されます。 (お友達の最初のレッスン完了日を基準)`,
        ``,
      ],
    },
    desc_3: {
      desc_1: [
        `비정상적인 친구 초대로 판단되는 경우 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Any abnormal participation will result in disqualification or withdrawal of rewarded points.`,
        'n_referral.terms_1.desc_3.desc_1',
        `如果朋友邀请被判断有异常，积分可能不被支付或被收回。`,
        `如果朋友邀請被判斷有異常，點數可能不被支付或被收回。`,
        `規約に反すると判断された場合、ポイントが付与されない、または回収されることがあります。`,
        ``,
      ],
    },
    desc_4: {
      caption_1: [
        `친구 초대 포인트는 신세계 상품권, 스타벅스 쿠폰으로 교환할 수 있습니다.`,
        `Points rewarded for inviting friends can be exchanged for Shinsegae/Startbucks gift cards.`,
        'n_referral.terms_1.desc_4.caption_1',
        `透过邀请朋友获得的积分可兑换新世界百货和星巴克优惠券。`,
        `透過邀請朋友獲得的點數可兌換新世界百貨和星巴克優惠券。`,
        `友達紹介ポイントは、新世界百貨店の商品券、スターバックスのギフトカードと交換することができます。`,
        ``,
      ],
    },
    desc_5: {
      desc_2: [
        `지급된 포인트의 유효기간은 1년입니다.`,
        `The rewarded points will be valid for 30 days.`,
        'n_referral.terms_1.desc_5.desc_2',
        `获得的积分使用有效期为30天。`,
        `獲得的點數使用有效期為30天。`,
        `支給されたポイントの有効期限は30日です。`,
        ``,
      ],
    },
  },
  terms_2: {
    title: {
      caption_2: [
        `추천 혜택 유의사항`,
        `Terms for Referral Benefits`,
        'n_referral.terms_2.title.caption_2',
        `朋友邀请福利注意事项`,
        `朋友邀請福利注意事項`,
        `紹介特典の注意事項`,
        ``,
      ],
    },
    desc_1: {
      desc_3: [
        `친구 초대코드로 가입한 후 24시간 이내 체험 수업을 신청할 수 있습니다.`,
        `The free trial lesson is available for 24 hours after joining Ringle using the invite link (referral code).`,
        'n_referral.terms_2.desc_1.desc_3',
        `使用朋友邀请连结加入并注册Ringle后的24小时内可以获得免费体验课程。`,
        `使用朋友邀請連結加入並註冊Ringle後的24小時內可以獲得免費體驗課程。`,
        `無料体験レッスンは、紹介リンクからRingleに入会後、24時間ご利用いただけます。`,
        ``,
      ],
    },
    desc_2: {
      caption_3: [
        `단, 최대 할인을 위한 단계를 모두 완료한 경우에 한하여 무료로 체험 수업이 가능합니다. (친구의 초대코드로 가입하지 않은 경우 4,000원에 체험 수업 가능)`,
        `The free trial lesson becomes available only after completing all given steps for receiving maximum discounts. (Newcomers who joined Ringle without a refereral code are eligible for a trial lesson at the price of $3 after completing the given steps.)`,
        'n_referral.terms_2.desc_2.caption_3',
        `使用朋友邀请连结加入并注册Ringle后的24小时内可以获得免费体验课程。
（如果未使用朋友的邀请代码，则可以以3每元的价格体验课程）`,
        `使用朋友邀請連結加入並註冊Ringle後的24小時內可以獲得免費體驗課程。
（如果未使用朋友的邀請代碼，則可以以3每元的價格體驗課程）`,
        `無料体験レッスンは、すべてのステップを完了した場合にご利用いただけます。(友達紹介コードをお持ちでない新規の方は、4,000円で体験レッスンが受けられます)`,
        ``,
      ],
    },
    desc_3: {
      desc_4: [
        `친구의 초대코드로 가입한 직후 5만 포인트가 지급됩니다.`,
        `$38 points will be rewarded immediately after joining Ringle using the invite link (referral code).`,
        'n_referral.terms_2.desc_3.desc_4',
        `使用朋友邀请连结加入Ringle能立即获得价值美金 $38 的积分。`,
        `使用朋友邀請連結加入Ringle能立即獲得價值美金 $38 的點數。`,
        `友達紹介コードから入会後、5万ポイントが付与されます。`,
        ``,
      ],
    },
    desc_4: {
      caption_4: [
        `지급된 포인트의 유효기간은 30일입니다.`,
        `Points issued are valid for 365 days after the issue date.`,
        'n_referral.terms_2.desc_4.caption_4',
        `积分的有效期为1年。`,
        `點數的有效期為1年。`,
        `支給されたポイントの有効期限は1年間です。`,
        ``,
      ],
    },
    desc_5: {
      desc_5: [
        `지급된 포인트는 30만원 이상 수업권 구매시 사용 가능합니다.`,
        `The rewarded points can be used to purchase credits worth $38 or more.`,
        'n_referral.terms_2.desc_5.desc_5',
        `获得的积分可在购买超过美金 $38的课程券时使用。`,
        `獲得的點數可在購買超過美金 $38的課程券時使用。`,
        `支給されたポイントは$38以上のレッスンチケットを購入する場合に使用できます。`,
        ``,
      ],
    },
    desc_6: {
      caption_5: [
        `신규회원쿠폰 (가입 후 7일간 사용가능) 과 중복으로 사용 가능합니다.`,
        `The rewarded points can be combined with the newcomer coupon (valid for 7 days after joining Ringle).`,
        'n_referral.terms_2.desc_6.caption_5',
        `新会员优惠券（注册后7天内可使用）可以与其他优惠一同使用。`,
        `新會員優惠券（註冊後7天內可使用）可以與其他優惠一同使用。`,
        `新規会員クーポン(入会後7日間使用可能)と重複して使用可能です。`,
        ``,
      ],
    },
  },
  currency: [`원`, ``, 'n_referral.currency', `元`, `元`, `円`, ``],
  section_4: {
    ssg: [
      `신세계 상품권`,
      `Sinsegae`,
      'n_referral.section_4.ssg',
      `新世界百货商品券`,
      `新世界百貨商品券`,
      `新世界百貨店の商品券`,
      ``,
    ],
    starbucks: [
      `스타벅스 쿠폰`,
      `Starbucks`,
      'n_referral.section_4.starbucks',
      `星巴克商品券`,
      `星巴克商品券`,
      `スターバックスのギフトカード`,
      ``,
    ],
    credit: [
      `링글 수업권`,
      `Ringle Credit`,
      'n_referral.section_4.credit',
      `Ringle课程券`,
      `Ringle課程券`,
      `Ringleレッスンチケット`,
      ``,
    ],
  },
  section_3: {
    point: [
      `50,000P`,
      `38.00P`,
      'n_referral.section_3.point',
      `美金$38相当价值积分`,
      `美金$38相當價值點數`,
      `50,000円`,
      ``,
    ],
  },
  change_points: {
    app: {
      title_1: [
        `정보를 확인하세요.`,
        `Please check the information.`,
        'n_referral.change_points.app.title_1',
        `请确认资讯`,
        `請確認資訊`,
        `情報をご確認ください。`,
        ``,
      ],
      title_2: [
        `상품으로 교환할
포인트를 선택해주세요.`,
        `Please select the points to redeem for gift.`,
        'n_referral.change_points.app.title_2',
        `请选择欲兑换礼物的点数`,
        `請選擇欲兌換禮物的點數`,
        `ギフトと交換する
 ポイントを選択してください。`,
        ``,
      ],
      title_3: (point) => [
        <>
          {point}포인트를
          <br />
          어떤 선물로 교환할까요?
        </>,
        <>
          What gift would you like to exchange
          <br />
          for {point} points?
        </>,
        'n_referral.change_points.app.title_3',
        <>
          您想要用{point}点<br />
          兑换什麽礼物呢？
        </>,
        <>
          您想要用{point}點<br />
          兌換什麼禮物呢？
        </>,
        <>
          {point}ポイントを獲得
          <br />
          どのギフトと交換しますか？
        </>,
        ``,
      ],
      btn_1: [
        `포인트를 선택하세요.`,
        `Please select the points.`,
        'n_referral.change_points.app.btn_1',
        `请选择点数`,
        `請選擇點數`,
        `ポイントを選択してください。`,
        ``,
      ],
      btn_2: (point) => [
        `${point}포인트 교환하기`,
        `Redeem ${point} points.`,
        'n_referral.change_points.app.btn_2',
        `兑换${point}点`,
        `兌換${point}點`,
        `${point}ポイント交換する`,
        ``,
      ],
      modal_title_1: (point) => [
        `${point}포인트를
신세계 상품권으로 교환합니다.`,
        `Exchange ${point} points for Sinsegae gift card.`,
        'n_referral.change_points.app.modal_title_1',
        `用${point}点
兑换新世界百货商品券`,
        `用${point}點
兌換新世界百貨商品券`,
        `${point}ポイントを新世界百貨店の商品券と交換します。`,
        ``,
      ],
      modal_title_2: (point) => [
        `${point}포인트를
링글 40분 수업권으로 교환합니다.`,
        `Exchange ${point} points for 40-min credit.`,
        'n_referral.change_points.app.modal_title_2',
        `用${point}点
兑换40分钟课程`,
        `用${point}點
兌換40分鐘課程`,
        `${point}ポイントをRingle40分レッスンチケットと交換します。`,
        ``,
      ],
      modal_title_3: (point) => [
        `${point}포인트를
스타벅스 쿠폰으로 교환합니다.`,
        `Exchange ${point} points for Starbucks coupon.`,
        'n_referral.change_points.app.modal_title_3',
        `用${point}点
兑换星巴克礼券`,
        `用${point}點
兌換星巴克禮券`,
        `${point}ポイントをスターバックスのギフトカードと交換します。`,
        ``,
      ],
      modal_desc: [
        `선물로 교환한 포인트는 복구할 수 없습니다.`,
        `Points exchanged for gifts cannot be restored.`,
        'n_referral.change_points.app.modal_desc',
        `已兑换的点数无法復原`,
        `已兌換的點數無法復原`,
        `ギフトに交換したポイントは回収できません。`,
        ``,
      ],
      name: [`이름`, `name`, 'n_referral.change_points.app.name', `名字`, `名字`, `氏名`, ``],
      number: [
        `휴대폰 번호`,
        `Phone number`,
        'n_referral.change_points.app.number',
        `手机号码`,
        `手機號碼`,
        `電話番号`,
        ``,
      ],
    },
  },
}
export const n_tutors = {
  video_updated_at: (year, month, day) => [
    <>
      {year}.{month}.{day} 촬영
    </>,
    <>
      Filmed on {month}.{day}.{year}
    </>,
    'n_tutors.video_updated_at',
    <>
      拍摄日期 {month}.{day}.{year}
    </>,
    <>
      拍攝日期 {month}.{day}.{year}
    </>,
    <>
      {year}.{month}.{day} 撮影
    </>,
    <>
      Ghi hình ngày {month}.{day}.{year}
    </>,
  ],
  btn_see_schedule: [
    `수업 스케줄 보기`,
    `Time slots`,
    'n_tutors.btn_see_schedule',
    `确认课程日期`,
    `確認課程日期`,
    `レッスンスケジュール`,
    ``,
  ],
  schedule_fully_booked: [
    `수업 예약 마감`,
    `Fully booked`,
    'n_tutors.schedule_fully_booked',
    `已无法预约`,
    `已無法預約`,
    `レッスン予約締切`,
    `Đã đặt hết`,
  ],
  btn_schedule: [
    `스케줄 오픈 전`,
    `Not available`,
    'n_tutors.btn_schedule',
    `日期尚未开放`,
    `日期尚未開放`,
    `まだ予約できません`,
    ``,
  ],
  mark_tutor: [`찜하기`, `Favorite`, 'n_tutors.mark_tutor', `收藏`, `收藏`, `お気に入り`, `Yêu thích`],
  accept_rate: [
    `수락률`,
    `Acceptance rate`,
    'n_tutors.accept_rate',
    `课程接受率`,
    `課程接受率`,
    `承諾率`,
    `Tỷ lệ nhận`,
  ],
  with_tutor_count: [
    `이 튜터와 수업한 횟수`,
    `Number of classes with this teacher.`,
    'n_tutors.with_tutor_count',
    `与此教师上过课的次数`,
    `與此教師上過課的次數`,
    `この先生と授業回数`,
    `Tỷ lệ nhận`,
  ],
  label_featured_desc: (name) => [
    <>{name} 튜터는 최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터입니다.</>,
    <>Tutor {name} received high ratings and positive reviews in the past month.</>,
    'n_tutors.label_featured_desc',
    <>{name}教师是最近一个月内收到高评分和正面评价的教师。</>,
    <>{name}教師是最近一個月內收到高評分和正面評價的教師。</>,
    <>{name} この講師は、最近1ヶ月間、高い授業評価と好意的なレビューを獲得した講師です</>,
    <>Tutor {name} received high ratings and positive reviews in the past month.</>,
  ],
  label_pointback_desc: (name) => [
    <>
      {name} 튜터는 링글에 새로 온 튜터입니다. 튜터와 수업 후 수업 평가를 남기면 1회 수업료의 50%를 포인트로
      돌려드립니다. (*유료 수업권 한정)
    </>,
    <>
      Tutor {name} is a rookie tutor. Take a lesson with {name} and receive 50% value back by leaving a review. (*paid
      lessons only)
    </>,
    'n_tutors.label_pointback_desc',
    <>{name}教师刚加入Ringle的新教师。与教师上完课后留下评价，您将获得课程费用50%相当的积分。 (*仅限付费课程)</>,
    <>{name}教師剛加入Ringle的新教師。與教師上完課後留下評價，您將獲得課程費用50%相當的點數。 (*僅限付費課程)</>,
    <>
      {name}{' '}
      チューターはRingleに新しく登録したばかりのチューターです。チューターとのレッスン後にレッスン評価を残すと、1回分のレッスン料の50%をポイントで還元します。(※有料レッスンチケットに限る)
    </>,
    <>
      Tutor {name} is a rookie tutor. Take a lesson with {name} and receive 50% value back by leaving a review. (*paid
      lessons only)
    </>,
  ],
  label_adults_only_desc: (name) => [
    <>
      {name} 튜터는 성인 수강생 대상 수업만 진행하는 튜터입니다. <br />
      초/중/고 수강생 예약 신청 시, 매칭되지 않을 가능성이 높습니다
    </>,
    <>
      Tutor {name} is available only for adult learners.
      <br />
      There is a high chance that matching will fail for students in their teens.
    </>,
    'n_tutors.label_adults_only_desc',
    <>{name}教师仅教授成人课程。如果国小/国中/高中生申请课程，可能无法成功预约。</>,
    <>{name}教師僅教授成人課程。如果國小/國中/高中生申請課程，可能無法成功預約。</>,
    <>
      {name} チューターは大人向けの授業のみを行っているチューターです。 <br />
      小・中・高生が予約申請する場合、マッチングされない可能性が高いです。
    </>,
    <>
      Tutor {name} is available only for adult learners.
      <br />
      There is a high chance that matching will fail for students in their teens.
    </>,
  ],
  annoucement: [
    `튜터 공지사항`,
    `Tutor’s Announcement`,
    'n_tutors.annoucement',
    `教师公告事项`,
    `教師公告事項`,
    `チューターからのお知らせ`,
    `Thông báo từ gia sư`,
  ],
  profile_overview: [`요약`, `Summary`, 'n_tutors.profile_overview', `简介`, `簡介`, `要約`, `Tóm tắt`],
  intro: [`자기소개`, `Intro`, 'n_tutors.intro', `自我介绍`, `自我介紹`, `自己紹介`, `Giới thiệu`],
  review: [`수업 평가`, `Review`, 'n_tutors.review', `课程评价`, `課程評價`, `レッスン評価`, `Nhận xét`],
  book_lessons: [
    `수업 예약`,
    `Book lessons`,
    'n_tutors.book_lessons',
    `预约课程`,
    `預約課程`,
    `レッスン予約`,
    `Book lessons`,
  ],
  keywords: [`키워드`, `Keywords`, 'n_tutors.keywords', `关键字`, `關鍵字`, `キーワード`, ``],
  experience: [`핵심 경험`, `Experience`, 'n_tutors.experience', `核心经验`, `核心經驗`, `主な経歴`, `Kinh nghiệm`],
  interests: [`관심사`, `Interests`, 'n_tutors.interests', `有兴趣的主题`, `有興趣的主題`, `興味/関心`, `Sở thích`],
  review_explanation: [
    `이 튜터의 수업을 직접 수강한 고객님들이 선택한 키워드 중 상위 5개에 대한 통계입니다.`,
    `Top 5 keywords students have used to describe the tutor's lesson style`,
    'n_tutors.review_explanation',
    `学生给教师的Top 5课程特色形容词`,
    `學生給教師的Top 5課程特色形容詞`,
    `当チューターのレッスンを実際に受講したお客様が選んだキーワードのうち上位5つに対する統計です。`,
    `Top 5 từ khoá học viên dùng để miêu tả phong cách giảng dạy của gia sư`,
  ],
  accept_rate_requested: [
    `수업 요청 시 튜터가 수락한 비율입니다.`,
    `Indicates rate that a tutor will accept your lesson booking.`,
    'n_tutors.accept_rate_requested',
    `教师接受课程的比例`,
    `教師接受課程的比例`,
    `チューターがレッスンの予約を受け付ける率を示します。`,
    ``,
  ],
  no_history: [
    `지난 2주간 수업 이력이 없어 수락률이 표기되지 않습니다.`,
    `The acceptance rate is not indicated since the tutor has not conducted lessons for the past two weeks.`,
    'n_tutors.no_history',
    `过去两週未进行课程因此不显示课程接受率`,
    `過去兩週未進行課程因此不顯示課程接受率`,
    `過去2週間のレッスン履歴がないため、承諾率は表示されません。`,
    ``,
  ],
  lesson_span_40_min: [
    `40분 수업`,
    `40-minute lesson`,
    'n_tutors.lesson_span_40_min',
    `40分课程`,
    `40分課程`,
    `40分授業`,
    ``,
  ],
  lesson_span_20_min: [
    `20분 수업`,
    `20-minute lesson`,
    'n_tutors.lesson_span_20_min',
    `20分课程`,
    `20分課程`,
    `20分授業`,
    ``,
  ],
  no_schedule_content: [
    <>
      최근 튜터가 수업 스케줄을 열지 않았어요.
      <br />
      다른 튜터와 수업 예약을 진행해보세요.
    </>,
    <>
      This tutor has not opened any time slots.
      <br />
      Try booking another tutor.
    </>,
    'n_tutors.no_schedule_content',
    <>
      教师尚未开放预约时间.
      <br />
      请预约其他教师的时间.
    </>,
    <>
      教師尚未開放預約時間.
      <br />
      請預約其他教師的時間.
    </>,
    <>
      このチューターは予約枠が空いていません
      <br />
      他のチューターを選択してください。
    </>,
    ``,
  ],
  fully_booked_title: [
    `튜터가 열어둔 스케줄이 모두 마감되었어요`,
    `All time slots have been booked.`,
    'n_tutors.fully_booked_title',
    `教师的预约已满`,
    `教師的預約已滿`,
    `このチューターは予約できる枠が空いていません。`,
    ``,
  ],
  fully_booked_content: [
    `다른 튜터와 수업 예약을 진행해보세요.`,
    `Please try booking another tutor.`,
    'n_tutors.fully_booked_content',
    `请尝试预约其他教师`,
    `請嘗試預約其他教師`,
    `他のチューターを選択してください。`,
    ``,
  ],
  fully_booked_40_min_content: [
    `20분 수업을 예약하거나, 다른 튜터와 수업 예약을 진행해보세요.`,
    `Book a 20-minute session with a tutor or check other tutors' schedule.`,
    'n_tutors.fully_booked_40_min_content',
    `请尝试预约20分钟课程或预约其他教师`,
    `請嘗試預約20分鐘課程或預約其他教師`,
    `20分レッスンを予約したり、他のチューターのスケジュールを確認してみましょう。`,
    ``,
  ],
  fully_booked_40_min: [
    <>
      튜터가 열어둔 40분 스케줄이 마감되었어요.
      <br />
      20분 수업을 확인해주세요.
    </>,
    <>
      The tutor's 40-min slots are fully booked.
      <br />
      Try booking 20-min lessons with the tutor.
    </>,
    'n_tutors.fully_booked_40_min',
    <>
      教师的40分课程预约已满
      <br />
      请确认20分课程可预约时间
    </>,
    <>
      教師的40分課程預約已滿
      <br />
      請確認20分課程可預約時間
    </>,
    <>
      チューターの40分レッスン枠が埋まってしまいました。
      <br />
      20分レッスンの空きをご確認ください。.
    </>,
    <>
      The tutor's 40-min slots are fully booked.
      <br />
      Try booking 20-min lessons with the tutor.
    </>,
  ],
  fully_booked_20_min: [
    <>
      튜터가 열어둔 20분 스케줄이 마감되었어요.
      <br />
      40분 수업을 확인해주세요.
    </>,
    <>
      The tutor's 20-min slots are fully booked.
      <br />
      Try booking 40-min lessons with the tutor.
    </>,
    'n_tutors.fully_booked_20_min',
    <>
      教师的20分课程预约已满
      <br />
      请确认40分课程可预约时间
    </>,
    <>
      教師的20分課程預約已滿
      <br />
      請確認40分課程可預約時間
    </>,
    <>
      チューターの20分レッスン枠が埋まってしまいました。
      <br />
      40分レッスンの空きをご確認ください。.
    </>,
    <>
      The tutor's 20-min slots are fully booked.
      <br />
      Try booking 40-min lessons with the tutor.
    </>,
  ],
}
export const n_tutor_lists = {
  title: [`튜터`, `Tutor`, 'n_tutor_lists.title', `教师`, `教師`, `チューター`, ``],
  all_tutor: [`전체 튜터`, `All`, 'n_tutor_lists.all_tutor', `全部教师`, `全部教師`, `すべてのチューター`, `Tất cả`],
  featured_desc: [
    <>
      최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요. <br />
      추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및
      사유 제출, 월 2회 한정)
    </>,
    <>
      These tutors have received high ratings and positive reviews.
      <br />
      Credit restored if unsatisfied with the Rookie/Featured Tutor. (Rate as 3 stars or less after a paid lesson with
      detailed reason, twice per month limit)
    </>,
    'n_tutor_lists.featured_desc',
    <>
      对新教师或推荐教师的课程不满意时，将退还课程券。
      （仅限用付费课程券预约之课程，教师的课后评分不足3分时并提交原因，每月限2次）
    </>,
    <>
      對新教師或推薦教師的課程不滿意時，將退還課程券。
      （僅限用付費課程券預約之課程，教師的課後評分不足3分時並提交原因，每月限2次）
    </>,
    `おすすめの/新規チューターのレッスンが満足できなかったら、レッスンチケットを復旧いたします。（有料レッスンチケットで予約、評価３点以下および理由提出、月２回限定）`,
    `Nếu không hài lòng với buổi học của gia sư mới/ gia sư đề cử, phiếu học sẽ được hoàn. (Tối đa 2 lần/ tháng cho buổi học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
  ],
  numbers: (number) => [
    `${number}명의 튜터`,
    `${number} tutors`,
    'n_tutor_lists.numbers',
    `共 ${number}名`,
    `共 ${number}名`,
    `合計${number}人`,
    ``,
  ],
  search_placehoder: [
    `튜터의 이름 또는 전공으로 검색하세요.`,
    `Enter a keyword`,
    'n_tutor_lists.search_placehoder',
    `请输入关键字`,
    `請輸入關鍵字`,
    `キーワードを入力してください。`,
    ``,
  ],
  search_title: [
    `튜터 검색`,
    `Search Tutors`,
    'n_tutor_lists.search_title',
    `搜寻教师`,
    `搜尋教師`,
    `チューター検索`,
    ``,
  ],
  search_keyword_title: (keyword) => [
    <>{keyword}에 대한 검색 결과</>,
    <>Results for "{keyword}"</>,
    'n_tutor_lists.search_keyword_title',
    <>{keyword}的搜寻结果</>,
    <>{keyword}的搜尋結果</>,
    <>{keyword}の検索結果</>,
    ``,
  ],
  search_keyword_empty_desc: (keyword) => [
    <>
      {keyword}’에 대한 검색 결과가 없어요.
      <br />
      단어의 철자를 확인 하거나 일반적인 검색어로 다시 검색해보세요.
    </>,
    <>No results for "{keyword}". Please check the spelling or use another keyword.</>,
    'n_tutor_lists.search_keyword_empty_desc',
    <>
      没有符合{keyword}’的搜寻结果
      <br />
      请重新确认关键字并重试
    </>,
    <>
      沒有符合{keyword}’的搜尋結果
      <br />
      請重新確認關鍵字並重試
    </>,
    <>
      {keyword}'についての検索結果がありません。
      <br />
      単語のスペルを確認したり、一般的な検索キーワードで再検索してみてください。
    </>,
    ``,
  ],
  search_keyword_empty_btn: [
    `뒤로 가기`,
    `Back`,
    'n_tutor_lists.search_keyword_empty_btn',
    `上一步`,
    `上一步`,
    `戻る`,
    ``,
  ],
  featured_desc_mobile1: [
    <>
      최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요. <br />
      추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다.{' '}
    </>,
    <>
      These tutors have received high ratings and positive reviews.
      <br />
      Credit restored if unsatisfied with the Rookie/Featured Tutor.{' '}
    </>,
    'n_tutor_lists.featured_desc_mobile1',
    <>
      这些是最近一个月内得到高评分和正面评价的教师。
      <br />
      如果不满意和推荐教师进行的课程，我们将退还课程券给您。{' '}
    </>,
    <>
      這些是最近一個月內得到高評分和正面評價的教師。
      <br />
      如果不滿意和推薦教師進行的課程，我們將退還課程券給您。{' '}
    </>,
    <>
      直近1ヶ月間で、レッスン評価が高かったチューターです。 <br />
      お勧めされたチューターとのレッスンに満足できない場合は、レッスンチケットを再発行します。
    </>,
    ``,
  ],
  featured_desc_mobile2: [
    `*유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정`,
    `*Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit`,
    'n_tutor_lists.featured_desc_mobile2',
    <>
      对新教师或推荐教师的课程不满意时，将退还课程券。
      （仅限用付费课程券预约之课程，教师的课后评分不足3分时并提交原因，每月限2次）
    </>,
    <>
      對新教師或推薦教師的課程不滿意時，將退還課程券。
      （僅限用付費課程券預約之課程，教師的課後評分不足3分時並提交原因，每月限2次）
    </>,
    `*有料レッスンチケットで予約後、チューター評価3点以下と理由の提出、月2回限定`,
    ``,
  ],
  featured_desc_multi: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요.`,
    ``,
    'n_tutor_lists.featured_desc_multi',
    ``,
    ``,
    ``,
    ``,
  ],
  subhead_1: (purpose) => [
    <>
      {purpose} 수업을
      <br />
      강점으로 가진 튜터를 추천드려요 
    </>,
    <>
      Here are tutors who are
      <br />
      great for {purpose}. 
    </>,
    'n_tutor_lists.subhead_1',
    <>推荐能够帮助{purpose}的教师</>,
    <>推薦能夠幫助{purpose}的教師</>,
    <>
      {purpose}が素晴らしい
      <br />
      チューターはこちらです
    </>,
    ``,
  ],
  subhead_2: (user_name) => [
    <>
      {user_name}님이
      <br />
      찜한 튜터의 스케줄이 열려있어요 
    </>,
    <>
      A tutor you added to favorites
      <br />
      is available for this time slot.
    </>,
    'n_tutor_lists.subhead_2',
    <>
      {user_name}
      <br />
      收藏的教师开课了 
    </>,
    <>
      {user_name}
      <br />
      收藏的教師開課了 
    </>,
    <>
      お気に入りに追加したチューターは
      <br />
      この時間帯で予約可能です
    </>,
    ``,
  ],
  subhead_3: [
    <>
      최근 높은 평점과
      <br />
      긍정적인 후기를 많이 받았어요 
    </>,
    <>
      This tutor received high ratings
      <br />
      and many positive reviews recently.
    </>,
    'n_tutor_lists.subhead_3',
    `最近获得高评分以及正面评价`,
    `最近獲得高評分以及正面評價`,
    <>
      このチューターは高い評価を受けており
      <br />
      多くの人がポジティブなレビューをしています
    </>,
    ``,
  ],
  subhead_4: [
    <>할인된 가격으로 수업할 수 있어요 </>,
    <>Learn at a discounted price.</>,
    'n_tutor_lists.subhead_4',
    <>可以用折扣价上课 </>,
    <>可以用折扣價上課 </>,
    <>割引価格で受講可能です</>,
    ``,
  ],
  subhead_5: [
    `수업 후기가 좋은 튜터에요`,
    `This tutor received great reviews.`,
    'n_tutor_lists.subhead_5',
    `拥有正面课后评价的教师`,
    `擁有正面課後評價的教師`,
    `このチューターは高い評価を受けています`,
    ``,
  ],
  recommend_desc: [
    `새로온 튜터로, 수업 평가를 남기면 수업료의 50%를 포인트로 돌려받을 수 있어요.`,
    `You can get 50% value back in points after rating your lesson with this tutor.`,
    'n_tutor_lists.recommend_desc',
    `留下与新进教师的课后评价，可以获得课程价格50％的积分回馈`,
    `留下與新進教師的課後評價，可以獲得課程價格50％的點數回饋`,
    `このチューターとのレッスン終了後、レッスンチケット50％相当のポイントがもらえます。`,
    ``,
  ],
  btn: [
    `예약 가능한 모든 튜터 보기`,
    `View all available tutors`,
    'n_tutor_lists.btn',
    `确认可预约的教师`,
    `確認可預約的教師`,
    `予約可能なチューターをすべて見る`,
    ``,
  ],
  subhead_6: [
    `예약할 수 있는 튜터가 더 있어요`,
    `Here are more available tutors.`,
    'n_tutor_lists.subhead_6',
    `还有更多可预约的教师`,
    `還有更多可預約的教師`,
    `すぐに予約可能な他のチューターはこちら`,
    ``,
  ],
  keywords_title: [
    `수업 후기 키워드`,
    `What students say`,
    'n_tutor_lists.keywords_title',
    `课后评论关键字`,
    `課後評論關鍵字`,
    `口コミ・レビュー`,
    ``,
  ],
  recommend_title: [
    `추천 이유`,
    `Special benefit`,
    'n_tutor_lists.recommend_title',
    `推荐原因`,
    `推薦原因`,
    `おすすめの理由`,
    ``,
  ],
  purpose_1: [
    `초급 영어 회화`,
    `Beginner English`,
    'n_tutor_lists.purpose_1',
    `初级英文会话`,
    `初級英文會話`,
    `初心者向けの英会話`,
    ``,
  ],
  purpose_2: [
    `영어 토론`,
    `English Discussions`,
    'n_tutor_lists.purpose_2',
    `英文讨论`,
    `英文討論`,
    `英語でのディスカッション`,
    ``,
  ],
  purpose_3: [
    `비즈니스 영어`,
    `Business English`,
    'n_tutor_lists.purpose_3',
    `商用英文`,
    `商用英文`,
    `ビジネス英語`,
    ``,
  ],
  purpose_4: [
    `비즈니스 인터뷰`,
    `Job Interviews`,
    'n_tutor_lists.purpose_4',
    `求职面试`,
    `求職面試`,
    `採用面接対策`,
    ``,
  ],
  purpose_5: [
    `해외 유학 인터뷰`,
    `Admissions Interviews`,
    'n_tutor_lists.purpose_5',
    `海外留学面试`,
    `海外留學面試`,
    `留学面接対策`,
    ``,
  ],
  purpose_6: [
    `에세이/ 이력서 교정`,
    `Refining Essays/Resume`,
    'n_tutor_lists.purpose_6',
    `修改英文履历／文章`,
    `修改英文履歷／文章`,
    `エッセイ・レジュメの添削`,
    ``,
  ],
  purpose_7: [
    `공인영어 시험 준비`,
    `Language Test Prep`,
    'n_tutor_lists.purpose_7',
    `准备英文检定考试`,
    `準備英文檢定考試`,
    `語学試験対策`,
    ``,
  ],
  selected_title: [
    `튜터 선택 이유`,
    `Why this tutor?`,
    'n_tutor_lists.selected_title',
    `选择教师的原因`,
    `選擇教師的原因`,
    `チューターの選定理由`,
    ``,
  ],
  tooltip: [
    `수업할 날짜와 시간을 자유롭게 변경할 수 있어요.`,
    `You can freely change the lesson date and time.`,
    'n_tutor_lists.tooltip',
    `可以自由更改上课日期与时间`,
    `可以自由更改上課日期與時間`,
    `レッスンの日時は自由に変更できます`,
    ``,
  ],
}
export const n_change_time = {
  title: [
    `수업 시간 변경`,
    `Reschedule Lesson`,
    'n_change_time.title',
    `变更课程时间`,
    `變更課程時間`,
    `レッスン時間の変更`,
    ``,
  ],
  time: [`시간`, `Date & Time`, 'n_change_time.time', `日期＆时间`, `日期＆時間`, `日時`, ``],
  tutor: [`튜터`, `Tutor`, 'n_change_time.tutor', `教师`, `教師`, `チューター`, ``],
  material: [`교재`, `Material`, 'n_change_time.material', `教材`, `教材`, `教材`, ``],
  btn_complete: [
    `시간 변경 완료`,
    `Change time slot`,
    'n_change_time.btn_complete',
    `变更时间`,
    `變更時間`,
    `時間変更完了`,
    ``,
  ],
  toast_success: [
    `수업 시간을 변경했어요.`,
    `Changed the time slot.`,
    'n_change_time.toast_success',
    `时间变更已完成`,
    `時間變更已完成`,
    `レッスン時間を変更しました。`,
    ``,
  ],
  toast_error: [
    `수업 시간 변경에 실패했습니다. 다시 시도해주세요.`,
    `Failed to change time slot. Please try again.`,
    'n_change_time.toast_error',
    `变更课程时间失败，请再试一次！`,
    `變更課程時間失敗，請再試一次！`,
    `レッスン時間の変更に失敗しました。もう一度お試しください。`,
    ``,
  ],
  float_title: [`시간 변경`, `Time`, 'n_change_time.float_title', `变更时间`, `變更時間`, `時間変更`, ``],
  float_placehoder: [
    `변경하고 싶은 수업시간을 선택해주세요.`,
    `Please select a time slot.`,
    'n_change_time.float_placehoder',
    `请选择欲变更的时间`,
    `請選擇欲變更的時間`,
    `変更したいレッスン時間を選択してください。`,
    ``,
  ],
}
export const ai_analytics = {
  title_summary: [
    `AI 분석 통계`,
    `AI Analysis Statistics`,
    'ai_analytics.title_summary',
    `AI 分析报告`,
    `AI 分析報告`,
    `AI Analysis統計`,
    ``,
  ],
  title: [
    `AI 분석 통계`,
    `AI Analysis Statistics`,
    'ai_analytics.title',
    `AI 分析报告`,
    `AI 分析報告`,
    `AI Analysis統計`,
    ``,
  ],
}
export const n_study = {
  week_streak: {
    main: (n) => [
      `${n}주 연속 학습`,
      `Week ${n} of Study Streak`,
      'n_study.week_streak.main',
      `连续${n}週学习`,
      `連續${n}週學習`,
      `${n}週学習継続`,
      ``,
    ],
    sub1: [
      `오늘의 학습을 시작하세요.`,
      `Let's start today's daily study.`,
      'n_study.week_streak.sub1',
      `开始每日学习活动吧！`,
      `開始每日學習活動吧！`,
      `今日の学習を始めましょう。`,
      ``,
    ],
    sub2: [
      `남은 학습을 완료하고 이번 주 학습을 완성하세요.`,
      `Finish the rest to continue your study streak.`,
      'n_study.week_streak.sub2',
      `请进行剩下的学习完成本週的每日学习活动`,
      `請進行剩下的學習完成本週的每日學習活動`,
      `残りの学習を完了し、今週の学習を完成させましょう。`,
      ``,
    ],
    sub3: [
      `잘하고 있어요! 내일 만나요.`,
      `You're doing great! See you tomorrow.`,
      'n_study.week_streak.sub3',
      `真是太棒了！明天见`,
      `真是太棒了！明天見`,
      `その調子です！また明日会いましょう。`,
      ``,
    ],
    sub4: [
      `대단해요! 이번 주 모든 학습을 완료했어요.`,
      `Great! You completed all daily studies for this week.`,
      'n_study.week_streak.sub4',
      `太厉害了！您已完成本週所有的每日学习活动`,
      `太厲害了！您已完成本週所有的每日學習活動`,
      `素晴らしい！今週はすべての学習を完了しました。`,
      ``,
    ],
    info1: (date, hour) => [
      `이번 주 학습 기간이 ${date}일 ${hour}시간 남았어요.`,
      `${date} day(s) ${hour} hour(s) left till deadline.`,
      'n_study.week_streak.info1',
      `距离本週每日学习活动结束还有${date}日${hour} 小时`,
      `距離本週每日學習活動結束還有${date}日${hour} 小時`,
      `今週の学習期間が${date}日${hour}時間残っています。`,
      ``,
    ],
    info2: [
      `5일 학습을 모두 완료하고 연속학습을 완성하세요.`,
      `Complete all 5 studies to keep your study streak.`,
      'n_study.week_streak.info2',
      `完成5天的每日学习活动确保连续学习`,
      `完成5天的每日學習活動確保連續學習`,
      `5日間の学習を全て完了し、継続学習を達成しましょう。`,
      ``,
    ],
    info3: (date, hour) => [
      `다음주 학습이 ${date}일 ${hour}시간 뒤에 시작되요.`,
      `Next week's daily study starts in ${date} day(s) ${hour} hour(s).`,
      'n_study.week_streak.info3',
      `距离下週每日学习活动开始还有${date}日${hour} 小时`,
      `距離下週每日學習活動開始還有${date}日${hour} 小時`,
      `来週の学習は${date}日${hour}時間後に始まります。`,
      ``,
    ],
    detail: [
      `오답이었거나, 건너뛴 학습이 아직 있어요!`,
      `There are incorrect or skipped activities`,
      'n_study.week_streak.detail',
      `尚有回答错误或是为回答的内容`,
      `尚有回答錯誤或是為回答的內容`,
      `間違った答えや、スキップした学習が残っています！`,
      ``,
    ],
  },
  card_label: {
    today: [`오늘`, `Today`, 'n_study.card_label.today', `今天`, `今天`, `今日`, ``],
    yet: [`진행 전`, `Todo`, 'n_study.card_label.yet', `预计完成`, `預計完成`, `未完了`, ``],
    done: [`학습 완료`, `Done`, 'n_study.card_label.done', `已完成`, `已完成`, `学習完了`, ``],
  },
  card_btn1: [
    `학습 시작하기`,
    `Start daily study`,
    'n_study.card_btn1',
    `开始每日学习`,
    `開始每日學習`,
    `学習を始める`,
    ``,
  ],
  card_btn2: [
    `학습 다시하기`,
    `Redo daily study`,
    'n_study.card_btn2',
    `再次进行每日学习`,
    `再次進行每日學習`,
    `学習をやり直す`,
    ``,
  ],
  card_btn3: [
    `학습 이어하기`,
    `Continue daily study`,
    'n_study.card_btn3',
    `继续进行每日学习`,
    `繼續進行每日學習`,
    `学習を続ける`,
    ``,
  ],
  more_study: {
    title_pc: [
      `더 다양한 학습을 원하세요?`,
      `Want to study more?`,
      'n_study.more_study.title_pc',
      `您还想学习更多吗？`,
      `您還想學習更多嗎？`,
      `学習を続けますか？`,
      ``,
    ],
    title_app: [
      `더 다양한 학습을\n원하세요?`,
      `Want to study more?`,
      'n_study.more_study.title_app',
      `您还想学习更多吗？`,
      `您還想學習更多嗎？`,
      `学習を\n続けますか？`,
      ``,
    ],
  },
  bsf: {
    title: [
      `Bite Sized feedback`,
      `Bite Sized feedback`,
      'n_study.bsf.title',
      `Bite Sized反馈`,
      `Bite Sized回饋`,
      `Bite Sized feedback`,
      ``,
    ],
    info1: [
      `튜터가 남겨준 피드백을 복습해요!`,
      `Review the feedback left by your tutor!`,
      'n_study.bsf.info1',
      `请複习教师提供的课后评价及回馈`,
      `請複習教師提供的課後評價及回饋`,
      `チューターからのフィードバックを確認しましょう！`,
      ``,
    ],
    info2: [
      `Bite Sized Feedback은 링글 앱에서만 사용이 가능합니다.`,
      `Bite Sized Feedback is only available on the Ringle app.`,
      'n_study.bsf.info2',
      `Bite Sized反馈只能在Ringle APP确认`,
      `Bite Sized回饋只能在Ringle APP確認`,
      `Bite Sized FeedbackはRingleアプリでのみ使用可能です。`,
      ``,
    ],
    btn_detail: [`자세히보기`, `Learn more`, 'n_study.bsf.btn_detail', `看更多`, `看更多`, `詳細はこちら`, ``],
  },
  reading_program: {
    title: [
      `신청 가능한 리딩 프로그램`,
      `Reading programs you can join`,
      'n_study.reading_program.title',
      `您可以参与的活动`,
      `您可以參與的活動`,
      `参加可能なリーディングプログラム`,
      ``,
    ],
  },
  material_list_new: {
    title: [
      `새로 나온 교재`,
      `New materials on trending topics are updated every day.`,
      'n_study.material_list_new.title',
      `最新教材`,
      `最新教材`,
      `新着教材`,
      ``,
    ],
    btn_all: [
      `새로운 교재 모두 보기`,
      `See latest materials`,
      'n_study.material_list_new.btn_all',
      `看所有最新教材`,
      `看所有最新教材`,
      `新着教材をみる`,
      ``,
    ],
  },
  activity: {
    btn_back: [`돌아가기`, `Back`, 'n_study.activity.btn_back', `上一页`, `上一頁`, `戻る`, ``],
    btn_skip1: [
      `듣기 건너뛰기`,
      `Skip listening`,
      'n_study.activity.btn_skip1',
      `跳过听力`,
      `跳過聽力`,
      `リスニングをスキップ`,
      ``,
    ],
    btn_skip2: [
      `읽기 건너뛰기`,
      `Skip reading`,
      'n_study.activity.btn_skip2',
      `跳过阅读`,
      `跳過閱讀`,
      `リーディングをスキップ`,
      ``,
    ],
    btn_done: [`완료`, `Done`, 'n_study.activity.btn_done', `完成`, `完成`, `完了`, ``],
    skip_txt1: [
      `액티비티를 건너뛸게요.`,
      `I'll skip this activity.`,
      'n_study.activity.skip_txt1',
      `我要跳过这个活动`,
      `我要跳過這個活動`,
      `この学習をスキップします。`,
      ``,
    ],
    skip_txt2: [
      `액티비티를 완료해야 데일리 학습을 완료할 수 있어요.`,
      `Come back this activity later to complete the daily study.`,
      'n_study.activity.skip_txt2',
      `请完成此活动以完成每日学习`,
      `請完成此活動以完成每日學習`,
      `この学習を完了することでデイリー学習が完了します。/この学習を完了することでデイリー学習が完了します。`,
      ``,
    ],
    success_txt1: [
      `잘했어요!`,
      `Good job!`,
      'n_study.activity.success_txt1',
      `太棒了！`,
      `太棒了！`,
      `お疲れさまでした！`,
      ``,
    ],
    success_txt2: [
      `다음 학습활동을 진행해보세요.`,
      `Now, let's do the next learning activity.`,
      'n_study.activity.success_txt2',
      `请进行下一题`,
      `請進行下一題`,
      `次の学習に進みましょう。`,
      ``,
    ],
    success_record: [
      `내 녹음 듣기`,
      `Play recording`,
      'n_study.activity.success_record',
      `听录音`,
      `聽錄音`,
      `再生する`,
      ``,
    ],
    fail_txt1: [
      `정답이 아니에요.`,
      `Incorrect answer.`,
      'n_study.activity.fail_txt1',
      `答错了`,
      `答錯了`,
      `不正解の回答`,
      ``,
    ],
    fail_txt2: [
      `틀린 문제는 나중에 다시 보여드릴게요!`,
      `We'll show you what you got wrong later!`,
      'n_study.activity.fail_txt2',
      `我们会告诉您答错的内容！`,
      `我們會告訴您答錯的內容！`,
      `間違った箇所はあとでチェックしましょう！`,
      ``,
    ],
    btn_speed_title: [`재생 속도`, `Speed`, 'n_study.activity.btn_speed_title', `速度`, `速度`, `再生速度`, ``],
    btn_speed1: [`느림`, `Slow`, 'n_study.activity.btn_speed1', `慢`, `慢`, `遅い`, ``],
    btn_speed2: [`보통`, `Normal`, 'n_study.activity.btn_speed2', `正常`, `正常`, `普通`, ``],
    btn_speed3: [`빠름`, `Fast`, 'n_study.activity.btn_speed3', `快`, `快`, `速い`, ``],
    txt_play: [
      `재생 중이에요.`,
      `Audio is being played`,
      'n_study.activity.txt_play',
      `播放中`,
      `播放中`,
      `再生中です。`,
      ``,
    ],
    btn_playagain: [
      `문장 다시 듣기`,
      `Listen again`,
      'n_study.activity.btn_playagain',
      `重听`,
      `重聽`,
      `もう一度聞く`,
      ``,
    ],
    btn_playstop: [`듣기 일시 정지`, `Pause audio`, 'n_study.activity.btn_playstop', `暂停`, `暫停`, `一時停止`, ``],
    txt_record: [
      `마이크를 눌러 녹음을 진행해주세요.`,
      `Press the mic button to start recording.`,
      'n_study.activity.txt_record',
      `请点麦克风图示进行录音`,
      `請點麥克風圖示進行錄音`,
      `マイクボタンを押して録音してください。`,
      ``,
    ],
    txt_record2: [
      `잘하고 있어요! 열심히 듣고 있어요!`,
      `You're doing great! Keep going!`,
      'n_study.activity.txt_record2',
      `您做得很好！请继续！`,
      `您做得很好！請繼續！`,
      `頑張っていますね！その調子です！`,
      ``,
    ],
    txt_recorddone: [
      `수고했어요! 녹음이 끝났어요.`,
      `Great job! You completed the activity.`,
      'n_study.activity.txt_recorddone',
      `做得太好了！您已完学习活动`,
      `做得太好了！您已完學習活動`,
      `お疲れさまでした！録音が完了しました。`,
      ``,
    ],
    txt_record3: [
      `녹음한 내용을 들어볼 수 있어요.`,
      `You can listen to your voice recording.`,
      'n_study.activity.txt_record3',
      `您可以听录音`,
      `您可以聽錄音`,
      `録音した内容を聞くことができます。`,
      ``,
    ],
    btn_playmyvoice: [
      `내 녹음 듣기`,
      `Play recording`,
      'n_study.activity.btn_playmyvoice',
      `听录音`,
      `聽錄音`,
      `録音を再生`,
      ``,
    ],
    txt_info1: [
      `항목 선택 후 ‘완료'를 눌러주세요.`,
      `Choose an answer, then click ‘Done.’`,
      'n_study.activity.txt_info1',
      `选择答案后点选'完成‘`,
      `選擇答案後點選'完成‘`,
      `項目選択後、「完了」を押してください。`,
      ``,
    ],
    placeholder_txt1: [
      `완벽하지 않아도 괜찮아요. 생각나는대로 적어보세요. (최소 20자 이상 입력)`,
      `It doesn't have to be perfect. Write down whatever comes to your mind. (minimum 20 characters)`,
      'n_study.activity.placeholder_txt1',
      `请依照想法写最少20字以上`,
      `請依照想法寫最少20字以上`,
      `完璧である必要はありません。思いついたことを書いてください。(20文字以上）`,
      ``,
    ],
    placeholder_txt2: [
      `완벽하지 않아도 괜찮아요. 생각나는대로 적어보세요. (최소 50자 이상 입력)`,
      `It doesn't have to be perfect. Write down whatever comes to your mind. (minimum 50 characters)`,
      'n_study.activity.placeholder_txt2',
      `请依照想法写最少50字以上`,
      `請依照想法寫最少50字以上`,
      `完璧である必要はありません。思いついたことを書いてください。(50文字以上）`,
      ``,
    ],
    answer_scope: [
      `공개범위`,
      `Who can see this`,
      'n_study.activity.answer_scope',
      `谁可以看见`,
      `誰可以看見`,
      `公開範囲`,
      ``,
    ],
    answer_scope_option1: [
      `모두보기`,
      `Everyone`,
      'n_study.activity.answer_scope_option1',
      `所有人`,
      `所有人`,
      `全ユーザー`,
      ``,
    ],
    answer_scope_option2: [
      `나만보기`,
      `Only Me`,
      'n_study.activity.answer_scope_option2',
      `只有我`,
      `只有我`,
      `自分のみ`,
      ``,
    ],
    answer_scope_option3: [
      `튜터와 나만 보기`,
      `Tutor and Me`,
      'n_study.activity.answer_scope_option3',
      `教师与我`,
      `教師與我`,
      `チューターと自分のみ`,
      ``,
    ],
    finish_title1: [
      `데일리 학습 완료`,
      `Daily study completed`,
      'n_study.activity.finish_title1',
      `完成每日学习`,
      `完成每日學習`,
      `デイリー学習完了`,
      ``,
    ],
    finish_title2: [
      `다시 풀어보세요.`,
      `Revisit what you missed.`,
      'n_study.activity.finish_title2',
      `请重试`,
      `請重試`,
      `もう一度チャレンジする。`,
      ``,
    ],
    finish_txt1: (name) => [
      `${name}님, 대단해요!\n준비된 학습을 모두 완료했어요!`,
      `Wow, ${name}!\nYou've completed every activity!`,
      'n_study.activity.finish_txt1',
      `${name}, 太棒了您已完成所有学习！`,
      `${name}, 太棒了您已完成所有學習！`,
      `準備した学習をすべて完了しました！ ${name} さん、さすがです！`,
      ``,
    ],
    finish_txt2: (name) => [
      `${name}님,\n오답이었거나, 건너뛴 학습이 있어요.\n다시 풀어보고 학습을 모두 완료해보세요.`,
      `${name},\nGo over incorrect answers or skipped\nactivities to complete this daily study.”`,
      'n_study.activity.finish_txt2',
      `${name},\n刚刚有回答错或是跳过的题目.\\请重新回答并完成学习.`,
      `${name},\n剛剛有回答錯或是跳過的題目.\\請重新回答並完成學習.`,
      `${name}さん、\n間違った答えがあったり、スキップした学習があります.\nすべての学習を完了してください。`,
      ``,
    ],
    finish_time: [
      `학습 완료까지 걸린 시간`,
      `Completion time`,
      'n_study.activity.finish_time',
      `距离完成学习的时间`,
      `距離完成學習的時間`,
      `学習完了までにかかった時間`,
      ``,
    ],
    finish_time_detail: (minute, second) => [
      `${minute}분${second}초`,
      `${minute}m ${second}s`,
      'n_study.activity.finish_time_detail',
      `${minute}分${second}秒`,
      `${minute}分${second}秒`,
      `${minute}分${second}秒`,
      ``,
    ],
    finish_date: [
      `연속 학습일`,
      `Study streak`,
      'n_study.activity.finish_date',
      `连续学习日`,
      `連續學習日`,
      `継続学習日`,
      ``,
    ],
    finish_date_detail: (week) => [
      `${week}일`,
      `${week} day`,
      'n_study.activity.finish_date_detail',
      `${week}日`,
      `${week}日`,
      `${week} 日`,
      ``,
    ],
    finish_btn_again: [
      `다시 풀어보기`,
      `Go over them now`,
      'n_study.activity.finish_btn_again',
      `再做一次`,
      `再做一次`,
      `もう一度チャレンジする。`,
      ``,
    ],
    skip_txt2_PC: [
      `액티비티를 완료해야 데일리 학습을 완료할 수 있어요.`,
      `Please note that you need to do this activity later to complete the daily study.`,
      'n_study.activity.skip_txt2_PC',
      `您必须完成此活动才能完成每日学习`,
      `您必須完成此活動才能完成每日學習`,
      `この学習を完了することでデイリー学習が完了します。`,
      ``,
    ],
    skip_txt2_app: [
      `액티비티를 완료해야\n데일리 학습을 완료할 수 있어요.`,
      `Please note that you need to do this activity later to complete the daily study.`,
      'n_study.activity.skip_txt2_app',
      `您必须完成此活动才能完成每日学习`,
      `您必須完成此活動才能完成每日學習`,
      `アクティビティを完了することで\nデイリー学習が完了します。`,
      ``,
    ],
    btn_readdone: [
      `다 읽었어요.`,
      `I'm done reading.`,
      'n_study.activity.btn_readdone',
      `阅读完毕`,
      `閱讀完畢`,
      `読み終わりました。`,
      ``,
    ],
    btn_playmyvoice_stop: [
      `일시정지`,
      `Pause`,
      'n_study.activity.btn_playmyvoice_stop',
      `暂停`,
      `暫停`,
      `一時停止`,
      ``,
    ],
    popup_title: [
      `데일리 학습을 그만두시겠어요?`,
      `Would you like to leave this daily study?`,
      'n_study.activity.popup_title',
      `您要离开每日学习吗？`,
      `您要離開每日學習嗎？`,
      `デイリー学習をやめますか？`,
      ``,
    ],
    popup_subtitle: [
      `지금 그만두면 완료한 학습까지만 저장됩니다.`,
      `If you leave, activities you have not completed will be reset.`,
      'n_study.activity.popup_subtitle',
      `如果现在离开，尚未完成的学习将初始化`,
      `如果現在離開，尚未完成的學習將初始化`,
      `今やめると、完了した学習までしか保存されません。`,
      ``,
    ],
    popup_btn_continue: [
      `계속 학습하기`,
      `Continue`,
      'n_study.activity.popup_btn_continue',
      `继续学习`,
      `繼續學習`,
      `学習を続ける`,
      ``,
    ],
    popup_btn_exit: [
      `학습 종료하기`,
      `Leave`,
      'n_study.activity.popup_btn_exit',
      `停止学习`,
      `停止學習`,
      `学習を終了する`,
      ``,
    ],
    tip_txt1: [`표현`, `expression`, 'n_study.activity.tip_txt1', `表达`, `表達`, `表現`, ``],
    finish_week_detail: (week) => [
      `${week}주`,
      `${week} week`,
      'n_study.activity.finish_week_detail',
      `${week}週`,
      `${week}週`,
      `${week}週`,
      ``,
    ],
    finish_week: [
      `연속 학습 주`,
      `Study streak`,
      'n_study.activity.finish_week',
      `连续学习`,
      `連續學習`,
      `継続学習週`,
      ``,
    ],
    insight_txt: (material_name, n) => [
      `출처: ${material_name}의 ${n}번 질문`,
      `Source: Question ${n} from ${material_name}`,
      'n_study.activity.insight_txt',
      `出处: ${material_name}的第${n}个问题`,
      `出處: ${material_name}的第${n}個問題`,
      `出典：${material_name} の ${n} 番の質問`,
      ``,
    ],
    placeholder_txt3: (n) => [
      `최소 ${n}자`,
      `minimum ${n} characters`,
      'n_study.activity.placeholder_txt3',
      `至少 ${n}字`,
      `至少 ${n}字`,
      `${n}文字以上`,
      ``,
    ],
    toast1: [
      `영어로만 입력할 수 있어요.`,
      `You can only type in English.`,
      'n_study.activity.toast1',
      `只能输入英文`,
      `只能輸入英文`,
      `英語のみ入力可能です。`,
      ``,
    ],
    finish_banner_title: [
      `데일리 학습 개선 의견 보내기`,
      `Send suggestions for improving Daily Study`,
      'n_study.activity.finish_banner_title',
      `提出每日学习活动改善建议`,
      `提出每日學習活動改善建議`,
      `デイリー学習の改善意見を送る`,
      ``,
    ],
    finish_banner_detail: [
      `데일리 학습을 사용하면서 개선되었으면 하는 부분이 있다면 말씀해 주세요.`,
      `If you have suggestions for improving Daily Study, please let us know.`,
      'n_study.activity.finish_banner_detail',
      `如果对于每日学习活动有任何建议请让我们知道`,
      `如果對於每日學習活動有任何建議請讓我們知道`,
      `デイリー学習について、改善してほしい点があれば教えてください。`,
      ``,
    ],
    finish_banner_btn: [
      `의견 작성하기`,
      `Give feedback`,
      'n_study.activity.finish_banner_btn',
      `填写意见`,
      `填寫意見`,
      `コメントを書く`,
      ``,
    ],
    form_title: [
      `데일리 학습 개선 의견`,
      `Suggestions for improving Daily Study`,
      'n_study.activity.form_title',
      `每日学习活动改善建议`,
      `每日學習活動改善建議`,
      `デイリー学習の改善意見`,
      ``,
    ],
    form_placeholder: [
      `학습의 난이도에 대한 의견이나 개선이 필요한 부분을 알려주세요.`,
      `Please enter the areas that needs improvement.`,
      'n_study.activity.form_placeholder',
      `请填写希望改善的地方`,
      `請填寫希望改善的地方`,
      `学習の難易度についてのご意見や改善すべき点を教えてください。`,
      ``,
    ],
    form_btn: [`제출하기`, `Submit`, 'n_study.activity.form_btn', `送出`, `送出`, `提出する`, ``],
    form_toast: [
      `정상적으로 제출되었어요.`,
      `Successfully submitted`,
      'n_study.activity.form_toast',
      `成功送出`,
      `成功送出`,
      `正常に提出されました。`,
      ``,
    ],
  },
  title: [`학습`, `Study`, 'n_study.title', `学习`, `學習`, `学習`, ``],
  popup_setting: {
    voice_title: [
      `마이크 사용 권한이 필요해요`,
      `Microphone access required`,
      'n_study.popup_setting.voice_title',
      `需要使用麦克风权限`,
      `需要使用麥克風權限`,
      `マイクの使用許可が必要です`,
      ``,
    ],
    voice_info: [
      `링글의 데일리 학습을 진행하려면\n기기설정에서 마이크 사용을 ‘허용’해주세요.`,
      `Please allow microphone access to continue Ringle daily study.`,
      'n_study.popup_setting.voice_info',
      `请开启麦克风使用权限以进行每日学习活动`,
      `請開啟麥克風使用權限以進行每日學習活動`,
      `Ringleのデイリー学習を進めるには、\n機器設定でマイクの使用を「許可」してください。`,
      ``,
    ],
    voice_button1: [
      `기기 설정하기`,
      `Go to device settings`,
      'n_study.popup_setting.voice_button1',
      `前往设定`,
      `前往設定`,
      `端末の設定`,
      ``,
    ],
  },
  btn_date_1: [`월`, `Mon`, 'n_study.btn_date_1', `一`, `一`, `月`, ``],
  btn_date_2: [`화`, `Tue`, 'n_study.btn_date_2', `二`, `二`, `火`, ``],
  btn_date_3: [`수`, `Wed`, 'n_study.btn_date_3', `三`, `三`, `水`, ``],
  btn_date_4: [`목`, `Thu`, 'n_study.btn_date_4', `四`, `四`, `木`, ``],
  btn_date_5: [`금`, `Fri`, 'n_study.btn_date_5', `五`, `五`, `金`, ``],
  info_user1: (number, minute) => [
    `${number}명이 평균 ${minute}분에 학습을 완료했어요.`,
    `${number} people completed the daily study in ${minute} mins on average.`,
    'n_study.info_user1',
    `有${number}位平均以${minute}分钟完成每日学习活动。`,
    `有${number}位平均以${minute}分鐘完成每日學習活動。`,
    `${number}人が平均 ${minute}分で学習を完了しました。`,
    ``,
  ],
  info_user2: [
    `첫번째로 학습을 완료해보세요!`,
    `Be the first to complete the daily study!`,
    'n_study.info_user2',
    `请完成第一个完成每日学习活动的人！`,
    `請完成第一個完成每日學習活動的人！`,
    `デイリー学習を完了させましょう！`,
    ``,
  ],
  complete: {
    customer: [`고객`, `Customer`, 'n_study.complete.customer', `用户`, `用戶`, `お客様`, ``],
  },
  finish_detail_info: [
    `아직 완료할 수 없어요.`,
    `Today’s study isn’t complete yet.`,
    'n_study.finish_detail_info',
    `今日学习活动尚未完成`,
    `今日學習活動尚未完成`,
    `まだ完了していません。`,
    ``,
  ],
}
export const n_zoom_guide = {
  title: [
    `Zoom 사용 안내`,
    `How to use Zoom`,
    'n_zoom_guide.title',
    `Zoom 使用指南`,
    `Zoom 使用指南`,
    `Zoom利用案内`,
    ``,
  ],
  tap_1: [
    `다운로드 및 설치`,
    `Download & Install`,
    'n_zoom_guide.tap_1',
    `下载和安装`,
    `下載和安裝`,
    `ダウンロードとインストール`,
    ``,
  ],
  tap_2: [`수업 입장`, `Entering a Lesson`, 'n_zoom_guide.tap_2', `进入课程`, `進入課程`, `レッスンに参加`, ``],
  tap_3: [`장치 테스트`, `Test Your Device`, 'n_zoom_guide.tap_3', `设备测试`, `設備測試`, `端末テスト`, ``],
  section_1: {
    title: [
      `다운로드 및 설치`,
      `Download & Install`,
      'n_zoom_guide.section_1.title',
      `下载和安装`,
      `下載和安裝`,
      `ダウンロードとインストール`,
      ``,
    ],
    desc: [
      `링글의 수업은 화상 대화 프로그램 Zoom에서 진행됩니다.
다운로드 버튼을 클릭해 파일을 다운로드하고 내 컴퓨터에 설치하세요.`,
      `Ringle lessons are held on Zoom, which is a video conferencing app.
Click the button below to download and install the app on your device.`,
      'n_zoom_guide.section_1.desc',
      `Ringle的课程将通过视频通话软件Zoom进行。
请点击下载按钮下软件并在您的电脑上安装。`,
      `Ringle的課程將通過視訊軟體Zoom進行。
請點擊下載按鈕下載程式並在您的電腦上安裝。`,
      `Ringleのレッスンは、ビデオ通話プログラムZoomで行われます。
 ダウンロードボタンをクリックしてファイルをダウンロードし、端末にインストールしてください。`,
      ``,
    ],
    btn: [
      `Zoom 다운로드`,
      `Download Zoom`,
      'n_zoom_guide.section_1.btn',
      `Zoom 下载`,
      `Zoom 下載`,
      `Zoomのダウンロード`,
      ``,
    ],
  },
  section_2: {
    title: [
      `수업 입장`,
      `Entering a Lesson`,
      'n_zoom_guide.section_2.title',
      `进入课程`,
      `進入課程`,
      `レッスンに参加`,
      ``,
    ],
    desc: [
      `수업 시작 전 Zoom회원가입 및 미팅 참여 절차가 필요하지 않아요.`,
      `You do not need to register as a Zoom member or  to enter a Ringle lesson.`,
      'n_zoom_guide.section_2.desc',
      `在开始课程之前，不需要进行Zoom会员注册和会议参与流程。`,
      `在開始課程之前，不需要進行Zoom會員註冊和會議參與流程。`,
      `レッスン開始前にZoom会員登録やミーティング参加の手続きをする必要ありません。`,
      ``,
    ],
    setp_1: [
      `수업 입장을 누르고 zoom.us 열기를 클릭하세요.
회의 기록 안내가 나타나면 확인을 클릭하세요.`,
      `Click on [Enter Lesson], then click [Allow] to allow the website to open "zoom.us."
When the popup informing you that the meeting is being recorded appears, click [Continue].`,
      'n_zoom_guide.section_2.setp_1',
      `点击‘进入课程’，然后点击‘打开 zoom.us’。 
如果出现会议记录提示，请点击‘确认’。`,
      `點‘進入課程’，然後‘打開 zoom.us’。 如果出現會議記錄提示，請點‘確認’。`,
      `「レッスンに参加」をクリックし、「zoom.us を開く」をクリックしてください。
 ミーティングが録画されていることを知らせるポップアップが表示されたら、[続行]をクリックします。`,
      ``,
    ],
    step_2: [
      `Zoom 좌측 하단 [오디오 연결]을 클릭하세요.`,
      `Click [Join Audio] at the bottom left of the Zoom screen.`,
      'n_zoom_guide.section_2.step_2',
      `在Zoom左下方点击‘[连接音频]’。`,
      `在Zoom左下方點‘[連接音訊]’。`,
      `Zoom画面左下の「オーディオに接続」をクリックしてください。`,
      ``,
    ],
    caption_1: [
      `오디오 연결 확인 후 [컴퓨터 오디오로 참가]를 클릭하세요.`,
      `After checking the audio connection, click [Join With Computer Audio].`,
      'n_zoom_guide.section_2.caption_1',
      `连接音频后，请点击‘[以计算机音频参加]’。`,
      `連接音訊後，請點‘[以電腦音訊參加]’。`,
      `「コンピューターでオーディオに参加」をクリックしてください。`,
      ``,
    ],
    step_3: [
      `음소거 표시를 확인하세요.`,
      `Make sure your audio is not muted.`,
      'n_zoom_guide.section_2.step_3',
      `确保您不是处于静音模式`,
      `確保您不是處於靜音模式`,
      `ミュートになっていないか確認してください。`,
      ``,
    ],
    caption_2: [
      `오디오/비디오 연결 성공`,
      `Audio/video connection successful`,
      'n_zoom_guide.section_2.caption_2',
      `音频/视频连接成功`,
      `音訊/影像連接成功`,
      `オーディオ/ビデオ接続成功`,
      ``,
    ],
    caption_3: [
      `오디오 연결되지 않음`,
      `Audio is not connected`,
      'n_zoom_guide.section_2.caption_3',
      `音频未连接`,
      `音訊未連接`,
      `オーディオが接続されていない`,
      ``,
    ],
    caption_4: [
      `음소거 및 비디오 끔`,
      `Audio and video are turned off`,
      'n_zoom_guide.section_2.caption_4',
      `音频和视频已关闭`,
      `音訊和影像已關閉`,
      `オーディオとビデオはオフ`,
      ``,
    ],
  },
  section_3: {
    title: [
      `장치 테스트`,
      `Test Your Device`,
      'n_zoom_guide.section_3.title',
      `测试你的设备`,
      `測試你的設備`,
      `端末テスト`,
      ``,
    ],
    setp_1: [
      <>
        <a target="_blank" rel="noreferrer" href="https://zoom.us/test" className="text-purple-500 text-underline">
          Zoom 회의 참가 테스트
        </a>
        를 진행하세요.
      </>,
      <>
        <a target="_blank" rel="noreferrer" href="https://zoom.us/test" className="text-purple-500 text-underline">
          Join a test meeting{' '}
        </a>
        on Zoom..
      </>,
      'n_zoom_guide.section_3.setp_1',
      <>
        <a target="_blank" rel="noreferrer" href="https://zoom.us/test" className="text-purple-500 text-underline">
          请进行Zoom
        </a>
        测试.
      </>,
      <>
        <a target="_blank" rel="noreferrer" href="https://zoom.us/test" className="text-purple-500 text-underline">
          請進行Zoom
        </a>
        測試.
      </>,
      <>
        <a target="_blank" rel="noreferrer" href="https://zoom.us/test" className="text-purple-500 text-underline">
          Zoomミーティング参加テスト
        </a>
        を実施してください。
      </>,
      ``,
    ],
    caption_1: [
      `페이지 이동 후 참가 버튼을 클릭하고 zoom.us를 열어주세요.`,
      `When the webpage opens, click on [Join], then allow the website to open "zoom.us.`,
      'n_zoom_guide.section_3.caption_1',
      `点击‘进入课程’，然后点击‘打开 zoom.us’。`,
      `點‘進入課程’，然後‘打開 zoom.us’`,
      `ページ移動後、「参加」ボタンをクリックしてzoom.usを開いてください。`,
      ``,
    ],
    setp_2: [
      `컴퓨터 오디오로 참가를 클릭하세요.`,
      `Click on [Join With Computer Audio].`,
      'n_zoom_guide.section_3.setp_2',
      `点击"以计算机音频参加`,
      `點選 "以電腦音訊參加`,
      `「コンピューターでオーディオに参加」をクリックしてください。`,
      ``,
    ],
    setp_3: [
      `순서에 따라 테스트를 진행하고, 장치가 올바르게 작동하는지 확인하세요.
장치가 작동하지 않는다면, 다른 장치로 설정해보세요.`,
      `Test your device as instructed and ensure that the device is functioning properly.
If your device isn't working properly, try setting it up with a different device.`,
      'n_zoom_guide.section_3.setp_3',
      `按照顺序进行测试，确保设备正常工作。
如果设备无法正常工作，请尝试使用其他设备。`,
      `"按照順序進行測試，確保設備正常運作。
如果設備無法正常運作，請嘗試使用其他設備。`,
      `手順に従ってテストを行い、端末が正しく動作することを確認してください。
 端末が動作しない場合は、他の端末で設定してみてください。`,
      ``,
    ],
    caption_2: [
      `카메라, 스피커, 마이크에 체크 표시가 되었는지 확인하세요.`,
      `Make sure that all components (camera, speakers, and microphone) are checked successfully.`,
      'n_zoom_guide.section_3.caption_2',
      `请确认摄像头/喇叭/麦克风都已勾选`,
      `請確認相機/喇叭/麥克風都已勾選`,
      `カメラ、スピーカー、マイクがチェックされていることを確認してください。`,
      ``,
    ],
  },
}
export const s_black_friday = {
  marketing_modal: {
    title: [
      `지금 이벤트 및 혜택 소식 수신 모두 동의하면 100% 선물을 드려요!`,
      `100% gift if you agree to receive all event news`,
      's_black_friday.marketing_modal.title',
      `现在，如果您同意接收活动和优惠消息，我们将赠送您礼物！`,
      `100% gift if you agree to receive all event news`,
      `今すぐイベントや特典情報の受信に同意すると、100%プレゼントがもらえます!`,
      `100% gift if you agree to receive all event news`,
    ],
    btn_disagree: [`아니오`, `No`, 's_black_friday.marketing_modal.btn_disagree', `不同意`, `不同意`, `いいえ`, `No`],
    btn_agree: [
      `수신 동의하기`,
      `Opt-in`,
      's_black_friday.marketing_modal.btn_agree',
      `同意`,
      `同意`,
      `受信に同意する`,
      `Opt-in`,
    ],
  },
  marketing_modal_agree: {
    content: [
      `이벤트 및 혜택 소식 수신에 감사합니다. 1월 5일에 추첨결과를 알려드릴게요. 단, 1월 5일 전에 마케팅 수신 동의 해지시 혜택을 받을 수 없어요.`,
      `Thanks for signing up to hear about our events and offers. We'll let you know the winner on Jan. 5, but if you unsubscribe from marketing before Jan. 5, you won't be eligible for the offer.`,
      's_black_friday.marketing_modal_agree.content',
      `感谢同意接收活动和优惠消息。我们将于1月5日公布抽奖结果。请注意，如果在1月5日之前取消营销信息接收同意，您将无法获得优惠。`,
      `感謝同意接收活動和優惠消息。我們將於1月5日公布抽獎結果。請註意，如果在1月5日之前取消營銷信息接收同意，您將無法獲得優惠。`,
      `イベントや特典のお知らせを受信していただきありがとうございます。1月5日に抽選結果をお知らせします。 ただし、1月5日までにマーケティングの受信同意を解除すると、特典を受け取ることができません。`,
      `Thanks for signing up to hear about our events and offers. We'll let you know the winner on Jan. 5, but if you unsubscribe from marketing before Jan. 5, you won't be eligible for the offer.`,
    ],
  },
  sign_up_content: [
    <>
      *이벤트 및 혜택 소식 수신 동의 후 가입하신 경우, 스타벅스 쿠폰(300명), 링글 20분 수업권(300명), 2만 포인트 중
      하나를 추첨으로 드립니다.
      <br />
      *경품은 1월 5일에 참여한 모든 분들께 일괄 지급될 예정입니다.
    </>,
    <>
      *If you signed up to receive events and offers, you will be entered into a drawing for one of the following: a
      Starbucks coupon (300 winners), a 20 min Ringle Credit (300 winners), or $15 Ringle points (all remaining).
      <br />
      *We’ll let you know the winner on Jan. 5th
    </>,
    's_black_friday.sign_up_content',
    <>
      *If you signed up to receive events and offers, you will be entered into a drawing for one of the following: a
      Starbucks coupon (300 winners), a 20 min Ringle Credit (300 winners), or $15 Ringle points (all remaining).
      <br />
      *We’ll let you know the winner on Jan. 5th
    </>,
    <>
      *If you signed up to receive events and offers, you will be entered into a drawing for one of the following: a
      Starbucks coupon (300 winners), a 20 min Ringle Credit (300 winners), or $15 Ringle points (all remaining).
      <br />
      *We’ll let you know the winner on Jan. 5th
    </>,
    <>
      *イベントや特典のニュースの受信に同意して登録した場合、スターバックスクーポン(300名)、Ringle20分レッスンチケット(300名)、2万ポイントのいずれかを抽選でプレゼントします。
      *プレゼントは1月5日に参加された方全員に一括支給される予定です
    </>,
    <>
      *If you signed up to receive events and offers, you will be entered into a drawing for one of the following: a
      Starbucks coupon (300 winners), a 20 min Ringle Credit (300 winners), or $15 Ringle points (all remaining).
      <br />
      *We’ll let you know the winner on Jan. 5th
    </>,
  ],
}
export const n_purchase = {
  title: [`결제`, `Purchase`, 'n_purchase.title', `付款`, `付款`, `お支払い内容`, `Thanh toán`],
  your_cart: {
    title: [
      `주문상품`,
      `Your cart`,
      'n_purchase.your_cart.title',
      `订购的商品`,
      `訂購的商品`,
      `購入内容`,
      `Giỏ hàng của bạn`,
    ],
    package: [
      `상품명`,
      `Package`,
      'n_purchase.your_cart.package',
      `课程券名称`,
      `課程券名稱`,
      `レッスンチケット`,
      `Gói`,
    ],
    duration: [
      `수업길이`,
      `Duration`,
      'n_purchase.your_cart.duration',
      `课时长度`,
      `課程長度`,
      `レッスン時間`,
      `Thời lượng`,
    ],
    min: (minute) => [
      `${minute}분`,
      `${minute} min`,
      'n_purchase.your_cart.min',
      `${minute}分钟`,
      `${minute}分鐘`,
      `${minute}分`,
      `${minute} phút`,
    ],
    expiration: [
      `수강기간`,
      `Expiration dates`,
      'n_purchase.your_cart.expiration',
      `使用期限`,
      `使用期限`,
      `有効期限`,
      `Ngày hết hạn`,
    ],
    days: (day) => [
      `${day}일`,
      `${day} days`,
      'n_purchase.your_cart.days',
      `${day}日`,
      `${day}天`,
      `${day}日`,
      `${day} ngày`,
    ],
    none_exp1: [
      `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
      `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
      'n_purchase.your_cart.none_exp1',
      `请注意课程券的使用期限不是从购买日开始扣除，而是从完成首次课程时开始扣除。`,
      `請註意課程券的使用期限不是從購買日開始扣除，而是從完成首次課程時開始扣除。`,
      `受講期間が始まる時点は購入日ではなく、初回レッスンを完了した時点からであることをご了承ください。`,
      `Ngày hết hạn được tính bắt đầu từ ngày bạn hoàn thành buổi học đầu tiên trong gói học đã thanh toán.`,
    ],
    none_exp2: [
      `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
      `Unused credits' expiration dates will start counting down a year after the purchase date.`,
      'n_purchase.your_cart.none_exp2',
      `购买后一年期间，于完成首次课程时扣除使用期限。`,
      `購買後一年期間，於完成首次課程時扣除使用期限。`,
      `レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。`,
      `Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.`,
    ],
    every_week_exp1: [
      `수업권 구매 즉시 수강기간 차감`,
      `Starts expiring immediately after purchase`,
      'n_purchase.your_cart.every_week_exp1',
      `购买后立即開始扣除课程券使用時間`,
      `購買後立即開始扣除課程券使用時間`,
      `購入直後、受講期間が差し引かれます。`,
      `Thời gian khóa học được khấu trừ ngay khi mua`,
    ],
    every_week_exp2: [
      `매주 1회 수업 필수 (미 수강 시 수업권 자동 차감)`,
      `Must take at least one lesson per week (1 credit automatically deducted each week)`,
      'n_purchase.your_cart.every_week_exp2',
      `每周必须上课1次（未上课将自动扣除课程券）`,
      `每週必須上課1次（未上課將自動扣除課程券）`,
      `週に1回の授業は必須（受講しない場合は自動にレッスンチケットが差し引かれます）`,
      `Lớp học bắt buộc mỗi tuần một lần`,
    ],
    benefit: [`혜택`, `Benefit`, 'n_purchase.your_cart.benefit', `优惠`, `優惠`, `特典`, ``],
    limited_benefit: (remainTime, amount) => [
      <>
        {remainTime} 내 구매시 {amount}회 추가
      </>,
      <>
        {amount} bonus credits if purchased in {remainTime}
      </>,
      'n_purchase.your_cart.limited_benefit',
      <>
        在{remainTime}前购买，多送 {amount}次
      </>,
      <>
        在{remainTime}前購買，多送 {amount}次
      </>,
      <>
        {remainTime}以内に購入すると{amount}回分追加されます。
      </>,
      ``,
    ],
  },
  point: {
    title: [`포인트`, `Points`, 'n_purchase.point.title', `积分`, `點數`, `ポイント`, `Điểm`],
    a_point: [
      `사용 가능한 포인트`,
      `Available points`,
      'n_purchase.point.a_point',
      `可使用的积分`,
      `可使用的點數`,
      `使用可能なポイント`,
      `Available points`,
    ],
    btn_point_all: [
      `전액 사용`,
      `Use all`,
      'n_purchase.point.btn_point_all',
      `全部使用`,
      `全部使用`,
      `すべて利用`,
      `Sử dụng hết`,
    ],
    max: [`최대 사용됨`, `Max. applied`, 'n_purchase.point.max', `可使用积分`, `可使用點數`, `利用可能分`, ``],
    al_max: [
      `항상 최대 사용`,
      `Always apply maximum applicable`,
      'n_purchase.point.al_max',
      `总是全部使用`,
      `總是全部使用`,
      `利用可能分を常に使用する`,
      ``,
    ],
  },
  payment: {
    title: [
      `결제 방식`,
      `Payment method`,
      'n_purchase.payment.title',
      `选择付款方式`,
      `選擇付款方式`,
      `お支払い方法`,
      `Chọn phương thức thanh toán`,
    ],
    switch_km: [
      `한국 결제 방식으로 바꾸기`,
      `Switch to Korean payment method`,
      'n_purchase.payment.switch_km',
      `转换为韩国支付方式`,
      `轉換為韓國支付方式`,
      `韓国の決済方式に変更する`,
      `Chuyển phương thức thanh toán Hàn Quốc`,
    ],
    switch_lm: [
      `해외 결제 방식으로 바꾸기`,
      `Switch to local payment method`,
      'n_purchase.payment.switch_lm',
      `转换为本地支付方式`,
      `轉換為本地支付方式`,
      `現地の決済方式に変更する`,
      `Chuyển phương thức thanh toán địa phương`,
    ],
    alert_year_long: [
      `1년 수업권 결제시 [카드 일반결제/토스페이/가상계좌 발급]만 지원되고 있습니다. 2개 카드 결제 또한 가능하지 않습니다.`,
      `For year-long credits: Only [Credit card/Toss Pay/Virtual Account] are accepted.`,
      'n_purchase.payment.alert_year_long',
      `1年课程券结算: 只支持一次性结算，无法分期付款`,
      `購買1年課程券: 只能1次全額付款，無法分期付款`,
      `一年のレッスンチケット決済: 一般決済のみ支援しております。分割払い決済は適応されません。`,
      `Không chia nhỏ phần thanh toán: Với gói học một năm, bạn phải thanh toán một lần`,
    ],
    easy_title: [`간편 결제`, `Digital wallet`, 'n_purchase.payment.easy_title', ``, `簡便付款`, `電子決済`, ``],
    naver_pay: [
      `네이버페이(카드결제)`,
      `NaverPay`,
      'n_purchase.payment.naver_pay',
      `Naver Pay(银行卡付款)`,
      `Naver Pay（信用卡付款）`,
      `NaverPay(カード決済)`,
      `NaverPay`,
    ],
    payco: [`페이코`, `PAYCO`, 'n_purchase.payment.payco', `PAYCO`, `PAYCO`, `PAYCO`, `PAYCO`],
    kakao_pay: [
      `카카오페이`,
      `KakaoPay`,
      'n_purchase.payment.kakao_pay',
      `KakaoPay`,
      `KakaoPay`,
      `KakaoPay`,
      `KakaoPay`,
    ],
    samsung_pay: [
      `삼성페이`,
      `SamsungPay`,
      'n_purchase.payment.samsung_pay',
      `SamsungPay`,
      `SamsungPay`,
      `SamsungPay`,
      `SamsungPay`,
    ],
    toss_pay: [`토스페이`, `Toss Pay`, 'n_purchase.payment.toss_pay', `Toss Pay`, `Toss Pay`, `Toss Pay`, `Toss Pay`],
    card_title: [
      `카드 결제`,
      `Credit card`,
      'n_purchase.payment.card_title',
      `银行卡付款`,
      `信用卡付款`,
      `カード決済`,
      ``,
    ],
    card: [
      `카드 일반 결제`,
      `Credit card`,
      'n_purchase.payment.card',
      `银行卡一般付款`,
      `信用卡一般付款`,
      `カード一般決済`,
      `Thẻ tín dụng`,
    ],
    card_manual: [
      `카드 직접 입력`,
      `Credit card (manual)`,
      'n_purchase.payment.card_manual',
      `直接输入银行卡号`,
      `手動輸入信用卡號`,
      `カード直接入力`,
      `Thẻ tín dụng`,
    ],
    two_cards: [
      `2개 카드 결제`,
      `Pay with two cards`,
      'n_purchase.payment.two_cards',
      `双卡付款`,
      `用2張信用卡付款`,
      `2枚のカードで決済`,
      `Trả với hai thẻ`,
    ],
    two_cards_exp: [
      `2개 카드 결제: 최종 결제 금액을 2개의 카드로 분할 결제하는 방식입니다. (국내 카드로 일시불 결제만 가능)`,
      `[Pay with 2 cards] - Divide total payment into two credit cards (Korean credit cards only).`,
      'n_purchase.payment.two_cards_exp',
      `2张卡付款"，将支付金额分开使用两张卡支付，仅限使用韩国国内的卡，并只能进行一次性支付。`,
      `2張卡付款"，將支付金額分開使用兩張卡支付，僅限使用韓國國內的卡，並只能進行一次全額付款。`,
      `「2枚カード決済」は、2枚のクレジットカードで決済金額を分割する決済方法で、韓国のクレジットカードでの一括払いのみ可能です。`,
      ``,
    ],
    transfer_title: [
      `계좌 이체`,
      `Bank transfer`,
      'n_purchase.payment.transfer_title',
      `银行转帐`,
      `銀行轉帳`,
      `口座振替`,
      ``,
    ],
    transfer_title2: [
      `가상 계좌 발급`,
      `Virtual Account`,
      'n_purchase.payment.transfer_title2',
      `虚拟账号`,
      `虛擬帳號`,
      `仮想口座発行`,
      `Virtual Account`,
    ],
    pay_with_card: [
      `Pay with Card`,
      `Pay with Card`,
      'n_purchase.payment.pay_with_card',
      `用卡片付款`,
      `用卡片付款`,
      `カードで支払う`,
      `Pay with Card`,
    ],
    alert_year_long_new: [
      `1년 수업권 결제 시, 간편 결제는 토스페이만 지원합니다.`,
      ``,
      'n_purchase.payment.alert_year_long_new',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder: [
      `선택해주세요`,
      `Please choose.`,
      'n_purchase.payment.placeholder',
      `请选择支付方式`,
      `請選擇支付方式`,
      `選択してください`,
      ``,
    ],
    km: [`국내 결제 방식`, `Korean`, 'n_purchase.payment.km', `韩国支付方式`, `韓國支付方式`, `韓国の支払方法`, ``],
    lm: [
      `해외 결제 방식`,
      `Local (non-Korean)`,
      'n_purchase.payment.lm',
      `海外支付方式`,
      `海外支付方式`,
      `韓国以外の支払方法`,
      ``,
    ],
    only_toss: [
      `1년 수업권 결제 시, 간편결제는 토스페이만 지원합니다.`,
      `Toss Pay is the only e-wallet payment supported for purchasing this credit.`,
      'n_purchase.payment.only_toss',
      `购买一年课程券时，简易支付只支持Toss`,
      `購買一年課程券時，簡易支付只支援Toss`,
      `1年分のレッスンチケットを購入する場合、決済アプリはToss Payのみ使用できます。`,
      ``,
    ],
    dollar_info: [
      `해외 결제 방식 선택 시, 미국 달러로 결제됩니다.`,
      `The purchase will be made in US dollars if you use a local (non-Korean) payment method.`,
      'n_purchase.payment.dollar_info',
      `选择海外支付方式，将以美金结算`,
      `選擇海外支付方式，將以美金結算`,
      `韓国以外の支払方法を選択した場合、米国ドル建で決済されます。`,
      ``,
    ],
    error: [
      `결제 방식을 선택하세요.`,
      `Select a payment method.`,
      'n_purchase.payment.error',
      `Select a payment method.`,
      `Select a payment method.`,
      `Select a payment method.`,
      ``,
    ],
    install_free: [
      `무이자 6개월`,
      `6-month plan (0% interest)`,
      'n_purchase.payment.install_free',
      `6-month plan (0% interest)`,
      `6-month plan (0% interest)`,
      `6-month plan (0% interest)`,
      ``,
    ],
    title_choose: [
      `결제 방식을 선택해주세요`,
      `Choose your payment method.`,
      'n_purchase.payment.title_choose',
      `Choose your payment method.`,
      `Choose your payment method.`,
      `Choose your payment method.`,
      ``,
    ],
    korean_card: [
      `국내 카드 결제`,
      `Pay with a Korean credit card`,
      'n_purchase.payment.korean_card',
      `Pay with a Korean credit card`,
      `Pay with a Korean credit card`,
      `Pay with a Korean credit card`,
      ``,
    ],
    foreign_card: [
      `해외 카드 결제`,
      `Pay with a local credit card`,
      'n_purchase.payment.foreign_card',
      `Pay with a local credit card`,
      `Pay with a local credit card`,
      `Pay with a local credit card`,
      ``,
    ],
    explanation_usd: [
      `미국 달러로 결제됩니다.`,
      `Payment will be made in US dollars.`,
      'n_purchase.payment.explanation_usd',
      `Payment will be made in US dollars.`,
      `Payment will be made in US dollars.`,
      `Payment will be made in US dollars.`,
      ``,
    ],
    select: [`선택`, `Next`, 'n_purchase.payment.select', `Next`, `Next`, `Next`, ``],
    sam_card: [
      `삼성카드`,
      `Samsung Card`,
      'n_purchase.payment.sam_card',
      `Samsung Card`,
      `Samsung Card`,
      `Samsung Card`,
      `Samsung Card`,
    ],
  },
  subtotal: {
    title: [`결제금액`, `Subtotal`, 'n_purchase.subtotal.title', `付款金额`, `付款金額`, `決済金額`, `Tổng`],
    regular: [`정가`, `Regular price`, 'n_purchase.subtotal.regular', `定价`, `定價`, `通常価格`, `Học phí tiêu chuẩn`],
    discount: [
      `할인 금액`,
      `Total discount`,
      'n_purchase.subtotal.discount',
      `总折扣金额`,
      `總折扣金額`,
      `割引分合計`,
      `Tổng giảm giá`,
    ],
    point: [
      `포인트 사용`,
      `Points applied`,
      'n_purchase.subtotal.point',
      `使用积分`,
      `使用點數`,
      `ポイント利用`,
      `Điểm được áp dụng`,
    ],
    total: [
      `최종 결제 금액`,
      `Your total`,
      'n_purchase.subtotal.total',
      `实际付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      `Tổng cộng`,
    ],
    estimated_points: [
      `예상 적립 포인트`,
      `Estimated points`,
      'n_purchase.subtotal.estimated_points',
      `预计积累积分`,
      `預計累積點數`,
      `獲得予定ポイント`,
      `Điểm ước tính`,
    ],
    explanation_usd: [
      `최종 결제는 미국 달러로 결제됩니다.`,
      `Payments will be processed in USD.`,
      'n_purchase.subtotal.explanation_usd',
      `付款将以美金支付。`,
      `付款將以美金支付。`,
      `米ドルでの決済になります。`,
      `Được thanh toán bằng USD.`,
    ],
    agreement: [
      `결제 내역을 확인하였으며, 링글 정책에 동의합니다.`,
      `I have checked the purchase details and consent to Ringle's policy.`,
      'n_purchase.subtotal.agreement',
      `确认购买内容并同意Ringle的使用政策`,
      `確認購買內容並同意Ringle的使用政策`,
      `支払内容を確認し、Ringleの規約に同意します。`,
      ``,
    ],
    discount_l_1: [`1:1 수업권 패키지 할인`, ``, 'n_purchase.subtotal.discount_l_1', ``, ``, ``, ``],
    discount_l_2: [`1:1 수업권 매주 수업 듣기 할인`, ``, 'n_purchase.subtotal.discount_l_2', ``, ``, ``, ``],
    discount_l_3: [`1:1 수업권 이벤트 할인`, ``, 'n_purchase.subtotal.discount_l_3', ``, ``, ``, ``],
    discount_a_1: [`AI 튜터 패키지 할인`, ``, 'n_purchase.subtotal.discount_a_1', ``, ``, ``, ``],
    discount_a_2: [`AI 튜터 이벤트 할인`, ``, 'n_purchase.subtotal.discount_a_2', ``, ``, ``, ``],
  },
  agreement: {
    checklist: [
      <>
        결제 내역을 확인하였으며{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline underline-offset-1 text-purple-500">전자상거래법 및 링글 정책</span>
        </LinkLocale>
        에 의거하여 환불이 진행되는 것에 동의합니다.
      </>,
      <>
        I confirm my purchase and agree to{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline underline-offset-1 text-purple-500">Ringle's terms and conditions</span>
        </LinkLocale>
        .
      </>,
      'n_purchase.agreement.checklist',
      <>
        已确认付款明细，同意依据{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">电子商务交易法及Ringle政策</span>
        </LinkLocale>
        进行退款。{' '}
      </>,
      <>
        已確認付款明細，同意依據{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">電子商務交易法及Ringle政策</span>
        </LinkLocale>
        進行退款。
      </>,
      <>
        決済履歴を確認し、{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">電子商取引法およびRingleのポリシー</span>
        </LinkLocale>
        に基づいて返金が行われることに同意します。
      </>,
      <>
        Tôi xác nhận thanh toán của mình và đồng ý với{' '}
        <LinkLocale href={urls.student.policy.all} target="_blank">
          <span className="underline text-purple-500">Điều khoản và điều kiện của Ringle</span>
        </LinkLocale>
        .
      </>,
    ],
  },
  btn_submit_order: [
    `결제하기`,
    `Submit order`,
    'n_purchase.btn_submit_order',
    `付款`,
    `付款`,
    `決済する`,
    `Hoàn thành`,
  ],
  point_info: {
    title: [
      `포인트 안내`,
      `Points info`,
      'n_purchase.point_info.title',
      `积分详情`,
      `點數詳情`,
      `ポイントの案内`,
      `Điểm tích lũy`,
    ],
  },
  point_info_desc: (point, availablePoint) => [
    <>
      내 보유 포인트: {point}P<br />
      <div className="flex">
        <div className="mr-[2px]">사용 가능 포인트: </div>
        <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      포인트 종류에 따라 수업권 구매 시 사용 가능 여부가 달라집니다.
      <br />
      <li className="li-tag-inside">수업 완료 후 받은 포인트 : 결제 금액과 무관하게 사용 가능</li>
      <li className="li-tag-inside">
        친구 추천 및 이벤트를 통해 받은 포인트 : 결제 금액이 30만원 이상 되는 경우 사용 가능
      </li>
      <br />
      결제사 정책에 의해 1,000원 미만으로 결제는 불가능합니다. 보유하신 포인트에서 최소 1,000원을 남겨두고 사용해
      주세요.
    </>,
    <>
      Your points total: {point}P<br />
      <div className="flex">
        Redeemable points: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      Points can be redeemed toward purchasing credits as follows.
      <br />
      <li className="li-tag-inside">
        Points received for completing lessons: Can be redeemed regardless of payment total
      </li>
      <li className="li-tag-inside">
        Points received through referrals and events: Can be redeemed when the payment total is over $230.76
      </li>
    </>,
    'n_purchase.point_info_desc',
    <>
      我的积分：{point}P<br />
      <div className="flex">
        本次支付可用积分：<div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      根据积分类型，购买课程券时可用积分的情况有所不同。
      <br />
      <li className="li-tag-inside">完成课程后获得的积分：无论支付金额大小都可使用</li>
      <li className="li-tag-inside">通过朋友推荐和活动获得的积分：仅在支付金额超过30万韩元时可使用</li>
    </>,
    <>
      我的點數：{point}P<br />
      <div className="flex">
        本次支付可用點數：<div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      根據點數類型，購買課程券時可用點數的情況有所不同。
      <br />
      <li className="li-tag-inside">完成課程後獲得的點數：無論支付金額大小都可以使用</li>
      <li className="li-tag-inside">通過朋友推薦和活動獲得的點數：僅在支付金額超過30萬韓元時可使用</li>
    </>,
    <>
      所持ポイント: {point}P<br />
      <div className="flex">
        この支払いで利用可能なポイント: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      ポイントの種類によって、レッスンチケットの購入時に使用可能かどうかが異なります。.
      <br />
      <li className="li-tag-inside">授業完了後にもらったポイント：支払金額に関係なく使用可能</li>
      <li className="li-tag-inside">
        友達の紹介やイベントでもらったポイント：支払金額が30万ウォン以上の場合に使用可能です。
      </li>
    </>,
    <>
      Điểm tích lũy của tôi: Điểm tích lũy có sẵn cho khoản thanh toán {point}P<br />
      Điểm khả dụng cho{' '}
      <div className="flex">
        khoản thanh toán này: <div className="text-purple-600">{availablePoint}P</div>
      </div>
      <br />
      Tùy thuộc vào loại điểm tích lũy khác nhau có thể dùng khi mua gói học khác nhau
      <br />
      <li className="li-tag-inside">
        Điểm nhận được sau khi hoàn thành khóa học: Dùng cho bất kỳ khoản thanh toán nào
      </li>
      <li className="li-tag-inside">
        Điểm nhận được thông qua giới thiệu bạn bè và sự kiện: Dùng khi thanh toán trên 300.000 won
      </li>
    </>,
  ],
  two_card: {
    first_card_title: [
      `2개 카드 결제 (첫번째 카드)`,
      `Pay with the first card`,
      'n_purchase.two_card.first_card_title',
      `用第一张信用卡付款`,
      `用第一張信用卡付款`,
      `2枚のカード決済（最初のカード）`,
      ``,
    ],
    second_card_title: [
      `2개 카드 결제 (두번째 카드)`,
      `Pay with the second card`,
      'n_purchase.two_card.second_card_title',
      `用第二张信用卡付款`,
      `用第二張信用卡付款`,
      `2枚のカード決済（2枚目のカード）`,
      ``,
    ],
    firstCard: [
      `두번째 카드 결제 금액 / 일시불`,
      `Charge on the first card / Pay Now`,
      'n_purchase.two_card.firstCard',
      `第一张信用卡付款金额`,
      `第一張信用卡付款金額`,
      `1枚目のカード決済金額`,
      `Tính phí thẻ đầu tiên`,
    ],
    secondCard: [
      `두번째 카드 결제 금액 / 일시불`,
      `Charge on the second card / Pay Now`,
      'n_purchase.two_card.secondCard',
      `第二张信用卡付款金额`,
      `第二張信用卡付款金額`,
      `2枚目のカード決済金額`,
      `Tính phí thẻ thứ hai`,
    ],
    secondCardPrice: (price) => [
      <>두번째 카드 결제 금액은 {price} 원 입니다.</>,
      <>{price} KRW will be charged to your second card.</>,
      'n_purchase.two_card.secondCardPrice',
      <>第二张信用卡付款金额为${price}。</>,
      <>第二張信用卡付款金額為${price}。</>,
      <>2枚目のカード決済金額は${price}です。</>,
      <>{price} KRW sẽ tính vào thẻ thứ hai của bạn.</>,
    ],
    firstCardPrice: (price) => [
      <>첫번째 카드 결제 금액은 {price} 원 입니다.</>,
      <>{price} KRW will be charged to your first card.</>,
      'n_purchase.two_card.firstCardPrice',
      <>第一张信用卡付款金额为${price}。</>,
      <>第一張信用卡付款金額為${price}。</>,
      <>1枚目のカード決済金額は${price}です。</>,
      <>{price} KRW sẽ tính vào thể đầu tiên của bạn.</>,
    ],
  },
  card_info: {
    total: [
      `총 결제 금액`,
      `Your total`,
      'n_purchase.card_info.total',
      `最终付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      ``,
    ],
    number: [`카드번호`, `Card number`, 'n_purchase.card_info.number', `卡号`, `卡號`, `カード番号`, ``],
    expire: [
      `유효기간 (mm/YY)`,
      `Expire date (mm/YY)`,
      'n_purchase.card_info.expire',
      `有效期限(mm/YY)`,
      `有效期限(mm/YY)`,
      `有効期限 (mm/YY)`,
      ``,
    ],
    pin: [
      `카드비밀번호 앞 2자리`,
      `PIN (First 2 digits)`,
      'n_purchase.card_info.pin',
      `卡片密码前2码`,
      `卡片密碼前2碼`,
      `PIN（はじめの2桁）`,
      ``,
    ],
    birth: [
      `생년월일 (YYmmdd)`,
      `Birth Date (YYmmdd)`,
      'n_purchase.card_info.birth',
      `生日 (YYmmdd)`,
      `生日 (YYmmdd)`,
      `生年月日 (YYmmdd)`,
      ``,
    ],
    business_r: [
      `사업자등록번호(10자리)`,
      `Business registration number (10 digits)`,
      'n_purchase.card_info.business_r',
      `事业登记编号(10码）`,
      `事業登記編號(10碼）`,
      `事業者登録番号(10桁)`,
      ``,
    ],
    install: [`할부`, `Pay in installations`, 'n_purchase.card_info.install', `分期付款`, `分期付款`, `分割払い`, ``],
    personal: [`개인`, `Personal`, 'n_purchase.card_info.personal', `个人`, `個人`, `個人`, ``],
    business: [`법인`, `Corporate`, 'n_purchase.card_info.business', `法人`, `法人`, `法人`, ``],
  },
  card_manual: {
    title: [
      `카드 직접 입력`,
      `Credit card (manual)`,
      'n_purchase.card_manual.title',
      `手动输入银行卡号`,
      `手動輸入信用卡號`,
      `クレジットカードの直接入力`,
      ``,
    ],
  },
  msg_err: {
    p_method: [
      `결제 방식을 선택해주세요`,
      `Please select your purchase method.`,
      'n_purchase.msg_err.p_method',
      `请选择付款方式`,
      `請選擇付款方式`,
      `お支払い方法を選択してください`,
      ``,
    ],
    check_box: [
      `상품 구매 관련 환불 규정에 동의 후 결제를 진행할 수 있습니다.`,
      `Please check the confirmation box.`,
      'n_purchase.msg_err.check_box',
      `请勾选确认`,
      `請勾選確認`,
      `商品購入に関する返金ポリシーに同意後、決済を行うことができます。`,
      ``,
    ],
    card_number_1: [
      `카드번호를 입력하지 않았습니다.`,
      `Please enter the credit card number.`,
      'n_purchase.msg_err.card_number_1',
      `请输入银行卡号`,
      `請輸入信用卡號`,
      `クレジットカード番号を入力してください。`,
      ``,
    ],
    card_number_2: [
      `일치하는 카드번호가 없습니다. 다시 입력해주세요.`,
      `Invalid card number. Please try again.`,
      'n_purchase.msg_err.card_number_2',
      `无效的卡号，请重试`,
      `無效的卡號，請重試`,
      `一致するカード番号がありません。 もう一度入力してください。`,
      ``,
    ],
    exp_1: [
      `유효기간을 입력하지 않았습니다.`,
      `Please enter the credit card expiry date.`,
      'n_purchase.msg_err.exp_1',
      `请输入卡片有效期`,
      `請輸入卡片有效期`,
      `有効期限を入力してください。`,
      ``,
    ],
    exp_2: [
      `유효기간이 일치하지 않습니다. 다시 입력해주세요.`,
      `Invalid expiry date. Please try again.`,
      'n_purchase.msg_err.exp_2',
      `无效的有效期，请重试`,
      `無效的有效期，請重試`,
      `有効期限が一致しません。 もう一度入力してください。`,
      ``,
    ],
    pin_1: [
      `카드비밀번호를 입력하지 않았습니다.`,
      `Please enter the personal identification number (PIN).`,
      'n_purchase.msg_err.pin_1',
      `请输入PIN码`,
      `請輸入PIN碼`,
      `PINを入力してください。`,
      ``,
    ],
    pin_2: [
      `카드 번호가 일치하지 않습니다. 다시 입력해주세요.`,
      `Invalid PIN. Please try again.`,
      'n_purchase.msg_err.pin_2',
      `无效的PIN码，请重试`,
      `無效的PIN碼，請重試`,
      `PIN番号が一致しません。 もう一度入力してください。`,
      ``,
    ],
    birth_1: [
      `생년월일을 입력하지 않았습니다.`,
      `Please enter your date of birth.`,
      'n_purchase.msg_err.birth_1',
      `请输入生日`,
      `請輸入生日`,
      `生年月日を入力してください。`,
      ``,
    ],
    birth_2: [
      `생년월일이 일치하지 않습니다. 다시 입력해주세요.`,
      `Invalid date of birth. Please try again.`,
      'n_purchase.msg_err.birth_2',
      `无效的生日，请重试`,
      `無效的生日，請重試`,
      `生年月日が一致しません。 もう一度入力してください。`,
      ``,
    ],
    business_1: [
      `사업자등록번호를 입력하지 않았습니다.`,
      `Please enter your business registration number.`,
      'n_purchase.msg_err.business_1',
      `请输入事业登记编号`,
      `請輸入事業登記編號`,
      `事業者登録番号を入力してください。`,
      ``,
    ],
    business_2: [
      `일치하는 사업자등록번호가 없습니다. 다시 입력해주세요.`,
      `Invalid business registration number. Please try again.`,
      'n_purchase.msg_err.business_2',
      `无效的事业登记编号，请重试`,
      `無效的事業登記編號，請重試`,
      `一致する事業者番号がありません。 もう一度入力してください。`,
      ``,
    ],
  },
  complete: {
    title: (name) => [
      <>
        {name}님<br />
        결제 완료되었습니다.
      </>,
      <>
        Hi {name}
        <br />
        Congrats! We received your payment.
      </>,
      'n_purchase.complete.title',
      <>
        {name}
        <br />
        付款已完成！
      </>,
      <>
        {name}
        <br />
        付款已完成！
      </>,
      <>
        {name}さん
        <br />
        決済完了しました。
      </>,
      ``,
    ],
    subtitle: [
      `이제 링글 수업을 시작해보세요!`,
      `Book a lesson now!`,
      'n_purchase.complete.subtitle',
      `现在就预约第一堂课吧！`,
      `現在就預約第一堂課吧！`,
      `さあ、Ringleのレッスンを始めましょう！`,
      ``,
    ],
    purchase: [
      `결제 내역`,
      `Purchase history`,
      'n_purchase.complete.purchase',
      `付款明细`,
      `付款明細`,
      `決済履歴`,
      `Lịch sử mua hàng`,
    ],
    alltime_time: [
      `오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)에 수강 가능합니다`,
      `Regular lesson hours are 5:00 am - 2:00 pm / 7:00 pm - 3:00 am (KST).`,
      'n_purchase.complete.alltime_time',
      ``,
      `可以上課的時間為 5AM~2PM / 7PM~3AM (KST)`,
      `レッスン受講可能時間は5時～14時／19時～3時です。`,
      ``,
    ],
    receipt_certificate: [
      `영수증/출석증은 PC에서 오른쪽 상단 프로필 > 수업권/증명서류 탭에서 PDF로 다운로드 가능합니다.`,
      `You can download your lesson certificates & proof of attendance under Credits.`,
      'n_purchase.complete.receipt_certificate',
      `"收据/出席证明可以在电脑网页右上角的个人资料 > 课程券/证明文件中以 PDF 格式下载。`,
      `"收據/出席證明可以在電腦網頁右上角的個人資料 > 課程券/證明文件中以 PDF 格式下載。`,
      `領収書/出席証は、PCの右上のプロフィール > レッスンチケット/証明書類タブからPDFでダウンロードできます。`,
      ``,
    ],
    receipt_certificate_go: [
      `바로가기`,
      `Go`,
      'n_purchase.complete.receipt_certificate_go',
      `立即前往`,
      `立即前往`,
      `進む`,
      ``,
    ],
    go_to_home: [`홈으로 가기`, `Home`, 'n_purchase.complete.go_to_home', `主页`, `首頁`, `ホーム`, ``],
    go_to_booking: [
      `수업 예약하기`,
      `Book a lesson`,
      'n_purchase.complete.go_to_booking',
      `预约课程`,
      `預約課程`,
      `レッスンを予約する`,
      ``,
    ],
    title_new: [
      `결제가 완료됐어요.`,
      `Payment completed`,
      'n_purchase.complete.title_new',
      `付款已完成`,
      `付款已完成`,
      `決済完了しました。`,
      ``,
    ],
    section_detail: {
      caption_1: [
        `수강기간`,
        `Expires in`,
        'n_purchase.complete.section_detail.caption_1',
        `使用期限`,
        `使用期限`,
        `有効期限`,
        `Ngày kết thúc`,
      ],
      caption_2: [
        `정가`,
        `Regular price`,
        'n_purchase.complete.section_detail.caption_2',
        `定价`,
        `定價`,
        `通常価格`,
        `Học phí tiêu chuẩn`,
      ],
      caption_3: [
        `총 할인 금액`,
        `Total discount`,
        'n_purchase.complete.section_detail.caption_3',
        `总优惠金额`,
        `總折扣金額`,
        `割引分合計`,
        `Tổng giảm giá`,
      ],
      caption_4: [
        `프로모션 할인`,
        `Promo discount`,
        'n_purchase.complete.section_detail.caption_4',
        `促销折扣`,
        `促銷折扣`,
        `プロモーション割引`,
        `Khuyến mãi giảm giá`,
      ],
      caption_5: [
        `매주 수업 듣기 할인`,
        `Every Week discount`,
        'n_purchase.complete.section_detail.caption_5',
        `每週上课折扣`,
        `每週上課折扣`,
        `毎週授業を受ける割引`,
        ``,
      ],
      caption_6: [
        `포인트 사용`,
        `Points applied`,
        'n_purchase.complete.section_detail.caption_6',
        `使用积分`,
        `使用點數`,
        `ポイント利用`,
        `Điểm được áp dụng`,
      ],
      caption_7: [
        `최종 결제 금액`,
        `Your total`,
        'n_purchase.complete.section_detail.caption_7',
        `实际付款金额`,
        `最終付款金額`,
        `お支払い総額`,
        `Tổng cộng`,
      ],
    },
    info_coupon: {
      title: [
        `수강기간 연장 쿠폰 발급 완료`,
        `Special Extension Coupon`,
        'n_purchase.complete.info_coupon.title',
        `已发放延长课程使用时间优惠券`,
        `已發放延長課程使用時間優惠券`,
        `Special coupon for extending lesson days`,
        `Special coupon for extending lesson days`,
      ],
      desc: [
        `보유한 자유수강 수업권의 처음 수강기간만큼 연장할 수 있는 쿠폰이 발급되었어요.`,
        `You have received a coupon that renews the validity period of your flexible credits.`,
        'n_purchase.complete.info_coupon.desc',
        `您已获得延长课程使用期限优惠券`,
        `您已獲得延長課程使用期限優惠券`,
        `You have received a coupon that allows you to extend the expiration date of your existing regular credits by the same duration as the original period.`,
        `You have received a coupon that allows you to extend the expiration date of your existing regular credits by the same duration as the original period.`,
      ],
    },
    info_expiration_date: {
      title: [
        `수강기간 안내`,
        `Credit information`,
        'n_purchase.complete.info_expiration_date.title',
        `b课程券使用期限说明`,
        `b課程券使用期限說明`,
        `Credit information`,
        `Credit information`,
      ],
      desc_1: [
        `첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
        `The validity period will only begin to expire after your first lesson.`,
        'n_purchase.complete.info_expiration_date.desc_1',
        `请注意课程券的使用期限不是从购买日开始扣除，而是从完成首次课程时开始扣除。`,
        `請註意課程券的使用期限不是從購買日開始扣除，而是從完成首次課程時開始扣除。`,
        `受講期間が始まる時点は購入日ではなく、初回レッスンを完了した時点からであることをご了承ください。`,
        `Ngày hết hạn được tính bắt đầu từ ngày bạn hoàn thành buổi học đầu tiên trong gói học đã thanh toán.`,
      ],
      desc_2: [
        `단, 구매 후 1년 되는 시점부터는 수업 이력이 없어도 수강기간이 차감됩니다.`,
        `However, unused credits will start expiring one year after purchase.`,
        'n_purchase.complete.info_expiration_date.desc_2',
        `购买后一年期间，于完成首次课程时扣除使用期限。`,
        `購買後一年期間，於完成首次課程時扣除使用期限。`,
        `レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。`,
        `Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.`,
      ],
      desc_3: [
        `[기존] 매주 수업듣기 수업권 구매 즉시 수강기간 차감이 시작됩니다.`,
        `[Original] The countdown towards expiration of lesson credits begins immediately upon purchase.`,
        'n_purchase.complete.info_expiration_date.desc_3',
        `[夏日优惠] 上完第一堂后开始扣除使用期限`,
        `[夏日優惠] 上完第一堂後開始扣除使用期限`,
        `[サマーキャンペーン特典] 有効期間は、初回レッスン受講完了時が開始日となります`,
        ``,
      ],
      desc_4: [
        `[이벤트 기간]  매주 수업듣기 수업권 구매 후 첫 수업을 시작하지 않아도 11월 6일까지 수강기간 시작이 홀딩 됩니다.
(24년 11월 7일 00시부터 수강기간 차감 자동으로 시작)`,
        `[Promotion Only] The countdown may be deferred until 23:59 on November 6, 2024.`,
        'n_purchase.complete.info_expiration_date.desc_4',
        `[夏日优惠] 2024.11.6 后即使未开始课程，课程券使用期限将开始扣除`,
        `[夏日優惠] 2024.11.6 後即使未開始課程，課程券使用期限將開始扣除`,
        `[サマーキャンペーン特典] ただし、2024年11月6日からは、初回レッスンの受講有無にかかわらず自動的に有効期間が開始されます`,
        ``,
      ],
    },
    info_receipt: [
      `가입한 이메일로 영수증을 보내드렸어요.`,
      `The payment receipt has been sent via email.`,
      'n_purchase.complete.info_receipt',
      `已传送收据至您于Ringle注册时使用的Email`,
      `已傳送收據至您於Ringle註冊時使用的Email`,
      `We've sent the payment receipt to your Ringle account's email.`,
      `We've sent the payment receipt to your Ringle account's email.`,
    ],
    section_tutor: {
      title: [
        <>
          진짜 실력을 만들어주는
          <br />
          링글 튜터와 수업을 시작하세요.
        </>,
        <>
          Start your lesson now
          <br />
          to reach your full potential.
        </>,
        'n_purchase.complete.section_tutor.title',
        <>
          现在就开始上课
          <br />
          和教师们一起提升英文能力
        </>,
        <>
          現在就開始上課
          <br />
          和教師們一起提升英文能力
        </>,
        <>
          Start your lesson now
          <br />
          to reach your full potential.
        </>,
        <>
          Start your lesson now
          <br />
          to reach your full potential.
        </>,
      ],
      caption: [
        `최근 수업 평가`,
        `Recent Review`,
        'n_purchase.complete.section_tutor.caption',
        `课程评价`,
        `課程評價`,
        `レッスン評価`,
        `Nhận xét`,
      ],
    },
    btn: [
      `수업 예약하기`,
      `Book a lesson`,
      'n_purchase.complete.btn',
      `课程预约`,
      `預約課程`,
      `レッスンを予約する`,
      `Đặt lịch học`,
    ],
    every_week: {
      label: [
        `매주 수업 듣기`,
        `Every Week`,
        'n_purchase.complete.every_week.label',
        `一週一次`,
        `一週一次`,
        `毎週レッスンを受ける`,
        `Lớp học hàng tuần`,
      ],
      title: [
        `7일 내 첫 수업을 완료하세요.`,
        `Complete \ba lesson within 7 days.`,
        'n_purchase.complete.every_week.title',
        `请于7天内完成第一堂课`,
        `請於7天內完成第一堂課`,
        `Complete your first lesson within 7 days.`,
        `Complete your first lesson within 7 days.`,
      ],
      desc: [
        `첫 주차 기간 내 수업을 진행하지 않는 경우 수업권이 차감됩니다.`,
        `One credit will expire after 7 days even if you don't take a lesson.`,
        'n_purchase.complete.every_week.desc',
        `如果未能于第一週完成一堂课，刚堂课将无法使用`,
        `如果未能於第一週完成一堂課，剛堂課將無法使用`,
        `If you do not take a lesson during the first weekly period, your lesson credit will be deducted.`,
        `If you do not take a lesson during the first weekly period, your lesson credit will be deducted.`,
      ],
      cpation: [
        `이번 주차 수업권 1회 만료까지`,
        `This week's credit expires in`,
        'n_purchase.complete.every_week.cpation',
        `距离这週课程失效还有`,
        `距離這週課程失效還有`,
        `Your first credit expires in`,
        `Your first credit expires in`,
      ],
      timer_date: [`일`, `day(s)`, 'n_purchase.complete.every_week.timer_date', `日`, `日`, `日`, `ngày`],
      timer_caption: [`남음`, ``, 'n_purchase.complete.every_week.timer_caption', ``, ``, ``, ``],
      btn: [
        `내게 딱 맞는 튜터 찾기`,
        `Find the perfect-fit tutor`,
        'n_purchase.complete.every_week.btn',
        `预约课程`,
        `預約課程`,
        `レッスンを予約する`,
        `Đặt lịch học`,
      ],
      btn_text: [
        `나중에 할게요`,
        `Skip for now`,
        'n_purchase.complete.every_week.btn_text',
        `下次再说`,
        `下次再說`,
        `今はしない`,
        `Bỏ qua`,
      ],
    },
    event: {
      label: [`이벤트`, `Event`, 'n_purchase.complete.event.label', `活动`, `活動`, `Event`, `Event`],
      title: [
        <>
          24시간 안에 첫 수업을 예약하고
          <br />
          5,000 포인트를 받으세요!
        </>,
        <>
          Book a lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
        'n_purchase.complete.event.title',
        <>
          请于24小时内预约课程
          <br />
          以获得价值$3.84积分
        </>,
        <>
          請於24小時內預約課程
          <br />
          以獲得價值$3.84點數
        </>,
        <>
          Book your first lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
        <>
          Book your first lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
      ],
      title_20: [
        <>
          24시간 안에 첫 수업을 예약하고
          <br />
          5,000 포인트를 받으세요!
        </>,
        <>
          Book a lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
        'n_purchase.complete.event.title_20',
        <>
          请于24小时内预约课程
          <br />
          以获得价值$3.84积分
        </>,
        <>
          請於24小時內預約課程
          <br />
          以獲得價值$3.84點數
        </>,
        <>
          Book your first lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
        <>
          Book your first lesson within 24 hours
          <br />
          and get $3.84 points!
        </>,
      ],
      cpation: [
        `이벤트까지 남은 시간`,
        `Event expires in`,
        'n_purchase.complete.event.cpation',
        `距离活动结束还有`,
        `距離活動結束還有`,
        `Event expires in`,
        `Event expires in`,
      ],
      timer_caption: [`남음`, ``, 'n_purchase.complete.event.timer_caption', ``, ``, ``, ``],
      btn: [
        `내게 딱 맞는 튜터 찾기`,
        `Find the perfect-fit tutor`,
        'n_purchase.complete.event.btn',
        `预约课程`,
        `預約課程`,
        `レッスンを予約する`,
        `Đặt lịch học`,
      ],
      btn_text: [
        `나중에 할게요`,
        `Skip for now`,
        'n_purchase.complete.event.btn_text',
        `下次再说`,
        `下次再說`,
        `今はしない`,
        `Bỏ qua`,
      ],
    },
    re_purchase: {
      title: (number) => [
        <>
          오늘 벌써 {number}명이
          <br />
          다음 수업을 예약했어요.
        </>,
        <>
          {number} students have already
          <br />
          booked their next lesson today.
        </>,
        'n_purchase.complete.re_purchase.title',
        <>
          今天已有{number}位<br />
          预约了下堂课
        </>,
        <>
          今天已有{number}位<br />
          預約了下堂課
        </>,
        <>
          {number} students have already
          <br />
          booked thee next lesson today.
        </>,
        <>
          {number} students have already
          <br />
          booked thee next lesson today.
        </>,
      ],
      btn: [
        `내게 딱 맞는 튜터 찾기`,
        `Find the perfect-fit tutor`,
        'n_purchase.complete.re_purchase.btn',
        `预约课程`,
        `預約課程`,
        `レッスンを予約する`,
        `Đặt lịch học`,
      ],
      btn_text: [
        `나중에 할게요`,
        `Skip for now`,
        'n_purchase.complete.re_purchase.btn_text',
        `下次再说`,
        `下次再說`,
        `今はしない`,
        `Bỏ qua`,
      ],
    },
    info_app: [
      `AI 튜터는 링글 앱에서만 사용 가능해요.`,
      `We sent the receipt to your registered email address.`,
      'n_purchase.complete.info_app',
      `We sent the receipt to your registered email address.`,
      `We sent the receipt to your registered email address.`,
      `We sent the receipt to your registered email address.`,
      ``,
    ],
  },
  exit: {
    title: [
      `현재 결제 중입니다.`,
      `Payment is being processed.`,
      'n_purchase.exit.title',
      `正在进行付款`,
      `正在進行付款`,
      `決済中です。`,
      ``,
    ],
    content: [
      <>
        페이지를 이동하면, 수업권 결제가 되지 않습니다.
        <br />
        <br />
        다른 페이지로 이동하시겠어요?
      </>,
      <>
        If you leave this page, the payment will not be processed.
        <br />
        <br />
        Leave this page anyway?
      </>,
      'n_purchase.exit.content',
      <>
        如果离开页面，付款将中止
        <br />
        <br />
        您确定要离开吗
      </>,
      <>
        如果離開頁面，付款將中止
        <br />
        <br />
        您確定要離開嗎
      </>,
      <>
        ページを移動すると、レッスンチケットのお支払いができません。
        <br />
        <br />
        他のページに移動しますか？
      </>,
      ``,
    ],
    btn_continue: [
      `결제 마저 하기`,
      `Continue payment`,
      'n_purchase.exit.btn_continue',
      `继续付款`,
      `繼續付款`,
      `支払いを続ける`,
      ``,
    ],
    btn_move: [`페이지 이동`, `Leave page`, 'n_purchase.exit.btn_move', `离开`, `離開`, `ページの移動`, ``],
  },
  fail: {
    title: [
      <>
        결제를 완료할 수 없습니다.
        <br />
        다시 시도해주세요.
      </>,
      <>
        Your payment didn't go through.
        <br />
        Please try again.
      </>,
      'n_purchase.fail.title',
      <>
        无法完成付款.
        <br />
        请再试一次
      </>,
      <>
        無法完成付款.
        <br />
        請再試一次
      </>,
      <>
        決済を完了することができません。
        <br />
        もう一度お試しください。
      </>,
      ``,
    ],
    error: [`결제 오류`, `Transaction failed`, 'n_purchase.fail.error', `付款失败`, `付款失敗`, `決済エラー`, ``],
    history: [
      `결제 시도 내역`,
      `Payment attempts`,
      'n_purchase.fail.history',
      `付款纪录`,
      `付款紀錄`,
      `決済試行履歴`,
      ``,
    ],
    amount: [`결제 요청 금액`, `Amount due`, 'n_purchase.fail.amount', `付款金额`, `付款金額`, `支払い額`, ``],
    help1: [
      `결제 오류가 반복되신다면`,
      `Having trouble?`,
      'n_purchase.fail.help1',
      `如果反复出现付款问题`,
      `如果反覆出現付款問題`,
      `決済エラーが繰り返される場合`,
      ``,
    ],
    help2: [
      `여기를 눌러 문의 해주세요.`,
      `Let us help you.`,
      'n_purchase.fail.help2',
      `请和我们联络`,
      `請和我們聯絡`,
      `こちらからお問い合わせください。`,
      ``,
    ],
    btn: [`다시 결제하기`, `Try again`, 'n_purchase.fail.btn', `再试一次`, `再試一次`, `再度決済する`, ``],
  },
  v_bank: {
    title: [
      `아래 계좌로 입금해 주세요.`,
      `Please make payment to the account below.`,
      'n_purchase.v_bank.title',
      `请汇款至以下帐号`,
      `請匯款至以下帳號`,
      `下記の口座に入金してください。`,
      `Vui lòng thanh toán theo thông tin bên dưới.`,
    ],
    desc: [
      `계좌 입금 완료 후 수업을 시작할 수 있습니다.`,
      `You can start your lessons after payment.`,
      'n_purchase.v_bank.desc',
      `汇款之后就可以开始上课`,
      `匯款之後就可以開始上課`,
      `You can start your lessons after completing the payment to your account.`,
      `You can start your lessons after completing the payment to your account.`,
    ],
    section_account: {
      title: [
        `입금 계좌`,
        `Account information`,
        'n_purchase.v_bank.section_account.title',
        `汇款帐号`,
        `匯款帳號`,
        `Account information`,
        `Account information`,
      ],
      caption_1: [
        `가상 계좌번호`,
        `Virtual account no.`,
        'n_purchase.v_bank.section_account.caption_1',
        `虚拟帐号`,
        `虛擬帳號`,
        `Virtual account number`,
        `Virtual account number`,
      ],
      caption_2: [
        `입금 기한`,
        `Payment due`,
        'n_purchase.v_bank.section_account.caption_2',
        `汇款期限`,
        `匯款期限`,
        `Payment due by`,
        `Payment due by`,
      ],
    },
    section_detail: {
      caption_1: [
        `수강기간`,
        `Expires in`,
        'n_purchase.v_bank.section_detail.caption_1',
        `使用期限`,
        `使用期限`,
        `有効期限`,
        `Ngày kết thúc`,
      ],
      caption_2: [
        `정가`,
        `Regular price`,
        'n_purchase.v_bank.section_detail.caption_2',
        `定价`,
        `定價`,
        `通常価格`,
        `Học phí tiêu chuẩn`,
      ],
      caption_3: [
        `총 할인 금액`,
        `Total discount`,
        'n_purchase.v_bank.section_detail.caption_3',
        `总优惠金额`,
        `總折扣金額`,
        `割引分合計`,
        `Tổng giảm giá`,
      ],
      caption_4: [
        `프로모션 할인`,
        `Promo discount`,
        'n_purchase.v_bank.section_detail.caption_4',
        `促销折扣`,
        `促銷折扣`,
        `プロモーション割引`,
        `Khuyến mãi giảm giá`,
      ],
      caption_5: [
        `매주 수업 듣기 할인`,
        `Every Week discount`,
        'n_purchase.v_bank.section_detail.caption_5',
        `每週上课折扣`,
        `每週上課折扣`,
        `毎週授業を受ける割引`,
        ``,
      ],
      caption_6: [
        `포인트 사용`,
        `Points applied`,
        'n_purchase.v_bank.section_detail.caption_6',
        `使用积分`,
        `使用點數`,
        `ポイント利用`,
        `Điểm được áp dụng`,
      ],
      caption_7: [
        `최종 결제 금액`,
        `Your total`,
        'n_purchase.v_bank.section_detail.caption_7',
        `实际付款金额`,
        `最終付款金額`,
        `お支払い総額`,
        `Tổng cộng`,
      ],
      caption_8: [
        `분할 결제(첫 번째 카드)`,
        `Split payment #1`,
        'n_purchase.v_bank.section_detail.caption_8',
        `分别付款 (银行卡二)`,
        `分期付款（第二張信用卡）`,
        `分割決済 (2枚目のカード)`,
        `Thẻ thứ hai`,
      ],
      caption_9: [
        `분할 결제(두 번째 카드)`,
        `Split payment #2`,
        'n_purchase.v_bank.section_detail.caption_9',
        `分别付款 (银行卡一)`,
        `分期付款（第一張信用卡）`,
        `分割決済 (1枚目のカード)`,
        `Thẻ thứ nhất`,
      ],
    },
    btn_cta: [
      `입금을 완료했어요.`,
      `Payment completed`,
      'n_purchase.v_bank.btn_cta',
      `付款完成`,
      `付款完成`,
      `Payment completed`,
      `Payment completed`,
    ],
    btn_text: [
      `홈 화면으로`,
      `Go to Home`,
      'n_purchase.v_bank.btn_text',
      `前往主页`,
      `前往首頁`,
      `ホームに移動`,
      `Trang chủ`,
    ],
  },
  upsell_coupon: {
    title: [
      <>
        쿠폰을 받고,
        <br />더 알뜰하게 구매해보세요.
      </>,
      <> Get coupons and grab better deals.</>,
      'n_purchase.upsell_coupon.title',
      `获领取优惠券省更多`,
      `獲領取優惠券省更多`,
      `クーポンを利用して、よりお得に購入しましょう。`,
      ``,
    ],
    secret_coupon: (amount) => [
      <>{amount}회 시크릿 쿠폰</>,
      <>{amount} Secret Coupon</>,
      'n_purchase.upsell_coupon.secret_coupon',
      <>神秘优惠券{amount}</>,
      <>神秘優惠券{amount} </>,
      <>{amount}分のシークレットクーポン</>,
      ``,
    ],
    download: [<>쿠폰 받기</>, `Download`, 'n_purchase.upsell_coupon.download', `Download`, `Download`, `Download`, ``],
    secret_coupon_benefit: (amount) => [
      <>구매 시 {amount} 시크릿 쿠폰 자동 적용</>,
      <>{amount} Secret Coupon applied at purchase</>,
      'n_purchase.upsell_coupon.secret_coupon_benefit',
      <>购买时使用{amount} 张神秘优惠券</>,
      <>購買時使用{amount} 張神秘優惠券</>,
      <>{amount}分のシークレットクーポン適用</>,
      ``,
    ],
    download_done: [`받기 완료`, `Done`, 'n_purchase.upsell_coupon.download_done', `Done`, `Done`, `Done`, ``],
    pricing_card_badge: (amount) => [
      <>시크릿 쿠폰 {amount}</>,
      <> {amount} Secret Coupons </>,
      'n_purchase.upsell_coupon.pricing_card_badge',
      <> {amount} Secret Coupons </>,
      <> {amount} Secret Coupons </>,
      <> {amount} Secret Coupons </>,
      ``,
    ],
  },
  summer_24: {
    popular_wording: [
      `여름 프로모션 인기`,
      `Most Popular`,
      'n_purchase.summer_24.popular_wording',
      `Most Popular`,
      `Most Popular`,
      `Most Popular`,
      `Most Popular`,
    ],
  },
  benefit: {
    limited_time: (remainTime) => [
      <>{remainTime} 내 구매시</>,
      <>{remainTime}</>,
      'n_purchase.benefit.limited_time',
      <>{remainTime}</>,
      <>{remainTime}</>,
      <>{remainTime}</>,
      ``,
    ],
  },
  complete_mo: {
    btn: [
      `앱에서 더 많은 튜터 보기`,
      `View more tutors on Ringle app`,
      'n_purchase.complete_mo.btn',
      `看更多教师`,
      `看更多教師`,
      `アプリでチューターをもっと見る`,
      ``,
    ],
    caption: [
      `링글 앱 다운로드 받고 시작하세요!`,
      `Download Ringle app and start learning!`,
      'n_purchase.complete_mo.caption',
      `下载Ringle APP开始学习`,
      `下載Ringle APP開始學習`,
      `アプリをダウンロードして、学習開始しよう！`,
      ``,
    ],
    benefit_1: [
      <>
        수업하고 싶은
        <br />
        튜터의 스케줄을
        <br />
        알림으로 받아보세요
      </>,
      <>
        Get notified when
        <br />
        your favorite
        <br />
        tutors are available.
      </>,
      'n_purchase.complete_mo.benefit_1',
      <>
        接收收藏的教师
        <br />
        开课时间通知
        <br />
      </>,
      <>
        接收收藏的教師
        <br />
        開課時間通知
        <br />
      </>,
      <>
        お気に入りの講師が
        <br />
        予約可能になったら
        <br />
        お知らせします
      </>,
      ``,
    ],
    benefit_2: [
      <>
        AI 스피킹 파트너로
        <br />
        꾸준한 말하기
        <br />
        연습을 해보세요
      </>,
      <>
        Practice talking
        <br />
        every day with
        <br />
        AI Speaking Partner.
      </>,
      'n_purchase.complete_mo.benefit_2',
      <>
        每天和AI口语伙伴
        <br />
        练习口语
        <br />
      </>,
      <>
        每天和AI口說夥伴
        <br />
        練習口說
        <br />
      </>,
      <>
        毎日AI Speaking
        <br />
        Partnerと会話の
        <br />
        練習をしましょう
      </>,
      ``,
    ],
    benefit_3: [
      <>
        AI 분석 리포트
        <br />
        영어 실력을
        <br />
        진단받아보세요
      </>,
      <>
        Get your English
        <br />
        analyzed by <br />
        Ringle AI.
      </>,
      'n_purchase.complete_mo.benefit_3',
      <>
        获得AI分析报告
        <br />
        确认自己的英语实力
        <br />
      </>,
      <>
        獲得AI分析報告
        <br />
        確認自己的英文實力
        <br />
      </>,
      <>
        あなたの英語について
        <br />
        RingleのAIによる
        <br />
        分析を受けましょう
      </>,
      ``,
    ],
    benefit_4: [
      <>
        매일 새로 나오는
        <br />
        1,300+개의 교재를
        <br />
        확인해 보세요
      </>,
      <>
        Access 1300+ <br />
        materials with
        <br />
        new daily additions.
      </>,
      'n_purchase.complete_mo.benefit_4',
      <>
        确认每天更新는
        <br />
        超过1,300+篇教材
        <br />
      </>,
      <>
        確認每天更新는
        <br />
        超過1,300+篇教材
        <br />
      </>,
      <>
        毎日新たに追加される
        <br />
        1300以上の教材に
        <br />
        アクセスしましょう
      </>,
      ``,
    ],
  },
  installment: {
    one: [`일시불`, `Pay now`, 'n_purchase.installment.one', `Pay now`, `Pay now`, `Pay now`, `Pay now`],
    n: (month) => [
      <>{month}개월</>,
      <>{month} plan</>,
      'n_purchase.installment.n',
      <>{month} plan</>,
      <>{month} plan</>,
      <>{month} plan</>,
      <>{month} plan</>,
    ],
    free: [
      `(무이자)`,
      `(0% interest)`,
      'n_purchase.installment.free',
      `(0% interest)`,
      `(0% interest)`,
      `(0% interest)`,
      `(0% interest)`,
    ],
  },
}
export const n_buy = {
  title: [`구매`, `Buy now`, 'n_buy.title', `购买`, `購買`, `購入`, ``],
}
export const n_help = {
  title: [`고객센터`, `Help`, 'n_help.title', `联络客服`, `聯絡客服`, `お客様センター`, ``],
}
export const n_updates = {
  title: [`공지사항`, `Updates`, 'n_updates.title', `公告事项`, `公告事項`, `お知らせ`, ``],
}
export const n_editing = {
  title: [`영문서 교정`, `Editing&proof reading`, 'n_editing.title', `修改英文文件`, `修改英文文件`, `英文添削`, ``],
}
export const n_menu = {
  privacy: [
    `개인정보 처리 방침`,
    `Privacy Policy`,
    'n_menu.privacy',
    `个人信息保护政策`,
    `個人資料保護政策`,
    `プライバシーポリシー`,
    ``,
  ],
  terms: [`이용 약관`, `Terms of Service`, 'n_menu.terms', `使用条款`, `使用條款`, `利用規約`, ``],
  app_version: [`앱 버전`, `App version`, 'n_menu.app_version', `App版本`, `App版本`, `アプリのバージョン`, ``],
  logout_modal: [
    `로그아웃 하시겠습니까?`,
    `Are you sure you want to log out?`,
    'n_menu.logout_modal',
    `您确定要登出吗`,
    `您確定要登出嗎`,
    `ログアウトしますか？`,
    ``,
  ],
  leave: [
    `링글 탈퇴하기`,
    `Delete my account`,
    'n_menu.leave',
    `注销Ringle账号`,
    `註銷Ringle會員`,
    `Ringleを退会する`,
    `Xoá tài khoản của tôi`,
  ],
}
export const n_secret_promo = {
  discount_label: (number) => [
    `게릴라 할인 ${number}`,
    `Flash Sale ${number}`,
    'n_secret_promo.discount_label',
    `Flash Sale ${number}`,
    `Flash Sale ${number}`,
    `Flash Sale ${number}`,
    ``,
  ],
  benefit: (number) => [
    `프로모션 혜택 ${number}개`,
    `${number} Promotion benefits`,
    'n_secret_promo.benefit',
    `促销福利 ${number}个`,
    `促銷福利 ${number}個`,
    `プロモーション特典 ${number}個`,
    ``,
  ],
  benefit_long_credit_mobile: (number) => [
    `튜터 우선 예약 + 혜택 ${number}개`,
    `Priority Booking + ${number} benefits`,
    'n_secret_promo.benefit_long_credit_mobile',
    `教师优先预约 + ${number}个 福利`,
    `教師優先預約 + ${number}個 福利`,
    `チューター優先予約+特典 ${number}個`,
    ``,
  ],
  benefit_detail: {
    book: [
      `튜터 우선 예약 서비스`,
      `Priority Tutor Booking Service`,
      'n_secret_promo.benefit_detail.book',
      `优先预约教师服务`,
      `優先預約教師服務`,
      `チューター優先予約サービス`,
      ``,
    ],
    conference: [
      `글로벌 커리어 컨퍼런스 티켓 (7만원 상당)`,
      `Global Career Conference ticket`,
      'n_secret_promo.benefit_detail.conference',
      `全球职业大会门票（价值$54美元）`,
      `全球職業大會門票（價值$54美元）`,
      `グローバルキャリアカンファレンスチケット($54相当)`,
      ``,
    ],
    book_pro: [
      `원서/신문 읽기 프로그램 6개 (32만원 상당)`,
      `Read Together Program (6 sessions)`,
      'n_secret_promo.benefit_detail.book_pro',
      `6次英文书籍/新闻阅读课程（6次课程价值$246美元））`,
      `6次英文書籍/新聞閱讀課程（6次課程價值$246美元）`,
      `Read Together Program 6セッション($246相当)`,
      ``,
    ],
    study_club: [
      `링글 스터디 클럽 (4주)`,
      `Ringle Study Club (4 Weeks)`,
      'n_secret_promo.benefit_detail.study_club',
      `Ringle学习小组 (4週）`,
      `Ringle學習小組 (4週）`,
      `Ringleスタディクラブ（4週間）`,
      ``,
    ],
    btn_more: [
      `더 알아보기`,
      `See details`,
      'n_secret_promo.benefit_detail.btn_more',
      `看更多细节`,
      `看更多細節`,
      `詳細を見る`,
      ``,
    ],
    promo_point: (amount) => [
      `즉시 ${amount}P 포인트백`,
      `${amount}P point back`,
      'n_secret_promo.benefit_detail.promo_point',
      `${amount}积分回馈`,
      `${amount}點數回饋`,
      `${amount}P point back`,
      `${amount}P point back`,
    ],
  },
  benefit_modal: {
    title: (number) => [
      <>혜택 ({number}회 수업권)</>,
      <>Benefits ({number} credits)</>,
      'n_secret_promo.benefit_modal.title',
      <>优惠 ({number}次课程券)</>,
      <>優惠 ({number}次課程券)</>,
      <>特典({number}回レッスンチケット)</>,
      ``,
    ],
  },
  line_banner_normal: {
    pc: [
      <>
        고객님께만 보이는 <b>최대 60만원 추가 할인 & 튜터 우선 예약 혜택</b>
      </>,
      <>
        Exclusive offer for you: <b>Up to $462 off & Priority booking</b>
      </>,
      'n_secret_promo.line_banner_normal.pc',
      <>
        特别优惠: <b>最高折扣 $462 & 教师优先预约服务</b>
      </>,
      <>
        特別優惠: <b>最高折扣 $462 & 教師優先預約服務</b>
      </>,
      <>
        お客様にのみ見える<b>最大60万ウォン追加割引＆チューター優先予約の特典</b>
      </>,
      ``,
    ],
    mobile: [
      <>
        고객님께만 보이는 <b>할인가 & 튜터 우선 예약 혜택</b>
      </>,
      <>
        Exclusive offer: <b>Special Price & Priority booking</b>
      </>,
      'n_secret_promo.line_banner_normal.mobile',
      <>
        特别优惠: <b>特别价格 & 教师优先预约服务</b>
      </>,
      <>
        特別優惠: <b>特別價格 & 教師優先預約服務</b>
      </>,
      <>
        お客様にのみ見える<b>割引＆チューター優先予約特典</b>
      </>,
      ``,
    ],
  },
  line_banner_7_days_left: {
    pc: (n) => [
      <>
        기존 고객 한정! 24년 최저가 할인 <span className="text-red-500">곧 마감! D-{n}</span>
      </>,
      <>
        2024's Best Discounts for Existing Customers <span className="text-red-500">ends soon! D-{n}</span>
      </>,
      'n_secret_promo.line_banner_7_days_left.pc',
      <>
        老朋友限定! 2024年最低价格 <span className="text-red-500">结束倒数! D-{n}</span>
      </>,
      <>
        老朋友限定! 2024年最低價格 <span className="text-red-500">結束倒數! D-{n}</span>
      </>,
      <>
        既存のお客様限定! 24年最安値割引 <span className="text-red-500">間もなく終了！ D-{n}</span>
        <span className="text-red-500">D-{n}</span>
      </>,
      ``,
    ],
    mobile: (n) => [
      <>
        기존 고객 한정! 24년 최저가 할인 <span className="text-red-500">곧 마감! D-{n}</span>
      </>,
      <>
        2024's Best Discounts <span className="text-red-500">ends soon! D-{n}</span>
      </>,
      'n_secret_promo.line_banner_7_days_left.mobile',
      <>
        老朋友限定! 2024年最低价格 <span className="text-red-500">结束倒数! D-{n}</span>
      </>,
      <>
        老朋友限定! 2024年最低價格 <span className="text-red-500">結束倒數! D-{n}</span>
      </>,
      <>
        既存のお客様限定! 24年最安値割引 <span className="text-red-500">間もなく終了！D-{n}</span>
      </>,
      ``,
    ],
  },
  line_banner_d_day: {
    pc: (time) => [
      <>
        기존 고객 한정! 24년 최저가 할인 <span className="text-red-500">오늘 마감! {time}</span>
      </>,
      <>
        2024's Best Discounts for Existing Customers <span className="text-red-500">ends TODAY! {time}</span>
      </>,
      'n_secret_promo.line_banner_d_day.pc',
      <>
        老朋友限定! 2024年最低价格 <span className="text-red-500">今天结束! D-{time}</span>
      </>,
      <>
        老朋友限定! 2024年最低價格 <span className="text-red-500">今天結束! D-{time}</span>
      </>,
      <>
        既存顧客限定! 24年最安値割引 <span className="text-red-500">本日締め切り！{time}</span>
      </>,
      ``,
    ],
    mobile: (time) => [
      <>
        24년 최저가 할인 <span className="text-red-500">오늘 마감! {time}</span>
      </>,
      <>
        2024's Best Discounts <span className="text-red-500">TODAY! {time}</span>
      </>,
      'n_secret_promo.line_banner_d_day.mobile',
      <>
        老朋友限定! 2024年最低价格 <span className="text-red-500">今天结束! D-{time}</span>
      </>,
      <>
        老朋友限定! 2024年最低價格 <span className="text-red-500">今天結束! D-{time}</span>
      </>,
      <>
        24 年最安値割引 <span className="text-red-500">本日締切！{time}</span>
      </>,
      ``,
    ],
  },
}
export const n_book = {
  tooltip: [
    `당일 수업과 정규 수업 모두 확인할 수 있어요.`,
    `Check last-minute and regular lesson schedules here.`,
    'n_book.tooltip',
    `可以确认所有课程时间`,
    `可以確認所有課程時間`,
    `当日の授業と通常の授業の両方を確認することができます。`,
    ``,
  ],
}
export const n_lesson_review_2 = {
  ai_status_title1_app: [
    `AI 분석 준비중입니다.`,
    `AI analysis is being prepared.`,
    'n_lesson_review_2.ai_status_title1_app',
    `AI分析报告准备中`,
    `AI分析報告準備中`,
    `AI Analysis準備中です。`,
    ``,
  ],
  ai_status_title2_app: [
    `이번 수업은 AI 분석 받지 않기를 선택했어요.`,
    `You chose to turn [AI analysis] off for this lesson.`,
    'n_lesson_review_2.ai_status_title2_app',
    `您未选择获得 [AI分析报告]`,
    `您未選擇獲得 [AI分析報告]`,
    `今回のレッスンはＡＩ分析を受けません。`,
    ``,
  ],
  ai_status_detail2_app: [
    <>
      수업 방식 설정에서 <br />
      진단 받기 여부를 설정할 수 있어요.
    </>,
    <>
      You can change the setting <br />
      for AI analysis under [Set Lesson Style].
    </>,
    'n_lesson_review_2.ai_status_detail2_app',
    `您可以在[课程模式设定]中选择是否进行课程录音`,
    `您可以在[課程模式設定]中選擇是否進行課程錄音`,
    <>
      レッスン方式の設定で
      <br /> AI Analysisを受けるかどうかを設定することができます。
    </>,
    ``,
  ],
  ai_status_title3_app: [
    `이번 수업은 녹음하지 않기를 선택했어요.`,
    `You chose to turn [Record Lesson] off for this lesson.`,
    'n_lesson_review_2.ai_status_title3_app',
    `您选择在此课程中关闭[课程录音]`,
    `您選擇在此課程中關閉[課程錄音]`,
    `今回のレッスンは録音しません。`,
    ``,
  ],
  ai_status_detail3_app: [
    <>
      수업 방식 설정에서 수업 녹음과 <br />
      AI 분석 받기 여부를 설정할 수 있어요.
    </>,
    <>
      You can change the settings <br />
      for recording lessons and receiving <br />
      AI analyses under [Set Lesson Style].
    </>,
    'n_lesson_review_2.ai_status_detail3_app',
    `您可以在[课程模式设定]中选择是否进行课程录音或是取得AI分析报告`,
    `您可以在[课程模式设定]中选择是否进行课程录音或是取得AI分析報告`,
    <>
      レッスン方式の設定で、レッスンの録音と
      <br />
      AI Analysisを受けるかどうかを設定することができます。
    </>,
    ``,
  ],
  ai_status_title4_app: [
    <>
      라이팅 중심 수업은 AI 분석이
      <br />
      제공되지 않습니다.
    </>,
    <>
      {' '}
      AI analysis is not available
      <br />
      for writing-focused lessons.
    </>,
    'n_lesson_review_2.ai_status_title4_app',
    `写作课程不提供AI分析报告`,
    `寫作課程不提供AI分析報告`,
    <>
      ライティング中心の授業では、AI Analysisは
      <br />
      提供されません。
    </>,
    ``,
  ],
  feedback_status_title1_app: [
    `수업 피드백 준비중입니다.`,
    `Tutor's feedback report is being prepared.`,
    'n_lesson_review_2.feedback_status_title1_app',
    `教师评价报告准备中`,
    `教師評價報告準備中`,
    `レッスンのフィードバックを準備中です。`,
    ``,
  ],
  feedback_status_title2_app: [
    <>
      이번 수업은 튜터 피드백 리포트가
      <br />
      제공되지 않습니다.
    </>,
    <>
      Tutor's feedback report is
      <br />
      not available for this lesson.
    </>,
    'n_lesson_review_2.feedback_status_title2_app',
    `这堂课不提供教师评价报告`,
    `這堂課不提供教師評價報告`,
    <>
      今回のレッスンでは、チューターのフィードバックレポートは
      <br />
      提供されません。
    </>,
    ``,
  ],
  feedback_status_title3_app: [
    <>
      라이팅 중심 수업은 튜터 피드백 리포트가
      <br />
      제공되지 않습니다.
    </>,
    <>
      Tutor's feedback report is
      <br />
      not available for writing-focused lessons.
    </>,
    'n_lesson_review_2.feedback_status_title3_app',
    `以写作为主的课程不提供教师评价报告`,
    `以寫作為主的課程不提供教師評價報告`,
    <>
      ライティング中心の授業は、チューターのフィードバックレポートが
      <br />
      提供されません。
    </>,
    ``,
  ],
  script_status_title1_app: [
    `수업 녹음파일과 스크립트를 준비중입니다.`,
    `Audio file is being prepared.`,
    'n_lesson_review_2.script_status_title1_app',
    `课程录音文字档`,
    `課程錄音文字檔`,
    `レッスンの録音ファイルとスクリプトを準備中です。`,
    ``,
  ],
  script_status_title2_app: [
    `이번 수업은 녹음하지 않기를 선택했어요.`,
    `You chose to turn [Record Lesson] off for this lesson.`,
    'n_lesson_review_2.script_status_title2_app',
    `课程录音已可确认`,
    `課程錄音已可確認`,
    `今回のレッスンは録音しません。`,
    ``,
  ],
  script_status_detail2_app: [
    <>
      수업 방식 설정에서 수업 녹음 여부를
      <br /> 설정할 수 있어요.
    </>,
    <>
      You can change the setting <br />
      for recording lessons under [Set Lesson Style].
    </>,
    'n_lesson_review_2.script_status_detail2_app',
    `您可以在[课程模式设定]中选择是否进行课程录音`,
    `您可以在[課程模式設定]中選擇是否進行課程錄音`,
    <>
      レッスン方式設定で録画の有無を
      <br />
      設定することができます。
    </>,
    ``,
  ],
}
export const n_achievement = {
  title: [`내 달성 과제`, `Achievements`, 'n_achievement.title', `成就`, `成就`, `業績`, ``],
  stage: {
    level: (n) => [
      <>Level {n}</>,
      <>Level {n}</>,
      'n_achievement.stage.level',
      <>Level {n}</>,
      <>Level {n}</>,
      <>Level {n}</>,
      <>Level {n}</>,
    ],
    goal: (n) => [
      <>
        {n}/{n}
      </>,
      <>
        {n}/{n}
      </>,
      'n_achievement.stage.goal',
      <>
        {n}/{n}
      </>,
      <>
        {n}/{n}
      </>,
      <>
        {n}/{n}
      </>,
      ``,
    ],
  },
  label: {
    stage_unlocked: [
      `Level Up`,
      `Level Up`,
      'n_achievement.label.stage_unlocked',
      `Level Up`,
      `Level Up`,
      `Level Up`,
      `Level Up`,
    ],
    new: [`New`, `New`, 'n_achievement.label.new', `New`, `New`, `新着情報`, ``],
  },
  info: [
    `다양한 달성 과제를 통해 영어 실력을 높이세요!`,
    `Take your achievements to the next level and enhance your English skills!`,
    'n_achievement.info',
    `Take your achievements to the next level and enhance your English skills!`,
    `Take your achievements to the next level and enhance your English skills!`,
    `Take your achievements to the next level and enhance your English skills!`,
    `Take your achievements to the next level and enhance your English skills!`,
  ],
  coming_soon: {
    title: [
      `Coming soon`,
      `Coming soon`,
      'n_achievement.coming_soon.title',
      `Coming soon`,
      `Coming soon`,
      `近日公開予定`,
      ``,
    ],
    desc: [
      `새로운 달성 과제가 추가됩니다!`,
      `New achievement unveiling soon!`,
      'n_achievement.coming_soon.desc',
      `新的成就很快就会增加！`,
      `新的成就很快就會增加！`,
      `近日中に新しいアチーブメントが追加されます`,
      ``,
    ],
  },
  banner: (n) => [
    <>{n}개의 달성 과제가 다음 단계로 올라갔어요!</>,
    <>Reached {n} new level(s)!</>,
    'n_achievement.banner',
    <>Reached {n} new level(s)!</>,
    <>Reached {n} new level(s)!</>,
    <>Reached {n} new level(s)!</>,
    <>Reached {n} new level(s)!</>,
  ],
  banner_random1: [
    `Take your English to the next level!`,
    `Take your English to the next level!`,
    'n_achievement.banner_random1',
    `Take your English to the next level!`,
    `Take your English to the next level!`,
    `Take your English to the next level!`,
    `Take your English to the next level!`,
  ],
  banner_random2: [
    `Give yourself a high five - you deserve it!`,
    `Give yourself a high five - you deserve it!`,
    'n_achievement.banner_random2',
    `Give yourself a high five - you deserve it!`,
    `Give yourself a high five - you deserve it!`,
    `Give yourself a high five - you deserve it!`,
    `Give yourself a high five - you deserve it!`,
  ],
  banner_random3: [
    `You've turned effort into achievement!`,
    `You've turned effort into achievement!`,
    'n_achievement.banner_random3',
    `You've turned effort into achievement!`,
    `You've turned effort into achievement!`,
    `You've turned effort into achievement!`,
    `You've turned effort into achievement!`,
  ],
  banner_random4: [
    `Your dedication is your superpower!`,
    `Your dedication is your superpower!`,
    'n_achievement.banner_random4',
    `Your dedication is your superpower!`,
    `Your dedication is your superpower!`,
    `Your dedication is your superpower!`,
    `Your dedication is your superpower!`,
  ],
  banner_random5: [
    `Celebrate this win, gear up for the next level!`,
    `Celebrate this win, gear up for the next level!`,
    'n_achievement.banner_random5',
    `Celebrate this win, gear up for the next level!`,
    `Celebrate this win, gear up for the next level!`,
    `Celebrate this win, gear up for the next level!`,
    `Celebrate this win, gear up for the next level!`,
  ],
  banner_random6: [
    `You're not just learning; you're excelling!`,
    `You're not just learning; you're excelling!`,
    'n_achievement.banner_random6',
    `You're not just learning; you're excelling!`,
    `You're not just learning; you're excelling!`,
    `You're not just learning; you're excelling!`,
    `You're not just learning; you're excelling!`,
  ],
  banner_random7: [
    `This success is a testament to your hard work!`,
    `This success is a testament to your hard work!`,
    'n_achievement.banner_random7',
    `This success is a testament to your hard work!`,
    `This success is a testament to your hard work!`,
    `This success is a testament to your hard work!`,
    `This success is a testament to your hard work!`,
  ],
  banner_random8: [
    `Your progress is your story of triumph!`,
    `Your progress is your story of triumph!`,
    'n_achievement.banner_random8',
    `Your progress is your story of triumph!`,
    `Your progress is your story of triumph!`,
    `Your progress is your story of triumph!`,
    `Your progress is your story of triumph!`,
  ],
  banner_random9: [
    `You're climbing the ladder of success!`,
    `You're climbing the ladder of success!`,
    'n_achievement.banner_random9',
    `You're climbing the ladder of success!`,
    `You're climbing the ladder of success!`,
    `You're climbing the ladder of success!`,
    `You're climbing the ladder of success!`,
  ],
  banner_random10: [
    `Fantastic job! You're putting in the real work!`,
    `Fantastic job! You're putting in the real work!`,
    'n_achievement.banner_random10',
    `Fantastic job! You're putting in the real work!`,
    `Fantastic job! You're putting in the real work!`,
    `Fantastic job! You're putting in the real work!`,
    `Fantastic job! You're putting in the real work!`,
  ],
  noti: {
    title1: [
      `첫 수업 완료!`,
      `First lesson completed!`,
      'n_achievement.noti.title1',
      `完成第一堂课！`,
      `完成第一堂課！`,
      `初めてのレッスン終了！`,
      `First lesson completed!`,
    ],
    desc1: [
      `“열정적인 영어 갓생러”를 확인하세요.`,
      `Check out your progress for “English Enthusiast”`,
      'n_achievement.noti.desc1',
      `确认 “热情的英语学习者”的达成状态`,
      `確認 “熱情的英文學習者”的達成狀態`,
      `“English Enthusiast”の進捗を確認しましょう`,
      `Check out your progress for “English Enthusiast”`,
    ],
    title2: [
      `첫 학습 완료!`,
      `First study completed!`,
      'n_achievement.noti.title2',
      `完成第一次学习！`,
      `完成第一次學習！`,
      `初めての学習完了！`,
      `First study completed!`,
    ],
    desc2: [
      `“성실한 영어 개근왕”을 확인하세요.`,
      `Check out your progress for “Attendance Ace”`,
      'n_achievement.noti.desc2',
      `确认 “出席王”的达成状态`,
      `確認 “出席王”的達成狀態`,
      `“Attendance Ace”の進捗を確認しましょう`,
      `Check out your progress for “Attendance Ace”`,
    ],
    title3: [
      `축하드려요! 달성한 과제가 있어요.`,
      `Reached a new level!`,
      'n_achievement.noti.title3',
      `达成新等级！`,
      `達成新等級！`,
      `次のレベルに到達しました！`,
      `Reached a new level!`,
    ],
    desc3: [
      `“{achievement_title}”의 다음 단계를 확인해 보세요.`,
      `Discover how to reach the next level for “{achievement title}”`,
      'n_achievement.noti.desc3',
      `确认如何达成下个等级 “{achievement title}”`,
      `確認如何達成下個等級 “{achievement title}”`,
      `次のレベルに到達する方法を確認する “{achievement title}”`,
      `Discover how to reach the next level for “{achievement title}”`,
    ],
  },
}
export const n_welcome = {
  title: {
    main_title: (name) => [
      <>
        {name}님,
        <br />
        링글 가입을 환영해요!
      </>,
      <>
        {name},<br />
        Welcome to Ringle!
      </>,
      'n_welcome.title.main_title',
      <>
        {name},<br />
        欢迎来到Ringle!
      </>,
      <>
        {name},<br />
        歡迎來到Ringle!
      </>,
      <>
        さん
        {name} さん,
        <br />
        Ringle加入を歓迎します!
      </>,
      ``,
    ],
    subheader: (name) => [
      <>{name}님, 환영합니다.</>,
      <> Welcome, {name}.</>,
      'n_welcome.title.subheader',
      <>{name}欢迎你！</>,
      <>{name}歡迎你！</>,
      <> ようこそ, {name}さん.</>,
      ``,
    ],
    header: [
      `링글 수업을 시작해보세요.`,
      `Let's begin your Ringle lessons.`,
      'n_welcome.title.header',
      `现在就开始Ringle课程吧`,
      `現在就開始Ringle課程吧`,
      `Ringleのレッスンを始めましょう`,
      ``,
    ],
  },
  subtitle: [
    <>신규 회원에게만 제공되는 혜택을 확인해보세요.</>,
    <>
      Check out the benefits available
      <br />
      to our newcomers only.
    </>,
    'n_welcome.subtitle',
    <>请确认新会员专属优惠！</>,
    <>請確認新會員專屬優惠！</>,
    <>新規会員限定の特典をご確認ください。</>,
    ``,
  ],
  benefit_one: {
    title: [
      `신규회원 할인가에 무료 수업권 받기`,
      `Get up to 3 extra credits & extra discounts`,
      'n_welcome.benefit_one.title',
      `活动期间获得免费课程券`,
      `活動期間獲得免費課程券`,
      `最大3クレジット追加＆追加割引`,
      ``,
    ],
    content: [
      `체험 없이 구매한 경우에만 무료 수업권을 드려요!`,
      `Free credits only if you buy without a trial!`,
      'n_welcome.benefit_one.content',
      `无体验即购买还能获得免费课程券！`,
      `無體驗即購買還能獲得免費課程券！`,
      `無料クレジットは、トライアルなしで購入した場合のみ！`,
      ``,
    ],
    button: [`지금 구매하기`, `Buy now`, 'n_welcome.benefit_one.button', `Buy now`, `Buy now`, `Buy now`, ``],
    label_early_bird: (day) => [
      <>얼리버드 종료 D-{day}</>,
      <>Earlybird Promotion D-{day}</>,
      'n_welcome.benefit_one.label_early_bird',
      <>早鸟优惠 D-{day}</>,
      <>早鳥優惠 D-{day}</>,
      <>Alybird プロモーション D-{day} Alybird キャンペーン</>,
      ``,
    ],
    label_promotion_2024: [
      `프로모션 한정`,
      `Special Offer`,
      'n_welcome.benefit_one.label_promotion_2024',
      `Special Offer`,
      `Special Offer`,
      `Special Offer`,
      ``,
    ],
    label_promotion_d_day: (day) => [
      <>정규 프로모션 D-{day}</>,
      <>New Year Promotion D-{day}</>,
      'n_welcome.benefit_one.label_promotion_d_day',
      <>一般促销活动 D-{day}</>,
      <>一般促銷活動 D-{day}</>,
      <>Regular Promotion D-{day} 正規プロモーション</>,
      ``,
    ],
    title2: [``, ``, 'n_welcome.benefit_one.title2', ``, ``, ``, ``],
    content2: [
      `첫 수업 불만족 시 100% 환불`,
      `100% refund for first lesson satisfaction`,
      'n_welcome.benefit_one.content2',
      `首次课程不满意可100%退费`,
      `首次課程不滿意可100%退費`,
      `初回のコースにご満足いただけない場合、100%返金いたします。`,
      ``,
    ],
  },
  benefit_two: {
    free_title: [
      `무료 체험 신청하기`,
      `Try a free trial`,
      'n_welcome.benefit_two.free_title',
      `Try a free trial`,
      `Try a free trial`,
      `Try a free trial`,
      ``,
    ],
    free_content: [
      `친구가 선물한 무료 체험수업, 24시간 동안만 체험할 수 있어요.`,
      `Enjoy your free trial lesson only available for 24 hours.`,
      'n_welcome.benefit_two.free_content',
      `朋友赠送的免费体验课程只能在24小时内使用`,
      `朋友贈送的免費體驗課程只能在24小時內使用`,
      `友達からプレゼントされた無料体験レッスン、24時間だけ体験できます。`,
      ``,
    ],
    paid_title: [
      `4,000원에 첫 수업 체험하기`,
      `Get a first lesson discount`,
      'n_welcome.benefit_two.paid_title',
      `首次购买优惠`,
      `首次購買優惠`,
      `初回レッスン割引特典`,
      ``,
    ],
    paid_content: [
      `링글의 첫 수업, 24시간 동안만 4,000원으로 체험할 수 있어요.`,
      `For 24 hours only, don’t miss out on your first lesson at just $3.`,
      'n_welcome.benefit_two.paid_content',
      `24小时内可以美金$3购买第一堂体验课程`,
      `24小時內可以美金$3購買第一堂體驗課程`,
      `Ringleの初回レッスン、24時間限定で4,000ウォンで体験できます。`,
      ``,
    ],
    paid_content_20: [
      `링글의 첫 수업, 24시간 동안만 4,000원으로 체험할 수 있어요.`,
      `For 24 hours only, don’t miss out on your first lesson at just $3.46.`,
      'n_welcome.benefit_two.paid_content_20',
      `24小时内可以美金$3.46购买第一堂体验课程`,
      `24小時內可以美金$3.46購買第一堂體驗課程`,
      `Ringleの初回レッスン、24時間限定で4,000ウォンで体験できます。`,
      ``,
    ],
    button: [
      `지금 체험하기`,
      `Get it now`,
      'n_welcome.benefit_two.button',
      `马上申请`,
      `馬上申請`,
      `今すぐ申し込む`,
      ``,
    ],
  },
  benefit_one_updated: {
    content: [
      `가입 후 24시간 내 구매 시 무료 수업권을 드려요!`,
      ``,
      'n_welcome.benefit_one_updated.content',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  purchase: {
    d_day: (amount) => [
      <>프로모션 종료 D-{amount}</>,
      <>D-{amount} till end of promotion</>,
      'n_welcome.purchase.d_day',
      <>优惠倒数 D-{amount}</>,
      <>優惠倒數 D-{amount}</>,
      <>特典終了までD-{amount}日</>,
      ``,
    ],
    title_promo: [
      `프로모션 패키지 구매하기`,
      `Buy promotional packages`,
      'n_welcome.purchase.title_promo',
      `购买优惠活动课程券`,
      `購買優惠活動課程券`,
      `特典のクレジットを購入する`,
      ``,
    ],
    title_normal: [
      `링글 패키지 구매하기`,
      `Buy Ringle package`,
      'n_welcome.purchase.title_normal',
      `第一次购买课程券`,
      `第一次購買課程券`,
      `最初のクレジットを購入する`,
      ``,
    ],
    benefit_promo: [
      <>최대 34% 할인</>,
      <>Up to 34% off</>,
      'n_welcome.purchase.benefit_promo',
      <>最高折扣34%</>,
      <>最高折扣34%</>,
      <>最大 34% off</>,
      ``,
    ],
    benefit_newbie_1: [
      `첫 수업 후 불만족시 100% 환불`,
      `100% refund if unsatisfied after first lesson`,
      'n_welcome.purchase.benefit_newbie_1',
      `第一堂课后不满意时可100%退费`,
      `第一堂課後不滿意時可100%退費`,
      `初回レッスンにご満足いただけなければ全額返金`,
      ``,
    ],
    benefit_newbie_2: [
      `AI 스피킹 파트너 이용권 증정`,
      `AI Speaking Tickets provided`,
      'n_welcome.purchase.benefit_newbie_2',
      `AI 口语通行证已提供`,
      `AI 口語通行證已提供`,
      `AI スピーキングチケットを提供`,
      ``,
    ],
  },
  trial: {
    title_normal: [
      `20분 수업 체험하기`,
      `Get 20-min trial lesson`,
      'n_welcome.trial.title_normal',
      `体验20分钟课程`,
      `體驗20分鐘課程`,
      `20分の体験レッスンを受ける`,
      ``,
    ],
    title_free: [
      `20분 수업 무료 체험하기`,
      `Get free 20-min trial lesson`,
      'n_welcome.trial.title_free',
      `免费体验20分钟课程`,
      `免費體驗20分鐘課程`,
      `20分の無料体験レッスンを受ける`,
      ``,
    ],
    content_normal: [
      `36,000원 수업 최저 4,000원에 들어보세요.`,
      `Take a $28 lesson for just $3.46.`,
      'n_welcome.trial.content_normal',
      `$28的课程用$3.46的价格就能获得`,
      `$28的課程用$3.46的價格就能獲得`,
      `$28のレッスンを、$3.46で受講できます`,
      ``,
    ],
    content_free: [
      `친구 혜택으로 무료로 들어보세요.`,
      `Take a free lesson as a gift from your friend.`,
      'n_welcome.trial.content_free',
      `用朋友邀请码加入获得免费体验`,
      `用朋友邀請碼加入獲得免費體驗`,
      `友人からの紹介特典により無料レッスンが受けられます`,
      ``,
    ],
  },
}
export const n_early_promo = {
  line_banner_7_days_left: {
    PC: (dday) => [
      <>
        <span className="text-[#D7FF6A]">최대 55만원 할인!</span> 신년 프로모션 얼리버드{' '}
        <span className="text-[#D7FF6A]">종료까지 D-{dday}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! D-{dday}</span>
      </>,
      'n_early_promo.line_banner_7_days_left.PC',
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! D-{dday}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! D-{dday}</span>
      </>,
      ``,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! D-{dday}</span>
      </>,
    ],
    mobile: (dday) => [
      <>
        <span className="text-[#D7FF6A]">최대 55만원 할인!</span> 신년 얼리버드{' '}
        <span className="text-[#D7FF6A]">종료까지 D-{dday}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird ends soon!{' '}
        <span className="text-[#D7FF6A]">D-{dday}</span>
      </>,
      'n_early_promo.line_banner_7_days_left.mobile',
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird ends soon!{' '}
        <span className="text-[#D7FF6A]">D-{dday}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird ends soon!{' '}
        <span className="text-[#D7FF6A]">D-{dday}</span>
      </>,
      ``,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird ends soon!{' '}
        <span className="text-[#D7FF6A]">D-{dday}</span>
      </>,
    ],
  },
  line_banner_d_days: {
    PC: (time) => [
      <>
        <span className="text-[#D7FF6A]">최대 55만원 할인!</span> 신년 프로모션 얼리버드{' '}
        <span className="text-[#D7FF6A]">종료까지 {time}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! {time}</span>
      </>,
      'n_early_promo.line_banner_d_days.PC',
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! {time}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! {time}</span>
      </>,
      ``,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> New Year Early Bird Offer{' '}
        <span className="text-[#D7FF6A]">ends soon! {time}</span>
      </>,
    ],
    mobile: (time) => [
      <>
        <span className="text-[#D7FF6A]">최대 55만원!</span> 신년 얼리버드{' '}
        <span className="text-[#D7FF6A]">종료까지 {time}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird{' '}
        <span className="text-[#D7FF6A]">ends in {time}</span>
      </>,
      'n_early_promo.line_banner_d_days.mobile',
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird{' '}
        <span className="text-[#D7FF6A]">ends in {time}</span>
      </>,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird{' '}
        <span className="text-[#D7FF6A]">ends in {time}</span>
      </>,
      ``,
      <>
        <span className="text-[#D7FF6A]">Up to $423 off!</span> Early Bird{' '}
        <span className="text-[#D7FF6A]">ends in {time}</span>
      </>,
    ],
  },
}
export const n_regular_promo = {
  line_banner_7_days_left: {
    mobile: (dday) => [
      <>
        <span className="text-[#D21D21]">신년 프로모션</span> <span className="text-black">24년 최대 할인</span>{' '}
        <span className="text-[#D21D21]">종료까지 D-{dday}</span>
      </>,
      <>
        <span className="text-black">2024's Biggest Discounts</span>{' '}
        <span className="text-[#D21D21]">Ends soon! D-{dday}</span>
      </>,
      'n_regular_promo.line_banner_7_days_left.mobile',
      <>
        <span className="text-black">2024's Biggest Discounts</span>{' '}
        <span className="text-[#D21D21]">Ends soon! D-{dday}</span>
      </>,
      <>
        <span className="text-black">2024's Biggest Discounts</span>{' '}
        <span className="text-[#D21D21]">Ends soon! D-{dday}</span>
      </>,
      <>
        <span className="text-black">2024's Biggest Discounts</span>{' '}
        <span className="text-[#D21D21]">Ends soon! D-{dday}</span>
      </>,
      <>
        <span className="text-black">2024's Biggest Discounts</span>{' '}
        <span className="text-[#D21D21]">Ends soon! D-{dday}</span>
      </>,
    ],
  },
  line_banner_d_days: {
    PC: (time) => [
      <>
        <span className="text-[#FFDA66]">24년 최대 할인</span> <span className="text-white">신년 프로모션</span>{' '}
        <span className="text-[#FFDA66]">종료까지 {time}</span>
      </>,
      <>
        <span className="text-[#FFDA66]">2024's Biggest Discounts</span>{' '}
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends soon! {time}</span>
      </>,
      'n_regular_promo.line_banner_d_days.PC',
      <>
        <span className="text-[#FFDA66]">2024's Biggest Discounts</span>{' '}
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends soon! {time}</span>
      </>,
      <>
        <span className="text-[#FFDA66]">2024's Biggest Discounts</span>{' '}
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends soon! {time}</span>
      </>,
      <>
        <span className="text-[#FFDA66]">2024's Biggest Discounts</span>{' '}
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends soon! {time}</span>
      </>,
      <>
        <span className="text-[#FFDA66]">2024's Biggest Discounts</span>{' '}
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends soon! {time}</span>
      </>,
    ],
    mobile: (time) => [
      <>
        <span className="text-white">신년 프로모션</span> <span className="text-[#FFDA66]">종료까지 {time}</span>
      </>,
      <>
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends in {time}</span>
      </>,
      'n_regular_promo.line_banner_d_days.mobile',
      <>
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends in {time}</span>
      </>,
      <>
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends in {time}</span>
      </>,
      <>
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends in {time}</span>
      </>,
      <>
        <span className="text-white">New Year Promotion</span> <span className="text-[#FFDA66]">ends in {time}</span>
      </>,
    ],
  },
}
export const n_bsf = {
  noti: {
    page_title: [
      `Bite sized Feedback`,
      `Bite sized Feedback`,
      'n_bsf.noti.page_title',
      `Bite sized Feedback`,
      `Bite sized Feedback`,
      `Bite sized Feedback`,
      `Bite sized Feedback`,
    ],
    page_desc: [
      `설정한 시간에 Bite Sized Feedback 알림을 앱 푸시로 받을 수 있습니다.`,
      `Receive push notifications for Bite on your preferred day and time.`,
      'n_bsf.noti.page_desc',
      `Receive push notifications for Bite on your preferred day and time.`,
      `Receive push notifications for Bite on your preferred day and time.`,
      `Receive push notifications for Bite on your preferred day and time.`,
      `Receive push notifications for Bite on your preferred day and time.`,
    ],
    toggle_1: [
      `앱 푸시`,
      `Push notification`,
      'n_bsf.noti.toggle_1',
      `软件推送通知`,
      `APP推播通知`,
      `アプリのプッシュ通知`,
      `Thông báo điện thoại`,
    ],
    setting_title: [
      `알림 설정`,
      `Notifications`,
      'n_bsf.noti.setting_title',
      `通知提示`,
      `通知設定`,
      `お知らせ設定`,
      `Notifications`,
    ],
    setting_desc: [
      `원활한 연습활동을 위해 말하기가 편안한 시간대를 추천드려요!`,
      `It's best to schedule practices on times when you can speak out loud!`,
      'n_bsf.noti.setting_desc',
      `It's best to schedule practices on times when you can speak out loud!`,
      `It's best to schedule practices on times when you can speak out loud!`,
      `It's best to schedule practices on times when you can speak out loud!`,
      `It's best to schedule practices on times when you can speak out loud!`,
    ],
    label: [`추천`, `Recommended`, 'n_bsf.noti.label', `Recommended`, `Recommended`, `Recommended`, `Recommended`],
    msg_1: [
      `요일을 선택하고 바이트 사이즈 피드백을 받아보세요!`,
      `Choose the day to get Bite Sized Feedback!`,
      'n_bsf.noti.msg_1',
      `Choose the day to get Bite Sized Feedback!`,
      `Choose the day to get Bite Sized Feedback!`,
      `Choose the day to get Bite Sized Feedback!`,
      `Choose the day to get Bite Sized Feedback!`,
    ],
    msg_2: [
      `언제든 결심한 순간은 빛나는 법이에요. 🌟`,
      `A great start to shine even brighter. 🌟`,
      'n_bsf.noti.msg_2',
      `A great start to shine even brighter. 🌟`,
      `A great start to shine even brighter. 🌟`,
      `A great start to shine even brighter. 🌟`,
      `A great start to shine even brighter. 🌟`,
    ],
    msg_3: [
      `꾸준함으로 영어 실력을 늘려봅시다! 💪`,
      `Consistency is key to improving your English! 💪`,
      'n_bsf.noti.msg_3',
      `Consistency is key to improving your English! 💪`,
      `Consistency is key to improving your English! 💪`,
      `Consistency is key to improving your English! 💪`,
      `Consistency is key to improving your English! 💪`,
    ],
    msg_4: [
      `불타는 의지! 링글팀이 응원해요. 🔥`,
      `You're on fire! We're rooting for you. 🔥`,
      'n_bsf.noti.msg_4',
      `You're on fire! We're rooting for you. 🔥`,
      `You're on fire! We're rooting for you. 🔥`,
      `You're on fire! We're rooting for you. 🔥`,
      `You're on fire! We're rooting for you. 🔥`,
    ],
    msg_5: [
      `매일 노력하는 당신, 정말 대단해요! 👍`,
      `Your determination is outstanding!`,
      'n_bsf.noti.msg_5',
      `Your determination is outstanding!`,
      `Your determination is outstanding!`,
      `Your determination is outstanding!`,
      `Your determination is outstanding!`,
    ],
    toggle_2: [
      `리마인더 알림`,
      `Reminder alerts`,
      'n_bsf.noti.toggle_2',
      `Reminder alerts`,
      `Reminder alerts`,
      `Reminder alerts`,
      `Reminder alerts`,
    ],
    reminder_desc: [
      `완료하지 못한 복습이 있는 경우 한 번 더 알려드려요. 1차 알림 시간 이후 선택할 수 있어요.`,
      `Alert sent to remind you to finish reviewing Today's Bite`,
      'n_bsf.noti.reminder_desc',
      `Alert sent to remind you to finish reviewing Today's Bite`,
      `Alert sent to remind you to finish reviewing Today's Bite`,
      `Alert sent to remind you to finish reviewing Today's Bite`,
      `Alert sent to remind you to finish reviewing Today's Bite`,
    ],
    toast_time: [
      `알림 시간을 변경했어요.`,
      `Notification time has been successfully changed`,
      'n_bsf.noti.toast_time',
      `Notification time has been successfully changed`,
      `Notification time has been successfully changed`,
      `Notification time has been successfully changed`,
      `Notification time has been successfully changed`,
    ],
  },
}
export const n_ai_analysis = {
  info_popup_btn: [`제출`, `Submit`, 'n_ai_analysis.info_popup_btn', `送出`, `送出`, `提出する`, `Submit`],
  banner_title: [
    `내 영어는 상위 몇 프로일까요?`,
    `Rank my English!`,
    'n_ai_analysis.banner_title',
    `我的英文能力排名前百分之几呢？`,
    `我的英文能力排名前百分之幾呢？`,
    `私の英語力はどの程度でしょうか？`,
    `Rank my English!`,
  ],
  banner_detail: [
    `나의 정보를 입력하고 같은 직군의 링글러와 AI 분석 레벨을 비교해보세요.`,
    `Check your standing against others in your industry.`,
    'n_ai_analysis.banner_detail',
    `和自己于同样领域工作的Ringle学习者比较AI分析结果`,
    `和自己於同樣領域工作的Ringle學習者比較AI分析結果`,
    `同業界/同業種のRinglerとAI-analyzed levelsを比較してみましょう。`,
    `Check your standing against others in your industry.`,
  ],
  banner_btn: [`조회하기`, `Go`, 'n_ai_analysis.banner_btn', `查询`, `查詢`, `比較する`, `Go`],
  info_popup_q1: [
    `직업을 선택해 주세요.*`,
    `Select your occupation.*`,
    'n_ai_analysis.info_popup_q1',
    `请选择职业*`,
    `請選擇職業*`,
    `職業/職種を選択してください`,
    `Select your occupation.*`,
  ],
  info_popup_q2: [
    `재직하는 형태에 가장 가까운 곳을 선택해 주세요.*`,
    `Select the option that best describes where you work.*`,
    'n_ai_analysis.info_popup_q2',
    `选择最接近您工作情况的描述 *`,
    `選擇最接近您工作情況的描述 *`,
    `勤務の状況にもっとも近いものを選択してください`,
    `Select the option that best describes where you work.*`,
  ],
  info_popup_q3: [
    `업무 경력을 선택해 주세요.*`,
    `Select how long you have worked in your field.*`,
    'n_ai_analysis.info_popup_q3',
    `请选择工作年资*`,
    `請選擇工作年資*`,
    `勤続年数を選択してください`,
    `Select how long you have worked in your field.*`,
  ],
  info_popup_q4: [
    `재직하고 있는 회사명을 작성해 주세요. (선택)`,
    `Enter the name of the company you work for. (optional)`,
    'n_ai_analysis.info_popup_q4',
    `请填写在职公司名称 (选填)`,
    `請填寫在職公司名稱 (選填)`,
    `勤務先を入力してください`,
    `Enter the name of the company you work for. (optional)`,
  ],
  info_popup_q5: [
    `해외에서 3개월 이상 거주한 경험이 있다면, 해당하는 경우를 모두 선택해주세요.*`,
    `If you have lived abroad for more than 3 months, select all terms that fit your experience.*`,
    'n_ai_analysis.info_popup_q5',
    `如有在海外居住3个月以上的经验，请选择符合情况的叙述*`,
    `如有在海外居住3個月以上的經驗，請選擇符合情況的敘述*`,
    `3か月以上海外に在住していた場合は、あてはまるものすべて選択してください`,
    `If you have lived abroad for more than 3 months, select all terms that fit your experience.*`,
  ],
  info_popup_q6: [
    `총 해외 거주 기간을 선택해주세요.*`,
    `Select your total length of living abroad.*`,
    'n_ai_analysis.info_popup_q6',
    `请选择海外居住时间*`,
    `請選擇海外居住時間*`,
    `海外在住年数を選択してください`,
    `Select your total length of living abroad.*`,
  ],
  info_popup_occupation1: [
    `마케팅`,
    `Marketing`,
    'n_ai_analysis.info_popup_occupation1',
    `营销`,
    `行銷`,
    `マーケティング`,
    `Marketing`,
  ],
  info_popup_occupation2: [
    `사업 전략`,
    `Business strategy`,
    'n_ai_analysis.info_popup_occupation2',
    `事业战略`,
    `事業戰略`,
    `企画・戦略`,
    `Business strategy`,
  ],
  info_popup_occupation3: [`영업`, `Sales`, 'n_ai_analysis.info_popup_occupation3', `业务`, `業務`, `営業`, `Sales`],
  info_popup_occupation4: [
    `개발`,
    `Developer`,
    'n_ai_analysis.info_popup_occupation4',
    `开发`,
    `開發`,
    `ディベロッパー`,
    `Developer`,
  ],
  info_popup_occupation5: [
    `PM/UX/서비스기획`,
    `PM/UX/UI`,
    'n_ai_analysis.info_popup_occupation5',
    `PM/UX/服务企划`,
    `PM/UX/服務企劃`,
    `PM/UX/UI`,
    `PM/UX/UI`,
  ],
  info_popup_occupation6: [
    `연구원/교수`,
    `Researcher/Professor`,
    'n_ai_analysis.info_popup_occupation6',
    `研究员/教授`,
    `研究員/教授`,
    `研究者・教授`,
    `Researcher/Professor`,
  ],
  info_popup_occupation7: [
    `의료인`,
    `Medical professional`,
    'n_ai_analysis.info_popup_occupation7',
    `医疗工作者`,
    `醫療工作者`,
    `医療従事者`,
    `Medical professional`,
  ],
  info_popup_occupation8: [`기타`, `Other`, 'n_ai_analysis.info_popup_occupation8', `其他`, `其他`, `その他`, `Other`],
  info_popup_occupation9: [
    `마케팅`,
    `Marketing`,
    'n_ai_analysis.info_popup_occupation9',
    `营销`,
    `行銷`,
    `マーケティング`,
    `Marketing`,
  ],
  info_popup_occupation10: [
    `사업 전략`,
    `Business strategy`,
    'n_ai_analysis.info_popup_occupation10',
    `事业战略`,
    `事業戰略`,
    `企画・戦略`,
    `Business strategy`,
  ],
  info_popup_occupation11: [`영업`, `Sales`, 'n_ai_analysis.info_popup_occupation11', `业务`, `業務`, `営業`, `Sales`],
  info_popup_occupation12: [
    `개발`,
    `Developer`,
    'n_ai_analysis.info_popup_occupation12',
    `开发`,
    `開發`,
    `ディベロッパー`,
    `Developer`,
  ],
  info_popup_occupation13: [
    `PM/UX/서비스기획`,
    `PM/UX/UI`,
    'n_ai_analysis.info_popup_occupation13',
    `PM/UX/服务企划`,
    `PM/UX/服務企劃`,
    `PM/UX/UI`,
    `PM/UX/UI`,
  ],
  info_popup_occupation_placeholder: [
    `직군을 입력해주세요.`,
    `Enter your field of work.`,
    'n_ai_analysis.info_popup_occupation_placeholder',
    `请输入职业类别`,
    `請輸入職業類別`,
    `職業/職種を入力してください`,
    `Enter your field of work.`,
  ],
  info_popup_company1: [
    `한국에서 본사에서 일함`,
    `I work at the company's headquarters in my country.`,
    'n_ai_analysis.info_popup_company1',
    `在国内总公司工作`,
    `在國內總公司工作`,
    `日本本社勤務`,
    `I work at the company's headquarters in my country.`,
  ],
  info_popup_company2: [
    `한국에서 지사에서 일함 (외국계)`,
    `I work at the company's regional office in my country.`,
    'n_ai_analysis.info_popup_company2',
    `在国内分公司工作`,
    `在國內分公司工作`,
    `日本国内の支社・支店勤務`,
    `I work at the company's regional office in my country.`,
  ],
  info_popup_company3: [
    `해외에서 본사에서 일함`,
    `I work at the company's headquarters located overseas.`,
    'n_ai_analysis.info_popup_company3',
    `在海外的总部工作`,
    `在海外的總部工作`,
    `日本国外の本社勤務`,
    `I work at the company's headquarters located overseas.`,
  ],
  info_popup_company4: [
    `해외에서 지사에서 일함`,
    `I work at the company's regional office located overseas.`,
    'n_ai_analysis.info_popup_company4',
    `在海外分公司工作`,
    `在海外分公司工作`,
    `日本国外の支社・支店勤務`,
    `I work at the company's regional office located overseas.`,
  ],
  info_popup_company5: [`기타`, `Other`, 'n_ai_analysis.info_popup_company5', `其他`, `其他`, `その他`, `Other`],
  info_popup_company_placeholder: [
    `재직 형태를 입력해주세요.`,
    `Describe your work location.`,
    'n_ai_analysis.info_popup_company_placeholder',
    `请输入就职情况`,
    `請輸入就職情況`,
    `勤務形態を入力してください`,
    `Describe your work situation.`,
  ],
  info_popup_period1: [
    `1년 이하`,
    `Less than 1 year`,
    'n_ai_analysis.info_popup_period1',
    `1年以下`,
    `1年以下`,
    `1年以下`,
    `Less than 1 year`,
  ],
  info_popup_period2: [
    `1 - 3년`,
    `1 - 3 years`,
    'n_ai_analysis.info_popup_period2',
    `1-3年`,
    `1-3年`,
    `1 - 3年`,
    `1 - 3 years`,
  ],
  info_popup_period3: [
    `4 - 6년`,
    `4 - 6 years`,
    'n_ai_analysis.info_popup_period3',
    `4 - 6年`,
    `4 - 6年`,
    `4 - 6年`,
    `4 - 6 years`,
  ],
  info_popup_period4: [
    `7 - 10년`,
    `7 - 10 years`,
    'n_ai_analysis.info_popup_period4',
    `7 - 10年`,
    `7 - 10年`,
    `7 - 10年`,
    `7 - 10 years`,
  ],
  info_popup_period5: [
    `11 - 15년`,
    `11 - 15 years`,
    'n_ai_analysis.info_popup_period5',
    `11 - 15年`,
    `11 - 15年`,
    `11 - 15年`,
    `11 - 15 years`,
  ],
  info_popup_period6: [
    `16 - 20년`,
    `16 - 20 years`,
    'n_ai_analysis.info_popup_period6',
    `16 - 20年`,
    `16 - 20年`,
    `16 - 20年`,
    `16 - 20 years`,
  ],
  info_popup_period7: [
    `20년 이상`,
    `More than 20 years`,
    'n_ai_analysis.info_popup_period7',
    `20年以上`,
    `20年以上`,
    `20年以上`,
    `More than 20 years`,
  ],
  info_popup_companyname_placeholder: [
    `회사명을 입력해주세요.`,
    `Enter the name of the company.`,
    'n_ai_analysis.info_popup_companyname_placeholder',
    `请输入公司名称`,
    `請輸入公司名稱`,
    `勤務先を入力してください`,
    `Enter the name of the company.`,
  ],
  info_popup_liveabroad1: [
    `해당 사항 없음`,
    `Not applicable`,
    'n_ai_analysis.info_popup_liveabroad1',
    `无符合之叙述`,
    `無符合之敘述`,
    `該当なし`,
    `Not applicable`,
  ],
  info_popup_liveabroad2: [
    `영어권, 0 - 9세 사이`,
    `English-speaking country, ages 0 - 9`,
    'n_ai_analysis.info_popup_liveabroad2',
    `英语系国家， 0 - 9岁之间`,
    `英語系國家， 0 - 9歲之間`,
    `英語圏に0 - 9歳の時に在住`,
    `English-speaking country, ages 0 - 9`,
  ],
  info_popup_liveabroad3: [
    `영어권, 10 - 19세 사이`,
    `English-speaking country, ages 10 - 19`,
    'n_ai_analysis.info_popup_liveabroad3',
    `英语系国家10 - 19岁之间`,
    `英語系國家10 - 19歲之間`,
    `英語圏に10 - 19歳の時に在住`,
    `English-speaking country, ages 10 - 19`,
  ],
  info_popup_liveabroad4: [
    `영어권, 20 - 24세 사이`,
    `English-speaking country, ages 20 - 24`,
    'n_ai_analysis.info_popup_liveabroad4',
    `英语系国家, 20 - 24岁之间`,
    `英語系國家, 20 - 24歲之間`,
    `英語圏に20 - 24歳の時に在住`,
    `English-speaking country, ages 20 - 24`,
  ],
  info_popup_liveabroad5: [
    `영어권, 25 - 29세 사이`,
    `English-speaking country, ages 25 - 29`,
    'n_ai_analysis.info_popup_liveabroad5',
    `英语系国家， 25 - 29岁之间`,
    `英語系國家， 25 - 29歲之間`,
    `英語圏に25 - 29歳の時に在住`,
    `English-speaking country, ages 25 - 29`,
  ],
  info_popup_liveabroad6: [
    `영어권, 30 - 39세 사이`,
    `English-speaking country, ages 30 - 39`,
    'n_ai_analysis.info_popup_liveabroad6',
    `英语系国家， 30 - 39岁之间`,
    `英語系國家， 30 - 39歲之間`,
    `英語圏に30 - 39歳の時に在住`,
    `English-speaking country, ages 30 - 39`,
  ],
  info_popup_liveabroad7: [
    `영어권, 40세 이후`,
    `English-speaking country, ages 40 or older`,
    'n_ai_analysis.info_popup_liveabroad7',
    `英语系国家，40岁以后`,
    `英語系國家，40歲以後`,
    `英語圏に40歳以降に在住`,
    `English-speaking country, ages 40 or older`,
  ],
  info_popup_liveabroad8: [
    `비영어권, 0 - 9세 사이`,
    `Non-English-speaking country, ages 0 - 9`,
    'n_ai_analysis.info_popup_liveabroad8',
    `非英语系国家， 0 - 9岁之间`,
    `非英語系國家， 0 - 9歲之間`,
    `非英語圏に0 - 9歳の時に在住`,
    `Non-English-speaking country, ages 0 - 9`,
  ],
  info_popup_liveabroad9: [
    `비영어권, 10 - 19세 사이`,
    `Non-English-speaking country, ages 10 - 19`,
    'n_ai_analysis.info_popup_liveabroad9',
    `非英语系国家，10-19岁之间`,
    `非英語系國家，10-19歲之間`,
    `非英語圏に10 - 19歳の時に在住`,
    `Non-English-speaking country, ages 10 - 19`,
  ],
  info_popup_liveabroad10: [
    `비영어권, 20 - 24세 사이`,
    `Non-English-speaking country, ages 20 - 24`,
    'n_ai_analysis.info_popup_liveabroad10',
    `非英语系国家， 20- 24岁之间`,
    `非英語系國家， 20- 24歲之間`,
    `非英語圏に20 - 24歳の時に在住`,
    `Non-English-speaking country, ages 20 - 24`,
  ],
  info_popup_liveabroad11: [
    `비영어권, 25 - 29세 사이`,
    `Non-English-speaking country, ages 25 - 29`,
    'n_ai_analysis.info_popup_liveabroad11',
    `非英语系国家， 25- 29岁之间`,
    `非英語系國家， 25- 29歲之間`,
    `非英語圏に25 - 29歳の時に在住`,
    `Non-English-speaking country, ages 25 - 29`,
  ],
  info_popup_liveabroad12: [
    `비영어권, 30 - 39세 사이`,
    `Non-English-speaking country, ages 30 - 39`,
    'n_ai_analysis.info_popup_liveabroad12',
    `非英语系国家， 30- 39岁之间`,
    `非英語系國家， 30- 39歲之間`,
    `非英語圏に30 - 39歳の時に在住`,
    `Non-English-speaking country, ages 30 - 39`,
  ],
  info_popup_liveabroad13: [
    `비영어권, 40세 이후`,
    `Non-English-speaking country, ages 40 or older`,
    'n_ai_analysis.info_popup_liveabroad13',
    `非英语系国家，40岁以后`,
    `非英語系國家，40歲以後`,
    `非英語圏に40歳以降に在住`,
    `Non-English-speaking country, ages 40 or older`,
  ],
  info_popup_duration1: [
    `거주 한적 없음`,
    `None`,
    'n_ai_analysis.info_popup_duration1',
    `从未居住过`,
    `從未居住過`,
    `海外在住経験なし`,
    `None`,
  ],
  info_popup_duration2: [
    `3 - 6개월`,
    `3 - 6 months`,
    'n_ai_analysis.info_popup_duration2',
    `3 - 6个月`,
    `3 - 6個月`,
    `3 - 6か月`,
    `3 - 6 months`,
  ],
  info_popup_duration3: [
    `6 - 12개월`,
    `6 - 12 months`,
    'n_ai_analysis.info_popup_duration3',
    `6 - 12个月`,
    `6 - 12個月`,
    `6 - 12か月`,
    `6 - 12 months`,
  ],
  info_popup_duration4: [
    `1 - 3년`,
    `1 - 3 years`,
    'n_ai_analysis.info_popup_duration4',
    `1-3年`,
    `1-3年`,
    `1 - 3年`,
    `1 - 3 years`,
  ],
  info_popup_duration5: [
    `4 - 6년`,
    `4 - 6 years`,
    'n_ai_analysis.info_popup_duration5',
    `4 - 6年`,
    `4 - 6年`,
    `4 - 6年`,
    `4 - 6 years`,
  ],
  info_popup_duration6: [
    `7 - 10년`,
    `7 - 10 years`,
    'n_ai_analysis.info_popup_duration6',
    `7 - 10年`,
    `7 - 10年`,
    `7 - 10年`,
    `7 - 10 years`,
  ],
  info_popup_duration7: [
    `11 - 15년`,
    `11 - 15 years`,
    'n_ai_analysis.info_popup_duration7',
    `11 - 15年`,
    `11 - 15年`,
    `11 - 15年`,
    `11 - 15 years`,
  ],
  info_popup_duration8: [
    `16 - 20년`,
    `16 - 20 years`,
    'n_ai_analysis.info_popup_duration8',
    `16 - 20年`,
    `16 - 20年`,
    `16 - 20年`,
    `16 - 20 years`,
  ],
  info_popup_duration9: [
    `20년 이상`,
    `More than 20 years`,
    'n_ai_analysis.info_popup_duration9',
    `20年以上`,
    `20年以上`,
    `20年以上`,
    `More than 20 years`,
  ],
  job_seg_level: {
    title: [
      `AI 분석 레벨 정보`,
      `AI Analysis Ranking`,
      'n_ai_analysis.job_seg_level.title',
      `我的AI分析等级`,
      `我的AI分析等級`,
      `My AI-analyzed levels`,
      `AI-analyzed Ranking`,
    ],
    my_average: [
      `내 평균 레벨`,
      `My average`,
      'n_ai_analysis.job_seg_level.my_average',
      `我的平均等级`,
      `我的平均等級`,
      `My average level`,
      `My average`,
    ],
    top: [`상위`, `Top`, 'n_ai_analysis.job_seg_level.top', `高级`, `高級`, `Top`, `Top`],
    detail1: [
      `*3000여명의 링글러의 AI 분석 진단 데이터를 토대로 제공됩니다.`,
      `*Compared with AI analysis data of 3,000+ Ringlers.`,
      'n_ai_analysis.job_seg_level.detail1',
      `*根据3000多名Ringle学习者的AI分析结果提供数据`,
      `*根據3000多名Ringle學習者的AI分析結果提供數據`,
      `*3,000人以上のRinglerのAI Analysisデータと比較`,
      `*Compared with the AI analysis data of 3,000+ Ringlers.`,
    ],
    detail2: [
      `*내 평균 레벨은 최근 3개 수업 AI 분석의 평균을 표시합니다.`,
      `*'My average' is calculated from your 3 most recent AI-analyzed lessons.`,
      'n_ai_analysis.job_seg_level.detail2',
      `*我的平均等级为最近3堂课的AI分析平均值`,
      `*我的平均等級為最近3堂課的AI分析平均值`,
      `*My average levelは、直近の3回のレッスンで取得したレベルに基づいて計算されます。`,
      `*'My average' is calculated from your 3 most recent AI-analyzed lessons.`,
    ],
  },
  job_seg_popup: {
    title: [
      `아직 AI 분석 기록이 없어요.`,
      `No AI analysis reports available.`,
      'n_ai_analysis.job_seg_popup.title',
      `目前还没有AI分析记录`,
      `目前還沒有AI分析記錄。`,
      `入手可能なAI Analysis reportがありません`,
      `No AI analysis reports available.`,
    ],
    body1: [
      `직군별 영어 실력을 조회하려면 링글 수업의 AI 분석 결과가 필요합니다. 수업을 듣고 자신의 영어 실력을 확인하세요.`,
      `To compare your English level with others in your industry, you need to perform Ringle's AI analysis first. Take a lesson to assess your English proficiency.`,
      'n_ai_analysis.job_seg_popup.body1',
      `如果想查询各职业的英文能力，需要有Ringle课程AI分析结果。 请进行课程并确认自己的英语能力`,
      `如果想查詢各職業的英文能力，需要有Ringle課程AI分析結果。 請進行課程並確認自己的英語能力`,
      `自分の英語力を同業界/同業種の人と比較するには、過去のAI Analysisの記録が必要です。レッスンを受けて、あなたの英語力を確認しましょう。`,
      `To compare your English proficiency with others in your field of work, you must have previous AI analysis records. Take a lesson to find out how good your English is.`,
    ],
    body2: [
      `직군별 영어 실력을 조회하려면 링글 수업의 AI 분석 결과가 필요합니다. 체험 수업을 듣고 자신의 영어 실력을 확인하세요.`,
      `To compare your English level with others in your industry, you need to perform Ringle's AI analysis first. Take a trial lesson to assess your English proficiency.`,
      'n_ai_analysis.job_seg_popup.body2',
      `如果想查询各职业的英文能力，需要有Ringle课程AI分析结果。 请进行体验课程并确认自己的英语能力`,
      `如果想查詢各職業的英文能力，需要有Ringle課程AI分析結果。 請進行體驗課程並確認自己的英語能力`,
      `自分の英語力を同業界/同業種の人と比較するには、過去のAI Analysisの記録が必要です。体験レッスンを受けて、あなたの英語力を確認しましょう。`,
      `To compare your English proficiency with others in your field of work, you must have previous AI analysis records. Take a trial lesson to find out where your English stands.`,
    ],
    btn1: [
      `수업 예약`,
      `Book a lesson`,
      'n_ai_analysis.job_seg_popup.btn1',
      `预约课程`,
      `預約課程`,
      `レッスン予約`,
      `Book a lesson`,
    ],
    btn2: [
      `체험 신청`,
      `Get a trial lesson`,
      'n_ai_analysis.job_seg_popup.btn2',
      `申请体验`,
      `申請體驗`,
      `体験予約`,
      `Get a trial lesson`,
    ],
    btn3: [
      `수업권 구매`,
      `Buy credits`,
      'n_ai_analysis.job_seg_popup.btn3',
      `购买课程券`,
      `購買課程券`,
      `購入`,
      `Buy credits`,
    ],
    invite_title: [
      `주변 동료에게 링글 AI 분석을 추천하고 싶다면?`,
      ``,
      'n_ai_analysis.job_seg_popup.invite_title',
      ``,
      ``,
      ``,
      ``,
    ],
    invite_title_app: [
      <>
        주변 동료에게 링글 AI 분석을 <br />
        추천하고 싶다면?
      </>,
      `Grow together with Ringle's AI analysis.`,
      'n_ai_analysis.job_seg_popup.invite_title_app',
      `想和同事推荐Ringle AI分析吗？`,
      `想和同事推薦Ringle AI分析嗎？`,
      `同僚にRingle AI Analysisをおすすめしますか？`,
      `Grow together with Ringle's AI analysis.`,
    ],
    invite_detail: [
      `지금 친구 추천하고 무료 체험을 선물하세요.`,
      `Invite your colleagues to Ringle and gift a free trial.`,
      'n_ai_analysis.job_seg_popup.invite_detail',
      `现在邀请朋友送他免费体验`,
      `現在邀請朋友送他免費體驗`,
      `今すぐ友達紹介をして無料レッスンチケットを贈りましょう。`,
      `Invite your colleagues to Ringle and gift a free trial.`,
    ],
    invite_btn: [
      `친구 추천 페이지로 이동`,
      `Invite now`,
      'n_ai_analysis.job_seg_popup.invite_btn',
      `前往邀请朋友`,
      `前往邀請朋友`,
      `友達紹介をする`,
      `Invite now`,
    ],
    dropdown1: [
      `연차`,
      `Career length`,
      'n_ai_analysis.job_seg_popup.dropdown1',
      `工作年资`,
      `工作年資`,
      `勤続年数`,
      `Career length`,
    ],
    dropdown2: [
      `국내/외국계`,
      `Local/Foreign company`,
      'n_ai_analysis.job_seg_popup.dropdown2',
      `国内企业/外企`,
      `國內企業/外商`,
      `日系企業/外資企業`,
      `Local/Foreign company`,
    ],
  },
  info_popup_occupation14: [
    `연구원/교수`,
    `Researcher/Professor`,
    'n_ai_analysis.info_popup_occupation14',
    `研究员/教授`,
    `研究員/教授`,
    `研究者・教授`,
    `Researcher/Professor`,
  ],
  info_popup_occupation15: [
    `의료인`,
    `Medical professional`,
    'n_ai_analysis.info_popup_occupation15',
    `医疗工作者`,
    `醫療工作者`,
    `医療従事者`,
    `Medical professional`,
  ],
  info_popup_occupation16: [
    `법조인`,
    `Legal professional`,
    'n_ai_analysis.info_popup_occupation16',
    `司法人员`,
    `司法人員`,
    `弁護士`,
    `Legal professional`,
  ],
  info_popup_occupation17: [
    `예술/디자인`,
    `Artist/designer`,
    'n_ai_analysis.info_popup_occupation17',
    `艺术/设计`,
    `藝術/設計`,
    `芸術家・デザイナー`,
    `Artist/designer`,
  ],
  info_popup_occupation18: [
    `재무/회계/인사`,
    `Finance/Accounting/HR`,
    'n_ai_analysis.info_popup_occupation18',
    `财务/会计/人事`,
    `財務/會計/人事`,
    `財務会計`,
    `Finance/Accounting/HR`,
  ],
  info_popup_occupation19: [
    `대학(원)생`,
    `University/graduate student`,
    'n_ai_analysis.info_popup_occupation19',
    `大学/研究生`,
    `大學/研究生`,
    `大学生`,
    `University/graduate student`,
  ],
  info_popup_occupation20: [
    `학생 (고등/중등/초등)`,
    `Primary/secondary school student`,
    'n_ai_analysis.info_popup_occupation20',
    `学生 (高中/中学/小学)`,
    `學生 (高中/國中/小學)`,
    `小・中・高校生`,
    `Primary/secondary school student`,
  ],
  job_seg_occupation8: [
    `기타' 분야에서는 Complexity와 Pronunciation 점수가 전체 평균보다 약간 낮은 반면, Accuracy와 Fluency 점수는 전체 평균보다 높습니다. 이는 기타 분야에서의 문법 정확도와 언어의 유창성이 뛰어나지만, 복잡성과 발음 면에서는 약간의 부족함을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation8',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation9: [
    `마케팅' 분야는 Fluency 점수가 전체 평균보다 높은 반면, 나머지 점수들은 전체 평균과 비슷합니다. 이는 마케팅 분야에서 언어의 유창성이 뛰어나다는 것을 의미합니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation9',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation10: [
    `사업 전략' 분야에서는 Complexity와 Fluency 점수가 전체 평균보다 높으나, Accuracy와 Pronunciation 점수는 전체 평균보다 약간 낮습니다. 이는 사업 전략 분야에서 언어의 복잡성과 유창성이 뛰어나지만, 문법 정확도와 발음 면에서는 약간의 부족함을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation10',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation11: [
    `영업' 분야에서는 Accuracy와 Fluency 점수가 전체 평균보다 높으나, Complexity와 Pronunciation 점수는 전체 평균보다 약간 낮습니다. 이는 영업 분야에서 문법 정확도와 언어의 유창성이 뛰어나지만, 복잡성과 발음 면에서는 다소 부족함을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation11',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation12: [
    `개발' 분야에서는 Accuracy 점수만이 전체 평균보다 높고, 나머지 점수들은 전체 평균보다 낮습니다. 이는 개발 분야에서 문법 정확도는 뛰어나지만, 복잡성, 언어의 유창성, 발음 면에서는 부족함을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation12',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation13: [
    `PM/UX/서비스기획' 분야에서는 모든 점수가 전체 평균보다 높습니다. 이는 이 분야에서 언어에 대한 전반적인 능력이 뛰어나다는 것을 의미합니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation13',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation14: [
    `연구원/교수' 분야에서는 모든 점수가 전체 평균보다 높습니다. 이는 연구원과 교수들이 복잡성, 문법 정확도, 언어의 유창성, 발음 등 전반적인 언어 능력이 뛰어나다는 것을 의미합니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation14',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation15: [
    `의료인' 분야에서는 Accuracy와 Fluency 점수가 전체 평균보다 높으나, Complexity와 Pronunciation 점수는 전체 평균보다 약간 낮습니다. 이는 의료인의 경우 문법적 정확성과 언어의 유창성은 뛰어나지만, 언어의 복잡성과 발음에 있어서는 약간의 부족함이 있음을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation15',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation16: [
    `법조인' 분야에서는 모든 점수가 전체 평균보다 높습니다. 이는 법조인들의 언어 능력이 전반적으로 뛰어나다는 것을 의미합니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation16',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation17: [
    `예술/디자인' 분야에서는 Accuracy 점수가 전체 평균보다 높지만, Complexity, Fluency, Pronunciation 점수는 전체 평균보다 낮습니다. 이는 예술가와 디자이너들이 문법적 정확성에는 뛰어나지만, 언어의 복잡성, 유창성, 발음 면에서는 약간의 부족함이 있음을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation17',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation18: [
    `재무/회계/인사' 분야에서는 Complexity와 Fluency 점수가 전체 평균보다 높으나, Accuracy와 Pronunciation 점수는 전체 평균과 비슷합니다. 이는 재무, 회계, 인사 분야에서 언어의 복잡성과 유창성이 뛰어나지만, 문법 정확성과 발음 면에서는 전체 평균과 비슷함을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation18',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation19: [
    `대학(원)생' 분야에서는 모든 점수가 전체 평균보다 높습니다. 이는 대학생과 대학원생들이 언어 능력이 전반적으로 뛰어나다는 것을 의미합니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation19',
    ``,
    ``,
    ``,
    ``,
  ],
  job_seg_occupation20: [
    `학생 (고등/중등/초등)' 분야에서는 Accuracy 점수가 전체 평균보다 매우 높으며, Fluency와 Pronunciation 점수도 전체 평균보다 높지만, Complexity 점수는 전체 평균보다 낮습니다. 이는 학생들이 문법적 정확성과 언어의 유창성, 발음에는 뛰어나지만, 언어의 복잡성에는 약간의 부족함이 있음을 보여줍니다.`,
    ``,
    'n_ai_analysis.job_seg_occupation20',
    ``,
    ``,
    ``,
    ``,
  ],
  job_segment_referral_title1: [
    `주변 동료에게 링글 AI 분석을 추천하고 싶다면?`,
    `Grow together with Ringle's AI analysis.`,
    'n_ai_analysis.job_segment_referral_title1',
    `想和同事推荐Ringle AI分析吗？`,
    `想和同事推薦Ringle AI分析嗎？`,
    `同僚にRingle AI Analysisをおすすめしますか？`,
    `Grow together with Ringle's AI analysis.`,
  ],
  job_segment_referral_title2: [
    `지금 친구 추천하고 무료 체험을 선물하세요.`,
    `Invite your colleagues to Ringle and gift a free trial.`,
    'n_ai_analysis.job_segment_referral_title2',
    `现在邀请朋友送他免费体验`,
    `現在邀請朋友送他免費體驗`,
    `今すぐ友達紹介をして無料レッスンチケットを贈りましょう。`,
    `Invite your colleagues to Ringle and gift a free trial.`,
  ],
  job_segment_referral_cta: [
    `친구 초대 페이지로 이동`,
    `Invite now`,
    'n_ai_analysis.job_segment_referral_cta',
    `前往邀请朋友`,
    `前往邀請朋友`,
    `友達紹介をする`,
    `Invite now`,
  ],
  side_menu_job_segment: [
    `직군별 레벨 비교`,
    `Rank my English`,
    'n_ai_analysis.side_menu_job_segment',
    `职业别从业人员等级`,
    `職業別從業人員等級`,
    `職業/職種別のランク比較`,
    `Rank my English`,
  ],
  side_menu_tooltip: [
    `준비중인 기능이에요.`,
    `It's a feature in preparation.`,
    'n_ai_analysis.side_menu_tooltip',
    `It's a feature in preparation.`,
    `It's a feature in preparation.`,
    `It's a feature in preparation.`,
    `It's a feature in preparation.`,
  ],
}
export const n_banner = {
  download_title: [
    `앱 안정화를 위한 업데이트가 있어요.`,
    `An update for app stabilization.`,
    'n_banner.download_title',
    `An update for app stabilization.`,
    `An update for app stabilization.`,
    `An update for app stabilization.`,
    `An update for app stabilization.`,
  ],
  download_btn: [
    `클릭하고 업데이트`,
    `Click to restart`,
    'n_banner.download_btn',
    `Click to restart`,
    `Click to restart`,
    `Click to restart`,
    `Click to restart`,
  ],
}
export const n_gnb = {
  trial_lesson: {
    pc: [
      <>
        지금 가입하고 <span className="font-bold text-[#FFCB3F]">4,000원</span> 으로 링글 첫 수업을 체험하세요
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
      'n_gnb.trial_lesson.pc',
      <>
        现在用$3优惠价<span className="font-bold text-[#FFCB3F]">进行Ringle第一堂课</span>
      </>,
      <>
        現在用$3優惠價<span className="font-bold text-[#FFCB3F]">進行Ringle第一堂課</span>
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
    ],
    mobile: [
      <>
        지금 가입하고 <span className="font-bold text-[#FFCB3F]">4,000원</span> 으로 첫 수업을 체험하세요
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
      'n_gnb.trial_lesson.mobile',
      <>
        现在用$3优惠价<span className="font-bold text-[#FFCB3F]">进行Ringle第一堂课</span>
      </>,
      <>
        現在用$3優惠價<span className="font-bold text-[#FFCB3F]">進行Ringle第一堂課</span>
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
      <>
        Sign up and start your <span className="font-bold text-[#FFCB3F]">first lesson for $3</span>
      </>,
    ],
  },
}
export const n_upcoming_lessonst = {
  title_last_lesson: [
    <>
      <span className="text-[#503CC8]">지난 수업과 같은 시간</span>에 예약해 보세요!
    </>,
    `Book a highly-rated tutor for the time slot you previously booked!`,
    'n_upcoming_lessonst.title_last_lesson',
    <>
      <span className="text-[#503CC8]">与上次课程相同时间开课的教师</span>一起上课如何呢?
    </>,
    <>
      <span className="text-[#503CC8]">與上次課程相同時間開課的教師</span>一起上課如何呢?
    </>,
    `Book a highly-rated tutor for the time slot you previously booked!`,
    ``,
  ],
  btn_last_lesson: [`수업 예약`, `Book Lessons`, 'n_upcoming_lessonst.btn_last_lesson', `预约`, `預約`, `予約する`, ``],
}
export const n_tutor_list = {
  all: {
    title: [`링글 튜터`, `Ringle Tutors`, 'n_tutor_list.all.title', `Ringle教师`, `Ringle教師`, `Ringleチューター`, ``],
  },
}
export const n_pricing = {
  banner: {
    newbie_benefit: [
      `[신규회원 혜택] 구매 시 추가 수업권`,
      `Bonus Credits Upon Purchase`,
      'n_pricing.banner.newbie_benefit',
      `[新会员优惠] 购买时额外获得免费课程券`,
      `[新會員優惠] 購買時額外獲得免費課程券`,
      `[ 新入会員特典 ] 購入時の追加レッスンチケット`,
      ``,
    ],
    timer: [
      `종료까지 HH:mm:SS`,
      `Ends in HH:mm:SS`,
      'n_pricing.banner.timer',
      `到HH:mm:SS为止`,
      `到HH:mm:SS為止`,
      `終了までHH:mm:SS`,
      ``,
    ],
    repuchase_benefit: [
      `[재구매 혜택] 최대 15만원 추가 할인`,
      ``,
      'n_pricing.banner.repuchase_benefit',
      ``,
      ``,
      ``,
      ``,
    ],
    day_timer: (day) => [`종료까지 D-${day}`, ``, 'n_pricing.banner.day_timer', ``, ``, ``, ``],
    event_sam_card: [
      `삼성카드 최대 12개월 무이자 할부 가능`,
      `[Samsung Card] Pay in up to 12 interest-free monthly installments`,
      'n_pricing.banner.event_sam_card',
      `[Samsung Card] Pay in up to 12 interest-free monthly installments`,
      `[Samsung Card] Pay in up to 12 interest-free monthly installments`,
      `[Samsung Card] Pay in up to 12 interest-free monthly installments`,
      ``,
    ],
    milion_1: [
      `[100만 수업] 결제 시 5만 포인트 추가 할인 (~9.30)`,
      `[Event] Get an additional $38 off at checkout (until 9/30)`,
      'n_pricing.banner.milion_1',
      `[Event] Get an additional $38 off at checkout (until 9/30)`,
      `[Event] Get an additional $38 off at checkout (until 9/30)`,
      `[Event] Get an additional $38 off at checkout (until 9/30)`,
      `[Event] Get an additional $38 off at checkout (until 9/30)`,
    ],
    milion_2: [
      `[100만 수업] 매주 수업듣기 구매 시 11월 4일까지 수강기간 시작 홀딩 (~9.30)`,
      `[Event] Hold your start date until Nov 4th when purchasing Every Week (until 9/30)`,
      'n_pricing.banner.milion_2',
      `[Event] Hold your start date until Nov 4th when purchasing Every Week (until 9/30)`,
      `[Event] Hold your start date until Nov 4th when purchasing Every Week (until 9/30)`,
      `[Event] Hold your start date until Nov 4th when purchasing Every Week (until 9/30)`,
      `[Event] Hold your start date until Nov 4th when purchasing Every Week (until 9/30)`,
    ],
  },
  every_week: {
    title: [`매주 수업듣기`, `Every Week`, 'n_pricing.every_week.title', `每週课程`, `每週課程`, `毎週受講`, ``],
    content_title: [
      `매주 수업듣기`,
      `Every Week Credits`,
      'n_pricing.every_week.content_title',
      `每週课程`,
      `每週課程`,
      `毎週受講`,
      ``,
    ],
    subtitle: [
      `매주 1회 이상 꾸준한 수강을 원하는 분을 위한 패키지입니다.`,
      `Recommended for learners who wish to study English consistently every week`,
      'n_pricing.every_week.subtitle',
      `推荐给希望每週至少上一堂课以上的用户`,
      `推薦給希望每週至少上一堂課以上的用戶`,
      `毎週1回以上の定期的な学習を希望される方のためのレッスンチケットです。`,
      ``,
    ],
  },
  none: {
    title: [`자유 수강`, `Flexible`, 'n_pricing.none.title', `无固定时间课程`, `無固定時間課程`, `自由受講`, ``],
    content_title: [
      `자유 수강`,
      `Flexible Credits`,
      'n_pricing.none.content_title',
      `无固定时间`,
      `無固定時間`,
      `自由受講`,
      ``,
    ],
    subtitle: [
      `내 스케줄에 맞게 자유로운 수강을 원하는 분을 위한 패키지입니다.`,
      `Recommended for learners who wish to study English flexibly without a fixed routine`,
      'n_pricing.none.subtitle',
      `推荐给希望自由安排课程时间的用户`,
      `推薦給希望自由安排課程時間的用戶`,
      `自分の都合に合わせて受講したい方のためのレッスンチケットです。`,
      ``,
    ],
  },
  b2b_price: {
    title: [
      `기업 맞춤 수업권`,
      `B2B Partner`,
      'n_pricing.b2b_price.title',
      `企业专属课程券`,
      `企業專屬課程券`,
      `法人向けレッスンチケット`,
      ``,
    ],
    content_title: [
      `기업 맞춤 수업권`,
      `B2B Partner Credits`,
      'n_pricing.b2b_price.content_title',
      `企业专属课程券`,
      `企業專屬課程券`,
      `法人向けレッスンチケット`,
      ``,
    ],
  },
  available_time: [
    `수업 시간: 오전 5시 ~ 오후 14시, 저녁 19시 ~ 새벽 3시 (KST)`,
    `Lesson times: 5 AM - 2 PM, 7 PM - 3 AM (KST)`,
    'n_pricing.available_time',
    `课程时间: 上午5点 ~ 下午2点, 晚上19点 ~ 凌晨3点 (KST)`,
    `課程時間: 上午5點 ~ 下午2點, 晚上19點 ~ 凌晨3點 (KST)`,
    `授業時間: 5:00～14:00、19:00～翌3:00（日本時間）`,
    ``,
  ],
  min_20: [`20분 수업`, `20-min lesson`, 'n_pricing.min_20', `20分钟课程`, `20分鐘課程`, `20分レッスン`, ``],
  min_40: [`40분 수업`, `40-min lesson`, 'n_pricing.min_40', `40分钟课程`, `40分鐘課程`, `40分レッスン`, ``],
  lesson_span: [`수강기간`, `Valid for`, 'n_pricing.lesson_span', `使用期限`, `使用期限`, `受講期間`, ``],
  benefit: {
    credit_added: (amount) => [
      `[신규회원] ${amount}회 추가`,
      `[Newcomers] +${amount} credit(s)`,
      'n_pricing.benefit.credit_added',
      `[新会员] 追加${amount}次`,
      `[新會員] 追加${amount}次`,
      `[新規会員] ${amount}回を追加する`,
      ``,
    ],
    delay_lessons: (amount) => [
      `수업 연기 (${amount}회)`,
      `Deferral (${amount} time(s))`,
      'n_pricing.benefit.delay_lessons',
      `延长课程券使用时间 (${amount}次)`,
      `延長課程券使用時間 (${amount}次)`,
      `授業延期 (${amount}回)`,
      ``,
    ],
    delay: [
      `수업 연기`,
      `Deferral`,
      'n_pricing.benefit.delay',
      `延长课程券使用时间`,
      `延長課程券使用時間`,
      `授業延期`,
      ``,
    ],
    priority_booking: [
      `튜터 우선 예약`,
      `Priority booking`,
      'n_pricing.benefit.priority_booking',
      `优先预约教师`,
      `優先預約教師`,
      `チューター優先予約`,
      ``,
    ],
    ai: [
      `매 수업 영어 AI 분석`,
      `AI analysis report`,
      'n_pricing.benefit.ai',
      `课后AI分析报告`,
      `課後AI分析報告`,
      `毎回のAI Analysis`,
      ``,
    ],
    feedback: [
      `튜터의 수업 피드백`,
      `Tutor's feedback report`,
      'n_pricing.benefit.feedback',
      `课后教师评语`,
      `課後教師評語`,
      `チューターからのフィードバック`,
      ``,
    ],
    script: [
      `수업 녹음 및 자동 스크립트`,
      `Lesson recording & transcript`,
      'n_pricing.benefit.script',
      `课程录音＆课程文字纪录`,
      `課程錄音＆課程文字紀錄`,
      `レッスンの録音と自動スクリプト`,
      ``,
    ],
    material: [
      `매일 업데이트 되는 교재`,
      `Daily material updates`,
      'n_pricing.benefit.material',
      `每日更新教材`,
      `每日更新教材`,
      `毎日更新される教材`,
      ``,
    ],
    title: [`구매 혜택`, `Benefit(s)`, 'n_pricing.benefit.title', `Benefit(s)`, `Benefit(s)`, `Benefit(s)`, ``],
    lesson_20: [
      `1:1 수업 (20분)`,
      `1:1 lesson (20 min)`,
      'n_pricing.benefit.lesson_20',
      `1:1 课程 (20分)`,
      `1:1 課程 (20分)`,
      `1on1 レッスン(20分)`,
      `1:1 lesson (20 min)`,
    ],
    lesson_40: [
      `1:1 수업 (40분)`,
      `1:1 lesson (40 min)`,
      'n_pricing.benefit.lesson_40',
      `1:1 课程 (40分)`,
      `1:1 課程 (40分)`,
      `1on1 レッスン(40分)`,
      `1:1 lesson (40 min)`,
    ],
    ai_speak: [
      `AI 스피킹 (20분)`,
      `AI speaking (20 min)`,
      'n_pricing.benefit.ai_speak',
      `AI 口说伙伴 (20分)`,
      `AI 口說夥伴 (20分)`,
      `AI Speaking (20分)`,
      `AI Speaking (20 min)`,
    ],
    study_club: [
      `스터디 클럽 (60분)`,
      `Study club (60 min)`,
      'n_pricing.benefit.study_club',
      `小组口说练习 (60分)`,
      `小組口說練習 (60分)`,
      `Study Club (60分)`,
      `Study Club (60 min)`,
    ],
    book_first: [
      `튜터 우선 예약`,
      `Priority tutor booking`,
      'n_pricing.benefit.book_first',
      `优先预约教师`,
      `優先預約教師`,
      `チューター優先予約`,
      `Priority tutor booking`,
    ],
    bonus_period: [
      `[프로모션] 추가 수강기간`,
      `[Promo] Countdown deferral`,
      'n_pricing.benefit.bonus_period',
      `[优惠] 课程券使用时间延长`,
      `[優惠] 課程券使用時間延長`,
      `[キャンペーン]受講期間延長`,
      `[Promo] Countdown deferral`,
    ],
    number: (amount) => [
      `${amount}회`,
      `${amount}`,
      'n_pricing.benefit.number',
      `${amount}`,
      `${amount}`,
      `${amount}`,
      `${amount}`,
    ],
    day: (amount) => [
      `${amount}일`,
      `${amount}day(s)`,
      'n_pricing.benefit.day',
      `${amount}天`,
      `${amount}天`,
      `${amount}日`,
      `${amount}Ngày`,
    ],
    include: [`구성`, `Content`, 'n_pricing.benefit.include', `商品內容`, `商品内容`, `Content`, `Content`],
    coaching: [
      `코칭 세션 (50분)`,
      `Coaching session (50 min)`,
      'n_pricing.benefit.coaching',
      `Coaching session (50 min)`,
      `Coaching session (50 min)`,
      `Coaching session (50 min)`,
      ``,
    ],
    ai_premium: [`AI 튜터 프리미엄 플러스`, ``, 'n_pricing.benefit.ai_premium', ``, ``, ``, ``],
  },
  go_to_b2b: [
    <>
      내가 재직하는 회사의 <br />
      맞춤 수업권이 있나요?
    </>,
    <>
      Want to check if your company
      <br />
      is a B2B Partner?
    </>,
    'n_pricing.go_to_b2b',
    <>
      想要确认自己的公司 <br />
      是否提供企业专属课程券吗?
    </>,
    <>
      想要確認自己的公司 <br />
      是否提供企業專屬課程券嗎?
    </>,
    <>
      あなたの勤務先について
      <br />
      法人契約の有無を確認しますか？
    </>,
    ``,
  ],
  go_to_b2b_btn: [`1:1 문의`, `1:1 Chat`, 'n_pricing.go_to_b2b_btn', `联络客服`, `聯絡客服`, `お問い合わせ`, ``],
  tooltip: {
    credit_added: [
      <>
        [신규 회원 가입 혜택]
        <br />
        24시간 내에 수업권 구매시 자유수강 수업권을 추가로 드립니다.
      </>,
      <>
        [Newcomer Benefit]
        <br />
        Purchase credits within 24 hrs and get extra flexible credits.
      </>,
      'n_pricing.tooltip.credit_added',
      <>
        [新加入会员优惠]
        <br />
        24小时内购买课程券，将额外获得免费课程券！
      </>,
      <>
        [新加入會員優惠]
        <br />
        24小時內購買課程券，將額外獲得免費課程券！
      </>,
      <>
        [新規会員加入特典]
        <br />
        24時間以内にレッスンチケットを購入すると自由受講のレッスンチケットを追加で差し上げます。
      </>,
      ``,
    ],
  },
  pay_benefit_1: [
    `최대 6개월 무이자 할부`,
    `Interest-free for up to 6 monthly installments`,
    'n_pricing.pay_benefit_1',
    ``,
    ``,
    ``,
    `Trả góp không lãi suất lên đến 6 tháng`,
  ],
  pay_benefit_2: [
    <>첫 수업 불만족 시 100% 환불</>,
    `100% Money-back guarantee`,
    'n_pricing.pay_benefit_2',
    <>首次课程不满意时可100%退款</>,
    <>首次課程不滿意時可100%退款</>,
    <>初回レッスンにご満足いただけない場合、全額返金保証</>,
    `Đảm bảo hoàn tiền 100%`,
  ],
  five_percent_discount: [
    `5% 추가 할인`,
    `5% extra off`,
    'n_pricing.five_percent_discount',
    `追加 5% 折扣`,
    `追加 5% 折扣`,
    `追加5%割引`,
    `Giảm thêm 5%`,
  ],
  credit_number: (number) => [
    `${number}회`,
    `${number} credits`,
    'n_pricing.credit_number',
    `${number}次`,
    `${number}次`,
    `${number}回`,
    `${number}`,
  ],
  deferral_number: (number) => [
    `${number}회`,
    `${number} times`,
    'n_pricing.deferral_number',
    `${number}次`,
    `${number}次`,
    `${number}回`,
    `${number}`,
  ],
  twentyfour_hour: {
    add_on: (number) => [
      <>+ {number}회</>,
      <>+ {number}</>,
      'n_pricing.twentyfour_hour.add_on',
      <>+ {number}</>,
      <>+ {number}</>,
      <>+ {number}</>,
      <>+ {number}</>,
    ],
  },
  b2b_partner: {
    input_title: [
      <>
        기업 맞춤 수업권을 확인하기 위해
        <br />
        기업 맞춤 코드를 입력하세요.
      </>,
      <>
        Please enter <br />
        your B2B Partenr Code.
      </>,
      'n_pricing.b2b_partner.input_title',
      <>
        Please enter <br />
        your B2B Partenr Code.
      </>,
      <>
        Please enter <br />
        your B2B Partenr Code.
      </>,
      <>
        Please enter <br />
        your B2B Partenr Code.
      </>,
      ``,
    ],
    input_placeholder: [
      `기업 맞춤 코드`,
      `B2B Partner code`,
      'n_pricing.b2b_partner.input_placeholder',
      `B2B Partner code`,
      `B2B Partner code`,
      `B2B Partner code`,
      ``,
    ],
    input_error: [
      `입력한 기업 맞춤코드가 없어요. 다시 입력해주세요.`,
      `This code is not existed. Please try again.`,
      'n_pricing.b2b_partner.input_error',
      `This code is not existed. Please try again.`,
      `This code is not existed. Please try again.`,
      `This code is not existed. Please try again.`,
      ``,
    ],
  },
  newcomer: {
    title: [
      `신규회원 혜택`,
      `For Newcomers`,
      'n_pricing.newcomer.title',
      `For Newcomers`,
      `For Newcomers`,
      `For Newcomers`,
      `For Newcomers`,
    ],
    content: [
      `지금 구매 시 추가 수업권을 드려요.`,
      `Get extra lesson credits now.`,
      'n_pricing.newcomer.content',
      `Get extra lesson credits now.`,
      `Get extra lesson credits now.`,
      `Get extra lesson credits now.`,
      `Get extra lesson credits now.`,
    ],
    title_mobile: [
      `24시간 한정 신규회원 혜택`,
      `Newcomers Offer only for 24hr`,
      'n_pricing.newcomer.title_mobile',
      `Newcomers Offer only for 24hr`,
      `Newcomers Offer only for 24hr`,
      `Newcomers Offer only for 24hr`,
      `Newcomers Offer only for 24hr`,
    ],
  },
  ended_in: [`종료까지`, `Ends in`, 'n_pricing.ended_in', `Ends in`, `Ends in`, `Ends in`, `Ends in`],
  pay_benefit_3: [
    `환불 위약금 0원`,
    `No penalty for refunds`,
    'n_pricing.pay_benefit_3',
    `No penalty for refunds`,
    `No penalty for refunds`,
    `No penalty for refunds`,
    `No penalty for refunds`,
  ],
  upsell: {
    title: [
      `더 알뜰한 수업권과 비교해보세요.`,
      `Compare with more economical lesson packages.`,
      'n_pricing.upsell.title',
      `Compare with more economical lesson packages.`,
      `Compare with more economical lesson packages.`,
      `Compare with more economical lesson packages.`,
      `Compare with more economical lesson packages.`,
    ],
    per_credit: (amount) => [
      `(회당 ${amount})`,
      `(Per credit: ${amount})`,
      'n_pricing.upsell.per_credit',
      `(Per credit: ${amount})`,
      `(Per credit: ${amount})`,
      `(Per credit: ${amount})`,
      `(Per credit: ${amount})`,
    ],
    purchase: [`구매`, `Buy`, 'n_pricing.upsell.purchase', `Buy`, `Buy`, `Buy`, `Buy`],
    change_to_purchase: [`구매`, `Buy`, 'n_pricing.upsell.change_to_purchase', `Buy`, `Buy`, `Buy`, `Buy`],
    benefit_save: (amount) => [
      <>구매 시 {amount} 절약</>,
      <>Save {amount}</>,
      'n_pricing.upsell.benefit_save',
      <>Save {amount}</>,
      <>Save {amount}</>,
      <>Save {amount}</>,
      <>Save {amount}</>,
    ],
    benefit_tutor: [
      `튜터 우선 예약 가능`,
      `Priority tutor booking available`,
      'n_pricing.upsell.benefit_tutor',
      `Priority tutor booking available`,
      `Priority tutor booking available`,
      `Priority tutor booking available`,
      `Priority tutor booking available`,
    ],
    guide: [
      `수업 횟수가 부담된다면? 남은 수업권은 수강 기간 내 환불 가능해요.`,
      `Unused credits are refundable within the subscription period.`,
      'n_pricing.upsell.guide',
      `Unused credits are refundable within the subscription period.`,
      `Unused credits are refundable within the subscription period.`,
      `Unused credits are refundable within the subscription period.`,
      `Unused credits are refundable within the subscription period.`,
    ],
    five_hundred: [
      <>
        가지고 있는 포인트로 <br /> 더 저렴하게 구매할 수 있어요!.
      </>,
      <>
        Use your points <br /> to get an even lower price!
      </>,
      'n_pricing.upsell.five_hundred',
      <>
        Use your points <br /> to get an even lower price!
      </>,
      <>
        Use your points <br /> to get an even lower price!
      </>,
      <>
        Use your points <br /> to get an even lower price!
      </>,
      <>
        Use your points <br /> to get an even lower price!
      </>,
    ],
  },
  ai_multi_two: {
    title: [
      `런칭 기념 2배 이벤트`,
      `Launching Event`,
      'n_pricing.ai_multi_two.title',
      `Launching Event`,
      `Launching Event`,
      `Launching Event`,
      `Launching Event`,
    ],
    title_mobile: [
      `런칭 기념 2배 이벤트 (~5/17)`,
      `Launching Event (until 5.17)`,
      'n_pricing.ai_multi_two.title_mobile',
      `Launching Event (until 5.17)`,
      `Launching Event (until 5.17)`,
      `Launching Event (until 5.17)`,
      `Launching Event (until 5.17)`,
    ],
    content: [
      `AI 스피킹 파트너 이용권을 2배로 드려요.`,
      `Receive double AI Speaking Partner tickets.`,
      'n_pricing.ai_multi_two.content',
      `Receive double AI Speaking Partner tickets.`,
      `Receive double AI Speaking Partner tickets.`,
      `Receive double AI Speaking Partner tickets.`,
      `Receive double AI Speaking Partner tickets.`,
    ],
  },
  pay_benefit_ai: {
    title: (amount) => [
      <>
        AI 스피킹 파트너 ({amount}+{amount}회)
      </>,
      <>
        AI Speaking Partner
        <br />({amount}+{amount})
      </>,
      'n_pricing.pay_benefit_ai.title',
      <>
        AI口语伙伴 ({amount}+{amount})
      </>,
      <>
        AI口說夥伴 ({amount}+{amount})
      </>,
      <>
        AI Speaking Partner
        <br />({amount}+{amount})
      </>,
      `AI Speaking Partner (${amount}+${amount})`,
    ],
    tooltip: [
      `AI 스피킹 파트너와 영어 대화를 할 수 있는 이용권입니다.`,
      `This ticket is for having conversations with an AI Speaking Partner.`,
      'n_pricing.pay_benefit_ai.tooltip',
      `使用AI口说伙伴练习券可在Ringle APP练习口说`,
      `使用AI口說夥伴練習券可在Ringle APP練習口說`,
      `This ticket is for having conversations with an AI Speaking Partner.`,
      `This ticket is for having conversations with an AI Speaking Partner.`,
    ],
    normal: (amount) => [
      <>AI 스피킹 파트너 ({amount}회)</>,
      <>AI Speaking Partner ({amount})</>,
      'n_pricing.pay_benefit_ai.normal',
      <>AI口语伙伴 ({amount})</>,
      <>AI口說夥伴 ({amount})</>,
      <>AI Speaking Partner ({amount})</>,
      ``,
    ],
  },
  ai_speaking_partner: {
    tootlip: [
      `AI 스피킹 파트너와 영어 대화를 할 수 있는 이용권입니다.`,
      `This ticket allows you to hold a 1:1 talk with AI Speaking Partner.`,
      'n_pricing.ai_speaking_partner.tootlip',
      `可以和AI伙伴练习口语的练习券`,
      `可以和AI夥伴練習口說的練習券`,
      `このチケットで、AI Speaking Partnerとのマンツーマンでの会話ができます。`,
      ``,
    ],
  },
  benefit_b2b2c: {
    title: [
      `완강 보너스`,
      `Bonus`,
      'n_pricing.benefit_b2b2c.title',
      `课程完成奖励`,
      `課程完成獎勵`,
      `修了ボーナス`,
      ``,
    ],
    subtitle: [
      `정규 수업 횟수를 모두 완강하면 보너스 수업권을 드려요.`,
      `Receive bonus credits after using up all purchased credits.`,
      'n_pricing.benefit_b2b2c.subtitle',
      `完成所有课程就能获得全勤课程券`,
      `完成所有課程就能獲得全勤課程券`,
      `正規のレッスンを全て受講した後に、ボーナスレッスンチケットをプレゼントします。`,
      ``,
    ],
    content: [
      <>
        <li className="li-tag-inside">완강 보너스가 표시된 수업권에만 적용돼요.</li>
        <li className="li-tag-inside">수업 참석 / 미참석/ 취소 / 만료든 수업권의 횟수를 모두 사용하면 제공돼요.</li>
        <li className="li-tag-inside">
          완강 보너스로 지급되는 수업권은 출석률에 포함되지 않고, 수강기간 1년 안에 자유롭게 사용할 수 있어요.
        </li>
        <li className="li-tag-inside">완강 보너스로 지급되는 수업권도 AI 스피킹 파트너 이용권이 지급됩니다.</li>
      </>,
      <>
        <li className="li-tag-inside">Applies only to credits denoting the 'Bonus.'</li>
        <li className="li-tag-inside">
          Bonus Credits are provided when you use up all of the purchased credits, regardless of how (lesson
          completion/no-show/cancellation, credit expiration, etc.).
        </li>
        <li className="li-tag-inside">
          Bonus Credits are not counted toward the attendance rate and can be used anytime within one year.
        </li>
        <li className="li-tag-inside">AI Speaking Tickets are also provided with Bonus Credits.</li>
      </>,
      'n_pricing.benefit_b2b2c.content',
      <>
        <li className="li-tag-inside">全勤奖励只适用于有标示的课程券</li>
        <li className="li-tag-inside">
          课程券全数使用完毕即可获得全勤课程券(不论是否正常完成课程/课程取消/课程券到期）
        </li>
        <li className="li-tag-inside">全勤课程券不计算出席率，使用期限为一年</li>
        <li className="li-tag-inside">获得全勤课程券时同时获得AI口语伙伴练习券</li>
      </>,
      <>
        <li className="li-tag-inside">全勤獎勵只適用於有標示的課程券</li>
        <li className="li-tag-inside">
          課程券全數使用完畢即可獲得全勤課程券(不論是否正常完成課程/課程取消/課程券到期）
        </li>
        <li className="li-tag-inside">全勤課程券不計算出席率，使用期限為一年</li>
        <li className="li-tag-inside">獲得全勤課程券時同時獲得AI口說夥伴練習券</li>
      </>,
      <>
        <li className="li-tag-inside">修了ボーナスの表示があるレッスンチケットにのみ適用されます。</li>
        <li className="li-tag-inside">
          授業の出席/不参加/キャンセル/有効期限切れであっても、レッスン回数分すべて使用すれば提供されます。
        </li>
        <li className="li-tag-inside">
          修了ボーナスとして支給されるチケットは、出席率にカウントされず、受講期間1年以内に自由に使用することができます。
        </li>
        <li className="li-tag-inside">
          修了ボーナスとして支給されるレッスンチケットには、AI Speaking Partner利用チケットも含まれます。
        </li>
      </>,
      ``,
    ],
    bonus_tooltip: [
      <>
        AI 스피킹 파트너와 영어 대화를 할 수 있는 이용권입니다.
        <br />
        (추가 이용권은 완강 보너스 제공 시 함께 지급됩니다.)
      </>,
      <>
        This ticket gives you access to a 1:1 talk with AI Speaking Partner.
        <br />
        (Additional tickets are provided with the Completion Bonus.)
      </>,
      'n_pricing.benefit_b2b2c.bonus_tooltip',
      <>这是\b可以和AI口语伙伴练习对话的练习券。 (额外的练习券将在完成课程后提供）</>,
      <>這是\b可以和AI口說夥伴練習對話的練習券。 (額外的練習券將在完成課程後提供）</>,
      <>
        AI Speaking Parnterと英会話ができるチケットです。
        <br />
        (追加チケットは、修了ボーナス提供時に一緒に支給されます。)
      </>,
      ``,
    ],
    label_promo: [
      `프로모션 할인가`,
      `Promo discount`,
      'n_pricing.benefit_b2b2c.label_promo',
      `活动优惠`,
      `活動優惠`,
      `キャンペーン価格`,
      ``,
    ],
    title_2: [
      `기업 맞춤 할인 혜택`,
      `B2B Partner Discount`,
      'n_pricing.benefit_b2b2c.title_2',
      `企业专属优惠`,
      `企業專屬優惠`,
      `法人割引＆特典`,
      ``,
    ],
    subtitle_2: [
      `기업 코드 입력 고객 한정 추가 할인 혜택이 적용됩니다.`,
      `Receive additional discounts by entering the B2B partner code.`,
      'n_pricing.benefit_b2b2c.subtitle_2',
      `输入企业专属代码享优惠价`,
      `輸入企業專屬代碼享優惠價`,
      `企業コードを入力されたお客様に限り、追加割引が適用されます。`,
      ``,
    ],
    content_2: [
      `기업 코드를 입력한 고객은 자유 수강 수업권을 1년 내내 매주 수업듣기 가격으로 구매할 수 있습니다.`,
      `By entering the B2B partner code, you can purchase Flexible credits at the price of Every Week credits all year long.`,
      'n_pricing.benefit_b2b2c.content_2',
      `输入企业专属代码可以于一年内以每週课程的价格购买无固定时间课程券`,
      `輸入企業專屬代碼可以於一年內以每週課程的價格購買無固定時間課程券`,
      `企業コードを入力されたお客様は、1年間、毎週受講チケットの価格で自由受講チケットを購入することができます。`,
      ``,
    ],
    blank_msg: [
      <>
        구매가능한 수업권이 없습니다.
        <br />
        다른 수업 길이를 선택해 주세요.
      </>,
      <>
        There are no credits available for purchase.
        <br />
        Please select a different lesson duration.
      </>,
      'n_pricing.benefit_b2b2c.blank_msg',
      <>
        There are no credits available for purchase.
        <br />
        Please select a different lesson duration.
      </>,
      <>
        There are no credits available for purchase.
        <br />
        Please select a different lesson duration.
      </>,
      <>
        There are no credits available for purchase.
        <br />
        Please select a different lesson duration.
      </>,
      ``,
    ],
    complete_bonus_long: (amount) => [
      <>정규 수업 횟수 완강시 {amount}회 추가</>,
      ``,
      'n_pricing.benefit_b2b2c.complete_bonus_long',
      ``,
      ``,
      ``,
      ``,
    ],
    complete_bonus_short: [`정규 수업 횟수 완강시`, ``, 'n_pricing.benefit_b2b2c.complete_bonus_short', ``, ``, ``, ``],
  },
  summer_24: {
    title: [
      `여름 프로모션 (~7/9)`,
      `Summer Promotion (~July 9th)`,
      'n_pricing.summer_24.title',
      `夏日优惠 (~7/9)`,
      `夏日優惠 (~7/9)`,
      `サマーキャンペーン・ (~7/9)`,
      ``,
    ],
    content: [
      <>
        최대 34% 할인부터 링글 스터디클럽까지
        <br />
        매주 수업듣기 수업권 9월 2일부터 수강기간 차감
      </>,
      <>
        ~34% discount and Ringle Study Club
        <br />
        For Every Week credits: Option to delay first lesson up to Sep. 2
      </>,
      'n_pricing.summer_24.content',
      <>
        最高折扣34%，免费参加小组口说练习
        <br />
        购买每週课程时，使用期限从9/2开始扣除
      </>,
      <>
        最高折扣34%，免費參加小組口說練習
        <br />
        購買每週課程時，使用期限從9/2開始扣除
      </>,
      <>
        最大34%割引からStudy Clubまで
        <br />
        「毎週受講」のチケットを購入すると、最初のレッスンを9/2まで延長できます。
      </>,
      ``,
    ],
    content_none: [
      `최대 34% 할인부터 링글 스터디클럽까지`,
      <>~34% discount & Rringle Study Club</>,
      'n_pricing.summer_24.content_none',
      <>最高折扣34%，免费参加小组口说练习</>,
      <>最高折扣34%，免費參加小組口說練習</>,
      <>最大34%割引 & Study Clubのメンバーシップをすべての購入者に</>,
      ``,
    ],
    benefit_1: [
      `[프로모션] 링글 스터디클럽`,
      `[Promo] Ringle Study Club`,
      'n_pricing.summer_24.benefit_1',
      `Ringle小组口说练习`,
      `Ringle小組口說練習`,
      `[特典] Ringle Study Club`,
      ``,
    ],
    benefit_1_tooltip: [
      `주2회 링글러와 함께하는 영어 스피킹 스터디클럽 참여 기회 제공`,
      `Participate in a study club with other Ringlers to practice English speaking twice a week`,
      'n_pricing.summer_24.benefit_1_tooltip',
      `提供每週2次，和Ringle学习者一起进行小组口说练习机会`,
      `提供每週2次，和Ringle學習者一起進行小組口說練習機會`,
      `週2回、他のRingleユーザーと英会話の練習をするStudy Clubに参加する。`,
      ``,
    ],
    benefit_hold: [
      `[프로모션] 수강기간 시작 홀딩`,
      `[Promo] Delay first lesson`,
      'n_pricing.summer_24.benefit_hold',
      `课程券使用期限延后扣除`,
      `課程券使用期限延後扣除`,
      `[特典] 初回レッスンの延期`,
      ``,
    ],
    benefit_hold_tooltip: [
      `구매 시점으로부터 약 3개월 동안 수강기간이 차감되지 않도록 홀드 (24.9.2부터 수강기간 자동으로 시작)`,
      `Expiration of validity period can be delayed for about 3 months (Validity period begins expiring automatically from Sep. 2, 2024)`,
      'n_pricing.summer_24.benefit_hold_tooltip',
      `购买之后约3个月内不扣除使用期限(从2024.9.2开始计算）`,
      `購買之後約3個月內不扣除使用期限(從2024.9.2開始計算）`,
      `有効期限を約3ヶ月延長できます（有効期限は2024年9月2日で自動的に終了となります）`,
      ``,
    ],
  },
  package: {
    title: [
      `나에게 맞는 패키지 찾기`,
      `Choose the Package that Fits You`,
      'n_pricing.package.title',
      `选择适合自己的课程券`,
      `選擇適合自己的課程券`,
      `自分に合ったパッケージを探す`,
      `Choose the Package that Fits You`,
    ],
    min_20: [`20분 수업`, `20-min lesson`, 'n_pricing.package.min_20', `20分钟课程`, `20分鐘課程`, `20分レッスン`, ``],
    min_40: [`40분 수업`, `40-min lesson`, 'n_pricing.package.min_40', `40分钟课程`, `40分鐘課程`, `40分レッスン`, ``],
    name: (amount) => [
      `${amount}주 패키지`,
      `${amount} Week Package`,
      'n_pricing.package.name',
      `${amount}週课程`,
      `${amount}週課程`,
      `受講期間${amount}週`,
      `${amount} Week Package`,
    ],
    monthly: (amount) => [
      `월 ${amount}`,
      `${amount}/mo`,
      'n_pricing.package.monthly',
      `月${amount}元`,
      `月${amount}元`,
      `$ ${amount}/月`,
      `${amount}/month`,
    ],
    name_aug_1: [
      `영어 스피킹 강화 패키지`,
      `Speaking Enhancement Package`,
      'n_pricing.package.name_aug_1',
      `Speaking Enhancement Package`,
      `Speaking Enhancement Package`,
      `Speaking Enhancement Package`,
      ``,
    ],
    name_aug_2: [
      `MBA 에세이/레쥬메 준비 패키지`,
      `MBA Essay/Resume Prep Package`,
      'n_pricing.package.name_aug_2',
      `MBA Essay/Resume Prep Package`,
      `MBA Essay/Resume Prep Package`,
      `MBA Essay/Resume Prep Package`,
      ``,
    ],
    name_aug_3: [
      `MBA 인터뷰 준비 패키지`,
      `MBA Interview Prep Package`,
      'n_pricing.package.name_aug_3',
      `MBA Interview Prep Package`,
      `MBA Interview Prep Package`,
      `MBA Interview Prep Package`,
      ``,
    ],
  },
  select_type: {
    title: [
      `수강 방식 선택`,
      `Choose Routine`,
      'n_pricing.select_type.title',
      `选择课程券类型`,
      `選擇課程券類型`,
      `受講方法を選ぶ`,
      `Choose Routine`,
    ],
  },
  every_week_n: {
    title: [`매주 수업 듣기`, `Every Week`, 'n_pricing.every_week_n.title', `每週课程`, `每週課程`, `毎週受講`, ``],
    subtitle: [
      `매주 1회 이상 꾸준히 수업을 원하는 분을 위한 패키지입니다.`,
      `Recommended for learners who wish to study English consistently every week`,
      'n_pricing.every_week_n.subtitle',
      `推荐给希望每週至少上一堂课以上的用户`,
      `推薦給希望每週至少上一堂課以上的用戶`,
      `毎週1回以上の定期的な学習を希望される方のためのレッスンチケットです。`,
      ``,
    ],
  },
  none_n: {
    title: [`자유 수강`, `Flexible`, 'n_pricing.none_n.title', `无固定时间课程`, `無固定時間課程`, `自由受講`, ``],
    subtitle: [
      `내 스케줄에 맞게 자유로운 수업을 원하는 분을 위한 패키지입니다.`,
      `Recommended for learners who wish to study English flexibly without a fixed routine`,
      'n_pricing.none_n.subtitle',
      `推荐给希望自由安排课程时间的用户`,
      `推薦給希望自由安排課程時間的用戶`,
      `自分の都合に合わせて受講したい方のためのレッスンチケットです。`,
      ``,
    ],
  },
  b2b_price_n: {
    title: [
      `기업 맞춤`,
      `B2B Partner`,
      'n_pricing.b2b_price_n.title',
      `企业专属课程券`,
      `企業專屬課程券`,
      `法人向けレッスンチケット`,
      ``,
    ],
  },
  package_q: {
    title: [
      `각 수강 방식에 대해 자세히 알고 싶어요.`,
      `I want to know more about each type.`,
      'n_pricing.package_q.title',
      `我想了解各类型课程券`,
      `我想了解各類型課程券`,
      `各受講方法の詳細について`,
      `I want to know more about each type.`,
    ],
    content: [
      <>
        <b>매주 수업듣기</b>
        <li className="li-tag-inside">매주 1회 이상 꾸준한 영어 학습을 희망하는 경우 추천하는 패키지입니다.</li>
        <li className="li-tag-inside">
          패키지 구매 즉시 수강 기간이 차감됩니다. 단, 여름 프로모션 기간 내 구매 시, 이벤트 혜택으로 2024년 9월 2일
          00시부터 수강기간이 차감됩니다.
        </li>
        <li className="li-tag-inside">
          구매 시점부터 주 1회 이상 1:1 수업을 수강하지 않을 경우 1:1 수업권 1회가 자동으로 차감됩니다.
        </li>
        <li className="li-tag-inside">
          매주 수업 듣기가 어려운 경우 수업 연기 혜택을 사용할 수 있으며, 혜택 사용을 위해 링글팀에 문의를 남겨주시면
          차감된 수업권을 복구해 드립니다. (10주 패키지는 2회 연기, 20주 패키지는 4회 연기, 30주 패키지는 6회, 60주
          패키지는 12회 연기 가능합니다.)
        </li>
        <li className="li-tag-inside">매주 수업 독려 알림을 보내드립니다.</li>
        <br />
        <b>자유 수강</b>
        <li className="li-tag-inside">내 스케줄에 맞게 자유로운 수강을 원하는 분을 위한 패키지입니다.</li>
        <li className="li-tag-inside">
          첫 수업 시작 전 수강기간이 차감되지 않습니다. (구매 1년 후에는 자동 차감 됩니다.)
        </li>
        <br />
        <b>기업 맞춤 </b>
        <li className="li-tag-inside">링글 제휴사 임직원에게 제공되는 패키지입니다.</li>
        <li className="li-tag-inside">회사 교육지원금에 따라 패키지를 편성하여 제공드릴 수 있습니다.</li>
        <li className="li-tag-inside">1:1 문의를 통해 연락해주세요.</li>
      </>,
      <>
        <b>Every Week</b>
        <li className="li-tag-inside">
          Recommended for learners who wish to study English consistently at least once a week.
        </li>
        <li className="li-tag-inside">
          The countdown towards expiration of lesson credits begins immediately upon purchase. [Promotion benefit] For
          packages purchased during the Summer Promotion period, the countdown may be deferred until 23:59 on September
          1, 2024.
        </li>
        <li className="li-tag-inside">
          One credit will be automatically deducted each week from the purchase date even if you do not take any
          lessons.
        </li>
        <li className="li-tag-inside">
          If you have difficulty taking lessons every week, you can use the deferral benefit to restore a deducted
          credit. Please contact the Ringle team to utilize this benefit. (This benefit is available twice for the 10
          Week package, four times for the 20 Week package, six times for the 30 Week package, and twelve times for the
          60 Week package.)
        </li>
        <li className="li-tag-inside">You will receive weekly lesson reminders.</li>
        <br />
        <b>Flexible</b>
        <li className="li-tag-inside">Recommended for learners who take lessons flexibly on their own schedule.</li>
        <li className="li-tag-inside">
          Expiration of lesson credits only begins after your first lesson or after one year from the purchase date,
          whichever comes first.
        </li>
        <br />
        <b>B2B Partner</b>
        <li className="li-tag-inside">Available for employees of Ringle's partner companies.</li>
        <li className="li-tag-inside">
          Credit packages can be arranged to fit the partner company's training allowance for employees.
        </li>
        <li className="li-tag-inside">Please leave a 1:1 inquiry for more information.</li>
      </>,
      'n_pricing.package_q.content',
      <>
        <b>每週课程</b>
        <li className="li-tag-inside">推荐给希望每週至少上一堂课以上的用户</li>
        <li className="li-tag-inside">
          购买后即刻开始计算使用期限。于夏日优惠活动期间购买不在此限，使用期限将从2024/9/2开始扣除。
        </li>
        <li className="li-tag-inside">购买后一週没有进行一堂以上的课程，刚週课程券将自动扣除。</li>
        <li className="li-tag-inside">
          如果无法每週进行课程，可以使用课程延期优惠，请联络客服团队，我们将协助恢復已扣除的课程券。(10週的课程可延期 2
          次，20週的课程可延期 4 次，30 週的课程可延期 6 次，60 週的课程可延期12 次）。
        </li>
        <li className="li-tag-inside">您将会收到每週上课提醒通知</li>
        <br />
        <b>无固定时间课程</b>
        <li className="li-tag-inside">推荐给希望自由安排课程时间的用户</li>
        <li className="li-tag-inside">课程券使用期限将于上完第一堂课后开始计算 (购买后1年自动开始计算</li>
        <br />
        <b>企业专属课程券 </b>
        <li className="li-tag-inside">提供给与Ringle合作的企业员工的课程</li>
        <li className="li-tag-inside">根据公司教育训练的费用提供客製化课程</li>
        <li className="li-tag-inside">请与我们联络获得更多资讯</li>
      </>,
      <>
        <b>每週課程</b>
        <li className="li-tag-inside">推薦給希望每週至少上一堂課以上的用戶</li>
        <li className="li-tag-inside">
          購買後即刻開始計算使用期限。於夏日優惠活動期間購買不在此限，使用期限將從2024/9/2開始扣除。
        </li>
        <li className="li-tag-inside">購買後一週沒有進行一堂以上的課程，剛週課程券將自動扣除。</li>
        <li className="li-tag-inside">
          如果無法每週進行課程，可以使用課程延期優惠，請聯絡客服團隊，我們將協助恢復已扣除的課程券。(10週的課程可延期 2
          次，20週的課程可延期 4 次，30 週的課程可延期 6 次，60 週的課程可延期12 次）。
        </li>
        <li className="li-tag-inside">您將會收到每週上課提醒通知</li>
        <br />
        <b>無固定時間課程</b>
        <li className="li-tag-inside">推薦給希望自由安排課程時間的用戶</li>
        <li className="li-tag-inside">課程券使用期限將於上完第一堂課後開始計算 (購買後1年自動開始計算</li>
        <br />
        <b>企業專屬課程券 </b>
        <li className="li-tag-inside">提供給與Ringle合作的企業員工的課程</li>
        <li className="li-tag-inside">根據公司教育訓練的費用提供客製化課程</li>
        <li className="li-tag-inside">請與我們聯絡獲得更多資訊</li>
      </>,
      <>
        <b>毎週受講</b>
        <li className="li-tag-inside">少なくとも週1回コンスタントに英語を勉強したい方におすすめ。</li>
        <li className="li-tag-inside">
          レッスンクレジットの有効期限までのカウントダウンは、購入と同時に開始されます。[キャンペーン特典]サマープロモーション期間中にご購入いただいたパッケージは、2024年9月1日23:59までカウントダウンが繰り延べられます。
        </li>
        <li className="li-tag-inside">
          レッスンを受講されなくても、購入日から毎週1クレジットが自動的に差し引かれます。
        </li>
        <li className="li-tag-inside">
          毎週レッスンを受講することが困難な場合、引き落とされたクレジットの復元ができる延期特典を利用することができます。この特典をご利用になりたい方は、Ringleまでご連絡ください。(この特典は10週パックで2回、20週パックで4回、30週パックで6回、60週パックで12回ご利用いただけます)
        </li>
        <li className="li-tag-inside">毎週レッスンリマインダーを受け取ります。</li>
        <br />
        <b>自由受講</b>
        <li className="li-tag-inside">自分のスケジュールに合わせてフレキシブルにレッスンを受けたい方におすすめ。</li>
        <li className="li-tag-inside">
          レッスンクレジットの有効期限は、初回レッスン終了後、または購入日から1年後のいずれか早い時点から開始されます。
        </li>
        <br />
        <b>法人契約</b>
        <li className="li-tag-inside">Ringleの提携企業の従業員様が対象となります。</li>
        <li className="li-tag-inside">パッケージは、従業員研修手当等に合わせてアレンジすることができます。</li>
        <li className="li-tag-inside">詳しくはお問い合わせください。</li>
      </>,
      <>
        <b>Every Week</b>
        <li className="li-tag-inside">
          Recommended for learners who wish to study English consistently at least once a week.
        </li>
        <li className="li-tag-inside">
          The countdown towards expiration of lesson credits begins immediately upon purchase. [Promotion benefit] For
          packages purchased during the Summer Promotion period, the countdown may be deferred until 23:59 on September
          1, 2024.
        </li>
        <li className="li-tag-inside">
          One credit will be automatically deducted each week from the purchase date even if you do not take any
          lessons.
        </li>
        <li className="li-tag-inside">
          If you have difficulty taking lessons every week, you can use the deferral benefit to restore a deducted
          credit. Please contact the Ringle team to utilize this benefit. (This benefit is available twice for the 10
          Week package, four times for the 20 Week package, six times for the 30 Week package, and twelve times for the
          60 Week package.)
        </li>
        <li className="li-tag-inside">You will receive weekly lesson reminders.</li>
        <br />
        <b>Flexible</b>
        <li className="li-tag-inside">Recommended for learners who take lessons flexibly on their own schedule.</li>
        <li className="li-tag-inside">
          Expiration of lesson credits only begins after your first lesson or after one year from the purchase date,
          whichever comes first.
        </li>
        <br />
        <b>B2B Partner</b>
        <li className="li-tag-inside">Available for employees of Ringle's partner companies.</li>
        <li className="li-tag-inside">
          Credit packages can be arranged to fit the partner company's training allowance for employees.
        </li>
        <li className="li-tag-inside">Please leave a 1:1 inquiry for more information.</li>
      </>,
    ],
  },
  select_package: {
    title: [
      `패키지 선택`,
      `Select Package`,
      'n_pricing.select_package.title',
      `选择课程券`,
      `選擇課程券`,
      `パッケージを選択する`,
      `Select Package`,
    ],
  },
  label: {
    first_buy_timer: (time) => [
      `${time} 내 구매 시, 첫 구매 혜택 증정`,
      `Buy in ${time} for first-time purchase benefits`,
      'n_pricing.label.first_buy_timer',
      `在${time}内购买可享首购优惠`,
      `在${time}內購買可享首購優惠`,
      `${time} 以内の購入で初回購入特典贈呈`,
      `Buy in ${time} for first-time purchase benefits`,
    ],
    promo: [
      `프로모션 할인`,
      `Promo discount`,
      'n_pricing.label.promo',
      `活动折扣`,
      `活動折扣`,
      `キャンペーン割引`,
      `Promo discount`,
    ],
    firt_buy: [
      `첫 구매 혜택`,
      `First purchase`,
      'n_pricing.label.firt_buy',
      `首购优惠`,
      `首購優惠`,
      `初回購入`,
      `First purchase`,
    ],
    promo_amount_won: (amount) => [
      `정가 대비 ${amount} 할인`,
      `Promo ₩${amount}K off`,
      'n_pricing.label.promo_amount_won',
      `比原價便宜 ₩${amount}K`,
      `比原价便宜 ₩${amount}K`,
      `Promo ₩${amount}K off`,
      `Promo ₩${amount}K off`,
    ],
    promo_amount_usd: (amount) => [
      `정가 대비 ${amount} 할인`,
      `Promo $${amount} off`,
      'n_pricing.label.promo_amount_usd',
      `比原價便宜 $${amount}`,
      `比原价便宜 $${amount}`,
      `Promo $${amount} off`,
      `Promo $${amount} off`,
    ],
    event: [`EVENT`, `EVENT`, 'n_pricing.label.event', `EVENT`, `EVENT`, `EVENT`, ``],
    sam_inst: [
      `무이자 12개월`,
      `12-month plan (0% interest)`,
      'n_pricing.label.sam_inst',
      `12-month plan (0% interest)`,
      `12-month plan (0% interest)`,
      `12-month plan (0% interest)`,
      `12-month plan (0% interest)`,
    ],
    coupon_autumn: (amount) => [
      `${amount} 쿠폰 적용가`,
      `${amount} coupon applied`,
      'n_pricing.label.coupon_autumn',
      `${amount} coupon applied`,
      `${amount} coupon applied`,
      `${amount} coupon applied`,
      `${amount} coupon applied`,
    ],
  },
  notice_every_week: [
    `매주 수업듣기는 주 1회 1:1 수업이 필수이며, 주 2회 이상 수강도 가능합니다.`,
    `Every Week credits require you to complete at least one lesson each week. You are free to take more lessons during the week if you wish.`,
    'n_pricing.notice_every_week',
    `每週课程券1週至少须完成1堂课，也可以进行2堂以上课程`,
    `每週課程券1週至少須完成1堂課，也可以進行2堂以上課程`,
    `毎週の単位取得には最低週1回の1on1レッスン受講が必要ですが、週1回以上受講することもできます。`,
    ``,
  ],
  notice_common: [
    `1주일에 들을 수 있는 1:1 수업 횟수가 제한되어 있지 않습니다.`,
    `There is no limit to the number of 1:1 lessons you can take in a week.`,
    'n_pricing.notice_common',
    `1週内可进行的1:1 课程無次数限制`,
    `1週內可進行的1:1 課程無次數限制`,
    `1週間に受講できる1on1レッスンの回数に上限はありません。`,
    ``,
  ],
  four_week: (amount) => [
    <>
      <span className="text-[16px] font-bold mr-[8px]">4주</span>
      {amount}
    </>,
    <>
      {amount} <span className="text-[16px] font-bold">/4 weeks</span>
    </>,
    'n_pricing.four_week',
    <>
      {amount} <span className="text-[16px] font-bold">/4週</span>
    </>,
    <>
      {amount} <span className="text-[16px] font-bold">/4週</span>
    </>,
    <>
      {amount} <span className="text-[16px] font-bold">/4週間</span>
    </>,
    ``,
  ],
  common: {
    case_needed: [
      `*30만원 이상 결제 시`,
      `*For purchases totalling $230 or above`,
      'n_pricing.common.case_needed',
      `*For purchases totalling $230 or above`,
      `*For purchases totalling $230 or above`,
      `*For purchases totalling $230 or above`,
      ``,
    ],
    case_card_common: [
      `*카드 일반 결제 시`,
      `*For payments by credit card`,
      'n_pricing.common.case_card_common',
      `*For payments by credit card`,
      `*For payments by credit card`,
      `*For payments by credit card`,
      ``,
    ],
  },
  b2b2c_label: {
    sale: (amount) => [
      `이벤트 | ${amount} 추가 할인`,
      `Event | $${amount} discount`,
      'n_pricing.b2b2c_label.sale',
      `Event | $${amount} discount`,
      `Event | $${amount} discount`,
      `Event | $${amount} discount`,
      `Event | $${amount} discount`,
    ],
    coupon_added: (amount) => [
      `이벤트 | ${amount}회 완강 보너스 추가`,
      `Event | ${amount} bonus credit(s) added`,
      'n_pricing.b2b2c_label.coupon_added',
      `Event | ${amount} bonus credit(s) added`,
      `Event | ${amount} bonus credit(s) added`,
      `Event | ${amount} bonus credit(s) added`,
      `Event | ${amount} bonus credit(s) added`,
    ],
  },
  lesson: {
    title_m: [
      <>
        나에게 맞는
        <br /> 1:1 화상영어 패키지 찾기
      </>,
      ``,
      'n_pricing.lesson.title_m',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  ai_tutor: {
    title_m: [
      <>
        나에게 맞는
        <br /> AI 튜터 멤버십을 선택하세요.
      </>,
      ``,
      'n_pricing.ai_tutor.title_m',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const n_module = {
  timer: {
    amount: (amount) => [
      `${amount}초 후`,
      `${amount} sec`,
      'n_module.timer.amount',
      `${amount}秒后`,
      `${amount}秒後`,
      `${amount}秒後`,
      ``,
    ],
    cpation: [
      `이 창이 닫혀요.`,
      `Closing soon`,
      'n_module.timer.cpation',
      `视窗即将关闭`,
      `視窗即將關閉`,
      `すぐに閉じる`,
      ``,
    ],
  },
  one_click: {
    title: (tutor_name) => [
      <>
        다시 만나고 싶었던
        <br />
        {tutor_name} 튜터와 수업하세요.
      </>,
      <>
        Book a lesson with
        <br />
        {tutor_name}, who you rated highly.
      </>,
      'n_module.one_click.title',
      <>
        \b和喜欢的
        <br />
        {tutor_name}教师上课吧
      </>,
      <>
        \b和喜歡的
        <br />
        {tutor_name}教師上課吧
      </>,
      <>
        お気に入りのチューター
        <br />
        {tutor_name}とレッスン
      </>,
      ``,
    ],
    btn_1: [
      `수업 시간 보기`,
      `View lesson slots`,
      'n_module.one_click.btn_1',
      `确认课程时间`,
      `確認課程時間`,
      `レッスン時間を確認する`,
      ``,
    ],
    lesson_caption: [
      `예약할 수업`,
      `Current schedule`,
      'n_module.one_click.lesson_caption',
      `推荐预约`,
      `推薦預約`,
      `おすすめ予約`,
      ``,
    ],
    lesson_btn: [
      `다른 시간 보기`,
      `Change schedule`,
      'n_module.one_click.lesson_btn',
      `预约其他时间`,
      `預約其他時間`,
      `別の時間を予約する`,
      ``,
    ],
    btn_2: [
      `이 수업 예약하기`,
      `Book this lesson`,
      'n_module.one_click.btn_2',
      `预约此课程`,
      `預約此課程`,
      `このレッスンを予約する`,
      ``,
    ],
  },
}
export const n_onboarding = {
  landing: {
    subhead: [
      `프로필 입력하고`,
      `Fill your profile to`,
      'n_onboarding.landing.subhead',
      `输入基本基料`,
      `輸入基本基料`,
      `プロフィールを入力して`,
      ``,
    ],
    title: [
      <>
        영어 공부 목적에 딱 맞는
        <br />
        튜터를 찾아보세요 
      </>,
      <>
        Find the Perfect Tutors
        <br />
        for Your English Needs 
      </>,
      'n_onboarding.landing.title',
      <>寻找能够帮助学习目的的教师</>,
      <>尋找能夠幫助學習目的的教師</>,
      <>
        あなたにぴったりの
        <br />
        チューターを探しましょう
      </>,
      ``,
    ],
    caption_1: [
      `프로필을 모두 입력하면 10,000 포인트도 드려요.`,
      `Also, earn $7.69 points by completing your profile.`,
      'n_onboarding.landing.caption_1',
      `输入全部基本资料获得$7.69\b积分`,
      `輸入全部基本資料獲得$7.69點數`,
      `プロフィールを完成させると$7.69も獲得できます`,
      ``,
    ],
    caption_2: [
      <>
        <li className="li-tag-inside">입력한 내용은 프로필에서 언제든 변경할 수 있어요.</li>
        <li className="li-tag-inside">이미 프로필 입력 후 포인트를 받은 분은 중복으로 받을 수 없어요.</li> 
      </>,
      <>
        <li className="li-tag-inside">You can make changes to your profile any time.</li>
        <li className="li-tag-inside">You can only earn points for completing your profile once.</li> 
      </>,
      'n_onboarding.landing.caption_2',
      <>
        <li className="li-tag-inside">输入的资料随时可修改</li>
        <li className="li-tag-inside">每人输入资料后只能获得一次积分</li> 
      </>,
      <>
        <li className="li-tag-inside">輸入的資料隨時可修改</li>
        <li className="li-tag-inside">每人輸入資料後只能獲得一次點數</li> 
      </>,
      <>
        <li className="li-tag-inside">プロフィールはいつでも変更ができます。</li>
        <li className="li-tag-inside">初回のプロフィール入力時のみポイントを獲得できます。</li> 
      </>,
      ``,
    ],
    btn_1: [`튜터 찾기`, `Find Tutors`, 'n_onboarding.landing.btn_1', `寻找教师`, `尋找教師`, `チューターを探す`, ``],
    btn_2: [
      `다음에 할게요`,
      `I'll come back later`,
      'n_onboarding.landing.btn_2',
      `下次再说`,
      `下次再說`,
      `また後で探す`,
      ``,
    ],
    title2: [
      <>
        영어 공부 목적에 딱 맞는
        <br />
        수업을 시작해 보세요{' '}
      </>,
      <>
        Start lessons that precisely
        <br />
        meet your learning goals
      </>,
      'n_onboarding.landing.title2',
      `开始符合您英语学习目标的课程`,
      `開始符合您英文學習目標的課程`,
      <>
        英語学習の目的に合わせた
        <br />
        レッスンを始めましょう
      </>,
      ``,
    ],
    title4: [
      <>
        영어 공부 목적에 딱 맞는 수업을
        <br />
        4,000원으로 체험해보세요{' '}
      </>,
      <>
        {' '}
        Start lessons that precisely
        <br />
        meet your learning goals for $3.46
      </>,
      'n_onboarding.landing.title4',
      `体验一堂符合您英语学习目标的课程，仅需$3.46`,
      `體驗一堂 符合您英語學習目標的課程，僅需$3.46`,
      `英語学習の目的にぴったりのクラスを $3.46ウォンで体験してみましょう`,
      ``,
    ],
    caption_3: [
      `입력한 내용은 프로필에서 변경할 수 있어요.`,
      `You can change your choices later on your profile.`,
      'n_onboarding.landing.caption_3',
      `您可以在个人资料中更改输入的内容`,
      `您可以在個人資料中更改輸入的內容`,
      `入力内容は[プロフィール設定]で変更できます`,
      ``,
    ],
    btn_3: [`시작`, `Start`, 'n_onboarding.landing.btn_3', `开始`, `開始`, `開始`, ``],
    btn_5: [`결과 보기`, `See Results`, 'n_onboarding.landing.btn_5', `查看结果`, `查看結果`, `結果を見る`, ``],
    title3: (purpose) => [
      <>
        {purpose} 수업
        <br />
        링글에서 시작해 보세요
      </>,
      <>
        Take customized lessons
        <br />
        for {purpose}
      </>,
      'n_onboarding.landing.title3',
      `在Ringle开始${purpose}课程`,
      `在Ringle開始${purpose}課程`,
      <>
        {purpose}のレッスンを
        <br />
        Ringleで始めましょう
      </>,
      ``,
    ],
    detail3: [
      <>
        나의 공부 목적에 딱 맞는
        <br />
        튜터와 교재로 수업할 수 있어요. 
      </>,
      <>
        Discover tutors and materials that are
        <br />
        perfect for your learning goals.
      </>,
      'n_onboarding.landing.detail3',
      `您可以选择符合学习目标的教师和教材进行课程`,
      `您可以選擇符合學習目標的教師和教材進行課程`,
      <>
        学習目的と完璧に合致した
        <br />
        チューターと教材で授業を受けられます。
      </>,
      ``,
    ],
    detail3_pc: [
      <>나의 공부 목적에 딱 맞는 튜터와 교재로 수업할 수 있어요. </>,
      <>Discover tutors and materials that are perfect for your learning goals.</>,
      'n_onboarding.landing.detail3_pc',
      `您可以选择符合学习目标的教师和教材进行课程`,
      `您可以選擇符合學習目標的教師和教材進行課程`,
      <>
        学習目的と完璧に合致した
        <br />
        チューターと教材で授業を受けられます。
      </>,
      ``,
    ],
    tutor_title: (purpose) => [
      <>
        {purpose}에 <br />
        강점을 가진 튜터와 수업해 보세요
      </>,
      <>
        Meet tutors who specialize
        <br />
        in {purpose}
      </>,
      'n_onboarding.landing.tutor_title',
      `与拥有${purpose}经验的教师上课`,
      `與擁有${purpose}經驗的教師上課`,
      <>
        {purpose}に<br />
        強いチューターとレッスンを受けましょう
      </>,
      ``,
    ],
    detail4: [
      `2,000명 이상의 검증된 명문대 원어민 튜터 중 나의 영어 공부 목적과 잘 맞는 튜터를 추천해드려요.`,
      `Check out our recommended tutors for your English learning goals from over 2,000 verified native-speaker tutors from top universities.`,
      'n_onboarding.landing.detail4',
      `我们从2000多名经过验证，
来自顶尖大学的母语教师中推荐最适合您英语学习目标的教师`,
      `我們從2000多名經過驗證，
來自頂尖大學的母語教師中推薦最適合您英文學習目標的教師`,
      `認定された2,000人以上の名門大学出身ネイティブスピーカーの中から、あなたの英語学習の目的に合ったチューターをおすすめします。`,
      ``,
    ],
    material_title: [
      <>
        지금 나에게 필요한 교재로 <br />
        시작해 보세요
      </>,
      <>
        Study with materials that
        <br />
        deliver your needs.
      </>,
      'n_onboarding.landing.material_title',
      `从自己需要的教材开始课程`,
      `從自己需要的教材開始課程`,
      <>
        今の自分に必要な教材で
        <br />
        今すぐ始めましょう
      </>,
      ``,
    ],
    detail5: [
      `* 회원 가입하고 [예약하기] > [영어 공부 목적에 맞는 튜터 찾기]에서 추천 튜터를 확인할 수 있어요.`,
      `* Join Ringle, then go to [Book Lessons] > [Find Tutors for Your Goals] to view recommended tutors.`,
      'n_onboarding.landing.detail5',
      `* 加入会员后从 [预约课程] > [寻找适合您英语学习目标的教师] 获得教师推荐`,
      `* 加入會員後從 [預約課程] > [尋找適合您英文學習目標的教師] 獲得教師推薦`,
      `* 会員登録して[レッスンを予約する] > [英語学習の目的に合ったチューターを探す]でおすすめのチューターを確認できます。`,
      ``,
    ],
    btn_4: [
      `링글 가입하고 4,000원 첫 수업 하기`,
      `Join and get first lesson for $3`,
      'n_onboarding.landing.btn_4',
      <>
        加入Ringle，
        <br />
        以$3的价格进行首次课程。
      </>,
      <>
        加入Ringle，
        <br />
        以$3的價格進行首次課程。
      </>,
      <>
        Ringleに登録して
        <br />
        $3.46の初回レッスンを受けましょう
      </>,
      ``,
    ],
  },
  loading: [
    <>
      이미 입력된 프로필 정보를
      <br />
      불러오고 있어요 
    </>,
    <>
      Pooling information
      <br />
      from your existing profile
    </>,
    'n_onboarding.loading',
    <>正在处理输入的资料</>,
    <>正在處理輸入的資料</>,
    <>
      入力された情報を
      <br />
      読み込んでいます
    </>,
    ``,
  ],
  close_popup: {
    title: [
      `튜터 찾기를 중단하시겠어요?`,
      `Are you sure you want to leave?`,
      'n_onboarding.close_popup.title',
      `要中断搜寻教师吗？`,
      `要中斷搜尋教師嗎？`,
      `中断してよろしいでしょうか？`,
      ``,
    ],
    desc: [
      `이어하면 영어 공부 목적에 딱 맞는 튜터와 수업할 수 있어요.`,
      `If you continue, you can study with a perfect tutor for your English needs.`,
      'n_onboarding.close_popup.desc',
      `如果您继续，您可以与一个完美符合您英语学习需求的导师学习。`,
      `如果您繼續，您可以與一位完美符合您英語需求的導師學習。`,
      `続けると、英語のニーズに完璧に適した講師と学習できます。`,
      ``,
    ],
    btn_1: [`중단하기`, `Leave`, 'n_onboarding.close_popup.btn_1', `中断`, `中斷`, `中断する`, ``],
    btn_2: [`이어하기`, `Continue`, 'n_onboarding.close_popup.btn_2', `继续`, `繼續`, `続ける`, ``],
  },
  step: (n) => [
    <>
      {n}/{n}
    </>,
    <>
      {n}/{n}
    </>,
    'n_onboarding.step',
    <>
      {n}/{n}
    </>,
    <>
      {n}/{n}
    </>,
    <>
      {n}/{n}
    </>,
    ``,
  ],
  step_1: {
    title: [
      `영어 공부 목적을 알려주세요`,
      `Please tell us why you wish to learn English.`,
      'n_onboarding.step_1.title',
      `请告诉我们您的英文学习目标`,
      `請告訴我們您的英文學習目標`,
      `英語学習の目的を教えてください`,
      ``,
    ],
    category_1: [
      `원어민과 대화`,
      `Speaking`,
      'n_onboarding.step_1.category_1',
      `和母语教师对话`,
      `和母語教師對話`,
      `スピーキング`,
      ``,
    ],
    choice_1: [
      <>
        원어민과
        <br />
        회화 자신감 키우기
      </>,
      <>
        Build confidence
        <br />
        in speaking English
      </>,
      'n_onboarding.step_1.choice_1',
      <>
        增加与母语人士
        <br />
        进行对话的自信
      </>,
      <>
        增加與母語人士
        <br />
        進行對話的自信
      </>,
      <>
        英語で自信を持って
        <br />
        会話すること
      </>,
      ``,
    ],
    choice_2: [
      <>
        원어민과
        <br />
        사회이슈 토론하기
      </>,
      <>
        Discuss diverse
        <br />
        topics in English
      </>,
      'n_onboarding.step_1.choice_2',
      <>
        和母语人士
        <br />
        讨论社会议题
      </>,
      <>
        和母語人士
        <br />
        討論社會議題
      </>,
      <>
        多様な話題について
        <br />
        英語で議論すること
      </>,
      ``,
    ],
    category_2: [`비즈니스`, `Business`, 'n_onboarding.step_1.category_2', `商用英文`, `商用英文`, `ビジネス`, ``],
    choice_3: [
      <>
        영어 발표/ 미팅/
        <br />
        이메일 능력 향상
      </>,
      <>
        Get better at
        <br />
        business
        <br />
        communication
      </>,
      'n_onboarding.step_1.choice_3',
      <>
        增加商用英文表达/会议
        <br />
        以及书写邮件的能力
      </>,
      <>
        增加商用英文表達/會議
        <br />
        以及書寫郵件的能力
      </>,
      <>
        ビジネスシーンでの
        <br />
        コミュニケーションを
        <br />
        より向上させること
      </>,
      ``,
    ],
    category_3: [`인터뷰`, `Interviews`, 'n_onboarding.step_1.category_3', `英文面试`, `英文面試`, `面接`, ``],
    choice_4: [
      <>
        비즈니스
        <br />
        인터뷰 준비
      </>,
      <>
        Prep for
        <br />
        job interview
      </>,
      'n_onboarding.step_1.choice_4',
      <>准备英文工作面试</>,
      <>準備英文工作面試</>,
      <>採用面接対策</>,
      ``,
    ],
    choice5: [
      <>
        해외 유학
        <br />
        인터뷰 준비
      </>,
      <>
        Prep for
        <br />
        admissions
        <br />
        interview
      </>,
      'n_onboarding.step_1.choice5',
      <>
        海外留学
        <br />
        面试准备
      </>,
      <>
        海外留學
        <br />
        面試準備
      </>,
      <>留学面接対策</>,
      ``,
    ],
    category_4: [`교정`, `Writing`, 'n_onboarding.step_1.category_4', `修改文件`, `修改文件`, `ライティング`, ``],
    choice_6: [
      <>
        영어 에세이/
        <br />
        이력서 교정
      </>,
      <>
        Refine essays
        <br />
        and resumes
      </>,
      'n_onboarding.step_1.choice_6',
      <>
        修改英文履历
        <br />
        或是其他英文文件
      </>,
      <>
        修改英文履歷
        <br />
        或是其他英文文件
      </>,
      <>エッセイやレジュメの添削</>,
      ``,
    ],
    category_5: [
      `시험 준비`,
      `Language Test`,
      'n_onboarding.step_1.category_5',
      `准备考试`,
      `準備考試`,
      `語学試験`,
      ``,
    ],
    choice_7: [
      <>
        공인영어
        <br />
        시험 준비
      </>,
      <>
        Prep for
        <br />
        English language
        <br />
        certification
      </>,
      'n_onboarding.step_1.choice_7',
      <>准备英文检定考试</>,
      <>準備英文檢定考試</>,
      <>英語の資格試験対策</>,
      ``,
    ],
    category_6: [`여행 영어`, `Travelling`, 'n_onboarding.step_1.category_6', `旅游英文`, `旅遊英文`, `旅行`, ``],
    choice_8: [
      <>
        여행/휴가 시 더<br />
        유창한 영어 구사
      </>,
      <>
        Communicate in English
        <br />
        when travelling abroad
      </>,
      'n_onboarding.step_1.choice_8',
      <>
        在国外旅行时
        <br />
        用流利的英文沟通
      </>,
      <>
        在國外旅行時
        <br />
        用流利的英文溝通
      </>,
      <>
        海外旅行時における
        <br />
        英語でのコミュニケーション
      </>,
      ``,
    ],
    one_choice: [`(1개 선택 가능)`, `(One can be selected)`, 'n_onboarding.step_1.one_choice', ``, ``, ``, ``],
  },
  step_2: {
    title: [
      <>
        집중적으로 개선하고 싶은
        <br />
        부분을 알려주세요
      </>,
      <>
        Which areas do you
        <br />
        want to improve the most?
      </>,
      'n_onboarding.step_2.title',
      <>
        请选择想集中改善
        <br />
        提升能力的部分
      </>,
      <>
        請選擇想集中改善
        <br />
        提升能力的部分
      </>,
      <>
        どの分野を最も
        <br />
        向上させたいですか?
      </>,
      ``,
    ],
    choice_1: [
      `기초 어휘`,
      `Basic vocabulary`,
      'n_onboarding.step_2.choice_1',
      `基础词彙`,
      `基礎詞彙`,
      `基礎的な語彙`,
      ``,
    ],
    choice_2: [
      <>
        새로운
        <br />
        고급 표현
      </>,
      <>
        New advanced
        <br />
        expressions
      </>,
      'n_onboarding.step_2.choice_2',
      `新的高级表达方式`,
      `新的高級表達方式`,
      <>難しい言い回し</>,
      ``,
    ],
    choice_3: [
      <>
        원어민이
        <br />
        자주 쓰는
        <br />
        자연스러운 표현
      </>,
      <>
        Commonly-used
        <br />
        expressions
        <br />
      </>,
      'n_onboarding.step_2.choice_3',
      <>
        母语人士
        <br />
        经常使用的
        <br />
        自然表达方法
      </>,
      <>
        母語人士
        <br />
        經常使用的
        <br />
        自然表達方法
      </>,
      <>ネイティブが使う言い回し</>,
      ``,
    ],
    choice_4: [
      <>
        간결하고
        <br />
        정확한 표현
      </>,
      <>
        Speaking concisely
        <br />
        and accurately
      </>,
      'n_onboarding.step_2.choice_4',
      <>
        简洁且精练的
        <br />
        正确表达方式
      </>,
      <>
        簡潔且精練的
        <br />
        正確表達方式
      </>,
      <>簡潔で正確なスピーキング</>,
      ``,
    ],
    choice_5: [
      <>
        원어민 같은
        <br />
        발음과 액센트
      </>,
      <>
        Pronunciation/
        <br />
        accent
      </>,
      'n_onboarding.step_2.choice_5',
      <>
        和母语人士一样的
        <br />
        发音和语调
      </>,
      <>
        和母語人士一樣的
        <br />
        發音和語調
      </>,
      <>発音・アクセント</>,
      ``,
    ],
    choice_6: [
      <>
        막힘없는
        <br />
        대화
      </>,
      <>
        Conversing fluidly
        <br />
        in English
      </>,
      'n_onboarding.step_2.choice_6',
      `流畅地用英文对话`,
      `流暢地用英文對話`,
      <>流暢さ</>,
      ``,
    ],
    choice_7: [
      <>
        논리적인
        <br />
        대화
      </>,
      <>
        Logical speaking
        <br />
        in English
      </>,
      'n_onboarding.step_2.choice_7',
      `有逻辑地用英文交谈`,
      `有邏輯地用英文交談`,
      <>英語での論理的な会話</>,
      ``,
    ],
    choice_8: [
      <>
        논리적인
        <br />글 작성
      </>,
      <>
        Logical writing
        <br />
        in English
      </>,
      'n_onboarding.step_2.choice_8',
      `有逻辑地用英文写作`,
      `有邏輯地用英文寫作`,
      <>英語での論理的な作文</>,
      ``,
    ],
  },
  caption: [
    `* 최대 3개 선택 가능`,
    `* Select up to 3`,
    'n_onboarding.caption',
    `最多选择3项`,
    `最多選擇3項`,
    `最大3つまで`,
    ``,
  ],
  step_3: {
    title: [
      `관심 주제를 알려주세요`,
      `What are your interests?`,
      'n_onboarding.step_3.title',
      `请选择有兴趣的主题`,
      `請選擇有興趣的主題`,
      `どの領域に関心がありますか？`,
      ``,
    ],
    choice_1: [
      <>
        Business
        <br />
        Case
      </>,
      <>
        Business
        <br />
        Case
      </>,
      'n_onboarding.step_3.choice_1',
      `商业案例`,
      `商業案例`,
      <>
        Business
        <br />
        Case
      </>,
      ``,
    ],
    choice_2: [`Economy`, `Economy`, 'n_onboarding.step_3.choice_2', `经济`, `經濟`, `Economy`, ``],
    choice_3: [
      <>
        Society/
        <br />
        Politics
      </>,
      <>
        Society/
        <br />
        Politics
      </>,
      'n_onboarding.step_3.choice_3',
      `社会/政治`,
      `社會/政治`,
      <>
        Society/
        <br />
        Politics
      </>,
      ``,
    ],
    choice_4: [`Tech`, `Tech`, 'n_onboarding.step_3.choice_4', `科技`, `科技`, `Tech`, ``],
    choice_5: [
      <>
        Science/
        <br />
        Environment
      </>,
      <>
        Science/
        <br />
        Environment
      </>,
      'n_onboarding.step_3.choice_5',
      `科学/环境`,
      `科學/環境`,
      <>
        Science/
        <br />
        Environment
      </>,
      ``,
    ],
    choice_6: [
      `Office Life`,
      `Office Life`,
      'n_onboarding.step_3.choice_6',
      `办公室生活`,
      `辦公室生活`,
      `Office Life`,
      ``,
    ],
    choice_7: [
      <>
        Culture/
        <br />
        Entertainment
      </>,
      <>
        Culture/
        <br />
        Entertainment
      </>,
      'n_onboarding.step_3.choice_7',
      `文化/娱乐`,
      `文化/娛樂`,
      <>
        Culture/
        <br />
        Entertainment
      </>,
      ``,
    ],
    choice_8: [`Sports`, `Sports`, 'n_onboarding.step_3.choice_8', `体育`, `體育`, `Sports`, ``],
  },
  step_4: {
    title: [
      `수업 날짜와 시간을 선택해 주세요`,
      `Choose a lesson date and time.`,
      'n_onboarding.step_4.title',
      `选择课程日期及时间`,
      `選擇課程日期及時間`,
      `レッスンの日時を選択する`,
      ``,
    ],
    check: [
      `이 시간에 규칙적으로 수업하고 싶어요`,
      `I want to take lessons regularly at this time.`,
      'n_onboarding.step_4.check',
      `想要在这个时间固定上课`,
      `想要在這個時間固定上課`,
      `定期的にレッスンを受講したい`,
      ``,
    ],
  },
  toast: [
    `10,000포인트가 적립됐어요.`,
    `You earned $7.69 points.`,
    'n_onboarding.toast',
    `获得价值$7.69积分`,
    `獲得價值$7.69點數`,
    `$7.69獲得`,
    ``,
  ],
  searching: (user_name) => [
    <>
      {user_name}님께 딱 맞는
      <br />
      튜터를 찾고 있어요 
    </>,
    <>
      Listing tutors based on
      <br />
      your profile 
    </>,
    'n_onboarding.searching',
    <>
      正在寻找符合{user_name}
      <br />
      需求的教师
    </>,
    <>
      正在尋找符合{user_name}
      <br />
      需求的教師
    </>,
    <>
      あなたのプロフィールに基づいた
      <br />
      チューターのリスト
    </>,
    ``,
  ],
}
export const n_book_course = {
  complete: {
    title: [
      `첫 수업 예약을 완료했어요.`,
      `Your first lesson is booked!`,
      'n_book_course.complete.title',
      `第一堂课预约完成`,
      `第一堂课預約完成`,
      `はじめてのレッスンが予約されました！`,
      ``,
    ],
    material_desc: [
      `공부 목적에 맞는 추천 교재를 미리 예약해드렸어요.`,
      `We picked a lesson material for you based on your learning goal.`,
      'n_book_course.complete.material_desc',
      `我们已根据您的学习目标为您预约了推荐教材`,
      `我们已根据您的学习目标为您預約了推荐教材`,
      `学習目的をもとに、教材を選定しました`,
      ``,
    ],
    material_title: [
      `예약된 교재`,
      `Selected material`,
      'n_book_course.complete.material_title',
      `预约教材`,
      `预約教材`,
      `選択された教材`,
      ``,
    ],
    material_caption: [
      `수업 시작 1시간 전까지 자유롭게 변경 가능해요.`,
      `You can change the material any time up to 1 hour before the lesson.`,
      'n_book_course.complete.material_caption',
      `您可以在上课前一小时更换教材`,
      `您可以在上课前一小时更换教材`,
      `レッスン開始1時間前までは、いつでも教材を変更できます`,
      ``,
    ],
    section_title: [
      `링글 추천 코스로 첫 한 달 수업을 예약해 보세요.`,
      `Take Ringle-curated courses for your first month of lessons.`,
      'n_book_course.complete.section_title',
      `採用Ringle提供的建议预约第一个月的课程`,
      `採用Ringle提供的建議預約第一個月的課程`,
      `Ringleおすすめコースで最初の1ヶ月間のレッスンを予約してみましょう`,
      ``,
    ],
    course_title_1: [
      `원어민과 회화 자신감 키우기 코스`,
      `English Confidence`,
      'n_book_course.complete.course_title_1',
      `与母语人士对话建立自信课程`,
      `与母语人士對話建立自信课程`,
      `ネイティブとの会話に対する自信獲得`,
      ``,
    ],
    course_desc_1: [
      `[스몰토크 질문 세트]로 기초 회화 능력을 키우고 튜터와 가벼운 일상을 공유하다보면 영어 회화 자신감을 키울 수 있어요!`,
      `Build confidence in speaking English by exchanging small talk with your tutor using curated small talk questions!`,
      'n_book_course.complete.course_desc_1',
      `通过[Small Talk教材]建构基本会话技能，与教师分享生活，慢慢建立英语口说自信！`,
      `通过[Small Talk教材]建構基本会话技能，与教師分享生活，慢慢建立英语口說自信！`,
      `厳選されたスモールトークの質問を使って、チューターと会話することで、英語を話す自信をつけましょう！`,
      ``,
    ],
    course_title_2: [
      `원어민과 사회 이슈 토론하기 (중급) 코스`,
      `English Discussion (Intermediate)`,
      'n_book_course.complete.course_title_2',
      `与母语人士讨论社会问题（中级）课程`,
      `与母语人士讨论社会问题（中级）课程`,
      `英語でのディスカッション(中級)`,
      ``,
    ],
    course_desc_2: [
      `튜터와 사회/경제/과학/문화 등 다양한 분야의 이야기를 나눠보세요. 꾸준히 교정받고 토론하면 설득력 있게 영어로 말하는 스킬을 키울 수 있어요!`,
      `Learn how to talk persuasively in English on a variety of topics from social and economic issues to trending topics in tech and culture and more!`,
      'n_book_course.complete.course_desc_2',
      `与教师讨论社会、经济、科学、文化等话题。通过不断的纠正和讨论，您将掌握用英语进行有说服力对话的技巧！`,
      `与教師讨论社会、经济、科学、文化等话题。通过不断的纠正和讨论，您将掌握用英语进行有说服力对话的技巧！`,
      `社会・経済問題からテクノロジーやカルチャーなどのトレンドトピックまで、様々なトピックについて英語で説得力のある話し方を学びます！`,
      ``,
    ],
    course_title_3: [
      `비즈니스 영어 향상 코스`,
      `Business English`,
      'n_book_course.complete.course_title_3',
      `商务英语提高课程`,
      `商务英语提高课程`,
      `ビジネス英語`,
      ``,
    ],
    course_desc_3: [
      `실제 직장에서 바로 활용할 수 있는 표현 학습은 물론, 비즈니스 관련 주제로 토론 하며 비즈니스 영어 실력을 키울 수 있어요!`,
      `Learn expressions you can use at work and discuss business-related topics to improve your business English!`,
      'n_book_course.complete.course_desc_3',
      `学习在工作中实际使用的英语表达，讨论与商务相关的话题，提高商务英语水平！`,
      `学习在工作中實際使用的英語表達，讨论与商务相关的话题，提高商务英语水平！`,
      `仕事で使える表現を学んだり、ビジネス関連のトピックについて話し合ったりして、ビジネス英語を上達させましょう！`,
      ``,
    ],
    course_title_4: [
      `비즈니스 인터뷰 준비 코스`,
      `Job Interview Prep`,
      'n_book_course.complete.course_title_4',
      `商务面试准备课程`,
      `商务面试准备课程`,
      `就職面接対策`,
      ``,
    ],
    course_desc_4: [
      `직접 올린 CV와 준비된 비즈니스 면접 예상 질문으로 튜터와 실전처럼 연습할 수 있어요. 튜터가 여러분의 답변을 설득력 있는 답변으로 교정해줄 거예요!`,
      `Rehearse job interviews based on your CV and prepared interview questions. Your tutor will help you sound more professional and persuasive!`,
      'n_book_course.complete.course_desc_4',
      `利用自己的简历和准备好的商务面试问题，与教师一起练习，导师将对您的答案进行修改，使其更具说服力！`,
      `利用自己的简历和准备好的商务面试问题，與教師一起练习，导师将对您的答案进行修改，使其更具说服力！`,
      `履歴書と用意した面接の質問をもとに、面接の練習をしましょう。チューターはよりプロフェッショナルで説得力のある言い方になるようサポートしてくれます！`,
      ``,
    ],
    course_title_5: [
      `해외 유학 인터뷰 준비 코스`,
      `Admissions Interview Prep`,
      'n_book_course.complete.course_title_5',
      `留学面试准备课程`,
      `留学面试准备课程`,
      `留学面接対策`,
      ``,
    ],
    course_desc_5: [
      `직접 올린 CV와 준비된 대학원 면접 예상 질문으로 튜터와 실전처럼 연습할 수 있어요. 튜터가 여러분의 답변을 설득력 있는 답변으로 교정해줄 거예요!`,
      `Rehearse for admission interviews based on your CV and prepared interview questions. Your tutor will help you sound more persuasive!`,
      'n_book_course.complete.course_desc_5',
      `利用自己的简历和准备好的面试问题，与教师一起练习。 教师将针对您的答案提供建议和修正，确保其具有说服力！`,
      `利用自己的简历和准备好的面试问题，与教師一起练习。 教師将針對您的答案提供建議和修正，确保其具有说服力！`,
      `履歴書と用意した面接の質問をもとに、面接の練習をしましょう。チューターはより説得力のある言い方になるようサポートしてくれます！`,
      ``,
    ],
    course_title_6: [
      `영어 에세이/ 이력서 교정 코스`,
      `Essay/CV Editing`,
      'n_book_course.complete.course_title_6',
      `英语作文/简历编写课程`,
      `英语作文/简历编寫课程`,
      `エッセイ/履歴書添削`,
      ``,
    ],
    course_desc_6: [
      <>
        직접 올린 에세이/ 이력서를 튜터와 논의하며 교정 받을 수 있어요. 간결하면서도 명확하게 내가 의도한 메시지가
        효과적으로 반영된 에세이/이력서를 준비해 보세요!
        <br />
        *해당 수업은 튜터의 피드백 리포트가 제공되지 않아요.
      </>,
      <>
        Go over your essay/resume with a tutor to make sure that it effectively communicates what you mean to say
        concisely and clearly!
        <br />
        *Tutor's feedback report is not available for these lessons.
      </>,
      'n_book_course.complete.course_desc_6',
      <>
        与导师讨论并修改自己的论文/简历，确保以精炼、清晰并有效地用语传达信息！
        <br />
        *本课程不提供教师师反馈报告。
      </>,
      <>
        与导师讨论并修改自己的论文/简历，确保以精煉、清晰并有效地用語传达信息！
        <br />
        *本课程不提供教師师反馈报告。
      </>,
      <>
        エッセイや履歴書をチューターと一緒に確認し、言いたいことを簡潔かつ明確に効果的に伝えられるようにしましょう！
        <br />
        *このレッスンでは、チューターからのフィードバックレポートは利用できません。
      </>,
      ``,
    ],
    course_title_7: [
      `공인영어 시험 준비 코스`,
      `Certified English Test Prep`,
      'n_book_course.complete.course_title_7',
      `认证英语考试准备课程`,
      `认证英语考试准备课程`,
      `英語の資格試験対策`,
      ``,
    ],
    course_desc_7: [
      `시험 유형 별로 준비된 연습 질문으로 실전처럼 연습할 수 있어요. 튜터와 스피킹 연습은 물론 고득점을 위한 답변까지 준비해 보세요!`,
      `Practice like you're taking a test with practice questions prepared for each test type. Practice speaking with a tutor and prepare answers that will get you a high score!`,
      'n_book_course.complete.course_desc_7',
      `通过每种考试类型的练习题，像参加考试一样进行练习。 与教师一起练习口语，于考试获得高分！`,
      `通过每种考试类型的练习题，像参加考试一样进行练习。 与教師一起练习口语，於考試获得高分！`,
      `テストタイプ別に用意された練習問題で、本番のように練習できます。チューターとスピーキングの練習をすることで、高得点を取るための回答を準備しましょう！`,
      ``,
    ],
    week: (n) => [`${n}주차`, `Week ${n}`, 'n_book_course.complete.week', `第${n}週`, `第${n}週`, `Week ${n}`, ``],
    week_1: [`1주차`, `Week 1`, 'n_book_course.complete.week_1', `第1週`, `第1週`, `Week 1`, ``],
    week_2: [`2주차`, `Week 2`, 'n_book_course.complete.week_2', `第2週`, `第2週`, `Week 2`, ``],
    week_3: [`3주차`, `Week 3`, 'n_book_course.complete.week_3', `第3週`, `第3週`, `Week 3`, ``],
    week_4: [`4주차`, `Week 4`, 'n_book_course.complete.week_4', `第4週`, `第4週`, `Week 4`, ``],
    subhead: [
      `링글 추천 코스로 추가 예약할까요?`,
      `Book the recommended Ringle course?`,
      'n_book_course.complete.subhead',
      `要进行第2週到第4週课程预约吗？`,
      `要進行第2週到第4週課程預約嗎？`,
      `残りのコース(Week 2～4)を予約しますか？`,
      ``,
    ],
    btn_1: [`추가 예약하기`, `Book Now`, 'n_book_course.complete.btn_1', `追加预约`, `追加預約`, `今すぐ予約`, ``],
    btn_2: [`아니요`, `No thanks`, 'n_book_course.complete.btn_2', `不追加预约`, `不追加預約`, `結構です`, ``],
    material_desc_2: [
      `처음 링글을 시작하는 분께 추천하는 교재를 미리 예약해 드렸어요.`,
      `We picked a material for you that's suitable for first-time Ringlers.`,
      'n_book_course.complete.material_desc_2',
      `为了新加入Ringle的你，预约了推荐教材`,
      `為了新加入Ringle的你，預約了推薦教材`,
      `初めてRingleを利用される方向けに、おすすめの教材で予約しました。`,
      ``,
    ],
    group_title_1: [
      `원어민과 회화 자신감 키우기`,
      `English Confidence`,
      'n_book_course.complete.group_title_1',
      `与母语人士对话建立自信课程`,
      `与母语人士對話建立自信课程`,
      `ネイティブとの会話に対する自信獲得`,
      ``,
    ],
    group_title_2: [
      `원어민과 사회 이슈 토론하기 (중급)`,
      `English Discussion (Intermediate)`,
      'n_book_course.complete.group_title_2',
      `与母语人士讨论社会问题（中级）课程`,
      `与母语人士讨论社会问题（中级）课程`,
      `英語でのディスカッション(中級)`,
      ``,
    ],
    group_title_3: [
      `비즈니스 영어 향상`,
      `Business English`,
      'n_book_course.complete.group_title_3',
      `商务英语提高课程`,
      `商务英语提高课程`,
      `ビジネス英語`,
      ``,
    ],
    group_title_4: [
      `비즈니스 인터뷰 준비`,
      `Job Interview Prep`,
      'n_book_course.complete.group_title_4',
      `商务面试准备课程`,
      `商务面试准备课程`,
      `就職面接対策`,
      ``,
    ],
    group_title_5: [
      `해외 유학 인터뷰 준비`,
      `Admissions Interview Prep`,
      'n_book_course.complete.group_title_5',
      `留学面试准备课程`,
      `留学面试准备课程`,
      `留学面接対策`,
      ``,
    ],
    group_title_6: [
      `영어 에세이/ 이력서 교정`,
      `Essay/CV Editing`,
      'n_book_course.complete.group_title_6',
      `英语作文/简历编写课程`,
      `英语作文/简历编寫课程`,
      `エッセイ/履歴書添削`,
      ``,
    ],
    group_title_7: [
      `공인영어 시험 준비`,
      `Certified English Test Prep`,
      'n_book_course.complete.group_title_7',
      `认证英语考试准备课程`,
      `认证英语考试准备课程`,
      `英語の資格試験対策`,
      ``,
    ],
  },
  close_popup: {
    title: [
      <>
        한 달 코스 예약을 중단하시겠어요?
        <br />
        코스 예약은 첫 4회 수업에서만 가능해요.
      </>,
      <>
        {' '}
        Are you sure you want to quit?
        <br />
        You cannot take the course after your first 4 lessons.
      </>,
      'n_book_course.close_popup.title',
      <>
        您要中断预约一个月的课程吗?
        <br />
        建议先预约第一个月的四堂课
      </>,
      <>
        您要中斷預約一個月的課程嗎?
        <br />
        建議先預約第一個月的四堂課
      </>,
      <>
        コースの予約をやめますか?
        <br />
        最初の月に4回分のレッスンを予約することをお勧めしています
      </>,
      ``,
    ],
    desc: [
      <>
        영어 실력이 가장 많이 오른 상위 8% 링글러들은
        <br />
        매주 수업을 꾸준히 들었어요!
      </>,
      <>
        The top 8% of Ringlers who improved their
        <br />
        English the most took lessons every week!
      </>,
      'n_book_course.close_popup.desc',
      <>
        英文能力进步最多的前8%的学习者
        <br />
        都坚持每週进行课程！
      </>,
      <>
        英文能力進步最多的前8%的學習者
        <br />
        都堅持每週進行課程！
      </>,
      <>
        英語力を向上させた上位8%のユーザーは
        <br />
        毎週レッスンを受けていました！
      </>,
      ``,
    ],
    btn_1: [`중단하기`, `Quit`, 'n_book_course.close_popup.btn_1', `不追加预约`, `不追加預約`, `やめる`, ``],
    brn_2: [
      `이어하기`,
      `Continue booking`,
      'n_book_course.close_popup.brn_2',
      `追加预约`,
      `追加預約`,
      `予約を続ける`,
      ``,
    ],
    title_2: [
      `한 달 수업 예약을 중단하시겠어요?`,
      `Would you like to quit the booking?`,
      'n_book_course.close_popup.title_2',
      `您要中断预约一个月课程吗？`,
      `您要中斷預約一個月課程嗎？`,
      `1ヶ月間のレッスン予約を中止しますか？`,
      ``,
    ],
    btn_3: [`이어하기`, `Continue`, 'n_book_course.close_popup.btn_3', `继续预约`, `繼續預約`, `続行する`, ``],
    btn_4: [
      `중단하고 수업 안내 보기`,
      `Quit and ready for the lesson`,
      'n_book_course.close_popup.btn_4',
      `退出并准备上课`,
      `退出並準備上課`,
      `終了して授業の準備`,
      ``,
    ],
    btn_5: [
      `수업 안내사항 확인`,
      `Get ready before the lesson`,
      'n_book_course.close_popup.btn_5',
      `课前准备`,
      `課前準備`,
      `レッスン前の準備`,
      ``,
    ],
  },
  check: {
    title: [
      <>
        <span className="text-[#503CC8]">추가 예약</span>할 수업을
        <br />
        확인하고 예약을 완료하세요!
      </>,
      <>
        Please check the booking details
        <br />
        for <span className="text-[#503CC8]">remaining course lessons</span>.
      </>,
      'n_book_course.check.title',
      <>
        确认<span className="text-[#503CC8]">追加预约</span>课程
        <br />
        完成预约！
      </>,
      <>
        確認<span className="text-[#503CC8]">追加預約</span>課程
        <br />
        完成預約！
      </>,
      <>
        <span className="text-[#503CC8]">追加で予約するレッスン</span>の<br />
        詳細を確認してください.
      </>,
      ``,
    ],
    subhead: [
      `마지막 수업 시간과 같은 시간을 잡아드렸어요.`,
      `They're in the same time slot as your last lesson.`,
      'n_book_course.check.subhead',
      `以预约了和你上堂课相同的上课时间`,
      `以預約了和你上堂課相同的上課時間`,
      `前回の授業と同じ時間帯を予約しました。`,
      ``,
    ],
    caption_title: [
      <>
        상세 내용은 자유롭게 변경할 수 있어요.
        <br />
        편한 마음으로 먼저 예약해 보세요!
      </>,
      <>
        You can make changes any time.
        <br />
        Book first and make changes later!
      </>,
      'n_book_course.check.caption_title',
      <>
        课程内容可以自由变更
        <br />
        先轻鬆进行预约
      </>,
      <>
        課程內容可以自由變更
        <br />
        先輕鬆進行預約
      </>,
      <>
        いつでもレッスンは変更できるので
        <br />
        まずは気軽に予約して必要に応じて後で変更しましょう！
      </>,
      ``,
    ],
    caption_desc: [
      <>
        <li className="li-tag-inside">수업 시작 24시간 전까지 수업을 취소해도 수업권이 복구됩니다.</li>
        <li className="li-tag-inside">수업 시작 1시간 전까지 교재 변경이 가능합니다.</li> 
      </>,
      <>
        <li className="li-tag-inside">
          You can cancel lessons up to 24 hours before the lesson without losing the credit.
        </li>
        <li className="li-tag-inside">You can change the material up to 1 hour before the lesson.</li>
      </>,
      'n_book_course.check.caption_desc',
      <>
        <li className="li-tag-inside">教师确认前可以变更教师，于课程开始前24小时取消课程时将推环课程券</li>
        <li className="li-tag-inside">课程开始前1小时可变更教材</li> 
      </>,
      <>
        <li className="li-tag-inside">教師確認前可以變更教師，於課程開始前24小時取消課程時將推環課程券</li>
        <li className="li-tag-inside">課程開始前1小時可變更教材</li> 
      </>,
      <>
        <li className="li-tag-inside">
          チューター確定前であれば、チューターを変更することができます。24時間以上先のレッスンをキャンセルする場合は、レッスンチケットは返却されます。
        </li>
        <li className="li-tag-inside">教材についてはレッスン開始1時間前まで変更可能です。</li>
      </>,
      ``,
    ],
    course: [
      `추천 코스`,
      `Ringle-curated course`,
      'n_book_course.check.course',
      `推荐课程`,
      `推薦課程`,
      `Ringleおすすめコース`,
      ``,
    ],
    lesson: (n) => [
      <>추가 예약 {n}개</>,
      <>{n} remaining course lessons</>,
      'n_book_course.check.lesson',
      <>追加预约{n}堂</>,
      <>追加預約{n}堂</>,
      <>残りのコースレッスン{n}回</>,
      ``,
    ],
    number: (n) => [<>{n}</>, <>{n}</>, 'n_book_course.check.number', <>{n}</>, <>{n}</>, <>{n}</>, ``],
    btn_1: [`예약 완료`, `Confirm booking`, 'n_book_course.check.btn_1', `预约完成`, `預約完成`, `予約を確認する`, ``],
    btn_2: [
      `나가기`,
      `Cancel booking`,
      'n_book_course.check.btn_2',
      `取消追加预约`,
      `取消追加預約`,
      `予約をキャンセルする`,
      ``,
    ],
  },
  complete_lesson: {
    toast: [
      `한 달 코스로 수업을 예약했어요.`,
      `Course lessons are booked!.`,
      'n_book_course.complete_lesson.toast',
      `已完成预约一个月的课程`,
      `已完成預約一個月的課程`,
      `1ヶ月コースを予約しました。`,
      ``,
    ],
  },
  step_1: {
    title: [
      `수업 전에 확인해주세요!`,
      `Get ready before your lesson!`,
      'n_book_course.step_1.title',
      `课前请确认！`,
      `課前請確認！`,
      `授業前に確認してください`,
      ``,
    ],
  },
  terms: {
    title: [
      `2. 링글 수업 유의사항`,
      `2. Ringle Lesson Basics`,
      'n_book_course.terms.title',
      `2. Ringle课程注意事项`,
      `2. Ringle課程注意事項`,
      `2. Ringleレッスンの注意事項`,
      ``,
    ],
    desc_1: [
      `수업을 위해 Zoom을 다운로드 받은 PC를 준비해주세요. (앱에서는 수업 중 수업 노트를 확인할 수 없어요.)`,
      `Please prepare a PC that is compatible with Zoom for the lesson. (The lesson note feature cannot be viewed during the lesson on a smart phone.)`,
      'n_book_course.terms.desc_1',
      `上课前请先准备电脑并下载Zoom（使用手机APP将看不到课程笔记）`,
      `上課前請先準備電腦並下載Zoom（使用手機APP將看不到課程筆記）`,
      `レッスンにはZoomに対応したPCをご用意ください。(スマートフォンではレッスン中にレッスンノート機能はご覧いただけません) `,
      ``,
    ],
    desc_2: [
      `예정된 수업 또는 홈에서 수업 시작 15분 전에 [수업 입장] 버튼을 통해 입장할 수 있어요. 별도의 Zoom 링크는 제공되지 않아요.`,
      `The [Join the lesson] button under Ringle website's Home or Upcoming Lessons activates 15 minutes before the lesson. (No Zoom link available.)`,
      'n_book_course.terms.desc_2',
      `您可以在课程开始前 15 分钟通过 "进入课程 "按钮进入课程（ 不另外提供Zoom链接）`,
      `您可以在課程開始前 15 分鐘通過 "進入課程 "按鈕進入課程( 不另外提供Zoom連結)`,
      `Ringleホームページの「ホーム」または「予定されたレッスン」にある「レッスンに参加する」ボタンは、レッスンの15分前から有効になります。(Zoomリンクはありません)`,
      ``,
    ],
    desc_3: [
      `수업 변경 및 취소는 예정된 수업 > 수업 카드 > 더보기 아이콘을 통해 가능해요.`,
      `You can change or cancel the lesson by going to Upcoming Lessons > Lesson Card > See more.`,
      'n_book_course.terms.desc_3',
      `需要取消或变更课程可以在已预定课程中进行变更`,
      `需要取消或變更課程可以在已預定課程中進行變更`,
      `レッスンの変更やキャンセルは、「予定されたレッスン」から行えます。`,
      ``,
    ],
  },
  etiquette: {
    title: [
      `3. 링글 수업 에티켓`,
      `3. Ringle Rules of Conduct`,
      'n_book_course.etiquette.title',
      `3. Ringle上课礼仪`,
      `3. Ringle上課禮儀`,
      `3. Ringleレッスンエチケット`,
      ``,
    ],
    desc: [
      `수업 에티켓을 지키지 않으면, 링글 수업이 제한될 수 있습니다.`,
      `If the rules of conduct are not followed, future lessons may be limited.`,
      'n_book_course.etiquette.desc',
      `如果不能遵守上课礼仪, 您的Ringle学习可能会受到限制`,
      `未遵守上課禮儀時，Ringle的課程可能受限`,
      `レッスンエチケットを守らない場合、Ringleレッスンが制限されることがあります。`,
      ``,
    ],
    subhead_1: [
      `알맞은 수업 환경 준비하기`,
      `Set up an Environment for Learning`,
      'n_book_course.etiquette.subhead_1',
      `准备合适的上课环境`,
      `準備合適的上課環境`,
      `適切なレッスン環境を準備する`,
      ``,
    ],
    desc_1: [
      <>
        운전 /이동 중 및 음주 후 수업을 자제해 주세요.
        <br />
        학습 효과를 위해 카메라는 꼭 켜주세요.
      </>,
      <>
        Please refrain from taking lessons when driving/on the move or after drinking.
        <br />
        Please keep the camera on to facilitate learning.
      </>,
      'n_book_course.etiquette.desc_1',
      <>
        请不要于行驶交通工具或移动中进行课程
        <br />
        为了有更好的学习体验，请打开摄像头
      </>,
      <>
        請不要於行駛交通工具或移動中進行課程
        <br />
        為了有更好的學習體驗，請打開鏡頭
      </>,
      <>
        運転中、移動中、飲酒後のレッスンはご遠慮ください。
        <br />
        学習しやすいよう、カメラはOnにしてください。
      </>,
      ``,
    ],
    subhead_2: [
      `약속된 시간 지키기`,
      `Be Punctual`,
      'n_book_course.etiquette.subhead_2',
      `遵守约定的课程时间`,
      `遵守約定的課程時間`,
      `約束の時間を守る`,
      ``,
    ],
    desc_2: [
      <>
        정해진 시간을 지켜주세요.
        <br />
        시간 종료 후 요청을 자제해 주세요.
      </>,
      <>
        Please be on time.
        <br />
        Please refrain from making requests after the lesson time has ended.
      </>,
      'n_book_course.etiquette.desc_2',
      <>
        请遵守上课时间
        <br />
        课程结束时请不要要求更多时间
      </>,
      <>
        請遵守上課時間
        <br />
        課程結束時請不要要求更多時間
      </>,
      <>
        時間は厳守してください
        <br />
        レッスン時間終了後のリクエストはご遠慮ください
      </>,
      ``,
    ],
    subhead_3: [
      `튜터 존중하기`,
      `Treat Your Tutor with Respect`,
      'n_book_course.etiquette.subhead_3',
      `尊重任课教师`,
      `尊重任課教師`,
      `チューターをリスペクトする`,
      ``,
    ],
    desc_3: [
      <>
        사적인 질문, 수업과 관련 없는 요청,
        <br />
        사진 촬영, 혐오 발언을 주의해주세요.
      </>,
      <>
        Please refrain from making personal questions or requests that are unrelated to the lesson, taking photos, and
        hate speech.
      </>,
      'n_book_course.etiquette.desc_3',
      <>
        请勿提出过于私人或是与课程无关的问题
        <br />
        请勿摄影或使用不适当的言语
      </>,
      <>
        請勿提出過於私人或是與課程無關的問題
        <br />
        請勿攝影或使用不適當的言語
      </>,
      <>レッスンと関係のない個人的な質問やリクエスト、写真撮影、ヘイトスピーチはご遠慮ください。</>,
      ``,
    ],
  },
  ot: {
    title: [
      `1. 링글 오리엔테이션`,
      `1. Orientation Session`,
      'n_book_course.ot.title',
      `1. Ringle课前说明`,
      `1. Ringle課前說明`,
      `1. オリエンテーション`,
      ``,
    ],
    subhead: [
      <>
        학습 담당자가 직접 진행하는 OT에서
        <br />
        다음 내용을 알려드려요!
      </>,
      <>
        Hear from a Ringle manager on
        <br />
        how to get the most out of Ringle!
      </>,
      'n_book_course.ot.subhead',
      <>
        由Ringle团队提供的课前说明
        <br />
        将包含以下内容！
      </>,
      <>
        由Ringle團隊提供的課前說明
        <br />
        將包含以下內容！
      </>,
      <>
        担当者が進行するオリエンテーションで
        <br />
        以下の内容をお伝えします！
      </>,
      ``,
    ],
    desc: [
      <>
        <li className="li-tag-inside">효과적인 예/복습 방법</li>
        <li className="li-tag-inside">인터뷰/ 이메일/ PT 발표 준비 방법</li>
        <li className="li-tag-inside">습관적인 영어 약점 보완하는 방법</li>
      </>,
      <>
        <li className="li-tag-inside">Prep for/review lesson effectively</li>
        <li className="li-tag-inside">Ringle for interviews/presentations</li>
        <li className="li-tag-inside">Correcting bad English habits</li>
      </>,
      'n_book_course.ot.desc',
      <>
        <li className="li-tag-inside">有效率的预习/複习方法</li>
        <li className="li-tag-inside">准备面试和报告的方法</li>
        <li className="li-tag-inside">改善常犯的英文错误的方法</li>
      </>,
      <>
        <li className="li-tag-inside">有效率的預習/複習方法</li>
        <li className="li-tag-inside">準備面試和報告的方法</li>
        <li className="li-tag-inside">改善常犯的英文錯誤的方法</li>
      </>,
      <>
        <li className="li-tag-inside">効果的な予習・復習方法</li>
        <li className="li-tag-inside">面接・メール・PT発表の準備方法</li>
        <li className="li-tag-inside">習慣的な弱点を補う方法</li>
      </>,
      ``,
    ],
    choice_title: [
      `참여 가능한 일정을 선택해 주세요.`,
      `Choose your preferred time(s).`,
      'n_book_course.ot.choice_title',
      `请选择您可参与的日期和时间`,
      `請選擇您可參與的日期和時間`,
      `ご都合の良い日にちを選んでく笹井`,
      ``,
    ],
    choice_caption: [
      `*중복 선택 가능`,
      `*You can choose more than one.`,
      'n_book_course.ot.choice_caption',
      `*可以重複选择`,
      `*可以重複選擇`,
      `*複数選択可`,
      ``,
    ],
    caption: [
      <>
        <li className="li-tag-inside">OT 시작 1시간 전에 가입하신 이메일과 전화번호로 참여링크를 보내드려요.</li>
        <li className="li-tag-inside">해외 번호의 경우 이메일로만 보내드립니다.</li>
      </>,
      <>
        <li className="li-tag-inside">
          For those who need the Orientation Session in English, please email at song@ringleplus.com with your name and
          email address.
        </li>
      </>,
      'n_book_course.ot.caption',
      <>
        <li className="li-tag-inside">如果需要以中文进行说明会，请寄信至 helen.lin@ringleplus.com.</li>
      </>,
      <>
        <li className="li-tag-inside">如果需要以中文進行說明會，請寄信至 helen.lin@ringleplus.com.</li>
      </>,
      <>
        <li className="li-tag-inside">
          英語でのオリエンテーションが必要な方は、お名前とメールアドレスを明記の上、こちら(song@ringleplus.com)までご連絡ください
        </li>
      </>,
      ``,
    ],
    btn_1: [
      `OT 신청`,
      `Sign up for Orientation`,
      'n_book_course.ot.btn_1',
      `申请说明会`,
      `申請說明會`,
      `オリエンテーションに申し込む`,
      ``,
    ],
    btn_2: [`나중에 신청`, `Try later`, 'n_book_course.ot.btn_2', `下次再申请`, `下次再申請`, `後で申し込む`, ``],
  },
  complete_ot: {
    toast: [
      `OT 신청을 완료했어요.`,
      `You are signed up!`,
      'n_book_course.complete_ot.toast',
      `已完成申请使用说明会`,
      `已完成申請使用說明會`,
      `オリエンテーションに申し込む`,
      ``,
    ],
  },
  step_2: {
    title: [
      `수업 방식 설정`,
      `Set lesson styles.`,
      'n_book_course.step_2.title',
      `设定课程模式`,
      `設定課程模式`,
      `レッスン方式を設定する`,
      ``,
    ],
    subhead: [
      <>
        설정한 방식을 튜터가 수업에 반영해 줄 거예요.
        <br />
        지금 예약한 수업은 공부 목적에 맞춰 미리 설정해 드렸어요.
      </>,
      <>
        Your tutors will teach based on your preferences.
        <br />
        The lesson style has been pre-set to suit your study needs.
      </>,
      'n_book_course.step_2.subhead',
      <>
        教师将根据您设定的课程模式进行课程
        <br />
        现在的设定为依照您提供的学习目的所设定
      </>,
      <>
        教師將根據您設定的課程模式進行課程
        <br />
        現在的設定為依照您提供的學習目的所設定
      </>,
      <>
        チューターはあなたの希望をレッスンに反映します
        <br />
        レッスン方式は、あなたの学習ニーズに合うよう事前設定しています
      </>,
      ``,
    ],
  },
  lesson_style: {
    caption: [
      `수업 방식 설정은 [수업] > [예정된 수업] > [수업 방식 설정]에서 변경할 수 있어요.`,
      `You can change the settings by going to [Lesson] > [Upcoming Lessons] > [Set Lesson Style].`,
      'n_book_course.lesson_style.caption',
      `课程模式设定可于 [课程] > [已预约的课程] > [设定课程模式]进行变更`,
      `課程模式設定可於 [課程] > [已預約的課程] > [設定課程模式]進行變更`,
      `レッスン方式の設定は、[レッスン] > [予定されたレッスン] > レッスン方式の設定]で変更できます。`,
      ``,
    ],
    btn: [
      `변경`,
      `Change setting`,
      'n_book_course.lesson_style.btn',
      `进行变更`,
      `進行變更`,
      `設定変更設定を変更する`,
      ``,
    ],
  },
  complete_popup: {
    title: [
      <>
        이제 첫 수업을 위한
        <br />
        마지막 준비만 남았어요!
      </>,
      <>
        Just one last step left
        <br />
        for your first lesson!
      </>,
      'n_book_course.complete_popup.title',
      <>
        剩下最后一步
        <br />
        就能完成课前准备了！
      </>,
      <>
        剩下最後一步
        <br />
        就能完成課前準備了！
      </>,
      <>
        初回レッスンまで
        <br />
        あと一歩！
      </>,
      ``,
    ],
    desc: [
      `수업 교재를 읽고 수업할 질문을 선택해주세요.`,
      `Read the material and choose questions for the lesson.`,
      'n_book_course.complete_popup.desc',
      `阅读教材并且选择课程问题`,
      `閱讀教材並且選擇課程問題`,
      `教材を読み、レッスン用の質問を選ぶ。`,
      ``,
    ],
    btn: [
      `첫 수업 준비`,
      `Prep for first lesson`,
      'n_book_course.complete_popup.btn',
      `准备第一堂课`,
      `準備第一堂課`,
      `初回レッスンの予習`,
      ``,
    ],
  },
  auto_maching: {
    desc: [
      `* 튜터들이 아직 스케줄을 열지 않았어요. 수업 전에 평점이 높고 성실한 튜터와 매칭해 드릴게요.`,
      `* Tutors haven't opened their schedules yet. We'll match you with a highly rated,diligent tutor before the lesson.`,
      'n_book_course.auto_maching.desc',
      `* 教师尚未开放课程预约，课程开始将将为您配对课程评价高的教师。`,
      `* 教師尚未開放課程預約，課程開始將將為您配對課程評價高的教師。`,
      `*チューターがまだスケジュールを確定していないため、コース開始時に評価の高い講師とマッチングします。`,
      ``,
    ],
  },
  tutor: {
    title: (user_name) => [
      <>
        {user_name}님께 딱 맞는
        <br />
        튜터들로 미리 선택해 드렸어요.
      </>,
      <>
        We went ahead and chose
        <br />
        tutors who seem perfect for you.
      </>,
      'n_book_course.tutor.title',
      <>
        已先选择
        <br />
        适合{user_name}的教师
      </>,
      <>
        已先選擇
        <br />
        適合{user_name}的教師
      </>,
      <>
        {user_name}様に最適な
        <br />
        チューターを事前に選定しました。
      </>,
      ``,
    ],
  },
}
export const n_signup = {
  name: [`이름`, `Name`, 'n_signup.name', `姓名`, `姓名`, `お名前`, `Tên`],
  phone_number: [`휴대폰 번호`, `Mobile number`, 'n_signup.phone_number', `电话号码`, `電話號碼`, `携帯電話番号`, ``],
  referral_code: {
    code: [
      `[선택] 친구초대코드`,
      `Referral Code (optional)`,
      'n_signup.referral_code.code',
      `朋友代码(选择)`,
      `朋友代碼（選填）`,
      `招待コード(任意)`,
      `Mã giới thiệu (không bắt buộc)`,
    ],
    verified: (name) => [
      <>
        {name}님의 친구 초대코드가 확인되었습니다.
        <br />
        무료 체험 수업 1회와 50,000P가 지급 되었습니다.
      </>,
      <>
        {name}'s friend invitation code has been confirmed. <br />
        One free experience class and 50,000P were given.
      </>,
      'n_signup.referral_code.verified',
      ``,
      ``,
      ``,
      ``,
    ],
    special: (name) => [
      <>
        {name}님의 친구 초대코드가 확인되었습니다.
        <br />
        무료 체험 수업 1회와 100,000P가 지급 되었습니다.
      </>,
      <>
        {name}'s friend invitation code has been confirmed. <br />
        One free experience class and 100,000P were given.
      </>,
      'n_signup.referral_code.special',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  select_all: [
    `전체 동의`,
    `Consent to all terms`,
    'n_signup.select_all',
    `全部同意`,
    `全部同意`,
    `下記項目すべてに同意する`,
    ``,
  ],
  please_enter_phone: [
    `휴대폰 번호를 입력해주세요.`,
    `Enter your mobile number.`,
    'n_signup.please_enter_phone',
    `请输入电话号码。`,
    `請輸入電話號碼。`,
    `電話番号を入力してください。`,
    `Vui lòng nhập số điện thoại của bạn.`,
  ],
  invalid_code: [
    `존재하지 않는 친구추천코드입니다.`,
    `Invalid referral code.`,
    'n_signup.invalid_code',
    `无效的朋友邀请码`,
    `無效的朋友邀請碼`,
    `存在しない友達紹介コードです`,
    ``,
  ],
  see_content: [`내용 보기`, `View`, 'n_signup.see_content', `确认内容`, `確認內容`, `内容を確認する`, ``],
  already_used_email: [
    `이미 사용 중인 이메일입니다.`,
    `This email is already in use by another account.`,
    'n_signup.already_used_email',
    `这个Email已经被使用`,
    `這個Email已經被使用`,
    `このメールは他のID(アカウント)で使用されています`,
    ``,
  ],
}
export const n_sign_up = {
  agreement: {
    agree_essential_all: [
      `만 14세 이상 이용, 서비스 이용 약관, 개인정보 수집 및 이용 동의`,
      `I confirm that I am age 14 or older and consent to Ringle's Terms of Service and privacy policy.`,
      'n_sign_up.agreement.agree_essential_all',
      `必须年满 14 岁，同意服务条款，个人资料收集和使用协议`,
      `必須年滿 14 歲，同意服務條款，個人資料收集和使用協議`,
      `14歳以上であることと、Ringleのサービス利用規約・プライバシーポリシーの内容に同意する`,
      ``,
    ],
    agree_optional_all: [
      `마케팅 및 프로모션 소식 수신 동의`,
      `I consent to receiving marketing and promotional updates.`,
      'n_sign_up.agreement.agree_optional_all',
      `同意接收营销及促销讯息`,
      `同意接收行銷及促銷訊息`,
      `マーケティングおよびプロモーションニュースの受信に同意する`,
      ``,
    ],
  },
  teens_banner: {
    title: [`초/중/고 학생이 수강할 예정인가요?`, ``, 'n_sign_up.teens_banner.title', ``, ``, ``, ``],
    text: [`링글 틴즈 바로 가기`, ``, 'n_sign_up.teens_banner.text', ``, ``, ``, ``],
  },
}
export const n_auth = {
  phone_check_2: (email) => [
    <>{email} 아이디(이메일)로 가입된 휴대폰 번호입니다.</>,
    <>This mobile phone number is registered to ID (email) {email}.</>,
    'n_auth.phone_check_2',
    <>该手机号码已被以下ID(Email){email}注册时使用</>,
    <>該手機號碼已被以下ID(Email){email}註冊時使用</>,
    <>{email}は別のID(アカウント)に登録済みの電話番号です</>,
    ``,
  ],
}
export const n_tutor = {
  category: {
    featured: [
      `링글 추천 튜터`,
      `Featured`,
      'n_tutor.category.featured',
      `Ringle推荐教师`,
      `Ringle推薦教師`,
      `Ringleのおすすめ`,
      ``,
    ],
    point_back: [
      `50% 포인트백`,
      `50% Value Back`,
      'n_tutor.category.point_back',
      `50% 点数回馈`,
      `50% 點數回饋`,
      `50%ポイント還元`,
      ``,
    ],
    gen: [`성별`, `Gender`, 'n_tutor.category.gen', `性别`, `性別`, `性別`, ``],
    acc: [`발음`, `Accent`, 'n_tutor.category.acc', `口音`, `口音`, `アクセント`, ``],
    dep: [`전공`, `Major`, 'n_tutor.category.dep', `专业`, `主修`, `専攻`, ``],
    str: [`수업 강점`, `Strengths`, 'n_tutor.category.str', `课程特色`, `課程特色`, `強み`, ``],
    exp: [`경험`, `Experience`, 'n_tutor.category.exp', `经验`, `經驗`, `経験`, ``],
    tutor: [`튜터 카테고리`, ``, 'n_tutor.category.tutor', ``, ``, ``, ``],
  },
  tooltip: {
    featured: [
      `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터입니다.`,
      `Tutors who have received high ratings and positive reviews over the past month.`,
      'n_tutor.tooltip.featured',
      `最近一个月获得好评的教师`,
      `最近一個月獲得好評的教師`,
      `過去1カ月に高い評価を得たチューター`,
      ``,
    ],
    point_back: [
      `링글에 새로 온 튜터에게 수업 평가를 남기면 1회 수업료의 50%를 포인트로 돌려드립니다. (*유료 수업권 한정)`,
      `Receive 50% value back in points by leaving a review after a lesson with a rookie tutor. (Applicable only to lessons using paid credits.)`,
      'n_tutor.tooltip.point_back',
      `给新教师留下课后评价的话就能获得价值课程券50%点数(*仅限付费课程券）`,
      `給新教師留下課後評價的話就能獲得價值課程券50%點數(*僅限付費課程券）`,
      ``,
      ``,
    ],
    str: [
      `튜터의 수업 강점은 튜터 프로필, 수업 평점, 수업 리뷰 정보를 기준으로 AI 분석을 통해 반영됩니다.`,
      `Area that the tutor excels in teaching, identified through an AI-based analysis of the tutors' profiles, ratings, and reviews.`,
      'n_tutor.tooltip.str',
      `教师的课程特色是根据AI分析该教师的个人档案、课程评分和课程评价结果来反映`,
      `教師的課程特色是根據AI分析該教師的個人檔案、課程評分和課程評價結果來反映`,
      `チューターの強みは、プロフィール、授業の評価、授業のレビュー情報をもとにAIが判定します。`,
      ``,
    ],
    tutor_category: [
      <>
        링글 추천 튜터
        <br />- 최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터입니다.
        <br />
        50% 포인트백
        <br />- 링글에 새로 온 튜터에게 수업 평가를 남기면 1회 수업료의 50%를 포인트로 돌려드립니다. (*유료 수업권 한정)
      </>,
      <>
        Featured
        <br />- Tutors who have received high ratings and positive reviews over the past month.
        <br />
        50% Value Back
        <br />- Receive 50% value back in points by leaving a review after a lesson with a rookie tutor. (Applicable
        only to lessons using paid credits.)
      </>,
      'n_tutor.tooltip.tutor_category',
      <>
        Ringle推荐教师
        <br />
        最近一个月获得好评的教师
        <br />
        50% 点数回馈
        <br />
        给新教师留下课后评价的话就能获得价值课程券50%点数(*仅限付费课程券）
      </>,
      <>
        Ringle推薦教師
        <br />
        最近一個月獲得好評的教師
        <br />
        50% 點數回饋
        <br />
        給新教師留下課後評價的話就能獲得價值課程券50%點數(*僅限付費課程券）
      </>,
      <>
        Ringleのおすすめ
        <br />
        過去1カ月に高い評価を得たチューター
        <br />
        50%ポイント還元
        <br />
      </>,
      ``,
    ],
  },
  gen: {
    option_1: [`남자`, `Male`, 'n_tutor.gen.option_1', `男性`, `男性`, `男性`, ``],
    option_2: [`여자`, `Female`, 'n_tutor.gen.option_2', `女性`, `女性`, `女性`, ``],
  },
  acc: {
    option_1: [`미국식 발음`, `American`, 'n_tutor.acc.option_1', `美式发音`, `美式發音`, `アメリカ`, ``],
    option_2: [`영국식 발음`, `British`, 'n_tutor.acc.option_2', `英式发音`, `英式發音`, `イギリス`, ``],
  },
  dep: {
    option_1: [`사회과학계열`, `Social Sciences`, 'n_tutor.dep.option_1', `社会科学`, `社會科學`, `社会科学`, ``],
    option_2: [`인문계열`, `Liberal Arts`, 'n_tutor.dep.option_2', `人文`, `人文`, `人文系`, ``],
    option_3: [`공학계열`, `Engineering`, 'n_tutor.dep.option_3', `理工`, `理工`, `理工系`, ``],
    option_4: [`자연과학계열`, `Natural Science`, 'n_tutor.dep.option_4', `自然科学`, `自然科學`, `自然科学系`, ``],
    option_5: [
      `예체능계열`,
      `Fine Arts and Athletics`,
      'n_tutor.dep.option_5',
      `艺术／体育`,
      `藝術／體育`,
      `芸術・体育`,
      ``,
    ],
    option_6: [`상경계열`, `Business Administration`, 'n_tutor.dep.option_6', `商业`, `商業`, `経済・経営`, ``],
    option_7: [`의학계열`, `Medical Sciences`, 'n_tutor.dep.option_7', `医学`, `醫學`, `医学`, ``],
    option_8: [`법학계열`, `Law`, 'n_tutor.dep.option_8', `法律`, `法律`, `法律`, ``],
  },
  str: {
    option_1: [
      `초급 영어`,
      `Beginner English`,
      'n_tutor.str.option_1',
      `初级英语`,
      `初級英文`,
      `初心者向けの英会話`,
      ``,
    ],
    option_2: [
      `영어 토론`,
      `English Discussions`,
      'n_tutor.str.option_2',
      `英文讨论`,
      `英文討論`,
      `英語でのディスカッション`,
      ``,
    ],
    option_3: [
      `대학원 인터뷰`,
      `Admissions Interviews`,
      'n_tutor.str.option_3',
      `研究所面试`,
      `研究所面試`,
      `入学面接対策`,
      ``,
    ],
    option_4: [`비즈니스 인터뷰`, `Job Interviews`, 'n_tutor.str.option_4', `工作面试`, `工作面試`, `採用面接対策`, ``],
    option_5: [`비즈니스 영어`, `Business English`, 'n_tutor.str.option_5', `商用英语`, `商用英文`, `ビジネス英語`, ``],
    option_6: [
      `공인 영어 시험`,
      `Language Test Prep`,
      'n_tutor.str.option_6',
      `准备英文检定考试`,
      `準備英文檢定考試`,
      `語学試験対策`,
      ``,
    ],
    option_7: [
      `에세이/이력서`,
      `Refining Essays/Resume`,
      'n_tutor.str.option_7',
      `修改英语履历／论文`,
      `修改英文履歷／論文`,
      `エッセイ・レジュメの添削`,
      ``,
    ],
  },
  exp: {
    option_1: [`업무 경험`, `Has work experience`, 'n_tutor.exp.option_1', `工作经验`, `工作經驗`, `就業経験`, ``],
    option_2: [
      `대학원 경험`,
      `Attended graduate school`,
      'n_tutor.exp.option_2',
      `研究所经验`,
      `研究所經驗`,
      `大学院卒`,
      ``,
    ],
  },
  btn: {
    reset: [`초기화`, `Reset`, 'n_tutor.btn.reset', `初始化`, `初始化`, `初期化`, ``],
    apply: [`적용하기`, `Apply`, 'n_tutor.btn.apply', `查询`, `查詢`, `適用`, ``],
    reset_filter: [`필터 초기화`, `Refresh`, 'n_tutor.btn.reset_filter', `重新整理`, `重新整理`, `更新する`, ``],
  },
  featured: {
    desc: [
      `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터와 수업해 보세요! 추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
      ``,
      'n_tutor.featured.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const n_ai_speaking = {
  title: [
    `AI 스피킹 파트너`,
    `AI Speaking Partner`,
    'n_ai_speaking.title',
    `AI口语伙伴`,
    `AI口說夥伴`,
    `AI Speaking Partner`,
    ``,
  ],
  label: [`Beta`, `Beta`, 'n_ai_speaking.label', `Beta`, `Beta`, `Beta`, ``],
  count: [`개`, ``, 'n_ai_speaking.count', ``, ``, ``, ``],
  count_session: [`회`, ``, 'n_ai_speaking.count_session', ``, ``, ``, ``],
  home: {
    credit_count: [
      `남은 이용권`,
      `Tickets left`,
      'n_ai_speaking.home.credit_count',
      `剩馀的练习券`,
      `剩餘的練習券`,
      `チケットの残り`,
      ``,
    ],
    session_count: [
      `전체 대화`,
      `Talk list`,
      'n_ai_speaking.home.session_count',
      `全部对话纪录`,
      `全部對話紀錄`,
      `すべての会話`,
      ``,
    ],
    msg1: [
      <>
        링글 수업 시작 전에
        <br />
        함께 입을 풀어볼까요?
      </>,
      <>
        How about a warm-up
        <br />
        before the lesson?
      </>,
      'n_ai_speaking.home.msg1',
      <>
        开始Ringle课程前
        <br />
        先暖身一下!
      </>,
      <>
        開始Ringle課程前
        <br />
        先暖身一下!
      </>,
      <>
        レッスン前のウォームアップに
        <br />
        いかがでしょうか？
      </>,
      ``,
    ],
    msg2: [
      <>
        심심한데
        <br />
        나랑 대화할 사람?
      </>,
      <>
        I'm bored.
        <br />
        Wanna talk?
      </>,
      'n_ai_speaking.home.msg2',
      <>
        有觉得无聊
        <br />
        想和我练习口语的人吗?
      </>,
      <>
        有覺得無聊
        <br />
        想和我練習對話的人嗎?
      </>,
      <>
        退屈しています
        <br />
        会話しませんか?
      </>,
      ``,
    ],
    msg3: [
      <>
        수업 준비 혼자서 어려웠죠?
        <br />
        이제는 저와 대화 연습해요.
      </>,
      <>
        Don't feel ready for a lesson?
        <br />
        Practice talking with me!
      </>,
      'n_ai_speaking.home.msg3',
      <>
        觉得自己预习课程不充分吗?
        <br />
        和我一起练习吧!
      </>,
      <>
        覺得自己預習課程不充分嗎?
        <br />
        和我一起練習吧!
      </>,
      <>
        レッスンの準備は大変ですか?
        <br />
        私と一緒に練習しましょう!
      </>,
      ``,
    ],
    msg4: [
      <>
        반가워요!
        <br />
        오늘은 어떤 주제로 대화할까요?
      </>,
      <>
        Hi there!
        <br />
        What shall we talk about today?
      </>,
      'n_ai_speaking.home.msg4',
      <>
        哈囉!
        <br />
        今天想要用什麽主题练习对话呢?
      </>,
      <>
        哈囉!
        <br />
        今天想要用什麼主題練習對話呢?
      </>,
      <>
        こんにちは!
        <br />
        今日は何を話しましょうか?
      </>,
      ``,
    ],
  },
  credit_name: [
    `AI 스피킹 이용권`,
    `AI Speaking Ticket`,
    'n_ai_speaking.credit_name',
    `AI 口语练习券`,
    `AI 口說練習券`,
    `AI Speaking チケット`,
    ``,
  ],
  credit_name_long: [
    `AI 스피킹 파트너 이용권`,
    `AI Speaking Ticket`,
    'n_ai_speaking.credit_name_long',
    `AI 口语伙伴练习券`,
    `AI 口說夥伴練習券`,
    `AI Speaking Partner`,
    ``,
  ],
  my_ringle: {
    session_complete: [
      `완료한 대화`,
      `Completed`,
      'n_ai_speaking.my_ringle.session_complete',
      `完成的对话`,
      `完成的對話`,
      `終了した会話`,
      ``,
    ],
    session_ongoing: [
      `진행중인 대화`,
      `Ongoing`,
      'n_ai_speaking.my_ringle.session_ongoing',
      `进行中的对话`,
      `進行中的對話`,
      `進行中の会話`,
      ``,
    ],
    session_all: [
      `전체 대화`,
      `Talk list`,
      'n_ai_speaking.my_ringle.session_all',
      `全部对话`,
      `全部對話`,
      `すべての会話`,
      ``,
    ],
  },
  lesson: {
    tooltip_msg: [
      `AI 스피킹 파트너와 지난 대화는 여기서 볼 수 있어요.`,
      `Press to view your past talks with AI Speaking Partner.`,
      'n_ai_speaking.lesson.tooltip_msg',
      `可以确认过去和口语伙伴的对话纪录`,
      `可以確認過去和口說夥伴的對話紀錄`,
      `AI Speaking Partnerとの過去の会話はこちらでご覧いただけます。`,
      ``,
    ],
    ai_btn: [
      `AI 스피킹 파트너와 대화 시작`,
      `Talk with AI Speaking Partner`,
      'n_ai_speaking.lesson.ai_btn',
      `开始和AI口语伙伴对话`,
      `開始和AI口說夥伴對話`,
      `AI Speaking Partnerと会話を始める`,
      ``,
    ],
    days_left: [
      `{n}일 남음`,
      `{n} day(s) left`,
      'n_ai_speaking.lesson.days_left',
      `剩下{n}天`,
      `剩下{n}天`,
      `残り{n}日`,
      ``,
    ],
    msg: [
      `* 링글 앱에서 사용 가능합니다.`,
      `* Available only on Ringle app.`,
      'n_ai_speaking.lesson.msg',
      `* 可以在Ringle APP上使用`,
      `* 可以在Ringle APP上使用`,
      `* Ringleアプリで利用可能です。`,
      ``,
    ],
  },
  my_credit: {
    tooltip_msg: [
      `AI 스피킹 파트너 이용권은 링글 앱을 통해 AI 스피킹 파트너와 1:1 대화를 할 수 있는 이용권입니다.`,
      `This ticket gives you access to a 1:1 talk with AI Speaking Partner on the Ringle app.`,
      'n_ai_speaking.my_credit.tooltip_msg',
      `AI 口语伙伴练习券可以在APP上和AI伙伴练习口说时使用`,
      `AI 口說夥伴練習券可以在APP上和AI夥伴練習口說時使用`,
      `このチケットは、AI Speaking Partnerとのマンツーマンでの会話をRingleアプリ上ですることができます。`,
      ``,
    ],
    tooltip_msg1: [
      `AI 스피킹 파트너 이용권의 이용 기간은 수업권 수강 기간 + 7일입니다.`,
      `AI Speaking Partner ticket is valid until 7 days after the credit expiry date.`,
      'n_ai_speaking.my_credit.tooltip_msg1',
      `AI 口语伙伴练习券的使用期限是课程券的使用期限+7天`,
      `AI 口說夥伴練習券的使用期限是課程券的使用期限+7天`,
      `AI Speaking Partnerチケットの利用期間は、レッスン受講期間＋7日間です。`,
      ``,
    ],
    label: [`20분`, `20 min`, 'n_ai_speaking.my_credit.label', `20分钟`, `20分鐘`, `20分`, ``],
    title: [
      `수업권/이용권 유의사항`,
      `Terms of use`,
      'n_ai_speaking.my_credit.title',
      `注意事项`,
      `注意事項`,
      `利用規約`,
      ``,
    ],
    note_4: [
      `AI 스피킹 파트너 이용권의 시작일은 구매한 수업권과 동일합니다. AI 스피킹 파트너 이용권의 이용기간은 구매한 수업권의 수강기간 +7일입니다.`,
      `AI Speaking Partner ticket's validity period begins on the same date as the purchased credits and ends 7 days after the credits' expiry date.`,
      'n_ai_speaking.my_credit.note_4',
      `AI口语伙伴练习券的使用期限的起算日和购买的课程券相同，
使用截止日为课程券有效日期+7天`,
      `AI口說夥伴練習券的使用期限的起算日和購買的課程券相同，
使用截止日為課程券有效日期+7天`,
      `AI Speaking Partnerチケットの有効期間は、購入したクレジットと同じ日に始まり、クレジットの有効期限の7日後に終了します。`,
      ``,
    ],
    period: [`이용 기간`, `Validity`, 'n_ai_speaking.my_credit.period', `使用期限`, `使用期限`, `使用期限`, ``],
    title1: [
      `이용권 보유 현황`,
      `My Tickets`,
      'n_ai_speaking.my_credit.title1',
      `练习券持有状态`,
      `練習券持有狀態`,
      `チケットの保有状況`,
      ``,
    ],
    credit_type_app: [
      `이용권 종류 / 이용권 수`,
      `Ticket type / count`,
      'n_ai_speaking.my_credit.credit_type_app',
      `练习券种类/数量`,
      `練習券種類/數量`,
      `チケットの種類 / 枚数`,
      ``,
    ],
    credit_type: [
      `이용권 종류`,
      `Ticket type`,
      'n_ai_speaking.my_credit.credit_type',
      `练习券种类`,
      `練習券種類`,
      `チケットの種類`,
      ``,
    ],
    credit_count: [
      `이용권 수`,
      `Ticket count`,
      'n_ai_speaking.my_credit.credit_count',
      `练习券数量`,
      `練習券數量`,
      `チケットの枚数`,
      ``,
    ],
    credit_detail: [
      `이용 내역 :`,
      `Tickets used :`,
      'n_ai_speaking.my_credit.credit_detail',
      `使用纪录`,
      `使用紀錄`,
      `利用履歴 ：`,
      ``,
    ],
    session_info: [
      `대화 내용 / 대화 시작 시간`,
      `Talk details / Start time`,
      'n_ai_speaking.my_credit.session_info',
      `对话内容/开始时间`,
      `對話內容/開始時間`,
      `会話内容/開始時間`,
      ``,
    ],
    session_material: [
      `대화 내용`,
      `Talk details`,
      'n_ai_speaking.my_credit.session_material',
      `对话内容`,
      `對話內容`,
      `会話内容`,
      ``,
    ],
    session_start_time: [
      `대화 시작 시간`,
      `Start time`,
      'n_ai_speaking.my_credit.session_start_time',
      `对话开始时间`,
      `對話開始時間`,
      `会話開始時間`,
      ``,
    ],
    credit_info: [
      `이용권 보유 현황`,
      `My Tickets`,
      'n_ai_speaking.my_credit.credit_info',
      `练习券持有状态`,
      `練習券持有狀態`,
      `チケットの保有状況`,
      ``,
    ],
    not_started: [`미시작`, `inactive`, 'n_ai_speaking.my_credit.not_started', `b尚未使用`, `b尚未使用`, `未使用`, ``],
    detail_button: [
      `이용권 사용 내역`,
      `See details`,
      'n_ai_speaking.my_credit.detail_button',
      `查看详情`,
      `詳細內容`,
      `詳しく見る`,
      ``,
    ],
  },
  credit_count: [
    `사용 가능 이용권 :`,
    `Available tickets :`,
    'n_ai_speaking.credit_count',
    `可使用的练习券`,
    `可使用的練習券`,
    `使用可能なチケット`,
    ``,
  ],
  popup_nocredit: {
    title: [
      `AI 스피킹 파트너 이용권이 없어요.`,
      `You have no AI Speaking Partner tickets.`,
      'n_ai_speaking.popup_nocredit.title',
      `您没有AI口语伙伴练习券`,
      `您沒有AI口說夥伴練習券`,
      `AI Speaking Partner チケットがありません`,
      ``,
    ],
    btn1: [
      `지난 대화 보기`,
      `View past talks`,
      'n_ai_speaking.popup_nocredit.btn1',
      `确认上次对话`,
      `確認上次對話`,
      `過去の会話を見る`,
      ``,
    ],
  },
  popup_new: {
    title: [
      `새로운 대화를 시작할까요?`,
      `Would you like to start a new talk?`,
      'n_ai_speaking.popup_new.title',
      `您要开始新对话吗?`,
      `您要開始新對話嗎?`,
      `新しい会話を始めますか？`,
      ``,
    ],
    btn: [
      `대화 시작하기`,
      `Start a new talk`,
      'n_ai_speaking.popup_new.btn',
      `开始新对话`,
      `開始新對話`,
      `新しい会話を始める`,
      ``,
    ],
  },
  popup_ongoing: {
    title: [
      <>
        진행 중인 대화가 있어요.
        <br />
        그래도 새로운 대화를 시작할까요?
      </>,
      <>
        You have an ongoing talk.
        <br />
        Do you still wish to start a new talk?
      </>,
      'n_ai_speaking.popup_ongoing.title',
      <>
        已有进行中的对话
        <br />
        还是要开始新对话吗?
      </>,
      <>
        已有進行中的對話
        <br />
        還是要開始新對話嗎?
      </>,
      <>
        進行中の会話があります
        <br />
        新しい会話を始めますか?
      </>,
      ``,
    ],
    btn1: [
      `새로운 대화 시작하기`,
      `Start a new talk`,
      'n_ai_speaking.popup_ongoing.btn1',
      `开始新的对话`,
      `開始新的對話`,
      `新しい会話を始める`,
      ``,
    ],
    btn2: [
      `진행 중인 대화 이어 하기`,
      `Resume ongoing talk`,
      'n_ai_speaking.popup_ongoing.btn2',
      `继续进行中的对话`,
      `繼續進行中的對話`,
      `進行中の会話を再開する`,
      ``,
    ],
    body: [
      `새로운 대화를 시작하면 진행중이던 대화는 종료됩니다.`,
      `Starting a new talk will end your ongoing talk.`,
      'n_ai_speaking.popup_ongoing.body',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup_mic: {
    title: [
      `마이크 사용 권한이 필요해요`,
      `Microphone access required`,
      'n_ai_speaking.popup_mic.title',
      `需要麦克风使用权限`,
      `需要麥克風使用權限`,
      `マイクへのアクセス許可が必要です`,
      ``,
    ],
    body: [
      `링글 AI와 대화를 진행하려면 기기설정에서 마이크 사용을 ‘허용’해주세요.`,
      `To start talking with Ringle AI, please allow access to the microphone in your device settings.`,
      'n_ai_speaking.popup_mic.body',
      `如果要和AI进行对话，请打开麦克风使用权限`,
      `如果要和AI進行對話，請打開麥克風使用權限`,
      `Ringle AIと会話を行うには、デバイス設定でマイクの使用を「許可」してください。`,
      ``,
    ],
    btn1: [
      `기기 설정하기`,
      `Go to device settings`,
      'n_ai_speaking.popup_mic.btn1',
      `进行设定`,
      `進行設定`,
      `デバイスの設定`,
      ``,
    ],
  },
  popup_exit1: {
    title: [
      <>
        지금 대화를 중단하면 AI 분석을 받을 수 없어요.
        <br />
        그래도 그만할까요?{' '}
      </>,
      <>
        AI analysis cannot be performed if you end the talk now.
        <br />
        Do you wish to end it anyway?
      </>,
      'n_ai_speaking.popup_exit1.title',
      <>
        如果现在中断对话，将无法获得AI分析报告
        <br />
        确定要中断吗?{' '}
      </>,
      <>
        如果現在中斷對話，將無法獲得AI分析報告
        <br />
        確定要中斷嗎?{' '}
      </>,
      <>
        今会話を終了すると、AI Analysis は提供されません
        <br />
        それでも会話を終了しますか?
      </>,
      ``,
    ],
    body: (time) => [
      <>AI 분석을 받으려면 최소 10번의 턴이 필요해요. {time}분 이내에 돌아와서 대화를 이어나갈 수 있어요.</>,
      <>
        At least 10 turns are necessary to run the AI analysis.{'\n'}You can resume the talk anytime within {time} mins.
      </>,
      'n_ai_speaking.popup_exit1.body',
      <>
        如想要获得AI分析报告请进行至少十次来回对话，{'\n'}在{time}分钟之内都可以回来继续对话
      </>,
      <>
        如想要獲得AI分析報告請進行至少十次來回對話，{'\n'}在{time}分鐘之內都可以回來繼續對話
      </>,
      `AIの分析を受けるには最低10ターン必要です。 {time}分以内であれば会話に戻ることができます。`,
      ``,
    ],
    title_b2b: [
      <>지금 대화를 중단하면 출석이 인정되지 않고 AI 분석을 받을 수 없어요.{'\n'}그래도 그만할까요? </>,
      <>
        Attendance won't be recognized and AI analysis cannot be performed if you end the talk now.{'\n'}Do you wish to
        end it anyway?
      </>,
      'n_ai_speaking.popup_exit1.title_b2b',
      <>现在停止对话的话，将无法认定出席，也无法进行AI分析。 {'\n'} 您仍然要停止吗？ </>,
      <>現在停止對話的話，將無法認定出席，也無法進行AI分析。 {'\n'} 您仍然要停止嗎？ </>,
      <>今会話を中断すると、出席が認められず、AI分析を受けることができません。 {'\n'} それでもやめますか？ </>,
      ``,
    ],
    body_b2b: (time) => [
      <>
        출석 인정과 AI 분석을 받으려면 최소 10번의 턴이 필요해요.{'\n'}
        {time}분 이내에 돌아와서 대화를 이어나갈 수 있어요.
      </>,
      <>
        At least 10 turns are necessary to get attendance recognition and run the AI analysis.{'\n'}You can return and
        continue the conversation within {time} minutes.
      </>,
      'n_ai_speaking.popup_exit1.body_b2b',
      <>
        要获得出席认定和AI分析，至少需要10轮。 {'\n'}您可以在{time}分钟内回来继续对话。{' '}
      </>,
      <>
        要獲得出席認定和AI分析，至少需要10輪。 {'\n'}您可以在{time}分鐘內回來繼續對話。{' '}
      </>,
      <>
        出席が認められ、AI分析を受けるには最低10ターン必要です。 {'\n'} {time}分以内に戻って会話を続けることができます。
      </>,
      ``,
    ],
  },
  popup_exit2: {
    title: [
      `대화를 마무리 할까요?`,
      `Do you wish to end the talk?`,
      'n_ai_speaking.popup_exit2.title',
      `您要结束对话吗?`,
      `您要結束對話嗎?`,
      `会話を終了しますか？`,
      ``,
    ],
    body: [
      <>
        마무리한 대화는 이어서 할 수 없어요.
        <br />
        대화 AI 분석은 1시간 내로 제공돼요.
      </>,
      `You cannot resume the talk after ending it.`,
      'n_ai_speaking.popup_exit2.body',
      `如果结束对话将无法继续，AI分析报告将在一个小时内提供`,
      `如果結束對話將無法繼續，AI分析報告將在一個小時內提供`,
      `終了した会話は続行できません。 会話のAI Analysisは1時間以内に提供されます。`,
      ``,
    ],
    btn: [`마무리하기`, `End the talk`, 'n_ai_speaking.popup_exit2.btn', `结束对话`, `結束對話`, `終了する`, ``],
  },
  popup_error: {
    title: [
      `오류가 발생했어요.`,
      `An error has occurred.`,
      'n_ai_speaking.popup_error.title',
      `发生错误`,
      `發生錯誤`,
      `エラーが発生しました`,
      ``,
    ],
    body: [
      <>
        대화를 다시 시작해 주세요.{'\n'}계속 오류가 발생한다면 아래 버튼을 눌러 문의해 주세요. 이용권을
        복구해드리겠습니다.
      </>,
      `Please try restarting the talk. If an error keeps occurring, please leave an inquiry here. We will restore your ticket.`,
      'n_ai_speaking.popup_error.body',
      `请重新开始对话，如果错误持续发生，请与我们反映，我们将退还练习券`,
      `請重新開始對話，如果錯誤持續發生，請與我們反映，我們將退還練習券`,
      `会話を再開してください。引き続きエラーが発生する場合は、こちらからお問い合わせください。 チケットを復元させていただきます。`,
      ``,
    ],
  },
  material_msg: [
    `‘내가 만드는 수업’ 교재로는 AI 스피킹 파트너와 대화를 나눌 수 없습니다.`,
    `You cannot use 'Custom Class' materials when talking with AI Speaking Partner.`,
    'n_ai_speaking.material_msg',
    `无法使用"自由主题"教材与AI进行对话`,
    `無法使用"自由主題"教材與AI進行對話`,
    `「自由テーマ」の教材では、AI Speaking Partnerとの会話はできません。`,
    ``,
  ],
  aI_list: {
    month: (month) => [
      `${month}월`,
      `${getMonthName(month - 1)[1]}`,
      'n_ai_speaking.aI_list.month',
      `${month}月`,
      `${month}月`,
      `${month}月`,
      ``,
    ],
    filter_all: (n) => [
      <>전체 ({n})</>,
      <>All ({n})</>,
      'n_ai_speaking.aI_list.filter_all',
      <>全部 ({n})</>,
      <>全部 ({n})</>,
      <>すべて ({n})</>,
      ``,
    ],
    filter_ai_analysis: [
      `AI 분석 있는 대화만 보기`,
      `AI analysis available`,
      'n_ai_speaking.aI_list.filter_ai_analysis',
      `查看有AI分析的对话`,
      `查看有AI分析的對話`,
      `AI Analysisのある会話のみ表示`,
      ``,
    ],
    empty_msg1: [
      `대화 기록이 없어요`,
      `You have no history of talks.`,
      'n_ai_speaking.aI_list.empty_msg1',
      `找不到对话纪录`,
      `找不到對話紀錄`,
      `会話履歴がありません`,
      ``,
    ],
    empty_msg2: [
      `새로운 대화를 시작해보세요!`,
      `Start a new talk!`,
      'n_ai_speaking.aI_list.empty_msg2',
      `开始新对话!`,
      `開始新對話!`,
      `新しい会話を始めましょう！`,
      ``,
    ],
    empty_btn1: [
      `AI 스피킹 파트너란?`,
      `What is AI Speaking Partner?`,
      'n_ai_speaking.aI_list.empty_btn1',
      `什麽是AI口语伙伴`,
      `什麼是AI口說夥伴`,
      `AI Speaking Partnerとは？`,
      ``,
    ],
    empty_btn2: [
      `최근 대화 기록`,
      `Recent talks`,
      'n_ai_speaking.aI_list.empty_btn2',
      `最近的对话纪录`,
      `最近的對話紀錄`,
      `最近の会話履歴`,
      ``,
    ],
    start_btn: [
      `새로운 대화 시작하기`,
      `Start a new talk`,
      'n_ai_speaking.aI_list.start_btn',
      `开始新对话!`,
      `開始新對話!`,
      `新しい会話を始める`,
      ``,
    ],
    card_label1: [
      `대화 진행중`,
      `Ongoing`,
      'n_ai_speaking.aI_list.card_label1',
      `对话进行中`,
      `對話進行中`,
      `進行中`,
      ``,
    ],
    card_msg: (n) => [
      <>
        대화는 <span className="text-purple-500">{n}분</span> 후 자동으로 종료돼요
      </>,
      <>
        This talk will end automatically in <span className="text-purple-500">{n} min(s)</span>.
      </>,
      'n_ai_speaking.aI_list.card_msg',
      <>
        对话将在 <span className="text-purple-500">{n}</span> 分后自动结束
      </>,
      <>
        對話將在 <span className="text-purple-500">{n}</span> 分後自動結束
      </>,
      `会話は {n} 分で自動的に終了します`,
      ``,
    ],
    card_turn: [`턴`, `turn(s)`, 'n_ai_speaking.aI_list.card_turn', `次`, `次`, `ターン`, ``],
    card_btn1: [
      `대화 이어하기`,
      `Resume talk`,
      'n_ai_speaking.aI_list.card_btn1',
      `继续上次的对话`,
      `繼續上次的對話`,
      `会話を再開する`,
      ``,
    ],
    card_btn2: [
      `대화 스크립트 확인`,
      `View transcript`,
      'n_ai_speaking.aI_list.card_btn2',
      `确认对话文字记录`,
      `確認對話文字記錄`,
      `スクリプトを見る`,
      ``,
    ],
    card_label2: [
      `AI 분석 확인`,
      `View AI analysis`,
      'n_ai_speaking.aI_list.card_label2',
      `确认AI分析`,
      `確認AI分析`,
      `AI Analysis`,
      ``,
    ],
    card_label3: [`준비 중`, `Running`, 'n_ai_speaking.aI_list.card_label3', `准备中`, `準備中`, `準備中`, ``],
    card_msg1: [
      `AI 분석 중에 문제가 생겼어요. 이곳을 눌러 문의를 남겨주세요.`,
      `A problem occurred while running the AI analysis. Please press here to leave an inquiry.`,
      'n_ai_speaking.aI_list.card_msg1',
      `AI分析过程发生问题，请点这裡提出问题`,
      `AI分析過程發生問題，請點這裡提出問題`,
      `AI Analysisの実行中に問題が発生しました。お問い合わせはこちらをクリックしてください。`,
      ``,
    ],
  },
  aI_intro: {
    msg1: [
      <>
        링글 AI 스피킹 파트너와
        <br />
        대화를 시작해보세요.
      </>,
      <>
        Start talking with
        <br />
        Ringle AI Speaking Partner.
      </>,
      'n_ai_speaking.aI_intro.msg1',
      <>
        和Ringle AI
        <br />
        开始对话
      </>,
      <>
        和Ringle AI
        <br />
        開始對話
      </>,
      <>
        Ringle AIと
        <br />
        会話を始めましょう
      </>,
      ``,
    ],
    msg2: [
      <>
        링글 AI와 영어로 대화를 나누기 위해서는 <span className="font-bold">마이크 사용 권한</span>이 필요해요.
      </>,
      `Please allow access to the microphone to talk with Ringle AI in English.`,
      'n_ai_speaking.aI_intro.msg2',
      `为了和AI进行对话，请打开麦克风权限`,
      `為了和AI進行對話，請打開麥克風權限`,
      `Ringle AIと会話するため、マイクへのアクセスを許可してください。`,
      ``,
    ],
    msg3: [
      `* 대화를 마친 후 1시간 이내에 AI 분석이 제공돼요.`,
      `* AI analysis is provided within one hour after the talk.`,
      'n_ai_speaking.aI_intro.msg3',
      `* 与AI对话结束后一小时提供AI分析报告`,
      `* 與AI對話結束後一小時提供AI分析報告`,
      `* AI Analysisは会話終了後1時間以内に行われます。`,
      ``,
    ],
    msg4: [
      <>
        대화는 <span className="font-bold">최대 20턴(약 20분)</span>이에요. 20턴을 채우면 대화가 자동으로 마무리돼요.
      </>,
      `You can take max. 20 turns per talk (about 20 mins). After 20 turns, the talk will end automatically.`,
      'n_ai_speaking.aI_intro.msg4',
      `发话次数最多为20次(约20分钟)
发话20次之后将自动结束对话`,
      `發話次數最多為20次(約20分鐘)
發話20次之後將自動結束對話`,
      `1回の会話あたり最大20ターン（約20分）できます。20ターンを超えると自動的に終了します。`,
      ``,
    ],
    msg5: [
      <>
        대화를 중간에 중단한 경우, 대화 시작 시간으로부터 <span className="font-bold">60분 이내</span>에 돌아와서
        이어나갈 수 있어요.
      </>,
      `If you need to stop the talk session midway, you can come back and pick up where you left off within 60 mins from the talk start time.`,
      'n_ai_speaking.aI_intro.msg5',
      `若于对话途中中断对话，
在对话开始后60分钟内都能继续对话`,
      `若於對話途中中斷對話，
在對話開始後60分鐘內都能繼續對話`,
      `会話を中断した場合は、開始時間から60分以内であれば会話を続けることができます。`,
      ``,
    ],
    msg6: [
      <>
        대화를 <span className="font-bold">10턴 이상(약 10분)</span> 진행하면 AI 분석을 받을 수 있어요.
      </>,
      `AI analysis is available for talks with at least 10 turns (about 10 mins).`,
      'n_ai_speaking.aI_intro.msg6',
      `发话至少10次以上(约10分钟）才能获得AI分析报告`,
      `發話至少10次以上(約10分鐘）才能獲得AI分析報告`,
      `AI Analysisは最低10ターン（約10分）のトークから利用できます。`,
      ``,
    ],
    check_item: [
      `다시 보지 않기`,
      `Don't show this again`,
      'n_ai_speaking.aI_intro.check_item',
      `不再显示`,
      `不再顯示`,
      `次回以降表示しない`,
      ``,
    ],
    btn1: [`대화 시작하기`, `Start talking`, 'n_ai_speaking.aI_intro.btn1', `开始对话`, `開始對話`, `会話を始める`, ``],
  },
  ai_session: {
    ai_analysis: [
      `AI 분석`,
      `AI Analysis`,
      'n_ai_speaking.ai_session.ai_analysis',
      `AI分析`,
      `AI分析`,
      `AI Analysis`,
      ``,
    ],
    btn1: [`한글로 번역`, `Translate in Korean`, 'n_ai_speaking.ai_session.btn1', ``, ``, ``, ``],
    btn2: [`한글 번역 숨기기`, `Hide Korean`, 'n_ai_speaking.ai_session.btn2', ``, ``, ``, ``],
    btn3: [
      `예시 답변 보기`,
      `View sample`,
      'n_ai_speaking.ai_session.btn3',
      `看范例回答`,
      `看範例回答`,
      `回答例を見る`,
      ``,
    ],
    btn4: [
      `예시 답변 숨기기`,
      `Hide sample`,
      'n_ai_speaking.ai_session.btn4',
      `隐藏范例回答`,
      `隱藏範例回答`,
      `回答例を隠す`,
      ``,
    ],
    btn5: [`답변 완료`, `Done`, 'n_ai_speaking.ai_session.btn5', `完成`, `完成`, `回答完了`, ``],
    btn6: [`예시 답변`, `Sample`, 'n_ai_speaking.ai_session.btn6', `回答`, `回答`, `回答例`, ``],
    msg1: [
      `마이크를 눌러 녹음을 진행해주세요.`,
      `Press the microphone to start recording.`,
      'n_ai_speaking.ai_session.msg1',
      `请按录音开始对话`,
      `請按錄音開始對話`,
      `マイクを押して録音してください。`,
      ``,
    ],
    msg2: [
      `생각나는 대로 말해보세요. 실수해도 괜찮아요.`,
      `Say whatever that comes to your mind. It's okay to make mistakes.`,
      'n_ai_speaking.ai_session.msg2',
      `自由的表达自己，不要担心犯错`,
      `自由的表達自己，不要擔心犯錯`,
      `思いつくままに話してください。 間違えても大丈夫です。`,
      ``,
    ],
    msg3: [
      `질문을 바꾸고 싶다면, “Ask me another question” 라고 말해보세요.`,
      `Say "Ask me another question" to change the question.`,
      'n_ai_speaking.ai_session.msg3',
      `如果想要更换问题，请说 “Ask me another question”`,
      `如果想要更換問題，請說 “Ask me another question”`,
      `質問を変えるには「Ask me another question」と言ってください`,
      ``,
    ],
    msg4: [
      `질문의 난이도는 어떤가요?`,
      `How is the question's difficulty level?`,
      'n_ai_speaking.ai_session.msg4',
      `问题的难易度如何呢？`,
      `問題的難易度如何呢？`,
      `質問の難易度はいかがでしょうか？`,
      ``,
    ],
    ans1: [`어려워요`, `Too hard`, 'n_ai_speaking.ai_session.ans1', `太难了`, `太難了`, `難しすぎる`, ``],
    ans2: [`쉬워요`, `Too easy`, 'n_ai_speaking.ai_session.ans2', `太简单了`, `太簡單了`, `簡単すぎる`, ``],
    ans3: [`적절해요`, `Just right`, 'n_ai_speaking.ai_session.ans3', `刚刚好`, `剛剛好`, `ちょうど良い`, ``],
    msg5: [
      `질문 난이도는 [대화 설정]에서 수정할 수 있어요.`,
      `You can adjust the question difficulty level by going to [Talk Settings].`,
      'n_ai_speaking.ai_session.msg5',
      `问题难易度可在[对话设定]中变更`,
      `問題難易度可在[對話設定]中變更`,
      `[会話の設定]から、質問の難易度を変更できます。`,
      ``,
    ],
    btn_end: [
      `대화 마무리`,
      `End talk`,
      'n_ai_speaking.ai_session.btn_end',
      `结束对话`,
      `結束對話`,
      `会話を終了する`,
      ``,
    ],
    msg6: [
      `대화를 요약하고 있어요. 잠시만 기다려주세요!`,
      `The talk is being summarized. Please wait a moment!`,
      'n_ai_speaking.ai_session.msg6',
      `正在进行对话重点整理，请稍等`,
      `正在進行對話重點整理，請稍等`,
      `会話を要約中です。少々お待ちください。`,
      ``,
    ],
    msg7: [
      `대화가 마무리되었습니다.`,
      `The talk has ended.`,
      'n_ai_speaking.ai_session.msg7',
      `对话已结束`,
      `對話已結束`,
      `会話は終了しました`,
      ``,
    ],
    btn_done: [
      `대화 완료`,
      `End the talk`,
      'n_ai_speaking.ai_session.btn_done',
      `结束对话`,
      `結束對話`,
      `会話を終了する`,
      ``,
    ],
    msg8: [
      `최대 턴 수를 모두 채워서 세션이 마무리되었습니다.`,
      `The talk has reached the maximum number of turns.`,
      'n_ai_speaking.ai_session.msg8',
      `发话次数已达最高上限，对话已结束`,
      `發話次數已達最高上限，對話已結束`,
      `会話はターンの上限数に達しました`,
      ``,
    ],
    msg9: [
      `AI 분석이 이제 가능해요.`,
      `Enough turns have been fulfilled for AI analysis.`,
      'n_ai_speaking.ai_session.msg9',
      `已经可以进行AI分析`,
      `已經可以進行AI分析`,
      `AI Analysisに必用なターン数を満たしました`,
      ``,
    ],
    msg9_b2b: [
      `이제 AI 분석이 가능해요. AI 분석이 있는 대화만 출석이 인정됩니다.`,
      `AI analysis is now available. Only talks with AI analysis will be counted for attendance.`,
      'n_ai_speaking.ai_session.msg9_b2b',
      `AI analysis is now available. Only talks with AI analysis will be counted for attendance.`,
      `AI analysis is now available. Only talks with AI analysis will be counted for attendance.`,
      `AI analysis is now available. Only talks with AI analysis will be counted for attendance.`,
      ``,
    ],
    setting_txt1: [
      `질문 난이도`,
      `Question difficulty`,
      'n_ai_speaking.ai_session.setting_txt1',
      `问题难易度`,
      `問題難易度`,
      `質問の難易度`,
      ``,
    ],
    setting_txt2: [`재생 속도`, `Speed`, 'n_ai_speaking.ai_session.setting_txt2', `速度`, `速度`, `速さ`, ``],
    setting_txt3: [`목소리`, `Voice`, 'n_ai_speaking.ai_session.setting_txt3', `声音`, `聲音`, `声`, ``],
    setting_txt4: [`여자`, `Female`, 'n_ai_speaking.ai_session.setting_txt4', `女性`, `女性`, `女性`, ``],
    setting_txt5: [`남자`, `Male`, 'n_ai_speaking.ai_session.setting_txt5', `男性`, `男性`, `男性`, ``],
    setting_txt6: [`액센트`, `Accent`, 'n_ai_speaking.ai_session.setting_txt6', `口音`, `口音`, `アクセント`, ``],
    setting_txt7: [
      `미국식`,
      `American`,
      'n_ai_speaking.ai_session.setting_txt7',
      `美式英文`,
      `美式英文`,
      `アメリカ`,
      ``,
    ],
    setting_txt8: [
      `영국식`,
      `British`,
      'n_ai_speaking.ai_session.setting_txt8',
      `英式英文`,
      `英式英文`,
      `イギリス`,
      ``,
    ],
    setting_txt9: [
      `글자 크기`,
      `Font size`,
      'n_ai_speaking.ai_session.setting_txt9',
      `字体大小`,
      `字體大小`,
      `フォントサイズ`,
      ``,
    ],
    setting_txt10: [`작게`, `Small`, 'n_ai_speaking.ai_session.setting_txt10', `小`, `小`, `小さい`, ``],
    setting_txt11: [`기본`, `Default`, 'n_ai_speaking.ai_session.setting_txt11', `预设`, `預設`, `デフォルト`, ``],
    setting_txt12: [`크게`, `Large`, 'n_ai_speaking.ai_session.setting_txt12', `大`, `大`, `大きい`, ``],
    setting_txt13: [`한글 번역`, `Korean translation`, 'n_ai_speaking.ai_session.setting_txt13', `na`, `na`, `na`, ``],
    setting_txt14: [`적용`, `Apply`, 'n_ai_speaking.ai_session.setting_txt14', `使用`, `使用`, `適用する`, ``],
    msg10: [
      `‘Advanced’로 난이도를 조정 했어요. 질문 난이도는 우측 상단 [대화 설정]에서 바꿀 수 있어요.`,
      `Level set to ‘Advanced.’ To adjust the question difficulty level, go to [Talk Settings] at the top right.`,
      'n_ai_speaking.ai_session.msg10',
      `已调整为‘高级(Advanced)’难度。问题难度可在右上角[对话设定]中更改。`,
      `已調整為‘高級(Advanced)’難度。問題難度可在右上角[對話設定]中更改。`,
      `難易度を‘Advanced’に設定しました。質問の難易度は右上の[会話設定]で変更できます。`,
      ``,
    ],
    msg11: [
      `‘Basic’로 난이도를 조정 했어요. 질문 난이도는 우측 상단 [대화 설정]에서 바꿀 수 있어요.`,
      `Level set to ‘Basic.’ To adjust the question difficulty level, go to [Talk Settings] at the top right.`,
      'n_ai_speaking.ai_session.msg11',
      `已调整为‘初级(Basic)’难度。问题难度可在右上角[对话设定]中更改。`,
      `已調整為‘初級(Basic)’難度。問題難度可在右上角[對話設定]中更改。`,
      `難易度を‘Basic’に設定しました。質問の難易度は右上の[会話設定]で変更できます。`,
      ``,
    ],
    msg12: [
      `지금의 난이도는 ‘Intermediate’에요. 질문 난이도는 우측 상단 [대화 설정]에서 바꿀 수 있어요.`,
      `The current level is ‘Intermediate.’ To adjust the question difficulty level, go to [Talk Settings] at the top right.`,
      'n_ai_speaking.ai_session.msg12',
      `当前难度为‘中级(Intermediate)’。问题难度可在右上角[对话设置]中更改。`,
      `當前難度為‘中級(Intermediate)’。問題難度可在右上角[對話設置]中更改。`,
      `現在の難易度は‘Intermediate’です。質問の難易度は右上の[会話設定]で変更できます。`,
      ``,
    ],
    setting_txt15: [
      `대화 설정`,
      `Talk Settings`,
      'n_ai_speaking.ai_session.setting_txt15',
      `对话设定`,
      `對話設定`,
      `会話設定`,
      ``,
    ],
    setting_txt16: [
      `Basic`,
      `Basic`,
      'n_ai_speaking.ai_session.setting_txt16',
      `初级(Basic)`,
      `初級(Basic)`,
      `Basic`,
      ``,
    ],
    setting_txt17: [
      `Intermediate`,
      `Intermediate`,
      'n_ai_speaking.ai_session.setting_txt17',
      `中级(Intermediate)`,
      `中級(Intermediate)`,
      `Intermediate`,
      ``,
    ],
    setting_txt18: [
      `Advanced`,
      `Advanced`,
      'n_ai_speaking.ai_session.setting_txt18',
      `高级(Advanced)`,
      `高級(Advanced)`,
      `Advanced`,
      ``,
    ],
    setting_txt19: [
      `AI 분석의 CAFP 종합 레벨 4~5 수준이에요.`,
      `Equivalent to AI analysis's CAFP levels 4-5.`,
      'n_ai_speaking.ai_session.setting_txt19',
      `AI分析的CAFP结果为等级4-5`,
      `AI分析的CAFP結果為等級4-5`,
      `AI AnalysisによるCAFP総合レベルは4〜5です。`,
      ``,
    ],
    setting_txt20: [
      `AI 분석의 CAFP 종합 레벨 6~7 수준이에요.`,
      `Equivalent to AI analysis's CAFP levels 6-7.`,
      'n_ai_speaking.ai_session.setting_txt20',
      `AI分析的CAFP结果为等级6-7`,
      `AI分析的CAFP結果為等級6-7`,
      `AI AnalysisによるCAFP総合レベルは6〜7です。`,
      ``,
    ],
    setting_txt21: [
      `AI 분석의 CAFP 종합 레벨 8~9 수준이에요.`,
      `Equivalent to AI analysis's CAFP levels 8-9.`,
      'n_ai_speaking.ai_session.setting_txt21',
      `AI分析的CAFP结果为等级8-9`,
      `AI分析的CAFP結果為等級8-9`,
      `AI AnalysisによるCAFP総合レベルは8〜9です。`,
      ``,
    ],
    setting_txt22: [
      `AI 스피킹 파트너가 질문에 사용하는 어휘의 난이도를 선택해 주세요.`,
      `Set the level of vocabulary for AI Speaking Partner's questions.`,
      'n_ai_speaking.ai_session.setting_txt22',
      `请选择AI口语伙伴对话时使用的词彙难易度`,
      `請選擇AI口說夥伴對話時使用的詞彙難易度`,
      `AI Speaking Partnerの質問で使用する語彙の難易度を選んでください。`,
      ``,
    ],
    setting_txt23: [
      `AI 스피킹 파트너 질문 재생 속도를 선택해 주세요.`,
      `Set the playback speed for AI Speaking Partner's questions.`,
      'n_ai_speaking.ai_session.setting_txt23',
      `请选择AI口语伙伴问题的播放速度`,
      `請選擇AI口說夥伴問題的播放速度`,
      `AI Speaking Partnerの質問の再生速度を選んでください。`,
      ``,
    ],
    setting_txt24: [
      `AI 스피킹 파트너랑 먼저 대화해보세요`,
      `Have a talk with AI Speaking Partner first.`,
      'n_ai_speaking.ai_session.setting_txt24',
      `请先尝试与AI口语伙伴对话`,
      `請先嘗試與AI口說夥伴對話`,
      `まずはAI Speaking Partnerと話してみてください。`,
      ``,
    ],
  },
  ai_complete: {
    txt1: [
      `대화 목표 달성`,
      `Minimum turns fulfilled!`,
      'n_ai_speaking.ai_complete.txt1',
      `完成挑战!`,
      `完成挑戰!`,
      `必用ターン数を達成`,
      ``,
    ],
    title: [
      <>
        AI 스피킹 파트너와
        <br /> 대화를 완료했어요.
      </>,
      `Your talk with AI Speaking Partner has been completed.`,
      'n_ai_speaking.ai_complete.title',
      <>
        完成和AI口语伙伴
        <br />
        进行口说练习
      </>,
      <>
        完成和AI口說夥伴
        <br />
        進行口說練習
      </>,
      `AI Speaking Partnerとの会話は完了しました`,
      ``,
    ],
    label1: [
      `이번 대화 턴 수`,
      `Total turns taken`,
      'n_ai_speaking.ai_complete.label1',
      `此次对话发言次数`,
      `此次對話發言次數`,
      `ターン数の合計`,
      ``,
    ],
    label2: [
      `이번주 대화`,
      `Talks this week`,
      'n_ai_speaking.ai_complete.label2',
      `本週对话次数`,
      `本週對話次數`,
      `今週の会話`,
      ``,
    ],
    msg1: [
      `AI 분석이 도착하면 알림으로 알려 드릴게요.`,
      `We'll alert you when the AI analysis is ready.`,
      'n_ai_speaking.ai_complete.msg1',
      `AI分析报告结束后将通知您`,
      `AI分析報告結束後將通知您`,
      `AI Analysisが完了したらお知らせします`,
      ``,
    ],
    msg2: [
      `대화를 마친 후 1시간 이내에 제공돼요.`,
      `It will be ready within one hour.`,
      'n_ai_speaking.ai_complete.msg2',
      `对话结束后一小时内提供分析结果`,
      `對話結束後一小時內提供分析結果`,
      `1時間以内に準備完了します`,
      ``,
    ],
    btn: [
      `지난 대화 목록`,
      `View talk list`,
      'n_ai_speaking.ai_complete.btn',
      `查看过去对话纪录`,
      `查看過去對話紀錄`,
      `すべての会話を見る`,
      ``,
    ],
  },
  feedback: {
    txt1: [
      `피드백을 남겨주세요`,
      `Give us your feedback.`,
      'n_ai_speaking.feedback.txt1',
      `请留下您的建议`,
      `請留下您的建議`,
      `フィードバックを残す`,
      ``,
    ],
    q1: [
      `AI 스피킹 파트너와 대화는 어땠나요?`,
      `How was your talk with AI Speaking Partner?`,
      'n_ai_speaking.feedback.q1',
      `觉得和AI伙伴的对话如何呢?`,
      `覺得和AI夥伴的對話如何呢?`,
      `AI Speaking Partnerとの会話はいかがでしたか？`,
      ``,
    ],
    placehoder0: [
      `대화 만족도를 평가해주세요`,
      `Please rate your satisfaction with the talk.`,
      'n_ai_speaking.feedback.placehoder0',
      `您对次对话的满意度`,
      `您對次對話的滿意度`,
      `レッスンの満足度を評価してください`,
      ``,
    ],
    placehoder1: [
      `별로 였어요`,
      `It was disappointing.`,
      'n_ai_speaking.feedback.placehoder1',
      `非常不满意`,
      `非常不滿意`,
      `大変不満`,
      ``,
    ],
    placehoder2: [
      `아쉬웠어요`,
      `It could've been better.`,
      'n_ai_speaking.feedback.placehoder2',
      `不太满意`,
      `不太滿意`,
      `不満`,
      ``,
    ],
    placehoder3: [`보통이에요`, `It was okay.`, 'n_ai_speaking.feedback.placehoder3', `还可以`, `還可以`, `普通`, ``],
    placehoder4: [`좋았어요`, `It was good.`, 'n_ai_speaking.feedback.placehoder4', `满意`, `滿意`, `満足`, ``],
    placehoder5: [
      `최고에요!`,
      `It was great!`,
      'n_ai_speaking.feedback.placehoder5',
      `非常满意`,
      `非常滿意`,
      `非常に満足`,
      ``,
    ],
    q2: [
      `자세한 후기를 남겨주세요. (선택)`,
      `Please leave a detailed review. (optional)`,
      'n_ai_speaking.feedback.q2',
      `请留下您的评价(选择)`,
      `請留下您的評價(選擇)`,
      `詳細なご感想をお聞かせください。 (任意)`,
      ``,
    ],
    placehoder6: [
      `AI 스피킹 파트너를 사용한 경험에 대해 솔직하게 알려주세요.`,
      `Tell us about your experience with AI Speaking Partner.`,
      'n_ai_speaking.feedback.placehoder6',
      `请告诉我们您的使用心得`,
      `請告訴我們您的使用心得`,
      `AI Spealomg Partnerを使った経験について率直に教えてください。`,
      ``,
    ],
    q3: [
      `질문의 난이도는 어땠나요?`,
      `How difficult were the questions for you?`,
      'n_ai_speaking.feedback.q3',
      `问题的难易度如何?`,
      `問題的難易度如何?`,
      `質問の難易度はどうでしたか？`,
      ``,
    ],
    q4: [
      `대화의 길이는 어땠나요?`,
      `How was the length of the talk?`,
      'n_ai_speaking.feedback.q4',
      `对话的长度如何?`,
      `對話的長度如何?`,
      `会話の長さはどうでしたか？`,
      ``,
    ],
    placehoder7: [
      `너무 쉬웠어요`,
      `It was too easy.`,
      'n_ai_speaking.feedback.placehoder7',
      `太简单`,
      `太簡單`,
      `とても簡単だった`,
      ``,
    ],
    placehoder8: [
      `너무 어려웠어요`,
      `It was too difficult.`,
      'n_ai_speaking.feedback.placehoder8',
      `太困难`,
      `太困難`,
      `難しすぎた`,
      ``,
    ],
    placehoder9: [
      `너무 짧았어요`,
      `It was too short.`,
      'n_ai_speaking.feedback.placehoder9',
      `太短`,
      `太短`,
      `短すぎた`,
      ``,
    ],
    placehoder10: [
      `너무 길었어요`,
      `It was too long.`,
      'n_ai_speaking.feedback.placehoder10',
      `太长`,
      `太長`,
      `長すぎた`,
      ``,
    ],
    placehoder11: [
      `적절했어요`,
      `It was just right.`,
      'n_ai_speaking.feedback.placehoder11',
      `刚刚好`,
      `剛剛好`,
      `適切だった`,
      ``,
    ],
    btn: [`평가하기`, `Submit`, 'n_ai_speaking.feedback.btn', `进行评价`, `進行評價`, `提出する`, ``],
    toast: [
      `피드백이 제출되었습니다.`,
      `Submitted.`,
      'n_ai_speaking.feedback.toast',
      `已提交评价`,
      `已提交評價`,
      `提出されました`,
      ``,
    ],
  },
  ai_analysis: {
    btn: [`대화 목록`, `Talk list`, 'n_ai_speaking.ai_analysis.btn', `对话目录`, `對話目錄`, `すべての会話`, ``],
  },
  credit_name_trial: {
    label: [
      `2회 AI 스피킹 파트너 이용권`,
      `2 AI Speaking Partner Ticket`,
      'n_ai_speaking.credit_name_trial.label',
      `2 AI Speaking Partner Ticket`,
      `2 AI Speaking Partner Ticket`,
      `2 AI Speaking Partner Ticket`,
      ``,
    ],
  },
  app_download: {
    btn: [
      `링글 앱 다운받기`,
      `Download Ringle app`,
      'n_ai_speaking.app_download.btn',
      `下载Ringle APP`,
      `下載Ringle APP`,
      `Ringleアプリをダウンロードする`,
      ``,
    ],
  },
  app_download_popup: {
    title1: [
      `링글 AI 스피킹 파트너는 링글 앱에서만 사용 가능해요.`,
      `Ringle AI Speaking Partner is only available on the Ringle app.`,
      'n_ai_speaking.app_download_popup.title1',
      `Ringle AI口语伙伴只能在APP上使用`,
      `Ringle AI口說夥伴只能在APP上使用`,
      `Ringle AI Speaking Partner はRingleアプリでのみ使用できます。`,
      ``,
    ],
    body1: [
      <>
        AI 스피킹 파트너와 수업 내용을 예습하고 영어 발화량을 늘려보세요.
        <br />
        대화를 마친 후 AI 분석까지 받을 수 있어요.
      </>,
      `Prep for lessons and get extra practice talking in English with Ringle AI Speaking Partner. You can even get the talks assessed by Ringle's AI Analysis.`,
      'n_ai_speaking.app_download_popup.body1',
      `花10分钟和AI口说伙伴一起预习课程，增加口说练习机会，
练习之后还能获得AI分析报告`,
      `花10分鐘和AI口說夥伴一起預習課程，增加口說練習機會，
練習之後還能獲得AI分析報告`,
      `Ringle AI Speaking Partnerでレッスンの予習や英会話の練習ができます。さらに、その会話内容をRingleのAIが分析します。`,
      ``,
    ],
  },
  info_trial: {
    msg1: [
      `AI 스피킹 파트너 이용권 이용 기간은 1회 수업 신청일 + 14일이에요.`,
      `AI Speaking Partner ticket's validity period ends 14 days after the lesson date.`,
      'n_ai_speaking.info_trial.msg1',
      `AI Speaking Partner ticket's validity period ends 14 days after the lesson date.`,
      `AI Speaking Partner ticket's validity period ends 14 days after the lesson date.`,
      `AI Speaking Partner ticket's validity period ends 14 days after the lesson date.`,
      ``,
    ],
    msg2: [
      `링글 AI 스피킹 파트너와 대화를 나눠보세요.`,
      `Start talking with Ringle AI Speaking Partner.`,
      'n_ai_speaking.info_trial.msg2',
      `Start talking with Ringle AI Speaking Partner.`,
      `Start talking with Ringle AI Speaking Partner.`,
      `Start talking with Ringle AI Speaking Partner.`,
      ``,
    ],
    msg3: [
      `[수업] > [예정된 수업] > [AI 스피킹 파트너와 대화 시작]에서 링글 AI 스피킹 파트너와 10-20분 대화로 수업 내용을 예습하고 영어 발화량을 늘려보세요.`,
      `Prep for lessons and get extra practice talking in English with Ringle AI Speaking Partner at [Lessons] > [Upcoming Lesson] > [Talk with AI Speaking Partner].`,
      'n_ai_speaking.info_trial.msg3',
      `Prep for lessons and get extra practice talking in English with Ringle AI Speaking Partner at [Lessons] > [Upcoming Lesson] > [Talk with AI Speaking Partner].`,
      `Prep for lessons and get extra practice talking in English with Ringle AI Speaking Partner at [Lessons] > [Upcoming Lesson] > [Talk with AI Speaking Partner].`,
      `Prep for lessons and get extra practice talking in English with Ringle AI Speaking Partner at [Lessons] > [Upcoming Lesson] > [Talk with AI Speaking Partner].`,
      ``,
    ],
    msg4: [
      `대화를 마친 후 AI 분석까지 받을 수 있어요.`,
      `You can even get the talks assessed by Ringle's AI Analysis.`,
      'n_ai_speaking.info_trial.msg4',
      `You can even get the talks assessed by Ringle's AI Analysis.`,
      `You can even get the talks assessed by Ringle's AI Analysis.`,
      `You can even get the talks assessed by Ringle's AI Analysis.`,
      ``,
    ],
  },
  material: {
    popup_title: [
      <>
        더 다양한 교재로 AI 스피킹 파트너와
        <br />
        대화를 나눠보세요!
      </>,
      <>
        Practice talking on diverse topics
        <br />
        with AI Speaking Partner!
      </>,
      'n_ai_speaking.material.popup_title',
      <>
        用更多的教材与AI口语伙伴
        <br />
        进行对话！
      </>,
      <>
        用更多的教材與AI口說夥伴
        <br />
        進行對話！
      </>,
      <>
        AI Speaking Partnerと
        <br />
        色々な教材で会話しましょう！
      </>,
      ``,
    ],
    popup_body: [
      <>
        최근 수업에서 다룬 교재나 링글 인기 교재로
        <br />
        AI 스피킹 파트너와 대화를 시작해 보세요.
      </>,
      <>
        You can choose any Ringle material
        <br />
        and have a 1:1 talk with AI Speaking Partner.
      </>,
      'n_ai_speaking.material.popup_body',
      <>
        用最近课程使用过或是其他人气教材
        <br />
        与AI口语伙伴开始对话。
      </>,
      <>
        用最近課程使用過或是其他人氣教材
        <br />
        與AI口說夥伴開始對話。
      </>,
      <>
        最新教材や人気教材を使って
        <br />
        AI Speaking Partnerと会話しましょう
      </>,
      ``,
    ],
    detail: [
      <>
        최근 수업에서 다룬 교재나 링글 인기 교재로
        <br />
        AI 스피킹 파트너와 대화를 시작해 보세요.
      </>,
      <>
        You can choose any Ringle material
        <br />
        and have a 1:1 talk with AI Speaking Partner.
      </>,
      'n_ai_speaking.material.detail',
      <>
        用最近课程使用过或是其他人气教材
        <br />
        与AI口语伙伴对话。
      </>,
      <>
        用最近課程使用過或是其他人氣教材
        <br />
        與AI口說夥伴對話。
      </>,
      <>
        最新教材や人気教材を使って
        <br />
        AI Speaking Partnerと会話しましょう
      </>,
      ``,
    ],
    btn_start: [`대화 시작`, `Talk`, 'n_ai_speaking.material.btn_start', `开始对话`, `開始對話`, `会話を始める`, ``],
    btn_book: [
      `수업 예약`,
      `Book a lesson`,
      'n_ai_speaking.material.btn_book',
      `预定课程`,
      `預約課程`,
      `授業を予約する`,
      ``,
    ],
  },
  trial_complete: {
    btn_practice: [
      `AI 스피킹 파트너랑 대화 연습하기`,
      `Practice with AI Speaking Partner`,
      'n_ai_speaking.trial_complete.btn_practice',
      `与AI口语伙伴练习对话`,
      `與AI口說夥伴練習對話`,
      `AI Speaking Partnerと練習をする`,
      ``,
    ],
  },
  noti: {
    msg_title: [
      `알림을 설정해보세요!`,
      `Get reminders!`,
      'n_ai_speaking.noti.msg_title',
      `请设置通知！`,
      `請設定通知！`,
      `リマインダーを設定しましょう`,
      ``,
    ],
    msg_body: [
      `매일 꾸준히 연습해야 영어가 늘어요. 잊지 않도록 설정한 시간에 알림을 보내드릴게요.`,
      `Practice makes perfect! Set up regular reminders to practice English.`,
      'n_ai_speaking.noti.msg_body',
      `每天学习英文能更快进步，为了提醒上课，我们会在
您设置的通知时间发送提醒`,
      `每天學習英文能更快進步，為了提醒上課，我們會在
您設定的通知時間發送提醒`,
      `継続練習が英語が上達のコツです。定期的なリマインダーを設定して、英語の練習をしましょう。`,
      ``,
    ],
    msg_btn: [
      `알림 설정하기`,
      `Go to Settings`,
      'n_ai_speaking.noti.msg_btn',
      `通知设置`,
      `通知設定`,
      `設定を変える`,
      ``,
    ],
    page_title: [
      `AI 스피킹 파트너`,
      `AI Speaking Partner`,
      'n_ai_speaking.noti.page_title',
      `AI口语伙伴`,
      `AI口說夥伴`,
      `AI Speaking Partner`,
      ``,
    ],
    apppush1_title: [`리마인더`, `Reminders`, 'n_ai_speaking.noti.apppush1_title', `通知`, `通知`, `リマインダー`, ``],
    apppush1_detail: [
      `설정한 시간에 AI 스피킹 파트너 리마인더 알림을 앱 푸시로 받을 수 있습니다.`,
      `Get push notifications reminding you to practice with AI Speaking Partner at the times you set.`,
      'n_ai_speaking.noti.apppush1_detail',
      `我们会在您设置的时间用APP提醒您和AI口语伙伴练习`,
      `我們會在您設定的時間用APP提醒您和AI口說夥伴練習`,
      `設定した時間にAI Speaking Partnerと練習するよう、プッシュ通知でお知らせします。`,
      ``,
    ],
    apppush2_title: [
      `AI 분석 리포트`,
      `AI analysis report`,
      'n_ai_speaking.noti.apppush2_title',
      `AI分析报告`,
      `AI分析報告`,
      `AI Analysis Report`,
      ``,
    ],
    apppush2_detail: [
      `대화의 AI 분석이 도착하면 알림을 받을 수 있습니다.`,
      `Get alerted when the AI analysis report for the talk is ready.`,
      'n_ai_speaking.noti.apppush2_detail',
      `AI分析报告结果出来时可接收通知`,
      `AI分析報告結果出來時可接收通知`,
      `AI Analysis Reportが完成したら、アラートでお知らせします。`,
      ``,
    ],
    time_title: [
      `알림 시간`,
      `Reminder schedule`,
      'n_ai_speaking.noti.time_title',
      `通知时间`,
      `通知時間`,
      `リマインダーの通知時刻`,
      ``,
    ],
    time_body: [
      `사용 가능한 이용권이 없는 경우 앱 푸시가 발송되지 않습니다.`,
      `Push notifications will not be sent when you have no AI Speaking Partner tickets.`,
      'n_ai_speaking.noti.time_body',
      `如果没有可使用的练习券将不会发送通知`,
      `如果沒有可使用的練習券將不會發送通知`,
      `AI Speaking Partnerのチケットがない場合、プッシュ通知は送信されません。`,
      ``,
    ],
    appush: [`앱 푸시`, `Push notifications`, 'n_ai_speaking.noti.appush', `通知提醒`, `通知提醒`, `プッシュ通知`, ``],
    msg_body2: [
      `설정한 시간에 AI 스피킹 파트너 리마인더 알림과 AI 분석 리포트 도착 알림을 앱 푸시로 받을 수 있습니다.`,
      `Get push notifications for AI Speaking Partner reminders and alerts at the times you set.`,
      'n_ai_speaking.noti.msg_body2',
      `于您设置的通知时间提醒练习和查看AI分析报告`,
      `於您設定的通知時間提醒練習和查看AI分析報告`,
      `AI Speaking Partnerのリマインダーや、AI Analysis Reportのアラートを設定した時間にプッシュ通知でお知らせします。`,
      ``,
    ],
    time_mon: [`월`, `M`, 'n_ai_speaking.noti.time_mon', `M`, `M`, `M`, ``],
    time_tue: [`화`, `T`, 'n_ai_speaking.noti.time_tue', `T`, `T`, `T`, ``],
    time_wed: [`수`, `W`, 'n_ai_speaking.noti.time_wed', `W`, `W`, `W`, ``],
    time_thu: [`목`, `T`, 'n_ai_speaking.noti.time_thu', `T`, `T`, `T`, ``],
    time_fri: [`금`, `F`, 'n_ai_speaking.noti.time_fri', `F`, `F`, `F`, ``],
    time_sat: [`토`, `S`, 'n_ai_speaking.noti.time_sat', `S`, `S`, `S`, ``],
    time_sun: [`일`, `S`, 'n_ai_speaking.noti.time_sun', `S`, `S`, `S`, ``],
    time_am: [`오후`, `AM`, 'n_ai_speaking.noti.time_am', `AM`, `AM`, `AM`, ``],
    time_pm: [`오전`, `PM`, 'n_ai_speaking.noti.time_pm', `PM`, `PM`, `PM`, ``],
  },
  b2b_wordcount: {
    wds: [`단어`, `Word(s)`, 'n_ai_speaking.b2b_wordcount.wds', `单词`, `單字`, `ワード`, ``],
    intro_detail: (min_word) => [
      `대화를 10턴 이상(약 10분) 진행하고 ${min_word}단어 이상 말하면 진행하면 출석이 인정되고 AI 분석을 받을 수 있어요.`,
      `Talk for at least 10 turns (about 10 mins) and ${min_word} words for the talk to count toward attendance and receive AI analysis.`,
      'n_ai_speaking.b2b_wordcount.intro_detail',
      `发话10次以上(约10分钟）并使用${min_word}个单词以上就能视为
出席，并能获得AI分析报告`,
      `發話10次以上(約10分鐘）並運用${min_word}個單字以上就能視為出席，並能獲得AI分析報告`,
      `少なくとも10ターン(約10分)・${min_word}ワードを話すと出席にカウントされ、AI Analysisを受けることができます。`,
      ``,
    ],
    popup_body1: (min_word) => [
      `대화를 10턴 이상(약 10분) 진행하고 ${min_word}단어 이상 말해야 출석이 인정됩니다.`,
      `The talk needs to be at least 10 turns (about 10 mins) and ${min_word} words long to count toward attendance.`,
      'n_ai_speaking.b2b_wordcount.popup_body1',
      `发话10次以上(约10分钟）并使用${min_word}个单词以上就能视为
出席`,
      `發話10次以上(約10分鐘）並使用${min_word}個單字以上就能視為出席`,
      `少なくとも10ターン(約10分)・${min_word}ワードを話すと出席にカウントされます。`,
      ``,
    ],
    popup_body2: (min_word, left_min) => [
      `출석 인정과 AI 분석을 받으려면 최소 10번의 턴을 진행하고 ${min_word}단어 이상 말해야 해요. ${left_min}분 이내에 돌아와서 대화를 이어 나갈 수 있어요.`,
      `At least 10 turns and ${min_word} words are required for the talk to count toward attendance and receive AI analysis. You can come back and resume the talk within ${left_min} mins.`,
      'n_ai_speaking.b2b_wordcount.popup_body2',
      `如要列入出席并获得AI分析报告，请至少发话10次并使用${min_word}个单词以上。
对话开始后的${left_min}分钟内都可以回来完成对话。`,
      `如要列入出席並獲得AI分析報告，請至少發話10次並使用${min_word}個單字以上。
對話開始後的${left_min}分鐘內都可以回來完成對話。`,
      `会話が出席にカウントされ、AI Analysisを受けるには、少なくとも10ターンと${min_word}ワードが必要です。${left_min}分以内であれば、トークを再開することができます。`,
      ``,
    ],
    progress_txt1: [
      `달성 기준`,
      `Fulfillment status`,
      'n_ai_speaking.b2b_wordcount.progress_txt1',
      `达标条件`,
      `達標條件`,
      `達成状況`,
      ``,
    ],
    progress_txt2: [`턴`, `Turn(s)`, 'n_ai_speaking.b2b_wordcount.progress_txt2', `次`, `次`, `ターン`, ``],
    msg_1: (word, min_word) => [
      `지금까지 말한 단어 수는 ${word} 단어입니다. 출석 인정과 AI 분석을 받으려면 ${min_word}단어 이상 말해야 해요.`,
      `You have spoken ${word} words so far. Reach ${min_word} words for attendance count and AI analysis.`,
      'n_ai_speaking.b2b_wordcount.msg_1',
      `现在为止使用的单词数 ${word}，如果要计算出席及获得AI分析报告请至少讲${min_word}字`,
      `現在為止使用的單字數 ${word}，如果要計算出席及獲得AI分析報告請至少講${min_word}字`,
      `あなたはこれまでに${word}ワード話しました。出席カウントとAI Analysisのために${min_word}ワードを達成してください。`,
      ``,
    ],
    msg_2: (min_word) => [
      `최대 턴 수를 모두 채워서 세션이 마무리되었습니다. 최소 단어 수(${min_word} 단어)를 달성하지 못해  출석 인정이 되지 않고 AI 분석을 제공하지 않습니다.`,
      `The talk has been concluded as you have reached the max. number of turns. However, as you have not fulfilled the required word count (${min_word} words), attendance count and AI analysis are not given for this talk.`,
      'n_ai_speaking.b2b_wordcount.msg_2',
      `已经达到最低发话次数要求，对话已结束。
因使用单词未达${min_word}字，将不计算出席并不提供AI分析报告。`,
      `已經達到最低發話次數要求，對話已結束。
因使用單字未達${min_word}字，將不計算出席並不提供AI分析報告。`,
      `最大ターン数に達したので、トークを終了しました。ただし、必要ワード数（${min_word}ワード）を満たしていないため、このトークでは出席カウントとAI Analysisは行われません。`,
      ``,
    ],
    popup_title: [
      `출석 인정 기준`,
      `Attendance Criteria`,
      'n_ai_speaking.b2b_wordcount.popup_title',
      `出勤认定标准`,
      `出勤認定標準`,
      `出席認定基準`,
      ``,
    ],
    popup_nolimit: [
      `대화를 10턴 이상(약 10분) 진행해야 출석이 인정됩니다.`,
      `You must have 10 turns (about 10 minutes) to be counted as present.`,
      'n_ai_speaking.b2b_wordcount.popup_nolimit',
      `对话需10轮以上(约10分钟)才算出勤。`,
      `對話需10輪以上(約10分鐘)才算出勤。`,
      `会話は10ターン以上（約10分）で出席が認定されます。`,
      ``,
    ],
  },
  list: {
    credit: [
      `남은 AI 분석 이용권`,
      `AI analysis tickets`,
      'n_ai_speaking.list.credit',
      `AI analysis tickets`,
      `AI analysis tickets`,
      `AI analysis tickets`,
      ``,
    ],
    infinite: [`무제한`, `Unlimited`, 'n_ai_speaking.list.infinite', `Unlimited`, `Unlimited`, `Unlimited`, ``],
    roleplay: [
      `롤플레잉`,
      `Role-playing`,
      'n_ai_speaking.list.roleplay',
      `Role-playing`,
      `Role-playing`,
      `Role-playing`,
      ``,
    ],
    discussion: [
      `디스커션`,
      `Discussion`,
      'n_ai_speaking.list.discussion',
      `Discussion`,
      `Discussion`,
      `Discussion`,
      ``,
    ],
    only_ai: [
      `AI 분석 있는 대화만 보기`,
      `AI-analyzed talks only`,
      'n_ai_speaking.list.only_ai',
      `AI-analyzed talks only`,
      `AI-analyzed talks only`,
      `AI-analyzed talks only`,
      ``,
    ],
    status: [
      `{n}분 후 자동 종료`,
      `Ends in {n} min(s)`,
      'n_ai_speaking.list.status',
      `Ends in {n} min(s)`,
      `Ends in {n} min(s)`,
      `Ends in {n} min(s)`,
      ``,
    ],
    btn1: [
      `AI 분석 요청`,
      `Run AI analysis`,
      'n_ai_speaking.list.btn1',
      `Run AI analysis`,
      `Run AI analysis`,
      `Run AI analysis`,
      ``,
    ],
  },
}
export const n_intro = {
  slides: {
    title1: [
      <>
        당신이 <span className="text-purple-500">꿈꾸던 영어 실력</span>과 <br /> 커리어를 현실로
      </>,
      <>
        <span className="text-purple-500">Unlock your potential</span> <br />
        with English
      </>,
      'n_intro.slides.title1',
      <>
        Unlock your potential <br />
        with English
      </>,
      <>
        Unlock your potential <br />
        with English
      </>,
      <>
        Unlock your potential <br />
        with English
      </>,
      ``,
    ],
    detail1: [
      <>
        원어민과의 스몰토크부터 비즈니스 영어 미팅, <br />
        외국계 취업까지. 링글이면 가능해요.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      'n_intro.slides.detail1',
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      ``,
    ],
    title2: [
      <>
        <span className="text-purple-500">똑똑한 영어 대화 상대</span>, <br /> 달라진 영어 실력
      </>,
      <>
        <span className="text-purple-500">Smart Tutors</span>, <br />
        Enhanced English Skills
      </>,
      'n_intro.slides.title2',
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      ``,
    ],
    detail2: [
      <>
        깊이있는 대화부터 고급 교정까지 가능한 <br />
        명문대 출신 원어민은 오직 링글에만 있어요.{' '}
      </>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      'n_intro.slides.detail2',
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      ``,
    ],
    title3: [
      <>
        다양한 영어 <span className="text-purple-500">대화 주제</span>, <br /> 늘어난 영어 자신감
      </>,
      <>
        <span className="text-purple-500">Diverse Topics</span>, <br /> Boosted Confidence
      </>,
      'n_intro.slides.title3',
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      ``,
    ],
    detail3: [
      <>
        일상 회화부터 비즈니스, 문화, 시사, 경제까지
        <br />
        영어 대화의 폭을 넓혀요.
      </>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      'n_intro.slides.detail3',
      <>Expand your English scope—from daily chats to business, and economics.</>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      ``,
    ],
    title4: [
      <>
        연습은 AI와 가볍게, <br />
        <span className="text-purple-500">실전은 원어민</span>과 밀도있게
      </>,
      <>
        Light AI Practice, <br /> <span className="text-purple-500">Intense Tutor Sessions</span>
      </>,
      'n_intro.slides.title4',
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      ``,
    ],
    detail4: [
      <>
        AI 스피킹 파트너로 스피킹 연습하고
        <br />
        원어민과 1:1 수업으로 실전 감각을 익혀요.
      </>,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      'n_intro.slides.detail4',
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      ``,
    ],
    title5: [
      <>
        화상영어 <span className="text-purple-500">재구매율 1위</span>, <br /> 진짜 실력을 만드는 링글
      </>,
      <>
        <span className="text-purple-500">#1 Repeat Customer Rate</span>, Ringle
      </>,
      'n_intro.slides.title5',
      `#1 Repeat Customer Rate, Ringle`,
      `#1 Repeat Customer Rate, Ringle`,
      `#1 Repeat Customer Rate, Ringle`,
      ``,
    ],
    detail5: [
      <>
        외국계부터 대기업까지. 실전 영어 사용자들이
        <br />
        링글을 다시 찾는데는 이유가 있어요.
      </>,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      'n_intro.slides.detail5',
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      ``,
    ],
    btn_next: [`다음`, `Next`, 'n_intro.slides.btn_next', `Next`, `Next`, `Next`, ``],
    btn_start: [
      `링글 시작하기`,
      `Start Ringle`,
      'n_intro.slides.btn_start',
      `Start Ringle`,
      `Start Ringle`,
      `Start Ringle`,
      ``,
    ],
  },
  slides_ai_tutor: {
    title1: [
      <>
        당신이 <span className="text-purple-500">꿈꾸던 영어 실력</span>과 <br /> 커리어를 현실로
      </>,
      <>
        <span className="text-purple-500">Unlock your potential</span> <br />
        with English
      </>,
      'n_intro.slides_ai_tutor.title1',
      <>
        Unlock your potential <br />
        with English
      </>,
      <>
        Unlock your potential <br />
        with English
      </>,
      <>
        Unlock your potential <br />
        with English
      </>,
      ``,
    ],
    detail1: [
      <>
        원어민과의 스몰토크부터 비즈니스 영어 미팅, <br />
        외국계 취업까지. 링글이면 가능해요.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      'n_intro.slides_ai_tutor.detail1',
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      <>
        From small talk to business meetings,
        <br />
        it’s all possible with Ringle.
      </>,
      ``,
    ],
    title2: [
      <>
        <span className="text-purple-500">똑똑한 영어 대화 상대</span>, <br /> 달라진 영어 실력
      </>,
      <>
        <span className="text-purple-500">Smart Tutors</span>, <br />
        Enhanced English Skills
      </>,
      'n_intro.slides_ai_tutor.title2',
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      <>
        Smart Tutors, <br />
        Enhanced English Skills
      </>,
      ``,
    ],
    detail2: [
      <>
        깊이있는 대화부터 고급 교정까지 가능한 <br />
        명문대 출신 원어민은 오직 링글에만 있어요.{' '}
      </>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      'n_intro.slides_ai_tutor.detail2',
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      <>Experience in-depth conversations with Ivy League tutors only at Ringle.</>,
      ``,
    ],
    title3: [
      <>
        다양한 영어 <span className="text-purple-500">대화 주제</span>, <br /> 늘어난 영어 자신감
      </>,
      <>
        <span className="text-purple-500">Diverse Topics</span>, <br /> Boosted Confidence
      </>,
      'n_intro.slides_ai_tutor.title3',
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      <>
        Diverse Topics, <br /> Boosted Confidence
      </>,
      ``,
    ],
    detail3: [
      <>
        일상 회화부터 비즈니스, 문화, 시사, 경제까지
        <br />
        영어 대화의 폭을 넓혀요.
      </>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      'n_intro.slides_ai_tutor.detail3',
      <>Expand your English scope—from daily chats to business, and economics.</>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      <>Expand your English scope—from daily chats to business, and economics.</>,
      ``,
    ],
    title4: [
      <>
        연습은 AI와 가볍게, <br />
        <span className="text-purple-500">실전은 원어민</span>과 밀도있게
      </>,
      <>
        Light AI Practice, <br /> <span className="text-purple-500">Intense Tutor Sessions</span>
      </>,
      'n_intro.slides_ai_tutor.title4',
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      <>
        Light AI Practice, <br /> Intense Tutor Sessions
      </>,
      ``,
    ],
    detail4: [
      <>
        AI 스피킹 파트너로 스피킹 연습하고
        <br />
        원어민과 1:1 수업으로 실전 감각을 익혀요.
      </>,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      'n_intro.slides_ai_tutor.detail4',
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      `Practice with an AI Speaking Partner and hone your skills with Ringle tutors.`,
      ``,
    ],
    title5: [
      <>
        화상영어 <span className="text-purple-500">재구매율 1위</span>, <br /> 진짜 실력을 만드는 링글
      </>,
      <>
        <span className="text-purple-500">#1 Repeat Customer Rate</span>, Ringle
      </>,
      'n_intro.slides_ai_tutor.title5',
      `#1 Repeat Customer Rate, Ringle`,
      `#1 Repeat Customer Rate, Ringle`,
      `#1 Repeat Customer Rate, Ringle`,
      ``,
    ],
    detail5: [
      <>
        외국계부터 대기업까지. 실전 영어 사용자들이
        <br />
        링글을 다시 찾는데는 이유가 있어요.
      </>,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      'n_intro.slides_ai_tutor.detail5',
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      `There's a reason why users repeatedly choose Ringle to build genuine skills.`,
      ``,
    ],
    login: [
      `기존 회원인가요?`,
      `Already a member?`,
      'n_intro.slides_ai_tutor.login',
      `Already a member?`,
      `Already a member?`,
      `Already a member?`,
      ``,
    ],
    login1: [`로그인`, `Log in`, 'n_intro.slides_ai_tutor.login1', `Log in`, `Log in`, `Log in`, ``],
  },
  noti_msg: {
    title1: [
      `푸시 알림을 ON 해보세요!`,
      `Enable push notifications from Ringle!`,
      'n_intro.noti_msg.title1',
      `Enable push notifications from Ringle!`,
      `Enable push notifications from Ringle!`,
      `Enable push notifications from Ringle!`,
      ``,
    ],
    body1: [
      `영어 공부에 도움되는 알림과 수업권과 멤버십 혜택, 이벤트에 대한 업데이트를 받아보세요.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      'n_intro.noti_msg.body1',
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      ``,
    ],
    btn_on: [
      `알림 켜기`,
      `Turn on notifications`,
      'n_intro.noti_msg.btn_on',
      `Turn on notifications`,
      `Turn on notifications`,
      `Turn on notifications`,
      ``,
    ],
    btn_next: [
      `다음에 할게요`,
      `I'll turn it on later.`,
      'n_intro.noti_msg.btn_next',
      `I'll turn it on later.`,
      `I'll turn it on later.`,
      `I'll turn it on later.`,
      ``,
    ],
    title2: [
      `영어 공부 꾸준히 할 수 있도록 알림을 보내드려요.`,
      `Receive alerts for keeping you motivated.`,
      'n_intro.noti_msg.title2',
      `Receive alerts for keeping you motivated.`,
      `Receive alerts for keeping you motivated.`,
      `Receive alerts for keeping you motivated.`,
      ``,
    ],
    body2: [
      `영어 공부에 도움되는 알림과 수업권과 멤버십 혜택, 이벤트에 대한 업데이트를 받아보세요.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      'n_intro.noti_msg.body2',
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      `Get helpful messages on studying English as well as alerts on membership benefits and events.`,
      ``,
    ],
  },
}
export const n_lesson_complete = {
  page_title: [
    `수업이 끝났어요.`,
    `The lesson has ended.`,
    'n_lesson_complete.page_title',
    `完成课程了`,
    `完成課程了`,
    `レッスン終了！`,
    ``,
  ],
  btn_exit: [`나가기`, `Leave`, 'n_lesson_complete.btn_exit', `退出`, `退出`, `退出する`, ``],
  btn_book: [
    `다음 수업 예약하기`,
    `Book next lesson`,
    'n_lesson_complete.btn_book',
    `预约下一节课`,
    `預約下一堂課`,
    `次のレッスンを予約する`,
    ``,
  ],
  btn_book_add: [
    `추가 수업 예약하기`,
    `Book more lessons`,
    'n_lesson_complete.btn_book_add',
    `预约更多课程`,
    `預約更多課程`,
    `追加レッスンを予約する`,
    ``,
  ],
  btn_purchase: [
    `수업권 구매하기`,
    `Buy credits`,
    'n_lesson_complete.btn_purchase',
    `购买课程券`,
    `購買課程券`,
    `レッスンチケットを購入する`,
    ``,
  ],
  message: {
    lesson_1: [
      `첫 수업을 잘 해냈어요! 다음 수업에는 영어가 더 편해질 거예요.`,
      `Great job with your first lesson!`,
      'n_lesson_complete.message.lesson_1',
      `完成了第一堂课！下一堂课会更进步`,
      `完成了第一堂課！下一堂課會更進步。`,
      `初回レッスンお疲れ様です！`,
      ``,
    ],
    lesson_2: [
      `"열정적인 영어 갓생러" 달성 과제가 Level 2로 올랐어요!`,
      `You reached Level 2 of "English Enthusiast"!`,
      'n_lesson_complete.message.lesson_2',
      `”热情的英语学习者”成就升级到第2级！`,
      `“熱情的英文學習者”成就升級到第2級！`,
      `“English Enthusiast”の達成状況がレベル2になりました！`,
      ``,
    ],
    lesson_3: [
      `벌써 세 번째 링글 수업을 완료했어요. 정말 대단해요!`,
      `You completed your third Ringle lesson! Amazing!`,
      'n_lesson_complete.message.lesson_3',
      `真是了不起！已经完成三次Ringle课程`,
      `真是了不起！已經完成三次Ringle課程。`,
      `3回目のレッスンを完了しましたね！素晴らしいです！`,
      ``,
    ],
    lesson_4: (user_name) => [
      <>꾸준히 성장하는 {user_name}님의 다음 수업이 정말 기대되네요!</>,
      `Can't wait to see how much you grow in the next lesson!`,
      'n_lesson_complete.message.lesson_4',
      <>期待持续成长的{user_name}的下一堂课！</>,
      <>期待持續成長的{user_name}的下一堂課！</>,
      <>着実に成長している{user_name}さんの次のレッスンがとても楽しみです！</>,
      ``,
    ],
    random_1: [
      `노력한 시간만큼 영어 실력으로 돌아올 거예요. 화이팅!`,
      `Your hard work will pay off in your English.`,
      'n_lesson_complete.message.random_1',
      `付出的时间会转化为英语能力。加油！`,
      `付出的時間會轉化為英文能力。加油！`,
      `頑張った分だけ、英語力も伸びるはずです！頑張ってください！`,
      ``,
    ],
    random_2: [
      `꾸준하게 노력하는 모습이 멋있습니다.`,
      `Your persistence is admirable.`,
      'n_lesson_complete.message.random_2',
      `坚持努力的样子真棒`,
      `努力不懈的樣子真棒`,
      `コツコツと努力している姿が素敵です！`,
      ``,
    ],
    random_3: [
      `수고했어요! 오늘도 한 발짝 더 나아갔네요!`,
      `Way to go, you've taken another step forward today!`,
      'n_lesson_complete.message.random_3',
      `辛苦了！今天又前进了一步！`,
      `辛苦了！今天又前進了一步！`,
      `お疲れ様です！今日も一歩前進しましたね！`,
      ``,
    ],
    random_4: [
      `최고예요! 당신의 노력은 절대 헛되지 않아요!`,
      `We promise your efforts will be rewarded in full.`,
      'n_lesson_complete.message.random_4',
      `太棒了！你的努力绝不会白费！`,
      `太棒了！你的努力絕不會白費！`,
      `最高です！あなたの努力は決して無駄にはなりません！`,
      ``,
    ],
    random_5: [
      `수고했어요! 오늘의 노력이 내일의 큰 성과로!`,
      `Great job! You will reap the fruits of your efforts soon!`,
      'n_lesson_complete.message.random_5',
      `辛苦了！今天的努力会带来明天的巨大成果！`,
      `辛苦了！今天的努力會帶來明天的豐碩成果！`,
      `お疲れ様です！今日の努力が明日の大きな成果に！`,
      ``,
    ],
    random_6: [
      `굉장해요! 영어 공부를 멈추지 마세요!`,
      `Incredible! Keep up the good work!`,
      'n_lesson_complete.message.random_6',
      `太厉害了！不要停止学习英语！`,
      `太厲害了！不要停止學習英文！`,
      `素晴らしいです！英語学習を継続してください！`,
      ``,
    ],
  },
  every_week: {
    complete_lesson: {
      title: (this_week) => [
        <>{this_week}주차 수업 완료!</>,
        <>Week {this_week} lesson completed!</>,
        'n_lesson_complete.every_week.complete_lesson.title',
        <>{this_week}周课程完成！</>,
        <>{this_week}週課程完成！</>,
        <>{this_week}週目のレッスン終了！</>,
        ``,
      ],
      subtitle: [
        `수업 복습도 잊지마세요.`,
        `Don't forget to review the lesson.`,
        'n_lesson_complete.every_week.complete_lesson.subtitle',
        `别忘了复习课程`,
        `別忘了複習課程`,
        `レッスンの復習も忘れずに。`,
        ``,
      ],
    },
    lesson_booked: {
      title: [
        `다음 수업이 예정되어 있어요.`,
        `You have an upcoming lesson.`,
        'n_lesson_complete.every_week.lesson_booked.title',
        `下一堂课已经预约好了`,
        `下一堂課已經預約好了`,
        `次のレッスンが予定されています。`,
        ``,
      ],
      subtitle: (user_name) => [
        <>다음 수업도 미리 계획해 놓는 {user_name}님 최고! 👍</>,
        `You're the best for planning ahead of time! 👍`,
        'n_lesson_complete.every_week.lesson_booked.subtitle',
        <>提前计划好下一堂课的{user_name}真棒！👍</>,
        <>事先計劃好下一堂課的{user_name}真棒！👍</>,
        <>次回レッスンも事前に計画している{user_name}さん、さすがです👍</>,
        ``,
      ],
    },
    no_lesson: {
      title: [
        `곧 차감되는 수업권이 있어요.`,
        `Book next week's lesson.`,
        'n_lesson_complete.every_week.no_lesson.title',
        `请预约下周课程`,
        `請預約下週課程`,
        `次の週のレッスンを予約してください。`,
        ``,
      ],
      subtitle: (expiration_date) => [
        <>{expiration_date}까지 수업을 완료하세요. 또 만나요 👋</>,
        <>Don't forget to complete a lesson by {expiration_date}.</>,
        'n_lesson_complete.every_week.no_lesson.subtitle',
        <>需要在{expiration_date}之前完成课程。</>,
        <>需要在{expiration_date}之前完成課程。</>,
        <>{expiration_date}までにレッスンを完了する必要があります。 </>,
        ``,
      ],
    },
    check_progress: {
      btn: [
        `주차별 현황`,
        `Weekly status`,
        'n_lesson_complete.every_week.check_progress.btn',
        `每週上课情况`,
        `每週上課情況`,
        `週ごとの状況`,
        ``,
      ],
    },
  },
  regular: {
    complete_lesson: {
      title: [
        `오늘 수업 완료!`,
        `Today's lesson done!`,
        'n_lesson_complete.regular.complete_lesson.title',
        `今日课程完成！`,
        `今日課程完成！`,
        `本日のレッスン終了！`,
        ``,
      ],
      subtitle: [
        `수업 복습도 잊지마세요.`,
        `Don't forget to review the lesson.`,
        'n_lesson_complete.regular.complete_lesson.subtitle',
        `别忘了复习课程`,
        `別忘了複習課程`,
        `レッスンの復習も忘れずに。`,
        ``,
      ],
    },
    lesson_booked: {
      title: [
        `다음 수업이 예정되어 있어요.`,
        `You have an upcoming lesson.`,
        'n_lesson_complete.regular.lesson_booked.title',
        `下一堂课已经预约好了`,
        `下一堂課已經預約好了`,
        `次のレッスンが予定されています。`,
        ``,
      ],
      subtitle: (user_name) => [
        <>다음 수업도 미리 계획해 놓는 {user_name}님 최고! 👍</>,
        `You're the best for planning ahead of time! 👍`,
        'n_lesson_complete.regular.lesson_booked.subtitle',
        <>提前计划好下一堂课的{user_name}真棒！👍</>,
        <>事先計劃好下一堂課的{user_name}真棒！👍</>,
        <>次回レッスンも事前に計画している{user_name}さん、さすがです👍</>,
        ``,
      ],
    },
    no_schedule: {
      title: [
        `곧 만료되는 수업권이 있어요.`,
        `You have a credit that expires soon.`,
        'n_lesson_complete.regular.no_schedule.title',
        `请预约下周课程`,
        `請預約下週課程`,
        `次の週のレッスンを予約してください。`,
        ``,
      ],
      subtitle: (expiration_date) => [
        <>{expiration_date}까지 수업을 완료하세요. 또 만나요 👋</>,
        <>You need to complete a lesson by {expiration_date}.</>,
        'n_lesson_complete.regular.no_schedule.subtitle',
        <>需要在{expiration_date}之前完成课程。</>,
        <>需要在{expiration_date}之前完成課程。</>,
        <>{expiration_date}までにレッスンを完了する必要があります。</>,
        ``,
      ],
    },
    no_schedule_alert: {
      title: [
        `지금 공부 의지를 이어가세요.`,
        `Keep up your commitment to learning.`,
        'n_lesson_complete.regular.no_schedule_alert.title',
        `每週上课情况`,
        `每週上課情況`,
        `週ごとの状況`,
        ``,
      ],
      subtitle: [
        `마음이 약해지기 전에 다음 수업을 예약하세요.`,
        `Book your next lesson before you lose your willpower.`,
        'n_lesson_complete.regular.no_schedule_alert.subtitle',
        `今日课程完成！`,
        `今日課程完成！`,
        `本日のレッスン終了！`,
        ``,
      ],
    },
  },
  msg: {
    no_credit: [
      `구매한 수업권을 모두 사용했어요.`,
      `You have used up all your purchased credits.`,
      'n_lesson_complete.msg.no_credit',
      `课程券全数使用完毕`,
      `課程券全數使用完畢`,
      `レッスンチケットを全て使いました。`,
      ``,
    ],
  },
  challenge: {
    banner: {
      title: [
        `이번 달 영어 공부 목표 세우셨나요?`,
        `Have you set your English study goal yet?`,
        'n_lesson_complete.challenge.banner.title',
        `您设定了本月的英语学习目标吗？`,
        `您設定了本月的英語學習目標嗎？`,
        `今月の英語学習目標は立てましたか？`,
        ``,
      ],
      subtitle: [
        <>
          목표 달성에 2배 더 가까워지고 싶다면
          <br />
          링글 챌린지에 참여해보세요! {`>`}{' '}
        </>,
        `Join the Challenge and get twice as close to achieving your goal! >`,
        'n_lesson_complete.challenge.banner.subtitle',
        `参加Challenge，离目标更近一倍！>`,
        `參加Challenge，離目標更近一倍！>`,
        `チャレンジで目標達成を2倍速く！>`,
        ``,
      ],
    },
  },
}
export const n_weekly = {
  freeze_popup: {
    title_1: [
      `매주 수업 듣기 달성을 이어가세요!`,
      `Keep up your Every Week status!`,
      'n_weekly.freeze_popup.title_1',
      `达成每週课程目标！`,
      `達成每週課程目標！`,
      `毎週のレッスン受講を達成しましょう！`,
      ``,
    ],
    desc_1: [
      `수업 연기 혜택으로 수업권이 차감되지 않았어요.`,
      `Deferral benefit was used to prevent credit deduction.`,
      'n_weekly.freeze_popup.desc_1',
      `使用课程延长优惠，课程券仍可使用`,
      `使用課程延長優惠，課程券仍可使用`,
      `レッスン延期特典で、レッスン料は引き落とされませんでした。`,
      ``,
    ],
    desc_2: [
      <>
        수업 연기 혜택을 <span className="text-[#F9725C]">모두 사용</span>했어요.
        <br />
        이제 놓치지 말고 매주 수업해 보세요.
      </>,
      <>
        You have <span className="text-[#F9725C]">0</span> deferral benefits left.
        <br />
        Don't forget to take lessons every week from now on.
      </>,
      'n_weekly.freeze_popup.desc_2',
      <>
        课程延长优惠 <span className="text-[#F9725C]">已使用完毕</span>
        <br />
        现在开始记得每週都要上课
      </>,
      <>
        課程延長優惠 <span className="text-[#F9725C]">已使用完畢</span>
        <br />
        現在開始記得每週都要上課
      </>,
      <>
        レッスン延期特典<span className="text-[#F9725C]">使用終了</span>
        <br />
        毎週レッスンを忘れずに受講してください。
      </>,
      ``,
    ],
  },
  freeze: {
    available: [
      `남은 수업 연기 혜택`,
      `Deferral benefits left`,
      'n_weekly.freeze.available',
      `剩下的课程延长优惠`,
      `剩下的課程延長優惠`,
      `残りのレッスン延期特典`,
      ``,
    ],
    count_1: (n) => [<>{n}</>, <>{n}</>, 'n_weekly.freeze.count_1', <>{n}</>, <>{n}</>, <>{n}</>, ``],
    count_2: (n) => [<>/{n}</>, <>/{n}</>, 'n_weekly.freeze.count_2', <>/{n}</>, <>/{n}</>, <>/{n}</>, ``],
    title: [
      `수업 연기 혜택`,
      `Deferral benefits`,
      'n_weekly.freeze.title',
      `课程券延长`,
      `課程券延長`,
      `レッスン延長`,
      ``,
    ],
    tooltip: [
      `개인적인 사정으로 주 1회 수강을 하지 못한 경우, 수업권이 차감되지 않도록 자동으로 사용됩니다.`,
      `Gets automatically applied to prevent credit deduction when you fail to take a lesson during the week.`,
      'n_weekly.freeze.tooltip',
      `如因个人原因无法每週上1次课，
将不扣除课程券并且自动于约课时使用。`,
      `如因個人原因無法每週上1次課，
將不扣除課程券並且自動於約課時使用。`,
      `個人的な事情で週1回受講できなかった場合、授業権が差し引かれないように自動的に特典が使用されます。`,
      ``,
    ],
  },
  credit: {
    deduction_date: [
      <>
        <span className="text-[#D21D21]">yyyy.mm.dd HH:mm (n)</span> 수업권 차감
      </>,
      <>
        1 credit will be deducted at <span className="text-[#D21D21]">HH:mm on mmm dd (n), yyy(n)</span>
      </>,
      'n_weekly.credit.deduction_date',
      <>
        到<span className="text-[#D21D21]">yyyy.mm.dd HH:mm</span>止(n)张课程券到期
      </>,
      <>
        到<span className="text-[#D21D21]">yyyy.mm.dd HH:mm</span>止(n)張課程券到期
      </>,
      <>
        <span className="text-[#D21D21]">yyyy.mm.dd HH:mm</span> (n) が受講期限です
      </>,
      ``,
    ],
    caption: [
      <>
        매주 수업 듣기는 <span className="text-[#D21D21]">매주 수업 듣기</span>가 표시된 수업권으로 수업해야 달성됩니다.
      </>,
      <>
        The lesson must be taken using credits marked <span className="text-[#D21D21]">Every Week</span> to count toward
        the Every Week status.
      </>,
      'n_weekly.credit.caption',
      <>
        每週课程需使用 <span className="text-[#D21D21]">每週课程券</span>预约并完成课程
      </>,
      <>
        每週課程需使用 <span className="text-[#D21D21]">每週課程券</span>預約並完成課程
      </>,
      <>
        毎週受講の受講状況をカウントするには <span className="text-[#D21D21]">毎週受講</span>
        のクレジットを使用する必要があります。
      </>,
      ``,
    ],
  },
  tutor_matching: {
    caption_title: [
      <>
        매주 수업 듣기는 <span className="text-[#503CC8]">'다른 튜터 매칭 받기’</span>를 추천해요
      </>,
      <>
        <span className="text-[#503CC8]">'Match me with another tutor’</span> recommended for Every Week.
      </>,
      'n_weekly.tutor_matching.caption_title',
      <>
        预约每週课程时建议选择<span className="text-[#503CC8]">'接受与其他教师配对’</span>
      </>,
      <>
        預約每週課程時建議選擇<span className="text-[#503CC8]">'接受與其他教師配對’</span>
      </>,
      <>
        毎週受講の際は、<span className="text-[#503CC8]">他のチューターとマッチングをしてもらう</span>{' '}
        をおすすめします。
      </>,
      ``,
    ],
    caption_desc: [
      `수업 시작 24시간 전까지는 튜터가 수업을 취소해도 해당 주차에 수업을 완료해야 수업권이 차감되지 않아요.`,
      `If tutor cancellation occurs 24 hours or more before the scheduled lesson, you need to complete a lesson during the week to avoid losing a credit.`,
      'n_weekly.tutor_matching.caption_desc',
      `即使因为课程开始前24小时教师取消课程导致课程无法进行，
若是没有在期限内上完课，当週课程券依然会被扣除。`,
      `即使因為課程開始前24小時教師取消課程導致課程無法進行，
若是沒有在期限內上完課，當週課程券依然會被扣除。`,
      `チューターのキャンセルがレッスン予定日の24時間以上前に発生した場合、クレジットを失わないためには、その週のレッスンを完了する必要があります。`,
      ``,
    ],
  },
  cancel: {
    title: [
      `[매주 수업 듣기 안내]`,
      `[Every Week]`,
      'n_weekly.cancel.title',
      `[每週课程说明]`,
      `[每週課程說明]`,
      `毎週受講`,
      ``,
    ],
  },
  done: {
    title: [
      `주 1회 수업 달성`,
      `Every Week fulfilled`,
      'n_weekly.done.title',
      `完成本週课程`,
      `完成本週課程`,
      `受講した週`,
      ``,
    ],
  },
  status_book: {
    title: [
      `주차별 현황`,
      `Weekly Status`,
      'n_weekly.status_book.title',
      `每週课程进度`,
      `每週課程進度`,
      `受講状況`,
      ``,
    ],
  },
  this_week: {
    btn: [`현재 주차`, `Current week`, 'n_weekly.this_week.btn', `本週`, `本週`, `今週`, ``],
  },
  status: {
    caption: {
      freeze: [
        `수업 연기 혜택으로 주 1회 수업 달성됨`,
        `Used deferral benefit`,
        'n_weekly.status.caption.freeze',
        `使用课程延长优惠`,
        `使用課程延長優惠`,
        `延長特典使用`,
        ``,
      ],
      deduction: [
        `주 1회 수업 미수강으로 수업권 차감됨`,
        `Every Week unfulfilled`,
        'n_weekly.status.caption.deduction',
        `未完成该週课程`,
        `未完成該週課程`,
        `受講できなかった週`,
        ``,
      ],
      student_cancel: [
        `학생 취소로 수업권 차감됨`,
        `Student cancellation`,
        'n_weekly.status.caption.student_cancel',
        `学生取消`,
        `學生取消`,
        `ユーザーのキャンセル`,
        ``,
      ],
      student_noshow: [
        `학생 노쇼로 수업권 차감됨`,
        `Student no-show`,
        'n_weekly.status.caption.student_noshow',
        `学生未出席`,
        `學生未出席`,
        `ユーザーのノーショー`,
        ``,
      ],
      tutor_cancel: [
        `튜터 취소로 주 1회 수업 달성됨`,
        `Tutor cancellation`,
        'n_weekly.status.caption.tutor_cancel',
        `教师取消`,
        `教師取消`,
        `チューターのキャンセル`,
        ``,
      ],
      tutor_noshow: [
        `튜터 노쇼로 주 1회 수업 달성됨`,
        `Tutor no-show`,
        'n_weekly.status.caption.tutor_noshow',
        `教师未出席`,
        `教師未出席`,
        `チューターのノーショー`,
        ``,
      ],
      no_credit: [
        `남은 수업권이 없어 차감되지 않음`,
        `No deductable credits left`,
        'n_weekly.status.caption.no_credit',
        `没有可扣除的课程券`,
        `沒有可扣除的課程券`,
        `差し引き可能なクレジットがありません`,
        ``,
      ],
      tutor_not_assigned: [
        `튜터가 배정되지 않아 주 1회 수업 달성됨`,
        `No tutor assigned`,
        'n_weekly.status.caption.tutor_not_assigned',
        `No tutor assigned`,
        `No tutor assigned`,
        `No tutor assigned`,
        ``,
      ],
    },
  },
}
export const invite = {
  section_2: {
    title_promotion: [
      `친구에게 첫 수업 선물하는 법 🎁`,
      `How to Gift a First Lesson to a Friend 🎁`,
      'invite.section_2.title_promotion',
      `如何送朋友一堂课🎁`,
      `如何送朋友一堂課🎁`,
      `友人へ初回レッスンをプレゼントする方法 🎁`,
      ``,
    ],
    desc_1_promotion: [
      `친구에게 초대장을 공유해요.`,
      `Share an invitation with your friend.`,
      'invite.section_2.desc_1_promotion',
      `将邀请连结分享给朋友`,
      `將邀請連結分享給朋友`,
      `Invitationを友人に共有`,
      ``,
    ],
    desc_2_promotion: [
      `초대 받은 친구가 링크로 가입해요.`,
      `Your friend signs up using the link.`,
      'invite.section_2.desc_2_promotion',
      `朋友使用邀请连结加入会员`,
      `朋友使用邀請連結加入會員`,
      `リンクを使って友人が会員登録`,
      ``,
    ],
    desc_3_promotion: [
      `친구가 첫 수업을 무료로 체험해요.`,
      `Your friend gets to experience their first lesson for free.`,
      'invite.section_2.desc_3_promotion',
      `朋友就朋获得一堂20分钟免费课程(需在加入后24小时内使用)`,
      `朋友就朋獲得一堂20分鐘免費課程(需在加入後24小時內使用)`,
      `友人は初回レッスン無料の権利を獲得`,
      ``,
    ],
  },
  section_4: {
    title_promotion: [
      `친구 초대 혜택받는 법 🍀`,
      `How to Receive Benefits from Inviting a Friend 🍀`,
      'invite.section_4.title_promotion',
      `如何获得邀请朋友奖励 🍀`,
      `如何獲得邀請朋友獎勵 🍀`,
      `友人紹介特典の受け取り方 🍀`,
      ``,
    ],
    desc_1_promotion: [
      `내 초대 코드로 가입한 친구가 체험 수업을 완료하면 1만 링글 포인트를 받아요.`,
      `When your friend completes the trial lesson using your invite code, you receive $7.7 Ringle points.`,
      'invite.section_4.desc_1_promotion',
      `当朋友用您的邀请码加入并完成第一堂课，您将获得价值美金$7.7奖励金`,
      `當朋友用您的邀請碼加入並完成第一堂課，您將獲得價值美金$7.7獎勵金`,
      `友人が紹介コードを使って初回レッスンを完了すると、$7.7相当のRingleポイントが獲得できます`,
      ``,
    ],
    label_1_promotion: [
      `최대 5만 포인트까지 받을 수 있습니다.`,
      `You can earn up to $38 points.`,
      'invite.section_4.label_1_promotion',
      `您最多可获得美金$38奖励金`,
      `您最多可獲得美金$38獎勵金`,
      `最大$38ポイント獲得できます`,
      ``,
    ],
    desc_2_promotion: [
      `내 초대 코드로 가입한 친구가 첫 유료 수업을 완료하면 5만 링글 포인트를 받아요.`,
      `If your friend completes their first paid lesson, you receive an additional $38 Ringle points.`,
      'invite.section_4.desc_2_promotion',
      `只要朋友完成第一堂付费课程，您将额外获得美金$38奖励金`,
      `只要朋友完成第一堂付費課程，您將額外獲得美金$38獎勵金`,
      `もし友人が初回レッスンを完了したら、追加で$38相当のRingleポイントを獲得`,
      ``,
    ],
    label_2_promotion: [
      `단, 30만원 이상 수업권을 구매한 경우에 한합니다.`,
      `They should purchase a lesson package worth at least $230.`,
      'invite.section_4.label_2_promotion',
      `朋友需购买价值高于美金$230课程您才有资格获得奖励`,
      `朋友需購買價值高於美金$230課程您才有資格獲得獎勵`,
      `最低$230相当のレッスンパッケージを購入する必要があります`,
      ``,
    ],
    desc_3_promotion: [
      `받은 포인트는 상품권 또는 링글 수업권으로 교환할 수 있어요.`,
      `The points can be exchanged for gift cards or Ringle lesson credits.`,
      'invite.section_4.desc_3_promotion',
      `奖励金可兑换商品券(限韩国)或是课程折抵`,
      `獎勵金可兌換商品券(限韓國)或是課程折抵`,
      `ポイントはギフトカードやRingleレッスンクレジットと交換ができます`,
      ``,
    ],
  },
}
export const terms_1 = {
  title: {
    promotion: [
      `나 (추천인) 유의사항`,
      `Terms for the Referrer:`,
      'terms_1.title.promotion',
      `邀请人奖励`,
      `邀請人獎勵`,
      `紹介者の条件:`,
      ``,
    ],
  },
  subtitle_1: {
    promotion: [
      `친구가 체험 수업을 완료하면 1만 포인트를 드려요.`,
      `Receive $7.7 points for each friend who completes a trial lesson.`,
      'terms_1.subtitle_1.promotion',
      `每推荐一位朋友完成体验课就能获得美金$7.7奖励金`,
      `每推薦一位朋友完成體驗課就能獲得美金$7.7獎勵金`,
      `友人がトライアルレッスンを完了する都度、$7.7相当のポイントが獲得可能`,
      ``,
    ],
  },
  section_1: {
    desc_1_promotion: [
      `체험 수업 완료자 1명 당 1만 포인트, 최대 5만 포인트를 드립니다. (친구의 체험 수업 완료 날짜 기준)`,
      `You receive $7.7 points for each friend who completes a trial lesson, up to a maximum of $38 points.`,
      'terms_1.section_1.desc_1_promotion',
      `朋友完成体验奖励最多回馈上限为美金$38`,
      `朋友完成體驗獎勵最多回饋上限為美金$38`,
      `友人が体験レッスンを完了するごとに$7.7ポイント、最大$38ポイントを受け取ることができます。`,
      ``,
    ],
    desc_2_promotion: [
      `6번째 친구가 체험 수업을 완료하면 포인트를 드리지 않습니다.`,
      `No points are given after the sixth friend completes their trial lesson.`,
      'terms_1.section_1.desc_2_promotion',
      `被邀请的第七位朋友即使完成体验课邀请人也无法获得奖励`,
      `被邀請的第七位朋友即使完成體驗課邀請人也無法獲得獎勵`,
      `6人目以降の友人が体験レッスンを完了しても、ピントは差し上げられません。`,
      ``,
    ],
    desc_3_promotion: [
      `친구 초대 이벤트 기간 중 친구가 체험 수업을 완료해야 포인트를 드립니다. (이벤트 기간 6.11 ~ 7.9 KST)`,
      `Points are only awarded if the friend completes the trial lesson during the event period (June 11 ~ July 9 KST).`,
      'terms_1.section_1.desc_3_promotion',
      `Points are only awarded if the friend completes the trial lesson during the event period (June 11 ~ July 9 KST).`,
      `Points are only awarded if the friend completes the trial lesson during the event period (June 11 ~ July 9 KST).`,
      `Points are only awarded if the friend completes the trial lesson during the event period (June 11 ~ July 9 KST).`,
      ``,
    ],
  },
  subtitle_2: {
    promotion: [
      `친구가 첫 유료 수업을 완료하면 5만 포인트를 드려요.`,
      `Receive $38 points when your friend completes their first paid lesson`,
      'terms_1.subtitle_2.promotion',
      `只要朋友完成第一堂付费课程，您将获得美金$38折扣金`,
      `只要朋友完成第一堂付費課程，您將獲得美金$38折扣金`,
      `友人の初回レッスン完了時に $38 ポイントをプレゼント`,
      ``,
    ],
  },
  section_2: {
    desc_1_promotion: [
      `친구가 30만원 이상 수업권을 결제하고 첫 수업을 완료하는 시점에 5만 포인트를 드립니다.`,
      `You receive $38 points when your friend completes their first paid lesson, provided they have purchased a lesson package worth at least $230.`,
      'terms_1.section_2.desc_1_promotion',
      `只要朋友完成第一堂付费课程，您将额外获得美金$38折扣金。朋友需购买价值高于美金$230课程您才有资格获得奖励。`,
      `只要朋友完成第一堂付費課程，您將額外獲得美金$38折扣金。朋友需購買價值高於美金$230課程您才有資格獲得獎勵。`,
      `友人が最初の有料レッスンを完了すると、$38 ポイントを受け取れます。ただし、その友人が $230 以上のレッスンパッケージを購入している場合に限ります。`,
      ``,
    ],
    desc_2_promotion: [
      `친구의 첫 수업 시 제공되는 포인트는 매 월 1명까지 5만 포인트, 이후 1만 포인트가 제공됩니다. (친구의 첫 수업 완료 날짜 기준)`,
      `For the first paid lesson completed in a month by a friend, you get $38 points; for subsequent friends, you get $7.7 points each month.`,
      'terms_1.section_2.desc_2_promotion',
      `每个月第一位完成付费课程的朋友出现时，您将获得美金$38折扣金。第二位朋友之后每一位完成获得的奖励为美金$7.7。`,
      `每個月第一位完成付費課程的朋友出現時，您將獲得美金$38折扣金。第二位朋友之後每一位完成獲得的獎勵為美金$7.7。`,
      `友人がその月に初めて有料レッスンを受講された場合、38ドル分のポイントがもらえます。`,
      ``,
    ],
  },
  subtitle_3: {
    promotion: [`공통사항`, `General Terms:`, 'terms_1.subtitle_3.promotion', `注意事项`, `注意事項`, `一般用語:`, ``],
  },
  section_3: {
    desc_1_promotion: [
      `친구 초대 포인트는 신세계 상품권, 스타벅스 쿠폰, 링글 수업권으로 교환할 수 있습니다.`,
      `Invite points can be exchanged for Shinsegae gift vouchers, Starbucks coupons, or Ringle lesson credits.`,
      'terms_1.section_3.desc_1_promotion',
      `奖励金可兑换商品券(限韩国)或是课程折抵`,
      `獎勵金可兌換商品券(限韓國)或是課程折抵`,
      `紹介ポイントは、Shinsegae gift vouchers, Starbucks coupons, あるいは Ringle lesson creditsと交換できます。`,
      ``,
    ],
    desc_2_promotion: [
      `수업권 구매 시 포인트를 사용할 경우 30만원 이상 결제 시 사용가능합니다.`,
      `The points can be used for purchasing lesson packages worth at least $230.`,
      'terms_1.section_3.desc_2_promotion',
      `购买价值超过美金$230以上课程时可使用奖励金折抵`,
      `購買價值超過美金$230以上課程時可使用獎勵金折抵`,
      `ポイントは$230ドル以上のレッスンパッケージの購入にご利用いただけます。`,
      ``,
    ],
    desc_3_promotion: [
      `지급된 포인트의 유효기간은 30일입니다.`,
      `The validity of awarded points is 30 days.`,
      'terms_1.section_3.desc_3_promotion',
      `奖励金的有效期限为30天`,
      `獎勵金的有效期限為30天`,
      `付与されたポイントの有効期限は30日間です.`,
      ``,
    ],
    desc_4_promotion: [
      `비정상적인 친구 초대로 판단되는 경우 모든 혜택 제공은 중단되거나, 반환을 요청할 수 있습니다.`,
      `If the invitation is deemed fraudulent, all benefits can be suspended or a refund may be requested.`,
      'terms_1.section_3.desc_4_promotion',
      `如果有恶意滥用邀请获得奖励金之行为，将收回或取消奖励金`,
      `如果有惡意濫用邀請獲得獎勵金之行為，將收回或取消獎勵金`,
      `紹介が不正であると判断された場合、すべての特典が停止されるか、返金を請求されることがあります。`,
      ``,
    ],
  },
}
export const terms_2 = {
  title: {
    promotion: [
      `친구 (피추천인) 유의사항`,
      `Terms for the Invitee:`,
      'terms_2.title.promotion',
      `被邀请人注意事项`,
      `被邀請人注意事項`,
      `被紹介者の条件:`,
      ``,
    ],
  },
  subtitle_1: {
    promotion: [
      `친구 초대코드로 가입한 후 24시간 이내 체험 수업을 무료로 신청할 수 있습니다.`,
      `After signing up with a friend's invite code, you can apply for a free trial lesson within 24 hours.`,
      'terms_2.subtitle_1.promotion',
      `使用朋友邀请连结加入后24小时能获得一堂20分钟免费课程`,
      `使用朋友邀請連結加入後24小時能獲得一堂20分鐘免費課程`,
      `友人の招待コードでご登録した場合は、24時間以内に無料体験レッスンをお申込みいただけます。`,
      ``,
    ],
  },
  section_1: {
    desc_1_promotion: [
      `단, 최대 할인을 위한 단계를 모두 완료한 경우에 한하여 무료로 체험 수업이 가능합니다. (친구의 초대코드로 가입하지 않은 경우 4,000원에 체험 수업 가능)`,
      `This free trial is only available after completing all steps for maximum discount. Without using a friend's invite code, a trial lesson costs $3.`,
      'terms_2.section_1.desc_1_promotion',
      `请完成所有指示条件已获得免费课程，否则将需付体验课费用美金$3元`,
      `請完成所有指示條件已獲得免費課程，否則將需付體驗課費用美金$3元`,
      `この無料体験は、最大割引のためのすべてのステップを完了した後にのみご利用いただけます。紹介コードを使用しない場合、体験レッスンは$3かかります。`,
      ``,
    ],
    desc_2_promotion: [
      `친구의 초대코드로 가입한 직후 5만 포인트가 지급됩니다.`,
      `$38 points are awarded immediately after signing up with a friend’s invite code.`,
      'terms_2.section_1.desc_2_promotion',
      `使用朋友邀请连结加入后立即获得美金$38奖励金`,
      `使用朋友邀請連結加入後立即獲得美金$38獎勵金`,
      `お友達の招待コードで申し込むと、すぐに$38のポイントが付与されます。`,
      ``,
    ],
    desc_3_promotion: [
      `지급된 포인트의 유효기간은 1년입니다.`,
      `The validity of awarded points is one year.`,
      'terms_2.section_1.desc_3_promotion',
      `奖励金的使用期限为一年`,
      `獎勵金的使用期限為一年`,
      `ポイントの有効期限は1年間です。`,
      ``,
    ],
    desc_4_promotion: [
      `지급된 포인트는 30만원 이상 수업권 구매시 사용 가능합니다.`,
      `The points can be used for purchasing lesson packages worth at least $230.`,
      'terms_2.section_1.desc_4_promotion',
      `奖励金可购买价值高于美金$230课程时使用`,
      `獎勵金可購買價值高於美金$230課程時使用`,
      `ポイントは、最低230ドル相当のレッスンパッケージの購入にご利用いただけます。`,
      ``,
    ],
  },
}
export const n_first_guide = {
  top: {
    date: (lesson_date) => [
      <>{lesson_date} 수업</>,
      <>Lesson on {lesson_date}</>,
      'n_first_guide.top.date',
      <>Lesson on {lesson_date}</>,
      <>Lesson on {lesson_date}</>,
      <>Lesson on {lesson_date}</>,
      ``,
    ],
  },
  level: {
    title: [
      `나의 첫 링글 수업,
영어는 어땠을까?`,
      `How was my English
in my first Ringle lesson?`,
      'n_first_guide.level.title',
      `How was my English
in my first Ringle lesson?`,
      `How was my English
in my first Ringle lesson?`,
      `How was my English
in my first Ringle lesson?`,
      ``,
    ],
    subtitle: (name) => [
      <>
        링글 AI가 분석한 <span className="text-purple-500">{name}</span>님의
        <br />
        강점과 약점을 알아보세요.
      </>,
      <>
        <span className="text-purple-500">{name}</span>, check out your strengths and weaknesses analyzed by Ringle AI.
      </>,
      'n_first_guide.level.subtitle',
      <>
        <span className="text-purple-500">{name}</span>, check out your strengths and weaknesses analyzed by Ringle AI.
      </>,
      <>
        <span className="text-purple-500">{name}</span>, check out your strengths and weaknesses analyzed by Ringle AI.
      </>,
      <>
        <span className="text-purple-500">{name}</span>, check out your strengths and weaknesses analyzed by Ringle AI.
      </>,
      ``,
    ],
    result_title: [`분석 결과`, `Summary`, 'n_first_guide.level.result_title', `Summary`, `Summary`, `Summary`, ``],
    result_more: [
      `분석 자세히 보기`,
      `View details`,
      'n_first_guide.level.result_more',
      `View details`,
      `View details`,
      `View details`,
      ``,
    ],
  },
  cafp_q: {
    title: [
      `CAFP가 무엇인가요?`,
      `What is CAFP?`,
      'n_first_guide.cafp_q.title',
      `What is CAFP?`,
      `What is CAFP?`,
      `What is CAFP?`,
      ``,
    ],
    content: [
      `고객님의 영어 구사 수준을 진단한 4가지 지표 (Complexity, Accuracy, Fluency, Pronunciation)를 CAFP라고 부르고 있어요.
참고로, 해당 지표는 언어학에서 오랫동안 사용된 언어 구사 수준을 평가하는 지표에요.`,
      `CAFP stands for four measures used to assess your English proficiency: Complexity, Accuracy, Fluency, Pronunciation.
FYI, these measures are used as established indicators for assessing language proficiency in linguistics research.`,
      'n_first_guide.cafp_q.content',
      `CAFP stands for four measures used to assess your English proficiency: Complexity, Accuracy, Fluency, Pronunciation.
FYI, these measures are used as established indicators for assessing language proficiency in linguistics research.`,
      `CAFP stands for four measures used to assess your English proficiency: Complexity, Accuracy, Fluency, Pronunciation.
FYI, these measures are used as established indicators for assessing language proficiency in linguistics research.`,
      `CAFP stands for four measures used to assess your English proficiency: Complexity, Accuracy, Fluency, Pronunciation.
FYI, these measures are used as established indicators for assessing language proficiency in linguistics research.`,
      ``,
    ],
  },
  tip: {
    subtitle: (name) => [
      <>
        <span className="text-purple-500">{name}</span>님의 영어 약점을 보완할 팁을 드려요
      </>,
      <>
        <span className="text-purple-500">{name}</span>, here are some tips to improve your weak points.
      </>,
      'n_first_guide.tip.subtitle',
      <>
        <span className="text-purple-500">{name}</span>, here are some tips to improve your weak points.
      </>,
      <>
        <span className="text-purple-500">{name}</span>, here are some tips to improve your weak points.
      </>,
      <>
        <span className="text-purple-500">{name}</span>, here are some tips to improve your weak points.
      </>,
      ``,
    ],
    c_0_title: [
      `Complexity를 높이는 방법`,
      `How to improve in Complexity`,
      'n_first_guide.tip.c_0_title',
      `How to improve in Complexity`,
      `How to improve in Complexity`,
      `How to improve in Complexity`,
      ``,
    ],
    c_1_title: [
      `먼저, Complexity가 낮은 이유를 확인해보세요.`,
      `First, check why your Complexity is low.`,
      'n_first_guide.tip.c_1_title',
      `First, check why your Complexity is low.`,
      `First, check why your Complexity is low.`,
      `First, check why your Complexity is low.`,
      ``,
    ],
    c_1_content: [
      `링글의 진단 알고리즘은 어휘 난이도, 문장 종류, 그리고 어휘 다양성 3가지 항목을 기준으로 영어의 복잡성을 진단합니다.`,
      `Ringle's diagnostic algorithm assesses Complexity based on the types of sentences and the level and diversity of vocabulary used.`,
      'n_first_guide.tip.c_1_content',
      `Ringle's diagnostic algorithm assesses Complexity based on the types of sentences and the level and diversity of vocabulary used.`,
      `Ringle's diagnostic algorithm assesses Complexity based on the types of sentences and the level and diversity of vocabulary used.`,
      `Ringle's diagnostic algorithm assesses Complexity based on the types of sentences and the level and diversity of vocabulary used.`,
      ``,
    ],
    c_2_title: [
      `Run-on 문장을 줄여보세요.`,
      `Reduce run-on sentences.`,
      'n_first_guide.tip.c_2_title',
      `Reduce run-on sentences.`,
      `Reduce run-on sentences.`,
      `Reduce run-on sentences.`,
      ``,
    ],
    c_2_content: [
      `Run-on 문장이란, 2-3개의 문장을 적절한 마침표나 접속사 없이 이어 말하는 것을 말합니다. 말을 이어서 계속하면 듣는 사람 입장에서 이해하기 어려울 수 있습니다. 짧은 문장으로 나누어 말하는 연습을 해보세요.`,
      `A run-on sentence occurs when two or more independent clauses run together without proper punctuation or appropriate conjunctions. Run-on sentences can be difficult to understand for listeners. Practice speaking in short sentences to avoid run-on.`,
      'n_first_guide.tip.c_2_content',
      `A run-on sentence occurs when two or more independent clauses run together without proper punctuation or appropriate conjunctions. Run-on sentences can be difficult to understand for listeners. Practice speaking in short sentences to avoid run-on.`,
      `A run-on sentence occurs when two or more independent clauses run together without proper punctuation or appropriate conjunctions. Run-on sentences can be difficult to understand for listeners. Practice speaking in short sentences to avoid run-on.`,
      `A run-on sentence occurs when two or more independent clauses run together without proper punctuation or appropriate conjunctions. Run-on sentences can be difficult to understand for listeners. Practice speaking in short sentences to avoid run-on.`,
      ``,
    ],
    c_3_title: [
      `예습을 통해, 동일한 단어 반복을 줄여보세요.`,
      `Avoid using repetitive words through lesson prep.`,
      'n_first_guide.tip.c_3_title',
      `Avoid using repetitive words through lesson prep.`,
      `Avoid using repetitive words through lesson prep.`,
      `Avoid using repetitive words through lesson prep.`,
      ``,
    ],
    c_3_content: [
      `다양한 단어를 사용할 수 있도록, 수업 준비 과정에서 미리 답변을 작성해 보세요. 링글 교재의 핵심 표현을 참고해 키워드 중심으로 정리해 두면, 동일한 내용이더라도 다른 문장으로 표현할 수 있습니다.`,
      `Prep for the lesson by jotting down what you plan to say in keywords, referring to key expressions from the Ringle material. This will help you use a greater variety of words and put together sentences in different ways.`,
      'n_first_guide.tip.c_3_content',
      `Prep for the lesson by jotting down what you plan to say in keywords, referring to key expressions from the Ringle material. This will help you use a greater variety of words and put together sentences in different ways.`,
      `Prep for the lesson by jotting down what you plan to say in keywords, referring to key expressions from the Ringle material. This will help you use a greater variety of words and put together sentences in different ways.`,
      `Prep for the lesson by jotting down what you plan to say in keywords, referring to key expressions from the Ringle material. This will help you use a greater variety of words and put together sentences in different ways.`,
      ``,
    ],
    a_0_title: [
      `Accuracy를 높이는 방법`,
      `How to improve in Accuracy`,
      'n_first_guide.tip.a_0_title',
      `How to improve in Accuracy`,
      `How to improve in Accuracy`,
      `How to improve in Accuracy`,
      ``,
    ],
    a_1_title: [
      `먼저, Accuracy가 낮은 이유를 확인해보세요.`,
      `First, understand why your Accuracy is low.`,
      'n_first_guide.tip.a_1_title',
      `First, understand why your Accuracy is low.`,
      `First, understand why your Accuracy is low.`,
      `First, understand why your Accuracy is low.`,
      ``,
    ],
    a_1_content: [
      `링글 AI 분석은 총 13개의 항목에 대해 영어의 정확도, 특히 문법 실수를 집중적으로 진단합니다. 어떤 항목에서 많은 실수가 있었는지 확인해보세요.`,
      `Ringle AI Analysis examines 13 items for Accuracy, focusing especially on grammatical mistakes. See which items you made the most mistakes in.`,
      'n_first_guide.tip.a_1_content',
      `Ringle AI Analysis examines 13 items for Accuracy, focusing especially on grammatical mistakes. See which items you made the most mistakes in.`,
      `Ringle AI Analysis examines 13 items for Accuracy, focusing especially on grammatical mistakes. See which items you made the most mistakes in.`,
      `Ringle AI Analysis examines 13 items for Accuracy, focusing especially on grammatical mistakes. See which items you made the most mistakes in.`,
      ``,
    ],
    a_2_title: [
      `문법 실수를 분석하고, 집중 학습 영역을 정하세요.`,
      `Analyze your grammar mistakes and set your focus areas for improvement.`,
      'n_first_guide.tip.a_2_title',
      `Analyze your grammar mistakes and set your focus areas for improvement.`,
      `Analyze your grammar mistakes and set your focus areas for improvement.`,
      `Analyze your grammar mistakes and set your focus areas for improvement.`,
      ``,
    ],
    a_2_content: [
      `AI 분석을 보면서 어떤 문법 실수를 많이 하는지, 그리고 왜 틀리는지 패턴을 파악해보세요. 3-4주 단위로 항목 1-2개를 집중적으로 고치기 위해 노력해보세요.`,
      `When going over the AI analysis report, try to find patterns in the grammar mistakes you make and why you get them wrong. Then, commit to fixing 1-2 mistakes over a 3-4 week period.`,
      'n_first_guide.tip.a_2_content',
      `When going over the AI analysis report, try to find patterns in the grammar mistakes you make and why you get them wrong. Then, commit to fixing 1-2 mistakes over a 3-4 week period.`,
      `When going over the AI analysis report, try to find patterns in the grammar mistakes you make and why you get them wrong. Then, commit to fixing 1-2 mistakes over a 3-4 week period.`,
      `When going over the AI analysis report, try to find patterns in the grammar mistakes you make and why you get them wrong. Then, commit to fixing 1-2 mistakes over a 3-4 week period.`,
      ``,
    ],
    a_3_title: [
      `1:1 수업 방식 설정을 200% 활용해보세요.`,
      `Make full use of the lesson setting.`,
      'n_first_guide.tip.a_3_title',
      `Make full use of the lesson setting.`,
      `Make full use of the lesson setting.`,
      `Make full use of the lesson setting.`,
      ``,
    ],
    a_3_content: [
      `링글 수업 요청 사항에 고치고 싶은 문법 오류에 대해 집중적으로 교정 해줄 것을 요청하세요. 상세하게 작성할수록, 원하는 항목의 교정을 받을 수 있습니다.`,
      `Leave a request for the tutor to focus on correcting the grammar mistakes you want to fix. The more detailed your request is, the better the result will be.`,
      'n_first_guide.tip.a_3_content',
      `Leave a request for the tutor to focus on correcting the grammar mistakes you want to fix. The more detailed your request is, the better the result will be.`,
      `Leave a request for the tutor to focus on correcting the grammar mistakes you want to fix. The more detailed your request is, the better the result will be.`,
      `Leave a request for the tutor to focus on correcting the grammar mistakes you want to fix. The more detailed your request is, the better the result will be.`,
      ``,
    ],
    f_0_title: [
      `Fluency를 높이는 방법`,
      `How to improve in Fluency`,
      'n_first_guide.tip.f_0_title',
      `How to improve in Fluency`,
      `How to improve in Fluency`,
      `How to improve in Fluency`,
      ``,
    ],
    f_1_title: [
      `먼저, Fluency가 낮은 이유를 확인해보세요.`,
      `First, find out why your Fluency is low.`,
      'n_first_guide.tip.f_1_title',
      `First, find out why your Fluency is low.`,
      `First, find out why your Fluency is low.`,
      `First, find out why your Fluency is low.`,
      ``,
    ],
    f_1_content: [
      `링글 AI 분석은 여러분의 말하기가 자연스러운 속도인지, 말하기 도중 멈춤이 있는지, 필러워드를 많이 쓰는지 등의 기준으로 영어의 유창성을 진단합니다.`,
      `Ringle AI Analysis diagnoses Fluency based on the pace of your speech and use of pauses and filler words.`,
      'n_first_guide.tip.f_1_content',
      `Ringle AI Analysis diagnoses Fluency based on the pace of your speech and use of pauses and filler words.`,
      `Ringle AI Analysis diagnoses Fluency based on the pace of your speech and use of pauses and filler words.`,
      `Ringle AI Analysis diagnoses Fluency based on the pace of your speech and use of pauses and filler words.`,
      ``,
    ],
    f_2_title: [
      `AI 스피킹 파트너와 꾸준히 대화하세요.`,
      `Keep practicing with AI Speaking Partner.`,
      'n_first_guide.tip.f_2_title',
      `Keep practicing with AI Speaking Partner.`,
      `Keep practicing with AI Speaking Partner.`,
      `Keep practicing with AI Speaking Partner.`,
      ``,
    ],
    f_2_content: [
      `유창성을 높이기 위해서는 정확도가 조금 떨어져도, 영어로 많이 말하는 연습을 하는 것이 중요합니다. 링글 앱만 있다면 언제든 대화할 수 있는 AI 스피킹 파트너와 매일 10분 정도 대화해 보세요.`,
      `To improve Fluency, it's important to practice speaking in English as much as possible, even if it's not perfect. Spend 10 minutes every day with AI Speaking Partner, available at all times on the Ringle app.`,
      'n_first_guide.tip.f_2_content',
      `To improve Fluency, it's important to practice speaking in English as much as possible, even if it's not perfect. Spend 10 minutes every day with AI Speaking Partner, available at all times on the Ringle app.`,
      `To improve Fluency, it's important to practice speaking in English as much as possible, even if it's not perfect. Spend 10 minutes every day with AI Speaking Partner, available at all times on the Ringle app.`,
      `To improve Fluency, it's important to practice speaking in English as much as possible, even if it's not perfect. Spend 10 minutes every day with AI Speaking Partner, available at all times on the Ringle app.`,
      ``,
    ],
    f_3_title: [
      `링글 수업 200% 활용해보세요.`,
      `Get 200% out of Ringle lessons.`,
      'n_first_guide.tip.f_3_title',
      `Get 200% out of Ringle lessons.`,
      `Get 200% out of Ringle lessons.`,
      `Get 200% out of Ringle lessons.`,
      ``,
    ],
    f_3_content: [
      <>
        1:1 원어민 수업에서 내가 영어로 더 많이 말할 수 있도록 수업을 요청해보세요. 교정 중심으로 할 경우에는 질문에
        대한 대화 후 교정 받기로 진행해보세요.
        <br />
        토론 중심 모드로 설정한다면 8:2 토론 모드를 통해 영어로 더 많이 말할 수 있는 기회를 만들어보세요.
      </>,
      `Get more chances to speak during the lesson by choosing 'Intermittent paraphrasing' for paraphrasing-focused lessons and 'Talk more (8:2)' for discussion-focused lessons.`,
      'n_first_guide.tip.f_3_content',
      `Get more chances to speak during the lesson by choosing 'Intermittent paraphrasing' for paraphrasing-focused lessons and 'Talk more (8:2)' for discussion-focused lessons.`,
      `Get more chances to speak during the lesson by choosing 'Intermittent paraphrasing' for paraphrasing-focused lessons and 'Talk more (8:2)' for discussion-focused lessons.`,
      `Get more chances to speak during the lesson by choosing 'Intermittent paraphrasing' for paraphrasing-focused lessons and 'Talk more (8:2)' for discussion-focused lessons.`,
      ``,
    ],
  },
  tutor: {
    c_subtitle: [
      `문장을 다듬어줄 튜터를 추천해드려요`,
      `Meet tutors who excel at paraphrasing.`,
      'n_first_guide.tutor.c_subtitle',
      `Meet tutors who excel at paraphrasing.`,
      `Meet tutors who excel at paraphrasing.`,
      `Meet tutors who excel at paraphrasing.`,
      ``,
    ],
    c_title: [
      <>
        <span className="text-purple-500">간결하고 명확한 문장</span>으로
        <br />
        바꿔주는 튜터와 수업해 보세요.
      </>,
      <>
        Learn from a tutor who can help you
        <br />
        <span className="text-purple-500">construct concise, clear sentences</span>.
      </>,
      'n_first_guide.tutor.c_title',
      <>
        Learn from a tutor who can help you
        <br />
        <span className="text-purple-500">construct concise, clear sentences</span>.
      </>,
      <>
        Learn from a tutor who can help you
        <br />
        <span className="text-purple-500">construct concise, clear sentences</span>.
      </>,
      <>
        Learn from a tutor who can help you
        <br />
        <span className="text-purple-500">construct concise, clear sentences</span>.
      </>,
      ``,
    ],
    c_content: [
      `첫 수업에서 나의 장황했던 문장이 간결하고 명확하게 고쳐지는 경험을 해 보셨나요? 좋은 문장 구성을 가장 잘 알려줄 수 있는 링글 튜터에게 문장을 교정 받으며 학습해보세요.`,
      `Did you see how your long-winded sentences can be made concise and clear in your first lesson? Try learning from a tutor who excels at teaching how to construct good sentences.`,
      'n_first_guide.tutor.c_content',
      `Did you see how your long-winded sentences can be made concise and clear in your first lesson? Try learning from a tutor who excels at teaching how to construct good sentences.`,
      `Did you see how your long-winded sentences can be made concise and clear in your first lesson? Try learning from a tutor who excels at teaching how to construct good sentences.`,
      `Did you see how your long-winded sentences can be made concise and clear in your first lesson? Try learning from a tutor who excels at teaching how to construct good sentences.`,
      ``,
    ],
    a_subtitle: [
      `문법 약점을 정확히 알려줄 튜터를 추천해드려요`,
      `Meet tutors who can pinpoint your grammar weaknesses.`,
      'n_first_guide.tutor.a_subtitle',
      `Meet tutors who can pinpoint your grammar weaknesses.`,
      `Meet tutors who can pinpoint your grammar weaknesses.`,
      `Meet tutors who can pinpoint your grammar weaknesses.`,
      ``,
    ],
    a_title: [
      <>
        <span className="text-purple-500">꼼꼼한 교정</span>이 강점인 
        <br />
        튜터와 수업해 보세요.
      </>,
      <>
        Learn from a tutor who  <br />
        excels at <span className="text-purple-500">correcting mistakes</span>.
      </>,
      'n_first_guide.tutor.a_title',
      <>
        Learn from a tutor who  <br />
        excels at <span className="text-purple-500">correcting mistakes</span>.
      </>,
      <>
        Learn from a tutor who  <br />
        excels at <span className="text-purple-500">correcting mistakes</span>.
      </>,
      <>
        Learn from a tutor who  <br />
        excels at <span className="text-purple-500">correcting mistakes</span>.
      </>,
      ``,
    ],
    a_content: [
      `첫 수업에서 내가 자주 틀리는 문법을 정확히 고쳐주는 경험을 해보셨나요? 자연스러운 문법을 가장 잘 알려줄 수 있는 링글 튜터에게 문법을 교정 받으며 학습해보세요.`,
      `Did you notice how your tutor catches and corrects the grammar mistakes you make often in your first lesson? Try learning from a tutor who can make grammar come naturally to you.`,
      'n_first_guide.tutor.a_content',
      `Did you notice how your tutor catches and corrects the grammar mistakes you make often in your first lesson? Try learning from a tutor who can make grammar come naturally to you.`,
      `Did you notice how your tutor catches and corrects the grammar mistakes you make often in your first lesson? Try learning from a tutor who can make grammar come naturally to you.`,
      `Did you notice how your tutor catches and corrects the grammar mistakes you make often in your first lesson? Try learning from a tutor who can make grammar come naturally to you.`,
      ``,
    ],
    f_subtitle: [
      `대화를 잘 유도하는 튜터를 추천해드려요`,
      `Meet tutors who are great at making you talk.`,
      'n_first_guide.tutor.f_subtitle',
      `Meet tutors who are great at making you talk.`,
      `Meet tutors who are great at making you talk.`,
      `Meet tutors who are great at making you talk.`,
      ``,
    ],
    f_title: [
      <>
        <span className="text-purple-500">편안한 분위기</span>가 강점인
        <br />
        튜터와 수업해 보세요.
      </>,
      <>
        Learn from a tutor who's
        <br />
        <span className="text-purple-500">comfortable to talk to</span>.
      </>,
      'n_first_guide.tutor.f_title',
      <>
        Learn from a tutor who's
        <br />
        <span className="text-purple-500">comfortable to talk to</span>.
      </>,
      <>
        Learn from a tutor who's
        <br />
        <span className="text-purple-500">comfortable to talk to</span>.
      </>,
      <>
        Learn from a tutor who's
        <br />
        <span className="text-purple-500">comfortable to talk to</span>.
      </>,
      ``,
    ],
    f_content: [
      `체험 수업에서 말하기를 자연스럽게 이끌어내는 마법같은 영어 질문들을 받아 보셨나요? 어색하지 않고 많은 대화를 나눌 수 있도록, 링글 튜터들이 이끌어드릴게요.`,
      `Did you feel how the tutor's questions magically get you talking in the trial lesson? Ringle tutors will pull you out of your shell and help you converse in English without feeling awkward.`,
      'n_first_guide.tutor.f_content',
      `Did you feel how the tutor's questions magically get you talking in the trial lesson? Ringle tutors will pull you out of your shell and help you converse in English without feeling awkward.`,
      `Did you feel how the tutor's questions magically get you talking in the trial lesson? Ringle tutors will pull you out of your shell and help you converse in English without feeling awkward.`,
      `Did you feel how the tutor's questions magically get you talking in the trial lesson? Ringle tutors will pull you out of your shell and help you converse in English without feeling awkward.`,
      ``,
    ],
  },
  material: {
    c_subtitle: [
      `다양한 단어와 표현을 배울 수 있는 교재`,
      `Materials for learning diverse words and expressions`,
      'n_first_guide.material.c_subtitle',
      `Materials for learning diverse words and expressions`,
      `Materials for learning diverse words and expressions`,
      `Materials for learning diverse words and expressions`,
      ``,
    ],
    c_title: [
      <>
        영어 교재로, <br />
        <span className="text-blue-600">원어민의 문장과 표현</span>을 익혀보세요.
      </>,
      <>
        Learn phrases and expressions
        <br />
        <span className="text-blue-600">used by native speakers</span> from Ringle materials.
      </>,
      'n_first_guide.material.c_title',
      <>
        Learn phrases and expressions
        <br />
        <span className="text-blue-600">used by native speakers</span> from Ringle materials.
      </>,
      <>
        Learn phrases and expressions
        <br />
        <span className="text-blue-600">used by native speakers</span> from Ringle materials.
      </>,
      <>
        Learn phrases and expressions
        <br />
        <span className="text-blue-600">used by native speakers</span> from Ringle materials.
      </>,
      ``,
    ],
    c_content: [
      `비즈니스부터 트렌디한 토픽까지, 다양한 분야에서 사용하는 단어와 문장을 살펴보며 수업 시간에 직접 활용해보세요. 전문적인 단어와 문장은 물론, 시사 지식까지 배울 수 있어요.`,
      `Pick up words and phrases from a wide range of topics and fields and try applying them during the lesson. Learn professional words and phrases, and even update yourself on trending issues.`,
      'n_first_guide.material.c_content',
      `Pick up words and phrases from a wide range of topics and fields and try applying them during the lesson. Learn professional words and phrases, and even update yourself on trending issues.`,
      `Pick up words and phrases from a wide range of topics and fields and try applying them during the lesson. Learn professional words and phrases, and even update yourself on trending issues.`,
      `Pick up words and phrases from a wide range of topics and fields and try applying them during the lesson. Learn professional words and phrases, and even update yourself on trending issues.`,
      ``,
    ],
    a_subtitle: [
      `정확한 문법 사용에 도움을 줄 블로그`,
      `Blog posts on how to use correct grammar`,
      'n_first_guide.material.a_subtitle',
      `Blog posts on how to use correct grammar`,
      `Blog posts on how to use correct grammar`,
      `Blog posts on how to use correct grammar`,
      ``,
    ],
    a_title: [
      <>
        정확한 영어를 위한
        <br />
        <span className="text-blue-600">영어 학습 방법</span>을 알려드려요.
      </>,
      <>
        Learn how to study
        <br />
        <span className="text-blue-600">correct English</span>.
      </>,
      'n_first_guide.material.a_title',
      <>
        Learn how to study
        <br />
        <span className="text-blue-600">correct English</span>.
      </>,
      <>
        Learn how to study
        <br />
        <span className="text-blue-600">correct English</span>.
      </>,
      <>
        Learn how to study
        <br />
        <span className="text-blue-600">correct English</span>.
      </>,
      ``,
    ],
    a_content: [
      `원어민처럼 자연스럽고, 정확한 영어를 하기 위해서는 자주 틀리는 영어 실수를 정복할 필요가 있어요. 링글에서 정리한 영어 공부 방법을 읽고 적용해보세요.`,
      `To speak natural, correct English like a native speaker, you need to conquer mistakes you make often. Read and apply Ringle's tips for studying English.`,
      'n_first_guide.material.a_content',
      `To speak natural, correct English like a native speaker, you need to conquer mistakes you make often. Read and apply Ringle's tips for studying English.`,
      `To speak natural, correct English like a native speaker, you need to conquer mistakes you make often. Read and apply Ringle's tips for studying English.`,
      `To speak natural, correct English like a native speaker, you need to conquer mistakes you make often. Read and apply Ringle's tips for studying English.`,
      ``,
    ],
    f_subtitle: [
      `나를 수다쟁이로 만들어 줄 교재`,
      `Materials that inspires you to talk`,
      'n_first_guide.material.f_subtitle',
      `Materials that inspires you to talk`,
      `Materials that inspires you to talk`,
      `Materials that inspires you to talk`,
      ``,
    ],
    f_title: [
      <>
        <span className="text-blue-600">말할 거리가 많은</span>
        <br />
        교재를 활용해보세요.
      </>,
      <>
        Choose materials that <br />
        give you <span className="text-blue-600">lots to talk about</span>.
      </>,
      'n_first_guide.material.f_title',
      <>
        Choose materials that <br />
        give you <span className="text-blue-600">lots to talk about</span>.
      </>,
      <>
        Choose materials that <br />
        give you <span className="text-blue-600">lots to talk about</span>.
      </>,
      <>
        Choose materials that <br />
        give you <span className="text-blue-600">lots to talk about</span>.
      </>,
      ``,
    ],
    f_content: [
      `링글에는 자체 제작한 1,000개 이상의 교재가 준비되어 있습니다. 내가 가장 좋아하는 주제를 골라, 끊임없이 대화해 보세요.`,
      `Ringle offers more than 1,000 original materials to choose from. Pick your favorite topics and start talking non-stop.`,
      'n_first_guide.material.f_content',
      `Ringle offers more than 1,000 original materials to choose from. Pick your favorite topics and start talking non-stop.`,
      `Ringle offers more than 1,000 original materials to choose from. Pick your favorite topics and start talking non-stop.`,
      `Ringle offers more than 1,000 original materials to choose from. Pick your favorite topics and start talking non-stop.`,
      ``,
    ],
  },
  change: {
    text_1: [
      <>
        <span className="text-purple-200">똑똑한 원어민</span>과<br />
        <span className="text-purple-300">좋은 주제</span>로<br />
        <span className="text-purple-400">꾸준히 연습</span>하면
        <br />
        <span className="text-white">영어실력은 늡니다.</span>
      </>,
      <>
        <span className="text-purple-200">Smart native speakers</span>
        <br />
        <span className="text-purple-300">Great materials</span>
        <br />
        <span className="text-purple-400">Consistent practice</span>
        <br />
        <span className="text-white">Find it all on Ringle.</span>
      </>,
      'n_first_guide.change.text_1',
      <>
        <span className="text-purple-200">Smart native speakers</span>
        <br />
        <span className="text-purple-300">Great materials</span>
        <br />
        <span className="text-purple-400">Consistent practice</span>
        <br />
        <span className="text-white">Find it all on Ringle.</span>
      </>,
      <>
        <span className="text-purple-200">Smart native speakers</span>
        <br />
        <span className="text-purple-300">Great materials</span>
        <br />
        <span className="text-purple-400">Consistent practice</span>
        <br />
        <span className="text-white">Find it all on Ringle.</span>
      </>,
      <>
        <span className="text-purple-200">Smart native speakers</span>
        <br />
        <span className="text-purple-300">Great materials</span>
        <br />
        <span className="text-purple-400">Consistent practice</span>
        <br />
        <span className="text-white">Find it all on Ringle.</span>
      </>,
      ``,
    ],
    text_2: [
      <>
        <span className="Headline2-24Bd">내가 꿈꾸는 영어 실력</span>
        <span className="text-white Hero4-32Bd">링글 하나로 완벽하게</span>
      </>,
      <>
        <span className="Headline2-24Bd">Perfect your English.</span>
        <span className="text-white Hero4-32Bd">Ringle is All You Need.</span>
      </>,
      'n_first_guide.change.text_2',
      <>
        <span className="Headline2-24Bd">Perfect your English.</span>
        <span className="text-white Hero4-32Bd">Ringle is All You Need.</span>
      </>,
      <>
        <span className="Headline2-24Bd">Perfect your English.</span>
        <span className="text-white Hero4-32Bd">Ringle is All You Need.</span>
      </>,
      <>
        <span className="Headline2-24Bd">Perfect your English.</span>
        <span className="text-white Hero4-32Bd">Ringle is All You Need.</span>
      </>,
      ``,
    ],
    btn: [
      `수업권 구매하기`,
      `Buy credits`,
      'n_first_guide.change.btn',
      `Buy credits`,
      `Buy credits`,
      `Buy credits`,
      ``,
    ],
  },
  floating: {
    title: [
      `최대 혜택 받고 링글 시작하기`,
      `Start Ringle with maximum benefits`,
      'n_first_guide.floating.title',
      `Start Ringle with maximum benefits`,
      `Start Ringle with maximum benefits`,
      `Start Ringle with maximum benefits`,
      ``,
    ],
    subtitle: [
      `6개월 무이자 지원`,
      `0% interest 6-month plan`,
      'n_first_guide.floating.subtitle',
      `0% interest 6-month plan`,
      `0% interest 6-month plan`,
      `0% interest 6-month plan`,
      ``,
    ],
  },
  change_text: {
    title: (name) => [
      <>
        영어 실력을 확실히 늘릴 수 있도록
        <br />
        <span className="text-purple-500">맞춤형 학습 방법</span>을 알려드릴게요.
      </>,
      <>
        {name}, find out
        <br />
        what works best for you
        <br />
        to improve your English.
      </>,
      'n_first_guide.change_text.title',
      <>
        {name}, find out
        <br />
        what works best for you
        <br />
        to improve your English.
      </>,
      <>
        {name}, find out
        <br />
        what works best for you
        <br />
        to improve your English.
      </>,
      <>
        {name}, find out
        <br />
        what works best for you
        <br />
        to improve your English.
      </>,
      ``,
    ],
  },
  toast: {
    click_caf: [
      `각 항목에 대한 자세한 내용은 지난 수업 > AI 분석에서 확인할 수 있어요.`,
      ``,
      'n_first_guide.toast.click_caf',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const cancel = {
  weekly_prior_24: [
    `해당 주차에 수업을 듣지 못하면 매주 수업 듣기 수업권이 차감되니 꼭 수업을 예약해주세요!`,
    `Book a lesson for the week to avoid losing an Every Week credit!`,
    'cancel.weekly_prior_24',
    `若没有在该週完成课程，课程券将被扣除，请记得预约课程！`,
    `若沒有在該週完成課程，課程券將被扣除，
請記得預約課程！`,
    `その週に授業を受けられない場合も毎週受講のクレジットが差し引かれますので、必ず同じ週に予約をしてください！`,
    ``,
  ],
  weekly_prior_24_2: [
    `수업 취소로 수업권이 차감되어, 해당 주차에 1회 이상 수업을 듣지 못해도 수업권이 추가로 차감되지 않습니다.`,
    `An Every Week credit has been deducted due to cancellation. (No more credits will be lost even if you don't take any lessons.)`,
    'cancel.weekly_prior_24_2',
    `该週课程券若已被扣除，即使该週没有上课，也不会再扣除课程券。`,
    `該週課程券若已被扣除，即使該週沒有上課，
也不會再扣除課程券。`,
    `毎週受講クレジットが差し引かれた場合は、その週にレッスンが受講できなかったとしても、それ以上クレジットが差し引かれることはありません。`,
    ``,
  ],
}
export const n_bonus_point = {
  popup: {
    subtitle: [
      `이제 다음 수업권을 구매할 타이밍`,
      `Time to buy the next lesson credits`,
      'n_bonus_point.popup.subtitle',
      `该买下一个课程券了`,
      `該買下一張課程券了`,
      `次のレッスンクレジットを購入する時です`,
      ``,
    ],
    subtitle_2: [
      `링글 체험수업 완료!`,
      `Trial lesson completed!`,
      'n_bonus_point.popup.subtitle_2',
      `试听课程完成！`,
      `試聽課程完成！`,
      `体験レッスン完了！`,
      ``,
    ],
    title: (name) => [
      <>
        열심히 공부한 {name}님께
        <br />
        포인트 선물 도착!
      </>,
      <>
        Get instantly redeemable points
        <br />
        as a reward for your hard work!
      </>,
      'n_bonus_point.popup.title',
      <>
        为了努力学习的{name}
        <br />
        送来礼物!
      </>,
      <>
        為了努力學習的{name}
        <br />
        送來禮物!
      </>,
      <>
        一生懸命勉強したご褒美として
        <br />
        すぐに使えるポイントを獲得しましょう！
      </>,
      ``,
    ],
    caption: [
      `단 7일 간!`,
      `For 7 days only!`,
      'n_bonus_point.popup.caption',
      `只有7天!`,
      `只有7天!`,
      `7日間のみ有効！`,
      ``,
    ],
    caption_24hours: [
      <>
        <span className="text-[#503CC8]">24시간 동안만 10,000원 추가 할인받을 수 있어요!</span>
      </>,
      <>
        <span className="text-[#503CC8]">You can get an additional $7.69 discount for only 24 hours!</span>
      </>,
      'n_bonus_point.popup.caption_24hours',
      <>
        <span className="text-[#503CC8]">仅限24小时内可以获得额外$7.69的折扣！</span>
      </>,
      <>
        <span className="text-[#503CC8]">僅限24小時內可以獲得額外$7.69的折扣！</span>
      </>,
      <>
        <span className="text-[#503CC8]">24時間限定で$7.69の追加割引を受けられます！</span>
      </>,
      ``,
    ],
    list_1: [
      <>
        10만원 이상 수업권 결제 시 <span className="text-[#503CC8]">20,000 포인트</span> 할인
      </>,
      <>
        <span className="text-[#503CC8]">$15.38 points</span> for purchases over $76.92
      </>,
      'n_bonus_point.popup.list_1',
      <>
        购买USD76.92以上课程券<span className="text-[#503CC8]">将获得USD15.38积分折扣</span>
      </>,
      <>
        購買USD76.92以上課程券<span className="text-[#503CC8]">將獲得USD15.38點數折扣</span>
      </>,
      <>
        USD76.92以上の購入で<span className="text-[#503CC8]">USD15.38ポイント獲得</span>
      </>,
      ``,
    ],
    list_2: [
      <>
        30만원 이상 수업권 결제 시 <span className="text-[#503CC8]">30,000만 포인트</span> 할인
      </>,
      <>
        <span className="text-[#503CC8]">$23.07 points</span> for purchases over $230.76
      </>,
      'n_bonus_point.popup.list_2',
      <>
        购买USD230.76以上课程券<span className="text-[#503CC8]">将获得USD23.07积分折扣</span>
      </>,
      <>
        購買USD230.76以上課程券<span className="text-[#503CC8]">將獲得USD23.07點數折扣</span>
      </>,
      <>
        USD230.76以上の購入で<span className="text-[#503CC8]">USD23.07ポイント獲得</span>
      </>,
      ``,
    ],
    btn_1: [
      `지금 바로 사용하기`,
      `Use right away`,
      'n_bonus_point.popup.btn_1',
      `现在马上使用`,
      `現在馬上使用`,
      `今すぐ使う`,
      ``,
    ],
    btn_2: [`나중에 사용`, `Use later`, 'n_bonus_point.popup.btn_2', `下次使用`, `下次使用`, `あとで使う`, ``],
  },
  banner: {
    subtitle: [
      `지금 수업권 구매하면 포인트 할인`,
      `Buy credits with points discount`,
      'n_bonus_point.banner.subtitle',
      `现在购买的话就能获得积分折扣`,
      `現在購買的話就能獲得點數折扣`,
      `ポイントを使って購入する`,
      ``,
    ],
    title: [
      `최대 50,000P`,
      `Instantly redeem up to $38`,
      'n_bonus_point.banner.title',
      `最多 $38`,
      `最多 $38`,
      `最大 $38`,
      ``,
    ],
  },
}
export const n_faq = {
  title: [`FAQ`, `FAQ`, 'n_faq.title', `FAQ`, `FAQ`, `FAQ`, ``],
  page_title: [
    `자주 묻는 질문`,
    `Frequently Asked Questions`,
    'n_faq.page_title',
    `常见问题`,
    `常見問題`,
    `よくあるご質問`,
    ``,
  ],
}
export const n_guide = {
  title: [`가이드`, `Guide`, 'n_guide.title', `Guide`, `Guide`, `Guide`, ``],
  page_title: [
    `링글 가이드`,
    `How to Ringle`,
    'n_guide.page_title',
    `Ringle课程介绍`,
    `Ringle課程介紹`,
    `Ringleレッスンガイド`,
    ``,
  ],
}
export const n_new = {
  reward: {
    detail_status: {
      title: [
        `내 수업 현황`,
        `Lessons completed`,
        'n_new.reward.detail_status.title',
        `课程进度`,
        `課程進度`,
        `受講済みレッスン`,
        ``,
      ],
      day: (n) => [
        `종료까지`,
        <>{n} days left</>,
        'n_new.reward.detail_status.day',
        `剩下D-n`,
        `剩下D-n`,
        `残りn日`,
        ``,
      ],
      desc: [
        <>
          <li className="li-tag-inside">첫 결제 시점 기준으로 30일 이내입니다. (수업권 종류 상관 없음)</li> 
          <li className="li-tag-inside">학생과 튜터가 모두 입장한 정상 수업이어야 합니다.</li> 
          <li className="li-tag-inside">
            리워드로 지급된 AI 스피킹 파트너 이용권(20분)은 지급일로부터 14일동안 사용 가능합니다.
          </li>
        </>,
        <>
          <li className="li-tag-inside">
            You must complete 4 lessons within 30 days from the date of your first purchase. (Applies to lessons taken
            using any type of credit.)
          </li>
           
          <li className="li-tag-inside">Lessons must be completed normally with both the tutor and student present.</li>
           
          <li className="li-tag-inside">
            The AI Speaking Partner tickets (20 mins) given as reward can be used for 14 days from the date of
            provision.
          </li>
        </>,
        'n_new.reward.detail_status.desc',
        <>
          <li className="li-tag-inside">결购买后30天内 (所有种类课程券适用)</li> 
          <li className="li-tag-inside">需确实和教师一起完成课程</li> 
          <li className="li-tag-inside">获得奖励的AI口说练习券(20分钟)使用期限为收到后14天内</li>
        </>,
        <>
          <li className="li-tag-inside">결購買後30天內 (所有種類課程券適用)</li> 
          <li className="li-tag-inside">需確實和教師一起完成課程</li> 
          <li className="li-tag-inside">獲得獎勵的AI口說練習券(20分鐘)使用期限為收到後14天內</li>
        </>,
        <>
          <li className="li-tag-inside">
            4回のレッスン受講を初回購入時より30日以内に完了しましょう(どの形態のレッスンクレジットにも適用されます)
          </li>
           
          <li className="li-tag-inside">
            レッスンは通常通りチューターとユーザーの両方が出席しているひつようがあります
          </li>
           
          <li className="li-tag-inside">
            特典として付与されたAI Speaking Partnerのチケット(20分用)は提供から14日以内に使用できます
          </li>
        </>,
        ``,
      ],
    },
    done: {
      tooltip: [`리워드 확인하기`, `Get reward`, 'n_new.reward.done.tooltip', `获得奖励`, `獲得獎勵`, `特典を得る`, ``],
    },
    detail: {
      subhead: [
        `공부 습관에 가장 중요한 첫 한 달!`,
        `Get started on the right path!`,
        'n_new.reward.detail.subhead',
        `开始有效学习！`,
        `開始有效學習！`,
        `正攻法で行きましょう！`,
        ``,
      ],
      title: [
        <>
          매주 영어 공부하는 습관을
          <br />
          만들어보세요
        </>,
        <>
          Build a habit of studying
          <br />
          English every week.
        </>,
        'n_new.reward.detail.title',
        <>
          培养每週上课习惯
          <br />
        </>,
        <>
          培養每週上課習慣
          <br />
        </>,
        <>
          毎週英語の学習をする
          <br />
          習慣を身につけましょう
        </>,
        ``,
      ],
      subtitle_1: [
        `수업 4회 완료하면`,
        `Complete 4 lessons and`,
        'n_new.reward.detail.subtitle_1',
        `只要完成4堂课`,
        `只要完成4堂課`,
        `4回レッスンを受講して`,
        ``,
      ],
      subtitle_2: [
        <>
          AI 스피킹 파트너
          <br />
          4회 이용권을 드려요.
        </>,
        <>
          Get 4 extra
          <br />
          AI Speaking Partner tickets.
        </>,
        'n_new.reward.detail.subtitle_2',
        <>
          就能获得4张
          <br />
          免费AI口说练习券
        </>,
        <>
          就能獲得4張
          <br />
          免費AI口說練習券
        </>,
        <>
          AI Speaking Partnerの
          <br />
          無料チケット4回分を追加で入手
        </>,
        ``,
      ],
    },
    detail_ai: {
      title: [
        `AI 스피킹 파트너`,
        `AI Speaking Partner`,
        'n_new.reward.detail_ai.title',
        `AI口说伙伴`,
        `AI口說夥伴`,
        `AI Speaking Partner`,
        ``,
      ],
      desc: [
        <>
          AI 스피킹 파트너 무료 이용권으로
          <br />
          한 달 동안 링글 튜터와 수업한 내용을
          <br />
          다시 연습해보세요
        </>,
        <>
          Practice what you learned
          <br />
          during your first month of
          <br />
          lessons with AI Speaking Partner.
        </>,
        'n_new.reward.detail_ai.desc',
        <>
          利用免费的AI口说练习券
          <br />
          和AI口说伙伴一起複习过去一个月
          <br />
          和教师进行的上课内容
        </>,
        <>
          利用免費的AI口說練習券
          <br />
          和AI口說夥伴一起複習過去一個月
          <br />
          和教師進行的上課內容
        </>,
        <>
          AI Speaking Partnerと
          <br />
          最初の1ヶ月のレッスンで学んだことを
          <br />
          練習してみましょう
        </>,
        ``,
      ],
      feature_1: [
        <>
          1:1 수업 전후로 대화하며
          <br />
          함께 예습/복습 할 수 있어요
        </>,
        <>
          Prep for or review the lesson
          <br />
          effectively through a 1:1 talk.
        </>,
        'n_new.reward.detail_ai.feature_1',
        <>
          进行1:1课程前/后和AI口说伙伴一起
          <br />
          进行预习/複习
        </>,
        <>
          進行1:1課程前/後和AI口說夥伴一起
          <br />
          進行預習/複習
        </>,
        <>
          1on1の会話を通じて、効果的な
          <br />
          レッスンの予習・復習をしましょう
        </>,
        ``,
      ],
      feature_2: [
        <>
          대화 나눌 주제를 직접
          <br />
          선택할 수 있어요
        </>,
        <>
          Talk about any topic you wish
          <br />
          from Ringle materials.
        </>,
        'n_new.reward.detail_ai.feature_2',
        <>
          自行选择
          <br />
          想要练习的主题教材
        </>,
        <>
          自行選擇
          <br />
          想要練習的主題教材
        </>,
        <>
          ご希望のトピックスについて
          <br />
          Ringleの教材で会話しましょう
        </>,
        ``,
      ],
      feature_3: [
        <>
          10분만 대화해도
          <br />
          AI 분석 리포트가 제공돼요
        </>,
        <>
          Receive AI analysis report
          <br />
          for even just a 10-minute talk.
        </>,
        'n_new.reward.detail_ai.feature_3',
        <>
          只要练习超过10分钟
          <br />
          就能获得AI分析报告
        </>,
        <>
          只要練習超過10分鐘
          <br />
          就能獲得AI分析報告
        </>,
        <>
          たった10分の会話でAI analysis
          <br />
          Reportを受け取りましょう
        </>,
        ``,
      ],
      caption: [
        `*링글 앱에서만 사용할 수 있습니다`,
        `*Available only on Ringle app.`,
        'n_new.reward.detail_ai.caption',
        `只能在Ringle APP上使用`,
        `只能在Ringle APP上使用`,
        `*Ringleアプリで飲み利用可能`,
        ``,
      ],
    },
    detail_onboarding: {
      title_1: [
        `아직 어떻게 수업해야 할지 고민이라면?`,
        `Not sure how to start Ringle lessons?`,
        'n_new.reward.detail_onboarding.title_1',
        `不知道怎麽开始Ringle课程吗？`,
        `不知道怎麼開始Ringle課程嗎？`,
        `レッスンの始め方がわかりませんか？`,
        ``,
      ],
      desc_1: [
        <>
          공부 목적을 선택하면 <span className="text-[#503CC8]">딱 맞는 튜터</span>와
          <span className="text-[#503CC8]">교재 코스</span>를 추천해 드려요. 영어 공부 목적에 맞게 차근차근 수업해
          보세요!
        </>,
        <>
          We'll pick out the <span className="text-[#503CC8]">perfect tutor</span> and
          <span className="text-[#503CC8]">materials</span> to realize your goal for learning English, one lesson at a
          time.
        </>,
        'n_new.reward.detail_onboarding.desc_1',
        <>
          只要选择学习目的<span className="text-[#503CC8]">我们将推荐适合的教师</span>和
          <span className="text-[#503CC8]">教材</span>
          朝着学习目标一步步迈进吧！
        </>,
        <>
          只要選擇學習目的<span className="text-[#503CC8]">我們將推薦適合的教師</span>和
          <span className="text-[#503CC8]">教材</span>
          朝著學習目標一步步邁進吧！
        </>,
        <>
          あなたの学習目的を達成するために、毎回のレッスンで<span className="text-[#503CC8]">最適なチューター</span>と
          <span className="text-[#503CC8]">教材</span>を選定します
        </>,
        ``,
      ],
      btn: [
        `영어 공부 목적 선택하기`,
        `Choose your learning goal`,
        'n_new.reward.detail_onboarding.btn',
        `选择学习目标`,
        `選擇學習目標`,
        `学習目的を選択する`,
        ``,
      ],
      title_2: [
        <>
          영어 공부 목적에 맞는 코스대로
          <br />
          차근차근 수업해 보세요!
        </>,
        <>
          Try out the Ringle course
          <br />
          curated for your learning goal!
        </>,
        'n_new.reward.detail_onboarding.title_2',
        <>
          在Ringle用适合的课程
          <br />
          朝学习目标一步步迈进！
        </>,
        <>
          在Ringle用適合的課程
          <br />
          朝學習目標一步步邁進！
        </>,
        <>
          学習目的に合わせて、厳選された
          <br />
          Ringleのコースを受講しませんか？
        </>,
        ``,
      ],
      desc_2: [
        <>
          수업 예약 후 <span className="text-[#503CC8]">교재 선택 페이지</span>에서
          <br />
          확인할 수 있어요.
        </>,
        <>
          Details are provided under <span className="text-[#503CC8]">Select Materials</span> after booking a lesson.
        </>,
        'n_new.reward.detail_onboarding.desc_2',
        <>
          预约课程后 <span className="text-[#503CC8]">可在选择教材页面确认</span>
        </>,
        <>
          預約課程後 <span className="text-[#503CC8]">可在選擇教材頁面確認</span>
        </>,
        <>
          レッスン予約後、<span className="text-[#503CC8]">「レッスン教材の選択」</span>の中に詳細が記載されています
        </>,
        ``,
      ],
    },
  },
}
export const n_mypage = {
  noti_ai_tutor: {
    title: [`AI 튜터`, `AI Tutor`, 'n_mypage.noti_ai_tutor.title', `AI Tutor`, `AI Tutor`, `AI Tutor`, ``],
    desc1: [
      `설정한 시간에 AI 튜터 리마인더 알림을 앱 푸시로 받을 수 있습니다.`,
      `Receive AI Tutor reminder app push notification at the scheduled time.`,
      'n_mypage.noti_ai_tutor.desc1',
      `Receive AI Tutor reminder app push notification at the scheduled time.`,
      `Receive AI Tutor reminder app push notification at the scheduled time.`,
      `Receive AI Tutor reminder app push notification at the scheduled time.`,
      ``,
    ],
  },
}
export const n_ai_tutor = {
  menu: {
    title: [`AI 튜터`, `AI Tutor`, 'n_ai_tutor.menu.title', `AI Tutor`, `AI Tutor`, `AI Tutor`, `AI Tutor`],
    subtitle1: [`학습`, `Study`, 'n_ai_tutor.menu.subtitle1', `Study`, `Study`, `Study`, `Study`],
    subtitle2: [
      `롤플레잉`,
      `Role Playing`,
      'n_ai_tutor.menu.subtitle2',
      `Role Playing`,
      `Role Playing`,
      `Role Playing`,
      `Role Playing`,
    ],
    subtitle3: [
      `디스커션`,
      `Discussion`,
      'n_ai_tutor.menu.subtitle3',
      `Discussion`,
      `Discussion`,
      `Discussion`,
      `Discussion`,
    ],
    subtitle4: [`달성`, `My Status`, 'n_ai_tutor.menu.subtitle4', `My Status`, `My Status`, `My Status`, `My Status`],
  },
  study: {
    course_title1: (job) => [
      `${job} 직군 맞춤 코스`,
      `${job} related English`,
      'n_ai_tutor.study.course_title1',
      `${job} related English`,
      `${job} related English`,
      `${job} related English`,
      `${job} related English`,
    ],
    course_title2: [
      `직장인 필수 영어 코스`,
      `Essential business English`,
      'n_ai_tutor.study.course_title2',
      `Essential business English`,
      `Essential business English`,
      `Essential business English`,
      `Essential business English`,
    ],
    msg_roleplay: [
      `같은 주제로 AI와 롤플레잉을 해보세요`,
      `Try role playing with AI Tutor on this topic.`,
      'n_ai_tutor.study.msg_roleplay',
      `Try role playing with AI Tutor on this topic.`,
      `Try role playing with AI Tutor on this topic.`,
      `Try role playing with AI Tutor on this topic.`,
      `Try role playing with AI Tutor on this topic.`,
    ],
    msg_next_course: [
      `다음 코스를 시작하세요`,
      `Let's start the next course.`,
      'n_ai_tutor.study.msg_next_course',
      `Let's start the next course.`,
      `Let's start the next course.`,
      `Let's start the next course.`,
      `Let's start the next course.`,
    ],
    start_btn: [`시작하기`, `Start`, 'n_ai_tutor.study.start_btn', `Start`, `Start`, `Start`, `Start`],
    see_all_course_btn: [
      `코스 전체보기`,
      `View all courses`,
      'n_ai_tutor.study.see_all_course_btn',
      `View all courses`,
      `View all courses`,
      `View all courses`,
      `View all courses`,
    ],
    continue_btn: [`이어하기`, `Resume`, 'n_ai_tutor.study.continue_btn', `Resume`, `Resume`, `Resume`, `Resume`],
    again_btn: [`다시하기`, `Redo`, 'n_ai_tutor.study.again_btn', `Redo`, `Redo`, `Redo`, `Redo`],
    ongoing_label: [
      `진행중인 코스`,
      `Ongoing`,
      'n_ai_tutor.study.ongoing_label',
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
    ],
    start_conversation_btn: [
      `대화 시작`,
      `Talk with AI Tutor`,
      'n_ai_tutor.study.start_conversation_btn',
      `Talk with AI Tutor`,
      `Talk with AI Tutor`,
      `Talk with AI Tutor`,
      `Talk with AI Tutor`,
    ],
  },
  course: {
    onboarding_question4: [
      `직업을 선택해 주세요.`,
      `Please select your job/industry.`,
      'n_ai_tutor.course.onboarding_question4',
      `Please select your job/industry.`,
      `Please select your job/industry.`,
      `Please select your job/industry.`,
      `Please select your job/industry.`,
    ],
    filter_all: [`전체`, `All`, 'n_ai_tutor.course.filter_all', `All`, `All`, `All`, `All`],
    category1: [
      `직장인 필수 영어`,
      `Essential Business English`,
      'n_ai_tutor.course.category1',
      `Essential Business English`,
      `Essential Business English`,
      `Essential Business English`,
      `Essential Business English`,
    ],
    category2: [
      `경영/재무/회계/인사`,
      `Management/Finance/HR`,
      'n_ai_tutor.course.category2',
      `Management/Finance/HR`,
      `Management/Finance/HR`,
      `Management/Finance/HR`,
      `Management/Finance/HR`,
    ],
    category3: [
      `마케팅/상품기획`,
      `Marketing/Planning`,
      'n_ai_tutor.course.category3',
      `Marketing/Planning`,
      `Marketing/Planning`,
      `Marketing/Planning`,
      `Marketing/Planning`,
    ],
    category4: [`영업`, `Sales`, 'n_ai_tutor.course.category4', `Sales`, `Sales`, `Sales`, `Sales`],
    category5: [
      `연구개발/생산`,
      `R&D/Production`,
      'n_ai_tutor.course.category5',
      `R&D/Production`,
      `R&D/Production`,
      `R&D/Production`,
      `R&D/Production`,
    ],
    category6: [
      `PM/UX/개발`,
      `PM/UX/Development`,
      'n_ai_tutor.course.category6',
      `PM/UX/Development`,
      `PM/UX/Development`,
      `PM/UX/Development`,
      `PM/UX/Development`,
    ],
    category7: [
      `의료인`,
      `Medical professional [`,
      'n_ai_tutor.course.category7',
      `Medical professional [`,
      `Medical professional [`,
      `Medical professional [`,
      `Medical professional [`,
    ],
    category8: [`시작하기`, `Basics`, 'n_ai_tutor.course.category8', `Basics`, `Basics`, `Basics`, `Basics`],
    category9: [
      `법무/법조인`,
      `Legal professional`,
      'n_ai_tutor.course.category9',
      `Legal professional`,
      `Legal professional`,
      `Legal professional`,
      `Legal professional`,
    ],
    category10: [
      `연구원/교수/대학(원)생`,
      `Researchers/Academics`,
      'n_ai_tutor.course.category10',
      `Researchers/Academics`,
      `Researchers/Academics`,
      `Researchers/Academics`,
      `Researchers/Academics`,
    ],
    select_btn: [`선택`, `Choose`, 'n_ai_tutor.course.select_btn', `Choose`, `Choose`, `Choose`, `Choose`],
  },
  roleplay: {
    custom_topic: [`맞춤 주제`, `Topics`, 'n_ai_tutor.roleplay.custom_topic', `Topics`, `Topics`, `Topics`, `Topics`],
    trending_topic: [
      `인기 주제`,
      `Popular Topics`,
      'n_ai_tutor.roleplay.trending_topic',
      `Popular Topics`,
      `Popular Topics`,
      `Popular Topics`,
      `Popular Topics`,
    ],
    toast1: [
      `저장한 롤프레잉에 추가했어요.`,
      `Added to My Roleplays`,
      'n_ai_tutor.roleplay.toast1',
      `Added to My Roleplays`,
      `Added to My Roleplays`,
      `Added to My Roleplays`,
      `Added to My Roleplays`,
    ],
    toast2: [
      `저장한 롤프레잉에서 삭제했어요.`,
      `Deleted from My Roleplays`,
      'n_ai_tutor.roleplay.toast2',
      `Deleted from My Roleplays`,
      `Deleted from My Roleplays`,
      `Deleted from My Roleplays`,
      `Deleted from My Roleplays`,
    ],
  },
  acheivement: {
    streak: [
      `연속 학습`,
      `Study streak`,
      'n_ai_tutor.acheivement.streak',
      `Study streak`,
      `Study streak`,
      `Study streak`,
      `Study streak`,
    ],
    day: (day) => [
      `${day}일`,
      `Day ${day}`,
      'n_ai_tutor.acheivement.day',
      `Day ${day}`,
      `Day ${day}`,
      `Day ${day}`,
      `Day ${day}`,
    ],
    msg1: [
      `오늘의 학습을 달성하세요!`,
      `Complete today's AI study!`,
      'n_ai_tutor.acheivement.msg1',
      `Complete today's AI study!`,
      `Complete today's AI study!`,
      `Complete today's AI study!`,
      `Complete today's AI study!`,
    ],
    msg2: [
      `오늘의 학습을 달성했어요!`,
      `Today's AI study completed!`,
      'n_ai_tutor.acheivement.msg2',
      `Today's AI study completed!`,
      `Today's AI study completed!`,
      `Today's AI study completed!`,
      `Today's AI study completed!`,
    ],
    msg3: [
      `영어 실력을 키우기 위해서는 꾸준한 학습이 중요해요. 새로운 표현을 배우고 롤플레잉이나 디스커션 대화로 연습해보세요!`,
      `Consistency is key to improving your English! Practice new expressions you learned by role-playing or holding discussions with AI Tutor!`,
      'n_ai_tutor.acheivement.msg3',
      `Consistency is key to improving your English! Practice new expressions you learned by role-playing or holding discussions with AI Tutor!`,
      `Consistency is key to improving your English! Practice new expressions you learned by role-playing or holding discussions with AI Tutor!`,
      `Consistency is key to improving your English! Practice new expressions you learned by role-playing or holding discussions with AI Tutor!`,
      `Consistency is key to improving your English! Practice new expressions you learned by role-playing or holding discussions with AI Tutor!`,
    ],
    streak_challenge: [
      `연속 학습 챌린지`,
      `Study Streak Challenge`,
      'n_ai_tutor.acheivement.streak_challenge',
      `Study Streak Challenge`,
      `Study Streak Challenge`,
      `Study Streak Challenge`,
      `Study Streak Challenge`,
    ],
    ai_analysis: [
      `AI 분석`,
      `AI Analysis`,
      'n_ai_tutor.acheivement.ai_analysis',
      `AI Analysis`,
      `AI Analysis`,
      `AI Analysis`,
      `AI Analysis`,
    ],
    msg4: [
      `내 영어는 상위 몇 프로일까?`,
      `How good is my English?`,
      'n_ai_tutor.acheivement.msg4',
      `How good is my English?`,
      `How good is my English?`,
      `How good is my English?`,
      `How good is my English?`,
    ],
    msg5: [
      `나와 같은 직군의 사람들과 영어 실력을 비교해보세요.`,
      `Check how your English ranks against others in your industry.`,
      'n_ai_tutor.acheivement.msg5',
      `Check how your English ranks against others in your industry.`,
      `Check how your English ranks against others in your industry.`,
      `Check how your English ranks against others in your industry.`,
      `Check how your English ranks against others in your industry.`,
    ],
    btn1: [
      `AI와 대화 나누고 분석 받기`,
      `Talk with AI Tutor and find out`,
      'n_ai_tutor.acheivement.btn1',
      `Talk with AI Tutor and find out`,
      `Talk with AI Tutor and find out`,
      `Talk with AI Tutor and find out`,
      `Talk with AI Tutor and find out`,
    ],
    msg6: (job_category) => [
      <>
        <span className="text-purple-500">{job_category}</span> 직군 내 다른 사람들과 비교한 나의 영어 실력이에요.
      </>,
      <>
        Below is how your English ranks against others in <span className="text-purple-500">{job_category}</span>.
      </>,
      'n_ai_tutor.acheivement.msg6',
      <>
        Below is how your English ranks against others in <span className="text-purple-500">{job_category}</span>.
      </>,
      <>
        Below is how your English ranks against others in <span className="text-purple-500">{job_category}</span>.
      </>,
      <>
        Below is how your English ranks against others in <span className="text-purple-500">{job_category}</span>.
      </>,
      <>
        Below is how your English ranks against others in <span className="text-purple-500">{job_category}</span>.
      </>,
    ],
    btn2: [
      `자세히 보기`,
      `View details`,
      'n_ai_tutor.acheivement.btn2',
      `View details`,
      `View details`,
      `View details`,
      `View details`,
    ],
    popup_title: [
      `아직 AI 분석 기록이 없어요.`,
      `No record of AI analysis.`,
      'n_ai_tutor.acheivement.popup_title',
      `No record of AI analysis.`,
      `No record of AI analysis.`,
      `No record of AI analysis.`,
      `No record of AI analysis.`,
    ],
    popup_body1: [
      `직군별 영어 실력을 조회하려면 AI 분석 결과가 필요해요. 10턴 (약 10분 소요) 이상 진행한 대화의 AI 분석을 요청한 후 조회하세요.`,
      `We need AI analysis reports to rank your English against others in your industry. Please run AI analysis on one of your talks with AI Tutor (should be at least 10 turns / about 10 mins long) and try again.`,
      'n_ai_tutor.acheivement.popup_body1',
      `We need AI analysis reports to rank your English against others in your industry. Please run AI analysis on one of your talks with AI Tutor (should be at least 10 turns / about 10 mins long) and try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please run AI analysis on one of your talks with AI Tutor (should be at least 10 turns / about 10 mins long) and try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please run AI analysis on one of your talks with AI Tutor (should be at least 10 turns / about 10 mins long) and try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please run AI analysis on one of your talks with AI Tutor (should be at least 10 turns / about 10 mins long) and try again.`,
    ],
    popup_body2: [
      `직군별 영어 실력을 조회하려면 AI 분석 결과가 필요해요. 10턴 (약 10분 소요) 이상 대화를 나누고 AI 분석을 요청하세요.`,
      `We need AI analysis reports to rank your English against others in your industry. Please have a talk with AI Tutor for at least 10 turns (about 10 mins long), run AI analysis, then try again.`,
      'n_ai_tutor.acheivement.popup_body2',
      `We need AI analysis reports to rank your English against others in your industry. Please have a talk with AI Tutor for at least 10 turns (about 10 mins long), run AI analysis, then try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please have a talk with AI Tutor for at least 10 turns (about 10 mins long), run AI analysis, then try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please have a talk with AI Tutor for at least 10 turns (about 10 mins long), run AI analysis, then try again.`,
      `We need AI analysis reports to rank your English against others in your industry. Please have a talk with AI Tutor for at least 10 turns (about 10 mins long), run AI analysis, then try again.`,
    ],
    popup_body3: [
      `직군별 영어 실력을 조회하려면 AI 분석 결과가 필요해요. AI와 대화를 나누고 자신의 영어 실력을 확인하세요.`,
      `We need AI analysis reports to rank your English against others in your industry. Have a talk with AI Tutor to find out where your English stands.`,
      'n_ai_tutor.acheivement.popup_body3',
      `We need AI analysis reports to rank your English against others in your industry. Have a talk with AI Tutor to find out where your English stands.`,
      `We need AI analysis reports to rank your English against others in your industry. Have a talk with AI Tutor to find out where your English stands.`,
      `We need AI analysis reports to rank your English against others in your industry. Have a talk with AI Tutor to find out where your English stands.`,
      `We need AI analysis reports to rank your English against others in your industry. Have a talk with AI Tutor to find out where your English stands.`,
    ],
    btn3: [`대화 목록`, `Talk list`, 'n_ai_tutor.acheivement.btn3', `Talk list`, `Talk list`, `Talk list`, `Talk list`],
    btn4: [
      `롤플레잉 하기`,
      `Talk with AI`,
      'n_ai_tutor.acheivement.btn4',
      `Talk with AI`,
      `Talk with AI`,
      `Talk with AI`,
      `Talk with AI`,
    ],
    btn5: [
      `멤버십 구매`,
      `Buy membership`,
      'n_ai_tutor.acheivement.btn5',
      `Buy membership`,
      `Buy membership`,
      `Buy membership`,
      `Buy membership`,
    ],
    btn6: [
      `결과 보기`,
      `See results`,
      'n_ai_tutor.acheivement.btn6',
      `See results`,
      `See results`,
      `See results`,
      `See results`,
    ],
  },
  onboarding: {
    popup_title1: [
      `초/중/고 학생이신가요?`,
      `Are you an elementary/middle/high school student?`,
      'n_ai_tutor.onboarding.popup_title1',
      `Are you an elementary/middle/high school student?`,
      `Are you an elementary/middle/high school student?`,
      `Are you an elementary/middle/high school student?`,
      `Are you an elementary/middle/high school student?`,
    ],
    popup_body1: [
      `초/중/고 학생 위한 영미 명문대 출신 원어민과 1:1 화상영어 서비스 링글 틴즈를 확인해보세요.`,
      `Try out Ringle Teens for 1:1 online lessons with top university native speakers, tailored for young students.`,
      'n_ai_tutor.onboarding.popup_body1',
      `Try out Ringle Teens for 1:1 online lessons with top university native speakers, tailored for young students.`,
      `Try out Ringle Teens for 1:1 online lessons with top university native speakers, tailored for young students.`,
      `Try out Ringle Teens for 1:1 online lessons with top university native speakers, tailored for young students.`,
      `Try out Ringle Teens for 1:1 online lessons with top university native speakers, tailored for young students.`,
    ],
    popup_btn1: [
      `링글 틴즈로 가기`,
      `Go to Ringle Teens`,
      'n_ai_tutor.onboarding.popup_btn1',
      `Go to Ringle Teens`,
      `Go to Ringle Teens`,
      `Go to Ringle Teens`,
      `Go to Ringle Teens`,
    ],
  },
  study_activity: {
    popup_title: [
      `학습을 그만할까요?`,
      `Quit the study?`,
      'n_ai_tutor.study_activity.popup_title',
      `Quit the study?`,
      `Quit the study?`,
      `Quit the study?`,
      ``,
    ],
    popup_body: [
      `언제든지 돌아와 학습을 이어할 수 있어요.`,
      `You can always come back and resume the study.`,
      'n_ai_tutor.study_activity.popup_body',
      `You can always come back and resume the study.`,
      `You can always come back and resume the study.`,
      `You can always come back and resume the study.`,
      ``,
    ],
    popup_btn1: [`학습 계속하기`, `Resume`, 'n_ai_tutor.study_activity.popup_btn1', `Resume`, `Resume`, `Resume`, ``],
    popup_btn2: [`그만하기`, `Quit`, 'n_ai_tutor.study_activity.popup_btn2', `Quit`, `Quit`, `Quit`, ``],
    label1: [
      `핵심 표현`,
      `Key expression`,
      'n_ai_tutor.study_activity.label1',
      `Key expression`,
      `Key expression`,
      `Key expression`,
      ``,
    ],
    label2: [`예시`, `Example`, 'n_ai_tutor.study_activity.label2', `Example`, `Example`, `Example`, ``],
    two_title: [
      `문장을 듣고 말해보세요.`,
      `Listen and repeat the sentence.`,
      'n_ai_tutor.study_activity.two_title',
      `Listen and repeat the sentence.`,
      `Listen and repeat the sentence.`,
      `Listen and repeat the sentence.`,
      ``,
    ],
    listen_btn: [`문장 듣기`, `Listen`, 'n_ai_tutor.study_activity.listen_btn', `Listen`, `Listen`, `Listen`, ``],
    detail1: [
      `마이크를 눌러 말해주세요.`,
      `Press the mic and speak.`,
      'n_ai_tutor.study_activity.detail1',
      `Press the mic and speak.`,
      `Press the mic and speak.`,
      `Press the mic and speak.`,
      ``,
    ],
    fb_title1: [
      `잘했어요!`,
      `Good job!`,
      'n_ai_tutor.study_activity.fb_title1',
      `Good job!`,
      `Good job!`,
      `Good job!`,
      ``,
    ],
    fb_body1: [
      `다음 학습 활동을 진행해보세요.`,
      `Let's move on to the next study activity.`,
      'n_ai_tutor.study_activity.fb_body1',
      `Let's move on to the next study activity.`,
      `Let's move on to the next study activity.`,
      `Let's move on to the next study activity.`,
      ``,
    ],
    continue_btn: [`다음`, `Next`, 'n_ai_tutor.study_activity.continue_btn', `Next`, `Next`, `Next`, ``],
    fb_title2: [`다시 시도하기`, `Redo`, 'n_ai_tutor.study_activity.fb_title2', `Redo`, `Redo`, `Redo`, ``],
    fb_body2: [
      `문장을 다시 듣고 더 천천히 또렷하게 발음해보세요.`,
      `Listen again and try reading it more slowly and clearly.`,
      'n_ai_tutor.study_activity.fb_body2',
      `Listen again and try reading it more slowly and clearly.`,
      `Listen again and try reading it more slowly and clearly.`,
      `Listen again and try reading it more slowly and clearly.`,
      ``,
    ],
    again_btn: [`다시하기`, `Redo`, 'n_ai_tutor.study_activity.again_btn', `Redo`, `Redo`, `Redo`, ``],
    fb_body3: [
      `나중에 다시 보여드릴게요.`,
      `We'll show this to you again later.`,
      'n_ai_tutor.study_activity.fb_body3',
      `We'll show this to you again later.`,
      `We'll show this to you again later.`,
      `We'll show this to you again later.`,
      ``,
    ],
    three_title: [
      `빈칸에 들어갈 단어를 선택해주세요.`,
      `Choose the right word for the sentence.`,
      'n_ai_tutor.study_activity.three_title',
      `Choose the right word for the sentence.`,
      `Choose the right word for the sentence.`,
      `Choose the right word for the sentence.`,
      ``,
    ],
    four_title: [
      `빈칸을 채워서 말해보세요.`,
      `Try saying the sentence from memory.`,
      'n_ai_tutor.study_activity.four_title',
      `Try saying the sentence from memory.`,
      `Try saying the sentence from memory.`,
      `Try saying the sentence from memory.`,
      ``,
    ],
    five_title: [
      `상황에 맞는 문장을 골라주세요.`,
      `Choose the right sentence to say in this situation.`,
      'n_ai_tutor.study_activity.five_title',
      `Choose the right sentence to say in this situation.`,
      `Choose the right sentence to say in this situation.`,
      `Choose the right sentence to say in this situation.`,
      ``,
    ],
    six_title: [
      `학습한 표현을 연습해보세요.`,
      `Practice the expression you learned.`,
      'n_ai_tutor.study_activity.six_title',
      `Practice the expression you learned.`,
      `Practice the expression you learned.`,
      `Practice the expression you learned.`,
      ``,
    ],
    label3: [`상황 설명`, `Situation`, 'n_ai_tutor.study_activity.label3', `Situation`, `Situation`, `Situation`, ``],
    fb_title3: [
      `정답이 아니에요.`,
      `Incorrect`,
      'n_ai_tutor.study_activity.fb_title3',
      `Incorrect`,
      `Incorrect`,
      `Incorrect`,
      ``,
    ],
    fb_body4: [`정답`, `Answer`, 'n_ai_tutor.study_activity.fb_body4', `Answer`, `Answer`, `Answer`, ``],
    fb_body5: [
      `틀린 문제는 나중에 다시 보여드릴게요.`,
      `This activity will be shown again later.`,
      'n_ai_tutor.study_activity.fb_body5',
      `This activity will be shown again later.`,
      `This activity will be shown again later.`,
      `This activity will be shown again later.`,
      ``,
    ],
    label4: [
      `다시 풀어보기`,
      `Try again`,
      'n_ai_tutor.study_activity.label4',
      `Try again`,
      `Try again`,
      `Try again`,
      ``,
    ],
    detail2: [
      `마이크를 눌러 답변해주세요.`,
      `Please press to respond.`,
      'n_ai_tutor.study_activity.detail2',
      `Please press to respond.`,
      `Please press to respond.`,
      `Please press to respond.`,
      ``,
    ],
    fb_body6: [
      `롤플레잉 액티비티를 완료했어요.`,
      `You have finished the role-playing activity.`,
      'n_ai_tutor.study_activity.fb_body6',
      `You have finished the role-playing activity.`,
      `You have finished the role-playing activity.`,
      `You have finished the role-playing activity.`,
      ``,
    ],
    done_btn: [`완료`, `Okay`, 'n_ai_tutor.study_activity.done_btn', `Okay`, `Okay`, `Okay`, ``],
    complete_title: [
      `학습을 모두 완료했어요!`,
      `You completed this AI Study!`,
      'n_ai_tutor.study_activity.complete_title',
      `You completed this AI Study!`,
      `You completed this AI Study!`,
      `You completed this AI Study!`,
      ``,
    ],
    complete_detail: [
      `계속해서 학습을 이어가세요.`,
      `Let's continue studying.`,
      'n_ai_tutor.study_activity.complete_detail',
      `Let's continue studying.`,
      `Let's continue studying.`,
      `Let's continue studying.`,
      ``,
    ],
    timespent: [
      `학습 소요 시간`,
      `Time spent`,
      'n_ai_tutor.study_activity.timespent',
      `Time spent`,
      `Time spent`,
      `Time spent`,
      ``,
    ],
    count_study: [
      `지금까지 학습한 표현`,
      `Expressions learned`,
      'n_ai_tutor.study_activity.count_study',
      `Expressions learned`,
      `Expressions learned`,
      `Expressions learned`,
      ``,
    ],
    key_espression: [
      `핵심 표현`,
      `Key expression`,
      'n_ai_tutor.study_activity.key_espression',
      `Key expression`,
      `Key expression`,
      `Key expression`,
      ``,
    ],
    roleplay_btn: [
      `롤플레잉으로 표현 연습하기`,
      `Practice with AI role playing`,
      'n_ai_tutor.study_activity.roleplay_btn',
      `Practice with AI role playing`,
      `Practice with AI role playing`,
      `Practice with AI role playing`,
      ``,
    ],
    expression_toast1: [
      `저장한 표현에 추가했어요.`,
      `Added to My Expressions`,
      'n_ai_tutor.study_activity.expression_toast1',
      `Added to My Expressions`,
      `Added to My Expressions`,
      `Added to My Expressions`,
      ``,
    ],
    expression_toast2: [
      `저장한 표현에서 삭제했어요.`,
      `Deleted from My Expressions`,
      'n_ai_tutor.study_activity.expression_toast2',
      `Deleted from My Expressions`,
      `Deleted from My Expressions`,
      `Deleted from My Expressions`,
      ``,
    ],
    detail3: [
      `오늘의 학습을 달성했어요!`,
      `You've completed today's AI study!`,
      'n_ai_tutor.study_activity.detail3',
      `You've completed today's AI study!`,
      `You've completed today's AI study!`,
      `You've completed today's AI study!`,
      ``,
    ],
    day1: [`월`, `Mon`, 'n_ai_tutor.study_activity.day1', `Mon`, `Mon`, `Mon`, ``],
    day2: [`화`, `Tue`, 'n_ai_tutor.study_activity.day2', `Tue`, `Tue`, `Tue`, ``],
    day3: [`수`, `Wed`, 'n_ai_tutor.study_activity.day3', `Wed`, `Wed`, `Wed`, ``],
    day4: [`목`, `Thu`, 'n_ai_tutor.study_activity.day4', `Thu`, `Thu`, `Thu`, ``],
    day5: [`금`, `Fri`, 'n_ai_tutor.study_activity.day5', `Fri`, `Fri`, `Fri`, ``],
    day6: [`토`, `Sat`, 'n_ai_tutor.study_activity.day6', `Sat`, `Sat`, `Sat`, ``],
    day7: [`일`, `Sun`, 'n_ai_tutor.study_activity.day7', `Sun`, `Sun`, `Sun`, ``],
    noti_title: [
      `오늘의 학습을 잊지 않도록 리마인드 알림을 설정하세요.`,
      `Set a reminder notification to not forget today's study.`,
      'n_ai_tutor.study_activity.noti_title',
      `Set a reminder notification to not forget today's study.`,
      `Set a reminder notification to not forget today's study.`,
      `Set a reminder notification to not forget today's study.`,
      ``,
    ],
    noti_subtitle: [
      `매일 꾸준히 학습하는 습관을 만들 수 있도록 링글이 도울게요!`,
      `Ringle will help you build a consistent daily study habit!`,
      'n_ai_tutor.study_activity.noti_subtitle',
      `Ringle will help you build a consistent daily study habit!`,
      `Ringle will help you build a consistent daily study habit!`,
      `Ringle will help you build a consistent daily study habit!`,
      ``,
    ],
    btn1: [
      `리마인드 알림 켜기`,
      `Turn on reminder notifications`,
      'n_ai_tutor.study_activity.btn1',
      `Turn on reminder notifications`,
      `Turn on reminder notifications`,
      `Turn on reminder notifications`,
      ``,
    ],
    btn3: [
      `내 발음 듣기`,
      `Play recording`,
      'n_ai_tutor.study_activity.btn3',
      `Play recording`,
      `Play recording`,
      `Play recording`,
      ``,
    ],
    btn4: [`발음 연습`, `Practice`, 'n_ai_tutor.study_activity.btn4', `Practice`, `Practice`, `Practice`, ``],
    btn5: [`발음 완료`, `Done`, 'n_ai_tutor.study_activity.btn5', `Done`, `Done`, `Done`, ``],
    detail5: [
      `* 단어를 클릭하고 발음 분석을 확인하세요.`,
      `*Click on the word and check your pronunciation.`,
      'n_ai_tutor.study_activity.detail5',
      `*Click on the word and check your pronunciation.`,
      `*Click on the word and check your pronunciation.`,
      `*Click on the word and check your pronunciation.`,
      ``,
    ],
    toast1: [
      `저장한 단어에 추가했어요.`,
      `Added to My Vocabs`,
      'n_ai_tutor.study_activity.toast1',
      `Added to My Vocabs`,
      `Added to My Vocabs`,
      `Added to My Vocabs`,
      ``,
    ],
    toast2: [
      `저장한 단어에서 삭제했어요.`,
      `Deleted from My Vocabs`,
      'n_ai_tutor.study_activity.toast2',
      `Deleted from My Vocabs`,
      `Deleted from My Vocabs`,
      `Deleted from My Vocabs`,
      ``,
    ],
  },
  noti: {
    page_title: [
      `AI 튜터 알림`,
      `AI Tutor Notification`,
      'n_ai_tutor.noti.page_title',
      `AI Tutor Notification`,
      `AI Tutor Notification`,
      `AI Tutor Notification`,
      ``,
    ],
  },
  study_activity_setting: {
    title1: [
      `학습 설정`,
      `AI Study Settings`,
      'n_ai_tutor.study_activity_setting.title1',
      `AI Study Settings`,
      `AI Study Settings`,
      `AI Study Settings`,
      ``,
    ],
    category1: [
      `오디오 재생 속도`,
      `Audio playback speed`,
      'n_ai_tutor.study_activity_setting.category1',
      `Audio playback speed`,
      `Audio playback speed`,
      `Audio playback speed`,
      ``,
    ],
    category2: [
      `자막`,
      `Subtitles`,
      'n_ai_tutor.study_activity_setting.category2',
      `Subtitles`,
      `Subtitles`,
      `Subtitles`,
      ``,
    ],
    detail1: [
      `AI 튜터 표현 학습 오디오 재생 속도를 선택해주세요.`,
      `Choose the audio playback speed for the AI Study.`,
      'n_ai_tutor.study_activity_setting.detail1',
      `Choose the audio playback speed for the AI Study.`,
      `Choose the audio playback speed for the AI Study.`,
      `Choose the audio playback speed for the AI Study.`,
      ``,
    ],
    detail2: [
      `자막으로 표시되는 언어를 선택해주세요.`,
      `Choose the language for the subtitles.`,
      'n_ai_tutor.study_activity_setting.detail2',
      `Choose the language for the subtitles.`,
      `Choose the language for the subtitles.`,
      `Choose the language for the subtitles.`,
      ``,
    ],
    kor: [`한국어`, `Korean`, 'n_ai_tutor.study_activity_setting.kor', `Korean`, `Korean`, `Korean`, ``],
    eng: [`영어`, `English`, 'n_ai_tutor.study_activity_setting.eng', `English`, `English`, `English`, ``],
    no_subtitle: [
      `자막 없음`,
      `No subtitles`,
      'n_ai_tutor.study_activity_setting.no_subtitle',
      `No subtitles`,
      `No subtitles`,
      `No subtitles`,
      ``,
    ],
  },
  error_report: {
    title: [
      `오류 리포트`,
      `Error report`,
      'n_ai_tutor.error_report.title',
      `Error report`,
      `Error report`,
      `Error report`,
      ``,
    ],
    placeholder: [
      `내용을 입력해주세요.`,
      `Please tell us about the problem.`,
      'n_ai_tutor.error_report.placeholder',
      `Please tell us about the problem.`,
      `Please tell us about the problem.`,
      `Please tell us about the problem.`,
      ``,
    ],
    submit: [`제출`, `Submit`, 'n_ai_tutor.error_report.submit', `Submit`, `Submit`, `Submit`, ``],
    toast: [
      `리포트가 제출되었습니다.`,
      `The report has been submitted.`,
      'n_ai_tutor.error_report.toast',
      `The report has been submitted.`,
      `The report has been submitted.`,
      `The report has been submitted.`,
      ``,
    ],
  },
  roleplay_intro: {
    label1: [`시나리오`, `Scenario`, 'n_ai_tutor.roleplay_intro.label1', `Scenario`, `Scenario`, `Scenario`, ``],
    label2: [`미션`, `Mission`, 'n_ai_tutor.roleplay_intro.label2', `Mission`, `Mission`, `Mission`, ``],
    mission_detail: [
      `아래의 표현을 대화에서 사용해보세요.`,
      `Use the expression below in your talk.`,
      'n_ai_tutor.roleplay_intro.mission_detail',
      `Use the expression below in your talk.`,
      `Use the expression below in your talk.`,
      `Use the expression below in your talk.`,
      ``,
    ],
    info_title1: [
      `마이크 사용 권한`,
      `Enable microphone`,
      'n_ai_tutor.roleplay_intro.info_title1',
      `Enable microphone`,
      `Enable microphone`,
      `Enable microphone`,
      ``,
    ],
    info_title2: [
      `AI 분석`,
      `AI analysis`,
      'n_ai_tutor.roleplay_intro.info_title2',
      `AI analysis`,
      `AI analysis`,
      `AI analysis`,
      ``,
    ],
    info_detail: [
      `대화를 10턴 이상(약 10분) 진행하면 대화를 마친 후 1시간 이내에 AI 분석이 제공돼요.`,
      `AI analysis will be available within an hour after ending a talk with at least 10 turns (about 10 mins).`,
      'n_ai_tutor.roleplay_intro.info_detail',
      `AI analysis will be available within an hour after ending a talk with at least 10 turns (about 10 mins).`,
      `AI analysis will be available within an hour after ending a talk with at least 10 turns (about 10 mins).`,
      `AI analysis will be available within an hour after ending a talk with at least 10 turns (about 10 mins).`,
      ``,
    ],
    info_title3: [
      `최대 턴 수와 시간`,
      `Maximum turns and duration`,
      'n_ai_tutor.roleplay_intro.info_title3',
      `Maximum turns and duration`,
      `Maximum turns and duration`,
      `Maximum turns and duration`,
      ``,
    ],
  },
  roleplay_during: {
    hint_btn1: [
      `한국어 번역 숨기기`,
      `Hide Korean`,
      'n_ai_tutor.roleplay_during.hint_btn1',
      `Hide Korean`,
      `Hide Korean`,
      `Hide Korean`,
      ``,
    ],
    hint_btn2: [
      `한국어 번역 표시`,
      `Show Korean`,
      'n_ai_tutor.roleplay_during.hint_btn2',
      `Show Korean`,
      `Show Korean`,
      `Show Korean`,
      ``,
    ],
    mission_msg1: [
      `미션 달성!`,
      `Mission achieved!`,
      'n_ai_tutor.roleplay_during.mission_msg1',
      `Mission achieved!`,
      `Mission achieved!`,
      `Mission achieved!`,
      ``,
    ],
    mission_msg2: [
      `미션: 아래의 표현을 대화에서 사용해보세요.`,
      `Mission: Use the expression below in your talk.`,
      'n_ai_tutor.roleplay_during.mission_msg2',
      `Mission: Use the expression below in your talk.`,
      `Mission: Use the expression below in your talk.`,
      `Mission: Use the expression below in your talk.`,
      ``,
    ],
  },
  roleplay_popup: {
    btn1: [
      `대화 계속하기`,
      `Resume talk`,
      'n_ai_tutor.roleplay_popup.btn1',
      `Resume talk`,
      `Resume talk`,
      `Resume talk`,
      ``,
    ],
    btn2: [`그만하기`, `Quit`, 'n_ai_tutor.roleplay_popup.btn2', `Quit`, `Quit`, `Quit`, ``],
    title1: [
      `대화를 그만 할까요?`,
      `Would you like to end the talk?`,
      'n_ai_tutor.roleplay_popup.title1',
      `Would you like to end the talk?`,
      `Would you like to end the talk?`,
      `Would you like to end the talk?`,
      ``,
    ],
    body1: [
      `마무리한 대화는 이어서 할 수 없어요.
대화의 AI 분석은 [마이 링글 > AI 대화 기록] 페이지에서 요청 가능해요.`,
      `You cannot resume the talk once you have ended it.
You can run AI analysis on the talk by going to [My Ringle > AI Talk Record].`,
      'n_ai_tutor.roleplay_popup.body1',
      `You cannot resume the talk once you have ended it.
You can run AI analysis on the talk by going to [My Ringle > AI Talk Record].`,
      `You cannot resume the talk once you have ended it.
You can run AI analysis on the talk by going to [My Ringle > AI Talk Record].`,
      `You cannot resume the talk once you have ended it.
You can run AI analysis on the talk by going to [My Ringle > AI Talk Record].`,
      ``,
    ],
  },
  roleplay_setting: {
    category1: [
      `질문 재생 속도`,
      `Playback speed`,
      'n_ai_tutor.roleplay_setting.category1',
      `Playback speed`,
      `Playback speed`,
      `Playback speed`,
      ``,
    ],
    detail: [
      `질문 재생 속도를 선택해 주세요.`,
      `Choose the playback speed for AI Tutor's questions.`,
      'n_ai_tutor.roleplay_setting.detail',
      `Choose the playback speed for AI Tutor's questions.`,
      `Choose the playback speed for AI Tutor's questions.`,
      `Choose the playback speed for AI Tutor's questions.`,
      ``,
    ],
  },
  roleplay_complete: {
    title1: [
      `대화를 완료했어요.`,
      `You have completed the talk.`,
      'n_ai_tutor.roleplay_complete.title1',
      `You have completed the talk.`,
      `You have completed the talk.`,
      `You have completed the talk.`,
      ``,
    ],
    body1: [
      `계속해서 학습을 이어가세요.`,
      `Let's continue studying.`,
      'n_ai_tutor.roleplay_complete.body1',
      `Let's continue studying.`,
      `Let's continue studying.`,
      `Let's continue studying.`,
      ``,
    ],
    turn_count: [
      `이번 대화 턴 수`,
      `Total turns taken`,
      'n_ai_tutor.roleplay_complete.turn_count',
      `Total turns taken`,
      `Total turns taken`,
      `Total turns taken`,
      ``,
    ],
    talk_count: [
      `완료한 대화`,
      `Talks completed`,
      'n_ai_tutor.roleplay_complete.talk_count',
      `Talks completed`,
      `Talks completed`,
      `Talks completed`,
      ``,
    ],
    mission_msg1: [
      `미션을 달성했어요.`,
      `Mission completed!`,
      'n_ai_tutor.roleplay_complete.mission_msg1',
      `Mission completed!`,
      `Mission completed!`,
      `Mission completed!`,
      ``,
    ],
    mission_msg2: [
      `미션을 달성하지 못했어요.`,
      `You have not completed the mission.`,
      'n_ai_tutor.roleplay_complete.mission_msg2',
      `You have not completed the mission.`,
      `You have not completed the mission.`,
      `You have not completed the mission.`,
      ``,
    ],
    btn_ai_analysis: [
      `AI 분석 요청`,
      `Run AI analysis`,
      'n_ai_tutor.roleplay_complete.btn_ai_analysis',
      `Run AI analysis`,
      `Run AI analysis`,
      `Run AI analysis`,
      ``,
    ],
    btn_continue: [
      `학습 계속하기`,
      `Continue studying`,
      'n_ai_tutor.roleplay_complete.btn_continue',
      `Continue studying`,
      `Continue studying`,
      `Continue studying`,
      ``,
    ],
    btn_loading: [
      `준비 중`,
      `Running`,
      'n_ai_tutor.roleplay_complete.btn_loading',
      `Running`,
      `Running`,
      `Running`,
      ``,
    ],
  },
  ai_analysis_popup: {
    title1: [
      `대화에 대한 AI 분석을 요청할까요?`,
      `Run AI analysis on this talk?`,
      'n_ai_tutor.ai_analysis_popup.title1',
      `Run AI analysis on this talk?`,
      `Run AI analysis on this talk?`,
      `Run AI analysis on this talk?`,
      ``,
    ],
    title2: [
      `AI 분석 이용권이 없어요.`,
      `You have no AI analysis tickets left.`,
      'n_ai_tutor.ai_analysis_popup.title2',
      `You have no AI analysis tickets left.`,
      `You have no AI analysis tickets left.`,
      `You have no AI analysis tickets left.`,
      ``,
    ],
    btn_membership: [
      `내 멤버십 보기`,
      `View My Membership`,
      'n_ai_tutor.ai_analysis_popup.btn_membership',
      `View My Membership`,
      `View My Membership`,
      `View My Membership`,
      ``,
    ],
    toast1: [
      `AI 분석이 요청되었어요.`,
      `AI analysis has been requested.`,
      'n_ai_tutor.ai_analysis_popup.toast1',
      `AI analysis has been requested.`,
      `AI analysis has been requested.`,
      `AI analysis has been requested.`,
      ``,
    ],
  },
  roleplay_feedback: {
    title1: [
      `AI 롤플레잉 대화는 어땠나요?`,
      `How was the AI role playing?`,
      'n_ai_tutor.roleplay_feedback.title1',
      `How was the AI role playing?`,
      `How was the AI role playing?`,
      `How was the AI role playing?`,
      ``,
    ],
    placeholder1: [
      `솔직한 사용 경험을 알려주세요. 자세한 피드백은 기능 개선에 큰 도움이 됩니다.`,
      `Please tell us about your experience. Your honest and detailed feedback will be greatly appreciated.`,
      'n_ai_tutor.roleplay_feedback.placeholder1',
      `Please tell us about your experience. Your honest and detailed feedback will be greatly appreciated.`,
      `Please tell us about your experience. Your honest and detailed feedback will be greatly appreciated.`,
      `Please tell us about your experience. Your honest and detailed feedback will be greatly appreciated.`,
      ``,
    ],
  },
  discussion_setting: {
    category2: [
      `대화 모드`,
      `Talk mode`,
      'n_ai_tutor.discussion_setting.category2',
      `Talk mode`,
      `Talk mode`,
      `Talk mode`,
      ``,
    ],
    detail: [
      `디스커션 대화의 목표를 선택해주세요.`,
      `Set your goal for the AI discussion.`,
      'n_ai_tutor.discussion_setting.detail',
      `Set your goal for the AI discussion.`,
      `Set your goal for the AI discussion.`,
      `Set your goal for the AI discussion.`,
      ``,
    ],
    selection1: [
      `Talking a lot`,
      `Talking a lot`,
      'n_ai_tutor.discussion_setting.selection1',
      `Talking a lot`,
      `Talking a lot`,
      `Talking a lot`,
      ``,
    ],
    detail1: [
      `대화를 많이 나눌 수 있는 모드에요.`,
      `AI Tutor will encourage your to talk more.`,
      'n_ai_tutor.discussion_setting.detail1',
      `AI Tutor will encourage your to talk more.`,
      `AI Tutor will encourage your to talk more.`,
      `AI Tutor will encourage your to talk more.`,
      ``,
    ],
    selection2: [
      `Vocab suggestion`,
      `Vocab suggestion`,
      'n_ai_tutor.discussion_setting.selection2',
      `Vocab suggestion`,
      `Vocab suggestion`,
      `Vocab suggestion`,
      ``,
    ],
    detail2: [
      `새로운 표현을 알려주는 모드에요.`,
      `AI Tutor will teach you new expressions.`,
      'n_ai_tutor.discussion_setting.detail2',
      `AI Tutor will teach you new expressions.`,
      `AI Tutor will teach you new expressions.`,
      `AI Tutor will teach you new expressions.`,
      ``,
    ],
    selection3: [
      `Paraphrasing suggestion`,
      `Paraphrasing suggestion`,
      'n_ai_tutor.discussion_setting.selection3',
      `Paraphrasing suggestion`,
      `Paraphrasing suggestion`,
      `Paraphrasing suggestion`,
      ``,
    ],
    detail3: [
      `답변 교정에 집중하는 모드에요.`,
      `AI Tutor will focus on correcting your speech.`,
      'n_ai_tutor.discussion_setting.detail3',
      `AI Tutor will focus on correcting your speech.`,
      `AI Tutor will focus on correcting your speech.`,
      `AI Tutor will focus on correcting your speech.`,
      ``,
    ],
    detail4: [
      `질문에 사용하는 어휘의 난이도를 선택해 주세요.`,
      `Set the level of vocabulary for AI Tutor's questions.`,
      'n_ai_tutor.discussion_setting.detail4',
      `Set the level of vocabulary for AI Tutor's questions.`,
      `Set the level of vocabulary for AI Tutor's questions.`,
      `Set the level of vocabulary for AI Tutor's questions.`,
      ``,
    ],
  },
  discussion_feedback: {
    title1: [
      `AI 디스커션 대화는 어땠나요?`,
      `How was the AI discussion?`,
      'n_ai_tutor.discussion_feedback.title1',
      `How was the AI discussion?`,
      `How was the AI discussion?`,
      `How was the AI discussion?`,
      ``,
    ],
  },
  popup: {
    b2b_title1: [
      `사용 가능한 AI 튜터 멤버십이 없어요.`,
      `Not subscribed to AI Tutor Membership.`,
      'n_ai_tutor.popup.b2b_title1',
      `Not subscribed to AI Tutor Membership.`,
      `Not subscribed to AI Tutor Membership.`,
      `Not subscribed to AI Tutor Membership.`,
      `Not subscribed to AI Tutor Membership.`,
    ],
    b2b_body1: [
      `해당 기능에 대해 더 알아보기 위해서는 담당자에게 문의해주세요.`,
      `Please contact the staff in charge to learn more about this feature.`,
      'n_ai_tutor.popup.b2b_body1',
      `Please contact the staff in charge to learn more about this feature.`,
      `Please contact the staff in charge to learn more about this feature.`,
      `Please contact the staff in charge to learn more about this feature.`,
      `Please contact the staff in charge to learn more about this feature.`,
    ],
    profile_title: [
      <>
        프로필을 입력하고
        <br />
        직군별 맞춤 학습 코스를 확인해보세요!
      </>,
      <>
        Enter your profile
        <br />
        and check out customized learning courses for each industry!
      </>,
      'n_ai_tutor.popup.profile_title',
      <>
        Enter your profile
        <br />
        and check out customized learning courses for each industry!
      </>,
      <>
        Enter your profile
        <br />
        and check out customized learning courses for each industry!
      </>,
      <>
        Enter your profile
        <br />
        and check out customized learning courses for each industry!
      </>,
      <>
        Enter your profile
        <br />
        and check out customized learning courses for each industry!
      </>,
    ],
    profile_btn1: [`닫기`, `Close`, 'n_ai_tutor.popup.profile_btn1', ``, ``, ``, ``],
    profile_btn2: [`프로필 입력`, `Enter Profile`, 'n_ai_tutor.popup.profile_btn2', ``, ``, ``, ``],
  },
  common: {
    popup_body1: [
      `단어를 인식하지 못했어요. 다시 녹음해 주세요.`,
      `No words were recognized. Please try again.`,
      'n_ai_tutor.common.popup_body1',
      `No words were recognized. Please try again.`,
      `No words were recognized. Please try again.`,
      `No words were recognized. Please try again.`,
      `No words were recognized. Please try again.`,
    ],
  },
}
export const n_b2b_inquiry = {
  title: [
    `지금 바로 문의하세요`,
    `Contact us now!`,
    'n_b2b_inquiry.title',
    `Contact us now!`,
    `Contact us now!`,
    `Contact us now!`,
    ``,
  ],
  subtitle: [
    `문의 신청 시 기업 담당자용 체험권을 드립니다.`,
    `When you submit an inquiry, you'll receive a free trial.`,
    'n_b2b_inquiry.subtitle',
    `When you submit an inquiry, you'll receive a free trial.`,
    `When you submit an inquiry, you'll receive a free trial.`,
    `When you submit an inquiry, you'll receive a free trial.`,
    ``,
  ],
  content: {
    first_name: [
      `이름`,
      `First Name`,
      'n_b2b_inquiry.content.first_name',
      `First Name`,
      `First Name`,
      `First Name`,
      ``,
    ],
    last_name: [`성`, `Last Name`, 'n_b2b_inquiry.content.last_name', `Last Name`, `Last Name`, `Last Name`, ``],
    company: [
      `회사명`,
      `Company Name`,
      'n_b2b_inquiry.content.company',
      `Company Name`,
      `Company Name`,
      `Company Name`,
      ``,
    ],
    phone: [
      `연락처`,
      `Contact Number`,
      'n_b2b_inquiry.content.phone',
      `Contact Number`,
      `Contact Number`,
      `Contact Number`,
      ``,
    ],
    email: [
      `회사 이메일`,
      `Company Email`,
      'n_b2b_inquiry.content.email',
      `Company Email`,
      `Company Email`,
      `Company Email`,
      ``,
    ],
    category: [
      `문의 유형`,
      `Inquiry Type`,
      'n_b2b_inquiry.content.category',
      `Inquiry Type`,
      `Inquiry Type`,
      `Inquiry Type`,
      ``,
    ],
    detail: [
      `문의 내용`,
      `Inquiry Details`,
      'n_b2b_inquiry.content.detail',
      `Inquiry Details`,
      `Inquiry Details`,
      `Inquiry Details`,
      ``,
    ],
    category_1: [
      `회사 할인 코드 문의`,
      `Company Discount Code Inquiry`,
      'n_b2b_inquiry.content.category_1',
      `Company Discount Code Inquiry`,
      `Company Discount Code Inquiry`,
      `Company Discount Code Inquiry`,
      ``,
    ],
    category_2: [
      `기업 영어 교육 문의`,
      `Corporate English Training Inquiry`,
      'n_b2b_inquiry.content.category_2',
      `Corporate English Training Inquiry`,
      `Corporate English Training Inquiry`,
      `Corporate English Training Inquiry`,
      ``,
    ],
    category_3: [
      `기업 영어 진단 테스트 문의`,
      `Corporate English Assessment Test Inquiry`,
      'n_b2b_inquiry.content.category_3',
      `Corporate English Assessment Test Inquiry`,
      `Corporate English Assessment Test Inquiry`,
      `Corporate English Assessment Test Inquiry`,
      ``,
    ],
    category_4: [
      `수출바우처 문의`,
      `Export Voucher Inquiry`,
      'n_b2b_inquiry.content.category_4',
      `Export Voucher Inquiry`,
      `Export Voucher Inquiry`,
      `Export Voucher Inquiry`,
      ``,
    ],
    category_5: [
      `기타 문의`,
      `Other Inquiries`,
      'n_b2b_inquiry.content.category_5',
      `Other Inquiries`,
      `Other Inquiries`,
      `Other Inquiries`,
      ``,
    ],
  },
  description: {
    name: [
      `예) 김링글`,
      `ex) Kim Ringle`,
      'n_b2b_inquiry.description.name',
      `ex) Kim Ringle`,
      `ex) Kim Ringle`,
      `ex) Kim Ringle`,
      ``,
    ],
    company: [
      `예) 링글`,
      `ex) Ringle`,
      'n_b2b_inquiry.description.company',
      `ex) Ringle`,
      `ex) Ringle`,
      `ex) Ringle`,
      ``,
    ],
    phone: [
      `000-0000-0000`,
      `000-0000-0000`,
      'n_b2b_inquiry.description.phone',
      `000-0000-0000`,
      `000-0000-0000`,
      `000-0000-0000`,
      ``,
    ],
    email: [
      `예) ringle@company.com`,
      `ex) ringle@company.com`,
      'n_b2b_inquiry.description.email',
      `ex) ringle@company.com`,
      `ex) ringle@company.com`,
      `ex) ringle@company.com`,
      ``,
    ],
    category: [
      `문의 유형`,
      `Inquiry Type`,
      'n_b2b_inquiry.description.category',
      `Inquiry Type`,
      `Inquiry Type`,
      `Inquiry Type`,
      ``,
    ],
    detail: [
      `기업 영어 교육 / 영어 진단 테스트 등 어떤 서비스를 검토하고 계신지 알려주세요.`,
      `Please let us know which services you are considering, such as corporate English training or English assessment tests.`,
      'n_b2b_inquiry.description.detail',
      `Please let us know which services you are considering, such as corporate English training or English assessment tests.`,
      `Please let us know which services you are considering, such as corporate English training or English assessment tests.`,
      `Please let us know which services you are considering, such as corporate English training or English assessment tests.`,
      ``,
    ],
  },
  button: [
    `문의하기`,
    `Submit Inquiry`,
    'n_b2b_inquiry.button',
    `Submit Inquiry`,
    `Submit Inquiry`,
    `Submit Inquiry`,
    ``,
  ],
  content_alert: {
    name: [
      `이름을 입력해주세요.`,
      `Please enter your name.`,
      'n_b2b_inquiry.content_alert.name',
      `Please enter your name.`,
      `Please enter your name.`,
      `Please enter your name.`,
      ``,
    ],
    company: [
      `회사명을 입력해주세요.`,
      `Please enter your company name.`,
      'n_b2b_inquiry.content_alert.company',
      `Please enter your company name.`,
      `Please enter your company name.`,
      `Please enter your company name.`,
      ``,
    ],
    phone: [
      `연락처를 입력해주세요.`,
      `Please enter your contact number.`,
      'n_b2b_inquiry.content_alert.phone',
      `Please enter your contact number.`,
      `Please enter your contact number.`,
      `Please enter your contact number.`,
      ``,
    ],
    email: [
      `회사 이메일 주소를 입력해주세요.`,
      `Please enter your company email.`,
      'n_b2b_inquiry.content_alert.email',
      `Please enter your company email.`,
      `Please enter your company email.`,
      `Please enter your company email.`,
      ``,
    ],
    category: [
      `문의 유형을 골라주세요.`,
      `Please select an inquiry type.`,
      'n_b2b_inquiry.content_alert.category',
      `Please select an inquiry type.`,
      `Please select an inquiry type.`,
      `Please select an inquiry type.`,
      ``,
    ],
    detail: [
      `문의 내용을 입력해주세요.`,
      `Please enter your inquiry details.`,
      'n_b2b_inquiry.content_alert.detail',
      `Please enter your inquiry details.`,
      `Please enter your inquiry details.`,
      `Please enter your inquiry details.`,
      ``,
    ],
  },
}
export const _ = {
  makeup_lesson_title: [
    `당일수업권`,
    `Last-minute Credits`,
    '   _.makeup_lesson_title',
    <>补充课程</>,
    <>補充課程</>,
    <>当日レッスン</>,
    `Buổi học bù`,
  ],
}
export const n_global = {
  nav: {
    teens: [`틴즈`, ``, 'n_global.nav.teens', ``, ``, ``, ``],
  },
}
export const n_membership = {
  sub_mon: [`월 구독`, `Monthly`, 'n_membership.sub_mon', `Monthly`, `Monthly`, `Monthly`, ``],
  sub_year: [`연 구독`, `Yearly`, 'n_membership.sub_year', `Yearly`, `Yearly`, `Yearly`, ``],
  sub_use: [`사용 중`, `Ongoing`, 'n_membership.sub_use', `Ongoing`, `Ongoing`, `Ongoing`, ``],
  pay_next: [
    `다음 결제일`,
    `Next billing`,
    'n_membership.pay_next',
    `Next billing`,
    `Next billing`,
    `Next billing`,
    ``,
  ],
  remain_crd: [
    `남은 AI 분석 이용권`,
    `AI analysis tickets left`,
    'n_membership.remain_crd',
    `AI analysis tickets left`,
    `AI analysis tickets left`,
    `AI analysis tickets left`,
    ``,
  ],
  pay_expired: [`만료 예정`, `Expires`, 'n_membership.pay_expired', `Expires`, `Expires`, `Expires`, ``],
  pay_not: [
    `결제가 되지 않았습니다.`,
    `Payment has not been made.`,
    'n_membership.pay_not',
    `Payment has not been made.`,
    `Payment has not been made.`,
    `Payment has not been made.`,
    ``,
  ],
  change_m: [`변경 예정`, `Switches`, 'n_membership.change_m', `Switches`, `Switches`, `Switches`, ``],
  btn_sub: [
    `계속 구독하기`,
    `Continue to subscribe`,
    'n_membership.btn_sub',
    `Continue to subscribe`,
    `Continue to subscribe`,
    `Continue to subscribe`,
    ``,
  ],
  btn_change: [
    `결제 수단 변경`,
    `Change payment method`,
    'n_membership.btn_change',
    `Change payment method`,
    `Change payment method`,
    `Change payment method`,
    ``,
  ],
  btn: {
    go_detail: [`내 멤버십`, `Go to Home`, 'n_membership.btn.go_detail', `Go to Home`, `Go to Home`, `Go to Home`, ``],
    change: [
      `변경 / 해지`,
      `Switch / Cancel`,
      'n_membership.btn.change',
      `Switch / Cancel`,
      `Switch / Cancel`,
      `Switch / Cancel`,
      ``,
    ],
    change_method: [
      `결제 수단 변경`,
      `Change payment method`,
      'n_membership.btn.change_method',
      `Change payment method`,
      `Change payment method`,
      `Change payment method`,
      ``,
    ],
    refund: [
      `멤버십 해지하기`,
      `Cancel membership`,
      'n_membership.btn.refund',
      `Cancel membership`,
      `Cancel membership`,
      `Cancel membership`,
      ``,
    ],
    refund_yes: [
      `네, 해지합니다.`,
      `Yes, I will cancel my membership.`,
      'n_membership.btn.refund_yes',
      `Yes, I will cancel my membership.`,
      `Yes, I will cancel my membership.`,
      `Yes, I will cancel my membership.`,
      ``,
    ],
  },
  using: {
    title: [
      `사용 중인 멤버십`,
      `사용 중인 멤버십`,
      'n_membership.using.title',
      `사용 중인 멤버십`,
      `사용 중인 멤버십`,
      `사용 중인 멤버십`,
      ``,
    ],
    btn_change: [`변경하기`, `Switch`, 'n_membership.using.btn_change', `Switch`, `Switch`, `Switch`, ``],
    btn_cancel: [`취소`, `Cancel`, 'n_membership.using.btn_cancel', `Cancel`, `Cancel`, `Cancel`, ``],
  },
  pay: {
    title: [
      `결제 내역`,
      `Payment history`,
      'n_membership.pay.title',
      `Payment history`,
      `Payment history`,
      `Payment history`,
      ``,
    ],
  },
  check: {
    title: [
      `멤버십/이용권 유의사항`,
      `Please note:`,
      'n_membership.check.title',
      `Please note:`,
      `Please note:`,
      `Please note:`,
      `Please note:`,
    ],
    content: [
      <>
        <li className="li-tag-inside"> 앱에서 구매한 멤버십은 구독 주기에 맞춰 다음 결제일에 자동 결제됩니다.</li>
        <li className="li-tag-inside">멤버십 변경/해지는 멤버십을 구매/구독한 플랫폼에서 가능합니다.</li>
      </>,
      <>
        <li className="li-tag-inside">
          Memberships purchased via Ringle app will be renewed automatically on the next billing date.{' '}
        </li>{' '}
        <li className="li-tag-inside">
          You can switch or cancel your membership on the platform you used to purchase or subscribe to the membership.
        </li>
      </>,
      'n_membership.check.content',
      <>
        <li className="li-tag-inside">
          Memberships purchased via Ringle app will be renewed automatically on the next billing date.{' '}
        </li>{' '}
        <li className="li-tag-inside">
          You can switch or cancel your membership on the platform you used to purchase or subscribe to the membership.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Memberships purchased via Ringle app will be renewed automatically on the next billing date.{' '}
        </li>{' '}
        <li className="li-tag-inside">
          You can switch or cancel your membership on the platform you used to purchase or subscribe to the membership.
        </li>
      </>,
      <>
        <li className="li-tag-inside">
          Memberships purchased via Ringle app will be renewed automatically on the next billing date.{' '}
        </li>{' '}
        <li className="li-tag-inside">
          You can switch or cancel your membership on the platform you used to purchase or subscribe to the membership.
        </li>
      </>,
      ``,
    ],
    title_1: [
      `변경 전 확인해주세요.`,
      `Please read before switching.`,
      'n_membership.check.title_1',
      `Please read before switching.`,
      `Please read before switching.`,
      `Please read before switching.`,
      ``,
    ],
    list_1: [
      `현재 멤버십은 사용 기한을 제하고 환불됩니다.`,
      `Your current membership will be refunded only for the days remaining in your membership.`,
      'n_membership.check.list_1',
      `Your current membership will be refunded only for the days remaining in your membership.`,
      `Your current membership will be refunded only for the days remaining in your membership.`,
      `Your current membership will be refunded only for the days remaining in your membership.`,
      ``,
    ],
    list_2: [
      `변경된 멤버십은 변경 후 바로 적용됩니다.`,
      `The new membership will be applied immediately after the switch.`,
      'n_membership.check.list_2',
      `The new membership will be applied immediately after the switch.`,
      `The new membership will be applied immediately after the switch.`,
      `The new membership will be applied immediately after the switch.`,
      ``,
    ],
    list_3: [
      `{date}부터 변경된 멤버쉽이 적용됩니다.`,
      `The new membership will be applied from {date}`,
      'n_membership.check.list_3',
      `The new membership will be applied from {date}`,
      `The new membership will be applied from {date}`,
      `The new membership will be applied from {date}`,
      ``,
    ],
  },
  label: {
    monthly: [`월 구독`, `Monthly`, 'n_membership.label.monthly', `Monthly`, `Monthly`, `Monthly`, ``],
    yearly: [`연 구독`, `Yearly`, 'n_membership.label.yearly', `Yearly`, `Yearly`, `Yearly`, ``],
    use: [`사용 중`, `Ongoing`, 'n_membership.label.use', `Ongoing`, `Ongoing`, `Ongoing`, ``],
    refund: [`환불됨`, `Refunded`, 'n_membership.label.refund', `Refunded`, `Refunded`, `Refunded`, ``],
  },
  sub: {
    next_day: [
      `다음 결제일`,
      `Next charge`,
      'n_membership.sub.next_day',
      `Next charge`,
      `Next charge`,
      `Next charge`,
      ``,
    ],
    expired: [`만료 예정`, `Expires`, 'n_membership.sub.expired', `Expires`, `Expires`, `Expires`, ``],
    changing: [`변경 예정`, `Switches`, 'n_membership.sub.changing', `Switches`, `Switches`, `Switches`, ``],
    not: [
      `결제가 되지 않았습니다.`,
      `Payment has not been made.`,
      'n_membership.sub.not',
      `Payment has not been made.`,
      `Payment has not been made.`,
      `Payment has not been made.`,
      ``,
    ],
    remain_analysis: [
      `AI 분석 이용권`,
      `My membership`,
      'n_membership.sub.remain_analysis',
      `My membership`,
      `My membership`,
      `My membership`,
      ``,
    ],
    number: [
      `{amount}회`,
      `AI analysis ticket`,
      'n_membership.sub.number',
      `AI analysis ticket`,
      `AI analysis ticket`,
      `AI analysis ticket`,
      ``,
    ],
  },
  unit: {
    monthly: [`/월`, `/month`, 'n_membership.unit.monthly', `/month`, `/month`, `/month`, ``],
    yearly: [`/년`, `/year`, 'n_membership.unit.yearly', `/year`, `/year`, `/year`, ``],
  },
  list: {
    amount: [
      `결제 금액`,
      `Payment total`,
      'n_membership.list.amount',
      `Payment total`,
      `Payment total`,
      `Payment total`,
      ``,
    ],
    platform: [`플랫폼`, `Platform`, 'n_membership.list.platform', `Platform`, `Platform`, `Platform`, ``],
    pay_day: [
      `결제일`,
      `Payment date`,
      'n_membership.list.pay_day',
      `Payment date`,
      `Payment date`,
      `Payment date`,
      ``,
    ],
    period: [`이용 기간`, `Period`, 'n_membership.list.period', `Period`, `Period`, `Period`, ``],
    remain: [
      `남은 AI 분석 이용권`,
      `AI analysis tickets left`,
      'n_membership.list.remain',
      `AI analysis tickets left`,
      `AI analysis tickets left`,
      `AI analysis tickets left`,
      ``,
    ],
  },
  platform: {
    web: [`WEB`, `WEB`, 'n_membership.platform.web', `WEB`, `WEB`, `WEB`, ``],
    apple: [
      `Apple App Store`,
      `Apple App Store`,
      'n_membership.platform.apple',
      `Apple App Store`,
      `Apple App Store`,
      `Apple App Store`,
      ``,
    ],
    android: [
      `Google Play Store`,
      `Google Play Store`,
      'n_membership.platform.android',
      `Google Play Store`,
      `Google Play Store`,
      `Google Play Store`,
      ``,
    ],
  },
  refund_info_1: {
    title: [
      `변경 / 해지 관련 안내사항`,
      `{amount}`,
      'n_membership.refund_info_1.title',
      `{amount}`,
      `{amount}`,
      `{amount}`,
      ``,
    ],
    content: [
      <>
        <li className="li-tag-inside">웹에서 결제한 멤버십은 해지만 가능해요</li>
        <li className="li-tag-inside">멤버십 변경을 원할 시, 해지 후 새로운 멤버십으로 결제해주세요.</li>
      </>,
      <>
        <li className="li-tag-inside">Memberships you have purchased via the Ringle website can only be cancelled.</li>
        <li className="li-tag-inside">
          If you wish to switch memberships, please cancel your current membership first, then subscribe to a new
          membership.
        </li>
      </>,
      'n_membership.refund_info_1.content',
      <>
        <li className="li-tag-inside">Memberships you have purchased via the Ringle website can only be cancelled.</li>
        <li className="li-tag-inside">
          If you wish to switch memberships, please cancel your current membership first, then subscribe to a new
          membership.
        </li>
      </>,
      <>
        <li className="li-tag-inside">Memberships you have purchased via the Ringle website can only be cancelled.</li>
        <li className="li-tag-inside">
          If you wish to switch memberships, please cancel your current membership first, then subscribe to a new
          membership.
        </li>
      </>,
      <>
        <li className="li-tag-inside">Memberships you have purchased via the Ringle website can only be cancelled.</li>
        <li className="li-tag-inside">
          If you wish to switch memberships, please cancel your current membership first, then subscribe to a new
          membership.
        </li>
      </>,
      ``,
    ],
  },
  refund_info_2: {
    title: [
      `정말 멤버십을 해지하시겠어요?`,
      `Do you really want to cancel your membership?`,
      'n_membership.refund_info_2.title',
      `Do you really want to cancel your membership?`,
      `Do you really want to cancel your membership?`,
      `Do you really want to cancel your membership?`,
      ``,
    ],
    content: (amount) => [
      <>
        <li className="li-tag-inside">해지 이후 링글 AI 튜터의 모든 기능들을 사용할 수 없어요.</li>
        <li className="li-tag-inside">
          해지 시 총 결제 금액에서 총 사용 금액(구매일로부터 환불 신청일까지의 경과일수 x 1일 사용 금액)과 위약금(총
          결제금액에서 총사용 금액을 뺀 금액의 10%)을 제하고 환불이 진행돼요.
        </li>
        <li className="li-tag-inside">
          한화가 아닌 외화로 결제한 경우, 구매 시점이 아니라 환불 신청일 기준의 환율을 적용하여 환불 금액이 산정돼요.
        </li>
        <li className="li-tag-inside">환불에 소요되는 시간은 결제한 수단에 따라 다소 시일이 걸릴수 있어요.</li>
        <li className="li-tag-inside">예상 환불 금액은 {amount}에요.</li>
      </>,
      <>
        <li className="li-tag-inside">You cannot use any of the AI Tutor services after cancellation.</li>
        <li className="li-tag-inside">
          Upon cancellation, a refund will be issued after subtracting the payment for the days you have used the
          membership (the number of days elapsed from the date of purchase to the date of refund request x price per
          day) and a penalty (10% of the price for the days remaining in your membership) from the total amount you
          paid.
        </li>
        <li className="li-tag-inside">
          If you paid in a currency other than Korean won, your refund will be calculated using the exchange rate at the
          time of refund request, not the time of purchase.
        </li>
        <li className="li-tag-inside">
          It may take some time for the refund to be processed depending on the payment method you used.
        </li>
      </>,
      'n_membership.refund_info_2.content',
      <>
        <li className="li-tag-inside">You cannot use any of the AI Tutor services after cancellation.</li>
        <li className="li-tag-inside">
          Upon cancellation, a refund will be issued after subtracting the payment for the days you have used the
          membership (the number of days elapsed from the date of purchase to the date of refund request x price per
          day) and a penalty (10% of the price for the days remaining in your membership) from the total amount you
          paid.
        </li>
        <li className="li-tag-inside">
          If you paid in a currency other than Korean won, your refund will be calculated using the exchange rate at the
          time of refund request, not the time of purchase.
        </li>
        <li className="li-tag-inside">
          It may take some time for the refund to be processed depending on the payment method you used.
        </li>
      </>,
      <>
        <li className="li-tag-inside">You cannot use any of the AI Tutor services after cancellation.</li>
        <li className="li-tag-inside">
          Upon cancellation, a refund will be issued after subtracting the payment for the days you have used the
          membership (the number of days elapsed from the date of purchase to the date of refund request x price per
          day) and a penalty (10% of the price for the days remaining in your membership) from the total amount you
          paid.
        </li>
        <li className="li-tag-inside">
          If you paid in a currency other than Korean won, your refund will be calculated using the exchange rate at the
          time of refund request, not the time of purchase.
        </li>
        <li className="li-tag-inside">
          It may take some time for the refund to be processed depending on the payment method you used.
        </li>
      </>,
      <>
        <li className="li-tag-inside">You cannot use any of the AI Tutor services after cancellation.</li>
        <li className="li-tag-inside">
          Upon cancellation, a refund will be issued after subtracting the payment for the days you have used the
          membership (the number of days elapsed from the date of purchase to the date of refund request x price per
          day) and a penalty (10% of the price for the days remaining in your membership) from the total amount you
          paid.
        </li>
        <li className="li-tag-inside">
          If you paid in a currency other than Korean won, your refund will be calculated using the exchange rate at the
          time of refund request, not the time of purchase.
        </li>
        <li className="li-tag-inside">
          It may take some time for the refund to be processed depending on the payment method you used.
        </li>
      </>,
      ``,
    ],
  },
  refund_comp: {
    content: [
      `해지가 완료되었습니다.`,
      `Your membership has been cancelled.`,
      'n_membership.refund_comp.content',
      `Your membership has been cancelled.`,
      `Your membership has been cancelled.`,
      `Your membership has been cancelled.`,
      ``,
    ],
  },
  change: {
    title: [
      `멤버십 변경`,
      `Switch Membership`,
      'n_membership.change.title',
      `Switch Membership`,
      `Switch Membership`,
      `Switch Membership`,
      ``,
    ],
    btn_cta: [
      `{product_line}(으)로 변경`,
      `Switch to {product_line}`,
      'n_membership.change.btn_cta',
      `Switch to {product_line}`,
      `Switch to {product_line}`,
      `Switch to {product_line}`,
      ``,
    ],
  },
  cancel: {
    title: [
      `구독 취소`,
      `Cancel subscription`,
      'n_membership.cancel.title',
      `Cancel subscription`,
      `Cancel subscription`,
      `Cancel subscription`,
      ``,
    ],
    content: [
      <>
        정말 구독을 취소하겠어요?
        <br />
        링글 AI 튜터의 모든 기능들을 사용할수 없어요.
      </>,
      <>
        Are you sure you want to cancel your subscription?
        <br /> You will lose access to all AI Tutor services.
      </>,
      'n_membership.cancel.content',
      <>
        Are you sure you want to cancel your subscription?
        <br /> You will lose access to all AI Tutor services.
      </>,
      <>
        Are you sure you want to cancel your subscription?
        <br /> You will lose access to all AI Tutor services.
      </>,
      <>
        Are you sure you want to cancel your subscription?
        <br /> You will lose access to all AI Tutor services.
      </>,
      ``,
    ],
    btn_using: [
      `구독 유지하기`,
      `Maintain subscription`,
      'n_membership.cancel.btn_using',
      `Maintain subscription`,
      `Maintain subscription`,
      `Maintain subscription`,
      ``,
    ],
    btn_cancel: [
      `구독 취소하기`,
      `Cancel subscription`,
      'n_membership.cancel.btn_cancel',
      `Cancel subscription`,
      `Cancel subscription`,
      `Cancel subscription`,
      `Cancel subscription`,
    ],
  },
  c_complete: {
    title: [
      `멤버십 변경이 완료되었습니다.`,
      `Your membership has been switched.`,
      'n_membership.c_complete.title',
      `Your membership has been switched.`,
      `Your membership has been switched.`,
      `Your membership has been switched.`,
      `Your membership has been switched.`,
    ],
  },
  receipt: {
    title: [
      `구독 영수증 안내`,
      `How to get a receipt`,
      'n_membership.receipt.title',
      `How to get a receipt`,
      `How to get a receipt`,
      `How to get a receipt`,
      `How to get a receipt`,
    ],
    content: [
      <>
        <div>Apple App Store에서 구독한 경우</div>
        <div>- App Store 앱을 엽니다.</div>
        <div>- 화면 상단에 있는 로그인 버튼 또는 사용자의 사진을 탭합니다.</div>
        <div>- ‘구입 내역'을 탭합니다. </div>
        <div>
          - 선택된 필터를 클릭해서 구독한 날짜에 맞춰 기간을 조정합니다. (최근 90일 이내의 구독 영수증을 발급받는 경우
          생략)
        </div>
        <div>- 검색 박스에 “Ringle”을 입력합니다.</div>
        <div>- 해당 내역을 클릭하면 영수증을 확인할 수 있습니다.</div>

        <div>Google Play에서 구독한 경우</div>
        <div>- Google 결제 센터로 이동합니다.</div>
        <div>- 거래내역을 선택합니다. </div>
        <div>- Ringle 거래내역을 클릭하면 영수증을 확인할 수 있습니다.</div>

        <div>*더 자세한 영수증을 원하는 경우, 진행한 결제 수단의 앱에서 확인해주세요.</div>
      </>,
      <>
        <div>If you subscribed through the Apple App Store:</div>
        <div>- Open the App Store app.</div>
        <div>- Tap the login button or your profile picture at the top of the screen.</div>
        <div>- Tap ‘Purchase History.’ </div>
        <div>
          - Click the selected filter and adjust the date range to match your subscription date (skip this step if
          requesting a receipt from the last 90 days).{' '}
        </div>
        <div>- Enter “Ringle” in the search bar.</div>
        <div>- Click on the relevant entry to view the receipt.</div>

        <div>If you subscribed through Google Play:</div>
        <div>- Go to the Google Payments Center.</div>
        <div>- Select ‘Transaction History.’ </div>
        <div>- Click on the Ringle transaction to view the receipt.</div>

        <div>*For more detailed receipts, please check the app associated with the payment method used.</div>
      </>,
      'n_membership.receipt.content',
      <>
        <div>If you subscribed through the Apple App Store:</div>
        <div>- Open the App Store app.</div>
        <div>- Tap the login button or your profile picture at the top of the screen.</div>
        <div>- Tap ‘Purchase History.’ </div>
        <div>
          - Click the selected filter and adjust the date range to match your subscription date (skip this step if
          requesting a receipt from the last 90 days).{' '}
        </div>
        <div>- Enter “Ringle” in the search bar.</div>
        <div>- Click on the relevant entry to view the receipt.</div>

        <div>If you subscribed through Google Play:</div>
        <div>- Go to the Google Payments Center.</div>
        <div>- Select ‘Transaction History.’ </div>
        <div>- Click on the Ringle transaction to view the receipt.</div>

        <div>*For more detailed receipts, please check the app associated with the payment method used.</div>
      </>,
      <>
        <div>If you subscribed through the Apple App Store:</div>
        <div>- Open the App Store app.</div>
        <div>- Tap the login button or your profile picture at the top of the screen.</div>
        <div>- Tap ‘Purchase History.’ </div>
        <div>
          - Click the selected filter and adjust the date range to match your subscription date (skip this step if
          requesting a receipt from the last 90 days).{' '}
        </div>
        <div>- Enter “Ringle” in the search bar.</div>
        <div>- Click on the relevant entry to view the receipt.</div>

        <div>If you subscribed through Google Play:</div>
        <div>- Go to the Google Payments Center.</div>
        <div>- Select ‘Transaction History.’ </div>
        <div>- Click on the Ringle transaction to view the receipt.</div>

        <div>*For more detailed receipts, please check the app associated with the payment method used.</div>
      </>,
      <>
        <div>If you subscribed through the Apple App Store:</div>
        <div>- Open the App Store app.</div>
        <div>- Tap the login button or your profile picture at the top of the screen.</div>
        <div>- Tap ‘Purchase History.’ </div>
        <div>
          - Click the selected filter and adjust the date range to match your subscription date (skip this step if
          requesting a receipt from the last 90 days).{' '}
        </div>
        <div>- Enter “Ringle” in the search bar.</div>
        <div>- Click on the relevant entry to view the receipt.</div>

        <div>If you subscribed through Google Play:</div>
        <div>- Go to the Google Payments Center.</div>
        <div>- Select ‘Transaction History.’ </div>
        <div>- Click on the Ringle transaction to view the receipt.</div>

        <div>*For more detailed receipts, please check the app associated with the payment method used.</div>
      </>,
      <>
        <div>If you subscribed through the Apple App Store:</div>
        <div>- Open the App Store app.</div>
        <div>- Tap the login button or your profile picture at the top of the screen.</div>
        <div>- Tap ‘Purchase History.’ </div>
        <div>
          - Click the selected filter and adjust the date range to match your subscription date (skip this step if
          requesting a receipt from the last 90 days).{' '}
        </div>
        <div>- Enter “Ringle” in the search bar.</div>
        <div>- Click on the relevant entry to view the receipt.</div>

        <div>If you subscribed through Google Play:</div>
        <div>- Go to the Google Payments Center.</div>
        <div>- Select ‘Transaction History.’ </div>
        <div>- Click on the Ringle transaction to view the receipt.</div>

        <div>*For more detailed receipts, please check the app associated with the payment method used.</div>
      </>,
    ],
  },
}
export const n_subscribe = {
  info: {
    title: [
      <>
        프리미엄 플러스 멤버십을 <span style={{ color: '503CC8' }}>7일 무료 체험</span>해보세요.
      </>,
      <>
        Get a <span style={{ color: '503CC8' }}>free 7-day trial</span> of
        <br /> Premium Plus Membership.
      </>,
      'n_subscribe.info.title',
      <>
        Get a <span style={{ color: '503CC8' }}>free 7-day trial</span> of
        <br /> Premium Plus Membership.
      </>,
      <>
        Get a <span style={{ color: '503CC8' }}>free 7-day trial</span> of
        <br /> Premium Plus Membership.
      </>,
      <>
        Get a <span style={{ color: '503CC8' }}>free 7-day trial</span> of
        <br /> Premium Plus Membership.
      </>,
      ``,
    ],
    subtitle: [
      `체험 기간 동안 위약금 없이 언제나 취소 가능해요.`,
      `You can cancel anytime during the trial period.`,
      'n_subscribe.info.subtitle',
      `You can cancel anytime during the trial period.`,
      `You can cancel anytime during the trial period.`,
      `You can cancel anytime during the trial period.`,
      ``,
    ],
    benefit_1: [
      <>
        맞춤형 직무 <b>영어 학습</b>
      </>,
      <>
        <b>Studies</b> tailored for your job
      </>,
      'n_subscribe.info.benefit_1',
      <>
        <b>Studies</b> tailored for your job
      </>,
      <>
        <b>Studies</b> tailored for your job
      </>,
      <>
        <b>Studies</b> tailored for your job
      </>,
      ``,
    ],
    benefit_2: [
      <>
        다양한 상황별 <b>AI 롤플레잉</b>
      </>,
      <>
        <b>AI Role Playing</b> for responding to varioius workplace situations{' '}
      </>,
      'n_subscribe.info.benefit_2',
      <>
        <b>AI Role Playing</b> for responding to varioius workplace situations{' '}
      </>,
      <>
        <b>AI Role Playing</b> for responding to varioius workplace situations{' '}
      </>,
      <>
        <b>AI Role Playing</b> for responding to varioius workplace situations{' '}
      </>,
      ``,
    ],
    benefit_3: [
      <>
        스마트한 티키타카 <b>AI 디스커션</b>
      </>,
      <>
        <b>AI Discussions</b> to practice conversing on diverse topics{' '}
      </>,
      'n_subscribe.info.benefit_3',
      <>
        <b>AI Discussions</b> to practice conversing on diverse topics{' '}
      </>,
      <>
        <b>AI Discussions</b> to practice conversing on diverse topics{' '}
      </>,
      <>
        <b>AI Discussions</b> to practice conversing on diverse topics{' '}
      </>,
      ``,
    ],
    benefit_4: [
      <>
        내 영어 실력, <b>무제한 분석</b>
      </>,
      <>
        <b>Unlimited AI Analysis</b> for checking your progress in learning English
      </>,
      'n_subscribe.info.benefit_4',
      <>
        <b>Unlimited AI Analysis</b> for checking your progress in learning English
      </>,
      <>
        <b>Unlimited AI Analysis</b> for checking your progress in learning English
      </>,
      <>
        <b>Unlimited AI Analysis</b> for checking your progress in learning English
      </>,
      ``,
    ],
    btn: [
      `7일 무료 체험 시작하기`,
      `Start the free 7-day trial`,
      'n_subscribe.info.btn',
      `Start the free 7-day trial`,
      `Start the free 7-day trial`,
      `Start the free 7-day trial`,
      ``,
    ],
  },
  info_2: {
    title: [
      <>
        체험 종료 2일 전에
        <br /> 알림을 보내드릴게요.
      </>,
      <>
        We will notify you 2 days
        <br /> before the trial ends.
      </>,
      'n_subscribe.info_2.title',
      <>
        We will notify you 2 days
        <br /> before the trial ends.
      </>,
      <>
        We will notify you 2 days
        <br /> before the trial ends.
      </>,
      <>
        We will notify you 2 days
        <br /> before the trial ends.
      </>,
      ``,
    ],
    subtitle: (date) => [
      <>{date}에 알림을 보내드려요.</>,
      <>We'll alert you on {date}.</>,
      'n_subscribe.info_2.subtitle',
      <>We'll alert you on {date}.</>,
      <>We'll alert you on {date}.</>,
      <>We'll alert you on {date}.</>,
      ``,
    ],
    today: [`오늘`, `Today`, 'n_subscribe.info_2.today', `Today`, `Today`, `Today`, ``],
    today_desc: [
      <>
        AI 튜터의 기능을 사용할 수 있어요.
        <br />
        무제한 학습을 시작하세요.
      </>,
      <>
        You now have free access to AI Tutor.
        <br />
        Start your unlimited learning.
      </>,
      'n_subscribe.info_2.today_desc',
      <>
        You now have free access to AI Tutor.
        <br />
        Start your unlimited learning.
      </>,
      <>
        You now have free access to AI Tutor.
        <br />
        Start your unlimited learning.
      </>,
      <>
        You now have free access to AI Tutor.
        <br />
        Start your unlimited learning.
      </>,
      ``,
    ],
    five_day: [
      `5일 후`,
      `5 days into trial`,
      'n_subscribe.info_2.five_day',
      `5 days into trial`,
      `5 days into trial`,
      `5 days into trial`,
      ``,
    ],
    five_day_desc: [
      `체험 기간이 종료된다는 리마인더를 보내드려요.`,
      `We'll remind you that you have 2 days left of your trial period.`,
      'n_subscribe.info_2.five_day_desc',
      `We'll remind you that you have 2 days left of your trial period.`,
      `We'll remind you that you have 2 days left of your trial period.`,
      `We'll remind you that you have 2 days left of your trial period.`,
      ``,
    ],
    seven_day: [
      `7일 후`,
      `7 days into the trial`,
      'n_subscribe.info_2.seven_day',
      `7 days into the trial`,
      `7 days into the trial`,
      `7 days into the trial`,
      ``,
    ],
    seven_day_desc: (date) => [
      <>
        {date}부터 요금이 부과됩니다.
        <br />그 전까지 언제든 취소 가능해요.
      </>,
      <>
        You will be charged on {date}.<br />
        You can cancel anytime before this date.
      </>,
      'n_subscribe.info_2.seven_day_desc',
      <>
        You will be charged on {date}.<br />
        You can cancel anytime before this date.
      </>,
      <>
        You will be charged on {date}.<br />
        You can cancel anytime before this date.
      </>,
      <>
        You will be charged on {date}.<br />
        You can cancel anytime before this date.
      </>,
      ``,
    ],
    btn: [`다음`, `Next`, 'n_subscribe.info_2.btn', `Next`, `Next`, `Next`, ``],
  },
  select: {
    title_newbie: [
      <>
        7일 무료 체험을 진행할
        <br />
        멤버십을 선택하세요
      </>,
      <>Please choose the membership you wish to try out for 7 days.</>,
      'n_subscribe.select.title_newbie',
      <>Please choose the membership you wish to try out for 7 days.</>,
      <>Please choose the membership you wish to try out for 7 days.</>,
      <>Please choose the membership you wish to try out for 7 days.</>,
      ``,
    ],
    yearly: [`연 구독`, `Yearly`, 'n_subscribe.select.yearly', `Yearly`, `Yearly`, `Yearly`, ``],
    monthly: [`월 구독`, `Monthly`, 'n_subscribe.select.monthly', `Monthly`, `Monthly`, `Monthly`, ``],
    view_all: [
      `모든 멤버십 보기`,
      `View all memberships`,
      'n_subscribe.select.view_all',
      `View all memberships`,
      `View all memberships`,
      `View all memberships`,
      ``,
    ],
    btn_newbie: (amount) => [
      `7일 무료 체험 후 ${amount}`,
      `${amount} after 7-day trial`,
      'n_subscribe.select.btn_newbie',
      `${amount} after 7-day trial`,
      `${amount} after 7-day trial`,
      `${amount} after 7-day trial`,
      ``,
    ],
    btn: (amount) => [
      `${amount} 시작하기`,
      `Start at ${amount}`,
      'n_subscribe.select.btn',
      `Start at ${amount}`,
      `Start at ${amount}`,
      `Start at ${amount}`,
      ``,
    ],
    benefit_1: [`AI 학습`, `AI Study`, 'n_subscribe.select.benefit_1', `AI Study`, `AI Study`, `AI Study`, ``],
    benefit_2: [
      `AI 롤플레잉`,
      `AI Role Playing`,
      'n_subscribe.select.benefit_2',
      `AI Role Playing`,
      `AI Role Playing`,
      `AI Role Playing`,
      ``,
    ],
    benefit_3: [
      `AI 디스커션`,
      `AI Discussion`,
      'n_subscribe.select.benefit_3',
      `AI Discussion`,
      `AI Discussion`,
      `AI Discussion`,
      ``,
    ],
    benefit_4: [
      `무제한 분석`,
      `Unlimited AI Analysis`,
      'n_subscribe.select.benefit_4',
      `Unlimited AI Analysis`,
      `Unlimited AI Analysis`,
      `Unlimited AI Analysis`,
      ``,
    ],
  },
  complete: {
    title_1: [
      `7일 무료 체험이 시작되었어요.`,
      `Your 7-day trial has started.`,
      'n_subscribe.complete.title_1',
      `Your 7-day trial has started.`,
      `Your 7-day trial has started.`,
      `Your 7-day trial has started.`,
      ``,
    ],
    title_2: [
      `멤버십이 시작되었어요.`,
      `Your membership has started.`,
      'n_subscribe.complete.title_2',
      `Your membership has started.`,
      `Your membership has started.`,
      `Your membership has started.`,
      ``,
    ],
    sub_1: [
      `결제 2일 전 알림을 보내드릴게요.`,
      `We will alert you 2 days before you are charged.`,
      'n_subscribe.complete.sub_1',
      `We will alert you 2 days before you are charged.`,
      `We will alert you 2 days before you are charged.`,
      `We will alert you 2 days before you are charged.`,
      ``,
    ],
    sub_2: [
      `알림을 켜주세요. 결제 2일 전 알림을 보내드릴게요.`,
      `Please turn on notifications to be reminded 2 days before you are charged.`,
      'n_subscribe.complete.sub_2',
      `Please turn on notifications to be reminded 2 days before you are charged.`,
      `Please turn on notifications to be reminded 2 days before you are charged.`,
      `Please turn on notifications to be reminded 2 days before you are charged.`,
      ``,
    ],
    sub_3: [
      `지금 바로 학습을 시작해보세요.`,
      `Let's start learning right away!`,
      'n_subscribe.complete.sub_3',
      `Let's start learning right away!`,
      `Let's start learning right away!`,
      `Let's start learning right away!`,
      ``,
    ],
    sub_4: [
      `알림을 켜주세요. 맞춤 영어학습 알림을 보내드릴게요.`,
      `Please turn on notifications for alerts on tailored AI study.`,
      'n_subscribe.complete.sub_4',
      `Please turn on notifications for alerts on tailored AI study.`,
      `Please turn on notifications for alerts on tailored AI study.`,
      `Please turn on notifications for alerts on tailored AI study.`,
      ``,
    ],
    next_day: [
      `다음 결제일`,
      `Next billing`,
      'n_subscribe.complete.next_day',
      `Next billing`,
      `Next billing`,
      `Next billing`,
      ``,
    ],
    btn_1: [
      `학습 시작하기`,
      `Start learning`,
      'n_subscribe.complete.btn_1',
      `Start learning`,
      `Start learning`,
      `Start learning`,
      ``,
    ],
    btn_2: [
      `알림 켜기`,
      `Turn on notifications`,
      'n_subscribe.complete.btn_2',
      `Turn on notifications`,
      `Turn on notifications`,
      `Turn on notifications`,
      ``,
    ],
    btn_3: [
      `다음에 켤게요`,
      `I'll turn it on later`,
      'n_subscribe.complete.btn_3',
      `I'll turn it on later`,
      `I'll turn it on later`,
      `I'll turn it on later`,
      ``,
    ],
    title_3: [
      `결제가 완료됐어요.`,
      `Your payment has been processed.`,
      'n_subscribe.complete.title_3',
      `Your payment has been processed.`,
      `Your payment has been processed.`,
      `Your payment has been processed.`,
      ``,
    ],
    sub_5: [
      `링글 앱에서 AI 튜터와 학습을 시작해보세요.`,
      `Start studying with AI tutor on Ringle app.`,
      'n_subscribe.complete.sub_5',
      `Start studying with AI tutor on Ringle app.`,
      `Start studying with AI tutor on Ringle app.`,
      `Start studying with AI tutor on Ringle app.`,
      ``,
    ],
    period: [`이용 기간`, `Period`, 'n_subscribe.complete.period', `Period`, `Period`, `Period`, ``],
    btn_app_2: [
      `홈으로 가기`,
      `AI Tutor is only available on Ringle app.`,
      'n_subscribe.complete.btn_app_2',
      `AI Tutor is only available on Ringle app.`,
      `AI Tutor is only available on Ringle app.`,
      `AI Tutor is only available on Ringle app.`,
      ``,
    ],
    btn_app: [
      `앱에서 시작하기`,
      `Get started with the app`,
      'n_subscribe.complete.btn_app',
      `Get started with the app`,
      `Get started with the app`,
      `Get started with the app`,
      ``,
    ],
  },
  tab: {
    membership: [
      `AI 튜터 멤버십`,
      `AI Tutor Membership`,
      'n_subscribe.tab.membership',
      `AI Tutor Membership`,
      `AI Tutor Membership`,
      `AI Tutor Membership`,
      ``,
    ],
    btn_pay: [`구매`, `Purchase`, 'n_subscribe.tab.btn_pay', `Purchase`, `Purchase`, `Purchase`, ``],
    one_on_one: [
      `1:1 수업권`,
      `Start on Ringle app`,
      'n_subscribe.tab.one_on_one',
      `Start on Ringle app`,
      `Start on Ringle app`,
      `Start on Ringle app`,
      ``,
    ],
  },
  content: {
    title: [
      `멤버십 선택`,
      `Choose Membership`,
      'n_subscribe.content.title',
      `Choose Membership`,
      `Choose Membership`,
      `Choose Membership`,
      ``,
    ],
  },
  warning: {
    title: [
      `앗! 사용 중인 멤버십이 있어요.`,
      `You have an ongoing subscription.`,
      'n_subscribe.warning.title',
      `You have an ongoing subscription.`,
      `You have an ongoing subscription.`,
      `You have an ongoing subscription.`,
      ``,
    ],
    content: [
      `사용 중인 멤버십의 이용기간 종료 혹은 해지 후에 구매 가능해요.`,
      `Please wait until your current membership ends or try again after cancelling the membership.`,
      'n_subscribe.warning.content',
      `Please wait until your current membership ends or try again after cancelling the membership.`,
      `Please wait until your current membership ends or try again after cancelling the membership.`,
      `Please wait until your current membership ends or try again after cancelling the membership.`,
      ``,
    ],
    btn_1: [
      `내 멤버십 확인하기`,
      `Go to My Membership`,
      'n_subscribe.warning.btn_1',
      `Go to My Membership`,
      `Go to My Membership`,
      `Go to My Membership`,
      ``,
    ],
  },
  warning_2: {
    title: [
      `구매 전 확인하세요.`,
      `Please read before purchase.`,
      'n_subscribe.warning_2.title',
      `Please read before purchase.`,
      `Please read before purchase.`,
      `Please read before purchase.`,
      ``,
    ],
    content: [
      <>
        <li className="li-tag-inside">해당 패키지는 1:1 수업과 AI 튜터 멤버십이 결합된 상품입니다.</li>
        <li className="li-tag-inside">이전에 구매한 AI 튜터 멤버십이 자동환불처리됩니다.</li>
        <li className="li-tag-inside">환불액은 사용한 기한만큼 제해서 드립니다.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">
          You will be automatically refunded for the AI Tutor membership you have already purchased.
        </li>
        <li className="li-tag-inside">Refund will be made for the number of days left in the membership.</li>
      </>,
      'n_subscribe.warning_2.content',
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">
          You will be automatically refunded for the AI Tutor membership you have already purchased.
        </li>
        <li className="li-tag-inside">Refund will be made for the number of days left in the membership.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">
          You will be automatically refunded for the AI Tutor membership you have already purchased.
        </li>
        <li className="li-tag-inside">Refund will be made for the number of days left in the membership.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">
          You will be automatically refunded for the AI Tutor membership you have already purchased.
        </li>
        <li className="li-tag-inside">Refund will be made for the number of days left in the membership.</li>
      </>,
      ``,
    ],
    btn_1: [
      `구매 진행하기`,
      `Go ahead with purchase`,
      'n_subscribe.warning_2.btn_1',
      `Go ahead with purchase`,
      `Go ahead with purchase`,
      `Go ahead with purchase`,
      ``,
    ],
  },
  warning_3: {
    title: [
      `패키지를 구매할 수 없습니다.`,
      `You cannot purchase this package.`,
      'n_subscribe.warning_3.title',
      `You cannot purchase this package.`,
      `You cannot purchase this package.`,
      `You cannot purchase this package.`,
      ``,
    ],
    content: [
      <>
        <li className="li-tag-inside">해당 패키지는 1:1 수업과 AI 튜터 멤버십이 결합된 상품입니다.</li>
        <li className="li-tag-inside">현재 구독 중인 멤버십 취소 시 구매 가능합니다.</li>
        <li className="li-tag-inside">관련 문의는 채널톡을 통해 진행해주세요.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">You can purchase this package after cancelling your current membership.</li>
        <li className="li-tag-inside">If you have any inquiries, please contact us via our talk channel.</li>
      </>,
      'n_subscribe.warning_3.content',
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">You can purchase this package after cancelling your current membership.</li>
        <li className="li-tag-inside">If you have any inquiries, please contact us via our talk channel.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">You can purchase this package after cancelling your current membership.</li>
        <li className="li-tag-inside">If you have any inquiries, please contact us via our talk channel.</li>
      </>,
      <>
        <li className="li-tag-inside">This package includes both 1:1 lessons and AI Tutor membership.</li>
        <li className="li-tag-inside">You can purchase this package after cancelling your current membership.</li>
        <li className="li-tag-inside">If you have any inquiries, please contact us via our talk channel.</li>
      </>,
      ``,
    ],
  },
}
export const n_aitutor = {
  title: [`AI 튜터`, `AI Tutor`, 'n_aitutor.title', `AI 教师`, `AI 教師`, `AI チューター`, ``],
}
export const n_lessons = {
  lesson_card: {
    ai_tutor_btn: [
      `AI 튜터와 수업 교재로 대화 시작`,
      `Start talking with Ringle AI`,
      'n_lessons.lesson_card.ai_tutor_btn',
      `Start talking with Ringle AI`,
      `Start talking with Ringle AI`,
      `Start talking with Ringle AI`,
      `Start talking with Ringle AI`,
    ],
  },
}
