import { useMemo } from 'react'
import { LOCALE_EN, LOCALE_KO, localeIndex } from '../../modules/i18n/config'
import { ContextProps, useLocale } from '../context/LocaleContext'
import { s_pricing } from './strings/auto/wording'

export type SType = string | Element | Array<any>
interface IUseTranslationReturnType extends ContextProps {
  t: (obj: SType) => string
  isLocaleEN: boolean
  isLocaleKO: boolean
}

export default function useTranslation(): IUseTranslationReturnType {
  const { locale, updateLandingLocale } = useLocale()
  const t = useMemo(() => {
    return (obj: SType) => {
      if (!obj) {
        return ''
      }
      if (typeof obj == 'string') {
        return obj
      }
      const value = obj[localeIndex(locale)] === undefined ? obj[localeIndex(LOCALE_EN)] : obj[localeIndex(locale)]
      const result = value ? value : ''
      return result
    }
  }, [locale])

  const isLocaleEN = locale == LOCALE_EN
  const isLocaleKO = locale == LOCALE_KO

  return {
    t,
    locale,
    isLocaleEN,
    isLocaleKO,
    updateLandingLocale,
  }
}

export const useLocaleVars = () => {
  const { t, locale } = useTranslation()
  const vars = useMemo(() => {
    return getVars(t)
  }, [locale])

  return vars
}

export const getVars = (t) => {
  return {
    duration_list: [
      { label: t(s_pricing.package_option_1.twenty_min), value: 'twenty_minutes', number: 20 },
      { label: t(s_pricing.package_option_1.forty_min), value: 'forty_minutes', number: 40 },
    ],
    lesson_num_list: [
      { label: t(s_pricing.package_option_2.four_lessons_for_month), value: 'four_lessons_per_month' },
      { label: t(s_pricing.package_option_2.six_lessons_for_month), value: 'six_lessons_per_month' },
    ],
    package_option_list: [
      { label: t(s_pricing.package_option_3.book_weekly_one_lesson), value: 'weekly_lesson_package' },
      { label: t(s_pricing.package_option_3.book_free), value: 'normal' },
    ],
  }
}
