export const enum DROPDOWN_STATES {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export const enum DROPDOWN_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
