// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'
import { wordCountCalculate } from '../modules/utils'

const questionSlice = createSlice({
  name: 'question',
  initialState: {
    questions: [],
    wordCountList: [],
    checkedList: [],
  },
  reducers: {
    reset(state) {
      state.questions = []
      state.wordCountList = []
      state.wordCountList = []
    },
    initialize(state, action) {
      const initialCheckedList = action.payload.map((item, i) => {
        return {
          number: item?.question_number,
          checked: item.student_choice,
        }
      })
      const getInitialWordCountList = (qList) => {
        const tempArr = []
        qList.forEach((qItem) => {
          tempArr.push(wordCountCalculate(qItem.requested_text?.content))
        })
        return tempArr
      }
      state.questions = action.payload
      state.checkedList = initialCheckedList
      state.wordCountList = getInitialWordCountList(action.payload)
    },
    toggleShareStatus(state, action) {
      const targetIdx = state.questions.findIndex((item, i) => item?.question_number == action.payload.questionNumber)
      if (targetIdx > -1 && state.questions[targetIdx].student_answer) {
        state.questions[targetIdx].student_answer.share_status = !state.questions[targetIdx].student_answer.share_status
      } else {
        state.questions[targetIdx].student_answer = {
          ...state.questions[targetIdx].student_answer,
          share_status: true,
        }
      }
    },
    setWordCount(state, action) {
      const targetIdx = state.questions.findIndex((item) => item?.question_number == action.payload.questionNumber)
      if (state.wordCountList[action.payload.index]) {
        state.wordCountList[action.payload.index] = action.payload.count
      }
      if (targetIdx > -1 && state.questions[targetIdx]) {
        state.wordCountList[targetIdx] = action.payload.count
      }
    },
    setCheckedList(state, action) {
      state.checkedList = action.payload
    },
    setAnswer(state, action) {
      const targetIdx = state.questions.findIndex((item, i) => item?.question_number == action.payload.questionNumber)
      if (targetIdx > -1 && state.questions[targetIdx]) {
        if (state.questions[targetIdx].student_answer) {
          state.questions[targetIdx].student_answer.content = action.payload.answer
        } else {
          state.questions[targetIdx].student_answer = { content: action.payload.answer }
        }
      }
    },
    setTutorAnswer(state, action) {
      const targetIdx = state.questions.findIndex((item, i) => item?.question_number == action.payload.questionNumber)
      if (targetIdx > -1 && state.questions[targetIdx]) {
        if (state.questions[targetIdx].tutor_answer) {
          state.questions[targetIdx].tutor_answer.content = action.payload.answer
        } else {
          state.questions[targetIdx].tutor_answer = {
            content: action.payload.answer,
          }
        }
      }
    },
    setCorrection(state, action) {
      const targetIdx = state.questions.findIndex((item, i) => item?.question_number == action.payload.questionNumber)
      if (targetIdx > -1 && state.questions[targetIdx]) {
        if (state.questions[targetIdx].requested_text) {
          state.questions[targetIdx].requested_text.content = action.payload.correction
        } else {
          state.questions[targetIdx].requested_text = { content: action.payload.correction }
        }
      }
    },
  },
})

export const questionReducer = questionSlice.reducer
export const questionActions = questionSlice.actions
