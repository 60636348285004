// @ts-strict-ignore
// API ID
export const API_FETCH_ALL_COURSES = 'api/FETCH_ALL_COURSES' as const
export const API_FETCH_MY_TUTORS = 'api/FETCH_MY_TUTORS' as const
export const API_FETCH_AVAILABLE_TUTORS = 'api/FETCH_AVAILABLE_TUTORS' as const
export const API_FETCH_PREFERRED_TUTORS = 'api/FETCH_PREFERRED_TUTORS' as const
export const API_FETCH_RISING_STAR_TUTORS = 'api/FETCH_RISING_STAR_TUTORS' as const
export const API_FETCH_ALL_TUTORS = 'api/FETCH_ALL_TUTORS' as const
export const API_FETCH_NEW_UNOCCUPIED_TUTORS = 'api/FETCH_NEW_UNOCCUPIED_TUTORS' as const
export const API_FETCH_OLD_UNOCCUPIED_TUTORS = 'api/FETCH_OLD_UNOCCUPIED_TUTORS' as const
export const API_FETCH_SEARCHED_TUTORS = 'api/FETCH_SEARCHED_TUTORS' as const
export const API_FETCH_TUTOR_LIST = 'api/FETCH_TUTOR_LIST' as const
export const API_FETCH_TUTOR_PROFILE = 'api/FETCH_TUTOR_PROFILE' as const
export const API_FETCH_TUTOR_OPENED_TIME_SCHEDULE = 'api/FETCH_TUTOR_OPENED_TIME_SCHEDULE' as const
export const API_FETCH_TUTORS_BY_TIME = 'api/FETCH_TUTORS_BY_TIME' as const
export const API_TUTOR_SAVE_AND_DELETE = 'api/TUTOR_SAVE_AND_DELETE' as const
export const API_FETCH_TIME_SCHEDULE = 'api/FETCH_TIME_SCHEDULE' as const
export const API_SUBMIT_LESSONS = 'api/SUBMIT_LESSONS' as const
export const API_SUBMIT_EDIT_MATERIAL = 'api/SUBMIT_EDIT_MATERIAL' as const
export const API_SUBMIT_EDIT_TIME_SELECT_MATCHING = 'api/SUBMIT_EDIT_TIME_SELECT_MATCHING' as const
export const API_SUBMIT_EDIT_TIME_AUTO_MATCHING = 'api/SUBMIT_EDIT_TIME_AUTO_MATCHING' as const
export const API_VALIDATE_COUPON = 'api/VALIDATE_COUPON' as const
export const API_VALIDATE_COUPON_FOR_EDIT = 'api/VALIDATE_COUPON_FOR_EDIT' as const
export const API_UNBOOK = 'api/UNBOOK' as const
export const API_FETCH_MATERIALS = 'api/FETCH_MATERIALS' as const
export const API_FETCH_TRIAL_MATERIALS = 'api/FETCH_TRIAL_MATERIALS' as const
export const API_FETCH_LOGDATA = 'api/FETCH_LOGDATA' as const
export const API_FETCH_USER_HAS_LOGDATA = 'api/FETCH_USER_HAS_LOGDATA' as const
export const API_FETCH_MATERIAL_SEARCH = 'api/FETCH_MATERIAL_SEARCH' as const
export const API_FETCH_BLOG_SEARCH = 'api/FETCH_BLOG_SEARCH' as const
export const API_FETCH_RESOURCE_SEARCH = 'api/FETCH_RESOURCE_SEARCH' as const
export const API_FETCH_WEBINAR_SEARCH = 'api/FETCH_WEBINAR_SEARCH' as const
export const API_FETCH_REVOKE_GOOGLE_CALENDAR = 'api/FETCH_REVOKE_GOOGLE_CALENDAR' as const
export const API_FETCH_TUTOR_WEEKLY_SCHEDULE = 'api/FETCH_TUTOR_WEEKLY_SCHEDULE' as const
export const API_FETCH_GOOGLE_WEEKLY_SCHEDULE = 'api/FETCH_GOOGLE_WEEKLY_SCHEDULE' as const
export const API_FETCH_OPEN_SCHEDULE = 'api/FETCH_OPEN_SCHEDULE' as const
export const API_FETCH_DELETE_SCHEDULE = 'api/FETCH_DELETE_SCHEDULE' as const
export const API_FETCH_HANDLE_TUTOR_TIMEZONE = 'api/FETCH_HANDLE_TUTOR_TIMEZONE' as const
export const API_FETCH_REQUESTED_LESSONS = 'api/FETCH_REQUESTED_LESSONS' as const
export const API_FETCH_UNASSIGNED_LESSONS = 'api/FETCH_UNASSIGNED_LESSONS' as const

const init = {
  loading: false,
  data: null,
  error: null,
  payload: null,
}

const initialState = {
  [API_FETCH_LOGDATA]: init,
  [API_FETCH_ALL_COURSES]: init,
  [API_FETCH_MATERIALS]: init,
  [API_FETCH_TRIAL_MATERIALS]: init,
  [API_FETCH_TUTOR_PROFILE]: init,
  [API_FETCH_TUTOR_LIST]: init,
  [API_FETCH_MY_TUTORS]: init,
  [API_FETCH_AVAILABLE_TUTORS]: init,
  [API_FETCH_PREFERRED_TUTORS]: init,
  [API_FETCH_ALL_TUTORS]: init,
  [API_FETCH_NEW_UNOCCUPIED_TUTORS]: init,
  [API_FETCH_OLD_UNOCCUPIED_TUTORS]: init,
  [API_FETCH_RISING_STAR_TUTORS]: init,
  [API_FETCH_SEARCHED_TUTORS]: init,
  [API_FETCH_TUTORS_BY_TIME]: init,
  [API_FETCH_TUTOR_OPENED_TIME_SCHEDULE]: init,
  [API_TUTOR_SAVE_AND_DELETE]: init,
  [API_FETCH_TIME_SCHEDULE]: init,
  [API_SUBMIT_LESSONS]: init,
  [API_SUBMIT_EDIT_MATERIAL]: init,
  [API_SUBMIT_EDIT_TIME_SELECT_MATCHING]: init,
  [API_SUBMIT_EDIT_TIME_AUTO_MATCHING]: init,
  [API_VALIDATE_COUPON]: init,
  [API_VALIDATE_COUPON_FOR_EDIT]: init,
  [API_UNBOOK]: init,
  [API_FETCH_USER_HAS_LOGDATA]: init,
  [API_FETCH_MATERIAL_SEARCH]: init,
  [API_FETCH_BLOG_SEARCH]: init,
  [API_FETCH_RESOURCE_SEARCH]: init,
  [API_FETCH_WEBINAR_SEARCH]: init,
  [API_FETCH_REVOKE_GOOGLE_CALENDAR]: init,
  [API_FETCH_TUTOR_WEEKLY_SCHEDULE]: init,
  [API_FETCH_GOOGLE_WEEKLY_SCHEDULE]: init,
  [API_FETCH_OPEN_SCHEDULE]: init,
  [API_FETCH_DELETE_SCHEDULE]: init,
  [API_FETCH_HANDLE_TUTOR_TIMEZONE]: init,
  [API_FETCH_REQUESTED_LESSONS]: init,
  [API_FETCH_UNASSIGNED_LESSONS]: init,
}

// action type
export const FETCH = 'fetch/FETCH' as const
export const ASYNC_FETCH = 'fetch/ASYNC_FETCH' as const
const FETCH_SUCCESS = 'fetch/SUCCESS' as const
const FETCH_ERROR = 'fetch/ERROR' as const

// action function
export const fetchAction = (id, call, payload?, callback?) => ({ type: FETCH, id, call, payload, callback })
export const fetchSuccessAction = (id, data) => ({ type: FETCH_SUCCESS, id, data })
export const fetchErrorAction = (id, error) => ({ type: FETCH_ERROR, id, error })

const f = (action, func) => func(action)

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASYNC_FETCH:
    case FETCH:
      return f(action, ({ id, payload }) => {
        return {
          ...state,
          [id]: {
            payload: payload,
            loading: true,
            data: null,
            error: null,
          },
        }
      })
    case FETCH_SUCCESS:
      return f(action, ({ id, data }) => {
        return {
          ...state,
          [id]: {
            ...state[id],
            loading: false,
            data: data,
            error: null,
          },
        }
      })
    case FETCH_ERROR:
      return f(action, ({ id, error }) => {
        return {
          ...state,
          [id]: {
            ...state[id],
            loading: false,
            data: null,
            error: error,
          },
        }
      })
    default: {
      return state
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default fetchReducer
