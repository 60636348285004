//

import { ControlTooltipProps, GetClickTypeProps, TOOLTIP_CLICK_TYPE, TOOLTIP_TYPE } from '../types'

export const getType = (
  isOpenOnClick: boolean,
  hasCloseButton: boolean,
  linkComponent: React.ReactNode
): TOOLTIP_TYPE => {
  switch (true) {
    case hasCloseButton && !!linkComponent:
      return TOOLTIP_TYPE.TEXT_CLOSE_LINK
    case hasCloseButton:
      return TOOLTIP_TYPE.TEXT_CLOSE
    case !!linkComponent:
      return TOOLTIP_TYPE.TEXT_LINK
    default:
      return isOpenOnClick ? TOOLTIP_TYPE.TEXT_ONLY_CLICK : TOOLTIP_TYPE.TEXT_ONLY_AUTO
  }
}

export const getIsInitialOpen = (tooltipType: TOOLTIP_TYPE) => {
  return tooltipType !== TOOLTIP_TYPE.TEXT_ONLY_CLICK
}

export const getIsOpenOnClick = (tooltipType: TOOLTIP_TYPE) => {
  return tooltipType === TOOLTIP_TYPE.TEXT_ONLY_CLICK
}

export const getClickType = (props: GetClickTypeProps): TOOLTIP_CLICK_TYPE => {
  const { e, tooltipContainerRef, triggerRef, linkRef } = props

  switch (true) {
    case triggerRef.current?.contains(e.target as Node):
      return TOOLTIP_CLICK_TYPE.TRIGGER
    case linkRef?.current?.contains(e.target as Node):
      return TOOLTIP_CLICK_TYPE.LINK
    case tooltipContainerRef.current?.contains(e.target as Node):
      return TOOLTIP_CLICK_TYPE.TOOLTIP
    default:
      return TOOLTIP_CLICK_TYPE.OUTSIDE
  }
}

export const controlTooltip = (props: ControlTooltipProps) => {
  const { tooltipType, clickType, setIsOpen } = props

  switch (tooltipType) {
    case TOOLTIP_TYPE.TEXT_ONLY_AUTO:
      setIsOpen(false)
      break
    case TOOLTIP_TYPE.TEXT_ONLY_CLICK:
      switch (clickType) {
        case TOOLTIP_CLICK_TYPE.TRIGGER:
          setIsOpen((prev) => !prev)
          break
        default:
          setIsOpen(false)
      }
      break
    case TOOLTIP_TYPE.TEXT_CLOSE:
      switch (clickType) {
        case TOOLTIP_CLICK_TYPE.TRIGGER:
          setIsOpen(false)
      }
      break
    case TOOLTIP_TYPE.TEXT_LINK:
      setIsOpen(false)
      break
    case TOOLTIP_TYPE.TEXT_CLOSE_LINK:
      switch (clickType) {
        case TOOLTIP_CLICK_TYPE.TRIGGER:
        case TOOLTIP_CLICK_TYPE.LINK:
          setIsOpen(false)
      }
      break
  }
}
