import { create } from 'zustand'

interface Geo {
  city: string
  country: string
  country_code: string
  currency: string
  timezone: string
}

interface Session {
  isLoggedIn: boolean
  currentUser: any
  token: string | null
  currentGeo: Geo
}

interface UserState {
  session: Session
  hackleVariation: string
  login: (payload: { currentUser: any; token: string }) => void
  updateUser: (payload: { currentUser: any; token: string }) => void
  updateCurrUser: (currentUser: any) => void
  logout: () => void
  updateGeo: (currentGeo: Geo) => void
  updateHackleVariation: ({ hackleVariation }: { hackleVariation: string }) => void
}

const useUserStore = create<UserState>((set) => ({
  session: {
    isLoggedIn: false,
    currentUser: null,
    token: null,
    currentGeo: {
      city: '',
      country: '',
      country_code: '',
      currency: '',
      timezone: '',
    },
  },
  hackleVariation: '',
  login: ({ currentUser, token }) =>
    set((state) => ({
      session: {
        ...state.session,
        currentUser,
        isLoggedIn: true,
        token,
      },
    })),
  updateUser: ({ currentUser, token }) =>
    set((state) => ({
      session: {
        ...state.session,
        currentUser,
        isLoggedIn: true,
        token,
      },
    })),
  updateCurrUser: (currentUser) =>
    set((state) => ({
      session: {
        ...state.session,
        currentUser,
      },
    })),
  logout: () =>
    set((state) => ({
      session: {
        ...state.session,
        currentUser: null,
        isLoggedIn: false,
        token: null,
      },
    })),
  updateGeo: (currentGeo) =>
    set((state) => ({
      session: {
        ...state.session,
        currentGeo,
      },
    })),
  updateHackleVariation: (hackleVariation) =>
    set(() => ({
      ...hackleVariation,
    })),
}))

export default useUserStore
