// @ts-strict-ignore
import produce from 'immer'

// // 액션 타입
export const INIT = 'mocksession/INIT'
export const CHOOSE_QUIZ_CHOICE = 'mocksession/CHOOSE_QUIZ_CHOICE' as const
export const SET_QUIZ_RESULTS = 'mocksession/SET_QUIZ_RESULTS' as const
export const SET_QUIZ_LIST = 'mocksession/SET_QUIZ_LIST' as const
export const SET_VIDEO_WATCHED = 'mocksession/SET_VIDEO_WATCHED' as const

export const initAction = () => ({ type: INIT })
//퀴즈 시험 페이지에서 답안 선택
export const chooseQuizChoice = (idx: number, choiceId: number) => ({ type: CHOOSE_QUIZ_CHOICE, idx, choiceId })
//OT 동영상 시청 완료 여부
export const setVideoWatched = (step, time) => ({ type: SET_VIDEO_WATCHED, step, time })

// 초기 상태
const initialState = {
  quizChoices: {},
  watchedClips: [],
}

const f = (action, func) => func(action)

// 리듀서
const mocksessionReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case INIT:
        return {
          ...initialState,
        }
      // quiz 페이지 - 답변 선택시 저장 (유효성 체크)
      case CHOOSE_QUIZ_CHOICE:
        return f(action, ({ idx, choiceId }) => {
          draft.quizChoices[`quiz${idx}`] = choiceId
        })
      // ot 비디오 - 시청시간 저장
      case SET_VIDEO_WATCHED:
        return f(action, ({ step, time }) => {
          if (draft.watchedClips.findIndex((v) => v.id === step) == -1) {
            draft.watchedClips.unshift({ id: step, playbacktime: time })
          }
          draft.watchedClips[draft.watchedClips.findIndex((v) => v.id === step)] = { id: step, playbacktime: time }
        })

      default:
        return state
    }
  })
}

export default mocksessionReducer
