import { CHIP_SIZE, CHIP_TYPE, Chip, DSInput, DSTooltipProps, Switch } from '@repo/design-system/index'
import { CodeClassName, ControlClassName, descriptionClassName, subHeaderClassName } from '../../style'

interface SectionTooltipControlsProps {
  text: string
  setText: React.Dispatch<React.SetStateAction<string>>
  place: 'top' | 'right' | 'bottom' | 'left'
  setPlace: React.Dispatch<React.SetStateAction<'top' | 'right' | 'bottom' | 'left'>>
  isMultiline: boolean
  setIsMultiline: React.Dispatch<React.SetStateAction<boolean>>
  remountFlag: boolean
  setRemountFlag: React.Dispatch<React.SetStateAction<boolean>>
}

const SectionTooltipControls = ({
  text,
  setText,
  place,
  setPlace,
  isMultiline,
  setIsMultiline,
  remountFlag,
  setRemountFlag,
}: SectionTooltipControlsProps) => {
  const placeArray = ['right', 'left', 'top', 'bottom'] as DSTooltipProps['place'][]

  return (
    <section className="mt-[8px] flex flex-col border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
      <div className={subHeaderClassName}>SectionTooltipControls</div>
      <div className="flex flex-col gap-[8px]">
        <div>
          <div className={ControlClassName}>content*</div>
          <div className="flex flex-row w-[300px]">
            <DSInput
              type="text"
              size="sm"
              value={text}
              setValue={setText}
              placeholder="tooltip content"
              clearable
              required
              name="tooltip content"
              className="bg-gray-50"
            />
          </div>
        </div>

        <div>
          <div className={ControlClassName}>place*</div>
          <div className={descriptionClassName}>
            <p className="text-green-600">
              * For Developer: 툴팁의 위치가 이상하다면 <code className={CodeClassName}>DSTooltip</code> 의 상위
              컴포넌트가 충분한 너비나 높이를 가지고 있는지 확인하세요.
            </p>
          </div>
          <div className="flex flex-row gap-[12px] max-w-max mt-[12px]">
            {placeArray.map((item, index) => {
              return (
                <Chip
                  type={CHIP_TYPE.CHOICE}
                  size={CHIP_SIZE.SM}
                  onClick={(e) => {
                    setPlace(item)
                    setRemountFlag(!remountFlag)
                  }}
                  initialSelected={place == item}
                  key={index}
                >
                  <Chip.Label>{item}</Chip.Label>
                </Chip>
              )
            })}
          </div>
        </div>

        <div>
          <div className={ControlClassName}>multiline</div>
          <div className="flex flex-row h-[24px] gap-[28px] items-center">
            <Switch checked={isMultiline} setChecked={setIsMultiline}></Switch>
            <div className={descriptionClassName}>{isMultiline && '(content에 문장을 입력해 보세요)'}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTooltipControls
