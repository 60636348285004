export enum LandingState {
  PORTAL = 'portal',
  LANDING = 'landing',
}

export enum PURCHASE_USER_TYPE {
  FIRST_PURCHASE, // 첫 구매 고객
  REGULAR_PURCHASE, // 재 구매 고객
}

export enum STUDENT_PORTAL_BTN_ID {
  APPLY_OT = 'click_apply_ot',
  APPLY_STUDY_WEEK = 'click_apply_study_week',
  BOOK_LESSON = 'click_book_lesson',
}

export enum STUDENT_TAP_ID {
  SEE_BENEFIT = 'click_tab_see_benefit',
  PRESENTATION = 'click_tab_presentation',
}

export enum STUDENT_BTN_ID {
  SIGN_IN = 'click_sign_in',
  SIGN_UP = 'click_sign_up',
  CLICK_NAVIGATION_LOGIN = 'click_navigation_login',
  CLICK_NAVIGATION_LOGOUT = 'click_navigation_logout',
  SEE_PRICE = 'click_see_price',
  PRESENTATION = 'click_presentation',
  STUDY_WEEK = 'click_study_week',
  REGISTER_OT = 'click_register_OT',
  SHARE_LINK = 'click_share_link',
  SIGN_IN_SOCIAL = 'click_sign_in_social',
  SEE_PROMO = 'click_see_promo',
  SEE_DETAIL_PRICE = 'click_see_detail_price',
  CLICK_PURCHASE = 'click_purchase',
  CLICK_PURCHASE_FAQ = 'click_purchase_faq',
  CLICK_PRODUCT_CARD = 'click_product_card', // 가격페이지 B안
  BANNER_MULTIMEDIA = 'click_banner_multimedia',
  CLICK_REGISTER_WEBINAR = 'click_register_webinar',
  CLICK_ENTER_WEBINAR = 'click_enter_webinar',
  ClICK_START_RINGLE = 'click_start_ringle',
  CLICK_SIGN_UP_METHOD = 'click_sign_up_method',
  CLICK_REGISTER_TRIAL = 'click_register_trial',
  CLICK_INVITE = 'click_invite',
  CLICK_SHARE = 'click_share',
  CLICK_PURCHASE_CONFERENCE = 'click_purchase_conference',
  CLICK_BOOK_LESSON = 'click_book_lesson',
  CLICK_B2B_TRACKER = 'click_b2b_tracker',
  CLICK_B2B_TRACKER_BOOK_LESSON = 'click_b2b_tracker_book_lesson',
  CLICK_MY_CREDITS_VIEW_MORE = 'click_my_credits_view_more',
  CLICK_DROPDOWN_CREDITS = 'click_dropdown_credits',
  CLICK_TAB_BOOK_LESSON = 'click_tab_book_lesson',
  CLICK_SELECT_CREDIT = 'click_select_credit',
  CLICK_SELECT_TUTOR = 'click_select_tutor',
  CLICK_SELECT_TUTOR_AVATAR = 'click_select_tutor_avatar',
  CLICK_NEXT_LESSON_SCHEDULE = 'click_next_lesson_schedule',
  CLICK_MATERIAL_CATEGORY = 'click_material_category',
  CLICK_MATERIAL_HASHTAG = 'click_material_hashtag',
  CLICK_SEE_MATERIAL_DETAIL = 'click_see_material_detail',
  CLICK_SELECT_MATERIAL = 'click_select_material',
  CLICK_BOOKMARK_MATERIAL = 'click_bookmark_material',
  CLICK_SEARCH_MATERIAL = 'click_search_material',
  CLICK_EXIT_BOOK_LESSON = 'click_exit_book_lesson',
  CLICK_BOOK_ANOTHER_LESSON = 'click_book_another_lesson',
  CLICK_SET_LESSON_STYLE = 'click_set_lesson_style',
  CLICK_INSTALL = 'click_install',
  CLICK_PAST_LESSON_LIST_FILTER = 'click_past_lesson_list_filter',
  CLICK_PAST_LESSON_LIST_CALENDAR_CHEVRON = 'click_past_lesson_list_calendar_chevron',
  CLICK_PAST_LESSON_THIS_MONTH = 'click_past_lesson_this_month',
  CLICK_PAST_LESSON_RECENT_LESSON = 'click_past_lesson_recent_lesson',
  CLICK_PAST_LESSON_PAGINATION = 'click_past_lesson_pagination',
  CLICK_MENU = 'click_menu',
  CLICK_NAVIGATION_MY_CLASS = 'click_navigation_my_class',
  CLICK_HOME_RINGLE_LOGO = 'click_home_ringle_logo',
  CLICK_MENU_LOGIN = 'click_menu_login',
  CLICK_MENU_LOGOUT = 'click_menu_logout',
  CLICK_MENU_WHAT_IS_RINGLE = 'click_menu_what_is_ringle',
  CLICK_MENU_TUTOR = 'click_menu_tutor',
  CLICK_MENU_MATERIALS = 'click_menu_materials',
  CLICK_MENU_FEATURES = 'click_menu_features',
  CLICK_MENU_RINGLE_FOR = 'click_menu_ringle_for',
  CLICK_MENU_GLOBAL_PROFESSIONALS = 'click_menu_global_professionals',
  CLICK_MENU_OVERSEAS_RESIDENTS = 'click_menu_overseas_residents',
  CLICK_MENU_INTERNATIONAL_STUDENTS = 'click_menu_international_students',
  CLICK_MENU_BEGINNER_INTERMEDIATE = 'click_menu_beginner_intermediate',
  CLICK_MENU_BUSINESS_ENGLISH = 'click_menu_business_english',
  CLICK_MENU_INTERVIEW_PREP = 'click_menu_interview_prep',
  CLICK_MENU_RESUME_ESSAY = 'click_menu_resume_essay',
  CLICK_MENU_PRICING = 'click_menu_pricing',
  CLICK_MENU_CONFERENCE = 'click_menu_conference',
  CLICK_MENU_BUSINESS = 'click_menu_business',
  CLICK_MENU_EVENT = 'click_menu_event',
  CLICK_MENU_BUSINESS_VOUCHER = 'click_menu_business_voucher',
  CLICK_MENU_BUSINESS_B2B = 'click_menu_business_b2b',
  CLICK_MENU_HELP = 'click_menu_help',
  CLICK_MENU_PROMOTION = 'click_menu_promotion',
  CLICK_MENU_CHALLENGE = 'click_menu_challenge',
  CLICK_MENU_COMPETITION = 'click_menu_competition',
  CLICK_MENU_STUDENT_2023 = 'click_menu_student_2023',
  CLICK_MENU_BECOME_A_TUTOR = 'click_menu_become_a_tutor',
  CLICK_MENU_CHANGE_LANG = 'click_menu_change_lang',
  CLICK_SECTION_THREE_TUTOR = 'click_section_3_tutor',
  CLICK_SECTION_FOUR_FEATURE = 'click_section_4_feature',
  CLICK_SECTION_FIVE_MATERIALS = 'click_section_5_materials',
  CLICK_SECTION_SIX_STORY = 'click_section_6_story',

  // AB 테스트 - B/C/D안
  CLICK_MENU_CHANGE_LANG_EN = 'click_menu_change_lang_en',
  CLICK_MENU_CHANGE_LANG_KO = 'click_menu_change_lang_ko',
  CLICK_MENU_CHANGE_LANG_JA = 'click_menu_change_lang_ja',
  CLICK_MENU_CHANGE_LANG_ZH_CHS = 'click_menu_change_lang_zh_chs',
  CLICK_MENU_CHANGE_LANG_ZH_CHT = 'click_menu_change_lang_zh_cht',
  CLICK_MENU_CHANGE_LANG_VI = 'click_menu_change_lang_vi',

  CLICK_SECTION_REVIEW = 'click_section_review',
  CLICK_SECTION_TUTOR = 'click_section_tutor',
  CLICK_SECTION_MATEIRALS = 'click_section_materials',
  CLICK_SECTION_PRICING = 'click_section_pricing',
  CLICK_SECTION_SYSTEM = 'click_section_system',
  CLICK_SECTION_FAQ = 'click_section_faq',
  CLICK_SECTION_PROMOTION = 'click_section_promotion',

  // 프로모션
  CLICK_REGISTER_STUDY_CLUB = 'click_register_study_club',

  // AB 테스트 V2
  CLICK_BOTTOM_NAVIGATION_HOME = 'click_bottom_navigation_home',
  CLICK_BOTTOM_NAVIGATION_MATERIAL = 'click_bottom_navigation_material',
  CLICK_BOTTOM_NAVIGATION_TUTOR = 'click_bottom_navigation_tutor',
  CLICK_BOTTOM_NAVIGATION_PRICING = 'click_bottom_navigation_pricing',
  CLICK_BOTTOM_NAVIGATION_PROMOTION = 'click_bottom_navigation_promotion',
  CLICK_BOTTOM_NAVIGATION_HELP = 'click_bottom_navigation_help',
  CLICK_FOOTER_BUTTON = 'click_footer_button',
  CLICK_SECTION_REVIEW_VIDEO = 'click_section_review_video',

  // 웰컴 페이지
  CLICK_WELCOME_PAGE_BTN = 'click_welcome_page_btn',

  // 수업 평가 23.02.21 by Jeongseop
  CLICK_LESSON_EVALUATION_BUTTON = 'click_lesson_eveluation_button',
  CLICK_INCOMPLETE_LESSON_REPORT_SUBMIT_BUTTON = 'click_incomplete_lesson_report_submit_button',

  //Portal Home Renewal 23.03.10 by Jeongseop
  CLICK_PHR_ONSITE_MULTI_ALL = 'click_onsite_multi_all',
  CLICK_PHR_RINGLE_PICK = 'click_ringle_pick',
  CLICK_PHR_NEW_TUTOR_ALL = 'click_new_tutor_all',
  CLICK_PHR_BANNER_RECOM_TUTOR = 'click_banner_recom_tutor',
  CLICK_PHR_CLICK_CAROUSAL_TIPS = 'click_carousal_tips',
  CLICK_PHR_MATERIAL = 'click_material',
  CLICK_PHR_MATERIAL_BOOKMARK = 'click_material_bookmark',
}

/**
 * Custom Page View ID
 */

export enum STUDENT_PAGE_VIEW_ID {
  LANDING_HOME = 'view_landing_home',
  LANDING_WELCOME = 'view_landing_welcome',
  LANDING_WHAT_IS_RINGLE = 'view_landing_what_is_ringle',
  LANDING_PROMOTION = 'view_landing_promotion',
  LANDING_PRICING = 'view_landing_pricing',
  LANDING_WEBINAR = 'view_landing_webinar',
  LANDING_EXCLASS = 'view_landing_exclass',
  LANDING_TRIAL = 'view_landing_trial',
  LANDING_CONFERENCE = 'view_landing_conference',
  LANDING_ENGLISH_TUTORS = 'view_landing_english_tutors',
  LANDING_ENGLISH_LEARNING_MATERIALS = 'view_landing_english_learning_materials',
  LANDING_ENGLISH_LEARNING_PLATFORM = 'view_landing_english_learning_platform',
  LANDING_CASE_CUSTOMER_PROFESSIONAL = 'view_landing_case_customer_professional',
  LANDING_CASE_CUSTOMER_LIVE_ABROAD = 'view_landing_case_customer_live_abroad',
  LANDING_CASE_CUSTOMER_STUDY_ABROAD = 'view_landing_case_customer_study_abroad',
  LANDING_CASE_NEED_BUSINESS_ENLGISH = 'view_landing_case_need_business_english',
  LANDING_CASE_NEED_INTERVIEW_PREP = 'view_landing_case_need_interview_prep',
  LANDING_CASE_NEED_RESUME_ESSAY_EDITING = 'view_landing_case_need_resume_essay_editing',
  LANDING_MATERIAL_AD = 'view_landing_material_ad',
  AUTH_NEW = 'view_sign_up',
  AUTH_SIGN_UP = 'view_sign_up_input',
  AUTH_ADDITIONAL_INFO = 'view_sign_up_additional_info',
  AUTH_SIGN_IN = 'view_sign_in',
  PORTAL_WELCOME = 'view_portal_welcome',
  PORTAL_PRICING = 'view_portal_pricing',
  PORTAL_HOME = 'view_portal_home',
  PORTAL_CHECKOUT = 'view_portal_checkout',
  PORTAL_WEBINAR = 'view_portal_webinar',
  PORTAL_EXCLASS = 'view_portal_exclass',
  PORTAL_LESSON_SCHEDULE_ENTER = 'view_book_lesson',
  PORTAL_LESSON_SCHEDULE = 'view_book_lesson_detail',
  PORTAL_INVITE = 'view_invite',
  PORTAL_PROMOTION = 'view_portal_promotion',
  LESSON_CLASSROOM = 'view_lesson_classroom',
  LESSON_REVIEW = 'view_lesson_review',

  //수업 평가 모달
  PORTAL_LESSON_EVALUATION_MODAL = 'view_lesson_evaluation_modal',
  PORTAL_INCOMPLETE_LESSON_REPORT = 'view_incomlete_lesson_report',
  //추천 튜터 페이지
  PORTAL_RECOMMENDED_TUTOR = 'view_portal_recommended_tutor',
}

/**
 * UI Element IDs
 */

export enum ELEM_STUDENT_PORTAL_HOME_BTN_ID {
  UNASSIGNED = 'unassigned',
  UPCOMING = 'upcoming',
  REVIEW_INCOMPLETE = 'review_incomplete',
  RESERVE_LESSON = 'reserve_lesson',
  PURCHASE = 'purchase',
  INVITE = 'invite',
}

export const ELEM_ID_TO_STRING = {
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.UNASSIGNED]: 'Make-up Lessons',
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.UPCOMING]: 'Upcoming Lesson',
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.REVIEW_INCOMPLETE]: 'Review Incomplete',
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.RESERVE_LESSON]: 'Book',
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.PURCHASE]: 'Purchase',
  [ELEM_STUDENT_PORTAL_HOME_BTN_ID.INVITE]: 'Invite',
}

export enum LANDING_HOME_BUTTON_CATEGORY {
  SECTION,
  MENU,
}
