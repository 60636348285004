/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any }
}

/** Achievement Campaign */
export type AchievementCampaign = {
  __typename?: 'AchievementCampaign'
  /** Guide after completion */
  completionGuide: Scalars['String']['output']
  /** Icon image URL after completion */
  completionIconImageUrl: Scalars['String']['output']
  /** Icon image URL while in progress */
  iconImageUrl: Scalars['String']['output']
  /** ID */
  id: Scalars['ID']['output']
  /** key */
  key: AchievementCampaignKey
  /** My achievement stages, order by level */
  myAchievementStages: UserAchievementStageConnection
  /** stages, order by level */
  stages: AchievementStageConnection
  /** when the campaign starts at */
  startAt: Scalars['ISO8601DateTime']['output']
  /** status */
  status: AchievementCampaignStatus
  /** Title */
  title: Scalars['String']['output']
}

/** Achievement Campaign */
export type AchievementCampaignCompletionGuideArgs = {
  locale?: InputMaybe<Locale>
}

/** Achievement Campaign */
export type AchievementCampaignMyAchievementStagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Achievement Campaign */
export type AchievementCampaignStagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Achievement Campaign */
export type AchievementCampaignTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** The connection type for AchievementCampaign. */
export type AchievementCampaignConnection = {
  __typename?: 'AchievementCampaignConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AchievementCampaignEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AchievementCampaign>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type AchievementCampaignEdge = {
  __typename?: 'AchievementCampaignEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<AchievementCampaign>
}

/** keys of achievement campaign */
export enum AchievementCampaignKey {
  /** Detail Detective */
  NumberCheckFeedback = 'NUMBER_CHECK_FEEDBACK',
  /** Referral Rockstar */
  NumberInviteFriend = 'NUMBER_INVITE_FRIEND',
  /** English Enthusiast */
  NumberTakenLessons = 'NUMBER_TAKEN_LESSONS',
  /** Tutor Trailblazer */
  NumberUniqueTutor = 'NUMBER_UNIQUE_TUTOR',
  /** Attendance Ace */
  SuccessiveWeekDailyStudy = 'SUCCESSIVE_WEEK_DAILY_STUDY',
  /** Streak Scholar */
  SuccessiveWeekLesson = 'SUCCESSIVE_WEEK_LESSON',
  /** Zealous Bookworm */
  WhetherTakeWebinar = 'WHETHER_TAKE_WEBINAR',
}

/** Status of achievement campaign */
export enum AchievementCampaignStatus {
  /** Active */
  Active = 'ACTIVE',
  /** campaign is not started yet */
  Upcoming = 'UPCOMING',
}

/** Achievement Stage */
export type AchievementStage = {
  __typename?: 'AchievementStage'
  /** Guide */
  guide: Scalars['String']['output']
  /** ID */
  id: Scalars['ID']['output']
  /** level */
  level: Scalars['Int']['output']
}

/** Achievement Stage */
export type AchievementStageGuideArgs = {
  locale?: InputMaybe<Locale>
}

/** The connection type for AchievementStage. */
export type AchievementStageConnection = {
  __typename?: 'AchievementStageConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AchievementStageEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AchievementStage>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type AchievementStageEdge = {
  __typename?: 'AchievementStageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<AchievementStage>
}

/** A badge */
export type Badge = {
  __typename?: 'Badge'
  /** ID */
  id: Scalars['ID']['output']
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
}

/** A badge */
export type BadgeTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** Challenge campaign */
export type ChallengeCampaign = {
  __typename?: 'ChallengeCampaign'
  /** Banner image for the campaign */
  bannerImageUrl: Scalars['String']['output']
  /** When the challenge ends */
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** additional notes */
  notices: Array<Scalars['String']['output']>
  /** Guide */
  guide?: Maybe<Scalars['String']['output']>
  /** Icon image for the campaign */
  iconImageUrl: Scalars['String']['output']
  /** ID */
  id: Scalars['ID']['output']
  /** Registration by the current user for this campaign */
  myRegistration?: Maybe<ChallengeRegistration>
  /** Challenge options */
  options: Array<ChallengeCampaignOption>
  /** keyword to identify the promotion or the event associated with this campaign */
  promotionKey?: Maybe<Scalars['String']['output']>
  /** When the challenge registration ends */
  registrationEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** When the challenge registration starts */
  registrationStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Number of registrations */
  registrationsCount?: Maybe<Scalars['Int']['output']>
  /** When the challenge starts */
  startAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Status of the campaign */
  status: ChallengeCampaignStatus
  /** Title */
  title?: Maybe<Scalars['String']['output']>
}

/** Challenge campaign */
export type ChallengeCampaignExtraNotesArgs = {
  locale?: InputMaybe<Locale>
}

/** Challenge campaign */
export type ChallengeCampaignGuideArgs = {
  locale?: InputMaybe<Locale>
}

/** Challenge campaign */
export type ChallengeCampaignOptionsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Challenge campaign */
export type ChallengeCampaignTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** The connection type for ChallengeCampaign. */
export type ChallengeCampaignConnection = {
  __typename?: 'ChallengeCampaignConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChallengeCampaignEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChallengeCampaign>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ChallengeCampaignEdge = {
  __typename?: 'ChallengeCampaignEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ChallengeCampaign>
}

/** Challenge campaign option */
export type ChallengeCampaignOption = {
  __typename?: 'ChallengeCampaignOption'
  /** Badge image for the campaign option */
  badgeImageUrl: Scalars['String']['output']
  /** Associated challenge campaign */
  campaign: ChallengeCampaign
  /** Goal */
  goal: QuestGoal
  /** ID */
  id: Scalars['ID']['output']
  /** Number of registrations */
  registrationsCount?: Maybe<Scalars['Int']['output']>
  /** Challenge rewards */
  rewards: Array<ChallengeReward>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
}

/** Challenge campaign option */
export type ChallengeCampaignOptionTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** Status of campaign */
export enum ChallengeCampaignStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Ended */
  Ended = 'ENDED',
  /** Not started yet but open for registration */
  OpenForRegistration = 'OPEN_FOR_REGISTRATION',
  /** Unknown status */
  Unknown = 'UNKNOWN',
  /** Registration is not started yet */
  Upcoming = 'UPCOMING',
}

export const EmptyChallengeObj: ChallengeCampaign = {
  __typename: 'ChallengeCampaign',
  bannerImageUrl: '',
  notices: [],
  iconImageUrl: '',
  id: '',
  options: [],
  status: ChallengeCampaignStatus.Unknown,
}

/** Challenge registration */
export type ChallengeRegistration = {
  __typename?: 'ChallengeRegistration'
  /** Associated challenge campaign */
  campaign: ChallengeCampaign
  /** When it's completed */
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** ID */
  id: Scalars['ID']['output']
  /** Whether the user completed the challenge */
  isCompleted?: Maybe<Scalars['Boolean']['output']>
  /** Whether the user checked the completion */
  isCompletionChecked: Scalars['Boolean']['output']
  /** Associated challenge campaign option */
  option: ChallengeCampaignOption
  /** Progress */
  progress: QuestProgress
}

/** The connection type for ChallengeRegistration. */
export type ChallengeRegistrationConnection = {
  __typename?: 'ChallengeRegistrationConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChallengeRegistrationEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChallengeRegistration>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ChallengeRegistrationEdge = {
  __typename?: 'ChallengeRegistrationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ChallengeRegistration>
}

/** Challenge rewards */
export type ChallengeReward = {
  __typename?: 'ChallengeReward'
  /** Challenge Campaign Option */
  campaignOption: ChallengeCampaignOption
  /** Description */
  description?: Maybe<Scalars['String']['output']>
  /** Icon image url */
  iconImageUrl: Scalars['String']['output']
  /** ID */
  id: Scalars['ID']['output']
  /** Name */
  name: Scalars['String']['output']
}

/** Challenge rewards */
export type ChallengeRewardDescriptionArgs = {
  locale?: InputMaybe<Locale>
}

/** Challenge rewards */
export type ChallengeRewardNameArgs = {
  locale?: InputMaybe<Locale>
}

/** Quest goal for counting lessons entered */
export type CountQuestProgress = {
  __typename?: 'CountQuestProgress'
  /** Current value */
  current: Scalars['Int']['output']
  /** Target value */
  target: Scalars['Int']['output']
}

/** A course */
export type Course = {
  __typename?: 'Course'
  /** ID */
  id: Scalars['ID']['output']
  /** Subtitle */
  subtitle?: Maybe<Scalars['String']['output']>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
}

/** A course */
export type CourseSubtitleArgs = {
  locale?: InputMaybe<Locale>
}

/** A course */
export type CourseTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** Input fields for creating a challenge campaign */
export type CreateChallengeCampaignInput = {
  /** badge image url */
  badgeImageUrl: Scalars['String']['input']
  /** badge title en */
  badgeTitleEn: Scalars['String']['input']
  /** badge title ko */
  badgeTitleKo: Scalars['String']['input']
  /** campaign banner image url */
  campaignBannerImageUrl: Scalars['String']['input']
  /** campaign guide en */
  campaignGuideEn: Scalars['String']['input']
  /** campaign guide ko */
  campaignGuideKo: Scalars['String']['input']
  /** campaign icon image url */
  campaignIconImageUrl: Scalars['String']['input']
  /** campaign title en */
  campaignOptionTitleEn: Scalars['String']['input']
  /** campaign title ko */
  campaignOptionTitleKo: Scalars['String']['input']
  /** campaign title en */
  campaignTitleEn: Scalars['String']['input']
  /** campaign title ko */
  campaignTitleKo: Scalars['String']['input']
  /** End time of the campaign */
  endAt: Scalars['ISO8601DateTime']['input']
  /** End time of the registration */
  registrationEndAt: Scalars['ISO8601DateTime']['input']
  /** Start time of the registration */
  registrationStartAt: Scalars['ISO8601DateTime']['input']
  /** Start time of the campaign */
  startAt: Scalars['ISO8601DateTime']['input']
}

/** Autogenerated return type of CreateChallengeCampaign. */
export type CreateChallengeCampaignPayload = {
  __typename?: 'CreateChallengeCampaignPayload'
  /** Newly created challenge campaign field */
  campaign?: Maybe<Array<ChallengeCampaign>>
  /** message field */
  message: Array<Scalars['String']['output']>
  /** success check field */
  success: Scalars['Boolean']['output']
}

/** A 1:1 lesson */
export type Lesson = {
  __typename?: 'Lesson'
  /** Course selected for the lesson */
  course?: Maybe<Course>
  /** ID */
  id: Scalars['ID']['output']
  /** When the lesson starts */
  startAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for Lesson. */
export type LessonConnection = {
  __typename?: 'LessonConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LessonEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Lesson>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** Lesson duration */
export enum LessonDuration {
  /** 20 or 40 minutes */
  All = 'ALL',
  /** 40 minutes */
  Forty = 'FORTY',
  /** 20 minutes */
  Twenty = 'TWENTY',
}

/** An edge in a connection. */
export type LessonEdge = {
  __typename?: 'LessonEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Lesson>
}

/** Quest goal for counting lessons finished */
export type ConversationFinishedCountQuestGoal = {
  __typename?: 'ConversationFinishedCountQuestGoal'
  /** Number of lessons to target */
  target: Scalars['Int']['output']
}

/** Quest goal for counting lessons finished */
export type LessonFinishedCountQuestGoal = {
  __typename?: 'LessonFinishedCountQuestGoal'
  /** Lesson duration */
  lessonDuration: LessonDuration
  /** Number of lessons to target */
  target: Scalars['Int']['output']
}

/** Locale (ICU) */
export enum Locale {
  /** English (United States) */
  EnUs = 'EN_US',
  /** Korean (South Korea) */
  KoKr = 'KO_KR',
}

/** Mutation */
export type Mutation = {
  __typename?: 'Mutation'
  /** create_challenge_campaign field */
  createChallengeCampaign?: Maybe<CreateChallengeCampaignPayload>
  /** register_for_challenge_campaign_option field */
  registerForChallengeCampaignOption?: Maybe<RegisterForChallengeCampaignOptionPayload>
  /** update_challenge_campaign field */
  updateChallengeCampaign?: Maybe<UpdateChallengeCampaignPayload>
}

/** Mutation */
export type MutationCreateChallengeCampaignArgs = {
  input: CreateChallengeCampaignInput
}

/** Mutation */
export type MutationRegisterForChallengeCampaignOptionArgs = {
  campaignOptionId: Scalars['ID']['input']
}

/** Mutation */
export type MutationUpdateChallengeCampaignArgs = {
  campaignId: Scalars['ID']['input']
  input: UpdateChallengeCampaignInput
}

/** Key of notification setting */
export enum NotificationKey {
  /** activities */
  Activities = 'ACTIVITIES',
  /** bite size feedback */
  BiteSizeFeedback = 'BITE_SIZE_FEEDBACK',
  /** content weekly */
  ContentWeekly = 'CONTENT_WEEKLY',
  /** credit */
  Credit = 'CREDIT',
  /** daily brief update */
  DailyBriefUpdate = 'DAILY_BRIEF_UPDATE',
  /** event */
  Event = 'EVENT',
  /** lesson reminder */
  LessonReminder = 'LESSON_REMINDER',
  /** lesson schedule update */
  LessonScheduleUpdate = 'LESSON_SCHEDULE_UPDATE',
  /** lesson update */
  LessonUpdate = 'LESSON_UPDATE',
  /** news letter */
  Newsletter = 'NEWSLETTER',
  /** point */
  Point = 'POINT',
  /** ringle live reminder */
  RingleLiveReminder = 'RINGLE_LIVE_REMINDER',
}

/** Notification Setting Option */
export type NotificationSettingOption = {
  __typename?: 'NotificationSettingOption'
  /** description */
  description?: Maybe<Scalars['String']['output']>
  /** ID */
  id: Scalars['ID']['output']
  /** option key */
  key: Scalars['String']['output']
  /** option type */
  optionSort: NotificationSettingOptionSort
  /** title */
  title: Scalars['String']['output']
}

/** Notification Setting Option */
export type NotificationSettingOptionDescriptionArgs = {
  locale?: InputMaybe<Locale>
}

/** Notification Setting Option */
export type NotificationSettingOptionTitleArgs = {
  locale?: InputMaybe<Locale>
}

/** Key of notification setting */
export enum NotificationSettingOptionSort {
  /** checkbox */
  Checkbox = 'CHECKBOX',
  /** select */
  Select = 'SELECT',
  /** switch */
  Switch = 'SWITCH',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

/** Query */
export type Query = {
  __typename?: 'Query'
  /** Achievement campaigns */
  achievementCampaigns: AchievementCampaignConnection
  /** Challenge campaigns */
  challengeCampaigns: ChallengeCampaignConnection
  /** Authenticated user */
  me?: Maybe<User>
}

/** Query */
export type QueryAchievementCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  keys?: InputMaybe<Array<AchievementCampaignKey>>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Query */
export type QueryChallengeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<ChallengeCampaignStatus>
}

/** A quest */
export type Quest = {
  __typename?: 'Quest'
  /** Time when the quest ends */
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Goal */
  goal: QuestGoal
  /** ID */
  id: Scalars['ID']['output']
  /** Time when the when the quest starts */
  startAt: Scalars['ISO8601DateTime']['output']
}

/** A Quest goal */
export type QuestGoal = LessonFinishedCountQuestGoal | ConversationFinishedCountQuestGoal

/** A Quest progress */
export type QuestProgress = CountQuestProgress

/** Autogenerated return type of RegisterForChallengeCampaignOption. */
export type RegisterForChallengeCampaignOptionPayload = {
  __typename?: 'RegisterForChallengeCampaignOptionPayload'
  /** challenge registration field */
  challengeRegistration?: Maybe<ChallengeRegistration>
  /** error field */
  errors: Array<Scalars['String']['output']>
}

/** A student */
export type Student = {
  __typename?: 'Student'
  /** ID */
  id: Scalars['ID']['output']
  /** Upcoming lessons (ordered by startAt ASC) */
  upcomingLessons: LessonConnection
}

/** A student */
export type StudentUpcomingLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Input fields for creating a challenge campaign */
export type UpdateChallengeCampaignInput = {
  /** End time of the campaign */
  endAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** End time of the registration */
  registrationEndAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Start time of the registration */
  registrationStartAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Start time of the campaign */
  startAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

/** Autogenerated return type of UpdateChallengeCampaign. */
export type UpdateChallengeCampaignPayload = {
  __typename?: 'UpdateChallengeCampaignPayload'
  /** Newly updated challenge campaign field */
  campaign?: Maybe<ChallengeCampaign>
  /** message field */
  message: Array<Scalars['String']['output']>
  /** Success check field */
  success: Scalars['Boolean']['output']
}

/** A user */
export type User = {
  __typename?: 'User'
  /** Badge rewards */
  badgeRewards?: Maybe<UserBadgeRewardConnection>
  /** Challenge registrations */
  challengeRegistrations?: Maybe<ChallengeRegistrationConnection>
  /** Email address */
  email: Scalars['String']['output']
  /** ID */
  id: Scalars['ID']['output']
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']['output']>
  /** user notification settings */
  myNotificationSettings: UserNotificationSettingConnection
  /** Name */
  name?: Maybe<Scalars['String']['output']>
  /** User as a student */
  student?: Maybe<Student>
  /** Timezone */
  timezone?: Maybe<Scalars['String']['output']>
  /** User quests */
  userQuests?: Maybe<UserQuestConnection>
}

/** A user */
export type UserBadgeRewardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A user */
export type UserChallengeRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  campaignStatus?: InputMaybe<ChallengeCampaignStatus>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A user */
export type UserMyNotificationSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  itemKey?: InputMaybe<NotificationKey>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** A user */
export type UserUserQuestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** User Achievement Stage */
export type UserAchievementStage = {
  __typename?: 'UserAchievementStage'
  /** when the user completed the stage */
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** ID */
  id: Scalars['ID']['output']
  /** whether the user completed this stage */
  isCompleted: Scalars['Boolean']['output']
  /** Current progress */
  progress: QuestProgress
  /** progress at */
  progressedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Achievement Stage */
  stage: AchievementStage
}

/** The connection type for UserAchievementStage. */
export type UserAchievementStageConnection = {
  __typename?: 'UserAchievementStageConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAchievementStageEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserAchievementStage>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserAchievementStageEdge = {
  __typename?: 'UserAchievementStageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<UserAchievementStage>
}

/** A badge reward to a user */
export type UserBadgeReward = {
  __typename?: 'UserBadgeReward'
  /** Associated badge */
  badge: Badge
  /** ID of the user badge reward record */
  id: Scalars['ID']['output']
  /** Time when the badge was rewarded to the user */
  rewardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Associated user */
  user: User
}

/** The connection type for UserBadgeReward. */
export type UserBadgeRewardConnection = {
  __typename?: 'UserBadgeRewardConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserBadgeRewardEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserBadgeReward>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserBadgeRewardEdge = {
  __typename?: 'UserBadgeRewardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<UserBadgeReward>
}

/** Notification Setting */
export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting'
  /** whether the user consent to the notification */
  didConsent?: Maybe<Scalars['Boolean']['output']>
  /** the time user set */
  hour?: Maybe<Scalars['Int']['output']>
  /** ID */
  id: Scalars['ID']['output']
  /** Notification Setting Option */
  notificationSettingOption: NotificationSettingOption
}

/** The connection type for UserNotificationSetting. */
export type UserNotificationSettingConnection = {
  __typename?: 'UserNotificationSettingConnection'
  /** whether the user consent to all notifications */
  didConsentAll: Scalars['Boolean']['output']
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserNotificationSettingEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserNotificationSetting>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserNotificationSettingEdge = {
  __typename?: 'UserNotificationSettingEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<UserNotificationSetting>
}

/** A user quest */
export type UserQuest = {
  __typename?: 'UserQuest'
  /** ID */
  id: Scalars['ID']['output']
  /** Whether the user complete the quest */
  isCompleted: Scalars['Boolean']['output']
  /** Progress status */
  progress: QuestProgress
  /** Associated quest */
  quest: Quest
  /** Associated user */
  user: User
}

/** The connection type for UserQuest. */
export type UserQuestConnection = {
  __typename?: 'UserQuestConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserQuestEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserQuest>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total count */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserQuestEdge = {
  __typename?: 'UserQuestEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<UserQuest>
}
