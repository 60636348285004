import { createContext, useContext } from 'react'

const HackleClientContext = createContext(null)

export const HackleClientProvider = ({ children, hackleClient }) => {
  return <HackleClientContext.Provider value={hackleClient}>{children}</HackleClientContext.Provider>
}

// Custom Hook을 사용하여 컨텍스트에 접근
export const useHackleClient = () => useContext(HackleClientContext)
