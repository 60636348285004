import { useCallback, useEffect, useMemo } from 'react'
import { GTM_EVENT, sendGTMEvent } from '../../../helpers/gtmHelper'
import { useCurrentUser } from '../../context/UserContext'
import { getEnv } from '../../envUtil'
import { IS_PRODUCTION } from '../../envVars'
import useAppRouter from './useAppRouter'
import useIsWebview from './useIsWebview'

const useChannelIOSetup = (currentUser, router, hideChannelButtonOnBoot = true) => {
  const isWebview = useIsWebview()

  const getLanguageCode = useCallback((lang) => {
    switch (lang) {
      case 'kr':
        return 'ko'
      case 'zh_chs':
        return 'zh'
      case 'zh_cht':
        return 'de'
      default:
        return lang
    }
  }, [])

  const lang = useMemo(() => getLanguageCode(router.query.lang), [router.query.lang, getLanguageCode])

  useEffect(() => {
    if (isWebview || !IS_PRODUCTION) return

    const baseConfig = {
      pluginKey: getEnv('NEXT_PUBLIC_PLUS_CHANNEL_IO_KEY'),
      language: lang,
      memberId: currentUser?.id,
      hideChannelButtonOnBoot: hideChannelButtonOnBoot,
      profile: {
        name: currentUser?.profile.name || '',
        email: currentUser?.profile.email || '',
        mobileNumber: currentUser?.profile.mobileNumber || '',
        avatarUrl: currentUser?.image_url || '',
        finished_lesson_count: currentUser?.profile?.finished_lesson_count || '',
        total_amount: currentUser?.profile?.total_amount || '',
        point: currentUser?.student_card?.points || '',
        unused_coupons: currentUser?.unused_coupons || '',
        purchase_status: currentUser?.braze?.purchase?.status || '',
        last_purchased_date: currentUser?.braze?.purchase?.last_purchased_date || '',
        last_purchased_name: currentUser?.braze?.purchase?.last_purchased_name || '',
        customer_type: currentUser?.braze?.private?.customer_type || '',
        last_lesson_start_time: currentUser?.braze?.lesson?.last_lesson_start_time || '',
      },
    }

    console.log('BOOT_CHANNEL_TALK : ', baseConfig)
    window.ChannelIO?.('boot', baseConfig)
    window.ChannelIO?.('onShowMessenger', () => sendGTMEvent(GTM_EVENT.CONTACT))
    window.ChannelIO?.('updateUser', baseConfig)
    return () => {
      window.ChannelIO?.('shutdown')
    }
  }, [lang, currentUser, isWebview, router.asPath, hideChannelButtonOnBoot])
}

export default useChannelIOSetup

export const useShowChannelButton = (channelTalk = true) => {
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    setTimeout(() => {
      if (window?.ChannelIO && channelTalk) {
        window?.ChannelIO('showChannelButton')
        return () => window?.ChannelIO('hideChannelButton')
      }
    }, 100)
  }, [channelTalk, currentUser])

  return null
}

export const useChannelIOInit = (hide = true) => {
  const router = useAppRouter()
  const { currentUser } = useCurrentUser()

  const getLanguageCode = useCallback((lang) => {
    switch (lang) {
      case 'kr':
        return 'ko'
      case 'zh_chs':
        return 'zh'
      case 'zh_cht':
        return 'de'
      default:
        return lang
    }
  }, [])

  const lang = useMemo(() => getLanguageCode(router.query.lang), [router.query.lang, getLanguageCode])

  useEffect(() => {
    if (!IS_PRODUCTION) return

    const baseConfig = {
      pluginKey: getEnv('NEXT_PUBLIC_PLUS_CHANNEL_IO_KEY'),
      language: lang,
      memberId: currentUser?.id,
      hideChannelButtonOnBoot: hide,
      profile: {
        name: currentUser?.profile.name,
        email: currentUser?.profile.email,
        mobileNumber: currentUser?.profile.mobileNumber,
        avatarUrl: currentUser?.image_url,
        finished_lesson_count: currentUser?.profile.finished_lesson_count,
      },
    }

    console.log('BOOT_CHANNEL_TALK : ', baseConfig)
    window.ChannelIO?.('boot', baseConfig)
    window.ChannelIO?.('onShowMessenger', () => () => sendGTMEvent(GTM_EVENT.CONTACT))

    if (!hide) {
      setTimeout(() => {
        window.ChannelIO?.('showChannelButton')
      }, 100)
    }

    return () => {
      window.ChannelIO?.('shutdown')
    }
  }, [lang, currentUser, router.asPath, hide])
}
