import classNames from 'classnames'
import { descriptionClassName, headerClassName } from '../style'

interface IState {
  title: string
  status: string
  className: string
}

const statesList: IState[] = [
  { title: 'Enabled', status: '100%', className: 'bg-purple-500 opacity-100' },
  { title: 'Hover', status: '70%', className: 'bg-purple-500 opacity-70' },
  { title: 'Pressed', status: '50%', className: 'bg-purple-500 opacity-50' },
  { title: 'Disabled', status: 'Gray-200', className: 'bg-gray-200' },
]

const StateSection = ({ state }: { state: IState }) => {
  return (
    <div className="flex h-[120px] w-[120px] flex-col items-center mt-[20px]">
      <p className="font-bold">{state.title}</p>
      <p className="mb-[16px] text-[14px]">{state.status}</p>
      <div className={classNames(state.className, 'rounded-[4px]')}>
        <p className="px-[36px] py-[12px] text-white">Label</p>
      </div>
    </div>
  )
}

const States = () => {
  return (
    <>
      <p className={headerClassName}>States</p>
      <p className={descriptionClassName}>
        Hover, Pressed 등의 상태를 표현할 때, Opacity를 사용합니다.
        <br />
        투명도 사용으로 상태 표현에 문제가 있는 컴포넌트는 각 컴포넌트에서 예외로 기술합니다.
      </p>

      <div className="flex flex-row gap-[24px]">
        {statesList.map((state) => (
          <StateSection key={state.title} state={state} />
        ))}
      </div>
    </>
  )
}

export default States
