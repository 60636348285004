import { useDSPromoPopupState } from '../modules/DSPropmoPopupContext'
import { DSPROMOPOPUP_SIZE_TYPE } from './DSPromoPopupMain'

type DSPROMOPOPUP_DIRECTION_TYPE = 'horizontal' | 'vertical'

interface DSPromoPopupButtonLayoutProps {
  direction: DSPROMOPOPUP_DIRECTION_TYPE
  children: React.ReactNode
}

const DSPromoPopupButtonContainer = (props: DSPromoPopupButtonLayoutProps) => {
  const { direction, children } = props
  const { size } = useDSPromoPopupState()

  const className = SIZE_CLASSNAME[size] + ' ' + DIRECTION_CLASSNAME[direction]

  return <div className={className}>{children}</div>
}

export default DSPromoPopupButtonContainer

const SIZE_CLASSNAME: { [size in DSPROMOPOPUP_SIZE_TYPE]: string } = {
  sm: 'mt-[8px] pr-[4px]',
  lg: 'mt-[12px] pr-[4px]',
}

const DIRECTION_CLASSNAME: { [direction in DSPROMOPOPUP_DIRECTION_TYPE]: string } = {
  horizontal: 'flex flex-row space-x-[8px]',
  vertical: '',
}
