interface InputTextCounterProps {
  value: string
  maxLength: number
}

const DSInputTextCounter = (props: InputTextCounterProps) => {
  const { value, maxLength } = props

  const numDigits = maxLength.toString().length

  return (
    <p className="flex pr-[4px] Caption-12Md text-gray-500">
      {value.length.toString().padStart(numDigits, '0')}/{maxLength.toString().padStart(numDigits, '0')}
    </p>
  )
}

export default DSInputTextCounter
