export const DEFAULT_LOCALE = 'ko' as const
export const LOCALE_EN = 'en' as const
export const LOCALE_KO = 'ko' as const
export const LOCALE_ZH_CHS = 'zh_chs' as const
export const LOCALE_ZH_CHT = 'zh_cht' as const
export const LOCALE_JA = 'ja' as const
export const LOCALE_VI = 'vi' as const
export const LOCALE_KEY = 'key' as const
export const SUPPORTED_LOCALES = [
  LOCALE_KO,
  LOCALE_EN,
  LOCALE_ZH_CHS,
  LOCALE_ZH_CHT,
  LOCALE_JA,
  LOCALE_VI,
  LOCALE_KEY,
] as const

export type LocaleType = (typeof SUPPORTED_LOCALES)[number]

export function isSupportedLocale(tested: string): tested is LocaleType {
  return SUPPORTED_LOCALES.some((locale) => locale === tested)
}

export function localeIndex(locale: LocaleType) {
  switch (locale) {
    case LOCALE_KO:
      return 0
    case LOCALE_EN:
      return 1
    case LOCALE_KEY:
      return 2
    case LOCALE_ZH_CHS:
      return 3
    case LOCALE_ZH_CHT:
      return 4
    case LOCALE_JA:
      return 5
    case LOCALE_VI:
      return 6
    default:
      return LOCALE_KO
  }
}
