/**
 * data-ref key for E2E test automation locator
 */
export const DATA_REF_KEY = {
  btn_modal_close: 'btn_modal_close',
  btn_signin: 'btn_signin',
  btn_signout: 'btn_signout',
  nav_signin: 'nav_signin',
  inp_email: 'inp_email',
  inp_password: 'inp_password',
  btn_kakao: 'btn_kakao',
  btn_naver: 'btn_naver',
  btn_google: 'btn_google',
  btn_facebook: 'btn_facebook',
  btn_apple: 'btn_apple',
  btn_signup: 'btn_signup',
  nav_signup: 'nav_signup',
  btn_email: 'btn_email',
  inp_password_again: 'inp_password_again',
  inp_name: 'inp_name',
  inp_phonenumber: 'inp_phonenumber',
  btn_verification_code: 'btn_verification_code',
  inp_verification_code: 'inp_verification_code',
  btn_modal_verification: 'btn_modal_verification',
  btn_verify_complete: 'btn_verify_complete',
  btn_select_account: 'btn_select_account',
  menu_conversation: 'menu_conversation', // menu_conversation
  nav_tab_pricing: 'btn_tab_pricing',
  nav_schedule_enter: 'nav_schedule_enter',
  btn_sort_by_tutor: 'btn_sort_by_tutor',
  btn_sort_by_time: 'btn_sort_by_time',
  btn_in_24hours: 'btn_in_24hours',
  tab_saved_tutor: 'tab_saved_tutor',
  tab_available_tutor: 'tab_available_tutor',
  tab_recommend_tutor: 'tab_recommend_tutor',
  btn_timeslot_available: 'btn_timeslot_available',
  btn_next: 'btn_next',
  btn_select_material: 'btn_select_material',
  btn_drop_matching_opt: 'btn_drop_matching_opt',
  btn_complete: 'btn_complete',
  btn_back: 'btn_back',
  btn_exit: 'btn_exit',
  btn_automatching: 'btn_automatching',
  btn_select_lesson: 'btn_select_lesson',
  tab_upcoming_lessons: 'tab_upcoming_lessons',
  btn_change: 'btn_change',
  btn_matching_option: 'btn_matching_option',
  btn_cancel: 'btn_cancel',
  btn_reschedule: 'btn_reschedule',
  btn_material: 'btn_material',
  btn_lesson_style: 'btn_lesson_style',
  btn_insight: 'btn_insight',
  btn_mp3: 'btn_mp3',
  btn_clip: 'btn_clip',
  btn_select: 'btn_select',
  btn_enter_lesson: 'btn_enter_lesson',
  btn_modal_lesson_style: 'btn_modal_lesson_style',
  btn_modal_enter_lesson: 'btn_modal_enter_lesson',
  btn_preparation: 'btn_preparation',
  btn_tab_insight: 'btn_tab_insight',
  btn_question: 'btn_question',
  btn_select_question: 'btn_select_question',
  btn_done: 'btn_done',
  btn_play: 'btn_play',
  btn_5s_foward: 'btn_5s_foward',
  btn_tab_expression: 'btn_tab_expression',
  btn_tab_video: 'btn_tab_video',
  btn_tab_schedule: 'btn_tab_schedule',
  btn_tab_lessons: 'btn_tab_lessons',
  btn_purchase: 'btn_purchase',
  btn_today: 'btn_today',
  btn_previous: 'btn_previous',
  btn_save: 'btn_save',
  btn_accept: 'btn_accept',
  btn_decline: 'btn_decline',
  btn_modal_zoom_open: 'btn_modal_zoom_open',
  btn_watch_clip: 'btn_watch_clip',
  btn_correction: 'btn_correction',
  btn_check_style: 'btn_check_style',
  btn_feedback: 'btn_feedback',
  btn_tab_past: 'btn_tab_past',
  btn_lesson_review: 'btn_lesson_review',
  btn_feedback_required: 'btn_feedback_required',
  btn_fluency: 'btn_fluency',
  btn_vocabulary: 'btn_vocabulary',
  btn_grammar: 'btn_grammar',
  btn_pronunciation: 'btn_pronunciation',
  btn_insert: 'btn_insert',
  btn_submit: 'btn_submit',
  btn_modal_feedback_submit: 'btn_modal_feedback_submit',
  drop_country_code: 'drop_country_code', //countryCode
  btn_verify: 'btn_verify',
  modal_verification_complete: 'modal_verification_complete',
  btn_card_number: 'btn_card_number',
  check_agreement: 'check_agreement',
  inp_card1: 'inp_card1',
  inp_card2: 'inp_card2',
  inp_card3: 'inp_card3',
  inp_card4: 'inp_card4',
  inp_expire_date_mm: 'inp_expire_date_mm',
  inp_expire_date_yy: 'inp_expire_date_yy',
  inp_birth_date: 'inp_birth_date',
  btn_success_home: 'btn_success_home',
  inp_card: 'inp_card',
  inp_expire_date: 'inp_expire_date',
  inp_cvc: 'inp_cvc',
  drop_country: 'drop_country',
  inp_zip: 'inp_zip',
  inp_answer: 'inp_answer',
  inp_overall_feedback: 'inp_overall_feedback',
  img_modal_close: 'img_modal_close',
  iframe_ringle_doc: 'iframe_ringle_doc',
  inp_lesson_note: 'inp_lesson_note',
  btn_modal_check: 'btn_modal_check',
  btn_credit: 'btn_credit',
  btn_check_schedule: 'btn_check_schedule',
  btn_ringle: 'btn_ringle',
  btn_change_currency: 'btn_change_currency',
  btn_card_purchase: 'btn_card_purchase',
  btn_restore_credit: 'btn_restore_credit',
}

export const ELEMENT_ID = {
  landing_home_signin_button: 'bt_landing_home_signin',
  auth_signin_email_input: 'in_auth_signin_email',
  auth_signin_password_input: 'in_auth_signin_password',
  auth_signin_button: 'bt_auth_signin',
  bt_landing_home_signup: 'bt_landing_home_signup',
  bt_auth_signin_kakao: 'bt_auth_signin_kakao',
  bt_auth_signin_naver: 'bt_auth_signin_naver',
  bt_auth_signin_google: 'bt_auth_signin_google',
  bt_auth_signin_facebook: 'bt_auth_signin_facebook',
  bt_auth_signup_email: 'bt_auth_signup_email',
  in_auth_signup_email: 'in_auth_signup_email',
  in_auth_signup_password: 'in_auth_signup_password',
  in_auth_signup_password_re: 'in_auth_signup_password_re',
  in_auth_signup_name: 'in_auth_signup_name',
  in_auth_signup_phonenumber: 'in_auth_signup_phonenumber',
  bt_auth_signup_request_verification: 'bt_auth_signup_request_verification',
  bt_auth_modal_signup_verification: 'bt_auth_modal_signup_verification',
  in_auth_signup_verification_code: 'in_auth_signup_verification_code',
  bt_auth_signup_verify_complete: 'bt_auth_signup_verify_complete',
  bt_auth_signup: 'bt_auth_signup',
  bt_portal_home_: 'bt_portal_home_',
  bt_portal_home_pricing: 'bt_portal_home_pricing',
  bt_portal_schedule_enter_book: 'bt_portal_schedule_enter_book',
  bt_portal_schedule_enter_sortbytutor: 'bt_portal_schedule_enter_sortbytutor',
  bt_portal_schedule_enter_sortbytime: 'bt_portal_schedule_enter_sortbytime',
  bt_portal_schedule_enter_in24hours: 'bt_portal_schedule_enter_in24hours',
  bt_portal_schedule_mode_saved_tutor: 'bt_portal_schedule_mode_saved_tutor',
  bt_portal_schedule_mode_available_tutor: 'bt_portal_schedule_mode_available_tutor',
  bt_portal_schedule_mode_recommended_tutor: 'bt_portal_schedule_mode_recommended_tutor',
  div_portal_schedule_mode_timeslot_: 'div_portal_schedule_mode_timeslot_',
  bt_portal_schedule_mode_timeslot: 'bt_portal_schedule_mode_timeslot',
  bt_portal_schedule_mode_timeslot_next: 'bt_portal_schedule_mode_timeslot_next',
  bt_portal_schedule_mode_select_material_: 'bt_portal_schedule_mode_select_material_',
  bt_portal_schedule_mode_material_next: 'bt_portal_schedule_mode_material_next',
  drop_portal_schedule_mode_matching_option: 'drop_portal_schedule_mode_matching_option',
  bt_portal_schedule_mode_complete: 'bt_portal_schedule_mode_complete',
  bt_portal_schedule_mode_back: 'bt_portal_schedule_mode_back',
  bt_portal_schedule_mode_exit: 'bt_portal_schedule_mode_exit',
  bt_portal_upcoming_prep_lessons: 'bt_portal_upcoming_prep_lessons',
  bt_portal_upcoming_change_cancel: 'bt_portal_upcoming_change_cancel',
  bt_portal_upcoming_side_cancel_option: 'bt_portal_upcoming_side_cancel_option',
  bt_portal_upcoming_side_reschedule: 'bt_portal_upcoming_side_reschedule',
  bt_portal_upcoming_side_change_material: 'bt_portal_upcoming_side_change_material',
  bt_portal_upcoming_side_lesson_style: 'bt_portal_upcoming_side_lesson_style',
  bt_portal_upcoming_cancel_lessons: 'bt_portal_upcoming_cancel_lessons',
  bt_portal_upcoming_read_insight: 'bt_portal_upcoming_read_insight',
  bt_portal_upcoming_mp3: 'bt_portal_upcoming_mp3',
  bt_portal_pricing_code_request: 'bt_portal_pricing_code_request',
  bt_portal_pricing_confirm: 'bt_portal_pricing_confirm',
  bt_portal_pricing_verify: 'bt_portal_pricing_verify',
  bt_portal_pricing_by_: 'bt_portal_pricing_by_',

  // -------------------------------- no excel --------------------//
  bt_next_week: 'bt_next_week',
  bt_tutor_open_slot_popup_save: 'bt_tutor_open_slot_popup_save',
  bt_portal_pricing_purchase: 'bt_portal_pricing_purchase',
  bt_portal_pricing_purchase_modal: 'bt_portal_pricing_purchase_modal',
}
