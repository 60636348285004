import { COLORS, DSTooltip } from '@repo/design-system/index'
import { QIcon_bell_default } from '@repo/icons/index'
import { subHeaderClassName } from '../../style'

interface SectionTooltipProps {
  text: string
  place: 'top' | 'right' | 'bottom' | 'left'
  isMultiline: boolean
  remountFlag: boolean
}

const TextCloseLink = ({ text, place, isMultiline, remountFlag }: SectionTooltipProps) => {
  return (
    remountFlag && (
      <div className="mt-[20px]">
        <p className={subHeaderClassName}>Text Close Link</p>
        <div className="flex h-[200px] w-[800px] flex-row items-center justify-center">
          <DSTooltip
            place={place}
            hasCloseButton
            triggerComponent={<QIcon_bell_default color={COLORS.PURPLE} />}
            contentComponent={text}
            isMultiline={isMultiline}
            linkComponent={
              <DSTooltip.LinkButton
                onClick={() => {
                  alert('Navigate to another page')
                }}
              >
                Landing
              </DSTooltip.LinkButton>
            }
          />
        </div>
      </div>
    )
  )
}

export default TextCloseLink
