// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'

const convertDataList = (list) => {
  return list.map((item, i) => {
    return {
      id: item.id,
      value: item.selected,
    }
  })
}

const lessonStyleSlice = createSlice({
  name: 'lessonStyle',
  initialState: {
    lesson_id: null,
    skip_intro: null,
    introduction: null,
    allow_record: null,
    correcting_style: null,
    other_request: null,
    ringle_area: null,
    selected_requests: null,
    class_type: null,
  },
  reducers: {
    init(state, action) {
      state.lesson_id = action.payload.lesson.id
      state.skip_intro = action.payload.intro.skip_intro_user
      state.introduction = action.payload.intro.desc
      state.allow_record = action.payload.allow_record
      state.correcting_style = action.payload.lesson_mode.option
      state.other_request = action.payload.other_request
      state.ringle_area = convertDataList(action.payload.intensive_area)
      state.selected_requests = convertDataList(action.payload.selectable_requests)
      state.class_type = action.payload.lesson_mode.mode
    },
    setOtherRequest(state, action) {
      state.other_request = action.payload
    },
    setLessonId(state, action) {
      state.lesson_id = action.payload
    },
    setSkipIntro(state, action) {
      state.skip_intro = action.payload
    },
    // setIntroduction(state, action) {
    //   state.introduction = action.payload
    // },
    setAllowRecord(state, action) {
      state.allow_record = action.payload
    },
    // .mode
    setClassType(state, action) {
      state.class_type = action.payload
    },
    // .option
    setCorrecting(state, action) {
      state.correcting_style = action.payload
    },
    setIntensive(state, action) {
      state.ringle_area = convertDataList(action.payload)
    },
    setSelectedRequests(state, action) {
      state.selected_requests = convertDataList(action.payload)
    },
  },
})

export const lessonStyleReducer = lessonStyleSlice.reducer
export const lessonStyleActions = lessonStyleSlice.actions
