import { NextRouter } from 'next/router'
import Cookies from 'universal-cookie'
import { CurrentUser } from '../../model/Common/common.interface'
import { DEFAULT_LOCALE, LOCALE_KO } from '../i18n/config'
import urls from '../urls'
import { COOKIE_LIST, COOKIE_OPT, ROLE_ADMIN, ROLE_STUDENT, ROLE_TUTOR, TUTOR_STATUS } from '../vars'

/**
 * Redirection Functions
 */

const redirectRoot = (router: NextRouter, toDefaultLocale = false) => {
  const { lang = LOCALE_KO } = router.query

  if (toDefaultLocale) {
    router.push(`/${DEFAULT_LOCALE}/`)
  } else {
    router.push(`/${lang}/`)
  }
}

const redirectPortal = (router: NextRouter, currentUser: CurrentUser) => {
  const { lang = LOCALE_KO } = router.query
  if (currentUser.role === ROLE_STUDENT) {
    router.push(`/${lang}${urls.student.portal.home}`)
  } else {
    router.push(`/${lang}${urls.tutor.portal.home}`)
  }
}

const redirectDormant = (router: NextRouter) => {
  const { lang = LOCALE_KO } = router.query
  router.push(`/${lang}${urls.tutor.landing.dormant}`)
}

const redirectHolding = (router: NextRouter) => {
  const { lang = LOCALE_KO } = router.query
  router.push(`/${lang}${urls.tutor.landing.holding}`)
}
const redirectStrike = (router: NextRouter) => {
  const { lang = LOCALE_KO } = router.query
  router.push(`/${lang}${urls.tutor.landing.strike}`)
}

// -------------------------------------------------------------------- //

/**
 * Navigate User By Path (Router Guard Function)
 * @param path router asPath
 * @param pathname router pathname
 * @param currentUser currentUser
 * @param router Next Router
 * @returns void
 */

export const navigateUserByPath = (
  path: string,
  pathname: string,
  currentUser: CurrentUser,
  router: NextRouter
): void => {
  if (!currentUser && pathname.startsWith('/design-system')) {
    redirectRoot(router)
    return
  }

  if (
    pathname.startsWith('/admin') ||
    path.startsWith('/key') ||
    pathname.startsWith('/[lang]/tutor/portal') ||
    pathname.startsWith('/[lang]/student/portal')
  ) {
    if (!currentUser) {
      // 토큰 없으면 리다이랙트
      processNoTokenPortalAccess(path, router)
    } else {
      processWithTokenPortalAccess(path, pathname, router, currentUser)
    }
  }
}

const processNoTokenPortalAccess = (asPath: string, router: NextRouter) => {
  // 토큰 없으면 리다이랙트
  if (asPath.startsWith('/key')) {
    redirectRoot(router, true)
  } else {
    const cookies = new Cookies()
    cookies.set(COOKIE_LIST.LOGIN_AFTER_URL, asPath.replace('/[lang]/', '/'), COOKIE_OPT)
    redirectRoot(router)
  }
}

const processWithTokenPortalAccess = (
  asPath: string,
  pathname: string,
  router: NextRouter,
  currentUser: CurrentUser
) => {
  // currentUser 가져오지 못하면 리다이랙트
  if (!currentUser) {
    processNoTokenPortalAccess(asPath, router)
    return
  }

  if (!asPath.startsWith('/key') && currentUser) {
    if (pathname.startsWith('/admin') && currentUser?.role !== ROLE_ADMIN) {
      redirectRoot(router)
      return
    }

    if (asPath.startsWith('/key') && !currentUser?.is_ringle_member) {
      redirectRoot(router, true)
      return
    }

    if (currentUser.role == ROLE_TUTOR) {
      //어플리케이션 단계의 튜터인 경우
      if (currentUser.tutor_status_id == TUTOR_STATUS.APPLICATION) {
        //목세션 통과한 튜터는 포털 홈 접근 가능 하지만 tutor_status == application
        if (currentUser.mock_session_passed) {
          if (
            !pathname.startsWith('/[lang]/tutor/portal/home') &&
            !pathname.startsWith('/[lang]/tutor/portal/orientation') &&
            !pathname.startsWith('/[lang]/tutor/portal/application')
          ) {
            router.push(`/en/tutor/landing/home`)
            return
          }
          //목세션 통과하지 못한 튜터는 포털 홈 접근 불가능, 예외 케이스 처리
        } else {
          if (
            !pathname.startsWith('/[lang]/tutor/portal/application') &&
            !pathname.startsWith('/[lang]/tutor/portal/orientation') &&
            !pathname.startsWith('/[lang]/tutor/portal/lessons/classroom/') &&
            !pathname.startsWith('/[lang]/tutor/portal/lessons/feedback/') &&
            !pathname.startsWith('/[lang]/tutor/portal/lessons/feedback-new/') &&
            !pathname.startsWith('/[lang]/tutor/portal/lessons/ringledoc-tutorial')
          ) {
            router.push(`/en/tutor/landing/home`)
            return
          }
        }
      }

      if (currentUser.tutor_status_id == TUTOR_STATUS.DORMANT && currentUser.available_balance == 0) {
        redirectDormant(router)
        return
      }

      if (!pathname.startsWith('/[lang]/tutor/portal')) {
        // 튜터인데 튜터 페이지 외 접속하면 리다이렉트
        redirectPortal(router, currentUser)
        return
      }
      if (currentUser.not_allowed_log_in) {
        // 튜터 페이지인데 Role 튜터가 아니면 리다이렉트
        if (
          currentUser.tutor_status_id == TUTOR_STATUS.OUT_TEN_STRIKE ||
          currentUser.tutor_status_id == TUTOR_STATUS.OUT_THREE_STRIKE
        ) {
          redirectStrike(router)
          return
        } else if (
          currentUser.tutor_status_id == TUTOR_STATUS.HOLD ||
          currentUser.tutor_status_id == TUTOR_STATUS.BLACKLIST
        ) {
          redirectHolding(router)
          return
        } else {
          redirectHolding(router)
          return
        }
      }
    }
    // 일반(성인) 학생인 경우
    if (currentUser.role == ROLE_STUDENT) {
      // 일반(성인) 학생인데 학생 페이지 외에 접속하면 리다이렉트
      if (!pathname.startsWith('/[lang]/student/portal')) {
        redirectPortal(router, currentUser)
        return
      }
    }
  }
}
