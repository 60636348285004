// @ts-strict-ignore
import { isEmpty, isNil, omitBy } from 'lodash'
import qs from 'qs'
import urljoin from 'url-join'
import { getEnv } from './envUtil'

export const SERVER_URL = getEnv('CONF_SERVER_URL')
// SERVER_URL을 바꾸고 싶으면, 위에 주소를 직접 수장하지 말고 .env.local 파일에 CONF_SERVER_URL를 추가해 주세요
//example : https://qa2.ringleserver.com/api/v4/student/matching/schedule

const teensV1 = (path) => urljoin(`${SERVER_URL}/api/teens/v1`, path)
const v4Student = (path) => urljoin(`${SERVER_URL}/api/v4/student`, path)
const v4Tutor = (path) => urljoin(`${SERVER_URL}/api/v4/tutor`, path)
const v5Tutor = (path) => urljoin(`${SERVER_URL}/api/v5/tutor`, path)
const v4 = (path) => urljoin(`${SERVER_URL}/api/v4`, path)
// null 일경우에는 파라메터 제거
export const urlParams = (obj, nullable?) => {
  if (nullable) {
    return isEmpty(obj) ? '' : '?' + qs.stringify(obj)
  }

  const nilRemoved = omitBy(obj, isNil)
  if (isEmpty(nilRemoved)) {
    return ''
  }
  return '?' + qs.stringify(nilRemoved)
}
const apis = {
  geolocation: () => v4Student('/geolocation'),
  track: () => v4Student(`/log`),
  error: () => v4(`/page_view/error`),
  auth: {
    me: () => v4('/users/me'),
    get: () => v4Student('/auth'),
    token: () => v4('/oauth/token'),
    signin: {
      socialAccount: () => v4('/accounts/signin/sns'), // will be deleted
      email: () => v4('/accounts/signin/email'), // will be deleted
    },
    signup: {
      tutor: () => v4(`/accounts/signup/tutor`),
      studentEmail: () => v4(`/accounts/signup/student/email`),
      studentSocialAccount: () => v4(`/accounts/signup/student/social_account`),
    },
    checkReferralCode: () => v4Student('/profile/referral'),
    passwordReset: () => v4(`/common/authenticate/reset_password`),
    password: {
      reset: () => v4(`/accounts/password/reset`),
      resetInitPassword: () => v4(`/accounts/password/reset_init_password`),
      resetToken: {
        validate: () => v4(`/accounts/password/validate_reset_token`),
        create: () => v4(`/accounts/password/create_reset_token`),
      },
    },
  },
  likeMaterial: () => v4Student('/my_original/course'),
  likeWebinar: () => v4Student('/my_original/webinar'),
  referral: (code) => v4Student(`/referral?referral_code=${code}`),
  course: {
    detail: (id) => v4Student(`/course/${id}`),
    search: () => v4Student(`/courses/search`),
  },
  landing: {
    topic: () => v4Student('/landing/course'),
  },
  tutor: {
    my: () => v4Student(`/tutor/my`),
    list: (page = 1, couponId?: number) => v4Student(`/tutor/list${urlParams({ page, coupon_type_id: couponId })}`),
    newTutors: (page = 1, couponId?) =>
      couponId
        ? v4Student(`/tutor/list?type=recommend&coupon_type_id=${couponId}&page=${page}`)
        : v4Student(`/tutor/list?type=recommend&page=${page}`),
    newUnoccupiedTutors: (page = 1) => v4Student(`/tutor/list?type=new_unoccupied&page=${page}`),
    oldUnoccupiedTutors: (page = 1) => v4Student(`/tutor/list?type=old_unoccupied&page=${page}`),
    allTutors: (page = 1, couponId?) =>
      couponId
        ? v4Student(`/tutor/list?type=all&coupon_type_id=${couponId}&page=${page}`)
        : v4Student(`/tutor/list?type=all&page=${page}`),
    myTutors: (page = 1, couponId?) =>
      couponId
        ? v4Student(`/tutor/list?type=my&coupon_type_id=${couponId}&page=${page}`)
        : v4Student(`/tutor/list?type=my&page=${page}`),
    search: () => v4Student(`/tutor/search_tutors`),
    profile: (id, locale?, couponId?, referrer?) =>
      v4Student(
        `/tutor/profile${urlParams({
          tutor_id: id,
          locale: locale,
          coupon_type_id: couponId,
          referrer,
        })}`
      ),
    onlyTeensProfile: (id, locale?, couponId?) =>
      teensV1(
        `/tutors/${id}${urlParams({
          locale: locale,
          coupon_type_id: couponId,
        })}`
      ), // 📍 teensV1 - 데이터독 호출 O
    add: () => v4Student('/tutor/add'),
    delete: () => v4Student('/tutor/delete'),
  },
  lessons: {
    enter: (id) => v4Student(`/lesson/upcoming/${id}/enter`),
    zoomReload: (id) => v4Student(`/lesson/${id}/zoom/reload`),
    setting: (id) => v4Student(`/lesson_setting/${id}`),
    editTimeForAutoMatching: () => v4Student('/lesson/edit/time_for_auto_matching'),
    classroom: (id) => v4Student(`/lesson/classroom/${id}`),
    pusherUrl: () => `${SERVER_URL}/pusher/auth_mobile`,
    chat: () => v4(`/rtc/talk_with_admin`),
    translate: (id) => v4Student(`lesson/classroom/${id}/translate`),
    upload_link: (id) => teensV1(`/lessons/${id}/link_material`), // // 📍 teensV1 - 데이터독 호출 X, 코드 or 정책확인 필요
    upload_file: (id) => v4Student(`/lesson/${id}/file_material`),
    preparation: {
      answer: (id) => v4Student(`/lesson/preparation/${id}/answer`),
      prestudy: (id) => v4Student(`/lesson/preparation/${id}/prestudy`),
    },
    review: {
      feedback: (lesson_id, item_type = 'all') =>
        v4Student(`/lesson/past/${lesson_id}/feedback?item_type=${item_type}`),

      feedbackNew: (lesson_id, isTeensLesson = false) =>
        isTeensLesson ? teensV1(`/lessons/${lesson_id}/feedback`) : v4Student(`/lesson/feedback/${lesson_id}`), // 📍 teensV1 - 데이터독 호출 O (튜터페이지에서 틴즈수업일 때 호출 되는 것으로 보임!)
      lateFeedback: (lesson_id) => v4Student(`/lesson/feedback/${lesson_id}/late`),
      study: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/study`),
      eval: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/eval`),
      postUseAnalysisUrl: () => v4(`/analysis/use`),
      late: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/late`),
    },
  },

  lesson: {
    answerSelect: () => v4Student(`/lesson/answer/select`),
    like: () => v4Student('/lesson/answer/like'),
    unlike: () => v4Student('/lesson/answer/unlike'),
  },
  profile: {
    get: () => v4Student('/profile'),
    timezone: (id?: any) => v4Student('/profile/timezone'),
    leave: () => v4Student('/profile/leave'),
  },
}

export const tutor_apis = {
  nlp: {
    is_complete_sentence: () => v4(`nlp/is_complete_sentence`),
  },
  stampBook: {
    get: () => v4Tutor('/home/stamp_book'),
    detail: (id) => v4Tutor(`/home/stamp_book/${id}`),
  },
  main: () => v5Tutor('/home'),
  lastMinute: {
    info: () => v5Tutor('/last-minute-schedules/me'),
    // list: () => v4Tutor('/last_minute_scheduling'),
    list: () => v5Tutor('/last-minute-schedules'),
    register: () => v5Tutor('/last-minute-schedules'),
    delete: () => v5Tutor('/last-minute-schedules'),
    deleteSlot: () => v4Tutor(`/schedules`),
    validate: () => v5Tutor('/last-minute-schedules/validates'),
    showNewAccess: (key: string) => v4(`/users/preference/${key}`),
    editNewAccess: (key: string, value: boolean) => v4(`/users/preference/${key}${urlParams({ value })}`),
    // validate: () => v4Tutor('/last_minute_scheduling/validate'),
  },
  popup: () => v4Tutor('/home/popup'),
  notifications: {
    get: () => v4(`/notification/tutor`),
    announcement: () => v4(`/notification/tutor?info_type=announcement`),
    notification: (part) => v4(`/notification/tutor?info_type=notification&part=${part}`),
    count: () => v4(`/notification/tutor?info_type=count_info`),
    read: () => v4(`/notification/tutor/read`),
  },
  get_ringledoc_tutorial_status: () => v4Tutor('/get_ringledoc_tutorial_status'),
  country_code: () => v4Tutor('/country_code'),
  blog: {
    search: () => v4Tutor(`/blog/search`),
  },
  course: {
    detail: (id) => v4Tutor(`/course/${id}`),
    coursekeyquestions: (course_id) => v5Tutor(`/courses/${course_id}/coursekeyquestions`),
    evaluation: () => v5Tutor(`/courses/preference`),
  },
  resources: {
    courses: {
      index: () => v4Tutor('/courses'),
      list: (id) => v4Tutor(`/courses?course_large_hashtag_id=${id}`),
      detail: (id) => v4Tutor(`/course/${id}`),
      search: () => v4Tutor(`/courses/search`),
      coursekeyquestions: (course_id) => v5Tutor(`/courses/${course_id}/coursekeyquestions`),
    },
    webinars: {
      index: (page) => v4Tutor(`webinars?page=${page}`),
      detail: (id) => v4Tutor(`/webinar/${id}`),
      search: () => v4Tutor(`/webinars/search`),
    },
  },

  referral: {
    referralCode: (referralCode) => v4Tutor(`/authenticate/referral_code?referral_code=${referralCode}`),
    code: () => v4Tutor('/referral/code'),
    event: () => v4Tutor('/referral/event'),
    donation: () => v4Tutor('/referral/donation'),
    dashboard: () => v4Tutor('/referral/dashboard'),
    progress: () => v4Tutor('/referral/progress'),
    shared: () => v4Tutor('/referral/shared'),
  },
  performance: {
    status: (type, strike_id?) => v4Tutor(`/performance/status${urlParams({ type, strike_id })}`),
    log: () => v4Tutor(`/performance/log_summary`),
    logDetail: () => v4Tutor(`/performance/log_details`),
    readStrike: () => v4Tutor('/performance/read_strike'),
  },
  lessons: {
    exit: (id) => v4Tutor(`/lesson/classroom/${id}/exit`),
    classroom: ({ lessonId }: { lessonId: any }) => v4Tutor(`/lesson/classroom/${lessonId}`),
    homework: (id) => v4Tutor(`/lesson/classroom/${id}/homework`),
    upcomings: () => v5Tutor('/lessons/upcoming'),
    requestedLessons: () => v4Tutor('/lesson/requested_lessons'),
    past: (type = 'all', page = 1) => v4Tutor(`/lesson/past_lessons?type=${type}&start=${page}`),
    lessonEnter: (id) => v4Tutor(`/lesson/upcoming_lessons/${id}/enter`),
    cancelCheck: (id) => v4Tutor(`/lesson/upcoming_lessons/${id}/cancel_check`),
    cancel: (id) => v4Tutor(`/lesson/upcoming_lessons/${id}/cancel`),
    lessonStyle: ({ lessonId }: { lessonId: number }) => v4Tutor(`/lesson/upcoming_lessons/${lessonId}/lesson_style`),
    lessonAgreement: ({ lessonId }: { lessonId: number }) => v4Tutor(`/lesson/${lessonId}/agreement`),
    waiver: (id) => v4Tutor(`/lesson/past_lessons/${id}/waiver`),
    waiverCancel: (id) => v4Tutor(`/lesson/past_lessons/${id}/waiver/cancel`),
    report: (id) => v4Tutor(`/lesson/past_lessons/${id}/report`),
    preparationTracing: () => v4(`/tutor/trace/lesson/preparation`),
    preparation: {
      get: (id) => v5Tutor(`/lessons/preparation/${id}`),
      patch: ({ answerId }: { answerId: number }) => v5Tutor(`/answers/${answerId}`),
      getShareAnswerV5: (answerId) => v5Tutor(`/answers/candidates?coursekeyquestion_id=${answerId}`),
      getV5: ({ lessonId }: { lessonId: number }) => v5Tutor(`/lessons/${lessonId}/preparation`),
      answerV5: () => v5Tutor(`/answers`),
    },
    confirm: (id) => v4Tutor(`/lesson/${id}/confirm`),
    unassigned: () => v4Tutor('/lesson/unassigned'),
    unassignedLessons: () => v4Tutor(`/lesson/unassigned_lessons`),
    googleTranslate: () => v4(`/common/google_translate`),
    revised: () => v4Tutor('/lesson/upcoming_lessons/revised'),
    answer: () => v4Tutor('/lesson/upcoming_lessons/answer'),
    v4Feedback: (lesson_id) => v4Tutor(`/lesson/feedback/${lesson_id}`),
    feedback: (lesson_id) => v5Tutor(`/lessons/${lesson_id}/feedback`),
    feedbackUpdateV5: (lesson_id) => v5Tutor(`/lessons/${lesson_id}/feedback`),
    feedanswerV5: (lesson_id) => v5Tutor(`/lessons/${lesson_id}/feedback/feedanswer`),
    updateFeedanswerCateogryV5: (feedanswer_id) => v5Tutor(`/${feedanswer_id}`),
    forced_submit_log: (lessonId) => v5Tutor(`/lessons/${lessonId}/feedback/forced_submit_log `),

    feedbackUpdate: (lesson_id) => v4Tutor(`/lesson/feedback/${lesson_id}/update`),
    addFeedanswer: (lesson_id) => v4Tutor(`/lesson/feedback/${lesson_id}/add_answer`),
    removeFeedanswer: (lesson_id) => v4Tutor(`/lesson/feedback/${lesson_id}/remove_answer`),
    updateFeedanswerCateogry: (lesson_id) => v4Tutor(`/lesson/feedback/${lesson_id}/update_feedquestion_id`),

    // 3.0이전, 이후 체크 api
    feedbackVersion: (lesson_id) => v5Tutor(`/lessons/${lesson_id}/feedback/version`),
  },
  promotion: {
    mission: () => v4Tutor('/promotion/missions'),
    status: (isTeens = false) => v4Tutor(`/promotion/status?is_teens=${isTeens}`),
    level: () => v4Tutor('/promotion/level'),
    history: (year = null, week = null) =>
      v4Tutor(
        `/promotion/history${urlParams({
          week: week ? week : '',
          year: year ? year : '',
        })}`
      ),
    historyTeens: (year = null, week = null) =>
      v4Tutor(
        `/promotion/teens_history${urlParams({
          week: week ? week : '',
          year: year ? year : '',
        })}`
      ),
  },
  payment: {
    upcomingPay: (year = null, week = null) => v4Tutor(`/payment/upcoming_pay?week=${week}&year=${year}`),
    payout: () => v4Tutor('/payment/payout'),
    activities: (type, week, year) => v4Tutor(`/payment/activities?type=${type}&week=${week}&year=${year}`),
    request: () => v4Tutor(`/cash/request`),
    earningsSummary: (year = null) => v4Tutor(`/payment/earnings_summary?year=${year}`),
    transactionHistory: () => v4Tutor(`/payment/transaction_history`),
    transaction: () => v4Tutor(`/payment/transaction`),
    detail: (id, week, year) => v4Tutor(`/payment/detail?lesson_id=${id}&week=${week}&year=${year}`),
    networkHistory: ({ lessonId }) => v4Tutor(`/lesson/${lessonId}/network_history`),
    incentive: () => v4Tutor('/payment/incentive/status'),
    exchangeRate: () => v4Tutor('/payment/exchange_rate'),
  },
  schedule: {
    delete: (startTime?: string, endTime?: string) =>
      v5Tutor(`/schedules${urlParams({ start_time: startTime, end_time: endTime })}`),
    register: (startTime?: string, endTime?: string) =>
      v4Tutor(`/schedules${urlParams({ start_time: startTime, end_time: endTime })}`),
    index: (startTime?: string, endTime?: string) =>
      v5Tutor(`/schedules${urlParams({ start_time: startTime, end_time: endTime })}`),
    dailyCap: (startTime?: string, endTime?: string) =>
      v4Tutor(`/schedules/daily_cap${urlParams({ start_time: startTime, end_time: endTime })}`),
    unlock: (startTime?: string, endTime?: string) =>
      v4Tutor(
        `/schedules/unlock${urlParams({
          start_time: startTime,
          end_time: endTime,
        })}`
      ),
    setting: (scheduleDays?: string, hours?: number) =>
      v4Tutor(
        `/schedules/setting${urlParams({
          schedule_day: scheduleDays,
          hours: hours,
        })}`
      ),
    revokeGoogleCalendar: (startTime?: string, endTime?: string) => v4Tutor(`schedules/revoke_google_calendar_token`),
    lesson_info: (lessonId) => v4Tutor(`schedules/lesson_card_new${urlParams({ lesson_id: lessonId })}`),
    syncAndGetGoogleCalendar: (authCode: string, startTime: string, endTime: string) =>
      v4Tutor(
        `schedules/get_google_calendar_right_after_consent${urlParams({
          auth_code: authCode,
          start_time: startTime,
          end_time: endTime,
        })}`
      ),
    getGoogleCalendar: (startTime: string, endTime: string) =>
      v4Tutor(
        `schedules/get_google_calendar_info${urlParams({
          start_time: startTime,
          end_time: endTime,
        })}`
      ),
    get: (year, month, day) => v4Tutor(`/schedules?year=${year}&month=${month}&day=${day}`),
    statusMessage: () => v4Tutor('/profile/status_message'),
  },
  teensOt: {
    video: () => v4Tutor(`/teens_quiz/video`),
    get: () => v4Tutor(`/teens_quiz`),
    post: () => v4Tutor(`/teens_quiz`),
    result: () => v4Tutor(`/teens_quiz/result`),
  },
  mockSession: {
    status: () => v4Tutor('/mock_session/status'),
    save: () => v4Tutor('/mock_session/save'),
    checkPrep: () => v4Tutor('/mock_session/check'),
    statusStandardized: () => v4Tutor('/mock_session/status_standardized'),
    saveStandardized: () => v4Tutor('/mock_session/save_standardized'),
    requestAdditionalSlot: () => v4Tutor('/mock_session/request_schedule'),
    requestOrientation: () => v4Tutor('/mock_session/request_orientation'),
    uploadResume: () => v4(`/tutor/user_account/resume/upload`),
    quiz: () => v4Tutor('/mock_session/quiz'),
    quizResult: () => v4Tutor('/mock_session/quiz/result'),
    phone: () => v4Tutor('/mock_session/phone'),
    applicationQuiz: () => v4Tutor('/paraphrase_quiz'),
    applicationQuizFinished: () => v4Tutor('/paraphrase_quiz/finish'),
    preparationCheck: () => v4Tutor('/mock_session/preparation_check'),
    requestReschedule: () => v4Tutor('/mock_session/request_reschedule'),
    rescheduleAvailable: (mockId) => v4Tutor(`/mock_session/reschedule_available?mock_id=${mockId}`),
    applyMockInterview: () => v4Tutor(`/mock_session/apply_mock_interview`),
  },
  profile: {
    basic: () => v4Tutor('/profile'),
    update: () => v4Tutor('/profile/update'),
    primaryPayoutMethod: () => v4Tutor('/profile/primary_payout_method'),
    paypal: () => v4Tutor('/profile/paypal'),
    sentbe: () => v4Tutor('/profile/sentbe'),
    updateImage: () => v4(`/tutor/account/update/avatar`),
    updateNoti: () => v4Tutor('/profile/notification'),
    updateTutorAbout: () => v4Tutor('/profile/userabout'),
    updateTeensTutorAbout: () => v4Tutor('/profile/teens_userabout'),
    timezone: () => v4Tutor('/profile/timezone'),
    taxInformation: () => v4Tutor('/profile/tax_information'),
    residence: () => v4Tutor('/profile/residence'),
    video: () => v4Tutor('/video/submit'),
    poe: () => v4Tutor('profile/poe'),
    diplomas: {
      create: () => v4Tutor(`/profile/diplomas`),
      detail: (diplomaId) => v4Tutor(`/profile/diplomas/${diplomaId}`),
      logoUrl: () => v4Tutor(`/schools/logo_url`),
    },
    marketingPolicyAgreement: () => v4Tutor('/profile/marketing_policy_agreement'),
    updateMarketingPolicyAgreement: (agreement: boolean) =>
      v4Tutor(`/profile/marketing_policy_agreement?agreed=${agreement}`),
  },
  writing: {
    requested: () => v4Tutor('/writing'),
    finished: () => v4Tutor('/writing/finished'),
    detail: ({ writingId }: { writingId: number }) => v4Tutor(`/writing/${writingId}`),
    submit: (writingId) => v4Tutor(`/writing/${writingId}/submit`),
  },
  auth: {
    password: () => v4(`/tutor/account/update/password`),
    email: () => v4(`/tutor/account/update/email`),
    requestEmail: () => v4Tutor('/authenticate/email'),
    verifyEmail: () => v4Tutor('/profile/verify_email'),
  },
  student: {
    profile: (id) => v4Tutor(`/student_profile?student_id=${id}`),
  },
  ringledoc_tutorial: {
    tutorial: (step) => v4Tutor(`/update_ringledoc_tutorial_status?step_number=${step}`),
  },
  tutor_referral_2022: {
    publish: () => v4Tutor(`/event_track/publish`),
    emailNudge: () => v4Tutor(`/event/donation/email_nudge`),
    emailInfo: (emailType, id) => v4Tutor(`/event/donation/email_info?email_type=${emailType}&recipent_id=${id}`),
  },
  tutorEventTrack: () => v4Tutor(`/event_track/publish`),
  seoul_challenge: {
    dashboard: () => v4(`/seoul_challenge/dashboard`),
    seasonDetail: (seasonID) => v4(`/seoul_challenge/season/${seasonID}`),
  },
  engagements: {
    index: () => v4Tutor('/engagements'),
    task: () => v4Tutor('/engagements/task'),
    oldTutorBoard: () => v4Tutor('/payment/incentive/weekly'),
  },
  event: {
    officeOpen: {
      getStart: () => v4Tutor('/event/office_open'),
      ranking: () => v4Tutor('/event/office_open/ranking'),
    },
  },
  autocomplete: {
    degree: (input) => v4Tutor(`/poe/autocomplete/degree?input=${input}`),
    major: (input) => v4Tutor(`/poe/autocomplete/major?input=${input}`),
    school: (input) => v4Tutor(`/poe/autocomplete/school?input=${input}`),
    fieldOfStudy: (input) => v4Tutor(`/poe/autocomplete/field_of_study?input=${input}`),
  },
}

export default apis
