module.exports = {
  primary: { DEFAULT: '#4c9cff', light: '#dbebff' },
  secondary: '#317CD9',
  secondary2: '#105DBB',
  transparent: 'transparent',
  current: 'currentColor',
  gray: {
    DEFAULT: '#5a5a5b',
    100: '#f5f5f5',
    200: '#edeeee',
    300: '#dfe1e3',
    400: '#cacccf',
    500: '#b2b6bb',
    600: '#989aa2',
    700: '#5a5a5b',
    900: '#171B47',
    800: '#272B55',
    50: '#FAFAFA',
  },
  success: { DEFAULT: '#3ACABB', light: '#d8f4f1' },
  warning: '#F6D34A',
  black: {
    DEFAULT: '#1b1b1b',
    overlay: 'rgba(0, 0, 0, 0.7)',
  },
  white: '#ffffff',
  danger: { DEFAULT: '#FA5C5C', light: '#FFD2D2' },
  info: { DEFAULT: '#B774EC', light: '#F5E5FF' },
  // Design System > Basic System > Color Style
  dim: 'rgba(0, 0, 0, 0.4)',
  purple: {
    DEFAULT: '#503CC8',
    900: '#120B60',
    800: '#1C1374',
    700: '#2B1E90',
    600: '#3C2BAC',
    500: '#503CC8',
    400: '#7B69DE',
    300: '#9B8AEE',
    200: '#C0B3F9',
    100: '#E0D8FC',
    50: '#EEEBFA',
  },
  blue: {
    DEFAULT: '#4495FF',
    900: '#0D297A',
    800: '#153B93',
    700: '#2255B7',
    600: '#3173DB',
    500: '#4495FF',
    400: '#72B5FF',
    300: '#8EC9FF',
    200: '#D9F0FF',
    100: '#E5F2FC',
    50: '#EDF7FF',
  },
  green: {
    DEFAULT: '#2BAB63',
    900: '#085246',
    800: '#0D634C',
    700: '#157B56',
    600: '#1F935D',
    500: '#2BAB63',
    400: '#52B879',
    300: '#71C490',
    200: '#9AD4AF',
    100: '#C2E5CE',
    50: '#E6F5EB',
  },
  red: {
    DEFAULT: '#F53428',
    900: '#750725',
    800: '#8E0C25',
    700: '#B01425',
    600: '#D21D21',
    500: '#F53428',
    400: '#F9725C',
    300: '#FC997D',
    200: '#FEC3A8',
    100: '#FEE4D3',
    50: '#FFF3EF',
  },
  yellow: {
    DEFAULT: '#FFB300',
    900: '#7A4600',
    800: '#935A00',
    700: '#B77500',
    600: '#DB9300',
    500: '#FFB300',
    400: '#FFCB3F',
    300: '#FFDA66',
    200: '#FFE999',
    100: '#FFF6CC',
    50: '#FFF8E1',
  },
}
