import { createSlice } from '@reduxjs/toolkit'

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    type: 7,
    month: 11,
  },
  reducers: {
    setOverallMonth(state, action) {
      state.month = action.payload
    },
  },
})

export const statisticsReducer = statisticsSlice.reducer
export const statisticsActions = statisticsSlice.actions
