// @ts-strict-ignore
import { useQueryClient } from '@tanstack/react-query'
import { NextRouter, useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { LOCALE_USER_TYPE } from '../../helpers/gtmHelper'
import { DEFAULT_LOCALE } from '../../modules/i18n/config'
import { appActions } from '../../reducer/appReducer'
import { useCurrentGeo } from '../apis/student/geolocation'
import { getEnv } from '../envUtil'
import { IS_DEVELOPMENT, IS_PRODUCTION } from '../envVars'
import { LOCALE_EN, LOCALE_KEY, LocaleType, SUPPORTED_LOCALES, isSupportedLocale } from '../i18n/config'
import { currentGeoQueryApi } from '../react-query/queryApis'
import urls from '../urls'
import { COOKIE_OPT } from '../vars'
import { useCurrentUser } from './UserContext'

interface IDefaultUserLocale {
  defaultLocale: LocaleType
  supportedLocale: Array<string | LocaleType>
}

interface LocaleProviderPropsType {
  children?: any
}

export interface ContextProps {
  readonly locale: LocaleType
  updateLandingLocale: (newLocale: LocaleType) => void
}

/**
 * * <DEFAULT LOCALE SETTINGS>
 */

const STUDENT_DEFAULT_LOCALE: IDefaultUserLocale = {
  defaultLocale: DEFAULT_LOCALE,
  supportedLocale: [...SUPPORTED_LOCALES],
}

const TUTOR_DEFAULT_LOCALE: IDefaultUserLocale = {
  defaultLocale: LOCALE_EN,
  supportedLocale: [LOCALE_EN, LOCALE_KEY],
}

/**
 * * ------------------------ *
 */

export const LocaleContext = createContext<ContextProps>({
  locale: DEFAULT_LOCALE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateLandingLocale: (_newLocale: LocaleType) => {},
})

/**
 * * Get default locale object by user type
 * @param userType 유저 타입
 * @returns IDefaultLocale
 */
const getDefaultLocaleByUserType = (userType: LOCALE_USER_TYPE): IDefaultUserLocale => {
  switch (userType) {
    case LOCALE_USER_TYPE.STUDENT:
      return STUDENT_DEFAULT_LOCALE
    case LOCALE_USER_TYPE.WEBVIEW:
      return STUDENT_DEFAULT_LOCALE
    case LOCALE_USER_TYPE.TUTOR:
      return TUTOR_DEFAULT_LOCALE
    default:
      return TUTOR_DEFAULT_LOCALE
  }
}

export const LocaleProvider = ({ children }: LocaleProviderPropsType) => {
  const { currentUser, localeUserType: userType } = useCurrentUser()
  const router = useRouter()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [cookies, setCookies] = useCookies(['locale'])

  const queryLang = router.query.lang as LocaleType
  const { data: currentGeo, isFetching } = useCurrentGeo()
  const [locale, setLocale] = useState<LocaleType>(
    isSupportedLocale(queryLang)
      ? queryLang
      : cookies.locale
      ? cookies.locale
      : getDefaultLocaleByUserType(userType).defaultLocale
  )

  /**
   * Non-User Handler for Locale Update
   * @param router Next Router
   * @param nextLocale Next Locale
   */
  const _updateLandingLocale = (router: NextRouter, curLocale: LocaleType, nextLocale: LocaleType): void => {
    // In Landing, mutation api will fail due to no token
    if (router.asPath.includes('/landing') || router.asPath.includes('/auth/')) {
      const cookies = new Cookies()
      cookies.set('locale', nextLocale, COOKIE_OPT)
      updateStorageLocale(nextLocale)
      setLocale(nextLocale)
      router.push(router.asPath.replace(`/${curLocale}/`, `/${nextLocale}/`), null, { shallow: false })
    }
  }

  /**
   * * Dispatch CurrentGeo to Redux
   */
  useEffect(() => {
    if (currentGeo && !isFetching) {
      dispatch(appActions.updateGeo({ currentGeo }))
    }
  }, [currentGeo, isFetching])

  useEffect(() => {
    /**
     * * Redirect to base locale
     * @param curLocale : 현재 Locale
     * @param baseLocale : 이동하려는 Locale
     */
    const redirectToLocale = (curLocale: LocaleType | string, baseLocale: LocaleType): void => {
      console.log(router.asPath, curLocale, baseLocale)
      if (router.asPath.includes(`/${baseLocale}`)) return

      if (router.asPath.startsWith('/design-system') && currentUser.is_ringle_member) return

      if (curLocale !== undefined) {
        console.log('%cLOCALE CONTEXT: REDIRECT TO LOCALE', 'background: blue; color: white')
        router.push(router.asPath.replaceAll(`/${curLocale}`, `/${baseLocale}`))
      } else {
        // if curLocale === undefined, it means its at the initial index page (www.ringleplus.com/)
        router.replace(`/en${urls.tutor.landing.home}`)
      }
    }

    /**
     * * Update locale with new locale
     * @param newLocale : 업데이트 될 Locale
     */
    const updateLocale = (newLocale: LocaleType): void => {
      if (locale === newLocale) return

      console.log(`%cLOCALE CONTEXT: UPDATE LOCALE :: ${newLocale}`, 'background: blue; color: white')
      setLocale(newLocale)
    }

    /* ---------------- *
      USE-EFFECT LOGIC
    ------------------ */

    /**
     * Check if router is ready
     */
    if (!router.isReady) return

    const cookies = new Cookies()
    const userLocale = (currentUser?.locale === 'kr' ? 'ko' : currentUser?.locale) as LocaleType
    const queryLang = router.query.lang as LocaleType
    const cookieLocale = cookies.get('locale') as LocaleType
    const { defaultLocale, supportedLocale } = getDefaultLocaleByUserType(userType)

    /**
     * ? Conditions
     */
    const isInvalidLocale = (localeStr: string | LocaleType) =>
      !localeStr || typeof localeStr !== 'string' || !supportedLocale.includes(localeStr)
    const isLocaleSupported = supportedLocale.includes(locale) && supportedLocale.includes(queryLang)
    const isKeyLocale = queryLang === LOCALE_KEY

    // default가 force이고 현 lang이 default가 아니거나 supported된 로케일이 아닐 경우 redirect
    if (!isLocaleSupported) {
      console.log(`DEFAULT LOCALE FORCED - ${defaultLocale}`)
      queryClient.invalidateQueries(currentGeoQueryApi.getQueryKey())
      updateCookieLocale(defaultLocale)
      updateStorageLocale(defaultLocale)
      updateLocale(defaultLocale)
      redirectToLocale(queryLang, defaultLocale)
      return
    }

    // Set default locale in cookie if cookie is non-existing
    if (!cookieLocale) {
      queryClient.invalidateQueries(currentGeoQueryApi.getQueryKey())
      if (isInvalidLocale(queryLang)) {
        updateCookieLocale(defaultLocale)
        updateStorageLocale(defaultLocale)
        updateLocale(defaultLocale)
      } else {
        updateCookieLocale(queryLang)
        updateStorageLocale(queryLang)
        updateLocale(queryLang)
      }

      return
    }

    // locale과 lang이 같을 경우 패스
    if (!currentUser && locale === queryLang && cookieLocale === queryLang) return

    if (currentUser && locale === currentUser.locale && cookieLocale === currentUser.locale) return
    if (router.pathname.includes('/portal/') && !currentUser) return

    /**
     * Edge Case for Key Locale for Visualizing Wording Keys
     * - Only available for ringle members or in DEV mode
     */
    if (isKeyLocale) {
      if (currentUser?.is_ringle_member || IS_DEVELOPMENT) {
        updateLocale(queryLang)
        return
      } else {
        redirectToLocale(queryLang, defaultLocale)
        return
      }
    }

    // If query lang locale is invalid
    if (isInvalidLocale(queryLang)) {
      queryClient.invalidateQueries(currentGeoQueryApi.getQueryKey())

      // If cached locale is invalid as well -> redirect to default locale
      if (isInvalidLocale(cookieLocale)) {
        console.log('INVALID QUERY LOCALE & INVALID CACHED LOCALE - REDIRECTING TO DEFAULT LOCALE')
        redirectToLocale(queryLang, defaultLocale)

        // If cached locale is valid -> redirect to cached locale
      } else {
        console.log('INVALID QUERY LOCALE - REDIRECTING TO CACHED LOCALE')
        /**
         * 에러페이지인 경우 querylang == undefined
         * _error.tsx 페이지에서 리디렉션 시키도록 함.
         */
        if (router.pathname === '/_error') {
          return
        }
        redirectToLocale(queryLang, cookieLocale)
      }
    } else if (queryLang !== userLocale || queryLang !== cookieLocale) {
      queryClient.invalidateQueries(currentGeoQueryApi.getQueryKey())

      if (currentUser?.locale) {
        console.log('USING USER LOCALE:', currentUser?.locale)
        updateCookieLocale(userLocale)
        updateStorageLocale(userLocale)
        updateLocale(userLocale)
        redirectToLocale(queryLang, userLocale)
      } else {
        console.log('USING QUERY LOCALE:', queryLang)
        updateCookieLocale(queryLang)
        updateStorageLocale(queryLang)
        updateLocale(queryLang)
        // redirectToLocale(queryLang, cookieLocale)
      }
    }
  }, [router.query.lang, userType, currentUser?.locale])

  // CHANNEL TALK CONFIGURATION - TUTOR
  useEffect(() => {
    if (!currentUser || !IS_PRODUCTION) return

    const baseConfig = {
      pluginKey: getEnv('NEXT_PUBLIC_TUTOR_CHANNEL_IO_KEY'),
      language: 'en',
      memberId: currentUser?.id,
    }

    const userProfile = {
      profile: {
        firstName: currentUser?.braze?.first_name,
        lastName: currentUser?.braze?.last_name,
        name: currentUser?.profile?.name,
        email: currentUser?.email,
        avatarUrl: currentUser?.image_url,
        school: currentUser?.braze.school,
        school_tier: currentUser?.braze.school_tier,
        tutor_status: currentUser?.profile.status,
        revisit_intention: currentUser?.tutor_info.revisit_intention,
        block_intention: currentUser?.tutor_info.block_intention,
        latest_lesson_start_time: currentUser?.braze.latest_lesson_start_time,
        upcoming_lesson_count: currentUser?.upcoming_lesson_count,
        teach_status: currentUser?.tutor_info?.teach_status,
        total_lessons: currentUser?.total_lesson_count,
      },
    }

    console.log(currentUser ? 'BOOT-WITH-USER' : 'BOOT-WITH-NO-USER')
    window.ChannelIO?.('boot', baseConfig)

    console.log(currentUser ? 'UPDATE-USER' : 'UPDATE-NO-USER')
    window.ChannelIO?.('updateUser', { ...baseConfig, ...userProfile })

    return () => {
      window.ChannelIO?.('shutdown')
    }
  }, [router.query.lang, currentUser])

  return (
    <LocaleContext.Provider
      value={{ locale, updateLandingLocale: (newLocale) => _updateLandingLocale(router, locale, newLocale) }}
    >
      {children}
      <div className=" fixed bottom-[0px] right-[0px] h-[100px] w-[100px] bg-white" style={{ zIndex: -99998 }}></div>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)

/**
 * Update Locale Value in Cookie
 * @param newLocale
 */
export const updateCookieLocale = (newLocale: LocaleType): void => {
  const cookies = new Cookies()

  // 로컬 스토리지 Locale과 다를 경우 업데이트
  // - 해당 locale이 valid 한 경우에 쿠키 업데이트 하는 방식
  if (newLocale !== cookies.get('locale')) {
    cookies.set('locale', newLocale, COOKIE_OPT)
  }
}

/**
 * Update Locale Value in Local Storage
 * @param newLocale
 */
export const updateStorageLocale = (newLocale: LocaleType): void => {
  // 로컬 스토리지 Locale과 다를 경우 업데이트
  // - 해당 locale이 valid 한 경우에 쿠키 업데이트 하는 방식
  if (newLocale !== localStorage.getItem('locale')) {
    localStorage.setItem('locale', newLocale)
  }
}

// export const onSucessChangeLanguage = (router: NextRouter, curLocale: LocaleType, nextLocale: LocaleType): void => {
// const cookies = new Cookies()
// cookies.set('locale', nextLocale, COOKIE_OPT)
// const regex = new RegExp(`^/(${curLocale})`)
// router.push(router.asPath.replace(regex, `/${nextLocale}`))
// }
