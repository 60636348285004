import { QIcon_check_mark, QIcon_chevron_right, QIcon_plus_default } from '@repo/icons/index'
import { BuilderButton } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { COLORS } from '../../modules/vars'
import { subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionIconButton = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  return (
    <section id="IconButton">
      <div className={subHeaderClassName}>아이콘이 있는 버튼 사이즈</div>
      <div className="flex flex-col items-start">
        <div>
          <p>xs</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XS}
              type={BUILDER_BUTTON_TYPE.SOLID}
              textWithIcon
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.XS}
              textWithIcon
              customBorderColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              size={BUILDER_BUTTON_SIZE.XS}
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.XS}
              customBgColor={COLORS.BLUE900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="check icon">
                <QIcon_check_mark width={20} height={20} color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              size={BUILDER_BUTTON_SIZE.XS}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={20} height={20} color={COLORS.BLACK} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              disabled
              size={BUILDER_BUTTON_SIZE.XS}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>sm</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              textWithIcon
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.SM}
              textWithIcon
              customBorderColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              size={BUILDER_BUTTON_SIZE.SM}
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="check icon">
                <QIcon_check_mark width={20} height={20} color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={20} height={20} color={COLORS.BLACK} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              disabled
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>md</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              textWithIcon
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.MD}
              textWithIcon
              customBorderColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              size={BUILDER_BUTTON_SIZE.MD}
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="check icon">
                <QIcon_check_mark width={24} height={24} color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              iconOnly
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={24} height={24} color={COLORS.BLACK} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              disabled
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>lg</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.LG}
              type={BUILDER_BUTTON_TYPE.SOLID}
              textWithIcon
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.LG}
              textWithIcon
              customBorderColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              size={BUILDER_BUTTON_SIZE.LG}
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className="mr-[4px]" />
              </BuilderButton.Icon>
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className="ml-[4px]" />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              disabled
              size={BUILDER_BUTTON_SIZE.LG}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className="mr-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className="ml-[4px]" color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>xl</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              textWithIcon
              customBgColor={COLORS.BLUE100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className={'mr-[8px]'} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className={'ml-[8px]'} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.XL}
              textWithIcon
              customBorderColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[8px]'} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[8px]'} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              size={BUILDER_BUTTON_SIZE.XL}
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[8px]'} />
              </BuilderButton.Icon>
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[8px]'} />
              </BuilderButton.Icon>
            </BuilderButton>

            <BuilderButton
              disabled
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
            >
              <BuilderButton.Icon alt="plus icon">
                <QIcon_plus_default width={12} height={12} className={'mr-[8px]'} color={COLORS.WHITE} />
              </BuilderButton.Icon>
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>BuilderButton</BuilderButton.Label>
              <BuilderButton.Icon alt="chevron icon">
                <QIcon_chevron_right width={12} height={12} className={'ml-[8px]'} color={COLORS.WHITE} />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionIconButton
