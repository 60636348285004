// @ts-strict-ignore
import classNames from 'classnames'
import { descriptionClassName, headerClassName } from '../style'

const promotionColors = {
  sample: {
    default: '#CB4A33',
    '900': '#470F07',
    '800': '#6E180D',
    '700': '#922515',
    '600': '#B23521',
    '500': '#CB4A33',
    '400': '#DE694B',
    '300': '#EC886B',
    '200': '#FCCFC0',
    '100': '#FFF3EF',
  },
}

const safeList = {
  sample: {
    default: 'bg-[#CB4A33]',
    '900': 'bg-[#470F07]',
    '800': 'bg-[#6E180D]',
    '700': 'bg-[#922515]',
    '600': 'bg-[#B23521]',
    '500': 'bg-[#CB4A33]',
    '400': 'bg-[#DE694B]',
    '300': 'bg-[#EC886B]',
    '200': 'bg-[#FCCFC0]',
    '100': 'bg-[#FFF3EF]',
  },
}

const colorBoxOrderList = ['900', '800', '700', '600', 'default', '400', '300', '200', '100', '50']

const ColorSection = ({ color }: { color: string }) => {
  return (
    <>
      <p className="text-[12px] text-[#7C7F94]">{color}</p>
      <div className="mb-[32px] flex flex-row">
        {colorBoxOrderList.map((colorKey: string) => {
          const realColorKey = colorKey === 'default' && !(colorKey in promotionColors[color]) ? '500' : colorKey
          if (!(realColorKey in promotionColors[color])) return <></>
          return (
            <div key={colorKey} className="flex flex-col items-center gap-[8px]">
              <div
                className={classNames(
                  `h-[75px] w-[75px] bg-[${promotionColors?.[color]?.[realColorKey]}]`,
                  realColorKey === 'default' && 'flex items-center justify-center rounded-full'
                )}
              >
                {realColorKey === 'default' && <p className="text-[20px] font-semibold text-white">P</p>}
              </div>
              {realColorKey === 'default' || <p className="text-[12px] text-[#555766]">{realColorKey}</p>}
            </div>
          )
        })}
      </div>
    </>
  )
}

const PromotionColorStyle = () => {
  return (
    <>
      <p className={headerClassName}>Promotion Color (미정)</p>
      <p className={descriptionClassName}>
        프로모션 등의 마케팅 영역에 활용되는 컬러입니다. 이외의 프로덕트 영역에 사용을 금합니다.
      </p>

      <div className="mt-[20px]">
        {Object.keys(promotionColors).map((color) => (
          <ColorSection key={color} color={color} />
        ))}
      </div>
    </>
  )
}

export default PromotionColorStyle
