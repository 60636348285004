import * as Sentry from '@sentry/react'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { RINGLE_DOMAIN } from '../../envVars'
import urls from '../../urls'
import { locationMoveLocale, locationReplaceLocale } from '../../utils'
import { COOKIE_LIST, COOKIE_OPT, COOKIE_OPT_FOR_ACCESS_TOKEN, FACEBOOK_APP_ID } from '../../vars'
import { APP_SCREEN_NAME } from './appRouterVars'
import useIsWebview from './useIsWebview'
interface UrlObject {
  auth?: string | null | undefined
  hash?: string | null | undefined
  host?: string | null | undefined
  hostname?: string | null | undefined
  href?: string | null | undefined
  pathname?: string | null | undefined
  protocol?: string | null | undefined
  search?: string | null | undefined
  slashes?: boolean | null | undefined
  port?: string | number | null | undefined
  query?: string | null | Record<string, string | string[]> | undefined
}
export type Url = UrlObject | string
interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  unstable_skipClientCache?: boolean
  appScreenName?: string
  appSendData?: Record<string, unknown>
  stackActivity?: string
  stackSendData?: Record<string, unknown>
}

export const sendPostMessageOnlyMessage = (message) => {
  window?.ReactNativeWebView?.postMessage(message)
}
export const sendRouterEvent = async (params: Record<string, string | Record<string, unknown>>) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ROUTER_EVENT', ...params }))
}
export const sendRouterReplaceEvent = async (params: Record<string, string | Record<string, unknown>>) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ROUTER_REPLACE', ...params }))
}
const sendRouterReset = async (params: Record<string, string | Record<string, unknown>>) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ROUTER_RESET', ...params }))
}
export interface IAppRouter {
  stackPush?: any
  stackReplace?: any
}

export interface CustomRouter {
  push: (url: string, options?: TransitionOptions) => Promise<void | boolean>
  replace: (url: Url, as?, options?: TransitionOptions) => Promise<void | boolean>
  appScreenReset: (options?: TransitionOptions) => Promise<void | boolean>
  reloadapp: () => Promise<void>
  reload: () => Promise<void>
  back: () => Promise<void>
  logout: () => Promise<void>
  error: () => Promise<void>
  purchase: () => Promise<void>
  tts: (text) => Promise<void>
  share: (title: string, url: string) => Promise<void>
  message: (message: string) => Promise<void>
  linking: (url: string) => Promise<void>
  asPath: string
  events: any
  isReady: boolean
  pathname: string
  query: Record<string, string | string[]>
  beforePopState: any
  postData: any
}

const useAppRouter = ({ stackPush, stackReplace }: IAppRouter = {}): CustomRouter => {
  const isWebView = useIsWebview()
  const router = useRouter()
  const dispatch = useDispatch()
  const push = useMemo(
    () =>
      async (url: string, options: TransitionOptions = { shallow: true }): Promise<void | boolean> => {
        if (isWebView) {
          if (stackPush && !options?.appScreenName) {
            return stackPush(options.stackActivity, options.stackSendData)
          }
          if (options.appScreenName) {
            return sendRouterEvent({
              path: `${RINGLE_DOMAIN}${url}`,
              screenName: options.appScreenName,
              data: options.appSendData ?? {},
            })
          }
        }
        return locationMoveLocale(router, url, options)
      },
    [stackPush, sendRouterEvent, locationMoveLocale, router, isWebView]
  )

  const appScreenReset = async (options: TransitionOptions = { shallow: true }): Promise<void | boolean> => {
    if (isWebView) {
      if (options.appScreenName) {
        return sendRouterReset({
          screenName: options.appScreenName || APP_SCREEN_NAME.STACK_SCREEN,
          data: options.appSendData ?? {},
        })
      }
    }
  }

  const replace = async (
    url: string | Url,
    as,
    options: TransitionOptions = { shallow: true }
  ): Promise<void | boolean> => {
    if (isWebView) {
      if (stackReplace && !options?.appScreenName) {
        return stackReplace(options.stackActivity, options.stackSendData)
      }
      if (options.appScreenName) {
        return sendRouterReplaceEvent({
          path: `${RINGLE_DOMAIN}${url}`,
          screenName: options.appScreenName || APP_SCREEN_NAME.STACK_SCREEN,
          data: options.appSendData ?? {},
        })
      }
    }
    return locationReplaceLocale(router, url, options)
  }

  const logoutStudent = () => {
    // sentry user info
    Sentry.setUser(null)
    const cookies = new Cookies()
    // 로그아웃 할때 리덕스 초기화
    cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
    cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.LOGGED_BY_PARENT, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)
    // FB delete login info
    window.sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`)
    window.localStorage.removeItem(`fblst_${FACEBOOK_APP_ID}`)
    push(urls.teens.landing.home)
  }

  const reloadapp = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'RELOAD_APP' }) : router.reload())

  const reload = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'ROUTER_REFRESH' }) : router.reload())

  const back = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'ROUTER_BACK' }) : router.back())

  const message = async (message: string): Promise<void> => (isWebView ? sendPostMessageOnlyMessage(message) : null)

  const logout = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'LOGOUT' }) : logoutStudent())

  const error = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'ERROR' }) : null)

  const purchase = async (): Promise<void> => (isWebView ? sendRouterEvent({ type: 'ROUTER_PURCHASE' }) : null)

  const tts = async (text): Promise<void> =>
    isWebView ? sendPostMessageOnlyMessage(JSON.stringify({ type: 'TTS', text: text })) : null

  const linking = async (url): Promise<void> =>
    isWebView ? sendRouterEvent({ type: 'LINKING', data: { url: url } }) : null

  const share = async (title, url): Promise<void> =>
    isWebView ? sendRouterEvent({ type: 'SHARE', data: { title: title, url: url } ?? {} }) : null

  const postData = async (data: any): Promise<void> => (isWebView ? sendRouterEvent(data) : null)

  return {
    push,
    replace,
    appScreenReset,
    reloadapp,
    reload,
    back,
    message,
    logout,
    error,
    share,
    purchase,
    tts,
    linking,
    postData,
    asPath: router.asPath,
    isReady: router.isReady,
    events: router.events,
    pathname: router.pathname,
    query: router.query,
    beforePopState: router.beforePopState,
  }
}

export default useAppRouter
