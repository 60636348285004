import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { COLORS } from '../../../modules/vars'
import { useButtonState } from '../modules/SegmentStateContext'
import { SEGMENT_SIZE, SEGMENT_SIZE_KEY, SEGMENT_TYPE, SEGMENT_TYPE_KEY, Selected } from '../types'

export interface ButtonLabelProps extends PropsWithChildren {
  className?: string
  customTxtColor?: (typeof COLORS)[keyof typeof COLORS]
  children?: ReactNode
  value: Selected
}

const SegmentItem = ({ className, customTxtColor, children, value }: ButtonLabelProps) => {
  const { size, type, selectedList, setSelectedList, multiple } = useButtonState()

  const selected = selectedList.includes(value)
  const toggle = () => {
    if (multiple) {
      if (selected) setSelectedList(selectedList.filter((item) => item !== value))
      else setSelectedList([...selectedList, value])
    } else {
      setSelectedList([value])
    }
  }

  return (
    <button
      className={classNames(
        styles.hover({ type }),
        styles.pressed({ type }),
        styles.color({ type }),
        styles.size({ type, size }),
        styles.border({ type }),
        styles.borderRadius({ type }),
        styles.margin({ type }),
        selected && styles.selected({ type }),
        'flex-1 h-full',
        className
      )}
      style={customTxtColor ? { color: customTxtColor } : {}}
      onClick={toggle}
    >
      {children}
    </button>
  )
}

export default SegmentItem

const styles = {
  size: ({ size }: { type: SEGMENT_TYPE_KEY; size: SEGMENT_SIZE_KEY }) =>
    classNames(
      size === SEGMENT_SIZE.SM && `Body2-14Md`,
      size === SEGMENT_SIZE.MD && `Body2-14Bd`,
      size === SEGMENT_SIZE.LG && `Body1-16Bd`
    ),
  color: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(type === SEGMENT_TYPE.SOLID && `text-black`, type === SEGMENT_TYPE.RAISED && `text-black`),
  border: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(type === SEGMENT_TYPE.SOLID && `border-l-1 border-t-1 border-b-1 last:border-r-1 border-gray-200`),
  borderRadius: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(
      type === SEGMENT_TYPE.SOLID && `first:rounded-l-[5px] last:rounded-r-[5px]`,
      type === SEGMENT_TYPE.RAISED && 'rounded-[5px]'
    ),
  selected: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(
      type === SEGMENT_TYPE.SOLID && 'bg-purple-100 text-purple-500',
      type === SEGMENT_TYPE.RAISED && 'bg-white text-black !drop-shadow-[0_2px_8px_rgba(0,0,0,0.2)]'
    ),
  hover: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(
      'cursor-pointer',
      type === SEGMENT_TYPE.SOLID && 'hover:bg-purple-100 hover:text-purple-500',
      type === SEGMENT_TYPE.RAISED && 'hover:bg-gray-200'
    ),
  pressed: ({ type }: { type: SEGMENT_TYPE_KEY }) =>
    classNames(
      type === SEGMENT_TYPE.SOLID && 'active:bg-purple-200 active:text-purple-500',
      type === SEGMENT_TYPE.RAISED && 'active:bg-gray-300 active:text-gray-500'
    ),
  margin: ({ type }: { type: SEGMENT_TYPE_KEY }) => classNames(type === SEGMENT_TYPE.RAISED && 'mr-[4px] last:mr-[0]'),
}
