import { createContext, useContext } from 'react'
import { CHIP_SIZE, CHIP_TYPE } from '../types'
interface ChipContextType {
  type: CHIP_TYPE.ACTIONABLE | CHIP_TYPE.CHOICE | CHIP_TYPE.FILTER
  size: CHIP_SIZE.XS | CHIP_SIZE.SM | CHIP_SIZE.MD | CHIP_SIZE.LG
  selected?: boolean
  disabled?: boolean
}

export const ChipContext = createContext<ChipContextType | null>(null)

export const useChipState = () => {
  const context = useContext(ChipContext)
  if (!context) {
    throw new Error('useChipState DropdownProvider 안에서만 사용 가능합니다.')
  }
  return context
}
