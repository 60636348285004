import { useEffect } from 'react'
import { getEnv } from '../../envUtil'
import useIsWebview from './useIsWebview'

const useChannelIOSetup = (currentUser, router) => {
  const isWebview = useIsWebview()
  const lang = router.query.lang

  useEffect(() => {
    const baseConfig = {
      pluginKey: getEnv('NEXT_PUBLIC_TEENS_CHANNEL_IO_KEY'),
      language: lang,
      memberId: currentUser?.id,
      hideChannelButtonOnBoot: isWebview,
      profile: {
        name: currentUser?.name,
        email: currentUser?.email,
        mobileNumber: currentUser?.phone,
        avatarUrl: currentUser?.image_url,
        parentId: currentUser?.parent?.id,
        parentEmail: currentUser?.parent?.email,
        total_amount: currentUser?.total_amount,
        points: currentUser?.points,
      },
    }

    console.log('BOOT_CHANNEL_TALK : ', baseConfig)
    window.ChannelIO?.('boot', baseConfig)

    return () => {
      window.ChannelIO?.('shutdown')
    }
  }, [lang, currentUser, isWebview, router.asPath])
}

export default useChannelIOSetup
