import CodeSnippet from '../../CodeSnippet'
import { headerClassName } from '../../style'

const tabList = [
  'Text Only (Auto)',
  'Text Only (Click)',
  'Text + Close Button',
  'Text + Link Button',
  'Text + Close Button + Link Button',
]

const SectionTooltipExample = ({ activeTabIdx, text, place, isMultiline, remountFlag }) => {
  return (
    <>
      <div id="ExampleCode" className={headerClassName}>
        Example Code
      </div>

      <CodeSnippet
        code={`
import { COLORS, DSTooltip } from '@repo/design-system/index'
import { QIcon_bell_default } from '@repo/icons/index'

const TooltipComponent = () => {
  return (
    <div className="flex h-[100px] w-[500px] items-center justify-center">
      <DSTooltip
        place="${place}"
        triggerComponent={<QIcon_bell_default color={COLORS.PURPLE} className="cursor-pointer" />}
        contentComponent="${text}"${
          isMultiline
            ? `
        isMultiline`
            : ''
        }${
          activeTabIdx === 1
            ? `
        isOpenOnClick`
            : ''
        }${
          activeTabIdx === 2 || activeTabIdx === 4
            ? `
        hasCloseButton`
            : ''
        }${
          activeTabIdx === 3 || activeTabIdx === 4
            ? `
        linkComponent={
          <DSTooltip.LinkButton
            onClick={() => {
              alert('Navigate to another page')
            }}
          >
            Landing
          </DSTooltip.LinkButton>
        }`
            : ''
        }
      />
    </div>
  )
}
        `}
      />
    </>
  )
}

export default SectionTooltipExample
