// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    data: {},
    isPopupRendered: false,
  },
  reducers: {
    show(state, action) {
      const { id } = action.payload
      state.data[id] = action.payload
      state.isPopupRendered = true
    },
    close(state, action) {
      const { id } = action.payload
      state.data[id] = null
      state.isPopupRendered = false
    },
    closeAll(state) {
      state.data = {}
      state.isPopupRendered = false
    },
  },
})

export const popupReducer = popupSlice.reducer
export const popupActions = popupSlice.actions
