import { DEFAULT_LOCALE, isSupportedLocale, LocaleType } from './config'

export function getInitialLocale(): LocaleType {
  const localSetting = localStorage.getItem('locale')
  if (localSetting && isSupportedLocale(localSetting)) {
    return localSetting
  }

  const [browserSetting] = navigator.language.split('-')
  if (isSupportedLocale(browserSetting)) {
    return browserSetting
  }

  return DEFAULT_LOCALE
}
