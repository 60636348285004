import { QIcon_check_mark, QIcon_chevron_right, QIcon_plus_default } from '@repo/icons/index'
import { BuilderButton, COLORS } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { CodeClassName, headerClassName, subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionStates = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  return (
    <section id="Types">
      <div className={headerClassName}>Types</div>
      <div className="flex flex-row items-start">
        <div className="flex flex-col items-center min-w-[250px]">
          <div className={subHeaderClassName}>Solid Button</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton size={BUILDER_BUTTON_SIZE.XL} type={BUILDER_BUTTON_TYPE.SOLID} onClick={handleButtonClick}>
                <BuilderButton.Label>라벨!</BuilderButton.Label>
              </BuilderButton>
              <p>컬러 변경 가능</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center min-w-[250px]">
          <div className={subHeaderClassName}>Outline Button</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton
                size={BUILDER_BUTTON_SIZE.XL}
                type={BUILDER_BUTTON_TYPE.OUTLINE}
                onClick={handleButtonClick}
              >
                <BuilderButton.Label>라벨!</BuilderButton.Label>
              </BuilderButton>
              <p>컬러 변경 가능</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center min-w-[250px]">
          <div className={subHeaderClassName}>Outline_Gray Button</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton
                size={BUILDER_BUTTON_SIZE.XL}
                type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
                onClick={handleButtonClick}
              >
                <BuilderButton.Label>라벨!</BuilderButton.Label>
              </BuilderButton>
              <p className="text-purple-600">컬러 변경 금지</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-[250px]">
          <div className={subHeaderClassName}>Text Button</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton
                type={BUILDER_BUTTON_TYPE.TEXT}
                size={BUILDER_BUTTON_SIZE.LG}
                onClick={handleButtonClick}
                className="bg-gray-100 max-w-max"
              >
                <BuilderButton.Label>BuilderButton</BuilderButton.Label>
                <BuilderButton.Icon alt={'plus icon'}>
                  <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
                </BuilderButton.Icon>
              </BuilderButton>
              <p>컬러 변경 가능</p>
              <div className="flex flex-col items-center text-center text-green-600">
                <p>* 모든 size의 최소 Touch Area 높이는 44px</p>
                <p>자동으로 설정됨(회색)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start">
        <div className="flex flex-col items-center min-w-[250px]">
          <div className={subHeaderClassName}>Icon 추가하기</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton
                type={BUILDER_BUTTON_TYPE.OUTLINE}
                size={BUILDER_BUTTON_SIZE.XL}
                textWithIcon
                customBorderColor={COLORS.BLUE500}
                onClick={handleButtonClick}
                className="max-w-max"
              >
                <BuilderButton.Icon alt={'plus icon'}>
                  <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[4px]'} />
                </BuilderButton.Icon>
                <BuilderButton.Label customTxtColor={COLORS.BLUE500}>BuilderButton</BuilderButton.Label>
                <BuilderButton.Icon alt={'plus icon'}>
                  <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[4px]'} />
                </BuilderButton.Icon>
              </BuilderButton>

              <p>아이콘이 없을 때와 size가 다릅니다.</p>
              <p className="text-green-600">
                * <code className={CodeClassName}>textWithIcon</code> 을 <code className={CodeClassName}>true</code>로
                설정
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center min-w-[250px]">
          <div className={subHeaderClassName}>Icon만 있는 버튼</div>

          <div className="flex">
            <div className="flex flex-col items-center gap-[12px]">
              <BuilderButton
                iconOnly
                size={BUILDER_BUTTON_SIZE.SM}
                type={BUILDER_BUTTON_TYPE.SOLID}
                customBgColor={COLORS.BLUE900}
                onClick={handleButtonClick}
                className="max-w-max"
              >
                <BuilderButton.Icon alt={'plus icon'}>
                  <QIcon_check_mark width={20} height={20} color={COLORS.WHITE} />
                </BuilderButton.Icon>
              </BuilderButton>

              <p>다른 모든 경우와 size가 다릅니다.</p>
              <p className="text-green-600">
                * <code className={CodeClassName}>iconOnly</code> 를 <code className={CodeClassName}>true</code>로 설정
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionStates
