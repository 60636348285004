import { useState } from 'react'
import { BUTTON_SIZE, BUTTON_TYPE, DSButton, DSModal, DSPromoPopup, DSRadio } from '../../index'
import { COLORS } from '../modules/vars'
import { subHeaderClassName } from '../style'

const popupNameList = [
  'TextOnlySMOneButtonPopup',
  'TextOnlySMTwoButtonHorizontalPopup',
  'TextOnlySMTwoButtonVerticalPopup',
  'TextOnlyLGOneButtonPopup',
  'TextOnlyLGTwoButtonHorizontalPopup',
  'TextOnlyLGTwoButtonVerticalPopup',
  'ContentsSMOneButtonPopup',
  'ContentsSMTwoButtonHorizontalPopup',
  'ContentsSMTwoButtonVerticalPopup',
  'ContentsLGOneButtonPopup',
  'ContentsLGTwoButtonHorizontalPopup',
  'ContentsLGTwoButtonVerticalPopup',
  'XLTwoButtonPopup',
  'XLNoButtonPopup',
  'XLTwoButtonScrollPopup',
  'XLNoButtonScrollPopup',
  'PromoSMOneButtonPopup',
  'PromoSMTwoButtonHorizontalPopup',
  'PromoSMTwoButtonVerticalPopup',
  'PromoLGOneButtonPopup',
  'PromoLGTwoButtonHorizontalPopup',
  'PromoLGTwoButtonVerticalPopup',
  'OverlapTestPopup',
]

const Popup = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showSubPopup, setShowSubPopup] = useState<boolean>(false)

  const openPopup: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowPopup(true)
  }
  const closePopup: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowPopup(false)
  }
  const openSubPopup: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowSubPopup(true)
  }
  const closeSubPopup: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowSubPopup(false)
  }

  const [count, setCount] = useState<number>(0)

  const increaseCount: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setCount((prev) => prev + 1)
  }

  const Content = () => {
    return (
      <div className="mt-[8px] flex h-[100px] w-full flex-col items-center justify-center space-y-[8px] rounded-[8px] bg-gray-300">
        <p>{count}</p>
        <div onClick={increaseCount}>Increase</div>
      </div>
    )
  }

  const TextOnlySMOneButtonPopup = () => {
    return (
      <DSModal id="TextOnlyMOneButtonPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const TextOnlySMTwoButtonHorizontalPopup = () => {
    return (
      <DSModal id="TextOnlySMTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.OUTLINE} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const TextOnlySMTwoButtonVerticalPopup = () => {
    return (
      <DSModal id="TextOnlySMTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const TextOnlyLGOneButtonPopup = () => {
    return (
      <DSModal id="TextOnlyMOneButtonPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const TextOnlyLGTwoButtonHorizontalPopup = () => {
    return (
      <DSModal id="TextOnlySMTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.OUTLINE} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const TextOnlyLGTwoButtonVerticalPopup = () => {
    return (
      <DSModal id="TextOnlySMTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsSMOneButtonPopup = () => {
    return (
      <DSModal id="ContentsSMOneButtonPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsSMTwoButtonHorizontalPopup = () => {
    return (
      <DSModal id="ContentsSMTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.OUTLINE} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsSMTwoButtonVerticalPopup = () => {
    return (
      <DSModal id="ContentsSMTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsLGOneButtonPopup = () => {
    return (
      <DSModal id="ContentsLGOneButtonPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsLGTwoButtonHorizontalPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonHorizontalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.OUTLINE} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const ContentsLGTwoButtonVerticalPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.MD} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const XLTwoButtonPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="xl">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const XLNoButtonPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="xl" closeButton>
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
      </DSModal>
    )
  }

  const XLTwoButtonScrollPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="xl">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.OUTLINE} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={increaseCount}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const XLNoButtonScrollPopup = () => {
    return (
      <DSModal id="ContentsLGTwoButtonVerticalPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="xl" closeButton>
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          Detach 후 사용하세요. Body text를 끄고 사용할 수 있습니다. 상황에 따라 Text의 위치를 커스텀하여 사용합니다.
          <Content />
        </DSModal.Content>
      </DSModal>
    )
  }

  const PromoSMOneButtonPopup = () => {
    return (
      <DSPromoPopup
        id="PromoSMOneButtonPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="sm"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[394px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              288*394
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const PromoSMTwoButtonHorizontalPopup = () => {
    return (
      <DSPromoPopup
        id="PromoSMTwoButtonHorizontalPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="sm"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[394px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              288*394
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const PromoSMTwoButtonVerticalPopup = () => {
    return (
      <DSPromoPopup
        id="PromoSMTwoButtonVerticalPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="sm"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[360px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              288*360
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={increaseCount}>
            <DSButton.Label customTxtColor={COLORS.WHITE}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const PromoLGOneButtonPopup = () => {
    return (
      <DSPromoPopup
        id="PromoLGOneButtonPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="lg"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[460px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              424*460
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const PromoLGTwoButtonHorizontalPopup = () => {
    return (
      <DSPromoPopup
        id="PromoLGTwoButtonHorizontalPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="lg"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[460px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              424*460
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const PromoLGTwoButtonVerticalPopup = () => {
    return (
      <DSPromoPopup
        id="PromoLGTwoButtonVerticalPopup"
        isOpen={showPopup}
        setIsOpen={setShowPopup}
        size="lg"
        bgColor={COLORS.PURPLE900}
        closeIconColor={COLORS.WHITE}
      >
        <DSPromoPopup.Content>
          <div className="flex h-[426px] w-full items-center justify-center bg-purple-800">
            <p className="Headline3-22Bd text-center text-white">
              contents-area
              <br />
              424*426
            </p>
          </div>
        </DSPromoPopup.Content>
        <DSPromoPopup.ButtonContainer direction="vertical">
          <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.WHITE} onClick={closePopup}>
            <DSButton.Label customTxtColor={COLORS.PURPLE800}>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.LG} type={BUTTON_TYPE.TEXT} customBgColor={COLORS.WHITE} onClick={increaseCount}>
            <DSButton.Label customTxtColor={COLORS.WHITE}>DSButton</DSButton.Label>
          </DSButton>
        </DSPromoPopup.ButtonContainer>
      </DSPromoPopup>
    )
  }

  const OverlapTestPopup = () => {
    const onClickWhiteButton: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      closePopup(e)
      openSubPopup(e)
    }

    return (
      <DSModal id="OverlapTestPopup" isOpen={showPopup} setIsOpen={setShowPopup} size="lg">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton
            size={BUTTON_SIZE.MD}
            type={BUTTON_TYPE.OUTLINE}
            customBgColor={COLORS.WHITE}
            onClick={onClickWhiteButton}
          >
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
          <DSButton size={BUTTON_SIZE.MD} onClick={closePopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const SubPopup = () => {
    return (
      <DSModal id="SubPopup" isOpen={showSubPopup} setIsOpen={setShowSubPopup} size="sm">
        <DSModal.Title>Title</DSModal.Title>
        <DSModal.Content>
          text only popup의 경우 커스텀을 최대한 지양합니다. Body text를 끄고 사용할 수 있습니다.
        </DSModal.Content>
        <DSModal.ButtonContainer direction="horizontal">
          <DSButton size={BUTTON_SIZE.MD} onClick={closeSubPopup}>
            <DSButton.Label>DSButton</DSButton.Label>
          </DSButton>
        </DSModal.ButtonContainer>
      </DSModal>
    )
  }

  const [popupIndex, setPopupIndex] = useState<number>(0)

  const popupList = [
    TextOnlySMOneButtonPopup,
    TextOnlySMTwoButtonHorizontalPopup,
    TextOnlySMTwoButtonVerticalPopup,
    TextOnlyLGOneButtonPopup,
    TextOnlyLGTwoButtonHorizontalPopup,
    TextOnlyLGTwoButtonVerticalPopup,
    ContentsSMOneButtonPopup,
    ContentsSMTwoButtonHorizontalPopup,
    ContentsSMTwoButtonVerticalPopup,
    ContentsLGOneButtonPopup,
    ContentsLGTwoButtonHorizontalPopup,
    ContentsLGTwoButtonVerticalPopup,
    XLTwoButtonPopup,
    XLNoButtonPopup,
    XLTwoButtonScrollPopup,
    XLNoButtonScrollPopup,
    PromoSMOneButtonPopup,
    PromoSMTwoButtonHorizontalPopup,
    PromoSMTwoButtonVerticalPopup,
    PromoLGOneButtonPopup,
    PromoLGTwoButtonHorizontalPopup,
    PromoLGTwoButtonVerticalPopup,
    OverlapTestPopup,
  ]

  const Popup = popupList[popupIndex]

  return (
    <div className="flex flex-col space-y-[24px]">
      <p className={subHeaderClassName}>Controls</p>
      <DSRadio
        controlSelected={[popupIndex, setPopupIndex]}
        containerClassName="flex flex-col space-y-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]"
      >
        {popupNameList.map((popupName, index) => (
          <DSRadio.Item key={index} value={index}>
            {popupName}
          </DSRadio.Item>
        ))}
      </DSRadio>

      <div className="h-[4px]" />
      <div className="h-[4px] bg-gray-600" />
      <div className="h-[4px]" />

      <p className={subHeaderClassName}>Popup QA</p>
      <DSButton size={BUTTON_SIZE.MD} onClick={openPopup}>
        <DSButton.Label>Open Popup</DSButton.Label>
      </DSButton>
      <Popup />
      <SubPopup />
    </div>
  )
}

export default Popup
