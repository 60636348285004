import { createContext, useContext, useEffect, useState } from 'react'
import { getCleanUrl } from '../../helpers/gtmHelper'
import { RINGLE_DOMAIN } from '../envVars'
import useAppRouter from '../hooks/common/useAppRouter'

export interface ContextProps {
  urlInfo: { prevUrl: { fullUrl: string; path: string }; currentUrl: { fullUrl: string; path: string } }
}

export const EventRouterContext = createContext<ContextProps>({
  urlInfo: {
    prevUrl: { fullUrl: '', path: '' },
    currentUrl: { fullUrl: '', path: '' },
  },
})

export const EventRouterProvider = ({ children }) => {
  const router = useAppRouter()

  const [urlInfo, setUrlInfo] = useState({
    prevUrl: {
      fullUrl: null,
      path: null,
    },
    currentUrl: {
      fullUrl: null,
      path: null,
    },
  })

  const handleRouteChangeComplete = (router) => {
    setUrlInfo(({ currentUrl }) => ({
      prevUrl: { ...currentUrl },
      currentUrl: {
        fullUrl: router.asPath ? `https://${RINGLE_DOMAIN}${router.asPath}` : null,
        path: router.pathname ? getCleanUrl(router.pathname) : null,
      },
    }))
  }

  useEffect(() => {
    if (!router) return
    handleRouteChangeComplete(router)
  }, [router.asPath])

  return <EventRouterContext.Provider value={{ urlInfo }}>{children}</EventRouterContext.Provider>
}

export const useEventRouter = () => useContext(EventRouterContext)
