// @ts-strict-ignore
import { addDays, endOfWeek, format, isAfter, isBefore, startOfWeek } from 'date-fns'
import { ko } from 'date-fns/locale'
import { s_common } from '../../modules/i18n/strings/auto/wording'

type weekOptionType = { weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 }
export const WEEK_OPTIONS_SUN: weekOptionType = { weekStartsOn: 0 }

export const idFromDate = (date) => format(date, 'yyyy-MM-dd-HH-mm', { locale: ko })
export const idFrom = ({ year, month, day, hours, minutes }) =>
  idFromDate(new Date(year, month - 1, day, hours, minutes))

export const getWeekDaysSun = (date) => {
  const start = startOfWeek(date, WEEK_OPTIONS_SUN)
  return Array(7)
    .fill(0)
    .map((_, i) => addDays(start, i))
}

export const isNotAllowSelectedDay = (initial, date) => {
  return (
    isBefore(date, startOfWeek(initial.today, WEEK_OPTIONS_SUN)) ||
    isAfter(date, endOfWeek(initial.toMonth, WEEK_OPTIONS_SUN))
  )
}

export const dayOfWeeks = (number, t) => {
  const days = [
    t(s_common.sun),
    t(s_common.mon),
    t(s_common.tue),
    t(s_common.wed),
    t(s_common.thu),
    t(s_common.fri),
    t(s_common.sat),
  ]
  return days[number]
}

export const getFormattedHour = (time, noAM?) => {
  const hour = parseInt(time.slice(11, 16))
  return hour < 10
    ? hour == 0
      ? 12
      : parseInt(time.slice(12, 16))
    : hour > 12
    ? hour - 12
    : parseInt(time.slice(11, 16))
}

export const getFormattedMinute = (time, noAM?) => {
  const minute = parseInt(time.slice(14, 16))
  const hour = parseInt(time.slice(11, 16))
  const AMPM = hour > 12 ? 'pm' : 'am'
  return minute > 0 ? `:${minute}${noAM ? '' : AMPM}` : noAM ? '' : AMPM
}

export const getAbsoluteMinute = (time, noAM?) => {
  const minute = parseInt(time.slice(14, 16))
  return minute
}
