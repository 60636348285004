// @ts-strict-ignore
import { useMemo, useRef } from 'react'
import { LOCALE_EN, LOCALE_KO, localeIndex } from '../../modules/i18n/config'
import { ContextProps, useLocale } from '../context/LocaleContext'

type SType = string | Element | Array<any>
interface IUseTranslationReturnType extends ContextProps {
  t: (obj: SType) => string
  isLocaleEN: boolean
  isLocaleKO: boolean
}

export default function useTranslation(): IUseTranslationReturnType {
  const { locale, updateLandingLocale } = useLocale()

  const cached = useRef<Map<SType, string>>(new Map())

  const t = useMemo(() => {
    return (obj: SType) => {
      if (!obj) {
        return ''
      }
      if (typeof obj == 'string') {
        return obj
      }
      if (cached.current.has(obj)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return cached.current.get(obj)!
      }
      const value = obj[localeIndex(locale)] === undefined ? obj[localeIndex(LOCALE_EN)] : obj[localeIndex(locale)]
      const result = value ? value : ''
      cached.current.set(obj, result)
      return result
    }
  }, [locale, cached])
  const isLocaleEN = locale == LOCALE_EN
  const isLocaleKO = locale == LOCALE_KO

  return {
    t,
    locale,
    isLocaleEN,
    isLocaleKO,
    updateLandingLocale,
  }
}
