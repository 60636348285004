import { createSlice } from '@reduxjs/toolkit'

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState: {
    unassignedLessons: [{ lesson_id: '' }],
    requestedLessons: [{ lesson_id: '' }],
    homeData: {},
  },
  reducers: {
    setHomeData(state, action) {
      state.homeData = action.payload
    },
    setUnassignedLessons(state, action) {
      state.unassignedLessons = action.payload
    },
    setRequestedLessons(state, action) {
      state.requestedLessons = action.payload
    },
    setFilterLesson(state, action) {
      state.requestedLessons = state.requestedLessons.filter((item, i) => item.lesson_id != action.payload)
    },
  },
})

export const lessonsReducer = lessonsSlice.reducer
export const lessonsActions = lessonsSlice.actions
