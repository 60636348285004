import { TAB_TYPE, Tab } from '@repo/design-system/index'
import { useEffect, useState } from 'react'
import TooltipNew from '../../../../../apps/ringle-student/components/_legacy/TooltipNew'
import { DSTooltipProps } from '../Components/Tooltip/types'
import { descriptionClassName, headerClassName } from '../style'
import SectionTooltipControls from './Tooltip/SectionTooltipControls'
import SectionTooltipExample from './Tooltip/SectionTooltipExample'
import TextClose from './Tooltip/TextClose'
import TextCloseLink from './Tooltip/TextCloseLink'
import TextLink from './Tooltip/TextLink'
import TextOnlyAuto from './Tooltip/TextOnlyAuto'
import TextOnlyClick from './Tooltip/TextOnlyClick'

const SectionLegacy = () => {
  return (
    <>
      <div className={headerClassName}>Legacy Tooltip</div>
      <div className={descriptionClassName}>
        Legacy Tooltip을 사용하고 있는 곳에서 DSTooltiip을 사용하도록 변경이 필요합니다.
        <br />
        student(약 10개 파일), teens(22개), tutor(15개), tutor-apply(3개) 각각에 모두 존재합니다.
      </div>
      <div className="px-[12px]">
        <TooltipNew defaultButton id={'legacy-tooltip'} event="hover" place="right">
          툴팁2
        </TooltipNew>
      </div>
    </>
  )
}

const Tooltip = () => {
  const [text, setText] = useState<string>('Tooltip Contents')
  const [place, setPlace] = useState<DSTooltipProps['place']>('right')
  const [isMultiline, setIsMultiline] = useState<DSTooltipProps['isMultiline']>(false)
  const [remountFlag, setRemountFlag] = useState<boolean>(false)

  useEffect(() => {
    setRemountFlag(true)
  }, [remountFlag, isMultiline])

  const [activeTabIdx, setActiveTabIdx] = useState(0)
  return (
    <>
      <div className={headerClassName}>Tooltip</div>
      <SectionTooltipControls
        text={text}
        setText={setText}
        place={place}
        setPlace={setPlace}
        isMultiline={isMultiline}
        setIsMultiline={setIsMultiline}
        remountFlag={remountFlag}
        setRemountFlag={setRemountFlag}
      />

      <section className="mt-[20px] overflow-x-auto">
        <Tab type={TAB_TYPE.SCROLL} activeTabIdx={activeTabIdx} setActiveTabIdx={setActiveTabIdx}>
          <Tab.TabItem name="Text Only (Auto)">
            <TextOnlyAuto text={text} place={place} isMultiline={isMultiline} remountFlag={remountFlag} />
          </Tab.TabItem>
          <Tab.TabItem name="Text Only (Click)">
            <TextOnlyClick text={text} place={place} isMultiline={isMultiline} remountFlag={remountFlag} />
          </Tab.TabItem>
          <Tab.TabItem name="Text + Close Button">
            <TextClose text={text} place={place} isMultiline={isMultiline} remountFlag={remountFlag} />
          </Tab.TabItem>
          <Tab.TabItem name="Text + Link Button">
            <TextLink text={text} place={place} isMultiline={isMultiline} remountFlag={remountFlag} />
          </Tab.TabItem>
          <Tab.TabItem name="Text + Close Button + Link Button">
            <TextCloseLink text={text} place={place} isMultiline={isMultiline} remountFlag={remountFlag} />
          </Tab.TabItem>
        </Tab>
      </section>

      <SectionTooltipExample
        activeTabIdx={activeTabIdx}
        text={text}
        place={place}
        isMultiline={isMultiline}
        remountFlag={remountFlag}
      />

      <SectionLegacy />
    </>
  )
}

export default Tooltip
