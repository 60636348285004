import classNames from 'classnames'
import { COLORS } from '../modules/vars'
import { descriptionClassName, headerClassName } from '../style'

// black/white 제외
const colorList = [
  { colorName: 'Gray', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('GRAY')) },
  { colorName: 'Purple', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('PURPLE')) },
  { colorName: 'Blue', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('BLUE')) },
  { colorName: 'Green', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('GREEN')) },
  { colorName: 'Red', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('RED')) },
  { colorName: 'Yellow', colorKeyList: Object.keys(COLORS).filter((key) => key.startsWith('YELLOW')) },
]

const ColorBox = ({ colorName, colorKeyList }: { colorName: string; colorKeyList: string[] }) => {
  const hasDefault = !colorKeyList.every((colorKey) => colorKey.endsWith('0'))
  colorKeyList = colorKeyList.filter((colorKey) => colorKey.endsWith('0')).reverse()

  return (
    <>
      <p className="text-[12px] text-[#7C7F94]">{colorName}</p>
      <div className="mb-[32px] flex flex-row">
        {colorKeyList.map((colorKey: string) => {
          const isDefault = hasDefault && colorKey.endsWith('500')
          return (
            <div key={colorKey} className="flex flex-col items-center gap-[8px]">
              <div
                className={classNames(
                  `h-[75px] w-[75px]`,
                  isDefault && 'flex items-center justify-center rounded-full'
                )}
                style={{ backgroundColor: COLORS[colorKey] }}
              >
                {isDefault && <p className="text-[20px] font-semibold text-white">P</p>}
              </div>
              {isDefault || <p className="text-[12px] text-[#555766]">{colorKey.replace(/^\D+/g, '')}</p>}
            </div>
          )
        })}
      </div>
    </>
  )
}

const ColorStyle = () => {
  return (
    <>
      <div className={headerClassName}>Color Style</div>
      <div className={descriptionClassName}>
        500을 Primary Color로 사용합니다. Gray Scale은 Primary Color가 없습니다.
        <br />
        Color Style에 지정되지 않은 컬러의 사용을 금합니다. 새로운 컬러 필요 시 디자인 시스템 추가를 요청합니다.
      </div>

      <div className="mt-[20px]" />
      <p className="text-[12px] text-[#7C7F94]">Black\White</p>
      <div className="mb-[32px] flex flex-row">
        <div className="flex flex-col items-center gap-[8px]">
          <div className={`h-[75px] w-[75px] bg-black`} />
          <p className="text-[12px] text-[#555766]">{COLORS.BLACK}</p>
        </div>
        <div className="flex flex-col items-center gap-[8px]">
          <div className={`border-1 h-[75px] w-[75px] border-gray-300 bg-white`} />
          <p className="text-[12px] text-[#555766]">{COLORS.WHITE}</p>
        </div>
      </div>

      {colorList.map((item) => (
        <ColorBox key={item.colorName} colorName={item.colorName} colorKeyList={item.colorKeyList} />
      ))}
    </>
  )
}

export default ColorStyle
