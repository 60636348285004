import { useEffect, useState } from 'react'

const useIsMobile = (defaultIsMobile: boolean | undefined = undefined) => {
  const MOBILE_SIZE = 786

  const [isMobile, setIsMobile] = useState(() => {
    if (defaultIsMobile !== undefined) {
      return defaultIsMobile
    } else {
      return typeof window !== 'undefined' && window?.innerWidth < MOBILE_SIZE ? true : false
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < window.innerWidth) {
        setIsMobile(window.screen.width < MOBILE_SIZE)
      } else {
        setIsMobile(window.innerWidth < MOBILE_SIZE)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setIsMobile(window.screen.width < MOBILE_SIZE)
    } else {
      setIsMobile(window.innerWidth < MOBILE_SIZE)
    }
  }, [])

  return isMobile
}

export default useIsMobile
