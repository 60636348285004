import { getEnv } from './envUtil'

type EnvironmentType = 'local' | 'qa' | 'staging' | 'production'

export const ENVIRONMENT = getEnv('ENVIRONMENT') as EnvironmentType

export const IS_LOCAL = ENVIRONMENT === 'local'
export const IS_QA = ENVIRONMENT === 'qa'
export const IS_STAGING = ENVIRONMENT === 'staging'
export const IS_PRODUCTION = ENVIRONMENT === 'production'

export const IS_DEVELOPMENT = IS_LOCAL || IS_QA || IS_STAGING

export const COOKIE_DOMAIN = getEnv('COOKIE_DOMAIN')
export const RINGLE_DOMAIN = getEnv('RINGLE_DOMAIN')
