// @ts-strict-ignore
import Div from './Div'
import { DivPropType } from './DivPropsType'

type ColType =
  | DivPropType
  | {
      auto?
      children?
      key?
      id?
    }

const Col = (props: ColType) => {
  const { id, auto, key, ...others } = props
  if (auto) others['colAuto'] = auto
  return (
    <Div key={key} col {...others} id={id}>
      {props.children}
    </Div>
  )
}

export default Col
