import { QIcon_close_default } from '@repo/icons/index'
import { COLORS } from '../../../modules/vars'
import { useDSPromoPopupState } from '../modules/DSPropmoPopupContext'
import { DSPROMOPOPUP_SIZE_TYPE } from './DSPromoPopupMain'

interface DSPromoPopupCloseButtonProps {
  closePopup: React.MouseEventHandler<SVGSVGElement>
  color?: (typeof COLORS)[keyof typeof COLORS]
}

const DSPromoPopupCloseButton = (props: DSPromoPopupCloseButtonProps) => {
  const { closePopup, color } = props
  const { size } = useDSPromoPopupState()

  const className = CLASSNAME[size]

  return (
    <div className={className}>
      <QIcon_close_default color={color} className="cursor-pointer" onClick={closePopup} width={20} height={20} />
    </div>
  )
}

export default DSPromoPopupCloseButton

const CLASSNAME: { [size in DSPROMOPOPUP_SIZE_TYPE]: string } = {
  sm: 'flex flex-row justify-end w-full mb-[12px]',
  lg: 'flex flex-row justify-end w-full mb-[20px]',
}
