import { QIcon_check_outline, QIcon_share } from '@repo/icons/index'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import Switch from './Components/Switch/Switch'
import { COLORS } from './modules/vars'

const CodeSnippet = ({ code }: { code: string }) => {
  const [darkmode, setDarkmode] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  const handleCopy = () => {
    try {
      setCopied(true) // 복사 상태 업데이트
      setTimeout(() => {
        setCopied(false) // 복사 상태 업데이트
      }, 3000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <div className="relative flex w-[740px]">
      <div className="absolute right-[10px] top-[-20px] flex flex-row items-center justify-end gap-[8px]">
        <div className="Body1-14Md">다크모드</div>
        <Switch checked={darkmode} setChecked={setDarkmode}></Switch>
      </div>
      <CopyToClipboard text={code} onCopy={handleCopy}>
        <div
          className="absolute right-0 top-0 m-[6px] p-[4px] rounded-[4px] flex flex-row Body1-14Md bg-gray-50 text-gray-600 items-center gap-[4px] cursor-pointer shadow-md"
          style={{ position: 'absolute', right: 10, top: 10 }}
        >
          {copied ? (
            <>
              <QIcon_check_outline color={COLORS.GRAY600} />
              복사 되었습니다!!
            </>
          ) : (
            <>
              <QIcon_share color={COLORS.GRAY600} />
              Copy
            </>
          )}
        </div>
      </CopyToClipboard>
      <SyntaxHighlighter language="tsx" style={darkmode ? dracula : undefined} customStyle={{ width: '100%' }}>
        {code}
      </SyntaxHighlighter>
    </div>
  )
}

export default CodeSnippet
