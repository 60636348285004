// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'
import { endOfWeek, startOfWeek } from 'date-fns'
import { WEEK_OPTIONS_SUN, getWeekDaysSun } from '../modules/schedule/scheduleUtils'

const logDataSlice = createSlice({
  name: 'logData',
  initialState: {
    category: null,
    loadedLogData: { logs: [] },
    monthlyHasLogData: [],
    userHasLogData: null,

    selectedDay: null,
    selectedDays: [],
    selectedWeekStartDay: null,
    selectedWeekEndDay: null,
    calendarMonth: null,

    scrollYValue: [],
    logSectionHeight: null,
  },
  reducers: {
    init(state, action) {
      state.selectedDay = new Date()
      state.selectedWeekStartDay = startOfWeek(new Date(), WEEK_OPTIONS_SUN)
      state.selectedWeekEndDay = endOfWeek(new Date(), WEEK_OPTIONS_SUN)
      state.monthlyHasLogData = []
    },
    setDay(state, action) {
      state.selectedDay = action.payload
    },
    setWeek(state, action) {
      state.selectedWeekStartDay = startOfWeek(action.payload, WEEK_OPTIONS_SUN)
      state.selectedWeekEndDay = endOfWeek(action.payload, WEEK_OPTIONS_SUN)
    },
    setLogs(state, action) {
      state.loadedLogData = action.payload
    },
    setSelectedDays(state, action) {
      state.selectedDays = getWeekDaysSun(action.payload)
    },
    setCategory(state, action) {
      state.category = action.payload
    },
    setMonthlyHasLogData(state, action) {
      state.monthlyHasLogData = action.payload
    },
    setscrollYValue(state, action) {
      const idx = state.scrollYValue.findIndex((v) => v.date === action.payload.date)
      if (idx > -1) {
        state.scrollYValue[idx].scrollY = action.payload.scrollY
      } else {
        state.scrollYValue.push(action.payload)
      }
    },
    setCalendarMonth(state, action) {
      state.calendarMonth = action.payload
    },
    setUserHasLogData(state, action) {
      state.userHasLogData = action.payload
    },
    setLogSectionHeight(state, action) {
      state.logSectionHeight = action.payload
    },
  },
})

export const logDataReducer = logDataSlice.reducer
export const logDataActions = logDataSlice.actions
