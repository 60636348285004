import classNames from 'classnames'

interface ISwitchProps {
  checked: boolean
  disabled?: boolean
  className?: string
  customBgColor?: string
  setChecked: React.Dispatch<React.SetStateAction<boolean>> | (() => void)
}

const Switch = ({ checked, disabled, setChecked, className = '', customBgColor }: ISwitchProps) => {
  const handleToggle = () => {
    if (!disabled) {
      if (typeof setChecked === 'function' && setChecked.length === 1) {
        // setChecked가 React.Dispatch<React.SetStateAction<boolean>> 타입인 경우
        setChecked(!checked)
      } else if (typeof setChecked === 'function') {
        // setChecked가 () => void 타입인 경우
        ;(setChecked as () => void)()
      }
    }
  }

  return (
    <div
      onClick={handleToggle}
      style={{ backgroundColor: customBgColor && checked ? customBgColor : '' }}
      className={classNames(
        'flex h-[18px] w-[30px] cursor-pointer items-center rounded-full',
        checked ? 'bg-purple-500' : 'bg-gray-300',
        disabled && 'opacity-50',
        className
      )}
    >
      <div
        className={classNames(
          'h-[16px] w-[16px] rounded-full bg-white transition-transform',
          checked ? 'translate-x-[13px]' : 'translate-x-[1px]'
        )}
      ></div>
    </div>
  )
}

export default Switch
