// @ts-strict-ignore
import { LocaleType } from 'aws-sdk/clients/workdocs'
import TagManager from 'react-gtm-module'
import { CurrentUser } from '../model/Common/common.interface'
import { IS_DEVELOPMENT } from '../modules/envVars'
import { LandingState } from '../modules/ids/gtm/teens'
import { firstUser } from '../modules/utils'

/** ----------------------- */
/** Coupon Helper Functions
/** ----------------------- */

interface IGtmUserIdDataLayer extends Record<string, string | number> {
  user_id: number
}

export enum GTM_EVENT {
  /**
   * GOOGLE ADS & FACEBOOK PIXEL - STANDARD_EVENTS
   */
  COMPLETE_REGISTRATION = 'complete_registration', // Sign Up
  PURCHASE = 'purchase', // PV student/portal/pricing/success/[purchaseId]
  CONTACT = 'contact',
  ADD_TO_WITHLIST = 'add_to_wishlist', // PV student/portal/pricing
  ADD_PAYMENT_INFO = 'add_payment_info', // click purchase button after  PV student/portal/pricing
  INITIATE_CHECKOUT = 'initiate_checkout', // PV student/portal/pricing/[productId]
  START_TRIAL = 'start_trial',
  LEAD = 'lead',

  /**
   * CUSTOM EVENTS
   */
  SIGN_IN = 'sign_in',
  SET_USER_ID = 'set_user_id',
  SET_USER_INFO = 'set_user_info',
  SET_IS_RINGLE_MEMBER = 'set_is_ringle_member',
  SET_TUTOR_STATUS = 'set_tutor_status',
  SET_HAS_CREDIT = 'set_has_credit',
  INPUT = 'input',
  IMPRESSION = 'impression',
  USERTYPE_DIMENSION = 'usertype_dimension',

  // Onsite GNB, Popup, Multimedia Banner Events
  VIEW_ONSITE_GNB = 'view_onsite_gnb',
  VIEW_ONSITE_POPUP = 'view_onsite_popup',
  VIEW_ONSITE_MULTI = 'view_onsite_multi',
  CLICK_ONSITE_GNB = 'click_onsite_gnb',
  CLICK_ONSITE_POPUP = 'click_onsite_popup',
  CLICK_ONSITE_MULTI = 'click_onsite_multi',
}

export enum GTM_CLICK_EVENT {
  /**
   * General Click Button Events
   */
  CLICK_BUTTON = 'click_button', // FIXME OLD CLICK BTN EVENT - LEGACY
  CLICK_BUTTON_NEW = 'click_button_new', // NEW CLICK BTN EVENT

  /**
   * Custom Click Button Events
   */
  CLICK_PURCHASE = 'click_purchase', // product_id
  CLICK_PRODUCT = 'click_product', // product_id
  CLICK_START_RINGLE = 'click_start_ringle',
  CLICK_INVITE = 'click_invite',
  CLICK_SHARE = 'click_share',
  CLICK_PURCHASE_CONFERENCE = 'click_purchase_conference',
  CLICK_BOOK_LESSON = 'click_book_lesson',
  CLICK_SELECT_CREDIT = 'click_select_credit',
  CLICK_SELECT_TUTOR = 'click_select_tutor',
  CLICK_SELECT_TUTOR_AVATAR = 'click_select_tutor_avatar',
  CLICK_NEXT_LESSON_SCHEDULE = 'click_next_lesson_schedule',
  CLICK_MATERIAL_CATEGORY = 'click_material_category',
  CLICK_SEE_MATERIAL_DETAIL = 'click_see_material_detail',
  CLICK_SELECT_MATERIAL = 'click_select_material',
  CLICK_BOOKMARK_MATERIAL = 'click_bookmark_material',
  CLICK_SEARCH_MATERIAL = 'click_search_material',
  CLICK_EXIT_BOOK_LESSON = 'click_exit_book_lesson',
  CLICK_CONTENT_AD_SIGN_UP = 'click_content_ad_sign_up',
  CLICK_MATERIAL_AD_APP_INSTALL = 'click_material_ad_app_install',
  CLICK_LANDING_BUTTON = 'click_landing_button',
  CLICK_PURCHASE_PAYMENT = 'click_purchase_payment',

  /**
   * Custom Teens Click Button Events
   */
  CLICK_INSTALL_APP = 'click_install_app',
  CLICK_SIGN_UP = 'click_sign_up',
  CLICK_BOOK_A_CALL = 'click_book_a_call',
}

export enum GTM_PV_EVENT {
  /**
   * Custom Page View Events
   */
  PV_BASIC = 'page_view_basic',
  PV_LANDING_MATERIAL_AD = 'view_landing_material_ad',
}

export enum LOCATION_TYPE {
  LANDING = 'landing',
  PORTAL = 'portal',
}

export enum TAG_MANAGER_ID_TYPE {
  TEENS = 'TEENS',
  QA = 'QA',
}

export enum LOCALE_USER_TYPE {
  STUDENT = 'student',
  TUTOR = 'tutor',
  TEENS = 'teens',
  WEBVIEW = 'webview',
}
export enum PLATFORM_TYPE {
  WEB = 'web',
  MOBILE_WEB = 'mobile_web',
  APP = 'app', //for webview pages
}

export const extractUserTypeFromPath = (path: string): LOCALE_USER_TYPE => {
  const splitUrlPath = path.split('/')

  // Return Student as default if splitted URL path has length below 2
  if (splitUrlPath.length < 3) {
    return LOCALE_USER_TYPE.STUDENT
  }

  // User Type is at the second index of the url path
  const userType = splitUrlPath[2] as LOCALE_USER_TYPE

  if (Object.values(LOCALE_USER_TYPE).includes(userType)) {
    return userType
  } else {
    return LOCALE_USER_TYPE.STUDENT
  }
}
export const TAG_MANAGER_ID = IS_DEVELOPMENT ? 'GTM-5TKJMLT' : 'GTM-5Q5PWWK'
/** ------------------------------------ */
/** GTM INTIALIZATION / REMOVAL HELPERS  */
/** ------------------------------------ */

const getAllLoadedGTMScripts = (): HTMLScriptElement[] =>
  [].filter.call(document.getElementsByTagName('head')[0].children, (el: HTMLElement & HTMLScriptElement) =>
    el.src?.includes('googletagmanager')
  )

const getDataLayerListByKey = (key: string) =>
  (window.dataLayer || []).filter((dl: Record<string, string | number>) => dl[key] !== undefined)

export const isGTMLoaded = (): boolean => {
  if (typeof document !== 'undefined') {
    return getAllLoadedGTMScripts().length > 0
  } else {
    return false
  }
}

export const removeGTMScript = (): void => {
  const gtmTagScripts = getAllLoadedGTMScripts()

  for (let i = 0; i < gtmTagScripts.length; i++) {
    gtmTagScripts[i].parentNode.removeChild(gtmTagScripts[i])
  }
}

export const initGTMScript = (): void => {
  const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : TAG_MANAGER_ID_TYPE.TEENS

  if (typeof window === 'undefined') return

  if (!isGTMLoaded()) {
    console.log(`Initializing Google Tag Manager - ${tagType}`)
    removeGTMScript()
    TagManager.initialize({
      gtmId: TAG_MANAGER_ID,
    })
  } else {
    console.log(`Google Tag Manager - ALREADY INIT - ${tagType}`)
  }
}

export const setGTMUserId = (
  userType: LOCALE_USER_TYPE,
  userId: number,
  userEmail: string,
  isRingleMember: boolean,
  isAdminLogin: boolean
): void => {
  if (typeof window === 'undefined') return

  // Prod Mode + (Ringle Member OR Admin Login) => RETURN & Remove Script
  if (!IS_DEVELOPMENT && (isRingleMember || isAdminLogin)) {
    if (isGTMLoaded()) {
      removeGTMScript()
    }
  } else {
    // If GTM is not loaded, initialize with user id
    // -> If user with token refreshed within portal page
    if (!isGTMLoaded()) {
      removeGTMScript()

      console.log(`Initializing Google Tag Manager - ${userType} - SET GTM USER ID`)
      TagManager.initialize({
        gtmId: TAG_MANAGER_ID,
      })
    } else {
      // If GTM is already loaded, push user ID to dataLayer
      const userIdDataLayerList: IGtmUserIdDataLayer[] = getDataLayerListByKey('user_id')
      const hasCurrentUserIdLayer = userIdDataLayerList.map((dl) => dl.user_id).includes(userId)

      // If dataLayer already contains user id, return the function
      if (userIdDataLayerList.length > 0 && hasCurrentUserIdLayer) {
        return
      }
    }

    // If dataLayer does not contain layer with user id, push user id data layer
    sendGTMEvent(GTM_EVENT.SET_USER_ID, {
      user_id: userId,
      user_email: userEmail,
    })

    // If it's Ringle Member, set is_ringle_member property
    sendGTMEvent(GTM_EVENT.SET_IS_RINGLE_MEMBER, {
      is_ringle_member: isRingleMember,
    })
  }
}

/* * ---------------- */
/* *  COMMON HELPERS  */
/* * ---------------- */

/**
 * Send GTM Event Function
 * @param eventName GTM Event Name
 * @param eventPayload GTM Event Payload
 */

export const sendGTMEvent = (eventName: string, eventPayload?: Record<string, any>): void => {
  console.log(`SEND GTM EVENT: ${eventName}`, eventPayload)
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventPayload,
    },
  })
}

/**
 * Send Click Button Event to GTM w/ id
 * @param btnId clicked button id
 * @param pageLocation page location without query params(mandatory)
 * @param otherPayload page location without query params(optional)
 */

export const sendGTMBasicClickEvent = (btnId: string, otherPayload?: Record<string, any>): void => {
  console.log(`SEND GTM BASIC CLICK-EVENT: ${btnId}`, otherPayload)
  TagManager.dataLayer({
    dataLayer: {
      event: GTM_CLICK_EVENT.CLICK_BUTTON,
      button_id: btnId,
      ...otherPayload,
    },
  })
}

export const sendGTMCustomClickEvent = (
  event_id: GTM_CLICK_EVENT,
  button_id: string,
  btn_name: string,
  usertype: string,
  page_title: string | null,
  routerPath: string,
  otherPayload?: Record<string, any>
): void => {
  console.log('Sending GTM Custom Click Event...', {
    event_id,
    button_id,
    btn_name,
    usertype,
    page_title,
    routerPath,
    otherPayload,
  })

  const landingState: LandingState = routerPath.includes('/portal') ? LandingState.PORTAL : LandingState.LANDING

  TagManager.dataLayer({
    dataLayer: {
      event: event_id,
      button_id,
      btn_name,
      usertype,
      page_title: page_title ?? routerPath,
      landing_state: landingState,
      ...otherPayload,
    },
  })
}

export const sendGTMBasicPageViewEvent = (
  page_id: string,
  usertype: string,
  locale: LocaleType,
  page_title: string,
  routerPath: string,
  otherPayload?: Record<string, any>
): void => {
  if (!isGTMLoaded()) {
    console.log(`Initializing Google Tag Manager - SEND BASIC PV`)
    TagManager.initialize({
      gtmId: TAG_MANAGER_ID,
    })
  }

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_PV_EVENT.PV_BASIC,
      page_id,
      usertype,
      locale,
      page_title,
      ...otherPayload,
    },
  })
}

export const getEventUserType = (currentUser?: CurrentUser | null) => {
  return !currentUser ? '신규방문자' : firstUser(currentUser) ? '첫결제자' : '기결제자'
}

/**
 * Get Location Type
 * @param path Router Path
 * @returns Location Type
 */
export const getLocationType = (path: string): LOCATION_TYPE | void => {
  if (path.includes(LOCATION_TYPE.LANDING)) {
    return LOCATION_TYPE.LANDING
  }

  if (path.includes(LOCATION_TYPE.PORTAL)) {
    return LOCATION_TYPE.PORTAL
  }
}

/* * ---------------- */
/* *  COMMON HELPERS  */
/* * ---------------- */
export const getCleanUrl = (pathname: string): string => pathname.replace('/[lang]/', '/')
