import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

interface StepContextType {
  step: number
  setStep: Dispatch<SetStateAction<number>>
}

const StepContext = createContext<StepContextType>({
  step: 1,
  setStep: () => {}, // placeholder function
})

export const StepProvider = ({ children }) => {
  const [step, setStep] = useState(1) // 초기 step 상태 설정
  return <StepContext.Provider value={{ step, setStep }}>{children}</StepContext.Provider>
}

export const useStep = () => useContext(StepContext)
