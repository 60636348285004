import { anchorClassName, descriptionClassName } from '../style'
import SectionBgColor from './BuilderButton/SectionBgColor'
import SectionButtonExample from './BuilderButton/SectionButtonExample'
import SectionIconButton from './BuilderButton/SectionIconButton'
import SectionSizes from './BuilderButton/SectionSizes'
import SectionStates from './BuilderButton/SectionStates'
import SectionTextButton from './BuilderButton/SectionTextButton'
import SectionTypes from './BuilderButton/SectionTypes'

const SectionDescription = () => {
  return (
    <section>
      <div className={descriptionClassName}>
        기존의 Button의 type은 solid와 outline으로밖에 구분이 되지 않아서 type에 outline_gray를 추가하여 구현한
        버튼입니다.
        <br />
        기존의 outline 버튼은 outline_gray로 type이 변경되었습니다.
        <br />
        <br />
        Builder.io를 잘 활용하기 위해 만든 버튼이지만, 비교적 최신 디자인 시스템을 반영하고 있기 때문에 꼭 Builder.io가
        아니더라도 더 유용하게 사용할 수 있습니다.
        <br />
        (DSButon과는 다르게 Hover, Pressed 효과가 적용됨)
        <br />
        (그 외 각종 디자인 수정 사항 반영)
      </div>
    </section>
  )
}

const BuilderButtonPage = () => {
  // 버튼 클릭 핸들러
  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[8px]">
        <SectionDescription />
        <SectionButtonExample handleButtonClick={handleButtonClick} />
        <SectionTypes handleButtonClick={handleButtonClick} />
        <SectionStates handleButtonClick={handleButtonClick} />
        <SectionSizes handleButtonClick={handleButtonClick} />
        <SectionIconButton handleButtonClick={handleButtonClick} />
        <SectionTextButton handleButtonClick={handleButtonClick} />
        <SectionBgColor handleButtonClick={handleButtonClick} />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#BuilderButton" className={anchorClassName}>
          BuilderButton
        </a>
        <a href="#ExampleCode" className={anchorClassName}>
          Example Code
        </a>
        <a href="#Types" className={anchorClassName}>
          Types
        </a>
        <a href="#States" className={anchorClassName}>
          States
        </a>
        <a href="#Sizes" className={anchorClassName}>
          Sizes
        </a>
        <a href="#IconButton" className={anchorClassName}>
          IconButton
        </a>
        <a href="#TextButton" className={anchorClassName}>
          TextButton
        </a>
        <a href="#BgColor" className={anchorClassName}>
          BgColor
        </a>
      </div>
    </div>
  )
}

export default BuilderButtonPage
