import classNames from 'classnames'
import { useButtonState } from '../modules/ButtonStateContext'
import { BUTTON_SIZE, BUTTON_SIZE_KEY, BUTTON_TYPE, BUTTON_TYPE_KEY } from '../types'

const ButtonLoading = () => {
  const { size, type } = useButtonState()

  return (
    <div className={`flex h-full w-full items-center justify-center`}>
      <div
        className={classNames(
          `animate-[spin_1.5s_linear_infinite] rounded-[50%] border-[2px] border-transparent`,
          styles.color(type),
          styles.size(size)
        )}
      />
    </div>
  )
}

export default ButtonLoading

const styles = {
  color: (type: BUTTON_TYPE_KEY) =>
    classNames(
      type === BUTTON_TYPE.SOLID && `border-t-white border-r-white`,
      type === BUTTON_TYPE.OUTLINE && `border-t-gray-200 border-r-gray-200`
    ),
  size: (size: BUTTON_SIZE_KEY) =>
    classNames(
      size === BUTTON_SIZE.XS && `h-[16px] w-[16px]`,
      size === BUTTON_SIZE.SM && `h-[20px] w-[20px]`,
      size === BUTTON_SIZE.MD && `h-[24px] w-[24px]`,
      size === BUTTON_SIZE.LG && `h-[32px] w-[32px]`,
      size === BUTTON_SIZE.XL && `h-[32px] w-[32px]`
    ),
}
