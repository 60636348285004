// Legacy Checkbox -> 이 파일보다는 다음을 import 해서 사용하기를 권장
// import { Checkbox } from '@repo/design-system/index'

import { QIcon_checkbox_selected, QIcon_checkbox_unselected } from '@repo/icons/index'
import { Fragment, useState } from 'react'
import { removeArray } from '../../modules/utils'
import Div from '../basic/Div'

interface CheckBoxProps {
  checked?: boolean
  label: React.ReactNode | string
  gap?: number
  clx?: string
  disabled?: boolean
  teens?: boolean
  danger?: boolean
  info?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

interface CheckBoxGroupProps {
  data: { name: string; items: { label: string; value: string; checked: boolean }[] }
  gap: number
  onChange: (newArr: string[] | number[]) => void
}

const CheckBoxs = ({ checked, disabled, label, ...props }: CheckBoxProps) => {
  const { onClick, clx, gap, ...others } = props
  return (
    <>
      {!disabled ? (
        <Div cursorPointer={!disabled} flex itemsCenter textGray600 mb={gap} onClick={onClick} clx={clx} {...others}>
          <div className="mr-[16px]">
            {checked ? (
              <QIcon_checkbox_selected className="h-24 w-24" />
            ) : (
              <QIcon_checkbox_unselected className="h-24 w-24" />
            )}
          </div>
          <h5 className="text-h5 text-left text-gray-700">{label}</h5>
        </Div>
      ) : (
        <Div cursorPointer={!disabled} flex itemsCenter textGray600 mb={gap} onClick={onClick} clx={clx} {...others}>
          <div className="mr-[16px]">
            {checked ? (
              <QIcon_checkbox_selected className="h-24 w-24" />
            ) : (
              <QIcon_checkbox_unselected className="h-24 w-24" />
            )}
          </div>
          <h5 className="text-h5 text-left text-gray-500">{label}</h5>
        </Div>
      )}
    </>
  )
}

const CheckBoxGroups = ({ data, onChange, gap, ...others }: CheckBoxGroupProps) => {
  const { name, items } = data

  const initSelected = items
    .filter((item, i) => item.checked)
    .map((item, i) => {
      return item.value
    })

  const [selectedArray, setSelectedArray] = useState(initSelected)

  const onChangeData = (e, id) => {
    e.preventDefault()
    let newArr = selectedArray
    const item = selectedArray.find((item, i) => item === id)
    if (item) {
      newArr = removeArray(selectedArray, item)
    } else {
      newArr.push(id)
    }
    setSelectedArray(newArr)
    onChange(newArr)
  }
  return (
    <>
      {items.map((item, i) => (
        <Fragment key={`${name}-${i}`}>
          <CheckBoxs
            checked={selectedArray.includes(item.value)}
            label={item.label}
            gap={gap}
            onClick={(e) => onChangeData(e, item.value)}
            {...others}
          />
        </Fragment>
      ))}
    </>
  )
}

export { CheckBoxGroups, CheckBoxs }
