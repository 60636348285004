import classNames from 'classnames'
import { anchorClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../style'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const LayoutGrid = () => {
  return (
    <div>
      <p id="Layout_Grid" className={headerClassName}>
        Layout Grid
      </p>
      <p className={descriptionClassName}>
        다양한 화면 내에서 일관성을 유지할 수 있도록 그리드 규칙을 정의합니다.
        <br />
        8px 그리드를 활용한 컬럼 그리드 규칙을 가지고, 상세 디자인 시에는 4px Spacing 규칙을 가집니다.
      </p>

      <p className={classNames(subHeaderClassName, 'mt-[36px]')}>[PC] Contents Grid</p>
      <p className={descriptionClassName}>
        PC 환경에서 적절한 넓이감과 가독성을 위해 콘텐츠 그리드의 max-width를 1040px로 지정합니다.
      </p>
      <div className="mt-[16px] h-[512px] max-w-[1040px] bg-gray-200" />

      <p className={classNames(subHeaderClassName, 'mt-[36px]')}>[PC] Landing Grid</p>
      <p className={descriptionClassName}>
        PC 환경의 랜딩 화면에서 다양한 엘리먼트를 사용할 수 있도록 자율도를 가지기 위해 max-width를 1140px로 지정합니다.
      </p>
      <div className="mt-[16px] h-[512px] max-w-[1140px] bg-gray-200" />
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Spacing = () => {
  return (
    <div>
      <p id="Spacing" className={headerClassName}>
        Spacing
      </p>
      <p className={descriptionClassName}>전체적으로 4px Spacing 규칙을 권장합니다.</p>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const TextStyle = () => {
  return (
    <div>
      <p id="Text_Style" className={headerClassName}>
        Text Style
      </p>
      <p className={descriptionClassName}>Pretendard를 사용합니다.</p>
      <br />
      <a
        href="https://cactus.tistory.com/306"
        target="_blank"
        className={classNames(descriptionClassName, 'underline')}
        rel="noreferrer"
      >
        프리텐다드 다운로드 링크
      </a>
      <br />
      <br />
      <p className={descriptionClassName}>
        프로덕트 디자인/개발 시 원활한 다국어 사용을 위해 Pretendard JP Variable를 사용합니다.
        <br />
        다국어를 쓰지 않는 곳에선 Pretendard Variable를 사용하셔도 무방합니다.
      </p>
    </div>
  )
}

const Foundation = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[128px]">
        <LayoutGrid />
        <Spacing />
        <TextStyle />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Layout_Grid" className={anchorClassName}>
          Layout Grid
        </a>
        <a href="#Spacing" className={anchorClassName}>
          Spacing
        </a>
        <a href="#Text_Style" className={anchorClassName}>
          Text Style
        </a>
      </div>
    </div>
  )
}

export default Foundation
