import { useRef, useState } from 'react'

interface DSBottomSheetUseTouchEventProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  closeOnDrag?: boolean
  isWebview?: boolean
}

type SCROLL_TYPE = 'none' | 'top' | 'middle' | 'bottom'

const VELOCITY_THRESHOLD = 200 // 100px in 0.5s
const REMAINING_HEIGHT_RATIO = 0.4 // 40% of initial bottomsheet height
declare global {
  interface Window {
    ReactNativeWebView?: any
    clipboardData: any
    copyToClipboardFF: any
    Kakao: any
    fbAsyncInit: any
    FB: any
    IMP: any
    TossPayments: any
  }
}

interface TouchInfo {
  timeStamp: number
  clientY: number
}

const getScrollType = (parentNode: HTMLElement): SCROLL_TYPE => {
  const { scrollHeight, scrollTop, clientHeight } = parentNode

  switch (true) {
    case scrollHeight === clientHeight:
      return 'none'
    case scrollTop === 0:
      return 'top'
    case scrollHeight - scrollTop === clientHeight:
      return 'bottom'
    default:
      return 'middle'
  }
}

const getTranslateY = ({ scrollType, deltaClientY }: { scrollType: SCROLL_TYPE; deltaClientY: number }) => {
  switch (scrollType) {
    case 'none':
      return deltaClientY >= 0 ? deltaClientY : Math.max(-16, deltaClientY * 0.01)
    case 'top':
      return deltaClientY > 0 ? deltaClientY : 0
    case 'bottom':
      return deltaClientY < 0 ? Math.max(-16, deltaClientY * 0.01) : 0
    default:
      return 0
  }
}

const getShouldClose = ({
  scrollType,
  deltaTime,
  deltaClientY,
  containerHeight,
}: {
  scrollType: SCROLL_TYPE
  deltaTime: number
  deltaClientY: number
  containerHeight: number
}) => {
  if (!(scrollType === 'top' || scrollType === 'none')) return

  const velocity = deltaClientY / (deltaTime / 1000)
  if (velocity >= VELOCITY_THRESHOLD) return true

  const remainingHeightRatio = 1 - deltaClientY / containerHeight
  if (remainingHeightRatio <= REMAINING_HEIGHT_RATIO) return true

  return false
}

export const useDSBottomSheetTouchEvent = (props: DSBottomSheetUseTouchEventProps) => {
  const { setIsOpen, closeOnDrag, isWebview } = props

  const [translateY, setTranslateY] = useState<number>(0)
  const scrollTypeRef = useRef<SCROLL_TYPE>(null)
  const initialTouchInfoRef = useRef<TouchInfo>(null)

  if (!closeOnDrag)
    return {
      translateY: undefined,
      setTranslateY: undefined,
      onTouchStart: undefined,
      onTouchMove: undefined,
      onTouchEnd: undefined,
    }

  // 스크롤 타입과 처음 터치 위치 저장
  const onTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement
    scrollTypeRef.current = getScrollType(target.parentNode as HTMLElement)
    if (scrollTypeRef.current === 'middle') return

    initialTouchInfoRef.current = { timeStamp: e.timeStamp, clientY: e.changedTouches[0].clientY }
  }

  // 바텀 시트 translateY 조절
  const onTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (scrollTypeRef.current === 'middle') return

    const clientY = e.changedTouches[0].clientY
    const deltaClientY = clientY - initialTouchInfoRef.current.clientY
    setTranslateY(getTranslateY({ scrollType: scrollTypeRef.current, deltaClientY }))
  }

  // 닫거나 초기화 하는 조건 만족하면 수행
  const onTouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (scrollTypeRef.current === 'middle') return

    const clientY = e.changedTouches[0].clientY
    const deltaClientY = clientY - initialTouchInfoRef.current.clientY
    const shouldClose = getShouldClose({
      scrollType: scrollTypeRef.current,
      deltaTime: e.timeStamp - initialTouchInfoRef.current.timeStamp,
      deltaClientY: deltaClientY,
      containerHeight: window.innerHeight / 2,
    })

    if (shouldClose) {
      setIsOpen(false)
      if (isWebview && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'SHOW_TAB_BAR' }))
      }
    } else {
      setTranslateY(0)
    }
  }

  return { translateY, setTranslateY, onTouchStart, onTouchMove, onTouchEnd }
}
