// legacy 툴팁 -> 이 파일보다는 DSTooltip 사용 권장

import classNames from 'classnames'
import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { ICON_QUESTION_GRAY } from '../../modules/svg/svg'

// react-tooltip 스타일 정의 (20230202 기준)
// 아래의 속성들을 override 하려면 !classname 사용!

// .react-tooltip {
//   max-width: 320px !important;
//   z-index: 999 !important;

//   &.pointer-events-auto {
//     pointer-events: auto !important;
//     border: 1px solid #edf0f5 !important;
//   }
// }

interface ITooltipNewProps {
  // id must start with alphabet character, and must followed by [-, _, letter, number]
  // id must not be 'tooptip' since other extensions or packages can use same id.
  id: string
  place?: 'top' | 'right' | 'bottom' | 'left'
  event?: 'click' | 'hover'
  clickable?: boolean
  defaultButton?: boolean // use default button?
  tooltipBgClx?: string // Tooltip bg classname
  tooltipClx?: string // Tooltip classname
  positionStrategy?: 'absolute' | 'fixed' // set to fixed if you run into issues with "overflow: hidden" on the tooltip parent container
  afterShow?: () => void // a function to be called after the tooltip is shown
  html?: string // html content to de displayed in tooltip, html 태그 포함한 경우 children 대신 사용
  isOpen?: boolean // handle show and hide tooltip outside tooltip
  children?: React.ReactNode // Tooltip content
}

/**
 * 버튼으로 사용할 외부 컴포넌트의 id와, <Tooltip> 컴포넌트의 id를 동일하게 설정
 *
 * 툴팁 내용은 <Tooltip> 컴포넌트의 children으로 설정
 */
const TooltipNew = (props: ITooltipNewProps) => {
  const {
    id,
    place = 'bottom',
    event = 'click',
    clickable = false,
    defaultButton = false,
    tooltipBgClx = 'bg-purple-500',
    tooltipClx,
    positionStrategy = 'absolute',
    afterShow,
    html,
    isOpen = undefined,
    children: tooltipContent,
  } = props
  return (
    <>
      {/* Tooltip button (if defaultButton) */}
      {defaultButton && <img id={id} className="cursor-pointer" src={ICON_QUESTION_GRAY} />}
      {/* Tooltip content */}
      <ReactTooltip
        anchorSelect={'#' + id}
        place={place}
        openOnClick={event === 'click'}
        clickable={clickable}
        className={classNames('react-tooltip', tooltipBgClx, tooltipClx)}
        positionStrategy={positionStrategy}
        afterShow={afterShow}
        isOpen={isOpen}
      >
        {html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : tooltipContent}
      </ReactTooltip>
    </>
  )
}

export default TooltipNew
