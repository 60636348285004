// @ts-strict-ignore
import { SERVER_URL } from '../modules/apis'

const urls = {
  student: {
    landing: {
      home: `/student/landing/home`,
    },
    auth: {
      signIn: `/student/auth/sign-in`,
      new: `/student/auth/new`, // 회원가입 랜딩 페이지.
    },
    policy: {
      terms: `/student/policy/terms`,
      all: `/student/policy/all`,
    },
    portal: {
      home: `/student/portal/home`,
      welcome: `/student/portal/welcome`,
      lessons: {
        preparation: {
          href: `/student/portal/lessons/preparation/[lessonId]`,
          as: (lessonId) => `/student/portal/lessons/preparation/${lessonId}`,
        },
      },
    },
  },
  tutor: {
    policy: {
      terms: `/tutor/policy/terms`,
      privacy: `/tutor/policy/privacy`,
    },
    auth: {
      basicInformation: `/tutor/auth/basic-information`,
      passwordReset: `/tutor/auth/password-reset`,
      passwordNew: `/tutor/auth/password-new`,
      passwordTempChange: `/tutor/auth/password-temp`,
      signIn: `/tutor/auth/sign-in`,
      signUp: `/tutor/auth/sign-up`,
      verfyEmail: `/tutor/auth/verify-email`,
    },
    landing: {
      home: `/tutor/landing/home`,
      policy: `/tutor/landing/policy`,
      about: `/tutor/landing/about-us`,
      dormant: `/tutor/landing/dormant`,
      strike: `/tutor/landing/strike`,
      earningsSummary: `/tutor/landing/earnings-summary`,
      holding: `/tutor/landing/holding`,
      blog: {
        index: `/tutor/landing/blog`,
        view: {
          href: `/tutor/landing/blog/detail/[blogId]`,
          as: (blogId) => `/tutor/landing/blog/detail/${blogId}`,
        },
      },
      help: {
        index: `/tutor/landing/help`,
        detail: {
          href: `/tutor/landing/help/detail/[help]`,
          as: (help) => `/tutor/landing/help/detail/${help}`,
        },
        result: {
          href: `/tutor/landing/help/result/[query]`,
          as: (query) => `/tutor/landing/help/result/${query}`,
        },
      },
    },
    portal: {
      help: {
        index: `/tutor/portal/help`,
        detail: {
          href: `/tutor/portal/help/detail/[help]`,
          as: (help) => `/tutor/portal/help/detail/${help}`,
        },
        result: {
          href: `/tutor/portal/help/result/[query]`,
          as: (query) => `/tutor/portal/help/result/${query}`,
        },
      },

      event: {
        officeOpenEvent: '/tutor/portal/event/office-event',
        officeOpenEventTracking: '/tutor/portal/event/office-event-tracking',
      },
      orientation: {
        step1: {
          index: `/tutor/portal/orientation/step-1`,
        },
        video: {
          index: `/tutor/portal/orientation/video`,
        },
        quiz: {
          index: `/tutor/portal/orientation/quiz`,
          quizResultPass: `/tutor/portal/orientation/quiz-result-pass`,
          quizResultFail: `/tutor/portal/orientation/quiz-result-fail`,
        },
        fail: `/tutor/portal/orientation/failed`,
      },
      seoulChallenge: {
        index: '/tutor/portal/seoul-challenge',
      },
      orientationTeens: {
        step1: {
          index: `/tutor/portal/orientation-teens/step-1`,
        },
        video: {
          index: `/tutor/portal/orientation-teens/video`,
        },
        quiz: {
          index: `/tutor/portal/orientation-teens/quiz`,
          quizResultPass: `/tutor/portal/orientation-teens/quiz-result-pass`,
          quizResultFail: `/tutor/portal/orientation-teens/quiz-result-fail`,
        },
        fail: `/tutor/portal/orientation-teens/failed`,
      },
      application: {
        ready: `/tutor/portal/application`,
        step1: {
          index: `/tutor/portal/application/step-1`,
          video: `/tutor/portal/application/step-1/video`,
          pass: `/tutor/portal/application/step-1/passed`,
          fail: `/tutor/portal/application/step-1/failed`,
          pending: `/tutor/portal/application/step-1/pending`,
          quiz: `/tutor/portal/application/step-1/quiz`,
        },
        step2: {
          index: `/tutor/portal/application/step-2`,
        },
        step3: {
          index: `/tutor/portal/application/step-3`,
          pending: `/tutor/portal/application/step-3/pending`,
          fail: `/tutor/portal/application/step-3/failed?1`,
          quiz: `/tutor/portal/application/step-3/quiz?1`,
          whatIsMockSession: `/tutor/portal/application/step-3/whatIsMockSession`,
          sampleMockSession: `/tutor/portal/application/step-3/sampleMockSession`,
        },
        profile: {
          index: `/tutor/portal/application/profile`,
        },
        policy: {
          index: `/tutor/portal/application/policy`,
        },
      },
      applicationTeens: {
        profile: {
          index: `/tutor/portal/application-teens/profile`,
        },
        policy: {
          index: `/tutor/portal/application-teens/policy`,
        },
      },
      resources: {
        tutoringResources: {
          index: `/tutor/portal/resources/tutoring-resources`,
          href: `/tutor/portal/resources/tutoring-resources/[contentsId]`,
          as: (contentsId) => `/tutor/portal/resources/tutoring-resources/${contentsId}`,
          result: {
            href: `/tutor/portal/resources/tutoring-resources/result/[categoryId]`,
            as: (categoryId) => `/tutor/portal/resources/tutoring-resources/result/${categoryId}`,
          },
        },
        tutorialsWebinars: {
          index: `/tutor/portal/resources/tutorials-webinars`,
          href: `/tutor/portal/resources/tutorials-webinars/[contentsId]`,
          as: (contentsId) => `/tutor/portal/resources/tutorials-webinars/${contentsId}`,
          result: {
            href: `/tutor/portal/resources/tutorials-webinars/result/[categoryId]`,
            as: (categoryId) => `/tutor/portal/resources/tutorials-webinars/result/${categoryId}`,
          },
        },
        lessonMaterials: {
          index: `/tutor/portal/lessons/lesson-materials`,
          href: `/tutor/portal/lessons/lesson-materials/[materialId]`,
          as: (materialId) => `/tutor/portal/lessons/lesson-materials/${materialId}`,
          list: {
            href: `/tutor/portal/lessons/lesson-materials/list/[categoryId]`,
            as: (categoryId) => `/tutor/portal/lessons/lesson-materials/list/${categoryId}`,
          },
        },
        writeResources: `/${SERVER_URL}/resource/new`,
        view: {
          href: `/tutor/portal/resources/[id]`,
          as: (id) => `/tutor/portal/resources/${id}`,
        },
      },
      notice: {
        index: `/tutor/portal/notice`,
        view: {
          href: `/tutor/portal/notice/[id]`,
          as: (id) => `/tutor/portal/notice/${id}`,
        },
      },
      mypage: {
        basic: '/tutor/portal/mypage/basic',
        timezone: '/tutor/portal/mypage/timezone',
        password: '/tutor/portal/mypage/password',
        profile: {
          photo: '/tutor/portal/mypage/profile/photo-video',
          introduction: '/tutor/portal/mypage/profile/introduction',
          preference: '/tutor/portal/mypage/profile/preference',
        },
        teensProfile: {
          photo: '/tutor/portal/mypage/teens-profile/photo-video',
          introduction: '/tutor/portal/mypage/teens-profile/introduction',
          preference: '/tutor/portal/mypage/teens-profile/preference',
        },
        noti: '/tutor/portal/mypage/notification',
        payout: '/tutor/portal/mypage/payout',
        taxpayerInform: '/tutor/portal/mypage/taxpayer-information',
      },
      policy: '/tutor/portal/policy',
      teensPolicy: '/tutor/portal/teens-policy',
      home: '/tutor/portal/home',
      thxLetter: '/tutor/portal/home/thanksLetter',
      referral: {
        index: `/tutor/portal/referral`,
      },
      blog: {
        index: `/tutor/portal/blog`,
        view: {
          href: `/tutor/portal/blog/[blogId]`,
          as: (blogId) => `/tutor/portal/blog/${blogId}`,
        },
      },

      lessons: {
        preparation: {
          href: `/tutor/portal/lessons/preparation/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/preparation/${lessonId}`,
          asTodo: (lessonId, activeTab) => `/tutor/portal/lessons/preparation/${lessonId}?type=${activeTab}`,
        },
        review: {
          href: `/tutor/portal/lessons/review/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/review/${lessonId}`,
        },
        classroom: {
          href: `/tutor/portal/lessons/classroom/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/classroom/${lessonId}`,
        },
        upcomingLessons: `/tutor/portal/lessons/upcoming-lessons`,
        pastLessons: `/tutor/portal/lessons/past-lessons`,
        lessonMaterials: {
          index: `/tutor/portal/lessons/lesson-materials`,
          href: `/tutor/portal/lessons/lesson-materials/[contentsId]`,
          as: (contentsId) => `/tutor/portal/lessons/lesson-materials/${contentsId}`,
        },
        agreement: (lessonId, userId) => `${SERVER_URL}/tutor/agreement/${lessonId}?user_id=${userId}`,
        lastMinuteAgreement: (startTime, userId) =>
          `${SERVER_URL}/tutor/last_minute_agreement?start_time=${startTime}&user_id=${userId}`,
        feedbackNew: {
          href: `/tutor/portal/lessons/feedback-new/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/feedback-new/${lessonId}`,
        },
        docs: {
          href: `/tutor/portal/lessons/docs/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/docs/${lessonId}`,
        },
        feedback: {
          href: `/tutor/portal/lessons/feedback/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/feedback/${lessonId}`,
        },
        feedbackReport: {
          // 3.0 이전 피드백 다시보기, Feedback completed 클릭 시 이동
          href: `/tutor/portal/lessons/feedback-report/[lessonId]`,
          as: (lessonId) => `/tutor/portal/lessons/feedback-report/${lessonId}`,
          asTeensId: (lessonId) => `/tutor/portal/lessons/feedback-report/teens/${lessonId}`,
          asPlusId: (lessonId) => `/tutor/portal/lessons/feedback-report/plus/${lessonId}`,
        },
        ringleDocTutorial: {
          href: `/tutor/portal/lessons/ringledoc-tutorial`,
        },
      },
      schedule: {
        index: `/tutor/portal/schedule`,
        as: (activeTab) => `/tutor/portal/schedule?type=${activeTab}`,
      },
      video: {
        upload: `${SERVER_URL}/intro_video/upload`,
      },
      performance: {
        status: `/tutor/portal/performance/status`,
        log: `/tutor/portal/performance/log`,
        overview: `/tutor/portal/performance/overview`,
        promotionTeens: `/tutor/portal/performance/promotion-teens`,
        link1: `/tutor/portal/policy`,
        link2: `/tutor/portal/resources/tutorials-webinars/list/5503476021519`,
      },
      promotion: {
        status: `/tutor/portal/promotion/status`,
        log: `/tutor/portal/promotion/log`,
        seeMore: `/tutor/portal/promotion/see-more`,
        level: `/tutor/portal/promotion/level`,
      },
      promotionTeens: {
        status: `/tutor/portal/promotion-teens/status`,
        log: `/tutor/portal/promotion-teens/log`,
        seeMore: `/tutor/portal/promotion-teens/see-more`,
        level: `/tutor/portal/promotion-teens/level`,
      },
      payment: {
        upcomingPay: `/tutor/portal/payment/upcoming-pay`,
        overview: `/tutor/portal/payment/overview`,
        payout: `/tutor/portal/payment/payout`,
        log: {
          index: `/tutor/portal/payment/log`,
          networkHistory: {
            detail: (lessonId: string) => `/tutor/portal/payment/log/network-history/${lessonId}`,
          },
        },
        transactionHistory: `/tutor/portal/payment/transaction-history`,
        earningsSummary: `/tutor/portal/payment/earnings-summary`,
      },
      writing: {
        completed: `/tutor/portal/writing/completed`,
        requested: `/tutor/portal/writing/requested`,
        detail: (writingId: number) => `/tutor/portal/writing/detail/${writingId}`,
      },
    },
  },
  admin: {
    home: `/admin`,
    feedbackReport: {
      href: `/admin/feedback-report/[lessonId]`,
      as: (lessonId) => `/admin/feedback-report/${lessonId}`,
    },
  },
}
export default urls
