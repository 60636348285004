import { createContext, useContext } from 'react'
import { DSMODAL_SIZE_TYPE } from '../ui/DSModalMain'

interface DSModalContextProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  size: DSMODAL_SIZE_TYPE
  closeButton: boolean
}

export const DSModalContext = createContext<DSModalContextProps>({
  setIsOpen: null,
  size: null,
  closeButton: null,
})

export const useDSModalState = () => {
  const context = useContext(DSModalContext)

  if (!context) throw new Error('Not in DSModalContext.')

  return context
}
