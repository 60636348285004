import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'
import { COLORS } from '../../../modules/vars'
import { ChipContext } from '../modules/ChipStateContext'
import { CHIP_SIZE, CHIP_TYPE } from '../types'

export interface ChipMainProps extends PropsWithChildren {
  type: CHIP_TYPE.ACTIONABLE | CHIP_TYPE.CHOICE | CHIP_TYPE.FILTER
  size: CHIP_SIZE.XS | CHIP_SIZE.SM | CHIP_SIZE.MD | CHIP_SIZE.LG
  onClick?: () => void
  disabled?: boolean
  clickable?: boolean
  className?: string
  customBgColor?: (typeof COLORS)[keyof typeof COLORS]
  initialSelected?: boolean
  id?: number
  chipSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>]
}

/**
 * Chip의 width는 외부에서 조절합니다. (because of w-full)
 * @param param0
 * @returns
 */
const ChipMain = ({
  type, //Required
  size, //Required
  children,
  onClick = undefined,
  disabled = false,
  clickable = true,
  className = '',
  customBgColor = undefined,
  initialSelected = false, // for CHOICE, FILTER type
  id = 0, // for FILTER type
  chipSelected = undefined, // for FILTER type
}: ChipMainProps) => {
  const [selected, setSelected] = useState<boolean>(initialSelected)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick()
    }
    if (!clickable) return
    if (disabled) return

    if (type !== CHIP_TYPE.ACTIONABLE) {
      setSelected((prev) => !prev)
    }
  }

  useEffect(() => {
    if (type === CHIP_TYPE.FILTER && id && chipSelected) {
      // 복수선택(filter)
      const [selectedIdList, setSelectedIdList] = chipSelected
      if (selected) {
        setSelectedIdList((prevState) => [...prevState, id])
      } else {
        setSelectedIdList(selectedIdList.filter((selected) => selected !== id))
      }
    }
  }, [selected])

  useEffect(() => {
    setSelected(initialSelected)
  }, [initialSelected])

  return (
    <ChipContext.Provider value={{ size, type, selected, disabled }}>
      <div>
        <button
          className={classNames(
            className,
            styles.button(type, size, selected, disabled),
            'font-preten flex items-center justify-center'
          )}
          style={customBgColor ? { backgroundColor: customBgColor } : {}}
          onClick={(e) => handleClick(e)}
        >
          {children}
        </button>
      </div>
    </ChipContext.Provider>
  )
}
export default ChipMain

const styles = {
  // border가 있는 경우 border 두께만큼 padding 감소
  button: (type: CHIP_TYPE, size: CHIP_SIZE, selected: boolean, disabled: boolean): string => {
    return classNames(
      type === CHIP_TYPE.ACTIONABLE && 'bg-purple-600',
      type === CHIP_TYPE.ACTIONABLE && size === CHIP_SIZE.XS && `rounded-[4px] py-[2px] px-[6px]`,
      type === CHIP_TYPE.ACTIONABLE && size === CHIP_SIZE.SM && `rounded-[4px] py-[2px] px-[6px]`,
      type === CHIP_TYPE.ACTIONABLE && size === CHIP_SIZE.MD && `rounded-[4px] py-[4px] px-[10px]`,
      type === CHIP_TYPE.ACTIONABLE && size === CHIP_SIZE.LG && `rounded-[8px] py-[6px] px-[12px]`,
      type === CHIP_TYPE.CHOICE && size === CHIP_SIZE.SM && `rounded-[8px] py-[6.5px] px-[10.5px]`,
      type === CHIP_TYPE.CHOICE && size === CHIP_SIZE.MD && `rounded-[8px] py-[6.5px] px-[10.5px]`,
      type === CHIP_TYPE.CHOICE && size === CHIP_SIZE.LG && `rounded-[8px] py-[6.5px] px-[14.5px]`,
      type === CHIP_TYPE.CHOICE && !selected && `border-[1.5px] border-gray-300 bg-white`,
      type === CHIP_TYPE.CHOICE && selected && `border-[1.5px] border-purple-500 bg-purple-50`,
      type === CHIP_TYPE.CHOICE && disabled && `border-[1.5px] border-gray-200`,
      type === CHIP_TYPE.FILTER && size === CHIP_SIZE.SM && `rounded-[8px] p-[6.5px] pl-[8.5px]`,
      type === CHIP_TYPE.FILTER && size === CHIP_SIZE.MD && `rounded-[8px] p-[6.5px] pl-[8.5px]`,
      type === CHIP_TYPE.FILTER && size === CHIP_SIZE.LG && `rounded-[8px] py-[6.5px] pr-[8.5px] pl-[10.5px]`,
      type === CHIP_TYPE.FILTER && !selected && `border-[1.5px] border-gray-300 bg-white`,
      type === CHIP_TYPE.FILTER && selected && `border-[1.5px] border-purple-500 bg-purple-50`
    )
  },
}
