import { useState } from 'react'
import { BuilderButton } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_SIZE_KEY, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { COLORS } from '../../modules/vars'
import { descriptionClassName, headerClassName } from '../../style'

const sizeList: BUILDER_BUTTON_SIZE_KEY[] = ['xs', 'sm', 'md', 'lg', 'xl']

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionBgColor = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  const [customBgColor, setCustomBgColor] = useState(COLORS.GREEN700)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomBgColor(e.target.value)
  }

  return (
    <section id="BgColor">
      <div className={headerClassName}>Background Color</div>

      <div className={descriptionClassName}>
        {/* <div> */}
        <label htmlFor="customBgColor">배경색 코드를 입력해주세요 (bg- 로 시작되는 토큰값에 한해 적용됩니다): </label>
        <input id="bgColor" type="text" className="border" value={customBgColor} onChange={handleInputChange} />
        {/* </div> */}
      </div>
      <div className="flex flex-col items-start gap-[10px]">
        {sizeList.map((size: BUILDER_BUTTON_SIZE[keyof BUILDER_BUTTON_SIZE]) => (
          <div key={size as string}>
            <BuilderButton
              size={size}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={customBgColor}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>{size as string}</BuilderButton.Label>
            </BuilderButton>
          </div>
        ))}
      </div>
    </section>
  )
}

export default SectionBgColor
