import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { DSInput, Switch } from '../../..'
import CodeSnippet from '../../CodeSnippet'
import { InputSize, InputType } from '../../Components/Input/types'
import DSRadio from '../../Components/Radio/DSRadio'
import { emailRegExpServer, noWhitespaceRegExp } from '../../modules/auth'
import {
  ControlClassName as OriginalControlClassName,
  descriptionClassName,
  headerClassName,
  rowContainerClassName,
  subHeaderClassName,
} from '../../style'

const ControlClassName = classNames(OriginalControlClassName, 'min-w-[210px]')

const SectionInputExample = () => {
  const [type, setType] = useState<InputType>('text')
  const [size, setSize] = useState<InputSize>('sm')
  const [value, setValue] = useState<string>('')
  const [upperText, setUpperText] = useState<string>('')
  const [required, setRequired] = useState<boolean>(false)
  const [bottomText, setBottomText] = useState<string>('')
  const [placeholder, setPlaceholder] = useState<string>('')
  const [clearable, setClearable] = useState<boolean>(false)
  const [regex, setRegex] = useState<'default' | 'emailFormat' | 'noWhiteSpace' | 'etc'>('default')
  const [autoFocus, setAutoFocus] = useState<boolean>(false)
  const [maxLength, setMaxLength] = useState<string>('')
  const [hasFunction, setHasFunction] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const regexObjectList = {
    emailFormat: {
      pattern: emailRegExpServer,
      message: '유효한 형태의 이메일을 입력해주세요.',
    },
    noWhiteSpace: {
      pattern: noWhitespaceRegExp,
      message: '공백 없이 입력해주세요.',
    },
    etc: {
      pattern: /^[a-zA-Z0-9]*$/,
      message: '영문 대소문자와 숫자로만 입력해주세요.',
    },
  }

  console.log('regexObject =====>', regexObjectList[regex])

  useEffect(() => {}, [autoFocus])

  return (
    <div>
      <section className="flex flex-col max-w-max border-[1.5px] rounded-[8px] p-[20px] bg-gray-50">
        <div className={subHeaderClassName}>Controls</div>

        <div className="flex flex-row">
          <div className={ControlClassName}>Type*</div>
          <DSRadio controlSelected={[type, setType]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="text">text</DSRadio.Item>
            <DSRadio.Item value="email">email</DSRadio.Item>
            <DSRadio.Item value="number">그 외 등등</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>size*</div>
          <DSRadio controlSelected={[size, setSize]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="sm">sm</DSRadio.Item>
            <DSRadio.Item value="md">md</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>upperText</div>
          <DSInput
            type="text"
            placeholder="Enter a description here"
            value={upperText}
            setValue={setUpperText}
            name={'upperText'}
            clearable
            size="sm"
            required
            className="bg-gray-50"
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, !upperText && 'text-gray-300')}>required (upperText 필요)</div>
          <Switch checked={required} setChecked={setRequired} disabled={!upperText} />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>bottomText</div>
          <DSInput
            type="text"
            placeholder="Enter a description here"
            value={bottomText}
            setValue={setBottomText}
            name={'bottomText'}
            clearable
            size="sm"
            required
            className="bg-gray-50"
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>placeholder</div>
          <DSInput
            type="text"
            placeholder="Enter a description here"
            value={placeholder}
            setValue={setPlaceholder}
            name={'placeholder'}
            clearable
            size="sm"
            required
            className="bg-gray-50"
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>clearable</div>
          <Switch checked={clearable} setChecked={setClearable} />
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>형태 검사 방법 (코드 확인)</div>
          <DSRadio controlSelected={[regex, setRegex]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="default">검사 X</DSRadio.Item>
            <DSRadio.Item value="emailFormat">이메일 형태 검사</DSRadio.Item>
            <DSRadio.Item value="noWhiteSpace">공백 검사</DSRadio.Item>
            <DSRadio.Item value="etc">그 외 등등</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>autoFocus (코드 확인)</div>
          <Switch checked={autoFocus} setChecked={setAutoFocus} />
          {autoFocus && (
            <div className={classNames(descriptionClassName, 'ml-[8px]')}>
              처음 화면 진입 시 input이 focused 되어있음
            </div>
          )}
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>최대 글자 수</div>
          <div className="flex max-w-[120px]">
            <DSInput
              type="number"
              placeholder="(e.g., 50)"
              value={maxLength}
              setValue={setMaxLength}
              name={'maxLength'}
              clearable
              size="sm"
              required
              className="bg-gray-50"
            />
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>onKeyDown 예시 (코드 확인)</div>
          <Switch checked={hasFunction} setChecked={setHasFunction} />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>disabled</div>
          <Switch checked={disabled} setChecked={setDisabled} />
        </div>
      </section>

      <section className="flex flex-row w-[700px] justify-center my-[40px]">
        <div>
          <DSInput
            type={type}
            size={size}
            value={value}
            setValue={setValue}
            name="example"
            upperText={upperText}
            required={required}
            bottomText={bottomText}
            placeholder={placeholder}
            clearable={clearable}
            regex={regex !== 'default' ? regexObjectList[regex] : undefined}
            autoFocus={autoFocus}
            textCounter={
              Number(maxLength) > 0
                ? {
                    maxLength: Number(maxLength),
                  }
                : undefined
            }
            onKeyDown={(e) => {
              if (hasFunction && e.key === 'Enter' && value != '') {
                alert(value)
              }
            }}
            // icon={<QIcon_star_filled width={20} height={20} />}
            disabled={disabled}
          />
        </div>
      </section>

      <div id="ExampleCode" className={headerClassName}>
        Example Code
      </div>

      <CodeSnippet
        code={`
import { DSInput } from "@repo/design-system/index"
import { useState } from "react"
  
const InputComponent = () => {
  const [value, setValue] = useState<string>('')

  return (
    <div>
      <DSInput
        type="${type}"
        size="${size}"
        value={value}
        setValue={setValue}
        name="example"${
          upperText
            ? `
        upperText="${upperText}"`
            : ''
        }${
          required
            ? `
        required`
            : ''
        }${
          bottomText
            ? `
        bottomText="${bottomText}"`
            : ''
        }${
          placeholder
            ? `
        placeholder="${placeholder}"`
            : ''
        }${
          clearable
            ? `
        clearable`
            : ''
        }${
          regex !== 'default'
            ? `
        regex={{
          pattern: ${regexObjectList[regex].pattern.toString()}, 
          message: "${regexObjectList[regex].message}",
        }}`
            : ''
        }${
          autoFocus
            ? `
        autoFocus`
            : ''
        }${
          Number(maxLength) > 0
            ? `
        textCounter={{ maxLength: ${Number(maxLength)} }}`
            : ''
        }${
          hasFunction
            ? `
        onKeyDown={(e) => {
          if (e.key === 'Enter' && value != '') {
            alert(value)
          }
        }}`
            : ''
        }${
          disabled
            ? `
        disabled`
            : ''
        }
      />
    </div>
  )
}
      `}
      />
    </div>
  )
}

export default SectionInputExample
