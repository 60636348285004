import classNames from 'classnames'
import { useState } from 'react'
import { Switch } from '../../index'
import CodeSnippet from '../CodeSnippet'
import { anchorClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../style'

const SectionExamples = () => {
  return (
    <div>
      <CodeSnippet
        code={`
import { Switch } from '@repo/design-system/index'
import { useState } from 'react'

const SwitchComponent = () => {
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(true)

  return (
    <>
      <Switch checked={checked1} setChecked={setChecked1}></Switch>
      <Switch checked={checked2} setChecked={setChecked2} disabled></Switch>
      <Switch checked={checked3} setChecked={setChecked3} disabled></Switch>
    </>
  )
}
    `}
      />
    </div>
  )
}

const SectionTypes = () => {
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(true)
  const [checked4, setChecked4] = useState(false)

  return (
    <div className="flex flex-col">
      <div className={subHeaderClassName}>States</div>
      <div className={descriptionClassName}>지정된 사이즈나 컬러 외의 사용을 금합니다.</div>

      <div className="flex flex-row gap-[30px] p-[20px]">
        <div className="flex flex-col items-center">
          <Switch checked={checked1} setChecked={setChecked1}></Switch>
          <div className={classNames(descriptionClassName, 'mt-10 font-bold')}>Enabled</div>
          <div className={descriptionClassName}>100%</div>
        </div>
        <div className="flex flex-col items-center">
          <Switch checked={checked2} setChecked={setChecked2}></Switch>
          <div className={classNames(descriptionClassName, 'mt-10 font-bold')}>Disabled</div>
          <div className={descriptionClassName}>Gray300</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-row gap-[12px] ">
            <Switch checked={checked3} setChecked={setChecked3} disabled></Switch>
            <Switch checked={checked4} setChecked={setChecked4} disabled></Switch>
          </div>
          <div className={classNames(descriptionClassName, 'mt-10 font-bold')}>Inactive</div>
          <div className={descriptionClassName}>50%</div>
        </div>
      </div>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const SwitchComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col">
        <div id="Switch" className={headerClassName}>
          Switch
        </div>
        <div className={classNames(descriptionClassName, 'mb-36')}>
          특정 옵션을 켜고 끌 수 있는 컨트롤입니다. <br />
          Switch의 상태를 보고 옵션의 설정 여부를 파악할 수 있습니다.
        </div>
        <SectionTypes />
        <SectionExamples />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Switch" className={anchorClassName}>
          Switch
        </a>
      </div>
    </div>
  )
}

export default SwitchComponent
