import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { COOKIE_LIST } from '../../vars'

const useIsMobile = (defaultIsMobile: boolean | undefined = undefined, mobileSize: number | undefined = 640) => {
  const cookies = new Cookies()
  // isWebviewCafAllowed
  // 어드민에서 발급한 URL에서는 CAF접근이 허용되도록 추가되었으나, 모바일 환경에서만 가능하도록 설계되어있어
  // 8/25 PC웹 런칭 후 해당 URL에 PC웹으로 인식돼 정상적으로 동작되지 않음, isMobile이 caf_token이 있는경우 true만 반환하도록 추가
  const isWebviewCafAllowed = !!cookies.get(COOKIE_LIST.CAF_TOKEN)
  const MOBILE_SIZE = mobileSize

  const [isMobile, setIsMobile] = useState(() => {
    if (isWebviewCafAllowed) return true
    if (defaultIsMobile !== undefined) {
      return defaultIsMobile
    } else {
      return typeof window !== 'undefined' && window?.innerWidth < MOBILE_SIZE ? true : false
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (isWebviewCafAllowed) {
        setIsMobile(true)
      } else if (window.screen.width < window.innerWidth) {
        setIsMobile(window.screen.width < MOBILE_SIZE)
      } else {
        setIsMobile(window.innerWidth < MOBILE_SIZE)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isWebviewCafAllowed) {
      setIsMobile(true)
    } else if (window.screen.width < window.innerWidth) {
      setIsMobile(window.screen.width < MOBILE_SIZE)
    } else {
      setIsMobile(window.innerWidth < MOBILE_SIZE)
    }
  }, [])

  return isMobile
}

export default useIsMobile
