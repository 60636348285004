import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { TAB_TYPE, TabHeaderItemProps, TabHeaderProps, TabItemProps, TabProps } from './interface'
import { TabContext, useTabState } from './modules/TabStateContext'

const Tab = (props: TabProps) => {
  const {
    children,
    type,
    customColor,
    className,
    activeTabIdx = 0,
    setActiveTabIdx,
    borderNone,
    wrapperClassName,
  } = props

  const [tab, setTab] = useState<number>(0)

  useEffect(() => {
    setTab(+activeTabIdx)
  }, [activeTabIdx])

  const tabNameList = React.Children.map(children, (child) => child?.props.name) as string[]
  const tabOnClickList = React.Children.map(children, (child) => child?.props.onClick)
  const activeTab = React.Children.toArray(children)[tab]
  return (
    <TabContext.Provider value={{ tab, setTab, customColor, type }}>
      <Tab.TabHeader
        setActiveTabIdx={setActiveTabIdx}
        activeTabIdx={activeTabIdx}
        tabNameList={tabNameList}
        onClickList={tabOnClickList}
        className={className}
        borderNone={borderNone}
        wrapperClassName={wrapperClassName}
      />
      {activeTab}
    </TabContext.Provider>
  )
}

// 전체 header
const TabHeader = (props: TabHeaderProps) => {
  const { tabNameList, onClickList, className, activeTabIdx, borderNone, setActiveTabIdx, wrapperClassName } = props
  const context = useTabState()
  const { type } = context
  const headerRef = useRef<HTMLDivElement>(null)

  // 가로 스크롤 구현
  const handleWheel = (event) => {
    if (type === TAB_TYPE.SCROLL && headerRef.current) {
      event.preventDefault()
      const delta = Math.sign(event.deltaY)
      const distance = 100
      headerRef.current.scrollLeft += delta * distance
    }
  }

  const handleMouseEnter = () => {
    if (type === TAB_TYPE.SCROLL) {
      window.addEventListener('wheel', handleWheel, { passive: false })
    }
  }

  const handleMouseLeave = () => {
    if (type === TAB_TYPE.SCROLL) {
      window.removeEventListener('wheel', handleWheel)
    }
  }

  return (
    <div
      className={classNames('no-scrollbar overflow-x-auto', wrapperClassName)}
      ref={headerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={classNames(
          'bg-q-white border-q-gray-100 flex h-[52px] flex-row',
          type === TAB_TYPE.SCROLL ? 'justify-start ' : 'justify-evenly sm:justify-center',
          className,
          borderNone ? '' : 'border-collapse border-b-[1px]'
        )}
      >
        {tabNameList.map((tabName, tabIndex) => (
          <TabHeaderItem
            activeTabIdx={activeTabIdx}
            setActiveTabIdx={setActiveTabIdx}
            key={`${tabIndex}-${tabName}`}
            tabName={tabName}
            onClickTab={onClickList[tabIndex]}
            tabIndex={tabIndex}
            containerRef={headerRef}
            handleWheel={handleWheel}
          />
        ))}
      </div>
    </div>
  )
}
Tab.TabHeader = TabHeader

const TabHeaderItem = (props: TabHeaderItemProps) => {
  const { tabName, tabIndex, containerRef, handleWheel, activeTabIdx, setActiveTabIdx, onClickTab } = props
  const tabRef = useRef<HTMLDivElement>(null)
  const context = useTabState()
  // const router = useAppRouter()
  if (!context) throw new Error('TabHeaderItem')
  const { tab, setTab, customColor, type } = context
  const isActiveTab = tabIndex === tab

  // 탭 선택 시 활성화된 탭이 완전히 나타나도록 위치 변경
  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (onClickTab) {
      onClickTab(e)
    }
    if (!isActiveTab && setActiveTabIdx) {
      setActiveTabIdx(tabIndex)
      setTab(tabIndex)
    }
    if (type === TAB_TYPE.SCROLL) {
      window.removeEventListener('wheel', handleWheel)
    }
  }

  useEffect(() => {
    if (type === TAB_TYPE.SCROLL) {
      if (activeTabIdx > 0 && tabRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const tabRect = tabRef.current.getBoundingClientRect()
        const left = tabRect.left - containerRect.left
        const right = tabRect.right - containerRect.right
        const scrollLeft = containerRef.current.scrollLeft

        if (left < 0) {
          containerRef.current.scrollLeft = scrollLeft + left
        } else if (right > 0) {
          containerRef.current.scrollLeft = scrollLeft + right
        }
      } else {
        containerRef.current.scrollLeft = 0
      }
    }
  }, [activeTabIdx])

  return (
    <div
      className={classNames(
        'min-w-fit py-[13px] px-[8px]',
        isActiveTab ? 'cursor-pointer border-b-[2px] border-black  text-black' : 'cursor-pointer text-gray-300',
        type === TAB_TYPE.SCROLL ? 'mx-[8px]' : 'mx-0 sm:mx-[68px] ',
        isActiveTab && !!customColor && `text-[${customColor}] border-[${customColor}]`
      )}
      style={
        customColor && isActiveTab
          ? {
              color: customColor,
              borderColor: customColor,
            }
          : {}
      }
      onClick={onClick}
      ref={tabRef}
    >
      <div
        className={classNames(
          typeof tabName !== 'object' && 'Body1-16Bd leading-[26px]',
          'flex items-center justify-center'
        )}
      >
        {tabName}
      </div>
    </div>
  )
}

// 기본 item
const TabItem = (props: TabItemProps) => {
  const { children, onClick } = props

  return <div onClick={onClick}>{children}</div>
}
Tab.TabItem = TabItem

export default Tab
