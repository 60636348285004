import { Cookies } from 'react-cookie'
import { COOKIE_LIST } from '../vars'
import { DEFAULT_LOCALE, isSupportedLocale, LocaleType } from './config'

export function getInitialLocale(): LocaleType {
  const cookies = new Cookies()
  const locale = cookies.get(COOKIE_LIST.LOCALE)
  if (isSupportedLocale(locale)) {
    return locale
  }

  const [browserSetting] = navigator.language.split('-')

  if (isSupportedLocale(browserSetting)) {
    return browserSetting
  }

  return DEFAULT_LOCALE
}
