import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useDropdownState } from '../modules/DropdownStateContext'
import DropdownMenuItem from './DropdownMenuItem'

interface DropdownMenuProps {
  className?: string
  children: any | React.ReactElement<typeof DropdownMenuItem>[] | React.ReactElement<typeof DropdownMenuItem>
  wrapperClassName?: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  className,
  wrapperClassName = '',
}: DropdownMenuProps) => {
  const { open } = useDropdownState()

  const animationClassName = classNames(
    'duration-140 transition-all ease-in-out ',
    open ? 'opacity-100' : 'invisible hidden opacity-0'
  )

  const count = useMemo(() => React.Children.count(children), [children])
  const isShowScrollbar = count > 6

  return (
    <div className={classNames(wrapperClassName, animationClassName, styles.menuWrapper({ isShowScrollbar }))}>
      <div className={classNames(styles.menu({ isShowScrollbar }), className)}>{children}</div>
    </div>
  )
}

export default DropdownMenu

const styles = {
  menuWrapper: ({ isShowScrollbar }) =>
    classNames(
      `z-1 absolute mt-[4px] flex flex-col items-center justify-start rounded-[8px] bg-white py-[8px] pl-[8px] shadow-[0px_2px_8px_rgba(0,0,0,0.15)]`,
      isShowScrollbar ? `pr-[2px]` : `pr-[8px]`
    ),
  menu: ({ isShowScrollbar }) => classNames(isShowScrollbar && `scrollbar-new max-h-[280px] overflow-y-auto`),
}
