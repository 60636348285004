import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CurrentUser } from '../../../model/Common/common.interface'
import { getJwtToken } from '../../../modules/auth'
import { userQueryApi } from '../../react-query/queryApis'

// * =========================================================================== */
// *
// * USE-QUERY *
// *
// * =========================================================================== */

export const useUser = () => {
  const token = getJwtToken()

  return useQuery<CurrentUser, AxiosError>({
    queryKey: userQueryApi.getQueryKey(),
    queryFn: userQueryApi.getQueryFn(),

    // // onError: (e) => handleAxiosError(e),
    staleTime: 1000 * 60 * 24,
    cacheTime: 1000 * 60 * 24,
    retry: false,
    enabled: !!token,
  })
}

// ! =========================================================================== */
