import { QIcon_chevron_right, QIcon_plus_default } from '@repo/icons/index'
import { BuilderButton } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { COLORS } from '../../modules/vars'
import { subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionTextButton = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  return (
    <section id="TextButton">
      <div className={subHeaderClassName}>TextButton 사이즈</div>
      <p>sm</p>
      <div className="flex items-center gap-[20px]">
        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.SM} onClick={handleButtonClick}>
            <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.SM} onClick={handleButtonClick}>
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton
            type={BUILDER_BUTTON_TYPE.TEXT}
            size={BUILDER_BUTTON_SIZE.SM}
            disabled
            customBgColor={COLORS.BLUE900}
            onClick={handleButtonClick}
          >
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className={'ml-[2px]'} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>
      </div>

      <p>md</p>
      <div className="flex items-center gap-[20px]">
        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.MD} onClick={handleButtonClick}>
            <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.MD} onClick={handleButtonClick}>
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton
            type={BUILDER_BUTTON_TYPE.TEXT}
            size={BUILDER_BUTTON_SIZE.MD}
            disabled
            customBgColor={COLORS.BLUE900}
            onClick={handleButtonClick}
          >
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className="ml-[2px]" />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>
      </div>

      <p>lg</p>
      <div className="flex items-center gap-[20px]">
        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.LG} onClick={handleButtonClick}>
            <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton type={BUILDER_BUTTON_TYPE.TEXT} size={BUILDER_BUTTON_SIZE.LG} onClick={handleButtonClick}>
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>

        <div>
          <BuilderButton
            type={BUILDER_BUTTON_TYPE.TEXT}
            size={BUILDER_BUTTON_SIZE.LG}
            disabled
            customBgColor={COLORS.BLUE900}
            onClick={handleButtonClick}
          >
            <BuilderButton.Label customTxtColor={COLORS.PURPLE}>BuilderButton</BuilderButton.Label>
            <BuilderButton.Icon alt={'plus icon'}>
              <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className={'ml-[2px]'} />
            </BuilderButton.Icon>
          </BuilderButton>
        </div>
      </div>
    </section>
  )
}

export default SectionTextButton
