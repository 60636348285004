import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { COLORS } from '../../../modules/vars'
import { DSPromoPopupContext } from '../modules/DSPropmoPopupContext'
import DSPromoPopupCloseButton from './DSPromoPopupCloseButton'

export type DSPROMOPOPUP_SIZE_TYPE = 'sm' | 'lg'

export const POPUP_CONTAINER_ID = 'ds-popup'

interface DSPromoPopupProps {
  id: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  size: DSPROMOPOPUP_SIZE_TYPE
  bgColor: (typeof COLORS)[keyof typeof COLORS]
  closeIconColor: (typeof COLORS)[keyof typeof COLORS]
  closeOnDim?: boolean

  children: React.ReactNode
}

/**
 * 프로모션용 팝업
 * @param props
 * @returns
 */
const DSPromoPopupMain = (props: DSPromoPopupProps) => {
  const { id, isOpen, setIsOpen, size, bgColor, closeIconColor, closeOnDim, children } = props

  const popupDOM = document.getElementById(POPUP_CONTAINER_ID)

  const closePopup: React.MouseEventHandler<HTMLDivElement | SVGSVGElement> = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }
  const onClickContent: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // Dim 까지 propagation 되면 팝업이 의도치 않게 닫힐 수 있으므로 stopPropagation 필수
    e.stopPropagation()
  }

  const dimClassName = DIM_CLASSNAME
  const containerClassName = CONATINER_CLASSNAME[size]

  const renderPopup = isOpen && !!popupDOM

  useEffect(() => {
    let isClosingByPopstate = false

    const closePopupForEventListner = (e: PopStateEvent) => {
      setIsOpen(false)
      isClosingByPopstate = true
    }

    if (renderPopup) {
      window.history.pushState(null, '')
      window.addEventListener('popstate', closePopupForEventListner)
    }

    return () => {
      window.removeEventListener('popstate', closePopupForEventListner)
      /**
       * return 문은 버튼으로 닫든 popstate 하든 무조건 동작하므로,
       * isClosingByPopstate 변수 사용해서 window.history.back() 중복 실행 방지
       */
      if (renderPopup && !isClosingByPopstate) window.history.back()
    }
  }, [renderPopup])

  if (!renderPopup) return null

  return createPortal(
    <div id={id} className={dimClassName} onClick={closeOnDim ? closePopup : undefined}>
      <div className={containerClassName} onClick={onClickContent} style={{ backgroundColor: bgColor }}>
        <DSPromoPopupContext.Provider value={{ size }}>
          <DSPromoPopupCloseButton closePopup={closePopup} color={closeIconColor} />
          {children}
        </DSPromoPopupContext.Provider>
      </div>
    </div>,
    popupDOM
  )
}

export default DSPromoPopupMain

const DIM_CLASSNAME =
  'fixed top-0 left-0 w-full px-[16px] h-full flex items-center justify-center bg-[rgba(0,0,0,0.4)] z-[2147483647]'

const CONATINER_CLASSNAME: { [size in DSPROMOPOPUP_SIZE_TYPE]: string } = {
  sm: 'w-[328px] pl-[20px] pr-[16px] pt-[16px] pb-[24px] rounded-[12px] font-preten',
  lg: 'w-[464px] pl-[20px] pr-[16px] pt-[16px] pb-[32px] rounded-[12px] font-preten',
}
