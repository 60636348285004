import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { COLOR_KEY } from '../../../..'
import { COLORS } from '../../../modules/vars'
import { BuilderButtonContext } from '../modules/BuilderButtonStateContext'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_SIZE_KEY, BUILDER_BUTTON_TYPE, BUILDER_BUTTON_TYPE_KEY } from '../types'

const ButtonMainSkeketon = ({ clx }) => {
  return <div className={classNames(clx, 'animate-pulse rounded-md bg-gray-100 text-transparent')}>Button</div>
}

// TODO: BuilderButton -> DSButton으로 네이밍 변경
// TODO: type 속성은 button 태그에 내장된 type 속성과 겹치기 때문에 속성 이름 변경하기
export interface IBuilderButtonMain extends PropsWithChildren {
  type: BUILDER_BUTTON_TYPE_KEY
  size: BUILDER_BUTTON_SIZE_KEY
  customBgColor?: COLOR_KEY
  customBorderColor?: COLOR_KEY
  textWithIcon?: boolean // 텍스트와 아이콘이 같이 있는지 여부
  iconOnly?: boolean // 아이콘만 있는지 여부
  isLoading?: boolean
  className?: string
  showSkeleton?: boolean
}

export type BuilderButtonMainProps = IBuilderButtonMain & {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const BuilderButtonMain = ({
  isLoading = false,
  textWithIcon = false,
  iconOnly = false,
  type,
  size,
  customBgColor = undefined,
  customBorderColor = undefined,
  className = '',
  onClick = (e) => {},
  disabled = false,
  showSkeleton = false,
  children = undefined,
}: BuilderButtonMainProps) => {
  if (showSkeleton) {
    return <ButtonMainSkeketon clx={styles.size({ type, size, textWithIcon, iconOnly, disabled, isLoading })} />
  }
  if (type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY) {
    if (customBgColor && customBgColor !== COLORS.WHITE) {
      throw new Error('background color of outline_gray button cannot be customized')
    }
    if (customBorderColor && customBorderColor !== COLORS.GRAY300) {
      throw new Error('border color of outline_gray button cannot be customized')
    }
  }
  if (textWithIcon && iconOnly) {
    throw new Error('"textWithIcon" mode and "iconOnly" mode cannot both be true.')
  }

  return (
    <BuilderButtonContext.Provider value={{ size, disabled, isLoading, type }}>
      <button
        className={classNames(
          type !== BUILDER_BUTTON_TYPE.TEXT && styles.disabled({ disabled }),
          type !== BUILDER_BUTTON_TYPE.TEXT && styles.size({ type, size, textWithIcon, iconOnly, disabled, isLoading }),
          type === BUILDER_BUTTON_TYPE.TEXT && 'h-[44px] px-[8px]',
          !disabled && styles.border({ type, size }),
          !disabled && type !== BUILDER_BUTTON_TYPE.TEXT && backgroundColor(type),
          !disabled && type !== BUILDER_BUTTON_TYPE.TEXT && borderColor(type),
          !disabled && !isLoading && type !== BUILDER_BUTTON_TYPE.TEXT && hoverStyles(type),
          !disabled && !isLoading && type !== BUILDER_BUTTON_TYPE.TEXT && activeStyles(type),
          'flex w-full items-center justify-center cursor-pointer',
          className
        )}
        style={{
          ...(customBgColor && !disabled && type !== BUILDER_BUTTON_TYPE.TEXT && { backgroundColor: customBgColor }),
          ...(customBorderColor &&
            !disabled &&
            type !== BUILDER_BUTTON_TYPE.TEXT && { borderColor: customBorderColor }),
        }}
        onClick={(e) => {
          onClick(e)
        }}
        disabled={disabled}
      >
        {children}
      </button>
    </BuilderButtonContext.Provider>
  )
}

export default BuilderButtonMain

const styles = {
  disabled: ({ disabled }: { disabled: boolean }) => [disabled && `bg-gray-200`],
  size: ({
    type,
    size,
    textWithIcon,
    iconOnly,
    disabled,
    isLoading,
  }: {
    type: BUILDER_BUTTON_TYPE_KEY
    size: BUILDER_BUTTON_SIZE_KEY
    textWithIcon: boolean
    iconOnly: boolean
    disabled: boolean
    isLoading: boolean
  }) => {
    if (type === BUILDER_BUTTON_TYPE.SOLID || disabled) {
      switch (size) {
        case BUILDER_BUTTON_SIZE.XS:
          return [
            `rounded-[4px]`,
            !iconOnly && !isLoading && `py-[6.5px] px-[8px]`,
            !iconOnly && isLoading && `py-[7.5px] px-[18.5px]`,
            iconOnly && `px-[5.5px] py-[6px]`,
          ]
        case BUILDER_BUTTON_SIZE.SM:
          return [
            `rounded-[4px]`,
            !iconOnly && !isLoading && `py-[10px] ${textWithIcon ? 'px-[12px]' : 'px-[16px]'}`,
            !iconOnly && isLoading && `py-[11px] px-[27.5px]`,
            iconOnly && `p-[11px]`,
          ]
        case BUILDER_BUTTON_SIZE.MD:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[12px] ${textWithIcon ? 'px-[24px]' : 'px-[28px]'}`,
            !iconOnly && isLoading && `py-[11px] px-[38.5px]`,
            iconOnly && `px-[10px] py-[11px]`,
          ]
        case BUILDER_BUTTON_SIZE.LG:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[16px] ${textWithIcon ? 'px-[20px]' : 'px-[24px]'}`,
            !iconOnly && isLoading && `py-[13px] px-[33px]`,
          ]
        case BUILDER_BUTTON_SIZE.XL:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[18px] ${textWithIcon ? 'px-[44px]' : 'px-[48px]'}`,
            !iconOnly && isLoading && `py-[15px] px-[57.5px]`,
          ]
        default:
          return ''
      }
    } else {
      // border가 있는 경우 border 두께만큼 padding 감소
      switch (size) {
        case BUILDER_BUTTON_SIZE.XS:
          return [
            `rounded-[4px]`,
            !iconOnly && !isLoading && `py-[5.5px] px-[7px]`,
            !iconOnly && isLoading && `py-[6.5px] px-[17.5px]`,
            iconOnly && `px-[4.5px] py-[5px]`,
          ]
        case BUILDER_BUTTON_SIZE.SM:
          return [
            `rounded-[4px]`,
            !iconOnly && !isLoading && `py-[8.5px] ${textWithIcon ? 'px-[10.5px]' : 'px-[14.5px]'}`,
            !iconOnly && isLoading && `py-[9.5px] px-[26px]`,
            iconOnly && `p-[9.5px]`,
          ]
        case BUILDER_BUTTON_SIZE.MD:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[10.5px] ${textWithIcon ? 'px-[22.5px]' : 'px-[26.5px]'}`,
            !iconOnly && isLoading && `py-[9.5px] px-[37px]`,
            iconOnly && `px-[8.5px] py-[9.5px]`,
          ]
        case BUILDER_BUTTON_SIZE.LG:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[14.5px] ${textWithIcon ? 'px-[18.5px]' : 'px-[22.5px]'}`,
            !iconOnly && isLoading && `py-[11.5px] px-[31.5px]`,
          ]
        case BUILDER_BUTTON_SIZE.XL:
          return [
            `rounded-[8px]`,
            !iconOnly && !isLoading && `py-[16.5px] ${textWithIcon ? 'px-[42.5px]' : 'px-[46.5px]'}`,
            !iconOnly && isLoading && `py-[13.5px] px-[56px]`,
          ]
        default:
          return ''
      }
    }
  },
  border: ({ type, size }: { type: BUILDER_BUTTON_SIZE_KEY; size: BUILDER_BUTTON_SIZE_KEY }) =>
    classNames(
      (type === BUILDER_BUTTON_TYPE.OUTLINE || type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY) &&
        size === BUILDER_BUTTON_SIZE.XS &&
        `border-[1px]`,
      (type === BUILDER_BUTTON_TYPE.OUTLINE || type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY) &&
        size !== BUILDER_BUTTON_SIZE.XS &&
        `border-[1.5px]`
    ),
}

const backgroundColor = (type: BUILDER_BUTTON_TYPE_KEY): string => {
  return classNames(
    type === BUILDER_BUTTON_TYPE.SOLID && 'bg-purple-600',
    type === BUILDER_BUTTON_TYPE.OUTLINE && 'bg-white',
    type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && 'bg-white'
  )
}

const borderColor = (type: BUILDER_BUTTON_TYPE_KEY): string => {
  return classNames(
    type === BUILDER_BUTTON_TYPE.OUTLINE && 'border-purple-600',
    type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && 'border-gray-300'
  )
}

const hoverStyles = (type: BUILDER_BUTTON_TYPE_KEY): string => {
  return classNames(
    type === BUILDER_BUTTON_TYPE.SOLID && 'hover:bg-opacity-70',
    type === BUILDER_BUTTON_TYPE.OUTLINE && 'hover:bg-gray-50',
    type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && 'hover:bg-gray-50'
  )
}
const activeStyles = (type: BUILDER_BUTTON_TYPE_KEY): string => {
  return classNames(
    type === BUILDER_BUTTON_TYPE.SOLID && 'active:bg-opacity-50',
    type === BUILDER_BUTTON_TYPE.OUTLINE && 'active:bg-gray-100',
    type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && 'active:bg-gray-100'
  )
}
