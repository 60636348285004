import { QIcon_close_default } from '@repo/icons/index'
import classNames from 'classnames'
import { useDSModalState } from '../modules/DSModalContext'
import { DSMODAL_SIZE_TYPE } from './DSModalMain'

export interface DSModalTitleProps {
  children: React.ReactNode
  className?: string
}

const DSModalTitle = (props: DSModalTitleProps) => {
  const { children, className } = props
  const { size, setIsOpen, closeButton } = useDSModalState()

  // const className = CLASSNAME[size]

  const onClickCloseButton: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <div className="flex w-full flex-row space-x-[12px]">
      <p className={classNames(className, CLASSNAME[size])}>{children}</p>
      {closeButton && (
        <QIcon_close_default className="cursor-pointer" onClick={onClickCloseButton} width={20} height={20} />
      )}
    </div>
  )
}

export default DSModalTitle

const CLASSNAME: { [size in DSMODAL_SIZE_TYPE]?: string } = {
  sm: 'w-full Title2-18Bd',
  lg: 'w-full Headline3-22Bd',
  xl: 'w-full Headline2-24Bd',
}
