import TagManager from 'react-gtm-module'
import { isGTMLoaded } from '../../../helpers/gtmHelper'
import { useDefaultEventPayload } from './useDefaultEventPayload'

export enum GTM_EVENTS {
  CLICK = 'click_event',
  PAGE_VIEW = 'page_view_event',
}

interface ClickEventProps {
  button_name: string
  object_id?: number | string
  object_title?: string
  object_context?: string
  location_detail?: string
}

interface PageViewEventProps {
  gtm_event?: string
  page_name: string
  object_id?: number | string
  object_title?: string
  object_context?: string
  page_context?: string
}

interface EventTrackingHook {
  sendClickEvent: (props: ClickEventProps) => void
  sendPageViewEvent: (props: PageViewEventProps) => void
}

export const useEventTracking = (): EventTrackingHook => {
  const defaultEventPayload = useDefaultEventPayload()

  const sendGTMClickEvent = async (eventName: GTM_EVENTS, eventPayload?: Record<string, any>): Promise<void> => {
    try {
      console.log(`SEND GTM EVENT: ${eventName}`, eventPayload)
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...(eventPayload ?? {}),
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const sendGTMPageViewEvent = async (
    eventName: GTM_EVENTS | string,
    eventPayload?: Record<string, any>
  ): Promise<void> => {
    try {
      console.log(`SEND GTM EVENT: ${eventName}`, eventPayload)
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...(eventPayload ?? {}),
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Send Click Event Function
   * @param button_name Button name (required)
   * @param object_id Object ID (optional)
   * @param object_title Object Title (optional)
   * @param object_context Object Context (optional)
   * @param location_detail Location Detail (optional)
   */
  const sendClickEvent = (props: ClickEventProps) => {
    const { button_name, object_id, object_title, object_context, location_detail } = props

    if (isGTMLoaded()) {
      const eventPayload = {
        ...(defaultEventPayload ?? {}),
        button_name: button_name ?? undefined,
        object_id: object_id ?? undefined,
        object_title: object_title ?? undefined,
        object_context: object_context ?? undefined,
        location_detail: location_detail ?? undefined,
      }
      sendGTMClickEvent(GTM_EVENTS.CLICK, eventPayload)
    }
  }

  /**
   * Send Page View Event Function
   * @param gtm_event GTM Event name (optional)
   * @param page_name Page name (required)
   * @param object_id Object ID (optional)
   * @param object_title Object Title (optional)
   * @param object_context Object Context (optional)
   * @param page_context Page Context (optional)
   */
  const sendPageViewEvent = (props: PageViewEventProps) => {
    const { gtm_event = '', page_name, object_id, object_title, object_context, page_context } = props
    if (isGTMLoaded()) {
      const gtmEvent = gtm_event ? gtm_event : GTM_EVENTS.PAGE_VIEW

      const eventPayload = {
        ...(defaultEventPayload ?? {}),
        page_name: page_name ?? undefined,
        object_id: object_id ?? undefined,
        object_title: object_title ?? undefined,
        object_context: object_context ?? undefined,
        page_context: page_context ?? undefined,
      }

      sendGTMPageViewEvent(gtmEvent, eventPayload)
    }
  }

  return { sendClickEvent, sendPageViewEvent }
}
