// TODO: 디자인시스템의 TextArea 사용 검토

import classNames from 'classnames/dedupe'
import { CSSProperties } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import { DivPropType } from '../basic/DivPropsType'

type ClassNamesValue = string | Record<string, boolean> | ClassNamesValue[]

interface CardTextareaPropsType extends DivPropType {
  cardClass?: string
  bodyClass?: string
  className?: ClassNamesValue
  disabled?: boolean
  style?: CSSProperties
  noBorder?: boolean
  rounded?: boolean
}

const CardTextarea = ({
  cardClass,
  bodyClass,
  className,
  disabled,
  style,
  rounded,
  ...others
}: CardTextareaPropsType) => {
  return (
    <div
      style={style}
      className={classNames(
        classNames(
          'shadow-none ',
          {
            rounded: rounded,
          },
          cardClass
        )
      )}
    >
      <div className={classNames(classNames('', bodyClass))}>
        <TextareaAutosize
          disabled={disabled}
          className={classNames(
            'w-full resize-none rounded border-0 border-transparent p-12 focus:outline-none focus:ring-0',
            className
          )}
          {...others}
        />
      </div>
    </div>
  )
}

export default CardTextarea
