// Legacy DSInput -> 이 파일보다는 DSInput을 사용 권장

import classNames from 'classnames'
import { forwardRef } from 'react'

interface InputTypes {
  dataRef?: string
  name?: string
  type?: string
  placeholder?: string
  autoComplete?: string
  id?: string
  tabIndex?: number
  clx?: any
  disabled?: boolean
  value?: any
  error?: boolean
  defaultValue?: any
  onChange?: any
  onBlur?: any
  style?: any
  maxLength?: number
  pattern?: any
  border?: boolean
  border2?: boolean
  border3?: boolean
  border4?: boolean
  noBorder?: boolean
  noPadding?: boolean
  borderPrimary?: boolean
  warningMessage?: string
  wrapperClassName?: string
}
const Input = forwardRef((props: InputTypes, ref: any) => {
  const {
    dataRef,
    name,
    type,
    placeholder,
    autoComplete,
    tabIndex,
    clx,
    disabled,
    value,
    onChange,
    onBlur,
    error,
    defaultValue,
    style,
    maxLength,
    pattern,
    border,
    border2,
    border3,
    border4,
    noBorder,
    noPadding = false,
    borderPrimary = true,
    id,
    warningMessage,
    wrapperClassName = null,
  } = props
  return (
    <div className={wrapperClassName}>
      <input
        data-ref={dataRef}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        ref={ref}
        name={name}
        type={type}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        tabIndex={tabIndex}
        defaultValue={defaultValue}
        style={Object.assign({ borderRadius: '8px' }, style)}
        maxLength={maxLength}
        pattern={pattern}
        id={id}
        className={classNames(
          clx,
          'text-h4 box-border block w-full appearance-none p-12 placeholder-gray-300 focus:outline-none',
          { 'focus:border-purple-500': borderPrimary },
          { 'border-red-500': error },
          { 'border-gray-300': !error && !noBorder },
          { 'bg-gray-100': disabled },
          { border: !border2 && !border3 && !noBorder },
          { 'border-2': border2 },
          { 'border-3': border3 },
          { 'border-4': border4 },
          { 'border-0 border-transparent': noBorder },
          { 'p-0': noPadding },
          { 'p-12': !noPadding }
        )}
      />
      {warningMessage && <div className="text-12 mt-[4px] px-0 pt-0 text-left text-red-500">{warningMessage}</div>}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
