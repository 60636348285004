import classNames from 'classnames'
import { PropsWithChildren, useState } from 'react'
import { useDropdownState } from '../modules/DropdownStateContext'
interface DropdownMenuItemProps extends PropsWithChildren {
  className?: string
  children: any
  value: string | number
  disabled?: boolean
  divider?: boolean
  label?: string
  onClick?: any
}

const DropdownMenuItem = ({ children, value, label, className, disabled, divider, onClick }: DropdownMenuItemProps) => {
  const { selected, setSelected, setOpen, isTeens } = useDropdownState()
  const [hover, setHover] = useState<boolean>(false)

  const isSelected = value == selected?.value

  const handleClickItem = () => {
    if (disabled) return

    setOpen((prev) => !prev)
    setSelected({ value: value, label: label ? label : children })
    if (onClick) onClick()
  }

  return (
    <>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={classNames(className, styles.item({ hover, isSelected, disabled, isTeens }))}
        onClick={handleClickItem}
      >
        {children}
      </div>
      {divider && <hr className={styles.divider} />}
    </>
  )
}

export default DropdownMenuItem

const styles = {
  divider: `my-[4px]`,
  item: ({ hover, isSelected, disabled, isTeens }) => {
    const selectedBgColor = isTeens ? `bg-blue-50` : 'bg-purple-50'
    const selectedTextColor = isTeens ? `text-blue-500` : 'text-purple-500'

    return classNames(
      `Body2-14Md w-full rounded-[5px] py-[11px] px-[12px] text-left`,
      isSelected && `${selectedBgColor} ${selectedTextColor}`,
      !isSelected && hover && `bg-gray-50 text-gray-500`,
      disabled && `bg-white text-gray-300`
    )
  },
}
