// @ts-strict-ignore
import { compareAsc, format, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import * as hangulRomanization from 'hangul-romanization'
import Router, { NextRouter } from 'next/router'
import randomstring from 'randomstring'
import Cookies from 'universal-cookie'
import {
  LOCALE_EN,
  LOCALE_JA,
  LOCALE_KO,
  LOCALE_VI,
  LOCALE_ZH_CHS,
  LOCALE_ZH_CHT,
  LocaleType,
  localeIndex,
} from '../modules/i18n/config'
import { COOKIE_OPT, DOLLARS_TO_KRW, TODO_TYPES } from '../modules/vars'
import { RINGLE_DOMAIN } from './envVars'
import { getInitialLocale } from './i18n/getInitialLocale'

export const isValidDate = (date) => {
  if (typeof date !== 'string' || date.length !== 10 || date[2] + date[5] !== '--') {
    return false
  }
  const mm = date.slice(0, 2)
  const dd = date.slice(3, 5)
  const yyyy = date.slice(6)
  const days = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ]
  let day = 30
  switch (mm) {
    case '01':
    case '03':
    case '05':
    case '07':
    case '08':
    case '10':
    case '12':
      if (!days.includes(dd)) {
        return false
      }
      break
    case '02':
      if (parseInt(yyyy) % 4 == 0) {
        day = 29
      } else {
        day = 28
      }
      if (!days.slice(0, day).includes(dd)) {
        return false
      }
      break
    default:
      day = 30
      if (!days.slice(0, day).includes(dd)) {
        return false
      }
      break
  }
  return true
}
export const isValidRegNum = (num) => {
  if (typeof num !== 'string' || num.length !== 14) {
    return false
  }
  for (let i = 0; i < num.length; i++) {
    if (i == 6) {
      if (num.charAt(i) !== '-') {
        return false
      }
    } else if (isNaN(parseInt(num.charAt(i)))) {
      return false
    }
  }
  return true
}
export const isInputValue = (value) => {
  if (value == undefined || value == '' || value == null || value == ' ') {
    return false
  } else {
    return true
  }
}
export const isKorean = (str) => {
  const re = /[^\u3131-\uD79D]/giu
  if (str.match(re)) {
    return false
  }
  return true
}

export const isNumber = (str) => {
  const re = /[^0-9]/g
  if (str.match(re)) {
    return false
  }
  return true
}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat().format(number)
}
export function financial(x, fixed = 2) {
  return Number.parseFloat(x).toFixed(fixed)
}

export function finishedFirstClassUser(currentUser) {
  return currentUser && currentUser.profile?.total_lesson > 0
}
export function firstUser(currentUser) {
  return !currentUser || (currentUser && currentUser.profile?.total_amount == 0)
}
export function paidUser(currentUser) {
  return currentUser && currentUser.profile?.total_amount > 0
}
export function paidUserInEvent(currentUser) {
  return currentUser && currentUser.event?.purchased_in_period
}
export function completeTrialUser(currentUser) {
  return currentUser && currentUser.trial_complete
}
export function priceWithLocale(isKo, price) {
  return isKo ? `${currencyFormat(price)}원` : `$${financial(price / DOLLARS_TO_KRW)}`
}

export function calculateByMathMethod(mathMethod: MATH_METHOD, value) {
  switch (mathMethod) {
    case MATH_METHOD.ROUND:
      value = Math.round(value)
      break
    case MATH_METHOD.CEIL:
      value = Math.round(value)
      break
    case MATH_METHOD.FLOOR:
      value = Math.round(value)
      break
  }
  return value
}
export function priceWithCountryCode(
  price,
  country_code,
  converted = true,
  point = false,
  locale: any = LOCALE_KO,
  mathMethod = null
) {
  const exchange_rate = getExchangeRate(country_code)
  if (!converted) price = price / exchange_rate

  if (mathMethod) {
    price = calculateByMathMethod(mathMethod, price)
  }

  switch (country_code) {
    case 'KR':
      price = `${currencyFormat(price)}`
      if (!point) {
        if (locale == LOCALE_KO) {
          price += '원'
        } else {
          price = '₩' + price
        }
      }
      break
    case 'JP':
      price = `${currencyFormat(price)}円`
      break
    case 'VN':
      price = Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(price)
      break
    default:
      if (point) {
        price = Math.round(price * 100) / 100
      } else {
        price = Math.round(price)
      }
      price = `$${currencyFormat(price)}`
      break
  }
  return price
}
export function languageByCountryCode(countryCode) {
  switch (countryCode) {
    case 'KR':
      return LOCALE_KO
    case 'JP':
      return LOCALE_JA
    case 'VN':
      return LOCALE_VI
    case 'CN':
      return LOCALE_ZH_CHS
    case 'TW':
      return LOCALE_ZH_CHT
    default:
      return LOCALE_EN
  }
}

export const manWon = (price, countryCode, point = false, locale: any = LOCALE_KO, mathMethod = null) => {
  if (countryCode == 'KR') {
    return `${price / 10000}만원`
  } else {
    return priceWithCountryCode(price, countryCode, false, point, locale, mathMethod)
  }
}

const decimalAdjust = (type, value, exp) => {
  type = String(type)
  if (!['round', 'floor', 'ceil'].includes(type)) {
    throw new TypeError("The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.")
  }
  exp = Number(exp)
  value = Number(value)
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN
  } else if (exp === 0) {
    return Math[type](value)
  }
  const [magnitude, exponent = 0] = value.toString().split('e')
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`)
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e')
  return Number(`${newMagnitude}e${+newExponent + exp}`)
}

export const convertPrice = (price, country_code, point = true) => {
  switch (country_code) {
    case 'KR':
      break
    case 'JP':
    case 'TW':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case 'CN':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case 'VN':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 1000) * 1000
      }
      break
    default:
      if (point) {
        price = Math.floor(price * 100) / 100
      } else {
        price = Math.round(price)
      }
      break
  }
  return price || 0
}
export const getExchangeRate = (countryCode) => {
  switch (countryCode) {
    case 'KR':
      return 1
    case 'JP':
    case 'VN':
    case 'TW':
    case 'CN':
    case 'US':
      return DOLLARS_TO_KRW
    default:
      return DOLLARS_TO_KRW
  }
}

export function setAdHistory(query) {
  if (!query) {
    return
  }

  const cookies = new Cookies()
  const utm_ad_info = cookies.get('utm_ad_info')
  const { utm_source = '', utm_medium = '', utm_term = '', utm_campaign = '', utm_content = '' } = query
  let str = ''
  let count = 0
  if (utm_ad_info == '' || utm_ad_info == undefined) {
    str = '#1-rg'
    count = 1
  } else {
    count = parseInt(utm_ad_info.substring(0, 2)[1])
    str = `#${count + 1}-rg`
  }
  if (count != 0) {
    if (utm_source != '') {
      str += `&utm_source=${utm_source}`
    }
    if (utm_medium != '') {
      str += `&utm_medium=${utm_medium}`
    }
    if (utm_term != '') {
      str += `&utm_term=${utm_term}`
    }
    if (utm_campaign != '') {
      str += `&utm_campaign=${utm_campaign}`
    }
    if (utm_content != '') {
      str += `&utm_content=${utm_content}`
    }
    const now = format(utcToZonedTime(new Date(), 'UTC'), 'yyyy-MM-dd HH:mm:ss XX')
    if (str.length > 5) {
      str += `&time=${now}`
      cookies.set('utm_ad_info', str + `${utm_ad_info ? utm_ad_info : ''}`, COOKIE_OPT)
    }
  }
}

export function getSupportedMimeTypes() {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska']
  const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus']

  const supportedTypes = []
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ]
      variations.forEach((variation) => {
        if (MediaRecorder.isTypeSupported(variation)) supportedTypes.push(variation)
      })
    })
  })
  return supportedTypes
}

export const getAdditionalDiscount = (is40m, number, isB2b = false, isEvent = false) => {
  return 0

  // if(isB2b) {
  //   return 0
  // } else if(isEvent) {
  //   return 0
  // } else if(is40m && number == 18) {
  //   return 40000
  // } else if(!is40m && number == 24) {
  //   return 20000
  // } else {
  //   return 0
  // }
}

export function getWeekNumber(d: Date) {
  const targetDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  targetDate.setUTCDate(targetDate.getUTCDate() + 4 - (targetDate.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(targetDate.getUTCFullYear(), 0, 1))
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
  return [targetDate.getUTCFullYear(), weekNo]
}

export function getMonthName(d) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return [monthNames[d], shortMonthNames[d]]
}

export const wordCount = (text) => {
  text = text?.trim() || ''
  return text.length > 0 ? text.split(/\s+/).length : 0
}

const formatCurrency = (amount, symbol) => {
  if (isNaN(amount)) {
    return amount
  }

  if (amount < 0) {
    return `-${symbol}` + `${Math.abs(parseFloat(amount)).toFixed(2)}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  } else {
    return `${symbol}` + `${parseFloat(amount).toFixed(2)}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }
}

export const dollar = (amount) => formatCurrency(amount, '$')
export const pound = (amount) => formatCurrency(amount, '£')

export const getNameFromStudent = (student) => {
  if (!student) {
    return ''
  }
  if (student.first_name) {
    return student.first_name
  }
  return student.korean_name ? hangulRomanization.convert(student.korean_name || '') : 'Anonymous'
}

export const randstr = (n) => {
  return randomstring.generate(n)
}

export const getDday = (date) => {
  const now = new Date().getTime()
  const remainTime = date - now
  return Math.floor(remainTime / (1000 * 60 * 60 * 24))
}

export const getBrowser = (useragent) => {
  let browser = ''
  if (useragent.indexOf('FBAN') > -1 || useragent.indexOf('FBAV') > -1) {
    browser = 'facebook'
  } else if (useragent.indexOf('Instagram') > -1) {
    browser = 'instagram'
  } else if (useragent.indexOf('KAKAOTALK') != -1) {
    browser = 'kakaotalk'
  } else {
    return null
  }

  return {
    browser: browser,
  }
}

export const detectInAppBrowser = (agent) => {
  const inAppRegex = [
    /KAKAOTALK/i,
    /Instagram/i,
    /NAVER/i,
    /zumapp/i,
    /Whale/i,
    /Snapchat/i,
    /Line/i,
    /everytimeApp/i,
    /Whatsapp/i,
    /Electron/i,
    /wadiz/i,
    /AliApp/i,
    /FB_IAB/i,
    /FB4A/i,
    /FBAN/i,
    /FBIOS/i,
    /FCSS/i,
    /SamsungBrowser/i,
  ]

  return inAppRegex.some((mobile) => agent.match(mobile))
}

export function redirect(ctx, location) {
  const status = 302
  if (ctx.res) {
    // Seems to be the version used by zeit
    ctx.res.writeHead(status, {
      Location: location,
      'Content-Type': 'text/html; charset=utf-8',
    })
    ctx.res.end()
    return
  }
  Router.replace(location)
}

export const formatTime = (time: number) => {
  let minutes, seconds
  minutes = Math.floor(time / 60)
  minutes = minutes >= 10 ? minutes : '0' + minutes
  seconds = Math.floor(time % 60)
  seconds = seconds >= 10 ? seconds : '0' + seconds
  return minutes + ':' + seconds
}

export const remainTime = (time, comparingTime) => {
  const total = Date.parse(time) - comparingTime
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
}

export const locationMove = (router: NextRouter, locale: LocaleType, url: string) => {
  if (url.includes('[lang]')) {
    router.push(url.replace('[lang]', locale))
  } else {
    router.push(`/${locale}${url}`)
  }
}
export const activeEnter = (e, router: NextRouter, url: string, value: string) => {
  if (url && e.key === 'Enter' && value != '') {
    locationMoveLocale(router, `${url}${value}`)
  }
}

export const locationMoveLocale = (router: NextRouter, url: string) => {
  if (url.startsWith('/en/') || url.startsWith('/ko/')) {
    router.push(url)
  } else if (url.includes('[lang]')) {
    router.push(url.replace('[lang]', getInitialLocale()))
  } else {
    router.push(`/${getInitialLocale()}${url}`)
  }
}

export const locationReplaceLocale = (router: NextRouter, url: string) => {
  if (url.startsWith('/en/') || url.startsWith('/ko/')) {
    router.replace(url)
  } else if (url.includes('[lang]')) {
    router.replace(url.replace('[lang]', getInitialLocale()))
  } else {
    router.replace(`/${getInitialLocale()}${url}`)
  }
}

export const locationMoveLocaleNewTab = (url) => {
  window.open(`/${getInitialLocale()}${url}`, '_blank')
}

export const locationMoveLocaleSizeTab = (url, locale = null) => {
  if (locale) {
    window.open(`/${locale}${url}`, '_blank', 'status=no,width=1100,height=1000,location=no')
  } else {
    window.open(`/${getInitialLocale()}${url}`, '_blank', 'status=no,width=800,height=1000,location=no')
  }
}
export const removeArray = (arr, value) => {
  return arr.filter((item) => {
    return item != value
  })
}

export const checkValidateFile = (file, validFileExtensions) => {
  let valid = false
  for (let j = 0; j < validFileExtensions.length; j++) {
    const sCurExtension = validFileExtensions[j]
    if (
      file.name.substr(file.name.length - sCurExtension.length, sCurExtension.length).toLowerCase() ==
      sCurExtension.toLowerCase()
    ) {
      valid = true
      break
    }
  }
  return valid
}

export const sumOfArray = (arr) => {
  if (arr.length == 0) return 0
  const sum = arr.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue
  })
  return sum
}

export const numberToAlphabet = (number) => {
  switch (number) {
    case 0:
      return 'a'
    case 1:
      return 'b'
    case 2:
      return 'c'
    case 3:
      return 'd'
    case 4:
      return 'e'
    case 5:
      return 'f'
    default:
      break
  }
}

export function timezonedNow(timezone = 'Asia/Seoul') {
  return utcToZonedTime(new Date(), timezone)
}

export const groupBy = (data, key) => {
  return data.reduce(function (carry, el) {
    const group = el[key]
    if (carry[group] === undefined) {
      carry[group] = []
    }
    carry[group].push(el)
    return carry
  }, {})
}

// AWS BUCKET
const img_addr1 = 'd2mkevusy1mb28.cloudfront.net'
const img_addr2 = 'd38emex6h5e12i.cloudfront.net'

const convertResizableUrl = (url) => url.replace(img_addr1, img_addr2)

export const cleanUri = (url) => url.split('?')[0]
export const urlWidth = (url, w) => cleanUri(url ? convertResizableUrl(url) : '') + '?w=' + w
export const wordCountCalculate = (text) => {
  if (!text) {
    return 0
  }
  text = text.trim()
  return text.length > 0 ? text.split(/\s+/).length : 0
}

export const isPast = (timezone, time) => {
  return compareAsc(timezonedNow(timezone), new Date(time)) > 0
}

export const getTodoColor = (type) => {
  switch (type) {
    case TODO_TYPES.PREP:
      return 'success'
    case TODO_TYPES.REVIEW:
      return 'info'
    case TODO_TYPES.HOMEWORK:
      return 'secondary2'

    default:
      break
  }
}
//FOR TUTOR SCHEDULING PAGE
export const dayOfWeeks = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
export const timezoneOffset = new Date().getTimezoneOffset() / 60
export const getAdjustedDateTimeMMT = (year, month, date, hour, minute, second) => {
  return set(new Date(year, month, date, hour, minute, second), {
    year,
    month,
    date,
    hours: hour,
    minutes: minute,
    seconds: second,
  })
}
export const getYearFromStr = (time) => parseInt(time?.slice(0, 4))
export const getMonthFromStr = (time) => parseInt(time?.slice(5, 7)) - 1
export const getDayFromStr = (time) => parseInt(time?.slice(8, 10))
export const getHourFromStr = (time: string) => parseInt(time?.slice(11, 13))
export const getMinuteFromStr = (time: string) => parseInt(time?.slice(14, 16))

export const getAbsoluteDate = (time) => {
  return getAdjustedDateTimeMMT(getYearFromStr(time), getMonthFromStr(time), getDayFromStr(time), 0, 0, 0)
}
export const getAbsoluteDateTime = (time) => {
  return getAdjustedDateTimeMMT(
    getYearFromStr(time),
    getMonthFromStr(time),
    getDayFromStr(time),
    getHourFromStr(time),
    getMinuteFromStr(time),
    0
  )
}

export const daysOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const timeMilSec = {
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
}
export const t = (obj, lang) => {
  if (typeof obj == 'string') {
    return obj
  }
  const value = obj[localeIndex(lang)] === undefined ? obj[localeIndex(LOCALE_EN)] : obj[localeIndex(lang)]
  return value ? value : ''
}

export const getOgUrl = ({ lang, context }) => {
  const strippedPathName = context.pathname.includes('/[lang]')
    ? '/' + lang + context.pathname.replace('/[lang]', '')
    : context.pathname
  return `https://${RINGLE_DOMAIN}${strippedPathName}`
}

export const getLength = (str) => {
  if (typeof str !== 'string') {
    return 0
  }
  if (str.length == 0) {
    return 0
  }
  const temp = str
    .replace(/([\s]|(<\/?p>)|<br>|(&nbsp;))+/gm, ' ')
    .replace(/(<.*?>)/g, '')
    .trim()
  if (temp == '') {
    return 0
  }
  return temp.split(/\s+/)?.filter((item) => item != '')?.length
}

export const getRandom = (max, min) => {
  const randNum = Math.floor(Math.random() * (max - min + 1) + min)
  return randNum
}

export const avg = (arr) => {
  let sum = 0
  for (const item of arr) {
    sum += item
  }
  return sum / arr.filter((item) => item && item > 0).length || 0
}

export enum MATH_METHOD {
  ROUND = 'round',
  CEIL = 'ceil',
  FLOOR = 'floor',
}

export enum TUTOR_STATUS {
  APPLICATION = 0,
  ACTIVE = 1,
  NEW_TUTOR = 2,
  DORMANT = 3,
  READY = 4,
  ONE_STRIKE = 5,
  TWO_STRIKE = 6,
  OUT_THREE_STRIKE = 7,
  OUT_TEN_STRIKE = 8,
  HOLD = 9,
  BLACKLIST = 10,
  NOT_AGREED_POLICY = 11,
  UNDER_REVIEW = 12,
}

export const getTutorStatusString = (statusNum: number) => {
  switch (statusNum) {
    case TUTOR_STATUS.APPLICATION:
      return 'APPLICATION'
    case TUTOR_STATUS.ACTIVE:
      return 'ACTIVE'
    case TUTOR_STATUS.NEW_TUTOR:
      return 'NEW_TUTOR'
    case TUTOR_STATUS.DORMANT:
      return 'DORMANT'
    case TUTOR_STATUS.READY:
      return 'READY'
    case TUTOR_STATUS.ONE_STRIKE:
      return 'ONE_STRIKE'
    case TUTOR_STATUS.TWO_STRIKE:
      return 'TWO_STRIKE'
    case TUTOR_STATUS.OUT_THREE_STRIKE:
      return 'OUT_THREE_STRIKE'
    case TUTOR_STATUS.OUT_TEN_STRIKE:
      return 'OUT_TEN_STRIKE'
    case TUTOR_STATUS.HOLD:
      return 'HOLD'
    case TUTOR_STATUS.BLACKLIST:
      return 'BLACKLIST'
    case TUTOR_STATUS.NOT_AGREED_POLICY:
      return 'NOT_AGREED_POLICY'
    case TUTOR_STATUS.UNDER_REVIEW:
      return 'UNDER_REVIEW'
    default:
      return 'NOT_DEFINED'
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

enum DEVICE_TYPE {
  IOS,
  ANDROID,
}

export const getMonthNumbToStr = (monthNumb: number | any) => {
  switch (monthNumb) {
    case 1:
      return 'January'
    case 2:
      return 'February'
    case 3:
      return 'March'
    case 4:
      return 'April'
    case 5:
      return 'May'
    case 6:
      return 'June'
    case 7:
      return 'July'
    case 8:
      return 'August'
    case 9:
      return 'September'
    case 10:
      return 'October'
    case 11:
      return 'November'
    case 12:
      return 'December'
    default:
      return monthNumb
  }
}
export const getMonthStrToNumb = (monthString: string) => {
  switch (monthString) {
    case 'January':
      return 1
    case 'February':
      return 2
    case 'March':
      return 3
    case 'April':
      return 4
    case 'May':
      return 5
    case 'June':
      return 6
    case 'July':
      return 7
    case 'August':
      return 8
    case 'September':
      return 9
    case 'October':
      return 10
    case 'November':
      return 11
    case 'December':
      return 12
  }
}
