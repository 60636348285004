import { createContext, useContext } from 'react'
import { COLORS } from '../../../modules/vars'
import { TAB_TYPE } from '../interface'
// import { BUTTON_SIZE, BUTTON_TYPE } from '../types'

export interface TabContextState {
  tab: number
  setTab: React.Dispatch<React.SetStateAction<number>>
  type: TAB_TYPE[keyof TAB_TYPE]
  customColor?: (typeof COLORS)[keyof typeof COLORS]
}

export const TabContext = createContext<TabContextState | null>(null)

export const useTabState = () => {
  const context = useContext(TabContext)
  if (!context) {
    throw new Error('useTabState는 TabProvider 안에서만 사용 가능합니다.')
  }
  return context
}
