import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { COLORS } from '../../../modules/vars'
import { ButtonContext } from '../modules/ButtonStateContext'
import { BUTTON_SIZE, BUTTON_SIZE_KEY, BUTTON_TYPE, BUTTON_TYPE_KEY } from '../types'

interface IButtonMain extends PropsWithChildren {
  type?: BUTTON_TYPE_KEY
  size?: BUTTON_SIZE_KEY
  customBgColor?: (typeof COLORS)[keyof typeof COLORS]
  customBorderColor?: (typeof COLORS)[keyof typeof COLORS]
  isIconMode?: boolean
  isLoading?: boolean
  className?: string
  showSkeleton?: boolean
}

export type ButtonMainProps = IButtonMain & {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
}

const ButtonMainSkeketon = ({ clx }) => {
  return <div className={classNames(clx, 'animate-pulse rounded-md bg-gray-100 text-transparent')}>Button</div>
}
const ButtonMain = ({
  isLoading = false,
  isIconMode = false,
  type = BUTTON_TYPE.SOLID,
  size = BUTTON_SIZE.MD,
  customBgColor,
  customBorderColor = COLORS.GRAY300,
  className,
  onClick,
  disabled = false,
  showSkeleton = false,
  children,
}: ButtonMainProps) => {
  if (showSkeleton) {
    return <ButtonMainSkeketon clx={styles.size({ size, isIconMode, isLoading })} />
  }
  return (
    <ButtonContext.Provider value={{ size, disabled, isLoading, type }}>
      <button
        className={classNames(
          type !== BUTTON_TYPE.TEXT && styles.disabled({ disabled }),
          type !== BUTTON_TYPE.TEXT && styles.size({ size, isIconMode, isLoading }),
          type === BUTTON_TYPE.TEXT && `h-[44px]`,
          styles.border({ type, size }),
          !disabled && hoverStyles,
          !disabled && activeStyles,
          'flex w-full items-center justify-center',
          className
        )}
        onClick={onClick}
        style={{
          backgroundColor:
            !disabled &&
            type !== BUTTON_TYPE.TEXT &&
            (customBgColor ? customBgColor : type === BUTTON_TYPE.SOLID ? COLORS.PURPLE600 : COLORS.WHITE),
          borderColor: disabled ? null : customBorderColor,
        }}
        disabled={disabled}
      >
        {children}
      </button>
    </ButtonContext.Provider>
  )
}

export default ButtonMain

const styles = {
  disabled: ({ disabled }: { disabled: boolean }) => [disabled && `bg-gray-200`],
  size: ({ size, isIconMode, isLoading }: { size: BUTTON_SIZE_KEY; isIconMode: boolean; isLoading: boolean }) => [
    size === BUTTON_SIZE.XS && !isIconMode && !isLoading && `rounded-[5px] py-[6px] px-[8px]`,
    size === BUTTON_SIZE.XS && !isIconMode && isLoading && `rounded-[5px] py-[7.5px] px-[18.5px]`,
    size === BUTTON_SIZE.XS && isIconMode && `rounded-[4px] p-[4px]`,

    size === BUTTON_SIZE.SM && !isIconMode && !isLoading && `rounded-[5px] py-[8px] px-[16px]`,
    size === BUTTON_SIZE.SM && !isIconMode && isLoading && `rounded-[5px] py-[8px] px-[27.5px]`,
    size === BUTTON_SIZE.SM && isIconMode && `rounded-[4px] p-[10px]`,

    size === BUTTON_SIZE.MD && !isIconMode && !isLoading && `rounded-[7px] py-[12px] px-[28px]`,
    size === BUTTON_SIZE.MD && !isIconMode && isLoading && `rounded-[7px] py-[12px] px-[38.5px]`,
    size === BUTTON_SIZE.MD && isIconMode && `rounded-[11px] py-[11px] px-[10px]`,

    size === BUTTON_SIZE.LG && !isIconMode && !isLoading && `rounded-[7px] py-[16px] px-[24px]`,
    size === BUTTON_SIZE.LG && !isIconMode && isLoading && `rounded-[7px] py-[13px] px-[33px]`,

    size === BUTTON_SIZE.XL && !isIconMode && !isLoading && `rounded-[7px] py-[18px] px-[48px]`,
    size === BUTTON_SIZE.XL && !isIconMode && isLoading && `rounded-[7px] py-[15px] px-[57.5px]`,
  ],
  border: ({ type, size }: { type: BUTTON_TYPE_KEY; size: BUTTON_SIZE_KEY }) =>
    classNames(
      type === BUTTON_TYPE.OUTLINE && size === BUTTON_SIZE.XS && `border-[1px]`,
      type === BUTTON_TYPE.OUTLINE && size !== BUTTON_SIZE.XS && `border-[1.5px]`
    ),
  backgroundColor: ({ bgColor, type }: { bgColor: (typeof COLORS)[keyof typeof COLORS]; type: BUTTON_TYPE_KEY }) =>
    classNames(bgColor || type === BUTTON_TYPE.SOLID ? COLORS.PURPLE : COLORS.WHITE),
}

const hoverStyles = 'hover:bg-opacity-70 cursor-pointer'
const activeStyles = 'active:bg-opacity-50'
