// @ts-strict-ignore
// FIXME : plus, tutor 관련 로직 삭제 필요합니다.

import { urlParams } from './apis'

const urls = {
  teens: {
    landing: {
      helpcenter: '/teens/landing/blog#1',
      blog: {
        index: `/teens/landing/blog?type=blog`,
        tags: (tag) => `/teens/landing/blog/tags/${tag}`,
        detail: (slug) => `/teens/landing/blog/${slug}`,
        live: (slug) => `/teens/landing/blog/live/${slug}`,
        result: (keywords) => `/teens/landing/blog/result/${keywords}`,
      },
      promotion: `/teens/landing/promotion`,
      challenge: `/teens/landing/challenge`,
      contents: {
        material: {
          detail: (materialId) => `/teens/landing/contents/material/${materialId}`,
        },
      },
      invitation: {
        code: (code) => `/teens/landing/home?referralCode=${code}`,
      },
      trial_event: (code: any) =>
        code ? `/teens/landing/trial-event?referralCode=${code}` : `/teens/landing/trial-event`,
      correction: `/teens/landing/essay-editing-and-proofreading`,
      main: (rootUrl) => `/teens/landing/${rootUrl}`,
      home: `/teens/landing/home`,
      whatIsRingle: {
        index: `/teens/landing/what_is_ringle`,
      },
      process: `/teens/landing/how-it-works`,
      pricing: `/teens/landing/pricing`,
      faq: `/teens/landing/faq`,
      tutors: `/teens/landing/english-tutors`,
      topic: `/teens/landing/english-learning-materials`,
      review: {
        index: `/teens/landing/review`,
      },
      system: `/teens/landing/english-learning-platform`,
      b2b: `/teens/landing/b2b`,
      voucher: `/teens/landing/voucher`,
      team: {
        index: `/teens/landing/team`,
      },
      event: (slugId) => `/teens/landing/event/${slugId}`,
      adv: (slugId) => `/teens/adv/${slugId}`,
      eventDetail: (slugId) => `/teens/landing/event/detail/${slugId}`,
    },
    auth: {
      passwordReset: `/teens/auth/password-reset`,
      signIn: `/teens/auth/sign-in`,
      signUp: `/teens/auth/sign-up`, // "이메일로 회원가입" 페이지 (회원가입 랜딩 페이지 X)
      kakaoLoginCallback: `/teens/auth/kakao-login-callback`,
      new: `/teens/auth/new`, // 회원가입 랜딩 페이지.
      additionalInfo: `/teens/auth/additional-info`,
    },
    policy: {
      privacy: `/teens/policy/privacy`,
      terms: `/teens/policy/terms`,
      all: `/teens/policy/all`,
    },
    portal: {
      curriculum: {
        index: `/teens/portal/curriculum`,
        my: `/teens/portal/curriculum/my`,
        detail: (id) => `/teens/portal/curriculum/${id}`,
      },
      onboarding: {
        parent: {
          childAccount: `/teens/portal/onboarding/parent/child-account`,
          childInformation: `/teens/portal/onboarding/parent/child-information`,
          survey: `/teens/portal/onboarding/parent/survey`,
        },
        child: {
          index: `/teens/portal/onboarding/child`,
        },
      },
      challenge: `/teens/portal/challenge`,
      my_original: `teens/portal/my-original`,
      history: `/teens/portal/history/all`,
      home: `/teens/portal/home`,
      curation: {
        href: `/teens/portal/curation/[curationId]`,
        as: (curationId) => `/teens/portal/curation/${curationId}`,
      },
      welcome: `/teens/portal/welcome`,
      welcomeMobile: `/teens/portal/welcome/mobile`,
      myOriginal: {
        index: `teens/portal/my-original`,
        course: `teens/portal/my-original/course`,
        prev: `teens/portal/my-original/prevcourse`,
      },
      promotion: `/teens/portal/promotion`,
      profile: {
        index: `/teens/portal/profile`,
      },
      gift: {
        index: `/teens/portal/gift`,
      },
      pricing: {
        index: `/teens/portal/pricing`,
        success: (id) => `/teens/portal/pricing/success/${id}`,
        fail: (id) => `/teens/portal/pricing/fail/${id}`,
        detail: (id) => `/teens/portal/pricing/${id}`,
        progress: `/teens/portal/pricing/progress`,
      },
      materials: {
        view: {
          href: `/teens/portal/materials/[materialId]`,
          as: (materialId) => `/teens/portal/materials/${materialId}`,
        },
        list: `/teens/portal/materials`,
      },
      // 특강
      lectures: {
        view: {
          href: `/teens/portal/lectures/[slug]`,
          as: (slug) => `/teens/portal/lectures/${slug}`,
        },
        detail: {
          href: `/teens/portal/lectures/[slug]`,
          as: (slug, studyId) => `/teens/portal/lectures/${slug}/detail/${studyId}`,
        },
        list: `/teens/portal/lectures`,
        my: `/teens/portal/lectures/my`,
        finish: `/teens/portal/lectures/finish`,
      },
      correction: {
        request: `/teens/portal/correction/request`,
        waiting: `/teens/portal/correction/waiting`,
        completed: `/teens/portal/correction/completed`,
      },
      tutors: {
        index: `/teens/portal/tutors`,
        recommended: `/teens/portal/tutors/recommended`,
        my_tutors: `/teens/portal/tutors/my-tutors`,
        my: `/teens/portal/tutors/my-tutors/my`,
        experienced: `/teens/portal/tutors/my-tutors/experienced`,
        detail: {
          href: `/teens/portal/tutors/[tutorId]`,
          as: (id: string, tab?: number) => (tab ? `/portal/tutors/${id}?tab=${tab}` : `/portal/tutors/${id}`),
        },
        search: (keyword) => `/teens/portal/tutors/search?keyword=${keyword}`,
      },
      invite: {
        event: `/teens/portal/invite-event`,
      },
      webinar: {
        index: `/teens/portal/webinar`,
        detail: {
          href: `/teens/portal/webinar/[webinarId]`,
          as: (webinarId) => `/teens/portal/webinar/${webinarId}`,
        },
      },
      dailybrief: {
        index: `/teens/portal/dailybrief`,
      },
      help: {
        faq: `/teens/portal/help/faq`,
      },
      lessons: {
        schedule: `/teens/portal/lessons/schedule`,
        reservation: `/teens/portal/lessons/reservation`,
        scheduleEnter: `/teens/portal/lessons/schedule-enter`,
        unassigned: `/teens/portal/lessons/unassigned-lessons`,
        classroom: {
          as: (lessonId) => `/teens/portal/lessons/classroom/${lessonId}`,
        },
        upcomingLessons: `/teens/portal/lessons/upcoming-lessons`,
        upcomingLessonsFocus: `/teens/portal/lessons/upcoming-lessons?focus=true`,
        materialEdit: {
          href: `/teens/portal/lessons/upcoming-lessons/edit/material/[lessonId]`,
          as: (lessonId) => `/teens/portal/lessons/upcoming-lessons/edit/material/${lessonId}`,
        },
        timeEdit: {
          href: `/teens/portal/lessons/upcoming-lessons/edit/time/[lessonId]`,
          as: (lessonId) => `/teens/portal/lessons/upcoming-lessons/edit/time/${lessonId}`,
        },
        timeEditForAuto: {
          href: `/teens/portal/lessons/upcoming-lessons/edit/time-for-auto/[lessonId]`,
          as: (lessonId) => `/teens/portal/lessons/upcoming-lessons/edit/time-for-auto/${lessonId}`,
        },
        preparation: {
          href: `/teens/portal/lessons/preparation/[lessonId]`,
          as: (lessonId) => `/teens/portal/lessons/preparation/${lessonId}`,
        },
        review: `/teens/portal/lessons/review`,
        reviewFocus: (size = null) => `/teens/portal/lessons/review?focus=true${size ? `&size=${size}` : ''}`,
        homework: `/teens/portal/lessons/homework`,
        homeworkDetail: {
          as: (lessonId) => `/teens/portal/lessons/homework/${lessonId}`,
        },
        statistics: {
          index: `/teens/portal/lessons/statistics`,
        },
        reviewDetail: {
          as: (lessonId) => `/teens/portal/lessons/review/${lessonId}`,
        },
        evaluation: {
          as: (lessonId) => `/teens/portal/lessons/evaluation/${lessonId}`,
          issueReport: (lessonId) => `/teens/portal/lessons/evaluation/${lessonId}/issue-report`,
        },
      },
      todo: `/teens/portal/todo`,
      trial: {
        index: `/teens/portal/trial`,
      },
      dashboard: {
        gateway: `/teens/portal/dashboard/gateway`,
        home: `/teens/portal/dashboard`,
        credits: {
          history: `/teens/portal/dashboard/credits/history`,
          point: `/teens/portal/dashboard/credits/points`,
        },
        lessons: {
          pastLessons: `/teens/portal/dashboard/lessons/past-lessons`,
          upcomingLessons: `/teens/portal/dashboard/lessons/upcoming-lessons`,
        },
        childBasicProfile: {
          as: (id) => `/teens/portal/dashboard/settings/child/basic-profile/${id}`,
        },
        childProfile: {
          as: (id) => `/teens/portal/dashboard/settings/child/profile/${id}`,
        },
        childNotification: {
          as: (id) => `/teens/portal/dashboard/settings/child/notification/${id}`,
        },
        childPassword: {
          as: (id) => `/teens/portal/dashboard/settings/child/change-password/${id}`,
        },
        childTimezone: {
          as: (id) => `/teens/portal/dashboard/settings/child/timezone/${id}`,
        },
        mySettings: `/teens/portal/dashboard/settings/my/`,
        myNotification: `/teens/portal/dashboard/settings/my/notification`,
        myPassword: `/teens/portal/dashboard/settings/my/password`,
        childAccount: `/teens/portal/dashboard/child-account`,
        childInformation: `/teens/portal/dashboard/child-information`,
      },
      mypage: {
        basic: `/teens/portal/mypage/basic-profile`,
        invite: `/teens/portal/mypage/invite`,
        timezone: `/teens/portal/mypage/timezone`,
        language: `/teens/portal/mypage/change-language`,
        profile: `/teens/portal/mypage/profile`,
        notification: `/teens/portal/mypage/notification`,
        password: `/teens/portal/mypage/change-password`,
        point: `/teens/portal/mypage/point`,
        currentCredit: `/teens/portal/mypage/credit`,
        couponBox: `/teens/portal/mypage/coupon-box`,
        zoom: `/teens/portal/mypage/zoom`,
      },
      statistics: {
        all: `/teens/portal/history/all`,
        read: `/teens/portal/history/read`,
        listen: `/teens/portal/history/listen`,
        write: `/teens/portal/history/write`,
        speak: `/teens/portal/history/speak`,
      },
    },
  },
  tutor: {
    policy: {
      privacy: `/tutor/policy/privacy`,
    },
    auth: {
      signUp: `/tutor/auth/sign-up`,
    },
    landing: {
      home: `/tutor/landing/home`,
      about: `/tutor/landing/about-us`,
      blog: {
        index: `/tutor/landing/blog`,
      },
    },
  },
  // mobile web
  webview: {
    policy: {
      privacy: `/webview/policy/privacy`,
      terms: `/webview/policy/terms`,
      all: `/webview/policy/all`,
      lessonPolicy: `/webview/policy/lesson-policy`,
      ipPolicy: `/webview/policy/ip-policy`,
    },
    leaveRingle: `/webview/appscreens/leave-ringle`,
    welcome: `/webview/welcome`,
    home: {
      index: `/webview/home`,
      todo: `/webview/home?activity=TodoListActivity`,
    },
    mypage: {
      point: `/webview/mypage/point`,
      invite: `/webview/mypage/invite`,
      basic: `/webview/mypage/basic-profile`,
      timezone: `/webview/mypage/timezone`,
      language: `/webview/mypage/change-language`,
      profile: `/webview/mypage/profile`,
      intro: `/webview/mypage/intro`,
      introDetail: `/webview/mypage/intro-detail`,
      introResult: `/webview/mypage/intro-result`,
      notification: `/webview/mypage/notification`,
      password: `/webview/mypage/change-password`,
      currentCredit: `/webview/mypage/credit`,
      issurance: {
        href: `/webview/mypage/certificate/issurance/[certificateId]`,
        as: (certificateId) => `/webview/mypage/certificate/issurance/${certificateId}`,
      },
      creditDetail: {
        href: `/webview/mypage/credit/details/[creditId]`,
        as: (creditId: number) => `/webview/mypage/credit/details/${creditId}`,
      },
      attendance: {
        href: `/webview/mypage/certificate/currentCredit/[id]`,
        as: (id) => `/webview/mypage/certificate/currentCredit/${id}`,
      },
      issueCredit: {
        href: `/webview/mypage/certificate/[purchaseId]`,
        as: (purchaseId, tabId?: number) => `/webview/mypage/certificate/${purchaseId}&tabId=${tabId}`,
      },
      extendCoupons: (creditId: number) => `/webview/credit/extend-coupons/${creditId}`,
      couponBox: `/webview/mypage/coupon-box`,
      account: `/webview/account`,
      zoom: `/webview/zoom`,
      leave: `/webview/mypage/leave`,
    },
    pricing: {
      index: `/webview/pricing`,
      success: (id) => `/webview/pricing/success/${id}`,
      fail: (id) => `/webview/pricing/fail/${id}`,
      detail: (id) => `/webview/pricing/${id}`,
      redirect: `/webview/pricing/redirect`,
    },
    notice: {
      index: `/webview/notice`,
      detail: (slug) => `/webview/blog/${slug}`,
    },
    helpCenter: {
      index: `/webview/help-center`,
      detail: (slug) => `/webview/blog/${slug}`,
    },
    tutors: {
      list: (tab: string) => `/webview/tutors?tab=${tab}`,
      detail: {
        href: `/webview/tutors/[id]`,
        as: (id: string, tab?: number) => `/webview/tutors?activity=TutorDetailActivity&tutorId=${id}&tab=${tab}`,
      },
      my: `/webview/tutors/my`,
      search: (keyword: string) => `/webview/tutors?activity=TutorSearchActivity&keyword=${keyword}`,
      experienced: `/webview/tutors/experienced`,
    },
    credits: {
      list: `/webview/credits?activity=CreditListActivity`,
      detail: `/webview/credits?activity=CreditDetailActivity`,
      lessonBook: (id) => `/webview/credits?activity=CreditLessonBookActivity&id=${id}`,
    },
    challenge: {
      index: `/webview/challenge`,
      detail: (challengeId) => `/webview/challenge?activity=ChallengeDetailActivity&challengeId=${challengeId}`,
      option: {
        detail: (challengeId) => `/webview/challenge?activity=OptionSelectActivity&challengeId=${challengeId}`,
      },
      badges: `/webview/challenge?activity=MyBadgesActivity`,
      ended: `/webview/challenge?activity=EndedChallengeActivity`,
      pricing: `/webview/challenge?activity=ChallengePricingMainActivity`,
    },

    event: {
      index: `/webview/event`,
      list: `/webview/event?activity=EventListActivity`,
      detail: (slug) => `/webview/event?activity=EventDetailActivity&slug=${slug}`,
    },
    blog: {
      index: `/webview/blog`,
      detail: (slug) => `/webview/blog?activity=BlogDetailActivity&slug=${slug}`,
    },
    ai_analysis: {
      statistics: {
        index: `/webview/lessons?activity=AIStatisticActivity`,
      },
    },
    caf: {
      statistic: {
        index: (start?: string, end?: string) => `/webview/caf/statistic?startDate=${start}&endDate=${end}`,
        lesson: (start: string, end: string) => `/webview/caf/statistic/lessons/list?startDate=${start}&endDate=${end}`,
        highlight: {
          list: (start: string, end: string, pinned?: boolean) =>
            `/webview/caf/statistic/highlight/list${urlParams({ startDate: start, endDate: end, pinned })}`,
          tag: (tagId: number, start: string, end: string) =>
            `/webview/caf/statistic/highlight/${tagId}?startDate=${start}&endDate=${end}`,
        },
      },
      analysis: {
        index: (type: string, componentId: number) => `/webview/caf/analysis/${type}/${componentId}`,
        detail: (type: string, metricId: number, errorTag: number) =>
          `/webview/caf/analysis/${type}/detail/${metricId}?error_tag=${errorTag}`,
        level: (status: string, level: number) => `/webview/caf/analysis/caf_level?status=${status}&level=${level}`,
      },
      highlight: {
        detail: (highlightId: number, tag: number) => `/webview/caf/highlight/${highlightId}?tag=${tag}`,
        list: (start: string, end: string) => `/webview/caf/highlight/list?startDate=${start}&endDate=${end}`,
        lesson_list: (lessonId: number, level: number, pinned?: boolean) =>
          `/webview/caf/highlight/list/${lessonId}${urlParams({ level, pinned })}`,
        tip: (tag) => `/webview/caf/highlight/ringle_tip?tag=${tag}`,
      },
      highlight_edit: (lessonId: number) => `/webview/caf/highlight-edit/${lessonId}`,
    },
    lessons: {
      index: ({ params }: any) => {
        if (params) {
          return `/webview/lessons` + urlParams(params)
        } else {
          return `/webview/lessons`
        }
      },
      homework: {
        detail: (lessonId) => `/webview/lessons?activity=HomeworkDetailActivity&lessonId=${lessonId}`,
      },
      upcoming_list: (childId) =>
        childId
          ? `/webview/lessons?activity=UpcomingLessonListActivity&childId=${childId}`
          : `/webview/lessons?activity=UpcomingLessonListActivity`,
      past_list: (childId) =>
        childId
          ? `/webview/lessons?activity=PastLessonListActivity&childId=${childId}`
          : `/webview/lessons?activity=PastLessonListActivity`,
      schedule: `/webview/lessons?activity=LessonScheduleActivity`,
      lesson_enter: (lessonId) => `/webview/lessons?activity=LessonEnterBeforeActivity&lessonId=${lessonId}`,
      lesson_cancle: (lessonId, childId) =>
        `/webview/lessons?activity=LessonCancelActivity&lessonId=${lessonId}&childId=${childId}`,
      lesson_style: (lessonId) => `/webview/lessons?activity=LessonStyleActivity&lessonId=${lessonId}`,
      tutor_matching_option: (lessonId) =>
        `/webview/lessons?activity=TutorMatchingOptionChangeActivity&lessonId=${lessonId}`,
      lesson_popup: (lessonId) => `/webview/lessons?activity=LessonReminderActivity&lessonId=${lessonId}`,
      preparation: (lessonId: string) => `/webview/lessons?activity=LessonsPreparationActivity&lessonId=${lessonId}`,
      preparation_in_review: (lessonId: string, isReview: boolean) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&isReview=${isReview}`,
      preparation_insight: (lessonId: string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_question: (lessonId: string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_upload: (lessonId: string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_over: (lessonId: string) =>
        `/webview/lessons?activity=LessonPreparationOverActivity&lessonId=${lessonId}`,
      preparation_answer_change: () => `/webview/lessons?activity=AnswerModifyActivity`,
      evaluation: (lessonId: number) => `/webview/lessons?activity=LessonEvaluationActivity&lessonId=${lessonId}`,
      materialSelect: (lessonId, materialId, childId) =>
        `/webview/lessons?activity=LessonMaterialChangeActivity&lessonId=${lessonId}&materialId=${materialId}&childId=${childId}`,
      // timeSelect: (lessonId) => `/webview/lessons?activity=LessonTimeChangeActivity&lessonId=${lessonId}`,
      review: {
        detail: (lessonId) => `/webview/lessons?activity=ReviewDetailActivity&lessonId=${lessonId}`,
        detailAsType: (lessonId, type: 'caf' | 'feedback' | 'note' | 'record') =>
          `/webview/lessons?activity=ReviewDetailActivity&lessonId=${lessonId}&type=${type}`,
        lessonSetting: (lessonId) => `/webview/lessons?activity=ReviewLessonStyleActivity&lessonId=${lessonId}`,
      },
      material_detail: (materialId) => `/webview/lessons?activity=MaterialViewActivity&materialId=${materialId}`,
    },
    schedule: {
      index: () => `/webview/schedule`, // default: TutorAndtimeSelectionActivity
      timeFirst: () => `/webview/schedule?activity=TimeSelectionActivity`,
      tutorFirst: (tutorId?: any) =>
        tutorId
          ? `/webview/schedule?activity=TutorAndTimeSelectionActivity&tutorId=${tutorId}`
          : `/webview/schedule?activity=TutorAndTimeSelectionActivity`,
      unassigned: `/webview/schedule?activity=UnassignedLessonsActivity`,
      unassignedCredit: `/webview/schedule?activity=UnassignedCreditSelectionActivity`,
    },
    materials: {
      index: `/webview/materials`,
      detail: (materialId) => `/webview/materials?activity=MaterialsDetailActivity&materialId=${materialId}`,
      category: (categoryId) => `/webview/materials?activity=MaterialsCategoryActivity&categoryId=${categoryId}`,
      search: (keyword: string) => `/webview/materials?activity=MaterialSearchActivity&keyword=${keyword}`,
      filter: (categoryId: number) => `/webview/materials?activity=FilteredMaterialsActivity&categoryId=${categoryId}`,
    },
    lectures: {
      index: `/webview/lectures`,
      detail: (slug) => `/webview/lectures?activity=LecturesDetailActivity&lectureId=${slug}`,
      replayDetail: (slug, id) =>
        `/webview/lectures?activity=LecturesReplayDetailActivity&lectureId=${slug}&studyId=${id}`,
    },

    study: {
      readTogether: {
        detail: (slug) => `/webview/study/read-together?activity=ReadTogetherDetailActivity&slug=${slug}`,
        success: (purchaseId) =>
          `/webview/study/read-together?activity=ReadTogetherPricingSuccessActivity&purchaseId=${purchaseId}`,
        fail: (purchaseId, errorMsg) =>
          `/webview/study/read-together?activity=ReadTogetherPricingFailActivity&purchaseId=${purchaseId}&errorMsg=${errorMsg}`,
      },
      readAlone: {
        detail: (slug) => `/webview/study/read-alone?activity=ReadAloneDetailActivity&slug=${slug}`,
        success: (purchaseId) =>
          `/webview/study/read-alone?activity=ReadAlonePricingSuccessActivity&purchaseId=${purchaseId}`,
        fail: (purchaseId, errorMsg) =>
          `/webview/study/read-alone?activity=ReadAlonePricingFailActivity&purchaseId=${purchaseId}&errorMsg=${errorMsg}`,
      },

      index: `/webview/study`,
      dailyActivity: (date) => `/webview/study?activity=StudyDailyActivity&date=${date}`,
      biteSizedFeedbackLanding: `/webview/study?activity=StudyBiteSizedFeedbackLandingActivity`,
      userReport: (setId) => `/webview/study?activity=StudyUserFeedbackActivity&setId=${setId}`,
    },
    myRingle: {
      index: `/webview/my-ringle`,
      aiAnalysis: `/webview/my-ringle/ai-analysis`,
      jobInput: `/webview/my-ringle/job-input`,
      prevReadTogether: `/webview/study/read-together?activity=PrevReadTogetherDetailActivity`,
      prevReadAlone: `/webview/study/read-alone?activity=PrevReadAloneDetailActivity`,
      badges: `/webview/my-ringle?activity=MyBadgesActivity`,
      achievements: `/webview/my-ringle?activity=AchievementsActivity`,
      materials: `/webview/materials?activity=MaterialsBookmarkActivity`,
      experiencedMaterials: `/webview/materials?activity=MaterialsPrevActivity`,
    },
    trial: {
      schedule: (childId) => `/teens/portal/trial?child_id=${childId}`,
    },
  },
}
export default urls
