import { createContext, useContext } from 'react'
import { CHECKBOX_CHECKED, CHECKBOX_POSITION, CHECKBOX_SHAPE, Selected, SelectedStateAction } from '../types'

export interface DefaultValueState {
  shape: CHECKBOX_SHAPE
  type: CHECKBOX_CHECKED
  buttonPosition: CHECKBOX_POSITION
  disabledAll: boolean
  selectedList: Selected[]
  setSelectedList: SelectedStateAction
  checkedColor?: string
}

export const CheckboxStateContext = createContext<DefaultValueState | null>(null)

export const useCheckboxState = () => {
  const context = useContext(CheckboxStateContext)
  if (!context) {
    throw new Error('useCheckboxState는 CheckboxProvider 안에서만 사용 가능합니다.')
  }
  return context
}
