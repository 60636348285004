import { BuilderButton } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { COLORS } from '../../modules/vars'
import { descriptionClassName, headerClassName, subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionSizes = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  return (
    <section id="Sizes">
      <div className={headerClassName}>Sizes</div>
      <div className={descriptionClassName}>
        버튼 사이즈별 상하 Padding과 Text Size는 고정입니다. <br />
        디자인 환경에 따라 좌우 Padding을 변경하여 사용합니다. 단, 각 사이즈별 좌우 Padding 최소 사이즈는 고정입니다.{' '}
        <br />
        <span className="text-purple-600">
          *시스템에 등록된 좌우 Padding이 최소 사이즈입니다. 필요 시 가로 사이즈를 늘릴 수 있으나, 줄이는 것을 금합니다.
        </span>
        <br />
        <span className="text-green-600">
          *For developer : 겉에 button을 둘러싸는 상위 컴포넌트(container)의 넓이에 따라 버튼 크기를 조절할 수 있습니다.
          Padding을 늘리는 부분도 겉 container의 넓이로 조절해주면 됩니다.
        </span>
      </div>
      <div className={subHeaderClassName}>기본 사이즈</div>
      <div className="flex flex-col items-start">
        <div>
          <p>xs</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.XS}
              customBgColor={COLORS.YELLOW500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.XS}
              customBorderColor={COLORS.YELLOW900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XS}
              type={BUILDER_BUTTON_TYPE.SOLID}
              disabled
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XS}
              type={BUILDER_BUTTON_TYPE.SOLID}
              isLoading
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>sm</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.SM}
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.SM}
              customBorderColor={COLORS.YELLOW900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              disabled
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              isLoading
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>md</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.MD}
              customBgColor={COLORS.YELLOW500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.MD}
              customBorderColor={COLORS.YELLOW900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              disabled
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              isLoading
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>lg</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.LG}
              customBgColor={COLORS.YELLOW}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.LG}
              customBorderColor={COLORS.YELLOW900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.LG}
              type={BUILDER_BUTTON_TYPE.SOLID}
              disabled
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.LG}
              type={BUILDER_BUTTON_TYPE.SOLID}
              isLoading
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
        <div>
          <p>xl</p>
          <div className="flex gap-[10px]">
            <BuilderButton
              type={BUILDER_BUTTON_TYPE.SOLID}
              size={BUILDER_BUTTON_SIZE.XL}
              customBgColor={COLORS.YELLOW700}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              size={BUILDER_BUTTON_SIZE.XL}
              customBorderColor={COLORS.YELLOW900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.YELLOW900}>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              disabled
              textWithIcon
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>

            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              isLoading
              customBgColor={COLORS.YELLOW300}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
      </div>

      <div className="mt-[10px]">
        <div className={subHeaderClassName}>사이즈 조절</div>
        <div className="flex items-center gap-[10px]">
          <div className="w-[200px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.SM}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE200}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label customTxtColor={COLORS.BLACK}>버튼 사이즈 SM</BuilderButton.Label>
            </BuilderButton>
          </div>
          <div className="w-[300px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.MD}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE500}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>버튼 사이즈 MD</BuilderButton.Label>
            </BuilderButton>
          </div>
          <div className="w-[200px]">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.LG}
              type={BUILDER_BUTTON_TYPE.SOLID}
              customBgColor={COLORS.BLUE900}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>버튼 사이즈 LG</BuilderButton.Label>
            </BuilderButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSizes

const SectionContainer = (props) => <div {...props} className="flex flex-col my-[30px]"></div>
