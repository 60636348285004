import { createContext, useContext } from 'react'
import { DSPROMOPOPUP_SIZE_TYPE } from '../ui/DSPromoPopupMain'

interface DSPromoPopupContextProps {
  size: DSPROMOPOPUP_SIZE_TYPE
}

export const DSPromoPopupContext = createContext<DSPromoPopupContextProps>({
  size: null,
})

export const useDSPromoPopupState = () => {
  const context = useContext(DSPromoPopupContext)

  if (!context) throw new Error('Not in DSModalContext.')

  return context
}
