// legacy radio button -> 이 파일보다는 DSRadio 사용 권장

// @ts-strict-ignore
import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import useTranslation from '../../modules/i18n/useTranslation'
import Div from '../basic/Div'

type IconCirclePropsType = {
  checked: boolean
  disabled: boolean
  readOnly?: boolean
  clx?: any
  style?: any
}

const IconCircle = ({ clx, style, checked, disabled, readOnly }: IconCirclePropsType) => {
  return (
    <div style={style} className={classNames(clx, 'flex items-center justify-center px-[12px]')}>
      <span
        className={classNames(
          { 'cursor-pointer': !readOnly },
          [checked ? (disabled ? 'border-gray-500 bg-white' : 'border-primary') : 'border-gray-500 bg-white'],
          'border-1 flex h-[18px] w-[18px] items-center justify-center rounded-full'
        )}
      >
        {checked && (
          <div
            className={classNames(
              { 'border-primary': !disabled },
              { 'border-gray-700': disabled },
              { 'bg-primary': !disabled },
              { 'bg-gray-500': disabled },
              'h-[12px] w-[12px] rounded-full'
            )}
          />
        )}
      </span>
    </div>
  )
}

const RadioGroup2 = (props: {
  settings
  formTitle?
  selected
  setSelected
  circleRight?
  horizontal?
  background?
  cardShape?
  clx?
  clxComp?
  readOnly?
  iconClx?
  iconStyle?
  selectedObjShow?
}) => {
  const {
    settings,
    formTitle,
    selected,
    setSelected,
    circleRight,
    horizontal,
    background,
    cardShape,
    clx,
    clxComp,
    readOnly,
    iconClx,
    iconStyle,
    selectedObjShow = false,
  } = props

  const { t } = useTranslation()

  const isCircleRight = circleRight
  const isBackground = background
  const isCardShape = cardShape
  const isHorizontal = horizontal

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">{formTitle}</RadioGroup.Label>
      <div className={classNames({ flex: isHorizontal }, 'items-center -space-y-px rounded-md bg-white')}>
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            className="mr-8 w-full focus:outline-none"
            key={settingIdx}
            value={setting.value}
            disabled={setting.disabled}
          >
            {({ active, checked }) => (
              <Div
                relative
                flex
                cursorPointer={!readOnly}
                hFull
                wFull
                clxComp={isFunction(clxComp) && clxComp({ checked })}
                clx={[
                  clx,
                  'focus:outline-none',
                  checked ? 'z-10' : '',
                  isCardShape ? 'border rounded m-4 p-16 w-full' : 'py-8',
                  isBackground && checked ? 'bg-gray-100' : '',
                ]}
              >
                {!isCircleRight && (
                  <IconCircle
                    checked={checked}
                    disabled={setting.disabled || readOnly}
                    readOnly={readOnly}
                    clx={iconClx}
                    style={iconStyle}
                  />
                )}
                <div className="ml-[3px] flex flex-1 flex-col">
                  {setting.name && (
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        'font-regular flex h-full items-center',
                        { 'text-gray-900': !checked },
                        { 'text-gray-600': setting.disabled || (readOnly && !checked) },
                        isCardShape ? 'text-d8' : 'text-sBody'
                      )}
                    >
                      <div className="keep-all">{setting.name}</div>
                    </RadioGroup.Label>
                  )}
                  {setting.description && (
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        // checked ? 'text-primary-700' : 'text-gray-500',
                        'block text-sm text-gray-600'
                      )}
                    >
                      {setting.description}
                    </RadioGroup.Description>
                  )}
                  {setting.obj &&
                    (selectedObjShow ? selected == setting.value ? <>{setting.obj}</> : null : <>{setting.obj}</>)}
                </div>
                {isCircleRight && <IconCircle checked={checked} disabled={setting.disabled} />}
              </Div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default RadioGroup2
