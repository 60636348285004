// @ts-strict-ignore
import globeIcon from '@iconify/icons-bi/globe'
import bxDotsVerticalRounded from '@iconify/icons-bx/bx-dots-vertical-rounded'
import bxGlobeIcon from '@iconify/icons-bx/bx-globe'
import bxHelpCircle from '@iconify/icons-bx/bx-help-circle'
import bxRadioCircleMarked from '@iconify/icons-bx/bx-radio-circle-marked'
import bxTransfer from '@iconify/icons-bx/bx-transfer'
import bxsStar from '@iconify/icons-bx/bxs-star'
import feExternalLinkAlt from '@iconify/icons-fa-solid/external-link-alt'
import feArrowDown from '@iconify/icons-fe/arrow-down'
import feArrowLeft from '@iconify/icons-fe/arrow-left'
import feArrowRight from '@iconify/icons-fe/arrow-right'
import feArrowUp from '@iconify/icons-fe/arrow-up'
import clockIcon from '@iconify/icons-fe/clock'
import feClose from '@iconify/icons-fe/close'
import featherAlertCircle from '@iconify/icons-feather/alert-circle'
import featherBookOpen from '@iconify/icons-feather/book-open'
import feahterChveronDown from '@iconify/icons-feather/chevron-down'
import feahterChveronLeft from '@iconify/icons-feather/chevron-left'
import feahterChveronRight from '@iconify/icons-feather/chevron-right'
import feahterChveronUp from '@iconify/icons-feather/chevron-up'
import featherClock from '@iconify/icons-feather/clock'
import featherUser from '@iconify/icons-feather/user'
import questionCircle from '@iconify/icons-la/question-circle'
import { Icon, IconProps } from '@iconify/react'

const ret = (icon, props): React.ReactElement<IconProps, string | React.JSXElementConstructor<any>> => {
  const { inline, size, color, style, hFlip, vFlip, rotate, className } = props
  // <Icon icon={icon} {...(size && { height: size, width: size })} style={style} {...others} />
  return (
    <Icon
      icon={icon}
      color={color}
      width={size}
      height={size}
      rotate={rotate}
      hFlip={hFlip}
      vFlip={vFlip}
      inline={inline}
      style={style}
      className={className}
    />
  )
}

interface PropsType extends Partial<IconProps> {
  size?: number
  onClick?: () => void
}

type IconType = (props: PropsType) => React.ReactElement<IconProps, string | React.JSXElementConstructor<any>>

export const ClockIcon: IconType = (props) => ret(clockIcon, props)
export const BxGlobeIcon: IconType = (props) => ret(bxGlobeIcon, props)
export const FeExternalLinkAlt: IconType = (props) => ret(feExternalLinkAlt, props)
export const FeArrowUp: IconType = (props) => ret(feArrowUp, props)
export const FeArrowDown: IconType = (props) => ret(feArrowDown, props)
export const FeArrowLeft: IconType = (props) => ret(feArrowLeft, props)
export const FeArrowRight: IconType = (props) => ret(feArrowRight, props)
export const BxTransfer: IconType = (props) => ret(bxTransfer, props)
export const FeatherUser: IconType = (props) => ret(featherUser, props)
export const BxsStar: IconType = (props) => ret(bxsStar, props)
export const BxRadioCircleMarked: IconType = (props) => ret(bxRadioCircleMarked, props)
export const BxDotsVerticalRounded: IconType = (props) => ret(bxDotsVerticalRounded, props)
export const FeClose: IconType = (props) => ret(feClose, props)
export const FeatherClock: IconType = (props) => ret(featherClock, props)
export const BxHelpCircle: IconType = (props) => ret(bxHelpCircle, props)
export const FeatherBookOpen: IconType = (props) => ret(featherBookOpen, props)
export const FeatherChevronLeft: IconType = (props) => ret(feahterChveronLeft, props)
export const FeatherChevronRight: IconType = (props) => ret(feahterChveronRight, props)
export const FeatherChevronDown: IconType = (props) => ret(feahterChveronDown, props)
export const FeatherChevronUp: IconType = (props) => ret(feahterChveronUp, props)
export const FeatherAlertCircle: IconType = (props) => ret(featherAlertCircle, props)
export const QuestionCircle: IconType = (props) => ret(questionCircle, props)
export const BiGlobeIcon: IconType = (props) => ret(globeIcon, props)
