import {
  BUTTON_SIZE,
  BUTTON_TYPE,
  COLORS,
  DSButton,
  DSInput,
  DSRadio,
  Switch,
  useToast,
} from '@repo/design-system/index'
import { QIcon_repeat } from '@repo/icons/index'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CodeSnippet from '../CodeSnippet'
import {
  ControlClassName,
  anchorClassName,
  descriptionClassName,
  headerClassName,
  rowContainerClassName,
  subHeaderClassName,
} from '../style'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Toast = () => {
  return (
    <div>
      <p id="Toast" className={headerClassName}>
        Toast
      </p>
      <p className={descriptionClassName}>
        액션 또는 변경의 결과로 인한 상태 변화를 사용자에게 알립니다.
        <br /> 일반적으로 시스템 상태를 바꾸는 액션에 대한 피드백을 제공하기 위해 사용됩니다. Toast는 사용자의 현재
        작업을 방해하지 않는 것이 중요합니다. <li>방해없이 정보 메세지를 전달합니다.</li>{' '}
        <li>일시적으로 나타나고 사용자 개입 필요없이 스스로 사라집니다. </li>
        <li>상황에 따라 사용자에게 추가 정보를 제공합니다.</li>
      </p>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Legacy = () => {
  return (
    <div>
      <p id="Legacy" className={headerClassName}>
        Legacy Toast
      </p>
      <p className={descriptionClassName}>
        Legacy Toast를 사용하고 있는 곳에서 useToast를 사용하도록 변경이 필요합니다.
        <br />
        plus의 32개 정도 파일에 존재합니다.
      </p>
      <DSButton
        type={BUTTON_TYPE.OUTLINE}
        size={BUTTON_SIZE.MD}
        className="max-w-max mt-[16px]"
        onClick={() => toast('This toast is legacy')}
      >
        <DSButton.Label>레거시 토스트</DSButton.Label>
      </DSButton>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const States = () => {
  const { toastInfo, toastError, toastSuccess } = useToast()

  const [value1, setValue1] = useState<string>('')
  const [value2, setValue2] = useState<string>('')
  const [value3, setValue3] = useState<string>('')
  const [value4, setValue4] = useState<string>('')

  const successDescription = value1 || 'Enter a description here'
  const errorDescription = value2 || 'Enter a description here'
  const infoDescription = value3 || 'Enter a description here'
  const multiDescription = value4 || 'Please enter a description here in order to notify users what happened.'

  return (
    <div>
      <p id="States" className={headerClassName}>
        States
      </p>
      <p className={descriptionClassName}>
        모든 Chip의 텍스트 굵기나 텍스트 사이즈의 커스텀을 금합니다.
        <br />
        Choice와 Filter는 기본적으로 잡혀있는 컬러에서 커스텀을 최대한 지양합니다. <br />
        모바일 환경에서의 토스트를 테스트 하시려면{' '}
        <a className="text-purple-500 underline" href="https://developer.chrome.com/docs/devtools/device-mode/">
          https://developer.chrome.com/docs/devtools/device-mode/
        </a>
        를 참고하여 모바일 모드로 전환하세요
        <br /> <br />
      </p>

      <div className="flex flex-row items-center w-full">
        <div className="mr-[36px] flex flex-col gap-[28px]">
          <div className="flex flex-row gap-[16px] max-h-max items-center">
            <div className="min-w-[150px]">
              <DSButton
                type={BUTTON_TYPE.OUTLINE}
                size={BUTTON_SIZE.MD}
                onClick={() => {
                  toastSuccess(successDescription)
                }}
              >
                <DSButton.Label>Success</DSButton.Label>
              </DSButton>
            </div>
            <DSInput
              type="text"
              upperText="Toast Description"
              placeholder="Enter a description here"
              value={value1}
              setValue={setValue1}
              name={'success_toast_description'}
              clearable
              size="sm"
              required={false}
            />
          </div>
          <div className="flex flex-row gap-[16px] max-h-max items-center">
            <div className="min-w-[150px]">
              <DSButton
                type={BUTTON_TYPE.OUTLINE}
                size={BUTTON_SIZE.MD}
                onClick={() => {
                  toastError(errorDescription)
                }}
              >
                <DSButton.Label>Error</DSButton.Label>
              </DSButton>
            </div>
            <DSInput
              type="text"
              upperText="Toast Description"
              placeholder="Enter a description here"
              value={value2}
              setValue={setValue2}
              name={'error_toast_description'}
              clearable
              size="sm"
              required={false}
            />
          </div>
          <div className="flex flex-row gap-[16px] max-h-max items-center">
            <div className="min-w-[150px]">
              <DSButton
                type={BUTTON_TYPE.OUTLINE}
                size={BUTTON_SIZE.MD}
                onClick={() => {
                  toastInfo(infoDescription)
                }}
              >
                <DSButton.Label>Info</DSButton.Label>
              </DSButton>
            </div>
            <DSInput
              type="text"
              upperText="Toast Description"
              placeholder="Enter a description here"
              value={value3}
              setValue={setValue3}
              name={'info_toast_description'}
              clearable
              size="sm"
              required={false}
            />
          </div>
          <div className="flex flex-row gap-[16px] max-h-max items-center">
            <div className="min-w-[150px]">
              <DSButton
                type={BUTTON_TYPE.OUTLINE}
                size={BUTTON_SIZE.MD}
                onClick={() => {
                  toastInfo(multiDescription)
                }}
              >
                <DSButton.Label>Multiline</DSButton.Label>
              </DSButton>
            </div>
            <DSInput
              type="text"
              upperText="Toast Description"
              placeholder="Enter a description here"
              value={value4}
              setValue={setValue4}
              name={'multiline_toast_description'}
              clearable
              size="sm"
              required={false}
            />
          </div>
        </div>
        <DSButton
          type={BUTTON_TYPE.SOLID}
          size={BUTTON_SIZE.SM}
          className="max-w-max max-h-max flex flex-row gap-[4px]"
          onClick={() => {
            setValue1('') // 빈 문자열로 초기화
            setValue2('') // 빈 문자열로 초기화
            setValue3('') // 빈 문자열로 초기화
            setValue4('') // 빈 문자열로 초기화
          }}
        >
          <DSButton.Icon alt="repeat-icon">
            <QIcon_repeat width={16} height={16} color={COLORS.WHITE} />
          </DSButton.Icon>
          <DSButton.Label>초기화</DSButton.Label>
        </DSButton>
      </div>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const ExampleCode = () => {
  const { toastInfo, toastError, toastSuccess } = useToast()

  const [state, setState] = useState<'Success' | 'Error' | 'Info'>('Success')
  const [isMultiline, setIsMultiline] = useState<boolean>(false)
  const [content, setContent] = useState<string>('Enter a description here')

  useEffect(() => {
    if (isMultiline) {
      setContent('Please enter a description here in order to notify users what happened.')
    } else {
      setContent('Enter a description here')
    }
  }, [isMultiline])

  return (
    <div>
      <section className="flex flex-col w-[560px] border-[1.5px] rounded-[8px] p-[20px] bg-gray-50">
        <div className={subHeaderClassName}>Controls</div>

        <div className="flex flex-row">
          <div className={ControlClassName}>state*</div>
          <DSRadio controlSelected={[state, setState]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="Success">success</DSRadio.Item>
            <DSRadio.Item value="Error">error</DSRadio.Item>
            <DSRadio.Item value="Info">info</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>multiline{state !== 'Info' && isMultiline && ' (최대한 지양)'}</div>
          <Switch checked={isMultiline} setChecked={setIsMultiline} />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>content*</div>
          <DSInput
            type="text"
            placeholder="Enter a description here"
            value={content}
            setValue={setContent}
            name={'content'}
            clearable
            size="sm"
            required
            className="bg-gray-50"
          />
        </div>
      </section>

      <section className="flex flex-row w-[560px] justify-center my-[40px]">
        <div className="min-w-[150px]">
          <DSButton
            type={BUTTON_TYPE.OUTLINE}
            size={BUTTON_SIZE.MD}
            onClick={() => {
              switch (state) {
                case 'Success':
                  toastSuccess(content)
                  break
                case 'Error':
                  toastError(content)
                  break
                case 'Info':
                  toastInfo(content)
                  break
                default:
                  break
              }
            }}
          >
            <DSButton.Label>Toast</DSButton.Label>
          </DSButton>
        </div>
      </section>

      <div className={headerClassName} id="ExampleCode">
        Example Code
      </div>

      <CodeSnippet
        code={`
import { BUTTON_SIZE, BUTTON_TYPE, DSButton, useToast } from '@repo/design-system/index'
          
const ToastComponent = () => {
  const { toastInfo, toastError, toastSuccess } = useToast()
  
  return (
    <div className="w-[150px]">
      <DSButton
        type={BUTTON_TYPE.OUTLINE}
        size={BUTTON_SIZE.MD}
        onClick={() => {toast${state}("${content}")}}
      >
        <DSButton.Label>Example</DSButton.Label>
      </DSButton>
    </div>
  )
}
      `}
      />
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const ToastComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[8px]">
        <Toast />
        <ExampleCode />
        <States />
        <Legacy />
      </div>

      <div className="sticky top-[48px] flex h-0 flex-none flex-col gap-[12px] px-[24px] w-[256px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Toast" className={anchorClassName}>
          Toast
        </a>
        <a href="#ExampleCode" className={anchorClassName}>
          Example Code
        </a>
        <a href="#States" className={anchorClassName}>
          States
        </a>
        <a href="#Legacy Toast" className={anchorClassName}>
          Legacy Toast
        </a>
      </div>
    </div>
  )
}

export default ToastComponent
