import { QIcon_close_default } from '@repo/icons/index'
import { COLORS } from '../../../modules/vars'

const DSBottomSheetCloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <QIcon_close_default
      className="z-100 absolute top-[25px] right-[20px] inline cursor-pointer"
      color={COLORS.BLACK}
      width={24}
      height={24}
      onClick={onClick}
    />
  )
}

export default DSBottomSheetCloseButton
