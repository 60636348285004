import classNames from 'classnames'
import { useState } from 'react'
import { Chip, DSInput, DSRadio } from '../../index'
import CodeSnippet from '../CodeSnippet'
import { CHIP_SIZE, CHIP_SIZE_NAMES, CHIP_TYPE } from '../Components/Chip/types'
import {
  ControlClassName,
  anchorClassName,
  descriptionClassName,
  headerClassName,
  rowContainerClassName,
  subHeaderClassName,
} from '../style'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Types = () => {
  return (
    <div>
      <p id="Types" className={headerClassName}>
        Types
      </p>
      <p className={descriptionClassName}>
        모든 Chip의 텍스트 굵기나 텍스트 사이즈의 커스텀을 금합니다. <br />
        Choice와 Filter는 기본적으로 잡혀있는 컬러에서 커스텀을 최대한 지양합니다. <br />
      </p>
      <div className="mr-[36px] flex flex-row gap-[50px]">
        <div className="flex w-[240px] flex-col">
          <p className={classNames(subHeaderClassName, 'mt-[36px] text-center')}>Actionable - Solid</p>
          <p className={classNames(descriptionClassName, 'text-center')}>컬러 커스텀이 가능합니다. </p>
          <div className="flex w-full flex-col items-center justify-center">
            <div className="">
              <Chip type={CHIP_TYPE.ACTIONABLE} size={CHIP_SIZE.LG}>
                <Chip.Label>Label</Chip.Label>
              </Chip>
            </div>
            <div className="Title2-18Bd mt-[12px] mb-[4px]">One State</div>
            <div className="">Fill: Customizable</div>
            <div className="">Text: Customizable</div>
          </div>
        </div>
        <div className="flex w-[500px] flex-col">
          <p className={classNames(subHeaderClassName, 'mt-[36px] text-center')}>Choice - Outline</p>
          <p className={classNames(descriptionClassName, 'text-center')}>
            컨텐츠의 관련 옵션 중 한가지를 선택하는 용도에 사용합니다. 보통 디폴트 선택값이 제공됩니다. 단일 선택만 할
            수 있으며 이미 선택된 칩이 있는 상태에서 다른 칩을 선택하면 기선택된 칩은 자동으로 선택이 풀립니다.
          </p>
          <div className="flex flex-row">
            <div className="flex w-full flex-col items-center justify-start">
              <div className="">
                <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG}>
                  <Chip.Label>Label</Chip.Label>
                </Chip>
              </div>
              <div className="Title2-18Bd mt-[12px] mb-[4px]">Unselected</div>
              <div className="">Outline: Gray300</div>
              <div className="">Text: Black</div>
            </div>
            <div className="flex w-full flex-col items-center justify-start">
              <div className="">
                <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG} initialSelected={true}>
                  <Chip.Label>Label</Chip.Label>
                </Chip>
              </div>
              <div className="Title2-18Bd mt-[12px] mb-[4px]">Selected</div>
              <div className="">Outline: Purple500</div>
              <div className="">Fill: Purple50</div>
              <div className="">Text: Purple500</div>
            </div>
            <div className="flex w-full flex-col items-center justify-start">
              <div className="">
                <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG} disabled={true}>
                  <Chip.Label>Label</Chip.Label>
                </Chip>
              </div>
              <div className="Title2-18Bd mt-[12px] mb-[4px]">Disabled</div>
              <div className="">Outline: Gray200</div>
              <div className="">Text: Gray300</div>
            </div>
          </div>
        </div>
        <div className="flex w-[280px] flex-col">
          <p className={classNames(subHeaderClassName, 'mt-[36px] text-center')}>Filter</p>
          <p className={classNames(descriptionClassName, 'text-center')}>
            컨텐츠를 필터링 하는 용도에 사용합니다. 복수 선택이 가능합니다.
          </p>
          <div className="flex w-full flex-row items-center justify-center">
            <div className="flex w-full flex-col items-center justify-start">
              <div className="">
                <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.LG}>
                  <Chip.Label>Label</Chip.Label>
                  <Chip.Icon />
                </Chip>
              </div>
              <div className="Title2-18Bd mt-[12px] mb-[4px]">Unselected</div>
              <div className="">Outline: Gray200</div>
              <div className="">Text: Gray300</div>
            </div>
            <div className="flex w-full flex-col items-center justify-start">
              <div className="">
                <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.LG} initialSelected>
                  <Chip.Label>Label</Chip.Label>
                  <Chip.Icon />
                </Chip>
              </div>
              <div className="Title2-18Bd mt-[12px] mb-[4px]">Selected</div>
              <div className="">Outline: Gray200</div>
              <div className="">Text: Gray300</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Sizes = () => {
  return (
    <div>
      <p id="Sizes" className={headerClassName}>
        Sizes
      </p>
      <p className={descriptionClassName}>
        모든 Chip의 텍스트 굵기나 텍스트 사이즈의 커스텀을 금합니다. <br />
        Choice와 Filter는 기본적으로 잡혀있는 컬러에서 커스텀을 최대한 지양합니다. <br />
      </p>
      <div className="flex flex-row justify-start gap-[180px]">
        <div className="flex flex-col">
          <div className="text-[11px] font-medium text-[#9747FF]">actionable</div>
          <div className=" border-1 flex flex-row  gap-[12px] rounded-[8px] border-dashed border-[#9747FF] p-[12px]">
            <Chip type={CHIP_TYPE.ACTIONABLE} size={CHIP_SIZE.XS}>
              <Chip.Label>Label</Chip.Label>
            </Chip>
            <Chip type={CHIP_TYPE.ACTIONABLE} size={CHIP_SIZE.SM}>
              <Chip.Label>Label</Chip.Label>
            </Chip>
            <Chip type={CHIP_TYPE.ACTIONABLE} size={CHIP_SIZE.MD}>
              <Chip.Label>Label</Chip.Label>
            </Chip>
            <Chip type={CHIP_TYPE.ACTIONABLE} size={CHIP_SIZE.LG}>
              <Chip.Label>Label</Chip.Label>
            </Chip>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-[11px] font-medium text-[#9747FF]">choice</div>
          <div className="border-1 m-0 flex flex-col gap-[12px] rounded-[8px] border-dashed border-[#9747FF] p-[12px]">
            <div className="flex flex-row gap-[12px]">
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.SM}>
                <Chip.Label>Label</Chip.Label>
              </Chip>
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.MD}>
                <Chip.Label>Label</Chip.Label>
              </Chip>{' '}
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG}>
                <Chip.Label>Label</Chip.Label>
              </Chip>
            </div>
            <div className="flex flex-row gap-[12px]">
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.SM} initialSelected>
                <Chip.Label>Label</Chip.Label>
              </Chip>
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.MD} initialSelected>
                <Chip.Label>Label</Chip.Label>
              </Chip>
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG} initialSelected>
                <Chip.Label>Label</Chip.Label>
              </Chip>
            </div>
            <div className="flex flex-row gap-[12px]">
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.SM} disabled>
                <Chip.Label>Label</Chip.Label>
              </Chip>
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.MD} disabled>
                <Chip.Label>Label</Chip.Label>
              </Chip>
              <Chip type={CHIP_TYPE.CHOICE} size={CHIP_SIZE.LG} disabled>
                <Chip.Label>Label</Chip.Label>
              </Chip>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-[11px] font-medium text-[#9747FF]">filter</div>
          <div className="border-1 m-0 flex flex-col gap-[12px] rounded-[8px]  border-dashed border-[#9747FF] p-[12px]">
            <div className="flex flex-row gap-[12px]">
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.SM}>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.MD}>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.LG}>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
            </div>
            <div className="flex flex-row gap-[12px]">
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.SM} initialSelected>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.MD} initialSelected>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
              <Chip type={CHIP_TYPE.FILTER} size={CHIP_SIZE.LG} initialSelected>
                <Chip.Label>Label</Chip.Label>
                <Chip.Icon />
              </Chip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Examples = () => {
  const [customBgColor, setCustomBgColor] = useState<string>(undefined)
  const [customTxtColor, setCustomTxtColor] = useState<string>(undefined)

  const [type, setType] = useState<CHIP_TYPE>(CHIP_TYPE.ACTIONABLE)
  const [size, setSize] = useState<CHIP_SIZE>(CHIP_SIZE.SM)

  const tags = [
    {
      id: 20,
      name: 'Chip A',
    },
    {
      id: 21,
      name: 'Chip B',
    },
    {
      id: 22,
      name: 'Chip C',
    },
  ]
  const [activeId, setActiveId] = useState<number>(tags[0].id)

  const [selectedIdList, setSelectedIdList] = useState<number[]>([20, 21])

  return (
    <div>
      <p id="Examples" className={headerClassName}>
        Chips
      </p>
      <section className="flex flex-col border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
        <div className={subHeaderClassName}>Controls</div>

        <div className="flex flex-row">
          <div className={ControlClassName}>type*</div>
          <DSRadio controlSelected={[type, setType]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="actionable">actionable</DSRadio.Item>
            <DSRadio.Item value="choice">choice</DSRadio.Item>
            <DSRadio.Item value="filter">filter</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>size*</div>
          <DSRadio controlSelected={[size, setSize]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="xs" disabled={type !== CHIP_TYPE.ACTIONABLE}>
              xs
            </DSRadio.Item>
            <DSRadio.Item value="sm">sm</DSRadio.Item>
            <DSRadio.Item value="md">md</DSRadio.Item>
            <DSRadio.Item value="lg">lg</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, type !== CHIP_TYPE.ACTIONABLE && 'text-gray-300')}>
            background color
          </div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #157B56)"
            value={customBgColor}
            setValue={setCustomBgColor}
            name={'button background color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={type !== CHIP_TYPE.ACTIONABLE}
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, type !== CHIP_TYPE.ACTIONABLE && 'text-gray-300')}>
            text color
          </div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #FFFFFF)"
            value={customTxtColor}
            setValue={setCustomTxtColor}
            name={'button text color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={type !== CHIP_TYPE.ACTIONABLE}
          />
        </div>
      </section>

      <div className="flex flex-row w-[560px] justify-center my-[40px]">
        {type === CHIP_TYPE.ACTIONABLE ? (
          <Chip type={CHIP_TYPE.ACTIONABLE} size={size} customBgColor={customBgColor}>
            <Chip.Label customTxtColor={customTxtColor}>Label</Chip.Label>
          </Chip>
        ) : type === CHIP_TYPE.CHOICE ? (
          <div className=" border-1 flex flex-row gap-[12px] rounded-[8px] border-dashed border-[#9747FF] p-[12px]">
            {tags.map((tag) => {
              return (
                <Chip
                  type={CHIP_TYPE.CHOICE}
                  size={size}
                  onClick={() => setActiveId(tag.id)}
                  initialSelected={activeId === tag.id}
                  key={tag.id}
                >
                  <Chip.Label>{tag.name}</Chip.Label>
                </Chip>
              )
            })}
            <Chip type={CHIP_TYPE.CHOICE} size={size} disabled>
              <Chip.Label>Chip D</Chip.Label>
            </Chip>
          </div>
        ) : (
          <div className="border-1 flex flex-row gap-[12px] rounded-[8px] border-dashed border-[#9747FF] p-[12px]">
            {tags.map((tag) => {
              return (
                <Chip
                  type={CHIP_TYPE.FILTER}
                  size={size}
                  key={tag.id}
                  initialSelected={selectedIdList.includes(tag.id)}
                  id={tag.id}
                  chipSelected={[selectedIdList, setSelectedIdList]}
                >
                  <Chip.Label>{tag.name}</Chip.Label>
                  <Chip.Icon />
                </Chip>
              )
            })}
          </div>
        )}
      </div>

      {type === CHIP_TYPE.CHOICE && (
        <p className="border-[1.5] border-1 rounded-[8px] bg-gray-50 py-[8px] px-[12px] max-w-max font-bold">
          You selected <span className="text-purple-500">{tags.find((tag) => tag.id === activeId)?.name}</span>
        </p>
      )}
      {type === CHIP_TYPE.FILTER && (
        <p className="border-[1.5] border-1 rounded-[8px] bg-gray-50 py-[8px] px-[12px] max-w-max font-bold">
          You selected{' '}
          <span className="text-purple-500">
            {selectedIdList
              .map((tagId) => tags.find((tag) => tag.id === tagId)?.name)
              .filter(Boolean)
              .join(', ')}
          </span>
        </p>
      )}

      <p id="Examples" className={headerClassName}>
        Example Code
      </p>

      {type === CHIP_TYPE.ACTIONABLE ? (
        <CodeSnippet
          code={`
import { CHIP_SIZE, CHIP_TYPE, COLORS, Chip } from '@repo/design-system/index'

const ChipComponent = () => {
  return (
    <Chip type={CHIP_TYPE.ACTIONABLE} size={${CHIP_SIZE_NAMES[size]}}${
            customBgColor ? ' customBgColor={COLORS.YELLOW100}' : ''
          }>
      <Chip.Label${customTxtColor ? ' customTxtColor={COLORS.YELLOW900}' : ''}>Label</Chip.Label>
    </Chip>
  )
}
      `}
        />
      ) : type === CHIP_TYPE.CHOICE ? (
        <CodeSnippet
          code={`
import { CHIP_SIZE, CHIP_TYPE, Chip } from '@repo/design-system/index'
import { useState } from 'react'

const ChipComponent = () => {

  const tags = [
    {
      id: 20,
      name: 'Chip A',
    },
    {
      id: 21,
      name: 'Chip B',
    },
    {
      id: 22,
      name: 'Chip C',
    },
  ]
  
  const [activeId, setActiveId] = useState<number>(tags[0].id)

  return (
    <div className="flex flex-row gap-[8px]">
      {tags.map((tag) => {
        return (
          <Chip
            type={CHIP_TYPE.CHOICE}
            size={${CHIP_SIZE_NAMES[size]}}
            onClick={() => setActiveId(tag.id)}
            initialSelected={activeId === tag.id}
            key={tag.id}
          >
            <Chip.Label>{tag.name}</Chip.Label>
          </Chip>
        )
      })}
      <Chip type={CHIP_TYPE.CHOICE} size={${CHIP_SIZE_NAMES[size]}} disabled>
        <Chip.Label>Chip D</Chip.Label>
      </Chip>
    </div>
  )
}
        `}
        />
      ) : (
        <CodeSnippet
          code={`
import { CHIP_SIZE, CHIP_TYPE, Chip } from '@repo/design-system/index'
import { useState } from 'react'

const ChipComponent = () => {

  const tags = [
    {
      id: 20,
      name: 'Chip A',
    },
    {
      id: 21,
      name: 'Chip B',
    },
    {
      id: 22,
      name: 'Chip C',
    },
  ]
  
  const [selectedIdList, setSelectedIdList] = useState<number[]>([20, 21])

  return (
    <div className="flex flex-row gap-[8px]">
      {tags.map((tag) => {
        return (
          <Chip
            type={CHIP_TYPE.FILTER}
            size={${CHIP_SIZE_NAMES[size]}}
            key={tag.id}
            initialSelected={selectedIdList.includes(tag.id)}
            id={tag.id}
            chipSelected={[selectedIdList, setSelectedIdList]}
          >
            <Chip.Label>{tag.name}</Chip.Label>
            <Chip.Icon />
          </Chip>
        )
      })}
    </div>
  )
}
          `}
        />
      )}
    </div>
  )
}

const ChipComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[8px]">
        <Examples />
        <Types />
        <Sizes />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Types" className={anchorClassName}>
          Types
        </a>
        <a href="#Sizes" className={anchorClassName}>
          Sizes
        </a>
        <a href="#Examples" className={anchorClassName}>
          Examples
        </a>
      </div>
    </div>
  )
}

export default ChipComponent
