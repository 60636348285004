import { createSlice } from '@reduxjs/toolkit'

const brazeSlice = createSlice({
  name: 'braze',
  initialState: {
    appboy: null,
    // pendingPurchase: {
    //     productId: null,
    //     name: null,
    //     pricePaid: null,
    //     currency: null,
    //     time: null,
    //     method: null,
    //     usedPoints: null
    // }
  },
  reducers: {
    setAppboy(state, action) {
      state.appboy = action.payload
    },
    // setPendingPurchase(state, action) {
    //     state.pendingPurchase = action.payload
    // }
  },
})

export const brazeReducer = brazeSlice.reducer
export const brazeActions = brazeSlice.actions
