// @ts-strict-ignore
import * as Sentry from '@sentry/react'
import fetch from 'isomorphic-unfetch'
import { isFunction } from 'lodash'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { GTM_EVENT, sendGTMEvent } from '../helpers/gtmHelper'
import apis from '../modules/apis'
import {
  COOKIE_OPT,
  COOKIE_OPT_FOR_ACCESS_TOKEN,
  EMAIL_LOGIN_PREFIX,
  FACEBOOK_APP_ID,
  SESSION_ROLE,
  TUTOR_STATUS,
} from '../modules/vars'
import { appActions } from '../reducer/appReducer'
import { axiosPost } from './axiosHelper'
import { IS_DEVELOPMENT } from './envVars'
import { DEFAULT_LOCALE, LOCALE_KEY, LocaleType } from './i18n/config'
import urls from './urls'
import { locationMove, locationMoveLocale, locationReplaceLocale } from './utils'
import { COOKIE_LIST } from './vars'

export const useLogin = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  return async (email, password, role, locale, onFail = null) => {
    try {
      const payload: Omit<ILoginPayload, 'google_token_type'> = {
        grant_type: OAUTH_GRANT_TYPE.PASSWORD,
        username: EMAIL_LOGIN_PREFIX + email,
        password: password,
        locale: locale == LOCALE_KEY ? DEFAULT_LOCALE : locale,
        product_type: PRODUCT_TYPE.PLUS,
      }
      const response = await axiosPost(apis.auth.token(), payload, { shouldThrowError: true })

      if (response) {
        dispatch(appActions.login({ token: response.jwt_token, currentUser: response.user }))
        afterLogin(router, response)
        if (response.is_password_reset_target) {
          onFail(response)
        }
      }
    } catch (error) {
      const errorRes = error?.response?.data
      if (errorRes?.error) {
        alert(errorRes.error)
      }
      if (onFail && isFunction(onFail)) onFail()
    }
  }
}

export const moveToLoginIfNotAlready = async (router, url) => {
  const token = getJwtToken()
  if (token == '' || !token) {
    const cookies = new Cookies()
    cookies.set(COOKIE_LIST.LOGIN_AFTER_URL, url, COOKIE_OPT)
    locationMoveLocale(router, `${urls.student.auth.signIn}?redirect_url=${encodeURIComponent(url)}`)
  } else {
    locationMoveLocale(router, url)
  }
}

export const afterLogin = (router, data) => {
  if (!data.jwt_token) {
    console.log(data)
    alert('Error')
    return
  }

  // sentry user info
  Sentry.setUser({
    email: data.user?.email,
    id: data.user?.id + '',
  })

  const isAdminLogin = data?.is_admin_login === 'true'
  const cookies = new Cookies()
  const redirectUrlQuery = router.query?.redirect_url
  const moveUrl = redirectUrlQuery || cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
  cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
  cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)

  if (isAdminLogin) {
    cookies.set(COOKIE_LIST.IS_ADMIN_LOGIN, data.is_admin_login, COOKIE_OPT)
  } else {
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)
  }

  if (data.is_password_reset_target) {
    return
  }

  if (data.session_role == SESSION_ROLE.STUDENT) {
    locationMoveLocale(router, urls.student.auth.signIn)
    return
  } else if (data.session_role == SESSION_ROLE.TUTOR) {
    // ============= TUTOR =============
    let url = null
    if (redirectUrlQuery) {
      url = redirectUrlQuery
    } else if (moveUrl) {
      cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
      url = moveUrl
    } else {
      url = data.user?.tutor_status_id == TUTOR_STATUS.APPLICATION ? urls.tutor.landing.home : urls.tutor.portal.home
    }
    isAdminLogin ? locationReplaceLocale(router, url) : locationMoveLocale(router, url)
    return
  } else if (data.session_role == SESSION_ROLE.ADMIN) {
    window.location.href = `${urls.admin.home}`
  }
}

export const setUserImage = (image_url) => {
  const cookies = new Cookies()
  cookies.set('userImage', image_url, { path: '/' })
}

export const signup = async (router, payload, successCallback, errorCallback, isMobile, isStudentAuthentication) => {
  try {
    const data = await axiosPost(
      isStudentAuthentication ? apis.auth.signup.studentEmail() : apis.auth.signup.tutor(),
      payload,
      { shouldThrowError: true }
    )

    if (data) {
      if (data.jwt_token) {
        const cookies = new Cookies()
        const redirectUrlQuery = router.query?.redirect_url
        const moveUrl = redirectUrlQuery ? redirectUrlQuery : cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
        cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
        cookies.set(COOKIE_LIST.SESSION_ROLE, data.session_role, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.REFERRAL_CODE, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.FRIEND, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.PHONE, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.UTM_AD_INFO, COOKIE_OPT)
        cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

        if (payload.session_role === SESSION_ROLE.STUDENT) {
          const isRingleMember = data.user.is_ringle_member || data.user.email.includes('ringleplus.com')

          if (!isRingleMember) {
            sendGTMEvent(GTM_EVENT.COMPLETE_REGISTRATION, {
              user_id: data.user.id,
              user_email: data.user.email,
              signup_medium: isMobile ? 'mobile_web' : 'desktop_web',
              login_provider: payload?.provider,
            })

            successCallback && successCallback()
          }

          if (moveUrl) {
            cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
            locationMoveLocale(router, moveUrl)
          } else {
            locationMoveLocale(router, urls.student.portal.welcome)
          }
        } else {
          if (data?.is_tutor_event) {
            locationMoveLocale(router, urls.tutor.portal.application.step1.pass)
          } else {
            locationMoveLocale(router, urls.tutor.portal.application.ready)
          }
        }
      }
    } else {
      errorCallback(data.message)
    }
  } catch (error) {
    const errorRes = error?.response?.data
    if (errorRes?.error) {
      alert(errorRes.error)
    }
  }
}

export const updateUserInfo = async (userInfo, callback) => {
  return await fetch(apis.profile.get(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getJwtToken(),
    },
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        const cookies = new Cookies()
        cookies.set(COOKIE_LIST.USER_IMAGE, data.user.image_url, { path: '/' })
        cookies.set(COOKIE_LIST.TIMEZONE, data.user.timezone, { path: '/' })
        cookies.set(COOKIE_LIST.USER_ID, data.user.id, { path: '/' })
        callback()
      } else {
        alert(data?.message)
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export const enum SOCIAL_LOGIN_PROVIDER {
  KAKAO = 'kakao',
  GOOGLE = 'google_oauth2',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  NAVER = 'naver',
}

export const enum PRODUCT_TYPE {
  PLUS = 'plus',
  TUTOR = 'tutor',
  TEENS = 'teens',
  ADMIN = 'admin',
}

export const enum OAUTH_GRANT_TYPE {
  PASSWORD = 'password', // 일반 email/sns login 시에 사용
  DELEGATION = 'delegation', // logged_by_parent, admin fake login인 경우에 사용
}

export interface ILoginPayload {
  grant_type: OAUTH_GRANT_TYPE
  username: string
  password: string
  locale: LocaleType
  product_type: PRODUCT_TYPE
  google_token_type?: 'id_token' | 'access_token' // only for {username:'snslogin:google'}
}

export const useLogoutTutor = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  return () => {
    // sentry user info
    Sentry.setUser(null)
    const cookies = new Cookies()
    cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
    cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
    cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

    dispatch(appActions.logout())
    // FB delete login info
    window.sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`)
    window.localStorage.removeItem(`fblst_${FACEBOOK_APP_ID}`)

    locationMove(router, 'en', urls.tutor.landing.home)
  }
}

export const getJwtToken = () => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_LIST.JWT_TOKEN)
}

export const removeJwtToken = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
  IS_DEVELOPMENT &&
    cookies.remove(COOKIE_LIST.JWT_TOKEN, {
      domain: 'ringlefront.com',
      path: '/',
    })
}

export const removeSessionRole = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_LIST.SESSION_ROLE, COOKIE_OPT)
  IS_DEVELOPMENT &&
    cookies.remove(COOKIE_LIST.SESSION_ROLE, {
      domain: 'ringlefront.com',
      path: '/',
    })
}

export const getRootUrl = () => {
  const cookies = new Cookies()
  const rootUrl = cookies.get(COOKIE_LIST.ROOT_URL)
  return rootUrl ? rootUrl : 'home'
}

export const emailRegExpServer = /^[A-Za-z0-9_.-]+@[A-Za-z0-9]+([.-][A-Za-z0-9]+)+$/
export function checkEmailValidity(asValue) {
  return emailRegExpServer.test(asValue) // 형식에 맞는 경우 true 리턴
}

export const noWhitespaceRegExp = /^\S*$/
