interface InputUpperTextProps {
  required?: boolean
  children: React.ReactNode
}

const DSInputUpperText = ({ required = false, children }: InputUpperTextProps) => {
  return (
    <label className={styles.label}>
      {children}
      {required && <span className={styles.requiredMark}>*</span>}
    </label>
  )
}

export default DSInputUpperText

const styles = {
  label: `mb-[4px] text-[12px] font-medium leading-[20px] text-gray-700`,
  requiredMark: `ml-[2px] text-[12px] font-medium leading-[20px] text-red-500`,
}
