import { QIcon_check_mark, QIcon_chevron_right, QIcon_plus_default } from '@repo/icons/index'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Button from '../../../../../apps/ringle-student/components/_legacy/Button'
import { DSButton, DSInput, DSRadio, Switch } from '../../index'
import CodeSnippet from '../CodeSnippet'
import {
  BUTTON_SIZE,
  BUTTON_SIZE_KEY,
  BUTTON_SIZE_NAMES,
  BUTTON_TYPE,
  BUTTON_TYPE_NAMES,
} from '../Components/Button/types'
import { COLORS } from '../modules/vars'
import {
  ControlClassName,
  anchorClassName,
  descriptionClassName,
  headerClassName,
  rowContainerClassName,
  subHeaderClassName,
} from '../style'

const sizeList: BUTTON_SIZE_KEY[] = ['xs', 'sm', 'md', 'lg', 'xl']

const ButtonPage = () => {
  const [customBgColor, setCustomBgColor] = useState<string>('')
  const [customBorderColor, setCustomBorderColor] = useState<string>('')
  const [customTxtColor, setCustomTxtColor] = useState<string>('')

  const [type, setType] = useState<'solid' | 'outline' | 'text'>(BUTTON_TYPE.SOLID)
  const [size, setSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl'>(BUTTON_SIZE.SM)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [width, setWidth] = useState<'default' | '200px' | 'w-full'>('default')
  const [withIcon, setWithIcon] = useState<'textOnly' | 'textWithIcon' | 'iconOnly'>('textOnly')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomBgColor(e.target.value)
  }

  // 버튼 클릭 핸들러
  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  useEffect(() => {
    if (disabled) {
      setIsLoading(false)
    }
  }, [disabled])

  useEffect(() => {
    if (withIcon === 'iconOnly') {
      if (type === BUTTON_TYPE.TEXT) {
        setType(BUTTON_TYPE.SOLID)
      }
      if (size === BUTTON_SIZE.LG || size === BUTTON_SIZE.XL) {
        setSize(BUTTON_SIZE.SM)
      }
    }
  }, [withIcon])

  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[8px]">
        <div>
          <div className={headerClassName} id="DSButton">
            DSButton
          </div>

          <section className="flex flex-col border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
            <div className={subHeaderClassName}>Controls</div>

            <div className="flex flex-row">
              <div className={ControlClassName}>type*</div>
              <DSRadio controlSelected={[type, setType]} containerClassName={rowContainerClassName}>
                <DSRadio.Item value="solid">solid</DSRadio.Item>
                <DSRadio.Item value="outline">outline</DSRadio.Item>
                <DSRadio.Item value="text" disabled={withIcon === 'iconOnly'}>
                  text
                </DSRadio.Item>
              </DSRadio>
            </div>

            <div className="flex flex-row">
              <div className={ControlClassName}>size*</div>
              <DSRadio controlSelected={[size, setSize]} containerClassName={rowContainerClassName}>
                <DSRadio.Item value="xs" disabled={type === BUTTON_TYPE.TEXT}>
                  xs
                </DSRadio.Item>
                <DSRadio.Item value="sm">sm</DSRadio.Item>
                <DSRadio.Item value="md">md</DSRadio.Item>
                <DSRadio.Item value="lg" disabled={withIcon === 'iconOnly'}>
                  lg
                </DSRadio.Item>
                <DSRadio.Item value="xl" disabled={type === BUTTON_TYPE.TEXT || withIcon === 'iconOnly'}>
                  xl
                </DSRadio.Item>
              </DSRadio>
            </div>

            <div className="flex flex-row items-center">
              <div className={ControlClassName}>disabled</div>
              <Switch checked={disabled} setChecked={setDisabled} />
            </div>

            <div className="flex flex-row items-center">
              <div className={classNames(ControlClassName, (disabled || type === BUTTON_TYPE.TEXT) && 'text-gray-300')}>
                isLoading
              </div>
              <Switch checked={isLoading} setChecked={setIsLoading} disabled={disabled || type === BUTTON_TYPE.TEXT} />
            </div>

            <div className="flex flex-row items-center">
              <div className={classNames(ControlClassName, type === BUTTON_TYPE.TEXT && 'text-gray-300')}>
                background color
              </div>
              <DSInput
                type="text"
                placeholder="Enter color (e.g., #157B56)"
                value={customBgColor}
                setValue={setCustomBgColor}
                name={'button background color'}
                clearable
                size="sm"
                required={false}
                className="bg-gray-50"
                disabled={type === BUTTON_TYPE.TEXT}
              />
            </div>

            <div className="flex flex-row items-center">
              <div className={classNames(ControlClassName, type !== BUTTON_TYPE.OUTLINE && 'text-gray-300')}>
                border color
              </div>
              <DSInput
                type="text"
                placeholder="Enter color (e.g., #157B56)"
                value={customBorderColor}
                setValue={setCustomBorderColor}
                name={'button border color'}
                clearable
                size="sm"
                required={false}
                className="bg-gray-50"
                disabled={type !== BUTTON_TYPE.OUTLINE}
              />
            </div>

            <div className="flex flex-row items-center">
              <div className={ControlClassName}>text color</div>
              <DSInput
                type="text"
                placeholder="Enter color (e.g., #FFFFFF)"
                value={customTxtColor}
                setValue={setCustomTxtColor}
                name={'button text color'}
                clearable
                size="sm"
                required={false}
                className="bg-gray-50"
              />
            </div>

            <div className="flex flex-row">
              <div className={ControlClassName}>너비 조절 방법 (코드 확인)</div>
              <DSRadio controlSelected={[width, setWidth]} containerClassName={rowContainerClassName}>
                <DSRadio.Item value="default">최소</DSRadio.Item>
                <DSRadio.Item value="200px">200px</DSRadio.Item>
                <DSRadio.Item value="w-full">w-full</DSRadio.Item>
              </DSRadio>
            </div>

            <div className="flex flex-row">
              <div className={ControlClassName}>아이콘 유무 (코드 확인)</div>
              <DSRadio controlSelected={[withIcon, setWithIcon]} containerClassName={rowContainerClassName}>
                <DSRadio.Item value="textOnly">text만</DSRadio.Item>
                <DSRadio.Item value="textWithIcon">text + icon</DSRadio.Item>
                <DSRadio.Item
                  value="iconOnly"
                  disabled={type === BUTTON_TYPE.TEXT || size === BUTTON_SIZE.LG || size === BUTTON_SIZE.XL}
                >
                  icon만
                </DSRadio.Item>
              </DSRadio>
            </div>
          </section>

          <section className={`flex flex-row w-[560px] justify-center my-[40px] ${width === '200px' && 'w-[200px]'}`}>
            {withIcon === 'iconOnly' ? (
              <div className={width === '200px' ? 'w-[200px]' : 'flex justify-center w-full'}>
                <DSButton
                  type={type}
                  size={size}
                  onClick={handleButtonClick}
                  isIconMode
                  disabled={disabled}
                  isLoading={isLoading}
                  customBgColor={customBgColor}
                  customBorderColor={customBorderColor}
                  className={width === 'default' && 'max-w-max'}
                >
                  <DSButton.Icon alt="check icon">
                    <QIcon_check_mark
                      width={20}
                      height={20}
                      color={type === BUTTON_TYPE.SOLID ? COLORS.WHITE : COLORS.BLACK}
                    />
                  </DSButton.Icon>
                </DSButton>
              </div>
            ) : (
              <div className={width === '200px' ? 'w-[200px]' : 'flex justify-center w-full'}>
                <DSButton
                  size={size}
                  type={type}
                  onClick={handleButtonClick}
                  disabled={disabled}
                  isLoading={isLoading}
                  customBgColor={customBgColor}
                  customBorderColor={customBorderColor}
                  className={width === 'default' && 'max-w-max'}
                >
                  {withIcon === 'textWithIcon' && (
                    <DSButton.Icon alt="plus icon">
                      <QIcon_plus_default
                        width={12}
                        height={12}
                        color={type === BUTTON_TYPE.SOLID ? COLORS.WHITE : COLORS.BLACK}
                        className="mr-[4px]"
                      />
                    </DSButton.Icon>
                  )}
                  <DSButton.Label customTxtColor={customTxtColor}>DSButton</DSButton.Label>
                </DSButton>
              </div>
            )}
          </section>

          <div id="ExampleCode" className={headerClassName}>
            Example Code
          </div>
          {withIcon === 'iconOnly' ? (
            <CodeSnippet
              code={`
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
  COLORS,
  DSButton
} from '@repo/design-system/index'
              
const ButtonComponent = () => {

  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  return (
    <div className="${width === '200px' ? 'w-[200px]' : 'w-full'}">
      <DSButton
        size={${BUTTON_SIZE_NAMES[size]}}
        type={${BUTTON_TYPE_NAMES[type]}}
        onClick={handleButtonClick}${
          disabled
            ? `
        disabled={true}`
            : ''
        }${
                isLoading
                  ? `
        isLoading={true}`
                  : ''
              }${
                customBgColor
                  ? `
        customBgColor={COLORS.YELLOW100}`
                  : ''
              }${
                customBorderColor
                  ? `
        customBorderColor={COLORS.YELLOW100}`
                  : ''
              }${
                width === 'default'
                  ? `
        className="max-w-max"`
                  : ''
              }
      >
        <DSButton.Icon alt="check icon">
          <QIcon_check_mark width={20} height={20} color={${
            type === BUTTON_TYPE.SOLID ? 'COLORS.WHITE' : 'COLORS.BLACK'
          }} />
        </DSButton.Icon>
      </DSButton>
    </div>
  )
}
`}
            />
          ) : (
            <CodeSnippet
              code={`
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
  COLORS,
  DSButton
} from '@repo/design-system/index'
              
const ButtonComponent = () => {

  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  return (
    <div className="${width === '200px' ? 'w-[200px]' : 'w-full'}">
      <DSButton
        size={${BUTTON_SIZE_NAMES[size]}}
        type={${BUTTON_TYPE_NAMES[type]}}
        onClick={handleButtonClick}${
          disabled
            ? `
        disabled={true}`
            : ''
        }${
                isLoading
                  ? `
        isLoading={true}`
                  : ''
              }${
                customBgColor
                  ? `
        customBgColor={COLORS.YELLOW100}`
                  : ''
              }${
                customBorderColor
                  ? `
        customBorderColor={COLORS.YELLOW100}`
                  : ''
              }${
                width === 'default'
                  ? `
        className="max-w-max"`
                  : ''
              }
      >${
        withIcon === 'textWithIcon'
          ? `
        <DSButton.Icon alt="plus icon">
          <QIcon_plus_default
            width={12}
            height={12}
            color={${type === BUTTON_TYPE.SOLID ? 'COLORS.WHITE' : 'COLORS.BLACK'}}
            className="mr-[4px]"
          />
        </DSButton.Icon>`
          : ''
      }
        <DSButton.Label${customTxtColor ? ' customTxtColor={COLORS.YELLOW900}>' : '>'}
          DSButton
        </DSButton.Label>
      </DSButton>
    </div>
  )
}
`}
            />
          )}
        </div>

        <section id="Legacy">
          <div className={headerClassName}>Legacy Buttons</div>
          <div className={descriptionClassName}>
            Legacy Button를 사용하고 있는 곳에서 DSButton을 사용하도록 변경이 필요합니다.
            <br />
            student(57개 파일), teens(105개), tutor, tutor-apply 각각에 모두 존재합니다.
          </div>
          <div className="w-[100px]">
            <Button outlined full lg clx="w-full mt-[12px]" type={'button'} onClick={handleButtonClick}>
              Legacy Button
            </Button>
          </div>
        </section>

        <section id="States">
          <div className={headerClassName}>States</div>
          <div className={descriptionClassName}>
            Solid DSButton, Outline Button은 팔레트 안에서 자유롭게 컬러를 변경할 수 있습니다.
            <br />
            Outline DSButton-Gray는 컬러 변경을 금합니다.
          </div>

          <div>
            <div className={subHeaderClassName}>Solid DSButton</div>
            <div className={descriptionClassName}>
              Hover (70%)와 Pressed(50%)는 직접 버튼 위에서 확인 할 수 있어서 따로 넣지 않았습니다.{' '}
            </div>
            <div className="flex gap-[20px]">
              <div className="flex flex-col items-center">
                <DSButton size={BUTTON_SIZE.XL} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
                <p>Enabled</p>
                <p>100%</p>
              </div>

              <div className="flex flex-col items-center">
                <DSButton size={BUTTON_SIZE.XL} disabled={true}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
                <p>Disabled</p>
                <p>Gray 200</p>
              </div>

              <div className="flex flex-col items-center">
                <DSButton size={BUTTON_SIZE.XL} onClick={handleButtonClick} isLoading={true}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
                <p>Loading</p>
              </div>
            </div>
          </div>

          <div>
            <div className={subHeaderClassName}>Outline DSButton</div>

            <div className="flex gap-[10px]">
              <div className="flex flex-col items-center">
                <DSButton
                  size={BUTTON_SIZE.XL}
                  type={BUTTON_TYPE.OUTLINE}
                  customBgColor={COLORS.WHITE}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>라벨!</DSButton.Label>
                </DSButton>
                <p>Enabled</p>
                <p>Outline: Gray300</p>
                <p>Fill: White</p>
              </div>

              <div className="flex flex-col items-center">
                <DSButton
                  size={BUTTON_SIZE.XL}
                  type={BUTTON_TYPE.OUTLINE}
                  customBgColor={COLORS.GRAY50}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>라벨!</DSButton.Label>
                </DSButton>
                <p>Enabled</p>
                <p>Outline: Gray300</p>
                <p>Fill: Gray50</p>
              </div>

              <div className="flex flex-col items-center">
                <DSButton
                  size={BUTTON_SIZE.XL}
                  type={BUTTON_TYPE.OUTLINE}
                  disabled={true}
                  customBgColor={COLORS.GRAY200}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>라벨!</DSButton.Label>
                </DSButton>
                <p>Disabled</p>
                <p>Fill: Gray200</p>
              </div>

              <div className="flex flex-col items-center">
                <DSButton size={BUTTON_SIZE.XL} type={BUTTON_TYPE.OUTLINE} isLoading={true} onClick={handleButtonClick}>
                  <DSButton.Label>라벨!</DSButton.Label>
                </DSButton>
                <p>Loading</p>
              </div>
            </div>
          </div>
        </section>

        <section id="Sizes">
          <div className={headerClassName}>Sizes</div>
          <div className={descriptionClassName}>
            버튼 사이즈별 상하 Padding과 Text Size는 고정입니다. <br />
            디자인 환경에 따라 좌우 Padding을 변경하여 사용합니다. 단, 각 사이즈별 좌우 Padding 최소 사이즈는
            고정입니다. <br />
            <span className="text-purple-600">
              *시스템에 등록된 좌우 Padding이 최소 사이즈입니다. 필요 시 가로 사이즈를 늘릴 수 있으나, 줄이는 것을
              금합니다.
            </span>
            <br />
            <span className="text-green-600">
              * For developer : 겉에 button을 둘러싸는 컨테이너의 넓이에 따라 버튼 크기를 조절할 수 있습니다. Padding을
              늘리는 부분도 겉 컨테이너의 넓이로 조절해주면 됩니다.
            </span>
          </div>
          <div className={subHeaderClassName}>기본 사이즈</div>
          <div className="flex flex-col items-start">
            <div>
              <p>xs</p>
              <div className="flex gap-[10px]">
                <DSButton size={BUTTON_SIZE.XS} customBgColor={COLORS.YELLOW100} onClick={handleButtonClick}>
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>
                <DSButton
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.XS}
                  customBorderColor={COLORS.YELLOW900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>

                <DSButton size={BUTTON_SIZE.XS} disabled={true} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  size={BUTTON_SIZE.XS}
                  isLoading={true}
                  customBgColor={COLORS.YELLOW300}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
              </div>
            </div>
            <div>
              <p>sm</p>
              <div className="flex gap-[10px]">
                <DSButton size={BUTTON_SIZE.SM} customBgColor={COLORS.YELLOW300} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.SM}
                  customBorderColor={COLORS.YELLOW900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>

                <DSButton size={BUTTON_SIZE.SM} disabled={true} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  size={BUTTON_SIZE.SM}
                  isLoading={true}
                  customBgColor={COLORS.YELLOW300}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
              </div>
            </div>
            <div>
              <p>md</p>
              <div className="flex gap-[10px]">
                <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.YELLOW500} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.MD}
                  customBorderColor={COLORS.YELLOW900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>

                <DSButton size={BUTTON_SIZE.MD} disabled={true} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  size={BUTTON_SIZE.MD}
                  isLoading={true}
                  customBgColor={COLORS.YELLOW300}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
              </div>
            </div>
            <div>
              <p>lg</p>
              <div className="flex gap-[10px]">
                <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.YELLOW} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.LG}
                  customBorderColor={COLORS.YELLOW900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>

                <DSButton size={BUTTON_SIZE.LG} disabled={true} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  size={BUTTON_SIZE.LG}
                  isLoading={true}
                  customBgColor={COLORS.YELLOW300}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
              </div>
            </div>
            <div>
              <p>xl</p>
              <div className="flex gap-[10px]">
                <DSButton size={BUTTON_SIZE.XL} customBgColor={COLORS.YELLOW700} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.XL}
                  customBorderColor={COLORS.YELLOW900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.YELLOW900}>DSButton</DSButton.Label>
                </DSButton>

                <DSButton size={BUTTON_SIZE.XL} disabled={true} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>

                <DSButton
                  size={BUTTON_SIZE.XL}
                  isLoading={true}
                  customBgColor={COLORS.YELLOW300}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label>DSButton</DSButton.Label>
                </DSButton>
              </div>
            </div>
          </div>

          <div className="mt-[10px]">
            <div className={subHeaderClassName}>사이즈 조절</div>
            <div className="flex items-center gap-[10px]">
              <div className="w-[200px]">
                <DSButton size={BUTTON_SIZE.SM} customBgColor={COLORS.BLUE200} onClick={handleButtonClick}>
                  <DSButton.Label customTxtColor={COLORS.BLACK}>버튼 사이즈 SM</DSButton.Label>
                </DSButton>
              </div>
              <div className="w-[300px]">
                <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.BLUE500} onClick={handleButtonClick}>
                  <DSButton.Label>버튼 사이즈 MD</DSButton.Label>
                </DSButton>
              </div>
              <div className="w-[200px]">
                <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.BLUE900} onClick={handleButtonClick}>
                  <DSButton.Label>버튼 사이즈 LG</DSButton.Label>
                </DSButton>
              </div>
            </div>
          </div>

          <div className="mt-[10px]">
            <div className={subHeaderClassName} id="IconButton">
              ICON DSButton
            </div>

            <div className="flex flex-col items-start">
              <div>
                <p>xs</p>
                <div className="flex gap-[10px]">
                  <DSButton size={BUTTON_SIZE.XS} customBgColor={COLORS.BLUE100} onClick={handleButtonClick}>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.XS}
                    customBorderColor={COLORS.BLUE500}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLUE500}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.XS}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.XS}
                    customBgColor={COLORS.BLUE900}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_check_mark width={20} height={20} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.XS}
                    type={BUTTON_TYPE.OUTLINE}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={20} height={20} color={COLORS.BLACK} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    disabled={true}
                    size={BUTTON_SIZE.XS}
                    customBgColor={COLORS.BLUE100}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>
                </div>
              </div>
              <div>
                <p>sm</p>
                <div className="flex gap-[10px]">
                  <DSButton size={BUTTON_SIZE.SM} customBgColor={COLORS.BLUE100} onClick={handleButtonClick}>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.SM}
                    customBorderColor={COLORS.BLUE500}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLUE500}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.SM}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.SM}
                    customBgColor={COLORS.BLUE900}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_check_mark width={20} height={20} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.SM}
                    type={BUTTON_TYPE.OUTLINE}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={20} height={20} color={COLORS.BLACK} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    disabled={true}
                    size={BUTTON_SIZE.SM}
                    customBgColor={COLORS.BLUE100}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>
                </div>
              </div>
              <div>
                <p>md</p>
                <div className="flex gap-[10px]">
                  <DSButton size={BUTTON_SIZE.MD} customBgColor={COLORS.BLUE100} onClick={handleButtonClick}>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.MD}
                    customBorderColor={COLORS.BLUE500}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLUE500}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.MD}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.MD}
                    customBgColor={COLORS.BLUE900}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_check_mark width={24} height={24} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    isIconMode={true}
                    size={BUTTON_SIZE.MD}
                    type={BUTTON_TYPE.OUTLINE}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={24} height={24} color={COLORS.BLACK} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    disabled={true}
                    size={BUTTON_SIZE.MD}
                    customBgColor={COLORS.BLUE100}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>
                </div>
              </div>
              <div>
                <p>lg</p>
                <div className="flex gap-[10px]">
                  <DSButton size={BUTTON_SIZE.LG} customBgColor={COLORS.BLUE100} onClick={handleButtonClick}>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.LG}
                    customBorderColor={COLORS.BLUE500}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLUE500}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.LG}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[4px]'} />
                    </DSButton.Icon>
                    <DSButton.Label>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[4px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    disabled={true}
                    size={BUTTON_SIZE.LG}
                    customBgColor={COLORS.BLUE100}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[4px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>
                </div>
              </div>
              <div>
                <p>xl</p>
                <div className="flex gap-[10px]">
                  <DSButton size={BUTTON_SIZE.XL} customBgColor={COLORS.BLUE100} onClick={handleButtonClick}>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[8px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[8px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.XL}
                    customBorderColor={COLORS.BLUE500}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.BLUE500} width={12} height={12} className={'mr-[8px]'} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLUE500}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.BLUE500} width={12} height={12} className={'ml-[8px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    type={BUTTON_TYPE.OUTLINE}
                    size={BUTTON_SIZE.XL}
                    customBorderColor={COLORS.GRAY300}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default color={COLORS.GRAY500} width={12} height={12} className={'mr-[8px]'} />
                    </DSButton.Icon>
                    <DSButton.Label>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right color={COLORS.GRAY500} width={12} height={12} className={'ml-[8px]'} />
                    </DSButton.Icon>
                  </DSButton>

                  <DSButton
                    disabled={true}
                    size={BUTTON_SIZE.XL}
                    customBgColor={COLORS.BLUE100}
                    onClick={handleButtonClick}
                  >
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_plus_default width={12} height={12} className={'mr-[8px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                    <DSButton.Label customTxtColor={COLORS.BLACK}>DSButton</DSButton.Label>
                    <DSButton.Icon alt={'plus icon'}>
                      <QIcon_chevron_right width={12} height={12} className={'ml-[8px]'} color={COLORS.WHITE} />
                    </DSButton.Icon>
                  </DSButton>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[10px]">
            <div className={subHeaderClassName} id="TextButton">
              Text DSButton
            </div>
            <p>sm</p>
            <div className="flex items-center gap-[20px]">
              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.SM} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.SM} onClick={handleButtonClick}>
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton
                  type={BUTTON_TYPE.TEXT}
                  size={BUTTON_SIZE.SM}
                  disabled={true}
                  customBgColor={COLORS.BLUE900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className={'ml-[2px]'} />
                  </DSButton.Icon>
                </DSButton>
              </div>
            </div>

            <p>md</p>
            <div className="flex items-center gap-[20px]">
              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.MD} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.MD} onClick={handleButtonClick}>
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton
                  type={BUTTON_TYPE.TEXT}
                  size={BUTTON_SIZE.MD}
                  disabled={true}
                  customBgColor={COLORS.BLUE900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className="ml-[2px]" />
                  </DSButton.Icon>
                </DSButton>
              </div>
            </div>

            <p>lg</p>
            <div className="flex items-center gap-[20px]">
              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.LG} onClick={handleButtonClick}>
                  <DSButton.Label>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_chevron_right width={16} height={16} className={'ml-[2px]'} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton type={BUTTON_TYPE.TEXT} size={BUTTON_SIZE.LG} onClick={handleButtonClick}>
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default width={16} height={16} className={'ml-[2px]'} color={COLORS.PURPLE} />
                  </DSButton.Icon>
                </DSButton>
              </div>

              <div>
                <DSButton
                  type={BUTTON_TYPE.TEXT}
                  size={BUTTON_SIZE.LG}
                  disabled={true}
                  customBgColor={COLORS.BLUE900}
                  onClick={handleButtonClick}
                >
                  <DSButton.Label customTxtColor={COLORS.PURPLE}>DSButton</DSButton.Label>
                  <DSButton.Icon alt={'plus icon'}>
                    <QIcon_plus_default color={COLORS.GRAY100} width={16} height={16} className={'ml-[2px]'} />
                  </DSButton.Icon>
                </DSButton>
              </div>
            </div>
          </div>
        </section>

        <section id="BgColor">
          <div className={headerClassName}>Background Color</div>

          <div className={descriptionClassName}>
            {/* <div> */}
            <label htmlFor="customBgColor">
              배경색 코드를 입력해주세요 (bg- 로 시작되는 토큰값에 한해 적용됩니다):{' '}
            </label>
            <input id="bgColor" type="text" className="border" value={customBgColor} onChange={handleInputChange} />
            {/* </div> */}
          </div>
          <div className="flex flex-col items-start gap-[10px]">
            {sizeList.map((size: BUTTON_SIZE_KEY) => (
              <div key={size as string}>
                <DSButton size={size} customBgColor={customBgColor} onClick={handleButtonClick}>
                  <DSButton.Label>{size as string}</DSButton.Label>
                </DSButton>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Legacy" className={anchorClassName}>
          Legacy
        </a>
        <a href="#DSButton" className={anchorClassName}>
          DSButton
        </a>
        <a href="#ExampleCode" className={anchorClassName}>
          Example Code
        </a>
        <a href="#States" className={anchorClassName}>
          States
        </a>
        <a href="#Sizes" className={anchorClassName}>
          Sizes
        </a>
        <a href="#IconButton" className={anchorClassName}>
          Icon Button
        </a>
        <a href="#TextButton" className={anchorClassName}>
          Text Button
        </a>
        <a href="#BgColor" className={anchorClassName}>
          BgColor
        </a>
      </div>
    </div>
  )
}

export default ButtonPage
