import * as svgIconsModule from './modules/svg'

const svgIcons = Object(svgIconsModule)

interface ILegacyIcon {
  iconKey: string
}

const LegacyIcon = (props: ILegacyIcon) => {
  const { iconKey } = props

  return (
    <a
      key={iconKey}
      className="flex h-[128px] w-[128px] cursor-pointer flex-col items-center justify-start gap-[16px] rounded-[5px] hover:bg-gray-200"
      href={svgIcons[iconKey]}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="mt-[32px] h-[32px] w-[32px] overflow-hidden">
        <img alt={iconKey} src={svgIcons[iconKey]} width={32} height={32} />
      </div>
      <p className="w-full break-words px-[12px] text-center text-[12px]">{iconKey}</p>
    </a>
  )
}

const LegacyIcons = () => {
  return (
    <div className="flex flex-wrap gap-[16px]">
      {Object.keys(svgIcons).map((iconKey) => (
        <LegacyIcon key={iconKey} iconKey={iconKey} />
      ))}
    </div>
  )
}

export default LegacyIcons
