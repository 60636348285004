import { MutableRefObject, useRef, useState } from 'react'
import Input from '../../../../../apps/ringle-student/components/_legacy/Input'
import { DSInput } from '../../index'
import { emailRegExpServer } from '../modules/auth'
import { anchorClassName, descriptionClassName, headerClassName } from '../style'
import SectionInputExample from './Input/SectionInputExample'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Inputs = () => {
  return (
    <div>
      <p id="Inputs" className={headerClassName}>
        Inputs
      </p>
      <p className={descriptionClassName}>Input은 문자, 숫자, 기호등을 입력하고 편집할 수 있는 입력 컴포넌트입니다.</p>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const States = () => {
  const [value1, setValue1] = useState<string>('')
  const [value2, setValue2] = useState<string>('')
  const [value3, setValue3] = useState<string>('')
  const [value4, setValue4] = useState<string>('')

  const statesArray = [
    {
      state: 'SM',
      component: (
        <DSInput
          type="email"
          upperText="Email"
          placeholder="email@ringleplus.com"
          value={value1}
          name={'email_input'}
          setValue={setValue1}
          clearable
          bottomText="인증가능한 이메일을 입력해주세요"
          size="sm"
          required
          regex={{
            pattern: emailRegExpServer,
            message: '유효한 형태의 이메일을 입력해주세요.',
          }}
        />
      ),
    },
    {
      state: 'SM + Text Counter',
      component: (
        <DSInput
          type="email"
          upperText="Email"
          placeholder="email@ringleplus.com"
          value={value2}
          name={'email_input'}
          setValue={setValue2}
          clearable
          bottomText="인증가능한 이메일을 입력해주세요"
          size="sm"
          required
          regex={{
            pattern: emailRegExpServer,
            message: '유효한 형태의 이메일을 입력해주세요.',
          }}
          textCounter={{ maxLength: 50 }}
        />
      ),
    },
    {
      state: 'MD',
      component: (
        <DSInput
          type="email"
          upperText="Email"
          placeholder="email@ringleplus.com"
          value={value3}
          name={'email_input_2'}
          setValue={setValue3}
          clearable
          bottomText="인증가능한 이메일을 입력해주세요"
          size="md"
          required
          regex={{
            pattern: emailRegExpServer,
            message: '유효한 형태의 이메일을 입력해주세요.',
          }}
        />
      ),
    },
    {
      state: 'MD + Text Counter',
      component: (
        <DSInput
          type="email"
          upperText="Email"
          placeholder="email@ringleplus.com"
          value={value4}
          name={'email_input_2'}
          setValue={setValue4}
          clearable
          bottomText="인증가능한 이메일을 입력해주세요"
          size="md"
          required
          regex={{
            pattern: emailRegExpServer,
            message: '유효한 형태의 이메일을 입력해주세요.',
          }}
          textCounter={{ maxLength: 50 }}
        />
      ),
    },
  ]
  return (
    <div>
      <p id="States" className={headerClassName}>
        States
      </p>
      <h2>SM/MD inputs</h2>
      <p>특정 섹션에 비교적 간단한 입력을 요청하는 경우에 사용됩니다.</p>
      <ul className="mt-[24px]">
        {statesArray.map(({ state, component }, idx) => (
          <li key={idx + '_input'} className="mb-[24px] flex items-start">
            <span className="col-3 text-[12px] font-medium">{state}</span>
            <div className="col-5 pr-[40px]">{component}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Legacy = () => {
  const messageRef: MutableRefObject<any> = useRef(null)

  return (
    <div>
      <p id="Legacy" className={headerClassName}>
        Legacy Inputs
      </p>
      <p className={descriptionClassName}>
        Legacy Inputs을 사용하고 있는 곳에서 DSInput을 사용하도록 변경이 필요합니다.
        <br />
        student, teens, tutor, tutor-apply 각각에 모두 존재합니다.
      </p>
      <div className="mt-[16px] flex flex-row items-start gap-[28px]">
        <Input style={{ width: 150 }} placeholder="Legacy Inputs" ref={messageRef} />
        <div>{messageRef?.current?.value}</div>
      </div>
    </div>
  )
}

const InputComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[8px]">
        <Inputs />
        <SectionInputExample />
        <States />
        <Legacy />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Inputs" className={anchorClassName}>
          Inputs
        </a>
        <a href="#ExampleCode" className={anchorClassName}>
          Example Code
        </a>
        <a href="#States" className={anchorClassName}>
          States
        </a>
        <a href="#Legacy Inputs" className={anchorClassName}>
          Legacy Inputs
        </a>
      </div>
    </div>
  )
}

export default InputComponent
