import classNames from 'classnames'
import { useState } from 'react'
import { DropdownContext } from '../modules/DropdownStateContext'
import { DROPDOWN_SIZE } from '../types'

type DropdownOption = {
  label: string
  value: any
}
interface IDropdown extends React.PropsWithChildren {
  size: DROPDOWN_SIZE[keyof DROPDOWN_SIZE]
  active?: boolean
  disabled?: boolean
  customBgColor?: string
  // children?: React.ReactNode | React.ReactNode[] | Element | Element[]
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  selected: DropdownOption
  setSelected: React.Dispatch<React.SetStateAction<DropdownOption>>
  isTeens?: boolean
}
/**
 * 드랍다운의 width는 외부에서 조절합니다. (because of w-full)
 * @param param0
 * @returns
 */
const DropdownMain = ({
  children,
  size,
  disabled = false,
  onClick,
  className = '',
  customBgColor = '',
  selected,
  setSelected,
  isTeens = false,
}: IDropdown) => {
  const [open, setOpen] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  return (
    <DropdownContext.Provider
      value={{
        size,
        active,
        setActive,
        disabled,
        onClick,
        customBgColor,
        open,
        setOpen,
        selected,
        setSelected,
        isTeens,
      }}
    >
      <button className={classNames('relative w-full', className)} onClick={onClick}>
        {children}
      </button>
    </DropdownContext.Provider>
  )
}

export default DropdownMain
