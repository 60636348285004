// @ts-strict-ignore
import getConfig from 'next/config'

export const getEnv = (envName: string, fallback: string = null): string => {
  const config = getConfig()
  const publicRuntimeConfig = config?.publicRuntimeConfig

  if (!envName) {
    return fallback
  }

  if (typeof window !== 'undefined') {
    return process?.env?.[envName] ?? publicRuntimeConfig?.[envName] ?? fallback
  } else {
    return process?.env?.[envName] ?? publicRuntimeConfig[envName] ?? fallback
  }
}
