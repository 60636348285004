/* ------------------------ Initial Props Type / Interfaces ------------------------ */

/**
 * T == JSON Type
 * V == Other additional data types
 */
export type InitialProps<T = InitialPropsJson, V = InitialPropsData> = V & InitialJson<T> & IInitialUserData

export interface InitialJson<T> {
  json?: JsonBase<T>
}

export type JsonBase<T> = Partial<T> & {
  success?: boolean
}

export interface IInitialUserData {
  currentUser?: CurrentUser
  ogData?: IOpenGraphData
  seoData?: ISeoData
}

/**
 * Temp Initial Props JSON Type
 * - Need to create diff JSON interfaces depending on API endpoints and payload
 */
export type InitialPropsJson = {
  [key: string]: any | any[]
}

/**
 * Temp Initial Props Data Type
 * - Other than json & currentUser, InitialPropsData refers to all the other key-properties within an API call
 * - This varies depending on what other props you want to pass to the component
 * - Make sure to create a type for the general payload of an API -> and then extend it using other interfaces or types within the component (if it's component specific)
 */
export type InitialPropsData = {
  [key: string]: any | any[]
}

/* --------------------- Current User Interface / Types --------------------- */

export type CurrentUser = IStudentUser & ITutorUser & ITeenParentUser

/**
 * Student Teens User
 * Student Plus User
 */
export interface IStudentUser {
  banner: any[]
  bonus_coupons: IUserCoupon[]
  braze: any
  code: string
  company_code: string
  country_code: string
  discount_coupons: IUserCoupon[]
  welcome_coupon: IUserCoupon[]
  email: string
  id: number
  image_url: string
  is_junior: boolean
  is_multicampus_member: boolean
  is_ringle_member: boolean
  jwt_token: string
  locale: string
  phone_verified: true
  profile: IUserProfile
  referral_code: string
  referral_event: boolean
  role: USER_ROLE
  show_first_coupon: boolean
  show_welcome_back: boolean
  student_card: IStudentCard
  time_now_in_time_zone: {
    year: number
    month: number
    day: number
    hours: number
    minutes: number
  }
  timezone: string
  trial_completed: boolean
  event: IEvent
  type: UserType
  unused_coupons: number
  upcoming_lesson_count: number
  silicon_valley_challenge_books: any[]
  user_info: IUserInfo
  special_offer_coupons: IUserCoupon[]
  zoom_installed: boolean

  [key: string | number]: any | any[]
}

export interface ITutorUser {
  allowed_schedule: boolean
  available_balance: number
  braze: {
    RI: number
    accept_rate: number
    birthday: null
    cancel_rate: string
    email: string
    first_lesson_completed_at: string
    id: number
    image_url: string
    is_deactivated: false
    is_ringle_member: true
    last_slot_opened_this_week_start_time: string
    latest_lesson_start_time: string
    level: number
    mock_apply_step: number
    mock_apply_sub_step: number
    mock_apply_updated_at: string
    mock_session_apply_platform: string
    mock_session_passed: boolean
    mock_session_start_time: string
    mock_session_status: string
    name: string
    noshows: number
    notification_event: boolean
    notification_newsletter: boolean
    notification_unassigned: boolean
    ot_completed_at: string | null
    past_lesson_count: number
    phone: string
    resume_completed_at: string
    resume_completed_reason: string
    resume_status: string
    ringle_doc_tutorial_step: number
    school: string
    school_tier: number
    sign_up_date: string
    sign_up_medium: string
    slots_opened_this_week_count: number
    status_id: number
    timezone: string
    upcoming_lesson_count: number
    watching_best_practice_video: boolean
    watching_mock_session_video: boolean
  }
  email: string
  first_name: string
  id: number
  image_url: string
  is_golden_star: boolean
  is_ringle_member: boolean
  is_teens: boolean
  jwt_token: string
  level: number
  locale: string
  mock_apply: any
  mock_session_passed: boolean
  need_to_popup_policy: boolean
  not_allowed_log_in: boolean
  orientation_done: boolean
  past_lesson_count: number
  payment_type: number
  paypal_account: string
  point: number
  profile: {
    accept_rate: number
    cancel_rate: string
    email: string
    first_name: string
    level: number
    mailing_address: {
      country: string
      state: string
      city: string
      address: string
      zip_code: string
    }
    major: string
    mobileNumber: string
    name: string
    noshows: number
    school: string
    school_tier: number
    status: string
  }
  push_notifications: {
    content: string
    has_read: boolean
    id: number
    title: string | null
    updated_at: string
  }[]
  role: USER_ROLE
  salary: number
  salary20: number
  stars: number
  status_sub_url: string
  status_url: string
  teens_level: number
  teens_ot_passed: boolean
  teens_point: number
  teens_salary: number
  teens_salary20: number
  time_now_in_time_zone: {
    year: number
    month: number
    day: number
    hours: number
    minutes: number
  }
  timezone: string
  total_lesson_count: number
  tutor_info: {
    // TODO : any type 제거
    career: any[]
    career_detail: any
    coupon_usage: any
    created_at: string
    expected_graduation_year: any
    experience_full_time: any
    experience_intern: any
    final_education: any
    first_lesson_completed_at: string
    how_did_you_learn_about_us: any
    id: number
    junior_preference: number
    link_poe_form: string
    link_w9_form: string
    pinned: number
    ringle_title: any
    ringledoc_tutorial: number
    signup_event_funnel: string
    specialty: any[]
    tax_form_type: number
    teach_status: number
    teaching_experience: boolean
    updated_at: string
    user_id: number
    verified_poe_form: number
    block_intention: any
    revisit_intention: any
  }
  tutor_status_id: number // TODO enum 치환
  upcoming_lesson_count: number
  uploaded_image: string
  writing_task_accessible: boolean
  is_top_30: boolean
  stage_for_teens_tutor: 'OT' | 'UNDER_REVIEW' | 'POE' | 'PROFILE'
}

export interface ITeenParentUser {
  birth_day: string
  by_parent: boolean
  children: IUserObject[]
  company: any
  company_code: any
  company_work: any
  email: string | null
  email2: string | null
  email_accept: boolean | null
  email_accept_2: boolean | null
  first_name: string
  id: number
  image_default: any
  image_url: string | null
  is_junior: boolean
  is_multicampus_member: boolean
  is_ringle_member: boolean
  jwt_token: string
  korean_name: string
  last_name: string
  locale: string
  logged_by_parent: boolean | null
  name: string
  notif_hr_array: number[] | null
  parent: IUserObject
  phone: string | null
  phone_verified: boolean
  points: number
  referral_code: string | null
  referral_info: string | null
  reminder_email: boolean
  reminder_push: boolean
  reminder_text: boolean
  role: USER_ROLE // TODO: Enum으로 치환해야함
  session_role: SessionRoleType
  settings: IUserSetting[]
  success: boolean
  timezone: string
  timezone_list: string[]
  trial_completed: boolean
  type: UserType
  upcoming_lesson_count: number
  zoom_installed: boolean

  // TODO: 틴즈 쪽에 이게 있는지 / 필요한지 확인 필요. CsrOnlyStudentBrazePixel쪽 타입 에러 원인
  profile?: any
  student_card?: any
  braze?: any
}

// * Teen User Type
export type UserType = 'Student' | 'Parent' | string
export type SessionRoleType = 'Parent' | 'Child'

export enum USER_ROLE {
  STUDENT = 0,
  TUTOR = 1,
  ADMIN = 3,
}

export interface IUserObject {
  email: string | null
  first_name: string
  id: any
  image_default: IUserObjectImage
  image_url: string | null
  last_name: string
  name: string
  session_role: SessionRoleType
  timezone: string
}

export interface IUserObjectImage {
  color: string
  initial: string
}

export interface IUserSetting {
  title: string
  id: number
  value: boolean
  option: number
  description?: string
}

export interface IUserProfile {
  email: string
  korean_name: string
  locale: string
  mobileNumber: string
  name: string
  phone_intl: any
  total_amount: number
  total_lesson: number
  consent_all_mkt_channel: boolean
  entered_lesson_count: number
  finished_lesson_count: number
}

export interface IUserInfo {
  created_at: string
  first_name: string
  id: number
  is_public: true
  job_id: number
  last_name: string
  lesson_purpose_ids: number[]
  other_job: string
  other_lesson_purpose: any
  status: number
  updated_at: string
  user_id: number
  video_id: any
}

export interface IUserCoupon {
  amount: number
  available_products: number[]
  bonus_coupon_type_id: number
  code: string
  created_at: string
  expired_at: string
  id: number
  obtainable_id: string
  obtainable_type: any
  target: any
  updated_at: string
  usable_id: any
  usable_type: any
  used: boolean
  used_at: string
  user_id: number
}

// STUDENT
export interface IStudentCard {
  company: any
  company_code: any
  company_work: any
  email: string
  email2: string
  email_accept: boolean
  email_accept_2: boolean
  first_name: string
  id: number
  image_url: string
  is_junior: boolean
  is_multicampus_member: boolean
  is_ringle_member: boolean
  korean_name: string
  last_name: string
  locale: string
  notif_hr_array: number[]
  password: any
  phone: string
  points: number
  referral_code: string
  reminder_email: boolean
  reminder_push: boolean
  reminder_text: boolean
  settings: IUserSetting[]
  timezone: string
  trial_completed: boolean
  zoom_installed: boolean
}

export interface IEvent {
  purchased_in_period: boolean
  dday: number
  name: string
  period: string
}

/* --------------------- Current User Interface / Types --------------------- */

export interface ICurrentGeo {
  city: string
  country: string
  country_code: string
  currency: string
  timezone: string
}

/* --------------------- Open Graph Data --------------------- */

export interface IOpenGraphData {
  [key: string]: any | any[]
}

export interface ISeoData {
  pageTitle: string
  pageDescription: string
  pageKeywords: string
  pageCanonicalPath?: string
}
