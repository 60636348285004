// @ts-strict-ignore
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { removeJwtToken, removeSessionRole } from '../auth'
import { getInitialLocale } from '../i18n/getInitialLocale'
import urls from '../urls'
import { COOKIE_LIST, SESSION_ROLE } from '../vars'

export const createAxiosTokenConfig = (token: string, data?) => {
  if (data) {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      }
    } else {
      return { data }
    }
  } else {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    } else {
      return {}
    }
  }
}

export const handleAxiosError = (e: AxiosError<any>, customErrMsg = '') => {
  console.error(e)

  if (e.response) {
    toast.error(`Server Error: ${e.response.status}: There has been an error. Please contact Ringle Team.`, {
      position: 'top-right',
    })

    e.response.data?.error &&
      toast.error(e.response.data?.error, {
        position: 'top-right',
      })

    if (e.response.status === 401) {
      removeJwtToken()

      const cookies = new Cookies()

      console.log('HERE?')

      if (cookies.get(COOKIE_LIST.SESSION_ROLE) === SESSION_ROLE.TUTOR) {
        removeSessionRole()
        window.location.href = `/${getInitialLocale()}${urls.tutor.landing.home}`
      } else {
        removeSessionRole()
        window.location.href = `/${getInitialLocale()}${urls.student.landing.home}`
      }
    }
  } else if (e.request) {
    console.error(e.request)
    toast.error(`Server Not Responding: The server is not responding at the moment. Please contact Ringle Team.`, {
      position: 'top-right',
    })
  } else {
    console.error(e.message)
    toast.error(`Error before Request: ${e.message}. Please contact Ringle Team.`, {
      position: 'top-right',
    })
  }

  if (customErrMsg) {
    toast.error(customErrMsg, {
      position: 'top-right',
    })
  }
}
