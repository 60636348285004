import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useDropdownState } from '../modules/DropdownStateContext'
import { DROPDOWN_SIZE } from '../types'
interface DropdownLabelProps extends PropsWithChildren {
  className?: string
  inactive?: boolean
}
const DropdownLabel = ({ children, className, inactive }: DropdownLabelProps) => {
  const { size, active, disabled, isTeens } = useDropdownState()
  const activeState = inactive ? false : active
  return <div className={styles.label({ size, activeState, disabled, isTeens })}>{children}</div>
}

export default DropdownLabel

const styles = {
  label: ({ size, activeState, disabled, isTeens }) => {
    const activeStateColor = isTeens ? 'text-blue-500' : 'text-purple-500'
    return classNames(
      'overflow-hidden truncate',
      size === DROPDOWN_SIZE.SM && `Caption-12Md min-h-[19px]`,
      size === DROPDOWN_SIZE.MD && `Body2-14Md min-h-[22px] `,
      size === DROPDOWN_SIZE.LG && `Body1-16Md min-h-[26px]`,
      !activeState && `text-black`,
      activeState && activeStateColor,
      disabled && `text-gray-300`
    )
  },
}
