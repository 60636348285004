import classNames from 'classnames'
import useTranslation from '../../modules/i18n/useTranslation'

interface BrProps {
  up?: boolean
  down?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  ko?: string
  en?: string
}

const Br = ({ up, down, sm, md, lg, xl, ko, en }: BrProps) => {
  const { locale } = useTranslation()

  return (
    <br
      className={classNames(
        { 'd-none hidden': ko && ko != locale },
        { 'd-none hidden': en && en != locale },
        { 'd-none d-sm-inline hidden sm:inline': sm && up },
        { 'd-none d-md-inline hidden md:inline': md && up },
        { 'd-none d-lg-inline hidden lg:inline': lg && up },
        { 'd-none d-xl-inline hidden xl:inline': xl && up },
        { 'd-md-none md:hidden': sm && down },
        { 'd-lg-none lg:hidden': md && down },
        { 'd-xl-none xl:hidden': lg && down }
      )}
    />
  )
}

export default Br
