import { useState } from 'react'
import { Dropdown } from '../../index'
import { DROPDOWN_SIZE } from '../Components/Dropdown/types'
import DropdownButton from '../Components/Dropdown/ui/DropdownButton'
import DropdownMenu from '../Components/Dropdown/ui/DropdownMenu'
import DropdownMenuItem from '../Components/Dropdown/ui/DropdownMenuItem'
import { descriptionClassName, headerClassName } from '../style'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const States = () => {
  const [selected1, setSelected1] = useState({ label: '', value: undefined })
  const [selected2, setSelected2] = useState({ label: '', value: undefined })
  const [selected3, setSelected3] = useState({ label: '', value: undefined })

  const options = [
    { label: 'option1', value: 1 },
    { label: 'option2', value: 2 },
    { label: 'option3', value: 3 },
    { label: 'option4', value: 4 },
    { label: 'option4', value: 4 },
    { label: 'option4', value: 4 },
    { label: 'option4', value: 4 },
    { label: 'option4', value: 4 },
    { label: 'option4', value: 4 },
  ]

  const option1 = [
    { label: 'option112312312', value: 1 },
    { label: 'option23123123', value: 2 },
    { label: 'option3123123123123123123', value: 3 },
    { label: 'option4;erkglsdfjgskdjfglksdjfg;lksjdf', value: 4 },
  ]
  return (
    <div className="flex flex-col gap-[13px]">
      <p className={descriptionClassName}>
        Dropdown은 사용자가 하나 이상의 옵션을 선택할 수 있는 옵션 목록을 제공합니다. Menu는 옵션 목록을 표시합니다.
        선택한 옵션은 콘텐츠를 필터링하거나 정렬할 수 있습니다.
      </p>
      <section>
        <p className={headerClassName}>Anatomy (Menu)</p>
        <p className={descriptionClassName}>
          Container안에 모든 메뉴 항목이 들어있습니다. List item에는 텍스트, 아이콘이 포함될 수 있습니다. List item을
          그룹으로 구분할 경우 Divider를 추가하여 사용합니다.
        </p>
      </section>
      <section>
        <p className={headerClassName}>States</p>
        <div className="flex flex-row gap-[32px]">
          <div className="flex flex-row gap-[32px]">
            <div>
              <div className="w-[320px]">
                <div className="Caption-12Md">SM</div>
                <Dropdown selected={selected1} setSelected={setSelected1} size={DROPDOWN_SIZE.SM}>
                  <DropdownButton>{selected1.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="w-[320px]">
                <div className="Body2-14Md">MD</div>
                <Dropdown selected={selected2} setSelected={setSelected2} size={DROPDOWN_SIZE.MD}>
                  <DropdownButton>{selected2.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="w-[320px]">
                <div className="Body1-16Md">LG</div>
                <Dropdown selected={selected3} setSelected={setSelected3} size={DROPDOWN_SIZE.LG}>
                  <DropdownButton>{selected3.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              <div className="w-[84px]">
                <div className="Caption-12Md">SM</div>
                <Dropdown selected={selected1} setSelected={setSelected1} size={DROPDOWN_SIZE.SM}>
                  <DropdownButton>{selected1.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="w-[84px]">
                <div className="Body2-14Md">MD</div>
                <Dropdown selected={selected2} setSelected={setSelected2} size={DROPDOWN_SIZE.MD}>
                  <DropdownButton>{selected2.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="w-[84px]">
                <div className="Body1-16Md">LG</div>
                <Dropdown selected={selected3} setSelected={setSelected3} size={DROPDOWN_SIZE.LG}>
                  <DropdownButton>{selected3.label}</DropdownButton>
                  <DropdownMenu>
                    {options.map((item, i) => {
                      return (
                        <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                          {item.label}
                        </DropdownMenuItem>
                      )
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div></div>
          <div className="flex flex-col">
            <div className="w-[84px]">
              <div className="Caption-12Md">SM</div>
              <Dropdown selected={selected1} setSelected={setSelected1} size={DROPDOWN_SIZE.SM}>
                <DropdownButton>{selected1.label}</DropdownButton>
                <DropdownMenu>
                  {option1.map((item, i) => {
                    return (
                      <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                        {item.label}
                      </DropdownMenuItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="w-[84px]">
              <div className="Body2-14Md">MD</div>
              <Dropdown selected={selected2} setSelected={setSelected2} size={DROPDOWN_SIZE.MD}>
                <DropdownButton>{selected2.label}</DropdownButton>
                <DropdownMenu>
                  {option1.map((item, i) => {
                    return (
                      <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                        {item.label}
                      </DropdownMenuItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="w-[84px]">
              <div className="Body1-16Md">LG</div>
              <Dropdown selected={selected3} setSelected={setSelected3} size={DROPDOWN_SIZE.LG} disabled={true}>
                <DropdownButton>{selected3.label}</DropdownButton>
                <DropdownMenu>
                  {option1.map((item, i) => {
                    return (
                      <DropdownMenuItem key={i} value={item.value} divider={i === 2}>
                        {item.label}
                      </DropdownMenuItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const DropdownComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <States />
    </div>
  )
}

export default DropdownComponent
