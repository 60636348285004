import { COLORS, DSTooltip } from '@repo/design-system/index'
import { QIcon_bell_default } from '@repo/icons/index'
import { subHeaderClassName } from '../../style'

interface SectionTooltipProps {
  text: string
  place: 'top' | 'right' | 'bottom' | 'left'
  isMultiline: boolean
  remountFlag: boolean
}

const TextOnlyAuto = ({ text, place, isMultiline, remountFlag }: SectionTooltipProps) => {
  return (
    remountFlag && (
      <div className="mt-[20px]">
        <p className={subHeaderClassName}>Text Only (Auto)</p>
        <div className="flex h-[200px] w-[800px] flex-row items-center justify-center">
          <DSTooltip
            place={place}
            isMultiline={isMultiline}
            triggerComponent={<QIcon_bell_default color={COLORS.PURPLE} className="cursor-pointer" />}
            contentComponent={text}
          />
        </div>
      </div>
    )
  )
}

export default TextOnlyAuto
