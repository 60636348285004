import { createContext, useContext } from 'react'
import { DROPDOWN_SIZE } from '../types'

export interface DropdownContextType {
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  size: DROPDOWN_SIZE[keyof DROPDOWN_SIZE]
  customBgColor?: string
  active: boolean
  setActive
  disabled: boolean
  onClick: any
  open: boolean
  setOpen: any
  selected
  setSelected
  isTeens?: boolean
}

export const DropdownContext = createContext<DropdownContextType | null>(null)

export const useDropdownState = () => {
  const context = useContext(DropdownContext)
  if (!context) {
    throw new Error('useDropdownState DropdownProvider 안에서만 사용 가능합니다.')
  }
  return context
}
