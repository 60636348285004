const headerClassName = 'Hero3-36Bd my-[8px]'
const subHeaderClassName = 'Headline2-24Bd my-[8px]'
const ControlClassName = 'flex flex-row items-end font-bold py-[8px] min-w-[180px]'
const descriptionClassName = 'Body1-14Md'
const anchorClassName = 'font-semibold text-blue hover:underline'
const CodeClassName = 'bg-gray-100 text-[14px] text-black p-[4px] rounded-[4px]'

const colContainerClassName = 'flex flex-col space-y-[8px]'
const rowContainerClassName = 'flex flex-row space-x-[24px]'
const gridContainerClassName = 'grid grid-cols-3 gap-[8px]'
const containerClassNameList = [colContainerClassName, rowContainerClassName, gridContainerClassName]

export {
  CodeClassName,
  ControlClassName,
  anchorClassName,
  colContainerClassName,
  containerClassNameList,
  descriptionClassName,
  gridContainerClassName,
  headerClassName,
  rowContainerClassName,
  subHeaderClassName,
}
