import { useDSModalState } from '../modules/DSModalContext'
import { DSMODAL_SIZE_TYPE } from './DSModalMain'

type DSMODAL_DIRECTION_TYPE = 'horizontal' | 'vertical'

export interface DSModalButtonLayoutProps {
  direction: DSMODAL_DIRECTION_TYPE
  children: React.ReactNode
  classNameFromProps?: string
}

const DSModalButtonContainer = (props: DSModalButtonLayoutProps) => {
  const { direction, children, classNameFromProps } = props
  const { size } = useDSModalState()

  const className = SIZE_CLASSNAME[size] + ' ' + DIRECTION_CLASSNAME[direction] + ' ' + classNameFromProps

  return <div className={className}>{children}</div>
}

export default DSModalButtonContainer

const SIZE_CLASSNAME: { [size in DSMODAL_SIZE_TYPE]?: string } = {
  sm: 'mt-[24px]',
  lg: 'mt-[40px]',
  xl: 'mt-[24px] justify-between',
}

const DIRECTION_CLASSNAME: { [direction in DSMODAL_DIRECTION_TYPE]: string } = {
  horizontal: 'flex flex-row space-x-[8px]',
  vertical: '',
}
