import { QIcon_check_mark, QIcon_plus_default } from '@repo/icons/index'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useChipState } from '../modules/ChipStateContext'
import { CHIP_SIZE } from '../types'
interface ChipIconProps extends PropsWithChildren {
  className?: string
  isIconVisible?: boolean
}

const ChipIcon: React.FC<ChipIconProps> = ({ className = '', isIconVisible = true }) => {
  const { size, selected } = useChipState()
  return selected ? (
    <QIcon_check_mark className={classNames(className, styles.icon({ size, selected, isIconVisible }))} />
  ) : (
    <QIcon_plus_default className={styles.icon({ size, selected, isIconVisible })} />
  )
}

export default ChipIcon

const styles = {
  icon: ({ size, selected, isIconVisible }) => {
    return classNames(
      `ml-[4px] flex items-center justify-center`,
      size === CHIP_SIZE.SM && `h-[12px] w-[12px]`,
      size === CHIP_SIZE.MD && `h-[14px] w-[14px]`,
      size === CHIP_SIZE.LG && `h-[16px] w-[16px]`,
      selected && `text-purple-500`,
      !selected && `text-gray-300`,
      !isIconVisible && !selected && `invisible`
    )
  },
}
