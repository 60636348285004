/* eslint-disable-next-line */
export interface DesignSystemProps {}

export function DesignSystem(props: DesignSystemProps) {
  return (
    <div>
      <h1>Welcome to DesignSystem!</h1>
    </div>
  )
}

export default DesignSystem
