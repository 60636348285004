import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PointJson } from '../../model/point.interface'
import apis, { webview_apis } from '../apis'
import { PAST_FILTER_TYPE } from '../apis/student/lesson'
import { getJwtToken } from '../auth'
import { axiosPost } from '../axiosHelper'
import { LocaleType } from '../i18n/config'
import QueryApi from './queryApiClass'

//* ============================================================================================================== */
//* landing.ts
//* ============================================================================================================== */

interface IlandingCoursesQueryApiSearchParams {
  locale: LocaleType
}

export const landingCoursesQueryApi = new QueryApi<void, IlandingCoursesQueryApiSearchParams>({
  api: apis.landing.topic,
})

interface IlandingTutorsQueryApiSearchParams {
  locale: LocaleType
}

export const landingTutorsQueryApi = new QueryApi<void, IlandingTutorsQueryApiSearchParams>({ api: apis.landing.tutor })

//* ============================================================================================================== */
//* user.ts
//* ============================================================================================================== */

export const userQueryApi = new QueryApi({ api: apis.auth.me })

export const eventPropsUserInfoQueryApi = new QueryApi({ api: apis.auth.eventProps.userInfo })

export const profileQueryApi = new QueryApi({ api: apis.profile.get })

export const upcomingsQueryApi = new QueryApi({ api: apis.lessons.upcomings })

export const upcomingsQueryApiV5 = new QueryApi({ api: apis.lessons.upcomingsV5 })

export const recommendLessonTimeQueryApiV5 = new QueryApi({ api: apis.lessons.recommendTime })

export const upcomingsCountQueryApi = new QueryApi({ api: apis.lessons.upcomingsCount })

//* ============================================================================================================== */
//* classroom.ts
//* ============================================================================================================== */

interface IStudentClassroomQueryApiPathParams {
  lessonId: string
}

export const studentClassroomQueryApi = new QueryApi<IStudentClassroomQueryApiPathParams>({
  api: apis.lessons.classroom,
})

export const classroomZoomQueryApi = new QueryApi<IStudentClassroomQueryApiPathParams>({
  api: apis.lessons.zoom,
})

export const classroomDocsQueryApi = new QueryApi<IStudentClassroomQueryApiPathParams>({
  api: apis.lessons.docs,
})

//* ============================================================================================================== */
//* credit.ts
//* ============================================================================================================== */

export const companyEducationsQueryApi = new QueryApi({ api: apis.company_educations.get })

export const companyEducationsConversationQueryApi = new QueryApi({ api: apis.company_educations.conversation })

export const companyEducationsOnlyAITutorQueryApi = new QueryApi({ api: apis.company_educations.aiTutor })

export const remainingCreditsCountQueryApi = new QueryApi({ api: apis.credit.couponBundlesSummary })

export const pointsQueryApi = new QueryApi({ api: apis.point.list })

interface ICouponBundleDetailsQueryApiPathParams {
  bundleId: number
}

export const couponBundleDetailsQueryApi = new QueryApi<ICouponBundleDetailsQueryApiPathParams>({
  api: apis.credit.couponBundlesDetails,
})

interface ICouponExtendInfoQueryApiPathParams {
  bundleId: number
}

export const couponExtendInfoQueryApi = new QueryApi<ICouponExtendInfoQueryApiPathParams>({
  api: apis.credit.couponExtendInfo,
})

interface ICouponPurchaseInfoQueryApiPathParams {
  bundleId: number
}

export const couponPurchaseInfoQueryApi = new QueryApi<ICouponPurchaseInfoQueryApiPathParams>({
  api: apis.purchase.certificate,
})

export const deductionGracedRecentlyUsed = new QueryApi({
  api: apis.credit.deductionGracedRecentlyUsed,
})

export const deductionGraceCreditsSummaryQueryApi = new QueryApi<IWeeklyLessonProgressQueryApiPathParams>({
  api: apis.credit.deductionGraceCreditsSummary,
})

interface IWeeklyLessonProgressQueryApiPathParams {
  bundleId: number
}

export const weeklyLessonProgressQueryApi = new QueryApi<IWeeklyLessonProgressQueryApiPathParams>({
  api: apis.credit.weeklyLessonProgress,
})

export const weeklyLessonPackageWarningQueryApi = new QueryApi({
  api: apis.credit.weeklyLessonPackageWarning,
})

//* ============================================================================================================== */
//* geolocation.ts
//* ============================================================================================================== */

export const currentGeoQueryApi = new QueryApi({ api: apis.geolocation })

//* ============================================================================================================== */
//* home.ts
//* ============================================================================================================== */

export const personalizationQueryApi = new QueryApi({ api: apis.personalization })

export const marketingInfoQueryApi = new QueryApi({ api: apis.home.marketing })

export const todoInfoQueryApi = new QueryApi({ api: apis.home.todo })

export const curationTutorsQueryApi = new QueryApi({ api: apis.curation.tutor })

export const curationPacketsQueryApi = new QueryApi({ api: apis.curation.material })

export const curationEventsQueryApi = new QueryApi({ api: apis.curation.event })

interface ITargetContentsQueryApiSearchParams {
  category_id: number
}

export const targetContentsQueryApi = new QueryApi<void, ITargetContentsQueryApiSearchParams>({
  api: apis.home.contents.get,
})

//* ============================================================================================================== */
//* share.ts
//* ============================================================================================================== */

export const challengeOgDataQueryApi = new QueryApi<IChallengeOgDataQueryApiPathParams>({ api: apis.share.challenge })

export interface IChallengeOgDataQueryApiPathParams {
  challengeId: string
  optionId: string
  locale: string
}

//* ============================================================================================================== */
//* lesson.ts
//* ============================================================================================================== */

interface ILessonPreparationQueryApiPathParams {
  lessonId: number
}

export const lessonPreparationQueryApi = new QueryApi<ILessonPreparationQueryApiPathParams>({
  api: apis.lessons.preparation.get,
})

export const lessonPreparationQueryApiV5 = new QueryApi<ILessonPreparationQueryApiPathParams>({
  api: apis.lessons.preparation.getV5,
})

interface ICafLessonStatusQueryApiPathParams {
  lessonId: number
}

export const cafLessonStatusQueryApi = new QueryApi<ICafLessonStatusQueryApiPathParams>({
  api: webview_apis.caf.status.check,
})
export const cafLessonStatusQueryApiV5 = new QueryApi<ICafLessonStatusQueryApiPathParams>({
  api: apis.caf.status.check,
})
export const cafLessonDiffQueryApi = new QueryApi<ICafLessonStatusQueryApiPathParams>({
  api: webview_apis.caf.status.diff,
})
export const cafLessonDiffQueryApiV5 = new QueryApi<ICafLessonStatusQueryApiPathParams>({
  api: apis.caf.status.diff,
})
export const cafLessonDiffFeedbackQueryApiV5 = new QueryApi<any>({
  api: apis.caf.status.diffFeedback,
})

//* ============================================================================================================== */
//* materials.ts
//* ============================================================================================================== */

export const materialsQueryApi = new QueryApi({ api: apis.course.courses })
export const interestedMaterialsQueryApi = new QueryApi({ api: apis.course.interest })
export const bookMarkedMaterialsQueryApi = new QueryApi({ api: apis.course.favorite })
export const experiencedMaterialsQueryApi = new QueryApi({ api: apis.course.experienced })
export const conversationMaterialsQueryApi = new QueryApi({ api: apis.course.conversation })

interface IMaterialsByCategoryIdQueryApiSearchParams {
  course_large_hashtag_id: number | string
}

export const materialsByCategoryIdQueryApi = new QueryApi<void, IMaterialsByCategoryIdQueryApiSearchParams>({
  api: apis.course.course_category,
})

interface IMaterialDetailByMaterialIdQueryApiSearchParams {
  id: number
}

export const materialDetailQueryApi = new QueryApi<IMaterialDetailByMaterialIdQueryApiSearchParams>({
  api: apis.course.detail,
})

interface ISelectMaterialGetLesosnInfoByLessonIdQueryApiSearchParams {
  id: number
}

export const selectMaterialGetLesosnInfoQueryApi =
  new QueryApi<ISelectMaterialGetLesosnInfoByLessonIdQueryApiSearchParams>({
    api: apis.lessons.upcoming,
  })

//* ============================================================================================================== */
//* notifications.ts
//* ============================================================================================================== */

export const notificationSettingsQueryApi = new QueryApi({ api: apis.auth.notificationSettings })

//* ============================================================================================================== */
//* products.ts
//* ============================================================================================================== */

interface IProductsListQueryApiSearchParams {
  locale: LocaleType
  currency: string
  discount?: boolean
}

export const productsB2CQueryApi = new QueryApi<void, IProductsListQueryApiSearchParams>({ api: apis.products.b2c })

export const productsB2BQueryApi = new QueryApi<void, IProductsListQueryApiSearchParams>({ api: apis.products.b2b })

export const customerTypeQueryApi = new QueryApi<void, void>({
  api: apis.products.customerType,
})

interface IProductsLineDetailQueryApiSearchParams {
  currency: string
}

export const productsLineDetailQueryApi = new QueryApi<
  { productLineId: number },
  IProductsLineDetailQueryApiSearchParams
>({
  api: apis.products.productLineDetail,
})

interface IProductsLineCompanyQueryApiSearchParams {
  currency: string
}

export const productsLineCompanyQueryApi = new QueryApi<null, IProductsLineCompanyQueryApiSearchParams>({
  api: apis.products.productLineCompany,
})

interface IB2BProductsListQueryApiSearchParams {
  locale: LocaleType
  country_code: string
}

export const b2bProductsListQueryApi = new QueryApi<void, IB2BProductsListQueryApiSearchParams>({
  api: apis.products.b2bList,
})

//* ============================================================================================================== */
//* promotion.ts
//* ============================================================================================================== */

export const promotionGnbQueryApi = new QueryApi({ api: apis.promotion.gnb })

export const promotionPopupQueryApi = new QueryApi({ api: apis.promotion.popup })

export const promotionTicketsQueryApi = new QueryApi({ api: apis.promotion.tickets })

export const promotionTicketStatusQueryApi = new QueryApi({ api: apis.promotion.ticketStatus })

//* ============================================================================================================== */
//* purchase.ts
//* ============================================================================================================== */

interface ISuccessPurchaseQueryApiPathParams {
  purchaseId: string
}

export const successPurchaseQueryApi = new QueryApi<ISuccessPurchaseQueryApiPathParams>({ api: apis.purchase.get })

interface IPreviousPurchaseQueryApiPathParams {
  productId: number
}

export const previousPurchaseQueryApi = new QueryApi<IPreviousPurchaseQueryApiPathParams>({
  api: apis.purchase.previous,
})

//* ============================================================================================================== */
//* tutors.ts
//* ============================================================================================================== */

export const myTutorsQueryApi = new QueryApi({ api: apis.tutor.my_type })

//* ============================================================================================================== */
//* caf.ts
//* ============================================================================================================== */

interface ICafAnalysisComponentListApiPathParams {
  lessonId: number
}

export const cafAnalysisComponentListQueryApi = new QueryApi<ICafAnalysisComponentListApiPathParams>({
  api: webview_apis.caf.analysisComponents.list,
})
export const cafAnalysisComponentListQueryApiV5 = new QueryApi<ICafAnalysisComponentListApiPathParams>({
  api: apis.caf.analysisComponents.list,
})

interface ICafAnalysisComponentListDetailApiPathParams {
  componentId: number
}

export const cafAnalysisComponentListDetailQueryApi = new QueryApi<ICafAnalysisComponentListDetailApiPathParams>({
  api: webview_apis.caf.analysisComponents.detail.summary,
})
export const cafAnalysisComponentListDetailQueryApiV5 = new QueryApi<ICafAnalysisComponentListDetailApiPathParams>({
  api: apis.caf.analysisComponents.detail.summary,
})

interface ICafAnalysisComponentDetailListApiPathParams {
  componentId: number
}

export const cafAnalysisComponentDetailListQueryApi = new QueryApi<ICafAnalysisComponentDetailListApiPathParams>({
  api: webview_apis.caf.analysisComponents.detail.details,
})
export const cafAnalysisComponentDetailListQueryApiV5 = new QueryApi<ICafAnalysisComponentDetailListApiPathParams>({
  api: apis.caf.analysisComponents.detail.details,
})

interface ICafSummaryOverviewQueryApiSearchParams {
  start_date: string
  end_date: string
}

export const cafSummaryOverviewQueryApi = new QueryApi<void, ICafSummaryOverviewQueryApiSearchParams>({
  api: webview_apis.caf.summary.overview,
})
export const cafSummaryOverviewQueryApiV5 = new QueryApi<void, ICafSummaryOverviewQueryApiSearchParams>({
  api: apis.caf.summary.overview,
})

interface ICafSummaryHighlightsQueryApiSearchParams {
  start_date?: string
  end_date?: string
  pinned: boolean
  recommended: boolean
}

export const cafSummaryHighlightsQueryApi = new QueryApi<void, ICafSummaryHighlightsQueryApiSearchParams>({
  api: webview_apis.caf.summary.highlights.list,
})
export const cafSummaryHighlightsQueryApiV5 = new QueryApi<void, ICafSummaryHighlightsQueryApiSearchParams>({
  api: apis.caf.summary.highlights.list,
})

interface ICafAnalysisAverageScoresQueryApiSearchParams {
  type: any
}

export const cafAnalysisAverageScoresQueryApi = new QueryApi<void, ICafAnalysisAverageScoresQueryApiSearchParams>({
  api: webview_apis.caf.summary.average,
})
export const cafAnalysisAverageScoresQueryApiV5 = new QueryApi<void, ICafAnalysisAverageScoresQueryApiSearchParams>({
  api: apis.caf.summary.average,
})

interface ICafSummaryDatesQueryApiSearchParams {
  year: number
  month: number
}

export const cafSummaryDatesQueryApi = new QueryApi<void, ICafSummaryDatesQueryApiSearchParams>({
  api: webview_apis.caf.summary.dates,
})
export const cafSummaryDatesQueryApiV5 = new QueryApi<void, ICafSummaryDatesQueryApiSearchParams>({
  api: apis.caf.summary.dates,
})

interface ICafScoresQueryApiSearchParams {
  type: string
}

export const cafScoresQueryApi = new QueryApi<void, ICafScoresQueryApiSearchParams>({
  api: webview_apis.caf.scores,
})
export const cafScoresQueryApiV5 = new QueryApi<void, ICafScoresQueryApiSearchParams>({
  api: apis.caf.scores,
})

interface ICafJobGraphDataQueryApiSearchParams {
  jobId: number
}

export const cafJobGraphDataQueryApi = new QueryApi<ICafJobGraphDataQueryApiSearchParams>({
  api: apis.caf.jobAnalysis.graphData,
})

export const cafJobIdQueryApi = new QueryApi({
  api: apis.caf.jobAnalysis.jobId,
})

interface ICafJobLevelDataQueryApiSearchParams {
  jobId: number
}

export const cafJobLevelDataQueryApi = new QueryApi<ICafJobLevelDataQueryApiSearchParams>({
  api: apis.caf.jobAnalysis.levelData,
})

export const cafJobStatusQueryApi = new QueryApi({
  api: apis.caf.jobAnalysis.status,
})

export const jobOptionsQueryApi = new QueryApi({
  api: apis.caf.jobAnalysis.options,
})

//* ============================================================================================================== */
//* trial.ts
//* ============================================================================================================== */

export const trialStatusSummaryQueryApi = new QueryApi({ api: apis.trial.summary })

interface ITrialOnboardingVideosQueryApiSearchParams {
  locale: LocaleType
}

export const trialOnboardingVideosQueryApi = new QueryApi<void, ITrialOnboardingVideosQueryApiSearchParams>({
  api: apis.trial.onboardingVideos.get,
})

interface ITrialSchedulesQueryApiSearchParams {
  duration: number
}

export const trialSchedulesQueryApi = new QueryApi<void, ITrialSchedulesQueryApiSearchParams>({
  api: apis.trial.schedules,
})

interface ITrialTutorsQueryApiSearchParams {
  classtime_id: number
  duration: number
}

export const trialTutorsueryApi = new QueryApi<void, ITrialTutorsQueryApiSearchParams>({ api: apis.trial.tutors })

export const trialCoursesQueryApi = new QueryApi({ api: apis.trial.courses })

interface ITrialStatusQueryApiSearchParams {
  locale: LocaleType
  duration: number
}

export const trialStatusQueryApi = new QueryApi<void, ITrialStatusQueryApiSearchParams>({ api: apis.trial.get })

interface ITrialProductDetailQueryApiPathParams {
  productId: number
}

interface ITrialProductDetailQueryApiSearchParams {
  country_code: string
}

export const trialProductDetailQueryApi = new QueryApi<
  ITrialProductDetailQueryApiPathParams,
  ITrialProductDetailQueryApiSearchParams
>({ api: apis.products.detail })

interface productDetailQueryApiPathParams {
  productId: number
}

interface productDetailQueryApiSearchParams {
  country_code: string
}

export const productDetailQueryApi = new QueryApi<productDetailQueryApiPathParams, productDetailQueryApiSearchParams>({
  api: apis.products.detail,
})

// Promotion
interface IPromotionProgramPathParams {
  programId: number
}

const promotionProgramQueryApi = new QueryApi({
  api: apis.promotion.programs,
})

interface IPromotionPrograms {
  id: number
  title_ko: string
  title_en: string
  description_ko: string
  description_en: string
  is_fcfs: string
}

export const usePromotionProgram = () => {
  return useQuery<IPromotionPrograms[], AxiosError>({
    queryKey: promotionProgramQueryApi.getQueryKey(),
    queryFn: promotionProgramQueryApi.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
    enabled: true,
  })
}

export const useProgramApply = () => {
  return useMutation({
    mutationFn: ({ programId }: IPromotionProgramPathParams) => {
      return axiosPost(apis.promotion.applyProgram(programId))
    },
  })
}

// Point
const pointQueryApi = new QueryApi({
  api: apis.point.list,
})
export const usePointList = () => {
  const token = getJwtToken()

  return useQuery<PointJson, AxiosError>({
    queryKey: pointQueryApi.getQueryKey(),
    queryFn: pointQueryApi.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
    enabled: !!token,
  })
}

export interface IReferralDatas {
  referrals: Referral[]
  referral_point_balance: number
}

export interface Referral {
  id: number
  name: string
  progress: number
  points: Point[] | null
}

export interface Point {
  id: number
  init_amount: number
  reason: number
  status: Status
}

export enum Status {
  ReadyToRedeem = 'ready_to_redeem',
}

// Referral
const referralQueryApi = new QueryApi({
  api: apis.point.referrals,
})
export const useReferralPointList = () => {
  const token = getJwtToken()

  return useQuery<IReferralDatas, AxiosError>({
    queryKey: referralQueryApi.getQueryKey(),
    queryFn: referralQueryApi.getQueryFn(),
    // onError: (e) => handleAxiosError(e),
    enabled: !!token,
  })
}

// lesson review
export interface ILessonReviewDataSearchParams {
  year: number
  month: number
  type: PAST_FILTER_TYPE
}

export const lessonReviewApi = new QueryApi<void, ILessonReviewDataSearchParams>({
  api: apis.lessons.review.pastV5,
})

export interface ILessonReviewMonthlyCountsSearchParams {
  year: number
}

export const lessonReviewMonyhlyCountApi = new QueryApi<void, ILessonReviewMonthlyCountsSearchParams>({
  api: apis.lessons.review.monthlyCount,
})

export const lessonDetailApi = new QueryApi<{ lessonId: number }, void>({
  api: apis.lessons.review.lessonInfoDetail,
})
export const lessonSettingDetailApi = new QueryApi<{ lessonId: number }, void>({
  api: apis.lessons.settingV5,
})
export const feedbackStatusQueryApiV5 = new QueryApi<{ lessonId: number }, void>({
  api: apis.lessons.review.feedbackStatusV5,
})
export const scriptStatusQueryApiV5 = new QueryApi<{ lessonId: number }, void>({
  api: apis.lessons.review.scriptStatusV5,
})

//* ============================================================================================================== */
//* conversation.ts
//* ============================================================================================================== */
export const conversationCreditsSummary = new QueryApi<void, void>({
  api: apis.conversation.credits.summary,
})

export const conversationThreadsSummary = new QueryApi<void, void>({
  api: apis.conversation.threads.summary,
})

export const cafRequestAnalysisComponentListQueryApiV5 = new QueryApi<{ threadId: number }>({
  api: apis.caf_request.analysisComponents.list,
})

export const conversationThreadsTurnCnt = new QueryApi<{ threadId: number }>({
  api: apis.conversation.threads.messageCount,
})

export const conversationCreditBundleSummary = new QueryApi<{ bundleId: number }, void>({
  api: apis.conversation.creditBundle.summary,
})

export const conversationCreditBundleThreads = new QueryApi<{ bundleId: number }, void>({
  api: apis.conversation.creditBundle.threads,
})

export const conversationThread = new QueryApi<number>({
  api: apis.conversation.threads.detail,
})

export const conversationThreads = new QueryApi<{ date?: Date }, void>({
  api: apis.conversation.threads.index,
})

export const conversationThreadsStatus = new QueryApi<{ status: string; date: Date }, void>({
  api: apis.conversation.threads.status,
})

export const conversationSetting = new QueryApi<void, void>({
  api: apis.conversation.setting.index,
})

export const aiTutorCourseList = new QueryApi<{ jobId?: number; jobCategoryId?: number }, void>({
  api: apis.aiTutor.course.list,
})

export const aiTutorRoleplayList = new QueryApi<{ jobId?: number; jobCategoryId?: number }, void>({
  api: apis.aiTutor.roleplay.list,
})

export const aiTutorCourseDetail = new QueryApi<{ courseId: number }, void>({
  api: apis.aiTutor.course.detail,
})

export const aiTutorLessonDetail = new QueryApi<{ lessonId: number }, void>({
  api: apis.aiTutor.lesson.detail,
})

export const aiTutorRoleplayDetail = new QueryApi<{ roleplayId: number }, void>({
  api: apis.aiTutor.roleplay.detail,
})

export const aiTutorSummary = new QueryApi<void, void>({
  api: apis.aiTutor.summary.get,
})
export const aiTutorSummaryStreak = new QueryApi<void, void>({
  api: apis.aiTutor.summary.streak,
})
export const aiTutorSummaryCourse = new QueryApi<void, void>({
  api: apis.aiTutor.summary.course,
})
export const aiTutorSummaryMembership = new QueryApi<{ service: string }, void>({
  api: apis.aiTutor.summary.membership,
})
export const aiTutorJobDetail = new QueryApi<{ jobId: number }, void>({
  api: apis.aiTutor.jobs.category,
})

export const aiTutorJobs = new QueryApi<void, void>({
  api: apis.aiTutor.jobs.list,
})

export const aiTutorUserJob = new QueryApi<void, void>({
  api: apis.aiTutor.jobs.user,
})

export const aiTutorLatestJob = new QueryApi<void, void>({
  api: apis.aiTutor.jobs.latest,
})

export const aiTutorStreak = new QueryApi<void, void>({
  api: apis.aiTutor.streak,
})

export const aiTutorMembership = new QueryApi<{ service: string }, void>({
  api: apis.aiTutor.summary.membership,
})
