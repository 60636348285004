// @ts-strict-ignore
export const INIT = 'createAccount/INIT'
export const SET_CHILD_ACCONT = 'createAccount/SET_CHILD_ACCOUNT'

const initialState = {
  childAccont: [],
  childInformation: [],
}

export const initChildAccountAction = () => ({ type: INIT })

export const setChildAccountAction = (value) => ({
  type: SET_CHILD_ACCONT,
  value,
})

const f = (action, func) => func(action)

const createChildAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...initialState,
      }

    case SET_CHILD_ACCONT:
      return f(action, ({ value }) => {
        return {
          ...state,
          childAccount: value,
        }
      })

    default:
      return state
  }
}
export default createChildAccountReducer
