import classNames from 'classnames'
import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { useDropdownState } from '../modules/DropdownStateContext'
import { DROPDOWN_SIZE } from '../types'
import DropdownLabel from './DropdownLabel'
interface DropdownButtonProps extends PropsWithChildren {
  wrapperClassName?: string
  buttonClassName?: string
  placeholder?: ReactNode
  iconClassName?: string
  iconColor?: string
  src?: string
  inactive?: boolean
  children?: any
}

const DropdownButton = ({
  children,
  wrapperClassName,
  buttonClassName,
  placeholder,
  src,
  iconClassName,
  iconColor,
  inactive,
}: DropdownButtonProps) => {
  const { active, setActive, size, open, setOpen, disabled, isTeens } = useDropdownState()
  const activeState = inactive ? false : active
  const svgRef = useRef(null)
  const buttonRef = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (disabled) return

      if (svgRef.current && buttonRef.current) {
        if (
          !(svgRef.current.id === event.target.id || svgRef.current.contains(event.target)) &&
          !(buttonRef.current === event.target || buttonRef.current.contains(event.target))
        ) {
          setOpen(false)
          setActive(false)
        }
      }
    }

    if (open) {
      document.addEventListener('click', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [open])

  const getWidthAndHeight = (size) => {
    switch (size) {
      case DROPDOWN_SIZE.SM:
        return 12
      case DROPDOWN_SIZE.MD:
        return 14
      case DROPDOWN_SIZE.LG:
        return 16
    }
  }
  const getColor = (active, disabled) => {
    if (disabled) {
      return `#C9CCDE`
    } else {
      if (iconColor) {
        return iconColor
      } else {
        switch (active) {
          case false:
            return '#140F33'
          case true:
            return '#503CC8'
        }
      }
    }
  }

  const handleOpen = () => {
    if (disabled) return

    setActive((prev) => !prev)
    setOpen((prev) => !prev)
  }
  return (
    <div
      className={classNames(buttonClassName, styles.buttonWrapper({ activeState, disabled, isTeens }))}
      onClick={handleOpen}
      ref={buttonRef}
    >
      <div className={classNames(styles.label, wrapperClassName)}>
        {children ? (
          <DropdownLabel inactive={inactive}>{children}</DropdownLabel>
        ) : (
          <DropdownLabel inactive={inactive}>{placeholder}</DropdownLabel>
        )}
      </div>
      <div
        onClick={() => {
          setOpen((prev) => !prev)
        }}
      >
        {open ? (
          <div>
            <UpIcon
              width={getWidthAndHeight(size)}
              height={getWidthAndHeight(size)}
              color={getColor(activeState, disabled)}
              setRef={svgRef}
              onClick={() => {
                setOpen((prev) => !prev)
              }}
            />
          </div>
        ) : (
          <div>
            <DownIcon
              width={getWidthAndHeight(size)}
              height={getWidthAndHeight(size)}
              color={getColor(activeState, disabled)}
              setRef={svgRef}
              onClick={() => {
                setOpen((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownButton

const styles = {
  label: `flex w-[90%] flex-row items-center`,
  icon: ({ size, activeState, disabled, isTeens }) => {
    const activeStateColor = isTeens ? `text-blue-500` : `text-purple-500`
    return classNames(
      size === DROPDOWN_SIZE.SM && `h-[12px] w-[12px]`,
      size === DROPDOWN_SIZE.MD && `h-[14px] w-[14px]`,
      size === DROPDOWN_SIZE.LG && `h-[16px] w-[16px]`,
      !activeState && `text-black`,
      activeState && activeStateColor,
      disabled && `text-gray-300`
    )
  },
  buttonWrapper: ({ activeState, disabled, isTeens }) => {
    const activeStateColor = isTeens ? `border-blue-500 bg-blue-50` : `border-purple-500 bg-purple-50`
    return classNames(
      `flex flex-row items-center justify-center gap-[4px] rounded-[8px] border-[1.5px] px-[12px] py-[8px]`,
      !activeState && `border-gray-300`,
      activeState && activeStateColor,
      disabled && `border-gray-200`
    )
  },
}

const UpIcon = ({ setRef, onClick, width, height, color }) => (
  <svg
    id="arrow-icon"
    ref={setRef}
    onClick={() => {
      onClick()
    }}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53958 16.842C4.21262 16.5877 4.15372 16.1165 4.40802 15.7895L11.0133 7.29695C11.5138 6.65352 12.4863 6.65352 12.9867 7.29695L19.592 15.7895C19.8464 16.1165 19.7874 16.5877 19.4605 16.842C19.1335 17.0963 18.6623 17.0374 18.408 16.7104L12 8.47158L5.59205 16.7104C5.33775 17.0374 4.86654 17.0963 4.53958 16.842Z"
      fill="currentColor"
    />
  </svg>
)

const DownIcon = ({ setRef, onClick, width, height, color }) => (
  <svg
    id="arrow-icon"
    ref={setRef}
    onClick={() => {
      onClick()
    }}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4604 9.15802C19.7874 9.41232 19.8463 9.88353 19.592 10.2105L12.9867 18.703C12.4862 19.3465 11.5137 19.3465 11.0133 18.703L4.40795 10.2105C4.15365 9.88353 4.21255 9.41232 4.53951 9.15802C4.86647 8.90372 5.33768 8.96262 5.59198 9.28958L12 17.5284L18.408 9.28958C18.6623 8.96262 19.1335 8.90372 19.4604 9.15802Z"
      fill="currentColor"
    />
  </svg>
)
