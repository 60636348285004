// @ts-strict-ignore
import { urlParams } from './apis'
import axiosInstance from './apis/axios-config'
import { getJwtToken } from './auth'

enum METHOD {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PUT = 'put',
  PATCH = 'patch',
}
/**
 * 기존 ApiHelper에서 사용하고 있는 fetch를 제거하고 axios로 변경하였습니다.
 *
 * @param url 호출 하려는 API의 url 입니다.
 * @param method "GET","POST","PUT","DELETE"
 * @param body axios config
 * @returns
 */

interface IAxiosConfig {
  token: string
  body?: object
}

const axiosConfig = ({ token, body }: IAxiosConfig) => {
  if (body) {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body, //POST,PUT,DELETE 시 payload가 담기는 부분
      }
    } else {
      return { data: body } //POST,PUT,DELETE 시 payload가 담기는 부분
    }
  } else {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    } else {
      return {}
    }
  }
}

interface IFetchData {
  url: string
  config: object
  method: string
}

interface ICustomConfig {
  param?: object // url parameter
  shouldThrowError?: boolean
  customResponse?: string
  customErrorResponse?: string
  customErrorRequest?: string
  customOther?: string
  initialToken?: string
  //getInitialProp형태로 토큰을 받아올때 getJwtToken이 실행되지 않음 -> 401error throw
}

export const axiosGet = async (url: string, customConfig?: ICustomConfig): Promise<any> => {
  if (url.includes('undefined')) {
    return
  }

  const parameter = customConfig?.param || null
  const urlWithParam = parameter ? url + urlParams(parameter) : url

  const initialToken = customConfig?.initialToken
  const jwtToken = initialToken ? initialToken : getJwtToken()
  const config = axiosConfig({ token: jwtToken })

  try {
    const response = await fetchData({
      url: urlWithParam,
      config: config,
      method: METHOD.GET,
    })

    return response //axios콜 응답의 "data"가 리턴 됨
  } catch (error) {
    const errorResponseData = error.response || {}
    if (error) throw error
    handleError(error)
    return {
      success: false,
      ...errorResponseData.data,
    }
  }
}

export const axiosPost = async (url: string, body?: object, customConfig?: ICustomConfig): Promise<any> => {
  if (url.includes('undefined')) {
    return
  }

  const parameter = customConfig?.param || null
  const urlWithParam = parameter ? url + urlParams(parameter) : url

  const initialToken = customConfig?.initialToken
  const jwtToken = initialToken ? initialToken : getJwtToken()
  const config = axiosConfig({ token: jwtToken, body: body })

  try {
    const response = await fetchData({
      url: urlWithParam,
      config: config,
      method: METHOD.POST,
    })

    return response
  } catch (error) {
    if (customConfig?.shouldThrowError && error) {
      throw error
    }

    const errorResponseData = error.response || {}

    handleError(error)
    return {
      success: false,
      ...errorResponseData.data,
    }
  }
}

export const axiosDelete = async (url: string, body?: object, customConfig?: ICustomConfig): Promise<any> => {
  if (url.includes('undefined')) {
    return
  }

  const initialToken = customConfig?.initialToken
  const jwtToken = initialToken ? initialToken : getJwtToken()
  const config = axiosConfig({ token: jwtToken, body: body })

  try {
    const response = await fetchData({
      url: url,
      config: config,
      method: METHOD.DELETE,
    })

    return response
  } catch (error) {
    const errorResponseData = error.response || {}

    handleError(error)
    return {
      success: false,
      ...errorResponseData.data,
    }
  }
}

export const axiosPut = async (url: string, body?: object, customConfig?: ICustomConfig): Promise<any> => {
  if (url.includes('undefined')) {
    return
  }

  const initialToken = customConfig?.initialToken
  const jwtToken = initialToken ? initialToken : getJwtToken()
  const config = axiosConfig({ token: jwtToken, body: body })

  try {
    const response = await fetchData({
      url: url,
      config: config,
      method: METHOD.PUT,
    })

    return response
  } catch (error) {
    const errorResponseData = error.response || {}

    handleError(error)
    return {
      success: false,
      ...errorResponseData.data,
    }
  }
}
export const axiosPatch = async (url: string, body?: object, customConfig?: ICustomConfig): Promise<any> => {
  if (url.includes('undefined')) {
    return
  }

  const initialToken = customConfig?.initialToken
  const jwtToken = initialToken ? initialToken : getJwtToken()
  const config = axiosConfig({ token: jwtToken, body: body })

  try {
    const response = await fetchData({
      url: url,
      config: config,
      method: METHOD.PATCH,
    })

    return response
  } catch (error) {
    const errorResponseData = error.response || {}

    handleError(error)
    return {
      success: false,
      ...errorResponseData.data,
    }
  }
}

const fetchData = async ({ url, config, method }: IFetchData) => {
  const response = await axiosInstance(url, { method: method, ...config })
  responseConsole(response)
  if (response) {
    return response.data
  }
}

const handleError = (error) => {
  if (error.response) {
    errorResponseConsole(error)
  } else if (error.request) {
    errorRequestConsole(error)
  } else {
    errorOtherConsole(error)
  }
}

const responseConsole = (response) => {
  const config = response.config
  // console.log('%c--AXIOS RESPONSE--', 'background: green; color: white')
  // console.log('%cAxios API URL ::', 'color: green', config.method.toUpperCase(), config.url)
  // console.log('%cAxios Response DATA', 'color: green', response.data)
}

const errorResponseConsole = (error) => {
  const config = error.config

  console.log('%c--AXIOS RESPONSE--', 'background: red; color: white')
  console.log('%cAxios API URL ::', 'color: red', config.method.toUpperCase(), config.url)
  console.log('%cAxios Error Code ::', 'color: red', error.response.status, error.response.data)
  console.log('%cAxios Error', 'color: red', error.response)
}

const errorRequestConsole = (error) => {
  console.log('%c--AXIOS REQUEST ERROR--', 'background: red; color: white')
  console.log('%cAxios No Request Error', 'color: red', error.request)
}

const errorOtherConsole = (error) => {
  console.log('%c--AXIOS OTHER ERROR--', 'background: red; color: white')
  console.log('Error', error.message)
}
