// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'

const tutorSlice = createSlice({
  name: 'tutor',
  initialState: {
    availableTutors: [],
    newTutors: [],
    allTutors: [],
    newUnoccupiedTutors: [],
    oldUnoccupiedTutors: [],
    experienceTutors: [],
    myTutors: [],
    searchedTutors: [],
    searchedPayload: { search: '' },
    searchedTutorsCount: 0,
    myTutorFetched: false,
    dragging: false,
  },
  reducers: {
    setLikeTutor(state, action) {
      const idx = state[action.payload.type].findIndex((item, i) => item.id == action.payload.tutor.id)
      if (idx > -1) {
        state[action.payload.type][idx].is_mine = !state[action.payload.type][idx].is_mine
      }
    },
    setAvailableTutors(state, action) {
      state.availableTutors = action.payload
    },
    setNewTutors(state, action) {
      state.newTutors = action.payload
    },
    setAllTutors(state, action) {
      state.allTutors = action.payload
    },
    setMyTutors(state, action) {
      state.myTutors = action.payload
    },
    setNewOccupiedTutors(state, action) {
      state.newUnoccupiedTutors = action.payload
    },
    setOldOccupiedTutors(state, action) {
      state.oldUnoccupiedTutors = action.payload
    },
    setExperienceTutors(state, action) {
      state.experienceTutors = action.payload
    },
    setSearchedTutors(state, action) {
      state.searchedTutors = action.payload
    },
    setSearchedPayload(state, action) {
      state.searchedPayload = action.payload
    },
    setSearchedTutorsCount(state, action) {
      state.searchedTutorsCount = action.payload
    },
    setMoreSearchedTutors(state, action) {
      state.searchedTutors = state.searchedTutors.concat([action.payload][0])
    },
    setMoreTutors(state, action) {
      state[action.payload.category] = state[action.payload.category].concat([action.payload.tutors][0])
    },
    onClickHeartTutor(state, action) {
      state.searchedTutors = action.payload
    },
    setFetchMytutorList(state, action) {
      state.myTutorFetched = action.payload
    },
    setDragging(state, action) {
      state.dragging = action.payload
    },
  },
})

export const tutorReducer = tutorSlice.reducer
export const tutorActions = tutorSlice.actions
