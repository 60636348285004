import { AnyAction } from '@reduxjs/toolkit'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { popupActions } from '../../../reducer/popupReducer'
import useTranslation from '../../i18n/useTranslation'

type PopupAction = {
  close: (payload: { id: string }) => AnyAction
  // ... other actions
}

/**
 * useModalBackButton - Custom hook to handle back button click on modal popup
 * @param {Function} dispatch - Redux dispatch function
 * @param {PopupAction} popupActions - Redux actions object for the popup
 * @param {string} id - Id of the popup
 * @param {boolean} isShow - Boolean flag to indicate whether the popup is shown or not
 * @returns {void}
 */
const useModalBackButton = (id: string, isShow = false): void => {
  const { locale } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShow && router.isReady) {
      /**
       * onPopState - Handler function for popstate event
       * This function will close the popup when back button is clicked
       * @returns {void}
       */
      const onPopState = (): void => {
        dispatch(popupActions.close({ id }))
        router.push(
          { pathname: router.pathname, query: { lang: locale } },
          { pathname: router.pathname },
          { shallow: true, scroll: false }
        )
      }

      router.beforePopState(() => {
        onPopState()
        return false
      })

      return () => {
        router.beforePopState(() => true)
      }
    }
  }, [isShow])
}

export default useModalBackButton
