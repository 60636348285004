// @ts-strict-ignore
import classNames from 'classnames'

type LoadingSpinnerPropsType = {
  show?: any
  muted?: any
  padded?: any
  primary?: any
  secondary?: any
  info?: any
  warning?: any
  danger?: any
  gray700?: any
  sm?: any
  md?: any
  lg?: any
  children?: any
  xl?: any
}

const LoadingSpinner = ({
  show,
  muted,
  padded,
  primary,
  info,
  warning,
  danger,
  gray700,
  sm,
  md,
  lg,
  xl,
  children,
}: LoadingSpinnerPropsType) => {
  return (
    <>
      {show ? (
        <div className={`flex h-full w-full items-center justify-center ${padded && 'py-32'}`}>
          <div
            className={classNames(
              'loading-btn',
              sm && 'sm',
              lg && 'lg',
              md && 'md',
              muted && 'muted',
              gray700 && 'gray700',
              primary && 'primary',
              warning && 'warning',
              danger && 'danger',
              info && 'info',
              xl && 'xl'
            )}
          ></div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default LoadingSpinner
