const Welcome = () => {
  return (
    <div className="flex flex-col gap-[36px]">
      <div className="flex flex-row items-center">
        <p className="text-[80px] font-extrabold leading-[100px]">
          Ringle
          <br />
          Basic·Design System
        </p>
        <div className="rounded-[100px] bg-purple-500 px-[36px] py-[16px]">
          <p className="text-[40px] font-bold leading-[40px] text-white">v1.0</p>
        </div>
      </div>
      <p className="text-[32px] font-bold leading-[32px]">for Plus·Tutor Products</p>

      <p className="mt-[128px] pl-[768px] text-[32px] font-bold leading-[32px]">UX Team X Web Team</p>
    </div>
  )
}

export default Welcome
