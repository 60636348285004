// @ts-strict-ignore
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import { Fragment, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useIsTablet from '../../../modules/hooks/common/useIsTablet'
import useModalBackButton from '../../../modules/hooks/common/useModalBackButton'
import LoginLinkA from '../../../modules/i18n/LoginLinkA'
import { s_common } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { DATA_REF_KEY } from '../../../modules/ids/e2e/student'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'
import Button from '../../_legacy/Button'

export interface ConfirmPopupPayload {
  id: string
  body: any
  title?: any
  subtitle?: any
  onClose?: any
  rightBtn?: boolean
  leftBtn?: boolean
  onRightBtnClick?: any
  onLeftBtnClick?: any
  leftBtnLabel?: string
  rightBtnLabel?: any
  noCloseBtn?: boolean
  rightBtnType?: string
  leftBtnType?: string
  borderColor?: string
  overlayClose?: boolean
  modalColor?: string
  maxBodyH?: number
  bodyPb?: number
  leftBtnClx?: string
  loginAfterLink?: string
  rightBtnDataRef?: string
  noCloseRightBtnClick?: boolean
  cancelBtnDataRef?: string
}

export const useConfirmPopup = () => {
  const dispatch = useDispatch()

  const show = (props: ConfirmPopupPayload) => {
    dispatch(popupActions.show({ ...props, type: 'confirm' }))
  }

  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }

  return { show, close }
}

export const ConfirmPopup = ({ id }: { id: any }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const focusRef = useRef(null)

  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )

  const open = data ? true : false
  const {
    title = '',
    bodyPb = 40,
    maxBodyH = null,
    subtitle = null,
    onClose = null,
    body = undefined,
    onRightBtnClick = undefined,
    onLeftBtnClick = undefined,
    leftBtn = true,
    rightBtn = true,
    leftBtnLabel = undefined,
    rightBtnLabel = undefined,
    overlayClose = false,
    noCloseBtn = false,
    leftBtnType = 'outlined',
    rightBtnType = 'primary',
    borderColor = '',
    modalColor = 'bg-white',
    leftBtnClx = '',
    loginAfterLink = '',
    rightBtnDataRef = '',
    noCloseRightBtnClick = false,
    cancelBtnDataRef = DATA_REF_KEY.img_modal_close,
  } = data ? data : {}

  const closeHandler = () => {
    dispatch(popupActions.close({ id }))
    if (isFunction(onClose)) {
      onClose()
    }
  }
  const [isTablet] = useIsTablet()

  useModalBackButton(id, isShow)

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setIsMobile(window.screen.width < 576)
    } else {
      setIsMobile(window.innerWidth < 576)
    }
  }, [])

  return (
    <div id={id}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="z-3000 fixed inset-0 flex items-center justify-center overflow-y-auto"
          initialFocus={focusRef}
          open={open}
          onClose={() => {
            if (overlayClose) {
              closeHandler()
            }
          }}
        >
          <button ref={focusRef} className="hidden" />
          <div className="flex-1 p-[8px]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="bg-dim fixed inset-0 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span aria-hidden="true" className="hidden sm:align-middle">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mx-auto max-h-screen max-w-[600px] transform overflow-hidden align-middle transition-all">
                <div className={classNames(borderColor, modalColor, 'flex flex-col rounded-[8px] shadow-lg')}>
                  <div className="relative m-[0px] flex items-end rounded-t-[8px] px-[32px] pt-[32px] pb-[16px]">
                    <div className="w-full">
                      <Dialog.Title
                        as="h1"
                        className="text-h2 mx-auto whitespace-normal pr-20 font-bold"
                        style={{ wordBreak: 'keep-all' }}
                      >
                        {/* title with border bottom(for notice popup) */}
                        {subtitle ? (
                          <div className="py-[16px]">
                            <div>{title}</div>
                            <div className="text-sBody flex flex-row items-center pt-[8px] font-normal text-gray-500">
                              {subtitle}
                            </div>
                          </div>
                        ) : (
                          title
                        )}
                      </Dialog.Title>
                    </div>
                    {/* CANCEL BUTTON */}
                    {!noCloseBtn && (
                      <div className="absolute top-[34px] right-[32px] flex h-[20px] w-[20px] items-center justify-center">
                        <button
                          type="button"
                          className="appearance-none border-none focus:outline-none"
                          style={{ width: 20, height: 20 }}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            closeHandler()
                          }}
                          data-ref={cancelBtnDataRef}
                        >
                          <i className="fe fe-x"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  {/* BODY */}
                  <div
                    style={{ wordBreak: 'keep-all' }}
                    className="text-sBody max-w-[600px] transform pt-[0px] pb-[32px] transition-all"
                  >
                    <div
                      className="overflow-y-auto px-[32px]"
                      style={{ maxHeight: maxBodyH ? maxBodyH : '100%', paddingBottom: bodyPb }}
                    >
                      {body}
                    </div>
                    {/* BUTTON AREA */}
                    <div className="float-right px-[32px]">
                      {leftBtn && (
                        <Button
                          outlined={leftBtnType == 'outlined'}
                          md={!isTablet}
                          sm={isTablet}
                          clx={`mr-8 ${leftBtnClx}`}
                          onClick={() => {
                            if (isFunction(onLeftBtnClick)) {
                              onLeftBtnClick()

                              dispatch(popupActions.close({ id }))
                            } else {
                              closeHandler()
                            }
                          }}
                        >
                          {leftBtnLabel ? leftBtnLabel : t(s_common.cancel)}
                        </Button>
                      )}
                      {rightBtn && (
                        <Button
                          primary={!rightBtnType ? true : rightBtnType == 'primary'}
                          outlined={!rightBtnType ? true : rightBtnType == 'outlined'}
                          outlinedRed={!rightBtnType ? true : rightBtnType == 'outlinedRed'}
                          md={!isTablet}
                          sm={isTablet}
                          onClick={() => {
                            !noCloseRightBtnClick && dispatch(popupActions.close({ id }))
                            if (isFunction(onRightBtnClick)) {
                              onRightBtnClick()
                            }
                          }}
                          dataRef={rightBtnDataRef}
                        >
                          {loginAfterLink ? (
                            <LoginLinkA href={loginAfterLink} afterLogin={loginAfterLink}>
                              {rightBtnLabel ? rightBtnLabel : t(s_common.ok)}
                            </LoginLinkA>
                          ) : (
                            <>{rightBtnLabel ? rightBtnLabel : t(s_common.ok)}</>
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
