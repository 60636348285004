// @ts-strict-ignore
// 액션 타입

export const SET_STEP = 'schedule/SET_STEP' as const
export const SET_SELECTED_BOX = 'schedule/SET_SELECTED_BOX' as const
export const SET_MATERIAL = 'schedule/SET_MATERIAL' as const
export const SET_MATERIAL_FOR_EDIT = 'schedule/SET_MATERIAL_FOR_EDIT' as const
export const SET_MATERIAL_EDIT_MODE = 'schedule/SET_MATERIAL_EDIT_MODE' as const
export const SET_TIME_FIRST_MODE = 'schedule/SET_TIME_FIRST_MODE' as const
export const SET_INITIAL_DATA_ACTION = 'schedule/SET_INTIAL_DATA_ACTION' as const
export const SET_DAY = 'schedule/SET_DAY' as const
export const SET_MONTH = 'schedule/SET_MONTH' as const
export const SET_DAY_AND_MONTH = 'schedule/SET_DAY_AND_MONTH' as const
export const REMOVE_COMPLETED = 'schedule/REMOVE_COMPLETED' as const
export const SELECT_CREDIT = 'schedule/SELECT_CREDIT' as const
export const TIME_FIRST_SET_TIME = 'schedule/TIME_FIRST_SET_TIME' as const
export const TIME_FIRST_SET_TUTOR = 'schedule/TIME_FIRST_SET_TUTOR' as const
export const TUTOR_FIRST_SET_TUTOR = 'schedule/TUTOR_FIRST_SET_TUTOR' as const
export const TUTOR_FIRST_SET_TIME = 'schedule/TUTOR_FIRST_SET_TIME' as const
export const SET_MATCHING_AFTER = 'schedule/SET_MATCHING_AFTER' as const

// 액션 생성 함수
type hasIdNullableType = {
  id: string
  [x: string]: any
} | null

export const setStepAction = (value: 'step-1' | 'step-2' | 'step-3' | 'step-4') => ({ type: SET_STEP, value })
export const setMatchingAfter = (id, value) => ({ type: SET_MATCHING_AFTER, id, value })
export const setSelectedBoxAction = (id) => ({ type: SET_SELECTED_BOX, id })
export const setMaterialAction = (data) => ({ type: SET_MATERIAL, data })
export const setMaterialEditModeAction = (onOff) => ({ type: SET_MATERIAL_EDIT_MODE, onOff })
export const setMaterialForEditAction = (data) => ({ type: SET_MATERIAL_FOR_EDIT, data })
export const setTimeFirstMode = (value: boolean) => ({ type: SET_TIME_FIRST_MODE, value })
export const setInitialDataAction = (data) => ({ type: SET_INITIAL_DATA_ACTION, data })
export const setDayAction = (date) => ({ type: SET_DAY, date })
export const setMonthAction = (date) => ({ type: SET_MONTH, date })
export const setDayAndMonthAction = (date) => ({ type: SET_DAY_AND_MONTH, date })
export const selectCreditAction = (data) => ({ type: SELECT_CREDIT, data })
export const timeFirstSetTimeAction = (time: hasIdNullableType) => ({ type: TIME_FIRST_SET_TIME, time })
export const timeFirstSetTutorAction = (
  tutor: hasIdNullableType,
  credit: hasIdNullableType,
  coupon_id,
  booked_time
) => ({
  type: TIME_FIRST_SET_TUTOR,
  tutor,
  credit,
  coupon_id,
  booked_time,
})
export const tutorFirstSetTutorAction = (tutor) => ({ type: TUTOR_FIRST_SET_TUTOR, tutor })
export const tutorFirstSetTimeAction = (
  time: hasIdNullableType,
  credit: hasIdNullableType,
  userapply_id,
  coupon_id,
  booked_time,
  formatted_start_time
) => ({
  type: TUTOR_FIRST_SET_TIME,
  time,
  credit,
  userapply_id,
  coupon_id,
  booked_time,
  formatted_start_time,
})
export const removeCompletedAction = (id) => ({ type: REMOVE_COMPLETED, id })

type completedType = {
  [x: string]: {
    is40M: boolean
    tutor: hasIdNullableType
    time: hasIdNullableType
    credit: hasIdNullableType
    material: hasIdNullableType
    [x: string]: any
  }
}

// 초기 상태
const initialState: {
  step: 'step-1' | 'step-2'
  step1: {
    currentCredit: hasIdNullableType
    timeFirst: {
      time: hasIdNullableType
      tutor: hasIdNullableType
    }
    tutorFirst: {
      time: hasIdNullableType
      tutor: hasIdNullableType
    }
    day: Date
    month: Date
    [x: string]: any
  }
  completed: completedType
  [x: string]: any
} = {
  step: 'step-1',
  initial: {
    today: null,
    fromMonth: null,
    toMonth: null,
    timezone: '',
    myCredits: [],
    reserved: [],
    availableTimes: [],
    unavailableTimes: [],
    noTutors: [],
  },
  step1: {
    isTimeFirstMode: true,
    isTutorFirstMode: false,
    day: null,
    month: null,
    currentCredit: null,
    timeFirst: {
      time: null,
      tutor: null,
    },
    tutorFirst: {
      time: null,
      tutor: null,
    },
  },
  step2: {
    editMode: false,
    selectedBox: null,
    selectedMaterial: null,
  },
  step3: {
    matchingAfter: {},
  },
  completed: {},
}

const f = (action, func) => func(action)

const sortedCompleted = (completed) => {
  const newCompleted = {}
  Object.keys(completed)
    .sort()
    .map((id) => {
      if (completed[id] != null) {
        newCompleted[id] = { ...completed[id] }
      }
    })
  return newCompleted
}

// 리듀서
const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHING_AFTER:
      return f(action, ({ id, value }) => {
        return {
          ...state,
          step3: {
            ...state.step3,
            matchingAfter: {
              ...state.step3.matchingAfter,
              [id]: value,
            },
          },
        }
      })
    case SET_SELECTED_BOX:
      return f(action, ({ id }) => {
        return {
          ...state,
          step2: {
            ...state.step2,
            selectedBox: id,
            selectedMaterial: state.completed[id].material,
          },
        }
      })
    case SET_MATERIAL:
      return f(action, ({ data }) => {
        if (state.step2.selectedBox == null) {
          console.error('selectedBox is null')
          return state
        }
        return {
          ...state,
          step2: {
            ...state.step2,
            selectedMaterial: data,
          },
          completed: sortedCompleted({
            ...state.completed,
            [state.step2.selectedBox]: {
              ...state.completed[state.step2.selectedBox],
              material: data,
            },
          }),
        }
      })
    case SET_MATERIAL_EDIT_MODE:
      return f(action, ({ onOff }) => {
        return {
          ...state,
          step2: {
            ...state.step2,
            editMode: onOff,
          },
        }
      })
    case SET_MATERIAL_FOR_EDIT:
      return f(action, ({ data }) => {
        return {
          ...state,
          step2: {
            ...state.step2,
            selectedMaterial: data,
          },
        }
      })
    case SET_STEP:
      return f(action, ({ value }) => {
        return {
          ...state,
          step: value,
        }
      })
    case SET_TIME_FIRST_MODE:
      return f(action, ({ value }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            isTimeFirstMode: value,
            isTutorFirstMode: !value,
          },
        }
      })
    case SET_INITIAL_DATA_ACTION:
      return f(action, ({ data }) => {
        return {
          ...state,
          initial: data,
        }
      })
    case SELECT_CREDIT:
      return f(action, ({ data }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            currentCredit: data,
          },
        }
      })
    case SET_DAY:
      return f(action, ({ date }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            day: date,
          },
        }
      })
    case SET_MONTH:
      return f(action, ({ date }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            month: date,
          },
        }
      })
    case SET_DAY_AND_MONTH:
      return f(action, ({ date }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            day: date,
            month: date,
          },
        }
      })
    case TIME_FIRST_SET_TIME:
      return f(action, ({ time }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            timeFirst: {
              time: time,
              tutor: null,
            },
          },
        }
      })
    case TIME_FIRST_SET_TUTOR:
      return f(action, ({ tutor, credit, coupon_id, booked_time }) => {
        if (state.step1.timeFirst.time == null) {
          console.error('STATE_TIME_IS_NULL')
          return state
        }
        return {
          ...state,
          step1: {
            ...state.step1,
            timeFirst: {
              ...state.step1.timeFirst,
              tutor: tutor,
            },
          },
          completed: sortedCompleted({
            ...state.completed,
            [state.step1.timeFirst.time.id]: {
              is40M: credit.is40M,
              credit: credit,
              time: state.step1.timeFirst.time,
              tutor: tutor,
              material: null,
              userapply_id: tutor?.userapply_id,
              classtime_id: tutor?.id == 'auto-matching' && tutor?.classtime_id,
              is_automatching: tutor?.id == 'auto-matching',
              coupon_id: coupon_id,
              booked_time: booked_time,
            },
          }),
        }
      })
    case TUTOR_FIRST_SET_TUTOR:
      return f(action, ({ tutor }) => {
        return {
          ...state,
          step1: {
            ...state.step1,
            tutorFirst: {
              time: null,
              tutor: tutor,
            },
          },
        }
      })
    case TUTOR_FIRST_SET_TIME:
      return f(action, ({ time, credit, userapply_id, coupon_id, booked_time }) => {
        if (state.step1.tutorFirst.tutor == null) {
          console.error('STATE_TIME_IS_NULL')
          return state
        }
        console.log(time)
        return {
          ...state,
          step1: {
            ...state.step1,
            tutorFirst: {
              ...state.step1.tutorFirst,
              time: time,
            },
          },
          completed: sortedCompleted({
            ...state.completed,
            [time.id]: {
              is40M: credit.is40M,
              credit: credit,
              time: time,
              tutor: state.step1.tutorFirst.tutor,
              userapply_id: userapply_id,
              coupon_id: coupon_id,
              is_automatching: false,
              material: null,
              booked_time: booked_time,
            },
          }),
        }
      })
    case REMOVE_COMPLETED:
      return f(action, ({ id }) => {
        const newCompleted = {}
        return {
          ...state,
          step1: {
            ...state.step1,
            timeFirst: {
              time: null,
              tutor: null,
            },
          },
          step2: {
            ...state.step2,
            selectedBox: null,
            selectedMaterial: null,
          },
          completed: sortedCompleted({
            ...state.completed,
            [id]: null,
          }),
        }
      })
    default:
      return state
  }
}

export default editReducer
