import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { COLORS } from '../../../modules/vars'
import { SegmentContext } from '../modules/SegmentStateContext'
import { SEGMENT_SIZE, SEGMENT_SIZE_KEY, SEGMENT_TYPE, SEGMENT_TYPE_KEY, Selected, SelectedStateAction } from '../types'

interface ISegmentMain extends PropsWithChildren {
  controlSelected: [Selected[], SelectedStateAction]
  type?: SEGMENT_TYPE_KEY
  size?: SEGMENT_SIZE_KEY
  multiple?: boolean
  customBgColor?: (typeof COLORS)[keyof typeof COLORS]
  customBorderColor?: (typeof COLORS)[keyof typeof COLORS]
  className?: string
  showSkeleton?: boolean
}

export type SegmentMainProps = ISegmentMain & {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  children: React.ReactNode
}

const SegmentMainSkeketon = ({ clx }) => {
  return <div className={classNames(clx, 'animate-pulse rounded-md bg-gray-100 text-transparent')}>Segment</div>
}
const SegmentMain = ({
  type = SEGMENT_TYPE.SOLID,
  size = SEGMENT_SIZE.MD,
  className,
  onClick,
  showSkeleton = false,
  multiple = false,
  children,
  controlSelected,
}: SegmentMainProps) => {
  const [selectedList, setSelectedList] = controlSelected

  if (showSkeleton) {
    return <SegmentMainSkeketon clx={styles.size({ size })} />
  }
  return (
    <SegmentContext.Provider value={{ size, type, selectedList, setSelectedList, multiple }}>
      <div
        className={classNames(
          hoverStyles,
          activeStyles,
          styles.size({ size }),
          'flex w-full items-center justify-center',
          className
        )}
        onClick={onClick}
        style={{
          backgroundColor: type === SEGMENT_TYPE.SOLID ? COLORS.WHITE : COLORS.GRAY100,
        }}
      >
        {children}
      </div>
    </SegmentContext.Provider>
  )
}

export default SegmentMain

const styles = {
  size: ({ size }: { size: SEGMENT_SIZE_KEY }) => [
    size === SEGMENT_SIZE.SM && `h-[43px] rounded-[5px]`,
    size === SEGMENT_SIZE.MD && `h-[46px] rounded-[7px]`,
    size === SEGMENT_SIZE.LG && `h-[50px] rounded-[7px]`,
    'p-[4px]',
  ],
  backgroundColor: ({ bgColor, type }: { bgColor: (typeof COLORS)[keyof typeof COLORS]; type: SEGMENT_TYPE_KEY }) =>
    classNames(bgColor || type === SEGMENT_TYPE.SOLID ? COLORS.WHITE : COLORS.GRAY100),
}

const hoverStyles = 'hover:bg-opacity-70 cursor-pointer'
const activeStyles = 'active:bg-opacity-50'
