import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { COLORS, COLOR_KEY } from '../../../..'
import { useButtonState } from '../modules/BuilderButtonStateContext'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_SIZE_KEY, BUILDER_BUTTON_TYPE, BUILDER_BUTTON_TYPE_KEY } from '../types'
import BuilderButtonLoading from './BuilderButtonLoading'

export interface BuilderButtonLabelProps extends PropsWithChildren {
  className?: string
  customTxtColor?: COLOR_KEY
}

const BuilderButtonLabel = ({ className = '', customTxtColor = undefined, children }: BuilderButtonLabelProps) => {
  const { size, disabled, isLoading, type } = useButtonState()

  if (type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && customTxtColor && customTxtColor !== COLORS.BLACK) {
    throw new Error('text color of outline_gray button cannot be customized')
  }

  if (isLoading) {
    return <BuilderButtonLoading />
  }

  return (
    <label
      className={classNames(
        styles.color({ type, disabled }),
        styles.size({ type, size }),
        className,
        !disabled && 'cursor-pointer'
      )}
      style={!disabled && customTxtColor ? { color: customTxtColor } : {}}
    >
      {children}
    </label>
  )
}

export default BuilderButtonLabel

const styles = {
  size: ({ type, size }: { type: BUILDER_BUTTON_TYPE_KEY; size: BUILDER_BUTTON_SIZE_KEY }) =>
    classNames(
      type !== BUILDER_BUTTON_TYPE.TEXT && size === BUILDER_BUTTON_SIZE.XS && `Caption-12Md`,
      type === BUILDER_BUTTON_TYPE.TEXT && size === BUILDER_BUTTON_SIZE.SM && `Caption-12Bd`,
      size === BUILDER_BUTTON_SIZE.SM && `Body2-14Md`,
      size === BUILDER_BUTTON_SIZE.MD && `Body2-14Bd`,
      size === BUILDER_BUTTON_SIZE.LG && `Body1-16Bd`,
      size === BUILDER_BUTTON_SIZE.XL && `Body1-16Bd`
    ),
  color: ({ type, disabled }: { type: BUILDER_BUTTON_TYPE_KEY; disabled: boolean }) =>
    classNames(
      (type === BUILDER_BUTTON_TYPE.SOLID || disabled) && type !== BUILDER_BUTTON_TYPE.TEXT && `text-white`,
      type === BUILDER_BUTTON_TYPE.OUTLINE && !disabled && `text-purple-600`,
      type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && !disabled && `text-black`,
      type === BUILDER_BUTTON_TYPE.TEXT && disabled && `text-gray-100`
    ),
}
