// @ts-strict-ignore

// Import Thirdparty styles
import 'animate.css/animate.min.css'
import 'aos/dist/aos.css'
import 'flatpickr/dist/flatpickr.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css' // react-tooltip v5
import 'select2/dist/css/select2.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

// Import custom style files
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import '../public/assets/css/new-theme/feather/feather.css'
import '../public/assets/css/new-theme/newCustom.scss'
import '../public/assets/css/new-theme/newDaypicker.scss'
import '../public/assets/css/new-theme/newRingledoc.scss'
import '../public/assets/css/new-theme/newSchedule.scss'
import '../public/assets/css/new-theme/react-slick.scss'
import '../public/assets/css/new-theme/reactQuill.scss'
import '../public/assets/css/new-theme/reviewGraph.scss'
import '../public/assets/css/new-theme/schedule-dropdown.scss'
import '../public/assets/css/new-theme/team.scss'

// Import Tailwind
import '../public/assets/css/new-theme/tailwind.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { TOAST_CONTAINER_ID } from '@repo/design-system/index'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AOS from 'aos'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { Slide, ToastContainer, toast as toastType } from 'react-toastify'
import PopupProvider from '../components/basic/popup/PopupProvider'
import { extractUserTypeFromPath, initGTMScript } from '../helpers/gtmHelper'
import { LocaleProvider } from '../modules/context/LocaleContext'
import { StepProvider } from '../modules/context/StepProvider'
import { UserProvider } from '../modules/context/UserContext'
import { IS_PRODUCTION } from '../modules/envVars'
import useSessionStorage from '../modules/hooks/common/useSessionStorage'
import { LOCALE_KO } from '../modules/i18n/config'
import { setAdHistory } from '../modules/utils'
import { GOOGLE_CLIENT_ID, NEXT_PUBLIC_SENTRY_DSN } from '../modules/vars'
import { wrapper } from '../reducer/store'

declare global {
  interface Window {
    dataLayer: any
  }
}

const ignorableErrors = [
  'The voice you passed is not valid or the voices have not been loaded yet.',
  '_next/static/chunks',
  // NextJS - next-head-count error
  'TypeError: Cannot read properties of null (reading "content")',
  `TypeError: Cannot read properties of null (reading 'content')`,
  `Cannot read properties of null (reading 'content')`,
  '_next/static/chunks',
  'Non-Error promise rejection captured',
]

/**
 * React Query Devtools in production
 * NODE_ENV === 'production' 이어도 볼 수 있도록 처리
 * https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
 */
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
)

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  /**
   * App.tsx State
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  //teens-tutor 파비콘 변경
  //const [favicon, setFavicon] = useState('/assets/favicon_default.ico')

  // useEffect(() => {
  //   const path = router.pathname
  //   let faviconPath

  //   if (path.includes('teens-home')) {
  //     faviconPath = '/assets/favicon_teens.ico'
  //   } else {
  //     faviconPath = '/assets/favicon_adult.ico'
  //   }

  //   setFavicon(faviconPath)
  // }, [router.pathname])

  // 직전 페이지 route 저장
  const [prevPage, setPrevPage] = useSessionStorage('prevPage', null)
  const [isMobile, setIsMobile] = useState(false)

  /**
   * React Query Devtools in production
   * NODE_ENV === 'production' 이어도 볼 수 있도록 처리
   * https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
   */
  const [showDevtools, setShowDevtools] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  /**
   * Setting Ad History
   */

  //인스타그램 브라우저에서 screen width 잘못 인식하는 부분까지 포함된 로직
  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setIsMobile(window.screen.width < 576)
    } else {
      setIsMobile(window.innerWidth < 576)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAdHistory(router.query)
      initGTMScript(extractUserTypeFromPath(router.asPath))
    }
  }, [])

  useEffect(() => {
    return () => {
      setPrevPage(router.pathname)
    }
  }, [router])
  /**
   * AOS animation library
   */
  const initAOS = () => {
    AOS.init({
      duration: 500,
      easing: 'ease-out-quad',
      once: true,
      startEvent: 'load',
    })
  }

  useEffect(() => {
    if (process.browser) {
      initAOS()
    }

    window.dispatchEvent(new Event('resize'))
  }, [])

  useEffect(() => {
    // Sentry
    const domain = 'www.ringletutor.com'

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',

      dsn: NEXT_PUBLIC_SENTRY_DSN,
      environment: window?.location?.host === domain ? 'production' : 'qa',
      ignoreErrors: [
        // 'TypeError: Cannot read properties of null (reading "content")',
        // `TypeError: Cannot read properties of null (reading 'content')`,
        // /Cannot read properties of null \(reading 'content'\)/,
        // '_next/static/chunks',
      ],

      // This enables automatic instrumentation (highly recommended), but is not
      // necessary for purely manual usage
      integrations: [
        new Integrations.BrowserTracing({
          tracePropagationTargets: [],
        }),
      ],
      // To set a uniform sample rate
      tracesSampleRate: 0,
      denyUrls: ['local.ringletutor.com'],
      beforeSend(event, hint) {
        const error = hint.originalException
        if (typeof error == 'string') {
          ignorableErrors.map((item, i) => {
            if (error.includes(item)) {
              return null
            }
          })
        } else if (error instanceof Error) {
          ignorableErrors.map((item, i) => {
            if (error.message.includes(item)) {
              return null
            }
          })
        }
        return event
      },
    })
  }, [])

  const toastProps = {
    containerId: TOAST_CONTAINER_ID,
    position: toastType.POSITION.BOTTOM_CENTER,
    className: 'new-design-system',
    closeButton: false,
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    draggable: true,
    transition: Slide,
    pauseOnHover: true,
    enableMultiContainer: true,
  }
  const isSafari = useRef(false)

  useEffect(() => {
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      isSafari.current = true
    }
  }, [])
  return (
    <>
      <Head>
        <link rel="icon" href={`/assets/favicon_adult.ico`} type="image/x-icon" />
        <link rel="preconnect" href="https://cdn.jsdelivr.net" crossOrigin="anonymous" />
        {isSafari && (
          <link
            rel="stylesheet"
            crossOrigin=""
            href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/variable/pretendardvariable-dynamic-subset.css"
          />
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <UserProvider>
            <StepProvider>
              <LocaleProvider>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <PopupProvider />
                  <ToastContainer {...toastProps} />
                  <Component {...pageProps} />
                  <div id="ds-bottom-sheet"></div> {/* For 3.0 Design System Bottom Sheet */}
                  <div id="ds-modal"></div> {/* For 3.0 Design System Popup */}
                </GoogleOAuthProvider>
              </LocaleProvider>
            </StepProvider>
          </UserProvider>
          {!IS_PRODUCTION && showDevtools && (
            <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction initialIsOpen panelProps={{ className: 'min-h-[300px]' }} />
            </Suspense>
          )}
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

export const redirectRoot = (ctx, headerObj = {}) => {
  const { lang = LOCALE_KO } = ctx.query
  if (ctx.res) {
    ctx.res.writeHead(302, {
      Location: `/${lang}/`,
      ...headerObj,
    })
    ctx.res.end()
  }
}

export default wrapper.withRedux(MyApp)
