interface DSPromoPopupContentProps {
  children?: React.ReactNode
}

const DSPromoPopupContent = (props: DSPromoPopupContentProps) => {
  const { children } = props

  const className = CLASSNAME

  return <div className={className}>{children}</div>
}

export default DSPromoPopupContent

const CLASSNAME = 'w-full pr-[4px]'
