import { useRef } from 'react'

interface DSTextAreaProps {
  name: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>

  /** Specifies the height of the text area (in lines). Default value is 2 */
  rows?: number
  placeholder?: string
  autoFocus?: boolean
  className?: string
  customTextClassName?: string
  textCounter?: {
    maxLength: number
    customTextClassName?: string
  }
  disabled?: boolean
}

const DSTextArea = (props: DSTextAreaProps) => {
  const {
    name,
    value,
    setValue,
    rows = 2,
    placeholder = '',
    autoFocus = false,
    className = '',
    customTextClassName = '',
    textCounter = undefined,
    disabled = false,
  } = props

  const ref = useRef<HTMLTextAreaElement>(null)

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value)
  }

  const containerClassName = CONTAINER_CLASSNAME + ' ' + className
  const textCounterClassName = TEXTCOUNTER_CLASSNAME

  const textCounterNumDigits = textCounter?.maxLength.toString().length

  return (
    <div className={containerClassName}>
      <Textarea
        ref={ref}
        value={value}
        onChange={onChange}
        rows={rows}
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={name}
        maxLength={textCounter?.maxLength}
        draggable={false}
        className={customTextClassName}
        disabled={disabled}
      />
      {textCounter && (
        <div className={textCounterClassName}>
          <p className={textCounter.customTextClassName}>
            {value.length.toString().padStart(textCounterNumDigits, '0')}/
            {textCounter.maxLength.toString().padStart(textCounterNumDigits, '0')}
          </p>
        </div>
      )}
    </div>
  )
}

export default DSTextArea

const Textarea = (props) => (
  <textarea
    {...props}
    className="min-w-[320px] w-full Body2-14Md text-black focus:outline-none !ring-0 resize-none !border-none rounded-[8px] p-[16px] bg-gray-50 focus:bg-gray-100 scrollbar-new"
  />
)

const CONTAINER_CLASSNAME = 'flex flex-col space-y-[4px]'
const TEXTCOUNTER_CLASSNAME = 'flex justify-end pr-[4px] Caption-12Md text-gray-500'
