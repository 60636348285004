import { QIcon_plus_default } from '@repo/icons/index'
import { Tab, TAB_TYPE } from '../../index'
import { COLORS } from '../modules/vars'
import { descriptionClassName, headerClassName, subHeaderClassName } from '../style'

const TabComponent = () => {
  return (
    <div className="flex flex-col gap-[40px]">
      <section>
        <div className={headerClassName}>States</div>
        <div className={descriptionClassName}>
          select의 컬러를 커스텀할 수 있습니다(Black 권장). unselect의 컬러 커스텀을 금합니다.
          <br />
          모든 탭 타입의 텍스트 사이즈의 커스텀을 금합니다.
        </div>
        <div className="w-2/5">
          <Tab type={TAB_TYPE.FIXED}>
            <Tab.TabItem name="Item">1</Tab.TabItem>
            <Tab.TabItem name="Item">2</Tab.TabItem>
          </Tab>
        </div>
      </section>
      <section>
        <div className={headerClassName}>Type</div>
        <div className={subHeaderClassName}>Line type</div>
        <div className={descriptionClassName}>
          탭의 기본형입니다. 일반적으로 가장 많이 사용하는 타입이며 다른 구성 요소와 연결되지 않은
          <br />
          전체 페이지 레이아웃을 사용하는 컨텐츠에 사용합니다
          <br />
          고정 너비 없이 표시되므로 더 긴 텍스트 레이블과 더 많은 수의 탭을 사용할 수 있습니다.
        </div>
        <div className={descriptionClassName}>
          <div>
            <span className="mt-10 font-bold">line-scrollable</span> : 고정 너비 없이 표시되므로 더 긴 텍스트 레이블과
            더 많은 수의 탭을 사용할 수 있습니다.
          </div>
          <Tab type={TAB_TYPE.SCROLL} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name="item">1</Tab.TabItem>
            <Tab.TabItem name="item">2</Tab.TabItem>
            <Tab.TabItem name="item">3</Tab.TabItem>
            <Tab.TabItem name="item">4</Tab.TabItem>
            <Tab.TabItem name="item">5</Tab.TabItem>
          </Tab>
        </div>

        <div className={descriptionClassName}>
          <div>
            <span className="mt-10 font-bold">line-fixed(n)tabs</span> : 각 탭의 너비가 고정됩니다. 더 많은 탭을
            표시하기 위해 스크롤 하지 않습니다.
          </div>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name="Item">1</Tab.TabItem>
            <Tab.TabItem name="Item">2</Tab.TabItem>
          </Tab>
        </div>
      </section>
      <section>
        <div className={headerClassName}>Sizes</div>
        <div className={descriptionClassName}>
          모바일 환경에선 SM, PC 환경에선 LG를 사용합니다
          <br />
          모바일 환경에서의 Tab 테스트를 하시려면 모바일 모드로 전환해 주세요 (현재는 화면 너비가 640px 이하일 때 모바일
          환경으로 전환됩니다)
          <br />탭 선택 시 활성화된 탭이 완전히 나타나도록 위치가 변경됩니다 (아래 line-scrollable 타입의 탭에서 테스트
          가능)
        </div>

        <div className="text-[11px] font-medium text-[#9747FF]">lg-line / sm-line</div>
        <div className="border-1 mb-32 flex w-full flex-col rounded-[5px] border-dashed border-[#9747FF] p-[12px]">
          <Tab className="w-fit" type={TAB_TYPE.SCROLL}>
            <Tab.TabItem name="item">1</Tab.TabItem>
            <Tab.TabItem name="item">2</Tab.TabItem>
            <Tab.TabItem name="item">3</Tab.TabItem>
            <Tab.TabItem name="item">4</Tab.TabItem>
            <Tab.TabItem name="item">5</Tab.TabItem>
            <Tab.TabItem name="item">6</Tab.TabItem>
            <Tab.TabItem name="item">7</Tab.TabItem>
            <Tab.TabItem name="item">8</Tab.TabItem>
            <Tab.TabItem name="item">9</Tab.TabItem>
            <Tab.TabItem name="item">10</Tab.TabItem>
            <Tab.TabItem name="item">11</Tab.TabItem>
            <Tab.TabItem name="item">12</Tab.TabItem>
            <Tab.TabItem name="item">13</Tab.TabItem>
            <Tab.TabItem name="item">14</Tab.TabItem>
            <Tab.TabItem name="item">15</Tab.TabItem>
            <Tab.TabItem name="item">16</Tab.TabItem>
            <Tab.TabItem name="item">17</Tab.TabItem>
            <Tab.TabItem name="item">18</Tab.TabItem>
            <Tab.TabItem name="item">19</Tab.TabItem>
            <Tab.TabItem name="item">20</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name="item">1</Tab.TabItem>
            <Tab.TabItem name="item">2</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name="item">1</Tab.TabItem>
            <Tab.TabItem name="item">2</Tab.TabItem>
            <Tab.TabItem name="item">3</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name="item">1</Tab.TabItem>
            <Tab.TabItem name="item">2</Tab.TabItem>
            <Tab.TabItem name="item">3</Tab.TabItem>
            <Tab.TabItem name="item">4</Tab.TabItem>
          </Tab>
        </div>
        <div className="text-[11px] font-medium text-[#9747FF]">lg-icon / sm-icon</div>
        <div className="border-1 flex flex-col rounded-[5px] border-dashed border-[#9747FF] p-[12px]">
          <Tab type={TAB_TYPE.SCROLL}>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>1</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>2</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>3</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>4</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>5</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>6</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>7</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>8</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>9</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>10</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>11</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>12</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>13</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>14</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>15</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>16</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>17</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>18</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>19</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>20</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>1</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>2</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>1</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>2</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>3</Tab.TabItem>
          </Tab>
          <Tab type={TAB_TYPE.FIXED} customColor={COLORS.PURPLE300}>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>1</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>2</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>3</Tab.TabItem>
            <Tab.TabItem name={<QIcon_plus_default width={18} height={18} />}>4</Tab.TabItem>
          </Tab>
        </div>
      </section>
    </div>
  )
}
export default TabComponent
