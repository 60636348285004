import classNames from 'classnames'
import { descriptionClassName, headerClassName, subHeaderClassName } from '../style'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const ScrollBar = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[128px]">
        <div>
          <p id="Layout_Grid" className={headerClassName}>
            Scrollbar
          </p>
          <p className={descriptionClassName}>
            정보 내용을 일부만 화면에 보여주고 보이지 않는 내용은 위, 아래 또는 오른쪽, 왼쪽으로 이동하여 보여주는
            컨트롤 컴포넌트입니다.
          </p>

          <p className={classNames(subHeaderClassName, 'mt-[36px]')}>States</p>
          <p className={descriptionClassName}>
            컴포넌트 내에서만 해당 스크롤바가 적용됩니다.
            <br />
            브라우저 전체 페이지 스크롤바에는 적용되지 않습니다.
            <br />
            (*초록색 border는 실제 개발시 tranparent로 적용됩니다.)
          </p>
          <br />
          <p className={classNames(descriptionClassName, 'font-bold')}>className: scrollbar-new</p>
          <div className="mt-[16px] flex h-[300px] flex-row items-center space-x-[24px]">
            <div className="flex h-[250px] w-[250px] flex-col items-center">
              <div className="bg-q-gray-300 border-q-green-100 mb-[16px] h-[49px] w-[16px] rounded-[8px] border-[5px]" />
              <p className="mb-[8px] font-bold">Default</p>
              <p className={descriptionClassName}>width: 16px</p>
              <p className={descriptionClassName}>border-radius:8px</p>
              <p className={descriptionClassName}>background-color: gray 300</p>
              <p className={descriptionClassName}>border: 5px inside transparent</p>
            </div>
            <div className="flex h-[250px] w-[250px] flex-col items-center">
              <div className="bg-q-gray-500 border-q-green-100 mb-[16px] h-[49px] w-[16px] rounded-[8px] border-[5px]" />
              <p className="mb-[8px] font-bold">Hover / Clicked</p>
              <p className={descriptionClassName}>width: 16px</p>
              <p className={descriptionClassName}>border-radius:8px</p>
              <p className={descriptionClassName}>background-color: gray 500</p>
              <p className={descriptionClassName}>border: 5px inside transparent</p>
            </div>
          </div>

          <p className={classNames(subHeaderClassName, 'mt-[36px]')}>Usage</p>
          <p className={descriptionClassName}>
            컴포넌트의 지정된 height/width내에 모든 정보가 보이지 않을 경우 스크롤바가 생성됩니다.
            <br />
            스크롤 바에 마우스 커서를 호버하거나 클릭하게 되면 Hover/Clicked 형태로 변경됩니다.
            <br />
            전체 페이지 스크롤바는 브라우저 기본 스크롤바를 사용합니다.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ScrollBar
