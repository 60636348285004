import { useEffect, useState } from 'react'

// https://medium.com/@uk960214/useanimation-훅으로-unmount-되는-컴포넌트에-애니메이션-추가하기-3bc775bf8abe

const useDSBottomSheetAnimation = (condition: boolean) => {
  const [isComplete, setComplete] = useState(false)

  useEffect(() => {
    if (condition) setComplete(true)
  }, [condition])

  const shouldRender = condition || isComplete
  const triggerAnimation = condition && isComplete

  const endTransition = () => {
    if (!condition) setComplete(false)
  }

  return { shouldRender, endTransition, triggerAnimation }
}

export default useDSBottomSheetAnimation
