import { CheckboxStateContext, DefaultValueState } from '../modules/CheckboxStateContext'
import { CHECKBOX_CHECKED, CHECKBOX_POSITION, CHECKBOX_SHAPE, Selected, SelectedStateAction } from '../types'
import { CheckboxItemProps } from './CheckboxItem'

export interface CheckboxGroupProps extends Partial<DefaultValueState> {
  controlSelected: [Selected[], SelectedStateAction]
  containerClassName?: string
  children: React.ReactElement<CheckboxItemProps> | Array<React.ReactElement<CheckboxItemProps>>
  checkedColor?: string
}

const CheckboxGroup = ({
  shape = CHECKBOX_SHAPE.SQUARE,
  controlSelected,
  buttonPosition = CHECKBOX_POSITION.LEFT,
  containerClassName = '',
  type = CHECKBOX_CHECKED.UNCHECKED,
  disabledAll = false,
  children,
  checkedColor = 'text-purple-500', // Default value for checkedColor
}: CheckboxGroupProps) => {
  const [selectedList, setSelectedList] = controlSelected

  return (
    <CheckboxStateContext.Provider
      value={{ shape, buttonPosition, selectedList, setSelectedList, type, disabledAll, checkedColor }}
    >
      <div className={containerClassName}>{children}</div>
    </CheckboxStateContext.Provider>
  )
}

export default CheckboxGroup
