// @ts-strict-ignore
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { createInstance, getUserId, HackleProvider } from '@hackler/react-sdk'
import * as Sentry from '@sentry/react'
import { UseQueryResult } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { extractUserTypeFromPath, setGTMUserId } from '../../helpers/gtmHelper'
import { getHackleUserData } from '../../helpers/hackleHelper'
import { CurrentUser, IEventPropsUserInfo } from '../../model/Common/common.interface'
import { useEventPropsUserInfo, useUser } from '../apis/common/user'
import { useRemainingCredits } from '../apis/teens/credit'
import { getEnv } from '../envUtil'
import { IS_DEVELOPMENT } from '../envVars'
import { COOKIE_LIST, HACKLE_SDK_KEY, ROLE_STUDENT } from '../vars'
declare global {
  interface Window {
    ChannelIO: any
  }
}

export const UserProvider = ({ children }) => {
  const [cookies] = useCookies([COOKIE_LIST.IS_ADMIN_LOGIN])
  const isAdminLogin = cookies[COOKIE_LIST.IS_ADMIN_LOGIN]

  const router = useRouter()

  const { isLoading, isFetching, data, dataUpdatedAt }: UseQueryResult<CurrentUser> = useUser()
  const { data: remainingCredits, isFetching: isCreditFetching } = useRemainingCredits()
  const creditCount = remainingCredits
    ? remainingCredits?.regular.credit_count_20m + remainingCredits?.regular.credit_count_40m
    : 0
  const currentUser = isLoading ? null : data
  const userData = useMemo(() => currentUser, [dataUpdatedAt])
  const isSwitching = currentUser?.isSwitching
  const isPortal = useMemo(() => router.asPath.includes('/portal/'), [router.asPath])
  const localeUserType = extractUserTypeFromPath(router.asPath)

  const showLoading = (isPortal && isLoading) || isSwitching
  const UserContextLoading = useMemo(
    () => (
      <div className="fixed flex h-screen w-screen items-center justify-center">
        <LoadingSpinner show primary lg />
      </div>
    ),
    []
  )

  const hackleClient = useMemo(
    () => createInstance(HACKLE_SDK_KEY, { debug: IS_DEVELOPMENT, auto_track_page_view: true }),
    []
  )
  const hackleDeviceId = typeof window !== 'undefined' ? getUserId() : undefined

  // datadog 관련 설정
  useEffect(() => {
    if (IS_DEVELOPMENT) {
      return
    }

    const domain = 'www.ringleteens.com'
    const RELEASE_VERSION = getEnv('CONF_RELEASE_VERSION')
    const logClientToken = 'pub10a8045aeb5d7ea45141fae5dad3f955'
    const datadogServiceName = 'ringle-teens-web'
    const rumApplicationId = '88540626-b7c3-4a03-88df-54aa83341d2f'
    const rumClientToken = 'pube68cc9397454878e5ebd9e6a092a4def'
    const rumEnv = window?.location?.host === domain ? 'production' : 'qa'

    // datadog log init
    if (datadogLogs.getInitConfiguration()?.service !== datadogServiceName) {
      datadogLogs.init({
        clientToken: logClientToken,
        site: 'datadoghq.com',
        env: rumEnv,
        service: datadogServiceName,
        version: RELEASE_VERSION,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        forwardReports: 'all',
        sampleRate: 100,
      })
    }
    // datadog rum init
    if (datadogRum.getInitConfiguration()?.service !== datadogServiceName) {
      datadogRum.init({
        applicationId: rumApplicationId,
        clientToken: rumClientToken,
        site: 'datadoghq.com',
        env: rumEnv,
        service: datadogServiceName,
        version: RELEASE_VERSION,
        enableExperimentalFeatures: ['clickmap', 'feature_flags'],
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        allowedTracingOrigins: [/https:\/\/.*\.ringleplus\.com/, /https:\/\/.*\.ringleteens\.com/],
      })
      // 로그인 안했떠라도 일단 녹화 시작
      datadogRum.startSessionReplayRecording()
    }
    // console.info(datadogRum.getInternalContext())

    if (currentUser && currentUser.id) {
      if (currentUser.role == ROLE_STUDENT) {
        datadogRum.setUser({
          id: `${currentUser.id}`,
          email: currentUser.email,
          name: (currentUser.korean_name || currentUser.first_name) + (isAdminLogin ? ' (Admin)' : ''),
        })
      }
    } else {
      datadogRum.removeUser()
    }
  }, [dataUpdatedAt, isAdminLogin])

  useEffect(() => {
    if (isFetching) {
      console.log('USER CONTEXT - IS LOADING')
    } else {
      console.log('USER CONTEXT - CURRENT USER:', currentUser)

      // * [ - TWO CASES - ]
      // ? 1. GTM Init before within landing -> Set User ID to GTM
      // ? 2. User intial access with token -> Initialize GTM with User ID
      if (userData) {
        setGTMUserId(localeUserType, userData.id, userData.email, userData.is_ringle_member, isAdminLogin)
        // sentry user info
        Sentry.setUser({
          email: userData.email,
          id: userData.id + '',
        })
      }
    }
  }, [isFetching])

  return (
    <HackleProvider
      hackleClient={hackleClient}
      supportSSR
      user={{
        ...getHackleUserData(currentUser, creditCount),
        id: hackleDeviceId,
        deviceId: hackleDeviceId,
      }}
    >
      {showLoading ? UserContextLoading : children}
    </HackleProvider>
  )
}

export const useCurrentUser = () => {
  const { isLoading, data }: UseQueryResult<CurrentUser> = useUser()
  const currentUser = isLoading ? null : data

  return { currentUser }
}

export const useCurrentUserEventProps = () => {
  const { data, isLoading }: UseQueryResult<IEventPropsUserInfo> = useEventPropsUserInfo()
  const currentUserEventProps = isLoading ? null : data

  return { currentUserEventProps }
}
