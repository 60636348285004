import * as Sentry from '@sentry/react'

import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { createInstance, getUserId, HackleProvider } from '@hackler/react-sdk'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import {
  extractUserTypeFromPath,
  isGTMLoaded,
  LOCALE_USER_TYPE,
  setGTMPlusCreditStatus,
  setGTMPlusUserInfo,
  setGTMTutorStatus,
  setGTMUserId,
} from '../../helpers/gtmHelper'
import { CurrentUser, IEventPropsUserInfo, IStudentUser, USER_ROLE } from '../../model/Common/common.interface'
import { useEventPropsUserInfo, useUser } from '../apis/common/user'
import { COOKIE_LIST, HACKLE_SDK_KEY, ROLE_STUDENT, ROLE_TUTOR, SESSION_ROLE } from '../vars'

import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import { getHackleUserData } from '../../helpers/hackleHelper'
import { FETCH_PROMOTION_DATE_DATA_QUERY } from '../../sanity/groqQueries'
import { useFeaturedTutorList } from '../apis/student/tutors/recommend/queries'
import { getJwtToken } from '../auth'
import { getEnv } from '../envUtil'
import { IS_DEVELOPMENT } from '../envVars'
import useIsAdminLogin from '../hooks/common/useIsAdminLogin'
import { eventPropsUserInfoQueryApi, userQueryApi } from '../react-query/queryApis'
import client from '../sanityClient'
import { getDday, isB2BHRGroup, isMulticampus } from '../utils'
import { HackleClientProvider } from './HackleClientContext'
import { navigateUserByPath } from './UserContextHelper'
declare global {
  interface Window {
    ChannelIO: any
  }
}

export const UserProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const isAdminLogin = useIsAdminLogin()
  const cookies = new Cookies()

  const cafToken = router.query.caf_token
  const token = getJwtToken()
  const { isLoading, isFetching, data, dataUpdatedAt }: UseQueryResult<CurrentUser> = useUser()
  const { data: prefTutorData } = useFeaturedTutorList(40)
  const { data: eventData, isLoading: eventDataLoading }: UseQueryResult<IEventPropsUserInfo> = useEventPropsUserInfo()

  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const currentUser = isLoading || !data ? null : data
  const userData = useMemo(() => currentUser, [dataUpdatedAt])
  const isPortal = useMemo(() => router.asPath.includes('/portal/'), [router.asPath])
  const localeUserType = extractUserTypeFromPath(router.asPath)
  const isTutorPath = useMemo(() => router.asPath.includes('/en/tutor/'), [router.asPath])

  useEffect(() => {
    if (!token && !cafToken) {
      console.log('USER CONTEXT - NO JWT_TOKEN')
      // * Reset User Query if the query has data even though there is no token inside the cookie
      if (data) {
        queryClient.resetQueries(userQueryApi.getQueryKey())
      }

      if (eventData) {
        queryClient.resetQueries(eventPropsUserInfoQueryApi.getQueryKey())
      }

      if (!isFetching) {
        navigateUserByPath(router.asPath, router.pathname, userData, router)
      }
    } else {
      if (data) {
        navigateUserByPath(router.asPath, router.pathname, userData, router)
      }

      // If token is valid & GTM is loaded, check below data on page move to make sure Amplitude has the most recent data
      if (data && eventData && isGTMLoaded()) {
        // If it's PLUS user, send plus properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.STUDENT && userData.type === 'Student' && !isAdminLogin) {
          console.log('***************************************')
          console.log('[ UPDATE AMP PLUS USER PROPERTIES ]')
          setGTMPlusCreditStatus(userData.unused_coupons > 0)
          setGTMPlusUserInfo(
            userData.email,
            (userData as IStudentUser).profile.mobileNumber,
            (userData as IStudentUser).profile.entered_lesson_count,
            (userData as IStudentUser).profile.finished_lesson_count,
            userData.is_ringle_member
          )
          console.log('***************************************')
        }

        // If it's TUTOR user, send tutor properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.TUTOR && userData.role === USER_ROLE.TUTOR && !isAdminLogin) {
          console.log('****************************************')
          console.log('[ UPDATE AMP TUTOR USER PROPERTIES ]')
          setGTMTutorStatus(userData.tutor_status_id)
          console.log('****************************************')
        }
      }
    }
  }, [router.asPath, token, cafToken])

  useEffect(() => {
    if (cafToken && router.asPath.includes('/webview/caf/')) {
      cookies.set(COOKIE_LIST.CAF_TOKEN, cafToken)
    }
    if (!cafToken && !router.asPath.includes('/webview/caf/')) {
      cookies.remove(COOKIE_LIST.CAF_TOKEN)
    }
  }, [cafToken])

  // datadog 관련 설정
  useEffect(() => {
    if (IS_DEVELOPMENT) {
      return
    }

    // 로그인 후에는 user의 role 로 serviceName 을 결정.
    // 로그인 전에는 path로 serviceName을 결정
    const domain = 'www.ringleplus.com'
    const RELEASE_VERSION = getEnv('CONF_RELEASE_VERSION')
    const datadogServiceName = 'ringle-plus-web'
    const rumApplicationId = '872ecb7b-ac90-428d-a8a3-2915a94e52c4'
    const rumClientToken = 'pub3496b2d69b171f31ff2e3ce760c618b8'
    const rumEnv = window?.location?.host === domain ? 'production' : 'qa'

    // datadog rum init
    if (datadogRum.getInitConfiguration()?.service !== datadogServiceName) {
      datadogRum.init({
        applicationId: rumApplicationId,
        clientToken: rumClientToken,
        site: 'datadoghq.com',
        env: rumEnv,
        service: datadogServiceName,
        version: RELEASE_VERSION,
        enableExperimentalFeatures: ['clickmap', 'feature_flags'],
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        allowedTracingOrigins: [/https:\/\/.*\.ringleplus\.com/],
      })
      // 로그인 안했떠라도 일단 녹화 시작
      datadogRum.startSessionReplayRecording()
    }
    // console.info(datadogRum.getInternalContext())

    if (currentUser && currentUser.id) {
      if (currentUser.role == ROLE_STUDENT) {
        datadogRum.setUser({
          id: `${currentUser.id}`,
          email: currentUser.email,
          name:
            (currentUser.profile?.korean_name || currentUser.profile?.first_name) + (isAdminLogin ? ' (Admin)' : ''),
        })
      }
      if (currentUser.role == ROLE_TUTOR) {
        datadogRum.setUser({
          id: `${currentUser.id}`,
          email: currentUser.email,
          name: currentUser.first_name + (isAdminLogin ? ' (Admin)' : ''),
        })
      }
    } else {
      datadogRum.removeUser()
    }
  }, [currentUser, isAdminLogin, isTutorPath])

  useEffect(() => {
    if (isFetching) {
      console.log('USER CONTEXT - IS LOADING')
      setIsDataLoaded(false)
    } else {
      console.log('USER CONTEXT - CURRENT USER:', userData)
      navigateUserByPath(router.asPath, router.pathname, userData, router)
      setIsDataLoaded(true)

      // * [ - TWO CASES - ]
      // ? 1. GTM Init before within landing -> Set User ID to GTM
      // ? 2. User intial access with token -> Initialize GTM with User ID
      if (userData) {
        setGTMUserId(localeUserType, userData.id, userData.email, userData.is_ringle_member, isAdminLogin)

        // If it's PLUS user, send plus properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.STUDENT && userData.type === 'Student' && !isAdminLogin) {
          console.log('***************************************')
          console.log('[ UPDATE AMP PLUS USER PROPERTIES ]')
          setGTMPlusCreditStatus(userData.unused_coupons > 0)
          setGTMPlusUserInfo(
            userData.email,
            (userData as IStudentUser).profile.mobileNumber,
            (userData as IStudentUser).profile.entered_lesson_count,
            (userData as IStudentUser).profile.finished_lesson_count,
            userData.is_ringle_member
          )
          console.log('***************************************')
        }

        // If it's TUTOR user, send tutor properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.TUTOR && userData.role === USER_ROLE.TUTOR && !isAdminLogin) {
          console.log('****************************************')
          console.log('[ UPDATE AMP TUTOR USER PROPERTIES ]')
          setGTMTutorStatus(userData.tutor_status_id)
          console.log('****************************************')
        }

        // sentry user info
        Sentry.setUser({
          email: userData.email,
          id: userData.id + '',
        })
      }
    }
  }, [isFetching])

  const showChildren = isDataLoaded || !isPortal
  // role과 접근하려는 url이 다른 경우에 리다이렉트 시키기 이전에 화면에 접근 시도가 되어 에러 페이지가 보여지는 부분 수정
  const sessionRole = cookies.get('session_role')
  let showAfterRedirect = true
  if (sessionRole && router.pathname !== '/_error') {
    if (sessionRole === SESSION_ROLE.STUDENT && router.asPath.includes('/tutor/portal')) {
      showAfterRedirect = false
    } else if (sessionRole === SESSION_ROLE.TUTOR && router.asPath.includes('/student/portal')) {
      showAfterRedirect = false
    }
  }

  const hackleClient = useMemo(
    () => createInstance(HACKLE_SDK_KEY, { debug: IS_DEVELOPMENT, auto_track_page_view: true }),
    []
  )

  const hackleDeviceId = typeof window !== 'undefined' ? getUserId() : undefined

  return (
    <HackleClientProvider hackleClient={hackleClient}>
      <HackleProvider
        hackleClient={hackleClient}
        supportSSR
        user={{
          ...getHackleUserData(userData, prefTutorData?.length),
          id: hackleDeviceId,
          deviceId: hackleDeviceId,
        }}
      >
        {/* {showLoading && UserContextLoading} */}
        {showChildren && showAfterRedirect && <div>{children}</div>}
        {/* <div className={`${!isDataLoaded ? 'opacity-0' : ''}`}>{children}</div> */}
        {/* 
          FIXME
          - ReflexContainer 사용 되는 부분 SSR로 currentUser 들고 오고 있어서 많은 로직이 currentUser의 null 방어 코드가 없음
          - getInitialProps 부분의 currentUser 제거 후 각 useEffect 및 기타 로직에서 currentUser가 없을 때 처리가 되어 있어야함
          - 해당 부분 다 수정이 되고 나서 opacity-0에서 hidden으로 다시 변경 가능
        */}
      </HackleProvider>
    </HackleClientProvider>
  )
}

export const useCurrentUser = () => {
  const { isLoading, data }: UseQueryResult<CurrentUser> = useUser()
  const currentUser = isLoading ? null : data
  return { currentUser }
}

const fetchPromotionDateData = async () => {
  try {
    const response = await client.fetch(FETCH_PROMOTION_DATE_DATA_QUERY)
    return response
  } catch (error) {
    console.error('Failed to fetch data:', error)
    throw error
  }
}

export const useIsPromotionUser = () => {
  const { currentUser } = useCurrentUser()

  const { data: promotionDateData } = useQuery({
    queryKey: ['promotion_date'],
    queryFn: () => fetchPromotionDateData(),
  })

  const enabled = promotionDateData?.enabled || false

  const isPromotionUser = enabled ? !isMulticampus(currentUser) && !isB2BHRGroup(currentUser) : false

  const dday = enabled
    ? getDday(
        new Date(
          Date.UTC(
            promotionDateData?.d_day?.target_year,
            promotionDateData?.d_day?.target_month - 1,
            promotionDateData?.d_day?.target_day,
            promotionDateData?.d_day?.target_hour
          )
        )
      )
    : null

  const endTime = enabled ? promotionDateData?.endDate || null : null

  return { isPromotionUser, dday, endTime }
}

export const useCurrentUserEventProps = () => {
  const { data, isLoading }: UseQueryResult<IEventPropsUserInfo> = useEventPropsUserInfo()
  const currentUserEventProps = isLoading ? null : data

  return { currentUserEventProps }
}
