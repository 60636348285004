import groq from 'groq'

const BLOG_HOME_QUERY_MAIN_MENU = groq`
  *[_type == "blogPosts" && subMenuCategory->menuCategory->form == "blog" && isPublic == true && language == $lang]
  | order(publishedAt desc) [$start..$end] {
    slug,
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
  }
`

const BLOG_HOME_QUERY_SUB_MENU = groq`
  *[_type == "blogPosts" && subMenuCategory->menuCategory->_id == $selectedMenuId && isPublic == true && language == $lang]
  | order(publishedAt desc) [$start..$end] {
    slug,
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
  }
`

const BLOG_HOME_QUERY_OTHER_MENU = groq`
  *[_type == "blogPosts" && subMenuCategory->_id == $selectedSubMenuId && isPublic == true && language == $lang]
  | order(publishedAt desc) [$start..$end] {
    slug,
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
  }
`
export const getBlogHomeGroqQueryAndParams = (start, end, selectedMenuId, selectedSubMenuId, lang) => {
  const CUSTOM_MENU_ID = 0
  let query
  let params

  if (selectedMenuId === CUSTOM_MENU_ID) {
    query = BLOG_HOME_QUERY_MAIN_MENU
    params = { start, end, lang }
  } else if (selectedSubMenuId === CUSTOM_MENU_ID) {
    query = BLOG_HOME_QUERY_SUB_MENU
    params = { start, end, selectedMenuId, lang }
  } else {
    query = BLOG_HOME_QUERY_OTHER_MENU
    params = { start, end, selectedSubMenuId, lang }
  }

  return { query, params }
}

export const BLOG_NOTICE_TAB_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang && subMenuCategory->menuCategory->form == $form ] | order(publishedAt desc) {
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title
  }
`

export const BLOG_SEARCH_TAB_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang]
  | score(
      boost(title match "*$value*", 2) || 
      boost(pt::text(content) match "*$value*", 1)
  )
  | order(_score desc)
  [0...5]
  {
      _score, 
      title, 
      "slug":slug.current 
  }
`

export const BLOG_SECRET_TAB_QUERY = groq`
  *[_type == "blogPosts" && isPublic == false && language == $lang] 
  | order(publishedAt desc) {
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title
  }
`

export const BLOG_FAQ_SECTION_WITH_SUBMENU_ID = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang && subMenuCategory->menuCategory->form == $form && subMenuCategory->_id == $subMenuId] 
  | order(publishedAt desc) {
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
    isFAQHaveDetail,
    faqSummary,
    faqPreviewImage,
  }
`

export const BLOG_FAQ_SECTION_WITHOUT_SUBMENU_ID = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang && subMenuCategory->menuCategory->form == $form] 
  | order(publishedAt desc) {
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
    isFAQHaveDetail,
    faqSummary,
    faqPreviewImage,
  }
`

export const BLOG_DETAIL_BANNER_QUERY = groq`
  *[ _type == $type ] {
    commonBanner,
}[0]
`

export const BLOG_TAG_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang && $tag in tagLists.tags[]->title]
  | order(publishedAt desc) [$start..$end]{
    ...,
    "tagLists": tagLists.tags[]->title,
  }
`

export const EVENT_DETAIL_BODY_QUERY = groq`
  *[_type == "plusEventPortal" && slug.current == $slug][0]{
    faq,
    information,
    replayLink,
    "recommendedContentsArray": recommendedContentsArray[]->{
      ...,
      "tags": tags[]->title,
    },
  }
`

export const EVENT_LIST_FUTURE_WITH_CUSTOM_MENU_ID_QUERY = groq`
  *[_type == "plusEventPortal" && isPublic == true && language == $lang && dueDate >= $currentUTCTime]
  | order(dueDate asc) | order(startDate asc) {
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const EVENT_LIST_FUTURE_WITH_SELECTED_SUB_MENU_ID_QUERY = groq`
  *[_type == "plusEventPortal" && category->_id == $selectedSubMenuId && isPublic == true && language == $lang && dueDate >= $currentUTCTime]
  | order(dueDate asc) | order(startDate asc) {
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const EVENT_LIST_PAST_WITH_CUSTOM_MENU_ID_QUERY = groq`
  *[_type == "plusEventPortal" && isPublic == true && language == $lang && dueDate < $currentUTCTime]
  | order(dueDate desc) | order(startDate desc) {
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const EVENT_LIST_PAST_WITH_SELECTED_SUB_MENU_ID_QUERY = groq`
  *[_type == "plusEventPortal" && category->_id == $selectedSubMenuId && isPublic == true && language == $lang && dueDate < $currentUTCTime]
  | order(dueDate desc) | order(startDate desc) {
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const EVENT_LIST_SUBMENU_QUERY = groq`
  *[_type == "plusEventPortalCategory" && language == $lang]
  | order(id asc)
`

export const EVENT_MAIN_NOTICE_SECTION_QUERY = groq`
  *[_type == "blogPosts" && subMenuCategory->menuCategory->form == "notice" && isPublic == true && language == $lang] {
    ...,
  } | order(publishedAt desc)
`

export const EVENT_MAIN_ONGOING_EVENT_KO_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "ongoingEventsList": ongoingEventsListKo[]->{
      ...,
      "tags": tags[]->title,
    }
  }
`

export const EVENT_MAIN_ONGOING_EVENT_EN_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "ongoingEventsList": ongoingEventsListEn[]->{
      ...,
      "tags": tags[]->title,
    }
  }
`

export const EVENT_MAIN_PREV_LIVE_KO_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "prevLiveList": prevLiveListKo[]->{
      ...,
      "tags": tags[]->title,
    }
  }
`

export const EVENT_MAIN_PREV_LIVE_EN_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "prevLiveList": prevLiveListEn[]->{
      ...,
      "tags": tags[]->title,
    }
  }
`

export const EVENT_MAIN_RINGLE_BLOG_KO_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "selectedBlogs": {
      "firstBlog": *[_type == "blogPosts" && _id == ^.selectedBlogs.blog_first._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title,
      },
      "secondBlog": *[_type == "blogPosts" && _id == ^.selectedBlogs.blog_second._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title, 
      },
      "thirdBlog": *[_type == "blogPosts" && _id == ^.selectedBlogs.blog_third._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title, 
      }
    }
  }
`

export const EVENT_MAIN_RINGLE_BLOG_EN_QUERY = groq`
  *[_type == "plusEventPortalMain"][0]{
    "selectedBlogs": {
      "firstBlog": *[_type == "blogPosts" && _id == ^.selectedBlogsEn.blog_first._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title,
      },
      "secondBlog": *[_type == "blogPosts" && _id == ^.selectedBlogsEn.blog_second._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title, 
      },
      "thirdBlog": *[_type == "blogPosts" && _id == ^.selectedBlogsEn.blog_third._ref][0]{ 
        ...,
        "category": subMenuCategory->menuCategory->title, 
      }
    }
  }
`

export const EVENT_MAIN_THIS_MONTH_EVENT_LIST_QUERY = groq`
  *[_type == "plusEventPortal" && startDate <= $endOfThisMonth && dueDate >= $startOfThisMonth && isPublic == true && language == $lang]
  | order(startDate asc){
    ...,
    "tags": tags[]->title,
  }
`
export const EVENT_NEAREST_EVENTS_QUERY = groq`
  *[_type == "plusEventPortal" && startDate >= $today && isPublic == true && language == $lang]
  | order(startDate asc)[0...10]{
    ...,
    "tags": tags[]->title,
  }
`
export const EVENT_MAIN_TODAY_WEBINAR_QUERY = groq` 
  *[_type == "plusEventPortal" && startDate >= $now && startDate <= $endOfToday && dueDate >= $startOfToday && language == $lang && isPublic == true][0]{
    ...,
    "tags": tags[]->title,
    "link": link->{...},
  }
`
export const EVENT_MAIN_TODAY_WEBINAR_QUERY_TEST = groq` 
  *[_type == "plusEventPortal" && startDate >= $now && startDate <= $endOfToday && dueDate >= $startOfToday && language == $lang && isPublic == false][0]{
    ...,
    "tags": tags[]->title,
    "link": link->{...},
  }
`
export const EVENT_SEARCH_TAB_QUERY = groq`
  *[_type == "plusEventPortal" && isPublic == true && language == $lang]
  | score(
      boost(title match $value, 2) || 
      boost(pt::text(content) match $value, 1)
  )
  | order(_score desc)
  [0...5]
  {
      _score, 
      title, 
      "slug": slug.current 
  }
`

export const PREV_READ_ALONE_QUERY = groq`
  *[_type == "contentReadAlone" && _id in $ids && language == $lang && isPublic == true]
  | order(startDate desc)
`

export const PREV_READ_TOGETHER = groq`
  *[_type == "contentReadTogether" && language == $lang && _id in $ids && isPublic == true]
  | order(startDate desc)
`

export const PRICING_PAGE_CMS_SECTION_QUERY = groq`
  *[_type == "pricingPage" && slug.current == $slug][0]{
    ..., 
    "ko":{
      "webSectionArray" : ko.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : ko.mobileSectionArray[]->{
        ...,
      }
    },
    "en":{
      "webSectionArray" : en.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : en.mobileSectionArray[]->{
        ...,
      }
    },
    "zh_cht":{
      "webSectionArray" : zh_cht.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_cht.mobileSectionArray[]->{
        ...,
      }
    },
    "zh_chs":{
      "webSectionArray" : zh_chs.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_chs.mobileSectionArray[]->{
        ...,
      }
    },
    "ja":{
      "webSectionArray" : ja.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : ja.mobileSectionArray[]->{
        ...,
      }
    },
  }
`

export const PRICING_BANNER_LIST_QUERY = groq`
  *[_type == "plusPricingBannerList" && slug.current == $slug][0]
`

export const CONTENT_DETAIL_PURCHASE_QUERY = groq`
  *[_type == $contentType && slug.current == $slug][0]{submitButtonLink}
`

export const CONTENT_PROGRAM_DETAIL_DATA_QUERY = groq`
  *[_type == $contentType && slug.current == $slug][0]{
    isPublic,
    information,
    "tutorList": mainTutorList.tutorList[]->{
      avatar,
      education,
      introduction,
      name,
      profession
    },
    relatedLinkList,
}
`

export const CONTENT_PROGRAM_ENROLL_DATA_QUERY = groq`
  *[_type == $contentType && slug.current == $slug][0]{
    isPublic,
    downloadLink,
    replayYoutubeLinkList,
  }
`

export const CONTENT_PROGRAM_PURCHASE_FAIL_GET_DATA_QUERY = groq`
  *[_type == $type && _id == $id][0]{
    ...
  }
`

export const GET_TEST_AVAILABLE_READ_TOGETHER_QUERY = groq`
  *[_type == "contentReadTogether" && language == $lang && isPublic == false && dueDate > $currentTime]
  | order(dueDate asc){
    ...,
    "tagLists": tagLists.tags[]->title,
  }
`

export const GET_TEST_AVAILABLE_READ_ALONE_QUERY = groq`
  *[_type == "contentReadAlone" && isPublic == false && language == $lang && dueDate > $currentTime]
  | order(dueDate asc){
    ...,
    "tagLists": tagLists.tags[]->title,
  }
`

export const INTERNAL_LINK_QUERY = groq`
  *[_type == "blogPosts" && _id == $refId && isPublic == true] {
    'slug': slug.current
  }
`

export const EVENT_DETAIL_ACTIVITY_GET_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && slug.current == $slug][0]{
    mainImage,
    metaData,
    thumbnail,
    "tags": tags[]->title,
    dueDate,
    startDate,
    slug,
    endDate,
    eventType,
    title,
    isAppliableEvent,
    "link": link->{...},
    _type,
    _id
  }
`

// 여기에 추가
export const HELP_CENTER_GET_MENU_DATA_QUERY = groq`
  *[_type == "blogCategory" && isPublic == true && language == $lang && _id in $ids]
  | order(id asc){
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const PREV_READ_ALONE_GET_DATA_QUERY = groq`
  *[_type == "contentReadAlone" && _id in $ids && language == $lang && isPublic == true]
  | order(startDate desc)
`

export const PREV_READ_TOGETHER_GET_DATA_QUERY = groq`
  *[_type == "contentReadTogether" && _id in $ids && language == $lang && isPublic == true]
  | order(startDate desc)
`

export const BLOG_TAG_LIST_PAGE_GET_DATA_QUERY = groq`
  *[_type == "blogPosts" && $tag in tagLists.tags[]->title && isPublic == true && language == $lang]
  | order(publishedAt desc) [0..8]{
    ...,
    "tagLists": tagLists.tags[]->title,
  }
`

export const TEST_ADMIN_EVENT_PAGE_GET_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && dueDate >= $currentUTCTime]
  | order(startDate asc) {
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const EVENT_DETAIL_GET_INITIAL_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && slug.current == $slug][0]{
    mainImage,
    metaData,
    thumbnail,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isAppliableEvent,
    "link": link->{...},
    _type,
    _id
  }
`

export const EVENT_SEARCH_RESULT_GET_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && isPublic == true && language == $lang && _score > 0 ]
  | score(
      boost(title match $keywords, 2) || 
      boost(pt::text(content) match $keywords, 1)
  )
  | order(_score desc)
  {
    "ctaSection": ctaSection->{title, link},
    mainImage,
    metaData,
    thumbnail,
    "menuCategory": "Event",
    "tags": tags[]->title,
    dueDate,
    startDate,
    "subMenuCategory": category->title,
    eventType,
    language,
    title,
    slug,
    _score,
    "description": pt::text(information.content),
  }
`

export const SITEMAP_BLOG_POSTS_QUERY = groq`
    *[_type == "blogPosts" && isPublic == true] {
      "slug": slug.current,
      language
    }
  `

export const SITEMAP_MATERIAL_POSTS_QUERY = groq`
    *[_type == "contentInsight"] {
      "slug": slug.current
    }
  `

export const BLOG_KEYWORDS_RESULT_GET_DATA_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang]
  | score(
      boost(title match $keywords, 2) || 
      boost(pt::text(content) match $keywords, 1)
  )
  | order(_score desc)
  {
    _score, 
    title, 
    content,
    "description": pt::text(content),
    "slug": slug.current,
    "subMenuCategory": subMenuCategory->title,
    "menuCategory": subMenuCategory->menuCategory->title,
    slug,
  }
`

export const EVENT_TAG_PAGE_GET_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && $tag in tags[]->title && isPublic == true && language == $lang]
  | order(publishedAt desc){
    thumbnail,
    slug,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isOnGoingEvent,
    isPublic,
    language,
    category,
  }
`

export const HELPCENTER_MAIN_POST_GET_DATA_QUERY = groq`
  *[_type == $type] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const HELPCENTER_GET_MENU_DATA_QUERY = groq`
  *[_type == "blogCategory" && isPublic != null && isPublic == true && language == $lang && (id == 5 || id == 3)] | order(id asc){
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const MATERIAL_DETAIL_GET_DATA_QUERY = groq`
  *[_type == "contentInsight" && slug.current == $materialId][0]
`

export const NOTICE_GET_DATA_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang && subMenuCategory->menuCategory->form == $form]
  | order(publishedAt desc) {
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title
  }
`

export const READ_ALONE_DETAIL_GET_DATA_QUERY = groq`
  *[_type == "contentReadAlone" && slug.current == $slug][0]{
    isPublic,
    backgroundImage,
    dueDate,
    endDate,
    method,
    price,
    slug, 
    startDate,
    subTitle,
    "tags": tagLists.tags[]->title,
    title,
    _type,
    _id,
  }
`

export const READ_TOGETHER_DETAIL_GET_DATA_QUERY = groq`
  *[_type == "contentReadTogether" && slug.current == $slug][0]{
    isPublic,
    backgroundImage,
    dueDate,
    endDate,
    liveWebinarDateList,
    method,
    price,
    slug, 
    startDate,
    subTitle,
    "tags": tagLists.tags[]->title,
    title,
    _type,
    _id,
  }
`

export const MKT_HOME_CHALLENGE_QUERY = groq`
  *[_type == "mktHomeChallengeBanner" && isPublic == true][0]
`

export const MKT_HOME_TUTOR_QUERY = groq`
  *[_type == "mktHomeTutorBanner" && isPublic == true][0]
`

export const MKT_HOME_PRICING_SUCCESS_QUERY = groq`
  *[_type == "mktPricingSuccessBanner" && isPublic == true][0]
`

export const MKT_HOME_PROFILE_QUERY = groq`
  *[_type == "mktProfileBanner" && isPublic == true][0]
`

export const MKT_MAIN_CHALLENGE_QUERY = groq`
  *[_type == "mktMainChallengeBanner" && isPublic == true][0]
`

export const MKT_EVENT_MAIN_QUERY = groq`
  *[_type == "mktEventMainBanner"][0]
`

export const GLOBAL_LANDING_CMS_SECTION_GET_DATA_QUERY = groq`
  *[_type == "pricingPage" && slug.current == "global_landing"][0]{
    "ko":{
      "webSectionArray" : ko.webSectionArray[]->{
        ...,
      }
    },
    "en":{
      "webSectionArray" : en.webSectionArray[]->{
        ...,
      }
    },
    "zh_cht":{
      "webSectionArray" : zh_cht.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_cht.mobileSectionArray[]->{
        ...,
      }
    },
    "zh_chs":{
      "webSectionArray" : zh_chs.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_chs.mobileSectionArray[]->{
        ...,
      }
    },
    "ja":{
      "webSectionArray" : ja.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : ja.mobileSectionArray[]->{
        ...,
      }
    }
  }
`

export const GLOBAL_LANDING_CMS_SECTION_MOBILE_GET_DATA_QUERY = groq`
  *[_type == "pricingPage" && slug.current == "global_landing"][0]{
    "ko":{
      "mobileSectionArray" : ko.mobileSectionArray[]->{
        ...,
      }
    },
    "en":{
      "mobileSectionArray" : en.mobileSectionArray[]->{
        ...,
      }
    },
    "zh_cht":{
      "webSectionArray" : zh_cht.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_cht.mobileSectionArray[]->{
        ...,
      }
    },
    "zh_chs":{
      "webSectionArray" : zh_chs.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : zh_chs.mobileSectionArray[]->{
        ...,
      }
    },
    "ja":{
      "webSectionArray" : ja.webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : ja.mobileSectionArray[]->{
        ...,
      }
    }
  }`

export const FETCH_DETAIL_BLOG_DATA_QUERY = groq`
  *[_type == "blogPosts" && slug.current == $slug][0] {
    content,
    ctaSection,
    "ctaList": ctaSection.ctaButtonList[]->{
      title,
      link
    },
    thumbnail,
    mainImage,
    publishedAt, 
    slug,
    language,
    title,
    subtitle,
    metaData,
    commonBanner,
    isForB2BPost,
    "menuForm": subMenuCategory->menuCategory->form,
    "recommendList": recommendedPosts.recommendedPostsSlug[]->{
      thumbnail,
      slug,
      title,
      subtitle,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title,
      "tagList": tagLists.tags[]->title
    },
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
    "tagList": tagLists.tags[]->title
  }
`

export const FETCH_BLOG_SUMMARY_QUERY = groq`
  *[_type == "blogPosts" && slug.current == $slug][0] {
    thumbnail,
    slug,
    language,
    title,
    subtitle,
    metaData,
    "menuForm": subMenuCategory->menuCategory->form,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title,
    "tagList": tagLists.tags[]->title
  }
`

export const FETCH_POST_LIST_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true] {
    "slug" : slug.current,
    language,
  }
`

export const FETCH_POST_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang] | order(publishedAt desc) [0..8]{
    slug, 
    isPublic,
    subtitle,
    thumbnail,
    title,
    metaData,
    subMenuCategory,
    publishedAt,
    "tagLists": tagLists.tags[]->title,
    "category": subMenuCategory->menuCategory->title,
    "subCategory": subMenuCategory->title
  }
`

export const FETCH_MENU_QUERY = groq`
  *[_type == "blogCategory" && isPublic == true && language == $lang] | order(id asc){
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_SUB_MENU_QUERY = groq`
  *[_type == "blogSubCategory"]{
    ...,
    "menu": menuCategory->title
  }
`

export const FETCH_MAIN_POST_KO_QUERY = groq`
  *[_type == "blogMainPost"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_MAIN_POST_EN_QUERY = groq`
  *[_type == "blogMainPostEn"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_MAIN_POST_JA_QUERY = groq`
  *[_type == "blogMainPostJa"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_MAIN_POST_VI_QUERY = groq`
  *[_type == "blogMainPostVi"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_MAIN_POST_ZHS_QUERY = groq`
  *[_type == "blogMainPostZhs"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_MAIN_POST_ZHT_QUERY = groq`
  *[_type == "blogMainPostZht"] {
    ...,
    "mainPost": *[_type == "blogPosts" && _id == ^.referenceSlug._ref][0]{
      slug, 
      title, 
      subtitle,
      ogImage,
      thumbnail,
      subMenuCategory,
      "tagLists": tagLists.tags[]->title,
      "category": subMenuCategory->menuCategory->title,
      "subCategory": subMenuCategory->title
    }
  }
`

export const FETCH_KEYWORDS_RESULT_DATA_QUERY = groq`
  *[_type == "blogPosts" && isPublic == true && language == $lang]
  | score(
      boost(title match $keywords, 2) || 
      boost(pt::text(content) match $keywords, 1)
  )
  | order(_score desc)
  {
      _score, 
      title, 
      content,
      "description": pt::text(content),
      "slug": slug.current,
      "subMenuCategory": subMenuCategory->title,
      "menuCategory": subMenuCategory->menuCategory->title,
      "metaData": metaData->,
      slug
  }
`

export const FETCH_TAG_DATA_QUERY = groq`*[_type == "blogTag" && title == $tag][0]`

export const FETCH_POST_TAG_DATA_QUERY = groq`
  *[_type == "blogPosts" && $tag in tagLists.tags[]->title && isPublic == true && language == $lang]
  | order(publishedAt desc) [0..8]{
    ...,
    "tagLists": tagLists.tags[]->title
  }
`

export const FETCH_EVENT_PAGE_DATA_QUERY = groq`
    *[_type == "eventPage" && slug.current == $slug && product == "plus"][0]{
      ..., 
      "sectionArray" : sectionArray[]->{
        ...,
      },
      "webSectionArray" : webSectionArray[]->{
        ...,
      },
      "mobileSectionArray" : mobileSectionArray[]->{
        ...,
      }
    }
  `

export const FETCH_EVENT_DETAIL_INITIAL_DATA_QUERY = groq`
  *[_type == "plusEventPortal" && slug.current == $slug && isPublic == true][0]{
    mainImage,
    metaData,
    thumbnail,
    "tags": tags[]->title,
    dueDate,
    startDate,
    endDate,
    eventType,
    title,
    isAppliableEvent,
    "link": link->{...},
    _type,
    _id
  }
`

export const FETCH_CONTENT_READ_ALONE_DETAIL_DATA_QUERY = groq`
  *[_type == "contentReadAlone" && slug.current == $slug][0]{
    isPublic,
    backgroundImage,
    dueDate,
    endDate,
    method,
    price,
    slug, 
    startDate,
    subTitle,
    "tags": tagLists.tags[]->title,
    title,
    metaData,
    thumbnail,
    _type,
    _id,
  }
`

export const FETCH_CONTENT_READ_TOGETHER_DETAIL_DATA_QUERY = groq`
  *[_type == "contentReadTogether" && slug.current == $slug][0]{
    isPublic,
    backgroundImage,
    dueDate,
    endDate,
    liveWebinarDateList,
    method,
    price,
    slug, 
    startDate,
    subTitle,
    "tags": tagLists.tags[]->title,
    title,
    metaData,
    thumbnail,
    _type,
    _id,
  }
`

export const FETCH_PLUS_TERMS_DATA_QUERY = groq`
  *[_type == "plusTerms" && language == $lang][0]
`

export const FETCH_PLUS_PRICING_HERO_QUERY = groq`
  *[_type == "pricingHero"]{
    desktop,
    mobile,
    type,
    title,
    detail,
  }
`

export const FETCH_BAND_BANNER_DATA_QUERY = groq` 
  *[_type == "bandBanner"][0]{
    mobile,
    d_day,
    desktop,
    background_color,
    display_period,
    app_link,
    web_link,
    enable_url
  }
`

export const FETCH_PORTAL_HOME_PROMOTION_BANNER_DATA_QUERY = groq`
  *[_type == "homePromotionBanner"][0]{
    desktop,
    mobile,
  }
`

export const FETCH_INVITE_MAIN_BANNER_DATA_QUERY = groq`
  *[_type == "inviteFriendBanner"][0]{
    desktop,
    mobile,
  }
`

export const FETCH_WELCOME_IMAGE_DATA_QUERY = groq`
  *[_type == "welcomeImage"][0]{
    desktop,
    mobile,
    d_day
  }
`

export const FETCH_PLUS_PRICING_FAQ_QUERY = groq`
  *[_type == "pricingFAQ" && slug.current == $slug][0]
`

export const FETCH_PROMOTION_DATE_DATA_QUERY = groq`
  *[_type == "promotionDate"][0]
`

export const FETCH_WEBVIEW_PORTAL_HOME_MARKETING_SECTION_BANNER_DATA = groq`
  *[_type == "webviewHomePromotionBanner"][0]
`

export const FETCH_WEBVIEW_PORTAL_HOME_PRICING_INDUCING_BANNER_DATA = groq`
  *[_type == "webviewHomePaymentInducingBanner"][0]
`

export const MKT_GNB_EVENT_MENU = groq`
  *[_type == "mktGnbMenu"][0]
`

export const FETCH_PRICING_EVENT_LIST_DATA = groq`
  *[_type == "pricingEventList"][0]
`
