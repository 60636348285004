// Legacy Checkbox -> 이 파일보다는 다음을 import 해서 사용하기를 권장
// import { Checkbox } from '@repo/design-system/index'

import { Fragment, MouseEventHandler, useState } from 'react'
import {
  ICON_CHECKBOX_ACTIVE,
  ICON_CHECKBOX_ACTIVE_DANGER,
  ICON_CHECKBOX_ACTIVE_INFO,
  ICON_CHECKBOX_INACTIVE,
} from '../../modules/svg/svg'
import { removeArray } from '../../modules/utils'
import Div from '../basic/Div'

interface CheckBoxProps {
  checked: boolean
  label: React.ReactNode | string
  gap?: number
  mrCheckbox?: number
  clx?: string
  disabled?: boolean
  teens?: boolean
  danger?: boolean
  info?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

interface CheckBoxGroupProps {
  data: { name: string; items: any[] }
  onChange: (newArr: string[] | number[]) => void
  gap: number
}

const CheckBoxNew = ({ checked, disabled, label, danger, info, ...props }: CheckBoxProps) => {
  const { onClick, clx, gap, teens, mrCheckbox, ...others } = props
  const getCheckmark = () => {
    if (checked) {
      if (danger) {
        return ICON_CHECKBOX_ACTIVE_DANGER
      } else if (info) {
        return ICON_CHECKBOX_ACTIVE_INFO
      }
      return ICON_CHECKBOX_ACTIVE
    } else {
      return ICON_CHECKBOX_INACTIVE
    }
  }

  return (
    <>
      {!disabled ? (
        <Div cursorPointer={!disabled} flex itemsCenter textGray600 mb={gap} onClick={onClick} clx={clx} {...others}>
          <div className={`mr-[${mrCheckbox ?? 16}px]`}>
            <img style={{ width: '20px', height: '20px', maxWidth: 'none' }} src={getCheckmark()} />
          </div>
          <h5 className="text-h5 text-left text-gray-700">{label}</h5>
        </Div>
      ) : (
        <Div cursorPointer={!disabled} flex itemsCenter textGray600 mb={gap} onClick={onClick} clx={clx} {...others}>
          <div className={`mr-[${mrCheckbox ?? 16}px]`}>
            <img
              style={{ width: '20px', height: '20px', maxWidth: 'none' }}
              src={checked ? ICON_CHECKBOX_ACTIVE : ICON_CHECKBOX_INACTIVE}
              className="h-[20px] w-[20px] opacity-60 grayscale filter"
            />
          </div>
          <h5 className="text-h5 text-left text-gray-500">{label}</h5>
        </Div>
      )}
    </>
  )
}

const CheckBoxGroupNew = ({ data, onChange, gap, ...others }: CheckBoxGroupProps) => {
  const { name, items } = data

  const initSelected = items
    .filter((item, i) => item.checked)
    .map((item, i) => {
      return item.value
    })

  const [selectedArray, setSelectedArray] = useState(initSelected)

  const onChangeData = (e, id) => {
    e.preventDefault()
    let newArr = selectedArray
    const item = selectedArray.find((item, i) => item === id)
    if (item) {
      newArr = removeArray(selectedArray, item)
    } else {
      newArr.push(id)
    }
    setSelectedArray(newArr)
    onChange(newArr)
  }
  return (
    <>
      {items.map((item, i) => (
        <Fragment key={`${name}-${i}`}>
          <CheckBoxNew
            checked={selectedArray.includes(item.value)}
            label={item.label}
            gap={gap}
            onClick={(e) => onChangeData(e, item.value)}
            {...others}
          />
        </Fragment>
      ))}
    </>
  )
}

export { CheckBoxGroupNew, CheckBoxNew }
