import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import useDSBottomSheetAnimation from '../modules/useDSBottomSheetAnimation'
import { useDSBottomSheetTouchEvent } from '../modules/useDSBottomSheetTouchEvent'
import DSBottomSheetCloseButton from './DSBottomSheetCloseButton'

const BOTTOMSHEET_CONTAINER_ID = 'ds-bottom-sheet'

interface DSBottomSheetMainProps {
  id: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  closeButton?: boolean
  closeOnDim?: boolean
  closeOnDrag?: boolean
  onClose?: any
  children: React.ReactNode
  enableScroll?: boolean
  noDim?: boolean
  zIndex?: string
  isWebview?: boolean
  maxH?: number
  wrapperStyle?: React.CSSProperties
  noScrollbar?: boolean
}

const DSBottomSheetMain = (props: DSBottomSheetMainProps) => {
  const {
    id,
    isOpen,
    setIsOpen,
    closeButton,
    closeOnDim,
    closeOnDrag,
    onClose,
    children,
    enableScroll = false,
    noDim = false,
    zIndex = '2147483647',
    isWebview = false,
    maxH,
    wrapperStyle,
    noScrollbar,
  } = props
  const [isMounted, setIsMounted] = useState(false)
  const bottomSheetDOM = isMounted && document?.getElementById(BOTTOMSHEET_CONTAINER_ID)
  const bottomSheetBackgroundDOM = isMounted && document?.getElementById(id)
  const shouldShow = isMounted && isOpen

  const { translateY, setTranslateY, onTouchStart, onTouchMove, onTouchEnd } = useDSBottomSheetTouchEvent({
    setIsOpen,
    closeOnDrag,
    isWebview,
  })
  const { shouldRender, endTransition, triggerAnimation } = useDSBottomSheetAnimation(shouldShow)

  const closeBottomSheet = () => {
    setIsOpen(false)

    if (onClose) onClose()
  }
  const onClickDim = closeOnDim ? closeBottomSheet : undefined
  const onClickContainer: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }
  const onTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (e) => {
    if (setTranslateY) setTranslateY(0)
    endTransition()
  }

  // 배경 스크롤 방지
  useEffect(() => {
    setIsMounted(true)
    if (!enableScroll) {
      if (shouldShow) {
        document.body.style.overflow = 'hidden'
        if (bottomSheetBackgroundDOM) {
          bottomSheetBackgroundDOM.style.zIndex = zIndex
        }
      } else {
        if (bottomSheetBackgroundDOM) {
          setTimeout(() => {
            bottomSheetBackgroundDOM.style.zIndex = '-1'
          }, 500)
        }
      }
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [shouldShow, enableScroll, bottomSheetBackgroundDOM])

  if (!shouldRender) return null

  return createPortal(
    <Dim id={id} noDim={noDim} onClick={onClickDim} triggerAnimation={triggerAnimation}>
      <Container
        onClick={onClickContainer}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onTransitionEnd={onTransitionEnd}
        triggerAnimation={triggerAnimation}
        translateY={translateY}
        style={wrapperStyle}
      >
        {closeButton && <DSBottomSheetCloseButton onClick={closeBottomSheet} />}
        <div
          className={classNames('h-full', {
            'no-scrollbar overflow-y-auto': noScrollbar,
            'scrollbar-new overflow-y-auto': !noScrollbar,
          })}
          style={{
            maxHeight: maxH,
          }}
        >
          {closeOnDrag && (
            <div className="flex w-full flex-row items-start justify-center">
              <div className="h-[4px] w-[36px] rounded-[3px] bg-gray-300"></div>
            </div>
          )}
          {children}
        </div>
      </Container>
    </Dim>,
    // bottomSheetDOM
    document.body
  )
}

export default DSBottomSheetMain

const Dim = (props) => {
  const { triggerAnimation, noDim, ...rest } = props
  const getStyle = (triggerAnimation, noDim) =>
    classNames(
      `fixed top-0 left-0 z-[2147483647] flex h-full w-full flex-col justify-end`,
      `transition-colors duration-500`,
      !noDim && `bg-[rgba(0,0,0,0.4)]`,
      triggerAnimation || `bg-[rgba(0,0,0,0)]`
    )
  return <div className={getStyle(triggerAnimation, noDim)} {...rest}></div>
}
const Container = (props) => {
  const { triggerAnimation, translateY, ...rest } = props
  const getStyle = (triggerAnimation, translateY) =>
    classNames(
      `font-preten fixed w-full rounded-t-[16px] bg-white pt-[16px] shadow-[0_-2px_20px_0_rgba(0,0,0,0.1)]`,
      `transition-[top] duration-500`,
      triggerAnimation || `top-full`,
      `transform: translate(0, ${translateY}px)`
    )
  return <div className={getStyle(triggerAnimation, translateY)} {...rest}></div>
}
