import { BuilderButton } from '../../../index'
import { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from '../../Components/BuilderButton/types'
import { COLORS } from '../../modules/vars'
import { CodeClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionStates = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  return (
    <section id="States">
      <div className={headerClassName}>States</div>

      <div className={descriptionClassName}>
        Hover와 Pressed는 직접 버튼 위에서 확인 할 수 있습니다.
        <br />
        <span className="text-green-600">
          *For Developer: <code className={CodeClassName}>customBgColor</code>를 지정하면 Hover와 Pressed 효과는
          사라집니다.
        </span>
      </div>
      <div>
        <div className={subHeaderClassName}>Solid Button</div>

        <div className="flex gap-[20px]">
          <div className="flex flex-col items-center">
            <BuilderButton size={BUILDER_BUTTON_SIZE.XL} type={BUILDER_BUTTON_TYPE.SOLID} onClick={handleButtonClick}>
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Enabled</strong>
            <p>100%</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
              className="bg-opacity-70"
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Hover</strong>
            <p>70%</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
              className="bg-opacity-50"
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Pressed</strong>
            <p>50%</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
              disabled={true}
              customBgColor={COLORS.GRAY50}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Disabled</strong>
            <p>Gray 200</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.SOLID}
              onClick={handleButtonClick}
              isLoading={true}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Loading</strong>
          </div>
        </div>
      </div>

      <div>
        <div className={subHeaderClassName}>Outline Button</div>

        <div className="flex gap-[10px]">
          <div className="flex flex-col items-center">
            <BuilderButton size={BUILDER_BUTTON_SIZE.XL} type={BUILDER_BUTTON_TYPE.OUTLINE} onClick={handleButtonClick}>
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Enabled</strong>
            <p>Outline: PURPLE600</p>
            <p>Fill: White</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              customBgColor={COLORS.GRAY50}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Hover</strong>
            <p>Outline: PURPLE600</p>
            <p>Fill: Gray50</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              customBgColor={COLORS.GRAY100}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Pressed</strong>
            <p>Outline: PURPLE600</p>
            <p>Fill: Gray100</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              disabled={true}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Disabled</strong>
            <p>Fill: Gray200</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE}
              isLoading={true}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Loading</strong>
          </div>
        </div>
      </div>

      <div>
        <div className={subHeaderClassName}>Outline_Gray Button</div>

        <div className="flex gap-[10px]">
          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Enabled</strong>
            <p>Outline: Gray300</p>
            <p>Fill: White</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>마우스를 올려보세요</BuilderButton.Label>
            </BuilderButton>
            <strong>Hover</strong>
            <p>Outline: Gray300</p>
            <p>Fill: Gray50</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>누르고 있어보세요</BuilderButton.Label>
            </BuilderButton>
            <strong>Pressed</strong>
            <p>Outline: Gray300</p>
            <p>Fill: Gray50</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              disabled={true}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Disabled</strong>
            <p>Fill: Gray200</p>
          </div>

          <div className="flex flex-col items-center">
            <BuilderButton
              size={BUILDER_BUTTON_SIZE.XL}
              type={BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
              isLoading={true}
              onClick={handleButtonClick}
            >
              <BuilderButton.Label>라벨!</BuilderButton.Label>
            </BuilderButton>
            <strong>Loading</strong>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionStates
