import classNames from 'classnames'

interface LoadingSpinnerPropsType {
  show?: boolean
  muted?: boolean
  padded?: boolean
  primary?: boolean
  info?: boolean
  warning?: boolean
  danger?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  gray400?: boolean
  gray700?: boolean
  children?: React.ReactNode
}

const LoadingSpinner = ({
  show,
  muted,
  padded,
  primary,
  info,
  warning,
  danger,
  sm,
  md,
  lg,
  xl,
  gray400,
  gray700,
  children,
}: LoadingSpinnerPropsType) => {
  return (
    <>
      {show ? (
        <div className={`flex h-full w-full items-center justify-center ${padded && 'py-32'}`}>
          <div
            className={classNames(
              'loading-btn',
              sm && 'sm',
              lg && 'lg',
              md && 'md',
              muted && 'muted',
              gray700 && 'gray700',
              gray400 && 'gray400',

              primary && 'primary',
              warning && 'warning',
              danger && 'danger',
              info && 'info',
              xl && 'xl'
            )}
          ></div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default LoadingSpinner
