import Cookies from 'universal-cookie'
import { PLATFORM_TYPE, getCleanUrl } from '../../../helpers/gtmHelper'
import { useEventRouter } from '../../context/EventRouterContext'
import { useCurrentUserEventProps } from '../../context/UserContext'
import { RINGLE_DOMAIN } from '../../envVars'
import useTranslation from '../../i18n/useTranslation'
import { parseUtmParams } from '../../utils'
import useAppRouter from './useAppRouter'
import useIsMobile from './useIsMobile'

interface UserInfo {
  user_id?: number
  user_email?: string
  is_ringle_member?: boolean
  user_type?: string
  has_unused_paid_credit?: boolean
  purchase_count?: number
  purchase_amount?: number
  paid_20_credit_count?: number
  paid_40_credit_count?: number
}

interface ExecutionEnvironment {
  page_location: string
  page_location_path: string
  locale: string
  referrer_page: string
  referrer_page_path: string
  platform: PLATFORM_TYPE
  visit_referrer: string
  visit_utm_source: string | string[]
  visit_utm_medium: string | string[]
  visit_utm_campaign: string | string[]
  visit_utm_content: string | string[]
  visit_utm_term: string | string[]
}

interface DefaultEventPayload extends UserInfo, ExecutionEnvironment {}

export const getUrlPath = (pathname: string) => {
  const locale = ['ko', 'en', 'ja', 'vi', 'zh_chs', 'zh_cht']
  const pathSegments = pathname.split('/')

  if (pathSegments.length > 1 && locale.includes(pathSegments[1])) {
    pathSegments.splice(1, 1)
  }

  return pathSegments.join('/')
}

const isWebviewUrl = (pathname: string) => {
  return pathname.includes('webview')
}

export const useDefaultEventPayload = (): DefaultEventPayload => {
  const cookies = new Cookies()
  const visitUTMInfo = cookies?.get('visit_utm_ad_info')
  const parsedUTM = parseUtmParams(visitUTMInfo || '')

  const { locale } = useTranslation()
  const router = useAppRouter()

  const isMobile = useIsMobile()
  const isWebview = isWebviewUrl(router.asPath)

  const { currentUserEventProps } = useCurrentUserEventProps()
  const { urlInfo } = useEventRouter()

  const userInfoPayload: UserInfo = !currentUserEventProps
    ? {}
    : {
        user_id: currentUserEventProps.user_id,
        user_email: currentUserEventProps.user_email,
        is_ringle_member: currentUserEventProps.is_ringle_member,
        user_type: currentUserEventProps.user_type,
        has_unused_paid_credit: currentUserEventProps.has_unused_paid_credit,
        purchase_count: currentUserEventProps.purchase_count,
        purchase_amount: currentUserEventProps.purchase_amount,
        paid_20_credit_count: currentUserEventProps.paid_20_credit_count,
        paid_40_credit_count: currentUserEventProps.paid_40_credit_count,
      }

  const executionEnvironmentPayload: ExecutionEnvironment = {
    page_location: `https://${RINGLE_DOMAIN}${router.asPath}`,
    page_location_path: getCleanUrl(router.pathname),
    locale: locale,
    referrer_page: urlInfo ? urlInfo.prevUrl.fullUrl : null,
    referrer_page_path: urlInfo ? urlInfo.prevUrl.path : null,
    platform: isWebview ? PLATFORM_TYPE.APP : isMobile ? PLATFORM_TYPE.MOBILE_WEB : PLATFORM_TYPE.WEB,
    visit_referrer: (typeof document !== 'undefined' && document.referrer) || '',
    visit_utm_source: parsedUTM?.utm_source || 'null',
    visit_utm_medium: parsedUTM?.utm_medium || 'null',
    visit_utm_campaign: parsedUTM?.utm_campaign || 'null',
    visit_utm_content: parsedUTM?.utm_content || 'null',
    visit_utm_term: parsedUTM?.utm_term || 'null',
  }

  return {
    ...userInfoPayload,
    ...executionEnvironmentPayload,
  }
}
