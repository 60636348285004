// @ts-strict-ignore
import { DSBottomSheet } from '@repo/design-system/index'
import { isFunction } from 'lodash'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'
import useTranslation from '../../i18n/useTranslation'
import useModalBackButton from './useModalBackButton'

export const useBottomSheet = () => {
  const dispatch = useDispatch()

  const show = (props) => {
    dispatch(popupActions.show({ ...props, type: 'bottom-sheet' }))
  }

  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }

  return { show, close }
}

export const BottomSheet = ({ id }: { id: any }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)

  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )

  const {
    onClose = null,
    onOutsideClose = null,
    bottombarContent = null,
    body = undefined,
    closeButton = true,
    closeOnDim = false,
    closeOnDrag = false,
    enableScroll = false,
    noDim = false,
    zIndex = '2147483647',
  } = data ? data : {}

  console.log(data, 'data')
  const closeHandler = () => {
    dispatch(popupActions.close({ id }))
    setOpen(false)
    if (isFunction(onClose)) {
      onClose()
    }
  }
  useModalBackButton(id, isShow)

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  return (
    <div id={id}>
      <DSBottomSheet
        id={id}
        isOpen={open}
        setIsOpen={setOpen}
        closeButton={closeButton}
        closeOnDim={closeOnDim}
        closeOnDrag={closeOnDrag}
        enableScroll={enableScroll}
        noDim={noDim}
        zIndex={zIndex}
        onClose={() => closeHandler()}
      >
        {body}
        {bottombarContent}
      </DSBottomSheet>
    </div>
  )
}
