import axios from 'axios'

const axiosInstance = axios.create({
  timeout: 300000, // 5분을 밀리초로 설정합니다. (1분 = 60,000 밀리초)
})

//해당 설정에서는 then또는 catch로 처리되기 전에 요청과 응답을 가로채서
//특정 작업을 수행하게 할 수 있습니다.

// 요청 인터셉터 추가하기
axios.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    return config
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error)
  }
)

// 응답 인터셉터 추가하기
axios.interceptors.response.use(
  function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response
  },
  function (error) {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    return Promise.reject(error)
  }
)
export default axiosInstance
