import classNames from 'classnames'
import { Dispatch, KeyboardEventHandler, useRef, useState } from 'react'
import { ICON_X_GRAY } from '../../../modules/svg'
import { InputSize, InputType } from '../types'
import DSInputHelperText from './DSInputHelperText'
import DSInputTextCounter from './DSInputTextCounter'
import DSInputUpperText from './DSInputUpperText'

interface DSInputProps {
  type: InputType
  size: InputSize
  value: string
  setValue: Dispatch<string>
  /**
   * input 태그의 name 속성
   */
  name: string
  /** upperText : 좌상단 Input Label. required인 경우 *표시 */
  upperText?: string
  required?: boolean
  /** bottomText : 하단 도움말 텍스트
   * - 디폴트로 bottomText를 일반 텍스트로 보여주고,
   * - regex 설정한 경우 focusOut될 때 validation 체크하여 regex.message를 에러메시지로 보여준다 */
  bottomText?: React.ReactNode
  /**
   * Input Text clear 활성화 여부
   */
  placeholder?: string
  clearable?: boolean
  regex?: { pattern: RegExp; message: string }
  /**
   * input 태그의 autoFocus 속성
   */
  autoFocus?: boolean
  textCounter?: {
    maxLength: number
  }
  /**
   * input 태그의 onKeyDown 속성
   */
  onKeyDown?: KeyboardEventHandler<Element>
  className?: string
  icon?: React.ReactNode
  disabled?: boolean
}

const DSInputMain = (props: DSInputProps) => {
  const {
    type,
    size,
    value,
    setValue,
    name,
    upperText = '',
    required = false,
    bottomText = '',
    placeholder = '',
    clearable = false,
    regex = undefined,
    autoFocus = false,
    textCounter = undefined,
    onKeyDown,
    className,
    icon,
    disabled = false,
  } = props

  const [isFocused, setIsFocused] = useState<boolean>(autoFocus)
  const [isError, setIsError] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const clearInputText = () => {
    setValue('')
    setIsFocused(true)
    setIsError(false)
    inputRef.current?.focus()
  }
  const textLength = value?.length ?? 0

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }
  const onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    setIsFocused(false)
    if (regex) {
      const { pattern } = regex
      if (!pattern.test(value)) {
        setIsError(true)
        return
      }
      setIsError(false)
    }
  }
  const onFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    setIsFocused(true)
  }

  return (
    <div className="relative flex w-full flex-col">
      {/*
       * Upper Section
       */}
      {/* input값 없으면 UpperText와 TextCounter 사라짐 */}
      {textLength > 0 && (upperText || textCounter) && (
        <div className={`flex h-[19px] items-center ${upperText ? 'justify-between' : 'justify-end'}`}>
          {upperText && <DSInputUpperText required={required}>{upperText}</DSInputUpperText>}
          {textCounter && <DSInputTextCounter value={value} maxLength={textCounter.maxLength} />}
        </div>
      )}
      {/*
       * Input Area Section
       */}
      <div className="relative items-center">
        <Input
          isError={isError}
          sizeType={size}
          isFocused={isFocused}
          clearable={clearable}
          inputRef={inputRef}
          type={type}
          pattern={`${regex?.pattern}`}
          onError={() => setIsError(true)}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          name={name}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={textCounter?.maxLength}
          className={className}
          disabled={disabled}
        />
        {icon && <div className="absolute">{icon}</div>}
        {clearable && textLength > 0 && (
          <button className="block" type="button">
            <img
              src={ICON_X_GRAY}
              alt="clear input"
              className="absolute right-0 top-1/2 h-[16px] w-[16px] -translate-y-1/2 cursor-pointer self-center rounded-full bg-gray-200 p-[4px]"
              onClick={clearInputText}
            />
          </button>
        )}
      </div>

      {/*
       * Helper Text Section
       */}
      <DSInputHelperText type={isError ? 'error' : 'default'}>
        {isError && regex?.message ? regex.message : bottomText}
      </DSInputHelperText>
    </div>
  )
}

export default DSInputMain

const Input = (props) => {
  const { isError, sizeType, isFocused, clearable, className, ...rest } = props

  return (
    <input
      {...props}
      className={classNames(
        `relative flex w-full border-x-0 border-t-0 border-b-2 border-gray-200 py-0 pl-0 text-black !ring-0 !ring-opacity-0 focus:outline-0`,
        sizeType === 'sm' && `Title1-20Md`,
        sizeType === 'md' && `Headline2-24Md`,
        sizeType === 'lg' && `Body1-16Md `,
        isFocused && `focus:border-b-purple-500`,
        isError && `border-b-red-500`,
        clearable ? `pr-[20px]` : `pr-0`,
        className
      )}
    ></input>
  )
}
