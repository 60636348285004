// @ts-strict-ignore
import * as Sentry from '@sentry/react'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import {
  extractUserTypeFromPath,
  isGTMLoaded,
  LOCALE_USER_TYPE,
  setGTMPlusCreditStatus,
  setGTMPlusUserInfo,
  setGTMTutorStatus,
  setGTMUserId,
} from '../../helpers/gtmHelper'
import { CurrentUser, IStudentUser, USER_ROLE } from '../../model/Common/common.interface'
import { useUser } from '../apis/common/user'
import { getJwtToken } from '../auth'
import useIsAdminLogin from '../hooks/common/useIsAdminLogin'
import { ROLE_STUDENT, ROLE_TUTOR, SESSION_ROLE } from '../vars'
import { navigateUserByPath } from './UserContextHelper'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import Cookies from 'universal-cookie'
import { getEnv } from '../envUtil'
import { IS_DEVELOPMENT } from '../envVars'
import { userQueryApi } from '../react-query/queryApis'
declare global {
  interface Window {
    ChannelIO: any
  }
}
interface ContextProps {
  readonly currentUser: CurrentUser
  readonly localeUserType: LOCALE_USER_TYPE
}

export const UserContext = createContext<ContextProps>({
  currentUser: null,
  localeUserType: null,
})

export const UserProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const isAdminLogin = useIsAdminLogin()
  const cookies = new Cookies()

  const UserContextLoading = useMemo(
    () => (
      <div>
        <div className="fixed flex h-screen w-screen items-center justify-center">
          <LoadingSpinner show primary lg />
        </div>
      </div>
    ),
    []
  )

  const { isLoading, isFetching, data, dataUpdatedAt }: UseQueryResult<CurrentUser> = useUser()

  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const currentUser = isLoading || !data ? null : data

  const userData = useMemo(() => currentUser, [dataUpdatedAt])
  const isPortal = useMemo(() => router.asPath.includes('/portal/'), [router.asPath])
  const localeUserType = extractUserTypeFromPath(router.asPath)
  const token = getJwtToken()
  const isTutorPath = useMemo(() => router.asPath.includes('/en/tutor/'), [router.asPath])
  useEffect(() => {
    if (!token) {
      console.log('USER CONTEXT - NO JWT_TOKEN')
      // * Reset User Query if the query has data even though there is no token inside the cookie
      if (data) {
        queryClient.resetQueries(userQueryApi.getQueryKey())
      }

      if (!isFetching) {
        navigateUserByPath(router.asPath, router.pathname, userData, router)
      }
    } else {
      if (data) {
        navigateUserByPath(router.asPath, router.pathname, userData, router)
      }

      // If token is valid & GTM is loaded, check below data on page move to make sure Amplitude has the most recent data
      if (data && isGTMLoaded()) {
        // If it's PLUS user, send plus properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.STUDENT && userData.type === 'Student' && !isAdminLogin) {
          console.log('***************************************')
          console.log('[ UPDATE AMP PLUS USER PROPERTIES ]')
          setGTMPlusCreditStatus(userData.unused_coupons > 0)
          setGTMPlusUserInfo(
            userData.email,
            (userData as IStudentUser).profile.mobileNumber,
            (userData as IStudentUser).profile.entered_lesson_count,
            (userData as IStudentUser).profile.finished_lesson_count,
            userData.is_ringle_member
          )
          console.log('***************************************')
        }

        // If it's TUTOR user, send tutor properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.TUTOR && userData.role === USER_ROLE.TUTOR && !isAdminLogin) {
          console.log('****************************************')
          console.log('[ UPDATE AMP TUTOR USER PROPERTIES ]')
          setGTMTutorStatus(userData.tutor_status_id)
          console.log('****************************************')
        }
      }
    }
  }, [router.asPath, token])

  // datadog 관련 설정
  useEffect(() => {
    if (IS_DEVELOPMENT) {
      return
    }

    // 로그인 후에는 user의 role 로 serviceName 을 결정.
    // 로그인 전에는 path로 serviceName을 결정
    const isTutor = true
    const domain = 'www.ringletutor.com'
    const RELEASE_VERSION = getEnv('CONF_RELEASE_VERSION')
    const logClientToken = 'pub10a8045aeb5d7ea45141fae5dad3f955'
    const datadogServiceName = isTutor ? 'ringle-tutor-web' : 'ringle-plus-web'
    const rumApplicationId = isTutor ? '223c592d-983c-458c-9de8-ffbbe2899ca7' : '872ecb7b-ac90-428d-a8a3-2915a94e52c4'
    const rumClientToken = isTutor ? 'pub8fe05179e3e0e6bc7736fbf05eb642d1' : 'pub3496b2d69b171f31ff2e3ce760c618b8'
    const rumEnv = window?.location?.host === domain ? 'production' : 'qa'

    // datadog log init
    if (datadogLogs.getInitConfiguration()?.service !== datadogServiceName) {
      datadogLogs.init({
        clientToken: logClientToken,
        site: 'datadoghq.com',
        env: rumEnv,
        service: datadogServiceName,
        version: RELEASE_VERSION,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        forwardReports: 'all',
        sampleRate: 100,
      })
    }

    // datadog rum init
    if (datadogRum.getInitConfiguration()?.service !== datadogServiceName) {
      datadogRum.init({
        applicationId: rumApplicationId,
        clientToken: rumClientToken,
        site: 'datadoghq.com',
        env: rumEnv,
        service: datadogServiceName,
        version: RELEASE_VERSION,
        enableExperimentalFeatures: ['clickmap', 'feature_flags'],
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        allowedTracingOrigins: [/https:\/\/.*\.ringle(tutor|plus)\.com/],
      })
      // 로그인 안했떠라도 일단 녹화 시작
      console.info(datadogRum, 'datadogRum')
      datadogRum.startSessionReplayRecording()
    }
    // console.info(datadogRum.getInternalContext())

    if (currentUser && currentUser.id) {
      if (currentUser.role == ROLE_STUDENT) {
        datadogRum.setUser({
          id: `${currentUser.id}`,
          email: currentUser.email,
          name:
            (currentUser.profile?.korean_name || currentUser.profile?.first_name) + (isAdminLogin ? ' (Admin)' : ''),
        })
      }
      if (currentUser.role == ROLE_TUTOR) {
        datadogRum.setUser({
          id: `${currentUser.id}`,
          email: currentUser.email,
          name: currentUser.first_name + (isAdminLogin ? ' (Admin)' : ''),
        })
      }
    } else {
      datadogRum.removeUser()
    }
  }, [currentUser, isAdminLogin, isTutorPath])

  useEffect(() => {
    if (isFetching) {
      console.log('USER CONTEXT - IS LOADING')
      setIsDataLoaded(false)
    } else {
      console.log('USER CONTEXT - CURRENT USER:', userData)
      navigateUserByPath(router.asPath, router.pathname, userData, router)
      setIsDataLoaded(true)

      // * [ - TWO CASES - ]
      // ? 1. GTM Init before within landing -> Set User ID to GTM
      // ? 2. User intial access with token -> Initialize GTM with User ID
      if (userData) {
        setGTMUserId(localeUserType, userData.id, userData.email, userData.is_ringle_member, isAdminLogin)

        // If it's PLUS user, send plus properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.STUDENT && userData.type === 'Student' && !isAdminLogin) {
          console.log('***************************************')
          console.log('[ UPDATE AMP PLUS USER PROPERTIES ]')
          setGTMPlusCreditStatus(userData.unused_coupons > 0)
          setGTMPlusUserInfo(
            userData.email,
            (userData as IStudentUser).profile.mobileNumber,
            (userData as IStudentUser).profile.entered_lesson_count,
            (userData as IStudentUser).profile.finished_lesson_count,
            userData.is_ringle_member
          )
          console.log('***************************************')
        }

        // If it's TUTOR user, send tutor properties to GTM
        if (localeUserType === LOCALE_USER_TYPE.TUTOR && userData.role === USER_ROLE.TUTOR && !isAdminLogin) {
          console.log('****************************************')
          console.log('[ UPDATE AMP TUTOR USER PROPERTIES ]')
          setGTMTutorStatus(userData.tutor_status_id)
          console.log('****************************************')
        }

        // sentry user info
        Sentry.setUser({
          email: userData.email,
          id: userData.id + '',
        })
      }
    }
  }, [isFetching])

  const showLoading = !isDataLoaded && isPortal
  const showChildren = isDataLoaded || !isPortal
  // role과 접근하려는 url이 다른 경우에 리다이렉트 시키기 이전에 화면에 접근 시도가 되어 에러 페이지가 보여지는 부분 수정
  const sessionRole = cookies.get('session_role')
  let showAfterRedirect = true
  if (sessionRole && router.pathname !== '/_error') {
    if (sessionRole === SESSION_ROLE.STUDENT && router.asPath.includes('/tutor/portal')) {
      showAfterRedirect = false
    } else if (sessionRole === SESSION_ROLE.TUTOR && router.asPath.includes('/student/portal')) {
      showAfterRedirect = false
    }
  }

  return (
    <UserContext.Provider value={{ currentUser: isDataLoaded ? userData : null, localeUserType }}>
      {showLoading && UserContextLoading}
      {showChildren && showAfterRedirect && <div>{children}</div>}
      {/* <div className={`${!isDataLoaded ? 'opacity-0' : ''}`}>{children}</div> */}
      {/*
        FIXME
        - ReflexContainer 사용 되는 부분 SSR로 currentUser 들고 오고 있어서 많은 로직이 currentUser의 null 방어 코드가 없음
        - getInitialProps 부분의 currentUser 제거 후 각 useEffect 및 기타 로직에서 currentUser가 없을 때 처리가 되어 있어야함
        - 해당 부분 다 수정이 되고 나서 opacity-0에서 hidden으로 다시 변경 가능
      */}
    </UserContext.Provider>
  )
}

export const useCurrentUser = () => useContext(UserContext)
