export const enum BUILDER_BUTTON_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export type BUILDER_BUTTON_SIZE_KEY = BUILDER_BUTTON_SIZE[keyof BUILDER_BUTTON_SIZE]

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const BUILDER_BUTTON_SIZE_NAMES = {
  xs: 'BUILDER_BUTTON_SIZE.XS',
  sm: 'BUILDER_BUTTON_SIZE.SM',
  md: 'BUILDER_BUTTON_SIZE.MD',
  lg: 'BUILDER_BUTTON_SIZE.LG',
  xl: 'BUILDER_BUTTON_SIZE.XL',
}

export const enum BUILDER_BUTTON_TYPE {
  SOLID = 'solid',
  OUTLINE = 'outline',
  OUTLINE_GRAY = 'outline_gray',
  TEXT = 'text',
}

export type BUILDER_BUTTON_TYPE_KEY = BUILDER_BUTTON_TYPE[keyof BUILDER_BUTTON_TYPE]

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const BUILDER_BUTTON_TYPE_NAMES = {
  solid: 'BUILDER_BUTTON_TYPE.SOLID',
  outline: 'BUILDER_BUTTON_TYPE.OUTLINE',
  outline_gray: 'BUILDER_BUTTON_TYPE.OUTLINE_GRAY',
  text: 'BUILDER_BUTTON_TYPE.TEXT',
}
