import { QIcon_check_mark, QIcon_plus_default } from '@repo/icons/index'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { DSInput } from '../../..'
import { BuilderButton, Switch } from '../../../index'
import CodeSnippet from '../../CodeSnippet'
import {
  BUILDER_BUTTON_SIZE,
  BUILDER_BUTTON_SIZE_NAMES,
  BUILDER_BUTTON_TYPE,
  BUILDER_BUTTON_TYPE_NAMES,
} from '../../Components/BuilderButton/types'
import DSRadio from '../../Components/Radio/DSRadio'
import { COLORS } from '../../modules/vars'
import { ControlClassName, headerClassName, rowContainerClassName, subHeaderClassName } from '../../style'

interface BuilderButtonSectionProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SectionButtonExample = ({ handleButtonClick }: BuilderButtonSectionProps) => {
  const [customBgColor, setCustomBgColor] = useState<string>(undefined)
  const [customBorderColor, setCustomBorderColor] = useState<string>(undefined)
  const [customTxtColor, setCustomTxtColor] = useState<string>(undefined)

  const [type, setType] = useState<BUILDER_BUTTON_TYPE>(BUILDER_BUTTON_TYPE.SOLID)
  const [size, setSize] = useState<BUILDER_BUTTON_SIZE>(BUILDER_BUTTON_SIZE.SM)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [width, setWidth] = useState<'default' | '200px' | 'w-full'>('default')
  const [withIcon, setWithIcon] = useState<'textOnly' | 'textWithIcon' | 'iconOnly'>('textOnly')

  useEffect(() => {
    if (disabled) {
      setIsLoading(false)
    }
  }, [disabled])

  useEffect(() => {
    if (withIcon === 'iconOnly') {
      if (type === BUILDER_BUTTON_TYPE.TEXT) {
        setType(BUILDER_BUTTON_TYPE.SOLID)
      }
      if (size === BUILDER_BUTTON_SIZE.LG || size === BUILDER_BUTTON_SIZE.XL) {
        setSize(BUILDER_BUTTON_SIZE.SM)
      }
    }
  }, [withIcon])

  return (
    <div>
      <div className={headerClassName} id="BuilderButton">
        BuilderButton
      </div>

      <section className="flex flex-col border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
        <div className={subHeaderClassName}>Controls</div>

        <div className="flex flex-row">
          <div className={ControlClassName}>type*</div>
          <DSRadio controlSelected={[type, setType]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="solid">solid</DSRadio.Item>
            <DSRadio.Item value="outline">outline</DSRadio.Item>
            <DSRadio.Item value="outline_gray">outline_gray</DSRadio.Item>
            <DSRadio.Item value="text" disabled={withIcon === 'iconOnly'}>
              text
            </DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>size*</div>
          <DSRadio controlSelected={[size, setSize]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="xs" disabled={type === BUILDER_BUTTON_TYPE.TEXT}>
              xs
            </DSRadio.Item>
            <DSRadio.Item value="sm">sm</DSRadio.Item>
            <DSRadio.Item value="md">md</DSRadio.Item>
            <DSRadio.Item value="lg" disabled={withIcon === 'iconOnly'}>
              lg
            </DSRadio.Item>
            <DSRadio.Item value="xl" disabled={type === BUILDER_BUTTON_TYPE.TEXT || withIcon === 'iconOnly'}>
              xl
            </DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>disabled</div>
          <Switch checked={disabled} setChecked={setDisabled} />
        </div>

        <div className="flex flex-row items-center">
          <div
            className={classNames(ControlClassName, (disabled || type === BUILDER_BUTTON_TYPE.TEXT) && 'text-gray-300')}
          >
            isLoading
          </div>
          <Switch
            checked={isLoading}
            setChecked={setIsLoading}
            disabled={disabled || type === BUILDER_BUTTON_TYPE.TEXT}
          />
        </div>

        <div className="flex flex-row items-center">
          <div
            className={classNames(
              ControlClassName,
              (type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY || type === BUILDER_BUTTON_TYPE.TEXT) && 'text-gray-300'
            )}
          >
            background color
          </div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #157B56)"
            value={customBgColor}
            setValue={setCustomBgColor}
            name={'button background color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY || type === BUILDER_BUTTON_TYPE.TEXT}
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, type !== BUILDER_BUTTON_TYPE.OUTLINE && 'text-gray-300')}>
            border color
          </div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #157B56)"
            value={customBorderColor}
            setValue={setCustomBorderColor}
            name={'button border color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={type !== BUILDER_BUTTON_TYPE.OUTLINE}
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY && 'text-gray-300')}>
            text color
          </div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #FFFFFF)"
            value={customTxtColor}
            setValue={setCustomTxtColor}
            name={'button text color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={type === BUILDER_BUTTON_TYPE.OUTLINE_GRAY}
          />
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>너비 조절 방법 (코드 확인)</div>
          <DSRadio controlSelected={[width, setWidth]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="default">최소</DSRadio.Item>
            <DSRadio.Item value="200px">200px</DSRadio.Item>
            <DSRadio.Item value="w-full">w-full</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>아이콘 유무 (코드 확인)</div>
          <DSRadio controlSelected={[withIcon, setWithIcon]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value="textOnly">text만</DSRadio.Item>
            <DSRadio.Item value="textWithIcon">text + icon</DSRadio.Item>
            <DSRadio.Item
              value="iconOnly"
              disabled={
                type === BUILDER_BUTTON_TYPE.TEXT || size === BUILDER_BUTTON_SIZE.LG || size === BUILDER_BUTTON_SIZE.XL
              }
            >
              icon만
            </DSRadio.Item>
          </DSRadio>
        </div>
      </section>

      <section className={`flex flex-row w-[560px] justify-center my-[40px] ${width === '200px' && 'w-[200px]'}`}>
        {withIcon === 'iconOnly' ? (
          <div className={width === '200px' ? 'w-[200px]' : 'flex justify-center w-full'}>
            <BuilderButton
              type={type}
              size={size}
              onClick={handleButtonClick}
              iconOnly
              disabled={disabled}
              isLoading={isLoading}
              customBgColor={customBgColor}
              customBorderColor={customBorderColor}
              className={width === 'default' && 'max-w-max'}
            >
              <BuilderButton.Icon alt="check icon">
                <QIcon_check_mark
                  width={size !== BUILDER_BUTTON_SIZE.MD ? 20 : 24}
                  height={size !== BUILDER_BUTTON_SIZE.MD ? 20 : 24}
                  color={
                    type === BUILDER_BUTTON_TYPE.SOLID || disabled
                      ? COLORS.WHITE
                      : type === BUILDER_BUTTON_TYPE.OUTLINE
                      ? COLORS.PURPLE600
                      : COLORS.BLACK
                  }
                />
              </BuilderButton.Icon>
            </BuilderButton>
          </div>
        ) : (
          <div className={width === '200px' ? 'w-[200px]' : 'flex justify-center w-full'}>
            <BuilderButton
              size={size}
              type={type}
              textWithIcon={withIcon === 'textWithIcon'}
              onClick={handleButtonClick}
              disabled={disabled}
              isLoading={isLoading}
              customBgColor={customBgColor}
              customBorderColor={customBorderColor}
              className={width === 'default' && 'max-w-max'}
            >
              {withIcon === 'textWithIcon' && (
                <BuilderButton.Icon alt="plus icon">
                  <QIcon_plus_default
                    width={12}
                    height={12}
                    color={
                      type === BUILDER_BUTTON_TYPE.TEXT && disabled
                        ? COLORS.GRAY100
                        : type === BUILDER_BUTTON_TYPE.SOLID || disabled
                        ? COLORS.WHITE
                        : type === BUILDER_BUTTON_TYPE.OUTLINE
                        ? COLORS.PURPLE600
                        : COLORS.BLACK
                    }
                    className="mr-[4px]"
                  />
                </BuilderButton.Icon>
              )}
              <BuilderButton.Label customTxtColor={customTxtColor}>BuilderButton</BuilderButton.Label>
            </BuilderButton>
          </div>
        )}
      </section>

      <div id="ExampleCode" className={headerClassName}>
        Example Code
      </div>

      {withIcon === 'iconOnly' ? (
        <CodeSnippet
          code={`
import {
  BUILDER_BUTTON_SIZE,
  BUILDER_BUTTON_TYPE,
  COLORS,
  BuilderButton
} from '@repo/design-system/index'
import { QIcon_check_mark } from '@repo/icons/index'
              
const ButtonComponent = () => {

  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  return (
    <div className="${width === '200px' ? 'w-[200px]' : 'w-full'}">
      <BuilderButton
        size={${BUILDER_BUTTON_SIZE_NAMES[size]}}
        type={${BUILDER_BUTTON_TYPE_NAMES[type]}}
        onClick={handleButtonClick}
        iconOnly${
          disabled
            ? `
        disabled={true}`
            : ''
        }${
            isLoading
              ? `
        isLoading={true}`
              : ''
          }${
            customBgColor
              ? `
        customBgColor={COLORS.YELLOW100}`
              : ''
          }${
            customBorderColor
              ? `
        customBorderColor={COLORS.YELLOW100}`
              : ''
          }${
            width === 'default'
              ? `
        className="max-w-max"`
              : ''
          }
      >
        <BuilderButton.Icon alt="check icon">
          <QIcon_check_mark width={${size !== BUILDER_BUTTON_SIZE.MD ? 20 : 24}} height={${
            size !== BUILDER_BUTTON_SIZE.MD ? 20 : 24
          }} color={${
            type === BUILDER_BUTTON_TYPE.SOLID || disabled
              ? 'COLORS.WHITE'
              : type === BUILDER_BUTTON_TYPE.OUTLINE
              ? 'COLORS.PURPLE600'
              : 'COLORS.BLACK'
          }} />
        </BuilderButton.Icon>
      </BuilderButton>
    </div>
  )
}
`}
        />
      ) : (
        <CodeSnippet
          code={`
import {
  BUILDER_BUTTON_SIZE,
  BUILDER_BUTTON_TYPE,
  COLORS,
  BuilderButton
} from '@repo/design-system/index'
import { QIcon_plus_default } from '@repo/icons/index'
              
const ButtonComponent = () => {

  const handleButtonClick = () => {
    alert('button clicked🔥')
  }

  return (
    <div className="${width === '200px' ? 'w-[200px]' : 'w-full'}">
      <BuilderButton
        size={${BUILDER_BUTTON_SIZE_NAMES[size]}}
        type={${BUILDER_BUTTON_TYPE_NAMES[type]}}
        onClick={handleButtonClick}${
          withIcon === 'textWithIcon'
            ? `
        textWithIcon`
            : ''
        }${
            disabled
              ? `
        disabled={true}`
              : ''
          }${
            isLoading
              ? `
        isLoading={true}`
              : ''
          }${
            customBgColor
              ? `
        customBgColor={COLORS.YELLOW100}`
              : ''
          }${
            customBorderColor
              ? `
        customBorderColor={COLORS.YELLOW100}`
              : ''
          }${
            width === 'default'
              ? `
        className="max-w-max"`
              : ''
          }
      >${
        withIcon === 'textWithIcon'
          ? `
        <BuilderButton.Icon alt="plus icon">
          <QIcon_plus_default
            width={12}
            height={12}
            color={${
              type === BUILDER_BUTTON_TYPE.TEXT && disabled
                ? COLORS.GRAY100
                : type === BUILDER_BUTTON_TYPE.SOLID || disabled
                ? 'COLORS.WHITE'
                : type === BUILDER_BUTTON_TYPE.OUTLINE
                ? 'COLORS.PURPLE600'
                : 'COLORS.BLACK'
            }}
            className="mr-[4px]"
          />
        </BuilderButton.Icon>`
          : ''
      }
        <BuilderButton.Label${customTxtColor ? ' customTxtColor={COLORS.YELLOW900}>' : '>'}
          BuilderButton
        </BuilderButton.Label>
      </BuilderButton>
    </div>
  )
}
`}
        />
      )}
    </div>
  )
}

export default SectionButtonExample
