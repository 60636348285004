export const enum SEGMENT_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export type SEGMENT_SIZE_KEY = SEGMENT_SIZE[keyof SEGMENT_SIZE]

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const SEGMENT_SIZE_NAMES = {
  sm: 'SEGMENT_SIZE.SM',
  md: 'SEGMENT_SIZE.MD',
  lg: 'SEGMENT_SIZE.LG',
}

export const enum SEGMENT_TYPE {
  SOLID = 'solid',
  RAISED = 'raised',
}

export type SEGMENT_TYPE_KEY = SEGMENT_TYPE[keyof SEGMENT_TYPE]

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const SEGMENT_TYPE_NAMES = {
  solid: 'SEGMENT_TYPE.SOLID',
  raised: 'SEGMENT_TYPE.RAISED',
}

export type Selected = string | number | boolean
export type SelectedStateAction = React.Dispatch<React.SetStateAction<Selected[]>>
