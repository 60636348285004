// @ts-strict-ignore
import { useRouter } from 'next/router'
import { Fragment, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { BottomSheet } from '../../../modules/hooks/common/BottomSheet'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'
import { Popup } from '../../basic/Popup'
import { AlertPopup } from '../../basic/popup/AlertPopup'
import { BasicPopup } from '../../basic/popup/BasicPopup'
import { SidePopup } from '../../basic/popup/SidePopup'
import { SplitPopup } from '../../basic/popup/SplitPopup'
import { TranslatorSidePopup } from '../../lessons/lesson-materials/question/TranslatorSidePopup'
import { ConfirmPopup } from './ConfirmPopup'

const PopupProvider = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const popups = useSelector((state: RootState) => state.popup.data, shallowEqual)

  useEffect(() => {
    return () => {
      dispatch(popupActions.closeAll())
    }
  }, [router.asPath])

  return (
    <Fragment>
      {Object.keys(popups)
        .map((key) => popups[key])
        .map((item, idx) => {
          if (item?.type == 'confirm') return <ConfirmPopup key={idx} id={item.id} />
          if (item?.type == 'split') return <SplitPopup key={idx} id={item.id} />
          if (item?.type == 'popup') return <Popup key={idx} id={item.id} />
          if (item?.type == 'side') return <SidePopup key={idx} id={item.id} />
          if (item?.type == 'basic') return <BasicPopup key={idx} id={item.id} />
          if (item?.type == 'alert') return <AlertPopup key={idx} id={item.id} />
          if (item?.type == 'bottom-sheet') return <BottomSheet key={idx} id={item.id} />
          if (item?.type == 'translatorSide') return <TranslatorSidePopup key={idx} id={item.id} />
        })}
    </Fragment>
  )
}

export default PopupProvider
