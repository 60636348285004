import { createContext, useContext } from 'react'
import { SEGMENT_SIZE_KEY, SEGMENT_TYPE_KEY, Selected, SelectedStateAction } from '../types'

interface DefaultValueState {
  type: SEGMENT_TYPE_KEY
  size: SEGMENT_SIZE_KEY
  selectedList: Selected[]
  setSelectedList: SelectedStateAction
  multiple: boolean
}

export const SegmentContext = createContext<DefaultValueState | null>(null)

export const useButtonState = () => {
  const context = useContext(SegmentContext)
  if (!context) {
    throw new Error('useSegmentState는 SegmentProvider 안에서만 사용 가능합니다.')
  }
  return context
}
