import BuilderButtonIcon from './lib/Components/BuilderButton/ui/BuilderButtonIcon'
import BuilderButtonLabel from './lib/Components/BuilderButton/ui/BuilderButtonLabel'
import BuilderButtonMain from './lib/Components/BuilderButton/ui/BuilderButtonMain'
import ButtonIcon from './lib/Components/Button/ui/ButtonIcon'
import ButtonLabel from './lib/Components/Button/ui/ButtonLabel'
import ButtonMain from './lib/Components/Button/ui/ButtonMain'
import CheckboxGroup from './lib/Components/Checkbox/ui/CheckboxGroup'
import CheckboxItem from './lib/Components/Checkbox/ui/CheckboxItem'
import ChipIcon from './lib/Components/Chip/ui/ChipIcon'
import ChipLabel from './lib/Components/Chip/ui/ChipLabel'
import ChipMain from './lib/Components/Chip/ui/ChipMain'
import DropdownButton from './lib/Components/Dropdown/ui/DropdownButton'
import DropdownToggle from './lib/Components/Dropdown/ui/DropdownLabel'
import DropdownMain from './lib/Components/Dropdown/ui/DropdownMain'
import DropdownMenu from './lib/Components/Dropdown/ui/DropdownMenu'
import DropdownMenuItem from './lib/Components/Dropdown/ui/DropdownMenuItem'
import DSModalButtonContainer from './lib/Components/Popup/ui/DSModalButtonContainer'
import DSModalContent from './lib/Components/Popup/ui/DSModalContent'
import DSModalMain from './lib/Components/Popup/ui/DSModalMain'
import DSModalTitle from './lib/Components/Popup/ui/DSModalTitle'
import DSPromoPopupButtonContainer from './lib/Components/PromoPopup/ui/DSPromoPopupButtonContainer'
import DSPromoPopupContent from './lib/Components/PromoPopup/ui/DSPromoPopupContent'
import DSPromoPopupMain from './lib/Components/PromoPopup/ui/DSPromoPopupMain'
import SegmentItem from './lib/Components/Segment/ui/SegmentItem'
import SegmentMain from './lib/Components/Segment/ui/SegmentMain'
import DSTooltipCloseButton from './lib/Components/Tooltip/ui/DSTooltipCloseButton'
import DSTooltipLinkButton from './lib/Components/Tooltip/ui/DSTooltipLinkButton'
import DSTooltipMain from './lib/Components/Tooltip/ui/DSTooltipMain'
import { COLORS } from './lib/modules/vars'

// Use this file to export React client components (e.g. those with 'use client' directive) or other non-server utilities

// ========================================== basic-system ============================================

export { default as ColorStyle } from './lib/basic-system/ColorStyle'
export { default as PromotionColor } from './lib/basic-system/PromotionColor'
export { default as States } from './lib/basic-system/States'

export { default as LegacyIcons } from './lib/LegacyIcons'
export { default as QIcons } from './lib/QIcons'
export { default as Welcome } from './lib/Welcome'

// export type { COLOR_KEY } from './lib/modules/vars'
export type COLOR_KEY = (typeof COLORS)[keyof typeof COLORS]

// ========================================== components ============================================

// BottomSheet
export { default as DSBottomSheet } from './lib/Components/BottomSheet/ui/DSBottomSheetMain'

// Button
export { BUTTON_SIZE, BUTTON_TYPE } from './lib/Components/Button/types'
export const DSButton = Object.assign(ButtonMain, {
  Label: ButtonLabel,
  Icon: ButtonIcon,
})

// Setment
export { SEGMENT_SIZE, SEGMENT_TYPE } from './lib/Components/Segment/types'
export const DSSegment = Object.assign(SegmentMain, {
  Item: SegmentItem,
})

// Button for Builder.io
export { BUILDER_BUTTON_SIZE, BUILDER_BUTTON_TYPE } from './lib/Components/BuilderButton/types'
export type { BuilderButtonLabelProps } from './lib/Components/BuilderButton/ui/BuilderButtonLabel'
export type { BuilderButtonMainProps } from './lib/Components/BuilderButton/ui/BuilderButtonMain'
export const BuilderButton = Object.assign(BuilderButtonMain, {
  Label: BuilderButtonLabel,
  Icon: BuilderButtonIcon,
})

// Checkbox
export { CHECKBOX_CHECKED, CHECKBOX_POSITION, CHECKBOX_SHAPE } from './lib/Components/Checkbox/types'
export type { Selected, SelectedStateAction } from './lib/Components/Checkbox/types'
export type { CheckboxGroupProps } from './lib/Components/Checkbox/ui/CheckboxGroup'
export type { CheckboxItemProps } from './lib/Components/Checkbox/ui/CheckboxItem'
export const Checkbox = Object.assign(CheckboxGroup, {
  Item: CheckboxItem,
})

// Chip
export { CHIP_SIZE, CHIP_TYPE } from './lib/Components/Chip/types'
export const Chip = Object.assign(ChipMain, {
  Label: ChipLabel,
  Icon: ChipIcon,
}) as any

// Dropdown
export { DROPDOWN_SIZE, DROPDOWN_STATES } from './lib/Components/Dropdown/types'
export { DropdownButton, DropdownMenu, DropdownMenuItem }
export const Dropdown = Object.assign(DropdownMain, {
  Button: DropdownButton,
  Menu: DropdownMenu,
  MenuItem: DropdownMenuItem,
  Toggle: DropdownToggle,
}) as any

// Input
export { default as DSInput } from './lib/Components/Input/ui/DSInputMain'

// Popup
export const DSModal = Object.assign(DSModalMain, {
  Title: DSModalTitle,
  Content: DSModalContent,
  ButtonContainer: DSModalButtonContainer,
})

// PromoPopup
export const DSPromoPopup = Object.assign(DSPromoPopupMain, {
  Content: DSPromoPopupContent,
  ButtonContainer: DSPromoPopupButtonContainer,
}) as any

// Radio
export { default as DSRadio } from './lib/Components/Radio/DSRadio'
export { DSRADIO_BUTTON_POSITION, DSRADIO_BUTTON_TYPE } from './lib/Components/Radio/dsradio.interface'

// Switch
export { default as Switch } from './lib/Components/Switch/Switch'

// Tab
export { default as Tab } from './lib/Components/Tab/Tab'
export { TAB_TYPE } from './lib/Components/Tab/interface'

// TextArea
export { default as DSTextArea } from './lib/Components/TextArea/ui/DSTextAreaMain'
export { COLORS } from './lib/modules/vars'

// Toast
export { TOAST_CONTAINER_ID, default as useToast } from './lib/Components/Toast/modules/useToast'

// Tooltip
export { TOOLTIP_CLICK_TYPE, TOOLTIP_TYPE } from './lib/Components/Tooltip/types'
export type { ControlTooltipProps, DSTooltipProps, GetClickTypeProps } from './lib/Components/Tooltip/types'
export type { CloseButtonProps } from './lib/Components/Tooltip/ui/DSTooltipCloseButton'
export type { LinkButtonProps } from './lib/Components/Tooltip/ui/DSTooltipLinkButton'
export const DSTooltip = Object.assign(DSTooltipMain, {
  CloseButton: DSTooltipCloseButton,
  LinkButton: DSTooltipLinkButton,
})

// ========================================== pages ============================================

export { default as BottomSheetComponent } from './lib/pages/BottomSheetComponent'
export { default as BuilderButtonComponent } from './lib/pages/BuilderButtonComponent'
export { default as ButtonComponent } from './lib/pages/ButtonComponent'
export { default as CheckboxComponent } from './lib/pages/CheckboxComponent'
export { default as ChipComponent } from './lib/pages/ChipComponent'
export { default as DropdownComponent } from './lib/pages/DropdownComponent'
export { default as Foundation } from './lib/pages/Foundation'
export { default as InputComponent } from './lib/pages/InputComponent'
export { default as PopupComponent } from './lib/pages/PopupComponent'
export { default as RadioComponent } from './lib/pages/RadioComponent'
export { default as ScrollBar } from './lib/pages/ScrollBar'
export { default as SwitchComponent } from './lib/pages/SwitchComponent'
export { default as TabComponent } from './lib/pages/TabComponent'
export { default as TextAreaComponent } from './lib/pages/TextAreaComponents'
export { default as ToastComponent } from './lib/pages/ToastComponent'
export { default as TooltipComponent } from './lib/pages/TooltipComponent'

export * from './lib/design-system'
