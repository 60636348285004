import classNames from 'classnames'
import { uniqueId } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { controlTooltip, getClickType, getIsInitialOpen, getType } from '../modules/dsTooltipHelper'
import { DSTooltipProps } from '../types'
import DSTooltipCloseButton from './DSTooltipCloseButton'

// 만약 스크롤 시에도 툴팁이 고정되어 있다면, 부모 중에 overflow-scroll 이 있는지 확인 후, 같은 레벨에 relative 추가

const DSTooltipMain = ({
  place,
  isOpenOnClick = false,
  hasCloseButton = false,
  isMultiline = false,
  triggerComponent,
  contentComponent,
  linkComponent = null,
  onClose = null,
  ignoreHandling = false,
  style = null,
}: DSTooltipProps) => {
  const tooltipId = uniqueId('tooltip-')
  const tooltipType = getType(isOpenOnClick, hasCloseButton, linkComponent)

  const isInitialOpen = getIsInitialOpen(tooltipType)
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen)

  const tooltipContainerRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLSpanElement>(null)
  const linkRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ignoreHandling) {
      const clickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        const clickType = getClickType({ e, tooltipContainerRef, triggerRef, linkRef })
        controlTooltip({ tooltipType, clickType, setIsOpen })
      }
      window.addEventListener('mouseup', clickHandler)

      const scrollHandler = () => setIsOpen(false)
      if (!hasCloseButton) window.addEventListener('scroll', scrollHandler, true)

      return () => {
        window.removeEventListener('mouseup', clickHandler)
        window.removeEventListener('scroll', scrollHandler, true)
      }
    }
  }, [tooltipType, hasCloseButton, ignoreHandling])

  return (
    <>
      {/* Tooltip trigger component */}
      <span data-tooltip-id={tooltipId} ref={triggerRef} className="cursor-pointer">
        {triggerComponent}
      </span>

      {/* Tooltip component */}
      <div ref={tooltipContainerRef}>
        <ReactTooltip
          id={tooltipId}
          place={place}
          clickable
          className={
            '!Body2-14Md !max-w-none !rounded-[8px] bg-purple-800 !p-[12px] !drop-shadow-[0_2px_8px_rgba(0,0,0,0.2)]'
          }
          isOpen={isOpen}
          style={style}
        >
          <div
            className={classNames(
              !isMultiline && !linkComponent && 'items-center',
              'flex w-full flex-row justify-between gap-[8px]'
            )}
          >
            <div className="flex flex-col gap-[6px]">
              <div className={classNames(isMultiline && 'max-w-[220px] break-words')}>{contentComponent}</div>
              {linkComponent && (
                <div className="w-fit" ref={linkRef}>
                  {linkComponent}
                </div>
              )}
            </div>
            {hasCloseButton && <DSTooltipCloseButton setIsOpen={setIsOpen} onClose={onClose} />}
          </div>
        </ReactTooltip>
      </div>
    </>
  )
}

export default DSTooltipMain
