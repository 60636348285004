// @ts-strict-ignore
import getConfig from 'next/config'

export const getEnv = (envName: string, fallback: string = null): string => {
  if (!envName) return fallback

  const config = getConfig()
  const publicRuntimeConfig = config?.publicRuntimeConfig

  return process?.env?.[envName] ?? publicRuntimeConfig?.[envName] ?? fallback
}
