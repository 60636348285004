import Link from 'next/link'
import urljoin from 'url-join'
import { DivPropType } from '../../components/basic/DivPropsType'
import useTranslation from './useTranslation'

const COOKIE_NAME = 'login_after_url'
const SIGN_UP_MODE_CORRECTION = 'CORRECTION'

interface LinkLocalePropsType extends DivPropType {
  href: any
  as?: any
  noLink?: boolean
  externalLink?: boolean
  className?: string
  children?: React.ReactNode
  dataRef?: any
  mode?: any
}

const LinkLocale = ({ dataRef, href, as, noLink, externalLink, children, mode, ...others }: LinkLocalePropsType) => {
  const { locale } = useTranslation()
  const hrefProp = urljoin('/[lang]', href)
  const asProp = urljoin(`/${locale}`, as || href)
  return (
    <>
      {noLink ? (
        children
      ) : externalLink ? (
        <a target="_blank" href={href} rel="noopener noreferrer" data-ref={dataRef}>
          {children}
        </a>
      ) : (
        <Link legacyBehavior href={externalLink ? href : hrefProp} as={asProp} data-ref={dataRef} {...others}>
          <a {...others} data-ref={dataRef}>
            {children}
          </a>
        </Link>
      )}
    </>
  )
}

export default LinkLocale
