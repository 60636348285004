import classNames from 'classnames'
import { useState } from 'react'
import { DSInput, Switch } from '../../..'
import CodeSnippet from '../../CodeSnippet'
import DSTextArea from '../../Components/TextArea/ui/DSTextAreaMain'
import { ControlClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../../style'

const SectionTextAreaExample = () => {
  const [value, setValue] = useState<string>('')
  const [rows, setRows] = useState<string>('')
  const [placeholder, setPlaceholder] = useState<string>('')
  const [autoFocus, setAutoFocus] = useState<boolean>(false)

  const [maxLength, setMaxLength] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)

  return (
    <div>
      <section className="flex flex-col max-w-max border-[1.5px] rounded-[8px] p-[20px] bg-gray-50">
        <div className={subHeaderClassName}>Controls</div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>
            rows <span className="text-gray-500 font-medium ml-[8px]">(Default value: 2)</span>
          </div>
          <div className="flex max-w-[120px] mr-[12px]">
            <DSInput
              type="number"
              placeholder="(e.g., 50)"
              value={rows}
              setValue={setRows}
              name={'rows'}
              clearable
              size="sm"
              required
              className="bg-gray-50"
            />
          </div>
          <div className={descriptionClassName}>the height of the text area in lines. </div>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>최대 글자 수</div>
          <div className="flex max-w-[120px]">
            <DSInput
              type="number"
              placeholder="(e.g., 50)"
              value={maxLength}
              setValue={setMaxLength}
              name={'maxLength'}
              clearable
              size="sm"
              required
              className="bg-gray-50"
            />
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>placeholder</div>
          <DSInput
            type="text"
            placeholder="Enter a description here"
            value={placeholder}
            setValue={setPlaceholder}
            name={'placeholder'}
            clearable
            size="sm"
            required
            className="bg-gray-50"
          />
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>autoFocus (코드 확인)</div>
          <Switch checked={autoFocus} setChecked={setAutoFocus} />
          {autoFocus && (
            <div className={classNames(descriptionClassName, 'ml-[8px]')}>
              처음 화면 진입 시 input이 focused 되어있음
            </div>
          )}
        </div>

        <div className="flex flex-row items-center">
          <div className={ControlClassName}>disabled</div>
          <Switch checked={disabled} setChecked={setDisabled} />
        </div>
      </section>

      <section className="flex flex-row w-[560px] justify-center my-[40px]">
        <div>
          <DSTextArea
            name="example"
            value={value}
            setValue={setValue}
            rows={Number(rows)}
            placeholder={placeholder}
            autoFocus={autoFocus}
            textCounter={
              Number(maxLength) > 0
                ? {
                    maxLength: Number(maxLength),
                  }
                : undefined
            }
          />
        </div>
      </section>

      <div id="ExampleCode" className={headerClassName}>
        Example Code
      </div>

      <CodeSnippet
        code={`
import { DSTextArea } from "@repo/design-system/index"
import { useState } from "react"
  
const InputComponent = () => {
  const [value, setValue] = useState<string>('')

  return (
    <div>
      <DSTextArea
        name="example"
        value={value}
        setValue={setValue}${
          rows
            ? `
        rows={${Number(rows)}}`
            : ''
        }${
          placeholder
            ? `
        placeholder="${placeholder}"`
            : ''
        }${
          autoFocus
            ? `
        autoFocus`
            : ''
        }${
          Number(maxLength) > 0
            ? `
        textCounter={{ maxLength: ${Number(maxLength)} }}`
            : ''
        }${
          disabled
            ? `
        disabled`
            : ''
        }
      />
    </div>
  )
}
      `}
      />
    </div>
  )
}

export default SectionTextAreaExample
