// @ts-strict-ignore
/*
  워딩파일 주의사항
  1. 워딩파일은 상단의 해시값으로 깃처럼 버전관리를 하고 있습니다.
  2. 그래서 변경사항이 있어도 해시값을 바꾸지 않으면 다음 wdown/wup 때 변경사항이 사라집니다.
  3. 그러니 머지 컨플릭트가 생길 때에는 꼭 해당 브랜치의 해시값을(incoming) 적용해주세요.
*/
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-target-blank */
import { format } from 'date-fns'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import DateComponent from '../../../../components/DateComponent'
import Div from '../../../../components/basic/Div'
import Br from '../../../../components/common/Br'
import { FeatherChevronRight } from '../../../../components/common/Icons'
import Span from '../../../../components/common/Span'
import urls from '../../../../modules/urls'
import { currencyFormat, financial, getMonthName } from '../../../../modules/utils'
import { DOLLARS_TO_KRW } from '../../../../modules/vars'
import LinkLocale from '../../LinkLocale'
export const s_landing_pricing = {
  makeup_lesson_title: [
    `당일수업권`,
    `Last-minute Credits`,
    's_landing_pricing.makeup_lesson_title',
    <>补充课程</>,
    <>補充課程</>,
    <>補充レッスン</>,
    `Buổi học bù`,
  ],
  times: [`회`, ``, 's_landing_pricing.times', `次`, `次`, `回`, ``],
  ask_channeltalk: [
    `1:1 문의하기`,
    `Contact us`,
    's_landing_pricing.ask_channeltalk',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi`,
  ],
  regular_lesson_title: [
    `정규수업권`,
    `Regular Credits`,
    's_landing_pricing.regular_lesson_title',
    `正规课程`,
    `正規課程`,
    `レギュラーレッスン`,
    `Buổi học tiêu chuẩn`,
  ],
  point: [
    `실제 결제한 수업권 금액의 3%를 적립해 드립니다. 적립된 수업 포인트는 결제 및 수강기간 연장에 사용할 수 있습니다. (매 수업 종료 7일 후 자동 적립 예정)`,
    `Additional points 3% of the purchased price can be awarded. Accumulated points can be used on purchasing lessons and extra validity period. (Lesson points are awarded 7 days after taking the lesson)`,
    's_landing_pricing.point',
    `按课程券实际付款金额的3%进行累计积分, 积累的课程积分可用于付款及延长课程期限。(课程积分将于课程结束后7日起自动进行付款)`,
    `額外為您累積實際付款課程券金額的3%，累積的點數可用於付款及延長聽課期間。（課程點數於每次課程結束7天後自動累積。）`,
    `実際に決済したレッスンチケット金額の3%を追加で付与いたします。付与されたレッスンポイントは、決済および受講期間の延長に使用することができます。(レッスンポイントは毎レッスン完了7日後に付与されます)`,
    `3% giá trị thanh toán sẽ được quy đổi thành điểm thưởng. Bạn sẽ nhận được điểm trong vòng 7 ngày. Điểm có thể được sử dụng cho lần mua tiếp theo hoặc gia hạn ngày kết thúc của khoá học.`,
  ],
  bonus_lesson_title: [
    `도전기간 내 완강 시 추가 수업`,
    `Challenge Complete Credits`,
    's_landing_pricing.bonus_lesson_title',
    `挑战时间内完成时额外赠送课程`,
    `挑戰期間內聽完時贈送額外課程`,
    `挑戦期間内に全レッスン終了時、追加レッスン`,
    `Thử thách hoàn thành thẻ học`,
  ],
  actual_payment_unit_price: [
    `실질 회당 단가`,
    `Final Price per Credit`,
    's_landing_pricing.actual_payment_unit_price',
    `实际课程单价`,
    `實際單次單價`,
    `1レッスンあたりの単価`,
    `Giá cuối cho một buổi học`,
  ],
  b2b_bonus_lesson_title: [
    `B2B 특별 추가 수업권`,
    `B2B Bonus Credits`,
    's_landing_pricing.b2b_bonus_lesson_title',
    `B2B特别追加课程`,
    `B2B特別額外課程`,
    `B2B特別追加レッスン`,
    `Thẻ học bổ sung B2B`,
  ],
  time: [`회`, `Credit`, 's_landing_pricing.time', `次`, `次`, `回`, ``],
}
export const s_pricing = {
  free: [
    <>
      언제 어디서나 <br />
      자유롭게 수강하세요
    </>,
    <>Learn whenever and wherever</>,
    's_pricing.free',
    <>
      无论何时何地 <br />
      随心自由的学习吧
    </>,
    <>
      隨時隨地
      <br />
      自由地聽課吧
    </>,
    <>
      いつでもどこでも <br />
      自由に受講しましょう。
    </>,
    <>Học bất cứ khi nào và ở đâu</>,
  ],
  credit: [`수강권`, `Credits`, 's_pricing.credit', `课程券`, `聽課券`, `受講チケット`, `Thẻ học`],
  credit_detail: [
    `수업권 상세`,
    `Credits Detail`,
    's_pricing.credit_detail',
    `课程券详情`,
    `課程券詳情`,
    `チケット料金内訳`,
    `Chi tiết thẻ học`,
  ],
  time: [`운영시간`, `Hours of Operation`, 's_pricing.time', `运营时间`, `營運時間`, `運営時間`, `Giờ làm việc`],
  assure_title: [
    `링글이 보장합니다`,
    `We guarantee Ringle is worth your time`,
    's_pricing.assure_title',
    `Ringle向您承诺`,
    `Ringle向您承諾`,
    `Ringleのお約束`,
    `Chúng tôi đảm bảo Ringle xứng đáng để bạn dành thời gian`,
  ],
  usePoint: [`보유 포인트`, `My points`, 's_pricing.usePoint', `持有积分`, `持有點數`, `保有ポイント`, `Điểm của bạn`],
  useAll: [`전부 사용`, `Use all`, 's_pricing.useAll', `全部使用`, `全部使用`, `すべて利用`, `Sử dụng hết`],
  purchaseMethod: [
    `결제방식 선택`,
    ``,
    's_pricing.purchaseMethod',
    `选择付款方式`,
    `選擇付款方式`,
    `お支払い方法`,
    `Chọn phương thức thanh toán`,
  ],
  domesticPayment: [
    `국내 카드 결제`,
    `Credit card`,
    's_pricing.domesticPayment',
    `韩国国内银行卡付款`,
    `韓國國內信用卡付款`,
    `国内カード決済`,
    `Thẻ tín dụng`,
  ],
  internationalPayment: [
    `해외 카드 결제`,
    `Credit card`,
    's_pricing.internationalPayment',
    `海外信用卡付款`,
    `海外信用卡付款`,
    `海外カード決済`,
    `Thẻ tín dụng`,
  ],
  normalPayment: [
    `카드 일반 결제`,
    `Credit card`,
    's_pricing.normalPayment',
    `银行卡一般付款`,
    `信用卡一般付款`,
    `カード一般決済`,
    `Thẻ tín dụng`,
  ],
  directPayment: [
    `카드 직접 입력`,
    `Credit card (manual)`,
    's_pricing.directPayment',
    `银行卡直接输入`,
    `手動輸入信用卡`,
    `カード直接入力`,
    `Thẻ tín dụng`,
  ],
  kakaoPayment: [`카카오페이`, `KakaoPay`, 's_pricing.kakaoPayment', `KakaoPay`, `KakaoPay`, `KakaoPay`, `KakaoPay`],
  samsungPayment: [
    `삼성페이`,
    `SamsungPay`,
    's_pricing.samsungPayment',
    `SamsungPay`,
    `SamsungPay`,
    `SamsungPay`,
    `SamsungPay`,
  ],
  paycoPayment: [`PAYCO`, `PAYCO`, 's_pricing.paycoPayment', `PAYCO`, `PAYCO`, `PAYCO`, `PAYCO`],
  dollarPayment: [
    `미국 달러로 결제`,
    `Pay in USD`,
    's_pricing.dollarPayment',
    `美金付款`,
    `美元付款`,
    `アメリカドルで決済`,
    `Trả bằng USD`,
  ],
  installmentPayment: [
    `분할 결제`,
    `Pay in installations`,
    's_pricing.installmentPayment',
    `分期付款`,
    `分批付款`,
    `分割払い`,
    `Trả góp`,
  ],
  paymentWithTwoCard: [
    `2개 카드 결제`,
    `Pay with two cards`,
    's_pricing.paymentWithTwoCard',
    `双卡付款`,
    `2張信用卡付款`,
    `2枚のカードで決済`,
    `Trả với hai thẻ`,
  ],
  purchase: [`결제하기`, `Submit order`, 's_pricing.purchase', `付款`, `付款`, `決済する`, `Hoàn thành`],
  agreeValidateMessage: [
    `상품 구매 관련 환불 규정에 동의 후 결제를 진행할 수 있습니다.`,
    `Please check the confirmation box.`,
    's_pricing.agreeValidateMessage',
    `同意商品购买的相关退换规则后方可进行付款。`,
    `同意商品購買相關退款規定後可進行付款。`,
    `商品購入に関する返金規定同意後に決済を行うことができます。`,
    `Vui lòng đánh dấu vào ô xác nhận.`,
  ],
  selectPaymentType: [
    `결제 방식을 선택하지 않으셨습니다. 결제 방식을 선택해주세요`,
    `You haven't selected the purchase method. Please select your purchase method.`,
    's_pricing.selectPaymentType',
    `尚未选择付款方式，请选择您希望的付款方式。`,
    `您尚未選擇付款方式，請選擇付款方式。`,
    `決済方法が選択されていません。決済方法を選択してください。`,
    `Bạn chưa chọn phương thức thanh toán. Vui lòng lựa chọn.`,
  ],
  first_benefit: {
    title: [
      <>
        첫 3회 수업권 구매하고, <br />
        <Div textPrimary clx="text-purple-500">
          포인트로 100% 돌려받으세요
        </Div>
      </>,
      <>
        <Div textPrimary clx="text-purple-500">
          100% Payback Offer
        </Div>{' '}
        for First Purchase Benefit
      </>,
      's_pricing.first_benefit.title',
      <>
        前3次购买课程券时, <br />
        <Div textPrimary clx="text-purple-500">
          可获得100%积分返还
        </Div>
      </>,
      <>
        請購買首3次課程券，
        <br />
        <Div textPrimary clx="text-purple-500">
          領回100%的點數。
        </Div>
      </>,
      <>
        初回3回レッスンチケットを購入して、 <br />
        <Div textPrimary clx="text-purple-500">
          100%ポイントバックを受け取りましょう。
        </Div>
      </>,
      <>
        <Div textPrimary clx="text-purple-500">
          100% Ưu đã hoàn lại
        </Div>{' '}
        cho thanh toán đầu tiên
      </>,
    ],
    new_user_pay: (amount) => [
      <>
        <Div clx="h5 mb-0 text-gray-700">첫 구매가</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">New usesr pay</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      's_pricing.first_benefit.new_user_pay',
      <>
        <Div clx="h5 mb-0 text-gray-700">首次购买价格</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">首次購買價</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">初回購入価格</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div clx="h5 mb-0 text-gray-700">Học viên mới thanh toán</Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
    ],
    point_payback: (amount) => [
      <>첫 구매시 {amount} 포인트 즉시 지급!</>,
      <>${amount} given right away on your first purchase!</>,
      's_pricing.first_benefit.point_payback',
      <>首次购买时{amount}积分立即支付!</>,
      <>首次購買時立即發送${amount}P！</>,
      <>初回購入時 {amount} ポイントを直ちに付与！</>,
      <>${amount} tặng ngay trong lần thanh toán đầu tiên!</>,
    ],
    purchase_now: [
      `구매하기`,
      `Purchase Now`,
      's_pricing.first_benefit.purchase_now',
      `购买`,
      `購買`,
      `購入する`,
      `Thanh toán ngay`,
    ],
  },
  july_promotion: {
    badge: [
      <>얼리버드</>,
      <>Early Bird</>,
      's_pricing.july_promotion.badge',
      <>早鸟</>,
      <>早鳥</>,
      <>アーリーバード</>,
      <>Sáng sớm</>,
    ],
    sign_up_title: [
      <>
        하반기 프로모션
        <br />
        얼리버드
      </>,
      `2nd Half Promotion Early Bird`,
      's_pricing.july_promotion.sign_up_title',
      <>
        下半期促销活动
        <br />
        早鸟
      </>,
      <>
        下半期宣傳活動
        <br />
        早鳥
      </>,
      <>
        下半期プロモーション
        <br />
        アーリーバード
      </>,
      `Ưu đãi sớm nửa cuối năm`,
    ],
    sign_up_subtitle: [
      <>
        최대 <span className="text-blue-500">50만원</span> 추가 할인
      </>,
      <>
        Additional discount up to <span className="text-blue-500">$431.03</span>
      </>,
      's_pricing.july_promotion.sign_up_subtitle',
      <>
        高达<span className="text-blue-500">$431</span>的追加优惠
      </>,
      <>
        最高<span className="text-blue-500">$431.03</span>額外優惠
      </>,
      <>
        最大 <span className="text-blue-500">$431</span> 追加割引
      </>,
      <>
        Giảm giá thêm lên tới <span className="text-blue-500">$431.03</span>
      </>,
    ],
  },
  recommend: [
    `추천 수업권`,
    `Popular`,
    's_pricing.recommend',
    `推荐的课程券`,
    `推薦的課程券`,
    `おすすめレッスンチケット`,
    `Phổ biến`,
  ],
  ringle_guarantee: [
    `링글이 보장합니다`,
    `Ringle guarantees`,
    's_pricing.ringle_guarantee',
    `Ringle向您承诺`,
    `Ringle向您承諾`,
    `Ringleのお約束`,
    `Ringle đảm bảo`,
  ],
  refund_all: [
    <Div textH3 textGray700>
      첫 수업 불만족 시<br />
      <Div textBlack spanTag fontBold textH2>
        100% 환불
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        100% Money-back guarantee
      </Div>
    </Div>,
    's_pricing.refund_all',
    <Div textH3 textGray700>
      首次课程不满意时
      <br />
      <Div textBlack spanTag fontBold textH2>
        承诺100%退款
      </Div>
    </Div>,
    <Div textH3 textGray700>
      首次課程不滿意時，
      <br />
      <Div textBlack spanTag fontBold textH2>
        可全額退款
      </Div>
    </Div>,
    <Div textH3 textGray700>
      全額返金保証
      <br />
      <Div textBlack spanTag fontBold textH2></Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        Hoàn tiền 100%
      </Div>
    </Div>,
  ],
  not_opened: [
    <Div textH3 textGray700>
      첫 수업 시작 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        수강기간 미차감
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        Start Whenever
      </Div>
    </Div>,
    's_pricing.not_opened',
    <Div textH3 textGray700>
      首次课程开始前
      <br />
      <Div textBlack spanTag fontBold textH2>
        课程期限不被扣除
      </Div>
    </Div>,
    <Div textH3 textGray700>
      首次課程開始之前，
      <br />
      <Div textBlack spanTag fontBold textH2>
        不扣除聽課期間
      </Div>
    </Div>,
    <Div textH3 textGray700>
      自分のペースで始められる
      <br />
      <Div textBlack spanTag fontBold textH2></Div>
    </Div>,
    <Div textH5 textGray700>
      <Div textBlack spanTag fontBold textH4>
        Bắt đầu bất cứ khi nào
      </Div>
    </Div>,
  ],
  additional_extend: [
    `구매 1년 후 수강기간 자동 차감`,
    `Buy now and start later`,
    's_pricing.additional_extend',
    `可延长课程期限`,
    `可額外延長聽課期間`,
    `チケット有効期限は延長可能`,
    `Mua ngay và học sau`,
  ],
  change_before_day: [
    <Div textH3 textGray700>
      수업 하루 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        일정 변경 가능
      </Div>
    </Div>,
    <Div textBlack fontBold textH3>
      Flexible cancellation
    </Div>,
    's_pricing.change_before_day',
    <Div textBlack fontBold textH3>
      截止于课程开始前一天可进行变更
    </Div>,
    <Div textBlack fontBold textH3>
      最晚可於課程開始1天前變更日程
    </Div>,
    <Div textBlack fontBold textH3>
      レッスン前日まで予約変更できる
    </Div>,
    <Div textBlack fontBold textH3>
      Huỷ linh hoạt
    </Div>,
  ],
  provide_makeup_lesson: [
    `수업 시작 기준 2시간 이전까지 변경 시, 당일수업권 제공`,
    <>
      If you cancel 24 to 2 hours before the start of
      <br />
      the lesson, a Last-minute Credit will be issued.
    </>,
    's_pricing.provide_makeup_lesson',
    `截止于课程开始前两小时变更时, 将提供补充课程券`,
    `最晚於課程開始2小時前變更時間時，將提供補充課程券`,
    `開始2時間前までの変更は補充レッスンチケットに交換`,
    `Huỷ tối thiểu 24 giờ trước buổi học`,
  ],
  credit_info: [
    `수업권 선택 방법`,
    `Credits Detail`,
    's_pricing.credit_info',
    `课程券选择方法`,
    `課程券選擇方法`,
    `レッスンチケットの種類`,
    `Chi tiết thẻ học`,
  ],
  duration: [
    `수업 길이`,
    `Lesson duration`,
    's_pricing.duration',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng buổi học`,
  ],
  full_desc: [
    <Div ml16 textGray700 textH5>
      하나의 주제에 대해 심도 있게!
      <br />
      최신 시사부터 비즈니스 영어까지
      <br />
      지적 대화를 레벨업하는 40분 수업
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for advanced discussions
      <br />
    </Div>,
    's_pricing.full_desc',
    <Div ml16 textGray700 textH5>
      对一个主题进行深度探索!
      <br />
      从最新时事到商务英语
      <br />
      知性对话提升实力的40分钟课程
    </Div>,
    <Div ml16 textGray700 textH5>
      深度討論一個主題！
      <br />
      從最新時事至商務英語，
      <br />
      提升知識性對話的40分鐘課程
    </Div>,
    <Div ml16 textGray700 textH5>
      一つのテーマについて深く！
      <br />
      最新時事からビジネス英語まで
      <br />
      高レベルな会話を学びたい方向け
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Buổi thảo luận nâng cao
      <br />
    </Div>,
  ],
  half_desc: [
    <Div ml16 textGray700 textH5>
      바쁜 일상 속, 짧지만 확실하게!
      <br />
      부담스러운 예습 시간은 줄이고
      <br />
      일상 대화 능력을 향상시키고 싶으신 분
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for casual conversations
    </Div>,
    's_pricing.half_desc',
    <Div ml16 textGray700 textH5>
      在忙碌的日常中简短却充实的对话!
      <br />
      缩短让人感到负担的预习时间
      <br />
      希望提升日常对话能力的人群
    </Div>,
    <Div ml16 textGray700 textH5>
      在忙碌的日常中，簡單卻確實！
      <br />
      想縮短感到負擔的預習時間，
      <br />
      提升日常對話能力的人
    </Div>,
    <Div ml16 textGray700 textH5>
      日常会話のレベルアップに！
      <br />
      なるべく短い予習時間で
      <br />
      会話力を上達したい方向け
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Buổi hội thoại thông thường
    </Div>,
  ],
  time_info: [`시간대`, `Times`, 's_pricing.time_info', `时间段`, `時段`, `レッスン受講可能時間帯`, `Thời gian`],
  base: [
    `수업 시작 기준`,
    `Lesson starting time`,
    's_pricing.base',
    `以课程开始为准`,
    `以課程開始時間為準（韓國標準時間）`,
    `レッスン開始時間`,
    `Thời gian bắt đầu học`,
  ],
  all_time: [`정규`, `Regular`, 's_pricing.all_time', `正规`, `正規`, `レギュラー`, `Tiêu chuẩn`],
  early: [`새벽반`, `Early Bird`, 's_pricing.early', `凌晨班`, `早鳥班`, `早朝プラン`, `Sáng sớm`],
  lunch: [`점심반`, `Matinee`, 's_pricing.lunch', `午间班`, `午間班`, `デイタイムプラン`, `Buổi trưa`],
  night_owl: [`심야반`, `Night Owl`, 's_pricing.night_owl', `深夜班`, `深夜班`, `深夜プラン`, `Ban đêm`],
  for_purpose: [
    `수강 목적에 꼭 맞는`,
    `Select the right package for you`,
    's_pricing.for_purpose',
    `请妥善选择符合课程目的的`,
    `請選擇確實符合聽課目的的`,
    `受講する`,
    `Chọn gói học phù hợp với bạn`,
  ],
  choose_your_credit: [
    <>&nbsp;수업권을 선택해보세요</>,
    ``,
    's_pricing.choose_your_credit',
    `课程券`,
    `課程券`,
    `チケットを選択してください。`,
    ``,
  ],
  pay_in_pieces: [
    `2개 카드 분할 결제 가능`,
    `Split payment available`,
    's_pricing.pay_in_pieces',
    `可以使用两张信用卡结帐`,
    `可以使用兩張信用卡結帳`,
    `2つのカード分割決済が可能`,
    `Đóng học phí từng phần`,
  ],
  interest_free: [
    `최대 7개월 무이자 할부`,
    `Interest-free for up to 7 monthly installments`,
    's_pricing.interest_free',
    `最高可分7期无利率`,
    `最高可分7期無利率`,
    `最大7ヶ月金利0%の分割払い`,
    `Trả góp không lãi suất lên đến 7 tháng`,
  ],
  certificates: [
    `증명서 발급`,
    `Lesson Certificates & Receipts`,
    's_pricing.certificates',
    `发放证明`,
    `核發證明`,
    `証明書発行`,
    `Chứng nhận buổi học & Biên nhận`,
  ],
  cert_example: [
    `증명서 예시 상세보기`,
    `See sample`,
    's_pricing.cert_example',
    `证明材料范本详细查看`,
    `詳細確認證明範例`,
    `証明書のサンプル`,
    `Xem mẫu`,
  ],
  can_provide_cert: [
    <>
      구매한 수업권 증명서 상시 발급 가능합니다.
      <br />
      수강증, 출석증, 영수증 (국/영문)
    </>,
    `You can issue lesson certificates, proof of attendance, or receipts.`,
    's_pricing.can_provide_cert',
    <>
      购买的课程券证明可随时发送。
      <br />
      听课证, 出席证, 收据 (中/英文)
    </>,
    <>
      購買的課程券可隨時核發證明。
      <br />
      上課證明、出席證明、收據（英文）
    </>,
    <>
      受講したレッスンの証明書類はいつでも発行可能です。
      <br />
      受講証、出席証、領収証 (日本語/英語)
    </>,
    `Bạn có thể lấy chứng nhận buổi học, chứng nhận tham gia hoặc biên nhận.`,
  ],
  benefit: [`수강 혜택`, `Benefits`, 's_pricing.benefit', `课程优惠`, `聽課優惠`, `受講特典`, `Ưu đãi`],
  benefit_detail: [
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+종의 링글&nbsp;
          <Div fontSemibold textBlack>
            오리지널 콘텐츠
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF 파일 / MP3 오디오북&nbsp;
          </Div>
          다운로드 지원
        </Div>
        <Div flex mb4>
          매주 진행되는&nbsp;
          <Div fontSemibold textBlack>
            튜터의 Live Class
          </Div>
        </Div>
        <Div flex>
          커리어, 영어학습 등&nbsp;
          <Div fontSemibold textBlack>
            380+편 VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          수업 24시간 전까지&nbsp;
          <Div fontSemibold textBlack>
            무제한 일정 변경&nbsp;
          </Div>
          가능
        </Div>
        <Div flex mb4>
          2시간 전 수업 취소 시,&nbsp;
          <Div fontSemibold textBlack>
            100% 보충 수업&nbsp;
          </Div>
          제공
        </Div>
        <Div flex mb4>
          모든&nbsp;
          <Div fontSemibold textBlack>
            수업 녹음파일 무제한&nbsp;
          </Div>
          듣기
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            개별 첨삭 수업노트&nbsp;
          </Div>
          (Ringle docs) 별도 제공
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            튜터의 1:1 피드백 리포트&nbsp;
          </Div>
          매 수업 종료 후 제공
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI 기반 대화 스크립트와 말하기 패턴 분석 자료
          </Div>
        </Div>
        <Div flex>
          말하기, 듣기, 읽기, 쓰기&nbsp;
          <Div fontSemibold textBlack>
            영역별 학습 데이터
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+ Ringle's&nbsp;
          <Div fontSemibold textBlack>
            Original Content
          </Div>
        </Div>
        <Div flex mb4>
          Download&nbsp;
          <Div fontSemibold textBlack>
            PDF/MP3 Audiobook&
          </Div>
        </Div>
        <Div flex mb4>
          Weekly top tutor&nbsp;
          <Div fontSemibold textBlack>
            LIVE Webinar
          </Div>
        </Div>
        <Div flex>
          380+&nbsp;
          <Div fontSemibold textBlack>
            Video Courses
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Flexible cancellations&nbsp;
          </Div>
          up to 24hrs before the lesson
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Last-minute lessons&nbsp;
          </Div>
          for late cancellations
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Audio replay & transcript&nbsp;
          </Div>
          for all lessons
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Lesson Notes&nbsp;
          </Div>
          after class
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Tutor's Feedback&nbsp;
          </Div>
          report
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI Analysis&nbsp;
          </Div>
          on speech patterns
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Activities log&nbsp;
          </Div>
          on your Ringling
        </Div>
      </Div>
    </Div>,
    's_pricing.benefit_detail',
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000多种的Ringle&nbsp;
          <Div fontSemibold textBlack>
            原版内容
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF文件 / MP3音频&nbsp;
          </Div>
          可随时下载
        </Div>
        <Div flex mb4>
          每周进行的&nbsp;
          <Div fontSemibold textBlack>
            教师Live Class
          </Div>
        </Div>
        <Div flex>
          职业, 英语学习等&nbsp;
          <Div fontSemibold textBlack>
            380多部VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          截止于课程开始前24小时&nbsp;
          <Div fontSemibold textBlack>
            可无限变更课程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          截止于2小时前取消课程时, &nbsp;
          <Div fontSemibold textBlack>
            100%提供补充课程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          全部&nbsp;
          <Div fontSemibold textBlack>
            课程录音无限量&nbsp;
          </Div>
          收听
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            各课程修改笔记&nbsp;
          </Div>
          (Ringle docs)分别提供
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            教师的1:1Feedback Report&nbsp;
          </Div>
          于课程结束后提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI基础对话脚本及口语模式分析资料
          </Div>
        </Div>
        <Div flex>
          口语, 听力, 阅读, 写作&nbsp;
          <Div fontSemibold textBlack>
            各方面的学习数据
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000多種Ringle的&nbsp;
          <Div fontSemibold textBlack>
            原創內容
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF檔／MP3有聲書&nbsp;
          </Div>
          下載支援
        </Div>
        <Div flex mb4>
          每週進行的&nbsp;
          <Div fontSemibold textBlack>
            任課教師Live Class
          </Div>
        </Div>
        <Div flex>
          職涯、英語學習等&nbsp;
          <Div fontSemibold textBlack>
            380多集VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          最晚可於課程開始24小時前&nbsp;
          <Div fontSemibold textBlack>
            無限變更日程&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          2小時前取消課程時，&nbsp;
          <Div fontSemibold textBlack>
            100%提供補課&nbsp;
          </Div>
        </Div>
        <Div flex mb4>
          所有&nbsp;
          <Div fontSemibold textBlack>
            課程錄音檔無限&nbsp;
          </Div>
          聆聽
        </Div>
        <Div flex>
          額外提供
          <Div fontSemibold textBlack>
            個別增刪課堂筆記&nbsp;
          </Div>
          （Ringle docs）
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            任課教師的1:1意見回饋報告書&nbsp;
          </Div>
          於每堂課結束後提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            結合AI技術的對話腳本及口說模式分析資料
          </Div>
        </Div>
        <Div flex>
          口說、聽力、閱讀、寫作&nbsp;
          <Div fontSemibold textBlack>
            各領域學習數據
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+種類のRingle&nbsp;
          <Div fontSemibold textBlack>
            オリジナルコンテンツ
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDFファイル / MP3オーディオブ&nbsp;
          </Div>
          ック
        </Div>
        <Div flex mb4>
          毎週行われる&nbsp;
          <Div fontSemibold textBlack>
            チューターのLive Class
          </Div>
        </Div>
        <Div flex>
          キャリアや英語学習など&nbsp;
          <Div fontSemibold textBlack>
            380+本のVOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          レッスン24時間前まで&nbsp;
          <Div fontSemibold textBlack>
            無制限で日程変更&nbsp;
          </Div>
          可能
        </Div>
        <Div flex mb4>
          2時間前にキャンセル時、&nbsp;
          <Div fontSemibold textBlack>
            補充レッスン&nbsp;
          </Div>
          提供
        </Div>
        <Div flex mb4>
          すべての&nbsp;
          <Div fontSemibold textBlack>
            レッスン録音ファイルを無制限で&nbsp;
          </Div>
          聴く
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            個別添削レッスンノート&nbsp;
          </Div>
          (Ringle docs) 別途提供
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Feedback Report&nbsp;
          </Div>
          レッスン終了後に提供
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            会話スクリプトとスピーキング分析資料
          </Div>
        </Div>
        <Div flex>
          スピーキング、リスニングなどの&nbsp;
          <Div fontSemibold textBlack>
            学習データ
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+&nbsp;
          <Div fontSemibold textBlack>
            Nội dung độc quyền trên Ringle
          </Div>
        </Div>
        <Div flex mb4>
          Tải&nbsp;
          <Div fontSemibold textBlack>
            PDF/sách nói MP3
          </Div>
        </Div>
        <Div flex mb4>
          Top gia sư mỗi tuần&nbsp;
          <Div fontSemibold textBlack>
            Webinar trực tuyến
          </Div>
        </Div>
        <Div flex>
          380+&nbsp;
          <Div fontSemibold textBlack>
            Video buổi học
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Huỷ linh hoạt&nbsp;
          </Div>
          tối thiểu 24 giờ trước buổi học
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Buổi học bù&nbsp;
          </Div>
          khi huỷ sát giờ
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Ghi âm & Bản ghi&nbsp;
          </Div>
          cho tất cả buổi học
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Ghi chú buổi học&nbsp;
          </Div>
          khi kết thúc
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Báo cáo đánh giá&nbsp;
          </Div>
          của gia sư
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Phân tích Ai&nbsp;
          </Div>
          dựa trên đặc điểm nói
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Nhật ký hoạt động&nbsp;
          </Div>
          trên trang của bạn
        </Div>
      </Div>
    </Div>,
  ],
  notice: [`유의사항`, `Terms`, 's_pricing.notice', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
  use_credit: [
    `수업권 사용 안내`,
    `Credits policy`,
    's_pricing.use_credit',
    `课程券使用介绍`,
    `課程券使用說明`,
    `レッスンチケットの使用方法`,
    `Chính sách thẻ học`,
  ],
  free_to_use: [
    `수업권은 구매한 횟수만큼, 수강기간 내 자유롭게 이용 가능합니다.`,
    `You can use your lesson credits before they expire.`,
    's_pricing.free_to_use',
    `课程券按购买次数, 可于课程期限内自由使用。`,
    `如同購買的次數，聽課期間內可自由使用課程券。`,
    `購入したレッスンチケットは有効期限内に自由に使うことができます。`,
    `Bạn hãy học hết các buổi học trước khi hết hạn.`,
  ],
  free_to_use_ex: [
    `(예: 수강기간 내, 하루 3회 연속 수업 신청도 가능합니다.)`,
    ``,
    's_pricing.free_to_use_ex',
    `(例如: 课程期限内一天可连续申请3次课程。)`,
    `（例：可於聽課期間內一天申請3次課程。）`,
    `(例: 有効期限内であれば、1日3回連続レッスンを予約することも可能です。)`,
    ``,
  ],
  twenty_four_seven: [
    `수업 시간은 연중무휴로 한국 시간 기준 새벽 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
    `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
    's_pricing.twenty_four_seven',
    `课程时间全年无休,  参照韩国时间凌晨5点 ~ 下午2点, 晚上7点 ~ 凌晨3点。`,
    `課程時間為全年無休，可於韓國時間凌晨5點～下午2點、晚上7點～凌晨3點上課。`,
    `レッスンは年中無休、日本時間午前5時～午後2時、午後7時～翌午前3時まで受講可能です。`,
    `Buổi học có sẵn lúc 05:00 - 14:00 / 19:00 - 03:00 (giờ Hàn quốc)  mỗi ngày.`,
  ],
  start_after_first_lesson: [
    `구매 후, 첫 수업 완료 전까지 수강기간은 시작되지 않습니다.`,
    `Expiration dates are calculated from the date you complete your first lesson using the purchased package.`,
    's_pricing.start_after_first_lesson',
    `购买后, 到首次课程完成为止, 不会开始计算课程期限。`,
    `購買後，完成首次課程前，不會開始計算聽課期間。`,
    `有効期限は、購入したチケットパックを使って予約した初回レッスン日から起算されます。`,
    `Ngày hết hạn chỉ bắt đầu tính từ ngày bạn hoàn thành buổi học đã thanh toán đầu tiên.`,
  ],
  start_after_first_lesson_ex: [
    `(예: 구매 후 3개월이 지난 후 첫 수업을 완료하면, 해당 시점부터 수강기간이 시작됩니다.)`,
    ``,
    's_pricing.start_after_first_lesson_ex',
    `(例如: 购买后3个月完成首次课程时, 将从该时间起计算课程期限。)`,
    `（例：購買後經過3個月，於完成首次課程時開始計算聽課期間。）`,
    `(例: チケットパックを購入してから3か月後にレッスンを受講した場合、その初回レッスン日から有効期限が起算されます。)`,
    ``,
  ],
  extend_by_payment: [
    `수강기간은 포인트 사용/소액 결제를 통해 연장할 수 있습니다.`,
    `You can extend credit expiration dates with your points or by paying a small fee.`,
    's_pricing.extend_by_payment',
    `课程期限可通过使用积分/小额付款的方式进行延长。`,
    `聽課期間可透過使用點數／小額付款延長。`,
    `有効期限はポイント又は少額手数料で延長することができます。`,
    `Bạn có thể gia hạn bằng cách sử dụng điểm hoặc trả một khoản phí nhỏ.`,
  ],
  refund_policy: [
    `수업권 환불 정책`,
    `Refund policy`,
    's_pricing.refund_policy',
    `课程券退款政策`,
    `課程券退款政策`,
    `レッスンチケット返金ポリシー`,
    `Chính sách hoàn tiền`,
  ],
  refund_all_before_open: [
    `구매 후 사용하지 않은 수업권은 전액 환불 가능합니다.`,
    `Unused lesson credits are eligible for refunds.`,
    's_pricing.refund_all_before_open',
    `购买后, 未使用的课程券将享受全额退款。`,
    `購買後未使用的課程券可全額退款。`,
    `未使用のレッスンチケットは全額返金可能です。`,
    `Các Thẻ học chưa sử dụng có thể được hoàn lại.`,
  ],
  refund_after_trial: [
    `체험 수업 포함 첫 수업 이후, 불만족 시 전액 환불 가능합니다.`,
    `We offer 100% money-back guarantee for your first lesson.`,
    's_pricing.refund_after_trial',
    `包含体验课程在内的首次课程结束后,  不满意可全额进行退款。`,
    `包含體驗課程的首次課程開始後，不滿意時可全額退款。`,
    `体験レッスン含む初回レッスンをご利用いただき、ご満足いただけなかった場合は全額返金いたします。`,
    `Cam kết hoàn tiền 100% với buổi học đầu tiên.`,
  ],
  check_policy: [
    `수강 이력이 있는 수업권은, 환불 시 이용약관(13조, 15조)에 의거하여 처리합니다.`,
    `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
    's_pricing.check_policy',
    `已有课程使用记录的课程券, 退款时将按照使用条款中的(13条, 15条)进行处理!`,
    `曾使用過的課程券將依據使用條款第13條、第15條進行退款。`,
    `受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づいて処理されます。`,
    `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
  ],
  bonus: (bonus) => [
    `+${bonus}회 수업`,
    `+${bonus} Lessons`,
    's_pricing.bonus',
    `+${bonus}次课程`,
    `+${bonus}次課程`,
    `+${bonus}回レッスン`,
    `+${bonus} buổi học`,
  ],
  if_finish_all: (date, credit) => [
    `${date}일 내 ${credit}회 완강 시`,
    `After ${credit} lessons in ${date} days`,
    's_pricing.if_finish_all',
    `${date}日内完成${credit}次课程时`,
    `${date}天內聽完${credit}次時`,
    `${date}日以内に全 ${credit}レッスン終了時`,
    `Sau ${credit} buổi học trong ${date} ngày`,
  ],
  free_subsidy: (free) => [
    `+${free}회 당일수업권`,
    `+${free} Last-minute Credits`,
    's_pricing.free_subsidy',
    `+${free}次补充课程`,
    `+${free}次補充課程`,
    `+${free}回分の補充レッスン`,
    `+${free} Buổi học bù`,
  ],
  count: (credit) => [
    `${credit}회 수업권`,
    `${credit} Credits`,
    's_pricing.count',
    `${credit}次`,
    `${credit}次`,
    `${credit}回`,
    `${credit} Buổi học`,
  ],
  min: (duration) => [
    `${duration}분`,
    `${duration} min`,
    's_pricing.min',
    `${duration}分钟`,
    `${duration}分鐘`,
    `${duration}分`,
    `${duration} phút`,
  ],
  day: (day) => [`${day}일`, `${day} days`, 's_pricing.day', `${day}日`, `${day}天`, `${day}日`, `${day} ngày`],
  by_one: [`/ 회`, `/ Credit`, 's_pricing.by_one', `/ 次`, `／次`, `/ 回`, `/ Buổi học`],
  normal_price: [`정가`, `Regular price`, 's_pricing.normal_price', `定价`, `定價`, `通常価格`, `Học phí tiêu chuẩn`],
  expected_point: [
    `예상 적립 포인트`,
    `Estimated points`,
    's_pricing.expected_point',
    `预计积累积分`,
    `預計累積點數`,
    `獲得予定ポイント`,
    `Điểm ước tính`,
  ],
  without_trial: [
    `체험없이 구매`,
    `Skip trial`,
    's_pricing.without_trial',
    `无体验直接购买`,
    `未先體驗即購買`,
    `体験なしで購入`,
    `Không học thử`,
  ],
  additional_lessons_and_discount: (count, discount) => [
    `${count ? `${count}회 수업 추가` : ''}${discount ? ` + ${discount}만원 할인` : ''}`,
    `${count ? `${count} Free lesson(s)` : ''}${discount ? ` + ${discount} Off` : ''}`,
    's_pricing.additional_lessons_and_discount',
    `${count ? `${count}次追加课程` : ''}${discount ? ` + ${discount}优惠` : ''}`,
    `${count ? `${count}次額外課程` : ''}${discount ? ` + ${discount}折扣` : ''}`,
    `${count ? `${count}回レッスン追加` : ''}${discount ? ` + ${discount}割引` : ''}`,
    `${count ? `${count} Buổi học miễn phí(s)` : ''}${discount ? ` + ${discount} Off` : ''}`,
  ],
  buy: [`구매하기`, `Purchase`, 's_pricing.buy', `购买`, `購買`, `購入する`, `Thanh toán`],
  including_trial: [
    `체험 수업도 첫 수업에 포함됨`,
    `Includes trial lesson.`,
    's_pricing.including_trial',
    `体验课程也包含在首次课程中`,
    `首次課程也包含體驗課程`,
    `初回レッスン、体験レッスンが対象`,
    `Bao gồm buổi học thử`,
  ],
  time_limit: [
    `시간한정`,
    `Limited Window`,
    's_pricing.time_limit',
    `时间限定`,
    `時間限定`,
    `時間帯限定`,
    `Giờ đặc biệt`,
  ],
  final_price: [
    `프로모션 특가`,
    `Promotional Deal`,
    's_pricing.final_price',
    `活动特价`,
    `活動特價`,
    `キャンペーン特価`,
    `Giá đặc biệt`,
  ],
  final_credit: [
    `최종 수업권 횟수`,
    `Total lessons`,
    's_pricing.final_credit',
    `最终课程券次数`,
    `最終課程券次數`,
    `合計レッスン回数`,
    `Tổng số buổi học`,
  ],
  additional_lesson: [
    `추가 정규수업`,
    `Bonus lessons`,
    's_pricing.additional_lesson',
    `追加正规课程`,
    `額外正規課程`,
    `追加レギュラーレッスン`,
    `Buổi học tặng thêm`,
  ],
  additional_benefit: (duration, name) => [
    `[${duration}분] ${name}회 수업권의 추가 혜택입니다.`,
    `Benefits for [${duration} min] ${name} Lessons Package`,
    's_pricing.additional_benefit',
    `[${duration}分钟] ${name}次, 课程券追加优惠。`,
    `[${duration}分鐘] ${name}次課程券的額外優惠。`,
    `[${duration}分] ${name}回レッスンチケットの追加特典です。`,
    `Ưu đãi cho [${duration} phút] ${name} gói học`,
  ],
  provide_additional_lesson: (challenge_date, count, bonus, day) => [
    <>
      {challenge_date > 0 ? `${challenge_date}일` : `수강기간 `} 이내 {count}회 수업을 모두 사용하면 추가 {bonus}회
      정규수업권이 제공됩니다. [정상 수강기간: {day}일]
    </>,
    <>
      If you complete {count} lessons in {challenge_date > 0 ? `${challenge_date} days` : `credits expire in`}, you'll
      get {bonus} bonus lessons. [Credits expire in {day} days]`
    </>,
    's_pricing.provide_additional_lesson',
    <>
      {challenge_date > 0 ? `${challenge_date}日` : `课程期限`} 以内 {count}次课程全部使用时, 追加提供{bonus}
      次正规课程券。 [正规课程期限: {day}日]
    </>,
    <>
      於{challenge_date > 0 ? `${challenge_date}天` : `聽課期間`}內將{count}次課程全數使用時，額外提供{bonus}
      次正規課程券。[正規聽課期間：{day}日]
    </>,
    <>
      {challenge_date > 0 ? `${challenge_date}日` : `受講期間 `} 内に {count}回レッスンをすべて使用すると追加で {bonus}
      回レギュラーレッスンチケットが提供されます。 [通常受講期間: {day}日]
    </>,
    <>
      Nếu bạn hoàn thành {count} buổi học trong {challenge_date > 0 ? `${challenge_date} days` : `credits expire in`},
      bạn sẽ nhận {bonus} buổi học tặng thêm. [Thẻ học hết hạn trong {day} ngày]`
    </>,
  ],
  about_additional_lesson: [
    `정규수업권을 모두 사용한 시점에 수업권이 제공되며, 추가 수업권의 수강기간은 1년입니다.`,
    `Issued bonus lessons are valid for 1 year.`,
    's_pricing.about_additional_lesson',
    `正规课程全部使用时将提供额外课程券, 追加课程券的课程期限为1年。`,
    `將所有正規課程全數使用時提供課程券，額外課程券的聽課期間為1年。`,
    `レギュラーレッスンがすべて終了した時点でレッスンチケットが提供され、追加レッスンチケットの受講期間は1年です。`,
    `Buổi học tặng thêm có thời hạn trong 1 năm.`,
  ],
  considered_to_be_used: [
    `* 수강생의 수업 불참/2시간 이내 취소도 수업권 [사용]으로 인정됩니다.`,
    `*Student no-show and cancellations in less than 2 hours also count as completing a lesson.`,
    's_pricing.considered_to_be_used',
    `* 学员旷课/课程开始前2小时以内对课程进行取消时, 将被视为[使用]课程券。`,
    `* 學生未出現／2小時以內取消也視同[使用]課程券。`,
    `* 受講生のレッスン欠席/2時間以内のキャンセルもレッスンチケット[使用]と認定されます。`,
    `*Học viên vắng mặt hoặc huỷ trong vòng 2 giờ sẽ được tính là hoàn thành buổi học.`,
  ],
  for_long: [
    `장기 수업권 혜택`,
    `Size Benefits`,
    's_pricing.for_long',
    `长期课程券优惠`,
    `長期課程券優惠`,
    `長期レッスンチケット特典`,
    `Giảm giá`,
  ],
  long_benefit_list_1: [
    <>당일수업권</>,
    `Last-minute Credits`,
    's_pricing.long_benefit_list_1',
    <>补充课程</>,
    <>補充課程</>,
    <>補充レッスン</>,
    `Buổi học bù`,
  ],
  about_lesson_in_a_day: [
    <>
      매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능) 당일수업권은 예약 시점
      기준 24시간 이내에 시작하는 당일수업만 예약할 수 있는 수업권입니다.
    </>,
    `For every four completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
    's_pricing.about_lesson_in_a_day',
    <>
      每使用4张正規课程卷，提供1张当日课程卷（使用期限1年）。
      <br />- 当日课程卷：可预约24小时以内开始的课程
    </>,
    <>
      目前於24小時以內開始的課程，只可選擇Free Talking教材。耗盡4次正規課程時，將發送1次。（聽課期間：自發送日起1年）
    </>,
    <>
      リアルタイムで24時間以内に受講できるレッスンです。レギュラーレッスンを4回使用すると当日のレッスン1回分のチケットが発行されます。(有効期限:
      発行から1年間)
    </>,
    <>
      Sau khi hoàn thành 4 tiết học bạn sẽ nhận được một Tín chỉ cuối giờ. (Thời hạn 1 năm) Bạn có thể sử dụng Tín chỉ
      cuối giờ cho các lớp học bắt đầu trong vòng 24 giờ kể từ thời điểm đặt lớp.
    </>,
  ],
  long_benefit_list_2: [
    `튜터 우선 예약`,
    `Early Booking Access`,
    's_pricing.long_benefit_list_2',
    `教师优先选定`,
    `優先指定任課教師`,
    `チューター優先予約`,
    `Gia sư ưu tiên`,
  ],
  schedule_first_benefit: [
    `튜터의 1주일 뒤 스케쥴을 24시간 동안 우선 예약할 수 있는 권한을 드립니다.`,
    `You'll have 24-hour priority access to your favorite tutor's schedule on a weekly basis.`,
    's_pricing.schedule_first_benefit',
    `您可于24小时内优先预约您满意的教师一周后的日程。(可通过Ringle应用程序提醒进行确认)`,
    `24小時期間提供您可優先預約喜歡的任課教師一週後日程的權限。（可透過Ringle APP通知確認）`,
    `通常より24時間早く好きなチューターの翌週のスケジュールにアクセス・予約ができます。(Ringleアプリ内で通知あり)`,
    `Bạn được ưu tiên truy cập thời khoá biểu hằng tuần của gia sư yêu thích trong vòng 24 giờ.`,
  ],
  long_benefit_list_3: [
    `80단어 답변 첨삭`,
    `Response proofreading`,
    's_pricing.long_benefit_list_3',
    `80单词回答修改`,
    `80個單字的增刪回覆`,
    `回答添削(80単語まで)`,
    `Hiệu đính câu trả lời`,
  ],
  editing_service: [
    <>
      튜터가 매 수업 80단어를 수업 전 첨삭해 드립니다. 수업 24시간 전까지 예습 페이지에서 신청 가능합니다. [예습] {'>'}{' '}
      [답변] {'>'} [첨삭 신청]
    </>,
    `Have your tutor proofread your response up to 80 words. You can submit requests up to 24 hours before the lesson at Lesson Prep.`,
    's_pricing.editing_service',
    <>
      教师可提供每次课程前80个单词的内容修改。 截止于课程开始前24小时可在预习页面中进行申请。[预习] {'>'} [回答] {'>'}{' '}
      [申请修改]
    </>,
    <>
      任課教師於每堂課於課程開始前為您增刪80個單字。 最晚可於課程開始24小時前於預習頁面申請。[預習] {'>'} [回覆] {'>'}{' '}
      [申請增刪]
    </>,
    <>
      チューターが毎レッスン80単語まで添削します。 添削リクエストはレッスン24時間前まで予習ページからできます。[予習]{' '}
      {'>'} [回答] {'>'} [添削リクエスト]
    </>,
    `Gia sư của bạn đã hiệu đính câu trả lời dưới 80 từ của bạn chưa? Bạn có thể gửi yêu cầu tối thiểu 24 giờ trước khi buổi học bắt đầu ở phần Chuẩn bị buổi học.`,
  ],
  input_company_code: [
    `기업 맞춤 수업권을 확인해 보세요.`,
    `Please enter B2B Partner code.`,
    's_pricing.input_company_code',
    `请输入企业特定代码`,
    `請輸入企業專用代碼。`,
    `企業専用コードを入力してください。`,
    `Vui lòng nhập mã Đối tác B2B.`,
  ],
  company_code: [
    `기업 맞춤 코드를 입력해 주세요.`,
    `B2B Partner Code`,
    's_pricing.company_code',
    `企业特定代码`,
    `企業專用代碼`,
    `企業専用コード`,
    `Mã code đối tác B2B`,
  ],
  channel_talk: [
    `할인코드 1:1 문의하기`,
    `Contact Us`,
    's_pricing.channel_talk',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi`,
  ],
  additional_discount: [
    `추가할인`,
    `Bonus Discount`,
    's_pricing.additional_discount',
    `追加优惠`,
    `額外折扣`,
    `追加割引`,
    `Ưu đãi bổ sung`,
  ],
  recommended_product: [
    `추천 수업권`,
    `Popular`,
    's_pricing.recommended_product',
    `推荐的课程券`,
    `推薦的課程券`,
    `Popular`,
    `Phổ biến`,
  ],
  applied_price: [
    `첫구매 혜택이 반영된 가격입니다.`,
    `First purchase benefits applied`,
    's_pricing.applied_price',
    `适用第一次购买优惠`,
    `適用第一次購買優惠`,
    `初購入の特典が反映された価格です。`,
    `Lợi ích mua hàng đầu tiên được áp dụng`,
  ],
  normal_customer: [
    `일반 수업권`,
    `Individuals`,
    's_pricing.normal_customer',
    `一般课程券`,
    `一般課程券`,
    `一般レッスンチケット`,
    `Cá nhân`,
  ],
  b2b_price: [
    `기업 맞춤 수업권`,
    `B2B`,
    's_pricing.b2b_price',
    `企业特定课程券`,
    `企業專用課程券`,
    `法人向けレッスンチケット`,
    `B2B`,
  ],
  alltime_time: [
    `수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
    `Lesson time: 5AM - 2PM / 7PM - 3AM (KST)`,
    's_pricing.alltime_time',
    `课程时间: 凌晨5点~下午2点 / 晚上19点~凌晨3点(KST)`,
    `課程時間: 凌晨5點～下午2點／晚上19點～凌晨3點`,
    `時間: 5:00 ~ 14:00 / 19:00 ~ 翌3時 (KST)`,
    `Buổi học: 05:00 - 14:00 / 19:00 - 03:00 (Giờ Hàn quốc)`,
  ],
  early_time: [
    `오전 5시~ 7시(KST)`,
    `5AM - 7AM (KST)`,
    's_pricing.early_time',
    `凌晨5点~7点(KST)`,
    `上午5點～7點`,
    `5:00~7:00 (KST)`,
    `05:00 - 07:00 (Giờ Hàn quốc)`,
  ],
  lunch_time: [
    `오전 11시 ~ 오후 1시(KST)`,
    `11AM - 1PM (KST)`,
    's_pricing.lunch_time',
    `上午11点~下午1点(KST)`,
    `上午11點～下午1點`,
    `11:00 ~ 13:00 (KST)`,
    `11:00 - 13:00 (Giờ Hàn quốc)`,
  ],
  night_time: [
    `자정 00시 ~ 새벽 2시(KST)`,
    `12AM - 2AM (KST)`,
    's_pricing.night_time',
    `午夜00点~凌晨2点(KST)`,
    `午夜00點～凌晨2點`,
    `深夜0時 ~ 2:00 (KST)`,
    `00:00 - 02:00 (Giờ Hàn quốc)`,
  ],
  certi: [`증명서류`, `Certificate`, 's_pricing.certi', `证明材料`, `證明`, `証明書類`, `Chứng nhận`],
  certi_example_desc: [
    `링글 증명서류 예시입니다. 1개월 이상 수강권의 경우 매월 분할 발급이 가능합니다.`,
    `Sample certificate. Can be issued monthly.`,
    's_pricing.certi_example_desc',
    `Ringle证明材料范本。 1个月以上的课程券可每月分期发放。`,
    `Ringle的證明範例。1個月以上的聽課券可按月核發。`,
    `Ringleの証明書類の例です。1か月以上の受講チケットの場合、毎月分割発行が可能です。`,
    `Mẫu chứng nhận. Có thể phát hành mỗi tháng.`,
  ],
  attendance_ex: [
    `- 출석증 증명서류 예시 -`,
    `- Sample Proof of Attendance -`,
    's_pricing.attendance_ex',
    `- 出席证范本 -`,
    `- 出席證明範例 -`,
    `- 出席証の証明書類の例 -`,
    `- Mẫu chứng nhận tham gia -`,
  ],
  lesson_certi_ex: [
    `- 수강증 증명서류 예시 -`,
    `- Sample Lesson Certificate -`,
    's_pricing.lesson_certi_ex',
    `- 听课证范本 -`,
    `- 上課證明範例 -`,
    `- 受講証の証明書類の例 -`,
    `- Mẫu chứng nhận buổi học -`,
  ],
  what_is_additional_lesson: [
    `당일수업이란?`,
    `What are [Last-minute Lessons]?`,
    's_pricing.what_is_additional_lesson',
    `何为补充课程?`,
    `補充課程意指？`,
    `補充レッスンについて`,
    `Buổi Học bù là gì?`,
  ],
  free_subsidy_description: [
    <>
      {' '}
      <Div>
        <Div fontBold>당일수업이란?</Div>
        <Div textGray700>
          - 예약 시점 기준 24시간 이내에 시작하는 수업
          <br />
          - 타 수강생이 수업을 취소할 경우에 형성
          <br />- &#91;1:1 수업&#93; - &#91;예약&#93; - &#91;당일수업&#93;에서 수업 예약 가능{' '}
        </Div>
        <br />
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div fontBold>What are [Last-minute lessons]?</Div>
        <Div textGray700>
          - Lessons starting within 24 hours in real-time.
          <br />
          - For every four completed lessons, one Last-minute Credit will be given.
          <br />- Can be reserved at [1:1 Lessons] - [Reservation] - [Last-minute lessons]
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>What counts as used lesson credits?</Div>
        <Div textGray700>
          - Completed lessons - Canceled lessons - No-show lessons all count as using lesson credits
        </Div>
      </Div>
    </>,
    's_pricing.free_subsidy_description',
    <>
      {' '}
      <Div>
        <Div fontBold>何为补充课程?</Div>
        <Div textGray700>
          - 当前于24小时以内开始的课程
          <br />
          - 完成正规课程4次, 提供1次(长期券优惠)
          <br />- 课程期限为发行之日起1年
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>扣除正规课程券时机?</Div>
        <Div textGray700>- 课程正常进行 - 课程旷课 - 课程开始前24小时以内取消课程</Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div fontBold>補充課程意指？</Div>
        <Div textGray700>
          - 目前於24小時以內開始的課程
          <br />
          - 耗盡4次正規課程時，將發送1次（長期券優惠）
          <br />- 聽課期間自發送日起1年
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>耗盡正規課程意指？</Div>
        <Div textGray700>- 正常進行課程 - 上課未出現 - 於課程開始前24小時以內取消</Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div fontBold>補充レッスンとは？</Div>
        <Div textGray700>
          - リアルタイムで24時間以内に受講できるレッスン
          <br />
          - レギュラーレッスンチケット4回使用で補充レッスン1回分付与。(長期受講チケット特典)
          <br />- 有効期間は発行日から1年間
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>レッスンチケットの使用カウント例</Div>
        <Div textGray700>
          - レッスンが問題なく実施された場合 - レッスンを無断欠席した場合 - レッスン開始24時間以内にキャンセルした場合
        </Div>
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div fontBold>Thẻ Học bù</Div>
        <Div textGray700>
          - Buổi học sẽ bắt đầu trong vòng 24 giờ.
          <br />
          - Cứ mỗi sáu buổi học bạn hoàn thành, một Thẻ học bù sẽ được tặng cho bạn.
          <br />- Thời hạn hiệu lực là một năm.
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>Những buổi nào được tính là Thẻ học đã sử dụng?</Div>
        <Div textGray700>
          - Buổi học đã hoàn thành - Buổi học đã huỷ - Buổi học vắng mặt cũng được coi là đã sử dụng thẻ học
        </Div>
      </Div>
    </>,
  ],
  refund_fee: [
    `환불시 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료는 차감하고 환불될 수 있습니다.`,
    `In the case of refunds, any transaction fees incurred will be deducted from the refund amount.`,
    's_pricing.refund_fee',
    `退款时，可能会扣除退还银行、信用卡公司、Paypal等支付方式的手续费。`,
    `退款時，可能會扣除退還銀行，信用卡公司，Paypal等支付方式的手續費.`,
    `返金時は銀行やカード会社、Paypalなどの手数料を差し引かれた額が返金されます。`,
    `Với trường hợp hoàn tiền, phí giao dịch sẽ bị trừ vào khoản tiền được hoàn trả.`,
  ],
  regular_price: (price, number) => [
    `(1회당 ${price} X ${number}회)`,
    `(${number} per Credit X ${number} lessons)`,
    's_pricing.regular_price',
    `(1次 ${price} X ${number}次)`,
    `(1次 ${price} X ${number}次)`,
    `(1回当たり${price}X ${number}回)`,
    `(Giá tiêu chuẩn ${price} X ${number} buổi học)`,
  ],
  first_purchase_benefit: [
    `첫 구매 혜택`,
    `First Purchase`,
    's_pricing.first_purchase_benefit',
    `首次购买优惠`,
    `首次購買折扣優惠`,
    `初回購入割引特典`,
    `Giảm giá cho học viên mới`,
  ],
  buy_btn: [`구매하기`, `Purchase`, 's_pricing.buy_btn', `购买`, `購買`, `購入する`, `Thanh toán ngay`],
  switch_to_won: [
    `원화로 바꾸기`,
    `Convert to KRW`,
    's_pricing.switch_to_won',
    `转换为韩元`,
    `轉換為韓元`,
    `韓国のウォン貨に通貨変更する`,
    `Chuyển qua tiền Hàn Quốc`,
  ],
  switch_to_other: [
    `현지 통화로 바꾸기`,
    `Convert to Local Currency`,
    's_pricing.switch_to_other',
    `转换为当地货币`,
    `轉換為當地貨幣`,
    `現地の通貨に変更する`,
    `Chuyển qua tiền địa phương`,
  ],
  buy_credit: [`수업권 구매`, `Purchase`, 's_pricing.buy_credit', `购买`, `購買`, `購入する`, `Thanh toán`],
  tab: {
    purchase: [`수업권`, `Purchase`, 's_pricing.tab.purchase', `购买`, `購買`, `購入する`, `Thanh toán`],
    method: [
      `선택방법`,
      `Credits Detail`,
      's_pricing.tab.method',
      `课程券选择方法`,
      `課程券選擇方法`,
      `レッスンチケットの種類`,
      `Chi tiết thẻ học`,
    ],
    guarantee: [
      `링글 보장제도`,
      `Guarantee Policy`,
      's_pricing.tab.guarantee',
      `保障制度&优惠`,
      `保障制度&優惠`,
      `保障制度＆特典`,
      `Chính sách đảm bảo`,
    ],
    benefit: [`수강 혜택`, `Benefits`, 's_pricing.tab.benefit', `课程优惠`, `聽課優惠`, `受講特典`, `Ưu đãi`],
    certificate: [
      `증명 서류`,
      `Certificate`,
      's_pricing.tab.certificate',
      `证明材料`,
      `證明`,
      `証明書類`,
      `Chứng nhận`,
    ],
    precaution: [`FAQ`, `FAQ`, 's_pricing.tab.precaution', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
    guarantee_and_benefit: [
      `보장제도 & 혜택`,
      `Guarantee Policy`,
      's_pricing.tab.guarantee_and_benefit',
      `保障制度&优惠`,
      `保障制度&優惠`,
      `保障制度＆特典`,
      `Chính sách đảm bảo`,
    ],
  },
  credit_info_description: [
    `수업 진행 방식과 콘텐츠는 동일합니다`,
    `Lesson format and available materials are the same`,
    's_pricing.credit_info_description',
    `课程进行方式和使用的教材相同`,
    `課程進行方式和使用的教材相同`,
    `レッスンの進行方式と教材は同じ`,
    `Hình thức học và tài liệu đều giống nhau`,
  ],
  guarantee: {
    desc1: [
      `첫 구매 혜택도 첫 수업에 포함됨`,
      `Bonus or free lessons may count as a first lesson`,
      's_pricing.guarantee.desc1',
      `使用赠送课程劵申请的首次课程也算作首次课程`,
      `使用贈送課程券申請的首次課程也算作首次課程`,
      `初回購入も初回レッスンに含まれる`,
      `Buổi học thêm hoặc miễn phí có thể được tính như buổi học đầu tiên.`,
    ],
  },
  benefit_list_1: {
    title1: [
      `오리지널 콘텐츠`,
      `Original Content`,
      's_pricing.benefit_list_1.title1',
      `独家原创教材`,
      `獨家原創教材`,
      `オリジナルコンテンツ`,
      `Nội dung độc quyền`,
    ],
    desc1: [
      `1,000+종의 링글`,
      `1,000+ Ringle's`,
      's_pricing.benefit_list_1.desc1',
      `1,000多篇学习材料`,
      `1,000多篇學習材料`,
      `1,000種類以上の`,
      `1,000+ nội dung từ Ringle`,
    ],
    title2: [
      `튜터의 Live Class`,
      `LIVE Webinar`,
      's_pricing.benefit_list_1.title2',
      `教师直播研讨会`,
      `教師直播研討會`,
      `Live Class`,
      `Webinar trực tuyến`,
    ],
    desc2: [
      `매주 진행되는`,
      `Weekly top tutor`,
      's_pricing.benefit_list_1.desc2',
      `每周进行的`,
      `每周進行的`,
      `毎週行われる`,
      `Các gia sư hàng đầu mỗi tuần`,
    ],
    title3: [
      `380+편 VOD`,
      `380+ Video Courses`,
      's_pricing.benefit_list_1.title3',
      `380多部教学视频`,
      `380多部教學視頻`,
      `380+編の VOD`,
      `380+ video khóa học`,
    ],
    desc3: [
      `커리어, 영어학습 등`,
      `Career & other topical`,
      's_pricing.benefit_list_1.desc3',
      `职场，英语学习等`,
      `職場，英語學習等`,
      `キャリア、英語学習など`,
      `Sự nghiệp & các chủ đề khác`,
    ],
    caption1: [
      `PDF 파일 / MP3 오디오북 다운로드 지원`,
      `PDF/MP3 Audiobook provided`,
      's_pricing.benefit_list_1.caption1',
      `提供PDF/MP3有声读物`,
      `提供PDF/MP3有聲讀物`,
      `PDF/MP3オーディオブック提供`,
      `PDF/MP3 Audiobook được cung cấp`,
    ],
    title: [
      `매일 업데이트 되는 최신 콘텐츠`,
      `New learning content updated daily`,
      's_pricing.benefit_list_1.title',
      `每日更新的最新内容`,
      `每日更新的最新内容`,
      `毎日更新される最新のコンテンツ`,
      `Tài liệu học cập nhật mới mỗi ngày`,
    ],
  },
  benefit_list_2: {
    title1: [
      `말하기 패턴 분석 자료`,
      `Speech pattern analysis`,
      's_pricing.benefit_list_2.title1',
      `口语分析材料`,
      `口語分析材料`,
      `話すパターンの分析材料`,
      `Phân tích mẫu nói`,
    ],
    desc1: [
      `AI 기반 분석 시스템`,
      `AI Review system's`,
      's_pricing.benefit_list_2.desc1',
      `人工智能分析系统`,
      `人工智能分析系統`,
      `AI基盤の分析システム`,
      `Hệ thống đánh giá`,
    ],
    title2: [
      `개별 첨삭 수업노트`,
      `Real-time lesson notes`,
      's_pricing.benefit_list_2.title2',
      `课堂修改笔记`,
      `課堂修改筆記`,
      `個別の添削レッスンノート`,
      `Ghi chú trực tiếp trong buổi học`,
    ],
    desc2: [
      `별도 제공`,
      `See after lesson`,
      's_pricing.benefit_list_2.desc2',
      `附加提供`,
      `附加提供`,
      `別途提供`,
      `Xem sau buổi học`,
    ],
    title3: [
      `튜터의 1:1 피드백 리포트`,
      `Tutor's Feedback report`,
      's_pricing.benefit_list_2.title3',
      `1:1教师反馈报告`,
      `1:1教師反饋報告`,
      `チューターの1:1フィードバックレポート`,
      `Đánh giá từ gia sư`,
    ],
    desc3: [
      `매 수업 종료 후 제공`,
      `Thorough and personalized`,
      's_pricing.benefit_list_2.desc3',
      `课程结束后提供`,
      `課程結束后提供`,
      `毎回レッスン終了後に提供`,
      `Toàn diện và cá nhân hóa`,
    ],
    caption1: [
      `모든 수업 녹음파일 무제한 듣기`,
      `Exclusive Lesson Materials`,
      's_pricing.benefit_list_2.caption1',
      `贴合您现实生活的教材`,
      `符合您的現實生活的教材`,
      `あなたのニーズに合わせた教材`,
      `Tài liệu độc quyền cho buổi học`,
    ],
    caption2: [
      `말하기, 듣기, 읽기, 쓰기 영역별 학습 데이터`,
      `Detailed feedback on vocabulary, fluency, grammar, and pronunciation`,
      's_pricing.benefit_list_2.caption2',
      `对词汇, 语法, 发音及流畅性等各方面进行分别评价`,
      `診斷詞彙、文法、發音及流暢性等各領域`,
      `語彙、文法、発音、流暢性など分野別診断`,
      `Đánh giá chi tiết về phát âm, ngữ pháp, từ vựng và mức độ trôi chảy`,
    ],
    title: [
      `AI가 기록하는 복습 시스템`,
      `AI-supported review system`,
      's_pricing.benefit_list_2.title',
      `人工智能复习系统`,
      `人工智能復習系統`,
      `AIが記録する復習システム`,
      `Hệ thống đánh giá bằng AI`,
    ],
  },
  btn: {
    detail: [`상세보기`, `See details`, 's_pricing.btn.detail', `查看详情`, `詳細內容`, `詳しく見る`, `Xem chi tiết`],
  },
  additional_lessons: (count) => [
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}회
          </Div>{' '}
          수업 추가
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>{' '}
          Free lesson(s)
        </>
      ) : (
        ''
      )}
    </>,
    's_pricing.additional_lessons',
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          次追加课程
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          次額外課程
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>
          回レッスン追加
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-purple-500']}>
            {count}
          </Div>{' '}
          Buổi học miễn phí(s)
        </>
      ) : (
        ''
      )}
    </>,
  ],
  additional_discounts: (discount) => [
    <>{discount ? `${discount}만원 할인` : ''}</>,
    <>{discount ? `${discount} Off` : ''}</>,
    's_pricing.additional_discounts',
    <>{discount ? `${discount}优惠` : ''}</>,
    <>{discount ? `${discount}折扣` : ''}</>,
    <>{discount ? `${discount}割引` : ''}</>,
    <>{discount ? `${discount} Off` : ''}</>,
  ],
  long_package: {
    benefit1: (count) => [
      <>{count ? `${count}회 수업 추가` : ''}</>,
      <>{count ? `${count} Free lesson(s)` : ''}</>,
      's_pricing.long_package.benefit1',
      <>{count ? `${count}次追加课程` : ''}</>,
      <>{count ? `${count}次額外課程` : ''}</>,
      <>{count ? `${count}回レッスン追加` : ''}</>,
      <>{count ? `${count} Buổi học miễn phí(s)` : ''}</>,
    ],
    benefit2: (discount) => [
      <>{discount ? `${discount}만원 할인` : ''}</>,
      <>{discount ? `${discount} Off` : ''}</>,
      's_pricing.long_package.benefit2',
      <>{discount ? `${discount}优惠` : ''}</>,
      <>{discount ? `${discount}折扣` : ''}</>,
      <>{discount ? `${discount}割引` : ''}</>,
      <>{discount ? `${discount} Off` : ''}</>,
    ],
    free_subsidy: (free) => [
      `${free}회 당일수업권 제공`,
      `${free} Last-minute Credits`,
      's_pricing.long_package.free_subsidy',
      `${free}次补充课程`,
      `${free}次補充課程`,
      `${free}回分の補充レッスン`,
      `${free} Buổi học bù`,
    ],
    additional: [
      `장기권 추가 혜택`,
      `Size Benefit`,
      's_pricing.long_package.additional',
      `长期课程券优惠`,
      `長期課程券優惠`,
      `長期レッスンチケット特典`,
      `Gói học Ưu đãi`,
    ],
    benefit: [`혜택`, `Benefits`, 's_pricing.long_package.benefit', `课程优惠`, `聽課優惠`, `受講特典`, `Ưu đãi`],
    benefit_1: {
      desc: [
        <>
          매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능)
          <br />
          당일수업권은 예약 시점 기준 24시간 이내에 시작하는 [당일수업]을 예약할 수 있습니다.
        </>,
        `For every four completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
        's_pricing.long_package.benefit_1.desc',
        <>
          是指将于24小时以内开始的课程。但只能选择Free Talking教材, 正规课程进行4次时将提供1次。(课程期限: 发行后1年)
        </>,
        <>
          目前於24小時以內開始的課程，只可選擇Free
          Talking教材。耗盡4次正規課程時，將發送1次。（聽課期間：自發送日起1年）
        </>,
        <>
          リアルタイムで24時間以内に受講できるレッスンです。Free
          Talking教材のみ選択可能で、レギュラーレッスンを4回使用すると補充レッスン1回分のチケットが発行されます。(有効期限:
          発行から1年間)
        </>,
        <>
          Buổi học bù sẽ diễn ra trong vòng 24 giờ. Chỉ có buổi học về Chủ đề Tự do hoặc Tập trung luyện nói. Cứ sáu
          buổi học hoàn thành, bạn được tặng một buổi học Bù. (có giá trị 1 năm)
        </>,
      ],
    },
  },
  actual_payment_unit_price_desc: [
    `정규수업권과 당일수업권을 포함한 총 수업 횟수를 합산하여 최종 결제 금액으로 나눈 단가입니다.`,
    `Final price divided by total credits (includes Last-minute Credit(s))`,
    's_pricing.actual_payment_unit_price_desc',
    `最终购买价格除以全部课程次数后的单价。（包含24小时内的补充课程）`,
    `最終購買價格除以全部課程次數後的單價。（包含24小時内的補充課程）`,
    <>
      最終決済の金額を提供される総レッスンの回数で
      <br />
      分割した単価です。（24時間以内の補充レッスンを含む）
    </>,
    `Giá cuối cùng được chia theo tổng số thẻ học (bao gồm cả thẻ học bù)`,
  ],
  expected_point_desc: [
    `실제 결제한 전체 수업권 금액의 3%를 적립해 드립니다. (매 수업 종료 7일 후 적립 예정)`,
    `3% of paid amount will be awarded as points (within 7 days of purchase)`,
    's_pricing.expected_point_desc',
    `累计积分为最终购买价格的3%。（每次课程结束7天后累计）`,
    `纍計點數為最終購買價格的3%。（每次課程結束7天后纍計）`,
    `実際に決済した全体のレッスンチケットの金額、3％のポイントを差し上げます。（毎回レッスン終了7日後に提供）`,
    `3% học phí đã thanh toán sẽ được thưởng dưới dạng điểm (sau 7 ngày thanh toán)`,
  ],
  not_opened_1: [
    `첫 수업 시작 전까지`,
    `Lesson days are frozen until first lesson`,
    's_pricing.not_opened_1',
    `首次课程开始前`,
    `首次課程開始之前，`,
    `自分のペースで始められる`,
    ``,
  ],
  not_opened_2: [
    `수강기간 미차감`,
    ``,
    's_pricing.not_opened_2',
    `课程期限不被扣除`,
    `不扣除聽課期間`,
    ``,
    `Bắt đầu bất cứ khi nào`,
  ],
  refund_all_1: [
    `첫 수업 불만족 시`,
    <>
      100% Money-back
      <br />
      guarantee
    </>,
    's_pricing.refund_all_1',
    `首次课程不满意时`,
    `首次課程不滿意時，`,
    `全額返金保証`,
    ``,
  ],
  refund_all_2: [`100% 환불`, ``, 's_pricing.refund_all_2', `承诺100%退款`, `可全額退款`, ``, `Hoàn tiền 100%`],
  change_before_day_1: [
    `수업 하루 전까지`,
    <>
      Change schedule up to 24 hours
      <br />
      before the lesson
    </>,
    's_pricing.change_before_day_1',
    `截止于课程开始前一天可进行变更`,
    `最晚可於課程開始1天前變更日程`,
    `レッスン前日まで予約変更できる`,
    `Huỷ linh hoạt`,
  ],
  change_before_day_2: [`일정 변경 가능`, ``, 's_pricing.change_before_day_2', ``, ``, ``, ``],
  hero: {
    benefit1: [
      `링글 첫 구매 수강생에게 드리는 혜택`,
      `First Purchase Benefits for New Ringlers`,
      's_pricing.hero.benefit1',
      `首次购买优惠`,
      `首次購買優惠`,
      `Ringle初回購入の受講生に与える特典`,
      `Ưu đãi đặc biệt cho người dùng mới`,
    ],
    benefit2: (point) => [
      `최대 3회 수업 추가 & 1만원 할인`,
      <>
        Up to 3 extra credits{isMobile && <br />} & {point} off
      </>,
      's_pricing.hero.benefit2',
      <>
        最多赠送３次课程{isMobile && <br />}&优惠{point}
      </>,
      <>
        最多贈送３次課程{isMobile && <br />}&優惠{point}
      </>,
      <>
        最大3回レッスン追加{isMobile && <br />}&{point}割引
      </>,
      <>
        Ưu đãi tới {point}
        {isMobile && <br />} & 3 buổi học miễn phí
      </>,
    ],
    benefit3: [
      <>
        첫 1회 수업
        <br />
        불만족 시, 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      's_pricing.hero.benefit3',
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
    ],
    benefit4: [
      <>
        첫 수업 시작 전<br />
        수강기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.hero.benefit4',
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
    ],
    benefit5: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      's_pricing.hero.benefit5',
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
    ],
    benefit6: [
      <>
        매 수업 완료 시<br />
        링글 포인트 적립
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      's_pricing.hero.benefit6',
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
    ],
    benefit7: [
      <>
        검증된 퀄리티의 수업을
        <br />
        합리적인 가격으로
      </>,
      <>
        Verified premium lessons
        <br />
        at a reasonable price
      </>,
      's_pricing.hero.benefit7',
      <>
        以合理的价格
        <br />
        获得高质量的课程
      </>,
      <>
        以合理的價格
        <br />
        獲得高質量的課程
      </>,
      <>
        検証されたクオリティのレッスンを <br />
        合理的な価格で
      </>,
      <>
        Buổi học chất lượng
        <br />
        Tương xứng số tiền bỏ ra
      </>,
    ],
    benefit8: [
      `2022년 영어는 제대로 시작하세요!`,
      `Learn English the right way in 2022!`,
      's_pricing.hero.benefit8',
      `2022年开启完美英语学习之旅吧！`,
      `2022年開啓完美英語學習之旅吧！`,
      `2022年は英語を正式に始めてみませんか？`,
      `Bắt đầu 2022 thật khác biệt!`,
    ],
    benefit9: (point) => [
      <>
        친구초대 성공 시 <b>{point}포인트 제공</b>
      </>,
      <>Invite your friends and earn {point} </>,
      's_pricing.hero.benefit9',
      <>邀请朋友成功时可获得 {point} 积分</>,
      <>邀請朋友成功時可獲得{point}點數</>,
      <>友達招待に成功した場合、{point}提供</>,
      <>Mời bạn bè và nhận {point} điểm thưởng</>,
    ],
    benefit10: [
      <>
        수업권 구매 후 <b>원하는 시점 시작 가능</b>
      </>,
      `Buy now and start later`,
      's_pricing.hero.benefit10',
      `购买课程劵后自由选择首次课程开始时间`,
      `購買課程券后自由選擇首次課程開始時間`,
      `レッスンチケットを購入後好きなタイミングで始められます。`,
      `Thời gian học tính từ buổi học đầu tiên`,
    ],
    benefit11: [
      <>
        수업이 끝나면 <b>3% 포인트 적립</b>
      </>,
      `Get 3% points of the purchased price after each lesson`,
      's_pricing.hero.benefit11',
      `每次课程结束后累计３％积分`,
      `每次課程結束后纍計3%點數`,
      `レッスンが終わったら3％ポイントゲット`,
      `3% Điểm thưởng sau khi hoàn thành buổi học`,
    ],
    title: [
      `오직 한 달간 진행되는 최대 할인`,
      `Maximum Discount for One Month`,
      's_pricing.hero.title',
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
      `Maximum Discount for One Month`,
    ],
    subtitle1: [
      <>
        할인부터 영어 공부법까지 챙기는{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          링글 하반기 프로모션
        </Div>
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      's_pricing.hero.subtitle1',
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
    ],
    date: [
      `22.06.07 (Tue) - 07.07 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      's_pricing.hero.date',
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
    ],
    date_mobile: [
      `~07.07 (목)`,
      `~July 07, 2022 (Thu)`,
      's_pricing.hero.date_mobile',
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
      `~July 07, 2022 (Thu)`,
    ],
    top: [
      `1년에 단 2번`,
      `Only Twice a Year`,
      's_pricing.hero.top',
      `Only Twice a Year`,
      `Only Twice a Year`,
      `Only Twice a Year`,
      `Only Twice a Year`,
    ],
    label_new1: [
      `오직 단 한번!`,
      `One-time`,
      's_pricing.hero.label_new1',
      `One-time`,
      `One-time`,
      `One-time`,
      `One-time`,
    ],
    label_new2: [
      `신규회원 첫 구매 이벤트`,
      `Newcomer Event`,
      's_pricing.hero.label_new2',
      `Newcomer Event`,
      `Newcomer Event`,
      `Newcomer Event`,
      `Newcomer Event`,
    ],
    before_login: [
      <>
        지금 링글 가입하면
        <br />
        <span className="text-[#FF6712]">최대 20만원 </span> 즉시 할인!
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
      's_pricing.hero.before_login',
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
    ],
    welcome: [
      <>
        최대 20만원 할인
        <br />
        <span className="text-[#FF6712]">혜택이 곧 사라져요.</span>
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
      's_pricing.hero.welcome',
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
    ],
    label_old1: [
      `잊지 않으셨죠?`,
      `Don't forget`,
      's_pricing.hero.label_old1',
      `Don't forget`,
      `Don't forget`,
      `Don't forget`,
      `Don't forget`,
    ],
    label_old2: [
      `고객님께만 드린 혜택`,
      `to get our special offer`,
      's_pricing.hero.label_old2',
      `to get our special offer`,
      `to get our special offer`,
      `to get our special offer`,
      `to get our special offer`,
    ],
    next: [
      <>
        최대 10만원 할인
        <br />
        혜택이 <span className="text-[#FF6712]">곧 사라져요.</span>
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
      's_pricing.hero.next',
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
    ],
    label_challenge1: [
      `이벤트 진행 중`,
      `Join Now!`,
      's_pricing.hero.label_challenge1',
      `Join Now!`,
      `Join Now!`,
      `Join Now!`,
      `Join Now!`,
    ],
    label_challenge2: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_pricing.hero.label_challenge2',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    challenge: [
      <>
        <span className="text-[#FF6712]">특별한 여행이</span> 기다립니다.
        <br />
        지금 링글을 시작하세요.
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      's_pricing.hero.challenge',
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
    ],
    countdown_day: [
      `일 후 종료`,
      `Day(s) left`,
      's_pricing.hero.countdown_day',
      `Day(s) left`,
      `Day(s) left`,
      `Day(s) left`,
      `Day(s) left`,
    ],
    countdown: [
      `할인 혜택 종료까지`,
      `Special offer expires in`,
      's_pricing.hero.countdown',
      `Special offer expires in`,
      `Special offer expires in`,
      `Special offer expires in`,
      `Special offer expires in`,
    ],
  },
  no_split_payment: [
    `1년 수업권 결제`,
    `No split payments`,
    's_pricing.no_split_payment',
    `1年课程券结算`,
    `1年課程券結算`,
    `一年のレッスンチケット決済`,
    `Không chia nhỏ phần thanh toán`,
  ],
  no_split_payment_desc: [
    `[카드 일반결제/실시간 계좌이체/가상계좌 발급]만 지원되고 있습니다. 분할 결제 또한 가능하지 않습니다.`,
    `Only [Full Credit/Debit card payments/Wire Transfer/Virtual Account] are accepted for year-long packages`,
    's_pricing.no_split_payment_desc',
    `只支持一次性结算，无法分期付款`,
    `只支持1次性結算，無法分期付款`,
    `一般決済のみ支援しております。分割払い決済は適応されません。`,
    `Với gói học một năm, bạn phải thanh toán một lần`,
  ],
  welcome_coupon: {
    label: [
      `3만원 웰컴쿠폰이 적용되어 있습니다`,
      `$25 OFF WELCOME COUPON applied`,
      's_pricing.welcome_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  starter_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Starter Coupon Pack applied`,
      's_pricing.starter_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  repurchase_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Next Purchase Coupon Pack applied`,
      's_pricing.repurchase_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    starter_coupon: {
      title_1: [
        `링글 첫 시작 한정`,
        `For first-purchasers only`,
        's_pricing.popup.starter_coupon.title_1',
        ``,
        ``,
        ``,
        ``,
      ],
      title_2: [`스타터 쿠폰팩 발급`, `Starter Coupon Pack`, 's_pricing.popup.starter_coupon.title_2', ``, ``, ``, ``],
      footer: [
        <>
          *쿠폰 발급 후 35일 이내 사용 가능합니다.
          <br />
          *20만원 이상 정규수업권 구매 시 적용 가능한 쿠폰입니다.
        </>,
        <>
          * Valid for 35 days after issuance.
          <br />* Only redeemable toward regular packages worth $169 or more.
        </>,
        's_pricing.popup.starter_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`한 달간 보지않기`, `Remind me in a month`, 's_pricing.popup.starter_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`확인`, `Close`, 's_pricing.popup.starter_coupon.btn_2', ``, ``, ``, ``],
    },
    repurchase_coupon: {
      title_1: [
        `기존 회원 한정`,
        `Next Purchase`,
        's_pricing.popup.repurchase_coupon.title_1',
        `Next Purchase`,
        `Next Purchase`,
        `Next Purchase`,
        `Next Purchase`,
      ],
      title_2: [
        `재구매 쿠폰 발급 안내`,
        `Coupon Pack`,
        's_pricing.popup.repurchase_coupon.title_2',
        `Coupon Pack`,
        `Coupon Pack`,
        `Coupon Pack`,
        `Coupon Pack`,
      ],
      footer: [
        <>* 쿠폰은 수업권 금액에 따라 차등 적용됩니다.</>,
        <>* Discounts vary according to credit price.</>,
        's_pricing.popup.repurchase_coupon.footer',
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
        <>* Discounts vary according to credit price.</>,
      ],
      btn_1: [
        `일주일간 보지않기`,
        `Remind me in a week`,
        's_pricing.popup.repurchase_coupon.btn_1',
        `Remind me in a week`,
        `Remind me in a week`,
        `Remind me in a week`,
        `Remind me in a week`,
      ],
      btn_2: [`확인`, `Close`, 's_pricing.popup.repurchase_coupon.btn_2', `Close`, `Close`, `Close`, `Close`],
    },
  },
  card_promo: [
    `프로모션 한정 판매`,
    `2022 Promo Only`,
    's_pricing.card_promo',
    `2022 Promo Only`,
    `2022 Promo Only`,
    `2022 Promo Only`,
    `2022 Promo Only`,
  ],
  card_popular: [
    `인기 수업권`,
    `Most Popular`,
    's_pricing.card_popular',
    `Most Popular`,
    `Most Popular`,
    `Most Popular`,
    `Most Popular`,
  ],
  promo_price: [
    `연중 최대 할인가`,
    `Best Deal All Year`,
    's_pricing.promo_price',
    `全年最高折扣价`,
    `全年最高折扣價`,
    `今年最大の割引価格`,
    `Giá thấp nhất`,
  ],
  package_price: [
    `패키지 할인`,
    `Package discount`,
    's_pricing.package_price',
    `礼包折扣`,
    `禮包折扣`,
    `パッケージ割引`,
    `Giảm giá gói hàng`,
  ],
  benefit_list1: (number) => [
    <>무료 수업권 {number}회 추가 증정</>,
    <>
      {number} extra regular {number > 1 ? 'credits' : 'credit'}{' '}
    </>,
    's_pricing.benefit_list1',
    <>{number}次追加课程</>,
    <>{number}次額外課程</>,
    <>{number}回レッスン追加</>,
    `Giảm giá cho học viên mới`,
  ],
  benefit_list2: (price) => [
    <>{price} 추가 할인</>,
    <>Extra {price} off</>,
    's_pricing.benefit_list2',
    <>{price}优惠</>,
    <>{price}折扣</>,
    <>{price}割引</>,
    <>{price} Off</>,
  ],
  benefit_list3: (number) => [
    <>당일수업권 {number}회 추가 증정</>,
    <>{number} extra last-minute credits</>,
    's_pricing.benefit_list3',
    <>{number}次补充课程</>,
    <>{number}次補充課程</>,
    <>{number}回分の補充レッスン</>,
    <>{number} Buổi học bù</>,
  ],
  benefit_list4: [
    `튜터 우선 예약`,
    `Early Booking Access`,
    's_pricing.benefit_list4',
    `教师优先选定`,
    `優先指定任課教師`,
    `チューター優先予約`,
    `Gia sư ưu tiên`,
  ],
  benefit_list5: (number) => [
    <>{number}회 당일수업권 제공</>,
    <>{number} Last-minute Credits</>,
    's_pricing.benefit_list5',
    <>{number}次补充课程</>,
    <>{number}次補充課程</>,
    <>{number}回分の補充レッスン</>,
    <>{number} Buổi học bù</>,
  ],
  btn_detail: [
    `자세히 보기`,
    `See details`,
    's_pricing.btn_detail',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  qna: {
    title: [
      `자주 묻는 질문`,
      `Frequently Asked Questions`,
      's_pricing.qna.title',
      `常见问题`,
      `常見問題`,
      `よくある質問`,
      `Những câu hỏi thường gặp`,
    ],
    list1: {
      main: [
        `어떤 수업권을 선택하면 될까요?`,
        `Which credit type should I buy?`,
        's_pricing.qna.list1.main',
        `该选择哪一个课程券呢？`,
        `我應該選擇哪一個課程券呢？`,
        `どのレッスンチケットを選択すればいいですか？`,
        `Tôi nên mua thẻ học nào?`,
      ],
      desc1: [
        `20분/40분 수업권은 수업 길이 차이만 있고 수업 진행 방식, 교재와 튜터 선택지는 모두 같습니다.`,
        `20 min and 40 min credits are only different in terms of time, everything else such as materials, tutors and lesson format are the same.`,
        's_pricing.qna.list1.desc1',
        `20分钟和40分钟课程券的区别只在于课程时间长度，课程进行方式、教材，选择任课教师均相同。`,
        `20分鐘及40分鐘課程券差別只在於課程長度，進行方式、教材、任課教師的選擇均相同。`,
        `20分と40分のレッスンチケットは長さの違いがあるだけで、進行方式、教材、チューターの選択肢は全て同じです。`,
        `Khác biệt duy nhất ở thẻ học 20 và 40 phút là thời gian, mọi thừ từ tài liệu, gia sư và hình thức đều giống nhau.`,
      ],
      desc2: [
        `20분 수업은 비교적 가벼운 주제로 꾸준한 영어 말하기 습관을 만드는데 추천하는 수업입니다.`,
        `20 minute lessons are recommended for those who wish to build English speaking habits on relatively lighter topics.`,
        's_pricing.qna.list1.desc2',
        `20分钟课程是进行轻松主题对话，培养英语口语习惯的课程。`,
        `20分鐘課程是利用相對輕鬆的主題培養按時說英語的習慣。`,
        `20分レッスンは比較的軽いテーマでコツコツと英語の話す習慣を形成するのにお勧めするレッスンです。`,
        `Buổi học 20 phút phù hợp với những ai muốn xây dựng thói quen nói tiếng Anh qua những chủ đề đơn giản.`,
      ],
      desc3: [
        `40분 수업은 특정 주제에 대한 깊이 있는 대화, 인터뷰/Writing 첨삭 등에 추천하는 수업입니다.`,
        `40 minute lessons are recommended for in-depth conversations on a specific topic or for editing interviews/writing.`,
        's_pricing.qna.list1.desc3',
        `40分钟课程是针对特定主题进行深度讨论或练习面试/笔试增删的课程。`,
        `40分鐘課程是針對特定主題進行深度討論或練習面試／Writing增刪。`,
        `40分レッスンは特定のテーマに対して深く対話をしたり、面接／Writingの添削にお勧めのレッスンです。`,
        `Buổi học 40 phút phù hợp với những buổi thảo luận sâu dựa trên những chủ đề nhất định hoặc luyện phỏng vấn thử/ chỉnh sửa bài viết.`,
      ],
      desc4: [
        `특히 영어 초·중급자의 경우 최소 3개월 이상 주 1-2회 수업을 수강했을 때 영어 실력이 향상됩니다. 20분/40분 24회 이상 수업권으로 시작하시는 것을 추천합니다.`,
        `For beginners/intermediates, we recommend credits more than 24 lessons. We suggest taking 1-2 lessons per week for at least 3 months to improve English proficiency.`,
        's_pricing.qna.list1.desc4',
        `针对英语能力初/中级者，我们建议上课频率为一週 1~2次，并且至少持续3个月，如此一来才能看见明显进步。我们建议从购买 20分钟/40分钟至少24回以上的课程券开始，以利长期学习。`,
        `針對英文能力初/中級者，我們建議上課頻率為一週 1~2次，並且至少持續3個月，如此一來才能看見明顯進步。我們建議從購買 20分鐘/40分鐘至少24回以上的課程券開始，以利長期學習。`,
        `特に英語の初級者、中級者の場合、最低3ヶ月以上週1~2回授業をした時に英語力が向上します。 20分/40分、24回以上のチケットで始めることをおすすめします。`,
        `Đối với người mới bắt đầu/trung cấp, chúng tôi khuyên bạn tham gia 24 bài học, tần suất 1-2 bài mỗi tuần trong ít nhất 3 tháng để cải thiện trình độ tiếng Anh.`,
      ],
    },
    list2: {
      main: [
        `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
        `Is there a limit on the time/number of times I can book a lesson?`,
        's_pricing.qna.list2.main',
        `可预约课程的时间/次数有限吗？`,
        `可預約課程的時間／次數有限嗎？`,
        `レッスンを予約することの出来る時間／回数は決まっていますか？`,
        `Có giới hạn số buổi học có thể đặt lịch không?`,
      ],
      desc1: [
        `링글 수업권은 횟수제로 제공되며, 구매하신 수업권 횟수만큼 수강기간 내 자유롭게 수강할 수 있습니다. 일 별 또는 주 별로 수강할 수 있는 수업 횟수에 제한이 없습니다.`,
        `You can take as many lessons as the number of 1:1 credits purchased before their expiration date. There is no limit to the number of lessons you can take per day or per week.`,
        's_pricing.qna.list2.desc1',
        `1:1课程券是按次数制使用，根据购买的课程次数，可在听课期间内自由使用。可每天或每周不限次数听课。`,
        `1:1課程券採次數制，依據購買的課程券次數，您可於聽課期間內自由聽課。每日或每週可聽課的次數並無限制。`,
        `1on1レッスンチケットは回数制で提供され、購入されたレッスンチケットの回数だけ受講期間内に自由に受講することが出来ます。日別または週別で受講することが出来るレッスンの回数は制限がありません。`,
        `Bạn có thể đặt số buổi học tương đương số thẻ học bạn đã mua trước khi hết hạn. Không có giới hạn số buổi học bạn có thể học trong một ngày hay trong một tuần.`,
      ],
      desc2: [
        `정규수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Regular lessons are lessons that start 24 hours after the time of reservation, and you can freely choose a tutor and materials.`,
        's_pricing.qna.list2.desc2',
        `正规课程是在预约课程起24小时后开始的课程，可自由选择任课教师和教材。`,
        `正規課程為自預約起24小時後開始的課程，可自由選擇任課教師及教材。`,
        `レギュラーレッスンは予約時の基準から24時間以降に始まるレッスンで、チューターと教材を自由に選択することができます。`,
        `Buổi học tiêu chuẩn là buổi học bạn sẽ đặt ít nhất 24 giờ trước khi bắt đầu. Bạn tự do chọn gia sư và tài liệu.`,
      ],
      desc3: [
        `당일수업은 예약 시점 기준 24시간 이내에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Last-minute credits can be used to book lessons that start within 24 hours. You can choose any tutor or material for last-minute lessons.`,
        's_pricing.qna.list2.desc3',
        `当日课程是指以预约时间为准 24 小时内开始的课程，可自由选择任课教师和教材。`,
        `當日課程是指以預約時間爲準 24 小時內開始的課程，可自由選擇任課教師和教材。`,
        `当日レッスンは予約から24時間以内に開始するレッスンであり、チューターと教材を自由に選択することができます。`,
        `Buổi học trong ngày là buổi học bắt đầu trong vòng 24 giờ sau khi đặt và có thể tự do chọn gia sư, tài liệu.`,
      ],
      desc4: [
        `[정규수업권]은 정규수업 및 당일수업 예약에 사용할 수 있습니다. (정규 시간 : 연중무휴 KST 기준 오전 5시 ~ 오후 14시, 저녁 19시 ~ 새벽 3시)`,
        `[Regular Credits] can be used freely during regular hours and can also be used for last-minute lessons. (Regular Hours: Open all year round, 5 am to 2 pm KST, 7 pm to 3 am KST)`,
        's_pricing.qna.list2.desc4',
        `[正规课程券]可在正规时间内自由使用，也可用于当天课程预约。(正规时间 : 年中无休， 以KST为准，凌晨5点 ~ 下午14点, 晚上19点 ~ 凌晨 3点)`,
        `[正規課程券]可於正規時間內自由使用，也可用於預約當天課程。（正規時間：全年無休，韓國時間凌晨5點～下午2點、晚上7點～凌晨3點）`,
        `[レギュラーレッスンチケット]は正規時間内に自由に利用が可能で、当日にレッスンの予約をすることが出来ます。（正規時間：年中無休　午前5時～午後4時、午後7時～午前3時）`,
        `[Thẻ học tiêu chuẩn] được sử dụng để đặt học trong giờ tiêu chuẩn và cũng có thể trong cùng một ngày. (Giờ tiêu chuẩn: 05:00-14:00, 19:00-03:00 (Giờ Hàn Quốc) mọi ngày trong năm`,
      ],
      desc5: [
        `[시간한정수업권]은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
        `[Limited Window Credits] are lesson credits that can be used during limited hours. (Morning hours: 5 am to 8 am (KST), Late night hours: midnight to 3 am (KST))`,
        's_pricing.qna.list2.desc5',
        `[限时课程券]可用于指定时间段的课程券。(凌晨班: 凌晨5点~ 上午8点(KST), 深夜班: 晚上00点 ~ 凌晨3点(KST))`,
        `[時間限定課程券]可用於指定時段的課程券。（早鳥班：凌晨5點～上午8點（韓國時間）、深夜班：晚上12點～凌晨3點（韓國時間））`,
        `[時間限定レッスンチケット]は決まった時間帯に利用可能なレッスンチケットです。（早朝：午前5時～午前8時、深夜：午前0時～午前3時）`,
        `[Thẻ học giờ đặc biệt] là thẻ để đặt lịch trong khung giờ đặc biệt (Sáng sớm: 05:00-08:00; Ban đêm 00:00-03:00 theo giờ Hàn Quốc)`,
      ],
      desc6: [
        `[당일수업권]이란 예약 시점 기준 24시간 이내에 시작하는 당일수업을 예약할 수 있는 수업권으로,  [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
        `[Last-minute Credits] are lesson credits that can only be used to reserve [Last-minute Lessons] starting within 24 hours from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
        's_pricing.qna.list2.desc6',
        `[当天课程券]是可用于预约课程起24小时内开始的[当天课程]的课程券,可在 [1:1课程] → [预约] → [当天课程]中使用。`,
        `[當天課程券]僅可預約自預約起24小時內開始的[當天課程]，可於[1:1課程] → [預約] → [當天課程]使用。`,
        `[当日レッスンチケット]とは予約時の基準から24時間以内に始まる[当日レッスン]だけ予約することが出来るレッスンチケットで、[1on1レッスン]→[予約]→[当日レッスン]→で利用することが出来ます。`,
        `[Thẻ học bù] là thẻ học đặt lịch cho các buổi học trong 24 giờ tới. Đặt lịch tại [Buổi học 1:1] → [Đặt lịch] → [Buổi học 24 giờ tới].`,
      ],
    },
    list3: {
      main: [
        `수업권은 어떻게 구매하나요?`,
        `How can I purchase credits?`,
        's_pricing.qna.list3.main',
        `如何购买课程券?`,
        `如何購買課程券?`,
        `レッスンチケットの購入方法は？`,
        `Mua thẻ học như thế nào?`,
      ],
      desc1: [
        `카드 일반 결제, 실시간 계좌 이체, 가상 계좌 발급, 2개 카드 분할 결제, 카카오/네이버/삼성 페이 등의 결제 방식을 지원합니다. (월 단위 정기 결제 형식은 지원하지 않습니다.)`,
        `If you are using Korean payment, you can purchase credits through the following payment methods: credit card (split payment with two credit cards possible), real-time payment, bank transfer to a virtual account, and mobile payment services such as Kakao Pay, Naver Pay, and Samsung Pay. Please note that we do not offer a monthly subscription service.`,
        's_pricing.qna.list3.desc1',
        `如果您选择韩国付款方式，我们支持刷卡、实时转账、虚拟账户发放、两卡分开付款、Kakao/Naver/SamsungPay 等支付方式。（不支持分月定期付款形式。）`,
        `支持刷卡、實時轉賬、虛擬賬戶發放、兩卡分開付款、Kakao/Naver/SamsungPay 等支付方式。（不支持分月定期付款形式。）`,
        `クレジットカード決済、口座振替、バーチャル口座振込決済、カード2枚による分割決済、カカオ/ネイバー/サムソンペイなどの決済方式に対応しております。（月単位の長期決済方式は対応しておりません。）`,
        `Hỗ trợ thanh toán thẻ tín dụng, thanh toán qua tài khoản ảo, chia thanh toán trên nhiều thẻ, Kakao / Naver / Samsung Pay,.v.v... (Không hỗ trợ thanh toán định kỳ theo tháng.)`,
      ],
      desc2: [
        `해외 결제의 경우 카드 결제, Paypal 결제가 가능하며 모두 미국 달러로 결제됩니다.`,
        `If you are not in Korea, you can purchase credits using a credit card or via Paypal, and all payments will be processed in US dollars.`,
        's_pricing.qna.list3.desc2',
        `如果您不在韩国，支付形式包括刷卡、PayPal 支付，均以美元支付`,
        `海外支付形式包括刷卡、PayPal 支付，均以美元爲準。`,
        `海外決済の場合は、クレジットカード決済、PayPal決済が可能であり、すべて米ドル決済で行われます。`,
        `Khi thanh toán quốc tế ngoài Hàn Quốc bằng PayPal, mọi giao dịch sẽ được thanh toán bằng đồng USD.`,
      ],
    },
    list4: {
      main: [
        `수업권의 수강기간이 궁금해요.`,
        `When do Lesson Credits expire?`,
        's_pricing.qna.list4.main',
        `想了解课程券的听课期间。`,
        `我想了解課程券的聽課期間。`,
        `レッスンチケットの受講期間が気になります。`,
        `Khi nào Thẻ học hết hạn?`,
      ],
      desc1: [
        `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
        `Expiration date countdown will begin after the first lesson is completed.`,
        's_pricing.qna.list4.desc1',
        `在完成首次课程时扣除听课期间`,
        `於完成首次課程時扣除聽課期間。`,
        `受講期間は初回レッスンを完了した時点から差し引きされます。`,
        `Ngày hết hạn sẽ bắt đầu được tính khi hoàn thành buổi học đầu tiên.`,
      ],
      desc2: [
        `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다.`,
        `However, if you do not take a lesson within 1 year after your purchase, the credits will automatically expire. Therefore, lessons may begin at anytime within 1 year of your purchase.`,
        's_pricing.qna.list4.desc2',
        `但购买课程券后1年期间里未开始上课，将自动扣除听课期间。而且，可在购买课程券1年内随时开始听课。`,
        `但購買後1年期間未開始上課之情況，將自動扣除聽課期間。因此，可於購買課程券後1年內於希望的時間開始聽課。`,
        `ただ、レッスンチケットを購入後に1年になる時点でレッスンを開始しなかった場合には受講期間が自動差し引きになります。したがって、レッスンチケットを購入後1年以内だとお好きな時点でレッスンを始めることが出来ます。`,
        `Tuy nhiên, nếu bạn không học trong vòng 1 năm kể từ khi mua, thẻ học sẽ tự động hết hạn. Nên bạn phải học buổi học đầu tiên trong vòng 1 năm kể từ khi mua.`,
      ],
      desc3: [
        `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
        `Expiration dates are calculated as of the time your first lesson is completed, not reserved.`,
        's_pricing.qna.list4.desc3',
        `听课期间将以第一次课程结束时间为准，并非以预约课程的时间为准。`,
        `聽課期間係以完成聽課的時間為準，並非預約課程的時間。`,
        `受講期間の基準はレッスンを予約する時点ではなく、受講完了の時点です。`,
        `Ngày hết hạn được tính khi buổi học đầu tiên hoàn thành, không theo ngày bạn đặt học.`,
      ],
      desc4: [
        `수강기간 연장은 [프로필] > [수업권/증명서류] > [수강기간 연장]에서 포인트 사용 또는 소액 결제를 통해 가능합니다.`,
        `You can extend the expiration date at [Profile] > [Credits] > [Extend expiration dates] by using points or paying an extension fee.`,
        's_pricing.qna.list4.desc4',
        `可在[简介] > [课程券/证明资料] > [延长课程期间]通过用积分或小额付款延长课程期间。`,
        `可於[簡介] > [課程券／證明] > [延長聽課期間]透過使用點數或小額付款延長聽課期間。`,
        `有効期限の延長は[プロフィール]＞[レッスンチケット／証明書類]＞[受講期間の延長]からポイントの使用又は少額決済を通して可能です。`,
        `Bạn có thể gia hạn ngày hết hạn tại  [Profile] → [Thẻ học] → [Gia hạn thẻ học] bằng cách sử dụng điểm hoặc trả khoản phí nhỏ.`,
      ],
      desc5: [
        `실결제가 20만원 이상의 수업권을 재구매하시면, 보유한 수업권의 처음 수강기간을 추가로 연장할 수 있는 [수강기간 연장 쿠폰]이 발급됩니다.`,
        `You will be issued an [Extension Coupon] for extending the lesson days on your existing credits upon repurchasing a credit package worth ₩200,000 or more.`,
        's_pricing.qna.list4.desc5',
        `【限于优惠活动】如果您购买实际结算金额超过 18 万韩元的课程券，可获得延长现有课程券听课时间的优惠券。`,
        `【限於優惠活動】 如果您購買實際結算金額超過 18 萬韓元的課程券，可獲得延長現有課程券聽課時間的優惠券。`,
        `[プロモーション限定]決済額が18万ウォン以上のレッスンチケットを購入すると既に保有しているレッスンチケットの受講期間を追加で延長できるクーポンが発行されます。`,
        `[Event có hạn] Khi thanh toán thẻ học từ 180 nghìn won trở lên, bạn sẽ nhận được Coupon kéo dài thời gian học của thẻ học đang sở hữu.`,
      ],
    },
    list5: {
      main: [
        `수업 취소 정책이 궁금해요.`,
        `What is the cancellation policy?`,
        's_pricing.qna.list5.main',
        `想了解课程取消政策。`,
        `我想了解課程取消政策。`,
        `レッスン取り消しに対する政策が気になります。`,
        `Chính sách hủy thể nào?`,
      ],
      desc1: [
        `수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
        `If you cancel 24 hours before the start of the lesson, the credit will be automatically restored.`,
        's_pricing.qna.list5.desc1',
        `在课程开始24小时前取消课程，可自动恢复课程券。`,
        `於課程開始24小時以前取消時，自動復原該課程券。`,
        `レッスン開始基準24時間以前に取り消しした場合、該当のレッスンチケットは自動復元されます。`,
        `Nếu bạn hủy trên 24 giờ trước khi buổi học bắt đầu, thẻ học sẽ được khôi phục tự động.`,
      ],
      desc2: [
        `수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.`,
        `If you cancel 24 to 2 hours before the start of the lesson, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will be issued.`,
        's_pricing.qna.list5.desc2',
        `在课程开始24小时~2小时前取消课程，将扣除课程券，并发送[当天课程券]。`,
        `於課程開始24小時～2小時前取消時，扣除該課程券，並發送[當天課程券]。`,
        `レッスン開始基準24時間～2時間以前に取り消しした場合、該当のレッスンチケットを差し引きした後[当日レッスンチケット]が支給されます。`,
        `Nếu hủy trong khoảng 2-24 giờ trước buổi học, thẻ học sẽ bị trừ và thay thể bằng Thẻ học bù.`,
      ],
      desc3: [
        `수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
        `If you cancel within 2 hours before the lesson starts, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will not be issued.`,
        's_pricing.qna.list5.desc3',
        `在课程开始2小时内取消课程，将扣除课程券，并且不发送[当天课程券]。`,
        `於課程開始前2小時以內取消時，扣除該課程券，並不發送[當天課程券]。`,
        `レッスンが始まる2時間以内に取り消しした場合、該当レッスンチケットは差し引きになり、[当日レッスンチケット]は支給されません。`,
        `Nếu bạn hủy ít hơn 2 giờ trước buổi học, thẻ học của bạn sẽ bị trừ.`,
      ],
    },
    list6: {
      main: [
        `수업권 환불 정책이 궁금해요.`,
        `How can I get a refund?`,
        's_pricing.qna.list6.main',
        `想了解课程券退款政策。`,
        `我想了解課程券退款政策。`,
        `レッスンチケットの払い戻し政策が気になります。`,
        `Làm sao để được hoàn tiền?`,
      ],
      desc1: [
        `수강기간 차감 전, 수업권 사용 이력이 없는 경우 전액 환불이 가능합니다. 무료 수업권 및 체험 수업을 포함한 첫 수업 1회 진행 후 불만족 시에도 전액 환불이 가능합니다.`,
        `Credit packages are fully refundable before their lesson days have started to diminish if they have not been used. Full refunds are also available if you are unsatisfied with our service after your first lesson, including lessons taken using free credits and trial lessons.`,
        's_pricing.qna.list6.desc1',
        `如未使用课程券，可全额退款。`,
        `如未曾使用課程券，可全額退款。`,
        `未使用のレッスンチケットは全額返金可能です。`,
        `Thẻ học chưa sử dụng sẽ được hoàn tiền.`,
      ],
      desc2: [
        <>
          수강기간 차감 후, 수강기간 또는 수업 횟수가 절반 이하 남은 수업권은 환불이 불가합니다. 그 외 사용 이력이 있는
          수업권은{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            이용약관 13조 및 15조
          </a>
          에 의거하여 부분 환불 처리합니다.{' '}
        </>,
        <>
          Credit packages with less than half of remaining in lesson days or lessons are not refundable. Any other
          credit packages that have been used may be eligible for partial refunds based on{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            Articles 13 and 15 of Ringle's Terms of Service.
          </a>
        </>,
        's_pricing.qna.list6.desc2',
        `课程期间或课程次数少于一半的课程券、曾延长或听课期间的课程券、以发放证明的课程券恕不退款。`,
        `聽課期間或課程次數少於一半的課程券、曾延長聽課期間的課程券、已核發證明的課程券恕不退款。`,
        `受講期間又はレッスンの回数が半分以下のレッスンチケットが残っている場合、もしくは受講期間の延長履歴があるレッスンチケットを所持している場合に証明書を発行したレッスンチケットは払い戻しが出来ません。`,
        `Không thể hoàn tiền cho các thẻ học đã qua hơn một nửa thời hạn sử dụng, hoặc những gói học đã sử dụng hơn một nửa/ những buổi học đã được cấp giấy chứng nhận.`,
      ],
      desc3: [
        `수강기간 연장 이력이 있는 수업권 또는 증명서를 발급한 수업권은 환불이 불가합니다.`,
        `Credits for which lesson days were extended or certificate(s) were issued are not refundable.`,
        's_pricing.qna.list6.desc3',
        `【限于优惠活动】学完前 2 次课程后，如果不满意，可全额退款。（包括免费课程券和体验课程）`,
        `【限於優惠活動】 學完前 2 次課程後，如果不滿意，可全額退款。（包括免費課程券和體驗課程）`,
        `[プロモーション限定]初回レッスンを2回受講後にご満足頂けなかった場合、全額返金が可能です。（無料レッスンチケットおよび体験レッスンを含む）`,
        `[Event có hạn] Hoàn tiền 100% nếu không hài lòng sau 2 buổi học đầu tiên (Gồm cả thẻ học miễn phí và buổi học trải nghiệm)`,
      ],
      desc4: [
        `환불 금액 산정 시, 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료가 차감될 수 있습니다.`,
        `In the case of refunds, any transaction fees incurred from your bank, credit card or Paypal will be deducted from the refund amount.`,
        's_pricing.qna.list6.desc4',
        `退款时，可能会扣除退还银行、信用卡公司、Paypal等支付方式的手续费。`,
        `退款金額可能會扣除銀行、信用卡公司、Paypal等支付方式的手續費。`,
        `払い戻し金額が算定時、銀行やカード会社、Paypalなどの決済手段に対する手数料が差し引かれた金額が返金されます。`,
        `Với trường hợp hoàn tiền, phí giao dịch sẽ bị trừ vào khoản tiền được hoàn trả.`,
      ],
      desc5: [
        <>
          그 외 사용 이력이 있는 수업권은{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            이용약관 13조 및 15조
          </a>
          에 의거하여 환불 처리합니다.{' '}
        </>,
        <>
          Used credits will be refunded according to{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-purple-500" target="_blank">
            articles 13 and 15 of your terms of service.
          </a>
        </>,
        's_pricing.qna.list6.desc5',
        `已有曾使用过的课程券，退款时将按照使用条款中的(13条, 15条)进行处理!`,
        `其他曾使用過的課程券將依據使用條款第13條及第15條進行退款。（需插入超連結 https://www.ringleplus.com/ko/student/policy/terms）`,
        `その他の受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づき、処理されます。 https://www.ringleplus.com/ko/student/policy/terms`,
        `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
      ],
    },
    list7: {
      main: [
        `수업 증명서를 발급 받을 수 있나요?`,
        `Can I get lesson certificates?`,
        's_pricing.qna.list7.main',
        `如何申请上课证明？`,
        `如何核發課程證明呢？`,
        `レッスンの証明書の発行は出来ますか？`,
        `Làm sao để nhận chứng nhận?`,
      ],
      desc1: [
        <>
          증명서 발급은{' '}
          <a
            className="text-purple-500"
            target="_blank"
            href="https://www.ringleplus.com/ko/student/portal/mypage/credit"
          >
            [프로필] {'>'} [수업권/증명서류] {'>'} [증명서 발급]
          </a>
          에서 수강증, 출석증, 또는 영수증을 직접 발급하실 수 있습니다.{' '}
        </>,
        `To get certificates, go to [Profile] > [Credits] > [Issue Certificate] to directly issue a lesson certificate, attendance certificate, or receipt. (Link https://www.ringleplus.com/ko/student/portal/mypage/credit)`,
        's_pricing.qna.list7.desc1',
        `[可在[简介] > [课程券/证明资料] > [证明书发放]中亲自申请发放上课证明、出席证明或收据。(链接 https://www.ringleplus.com/ko/student/portal/mypage/credit)`,
        `[可於[簡介] > [課程券／證明] > [核發證明]親自申請核發上課證明、出席證明或收據。（連結 https://www.ringleplus.com/ko/student/portal/mypage/credit）`,
        `受講したレッスンの証明書類はいつでも発行可能です。受講証、出席証、領収証 (英語)`,
        `Để nhận chứng nhận vào phần [Profile] > [Thẻ học/Chứng nhận] > [Xuất chứng nhận] để nhận chứng nhận buổi học, chứng nhận tham gia hoặc hoá đơn.`,
      ],
      desc2: [
        `수강증 및 출석증은 월별로 나누어 발급하실 수 있습니다.`,
        `Lesson certificates and attendance certificates can be downloaded on a monthly basis.`,
        's_pricing.qna.list7.desc2',
        `可按月发放上课证明及出席证明。`,
        `可按月核發上課證明及出席證明。`,
        `受講証及び出席証は月別で分けて発行することが出来ます。`,
        `Chứng nhận buổi học và chứng nhận tham gia có thể xuất mỗi tháng.`,
      ],
      desc3: [
        `증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 발급하실 수 있습니다.`,
        `Certificates will only be issued for purchased credits and regular lessons.`,
        's_pricing.qna.list7.desc3',
        `仅针对实际付款的课程券及正常进行的课程发放证明。`,
        `僅可針對實際付款的課程券及正常進行的課程核發證明。`,
        `受講証は実際に決済したレッスンチケット及び正常にレッスンをしたもののみ発行することが出来ます。`,
        `Chứng nhận chĩ xuất cho buổi học tiêu chuẩn và các thẻ học đã thanh toán.`,
      ],
      text_btn: [
        `증명서류 예시 상세보기`,
        `See detailed preview of certificate`,
        's_pricing.qna.list7.text_btn',
        `证明资料示例详情`,
        `詳細確認證明範例`,
        `証明書類の例示を見る`,
        `Xem chi tiết chứng nhận`,
      ],
    },
  },
  see_detail: {
    credit_info: [
      `수업권 정보`,
      `Credits detail`,
      's_pricing.see_detail.credit_info',
      `课程券详情`,
      `課程券詳情`,
      `チケット料金内訳`,
      `Chi tiết thẻ học`,
    ],
    total_credits: [
      `최종 수업권 횟수`,
      `Total credits`,
      's_pricing.see_detail.total_credits',
      `最终课程券次数`,
      `最終課程券次數`,
      `合計レッスン回数`,
      `Tổng số buổi học`,
    ],
    regular: [
      `정규수업권`,
      `Regular Credits`,
      's_pricing.see_detail.regular',
      `我的英语实时可视化`,
      `即時將我的英語視覺化`,
      `自分の英語がその場で校正される`,
      `Xem phần sửa lỗi, tài liệu học và gia sư cùng lúc`,
    ],
    sameday: [
      `당일수업권`,
      `Last-minute Credits`,
      's_pricing.see_detail.sameday',
      `24小时以内的补充课程`,
      `24小時以內的補充課程`,
      `24時間以内の補充レッスン`,
      `Buổi học trong 24 giờ tới`,
    ],
    credit_price: [
      `가격 상세 내역`,
      `Price detail`,
      's_pricing.see_detail.credit_price',
      `价格详情`,
      `價格詳情`,
      `価格の詳細`,
      `Chi tiết giá cả`,
    ],
    normal_price: [
      `정가`,
      `Regular price`,
      's_pricing.see_detail.normal_price',
      `定价`,
      `定價`,
      `通常価格`,
      `Học phí tiêu chuẩn`,
    ],
    discount_price: [
      `총 할인 금액`,
      `Total discount`,
      's_pricing.see_detail.discount_price',
      `总优惠金额`,
      `總折扣金額`,
      `割引分合計`,
      `Tổng giảm giá`,
    ],
    discount_promo: [
      `프로모션 할인`,
      `Promo discount`,
      's_pricing.see_detail.discount_promo',
      `促销折扣`,
      `促銷折扣`,
      `プロモーション割引`,
      `Khuyến mãi giảm giá`,
    ],
    discount_secret: [
      `시크릿 쿠폰 할인`,
      `Secret offer`,
      's_pricing.see_detail.discount_secret',
      `Secret offer`,
      `Secret offer`,
      `Secret offer`,
      `Secret offer`,
    ],
    discount_welcome: [
      `웰컴 쿠폰 할인`,
      `Welcome coupon`,
      's_pricing.see_detail.discount_welcome',
      `Welcome优惠券`,
      `Welcome優惠券`,
      `ウェルカムクーポン`,
      `Coupon chào mừng`,
    ],
    final_price: [
      `최종 결제 금액`,
      `Your total`,
      's_pricing.see_detail.final_price',
      `实际付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      `Tổng cộng`,
    ],
    unit_price: [
      `실질 회당 단가`,
      `Final price per credit`,
      's_pricing.see_detail.unit_price',
      `实际课程单价`,
      `實際單次單價`,
      `1レッスンあたりの単価`,
      `Giá cuối cho một buổi học`,
    ],
    my_points: [
      `사용 가능 포인트`,
      `My points`,
      's_pricing.see_detail.my_points',
      `持有积分`,
      `持有點數`,
      `保有ポイント`,
      `Điểm của tôi`,
    ],
    discount_package: [
      `패키지 할인`,
      `Package discount`,
      's_pricing.see_detail.discount_package',
      `礼包折扣`,
      `禮包折扣`,
      `パッケージ割引`,
      `Giảm giá gói hàng`,
    ],
    discount_first: [
      `첫 구매 혜택`,
      `First purchase benefit`,
      's_pricing.see_detail.discount_first',
      `首次购买优惠`,
      `首次購買折扣優惠`,
      `初回購入割引特典`,
      `Giảm giá cho học viên mới`,
    ],
    discount_re: [
      `재구매 쿠폰 할인`,
      `Next purchase coupon`,
      's_pricing.see_detail.discount_re',
      `下次购买优惠券`,
      `下次購買優惠券`,
      `次の購入クーポン`,
      `Phiếu mua hàng tiếp theo`,
    ],
    discount_student_2023: [
      `대학생 할인`,
      `Student discount`,
      's_pricing.see_detail.discount_student_2023',
      `Student discount`,
      `Student discount`,
      `大学生割引`,
      `Student discount`,
    ],
  },
  label_first: [`첫 구매`, `First`, 's_pricing.label_first', `First`, `First`, `First`, `First`],
  label_size: [`장기권`, `Size`, 's_pricing.label_size', `Size`, `Size`, `Size`, `Size`],
  total_sale_rate: [
    `총 할인율`,
    `Total discount`,
    's_pricing.total_sale_rate',
    `总贴现率`,
    `總貼現率`,
    `総割引率`,
    `chiết khấu`,
  ],
  by_month: [`월`, `mo`, 's_pricing.by_month', `月`, `月`, `月`, `tháng`],
  promo_best_price: [
    `프로모션 최대 할인 적용`,
    `Biggest discount appplied`,
    's_pricing.promo_best_price',
    `Biggest discount appplied`,
    `Biggest discount appplied`,
    `Biggest discount appplied`,
    `Biggest discount appplied`,
  ],
  welcome_best_price: [
    `웰컴 쿠폰 최대 혜택`,
    `Welcome Coupon`,
    's_pricing.welcome_best_price',
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
  ],
  first_price: [
    `첫 구매 혜택`,
    `First Purchase`,
    's_pricing.first_price',
    `First Purchase`,
    `First Purchase`,
    `First Purchase`,
    `First Purchase`,
  ],
  next_best_price: [
    `재구매 쿠폰 혜택`,
    `Next Purchase Coupon`,
    's_pricing.next_best_price',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  total_best_price: [
    `최대 혜택 적용가`,
    `Final dicounted price`,
    's_pricing.total_best_price',
    `最终价格`,
    `最終價格`,
    `最終価格`,
    `giá niêm yết`,
  ],
  card_recommended: [
    `추천 수업권`,
    `Recommended`,
    's_pricing.card_recommended',
    `Recommended`,
    `Recommended`,
    `Recommended`,
    `Recommended`,
  ],
  season_best_price: [
    `8월･9월 한정 수업권`,
    `Seasonal offer`,
    's_pricing.season_best_price',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  label_season: [`기간한정`, `Season`, 's_pricing.label_season', `Season`, `Season`, `Season`, `Season`],
  card_size: [
    `장기권 혜택`,
    `Size Benefits`,
    's_pricing.card_size',
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
    `Size Benefits`,
  ],
  season_discount: [
    `8월･9월 한정 특가 할인`,
    `Seasonal offer`,
    's_pricing.season_discount',
    `追加优惠`,
    `額外折扣`,
    `追加割引`,
    `Ưu đãi bổ sung`,
  ],
  notice_usd: [
    `결제는 미국 달러로 진행됩니다. 괄호안의 통화별 금액은 당일 환율이 반영됩니다.`,
    <>
      Payments will be processed in USD.
      <br />
      The price shown in your local currency reflects today's exchange rate.
    </>,
    's_pricing.notice_usd',
    `支付方式以美元为准。括号内的各货币金额以当天汇率为准。`,
    `付款將以美金支付。括號內的各貨幣金額以當天匯率計算。`,
    `米ドルでの決済になります。カッコ内金額は当日のレートが適用されます。`,
    `Được thanh toán bằng USD. Số tiền theo tiền tệ trong ngoặc là tỷ giá hối đoái hôm nay.`,
  ],
  notice_exchange: [
    `실결제 금액은 카드사 적용 환율 및 수수료에 따라 차이가 발생할 수 있습니다.`,
    <>
      The final transaction price may differ due to the currency exchange rate
      <br />
      and processing fee applied by your credit card company.
    </>,
    's_pricing.notice_exchange',
    `根据信用卡公司的适用汇率和手续费的不同，实际结算金额可能会有所差异。`,
    `根據信用卡公司的適用匯率和手續費的不同，實際結算金額可能會有所差異。`,
    <>決済額はカード会社が適用するレートや手数料によって差が生じます。</>,
    <>Số tiền thanh toán thực tế có thể khác nhau tùy theo tỷ giá hối đoái và lệ phí của công ty thẻ.</>,
  ],
  newbie_best_price: [
    `신규회원 추가 수업권 혜택`,
    `For Newcomers`,
    's_pricing.newbie_best_price',
    `新注册会员额外课程券优惠`,
    `新註冊會員額外課程券優惠`,
    `新規加入者特典`,
    `Ưu đãi thêm thẻ học chào mừng`,
  ],
  label_newbie: [`신규회원`, `Newcomer`, 's_pricing.label_newbie', `Newcomer`, `Newcomer`, `Newcomer`, `Newcomer`],
  benefit_newbie: [
    `0만원 추가 할인 적용`,
    `Additional ₩0 off`,
    's_pricing.benefit_newbie',
    `Additional ₩0 off`,
    `Additional ₩0 off`,
    `Additional ₩0 off`,
    `Additional ₩0 off`,
  ],
  label_trial_event: [
    `체험 수업 이벤트`,
    `Special Trial Lesson`,
    's_pricing.label_trial_event',
    `Special Trial Lesson`,
    `Special Trial Lesson`,
    `Special Trial Lesson`,
    `Special Trial Lesson`,
  ],
  newbie_discount: [
    `체험 특가 할인`,
    `Trial Discount`,
    's_pricing.newbie_discount',
    `Trial Discount`,
    `Trial Discount`,
    `Trial Discount`,
    `Trial Discount`,
  ],
  label_trial: [`체험수업`, `Trial`, 's_pricing.label_trial', `Trial`, `Trial`, `Trial`, `Trial`],
  benefit_trial: [
    `1회 수업권 95% 할인`,
    `95% discount`,
    's_pricing.benefit_trial',
    `95% discount`,
    `95% discount`,
    `95% discount`,
    `95% discount`,
  ],
  total_discount: [
    `총 할인율`,
    `Total discount`,
    's_pricing.total_discount',
    `总贴现率`,
    `總貼現率`,
    `総割引率`,
    `chiết khấu`,
  ],
  welcome_new_comer_best_price: [
    `신규 회원 최대 혜택`,
    `Newcomer Offer`,
    's_pricing.welcome_new_comer_best_price',
    `Newcomer Offer`,
    `Newcomer Offer`,
    `Newcomer Offer`,
    `Newcomer Offer`,
  ],
  season_best_price_2: [
    `10월･11월 한정 판매`,
    `Seasonal offer`,
    's_pricing.season_best_price_2',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  season_discount_2: [
    `10월･11월 한정 특가 할인`,
    `Seasonal offer`,
    's_pricing.season_discount_2',
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
    `Seasonal offer`,
  ],
  note_usd: {
    purchase_now: [
      `최종 결제는 미국 달러로 결제됩니다.`,
      `Payments will be processed in USD.`,
      's_pricing.note_usd.purchase_now',
      `支付方式以美元为准。`,
      `付款將以美金支付。`,
      `米ドルでの決済になります。`,
      `Được thanh toán bằng USD.`,
    ],
  },
  label_secret: [`시크릿쿠폰`, `Secret`, 's_pricing.label_secret', `Secret`, `Secret`, `Secret`, `Secret`],
  secret: [
    `시크릿 쿠폰 할인`,
    `Secret Offer`,
    's_pricing.secret',
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
  ],
  benefit_list_secret: (amount) => [
    `${amount} 추가 할인 적용`,
    `Extra ${amount} off`,
    's_pricing.benefit_list_secret',
    `Extra ${amount} off`,
    `Extra ${amount} off`,
    `Extra ${amount} off`,
    `Extra ${amount} off`,
  ],
  input_company_code_verified: [
    `등록한 기업 맞춤 /학생 할인 수업권을 확인해 보세요.`,
    `Check out your B2B Partner credits/Student discount.`,
    's_pricing.input_company_code_verified',
    `请查看所注册企业的定制课程券。`,
    `請查看所註冊企業的定製課程券。`,
    `登録した企業レッスンチケットを確認してください。`,
    `Hãy xem buổi học phù hợp với doanh nghiệp đã đăng ký.`,
  ],
  verify_code: [`인증`, `Verify`, 's_pricing.verify_code', `验证`, `验证`, `Verify`, `Verify`],
  sameday_benefit: [
    `당일수업권 혜택`,
    `Last-minute Credits`,
    's_pricing.sameday_benefit',
    `当日课程券优惠`,
    `Last-minute Credits`,
    `補充レッスンチケット`,
    `Thẻ học bù`,
  ],
  promo_23_1st: {
    earlybirdbanner: [
      `지금 보고 있는 얼리버드 혜택이 곧 사라져요!`,
      `The Early Bird Offer will disappear soon!`,
      's_pricing.promo_23_1st.earlybirdbanner',
      `现在看到的早鸟特价优惠即将结束!`,
      `The Early Bird Offer will disappear soon!`,
      `The Early Bird Offer will disappear soon!`,
      `The Early Bird Offer will disappear soon!`,
    ],
    newcomerbanner: [
      `지금 적용된 신규회원 혜택,  nn:nn:nn 뒤에 사라져요!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      's_pricing.promo_23_1st.newcomerbanner',
      `此次新入会员优惠将于 nn:nn:nn 后结束！`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
    ],
    datechip_early: [
      `얼리버드 혜택`,
      `Early Bird`,
      's_pricing.promo_23_1st.datechip_early',
      `早鸟特价优惠`,
      `Early Bird`,
      `Early Bird`,
      `Early Bird`,
    ],
    datechip_regular: [
      `신년 프로모션`,
      `New Year Promotion`,
      's_pricing.promo_23_1st.datechip_regular',
      `正式活动`,
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
    ],
    date_early: [
      `22.12.23 (금) - 23.02.02 (목)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_early',
      `2022.12.23(周五)~2023.01.05(周四)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
    ],
    date_regular: [
      `23.01.06 (금) - 23.02.02 (목)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_regular',
      `2023.01.06(周五)~2023.02.02(周四)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    ],
    herotitle: [
      `가장 저렴하게 시작하는 23년 영어 정복`,
      `Study English at the Lowest Prices`,
      's_pricing.promo_23_1st.herotitle',
      `以全年最低的价格购买的机会`,
      `Study English at the Lowest Prices`,
      `Study English at the Lowest Prices`,
      `Study English at the Lowest Prices`,
    ],
    herosubtitle: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해 링글이 준비했어요.`,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle',
      `为了不能再推迟英语学习计划的你，我们准备好了。`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    herocard1_subtext: [
      `프로모션 한정 할인`,
      `New Year Promotion Only`,
      's_pricing.promo_23_1st.herocard1_subtext',
      `早鸟特价优惠`,
      `New Year Promotion Only`,
      `New Year Promotion Only`,
      `New Year Promotion Only`,
    ],
    herocard1_text: [
      `최대 38% 할인`,
      `Up to 38% off`,
      's_pricing.promo_23_1st.herocard1_text',
      `Up to 38% off`,
      `Up to 38% off`,
      `Up to 38% off`,
      `Up to 38% off`,
    ],
    n_herocard2_subtext: [
      `3시간 한정 신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.n_herocard2_subtext',
      `仅限于新入会员`,
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
    ],
    n_herocard2_text: [
      `수업권 추가 증정`,
      `Bonus Credits`,
      's_pricing.promo_23_1st.n_herocard2_text',
      `额外赠送课程券`,
      `Bonus Credits`,
      `Bonus Credits`,
      `Bonus Credits`,
    ],
    herocard2_subtext: [
      `보스턴&뉴욕 챌린지 혜택`,
      `Boston & NYC Challenge`,
      's_pricing.promo_23_1st.herocard2_subtext',
      `波士顿 & 纽约挑战赛优惠`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    herocard2_text: [
      `수업권 추가 증정`,
      `Bonus Credits`,
      's_pricing.promo_23_1st.herocard2_text',
      `额外赠送课程券`,
      `Bonus Credits`,
      `Bonus Credits`,
      `Bonus Credits`,
    ],
    herocard3_subtext: [
      `프로모션 구매자 한정`,
      `For purchasers only`,
      's_pricing.promo_23_1st.herocard3_subtext',
      `限于优惠活动购买者`,
      `For purchasers only`,
      `For purchasers only`,
      `For purchasers only`,
    ],
    herocard3_text: [
      `스터디 클럽`,
      `Join Study Club`,
      's_pricing.promo_23_1st.herocard3_text',
      `学习俱乐部`,
      `Join Study Club`,
      `Join Study Club`,
      `Join Study Club`,
    ],
    n_herorow1: [
      <>
        첫 2회 수업
        <br />
        불만족 시 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      's_pricing.promo_23_1st.n_herorow1',
      <>
        前 2 次课程
        <br />
        如果不满意 100% 退款
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
    ],
    herorow1: [
      <>
        수업권 구매 시<br />
        수강기간 연장 쿠폰 증정
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      's_pricing.promo_23_1st.herorow1',
      <>
        购买课程券时
        <br />
        赠送延长听课时间优惠券
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
    ],
    herorow2: [
      <>
        첫 수업 시작 전<br />
        수강 기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.promo_23_1st.herorow2',
      <>
        第一节课开始之前
        <br />
        不扣除听课时间
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
    ],
    herorow3: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      's_pricing.promo_23_1st.herorow3',
      <>
        截止到课程前一天
        <br />
        可变更日程
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
    ],
    pricecard_n_chip: [
      `시간 한정 신규회원 혜택`,
      `Bonus Credits for Newcomers`,
      's_pricing.promo_23_1st.pricecard_n_chip',
      `新入会员课程券额外优惠`,
      `Bonus Credits for Newcomers`,
      `Bonus Credits for Newcomers`,
      `Bonus Credits for Newcomers`,
    ],
    pricecard_chip: [
      `당일수업권 혜택`,
      `Last-minute Credits`,
      's_pricing.promo_23_1st.pricecard_chip',
      `当日课程券优惠`,
      `Last-minute Credits`,
      `Last-minute Credits`,
      `Last-minute Credits`,
    ],
    cta_early: [
      `얼리버드 프로모션 마감`,
      `Early Bird Promotion`,
      's_pricing.promo_23_1st.cta_early',
      `早鸟特价活动`,
      `Early Bird Promotion`,
      `Early Bird Promotion`,
      `Early Bird Promotion`,
    ],
    cta_regular: [
      `정규 프로모션 마감`,
      `New Year Promotion`,
      's_pricing.promo_23_1st.cta_regular',
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
      `New Year Promotion`,
    ],
    mo_herocard1_text: [
      `최대 38% 할인`,
      `Up to 38% off`,
      's_pricing.promo_23_1st.mo_herocard1_text',
      `Up to 38% off`,
      `Up to 38% off`,
      `Up to 38% off`,
      `Up to 38% off`,
    ],
    mo_n_herocard2_subtext: [
      `신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.mo_n_herocard2_subtext',
      `仅限于新入会员`,
      `For Newcomers Only`,
      `For Newcomers Only`,
      `For Newcomers Only`,
    ],
    datechip_regular_mW: [
      `신년 프로모션`,
      `Promotion`,
      's_pricing.promo_23_1st.datechip_regular_mW',
      `正式活动`,
      `Promotion`,
      `Promotion`,
      `Promotion`,
    ],
    herotitle_mW: [
      <>
        가장 저렴하게 시작하는
        <br />
        23년 영어 정복
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      's_pricing.promo_23_1st.herotitle_mW',
      `以全年最低的价格购买的机会`,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
    ],
    herosubtitle_mW: [
      <>
        더 이상 영어 공부를 미룰 수 없는 당신을 위해
        <br />
        링글이 준비했어요.
      </>,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle_mW',
      `为了不能再推迟英语学习计划的你，我们准备好了。`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    main_logout1: [
      `최대 25만원 추가 할인에`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_logout1',
      `Up to $192 extra off`,
      `Up to $192 extra off`,
      `Up to $192 extra off`,
      `Up to $192 extra off`,
    ],
    main_logout2: [
      <>
        신규회원 한정
        <br />
        수업권 증정 혜택까지
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      's_pricing.promo_23_1st.main_logout2',
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
    ],
    main_countdown1: [
      `신년 프로모션 종료까지`,
      `Promotion Ends in`,
      's_pricing.promo_23_1st.main_countdown1',
      `Promotion Ends in`,
      `Promotion Ends in`,
      `Promotion Ends in`,
      `Promotion Ends in`,
    ],
    main_countdown2: [
      `일 남았어요`,
      `Days left`,
      's_pricing.promo_23_1st.main_countdown2',
      `Days left`,
      `Days left`,
      `Days left`,
      `Days left`,
    ],
    main_nocoupon1: [
      `최대 25만원 추가 할인`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_nocoupon1',
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
      `Up to $192 Extra Off`,
    ],
    main_nocoupon2: [
      <>
        신년 프로모션 혜택이
        <br />곧 사라져요.
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      's_pricing.promo_23_1st.main_nocoupon2',
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
    ],
  },
  cta_empty: [
    `수업권을 선택해 주세요.`,
    `Please select credit type.`,
    's_pricing.cta_empty',
    `请选择课程券`,
    `Please select credit type.`,
    `Please select credit type.`,
    `Please select credit type.`,
  ],
  modal_company_code1: [
    `로그인이 필요합니다.`,
    `You must log in to proceed.`,
    's_pricing.modal_company_code1',
    `You must log in to proceed.`,
    `You must log in to proceed.`,
    `You must log in to proceed.`,
    `You must log in to proceed.`,
  ],
  modal_company_code2: [
    `기업 맞춤 코드를 입력하시려면 로그인을 먼저 진행해주세요.`,
    `Please log in first to enter the B2B code.`,
    's_pricing.modal_company_code2',
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
    `Please log in first to enter the B2B code.`,
  ],
  btn_company_code: [`로그인`, `Log in`, 's_pricing.btn_company_code', `Log in`, `Log in`, `Log in`, `Log in`],
  early_discount: [
    `기간 한정 할인율`,
    `Promotion Offer`,
    's_pricing.early_discount',
    `Promotion Offer`,
    `Promotion Offer`,
    `Promotion Offer`,
    `Promotion Offer`,
  ],
  card_newbie: [
    `신규회원 추천`,
    `For Newcomers`,
    's_pricing.card_newbie',
    `For Newcomers`,
    `For Newcomers`,
    `For Newcomers`,
    `For Newcomers`,
  ],
  newbie_best_price2: [
    <>추가 수업권 혜택 마감</>,
    <>Newcomers Benefit</>,
    's_pricing.newbie_best_price2',
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
    <>Newcomers Benefit</>,
  ],
  hour_timer: {
    label: (hour, min, sec) => [
      <>
        {hour}:{min}:{sec} 남음
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      's_pricing.hour_timer.label',
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
    ],
  },
  card_promotion_only: [
    `신년 프로모션 한정`,
    `Promotion Only`,
    's_pricing.card_promotion_only',
    `Promotion Only`,
    `Promotion Only`,
    `Promotion Only`,
    `Promotion Only`,
  ],
  popupcheck: [
    `브라우저 팝업이 차단된 경우 결제가 진행되지 않을 수 있습니다. 팝업 설정을 미리 확인해 주세요.`,
    `You may not be able to proceed with the purchase if pop-ups are blocked. Please make sure that pop-ups are enabled beforehand.`,
    's_pricing.popupcheck',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_subtitle_1: [
    `하나의 주제에 대해 심도있게`,
    `Topical & In-depth`,
    's_pricing.pricing_subtitle_1',
    `一个主题深度学习`,
    `一個主題深度學習`,
    `一つのテーマに関しより深く`,
    `chủ đề, chuyên sâu`,
  ],
  pricing_subtitle_2: [
    `40분 수업`,
    `40-min lessons`,
    's_pricing.pricing_subtitle_2',
    `40分钟课程`,
    `40分鐘課程`,
    `40分授業`,
    `các tiết học 40 phút`,
  ],
  pricing_subtitle_3: [
    `바쁜 일상 속 짧지만 확실하게`,
    `Short & Compact`,
    's_pricing.pricing_subtitle_3',
    `在繁忙的日常效率学习`,
    `在繁忙的日常效率學習`,
    `忙しい日常、短いけど確実に`,
    `ngắn gọn, súc tích`,
  ],
  pricing_subtitle_4: [
    `20분 수업`,
    `20-min lessons`,
    's_pricing.pricing_subtitle_4',
    `20分钟课程`,
    `20分鐘課程`,
    `20分授業`,
    `các tiết học 20 phút`,
  ],
  point_message: [
    `보유 포인트를 사용하면 할인된 가격으로 구매할 수 있어요.`,
    `Use your points toward this purchase.`,
    's_pricing.point_message',
    `使用持有点數取得優惠价格`,
    `使用持有點數獲得優惠價格`,
    `保有ポイントを使えば割引価格で購入できます。`,
    `Nếu sử dụng điểm tích lũy, bạn có thể được giảm giá.`,
  ],
  total: [
    `최종 결제 금액`,
    `Your total`,
    's_pricing.total',
    `实际付款金额`,
    `最終付款金額`,
    `お支払い総額`,
    `Tổng cộng`,
  ],
  next_purchase: {
    label: (benefit) => [
      <>재구매 쿠폰 ${benefit} 추가 할인</>,
      <>Extra ${benefit} off</>,
      's_pricing.next_purchase.label',
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
    ],
  },
  welcome: {
    label: (benefit) => [
      <>신규회원 {benefit}원 추가 할인</>,
      <>Extra ${benefit} off</>,
      's_pricing.welcome.label',
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
      <>Extra ${benefit} off</>,
    ],
  },
  promotion_dday: {
    label: [
      <>신년 프로모션 마감까지 </>,
      <>Promotion ends in </>,
      's_pricing.promotion_dday.label',
      <>Promotion ends in </>,
      <>Promotion ends in </>,
      <>Promotion ends in </>,
      <>Promotion ends in </>,
    ],
  },
  card_recommend: [
    `추천 수업권`,
    `Recommended`,
    's_pricing.card_recommend',
    `Recommended`,
    `Recommended`,
    `紹介レッスンチケット`,
    `Recommended`,
  ],
  student_price: [
    `학생 할인 수업권`,
    `Student discount`,
    's_pricing.student_price',
    `Student discount`,
    `Student discount`,
    `学生割引レッスンチケット`,
    `Student discount`,
  ],
  student_code: [
    `학생 할인 코드`,
    `Student discount code`,
    's_pricing.student_code',
    `Student discount code`,
    `Student discount code`,
    `学生割引コード`,
    `Student discount code`,
  ],
  input_student_code: [
    <>
      학생 할인 코드를 입력해보세요.
      <br />
      궁금한 점이 있다면 아래 문의하기로 알려주세요.
    </>,
    `Please enter student discount code.`,
    's_pricing.input_student_code',
    `Please enter student discount code.`,
    `Please enter student discount code.`,
    <>
      学割引コードを入力してください。
      <br />
      ご不明な点は下記よりお問い合わせください。
    </>,
    `Please enter student discount code.`,
  ],
  card_2023_student: [
    `2023 대학생 할인 수업권`,
    `2023 Student Discount`,
    's_pricing.card_2023_student',
    `2023 Student Discount`,
    `2023 Student Discount`,
    `2023大学生割引レッスンチケット`,
    `2023 Student Discount`,
  ],
  student_2023: {
    label: (benefit) => [
      <>대학생 {benefit} 추가 할인</>,
      <>Extra {benefit} off</>,
      's_pricing.student_2023.label',
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
      <>Extra {benefit} off</>,
    ],
  },
  label_size_student_2023: [
    `추가혜택`,
    `Special`,
    's_pricing.label_size_student_2023',
    `Special`,
    `Special`,
    `追加特典`,
    `Special`,
  ],
  student_package: {
    free_subsidy: (free) => [
      `${free}회 당일수업권 제공`,
      `${free} Last-minute Credits`,
      's_pricing.student_package.free_subsidy',
      `${free} Last-minute Credits`,
      `${free} Last-minute Credits`,
      `${free}回 当日レッスンチケット提供`,
      `${free} Last-minute Credits`,
    ],
    expirationstarts: [
      `구매 직후부터 수강기간 차감`,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      's_pricing.student_package.expirationstarts',
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
      <>
        Credits valid for 90 days <br />
        from purchase
      </>,
    ],
  },
  code: [
    `할인 코드를 입력해 주세요.`,
    `Please enter a discount code.`,
    's_pricing.code',
    `Please enter a discount code.`,
    `Please enter a discount code.`,
    `割引コードを入力してください。`,
    `Please enter a discount code.`,
  ],
  channel_talk_mobile: [
    `링글 할인 수업권 문의하기`,
    `Contact us`,
    's_pricing.channel_talk_mobile',
    `Contact us`,
    `Contact us`,
    `Ringle割引レッスンチケットのお問い合わせ`,
    `Contact us`,
  ],
  tossPayment: [`토스페이`, ``, 's_pricing.tossPayment', ``, ``, ``, ``],
  event: [`Event`, ``, 's_pricing.event', ``, ``, ``, ``],
  marchsale: {
    label: [
      `3월 한정 속성수업권 할인`,
      `March Special Sale`,
      's_pricing.marchsale.label',
      `March Special Sale`,
      `March Special Sale`,
      `March Special Sale`,
      `March Special Sale`,
    ],
    count: (credit) => [
      `${credit}회 수업권 (속성)`,
      `${credit} Credits (EXPED)`,
      's_pricing.marchsale.count',
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
      `${credit} Credits (EXPED)`,
    ],
    label_size: [`참고`, `Note`, 's_pricing.marchsale.label_size', `Note`, `Note`, `Note`, `Note`],
  },
  discount: {
    marchsale: [
      `3월 한정 할인`,
      `March special sale`,
      's_pricing.discount.marchsale',
      `March special sale`,
      `March special sale`,
      `March special sale`,
      `March special sale`,
    ],
  },
}
export const s_menu = {
  not_supported_features_in_english_mode: [
    `언어를 영어로 변경하면, 지원되지 않는 기능이 있을 수 있습니다.`,
    `Some features may not be available in English mode.`,
    's_menu.not_supported_features_in_english_mode',
    `将语言变更为英文时，可能不支持部分功能。`,
    `將語言變更為英文時，可能不支持部分功能。`,
    `言語設定を英語に変更すると、対応していない機能がある場合があります。`,
    `Một số tính năng sẽ không hiển thị tiếng Anh.`,
  ],
  portal: {
    conversation: [
      `1:1 회화`,
      `1:1 Lessons`,
      's_menu.portal.conversation',
      `1:1会话`,
      `1:1會話`,
      `1:1レッスン`,
      `Buổi học`,
    ],
    editing_service: [
      `영문서 교정`,
      `Editing&proof reading`,
      's_menu.portal.editing_service',
      `英文书面文本校正`,
      `糾正英文文件`,
      `英文校正`,
      `Editing&proof reading`,
    ],
    webinar: [`웨비나`, `Webinar`, 's_menu.portal.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinar`],
    webinar_and_course: [
      `웨비나/교재`,
      `Webinar/Materials`,
      's_menu.portal.webinar_and_course',
      `网络研讨会/教材`,
      `網路研討會／教材`,
      `ウェビナー/教材`,
      `Webinar/Tài liệu`,
    ],
    purchase_lessons: [`구매`, `Buy now`, 's_menu.portal.purchase_lessons', `购买`, `購買`, `購入`, `Mua ngay`],
    invite: [`친구초대`, `Invite`, 's_menu.portal.invite', `邀请朋友`, `邀請朋友`, `友達招待`, `Mời bạn`],
    faq: [`Help Center`, `Help`, 's_menu.portal.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
    notice: [`공지사항`, `Updates`, 's_menu.portal.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
    mypage: [`내 페이지`, `My Page`, 's_menu.portal.mypage', `我的页面`, `我的頁面`, `マイページ`, `Trang của tôi`],
  },
  portalv2: {
    home: [`홈`, `Home`, 's_menu.portalv2.home', `主页`, `主頁`, `Home`, `Trang chủ`],
    conversation: [
      `1:1 수업`,
      `1:1 Lessons`,
      's_menu.portalv2.conversation',
      `1:1课程`,
      `1:1課程`,
      `1on1レッスン`,
      `Buổi học`,
    ],
    original: [`콘텐츠`, `Content`, 's_menu.portalv2.original', `内容`, `內容`, `コンテンツ`, `Nội dung`],
    history: [`학습 활동`, `Activities`, 's_menu.portalv2.history', `学习活动`, `學習活動`, `学習内容`, `Hoạt động`],
    editing_service: [
      `영문서 교정`,
      `Editing&proof reading`,
      's_menu.portalv2.editing_service',
      `英文书面文本校正`,
      `糾正英文文件`,
      `英文校正`,
      `Editing&proof reading`,
    ],
  },
  mypage: {
    my_info: [
      `계정 관리`,
      `My Account`,
      's_menu.mypage.my_info',
      `账户管理`,
      `帳戶管理`,
      `アカウント管理`,
      `Tài khoản của tôi`,
    ],
    coupon_certificates: [
      `수업권/증명서류`,
      `Credits`,
      's_menu.mypage.coupon_certificates',
      `课程券/证明材料`,
      `課程券／證明`,
      `レッスンチケット/証明書類`,
      `Thẻ học`,
    ],
    coupon_box: [`쿠폰함`, `Coupons`, 's_menu.mypage.coupon_box', `优惠券`, `優惠券`, `クーポン`, `Coupons`],
    my_point: [`포인트`, `Points`, 's_menu.mypage.my_point', `积分`, `點數`, `ポイント`, `Điểm`],
    faq: [`고객센터`, `Help Center`, 's_menu.mypage.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Trợ giúp`],
    notice: [`공지사항`, `Updates`, 's_menu.mypage.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
    zoom_install: [
      `Zoom 설치 가이드`,
      `How to Install Zoom`,
      's_menu.mypage.zoom_install',
      `安装Zoom指南`,
      `Zoom安裝指南`,
      `Zoomインストールガイド`,
      `Hướng dẫn cài đặt Zoom`,
    ],
    sign_out: [`로그아웃`, `Logout`, 's_menu.mypage.sign_out', `退出`, `登出`, `ログアウト`, `Đăng xuất`],
    sign_out_message: [
      `로그아웃 하시겠습니까?`,
      `Are you sure you want to log out?`,
      's_menu.mypage.sign_out_message',
      `确定要退出吗?`,
      `確定要登出嗎？`,
      `ログアウトしますか？`,
      `Bạn có chắc muốn thoát ra?`,
    ],
    info: [
      `계정관리`,
      `My Account`,
      's_menu.mypage.info',
      `账户管理`,
      `帳戶管理`,
      `アカウント管理`,
      `Tài khoản của tôi`,
    ],
    timezone_language: [
      <>타임존 & 언어</>,
      <>Time zone & Language</>,
      's_menu.mypage.timezone_language',
      <>时区 & 语言</>,
      <>時區 & 語言</>,
      <>タイムゾーン & 言語</>,
      <>Múi giờ & Ngôn ngữ</>,
    ],
    change: [`변경`, `Update`, 's_menu.mypage.change', `变更`, `變更`, `変更`, `Cập nhật`],
    account: [`계정`, `Account`, 's_menu.mypage.account', `账户`, `帳戶`, `アカウント`, `Tài khoản`],
  },
  conversation: {
    trial_lesson: [
      `체험 신청`,
      `Trial Lesson`,
      's_menu.conversation.trial_lesson',
      `申请体验`,
      `申請體驗`,
      `体験レッスン予約`,
      `Buổi học thử`,
    ],
    schedule_lesson: [`예약`, `Book`, 's_menu.conversation.schedule_lesson', `预约`, `預約`, `予約`, `Đặt lịch`],
    upcoming_lessons: (count) => [
      `예습 & 수업 (${count})`,
      `Prep & Lesson (${count})`,
      's_menu.conversation.upcoming_lessons',
      `预习&课程 (${count})`,
      `預習 & 課程（${count}）`,
      `予習 & レッスン (${count})`,
      `Tài liệu Chuẩn bị & buổi học (${count})`,
    ],
    lesson_statistics: [
      `수업 통계`,
      `Stats`,
      's_menu.conversation.lesson_statistics',
      `课程统计`,
      `課程統計`,
      `レッスン統計`,
      `Thống kê`,
    ],
    review_lessons: [`복습`, `Review`, 's_menu.conversation.review_lessons', `复习`, `複習`, `復習`, `Đánh giá`],
    lesson_materials: [`교재`, `Materials`, 's_menu.conversation.lesson_materials', `教材`, `教材`, `教材`, `Tài liệu`],
    tutors: [`튜터`, `Tutors`, 's_menu.conversation.tutors', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
    ringle_guide: [
      `링글 활용법`,
      `How Ringle works`,
      's_menu.conversation.ringle_guide',
      `Ringle教学方法`,
      `Ringle教學法`,
      `Ringleのレッスン方法`,
      `Cách thức Ringle hoạt động`,
    ],
    testimonials: [
      `후기`,
      `Testimonials`,
      's_menu.conversation.testimonials',
      `评价`,
      `心得`,
      `レビュー`,
      `Chia sẻ từ khách hàng`,
    ],
    memo: [`메모`, `Memo`, 's_menu.conversation.memo', ``, ``, ``, ``],
    homework: [`숙제`, `Homework`, 's_menu.conversation.homework', `作业`, `作業`, `宿題`, `Bài tập`],
  },
  writing: {
    edit_request: [
      `교정 요청`,
      `Requested Edits`,
      's_menu.writing.edit_request',
      `校正申请`,
      `申請糾正`,
      `校正リクエスト`,
      `Yêu cầu chỉnh sửa`,
    ],
    ongoing_edits: [
      `진행 중인 교정`,
      `Edits in Progress`,
      's_menu.writing.ongoing_edits',
      `进行中的校正`,
      `正在進行的糾正`,
      `進行中の校正`,
      `Đang chỉnh sửa`,
    ],
    completed_edits: [
      `완료된 교정`,
      `Completed Edits`,
      's_menu.writing.completed_edits',
      `已完成的校正`,
      `已完成的糾正`,
      `完了した校正`,
      `Hoàn tất chỉnh sửa`,
    ],
  },
  original: {
    webinar: [`웨비나`, `Webinars`, 's_menu.original.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinars`],
    course: [`교재`, `Materials`, 's_menu.original.course', `教材`, `教材`, `教材`, `Tài liệu`],
    my: [`내 콘텐츠`, `My Content`, 's_menu.original.my', `我的收藏`, `我的收藏`, `マイコンテンツ`, `Nội dung của tôi`],
    dailybrief: [
      `데일리 브리프`,
      `Daily Brief`,
      's_menu.original.dailybrief',
      `Daily Brief`,
      `Daily Brief`,
      `Daily Brief`,
      `Daily Brief`,
    ],
  },
  landing: {
    why_ringle: [
      `What is Ringle`,
      `What is Ringle`,
      's_menu.landing.why_ringle',
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
    ],
    by_customer: [
      `수강 대상`,
      `By Customer`,
      's_menu.landing.by_customer',
      `课程对象`,
      `聽課對象`,
      `対象別`,
      `Theo khách hàng`,
    ],
    by_purpose: [`수강 목적`, `By Need`, 's_menu.landing.by_purpose', `课程目标`, `聽課目標`, `目的別`, `Theo nhu cầu`],
    ringle_tip: [
      `Ringle Tip`,
      `Testimonials`,
      's_menu.landing.ringle_tip',
      `Ringle Tip`,
      `Ringle Tip`,
      `Ringle Tip`,
      `Khách hàng`,
    ],
    beginner: [
      `영어 초중급자`,
      `Beginner-Intermediate`,
      's_menu.landing.beginner',
      `英语初/中级学员`,
      `英語初／中級者`,
      `英語初中級者向け`,
      `Cơ bản-Trung cấp`,
    ],
    signin: [`로그인`, `Log in`, 's_menu.landing.signin', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    free_trial: [`무료 체험`, `Sign up`, 's_menu.landing.free_trial', `免费体验`, `免費體驗`, `無料体験`, `Đăng ký`],
    free_trial_btn: [
      `무료 체험`,
      `Free Trial`,
      's_menu.landing.free_trial_btn',
      `免费体验`,
      `免費體驗`,
      `無料体験`,
      `Học thử miễn phí`,
    ],
    service_intro: [
      `전체보기`,
      `Overview`,
      's_menu.landing.service_intro',
      `服务介绍`,
      `服務介紹`,
      `サービス紹介`,
      `Tổng quan`,
    ],
    portal: [`내 강의실`, `My Lessons`, 's_menu.landing.portal', `我的教室`, `我的教室`, `マイレッスン`, `Buổi học`],
    conversation: [
      `What is Ringle`,
      `What is Ringle`,
      's_menu.landing.conversation',
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
      `What is Ringle`,
    ],
    tutor: [`튜터`, `Tutors`, 's_menu.landing.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
    lesson_materials: [`교재`, `Materials`, 's_menu.landing.lesson_materials', `教材`, `教材`, `教材`, `Tài liệu`],
    review: [`후기`, `Testimonials`, 's_menu.landing.review', `评价`, `心得`, `レビュー`, `Khách hàng`],
    lesson_process: [
      `사용법`,
      `How Ringle works`,
      's_menu.landing.lesson_process',
      `使用方法`,
      `使用方法`,
      `受講の流れ`,
      `Cách thức hoạt động`,
    ],
    lesson_system: [
      `수업 시스템`,
      `Features`,
      's_menu.landing.lesson_system',
      `课程系统`,
      `課程系統`,
      `レッスンシステム`,
      `Tính năng nổi bật`,
    ],
    solution: [
      `Ringle for`,
      `Ringle for`,
      's_menu.landing.solution',
      `Ringle for`,
      `Ringle for`,
      `Ringle for`,
      `Ringle for`,
    ],
    pricing: [`가격`, `Pricing`, 's_menu.landing.pricing', `Pricing`, `Pricing`, `Pricing`, `Pricing`],
    business: [
      `비즈니스 영어`,
      `Business English`,
      's_menu.landing.business',
      `商务英语`,
      `商務英語`,
      `ビジネス英語`,
      <>
        Tiếng Anh
        <br /> thương mại
      </>,
    ],
    correction: [
      `이메일/에세이 교정`,
      `Resume & Essay Editing`,
      's_menu.landing.correction',
      `电子邮件/短文校正`,
      `糾正電子郵件／申論`,
      `文書添削`,
      `Chỉnh sửa Resume & Bài luận`,
    ],
    prep_interview: [
      `인터뷰 준비`,
      `Interview Prep`,
      's_menu.landing.prep_interview',
      `英文面试准备`,
      `英語面試準備`,
      `英語面接準備`,
      <>
        Chuẩn bị
        <br /> phỏng vấn
      </>,
    ],
    pronounce: [
      <>발음 교정 (미국/영국)</>,
      <>Pronunciation</>,
      's_menu.landing.pronounce',
      <>发音矫正(美式/英式)</>,
      <>矯正發音（英美式）</>,
      <>発音矯正 (米/英国)</>,
      <>Phát âm</>,
    ],
    office_worker: [
      `직장인/전문직`,
      `Global Professionals`,
      's_menu.landing.office_worker',
      `公司职员/专业人士`,
      `上班族／專業人員`,
      `会社員/専門職向け`,
      `Nhân viên toàn cầu`,
    ],
    professional: [`전문직 종사자`, ``, 's_menu.landing.professional', ``, ``, ``, ``],
    study_abroad: [
      `유학생`,
      `International Students`,
      's_menu.landing.study_abroad',
      `留学生`,
      `留學生`,
      `海外留学生向け`,
      `Du học sinh`,
    ],
    junior: [
      `초중고 학생`,
      `K-12`,
      's_menu.landing.junior',
      `小学/初中/高中学生`,
      `國小、國中、高中生`,
      `小中高生向け`,
      `K-12`,
    ],
    prep_exam: [
      `공인영어시험 대비`,
      `Exam Prep`,
      's_menu.landing.prep_exam',
      `专业英语备考`,
      `準備英語檢定`,
      `英語資格試験対策`,
      `Luyện thi`,
    ],
    live_abroad: [
      `해외 거주자`,
      `Overseas Residents`,
      's_menu.landing.live_abroad',
      `海外居住者`,
      `海外居住者`,
      `海外在住者向け`,
      `Người định cư ở nước ngoài`,
    ],
    b2b: [`기업제휴`, `Business`, 's_menu.landing.b2b', `企业合作`, `企業合作`, `Business`, `Business`],
    event: [`이벤트`, `Event`, 's_menu.landing.event', `活动`, `活動`, `イベント`, `Sự kiện`],
    faq: [`Help Center`, `Help Center`, 's_menu.landing.faq', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
    language: [`언어`, `Language`, 's_menu.landing.language', `语言`, `語言`, `言語`, `Ngôn ngữ`],
    free_trial_btn_2: [
      `무료체험`,
      `Free Trial`,
      's_menu.landing.free_trial_btn_2',
      `注册`,
      `註冊`,
      `無料会員登録`,
      `Đăng ký`,
    ],
    signup: [`회원가입`, `Sign-up`, 's_menu.landing.signup', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
    login: [`로그인`, `Log in`, 's_menu.landing.login', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    bosnychallenge: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_menu.landing.bosnychallenge',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    event_title: [`이벤트`, ``, 's_menu.landing.event_title', ``, ``, ``, ``],
    event_list_1: [`봄맞이 대학생 할인`, ``, 's_menu.landing.event_list_1', ``, ``, ``, ``],
    event_list_2: [`제4회 링글 공모전`, ``, 's_menu.landing.event_list_2', ``, ``, ``, ``],
  },
  statistics: {
    all: [`전체`, `All`, 's_menu.statistics.all', `全部`, `全部`, `すべて`, `Tất cả`],
    read: [`읽기`, `Reading`, 's_menu.statistics.read', `阅读`, `閱讀`, `読み`, `Đọc`],
    listen: [`듣기`, `Listening`, 's_menu.statistics.listen', `听力`, `聽力`, `聞取り`, `Nghe`],
    write: [`쓰기`, `Writing`, 's_menu.statistics.write', `写作`, `寫作`, `書き`, `Viết`],
    speak: [`말하기`, `Speaking`, 's_menu.statistics.speak', `口语`, `口說`, `話す`, `Nói`],
  },
  return_prev: [
    `이전 페이지로 돌아가기`,
    `Return to previous page`,
    's_menu.return_prev',
    `返回上一页`,
    `返回上一頁`,
    `前のページに戻る`,
    `Trở về trang trang trước`,
  ],
  sir: [`님`, ``, 's_menu.sir', `用户`, `先生／小姐`, `さん`, ``],
  change_language: [
    `언어 변경`,
    `Change Language`,
    's_menu.change_language',
    `变换语言`,
    `變更語言`,
    `言語変更`,
    `Đổi ngôn ngữ`,
  ],
}
export const s_landing = {
  get_started: [
    `무료체험 수업 신청하기`,
    `Sign up`,
    's_landing.get_started',
    `申请免费咨询`,
    `申請免費諮詢`,
    `無料相談お申込み`,
    `Đăng ký`,
  ],
  get_started_free_trial: [
    <>
      지금 링글을 시작해 보세요!
      <br />
      회원가입 시 1회 체험 수업 무료
    </>,
    <>Start now with a free trial</>,
    's_landing.get_started_free_trial',
    <>
      现在就请使用Ringle吧!
      <br />
      首次课程不满意时, 可100%退款
    </>,
    <>
      現在就使用Ringle吧！
      <br />
      首次課程不滿意時，可全額退款
    </>,
    <>
      さあ、Ringleを始めましょう！
      <br />
      ご満足いただけなければ全額返金保証
    </>,
    <>Học thử miễn phí</>,
  ],
  signup_check_out: [
    `무료체험 신청하기`,
    `Register for free trial`,
    's_landing.signup_check_out',
    `注册`,
    `註冊`,
    `Đăng ký`,
    `無料会員登録`,
  ],
  get_started_signup: [
    <>첫 수업 불만족 시, 100% 환불 가능</>,
    <>100% Money-back guarantee.</>,
    's_landing.get_started_signup',
    <>首次课程不满意时, 承诺100%退款</>,
    <>首次課程不滿意時，可全額退款</>,
    <>初回レッスン後にご満足いただけない場合、全額返金</>,
    <>Hoàn tiền 100%.</>,
  ],
  get_signup: [
    `무료체험 신청하기`,
    `Register for free trial`,
    's_landing.get_signup',
    `注册`,
    `註冊`,
    `Đăng ký`,
    `無料会員登録`,
  ],
  get_started_counsel: [<>무료 상담 신청</>, <>Book 1:1 Advising</>, 's_landing.get_started_counsel', ``, ``, ``, ``],
  gnb: {
    welcome_coupon: [
      <>
        지금 가입하면{' '}
        <Div spanTag fontBold>
          3만원 웰컴쿠폰
        </Div>
        을 드려요
      </>,
      <>
        Join now for{' '}
        <Div spanTag fontBold>
          extra $25
        </Div>{' '}
        off
      </>,
      's_landing.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome_coupon_otherpage_btn: [
    `지금 신규가입 혜택받기`,
    `Get max.$77 off Newcomer Offer`,
    's_landing.welcome_coupon_otherpage_btn',
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
    `Get max.$77 off Newcomer Offer`,
  ],
}
export const s_home = {
  more_than_offline: [
    <>
      링글,
      <br />
      No.1 일대일 화상영어
    </>,
    <>
      The <Span primary>New Standard</Span>
      <br />
      in Online English Learning
    </>,
    's_home.more_than_offline',
    <>
      Ringle,
      <br />
      No.1的1对1线上英语
    </>,
    <>
      Ringle，
      <br />
      No.1的1:1視訊英語
    </>,
    <>
      1on1オンライン英会話の
      <br />
      新時代を駆けるRingle
    </>,
    <>
      <Span primary>Nâng tầm</Span>
      <br />
      việc học tiếng Anh Online
    </>,
  ],
  from_stanford: [
    <>
      아이비리그 출신 원어민 튜터와 함께하는
      <br />
      나만을 위한 1:1 화상영어 수업!
    </>,
    <>1:1 English lessons with top university students & graduates</>,
    's_home.from_stanford',
    <>
      携手常青藤联盟出身的英文母语教师
      <br />
      专为我打造的1:1线上英语课程!
    </>,
    <>
      與常春藤名校的母語任課教師
      <br />
      一起進行專屬於我的1:1視訊英語課程！
    </>,
    <>
      アイビーリーグ大学出身のネイティブチューターが教える
      <br />
      マンツーマンのオンライン英会話レッスン！
    </>,
    <>Học tiếng Anh 1:1 cùng các sinh viên và cựu sinh viên các trường đại học hàng đầu</>,
  ],
  not_just_english: [
    <>
      당신이 꿈꾸던 영어실력과 커리어,
      <br />
      링글에선 모두 현실이 됩니다
    </>,
    <>
      Unlock your global potential
      <br />
      Learn about Ringle's top use cases
    </>,
    's_home.not_just_english',
    <>
      您希望的英文实力及职业,
      <br />
      在Ringle中均将梦想成真。
    </>,
    <>
      您夢寐以求的英文實力及職涯，
      <br />
      將在Ringle全部實現。
    </>,
    <>
      あなたが夢見ていた英語力とキャリア、
      <br />
      Ringleですべて実現できます。
    </>,
    <>
      Khai mở tiềm năng của bạn
      <br />
      Tìm hiểu những đối tượng thường sử dụng Ringle
    </>,
  ],
  tutors: [
    <Fragment>
      <b>똑똑한 튜터와의 맞춤 수업</b>
      <br />
      <br />
      1000+명 이상의 검증된
      <br />
      영미권 명문대 원어민 튜터
    </Fragment>,
    <>
      <b>Manually vetted and trained tutors</b>
      <br />
      1000+ tutors from top universities{' '}
    </>,
    's_home.tutors',
    <Fragment>
      <b>和优秀教师间的定制型课程</b>
      <br />
      <br />
      经1000人以上的验证
      <br />
      英美地区, 名校母语教师
    </Fragment>,
    <Fragment>
      <b>與優秀任課教師的客製化課程</b>
      <br />
      <br />
      1000名以上眾所公認的
      <br />
      英美名校母語任課教師
    </Fragment>,
    <Fragment>
      <b>精鋭揃いのチューターとカスタマイズレッスン</b>
      <br />
      <br />
      英米名門校出身のネイティブチューター
      <br />
      1000名以上在籍
    </Fragment>,
    <>
      <b>Được đào tạo và hướng dẫn bài bản</b>
      <br />
      1000+ gia sư từ các trường đại học hàng đầu{' '}
    </>,
  ],
  tutors_title: [
    <Fragment>똑똑한 튜터와의 맞춤 수업</Fragment>,
    <>Manually vetted and trained tutors</>,
    's_home.tutors_title',
    <Fragment>和优秀教师间的定制型课程</Fragment>,
    <Fragment>與優秀任課教師的客製化課程</Fragment>,
    <Fragment>精鋭揃いのチューターとカスタマイズレッスン</Fragment>,
    <>Được đào tạo và hướng dẫn bài bản</>,
  ],
  tutors_subtitle: [
    <Fragment>
      1000명 이상의 검증된
      <Br md down /> 영미권 명문대 원어민 튜터
    </Fragment>,
    <>1000+ Tutors from top universities</>,
    's_home.tutors_subtitle',
    <Fragment>
      经过验证的
      <Br md down /> 英美地区名校母语教师1000多位
    </Fragment>,
    <Fragment>
      1000名以上眾所公認的
      <Br md down />
      英美名校母語任課教師
    </Fragment>,
    <Fragment>
      英米名門校出身のネイティブチューター
      <Br md down /> 1000名以上在籍
    </Fragment>,
    <>1000+ gia sư từ các trường đại học hàng đầu</>,
  ],
  with_a_major_of_your_chice: [
    <>
      전공/경력/관심사/발음 등을 미리 확인하고&nbsp;
      <Br md down />
      원하는 튜터를 직접 선택하세요
    </>,
    ``,
    's_home.with_a_major_of_your_chice',
    <>
      提前确认专业/经历/兴趣/发音等内容&nbsp;
      <Br md down />
      可以直接选择您希望的教师
    </>,
    <>
      請事先確認主修／經歷／感興趣的領域／發音等內容，&nbsp;
      <Br md down />
      親自選擇您希望的任課教師。
    </>,
    <>
      専攻・経歴・興味/関心・アクセントなどから&nbsp;
      <Br md down />
      お好みのチューターを選んでみましょう。
    </>,
    ``,
  ],
  ai_platform: [
    <>
      <b>말하고 사라지던 영어의 완전한 시각화</b>
      <br />
      <br />
      링글만의 차별화된 화상영어 시스템
    </>,
    <>
      <b>Comprehensive Feedback </b>
      <br />
      Review key corrections and receive personalized feedback
    </>,
    's_home.ai_platform',
    <>
      <b>将出口无形的英语可视化</b>
      <br />
      <br />
      别具一格的线上英语系统, 只在Ringle
    </>,
    <>
      <b>將一說即逝的英語完全視覺化</b>
      <br />
      <br />
      Ringle與眾不同的視訊英語系統
    </>,
    <>
      <b>視覚化された英会話学習を体験</b>
      <br />
      <br />
      Ringle独自のオンライン英会話システム
    </>,
    <>
      <b>Đánh giá toàn diện </b>
      <br />
      Xem lại những chỉnh sửa chính và nhận phản hồi được cá nhân hoá
    </>,
  ],
  ai_platform_title: [
    <>말하고 사라지던 영어의 완전한 시각화</>,
    <>Comprehensive Feedback</>,
    's_home.ai_platform_title',
    <>将出口无形的英语可视化</>,
    <>將一說即逝的英語完全視覺化</>,
    <>視覚化された英会話学習を体験</>,
    <>Đánh giá toàn diện</>,
  ],
  ai_platform_subtitle2: [
    <>링글만의 차별화된 화상영어 시스템</>,
    <>4 Personalized feedback reports after each lesson</>,
    's_home.ai_platform_subtitle2',
    <>别具一格的线上英语系统, 只在Ringle</>,
    <>Ringle與眾不同的視訊英語系統</>,
    <>Ringle独自のオンライン英会話システム</>,
    <>4 báo cáo đánh giá được cá nhân hoá sau mỗi buổi học</>,
  ],
  ai_platform_subtitle: [
    <>
      내 영어를 눈으로 보며&nbsp;
      <Br md down />
      실시간 교정을 받아보세요
    </>,
    ``,
    's_home.ai_platform_subtitle',
    <>
      我的英语可视直观&nbsp;
      <Br md down />
      随时获取纠正吧!
    </>,
    <>
      親眼確認您的英語，&nbsp;
      <Br md down />
      獲得即時糾正吧！
    </>,
    <>
      自分の英語がその場で校正される &nbsp;
      <Br md down /> 可視化されたプロセス
    </>,
    ``,
  ],
  change_experience: [
    <>
      영어 실력의 성장,
      <Br md /> 그로 인해 찾아온 인생의 변화
    </>,
    <>Start a new chapter of your life with Ringle</>,
    's_home.change_experience',
    <>
      英语实力的增长,
      <Br md /> 由此引发的人生变化
    </>,
    <>
      由於英語實力增長，
      <Br md />
      因此出現人生的變化
    </>,
    <>
      英語力の成長が
      <Br md /> もたらした訪れた人生の変化
    </>,
    <>Khởi đầu hành trình mới cùng Ringle</>,
  ],
  listen_voice: [
    `수강생들의 솔직한 이야기를 들어보세요`,
    ``,
    's_home.listen_voice',
    `来听一听学生们的真实说法吧`,
    `來聽一聽學生們的誠實心得吧！`,
    `受講生たちの正直な感想をぜひご覧ください。`,
    ``,
  ],
  six_years: [
    <>
      <b>당신의 현실에 맞춘 교재</b>
      <br />
      <br />
      일상 회화부터 시사, <Br md down />
      실전 비즈니스 영어까지
      <br />
      Top MBA 출신 집필진과 튜터들이 매주 업데이트하는 1,000+개의 교재
    </>,
    <>
      <b>Exclusive lesson materials </b>
      <br />
      1,000+ materials written by subject experts{' '}
    </>,
    's_home.six_years',
    <>
      <b>贴合您现实生活的教材</b>
      <br />
      <br />
      从日常生活对话, <Br md down />
      到实战商务英语
      <br />
      出身Top MBA的编纂人员及教师们将每周更新超过1,000篇的教材内容
    </>,
    <>
      <b>符合您的現實生活的教材</b>
      <br />
      <br />
      從日常對話到時事、
      <Br md down />
      實戰商務英語
      <br />
      Top MBA出身的執筆團隊及任課教師們每週更新1,000種以上的教材
    </>,
    <>
      <b>あなたのニーズに合わせた教材</b>
      <br />
      <br />
      日常会話から時事や
      <Br md down />
      実践ビジネス英語まで
      <br />
      MBAトップ校出身のライターとチューターが毎週アップデートする、1,000以上の教材から選ぶことができます。
    </>,
    <>
      <b>Tài liệu độc quyền cho buổi học </b>
      <br />
      1,000+ tài liệu được viết bởi các chuyên gia{' '}
    </>,
  ],
  six_years_title: [
    <>당신의 현실에 맞춘 교재</>,
    <>Exclusive lesson materials</>,
    's_home.six_years_title',
    <>贴合您现实生活的教材</>,
    <>符合您的現實生活的教材</>,
    <>あなたのニーズに合わせた教材</>,
    <>Tài liệu độc quyền cho buổi học</>,
  ],
  six_years_subtitle: [
    <>
      일상 회화부터 시사, <Br md down />
      실전 비즈니스 영어까지
    </>,
    <>1,000+ Materials written by subject experts </>,
    's_home.six_years_subtitle',
    <>
      从日常生活对话, <Br md down />
      到实战商务英语
    </>,
    <>
      從日常對話到時事、
      <Br md down />
      實戰商務英語
    </>,
    <>
      日常会話から時事や
      <Br md down />
      実践ビジネス英語まで
    </>,
    <>1,000+ Tài liệu được viết bởi các chuyên gia </>,
  ],
  six_years_text: [
    <>
      Top MBA 출신 집필진과 튜터들이 <Br md down />
      매주 업데이트하는 1,000+개의 교재
    </>,
    ``,
    's_home.six_years_text',
    <>
      出身Top MBA的编纂人员及教师们
      <Br md down />
      将每周更新超过1,000篇的教材内容
    </>,
    <>
      Top MBA出身的執筆團隊及任課教師們
      <Br md down />
      每週更新1,000種以上的教材
    </>,
    <>
      MBAトップ校出身のライターとチューターが
      <Br md down />
      毎週アップデートする、1,000以上の教材から選ぶことができます。
    </>,
    ``,
  ],
  anytime_anywhere: [
    <>영어 PT, Resume, 이메일, 면접 준비 등 맞춤 수업도 가능합니다</>,
    <></>,
    's_home.anytime_anywhere',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  no_fake: (n) => [
    <Fragment>
      {n}개의 거짓 없는
      <br />
      실시간 수업 후기가 증명합니다
    </Fragment>,
    <Fragment>{n} reviews </Fragment>,
    's_home.no_fake',
    <Fragment>
      {n}条真实的
      <br />
      实时课程评价足可以证明。
    </Fragment>,
    <Fragment>
      {n}條真實的
      <br />
      即時課程心得向您保證。
    </Fragment>,
    <Fragment>
      {n}件の受講生たちの
      <br />
      リアルタイムレッスンレビューが証明します。
    </Fragment>,
    <Fragment>{n} đánh giá </Fragment>,
  ],
  realtime_edits: [
    `실시간 교정`,
    `Real-time Correction`,
    's_home.realtime_edits',
    `实时纠正`,
    `即時糾正`,
    `リアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  realtime_edits_desc: [
    <>
      말하면 눈에 보이는 실시간 화상영어
      <br />
      튜터와 대화하며 실시간 교정과 피드백을 받아보세요
    </>,
    <>Follow along your tutor's corrections real-time</>,
    's_home.realtime_edits_desc',
    <>
      可以直观的实时会话线上英语
      <br />
      和教师展开对话, 获取实时纠正和反馈吧。
    </>,
    <>
      說出口便能即時確認的視訊英語
      <br />
      與任課教師對話，確認即時糾正與意見回饋吧！
    </>,
    <>
      その場で理解を深められるオンライン学習システム
      <br />
      チューターと会話しながらリアルタイム校正やフィードバックがもらえます。
    </>,
    <>Theo dõi những chỉnh sửa trực tiếp từ gia sư</>,
  ],
  conversation_script: [
    `대화 스크립트`,
    `Recording & Transcript`,
    's_home.conversation_script',
    `对话脚本`,
    `對話腳本`,
    `会話スクリプト`,
    `Ghi âm & Bản ghi`,
  ],
  conversation_script_desc: [
    <>
      AI가 기록하는 녹음 스크립트
      <br />
      튜터와 나의 대화를 다시 확인하며 언제든 복습해보세요
    </>,
    <>Revisit your lesson by audio or by text</>,
    's_home.conversation_script_desc',
    <>
      AI记录的录音脚本
      <br />
      课后通过再次确认, 随时进行复习吧
    </>,
    <>
      AI記錄的錄音腳本
      <br />
      再次確認任課教師與我的對話，隨時進行複習吧！
    </>,
    <>
      AIが記録する会話スクリプト
      <br />
      レッスン中の会話を音声とスクリプトからいつでも復習できます。
    </>,
    <>Xem lại buổi học qua file âm thanh hoặc chữ</>,
  ],
  ai_analytics: [
    `AI 분석`,
    `AI Analysis`,
    's_home.ai_analytics',
    `AI分析`,
    `AI分析`,
    `AI analysis`,
    `Phân tích bằng AI`,
  ],
  ai_analytics_desc: [
    <>
      AI가 분석한 영어회화 패턴
      <br />
      말하기 속도, 자주 쓰는 단어, Filler Word 등 나의 영어 습관을 체크해보세요
    </>,
    <>Review speech pace, vocabulary range and filler words</>,
    's_home.ai_analytics_desc',
    <>
      采用AI技术进行分析的英语会话模式
      <br />
      可确认会话速度, 常用词, Filler Word等有关本人的英文会话习惯
    </>,
    <>
      AI分析的英語會話模式
      <br />
      確認口說速度、常用單字、Filler Word等自己的英文習慣吧！
    </>,
    <>
      AIが分析する会話パターン
      <br />
      話すスピード、よく使う単語、つなぎ言葉(Filler Word)などから自分の傾向をチェックしてみましょう。
    </>,
    <>Đánh giá tốc độ nói, vốn từ và các fillers words</>,
  ],
  mutli_feedback: [
    `다각도 피드백`,
    `Tutor Feedback`,
    's_home.mutli_feedback',
    `多角度反馈`,
    `全方位意見回饋`,
    `多角的フィードバック`,
    `Đánh giá từ gia sư`,
  ],
  mutli_feedback_desc: [
    <>
      수업 후 제공되는 튜터의 맞춤 피드백
      <br />
      발음, 문법, 어휘 등 나만을 위한 피드백으로 영어 실력을 키워보세요
    </>,
    <>Receive thorough evaluations on pronunciations, grammar, vocabulary and fluency</>,
    's_home.mutli_feedback_desc',
    <>
      课程结束后提供的教师针对性反馈
      <br />
      发音, 语法, 词汇等专属于我的反馈内容, 努力提升您的英语实力吧。
    </>,
    <>
      課程結束後任課教師提供客製化意見回饋
      <br />
      利用發音、文法、詞彙等專屬於我的意見回饋，提升您的英文實力吧！
    </>,
    <>
      レッスン後にチューターから提供されるフィードバック
      <br />
      発音、文法、語彙など、あなたのためだけのフィードバックで英語力を伸ばしましょう。
    </>,
    <>Nhận đánh giá đầy đủ về phát âm. ngữ pháp, từ vựng và mức độ trôi chảy</>,
  ],
  office_worker: [
    <>직장인/전문직</>,
    `Global Professionals`,
    's_home.office_worker',
    <>公司职员/专业人士</>,
    <>上班族／專業人員</>,
    <>会社員/専門職向け</>,
    `Nhân viên toàn cầu`,
  ],
  study_abroad: [
    <>유학생</>,
    <>International Students</>,
    's_home.study_abroad',
    <>留学生</>,
    <>留學生</>,
    `海外留学生向け`,
    <>Du học sinh</>,
  ],
  junior: [
    <>초중고 학생</>,
    `K-12`,
    's_home.junior',
    <>小学/初中/高中学生</>,
    <>國小、國中、高中學生</>,
    <>小中高生向け</>,
    `K-12`,
  ],
  live_abroad: [
    <>해외 거주자</>,
    <>Overseas Residents</>,
    's_home.live_abroad',
    <>海外居住者</>,
    <>海外居住者</>,
    <>海外在住者向け</>,
    <>Người định cư ở nước ngoài</>,
  ],
  correction: [
    <>이메일/에세이 교정</>,
    `Resume & Essay Editing`,
    's_home.correction',
    <>电子邮件/短文校正</>,
    <>糾正電子郵件／申論</>,
    <>履歴書/エッセー校正</>,
    `Chỉnh sửa Resume & Bài luận`,
  ],
  prep_interview: [
    <>인터뷰 준비</>,
    `Interview Prep`,
    's_home.prep_interview',
    <>英语面试准备</>,
    <>英語面試準備</>,
    <>英語面接準備</>,
    `Chuẩn bị phỏng vấn`,
  ],
  pronounce: [
    <>발음 교정 (미국/영국)</>,
    <>Pronunciation</>,
    's_home.pronounce',
    <>发音矫正(美式/英式)</>,
    <>矯正發音（美式／英式）</>,
    <>発音矯正 (アメリカ/イギリス)</>,
    <>Phát âm</>,
  ],
  prep_exam: [
    <>공인영어시험 대비</>,
    `Exam Prep`,
    's_home.prep_exam',
    <>专业英语备考</>,
    <>準備英語檢定</>,
    <>英語資格試験対策</>,
    `Luyện thi`,
  ],
  webinar_free_title: [
    <>고퀄리티 영상 강의 링글 웨비나</>,
    <>Live Webinars</>,
    's_home.webinar_free_title',
    <>高质量视频课程Ringle网络研讨会</>,
    <>高品質視訊課程Ringle網路研討會</>,
    <>高品質のオリジナルオンライン講義</>,
    <>Webinar trực tuyến</>,
  ],
  webinar_free_subtitle: [
    <>
      링글 회원에게만 제공되는 <Br md down />
      300+개의 무료 영어 웨비나
    </>,
    <>300+ Webinars hosted by Ringle's best tutors</>,
    's_home.webinar_free_subtitle',
    <>
      只向Ringle会员提供的
      <Br md down />
      300多个免费英文网络研讨会
    </>,
    <>
      只提供Ringle會員的
      <Br md down />
      300個以上免費英語網路研討會
    </>,
    <>
      Ringle会員だけが視聴できる
      <Br md down />
      300本超の無料英語ウェビナー
    </>,
    `300+ webinar dẫn dắt bởi các gia sư hàng đầu Ringle`,
  ],
  webinar_free_text: [
    <>
      매주 1회, 링글 인기 튜터와 함께하는 <Br md down />
      1시간 LIVE 강의도 들어보세요
    </>,
    <></>,
    's_home.webinar_free_text',
    <>
      每周一次, 参与Ringle人气教师开办的
      <Br md down />
      1小时LIVE课程
    </>,
    <>
      一週聆聽一次Ringle人氣任課教師開辦的
      <Br md down />
      1小時LIVE課程吧！
    </>,
    <>
      毎週1回開催、Ringle人気チューターによる
      <Br md down />
      1時間ライブ講義も受けてみましょう。
    </>,
    <></>,
  ],
  promotion: {
    head: (dday) => [
      <>하반기 프로모션 얼리버드 마감까지 D-{dday}</>,
      <>D-{dday} 2nd Half Early Bird Promotion</>,
      's_home.promotion.head',
      <>下半期促销活动早鸟截止前D-{dday}</>,
      <>距離下半期宣傳活動早鳥截止剩下D-{dday}</>,
      <>下半期プロモーションアーリーバード締め切りまでD-{dday}</>,
      <>D-{dday} Ưu đãi sớm nửa cuối năm </>,
    ],
    title: [
      <>
        정규 할인 전, 기존 고객분들에게만
        <br />
        얼리버드 한정 혜택을 드려요!
      </>,
      <>
        Early Bird offers only for existing users,
        <br />
        before the regular discount
      </>,
      's_home.promotion.title',
      <>
        正规优惠前, 仅对现有用户
        <br />
        提供早鸟限定优惠!
      </>,
      <>
        正規折扣之前，僅提供給既有學生
        <br />
        早鳥限定優惠！
      </>,
      <>
        正規割引前、既存のお客様にだけ
        <br />
        アーリーバード限定特典を差し上げます！
      </>,
      <></>,
    ],
    content: (amount) => [
      <>
        최대 <span style={{ color: '#b4caff' }}>{amount}만원</span> 추가 할인
        <br />
        <span style={{ color: '#b4caff' }}>미국 항공권</span> 지원 혜택을 놓치지 마세요!
      </>,
      <>
        Don’t miss out on the early bird
        <br />
        discount up to <span style={{ color: '#b4caff' }}>$344.83</span> +{' '}
        <span style={{ color: '#b4caff' }}>Int’l airline tickets</span>{' '}
      </>,
      's_home.promotion.content',
      <>
        高达<span style={{ color: '#b4caff' }}>${amount}</span>追加优惠
        <br />
        <span style={{ color: '#b4caff' }}>美国机票</span>优惠千万不要错过!
      </>,
      <>
        最高<span style={{ color: '#b4caff' }}>${amount}</span>額外優惠
        <br />
        並支援<span style={{ color: '#b4caff' }}>美國機票</span>，千萬不要錯過！
      </>,
      <>
        最大 <span style={{ color: '#b4caff' }}>{amount}</span> 追加割引
        <br />
        <span style={{ color: '#b4caff' }}>アメリカ航空券</span> サポート特典をお見逃しなく！
      </>,
      <>
        Đừng bỏ lỡ ưu đãi early bird
        <br />
        giảm giá lên đến <span style={{ color: '#b4caff' }}>$344.83</span> +{' '}
        <span style={{ color: '#b4caff' }}>Int’l airline tickets</span>{' '}
      </>,
    ],
    button: [
      <>지금 바로 혜택 받기</>,
      <>Receive benefits now</>,
      's_home.promotion.button',
      <>现在就获得优惠</>,
      <>現在立即領取優惠</>,
      <>今すぐ特典を受け取る</>,
      <>Nhận ưu đãi ngay</>,
    ],
  },
  bn_challenge: {
    banner: [
      `링글과 함께하는 세 번째 도전, 보스턴&뉴욕 챌린지가 1월 6일부터 시작됩니다!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      's_home.bn_challenge.banner',
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
    ],
    title: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_home.bn_challenge.title',
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
      `Boston & NYC Challenge`,
    ],
    participants: (number) => [
      <>🚩{number}명이 함께하고 있어요.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      's_home.bn_challenge.participants',
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
    ],
    view_more: [`더 보기`, `See more`, 's_home.bn_challenge.view_more', `See more`, `See more`, `See more`, `See more`],
    desc: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      's_home.bn_challenge.desc',
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
    ],
    btn: [
      `챌린지 도전하기`,
      `Join immediately`,
      's_home.bn_challenge.btn',
      `Join immediately`,
      `Join immediately`,
      `Join immediately`,
      `Join immediately`,
    ],
    title_monthly: [
      `월별 수업 미션`,
      `Monthly Mission`,
      's_home.bn_challenge.title_monthly',
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
    ],
    timer_end: (day) => [
      <>{day}일 후 종료</>,
      <>Ends in {day} day(s)</>,
      's_home.bn_challenge.timer_end',
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
      <>Ends in {day} day(s)</>,
    ],
    tooltip: [
      `[나의 챌린지 현황]에서 포인트를 받으세요!`,
      `Receive points from [My Challenge Board]!`,
      's_home.bn_challenge.tooltip',
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
      `Receive points from [My Challenge Board]!`,
    ],
    desc_point: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      's_home.bn_challenge.desc_point',
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
    ],
  },
  hero: {
    title: {
      desktop: [
        `아이비리그 출신\n원어민 튜터와 함께하는\n1:1 맞춤 화상영어`,
        `1:1 online English\nlessons with tutors\nof the top universities`,
        's_home.hero.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `아이비리그 출신\n원어민 튜터와 함께하는\n1:1 맞춤 화상영어`,
        `1:1 online English\nlessons with tutors\nof the top universities`,
        's_home.hero.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    subtitle: {
      desktop: [
        `당신이 꿈꾸던 영어 실력과 커리어를 링글에서`,
        `Improve your English, Unlock your potential`,
        's_home.hero.subtitle.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `당신이 꿈꾸던 영어 실력과 커리어를 링글에서`,
        `Improve your English, Unlock your potential`,
        's_home.hero.subtitle.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`무료로 튜터 둘러보기`, `Meet our tutors`, 's_home.hero.cta.desktop', ``, ``, ``, ``],
      mobile: [`무료로 튜터 둘러보기`, `Meet our tutors`, 's_home.hero.cta.mobile', ``, ``, ``, ``],
    },
  },
  tutor_title: {
    subtitle: {
      desktop: [
        `똑똑하고 성실한 튜터`,
        `Smart and sincere tutors`,
        's_home.tutor_title.subtitle.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `똑똑하고 성실한 튜터`,
        `Smart and sincere tutors`,
        's_home.tutor_title.subtitle.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title: {
      desktop: [
        `영어로 생각을 나누는\n명문대 원어민 튜터`,
        `Discuss in-depth with\ntutors from top schools`,
        's_home.tutor_title.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영어로 생각을 나누는\n명문대 원어민 튜터`,
        `Discuss in-depth with\ntutors from top schools`,
        's_home.tutor_title.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `원어민은 많지만 고급 표현과 영어로\n생각하는 법을 배울 수 있는 튜터는 따로 있죠.`,
        `Not everyone can teach how to think\nand express yourself eloquently in English.`,
        's_home.tutor_title.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `원어민은 많지만 고급 표현과 영어로\n생각하는 법을 배울 수 있는 튜터는 따로 있죠.`,
        `Not everyone can teach how to think\nand express yourself eloquently in English.`,
        's_home.tutor_title.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  tutor_txt_docs: {
    title_white: {
      desktop: [
        `머릿 속에서 번역하고\n말하느라 영어가\n부자연스럽나요?`,
        `Is your English awkward\nbecause you're trying to\ntranslate in your head?`,
        's_home.tutor_txt_docs.title_white.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `머릿 속에서 번역하고\n말하느라 영어가\n부자연스럽나요?`,
        `Is your English awkward\nbecause you're trying to\ntranslate in your head?`,
        's_home.tutor_txt_docs.title_white.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title_purple: {
      desktop: [
        `원어민의 영어를 알려주는\n튜터와 연습하세요.`,
        `Learn from a tutor who can\nteach you to talk like a native.`,
        's_home.tutor_txt_docs.title_purple.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `원어민의 영어를 알려주는\n튜터와 연습하세요.`,
        `Learn from a tutor who can\nteach you to talk like a native.`,
        's_home.tutor_txt_docs.title_purple.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `단순한 문법 교정을 넘어 현지 원어민의 고급 표현과 논리적인 문장 구조를 링글 튜터에게 배워보세요. 훨씬 자연스러운 영어가 가능해집니다.`,
        `Correcting your grammar is a given. Learn how to speak English effectively and logically with Ringle tutors. You'll soon be able to converse naturally in English.`,
        's_home.tutor_txt_docs.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `단순한 문법 교정을 넘어 현지 원어민의 고급 표현과 논리적인 문장 구조를 링글 튜터에게 배워보세요. 훨씬 자연스러운 영어가 가능해집니다.`,
        `Correcting your grammar is a given. Learn how to speak English effectively and logically with Ringle tutors. You'll soon be able to converse naturally in English.`,
        's_home.tutor_txt_docs.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    caption: {
      desktop: [
        `수업 중 사용하는 링글 닥스. 튜터가 교정을 적으며 알려줘요.`,
        `Ringle tutors provide real-time corrections\nduring lessons using Ringle docs.`,
        's_home.tutor_txt_docs.caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 중 사용하는 링글 닥스. 튜터가 교정을 적으며 알려줘요.`,
        `Ringle tutors provide real-time corrections\nduring lessons using Ringle docs.`,
        's_home.tutor_txt_docs.caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  tutor_txt_tutorlist: {
    title_white: {
      desktop: [
        `영어 대화 주제가 바뀌면\n꿀먹은 벙어리가 되나요?`,
        `Have difficulties talking\nabout different topics?`,
        's_home.tutor_txt_tutorlist.title_white.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영어 대화 주제가 바뀌면\n꿀먹은 벙어리가 되나요?`,
        `Have difficulties talking\nabout different topics?`,
        's_home.tutor_txt_tutorlist.title_white.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title_purple: {
      desktop: [
        `진짜 실력을 만들어주는\n튜터를 만나세요.`,
        `Find a tutor who can help\nmake English your own.`,
        's_home.tutor_txt_tutorlist.title_purple.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `진짜 실력을 만들어주는\n튜터를 만나세요.`,
        `Find a tutor who can help\nmake English your own.`,
        's_home.tutor_txt_tutorlist.title_purple.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt_1: {
      desktop: [
        `외국인이 케이팝 말고 한국 정치와 경제에 대해서 이야기하면 우리 모두 감탄하죠. 영어도 마찬가지입니다. 진짜 영어 실력은 다양한 상황에서 대처하는 능력에서 나옵니다.`,
        `It's amazing when foreigners have more to say about Korea than just K-pop. It's the same with English. You know your English proficiency is real when you could talk about diverse topics in English.`,
        's_home.tutor_txt_tutorlist.bodytxt_1.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `외국인이 케이팝 말고 한국 정치와 경제에 대해서 이야기하면 우리 모두 감탄하죠. 영어도 마찬가지입니다. 진짜 영어 실력은 다양한 상황에서 대처하는 능력에서 나옵니다.`,
        `It's amazing when foreigners have more to say about Korea than just K-pop. It's the same with English. You know your English proficiency is real when you could talk about diverse topics in English.`,
        's_home.tutor_txt_tutorlist.bodytxt_1.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt_2: {
      desktop: [
        `영어가 모국어인 사람은 많지만 가벼운 일상 주제부터  비즈니스 케이스, 깊이 있는 토론을 함께 연습할 수 있는 원어민은 많지 않습니다. 다양한 전공과 관심사를 가진 똑똑한 튜터는 링글에만 있습니다.`,
        `There are many native English speakers, but few can hold in-depth discussions on different topics, from everyday issues to business trends. At Ringle, you can meet intelligent tutors who are trained in various subjects have diverse interests.`,
        's_home.tutor_txt_tutorlist.bodytxt_2.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영어가 모국어인 사람은 많지만 가벼운 일상 주제부터  비즈니스 케이스, 깊이 있는 토론을 함께 연습할 수 있는 원어민은 많지 않습니다. 다양한 전공과 관심사를 가진 똑똑한 튜터는 링글에만 있습니다.`,
        `There are many native English speakers, but few can hold in-depth discussions on different topics, from everyday issues to business trends. At Ringle, you can meet intelligent tutors who are trained in various subjects have diverse interests.`,
        's_home.tutor_txt_tutorlist.bodytxt_2.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    totalnumber: {
      desktop: [
        `전체 튜터 수\n1,500명`,
        `Total # of tutors\n1,500`,
        's_home.tutor_txt_tutorlist.totalnumber.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `전체 튜터 수\n1,500명`,
        `Total # of tutors\n1,500`,
        's_home.tutor_txt_tutorlist.totalnumber.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    graduates: {
      desktop: [
        `직장인/대학원생\n574명`,
        `Graduates/graduate students\n574`,
        's_home.tutor_txt_tutorlist.graduates.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `직장인/대학원생\n574명`,
        `Graduates/graduate students\n574`,
        's_home.tutor_txt_tutorlist.graduates.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    economics: {
      desktop: [
        `경영/경제 전공\n200명`,
        `Business/economics majors\n200`,
        's_home.tutor_txt_tutorlist.economics.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `경영/경제 전공\n200명`,
        `Business/economics majors\n200`,
        's_home.tutor_txt_tutorlist.economics.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    law: {
      desktop: [`법학 전공\n60명`, `Law majors\n60`, 's_home.tutor_txt_tutorlist.law.desktop', ``, ``, ``, ``],
      mobile: [`법학 전공\n60명`, `Law majors\n60`, 's_home.tutor_txt_tutorlist.law.mobile', ``, ``, ``, ``],
    },
    engineering: {
      desktop: [
        `공학 전공\n150명`,
        `Engineering majors\n150`,
        's_home.tutor_txt_tutorlist.engineering.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `공학 전공\n150명`,
        `Engineering majors\n150`,
        's_home.tutor_txt_tutorlist.engineering.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    medical: {
      desktop: [`의학 전공\n50명`, `Medical majors\n50`, 's_home.tutor_txt_tutorlist.medical.desktop', ``, ``, ``, ``],
      mobile: [`의학 전공\n50명`, `Medical majors\n50`, 's_home.tutor_txt_tutorlist.medical.mobile', ``, ``, ``, ``],
    },
  },
  tutor_txt_ivyleague: {
    title_white: {
      desktop: [
        `아이비리그 학생들의\n영어, 경험을 훔칠 수 있다면?`,
        `Wish you could become like Ivy-league students?`,
        's_home.tutor_txt_ivyleague.title_white.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `아이비리그 학생들의\n영어, 경험을 훔칠 수 있다면?`,
        `Wish you could become like Ivy-league students?`,
        's_home.tutor_txt_ivyleague.title_white.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title_purple: {
      desktop: [
        `상위 1% 실력의\n튜터를 만나보세요.`,
        `Meet the world's\ntop 1% tutors.`,
        's_home.tutor_txt_ivyleague.title_purple.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `상위 1% 실력의\n튜터를 만나보세요.`,
        `Meet the world's\ntop 1% tutors.`,
        's_home.tutor_txt_ivyleague.title_purple.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글의 튜터들은 대학 입학을 준비할 때부터 최고 수준의 논리적 사고력과 에세이 실력을 매일 갈고닦습니다. 영∙미 상위 1% 학생들이 가진 고급 표현력, 글쓰기 역량은 오직 링글에서만 배울 수 있습니다.`,
        `Our tutors have been training their logical thinking and essay-writing skills everyday, ever since they began preparing to enter a university. At Ringle, you can learn vocabulary and writing skills that only the top 1% students in the US and UK possess.`,
        's_home.tutor_txt_ivyleague.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글의 튜터들은 대학 입학을 준비할 때부터 최고 수준의 논리적 사고력과 에세이 실력을 매일 갈고닦습니다. 영∙미 상위 1% 학생들이 가진 고급 표현력, 글쓰기 역량은 오직 링글에서만 배울 수 있습니다.`,
        `Our tutors have been training their logical thinking and essay-writing skills everyday, ever since they began preparing to enter a university. At Ringle, you can learn vocabulary and writing skills that only the top 1% students in the US and UK possess.`,
        's_home.tutor_txt_ivyleague.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  tutor_txt_business: {
    title_white: {
      desktop: [
        `대학생 튜터는\n비즈니스 영어를 못한다?`,
        `Think university students can't teach Business English?`,
        's_home.tutor_txt_business.title_white.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `대학생 튜터는\n비즈니스 영어를 못한다?`,
        `Think university students can't teach Business English?`,
        's_home.tutor_txt_business.title_white.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title_purple: {
      desktop: [
        `나이보다 논리적 설득력이\n더 중요합니다.`,
        `It's not age that matters, but the ability to be logically persuasive.`,
        's_home.tutor_txt_business.title_purple.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `나이보다 논리적 설득력이\n더 중요합니다.`,
        `It's not age that matters, but the ability to be logically persuasive.`,
        's_home.tutor_txt_business.title_purple.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt_1: {
      desktop: [
        `사회생활 경험이 비즈니스 영어를 잘하기 위한 필수 조건일까요? 비즈니스 환경의 커뮤니케이션에서 중요한 것은 영어에 설득력을 더하는 일입니다.`,
        `Do you think career experience is essential for Business English?
Actually, what's more important is knowing how to be persuasive in English.`,
        's_home.tutor_txt_business.bodytxt_1.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `사회생활 경험이 비즈니스 영어를 잘하기 위한 필수 조건일까요? 비즈니스 환경의 커뮤니케이션에서 중요한 것은 영어에 설득력을 더하는 일입니다.`,
        `Do you think career experience is essential for Business English?
Actually, what's more important is knowing how to be persuasive in English.`,
        's_home.tutor_txt_business.bodytxt_1.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt_2: {
      desktop: [
        `영∙미 명문대 학생들은 교수와 동료를 설득해야하는 치열한 환경 속에서 어떤 상황에서도 논리적인 설득과 생각 구조화를 할 수 있도록 훈련 받습니다.`,
        `Students at top US and UK universities train rigorously with professors and peers to think structurally and be logically persuasive in any situation.`,
        's_home.tutor_txt_business.bodytxt_2.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영∙미 명문대 학생들은 교수와 동료를 설득해야하는 치열한 환경 속에서 어떤 상황에서도 논리적인 설득과 생각 구조화를 할 수 있도록 훈련 받습니다.`,
        `Students at top US and UK universities train rigorously with professors and peers to think structurally and be logically persuasive in any situation.`,
        's_home.tutor_txt_business.bodytxt_2.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt_3: {
      desktop: [
        `단순히 비즈니스 용어만 배우는 게 아니라, 영어로 내 생각을 잘 전달하는 법을 배울 수 있는 곳은 링글밖에 없습니다.`,
        `Ringle is the only place you could learn how to express your thoughts clearly and persuasively in English.`,
        's_home.tutor_txt_business.bodytxt_3.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `단순히 비즈니스 용어만 배우는 게 아니라, 영어로 내 생각을 잘 전달하는 법을 배울 수 있는 곳은 링글밖에 없습니다.`,
        `Ringle is the only place you could learn how to express your thoughts clearly and persuasively in English.`,
        's_home.tutor_txt_business.bodytxt_3.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  tutor_txt_transit: {
    desktop: [
      `링글의 수업 시스템이\n튜터와의 시간을\n더욱 가치있게 해줍니다.`,
      `Ringle's learning system\ndelivers the most value\nfor your time with tutors.`,
      's_home.tutor_txt_transit.desktop',
      ``,
      ``,
      ``,
      ``,
    ],
    mobile: [
      `링글의 수업 시스템이\n튜터와의 시간을\n더욱 가치있게 해줍니다.`,
      `Ringle's learning system\ndelivers the most value\nfor your time with tutors.`,
      's_home.tutor_txt_transit.mobile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_title: {
    subtitle: {
      desktop: [
        `나만을 위한 맞춤형 수업`,
        `Fully personalized lessons`,
        's_home.lesson_title.subtitle.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `나만을 위한 맞춤형 수업`,
        `Fully personalized lessons`,
        's_home.lesson_title.subtitle.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title: {
      desktop: [
        `수업 시간부터 방식까지,\n나에게 딱 맞게`,
        `From scheduling lessons\nto teaching style.`,
        's_home.lesson_title.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 시간부터 방식까지,\n나에게 딱 맞게`,
        `From scheduling lessons\nto teaching style.`,
        's_home.lesson_title.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `나의 상황이나 공부 목적, 영어 실력에 맞춰\n수업을 유연하게 조정할 수 있어요.`,
        `Adjust lessons flexibly to suit your situation,\npurpose, and level of proficiency.`,
        's_home.lesson_title.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `나의 상황이나 공부 목적, 영어 실력에 맞춰\n수업을 유연하게 조정할 수 있어요.`,
        `Adjust lessons flexibly to suit your situation,\npurpose, and level of proficiency.`,
        's_home.lesson_title.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  lesson_txt_time: {
    title: {
      desktop: [
        `야근, 출장 때문에\n수업 놓친적 있으시죠?`,
        `Ever missed a lesson\nbecause of work or a trip?`,
        's_home.lesson_txt_time.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `야근, 출장 때문에\n수업 놓친적 있으시죠?`,
        `Ever missed a lesson\nbecause of work or a trip?`,
        's_home.lesson_txt_time.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `정해진 수업 시간에 얽매일 필요 없어요. 20분/40분 수업 중 선택하여 내가 원하는 시간과 요일에 편하게 수업을 들으세요.`,
        `Don't be pressured to make time regularly. Book 20 or 40 minute lessons for the day and time that suits you.`,
        's_home.lesson_txt_time.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `정해진 수업 시간에 얽매일 필요 없어요. 20분/40분 수업 중 선택하여 내가 원하는 시간과 요일에 편하게 수업을 들으세요.`,
        `Don't be pressured to make time regularly. Book 20 or 40 minute lessons for the day and time that suits you.`,
        's_home.lesson_txt_time.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    caption: {
      desktop: [
        `*수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
        `*Lesson slots available from 5 am to 2 pm / 7 pm to 3 am (KST)`,
        's_home.lesson_txt_time.caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `*수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
        `*Lesson slots available from 5 am to 2 pm / 7 pm to 3 am (KST)`,
        's_home.lesson_txt_time.caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  lesson_txt_material: {
    title: {
      desktop: [
        `지금 나에게 필요한\n교재를 직접 골라요.`,
        `Freely choose the material that works for you.`,
        's_home.lesson_txt_material.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `지금 나에게 필요한\n교재를 직접 골라요.`,
        `Freely choose the material that works for you.`,
        's_home.lesson_txt_material.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `현재 주목받는 주제부터 비즈니스 케이스, 실전 이메일 연습 등 매일 발행되는 1,000개 이상의 교재로 내가 주제의 수업을 만들어보세요.`,
        `From trending topics to writing business emails, Ringle has more than 1,000 materials you can choose from, with new materials updated everyday.`,
        's_home.lesson_txt_material.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `현재 주목받는 주제부터 비즈니스 케이스, 실전 이메일 연습 등 매일 발행되는 1,000개 이상의 교재로 내가 주제의 수업을 만들어보세요.`,
        `From trending topics to writing business emails, Ringle has more than 1,000 materials you can choose from, with new materials updated everyday.`,
        's_home.lesson_txt_material.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  lesson_txt_resume: {
    title: {
      desktop: [
        `당장 영어 인터뷰가\n코앞이라면?`,
        `Have an impending interview in English?`,
        's_home.lesson_txt_resume.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `당장 영어 인터뷰가\n코앞이라면?`,
        `Have an impending interview in English?`,
        's_home.lesson_txt_resume.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `영어 인터뷰 연습부터 레쥬메 교정, 발표 자료 준비 등 내가  원하는 자료를 직접 업로드하여 연습/교정 수업을 할 수 있어요.`,
        `Get help from Ringle tutors, from editing your resume, preparing your presentation, and practicing the interview.`,
        's_home.lesson_txt_resume.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영어 인터뷰 연습부터 레쥬메 교정, 발표 자료 준비 등 내가  원하는 자료를 직접 업로드하여 연습/교정 수업을 할 수 있어요.`,
        `Get help from Ringle tutors, from editing your resume, preparing your presentation, and practicing the interview.`,
        's_home.lesson_txt_resume.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  lesson_txt_profile: {
    title: {
      desktop: [
        `당신에게 필요한\n대화 상대를 찾고 있나요?`,
        `Looking for the perfect conversation partner?`,
        's_home.lesson_txt_profile.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `당신에게 필요한\n대화 상대를 찾고 있나요?`,
        `Looking for the perfect conversation partner?`,
        's_home.lesson_txt_profile.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `한국어든 영어든 대화가 잘 통해야 지루하지 않죠. 관심사, 자기소개, 그리고 전공을 바탕으로 나와 잘 맞는 튜터를 골라보세요. 아직 잘 모르겠다면 링글팀이 보장하는 추천 튜터로 쉽게 고를 수 있습니다.`,
        `In whatever language, the quality of conversations depend on who you're talking with. Browse the information on our tutors and find your perfect match. You can also start with Ringle's recommended tutors if you have a hard time choosing.`,
        's_home.lesson_txt_profile.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `한국어든 영어든 대화가 잘 통해야 지루하지 않죠. 관심사, 자기소개, 그리고 전공을 바탕으로 나와 잘 맞는 튜터를 골라보세요. 아직 잘 모르겠다면 링글팀이 보장하는 추천 튜터로 쉽게 고를 수 있습니다.`,
        `In whatever language, the quality of conversations depend on who you're talking with. Browse the information on our tutors and find your perfect match. You can also start with Ringle's recommended tutors if you have a hard time choosing.`,
        's_home.lesson_txt_profile.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  lesson_txt_lessonmode: {
    title: {
      desktop: [
        `수업 방식도\n내가 원하는대로 정해요.`,
        `Decide how you want to be taught.`,
        's_home.lesson_txt_lessonmode.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 방식도\n내가 원하는대로 정해요.`,
        `Decide how you want to be taught.`,
        's_home.lesson_txt_lessonmode.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `수업 모드부터 집중 교정 영역까지 꼼꼼히 설정할 수 있어요. 짧은 수업 시간 동안 나에게 꼭 필요한 것에 집중하세요.`,
        `Personalize how you wish to be taught from teaching mode to focus areas. Make the most out of the lesson by specifying what you need the most.`,
        's_home.lesson_txt_lessonmode.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 모드부터 집중 교정 영역까지 꼼꼼히 설정할 수 있어요. 짧은 수업 시간 동안 나에게 꼭 필요한 것에 집중하세요.`,
        `Personalize how you wish to be taught from teaching mode to focus areas. Make the most out of the lesson by specifying what you need the most.`,
        's_home.lesson_txt_lessonmode.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  system_title: {
    subtitle: {
      desktop: [
        `내 실력을 눈으로 확인하는 시스템`,
        `Check your progress at a glance`,
        's_home.system_title.subtitle.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `내 실력을 눈으로 확인하는 시스템`,
        `Check your progress at a glance`,
        's_home.system_title.subtitle.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    title: {
      desktop: [
        `실시간 피드백부터\n수업 상세 분석까지`,
        `From real-time corrections to\ndetailed post-lesson feedback`,
        's_home.system_title.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `실시간 피드백부터\n수업 상세 분석까지`,
        `From real-time corrections to\ndetailed post-lesson feedback`,
        's_home.system_title.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `매 수업 향상되는 실력을\n수업 기록과 결과치로 직접 확인하세요.`,
        `Monitor your progress easily through\nlesson notes and visualized charts.`,
        's_home.system_title.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `매 수업 향상되는 실력을\n수업 기록과 결과치로 직접 확인하세요.`,
        `Monitor your progress easily through\nlesson notes and visualized charts.`,
        's_home.system_title.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  system_txt_docsedit: {
    title: {
      desktop: [
        `튜터가 내 영어를\n실시간으로 고쳐줘요.`,
        `Get real-time corrections from tutors.`,
        's_home.system_txt_docsedit.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `튜터가 내 영어를\n실시간으로 고쳐줘요.`,
        `Get real-time corrections from tutors.`,
        's_home.system_txt_docsedit.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 수업은 튜터와 화면을 공유하여 보면서 진행됩니다. 수업 중 교정이 필요한 내 영어를 튜터가 실시간으로 수업 노트에 받아 적고, 더 나은 표현, 틀린 문법을 제안하면서 이유를 설명해 줍니다.`,
        `Through a shared screen, Ringle tutors check your English in real-time, showing you corrections and better expressions in writing along with verbal explanations.`,
        's_home.system_txt_docsedit.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글 수업은 튜터와 화면을 공유하여 보면서 진행됩니다. 수업 중 교정이 필요한 내 영어를 튜터가 실시간으로 수업 노트에 받아 적고, 더 나은 표현, 틀린 문법을 제안하면서 이유를 설명해 줍니다.`,
        `Through a shared screen, Ringle tutors check your English in real-time, showing you corrections and better expressions in writing along with verbal explanations.`,
        's_home.system_txt_docsedit.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  system_txt_feedback: {
    title: {
      desktop: [
        `24시간 안에 받아보는\n튜터의 수업 피드백.`,
        `Receive tutor's feedback report within 24 hours.`,
        's_home.system_txt_feedback.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `24시간 안에 받아보는\n튜터의 수업 피드백.`,
        `Receive tutor's feedback report within 24 hours.`,
        's_home.system_txt_feedback.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `수업 내용을 바탕으로 총점, 분야별 수업 점수, 그리고 내가 집중적으로 공부해야 할 영역을 튜터가 정리해서 알려줘요. 피드백 리포트를 통해 수업마다 내 실력을 객관적으로 점검할 수 있어요.`,
        `After each lesson, Ringle tutors will send you feedback including how you scored overall and on different aspects and the areas you should focus on. These feedback reports will help you review your English objectively.`,
        's_home.system_txt_feedback.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 내용을 바탕으로 총점, 분야별 수업 점수, 그리고 내가 집중적으로 공부해야 할 영역을 튜터가 정리해서 알려줘요. 피드백 리포트를 통해 수업마다 내 실력을 객관적으로 점검할 수 있어요.`,
        `After each lesson, Ringle tutors will send you feedback including how you scored overall and on different aspects and the areas you should focus on. These feedback reports will help you review your English objectively.`,
        's_home.system_txt_feedback.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  system_txt_conversation: {
    title: {
      desktop: [
        `대화 내용이\n사라지지 않고 남아요.`,
        `Don't worry about taking notes.`,
        's_home.system_txt_conversation.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `대화 내용이\n사라지지 않고 남아요.`,
        `Don't worry about taking notes.`,
        's_home.system_txt_conversation.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 수업 시간 동안 이야기한 내용은 모두 녹음되어 자동으로 스크립트 처리됩니다. 수업이 끝나도 튜터가 교정해 준 내용을 다시 듣고, 읽어보면서 복습할 수 있어요.`,
        `Your conversation with the tutor during the lesson is  recorded and transcribed automatically so that you could review the tutor's corrections and what you learned during the lesson.`,
        's_home.system_txt_conversation.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글 수업 시간 동안 이야기한 내용은 모두 녹음되어 자동으로 스크립트 처리됩니다. 수업이 끝나도 튜터가 교정해 준 내용을 다시 듣고, 읽어보면서 복습할 수 있어요.`,
        `Your conversation with the tutor during the lesson is  recorded and transcribed automatically so that you could review the tutor's corrections and what you learned during the lesson.`,
        's_home.system_txt_conversation.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  system_txt_analysis: {
    title: {
      desktop: [
        `반복되는 나쁜 습관을 분석결과로 고치세요.`,
        `Correct bad habits identified by analysis results.`,
        's_home.system_txt_analysis.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `반복되는 나쁜 습관을 분석결과로 고치세요.`,
        `Correct bad habits identified by analysis results.`,
        's_home.system_txt_analysis.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `특정 단어의 반복 사용, 불필요한 필러 워드 사용 등 잘 고쳐지지 않는 영어 말하기 습관 분석을 통해 직접 확인하고 개선하세요.`,
        `Find out your speech habits and correct bad ones, such as repetitive words and unnecessary fillers, based on the analysis provided by Ringle.`,
        's_home.system_txt_analysis.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `특정 단어의 반복 사용, 불필요한 필러 워드 사용 등 잘 고쳐지지 않는 영어 말하기 습관 분석을 통해 직접 확인하고 개선하세요.`,
        `Find out your speech habits and correct bad ones, such as repetitive words and unnecessary fillers, based on the analysis provided by Ringle.`,
        's_home.system_txt_analysis.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  content_title: {
    subtitle: {
      desktop: [`링글과 함께하는 하루`, `A day with Ringle`, 's_home.content_title.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`링글과 함께하는 하루`, `A day with Ringle`, 's_home.content_title.subtitle.mobile', ``, ``, ``, ``],
    },
    title: {
      desktop: [
        `수업 밖에서도\n영어를 놓치지 않도록`,
        `Keep English close even\noutside lessons`,
        's_home.content_title.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `수업 밖에서도\n영어를 놓치지 않도록`,
        `Keep English close even\noutside lessons`,
        's_home.content_title.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  content_txt_morning: {
    subtitle: {
      desktop: [`아침 9시`, `9 AM.`, 's_home.content_txt_morning.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`아침 9시`, `9 AM.`, 's_home.content_txt_morning.subtitle.mobile', ``, ``, ``, ``],
    },
    title: {
      desktop: [
        `출근길 아침\n새로운 교재로 리딩 연습`,
        `Read Ringle's latest materials\nduring commute`,
        's_home.content_txt_morning.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `출근길 아침\n새로운 교재로 리딩 연습`,
        `Read Ringle's latest materials\nduring commute`,
        's_home.content_txt_morning.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `가장 트렌디한 사회 이슈부터, 정치, 경제 비즈니스 케이스, 일상 대화 주제까지. 유료 뉴스레터 부럽지 않은 링글의 무료 교재를 링글앱으로 매일 받아보세요.`,
        `Receive high-quality materials on diverse topics, including trending topics, social issues, politics, economics, business, and small talk, updated daily via Ringle app.`,
        's_home.content_txt_morning.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `가장 트렌디한 사회 이슈부터, 정치, 경제 비즈니스 케이스, 일상 대화 주제까지. 유료 뉴스레터 부럽지 않은 링글의 무료 교재를 링글앱으로 매일 받아보세요.`,
        `Receive high-quality materials on diverse topics, including trending topics, social issues, politics, economics, business, and small talk, updated daily via Ringle app.`,
        's_home.content_txt_morning.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  content_txt_afternoon: {
    subtitle: {
      desktop: [`오후 1:00`, `1 PM.`, 's_home.content_txt_afternoon.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`오 후 1시`, `1 PM.`, 's_home.content_txt_afternoon.subtitle.mobile', ``, ``, ``, ``],
    },
    title: {
      desktop: [
        `짧은 릴스 영상으로\n3분만에 표현 배우기`,
        `Learn new expressions\nfrom Ringle reels`,
        's_home.content_txt_afternoon.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `짧은 릴스 영상으로\n3분만에 표현 배우기`,
        `Learn new expressions\nfrom Ringle reels`,
        's_home.content_txt_afternoon.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글 인스타그램으로 빠르고 간결하게\n다양한 상황에 필요한 표현을 배워요.`,
        `Learn useful expressions for different situations\nquickly and easily from Ringle's posts on Instagram.`,
        's_home.content_txt_afternoon.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글 인스타그램으로 빠르고 간결하게\n다양한 상황에 필요한 표현을 배워요.`,
        `Learn useful expressions for different situations\nquickly and easily from Ringle's posts on Instagram.`,
        's_home.content_txt_afternoon.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  content_txt_evening: {
    subtitle: {
      desktop: [`저녁 9시`, `9 PM.`, 's_home.content_txt_evening.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`저녁 9시`, `9 PM.`, 's_home.content_txt_evening.subtitle.mobile', ``, ``, ``, ``],
    },
    title: {
      desktop: [
        `영어 원서 읽기부터\n각종 팁까지, 링글 LIVE`,
        `Join Ringle LIVE to read books together, get useful tips, and more`,
        's_home.content_txt_evening.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영어 원서 읽기부터\n각종 팁까지, 링글 LIVE`,
        `Join Ringle LIVE to read books together, get useful tips, and more`,
        's_home.content_txt_evening.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `링글의 인기 튜터들이 진행하는 원서 읽기부터 표현법, 인터뷰 팁 등 다양한 주제의\n웨비나로 폭넓게 영어 공부해 보세요.`,
        `Make studying English fun by joining webinars hosted by Ringle's featured tutors on diverse topics.`,
        's_home.content_txt_evening.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글의 인기 튜터들이 진행하는 원서 읽기부터 표현법, 인터뷰 팁 등 다양한 주제의\n웨비나로 폭넓게 영어 공부해 보세요.`,
        `Make studying English fun by joining webinars hosted by Ringle's featured tutors on diverse topics.`,
        's_home.content_txt_evening.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    forfree_title: {
      desktop: [
        `교재부터 웨비나까지\n이 모든게 가입만해도 무료.`,
        `Access all materials\nand webinars for free`,
        's_home.content_txt_evening.forfree_title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `교재부터 웨비나까지\n이 모든게 가입만해도 무료.`,
        `Access all materials\nand webinars for free`,
        's_home.content_txt_evening.forfree_title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    forfree_caption: {
      desktop: [
        `*일부 웨비나 유료 진행`,
        `*A fee may be charged for some webinars`,
        's_home.content_txt_evening.forfree_caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `*일부 웨비나 유료 진행`,
        `*A fee may be charged for some webinars`,
        's_home.content_txt_evening.forfree_caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  review_title: {
    subtitle: {
      desktop: [`고객후기`, `What Our Customers Say`, 's_home.review_title.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`고객후기`, `What Our Customers Say`, 's_home.review_title.subtitle.mobile', ``, ``, ``, ``],
    },
    seoyoung_title: {
      desktop: [
        `“링글로 반복되는 영어 실수를\n잡았더니 실력이 확 늘었어요.”`,
        `"My English improved\ngreatly after correcting repetitive\nmistakes with Ringle.`,
        's_home.review_title.seoyoung_title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `“링글로 반복되는 영어 실수를\n잡았더니 실력이 확 늘었어요.”`,
        `"My English improved\ngreatly after
correcting repetitive\nmistakes with Ringle.`,
        's_home.review_title.seoyoung_title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    seoyoung_caption: {
      desktop: [
        `장서영님, 디지털 마케터`,
        `Seoyoung Jang, Digital Marketer`,
        's_home.review_title.seoyoung_caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `장서영님, 디지털 마케터`,
        `Seoyoung Jang, Digital Marketer`,
        's_home.review_title.seoyoung_caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    hyunseok_title: {
      desktop: [
        `“링글을 통해 자신감이 생기고\n영어 공포증을 극복했어요.”`,
        `"I became confident and overcomed\nmy fear of English with Ringle.`,
        's_home.review_title.hyunseok_title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `“링글을 통해 자신감이 생기고\n영어 공포증을 극복했어요.”`,
        `"I became confident and overcomed\nmy fear of English with Ringle.`,
        's_home.review_title.hyunseok_title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    hyunseok_caption: {
      desktop: [
        `정현석님, 소프트웨어 개발자`,
        `Hyunseok Jung, Software Developer`,
        's_home.review_title.hyunseok_caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `정현석님, 소프트웨어 개발자`,
        `Hyunseok Jung, Software Developer`,
        's_home.review_title.hyunseok_caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    yoonjin_title: {
      desktop: [
        `“40대 워킹맘의 도전, 링글로\n영어 슬럼프없이 진행 중이에요.”`,
        `"A working mom in my 40s,\nI could study English consistently\nthanks to Ringle.`,
        's_home.review_title.yoonjin_title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `“40대 워킹맘의 도전, 링글로\n영어 슬럼프없이 진행 중이에요.”`,
        `"A working mom in my 40s,\nI could study English consistently\nthanks to Ringle.`,
        's_home.review_title.yoonjin_title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    yoonjin_caption: {
      desktop: [
        `이윤진님, 프리랜서 마케터`,
        `Yoonjin Lee, Freelance Marketer`,
        's_home.review_title.yoonjin_caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `이윤진님, 프리랜서 마케터`,
        `Yoonjin Lee, Freelance Marketer`,
        's_home.review_title.yoonjin_caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    yewon_title: {
      desktop: [
        `“외국인 팀원과 일할 때 필요한\n원어민의 늬앙스를 배워요.”`,
        `"I could now express myself\nsubtly when working with\ninternational colleagues.`,
        's_home.review_title.yewon_title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `“외국인 팀원과 일할 때 필요한\n원어민의 늬앙스를 배워요.”`,
        `"I could now express myself\nsubtly when working with\ninternational colleagues.`,
        's_home.review_title.yewon_title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    yewon_caption: {
      desktop: [
        `최예원님, 제약회사 클리니컬 디렉터`,
        `Yewon Choi, Clinical Director`,
        's_home.review_title.yewon_caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `최예원님, 제약회사 클리니컬 디렉터`,
        `Yewon Choi, Clinical Director`,
        's_home.review_title.yewon_caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  pricing_title: {
    subtitle: {
      desktop: [`가격`, `Pricing`, 's_home.pricing_title.subtitle.desktop', ``, ``, ``, ``],
      mobile: [`가격`, `Pricing`, 's_home.pricing_title.subtitle.mobile', ``, ``, ``, ``],
    },
    title: {
      desktop: [
        `월 11만원으로 이루는\n글로벌을 향한 성장`,
        `Realize your global potential\nstarting at $84/month`,
        's_home.pricing_title.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `월 11만원으로 이루는\n글로벌을 향한 성장`,
        `Realize your global potential\nstarting at $84/month`,
        's_home.pricing_title.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    caption: {
      desktop: [
        `*20분 4회 신규회원 할인 적용 시`,
        `*For four 20-minute lessons with newcomer discount`,
        's_home.pricing_title.caption.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `*20분 4회 신규회원 할인 적용 시`,
        `*For four 20-minute lessons with newcomer discount`,
        's_home.pricing_title.caption.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    bodytxt: {
      desktop: [
        `자신의 상황과 영어 실력에 따라\n수업권 패키지를 선택하세요.`,
        `Choose credits according to\nyour needs and English level.`,
        's_home.pricing_title.bodytxt.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `자신의 상황과 영어 실력에 따라
수업권 패키지를 선택하세요.`,
        `Choose credits according to\nyour needs and English level.`,
        's_home.pricing_title.bodytxt.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`가격 더 알아보기`, `Find out more`, 's_home.pricing_title.cta.desktop', ``, ``, ``, ``],
      mobile: [`가격 더 알아보기`, `Find out more`, 's_home.pricing_title.cta.mobile', ``, ``, ``, ``],
    },
  },
  b2b: {
    title: {
      desktop: [
        `이미 많은 고객분들이 링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed countless dreams to come true`,
        's_home.b2b.title.desktop',
        `已有很多学生正在与Ringle一起实现梦想。`,
        `已經有許多學生正與Ringle一起實現夢想。`,
        `既に多くのお客様がRingleと一緒に夢を現実にしています。`,
        `Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ`,
      ],
      mobile: [
        `이미 많은 고객분들이\n링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed countless\ndreams to come true`,
        's_home.b2b.title.mobile',
        `已有很多学生正在与Ringle一起实现梦想。`,
        `已經有許多學生正與Ringle\n一起實現夢想。`,
        `既に多くのユーザーがRingleと共に\n夢を現実にしています。`,
        `Ringle mở ra những cơ hội\nmới để bạn đạt được giấc mơ`,
      ],
    },
  },
  footer_txt: {
    companyname: {
      desktop: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.desktop',
        `(株)Ringle English Education Service`,
        `Ringle英語教育服務股份有限公司`,
        `ⓒRingle English Education Service`,
        `ⓒRingle English Education Service`,
      ],
      mobile: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.mobile',
        `(株)Ringle English Education Service`,
        `Ringle英語教育服務股份有限公司`,
        `ⓒRingle English Education Service`,
        `ⓒRingle English Education Service`,
      ],
    },
    address: {
      desktop: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
        's_home.footer_txt.address.desktop',
        `首尔江南区德黑兰路5街7号12层`,
        `首爾江南區德黑蘭路5街7號12樓`,
        `大韓民国ソウル特別市瑞草区瑞草路78ギル-22`,
        `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
      ],
      mobile: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
        's_home.footer_txt.address.mobile',
        `首尔江南区德黑兰路5街7号12层`,
        `首爾江南區德黑蘭路5街7號12樓`,
        `大韓民国ソウル特別市瑞草区瑞草路78ギル-22`,
        `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
      ],
    },
    businessnumber: {
      desktop: [
        `사업자등록번호: 876-81-00110`,
        ``,
        's_home.footer_txt.businessnumber.desktop',
        `营业执照号码: 876-81-00110`,
        `營利事業統一編號：876-81-00110`,
        `事業者登録番号 876-81-00110`,
        ``,
      ],
      mobile: [
        `사업자등록번호: 876-81-00110`,
        ``,
        's_home.footer_txt.businessnumber.mobile',
        `营业执照号码: 876-81-00110`,
        `營利事業統一編號：876-81-00110`,
        `事業者登録番号 876-81-00110`,
        ``,
      ],
    },
    purchasenumber: {
      desktop: [
        `통신판매신고번호: 2019-서울강남-04831`,
        ``,
        's_home.footer_txt.purchasenumber.desktop',
        `通信销售申告号码: 2019-首尔江南-04831`,
        `通訊販賣申報編號：2019-首爾江南-04831`,
        `通信販売申告番号 2019-ソウル江南-04831`,
        ``,
      ],
      mobile: [
        `통신판매신고번호: 2019-서울강남-04831`,
        ``,
        's_home.footer_txt.purchasenumber.mobile',
        `通信销售申告号码: 2019-首尔江南-04831`,
        `通訊販賣申報編號：2019-首爾江南-04831`,
        `通信販売申告番号 2019-ソウル江南-04831`,
        ``,
      ],
    },
    phonenumber: {
      desktop: [
        `대표전화: 02-6204-8880`,
        ``,
        's_home.footer_txt.phonenumber.desktop',
        `代表电话: 02-6204-8880`,
        `公司代表號：02-6204-888`,
        `代表電話 02-6204-8880`,
        ``,
      ],
      mobile: [
        `대표전화: 02-6204-8880`,
        ``,
        's_home.footer_txt.phonenumber.mobile',
        `代表电话: 02-6204-8880`,
        `公司代表號：02-6204-888`,
        `代表電話 02-6204-8880`,
        ``,
      ],
    },
    ceo: {
      desktop: [
        `대표이사: 이성파, 이승훈. 개인정보책임자: 이성파`,
        ``,
        's_home.footer_txt.ceo.desktop',
        `代表理事: Lee Seongpa, Lee Seunghun。个人信息负责人: Lee Seongpa`,
        `代表理事：Lee Seongpa、Lee Seunghun。個人資料負責人：Lee Seongpa`,
        `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ`,
        ``,
      ],
      mobile: [
        `대표이사: 이성파, 이승훈. 개인정보책임자: 이성파`,
        ``,
        's_home.footer_txt.ceo.mobile',
        `代表理事: Lee Seongpa, Lee Seunghun。个人信息负责人: Lee Seongpa`,
        `代表理事：Lee Seongpa、Lee Seunghun。個人資料負責人：Lee Seongpa`,
        `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ`,
        ``,
      ],
    },
    email: {
      desktop: [
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        's_home.footer_txt.email.desktop',
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
      ],
      mobile: [
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        's_home.footer_txt.email.mobile',
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
        `contact@ringleplus.com`,
      ],
    },
    helpcenter: {
      desktop: [`헬프센터`, `Help Center`, 's_home.footer_txt.helpcenter.desktop', ``, ``, ``, ``],
      mobile: [`헬프센터`, `Help Center`, 's_home.footer_txt.helpcenter.mobile', ``, ``, ``, ``],
    },
    weekday: {
      desktop: [
        `평일 오전 10:00 - 오후 6:30(KST)`,
        `Weekdays from 10 am to 6 pm (KST)`,
        's_home.footer_txt.weekday.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `평일 오전 10:00 - 오후 6:30(KST)`,
        `Weekdays from 10 am to 6 pm (KST)`,
        's_home.footer_txt.weekday.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    break: {
      desktop: [
        `점심시간: 오후 12:00 - 오후 1:00(KST)`,
        `Lunch break from 12 pm to 1 pm (KST)`,
        's_home.footer_txt.break.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `점심시간: 오후 12:00 - 오후 1:00(KST)`,
        `Lunch break from 12 pm to 1 pm (KST)`,
        's_home.footer_txt.break.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  footer_button: {
    content: {
      desktop: [`교재`, `Content`, 's_home.footer_button.content.desktop', ``, ``, ``, ``],
      mobile: [`교재`, `Content`, 's_home.footer_button.content.mobile', ``, ``, ``, ``],
    },
    tutors: {
      desktop: [`튜터`, `Tutors`, 's_home.footer_button.tutors.desktop', ``, ``, ``, ``],
      mobile: [`튜터`, `Tutors`, 's_home.footer_button.tutors.mobile', ``, ``, ``, ``],
    },
    pricing: {
      desktop: [`가격`, `Pricing`, 's_home.footer_button.pricing.desktop', ``, ``, ``, ``],
      mobile: [`가격`, `Pricing`, 's_home.footer_button.pricing.mobile', ``, ``, ``, ``],
    },
    b2b: {
      desktop: [`기업제휴`, `B2B Partnerships`, 's_home.footer_button.b2b.desktop', ``, ``, ``, ``],
      mobile: [`기업제휴`, `B2B Partnerships`, 's_home.footer_button.b2b.mobile', ``, ``, ``, ``],
    },
    careers: {
      desktop: [`인재 채용`, `Careers`, 's_home.footer_button.careers.desktop', ``, ``, ``, ``],
      mobile: [`인재 채용`, `Careers`, 's_home.footer_button.careers.mobile', ``, ``, ``, ``],
    },
    team: {
      desktop: [`링글 팀`, `Ringle Team`, 's_home.footer_button.team.desktop', ``, ``, ``, ``],
      mobile: [`링글 팀`, `Ringle Team`, 's_home.footer_button.team.mobile', ``, ``, ``, ``],
    },
    blog: {
      desktop: [`링글 블로그`, `Ringle Blog`, 's_home.footer_button.blog.desktop', ``, ``, ``, ``],
      mobile: [`링글 블로그`, `Ringle Blog`, 's_home.footer_button.blog.mobile', ``, ``, ``, ``],
    },
    faq: {
      desktop: [`자주 묻는 질문`, `FAQs`, 's_home.footer_button.faq.desktop', ``, ``, ``, ``],
      mobile: [`자주 묻는 질문`, `FAQs`, 's_home.footer_button.faq.mobile', ``, ``, ``, ``],
    },
    kakao: {
      desktop: [`카카오톡 문의`, `Inquire via KakaoTalk`, 's_home.footer_button.kakao.desktop', ``, ``, ``, ``],
      mobile: [`카카오톡 문의`, `Inquire via KakaoTalk`, 's_home.footer_button.kakao.mobile', ``, ``, ``, ``],
    },
  },
  faq: {
    title: {
      desktop: [`자주 묻는 질문`, `Frequently Asked Questions`, 's_home.faq.title.desktop', ``, ``, ``, ``],
      mobile: [`자주 묻는 질문`, `Frequently Asked Questions`, 's_home.faq.title.mobile', ``, ``, ``, ``],
    },
  },
  faq_1: {
    question: {
      desktop: [`영어 실력 초급인데, 수강할 수 있을까요?`, ``, 's_home.faq_1.question.desktop', ``, ``, ``, ``],
      mobile: [`영어 실력 초급인데, 수강할 수 있을까요?`, ``, 's_home.faq_1.question.mobile', ``, ``, ``, ``],
    },
    answer: {
      desktop: [
        `링글 수강생 분들의 50% 이상이 초/중급 레벨에 속하는 분으로 영어로 자기 소개를 할 수 있는 정도라면 충분히 수강할 수 있습니다. 특히 일상 교재를 활용하여 수업하면 도움이 될거에요.`,
        ``,
        's_home.faq_1.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글 수강생 분들의 50% 이상이 초/중급 레벨에 속하는 분으로 영어로 자기 소개를 할 수 있는 정도라면 충분히 수강할 수 있습니다. 특히 일상 교재를 활용하여 수업하면 도움이 될거에요.`,
        ``,
        's_home.faq_1.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`초급자를 위한 활용법을 더 알아보시려면`, ``, 's_home.faq_1.cta.desktop', ``, ``, ``, ``],
      mobile: [`초급자를 위한 활용법을 더 알아보시려면`, ``, 's_home.faq_1.cta.mobile', ``, ``, ``, ``],
    },
  },
  faq_2: {
    question: {
      desktop: [
        `링글 1:1 영어 수업은 학원과 어떻게 다른지 궁금해요.`,
        ``,
        's_home.faq_2.question.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글 1:1 영어 수업은 학원과 어떻게 다른지 궁금해요.`,
        ``,
        's_home.faq_2.question.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    answer: {
      desktop: [
        `학생 한 명에게만 집중하는 1:1 수업으로 원하는 시간, 튜터, 교재를 자유롭게 선택할 수 있습니다. 링글의 엄선된 명문대 원어민 튜터, 직접 만드는 교재, 효과적인 학습을 위한 시스템은 공부만 하면 되는 환경을 제공합니다.`,
        ``,
        's_home.faq_2.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `학생 한 명에게만 집중하는 1:1 수업으로 원하는 시간, 튜터, 교재를 자유롭게 선택할 수 있습니다. 링글의 엄선된 명문대 원어민 튜터, 직접 만드는 교재, 효과적인 학습을 위한 시스템은 공부만 하면 되는 환경을 제공합니다.`,
        ``,
        's_home.faq_2.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`차별화된 튜터와 시스템을 더 알아보려면`, ``, 's_home.faq_2.cta.desktop', ``, ``, ``, ``],
      mobile: [`차별화된 튜터와 시스템을 더 알아보려면`, ``, 's_home.faq_2.cta.mobile', ``, ``, ``, ``],
    },
  },
  faq_3: {
    question: {
      desktop: [`커리큘럼은 어떻게 되나요?`, ``, 's_home.faq_3.question.desktop', ``, ``, ``, ``],
      mobile: [`커리큘럼은 어떻게 되나요?`, ``, 's_home.faq_3.question.mobile', ``, ``, ``, ``],
    },
    answer: {
      desktop: [
        `링글은 학생이 직접 커리큘럼을 구성합니다. 다양한 튜터와 교재를 자유롭게 선택하고 수업방식도 필요에 따라 변경할 수 있어요. 학생이 직접 자료를 업로드 할 수도 있고 자유주제를 선택하여 인터뷰나 회화 연습도 가능합니다.`,
        ``,
        's_home.faq_3.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글은 학생이 직접 커리큘럼을 구성합니다. 다양한 튜터와 교재를 자유롭게 선택하고 수업방식도 필요에 따라 변경할 수 있어요. 학생이 직접 자료를 업로드 할 수도 있고 자유주제를 선택하여 인터뷰나 회화 연습도 가능합니다.`,
        ``,
        's_home.faq_3.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  faq_4: {
    question: {
      desktop: [`링글 튜터의 강점은 무엇인지 궁금해요.`, ``, 's_home.faq_4.question.desktop', ``, ``, ``, ``],
      mobile: [`링글 튜터의 강점은 무엇인지 궁금해요.`, ``, 's_home.faq_4.question.mobile', ``, ``, ``, ``],
    },
    answer: {
      desktop: [
        `튜터들이 미국/영국 명문 대학에서 토론과 글쓰기 훈련을 받아 온 만큼 격식에 맞는 영어를 하실 수 있도록 수업을 진행합니다. 양질의 수업을 위하여 링글은 튜터를 4차례에 걸쳐 엄격하게 선발하고 관리합니다.`,
        ``,
        's_home.faq_4.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `튜터들이 미국/영국 명문 대학에서 토론과 글쓰기 훈련을 받아 온 만큼 격식에 맞는 영어를 하실 수 있도록 수업을 진행합니다. 양질의 수업을 위하여 링글은 튜터를 4차례에 걸쳐 엄격하게 선발하고 관리합니다.`,
        ``,
        's_home.faq_4.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  faq_5: {
    question: {
      desktop: [
        `어느 정도 공부를 해야 영어 실력이 향상될지 고민이에요.`,
        ``,
        's_home.faq_5.question.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `어느 정도 공부를 해야 영어 실력이 향상될지 고민이에요.`,
        ``,
        's_home.faq_5.question.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    answer: {
      desktop: [
        `링글에서 영어 실력이 향상한 고객분들은 보통 2주에 최소 3회씩 수업합니다. 꾸준히 수업을 듣고 링글의 다양한 복습 자료를 통해 매 수업을 내 것으로 만든다면 영어 실력을 더욱 향상시킬 수 있습니다.`,
        ``,
        's_home.faq_5.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `링글에서 영어 실력이 향상한 고객분들은 보통 2주에 최소 3회씩 수업합니다. 꾸준히 수업을 듣고 링글의 다양한 복습 자료를 통해 매 수업을 내 것으로 만든다면 영어 실력을 더욱 향상시킬 수 있습니다.`,
        ``,
        's_home.faq_5.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  faq_6: {
    question: {
      desktop: [`영문서 교정이 궁금해요.`, ``, 's_home.faq_6.question.desktop', ``, ``, ``, ``],
      mobile: [`영문서 교정이 궁금해요.`, ``, 's_home.faq_6.question.mobile', ``, ``, ``, ``],
    },
    answer: {
      desktop: [
        `영문서 교정 서비스를 이용하여 링글 에디터에게 교정을 받을 수도 있고 1:1 수업을 통해 튜터와 직접 이야기하며 고칠 수도 있습니다. 특히 레쥬메는 1:1 수업으로 교정하시기를 추천 드립니다.`,
        ``,
        's_home.faq_6.answer.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `영문서 교정 서비스를 이용하여 링글 에디터에게 교정을 받을 수도 있고 1:1 수업을 통해 튜터와 직접 이야기하며 고칠 수도 있습니다. 특히 레쥬메는 1:1 수업으로 교정하시기를 추천 드립니다.`,
        ``,
        's_home.faq_6.answer.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`영문서 교정 받는 법 더 알아보려면`, ``, 's_home.faq_6.cta.desktop', ``, ``, ``, ``],
      mobile: [`영문서 교정 받는 법 더 알아보려면`, ``, 's_home.faq_6.cta.mobile', ``, ``, ``, ``],
    },
  },
  tutor_page: {
    title: {
      desktop: [
        `다양한 전공과 관심사를 가진 명문대 원어민 튜터`,
        `Meet intelligent tutors,\ntrained in various subjects\nhave diverse interests`,
        's_home.tutor_page.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `다양한 전공과 관심사를 가진 명문대 원어민 튜터`,
        `Meet intelligent tutors,\ntrained in various subjects\nhave diverse interests`,
        's_home.tutor_page.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cta: {
      desktop: [`무료로 튜터 먼저 둘러보기`, `Meet our tutors`, 's_home.tutor_page.cta.desktop', ``, ``, ``, ``],
      mobile: [`무료로 튜터 먼저 둘러보기`, `Meet our tutors`, 's_home.tutor_page.cta.mobile', ``, ``, ``, ``],
    },
  },
  content_page: {
    cta: {
      desktop: [`무료로 교재 먼저 둘러보기`, `Browse our contents`, 's_home.content_page.cta.desktop', ``, ``, ``, ``],
      mobile: [`무료로 교재 먼저 둘러보기`, `Browse our contents`, 's_home.content_page.cta.mobile', ``, ``, ``, ``],
    },
  },
  business: {
    title: {
      desktop: [
        `200개 이상 기업의 임직원들이 링글과 함께 꿈을 현실로 만들고 있습니다.`,
        `Employees of over 200 firms are realizing their dreams through Ringle.`,
        's_home.business.title.desktop',
        `200多家企业的员工与Ringle一起将梦想变成现实。`,
        `200多家企業的員工與Ringle一起將夢想變成現實。`,
        `200以上の企業の皆様がRingleと共に夢を現実に変えています。`,
        `Nhân viên của hơn 200 công ty đang biến giấc mơ thành hiện thực với Ringle.`,
      ],
      mobile: [
        `200개 이상 기업의 임직원들이 링글과 함께 꿈을 현실로 만들고 있습니다.`,
        `Employees of over 200 firms are realizing their dreams through Ringle.`,
        's_home.business.title.mobile',
        `200多家企业的员工与Ringle一起将梦想变成现实。`,
        `200多家企業的員工與Ringle一起將夢想變成現實。`,
        `200以上の企業の皆様がRingleと共に夢を現実に変えています。`,
        `Nhân viên của hơn 200 công ty đang biến giấc mơ thành hiện thực với Ringle.`,
      ],
    },
    subtitle: {
      desktop: [
        `국내외 스타트업부터 외국계, 대기업까지`,
        `From startups to global corporations`,
        's_home.business.subtitle.desktop',
        `从国内外初创企业到外企、大企业`,
        `從國內外初創企業到外企、大企業`,
        `国内外スタートアップから外資系、大手企業まで`,
        `Từ các khởi nghiệp trong và ngoài nước đến các tập đoàn lớn nước ngoài`,
      ],
      mobile: [
        `국내외 스타트업부터 외국계, 대기업까지`,
        `From startups to global corporations`,
        's_home.business.subtitle.mobile',
        `从国内外初创企业到外企、大企业`,
        `從國內外初創企業到外企、大企業`,
        `国内外スタートアップから外資系、大手企業まで`,
        `Từ các khởi nghiệp trong và ngoài nước đến các tập đoàn lớn nước ngoài`,
      ],
    },
    cta: {
      desktop: [
        `담당자에게 문의하기`,
        `Send us an inquiry`,
        's_home.business.cta.desktop',
        `咨询负责人`,
        `諮詢負責人`,
        `担当者へ問い合わせ`,
        `Liên hệ người phụ trách`,
      ],
      mobile: [
        `담당자에게 문의하기`,
        `Send us an inquiry`,
        's_home.business.cta.mobile',
        `咨询负责人`,
        `諮詢負責人`,
        `担当者へ問い合わせ`,
        `Liên hệ người phụ trách`,
      ],
    },
  },
  business_review: {
    subtitle: {
      desktop: [
        `기업 고객 후기`,
        `Customers' Testimonials`,
        's_home.business_review.subtitle.desktop',
        `企业用户评价`,
        `企業用戶評價`,
        `法人のお客様の声`,
        `Đánh giá của khách hàng doanh nghiệp`,
      ],
      mobile: [
        `기업 고객 후기`,
        `Customers' Testimonials`,
        's_home.business_review.subtitle.mobile',
        `企业用户评价`,
        `企業用戶評價`,
        `法人のお客様の声`,
        `Đánh giá của khách hàng doanh nghiệp`,
      ],
    },
    title: {
      desktop: [
        `링글을 도입한 기업 담당자들의
솔직한 후기를 확인하세요.`,
        `Hear from companies that have
partnered with Ringle.`,
        's_home.business_review.title.desktop',
        `请阅览引进了Ringle的企业负责人的
真实使用评价。`,
        `請閱覽引進了Ringle的企業負責人的
真實使用評價。`,
        `Ringle導入企業の担当者様の
リアルレビュー`,
        `Đánh giá trung thực từ những người phụ trách các doanh nghiệp hợp tác với Ringle`,
      ],
      mobile: [
        `링글을 도입한 기업 담당자들의
솔직한 후기를 확인하세요.`,
        `Hear from companies that have
partnered with Ringle.`,
        's_home.business_review.title.mobile',
        `请阅览引进了Ringle的企业负责人的
真实使用评价。`,
        `請閱覽引進了Ringle的企業負責人的
真實使用評價。`,
        `Ringle導入企業の担当者様の
リアルレビュー`,
        `Đánh giá trung thực từ những người phụ trách các doanh nghiệp hợp tác với Ringle`,
      ],
    },
    toss: {
      desktop: [
        `개인 역량 개발을 위한 지원을 아낌없이 제공하는 토스는 임직원의 자유도를 중요시합니다. 
수업 시간, 교재, 튜터를 자유롭게 선택해 수업할 수 있는 링글의 유연함이 토스의 이런 문화와 잘 맞는 것 같습니다.`,
        `At Toss, we're all about personal development and employee freedom. 
Ringle's flexibility in scheduling and choosing materials and tutors complements our corporate culture.`,
        's_home.business_review.toss.desktop',
        `毫不吝啬地提供个人能力开发支援的toss非常重视员工的自由度。 
Ringle可以自由选择上课时间、教材、老师进行授课的的灵活性与Toss的企业文化不谋而合。`,
        `毫不吝嗇地提供個人能力開發支援的toss非常重視員工的自由度。 
Ringle可以自由選擇上課時間、教材、老師進行授課的的靈活性與Toss的企業文化不謀而合。`,
        `個人の能力向上のため惜しみなくサポートを提供するTossは、全社員の自由度を重視しています。
レッスン時間、教材、チューターを自由に選べるRingleの柔軟性がTossの企業文化とマッチしていると思います。`,
        `Với sự hỗ trợ không ngừng nghỉ để phát triển năng lực cá nhân, Toss coi trọng sự tự do của nhân viên.
Sự linh hoạt của Ringle cho phép tự do lựa chọn giờ học, tài liệu, gia sư phù hợp với văn hóa này của Toss.`,
      ],
      mobile: [
        `개인 역량 개발을 위한 지원을 아낌없이 제공하는 토스는 임직원의 자유도를 중요시합니다. 
수업 시간, 교재, 튜터를 자유롭게 선택해 수업할 수 있는 링글의 유연함이 토스의 이런 문화와 잘 맞는 것 같습니다.`,
        `At Toss, we're all about personal development and employee freedom. 
Ringle's flexibility in scheduling and choosing materials and tutors complements our corporate culture.`,
        's_home.business_review.toss.mobile',
        `毫不吝啬地提供个人能力开发支援的toss非常重视员工的自由度。 
Ringle可以自由选择上课时间、教材、老师进行授课的的灵活性与Toss的企业文化不谋而合。`,
        `毫不吝嗇地提供個人能力開發支援的toss非常重視員工的自由度。 
Ringle可以自由選擇上課時間、教材、老師進行授課的的靈活性與Toss的企業文化不謀而合。`,
        `個人の能力向上のため惜しみなくサポートを提供するTossは、全社員の自由度を重視しています。
レッスン時間、教材、チューターを自由に選べるRingleの柔軟性がTossの企業文化とマッチしていると思います。`,
        `Với sự hỗ trợ không ngừng nghỉ để phát triển năng lực cá nhân, Toss coi trọng sự tự do của nhân viên.
Sự linh hoạt của Ringle cho phép tự do lựa chọn giờ học, tài liệu, gia sư phù hợp với văn hóa này của Toss.`,
      ],
    },
    sk: {
      desktop: [
        `실무에 영어를 많이 쓰는 임직원의 링글 활용도가 높아 3년 이상 꾸준히 사내 어학 프로그램으로 제공하고 있습니다. 
특히 원하는 때에 앱과 웹으로 학습할 수 있어 시간과 공간의 제약이 적고, 바쁜 임직원들의 만족도가 높은 것 같습니다.`,
        `As our in-house language program for more than three years, Ringle is utilized heavily by employees whose jobs require English proficiency. 
It's especially popular among employees with busy schedules because they could take lessons without being constrained by time and space.`,
        's_home.business_review.sk.desktop',
        `在实务中经常使用英语的职员们使用Ringle的频率很高，因此3年以来Ringle一直被用作公司内部语言培训项目。 
特别是可以在需要的时候通过APP和网页学习，不受时间和空间的限制，平日忙碌的员工们的满意度很高。`,
        `在實務中經常使用英語的職員們使用Ringle的頻率很高，因此3年以來Ringle一直被用作公司內部語言培訓專案。 
特別是可以在需要的時候通過APP和網頁學習，不受時間和空間的限制，平日忙碌的員工們的滿意度很高。`,
        `業務で英語を多く使う社員のRingle活用度が高く、3年以上継続して社内の語学プログラムとして提供しています。
いつでもアプリやウェブで学習できるので時間や空間の制限が少なく、忙しい社員たちにとっても満足度が高いと思われます。`,
        `Vì những nhân viên sử dụng tiếng Anh nhiều trong công việc có độ ứng dụng Ringle cao nên chúng tôi đã cung cấp Ringle như một chương trình ngôn ngữ nội bộ trong hơn 3 năm qua.
Đặc biệt, vì có thể học thông qua ứng dụng và web bất cứ khi nào, ít bị hạn chế về thời gian và không gian nên các nhân viên bận rộn dường như rất hài lòng`,
      ],
      mobile: [
        `실무에 영어를 많이 쓰는 임직원의 링글 활용도가 높아 3년 이상 꾸준히 사내 어학 프로그램으로 제공하고 있습니다. 
특히 원하는 때에 앱과 웹으로 학습할 수 있어 시간과 공간의 제약이 적고, 바쁜 임직원들의 만족도가 높은 것 같습니다.`,
        `As our in-house language program for more than three years, Ringle is utilized heavily by employees whose jobs require English proficiency. 
It's especially popular among employees with busy schedules because they could take lessons without being constrained by time and space.`,
        's_home.business_review.sk.mobile',
        `在实务中经常使用英语的职员们使用Ringle的频率很高，因此3年以来Ringle一直被用作公司内部语言培训项目。 
特别是可以在需要的时候通过APP和网页学习，不受时间和空间的限制，平日忙碌的员工们的满意度很高。`,
        `在實務中經常使用英語的職員們使用Ringle的頻率很高，因此3年以來Ringle一直被用作公司內部語言培訓專案。 
特別是可以在需要的時候通過APP和網頁學習，不受時間和空間的限制，平日忙碌的員工們的滿意度很高。`,
        `業務で英語を多く使う社員のRingle活用度が高く、3年以上継続して社内の語学プログラムとして提供しています。
いつでもアプリやウェブで学習できるので時間や空間の制限が少なく、忙しい社員たちにとっても満足度が高いと思われます。`,
        `Vì những nhân viên sử dụng tiếng Anh nhiều trong công việc có độ ứng dụng Ringle cao nên chúng tôi đã cung cấp Ringle như một chương trình ngôn ngữ nội bộ trong hơn 3 năm qua.
Đặc biệt, vì có thể học thông qua ứng dụng và web bất cứ khi nào, ít bị hạn chế về thời gian và không gian nên các nhân viên bận rộn dường như rất hài lòng`,
      ],
    },
    yanolja: {
      desktop: [
        `기존에 사용하던 전화 영어보다 더 다양한 튜터와 교재가 제공되어 200명 이상의 교육 대상자들이 만족할거란 
확신이 링글을 선택하게 된 가장 큰 이유입니다. 예산 내에서 좋은 서비스를 구성원들에게 제공할 수 있어 무엇보다 기쁘게 생각합니다.`,
        `We chose Ringle because it offers a greater variety of tutors and materials than our previous phone-based English program. 
We knew that we will be able to satisfy our 200+ employees better, we're pleased that such a good program can be offered within our budget.`,
        's_home.business_review.yanolja.desktop',
        `与之前使用的电话英语相比，Ringle提供了更多的导师选择和教材，选择Ringle最重要的原因就是确信200多名培训生会满意的这份自信。 没有什么比能够在预算内向员工们提供好的服务更令人高兴的了。`,
        `與之前使用的電話英語相比，Ringle提供了更多的導師選擇和教材，選擇Ringle最重要的原因就是確信200多名培訓生會滿意的這份自信。 沒有什麼比能夠在預算內向員工們提供好的服務更令人高興的了。`,
        `以前利用していた電話英会話よりも、豊富なチューター数と教材により200名以上の教育対象者が満足できるだろうという
確信がRingleを選択した一番大きな理由です。予算内で良質のサービスを提供できることを何よりも嬉しく思います。`,
        `Lý do lớn nhất để chúng tôi chọn Ringle vì chắc chắn rằng hơn 200 đối tượng đào tạo sẽ hài lòng với việc được cung cấp gia sư và tài liệu nhiều hơn chương trình tiếng Anh qua điện thoại trước đây. Chúng tôi rất vui mừng vì có thể cung cấp dịch vụ tốt như vậy trong phạm vi ngân sách của mình.`,
      ],
      mobile: [
        `기존에 사용하던 전화 영어보다 더 다양한 튜터와 교재가 제공되어 200명 이상의 교육 대상자들이 만족할거란 
확신이 링글을 선택하게 된 가장 큰 이유입니다. 예산 내에서 좋은 서비스를 구성원들에게 제공할 수 있어 무엇보다 기쁘게 생각합니다.`,
        `We chose Ringle because it offers a greater variety of tutors and materials than our previous phone-based English program. 
We knew that we will be able to satisfy our 200+ employees better, we're pleased that such a good program can be offered within our budget.`,
        's_home.business_review.yanolja.mobile',
        `与之前使用的电话英语相比，Ringle提供了更多的导师选择和教材，选择Ringle最重要的原因就是确信200多名培训生会满意的这份自信。 没有什么比能够在预算内向员工们提供好的服务更令人高兴的了。`,
        `與之前使用的電話英語相比，Ringle提供了更多的導師選擇和教材，選擇Ringle最重要的原因就是確信200多名培訓生會滿意的這份自信。 沒有什麼比能夠在預算內向員工們提供好的服務更令人高興的了。`,
        `以前利用していた電話英会話よりも、豊富なチューター数と教材により200名以上の教育対象者が満足できるだろうという
確信がRingleを選択した一番大きな理由です。予算内で良質のサービスを提供できることを何よりも嬉しく思います。`,
        `Lý do lớn nhất để chúng tôi chọn Ringle vì chắc chắn rằng hơn 200 đối tượng đào tạo sẽ hài lòng với việc được cung cấp gia sư và tài liệu nhiều hơn chương trình tiếng Anh qua điện thoại trước đây. Chúng tôi rất vui mừng vì có thể cung cấp dịch vụ tốt như vậy trong phạm vi ngân sách của mình.`,
      ],
    },
    bcg: {
      desktop: [
        `당사는 4개의 링글 수업 과정을 개설해두고, 목표 출석률을 달성한 임직원들에게 매달 링글 수업권을 제공하고 있습니다. 
수강 신청부터 출석률 확인의 모든 과정에서 링글팀의 지원을 받아 최소한의 운영 리소스만 투입하고 있습니다.`,
        `We offer four Ringle courses and provide monthly credits to employees who meet the target attendance rate. 
The Ringle team provides comprehensive support from registration to checking attendance, minimizing the work required on our side.`,
        's_home.business_review.bcg.desktop',
        `本公司开设了4个Ringle课程，每月向达成目标出席率的职员提供Ringle课程券。 从听课申请到确认出席率的所有过程，都得到了Ringle团队的帮助，我们只需要投入最少的运营资源。`,
        `本公司開設了4個Ringle課程，每月向達成目標出席率的職員提供Ringle課程券。 從聽課申請到確認出席率的所有過程，都得到了Ringle團隊的幫助，我們只需要投入最少的運營資源。`,
        `当社では4つのRingleレッスン課程を開設し、目標出席率を達成した社員に毎月Ringleレッスンチケットを提供しています。 
受講申請から出席率確認まで全過程においてRingleチームのサポートを受け、最小限の運営リソースのみを投入しています。`,
        `Chúng tôi đã mở 4 khóa học Ringle và cấp phiếu buổi học Ringle hàng tháng cho nhân viên đạt tỷ lệ chuyên cần theo chỉ tiêu. Chúng tôi nhận được sự hỗ trợ từ nhóm Ringle trong tất cả các quy trình, từ đăng ký học đến kiểm tra điểm danh và chỉ đầu tư các nguồn lực vận hành tối thiểu.”`,
      ],
      mobile: [
        `당사는 4개의 링글 수업 과정을 개설해두고, 목표 출석률을 달성한 임직원들에게 매달 링글 수업권을 제공하고 있습니다. 
수강 신청부터 출석률 확인의 모든 과정에서 링글팀의 지원을 받아 최소한의 운영 리소스만 투입하고 있습니다.`,
        `We offer four Ringle courses and provide monthly credits to employees who meet the target attendance rate. 
The Ringle team provides comprehensive support from registration to checking attendance, minimizing the work required on our side.`,
        's_home.business_review.bcg.mobile',
        `本公司开设了4个Ringle课程，每月向达成目标出席率的职员提供Ringle课程券。 从听课申请到确认出席率的所有过程，都得到了Ringle团队的帮助，我们只需要投入最少的运营资源。`,
        `本公司開設了4個Ringle課程，每月向達成目標出席率的職員提供Ringle課程券。 從聽課申請到確認出席率的所有過程，都得到了Ringle團隊的幫助，我們只需要投入最少的運營資源。`,
        `当社では4つのRingleレッスン課程を開設し、目標出席率を達成した社員に毎月Ringleレッスンチケットを提供しています。 
受講申請から出席率確認まで全過程においてRingleチームのサポートを受け、最小限の運営リソースのみを投入しています。`,
        `Chúng tôi đã mở 4 khóa học Ringle và cấp phiếu buổi học Ringle hàng tháng cho nhân viên đạt tỷ lệ chuyên cần theo chỉ tiêu. Chúng tôi nhận được sự hỗ trợ từ nhóm Ringle trong tất cả các quy trình, từ đăng ký học đến kiểm tra điểm danh và chỉ đầu tư các nguồn lực vận hành tối thiểu.”`,
      ],
    },
    devsisters: {
      desktop: [
        `기본적인 비즈니스 뿐만 아니라 시사/정치/경제 등 다양한 주제로 영어를 학습할 수 있고, 희망하는 튜터와 시간을 자유롭게 선택할 수 있다는 장점이 있어 많은 
구성원이 이용하고 있습니다. 튜터들의 교정 능력과 수업 태도 또한 우수하여, 튜터 만족도 역시 높은 편입니다.`,
        `Ringle is used by many of our employees because they can learn English on diverse topics from business to current affairs, politics, and economics, with the tutor they like at their convenient time. They also find that the tutors are highly competent and engaging.`,
        's_home.business_review.devsisters.desktop',
        `除了基本的商务，还有时事、政治、经济等多种英语学习主题，而且具有可以自由选择时间及自己喜爱的导师的优点，因此很多员工都在使用Ringle。导师们的纠错能力和授课态度也非常优秀，导师的满意度也相当高。`,
        `除了基本的商務，還有時事、政治、經濟等多種英語學習主題，而且具有可以自由選擇時間及自己喜愛的導師的優點，因此很多員工都在使用Ringle。導師們的糾錯能力和授課態度也非常優秀，導師的滿意度也相當高。`,
        `基本的なビジネスだけでなく時事/政治/経済など様々な英語を学べ、希望するチューターや時間を自由に選択できるという長所があるため多くの社員が利用しています。チューターの校正能力やレッスン態度もまた優秀で、チューター満足度もやはり高いです。`,
        `Nhiều nhân viên đang sử dụng Ringle vì ưu điểm là có thể học tiếng Anh theo nhiều chủ đề khác nhau như thời sự, chính trị, kinh tế cũng như kinh doanh cơ bản, đồng thời có thể tự do chọn gia sư và thời gian mình mong muốn. Kỹ năng hiệu chỉnh và thái độ của gia sư trong buổi học cũng rất xuất sắc nên mức độ hài lòng cao.`,
      ],
      mobile: [
        `기본적인 비즈니스 뿐만 아니라 시사/정치/경제 등 다양한 주제로 영어를 학습할 수 있고, 희망하는 튜터와 시간을 자유롭게 선택할 수 있다는 장점이 있어 많은 
구성원이 이용하고 있습니다. 튜터들의 교정 능력과 수업 태도 또한 우수하여, 튜터 만족도 역시 높은 편입니다.`,
        `Ringle is used by many of our employees because they can learn English on diverse topics from business to current affairs, politics, and economics, with the tutor they like at their convenient time. They also find that the tutors are highly competent and engaging.`,
        's_home.business_review.devsisters.mobile',
        `除了基本的商务，还有时事、政治、经济等多种英语学习主题，而且具有可以自由选择时间及自己喜爱的导师的优点，因此很多员工都在使用Ringle。导师们的纠错能力和授课态度也非常优秀，导师的满意度也相当高。`,
        `除了基本的商務，還有時事、政治、經濟等多種英語學習主題，而且具有可以自由選擇時間及自己喜愛的導師的優點，因此很多員工都在使用Ringle。導師們的糾錯能力和授課態度也非常優秀，導師的滿意度也相當高。`,
        `基本的なビジネスだけでなく時事/政治/経済など様々な英語を学べ、希望するチューターや時間を自由に選択できるという長所があるため多くの社員が利用しています。チューターの校正能力やレッスン態度もまた優秀で、チューター満足度もやはり高いです。`,
        `Nhiều nhân viên đang sử dụng Ringle vì ưu điểm là có thể học tiếng Anh theo nhiều chủ đề khác nhau như thời sự, chính trị, kinh tế cũng như kinh doanh cơ bản, đồng thời có thể tự do chọn gia sư và thời gian mình mong muốn. Kỹ năng hiệu chỉnh và thái độ của gia sư trong buổi học cũng rất xuất sắc nên mức độ hài lòng cao.`,
      ],
    },
    celltrion: {
      desktop: [
        `전사 임직원에게 복지 혜택으로 링글을 제공하고 있습니다. 다양한 튜터, 매일 업데이트 되는 
학습 콘텐츠와 커스텀 가능한 수업 방식 덕분에 각기 다른 업무를 하는 임직원들이 자신에게 맞는 영어 학습을 진행할 수 있어 좋습니다.`,
        `Ringle is perfect as an education benefit for all employees because it can be customized 
for any employee, regardless of role, to learn English at their own pace, from tutors, materials, and lesson style.`,
        's_home.business_review.celltrion.desktop',
        `我们正在向全体员工提供Ringle作为一种公司福利。得益于不同类型的导师、每天更新的学习内容和可定制的授课方式，从事不同业务的员工可以选择适合自己的方式内容进行英语学习，真是太棒了。`,
        `我們正在向全體員工提供Ringle作為一種公司福利。得益於不同類型的導師、每天更新的學習內容和可定制的授課方式，從事不同業務的員工可以選擇適合自己的方式內容進行英語學習，真是太棒了。`,
        `全社員に福祉特典としてRingleを提供しています。 様々なチューター、毎日アップデートされる 
学習コンテンツやカスタム可能なレッスン方式のおかげで、それぞれ業務の異なる社員が各自に合った英語学習を進めることができる点が気に入っています。`,
        `Chúng tôi đang cung cấp Ringel như một lợi ích phúc lợi cho nhân viên của công ty. Nhờ có nhiều gia sư khác nhau, nội dung học tập được cập nhật hàng ngày và phương pháp học có thể tùy chỉnh nên các nhân viên làm việc khác nhau có thể tiến hành học tiếng Anh phù hợp với bản thân.`,
      ],
      mobile: [
        `전사 임직원에게 복지 혜택으로 링글을 제공하고 있습니다. 다양한 튜터, 매일 업데이트 되는 
학습 콘텐츠와 커스텀 가능한 수업 방식 덕분에 각기 다른 업무를 하는 임직원들이 자신에게 맞는 영어 학습을 진행할 수 있어 좋습니다.`,
        `Ringle is perfect as an education benefit for all employees because it can be customized 
for any employee, regardless of role, to learn English at their own pace, from tutors, materials, and lesson style.`,
        's_home.business_review.celltrion.mobile',
        `我们正在向全体员工提供Ringle作为一种公司福利。得益于不同类型的导师、每天更新的学习内容和可定制的授课方式，从事不同业务的员工可以选择适合自己的方式内容进行英语学习，真是太棒了。`,
        `我們正在向全體員工提供Ringle作為一種公司福利。得益於不同類型的導師、每天更新的學習內容和可定制的授課方式，從事不同業務的員工可以選擇適合自己的方式內容進行英語學習，真是太棒了。`,
        `全社員に福祉特典としてRingleを提供しています。 様々なチューター、毎日アップデートされる 
学習コンテンツやカスタム可能なレッスン方式のおかげで、それぞれ業務の異なる社員が各自に合った英語学習を進めることができる点が気に入っています。`,
        `Chúng tôi đang cung cấp Ringel như một lợi ích phúc lợi cho nhân viên của công ty. Nhờ có nhiều gia sư khác nhau, nội dung học tập được cập nhật hàng ngày và phương pháp học có thể tùy chỉnh nên các nhân viên làm việc khác nhau có thể tiến hành học tiếng Anh phù hợp với bản thân.`,
      ],
    },
  },
  business_b2bservice: {
    subtitle: {
      desktop: [
        `맞춤형 기업 서비스 제공`,
        `Customized B2B Services`,
        's_home.business_b2bservice.subtitle.desktop',
        `为企业提供量身定制的服务`,
        `為企業提供量身定制的服務`,
        `個別型企業サービス提供`,
        `Cung cấp dịch vụ tùy chỉnh cho doanh nghiệp`,
      ],
      mobile: [
        `맞춤형 기업 서비스 제공`,
        `Customized B2B Services`,
        's_home.business_b2bservice.subtitle.mobile',
        `为企业提供量身定制的服务`,
        `為企業提供量身定制的服務`,
        `個別型企業サービス提供`,
        `Cung cấp dịch vụ tùy chỉnh cho doanh nghiệp`,
      ],
    },
    title: {
      desktop: [
        `링글 B2B 전담팀이 
최적화된 기업 서비스를 제공합니다.`,
        `Ringle's B2B team creates 
the optimal service for your company`,
        's_home.business_b2bservice.title.desktop',
        `Ringle B2B专业团队
为企业提供最优质的服务。`,
        `Ringle B2B專業團隊
為企業提供最優質的服務。`,
        `RingleのB2B専任チームが
最適化された法人向けサービスを提供します。`,
        `Nhóm chuyên trách Ringel B2B cung cấp dịch vụ tối ưu hóa cho doanh nghiệp`,
      ],
      mobile: [
        `링글 B2B 전담팀이 
최적화된 기업 서비스를 제공합니다.`,
        `Ringle's B2B team creates 
the optimal service for your company`,
        's_home.business_b2bservice.title.mobile',
        `Ringle B2B专业团队
为企业提供最优质的服务。`,
        `Ringle B2B專業團隊
為企業提供最優質的服務。`,
        `RingleのB2B専任チームが
最適化された法人向けサービスを提供します。`,
        `Nhóm chuyên trách Ringel B2B cung cấp dịch vụ tối ưu hóa cho doanh nghiệp`,
      ],
    },
    nolimit_title: {
      desktop: [
        `기업 맞춤형 수업권 제안`,
        `Customized\nB2B Credits`,
        's_home.business_b2bservice.nolimit_title.desktop',
        `企业定制型课程券提案`,
        `企業定制型課程券提案`,
        `企業に合わせたレッスンチケット提案`,
        `Đề xuất phiếu buổi học tùy chỉnh cho doanh nghiệp`,
      ],
      mobile: [
        `기업 맞춤형 수업권 제안`,
        `Customized B2B Credits`,
        's_home.business_b2bservice.nolimit_title.mobile',
        `企业定制型课程券提案`,
        `企業定制型課程券提案`,
        `企業ごとのレッスンチケット提案`,
        `Đề xuất phiếu buổi học tùy chỉnh cho doanh nghiệp`,
      ],
    },
    nolimit_subtitle: {
      desktop: [
        `기업의 예산과 임직원 지원 방식에 따라
커스텀된 가격과 형식을 제안합니다.`,
        `Pricing and credit packages can be
customized to your budget and program`,
        's_home.business_b2bservice.nolimit_subtitle.desktop',
        `根据企业的预算和员工支援方式，
我们为您提案量身定制的授课形式和合理的价格。`,
        `根據企業的預算和員工支援方式，
我們為您提案量身定制的授課形式和合理的價格。`,
        `企業の予算や社員サポート方式に合わせた
お値段と形式を提案します。`,
        `Chúng tôi đề xuất mức giá và định dạng tùy chỉnh theo ngân sách của doanh nghiệp và phương pháp hỗ trợ nhân viên`,
      ],
      mobile: [
        `기업의 예산과 임직원 지원 방식에 따라
커스텀된 가격과 형식을 제안합니다.`,
        `Pricing and credit packages can be
customized to your budget and program`,
        's_home.business_b2bservice.nolimit_subtitle.mobile',
        `根据企业的预算和员工支援方式，
我们为您提案量身定制的授课形式和合理的价格。`,
        `根據企業的預算和員工支援方式，
我們為您提案量身定制的授課形式和合理的價格。`,
        `企業の予算や社員サポート方式に合わせた
お値段と形式を提案します。`,
        `Chúng tôi đề xuất mức giá và định dạng tùy chỉnh theo ngân sách của doanh nghiệp và phương pháp hỗ trợ nhân viên`,
      ],
    },
    consult_title: {
      desktop: [
        `수업 컨설팅 가능`,
        `Personalized\nConsultations`,
        's_home.business_b2bservice.consult_title.desktop',
        `可进行课程咨询`,
        `可進行課程諮詢`,
        `レッスンコンサルティング可能`,
        `Tư vấn buổi học`,
      ],
      mobile: [
        `수업 컨설팅 가능`,
        `Personalized Consultations`,
        's_home.business_b2bservice.consult_title.mobile',
        `可进行课程咨询`,
        `可進行課程諮詢`,
        `レッスンコンサルティング可能`,
        `Tư vấn buổi học`,
      ],
    },
    consult_subtitle: {
      desktop: [
        `교재 선정부터 수업 내용에 대한
상세한 컨설팅을 진행할 수 있습니다.`,
        `We provide 1:1 advising to all B2B customers to further guide their English learning journey`,
        's_home.business_b2bservice.consult_subtitle.desktop',
        `从教材选定到对授课内容的讲解，
我们为您提供详细的咨询服务。`,
        `從教材選定到對授課內容的講解，
我們為您提供詳細的諮詢服務。`,
        `教材選びからレッスン内容における
細かいコンサルティングが可能です。`,
        `Chúng tôi cung cấp tư vấn từ việc lựa chọn tài liệu đến nội dung chi tiết buổi học`,
      ],
      mobile: [
        `교재 선정부터 수업 내용에 대한
상세한 컨설팅을 진행할 수 있습니다.`,
        `We provide 1:1 advising to all B2B customers to further guide their English learning journey`,
        's_home.business_b2bservice.consult_subtitle.mobile',
        `从教材选定到对授课内容的讲解，
我们为您提供详细的咨询服务。`,
        `從教材選定到對授課內容的講解，
我們為您提供詳細的諮詢服務。`,
        `教材選びからレッスン内容における
細かいコンサルティングが可能です。`,
        `Chúng tôi cung cấp tư vấn từ việc lựa chọn tài liệu đến nội dung chi tiết buổi học`,
      ],
    },
  },
  business_manage: {
    subtitle: {
      desktop: [
        `맞춤형 관리 시스템 제공`,
        `All-Inclusive Support`,
        's_home.business_manage.subtitle.desktop',
        `提供定制管理系统`,
        `提供定制管理系統`,
        `個別型管理システム提供`,
        `Cung cấp hệ thống quản lý tùy chỉnh`,
      ],
      mobile: [
        `맞춤형 관리 시스템 제공`,
        `All-Inclusive Support`,
        's_home.business_manage.subtitle.mobile',
        `提供定制管理系统`,
        `提供定制管理系統`,
        `個別型管理システム提供`,
        `Cung cấp hệ thống quản lý tùy chỉnh`,
      ],
    },
    title: {
      desktop: [
        `수업 알림부터 학습 현황 파악까지
실시간으로 관리해드립니다.`,
        `Our team provides real-time support
from lesson alerts to monitoring progress`,
        's_home.business_manage.title.desktop',
        `从上课提醒到学习进度掌握，
我们进行实时的管理。`,
        `從上課提醒到學習進度掌握，
我們進行即時的管理。`,
        `レッスン通知から学習現況把握まで
リアルタイムでの管理が可能です。`,
        `Chúng tôi quản lý theo thời gian thực từ thông báo buổi học đến nắm bắt tình hình học tập`,
      ],
      mobile: [
        `수업 알림부터 학습 현황 파악까지
실시간으로 관리해드립니다.`,
        `Our team provides real-time support from lesson alerts to monitoring progress`,
        's_home.business_manage.title.mobile',
        `从上课提醒到学习进度掌握，
我们进行实时的管理。`,
        `從上課提醒到學習進度掌握，
我們進行即時的管理。`,
        `レッスン通知から学習現況把握まで
リアルタイムでの管理が可能です。`,
        `Chúng tôi quản lý theo thời gian thực từ thông báo buổi học đến nắm bắt tình hình học tập`,
      ],
    },
    system_title: {
      desktop: [
        `기업과 임직원 모두를 위한
전담 관리시스템`,
        `Streamlined System`,
        's_home.business_manage.system_title.desktop',
        `为企业和全体员工提供
专门管理系统`,
        `為企業和全體員工提供
專門管理系統`,
        `企業と社員全員のための
専担管理システム`,
        `Hệ thống quản lý chuyên trách cho cả doanh nghiệp và nhân viên`,
      ],
      mobile: [
        `기업과 임직원 모두를 위한
전담 관리시스템`,
        `Streamlined System`,
        's_home.business_manage.system_title.mobile',
        `为企业和全体员工提供
专门管理系统`,
        `為企業和全體員工提供
專門管理系統`,
        `企業と社員全員のための
専担管理システム`,
        `Hệ thống quản lý chuyên trách cho cả doanh nghiệp và nhân viên`,
      ],
    },
    system_bodytxt_1: {
      desktop: [
        `기업 HR팀을 위한 맞춤관리 페이지 제공 및 임직원 
실시간 학습 현황과 엑셀 파일 다운로드를 제공합니다.`,
        `Ringle's B2B platform allows HR and managers to view and download team's lesson progress`,
        's_home.business_manage.system_bodytxt_1.desktop',
        `为企业HR团队提供量身定做的管理页面及员工 
实时学习进度和Excel文件下载。`,
        `為企業HR團隊提供量身定做的管理頁面及員工 
即時學習進度和Excel檔下載。`,
        `企業HRチームのための管理ページ提供及び社員の
リアルタイム学習状況やエクセルファイルダウンロードを提供します。`,
        `Cung cấp trang quản lý tùy chỉnh cho nhân sự của doanh nghiệp, cung cấp tình hình học tập của nhân viên theo thời gian thực và tải tệp Excel`,
      ],
      mobile: [
        `기업 HR팀을 위한 맞춤관리 페이지 제공 및 임직원 
실시간 학습 현황과 엑셀 파일 다운로드를 제공합니다.`,
        `Ringle's B2B platform allows HR and managers to view
and download team's lesson progress`,
        's_home.business_manage.system_bodytxt_1.mobile',
        `为企业HR团队提供量身定做的管理页面及员工 
实时学习进度和Excel文件下载。`,
        `為企業HR團隊提供量身定做的管理頁面及員工 
即時學習進度和Excel檔下載。`,
        `企業HRチームのための管理ページ提供及び社員の
リアルタイム学習状況やエクセルファイルダウンロードを提供します。`,
        `Cung cấp trang quản lý tùy chỉnh cho nhân sự của doanh nghiệp, cung cấp tình hình học tập của nhân viên theo thời gian thực và tải tệp Excel`,
      ],
    },
    system_bodytxt_2: {
      desktop: [
        `수강생 전원 1:1 상담부터 수업알림 제공을 통해 
정해진 기간 충분한 학습 효과를 누릴 수 있도록 
도움을 드리고 있습니다.`,
        `From 1:1 advising to lesson alerts, 
Ringle takes care that everyone achieves
maximum learning within the given period.`,
        's_home.business_manage.system_bodytxt_2.desktop',
        `全体学生可进行1:1咨询商谈，通过提供上课提醒服务，帮助学生在规定的时间内达成充分的学习效果。`,
        `全體學生可進行1:1諮詢商談，通過提供上課提醒服務，幫助學生在規定的時間內達成充分的學習效果。`,
        `全受講生向け1on1相談やレッスン通知提供を通して
決められた期間内に十分な学習効果を得られるよう
お手伝いしております。`,
        `Chúng tôi đang giúp tất cả học viên tận hưởng hiệu quả học tập đầy đủ trong thời gian quy định thông qua việc cung tư vấn 1:1 đến thông báo buổi học.`,
      ],
      mobile: [
        `수강생 전원 1:1 상담부터 수업알림 제공을 통해 
정해진 기간 충분한 학습 효과를 누릴 수 있도록 
도움을 드리고 있습니다.`,
        `From 1:1 advising to lesson alerts, 
Ringle takes care that everyone achieves
maximum learning within the given period.`,
        's_home.business_manage.system_bodytxt_2.mobile',
        `全体学生可进行1:1咨询商谈，通过提供上课提醒服务，帮助学生在规定的时间内达成充分的学习效果。`,
        `全體學生可進行1:1諮詢商談，通過提供上課提醒服務，幫助學生在規定的時間內達成充分的學習效果。`,
        `全受講生向け1on1相談やレッスン通知提供を通して
決められた期間内に十分な学習効果を得られるよう
お手伝いしております。`,
        `Chúng tôi đang giúp tất cả học viên tận hưởng hiệu quả học tập đầy đủ trong thời gian quy định thông qua việc cung tư vấn 1:1 đến thông báo buổi học.`,
      ],
    },
  },
  business_inquiry: {
    title: {
      desktop: [
        `확실한 영어 실력향상을
링글로 확인하세요.`,
        `Improve your team's English communications with Ringle`,
        's_home.business_inquiry.title.desktop',
        `敬请在Ringle确认英语实力的显著提高。`,
        `敬請在Ringle確認英語實力的顯著提高。`,
        `確実な英語力アップを
Ringleで実現。`,
        `Hãy kiểm tra sự cải thiện rõ rệt khả năng tiếng Anh của bạn với Ringle`,
      ],
      mobile: [
        `확실한 영어 실력향상을
링글로 확인하세요.`,
        `Improve your team's English communications with Ringle`,
        's_home.business_inquiry.title.mobile',
        `在Ringle确认自己的英语实力显著提高。`,
        `在Ringle確認自己的英文能力提升。`,
        `確実な英語力アップを
Ringleで実現。`,
        `Hãy kiểm tra sự cải thiện rõ rệt khả năng tiếng Anh của bạn với Ringle`,
      ],
    },
    subtitle: {
      desktop: [
        `문의를 남겨주시면 기업 담당자용 1회 정규 수업권을 드립니다.`,
        `Leave an inquiry and get a free lesson!`,
        's_home.business_inquiry.subtitle.desktop',
        `留言咨询，赠送1张企业负责人专用正规课程券。`,
        `留言諮詢，贈送1張企業負責人專用正規課程券。`,
        `お問い合わせをいただいた方に企業担当者様にレギュラーレッスンチケット１回分を差し上げます。`,
        `Hãy để lại câu hỏi, chúng tôi sẽ cung cấp phiếu học 1 lần cho người phụ trách doanh nghiệp.`,
      ],
      mobile: [
        `문의를 남겨주시면 기업 담당자용 1회 정규 수업권을 드립니다.`,
        `Leave an inquiry and get a free lesson!`,
        's_home.business_inquiry.subtitle.mobile',
        `留言咨询，赠送1张企业负责人专用正规课程券。`,
        `留言諮詢，贈送1張企業負責人專用正規課程券。`,
        `お問い合わせをいただいた方に企業担当者様にレギュラーレッスンチケット１回分を差し上げます。`,
        `Hãy để lại câu hỏi, chúng tôi sẽ cung cấp phiếu học 1 lần cho người phụ trách doanh nghiệp.`,
      ],
    },
  },
}
export const s_landing_tutors = {
  ivy_league_tutors: [
    <>
      영∙미 Top30 대학 출신
      <br />
      똑똑한 튜터와의
      <br />
      맞춤형 1:1 영어수업
    </>,
    <>
      Engaging conversations
      <br />
      with outstanding tutors
    </>,
    's_landing_tutors.ivy_league_tutors',
    <>
      充分了解您的
      <br />
      智能对话伙伴
    </>,
    <>
      了解您的
      <br />
      優質對話夥伴
    </>,
    <>
      選りすぐりのチューターと
      <br />
      興味・関心をそそる会話をしてみませんか
    </>,
    <>
      Các buổi thảo luận sâu
      <br />
      cùng các gia sư xuất sắc
    </>,
  ],
  see_video: [
    `가입하고 튜터 더 알아보기`,
    `Meet our tutors`,
    's_landing_tutors.see_video',
    `查看教师简介`,
    `查看任課教師簡介`,
    `チュータープロフィールを見る`,
    `Gặp gỡ gia sư của chúng tôi`,
  ],
  not_all_foreigner_good: [
    <>하고 싶은 말을 더 세련된 영어로</>,
    <>Exceptionally qualified tutors with a range of specialties</>,
    's_landing_tutors.not_all_foreigner_good',
    <>渴望表达的内容, 选用更为精练的英语</>,
    <>以更幹練的英語表達您想說的話</>,
    <>幅広い専門知識を有するチューターが所属しています</>,
    <>Những gia sư xuất sắc với chuyên môn khác nhau</>,
  ],
  ratio_na_tutors: [
    `아이비리그 & Top 30 대학 출신`,
    `Tutors from Top 30 Universities`,
    's_landing_tutors.ratio_na_tutors',
    `出身常春藤联盟&Top 30大学`,
    `常春藤名校 & Top 30大學出身`,
    `アイビーリーグ & TOP30の名門校出身`,
    `Gia sư từ các Trường Đại học Top 30`,
  ],
  native_speaker: [
    `직장 경력 보유자`,
    `Tutors with work experience`,
    's_landing_tutors.native_speaker',
    `具有工作经验`,
    `具備工作經驗`,
    `実務経験あり`,
    `Gia sư với kinh nghiệm làm việc`,
  ],
  variety_majors: [
    `경영, 개발, 의학 등 다양한 전공`,
    <>Majors including Business, Pre-Med, STEM, and more</>,
    's_landing_tutors.variety_majors',
    `经营, 开发, 医学等多种专业`,
    `經營、開發、醫學等各種主修`,
    `専攻は経営、開発、医学など多岐に渡る`,
    <>Chuyên ngành: Kinh doanh, Y khoa, STEM và nhiều ngành khác</>,
  ],
  register_and_check_profiles_out: [
    `당신에게 꼭 맞는 튜터를 찾아보세요`,
    `Find the perfect tutor for you`,
    's_landing_tutors.register_and_check_profiles_out',
    `一定可以找到最适合您的教师`,
    `請尋找絕對適合您的任課教師`,
    `あなたにぴったりのチューターを探してみよう`,
    `Tìm kiếm gia sư hoàn hảo`,
  ],
  register_and_check_it_out: [
    `내게 맞는 튜터 찾기`,
    `Browse 1700+ tutors`,
    's_landing_tutors.register_and_check_it_out',
    `查看1700名以上的教师简介`,
    `查看1700名以上任課教師的簡介`,
    `1700名のチュータープロフィールを見る`,
    `Xem 1700+ gia sư`,
  ],
  ringle_tutor_different: [
    <>링글 튜터는 다릅니다</>,
    <>Ringle tutors are different</>,
    's_landing_tutors.ringle_tutor_different',
    <>Ringle的教师与众不同</>,
    <>Ringle的任課教師與眾不同</>,
    <>Ringleのチューターが他と違う理由</>,
    <>Các gia sư của Ringle rất khác biệt</>,
  ],
  tutor_review_1: [
    <>
      링글 튜터는 아이비리그나 MBA 출신이라 더 검증됐다고 생각해요. 실제로 원어민들이 사용하는 고급 어휘나 세련된
      문장들을 체득할 수 있었어요.
    </>,
    <>
      I think Ringle tutors' credentials set them apart. Having attended top schools or MBA programs, they have the
      capability to advise me on advanced vocabularies and sophisticated sentences.
    </>,
    's_landing_tutors.tutor_review_1',
    <>Ringle的教师们出身常青藤联盟或MBA， 所以更能够让我学习到以英文为母语的人们实际使用的高级词汇及精炼的语句。</>,
    <>
      我覺得Ringle的任課教師出自常春藤名校或MBA，因此更加值得信賴。
      讓我可以學到母語人士們實際使用的高級詞彙及幹練的句子。
    </>,
    <>
      Ringleのチューターはその経歴から他と差をつけていると思います。トップ校やMBAで
      ネイティブが使う高レベル語彙や洗練された文章を教わることができました。
    </>,
    <>
      Tôi nghĩ xuất phát điểm của các gia sư Ringle khiến họ khác biệt. Họ theo học các trường đại học hoặc các chương
      trình MBA hàng đầu, họ có khả năng hướng dẫn tôi các từ vựng nâng cao hoặc sử dụng các câu phức tạp.
    </>,
  ],
  tutor_review_1_writer: [
    <>마이크로소프트 마케팅팀, Lyn</>,
    <>Lyn, Marketing at Microsoft</>,
    's_landing_tutors.tutor_review_1_writer',
    <>Microsoft市场推广组, Lyn</>,
    <>Microsoft行銷團隊，Lyn</>,
    <>Microsoft マーケティングチーム、Lyn</>,
    <>Lyn, Marketing tại Microsoft</>,
  ],
  tutor_review_2: [
    <>
      링글 튜터들은 논리적으로 생각하고 대화해요. 그래서 영어를 공식처럼 알려주는 게 아니라, 내 생각을 남들에게 전하기
      쉽게 논리적인 영어를 가르쳐줘요.
    </>,
    <>
      Ringle tutors think critically when they speak. They don't teach English by grammatical rules; they teach how to
      deliver your ideas logically so that everyone can understand you.
    </>,
    's_landing_tutors.tutor_review_2',
    <>
      Ringle的教师能够提供给我们具有逻辑性的对话, 不像官方英语那样
      死板。而且教给我如何能够运用简练及逻辑性强的英语将自身想法传递给他人。
    </>,
    <>
      Ringle的任課教師們會邏輯性地思考並對話，所以不是像教公式一樣死板，而是教我如何以具邏輯性的英語輕鬆表達我的想法。
    </>,
    <>
      Ringleのチューターは論理的に考えて会話します。だから英語を公式のように
      教えてくれるのではなく、自分の考えを他人に伝えやすく論理的な英語を教えてくれます。
    </>,
    <>
      Các gia sư Ringle suy nghĩ sâu sắc. Họ không dạy bạn những quy tắc ngữ pháp; họ dạy bạn cách truyền đạt ý tưởng
      một cách logic để mọi người có thể nắm được ý bạn.
    </>,
  ],
  tutor_review_2_writer: [
    <>Deloitte 컨설턴트, Kim</>,
    <>Kim, Consultant at Deloitte</>,
    's_landing_tutors.tutor_review_2_writer',
    <>Deloitte顾问, Kim</>,
    <>Deloitte顧問，Kim</>,
    <>Deloitte コンサルタント、Kim</>,
    <>Kim, Consultant tại Deloitte</>,
  ],
  tutor_review_3: [
    <>
      튜터들의 백그라운드가 굉장히 다양해서 나의 성향과 목표에 맞는 분들이 많아요. 선택의 폭이 넓다는 것이 가장
      좋습니다.
    </>,
    <>
      Ringle tutors come from diverse backgrounds, so I can find tutors who match my personality and share my career
      goals. My favorite thing about Ringle is the big pool of tutors to choose from.
    </>,
    's_landing_tutors.tutor_review_3',
    <>教师们的文化背景丰富, 符合我要求及目标的教师很多。 选择余地大, 这一点我很满意。</>,
    <>任課教師們的背景豐富，有許多任課教師符合我的性向及目標。選擇多元這點最棒了。</>,
    <>
      チューターのバックグラウンドが非常に多様なので、私の性格や目標に合う方が
      多いです。選択の幅が広いというのが一番いいですね。
    </>,
    <>
      Gia sư Ringle làm việc trong những môi trường khác nhau, nên tôi có thể chọn người phù hợp với tính cách và chia
      sẻ cùng mục tiêu nghề nghiệp. Điều tôi yêu thích nhất ở Ringle chính là sự lựa chọn đa dạng.
    </>,
  ],
  tutor_review_3_writer: [
    <>Ebay 엔지니어, Claire</>,
    <>Claire, Engineer at Ebay</>,
    's_landing_tutors.tutor_review_3_writer',
    <>Ebay工程师, Claire</>,
    <>Ebay工程師，Claire</>,
    <>Ebay エンジニア、Claire</>,
    <>Claire, Engineer tại Ebay</>,
  ],
  tutor_review_4: [
    <>
      전문성은 링글 튜터를 따라갈 수가 없어요. 현직 기자로 일하며, 같은 분야의 튜터와 준비한 덕분에 클라이언트와의 영어
      인터뷰에서 큰 성과를 냈습니다.
    </>,
    <>
      Ringle tutors come with deep, professional expertise at their respective area of study or career. I met a tutor
      who is also a journalist and, thanks to our lessons, I nailed an English interview with an important client.
    </>,
    's_landing_tutors.tutor_review_4',
    <>
      在专业性方面Ringle的教师真是无人能及, 我现在作为一名记者，
      多亏了和具备相同专业背景的教师一起准备，让我在和客户的访谈中游刃有余。
    </>,
    <>
      就專業性而言，Ringle的任課教師實在無人能及，我目前是一名記者，多虧與相同領域的任課教師一起準備，讓我在以英語採訪客戶時得到不錯的成果。
    </>,
    <>
      専門性はRingleのチューターに追いつけません。現職の記者として働きながら、同じ分野の
      チューターと準備したおかげで、クライアントとの英語会議で成果をあげました。
    </>,
    <>
      Các gia sư Ringle có chuyên môn sâu trong lĩnh vực học tập hoặc nghề nghiệp của họ. Tôi từng gặp một gia sư hiện
      đang làm phóng viên, và nhờ những buổi học đó, tôi xuất sắc vượt qua buổi phỏng vấn tiếng Anh với một khách hàng
      quan trọng.
    </>,
  ],
  tutor_review_4_writer: [
    <>한국경제신문, Lee</>,
    <>Lee, The Korea Economic Daily</>,
    's_landing_tutors.tutor_review_4_writer',
    <>韩国经济新闻, Lee</>,
    <>韓國經濟新聞，Lee</>,
    <>韓国経済新聞、Lee</>,
    <>Lee, báo The Korea Economic Daily</>,
  ],
  free_start: [
    <>부담없이 시작하세요</>,
    <>Start learning!</>,
    's_landing_tutors.free_start',
    <>赶快体验Ringle吧！</>,
    <>趕快體驗Ringle吧！</>,
    <>気軽に始めてみましょう。。</>,
    <>Học thử miễn phí</>,
  ],
  free_start_btn: [
    <>무료 체험 수업 신청하기</>,
    <>Sign up</>,
    's_landing_tutors.free_start_btn',
    <>申请免费体验课程</>,
    <>申請免費體驗課程</>,
    <>無料体験レッスンを予約する</>,
    <>Đăng ký</>,
  ],
}
export const s_topic = {
  you_disappointed: [
    `Ringle Materials`,
    ``,
    's_topic.you_disappointed',
    `Ringle Materials`,
    `Ringle Materials`,
    `Ringle Materials`,
    ``,
  ],
  ringle_high_quality: [
    <>
      일상 대화부터 시사, <br />
      실전 비즈니스 영어까지
    </>,
    <>
      Lead a discussion
      <br />
      on a real-world topic
    </>,
    's_topic.ringle_high_quality',
    <>
      从日常生活对话, <br />
      到实战商务英语
    </>,
    <>
      從日常對話到時事、
      <br />
      實戰商務英語
    </>,
    <>
      日常会話から時事、
      <br />
      実践ビジネス英語まで
    </>,
    <>
      Thảo luận
      <br />
      chủ đề đời thực
    </>,
  ],
  ringle_various_materials: [
    <>
      당신이 영어로 <Br md down />
      말하고 싶은 이야기
    </>,
    <>Learning materials for real-world speaking</>,
    's_topic.ringle_various_materials',
    <>
      您渴望使用英文
      <Br md down />
      表达的全部内容
    </>,
    <>
      您渴望使用英文
      <Br md down />
      表達的內容
    </>,
    <>本物の英語を学べる教材</>,
    <>Tài liệu học cho bối cảnh giao tiếp hằng ngày</>,
  ],
  not_only_english: [
    <>
      매일 마주하는 상황과 연결되는 <Br md down />
      쓸모있는 대화에 집중합니다
    </>,
    ``,
    's_topic.not_only_english',
    <>
      专注于每日所见所闻的
      <Br md down />
      实用性对话内容
    </>,
    <>
      專注於每日所見所聞的
      <Br md down />
      實用性對話內容
    </>,
    <>日常会話に役立つヒントが盛りだくさん</>,
    ``,
  ],
  various_materials: [
    <>
      비즈니스, 정치/경제, 문화 등
      <br />
      <Div spanTag textPrimary>
        +1,000개의 다양한 교재
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        1,000+ Materials
      </Div>
      <br />
      on casual to advanced topics
    </>,
    's_topic.various_materials',
    <>
      商业, 政治/经济, 文化等
      <br />
      <Div spanTag textPrimary>
        1,000种以上的多元化教材
      </Div>
    </>,
    <>
      商業、政治／經濟、文化等
      <br />
      <Div spanTag textPrimary>
        1,000多種教材
      </Div>
    </>,
    <>
      ビジネス、政治・経済、文化など
      <br />
      <Div spanTag textPrimary>
        1,000を超える多様な教材
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        1,000+ tài liệu
      </Div>
      <br />
      với chủ đề từ cơ bản đến nâng cao
    </>,
  ],
  topics_example: [``, ``, 's_topic.topics_example', ``, ``, ``, ``],
  from_MBA: [
    <>
      <Div spanTag textPrimary>
        Top MBA 출신 집필진과 튜터들
      </Div>
      의
      <br />
      오리지널 교재
    </>,
    <>
      <Div spanTag textPrimary>
        Expert writers
      </Div>
      <br />
      from top universities & MBA
    </>,
    's_topic.from_MBA',
    <>
      <Div spanTag textPrimary>
        Top MBA的编纂人员及教师们共同打造
      </Div>
      的
      <br />
      原版教材
    </>,
    <>
      <Div spanTag textPrimary>
        Top MBA的執筆團隊及任課教師們
      </Div>
      的
      <br />
      原創教材
    </>,
    <>
      <Div spanTag textPrimary>
        MBAトップ校出身のライターとチューターたち
      </Div>
      監修の
      <br />
      オリジナル教材
    </>,
    <>
      <Div spanTag textPrimary>
        Người viết chuyên nghiệp
      </Div>
      <br />
      từ các trường Đại học & MBA hàng đầu
    </>,
  ],
  MBA_ivy_league: [``, ``, 's_topic.MBA_ivy_league', ``, ``, ``, ``],
  recent_trend: [
    <>
      매주 업데이트되는
      <br />
      <Div spanTag textPrimary>
        최신 콘텐츠
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        New content
      </Div>
      <br />
      released every week
    </>,
    's_topic.recent_trend',
    <>
      每周更新的
      <br />
      <Div spanTag textPrimary>
        全新内容
      </Div>
    </>,
    <>
      每週更新的
      <br />
      <Div spanTag textPrimary>
        最新內容
      </Div>
    </>,
    <>
      毎週アップデートされる
      <br />
      <Div spanTag textPrimary>
        最新コンテンツ
      </Div>
    </>,
    <>
      <Div spanTag textPrimary>
        Nội dung mới
      </Div>
      <br />
      mỗi tuần
    </>,
  ],
  weekly_update: [``, ``, 's_topic.weekly_update', ``, ``, ``, ``],
  ringle_material_systematic: [
    `영어 대화에 최적화된 구성`,
    `4 Parts to guide meaningful conversations`,
    's_topic.ringle_material_systematic',
    `最适合英语对话的结构`,
    `英語對話最佳架構`,
    `英会話に最適化された教材構成`,
    `4 phần giúp dẫn dắt cuộc hội thoại ý nghĩa`,
  ],
  topic_think: [
    <Fragment>
      생각을 자극하는 본질적인 질문과 <Br md down />
      시청각 자료로 깊이 있는 대화를 이끕니다
    </Fragment>,
    ``,
    's_topic.topic_think',
    <Fragment>
      用激发思维的本质性提问及
      <Br md down />
      视听资料引发深度对话
    </Fragment>,
    <Fragment>
      用激發思維的本質性提問及
      <Br md down />
      視聽資料引導深度對話
    </Fragment>,
    <Fragment>
      思考を刺激する本質的な質問と <Br md down />
      視聴覚資料で深みのある会話をリードします。
    </Fragment>,
    ``,
  ],
  insight: [`인사이트`, `Insight`, 's_topic.insight', `正文`, `序言`, `インサイト`, `Insight`],
  insight_detail: [
    `주제의 핵심 내용 요약 (국문/영문 제공)`,
    <>Summary article on each topic written by Ringle</>,
    's_topic.insight_detail',
    `主题核心内容摘要(提供英文)`,
    `主題的核心內容摘要（提供英文）`,
    `各テーマの要約`,
    <>Các bài báo tóm tắt ở mỗi chủ để viết bởi chính Ringle</>,
  ],
  debate_question: [
    `토론 질문`,
    `Discussion Questions`,
    's_topic.debate_question',
    `讨论问题`,
    `討論提問`,
    `質問`,
    `Câu hỏi thảo luận`,
  ],
  prepare_logical_answer: [
    `심도있는 대화를 이끄는 다양한 질문들`,
    `Ranging in light-hearted to deeper-dive questions`,
    's_topic.prepare_logical_answer',
    `引发深入对话的多样性提问`,
    `引導深度對話的各種提問`,
    `深みのある会話をリードする様々な質問`,
    `Từ các câu hỏi cơ bản đến chuyên sâu`,
  ],
  related_video: [
    `주제 관련 영상/기사`,
    `Videos & News`,
    's_topic.related_video',
    `主题相关视频/新闻报道`,
    `影片／報導`,
    `動画＆ニュース記事`,
    `Videos & Tin tức`,
  ],
  high_understanding: [
    `다양한 배경지식으로 주제 이해도 향상`,
    `Additional resources for a full download on a topic`,
    's_topic.high_understanding',
    `根据多样的背景知识加深对内容的理解`,
    `透過豐富的背景知識提升主題的理解度`,
    `多様な予備知識でテーマの理解度向上`,
    `Tài liệu bổ sung miễn phí liên quan đến chủ đề`,
  ],
  key_expression: [
    `핵심 표현`,
    `Key expressions`,
    's_topic.key_expression',
    `核心表达`,
    `核心表現`,
    `キーフレーズ`,
    `Mẫu câu chính`,
  ],
  practice_key_expression: [
    `실전에 바로 쓸 수 있는 유용한 영어 표현 제공`,
    <>Idioms and sayings to immediately boost fluency</>,
    's_topic.practice_key_expression',
    `提供真正实用的英语表达方式`,
    `提供可立即用於實戰的實用英語用法`,
    `すぐに使える役立つ英語表現`,
    <>Các thành ngữ và mẫu câu giúp bạn nói trôi chảy hơn,</>,
  ],
  register_and_check_it_out: [
    `당신이 말하고 싶은 주제를 찾아보세요`,
    `Talk about what you care about`,
    's_topic.register_and_check_it_out',
    `请寻找您希望进行的会话主题`,
    `尋找您想說的主題吧！`,
    `話してみたいテーマを見つけよう`,
    `Thảo luận chủ đề bạn thích`,
  ],
  find_out_more_material: [
    <>1,000+ 개의 교재 살펴보기</>,
    <>View 1,000+ lesson materials</>,
    's_topic.find_out_more_material',
    <>查看1,000种以上的教材</>,
    <>查看1,000多種教材</>,
    <>1,000種類以上の教材を見る</>,
    <>Xem 1,000+ tài liệu cho buổi học</>,
  ],
  ringle_because_material: [
    `링글 교재는 다릅니다`,
    <>Ringle materials are unlike others</>,
    's_topic.ringle_because_material',
    `Ringle的教材与众不同`,
    `Ringle的教材與眾不同`,
    `Ringleの教材は他とは違います。`,
    <>Tài liệu của Ringle là duy nhất</>,
  ],
  topic_review_1: [
    `트렌드에 잘 맞는 최신 교재를 직접 골라서 제가 관심 있는 주제로 대화할 수 있어서 정말 좋아요. 영어를 배우면서 동시에 시사를 배울 수도 있어서 외국 팀원들과 대화하기가 훨씬 수월해졌어요.`,
    <>
      I love that I can choose materials on a topic I'm interested in. After practicing speaking English on current
      events, I found it a lot easier to hold conversations with my colleagues at work.
    </>,
    's_topic.topic_review_1',
    `能够直接选择符合当前趋势的教材主题进行对话, 这一点真的很满意。一边学习英文的同时又能了解时事, 让我和外籍教师间的对话变得容易了很多。`,
    `可以親自選擇符合趨勢的最新教材，以我感興趣的主題進行對話，真的很棒。一邊學習英文的同時一邊了解時事，讓我和外籍組員之間的對話變得容易許多。`,
    `トレンドに合う最新教材を直接選び、自分の興味のあるテーマで話せるのは本当にいいです。英語を学びながら時事も学ぶことができるので、外国のチームメンバーたちとの話し合いがかなり円滑になりました。`,
    <>
      Tôi thích được chọn tài liệu dựa trên chủ đề yêu thích. Sau khi luyện nói về những tin tức thời sự, tôi cảm thấy
      dễ dàng hơn trong việc nói chuyện với các đồng nghiệp tại công ty.
    </>,
  ],
  topic_review_1_writer: [
    `HBS Class of 2019 , Dylan`,
    `Dylan, HBS Class of 2019`,
    's_topic.topic_review_1_writer',
    `HBS Class of 2019, Dylan`,
    `HBS Class of 2019，Dylan`,
    `HBS 2019年卒、Dylan`,
    `Dylan, Trường Kinh doanh Harvard khoá 2019`,
  ],
  topic_review_2: [
    `링글 교재는 바쁜 직장인들이 소화하기 좋은 형식으로 구성되어 있어요. 시사 전문 잡지보다는 더 쉽게 읽히고 회사에서 보는 보고서처럼 요점이 잘 요약되어 있어서 부담 없이 읽을 수 있어요.`,
    `Ringle materials are easy to digest. I find them more like a corporate report - they are easier to read than most investigative magazine pieces and provides clear concise summaries on each topic.`,
    's_topic.topic_review_2',
    `Ringle教材针对日常工作繁忙的上班族采用便于理解的形式。比起时事杂志, 阅读更加简单, 同时参考公司报告的形式添加有要点概述, 更加减轻了学习负担。`,
    `Ringle教材以繁忙的上班族容易消化的形式編撰。比時事專業雜誌更容易閱讀，就如公司的報告書一樣，重點簡潔二要，閱讀起來更無負擔。`,
    `Ringleの教材は、忙しい社会人が使いやすいよう構成されています。時事専門誌よりもっと手軽に読めて、会社で見る報告書のように要点がしっかりまとめられているので簡単に読むことができます。`,
    `Tài liệu của Ringle dễ tiếp thu. Tôi thấy nó giống như báo cáo ở các tập đoàn - dễ hiểu hơn các bài phóng sự trên tạp chí và cung cấp những tóm tắt súc tích, chi tiết cho từng chủ đề.`,
  ],
  topic_review_2_writer: [
    `구글 시니어 엔지니어, Daniel`,
    `Daniel, Senior Engineer at Google`,
    's_topic.topic_review_2_writer',
    `谷歌高级工程师, Daniel`,
    `Google資深工程師，Daniel`,
    `Google シニアエンジニア、Daniel`,
    `Daniel, Senior Engineer tại Google`,
  ],
  topic_review_3: [
    `시의성 있는 비즈니스 관련 주제이고 추가 영상/기사 자료도 같이 제공되어 다양한 방식으로 공부할 수 있었습니다. 그만큼 얘기할 게 많아서 토론하기에 적합한 교재라고 생각합니다.`,
    `I appreciate that Ringle constantly uploads materials on current events, which also include additional resources like videos and news articles. It gives me plenty to discuss with my tutors.`,
    's_topic.topic_review_3',
    `具备适宜性的商业主题, 同时提供视频/新闻等附加资料, 通过多样化的方式进行学习。能够充分引发对话的主题，我认为教材内容非常适用于讨论。`,
    `不僅是合時宜的商務主題，同時提供額外影片／報導資料，讓我可以透過各種方式學習。因此，可討論的話題也更多，我認為是很適合討論的教材。`,
    `時事的なビジネス関連のテーマで追加の動画やニュース記事資料も一緒に提供されるので、さまざまな角度から勉強することができました。それだけ話す材料が増えるので、ディスカッションに適した教材だと思います。`,
    `Tôi đánh giá cao việc Ringle liên tục cập nhật các tài liệu về những sự kiện đang diễn ra, bao gồm các video và tin tức. Việc này giúp tôi có nhiều thông tin để thảo luận cùng các gia sư của mình.`,
  ],
  topic_review_3_writer: [
    `아마존 인사팀, Jimmy`,
    `Jimmy, HR at Amazon`,
    's_topic.topic_review_3_writer',
    `亚马逊人事部, Jimmy`,
    `亞馬遜人事組，Jimmy`,
    `Amazon 人事チーム、Jimmy`,
    `Jimmy, HR tại Amazon`,
  ],
  topic_review_4: [
    `교재마다 양질의 질문이 준비되어 있어 튜터와 심도있는 대화를 나누는 데 도움이 됐습니다. 질문의 난이도와 목적도 잘 배치되어 있어 효과적으로 시간을 쓸 수 있었습니다.`,
    `Each material comes with a set of high-quality discussion questions, which helps me have a meaningful conversation with my tutors. Since the questions are deliberately ordered by purpose and difficulty level, I can also hand-pick the questions that are best for me and use my lesson time to the fullest.`,
    's_topic.topic_review_4',
    `每个教材都准备有高质量的提问, 有助于和教师进行深度对话。问题的难易度也进行了合理性搭配, 让课程时间能够更为有效的利用。`,
    `每個教材均準備了優質的提問，有助於和任課教師進行深度對話。提問的難度與目的也分配均勻，可以有效地利用時間。`,
    `教材ごとに良質な質問が用意されており、チューターと深みのある会話をするのに役立ちました。質問の難易度と目的もしっかり設定されていて、効果的に時間を使うことができました。`,
    `Mỗi tài liệu đi kèm với bộ câu hỏi thảo luận chất lượng giúp tôi trao đổi tốt hơn với gia sư của mình. Những câu hỏi được sắp xếp theo mục đích nhất định và theo độ khó, tôi có thể dễ dàng chọn ra câu hỏi phù hợp nhất cho mình để sử dụng thời gian học một cách tối ưu nhất`,
  ],
  topic_review_4_writer: [
    `SAP 사업전략, Sam`,
    `Sam, Business strategies at SAP`,
    's_topic.topic_review_4_writer',
    `SAP产业战略, Sam`,
    `SAP事業策略，Sam`,
    `SAP 事業戦略、Sam`,
    `Sam, Business strategies tại SAP`,
  ],
}
export const s_b2b = {
  better_english_for_company: [
    <>
      임직원에게
      <br />더 좋은 영어교육을
    </>,
    <>
      Globalize your team's
      <br />
      communication
    </>,
    's_b2b.better_english_for_company',
    <>
      提供给职员们
      <br />
      更好的英文教育
    </>,
    <>
      讓職員接受
      <br />
      更好的英語教育
    </>,
    <>
      社内の
      <br />
      より優れた英語教育を
    </>,
    <>
      Toàn cầu hoá
      <br />
      giao tiếp của team
    </>,
  ],
  experience_ringle: [
    <>국내외 스타트업부터 외국계, 대기업까지</>,
    <></>,
    's_b2b.experience_ringle',
    <>从国内外初创公司, 到国际化大型企业</>,
    <>從國內外新創公司至外資公司、大型企業</>,
    <>国内外のスタートアップ企業から外資系、大企業まで</>,
    <></>,
  ],
  ask_b2b: [
    `기업 수강 문의하기`,
    `Send us an inquiry`,
    's_b2b.ask_b2b',
    `咨询企业课程`,
    `諮詢企業課程`,
    `法人向けサービスのお問い合わせ`,
    `Gửi yêu cầu cho chúng tôi`,
  ],
  oneclick_system: [
    <>원클릭 수업 예약</>,
    ``,
    's_b2b.oneclick_system',
    <>预约一键式课程</>,
    <>預約一鍵式課程</>,
    <>ワンクリックレッスン予約</>,
    ``,
  ],
  oneclick_easy: [
    <>
      연중무휴로 원하는 일정의 수업을
      <br />
      원클릭으로 간편하게 예약
    </>,
    <></>,
    's_b2b.oneclick_easy',
    <>
      全年无休, 选择您希望的日期
      <br />
      一键式便捷预约
    </>,
    <>
      我們全年無休，您可一鍵預約
      <br />
      希望日程的課程
    </>,
    <>
      年中無休で好きな日程のレッスンを
      <br />
      ワンクリックで予約可能
    </>,
    <></>,
  ],
  case_materials: [
    `실무중심 교재`,
    ``,
    's_b2b.case_materials',
    `以实务为主的教材`,
    `以實務為主的教材`,
    `実務中心の教材`,
    ``,
  ],
  written_by_MBA: [
    <Fragment>
      최신 시사부터 비즈니스 영어까지
      <br />
      MBA출신 집필진의 퀄리티 높은 교재
    </Fragment>,
    <Fragment></Fragment>,
    's_b2b.written_by_MBA',
    <Fragment>
      从最新时事到商务英语
      <br />
      出身MBA编纂人员全心打造的高质量教材
    </Fragment>,
    <Fragment>
      從最新時事到商務英語
      <br />
      MBA出身的執筆團隊打造的高品質教材
    </Fragment>,
    <Fragment>
      最新時事からビジネス英語まで
      <br />
      MBA出身ライター監修の高品質教材
    </Fragment>,
    <Fragment></Fragment>,
  ],
  top_20_tutors: [
    `Top30 명문대 출신 튜터`,
    ``,
    's_b2b.top_20_tutors',
    `出身Top30名校的教师`,
    `來自Top 30名校的任課教師`,
    `TOP30の名門大学出身のチューター`,
    ``,
  ],
  choose_tutors: [
    <>
      전공/직장경력을 확인하고
      <br />
      직접 선택하는 튜터
    </>,
    <></>,
    's_b2b.choose_tutors',
    <>
      对专业/职场经历进行确认
      <br />
      直接选择教师
    </>,
    <>
      確認主修／工作經驗後
      <br />
      親自選擇任課教師
    </>,
    <>
      専攻・経歴から
      <br />
      お好みのチューターを選べる
    </>,
    <></>,
  ],
  manage_study: [`학습관리`, ``, 's_b2b.manage_study', `学习管理`, `學習管理`, `学習サポート`, ``],
  b2b_team_manage_study: [
    `B2B 전담팀이 체계적으로 관리합니다`,
    `A dedicated team and portal for B2B clients`,
    's_b2b.b2b_team_manage_study',
    `由B2B专职部门进行系统管理`,
    `由B2B專職團隊進行系統管理`,
    `B2B専門チームがワンストップで対応します。`,
    `Đội ngũ tận tình và cổng thông tin riêng cho khách hàng B2B`,
  ],
  fit_b2b_service: [
    <>
      프로그램 도입 컨설팅부터 학습종료 결과 보고서까지
      <Br md up />
      &nbsp;최적화된 기업서비스를 제공합니다
    </>,
    <>We'll be with you every step of the way, from implementation to reporting</>,
    's_b2b.fit_b2b_service',
    <>
      从项目引进咨询到学习结果报告书
      <Br md up />
      &nbsp;提供最优化的企业服务
    </>,
    <>
      從引進計畫諮詢至學習結果報告書
      <Br md up />
      &nbsp;提供最合適的企業服務
    </>,
    <>
      プログラム導入に関するご相談から学習成果報告書まで
      <Br md up />
      &nbsp;一括で法人向けサービスを提供します。
    </>,
    <>Chúng tôi đồng hành cùng bạn, từ việc triển khai đến báo cáo</>,
  ],
  ringle_b2b_companies: [
    `링글과 함께하는 B2B 제휴사`,
    `Ringle's B2B Partners`,
    's_b2b.ringle_b2b_companies',
    `Ringle携手的B2B合作企业`,
    `Ringle攜手的B2B合作企業`,
    `RingleのB2B提携企業`,
    `Đối tác B2B của Ringle`,
  ],
  name: [`이름`, `Name`, 's_b2b.name', `姓名`, `姓名`, `お名前`, `Tên`],
  enter_name: [
    `이름을 입력해주세요`,
    `Enter name`,
    's_b2b.enter_name',
    `请输入姓名`,
    `請輸入姓名`,
    `お名前を入力してください。`,
    `Vui lòng nhập tên`,
  ],
  email: [`이메일`, `Email`, 's_b2b.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_email: [
    `이메일을 입력해주세요`,
    `Enter email`,
    's_b2b.enter_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập email`,
  ],
  ask_partner: [
    `제휴문의`,
    `How can we help?`,
    's_b2b.ask_partner',
    `合作咨询`,
    `合作諮詢`,
    `提携に関するお問い合わせ`,
    `Chúng tôi có thể giúp gì cho bạn?`,
  ],
  enter_question: [
    `기업수강 / 제휴할인 등 제휴형태를 포함한 문의사항을 남겨주세요`,
    `Leave us an inquiry about our B2B partnerships and discounts.`,
    's_b2b.enter_question',
    `请留下企业课程/合作优惠等有关合作形态内容的咨询事项`,
    `請留下企業課程／合作優惠等包含合作型態的諮詢事項`,
    `法人契約 / 提携割引などご希望の提携形態をご明記の上、お問い合わせ内容を入力してください。`,
    `Để lại yêu cầu cho chúng tôi về chương trình đối tác B2B và ưu đãi.`,
  ],
  start_with_ringle: [
    <>
      임직원 영어교육
      <br />
      학습효과를 보장하는 링글과 시작하세요
    </>,
    <>Improve your team's English communications with Ringle</>,
    's_b2b.start_with_ringle',
    <>
      职员英语教育
      <br />
      学习效果能够保障, 携手Ringle!
    </>,
    <>
      職員英語教育
      <br />
      與保障學習效果的Ringle一起開始
    </>,
    <>
      社内のより優れた英語教育を
      <br />
      確実な学習成果をお約束するRingleにお任せください。
    </>,
    <>Cải thiện kỹ năng giao tiếp tiếng Anh của đội ngũ với Ringle</>,
  ],
  one_for_free: [
    <>
      문의를 남겨주시면 <Br md down />
      기업 담당자용 1회 정규수업권을 제공합니다
    </>,
    <>Leave an inquiry and get a free lesson!</>,
    's_b2b.one_for_free',
    <>
      留下咨询事项, 将提供
      <Br md down />
      企业负责人1次正规课程券
    </>,
    <>
      留下諮詢事項時，將提供
      <Br md down />
      企業負責人1次正規課程券
    </>,
    <>
      お問い合わせいただいたご担当者には
      <Br md down />
      レギュラーレッスンチケット1枚を差し上げます。
    </>,
    <>Để lại yêu cầu và nhận một buổi học miễn phí!</>,
  ],
  ringle_b2b_program: [
    `링글 B2B 프로그램`,
    ``,
    's_b2b.ringle_b2b_program',
    `Ringle B2B项目`,
    `Ringle B2B計畫`,
    `Ringle B2Bプログラム`,
    ``,
  ],
  serve_high_quality_education: [
    <>
      시공간의 제약 없이
      <br />
      수준 높은 영어 교육을 제공합니다
    </>,
    <>Sophisticated lessons and a specialized platform team leaders</>,
    's_b2b.serve_high_quality_education',
    <>
      不受时间空间限制
      <br />
      致力于提供给您高水平的英语教育
    </>,
    <>
      為您提供不受時空限制的
      <br />
      高水準英語教育
    </>,
    <>
      いつでもどこでも
      <br />
      レベルの高い英語教育を提供します。
    </>,
    <>Buổi học thực tiễn và nền tảng chuyên môn cho lãnh đạo</>,
  ],
  propose_b2b: [`B2B 제안`, `B2B Contract`, 's_b2b.propose_b2b', `B2B提案`, `B2B提案`, `B2B契約`, `Hợp đồng B2B`],
  run_b2b: [`B2B 운영`, `B2B Operation`, 's_b2b.run_b2b', `B2B运营`, `B2B營運`, `B2B管理`, `Vận hành B2B`],
  manage_b2b: [`B2B 관리`, `B2B Management`, 's_b2b.manage_b2b', `B2B管理`, `B2B管理`, `B2Bサポート`, `Quản lý B2B`],
  no_limit_proposal: [
    <>
      최소 수강인원 제한없이
      <br />
      기업수강/제휴할인 등<br />
      예산과 형식에 따라 맞춤 제안
    </>,
    <>
      B2B contracts service an unlimited number of students in each organization and can be further customized to fit
      your budget
    </>,
    's_b2b.no_limit_proposal',
    <>
      无最低学员数限制
      <br />
      企业课程/合作优惠等
      <br />
      将根据公司预算及形式提供定制型方案
    </>,
    <>
      無最低學生限制
      <br />
      企業課程／合作優惠等
      <br />
      依據預算及形式，提供客製化方案
    </>,
    <>
      無制限の受講者数
      <br />
      法人契約/提携割引など
      <br />
      予算やニーズに合わせてご提案
    </>,
    <>
      Hợp đồng B2B cung cấp không giới hạn số lượng người học cho công ty bạn và nhiều lợi ích khác phù hợp với ngân
      sách đề ra
    </>,
  ],
  download_excel: [
    <>
      HR 맞춤 관리 페이지 제공
      <br />
      실시간 학습현황과
      <br />
      엑셀파일 다운로드 가능
    </>,
    <>Ringle's B2B platform allows HR and managers to view and download team's lesson progress</>,
    's_b2b.download_excel',
    <>
      提供HR定制管理页面
      <br />
      可实时下载学习现状
      <br />
      及相关Excel文件
    </>,
    <>
      提供人資客製化管理頁面
      <br />
      可即時下載學習現況及
      <br />
      Excel檔
    </>,
    <>
      人事部専用の管理ページを提供
      <br />
      リアルタイムで受講社員の学習状況の確認、
      <br />
      エクセルファイルダウンロード可能
    </>,
    <>Nền tảng B2B cho phép Nhân sự và Quản lý xem và tải xuống quá trình học của thành viên</>,
  ],
  b2b_one_on_one: [
    <>
      B2B 수강생 전원 1:1 상담
      <br />
      알림톡/푸시/이메일 채널
      <br />
      밀착관리로 수업 지원
    </>,
    <>We provide 1:1 advising to all B2B customers to further guide their English learning journey</>,
    's_b2b.b2b_one_on_one',
    <>
      B2B学员全部1:1咨询
      <br />
      通过提醒/推送/电子邮件等形式
      <br />
      对课程进行密集式辅助管理
    </>,
    <>
      B2B學生全員1:1諮詢
      <br />
      透過訊息通知／推播通知／電子信箱等形式
      <br />
      對課程進行密集式管理
    </>,
    <>
      B2B受講者向けのマンツーマン相談受付け
      <br />
      チャット/プッシュ通知/メール
      <br />
      丁寧に寄り添う英語学習サポート
    </>,
    <>Chúng tôi cung cấp buổi tư vấn 1:1 cho khách hàng B2B giúp định hướng việc học tiếng Anh</>,
  ],
  submit: [`제출하기`, `Submit`, 's_b2b.submit', `提交`, `提交`, `送信する`, `Gửi`],
  total_number_tutees: [
    `누적 수강생`,
    `Students`,
    's_b2b.total_number_tutees',
    `累计学员`,
    `累積學生`,
    `累計受講生数`,
    `Học sinh`,
  ],
  partners: [`제휴사`, `Partners`, 's_b2b.partners', `合作企业`, `合作公司`, `提携企業数`, `Đối tác`],
  satisfaction: [
    `만족도`,
    `Customer satisfaction`,
    's_b2b.satisfaction',
    `满意度`,
    `滿意度`,
    `満足度`,
    `Mức độ hài lòng của khách hàng`,
  ],
  please_fill_in_blank: [
    `성함, 이메일과 제휴문의를 적어주세요.`,
    `Where is this`,
    's_b2b.please_fill_in_blank',
    `请填写姓名, 电子邮箱及合作咨询事项。`,
    `請填寫姓名、電子信箱及合作諮詢事項。`,
    `氏名、メールアドレス、提携に関するお問い合わせを入力してください。`,
    `Để lại yêu cầu cho chúng tôi về chương trình đối tác B2B và ưu đãi.`,
  ],
}
export const s_process = {
  simple_ringle_usage: [
    `간단한 링글 사용법`,
    `Ringle lesson in 7 steps`,
    's_process.simple_ringle_usage',
    `Ringle便捷使用方法`,
    `簡單的Ringle使用方法`,
    `Ringleの受講の流れ`,
    `Tham gia buổi học của Ringle với 7 bước`,
  ],
  select_date: [
    `일정 선택`,
    `Select Date & Time`,
    's_process.select_date',
    `选择日期`,
    `選擇日程`,
    `日時を選択`,
    `Chọn Ngày & Giờ`,
  ],
  select_tutor: [
    `튜터 선택`,
    `Select a tutor`,
    's_process.select_tutor',
    `选择教师`,
    `選擇任課教師`,
    `チューターを選択`,
    `Chọn gia sư`,
  ],
  desc_select_tutor: [
    <>
      전공/학교/경력/발음 등을 확인하고
      <br />
      <Div spanTag textPrimary>
        나에게 꼭 맞는 튜터
      </Div>
      와 수업해보세요.
    </>,
    `Choose a tutor whose expertise matches your interests.`,
    's_process.desc_select_tutor',
    <>
      对专业/学校/经历/发音等内容进行确认后
      <br />
      <Div spanTag textPrimary>
        请选择适合的
      </Div>
      教师开始您的课程。
    </>,
    <>
      確認主修／學校／經歷／發音等，
      <br />與
      <Div spanTag textPrimary>
        適合自己的任課教師
      </Div>
      上課吧！
    </>,
    <>
      専攻・出身校・経歴・アクセントなどから
      <br />
      <Div spanTag textPrimary>
        自分にぴったり合うチューター
      </Div>
      を見つけましょう。
    </>,
    `Lựa chọn gia sư với chuyên môn thuộc lĩnh vực bạn yêu thích.`,
  ],
  select_material: [
    `교재 선택`,
    `Select materials`,
    's_process.select_material',
    `选择教材`,
    `選擇教材`,
    `教材を選択`,
    `Chọn tài liệu`,
  ],
  desc_select_material: [
    `원하는 토픽을 자유롭게 선택하세요. 자료를 직접 업로드할 수 있는 자유주제 수업도 가능합니다.`,
    <>
      Pick out materials on a topic of your interest or have a "Free Topic" discussion.
      <br />
      Or choose Free Topic or upload your own materials ,
    </>,
    's_process.desc_select_material',
    `请自由选择希望的主题, 也可直接上传资料进行主题自选课程`,
    `請自由選擇希望的主題，也可進行親自上傳資料的自由主題課程。`,
    `好きなトピックを自由に選んでください。資料を直接アップロードすることができる自由テーマレッスンも可能です。`,
    <>
      Chọn tài liệu liên quan đến chủ đề yêu thích hoặc thảo luận "Tự do".
      <br />
      Hoặc chọn Chủ đề Tự do hoặc đăng tải tài liệu của bạn ,
    </>,
  ],
  select_question: [
    `교재 읽고, 질문 선택`,
    `Read the materials and select questions`,
    's_process.select_question',
    `阅读教材, 选择题目`,
    `閱讀教材、選擇提問`,
    `教材を読み、レッスン質問を選択`,
    `Đọc tài liệu và chọn các câu hỏi`,
  ],
  desc_select_question: [
    <>
      <Div spanTag textPrimary>
        교재를 예습
      </Div>
      하고, 수업에서 다룰 <Br md down />
      <Div spanTag textPrimary>
        질문을 선택
      </Div>
      해 답변을 준비해보세요.
    </>,
    `Mark questions you'd like to focus on during the lesson.`,
    's_process.desc_select_question',
    <>
      <Div spanTag textPrimary>
        请预习教材
      </Div>
      , 选择课程中进行的
      <Br md down />
      <Div spanTag textPrimary>
        题目
      </Div>
      然后准备相关答案。
    </>,
    <>
      請
      <Div spanTag textPrimary>
        預習教材
      </Div>
      ，選擇上課時要討論的
      <Br md down />
      <Div spanTag textPrimary>
        提問
      </Div>
      後，準備回覆內容。
    </>,
    <>
      <Div spanTag textPrimary>
        教材を予習
      </Div>
      しレッスンで扱う <Br md down />
      <Div spanTag textPrimary>
        質問を選んだら、
      </Div>
      回答を作成してみましょう。
    </>,
    `Đánh dấu vào câu hỏi bạn muốn tập trung trong buổi học.`,
  ],
  require_correction: [
    `수업 방식 설정`,
    `Set Lesson Style`,
    's_process.require_correction',
    `设定课程形式`,
    `上課方式設定`,
    `レッスン形式設定`,
    `Chọn Hình thức học`,
  ],
  desc_require_correction: [
    <>
      토론 중심/교정 중심 등{' '}
      <Div spanTag textPrimary>
        맞춤 수업 방식을 설정
      </Div>
      하세요.
      <Br md up /> "제 영어를 비즈니스 영어로 바꿔주세요." 등{' '}
      <Div spanTag textPrimary>
        상세 요청사항
      </Div>{' '}
      작성도 가능합니다.
    </>,
    `Choose either correction or discussion-focused lesson and leave other requests.`,
    's_process.desc_require_correction',
    <>
      请选择以讨论为主/纠正为主等
      <Div spanTag textPrimary>
        多种定制型课程
      </Div>
      形式。
      <Br md up />
      也可提出"请将我的英语变化成商务英语。"等
      <Div spanTag textPrimary>
        符合自身特点的
      </Div>{' '}
      具体要求。
    </>,
    <>
      請設定以討論／糾正為主等的
      <Div spanTag textPrimary>
        客製化上課方式
      </Div>
      。
      <Br md up />
      也可填寫「請將我的英語變成商務英語。」等
      <Div spanTag textPrimary>
        具體要求事項
      </Div>
      。{' '}
    </>,
    <>
      ディスカッション中心/校正中心等
      <Div spanTag textPrimary>
        ご希望レッスン形式を設定
      </Div>
      します。
      <Br md up /> 「私の英語をビジネス英語に直して」といった
      <Div spanTag textPrimary>
        追加リクエスト事項
      </Div>
      にも対応しています。
    </>,
    `Lựa chọn tập trung sửa lỗi hoặc tập trung thảo luận và những yêu cầu khác.`,
  ],
  enter_lesson: [
    `수업 입장`,
    `Join the lesson`,
    's_process.enter_lesson',
    `课程入场`,
    `進入課程`,
    `レッスン開始`,
    `Tham gia buổi học`,
  ],
  desc_enter_lesson: [
    <>
      수업 입장은{' '}
      <Div spanTag textPrimary>
        [수업 입장]
      </Div>{' '}
      버튼을 통해 수업 시작 15분 전부터 가능합니다.
      <br />한 화면으로 교재/튜터화면/실시간 교정 모두 확인 가능한 PC 사용을 권장드립니다.
    </>,
    <>
      Log in to Ringle to view upcoming lessons and enter lesson on time.
      <br />
      You'll see your tutor, materials, and discussion questions all on the same screen.
    </>,
    's_process.desc_enter_lesson',
    <>
      点击
      <Div spanTag textPrimary>
        [课程入场]
      </Div>
      按钮, 可从课程开始前15分钟进场准备。
      <br />
      建议使用可在同一画面对教材/教师画面/实时纠正进行查看的PC终端。
    </>,
    <>
      透過
      <Div spanTag textPrimary>
        [進入課程]
      </Div>
      按鈕，可於課程開始15分鐘前進入課程。
      <br />
      建議使用電腦於同一個畫面確認教材／任課教師畫面／即時糾正。
    </>,
    <>
      <Div spanTag textPrimary>
        [レッスン開始]
      </Div>
      ボタンでレッスン15分前から入室可能です。
      <br />
      一つの画面で教材・チューター画面・リアルタイム校正全てを確認できるPCの使用を推奨します。
    </>,
    <>
      Đăng nhập vào Ringle để xem các buổi học sắp tới và vào học đúng giờ.
      <br />
      Bạn sẽ thấy thông tin gia sư, tài liệu và các câu hỏi thảo luận trong cùng một trang.
    </>,
  ],
  visible_correction: [
    `바로 보이는 실시간 교정`,
    `Real-time corrections`,
    's_process.visible_correction',
    `即时可看的实时纠正`,
    `可立即確認的即時糾正`,
    `その場で見れるリアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  desc_visible_correction: [
    `수업 중 튜터가 교정해주는 내용을 실시간으로 볼 수 있습니다. 수업 노트는 튜터와 나 모두 사용할 수 있습니다.`,
    `Follow along your tutor's corrections and comments real-time.`,
    's_process.desc_visible_correction',
    `课程中可实时查看教师的纠正内容。同时课堂笔记教师及本人均可使用。`,
    `上課時可即時確認任課教師糾正的內容，任課教師與您均可使用課堂筆記。`,
    `レッスン中にチューターが校正してくれる内容をリアルタイムで見ることができます。レッスンノートはチューターと自分の両者が使用可能です。`,
    `Theo dõi những lỗi được sửa và bình luận của gia sư trực tiếp.`,
  ],
  script_and_recording: [
    `2. 대화 스크립트`,
    `2. Recording & Transcript`,
    's_process.script_and_recording',
    `2. 对话脚本`,
    `2. 對話腳本`,
    `2. 録音＆会話スクリプト`,
    `2. Ghi âm & Bản ghi`,
  ],
  desc_script_and_recording: [
    `AI가 기록한 스크립트를 보고 들으며 복습할 수 있어요. 튜터와 나의 대화를 녹음 파일 & 스크립트로 언제든 다시 확인하세요.`,
    `You can revisit the lesson by listening to audio recording and reading conversation transcript`,
    's_process.desc_script_and_recording',
    `可查看及收听AI记录的脚本内容, 对课程进行复习。请随时确认教师与本人的对话录音&脚本文件。`,
    `可一邊聽看AI記錄的腳本一邊複習。隨時可利用錄音 & 腳本再次確認任課教師與我的對話。`,
    `AIが記録したスクリプトを読み聞き直すことができます。レッスン中の会話を音声とスクリプトから復習しましょう。`,
    `Bạn có thể xem lại buổi học bằng cách nghe ghi âm và đọc bản ghi cuộc hội thoại`,
  ],
  based_on_AI: [
    `3. AI 분석`,
    `3. AI Analysis`,
    's_process.based_on_AI',
    `3. AI分析`,
    `3. AI分析`,
    `AI 分析`,
    `3. Phân tích bằng AI`,
  ],
  desc_based_on_AI: [
    `AI가 분석한 영어회화 패턴을 확인하세요. 말하기 속도, 자주 쓰는 단어, Filler Word 빈도 등을 분석하고, 대체 가능한 표현도 확인 가능합니다.`,
    `Ringle offers AI-generated analysis on suggestions on speech pace and vocabulary range.`,
    's_process.desc_based_on_AI',
    `请确认AI分析的英语会话模式。同时可对口语速度, 常用词汇, Filler Word频率等内容进行分析并了解相关替代表达方式。`,
    `請確認AI分析的英語會話模式。也可確認分析口說速度、常用單字、Filler Word頻率等後可取代的用法。`,
    `AIが分析した英会話パターンを確認しましょう。話すスピード、よく使う単語、つなぎ言葉(Filler Word)の頻度などを分析し、代わりとなる表現もここから確認できます。`,
    `Ringle cung cấp phân tích thực hiện bởi AI với những gợi ý về tốc độ nói và từ vựng.`,
  ],
  multi_feedback: [
    `4. 다각도 피드백`,
    `4. Comprehensive Feedback`,
    's_process.multi_feedback',
    `4. 多角度反馈`,
    `4. 全方位意見回饋`,
    `4. 多角的フィードバック`,
    `4. Đánh giá toàn diện`,
  ],
  desc_multi_feedback: [
    `발음, 문법, 어휘 등 영역별로 분석된 튜터의 피드백을 받아볼 수 있습니다. 나만을 위한 피드백으로 영어 실력을 키워보세요.`,
    `After each lesson, your tutor will leave a detailed feedback on vocabulary, fluency, grammar, and pronunciation.`,
    's_process.desc_multi_feedback',
    `可获得教师提供的包含发音, 语法, 词汇等各方面内容的意见反馈。通过针对自身的学习反馈, 提升您的英语水平吧。`,
    `可取得任課教師分析發音、文法、詞彙等各領域的意見回饋，藉由專屬於我的意見回饋提升英語實力吧！`,
    `発音、文法、語彙など分野別に分析されたチューターのフィードバックを受けることができます。あなたのためだけのフィードバックで英語力を伸ばしましょう。`,
    `Sau mỗi buổi học, gia sư của bạn sẽ cung cấp phản hồi chi tiết về từ vựng, sự trôi chảy, ngữ pháp và phát âm..`,
  ],
  correction_note: [
    `1. 실시간 교정`,
    `1. Correction notes from lesson`,
    's_process.correction_note',
    `1.实时纠正`,
    `1.即時糾正`,
    `1. レッスンノート`,
    `1. Ghi chú sửa lỗi của buổi học`,
  ],
  desc_correction_note: [
    `튜터와 대화하며 실시간 교정과 피드백을 확인합니다. 수업 후에도 언제든 다시 노트를 복습할 수 있습니다.`,
    `You can review the tutor correction doc that you used during the lesson.`,
    's_process.desc_correction_note',
    `与教师进行对话, 可实时确认纠正内容及反馈。同时课程结束后可随时查阅课程笔记进行复习。`,
    `與任課教師對話，確認即時糾正與意見回饋。課程結束後，隨時可再次複習筆記。`,
    `チューターと会話しながらリアルタイム校正とフィードバックが書き込まれます。レッスン後にはいつでもノートで復習できます。`,
    `Bạn có thể xem lại bản sửa lỗi của gia sư trong buổi học.`,
  ],
  tutor_i_selected: [
    `언제 어디서나`,
    `Design your own lessons`,
    's_process.tutor_i_selected',
    `何时何地`,
    `隨時隨地`,
    `いつでもどこでも`,
    `Thiết kế buổi học`,
  ],
  just_for_you: [
    `원하는 수업을 자유롭게`,
    `wherever and whenever`,
    's_process.just_for_you',
    `自由选择希望的课程`,
    `自由進行想要的課程`,
    `好きなレッスンを自由に`,
    `bất cứ đâu và bất cứ khi nào`,
  ],
  what_offered_after_lesson: [
    `수업이 끝나고 제공되는 것들`,
    `What’s offered after the lesson`,
    's_process.what_offered_after_lesson',
    `课程结束后即可提供`,
    `課程結束後提供的內容`,
    `レッスン後に提供されるもの`,
    `Bạn được cung cấp sau buổi học`,
  ],
  real_lesson_video: [
    `실제 수업 영상`,
    `Here's a recording of an actual Ringle lesson`,
    's_process.real_lesson_video',
    `实际课程视频`,
    `實際課程影片`,
    `実際のレッスン動画`,
    `Đây là bản ghi âm một buổi học cùa Ringle`,
  ],
  desc_real_lesson_video: [
    <>
      실제 링글 수업을 수강 중인 모습입니다.
      <br />
      질문 2-3개로 대화하다 보면 생각보다 40분 수업이 금방 끝납니다.
    </>,
    ``,
    's_process.desc_real_lesson_video',
    <>
      Ringle课程实际状态。
      <br />
      针对2-3个问题进行对话, 40分钟的课程完全没有想象中漫长。
    </>,
    <>
      Ringle實際聽課情形。
      <br />
      利用2～3個提問對話，40分鐘課程比想像中更快結束。
    </>,
    <>
      実際のRingleレッスン受講中の様子です。
      <br />
      レッスン質問を2、3個で会話してみると、40分のレッスンもあっという間に終わります。
    </>,
    ``,
  ],
  free_lesson: [
    `무료 체험 수업 신청하기`,
    `Sign up now!`,
    's_process.free_lesson',
    `申请免费体验课程`,
    `申請免費體驗課程`,
    `無料体験レッスンを予約する`,
    `Học thử miễn phí`,
  ],
  qa: [
    <>첫 수업 불만족 시 즉시 환불</>,
    `100% Money-back guarantee`,
    's_process.qa',
    <>首次课程不满意时可立即退款</>,
    <>首次課程不滿意時可立即退款</>,
    <>初回レッスンにご満足いただけない場合、全額返金保証</>,
    `Đảm bảo hoàn tiền 100%`,
  ],
  first_experience: [
    <>경험하고 결정하세요. 수업권 구매 후, 첫 수업에 만족하지 못하시면 즉시 환불해드립니다.</>,
    <>We promise a full refund if you're not 100% satisfied with your first paid lesson.</>,
    's_process.first_experience',
    <>请先体验后再决定。购买课程券后，如对首次课程不满意，将会立即为您进行退款。</>,
    <>請先體驗再決定。購買課程券後，首次課程不滿意時，會立即退款給您。</>,
    <>
      体験してから決めてください。レッスンチケット購入後、初回レッスンにご満足いただけなければ\nすぐに返金いたします。
    </>,
    <>Chúng tôi đảm bảo hoàn tiền đầy đủ nếu bạn không hài lòng 100% với buổi học tính phí đầu tiên.</>,
  ],
  only_for_first: [
    <>* 체험 수업도 첫 수업에 포함됨</>,
    <>*Includes trial lesson</>,
    's_process.only_for_first',
    <>*试听课程也包含在首次课程中</>,
    <>* 首次課程也包含體驗課程</>,
    <>* 初回レッスン、体験レッスンが対象</>,
    ``,
  ],
  time_assurance: [
    <>첫 수업 시작 전까지 수강기간 미차감</>,
    `Start Whenever`,
    's_process.time_assurance',
    <>首次课程开始前课程期限不被扣除</>,
    <>首次課程開始之前，不扣除聽課期間</>,
    <>自分のペースで始められる </>,
    `Bắt đầu bất cứ khi nào`,
  ],
  start_as_you_like: [
    <>원하는 일정에 맞춰 시작하세요.</>,
    <Fragment>You can purchase lesson credits now and start later.</Fragment>,
    's_process.start_as_you_like',
    <>选择希望的时间, 开始您的课程吧。</>,
    <>請在您希望的日程開始。</>,
    <>ご希望の日時に合わせて始めましょう。</>,
    <Fragment>Bạn có thể mua gói học trước và bắt đầu sau.</Fragment>,
  ],
  can_extend: [
    <>* 구매 후 1년 이후 수강기간 자동 차감 </>,
    <>* Unused credits' expiration dates will start counting down a year after the purchase date.</>,
    's_process.can_extend',
    <>* 课程期限从第一次上课开始计算.</>,
    <>* 購買後一年期間，於完成首次課程時扣除聽課期間。</>,
    <>* レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。</>,
    <>* Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.</>,
  ],
  finish_assurance: [
    <>수업 하루 전까지 변경 가능</>,
    `Flexible Cancellation`,
    's_process.finish_assurance',
    <>截止于课程开始前一天可进行变更</>,
    <>最晚可於課程開始1天前變更</>,
    <>レッスン前日まで予約変更できる</>,
    `Huỷ linh hoạt`,
  ],
  dont_miss: [
    <>일정이 생겨도 끝까지 수강하세요.</>,
    <Fragment>You can cancel or reschedule your lesson up to 24 hours before the lesson.</Fragment>,
    's_process.dont_miss',
    <>即使有其他日程, 也请尽可能完成课程。</>,
    <>即使有其他日程，也能聽完所有課程！</>,
    <>万一予定が入っても柔軟に対応します。</>,
    <Fragment>Bạn có thể huỷ hoặc dời buổi học tối thiểu 24 giờ trước buổi học.</Fragment>,
  ],
  unlimited_provided: [
    <>
      * 수업 시작 기준 24~2시간 이전 변경 시,
      <br />
      당일수업권 제공
    </>,
    ``,
    's_process.unlimited_provided',
    <>
      * 截止于课程开始前两小时变更时
      <br />
      将提供补充课程券
    </>,
    <>
      * 最晚於課程開始2小時前變更時間時，
      <br />
      將提供補充課程券
    </>,
    <>
      * 開始2時間前までの変更は
      <br />
      補充レッスンチケットに交換
    </>,
    ``,
  ],
  try_lesson: [
    `무료체험 수업 신청하기`,
    `Start now with a free trial`,
    's_process.try_lesson',
    `申请免费咨询`,
    `申請免費諮詢`,
    `無料体験レッスンのお申込み`,
    `Đăng ký`,
  ],
  always: [
    <>
      365일 연중무휴 & 하루에도 여러 번<br />
      <Div spanTag textPrimary>
        원하는 일정을 선택
      </Div>
      할 수 있습니다.
    </>,
    `Choose a time that best fits your schedule.`,
    's_process.always',
    <>
      365天全年无休&一天内可选择
      <br />
      <Div spanTag textPrimary>
        多个希望的时间
      </Div>
      开展课程。
    </>,
    <>
      365日全年無休&一天可選擇
      <br />
      數個
      <Div spanTag textPrimary>
        希望的時間
      </Div>
      。
    </>,
    <>
      365日年中無休 & 1日に何回でも
      <br />
      <Div spanTag textPrimary>
        好きな時間帯を選択
      </Div>
      することができます。
    </>,
    `Chọn thời gian phù hợp với lịch của bạn.`,
  ],
  ringle_application: [
    `링글 어플리케이션`,
    `Ringle application`,
    's_process.ringle_application',
    `Ringle应用程序`,
    `Ringle APP`,
    `Ringleアプリ`,
    `Ứng dụng Ringle`,
  ],
  desc_enter_lesson_title: [
    <>
      수업 입장은{' '}
      <Div spanTag textPrimary>
        [수업 입장]
      </Div>{' '}
      버튼을 통해 수업 시작 15분 전부터 가능합니다.
    </>,
    <>
      Log in to Ringle and enter lesson on time.
      <br />
      See your tutor, materials, and questions all on the same screen.
    </>,
    's_process.desc_enter_lesson_title',
    <>
      课程入场
      <Div spanTag textPrimary>
        可通过点击[课程入场]
      </Div>
      按钮, 从课程开始前15分钟可以进场准备。
      <br />
      建议使用可在同一画面对教材/教师画面/实时纠正等进行查看的PC终端。
    </>,
    <>
      透過
      <Div spanTag textPrimary>
        [進入課程]
      </Div>
      按鈕，可於課程開始15分鐘前進入課程。
    </>,
    <>
      <Div spanTag textPrimary>
        [レッスン開始]
      </Div>{' '}
      ボタンでレッスン開始15分前から入室可能です。
    </>,
    <>
      Đăng nhập vào Ringle và tham gia buổi học đúng giờ.
      <br />
      Xem thông tin gia sư, tài liệu và tất cả các câu hỏi trên cùng một trang.
    </>,
  ],
  desc_enter_lesson_sub: [
    <>한 화면으로 교재/튜터 화면/실시간 교정 모두 확인 가능한 PC 사용을 권장드립니다.</>,
    <></>,
    's_process.desc_enter_lesson_sub',
    <>建议使用可于同一画面对教材/教师画面/实时纠正等内容进行同时查看的PC终端设备。</>,
    <>建議使用電腦於同一個畫面確認教材／任課教師畫面／即時糾正。</>,
    <>一つの画面で教材・チューター・リアルタイム校正を確認できるPCの使用を推奨します。</>,
    <></>,
  ],
  desc_visible_correction_title: [
    <>
      수업 중 튜터가 교정해주는 내용을{' '}
      <Div spanTag textPrimary>
        실시간
      </Div>
      으로 볼 수 있습니다.
    </>,
    <>Follow along your tutor's corrections and comments real-time.</>,
    's_process.desc_visible_correction_title',
    <>
      课程中教师纠正的内容
      <Div spanTag textPrimary>
        可实时
      </Div>
      进行查看。
    </>,
    <>
      上課時可
      <Div spanTag textPrimary>
        即時
      </Div>
      確認任課教師糾正的內容。
    </>,
    <>
      レッスン中にチューターが校正をしている画面を{' '}
      <Div spanTag textPrimary>
        リアルタイムで
      </Div>
      見ることができます。
    </>,
    <> Theo dõi những lỗi được sửa và bình luận của gia sư trực tiếp.</>,
  ],
  desc_visible_correction_sub: [
    <>
      교정 노트는 튜터와 나 모두 <Br md down />
      사용할 수 있습니다.
    </>,
    <></>,
    's_process.desc_visible_correction_sub',
    <>
      教师及学生本人
      <Br md down />
      均可使用纠正笔记。
    </>,
    <>
      任課教師與您均可使用
      <Br md down />
      糾正筆記。
    </>,
    <>
      レッスンノートはチューターと自分どちらも <Br md down />
      アクセスできます。
    </>,
    <></>,
  ],
  all_device: [
    <>
      PC, 태블릿, 스마트폰으로 <Br md down />
      예약 가능합니다.
    </>,
    <></>,
    's_process.all_device',
    <>
      PC, 平板, 智能手机
      <Br md down />
      均可预约课程。
    </>,
    <>
      可使用電腦、平板電腦、智慧型手機
      <Br md down />
      預約。
    </>,
    <>
      PC、タブレット、スマートフォンから <Br md down />
      予約可能
    </>,
    <></>,
  ],
  desc_select_material_title: [
    <>
      <Div spanTag textPrimary>
        원하는 교재
      </Div>
      를 자유롭게 선택하세요.
    </>,
    `Pick out lesson materials on a topic of your interest.`,
    's_process.desc_select_material_title',
    <>
      <Div spanTag textPrimary>
        请自由选择
      </Div>
      您感兴趣的教材。
    </>,
    <>
      請自由選擇
      <Div spanTag textPrimary>
        希望的教材
      </Div>
      。
    </>,
    <>
      <Div spanTag textPrimary>
        好きな教材
      </Div>
      を自由に選ぶことができます。
    </>,
    `Chọn tài liệu liên quan đến chủ đề yêu thích.`,
  ],
  desc_select_material_title_sub: [
    `자료를 직접 업로드할 수 있는 자유주제 수업도 가능합니다.`,
    <>
      Pick out materials on a topic of your interest or have a "Free Topic" discussion.
      <br />
      Or choose Free Topic or upload your own materials
    </>,
    's_process.desc_select_material_title_sub',
    `同时也可自主上传资料进行自由主题课程。`,
    `也可進行親自上傳資料的自由主題課程。`,
    `自分でアップロードした資料を用いた自由テーマレッスンも対応。`,
    <>Hoặc chọn Chủ đề tự do hoặc đăng tải tài liệu của bạn.</>,
  ],
  ringle_platform: [
    `Ringle Platform`,
    ``,
    's_process.ringle_platform',
    `Ringle Platform`,
    `Ringle Platform`,
    `Ringle Platform`,
    ``,
  ],
}
export const s_system = {
  for_better_study: [
    <>
      오직 당신을 위한
      <br />
      일대일 맞춤 수업 시스템
    </>,
    <>
      The best-in-class
      <br />
      learning platform
    </>,
    's_system.for_better_study',
    <>
      专为您量身打造的
      <br />
      1:1定制型课程系统
    </>,
    <>
      專為您量身打造的
      <br />
      1:1客製化課程系統
    </>,
    <>
      あなた専用の
      <br />
      1on1カスタムレッスンシステム
    </>,
    <>
      Nền tảng học tập
      <br />
      tốt nhất
    </>,
  ],
  reservation_system: [`예약`, `Book`, 's_system.reservation_system', `预约`, `預約`, `予約`, `Đặt lịch`],
  reservation_subtitle: [
    <>
      365일 원클릭으로 <br />
      직접 정하는 수업 일정
    </>,
    ``,
    's_system.reservation_subtitle',
    <>
      365天, 一键式
      <br />
      自主便捷安排课程
    </>,
    <>
      365天一鍵
      <br />
      親自決定課程日程
    </>,
    <>
      365日ワンクリックで <br />
      自由に決められるレッスン日程
    </>,
    ``,
  ],
  tutor_i_want: [
    `원하는 튜터 직접 선택`,
    `Searchable Tutor Profiles`,
    's_system.tutor_i_want',
    `直接选择满意的教师`,
    `親自選擇希望的任課教師`,
    `好きなチューターを自由に選択`,
    `Tìm kiếm hồ sơ gia sư dễ dàng`,
  ],
  tutor_i_want_detail_1: [
    `1000+명의 튜터 상세 프로필 제공`,
    `View detailed profiles of 1000+ tutors`,
    's_system.tutor_i_want_detail_1',
    `提供1000名以上的教师详细简介`,
    `提供1000名以上任課教師的詳細簡介`,
    `1000名超えるチューターの詳細なプロフィールが見られる`,
    `Xem hồ sơ chi tiết của 1000+ gia sư`,
  ],
  tutor_i_want_detail_2: [
    `전공/경력/커리어/발음 등 맞춤 검색`,
    `Search by tutor's major, experience, career`,
    's_system.tutor_i_want_detail_2',
    `进行专业/经历/职业/发音等针对性搜索`,
    `主修／經歷／職業／發音等個人化搜尋`,
    `専攻・経歴・アクセントなどから詳細検索`,
    `Tìm kiếm theo chuyên ngành, kinh nghiệm, công việc của gia sư`,
  ],
  tutor_i_want_detail_3: [
    `찜한 튜터 수업 오픈 시 알림 제공`,
    `Set notifications for your favorite tutors`,
    's_system.tutor_i_want_detail_3',
    `提供指定教师的开课提醒`,
    `我收藏的任課教師公開課程時提供通知`,
    `お気に入りのチューターの予定が空いたら通知でお知らせ`,
    `Đặt thông báo cho gia sư yêu thích của bạn`,
  ],
  auto_matching: [
    `자동 매칭 시스템`,
    `Ringle Auto Matching`,
    's_system.auto_matching',
    `自动匹配系统`,
    `自動配對系統`,
    `自動マッチングシステム`,
    `Ringle xếp ngẫu nhiên`,
  ],
  auto_matching_detail_1: [
    `원하는 일정만 선택하면 최적의 튜터 매칭`,
    `We'll match you to the best tutor available`,
    's_system.auto_matching_detail_1',
    `只要选择希望的日期, 即可为您提供最佳的匹配教师`,
    `選擇希望日程便為您配對最適合的任課教師。`,
    `希望日程を選択するだけで最適なチューターとマッチング`,
    `Chúng tôi chọn gia sư tốt nhất cho bạn`,
  ],
  free_scheduling: [
    `자유로운 일정 변경`,
    `Flexible Booking`,
    's_system.free_scheduling',
    `自由变更日期`,
    `自由變更日程`,
    `柔軟に日程変更できる`,
    `Đặt lịch linh hoạt`,
  ],
  free_scheduling_detail_1: [
    `연중무휴로 하루에도 횟수 제한 없이 예약 가능`,
    `Book as many or as little lessons as you like`,
    's_system.free_scheduling_detail_1',
    `全年无休, 每日预约次数无上限`,
    `全年無休，每日預約次數無上限`,
    `年中無休でレッスン予約可能、回数制限なし`,
    `Đặt số lượng buổi học theo nhu cầu`,
  ],
  free_scheduling_detail_2: [
    `수업 시작 24시간 전까지 변경/취소 가능`,
    `Pick a time that works for you`,
    's_system.free_scheduling_detail_2',
    `变更/取消截止于课程开始前24小时`,
    `最晚可於課程開始24小時前變更／取消`,
    `レッスン開始24時間前まで変更・キャンセル可能`,
    `Chọn thời gian phù hợp với bạn`,
  ],
  free_scheduling_detail_3: [
    `수업 시작 기준 24~2시간 이전 취소 시, 당일수업권 제공`,
    `Flexible cancellations`,
    's_system.free_scheduling_detail_3',
    `当天取消将提供补充课程券(截止于课程开始前两小时)`,
    `當天取消提供補課券 (最晚於課程開始2小時前)`,
    `当日キャンセル時は、補充レッスンチケット付与(レッスン開始2時間前まで)`,
    `Huỷ linh hoạt`,
  ],
  prestudy_system: [`예습`, `Lesson Prep`, 's_system.prestudy_system', `预习`, `預習`, `予習`, `Chuẩn bị Buổi học`],
  prestudy_subtitle: [
    <>
      일대일 수업에 최적화된 <br />
      학습 인터페이스
    </>,
    ``,
    's_system.prestudy_subtitle',
    <>
      最佳的1:1课程
      <br />
      学习平台
    </>,
    <>
      最適合1:1課程的
      <br />
      學習介面
    </>,
    <>
      1on1レッスンに特化した
      <br />
      学習インターフェース
    </>,
    ``,
  ],
  course: [
    <>온라인 교재와 오디오북</>,
    <>Lesson Materials</>,
    's_system.course',
    <>在线教材及有声读物</>,
    <>線上教材與有聲書</>,
    <>オンライン教材とオーディオブック</>,
    <>Tài liệu học</>,
  ],
  course_detail_1: [
    <>PC/모바일 앱에서 무제한 열람</>,
    <>Search by topic or difficulty</>,
    's_system.course_detail_1',
    <>PC/手机app无限制浏览</>,
    <>電腦／手機APP無限閱覽</>,
    <>PC/モバイルアプリから無制限閲覧</>,
    <>Xem trên điện thoại hoặc máy tính</>,
  ],
  course_detail_2: [
    <>PDF/MP3로 다운로드</>,
    <>Read on Ringle mobile app or PC</>,
    's_system.course_detail_2',
    <>下载PDF/MP3</>,
    <>下載PDF／MP3</>,
    <>PDF又はMP3でダウンロード可能</>,
    <>Tải sách nói định dạng MP3</>,
  ],
  course_detail_3: [
    <>국/영문 통합본 제공</>,
    <>Download audiobook in MP3</>,
    's_system.course_detail_3',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  pre_qna: [
    `사전 질문 선택 및 답변 작성`,
    `Discussion Questions`,
    's_system.pre_qna',
    `课前选择提问及填写答案`,
    `事先選擇提問及填寫回覆`,
    `レッスンの質問`,
    `Câu hỏi thảo luận`,
  ],
  can_choose_question: [
    `수준별 질문 선택으로 난이도 조절`,
    `Choose questions of desired difficulty level`,
    's_system.can_choose_question',
    `选择不同水平的问题调节难易度`,
    `選擇各種程度的提問調整難度`,
    `レベル別の質問から自分に合った難易度を選べる`,
    `Chọn câu hỏi theo mức độ khó mong muốn`,
  ],
  answer_to_study: [
    `질문별 답변 작성으로 영작문 연습`,
    `Prep responses in advance`,
    's_system.answer_to_study',
    `回答问题练习英文写作`,
    `藉由回覆各提問練習英文寫作`,
    `回答の事前作成で英作文の練習`,
    `Chuẩn bị trước câu trả lời`,
  ],
  share_answers: [
    `학습자와 튜터의 답변 공유 가능`,
    `Request proofreading on written response`,
    's_system.share_answers',
    `学员及教师们可共享回答`,
    `學生與任課教師可共享回覆`,
    `他の受講生と回答を共有する`,
    `Yêu cầu hiệu đính câu trả lời đã viết`,
  ],
  can_upload_my_data: [
    `개인 수업 자료 업로드`,
    `Upload Your Own Materials`,
    's_system.can_upload_my_data',
    `上传个人课程材料`,
    `上傳個人課程資料`,
    `自分専用のレッスン資料アップロード`,
    `Đăng tài liệu của bạn`,
  ],
  free_topic_material: [
    `영어 인터뷰, 발표 준비, 이메일 교정 등 맞춤 수업 가능`,
    <>Upload resume, presentation, articles, etc.</>,
    's_system.free_topic_material',
    `可选择英语面试, 发表准备, 校对邮件等各种定制型课程`,
    `可選擇英語面試、發表準備、糾正電子郵件等各種客製化課程`,
    `英語面接、発表準備、メール校正などニーズに合わせたカスタマイズレッスン可能`,
    <>Đăng resume, bài thuyết trình, bài báo,...</>,
  ],
  format_support: [
    `PDF, Docx 등 다양한 포맷 지원`,
    <>Upload in PDF, DOC, or PPT</>,
    's_system.format_support',
    `支持PDF, Docx等多种格式`,
    `支援PDF、Docx等各種格式`,
    `PDF、DOC、PPTなど様々なフォーマットに対応`,
    <>Đăng ở định dạng PDF, DOC, hoặc PPT</>,
  ],
  security_system: [
    `개인정보 보호 기능`,
    `Security system protects confidential info`,
    's_system.security_system',
    `个人信息保护功能`,
    `個人資料保護功能`,
    `厳重なセキュリティシステムで個人情報を守る`,
    `Hệ thống bảo mật thông tin của bạn`,
  ],
  setting: [`설정`, `Lesson Style`, 's_system.setting', `设定`, `設定`, `設定`, `Hình thức học`],
  setting_subtitle: [
    <>
      원하는 수업을 <br />
      수준에 맞춰 설계
    </>,
    ``,
    's_system.setting_subtitle',
    <>
      按照自身水平
      <br />
      设计理想课程
    </>,
    <>
      依照程度
      <br />
      設計希望的課程
    </>,
    <>
      好きなレベルに合わせた <br />
      レッスン設計
    </>,
    ``,
  ],
  lesson_style: [
    <>수업 방식 설정</>,
    <>Customizable Lesson Style</>,
    's_system.lesson_style',
    <>设定课程形式</>,
    <>上課方式設定</>,
    <>レッスン形式設定</>,
    <>Tùy chỉnh Hình thức học</>,
  ],
  debate_talk: [
    <>교정 중심 vs 토론 중심</>,
    <>Discussion Focused vs Paraphrasing Focused</>,
    's_system.debate_talk',
    <>以纠正为主 vs 以讨论为主</>,
    <>以糾正為主 vs 以討論為主</>,
    <>校正中心 or ディスカッション中心</>,
    <>Tập trung thảo luận vs Tập trung sửa lỗi</>,
  ],
  intensity: [
    <>대화 비중 및 교정 강도 조절</>,
    <>Control discussion engagement</>,
    's_system.intensity',
    <>调整对话比重及纠正强度</>,
    <>調整對話比重及糾正強度</>,
    <>会話の比重および校正レベル調節</>,
    <>Kiểm soát tần suất sửa lỗi</>,
  ],
  focusing_area: [
    <>집중 교정 영역 선택</>,
    <>Main Area of Focus</>,
    's_system.focusing_area',
    <>选择集中纠正的方面</>,
    <>選擇集中糾正領域</>,
    <>集中校正分野が選べる</>,
    <>Phần sửa lỗi</>,
  ],
  detail_area: [
    <>어휘, 문법, 표현, 발음 등 세분화된 교정 영역</>,
    <>Choose from 4 categories</>,
    's_system.detail_area',
    <>词汇, 语法, 表达, 发音等详细纠正方面</>,
    <>詞彙、文法、用法、發音等詳細糾正領域</>,
    <>流暢性、語彙、文法、発音の細分化された4の校正分野</>,
    <>Lựa chọn từ 4 mục sau</>,
  ],
  area_feedback: [
    <>선택 영역 기반 피드백 제공</>,
    <>Feedback provided based on your selection</>,
    's_system.area_feedback',
    <>提供基于选择方面的针对性反馈</>,
    <>針對所選領域提供意見回饋</>,
    <>選択分野をもとにフィードバック提供</>,
    <>Đánh giá dựa trên lựa chọn của bạn</>,
  ],
  detail_request: [
    <>상세 요청사항 작성</>,
    <>Other Requests</>,
    's_system.detail_request',
    <>填写详细的要求事项</>,
    <>填寫具體要求事項</>,
    <>追加リクエスト事項の入力</>,
    <>Những yêu cầu khác</>,
  ],
  ask_before: [
    <>원하는 수업 방향을 튜터에게 전달</>,
    <>Specify any other requests to your tutor</>,
    's_system.ask_before',
    <>将希望的课程方向传达给教师</>,
    <>向任課教師傳達希望的課程方向</>,
    <>希望するレッスンの方向性を事前に伝えられる</>,
    <>Nêu rõ những yêu cầu khác cho gia sư</>,
  ],
  lesson_design: [
    <>학습 목적에 맞는 수업 설계</>,
    <>Further customize your lessons to fit learning objectives</>,
    's_system.lesson_design',
    <>设计符合学习目的的课程</>,
    <>設計符合學習目的的課程</>,
    <>学習目的に沿ったレッスン設計</>,
    <>Tuỳ chỉnh thêm cho buổi học để phù hợp với mục tiêu của bạn</>,
  ],
  lesson_system: [`수업`, `Lessons`, 's_system.lesson_system', `课程`, `課程`, `レッスン`, `Buổi học`],
  lesson_subtitle: [
    <>
      말하면 눈에 보이는 <br />
      실시간 교정 화면
    </>,
    ``,
    's_system.lesson_subtitle',
    <>
      对话时可进行直观确认的
      <br />
      实时纠正画面
    </>,
    <>
      說出口便能確認的
      <br />
      即時糾正畫面
    </>,
    <>
      その場で理解を深められる <br />
      オンライン学習画面
    </>,
    ``,
  ],
  realtime_correction: [
    `실시간 교정 제공`,
    `Real-time corrections`,
    's_system.realtime_correction',
    `提供实时纠正`,
    `提供即時糾正`,
    `リアルタイム校正`,
    `Sửa lỗi trực tiếp`,
  ],
  realtime_correction_detail_1: [
    `나의 영어를 실시간으로 시각화`,
    `View corrections, lesson materials, and tutor simultaneously`,
    's_system.realtime_correction_detail_1',
    `我的英语实时可视化`,
    `即時將我的英語視覺化`,
    `自分の英語がその場で校正される`,
    `Xem phần sửa lỗi, tài liệu học và gia sư cùng lúc`,
  ],
  realtime_correction_detail_2: [
    `영어 글쓰기 공동작업`,
    ``,
    's_system.realtime_correction_detail_2',
    `英语写作共同进行`,
    `共同進行英文寫作`,
    `チューターと一緒に英作文作成・直し`,
    ``,
  ],
  realtime_correction_detail_3: [
    `수업 후에도 수업 노트 확인 가능`,
    <>You can view correction notes after lessons</>,
    's_system.realtime_correction_detail_3',
    `课程结束后可确认课堂笔记`,
    `課程結束後也可確認課堂筆記`,
    `レッスン後にレッスンノートが見られる`,
    <>Bạn có thể xem bản ghi sửa lỗi sau buổi học</>,
  ],
  translation_function: [
    `번역기/사전 탑재`,
    `Translator & Dictionary`,
    's_system.translation_function',
    `安装有翻译器/字典`,
    `附有翻譯機／字典`,
    `翻訳・辞書機能搭載`,
    `Phiên dịch & Từ điển`,
  ],
  google_translator: [
    `수업 중 번역기 사용 가능`,
    `Translating tools available during lessons`,
    's_system.google_translator',
    `课程中可使用翻译器`,
    `上課時可使用翻譯機`,
    `レッスン中に翻訳機能が使える`,
    `Công cụ dịch có sẵn trong quá trình học`,
  ],
  dictionary: [`영/한 사전 탑재`, ``, 's_system.dictionary', ``, ``, ``, ``],
  realtime_video: [
    `끊김없는 화상환경`,
    `1:1 Video conferencing`,
    's_system.realtime_video',
    `无中断的视频环境`,
    `不中斷的視訊環境`,
    `マンツーマンのオンラインレッスン`,
    `Học trực tuyến 1:1`,
  ],
  realtime_video_detail_1: [
    `최신 화상 프로그램(Zoom) 사용`,
    `Lesson conducted via Zoom`,
    's_system.realtime_video_detail_1',
    `使用最新视频软件(Zoom)`,
    `使用最新視訊軟體（Zoom）`,
    `最新のWeb会議アプリ(Zoom)使用`,
    `Buổi học diễn ra trên Zoom`,
  ],
  realtime_video_detail_2: [
    `운영팀 실시간 지원`,
    `Support team available real-time`,
    's_system.realtime_video_detail_2',
    `运营组实时在线服务`,
    `營運團隊即時支援`,
    `リアルタイムで技術サポート`,
    `Đội ngũ hỗ trợ luôn sẵn sàng`,
  ],
  realtime_video_detail_3: [
    `네트워크 문제 시 해결 및 보상`,
    ``,
    's_system.realtime_video_detail_3',
    `出现网络问题时的解决及补偿`,
    `出現網路問題時的解決及補償`,
    `ネットワークトラブル発生時は解決支援＆補償あり`,
    ``,
  ],
  review_system: [`복습`, `Lesson Review`, 's_system.review_system', `复习`, `複習`, `復習`, `Đánh giá buổi học`],
  review_system_subtitle: [
    <>
      수업이 끝나도
      <br />
      기록에 남는 수업
    </>,
    ``,
    's_system.review_system_subtitle',
    <>
      即使课程结束
      <br />
      仍留有记录
    </>,
    <>
      即使課程結束，
      <br />
      仍留有紀錄
    </>,
    <>
      レッスンが終わっても
      <br />
      記録に残るレッスン
    </>,
    ``,
  ],
  review_script: [
    <>녹음파일 & 대화 스크립트</>,
    <>Recording & Transcript</>,
    's_system.review_script',
    <>录音文件&对话脚本</>,
    <>錄音檔 & 對話腳本</>,
    <>録音 & 会話スクリプト</>,
    <>Ghi âm & Bản ghi</>,
  ],
  review_script_detail_1: [
    <>수업 전체 녹음파일 지원</>,
    <>Audio-recording of your lesson</>,
    's_system.review_script_detail_1',
    <>提供全部课程录音文件</>,
    <>提供所有課程錄音檔</>,
    <>全レッスンの録音ファイルを提供</>,
    <>File ghi âm buổi học của bạn</>,
  ],
  review_script_detail_2: [
    <>튜터와 나눈 대화를 스크립트로 제공</>,
    <>Conversation script of your lesson</>,
    's_system.review_script_detail_2',
    <>提供与教师间的对话脚本</>,
    <>提供與任課教師的對話腳本</>,
    <>チューターとの会話をスクリプトで提供</>,
    <>Văn bản hội thoại của buổi học</>,
  ],
  review_script_detail_3: [
    <>수업 노트 다시 보기 가능</>,
    <>Correction notes</>,
    's_system.review_script_detail_3',
    <>可重新查看课堂笔记</>,
    <>可重新確認課堂筆記</>,
    <>レッスンノートを読み返しできる</>,
    <>Ghi chú sửa lỗi</>,
  ],
  review_feedback: [
    <>다각도 피드백</>,
    <>Tutor Feedback</>,
    's_system.review_feedback',
    <>多角度反馈</>,
    <>全方位意見回饋</>,
    <>多角的フィードバック</>,
    <>Đánh giá từ gia sư</>,
  ],
  review_feedback_detail_1: [
    <>공인 영어 스피킹 점수 예측</>,
    <>Estimated scores for English proficiency exams</>,
    's_system.review_feedback_detail_1',
    <>官方英语口语分数预测</>,
    <>預測英語檢定口說分數</>,
    <>英語試験スピーキング点数予測</>,
    <>Ước tính điểm trong các bài đánh giá năng lực tiếng Anh</>,
  ],
  review_feedback_detail_2: [
    <>어휘, 문법, 발음, 유창성 등 영역별 진단</>,
    <>Thorough evaluations on pronunciations, grammar, vocabulary and fluency</>,
    's_system.review_feedback_detail_2',
    <>对词汇, 语法, 发音及流畅性等各方面进行分别评价</>,
    <>診斷詞彙、文法、發音及流暢性等各領域</>,
    <>語彙、文法、発音、流暢性など分野別診断</>,
    <>Đánh giá chi tiết về phát âm, ngữ pháp, từ vựng và mức độ trôi chảy</>,
  ],
  review_ai: [
    <>AI 분석</>,
    <>AI Analysis</>,
    's_system.review_ai',
    <>AI分析</>,
    <>AI分析</>,
    <>AI analysis</>,
    <>Phân tích bằng AI</>,
  ],
  review_ai_detail_1: [
    <>Filler Word, 자주 쓰는 단어 등 영어 습관 분석</>,
    <>Review speech pace, vocab range and filler words</>,
    's_system.review_ai_detail_1',
    <>对Filler Word, 常用词汇等英语习惯进行分析</>,
    <>分析Filler Word、常用單字等英語習慣</>,
    <>つなぎ言葉(Filler Word)、よく使う単語等から自分の傾向分析</>,
    <>Đánh giá tốc độ nói, vốn từ và các fillers words</>,
  ],
  review_ai_detail_2: [
    <>말하기 속도 및 발화량 분석</>,
    <>Measure your performance against other Ringlers</>,
    's_system.review_ai_detail_2',
    <>对口语速度及说话量进行分析</>,
    <>分析口說速度及說話量</>,
    <>話すスピードおよび発話量分析</>,
    <>Đánh giá khả năng của bạn so với các học viên Ringle khác</>,
  ],
  all_this_mobile: [
    <>
      이 모든 것을 <br />
      앱에서 제약없이 <br />
      <br />
      PC - 앱 연동으로 모든 기능을
      <br />
      자유롭게 이동하며 이용할 수 있습니다
    </>,
    <>Access all of Ringle on your phone</>,
    's_system.all_this_mobile',
    <>
      这一切均
      <br />
      可在应用程序中无限制使用
      <br />
      <br />
      通过PC - APP联动
      <br />
      可自由移动, 便捷使用全部功能
    </>,
    <>
      這一切
      <br />
      均可在APP中無限使用
      <br />
      <br />
      透過綁定電腦及APP
      <br />
      可自由移動並使用所有功能
    </>,
    <>
      PCとアプリ併用で全機能を
      <br />
      ご自由にご利用いただけます。
    </>,
    <>Truy cập tất cả tính năng của Ringle trên điện thoại</>,
  ],
  app_down: [
    <>앱 다운로드</>,
    <>Download App</>,
    's_system.app_down',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  ringle_platform: [
    `Ringle's Approach`,
    `Ringle's Approach`,
    's_system.ringle_platform',
    `Ringle's Approach`,
    `Ringle's Approach`,
    `Ringle's Approach`,
    `Cách tiếp cận của Ringle`,
  ],
}
export const s_correction = {
  beyond_the_wall: [
    <>
      언어의 장벽을 넘어
      <br />더 큰 세계로 나아간 링글러 이야기
    </>,
    <>Ringlers break through language barriers and join the bigger world</>,
    's_correction.beyond_the_wall',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  not_sentence: [
    <>
      문장이 아닌
      <br />
      글을 교정합니다
    </>,
    <>
      We don't just fix sentences
      <br />
      We make you a great writer
    </>,
    's_correction.not_sentence',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  pro_editor: [
    <>
      하버드, 아이비리그 출신 <br />
      분야별 전문 에디터를 바로 연결해드립니다
    </>,
    <></>,
    's_correction.pro_editor',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  best_editor: [
    `최고의 에디터에게 맡기세요`,
    `Have 'your writing reviewed by the best editors`,
    's_correction.best_editor',
    ``,
    ``,
    ``,
    ``,
  ],
  harvard_harsh_edu: [
    <>
      혹독한 글쓰기 교육을 받은 <Br md down />
      하버드, 아이비리그 튜터들의 영문서 교정
      <br />
      단순히 문법에 맞는 문장이 아닌, <Br md down />
      우수하고 뛰어난 글로 교정해 드립니다
    </>,
    <></>,
    's_correction.harvard_harsh_edu',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_categories: [
    `영문서 교정 카테고리`,
    `Professional editing for all purposes`,
    's_correction.correction_categories',
    ``,
    ``,
    ``,
    ``,
  ],
  prepare_abroad: [`유학준비`, `Study Abroad`, 's_correction.prepare_abroad', ``, ``, ``, ``],
  admission_essay: [
    <>
      어드미션 에세이
      <br />
      MBA 에세이
      <br />
      SOP/학업계획서
      <br />
      추천서 등
    </>,
    <>
      Application Essays
      <br />
      Writing Sample
      <br />
      Statement of Purpose
      <br />
      Recommendation Letters, etc.
    </>,
    's_correction.admission_essay',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  career_business: [`커리어/비즈니스`, `Career & Business`, 's_correction.career_business', ``, ``, ``, ``],
  english_resume: [
    <>
      영어 레주메
      <br />
      영어 인터뷰 스크립트
      <br />
      커버레터
      <br />
      비즈니스 이메일
      <br />
      발표 자료 등
    </>,
    <>
      Resume & CV
      <br />
      Cover Letters
      <br />
      Business Emails
      <br />
      Presentation Materials, etc.
    </>,
    's_correction.english_resume',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  study_data: [`학업/학술 자료`, `Academics`, 's_correction.study_data', ``, ``, ``, ``],
  paper_first: [
    <>
      논문 초록
      <br />
      저널 커버레터 <br />
      컨퍼런스 페이퍼
      <br />
      레포트 등
    </>,
    <>
      Abstract
      <br />
      Presentation Scripts
      <br />
      Conference Papers
      <br />
      Research Reports, etc.
    </>,
    's_correction.paper_first',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  program_cost: [
    `프로그램 비용 및 절차 안내`,
    `Guide for pricing and process`,
    's_correction.program_cost',
    ``,
    ``,
    ``,
    ``,
  ],
  cost_info: [
    <>
      기본료: 7,700원 ( ~ 100단어 까지)
      <br />
      추가비용: 단어 당 77원 <Br md down />
      [예: 500단어 기준 38,500원]
      <br />
      소요시간: 영문서 제출 후 36시간 이내
    </>,
    <>
      Basic fee: 7,700 KRW (up to 100 words)
      <br />
      Additional fee: 77 KRW per word
      <br />
      (ex. 500-words essay will be 38,500 KRW)
      <br />
      Estimated time: within 36 hours of submission
    </>,
    's_correction.cost_info',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_and_pay: [
    <>
      교정 요청 & 결제
      <br />
      (자동 견적 제공)
    </>,
    <>Submit document and pay</>,
    's_correction.correction_and_pay',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  connect_editor: [
    <>
      원어민 에디터 연결
      <br />
      (에디터 지정 시 1.3배 과금)
    </>,
    <>Connect with editor</>,
    's_correction.connect_editor',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  get_result: [
    <>
      교정 결과물 빠르게 받기
      <br />
      (36시간 이내)
    </>,
    <>Get your edits in 36 hours</>,
    's_correction.get_result',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  more_correction: [
    <>
      후속 교정 요청 시 진행
      <br />
      (추가 부분결제)
    </>,
    <>Iterate at additional cost</>,
    's_correction.more_correction',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  important_ringle: [
    `중요한 영문서는 링글과 함께하세요!`,
    <>
      Ringle will perfect
      <br />
      your English writings!
    </>,
    's_correction.important_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  more_accurate: [
    <>
      한글을 영문으로 번역하거나 영어를 대필하진 않지만
      <Br md up />
      &nbsp;영문서의 의도와 목적을 이해하고 누구보다 정확히 전달합니다
    </>,
    <>
      We do not translate KOR-ENG or ghostwrite, <br />
      but we will help you express your ideas most effectively
    </>,
    's_correction.more_accurate',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  see_estimation: [`교정 견적 보기`, `See estimated price`, 's_correction.see_estimation', ``, ``, ``, ``],
  for_your_intention: [
    <>목적에 맞는 글로 교정합니다</>,
    <>Edit your piece technically and holistically</>,
    's_correction.for_your_intention',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  correction_mode: [`Correction Mode`, `Correction Mode`, 's_correction.correction_mode', ``, ``, ``, ``],
  paraphrase_mode: [`Paraphrase Mode`, `Paraphrase Mode`, 's_correction.paraphrase_mode', ``, ``, ``, ``],
  correct_grammar: [
    `문법을 교정하고 문맥에 맞는 표현으로 교정`,
    `Correct grammar and edit short phrases`,
    's_correction.correct_grammar',
    ``,
    ``,
    ``,
    ``,
  ],
  more_natural: [
    `문장과 문단을 더 자연스럽게 새로 작성`,
    <>Rewrite sentences and paragraphs</>,
    's_correction.more_natural',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_event = {
  normal_price: [`정가`, `Regular price`, 's_event.normal_price', ``, ``, ``, ``],
}
export const s_landing_review = {
  you_think_same: [
    <>
      영어 실력의 성장, <Br md down />
      그로 인해 찾아온 인생의 변화
    </>,
    ``,
    's_landing_review.you_think_same',
    <>
      因英语实力的增长
      <Br md down />
      而引发的人生改变
    </>,
    <>
      由於英語實力增長，
      <Br md down />
      因此出現人生的變化
    </>,
    <>
      英語力の成長
      <Br md down />
      がもたらした人生の変化
    </>,
    ``,
  ],
  check_out_reviews: [
    <>
      {isMobile ? (
        <>
          수강생들의
          <br />
          솔직한 이야기를
          <br />
          들어보세요
        </>
      ) : (
        <>
          수강생들의 솔직한
          <br />
          이야기를 들어보세요
        </>
      )}
    </>,
    <>
      Join the community
      <br />
      of learners & dreamers
    </>,
    's_landing_review.check_out_reviews',
    <>
      {isMobile ? (
        <>
          请听一下
          <br />
          学生们的
          <br />
          真实想法吧
        </>
      ) : (
        <>
          请听一下
          <br />
          学生们的真实想法吧
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          來聽一聽
          <br />
          學生們的
          <br />
          誠實心得吧！
        </>
      ) : (
        <>
          來聽一聽學生們的
          <br />
          誠實心得吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          受講生たちの
          <br />
          正直な感想を
          <br />
          聴いてみてください。
        </>
      ) : (
        <>
          受講生たちの正直な
          <br />
          感想を聴いてみてください。
        </>
      )}
    </>,
    <>
      Tham gia cùng những người
      <br />
      dám nghĩ và dám làm
    </>,
  ],
  find_out_interested: [
    `관심 있는 대화 주제를 찾아보세요`,
    <>
      <b>
        Look for a conversation topic
        <br />
        of your interest.
      </b>
    </>,
    's_landing_review.find_out_interested',
    `请寻找您感兴趣的会话主题`,
    `請尋找您感興趣的對話主題`,
    `関心のある会話テーマを探してみましょう。`,
    <>
      <b>
        Tìm chủ đề giao tiếp
        <br />
        theo sở thích.
      </b>
    </>,
  ],
  whenever_wherever: [
    `MBA 출신 집필진이 만든 온라인 교재를 언제 어디서나!`,
    `Access online materials made by MBA tutors!`,
    's_landing_review.whenever_wherever',
    `随时随地均可阅览MBA出身的编纂人员全心打造的在线教材!`,
    `隨時隨地觀看MBA出身的執筆團隊打造的線上教材！`,
    `MBA出身ライターが作ったオンライン教材をいつでもどこでも！`,
    `Truy cập tài liệu trực tuyến viết bởi các gia sư MBA!`,
  ],
  companies_using_english: [
    `영어 쓰는 회사`,
    `Global Career`,
    's_landing_review.companies_using_english',
    `使用英语的公司`,
    `使用英語的公司`,
    `英語を使う会社`,
    `Sự nghiệp toàn cầu`,
  ],
  presentation_report_paper: [
    `발표/보고/논문`,
    `Presentation/Report/Paper`,
    's_landing_review.presentation_report_paper',
    `发表/报告/论文`,
    `發表／報告／論文`,
    `発表/報告/論文`,
    `Thuyết trình/Báo cáo/Nghiên cứu`,
  ],
  interview_essay: [
    `인터뷰/에세이`,
    `Interview/Essay`,
    's_landing_review.interview_essay',
    `面试/短文`,
    `面試／申論`,
    `面接/エッセイ`,
    `Phỏng vấn/Bài luận`,
  ],
  study_abroad_immigrant: [
    `유학/해외거주`,
    `Study Abroad/Immigration`,
    's_landing_review.study_abroad_immigrant',
    `留学/海外居住`,
    `留學／海外居住`,
    `留学/海外居住`,
    `Du học/Định cư`,
  ],
  tutors_who_fit_me: [
    `핏이 맞는 튜터`,
    `Tutors who fit me`,
    's_landing_review.tutors_who_fit_me',
    `适合自身的教师`,
    `適合自己的任課教師`,
    `自分に合うチューター`,
    `Gia sư phù hợp với tôi`,
  ],
  free_schedule: [
    `자유로운 스케쥴`,
    `Flexible schedule`,
    's_landing_review.free_schedule',
    `自由随心的时间表`,
    `自由自在的日程`,
    `自由なスケジュール`,
    `Thời khoá biểu linh hoạt`,
  ],
  overcome_fear: [
    `두려움 극복`,
    `Overcome fear`,
    's_landing_review.overcome_fear',
    `克服内心的恐惧`,
    `克服恐懼`,
    `恐怖克服`,
    `Vượt qua nỗi sợ`,
  ],
  business_kdy: [
    `비즈니스 / 김도엽님`,
    `Business / Doyup Kim`,
    's_landing_review.business_kdy',
    `商务人士 / Mr. Kim`,
    `商務人士／Mr. Kim`,
    `ビジネス / キム・ドヨプさん`,
    `Kinh doanh / Doyup Kim`,
  ],
  mba_cdh: [
    `MBA / 최다혜님`,
    `MBA / Dahye Choi`,
    's_landing_review.mba_cdh',
    `MBA / Ms. Choi`,
    `MBA／Ms. Choi`,
    `MBA / チェ・ダヘさん`,
    `MBA / Dahye Choi`,
  ],
  study_abroad_wjh: [
    `해외취업 / 우지혜님`,
    `Applying for Overseas Jobs / Jihye Woo`,
    's_landing_review.study_abroad_wjh',
    `海外就业 / Ms. Woo`,
    `海外就業／Ms. Woo`,
    `海外就職 / ウ・ジヘさん`,
    `Ứng tuyển các công việc ở nước ngoài / Jihye Woo`,
  ],
  interview_jhs: [
    `인터뷰 / 정희선님`,
    `Applying for Overseas Jobs / Jihye Woo`,
    's_landing_review.interview_jhs',
    `面试 / Ms. Chung`,
    `面試／Ms. Chung`,
    `面接 / チョン・ヒソンさん`,
    `Ứng tuyển các công việc ở nước ngoài / Jihye Woo`,
  ],
  correction_lsy: [
    `영문서교정 / 이상윤님`,
    `Editing Service / Sangyoon Lee`,
    's_landing_review.correction_lsy',
    `英文书面文本校正 / Mr. Lee`,
    `糾正英文文件／Mr. Lee`,
    `英文校正 / イ・サンユンさん`,
    `Dịch vụ chỉnh sửa / Sangyoon Lee`,
  ],
  study_aboard_yhs: [
    `유학생 / 양희승님`,
    `International Student / Heeseung Yang`,
    's_landing_review.study_aboard_yhs',
    `留学生 / Mr. Yang`,
    `留學生／Mr. Yang`,
    `留学生 / ヤン・ヒスンさん`,
    `Sinh viên quốc tế / Heeseung Yang`,
  ],
  junior_hsm: [
    `Junior / 홍상민님`,
    `Junior / Sangmin Hong`,
    's_landing_review.junior_hsm',
    `Junior / Mr. Hong`,
    `Junior／Mr. Hong`,
    `Junior / ホン・サンミンさん`,
    `Sinh viên năm 3 / Sangmin Hong`,
  ],
  live_abroad_psy: [
    `해외거주 / 박서영님`,
    `Overseas Resident / Seoyoung Park`,
    's_landing_review.live_abroad_psy',
    `海外居住 / Ms. Park`,
    `海外居住／Ms. Park`,
    `海外居住 / パク・ソヨンさん`,
    `Định cư nước ngoài / Seoyoung Park`,
  ],
  only_one: [
    <>정말 유창한 수준까지 올리기 위해 대체할 수 없는, 지금 한국에서는 유일한 서비스라고 생각해요.</>,
    <>Ringle is an irreplaceable service that helps people to speak English at a native speaker's level.&nbsp;</>,
    's_landing_review.only_one',
    <>为了能够达到英语流畅水平, 我认为这是韩国当前独一无二的英语学习服务平台, 无可替代。</>,
    <>我認為這是目前韓國唯一可以真正提升至流暢程度的英語教學服務。</>,
    <>本当に流暢なレベルまで上げるためには他に代わるもののない、今韓国に唯一のサービスだと思います。</>,
    <>Ringle là dịch vụ không thể thay thế giúp bạn nói tiếng Anh như người bản xứ.&nbsp;</>,
  ],
  hbs_kdy: [
    `HBS class of 2019 / 김도엽님`,
    `HBS Class of 2019/ Doyup Kim`,
    's_landing_review.hbs_kdy',
    `HBS class of 2019 / Mr. Kim`,
    `HBS class of 2019／Mr. Kim`,
    `HBS class of 2019 / キム・ドヨプさん`,
    `Trường Kinh doanh Harvard khoá 2019/ Doyup Kim`,
  ],
  opportunity: [
    <>영어를 잘함으로써 얻을 수 있는 기회는 100배 아니, 1000배쯤 되는 것 같아요.</>,
    <>
      Speaking fluent English opens the door to <b>infinite opportunities.</b>
    </>,
    's_landing_review.opportunity',
    <>学好英语能够获得机会的可能性应该是100倍, 不对, 应该1000倍。</>,
    <>學好英語可以獲得的機會好像是100倍，不對，應該是1000倍。</>,
    <>英語が上手なことで得られる機会は100倍、いや、1000倍くらいになると思います。</>,
    <>
      Giao tiếp tiếng Anh tốt mở ra cảnh cửa đến <b>vô vàn cơ hội.</b>
    </>,
  ],
  hbs_cdh: [
    `HBS class of 2020 / 최다혜님`,
    `HBS Class of 2020/ Dahye Choi`,
    's_landing_review.hbs_cdh',
    `HBS class of 2020 / Ms. Choi`,
    `HBS class of 2020／Ms. Choi`,
    `HBS class of 2020 / チェ・ダヘさん`,
    `Trường Kinh doanh Harvard khoá 2020/ Dahye Choi`,
  ],
  ringle_can_change: [
    <>미국에서도 교정해주지 않던 영어, 링글에서 바꿀 수 있었어요.</>,
    <>
      My Ringle tutor corrected my English <b>like no one else.</b>
    </>,
    's_landing_review.ringle_can_change',
    <>即使在美国也没能得到纠正的英语问题，在Ringle得到了改正。</>,
    <>即使在美國也未能獲得糾正的英語，我在Ringle得到改善。</>,
    <>アメリカでも正してくれなかった英語、Ringleで変えることができました。</>,
    <>
      Gia sư của tôi ở Ringle sửa lỗi tiếng Anh cho tôi <b>theo một cách đặc biệt.</b>
    </>,
  ],
  pratt_wjh: [
    `Pratt Institute 석사과정 / 우지혜님`,
    `M.A Candidate, Pratt Institute/ Jihye Woo`,
    's_landing_review.pratt_wjh',
    `Pratt Institute硕士研究生 / Ms. Woo`,
    `Pratt Institute碩士班／Ms. Woo`,
    `Pratt Institute 修士課程 / ウ・ジヘさん`,
    `Thạc sĩ Khoa học xã hội, Học viện Pratt/ Jihye Woo`,
  ],
  resume_correction: [
    <>레주메 교정부터 영어 인터뷰까지 링글에서 준비하고 목표하는 회사에 입사했어요.</>,
    <>I prepared every step of my job application process with Ringle and got a position in my dream company.</>,
    's_landing_review.resume_correction',
    <>从履历校正到准备英语面试, 通过在Ringle的学习我顺利进入了希望的公司。</>,
    <>從糾正簡歷到英語面試，我在Ringle準備並進入理想的公司。</>,
    <>レジュメ校正から英語面接までRingleで準備し、目標の会社に入社しました。</>,
    <>Tôi chuẩn bị cho việc ứng tuyển từ đầu đến cuối cùng Ringle và đã được nhận vị trí tại công ty mơ ước.</>,
  ],
  japan_jhs: [
    `일본 외국계 10년차 직장인 / 정희선님`,
    `Working in a Japanese multinational company for 10 years/ Heeseon Jung`,
    's_landing_review.japan_jhs',
    `日本外企10年职员 / Ms. Chung`,
    `進入日資公司9年餘的上班族／Ms. Chung`,
    `日本外資系10年目会社員 / チョン・ヒソンさん`,
    `Làm việc trong công ty đa quốc gia của Nhật hơn 10 năm/ Heeseon Jung`,
  ],
  from_school: [
    <>그냥 영어 선생님이 아닌, 제가 목표하는 학교 출신 에디터라서 굉장히 도움을 많이 받았습니다.</>,
    <>
      My tutor was <b>from my dream school</b> and gave me the best advice.
    </>,
    's_landing_review.from_school',
    <>不仅是一名英语教师, 而是出身我梦想学校的编辑, 课程让我受益匪浅。</>,
    <>不僅是英語教師，編輯來自我嚮往的學校，讓我受益匪淺。</>,
    <>ただの英語の先生ではなく、私が目標とする学校出身のエディターなので、とても助けてもらいました。</>,
    <>
      Gia sư của tôi <b>đến từ ngôi trường tôi mơ ước</b> và đưa ra những lời khuyên tuyệt vời.
    </>,
  ],
  hbs_lsy: [
    `HBS class of 2020 / 이상윤님`,
    `HBS Class of 2020/ Sangyoon Lee`,
    's_landing_review.hbs_lsy',
    `HBS class of 2020 / Mr. Lee`,
    `HBS class of 2020／Mr. Lee`,
    `HBS class of 2020 / イ・サンユンさん`,
    `Trường Kinh doanh Harvard khoá 2020/ Sangyoon Lee`,
  ],
  american_view: [
    <>미국인 관점에서도 영어로 말을 잘 하고 글을 잘 쓰는 튜터들이 링글 튜터들이에요.</>,
    <>Ringle tutors are great speakers and writers, even for natives</>,
    's_landing_review.american_view',
    <>Ringle教师的英语会话及写作水平, 在美国人中都是佼佼者。</>,
    <>Ringle任課教師的英語會話及寫作水準在美國人眼中也都很高。</>,
    <>アメリカ人の観点でも英語で話すのが上手で文章力のあるチューターがRingleのチューターです。</>,
    <>Gia sư Ringle là những diễn giả và cây viết xuất sắc, kể cả cho người bản xứ</>,
  ],
  vanderbilt_yhs: [
    `Vanderbilt University 유학생 / 양희승님`,
    `Vanderbilt University/ Heeseung yang`,
    's_landing_review.vanderbilt_yhs',
    `Vanderbilt University留学生 /  Mr. Yang`,
    `Vanderbilt University留學生／Mr. Yang`,
    `Vanderbilt University 留学生 / ヤン・ヒスンさん`,
    `Trường Đại học Vanderbilt/ Heeseung yang`,
  ],
  not_in_korea: [
    <>저희 아들이 하버드 출신 튜터를 만나 대화를 나눈적은 없었죠. 한국에서 이런 기회 잡기는 어렵습니다.</>,
    <>
      Before Ringle, son had never studied with a tutor from Harvard. It's hard to have such opportunity in Korea.&nbsp;
    </>,
    's_landing_review.not_in_korea',
    <>我儿子没有和哈佛出身的教师进行过对话, 在韩国这种机会是很难得的。</>,
    <>我兒子不曾和哈佛出身的任課教師對話，在韓國很難有這種機會。</>,
    <>
      私の息子はハーバード出身のチューターに出会って会話したことがありませんでした。韓国でこんなチャンスを掴むのは難しいです。
    </>,
    <>
      Trước Ringle, con trai tôi chưa bao giờ học cùng một gia sư từ Hardvard. Rất khó để tìm một cơ hội như vậy ở Hàn
      Quốc.&nbsp;
    </>,
  ],
  nextrans_hsm: [
    `Nextrans 대표 / 홍상민님`,
    `CEO, Nextrans/ Sangmin Hong`,
    's_landing_review.nextrans_hsm',
    `Nextrans代表 / Mr. Hong`,
    `Nextrans代表／Mr. Hong`,
    `Nextrans 代表 / ホン・サンミンさん`,
    `CEO, Nextrans/ Sangmin Hong`,
  ],
  mba_intern: [
    <>2년간 180회 이상 수업하면서 MBA 지원부터 미국생활 그리고 인턴십까지 도움 받았어요.</>,
    <>
      I took more than 180 lessons for 2 years, and got help with my MBA applications, life in the US, and internship.
    </>,
    's_landing_review.mba_intern',
    <>两年时间听了180堂课以上, 从帮我准备MBA开始到去美国生活, 经历工作实习等过程都给我提供了很多的帮助。</>,
    <>兩年之間上了180次以上的課程，從我報名MBA、美國生活至工作實習，都得到了許多幫助。</>,
    <>2年間で180回以上レッスンしながらMBAのサポートからアメリカ生活、そしてインターンシップまで助けてもらいました。</>,
    <>
      Tôi đã học hơn 180 buổi học trong 2 năm qua, nhận được sự giúp đỡ cho việc nộp đơn học MBA, cuộc sống ở Mỹ và công
      việc thực tập.
    </>,
  ],
  nyu_psy: [
    `NYU Stern MBA / 박서영님`,
    `NYU Stern MBA/ Seoyoung Park`,
    's_landing_review.nyu_psy',
    `NYU Stern MBA / Ms. Park`,
    `NYU Stern MBA／Ms. Park`,
    `NYU Stern MBA / パク・ソヨンさん`,
    `NYU Stern MBA/ Seoyoung Park`,
  ],
}
export const s_case_professional = {
  who_need_pro_eng: [
    <>
      <Div spanTag textSecondary>
        외국계/해외관련 직무
      </Div>{' '}
      또는
      <br />
      의학/법학/디자인 등
      <br />
      <Div spanTag textSecondary>
        전문 영어
      </Div>
      가 필요하신 분
    </>,
    <>Build an international career</>,
    's_case_professional.who_need_pro_eng',
    <>
      <Div spanTag textSecondary>
        外国企业/海外相关工作
      </Div>
      或是从事于
      <br />
      医学/法学/设计等
      <br />
      <Div spanTag textSecondary>
        需要专业英语水平
      </Div>
      的人员
    </>,
    <>
      <Div spanTag textSecondary>
        外資公司／海外相關職務
      </Div>
      或從事於
      <br />
      醫學／法學／設計等
      <br />
      需要
      <Div spanTag textSecondary>
        專業英語
      </Div>
      的人士
    </>,
    <>
      <Div spanTag textSecondary>
        外資系/海外関連職務
      </Div>{' '}
      または
      <br />
      医学/法学/デザインなど
      <br />
      <Div spanTag textSecondary>
        専門英語
      </Div>
      が必要な方
    </>,
    <>Xây dựng sự nghiệp quốc tế</>,
  ],
  specific_part: [
    <>
      전문적이고 품격 있는 영어를
      <br />
      자신있게 구사하며
      <br />
      글로벌 커리어를 꿈꿉니다
    </>,
    <>Hone your business English and learn sector-specific terminologies</>,
    's_case_professional.specific_part',
    <>
      充满自信掌握
      <br />
      专业且高品质的英语
      <br />
      梦想成为国际化人才
    </>,
    <>
      充滿自信地說出
      <br />
      專業且具有品格的英語，
      <br />
      夢想國際化職涯。
    </>,
    <>
      専門的で品格のある英語を
      <br />
      自信を持って駆使しながら
      <br />
      グローバルキャリアを夢見ています。
    </>,
    <>Rèn giũa kỹ năng tiếng Anh trong công việc và học các từ chuyên ngành</>,
  ],
  pro: [
    `#외국계회사`,
    `#Professional_English`,
    's_case_professional.pro',
    `#外资企业`,
    `#外資公司`,
    `#外資系企業`,
    `#Tiếng_Anh_cho_công_việc`,
  ],
  major: [
    `#전문영어`,
    `#Meetings`,
    's_case_professional.major',
    `#专业英语`,
    `#專業英語`,
    `#専門英語`,
    `#Các_buổi_họp`,
  ],
  conference: [
    `#비즈니스영어`,
    `#Business_English`,
    's_case_professional.conference',
    `#商务英语`,
    `#商務英語`,
    `#ビジネス英語`,
    `#Tiếng_Anh_thương_mại`,
  ],
  presentation: [
    `#글로벌커리어`,
    `#Conferences`,
    's_case_professional.presentation',
    `#海外岗位`,
    `#國際化職涯`,
    `#グローバルキャリア`,
    `#Hội_nghị`,
  ],
  are_there_pros: [
    `일할 때 영어가 필요한데, 링글이 도움이 될까요?`,
    `I use English at work. Will studying with Ringle help?`,
    's_case_professional.are_there_pros',
    `工作时需要使用英语, Ringle能否助您一臂之力?`,
    `工作時需要使用英語，Ringle會有幫助嗎？`,
    `働く時に英語が必要なのですが、Ringleは役立ちますか？`,
    `Tôi sử dụng tiếng Anh trong công việc. Liệu học ở Ringle có giúp ích cho tôi?`,
  ],
  many_pros: [
    <>
      네, 링글 수강생의 약 50%는 외국계, 글로벌 회사에 재직 중이시거나 해외 출장, 영어 회의, 발표, 이메일 작성 등으로
      업무 중 영어 사용이 잦은 직장인이십니다. 뿐만 아니라 LLM을 준비하시는 변호사, MBA 및 해외 클라이언트와의 미팅을
      준비하시는 컨설턴트, 회계사, 영어 강의 및 해외연수, 콘퍼런스를 준비하시는 의사, 교수 등 다양한 전문 분야
      종사자들께서 링글을 사용하고 계십니다.
      <br />
      <br />
      영어로 일하는 직장인 분들은 링글 수강 후 "정말 내가 필요한 말을 영어로 명확하게 전달하는 능력을 키울 수 있어
      좋았다.", "영어가 항상 마음의 짐처럼 느껴졌는데, 이제는 자신 있게 내 역량을 영어로 보여줄 수 있게 되었다."라고
      말씀하십니다.
    </>,
    <>
      Yes! About half of Ringlers are working professionals who often need to communicate in English.
      <br />
      <br />
      Lawyers use Ringle to prepare for LLM, consultants to prepare for meetings with clients, professors to conduct
      courses in English, and doctors to prepare for overseas training programs or conferences.
      <br />
      <br />
      The list goes on: our students are engineers, investors, accountants, designers, and more, whose job entails
      English communications or who are looking to make a transition into a more global role.
    </>,
    's_case_professional.many_pros',
    <>
      当然, Ringle中有超过50%的学员就职于外资企业, 跨国公司, 当然也包含那些经常使用英语进行会话, 发表,
      邮件书写等业务的职场人士。同时学员中也不乏一些正在准备LLM的律师, MBA或预备和海外客户进行会议的顾问人员, 会计师,
      准备英语授课, 海外研修以及需要进行电话会议的医生, 教授等多领域的从业人员。
      <br />
      <br />
      使用英语工作的职场人士们在体验过Ringle的课程后, 经常能够听到他们发出‘能够提升我的英语会话实力,
      用英语准确表达自身想法的感觉真是太好了’, ‘英语一直是我的一种心理负担,
      现在终于具备使用英语表达自我的能力了’等这样的感叹。
    </>,
    <>
      是的，Ringle超過50%的學生於外資公司、跨國企業工作，或到國外出差、進行英語會議、發表、撰寫電子郵件等業務上經常使用英語的上班族。不僅如此，學生中也不乏一些準備LLM的律師、準備MBA或與海外客戶開會的顧問或會計師、準備英語課程、海外研修或研討會的醫生或教授等各界領域人士。
      <br />
      <br />
      使用英語工作的上班族在上過Ringle課程後表示：「能夠提升使用英語明確表達我需要說的話的能力，真的很棒。」、「英語在我心中一直是一種心理負擔，現在終於可以充滿自信地使用英語展現我的能力。」
    </>,
    <>
      はい。Ringle受講生の約50%は外資系やグローバル企業に在職中であったり、海外出張や英語会議、発表、メール作成など、仕事中に英語の使用が多い会社員です。それだけでなく、LLMを準備中の弁護士、MBAおよび海外クライアントとのミーティングを準備するコンサルタント、会計士、英語講義および海外研修、カンファレンスを準備している医師や教授など、多様な専門分野の従事者がRingleを使用されています。
      <br />
      <br />
      英語で働く会社員はRingle受講後「本当に自分が必要な言葉を英語で明確に伝える能力を育てることができてよかった」「英語がいつも心の重荷だったが、今では自信を持って自分の力を英語で発揮することができるようになった」とおっしゃっています。
    </>,
    <>
      Có chứ! Gần một nửa học viên Ringle đang đi làm và thường xuyên sử dụng tiếng Anh trong giao tiếp.
      <br />
      <br />
      Luật sư học Ringle để chuẩn bị cho Chương trình Thạc sĩ Luật, các tư vấn viên chuẩn bị cho buổi họp với khách
      hàng, giáo sư chuẩn bị cho các lớp học giảng dạy bằng tiếng Anh, và các bác sĩ chuẩn bị cho các chương trình tập
      huấn và hội nghị ở nước ngoài.
      <br />
      <br />
      Và còn rất nhiều trường hợp khác: học sinh của chúng tôi là kỹ sư, nhà đầu tư, kế toán, designer và những ngành
      nghề khác sử dụng tiếng Anh trong giao tiếp hoặc những ai đang tìm kiếm cơ hội cho một môi trường quốc tế.
    </>,
  ],
  question: [
    <>어떻게 링글로 직장 및 전문 분야 영어 실력을 향상시키나요?</>,
    `Here’s how Ringle is the best solution for global professionals. At Ringle, you can:`,
    's_case_professional.question',
    <>如何通过Ringle提升职场及专业领域的英语水平呢?</>,
    <>如何透過Ringle提升職場及專業領域的英語實力呢？</>,
    <>どうやってRingleで仕事および専門分野の英語力を向上させるんですか？</>,
    `Ringle cung cấp giải pháp tốt nhất cho người đi làm. Tại Ringle, bạn có thể:`,
  ],
  bullet_1: [
    <>
      실시간 교정 화면을 통해 제공되는 링글의 ‘눈에 보이는 교정’은 단순 귀로만 들리는 피드백 대비 기억에 남는 교정을
      제공합니다. 링글의 체계적인 수업 시스템으로 영어 실력을 빠르게 성장시키세요. 수업 후 제공되는 녹음 파일, 스크립트,
      AI 분석, 튜터 피드백으로 영어 실력을 점검하고 언제든 복습할 수 있습니다.
    </>,
    `Select a tutor who share your areas of study. Medical doctors can select tutors with a biology major, lawyers can select tutors with a law or social science major, and consultants can select tutors a business or economics major`,
    's_case_professional.bullet_1',
    <>
      通过实时纠正画面, 打造Ringle'可直观地纠正’这一方式, 比单纯只依靠听力进行的纠错方式, 能够给学员留下更深的印象,
      同时Ringle采用系统化的教学体系, 有助于学员快速提升英语水平。课程结束后所提供的录音文件, 脚本,
      AI分析及教师反馈等内容, 均可让学员对自身英语水平进行准确判断, 同时有助于随时进行复习。
    </>,
    <>
      Ringle透過即時糾正畫面提供「看得到的糾正」，比起單純只依靠聽力的糾正更能留下深刻的印象。同時，Ringle採用系統化的教學系統，有助於學生快速提升英語實力。利用課程結束後提供的錄音檔、腳本、AI分析、任課教師意見回饋，可檢驗自己的英語實力並隨時複習。
    </>,
    <>
      リアルタイム校正画面で提供されるRingleの「目に見える校正」は、単に耳で聞くフィードバックに比べて記憶に残る校正を提供します。Ringleの体系的なレッスンシステムで英語力を早く成長させましょう。レッスン後に提供される録音ファイル、スクリプト、AI
      analysis、チューターからのフィードバックで英語力をチェックして、いつでも復習することができます。
    </>,
    `Chọn gia sư học cùng lĩnh vực với bạn. Bác sĩ có thể chọn gia sư chuyên ngành về sinh học, luật sư chọn gia sư liên quan đến luật hoặc các ngành khoa học xã hội, và tư vấn viên chọn gia sư thuộc chuyên ngành kinh doanh hoặc kinh tế`,
  ],
  bullet_2: [
    `매번 똑같은 일상 회화 대신, 비즈니스/전문 분야 관련 주제로 대화하세요. 자유주제 수업으로 대화나 교정을 원하는 자료를 업로드할 수도 있으며, 나의 전문 분야를 이해하는 특정 전공의 튜터와 깊이 있는 대화를 나누고 영어를 교정받을 수도 있습니다.`,
    `Find lesson materials that can guide an in-depth discussion the latest current events in your sector`,
    's_case_professional.bullet_2',
    `相比于不断重复的日常对话，选择商务/专业领域的主题进行英语对话吧。通过自由主题课程, 可上传自己希望进行的对话及纠正材料, 同时和了解自身专业领域的特定教师进行深入对话, 能够更加有效的对英语问题进行修正及理解。`,
    `選擇商務／專業領域的相關主題對話，取代每次一樣的日常對話吧！也可透過自由主題課程，上傳自己希望對話或糾正的資料。也能和了解您專業領域的特定主修任課教師進行深度對話，糾正您的英語。`,
    `毎回同じ日常会話の代わりに、ビジネス/専門分野に関するテーマで会話をしましょう。自由テーマレッスンでは、会話や校正してもらいたい資料をアップロードすることができ、自分の専門分野を理解する特定専攻のチューターと深みのある会話をして、英語の校正を受けることもできます。`,
    `Tìm kiếm những tài liệu có thể giúp bạn thảo luận sâu về tin tức thời sự trong ngành của bạn`,
  ],
  bullet_3: [
    `바쁜 일정 틈틈이, 주말, 주중 새벽/늦은 시간을 활용해보세요. 링글 수업은 연중무휴로 주말 포함 새벽 5시~오후 2시, 오후 7시~새벽 3시 수업까지 자유롭게 예약 가능합니다.`,
    `Take lessons around your busy schedule, be it over the weekend, early morning, or late night`,
    's_case_professional.bullet_3',
    `灵活应用繁忙日常的零散时间, 周末及工作日的清晨/夜间吧。Ringle课程全年无休, 从清晨5点~下午2点, 晚7点~凌晨3点, 均可自由预约课程。`,
    `活用繁忙日常的零散時間、週末、平日凌晨／深夜吧！Ringle課程全年無休，週末也不例外，您可自由預約從凌晨5點～下午2點、晚上7點～凌晨3點的課程。`,
    `忙しいスケジュールの合間に、週末、平日の早朝/遅い時間を活用してみましょう。Ringleのレッスンは年中無休で、週末を含め午前5時〜午後2時、午後7時〜午前3時のレッスンまで自由に予約可能です。.`,
    `Lựa chọn buổi học vào thời gian rảnh, có thể là vào cuối tuần, sáng sớm hoặc khuya`,
  ],
  quote: [
    <>
      "제 꿈은 제약 없이 경쟁할 수 있게 되는 것입니다. 내가 가진 역량이 영어 때문에 표현되지 못하는 일이 없도록 하는
      것이 영어를 공부하는 가장 큰 이유입니다. 정말 유창한 수준까지 올리기 위해서는 대체될 수 없는, 한국에서는 유일한
      서비스라고 생각해요."
    </>,
    <>
      "I wanted to compete in the global job market without barriers. I wanted to make sure that my competence isn't
      capped by my lack of English fluency... Ringle was the only service that could help me get there."
    </>,
    's_case_professional.quote',
    <>
      "我的梦想就是没有短板地进行竞争。学习英语最大的理由就是可以让我尽情用英语表达我希望表达的一切。我认为这里是能够让英语达到流畅水平,
      无可替代的服务平台。"
    </>,
    <>
      「我的夢想就是可以不受限制地競爭。學習英語最大的理由就是為了希望可以利用我的英語實力表達我想表達的一切。我認為這是目前韓國唯一可以真正提升至流暢程度的英語教學服務。」
    </>,
    <>
      "私の夢は、制約されずに競争できるようになることです。自分の力が英語のせいで表現できないことのないようにすることが、英語を勉強する最大の理由です。本当に流暢なレベルまで上げるためには他に代わるもののない、韓国では唯一のサービスだと思います。"
    </>,
    <>
      "Tôi muốn cạnh tranh trong thị trường làm việc toàn cầu mà không gặp bất kì rào cản nào. Tôi muốn chắc chắn rằng
      năng lực của mình không bị giới hạn bởi khả năng tiếng Anh... Ringle là dịch vụ duy nhất giúp tôi đạt được điều
      này."
    </>,
  ],
  quote_writer: [
    `- HBS class of 2019 / 김도엽님`,
    `- Dylan, HBS Class of 2019`,
    's_case_professional.quote_writer',
    `- HBS class of 2019 / Mr. Kim`,
    `- HBS class of 2019／Mr. Kim`,
    `- HBS class of 2019 / キム・ドヨプさん`,
    `- Dylan, Trường Kinh doanh Harvard Khoá 2019`,
  ],
}
export const s_case_interview = {
  mba_abroad: [
    <>
      MBA/유학
      <br />
      해외 취업/외국계 기업
      <br />
      <Div spanTag textSecondary>
        영어 인터뷰
      </Div>{' '}
      준비
    </>,
    <>Nail your interview and get into your dream job or school</>,
    's_case_interview.mba_abroad',
    <>
      MBA/留学
      <br />
      海外就业/外资企业
      <br />
      <Div spanTag textSecondary>
        英语面试
      </Div>
      准备
    </>,
    <>
      MBA／留學
      <br />
      海外就業／外資公司
      <br />
      <Div spanTag textSecondary>
        英語面試
      </Div>
      準備
    </>,
    <>
      MBA/留学
      <br />
      海外就職/外資系企業
      <br />
      <Div spanTag textSecondary>
        英語面接
      </Div>{' '}
      準備
    </>,
    <>Vượt qua buổi phỏng vấn công việc hoặc vào ngôi trường mơ ước</>,
  ],
  like_real: [
    <>
      실전과 유사한 환경에서
      <br />
      경력 있는 원어민의 피드백을 통해
      <br />
      성공적인 인터뷰를 준비합니다
    </>,
    <>Speak to a tutor who has been at your place</>,
    's_case_interview.like_real',
    <>
      仿佛置身实战现场
      <br />
      通过经验丰富的母语教师的反馈
      <br />
      准备一场成功的面试吧
    </>,
    <>
      在接近實戰的環境中，
      <br />
      透過具有經歷的母語人士的意見回饋，
      <br />
      準備成功的面試吧！
    </>,
    <>
      実際と似た環境で
      <br />
      経歴のあるネイティブのフィードバックを通じて
      <br />
      面接が成功するように準備します。
    </>,
    <>Trò chuyện cùng gia sư đã ở vị trí giống như bạn</>,
  ],
  interview: [
    `#영어인터뷰`,
    `#MBA_Interview`,
    's_case_interview.interview',
    `#英语面试`,
    `#英語面試`,
    `#英語面接`,
    `#Phỏng_vấn_MBA`,
  ],
  MBA: [`#MBA`, `#Job_Interview`, 's_case_interview.MBA', `#MBA`, `#MBA`, `#MBA`, `#Phỏng_vấn_công_việc`],
  move_job: [
    `#외국계기업`,
    `#Case_Interview`,
    's_case_interview.move_job',
    `#外资企业`,
    `#外資公司`,
    `#外資系企業`,
    `#Phỏng_vấn_khác`,
  ],
  job_abroad: [
    `#해외취업`,
    `#Tell_your_story`,
    's_case_interview.job_abroad',
    `#海外就业`,
    `#海外就業`,
    `#海外就職`,
    `#Chia_sẻ_câu_chuyện`,
  ],
  can_ringle_interview: [
    `영어 인터뷰를 링글 화상영어로 준비할 수 있나요?`,
    `Can I prepare for an English interview with Ringle?`,
    's_case_interview.can_ringle_interview',
    `英语面试可通过Ringle线上英语进行准备吗?`,
    `可透過Ringle的視訊英語準備英語面試嗎？`,
    `英語面接をRingleオンライン英会話で準備することはできますか？`,
    `Tôi có thể chuẩn bị cho buổi phỏng vấn tiếng Anh cùng Ringle không?`,
  ],
  many_interview: [
    <>
      네, 링글 수강생의 10-15%는 유학, 케이스 인터뷰, 해외 취업 및 이직을 위한 영어 인터뷰 준비 등에 링글을 활용하고
      계십니다.
      <br />
      <br />
      영어 인터뷰는 결국 면접관과 대화하며 주어진 시간 안에 '나'라는 사람을 드러내는 과정입니다. 링글을 통해 인터뷰를
      준비하셨던 분들은, "말하고자 하는 바를 간결하고 격식 있는 영어로 표현하는 능력을 키우는 데에 있어 큰 도움이
      되었다."라고 말씀하셨습니다.
    </>,
    <>
      Sure thing! About 10-15% of our customers prepare for interviews with us, whether it is an interview for school
      admission, case interview for consultant positions, online interviews for getting an overseas job, and even
      negotiations for a promotion.
      <br />
      <br />
      Our tutors have been there too - on a hot seat under a lot of pressure to nail every question thrown at them.
      <br />
      <br />
      Since interviews can carry a lot of weight in shaping your near future, they deserve an intensive preparation and
      practice.
    </>,
    's_case_interview.many_interview',
    <>
      当然, Ringle有10-15%的学员正在准备留学, 案例面试以及为海外应聘或跳槽准备面试。
      <br />
      <br />
      英语面试究其根本是和面试官间的一场对话,
      是在规定的时间内展现'自我'的过程。通过Ringle准备面试的学员经常会说‘学习对于培养简洁有格调的英语表达能力具有很大的帮助’。
    </>,
    <>
      當然，Ringle有10～15%是正在為了留學、個案面試、海外就業或換工作而準備英語面試等的學生。
      <br />
      <br />
      英語面試終究是和面試官對話，在規定時間內展現「自我」的一個過程。曾透過Ringle準備面試的學生表示：「在培養簡潔且具有格調的英語表達能力時有很大的幫助。」
    </>,
    <>
      はい。Ringle受講生の10-15%は留学、ケース面接、海外就職および転職のための英語面接の準備などにRingleを活用していらっしゃいます。
      <br />
      <br />
      英語面接は、つまり面接官と会話しながら与えられた時間内に「自分」という人を表す過程です。Ringleを通じて面接を準備した方々は「言いたいことを簡潔にしてフォーマルな英語で表現する能力を育てるのに大いに役立った」とおっしゃっています。
    </>,
    <>
      Chắn chắn rồi! Khoảng 10-15% học viên chuẩn bị buổi phỏng vấn cùng chúng tôi, đó có thể là buổi phỏng vấn với
      trường đại học, buổi phỏng vấn với vai trò tư vấn viên, phỏng vấn online cho các công việc ở nước ngoài và kể cả
      buổi đàm phán liên quan đến việc thăng chức.
      <br />
      <br />
      Gia sư của chúng tôi đã từng trong tình huống như vậy - vị trí với áp lực cao khi rất nhiều câu hỏi đặt ra cho họ.
      <br />
      <br />
      Buổi phỏng vấn rất quan trọng vì nó sẽ định hình tương lai gần của bạn, chính vì vậy nó đòi hỏi cho sự chuẩn bị và
      luyện tập kỹ càng.
    </>,
  ],
  question: [
    <>어떻게 링글을 활용해 영어 면접을 대비하나요?</>,
    `Here’s how you can leverage Ringle to make your next interview as successful as possible:`,
    's_case_interview.question',
    <>如何运用Ringle来准备英语面试呢?</>,
    <>如何運用Ringle來準備英語面試呢？</>,
    <>どうやってRingleを活用して英語面接に備えるんですか？</>,
    `Dưới đây là cách bạn tận dụng Ringle để chuẩn bị cho một buổi phỏng vấn thành công:`,
  ],
  bullet_1: [
    `레주메와 CV, 인터뷰 예상 질문 등을 업로드하고 링글 튜터와 실전처럼 영어 인터뷰를 진행해보세요. 말하기 연습은 물론, 튜터의 실시간 교정으로 원어민 관점에서 나의 답변이 어떻게 들리는지 피드백 받고, 내 답변을 한층 더 고급스러운 표현으로 업그레이드할 수 있습니다.`,
    `Upload your resume or CV as well as a description of the position you’re interviewing for and request a mock interview`,
    's_case_interview.bullet_1',
    `上传履历, CV及面试预设问题, 和Ringle的教师们进行实战般的英语面试练习。不光是单纯的口语练习, 而是通过教师们的实时纠正, 以母语使用者的角度审视我的回答并提出建设性建议, 帮助本人对答案进行完善, 实现高水品的英语表达。`,
    `請上傳簡歷、CV及面試預計提問等，和Ringle的任課教師們進行如實戰ㄧ般的英語面試。不僅口說練習，透過教師的即時糾正，以母語人士的觀點聽取我的回覆並獲得意見回饋，可將我的回覆升級為更高級的用法。`,
    `レジュメやCV、面接予想質問などをアップロードし、Ringleのチューターと本番のように英語で面接を行ってみてください。スピーキングの練習はもちろん、チューターのリアルタイム校正によりネイティブの観点からあなたの回答がどのように聞こえるのかフィードバックしてもらい、あなたの回答をさらに高級な表現にアップグレードすることができます。`,
    `Tải lên resume hoặc CV và thông tin mô tả vị trí bạn ứng tuyển. Sau đó yêu cầu buổi phỏng thử`,
  ],
  bullet_2: [
    `튜터에게 예상 질문에 대한 답변 스크립트를 실시간으로 첨삭 받거나, 링글 튜터라면 해당 질문에 어떻게 답변할지를 참고해 스크립트를 작성해보세요.`,
    `You can bring your own set of expected questions or request a tutor with a relevant experience to ask questions instead`,
    's_case_interview.bullet_2',
    `实时从教师处获得预设问题回答的修改意见, 或对Ringle教师给出的问题标准答案进行借鉴参考。`,
    `請讓任課教師針對預計提問的回覆腳本即時增刪，或參考若是Ringle任課教師會如何回覆該提問並撰寫腳本。`,
    `チューターに予想質問の回答スクリプトをリアルタイムで添削してもらったり、Ringleのチューターなら質問にどう答えるのかを参考にスクリプトを作成してみましょう。`,
    `Bạn có thể chuẩn bị những câu hỏi dự kiến hoặc yêu cầu một gia sư với kinh nghiệm tương đương để đặt câu hỏi cho bạn`,
  ],
  bullet_3: [
    <>
      실시간 화상영어 수업으로 내 생각을 영어로 명확하고 간결하게 표현하는 연습을 해보세요. 링글 튜터는 여러분이
      강조하고 싶은 포인트를 격식 있는 영어로 표현할 수 있도록 도울 거예요. 수업이 끝난 뒤에도 녹음 파일, 스크립트, 수업
      노트 등으로 언제든 복습할 수 있습니다.
    </>,
    `Ringle tutors will not only help you deliver your ideas clearly and effectively, but comment on how to craft a persona and tell a story unique to you`,
    's_case_interview.bullet_3',
    <>
      通过实时线上英语课程, 练习使用英语对自身想法进行明确及简洁的表达吧。Ringle教师帮助您运用高水平的英语,
      重点表达自身希望的部分。同时课程结束后还可随时对录音文件, 脚本及课堂笔记等内容进行确认及复习。
    </>,
    <>
      透過即時視訊英語課程，練習使用英語明確且簡潔地表達自身想法吧！Ringle任課教師將會幫助您運用具有格調的英語，表達您想強調的部分。您也能在課程結束後，隨時利用錄音檔、腳本及課堂筆記等內容進行複習。
    </>,
    <>
      リアルタイムオンライン英会話レッスンであなたの考えを英語で明確かつ簡潔に表現する練習をしてみましょう。Ringleのチューターは、皆さんが強調したいポイントをフォーマルな英語で表現できるようにサポートします。レッスンが終わった後も録音ファイルやスクリプト、レッスンノートなどでいつでも復習することができます。
    </>,
    `Gia sư Ringle không chỉ giúp bạn truyền đạt thông điệp của mình rõ ràng và hiệu quả, mà còn đưa ra nhận xét giúp bạn xây dựng một câu chuyện đặc biệt cho riêng mình`,
  ],
  quote: [
    <>
      "링글을 수강하는 2년 동안 약 180회 정도의 수업을 들었습니다. MBA를 준비하던 당시에는 에세이 교정과 인터뷰 준비를
      목적으로, 또 여름 인턴십 인터뷰 준비를 위해 링글을 사용했습니다. 그 덕분에 이렇게 MBA도 오고, 여름 인턴십도
      성공적으로 구해 열심히 미국 생활을 하고 있습니다."
    </>,
    <>
      "I've taken about 180 lessons on Ringle over two years. I used Ringle to prepare for MBA admission interviews and
      application essays, then again to prepare for interviews to land a summer internship. And so here I am, at an MBA
      with a summer internship!"
    </>,
    's_case_interview.quote',
    <>
      "在Ringle上课大约有两年的时间, 听了不下180堂的课程。当时在准备MBA为了校正短文,
      准备面试及当时夏季的一次实习开始使用Ringle。当然也得益于此, 我的MBA课程顺利走到今天, 也成功争取到了那次实习机会,
      当前正在美国努力生活中。"
    </>,
    `「在Ringle上課的2年期間，我聽了180次左右的課程。準備MBA的當時，我是為了糾正申論和準備面試，並在準備夏季實習的面試使用了Ringle。多虧如此，我才能來唸MBA，並成功取得夏季實習的機會，目前努力在美國生活中。」`,
    <>
      "Ringleを受講している2年間で180回ほどレッスンを受けました。MBAに向けて準備していた当時はエッセイ校正と面接準備を目的に、また夏のインターンシップの面接準備のためにRingleを使いました。そのおかげでこうしてMBAを取得しに来て夏のインターンシップにも参加し、頑張ってアメリカ生活をしています。"
    </>,
    <>
      "Tôi đã học 180 buổi với Ringle trong hai năm. Tôi dùng Ringle để chuẩn bị cho buổi phỏng vấn MBA và bài luận, và
      sau đó lại chuẩn bị cho buổi phỏng vấn vị trí thực tập hè. Và giờ thì tôi đang học MBA cùng vị trí thực tập đó!"
    </>,
  ],
  quote_writer: [
    `-NYU Stern MBA / 박서영 님`,
    `- Seoyeong, NYU Stern`,
    's_case_interview.quote_writer',
    `-NYU Stern MBA /  Ms. Park`,
    `-NYU Stern MBA／Ms. Park`,
    `-NYU Stern MBA / パク・ソヨンさん`,
    `- Seoyeong, NYU Stern`,
  ],
}
export const s_case_business = {
  for_business: [
    <>
      영어 이메일/보고서 작성
      <br />
      영어 발표, 회의 등
      <br />
      <Div spanTag textSecondary>
        비즈니스 영어
      </Div>{' '}
      대비
    </>,
    <>Communicate fluently in a corporate environment</>,
    's_case_business.for_business',
    <>
      编写英文邮件/报告书
      <br />
      英语发表及会议等
      <br />
      准备
      <Div spanTag textSecondary>
        商务英语
      </Div>
    </>,
    <>
      撰寫英文電子郵件／報告
      <br />
      準備英語發表、會議等
      <br />
      <Div spanTag textSecondary>
        商務英語
      </Div>
    </>,
    <>
      英語メール/報告書作成
      <br />
      英語発表、会議など
      <br />
      <Div spanTag textSecondary>
        ビジネス英語
      </Div>{' '}
      対策
    </>,
    <>Giao tiếp thành thạo trong môi trường chuyên nghiệp</>,
  ],
  not_everyday_english: [
    <>
      일상 회화가 아닌
      <br />
      비즈니스 상황에서 쓰이는
      <br />
      격식 있는 영어를 배웁니다
    </>,
    <>Express your thoughts intelligently everywhere you go, from formal presentations to water cooler chats</>,
    's_case_business.not_everyday_english',
    <>
      并非单纯的日常对话
      <br />
      而是学习可运用于商务场合
      <br />
      且具有深度的英语表达方式
    </>,
    <>
      並非學習日常對話，
      <br />
      而是學習可運用於商務場合
      <br />
      並具有格調的英語
    </>,
    <>
      日常会話ではなく
      <br />
      ビジネスの現場で使われる
      <br />
      フォーマルな英語を学びます。
    </>,
    <>Thể hiện suy nghĩ bất cứ nơi đâu, từ bài thuyết trình chuyên nghiệp đến buổi trò chuyện cùng đồng nghiệp</>,
  ],
  business_english: [
    `#비즈니스영어`,
    `#Business_English`,
    's_case_business.business_english',
    `#商务英语`,
    `#商務英語`,
    `#ビジネス英語`,
    `#Tiếng_Anh_thương_mại`,
  ],
  english_email: [
    `#영어이메일`,
    `#Email_writing`,
    's_case_business.english_email',
    `#英文邮件`,
    `#英文電子郵件`,
    `#英語メール`,
    `#Viết_Email`,
  ],
  conference_call: [
    `#영어회의`,
    `#Presentations`,
    's_case_business.conference_call',
    `#英语会议`,
    `#英語會議`,
    `#英語会議`,
    `#Thuyết_trình`,
  ],
  english_presentation: [
    `#영어발표`,
    `#Water_cooler_chat`,
    's_case_business.english_presentation',
    `#英语发表`,
    `#英語發表`,
    `#英語発表`,
    `#Trò_chuyện_thân_mật`,
  ],
  can_ringle_business: [
    `비즈니스 영어를 링글 화상영어로 배울 수 있나요?`,
    `Can I learn business English through Ringle?`,
    's_case_business.can_ringle_business',
    `商务英语可以通过Ringle线上英语进行学习吗?`,
    `可透過Ringle視訊英語學習商務英語嗎？`,
    `ビジネス英語をRingleオンライン英会話で学べますか？`,
    `Tôi có thể học tiếng Anh thương mại với Ringle không?`,
  ],
  many_ringle_business: [
    <>
      네, 50% 이상의 링글 수강생은 구글, 아마존, 마이크로소프트, P&G, PwC 등 외국계 회사 재직자 또는 업무 중 영어 사용이
      잦은 직장인이십니다.
      <br />
      <br />
      영어 이메일, 보고서 작성, 발표, 화상 회의 준비 등 업무에서 영어가 필요한 경우 또는 외국인 직장 동료와의 소통을
      위해, 수준 높은 영어를 구사하는 링글 튜터와 비즈니스 영어 실력을 키워보세요. 링글 튜터는 영미권 Top 30 대학
      출신으로 70% 이상이 글로벌 회사 인턴, 재직 경험을 보유하고 있습니다.
    </>,
    <>
      By all means! Over 50% of our customers work at multinational companies including Google, Amazon, Microsoft, P&G,
      and PwC and use Ringle specifically to improve their business English.
      <br />
      <br />
      Business English is not just about nailing your next formal meeting or presentations. Speaking English at a
      corporate environment means you must be ready for all kinds of interactions – from written communications like
      emails to a casual watercooler chats and a networking event.
    </>,
    's_case_business.many_ringle_business',
    <>
      当然, 50%以上的Ringle学员来自谷歌, 亚马逊, 微软,
      P&G及PwC等外资企业的在职职员以及那些在工作中经常使用英语的职场人士。
      <br />
      <br />
      为编写邮件, 报告书, 准备发表及视频会议等工作中需要英文的诸多情况以及能够更好的与外国同事进行沟通,
      借助具备高水平英语能力的Ringle教师提升自身商务英语实力吧。Ringle教师均出身Top30的英美名校,
      其中更是有超过70%教师拥有跨国企业实习或在职的工作经验。
    </>,
    <>
      當然，50%以上的Ringle的學生是Google、亞馬遜、微軟、P&G及PwC等外資公司的員工或工作時經常使用英語的上班族。
      <br />
      <br />
      為了撰寫英文電子郵件或報告書、準備發表或視訊會議等業務上需要英語的情況，或為了能和外國同事溝通，請與使用高水準英語的Ringle任課教師培養商務英語的實力。Ringle任課教師均來自於英美Top
      30名校，其中超過70%擁有在跨國企業實習或在職的經驗。
    </>,
    <>
      はい。50%以上のRingle受講生はGoogle、Amazon、Microsoft、P&G、PwCなど外資系企業の在職者、または仕事中に英語の使用が多い会社員です。
      <br />
      <br />
      英語メールや報告書の作成、発表、Web会議の準備など、業務において英語が必要な場合や職場の外国人同僚とのコミュニケーションのために、レベルの高い英語を駆使するRingleのチューターとビジネス英語力を伸ばしましょう。Ringleのチューターは英米圏Top30大学出身で、70%以上がグローバル企業のインターン、在職経験があります。
    </>,
    <>
      Chắc chắn rồi! Hơn 50% học viên của chúng tôi làm việc ở các tập đoàn đa quốc gia như Google, Amazon, Microsoft,
      P&G và PwC. Tất cả mọi người đến với Ringle để cải thiện kỹ năng tiếng Anh trong công việc.
      <br />
      <br />
      Tiếng Anh thương mại không chỉ đơn giản là vượt qua buổi họp hay thuyết trình quan trọng. Giao tiếp tiếng Anh
      trong doanh nghiệp đòi hỏi bạn sẵn sàng trong mọi trường hợp - từ kỹ năng viết như soạn email đến các cuộc trò
      chuyện thân mật hay tham dự các sự kiện networking.
    </>,
  ],
  question: [
    <>링글 화상영어로 어떻게 비즈니스 영어 실력을 키울 수 있나요?</>,
    `Ringle can help you prepare for all uses of Business English:`,
    's_case_business.question',
    <>如何运用Ringle线上英语提升自身的商务英语水平?</>,
    <>如何運用Ringle視訊英語提升商務英語的實力？</>,
    <>Ringleオンライン英会話でどうやってビジネス英語力を伸ばすことができますか？</>,
    `Ringle có thể giúp bạn chuẩn bị cho mọi trường hợp sử dụng tiếng Anh trong công việc:`,
  ],
  bullet_1: [
    `링글 튜터들은 여러분의 답변의 핵심은 유지한 채, 원어민 관점에서 어색한 표현, 뉘앙스 등을 고려해 실제 비즈니스 상황에서 쓰이는 표현으로 교정합니다. 내가 말한 영어가 실시간으로 교정되는 모습을 눈으로 확인하며 영어 실력을 한 단계 더 업그레이드 해보세요.`,
    `Taking Ringle lessons consistently improves your overall English fluency, so that you can be prepared for even the most unexpected occasion that requires English communication. For the best results, we recommend taking 6 40-minute lessons or 12 20-minute lessons a month for at least 6 months`,
    's_case_business.bullet_1',
    `Ringle教师将抓住学员回答中的核心内容, 从母语者的角度, 将表达中出现的生涩部分及语调问题, 用正宗商务表达形式进行替换。让您能够实时直观确认自身英语会话中的问题, 有助于您的英语水平进一步提升。`,
    `Ringle任課教師會維持回覆的核心內容，以母語人士的觀點，考量不自然的用法、語感等，糾正為實際商務情況中會使用的用法。親眼確認您所說的英語即時得到糾正，將您的英語實力更升一級吧！`,
    `Ringleのチューターは皆さんの回答の核心は維持したまま、ネイティブの観点から不自然な表現やニュアンスなどを考慮し、実際のビジネス現場で使われる表現に校正します。あなたが話した英語がリアルタイムで校正される様子を目で確認しながら、英語力をもう一段階アップグレードしましょう。`,
    `Tham gia đều đặn các buổi học cùng Ringle giúp cải thiện việc sử dụng tiếng Anh một cách trôi chảy, từ đó bạn sẵn sàng cho tất cả các tình huống bất ngờ yêu cầu sử dụng tiếng Anh. Để có kết quả tốt nhất, chúng tôi khuyến khích bạn học 6 buổi 40 phút hoặc 12 buổi 20 phút mỗi tháng, và liên tục như vậy 6 tháng`,
  ],
  bullet_2: [
    `자유주제 수업을 통해 영어 이메일, 보고서, 발표 스크립트 등 원하는 자료를 업로드하고, 튜터의 실시간 첨삭을 받아보세요. 업로드한 자료를 직접 교정받으며 비즈니스 상황에 맞는 영어를 익힐 수 있습니다.`,
    `Ringle tutors understand that business communications, all else equal, prefer succinct and concise sentences and will provide feedback accordingly`,
    's_case_business.bullet_2',
    `通过自由主题课程, 上传希望校正的英文邮件, 报告书及发表脚本, 于课程中接受教师的实时纠正。通过此种对自身资料直接修改的形式, 逐渐掌握商务英语的精髓所在。`,
    `透過自由主題課程，上傳英文電子郵件、報告書、發表腳本等您希望的資料，由任課教師為您即時增刪吧！透過直接糾正上傳的資料，您將能掌握符合商務情況的英語。`,
    `自由テーマレッスンで英語メール、報告書、発表スクリプトなど希望する資料をアップロードし、チューターのリアルタイム校正を受けてみましょう。アップロードした資料を直接校正してもらい、ビジネスの現場に合わせた英語を身につけることができます。`,
    `Gia sư Ringle hiểu rằng, sử dụng tiếng Anh thương mại ở các hình thức đều quan trọng như nhau, nên dùng các mẫu câu ngắn gọn và súc tích. Họ sẽ đưa ra những nhận xét phù hợp cho bạn`,
  ],
  bullet_3: [
    `회의 또는 업무 상황에서는 나의 의견을 간결하고 정확하게 전달하는 것이 중요합니다. 비즈니스 및 전문 분야 관련 교재로 수업하며 실전 업무 환경에 적용할 수 있는 영어 말하기를 연습하세요.`,
    `Ringle tutors also pay special attention to the nuance and context of your speaking and offer business idioms and expressions`,
    's_case_business.bullet_3',
    `在会议或工作情况下能够运用简洁准确的英语表达自身想法是至关重要的。Ringle选用商务或与专业领域相关的教材, 能够对运用于实际工作环境中的英语口语进行更具针对性的练习。`,
    `在會議或工作情況中，必須能夠簡潔精確地傳達自身的意見。使用商務及專業領域相關教材上課，練習可運用於實戰工作環境的英語口說吧！`,
    `会議または仕事現場では、自分の意見を簡潔かつ正確に伝えることが重要です。ビジネスや専門分野に関する教材でレッスンをしながら、実際の業務環境で適用できる英語のスピーキングを練習しましょう。`,
    `Gia sư Ringle cũng đặc biết chú ý đến sắc thái và ngữ cảnh sử dụng để cung cấp những mẫu câu và cách nói thông dụng`,
  ],
  quote: [
    <>
      "아무래도 외국계 회사에 다니다 보니 업무도 항상 영어로 해야 했고 개인적으로도 영어에 많은 투자를 했었죠. 나만을
      위해 일대일로 퀄리티 높은 선생님이 가르쳐주는 기회는 많지 않거든요, 직장인 분들이나 외국계 근무하시는 분들이
      링글을 경험하시면 만족도가 아주 높으실 겁니다."
    </>,
    <>
      "I have to speak English for work on a daily basis. It's not often that you get the opportunity to have a 1:1
      lesson with high quality tutors. I believe a lot of working professionals would find Ringle extremely helpful."
    </>,
    's_case_business.quote',
    <>
      "不管在什么样的外资企业工作, 经常使用英语是不可避免的, 所以对于英语方面的投资一定会很多。对我而言,
      这种1:1形式和高水平的教师一起学习的机会并不是很多,
      所以我相信对于公司职员或那些就职于外资企业的职场人士而言如果体验到Ringle课程，一定会十分满意。"
    </>,
    `「因為在外資公司工作，工作時經常需要使用英語，我私底下也在英語方面投資了許多。這種1:1由高水準的老師專為我授課的機會並不多，如果上班族或在外資公司工作的人士能體驗Ringle的課程，一定會十分滿意。」`,
    <>
      "やはり外資系企業に勤めていると業務も常に英語でしなければならず、個人的にも英語にたくさん投資しました。私だけのために1on1でクオリティの高い先生が教えてくれる機会は多くありません。会社員の方や外資系に勤務されている方がRingleを体験すれば、満足度がとても高いと思います。"
    </>,
    <>
      "Tôi phải sử dụng tiếng Anh trong công việc hằng ngày. Bạn sẽ không thường xuyên có cơ hội tham gia những buổi học
      1:1 với các gia sư giỏi. Tôi tin rằng những người đi làm sẽ thấy Ringle hữu ích."
    </>,
  ],
  quote_writer: [
    `- 김정은 님`,
    `- Jungeun, Engineer`,
    's_case_business.quote_writer',
    `- Mr. Kim`,
    `- Mr. Kim`,
    `- キム・ジョンウンさん`,
    `- Jungeun, Kỹ sư`,
  ],
}
export const s_case_correction = {
  who_need_correction: [
    <>
      에세이/레주메/CV
      <br />
      <Div spanTag textSecondary>
        영문서 교정
      </Div>
    </>,
    <>Perfect your Resume, CV, essay, and more</>,
    's_case_correction.who_need_correction',
    <>
      短文/履历/CV
      <br />
      <Div spanTag textSecondary>
        英文书面文本校正
      </Div>
    </>,
    <>
      申論／簡歷／CV
      <br />
      <Div spanTag textSecondary>
        糾正英文文件
      </Div>
    </>,
    <>
      エッセイ/レジュメ/CV
      <br />
      <Div spanTag textSecondary>
        英文校正
      </Div>
    </>,
    <>Hoàn thiện Resume, CV, bài luận và các tài liệu khác</>,
  ],
  important_document: [
    <>
      문서별 목적에 맞는
      <br />
      전공과 배경을 가진 튜터와 대화하며
      <br />
      수준 높은 영문서를 작성합니다
    </>,
    <>Get advice from a tutor with a relevant background for a detailed fine-tuning</>,
    's_case_correction.important_document',
    <>
      根据不同文件的使用目的
      <br />
      与具备相关专业背景的教师进行对话
      <br />
      编写高水平的英文书面文本
    </>,
    <>
      與具備符合各文件目的的
      <br />
      專業背景的任課教師對話，
      <br />
      撰寫高水準的英文文件。
    </>,
    <>
      文書別目的に合った
      <br />
      専攻と背景を持ったチューターと会話しながら
      <br />
      レベルの高い英文書を作成します。
    </>,
    <>Lời khuyên từ gia sư có nền tảng kiến thức phù hợp cho những điều chỉnh chi tiết</>,
  ],
  essay: [`#에세이`, `#Resume`, 's_case_correction.essay', `#短文`, `#申論`, `#エッセイ`, `#Resume`],
  resume: [`#레주메`, `#Essay`, 's_case_correction.resume', `#履历`, `#簡歷`, `#レジュメ`, `#Bài_luận`],
  CV: [`#CV`, `#CV`, 's_case_correction.CV', `#CV`, `#CV`, `#CV`, `#CV`],
  SOP: [`#SOP`, `#SOP`, 's_case_correction.SOP', `#SOP`, `#SOP`, `#SOP`, `#SOP`],
  can_ringle_correction: [
    `영문서를 링글 화상영어로 교정 받을 수 있나요?`,
    `Can I have my Resume, Essays, and other documents edited with Ringle?`,
    's_case_correction.can_ringle_correction',
    `英文书面文本可以通过Ringle线上英语进行校正吗?`,
    `可透過Ringle視訊英語糾正英文文件嗎？`,
    `英文書をRingleオンライン英会話で校正してもらえますか？`,
    `Tôi có thể sủa Resume, bài luận và các tài liệu khác với Ringle không?`,
  ],
  many_ringle_correction: [
    <>
      네, 약 10-15% 정도의 링글 수강생 분들이 영문서 교정에 링글 수업을 활용하고 계십니다. 유학 및 취업 준비를 위한
      레주메, 에세이, CV, SOP 등은 물론, 영어 이메일, PT 스크립트, 발표 자료 등 영어로 된 모든 문서는 링글 튜터와 함께
      대화하며 교정할 수 있습니다.
    </>,
    <>
      Of course! About 10-15% of our customers have editing focused lessons on documents of their choosing, be it a CV,
      resume, cover letter, essay for a class or for a grad school application, statement of purpose, research abstract,
      thesis, presentation scripts, blog posts, or even a particularly important email.
    </>,
    's_case_correction.many_ringle_correction',
    <>
      当然, Ringle学员中约有10-15%通过Ringle课程对英文书面文本进行校正。其中包含为留学及就业准备的履历, 短文,
      CV及SOP等内容, 同时诸如英文邮件, PT脚本, 发表材料等任何英文形式的书面文本均可通过与Ringle教师的视频会话进行修正。
    </>,
    <>
      當然，Ringle學生中約有10～15%透過Ringle課程糾正英文文件。不僅是為了留學及就業而準備的簡歷、申論、CV及SOP等，英文電子郵件、報告腳本、發表資料等任何以英文撰寫的文件均可透過與Ringle任課教師對話並進行糾正。
    </>,
    <>
      はい。約10-15%のRingle受講生が英文書の校正にRingleのレッスンを活用しています。留学および就職準備のためのレジュメやエッセイ、CV、SOPなどはもちろん、英語メール、PTスクリプト、発表資料など、英語で書かれたすべての文書はRingleのチューターと一緒に会話しながら校正することができます。
    </>,
    <>
      Hoàn toàn được! Khoảng 10-15% học viên chọn tập trung vào phần chỉnh sửa các tài liệu của họ trong buổi học. Đó có
      thể là CV, resume, cover letter, bài luận tại lớp hoặc đơn ứng tuyển sau đại học, statement of purpose, bản tóm
      tắt nghiên cứu, luận văn, kịch bản thuyết trình, bài blog hoặc kể cả email cho những mục đích quan trọng.
    </>,
  ],
  question: [
    <>링글 튜터의 영문서 교정은 무엇이 다른가요?</>,
    `Ringle has three major advantages when it comes to editing:`,
    's_case_correction.question',
    <>Ringle教师的英文书面文本校正有何不同之处?</>,
    <>Ringle任課教師的糾正英文文件有何不同之處？</>,
    <>Ringleのチューターの英文校正は何が違いますか？</>,
    `Ringle có 3 ưu điểm chính trong việc chỉnh sửa:`,
  ],
  bullet_1: [
    `링글 튜터는 대부분 영국/미국 명문대 출신으로 영어 글쓰기에 대한 강도 높은 훈련을 받은 원어민입니다. 이들은 풍부한 영문서 작성 및 첨삭 경험으로 상황과 목적에 맞는 글쓰기에 능숙하며, 원어민 관점에서 영문서에 적합한 문체와 표현으로 글을 수정할 수 있습니다.`,
    `Ringle tutors are excellent editors and writers who have developed effective communication skills at prestigious universities`,
    's_case_correction.bullet_1',
    `绝大多数的Ringle教师出自英/美名牌大学, 均是接受过英文写作高强度训练的母语教师。他们具备丰富的英文书写及修改经验, 擅长根据情况及具体目的撰写文章, 同时能够从母语者的角度提出适用于英文书面文本的文法及表达方式, 对其进行纠正及提升。`,
    `Ringle的任課教師大部分出自英國／美國的名校，是曾接受過高強度英文寫作訓練的母語人士。他們具備豐富的英文文件撰寫及增刪經驗，擅長依據情況及目的撰寫文章，能以母語人士的觀點，使用適合英文文件的文體與用法為您修改文章。`,
    `Ringleのチューターは大半がイギリス/アメリカの名門大学出身で、英語のライティングについて厳しい訓練を受けたネイティブスピーカーです。彼らは豊富な英文書の作成および添削経験があり、状況と目的に合わせたライティングに長けており、ネイティブの観点から英文書に適した文体と表現で文章を修正することができます。`,
    `Gia sư Ringle là những biên tập viên và cây viết xuất sắc, đã tôi luyện kỹ năng giao tiếp tại những trường Đại học danh tiếng`,
  ],
  bullet_2: [
    `링글 튜터들은 각 문서의 목적과 의도를 이해하고 교정합니다. 단순히 틀린 문장을 수정하는 것이 아니라, 수강생이 전달하려는 핵심이 간결하고 명쾌하게 전달될 수 있도록, 또 문서에 맞는 톤으로 작성될 수 있도록 실시간으로 대화하며 교정합니다.`,
    `Ringle tutors understand that each document serves a unique purpose and always edit holistically, prioritizing the content and messaging of your document above anything else. They might make your sentences more concise or more complex, more direct or more subtle, more humorous or more serious, all depending on what is more appropriate for your case`,
    's_case_correction.bullet_2',
    `Ringle的教师将根据不同文本的目的及意图进行理解及校正。不仅仅是单纯地对文章错句进行修改，而是试图将学生希望表达的核心内容运用更为简洁及准确的英文进行表达，同时为准确掌握文章语气，可通过实时对话进行及时纠正。`,
    `Ringle的任課教師會理解各文件的目的與意圖進行糾正。不僅修改錯誤的句子，更會即時對話並糾正，使學生能簡潔明快地傳達核心內容，或以符合文件的語氣撰寫。`,
    `Ringleのチューターは、各文書の目的と意図を理解して校正します。単に間違った文章を修正するのではなく、受講生が伝えようとする核心が簡潔かつ明快に伝わるよう、また文書に合ったトーンで作成されるようにリアルタイムで会話をして校正します。`,
    `Gia sư Ringle hiểu rằng mỗi tài liệu phục vụ những mục đích khác nhau và cần được chỉnh sửa một cách toàn diện, đặc biệt chú trọng nội dung và thông điệp của tài liệu. Họ có thể biến câu văn của bạn trở nên ngắn gọn hơn hoặc phức tạp hơn, trực tiếp hơn hoặc tinh tế hơn, hài hước hơn hoặc nghiêm túc hơn, tất cả sẽ tuỳ thuộc vào trường hợp của bạn`,
  ],
  bullet_3: [
    `링글에는 경영/경제/영문학/의학/공학/법학 등 다양한 전공을 가진 튜터들이 있습니다. 이에 특정 분야에 대한 일정 수준 이상 전문 지식이 필요한 교정의 경우, 관련 분야 튜터를 검색해 수업 진행이 가능합니다.`,
    `Ringle tutors vary in area of specialties from business & economics to English literature to engineering, and can cater your writing to carry the right terms and expressions specific to your sector`,
    's_case_correction.bullet_3',
    `Ringle的教师中不乏出身经营/经济/英文/医学/工学/法学等专业的高端人才, 因此需特定领域的专业知识对文件进行校正的情况可通过搜索功能对相关领域教师进行查找后预约课程。`,
    `Ringle擁有經營／經濟／英文文學／醫學／工學／法學等各種主修的任課教師。因此，如需擁有特定領域一定水準以上的專業知識以利糾正時，可透過搜尋相關領域的任課教師後進行課程。`,
    `Ringleには経営/経済/英文学/医学/工学/法学など、多様な専攻のチューターがいます。そのため、特定分野に対する一定レベル以上の専門知識が必要な校正の場合、関連分野のチューターを検索してレッスンを行うことができます。`,
    `Chuyên môn của các gia sư tại Ringle khá đa dạng, từ Kinh tế đến Ngữ văn Anh hay Kỹ sư. Họ có thể hỗ trợ bạn sử dụng thuật ngữ và cách diễn đạt phù hợp cho bài viết chuyên ngành của bạn.`,
  ],
  quote: [
    <>
      "그냥 영어 선생님이 아니라, 제가 가고자 하는 학교에서 실제로 공부를 하는, 실제 지원자 입장에서 문서를 작성했었던
      튜터분들을 만날 수 있었던 것이 정말 행운이었습니다."
    </>,
    <>
      "I felt so lucky that I was able to meet with tutors that currently study in the schools I was applying to and
      have also written application essays for those schools."
    </>,
    's_case_correction.quote',
    <>
      "不仅是英语教师, 而是作为我梦想学校的实际在校生能够以申请者的角度设身处地地对文件进行编写,
      我觉得能够和教师们一起学习真是非常幸运。"
    </>,
    `「不僅是英語老師，而是實際在我夢想的學校就學的在校生，能夠遇見曾經以申請者立場撰寫文件的任課教師真的很幸運。」`,
    <>
      "ただの英語の先生ではなく、私が行こうとしている学校で実際に勉強している、実際に志願者の立場で文書を作成していたチューターの方々にお会いできて本当に幸運でした。"
    </>,
    <>
      "Tôi cảm thấy rất may mắn khi có thể gặp được những gia sư đang theo học tại những ngôi trường tôi đang viết bài
      luận để nộp đơn vào."
    </>,
  ],
  quote_writer: [
    `- HBS Class of 2020 / 이상윤님`,
    `- Sangyoon, HBS Class of 2020`,
    's_case_correction.quote_writer',
    `- HBS Class of 2020 / Mr. Lee`,
    `- HBS Class of 2020／Mr. Lee`,
    `- HBS Class of 2020 / イ・サンユンさん`,
    `- Sangyoon, Trường Kinh doanh Harvard khoá 2020`,
  ],
}
export const s_case_exam = {
  toefl_ielts: [
    <>
      토플/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        공인영어시험 대비
      </Div>
    </>,
    <>Prepare for TOEFL, TOEIC, IELTS and more</>,
    's_case_exam.toefl_ielts',
    <>
      TOEFL/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        专业英语考试应对
      </Div>
    </>,
    <>
      TOEFL／IELTS
      <br />
      Speaking／Writing
      <br />
      <Div spanTag textSecondary>
        準備英語檢定
      </Div>
    </>,
    <>
      TOEFL/IELTS
      <br />
      Speaking/Writing
      <br />
      <Div spanTag textSecondary>
        公認英語試験対策
      </Div>
    </>,
    <>Chuẩn bị cho các kỳ thi TOEFL, TOEIC, IELTS và nhiều kỳ thi khác</>,
  ],
  for_exam: [
    <>
      기존 학습법, 템플릿 암기의
      <br />
      한계에 부딪혔을 때,
      <br />
      원어민 튜터의 교정으로 고득점을 달성합니다
    </>,
    <>Go beyond rote memorization to improve your fundamental English fluency</>,
    's_case_exam.for_exam',
    <>
      现有学习方法, 模板背诵
      <br />
      遇到瓶颈时,
      <br />
      通过母语教师的指点取得更好的成绩
    </>,
    <>
      當原本的學習方法、模板背誦
      <br />
      遇到瓶頸時，
      <br />
      透過母語任課教師的糾正取得高分
    </>,
    <>
      従来の学習方法、テンプレート暗記の
      <br />
      限界にぶつかった時、
      <br />
      ネイティブチューターの校正で高得点を達成します。
    </>,
    <>Vượt qua học thuộc lòng để cải thiện khả năng sử dụng tiếng Anh thành thạo</>,
  ],
  can_ringle_for_exam: [
    <>공인영어시험을 링글 화상영어로 준비할 수 있나요?</>,
    `Can I prepare for English proficiency tests with Ringle?`,
    's_case_exam.can_ringle_for_exam',
    <>可以通过Ringle线上英语准备专业英语考试吗?</>,
    <>可以透過Ringle準備準備英語檢定嗎？</>,
    <>公認英語試験の勉強をRingleオンライン英会話で準備することはできますか？</>,
    `Tôi có thể chuẩn bị cho các kỳ thi năng lực tiếng Anh với Ringle không?`,
  ],
  many_ringlers_for_exam: [
    <>
      네, 링글로 토플/IELTS/토익 스피킹/오픽 등 공인영어시험을 준비하시는 수강생이 약 10-15% 계십니다. 대부분은 학원 및
      강의를 통해 템플릿 암기 위주로 공부하시다가 더 이상 점수가 오르지 않았던 분들이었습니다. 특히 토플 스피킹 21-25점,
      IELTS speaking 3-5점에서 점수가 잘 오르지 않는다는 고민으로 링글 수강을 시작하신 분이 많습니다.
      <br />
      <br />
      고득점을 위해서는 무의식적 실수와 Filler Word 사용은 줄이고, 다양한 어휘를 사용하면서도 말하고자 하는 바를
      간결하게 전달해야 합니다. 링글에서 실전처럼 영어를 연습하고 본질적인 영어 소통 능력을 키워 고득점을 달성하세요.
    </>,
    <>
      Absolutely! About 10-15% of our customers use Ringle to prepare for English proficiency exams such as TOEFL,
      TOEIC, IELTS and more.
      <br />
      <br />
      Plenty of coaching services and learning materials for these exams exist, but most tend to harp on rote
      memorizations of vocabs and grammar rules.
      <br />
      <br />
      This can be effective, but only to a certain degree. For example, our customers often find their score stagnate
      around 21-25 in TOEFL speaking and 3-5 in IELTS speaking before they started learning with Ringle.
      <br />
      <br />
      At Ringle, we focus on improving fundamental fluency that can bring a better result on exams by making you a
      better speaker overall.
    </>,
    's_case_exam.many_ringlers_for_exam',
    <>
      当然,
      Ringle中大约有10-15%的学员在准备托福/IELTS/托业口语/Opic等专业英语考试。其中大部分学生都有在其他补习平台通过模板背诵的方式进行学习后,
      出现分数上升瓶颈的问题, 特别是托福口语21-25分, IELTS Speaking3-5分的学员因分数提升困难开始使用Ringle的大有人在。
      <br />
      <br />
      为取得高分, 应减少无意识的失误及使用Filler Word的频率,
      即使是使用多样化的词汇也要以简洁传达自身想法为基础进行会话。于Ringle中进行实战会话练习,
      提升自身英语水平从而获得高分数吧。
    </>,
    <>
      當然，Ringle中大約有10～15%的學生準備托福／雅思／多益口說／Opic等英語檢定。其中大部分學生都是在補習或課程透過模板背誦的方式學習後，分數再也無法提升。尤其是很多學生的成績停留在托福口說21～25分、雅思口說3～5分，所以開始聆聽Ringle的課程。
      <br />
      <br />
      為取得高分，應減少無意識的失誤及使用Filler
      Word的頻率，即使是使用各種詞彙，也必須簡潔傳達自身的想法。請在Ringle進行實戰英語練習，培養實質的英語溝通能力並取得高分。
    </>,
    <>
      はい。RingleでTOEFL/IELTS/TOEIC
      SPEAKING/OPIcなど公認英語試験の勉強をされる受講生は約10-15%いらっしゃいます。大半の方は塾や講義を通してテンプレート暗記中心の勉強をされていましたが、それ以上点数が上がらなかった方々でした。特にTOEFL
      SPEAKINGが21-25点、IELTS
      speakingが3-5点と点数があまり上がらないという悩みをお持ちで、Ringleを受講し始めた方が多いです。
      <br />
      <br />
      高得点のためには無意識的なミスとFiller
      Wordの使用を減らし、さまざまな語彙を使いながらも言いたいことを簡潔に伝えなければなりません。Ringleで実際のように英語を練習し、本質的な英語コミュニケーション能力を伸ばして高得点を達成しましょう。
    </>,
    <>
      Hoàn toàn được! Khoảng 10-15% học viên cùng Ringle chuẩn bị cho các kỳ thi tiếng Anh như TOEFL, TOEIC, IELTS và
      các kỳ thi khác.
      <br />
      <br />
      Có rất nhiều dịch vụ và tài liệu để chuẩn bị cho các kỳ thi, tuy nhiên đa phần tập trung vào việc ghi nhớ từ vựng
      và cấu trúc ngữ pháp.
      <br />
      <br />
      Điều này vẫn hiệu quả nhưng chỉ ở một mức độ nào đó. Ví dụ, học viên của chúng tôi thường có điểm số nằm trong
      khoảng 21-25 ở bài nói TOEFL và 3-5 cho bài thi nói IELTS trước khi họ học Ringle
      <br />
      <br />
      Tại Ringle, chúng tôi tập trung cải thiện khả năng sử dụng tiếng Anh trôi chảy giúp mang đến kết quả tốt hơn trong
      các kỳ thi và giúp bạn giao tiếp tốt hơn.
    </>,
  ],
  question: [
    <>링글이 어떻게 공인영어시험 고득점에 도움이 되나요?</>,
    `Here are different ways you can customize your Ringle lessons to prepare for English proficiency exams:`,
    's_case_exam.question',
    <>如何通过Ringle提升专业英语考试的分数?</>,
    <>Ringle有助於英語檢定取得高分嗎？</>,
    <>Ringleはどのように公認英語試験において高得点に役立ちますか？</>,
    `Đây là những cách điều chỉnh buổi học ở Ringle để giúp bạn chuẩn bị cho kỳ thi tiếng Anh:`,
  ],
  bullet_1: [
    `Speaking 시험의 경우, 자유주제(Speaking Focused) 수업으로 원하는 자료를 업로드하신 뒤 튜터와 실전처럼 연습할 수 있습니다. 고급 영어를 구사하는 영미권 아이비리그 튜터의 실시간 피드백으로 내가 말한 답안을 눈으로 보며 교정받으세요. 튜터의 교정으로 어휘력과 문장력을 넓히고 새로 배운 표현은 다음 답안에 바로 적용할 수 있습니다.`,
    `Come to your lesson with 4-5 speaking prompts and practice timed speaking with your tutor`,
    's_case_exam.bullet_1',
    `Speaking考试的情况, 可通过自由主题(Speaking Focused)课程上传需要的资料和Ringle教师开展实战性练习。通过使用高级英语的英美圈常春藤教师的实时反馈, 对纠正的问题进行直观确认。教师的纠正有助于拓展学员的词汇量及文章表现力, 同时将所学到的表达方式应用于今后的答题之中。`,
    `Speaking測驗的情況，透過自由主題（Speaking Focused）課程，可於上傳希望的資料後，和Ringle任課教師進行如實戰一般的練習。透過使用高級英語的英美常春藤任課教師的即時意見回饋，親眼確認如何糾正您所說的回覆吧！任課教師的糾正有助於學生增進詞彙及句子的能力，同時可將剛學到的用法馬上應用於下一個回覆。`,
    `Speaking試験の場合、自由テーマ(Speaking Focused)レッスンで好きな資料をアップロードし、チューターと実際のように練習することができます。高級英語を駆使する英米圏アイビーリーグチューターのリアルタイムフィードバックで、あなたが言った答えを目で見ながら校正してもらいましょう。チューターの校正で語彙力と文章力を高め、新しく学んだ表現は次の答えですぐに適用することができます。`,
    `Tham gia buổi học với 4-5 gợi ý và luyện tập cùng gia sư`,
  ],
  bullet_2: [
    <>
      고득점을 목표하신다면, 문법/발음 실수를 바로잡고, Filler word를 줄이는 등 나의 말하기 습관을 객관적으로 점검해야
      합니다. 수업 후 제공되는 녹음 파일, 스크립트, AI 분석으로 말하기 속도, Filler word 사용 빈도, 자주 사용하는 어휘나
      구문을 꼼꼼히 복습해보세요.
    </>,
    `You can also have a writing-focused lesson by bringing a script written on a prompt of your choice and have your tutor review them`,
    's_case_exam.bullet_2',
    <>
      如果考试的目标是获得高分数, 那么就要抓住文法/发音中的问题，减少Filler
      word的频率等对自身英语进行客观性的判断。课程结束后，通过Ringle所提供的录音文件, 脚本, AI分析等内容,
      对自身语言速度, Filler word使用频率, 常用词及语句等问题进行仔细复习。
    </>,
    <>
      如果希望取得高分，就要糾正文法／發音的失誤，減少Filler
      word的頻率等，客觀檢查自己的口說習慣。課程結束後，請利用Ringle所提供的錄音檔、腳本、AI分析的口說速度、Filler
      word使用頻率、常用詞彙或句型仔細複習。
    </>,
    <>
      高得点が目標なら、文法/発音ミスを正してFiller
      wordを減らすなど、自分のスピーキング習慣を客観的にチェックしなければなりません。レッスン後に提供される録音ファイル、スクリプト、AI
      analysisで話すスピードやFiller wordの使用頻度、よく使用する語彙や構文をしっかり復習しましょう。
    </>,
    `Bạn có thể chọn buổi học tập trung vào viết và mang theo bài viết dựa trên chủ đề bạn chọn để cùng gia sư đánh giá bài của mình`,
  ],
  bullet_3: [
    `Writing 시험의 경우, 자유주제(Writing Focused) 수업으로 원하는 자료를 업로드해 실시간 교정을 받을 수 있습니다. Academic Writing에 능한 링글 튜터들과 함께 표현력을 넓히고 실전 답안 작성에 바로 적용해보세요. 단순한 어휘로 만든 내 답변이 고급 영어로 재탄생합니다.`,
    `Ringle tutors will provide real-time feedback on both technical errors and content improvement. Feel free to repeat the same prompt after receiving the feedback to drive home key corrections!`,
    's_case_exam.bullet_3',
    `Writing考试的情况, 可通过自由主题(Writing Focused)课程上传所需的资料, 接受实时纠正。将与擅长Academic Writing的Ringle教师一起提升表达能力, 应用于实战答题中。将那些只是单纯词汇堆叠的文章变身为高级英语表达吧。`,
    `Writing測驗的情況，可透過自由主題（Writing Focused）的課程，上傳您希望的資料，並接受即時糾正。與擅長Academic Writing的Ringle任課教師一起增強表達能力，並立即用於實戰撰寫回覆吧！僅由單純詞彙組成的回覆將以高級英語重新誕生。`,
    `Writing試験の場合、自由テーマ(Writing Focused)レッスンで好きな資料をアップロードし、リアルタイム校正してもらうことができます。Academic Writingが上手なRingleのチューターと共に表現力を高め、実際の答案作成にすぐに適用しましょう。単純な語彙で作ったあなたの答えが高級英語に生まれ変わります。`,
    `Gia sư Ringle sẽ đánh giá bài viết trực tiếp cho bạn cả về lỗi sai và cách cải thiện nội dung. Hãy viết lại bài lần nữa với những đánh giá của gia sư để nắm được trọn vẹn.`,
  ],
  quote: [
    <>
      "제가 링글을 사용하며 느낀 건, 링글에서는 내가 쓰는 영어가 한 단계 스킬업이 된다는 거였어요. 단어, 문장, 뉘앙스,
      발음 그 구체적인 하나 하나들을 튜터들이 다 캐치해줘요."
    </>,
    <>
      "My English definitely stepped up with Ringle. Ringle tutors pay attention to every details on my vocabulary,
      sentences, nuances, and pronunciation."
    </>,
    's_case_exam.quote',
    <>
      "我在使用Ringle时感到我的英语水平提升了一个档次。教师会针对我使用的单词, 语句, 语气, 发音等具体内容进行详细纠正。"
    </>,
    `「進行Ringle課程時，我感覺到我的英語提升了一個等級。任課教師會針對我使用的單字、句子、語感、發音等具體內容進行詳細糾正。」`,
    <>
      "私がRingleを使って感じたのは、Ringleでは私が使う英語が一段階スキルアップするということでした。単語、文章、ニュアンス、発音、具体的なひとつひとつをチューターがすべてキャッチしてくれます。"
    </>,
    <>
      "Khả năng tiếng Anh của tôi cải thiện vượt bậc nhờ Ringle. Gia sư của Ringle chú ý đến từng chi tiết về từ vựng,
      câu, sắc thái và phát âm."
    </>,
  ],
  quote_writer: [
    `- 패션 스타트업 CHELEC 대표 임선영님`,
    `- Sunyoung, CHELEC CEO`,
    's_case_exam.quote_writer',
    `- 时尚创业公司CHELEC代表 Ms Im`,
    `- 時尚新創公司CHELEC代表 Ms. Im`,
    `- ファッションスタートアップ CHELEC代表 イム・ソンヨンさん`,
    `- Sunyoung, CHELEC CEO`,
  ],
  ielts: [`#아이엘츠`, `#TOEIC`, 's_case_exam.ielts', `#雅思`, `#雅思`, `#IELTS`, `#TOEIC`],
  toefl: [`#토플`, `#TOEFL`, 's_case_exam.toefl', `#托福`, `#托福`, `#TOEFL`, `#TOEFL`],
  toeic_speaking: [
    `#토익스피킹`,
    `#IELTS`,
    's_case_exam.toeic_speaking',
    `#托业口语`,
    `#多益口說`,
    `#TOPICスピーキング`,
    `#IELTS`,
  ],
  opic: [`#오픽`, `#OPIC`, 's_case_exam.opic', `#Opic`, `#Opic`, `#OPIc`, `#OPIC`],
}
export const s_case_pronunciation = {
  korean_accent: [
    <>
      한국식{' '}
      <Div spanTag textSecondary>
        영어 발음
      </Div>
      을
      <br />
      자연스럽게 교정하기
    </>,
    <>You can sound like a native speaker</>,
    's_case_pronunciation.korean_accent',
    <>
      将中式
      <Div spanTag textSecondary>
        英语发音
      </Div>
      <br />
      进行自然矫正
    </>,
    <>
      將不自然的
      <Div spanTag textSecondary>
        英語發音
      </Div>
      <br />
      矯正成自然的發音
    </>,
    <>
      日本式{' '}
      <Div spanTag textSecondary>
        英語発音
      </Div>
      を
      <br />
      自然に矯正する
    </>,
    <>Giao tiếp như người bản xứ</>,
  ],
  naturally_correct: [
    <>
      굳어진 한국식 영어 발음,
      <br />
      억양, 인토네이션을
      <br />
      대화를 통해 자연스럽게 교정합니다
    </>,
    <>Pronounce and intonate correctly for an effective communication</>,
    's_case_pronunciation.naturally_correct',
    <>
      固化的中式英语发音
      <br />
      口音及语调,
      <br />
      通过对话自然修正。
    </>,
    <>
      透過對話自然矯正
      <br />
      不自然的英語發音、
      <br />
      口音及語調。
    </>,
    <>
      固まった日本式の英語発音、
      <br />
      イントネーション、アクセントを
      <br />
      会話を通じて自然に矯正します。
    </>,
    <>Phát âm và ngữ điệu chính xác để giao tiếp hiệu quả</>,
  ],
  american_accent: [
    `#미국식발음`,
    `#Accent`,
    's_case_pronunciation.american_accent',
    `#美式发音`,
    `#美式發音`,
    `#アメリカ式発音`,
    `#Giọng`,
  ],
  british_accent: [
    `#영국식발음`,
    `#Intonation`,
    's_case_pronunciation.british_accent',
    `#英式发音`,
    `#英式發音`,
    `#イギリス式発音`,
    `#Ngữ_điệu`,
  ],
  accent: [
    `#억양`,
    `#Enunciation`,
    's_case_pronunciation.accent',
    `#口音`,
    `#口音`,
    `#アクセント`,
    `#Tròn_vành_rõ_chữ`,
  ],
  intonation: [
    `#인토네이션`,
    `#Pronunciation`,
    's_case_pronunciation.intonation',
    `#语调`,
    `#語調`,
    `#イントネーション`,
    `#Phát_âm`,
  ],
  can_ringle_correct_pronunciation: [
    `영어 발음도 링글 화상영어로 교정할 수 있나요?`,
    `Can I correct my pronunciation using Ringle lessons?`,
    's_case_pronunciation.can_ringle_correct_pronunciation',
    `英语发音也可以通过Ringle线上英语进行纠正吗?`,
    `英語發音也可以透過Ringle視訊英語矯正嗎？`,
    `英語の発音もRingleオンライン英会話で矯正できますか？`,
    `Tôi có thể chỉnh phát âm của mình trong buổi học của Ringle không?`,
  ],
  many_ringlers_pronunciation: [
    <>
      네, 링글 수강생 중 발음 및 인토네이션을 중점적으로 연습하시는 분들이 많이 계십니다. 영국 발음, 미국 발음 등 배우고
      싶은 발음이 있거나, 발음 때문에 내 역량이 다 표현되지 않을까 고민이라면, 링글의 일대일 수업으로 원어민 튜터의
      실시간 피드백을 받으며 발음을 교정해보세요!
    </>,
    <>
      Sure thing! We understand pronunciations and intonations can be hard. It certainly takes a good tutor and lots of
      practice to sound like a native speaker.
    </>,
    's_case_pronunciation.many_ringlers_pronunciation',
    <>
      当然, Ringle学员中将发音及语调作为英语学习重点的人很多。如果想学习正宗的美式,
      英式发音或是担心因发音上的不足出现限制自身英语能力表达的问题时, 都可通过Ringle 1:1课程,
      在母语教师实时纠正的情况下对发音问题进行改善。
    </>,
    <>
      當然，Ringle有許多學生以發音及語調為主進行練習。如果想學習正統的英式發音、美式發音等，或擔心因為發音而無法展現所有實力的話，就透過Ringle
      1:1課程取得母語任課教師的即時意見回饋，並矯正發音吧！
    </>,
    <>
      はい。Ringle受講生のうち、発音とイントネーションを重点的に練習される方が多いです。イギリス発音やアメリカ発音など学びたい発音があったり、発音のせいであなたの力がすべて表現できないのではないかとお悩みなら、Ringleの1on1のレッスンでネイティブチューターのリアルタイムフィードバックを受けながら発音を矯正しましょう！
    </>,
    <>
      Hoàn toàn được! Chúng tôi hiểu rằng phát âm và ngữ điệu không dễ. Điều này đòi hỏi một gia sư giỏi và nhiều thời
      gian tập luyện để nói như người bản ngữ.
    </>,
  ],
  question: [
    <>링글을 통해 어떻게 영어 발음을 교정할 수 있나요?</>,
    `Here’s how Ringle lessons can help you improve your pronunciations:`,
    's_case_pronunciation.question',
    <>如何通过Ringle对英语发音进行纠正?</>,
    <>如何透過Ringle矯正英語發音呢？</>,
    <>Ringleでどのように英語の発音を矯正できますか？</>,
    `Đây là cách buổi học của Ringle giúp bạn cải thiện việc phát âm:`,
  ],
  bullet_1: [
    `수업 전 수업 방식 설정에서 발음을 집중 교정영역으로 선택하세요. '대화 중 어색한 발음은 바로 교정해달라', '어려운 발음은 여러 번 반복해달라'는 등의 구체적인 요청사항 작성도 가능합니다.`,
    `Ringle makes it easy for you to set the lesson preference to focus on pronunciation. Simply leave a lesson request like "Please correct my pronunciation after each time I speak”`,
    's_case_pronunciation.bullet_1',
    `请在课程开始之前选择以‘发音'作为集中纠正部分。同时还可以提出 '立即纠正对话中生涩的发音', '发音困难部分进行反复练习‘等课程具体要求事项。`,
    `請在課程開始前選擇「發音」作為集中糾正領域，也可寫下「對話時請立即糾正不自然的發音」、「請重複多次困難的發音」等具體要求事項。`,
    `レッスン前のレッスン方式設定で発音を集中校正分野に設定してください。「会話中の不自然な発音はすぐに矯正してほしい」「難しい発音は何度か繰り返してほしい」などの具体的なリクエスト事項の作成も可能です。`,
    `Missing " at the end of the sentences`,
  ],
  bullet_2: [
    `닮고 싶은 발음을 구사하는 튜터를 선택해 수업을 들어보세요. 자기소개 영상을 시청하거나 검색을 통해 원하는 발음의 튜터와의 수업을 예약할 수 있습니다.`,
    `Ringle offers 1,000+ lesson materials various topics including politics, and culture, so you can focus on perfecting pronunciations of sector-specific terminologies`,
    's_case_pronunciation.bullet_2',
    `明确自身想学习的发音类型, 选择一名使用此种发音语调的教师开始会话课程吧。通过观看教师自我介绍视频或进行搜索, 预约符合自身要求的教师课程。`,
    `請選擇使用您想學習的發音的任課教師進行課程，您可透過觀看任課教師的自我介紹影片或搜尋，預約您想要的發音的任課教師。`,
    `真似したい発音のチューターを選んでレッスンを受けましょう。自己紹介動画の視聴や検索を通して希望する発音のチューターとのレッスンを予約することができます。`,
    `Ringle cung cấp 1,000+ tài liệu với những chủ đề khác như như chính trị và văn hoá. Chính vì vậy, bạn có thể tập trung hoàn thiện việc phát âm với các từ theo chuyên ngành`,
  ],
  bullet_3: [
    <>
      수업이 끝나도 언제든지 복습 탭에서 수업 녹음 파일을 다시 들을 수 있습니다. 녹음을 들으며 나와 튜터의 발음을
      비교해보고, 수업 중 연습했던 발음을 다시 들어보세요.
    </>,
    `Each tutor has a short introduction video so you can find the tutor who has the accent you’d like to emulate, be it a standard American accent or a British accent`,
    's_case_pronunciation.bullet_3',
    <>
      课程结束后, 可随时通过复习页面对课程录音文件进行回顾。通过重新收听录音, 将本人发音与教师进行比较,
      对课程中练习的发音内容重新进行确认复习。
    </>,
    <>
      課程結束後，可隨時於複習分頁重新聆聽課程錄音檔。請聆聽錄音檔，比較自己和任課教師的發音，重新確認上課時練習過的發音。
    </>,
    <>
      レッスンが終わってもいつでも復習タブでレッスンの録音ファイルをまた聴くことができます。録音を聴きながら自分とチューターの発音を比較し、レッスン中に練習した発音をもう一度聴いてみましょう。
    </>,
    `Mỗi gia sư có một video ngắn giới thiệu bản thân nên bạn có thể tìm gia sư có giọng bạn muốn học theo như là giọng Anh hay giọng Mỹ`,
  ],
  quote: [
    <>
      "일주일에 한 번씩 뵙는 선생님인데도 자연스럽게 그 선생님의 말하는 습관이나 악센트를 따라가게 되었습니다. 링글
      수업의 임팩트가 정말 크다고 느꼈고, 그렇다면 제가 롤모델로 삼을 수 있는 선생님께 배우는 게 좋다고 생각했어요."
    </>,
    <>
      "I only take Ringle lessons once a week but I already catch myself copying tutors' accents and intonations, which
      speaks to the size of the impact of these lessons, and makes me want to choose a tutor I want to emulate."
    </>,
    's_case_pronunciation.quote',
    <>
      "虽然每周仅和教师学习一次, 但是你会很自然地模仿老师说话的习惯和重音使用。我觉得Ringle课程对我的影响真的很大,
      我认为能够和自己心目中的英语标杆人物一起学习才是明智之选。"
    </>,
    `「儘管一週只見一次老師，卻自然而然地模仿了那位老師說話的習慣或口音。我感覺到Ringle課程的後勁很強，能夠把老師當作我的學習榜樣，我覺得很棒。」`,
    <>
      "一週間に一度しかお会いしない先生なのに、自然とその先生の話し方やアクセントが身につきました。Ringleのレッスンのインパクトは本当に大きいと感じ、それなら私がロールモデルにしたい先生に教わるのが良いと思いました。"
    </>,
    <>
      "Tôi chỉ học Ringle một buổi một tuần mà đã có thể bắt chước giọng và ngữ điệu của gia sư, nghĩa là lợi ích của
      buổi học rất lớn, và tôi muốn chọn gia sư có giọng điệu mà mình muốn học theo."
    </>,
  ],
  quote_writer: [
    `- K 게임사 심정하님`,
    `- Jungha`,
    's_case_pronunciation.quote_writer',
    `- K 游戏公司 Mr Sim`,
    `- K遊戲公司 Mr. Sim`,
    `- K ゲーム会社 シム・ジョンハさん`,
    `- Jungha`,
  ],
}
export const s_case_lived_abroad = {
  who_lived_abroad: [
    <>
      영어권 국가에서
      <br />
      <Div spanTag textSecondary>
        유학 중
      </Div>
      이시거나
      <br />
      <Div spanTag textSecondary>
        유학 경험
      </Div>
      이 있으신 분
    </>,
    <>Make the most of your time spent studying abroad</>,
    's_case_lived_abroad.who_lived_abroad',
    <>
      于英语圈国家
      <br />
      <Div spanTag textSecondary>
        留学中
      </Div>
      或
      <br />
      <Div spanTag textSecondary>
        有过留学
      </Div>
      经验的学员
    </>,
    <>
      於英語圈國家
      <br />
      <Div spanTag textSecondary>
        留學中
      </Div>
      或
      <br />有
      <Div spanTag textSecondary>
        留學經驗
      </Div>
      的人
    </>,
    <>
      英語圏の国に
      <br />
      <Div spanTag textSecondary>
        留学中
      </Div>
      や
      <br />
      <Div spanTag textSecondary>
        留学経験
      </Div>
      のある方
    </>,
    <>Tận dụng tối đa thời gian du học của bạn</>,
  ],
  maintain_english: [
    <>
      영어가 불편하진 않아도
      <br />
      지속적으로 감각을 유지하고
      <br />
      한단계 높은 영어 수준을 목표합니다
    </>,
    <>Excel in classes and remove barriers to make lifelong friends</>,
    's_case_lived_abroad.maintain_english',
    <>
      虽然没有因为英语感到不便
      <br />
      但希望语感能够一直维持
      <br />
      目标是更上一层楼
    </>,
    <>
      雖然沒有對英語感到不自在，
      <br />
      但希望持續維持語感，
      <br />
      讓英語實力更升一級。
    </>,
    <>
      英語に不自由していなくても
      <br />
      持続的に感覚を維持して
      <br />
      一段階高い英語レベルを目指します。
    </>,
    <>Trở nên xuất sắc hơn và vượt qua những rào cản để kết nối với mọi người</>,
  ],
  experience_abroad: [
    `#유학`,
    `#International_students`,
    's_case_lived_abroad.experience_abroad',
    `#留学`,
    `#留學`,
    `#留学`,
    `#Sinh_viên_quốc_tế`,
  ],
  lived_abroad: [
    `#어학연수`,
    `#Foreign_exchange`,
    's_case_lived_abroad.lived_abroad',
    `#语言研修`,
    `#語言研修`,
    `#語学研修`,
    `#Sinh_viên_trao_đổi`,
  ],
  real_english: [
    `#영어실력유지`,
    `#Study_abroad`,
    's_case_lived_abroad.real_english',
    `#英语实力维持`,
    `#維持英語實力`,
    `#英語力維持`,
    `#Du_học`,
  ],
  high_english: [
    `#고급영어`,
    `#Higher_education`,
    's_case_lived_abroad.high_english',
    `#高级英语`,
    `#高級英語`,
    `#高級英語`,
    `#Cao_học`,
  ],
  specifically_what: [
    `현재 유학 중이거나 유학 후 귀국한 사람들도 링글을 수강하나요?`,
    `I am currently studying or have previously studied abroad. Will I find Ringle helpful?`,
    's_case_lived_abroad.specifically_what',
    `正在留学或留学后归国的人群也需要Ringle课程吗?`,
    `正在留學或留學後歸國的人也聽Ringle的課程嗎？`,
    `現在留学中や留学後に帰国した人もRingleを受講しますか？`,
    `Tôi đang đi học hoặc đã đi du học. Liệu Ringle có thể hỗ trợ tôi?`,
  ],
  writing: [
    <>
      네, 해외 유학 준비를 위해, 실제 유학 중 필요한 수준 높은 글쓰기/말하기 실력을 키우기 위해, 혹은 귀국 후 영어
      실력이 떨어지는 것을 방지하기 위해 링글을 수강하고 계신 분들이 많습니다.
      <br />
      <br />
      또한 "현재 유학 중이지만 지금 쓰는 영어를 아무도 교정해주지 않아 제대로 된 영어를 쓰고 있는지 모르겠다.", "내 영어
      실력이 일정 수준 이상으로 더 성장하는 것 같지 않았다."라는 고민에서 링글 수강을 시작하신 분들이 많이 계십니다.
    </>,
    <>
      Yes, of course! Whether it’s for a semester or for several years, studying abroad is a unique experience that
      combines culture and education, and to make the most of it often requires that you speak fluently the language of
      your destination.
      <br />
      <br />
      And for those who have previously studied abroad and learned to speak the language fluently while there, you might
      want to continuously brush up on your language skills so as not to lose the fluency you earned.
    </>,
    's_case_lived_abroad.writing',
    <>
      当然, 现在Ringle的学员中不乏有准备留学, 正在留学中需要高水平的写作/会话能力以及留学回国后担心英语水平下降的人士。
      <br />
      <br />
      同时那些心存‘虽然正在留学之中, 但没有人纠正现在正在使用的英语,
      不知道自己的英语是否存在问题’以及‘我的英语水平停留在某一水平后始终无法提升’等诸如此类烦恼的学员也不在少数。
    </>,
    <>
      當然，目前有許多正在準備留學、於留學中需要培養高水準寫作／口說實力或留學回國後為避免英語實力下滑而聆聽Ringle課程的學生。
      <br />
      <br />
      同時，也有許多學生因為煩惱「雖然正在留學，但沒有人糾正現在使用的英語，不知道我的英語是否正確。」、「我的英語實力好像停留在某一個程度無法再提升。」而開始聆聽Ringle的課程。
    </>,
    <>
      はい。海外留学に向けて、実際の留学中に必要なレベルの高いライティング/スピーキング力を育むため、あるいは帰国後の英語力の低下を防ぐためにRingleを受講されている方が多いです。
      <br />
      <br />
      また「現在留学中だが、今使っている英語を誰も正してくれないので、きちんとした英語を使っているのかわからない」「私の英語力は一定レベル以上に向上しそうになかった」というお悩みからRingleを受講し始めた方がたくさんいらっしゃいます。
    </>,
    <>
      Chắc chắn rồi! Dù là một học kỳ hay vài năm, việc đi du học là một trải nghiệm đặc biệt kết hợp giữa văn hoá và
      giáo dục, và để tận dụng tối đa trải nghiệm này đòi hỏi khả năng nói trôi chảy ngôn ngữ bạn sử dụng tại đất nước
      đó.
      <br />
      <br />
      Và kể cả những ai đã từng du học và học cách sử dụng trôi chảy ngôn ngữ khi còn ở đó, bạn vẫn nên tiếp tục mài
      giũa khả năng ngôn ngữ của mình để không đánh mất những gì đã học được.
    </>,
  ],
  question: [
    <>유학생/유학 후 귀국자는 링글을 어떻게 활용할 수 있나요?</>,
    `In either case, Ringle is here to help:`,
    's_case_lived_abroad.question',
    <>留学生/留学后归国人员将如何使用Ringle呢?</>,
    <>留學生／留學後回國者如何活用Ringle呢？</>,
    <>留学生/留学後の帰国者はRingleをどのように活用できますか？</>,
    `Dù bất kì trường hợp nào, Ringle có thể giúp bạn:`,
  ],
  bullet_1: [
    `원어민이라고 해서 모두 격조 높은 표현을 구사하는 것은 아닙니다. 링글 튜터들은 미국, 영국 명문대 출신으로 혹독한 글쓰기/토론 교육을 받고 있습니다. 발표 스크립트나 에세이 교정, 영어 토론 대비, 실전 말하기까지, 원하시는 목적에 따라 수강하며 한 단계 더 높은 영어 실력을 목표하세요.`,
    `Ringle tutors have attended top universities in the U.S. and in the U.K. and specialize in a wide variety of majors, so you can always find one that match your area of study`,
    's_case_lived_abroad.bullet_1',
    `即使是母语人群也不是所有人都能掌握高水平的表达能力。Ringle的教师均出身美英知名学府, 接受过严格的写作/讨论教育。无论是对发表脚本或是对短文进行校正, 还是准备英语讨论, 实战演讲, 只要按照自身希望的目标选择适合的课程, 您的英语实力必将得到大幅度提升。`,
    `即使是母語人士也不是所有人都能使用高格調的用法。Ringle的任課教師均來自美國、英國名校，接受嚴格的寫作／討論訓練。無論是發表腳本、糾正申論至實戰口說，請按照希望的目標聽課，將您的英語實力更升一級。`,
    `ネイティブスピーカーだからといって全員が格調の高い表現を使うわけではありません。Ringleのチューターはアメリカ、イギリスの名門大学出身で、厳しいライティング/ディスカッション教育を受けています。発表スクリプトやエッセイ校正、英語ディスカッションの準備、実践スピーキングなど、ご希望の目的に合わせて受講して一段階上の英語力を目指しましょう。`,
    `Gia sư Ringle theo học tại các trường Đại học hàng đầu ở Mỹ và Anh cũng như có chuyên môn đa dạng, nên bạn sẽ luôn tìm được một gia sư phù hợp với ngành bạn học`,
  ],
  bullet_2: [
    <>
      링글에는 다양한 전공, 학교, 경력의 튜터들이 있습니다. 유학을 희망하는 혹은 전공 분야의 튜터를 선택해 해당 분야
      에세이를 첨삭받거나, 관련 주제로 토론하는 등 깊이 있는 수업 수강이 가능합니다.
    </>,
    `Ringle tutors provide detailed feedback on all scopes of English speaking, from small technical errors like articles and prepositions to crafting elegant sentences to communicate complex ideas`,
    's_case_lived_abroad.bullet_2',
    <>
      Ringle的教师来自不同的专业和学校，拥有丰富多样的经验。希望留学或选择专业的学员可通过挑选有经验的教师,
      接受相关专业的短文校正或是通过主题讨论的方式开展深层次的学习课程。
    </>,
    <>
      Ringle有許多來自不同主修、學校、經歷的任課教師。您可選擇希望留學或主修領域的任課教師增刪申論，或透過相關主題討論等，聆聽深度課程。
    </>,
    <>
      Ringleには多様な専攻、学校、経歴のチューターがいます。留学を希望する、または専攻分野のチューターを選択してその分野のエッセイを添削してもらったり、関連テーマでディスカッションしたりするなど、深みのあるレッスンを受けることができます。
    </>,
    `Gia sư Ringle cung cấp đánh giá chi tiết về mọi mặt trong tiếng Anh giao tiếp, từ những lỗi sai nhỏ như sử dụng mạo từ và giới từ để câu văn mượt mà hơn đến biểu đạt những ý phức tạp`,
  ],
  bullet_3: [
    <>
      유학 후 귀국했거나 유학 중이라도 제대로 된 영어를 배울 기회가 적다고 느낀다면, 링글 수업으로 영어로 말할 기회를
      마련해 감각을 유지하세요. 원어민 관점에서 나의 영어가 어떤 느낌으로 다가왔는지 실시간으로 확인하며 영어 실력을
      정말 유창한 수준으로 끌어올릴 수 있습니다.
    </>,
    `Ringle tutors can distinguish between academic English and casual English and offer tips appropriate for each situation`,
    's_case_lived_abroad.bullet_3',
    <>
      留学后归国或正在留学中, 深感英语使用时间少,
      请通过Ringle获取英语会话机会维持现有语感吧。从母语教师的观点中实时感受自身英语水平, 并将其提升至非常流畅的高度。
    </>,
    <>
      就算留學歸國或正在留學中，若覺得可以好好學習英語的機會不多的話，那就利用Ringle課程取得英語口說機會並維持語感吧！您可即時確認母語人士對您的英語有什麼感覺，並將英語實力提升至流暢的水準。
    </>,
    <>
      留学後帰国したり留学中でもまともに英語を学ぶ機会が少ないと感じたら、Ringleのレッスンで英語で話す機会を設けて感覚を維持しましょう。ネイティブの観点で自分の英語がどんな感じで向上しているのかをリアルタイムで確認しながら、英語力を本当に流暢なレベルに引き上げることができます。
    </>,
    `Gia sư Ringle có thể phân biệt giữa tiếng Anh học thuật và tiếng Anh đời thường. Từ đó đưa ra những gợi ý phù hợp với mỗi tình huống`,
  ],
  quote: [
    <>
      "링글을 통해 영어를 잘하게 되면서 얻을 수 있는 기회가 무한하게 느껴졌습니다. 만날 수 있는 사람이 10배, 100배,
      1,000배가 되고, 내가 만들어나갈 수 있는 네트워크도 기하급수적으로 늘어나더라고요."
    </>,
    <>
      "Speaking English is linked to limitless amount of opportunities. It expands the circle of people I can network
      with by 10, 100, even 1,000 times and grows my network exponentially."{' '}
    </>,
    's_case_lived_abroad.quote',
    <>
      "通过Ringle将英语学好, 我感觉能够因此获得无限的机会, 能够结识比之前多10倍, 100倍甚至1000倍的人,
      而我借此所建立起的人脉网络也呈现几何式增长。"
    </>,
    `「我感覺到我的英語透過Ringle變好，並能獲得無限的機會。因此，我能夠認識比之前多10倍、100倍、1000倍的人，而我可建立的人脈網絡也加倍成長。」`,
    <>
      "Ringleを通じて英語が上手になり、得られる機会は無限に感じました。出会える人が10倍、100倍、1,000倍になり、私が作っていけるネットワークも幾何級数的に増えました。"
    </>,
    <>
      "Khả năng nói tiếng Anh mang đến cho bạn vô vàn cơ hội. Nó mở rộng vòng tròn xã hội giúp bạn kết nối với mọi người
      gấp 10, 100 hay 1000 lần và phát triển mạng lưới của mình theo cấp số nhân."{' '}
    </>,
  ],
  quote_writer: [
    `- HBS Class of 2020 / 최다혜님`,
    `- Dahye, HBS Class of 2020`,
    's_case_lived_abroad.quote_writer',
    `- HBS Class of 2020 / Ms. Choi`,
    `- HBS Class of 2020／Ms. Choi`,
    `- HBS Class of 2020 / チェ・ダヘさん`,
    `- Dahye, Trường Kinh doanh Harvard2020`,
  ],
}
export const s_case_junior = {
  who_prepare_study: [
    <Fragment>
      국제학교/유학 준비 또는
      <br />
      영어 실력 향상을 원하시는
      <br />
      <Div spanTag textSecondary>
        초중고 학생분
      </Div>
    </Fragment>,
    <>Get a head start on mastering English fluency</>,
    's_case_junior.who_prepare_study',
    <Fragment>
      国际学校/留学准备中
      <br />
      希望提升自身英语实力的
      <br />
      <Div spanTag textSecondary>
        小学/初中/高中学生
      </Div>
    </Fragment>,
    <Fragment>
      準備國際學校／留學或
      <br />
      希望提升英語實力的
      <br />
      <Div spanTag textSecondary>
        國小、國中、高中學生
      </Div>
    </Fragment>,
    <Fragment>
      インターナショナルスクール/留学準備または
      <br />
      英語力を向上したい
      <br />
      <Div spanTag textSecondary>
        小中高生
      </Div>
    </Fragment>,
    <>Khởi đầu thuận lợi cho việc sử dụng tiếng Anh thành thạo</>,
  ],
  want_higher_level: [
    <>
      유학을 가지 않아도
      <br />
      아이비리그 원어민 튜터와 대화하며
      <br />
      영어 공부의 재미를 느낍니다
    </>,
    <>Go beyond classrooms for a real world exposure</>,
    's_case_junior.want_higher_level',
    <>
      即使不去留学
      <br />
      就能够与出身常青藤联盟的母语教师对话
      <br />
      培养英语学习的兴趣。
    </>,
    <>
      即使不去留學，
      <br />
      也能與出身常春藤名校的母語任課教師對話，
      <br />
      感受學習英語的樂趣。
    </>,
    <>
      留学しなくても
      <br />
      アイビーリーグのネイティブチューターと会話しながら
      <br />
      英語の勉強の面白さを感じます。
    </>,
    <>Vượt qua những căn phòng để tiếp xúc với thế giới thực</>,
  ],
  international_school: [
    `#국제학교`,
    `#International_School`,
    's_case_junior.international_school',
    `#国际学校`,
    `#國際學校`,
    `#インターナショナルスクール`,
    `#Trường_Quốc_tế`,
  ],
  study_abroad: [
    `#영어조기교육`,
    `#Study_Abroad`,
    's_case_junior.study_abroad',
    `#英语早期教育`,
    `#英語早期教育`,
    `#英語早期教育`,
    `#Du_học`,
  ],
  kindergarten: [
    `#영어유치원`,
    `#SAT`,
    's_case_junior.kindergarten',
    `#英语幼儿园`,
    `#英語幼稚園`,
    `#英語幼稚園`,
    `#SAT`,
  ],
  native: [
    `#원어민회화`,
    `#K-12`,
    's_case_junior.native',
    `#母语教师会话`,
    `#母語人士會話`,
    `#ネイティブ会話`,
    `#K-12`,
  ],
  can_junior_ringle: [
    <>초중고 학생도 링글을 이용할 수 있나요?</>,
    `Can pre-college students in elementary, middle, or high school use Ringle?`,
    's_case_junior.can_junior_ringle',
    <>小学/初中/高中学生也可以使用Ringle吗?</>,
    <>國小、國中、高中學生也可以使用Ringle嗎？</>,
    <>小中高生もRIngleを利用することはできますか？</>,
    `Học sinh tiểu học, trung học cơ sở và phổ thông có thể sử dụng Ringle không?`,
  ],
  many_junior: [
    <>
      네, 링글 수강생 중 초중고 학생분은 대부분 기초적인 영어 회화는 낯설지 않지만, 조금 더 심도 있는 영어 대화/글쓰기
      능력을 키우고 싶으신 분들이었습니다.
      <br />
      <br />
      특히, 수강생의 부모님들은 영어 전문 강사보다 미국, 영국 명문대 진학 후 다양한 경험을 쌓아가고 있는 링글 튜터와의
      수업을 통해 "영어 실력 향상은 물론, 아이가 집에서 아이비리그 튜터와 대화하며 사고를 확장하고 영어 공부의
      동기부여와 흥미를 느끼고 있다."라는 말씀을 하셨습니다.
    </>,
    <>
      Certainly! Some of our most dedicated users are these future leaders of the world, whose grades can range anywhere
      from elementary to high school.
      <br />
      <br />
      These students may have learned English in a classroom, have attended English-speaking schools, or lived in
      English-speaking countries.
      <br />
      <br />
      Ringle offers an environment where students can go beyond classrooms and have an engaging conversation with native
      speakers.
      <br />
    </>,
    's_case_junior.many_junior',
    <>
      当然, Ringle学员中的小学/初中/高中学生, 他们大部分对基础英语会话并不陌生,
      很多人都是希望能够培养更具深度的英语对话/写作能力。
      <br />
      <br />
      特别的是学员的家长们发现比起一般英语专业的讲师,
      与出身美英知名学府拥有丰富经验的Ringle教师们一起学习‘不仅能提升孩子们的英语水平,
      而且让他们足不出户就能和出身常青藤联盟的教师们进行对话, 在拓展思维的同时找到学习英语的动力及兴趣’。
    </>,
    <>
      當然，Ringle學生中的國小、國中、高中學生大部分對基礎英語會話並不陌生，但希望培養更具深度的英語對話／寫作能力。
      <br />
      <br />
      尤其，比起英語專業講師，與來自美國、英國名校並累積豐富經驗的Ringle任課教師上課，學生家長們表示：「不僅能提升英語實力，而且孩子在家中就能和出身常春藤名校的任課教師們對話，拓展思維的同時找到了學習英語的動機及樂趣。」
    </>,
    <>
      はい。Ringle受講生のうち、小中高生の大半は基礎的な英会話に慣れていませんが、もう少し踏み込んだ英会話/ライティング能力を身につけたいという方々でした。
      <br />
      <br />
      特に、受講生の親は英語の専門講師よりもアメリカやイギリスの名門大学進学後に多様な経験を積んでいるRingleのチューターとのレッスンを通じて「英語力の向上はもちろん、子供が家でアイビーリーグのチューターと会話しながら思考を広げ、英語の勉強のモチベーションアップと興味を感じている」とおっしゃいました。
    </>,
    <>
      Được chứ! Những học sinh chăm chỉ nhất của chúng tôi - những lãnh đạo tương lai, có thể đang học ở bất kì cấp nào.
      <br />
      <br />
      Những học sinh này đã học tiếng Anh trên trường, tham gia các lớp học nói và có cả những học sinh sống ở các nước
      nói tiếng Anh.
      <br />
      <br />
      Ringle mang đến môi trường nơi mà học sinh vượt ra khỏi giới hạn của lớp học để luyện nói và giao tiếp tốt hơn với
      người bản xứ.
      <br />
    </>,
  ],
  question: [
    <>주니어 수강생은 링글을 어떻게 활용하면 좋을까요?</>,
    `Here’s how you can make the most out of Ringle as a K-12 learner:`,
    's_case_junior.question',
    <>青少年学员应该如何使用Ringle呢?</>,
    <>青少年學生如何使用Ringle呢？</>,
    <>ジュニア受講生はRingleをどのように活用すればいいですか？</>,
    `Đây là cách các học sinh K-12 có thể học tập tốt nhất với Ringle:`,
  ],
  bullet_1: [
    `뉴욕타임즈 제휴 교재를 비롯한 다양한 교재를 활용한 수업으로 튜터와 대화하며 영어로 생각을 표현하는 연습을 해보세요. 튜터의 실시간 피드백과 교정으로 의견을 정확하게 영어로 표현하는 방식을 익힐 수 있습니다.`,
    `Upload essays, presentations, or any other learning materials from your classes to practice what you learned`,
    's_case_junior.bullet_1',
    `请选用包含纽约时报合作教材在内的多种教材开展的课程, 通过和教师的对话练习表达自身想法吧。同时教师提供的实时反馈及纠正内容, 有助于掌握更为准确的英语表达方式。`,
    `請利用包含紐約時報合作教材在內的各種教材上課，和任課教師對話並練習使用英語表達自身想法。可利用任課教師提供的即時意見回饋及糾正，熟悉精確使用英語表達意見的方式。`,
    `ニューヨークタイムズ提携教材をはじめ、多様な教材を活用したレッスンでチューターと会話しながら英語で考えを表現する練習をしてみましょう。チューターのリアルタイムフィードバックと校正で、意見を正確に英語で表現する方法を身につけることができます。`,
    `Tải lên các bài luận, thuyết trình hoặc bất cứ tài liệu học tập nào ở lớp học mà bạn muốn luyện tập thêm`,
  ],
  bullet_2: [
    <>
      영어 토론 준비, 에세이나 발표자료 교정 등 특정 주제/자료에 대한 교정이 필요하신 경우 자유주제 수업으로 튜터와
      자료를 함께 보며 상황과 목적에 맞는 수업 진행이 가능합니다.
    </>,
    `Speak with a tutor attending your dream school and use the lesson to practice English speaking and to ask questions about their experience`,
    's_case_junior.bullet_2',
    <>
      如需准备英语讨论或对短文/发表材料等特定主题/资料进行校正时, 可通过自由主题课程与教师共同查看,
      开展符合情况及有目的性的针对化课程。
    </>,
    <>
      如需準備英語討論或針對申論、發表資料等特定主題／資料進行糾正時，可透過自由主題課程，與任課教師共同確認資料，進行符合情況及目的的課程。
    </>,
    <>
      英語でのディスカッション準備、エッセイや発表資料の校正など、特定のテーマ/資料の校正が必要な場合は、自由テーマレッスンでチューターと資料を一緒に見ながら、状況と目的に合わせたレッスンが可能です。
    </>,
    `Trò chuyện cùng gia sư đang học tại ngôi trường mơ ước của bạn và tận dụng buổi học này để hỏi về trải nghiệm của họ`,
  ],
  bullet_3: [
    <>
      다양한 전공과 배경을 가진 튜터들 중 원하는 튜터를 선택하여 수업을 진행해보세요. 여러 명의 튜터와 다양한 주제로
      대화하며, 실력 향상은 물론 영어 자체에 대한 흥미를 키우고 넓은 시야를 가질 수 있습니다.
    </>,
    `Speak with a tutor pursuing a major you’re curious about or rocking the career you dreamed of to get an insider view of their world`,
    's_case_junior.bullet_3',
    <>
      在具备不同专业及背景的众多教师中, 选择自己满意的课程。与多位教师进行不同主题的对话, 不仅能提升自身英语能力,
      同时还能扩展视野培养对英语学习的兴趣。
    </>,
    <>
      從具備不同主修及背景的任課教師中，選擇自己希望的任課教師上課吧！與多位任課教師使用各種主題對話，不僅能夠提升實力，還能培養對於英語的興趣及拓展視野。
    </>,
    <>
      多様な専攻と背景を持つチューターたちの中から好きなチューターを選んでレッスンを行いましょう。複数のチューターと多様なテーマで会話しながら、実力向上はもちろん、英語そのものに対する興味を高めて広い視野を持つことができます。
    </>,
    `Nói chuyện cùng gia sư đang theo đuổi chuyên ngành bạn muốn tìm hiểu thêm hoặc chuẩn bị cho sự nghiệp trong mơ của bạn bằng những góc nhìn từ người trong cuộc`,
  ],
  quote: [
    <>"저희 아들이 하버드 출신 튜터를 만나 대화를 나눈 적은 없었죠. 한국에서 이런 기회를 잡기는 어렵습니다."</>,
    <>
      "I didn't imagine that my son would get a chance to talk with a Harvard graduate. It's a rare opportunity,
      especially outside the U.S."{' '}
    </>,
    's_case_junior.quote',
    <>"我的儿子以前没有和出身哈佛的教师一起对话的经历, 说实话这种机会在韩国并不多见。"</>,
    `「我兒子不曾和哈佛出身的任課教師對話，在韓國很難有這種機會。」`,
    <>
      "私の息子はハーバード出身のチューターに出会って会話したことがありませんでした。韓国でこんなチャンスを掴むのは難しいです。"
    </>,
    <>
      Tôi không nghĩ là con trai mình sẽ có cơ hội nói chuyện cùng một sinh viên tốt nghiệp từ Harvard. Đây là một cơ
      hội hiếm có, đặc biệt khi bạn không ở Mỹ.
    </>,
  ],
  quote_writer: [
    `- Nextrans 대표 / 홍상민님`,
    `- Sangmin Hong, CEO of Nextrans`,
    's_case_junior.quote_writer',
    `- Nextrans代表 / Mr. Hong`,
    `- Nextrans代表／Mr. Hong`,
    `- Nextrans 代表 / ホン・サンミンさん`,
    `- Sangmin Hong, Nextrans CEO`,
  ],
}
export const s_case_beginner = {
  uncomfortable: [
    <>
      <Div spanTag textSecondary>
        초중급 실력
      </Div>
      으로
      <br />
      고급 영어를
      <br />
      구사하고 싶으신 분
    </>,
    <Fragment>Lay the groundwork for advanced discussions</Fragment>,
    's_case_beginner.uncomfortable',
    <>
      <Div spanTag textSecondary>
        拥有初/中级英语实力
      </Div>
      <br />
      渴望学习掌握
      <br />
      高等英语的学员
    </>,
    <>
      希望以
      <Div spanTag textSecondary>
        初／中級英語實力
      </Div>
      <br />
      述說
      <br />
      高級英語的人
    </>,
    <>
      <Div spanTag textSecondary>
        初中級の実力
      </Div>
      で
      <br />
      高級英語を
      <br />
      駆使したい方
    </>,
    <Fragment>Đặt nền tảng các buổi thảo luận nâng cao</Fragment>,
  ],
  still_uncomfortable: [
    <>
      원어민 튜터의 실시간 교정으로
      <br />
      영어 실력을 빠르게 성장시켜
      <br />
      영어가 훨씬 편해집니다
    </>,
    <Fragment>
      Let’s first get you comfortable
      <br />
      with the idea of speaking in English
    </Fragment>,
    's_case_beginner.still_uncomfortable',
    <>
      通过母语教师实时纠正
      <br />
      快速提升您的英语水平
      <br />
      让英语学习游刃有余
    </>,
    <>
      透過母語任課教師的即時糾正，
      <br />
      快速提升英語實力，
      <br />
      讓英語變得不再困難重重。
    </>,
    <>
      ネイティブチューターのリアルタイム校正で
      <br />
      英語力を素早く成長させて
      <br />
      英語がはるかに楽になります。
    </>,
    <Fragment>
      Trước hết hãy cùng thoải mái
      <br />
      với ý tưởng nói tiếng Anh
    </Fragment>,
  ],
  elementary: [
    `#영어초중급`,
    `#Beginner_English`,
    's_case_beginner.elementary',
    `#英语初/中级`,
    `#英語初／中級`,
    `#英語初中級`,
    `#Tiếng_Anh_cơ_bản`,
  ],
  fundamental: [
    `#기초영어`,
    `#Intermediate_English`,
    's_case_beginner.fundamental',
    `#基础英语`,
    `#基礎英語`,
    `#基礎英語`,
    `#Tiếng_Anh_trung_cấp`,
  ],
  level: [
    `#초급레벨`,
    `#Casual_Conversations`,
    's_case_beginner.level',
    `#初级水平`,
    `#初級程度`,
    `#初級レベル`,
    `#Giao_tiếp_hằng_ngày`,
  ],
  daily_talk: [
    `#일상회화`,
    `#Basics`,
    's_case_beginner.daily_talk',
    `#日常会话`,
    `#日常會話`,
    `#日常会話`,
    `#Kiến_thức_căn_bản`,
  ],
  how_good_to_ringle: [
    `영어 초중급 실력인데, 링글을 잘 이용할 수 있을까요?`,
    `Can people at a beginner or intermediate level of English also take Ringle lessons?`,
    's_case_beginner.how_good_to_ringle',
    `英语只有初/中级水平, 适用于Ringle吗?`,
    `英語只有初／中級程度，能夠活用Ringle嗎？`,
    `英語は初中級レベルですが、Ringleを上手く利用できるでしょうか？`,
    `Với trình độ cơ bản hoặc trung cấp có thể tham gia buổi học của Ringle không?`,
  ],
  many_beginners: [
    <>
      네, 링글 수강생의 50% 이상은 초중급 레벨에 속하는 분들입니다. 초중급 레벨 수강생들은 20분, 40분 동안 원어민과
      대화할 수 있을지, 아이비리그 튜터의 영어가 너무 어렵지는 않을지 등을 고민하시기도 합니다.
      <br />
      <br />
      그러나 실제 초중급 레벨로 시작하여 링글을 수강하고 계신 분들은, "시간이 생각보다 훨씬 빠르게 지나간다.", "새로운
      표현/어휘, 발음, 문법 등 원하는 영역에 맞춰 수업이 진행된다.", "무엇보다 내가 말한 영어를 눈으로 보며 실시간으로
      교정을 확인할 수 있어, 훨씬 더 빠른 영어 실력의 성장을 체감한다."라고 말씀하십니다. 영어 실력의 정체감을 느끼거나,
      한 단계 더 높은 영어 실력을 목표하시는 초중급 수강생이시라면 링글을 시작해보세요!
    </>,
    <>
      They sure can! Over half of Ringle customers speak beginner or intermediate level English, meaning they do not
      have much problem with reading, but feel less comfortable with speaking and writing.
      <br />
      <br />
      If you are a beginner or intermediate level speaker, you might be worried that you lack the fluency to talk to a
      native speaker for 20 or 40 minutes straight.
    </>,
    's_case_beginner.many_beginners',
    <>
      当然, Ringle学员中超过50%属于初/中英语水平。因此必然出现诸如初/中水平的学生在20分种或40分钟的课程期间,
      能否和母语教师进行良好的对话沟通以及出身常青藤联盟的教师们使用的英语会不会难以理解等担心的问题。
      <br />
      <br />
      但是, 在实际课程中英语水平初/中水平的学员们普遍感到‘时间比想象中过得快’, ‘可以针对新学习的表达方式/词汇,
      发音及语法等希望的部分进行定制型课程’以及‘最重要的是能够实时确认对自身英语问题的纠正,
      切实体会到英语实力的快速提升’等积极作用。想要了解自身英语的整体实力或以更高水平的英语作为学习目标的初/中水平的学员们,
      请尽快开始您的Ringle课程吧!
    </>,
    <>
      當然，Ringle學生之中超過50%屬於初／中級程度。初／中級程度的學生也很煩惱20分鐘或40分鐘期間能否和母語人士對話，或擔心來自常春藤名校的任課教師的英語會不會太難。
      <br />
      <br />
      但實際上以初／中級程度的英語開始聆聽Ringle的學生們表示：「時間比想像中快很多。」、「可以針對全新用法／詞彙、發音、文法等希望的領域進行課程。」、「最重要的是，能夠親眼確認我說的英語即時獲得糾正，確實體會到英語實力快速提升。」若您是感覺到英語實力停滯不前或想將英語實力提升一級的初／中級學生，那就開始您的Ringle課程吧！
    </>,
    <>
      はい。Ringle受講生の50%以上は初級レベルの方です。初中級レベルの受講生は20分、40分間ネイティブと会話できるか、アイビーリーグのチューターの英語が難しすぎないかなど、悩まれたりもします。
      <br />
      <br />
      しかし、実際に初中級レベルから始めてRingleを受講している方は「時間が思ったよりはるかに早く過ぎる」「新しい表現/語彙、発音、文法など希望する分野に合わせてレッスンが進む」「何よりも自分が話した英語を目で見ながらリアルタイムで校正を確認することができ、はるかに速い英語力の成長を体感する」とおっしゃいます。英語力の停滞感を感じたり、もう一段階高い英語力を目指す初中級の方なら、Ringleを始めましょう！
    </>,
    <>
      Tất cả đều có thể học! Hơn một nửa học viên Ringle có kỹ năng nói cơ bản hoặc trung cấp, họ không có vấn đề về
      việc đọc nhưng chưa hoàn toàn thoải mái trong việc nói và viết.
      <br />
      <br />
      Nếu kỹ năng nói ở mức cơ bản hoặc trung cấp, bạn có thể đang lo lắng về khả năng sử dụng tiếng Anh một cách trôi
      chảy khi nói chuyện với người bản xứ liên tục trong 20 hoặc 40 phút.
    </>,
  ],
  question: [
    <>영어 초중급자는 링글을 어떻게 활용해야 할까요?</>,
    `Fear not! Here are some ways that Ringle can be catered to your needs of honing the basics and getting your feet wet with English speaking:`,
    's_case_beginner.question',
    <>英语初/中水平者应该如何使用Ringle呢?</>,
    <>英語初／中級者如何活用Ringle呢？</>,
    <>英語の初中級者はRingleをどのように活用すべきですか？</>,
    `Đừng sợ! Đây là một số cách Ringle làm để đáp ứng nhu cầu trau dồi kiến thức và làm quen với việc nói tiếng Anh của bạn:`,
  ],
  bullet_1: [
    <>
      나와 맞는 튜터와 정기적으로 수업해보세요. 매번 새로운 튜터를 만나는 것이 조금은 부담스럽다면, 내가 원하는
      수업방식과 나의 영어 고민을 이해해주는 2-3명의 튜터와 주기적으로 수업하며 영어 실력을 향상시키세요.
    </>,
    `Try having regular lessons with one tutor you like, instead of meeting with a different tutor each time, so that the familiar face will help ease any existing tension or fear about English speaking`,
    's_case_beginner.bullet_1',
    <>
      请坚持定期和适合自己的教师一起学习吧。 如果每周要和全新的教师一起学习让您感到负担的话,
      可定期和2-3位能够按照本人希望的授课形式进行课程安排且能够理解学员英语学习烦恼的教师一起学习, 提升您的英语水平吧。
    </>,
    <>
      請定期和適合自己的任課教師學習。如果每週要和全新的任課教師上課讓您感到負擔的話，那就定期和2～3位能夠按照您希望的上課方式並理解您的英語煩惱的任課教師上課，提升英語實力吧！
    </>,
    <>
      自分に合ったチューターと定期的にレッスンしましょう。毎回新しいチューターに出会うのは少し気が重いなら、あなたが望むレッスン方式とあなたの英語の悩みを理解してくれる2-3人のチューターと周期的にレッスンをして、英語力を向上させましょう。
    </>,
    `Hãy học đều đặn với một gia sư bạn yêu thích thay vì học với những gia sư khác nhau. Sự quen thuộc sẽ giúp bạn giảm căng thẳng và lo sợ trong quá trình luyện nói tiếng Anh`,
  ],
  bullet_2: [
    `나에게 익숙한 주제의 교재, 혹은 일상 대화 주제로 수업한 후, 조금 더 난이도 있는 교재에 도전하세요. 하나의 교재로 여러 번 수업하며 교재와 관련된 영어 표현을 완전히 익히는 방법도 있습니다.`,
    `Try using the same lesson materials for 2 or 3 lessons so you can really absorb all the relevant expressions`,
    's_case_beginner.bullet_2',
    `在使用过自身熟悉的主题教材或以日常对话为主题进行课程后, 可逐渐挑战具有一定难度的内容进行学习。同时还有针对同一教材反复学习, 将其中的英语表达方式彻底掌握的学习形式供您选择。`,
    `使用您熟悉的主題教材或以日常對話為主題進行課程後，逐漸挑戰難度更高的教材吧！使用一個教材進行多次課程，完全熟悉與教材相關的用法也是一個方法。`,
    `あなたにとって身近なテーマの教材や日常会話をテーマにレッスンした後、より難易度の高い教材に挑戦しましょう。一つの教材で何度もレッスンを行いながら、教材に関する英語表現を完全に身につける方法もあります。`,
    `Thử dùng cùng một tài liệu học cho 2, 3 buổi học liên tục để có thể nắm rõ tất cả những cách diễn đạt tương tự`,
  ],
  bullet_3: [
    <>
      예습, 복습을 적극 활용하세요. 수업 전 예습을 위해 제공되는 다양한 자료를 미리 공부하고, 수업이 끝나면 녹음 파일과
      스크립트, 수업 노트를 언제 어디서든 다시 확인해 배운 내용을 완전히 내 것으로 만들 수 있습니다.
    </>,
    `Take advantage of tutor’s detailed feedback, audio-recording of the lesson, and AI analysis to further make notes of your room for improvement, and apply them at your next lesson!`,
    's_case_beginner.bullet_3',
    <>
      请积极对学习内容进行预习及复习。上课前对提供的资料进行预习, 同时课程结束后可对提供的录音文件, 脚本,
      课程笔记等进行确认及复习, 确保课堂中学习的内容能够真正的融会贯通。
    </>,
    <>
      請積極活用預習及複習。課程開始前事先預習各種資料，課程結束後隨時隨地重新確認錄音檔、腳本、課堂筆記，可完全將所學的內容變成您的東西。
    </>,
    <>
      予習、復習を積極的に活用しましょう。レッスン前に予習のために提供される多様な資料で事前に勉強し、レッスン後は録音ファイル、スクリプト、レッスンノートをいつでもどこでも確認して学んだ内容を完全に自分のものにすることができます。
    </>,
    `Hãy tận dụng phản hồi chi tiết của gia sư, bản ghi âm buổi học và phân tích từ AI để ghi lại những điều cần cải thiện và áp dụng những điều đó cho buổi học tiếp theo!`,
  ],
  quote: [
    <>
      다른 플랫폼에서는 영어로 말해도 말한 것이 사라지는 느낌이었는데, 링글에서는 말한게 저에게 남는 느낌이에요. 링글을
      더 일찍 알았다면, 지금보다 영어를 더 잘했을 것 같아요.
    </>,
    <>
      "My English would have been better if I had started Ringle earlier." <br />
    </>,
    's_case_beginner.quote',
    <>
      在其他平台学习英语时, 感觉虽然在使用英语但没有任何的存在感,
      而在Ringle我觉得我说的每一句都清晰可见印象深刻。如果早一点知道Ringle, 我想我的英语水平应该不止如此。
    </>,
    <>
      在其他平台學習英語時，感覺我說的英語一說即逝，但在Ringle，我說的英語都留下來了。如果早一點知道Ringle，我想我現在的英語實力應該不止如此。
    </>,
    <>
      他のプラットフォームでは英語で言っても話したことが消える感じでしたが、Ringleでは話したことが自分に残る感じです。Ringleをもっと早く知っていたら、今よりもっと英語が上手だったと思います。
    </>,
    <>
      "Tiếng Anh của tôi có lẽ đã tốt hơn nếu tôi bắt đầu với Ringle sớm hơn." <br />
    </>,
  ],
  quote_writer: [
    `- 영문과 이소정님`,
    `- Sojung, English major`,
    's_case_beginner.quote_writer',
    `- 英文系 Ms Lee`,
    `- 英文系 Ms. Lee`,
    `- 英文科 イ・ソジョンさん`,
    `- Sojung, chuyên ngành Tiếng Anh`,
  ],
}
export const s_case_live_abroad = {
  who_live_abroad: [
    <>
      현재{' '}
      <Div spanTag textSecondary>
        해외에 거주
      </Div>
      하시면서
      <br />
      영어 실력의 한계를 느껴
      <br />
      빠른 성장을 원하는 분
    </>,
    <>Go beyond basic conversations</>,
    's_case_live_abroad.who_live_abroad',
    <>
      目前
      <Div spanTag textSecondary>
        虽在
      </Div>
      海外生活
      <br />
      但是感觉英语水平进入瓶颈期
      <br />
      希望能够快速进步的人士
    </>,
    <>
      目前
      <Div spanTag textSecondary>
        居住海外
      </Div>
      ，
      <br />
      但感覺英語實力達極限，
      <br />
      希望能快速進步的人
    </>,
    <>
      現在{' '}
      <Div spanTag textSecondary>
        海外に居住
      </Div>
      していて
      <br />
      英語力の限界を感じて
      <br />
      早く成長したい方
    </>,
    <>Vượt qua những cuộc trò chuyện cơ bản</>,
  ],
  precise_comm: [
    <>
      매일 영어를 사용하지만
      <br />
      조금 더 정확하고 세련된
      <br />
      커뮤니케이션 능력을 목표합니다
    </>,
    <>Unlock the missing fluency to immerse yourself in the new culture</>,
    's_case_live_abroad.precise_comm',
    <>
      虽然每天都在使用英语
      <br />
      但还是希望能够掌握更为准确精炼的
      <br />
      沟通交流能力
    </>,
    <>
      雖然每天都在使用英語，
      <br />
      但希望能夠培養更精確幹練的
      <br />
      溝通能力。
    </>,
    <>
      毎日英語を使用しますが
      <br />
      もう少し正確で洗練された
      <br />
      コミュニケーション能力を目指します。
    </>,
    <>Hoàn thiện khả năng nói trôi chảy để hòa nhập với nền văn hóa mới</>,
  ],
  foreign_job: [
    `#해외거주`,
    `#Casual_conversations`,
    's_case_live_abroad.foreign_job',
    `#海外居住`,
    `#海外居住`,
    `#海外居住`,
    `#Hội_thoại_hằng_ngày`,
  ],
  business_eng: [
    `#원어민표현`,
    `#Idioms`,
    's_case_live_abroad.business_eng',
    `#母语表达`,
    `#母語人士用法`,
    `#ネイティブ表現`,
    `#Thành_ngữ`,
  ],
  student: [`#영어회화`, `#Expressions`, 's_case_live_abroad.student', `#英语会话`, `#英語會話`, `#英会話`, `#Mẫu_câu`],
  live_abroad: [
    `#실전영어`,
    `#Real_Life_English`,
    's_case_live_abroad.live_abroad',
    `#实战英语`,
    `#實戰英語`,
    `#実践英語`,
    `#Tiếng_Anh_trong_đời_sống`,
  ],
  other_than_korea: [
    `한국이 아닌 다른 나라에서도 링글을 수강하나요?`,
    `Will I find Ringle helpful if I currently live or have previously lived in an English-speaking country?`,
    's_case_live_abroad.other_than_korea',
    `不在韩国身处其他国家也可以使用Ringle吗?`,
    `即使不在韓國，而是在其他國家也能使用Ringle嗎？`,
    `日本ではない他国でもRingleを受講していますか？`,
    `Liệu Ringle có ích với tôi không nếu tôi đang sống hoặc đã từng sống ở các nước nói tiếng Anh?`,
  ],
  english_not_good: [
    <>
      네, 링글 수강생의 25% 정도는 해외에 거주하고 계십니다. 특히 "해외에 살아도 실제로 영어를 사용할 기회가 생각보다
      많지 않다.", "외국에 살고 있지만 아직 영어가 완벽하지 않아 한계를 느꼈다.", "나의 영어를 교정해주는 사람이 없어
      제대로 영어를 구사하고 있는지 잘 모르겠다." 등의 고민으로 링글을 수강하는 분들이 많습니다.
      <br />
      <br />
      어느 정도 영어로 소통은 가능하지만, 내가 표현하고자 하는 바가 영어로 인해 다 전달되지 못해 영어 실력의 한계를
      느끼신다면, 링글과 함께 정말 유창한 영어 실력을 목표해보세요.
    </>,
    <Fragment>
      Definitely! 25% of our customers are overseas residents. Many of them find it surprisingly difficult to improve
      their English, either because they interact with other immigrants of similar fluency levels or because their
      native speaker friends or colleagues do not correct their English.
      <br />
      <br />
      Interacting with locals to make friends, get advice, and experience the culture fully requires more than basic
      English.
    </Fragment>,
    's_case_live_abroad.english_not_good',
    <>
      当然, 目前Ringle学员中有将近25%居住在海外。其中深感‘即使生活在国外,
      但实际使用英语的机会并没有想象中那么多’以及‘虽然在外国, 但英语水平还不够完美, 感到进入了上升瓶颈期’,
      ‘平时并没有人帮我纠正英文, 因此我也不能确定我的英语使用是否正确’的人其实很多。
      <br />
      <br />
      虽然具备一定程度的英语沟通能力, 但还是会出现英语表达上的限制, 感到英语实力上升存在瓶颈,
      那么请携手Ringle一起实现流畅英语水平的目标吧。
    </>,
    <>
      當然，目前Ringle有25%左右的學生居住海外。有許多學生因為煩惱「即使在海外生活，但實際使用英語的機會並沒有想像中那麼多。」、「雖然住國外，但是英語尚不完美，感覺達到了極限。」、「因為沒人糾正我的英語，我不曉得我說的英語是否正確。」而聆聽Ringle的課程。
      <br />
      <br />
      雖然具備一定程度的英語溝通能力，但因為無法使用英語完全表達您的意思，而感覺到英語實力極限的話，那就與Ringle一起實現流暢英語實力的目標吧。
    </>,
    <>
      はい。Ringle受講生の約25%は海外に居住しています。特に「海外に住んでいても実際に英語を使う機会が思ったより多くない」「外国に住んでいるが、まだ英語が完璧ではなく限界を感じた」「私の英語を正してくれる人がいないので、きちんとした英語を駆使しているかどうかわからない」などのお悩みでRingleを受講する方が多いです。
      <br />
      <br />
      ある程度英語でのコミュニケーションは可能ですが、自分が表現したいことを英語ですべて伝えられず、英語力の限界を感じているなら、Ringleと共に流暢な英語を目指しましょう。
    </>,
    <Fragment>
      Chắc chắn rồi! 25% học viên của chúng tôi định cư ở nước ngoài. Rất nhiều người trong số họ gặp khó khăn trong
      việc cải thiện tiếng Anh. Có thể là vì họ chỉ tiếp xúc với những người định cư với trình độ tiếng Anh giống họ
      hoặc những người bạn bản xứ không sửa lỗi tiếng Anh cho họ.
      <br />
      <br />
      Tiếp xúc với người bản xứ để kết bạn, nhận lời khuyên và trải nghiệm nền văn hoá một cách trọn vẹn đòi hỏi trình
      độ tiếng Anh hơn mức cơ bản.
    </Fragment>,
  ],
  question: [
    <>해외 거주자인데 링글이 제게 어떻게 도움이 될까요?</>,
    `Here’s how Ringle can get you to the next level of fluency:`,
    's_case_live_abroad.question',
    <>作为海外居住者Ringle能带给我什么帮助?</>,
    <>我是海外居住者，Ringle能帶給我什麼幫助？</>,
    <>海外居住者ですが、Ringleは私にどう役立ちますか？</>,
    `Đây là cách Ringle nâng cấp mức độ thành thạo của bạn:`,
  ],
  bullet_1: [
    <>
      외국에 살아도 나의 영어를 교정받을 수 있는 상황은 많지 않습니다. 링글 수업으로 원어민 관점에서 내 표현이 어색한지,
      그렇다면 대체할 표현은 무엇이 있을지, 자주 하는 문법 실수가 있거나 발음이 어색하지는 않은지 등 원어민 튜터에게
      구체적이고 실질적인 피드백과 교정을 받아보세요.
    </>,
    `Ringle tutors come from a diverse background so you can select the ones that share yours, or are from a background that you’re curious about`,
    's_case_live_abroad.bullet_1',
    <>
      即使身处国外, 但对自身英语进行纠正的机会并不是很多。通过Ringle课程从母语教师的角度了解自身英语表达中的生涩之处,
      学习针对以上问题的替代表达方法。洞察自身习惯性语法错误, 发音偏差等问题, 从母语教师处获得具体实用的反馈及纠正吧。
    </>,
    <>
      雖然住在國外，但自身英語能夠獲得糾正的機會並不多。透過Ringle的課程，以母語人士的觀點確認您的用法是否不自然、那可以使用什麼用法、是否有常犯的文法錯誤或發音不自然等，取得母語任課教師具體且實質的意見回饋及糾正吧！
    </>,
    <>
      外国に住んでいても、自分の英語を正してもらえることは多くありません。Ringleのレッスンでネイティブの観点から自分の表現が不自然なのか、その場合代替する表現は何があるのか、よくする文法ミスや発音が不自然ではないかなど、ネイティブチューターに具体的かつ実質的なフィードバックと校正をしてもらいましょう。
    </>,
    `Gia sư Ringle có những nền tảng khác nhau. Bạn nên chọn người chia sẻ cùng một nền tảng giống bạn hoặc người có câu chuyện mà bạn tò mò muốn hiểu thêm.`,
  ],
  bullet_2: [
    <>
      발음 교정, 문법, 고급 영어 표현 등 구체적인 요청사항을 적어 맞춤 수업을 진행하세요. 매 수업마다 원하는 튜터와
      교재로, 원하는 수업방식을 설정해 효율적인 수업을 진행할 수 있습니다.
    </>,
    `Unlike native speaker friends who might feel sheepish about correcting your English, Ringle tutors will point out every mistake in grammar, pronunciation and vocabulary, and offer a more fluent paraphrasing`,
    's_case_live_abroad.bullet_2',
    <>
      请填写发音矫正, 语法, 高级英语表达等具体要求事项, 以便能够开展更具针对性的课程。每堂课都可选择满意的教师及教材,
      设定具体的课程形式, 有助于课程能够更加有效进行。
    </>,
    <>
      請寫下發音矯正、文法、高級英語用法等具體要求事項，進行客製化課程吧！每堂課程都可選擇您希望的任課教師及教材，設定希望的上課方式，進行有效率的課程。
    </>,
    <>
      発音矯正、文法、高級英語表現など、具体的なリクエスト事項を書いてカスタマイズレッスンを行いましょう。レッスンごとに希望するチューターと教材で、希望するレッスン方式を設定して効率的なレッスンを行うことができます。
    </>,
    `Những người bạn bản xứ có thể sẽ thấy ngại khi chỉnh phát âm cho bạn, nhưng gia sư Ringle sẽ chỉ ra những lỗi sai trong ngữ pháp, phát âm, từ vựng và giúp bạn chỉnh câu trôi chảy hơn`,
  ],
  bullet_3: [
    <>
      다양한 교재를 활용해 튜터와 대화하며 문장력과 표현력을 넓혀보세요. 어떤 상황에서도 내 생각을 영어로 명확히
      전달하는 능력을 키울 수 있습니다. 또한 여러 명의 튜터와 수업하며 다양한 배경을 가진 튜터들과 생각을 공유할 수도
      있습니다.
    </>,
    `Ringle tutors also teach the cultural contexts of various native expressions so that you can start using them at the right place and time`,
    's_case_live_abroad.bullet_3',
    <>
      请选用多样化的教材,
      和教师们展开对话开拓您的写作能力及表达能力吧。提升无论在何种情况下都能灵活运用英语表达自身想法的能力。此外可选择不同教师的课程和具有丰富知识背景的教师们分享您的想法。
    </>,
    <>
      請活用各種教材並和任課教師對話，拓展您的句子與用法實力。您可培養無論在什麼情況下，都能使用英文明確表達想法的能力。此外，您也可與數名任課教師上課，與具有豐富背景的任課教師們分享您的想法。
    </>,
    <>
      多様な教材を活用してチューターと会話しながら、文章力と表現力を高めましょう。どんな状況でも自分の考えを英語で明確に伝える能力を育みます。また、複数のチューターとレッスンをしながら、さまざまな背景を持つチューターと考えを共有することもできます。
    </>,
    `Gia sư Ringle hướng dẫn những cách diễn đạt khác nhau đặt trong bối cảnh về văn hoá để giúp bạn sử dụng chúng đúng nơi và đúng thời điểm`,
  ],
  quote: [
    <>
      "미국에 와서 사는 사람들 중에 영어로부터 자유로울 수 있는 분은 사실 많지 않은 것 같아요. 그래서 저의 영어를 조금
      더 파인튜닝 할 수 있는 그런 서비스를 찾았습니다."
    </>,
    <>
      "As an immigrant myself, I don’t think I’ve met a lot of other immigrants who are completely free of concerns when
      it comes to English. So I was looking for a service that can help me fine-tune my English."{' '}
    </>,
    's_case_live_abroad.quote',
    <>"其实来到美国生活的人当中无法自由使用英语的人还有很多。为了让我的英语水平能够更加精准, 我找到了Ringle。"</>,
    `「其實來到美國生活的人之中，有很多人還是無法自由使用英語，我也尋找了可以改善我的英語的服務。」`,
    <>
      "アメリカに来て暮らす人の中で、英語から自由になれる方は実際多くないようです。なので私の英語がもう少しファインチューニングできる、そんなサービスを見つけました。"
    </>,
    <>
      "Bản thân tôi là một người nhập cư và đã gặp nhiều người nhập cư khác cũng lo lắng về vấn đề tiếng Anh cuả họ.
      Chính vì vậy tôi đã tìm kiếm một dịch vụ có thể giúp tôi cải thiện tiếng Anh của mình."{' '}
    </>,
  ],
  quote_writer: [
    `- 정남원 님`,
    `- Namwon`,
    's_case_live_abroad.quote_writer',
    `- Mr. Jeong`,
    `- Mr. Jeong`,
    `- チョン・ナムウォンさん`,
    `- Namwon`,
  ],
}
export const s_trial = {
  noTimeBanner: {
    text_1: [
      <>
        <div>
          <Span bold>바로 구매하면 추가 혜택을 드립니다.</Span>
        </div>
        <div>
          <Span size={14}>1만 원 추가할인+최대 3회 수업권</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            첫 수업 후 환불 요청 시, 100% 환불 제공
          </Span>
        </div>
      </>,
      <>
        Buy now without a Free Trial Lesson
        <br />
        And get up to three free lesson credits
      </>,
      's_trial.noTimeBanner.text_1',
      <>
        <div>
          <Span bold>立即购买可享受额外优惠。</Span>
        </div>
        <div>
          <Span size={14}>$8.62额外优惠+最多四次课程券</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            首次课程后申请退款时, 可100%返还
          </Span>
        </div>
      </>,
      <>
        <div>
          <Span bold>立即購買就送您額外優惠。</Span>
        </div>
        <div>
          <Span size={14}>$8.62額外折扣+最多4次課程券</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            首次課程後申請退款時，可全額退款
          </Span>
        </div>
      </>,
      <>
        <div>
          <Span bold>今すぐご購入で追加特典を差し上げます。</Span>
        </div>
        <div>
          <Span size={14}>$8.62追加割引+最大3回レッスンチケット</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-500" size={isMobile ? 10 : 14}>
            初回レッスン後にご満足いただけない場合、全額返金
          </Span>
        </div>
      </>,
      <>
        Mua ngay không cần buổi học thử miễn phí
        <br />
        Cơ hội nhận được tới 3 thẻ học miễn phí
      </>,
    ],
    go: [
      <>바로 구매하기</>,
      <>Buy Now</>,
      's_trial.noTimeBanner.go',
      <>立即购买</>,
      <>立即購買</>,
      <>すぐに購入する</>,
      <>Mua ngay</>,
    ],
  },
  purchase_message: [
    <>
      무료 체험 수업은 시간과 교재 선택이 제한적일 수 있습니다.
      <br />
      체험없이 구매 시, 10,000원 추가 할인 및 최대 3회 추가 수업이 제공됩니다. (첫 수업 불만족 시 100% 환불 보장)
    </>,
    <>
      Time slots and materials may be limited for trial lessons.
      <br />
      Buy now without a Free trial for additional promo or 3 free lesson credits.
      <br />
      100% Money-back guarantee.
    </>,
    's_trial.purchase_message',
    <>
      免费体验课程的时间及教材可能存在选择限制。
      <br />
      不体验直接购买时可享受$8.62的额外优惠以及最多4次的课程券。 (首次课程不满意时, 承诺100%退款)
    </>,
    <>
      免費體驗課程的時間與教材選擇上可能有限。
      <br />
      未先體驗即購買時，提供$8.62額外折扣及最多3次額外課程。 （首次課程不滿意時，可全額退款）
    </>,
    <>
      無料体験レッスンは時間と教材選択が制限されることがあります。
      <br />
      体験なしでご購入時、$8.62追加割引および最大3回追加レッスンが提供されます。
      (初回レッスン後にご満足いただけない場合、全額返金)
    </>,
    <>
      Thời gian học và tài liệu có thể giới hạn trong buổi học thử.
      <br />
      Mua ngay không cần học thử để nhận thêm ưu đãi hoặc 3 thẻ học miễn phí.
      <br />
      Hoàn tiền 100%.
    </>,
  ],
  select_date: [`날짜 선택`, `Select date`, 's_trial.select_date', `选择日期`, `選擇日期`, `日付選択`, `Chọn ngày`],
  select_time: [`시간 선택`, `Select time`, 's_trial.select_time', `选择时间`, `選擇時間`, `時間選択`, `Chọn giờ`],
  request_verification_code: [
    `인증 요청`,
    `Request verification code`,
    's_trial.request_verification_code',
    `申请验证`,
    `傳送驗證碼`,
    `認証リクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  _1_title: [
    `체험 수업 시간 선택`,
    <>Select Date & Time</>,
    's_trial._1_title',
    `选择体验课程的时间`,
    `選擇體驗課程時間`,
    `体験レッスン時間選択`,
    <>Chọn Ngày & Giờ</>,
  ],
  _1_subTitle: [
    `시간은 수업 시작 24시간 전까지 변경하실 수 있습니다.`,
    `You may reschedule as late as 24 hours before the lesson starts.`,
    's_trial._1_subTitle',
    `时间变更截止于课程开始前24小时`,
    `最晚可於課程開始24小時前變更時間。`,
    `時間はレッスン開始24時間前まで変更することができます。`,
    `Bạn có thể dời buổi học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  _1_description: [
    `무료 체험 수업은 각 시간대에서 선착순으로 제공되고 있기 때문에 시간 선택이 제한적일 수 있습니다.`,
    ``,
    's_trial._1_description',
    `免费体验课程将于各时间段按先后顺序进行, 因此时间选择上存在局限。`,
    `免費體驗課程為各時段先搶先贏，時間選擇上可能有限。`,
    `無料体験レッスンは各時間帯に先着順で提供されるため、時間選択が制限されることがあります。`,
    ``,
  ],
  _2_title: [
    `교재 선택`,
    `Select Lesson Materials`,
    's_trial._2_title',
    `选择教材`,
    `選擇教材`,
    `教材選択`,
    `Chọn Tài liệu học`,
  ],
  _2_subTitle: [
    <>
      수업에서 튜터와 다룰 교재는&nbsp;
      <Span className="font-weight-medium">수업 시작 24시간 전까지</Span>&nbsp; 변경하실 수 있습니다.
    </>,
    `You may change Lesson Materials as late as 24 hours before the lesson starts.`,
    's_trial._2_subTitle',
    <>
      课程中与教师讨论的教材&nbsp;
      <Span className="font-weight-medium">变更截止于课程</Span>&nbsp; 开始前24小时。
    </>,
    <>
      &nbsp; <Span className="font-weight-medium">最晚可於課程開始24小時前</Span>
      &nbsp;變更上課時要與任課教師進行的教材。
    </>,
    <>
      レッスンでチューターと扱う教材は&nbsp;
      <Span className="font-weight-medium">レッスン開始24時間前まで</Span>&nbsp; 変更することができます。
    </>,
    `Bạn có thể đổi Tài liệu học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  _2_description: [
    `무료 체험수업은 일부 교재만 선택할 수 있습니다. 정규수업에서는 인터뷰/프리젠테이션 준비 등을 위한 맞춤 수업이 가능합니다.`,
    ``,
    's_trial._2_description',
    `免费体验课程只能选择特定教材。正规课程可针对面试/报告书等内容进行定制型课程。`,
    `免費體驗課程只能選擇部分教材，正規課程可針對面試／報告準備等進行客製化課程。`,
    `無料体験レッスンは一部の教材のみ選択することができます。レギュラーレッスンでは面接/プレゼンテーション準備などのためのカスタマイズレッスンが可能です。`,
    ``,
  ],
  _3_title: [
    `휴대폰 인증`,
    `Verify Mobile Number`,
    's_trial._3_title',
    `手机验证`,
    `手機驗證`,
    `SMS認証`,
    `Xác nhận số điện thoại`,
  ],
  _3_subTitle: [
    `무료 체험수업 신청 마지막 단계입니다. 인증 중 문제가 발생하면 [문의]를 클릭하여 연락해주세요.`,
    ``,
    's_trial._3_subTitle',
    `申请免费体验课程的最后一步。如在验证过程中出现问题, 请点击[咨询]按钮进行咨询。`,
    `申請免費體驗課程的最後步驟。如驗證過程中出現問題，請點擊[諮詢]聯絡我們。`,
    `無料体験レッスン予約の最終ステップです。認証中に問題が発生した場合は [お問い合わせ]をクリックしてご連絡ください。`,
    ``,
  ],
  there_is_no_available_time: [
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          해당 날짜 체험수업은 마감되었습니다.
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        [날짜 선택] 탭을 통해 다른 일정을 확인해 보세요.
        <br />
        체험수업은 모든 정규수업이 선 배정된 후 선착순으로 배정됩니다.
        <br />
        현재 모든 수업 수가 동시 증가하여, 체험수업 시간이 충분하지 않음을 양해 부탁드립니다.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          수업 관련 문의는 홈페이지 하단의 [1:1 문의]를 이용해 주세요.
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span size={18}>There is no availability on the selected date.</Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        Ringle assigns regular lessons first, then allocates the remaining time slots for free trial lessons.
        <br />
        Please understand that there may not be enough available time slots due to high demands.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          If you have any questions, please contact us via chat below.
        </Span>
      </div>
    </>,
    's_trial.there_is_no_available_time',
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          所选时间的体验课程已经截止。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        请通过[选择日期]弹窗确认其他日程。
        <br />
        体验课程在所有正规课程排课后, 按先后顺序进行分配。
        <br />
        当前所有课程的数量同步增加, 体验课程的时间 不足, 敬请谅解。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          有关课程的其他问题, 可通过主页下方的在线咨询联系我们。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          該日期的體驗課程已截止。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        請透過[選擇日期]分頁確認其他日程。
        <br />
        體驗課程將於所有正規課程分配結束後，按照先後順序進行分配。
        <br />
        目前所有課程數同時增加，體驗課程時間不足，敬請見諒。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          如有課程相關疑問，請透過網頁下方的聊天聯絡我們。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          この日の体験レッスンは締め切りました。
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        [日付選択] タブから他の日程を確認してください。
        <br />
        体験レッスンはすべてのレギュラーレッスンが先に割り当てられた後、先着順で割り当てられます。
        <br />
        現在、すべてのレッスン数が同時に増え、体験レッスン時間が十分ではありません。 ご了承ください。
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          レッスンに関する質問はホームページ下のチャネルトークよりお問い合わせください。
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span size={18}>Không có buổi học nào trong ngày bạn chọn.</Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        Ringle sắp xếp buổi học thông thường trước, sau đó sẽ sắp xếp thời gian còn lại cho buổi học thử.
        <br />
        Mong bạn thông cảm do nhu cầu tăng cao nên có thể không đủ sự lựa chọn.
      </div>
      <div>
        <Span className="text-gray-500" size={12}>
          Nếu bạn có bất kì câu hỏi nào, hãy chat với chúng tôi.
        </Span>
      </div>
    </>,
  ],
  not_select_time: [
    `시간 선택되지 않음`,
    `Time unselected`,
    's_trial.not_select_time',
    `未选择时间`,
    `未選擇時間`,
    `時間未選択`,
    `Thời gian chưa chọn`,
  ],
  selected_time: [
    `시간 선택 완료`,
    `Time selected`,
    's_trial.selected_time',
    `完成时间选择`,
    `時間選擇完畢`,
    `時間選択完了`,
    `Thời gian đã được chọn`,
  ],
  not_verify_phone: [
    `번호 인증되지 않음`,
    `Mobile number not verified`,
    's_trial.not_verify_phone',
    `号码未验证`,
    `未驗證號碼`,
    `携帯電話番号未認証`,
    `Số điện thoại chưa được xác minh`,
  ],
  verify_phone: [
    `번호 인증 됨`,
    `Mobile number verified`,
    's_trial.verify_phone',
    `号码已验证`,
    `號碼驗證成功`,
    `携帯電話番号認証済み`,
    `Số điện thoại đã được xác minh`,
  ],
  not_select_course: [
    `교재 선택되지 않음`,
    `Materials unselected`,
    's_trial.not_select_course',
    `未选择教材`,
    `未選擇教材`,
    `教材未選択`,
    `Tài liệu chưa được chọn`,
  ],
  selected_course: [
    `교재 선택 완료`,
    `Materials selected`,
    's_trial.selected_course',
    `完成教材选择`,
    `教材選擇完畢`,
    `教材選択完了`,
    `Tài liệu đã chọn`,
  ],
  enter_your_phone_number: [
    `휴대폰 번호를 입력해주세요`,
    `Enter your phone number.`,
    's_trial.enter_your_phone_number',
    `请输入您的手机号码`,
    `請輸入手機號碼`,
    `携帯電話番号を入力してください。`,
    `Nhập số điện thoại của bạn.`,
  ],
  verification_is_incorrect_plz_try_again: [
    `인증 번호가 틀렸습니다. 다시 입력해 주세요`,
    `Verification is incorrect. Please try again.`,
    's_trial.verification_is_incorrect_plz_try_again',
    `验证码出现错误, 请重新输入`,
    `驗證碼錯誤，請重新輸入。`,
    `認証コードが間違っています。もう一度入力してください。`,
    `Mã xác minh không chính xác. Vui lòng thử lại.`,
  ],
  view_material: [
    `교재 보기`,
    `View Lesson Materials`,
    's_trial.view_material',
    `查看教材`,
    `查看教材`,
    `教材を見る`,
    `Xem Tài liệu học`,
  ],
  you_have_already_requested: [
    `이미 체험신청이 완료되었습니다.`,
    `You already booked your trial lesson.`,
    's_trial.you_have_already_requested',
    `体验申请已经完成`,
    `已完成體驗申請。`,
    `すでに体験予約が完了しました。`,
    `Bạn đã đặt lịch buổi học thử.`,
  ],
  please_select_a_date_and_time: [
    `날짜와 시간을 선택해주세요`,
    `Please select date and time.`,
    's_trial.please_select_a_date_and_time',
    `请选择日期和时间`,
    `請選擇日期和時間`,
    `日付と時間を選択してください。`,
    `Vui lòng chọn ngày và giờ.`,
  ],
  please_select_material: [
    `교재를 선택해주세요`,
    `Please select lesson materials.`,
    's_trial.please_select_material',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください。`,
    `Vui lòng chọn tài liệu học.`,
  ],
  please_complete_phone_number_verification: [
    `휴대폰 인증을 완료해주세요`,
    `Please verify your mobile number.`,
    's_trial.please_complete_phone_number_verification',
    `请完成手机验证`,
    `請完成手機驗證`,
    `SMS認証を完了してください。`,
    `Vui lòng xác minh số điện thoại của bạn.`,
  ],
  submit: [`체험 수업 신청`, `Submit`, 's_trial.submit', `申请体验课程`, `申請體驗課程`, `体験レッスン予約`, `Gửi`],
  incomplete_alert: [
    `수업시간 선택, 교재 선택, 번호 인증 등의 3단계를 모두 완료하셔야만 체험 수업을 신청하실 수 있습니다.`,
    `Please complete all three steps to book your trial lesson: 1) select date & time, 2) select lesson materials, 3) verifying mobile number.`,
    's_trial.incomplete_alert',
    `需完成课程时间选择, 教材选择及手机验证三个步骤后,体验课程申请才能成功。`,
    `必須選擇課程時間、選擇教材及驗證號碼等完成3個步驟，才能申請體驗課程。`,
    `レッスン時間選択、教材選択、SMS認証などの3ステップをすべて完了しなければ、体験レッスンを予約することができません。`,
    `Vui lòng hoàn thành hết ba bước để đăng ký học thử: 1) chọn ngày & giờ, 2) chọn tài liệu, 3) xác minh số điện thoại.`,
  ],
  you_can_view_list_of_available_tutors_after_entering_self_intro: [
    `짧게 시간내어 소개를 남기고, 수업에서 만날 튜터의 프로필을 확인해 보세요!`,
    `Complete your profile and check out your tutor's profile!`,
    's_trial.you_can_view_list_of_available_tutors_after_entering_self_intro',
    `请抽出时间留下您的自我介绍并确认将开始课程的教师简介!`,
    `請抽出一點時間寫下介紹，並確認將在課程見面的任課教師簡介！`,
    `短い時間で自己紹介を作成し、レッスンで出会うチューターのプロフィールを確認しましょう！`,
    `Hoàn thành thông tin cá nhân và xem hồ sơ gia sư của bạn!`,
  ],
  everybody_conversation: [
    `일상 대화`,
    `Casual Conversations`,
    's_trial.everybody_conversation',
    `日常会话`,
    `日常會話`,
    `日常会話`,
    `Hội thoại hằng ngày`,
  ],
  freetalking: [`자유주제`, `Free Topic`, 's_trial.freetalking', `自由主题`, `自由主題`, `自由テーマ`, `Chủ đề tự do`],
  completed_title: [
    <>
      체험 수업 신청이 {isMobile && <br />}
      완료되었습니다.
    </>,
    <>Your free trial lesson {isMobile && <br />}has been successfully scheduled</>,
    's_trial.completed_title',
    <>
      已完成{isMobile && <br />}
      体验课程申请。
    </>,
    <>
      體驗課程申請{isMobile && <br />}
      完成。
    </>,
    <>
      体験レッスンの予約が {isMobile && <br />}
      完了しました。
    </>,
    <>Buổi học thử miễn phí của bạn {isMobile && <br />}đã được lên lịch thành công</>,
  ],
  completed_description: [
    <>수업 시작 10분 전까지 노트북으로 링글 웹사이트에 로그인 하신 후, 수업을 준비해 주세요.</>,
    <>
      Please log into Ringle's website 10 minutes before the lesson starts. For a better learning experience, please
      enter Ringle lessons on a web browser.
    </>,
    's_trial.completed_description',
    <>请于课程开始前10分钟, 使用笔记本电脑登录Ringle网站, 准备开始您的课程。</>,
    <>請最晚於課程開始10分鐘前使用筆記型電腦登入Ringle網站並準備上課。</>,
    <>レッスン開始10分前までにノートパソコンでRingleウェブサイトにログインした後、レッスンの 準備をしてください。</>,
    <>
      Vui lòng đăng nhập vào website Ringle 10 phút trước buổi học. Để có trải nghiệm học tốt nhất, vui lòng vào lớp
      bằng trình duyệt web.
    </>,
  ],
  check_before_lesson: [
    `수업 전 필수 확인 사항`,
    `Before your lesson`,
    's_trial.check_before_lesson',
    `课程开始前务必进行确认`,
    `課程開始前必須確認事項`,
    `レッスン前の必須確認事項`,
    `Trước buổi học`,
  ],
  need_to_zoom: [
    `화상수업을 위해 Zoom 설치가 필요합니다.`,
    `Ringle lessons are run on Zoom. Please install Zoom.`,
    's_trial.need_to_zoom',
    `视频课程需安装Zoom软件。`,
    `為進行視訊課程，需安裝Zoom。`,
    `オンラインレッスンを行うにはZoomのインストールが必要です。`,
    `Buổi học của Ringle diễn ra trên Zoom. Vui lòng cài đặt Zoom.`,
  ],
  need_to_chrome: [
    `링글 서비스는 크롬 브라우저에서 최적화되어 되어 있습니다.`,
    `Please use Google Chrome or Microsoft Edge to access Ringle. Some features may not be available in other browsers.`,
    's_trial.need_to_chrome',
    `建议使用Chrome浏览器, 获得最佳Ringle课程效果`,
    `建議使用Chrome瀏覽器，以獲得最佳Ringle服務瀏覽體驗。`,
    `RingleサービスはChromeブラウザに最適化されています。`,
    `Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle. Một số tính năng có thể không hiển thị trên các trình duyệt khác.`,
  ],
  not_support_ie: [
    `익스플로러 브라우저는 지원하지 않습니다.`,
    `Browser not supported.`,
    's_trial.not_support_ie',
    `不支持Explorer浏览器。`,
    `不支援IE瀏覽器。`,
    `Explorerブラウザには対応していません。`,
    `Trình duyệt không hỗ trợ.`,
  ],
  laptop: [
    `1:1 영어 화상 수업은 노트북 사용을 권장드립니다.`,
    `For a better learning experience, please enter Ringle lessons on a web browser.`,
    's_trial.laptop',
    `1:1英语视频课程, 建议使用笔记本电脑。`,
    `建議使用筆記型電腦進行1:1英語視訊課程。`,
    `1:1オンライン英会話レッスンはノートパソコンの使用を推奨します。`,
    `Để có trải nghiệm học tốt nhất, vui lòng vào lớp bằng trình duyệt web.`,
  ],
  trial_guide: [
    `체험 수업 가이드 확인 하기`,
    `How it works: Ringle Trial Lessons`,
    's_trial.trial_guide',
    `请确认体验课程指南`,
    `查看體驗課程指南`,
    `体験レッスンガイドを確認する`,
    `Cách hoạt động: Buổi học thử của Ringle`,
  ],
  go_prep: [`예습하러 가기`, `Lesson Prep`, 's_trial.go_prep', `去预习`, `前往預習`, `予習する`, `Chuẩn bị Buổi học`],
  download_app: [
    `링글 모바일앱을 설치해 보세요`,
    `Download Ringle Mobile App`,
    's_trial.download_app',
    `请安装Ringle应用程序`,
    `請安裝Ringle APP`,
    `Ringleモバイルアプリをインストールしましょう。`,
    `Tải ứng dụng Ringle trên điện thoại`,
  ],
  stay_connected: [
    `예습-수업-복습을 언제 어디서나 할 수 있어요.`,
    ``,
    's_trial.stay_connected',
    `预习-课程-复习, 随时随地学习英语。`,
    `可隨時隨地預習-上課-複習。`,
    `予習-レッスン-復習をいつでもどこでもすることができます。`,
    ``,
  ],
  completed_guide_1: [
    `수업 취소 및 시간 및 교재 변경은 수업 시작 24시간 전까지 예정된 수업 페이지에서 가능합니다.`,
    `You may reschedule or cancel the lesson or change lesson materials as late as 24 hours before the lesson starts.`,
    's_trial.completed_guide_1',
    `课程取消或更改时间, 教材时, 最迟可于课程开始前24小时于预约页面进行。`,
    `最晚可於課程開始24小時前於預定的課程頁面取消課程及變更時間與教材。`,
    `レッスンのキャンセルおよび時間・教材の変更は、レッスン開始24時間前までにレッスン予定ページで可能です。`,
    `Bạn có thể dời, huỷ buổi học hoặc đổi tài liệu học tối thiểu 24 giờ trước khi buổi học bắt đầu.`,
  ],
  completed_guide_2: [
    `급한 사정으로 수업 시작 24시간 이내 일정 변경이 필요한 경우, 수업을 취소하고 홈페이지의 [문의]를 통해 연락주세요.`,
    `If you must reschedule less than 24 hours before the lesson, please contact us via chat below.`,
    's_trial.completed_guide_2',
    `因紧急情况需要在课程开始前24小时内变更日期时，请取消课程后与主页在线[咨询]联系。`,
    `於課程開始前24小時以內緊急需要變更日程時，請取消課程並透過首頁的[諮詢]聯絡我們。`,
    `急な事情によりレッスン開始24時間以内の日程変更が必要な場合はレッスンをキャンセルし、ホームページの[お問い合わせ]からご連絡ください。`,
    `Nếu bạn phải dời lịch trong vòng 24 giờ trước khi buổi học bắt đầu, vui lòng chat với chúng tôi.`,
  ],
  no_available_lesson: [
    `선착순으로 금일 수업은 마감되었습니다. 다른 일정을 확인해 보세요!`,
    `There is no available lesson on the selected date`,
    's_trial.no_available_lesson',
    `按照先后顺序, 今日的课程预约已经结束, 请确认其他日期。`,
    `今日的課程已截止，請確認其他日程。`,
    `先着順で本日のレッスンは締め切られました。他の日程をご確認ください！`,
    `Không có buổi học nào trong ngày bạn chọn`,
  ],
  view_all_material: [
    `교재 전체 보기`,
    `View All Materials`,
    's_trial.view_all_material',
    `查看全部教材`,
    `查看全部教材`,
    `すべての教材を見る`,
    `Xem Tất cả Tài liệu`,
  ],
  freetalking_message: [
    <>
      체험수업에서는 <Span danger>자유주제 수업을 신청할 수 없습니다.</Span>
      <br />
      정규수업 시, 내가 원하는 주제로 토론 및 교정 수업을 진행할 수 있습니다.
    </>,
    <>
      <Span danger>Free talking lessons are unavailable</Span> for the trial.
      <br />
      During the regular lesson, you can take correction or discussion-focused lessons with any topic you’re interested
      in.
    </>,
    's_trial.freetalking_message',
    <>
      体验课程<Span danger>无法申请自由主题课程。</Span>
      <br />
      正规授课时, 可选择自身希望的主题内容进行讨论及纠正。
    </>,
    <>
      進行體驗課程時，<Span danger>無法申請自由主題。</Span>
      <br />
      進行正規課程時，能以自己希望的主題進行討論與糾正課程。
    </>,
    <>
      体験レッスンでは <Span danger>自由テーマレッスンを予約することができません。</Span>
      <br />
      レギュラーレッスンの際、あなたの好きなテーマでディスカッションおよび校正レッスンを行うことができます。
    </>,
    <>
      <Span danger>Không có buổi học nói tự do</Span> khi học thử.
      <br />
      Trong buổi học tiêu chuẩn, bạn có thể chọn tập trung vào sửa lỗi hay tập trung thảo luận với bất kì chủ đề nào bạn
      hứng thú.
    </>,
  ],
  material_guide: [
    <>
      체험수업 시,&nbsp;
      <span className="font-medium">각 주제마다 가장 인기 있는 교재 10개가 제공됩니다.</span>
      <br />
      정규수업 시, 링글에서 제공하는 1,000개 이상 교재를 자유롭게 무료로 이용 가능합니다.
      <br />
    </>,
    <>
      Only the 10 most popular Ringle materials in each section are available for the trial lesson. During the regular
      lesson, you can select more than 1,000 materials for free.
    </>,
    's_trial.material_guide',
    <>
      体验课程时&nbsp;
      <span className="font-medium">每个主题将提供10个最受欢迎的教材。</span>
      <br />
      正规课程可免费自由使用Ringle所提供的1,000个以上的教材。
      <br />
    </>,
    <>
      進行體驗課程時，&nbsp;
      <span className="font-medium">將提供各主題最受歡迎的10個教材。</span>
      <br />
      進行正規課程時，可自由利用Ringle提供的1,000個以上免費教材。
      <br />
    </>,
    <>
      体験レッスン時&nbsp;
      <span className="font-medium">テーマごとに最も人気のある教材10個が提供されます。</span>
      <br />
      レギュラーレッスン時、Ringleが提供する1,000個以上の教材を自由に無料で利用 可能です。
      <br />
    </>,
    <>
      Chỉ 10 tài liệu phổ biến nhất của Ringle của mỗi phần có sẵn cho buổi học thử. Trong buổi học thông thường, bạn có
      thể chọn hơn 1,000 tài liệu miễn phí.
    </>,
  ],
  buy_without_trial: [
    `무료 체험 없이 바로 구매`,
    `Buy without trial`,
    's_trial.buy_without_trial',
    `不进行免费体验立即购买`,
    `未先免費體驗即購買`,
    `無料体験なしで購入`,
    `Mua mà không cần học thử`,
  ],
  trial_can_be_limited: [
    `무료체험: 정규수업보다 수업 시간, 교재, 튜터 선택이 자유롭지 않습니다.`,
    `Free trial offers limited options for time slots, materials, and tutors.`,
    's_trial.trial_can_be_limited',
    `免费体验课程于时间和教材选择上存在一定局限。`,
    `免費體驗課程的時間與教材選擇上可能有限。`,
    `無料体験レッスンは時間と教材の選択が制限されることがあります。`,
    `Buổi học thử sẽ giới hạn về thời gian & tài liệu để lựa chọn.`,
  ],
  purchase_message_new: [
    <>무료체험 없이 구매 시, 최대 3회 수업권 + 1만원 추가 할인 혜택을 드려요! (첫 수업 불만족 시, 100% 환불 보장)</>,
    <>
      Skip trial and get up to 3 free credits & $8 off (full refund available for unsatisfied customers after first
      lesson)
    </>,
    's_trial.purchase_message_new',
    <>
      <b>不进行体验课程直接购买时, </b>可追加提供$8.62的优惠及最多4次的额外 课程。(首次课程不满意时, 承诺100%退款)
    </>,
    <>
      <b>未先體驗即購買時，</b>提供$8.62額外折扣及最多4次 額外課程。（首次課程不滿意時，可全額退款）
    </>,
    <>
      <b>体験レッスンなしでご購入時、</b> $8.62追加割引および最大3回追加
      レッスンが提供されます。(初回レッスンにご満足いただけない場合、全額返金保証)
    </>,
    <>
      <b>Mua mà không cần học thử</b> giảm $8.26 + tới 4 buổi học miễn phí (Hoàn tiền 100% ).
    </>,
  ],
  promotion_message: [
    `체험수업 일시 부족으로 추가 혜택을 제공합니다.`,
    `Offering extra benefits due to limited free trial`,
    's_trial.promotion_message',
    `体验课程暂时不足, 将提供额外优惠。`,
    `由於體驗課程暫時不足，故提供額外優惠。`,
    `体験レッスンの日時不足により、追加特典を提供します。`,
    `Thêm nhiều ưu đãi vì buổi học thử miễn phí có hạn`,
  ],
}
export const s_products = {
  dollarDescription: [
    <>
      <ul>
        <li>현재 환율에 의거하여 달러($)로 결제됩니다.</li>
        <li>
          PayPal을 로그인하지 않아도 사용가능합니다(PayPal Guest Checkout 또는 Pay with Debit or Credit Card로
          결제하시면 됩니다)
        </li>
        <li>영수증은 등록하신 이메일로 자동 발송됩니다. (링글 사이트 내에서 확인이 불가합니다.)</li>
        <li>
          청구주소가 한국이면 이용이 <Span danger>불가능</Span>합니다.
        </li>
        <li>일부 국내법인카드는 Paypal을 지원하지 않습니다. 이 경우, 국내 &gt; 비인증 결제 진행부탁드립니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>
          You can complete your purchase without logging into PayPal. Select PayPal Guest Checkout or Pay with Debit or
          Credit Card.
        </li>
        <li>Your receipt will be emailed to you. You cannot view it on Ringle website.</li>
        <li>
          If your billing address is in South Korea, you <Span danger>cannot</Span> use PayPal.
        </li>
        <li>
          Some South Korean corporate credit cards may not be compatible with PayPal. Please use personal credit cards
          or Non-certificate pay
        </li>
      </ul>
    </>,
    's_products.dollarDescription',
    <>
      <ul>
        <li>按照当前汇率, 可使用美金($)进行付款。</li>
        <li>无需登录PayPal也可使用。(可用PayPal Guest Checkout或 Pay with Debit or Credit Card进行付款)</li>
        <li>收据将自动发送至登录的邮箱。(无法于Ringle网站进行确认。)</li>
        <li>
          申请地址是韩国时, 将无法<Span danger>使用</Span>。
        </li>
        <li>部分韩国法人卡不支持Paypal。这种情况时请使用韩国&gt; 非验证付款方式。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>將依照目前匯率以美元（$）付款。</li>
        <li>不必登入PayPal即可付款。（只需使用PayPal Guest Checkout或Pay with Debit or Credit Card付款即可。）</li>
        <li>收據將自動發送至您登錄的電子信箱。（無法於Ringle網站進行確認。）</li>
        <li>
          請款地址為韓國的情況，將<Span danger>無法</Span>使用。
        </li>
        <li>部分韓國國內公司卡不支援PayPal。這種情況請使用韓國國內&gt;非驗證付款。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>現在の為替レートにもとづきドル($)で決済されます。</li>
        <li>
          PayPalでログインしなくても使用可能です。(PayPal Guest Checkoutまたは Pay with Debit or Credit
          Cardで決済してください)
        </li>
        <li>領収証は登録されたメールアドレスに自動的に送信されます。(Ringleサイト内では確認 できません)</li>
        <li>
          請求住所が日本なら利用が <Span danger>不可能</Span>です。
        </li>
        <li>一部の国内法人カードはPaypalに対応していません。その場合、国内 &gt; 非認証決済を行ってください。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>
          Bạn có thể hoàn thành giao dịch mà không cần phải đăng nhập vào PayPal. Chọn PayPal Guest Checkout hoặc trả
          bằng thẻ ghi nợ hoặc thẻ tín dụng.
        </li>
        <li>Biên nhận sẽ được gửi qua email của bạn. Bạn không thể kiểm tra biên nhận trên website Ringle.</li>
        <li>
          Nếu địa chỉ thanh toán của bạn ở Hàn Quốc, bạn <Span danger>không thể</Span> sử dụng PayPal.
        </li>
        <li>
          Một số thẻ tín dụng của doanh nghiệp Hàn Quốc không tương thích với PayPal. Vui lòng sử dụng thẻ tín dụng cá
          nhân.
        </li>
      </ul>
    </>,
  ],
  dollarConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>${price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected credits will be purchased with&nbsp;
        <Span primary>${price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.dollarConfirm',
    <>
      <b>
        该商品使用<Span primary>${price}</Span>付款。
        <br />
        确定要进行吗?
      </b>
    </>,
    <>
      <b>
        該商品將以<Span primary>${price}</Span>付款。
        <br />
        確定要進行嗎？
      </b>
    </>,
    <>
      <b>
        この商品は <Span primary>${price}</Span>で決済されます。
        <br />
        実行しますか？
      </b>
    </>,
    <>
      <b>
        Thẻ tín dụng được chọn sẽ thanh toán với&nbsp;
        <Span primary>${price}</Span>. Bạn có muốn thực hiện?
      </b>
    </>,
  ],
  no: [`아니오`, `No`, 's_products.no', `不`, `否`, `いいえ`, `Không`],
  yes: [`네`, `Yes`, 's_products.yes', `是`, `是`, `はい`, `Có`],
  wonDescription: [
    <>
      <ul>
        <li>원화(￦)로 결제됩니다.</li>
        <li>결제 내역에는 링글의 결제 대행사인 ‘JTNET’이 상점명으로 표시됩니다.</li>
        <li>링글 사이트 내에서 영수증을 확인할 수 있습니다.</li>
        <li>소량의 환전 수수료가 추가될 수 있습니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>The purchase will be made in Korean Won (KRW).</li>
        <li>The receipt will be issued under 'JTNET,' Ringle's payment agency.</li>
        <li>You can view your receipt on the Ringle website.</li>
        <li>A small amount of currency exchange fee may be charged.</li>
      </ul>
    </>,
    's_products.wonDescription',
    <>
      <ul>
        <li>使用韩元(￦)进行付款。</li>
        <li>付款内容中Ringle所显示的代理公司为‘JTNET’。</li>
        <li>可在Ringle网站内确认收据。</li>
        <li>可能会产生附加的小额换汇手续费。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>將以韓元（￦）付款。</li>
        <li>付款明細的商店名稱將標示Ringle的代收業者「JTNET」。</li>
        <li>可在Ringle網站確認收據。</li>
        <li>可能會產生小額的換匯手續費。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>$（ドル）で決済されます。</li>
        <li>決済履歴はRingleの決済代行会社の「JTNET」がショップ名で表示されます。</li>
        <li>Ringleサイト内で領収証を確認することができます。</li>
        <li>少額の両替手数料が追加されることがあります。</li>
      </ul>
    </>,
    <>
      <ul>
        <li>Giao dịch sẽ được thực hiện bằng tiền Won (KRW).</li>
        <li>Biên nhận sẽ được xuất dưới tên 'JTNET',' công ty thanh toán của Ringle.</li>
        <li> Bạn có thể xem biên nhận trên website Ringle.</li>
        <li>Một phần nhỏ phí chuyển đổi ngoại tệ có thể được tính vào.</li>
      </ul>
    </>,
  ],
  wonConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>￦{price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected package will be purchased with&nbsp;
        <Span primary>￦{price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.wonConfirm',
    <>
      <b>
        该商品使用<Span primary>￦{price}</Span>付款。
        <br />
        确定要进行吗?
      </b>
    </>,
    <>
      <b>
        該商品將以<Span primary>￦{price}</Span>付款。
        <br />
        確定要進行嗎？
      </b>
    </>,
    <>
      <b>
        この商品は <Span primary>￦{price}</Span>で決済されます。
        <br />
        実行しますか？
      </b>
    </>,
    <>
      <b>
        Gói học bạn chọn sẽ thanh toán với&nbsp;
        <Span primary>￦{price}</Span>. Bạn có muốn thực hiện?
      </b>
    </>,
  ],
  lumpPayment: [`일시불`, `Pay Upfront`, 's_products.lumpPayment', `一次付清`, `一次付清`, `一括払い`, `Trả trước`],
  month: (month) => [
    <>{month}개월</>,
    <>{month} months</>,
    's_products.month',
    <>{month}个月</>,
    <>{month}個月</>,
    <>{month}か月</>,
    <>{month} tháng</>,
  ],
  totalPrice: (price) => [
    <>총 결제 금액: {price}원</>,
    <>Your Total: {price} KRW</>,
    's_products.totalPrice',
    <>总付款金额: ${price}</>,
    <>總付款金額：${price}</>,
    <>合計決済金額: {price}</>,
    <>Tổng cộng: {price} KRW</>,
  ],
  firstCard: [
    `첫번째 카드 결제 금액`,
    `Charge on the first card`,
    's_products.firstCard',
    `第一张银行卡付款金额`,
    `第一張信用卡付款金額`,
    `1枚目のカード決済金額`,
    `Tính phí thẻ đầu tiên`,
  ],
  secondCard: [
    `두번째 카드 결제 금액`,
    `Charge on the second card`,
    's_products.secondCard',
    `第二张银行卡付款金额`,
    `第二張信用卡付款金額`,
    `2枚目のカード決済金額`,
    `Tính phí thẻ thứ hai`,
  ],
  person: [
    `일시불 / 개인`,
    `Pay Now`,
    's_products.person',
    `一次付清 / 个人`,
    `一次付清／個人`,
    `一括払い / 個人`,
    `Thanh toán ngay`,
  ],
  secondCardPrice: (price) => [
    <>두번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your second card.</>,
    's_products.secondCardPrice',
    <>第二张银行卡付款金额为${price}。</>,
    <>第二張信用卡付款金額為${price}。</>,
    <>2枚目のカード決済金額は${price}です。</>,
    <>{price} KRW sẽ tính vào thẻ thứ hai của bạn.</>,
  ],
  firstCardPrice: (price) => [
    <>첫번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your first card.</>,
    's_products.firstCardPrice',
    <>第一张银行卡付款金额为${price}。</>,
    <>第一張信用卡付款金額為${price}。</>,
    <>1枚目のカード決済金額は${price}です。</>,
    <>{price} KRW sẽ tính vào thể đầu tiên của bạn.</>,
  ],
  totalAmount: [`결제 금액`, `Your total`, 's_products.totalAmount', `付款金额`, `付款金額`, `決済金額`, `Tổng cộng`],
  realAmount: (n) => [<>{n}원</>, <>${n}</>, 's_products.realAmount', <>${n}</>, <>${n}</>, <>${n}</>, <>${n}</>],
  cardInstallmentInfo: [
    `카드 할부 안내`,
    ``,
    's_products.cardInstallmentInfo',
    `银行卡分期付款说明`,
    `信用卡分期付款說明`,
    `カード分割払いのご案内`,
    ``,
  ],
  installment: [
    `할부`,
    `Pay in installations`,
    's_products.installment',
    `分期付款`,
    `分期付款`,
    `分割払い`,
    `Trả góp`,
  ],
  inputCard: [
    `카드번호 입력`,
    `Enter card number`,
    's_products.inputCard',
    `输入银行卡号码`,
    `輸入卡片號碼`,
    `カード番号入力`,
    `Nhập số thẻ`,
  ],
  personal: [`개인`, `Personal`, 's_products.personal', `个人`, `個人`, `個人`, `Cá nhân`],
  corporation: [`법인`, `Corporate`, 's_products.corporation', `法人`, `法人`, `法人`, `Công ty`],
  expiryDate: [
    `유효기간(mm/YY)`,
    `Expiration date (mm/YY)`,
    's_products.expiryDate',
    `有效期(mm/YY)`,
    `有效期限（mm／YY）`,
    `有効期間(mm/YY)`,
    `Ngày hết hạn (mm/YY)`,
  ],
  passwordTwoDigit: [
    `비밀번호 앞 2자리`,
    `First 2 digits of the PIN`,
    's_products.passwordTwoDigit',
    `密码前两位`,
    `密碼前2碼`,
    `暗証番号の前2桁`,
    `2 số đầu của mã PIN`,
  ],
  birthPersonal: [
    `생년월일(6자리)`,
    `Birth Date (6 digits, YYmmdd)`,
    's_products.birthPersonal',
    `出生日期(6位数字)`,
    `出生年月日（6位數）`,
    `生年月日(6桁)`,
    `Ngày sinh (6 chữ số, YYmmdd)`,
  ],
  birthCompany: [
    `사업자등록번호(10자리)`,
    `Business registration number (10 digits)`,
    's_products.birthCompany',
    `营业执照号码(10位数字)`,
    `營利事業統一編號（10位數）`,
    `法人番号(13桁)`,
    `Mã đăng ký kinh doanh (10 chữ số)`,
  ],
  limitPromotionPoint: [
    `해당 수업권은 첫 구매 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $8.62 points can be used for purchasing this lesson credit.`,
    's_products.limitPromotionPoint',
    `该课程券已享受首次购买优惠, 仅可使用$8.62积分。`,
    `該課程券已套用首次購買優惠，僅可使用$8.62P。`,
    `このレッスンチケットは初回購入割引が適用されているため、$8.62ポイントのみ使用可能です。`,
    `Chỉ được sử dụng tối đa $8.62 điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedPoint: [
    `해당 수업권은 최대 할인이 적용되어 추가 포인트 사용은 불가합니다.`,
    `You cannot use points for purchasing this lesson credit.`,
    's_products.notAllowedPoint',
    `该课程券已使用了最大折扣, 不能再额外使用积分。`,
    `該課程券已套用最高折扣，無法額外使用點數。`,
    `このレッスンチケットは最大割引が適用されているため、追加ポイントを使用することはできません。`,
    `Bạn không thể sử dụng điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedTimelimitPoint: [
    `해당 수업권은 시간한정 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $48.62 points can be used for purchasing this lesson credit.`,
    's_products.notAllowedTimelimitPoint',
    `该课程券已享受时间限定优惠, 仅可使用$8.62积分。`,
    `該課程券已套用時間限定優惠，僅可使用$8.62P。`,
    `このレッスンチケットは時間限定割引が適用されているため、$8.62ポイントのみ使用可能です。`,
    `Chỉ được sử dụng tối đa $48.62 điểm để thanh toán cho thẻ học này.`,
  ],
  notAllowedGivingBack: [
    `Giving Back 포인트는 18회 이상 수업권 구매 시에만 사용 가능합니다.`,
    <>&apos;Giving Back Promotion&apos; point can be used only when purchasing more than 18 lesson credits.</>,
    's_products.notAllowedGivingBack',
    `Giving Back积分仅可在购买18次以上课程时使用`,
    `Giving Back點數僅可於購買18次以上課程券時使用。`,
    `Giving Backポイントは18回以上のレッスンチケット購入時にのみ使用可能です。`,
    <>&apos;Ưu đãi tri ân&apos; điểm chỉ có thể sử dụng khi thanh toán từ 18 buổi học trở lên.</>,
  ],
  payment: [`결제하기`, `Purchase`, 's_products.payment', `付款`, `付款`, `決済する`, `Thanh toán`],
  cardInfo: [`카드사 무이자 할부 안내`, `0% monthly payments`, 's_products.cardInfo', ``, ``, ``, ``],
  cardInfoContent: [
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    's_products.cardInfoContent',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  firstTimePurchase: [
    `첫구매가`,
    `First-time Purchase`,
    's_products.firstTimePurchase',
    `首次购买价格`,
    `首次購買價`,
    `初回購入価格`,
    `Thanh toán lần đầu`,
  ],
  purchase_must_be: [
    `('결제사 정책에 의해 1,000원 이하 결제는 불가합니다')`,
    `(Purchases must be greater than $1.)`,
    's_products.purchase_must_be',
    `('根据支付公司的政策要求，1000元以下无法结算')`,
    `（「依據支付公司的政策，低於$1無法付款。」）`,
    `「決済会社の規約により1,000ウォン以下の決済はできません」`,
    `(Thanh toán phải lớn hơn $1.)`,
  ],
  corporate_donot_allow_installments: [
    `법인카드 사용시, 할부 결제가 불가능합니다.`,
    `Corporate cards do not allow installments.`,
    's_products.corporate_donot_allow_installments',
    `公司信用卡无法使用分期付款.`,
    `使用公司卡時無法分期付款。`,
    `法人カードをご利用の場合、分割払いはできません。`,
    `Thẻ công ty sẽ không trả góp được.`,
  ],
  how_to_use: {
    title: [
      `수업권 사용 안내`,
      `How to Use Credits`,
      's_products.how_to_use.title',
      `课程券使用介绍`,
      `課程券使用說明`,
      `レッスンチケットの使用方法`,
      `Chính sách thẻ học`,
    ],
    list1: [
      `수업권은 수강기간 내 자유롭게 이용할 수 있습니다. (일 별 수업 예약 횟수에 제한이 없습니다.)`,
      `You can book lessons during business hours, on any date before the credits expire. (You can freely book the lesson at any date and time.)`,
      's_products.how_to_use.list1',
      `课程券按购买次数, 可于课程期限内自由使用。`,
      `可於聽課期間內自由使用課程券。（每日課程預約次數不限。）`,
      `購入したレッスンチケットは有効期限内に自由に使うことができます。`,
      `Bạn có thể sử dụng thẻ học trước khi hết hạn.`,
    ],
    list2: [
      `수업 시간은 연중무휴로 한국 시간 기준 오전 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
      `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
      's_products.how_to_use.list2',
      `可在Ringle的课程时间内自由使用正规课程券。凌晨5点～下午2点（韩国标准时间，以开始时间为准）， 晚上7点～凌晨3点（韩国标准时间，以开始时间为准）`,
      `課程時間為全年無休，可於韓國時間凌晨5點～下午2點、晚上7點～凌晨3點上課。`,
      `レギュラーレッスンチケットは、Ringleのレッスン時間内で自由に利用可能です。 午前5時 ~ 午後2時 (開始時間基準)、 午後7時 ~ 午前3時 (開始時間基準)`,
      `Bạn có thể sử dụng Thẻ học tiêu chuẩn bất cứ khi nào trong giờ hành chính: 05:00-14:00 (Giờ Hàn Quốc, tính từ giờ bắt đầu học), 19:00-03:00 (Giờ Hàn Quốc, tính từ giờ bắt đầu học)`,
    ],
    list3: [
      `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
      `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
      's_products.how_to_use.list3',
      `请注意课程券的有效期不是从购买日开始扣除，而是从完成首次课程时开始扣除。`,
      `於完成首次課程時扣除聽課期間。`,
      `受講期間が始まる時点は購入日ではなく、初回レッスンを完了した時点からであることをご了承ください。`,
      `Ngày hết hạn được tính bắt đầu từ ngày bạn hoàn thành buổi học đầu tiên trong gói học đã thanh toán.`,
    ],
    list4: [
      `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
      `Unused credits' expiration dates will start counting down a year after the purchase date.`,
      's_products.how_to_use.list4',
      `课程期限从第一次上课开始计算.`,
      `購買後一年期間，於完成首次課程時扣除聽課期間。`,
      `レッスンチケットの有効期限は初回レッスンを完了した日から起算されます。`,
      `Thời hạn kết thúc của thẻ học chưa được dùng sẽ là một năm kể từ ngày bạn thanh toán.`,
    ],
    list5: [
      `포인트 사용 혹은 소액 결제를 통해 수강기간을 연장할 수 있습니다.`,
      `You can extend credit expiration dates with your points or by paying a small fee.`,
      's_products.how_to_use.list5',
      `课程期限可通过使用积分/小额付款的方式进行延长。`,
      `聽課期間可透過使用點數／小額付款延長。`,
      `有効期限はポイント又は少額手数料で延長することができます。`,
      `Bạn có thể gia hạn bằng cách sử dụng điểm hoặc trả một khoản phí nhỏ.`,
    ],
    list6: [
      `실결제가 18만원 이상 수업권을 구매하신 후 링글 팀으로 연락주시면, 보유하신 수업권의 남은 기간에 최초 수강 기간을 더해 연장해드립니다. (예: 90일 수업권 구매 후, 수강기간 10일 남은 시점에 추가 수업권 구매 시, 해당 수업권에 90일 수강기간을 추가 연장 조치함)`,
      `If you contact our Help Center after purchasing a package worth $155.17 or more, we will extend your old credits by the initial validity period (e.g. 90-day credits expiring in 10 days will be extended by 90 days after the purchase of 180,000KRW worth of new credits).`,
      's_products.how_to_use.list6',
      `购买实际付款金额达₩180,000以上的套餐后告诉Ringle团队，将为您回复之前付款购买的套餐原本的听课期间1次。`,
      `額外購買₩180,000以上的課程券後，透過1:1諮詢告知Ringle團隊，可將先前課程券的聽課期間復原至最初狀態。`,
      `18万ウォン以上レッスンチケットを購入された後、Ringleチームにご連絡いただくと、保有されたレッスンチケットの残りの期間に最初の受講期間を加えて延長いたします。（例：90日レッスンチケットをご購入後、受講期間残り10日の時点で追加レッスンチケットをご購入した場合、該当のレッスンチケットに90日受講期間を追加で延長させていただきます）`,
      `Liên hệ với chúng tôi để thực hiện thanh toán khoác trên ₩180,000 để gia hạn cho thẻ học cũ có ngày hết hạn cùng với thẻ học mới.`,
    ],
    list7: [
      `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      's_products.how_to_use.list7',
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
    ],
  },
  refund_policy: {
    title: [
      `수업권 환불 정책`,
      `Refund Policy`,
      's_products.refund_policy.title',
      `课程券退款政策`,
      `課程券退款政策`,
      `レッスンチケット返金ポリシー`,
      `Chính sách hoàn tiền`,
    ],
    list1: [
      `구매 후 1년 이내 사용하지 않은 수업권은 100% 환불 가능합니다.`,
      `Credits that have not been used within a year after purchase are eligible for a full refund.`,
      's_products.refund_policy.list1',
      `购买后, 未使用的课程券将享受全额退款。`,
      `購買後，可全額退還未使用的課程券。`,
      `未使用のレッスンチケットは全額返金可能です。`,
      `Các Thẻ học chưa sử dụng có thể được hoàn lại.`,
    ],
    list2: [
      `첫 수업 불만족 시, 전액 환불 가능합니다. (첫 수업에 체험 수업 포함)`,
      `We offer a 100% money-back guarantee for your first lesson (including trial lessons).`,
      's_products.refund_policy.list2',
      `首次课程结束后,  不满意可全额退款。`,
      `首次課程不滿意時，可全額退款。`,
      `体験レッスン含む初回レッスンをご利用いただき、ご満足いただけなかった場合は全額返金いたします。`,
      `Chúng tôi đảm bảo hoàn tiền 100% cho buổi học đầu tiên.`,
    ],
    list3: [
      `수강기간이 절반 이하 남거나, 수업 횟수가 절반 이하 남은 수업권은 환불이 불가능합니다.`,
      `No refund is offered for lesson credits starting halfway through the expiration dates or for packages that are half-used or more.`,
      's_products.refund_policy.list3',
      `剩余课程期限不足一半或课程次数剩余不足一半时，无法退费.`,
      `聽課期間少於一半或課程次數少於一半的課程券恕不退款。`,
      `有効期限が半分以下のレッスンチケットおよび残りの回数が半分以下のレッスンパックは払戻できません。`,
      `Hoàn tiền không áp dụng cho thẻ học có ngày hết hạn được một nửa, hoặc thẻ học đã được dùng hơn một nửa.`,
    ],
    list4: [
      `수강 이력이 있는 수업권은 이용약관(13조, 15조)에 의거하여 처리합니다.`,
      `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
      's_products.refund_policy.list4',
      `已有课程使用记录的课程券, 退款时将按照使用条款中的(13条, 15条)进行处理!`,
      `曾使用過的課程券將依據使用條款（第13條、第15條）進行退款。`,
      `受講履歴のあるレッスンチケットの返金は、利用規約13条および15条に基づいて処理されます。`,
      `Về việc hoàn tiền những Thẻ học đã sử dụng, vui lòng xem điều 13 và 15 trong điều khoản dịch vụ.`,
    ],
  },
}
export const s_mobile = {
  welcome: [`환영합니다`, `Welcome`, 's_mobile.welcome', `欢迎您`, `歡迎您`, `ようこそRingleへ！`, `Xin chào`],
  app_description: [
    <>
      모바일에서 최적의 학습 환경을 위해
      <br />앱 사용을 권장 해 드립니다.
    </>,
    <>
      Download the Ringle app
      <br />
      for a mobile learning experience.
    </>,
    's_mobile.app_description',
    <>
      为保证移动终端提供最优质的学习环境
      <br />
      建议使用应用程序。
    </>,
    <>
      為使用手機體驗最佳學習環境，
      <br />
      建議您使用APP。
    </>,
    <>
      モバイルで最適な学習環境を整えるために
      <br />
      アプリの使用を推奨いたします。
    </>,
    <>
      Tải ứng dụng Ringle
      <br />
      để trải nghiệm trên điện thoại.
    </>,
  ],
  ringle_recommend: [
    `신규고객 한정`,
    `New members only`,
    's_mobile.ringle_recommend',
    `新用户限定`,
    `新學生限定`,
    `新規顧客限定`,
    `Chỉ dành cho thành viên mới`,
  ],
  app_download: [
    `앱으로 이동하기`,
    `Open the app`,
    's_mobile.app_download',
    `移动至应用程序`,
    `前往APP`,
    `アプリに移動する`,
    `Mở ứng dụng`,
  ],
  description: [
    <>
      앱에서는 링글 튜터와 콘텐츠를
      <br />
      제한없이 만나볼 수 있어요!
    </>,
    <>Experience all of Ringle on the Ringle App</>,
    's_mobile.description',
    <>
      在应用程序中自由无限制地
      <br />
      选择Ringle教师及内容进行学习吧!
    </>,
    <>
      可在APP不受限制地
      <br />
      遇見Ringle的任課教師與內容！
    </>,
    <>
      アプリでRingleのチューターとコンテンツを
      <br />
      制限なくご利用いただけます！
    </>,
    <>Trải nghiệm toàn bộ tính năng của Ringle trong một ứng dụng</>,
  ],
  ringle: [
    `7년간 화상영어에 집중한 링글`,
    `Ringle | The New Standard in Online English Learning`,
    's_mobile.ringle',
    `7年间专注于线上英语教育的Ringle`,
    `7年來專注於視訊英語的Ringle`,
    `7年間オンライン英会話に集中したRingle`,
    `Ringle | Nâng tầm việc học tiếng Anh Online`,
  ],
  realLesson: [
    `링글 수업은 이렇게 진행됩니다!`,
    `How Ringle works`,
    's_mobile.realLesson',
    `Ringle课程就是这样进行的!`,
    `Ringle課程就是這樣進行的！`,
    `Ringleのレッスンはこうして行われます！`,
    `Cách Ringle hoạt động`,
  ],
}
export const s_memo = {
  empty_page: [
    <>
      본 페이지는 더 나은 기능 제공을 위해 개선 작업 중입니다.
      <br />
      이용에 불편을 드려서 죄송합니다.
    </>,
    <>
      This page is not available to provide better functionality.
      <br />
      Sorry for the inconvenience.
    </>,
    's_memo.empty_page',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  memo_title: {
    title: [
      `오늘의 표현으로 문장을 만들어보세요!`,
      `Make a sentence with today's expression!`,
      's_memo.memo_title.title',
      ``,
      ``,
      ``,
      ``,
    ],
    body: [
      `오늘 공부한 것을 정리해보세요.`,
      `Review what you have learned today.`,
      's_memo.memo_title.body',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  save_alert: [`저장되었습니다.`, `Saved.`, 's_memo.save_alert', ``, ``, ``, ``],
  memo_content: {
    visible_only_me: [`나만 보기`, `Show only to me`, 's_memo.memo_content.visible_only_me', ``, ``, ``, ``],
    placeholder: [`새로운 메모를 입력해보세요.`, `Type a new memo.`, 's_memo.memo_content.placeholder', ``, ``, ``, ``],
    save: [`저장하기`, `Save`, 's_memo.memo_content.save', ``, ``, ``, ``],
    to_english: [`영작`, `English writing`, 's_memo.memo_content.to_english', ``, ``, ``, ``],
    sample_answer: [`모범답안`, `Sample answer`, 's_memo.memo_content.sample_answer', ``, ``, ``, ``],
    explanation: [`해설`, `Explanation`, 's_memo.memo_content.explanation', ``, ``, ``, ``],
    free_placeholder: [
      `자유롭게 영작해보세요! 최소 6자를 입력해주세요.`,
      `Type freely in English! (at least 6 words)`,
      's_memo.memo_content.free_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    submit: [`제출하기`, `Submit`, 's_memo.memo_content.submit', ``, ``, ``, ``],
    emptypage: [
      `현재 등록된 퀴즈가 없습니다. 빠르게 업데이트하도록 하겠습니다.`,
      `No quiz right now. Will be updated soon.`,
      's_memo.memo_content.emptypage',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  memo_body: {
    updated: [`수정 되었습니다.`, `Updated`, 's_memo.memo_body.updated', ``, ``, ``, ``],
    visible_only_me: [`나만 보기`, `Show only to me`, 's_memo.memo_body.visible_only_me', ``, ``, ``, ``],
    update: [`수정`, `Update`, 's_memo.memo_body.update', ``, ``, ``, ``],
  },
  memo_list: {
    update_memo: [`메모 수정`, `Update memo`, 's_memo.memo_list.update_memo', ``, ``, ``, ``],
    confirm_delete: [
      `삭제하시겠습니까?`,
      `Are you sure you want to delete this memo?`,
      's_memo.memo_list.confirm_delete',
      ``,
      ``,
      ``,
      ``,
    ],
    more: [`더보기`, `More...`, 's_memo.memo_list.more', ``, ``, ``, ``],
  },
  social_memo: {
    emptypage: [
      `해당 필터에 맞는 메모 리스트가 없습니다.`,
      `No memo list that matches the filter`,
      's_memo.social_memo.emptypage',
      ``,
      ``,
      ``,
      ``,
    ],
    more: [`더보기`, `More...`, 's_memo.social_memo.more', ``, ``, ``, ``],
  },
  dropdowns: {
    defaultTitle: [`보기`, `View`, 's_memo.dropdowns.defaultTitle', ``, ``, ``, ``],
    list_1: [`전체보기`, `View all`, 's_memo.dropdowns.list_1', ``, ``, ``, ``],
    list_2: [`내가 좋아한 메모`, `Memos I liked`, 's_memo.dropdowns.list_2', ``, ``, ``, ``],
    list_3: [`내가 팔로우하는 링글러의 메모`, `Memos of Ringlers I follow`, 's_memo.dropdowns.list_3', ``, ``, ``, ``],
  },
  memo_container: {
    updated: [`모두 업데이트되었습니다.`, `Updated.`, 's_memo.memo_container.updated', ``, ``, ``, ``],
    my_memo: [`나의 메모`, `My memo`, 's_memo.memo_container.my_memo', ``, ``, ``, ``],
    ringlers_memo: [
      `다른 링글러의 메모`,
      `Other Ringlers' memos`,
      's_memo.memo_container.ringlers_memo',
      ``,
      ``,
      ``,
      ``,
    ],
    heading: [`내가 작성한 메모`, `Memos that I wrote`, 's_memo.memo_container.heading', ``, ``, ``, ``],
  },
  doc: {
    example_1: {
      title: [`수업 때 배운 것`, `What I learned from the lesson`, 's_memo.doc.example_1.title', ``, ``, ``, ``],
      content: [`It gives you buzz`, `It gives you buzz`, 's_memo.doc.example_1.content', ``, ``, ``, ``],
    },
    example_2: {
      title: [`교재에서 배운 것`, `What I learned from the material`, 's_memo.doc.example_2.title', ``, ``, ``, ``],
      content: [
        `새로운 표현 by dint of(~에 의해)`,
        <>A new expression "by dint of"(by means of)</>,
        's_memo.doc.example_2.content',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    example_3: {
      title: [
        `다른 링글러의 메모 참조하기`,
        `Refer to other Ringlers' memos`,
        's_memo.doc.example_3.title',
        ``,
        ``,
        ``,
        ``,
      ],
      content: [
        <>"회사에서 집으로 오는 길에" 를 영어로 어떻게 표현할까?</>,
        <>Is there any other way to say "this is a good opportunity"?</>,
        's_memo.doc.example_3.content',
        <></>,
        <></>,
        <></>,
        <></>,
      ],
    },
  },
}
export const s_lesson_style = {
  record: {
    title: [
      `수업 녹음`,
      `Lesson Audio-Recording`,
      's_lesson_style.record.title',
      `课程录音`,
      `課程錄音`,
      `レッスン録音設定`,
      `Bản ghi âm buổi học`,
    ],
    note: [
      `*수업 녹음 MP3파일은 수업 종료 후 파일 제작이 완료되면 "복습" 메뉴에서 확인하실 수 있습니다.`,
      `Your audio file (.MP3) will be available under "Review" after your lesson.`,
      's_lesson_style.record.note',
      `*课程录音MP3文件, 可于课程结束后文件制作完成时于"复习”菜单中进行确认。`,
      `*課程錄音MP3檔可於課程結束後檔案製作完成時，於「複習」選單確認。`,
      `*レッスンの録音MP3ファイルは、レッスン終了後に「復習」メニューから確認することができます。`,
      `File âm thanh của bạn (.MP3) sẽ có ở phần "Xem lại" sau buổi học của bạn.`,
    ],
    noteTrial: [
      `수업 후, 복습을 위한 녹음파일과 Beta 서비스로 운영하는 스크립트 자료 제공를 위해 수업 내용은 녹음됩니다.`,
      `Trial Lessons are recorded to provide AI Analysis and audio playback & transcript.`,
      's_lesson_style.record.noteTrial',
      `为在课程结束后提供复习所需要的录音文件及运用Beta服务的脚本资料, 我们将对您的课程进行录音。`,
      `為了於課程結束後提供複習所需的錄音檔及Beta服務的腳本資料，我們將對課程進行錄音。`,
      `レッスン後に復習のための録音ファイルとBetaサービスで運営するスクリプト資料の提供のため、レッスン内容は録音されます。`,
      `Buổi học thử được thu âm để cung cấp phân tích từ AI, file âm thanh & bản ghi.`,
    ],
    yes: [
      `수업 녹음하기`,
      `Record`,
      's_lesson_style.record.yes',
      `允许课程录音`,
      `進行課程錄音`,
      `レッスンを録音する`,
      `Thu âm`,
    ],
    no: [
      `수업 녹음하지 않기`,
      `Do Not Record`,
      's_lesson_style.record.no',
      `不进行课程录音`,
      `不進行課程錄音`,
      `レッスンを録音しない`,
      `Không thu âm`,
    ],
  },
  intro: {
    title: [
      `자기 소개`,
      `Tutor & Student Introductions`,
      's_lesson_style.intro.title',
      `自我介绍`,
      `自我介紹`,
      `自己紹介`,
      `Gia sư & Học viên giới thiệu`,
    ],
    placeholder: [
      `소개 없이 바로 수업할 경우 미리 튜터가 알 수 있도록 자신을 소개하는 글을 적어주세요.`,
      `If you'd like to start the lesson without introductions, please write a brief intro on yourself here.
E.g. My name is Kildong Hong and I am a business developer at Ringle, a startup providing 1:1 online English education services.`,
      's_lesson_style.intro.placeholder',
      `在无任何介绍的情况下直接开始课程, 请提前写下您的简介, 帮助教师对您建立初步了解吧。`,
      `未先自我介紹便開始上課的情況，請先寫下介紹自己的短文，以利任課教師先認識您。`,
      `自己紹介なしですぐにレッスンを受ける場合は、チューターがわかるように事前に自己紹介文を作成してください。`,
      `Nếu bạn muốn bắt đầu buổi học mà không cần giới thiệu, vui lòng viết phần giới thiệu ngắn về bạn tại đây.
Ví dụ: My name is Ahn and I am a business developer at Ringle, a startup providing 1:1 online English education services.`,
    ],
    skip: [
      `소개 없이 바로 시작`,
      `Start lesson without introductions`,
      's_lesson_style.intro.skip',
      `无自我介绍直接开始课程`,
      `未先自我介紹便開始`,
      `自己紹介なしですぐに開始`,
      `Bắt đầu buổi học bỏ qua phần giới thiệu`,
    ],
    do: [
      `서로 소개 3분 후 시작`,
      `Spend first 3 minutes exchanging introductions`,
      's_lesson_style.intro.do',
      `彼此介绍3分钟后开始课程`,
      `各自我介紹3分鐘後開始`,
      `3分間お互いに自己紹介してから開始`,
      `Dành 3 phút đầu để giới thiệu`,
    ],
  },
  lesson_mode: {
    title: [
      `수업 모드`,
      `Lesson Mode`,
      's_lesson_style.lesson_mode.title',
      `课程模式`,
      `課程模式`,
      `レッスンモード`,
      `Kiểu bài học`,
    ],
    note: [
      <>
        체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업 모드를 교정 중심과
        토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.
      </>,
      <>
        All trial lessons are discussion-focused.
        <br />
        Paid lessons can either be discussion-focused or correction focused.
      </>,
      's_lesson_style.lesson_mode.note',
      <>
        体验课程的形式为完成各问题对话后,
        以纠正教学收尾。而正规课程则可选择纠正为主或讨论为主的课程模式以及其他详细方式。
      </>,
      <>
        進行體驗課程時，在針對各提問結束對話後，以糾正方式進行。進行正規課程時，可於課程模式選擇以糾正為主或以討論為主後，選擇詳細的上課方式。
      </>,
      <>
        体験レッスンは各質問の会話が終わった後に校正してもらう方式で行われます。レギュラーレッスンでは、レッスンモードを校正中心とディスカッション中心から選択して細かいレッスン方式を選択することができます。
      </>,
      <>
        Tất cả buổi học thử sẽ tập trung thảo luận.
        <br />
        Buổi học trả phí có thể tùy chọn tập trung thảo luận hoặc tập trung sửa lỗi.
      </>,
    ],
    note_short: [
      `15분 대화 - 5분 피드백`,
      `15m conversation - 5m feedback`,
      's_lesson_style.lesson_mode.note_short',
      `15分钟对话 - 5分钟反馈`,
      `15分鐘對話 - 5分鐘意見回饋`,
      `15分会話 - 5分フィードバック`,
      `15 phút hội thoại - 5 phút feedback`,
    ],
    correction: [
      `교정 중심`,
      `Paraphrasing-Focused`,
      's_lesson_style.lesson_mode.correction',
      `以纠正为主`,
      `以糾正為主`,
      `校正中心`,
      `Tập trung sửa lỗi`,
    ],
    discussion: [
      `토론 중심`,
      `Discussion-Focused`,
      's_lesson_style.lesson_mode.discussion',
      `以讨论为主`,
      `以討論為主`,
      `ディスカッション中心`,
      `Tập trung thảo luận`,
    ],
  },
  mode_detail: {
    correction: {
      instant: [
        `상시교정(틀린 영어 표현을 할 때마다 교정 받음)`,
        `Rephrase my English as soon as possible`,
        's_lesson_style.mode_detail.correction.instant',
        `随时纠正(每当出现英语错误表达时均予以纠正)`,
        `隨時糾正（每當出現錯誤的英語用法時接受糾正）`,
        `常時校正(間違った英語表現をするたびに校正してもらう)`,
        `Sửa ngay khi tôi mắc lỗi`,
      ],
      instant_short: [
        `상시교정`,
        `Instant Paraphrasing`,
        's_lesson_style.mode_detail.correction.instant_short',
        `随时纠正`,
        `隨時糾正`,
        `常時校正`,
        `Sửa lỗi ngay`,
      ],
      intermittent: [
        `대화 교정모드(각 질문에 대한 대화가 끝난 후 교정 받음)`,
        `Rephrase my English after each of my answers`,
        's_lesson_style.mode_detail.correction.intermittent',
        `对话纠正模式(各问题对话结束后进行纠正)`,
        `對話糾正模式（針對各提問的對話結束後接受糾正）`,
        `会話訂正モード(各質問の会話が終わった後に訂正してもらう)`,
        `Sửa cho tôi sau khi thảo luận câu hỏi`,
      ],
      intermittent_short: [
        `대화 교정모드`,
        `Intermittent Paraphrasing`,
        's_lesson_style.mode_detail.correction.intermittent_short',
        `对话纠正模式`,
        `對話糾正模式`,
        `会話訂正モード`,
        `Sửa lỗi ngắt quãng`,
      ],
    },
    discussion: {
      mode55: [
        `5:5 토론(튜터와 내가 절반씩 이야기를 주고 받음)`,
        `I want my tutor to speak as much as I do (5:5)`,
        's_lesson_style.mode_detail.discussion.mode55',
        `5:5讨论(教师和本人听说各占一半)`,
        `5:5討論（任課教師與我各發言一半）`,
        `5:5 ディスカッション(チューターと受講生が半分ずつ話す)`,
        `Tôi muốn gia sư nói nhiều như tôi (5:5)`,
      ],
      mode55_short: [
        `5:5 토론`,
        `5:5 Discussion`,
        's_lesson_style.mode_detail.discussion.mode55_short',
        `5:5讨论`,
        `5:5討論`,
        `5:5 ディスカッション`,
        `Thảo luận 5:5`,
      ],
      mode82: [
        `8:2 토론(튜터는 듣고 내가 주로 이야기함)`,
        `I want to speak more than than my tutor (8:2)`,
        's_lesson_style.mode_detail.discussion.mode82',
        `8:2讨论(教师以听为主, 侧重于学员会话)`,
        `8:2討論（任課教師聆聽，主要由我發言）`,
        `8:2 ディスカッション(チューターは聴いて受講生が主に話す)`,
        `Tôi muốn nói nhiều hơn gia sư (8:2)`,
      ],
      mode82_short: [
        `8:2 토론`,
        `8:2 Discussion`,
        's_lesson_style.mode_detail.discussion.mode82_short',
        `8:2讨论`,
        `8:2討論`,
        `8:2 ディスカッション`,
        `Thảo luận 8:2`,
      ],
    },
  },
  intensive_focus: {
    title: [
      `집중 교정 영역 (다중 선택 가능)`,
      `Focus Areas (Multi-selectable)`,
      's_lesson_style.intensive_focus.title',
      `集中纠正部分(可进行多选)`,
      `集中糾正領域（可複選）`,
      `集中校正分野 (複数選択可)`,
      `Phần sửa lỗi (Có thể chọn nhiều)`,
    ],
    detail: [
      `집중 교정`,
      `Focus Areas`,
      's_lesson_style.intensive_focus.detail',
      `集中纠正`,
      `集中糾正`,
      `集中校正`,
      `Phần sửa lỗi`,
    ],
  },
  additional_request: {
    title: [
      `상세 요청 사항 (다중 선택 가능)`,
      `Additional Requests (Multi-selectable)`,
      's_lesson_style.additional_request.title',
      `具体要求事项(可进行多选)`,
      `具體要求事項（可複選）`,
      `リクエスト項目 (複数選択可)`,
      `Yêu cầu thêm (Có thể chọn nhiều)`,
    ],
    detail: [
      `상세 요청`,
      `Additional requests`,
      's_lesson_style.additional_request.detail',
      `具体要求`,
      `具體要求`,
      `詳細リクエスト`,
      `Yêu cầu thêm`,
    ],
    placeholder: [
      `튜터에게 구체적으로 원하는 수업 방식을 영어로 남겨주세요!`,
      `Feel free to enter any other requests.`,
      's_lesson_style.additional_request.placeholder',
      `请用英文向教师提出课程希望的具体形式!`,
      `請以英文具體寫下希望任課教師進行的上課方式！`,
      `チューターに具体的に希望するレッスン方式を英語で入力してください！`,
      `Thoải mái bổ sung thêm yêu cầu khác.`,
    ],
    other_request: [
      `추가 요청`,
      `Specific request`,
      's_lesson_style.additional_request.other_request',
      `附加要求`,
      `額外要求`,
      `追加リクエスト`,
      `Yêu cầu khác`,
    ],
  },
}
export const s_portal_webinar = {
  lesson_enter_modal_title: [
    `웨비나 입장 안내`,
    `Enter Live Webinar`,
    's_portal_webinar.lesson_enter_modal_title',
    `网络研讨会入场介绍`,
    `網路研討會進場說明`,
    `ウェビナー入室方法のご案内`,
    `Tham gia Webinar trực tuyến`,
  ],
  speed: [
    `재생 속도`,
    `Playback Speed`,
    's_portal_webinar.speed',
    `加载速度`,
    `播放速度`,
    `再生速度`,
    `Tốc độ phát lại`,
  ],
  normal: [`(보통)`, `(normal)`, 's_portal_webinar.normal', `(普通)`, `（普通）`, `(普通)`, `(bình thường)`],
  lesson_enter_modal_content: [
    <>
      <Span size={12}>웨비나 시작 15분 전부터 입장하실 수 있습니다.</Span>
    </>,
    <>
      <Span size={12}>You can enter live webinars up to 15 minutes before the scheduled start time.</Span>
    </>,
    's_portal_webinar.lesson_enter_modal_content',
    <>
      <Span size={12}>网络研讨会开始前15分钟可进场。</Span>
    </>,
    <>
      <Span size={12}>網路研討會開始15分鐘前可進場。</Span>
    </>,
    <>
      <Span size={12}>ウェビナー開始15分前から入室することができます。</Span>
    </>,
    <>
      <Span size={12}>Bạn có thể tham dự webinar sớm hơn 15 phút trước giờ bắt đầu.</Span>
    </>,
  ],
  viewAll: [`전체보기`, `View All`, 's_portal_webinar.viewAll', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  clips: [`클립 목록`, `Clips`, 's_portal_webinar.clips', `视频片段`, `片段清單`, `再生リスト`, `Clips`],
  currentApply: (count) => [
    <>현재 신청 {count}명</>,
    <>{count} people RSVP-ed</>,
    's_portal_webinar.currentApply',
    <>当前申请 {count}名</>,
    <>目前申請：{count}人</>,
    <>現在予約済 {count}名</>,
    <>{count} người tham gia</>,
  ],
  totalAttendee: (total) => [
    <>전체 정원 {total}명</>,
    <>Up to {total} attendees</>,
    's_portal_webinar.totalAttendee',
    <>总名额 {total}名</>,
    <>總名額：{total}人</>,
    <>定員 {total}名</>,
    <>{total} chỗ trống</>,
  ],
  doc: {
    search: {
      placeholder: [
        `튜터명과 주제등을 검색하세요`,
        `Enter keyword(s) to search.`,
        's_portal_webinar.doc.search.placeholder',
        `请搜索教师姓名或主题等关键词`,
        `請搜尋任課教師姓名與主題等`,
        `チューター名やテーマなどを検索してください。`,
        `Nhập từ khóa để tìm kiếm.`,
      ],
      label: [`검색하기`, `Search`, 's_portal_webinar.doc.search.label', `搜索`, `搜尋`, `検索する`, `Tìm kiếm`],
    },
  },
  register: [
    `자세히 보기`,
    `View detail`,
    's_portal_webinar.register',
    `详细查看`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  lucky_draw_enroll: [
    `이벤트 참여`,
    `Enter Event`,
    's_portal_webinar.lucky_draw_enroll',
    `参与活动`,
    `參與活動`,
    `イベント参加`,
    `Tham dự sự kiện`,
  ],
  related_material: [
    `관련 교재`,
    `Related Materials`,
    's_portal_webinar.related_material',
    `相关教材`,
    `相關教材`,
    `関連教材`,
    `Tài liệu liên quan`,
  ],
  related_article: [
    `관련 링크`,
    `Related Links`,
    's_portal_webinar.related_article',
    `相关新闻报道`,
    `相關報導`,
    `関連記事`,
    `Tin tức liên quan`,
  ],
  related_video: [
    `관련 기사`,
    `Related Videos`,
    's_portal_webinar.related_video',
    `相关新闻报道`,
    `相關報導`,
    `関連記事`,
    `Video liên quan`,
  ],
  lesson_cancel_modal_title: [
    `링글 라이브 신청 취소`,
    `Cancel Ringle LIVE RSVP`,
    's_portal_webinar.lesson_cancel_modal_title',
    `取消Ringle LIVE的申请`,
    `取消Ringle LIVE的申請`,
    `Ringle LIVEの申込を取り消し`,
    `Hủy đăng ký Ringle LIVE`,
  ],
  lesson_cancel_modal_content: [
    `신청한 링글 라이브를 취소하시겠습니까?`,
    `Do you want to cancel Ringle LIVE RSVP?`,
    's_portal_webinar.lesson_cancel_modal_content',
    `确定要取消Ringle LIVE的申请吗？`,
    `確定要取消Ringle LIVE的申請嗎？`,
    `申込をしたRingle LIVEを取り消ししますか？`,
    `Bạn có muốn hủy đăng ký Ringle LIVE?`,
  ],
  lesson_cancel_modal_subject: [
    `주제`,
    `Topic`,
    's_portal_webinar.lesson_cancel_modal_subject',
    `主题`,
    `主題`,
    `テーマ`,
    `Chủ đề`,
  ],
  lesson_cancel_modal_time: [
    `시간`,
    `Time`,
    's_portal_webinar.lesson_cancel_modal_time',
    `时间`,
    `時間`,
    `時間`,
    `Thời gian`,
  ],
}
export const s_webinar = {
  like: [`찜하기`, `Save`, 's_webinar.like', `收藏`, `收藏`, `お気に入り登録`, `Lưu`],
  apply_description: {
    li_1: [
      `구글 계정을 사용하시면 '내 구글 캘린더'에 자동으로 일정이 추가되며 웨비나 당일 1시간 전 앱 푸시 알림을 받아볼 수 있습니다.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      's_webinar.apply_description.li_1',
      `如果您使用谷歌账号，日程将自动添加到"我的谷歌日历”中，您可以在网络研讨会当天1小时前收到应用推送通知。`,
      `如果您使用谷歌賬號，日程將自動添加到"我的谷歌日曆”中，您可以在網絡研討會當天1小時前收到應用推送通知。`,
      `Googleアカウントを使用すると「Googleカレンダー」に自動でスケジュールが追加され、ウェビナー当日開始1時間前に通知を受け取る設定ができます。`,
      `Nếu bạn xác nhận qua gmail, buổi Webinar sẽ được thêm trực tiếp vào Google Calendar của bạn và sẽ gửi thông báo 10 phút trước khi bắt đầu.`,
    ],
    li_2: [
      `링글 LIVE 수업 녹화 영상은 세션 종료 후 최대 7일 이내로 ‘[링글 홈] > [콘텐츠] > [웨비나]’에 업데이트됩니다.`,
      `Live webinars recordings are uploaded on Ringle's website and app under '[Contents] > [Webinar]' within 7 days from live streaming.`,
      's_webinar.apply_description.li_2',
      `Ringle 直播课程的录像视频将在会话结束后最多7天内更新到 "[主页] > [内容] > [网络研讨会]” 上。`,
      `Ringle 直播課程的錄像視頻將在會話結束後最多7天內更新到 "[主頁]>[內容]>[網絡研討會]” 上。`,
      `リンガルLIVEレッスンの録画映像はセッション終了後、最大7日以内に[リンガルホーム]>[コンテンツ]>[ウェビナー]内にアップデートされます。`,
      `Video buổi học LIVE trên Ringle sẽ được Update trong mục ‘[Ringle HOME] > [Nội dung] > [Hội thảo trực tuyến]’ trong vòng 7 ngày sau khi kết thúc buổi học.`,
    ],
    li_3: [
      `수업 녹화 영상 링크와 자료는 수업 후 2~3일 내로 링글 LIVE를 신청한 링글 계정 이메일로 공유드립니다.`,
      `The materials used during the webinar and the link to the webinar recording will be shared to the RSVPed email addresses within 2-3 days from live streaming.`,
      's_webinar.apply_description.li_3',
      `课程录像视频链接和资料将在课后 2~3 天内发送到申请直播课的 Ringle 账号邮箱内。`,
      `課程錄像視頻鏈接和資料將在課後 2~3 天內發送到申請直播課的 Ringle 賬號郵箱內。`,
      `レッスン録画映像リンクと資料はレッスン後2～3日以内にリンガルLIVEを申し込んだリンガルアカウントのメールを通して共有いたします。`,
      `Link Video buổi học và tài liệu sẽ được chia sẻ trong vòng 2 đến 3 ngày qua địa chỉ Email bạn đã dùng để đăng ký Ringle LIVE.`,
    ],
    li_4: [
      `링글 LIVE와 관련하여 문의사항이 있으시면, [홈페이지 1:1 문의창 > 링글 콘텐츠 > 웨비나] 를 통해 문의사항을 남겨주세요! (* 1:1 문의 : 홈페이지 화면 우측 하단 보라색 말풍선)`,
      `If you have any questions about Ringle LIVE, please leave a message via the 1:1 chat support at the bottom right of the Ringle homepage (click 'Ringle contents' > 'Webinars').`,
      's_webinar.apply_description.li_4',
      `如果您对 Ringle 直播课有疑问，请通过 [主页 1:1 咨询窗口 > Ringle 内容 > 网络研讨会] 留下咨询事项！（* 1:1 咨询: 主页右下角紫色对话框）`,
      `如果您對 Ringle 直播課有疑問，請通過 [主頁 1:1 諮詢窗口 > Ringle 內容 > 網絡研討會] 留下諮詢事項！（* 1:1 諮詢: 主頁右下角紫色對話框）`,
      `リンガルLIVEと関連してお問い合わせがありましたら、[ホームページ１：１問い合わせチャット>リンガルコンテンツ>ウェビナー]を通してお問い合わせ内容をお送りください！（※１：１問い合わせ：ホームページ画面右下の紫のふきだし）`,
      `Nếu có điều gì thắc mắc về Ringle LIVE, vui lòng để lại câu hỏi cho chúng tôi trong mục [Trang Tư vấn 1:1 tại Homepage > Nội dung Ringle > Hội thảo trực tuyến]! (* Tư vấn 1:1 : Biểu tượng Chat ở góc phải bên dưới Homepage)`,
    ],
    li_5: [
      `수업 녹화 영상과 자료는 연사의 요청에 따라 공개되지 않을 수 있습니다.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      's_webinar.apply_description.li_5',
      `根据讲师的要求，录像视频和资料有可能不被公开。`,
      `根據講師的要求，錄像視頻和資料有可能不被公開。`,
      `レッスン録画映像と資料は講師の要望により公開されない場合があります。`,
      `Video buổi học và tài liệu có thể không được công khai tùy theo yêu cầu của diễn giả.`,
    ],
    sign_up_now: [
      `링글 LIVE 안내`,
      `Ringle LIVE Info`,
      's_webinar.apply_description.sign_up_now',
      `直播网络研讨会说明事项`,
      `即時網路研討會說明事項`,
      `Ringle LIVEのご案内`,
      `Ringle LIVE Info`,
    ],
    sign_up_now_paid: [
      `링글 LIVE 안내`,
      `Ringle LIVE Info`,
      's_webinar.apply_description.sign_up_now_paid',
      `Ringle LIVE Info`,
      `Ringle LIVE Info`,
      `Ringle LIVE Info`,
      `Ringle LIVE Info`,
    ],
    li_1_paid: [
      `링글 유료 라이브의  베타 기간동안,  라이브 신청 및 결제는 전문 행사 플랫폼 이벤터스에서 진행됩니다.`,
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
      's_webinar.apply_description.li_1_paid',
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
    ],
    li_2_paid: [
      `신청 마감 안내 라이브 신청은 세션 시작 3시간 전 마감되며, 신청 마감 시간 이후 취소 및 환불은 불가합니다.`,
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
      's_webinar.apply_description.li_2_paid',
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
    ],
    li_3_paid: [
      `취소/환불 안내 취소 및 환불은 결제하신 이벤터스 페이지에서 진행하시면 되며, 관련 문의는 이벤터스 ‘호스트에게 문의하기’ 를 통해 문의하실 수 있습니다.`,
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
      's_webinar.apply_description.li_3_paid',
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
    ],
    li_4_paid: [
      `참여 가능한 줌 링크는 세션 시작 1시간 전 이벤터스를 통해 남겨주신 문자 및 이메일을 통해 발송드립니다.`,
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
      's_webinar.apply_description.li_4_paid',
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
    ],
    li_5_paid: [
      `해당 라이브의 다시보기 링크는 구매하신 분께만 별도로 전송됩니다. 다만, 수업자료와 다시 보기 영상의 경우 수업의 성격에 따라 무료 공유가 제한될 수 있습니다.`,
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
      's_webinar.apply_description.li_5_paid',
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
    ],
  },
  apply_float: {
    download: [
      `자료 받기`,
      `Download Materials`,
      's_webinar.apply_float.download',
      `下载资料`,
      `下載資料`,
      `資料ダウンロード`,
      `Tải Tài liệu`,
    ],
    register: [`신청하기`, `RSVP`, 's_webinar.apply_float.register', `申请`, `申請`, `予約`, `Xác nhận tham gia`],
    completed: [
      `신청 완료`,
      `RSVP-ed`,
      's_webinar.apply_float.completed',
      `完成申请`,
      `申請完成`,
      `予約完了`,
      `Đã xác nhận tham gia`,
    ],
    cancel: [
      `신청 취소`,
      `Cancel RSVP`,
      's_webinar.apply_float.cancel',
      `取消申请`,
      `取消申請`,
      `予約キャンセル`,
      `Hủy đăng ký tham gia`,
    ],
    enter: [`입장하기`, `Enter`, 's_webinar.apply_float.enter', `入场`, `進場`, `入室する`, `Tham gia`],
    enterLong: [
      `입장하기`,
      `Enter Live Webinar`,
      's_webinar.apply_float.enterLong',
      `入场`,
      `進場`,
      `入室する`,
      `Tham gia buổi webinar trực tuyến`,
    ],
    register_paid: (amount) => [
      <>신청하러 가기 ({amount}원)</>,
      <>register ({amount}KRW) </>,
      's_webinar.apply_float.register_paid',
      <>register ({amount}KRW) </>,
      <>register ({amount}KRW) </>,
      <>register ({amount}KRW) </>,
      <>register ({amount}KRW) </>,
    ],
  },
  a_tutor_will_be_added_soon: [
    `튜터가 곧 추가될 예정입니다.`,
    `A tutor will be added soon.`,
    's_webinar.a_tutor_will_be_added_soon',
    `即将添加教师。`,
    `預計新增任課教師。`,
    `チューターがまもなく追加される予定です。`,
    `Gia sư sẽ sớm được thêm vào.`,
  ],
  tab_summary: [`개요`, `Overview`, 's_webinar.tab_summary', `概要`, `概要`, `概要`, `Tổng quan`],
  tab_tutor: (name) => [
    `${name}의 다른 웨비나`,
    `More webinars by ${name}`,
    's_webinar.tab_tutor',
    `${name}的其他网络研讨会`,
    `${name}的其他網路研討會`,
    `${name}さんの他のウェビナー`,
    `Các webinar khác với ${name}`,
  ],
  introduce_of_out_tutor: [`Tutor`, `Tutor`, 's_webinar.introduce_of_out_tutor', `Tutor`, `Tutor`, `Tutor`, `Gia sư`],
  search_result: [
    `웨비나 검색 결과`,
    `Search Results`,
    's_webinar.search_result',
    `网络研讨会搜索结果`,
    `網路研討會搜尋結果`,
    `ウェビナー検索結果`,
    `Kết quả tìm kiếm`,
  ],
  no_result: [
    `😣  키워드에 맞는 검색 결과가 없습니다.`,
    `😣  No Result`,
    's_webinar.no_result',
    `😣  没有符合关键词的搜索结果。`,
    `😣  沒有符合關鍵字的搜尋結果。`,
    `😣  キーワードに合う検索結果はありません。`,
    `😣  Không có kết quả`,
  ],
  search_webinar: [
    `웨비나 검색`,
    `Search`,
    's_webinar.search_webinar',
    `搜索网络研讨会`,
    `搜尋網路研討會`,
    `ウェビナー検索`,
    `Tìm kiếm`,
  ],
  apply: [
    `웨비나 신청`,
    `RSVP`,
    's_webinar.apply',
    `申请网络研讨会`,
    `申請網路研討會`,
    `ウェビナー予約`,
    `Xác nhận đăng ký`,
  ],
  need_login: [
    <>
      선택하신 웨비나를 보시려면 로그인이 필요합니다.
      <br />
      링글 회원에게만 제공되는 300+개의 무료 웨비나를 확인해보세요.
    </>,
    <>
      Please log in to watch the webinar you selected.
      <br />
      Check 300+ webinars hosted by Ringe's best tutors.
    </>,
    's_webinar.need_login',
    <>
      如果想观看选择的网络研讨会, 请先登录。
      <br />
      请确认超过300部提供给Ringle会员的免费网络研讨会资料。
    </>,
    <>
      欲觀看選擇的網路研討會需先登入。
      <br />
      請確認300多個只提供給Ringle會員的免費網路研討會。
    </>,
    <>
      選択したウェビナーを見るにはログインが必要です。
      <br />
      Ringle会員にのみ提供される300+本の無料ウェビナーを確認してみましょう。
    </>,
    <>
      Vui lòng đăng nhập để xem webinar bạn đã chọn.
      <br />
      Khám phá 300+ webinar từ những gia sư giỏi nhất của Ringle.
    </>,
  ],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_webinar.login_desc',
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
  ],
  login: [`로그인`, `Log in`, 's_webinar.login', `Log in`, `Log in`, `Log in`, `Log in`],
  view_detail: [
    `더 알아보기`,
    `View detail`,
    's_webinar.view_detail',
    `View detail`,
    `View detail`,
    `View detail`,
    `View detail`,
  ],
}
export const s_tips = {
  title: [
    `링글 활용법`,
    `How Ringle works`,
    's_tips.title',
    `Ringle使用方法`,
    `Ringle活用方法`,
    `Ringleの活用方法`,
    `Cách Ringle hoạt động`,
  ],
}
export const s_schedule_enter = {
  go: [`예약하기`, `Go`, 's_schedule_enter.go', `预约`, `預約`, `予約する`, `Kiểm tra`],
  purchase: [
    `수업권 구매하러 가기`,
    `Buy now`,
    's_schedule_enter.purchase',
    `购买课程券`,
    `前往購買課程券`,
    `レッスンチケットを購入する`,
    `Mua ngay`,
  ],
  challenge_book_title: (date) => [
    <>도전 기간: {date}</>,
    <>Challenge Period: {date}</>,
    's_schedule_enter.challenge_book_title',
    <>挑战期间: {date}</>,
    <>挑戰期間：{date}</>,
    <>挑戦期間: {date}</>,
    <>Thời gian thử thách: {date}</>,
  ],
  challenge_book_duration: (duration) => [
    <>도전 기간: {duration}일 (첫 수업 완료 후 시작)</>,
    <>Challenge Period: {duration} days (Countdown will start once you complete your first lesson)</>,
    's_schedule_enter.challenge_book_duration',
    <>挑战期间: {duration}日 (首次课程结束后开始)</>,
    <>挑戰期間：{duration}日（首次課程結束後開始）</>,
    <>挑戦期間: {duration}日 (初回レッスン完了後開始)</>,
    <>Thời gian thử thách: {duration} ngày (Đếm ngược bắt đầu khi bạn hoàn thành buổi học đầu tiên)</>,
  ],
  challenge_book_content: (goal, bonus) => [
    `${goal}회 수업권을 도전 기간 내 모두 사용하면 ${bonus}회 추가 정규수업권이 제공됩니다. (*수업 노쇼/취소도 수업권 [사용]으로 인정)`,
    `If you use up all your credits during the Challenge Period, we will provide you with additional credits.`,
    's_schedule_enter.challenge_book_content',
    `${goal}次课程券于挑战期间全部使用后将提供${bonus}次正规课程券。 (*旷课/取消时也被视为[使用])`,
    `於挑戰期間內將${goal}次課程券全數使用時，額外提供${bonus}次正規課程券。（*上課未出現／取消也視同[使用]課程券）`,
    `${goal}回レッスンチケットを挑戦期間内にすべて使用すると、${bonus}回追加でレギュラーレッスンチケットが提供されます。 (*レッスン無断欠席/キャンセルもレッスンチケット [使用]として認定)`,
    `Nếu bạn dùng hết các thẻ học trong khoảng Thời gian thử thách, chúng tôi sẽ tặng thêm thẻ học cho bạn.`,
  ],
  lesson_book_title: [
    <>[수업권 챌린지] 장기권 혜택으로 당일수업권 제공</>,
    <>Last-minute Credits</>,
    's_schedule_enter.lesson_book_title',
    <>提供24小时补充课程</>,
    <>提供24小時補充課程</>,
    <>24時間補充レッスン進呈</>,
    <>Thẻ học bù</>,
  ],
  lesson_book_content: [
    `매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공해 드립니다.`,
    `For every four completed lessons, you’ll get one Last-minute Credit that can be used for [Last-minute Lessons].`,
    's_schedule_enter.lesson_book_content',
    `每使用六次正规课程券后, 将获得可于24小时内使用的补充课程券。`,
    `每耗盡4次正規課程，提供24小時以內可使用的補充課程券。`,
    `レギュラーレッスンチケット4回使用で、24時間以内に受講可能な補充レッスン1回分が付与されます。`,
    `Cứ 4 buổi học bạn hoàn thành, bạn sẽ nhận được một thẻ học bù và có thể sử dụng trong vòng 24 giờ.`,
  ],
  used_challenge: (used) => [
    `${used}회 달성`,
    `Achieved ${used}`,
    's_schedule_enter.used_challenge',
    `完成${used}次`,
    `達成${used}次`,
    `${used}回達成`,
    `${used} buổi học`,
  ],
  used: (used) => [
    `${used}회 사용`,
    `${used}`,
    's_schedule_enter.used',
    `使用${used}次`,
    `使用${used}次`,
    `${used}回使用`,
    `${used}`,
  ],
  received: (received) => [
    `${received}회 받음`,
    `Received ${received}`,
    's_schedule_enter.received',
    `领取${received}次`,
    `領取${received}次`,
    `${received}回付与`,
    `Được tặng ${received} buổi học`,
  ],
  available: [
    `남은 수업권`,
    `Remaining Lesson Credit(s)`,
    's_schedule_enter.available',
    `所剩课程券`,
    `所剩課程券`,
    `残りのレッスンチケット`,
    `Thẻ học còn lại`,
  ],
  select: [
    `수업 선택`,
    `Select Lesson`,
    's_schedule_enter.select',
    `选择课程`,
    `選擇課程`,
    `レッスン選択`,
    `Chọn buổi học`,
  ],
  expired: [`수강 기간`, `Expires`, 's_schedule_enter.expired', `课程期限`, `聽課期間`, `有効期限`, `Ngày kết thúc`],
  expiredIn: (days) => [
    <>{days}일 남음</>,
    <>in {days} days</>,
    's_schedule_enter.expiredIn',
    <>剩余{days}日</>,
    <>剩下{days}日</>,
    <>残り{days}日</>,
    <>trong {days} ngày</>,
  ],
  select_time_first: {
    heading: [
      `시간 먼저 선택`,
      `Sort by time`,
      's_schedule_enter.select_time_first.heading',
      `请先选择时间`,
      `先選擇時間`,
      `時間帯を選ぶ`,
      `Chọn theo giờ`,
    ],
    i_will_choose_a_time_slot_first: [
      <>원하는 수업 시간을 먼저 선택할 수 있습니다.</>,
      `See available tutors for selected time`,
      's_schedule_enter.select_time_first.i_will_choose_a_time_slot_first',
      <>请先选择希望的课程时间。</>,
      <>
        可先選擇
        <br />
        希望的課程時間。
      </>,
      <>
        希望する受講時間帯から
        <br />
        レッスンを選びます。
      </>,
      `Xem những gia sư trống lịch trong thời gian được chọn`,
    ],
  },
  select_tutor_first: {
    heading: [
      `튜터 먼저 선택`,
      `Sort by tutor`,
      's_schedule_enter.select_tutor_first.heading',
      `请先选择教师`,
      `先選擇任課教師`,
      `チューターを選ぶ`,
      `Chọn gia sư`,
    ],
    i_will_choose_a_tutor_first: [
      <>
        원하는 튜터를
        <br />
        먼저 선택할 수 있습니다.
      </>,
      `See available slots of selected tutors`,
      's_schedule_enter.select_tutor_first.i_will_choose_a_tutor_first',
      <>
        请先选择
        <br />
        满意的教师。
      </>,
      <>
        可先選擇
        <br />
        希望的任課教師。
      </>,
      <>
        希望するチューターの
        <br />
        空き状況から選びます。
      </>,
      `Xem các ca trống của những gia sư được chọn`,
    ],
  },
  my_coupons: [
    `내 수업권`,
    `My Credits`,
    's_schedule_enter.my_coupons',
    `我的课程券`,
    `我的課程券`,
    `マイレッスンチケット`,
    `Thẻ học của tôi`,
  ],
  no_coupons: [
    `수업을 예약할 수 있는 수업권이 없습니다.`,
    `You have no lesson credit`,
    's_schedule_enter.no_coupons',
    `没有可预约的课程券。`,
    `無可預約課程的課程券。`,
    `レッスンを予約できるレッスンチケットはありません。`,
    `Bạn không còn thẻ học nào.`,
  ],
  min: [`분`, `min`, 's_schedule_enter.min', `分钟`, `分鐘`, `分`, `phút`],
  free: [`무료`, `Free`, 's_schedule_enter.free', `免费`, `免費`, `無料`, `Miễn phí`],
  regular_credits: [
    `정규수업권`,
    `Regular`,
    's_schedule_enter.regular_credits',
    `正规课程券`,
    `正規課程券`,
    `レギュラーレッスンチケット`,
    `Thẻ học tiêu chuẩn`,
  ],
  limited_window_credits: [
    `시간한정수업권`,
    `Limited window credits`,
    's_schedule_enter.limited_window_credits',
    `时间限定课程券`,
    `時間限定課程券`,
    `時間限定レッスンチケット`,
    `Thẻ học giờ đặc biệt`,
  ],
  makeup_credits: [
    `당일수업권`,
    `Last-minute`,
    's_schedule_enter.makeup_credits',
    `当日课程券`,
    `當日課程券`,
    `補充レッスンチケット`,
    `Thẻ học bù`,
  ],
  regular_tooltip: [
    `정규수업권 1회로 정규 시간 내 1:1 수업을 1회 자유롭게 이용가능하며, [당일수업] 예약에도 사용할 수 있습니다.`,
    `Regular Credits can be used to book any regular lessons. You can also book a lesson available in 24 hours with Regular Credits.`,
    's_schedule_enter.regular_tooltip',
    `一张正规课程券可以预约一次1:1课程，也可以预约[当日课程] 。`,
    `一張正規課程券可以預約一次1:1課程，也可以預約[當日課程] 。`,
    `レギュラーレッスンチケット1つで1回の1on1レッスンを受講できます。
また、24時間前のレッスンもレギュラーレッスンチケットで予約／受講できます。`,
    `Bạn có thể đặt lịch học bình thường với thẻ học tiêu chuẩn. Bạn cũng có thể đặt lịch học bù trong 24 giờ tới`,
  ],
  limited_tooltip: [
    `시간한정수업권은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited window credits can only be used for certain time slots.
Morning: Lessons starting between 5am & 7am (KST)
Midday: Lessons starting between 11am & 1pm (KST)
Late night: Lessons starting between 12am & 2am (KST)`,
    's_schedule_enter.limited_tooltip',
    `时间限定课程券只能于以下时间使用
凌晨班 - 上午5点～8点（韩国标准时间）
深夜班 - 晚上12点 ~ 凌晨3点 (韩国时间)`,
    `時間限定課程券只能於以下時間使用
早鳥班 - 上午5點～8點（韓國標準時間）
深夜班 - 晚上12點 ~ 凌晨3點 (韓國時間)`,
    `時間限定レッスンチケットは時間帯による３つのレッスンチケットで構成されています。
早朝：午前5時～午前7時（開始時間基準）
ランチタイム：午前11時～午後1時（開始時間基準）
深夜：午前0時～午前2時（開始時間基準）`,
    `Thẻ học giờ đặt biệt chỉ có thể đặt lịch học cho khoảng thời gian nhất định Sáng: các buổi học bắt đầu từ 5-7 giờ sáng (Giờ Hàn Quốc) Trưa: các buổi học bắt đầu từ 11 giờ sáng-13 giờ chiều (Giờ Hàn Quốc) Khuya: các buổi học bắt đầu từ 12 giờ khuya-2 giờ sáng (Giờ Hàn Quốc)`,
  ],
  makeup_tooltip: [
    `당일수업권이란 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있는 수업권으로, [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
    `[Last-minute Credits] are lesson credits that can only be used to reserve [Lessons starting within 24 hours] from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
    's_schedule_enter.makeup_tooltip',
    `当日课程券只能预约24小时内开始的课程，[1:1 课程] > [预约] > [可预约的当天课程]。`,
    `當日課程券只能預約24小時內開始的課程，[1:1 課程] > [預約] > [可預約的當天課程]。`,
    `補充レッスンは24時間以内に受講可能なレッスンです。
補充レッスンチケットは予約ページで[24時間以内の授業]を予約するときに利用できます。`,
    `Buổi học bù là những buổi học diễn ra trong 24 giờ tới. Bạn sẽ sử dụng thẻ học bù để đặt.`,
  ],
  selected_material: [
    `[교재 선택 > 찜한 교재] 에서 확인 할 수 있습니다.`,
    `View in [Select Materials > Favorites]`,
    's_schedule_enter.selected_material',
    `View in [Select Materials > Favorites]`,
    `View in [Select Materials > Favorites]`,
    `[教材選択＞お気に入りの教材]で確認することができます。`,
    `Xem tại [Chọn Tài liệu > Yêu thích]`,
  ],
}
export const s_unassigned_lessons = {
  n_left: (n) => [
    <>{n}개</>,
    <>{n} left</>,
    's_unassigned_lessons.n_left',
    <>{n}个</>,
    <>{n}個</>,
    <>{n}回</>,
    <>{n} buổi học</>,
  ],
  credit_choice: [
    `수업권 선택하기`,
    `Select Lesson Credit`,
    's_unassigned_lessons.credit_choice',
    `选择课程券`,
    `選擇課程券`,
    `レッスンチケットを選択`,
    `Chọn thẻ học`,
  ],
  credits: {
    regular_credit: [
      `정규수업권`,
      `Regular Credit`,
      's_unassigned_lessons.credits.regular_credit',
      `正规课程券`,
      `正規課程券`,
      `レギュラーレッスンチケット`,
      `Thẻ học tiêu chuẩn`,
    ],
    makeup_credit: [
      `당일수업권`,
      `Last-minute Credit`,
      's_unassigned_lessons.credits.makeup_credit',
      `补充课程券`,
      `補充課程券`,
      `補充レッスンチケット`,
      `Thẻ học bù`,
    ],
    urgent_credit: [
      `긴급편성수업권`,
      `FastBook Lesson Credit`,
      's_unassigned_lessons.credits.urgent_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    no_credits_available: [
      `사용할 수 있는 수업권이 없습니다.`,
      `No credits available`,
      's_unassigned_lessons.credits.no_credits_available',
      `没有可使用的课程券。`,
      `無可使用的課程券。`,
      `使用できるレッスンチケットはありません。`,
      `Không còn thẻ học nào`,
    ],
  },
  expires_in_n_days: (n) => [
    <>수강기간: {n}일</>,
    <>Expires in: {n} days</>,
    's_unassigned_lessons.expires_in_n_days',
    <>课程期限: {n}日</>,
    <>聽課期間：{n}日</>,
    <>受講期間: {n}日</>,
    <>Hết hạn trong: {n} ngày</>,
  ],
  info: {
    title: [
      `예약 가능한 당일수업`,
      `Last-minute lessons available`,
      's_unassigned_lessons.info.title',
      `可预约的当日课程`,
      `可預約的當日課程`,
      `予約可能な当日レッスン`,
      `Buổi học trong ngày có thể đặt`,
    ],
    what_is_makeup_lesson: [
      `당일수업이란?`,
      `What are lessons available in 24 hours?`,
      's_unassigned_lessons.info.what_is_makeup_lesson',
      `24小时以内的课程?`,
      `24小時以內的課程意指？`,
      `24時間以内のレッスンとは？`,
      `Những buổi học nào trống trong 24 giờ tới?`,
    ],
    makeup_lesson_desc: [
      <>
        - 예약 시점 기준 24시간 이내에 시작하는 수업입니다. (타 수강생 취소 시 발생)
        <br />- 정규수업권으로 예약하는 경우 포인트가 추가 적립됩니다. (40분: ￦3,000P / 20분: ￦1,000P){' '}
      </>,
      `These are lessons that opened up due to last-minute cancellations by other students.`,
      's_unassigned_lessons.info.makeup_lesson_desc',
      `指被其他学生取消, 将于24小时以内开始的课程。`,
      `意指其他學生所取消且距離課程開始不到24小時的課程。`,
      `他の受講生がキャンセルした、レッスン開始まで24時間以内のレッスンを意味します。`,
      `Đây là những buổi học được mở vì học viên khác đã huỷ sát giờ.`,
    ],
    label_info: [
      `2. 라벨 설명`,
      `2. Label Description`,
      's_unassigned_lessons.info.label_info',
      `2.等级说明`,
      `2.等級說明`,
      `2.ラベル説明`,
      `2. Giải thích nhãn`,
    ],
    makeup_lesson_info: [
      `당일수업권으로 예약 가능한 24시간 이내 수업을 의미합니다.`,
      `Lessons available in 24 hours that can be booked with Last-minute Credits.`,
      's_unassigned_lessons.info.makeup_lesson_info',
      `可使用补充课程券申请的24小时以内的课程。`,
      `可使用補充課程券申請的24小時以內的課程。`,
      `補充レッスンチケットで予約可能な24時間以内のレッスンを意味します。`,
      `Những buổi học trống trong vòng 24 giờ mà bạn có thể đặt lịch bằng Thẻ Học bù.`,
    ],
    urgent_lesson_info: [
      `긴급편성수업권으로 2시간 이내 시작하는 수업을 예약할 수 있습니다.`,
      `Lesson starting in two hours or less can be booked using Fastbook Credit(s).`,
      's_unassigned_lessons.info.urgent_lesson_info',
      `使用紧急编排课程券可以预约2小时之内开始的课程。`,
      `使用緊急編排課程券可以預約2小時之內開始的課程。`,
      `緊急編成レッスンチケットで2時間以内に開始するレッスンを予約することができます。`,
      `Bạn có thể đặt buổi học bắt đầu trong vòng 2 giờ với Thẻ mở buổi học gấp`,
    ],
    n_minute_lesson40: [
      `40분 수업입니다.`,
      `This is a 40-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson40',
      `40分钟课程`,
      `40分鐘課程`,
      `40分レッスンです。`,
      `Buổi học 40 phút.`,
    ],
    n_minute_lesson20: [
      `20분 수업입니다.`,
      `This is a 20-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson20',
      `20分钟课程`,
      `20分鐘課程`,
      `20分レッスンです。`,
      `Buổi học 20 phút.`,
    ],
  },
  inform: {
    topic_of_this_lesson_will_be: (topic) => [
      <>
        <b>{topic}</b> 교재로 진행됩니다.
      </>,
      <>
        This lesson will be on <strong>{topic}</strong>.
      </>,
      's_unassigned_lessons.inform.topic_of_this_lesson_will_be',
      <>
        <b>使用{topic}</b>教材进行。
      </>,
      <>
        <b>使用{topic}</b>教材進行。
      </>,
      <>
        <b>{topic}</b> 教材で行います。
      </>,
      <>
        Buổi học sẽ về <strong>{topic}</strong>.
      </>,
    ],
    available_lessons: [
      `1. 당일수업이란?`,
      `1. What is a Last-minute Lesson?`,
      's_unassigned_lessons.inform.available_lessons',
      `1.什么是当日课程？`,
      `1.什麼是當日課程？`,
      `1.当日レッスンとは？`,
      `1. Buổi học trong ngày là?`,
    ],
    available_lessons_count: (n) => [
      <>예약 가능한 당일수업 ({n})</>,
      <>Last-minute lessons available for booking ({n}) </>,
      's_unassigned_lessons.inform.available_lessons_count',
      <>可预约的当日课程({n})</>,
      <>可預約的當日課程({n})</>,
      <>予約可能な当日レッスン({n})</>,
      <>Buổi học trong ngày có thể đặt ({n})</>,
    ],
  },
  makeup_lesson: [
    `당일수업`,
    `Last-minute`,
    's_unassigned_lessons.makeup_lesson',
    `补充课程`,
    `補充課程`,
    `補充レッスン`,
    `Học bù`,
  ],
  lessons_in_24: [
    `당일수업 예약`,
    `Book Last-minute Lesson`,
    's_unassigned_lessons.lessons_in_24',
    `当日课程预约`,
    `當日課程預約`,
    `当日レッスン予約`,
    `Đặt buổi học trong ngày`,
  ],
  noti: {
    title_notifications: [
      `알림 설정`,
      `Notification Settings`,
      's_unassigned_lessons.noti.title_notifications',
      `设置提醒`,
      `通知設定`,
      `通知設定`,
      `Cài đặt thông báo`,
    ],
    get_noti_all: [
      `모든 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me every time a lesson becomes available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_all',
      `接收全部的补充课程提醒`,
      `接收所有補充課程通知`,
      `補充レッスン通知をすべて受け取る`,
      `Thông báo tôi mỗi khi có buổi học trống lịch trong 24 giờ tới`,
    ],
    get_noti_of_my_tutors_only: [
      `찜한 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me only when my favorite tutor has a lesson available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_of_my_tutors_only',
      `选择接收的补充课程提醒('仅限收藏的教师)`,
      `選擇性接收補充課程通知（僅接收「我收藏的任課教師」）`,
      `補充レッスン通知を選んで受け取る (「お気に入りチューター」のみ)`,
      `Chỉ thông báo khi gia sư yêu thích của tôi có buổi học trống trong 24 giờ tới`,
    ],
    set_off: [
      `[당일수업] 업데이트 알림 받지 않기`,
      `Turn notifications off`,
      's_unassigned_lessons.noti.set_off',
      `不接收提醒`,
      `不接收通知`,
      `通知を受け取らない`,
      `Tắt thông báo`,
    ],
  },
  lesson_in_24_hours: [
    `예약 가능한 당일수업`,
    `Last-minute Lessons`,
    's_unassigned_lessons.lesson_in_24_hours',
    `24小时内的课程`,
    `24小時以內的課程`,
    `24時間以内のレッスン`,
    `Buổi học trong 24 giờ tới`,
  ],
  lesson_in_24_description: [
    <>
      24시간 이내에 시작하는 수업을 예약할 수 있습니다.
      <br />
      정규/당일/긴급편성수업권으로 사용 가능합니다.
    </>,
    `You can book with Regular, Last-minute, or FastBook Lesson Credits.`,
    's_unassigned_lessons.lesson_in_24_description',
    `预约从当前起24小时以内开始的课程。正规/补充/紧急编辑可使用课程券进行预约。`,
    `預約從現在起24小時以內開始的課程。可利用正規／補充／緊急分班課程券預約。`,
    `これから24時間以内に始まるレッスンを予約します。レギュラー/補充/直前予約レッスンチケットで予約することができます。`,
    `Bạn có thể đặt bằng Thẻ học Tiêu chuẩn hoặc Thẻ Học bù.`,
  ],
  register: [`신청하기`, `Book`, 's_unassigned_lessons.register', `申请`, `申請`, `予約する`, `Đặt lịch`],
  urgent_lesson: [
    `긴급편성수업권 사용 가능`,
    `Can be booked using Fastbook Credit(s).`,
    's_unassigned_lessons.urgent_lesson',
    `可使用紧急编排课程券`,
    `可使用緊急編排課程券`,
    `緊急編成レッスンチケット使用可能`,
    `Có thể sử dụng Thẻ mở buổi học gấp`,
  ],
  credit_to_use: [
    `사용 수업권`,
    `Selected Credit`,
    's_unassigned_lessons.credit_to_use',
    `使用课程券`,
    `使用的課程券`,
    `使用レッスンチケット`,
    `Thẻ học đã chọn`,
  ],
  tutor: [`튜터`, `Tutor:`, 's_unassigned_lessons.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư:`],
  confirm: {
    title_register_for_lessons: [
      `수업 신청`,
      `Book Lesson`,
      's_unassigned_lessons.confirm.title_register_for_lessons',
      `申请课程`,
      `申請課程`,
      `レッスン予約`,
      `Đặt lịch học`,
    ],
    registration_has_been_successful: [
      `수업이 신청되었습니다.`,
      `Your booking has been submitted`,
      's_unassigned_lessons.confirm.registration_has_been_successful',
      `课程已申请。`,
      `課程申請完成。`,
      `レッスンを予約しました。`,
      `Đăng ký của bạn đã được gửi`,
    ],
    has_failed: [
      `수업 신청에 실패하였습니다.`,
      `Your booking has failed.`,
      's_unassigned_lessons.confirm.has_failed',
      `课程申请失败。`,
      `課程申請失敗。`,
      `レッスン予約に失敗しました。`,
      `Đăng ký của bạn không thành công.`,
    ],
    no_available_credit: [
      `사용 가능한 수업권이 없습니다.`,
      `We're sorry - your booking didn't go through. Please try again.`,
      's_unassigned_lessons.confirm.no_available_credit',
      `没有可使用的课程券。`,
      `無可使用的課程券。`,
      `使用可能なレッスンチケットはありません。`,
      `Xin lỗi bạn - đăng ký chưa hoàn thành. Vui lòng thử lại.`,
    ],
    ok: [`확인`, `Ok`, 's_unassigned_lessons.confirm.ok', `确认`, `確認`, `確認`, `Ok`],
  },
  warning: {
    check_if_you_agree: [
      `유의사항`,
      `Terms and Conditions`,
      's_unassigned_lessons.warning.check_if_you_agree',
      `注意事项`,
      `注意事項`,
      `注意事項`,
      `Chú ý`,
    ],
    not_restore_if_you_cancel: [
      `당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.`,
      `The credit used for booking the last-minute lesson will not be returned even if you cancel or miss the lesson.`,
      's_unassigned_lessons.warning.not_restore_if_you_cancel',
      `取消当日课程或缺席时，将扣除课程券。`,
      `取消當日課程或缺席時，將扣除課程券。`,
      `当日レッスンはレッスンキャンセルおよび無断欠席時にレッスンチケットが差し引かれます。`,
      `Với Buổi học trong ngày, thẻ học vẫn bị trừ khi hủy buổi học hoặc không tham gia.`,
    ],
    cannot_change_tutor_or_time: [
      `당일수업은 튜터 및 시간 변경이 불가합니다.`,
      `You cannot change the tutor or time slot for last-minute lessons.`,
      's_unassigned_lessons.warning.cannot_change_tutor_or_time',
      `当日课程不可以更改课教师和时间。`,
      `當日課程不可以更改課教師和時間。`,
      `当日レッスンはチューターおよび時間の変更ができません。`,
      `Với Buổi học trong ngày, bạn không thể thay đổi gia sư và thời gian học.`,
    ],
    auto_matching_after_tutor_canceled: [
      `당일수업의 튜터는 상황에 따라 변경될 수 있습니다.`,
      `The tutor for the last-minute lesson is subject to change.`,
      's_unassigned_lessons.warning.auto_matching_after_tutor_canceled',
      `当日课程的任课教师根据情况会有所变动。`,
      `當日課程的任課教師根據情況會有所變動。`,
      `当日レッスンのチューターは状況によって変更されることがあります。`,
      `Gia sư của buổi học trong ngày có thể bị thay đổi tùy tình huống.`,
    ],
    use_a_regular_credit: (credit) => [
      <>해당 수업은 정규수업권으로 신청됩니다. (+{credit} 포인트가 추가됩니다.)</>,
      <>This lesson will use a regular lesson credit. +${financial(credit / DOLLARS_TO_KRW)}P will be added.</>,
      's_unassigned_lessons.warning.use_a_regular_credit',
      <>该课程使用正规课程券进行申请。(追加+{credit}积分。)</>,
      <>該課程使用正規課程券申請。（新增+${credit}P。）</>,
      <>このレッスンは、レギュラーレッスンチケットで予約します。(+{credit} ポイントが付与されます。)</>,
      <>Buổi học này sử dụng Thẻ học tiêu chuẩn. +${financial(credit / DOLLARS_TO_KRW)}P sẽ được thêm vào.</>,
    ],
    use_a_regular_credit_mulcam: [
      <>해당 수업은 정규수업권으로 신청됩니다.</>,
      <>This lesson will use a regular lesson credit.</>,
      's_unassigned_lessons.warning.use_a_regular_credit_mulcam',
      <>该课程使用正规课程券进行申请。</>,
      <>該課程使用正規課程券申請。</>,
      <>このレッスンは、レギュラーレッスンチケットで予約します。</>,
      <>Buổi học này sử dụng Thẻ học tiêu chuẩn.</>,
    ],
  },
  has_not_unassigned_lessons: [
    `현재 예약 가능한 당일수업이 없습니다.`,
    `Sorry, there are currently no lessons available within 24 hours.`,
    's_unassigned_lessons.has_not_unassigned_lessons',
    `当前没有可预约的24小时内课程。`,
    `目前沒有可預約的24小時以內的課程。`,
    `現在予約可能な24時間以内のレッスンはありません。`,
    `Xin lỗi, hiện tại không có buổi học nào trống trong 24 giờ tới.`,
  ],
  remaining_time: (n) => [
    <>{n}분 후 수업 시작</>,
    <>Lesson begins in {n} minutes</>,
    's_unassigned_lessons.remaining_time',
    <>{n}分后开始课程</>,
    <>{n}分后開始課程</>,
    <>{n}分後レッスン開始</>,
    <>Bắt đầu buổi học sau {n} phút</>,
  ],
  step_info_1: [
    `STEP 1. 수업 선택`,
    `STEP 1. Select Lesson`,
    's_unassigned_lessons.step_info_1',
    `STEP 1.课程选择`,
    `STEP 1.課程選擇`,
    `STEP 1.レッスンを選択`,
    `BƯỚC 1. Chọn buổi học`,
  ],
  step_info_2: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select Lesson Material`,
    's_unassigned_lessons.step_info_2',
    `STEP 2.教材选择`,
    `STEP 2.教材選擇`,
    `STEP 2.教材を選択`,
    `BƯỚC 2. Chọn tài liệu`,
  ],
  step_info_3: [
    `STEP 3. 수업권 선택  & 예약 확인`,
    `STEP 3. Select Lesson Credit & Confirm Booking`,
    's_unassigned_lessons.step_info_3',
    `STEP 3.选择课程券&确认预约`,
    `STEP 3.選擇課程券&確認預約`,
    `STEP 3.レッスンチケット選択＆予約確認`,
    `BƯỚC 3. Chọn thẻ học & Xác nhận đặt`,
  ],
  regular_credit_choice_40_min: [
    `정규수업권으로 수업 예약 시 ￦3,000P 추가 적립`,
    `Additional $2.31P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_40_min',
    `使用正规课程券预约课程时，额外累计积分$2.31P`,
    `使用正規課程券預約課程時，額外累計積分$2.31P`,
    `レギュラーレッスンチケットでレッスン予約時$2.31P追加`,
    `Khi đặt buổi học bằng thẻ học tiêu chuẩn bạn tích lũy được $2.31P`,
  ],
  regular_credit_choice_20_min: [
    `정규수업권으로 수업 예약 시 ￦1,000P 추가 적립`,
    `Additional $0.77P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_20_min',
    `使用正规课程券预约课程时，额外累计积分$0.77P`,
    `使用正規課程券預約課程時，額外累計積分$0.77P`,
    `レギュラーレッスンチケットでレッスン予約時$0.77P追加`,
    `Khi đặt buổi học bằng thẻ học tiêu chuẩn bạn tích lũy được $0.77P`,
  ],
  regular_credit_choice_point_info_40_min: [
    <>
      <span className="text-purple-500">￦3,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-purple-500">$2.31P</span> rewarded.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_40_min',
    <>
      额外累积积分<span className="text-purple-500">$2.31P</span>
    </>,
    <>
      額外累積積分<span className="text-purple-500">$2.31P</span>
    </>,
    <>
      <span className="text-purple-500">$2.31P</span>が追加されました。
    </>,
    <>
      Bạn đã tích lũy thêm <span className="text-purple-500">$2.31P</span>
    </>,
  ],
  regular_credit_choice_point_info_20_min: [
    <>
      <span className="text-purple-500">￦1,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-purple-500">$0.77P</span> rewarded.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_20_min',
    <>
      额外累积积分<span className="text-purple-500">$0.77P</span>
    </>,
    <>
      額外累積積分<span className="text-purple-500">$0.77P</span>
    </>,
    <>
      <span className="text-purple-500">$0.77P</span>が追加されました。
    </>,
    <>
      Bạn đã tích lũy thêm <span className="text-purple-500">$0.77P</span>
    </>,
  ],
  exit: [`나가기`, `Leave`, 's_unassigned_lessons.exit', `关闭`, `關閉`, `退出`, `Thoát`],
  credit_select: [
    `수업권 선택`,
    `Select Lesson Credit`,
    's_unassigned_lessons.credit_select',
    `课程券选择`,
    `課程券選擇`,
    `レッスンチケット選択`,
    `Chọn thẻ học`,
  ],
  alarm_setting: [
    `예약 가능시 알림 받기`,
    `Notify me about updates`,
    's_unassigned_lessons.alarm_setting',
    `可预约时接收通知提醒。`,
    `可預約時接收通知提醒。`,
    `予約可能な際にお知らせを受け取る`,
    `Thông báo thời gian đặt lớp`,
  ],
  ask_unassigned_alarm_turned_on: [
    `당일수업이 예약 가능할 때 앱 푸시를 받으시겠습니까?`,
    `Would you like to receive an app push notification when a last-minute lesson becomes available?`,
    's_unassigned_lessons.ask_unassigned_alarm_turned_on',
    `可预约当天课程时，需要接收 App 消息推送吗？`,
    `可預約當天課程時，需要接收 App 消息推送嗎？`,
    `当日レッスン予約が可能な時にプッシュ通知を受け取りますか？`,
    `Nhận thông báo khi có thể đặt lớp trong ngày?`,
  ],
  already_unassigned_alarm_turned_on: [
    `이미 당일수업 업데이트 알림이 설정되어 있습니다.`,
    `You are already receiving notifications on last-minute lessons.`,
    's_unassigned_lessons.already_unassigned_alarm_turned_on',
    `已设置当天课程更新提醒。`,
    `已設置當天課程更新提醒。`,
    `すでに当日レッスンのアップデートのお知らせが設定されています。`,
    `Bạn đã cài đặt nhận thông báo lớp trong ngày.`,
  ],
  alrarm_turned_on_succ_toast: [
    `당일 수업 알림 신청이 완료되었습니다.`,
    `Notifications on last-minute lessons have been enabled.`,
    's_unassigned_lessons.alrarm_turned_on_succ_toast',
    `已完成当天课程提醒申请。`,
    `已完成當天課程提醒申請。`,
    `当日レッスンのお知らせの申し込みが完了しました。`,
    `当日レッスンのお知らせの申し込みが完了しました。`,
  ],
}
export const s_schedule = {
  exit: [`나가기`, `Leave`, 's_schedule.exit', `关闭`, `關閉`, `退出`, `Thoát`],
  change_timezone: [
    `타임존 변경`,
    `Change time zone`,
    's_schedule.change_timezone',
    `时区变更`,
    `變更時區`,
    `タイムゾーン変更`,
    `Đổi múi giờ`,
  ],
  valid_n_days: (n) => [
    <>수강 기간: {n}일 남음</>,
    <>Expires in: {n} days</>,
    's_schedule.valid_n_days',
    <>上课时间：还有{n}天</>,
    <>上課時間：還有{n}天</>,
    <>レッスン時間: 残り{n}日</>,
    <>Thời gian học: Còn {n} ngày</>,
  ],
  valid_from_to_days: (from, to) => [
    `수강기간 ${from} ~ ${to}일`,
    `Valid from ${from} to ${to}`,
    's_schedule.valid_from_to_days',
    `课程期限${from} ~ ${to}日`,
    `聽課期間：${from} ~ ${to}日`,
    `有効期限${from} ~ ${to}日`,
    `Có hiệu lực từ ${from} đến ${to}`,
  ],
  unused_credits: [
    `미사용 수업권`,
    `Unused Lesson Credits`,
    's_schedule.unused_credits',
    `未使用的课程券`,
    `未使用的課程券`,
    `未使用レッスンチケット`,
    `Số thẻ học chưa dùng`,
  ],
  please_click_button_to_select_material: [
    `다음을 눌러 교재를 선택하세요`,
    ``,
    's_schedule.please_click_button_to_select_material',
    `请点击下一步选择教材`,
    `請點擊繼續選擇教材`,
    `次へを押して教材を選択してください。`,
    ``,
  ],
  please_select_more_than_one_lesson: [
    `1개 이상 수업을 신청하세요`,
    `Please select more than one lesson.`,
    's_schedule.please_select_more_than_one_lesson',
    `请申请至少1个以上的课程`,
    `請申請1個以上的課程`,
    `1回以上レッスンを予約してください。`,
    `Vui lòng chọn nhiều hơn một buổi học.`,
  ],
  select_credit: [
    `수업권 선택`,
    `Select Credits`,
    's_schedule.select_credit',
    `选择课程券`,
    `選擇課程券`,
    `レッスンチケット選択`,
    `Lựa chọn Thẻ học`,
  ],
  please_select_the_time_and_tutors: [
    `시간과 튜터를 선택해 주세요.`,
    `Please select time and tutors.`,
    's_schedule.please_select_the_time_and_tutors',
    `请选择时间及教师。`,
    `請選擇時間及任課教師。`,
    `時間とチューターを選択してください。`,
    `Lựa chọn thời gian và gia sư.`,
  ],
  automatic_matching_for_tutor: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_schedule.automatic_matching_for_tutor',
    `自动匹配教师`,
    `自動配對任課教師`,
    `チューター自動マッチング`,
    `Xếp ngẫu nhiên tôi với một gia sư`,
  ],
  ringle_automatic_matching: [
    `링글 자동 매칭`,
    `Auto matching`,
    's_schedule.ringle_automatic_matching',
    `Ringle自动匹配`,
    `Ringle自動配對`,
    `Ringle自動マッチング`,
    `Xếp ngẫu nhiên`,
  ],
  ringle_will_match_appropriate_tutor: [
    `예약 가능한 튜터 중, 평점이 높고 성실한 튜터와 자동으로 매칭해 드려요.`,
    `We'll find the best tutor available for you.`,
    's_schedule.ringle_will_match_appropriate_tutor',
    `在可预约的任课教师中，自动为您分配安排评分高、诚实可信的任课教师。`,
    `在可預約的任課教師中，自動為您分配安排評分高、誠實可信的任課教師。`,
    `予約可能な最高のチューターとマッチング`,
    `‘Chúng tôi sẽ ghép bạn vơi gia sư tốt nhất có khả năng hẹn sẵn trước.’`,
  ],
  recommend: [`추천`, `Featured`, 's_schedule.recommend', `推荐`, `推薦`, `おすすめ`, `Nổi bật`],
  please_select_material: [
    `교재를 선택해 주세요`,
    `Please select lesson materials.`,
    's_schedule.please_select_material',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください。`,
    `Vui lòng chọn tài liệu học.`,
  ],
  click_button_to_go_to_the_next_step: [
    `버튼을 누르면 다음 단계로 넘어갑니다.`,
    ``,
    's_schedule.click_button_to_go_to_the_next_step',
    `点击按键, 进行下一步。`,
    `點擊按鈕時，前往下一步驟。`,
    `ボタンを押すと次のステップに進みます。`,
    ``,
  ],
  previous: [`이전`, `Back`, 's_schedule.previous', `上一步`, `上一步`, `戻る`, `Trước`],
  next: [`다음`, `Next`, 's_schedule.next', `下一步`, `下一步`, `次へ`, `Tiếp theo`],
  do_you_want_to_cancel_lesson: [
    `이 수업을 취소하시겠습니까?`,
    `Do you want to cancel this lesson?`,
    's_schedule.do_you_want_to_cancel_lesson',
    `您确认要取消此课程吗?`,
    `確認要取消此課程嗎？`,
    `このレッスンをキャンセルしますか？`,
    `Bạn có muốn huỷ buổi học này?`,
  ],
  the_material_is_not_selected: [
    `교재를 선택해주세요`,
    `Please select the lesson material you wish to use.`,
    's_schedule.the_material_is_not_selected',
    `请选择教材`,
    `請選擇教材`,
    `教材を選択してください`,
    `Hãy chọn tài liệu`,
  ],
  go_to_the_previous_step_cuz_no_lessons: [
    `신청한 수업이 없어서 이전 단계로 이동합니다`,
    `Since there is no booked lesson, you're moving to the previous step.`,
    's_schedule.go_to_the_previous_step_cuz_no_lessons',
    `没有申请的课程, 请返回上一步。`,
    `未申請課程，前往上一步驟。`,
    `予約したレッスンがないため、前のステップに移動します。`,
    `Vì chưa có buổi học nào được đặt, bạn sẽ được chuyển về trang trước.`,
  ],
  counter_of_selected_materials: (materialCount, completedCount) => [
    `교재 선택 : ${materialCount} / ${completedCount} 개`,
    `Selected materials: ${materialCount} / ${completedCount}`,
    's_schedule.counter_of_selected_materials',
    `选择教材 : ${materialCount} / ${completedCount} 个`,
    `選擇教材：${materialCount}／${completedCount}個`,
    `選択した教材 : ${materialCount} / ${completedCount} 個`,
    `Tài liệu đã chọn: ${materialCount} / ${completedCount}`,
  ],
  an_error_occurred_during_the_process: [
    `신청 과정에서 오류가 발생했습니다.`,
    `An error occurred during the process.`,
    's_schedule.an_error_occurred_during_the_process',
    `申请过程中出现错误。`,
    `申請過程中發生錯誤。`,
    `予約過程でエラーが発生しました。`,
    `Đã có lỗi xảy ra.`,
  ],
  btn_register: [`신청하기`, `Book`, 's_schedule.btn_register', `申请`, `申請`, `予約する`, `Đặt lịch`],
  complete: [
    `신청 완료`,
    `Book Lesson`,
    's_schedule.complete',
    `申请完成`,
    `申請完成`,
    `申請完了`,
    `Đăng ký thành công`,
  ],
  after_cancel_auto_matching_unavailable: [
    `현재 가능한 튜터가 없어서, 취소 후 자동 매칭 기능은 제공되지 않습니다.`,
    `We couldn't match you to a tutor.`,
    's_schedule.after_cancel_auto_matching_unavailable',
    `目前没有符合条件的教师, 取消后将不再提供自动匹配功能。`,
    `目前沒有可以的教師，取消後將不提供自動配對功能。`,
    `現在予約可能なチューターがいないため、キャンセル後に自動マッチング機能は提供されません。`,
    `Chúng tôi không thể sắp xếp bạn với gia sư.`,
  ],
  the_past_time: [
    `지난 시간 입니다.`,
    `Sorry, you cannot book a past lesson.`,
    's_schedule.the_past_time',
    `已错过预约时间。`,
    `已過的時間。`,
    `予約可能時間ではありません。`,
    `Vui lòng chọn thời gian trong tương lai.`,
  ],
  you_cannot_register_within_26_hours: [
    `현재 시간 기준 26시간 안에는 신청 할 수 없습니다`,
    `Book lessons starting in 26h or later.`,
    's_schedule.you_cannot_register_within_26_hours',
    `以当前时间为基准, 24小时内无法提供申请。`,
    `以目前時間為準，24小時內無法申請。`,
    `現在時刻から24時間以内は予約することができません。`,
    `Bạn không thể đặt trước 24 giờ kể từ bây giờ.`,
  ],
  you_cannet_register_with_the_selected_credits: [
    `선택된 수업권으로는 신청하실 수 없습니다.`,
    `You cannot use selected lesson credit.`,
    's_schedule.you_cannet_register_with_the_selected_credits',
    `选择的课程券无法进行申请。`,
    `無法使用選擇的課程券申請。`,
    `選択したレッスンチケットでは予約することができません。`,
    `Bạn không thể sử dụng thẻ học này.`,
  ],
  you_are_out_of_credits_please_choose_another_credit: [
    `남은 수업권이 없습니다. 다른 수업권을 선택해 주세요.`,
    `You are out of lesson credits.`,
    's_schedule.you_are_out_of_credits_please_choose_another_credit',
    `没有剩余的课程券。请选择其他课程券。`,
    `沒有剩餘的課程券。請選擇其他課程券。`,
    `残っているレッスンチケットはありません。他のレッスンチケットを選択してください。`,
    `Bạn đã hết thẻ học.`,
  ],
  credit: [`수업권`, `Credit`, 's_schedule.credit', `课程券`, `課程券`, `レッスンチケット`, `Thẻ học`],
  change_registration_mode: [
    `신청 방식 변경`,
    `Change sorting`,
    's_schedule.change_registration_mode',
    `申请方式变更`,
    `變更申請方式`,
    `予約方式変更`,
    `Đổi cách sắp xếp`,
  ],
  select_time_first: [
    `시간 먼저 선택`,
    `Sort by time`,
    's_schedule.select_time_first',
    `请先选择时间`,
    `先選擇時間`,
    `時間帯から選ぶ`,
    `Sắp xếp theo thời gian`,
  ],
  select_tutor_first: [
    `튜터 먼저 선택`,
    `Sort by tutor`,
    's_schedule.select_tutor_first',
    `请先选择教师`,
    `先選擇任課教師`,
    <>チューターから選ぶ</>,
    `Sắp xếp theo gia sư`,
  ],
  are_you_sure_you_want_to_delete_this_lesson: [
    `이 수업을 삭제하시겠습니까?`,
    `Are you sure you want to cancel this lesson?`,
    's_schedule.are_you_sure_you_want_to_delete_this_lesson',
    `您确定要删除此课程吗?`,
    `確定要刪除此課程嗎？`,
    `このレッスン予約を取り消しますか？`,
    `Bạn có chắc muốn huỷ buổi học này?`,
  ],
  lesson_reserved: [
    `수업 예정`,
    `Booked`,
    's_schedule.lesson_reserved',
    `预定课程`,
    `預定的課程`,
    `レッスン予定あり`,
    `Đã đặt lịch`,
  ],
  selected: [`선택 완료`, `Selected`, 's_schedule.selected', `选择完成`, `選擇完畢`, `選択済`, `Đã chọn`],
  select_tutor: [
    `튜터 선택`,
    `Select a tutor`,
    's_schedule.select_tutor',
    `选择教师`,
    `選擇教師`,
    `チューター選択`,
    `Lựa chọn gia sư`,
  ],
  selectable: [`선택 가능`, `Available`, 's_schedule.selectable', `可进行选择`, `可選擇`, `選択可能`, `Lịch trống`],
  unavailable: [`신청 불가`, `Unavailable`, 's_schedule.unavailable', `无法申请`, `無法申請`, `予約不可`, `Không có`],
  reserve_closed: [
    `예약 마감`,
    <>Booking Closed</>,
    's_schedule.reserve_closed',
    `结束预约`,
    `預約截止`,
    `予約締切`,
    <>Đã đóng đặt lịch</>,
  ],
  not_service_hours: [
    `서비스 운영 시간이 아닙니다`,
    `Ringle is not open yet.`,
    's_schedule.not_service_hours',
    `非服务运营时间`,
    `非服務營運時間`,
    `サービスご利用時間外です`,
    `Hiện đã hết giờ làm việc của Ringle.`,
  ],
  view_profiles: [
    `프로필 보기`,
    `View profiles`,
    's_schedule.view_profiles',
    `查看简介`,
    `查看簡介`,
    `プロフィールを見る`,
    `Xem thông tin cá nhân`,
  ],
  please_register_for_lessons_by_select_the_desired_time_on_calender: [
    <>
      캘린더에서 원하시는 시간을 눌러
      <br />
      수업을 신청해 주세요.
    </>,
    `Please select time on calendar.`,
    's_schedule.please_register_for_lessons_by_select_the_desired_time_on_calender',
    `请点击日历上希望的时间进行课程申请。`,
    `請點擊希望的時間申請課程。`,
    `カレンダー上でご希望の時間帯を選んでください`,
    `Hãy chọn thời gian trên lịch.`,
  ],
  not_able_to_fetch_tutors_info: [
    `튜터 정보를 가져오지 못했습니다.`,
    `We were not able to fetch this tutor's information.`,
    's_schedule.not_able_to_fetch_tutors_info',
    `无法获取教师信息。`,
    `無法讀取任課教師資訊。`,
    `チューター情報を読み込めませんでした。`,
    `Chúng tôi không thể lấy thông tin từ gia sư này.`,
  ],
  gender: [`성별`, `Gender`, 's_schedule.gender', `性别`, `性別`, `性別`, `Giới tính`],
  accent: [`억양`, `Accent`, 's_schedule.accent', `口音`, `口音`, `アクセント`, `Giọng`],
  major: [`전공`, `Major`, 's_schedule.major', `专业`, `主修`, `専攻`, `Chuyên môn`],
  available_tutor: [
    `예약 가능`,
    `Available`,
    's_schedule.available_tutor',
    `预约可能`,
    `可預約`,
    `予約可能`,
    `Lịch trống`,
  ],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_schedule.recommended_tutor',
    `教师推荐`,
    `推薦的教師`,
    `おすすめ`,
    `Nổi bật`,
  ],
  saved_tutor: [
    `찜한 튜터`,
    `Favorites`,
    's_schedule.saved_tutor',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入り`,
    `Yêu thích`,
  ],
  tutors: [`튜터 수`, `Tutors`, 's_schedule.tutors', `教师人数`, `任課教師人數`, `チューター数`, `Gia sư`],
  my_tutors: [
    `찜한 튜터`,
    `Favorites`,
    's_schedule.my_tutors',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入り`,
    `Yêu thích`,
  ],
  tutors_available_in_this_time: [
    `선택한 시간에 수업 가능한 튜터들입니다.`,
    `Tutors available at selected time`,
    's_schedule.tutors_available_in_this_time',
    `于选择的时间可进行课程的教师。`,
    `您選擇的時間可授課的任課教師。`,
    `選択した時間帯にレッスン可能なチューターです。`,
    `Gia sư trống lịch trong thời gian được chọn`,
  ],
  tutors_unavailable_in_this_time: [
    `선택한 시간에 수업 예약이 마감된 튜터들입니다.`,
    `Tutors unavailable at selected time`,
    's_schedule.tutors_unavailable_in_this_time',
    `于选择的时间已结束预约的教师。`,
    `您選擇的時間課程預約已截止的任課教師。`,
    `選択した時間帯のレッスン予約が締め切られたチューターです。`,
    `Gia sư không thể dạy trong thời gian được chọn`,
  ],
  tutors_available_you_gave_a_good_rating: [
    <>
      추천 튜터는 최대 5명까지만 노출됩니다.
      <br />더 많은 튜터를 [예약 가능] 탭에서 확인해보세요!
    </>,
    <>
      Shows up to five featured tutors.
      <br />
      Please check out the [Available] tab to view more tutors!
    </>,
    's_schedule.tutors_available_you_gave_a_good_rating',
    `<>
最多只显示5名推荐任课教师。<br />请在【可预约】标签中查看更多的任课教师！
</>`,
    `<>
最多隻顯示5名推薦任課教師。<br />請在【可預約】標籤中查看更多的任課教師！
</>`,
    `<>おすすめチューターは最大５名まで見ることが出来ます。<br />より多くのチューターを［予約可能］タブからご覧ください！</>`,
    `<>Chỉ hiển thị tối đa 5 gia sư được gợi ý.<br />Hãy tìm hiểu nhiều gia sư khác trong mục [Có thể đặt]!</>`,
  ],
  tutors_youve_liked_that_are_available: [
    `선택한 시간에 수업 가능한 찜한 튜터들입니다.`,
    `Favorite tutors available at selected time`,
    's_schedule.tutors_youve_liked_that_are_available',
    `于选择的时间, 可进行课程的收藏教师。`,
    `您選擇的時間可授課且是您收藏的任課教師。`,
    `選択した時間にレッスン可能なお気に入りチューターです。`,
    `Gia sư yêu thích trống lịch trong thời gian được chọn`,
  ],
  add_more_tutor_my_tutor: [
    `찜한 튜터 리스트 입니다. 더 다양한 튜터를 찾아보세요!`,
    `Browse more Ringle tutors!`,
    's_schedule.add_more_tutor_my_tutor',
    `收藏的教师列表。 请查找更多的教师吧!`,
    `您收藏的任課教師清單，請尋找更多任課教師！`,
    `お気に入りチューターリストです。より多様なチューターを探してみましょう！`,
    `Xem thêm gia sư của Ringle!`,
  ],
  add_more_tutor_available: [
    `수업 가능한 튜터 중, 내게 꼭 맞는 튜터를 찾아보세요!`,
    `Browse more available tutors to find your perfect fit!`,
    's_schedule.add_more_tutor_available',
    `于可进行课程的教师中, 找到最适合我的教师吧!`,
    `從可授課的任課教師中，尋找最適合我的任課教師吧！`,
    `レッスン可能なチューターのうち、自分にぴったり合うチューターを探しましょう！`,
    `Tìm thêm các gia sư đang trống lịch để chọn người phù hợp với bạn!`,
  ],
  find_a_tutor: [
    `튜터 찾기`,
    `Search tutors`,
    's_schedule.find_a_tutor',
    `查找教师`,
    `尋找任課教師`,
    `チューター検索`,
    `Tìm gia sư`,
  ],
  the_tutors_you_gave_a_good_raitings: [
    `링글에서 추천하는 신규 튜터들입니다.`,
    `Featured tutors`,
    's_schedule.the_tutors_you_gave_a_good_raitings',
    `Ringle推荐的新任教师。`,
    `Ringle推薦的最新任課教師。`,
    `Ringleがおすすめする新規チューターです。`,
    `Gia sư nổi bật`,
  ],
  there_is_no_credit_available_you_can_register_for_lesson_after_purchase: [
    `신청 가능한 수업권이 없습니다. 수업권 구매 후 수업 신청하실 수 있습니다.`,
    `You have no lesson credits. Please book lessons after adding more credits.`,
    's_schedule.there_is_no_credit_available_you_can_register_for_lesson_after_purchase',
    `没有可申请的课程券。购买课程券后可完成课程申请。`,
    `沒有可申請的課程券，購買課程券後可申請課程。`,
    `予約可能なレッスンチケットはありません。レッスンチケット購入後にレッスンを予約することができます。`,
    `Bạn không còn thẻ học. Vui lòng đặt lịch học sau khi mua thêm thẻ học mới.`,
  ],
  the_selected_lesson_starts_within_48_hours: [
    `선택하신 수업은 48시간 이내에 시작하는 수업입니다. 튜터와의 시차 및 응답 속도에 따라 매칭되지 않을 가능성이 높습니다.`,
    `This lesson starts in less than 48 hours. Tutor may not confirm in time.`,
    's_schedule.the_selected_lesson_starts_within_48_hours',
    `选择的课程于48小时内开始。根据与教师所在地区的时差和应答速度, 无法进行匹配的可能性较大。`,
    `您選擇的課程為48小時以內即將開始的課程，依據與任課教師的時差與回覆速度，很有可能配對失敗。`,
    `選択したレッスンは48時間以内に始まるレッスンです。チューターとの時差および返答スピードからマッチングしない可能性が高いです。`,
    `Buổi học này bắt đầu trong vòng 48 giờ nữa. Gia sư có thể không kịp xác nhận.`,
  ],
  AM_hour: (h) => [`오전 ${h}시`, `AM ${h}`, 's_schedule.AM_hour', `上午 ${h}点`, `上午${h}點`, `午前 ${h}`, `AM ${h}`],
  PM_hour: (h) => [`오후 ${h}시`, `PM ${h}`, 's_schedule.PM_hour', `下午 ${h}点`, `下午${h}點`, `午後 ${h}`, `PM ${h}`],
  thirty_min: [`30분`, `:30`, 's_schedule.thirty_min', `30分钟`, `30分`, `30分`, `:30`],
  o_clock: [`시`, `:00`, 's_schedule.o_clock', ``, ``, `時`, `:00`],
  select_available_tutor: [
    `튜터 직접 선택`,
    `Tutors available at selected time`,
    's_schedule.select_available_tutor',
    `直接选择教师`,
    `直接選擇教師`,
    `チューターを選択`,
    `Những gia sư trống lịch vào thời gian được chọn`,
  ],
  modal: {
    no_credit: [
      `수업권 미보유`,
      `No Credit`,
      's_schedule.modal.no_credit',
      `未持有课程券`,
      `未持有課程券`,
      `レッスンチケット未保有`,
      `Không có thẻ học`,
    ],
    after_purchasing: [
      `수업권 구매 후 이용 가능합니다.`,
      `Available after purchasing lesson credits.`,
      's_schedule.modal.after_purchasing',
      `购买课程券后可使用。`,
      `購買課程券後可使用。`,
      `レッスンチケット購入後に利用可能です。`,
      `Chỉ có sau khi thanh toán thẻ học.`,
    ],
    purchase: [
      `구매하러 가기`,
      `Purchase`,
      's_schedule.modal.purchase',
      `前往购买`,
      `前往購買`,
      `購入する`,
      `Thanh toán`,
    ],
    title: [
      `추천 튜터 수업 안내`,
      `Lessons with featured tutors`,
      's_schedule.modal.title',
      `推荐任课教师课程介绍`,
      `推薦任課教師課程介紹`,
      `おすすめチューターレッスン案内`,
      `Hướng dẫn buổi học gia sư được đề xuất`,
    ],
  },
  get_matched_with_other_tutors: [
    `다른 튜터 매칭 받기`,
    `Match me with another tutor`,
    's_schedule.get_matched_with_other_tutors',
    `接受其他任课教师匹配`,
    `接受其他任課教師配對`,
    `他のチューターとマッチングしてもらう`,
    `Tự động xếp tôi với một gia sư khác.`,
  ],
  check_other_times_for_the_selected_tutor: [
    `선택한 튜터의 다른 시간 확인하기`,
    `Show availability of this tutor`,
    's_schedule.check_other_times_for_the_selected_tutor',
    `确认所选教师的其他时间`,
    `確認所選的任課教師其他時間`,
    `選択したチューターの別時間帯を確認する`,
    `Xem lịch trống của gia sư này`,
  ],
  recovering_a_used_class_ticket_after_canceling: [
    `수업 취소 후 사용한 수업권 복구받기`,
    `Restore my lesson credit`,
    's_schedule.recovering_a_used_class_ticket_after_canceling',
    `课程取消后课程券返还`,
    `取消課程後復原已使用的課程券`,
    `予約をキャンセルし、使用したレッスンチケットを復元する`,
    `Khôi phục thẻ học của tôi`,
  ],
  tutor_matching_options: [
    `튜터 매칭 옵션`,
    `Tutor matching options`,
    's_schedule.tutor_matching_options',
    `教师匹配选项`,
    `任課教師配對選項`,
    `チューターマッチングオプション`,
    `Lựa chọn sắp xếp gia sư`,
  ],
  check_class_reservation_history: [
    `수업 예약 내역 확인`,
    `Lesson Booking Details`,
    's_schedule.check_class_reservation_history',
    `课程预约内容确认`,
    `確認課程預約明細`,
    `レッスン予約内容確認`,
    `Chi tiết đặt lịch buổi học`,
  ],
  choose_how_to_handle_the_class_in_the_tutor_matching: [
    <>
      <Div textPrimary>수업 정보</Div>와{' '}
      <Div textPrimary mx4>
        튜터 매칭 옵션
      </Div>
      확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please review{' '}
      <Div textPrimary mx4>
        the lesson information
      </Div>
      and{' '}
      <Div textPrimary ml4>
        tutor matching option
      </Div>
      .{' '}
    </>,
    's_schedule.choose_how_to_handle_the_class_in_the_tutor_matching',
    <>
      请确认<Div textPrimary>课程信息</Div>和<Div textPrimary>教师匹配选项</Div>之后完成预约.
    </>,
    <>
      請確認<Div textPrimary>課程信息</Div>和<Div textPrimary>教師匹配選項</Div>之後完成預約.
    </>,
    <>
      <Div textPrimary>レッスン内容</Div>と<Div textPrimary>チューターマッチングのオプション</Div>
      を確認後、予約を完了してください。
    </>,
    <>
      Vui lòng kiểm tra lại các{' '}
      <Div textPrimary mx4>
        thông tin buổi học
      </Div>{' '}
      và{' '}
      <Div textPrimary mx4>
        sự lựa chọn gia sư.
      </Div>
    </>,
  ],
  classes_for_which_reservations: [
    `예약 신청한 수업`,
    `Selected Lesson`,
    's_schedule.classes_for_which_reservations',
    `预约申请的课程`,
    `已申請預約的課程`,
    `予約確定前レッスン`,
    `Buổi học đã chọn`,
  ],
  class_reservation: [
    `수업 예약`,
    `Book lesson`,
    's_schedule.class_reservation',
    `课程预约`,
    `預約課程`,
    `レッスン予約`,
    `Đặt lịch học`,
  ],
  book_another_class: [
    `다른 수업 예약하기`,
    `Book Another Lesson`,
    's_schedule.book_another_class',
    `预约其它课程`,
    `預約其它課程`,
    `次のレッスンを予約`,
    `Đặt buổi học khác`,
  ],
  set_up_a_class: [
    `수업 방식 설정하기`,
    `Select Lesson Style`,
    's_schedule.set_up_a_class',
    `设置课程方式`,
    `設置課程方式`,
    `レッスン方式を設定`,
    `Cài đặt phương thức học`,
  ],
  class_reservation_results: [
    `수업 예약 결과`,
    `Lesson Booking Details`,
    's_schedule.class_reservation_results',
    `课程预约结果`,
    `課程預約結果`,
    `レッスン予約結果`,
    `Kết quả đặt buổi học`,
  ],
  recommended_to_prepare_a_lesson_before_class: [
    `학습 효과 및 맞춤 수업을 위해 수업 전에 예습 및 수업 방식 설정을 추천합니다.`,
    `Set 'Lesson Styles' before the lesson for the best learning experience and customized lesson.`,
    's_schedule.recommended_to_prepare_a_lesson_before_class',
    `为达到最佳学习效果及体现定制型课程的优势, 建议课程开始前进行预习及设定希望的课程形式。`,
    `為了更佳的學習效果與客製化課程，建議於課程開始前設定預習及上課方式。`,
    `学習効果およびカスタマイズレッスンのために、レッスン前に予習とレッスン方式の設定をすることをおすすめします。`,
    `Cài đặt 'Hình thức học' trước buổi học để có trải nghiệm học tốt nhất và điều chỉnh buổi học phù hợp.`,
  ],
  classes_that_have_been_booked: [
    `예약 완료한 수업`,
    `Lesson(s) Successfully Booked`,
    's_schedule.classes_that_have_been_booked',
    `预约成功的课程`,
    `預約成功的課程`,
    `予約完了したレッスン`,
    `Buổi học đã đặt thành công`,
  ],
  classes_that_have_not_been_booked: [
    `예약 실패한 수업`,
    `Booking Failure`,
    's_schedule.classes_that_have_not_been_booked',
    `预约失败的课程`,
    `預約失敗的課程`,
    `予約に失敗したレッスン`,
    `Buổi học đã đặt không thành công.`,
  ],
  step3_heck_class_reservation_history: [
    `STEP 3. 수업 예약 내역 확인`,
    `STEP3. Check My Booked Lessons`,
    's_schedule.step3_heck_class_reservation_history',
    `STEP 3. 课程预约内容确认`,
    `STEP 3. 確認課程預約明細`,
    `STEP 3. レッスン予約内容を確認`,
    `BƯỚC 3. Kiểm tra những buổi học đã đặt`,
  ],
  lesson_reservation_cancel: [
    `수업 예약 취소`,
    `Cancel reservation`,
    's_schedule.lesson_reservation_cancel',
    `取消课程预约`,
    `取消課程預約`,
    `レッスン予約キャンセル`,
    `Huỷ đặt chỗ`,
  ],
  want_to_cancel_the_reservation: [
    <>
      현재까지 예약 신청중인 내역이 삭제됩니다.
      <br />
      진행중인 수업 예약을 취소하시겠습니까?
    </>,
    `Want to continue booking the lesson?`,
    's_schedule.want_to_cancel_the_reservation',
    <>
      删除至今为止预约申请中的内容。
      <br />
      确定要取消正在进行中的课程预约吗?
    </>,
    <>
      將刪除至今申請預約的明細，
      <br />
      確定要取消正在進行的課程預約嗎？
    </>,
    <>
      現在までの予約確定前の内容が削除されます。
      <br />
      予約確定前のレッスン予約を取消しますか？
    </>,
    `Bạn có muốn tiếp tục đặt buổi học?`,
  ],
  keep_booking: [
    `예약 계속하기`,
    `Continue scheduling`,
    's_schedule.keep_booking',
    `继续预约`,
    `繼續預約`,
    `予約を続ける`,
    `Tiếp tục lên lịch`,
  ],
  choose_tutor_first: [
    `튜터를 먼저 선택하세요`,
    `Select the tutor first`,
    's_schedule.choose_tutor_first',
    `请先选择教师`,
    `請先選擇任課教師`,
    `チューターを先に選択してください。`,
    `Đầu tiên hãy chọn gia sư.`,
  ],
  available_times: [
    `캘린더에 신청 가능한 시간이 표시됩니다.`,
    `Available time slots will be displayed on the calendar`,
    's_schedule.available_times',
    `日历上显示可申请的时间。`,
    `日曆上顯示可申請的時間。`,
    `カレンダーに予約可能な時間が表示されます。`,
    ``,
  ],
  tutor_and_time_selection: [
    `STEP 1. 튜터 및 시간 선택`,
    `STEP 1. Select tutor & time`,
    's_schedule.tutor_and_time_selection',
    `STEP 1. 教师及时间选择`,
    `STEP 1. 選擇任課教師及時間`,
    `STEP 1. チューターおよび時間選択`,
    `BƯỚC 1. Chọn gia sư & Thời gian`,
  ],
  left_count: [`회 남음`, `left`, 's_schedule.left_count', `次`, `次`, `回残り`, `Buổi học còn lại`],
  there_is_no_saved_tutor: [
    `찜한 튜터가 없습니다.`,
    `You haven't added any favorite tutors`,
    's_schedule.there_is_no_saved_tutor',
    `没有收藏的教师`,
    `無收藏的任課教師。`,
    `お気に入りチューターはいません。`,
    `Bạn chưa chọn gia sư yêu thích!`,
  ],
  choose_material: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select lesson materials`,
    's_schedule.choose_material',
    `STEP 2. 教材选择`,
    `STEP 2. 選擇教材`,
    `STEP 2. 教材選択`,
    `BƯỚC 2. Chọn Tài liệu`,
  ],
  class_reservations_are_not_available: [
    `수업 예약 가능한 수업권이 없습니다.`,
    `You have no lesson credit.`,
    's_schedule.class_reservations_are_not_available',
    `没有可预约课程的课程券。`,
    `無可預約課程的課程券。`,
    `レッスンが予約可能なレッスンチケットはありません。`,
    `Bạn không có thẻ học còn hạn!`,
  ],
  go_to_home: [`홈으로 가기`, `Home`, 's_schedule.go_to_home', `返回主页`, `前往首頁`, `ホームに移動`, `Trang chủ`],
  check_slots: [
    `스케쥴 보기`,
    `View slots`,
    's_schedule.check_slots',
    `查看日程`,
    `查看日程`,
    `スケジュールを見る`,
    `Kiểm tra chỗ`,
  ],
  additional_available_tutors: [
    `예약 가능한 튜터가 추가될 예정입니다.`,
    `More tutors will be available soon.`,
    's_schedule.additional_available_tutors',
    `预计新增可预约的任课教师。`,
    `預計新增可預約的任課教師。`,
    `予約可能なチューターが追加される予定です。`,
    `Các gia sư trống lịch sẽ hiển thị sớm.`,
  ],
  appreciate_if_you_could_wait_for: [
    <>
      오늘 기준 2주뒤 수업은 <Span fontBold>순차적으로 오픈될 예정</Span>입니다. 조금만 기다려 주시면 감사하겠습니다.
    </>,
    <>
      We would appreciate if you could wait for <Span fontBold>more lesson slots to be opened soon.</Span>
    </>,
    's_schedule.appreciate_if_you_could_wait_for',
    <>
      以今天为基准2周后的课程 <Span fontBold>将依次开放。</Span> 希望您耐心等待.
    </>,
    <>
      從今日起2週後的課程<Span fontBold>將依序開啟，</Span>敬請耐心等待。
    </>,
    <>
      2週間後以降のレッスン予定は{' '}
      <Span font Bold>
        {' '}
        順次オープンされます。
      </Span>{' '}
      しばらくお待ちください。
    </>,
    <>
      Chúng tôi rất vui nếu bạn có thể đợi <Span fontBold>đến khi nhiều buổi học hơn được mở.</Span>
    </>,
  ],
  scheduling_progress: [
    `스케쥴 오픈 중`,
    `Scheduling in progress`,
    's_schedule.scheduling_progress',
    `日程表开放中`,
    `日程表開放中`,
    `スケジュールオープン中`,
    `Đang lên lịch`,
  ],
  temporaily_closed: [
    `임시 마감`,
    `Temporaily Closed`,
    's_schedule.temporaily_closed',
    `临时关闭`,
    `臨時關閉`,
    `臨時締め切り`,
    `Tạm thời đóng`,
  ],
  tutors_generally_open_their_lesson: [
    <>
      링글 튜터들의 경우, 주로 <Span fontBold>향후 2주까지 스케쥴을 오픈</Span>하고 있습니다.
    </>,
    <>
      Ringle <Span fontBold>tutors generally open their lesson</Span> avaliability no more than{' '}
      <Span bold>two weeks in advance.</Span>
    </>,
    's_schedule.tutors_generally_open_their_lesson',
    <>
      Ringle的教师们的日程表 <Span fontBold>只显示到2周后</Span>
    </>,
    <>
      Ringle的任課教師們通常<Span fontBold>只開啟未來2週的日程</Span>。
    </>,
    <>
      Ringleチューターは基本的に
      <Span font Bold>
        今から2週間後までのスケジュールを公開
      </Span>
      しています。
    </>,
    <>
      Gia sư <Span fontBold>Ringle thường mở lịch</Span> không quá <Span bold>hai tuần.</Span>
    </>,
  ],
  tutor_unavailable: [
    `수업 마감 안내`,
    `Tutor unavailable`,
    's_schedule.tutor_unavailable',
    `课程无法预约提示`,
    `課程無法預約提示`,
    `レッスン締め切りのご案内`,
    `Gia sư không trống lịch`,
  ],
  schedule_closed: (tutor_name) => [
    <>{tutor_name}튜터의 이번주 수업이 마감되었습니다.</>,
    <>{tutor_name}'s schedule is now closed for:</>,
    's_schedule.schedule_closed',
    `{tutor_name}教师这周的课程已全部被预约`,
    `{tutor_name}教師這周的課程已全部被預約`,
    `{tutor_name}チューターの今週のレッスンは締め切りました。`,
    `Lịch của {tutor_name} đã đóng vào:`,
  ],
  closed_time: [
    `마감된 이번주 수업기간:`,
    ``,
    's_schedule.closed_time',
    `无法预约的时间段：`,
    `無法預約的時間段：`,
    `締め切った今週のレッスン期間:`,
    ``,
  ],
  select_different_date: [
    `다른  기간의 스케쥴을 신청해주세요.`,
    `Please select a different date & time.`,
    's_schedule.select_different_date',
    `请选择其他时间段`,
    `請選擇其他時間段`,
    `別の期間のスケジュールを申請してください。`,
    `Vui lòng chọn ngày & giờ khác.`,
  ],
  slot_unavailable: [
    `수업 마감`,
    `Unavailable`,
    's_schedule.slot_unavailable',
    `无法预约`,
    `無法預約`,
    `予約締切`,
    `Không có`,
  ],
  select_tutor_matching_option: [
    `튜터와 매칭이 안 될 경우 어떻게 진행해 드릴까요?`,
    `What should we do if the tutor cancels?`,
    's_schedule.select_tutor_matching_option',
    `请选择匹配失败时的处理方式`,
    `請選擇匹配失敗時的處理方式`,
    `チューターマッチングが出来ない場合はオプション選択をしてください。`,
    `Chúng tôi nên giải quyết thế nào nếu gia sư hủy?`,
  ],
  check_tutor_matching_option: [
    `튜터 매칭 옵션을 선택하고 수업 예약을 완료해주세요.`,
    `Please select the tutor matching option to complete your lesson booking.`,
    's_schedule.check_tutor_matching_option',
    `请选择教师匹配选项来完成预约的最后一步`,
    `請選擇教師匹配選項來完成預約的最後一步`,
    `チューターのマッチングオプションを選択してレッスン予約を完了してください。`,
    `Vui lòng chọn Sắp xếp gia sư để hoàn thành đặt lịch học`,
  ],
  final_step_for_booking: [
    `수업 예약 마지막 단계입니다.`,
    `This is the final step for booking the lesson.`,
    's_schedule.final_step_for_booking',
    `现在进行预约课程的最后一步.`,
    `現在進行預約課程的最後一步.`,
    `レッスン予約の最終段階です。`,
    `Đây là bước cuối cùng để đặt lịch.`,
  ],
  select_credit_b2b: {
    b2b_title: (name, count) => [
      <>
        {name} {count}회 수업권
      </>,
      <>
        {name} {count}Lessons
      </>,
      's_schedule.select_credit_b2b.b2b_title',
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
      <>
        {name} {count}Lessons
      </>,
    ],
    b2b_vaild_n_day: (day, n) => [
      <>수강기간: {day}일 남음</>,
      <>Expires in: {n}days</>,
      's_schedule.select_credit_b2b.b2b_vaild_n_day',
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
      <>Expires in: {n}days</>,
    ],
    unused_b2b_credits_1: [
      `남은 정규 수업권`,
      `Available Credits`,
      's_schedule.select_credit_b2b.unused_b2b_credits_1',
      `Available Credits`,
      `Available Credits`,
      `Available Credits`,
      `Available Credits`,
    ],
    count: (count) => [
      <>{count}</>,
      <>{count}</>,
      's_schedule.select_credit_b2b.count',
      <>{count}</>,
      <>{count}</>,
      <>{count}</>,
      <>{count}</>,
    ],
    attandance: (percent) => [
      <>출석률: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      's_schedule.select_credit_b2b.attandance',
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
    ],
    unused_b2b_credits_2: (count) => [
      <>남은 정규 수업권 {count}회</>,
      <>Available Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_credits_2',
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
      <>Available Credits {count}</>,
    ],
    unused_b2b_makeup_credits: (count) => [
      <>남은 당일 수업권 {count}회</>,
      <>Last-minute Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_makeup_credits',
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
      <>Last-minute Credits {count}</>,
    ],
    attandance_notice: [
      `*출석률 반영을 위해 예약 가능한 당일수업에서 남은 당일 수업권을 사용해주세요.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice',
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
    ],
    attandance_notice_2: [
      `*출석률 반영을 위해 남은 당일 수업권을 사용해주세요.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice_2',
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
    ],
    book_makeup_lesson: [
      `당일수업 예약하기`,
      `Make-up Lessons`,
      's_schedule.select_credit_b2b.book_makeup_lesson',
      `Make-up Lessons`,
      `Make-up Lessons`,
      `Make-up Lessons`,
      `Make-up Lessons`,
    ],
  },
  refund: [
    `불만족 시 수업권 복구`,
    `Get Credit Back`,
    's_schedule.refund',
    `不满意课程时恢复课程券使用`,
    `不滿意課程時重新補發課程券`,
    `レッスンチケット復元`,
    `Đổi lại quyền buổi học`,
  ],
  refund_notice: [
    `자동 매칭 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해드립니다. (튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
    `If you are not satified with the auto-match tutor, we'll return your lesson credit. (Rate as 3 stars or lower and must submit reason for low rating, limited to twice per month)`,
    's_schedule.refund_notice',
    `若您对自动安排的任课教师不满意，我们将为您恢复课程券的使用。（任课教师评价3分以下并提交事由，每月限定2次）`,
    `若您對自動安排的任課教師不滿意，我們將為您新補發課程券。（任課教師評價3分以下並提出事由，每月限定2次）`,
    `自動マッチングチューターとのレッスン満足度が低かった場合、レッスンチケットを返還いたします。 (チューター評価が3点以下および理由を提出、月2回まで)`,
    `Nếu bạn có mức độ hài lòng thấp với một gia sư tự động kết hợp, chúng tôi sẽ khôi phục phiếu giảm vé buổi học. (Đánh giá của gia sư từ 3 điểm trở xuống và lý do được gửi, giới hạn ở 2 lần mỗi tháng)`,
  ],
  recommended_notice: [
    <>
      링글이 추천하는 튜터와 수업해보세요!
      <br />
      #높은평점 #불만족시수업권복구
    </>,
    <>
      Learn from Ringle-recommended tutors!
      <br />
      #HighlyRated #GetCreditBack
    </>,
    's_schedule.recommended_notice',
    <>
      和Ringle为您推荐的任课教师上课!
      <br />
      #高评分 #不满意课程时恢复课程券使用
    </>,
    <>
      和Ringle為您推薦的任課教師上課！
      <br />
      #高評分 #不滿意課程時重新補發課程券
    </>,
    <>
      おすすめのチューターとレッスン！
      <br />
      #HighlyRated #GetCreditBack
    </>,
    <>
      Các bạn hãy tham gia lớn học cùng với gia sư chúng tôi đã giới thiệu đi ạ!
      <br />
      #HighlyRated #GetCreditBack
    </>,
  ],
  btn_detail: [
    <>
      <span className="text-purple-500">자세히 보기</span>
    </>,
    <>
      <span className="text-purple-500">See details</span>
    </>,
    's_schedule.btn_detail',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  automatic_matching_notice: [
    <>
      <span className="text-purple-500">튜터 매칭 옵션</span>에서 [다른 튜터 매칭 받기]를 선택하시면,
      <br />
      튜터와 매칭이 안될 경우 평점이 높은 튜터와 다시 매칭해 드려요.
    </>,
    <>
      If you select [Match me with another tutor] in <span className="text-purple-500">the Tutor Matching Options</span>
      , we'll find another highly-rated tutor for you when a tutor matching falls through.
    </>,
    's_schedule.automatic_matching_notice',
    `在"分配安排任课教师”选项中选择【 接受其他任课教师匹配】，
 任课教师未分配时，重新为您安排评分高的任课教师。`,
    `在「分配安排任課教師」選項中選擇【接受其他任課教師配對】，
 任課教師未分配時，重新為您安排評分高的任課教師。`,
    `チューターマッチングオプションから[他のチューターとマッチングしてもらう]を選ぶと
 チューターとマッチングされない場合に評価の高いチューターと再びマッチングいたします。`,
    `Nếu bạn chọn [Tự động xếp tôi với một gia sư khác.] từ các tùy chọn kết hợp gia sư, Nếu bạn không thể kết hợp với một gia sư, chúng tôi sẽ kết hợp bạn một lần nữa với một gia sư có xếp hạng cao hơn.`,
  ],
  automatic_matching_desc: [
    <>
      <span className="text-red-500">*</span>매칭된 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.
    </>,
    <>
      <span className="text-red-500">*</span>We'll give your credit back if you are not satisfied with the assigned
      tutor.
    </>,
    's_schedule.automatic_matching_desc',
    <>
      <span className="text-red-500">*</span>对安排的任课教师不满意时，将为您恢复课程券的使用。
    </>,
    <>
      <span className="text-red-500">*</span>對安排的任課教師不滿意時，將為您重新補發課程券。
    </>,
    <>
      <span className="text-red-500">*</span>
      マッチングされたチューターとのレッスン満足度が低かった場合、レッスンチケットを返還いたします。
    </>,
    <>
      <span className="text-red-500">*</span>Nếu bạn có mức độ hài lòng thấp với một gia sư phù hợp, chúng tôi sẽ khôi
      vé buổi học giá của bạn.
    </>,
  ],
  choose_automatic_matching: [
    <>
      <span className="text-purple-500">수업 정보</span> 확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please check <span className="text-purple-500">the lesson details</span> and complete the booking.
    </>,
    's_schedule.choose_automatic_matching',
    `请确认信息后完成预约。`,
    `請確認資訊後完成預約。`,
    `レッスン情報を確認後、予約を完了してください。`,
    `Vui lòng kiểm tra thông tin khóa học của bạn và hoàn tất đặt chỗ của bạn.`,
  ],
  tutors_unavailable_recommended: [
    <>
      해당 시간에 수업 가능한 추천 튜터가 없습니다.
      <br />
      [예약 가능] 탭을 확인해주세요.
    </>,
    <>
      There are no featured tutors available for the chosen time slot.
      <br />
      Please check out the [Available] tab.
    </>,
    's_schedule.tutors_unavailable_recommended',
    <>
      没有可以在该时间上课的推荐任课教师。
      <br />
      请确认【可预约】标签。
    </>,
    <>
      沒有可以在該時間上課的推薦任課教師。
      <br />
      請確認【可預約】標籤。
    </>,
    <>
      該当時間にレッスン可能なおすすめチューターはいません。
      <br />
      ［予約可能］タブを確認してください。
    </>,
    <>
      Không có gia sư được gợi ý nào có thể dạy trong thời gian này.
      <br />
      Hãy xem trong mục [Có thể đặt].
    </>,
  ],
  get_matched_with_other_tutors_label: [
    <div className="flex items-center">
      다른 튜터 매칭 받기{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">추천</span>
    </div>,
    <div className="flex items-center">
      Match me with another tutor{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">Featured</span>
    </div>,
    's_schedule.get_matched_with_other_tutors_label',
    <div className="flex items-center">
      接受其他任课教师匹配{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">推荐</span>
    </div>,
    <div className="flex items-center">
      接受其他任課教師配對{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">推薦</span>
    </div>,
    <div className="flex items-center">
      他のチューターとマッチングしてもらう{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">おすすめ</span>
    </div>,
    <div className="flex items-center">
      Tự động xếp tôi với một gia sư khác.{' '}
      <span className="bg-red-50 text-red-500 text-[14px] rounded-[5px] px-[8px] ml-[8px]">Nổi bật</span>
    </div>,
  ],
  ringle_tips: [
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    's_schedule.ringle_tips',
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    `Ringle’s Tip`,
    `Ringle’s Tip`,
  ],
  tips_message_1: [
    `튜터의 수업이 빠르게 마감될 수 있습니다. 미리 예약해보세요.`,
    `Tutors' slots may fill up quickly!`,
    's_schedule.tips_message_1',
    `教师的课很快就会没有了！`,
    `教師的課很快就會沒有了！`,
    `チューター授業の締切が迫っています！`,
    `Các lịch trống của gia sư sẽ được bổ sung nhanh chóng`,
  ],
  tips_message_2: [
    `수업을 미리 예약하면 나와 더 잘 맞는 튜터를 만날 수 있어요.`,
    `Book lessons in advance to learn from tutors that suit you best.`,
    's_schedule.tips_message_2',
    `提早预约课程确保和喜欢的教师上课`,
    `提早預約課程確保和喜歡的教師上課`,
    `授業を事前予約すれば最適なチューターに出会えます。`,
    `Đặt lớp học để tìm gia sư phù hợp với bạn nhất`,
  ],
  tips_message_3: [
    `주 1회 이상 꾸준히 공부해야 영어 실력이 늘어요.`,
    `Study English at least once a week to enhance your English proficiency.`,
    's_schedule.tips_message_3',
    `一週至少进行一次课程英文实力才会增加`,
    `一週至少進行一次課程英文實力才會增加`,
    `週1回以上しっかり勉強すれば実力が向上します。`,
    `Học tiếng anh ít nhất một lần một tuần để nâng cao trình độ tiếng anh của bạn`,
  ],
  tips_message_4: [
    `3~4주 뒤 수업도 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_4',
    `现在提早预约往后一个月的课程吧`,
    `現在提早預約往後一個月的課程吧`,
    `今すぐ一カ月授業を予約しませんか。`,
    `Đăng ký các bài học của bạn trước một tháng.`,
  ],
  tips_message_5: [
    `지금 한 달 수업을 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_5',
    `现在提早预约往后一个月的课程吧`,
    `現在提早預約往後一個月的課程吧`,
    `今すぐ一カ月授業を予約しませんか。`,
    `Đăng ký các bài học của bạn trước một tháng.`,
  ],
  popup_pointback1: [
    <>
      선택한 튜터의 [50% 포인트백]이 마감되었습니다.
      <br />
      혜택을 받으려면 [다시 예약하기]로 다른 새로 온 튜터를 예약해주세요.
    </>,
    ``,
    's_schedule.popup_pointback1',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_pointback2: [
    <>
      무료 수업권으로 예약 시 [50% 포인트백]이 적용되지 않고 예약이 진행됩니다.
      <br />
      50% 포인트백을 받으시려면 유료 수업권으로 예약해 주세요.
    </>,
    <>
      [50% Value Back] is not applicable to lessons booked with a free credit.
      <br />
      To get 50% value back, please book the lesson with a paid credit.
    </>,
    's_schedule.popup_pointback2',
    <>
      使用免費課程券預約時，不適用[50%返點數活動]進行預約。想要獲得
      <br />
      50%點數返還，請用收費課程券預約。
    </>,
    <>
      使用免费课程券预约时，不适用[50%返积分活动]进行预约。想要获得
      <br />
      50%积分返还，请用收费课程券预约。
    </>,
    <>
      無料レッスンチケット出予約した場合［50％ポイントバック］は適応されず予約が進行されます。
      <br />
      50%ポイントバックを受け取るには有料レッスンチケットで予約してください。
    </>,
    <>
      [Hoàn lại 50% điểm] không áp dụng cho phiếu buổi học miễn phí.
      <br />
      Để nhận lại 50% điểm, vui lòng đặt lịch bằng phiếu buổi học trả phí.
    </>,
  ],
  popup_pointback3: [
    <>
      선택한 튜터의 [50% 포인트백]이 마감되었습니다.
      <br />
      50% 포인트백을 받기 위해서는 다른 [새로 온 튜터]와 예약해 주세요.
    </>,
    <>
      This tutor's [50% Value Back] has ended.
      <br />
      To receive 50% value back in points, please choose another [Rookie Tutor].
    </>,
    's_schedule.popup_pointback3',
    <>
      所選導師的[50%返點數活動]已截止。想獲得
      <br />
      50%點數返還，請預約其他[新導師]。
    </>,
    <>
      所选导师的[50%返积分活动]已截止。想获得
      <br />
      50%积分返还，请预约其他[新导师]。
    </>,
    <>
      選択したチューターの［50％ポイントバック］が終了しました。
      <br />
      50% ポイントバックを受け取るためには他の［新しく来たチューター］と予約してください。
    </>,
    <>
      Chương trình [Hoàn lại 50% điểm] của gia sư này đã kết thúc.
      <br />
      Để nhận lại 50% điểm, vui lòng chọn một [Gia sư mới] khác.
    </>,
  ],
  popup_pointback1_btn1: [`다시 예약하기`, ``, 's_schedule.popup_pointback1_btn1', ``, ``, ``, ``],
  popup_pointback1_btn2: [`확인`, `Ok`, 's_schedule.popup_pointback1_btn2', `确认`, `確認`, `確認`, `Ok`],
  popup_pointback2_btn: [`확인`, `Ok`, 's_schedule.popup_pointback2_btn', `确认`, `確認`, `確認`, `Ok`],
}
export const s_history = {
  read: [`읽기`, `Reading`, 's_history.read', `阅读`, `閱讀`, `リーディング`, `Đọc`],
  listen: [`듣기`, `Listening`, 's_history.listen', `听力`, `聽力`, `リスニング`, `Nghe`],
  watch: [`보기`, `Watching`, 's_history.watch', `查看`, `查看`, `ウォッチング`, `Xem`],
  only_ringle_logs_from_mar_1_2021_can_be_checked_: [
    `2021년 3월 30일부터의 학습 활동만 확인 가능합니다.`,
    `Ringle logs are available from Mar 30, 2021.`,
    's_history.only_ringle_logs_from_mar_1_2021_can_be_checked_',
    `仅可确认自2021年3月30日开始的学习活动。`,
    `僅可確認2021年3月30日之後的學習活動。`,
    `2021年3月30日からの学習内容のみ確認可能です。`,
    `Nhật ký Ringle có sẵn từ ngày 30/03/2021.`,
  ],
  haven_t_done_ringle_yet: [
    <>아직 링글 콘텐츠를 경험하지 않으셨네요!</>,
    <>Hi, you haven't explored Ringle content yet!</>,
    's_history.haven_t_done_ringle_yet',
    <>现在还没有体验过Ringle的内容吗!</>,
    <>您尚未體驗Ringle內容！</>,
    <>まだRingleのコンテンツを体験していませんね！</>,
    <>Xin chào, bạn vẫn chưa khám phá các nội dung của Ringle!</>,
  ],
  haven_t_done_ringle_yet_2: [
    <>
      지금 <b>콘텐츠</b> 메뉴에서 다양한 <b>Live 동영상, VOD, 교재</b>를 확인하고 <b>수업</b>을 시작해 보세요.
    </>,
    <>
      Go to <b>Contents</b> to explore our archive of&nbsp;
      <b>webinars and materials</b> and start your study log.
    </>,
    's_history.haven_t_done_ringle_yet_2',
    <>
      现在就在<b>内容</b>菜单中对多样化的<b>Live视频, VOD, 教材</b>等 进行确认后, 开始您的<b>课程</b>吧。
    </>,
    <>
      現在就於<b>內容</b>選單確認各種<b>Live影片、VOD、教材</b>， 開始<b>上課</b>吧！
    </>,
    <>
      今すぐ <b>コンテンツ</b> メニューから多様な <b>Live動画、VOD、教材</b>を 確認して <b>レッスン</b>
      を始めてみましょう。
    </>,
    <>
      Vào phần <b>Nội dung</b> để khám phá kho tàng&nbsp;
      <b>webinars và tài liệu </b> và bắt đầu chuỗi nhật ký học tập.
    </>,
  ],
  haven_t_done_ringle_yet_3: [
    <>여러분이 살펴본 모든 콘텐츠와 수업 학습 데이터까지 모두 기록해서 알려드려요!</>,
    <></>,
    's_history.haven_t_done_ringle_yet_3',
    <>从所有查看内容到课程学习数据, 我们都将为您记录下来并告知予您!</>,
    <>我們會記錄您看過的所有內容與課程學習數據並告訴您！</>,
    <>皆さんがご覧になったすべてのコンテンツからレッスン学習データまですべて記録して お知らせします！</>,
    <></>,
  ],
  haven_t_done_ringle_yet_this_week_all: [
    <>
      이번 주는 아직 링글에서 <b>영어 공부를 시작하지 않으셨네요!</b>
    </>,
    <>You haven't studied English with Ringle this week!</>,
    's_history.haven_t_done_ringle_yet_this_week_all',
    <>
      这周还没有开始您的<b>Ringle英语学习吗!</b>
    </>,
    <>
      這星期您尚未於Ringle<b>開始學習英語！</b>
    </>,
    <>
      今週はまだRingleで <b>英語の勉強を始めていませんね！</b>
    </>,
    <>Bạn chưa học buổi nào cùng Ringle trong tuần này!</>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_all: [
    `지금, 영역별 학습을 시작하고 학습 활동을 쌓아보세요.`,
    `Start learning and add an entry to study log.`,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_all',
    `立即开始各方面的学习, 累积学习活动吧。`,
    `現在就開始學習各領域，累積學習活動吧！`,
    `今すぐ分野別学習を始めて、学習内容を積み重ねてみましょう。`,
    `Bắt đầu việc học và thêm mục đầu tiên trong nhật ký học tập.`,
  ],
  haven_t_done_ringle_yet_this_week_read: [
    <>
      이번 주는 아직 링글에서 <b>읽기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't <b>read</b> any lesson materials this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_read',
    <>
      这周还没开始您的Ringle<b>阅读</b>专项学习吗!
    </>,
    <>
      這星期您尚未於Ringle開始學習<b>閱讀</b>領域！
    </>,
    <>
      今週はまだRingleで <b>リーディング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa <b>đọc</b> tài liệu buổi học nào trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_read: [
    `교재 인사이트, 핵심 표현, 관련 기사 등을 읽고 읽기 영역 학습을 시작해 보세요.`,
    <>Read lesson materials to gain insights and learn new expressions.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_read',
    `请阅读教材正文, 核心表达及相关报道, 开始您的阅读专项学习吧。`,
    `閱讀教材序言、核心用法、相關報導等，開始學習閱讀領域吧！`,
    `教材インサイト、キーフレーズ、関連記事などを読んでリーディング分野の学習を
 始めてみましょう。`,
    <>Đọc tài liệu buổi học để nắm được các insights và học cách diễn đạt mới.</>,
  ],
  haven_t_done_ringle_yet_this_week_listen: [
    <>
      이번 주는 아직 링글에서 <b>듣기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>listening skills</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_listen',
    <>
      这周还没开始您的Ringle <b>听力</b>专项学习吗!
    </>,
    <>
      這星期您尚未於Ringle開始學習<b>聽力</b>領域！
    </>,
    <>
      今週はまだRingleで <b>リスニング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành<b> kỹ năng nghe</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_listen: [
    <>
      <b>교재 MP3, 웨비나, 수업 녹음파일</b> 등을 듣고 듣기 영역 학습을 시작해 보세요.
    </>,
    <>Listen to audio books, Webinars, and lesson recordings.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_listen',
    <>
      <b>请收听教材MP3, 网络研讨会及课程录音文件等</b>内容, 开始您的听力专项学习吧!
    </>,
    <>
      請聆聽<b>教材MP3、網路研討會、課程錄音檔</b>等， 開始學習聽力領域吧！
    </>,
    <>
      <b>教材MP3、ウェビナー、レッスン録音ファイル</b> などを聴いてリスニング分野の学習を始めて みましょう。
    </>,
    <>Nghe sách nói, webinar và các bản ghi âm buổi học.</>,
  ],
  haven_t_done_ringle_yet_this_week_write: [
    <>
      이번 주는 아직 링글에서 <b>쓰기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>writing</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_write',
    <>
      这周还没开始您的Ringle<b>写作</b>专项学习吗!
    </>,
    <>
      這星期您尚未於Ringle開始學習<b>寫作</b>領域！
    </>,
    <>
      今週はまだRingleで <b>ライティング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành <b>bài viết của mình</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_write: [
    <>교재 토론질문에 대한 의견을 작성하고 쓰기 영역의 학습을 시작해 보세요.</>,
    <>Write your response to discussion questions!</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_write',
    <>针对教材讨论问题提出意见, 开始您的写作专项学习吧。</>,
    <>請針對教材課程提問撰寫意見，開始學習寫作領域吧！</>,
    <>教材の質問に対する意見を作成して、ライティング分野の 学習を始めてみましょう。</>,
    <>Viết phần trả lời của bạn cho câu hỏi thảo luận!</>,
  ],
  haven_t_done_ringle_yet_this_week_speak: [
    <>
      이번 주는 아직 링글에서 <b>말하기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>speaking</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_speak',
    <>
      这周还没开始您的Ringle<b>口语</b>专项学习吗!
    </>,
    <>
      這星期您尚未於Ringle開始學習<b>口說</b>領域！
    </>,
    <>
      今週はまだRingleで <b>スピーキング</b> 分野の学習を始めていませんね！
    </>,
    <>
      Bạn chưa thực hành <b>kỹ năng nói</b> trong tuần này!
    </>,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_speak: [
    <>
      <b>1:1 화상영어 수업</b>에서 영어 말하기를 연습하고 말하기 영역 학습을 시작해 보세요.
    </>,
    <>Book a lesson to practice speaking with a tutor.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_speak',
    <>
      <b>在1:1线上英语课程</b>中练习英语会话, 开始您的口语专项学习吧。
    </>,
    <>
      請於<b>1:1視訊英語課程</b>練習英語口說，開始學習口說領域吧！
    </>,
    <>
      <b>1:1オンライン英会話レッスン</b>で英語のスピーキングを練習してスピーキング分野の学習を 始めてみましょう。
    </>,
    <>Đặt lịch học để luyện nói với gia sư.</>,
  ],
  ringle_ing_in_mmm_days: (m, n) => [
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}월 링글 학습
      <Span primary className="pl-2">
        {n}일
      </Span>
    </Span>,
    <>
      <Span className="display-9 pl-2">Ringling in {format(m, 'mmM')}:</Span>
      <Span primary className="display-9 pl-2">
        {n} days
      </Span>
    </>,
    's_history.ringle_ing_in_mmm_days',
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月Ringle学习
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月Ringle學習
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}月のRingle学習
      <Span primary className="pl-2">
        {n}日
      </Span>
    </Span>,
    <>
      <Span className="display-9 pl-2">Học với Ringle {format(m, 'mmM')}:</Span>
      <Span primary className="display-9 pl-2">
        {n} ngày
      </Span>
    </>,
  ],
  this_week: [`이번 주로 가기`, `This week`, 's_history.this_week', `前往本周`, `前往本週`, `今週に移動`, `Tuần này`],
  packet: [`교재`, `Materials`, 's_history.packet', `教材`, `教材`, `教材`, `Tài liệu`],
  lesson: [`수업시간`, `Lesson`, 's_history.lesson', `课程时间`, `課程時間`, `時間`, `Buổi học`],
  webinar: [`웨비나`, `Webinars`, 's_history.webinar', `网络研讨会`, `網路研討會`, `ウェビナー`, `Webinars`],
  ok: [`확인`, `OK`, 's_history.ok', `确认`, `確認`, `確認`, `OK`],
  tutor: [`튜터`, `Tutor`, 's_history.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  minutes: [`분`, `-min`, 's_history.minutes', `分钟`, `分鐘`, `分`, `-phút`],
  this_week_all_checked: [
    `이 주의 모든 학습 활동을 확인하셨습니다.`,
    `You've checked this week's log.`,
    's_history.this_week_all_checked',
    `已确认本周所有学习活动。`,
    `您已確認本週所有學習活動。`,
    `今週のすべての学習内容を確認しました。`,
    `Bạn đã đánh dấu nhật ký tuần này.`,
  ],
  answer: [`답변`, `Response`, 's_history.answer', `回答`, `回覆`, `回答`, `Phản hồi`],
  matching: [
    `매칭 중`,
    `We're finding a tutor for you`,
    's_history.matching',
    `匹配中`,
    `配對中`,
    `マッチング中`,
    `Chúng tôi đang tìm gia sư cho bạn`,
  ],
  answer_edit: [
    <>
      답변
      <br />
      (첨삭신청)
    </>,
    <>
      Response
      <br />
      (Request edits)
    </>,
    's_history.answer_edit',
    <>
      回答
      <br />
      (申请修改)
    </>,
    <>
      回覆
      <br />
      （申請增刪）
    </>,
    <>
      回答
      <br />
      (添削申し込み)
    </>,
    <>
      Phản hồi
      <br />
      (Yêu cầu chỉnh sửa)
    </>,
  ],
  help_modal_title: [`학습 활동`, `Log`, 's_history.help_modal_title', `学习活动`, `學習活動`, `学習内容`, `Nhật ký`],
  help_modal_sub1: [
    `학습 활동 페이지에서는 링글에서 학습한 데이터를 읽기/듣기/쓰기/말하기 영역별로 기록하고 분석한 데이터를 제공합니다.`,
    `Log keeps record of your reading, listening, writing, and speaking practices.`,
    's_history.help_modal_sub1',
    `学习活动页面将提供您的Ringle学习数据, 我们将按照阅读/听力/写作/口语四方面分别进行记录, 并提供给您相关分析数据。`,
    `學習活動頁面將您於Ringle學習的數據，依照閱讀／聽力／寫作／口說領域記錄並提供分析數據。`,
    `学習内容では、Ringleで学習したデータを読み/聞取り/書き/話す分野別に記録して分析したデータを提供します。`,
    `Nhật ký lưu lại phần thực hành đọc, nghe, viết và nói của bạn.`,
  ],
  help_modal_sub2: [
    `학습 활동 기록 항목`,
    ``,
    's_history.help_modal_sub2',
    `学习活动记录项目`,
    `學習活動紀錄項目`,
    `学習内容記録項目`,
    ``,
  ],
  help_modal_sub3: [
    <>교재 인사이트, 핵심 표현, 관련 기사, 다른 링글러의 답변, 수업 노트, 튜터 피드백</>,
    <>Lesson materials, key expressions, linked articles, Ringlers' responses, class notes, and tutor's feedback</>,
    's_history.help_modal_sub3',
    <>教材正文, 核心表达, 相关新闻报道, 其他Ringle用户的回答, 课程笔记及教师 反馈</>,
    <>序言、核心用法、相關報導、其他Ringler的回覆、課堂筆記、任課教師意見回饋</>,
    <>教材インサイト、キーフレーズ、関連記事、他のRinglerの回答、レッスンノート、チューター フィードバック</>,
    <>
      Tài liệu buổi học, những mẫu câu chính, bài báo liên quan, phản hồi của học viên, ghi chú lớp học và đánh giá của
      gia sư
    </>,
  ],
  help_modal_sub4: [
    <>교재 MP3, 핵심표현 듣기, 관련 영상, 웨비나 Live/VOD</>,
    <>Lesson materials audio book, linked videos, webinars</>,
    's_history.help_modal_sub4',
    <>教材MP3, 核心表达听力, 相关视频, 网络研讨会Live/VOD</>,
    <>教材MP3、聆聽核心用法、相關影片、網路研討會Live／VOD</>,
    <>教材MP3、キーフレーズリスニング、関連動画、ウェビナーLive/VOD</>,
    <>Tài liệu buổi học, sách nói, video liên quan, webinars</>,
  ],
  help_modal_sub5: [
    <>교재 토론 질문작성</>,
    <>Submit responses to discussion questions</>,
    's_history.help_modal_sub5',
    <>回答教材讨论问题</>,
    <>教材討論提問的回覆</>,
    <>教材の質問作成</>,
    <>Gửi câu trả lời cho câu hỏi thảo luận</>,
  ],
  help_modal_sub6: [
    <>수업 진행</>,
    <>Lessons</>,
    's_history.help_modal_sub6',
    <>课程进行</>,
    <>課程進行</>,
    <>レッスン進行</>,
    <>Buổi học</>,
  ],
  help_modal_reading: [
    <b>읽기:</b>,
    <b>Reading:</b>,
    's_history.help_modal_reading',
    <b>阅读:</b>,
    <b>閱讀：</b>,
    <b>読み:</b>,
    <b>Đọc:</b>,
  ],
  help_modal_listening: [
    <b>듣기:</b>,
    <b>Listening:</b>,
    's_history.help_modal_listening',
    <b>听力:</b>,
    <b>聽力：</b>,
    <b>聞取り:</b>,
    <b>Nghe:</b>,
  ],
  help_modal_writing: [
    <b>쓰기:</b>,
    <b>Writing:</b>,
    's_history.help_modal_writing',
    <b>写作:</b>,
    <b>寫作：</b>,
    <b>書き:</b>,
    <b>Viết:</b>,
  ],
  help_modal_speaking: [
    <b>말하기:</b>,
    <b>Speaking:</b>,
    's_history.help_modal_speaking',
    <b>口语:</b>,
    <b>口說：</b>,
    <b>話す:</b>,
    <b>Nói:</b>,
  ],
  read_the_packet_again: [
    `교재 다시 읽기`,
    `Read the materials again`,
    's_history.read_the_packet_again',
    `重新阅读教材`,
    `重新閱讀教材`,
    `教材をもう一度読む`,
    `Đọc lại tài liệu`,
  ],
  listen_to_the_mp3_again_: [
    `교재 다시 듣기`,
    `Listen to audiobook again`,
    's_history.listen_to_the_mp3_again_',
    `重新收听教材`,
    `重新聆聽教材`,
    `教材をもう一度聴く`,
    `Nghe lại sách nói`,
  ],
  continue_to_listent_to_the_mp3: [
    `교재 이어서 듣기`,
    `Continue listening`,
    's_history.continue_to_listent_to_the_mp3',
    `继续收听教材`,
    `繼續聆聽教材`,
    `教材を続けて聴く`,
    `Tiếp tục nghe`,
  ],
  clip: [`영상`, `Clip`, 's_history.clip', `视频`, `影片`, `動画`, `Clip`],
  continue_to_watch_the_video: [
    `영상 이어서 보기`,
    `Continue watch clip`,
    's_history.continue_to_watch_the_video',
    `继续观看视频`,
    `繼續觀看影片`,
    `動画を続けて見る`,
    `Tiếp tục xem clip`,
  ],
  watch_the_video_again: [
    `영상 다시 보기`,
    `Watch the clip again`,
    's_history.watch_the_video_again',
    `重新观看视频`,
    `重新觀看影片`,
    `動画をもう一度見る`,
    `Xem lại clip`,
  ],
  article: [`기사`, `News Article`, 's_history.article', `新闻报道`, `報導`, `記事`, `Bài báo`],
  reac_the_article_again: [
    `기사 다시 읽기`,
    `Read news articles again`,
    's_history.reac_the_article_again',
    `重新阅读新闻报道`,
    `重新閱讀報導`,
    `記事をもう一度読む`,
    `Đọc lại bài báo một lần nữa`,
  ],
  expressions: [`핵심표현`, `Expressions`, 's_history.expressions', `核心表达`, `核心用法`, `キーフレーズ`, `Mẫu câu`],
  study_the_expressions_again: [
    `핵심표현 다시 학습하기`,
    `Study the expressions again`,
    's_history.study_the_expressions_again',
    `重新学习核心表达`,
    `重新學習核心用法`,
    `キーフレーズをもう一度学習する`,
    `Học lại mẫu câu`,
  ],
  expression_example: [
    `핵심표현 및 예문`,
    `Expression & Example`,
    's_history.expression_example',
    `核心表达及例句`,
    `核心用法與例句`,
    `キーフレーズと例文`,
    `Mẫu câu & Ví dụ`,
  ],
  listen_to_the_example_again: [
    `핵심표현 예문 다시 듣기`,
    `Listen to the example again`,
    's_history.listen_to_the_example_again',
    `重新收听核心表达的例句`,
    `重新聆聽核心用法例句`,
    `キーフレーズの例文をもう一度聴く`,
    `Nghe lại ví dụ`,
  ],
  question: [`토론질문`, `Questions`, 's_history.question', `讨论问题`, `討論提問`, `質問`, `Câu hỏi`],
  prep_the_lesson: [
    `수업 예습하기`,
    `Lesson Prep`,
    's_history.prep_the_lesson',
    `预习课程`,
    `預習課程`,
    `レッスンの予習をする`,
    `Chuẩn bị Buổi học`,
  ],
  listen_to_lesson_recording_again: [
    `수업 녹음 다시 듣기`,
    `Listen to lesson recording again`,
    's_history.listen_to_lesson_recording_again',
    `重新收听课程录音`,
    `重新聆聽課程錄音`,
    `レッスンの録音をもう一度聴く`,
    `Nghe bản ghi âm buổi học một lần nữa`,
  ],
  review_the_lesson: [
    `수업 복습하기`,
    `Review Lesson`,
    's_history.review_the_lesson',
    `复习课程`,
    `複習課程`,
    `レッスンの復習をする`,
    `Xem lại buổi học`,
  ],
  read_the_feedback_again: [
    `튜터 피드백 다시 확인하기`,
    `Read tutor feedback again`,
    's_history.read_the_feedback_again',
    `重新确认教师反馈`,
    `重新確認任課教師的意見回饋`,
    `チューターフィードバックをもう一度確認する`,
    `Xem lại đánh giá của gia sư`,
  ],
  continue_to_watch_the_clip: [
    `클립 이어서 보기`,
    `Continue watching the clip`,
    's_history.continue_to_watch_the_clip',
    `继续观看短片`,
    `繼續觀看片段`,
    `動画を続けて見る`,
    `Tiếp tục xem clip`,
  ],
  watch_the_clip_again: [
    `클립 다시 보기`,
    `Watch the clip again`,
    's_history.watch_the_clip_again',
    `重新观看短片`,
    `重新觀看片段`,
    `動画をもう一度見る`,
    `Xem lại clip`,
  ],
  watch_the_webinar_again: [
    `웨비나 다시 보기`,
    `Watch the webinar again`,
    's_history.watch_the_webinar_again',
    `重新观看网络研讨会`,
    `重新觀看網路研討會`,
    `ウェビナーをもう一度見る`,
    `Xem lại webinar`,
  ],
  canceled_lesson: [
    `취소된 수업`,
    `Cancelled Lesson`,
    's_history.canceled_lesson',
    `取消的课程`,
    `已取消的課程`,
    `キャンセルしたレッスン`,
    `Buổi học đã huỷ`,
  ],
  date_time: (n) => [
    <DateComponent format="M월 D일(dd) a hh:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="ddd, mmM D, h:mm A" locale="en-US">
      {n}
    </DateComponent>,
    's_history.date_time',
    <DateComponent format="M月 D日(dd) a hh:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="M月 D日(dd) a hh:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="M月 D日(dd) a hh:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="D mmmm [năm] hh:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  full_date: (n) => [
    <DateComponent format="yyyy년 mm월 dd일" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mmM dd, yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_history.full_date',
    <DateComponent format="yyyy年 mm月 dd日" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月 dd日" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月 dd日" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="D mm [năm] yyyy" locale="vi">
      {n}
    </DateComponent>,
  ],
  year_month: (n) => [
    <DateComponent format="yyyy년 mm월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mmM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_history.year_month',
    <DateComponent format="yyyy年 mm月" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="mm [năm] yyyy" locale="vi">
      {n}
    </DateComponent>,
  ],
}
export const s_curation = {
  source: [`출처`, `Source`, 's_curation.source', `出处`, `出處`, `出典`, `Nguồn`],
  go_to_prev: [`홈으로 돌아가기`, `Home`, 's_curation.go_to_prev', `返回主页`, `返回首頁`, `ホームに戻る`, `Trang chủ`],
  example: [`예문`, `Example`, 's_curation.example', `例句`, `例句`, `例文`, `Ví dụ`],
  topic: [`주제`, `Topic`, 's_curation.topic', `主题`, `主題`, `テーマ`, `Chủ đề`],
  today_s_expression: [
    `오늘의 expression`,
    `Today's expression`,
    's_curation.today_s_expression',
    `今日expression`,
    `今日expression`,
    `今日のexpression`,
    `Mẫu câu hôm nay`,
  ],
  want_to_read_the_packet_related_to_this_brief_: [
    `이 Daily Brief가 마음에 드셨다면?`,
    `View Lesson Materials related to this brief`,
    's_curation.want_to_read_the_packet_related_to_this_brief_',
    `想阅读此Brief相关的教材吗?`,
    `如果想閱讀這則Brief的相關教材？`,
    `このBrief関連教材を読みたいなら？`,
    `Xem lại Tài liệu học liên quan đến bản tóm tắt này`,
  ],
  want_to_watch_the_webinar_related_to_this_brief_: [
    `이 Brief 관련 웨비나를 보고 싶다면?`,
    `View Webinars related to this brief`,
    's_curation.want_to_watch_the_webinar_related_to_this_brief_',
    `想观看此Brief相关的网络研讨会吗?`,
    `如果想觀看這則Brief的相關網路研討會？`,
    `このBrief関連ウェビナーを見たいなら？`,
    `Xem Webinars liên quan đến bản tóm tắt này`,
  ],
  go_to_read_the_packet: [
    `교재 읽으러 가기`,
    `Go to Lesson Materials`,
    's_curation.go_to_read_the_packet',
    `前往阅读教材`,
    `前往閱讀教材`,
    `教材を読む`,
    `Truy cập Tài liệu học`,
  ],
  go_to_watch_the_webinar: [
    `웨비나 보러 가기`,
    `Go to Webinars`,
    's_curation.go_to_watch_the_webinar',
    `前往观看网络研讨会`,
    `前往觀看網路研討會`,
    `ウェビナーを見る`,
    `Truy cập Webinars`,
  ],
  this_week_s_briefs: [
    `이번 주의 Briefs`,
    `This Week's Briefs`,
    's_curation.this_week_s_briefs',
    `本周的Briefs`,
    `本週Briefs`,
    `今週のBriefs`,
    `Tin tức tuần này`,
  ],
  reading_now: [
    `지금 읽고 있음`,
    `Reading now`,
    's_curation.reading_now',
    `正在阅读中`,
    `目前正在閱讀`,
    `今読んでいます`,
    `Đọc ngay bây giờ`,
  ],
  modal_body: (day, hour, min) => [
    <>
      이 Brief는 {day <= 0 ? '' : day + '일'} {hour <= 0 ? '' : hour + '시간'}&nbsp;
      {min <= 0 ? '' : min + '분'} 후에 확인할 수 있어요!
    </>,
    <>
      This Daily Brief will be availalbe in {day <= 0 ? '' : day + ' days'}&nbsp;
      {hour <= 0 ? '' : hour + ' hours'} {min <= 0 ? '' : min + ' mins'}.
    </>,
    's_curation.modal_body',
    <>
      本Brief可于{day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '小时'}&nbsp;
      {min <= 0 ? '' : min + '分钟'}后进行确认!
    </>,
    <>
      這則Brief可於{day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '小時'}&nbsp;
      {min <= 0 ? '' : min + '分鐘'}後確認！
    </>,
    <>
      このBriefは {day <= 0 ? '' : day + '日'} {hour <= 0 ? '' : hour + '時間'}&nbsp;
      {min <= 0 ? '' : min + '分'} 後に確認することができます！
    </>,
    <>
      Daily Brief sẽ có trong {day <= 0 ? '' : day + ' days'}&nbsp;
      {hour <= 0 ? '' : hour + ' hours'} {min <= 0 ? '' : min + ' mins'}.
    </>,
  ],
}
export const s_lesson_edit = {
  do_you_want_to_exit: [
    `수업 시간 변경 페이지를 나가겠습니까?`,
    `Do you want to exit rescheduling?`,
    's_lesson_edit.do_you_want_to_exit',
    `您确定要离开课程时间变更页面吗?`,
    `確定要離開變更課程時間頁面嗎？`,
    `レッスン時間変更ページを閉じますか？`,
    `Bạn có muốn thoát phần dời lịch?`,
  ],
  change_time_of_lesson: [
    `수업 시간 변경`,
    `Reschedule`,
    's_lesson_edit.change_time_of_lesson',
    `课程时间变更`,
    `變更課程時間`,
    `レッスン時間変更`,
    `Dời lịch`,
  ],
  before_change: [
    `변경 전`,
    `Before change`,
    's_lesson_edit.before_change',
    `变更前`,
    `變更前`,
    `変更前`,
    `Trước khi thay đổi`,
  ],
  after_change: [
    `변경 후`,
    `After change`,
    's_lesson_edit.after_change',
    `变更后`,
    `變更後`,
    `変更後`,
    `Sau khi thay đổi`,
  ],
  title_cancel_lesson: [
    `수업 취소`,
    `Cancel Lesson`,
    's_lesson_edit.title_cancel_lesson',
    `课程取消`,
    `取消課程`,
    `レッスンキャンセル`,
    `Huỷ buổi học`,
  ],
  cancel_lessons: [
    `수업 취소`,
    `Cancel Lesson`,
    's_lesson_edit.cancel_lessons',
    `课程取消`,
    `取消課程`,
    `レッスンキャンセル`,
    `Huỷ buổi học`,
  ],
  select_the_reason_for_cancellation: [
    `수업 취소 이유를 선택해 주세요.`,
    `Select reason for cancellation.`,
    's_lesson_edit.select_the_reason_for_cancellation',
    `请选择您取消课程的理由。`,
    `請選擇取消課程的理由。`,
    `レッスンのキャンセル理由を選択してください。`,
    `Chọn lí do huỷ lớp.`,
  ],
  change_lessons: [
    `수업 변경`,
    `Change Lessons`,
    's_lesson_edit.change_lessons',
    `课程变更`,
    `變更課程`,
    `レッスン変更`,
    `Đổi buổi học`,
  ],
  select_the_category_you_want_to_change: [
    `변경을 원하시는 메뉴를 클릭해주세요.`,
    `Select category you want to change.`,
    's_lesson_edit.select_the_category_you_want_to_change',
    `请点击希望变更的菜单。`,
    `請點擊希望變更的選單。`,
    `変更したいメニューをクリックしてください。`,
    `Chọn mục bạn muốn thay đổi.`,
  ],
  request_cancellation: [
    `수업 취소 요청`,
    `Cancel Lesson`,
    's_lesson_edit.request_cancellation',
    `申请课程取消`,
    `要求取消課程`,
    `レッスンキャンセルリクエスト`,
    `Huỷ buổi học`,
  ],
  change_lesson_materials: [
    `교재 변경`,
    `Change lesson materials`,
    's_lesson_edit.change_lesson_materials',
    `教材变更`,
    `變更教材`,
    `教材変更`,
    `Đổi tài liệu học`,
  ],
  btn_change_lesson_material: [
    `교재 변경하기`,
    `Change lesson materials`,
    's_lesson_edit.btn_change_lesson_material',
    `教材变更`,
    `變更教材`,
    `教材を変更する`,
    `Đổi tài liệu học`,
  ],
  title_change_time: [
    `시간 변경`,
    `Reschedule`,
    's_lesson_edit.title_change_time',
    `时间变更`,
    `變更時間`,
    `時間変更`,
    `Dời lịch`,
  ],
  time_has_been_changed: [
    `수업 시간이 변경되었습니다.`,
    `The lesson has been rescheduled.`,
    's_lesson_edit.time_has_been_changed',
    `时间已变更。`,
    `時間變更成功。`,
    `時間が変更されました。`,
    `Buổi học này đã được dời lịch.`,
  ],
  select_time_to_change: [
    `변경할 시간을 선택해 주세요`,
    `Select time.`,
    's_lesson_edit.select_time_to_change',
    `请选择希望变更的时间`,
    `請選擇欲變更的時間`,
    `変更する時間を選択してください。`,
    `Lựa chọn thời gian.`,
  ],
  btn_changing_time: [
    `시간 변경하기`,
    `Reschedule`,
    's_lesson_edit.btn_changing_time',
    `时间变更`,
    `變更時間`,
    `時間を変更する`,
    `Dời lịch`,
  ],
  not_prepared_for_the_lesson: [
    `수업을 들을 준비가 부족합니다.`,
    `I am not prepared for this lesson.`,
    's_lesson_edit.not_prepared_for_the_lesson',
    `课程准备不足。`,
    `上課準備不足。`,
    `レッスンの準備が足りません。`,
    `Tôi chưa chuẩn bị cho buổi học.`,
  ],
  urgent_personal_matter: [
    `급한 개인 사정이 생겨서 수업을 취소합니다.`,
    `I have a conflict.`,
    's_lesson_edit.urgent_personal_matter',
    `因紧急个人事务取消课程。`,
    `突然有私人急事，故取消課程。`,
    `急な個人的な都合によりレッスンをキャンセルします。`,
    `Tôi có việc gấp.`,
  ],
  other_reasons: [
    `기타 사유`,
    `Others`,
    's_lesson_edit.other_reasons',
    `其他原因`,
    `其他原因`,
    `その他の理由`,
    `Lí do khác`,
  ],
  let_us_know_the_reason_for_cancelling: [
    `기타 취소 사유를 입력해주세요.`,
    `Type in your reason for cancellation.`,
    's_lesson_edit.let_us_know_the_reason_for_cancelling',
    `请输入其他取消的原因。`,
    `請輸入其他取消原因。`,
    `その他のキャンセル理由を入力してください。`,
    `Chọn lý do huỷ.`,
  ],
  automatic_matching_for_tutors: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_lesson_edit.automatic_matching_for_tutors',
    `教师自动匹配`,
    `自動配對任課教師`,
    `チューター自動マッチング`,
    `Xếp ngẫu nhiên tôi với một gia sư`,
  ],
  select_the_materials_to_change: [
    `변경할 교재를 선택해 주세요`,
    `Select lesson materials.`,
    's_lesson_edit.select_the_materials_to_change',
    `请选择要变更的教材`,
    `請選擇要變更的教材`,
    `変更する教材を選択してください。`,
    `Chọn tài liệu học.`,
  ],
  click_to_change_material: [
    `버튼을 누르면 교재가 변경 됩니다`,
    ``,
    's_lesson_edit.click_to_change_material',
    `点击按钮, 即可变更教材`,
    `點擊按鈕時，將變更教材。`,
    `ボタンを押すと教材が変更されます。`,
    ``,
  ],
  material_has_been_changed: [
    `교재가 변경되었습니다.`,
    `Your lesson materials have been changed.`,
    's_lesson_edit.material_has_been_changed',
    `教材已变更。`,
    `教材變更成功。`,
    `教材が変更されました。`,
    `Tài liệu học đã thay đổi.`,
  ],
  do_you_want_to_cancel_changing_materials: [
    `교재 변경을 취소하시겠습니까?`,
    `Do you want to cancel changing lesson materials?`,
    's_lesson_edit.do_you_want_to_cancel_changing_materials',
    `您确定要取消教材变更吗?`,
    `確定要取消教材變更嗎？`,
    `教材変更をキャンセルしますか？`,
    `Bạn có muốn huỷ việc thay đổi tài liệu học không?`,
  ],
  select_material: [
    `교재 선택`,
    `Select`,
    's_lesson_edit.select_material',
    `教材选择`,
    `教材選擇`,
    `教材を選択`,
    `Chọn tài liệu`,
  ],
  cancel_material: [
    `선택 취소`,
    `Cancel`,
    's_lesson_edit.cancel_material',
    `选择取消`,
    `選擇取消`,
    `選択をキャンセル`,
    `Hủy chọn`,
  ],
  info_change_material_more_than_1h: [
    `수업 예약 후 교재를 변경할 수 있습니다. 부담 없이 교재를 선택해주세요.`,
    `You can change the lesson material after you complete booking the lesson, so please go ahead and choose what interests you most.`,
    's_lesson_edit.info_change_material_more_than_1h',
    `预约课程后可以更改教材。请自由选择教材`,
    `預約課程后可以更改教材。請自由選擇教材`,
    `レッスン予約後も教材を変更することができます。気軽に教材を選択してください。`,
    `Bạn có thể thay đổi tài liệu sau khi đặt buổi học. Hãy thoải mái lựa chọn tài liệu.`,
  ],
  info_change_material_less_than_1h: [
    `수업 시작 1시간 이내인 경우 '자유주제' 또는 '튜터가 이전에 수업한 교재'만 선택 가능합니다.`,
    `If the lesson begins in less than one hour, you can either select 'Free Topic' or choose from 'Tutor's Previously Taught Materials.`,
    's_lesson_edit.info_change_material_less_than_1h',
    `如果课程开始时间不超过1小时，则只能选择"自由主题”或"任课教师先前授课的教材”。`,
    `如果課程開始時間不超過1小時，則只能選擇"自由主題”或"任課教師先前授課的教材”。`,
    `レッスン開始1時間以内である場合「自由な主題」または「チューターが以前レッスンした教材」のみ選択可能です。`,
    `Trong vòng 1 tiếng trước khi bắt đầu buổi học, bạn chỉ có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử dụng trước đây”`,
  ],
  change_material_modal_title: [
    `교재 변경 확인`,
    `Change Lesson Material`,
    's_lesson_edit.change_material_modal_title',
    `教材更改确认`,
    `教材更改確認`,
    `教材の変更確認`,
    `Xác nhận thay đổi tài liệu`,
  ],
  change_material_modal_content: [
    <>
      새로 선택한 교재로 변경하시겠습니까?
      <br />
      교재 변경 시 진행한 예습 목록은 초기화됩니다.
    </>,
    <>
      Would you like to change to the selected lesson material?
      <br />
      Changing the lesson material will also reset your lesson prep list.
    </>,
    's_lesson_edit.change_material_modal_content',
    <>
      是否要更改为新选择的教材？
      <br />
      更改教材时预习列表将被初始化。
    </>,
    <>
      是否要更改為新選擇的教材？
      <br />
      更改教材時預習清單將被初始化。
    </>,
    <>
      新しく選択した教材に変更しますか？
      <br />
      教材を変更すると進めていた予習リストが初期化されます。
    </>,
    <>
      Đổi thành tài liệu bạn vừa mới chọn?
      <br />
      Khi thay đổi tài liệu, mục lục học trước đã thực hiện sẽ khởi tạo lại.
    </>,
  ],
  change_material_modal_content_80_correction_info: [
    `80단어 첨삭 요청을 한 경우, 기존 요청 건은 취소가 되며 새로 첨삭을 신청해야 합니다.`,
    `Any previous request for 80-word response editing will be cancelled. If you wish to request 80-word response editing, please submit your response for the new lesson material.`,
    's_lesson_edit.change_material_modal_content_80_correction_info',
    `如果要求增删80个词，既有的增删请求将被取消，您必须申请新的增删要求。`,
    `如果要求增刪80個詞，既有的增刪請求將被取消，您必須申請新的增刪要求。`,
    `80単語添削リクエストをした場合、既存のリクエストはキャンセルされ、新しく添削を申請する必要があります。`,
    `Nếu bạn đã yêu cầu chỉnh sửa 80 từ, yêu cầu đó sẽ bị hủy và bạn phải gửi lại yêu cầu mới.`,
  ],
  modal_cancel: [`취소`, `Cancel`, 's_lesson_edit.modal_cancel', `取消`, `取消`, `キャンセル`, `Hủy`],
  modal_proceed: [
    `교재 변경`,
    `Confirm`,
    's_lesson_edit.modal_proceed',
    `教材更改`,
    `教材更改`,
    `教材を変更`,
    `Thay đổi tài liệu`,
  ],
}
export const s_coupon_box = {
  subtitle: [
    `각종 혜택을 누릴 수 있는 쿠폰을 확인하세요`,
    ``,
    's_coupon_box.subtitle',
    `请确认可享受各种优惠的优惠券。`,
    `請確認可享受各種優惠的優惠券。`,
    `各種特典を受けられるクーポンをご確認ください。`,
    ``,
  ],
  coupon_count: (count) => [
    <>
      쿠폰 <span className="text-purple-500">{count}개</span>
    </>,
    <>
      You have <span className="text-purple-500">{count} coupons</span>
    </>,
    's_coupon_box.coupon_count',
    <>
      优惠券<span className="text-purple-500">{count}张</span>
    </>,
    <>
      優惠券<span className="text-purple-500">{count}張</span>
    </>,
    <>
      クーポン <span className="text-purple-500">{count}枚</span>
    </>,
    <>
      Bạn có <span className="text-purple-500">{count} coupon</span>
    </>,
  ],
  redeem: [`사용하기`, `Redeem`, 's_coupon_box.redeem', `使用`, `使用`, `使用する`, `Đổi thưởng`],
  used: [`사용완료`, `Used`, 's_coupon_box.used', `使用完成`, `已使用`, `使用済み`, `Đã sử dụng`],
  bonus_coupon_type_1_title: [
    `3회 수업권 45% 할인 쿠폰`,
    `45% off 3 lessons credits`,
    's_coupon_box.bonus_coupon_type_1_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_1: [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은 <b>3회 수업권</b> 구매 시 첫 구매 가격으로 할인해드리는 쿠폰입니다. (정가 대비 45% 할인)
        </li>
        <li>본 쿠폰은 20분, 40분 수업 모두 사용 가능합니다.</li>
        <li>
          본 쿠폰을 적용하여 20분 또는 40분 <b>3회 수업권</b> 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.
        </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is valid until January 28th, 2021.</li>
        <li>
          Redeeming this coupon applies a 45% discount from the regular price for the <b>First 3 Lessons package</b>.
        </li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>
          If you have further questions, please contact us via chat below or through @Ringle on Kakao Talk channel.
        </li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_1',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_2_title: (amount) => [
    `재등록 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `Returning Customer Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_2_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_2: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_2',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_3_title: (amount) => [
    `신년 특가 추가 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `New Year’s Resolution Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_3_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_3: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_3',
    <> </>,
    <> </>,
    <> </>,
    <> </>,
  ],
  bonus_coupon_type_4_title: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_4_title',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  bonus_coupon_type_4: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_4',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  use_confirm: [
    `사용하시겠습니까?`,
    `Submit?`,
    's_coupon_box.use_confirm',
    `确定要使用吗?`,
    `確定要使用嗎？`,
    `使用しますか？`,
    `Gửi?`,
  ],
  expired: [`기간만료`, `Expired`, 's_coupon_box.expired', `已到期`, `已到期`, `期間満了`, `Hết hạn`],
  bonus_coupon_type_5_title: [
    `재구매 할인쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_5_title',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  bonus_coupon_type_5: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_5',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  bonus_coupon_type_11_title: [
    `웰컴쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.bonus_coupon_type_11_title',
    `Welcome优惠券`,
    `Welcome優惠券`,
    `ウェルカムクーポン`,
    `Coupon chào mừng`,
  ],
  bonus_coupon_type_11: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 쿠폰 발급 후 7일 이내 사용 가능합니다
          <br />
          • 타 쿠폰과 중복하여 사용할 수 없습니다.
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Valid for 7 days after issuance
          <br />
          • Cannot be combined with any other coupons
          <br />
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_11',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 领取优惠券后7天之内可使用
          <br />
          • 无法和其他优惠券一起使用
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 領取優惠券后7天之内可使用
          <br />
          • 無法和其他優惠券一起使用
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • クーポン発行後7日以内に使用可能です
          <br />
          • 他のクーポンと重複しているため、使用できません
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Thời hạn sử dụng 7 ngày
          <br />
          • Không áp dụng cùng lúc với coupon khác
          <br />
        </Div>
      </Div>
    </>,
  ],
  bonus_coupon_type_12_title: (duration, number) => [
    <>
      시크릿 할인 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_12_title',
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
  ],
  bonus_coupon_type_12: (duration, number) => [
    <>
      <ul>
        <li>본 쿠폰은 2022년 6월 21일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은{' '}
          <b>
            {duration}분/{number}회 정규 수업권
          </b>{' '}
          구매 시 적용 가능합니다.{' '}
        </li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_12',
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
  ],
  bonus_coupon_type_15_title: (duration, number) => [
    <>
      블랙프라이데이 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_15_title',
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
  ],
  bonus_coupon_type_15: (duration, number) => [
    <>
      <ul>
        <li>
          {duration}분 {number}회 수업권 구매 시 적용 가능합니다.{' '}
        </li>
        <li>2022년 12월 2일 오후 14:00(KST)에 만료됩니다.</li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_15',
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
  ],
  title_secret: [
    `시크릿 쿠폰`,
    `Secret Coupon`,
    's_coupon_box.title_secret',
    `Secret Coupon`,
    `Secret Coupon`,
    `Secret Coupon`,
    `Secret Coupon`,
  ],
  title_welcome: [
    `웰컴 쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.title_welcome',
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
    `Welcome Coupon`,
  ],
  title_next_1: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next_1',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  title_next_2: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next_2',
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
    `Next Purchase Coupon`,
  ],
  title2: (duration, number) => [
    <>
      {duration}분 {number}회 수업권 구매 시 적용 가능
    </>,
    <>
      Redeemable for {duration}-min {number} credits
    </>,
    's_coupon_box.title2',
    <>
      Redeemable for {duration} {number} credits
    </>,
    <>
      Redeemable for {duration} {number} credits
    </>,
    <>
      Redeemable for {duration} {number} credits
    </>,
    <>
      Redeemable for {duration} {number} credits
    </>,
  ],
  expiration: (date) => [
    <>{date}까지</>,
    <>Valid till {date}</>,
    's_coupon_box.expiration',
    <>截止到{date}</>,
    <>截止到{date}</>,
    <>{date}まで</>,
    <>Đến {date}</>,
  ],
  discount: (amount) => [
    <>{amount} 할인</>,
    <>${amount} off</>,
    's_coupon_box.discount',
    <>${amount} off</>,
    <>${amount} off</>,
    <>${amount} off</>,
    <>${amount} off</>,
  ],
  desc_secret: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능합니다.
          <br />
          • 2022년 12월 22일 저녁 23시 59분에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_secret',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_next_1: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 35일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_1',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_next_2: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_2',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  desc_welcome: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_welcome',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
  ],
  title_new: [
    `신규가입 쿠폰`,
    `Welcome Coupon`,
    's_coupon_box.title_new',
    `新注册优惠券`,
    `新註冊優惠券`,
    `新規入会クーポン`,
    `Coupon chào mừng`,
  ],
  title2_new: (min, credits) => [
    <>
      {min}분 {credits}회 수업권 구매 시 적용 가능
    </>,
    <>
      Redeemable for {min}-min {credits} credits
    </>,
    's_coupon_box.title2_new',
    <>
      购买{credits}次{min}分钟课程券时可使用
    </>,
    <>
      購買{credits}次{min}分鐘課程券時可使用
    </>,
    <>
      {min}分{credits}回 購入時適用可能
    </>,
    <>
      Lớp {min}p {credits} buổi
    </>,
  ],
  desc_new: (duration, credits, year, month, day, hour, min) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {credits}회 수업권 구매 시 자동 적용됩니다.
          <br />• {year}년 {month}월 {day}일 {hour}:{min}에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeems automatically when purchasing {duration}-min {credits} credits.
          <br />• Expires on {month} {day}, {year} at {hour}:{min}
          <br />• Cannot be combined with other coupons.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_new',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 购买{credits}次{duration}分钟课程券时可自动使用。
          <br />• 于{year}年{month}月{day}日到期。
          <br />• 不可以和其它优惠券重复使用。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 購買{credits}次{duration}分鐘課程券時可自動使用。
          <br />• 於{year}年{month}月{day}日到期。
          <br />• 不可以和其它優惠券重複使用。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}分{credits}回レッスンチケット購入時自動適用されます。
          <br />• {year}年{month}月{day}日{hour}:{min}に満了します。
          <br />• その他クーポンと同時に使用できません。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Tự động áp dụng khi mua thẻ học {duration} phút {credits} buổi.
          <br />• Hết hạn vào {hour}:{min}, ngày {day} tháng {month} năm {year}.<br />• Không thể dùng đồng thời với
          Coupon khác.
        </Div>
      </Div>
    </>,
  ],
  benefit_new: (min, credits) => [
    <>
      {min}분 수업권 {credits}회 증정
    </>,
    <>
      Free {min}-min {credits} credits
    </>,
    's_coupon_box.benefit_new',
    <>
      赠送{credits}次{min}分钟课程券
    </>,
    <>
      贈送{credits}次{min}分鐘課程券
    </>,
    <>
      {min}分レッスン{credits}回贈呈
    </>,
    <>
      Free {min}-min {credits} credits
    </>,
  ],
  title_extend: [
    `수강기간 연장 쿠폰`,
    `Extension Coupon`,
    's_coupon_box.title_extend',
    `延长听课时间优惠券`,
    `延長聽課時間優惠券`,
    `受講期間延長クーポン`,
    `Coupon gia hạn`,
  ],
  title2_extend: [
    `보유한 수업권의 수강기간 연장 가능`,
    `For extending lesson days`,
    's_coupon_box.title2_extend',
    `可以延长所持有课程券的听课时间`,
    `可以延長所持有課程券的聽課時間`,
    `レッスンチケット期間延長可能`,
    `Gia hạn thẻ đang có`,
  ],
  desc_extend: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 수강기간이 남아 있는 수업권에만 적용 가능합니다. (만료된 수업권에 사용할 수 없습니다.)
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />• 쿠폰 사용 후, 수업권 환불 시 수강기간 연장에 대한 비용 차감 후 환불됩니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to extend the lesson days of a chosen credit.
          <br />
          • Expires in 60 days after issuance
          <br />• The regular price for extending lesson days will be deducted if you choose to refund this credit in
          the future after using the extension coupon.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_extend',
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 选择课程券可以延长听课时间。
          <br />
          • 可以在优惠券发放60天内使用。
          <br />• 若延长后再申请课程券退款，将扣除延长听课时间的费用后退款。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 選擇課程券可以延長聽課時間。
          <br />
          • 可以在優惠券發放60天內使用。
          <br />• 若延長後再申請課程券退款，將扣除延長聽課時間的費用後退款。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • レッスンチケットを選択して受講期間の延長が可能です。
          <br />
          • クーポン発行後60日以内に使用可能です。
          <br />•
          延長後に該当レッスンチケットを返金する際は、受講期間の延長にかかった費用を差し引いた金額を返金致します。
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Có thể chọn thẻ học để gia hạn.
          <br />
          • Hạn sử dụng 60 ngày kể từ ngày cấp Coupon.
          <br />• Nếu yêu cầu hoàn tiền sau khi gia hạn, học phí sẽ được hoàn lại sau khi trừ phí gia hạn.
        </Div>
      </Div>
    </>,
  ],
  time: (hh, mm) => [
    <>
      {hh}시간 {mm}분 남음
    </>,
    <>
      {hh}:{mm} left
    </>,
    's_coupon_box.time',
    <>
      还剩{hh}小时{mm}分钟
    </>,
    <>
      還剩{hh}小時{mm}分鐘
    </>,
    <>
      残り{hh}時間{mm}分
    </>,
    <>
      Còn lại {hh} giờ {mm} phút
    </>,
  ],
  use_new: [
    `신규가입 쿠폰 사용`,
    `Redeem Welcome Coupon`,
    's_coupon_box.use_new',
    `新注册优惠券使用`,
    `新註冊優惠券使用`,
    `新規入会クーポン使用`,
    `Dùng Coupon chào mừng`,
  ],
  btn_new: [
    `구매페이지 이동`,
    `Go to purchase page`,
    's_coupon_box.btn_new',
    `前往购买页面`,
    `前往購買頁面`,
    `購入ページを移動`,
    `Chuyển đến trang mua hàng`,
  ],
  use_extend: [
    `수강기간 연장 쿠폰 사용`,
    `Redeem Extension Coupon`,
    's_coupon_box.use_extend',
    `延长听课时间优惠券使用`,
    `延長聽課時間優惠券使用`,
    `受講期間延長クーポン使用`,
    `Dùng Coupon gia hạn`,
  ],
  notice1_extend: [
    <>
      수강기간을 연장할 수업권을 선택해 주세요.
      <br />
      유의사항 모두 체크 후 [수강기간 연장하기]를 클릭해 주세요.{' '}
    </>,
    <>
      Please select the credit you wish to extend.
      <br />
      Please check all terms then click on [Extend].
    </>,
    's_coupon_box.notice1_extend',
    <>
      请选择要延长听课时间的课程券。
      <br />
      请阅读所有注意事项后，点击【课程期限延长】。
    </>,
    <>
      請選擇要延長聽課時間的課程券。
      <br />
      請閱讀所有注意事項後，點擊【延長聽課期間】。
    </>,
    <>
      受講期間を延長するレッスンチケットを選択してください。
      <br />
      注意事項全てにチェックし[受講期間延長]をクリックしてください。
    </>,
    <>
      Hãy chọn thẻ học cần gia hạn.
      <br />
      Sau khi chọn hết các nội dung lưu ý, nhấp vào [Gia hạn ngày kết thúc].
    </>,
  ],
  select_credit: [
    `수업권을 선택하세요.`,
    `Please select a credit.`,
    's_coupon_box.select_credit',
    `请选择课程券。`,
    `請選擇課程券。`,
    `レッスンチケットを選択してください`,
    `Hãy chọn thẻ học.`,
  ],
  terms: [`유의사항`, `Terms`, 's_coupon_box.terms', `注意事项`, `注意事項`, `注意事項`, `Chú ý`],
  notice2_extend: [
    `수강기간을 연장할 수업권 정보를 확인했습니다.`,
    `I have checked the information on the credit I chose to extend using the coupon.`,
    's_coupon_box.notice2_extend',
    `已确认要延长听课时间的课程券信息。`,
    `已確認要延長聽課時間的課程券信息。`,
    `受講期間延長クーポンを適用するレッスンチケットを確認しました。`,
    `Hãy kiểm tra thông tin thẻ học sử dụng Coupon gia hạn.`,
  ],
  notice3_extend: [
    <>연장 후 해당 수업권 환불 시, 수강기간 연장에 대한 비용 차감 후 환불됩니다.</>,
    `I understand that the regular price for extending lesson days will be deducted if I choose to refund this credit in the future after applying the coupon.`,
    's_coupon_box.notice3_extend',
    `若延长后再申请课程券退款，将扣除延长听课时间的费用后退款。`,
    `若延長後再申請課程券退款，將扣除延長聽課時間的費用後退款。`,
    `延長後に該当レッスンチケットを返金する際は、受講期間延長にかかった費用を差し引いた金額を返金致します。`,
    `Nếu yêu cầu hoàn tiền sau khi gia hạn, học phí sẽ được hoàn lại sau khi trừ phí gia hạn.`,
  ],
  btn_extend: [
    `수강기간 연장`,
    `Extend`,
    's_coupon_box.btn_extend',
    `课程期限延长`,
    `延長聽課期間`,
    `受講期間延長`,
    `Gia hạn ngày kết thúc`,
  ],
  toast_extend: [
    `수강기간이 연장되었습니다.`,
    `Lesson days have been extended.`,
    's_coupon_box.toast_extend',
    `听课时间已延长。`,
    `聽課時間已延長。`,
    `受講期間が延長されました。`,
    `Đã gia hạn`,
  ],
  left_credit: [
    `남은 수업권`,
    `Remaining`,
    's_coupon_box.left_credit',
    `所剩课程券`,
    `所剩課程券`,
    `使用可能`,
    `Có thể dùng`,
  ],
  left_date: (n) => [
    <>수강기간 {n}일 남음</>,
    <>{n} days left</>,
    's_coupon_box.left_date',
    <>听课时间还剩{n}天</>,
    <>聽課時間還剩{n}天</>,
    <>残り{n}日</>,
    <>Còn {n} ngày</>,
  ],
  extend_date: (n) => [
    <>+{n}일 연장</>,
    <>+add {n} days</>,
    's_coupon_box.extend_date',
    <>+延长{n}天</>,
    <>+延長{n}天</>,
    <>+{n}日延長</>,
    <>+{n} ngày</>,
  ],
  add: (n) => [
    <div className="flex items-center">
      추가 연장 <div className="ml-[4px] text-red-500">+{n}일</div>
    </div>,
    <div className="flex items-center">
      add <div className="text-red-500 ml-[4px]">+{n} days</div>
    </div>,
    's_coupon_box.add',
    <>额外延长+{n}天</>,
    <>額外延長+{n}天</>,
    <>追加延長+{n}日</>,
    <>Thêm +{n} ngày</>,
  ],
  title_next: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.title_next',
    `回购优惠券`,
    `回購優惠券`,
    `再購入クーポン`,
    `Coupon mua lại`,
  ],
  benefit_price: (price) => [
    <>{price} 할인</>,
    <>Extra {price} off</>,
    's_coupon_box.benefit_price',
    <>{price}优惠</>,
    <>{price}折扣</>,
    <>{price}割引</>,
    <>{price} Off</>,
  ],
  use_next: [
    `재구매 쿠폰 사용`,
    `Redeem Next Purchase Coupon`,
    's_coupon_box.use_next',
    `新注册优惠券使用`,
    `新註冊優惠券使用`,
    `新規入会クーポン使用`,
    `Dùng Coupon chào mừng`,
  ],
}
export const s_preparation = {
  exit: [`나가기`, `Exit`, 's_preparation.exit', `离开`, `離開`, `閉じる`, `Thoát ra`],
  prep: [`예습`, `Prep`, 's_preparation.prep', `预习`, `預習`, `予習`, `Chuẩn bị`],
  save: [`저장`, `Save`, 's_preparation.save', `保存`, `儲存`, `保存`, `Lưu`],
  free_editing: [
    `80단어 답변 첨삭`,
    `80-word Response Editing`,
    's_preparation.free_editing',
    `80单词回答修改`,
    `80個單字的增刪回覆`,
    `80単語回答添削`,
    `Chỉnh sửa câu trả lời 80 từ`,
  ],
  correction_placeholder: [
    `수업시간 하루 전까지, 교정 받고 싶은 문장을 넣어주세요. 단 Confidential한 내용은 넣지 마세요!`,
    `Submit your response in 80 words or less at least 24 hours before the lesson starts. Please be sure not to share anything confidential!`,
    's_preparation.correction_placeholder',
    `最晚于课程开始前一天, 填写好希望修改的句子。但请勿涉及Confidential内容!`,
    `最晚請於課程時間開始一天之前，寫下希望糾正的句子。但請勿寫下Confidential的內容！`,
    `レッスン開始24時前までに、添削してもらいたい文章を80単語まで入力してください。個人情報や機密事項は入力しないでください`,
    `Gừi câu trả lời của bạn trong 80 từ hoặc ít nhất 24 giờ trước khi buổi học bắt đầu. Hãy chắc chắn bạn không chia sẻ những thông tin tuyệt mật!`,
  ],
  click_if_you_want_this_question: [
    `수업에서 다룰 질문을 선택해 주세요!`,
    `Select question to discuss in lesson`,
    's_preparation.click_if_you_want_this_question',
    `请选择希望于课程中讨论的问题!`,
    `請選擇上課時要討論的提問！`,
    `レッスンで扱う質問を選択してください！`,
    `Chọn câu hỏi để thảo luận trong buổi học`,
  ],
  show_only_to_me: [
    `튜터와 나만보기`,
    `Only visible to me & tutor`,
    's_preparation.show_only_to_me',
    `仅教师和本人可见`,
    `僅任課教師與我查看`,
    `チューターと自分だけ見る`,
    `Chỉ hiển thị cho tôi & gia sư`,
  ],
  my_answer: [
    `내 답변`,
    `My Response`,
    's_preparation.my_answer',
    `我的回答`,
    `我的回覆`,
    `あなたの回答`,
    `Câu trả lời của tôi`,
  ],
  my_answer_placeholder: [
    `질문에 대한 생각을 작성해 주세요. 작성하신 내용은 수업 직전까지 언제든 수정하실 수 있습니다.`,
    `Please write your response. You can revise it any time before the lesson.`,
    's_preparation.my_answer_placeholder',
    `请写下您对问题的回答, 同时编写的内容于课程前可随时进行修改。`,
    `請寫下您對於提問的想法。課程開始前，您隨時可以修改您寫的內容。`,
    `質問に対する考えを作成してください。作成する内容はレッスン直前までいつでも修正することができます。`,
    `Vui lòng viết câu trả lời của bạn. Bạn có thể sửa lại bất cứ lúc nào trước buổi học.`,
  ],
  upload_your_file_to_share: [
    <>
      [Free Topic 수업 유의사항]
      <br />
      1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 문서 업로드를 권장 드립니다.
      <br />
      2) <Span className="font-bold">문서 교정은 수업 시간에 실시간으로 진행</Span>됩니다.
      <br />
      3) 개인 정보가 노출될 수 있는 <Span className="font-bold">민감한 내용은 사전 수정 요청</Span>드립니다.
      <br />
      4) 수업 중 영문 교정을 위해 개인 자료를 업로드 시,{' '}
      <Span className="font-bold">20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장 분량)</Span> 분량을 권장
      드립니다.
      <br />
      5) Writing-focused 위주의 수업은 튜터 피드백 리포트가 제공되지 않습니다.{' '}
    </>,
    <>
      Please upload any materials you'd like to discuss in this class.
      <br />
      <br />
      1) Please upload your document at least 24 hours before the lesson.
      <br />
      2) Writing corrections will be conducted live during the lesson.
      <br />
      3) Please be sure to delete or redact any personal or confidential information.
      <br />
      4)When you upload your own material to be edited during your Free Topic lesson, please limit the word count to 500
      (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
      <br />
      5) A separate tutor's feedback report is not provided after Writing Focused Lessons.
    </>,
    's_preparation.upload_your_file_to_share',
    <>
      [Free Topic课程注意事项]
      <br />
      1) <Span className="font-bold">建议最晚于课程开始前24小时</Span>完成文件上传。
      <br />
      2) <Span className="font-bold">文件可在课程中实时</Span>进行修改。
      <br />
      3) 可能会暴露个人信息的<Span className="font-bold">敏感内容, 请提前</Span>予以删除。
      <br />
      4) Writing-focused 课程不提供任课教师反馈报告。
    </>,
    <>
      [Free Topic課程注意事項]
      <br />
      1) 建議最晚於<Span className="font-bold">課程開始24小時前</Span>上傳文件。
      <br />
      2) <Span className="font-bold">將在課程中即時糾正文件</Span>。
      <br />
      3) 可能洩漏個人資料的
      <Span className="font-bold">
        敏感內容，請您事先修改
        <br />
        4) Writing-focused 課程不會提供任課教師的意見回饋報告書，敬請留意。
      </Span>
      。
    </>,
    <>
      [自由テーマレッスンの注意事項]
      <br />
      1) <Span className="font-bold">レッスン開始24時間前</Span>までに資料をアップロードすることを推奨します。
      <br />
      2) <Span className="font-bold">文書校正はレッスン時間にリアルタイムで行い</Span>ます。
      <br />
      3) 個人情報が流出する恐れのある <Span className="font-bold">デリケートな内容は、事前に修正リクエスト</Span>
      します。
      <br />
      4)
      レッスン中の英文添削のため個人の材料をアップロードする場合、20分レッスンは500字（A41枚）、40分レッスンは1000字（A42枚）の分量をお勧めします。
      <br />
      5) Writing-focused レッスンでは、チューターフィードバックレポートは提供されません。
    </>,
    <>
      Vui lòng tải lên bất cứ tài liệu nào bạn muốn thảo luận trong buổi học.
      <br />
      <br />
      1) Vui lòng tải tài liệu của bạn ít nhất 24 tiếng trước buổi học.
      <br />
      2) Chỉnh sửa bài viết sẽ diễn ra trong buổi học.
      <br />
      3) Hãy đảm bảo xoá hoặc biên tập lại những thông tin cá nhân hoặc tuyệt mật.
      <br />
      4) Sau khi tải tài liệu của bạn để sử dụng trong buổi học Chủ đề tự do, lưu ý về số lượng từ trong tài liệu: 500
      từ (khoảng 1 trang A4) với buổi học 20 phút và 1,000 từ (2 tờ A4) cho buổi học 40 phút.
      <br />
      5) Các buổi học tập trung viết không cung cấp báo cáo nhận xét từ gia sư.
    </>,
  ],
  correct_policy1: [
    `교정 요청 원문 및 교정된 문장은 익명으로 공개되어, 링글 수강생들의 학습용도로 사용될 예정입니다.
이에, 교정 요청 전 수강생의 신분을 노출시킬 수 있는 단어, 사내 기밀 내용이 담긴 문장이 있는지 살펴주시면 감사하겠습니다`,
    `Submitted document and tutor's edits may be used as an educational reference for other Ringlers. We will not reveal your name.
Please make sure that your document does not contain any personal or confidential information.`,
    's_preparation.correct_policy1',
    `申请校正的原文及校正后的句子将以匿名的形式公开, 用于Ringle学员们共同学习。
因此在请求校正前, 请确认是否有暴露学员身份的敏感词汇及可能涉及公司机密的内容, 非常感谢。`,
    `要求糾正的原文及糾正過的句子將以匿名方式公開，預計使用於Ringle學生們的學習用途。
因此，請於要求糾正前注意句子是否包含洩漏學生身分的單字或公司機密。`,
    `添削リクエストの原文および添削された文章は匿名で公開され、Ringle受講生の学習用途に使われる予定です。したがって、添削リクエスト前に、受講生の個人情報が流出するおそれのある単語や機密事項が記された文章が入っていないことを確認してください。`,
    `Tài liệu được gửi và chỉnh sửa từ gia sư có thể được sử dụng làm tài liệu tham khảo cho các học viên khác. Chúng tôi sẽ không tiết lộ tên của bạn. Hãy đảm bảo tài liệu của bạn không bao gồm thông tin cá nhân và tuyệt mật nào.`,
  ],
  answer_history: [
    `지난 수업에 작성한 답변`,
    `My Previous Response`,
    's_preparation.answer_history',
    `之前课程书写的回答`,
    `上次課程撰寫的回覆`,
    `過去レッスンでの回答履歴`,
    `Câu trả lời trước của tôi`,
  ],
  correct_policy2: [
    <Fragment>
      <li>
        예습 답안교정 베타 서비스는 수업 당 80단어 (80 words) 까지 제공되며, 교정된 답은 수업 종료 후 피드백 레포트를
        통해 확인 가능합니다.
      </li>
      <li>
        교정 요청하신 원문 및 튜터가 제공한 교정본은 향후 익명으로 공개될 예정이며, Ringle 의 수강생분들의 영어 학습
        콘텐츠로 제공될 예정입니다.
      </li>
      <li>
        각 질문 별 답을 작성하시는 고객 분들께, 다른 수강생이 해당 질문에 대한 예습 시 남긴 교정 요청 원문 및 튜터가
        제공한 교정본을 익명으로 open 하여, 다른 수강생 분들께서 영어 공부 content 로 활용하실 수 있게 제공될
        예정입니다.
      </li>
      <li>
        이에, 수강생 분의 신분이 노출될 수 있는 내용, 기밀 유지가 필요한 내용에 대해서는 교정 요청을 하지 말아주시길
        부탁드립니다.
      </li>
      <li>
        Ringle 은 수강생 분들의 영어 학습 과정이, 다른 수강생 분들께도 영어 학습을 위한 content 로 제공될 수 있는 환경을
        조성하고자 합니다. 단, 모든 content 는 익명으로 제공하여 수강생 분들의 privacy 보호에 가장 큰 중점을 둘
        예정입니다.
      </li>
      <li>
        관련하여 의견 및 피드백 있으시면 언제든 contact@ringleplus.com 을 통해 연락주십시오. 저희가 바로 회신드리고
        소통하고, 의견을 적극 반영하겠습니다. 감사합니다.
      </li>
    </Fragment>,
    <Fragment>
      <li>
        The Response Editing Service (beta) provides free editing of 80 words per lesson. You can view the edits on the
        Feedback Report after the lesson.
      </li>
      <li>
        The submitted document and its edited version will be used for educational purposes without revealing the
        writer's name.
      </li>
      <li>
        Your answers and your tutor's corrections will be shown to other Ringlers that are answering the same questions
        to help with their learning process. All of the disclosed answers will be anonymous.
      </li>
      <li>
        Therefore, please do not submit a document that contains personal or confidential information for the editing
        service.
      </li>
      <li>
        Ringle aims to establish a learning atmosphere where your learning experience can help other Ringlers. However,
        we will protect your privacy by posting everything anonymously.
      </li>
      <li>
        If you have any suggestions or feedback regarding this, please do not hesitate to contact us at
        contact@ringleplus.com. We will respond as soon as possible, and do our best to reflect your opinions on our
        services. Thank you.
      </li>
    </Fragment>,
    's_preparation.correct_policy2',
    <Fragment>
      <li>
        预习答案校正的服务, 每个课程仅可提供80字(80 words), 同时校正后的结果可于课程结束后通过反馈报告书进行确认。
      </li>
      <li>申请校正的原文及教师提供的修改文之后可能会以匿名的方式 进行公开, 作为Ringle学员们英语学习的共享内容。</li>
      <li>
        对于各问题的回答者, 我们将以匿名的方式提供其他学员对该问题的
        回答和校正结果。以这种方式为学员提供互相学习的平台。
      </li>
      <li>因此, 对于那些可能会暴露学员身份及需要保密的内容, 请不要提出 校正请求。</li>
      <li>
        Ringle希望建立一个可将学员们的英语学习过程转化成为其他学员学习 content的良好互助环境。但,
        所有content都将以匿名的形式提供, 我们 将保护学员隐私作为重中之重。
      </li>
      <li>
        相关意见及反馈, 可随时通过contact@ringleplus.com与我们联系。 我们会及时给予您回复, 积极沟通妥善处理。非常感谢。
      </li>
    </Fragment>,
    <Fragment>
      <li>預習答案糾正測試版服務每堂課最多80個單字（80 words）， 並可於課程結束後透過反饋報告書確認糾正的結果。</li>
      <li>要求糾正的原文及任課教師提供的糾正版本之後預計 以匿名方式公開，提供作為Ringle學生們的英語學習內容。</li>
      <li>
        我們預計為針對各提問撰寫答案的學生，以匿名方式 open其他學生針對該提問預習時留下的要求糾正原文及
        任課教師提供的糾正版本，並將提供作為英語學習content 以供其他學生使用。
      </li>
      <li>因此，請勿針對可能洩漏學生身分的內容或需要保密的內容 要求糾正。</li>
      <li>
        Ringle希望建立可以將學生們的英語學習過程提供作為 其他學生英語學習content的環境。但所有content
        均以匿名方式提供，我們將重點保護各位學生的隱私。
      </li>
      <li>如有相關意見回饋，歡迎隨時透過contact@ringleplus.com聯絡我們， 我們會立即回覆並積極反映您的意見，感謝。</li>
    </Fragment>,
    <Fragment>
      <li>
        予習回答添削Betaサービスは1レッスンあたり80単語 (80 words)
        まで対応し、添削されたものは授業終了後、フィードバックレポートを通して確認可能です。
      </li>
      <li>
        添削をリクエストした原文およびチューターが提供した添削内容は、今後匿名で公開される予定であり、Ringle受講生たちの英語学習コンテンツとして提供される予定です。
      </li>
      <li>
        各質問に回答を作成される受講生の皆様へ。他の受講生がその質問に対する予習時に提出した添削リクエストの原文およびチューターが提供した添削内容を匿名で公開し、他の受講生たちに英語学習コンテンツとして活用できるように提供される予定です。
      </li>
      <li>
        したがって、受講生の個人情報が流出するおそれのある内容、機密事項などは添削リクエストに入力しないようお願いします。
      </li>
      <li>
        Ringleでは、受講生の英語学習成果を他の受講生の英語学習のためのコンテンツとして提供できる環境を設ける取組みをしています。すべてのコンテンツは匿名で提供され、受講生の方のプライバシー保護を最優先することに努めます。
      </li>
      <li>
        \n\nこの件に関してご意見やフィードバックがございましたら、いつでもcontact@ringleplus.comまでご連絡ください。迅速にご返信し、積極的にご意見を取り入れていきたいと考えております。どうぞよろしくお願いいたします。
      </li>
    </Fragment>,
    <Fragment>
      <li>
        Dịch vụ chỉnh sửa câu trả lời phiên bản Beta, bạn có thể gửi tài liệu tối đa 80 chữ để chỉnh sửa miễn phí trước
        buổi học.
      </li>
      <li>
        Tài liệu được gửi và phiên bản đã chỉnh sửa sẽ được dùng cho mục đích giáo dục. Tên người viết sẽ được giữ kín.
      </li>
      <li>
        Câu trả lời và phần chỉnh sửa của gia sư sẽ hiển thị cho những học viên khác có cùng câu hỏi để giúp đỡ mọi
        người trong quá trình học. Tất cả câu trả lời hiển thị sẽ được ẩn danh.
      </li>
      <li>Vậy nên, vui lòng không gửi tài liệu có chứa thông tin cá nhân hay thông tin tuyệt mật để chỉnh sửa.</li>
      <li>
        Ringle mong muốn tạo ra một môi trường học tập nơi mà trải nghiệm học của bạn có thể giúp những học viên khác.
        Tuy nhiên, chúng tôi sẽ bảo vệ quyền riêng tư bằng cách đăng tải mọi thứ ở trạng thái ẩn danh.
      </li>
      <li>
        Nếu bạn có bất cứ đề xuất hoặc phản hồi liên quan đến vấn đề này, hãy liên hệ với chúng tôi qua email
        contact@ringleplus.com. Chúng tôi sẽ phản hồi sớm nhất có thể, và cố gắng hết sức để thể hiện sự tiếp thu ý kiến
        trong dịch vụ của mình. Trân trọng.
      </li>
    </Fragment>,
  ],
  terms: [
    `이용약관`,
    `Terms of Use`,
    's_preparation.terms',
    `使用条款`,
    `使用條款`,
    `添削利用規約`,
    `Điều khoản sử dụng`,
  ],
  intro: [`인트로`, `Intro`, 's_preparation.intro', `介绍`, `開頭`, `イントロ`, `Giới thiệu`],
  insight: [`인사이트`, `Insight`, 's_preparation.insight', `正文`, `序言`, `インサイト`, `Insight`],
  clip: [`영상`, `Video`, 's_preparation.clip', `视频`, `影片`, `動画`, `Video`],
  article: [`기사`, `News`, 's_preparation.article', `新闻报道`, `報導`, `記事`, `News`],
  clipComplete: [
    `영상 1개 보기를 완료하셨습니다!`,
    `You watched one video.`,
    's_preparation.clipComplete',
    `已看完1个视频!`,
    `您已看完1個影片！`,
    `動画を1本見るを完了しました！`,
    `Bạn đã xem một video.`,
  ],
  toastInsight: [
    `인사이트 읽기를 완료하셨습니다!`,
    `You completed reading Insight!`,
    's_preparation.toastInsight',
    `已阅读完正文!`,
    `您已看完序言！`,
    `インサイトを読み終わりました！`,
    `Bạn đã hoàn thành phần insight bài đọc!`,
  ],
  listenMp3: [
    `MP3 듣기를 완료하셨습니다!`,
    `You completed listening to audiobook!`,
    's_preparation.listenMp3',
    `已收听完MP3!`,
    `您已聽完MP3！`,
    ``,
    `Bạn đã nghe xong sách nói!`,
  ],
  readInsight: [
    `인사이트 읽기 완료`,
    `Done reading Insight`,
    's_preparation.readInsight',
    `阅读完毕`,
    `序言閱讀完畢`,
    `インサイト読み完了`,
    `Đã đọc xong insight`,
  ],
  keyQuestion: [
    `수업 질문`,
    `Questions`,
    's_preparation.keyQuestion',
    `课程提问`,
    `課程提問`,
    `レッスンの質問`,
    `Câu hỏi`,
  ],
  keyQeustionSelected: (length) => [
    `수업 질문 ${length}개 선택하기를 완료하셨습니다!`,
    `You selected ${length} discussion questions!`,
    's_preparation.keyQeustionSelected',
    `已选择 ${length}个课程提问!`,
    `您已選擇${length}個課程提問！`,
    `レッスンの質問を ${length}つ選択するを完了しました！`,
    `Bạn đã chọn ${length} câu hỏi thảo luận!`,
  ],
  googleDocs: [
    `수업 노트`,
    `Lesson Notes`,
    's_preparation.googleDocs',
    `课程笔记`,
    `課堂筆記`,
    `レッスンノート`,
    `Ghi chú buổi học`,
  ],
  saved: [`저장됨`, `Saved`, 's_preparation.saved', `已保存`, `已儲存`, `保存済み`, `Đã lưu`],
  you_can_only_select_up_to_three_questions: [
    `20분 수업의 경우 최대 3개의 질문만 선택 가능합니다.`,
    `You can only choose up to 3 questions for 20-minute lessons.`,
    's_preparation.you_can_only_select_up_to_three_questions',
    `20分钟的课程最多只能选择3个问题。`,
    `20分鐘的課程最多只能選擇3個問題。`,
    `20分レッスンの場合、最大3つの質問のみ選択可能です。`,
    `Bạn chỉ có thể chọn tối đa 3 câu hỏi cho buổi học 20 phút.`,
  ],
  you_can_view_previous_answers: [
    `질문에 남기신 답변은 저장되어 다음 예습 시 열람하실 수 있습니다.`,
    `You'll be able to view your responses at Lesson Prep.`,
    's_preparation.you_can_view_previous_answers',
    `有关问题的回答已保存, 下次预习时可进行阅览。`,
    `針對提問留下的回覆將會儲存，並可於下次預習時閱覽。`,
    `質問への回答は保存され、次の予習時に閲覧することができます。`,
    `Bạn có thể xem câu trả lời ở phần Chuẩn bị Buổi học.`,
  ],
  your_upload_has_failed: [
    `서류 업로드가 정상적으로 되지 않았습니다. 다시 업로드해주세요`,
    `Your upload has failed. Please try again.`,
    's_preparation.your_upload_has_failed',
    `文件上传出现问题, 请重新尝试`,
    `文件上傳失敗，請重新上傳。`,
    `書類のアップロードに失敗しました。もう一度アップロードしてください。`,
    `Tải lên không thành công. Vui lòng thử lại.`,
  ],
  the_size_of_file_is_less_than_10mb: [
    `크기가 10MB를 넘지 않는 파일만 업로드가 가능합니다.`,
    `You cannot upload files larger than 10MB.`,
    's_preparation.the_size_of_file_is_less_than_10mb',
    `只能上传不超过10MB的文件。`,
    `僅可上傳不超過10MB的檔案。`,
    `アップロードできるファイルのサイズは10MBまでです。`,
    `Bạn không thể tải files lớn hơn 10MB.`,
  ],
  unsupported_file_format: [
    `지원되지 않는 파일 형식입니다. 확인후 다시 업로드해주세요.`,
    `This file type is not supported. Please check that your file is PPT, PPTX, DOC, DOCX, or PDF and re-upload.`,
    's_preparation.unsupported_file_format',
    `尚未支持的文件格式, 请确认后重新上传。`,
    `不支援的檔案格式，請確認後重新上傳。`,
    `対応していないファイル形式です。ファイルがPPT, PPTX, DOC, DOCX, PDFのいずれかであることを確認し、再度アップロードしてください。`,
    `Định dạng này không thể tải lên. Vui lòng sử dụng file PPT, PPTX, DOC, DOCX, hoặc PDF  tải lên lại.`,
  ],
  upload_your_file_first: [
    `파일을 먼저 업로드해주세요!`,
    `Please first upload your file.`,
    's_preparation.upload_your_file_first',
    `请先上传文件!`,
    `請先上傳檔案！`,
    `ファイルを先にアップロードしてください！`,
    `Vui lòng tải file của bạn trước.`,
  ],
  document_submission: [
    `자료 업로드`,
    `Upload`,
    's_preparation.document_submission',
    `上传材料`,
    `上傳資料`,
    `資料アップロード`,
    `Tải lên`,
  ],
  delete_documents: [
    `자료 삭제`,
    `Delete document`,
    's_preparation.delete_documents',
    `删除材料`,
    `刪除資料`,
    `資料削除`,
    `Xoá tài liệu`,
  ],
  reuploading: [
    `다른 자료 업로드`,
    `Re-upload`,
    's_preparation.reuploading',
    `上传其他材料`,
    `上傳其他資料`,
    `他の資料アップロード`,
    `Tải lên lại`,
  ],
  download_template: [
    `템플릿 다운로드`,
    `Download Templates`,
    's_preparation.download_template',
    `下载模板`,
    `下載模板`,
    `テンプレートダウンロード`,
    `Tải xuống template`,
  ],
  displayed_after_file_upload: [
    `업로드한 자료가 없습니다.`,
    ``,
    's_preparation.displayed_after_file_upload',
    `没有上传的材料。`,
    `無上傳的資料。`,
    `アップロードした資料はありません。`,
    ``,
  ],
  google_docs_is_being_generated: [
    `Google Docs 생성 중입니다.`,
    `Generating Google Docs...`,
    's_preparation.google_docs_is_being_generated',
    `Google Docs加载中。`,
    `Google Docs生成中。`,
    `Google Docs作成中です。`,
    `Đang tạo Google Docs...`,
  ],
  if_no_screen: [
    <>
      업로드한 자료가 보이지 않으면 새로고침해주세요. <br />
      직접 교정받고 싶은 내용은 수업 노트에 붙여보세요.
    </>,
    `If the material doesn't show up, please try refreshing the page.`,
    's_preparation.if_no_screen',
    <>
      无法看到上传的材料, 请重新刷新。 <br />
      希望直接进行校正的内容请粘贴到课程笔记上。
    </>,
    <>
      如未看到上傳的資料，請重新整理。
      <br />
      請將想直接糾正的內容貼於課堂筆記。
    </>,
    <>
      アップロードした資料が表示されない場合、ページを更新してください。 <br />
      直接校正してほしい内容はレッスンノートにコピーしてください。
    </>,
    `Nếu tài liệu không hiển thị, vui lòng làm mới trang.`,
  ],
  other_people_ans: [
    `다른 링글러들의 답변`,
    `Ringlers’ Response`,
    's_preparation.other_people_ans',
    `其他Ringle用户的回答`,
    `其他Ringler的回覆`,
    `他のRinglerの回答`,
    `Phản hồi học viên Ringle`,
  ],
  share_answer: [
    `선택 해제시 다른 수강생에게 내 답변이 공유됩니다.`,
    `If you deselect, other Ringlers can view your response.`,
    's_preparation.share_answer',
    `解除选择时, 我的回答将共享给其他学员。`,
    `解除選擇時，其他學生將會看到我的回覆。`,
    `選択解除すると、他の受講生にあなたの回答が共有されます。`,
    `Nếu bạn bỏ chọn, những học viên Ringle khác có thể xem trả lời của bạn.`,
  ],
  select_question: [
    `수업 질문으로 선택하기`,
    `Select question to discuss in lesson`,
    's_preparation.select_question',
    `选择课程问题`,
    `選為課程提問`,
    `レッスンの質問に選択する`,
    `Chọn câu hỏi thảo luận cho buổi học`,
  ],
  upload_material: [
    <>
      수업에서 다루고 싶은 자료를 업로드 해보세요.
      <br />
      직접 교정받고 싶은 내용은 오른쪽 수업 노트에 붙여보세요.
    </>,
    <>
      Upload your lesson materials.
      <br />
      If you have a written sample that need proofreading, please try pasting it on Lesson Notes.
    </>,
    's_preparation.upload_material',
    <>
      请上传希望于课程中学习的资料。
      <br />
      希望直接纠正的内容, 请粘贴在右边的课程笔记上。
    </>,
    <>
      請將想於課程中進行的資料上傳。
      <br />
      請將想直接糾正的內容貼於右側的課堂筆記。
    </>,
    <>
      レッスンで扱いたい資料をアップロードしてみましょう。
      <br />
      直接校正してほしい内容は、右のレッスンノートにペーストしてください。
    </>,
    <>
      Tải lên học liệu của bạn.
      <br />
      Nếu bạn có bài viết mẫu cần hiệu đính, hãy sao chép vào phần Ghi chú buổi học.
    </>,
  ],
  no_saved_answer: [
    `저장된 답변이 없습니다.`,
    `No answer saved.`,
    's_preparation.no_saved_answer',
    `没有保存的回答。`,
    `無儲存的回覆。`,
    `保存した回答はありません。`,
    `Không có câu trả lời nào được lưu.`,
  ],
  no_shared_answer: [
    `다른 링글러들의 답변이 아직 없습니다. 첫번째 답변 작성자가 되어 보세요.`,
    `Be the first one to share a response to this question!`,
    's_preparation.no_shared_answer',
    `其他Ringle使用者还没有回答。快成为第一个回答问题的人吧。`,
    `還沒有其他Ringler回覆，快成為第一個回覆的人吧！`,
    `他のRinglerたちの回答はまだありません。最初の回答作成者になってみましょう。`,
    `Hãy là người đầu tiên chia sẻ câu trả lời cho câu hỏi này!`,
  ],
  open_note_new_tab: [
    `수업 노트 새 탭으로 열기 (1hr)`,
    `Open in a new tab (1hr)`,
    's_preparation.open_note_new_tab',
    `于新页面中打开课程笔记 (1hr)`,
    `開啟課堂筆記新分頁 (1hr)`,
    `レッスンノートを新しいタブで開く (1hr)`,
    `Mở một tab mới (1hr)`,
  ],
  upload_material_notice: [
    `10mb 미만 PPT, PPTX, DOC, DOCX, PDF 파일 형식만 지원하며, 1개 파일만 업로드 할 수 있습니다.`,
    `You can only upload one file with PPT, PPTX, DOC, DOCX, PDF format less than 10 MB.`,
    's_preparation.upload_material_notice',
    `仅支持10mb以下的PPT, PPTX, DOC, DOCX, PDF文件形式, 并且仅可上传1个文件。`,
    `僅支援小於10MB的PPT、PPTX、DOC、DOCX、PDF檔，且僅可1個檔案。`,
    `アップロードに対応するファイルはサイズが10MB未満、形式はPPT、PPTX、DOC、DOCX、PDFのいずれかのファイル1つのみです。`,
    ``,
  ],
  upload_youtube_link: [
    `수업에서 다루고 싶은 유튜브 영상의 링크를 공유해주세요.`,
    `Upload the YouTube link you want to discuss with the tutor.`,
    's_preparation.upload_youtube_link',
    `请共享课程中希望使用的Youtube视频链接。`,
    `請分享上課時希望進行的YouTube影片連結。`,
    `レッスンで扱いたいYouTube動画のリンクを共有してください。`,
    ``,
  ],
  notice_youtube_link: {
    one: [
      `분 미만의 유튜브 영상 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one YouTube video link less than ( ) minute.`,
      's_preparation.notice_youtube_link.one',
      `分钟以下的Youtube视频链接仅可共享1个。`,
      `僅可分享少於____分鐘的1個YouTube影片連結。`,
      `分未満のYouTube動画1本のリンクのみ共有可能です。`,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_youtube_link.two',
      `希望将课程相关内容留言给教师时, 可将内容粘贴到课程笔记上。`,
      `請於課堂筆記寫下課程相關內容與任課教師分享。`,
      `レッスン内容に関して、チューターに伝えたいことをレッスンノートに入力してください。`,
      ``,
    ],
  },
  upload_article_link: [
    `수업에서 다루고 싶은 아티클의 링크를 공유해주세요.`,
    `Upload the article link you want to discuss with the tutor.`,
    's_preparation.upload_article_link',
    `请共享课程中希望使用的文章链接。`,
    `請分享上課時希望進行的文章連結。`,
    `レッスンで扱いたいアーティクルのリンクを共有してください。`,
    ``,
  ],
  notice_article_link: {
    one: [
      `단어 이내의 아티클 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one article link less than () words.`,
      's_preparation.notice_article_link.one',
      `单词以下的文章链接仅可共享1个。`,
      `僅可分享____個單字以內的1篇文章連結。`,
      `単語以内のアーティクル1つのリンクのみ共有可能です。`,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_article_link.two',
      `希望将课程相关内容留言给教师时, 可将内容粘贴到课程笔记上`,
      `請於課堂筆記寫下課程相關內容與任課教師分享。`,
      `レッスン内容に関して、チューターに伝えたいことをレッスンノートに入力してください。`,
      ``,
    ],
  },
  upload_your_link_to_share: {
    article: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 아티클 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the article link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.article',
      <>
        [Free Topic课程注意事项]
        <br />
        1) 建议最晚于<Span className="font-bold">课程开始前24小时</Span>提供共享的文章链接。
        <br />
        2) 请选择<Span className="font-bold">适合的</Span>内容进行共享。如存在不合理或敏感内容, 可能无法于课程中进行。
      </>,
      <>
        [Free Topic課程注意事項]
        <br />
        1) 建議<Span className="font-bold">最晚於課程開始24小時前</Span>分享文章連結。
        <br />
        2) 請分享<Span className="font-bold">適合上課時進行的內容</Span>。課堂上可能無法進行不適當或敏感的內容。
      </>,
      <>
        [Free Topicレッスンの注意事項]
        <br />
        1) <Span className="font-bold">レッスン開始24時間前</Span>までにアーティクルのリンクを共有することを推奨します。
        <br />
        2) レッスンで <Span className="font-bold">扱うのに適した内容</Span>
        を共有してください。不適合またはデリケートな内容の場合、レッスンで扱うことはできません。
      </>,
      ``,
    ],
    youtube: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 유튜브 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the Youtube link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.youtube',
      <>
        [Free Topic课程注意事项]
        <br />
        1) 建议最晚于<Span className="font-bold">课程开始前24小时</Span>提供共享的Youtube链接。
        <br />
        2) 请选择<Span className="font-bold">适合的</Span>内容进行共享。如存在不合理或敏感内容, 可能无法于课程中进行。
      </>,
      <>
        [Free Topic課程注意事項]
        <br />
        1) 建議<Span className="font-bold">最晚於課程開始24小時前</Span>分享YouTube影片連結。
        <br />
        2) 請分享<Span className="font-bold">適合上課時進行的內容</Span>。課堂上可能無法進行不適當或敏感的內容。
      </>,
      <>
        [Free Topicレッスンの注意事項]
        <br />
        1) <Span className="font-bold">レッスン開始24時間前</Span>までにYouTubeのリンクを共有することを推奨します。
        <br />
        2) レッスンで <Span className="font-bold">扱うのに適した内容</Span>
        を共有してください。不適合またはデリケートな内容の場合、レッスンで扱うことはできません。
      </>,
      ``,
    ],
  },
  btn_delete_link: [`삭제`, ``, 's_preparation.btn_delete_link', `删除`, `刪除`, `削除`, ``],
  btn_upload_link: [`링크 공유`, ``, 's_preparation.btn_upload_link', `链接共享`, `分享連結`, `リンク共有`, ``],
  youtube_link_error: {
    title: [
      `유튜브 링크 형식 오류`,
      `YouTube link error`,
      's_preparation.youtube_link_error.title',
      `Youtube链接格式错误`,
      `YouTube連結格式錯誤`,
      `YouTubeリンク形式エラー`,
      ``,
    ],
    body: [
      `입력하신 링크는 올바른 유튜브 링크 형식이 아닙니다. 링크를 확인해주세요.`,
      `The uploaded YouTube link is in wrong form. Please check it again.`,
      's_preparation.youtube_link_error.body',
      `您提供的Youtube视频链接地址不正确。请进行确认。`,
      `您輸入的連結並非正確的YouTube連結格式，請確認連結。`,
      `入力したリンクは正しいYouTubeのリンクの形式ではありません。リンクを確認してください。`,
      ``,
    ],
  },
  please_connect_directly: [
    `공유한 링크의 내용이 여기서 바로  보이지 않으면,  새 창에서 직접 접속해서 아티클을 보면서 튜터와 수업해주세요.`,
    `If the uploaded link doesn't show up, please open a new window to share it with the tutor.`,
    's_preparation.please_connect_directly',
    `共享链接的内容如果不能即时查看, 请直接连接新的窗口, 边看文章边和教师开始课程。`,
    `無法在這裡看到分享連結的內容時，請另開連結的視窗，一邊觀看文章一邊與任課教師上課。`,
    `共有したリンクの内容がここにすぐ表示されない場合は、新しいウィンドウで直接アクセスし、アーティクルを見ながらチューターとレッスンしてください。`,
    ``,
  ],
  upload_your_link: [
    `링크를 입력하거나 붙여 주세요.`,
    `Enter or paste the webpage link on Lesson Notes.`,
    's_preparation.upload_your_link',
    `请输入或复制链接`,
    `請輸入或貼上連結。`,
    `リンクを入力またはペーストしてください。`,
    ``,
  ],
  ringle_docs_is_being_generated: [
    `Ringle Docs 생성 중입니다.`,
    `Generating Ringle Docs...`,
    's_preparation.ringle_docs_is_being_generated',
    `Ringle Docs生成中`,
    `Ringle Docs生成中`,
    `Ringle Docs作成中です。`,
    `Đang tạo Ringle Docs...`,
  ],
  no_link: [
    `공유된 링크가 없습니다.`,
    `No link has been shared.`,
    's_preparation.no_link',
    `没有共享的网址.`,
    `無分享的連結。`,
    `共有されたリンクがありません。`,
    `Không có link nào được chia sẻ.`,
  ],
  due_to_technical_issues: [
    `해당 수업은 테크 이슈로 구글닥으로 진행되며, 수업권이 보상 지급 됩니다.`,
    `Due to technical issues, this lesson will be conducted with Google Docs. As an apology, we have sent you an excusal credit.`,
    's_preparation.due_to_technical_issues',
    `因系统问题，本课程将使用Google Docs进行，作为补偿将为您提供额外的课程劵.`,
    `因系統問題，本課程將使用Google Docs進行，作爲補償將爲您提供額外的課程劵.`,
    `この授業は、エラーでGoogle Docsで行われ、授業権が補償されます`,
    `Vì vấn đề kĩ thuật, buổi học này sẽ diễn ra trên Google Docs. Chúng tôi gửi bạn một thẻ học thay cho lời xin lỗi.`,
  ],
}
export const s_my_original = {
  saved_webinars: [
    `찜한 웨비나`,
    `Saved Webinars`,
    's_my_original.saved_webinars',
    `收藏的网络研讨会`,
    `我收藏的網路研討會`,
    `お気に入り登録したウェビナー`,
    `Những webinar được lưu`,
  ],
  saved_packets: [
    `찜한 교재`,
    `My Materials`,
    's_my_original.saved_packets',
    `收藏的教材`,
    `我收藏的教材`,
    `お気に入り教材`,
    `Tài liệu của tôi`,
  ],
  save_the_webinar_that_you_want_to_watch_again_: [
    <>
      다시 보고 싶은
      <br />
      웨비나를 찜해보세요.
    </>,
    <>Add your favorite webinars!</>,
    's_my_original.save_the_webinar_that_you_want_to_watch_again_',
    <>
      请收藏希望重新收看的
      <br />
      网络研讨会。
    </>,
    <>
      請收藏想再次觀看的
      <br />
      網路研討會。
    </>,
    <>
      もう一度見たい
      <br />
      ウェビナーをお気に入り登録しましょう。
    </>,
    <>Thêm buổi webinar yêu thích!</>,
  ],
  save_the_packet_that_you_want_to_read_again_: [
    <>
      다시 보고 싶은
      <br />
      교재를 찜해보세요.
    </>,
    <>Add your favorite materials!</>,
    's_my_original.save_the_packet_that_you_want_to_read_again_',
    <>
      请收藏希望重新阅读的
      <br />
      教材。
    </>,
    <>
      請收藏想再次
      <br />
      觀看的教材。
    </>,
    <>
      もう一度見たい
      <br />
      教材をお気に入り登録しましょう。
    </>,
    <>Thêm tài liệu yêu thích!</>,
  ],
  no_packets_saved_yet: [
    `찜한 교재가 없습니다.`,
    `You haven't saved any materials.`,
    's_my_original.no_packets_saved_yet',
    `没有收藏的教材。`,
    `無收藏的教材。`,
    `お気に入り登録した教材はありません。`,
    `Bạn chưa lưu tài liệu nào.`,
  ],
  no_packets: [
    `선택 가능한 교재가 없습니다.`,
    `No materials available`,
    's_my_original.no_packets',
    `没有可以选择的教材。`,
    `無可選擇的教材。`,
    `選択可能な教材はありません。`,
    `Không có tài liệu nào`,
  ],
  save_daily_briefs: [
    <>
      다시 보고 싶은
      <br /> 데일리 브리프를 찜해보세요.
    </>,
    `Add your favorite daily briefs!`,
    's_my_original.save_daily_briefs',
    <>
      请收藏希望重看的
      <br />
      Daily Brief吧。
    </>,
    <>
      請收藏想再次觀看的
      <br />
      Daily Brief。
    </>,
    <>
      もう一度見たい
      <br /> Daily Briefをお気に入り登録しましょう。
    </>,
    `Thêm daily brief yêu thích của bạn!`,
  ],
  dailybrief: [
    `찜한 데일리 브리프`,
    `Favorite Daily Briefs`,
    's_my_original.dailybrief',
    `收藏的Daily Brief`,
    `我收藏的Daily Brief`,
    `お気に入りDaily Brief`,
    `Daily Brief yêu thích`,
  ],
  favorite_webinar: [
    `찜한 웨비나`,
    `Favorite Webinars`,
    's_my_original.favorite_webinar',
    `收藏的网络研讨会`,
    `我收藏的網路研討會`,
    `お気に入りウェビナー`,
    `Webinar yêu thích`,
  ],
}
export const s_notices = {
  notice: [`공지사항`, `Updates`, 's_notices.notice', `通知事项`, `公告事項`, `お知らせ`, `Cập nhật`],
  notice_list: [
    `공지사항 목록`,
    `Updates List`,
    's_notices.notice_list',
    `通知事项目录`,
    `公告事項清單`,
    `お知らせリスト`,
    `Danh sách cập nhật`,
  ],
}
export const s_invite = {
  recommender_count: [
    `어제 추천한 링글러의 수`,
    `Ringlers who have been recommended`,
    's_invite.recommender_count',
    `昨天推荐的Ringle用户人数`,
    `昨天推薦的Ringler人數`,
    `昨日までに招待したRingler`,
    `Những học viên được giới thiệu đến Ringle`,
  ],
  recommendee_count: [
    `어제 추천으로 가입한 링글러의 수`,
    `Ringlers who signed up with recommendation`,
    's_invite.recommendee_count',
    `昨天通过推荐注册Ringle的用户人数`,
    `昨天因推薦而註冊的Ringler人數`,
    `昨日までに会員登録したRingler`,
    `Học viên Ringle đăng ký qua lời giới thiệu`,
  ],
  people: [`명`, `People`, 's_invite.people', `名`, `名`, `名`, `người`],
  doc: {
    saved_clipboard: [
      <>
        초대 링크가 복사되었습니다.
        <br />
        친구에게 바로 링글을 선물하세요!
      </>,
      `The link has been copied. Invite your friend to Ringle now!`,
      's_invite.doc.saved_clipboard',
      `邀请链接已复制。请复制粘贴至您希望的位置。`,
      `邀請連結複製成功，請於想要的地方複製貼上。`,
      `招待リンクがコピーされました。さっそくお友達に送ってみよう`,
      `Link mời của bạn đã được sao chép vào clipboard.`,
    ],
    thank_you: [
      `링글을 추천해주셔서 감사합니다.`,
      `Thank you for recommending Ringle.`,
      's_invite.doc.thank_you',
      `非常感谢您对Ringle的推荐。`,
      `感謝您推薦Ringle。`,
      `Ringleをご紹介いただきありがとうございます。`,
      `Cảm ơn vì đã giới thiệu Ringle.`,
    ],
    fail_to_post: [
      `포스트가 올라가지 않았습니다.`,
      `Failed to post.`,
      's_invite.doc.fail_to_post',
      `发布失败。`,
      `發文失敗。`,
      `招待できませんでした。`,
      `Không đăng được.`,
    ],
  },
  body_header: {
    title: [
      `친구 초대 이벤트`,
      `Referral Event`,
      's_invite.body_header.title',
      `朋友邀请活动`,
      `邀請朋友活動`,
      `友達招待イベント`,
      `Sự kiện giới thiệu`,
    ],
    subtitle: [
      `무제한 포인트 적립 & 최대 20회 수업권 혜택`,
      `Unlimited points & max 20 credits`,
      's_invite.body_header.subtitle',
      `无限积分收集&最多20次课程券优惠`,
      `無限累積點數 & 最多20次課程券優惠`,
      `無制限ポイント獲得 & 最大20回レッスンチケット特典`,
      `Không giới hạn điểm & tối đa 20 thẻ học`,
    ],
    description: [
      `아직도 링글러가 아닌 친구가 있다면 지금 바로 추천하세요!!`,
      `Recommend Ringle to your friend!!`,
      's_invite.body_header.description',
      `现在还有不是Ringle用户的朋友, 快去向他推荐吧!!`,
      `如果還有朋友不是Ringler，快去推薦吧！！`,
      `まだRinglerじゃない友達がいたら、今すぐ招待してみよう！`,
      `Giới thiệu Ringle đến bạn bè!`,
    ],
    subheader: [
      <>
        친구를 초대하고
        <br />
        최대 36만 원+α 선물 받으세요!
      </>,
      <>
        Invite your friends
        <br />
        and earn up to $310.34 & more!
      </>,
      's_invite.body_header.subheader',
      <>
        邀请朋友
        <br />
        最多可获得$310.34+α礼物!
      </>,
      <>
        邀請朋友
        <br />
        最多領取$310.34+α禮物！
      </>,
      <>
        お友達を招待すると
        <br />
        最大$310.34＋他にもプレゼントがもらえる！
      </>,
      <>
        Mời bạn bè
        <br />
        và nhận đến $310.34 & nhiều phần thưởng khác!
      </>,
    ],
  },
  section1: {
    headline: [
      <>
        더 수준 높은 영어가 필요한
        <br />
        모든 분들을 위해
      </>,
      <>
        For everyone
        <br />
        who needs a more sophisticated English
      </>,
      's_invite.section1.headline',
      <>
        为需要更高英文水平的
        <br />
        所有人
      </>,
      <>
        為了需要更高水準英語的
        <br />
        所有人
      </>,
      <>
        よりレベルの高い英語を求める
        <br />
        すべての方のために
      </>,
      <>
        Cho bất kỳ ai
        <br />
        muốn nâng cao trình độ tiếng Anh
      </>,
    ],
  },
  section2: {
    display: [
      <>
        친구에게
        <br />
        링글을 알리기만 해도!
      </>,
      <>
        Just
        <br />
        Tell your friend!
      </>,
      's_invite.section2.display',
      <>
        即使只是让
        <br />
        朋友们知道Ringle!
      </>,
      <>
        光是讓
        <br />
        朋友知道Ringle！
      </>,
      <>
        お友達に
        <br />
        Ringleを広めるだけでも！
      </>,
      <>
        Hãy thoải mái
        <br />
        chia sẻ với bạn bè!
      </>,
    ],
    title: [`스타벅스 (선착순 100명)`, `Starbucks (First 100 people)`, 's_invite.section2.title', ``, ``, ``, ``],
    body: [
      `*해쉬태그 #링글을 남겨주셔야 당첨을 확인할 수 있어요!`,
      `*Hashtag #Ringle to see if you've won!`,
      's_invite.section2.body',
      `*标记#Ringle标签才能确认中奖情况!`,
      `*使用標籤#Ringle才能確認中獎！`,
      `*ハッシュタグ #Ringle をつけると当選確率がアップします！`,
      `*Hashtag #Ringle để xem bạn có thắng không!`,
    ],
  },
  section3: {
    display: [
      <>
        친구가 링글러 되면
        <br />
        나에게 돌아오는 혜택!
      </>,
      <>
        Invite your friend to Ringle
        <br />
        and get rewards!
      </>,
      's_invite.section3.display',
      <>
        朋友成为Ringle用户后
        <br />
        回馈给本人的优惠!
      </>,
      <>
        朋友成為Ringler後，
        <br />
        我能得到的優惠！
      </>,
      <>
        お友達がRinglerになったら
        <br />
        もらえる特典！
      </>,
      <>
        Mời bạn bè
        <br />
        và nhận thưởng!
      </>,
    ],
    blue: [
      `체험만해도`,
      `That includes when your friend just takes a trial lesson`,
      's_invite.section3.blue',
      `仅仅是体验`,
      `光是體驗`,
      `体験するだけでも`,
      `Ngay cả khi bạn bè của bạn chỉ học buổi học thử`,
    ],
    pink: [
      `링글러가 되면`,
      `If you friend becomes a Ringler`,
      's_invite.section3.pink',
      `仅仅是成为Ringle用户`,
      `成為Ringler的話`,
      `Ringlerになったら`,
      `Nếu bạn của bạn trở thành học viên Ringle`,
    ],
    description_box: [
      `5만포인트(최대3명) / 3만포인트(7명) / 이후 1만포인트 🍋`,
      `$43.10 Points (First 3 people)/ $25.86 Points (Next 7 people)/ $8.62 Points (Everyone else) 🍋`,
      's_invite.section3.description_box',
      `$43.1积分(限3名) / $25.86积分(7名) / 之后$8.62积分 🍋`,
      `$43.1P（限3名）／$25.86P（7名）／之後$8.62P🍋`,
      `$43.1ポイント(最大3名様) / $25.86ポイント(7名様) / 以降$8.62ポイント 🍋`,
      `$43.10 điểm (3 người đầu tiên)/ $25.86 điểm (7 người tiếp theo)/ $8.62 điểm (cho tất cả người khác) 🍋`,
    ],
  },
  section4: {
    display: [
      <>
        링글러가 된 친구에게
        <br />
        선물하는 혜택!
      </>,
      <>
        Send gifts to your friend
        <br />
        who just became a Ringler!
      </>,
      's_invite.section4.display',
      <>
        给予成为Ringle用户的朋友,
        <br />
        优惠礼物!
      </>,
      <>
        送給成為Ringler的朋友的
        <br />
        優惠！
      </>,
      <>
        Ringlerになった友達に
        <br />
        プレゼントする特典！
      </>,
      <>
        Gửi quà tặng cho bạn bè
        <br />
        mới trở thành học viên Ringle!
      </>,
    ],
  },
  section5: {
    display: [
      <>
        친구가 링글을 오래할수록
        <br />더 커지는 혜택
      </>,
      <>
        Receive more gifts
        <br />
        if your friend stays with Ringle longer!
      </>,
      's_invite.section5.display',
      <>
        朋友使用Ringle的时间越长
        <br />
        所获得优惠就越多
      </>,
      <>
        朋友使用Ringle的時間越長，
        <br />
        優惠就越多。
      </>,
      <>
        お友達がRingleを長く利用するほど
        <br />
        もらえる特典が増えていく
      </>,
      <>
        Nhận nhiều quà hơn
        <br />
        nếu bạn của bạn học ở Ringle càng lâu!
      </>,
    ],
    count: (n) => [
      <>{n}회</>,
      <>{n} times</>,
      's_invite.section5.count',
      <>{n}次</>,
      <>{n}次</>,
      <>{n}回</>,
      <>{n} lần</>,
    ],
    package: (n) => [
      <>{n}회 패키지 이상 결제시</>,
      <>If your friend purchases packages of more than {n} lessons</>,
      's_invite.section5.package',
      <>{n}次以上礼包购买时</>,
      <>購買{n}次以上禮包時</>,
      <>{n}回以上決済時</>,
      <>Nếu bạn bè của bạn mua gói học với hơn {n} buổi học</>,
    ],
    additional_coupon: [
      `수업권 추가 증정`,
      `You will get additional credits`,
      's_invite.section5.additional_coupon',
      `追加赠送课程券`,
      `額外贈送課程券`,
      `レッスンチケット追加プレゼント`,
      `Bạn sẽ có thêm các thẻ học`,
    ],
    max_coupon: [
      `최대 20회 수업권 증정`,
      `Get max 20 additional credits`,
      's_invite.section5.max_coupon',
      `最多赠送20次的课程券`,
      `最多贈送20次課程券`,
      `最大20回分のレッスンチケットプレゼント`,
      `Nhận thêm tối đa 20 thẻ học`,
    ],
  },
  section6: {
    display: [
      <>
        추천 포인트는
        <br />
        이렇게 쓸 수 있어요!
      </>,
      <>
        You can get gifts
        <br />
        with your referral points&nbsp;
      </>,
      's_invite.section6.display',
      <>
        推荐积分
        <br />
        可以这样使用!
      </>,
      <>
        推薦點數
        <br />
        可以這樣使用！
      </>,
      <>
        招待ポイントは
        <br />
        このように使うことができます！
      </>,
      <>
        Bạn có thể nhận quà
        <br />
        với điểm giới thiệu&nbsp;
      </>,
    ],
    img_alt_1: [
      `링글 수업권 결제`,
      `Purchase Ringle lessons`,
      's_invite.section6.img_alt_1',
      `Ringle课程券付款`,
      `購買Ringle課程券`,
      `Ringleレッスンチケット決済`,
      `Thanh toán buổi học Ringle`,
    ],
    img_alt_2: [
      `스타벅스 카드로 교환`,
      `Exchange with Starbucks Gift Card`,
      's_invite.section6.img_alt_2',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_3: [
      `신세계 상품권 교환`,
      `Exchange with Shinsegae Gift Card`,
      's_invite.section6.img_alt_3',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_4: [
      `링글 수업권 교환`,
      `Exchange with Ringle lessons`,
      's_invite.section6.img_alt_4',
      `兑换为Ringle课程券`,
      `兌換Ringle課程券`,
      `Ringleレッスンチケットに交換`,
      `Đổi lấy buổi học Ringle`,
    ],
  },
  section7: {
    title: [
      <>
        지금 친구에게
        <br />
        링글 수업권을 보내주세요!
      </>,
      <>
        Send your friend
        <br />a Ringle lesson right now!
      </>,
      's_invite.section7.title',
      <>
        现在就给您的朋友
        <br />
        发送Ringle课程券吧!
      </>,
      <>
        現在發送Ringle課程券
        <br />
        給您的朋友吧！
      </>,
      <>
        今すぐ友達に
        <br />
        Ringleのレッスンチケットを贈ってみよう！
      </>,
      <>
        Gửi bạn bè của bạn
        <br />a một buổi học cùng Ringle ngay thôi!
      </>,
    ],
    subtitle: [
      `10초면 끝 ! 지금 바로 보내주세요 !`,
      `It only takes 10 seconds! Send right now!`,
      's_invite.section7.subtitle',
      `仅需10秒! 现在立即发送吧!`,
      `10秒即可！現在立即發送吧！`,
      `たった10秒！今すぐ送ってみよう`,
      `Chỉ mất 10 giây! Gửi ngay thôi!`,
    ],
  },
  banner: {
    normal_title: (name) => [
      <>{name}님 이 추천한 1:1 화상영어, 링글</>,
      <>{name} has invited you to Ringle</>,
      's_invite.banner.normal_title',
      <>{name}用户送来了特别的优惠礼物!</>,
      <>{name}送您特別優惠！</>,
      <>{name} さんから特別なプレゼントが届きました！</>,
      <>Món quà đặc biệt từ {name}</>,
    ],
    sub_title: [
      <>자기계발을 위한 영어부터 비즈니스 영어까지</>,
      <>For Small Talk, Business English, Interviews and more</>,
      's_invite.banner.sub_title',
      <>
        现在就注册会员, 马上进行确认吧 <FeatherChevronRight inline />
      </>,
      <>
        現在就註冊會員，馬上確認吧！
        <FeatherChevronRight inline />
      </>,
      <>
        今すぐ会員登録して確認してみましょう。 <FeatherChevronRight inline />
      </>,
      <>
        Đăng ký để trải nghiệm thử <FeatherChevronRight inline />
      </>,
    ],
    my_code: [`나의코드`, `My Code`, 's_invite.banner.my_code', `我的代码`, `我的代碼`, `マイコード`, `Mã của tôi`],
    share_link: [
      `초대링크 공유`,
      `Share invitation link`,
      's_invite.banner.share_link',
      `分享邀请链接`,
      `分享邀請連結`,
      `招待リンク共有`,
      `Chia sẻ link mời`,
    ],
    button: [
      <>50,000P 받고 링글 시작하기</>,
      <>Sign up & Earn $38 Points</>,
      's_invite.banner.button',
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
      <>Sign up & Earn $38 Points</>,
    ],
    button2: [
      <>100,000P 받고 링글 시작하기</>,
      <>Sign up & Earn $76 Points</>,
      's_invite.banner.button2',
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
      <>Sign up & Earn $76 Points</>,
    ],
  },
  kakao: {
    title: [
      `친구 초대 - 링글로 영어 공부하는 친구가 초대했어요!`,
      `Personalized Ringle lessons--Just for me!`,
      's_invite.kakao.title',
      `邀请朋友 - 使用Ringle学习英语的朋友邀请了您!`,
      `邀請朋友 - 使用Ringle學習英語的朋友邀請您！`,
      `友達招待- Ringleで英語を勉強している友達から招待状が届き足！`,
      `Buổi học được cá nhân hoá tại Ringle--Dành riêng cho bạn!`,
    ],
    description: [
      `지금 초대장으로 가입하시면 바로 드려요 🙂`,
      `Experience 40 minutes of intellectual conversation`,
      's_invite.kakao.description',
      `现在通过邀请函进行注册的话, 马上提供给您🙂`,
      `現在透過邀請函註冊的話，就馬上送您！🙂`,
      `この招待状から会員登録で今すぐプレゼント 🙂`,
      `Trải nghiệm 40 phút trò chuyện sâu sắc`,
    ],
  },
  footer: {
    title: [
      <>10초만에 링글로 친구를 초대해 보세요!</>,
      <>Invite your friend to Ringle in 10 seconds!</>,
      's_invite.footer.title',
      <>仅用10秒, 通过Ringle邀请您的朋友吧!</>,
      <>10秒即可，邀請朋友註冊Ringle吧！</>,
      <>たった10秒でRingleに友達招待してみよう！</>,
      <>Mời bạn bè tham gia Ringle chỉ trong 10 giây!</>,
    ],
    copy: [
      `친구에게 초대장 보내기`,
      `Send Invitation`,
      's_invite.footer.copy',
      `向朋友发送邀请函`,
      `向朋友發送邀請函`,
      `招待状を送る`,
      `Gửi lời mời`,
    ],
  },
  sign_up_after_benefit: [
    `회원가입하고 혜택받기`,
    `Sign up and get benefits`,
    's_invite.sign_up_after_benefit',
    `注册会员获得优惠`,
    `註冊會員並領取優惠`,
    `会員登録して特典を受け取る`,
    `Đăng ký và nhận nhiều ưu đãi`,
  ],
  item_list_1: [
    `링글에 대한 솔직한 수업 후기를 남겨주세요!`,
    `Tell us how you felt about the lesson!`,
    's_invite.item_list_1',
    `请留下您对Ringle课程的真实评价!`,
    `請留下您對Ringle課程的真實心得！`,
    `Ringleに対する正直なレッスンレビューを作成してください！`,
    `Hãy chia sẻ cảm nhận của bạn về buổi học nhé!`,
  ],
  item_list_2: [
    `인스타그램에서 #링글 #Ringle 태그`,
    `#Ringle on Instagram`,
    's_invite.item_list_2',
    `于Instagram使用#Ringle标签`,
    `在Instagram使用標籤#Ringle`,
    `Instagramで#リングル #Ringle をタグ付け`,
    `#Ringle trên Instagram`,
  ],
  item_list_3: [
    `추천코드링크 공유와 함께 추천할 친구 @ 소환`,
    `@Tag a friend with an invitation code`,
    's_invite.item_list_3',
    `分享推荐代码链接, @想推荐的朋友`,
    `分享推薦代碼連結並@標記想推薦的朋友`,
    `紹介コードリンクの共有と一緒に紹介した友達を@メンション`,
    `@Tag một người bạn với mã giảm giá`,
  ],
  join_ringle_and_start_to_excel: [
    `영어가 경쟁력이 되는 일대일 화상영어, 링글. 지금 시작하세요!`,
    `Join Ringle and start to excel in English!`,
    's_invite.join_ringle_and_start_to_excel',
    `提高英语竞争力的1对1线上英语，现在开始使用Ringle吧!`,
    `提高英語競爭力的1對1綫上英語，現在開始使用Ringle吧!`,
    `英語が競争力になる1on1の画像英語、Ringle。 今、始めてください`,
    `Học cùng Ringle và phát triển tiếng Anh của bạn!`,
  ],
  portal: {
    subtitle: [
      `‘똑똑한 영어’를 선물하는 방법`,
      `Share the smartest way to improve English fluency`,
      's_invite.portal.subtitle',
      `贈送‘智慧型英语’的方法`,
      `贈送「智慧型英語」的方法`,
      `「賢い英語」をプレゼントする方法`,
      `Chia sẻ bạn bè cách học tiếng Anh thông minh`,
    ],
    title1: [
      `링글을 친구에게`,
      `Invite Your Friends`,
      's_invite.portal.title1',
      `请向朋友`,
      `請向朋友`,
      `Ringleをお友達に`,
      `Mời bạn bè`,
    ],
    title2: [
      `소개해 주세요!`,
      `to Ringle`,
      's_invite.portal.title2',
      `介绍Ringle`,
      `介紹Ringle`,
      `紹介してください。`,
      `học Ringle`,
    ],
    benefit1: {
      title1: [
        `초대장을 받은 친구는`,
        `When your friend signs up with your invite code`,
        's_invite.portal.benefit1.title1',
        `收到邀请函`,
        `收到邀請函的朋友`,
        `招待状を受け取り`,
        `Khi bạn của bạn đăng ký bằng mã mời của bạn`,
      ],
      title2: (amount) => [
        <>회원가입하면 {amount}를 받아요</>,
        <>they’ll receive {amount} in points</>,
        's_invite.portal.benefit1.title2',
        <>注册会员时可领取${amount}</>,
        <>註冊會員時，可領取${amount}。</>,
        <>会員登録すると${amount}もらえます。</>,
        <>họ sẽ nhận được {amount} điểm</>,
      ],
      desc: (amount) => [
        <>({amount}만원 이상 수업권 결제 시, 즉시 할인 가능)</>,
        <>(Can be applied towards purchases over {amount})</>,
        's_invite.portal.benefit1.desc',
        <>购买{amount}以上的课程劵时可立即获得优惠</>,
        <>（購買{amount}以上的課程券時，可立即享折扣。）</>,
        <>(購入額{amount}以上の決済時に適用されます)</>,
        <>(Có thể áp dụng cho lần thanh toán trên {amount})</>,
      ],
      caption: [
        `* 회원 가입 기준: ‘본인 인증’ 완료`,
        `Must complete identity verification to sign up`,
        's_invite.portal.benefit1.caption',
        `*注册会员标准：完成‘本人认证’`,
        `*註冊會員標準：完成「身分驗證」`,
        `*会員登録には本人認証が必要です`,
        `*Phải hoàn thành xác minh danh tính để đăng ký`,
      ],
    },
    benefit2: {
      title1: [
        `친구가 첫 수업을 완료하면`,
        `When your friend completes the first paid lesson`,
        's_invite.portal.benefit2.title1',
        `朋友完成首次课程时`,
        `朋友完成首次課程時，`,
        `招待した友達が最初のレッスンを完了すると`,
        `Khi bạn của bạn hoàn thành buổi học đã thanh toán đầu tiên`,
      ],
      title2: (amount) => [
        <>나도 {amount}를 받아요</>,
        <>you'll earn {amount} in points</>,
        's_invite.portal.benefit2.title2',
        <>您也可领取${amount}</>,
        <>您也可領取${amount}。</>,
        <>あなたにも${amount}付与されます。</>,
        <>bạn nhận được {amount} điểm</>,
      ],
      desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Can be exchanged to Ringle credits)`,
        's_invite.portal.benefit2.desc',
        `（可兑换新世界商品劵，星巴克饮料劵，Ringle课程劵）`,
        `（可兌換新世界商品券、星巴克飲料券、Ringle課程券）`,
        `(Ringleレッスンチケットに交換可)`,
        `(Có thể đổi lấy thẻ học Ringle)`,
      ],
      caption: (amount) => [
        <>* 첫 수업 기준: ‘{amount}만원 이상’ 유료 수업 완료</>,
        <>First purchased lesson must be from a package over {amount}</>,
        's_invite.portal.benefit2.caption',
        <>首次课程标准：完成‘{amount}以上’付费课程</>,
        <>*首次課程標準：完成「{amount}以上」付費課程</>,
        <>*初回レッスンとは、{amount}以上の購入されたパックを指します。</>,
        <>*Gói học thanh toán đầu tiên phải trị giá hơn {amount}</>,
      ],
    },
    guide: {
      title: [
        `꼭 읽어주세요!`,
        `Please read the terms!`,
        's_invite.portal.guide.title',
        `必读！`,
        `必讀！`,
        `必ずお読みください。`,
        `Vui lòng đọc điều khoản!`,
      ],
      list_1: [
        `신규 회원 가입한 대상에게만 포인트가 제공됩니다.`,
        `Points will be provided only for new Ringle students.`,
        's_invite.portal.guide.list_1',
        `积分只提供给新注册的会员`,
        `僅提供新註冊會員的學生點數。`,
        `新規会員登録した方のみを対象にポイントが付与されます。`,
        `Điểm chỉ dành cho học viên mới.`,
      ],
      list_2: (amount, normal_price) => [
        <>
          포인트는 초대받은 친구에게는 가입 즉시 지급, 나에게는 친구가 실결제가 {amount}만원 이상 수업권 구매 후, 첫
          수업을 완료하는 시점에 지급됩니다.
        </>,
        <>
          Your friend will earn {amount} points right after they sign up. You will earn {amount} points when your friend
          completes the first lesson of the credits priced over {normal_price}.
        </>,
        's_invite.portal.guide.list_2',
        <>
          当受邀的朋友注册时，我们会立刻发送积分给朋友。当朋友实际付款购买{normal_price}
          以上的课程并完成首次课程时，我们将发送积分给您。
        </>,
        <>
          當受邀的朋友註冊時，我們將立即發送點數給朋友。當朋友實際付款購買{normal_price}
          以上的課程券並完成首次課程時，我們將發送點數給您。
        </>,
        <>
          招待された友達は会員登録するとその場で{amount}ポイントが付与されます。あなた(招待者)は、友達が購入額
          {normal_price}以上のレッスンチケットを購入し初めてのレッスンを完了した時点で{amount}ポイントが付与されます。
        </>,
        <>
          Bạn của bạn sẽ nhận được {amount} điểm ngay khi đăng ký. Bạn sẽ nhận được {amount} điểm khi bạn của bạn hoàn
          thành buổi học đầu tiên trong gói học trị giá hơn {normal_price}.
        </>,
      ],
      list_3: (amount, benefit) => [
        <>
          친구초대 혜택은 매 월 1명까지 {amount}, 이후 {benefit}이 제공됩니다
        </>,
        <>
          The {amount} referral points will be provided once a month. Then, earn {benefit} for each additional friend.
        </>,
        's_invite.portal.guide.list_3',
        <>
          每月邀请1名朋友时提供{amount}，之后提供{benefit}。
        </>,
        <>
          每月邀請1名朋友時提供{amount}，之後提供{benefit}。
        </>,
        <>
          友達招待特典の{amount}ポイントは毎月1人分までとし、以降{benefit}ポイントが付与されます
        </>,
        <>
          {amount} điểm giới thiệu sẽ được nhận một lần mỗi tháng. Mỗi người bạn mới bạn sẽ nhận thêm {benefit} điểm.
        </>,
      ],
      list_4: [
        `제공받은 친구초대 포인트는 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged to Ringle credits.`,
        's_invite.portal.guide.list_4',
        `领取的邀请朋友积分可兑换Ringle课程劵。`,
        `可使用領取的邀請朋友點數兌換Ringle課程券。`,
        `友達招待ポイントはRingleレッスンチケットに交換できます。`,
        `Điểm giới thiệu có thể đổi lấy thẻ học Ringle.`,
      ],
      list_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Inviting duplicate accounts may result in no points or points cancellation.`,
        's_invite.portal.guide.list_5',
        `若发现账户重复邀请等非正当参与行为时，将不会发送积分或撤回积分。`,
        `判斷帳戶重複邀請等異常參與時，將可能不發送點數或撤回。`,
        `同一人物の重複招待など不適切な会員登録が判明した場合、ポイントの付与対象外となる又は没収されることがあります。`,
        `Những bất thường như mở tài khoản trùng lặp sẽ không nhận dược điểm hoặc bị hủy điểm.`,
      ],
    },
    floating: {
      title: (N) => [
        <>{N}월 친구초대 최대 혜택</>,
        <>{N} Invite & Earn Offer</>,
        's_invite.portal.floating.title',
        <>{N}月邀请朋友最高优惠</>,
        <>{N}月邀請朋友最高優惠</>,
        <>{N}月の友達招待最大特典</>,
        <>Tháng {N} Mời & nhận thưởng</>,
      ],
      btn1: [
        `초대장 보내기`,
        `Invite Friends`,
        's_invite.portal.floating.btn1',
        `发送邀请函`,
        `發送邀請函`,
        `招待状を送る`,
        `Mời bạn bè`,
      ],
      btn2: [
        `링크 복사`,
        `Copy Link`,
        's_invite.portal.floating.btn2',
        `复制链接`,
        `複製連結`,
        `リンクをコピー`,
        `Sao chép Link`,
      ],
    },
    event: {
      subtitle: [
        `링글 친구 초대 이벤트`,
        `Ringle Referral Event`,
        's_invite.portal.event.subtitle',
        `Ringle Referral Event`,
        `Ringle Referral Event`,
        `Ringle Referral Event`,
        `Ringle Referral Event`,
      ],
      title: [
        <>
          지금, <span className="font-bold underline underline-offset-[10px]">링글 추천하기</span>
          <br />
          가장 좋은 타이밍!
        </>,
        <>
          Now is the best time
          <br />
          to <span className="font-bold underline underline-offset-[10px]">invite your friends!</span>
        </>,
        's_invite.portal.event.title',
        <>
          现在立即
          <br />
          <span className="font-bold underline underline-offset-[10px]">向好友推荐 Ringle！</span>
        </>,
        <>
          現在馬上
          <br />
          <span className="font-bold underline underline-offset-[10px]">向好友推薦 Ringle ！</span>
        </>,
        <>
          今こそリ<span className="font-bold underline underline-offset-[10px]">ンガルを</span>
          <br />
          オススメする
        </>,
        <>
          Đã đến lúc
          <br />
          <span className="font-bold underline underline-offset-[10px]">giới thiệu Ringle!</span>
        </>,
      ],
      desc: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
          <br />
          <span className="underline text-h4 font-bold underline-offset-[6px]">오직 3주간의 특별한 기회</span>를 놓치지
          마세요!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc',
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
      ],
      date: [
        `22.08.23 (화) - 09.08 (목)`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        's_invite.portal.event.date',
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
      ],
      desc2: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
        </>,
        <>
          Get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc2',
        <>
          邀请人和被邀请好友
          <br />
          Ringle 好友推荐活动
        </>,
        <>
          邀請人和被邀請好友
          <br />
          Ringle 好友推薦活動
        </>,
        <>
          招待した人もされた人もみんな得する
          <br />
          リンガルフレンド招待イベント
        </>,
        <>
          Event dành
          <br />
          cho cả bạn và bạn bè
        </>,
      ],
      section_subtitle_1: [
        `더 많이 알릴수록`,
        `The More You Reach Out`,
        's_invite.portal.event.section_subtitle_1',
        `宣传得越多`,
        `分享越多`,
        `広めれば広めるほど`,
        `Càng giới thiệu rộng rãi`,
      ],
      section_title_1: [
        `쌓이는 나의 혜택`,
        `The More Rewards
You Get!`,
        's_invite.portal.event.section_title_1',
        `优惠就越多`,
        `優惠就越多`,
        `貯まる特典`,
        `Ưu đãi càng nhiều`,
      ],
      section_list_1_subtitle: [
        <>
          내 초대 코드로 가입한 친구가 <span className="text-purple-500">첫 수업을 완료</span> 하면
        </>,
        <>
          When your friend <span className="text-purple-500">completes the first paid lesson*</span>
        </>,
        's_invite.portal.event.section_list_1_subtitle',
        <>
          只要通过邀请代码加入会员的好友<span className="text-purple-500">完成第一节课，</span>
        </>,
        <>
          只要透過邀請代碼加入會員的好友<span className="text-purple-500">完成第一堂課，</span>
        </>,
        <>
          招待コードで入会したフレンドが<span className="text-purple-500">初回レッスンを完了</span>すると
        </>,
        <>
          Khi người học do bạn giới thiệu <span className="text-purple-500">hoàn thành buổi học đầu tiên</span>
        </>,
      ],
      section_list_1_title: [
        `5만 링글 포인트를 드려요.`,
        `You get $38p referral points`,
        's_invite.portal.event.section_list_1_title',
        `邀请人即可获取 5 万 Ringle 积分。`,
        `邀請人即可獲得 5 萬 Ringle 積分。`,
        `5万リンガルポイントを贈呈します。`,
        `bạn sẽ nhận được 50,000 Ringle Point.`,
      ],
      section_list_1_desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Exchange for SSG/Starbucks or Ringle credits)`,
        's_invite.portal.event.section_list_1_desc',
        `（可以换取 SSG 商品券、星巴克商品券、Ringle 课程券）`,
        `（可以兌換 SSG 商品券、星巴克商品券、Ringle 課程券）`,
        `（SSG、スターバックス商品券、リンガルレッスンチケットへ交換可能）`,
        `(Có thể hoán đổi thành phiếu mua hàng SSG, Starbucks, thẻ học Ringle)`,
      ],
      section_desc_1: [
        `* 첫 수업 완료 기준 : 30만원 이상 수업권 구매 후 수업 완료`,
        `* First lesson completed from a package worth $231+`,
        's_invite.portal.event.section_desc_1',
        `* 第一节课完成标准：购买 30 万韩元以上课程券后并完成课程。`,
        `* 第一堂課完成標準：購買 30 萬韓元以上課程券後並完成課程。`,
        `* 初回レッスン完了基準：30万ウォン以上のレッスンチケットを購入後、レッスンを完了`,
        `*Tiêu chuẩn hoàn thành buổi học đầu tiên: Đã mua thẻ học trên 300,000 won và hoàn thành buổi học`,
      ],
      section_subtitle_2: [
        `내 친구에게도`,
        `Give Your Friends`,
        's_invite.portal.event.section_subtitle_2',
        `给好友`,
        `給好友`,
        `フレンドにも`,
        `Ưu đãi cần thiết nhất`,
      ],
      section_title_2: [
        `가장 필요한 혜택`,
        `What They Need
the Most`,
        's_invite.portal.event.section_title_2',
        `最需要的优惠`,
        `最需要的優惠`,
        `最も必要な特典`,
        `cho cả bạn của bạn`,
      ],
      section_list_2_subtitle: [
        `초대 코드로 회원 가입하는 즉시`,
        `When your friends sign up`,
        's_invite.portal.event.section_list_2_subtitle',
        `通过邀请代码加入会员后即可`,
        `通過邀請代碼加入會員後即可`,
        `招待コードで入会すると即`,
        `Khi đăng ký thành viên bằng mã giới thiệu`,
      ],
      section_list_2_title: [
        `5만 링글 포인트를 받아요.`,
        `They get $38p referral points`,
        's_invite.portal.event.section_list_2_title',
        `获取 5 万 Ringle 积分。`,
        `獲取 5 萬 Ringle 積分。`,
        `5万リンガルポイントを受け取れます。`,
        `sẽ được cấp ngay 50,000 Ringle Point.`,
      ],
      section_desc_2: [
        `* 30만원 이상 수업권 구매 시 사용 가능`,
        `* Can be used toward packages worth $231+`,
        's_invite.portal.event.section_desc_2',
        `* 购买 30 万韩元以上课程券时可以使用。`,
        `* 購買 30 萬韓元以上課程券時可以使用。`,
        `* 30万ウォン以上のレッスンチケットを購入時使用可能`,
        `* Có thể sử dụng khi mua thẻ học trên 300,000 won.`,
      ],
      terms_title: [
        `유의 사항`,
        `Terms and Conditions`,
        's_invite.portal.event.terms_title',
        `注意事项`,
        `注意事項`,
        `注意事項`,
        `Lưu ý`,
      ],
      terms_subtitle: [
        `[포인트 지급 및 사용 안내]`,
        `[Issuance and use of points]`,
        's_invite.portal.event.terms_subtitle',
        `【积分支付和使用指南】`,
        `【獲得積分及使用指南】`,
        `[ポイント支給および使用上のご案内]`,
        `[Hướng dẫn cấp và sử dụng Point]`,
      ],
      terms_1: [
        `초대받은 친구에게는 가입 즉시 포인트가 지급됩니다.`,
        `Your friend will earn referral points right after they sign up.`,
        's_invite.portal.event.terms_1',
        `被邀请好友加入会员后即可获取积分。`,
        `被邀請好友加入會員後即可獲得積分。`,
        `招待を受けたフレンドには入会後、即時ポイントが支給されます。`,
        `Người được giới thiệu sẽ nhận được Point ngay khi đăng ký thành viên.`,
      ],
      terms_2: [
        `나에게는 친구가 실결제가 30만원 이상 수업권 구매 후, 첫 수업을 완료하는 시점에 지급됩니다.`,
        `You will earn referral points when your friend completes the first lesson after purchasing packages worth $230 or more excluding discounts or points.`,
        's_invite.portal.event.terms_2',
        `被邀请人购买 30 万韩元以上课程券，并完成第一节课后，邀请人即可获取积分。`,
        `被邀請人購買 30 萬韓元以上課程券，並完成第一堂課後，邀請人即可獲得積分。`,
        `招待者はフレンドが30万ウォン以上のレッスンチケットを購入、決済完了後、初回レッスンを完了した時点でポイントが支給されます。`,
        `Đối với người giới thiệu, Point sẽ được cấp sau khi người được giới thiệu mua thẻ học trên 300,000 won và hoàn thành buổi học.`,
      ],
      terms_3: [
        `제공받은 친구 초대 포인트는 SSG, 스타벅스 상품권, 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged for SSG or Starbucks gift cards or Ringle credits.`,
        's_invite.portal.event.terms_3',
        `获取的好友推荐活动积分可以换取 SSG 商品券、星巴克商品券、Ringle 课程券。`,
        `獲得的好友推薦活動積分可以換取 SSG 商品券、星巴克商品券、Ringle 課程券。`,
        `受け取ったフレンド招待ポイントはSSG、スターバックス商品券、リンガルレッスンチケットへ交換可能です。`,
        `Point nhận được khi giới thiệu người dùng có thể được hoán đổi thành phiếu mua hàng SSG, Starbuck, thẻ học Ringle.`,
      ],
      terms_4: [
        `친구 초대 혜택은 매 월 1명까지 ₩50,000, 이후 ₩10,000이 제공됩니다.`,
        `You can earn $38p referral points once a month when a friend signs up with your referral code and $8p referral points for each additional friend who signs up. `,
        's_invite.portal.event.terms_4',
        `好友推荐活动优惠每月第 1 人为5万积分，之后为1万积分。`,
        `好友推薦活動優惠每月第 1 人爲 5 萬積分，之後爲 1 萬積分。`,
        `フレンド招待特典は毎月1名まで5万ウォン分、以降1万ウォン分が提供されます。`,
        `Ưu đãi giới thiệu mỗi tháng ₩50,000 cho người đầu tiên, ₩10,000 từ người thứ hai trở đi.`,
      ],
      terms_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Invalid participation, such as inviting duplicate accounts, may result in no points or points cancellation.`,
        's_invite.portal.event.terms_5',
        `如果被判定为非正常参与，如使用本人账号重复邀请等，积分有可能中断支付或被收回。`,
        `如果被判定爲非正常參與，如使用本人賬號重複邀請等，可能無法獲得積分或被收回。`,
        `本人アカウントから重複招待などの異常な参加と判断した場合は、ポイントの不支給や回収をすることがあります。`,
        `Trường hợp phát hiện sử dụng nhiều tài khoản để dùng mã mời của bản thân, Point được cấp có thể bị thu hồi.`,
      ],
      terms_6: [
        `지급된 포인트의 유효기간은 30일입니다.`,
        `Points issued to your account for this event are valid for 30 days after the issue date.`,
        's_invite.portal.event.terms_6',
        `获取的积分有效期为 30 天。`,
        `獲得的積分的有效期限爲 30 天。`,
        `支給されたポイントの有効期間は30日間です。`,
        `Point được cấp có thời hạn sử dụng trong vòng 30 ngày.`,
      ],
    },
    dashboard: {
      friend: [
        `내 초대 코드로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend',
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
      ],
      point: [
        `누적된 친구 초대 포인트`,
        `Referral points earned`,
        's_invite.portal.dashboard.point',
        `邀请朋友积分`,
        `邀請朋友點數`,
        `友達紹介ポイント`,
        `Điểm giới thiệu`,
      ],
      friend_count: (count) => [
        <>{count}명</>,
        <>{count}</>,
        's_invite.portal.dashboard.friend_count',
        <>{count}</>,
        <>{count}</>,
        <>{count}</>,
        <>{count}</>,
      ],
      point_count: (point) => [
        <>{point}</>,
        <>{point}</>,
        's_invite.portal.dashboard.point_count',
        <>{point}</>,
        <>{point}</>,
        <>{point}</>,
        <>{point}</>,
      ],
      friend_promo: [
        `이벤트 기간 중 초대로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend_promo',
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
        `Friends who joined Ringle`,
      ],
      point_promo: [
        `이벤트 기간 중 받은 친구 초대 포인트`,
        `Referral points earned`,
        's_invite.portal.dashboard.point_promo',
        `邀请朋友积分`,
        `邀請朋友點數`,
        `友達紹介ポイント`,
        `Điểm giới thiệu`,
      ],
    },
    referral: {
      code: (referralCode) => [
        <>내 초대 코드 : {referralCode}</>,
        <>My referral code : {referralCode}</>,
        's_invite.portal.referral.code',
        <>我的推荐代码 : {referralCode}</>,
        <>我的推薦代碼 : {referralCode}</>,
        <>マイ紹介コード : {referralCode}</>,
        <>Mã giới thiệu của tôi : {referralCode}</>,
      ],
    },
    my_benefit: [
      `나의 혜택 보기`,
      `Rewards for You`,
      's_invite.portal.my_benefit',
      `Rewards for You`,
      `Rewards for You`,
      `Rewards for You`,
      `Rewards for You`,
    ],
    friend_benefit: [
      `친구 혜택 보기`,
      `Rewards for Friends`,
      's_invite.portal.friend_benefit',
      `Rewards for Friends`,
      `Rewards for Friends`,
      `Rewards for Friends`,
      `Rewards for Friends`,
    ],
    btn: {
      kakao_short: [
        `카카오톡 공유하기`,
        `Share via KakaoTalk`,
        's_invite.portal.btn.kakao_short',
        `分享至 Kakao Talk`,
        `分享至 Kakao Talk`,
        `カカオトークで共有`,
        `Chia sẻ qua KakaoTalk`,
      ],
      kakao_long: [
        `카카오톡으로 초대장 공유하기`,
        `Share invite via KakaoTalk`,
        's_invite.portal.btn.kakao_long',
        `用 Kakao Talk 分享邀请函`,
        `用 Kakao Talk 分享邀請函`,
        `カカオトークで招待状を共有`,
        `Chia sẻ thư mời qua KakaoTalk`,
      ],
      link_short: [
        `초대장 공유하기`,
        `Copy link`,
        's_invite.portal.btn.link_short',
        `分享邀请函`,
        `分享邀請函`,
        `招待状を共有`,
        `Chia sẻ thư mời`,
      ],
      link_long: [
        `친구 초대장 링크 복사하기`,
        `Copy invite link`,
        's_invite.portal.btn.link_long',
        `复制好友邀请链接`,
        `複製好友邀請連結`,
        `フレンドの招待状リンクをコピー`,
        `Sao chép thư mời bạn bè`,
      ],
      kakao_long_promo: [
        `카카오톡으로 초대장 공유하기`,
        `Send invitation via KakaoTalk`,
        's_invite.portal.btn.kakao_long_promo',
        `用 Kakao Talk 分享邀请函`,
        `用 Kakao Talk 分享邀請函`,
        `カカオトークで招待状を共有`,
        `Chia sẻ thư mời qua KakaoTalk`,
      ],
      link_long_promo: [
        `초대장 링크 복사하기`,
        `Copy invite link`,
        's_invite.portal.btn.link_long_promo',
        `复制好友邀请链接`,
        `複製好友邀請連結`,
        `フレンドの招待状リンクをコピー`,
        `Sao chép thư mời bạn bè`,
      ],
    },
    share: {
      desc: [
        <>
          공유하기/복사하기를 클릭하면
          <br />내 초대 코드가 공유돼요!
        </>,
        <>
          Simply click the buttons below
          <br />
          to share your referral code!
        </>,
        's_invite.portal.share.desc',
        `点击分享/复制按钮即可分享邀请代码！`,
        `點擊分享/複製按鈕即可分享邀請代碼！`,
        <>
          共有/コピーをクリックで
          <br />
          招待コードが共有されます！
        </>,
        <>
          Khi nhấp vào Chia sẻ/Sao chép
          <br />
          Mã giới thiệu sẽ được chia sẻ!
        </>,
      ],
    },
  },
}
export const s_lesson = {
  example: [`예문`, `Example`, 's_lesson.example', `例句`, `例句`, `例文`, `Ví dụ`],
  does_not_provide_expressions: [
    `해당 교재는 핵심 표현이 제공되지 않습니다.`,
    `This lesson material does not have expressions.`,
    's_lesson.does_not_provide_expressions',
    `该教材不提供核心表达。`,
    `該教材未提供核心用法。`,
    `この教材はキーフレーズを提供していません。`,
    `Tài liệu này không có các mẫu câu.`,
  ],
  google_translator: [
    `구글 번역기`,
    `Google Translate`,
    's_lesson.google_translator',
    `谷歌翻译器`,
    `Google翻譯機`,
    `Google翻訳`,
    `Google Dịch`,
  ],
  translate: [`번역하기`, `Translate`, 's_lesson.translate', `翻译`, `翻譯`, `翻訳する`, `Dịch`],
}
export const s_evaluation = {
  thank_you: [
    `평가가 완료되었습니다. 감사합니다.`,
    `Your evaluation has been completed. Thank you.`,
    's_evaluation.thank_you',
    `评价已完成。非常感谢。`,
    `已完成評論，謝謝。`,
    `評価が完了しました。ありがとうございました。`,
    `Bạn đã hoàn thành đánh giá. Cảm ơn bạn.`,
  ],
  help: [`지원 요청`, `Help`, 's_evaluation.help', `请求支持`, `要求支援`, `サポートリクエスト`, `Hỗ trợ`],
}
export const s_review = {
  lesson_review: [
    `수업 후기`,
    `Lesson Reviews`,
    's_review.lesson_review',
    `课程评价`,
    `課程心得`,
    `レッスンのレビュー`,
    `Đánh giá buổi học`,
  ],
  customer_review: [
    `고객 후기`,
    `Testimonials`,
    's_review.customer_review',
    `用户评价`,
    `學生心得`,
    `お客様のレビュー`,
    `Chia sẻ của khách hàng`,
  ],
  so_far_n_reviews_written: (n) => [
    <>
      현재까지
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      개의 수업 후기가 작성되었습니다.
    </>,
    <>
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      reviews
    </>,
    's_review.so_far_n_reviews_written',
    <>
      当前为止, 已完成
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      个课程的评价。
    </>,
    <>
      至目前為止已填寫
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      個課程心得。
    </>,
    <>
      現在まで
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      件のレッスンレビューが作成されました。
    </>,
    <>
      <span className="ml-2 text-purple-500 font-weight-bold">{n}</span>
      đánh giá
    </>,
  ],
  view_all: [`전체보기`, `View all`, 's_review.view_all', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  company_using_english: [
    `영어 쓰는 회사`,
    `Global companies`,
    's_review.company_using_english',
    `使用英语的公司`,
    `使用英語的公司`,
    `英語を使う会社`,
    `Công ty toàn cầu`,
  ],
  presentation_report_paper: [
    `발표/보고/논문`,
    `Presentation/Report/Paper`,
    's_review.presentation_report_paper',
    `发表/报告/论文`,
    `發表／報告／論文`,
    `発表/報告/論文`,
    `Thuyết trình/Báo cáo/Nghiên cứu`,
  ],
  interview_essay: [
    `인터뷰/에세이`,
    `Interview/Essay`,
    's_review.interview_essay',
    `面试/短文`,
    `面試／申論`,
    `面接/エッセイ`,
    `Phỏng vấn/Bài luận`,
  ],
  study_abroad_immigrant: [
    `유학/해외거주`,
    `Study Abroad/Immigration`,
    's_review.study_abroad_immigrant',
    `留学/海外居住`,
    `留學／海外居住`,
    `留学/海外居住`,
    `Du học/Định cư`,
  ],
  tutors_who_fit_me: [
    `핏이 맞는 튜터`,
    `Tutors who fit me`,
    's_review.tutors_who_fit_me',
    `适合自身的教师`,
    `適合自己的任課教師`,
    `よく合うチューター`,
    `Gia sư phù hợp với tôi`,
  ],
  free_schedule: [
    `자유로운 스케쥴`,
    `Flexible schedule`,
    's_review.free_schedule',
    `自由随心的时间表`,
    `自由自在的日程`,
    `自由なスケジュール`,
    `Thời khoá biểu linh hoạt`,
  ],
  overcome_fear: [
    `두려움 극복`,
    `Overcome fear`,
    's_review.overcome_fear',
    `克服内心的恐惧`,
    `克服恐懼`,
    `恐怖克服`,
    `Vượt qua nỗi sợ`,
  ],
  most_frequency: [
    `내가 사용한 단어`,
    `View your words`,
    's_review.most_frequency',
    `我使用的单词`,
    `我使用的單字`,
    `自分が使用した単語`,
    `Xem các từ của bạn`,
  ],
  filler_word_desc: [
    `필러워드(문장 사이를 채워 주는 영어 추임새, ex)Hmm~, I mean~)`,
    `Filler words (words used to mark a pause or hesitation, ex. Hmm, like)`,
    's_review.filler_word_desc',
    `填充词(填写句子中间的英语助词, ex)Hmm~, I mean~)`,
    `填補詞（句子中間的語助詞，ex) Hmm~, I mean~）`,
    `つなぎ言葉(Filler Word)会話の間を埋める言葉 例)Hmm や I meanなど)`,
    `Filler words (những từ dùng cho khoảng ngừng khi cần suy nghĩ để tìm ý, như Hmm, like)`,
  ],
  see_recommended_synonyms_list: [
    `추천 동의어 리스트`,
    `View suggested synonyms`,
    's_review.see_recommended_synonyms_list',
    `推荐的同义词列表`,
    `推薦的同義詞清單`,
    `おすすめ同義語リスト`,
    `Xem các từ đồng nghĩa được đề xuất`,
  ],
  recorded_script: [
    <>
      수업 녹음 및 스크립트는 Zoom 기능을 활용하고 있습니다.
      <br />
      유의사항
      <br /> 동시에 말하는 경우 스크립트가 정확하지 않을 수 있습니다.
      <br /> 원어민 관점에서 영어 발음이 분석되어 일부 스크립트가 정확하지 않을 수 있습니다.
    </>,
    <>
      Ringle provides lesson recordings and script through Zoom's features.
      <br />
      Notice
      <br /> When the student and the tutor speak at the same time, the script may be inaccurate.
      <br />- Some parts of the script may not be accurate, as the conversation is processed based on native speakers
      pronunciation.
    </>,
    's_review.recorded_script',
    <>
      使用zoom记录并生成课堂录音和文本。
      <br />
      注意事项
      <br /> 教师和本人同时说话时, 有可能发生文本不准确的情况。
      <br /> 因以母语使用者为基准分析英语发音, 部分文本可能出现误差。
    </>,
    <>
      使用zoom記錄並生成課堂錄音和文本。
      <br />
      注意事項
      <br />- 教師和本人同時說話時，有可能發生文本不準確的情況。
      <br />- 因以母語人士為基準分析英語發音，部分文本可能有誤。
    </>,
    <>
      レッスンの録音及びスクリプトはZoomの機能を使用しています。
      <br />
      注意事項
      <br />- 同時に話す場合、スクリプトが的確でない可能性があります。
      <br />- ネイティブの観点から英語の発音が分析されるため、一部のスクリプトが正確では ないことがあります。
    </>,
    <>
      Ringle cung cấp ghi âm và bản ghi buổi học thông qua tính năng của Zoom.
      <br />
      Lưu ý
      <br />- Nếu gia sư và học viên nói cùng một lúc, bản ghi có thể không chính xác.
      <br />- Một số phần của bản ghi có thể không chính xác vì hội thoại được xử lý dựa trên cách phát âm của người bản
      xứ.
    </>,
  ],
  suggestions: [
    <>
      Filler Word는 like, um과 같이 의미 없이 문장에서 사용되는 단어들을 말합니다.
      <br />
      주로 사용한 단어의 동의어를 확인하고, 다음 수업에서 활용해보세요.
    </>,
    <>
      In this part, you can view the analysis of your speech. You can check which vocabularies are most frequently used
      by native speakers, see synonyms, and spot filler words you've used. Filler words refer to words spoken in
      conversation without meaning, in order to signal a pause or hesitation.
      <br />
      Also, check out synonyms of your most frequently used words, and use them in your next lesson.
      <br />
      The percentage of filler word shows the probability of you using filler words more than once, for every sentence
      you speak. Appropriate rate for this category is about 30%.
    </>,
    's_review.suggestions',
    <>
      Filler Word是指诸如like, um等于文章中不具备任何意义的词汇。
      <br />
      请对主要使用的单词同义词进行确认, 并将其运用于之后的课程中。
    </>,
    <>
      Filler Word意指句子中所使用如like、um等無意義的單字。
      <br />
      請確認主要使用的單字同義詞，並運用於下一次課程。
    </>,
    <>
      つなぎ言葉(Filler Word)は「like」「um」のような会話の間を埋める用途の意味を持たない言葉のことです。
      <br />
      頻繫に使ったつなぎ言葉の同義語を確認し、次のレッスンで活用してみましょう。
    </>,
    <>
      Trong phần này, bạn có thể xem phân tích bài nói. Bạn có thể kiểm tra từ vựng nào được dùng nhiều bởi người bản
      xứ, xem từ đồng nghĩa, và những filler words bạn đã dùng. Filler words là những từ sử dụng khi nói nhưng không có
      nghĩa gì hết. Nó thường dùng để thể hiện một khoảng dừng hoặc ngập ngừng.
      <br />
      Đừng quên xem từ những đồng nghĩa thay thế cho từ bạn thường dùng và áp dụng những từ đồng nghĩa này trong buổi
      học sau.
      <br />
      Phần trăm filler word thể hiện xác suất bạn sử dụng những từ này nhiều hơn một lần trong mỗi câu nói. Tỷ lệ phù
      hợp là khoảng 30%.
    </>,
  ],
  feedback: [
    `튜터가 남긴 나의 영어 실력에 대한 평가 점수, 피드백 및 교정결과를 확인할 수 있습니다. 평가 점수를 바탕으로 IELTS, TOEIC SPEAKING, TOEFL 예상 점수도 확인할 수 있습니다.`,
    `You can view your tutor's evaluation, feedback, and corrections. You can also get expected scores for standardized English exams, such as IELTS, TOEIC SPEAKING, and TOEFL.`,
    's_review.feedback',
    `可确认教师对本人英语实力的评价分数, 反馈及校正结果。同时以评价分数为基础, 可对IELTS, TOEIC SPEAKING, TOEFL等考试项目进行分数预测。`,
    `可確認任課教師對您的英語實力留下的評分、意見回饋及糾正結果。您可以評分為基礎，確認IELTS、TOEIC SPEAKING、TOEFL的預測分數。`,
    `チューターが作成した英語力に対する評価点数、フィードバックおよび校正結果を確認することができます。評価点数にもとづき、IELTS、TOEIC SPEAKING、TOEFLの予想点数も確認することができます。`,
    `Bạn có thể xem đánh giá, nhận xét và sửa lỗi của gia sư. Bạn sẽ thấy điểm của bạn hiện giờ so sánh với các kỳ thi năng lực tiếng Anh như IELTS, TOEIC SPEAKING, và TOEFL.`,
  ],
  summary_graph: [
    `수업 시간 동안 사용한 단어의 종류, 분당 말하기 속도를 확인할 수 있습니다. 튜터와 비교하여, 원어민 대비 나의 실력이 어느 정도인지 체크해보세요.`,
    `You can view types of vocabularies used in your lessons and your speech pace. Compare these information with native speakers' and find out how you compare.`,
    's_review.summary_graph',
    `您可以对课程中使用的单词种类, 每分钟口语速度等进行确认。同时与教师进行比较, 了解自身英语水平和母语使用者间的差距。`,
    `您可以確認課程中使用的單字種類、每分鐘的口說速度。請與任課教師相比，確認您的英語實力與母語人士的程度差別。`,
    `レッスン中に使用した単語の種類、1分当たりの話すスピードを確認することができます。チューターと比較して、ネイティブに比べて自分の実力がどの程度なのかチェックしてみましょう。`,
    `Bạn có thể xem các kiểu từ vựng bạn dùng trong buổi học và tốc độ nói. So sánh những thông tin này với người bản xứ để hiểu khả năng của bạn.`,
  ],
  script_you_can_setting_record: [
    <>
      해당 수업은 [수업 녹음] 미설정으로 녹음 파일과 스크립트가 제공되지 않습니다.
      <br />
      <br />
      수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터 수업 녹음파일과 스크립트를 확인할 수
      있습니다.
    </>,
    <>
      You did not record this lesson. Audio recording and transcripts are only available for lessons that allowed audio
      recording. You can change this setting under Lesson Style for each lesson.
    </>,
    's_review.script_you_can_setting_record',
    <>
      该课程未设置[课程录音], 因此无法提供录音文件及课程脚本。
      <br />
      <br />
      课程开始前, 请于[课程模式设定]菜单中设定[课程录音] 您可从下次课程开始对课程录音及脚本内容进行确认。
    </>,
    <>
      由於該課程未設定[課程錄音]，故不提供錄音檔及腳本。
      <br />
      <br />
      若於課程開始前，於[上課方式設定]選單設定[課程錄音]， 您就可從下一次上課起確認錄音檔及腳本。
    </>,
    <>
      このレッスンは [レッスン録音] 未設定のため、録音ファイルとスクリプトは提供され ません。
      <br />
      <br />
      レッスン前に [レッスン方式設定] メニューで [レッスン録音]を設定すると、次のレッスンから
      レッスンの録音ファイルとスクリプトを確認することができます。
    </>,
    <>
      Bạn đã không thu âm buổi học. Ghi âm và bản ghi buổi học chỉ có khi bạn đồng ý cho thu âm. Bạn có thể chỉnh lại
      cài đặt ở phần Hình thức học của mỗi buổi học.
    </>,
  ],
  noshow: [
    `노쇼 수업으로 수업 분석이 제공되지 않습니다.`,
    `No-show lessons do not have audio recording and scripts.`,
    's_review.noshow',
    `旷课的情况, 将无法提供课程分析。`,
    `由於課程未出現，故不提供課程分析。`,
    `無断欠席したレッスンのため、レッスン分析は提供されません。`,
    `Các buổi học vắng mặt không có ghi âm và bản ghi.`,
  ],
  analyzing_patterns: [
    `스피킹 패턴(구문/표현) 분석 중`,
    `Analyzing speaking patterns`,
    's_review.analyzing_patterns',
    `口语模式(语法/表达)正在分析中`,
    `正在分析口說模式（句型／用法）`,
    `スピーキングパターン(構文/表現) 分析中`,
    `Phân tích các đặc điểm nói`,
  ],
  wpm: [
    `1분당 말하는 단어수를 의미하며 학습자의 말하기 속도를 측정합니다.`,
    `Speech pace is estimated using average words uttered per minute.`,
    's_review.wpm',
    `是指每分钟话语内所包含的单词数量, 从而推测学员说话的速度。`,
    `意指每分鐘使用的單字數，測量學生的口說速度。`,
    `1分間あたりの発話単語数を指し、受講生の話すスピードを測定します。`,
    `Tốc độ nói được ước tính bằng trung bình từ sử dụng trong một phút.`,
  ],
  vocabulary_range: [
    `수업 중 학습자가 사용한 단어를 기반으로 현재 학습자가 구사할 수 있는 영어 구사력을 측정합니다.`,
    `Measures fluency based on words spoken during lesson.`,
    's_review.vocabulary_range',
    `是指以课程中学员使用的单词为基础, 从而推测学员能够掌握的英语应用能力。`,
    `以上課時學生使用的單字為基礎，測量目前學生的英語口說能力。`,
    `レッスン中に使った単語をもとに、現時点での受講生の英語運用能力を測定します。`,
    `Đo lường mức độ trôi chảy dựa trên số từ bạn nói trong buổi học.`,
  ],
  script_being_prepared: [
    <>
      수업 녹음파일과 스크립트를 생성중입니다.
      <br />
      수업 종료 후, 최대 24시간 정도 소요될 수 있습니다. 조금만 기다려 주세요!
    </>,
    <>
      Generating lesson audio recordings and transcripts.
      <br />
      This process may take up to 24 hours after lesson. Thank you for your patience!
    </>,
    's_review.script_being_prepared',
    <>
      课程录音文件及脚本正在生成中。
      <br />
      课程结束后, 最多可需要24小时, 请您耐心等待!
    </>,
    <>
      正在生成課程錄音檔與腳本。
      <br />
      課程結束後，最長需要24小時，請稍候！
    </>,
    <>
      レッスンの録音ファイルとスクリプトを作成中です。
      <br />
      レッスン終了後、最長24時間ほどかかることがあります。もうしばらくお待ちください！
    </>,
    <>
      Đang tạo ghi âm và bản ghi buổi học.
      <br />
      Quy trình này có thể mất tới 24 giờ sau buổi học. Cảm ơn sự kiên nhẫn của bạn!
    </>,
  ],
  script_being_delayed: [
    <>
      수업 녹음파일과 스크립트 생성이 지연되고 있습니다. 아래 문의 버튼을 통해 링글팀에 연락 주시면, 빠르게 확인 후
      조치하겠습니다.
      <br />
      복습 자료 제공이 늦어진 점에 사과드립니다.
    </>,
    <>
      We're experiencing a delay in generating your lesson audio recording and transcripts.
      <br />
      We apologize for the inconvenience.
    </>,
    's_review.script_being_delayed',
    <>
      课程录音文件及脚本生成出现延迟。点击咨询按钮联系Ringle团队 我们将尽快为您解决。
      <br />
      我们对复习材料的延迟提供深表歉意。
    </>,
    <>
      課程錄音檔與腳本生成延誤中，如點擊以下諮詢按鈕 聯絡Ringle團隊，將盡快確認後進行處理。
      <br />
      我們為延誤提供複習資料向您致歉。
    </>,
    <>
      レッスンの録音ファイルとスクリプトの作成に時間がかかっています。下のお問い合わせボタンから
      Ringleチームにご連絡いただくと、速やかに確認して処理いたします。
      <br />
      復習資料のご提供が遅くなり申し訳ございません。
    </>,
    <>
      Chúng tôi gặp phải sự chậm trễ trong việc xuất ghi âm và bản ghi.
      <br />
      Chúng tôi xin lỗi vì sự bất tiện này.
    </>,
  ],
  inquiry: [
    `링글팀에게 알리기`,
    `Report to Ringle team`,
    's_review.inquiry',
    `通知Ringle团队`,
    `告知Ringle團隊`,
    `Ringleチームに知らせる`,
    `Báo lỗi cho đội ngũ Ringle`,
  ],
  successfully_submitted: [
    `링글팀에 복습 자료를 아직 제공받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.successfully_submitted',
    `已通知Ringle团队您尚未收到复习材料, 我们将尽快确认后给予您回复。`,
    `您已告知Ringle團隊尚未收到複習資料，我們將盡快確認後連絡您。`,
    `Ringleチームに復習資料がまだ提供されていないことを知らせました。速やかに確認後、ご連絡いたします。`,
    `Báo lỗi đã được gửi đến Ringle. Chúng tôi sẽ đánh giá và phản hồi lại cho bạn.`,
  ],
  only_available_allowed_recording: [
    `수업 녹음파일 미설정으로 AI 분석 기능은 제공되지 않습니다.`,
    `The AI analysis service is not available due to "Do not record" state.`,
    's_review.only_available_allowed_recording',
    `未设置课程录音, 因此无法提供AI分析功能。`,
    `由於未設定課程錄音檔，故不提供AI分析功能。`,
    `レッスンの録音ファイル未設定のため、AI analysis機能は提供されません。`,
    `Dịch vụ phân tích bằng AI hiện thời không khả dụng do trạng thái "Không thu âm".`,
  ],
  analyzing: [
    `말하기 속도 & 사용 단어 수 분석 중`,
    `Analyzing Speech Pace & Vocabulary Range`,
    's_review.analyzing',
    `口语速度&使用单词量正在分析中`,
    `正在分析口說速度 & 使用的單字數`,
    `話すスピード & 使用単語数分析中`,
    `Phân tích tốc độ nói & Vốn từ vựng`,
  ],
  feedback_waiver: [
    <>
      해당 수업의 피드백은 아래의 사유 중 하나로 제공되지 않습니다.
      <br />
      문의사항이 있으시면 홈페이지 내 [문의] 채널로 알려주세요!
      <br />
      <br />
      - 수강생의 요청
      <br />
      - 작문 위주로 진행된 수업
      <br />
      - 인터뷰 형식으로 진행된 수업
      <br />
    </>,
    <>
      Feedback from this class is not available due to one of the following reasons:
      <br />
      If you have any questions, please contact us via chat below
      <br />
      <br />
      - Student confirmation - no feedback required
      <br />
      - Writing-oriented class
      <br />
      - Interview-oriented class
      <br />
    </>,
    's_review.feedback_waiver',
    <>
      该课程的反馈, 因以下原因无法提供。
      <br />
      如有疑问, 可通过主页的在线[咨询]联系我们!
      <br />
      <br />
      - 学员请求
      <br />
      - 以写作为主的课程
      <br />
      - 以面试形式进行的课程
      <br />
    </>,
    <>
      該課程的意見回饋由於以下原因之一未提供。
      <br />
      如有疑問，請透過首頁的[諮詢]聯絡我們！
      <br />
      <br />
      - 學生的要求
      <br />
      - 以寫作為主進行的課程
      <br />
      - 以面試為主進行的課程
      <br />
    </>,
    <>
      このレッスンのフィードバックは、以下のいずれかの理由により提供されません。
      <br />
      お問い合わせはホームページ内の [お問い合わせ] チャンネルからご連絡ください。
      <br />
      <br />
      - 受講生のリクエスト
      <br />
      - 作文中心に行われたレッスン
      <br />
      - 面接形式で行われたレッスン
      <br />
    </>,
    <>
      Đánh giá của lớp học hiện thời không có do những lí do sau:
      <br />
      Nếu bạn có bất kỳ câu hỏi nào, vui lòng chat với chúng tôi
      <br />
      <br />
      - Học viên xác nhận - không yêu cầu đánh giá
      <br />
      - Lớp học tập trung viết
      <br />
      - Lớp học tập trung phần phỏng vấn
      <br />
    </>,
  ],
  contact: [
    `링글팀에게 알리기`,
    `Report to Ringle team`,
    's_review.contact',
    `通知Ringle小组`,
    `告知Ringle團隊`,
    `Ringleチームに知らせる`,
    `Báo lỗi cho đội ngũ Ringle`,
  ],
  alertMessage: [
    `링글팀에 튜터 피드백 리포트를 아직 받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.alertMessage',
    `已通知Ringle团队您尚未收到教师的Feedback Report, 我们将尽快确认后给予您回复。`,
    `您已告知Ringle團隊尚未收到任課教師的意見回饋報告書，我們將盡快確認後連絡您。`,
    `RingleチームにチューターからのFeedback Reportをまだ受け取っていないことを知らせました。速やかに確認後、ご連絡いたします。`,
    `Báo lỗi đã được gửi đến Ringle. Chúng tôi sẽ xem lại và phản hồi lại cho bạn.`,
  ],
  worst: [`최악이었어요!`, `Worst`, 's_review.worst', `真是糟糕透了!`, `超級糟糕！`, `最悪でした！`, `Rất tệ`],
  bad: [`그냥 별로였어요`, `Bad`, 's_review.bad', `不怎么样`, `普通`, `イマイチでした。`, `Tệ`],
  dissatisfied: [
    `전반적으로 아쉬웠어요`,
    `Dissatisfied`,
    's_review.dissatisfied',
    `整体而言不是很满意`,
    `整體上很可惜`,
    `全般的に残念でした。`,
    `Chưa hài lòng`,
  ],
  fine: [
    `좋았는데, 조금 아쉬웠어요`,
    `Fine`,
    's_review.fine',
    `还不错, 但有点小问题`,
    `還不錯，但有點可惜`,
    `よかったけど、少し残念でした。`,
    `Tốt`,
  ],
  great: [`좋았어요!`, `Great`, 's_review.great', `很不错!`, `很不錯！`, `よかったです！`, `Tuyệt vời`],
  what_did_you_have_problem: [
    `어떤 점이 불만이셨나요?`,
    `What did you have problems with?`,
    's_review.what_did_you_have_problem',
    `哪方面让您不满意?`,
    `什麼部分讓您不滿意呢？`,
    `どの点が不満でしたか？`,
    `Bạn đã gặp vấn đề gì?`,
  ],
  what_small_improvements: [
    `어떤 점만 개선하면 좋을까요?`,
    `What improvements would you suggest?`,
    's_review.what_small_improvements',
    `哪方面需要进行改善?`,
    `您希望改善哪一部分呢？`,
    `どの点を改善すればいいですか？`,
    `Điều gì bạn cần chúng tôi cải thiện?`,
  ],
  what_did_you_like_the_most: [
    `특히 어떤 점이 좋으셨나요?`,
    `What did you like the most?`,
    's_review.what_did_you_like_the_most',
    `哪方面特别满意?`,
    `您特別喜歡哪一部分呢？`,
    `特にどの点がよかったですか？`,
    `Điều gì bạn thích nhất?`,
  ],
  placeholder_1: [
    `수업 이용에 불편을 드려 죄송합니다. 튜터와 진행한 수업에서 겪으셨던 불편 사항을 남겨주세요.`,
    `We are sorry to hear that. Please note what you found to be unsatisfactory.`,
    's_review.placeholder_1',
    `很抱歉课程给您造成的不便, 请阐述和教师的课程中您所遇到的不便之处。`,
    `抱歉造成您在進行課程上的不便，請留下與任課教師進行課程時遭遇的不便事項。`,
    `レッスンのご利用にご不便をおかけして申し訳ございません。チューターと行ったレッスンで感じた不満点を入力してください。`,
    `Chúng tôi rất tiếc. Xin ghi rõ điều bạn chưa hài lòng.`,
  ],
  placeholder_2: [
    `기대하셨던 것과는 달리 진행된 수업에서 튜터의 부족했던 부분을 남겨주세요.`,
    `Has your tutor missed your expectations at all? How so?`,
    's_review.placeholder_2',
    `未能达到您期待的课程, 请阐述教师的不足之处。`,
    `請留下低於您所期待的課程中任課教師的不足之處。`,
    `期待とは異なったレッスンでのチューターの足りない部分を入力してください。`,
    `Có phải gia sư của bạn không đáp ứng được những kỳ vọng?`,
  ],
  placeholder_3: [
    `만족도 높은 수업을 제공하지 못해 아쉽습니다. 튜터가 개선할 수 있는 부분을 남겨주세요.`,
    `We are sorry to hear that. Please note where the tutor could improve`,
    's_review.placeholder_3',
    `未能提供给您满意的课程, 我们深表遗憾。请阐述希望教师进行改善的部分。`,
    `抱歉未能提供滿意度高的課程，請留下任課教師可以改善的部分。`,
    `満足度の高いレッスンを提供することができず、申し訳ございません。チューターが改善できる部分を入力してください。`,
    `Chúng tôi rất tiếc. Mong bạn nêu rõ những điểm gia sư có thể cải thiện`,
  ],
  placeholder_4: [
    `튜터에 대한 자세한 수업 평가는 튜터의 역량 개선 및 수업 퀄리티 증진에 큰 도움이 됩니다.
(네트워크/교재 평가는 아래 선택사항에 남겨주세요. 수업과 무관한 내용, 욕설 등이 포함된 악의적인 평가는 사전 안내 없이 삭제될 수 있습니다.)`,
    `Your detailed feedback on tutor helps improving our tutor’s ability and class quality.
(Leave feedback on network or lesson materials through options below. Ill-intended feedback irrelevant to lessons or containing swearwords may be deleted without prior notice.)`,
    's_review.placeholder_4',
    `有关教师的详细课程评价, 有助于改善教师执教能力提升课程质量。
(但网络, 教材等与教师无关的情况请通过以下选择事项进行留言。)`,
    `有關任課教師的詳細課程評論有助於任課教師改善能力與增進課程品質。
(網路、教材等與任課教師無關的項目請透過以下選填事項留言。)`,
    `チューターへの詳しいレッスン評価は、チューターの能力改善とレッスンクオリティの向上に大いに役立ちます。
(ネットワーク／教材評価は下記の選択事項に残してください。レッスンに関係なく、暴言を含む悪質的な評価は事前案内なしに削除されます。)`,
    `Đánh giá chi tiết của bạn dành cho gia sư sẽ giúp chúng tôi cải thiện khả năng của gia sư và chất lượng lớp học.
(Viết nhận xét về đường truyền hoặc tài liệu học ở phía dưới)`,
  ],
  submit: [`제출하기`, `Submit`, 's_review.submit', `提交`, `提交`, `送信する`, `Gửi`],
  shareRingleTeam: [
    `링글팀과 튜터에게만 공유하기`,
    `Visible only to Ringle Team and Tutor.`,
    's_review.shareRingleTeam',
    `仅限共享给Ringle团队及教师`,
    `僅分享給Ringle團隊與任課教師`,
    `Ringleチームとチューターにのみ共有する`,
    `Chỉ hiển thị cho đội ngũ Ringle và Gia sư.`,
  ],
  ringleTeamComment: [
    `링글팀에게 남길 의견이 있으신가요?`,
    `Do you have any feedback for the Ringle Team?`,
    's_review.ringleTeamComment',
    `您有建议向Ringle团队提出吗?`,
    `您有意見要和Ringle團隊分享嗎？`,
    `Ringleチームへのご意見はありますか？`,
    `Bạn có đánh giá nào dành cho đội ngũ Ringle?`,
  ],
  submit_late: [`나중에 하기`, `Skip for now`, 's_review.submit_late', `下次再说`, `下次再說`, `今はしない`, `Bỏ qua`],
  required_field: [
    `필수로 입력해야하는 항목을 입력해주세요`,
    `Please enter the required fields`,
    's_review.required_field',
    `请输入必需项目。`,
    `請輸入必填項目`,
    `必須項目を入力してください。`,
    `Vui lòng điền vào các ô bắt buộc`,
  ],
  preparation: [
    `수업 준비도`,
    `Preparation`,
    's_review.preparation',
    `课程准备度`,
    `課程準備度`,
    `レッスン準備度`,
    `Sự chuẩn bị`,
  ],
  tech_environment: [
    `수업 환경`,
    `Teaching Environment`,
    's_review.tech_environment',
    `课程环境`,
    `上課環境`,
    `レッスン環境`,
    `Môi trường giảng dạy`,
  ],
  tech_quality: [
    `비디오/마이크 상태`,
    `Audio/Video Quality`,
    's_review.tech_quality',
    `视频/麦克风状态`,
    `影像／麥克風狀態`,
    `ビデオ/マイクの状態`,
    `Chất lượng âm thanh/video`,
  ],
  punctuality: [
    `지각/조기종료`,
    `Punctuality`,
    's_review.punctuality',
    `迟到/提前结束`,
    `遲到／提早結束`,
    `遅刻/早期終了`,
    `Đúng giờ`,
  ],
  correction_quality: [
    `교정 능력`,
    `Correction Quality`,
    's_review.correction_quality',
    `纠正能力`,
    `糾正能力`,
    `校正能力`,
    `Chất lượng phần sửa lỗi`,
  ],
  tailoring: [
    `수업 설정 미반영`,
    `Tailoring to Lesson Requests`,
    's_review.tailoring',
    `未响应课程设置`,
    `未反映課程設定`,
    `レッスン設定未反映`,
    `Xây dựng buổi học theo yêu cầu`,
  ],
  lesson_direction: [
    `수업 진행 능력`,
    `Lesson Direction`,
    's_review.lesson_direction',
    `课程进行能力`,
    `課程進行能力`,
    `レッスン進行能力`,
    `Định hướng của buổi học`,
  ],
  attitude: [
    `수업 태도`,
    `Attitude and Tone`,
    's_review.attitude',
    `课程进行态度`,
    `授課態度`,
    `レッスン態度`,
    `Thái độ và tông giọng`,
  ],
  punctualityRight: [
    `수업시간 엄수`,
    `Punctuality`,
    's_review.punctualityRight',
    `严守课程时间`,
    `嚴守課程時間`,
    `レッスン時間厳守`,
    `Đúng giờ`,
  ],
  tailoringRight: [
    `수업 설정 숙지`,
    `Tailoring to Lesson Requests`,
    's_review.tailoringRight',
    `熟知课程设置`,
    `清楚課程設定`,
    `レッスン設定熟知`,
    `Xây dựng buổi học theo yêu cầu`,
  ],
  writeThisPlace: [
    `소중한 의견을 작성해주세요. 더 나은 서비스를 위해 노력하겠습니다.`,
    `Please leave a comment.`,
    's_review.writeThisPlace',
    `请留下您的宝贵意见。我们将努力为您提供更优质的服务。`,
    `請撰寫寶貴的意見，我們將努力提供更好的服務。`,
    `大切なご意見をお願いいたします。より良いサービスのために努力いたします。`,
    `Vui lòng để lại bình luận.`,
  ],
  material: [`교재`, `Materials`, 's_review.material', `教材`, `教材`, `教材`, `Tài liệu`],
  network: [`네트워크`, `Network`, 's_review.network', `网络`, `網路`, `ネットワーク`, `Đường truyền`],
  overall: [`총평`, `Overall`, 's_review.overall', `总评价`, `總評`, `総評`, `Nhận xét chung`],
  doNotWantToMeetAgain: [
    `이 튜터와 다시 만나지 않기`,
    `I do not want to meet this tutor again`,
    's_review.doNotWantToMeetAgain',
    `不再与此教师学习`,
    `不再見到這個任課教師`,
    `このチューターと二度と会わない`,
    `Tôi không muốn gặp lại gia sư này`,
  ],
  howWasYourLessonWith: (tutor) => [
    <>{tutor} 튜터와의 수업은 어떠셨나요?</>,
    <>How was your lesson with {tutor}?</>,
    's_review.howWasYourLessonWith',
    <>和{tutor}教师的课程如何?</>,
    <>您與{tutor}任課教師的課程進行得如何呢？</>,
    <>{tutor} チューターとのレッスンはいかがでしたか？</>,
    <>Buổi học của bạn cùng với {tutor} như thế nào?</>,
  ],
  leaveTutorComment: [
    `튜터에게 수업에 대한 평가를 남겨주세요.`,
    `Please leave a comment about this tutor.`,
    's_review.leaveTutorComment',
    `请告知教师您对课程的评价。`,
    `請對任課教師留下課程評論。`,
    `チューターへのレッスンの評価を作成してください。`,
    `Vui lòng để lại nhận xét về gia sư này.`,
  ],
  lessonOverall: [
    `전반적인 수업 만족도는 어떠셨나요?`,
    `What did you think about the lesson overall?`,
    's_review.lessonOverall',
    `课程整体的满意程度如何?`,
    `整體的課程滿意度如何呢？`,
    `全般的なレッスンの満足度はいかがでしたか？`,
    `Bạn nghĩ sao về buổi học?`,
  ],
  optional: [`선택사항`, `Optional`, 's_review.optional', `选择事项`, `選填事項`, `選択事項`, `Không bắt buộc`],
  placeholder_5: [
    <>수업과 관련 없거나 부적절한 표현이 포함된 평가는 숨김 처리 될 수 있습니다.</>,
    <>Feedbacks containing irrelevant facts or offensive language may be hidden.</>,
    's_review.placeholder_5',
    <>如评价中出现和课程无关或不恰当的内容时, 相关评价可能会被隐藏。</>,
    <>評論中包含與課程無關或不當的言論時，將可能被隱藏。</>,
    <>レッスンと関連がない、または不適切な表現が含まれた評価は、非表示になることがあります。</>,
    <>Đánh giá bao gồm những sự thật không liên quan hoặc ngôn ngữ xúc phạm sẽ bị ẩn.</>,
  ],
  entire: [`전체 수업`, `All`, 's_review.entire', `全部`, `全部`, `すべて`, `Tất cả`],
  no_show_lesson: [
    `노쇼한 수업`,
    `No-show`,
    's_review.no_show_lesson',
    `未出席的课程`,
    `未出現的課程`,
    `無断欠席したレッスン`,
    `Vắng mặt`,
  ],
  all_viewed: [
    `모두 조회했습니다.`,
    `Review complete`,
    's_review.all_viewed',
    `全部查询完毕。`,
    `已全部查詢。`,
    `すべて照会しました。`,
    `Hoàn tất xem lại`,
  ],
  all: [`전체`, `All`, 's_review.all', `全部`, `全部`, `すべて`, `Tất cả`],
  no_reviewed: [
    `복습을 완료한 수업이 없습니다.`,
    `You haven't reviewed any lessons.`,
    's_review.no_reviewed',
    `没有完成复习的课程。`,
    `無完成複習的課程。`,
    `復習を完了したレッスンはありません。`,
    `Bạn chưa xem lại buổi học.`,
  ],
  lesson_eval_submit: [
    `수업 평가가 제출되었습니다.`,
    `Submitted.`,
    's_review.lesson_eval_submit',
    `已经提交了课程评价。`,
    `已提交課程評論。`,
    `レッスン評価が送信されました。`,
    `Đã gửi.`,
  ],
  placeholder_refund: [
    `만족스러운 수업을 제공해 드리지 못해 죄송합니다. 튜터가 개선할 수 있는 부분을 구체적으로 남겨주시면, 담당팀에서 확인 후 수업권 복구에 대해 안내해 드리겠습니다.`,
    `We are sorry to hear that you were unsatisfied with the lesson. If you tell us what areas the tutor can work on in detail, the Ringle team will review your comments and contact you about reissuing the credit.`,
    's_review.placeholder_refund',
    `很抱歉未能给您提供满意的课程。如果您留下任课教师的具体改善建议，负责团队确认后，将为您介绍关于课程券恢复使用的相关事宜。`,
    `很抱歉未能給您提供滿意的課程。如果您留下任課教師的具體改善建議，負責團隊確認后，將為您介紹關於課程券恢復使用的相關事宜 。`,
    `ご満足のいくレッスンを提供することができず申し訳ございませんでした。チューターが改善できる部分を具体的にご記入いただけますと、担当チームが確認後レッスンチケットの返還についてご案内いたします。`,
    `Chúng tôi xin lỗi vì không thể cung cấp cho bạn một buổi học thỏa đáng. Nếu bạn viết chi tiết những gì gia sư có thể cải thiện, nhóm phụ trách sẽ kiểm tra lại và hướng dẫn bạn khôi phục lại phiếu buổi học.`,
  ],
  year_month: (n) => [
    <DateComponent format="yyyy년 mm월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mmM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_review.year_month',
    <DateComponent format="yyyy年 mm月" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy年 mm月" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="mm [năm] yyyy" locale="vi">
      {n}
    </DateComponent>,
  ],
  current_month: [
    `이번 달로 가기`,
    `Show Current Month`,
    's_review.current_month',
    `返回本月`,
    `返回本月`,
    `今月へ`,
    `Về tháng này`,
  ],
  full_date: (year, month, date) => [
    <>
      {year}.{month}.{date}
    </>,
    <>
      {month}.{date}.{year}
    </>,
    's_review.full_date',
    <>
      {year}.{month}.{date}
    </>,
    <>
      {year}.{month}.{date}
    </>,
    <>
      {year}.{month}.{date}
    </>,
    <>
      {date}.{month}.{year}
    </>,
  ],
  no_list_desc: [
    `이 달의 수업기록이 없습니다.`,
    `You did not take any lessons during this period.`,
    's_review.no_list_desc',
    `查询月份没有学习记录`,
    `查詢月份沒有學習記錄`,
    `ここ一ヶ月の学習記録がありません`,
    `Bạn không học trong khoảng thời gian này`,
  ],
  view_recent_lessons: [
    `최근 수업 기록 보기`,
    `View Recent Lessons`,
    's_review.view_recent_lessons',
    `查看最近课程记录`,
    `查看最近課程記錄`,
    `最近のレッスン`,
    `Lịch sử học gần đây`,
  ],
  full_month: (month) => [
    `${month}월`,
    `${getMonthName(month - 1)[1]}`,
    's_review.full_month',
    `${month}月`,
    `${month}月`,
    `${month}月`,
    `${getMonthName(month - 1)[1]}`,
  ],
  keyword_title_1: [`수업 진행능력`, `Teaching Ability`, 's_review.keyword_title_1', ``, ``, ``, ``],
  keyword_title_2: [`수업 참여도/준비도`, `Engagement/Preperation`, 's_review.keyword_title_2', ``, ``, ``, ``],
  keyword_title_3: [`수업 태도`, `Attitude`, 's_review.keyword_title_3', ``, ``, ``, ``],
  keyword_title_4: [`수업 진행능력`, `Teaching Ability`, 's_review.keyword_title_4', ``, ``, ``, ``],
  keyword_title_5: [`튜터의 태도/환경`, `Attitude/Environment`, 's_review.keyword_title_5', ``, ``, ``, ``],
  keyword_1_1: [`교정의 양이 많아요`, `Provides sufficient paraphrasing`, 's_review.keyword_1_1', ``, ``, ``, ``],
  keyword_1_2: [`새로운 문장 형식을 알려줘요`, `Teaches sentence structures`, 's_review.keyword_1_2', ``, ``, ``, ``],
  keyword_1_3: [`새로운 단어를 많이 알려줘요`, `Teaches many new expressions`, 's_review.keyword_1_3', ``, ``, ``, ``],
  keyword_1_4: [`추가 질문을 많이 해줬어요`, `Asks many questions`, 's_review.keyword_1_4', ``, ``, ``, ``],
  keyword_1_5: [`대화를 많이 할 수 있어요`, `Actively engages conversation`, 's_review.keyword_1_5', ``, ``, ``, ``],
  keyword_1_6: [`교정에 대한 이유를 잘 설명해요`, `Provides good explanations`, 's_review.keyword_1_6', ``, ``, ``, ``],
  keyword_1_7: [`반복되는 실수를 잘 짚어줘요`, `Corrects repetitive mistakes`, 's_review.keyword_1_7', ``, ``, ``, ``],
  keyword_2_1: [`수업을 잘 리드해요`, `Leads lessons well`, 's_review.keyword_2_1', ``, ``, ``, ``],
  keyword_2_2: [
    `학생의 요청사항을 잘 들어줘요`,
    `Responds willingly to requests`,
    's_review.keyword_2_2',
    ``,
    ``,
    ``,
    ``,
  ],
  keyword_2_3: [`수업 시간을 충분히 활용해요`, `Makes full use of time`, 's_review.keyword_2_3', ``, ``, ``, ``],
  keyword_2_4: [`수업 준비를 잘 해줘요`, `Comes to lessons prepared`, 's_review.keyword_2_4', ``, ``, ``, ``],
  keyword_2_5: [`수업 노트를 잘 정리해요`, `Provides good written feedback`, 's_review.keyword_2_5', ``, ``, ``, ``],
  keyword_2_6: [`교재를 정확히 이해하고 있어요`, ``, 's_review.keyword_2_6', ``, ``, ``, ``],
  keyword_3_1: [`수업 분위기가 활기차요`, `Energetic and lively`, 's_review.keyword_3_1', ``, ``, ``, ``],
  keyword_3_2: [`차분하게 수업을 진행해요`, `Calm and composed`, 's_review.keyword_3_2', ``, ``, ``, ``],
  keyword_3_3: [`답변에 대한 리액션이 좋아요`, `Good reactions`, 's_review.keyword_3_3', ``, ``, ``, ``],
  keyword_3_4: [`수준에 맞게 수업을 진행해줘요`, `Teaches at my level`, 's_review.keyword_3_4', ``, ``, ``, ``],
  keyword_3_5: [`냉철한 피드백을 해줘요`, `Gives objective feedback`, 's_review.keyword_3_5', ``, ``, ``, ``],
  keyword_4_1: [`교정이 부족했어요`, `Not enough paraphrasing`, 's_review.keyword_4_1', ``, ``, ``, ``],
  keyword_4_2: [`단순 문법 교정만 진행됐어요`, `Only corrects simple grammar`, 's_review.keyword_4_2', ``, ``, ``, ``],
  keyword_4_3: [`설명이 부족했어요`, `Not enough explanations`, 's_review.keyword_4_3', ``, ``, ``, ``],
  keyword_4_4: [`교정 외에 대화가 부족했어요`, `Not enough conversation`, 's_review.keyword_4_4', ``, ``, ``, ``],
  keyword_4_5: [`학생과 소통이 부족했어요`, `Not communicative`, 's_review.keyword_4_5', ``, ``, ``, ``],
  keyword_4_6: [`수업을 리드하지 못했어요`, `Not good at leading lessons`, 's_review.keyword_4_6', ``, ``, ``, ``],
  keyword_4_7: [`수업 준비가 아쉬웠어요`, `Did not come prepared`, 's_review.keyword_4_7', ``, ``, ``, ``],
  keyword_5_1: [`수업 시간을 잘 지키지 않았어요`, `Came late/ended early`, 's_review.keyword_5_1', ``, ``, ``, ``],
  keyword_5_2: [`수업에 집중하지 못했어요`, `Lacked focus`, 's_review.keyword_5_2', ``, ``, ``, ``],
  keyword_5_3: [`수업 진행 환경이 어수선해요`, `Distracting environment`, 's_review.keyword_5_3', ``, ``, ``, ``],
  keyword_5_4: [
    `불친절해서 대화가 어려웠어요`,
    `Unfriendly and difficult to talk to`,
    's_review.keyword_5_4',
    ``,
    ``,
    ``,
    ``,
  ],
  title: [`수업 평가`, ``, 's_review.title', ``, ``, ``, ``],
  subtitle_1: [`이번 수업은 어떠셨나요?`, ``, 's_review.subtitle_1', ``, ``, ``, ``],
  subtitle_2: [
    `이번 수업에서 {tutor name}의 아쉬웠던 점을 알려주세요. (1개 이상)`,
    ``,
    's_review.subtitle_2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_3: [`{tutor name}튜터와 다음에도 수업을 하고 싶으신가요?`, ``, 's_review.subtitle_3', ``, ``, ``, ``],
  subtitle_4: [`자세한 수업 후기를 알려주세요. (선택)`, ``, 's_review.subtitle_4', ``, ``, ``, ``],
  subtitle_5: [`교재가 수업에 도움이 되었나요? (선택)`, ``, 's_review.subtitle_5', ``, ``, ``, ``],
  subtitle_6: [`수업 중 네트워크 환경은 어땠나요? (선택)`, ``, 's_review.subtitle_6', ``, ``, ``, ``],
  rating_0: [`수업 만족도를 평가해주세요.`, ``, 's_review.rating_0', ``, ``, ``, ``],
  rating_1: [`최악이었어요.`, ``, 's_review.rating_1', ``, ``, ``, ``],
  rating_2: [`너무 별로였어요.`, ``, 's_review.rating_2', ``, ``, ``, ``],
  rating_3: [`아쉬웠어요.`, ``, 's_review.rating_3', ``, ``, ``, ``],
  rating_4: [`좋았어요.`, ``, 's_review.rating_4', ``, ``, ``, ``],
  rating_5: [`최고에요!`, ``, 's_review.rating_5', ``, ``, ``, ``],
  report: [`수업이 진행되지 않았다면 링글팀에게 알려주세요.`, ``, 's_review.report', ``, ``, ``, ``],
  btn_1: [`나중에 하기`, ``, 's_review.btn_1', ``, ``, ``, ``],
  btn_2: [`다음`, ``, 's_review.btn_2', ``, ``, ``, ``],
  btn_3: [`선택 완료`, ``, 's_review.btn_3', ``, ``, ``, ``],
  btn_4: [`평가 완료`, ``, 's_review.btn_4', ``, ``, ``, ``],
  btn_5: [`제출하기`, ``, 's_review.btn_5', ``, ``, ``, ``],
  btn_6: [`확인`, ``, 's_review.btn_6', ``, ``, ``, ``],
  btn_7: [`평가 완료하기`, ``, 's_review.btn_7', ``, ``, ``, ``],
  btn_8: [`추가 작성하기`, ``, 's_review.btn_8', ``, ``, ``, ``],
  btn_9: [`확인`, ``, 's_review.btn_9', ``, ``, ``, ``],
  prefer_0: [`튜터 선호도는 링글팀에게만 공개됩니다.`, ``, 's_review.prefer_0', ``, ``, ``, ``],
  prefer_1: [`아니요. 다시 만나고 싶지 않아요.`, ``, 's_review.prefer_1', ``, ``, ``, ``],
  prefer_2: [`괜찮았어요. 기회가 되면 또 하고 싶어요.`, ``, 's_review.prefer_2', ``, ``, ``, ``],
  prefer_3: [`최고의 튜터! 꼭 다시 만나고 싶어요.`, ``, 's_review.prefer_3', ``, ``, ``, ``],
  placeholder_textreview: [
    `자세한 수업 후기는 튜터의 역량 개선 및 수업의 만족도 증진에 큰 도움이 됩니다. 
부적절한 단어/표현이 포함된 경우,  별도의 통지없이 삭제될 수 있습니다.`,
    ``,
    's_review.placeholder_textreview',
    ``,
    ``,
    ``,
    ``,
  ],
  word_count: [`/최소 20자`, ``, 's_review.word_count', ``, ``, ``, ``],
  option_share_1: [`전체공개`, ``, 's_review.option_share_1', ``, ``, ``, ``],
  option_share_2: [`링글팀에게만 공유하기`, ``, 's_review.option_share_2', ``, ``, ``, ``],
  option_share_3: [`링글팀과 튜터에게만 공유하기`, ``, 's_review.option_share_3', ``, ``, ``, ``],
  placeholder_optional: [
    `어떤점이 아쉬웠는지 의견을 보내주세요. (선택)`,
    ``,
    's_review.placeholder_optional',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_error: [`자세한 수업 후기는 20자 이상으로 입력해주세요.`, ``, 's_review.desc_error', ``, ``, ``, ``],
  toast: [`리뷰를 남겨주셔서 감사합니다.`, ``, 's_review.toast', ``, ``, ``, ``],
  title_pointback: [`새로 온 튜터 후기 작성`, ``, 's_review.title_pointback', ``, ``, ``, ``],
  desc_pointback: [
    `수업 후기를 100자 이상 입력해야 {00,000}포인트를 돌려 받을 수 있어요.
지금 수업 평가를 완료하시겠어요?`,
    ``,
    's_review.desc_pointback',
    ``,
    ``,
    ``,
    ``,
  ],
  label_pointback: [`새로 온 튜터 포인트백 혜택`, ``, 's_review.label_pointback', ``, ``, ``, ``],
  info_pointback: [
    `100자 이상의 수업 후기를 남기면 {00,000}P를 돌려드려요!`,
    ``,
    's_review.info_pointback',
    ``,
    ``,
    ``,
    ``,
  ],
  toast_pointback: [`새로 온 튜터 후기 작성 포인트가 적립되었습니다.`, ``, 's_review.toast_pointback', ``, ``, ``, ``],
  title_report: [`수업에 어떤 문제가 있었나요?`, ``, 's_review.title_report', ``, ``, ``, ``],
  report_option_1: [`줌이 제대로 실행되지 않았어요.`, ``, 's_review.report_option_1', ``, ``, ``, ``],
  report_option_2: [`비디오/오디오가 연결되지 않았어요.`, ``, 's_review.report_option_2', ``, ``, ``, ``],
  report_option_3: [`튜터가 입장하지 않았어요.`, ``, 's_review.report_option_3', ``, ``, ``, ``],
  report_option_4: [`정규 수업 시간을 충분히 채우지 못했어요.`, ``, 's_review.report_option_4', ``, ``, ``, ``],
  report_option_5: [`수업이 자꾸 끊겼어요.`, ``, 's_review.report_option_5', ``, ``, ``, ``],
  subtitle_report: [`문제 상황을 설명해 주세요.`, ``, 's_review.subtitle_report', ``, ``, ``, ``],
  placeholder_report: [
    `불편을 겪으신 사항을 자세히 알려주세요. 
링글팀이 1 영업일 이내 확인 후 연락 드리겠습니다.`,
    ``,
    's_review.placeholder_report',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_report: [
    `수업에 불편을 드려 죄송합니다. 제보해주신 내용은 1영업일 내로 처리 결과를 알려드리겠습니다.`,
    ``,
    's_review.desc_report',
    ``,
    ``,
    ``,
    ``,
  ],
  title_quit: [`수업 나가기`, ``, 's_review.title_quit', ``, ``, ``, ``],
  desc_quit: [`수업을 중단하시겠습니까?`, ``, 's_review.desc_quit', ``, ``, ``, ``],
  btn_quit_1: [`취소`, ``, 's_review.btn_quit_1', ``, ``, ``, ``],
  btn_quit_2: [`나가기`, ``, 's_review.btn_quit_2', ``, ``, ``, ``],
  btn_10: [`평가 완료하기`, `Complete`, 's_review.btn_10', `Complete`, `Complete`, `評価を完了`, `Complete`],
  btn_11: [`추가 작성하기`, `Write more`, 's_review.btn_11', `Write more`, `Write more`, `追加作成`, `Write more`],
  subtitle_7: (name) => [
    `이번 수업에서 ${name}의 좋았던 점을 알려주세요. (1개 이상)`,
    `Please check every item that describes what you liked about ${name} or this lesson. (Please choose at least one item.)`,
    's_review.subtitle_7',
    `请告诉我们${name}在这节课上令您感到满意的表现。（一个以上）`,
    `請告訴我們${name}在這節課上令您感到滿意的表現。（一個以上）`,
    `今回のレッスンで${name}の良かった点を教えてください。(1つ以上)`,
    `Vui lòng cho biết điểm tốt của ${name} trong buổi học này. (nhiều hơn 1 điểm)`,
  ],
  placeholder_optional_material: [
    `교재가 수업에 얼마나 도움이 되었는지 의견을 남겨주세요. (선택)`,
    `How helpful was the learning material? (Optional)`,
    's_review.placeholder_optional_material',
    `教材对课程的帮助程度（选填）`,
    `教材對課程的幫助程度（選填）`,
    `レッスン教材についてのご意見をお願いします。(任意)`,
    `Đánh giá tài liệu học (không bắt buộc)`,
  ],
  placeholder_optional_network: [
    `네트워크 환경에 대한 의견을 남겨주세요. (선택)`,
    `Did you have any network issues? (Optional)`,
    's_review.placeholder_optional_network',
    `请留下对于连线品质的意见（选填）`,
    `請留下對於連線品質的意見（選填）`,
    `ネットワークについてのご意見をお願いします。(任意)`,
    `Đánh giá kết nối mạng (không bắt buộc)`,
  ],
}
export const s_convert_credit = {
  change_credit: [
    `수업권 변경`,
    `Exchange Lesson Credits`,
    's_convert_credit.change_credit',
    `课程券变更`,
    `變更課程券`,
    `レッスンチケット変更`,
    `Quy đổi Thẻ học`,
  ],
  select_the_package_to_change: [
    `변경 할 패키지를 선택해주세요`,
    `Select lesson credits to exchange`,
    's_convert_credit.select_the_package_to_change',
    `请选择要变更的礼包`,
    `請選擇欲變更的禮包`,
    `変更するパッケージを選択してください。`,
    `Chọn Thẻ học bạn muốn đổi`,
  ],
  select_more_than_zero: [
    `수업권 종류 및 0개 이상의 갯수를 선택해주세요`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.select_more_than_zero',
    `请选择课程券种类及1张以上的课程券`,
    `請選擇課程券種類及1張以上`,
    `レッスンチケットの種類および1枚以上の枚数を選択してください。`,
    `Vui lòng chọn ít nhất một thẻ học bạn muốn đổi.`,
  ],
  plz_agree_to_terms: [
    `변경 이용 약관 동의를 해주세요`,
    `Please agree to the exchange terms of service.`,
    's_convert_credit.plz_agree_to_terms',
    `请同意变更使用条款`,
    `請同意變更使用條款`,
    `変更利用規約に同意してください。`,
    `Vui lòng đồng ý với điều khoản quy đổi.`,
  ],
  the_change_has_been_successful: [
    `변환이 완료되었습니다. 수업권 관리 페이지에서 확인해보세요!`,
    `We have successfully exchanged your lesson credits. You can see the change under My Account.`,
    's_convert_credit.the_change_has_been_successful',
    `已完成变换。请在课程券管理页面进行确认!`,
    `轉換成功，請於課程券管理頁面確認！`,
    `変更が完了しました。レッスンチケット管理ページで確認してみましょう！`,
    `Bạn đã quy đổi thẻ học thành công. Bạn có thể xem thay đổi dưới phần Tài khoản của tôi.`,
  ],
  u_cannot_change_to_the_same_type: [
    `같은 타입으로 변경이 불가능합니다.`,
    `You cannot exchange to the same lesson type.`,
    's_convert_credit.u_cannot_change_to_the_same_type',
    `不能变更为相同类型。`,
    `無法變更為相同類型。`,
    `同じタイプに変更することはできません。`,
    `Bạn không thể đổi sang buổi học có cùng hình thức.`,
  ],
  u_cannot_change_to_the_same_target: [
    `같은 시간으로 변경이 불가능합니다.`,
    `You cannot exchange to the same duration.`,
    's_convert_credit.u_cannot_change_to_the_same_target',
    `不能变更为相同时间。`,
    `無法變更為相同時間。`,
    `同じ時間に変更することはできません。`,
    `Bạn không thể đổi sang buổi học có cùng thời lượng.`,
  ],
  u_cannot_change_to_the_zero_credit: [
    `변경 되는 수업권 수가 0개 입니다. 변경 할 수업권 갯수를 다시 선택해주세요.`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.u_cannot_change_to_the_zero_credit',
    `变更的课程券为0张。请重新选择要进行变更的课程券数量。`,
    `可變更的課程券為0張。請重新選擇欲變更的課程券數量。`,
    `変更できるレッスンチケット数は0枚です。変更するレッスンチケットの枚数をもう一度選択してください。`,
    `Vui lòng chọn ít nhất một thẻ học bạn muốn đổi.`,
  ],
  notice: [
    `수업권 변경 유의사항`,
    `Terms`,
    's_convert_credit.notice',
    `课程券变更的注意事项`,
    `課程券變更注意事項`,
    `レッスンチケット変更の注意事項`,
    `Điều khoản`,
  ],
  strong_1: [
    `1. 변경 할 패키지를 선택해주세요.`,
    `Select a package to exchange`,
    's_convert_credit.strong_1',
    `1. 请选择要变更的礼包。`,
    `1. 請選擇欲變更的禮包。`,
    `1. 変更するパッケージを選択してください。`,
    `Vui lòng chọn gói học muốn đổi`,
  ],
  dropdown_1: [
    `패키지를 선택해주세요`,
    `Select a package`,
    's_convert_credit.dropdown_1',
    `请选择礼包`,
    `請選擇禮包`,
    `パッケージを選択してください。`,
    `Vui lòng chọn gói học`,
  ],
  strong_2: [
    `2. 변경 할 수업권 개수를 선택해주세요`,
    `Select number of credits`,
    's_convert_credit.strong_2',
    `2. 请选择要变更的课程券数量`,
    `2. 請選擇欲變更的課程券數量。`,
    `2. 変更するレッスンチケットの枚数を選択してください。`,
    `Vui lòng chọn số thẻ học`,
  ],
  strong_3: [
    `3. 변경 할 수업권 타입을 선택해주세요`,
    `Select credit type`,
    's_convert_credit.strong_3',
    `3. 请选择要变更的课程券类型`,
    `3. 請選擇欲變更的課程券類型。`,
    `3. 変更するレッスンチケットのタイプを選択してください。`,
    `Vui lòng chọn loại thẻ học`,
  ],
  dropdown_3: [
    `변경할 패키지`,
    `Package to be converted`,
    's_convert_credit.dropdown_3',
    `要变更的礼包`,
    `欲變更的禮包`,
    `変更するパッケージ`,
    `Gói học chuẩn bị chuyển đổi`,
  ],
  confirm_convert: [
    `변경 수업권 확인`,
    `Check the exchanged credit`,
    's_convert_credit.confirm_convert',
    `确认变更的课程券`,
    `確認變更的課程券`,
    `変更レッスンチケット確認`,
    `Kiểm tra thẻ học đã quy đổi`,
  ],
  count: [`개`, `Credits`, 's_convert_credit.count', `张`, `張`, `枚`, `Thẻ học`],
  point: [`포인트`, `Points`, 's_convert_credit.point', `积分`, `點數`, `ポイント`, `Điểm`],
  no_revert: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_convert_credit.no_revert',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  understood: [
    `이를 숙지 및 동의하였고, 변경을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to exchange my credits.`,
    's_convert_credit.understood',
    `已了解以上内容并同意, 进行变更。`,
    `已清楚並同意內容，進行變更。`,
    `これについて熟知および同意し、変更を実行します。`,
    `Tôi hiểu và đồng ý với điều này, tôi muốn đổi thẻ học.`,
  ],
  convert: [`변경 진행`, `Exchange`, 's_convert_credit.convert', `进行变更`, `進行變更`, `変更実行`, `Quy đổi`],
  list_1: [
    `유료로 구매한 수업권만 수업권 변경 가능합니다. (예외: 기업맞춤 수업권, 시간한정 수업권 변경 불가)`,
    `Exchanges between lesson credits is only available for paid credits. (but not for B2B partner credits and limited window credits.)`,
    's_convert_credit.list_1',
    `只有付费购买的课程券才能变更。（例外情况：企业定制授课程券、限时课程券不可变更。）`,
    `只有付費購買的課程券才能變更。（例外情況：企業定製授課程券、限時課程券不可變更。）`,
    `有料で購入したレッスンチケットのみレッスンチケットの変更が可能です。（例外：企業オーダーメイドレッスンチケット、時間限定レッスンチケットは変更不可）`,
    `Bạn chỉ có thể thay đổi thẻ học đã mua. (Ngoại trừ: Thẻ học dành cho doanh nghiệp, thẻ học giới hạn về thời gian)`,
  ],
  list_2: [
    <Span danger>수업권 변경은 패키지 당 1회만 가능하며, 변경한 수업권은 원상복구 불가합니다.</Span>,
    <Span danger>Credit exchange is available only once per package and cannot be reversed.</Span>,
    's_convert_credit.list_2',
    <Span danger>课程券只限每套餐变更一次，变更后的课程券无法恢复。</Span>,
    <Span danger>課程券只限每套餐變更一次，變更後的課程券無法恢復。</Span>,
    <Span danger>
      レッスンチケットの変更はパッケージにつき1回まで変更可能であり、変更したレッスンチケットは元に戻すことができません。
    </Span>,
    <Span danger>
      {' '}
      Bạn chỉ có thể thay đổi thẻ học một lần cho mỗi Gói, thẻ học đã thay đổi sẽ không thể phục hồi lại.
    </Span>,
  ],
  list_3: [
    <Span danger>변경된 수업권은 환불 불가합니다.</Span>,
    <Span danger>No refund is available for exchanged credits.</Span>,
    's_convert_credit.list_3',
    <Span danger>变更后的课程券不可退款。</Span>,
    <Span danger>變更後的課程券不可退款。</Span>,
    <Span danger>変更したレッスンチケットは返金を承ることができません。</Span>,
    <Span danger>Không thể hoàn tiền đối với thẻ học đã thay đổi.</Span>,
  ],
  list_4: [
    <Span danger>수업권 변경 이력이 있는 수업권은 증명서 발급이 불가합니다.</Span>,
    <Span danger>Certificates cannot be issued for exchanged credits.</Span>,
    's_convert_credit.list_4',
    <Span danger>变更过课程券不可开具证明书。</Span>,
    <Span danger>變更過課程券不可開具證明書。</Span>,
    <Span danger>変更履歴のあるレッスンチケットは証明書の発行ができません。</Span>,
    <Span danger>Không thể cấp chứng nhận đối với thẻ học đã có lịch sử thay đổi.</Span>,
  ],
  list_5: [
    `증명서 발급 이력이 있는 수업권은 수업권 변경이 불가합니다.`,
    `Credit exchange is not available for credits for which certificate(s) have been issued in the past.`,
    's_convert_credit.list_5',
    `开具过证明书的课程券不可变更。`,
    `開具過證明書的課程券不可變更。`,
    `証明書の発行履歴のあるレッスンチケットは変更ができません。`,
    `Thẻ học đã cấp chứng nhận sẽ không thể thay đổi.`,
  ],
  complete_converted: [
    `이관이 완료되었습니다. 수업권/증명서류 페이지에서 확인해보세요`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    's_convert_credit.complete_converted',
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
  ],
  list_6: [
    `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다. (수업권 변경 전 수업 길이와 동일한 당일수업권 제공 | 수강기간: 1년)`,
    `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once, instead of issuing one Last-minute Credit after completing four lessons. (All Last-minute Credits will expire after one year from issuance.)`,
    's_convert_credit.list_6',
    `使用长期券优惠获取的当日课程券在变更时统一提供。（提供与变更前课程券时间长短相同的当日课程券。/授课时间：1年）`,
    `使用長期券優惠獲取的當日課程券在變更時統一提供。（提供與變更前課程券時間長短相同的當日課程券。/授課時間：1年）`,
    `長期チケットの特典として提供される当日レッスンチケットは変更時に一括提供されます。（レッスンチケット変更前のレッスンと同じ長さの当日レッスンチケットを提供 | 受講期間：1年）`,
    `Thẻ học trong ngày được cấp dưới dạng ưu đãi của thẻ học định kỳ sẽ được cấp đồng thời khi thay đổi thẻ học.
(Chiều dài của thẻ học trong ngày được cấp sẽ tương ứng với độ dài của thẻ học trước khi thay đổi)`,
  ],
  popup: {
    title: [
      `유의사항`,
      `Terms`,
      's_convert_credit.popup.title',
      `课程券变更的注意事项`,
      `課程券變更注意事項`,
      `レッスンチケット変更の注意事項`,
      `Điều khoản`,
    ],
    content_1: [
      `변경된 수업권은 원상복구 불가합니다.`,
      `Credit exchange is available only once per package and cannot be reversed.`,
      's_convert_credit.popup.content_1',
      `课程券只限每套餐变更一次，变更后的课程券无法恢复。`,
      `課程券只限每套餐變更一次，變更後的課程券無法恢復。`,
      `レッスンチケットの変更はパッケージにつき1回まで変更可能であり、変更したレッスンチケットは元に戻すことができません。`,
      `Bạn chỉ có thể thay đổi thẻ học một lần cho mỗi Gói, thẻ học đã thay đổi sẽ không thể phục hồi lại.`,
    ],
    content_2: [
      `수업권 변경 후 증명서 발급이 불가합니다.`,
      `Once exchanged, the credit(s) will not be eligible for certificate issuance.`,
      's_convert_credit.popup.content_2',
      `课程券变更后不可开具证明书。`,
      `課程券變更後不可開具證明書。`,
      `レッスンチケット変更後は証明書の発行ができません。`,
      `Không thể cấp chứng nhận sau khi thay đổi thẻ học.`,
    ],
    content_3: [
      `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다.`,
      `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once.`,
      's_convert_credit.popup.content_3',
      `使用长期券优惠获取的当日课程券在变更时统一提供。`,
      `使用長期券優惠獲取的當日課程券在變更時統一提供。`,
      `長期チケットの特典として提供される当日レッスンチケットは変更時に一括提供されます。`,
      `Thẻ học trong ngày được cấp dưới dạng ưu đãi của thẻ học định kỳ sẽ được cấp đồng thời khi thay đổi thẻ học.`,
    ],
    cancel: [`취소`, `Cancel`, 's_convert_credit.popup.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
    confirm: [`확인`, `Confirm`, 's_convert_credit.popup.confirm', `确认`, `確定`, `確認`, `Xác nhận`],
  },
}
export const s_materials = {
  title: [`교재`, `Lesson Materials`, 's_materials.title', `教材`, `教材`, `教材`, `Tài liệu học`],
  search_placeholder: [
    `원하시는 교재의 키워드를 입력해주세요. ex) Economy`,
    `Search for lesson materials ex) Economy`,
    's_materials.search_placeholder',
    `请输入您希望学习的教材关键词。ex) Economy`,
    `請輸入您希望的教材的關鍵字。ex) Economy`,
    `好きな教材のキーワードを入力してください。 ex) Economy`,
    `Tìm kiếm tài liệu buổi học. Ví dụ: Economy`,
  ],
  material_download: [`PDF`, `PDF`, 's_materials.material_download', `PDF`, `PDF`, `PDF`, `PDF`],
  view_material: [`교재 보기`, `View`, 's_materials.view_material', `查看教材`, `查看教材`, `教材を見る`, `Xem`],
  select_material_you_want_to_change: [
    `변경할 수업 교재를 선택해 주세요.`,
    `Select the lesson material you want to change.`,
    's_materials.select_material_you_want_to_change',
    `请选择要变等的课程教材。`,
    `請選擇欲變更的課程教材。`,
    `変更するレッスン教材を選択してください。`,
    `Chọn tài liệu học bạn muốn đổi.`,
  ],
  feedback_not_provided: [
    `* 튜터의 Feedback Report 미제공`,
    `* This option does not provide tutor's feedback report`,
    's_materials.feedback_not_provided',
    `*未提供教师的Feedback Report`,
    `* 未提供任課教師的Feedback Report`,
    `* チューターのFeedback Report未提供`,
    `* Lựa chọn này sẽ không cung cấp báo cáo đánh giá từ gia sư`,
  ],
  no_result: [
    `키워드에 맞는 검색 결과가 없습니다.`,
    `No Result`,
    's_materials.no_result',
    `未找到相关搜索结果`,
    `無符合關鍵字的搜尋結果。`,
    `キーワードに一致する検索結果はありません。`,
    `Không có kết quả`,
  ],
  search_result: [
    `교재 검색 결과`,
    `Search Results`,
    's_materials.search_result',
    `教材搜索结果`,
    `教材搜尋結果`,
    `教材内検索結果`,
    `Kết quả tìm kiếm`,
  ],
  insight: [`인사이트`, `Insight`, 's_materials.insight', `正文`, `인사이트`, `インサイト`, `Insight`],
  clip_article: [
    `영상 / 기사`,
    `Video Clip / Article`,
    's_materials.clip_article',
    `视频 / 新闻报道`,
    `影片／報導`,
    `動画 / 記事`,
    `Video / Bài báo`,
  ],
  key_questions: [
    `수업 질문`,
    `Questions`,
    's_materials.key_questions',
    `课程问题`,
    `課程提問`,
    `レッスンの質問`,
    `Câu hỏi`,
  ],
  expressions: [
    `핵심 표현`,
    `Expressions`,
    's_materials.expressions',
    `核心表达`,
    `核心用法`,
    `キーフレーズ`,
    `Mẫu câu`,
  ],
  does_not_include_video_article: [
    `해당 교재는 영상 및 기사가 제공되지 않습니다.`,
    `This lesson material does not include a video or an article.`,
    's_materials.does_not_include_video_article',
    `该教材不提供视频及新闻报道。`,
    `該教材未提供影片及報導。`,
    `この教材は動画および記事が提供されません。`,
    `Học liệu này không có video hoặc bài báo.`,
  ],
  new: [`새 교재`, `New material`, 's_materials.new', `新教材`, `新教材`, `新しい教材`, `Tài liệu mới`],
  like: [`찜하기`, `Save`, 's_materials.like', `收藏`, `收藏`, `お気に入り登録`, `Lưu`],
  categoryDescription_1: [
    <>
      <ul className="pl-4">
        <li>20분 수업용으로 좋습니다.</li>
        <li>영어 초중급 - 토플 iBT 19-24점, OPIC IL 이상, 토익스피킹 5-6점 - 인 분들께 추천해 드립니다.</li>
        <li>예습은 30분 이내면 충분합니다.</li>
        <li>교정 위주 수업 보다는 대화 중심 수업에 적합합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 20-minute lessons</li>
      <li>Best for beginner-intermediate level speakers (TOEFL iBT 19-24, OPIC IL and up, TOEIC Speaking 5-6)</li>
      <li>Lesson Prep takes less than 30 minutes</li>
      <li>Best for discussion-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_1',
    <>
      <ul className="pl-4">
        <li>推荐20分钟的课程。</li>
        <li>英语初中级 - 托福iBT19-24分, OPIC IL以上, 托业口语5-6分 - 推荐给以上人群。</li>
        <li>预习时间30分钟以内。</li>
        <li>比起纠正为主的课程形式, 更适用于侧重会话。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>適合20分鐘課程。</li>
        <li>推薦給英語初中級 - 托福iBT 19～24分、OPIC IL以上、多益口說5～6分的人</li>
        <li>預習不必30分鐘。</li>
        <li>比起以糾正為主的課程，更適合以對話為主的課程。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>20分レッスン用におすすめです。</li>
        <li>英語初中級 - TOEFL iBT 19-24点、OPIC IL以上、TOEIC SPEAKING 5-6点 - の 方におすすめです。</li>
        <li>予習は30分以内で十分です。</li>
        <li>校正中心レッスンよりは会話中心レッスンに適しています。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Phù hợp nhất cho buổi học 20 phút</li>
      <li>Phù hợp cho trình độ nói sơ và trung cấp (TOEFL iBT 19-24, OPIC IL trở lên, TOEIC Speaking 5-6)</li>
      <li>Chuẩn bị buổi học chưa tới 30 phút</li>
      <li>Phù hợp nhất cho buổi học tập trung thảo luận</li>
    </ul>,
  ],
  categoryDescription_2: [
    <>
      <ul className="pl-4">
        <li>40분 수업용으로 좋습니다.</li>
        <li>영어 중급 이상 - 토플 iBT +25점, OPIC IH 이상, 토익스피킹 +7점 - 분들께 추천해 드립니다.</li>
        <li>30분 이상 예습이 필요합니다.</li>
        <li>비즈니스 토론, 심층 교정 모두 가능합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 40-minute lessons</li>
      <li>Best for advanced speakers (TOFLE iBT 25+, OPIC IH and up, TOEIC Speaking 7+)</li>
      <li>Lesson Prep takes 30 minutes or more</li>
      <li>Good for both discussion and correction-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_2',
    <>
      <ul className="pl-4">
        <li>推荐40分钟的课程。</li>
        <li>英语中级以上 - 托福 iBT+25分, OPIC IH以上, 托业口语+7分 - 推荐给以上人群。</li>
        <li>需要30分钟以上的预习。</li>
        <li>商业讨论, 深度纠正均可进行。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>適合40分鐘課程。</li>
        <li>推薦給英語中級以上 - 托福 iBT +25分、OPIC IH以上、多益口說+7分的人</li>
        <li>需要30分鐘以上的預習。</li>
        <li>商務討論、深度糾正均可。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>40分レッスン用におすすめです。</li>
        <li>英語中級以上 - TOEFL iBT +25点、OPIC IH以上、TOEIC SPEAKING +7点 - の 方におすすめです。</li>
        <li>30分以上の予習が必要です。</li>
        <li>ビジネスディスカッション、詳細校正すべて可能です。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Phù hợp nhất cho buổi học 40 phút</li>
      <li>Phù hợp với trình độ nâng cao (TOFLE iBT 25+, OPIC IH và hơn, TOEIC Speaking 7+)</li>
      <li>Chuẩn bị cho buổi học cần 30 phút trở lên</li>
      <li>Phù hợp cho cả buổi học tập trung thảo luận và tập trung sửa lỗi</li>
    </ul>,
  ],
  categoryDescription_3: [
    <>
      <ul className="pl-4">
        <li>뉴욕타임즈에서 학생들을 위해 특별히 만든 교재입니다.</li>
        <li>초등 4학년 ~ 중등 3학년에게 추천해드립니다.</li>
        <li>20분, 40분 수업 모두 사용하기에 적합합니다.</li>
        <li>시사/과학 관련 토론수업을 원하는 성인 분들께도 추천합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Designed for teens & junior users</li>
      <li>Best for 4th-9th graders</li>
      <li>Good for adult discussions on current events</li>
      <li>Good for both 20 and 40-minute lessons</li>
    </ul>,
    's_materials.categoryDescription_3',
    <>
      <ul className="pl-4">
        <li>这是纽约时报专为学生们编写的教材。</li>
        <li>推荐给小学4年级 ~ 初中3年级。</li>
        <li>20分钟, 40分钟课程全部适用。</li>
        <li>同时也推荐给对时事/科学内容感兴趣的成人学员。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>紐約時報為學生們特別編寫的教材。</li>
        <li>推薦給小學4年級～國中3年級。</li>
        <li>20分鐘、40分鐘課程均適用。</li>
        <li>也推薦給希望進行時事／科學相關討論課程的成人學生。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>ニューヨークタイムズが学生のために特別に作った教材です。</li>
        <li>小学4年生 ~ 中学3年生におすすめです。</li>
        <li>20分、40分レッスン両方の使用に適しています。</li>
        <li>時事/科学関連ディスカッションレッスンをご希望の成人の方にもおすすめです。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Thiết kế riêng cho học viên tuổi teen</li>
      <li>Phù hợp cho học sinh lớp 4-lớp 9</li>
      <li>Phù hợp cho các buổi thảo luận về các sự kiện thời sự</li>
      <li>Phù hợp cho cả buổi học 20 và 40 phút</li>
    </ul>,
  ],
  categoryDescription_4: [
    <>
      <ul className="pl-4">
        <li>내가 올린 자료 - pdf, ppt, docs 형식 문서 가능 - 를 튜터와 함께 보며 수업할 수 있습니다.</li>
        <li>이메일 교정, 프레젠테이션 발표연습, 인터뷰 연습 등을 할 수 있습니다.</li>
        <li>문서 교정 위주의 수업은 40분이 적합합니다.</li>
        <li>자유주제 수업 관련 도움이 필요하시면 1:1 문의로 연락하실 수 있습니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>You can upload your own lesson materials for your lesson (PDF, ppt, docs formats).</li>
      <li>You can get corrections on your writing piece, practice presentations, or prepare for an interview.</li>
      <li>If you want to get edits for your document, we recommend you to take a 40-minute lesson.</li>
      <li>If you have any questions, please contact us!</li>
    </ul>,
    's_materials.categoryDescription_4',
    <>
      <ul className="pl-4">
        <li>我上传的资料 - 支持pdf, ppt, docs格式 - 可与教师们一起 开展针对性课程。</li>
        <li>可进行邮件校对, 练习发表报告及面试等多种形式。</li>
        <li>以文本校正为主的课程更适合40分钟。</li>
        <li>如需有关自由主题课程的帮助, 请进行在线咨询。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>可與任課教師一起觀看我上傳的資料 - 支援pdf、ppt、docs格式的文件 - 並上課。</li>
        <li>可糾正電子郵件、練習報告發表、練習面試等。</li>
        <li>以糾正文件為主的課程適合40分鐘課程。</li>
        <li>如需有關自由主題課程相關協助，您可透過聊天聯絡我們。</li>
      </ul>
    </>,
    <>
      <ul className="pl-4">
        <li>
          あなたがアップロードした資料 - pdf、ppt、docs形式の文書可能 - をチューターと一緒に見ながら
          レッスンを受けることができます。
        </li>
        <li>メール校正、プレゼンテーション発表練習、面接練習などをすることができます。</li>
        <li>文書校正中心のレッスンは40分が適しています。</li>
        <li>自由テーマレッスンに関してヘルプが必要な場合は、チャネルトークでご連絡ください。</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Bạn có thể tải lên học liệu của riêng bạn (PDF, ppt, docs formats).</li>
      <li>Bạn sẽ được chỉnh sửa bài viết, thực hành thuyết trình và chuẩn bị cho buổi phỏng vấn.</li>
      <li>Nếu bạn cần chỉnh sửa tài liệu, chúng tôi khuyến khích bạn đặt buổi học 40 phút.</li>
      <li>Nếu bạn có câu hỏi, hãy liên hệ với chúng tôi!</li>
    </ul>,
  ],
  speaking_focused_guide_title: [
    <>
      모의 인터뷰, 프레젠테이션 연습, 자유주제 말하기 연습 등 원하는 주제로 수업할 수 있습니다.
      <br />
      준비한 자료를 수업에서 튜터와 함께 보고 목적에 맞는 수업을 진행해 보세요.
    </>,
    <>
      You can take a lesson on any topic through the Free Speaking lesson by uploading the relevant documents, such as
      mock interviews, practicing for presentations, etc. <br /> Students can upload their own resume, presentation,
      etc. before the lesson and then tutors will go through the material with students during the lesson and cover the
      topics for the lesson style you have selected.
    </>,
    's_materials.speaking_focused_guide_title',
    <>
      模拟面试, 报告发表练习, 自由主题会话练习等任何希望的内容 均可放入课程之中。
      <br />
      将准备好的资料于课程中使用, 和教师一起开展针对性的高效课程吧!
    </>,
    <>
      能以模擬面試、報告練習、自由主題口說練習等希望的主題上課。
      <br />
      請與任課教師一起觀看您準備的資料，進行符合目的的課程。
    </>,
    <>
      模擬面接、プレゼンテーション練習、自由テーマのスピーキング練習などご希望のテーマで
      レッスンを受けることができます。
      <br />
      準備した資料をレッスンでチューターと一緒に見ることで、目的に合ったレッスンを行うことができます。
    </>,
    <>
      Bạn có thể chọn bất kỳ chủ đề nào trong buổi học nói tự do bằng việc tải lên những tài liệu liên quan, như buổi
      phỏng vấn thử, thực hành thuyết trình,.... <br /> Học viên có thể tải lên resume, bài thuyết trình,... trước buổi
      học và gia sư sẽ cùng học viên xem qua các tài liệu và nói về các chủ đề theo kiểu học đã được bạn chọn.
    </>,
  ],
  speaking_focused_guide_text1: [
    <>[Speaking Focused 수업 유의사항]</>,
    <>[Please note for Speaking Focused Lesson]</>,
    's_materials.speaking_focused_guide_text1',
    <>[Speaking Focused课程注意事项]</>,
    <>[Speaking Focused課程注意事項]</>,
    <>[Speaking Focusedレッスンの注意事項]</>,
    <>[Lưu ý cho buổi tập trung luyện nói]</>,
  ],
  speaking_focused_guide_text2: [
    <>
      1) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      2) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다. <br />
      3) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      4) 수업 중 영문 교정을 위해 개인 자료를 업로드 시, 20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장
      분량) 분량을 권장 드립니다.
    </>,
    <>
      1) After scheduling the lesson, you can upload the material on the upcoming lesson page.
      <br />
      2) It is recommended to upload your document at least 24 hours before the lesson starts.
      <br />
      3) Writing corrections will be conducted live during the lesson.
      <br />
      4) When you upload your own material to be edited during your Free Topic lesson, please limit the word count to
      500 (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
    </>,
    's_materials.speaking_focused_guide_text2',
    <>
      1) 完成课程预约后, 可在预约课程的页面上传您的资料。
      <br />
      2) 建议最晚于课程开始前24小时完成资料上传。
      <br />
      3) 文本校正可在课程中随堂进行。
    </>,
    <>
      1) 預約課程後，可於預定的課程頁面上傳資料。
      <br />
      2) 建議最晚於課程開始24小時前上傳資料。
      <br />
      3) 將於上課時間即時糾正文件。
    </>,
    <>
      1) レッスン予約後、レッスンページから資料をアップロード できます。
      <br />
      2) レッスン開始24時間前までに文書をアップロードすることを推奨します。 <br />
      3) 文書校正はレッスン時間にリアルタイムで行われます。 <br />
      4)
      レッスン中の英文添削のため個人の材料をアップロードする場合、20分レッスンは500字（A41枚）、40分レッスンは1000字（A42枚）の分量をお勧めします。
    </>,
    <>
      1) Sau khi đặt lịch học, bạn có thể tải lên tài liệu học trên trang tiếp theo của buổi học.
      <br />
      2) Chúng tôi khuyến khích tải tài liệu lên tối thiểu 24 giờ trước khi buổi học bắt đầu.
      <br />
      3) Chỉnh lỗi bài viết sẽ diễn ra trong quá trình học.
      <br />
      4) Sau khi tải tài liệu của bạn để sử dụng trong buổi học Chủ đề tự do, lưu ý về số lượng từ trong tài liệu: 500
      từ (khoảng 1 trang A4) với buổi học 20 phút và 1,000 từ (2 tờ A4) cho buổi học 40 phút.
    </>,
  ],
  writing_focused_guide_title: [
    <>
      직접 작성한 에세이, 이력서, 이메일 등 영문서를 업로드하고 수업에서 교정 받을 수 있습니다.
      <br />
      업로드한 자료를 수업에서 튜터와 함께 보고 상황과 문맥에 맞는 문장으로 교정해 보세요.
    </>,
    <>
      Students can upload their own essay, resume, e-mail, etc. before the lesson and then tutors will go through the
      material with students during the lesson and provide appropriate corrections.
    </>,
    's_materials.writing_focused_guide_title',
    <>
      上传自己书写的短文, 简历, 电子邮件等英文书面文本, 可在课程中获得校对反馈。
      <br />
      将准备好的资料于课程中使用, 和教师一起开展针对自身情况及文脉的纠正学习吧。
    </>,
    <>
      您可上傳親自撰寫的申論、簡歷、電子郵件等英文文件，在上課時接受糾正。
      <br />
      上課時請與任課教師一起觀看您上傳的資料，糾正為符合狀況與文脈的句子。
    </>,
    <>
      直接作成したエッセイ、履歴書、メールなどの英文書をアップロードして、レッスンで校正 してもらうことができます。
      <br />
      アップロードした資料をレッスンでチューターと一緒に見て、状況や文脈に合う文章に 校正してみましょう。
    </>,
    <>
      Học viên có thể tải lên bài luận, resume, email,... của chính họ trước buổi học và gia sư sẽ hướng dẫn học viên
      dựa trên những tài liệu này và cung cấp những điều chỉnh thích hợp.
    </>,
  ],
  writing_focused_guide_text_1: [
    <>[Writing Focused 수업 유의사항]</>,
    <>[Please note for Writing Focused Lesson] </>,
    's_materials.writing_focused_guide_text_1',
    <>[Writing Focused课程注意事项]</>,
    <>[Writing Focused課程注意事項]</>,
    <>[Writing Focusedレッスンの注意事項]</>,
    <>[Lưu ý cho buổi tập trung viết] </>,
  ],
  writing_focused_guide_text_2: [
    <>
      1) Writing 위주의 수업으로&nbsp; 튜터 Feedback Report는 제공되지 않습니다.
      <br />
      2) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      3) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다.
      <br />
      4) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      5) 수업 중 영문 교정을 위해 개인 자료를 업로드 시, 20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장
      분량) 분량을 권장 드립니다.
    </>,
    <>
      1) Feedback reports are not available for Writing Focused lesson. upcoming lesson page.
      <br />
      2) After scheduling the lesson, you can upload the material on the lesson starts.
      <br />
      3) It is recommended to upload your document at least 24 hours before the
      <br />
      4) Writing corrections will be conducted live during the lesson.
      <br />
      5) When you upload your own material to be edited during your Free Topic lesson, please limit the word count to
      500 (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
    </>,
    's_materials.writing_focused_guide_text_2',
    <>
      1) 完成课程预约后, 可在预约课程的页面上传您的资料。
      <br />
      2) 建议最晚于课程开始前24小时完成资料上传。
      <br />
      3) 文本校正可在课程中随堂进行。
      <br />
      4) Writing为主的课程, &nbsp; 教师将不提供Feedback Report。
    </>,
    <>
      1) 預約課程後，可於預定的課程頁面上傳資料。
      <br />
      2) 建議最晚於課程開始24小時前上傳資料。
      <br />
      3) 將於上課時間即時糾正文件。
      <br />
      4) 以Writing為主的課程&nbsp; 將不提供任課教師的Feedback Report。
    </>,
    <>
      1) レッスン予約後、レッスン予定ページから資料をアップロードすることが できます。
      <br />
      2) レッスン開始24時間前までに文書をアップロードすることを推奨します。
      <br />
      3) 文書校正はレッスン時間にリアルタイムで行われます。
      <br />
      4) Writing中心のレッスンでは&nbsp; チューターのFeedback Reportは提供されません。 <br />
      5)
      レッスン中の英文添削のため個人の材料をアップロードする場合、20分レッスンは500字（A41枚）、40分レッスンは1000字（A42枚）の分量をお勧めします。
    </>,
    <>
      1) Sau khi đặt lịch học, bạn có thể tải lên tài liệu học trên trang tiếp theo của buổi học.
      <br />
      2) Chúng tôi khuyến khích tải tài liệu lên tối thiểu 24 giờ trước khi buổi học bắt đầu.
      <br />
      3) Chỉnh lỗi bài viết sẽ diễn ra trong quá trình học.
      <br />
      4) Báo cáo đánh giá sẽ không có trong buổi tập trung viết.
    </>,
  ],
  search_material: [
    `교재 검색`,
    `Search`,
    's_materials.search_material',
    `教材搜索`,
    `教材搜索`,
    `教材を検索`,
    `Tìm tài liệu`,
  ],
  search_results: [
    `교재 검색 결과`,
    `Search Results`,
    's_materials.search_results',
    `教材搜寻结果`,
    `教材搜尋結果`,
    `教材検索結果`,
    `Kết quả tìm kiếm`,
  ],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_materials.login_desc',
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
    `Sign in to access all of Ringle's content for free.`,
  ],
  login: [`로그인`, `Log in`, 's_materials.login', `Log in`, `Log in`, `Log in`, `Log in`],
  more_from_ringle: [
    `링글 관련 콘텐츠`,
    `More from Ringle`,
    's_materials.more_from_ringle',
    `More from Ringle`,
    `More from Ringle`,
    `More from Ringle`,
    `More from Ringle`,
  ],
  download_material: [
    `다운로드하기`,
    `Download Materials`,
    's_materials.download_material',
    `Download Materials`,
    `Download Materials`,
    `ダウンロードをする`,
    `Tải tài liệu`,
  ],
  notice_desc: [
    <>* 라이팅 중심 수업은 튜터 피드백 리포트가 제공되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.notice_desc',
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
  ],
  banner_title: [
    <>Writing-focused 수업은 튜터 Feedback Report가 제출되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.banner_title',
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
  ],
  copyright_desc1: [`찜하기`, `Save`, 's_materials.copyright_desc1', `收藏`, `收藏`, `お気に入り登録`, `Lưu`],
  action_title: [
    `지금 보신 교재가 마음에 드셨다면?`,
    `Like this material?`,
    's_materials.action_title',
    `Like this material?`,
    `Like this material?`,
    `Like this material?`,
    `Like this material?`,
  ],
  action_subtitle: [
    `이 교재로 수업해 보세요.`,
    `Use this material for your next lesson.`,
    's_materials.action_subtitle',
    `Use this material for your next lesson.`,
    `Use this material for your next lesson.`,
    `Use this material for your next lesson.`,
    `Use this material for your next lesson.`,
  ],
  btn1: [
    `수업 예약하기`,
    `Book lessons`,
    's_materials.btn1',
    `Book lessons`,
    `Book lessons`,
    `Book lessons`,
    `Book lessons`,
  ],
}
export const s_upcoming_lessons = {
  to_do_list: [
    `예습 목록`,
    `Lesson Prep List`,
    's_upcoming_lessons.to_do_list',
    `预习目录`,
    `預習清單`,
    `予習リスト`,
    `Danh sách Chuẩn bị Buổi học`,
  ],
  not_confirmed: [
    `확정 대기중`,
    `Not Confirmed`,
    's_upcoming_lessons.not_confirmed',
    `等待确定中`,
    `等待確定中`,
    `確定待ち`,
    `Chưa xác nhận`,
  ],
  confirmed: [
    `확정 완료`,
    `Confirmed`,
    's_upcoming_lessons.confirmed',
    `确定完成`,
    `已確定`,
    `確定済み`,
    `Đã xác nhận`,
  ],
  compatible_tutor: [
    `고객님께 적합한 튜터를 매칭 중입니다.`,
    `We are finding the best tutor for you.`,
    's_upcoming_lessons.compatible_tutor',
    `正在为您寻找匹配的教师。`,
    `正在為您配對適合的任課教師。`,
    `お客様に適したチューターをマッチング中です。`,
    `Chúng tôi đang tìm gia sư tốt nhất cho bạn.`,
  ],
  option_not_change: [
    `튜터가 확정되어 옵션 변경이 불가능합니다.`,
    `Option cannot be changed since the lesson has a tutor assigned with confirmation.`,
    's_upcoming_lessons.option_not_change',
    `已确定教师, 无法更改选项。`,
    `已確定任課教師，無法變更選項。`,
    `チューターが確定したため、オプション変更は不可能です。`,
    `Lựa chọn không thể thay đổi vì buổi học đã có gia sư xác nhận.`,
  ],
  option_not_change_auto: [
    `자동매칭은 옵션 변경이 불가능합니다.`,
    `Option cannot be changed with auto tutor matching`,
    's_upcoming_lessons.option_not_change_auto',
    `自动匹配无法更改选项。`,
    `自動配對無法變更選項。`,
    `自動マッチングはオプション変更が不可能です。`,
    `Lựa chọn không thể thay đổi khi sử dụng sắp xếp gia sư ngẫu nhiên`,
  ],
  matching_after: {
    auto: [
      `자동으로 매칭해주세요`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.matching_after.auto',
      `请自动进行匹配`,
      `請自動為我配對`,
      `自動的にマッチングしてください。`,
      `Xếp ngẫu nhiên tôi với một gia sư khác.`,
    ],
    credit: [
      `수업을 취소하고 수업권을 복구해주세요`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.matching_after.credit',
      `请取消课程, 恢复课程券`,
      `請取消課程並為我復原課程券`,
      `レッスンをキャンセルしてレッスンチケットを復元してください。`,
      `Huỷ và khôi phục các thẻ học của tôi.`,
    ],
  },
  options: {
    option: {
      title: [
        `튜터 매칭 옵션 변경`,
        `In case of tutor cancellation`,
        's_upcoming_lessons.options.option.title',
        `变更教师匹配选项`,
        `變更任課教師配對選項`,
        `チューターマッチングオプション変更`,
        `Trong trường hợp gia sư huỷ`,
      ],
      description: [
        `선택한 튜터와 수업이 이뤄지지 않을 때 처리 방법을 설정합니다.`,
        `Please select how you'd like to resolve cancellation by your tutor.`,
        's_upcoming_lessons.options.option.description',
        `请设置与选择的教师无法进行课程时的处理方式。`,
        `設定我選擇的任課教師與課程未搭配成功時的處理方式。`,
        `選択したチューターとレッスンが行われない時の処理方法を設定します。`,
        `Vui lòng lựa chọn hướng giải quyết cho buổi học bị huỷ bởi gia sư.`,
      ],
    },
    time: {
      title: [
        `수업시간 변경`,
        `Reschedule Lesson`,
        's_upcoming_lessons.options.time.title',
        `课程时间变更`,
        `變更課程時間`,
        `レッスン時間変更`,
        `Dời lịch Buổi học`,
      ],
      description: [
        `수업이 확정되기 전까지만 가능합니다.`,
        `Lesson time can only be changed before the lesson is confirmed by tutor`,
        's_upcoming_lessons.options.time.description',
        `仅可于课程确定前进行。`,
        `僅可於課程確定之前進行。`,
        `レッスンが確定する前まで可能です。`,
        `Giờ học chỉ có thể thay đổi trước khi gia sư xác nhận buổi học`,
      ],
    },
    course: {
      title: [
        `교재 변경`,
        `Change Materials`,
        's_upcoming_lessons.options.course.title',
        `教材变更`,
        `變更教材`,
        `教材変更`,
        `Thay đổi Tài liệu`,
      ],
      description: [
        `수업 교재를 변경할 수 있습니다.`,
        `You can change the lesson material.`,
        's_upcoming_lessons.options.course.description',
        `可更改课程教材。`,
        `可更改課程教材。`,
        `レッスンの教材を変更することができます。`,
        `Bạn có thể thay đổi tài liệu buổi học.`,
      ],
    },
    setting: {
      title: [
        `수업 방식 설정`,
        `Set Lesson Style`,
        's_upcoming_lessons.options.setting.title',
        `课程模式设定`,
        `上課方式設定`,
        `レッスン形式設定`,
        `Chọn Hình thức học`,
      ],
      check_title: [
        `수업 방식`,
        `Lesson Style`,
        's_upcoming_lessons.options.setting.check_title',
        `课程形式`,
        `上課方式`,
        `レッスン形式`,
        `Hình thức học`,
      ],
      description: [
        `수업녹음, 수업모드 등에 대해 설정할 수 있습니다.`,
        `Set Lesson Style for audio recording and customized lesson.`,
        's_upcoming_lessons.options.setting.description',
        `可对课程录音, 课程模式等进行设置。`,
        `可設定課程錄音、課程模式。`,
        `録音設定、レッスンモードなどを設定することができます。`,
        `Chọn Hình thức học để thu âm và điều chỉnh buổi học.`,
      ],
    },
    cancel: {
      title: [
        `수업 취소`,
        `Cancel Lesson`,
        's_upcoming_lessons.options.cancel.title',
        `课程取消`,
        `取消課程`,
        `レッスンキャンセル`,
        `Huỷ buổi học`,
      ],
      description: [
        `수업 시작 24시간 이내 취소하면 수업권이 복구되지 않습니다.`,
        `Cancellations made less than 24 hours before the lesson will be non-refundable.`,
        's_upcoming_lessons.options.cancel.description',
        `课程开始前24小时内取消时, 课程券将无法恢复。`,
        `於課程開始前24小時以內取消時，將不復原課程券。`,
        `レッスン開始24時間以内のキャンセルはレッスンチケットが復元されません。`,
        `Huỷ lớp trong vòng 24 giờ trước buổi học sẽ không được hoàn lại thẻ học.`,
      ],
      description2: [
        `튜터가 배정되지 않아 취소시 수업권이 자동으로 복구됩니다.`,
        `Since a tutor hasn't been assigned, if you cancel now we'll restore your leson credit.`,
        's_upcoming_lessons.options.cancel.description2',
        `因教师无法匹配取消课程时, 课程券将自动恢复。`,
        `尚未分配任課教師，取消時將自動復原課程券。`,
        `チューターが割り当てられずキャンセルになる場合、レッスンチケットは自動的に復元します。`,
        `Vì gia sư chưa xác nhận, nếu bạn huỷ bây giờ chúng tôi sẽ hoàn lại thẻ học cho bạn.`,
      ],
    },
  },
  banner_zoom: {
    test_for_video_call: [
      `Zoom 설치 가이드`,
      `Test Zoom`,
      's_upcoming_lessons.banner_zoom.test_for_video_call',
      `Zoom安装指南`,
      `Zoom安裝指南`,
      `Zoomインストールガイド`,
      `Kiểm tra Zoom`,
    ],
  },
  no_schedule_ahead: [
    `현재 예정되어 있는 수업이 없습니다.`,
    `You have no upcoming lessons.`,
    's_upcoming_lessons.no_schedule_ahead',
    `当前没有预定的课程。`,
    `目前無預定的課程。`,
    `現在予定されているレッスンはありません。`,
    `Bạn không có buổi học nào sắp tới.`,
  ],
  go_to_registration_page: [
    `수업 예약하러 가기`,
    `Book Lessons`,
    's_upcoming_lessons.go_to_registration_page',
    `前去预约课程`,
    `前往預約課程`,
    `レッスンを予約する`,
    `Đặt lịch học`,
  ],
  lesson_enter_modal_title: [
    `수업 입장 안내`,
    `Enter Lesson`,
    's_upcoming_lessons.lesson_enter_modal_title',
    `课程入场介绍`,
    `課程進場通知`,
    `レッスン入室方法のご案内`,
    `Vào học`,
  ],
  lesson_style_btn: [
    `수업 방식 설정`,
    `Set Lesson Style`,
    's_upcoming_lessons.lesson_style_btn',
    `课程模式设定`,
    `上課方式設定`,
    `レッスン形式設定`,
    `Chọn Hình thức học`,
  ],
  change_style_btn: [
    `수업 방식 변경`,
    `Set Lesson Style`,
    's_upcoming_lessons.change_style_btn',
    `课程形式变更`,
    `變更上課方式`,
    `レッスン形式変更`,
    `Chọn Hình thức học`,
  ],
  enter_btn: [
    `수업 입장`,
    `Enter Lesson`,
    's_upcoming_lessons.enter_btn',
    `课程入场`,
    `進入課程`,
    `レッスン開始`,
    `Vào học`,
  ],
  zoom_modal: {
    title: [
      `수업 전 프로그램 설치 확인`,
      `Wait!`,
      's_upcoming_lessons.zoom_modal.title',
      `课程前确认程序安装`,
      `上課前確認安裝軟體`,
      `レッスン前にアプリのインストール確認`,
      `Đợi!`,
    ],
    wait: [`잠시만요!`, ``, 's_upcoming_lessons.zoom_modal.wait', `请稍等!`, `請稍等！`, `お待ちください！`, ``],
    have_you_installed_zoom: [
      `수업에 꼭 필요한 Zoom 프로그램 설치하셨나요?`,
      `Ringle lessons are run on Zoom. Have you installed Zoom?`,
      's_upcoming_lessons.zoom_modal.have_you_installed_zoom',
      `是否已安装课程必需的Zoom程序?`,
      `您安裝上課必備的Zoom軟體了嗎？`,
      `レッスンに必要なZoomはインストールしましたか？`,
      `Buổi học của Ringle diễn ra trên Zoom. Bạn đã cài đặt Zoom chưa?`,
    ],
    i_have_installed_zoom: [
      `이미 설치했습니다.`,
      `I have installed Zoom`,
      's_upcoming_lessons.zoom_modal.i_have_installed_zoom',
      `已经安装`,
      `已安裝。`,
      `すでにインストールしました。`,
      `Tôi đã cài đặt Zoom`,
    ],
    go_to_installation_page: [
      `설치 페이지 바로가기`,
      `I need to install Zoom`,
      's_upcoming_lessons.zoom_modal.go_to_installation_page',
      `前往安装页面`,
      `立即前往安裝頁面`,
      `インストールページに移動`,
      `Tôi cần cài đặt Zoom`,
    ],
  },
  after_tutor_cancel: {
    automatic_match_me: [
      `다른 튜터 매칭 받기`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.after_tutor_cancel.automatic_match_me',
      `接受与其他教师的匹配`,
      `接受其他任課教師配對`,
      `他のチューターとマッチングしてもらう`,
      `Xếp ngẫu nhiên tôi với một gia sư khác.`,
    ],
    plz_let_me_know_available_times: [
      <>선택한 튜터의 다른 시간 확인하기</>,
      <>Show me this tutor's availability. </>,
      's_upcoming_lessons.after_tutor_cancel.plz_let_me_know_available_times',
      <>确认所选教师的其他时间</>,
      <>確認所選的任課教師其他時間</>,
      `選択したチューターの別時間帯を確認する`,
      <>Cho tôi xem lịch trống của gia sư. </>,
    ],
    cancel_and_restore_credit: [
      `수업 취소 후 사용한 수업권 복구 받기`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.after_tutor_cancel.cancel_and_restore_credit',
      `课程取消后课程券返还`,
      `取消課程後復原已使用的課程券`,
      `予約をキャンセルし、使用したレッスンチケットを復元する`,
      `Huỷ và hoàn lại thẻ học.`,
    ],
    your_option_change_has_been_completed: [
      `변경한 내용이 저장되었습니다.`,
      `Saved`,
      's_upcoming_lessons.after_tutor_cancel.your_option_change_has_been_completed',
      `变更的内容已保存`,
      `變更的內容儲存成功。`,
      `変更した内容が保存されました。`,
      `Đã lưu`,
    ],
    lesson_canceled: [
      `수업이 취소되었습니다.`,
      `Lesson Canceled`,
      's_upcoming_lessons.after_tutor_cancel.lesson_canceled',
      `课程已取消。`,
      `已取消課程。`,
      `レッスンがキャンセルされました。`,
      `Buổi học bị huỷ`,
    ],
  },
  matching_after_options: {
    view_tutors_schedule: [
      `튜터의 다른 시간 보기`,
      `View tutor's availability`,
      's_upcoming_lessons.matching_after_options.view_tutors_schedule',
      `查看教师的其他时间`,
      `查看任課教師的其他時間`,
      `チューターの他の時間を見る`,
      `Xem lịch trống của gia sư`,
    ],
  },
  notice: {
    title: [
      `[화상프로그램 (Zoom) 오디오 연결 가이드]`,
      `[Zoom Audio Connection Guide]`,
      's_upcoming_lessons.notice.title',
      `[视频程序 (Zoom) 音频连接指南]`,
      `[視訊軟體（Zoom）音訊連接指南]`,
      `[ビデオ会議アプリ (Zoom) オーディオ接続ガイド]`,
      `[Hướng dẫn kết nối âm thanh trong Zoom]`,
    ],
    content: [
      <>
        수업이 시작되고 오디오 연결이 원활하지 않을 경우,
        <br />꼭 아래 이미지 위치에 있는 <b>오디오 연결</b> 버튼을 클릭해 주세요.
      </>,
      <>
        If you or the student can't hear one another on the Zoom meeting,
        <br />
        Please click on the <b>"Join Audio"</b> button.
      </>,
      's_upcoming_lessons.notice.content',
      <>
        课程开始后，音频功能无法使用时
        <br />
        请务必点击下方图案<b>音频连接</b>按钮。
      </>,
      <>
        課程開始後，音訊連接不順時，
        <br />
        請務必點擊下方圖片的<b>加入音訊</b>按鈕。
      </>,
      <>
        レッスンが始まりオーディオ接続が円滑ではない場合、
        <br />
        必ず下の画像の位置にある <b>オーディオに接続</b> ボタンをクリックして ください。
      </>,
      <>
        Nếu bạn hoặc học viên không nghe được người còn lại trên Zoom,
        <br />
        Vui lòng chọn nút <b>"Join Audio"</b>.
      </>,
    ],
    footer: [
      <>
        문제가 해결되지 않을 경우,
        <br />
        오른쪽 상단에 위치한 <b>[실시간 Help]</b> 버튼을 클릭하여 링글팀에 도움을 요청하세요.
      </>,
      <>
        If the problem persists, please contact us at <b>[Help button]</b> on the top-right corner.
      </>,
      's_upcoming_lessons.notice.footer',
      <>
        问题无法解决时
        <br />
        请点击右上方的<b>[在线Help]</b> 按钮 向Ringle团队申请帮助
      </>,
      <>
        問題未能解決時，
        <br />
        請點擊右上方的<b>[即時Help]</b> 按鈕， 向Ringle團隊尋求協助。
      </>,
      <>
        問題が解決しない場合、
        <br />
        右上にある <b>[リアルタイムHelp]</b> ボタンをクリックしてRingleチームに ヘルプをリクエストしてください。
      </>,
      <>
        Nếu vấn đề tiếp diễn, vui lòng liên hệ chúng tôi qua <b>[nút Help]</b> phía bên phải trên cùng.
      </>,
    ],
  },
  welcomeBackModal: {
    main: (name) => [
      <>
        Welcome Back {name}님<br />
        반갑습니다!
      </>,
      <>
        Welcome Back {name},<br />
        Good to see you again!
      </>,
      's_upcoming_lessons.welcomeBackModal.main',
      <>
        Welcome Back {name}用户
        <br />
        感谢您回来!
      </>,
      <>
        Welcome Back {name}，<br />
        歡迎您！
      </>,
      <>
        Welcome Back {name}さん
        <br />
        ようこそ！
      </>,
      <>
        Chào mừng {name} trở lại,
        <br />
        Rất vui lại được thấy bạn!
      </>,
    ],
    content: [
      `첫 3회 수업 45% 할인가로 다시 시작해보세요.`,
      <>Start anew with a 45% discount on first 3 Lessons.</>,
      's_upcoming_lessons.welcomeBackModal.content',
      `前3次课程享受45%优惠, 立即重新开始吧。`,
      `請以首3次課程55折優惠價重新開始吧！`,
      `初回3回レッスンを45%割引価格でもう一度始めましょう。`,
      <>Bắt đầu hành trình mới với 3 buổi học đầu được giảm giá 45%.</>,
    ],
    getDiscount: [
      `할인가로 구매하기`,
      `Buy now`,
      's_upcoming_lessons.welcomeBackModal.getDiscount',
      `以优惠价购买`,
      `以優惠價購買`,
      `割引価格で購入する`,
      `Mua ngay`,
    ],
    threeLessons: [
      `3회 수업권`,
      `3-Lesson Credits`,
      's_upcoming_lessons.welcomeBackModal.threeLessons',
      `3次课程券`,
      `3次課程券`,
      `3回レッスンチケット`,
      `Thẻ học 3 buổi`,
    ],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.welcomeBackModal.footerLeft',
      `一周内不显示`,
      `一週內不再顯示`,
      `1週間表示しない`,
      `Nhắc tôi vào tuần tới`,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.welcomeBackModal.footerRight', `关闭`, `關閉`, `閉じる`, `Đóng`],
  },
  inviteModal: {
    title: [
      <>
        친구초대 이벤트
        <br /> 2만 포인트가 적립되었습니다.
      </>,
      <>
        Referral Event
        <br /> You earned $17.24
      </>,
      's_upcoming_lessons.inviteModal.title',
      <>
        朋友邀请活动
        <br /> 获得$17.24积分。
      </>,
      <>
        邀請朋友活動
        <br />
        已累積$17.24P。
      </>,
      <>
        友達紹介イベント
        <br /> $17.24ポイントが付与されました。
      </>,
      <>
        Sự kiện giới thiệu
        <br /> Bạn nhận được $17.24
      </>,
    ],
    subtitle: [
      <>
        20분 수업을 무료로 체험하고,
        <br />첫 3회 특가로 시작해보세요.
      </>,
      <>
        Have a free 20-min Trial Lesson
        <br />
        and start Ringle at a special price
      </>,
      's_upcoming_lessons.inviteModal.subtitle',
      <>
        免费体验20分钟课程，
        <br />
        前3次课程享有优惠, 开始您的学习吧。
      </>,
      <>
        免費體驗20分鐘課程，
        <br />
        以首3次特價開始吧！
      </>,
      <>
        20分レッスンを無料で体験し、
        <br />
        最初の3回を特価で始めましょう。
      </>,
      <>
        Nhận 20 phút học thử miễn phí
        <br />
        và bắt đầu học cùng Ringle với mức giá đặc biệt
      </>,
    ],
    freeTrial: [
      `무료 체험 신청하기`,
      `Start Free Trial`,
      's_upcoming_lessons.inviteModal.freeTrial',
      `申请免费体验课程`,
      `申請免費體驗`,
      `無料体験を予約する`,
      `Bắt đầu học thử miễn phí`,
    ],
  },
  firstCouponModal: {
    main: [
      <>
        첫 3회 패키지
        <br />
        구매 고객께
        <br />
        링글이 드리는 선물
      </>,
      <>A Gift from Ringle:</>,
      's_upcoming_lessons.firstCouponModal.main',
      <>
        前3次课程礼包
        <br />
        的购买用户
        <br />
        将获得Ringle的礼物
      </>,
      <>
        Ringle贈送
        <br />
        購買首3次禮包的學生
        <br />
        禮物
      </>,
      <>
        初回3回パッケージ
        <br />
        ご購入のお客様への
        <br />
        Ringleからのプレゼント
      </>,
      <>Món quà từ Ringle:</>,
    ],
    getDiscount: [
      `쿠폰함 바로 가기`,
      `Buy now`,
      's_upcoming_lessons.firstCouponModal.getDiscount',
      `前往优惠券页面`,
      `立即前往優惠券`,
      `クーポンに移動`,
      `Mua ngay`,
    ],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.firstCouponModal.footerLeft',
      `一周内不显示`,
      `一週內不再顯示`,
      `1週間表示しない`,
      `Nhắc tôi vào tuần tới`,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.firstCouponModal.footerRight', `关闭`, `關閉`, `閉じる`, `Đóng`],
  },
  forPromotionModal: {
    main: [
      <>
        2021년에도
        <br />
        멈추지 않는 당신에게
        <br />
        드리는 혜택
      </>,
      <>
        Never Stop Studying:
        <br />
        An exclusive offer
      </>,
      's_upcoming_lessons.forPromotionModal.main',
      <>
        2021年给予
        <br />
        持续拼搏的您
        <br />
        特别的优惠
      </>,
      <>
        送給2021年
        <br />
        也不停歇的您
        <br />
        優惠
      </>,
      <>
        2021年も
        <br />
        止まらないあなたへ
        <br />
        差し上げる特典
      </>,
      <>
        Không ngừng học hỏi:
        <br /> Ưu đãi đặc biệt
      </>,
    ],
    getDiscount: [
      `할인가로 구매하기`,
      `Buy now`,
      's_upcoming_lessons.forPromotionModal.getDiscount',
      `以优惠价购买`,
      `以優惠價購買`,
      `割引価格で購入する`,
      `Mua ngay`,
    ],
  },
  firstPurchaseModal: {
    main: (name) => [
      <>
        {name}님, 첫 3회 수업권을 <br />
        구매해주셔서 감사합니다.
      </>,
      <>Thank you for buying your first 3 lessons, #{name}.</>,
      's_upcoming_lessons.firstPurchaseModal.main',
      <>
        {name}, 非常感谢您
        <br />
        购买前3次课程券。
      </>,
      <>
        {name}， 感謝您
        <br />
        購買首3次課程券。
      </>,
      <>
        {name}さん、初回3回レッスンチケットを <br />
        ご購入いただきありがとうございます。
      </>,
      <>Cảm ơn #{name} đã thanh toán 3 buổi học đầu tiên.</>,
    ],
    content: (amount) => [
      <>
        재구매 시 사용 가능한
        <br />
        {amount} 포인트가 지급되었습니다.
      </>,
      <>
        you have been awarded
        <br />
        {amount} points.
      </>,
      's_upcoming_lessons.firstPurchaseModal.content',
      <>
        已发送给您再购买时
        <br />
        {amount}可使用的积分。
      </>,
      <>
        已為您發送再次購買時
        <br />
        可使用的${amount}P。
      </>,
      <>
        再購入時に使用可能な
        <br />
        {amount} ポイントが付与されました。
      </>,
      <>
        bạn được thưởng
        <br />
        {amount} điểm.
      </>,
    ],
    getDiscount: [
      `내 포인트 확인하기`,
      `View My Points`,
      's_upcoming_lessons.firstPurchaseModal.getDiscount',
      `确认我的积分`,
      `確認我的點數`,
      `マイポイントを確認する`,
      `Xem Điểm của tôi`,
    ],
  },
  givingBackVipModal: {
    dday: [
      <>
        <Span size={14} white>
          프로모션이 <Span warning>오늘</Span> 마감됩니다!
        </Span>
      </>,
      <>
        <Span size={14} white>
          Giving Back Promotion ends <Span warning>today</Span>.
        </Span>
      </>,
      's_upcoming_lessons.givingBackVipModal.dday',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    main: [
      <>
        링글이 100억 투자를 유치하며
        <br />A 라운드를 시작했습니다!
      </>,
      <>
        Ringle has secured
        <br />
        10 billion won in Series A!
      </>,
      's_upcoming_lessons.givingBackVipModal.main',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    content: (name) => [
      <>
        감사의 마음을 담아, {name}님께
        <br />
        10만 포인트를 지급해드렸습니다.
        <br />
        포인트는 재구매 시 사용 가능합니다.
      </>,
      <>
        As a token of our appreciation,
        <br />
        you have been awarded 86.20 Points.
      </>,
      's_upcoming_lessons.givingBackVipModal.content',
      <></>,
      <></>,
      <></>,
      <></>,
    ],
    getDiscount: [`지금 구매하기`, `Buy now`, 's_upcoming_lessons.givingBackVipModal.getDiscount', ``, ``, ``, ``],
    footerLeft: [
      `다시 보지 않기`,
      `Don't show again`,
      's_upcoming_lessons.givingBackVipModal.footerLeft',
      ``,
      ``,
      ``,
      ``,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.givingBackVipModal.footerRight', ``, ``, ``, ``],
  },
  upcoming_lesson_enter_modal_content: (lesson, timezone, remainText) => [
    <>
      <Span>
        수업 시간: {lesson.start_time} ({timezone})
        <br />
        <Span textDanger>{remainText}</Span> 남았습니다.
        <br />
        <br />
        수업 입장은 수업 시작 15분 전부터 가능합니다.
      </Span>
    </>,
    <>
      <Span size={12}>
        Lesson time : {lesson.start_time} ({timezone})
        <br />
        The lesson will begin in {remainText}.
        <br />
        <br />
        You can enter the lesson 15 minutes before the lesson begins.
      </Span>
    </>,
    's_upcoming_lessons.upcoming_lesson_enter_modal_content',
    <>
      <Span>
        课程时间: {lesson.start_time} ({timezone})
        <br />
        剩余<Span textDanger>{remainText}</Span>
        <br />
        <br />
        课程开始前15分钟可入场。
      </Span>
    </>,
    <>
      <Span>
        課程時間：{lesson.start_time}（{timezone}）
        <br />
        剩下<Span textDanger>{remainText}</Span>
        <br />
        <br />
        可於課程開始15分鐘前進入課程。
      </Span>
    </>,
    <>
      <Span>
        レッスン時間: {lesson.start_time} ({timezone})
        <br />
        <Span textDanger>{remainText}</Span> 残っています。
        <br />
        <br />
        レッスン入室はレッスン開始15分前から可能です。
      </Span>
    </>,
    <>
      <Span size={12}>
        Thời gian học : {lesson.start_time} ({timezone})
        <br />
        Buổi học sẽ bắt đầu trong {remainText}.
        <br />
        <br />
        Bạn có thể vào lớp trước 15 phút.
      </Span>
    </>,
  ],
  change_cancel: [
    `변경/ 취소`,
    `Change/Cancel`,
    's_upcoming_lessons.change_cancel',
    `变更/取消`,
    `變更／取消`,
    `予約変更/ キャンセル`,
    `Đổi/Huỷ`,
  ],
  addi_request: [
    `추가 요청 사항`,
    `Specific Requests`,
    's_upcoming_lessons.addi_request',
    `追加要求事项`,
    `額外要求事項`,
    `追加リクエスト事項`,
    `Yêu cầu thêm`,
  ],
  plz_choose_impossible_lesson_option: [
    `선택한 튜터와 매칭되지 않을 경우, 수업 처리 방식을 선택해주세요.`,
    `Please select how you'd like to resolve cancellation by your tutor.`,
    's_upcoming_lessons.plz_choose_impossible_lesson_option',
    `与选择的教师匹配失败时, 请选择处理课程的方式。`,
    `與選擇的任課教師配對失敗時，請選擇課程處理方式。`,
    `選択したチューターとマッチングしない場合、レッスン処理方式を選択してください。`,
    `Vui lòng lựa chọn hướng giải quyết cho buổi học bị huỷ bởi gia sư`,
  ],
  desc_about_tiral_lesson: [
    `*체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업모드를 교정 중심과 토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.`,
    `*All trial lessons are discussion-focused. Paid lessons can either be discussion-focused or correction focused.`,
    's_upcoming_lessons.desc_about_tiral_lesson',
    `*体验课程的形式为完成各问题对话后, 以纠正教学收尾。而正规课程则可选择纠正为主或讨论为主的课程模式以及其他详细方式。`,
    `*進行體驗課程時，在針對各提問結束對話後，以糾正方式進行。進行正規課程時，可於課程模式選擇以糾正為主或以討論為主後，選擇詳細的上課方式。`,
    `*体験レッスンは各質問の会話が終わった後に校正してもらう方式で行われます。レギュラーレッスンでは、レッスンモードを校正中心とディスカッション中心から選択して細かいレッスン方式を選択することができます。`,
    `*Tất cả buổi học thử sẽ tập trung thảo luận. Buổi học trả phí có thể tùy chọn tập trung thảo luận hoặc tập trung sửa lỗi.`,
  ],
  recording_notice: [
    <>
      수업 시작 시, "Recording In Progress" 안내멘트가 나오고 [● Recording]가 표기됩니다. (수업화면은 녹화되지
      않습니다.)
    </>,
    <>You'll hear "Recording In Progress" and see [● Recording] sign (lesson screen isn't recorded).</>,
    's_upcoming_lessons.recording_notice',
    <>课程开始时, 出现 "Recording In Progress"指示及显示[● Recording]时。 (课程画面将不进行录制)</>,
    <>課程開始時，出現「Recording In Progress」指示聲，並標示[● Recording]。 （不錄製上課畫面。）</>,
    <>
      レッスン開始時「Recording In Progress」という案内メッセージが出て[● Recording]と
      表示されます。(レッスン画面は録画されません)
    </>,
    <>
      Bạn sẽ nghe "Recording In Progress" và thấy biểu tượng [● Recording] (màn hình buổi học sẽ không được ghi lại).
    </>,
  ],
  mp3_warning_danger: [
    <>
      *수업 녹음 설정은 <Span danger> 수업 시작 15분 전까지만 변경 가능</Span>합니다.
    </>,
    <>
      *You can change recording preferences<Span danger> up to 15 minutes before the lesson</Span>.
    </>,
    's_upcoming_lessons.mp3_warning_danger',
    <>
      *课程录音设定, 最晚可于<Span danger>课程开始前15分钟进行</Span>变更。
    </>,
    <>
      *課程錄音設定<Span danger>最晚可於課程開始15分鐘前變更</Span>。
    </>,
    <>
      *レッスン録音設定は <Span danger> レッスン開始15分前まで変更可能</Span>です。
    </>,
    <>
      *Bạn có thể đổi tuỳ chọn ghi âm<Span danger> tối thiểu 15 phút trước buổi học</Span>.
    </>,
  ],
  in_upcoming_page_what_you_can_do: [
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        예습 & 수업 페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 수업 전 준비, 예정된 수업의 변경 및 취소,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        그리고 수업 방식을 설정할 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can set lesson styles, cancel, or reschedule under
      </Div>
      <Div spanTag fontBold textGray700 fontSize18 ml4>
        Lessons
      </Div>
    </>,
    's_upcoming_lessons.in_upcoming_page_what_you_can_do',
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        于预习 & 课程页面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        可进行课程前准备, 对预约课程进行变更或取消
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        以及对课程形式进行设定。
      </Div>
    </>,
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        可於預習 & 課程頁面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        進行課程開始前的準備、變更或取消預定的課程，
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        以及設定上課方式。
      </Div>
    </>,
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        予習 & レッスンページ
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        ではレッスン前の準備、レッスン予定の変更およびキャンセル、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        そしてレッスン方式を設定することができます。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        Bạn có thể chọn kiểu học, huỷ hoặc dời lịch dưới phần
      </Div>
      <Div spanTag fontBold textGray700 fontSize18>
        Buổi học
      </Div>
    </>,
  ],
  ringle_usage: [
    `링글 사용법`,
    `How Ringle works`,
    's_upcoming_lessons.ringle_usage',
    `Ringle使用方法`,
    `Ringle使用方法`,
    `Ringleの使用方法`,
    `Cách Ringle hoạt động`,
  ],
  zoom_usage: [
    `화상 프로그램 (Zoom) 설치 가이드`,
    `How to Install Zoom`,
    's_upcoming_lessons.zoom_usage',
    `视频程序(Zoom)安装指南`,
    `視訊軟體（Zoom）安裝指南`,
    `ビデオ会議アプリ (Zoom) インストールガイド`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  wrong_expression: [
    `틀린 영어 표현을 할 때마다 교정 받음`,
    `Correct and paraphrase my errors as soon as I make them`,
    's_upcoming_lessons.wrong_expression',
    `每当出现英语错误表达时立即进行纠正`,
    `每當出現錯誤的英語用法時接受糾正`,
    `間違った表現をしたらその場で校正してもらう`,
    `Sửa ngay khi tôi mắc lỗi`,
  ],
  after_conversation: [
    `각 질문에 대한 대화가 끝난 후 교정 받음`,
    `Correct and paraphrase my errors after each question`,
    's_upcoming_lessons.after_conversation',
    `各问题的对话结束后统一进行纠正`,
    `針對各提問的對話結束後接受糾正`,
    `各質問についての会話が終わった後に校正してもらう`,
    `Sửa cho tôi sau khi thảo luận câu hỏi`,
  ],
  half_talk: [
    `튜터와 내가 절반씩 이야기를 주고 받음`,
    `I want my tutor to speak as much as I do`,
    's_upcoming_lessons.half_talk',
    `教师和本人听说各占一半`,
    `任課教師與我各發言一半`,
    `チューターと自分が半分ずつ話す`,
    `Tôi muốn gia sư nói nhiều như tôi`,
  ],
  i_mainly_talk: [
    `튜터는 듣고 내가 주로 이야기함`,
    `I want to speak more than my tutor`,
    's_upcoming_lessons.i_mainly_talk',
    `教师以听为主, 侧重于学员会话`,
    `任課教師聆聽，主要由學生發言`,
    `チューターは聴く側で自分が主に話す`,
    `Tôi muốn nói nhiều hơn gia sư`,
  ],
  regular_calibration: [
    `상시교정`,
    `Instant Paraphrasing`,
    's_upcoming_lessons.regular_calibration',
    `随时纠正`,
    `隨時糾正`,
    `常時修正モード`,
    `Sửa lỗi ngay`,
  ],
  conversation_mode: [
    `대화 교정모드`,
    `Intermittent Paraphrasing`,
    's_upcoming_lessons.conversation_mode',
    `对话纠正模式`,
    `對話糾正模式`,
    `会話優先モード`,
    `Sửa lỗi ngắt quãng`,
  ],
  discussion_55: [
    `5:5 토론`,
    `5:5 Discussion`,
    's_upcoming_lessons.discussion_55',
    `5:5讨论`,
    `5:5討論`,
    `5:5ディスカッション`,
    `Thảo luận 5:5`,
  ],
  discussion_82: [
    `8:2 토론`,
    `8:2 Discussion`,
    's_upcoming_lessons.discussion_82',
    `8:2讨论`,
    `8:2討論`,
    `8:2ディスカッション`,
    `Thảo luận 8:2`,
  ],
  tutor_canceled: [
    `튜터 취소`,
    `Tutor cancelled`,
    's_upcoming_lessons.tutor_canceled',
    `教师取消`,
    `任課教師取消`,
    `チューターキャンセル`,
    `Gia sư huỷ`,
  ],
  tutor_another_time: {
    select: [
      <>튜터의 다른 시간을 선택해 주세요.</>,
      <>Please select a different time for tutor's schedule.</>,
      's_upcoming_lessons.tutor_another_time.select',
      <>请选择教师的其他时间。</>,
      <>請選擇任課教師的其他時間。</>,
      <>チューターの他の時間を選択してください。</>,
      <>Vui lòng chọn thời gian khác trong thời khoá biểu của gia sư.</>,
    ],
    restored: [
      <>수업 취소 시 자동으로 수업권은 복구됩니다.</>,
      <>Cancel the lesson to restore the lesson credit.</>,
      's_upcoming_lessons.tutor_another_time.restored',
      <>课程取消后, 课程券将自动恢复。</>,
      <>課程取消時，自動復原課程券。</>,
      <>レッスンキャンセル時、自動的にレッスンチケットは復元されます。</>,
      <>Huỷ buổi học và khôi phục lại thẻ học.</>,
    ],
  },
  lesson_style: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parent,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_style.auto_delete_in_24',
      <Div>
        为保护教师及学员, 所有
        <Div spanTag textBlack fontMedium>
          课程均会录像, 且课程视频内容将保存24小时。
        </Div>
        24小时过后将自动删除。
      </Div>,
      <Div>
        為保護學生與任課教師，所有
        <Div spanTag textBlack fontMedium>
          課程影片將會錄影，並儲存一個工作天共24小時
        </Div>
        。24小時後將自動刪除。
      </Div>,
      <Div>
        受講生とチューターを保護するため、すべての{' '}
        <Div spanTag textBlack fontMedium>
          レッスン動画は録画され、営業日基準で24時間保存
        </Div>
        されます。24時間が過ぎると自動的に削除されます。
      </Div>,
      <Div>
        Để bảo vệ học sinh & phụ huynh,{' '}
        <Div spanTag textBlack fontMedium>
          tất cả buổi học sẽ được ghi hình.
        </Div>{' '}
        Tất cả video buổi học sẽ được xoá sau 24 giờ nếu không có bất kì báo cáo nào liên quan đến buổi học.
      </Div>,
    ],
    not_be_deleted_for_lesson: [
      `단, 수업 분석 확인과 수업 다시 듣기를 위해 수업 녹음 파일은 삭제되지 않습니다. (파일 제작은 최대 24시간이 
소요됩니다.)`,
      `Audio recordings won't be deleted for lesson statistics and replay, which may take up to 24 hours after each lesson to process.`,
      's_upcoming_lessons.lesson_style.not_be_deleted_for_lesson',
      `但, 为了能确认课程分析内容及重听课程, 课程录音文件将不会被删除。(文件制作时间最多需要24小时。)`,
      `但為了確認課程分析與重新聆聽課程，將不會刪除課程錄音檔。（製作檔案最長需要24小時。）`,
      `ただし、レッスン分析確認とレッスンをもう一度聴くことができるように、レッスン録音ファイルは削除されません。(ファイル制作は最長24時間
所要します)`,
      `Ghi âm sẽ không bị xoá để sử dụng cho thống kê sau buổi học và xem lại, điều này sẽ cần tới 24 giờ để xử lý.`,
    ],
    class_style: [
      `수업 스타일`,
      `Lesson Style`,
      's_upcoming_lessons.lesson_style.class_style',
      `课程类型`,
      `課程風格`,
      `レッスンスタイル`,
      `Hình thức học`,
    ],
    discussion_type: [
      `토론형`,
      `Debate Mode`,
      's_upcoming_lessons.lesson_style.discussion_type',
      `讨论型`,
      `討論型`,
      `ディスカッション型`,
      `Tranh luận`,
    ],
    able_to_communicate: [
      `영어로 토론을 잘하는 것이 목표입니다. 아이의 답변에 챌린지 해주시고, 튜터분의 의견도 적극 표현해 주세요.`,
      `Please ask thought-provoking follow-up questions for in-depth discussions.`,
      's_upcoming_lessons.lesson_style.able_to_communicate',
      `将能够用英语进行良好讨论作为目标。请鼓励孩子们积极进行回答, 同时请教师提出意见。`,
      `目標是變得擅長英語討論。請多鼓勵孩子回覆，並請任課教師積極表達意見。`,
      `英語でディスカッションを上手にするのが目標です。子供も回答できるように誘導し、チューターの意見も積極的に表現してください。`,
      `Vui lòng đặt ra các câu hỏi đáng suy ngẫm cho các buổi thảo luận sâu.`,
    ],
    speech_guided: [
      `발언 유도형`,
      `Student-Centered Mode`,
      's_upcoming_lessons.lesson_style.speech_guided',
      `发言诱导型`,
      `發言誘導型`,
      `発言誘導型`,
      `Nói`,
    ],
    increase_confidence: [
      `영어에 대한 자신감을 높여주고 싶습니다. 적극적 경청 및 질문을 통해, 아이가 말할 수 있는 기회를 많이 주세요.`,
      `Please ask as many follow-up questions as you can to maximize the student's speaking opportunities.`,
      's_upcoming_lessons.lesson_style.increase_confidence',
      `我想提升孩子对英语方面的信心。通过积极的倾听及提问, 给孩子提供更多的开口机会。`,
      `我想提升孩子對英語的信心。請積極傾聽並提問，多提供孩子們開口的機會。`,
      `英語に対する自信を高めたいです。積極的な傾聴および質問で、子供が話せる機会をたくさんあげてください。`,
      `Vui lòng đặt ra càng nhiều câu hỏi bổ sung càng tốt để học viên có thể nói nhiều hơn.`,
    ],
    corrective_type: [
      `교정형`,
      `Correction Mode`,
      's_upcoming_lessons.lesson_style.corrective_type',
      `纠正型`,
      `糾正型`,
      `校正型`,
      `Sửa lỗi`,
    ],
    develop_expressive_vocabulary_skills: [
      `표현력/어휘력을 키워주는 것이 목표입니다. 아이의 틀린/어색한 영어 표현을 적극 교정해 주세요.`,
      `Please correct the student's incorrect or awkward grammar and vocabulary.`,
      's_upcoming_lessons.lesson_style.develop_expressive_vocabulary_skills',
      `将提升表达力/词汇力作为目标。请积极纠正孩子在表达中出现的错误/生涩之处。`,
      `目標是提升表達能力／詞彙能力。請積極糾正孩子錯誤／不自然的英語用法。`,
      `表現力/語彙力を高めることが目標です。子供の間違った/不自然な英語表現を積極的に校正してください。`,
      `Vui lòng chỉnh lỗi khi học viên mắc lỗi hoặc những từ vựng và cấu trúc ngữ pháp chưa tự nhiên.`,
    ],
    private_to_your_children: [
      `자녀에게는 비공개됩니다.`,
      `Not shown to student.`,
      's_upcoming_lessons.lesson_style.private_to_your_children',
      `不对子女进行公开。`,
      `不對子女公開。`,
      `子供には非公開となります。`,
      `Không hiển thị cho học viên.`,
    ],
    teaching_style: [
      `티칭 스타일 (3개까지 선택 가능)`,
      `Teaching Style (Select up to 3)`,
      's_upcoming_lessons.lesson_style.teaching_style',
      `授课类型(可选择3种)`,
      `授課風格（最多可選擇3個）`,
      `ティーチングスタイル (3つまで選択可能)`,
      `Hình thức dạy (Tối thiểu 3 lựa chọn)`,
    ],
  },
  lesson_confirm: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parents,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_confirm.auto_delete_in_24',
      <Div>
        为保护教师及学员, 所有
        <Div spanTag textBlack fontMedium>
          课程视频内容将保存24小时。
        </Div>
        24小时后将自动删除。
      </Div>,
      <Div>
        為保護學生與任課教師，所有
        <Div spanTag textBlack fontMedium>
          課程影片將會錄影，並儲存一個工作天共24小時
        </Div>
        。24小時後將自動刪除。
      </Div>,
      <Div>
        受講生とチューターの保護のため、{' '}
        <Div spanTag textBlack fontMedium>
          レッスン動画は録画され、営業日基準で24時間保存
        </Div>
        されます。24時間が過ぎると自動的に削除されます。
      </Div>,
      <Div>
        Để bảo vệ học sinh & phụ huynh,{' '}
        <Div spanTag textBlack fontMedium>
          tất cả buổi học sẽ được ghi hình.
        </Div>{' '}
        Tất cả video sẽ xoá trong vòng 24 giờ nếu không có bất kỳ báo cáo nào liên quan đến buổi học.
      </Div>,
    ],
    teaching_style: [
      `티칭 스타일`,
      `Teaching Style`,
      's_upcoming_lessons.lesson_confirm.teaching_style',
      `授课类型`,
      `授課風格`,
      `ティーチングスタイル`,
      `Hình thức dạy`,
    ],
  },
  lesson_cancellation: [
    `수업 취소 결과`,
    `Lesson Cancellation Results`,
    's_upcoming_lessons.lesson_cancellation',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_confirmed: [
    `튜터의 배정이 확정되었으므로, 시간 변경이 불가능합니다. 
수업 취소 후 다시 신청해주세요.`,
    `As tutor confirmed the lesson, schedule cannot be changed.
Please book again after canceling the lesson.`,
    's_upcoming_lessons.tutor_confirmed',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_beta: [`CAF Beta`, `CAF Beta`, 's_upcoming_lessons.caf_beta', `CAF Beta`, `CAF Beta`, `CAF Beta`, `CAF Beta`],
  caf_beta_info: [
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    's_upcoming_lessons.caf_beta_info',
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
  ],
  caf_beta_record: [
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    's_upcoming_lessons.caf_beta_record',
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
  ],
  caf_beta_writing: [
    `‘라이팅 중심’ 수업은 CAF 진단이 제공되지 않습니다.`,
    ``,
    's_upcoming_lessons.caf_beta_writing',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_menu: {
    title: [`CAF 진단`, ``, 's_upcoming_lessons.caf_menu.title', ``, ``, ``, ``],
    optn1: [`진단 받기`, ``, 's_upcoming_lessons.caf_menu.optn1', ``, ``, ``, ``],
    optn2: [`진단 받지 않기`, ``, 's_upcoming_lessons.caf_menu.optn2', ``, ``, ``, ``],
  },
}
export const s_tutors = {
  schedule: [`예약하기`, `Book lessons`, 's_tutors.schedule', `预约`, `預約`, `予約する`, `Đặt lịch học`],
  find_a_tutor: [
    `튜터 찾기`,
    `Find a tutor`,
    's_tutors.find_a_tutor',
    `寻找教师`,
    `尋找任課教師`,
    `チューター検索`,
    `Tìm gia sư`,
  ],
  placeholder: [
    `원하는 튜터의 키워드를 입력해주세요. ex) Finance`,
    `Find tutors with a keyword (E.g. Finance)`,
    's_tutors.placeholder',
    `请输入您希望查找的教师关键词。ex) Finance`,
    `請輸入希望的任課教師的關鍵字。ex) Finance`,
    `希望するチューターのキーワードを入力してください。 ex) Finance`,
    `Tìm gia sư với từ khoá (Ví dụ: Finance)`,
  ],
  see_more: [`더보기`, `See more`, 's_tutors.see_more', `查看更多`, `查看更多`, `もっと見る`, `Xem thêm`],
  any: [`상관없음`, `Any`, 's_tutors.any', `不介意`, `皆可`, `こだわらない`, `Bất kỳ`],
  gender: {
    label: [`성별`, `Gender`, 's_tutors.gender.label', `性别`, `性別`, `性別`, `Giới tính`],
    female: [`여자`, `Female`, 's_tutors.gender.female', `女性`, `女性`, `女性`, `Nữ`],
    male: [`남자`, `Male`, 's_tutors.gender.male', `男性`, `男性`, `男性`, `Nam`],
  },
  accent: {
    label: [`액센트`, `Accent`, 's_tutors.accent.label', `口音`, `口音`, `アクセント`, `Giọng`],
    accent_american: [`미국식`, `American`, 's_tutors.accent.accent_american', `美式`, `美式`, `アメリカ`, `Giọng Mỹ`],
    accent_british: [`영국식`, `British`, 's_tutors.accent.accent_british', `英式`, `英式`, `イギリス`, `Giọng Anh`],
  },
  major: {
    label: [`전공`, `Major`, 's_tutors.major.label', `专业`, `主修`, `専攻`, `Chuyên môn`],
    social_sciences: [
      `사회과학경영`,
      `Social Sciences/Business`,
      's_tutors.major.social_sciences',
      `社会科学/经营`,
      `社會科學／經營`,
      `社会科学・経営`,
      `Khoa học xã hội/Kinh doanh`,
    ],
    liberal_arts: [
      `인문계`,
      `Liberal Arts`,
      's_tutors.major.liberal_arts',
      `人文系`,
      `人文`,
      `人文系`,
      `Giáo dục khai phóng`,
    ],
    engineering: [`공과계열`, `Engineering`, 's_tutors.major.engineering', `工学系`, `理工`, `工科系`, `Kỹ sư`],
    natural_science: [
      `자연과학계열`,
      `Natural Science`,
      's_tutors.major.natural_science',
      `自然科学系`,
      `自然科學`,
      `自然科学系`,
      `Khoa học tự nhiên`,
    ],
    fine_arts_and_athletics: [
      `예체능`,
      `Fine Arts and Athletics`,
      's_tutors.major.fine_arts_and_athletics',
      `艺体专业`,
      `藝術／體育`,
      `芸能・芸術・体育`,
      `Mỹ thuật và Thể dục`,
    ],
  },
  search: [`검색`, `Search`, 's_tutors.search', `搜索`, `搜尋`, `検索`, `Tìm kiếm`],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_tutors.recommended_tutor',
    `推荐的教师`,
    `推薦的教師`,
    `おすすめチューター`,
    `Nổi bật`,
  ],
  all_tutor: [`전체 튜터`, `All`, 's_tutors.all_tutor', `全部教师`, `所有任課教師`, `すべてのチューター`, `Tất cả`],
  saved_tutor: [
    `찜한 튜터`,
    `Favorites`,
    's_tutors.saved_tutor',
    `收藏的教师`,
    `我收藏的教師`,
    `お気に入りチューター`,
    `Yêu thích`,
  ],
  search_result: [
    `튜터 검색 결과`,
    `Search Results`,
    's_tutors.search_result',
    `教师搜索结果`,
    `任課教師搜尋結果`,
    `チューター検索結果`,
    `Kết quả tìm kiếm`,
  ],
  no_result: [
    `😣  검색 결과가 없습니다`,
    `😣  No Result`,
    's_tutors.no_result',
    `😣 无搜索结果`,
    `😣 無搜尋結果`,
    `😣  検索結果はありません。`,
    `😣  Không có kết quả`,
  ],
  accept_rate: [`수락률`, `Acceptance rate`, 's_tutors.accept_rate', `接受率`, `接受率`, `承諾率`, `Tỷ lệ nhận`],
  accept_rate_meaning: [
    <>
      튜터에게 수업 요청 시 튜터가 수업을 수락할 확률 입니다. <br />
      요청하실 튜터의 수락률이 높지 않다면, 신청하는 것을 고려해보세요!
    </>,
    <>
      Indicates probability that a tutor will accept your lesson booking. <br />
      If a tutor has a low acceptance rate, try finding a different tutor!
    </>,
    's_tutors.accept_rate_meaning',
    <>
      向教师提出课程申请时, 教师接受课程的概率。 <br />
      如果您申请的教师接受率不高, 请慎重考虑!
    </>,
    <>
      為向任課教師申請課程時，任課教師接受申請的機率。
      <br />
      若您申請的任課教師接受率不高，請考慮是否申請！
    </>,
    <>
      チューターにレッスンをリクエストした際に、チューターがレッスンを承諾する確率です。 <br />
      リクエストするチューターの承諾率が高くなければ、別のチューターを予約することを検討してみてください。
    </>,
    <>
      Xác suất cho thấy khả năng gia sư sẽ nhận đặt lịch học của bạn. <br />
      Nếu một gia sư có tỷ lệ nhận thấp, thử tìm gia sư khác!
    </>,
  ],
  mark_tutor: [`찜하기`, `Favorite`, 's_tutors.mark_tutor', `收藏`, `收藏`, `お気に入り`, `Yêu thích`],
  tutor_profile: [
    `튜터 프로필`,
    `Tutor Profile`,
    's_tutors.tutor_profile',
    `教师简介`,
    `任課教師簡介`,
    `チュータープロフィール`,
    `Hồ sơ gia sư`,
  ],
  student_profile: [
    `Student Info`,
    `Student Profile`,
    's_tutors.student_profile',
    `Student Info`,
    `Student Info`,
    `Student Info`,
    `Hồ sơ học viên`,
  ],
  video_preparing: [
    <>
      튜터가 영상을 준비중입니다.
      <br />
      조금만 기다려주세요!
    </>,
    <>Introduction video will be provided soon!</>,
    's_tutors.video_preparing',
    <>
      教师正在准备视频。
      <br />
      请耐心等待!
    </>,
    <>
      任課教師正在準備影像，
      <br />
      請稍候！
    </>,
    <>
      チューターが動画を準備中です。
      <br />
      もう少しお待ちください！
    </>,
    <>Video giới thiệu sẽ được cung cấp sớm!</>,
  ],
  schedule_available: [
    `수업 예약 가능`,
    `Available`,
    's_tutors.schedule_available',
    `可预约课程`,
    `可預約課程`,
    `レッスン予約可能`,
    `Trống lịch`,
  ],
  schedule_fully_booked: [
    `수업 예약 마감`,
    `Fully booked`,
    's_tutors.schedule_fully_booked',
    `课程预约结束`,
    `課程預約截止`,
    `レッスン予約締切`,
    `Đã đặt hết`,
  ],
  not_schedule_yet: [
    `스케쥴 오픈 전`,
    `Not available`,
    's_tutors.not_schedule_yet',
    `日程未公开`,
    `尚未公開日程`,
    `スケジュールオープン前`,
    `Không trống lịch`,
  ],
  my_tutor: [
    `내 튜터`,
    `My Tutors`,
    's_tutors.my_tutor',
    `我的教师`,
    `我的任課教師`,
    `マイチューター`,
    `Gia sư của tôi`,
  ],
  intro: [`자기소개`, `Intro`, 's_tutors.intro', `自我介绍`, `自我介紹`, `自己紹介`, `Giới thiệu`],
  review: [`수업 평가`, `Review`, 's_tutors.review', `课程评价`, `課程評論`, `レッスン評価`, `Nhận xét`],
  annoucement: [
    `튜터 공지사항`,
    `Tutor’s Announcement`,
    's_tutors.annoucement',
    `教师通知事项`,
    `任課教師公告事項`,
    `チューターからのお知らせ`,
    `Thông báo từ gia sư`,
  ],
  experience: [`핵심 경험`, `Experience`, 's_tutors.experience', `核心经验`, `核心經驗`, `主な経歴`, `Kinh nghiệm`],
  interests: [`관심사`, `Interests`, 's_tutors.interests', `关注之事`, `感興趣的領域`, `興味/関心`, `Sở thích`],
  no_junior_desc: [
    <>
      성인 수강생 대상 수업만 진행하는 튜터입니다.
      <br />
      초/중/고 수강생 예약 신청 시, 매칭되지 않을 가능성이 높습니다.
    </>,
    <>
      This tutor prefers lessons with working professionals.
      <br />
      Hence, the lesson may not be matched with juniors.
    </>,
    's_tutors.no_junior_desc',
    <>
      只接受成人学员的教师。
      <br />
      小/初/高中学员预约课程时, 不成功的可能性很高。
    </>,
    <>
      僅為成人學生授課的任課教師。
      <br />
      國小／國中／高中學生申請預約時，很有可能配對失敗。
    </>,
    <>
      成人受講生対象レッスンのみ行うチューターです。
      <br />
      小/中/高生の受講生が予約すると、マッチングしない可能性が高いです。{' '}
    </>,
    <>
      Gia sư này phù hợp hơn với những người đi làm.
      <br />
      Nên buổi học có thể sẽ không hợp với các bạn học sinh.
    </>,
  ],
  save_tutor_like: [
    `같이 수업하고 싶은\n튜터를 찜해보세요.`,
    <>Favorite a tutor.</>,
    's_tutors.save_tutor_like',
    `请收藏希望一起\n学习的教师吧。`,
    `請收藏想一起上課的\n任課教師。`,
    `レッスンを受けたい\nチューターをお気に入り登録しましょう。`,
    <>Chọn làm gia sư yêu thích.</>,
  ],
  check_exp_tutor: [
    `나와 수업한 튜터를\n여기에서 확인할 수 있습니다.`,
    <>See your previous tutors.</>,
    's_tutors.check_exp_tutor',
    `您可以在这里确认\n和我一起学习的教师。`,
    `可以在這裡確認\n和我上過課的任課教師。`,
    `あなたとレッスンをしたチューターを\nここで確認することができます。`,
    <>Xem những gia sư từng học.</>,
  ],
  experienced_tutor: [
    `수업한 튜터`,
    `Prev. Tutors`,
    's_tutors.experienced_tutor',
    `任课教师`,
    `和我上過課的任課教師`,
    `レッスンしたチューター`,
    `Gia sư từng học`,
  ],
  apply: [`적용`, `Apply`, 's_tutors.apply', `适用`, `套用`, `適用`, `Áp dụng`],
  search_tutor: [
    `튜터 검색`,
    `Search`,
    's_tutors.search_tutor',
    `搜索教师`,
    `搜尋任課教師`,
    `チューター検索`,
    `Tìm kiếm`,
  ],
  k12_lesson: [
    `초중고생 수업`,
    `K-12`,
    's_tutors.k12_lesson',
    `小学/初中/高中学生课程`,
    `國小、國中、高中學生課程`,
    `小中高生レッスン`,
    `K-12`,
  ],
  no_review_yet: [
    `No reviews yet.`,
    `No reviews yet.`,
    's_tutors.no_review_yet',
    `No reviews yet.`,
    `No reviews yet.`,
    `No reviews yet.`,
    `Chưa có nhận xét.`,
  ],
  accept_rate_requested: [
    `수업 요청 시 튜터가 수락한 비율입니다.`,
    `Indicates rate that a tutor will accept your lesson booking.`,
    's_tutors.accept_rate_requested',
    ``,
    ``,
    ``,
    ``,
  ],
  no_history: [
    `지난 2주간 수업 이력이 없어 수락률이 표기되지 않습니다.`,
    `The acceptance rate is not indicated since the tutor has not conducted lessons for the past two weeks.`,
    's_tutors.no_history',
    ``,
    ``,
    ``,
    ``,
  ],
  adults_only: [
    `성인만 가능`,
    `Adults only`,
    's_tutors.adults_only',
    `仅限成人`,
    `僅限成人`,
    `成人のみ可能`,
    `Cho người lớn`,
  ],
  k12ok: [
    `초중고생 가능`,
    `K-12 OK`,
    's_tutors.k12ok',
    `小学，中学，高中生可以`,
    `國小、國中、高中學生可以`,
    `小中高生可能`,
    `K-12 OK`,
  ],
  review_complete: [
    `모두 조회했습니다.`,
    `Review complete`,
    's_tutors.review_complete',
    `全部查询完毕。`,
    `已全部查詢。`,
    `すべて照会しました。`,
    `Hoàn tất xem lại`,
  ],
  no_reviews_yet: [
    `아직 등록된 수업 평가가 없습니다.`,
    `No reviews yet`,
    's_tutors.no_reviews_yet',
    `尚无课程评价。`,
    `尚無課程評論。`,
    `まだ登録されたレッスン評価がありません。`,
    `Không có đánh giá`,
  ],
  new_tutor: [
    `새로 온 튜터`,
    `Rookie Tutors`,
    's_tutors.new_tutor',
    `新教師`,
    `新教师`,
    `新しく来たチューター`,
    `Gia sư mới`,
  ],
  new_tutor_detail_info: {
    title: [
      `수업후기 남기고 수업료 50%를 돌려받으세요.`,
      `Write a review and get 50% value back.`,
      's_tutors.new_tutor_detail_info.title',
      `提交課程評價，領取50%課費點數。`,
      `提交课程评价，领取50%课费积分。`,
      `レビューを書くとレッスン料の50%を！`,
      `Viết đánh giá và nhận lại 50% học phí.`,
    ],
    desc0: [
      `새로 온 튜터와 수업 후 수업후기를 남기면 수업료의 50%를 포인트로 돌려드려요.`,
      `Get 50% of the lesson fee back in points by leaving a review.`,
      's_tutors.new_tutor_detail_info.desc0',
      `提交與新導師上課的課後評價，可獲得課費50%的點數返還。`,
      `提交与新导师上课的课后评价，可获得课费50%的积分返还。`,
      `新規チューターのレビューを残すと料金の50％をポイントで受け取れます。`,
      `Nhận 50% hoàn học phí bằng Điểm khi viết nhận xét.`,
    ],
    desc1: [
      `유료 수업권으로 예약한 수업 중 새로 온 튜터와의 수업에 한해 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
      `Applicable to paid lessons with Rookie Tutors only / Eligible points will be calculated based on billed payment.`,
      's_tutors.new_tutor_detail_info.desc1',
      `僅限用收費課程券預約的課程中新導師的課程，以最終結算金額為基準，1次課費的50%將作為點數返還。`,
      `仅限用收费课程券预约的课程中新导师的课程，以最终结算金额为基准，1次课费的50%将作为积分返还。`,
      `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
      `50% điểm được cộng 1 lần theo hóa đơn thanh toán gần nhất, chỉ áp dụng cho buổi học trả phí với gia sư mới.`,
    ],
    desc2: [
      `새로 온 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
      `Credit restored if unsatisfied with the Rookie Tutor. (Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit)`,
      's_tutors.new_tutor_detail_info.desc2',
      `如果對新導師的授課表現不滿意，課程券將被退還。 （用收費授課券預約後，導師評價不足3分時並提交原因，每月限2次）`,
      `如果对新导师的授课表现不满意，课程券将被退还。 （用收费授课券预约后，导师评价不足3分时并提交原因，每月限2次）`,
      `新規チューターに満足できなかったら、レッスンチケットを復元いたします。（有料レッスン/評価３点以下/理由提出、月２回限定）`,
      `Phiếu học được hoàn nếu không hài lòng với tiết học cùng gia sư mới (tối đa 2 lần/ tháng cho phiếu học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
    ],
  },
  tutor_profile_title: [
    `새로 온 튜터 50% 포인트백`,
    `Rookie Tutors 50% Value Back`,
    's_tutors.tutor_profile_title',
    `新教師50% 點數返還`,
    `新教师50% 积分返还`,
    `新しく来たチューター50% Point Back`,
    `Gia sư mới50% Point Back`,
  ],
  tutor_profile_desc1: (name) => [
    <>{name} 튜터와 수업 후 수업평가를 남기면 수업료의 50%를 포인트로 돌려드려요.</>,
    <>Take a lesson from tutor {name} and leave a review to get 50% value back in points.</>,
    's_tutors.tutor_profile_desc1',
    <>留下{name}導師的課後評價，將返還您50%的課費作為點數。</>,
    <>留下{name}导师的课后评价，将返还您50%的课费作为积分。</>,
    <>{name}チューターとレッスン後レッスン評価を残したらレッスン料の50％をポイントで差し上げます。</>,
    <>Sau buổi học với gia sư {name}, để lại đánh giá để nhận lại 50% học phí dưới dạng điểm..</>,
  ],
  tutor_profile_desc2: [
    `유료 수업권으로 예약한 수업에 한하여 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
    `Book the lesson using a paid credit to receive 50% of the lesson fee (based on billed payment) back in points.`,
    's_tutors.tutor_profile_desc2',
    `僅限於用收費課程券預約的課程，以最終結算金額為基準，返還1次課費的50%點數。`,
    `仅限于用收费课程券预约的课程，以最终结算金额为基准，返还1次课费的50%积分。`,
    `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
    `50% điểm sẽ được cộng 1 lần dựa trên số tiền thanh toán cuối cùng, chỉ áp dụng cho buổi học được đặt lịch bằng phiếu trả phí.`,
  ],
  new_tutor_desc: [
    `10대 1의 경쟁률을 뚫고 선발된 튜터와 만나보세요.`,
    `Meet our highly-qualified Rookie Tutors.`,
    's_tutors.new_tutor_desc',
    `和新教师上课`,
    `和新教師上課`,
    `新規チューターのレビューを残すと料金の50％をポイントで受け取れます。`,
    ``,
  ],
  recommended_tutor_desc: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요.`,
    `These tutors have received high ratings and positive reviews.`,
    's_tutors.recommended_tutor_desc',
    `最近一個月獲得高評分及正面評價的教師`,
    `最近一个月获得高评分及正面评价的教师`,
    `ここ一ヶ月、高い評価とレビューを維持しているチューターです。`,
    `Gia sư được xếp hạng cao và phản hồi tích cực.`,
  ],
  new_tutor_viewall: [
    `전체보기`,
    `View All`,
    's_tutors.new_tutor_viewall',
    `查看全部`,
    `查看全部`,
    `すべて見る`,
    `Xem tất cả`,
  ],
  search_all: [`전체`, `All`, 's_tutors.search_all', `全部`, `全部`, `すべて`, `Tất cả`],
  profile_overview: [`요약`, `Summary`, 's_tutors.profile_overview', `简介`, `簡介`, `要約`, `Tóm tắt`],
  read_more_about_intro: [
    `자기소개 더 읽기`,
    `Read more`,
    's_tutors.read_more_about_intro',
    `自我介绍`,
    `自我介紹`,
    `もっと見る`,
    `Đọc thêm`,
  ],
  read_more_about_review: [
    `수업 평가 전체 보기`,
    `View all`,
    's_tutors.read_more_about_review',
    `课程评价`,
    `課程評價`,
    `全体を見る`,
    `Xem toàn bộ`,
  ],
  video_updated_at: (year, month, day) => [
    <>
      {year}.{month}.{day} 촬영
    </>,
    <>
      Filmed on {month}.{day}.{year}
    </>,
    's_tutors.video_updated_at',
    <>
      拍摄日期 {month}.{day}.{year}
    </>,
    <>
      拍攝日期 {month}.{day}.{year}
    </>,
    <>
      {year}.{month}.{day} 撮影
    </>,
    <>
      Ghi hình ngày {month}.{day}.{year}
    </>,
  ],
  review_explanation: [
    `이 튜터의 수업을 직접 수강한 고객님들이 선택한 키워드 중 상위 5개에 대한 통계입니다.`,
    `Top 5 keywords students have used to describe the tutor's lesson style`,
    's_tutors.review_explanation',
    `学生给老师的Top 5课程特色形容词`,
    `學生給老師的Top 5課程特色形容詞`,
    `当チューターのレッスンを実際に受講したお客様が選んだキーワードのうち上位5つに対する統計です。`,
    `Top 5 từ khoá học viên dùng để miêu tả phong cách giảng dạy của gia sư`,
  ],
}
export const s_portal_home = {
  reserve_lessons: [`수업예약`, `Book`, 's_portal_home.reserve_lessons', `预约课程`, `預約課程`, `予約`, `Đặt lịch`],
  time_left: (days, hours, minutes) => [
    <>
      {days <= 0 ? '' : days + '일 '}
      {hours <= 0 ? '' : hours + '시간 '}
      {minutes <= 0 ? '' : minutes + '분 '}남음
    </>,
    <>
      {days <= 0 ? '' : days + 'd '}
      {hours <= 0 ? '' : hours + 'h '}
      {minutes <= 0 ? '' : minutes + 'm '}left
    </>,
    's_portal_home.time_left',
    <>
      剩余{days <= 0 ? '' : days + '日'}
      {hours <= 0 ? '' : hours + '小时 '}
      {minutes <= 0 ? '' : minutes + '分钟 '}
    </>,
    <>
      剩下{days <= 0 ? '' : days + '日'}
      {hours <= 0 ? '' : hours + '小時'}
      {minutes <= 0 ? '' : minutes + '分鐘'}
    </>,
    <>
      残り{days <= 0 ? '' : days + '日 '}
      {hours <= 0 ? '' : hours + '時間 '}
      {minutes <= 0 ? '' : minutes + '分 '}
    </>,
    <>
      {days <= 0 ? '' : days + 'd '}
      {hours <= 0 ? '' : hours + 'h '}
      {minutes <= 0 ? '' : minutes + 'm '}còn lại
    </>,
  ],
  start_after_min: (time) => [
    `${time}분 후 시작`,
    `Starts in ${time} minutes`,
    's_portal_home.start_after_min',
    `${time}分钟后开始`,
    `${time}分鐘後開始`,
    `${time}分後開始`,
    `Bắt đầu trong ${time} phút`,
  ],
  lesson_start_past_min: (time) => [
    `수업시간 ${time} 분 지남`,
    `Started ${time} min ago`,
    's_portal_home.lesson_start_past_min',
    `课程时间已超过${time}分钟`,
    `課程時間已過${time}分鐘`,
    `レッスン時間 ${time} 分経過`,
    `Bắt đầu ${time} phút trước`,
  ],
  webinar_start_past_min: (time) => [
    `${time} 분 전부터 진행 중`,
    `Started ${time} min ago`,
    's_portal_home.webinar_start_past_min',
    `${time}分钟前开始进行`,
    `自${time}分鐘前開始`,
    `${time} 分前から進行中`,
    `Bắt đầu ${time} phút trước`,
  ],
  hello: (name) => [
    <>안녕하세요 {name}님!</>,
    <>Hello {name}!</>,
    's_portal_home.hello',
    <>您好, {name}用户!</>,
    <>您好，{name}用戶！</>,
    <>こんにちは、 {name}さん！</>,
    <>Xin chào {name}!</>,
  ],
  trial_card_title: [
    `선착순 마감 혜택`,
    `First-Come First-Served`,
    's_portal_home.trial_card_title',
    `限量优惠`,
    `先搶先贏優惠`,
    `先着順特典`,
    `Ưu tiên đăng ký sớm`,
  ],
  trial_card: [
    <>
      20분 무료수업
      <br />
      예약하기
    </>,
    <>
      Try 20-min <br />
      lesson now!
    </>,
    's_portal_home.trial_card',
    <>
      预约20分钟
      <br />
      免费课程
    </>,
    <>
      預約20分鐘
      <br />
      免費課程
    </>,
    <>
      20分無料レッスンを
      <br />
      予約する
    </>,
    <>
      Học thử 20 phút <br />
      ngay bây giờ!
    </>,
  ],
  benefit_1: [
    <>
      최대 3회 수업 제공
      <br />+ 10,000원 추가 할인
    </>,
    <>
      Max 3 extra lessons
      <br />+ ₩10,000 Discount{' '}
    </>,
    's_portal_home.benefit_1',
    <>
      最多提供3次课程
      <br />+ ₩10,000 额外优惠
    </>,
    <>
      最多提供3次課程
      <br />+ ₩10,000 額外折扣{' '}
    </>,
    <>
      最大3回レッスン提供
      <br />+ ₩10,000 追加割引
    </>,
    <>
      Tối đa 3 buổi học thêm
      <br />+ ₩10,000 ưu đãi
    </>,
  ],
  benefit_2: [
    <>첫 수업 불만족 시 100% 환불 보장</>,
    <>100% Money-back guarantee</>,
    's_portal_home.benefit_2',
    <>首次课程不满意时可100%退款</>,
    <>首次課程不滿意時，可全額退款</>,
    <>初回レッスンにご満足いただけない場合、全額返金保証</>,
    <>100% hoàn tiền</>,
  ],
  no_coupons: [
    <>
      체험 없이 구매하고
      <br />
      <span className="text-purple-500">최대 혜택</span> 받기
    </>,
    <>
      <span className="text-purple-500">Best offer</span> available without trial lesson
    </>,
    's_portal_home.no_coupons',
    <>
      不体验直接购买时
      <br />
      <span className="text-purple-500">可获得</span> 最大优惠
    </>,
    <>
      未先體驗即購買
      <br />
      <span className="text-purple-500">領取</span>最高優惠
    </>,
    <>
      体験せず購入して
      <br />
      <span className="text-purple-500">最大特典</span> を受け取る
    </>,
    <>
      <span className="text-purple-500">Ưu đãi tốt nhất</span> bây giờ không bao gờm học thử
    </>,
  ],
  purchase: [`구매하기`, `Buy now`, 's_portal_home.purchase', `购买`, `購買`, `購入する`, `Mua ngay`],
  purchaseCredit: [
    <>
      첫 구매{' '}
      <Div spanTag textSecondary>
        할인 혜택
      </Div>
    </>,
    <>
      Benefits on{' '}
      <Div spanTag textSecondary>
        first purchase
      </Div>
    </>,
    's_portal_home.purchaseCredit',
    `首次购买优惠`,
    `首次購買折扣優惠`,
    `初回購入割引特典`,
    `Ưu đãi cho lần thanh toán đầu tiên`,
  ],
  upcoming: [
    `예정된 수업`,
    `Upcoming Lessons`,
    's_portal_home.upcoming',
    `预约的课程`,
    `預定的課程`,
    `レッスン予定`,
    `Buổi học sắp tới`,
  ],
  reviewed_yet2: [
    `복습 미완료 수업`,
    `Lessons to review`,
    's_portal_home.reviewed_yet2',
    `未复习的课程`,
    `未完成複習的課程`,
    `復習未完了のレッスン`,
    `Xem lại buổi học`,
  ],
  liveWebinar2: [
    `웨비나 라이브`,
    `Live Webinar`,
    's_portal_home.liveWebinar2',
    `Live网络研讨会`,
    `即時網路研討會`,
    `Live Webinar`,
    `Webinar trực tuyến`,
  ],
  tutor: [`튜터`, `Tutors`, 's_portal_home.tutor', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  tutor_like_added: [
    `[튜터 > 내 튜터] 찜한 튜터에 추가되었습니다.`,
    `Added to [Tutors > My Tutors] Favorites.`,
    's_portal_home.tutor_like_added',
    `添加至[教师 > 我的教师] 收藏的教师。`,
    `已新增於[任課教師 > 我的任課教師]我收藏的任課教師。`,
    `[チューター > マイチューター] お気に入りチューターに追加されました。`,
    `Thêm [Tutors > My Tutors] vào Yêu thích.`,
  ],
  tutor_like_deleted: [
    `[튜터 > 내 튜터] 찜한 튜터에서 삭제되었습니다.`,
    `Removed from [Tutors > My Tutors] .`,
    's_portal_home.tutor_like_deleted',
    `移出[教师 > 我的教师] 收藏的教师`,
    `已從[任課教師 > 我的任課教師]我收藏的任課教師刪除。`,
    `[チューター > マイチューター] お気に入りチューターから削除されました。`,
    `Bỏ [Tutors > My Tutors] .`,
  ],
  confirm_delete: [
    `콘텐츠 > 내 콘텐츠에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from Content > My Content?`,
    's_portal_home.confirm_delete',
    `确定要从内容 > 我的收藏中删除吗?`,
    `確定要從內容 > 我的收藏刪除嗎？`,
    `コンテンツ > マイコンテンツから削除しますか？`,
    `Bạn có chắc muốn bỏ khỏi Nội dung >  Nội dung của tôi?`,
  ],
  cancel: [`취소`, `Cancel`, 's_portal_home.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  remove: [`삭제`, `Remove`, 's_portal_home.remove', `删除`, `刪除`, `削除`, `Bỏ`],
  every_minute_dose_of_insight_english_everyday: [
    `하루 5분으로 쌓는 상식과 영어`,
    `5-minute dose of insight & English`,
    's_portal_home.every_minute_dose_of_insight_english_everyday',
    `每天5分钟积累的常识和英语`,
    `一天5分鐘累積常識與英語`,
    `1日5分で積み重ねる知見と英語`,
    `5 phút đọc thêm insight & tiếng Anh`,
  ],
  this_week_s_topic: [
    `이번 주 주제`,
    `Weekly Topic`,
    's_portal_home.this_week_s_topic',
    `本周主题`,
    `本週主題`,
    `今週のテーマ`,
    `Chủ đề hằng tuần`,
  ],
  today_s_tutor: [
    `오늘의 튜터`,
    `Tutor Highlights`,
    's_portal_home.today_s_tutor',
    `今日教师`,
    `今日任課教師`,
    `今日のチューター`,
    `Gia sư nổi bật`,
  ],
  check_out_tutors_recommended_by_ringle_users_: [
    `링글 수강생이 추천하는 튜터를 확인해 보세요.`,
    `Check out tutors recommended by Ringle users.`,
    's_portal_home.check_out_tutors_recommended_by_ringle_users_',
    `请确认Ringle学员们推荐的教师。`,
    `請確認Ringle學生推薦的任課教師。`,
    `Ringle受講生がおすすめするチューターをチェックしてみましょう。`,
    `Xem thử những gia sư được Ringle giới thiệu.`,
  ],
  view_all: [`전체 보기`, `See All`, 's_portal_home.view_all', `查看全部`, `查看全部`, `全体を見る`, `Xem toàn bộ`],
  latest_packets: [
    `새로나온 교재`,
    `Latest Materials`,
    's_portal_home.latest_packets',
    `最新教材`,
    `最新教材`,
    `最新の教材`,
    `Tài liệu mới nhất`,
  ],
  read_new_articles_released_every_week_: [
    `매일 업데이트되는 교재를 읽고, 수업을 바로 예약해 보세요!`,
    `Read new materials released every week.`,
    's_portal_home.read_new_articles_released_every_week_',
    `请确认每天更新的新教材。`,
    `請確認每天更新的新教材。`,
    `毎日アップデートされる新しい教材をチェックしてみましょう。`,
    `Đọc tài liệu mới ra mỗi ngày.`,
  ],
  featured_webinars: [
    `Upcoming 웨비나`,
    `Upcoming Webinar`,
    's_portal_home.featured_webinars',
    `即将举行的网络研讨会`,
    `即將舉行的網絡研討會`,
    `Upcoming ウェビナー`,
    `Hội thảo trên web sắp tới`,
  ],
  watch_video_lectures_taught_by_handpicked_tutors_: [
    `링글의 인기 튜터가 진행하는 LIVE 웨비나를 신청해 보세요.`,
    `Attend live webinars hosted by Ringle's top tutors.`,
    's_portal_home.watch_video_lectures_taught_by_handpicked_tutors_',
    `观看 Ringle 任课教师的现场网络研讨会`,
    `觀看 Ringle 任課教師的現場網絡研討會。`,
    `Ringleの人気チューターが進行するLIVEウェビナーをお申し込みください。`,
    `Xem hội thảo trực tiếp trên web do các gia sư nổi tiếng của Ringle tổ chức.`,
  ],
  confirm_delete_tutor: [
    `[튜터 > 내 튜터] 찜한 튜터에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from [Tutors > My Tutors] Favorite?`,
    's_portal_home.confirm_delete_tutor',
    `确定要从[教师 > 我的教师] 收藏的教师中删除吗?`,
    `確定要從[任課教師 > 我的任課教師]我收藏的任課教師刪除嗎？`,
    `[チューター > マイチューター] お気に入りチューターから削除しますか？`,
    `Bạn có chắc muốn bỏ khỏi [Gia sư > Gia sư của tôi] danh sách yêu thích?`,
  ],
  unlike: [
    `찜하기 해제`,
    `Remove from Favorites`,
    's_portal_home.unlike',
    `移出收藏`,
    `取消收藏`,
    `お気に入り削除`,
    `Bỏ khỏi Mục yêu thích`,
  ],
  material_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 교재에 추가되었습니다.`,
    `Added to [Content > My Content] Favorite Materials.`,
    's_portal_home.material_like_added',
    `添加至[内容>我的收藏]收藏的教材。`,
    `已新增於[內容 > 我的收藏]我收藏的教材。`,
    `[コンテンツ > マイコンテンツ] お気に入り教材に追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Tài liệu Yêu thích.`,
  ],
  material_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 교재에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Favorite Materials.`,
    's_portal_home.material_like_deleted',
    `移出[内容>我的收藏]收藏的教材。`,
    `已從[內容 > 我的收藏]我收藏的教材刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入り教材から削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Tài liệu Yêu thích.`,
  ],
  brief_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에 추가되었습니다.`,
    `Added to [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_added',
    `添加至[内容>我的收藏]收藏的Daily Brief。`,
    `已新增於[內容 > 我的收藏]我收藏的Daily Brief。`,
    `[コンテンツ > マイコンテンツ] お気に入りDaily Briefに追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Daily Brief Yêu thích.`,
  ],
  brief_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_deleted',
    `移出[内容>我的收藏]收藏的Daily Brief。`,
    `已從[內容 > 我的收藏]我收藏的Daily Brief刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入りDaily Briefから削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Daily Brief Yêu thích.`,
  ],
  webinar_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에 추가되었습니다.`,
    `Added to [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_added',
    `添加至[内容>我的收藏]收藏的网络研讨会。`,
    `已新增於[內容 > 我的收藏]我收藏的網路研討會。`,
    `[コンテンツ > マイコンテンツ] お気に入りウェビナーに追加されました。`,
    `Thêm vào [Nội dung > Nội dung của tôi] Webinar Yêu thích.`,
  ],
  webinar_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_deleted',
    `移出[内容>我的收藏] 收藏的网络研讨会。`,
    `已從[內容 > 我的收藏]我收藏的網路研討會刪除。`,
    `[コンテンツ > マイコンテンツ] お気に入りウェビナーから削除されました。`,
    `Bỏ khỏi [Nội dung > Nội dung của tôi] Webinar Yêu thích.`,
  ],
  title_ot: [
    `오리엔테이션 신청하기`,
    `Register Orientation`,
    's_portal_home.title_ot',
    `申请使用教学`,
    `申請使用教學`,
    `Register Orientation`,
    `Register Orientation`,
  ],
  subtitle_ot: [
    `링글 수업 200% 활용법`,
    `Make the best of Ringle`,
    's_portal_home.subtitle_ot',
    `Make the best of Ringle`,
    `Make the best of Ringle`,
    `Make the best of Ringle`,
    `Make the best of Ringle`,
  ],
  benefit_3: [
    <>최대 100,000원 추가 할인</>,
    <>Up to $77 Discount</>,
    's_portal_home.benefit_3',
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
    <>Up to $77 Discount</>,
  ],
  purchase_welcome: [
    <>
      신규회원{' '}
      <Div spanTag textSecondary>
        웰컴 쿠폰
      </Div>
    </>,
    <Div textSecondary>Welcome Coupon</Div>,
    's_portal_home.purchase_welcome',
    `首次购买优惠`,
    `首次購買折扣優惠`,
    `初回購入割引特典`,
    `Ưu đãi cho lần thanh toán đầu tiên`,
  ],
  gnb: {
    welcome_coupon: (day) => [
      <>
        신규회원 한정 3만원 웰컴쿠폰이{' '}
        <Div spanTag fontBold>
          {day}
        </Div>{' '}
        사라집니다
      </>,
      <>
        Last {day} for{' '}
        <Div spanTag fontBold>
          $25 off welcome offers
        </Div>
      </>,
      's_portal_home.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    welcome_coupon: {
      title_1: [`신규회원 한정`, `Welcome Gift`, 's_portal_home.popup.welcome_coupon.title_1', ``, ``, ``, ``],
      title_2: [
        `웰컴쿠폰 발급 안내`,
        `for new members only`,
        's_portal_home.popup.welcome_coupon.title_2',
        ``,
        ``,
        ``,
        ``,
      ],
      footer: [
        <>
          *쿠폰 발급 후 7일 이내 사용 가능합니다.
          <br />
        </>,
        <>
          * Valid for 7 days after issuance.
          <br />* Only redeemable toward regular packages worth $169 or more.
        </>,
        's_portal_home.popup.welcome_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`혜택 확인하기`, `See Details`, 's_portal_home.popup.welcome_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`닫기`, `Close`, 's_portal_home.popup.welcome_coupon.btn_2', ``, ``, ``, ``],
      btn_3: [`일주일간 보지 않기`, `Don't show again`, 's_portal_home.popup.welcome_coupon.btn_3', ``, ``, ``, ``],
    },
  },
  silicon_challenge: {
    title: [
      `실리콘밸리 챌린지`,
      `Silicon Valley Challenge`,
      's_portal_home.silicon_challenge.title',
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
      `Silicon Valley Challenge`,
    ],
    time_left: (day, number) => [
      <>🔥 {day}일 남음</>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.time_left',
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
    ],
    detail: (participants) => [
      <>🎯 {participants}명이 함께하고 있어요.</>,
      <>🎯 {participants} Ringlers participating</>,
      's_portal_home.silicon_challenge.detail',
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
      <>🎯 {participants} Ringlers participating</>,
    ],
    silicon_percent: (percent) => [
      <>{percent}%</>,
      <>{percent}%</>,
      's_portal_home.silicon_challenge.silicon_percent',
      <>{percent}%</>,
      <>{percent}%</>,
      <>{percent}%</>,
      <>{percent}%</>,
    ],
    silicon_lessons: (number, count) => [
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count}회 달성
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.silicon_lessons',
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
    ],
    monthly_mission: (month) => [
      <>{month}월 미션</>,
      <>{month} Mission</>,
      's_portal_home.silicon_challenge.monthly_mission',
      <>{month} Mission</>,
      <>{month} Mission</>,
      <>{month} Mission</>,
      <>{month} Mission</>,
    ],
    monthly_lessons: (number, count) => [
      <>
        <span className="text-black">{number}</span>/{count}회 완료
      </>,
      <>
        {number}/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.monthly_lessons',
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
      <>
        {number}/{count} Lessons
      </>,
    ],
    last_week: [
      `마지막 주`,
      `Last Week`,
      's_portal_home.silicon_challenge.last_week',
      `Last Week`,
      `Last Week`,
      `Last Week`,
      `Last Week`,
    ],
    last_week_msg: [
      `챌린지 기간이 얼마 안남았어요!`,
      `There's not much time left!`,
      's_portal_home.silicon_challenge.last_week_msg',
      `There's not much time left!`,
      `There's not much time left!`,
      `There's not much time left!`,
      `There's not much time left!`,
    ],
    start_challenge: [
      `챌린지 시작하기!`,
      `Start Challenge!`,
      's_portal_home.silicon_challenge.start_challenge',
      `Start Challenge!`,
      `Start Challenge!`,
      `Start Challenge!`,
      `Start Challenge!`,
    ],
    menu_tooltip: [
      `무료 수업권`,
      `Free Credit`,
      's_portal_home.silicon_challenge.menu_tooltip',
      `Free Credit`,
      `Free Credit`,
      `Free Credit`,
      `Free Credit`,
    ],
    msg: [
      <>
        수업도 듣고,
        <br />
        <Div spanTag fontBold>
          챌린지 혜택
        </Div>
        도 받으세요.
      </>,
      <>
        <Div spanTag fontBold>
          Earn rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      's_portal_home.silicon_challenge.msg',
      <>
        <Div spanTag fontBold>
          Earn rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      <>
        Earn{' '}
        <Div spanTag fontBold>
          rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
    ],
    btn_join_challenge: [
      `1초만에 신청하기`,
      `Start now`,
      's_portal_home.silicon_challenge.btn_join_challenge',
      `Start now`,
      `Start now`,
      `Start now`,
      `Start now`,
    ],
    see_detail: [
      `자세히 보기`,
      `See details`,
      's_portal_home.silicon_challenge.see_detail',
      `See details`,
      `See details`,
      `See details`,
      `See details`,
    ],
    forty: [
      `40분 수업권`,
      `40min credit`,
      's_portal_home.silicon_challenge.forty',
      `40min credit`,
      `40min credit`,
      `40min credit`,
      `40min credit`,
    ],
    twenty: [
      `20분 수업권`,
      `20min credit`,
      's_portal_home.silicon_challenge.twenty',
      `20min credit`,
      `20min credit`,
      `20min credit`,
      `20min credit`,
    ],
    point: [
      `참여 혜택 포인트`,
      `Points earned`,
      's_portal_home.silicon_challenge.point',
      `Points earned`,
      `Points earned`,
      `Points earned`,
      `Points earned`,
    ],
    benefit_left: [
      `다음 포인트 획득까지`,
      `Next points`,
      's_portal_home.silicon_challenge.benefit_left',
      `Next points`,
      `Next points`,
      `Next points`,
      `Next points`,
    ],
    benefit_ticket: [
      `여행 응모권 획득까지`,
      `Raffle ticket`,
      's_portal_home.silicon_challenge.benefit_ticket',
      `Raffle ticket`,
      `Raffle ticket`,
      `Raffle ticket`,
      `Raffle ticket`,
    ],
    left: (number) => [
      <>{number}회 남음</>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.left',
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
    ],
    date: [
      `06.08(수) - 10.06(목)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      's_portal_home.silicon_challenge.date',
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
    ],
    msg1: (point) => [
      <>
        수업만 들어도 챌린지 혜택을 드립니다! 지금 참여하시면{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point}P
        </span>
        가 즉시 적립됩니다.
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      's_portal_home.silicon_challenge.msg1',
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
    ],
    msg2: [
      `수업만 들어도 챌린지 혜택을 드립니다!`,
      `Earn rewards just by taking Ringle lessons!`,
      's_portal_home.silicon_challenge.msg2',
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
      `Earn rewards just by taking Ringle lessons!`,
    ],
    mission: (number) => [
      <>{number}회 완강 시 무료 수업권 증정</>,
      <>Free credit rewarded for completing {number} lessons</>,
      's_portal_home.silicon_challenge.mission',
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
      <>Free credit rewarded for completing {number} lessons</>,
    ],
    msg3: [
      `수업만 들어도 챌린지 혜택을 드립니다! 수업권을 구매하고 챌린지에 참여하세요.`,
      `Buy credits now and join the challenge.`,
      's_portal_home.silicon_challenge.msg3',
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
      `Buy credits now and join the challenge.`,
    ],
    oct_details: [
      `스탬프 6개 채우고 수업권 받기`,
      `Collect 6 stamps!`,
      's_portal_home.silicon_challenge.oct_details',
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
      `Collect 6 stamps!`,
    ],
  },
  label_today: [`Today`, `Today`, 's_portal_home.label_today', `Today`, `Today`, `Today`, `Today`],
  coupon: {
    title_welcome: [
      `신규회원 쿠폰`,
      `Welcome Coupon`,
      's_portal_home.coupon.title_welcome',
      `新会员欢迎优惠券`,
      `新會員歡迎優惠券`,
      `新規会員ウェルカムクーポン`,
      `Phiếu giảm giá chào mừng thành viên mới`,
    ],
    desc_welcome: [
      <>
        <Div spanTag fontBold>
          최대 20만원 할인
        </Div>{' '}
        받는 지금이
        <br />
        링글 시작하기 가장 좋은 타이밍!
      </>,
      <>
        Start Ringle now
        <br />&{' '}
        <Div spanTag fontBold>
          get up to $153 off!
        </Div>
      </>,
      's_portal_home.coupon.desc_welcome',
      <>
        <Div spanTag fontBold>
          现在开始Ringle课程最多优惠$153
        </Div>
        ，<br />
        不要错过此次最佳时机！
      </>,
      <>
        <Div spanTag fontBold>
          現在開始Ringle課程最多優惠$153
        </Div>
        ，<br />
        不要錯過此次最佳時機！
      </>,
      `最大$153割引を受けられる今がRingleを始めるグッドタイミング！`,
      ``,
    ],
    btn: [
      `수업권 구매하기`,
      `Buy Credits`,
      's_portal_home.coupon.btn',
      `购买课程券`,
      `購買課程券`,
      `レッスンチケットを購入`,
      `Mua vé buổi học`,
    ],
    title_next: [
      `재구매 쿠폰`,
      `Next Purchase`,
      's_portal_home.coupon.title_next',
      `回购折扣券`,
      `回購折扣券`,
      `再購入割引クーポン`,
      `Mua lại phiếu giảm giá`,
    ],
    desc_next: [
      <>
        최대 10만원 추가 할인된
        <br />
        가격으로 수업권을 구매하세요!
      </>,
      <>
        Buy credits at prices
        <br />
        discounted up to $77!
      </>,
      's_portal_home.coupon.desc_next',
      `以最多再优惠$77的价格购买课程券吧！`,
      `以最多再優惠$77的價格購買課程券吧！`,
      `最大$77がさらに割引された価格でレッスンチケットを購入しましょう！`,
      `Mua vé buổi học với mức giảm thêm lên đến $77!`,
    ],
    title_secret: [
      `시크릿 쿠폰 할인`,
      `Secret Offer`,
      's_portal_home.coupon.title_secret',
      `Secret Offer`,
      `Secret Offer`,
      `Secret Offer`,
      `Secret Offer`,
    ],
    desc_secret: [
      <>
        23년 가격 인상 전 마지막
        <br />
        최대 30만원 추가 할인!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      's_portal_home.coupon.desc_secret',
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
    ],
  },
  default_stat: {
    title: (name, n) => [
      <>
        {name}님의
        <br />
        링글 학습 {n}일째
      </>,
      <>
        {name}'s
        <br />
        Day {n} with Ringle
      </>,
      's_portal_home.default_stat.title',
      <>
        {name}的Ringle学习第{n}天
      </>,
      <>
        {name}的Ringle學習第{n}天
      </>,
      <>
        {name}様のRingle学習 {n}日目
      </>,
      <>
        Ngày thứ {n} của {name} với Ringle
      </>,
    ],
    desc1: [
      `지금까지 링글 수업`,
      `Completed`,
      's_portal_home.default_stat.desc1',
      `至今为止的课程`,
      `至今為止的課程`,
      `今までのレッスン`,
      `Buổi học cho đến nay`,
    ],
    desc2: [
      `이번 달 링글 수업`,
      `This month`,
      's_portal_home.default_stat.desc2',
      `本月的课程`,
      `本月的課程`,
      `今月のレッスン`,
      `Buổi học trong tháng này`,
    ],
    desc3: [
      `연속 수업 진행`,
      `In a row`,
      's_portal_home.default_stat.desc3',
      `连续课程学习`,
      `連續課程學習`,
      `連続でレッスン進行`,
      `Hướng dẫn liên tục`,
    ],
    total: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.total',
      <>{n} 课程</>,
      <>{n} 課程</>,
      <>{n} 回</>,
      <>{n} </>,
    ],
    this_month: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.this_month',
      <>{n} 课程</>,
      <>{n} 課程</>,
      <>{n} 回</>,
      <>{n} </>,
    ],
    week: (n) => [
      <>{n}주</>,
      <>
        {n} {n > 1 ? 'weeks' : 'week'}
      </>,
      's_portal_home.default_stat.week',
      <>{n} 星期</>,
      <>{n} 星期</>,
      <>{n} 週</>,
      <>{n} tuần</>,
    ],
  },
  to_do: {
    msg_list: [
      `링글이 처음이라면 아래 목록을 진행해보세요!`,
      `If you're new to Ringle, check out the list below!`,
      's_portal_home.to_do.msg_list',
      `如果您是第一次使用Ringle，请按照下面目录进行操作。`,
      `如果您是第一次使用Ringle，請按照下面目錄進行操作。`,
      `Ringleが初めてなら下のリストを進めてみましょう！`,
      `Nếu bạn mới sử dụng Ringle, hãy tiếp tục với danh sách bên dưới!`,
    ],
    btn1: [
      `링글 사용법 알아보기`,
      `How to use Ringle`,
      's_portal_home.to_do.btn1',
      `了解Ringle使用方法`,
      `瞭解Ringle使用方法`,
      `Ringleの使用方法を調べる`,
      `Tìm hiểu cách sử dụng Ringle`,
    ],
    btn2: [
      `OT 신청하기`,
      `Sign up for Orientation`,
      's_portal_home.to_do.btn2',
      `申请OT`,
      `申請OT`,
      `OTを申請する`,
      `Đăng ký OT`,
    ],
    btn3: [
      `튜터 찜하기`,
      `Add tutors to [My Tutors]`,
      's_portal_home.to_do.btn3',
      `收藏想上课的任课教师`,
      `收藏想上課的任課教師`,
      `レッスンを受けたいチューターをいいねする`,
      `Hấp dẫn gia sư bạn muốn dạy`,
    ],
    btn4: [
      `교재 찜하기`,
      <>
        Add materials
        <br />
        to [My Content]
      </>,
      's_portal_home.to_do.btn4',
      `收藏想申请的课程`,
      `收藏想申請的課程`,
      `レッスンしたい教材をいいねする`,
      `Hấp các tài liệu bạn muốn dạy`,
    ],
    placeholder_book: [
      `예정된 수업이 없습니다. 수업을 예약해 보세요!`,
      `You have no scheduled lesson. Why don't you book one?`,
      's_portal_home.to_do.placeholder_book',
      `没有预定的课程。请预约课程。`,
      `沒有預定的課程。請預約課程。`,
      `予定されたレッスンはありません。レッスンを予約してみましょう！`,
      `Tôi không có một buổi học theo lịch trình. Đặt một buổi học!`,
    ],
    btn_book: [
      `수업 예약하기`,
      `Book a Lesson`,
      's_portal_home.to_do.btn_book',
      `预约课程`,
      `預約課程`,
      `レッスンを予約する`,
      `Đặt buổi học`,
    ],
    placeholder_purchase: [
      `수업권을 구매하고 수업을 시작해 보세요!`,
      `Buy credits and start taking lessons!`,
      's_portal_home.to_do.placeholder_purchase',
      `购买课程券开始上课`,
      `購買課程券開始上課`,
      `レッスンチケットを購入してレッスンを始めましょう！`,
      `Mua vé buổi học của bạn và bắt đầu buổi học của bạn!`,
    ],
    btn_purchase: [
      `수업권 구매하기`,
      `Buy Credits`,
      's_portal_home.to_do.btn_purchase',
      `购买课程券`,
      `購買課程券`,
      `レッスンチケットを購入`,
      `Mua vé buổi học`,
    ],
    msg_prep: [
      `예습을 진행하고 더 효과적인 수업을 경험해 보세요.`,
      `Check and prep for your upcoming lesson.`,
      's_portal_home.to_do.msg_prep',
      `请确认预定的课程并进行预习。`,
      `請確認預定的課程並進行預習。`,
      `予定されたレッスンを確認して予習を進めてください。`,
      `Kiểm tra các buổi học sắp tới và trước buổi học của bạn.`,
    ],
    title_lesson_crad: [
      `예정된 수업`,
      `Upcoming Lesson`,
      's_portal_home.to_do.title_lesson_crad',
      `预定课程`,
      `預定課程`,
      `予定されたレッスン`,
      `Các buổi học theo lịch trình`,
    ],
    btn_prep: [`예습하기`, `Lesson Prep`, 's_portal_home.to_do.btn_prep', `预习`, `預習`, `予習する`, `Học trước`],
    msg_lesson: [
      `곧 수업이 시작됩니다! 수업에 입장해 주세요.`,
      `The lesson is about to start! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson',
      `课程马上开始！请入场上课`,
      `課程馬上開始！請入場上課`,
      `すぐにレッスンが始まります！レッスンに入場してください。`,
      `Các buổi học sẽ sớm bắt đầu! Vui lòng tham gia buổi học.`,
    ],
    btn_lesson: [
      `입장하기`,
      `Enter Lesson`,
      's_portal_home.to_do.btn_lesson',
      `课程入场`,
      `進入課程`,
      `レッスン開始`,
      `Vào học`,
    ],
    msg_cancel: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 진심으로 사과드립니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled due to the tutor’s unforeseen circumstance. </>,
      's_portal_home.to_do.msg_cancel',
      <>由于任课教师不可避免的原因，{time}课程被取消。非常抱歉。</>,
      <>由於任課教師不可避免的原因，{time}課程被取消。非常抱歉。</>,
      <>チューターの避けられない事情により {time} レッスンがキャンセルされました。申し訳ございません。</>,
      <>Do gia sư không thể chào hàng, buổi học {time} đã bị hủy bỏ. Tôi chân thành xin lỗi.</>,
    ],
    msg_automatch: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 빠르게 다른 튜터를 찾아 매칭하겠습니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled. We will assign a new tutor for you.</>,
      's_portal_home.to_do.msg_automatch',
      <>由于任课教师不可避免的原因，{time}课程被取消，将尽快为您安排其他任课教师。</>,
      <>由於任課教師不可避免的原因，{time}課程被取消，將儘快為您安排其他任課教師。</>,
      <>
        チューターの避けられない事情により {time}{' '}
        レッスンがキャンセルされました。すぐに他のチューターをマッチングさせていただきます。
      </>,
      <>
        Do gia sư không thể chào hàng, buổi học {time} đã bị hủy bỏ. Tôi sẽ nhanh chóng tìm và kết hợp các gia sư khác.
      </>,
    ],
    msg_start_lesson: [
      `효과적인 수업을 위해 미리 교재를 읽고 수업 질문을 선택해 주세요.`,
      `Please read the lesson material and choose questions in advance.`,
      's_portal_home.to_do.msg_start_lesson',
      `为了提高课程效率，请提前阅读教材内容，选择课程问题。`,
      `爲了提高課程效率，請提前閱讀教材內容，選擇課程問題。`,
      `あらかじめ教材を読んで質問を選択してください。`,
      `Hãy đọc trước tài liệu và chọn trước câu hỏi thảo luận.`,
    ],
    msg_prep_freetopic: [
      `효과적인 수업을 위해 수업 자료를 미리 업로드해 주세요.`,
      `Please upload the lesson materials in advance.`,
      's_portal_home.to_do.msg_prep_freetopic',
      `为了提高课程效率，请提前上传课程资料。`,
      `爲了提高課程效率，請提前上傳課程資料。`,
      `あらかじめレッスン資料をアップロードしてください。`,
      `Hãy tải lên trước tài liệu để có một buổi học hiệu quả.`,
    ],
    tutor_not_matched_yet: [
      `튜터가 배정 중입니다.`,
      `We're finding a tutor for you.`,
      's_portal_home.to_do.tutor_not_matched_yet',
      `正在分配任课教师。`,
      `正在分配任課教師。`,
      `チューターを割り当て中です。`,
      `Chúng tôi đang tìm gia sư cho bạn`,
    ],
    msg_lesson_started: [
      `수업이 시작되었습니다! 수업에 입장해 주세요.`,
      `The lesson has already begun! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson_started',
      `课程已经开始，请入场上课。`,
      `課程已經開始，請入場上課。`,
      `レッスンが始まりました！レッスンに入ってください。`,
      `Buổi học đã bắt đầu! Hãy tham gia buổi học.`,
    ],
  },
  monthly_challenge: {
    msg: [
      `오늘부터 링글팀과 영어 공부 루틴을 만들어보세요!`,
      `Make a routine of studying English with Ringle!`,
      's_portal_home.monthly_challenge.msg',
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
      `Make a routine of studying English with Ringle!`,
    ],
    btn_join: [
      `챌린지 참여하기`,
      `Join Challenge`,
      's_portal_home.monthly_challenge.btn_join',
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
    ],
    title_oct: [
      `10월 수업 챌린지`,
      `October Challenge`,
      's_portal_home.monthly_challenge.title_oct',
      `October Challenge`,
      `October Challenge`,
      `October Challenge`,
      `October Challenge`,
    ],
    title_nov: [
      `11월 수업 챌린지`,
      `November Challenge`,
      's_portal_home.monthly_challenge.title_nov',
      `November Challenge`,
      `November Challenge`,
      `November Challenge`,
      `November Challenge`,
    ],
    title_dec: [
      `12월 수업 챌린지`,
      `December Challenge`,
      's_portal_home.monthly_challenge.title_dec',
      `December Challenge`,
      `December Challenge`,
      `December Challenge`,
      `December Challenge`,
    ],
    mission: [
      `보너스 스탬프 미션`,
      `Bonus Mission`,
      's_portal_home.monthly_challenge.mission',
      `Bonus Mission`,
      `Bonus Mission`,
      `Bonus Mission`,
      `Bonus Mission`,
    ],
    mission_board: [
      `4주 연속 수업 듣기 현황`,
      `Status for taking lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.mission_board',
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
      `Status for taking lessons 4 weeks in a row`,
    ],
    streak: [
      `이번 달 4주 연속 수업 듣기`,
      `Take lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.streak',
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
      `Take lessons 4 weeks in a row`,
    ],
    fast_lesson: (date) => [
      <>{date}까지 첫 수업 듣기</>,
      <>Take first lesson by {date}</>,
      's_portal_home.monthly_challenge.fast_lesson',
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
      <>Take first lesson by {date}</>,
    ],
    see_detail: [
      `자세히 보기`,
      `See details`,
      's_portal_home.monthly_challenge.see_detail',
      `See details`,
      `See details`,
      `See details`,
      `See details`,
    ],
    modal_title_mkt: [
      `챌린지 참여 신청`,
      `Join the challenge`,
      's_portal_home.monthly_challenge.modal_title_mkt',
      `Join the challenge`,
      `Join the challenge`,
      `Join the challenge`,
      `Join the challenge`,
    ],
    modal_desc: [
      `챌린지에 참여하게 되면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      's_portal_home.monthly_challenge.modal_desc',
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
    ],
    modal_btn1: [
      `나중에 다시 보기`,
      `Skip for Now`,
      's_portal_home.monthly_challenge.modal_btn1',
      `Skip for Now`,
      `Skip for Now`,
      `Skip for Now`,
      `Skip for Now`,
    ],
    modal_btn2: [
      `챌린지 참여하기`,
      `Join Challenge`,
      's_portal_home.monthly_challenge.modal_btn2',
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
    ],
    toast: (date) => [
      <>{date} 마케팅 수신에 동의하셨습니다.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      's_portal_home.monthly_challenge.toast',
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
    ],
    modal_title: [
      `수업 챌린지`,
      `Monthly Challenge`,
      's_portal_home.monthly_challenge.modal_title',
      `Monthly Challenge`,
      `Monthly Challenge`,
      `Monthly Challenge`,
      `Monthly Challenge`,
    ],
    oct: [`10월 챌린지`, `October`, 's_portal_home.monthly_challenge.oct', `October`, `October`, `October`, `October`],
    date_oct: [
      `2022. 10. 1 - 2022. 10. 31`,
      `2022. 10. 1 - 2022. 10. 31`,
      's_portal_home.monthly_challenge.date_oct',
      `2022. 10. 1 - 2022. 10. 32`,
      `2022. 10. 1 - 2022. 10. 33`,
      `2022. 10. 1 - 2022. 10. 34`,
      `2022. 10. 1 - 2022. 10. 35`,
    ],
    nov: [
      `11월 챌린지`,
      `November`,
      's_portal_home.monthly_challenge.nov',
      `November`,
      `November`,
      `November`,
      `November`,
    ],
    date_nov: [
      `2022. 11. 1 - 2022. 11. 30`,
      `2022. 11. 1 - 2022. 11. 30`,
      's_portal_home.monthly_challenge.date_nov',
      `2022. 11. 1 - 2022. 11. 31`,
      `2022. 11. 1 - 2022. 11. 32`,
      `2022. 11. 1 - 2022. 11. 33`,
      `2022. 11. 1 - 2022. 11. 34`,
    ],
    dec: [
      `12월 챌린지`,
      `December`,
      's_portal_home.monthly_challenge.dec',
      `December`,
      `December`,
      `December`,
      `December`,
    ],
    date_dec: [
      `2022. 12. 1 - 2022. 12. 31`,
      `2022. 12. 1 - 2022. 12. 31`,
      's_portal_home.monthly_challenge.date_dec',
      `2022. 12. 1 - 2022. 12. 32`,
      `2022. 12. 1 - 2022. 12. 33`,
      `2022. 12. 1 - 2022. 12. 34`,
      `2022. 12. 1 - 2022. 12. 35`,
    ],
    credit_20min: [
      `20분 수업권`,
      `20-min credit`,
      's_portal_home.monthly_challenge.credit_20min',
      `20-min credit`,
      `20-min credit`,
      `20-min credit`,
      `20-min credit`,
    ],
    week: [`주차`, `Week`, 's_portal_home.monthly_challenge.week', `Week`, `Week`, `Week`, `Week`],
    date: [`기간`, `Period`, 's_portal_home.monthly_challenge.date', `Period`, `Period`, `Period`, `Period`],
    lesson: [
      `수업 수`,
      `Lessons`,
      's_portal_home.monthly_challenge.lesson',
      `Lessons`,
      `Lessons`,
      `Lessons`,
      `Lessons`,
    ],
    done: [`달성 여부`, `Status`, 's_portal_home.monthly_challenge.done', `Status`, `Status`, `Status`, `Status`],
    oct_week1: [
      `10. 3(월) - 10. 9(일)`,
      `Oct 3(M) - Oct 9(S)`,
      's_portal_home.monthly_challenge.oct_week1',
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
      `Oct 3(M) - Oct 9(S)`,
    ],
    oct_week2: [
      `10. 10(월) - 10. 16(일)`,
      `Oct 10(M) - Oct 16(S)`,
      's_portal_home.monthly_challenge.oct_week2',
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
      `Oct 10(M) - Oct 16(S)`,
    ],
    oct_week3: [
      `10.17(월) - 10. 23(일)`,
      `Oct 17(M) - Oct 23(S)`,
      's_portal_home.monthly_challenge.oct_week3',
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
      `Oct 17(M) - Oct 23(S)`,
    ],
    oct_week4: [
      `10. 24(월) - 10. 30(일)`,
      `Oct 24(M) - Oct 30(S)`,
      's_portal_home.monthly_challenge.oct_week4',
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
      `Oct 24(M) - Oct 30(S)`,
    ],
    nov_week1: [
      `10. 31(월) - 11. 6(일)`,
      `Oct 31(M) - Nov 6(S)`,
      's_portal_home.monthly_challenge.nov_week1',
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
      `Oct 31(M) - Nov 6(S)`,
    ],
    nov_week2: [
      `11. 7(월) - 11. 13(일)`,
      `Nov 7(M) - Nov 13(S)`,
      's_portal_home.monthly_challenge.nov_week2',
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
      `Nov 7(M) - Nov 13(S)`,
    ],
    nov_week3: [
      `11. 14(월) - 11. 20(일)`,
      `Nov 14(M) - Nov 20(S)`,
      's_portal_home.monthly_challenge.nov_week3',
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
      `Nov 14(M) - Nov 20(S)`,
    ],
    nov_week4: [
      `11. 21(월) - 11. 27(일)`,
      `Nov 21(M) - Nov 27(S)`,
      's_portal_home.monthly_challenge.nov_week4',
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
      `Nov 21(M) - Nov 27(S)`,
    ],
    dec_week1: [
      `11. 28(월) - 12. 4(일)`,
      `Nov 28(M) - Dec 4(S)`,
      's_portal_home.monthly_challenge.dec_week1',
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
      `Nov 28(M) - Dec 4(S)`,
    ],
    dec_week2: [
      `12. 5(월) - 12. 11(일)`,
      `Dec 5(M) - Dec 11(S)`,
      's_portal_home.monthly_challenge.dec_week2',
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
      `Dec 5(M) - Dec 11(S)`,
    ],
    dec_week3: [
      `12. 12(월) - 12. 18(일)`,
      `Dec 12(M) - Dec 18(S)`,
      's_portal_home.monthly_challenge.dec_week3',
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
      `Dec 12(M) - Dec 18(S)`,
    ],
    dec_week4: [
      `12. 19(월) - 12. 25(일)`,
      `Dec 19(M) - Dec 25(S)`,
      's_portal_home.monthly_challenge.dec_week4',
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
      `Dec 19(M) - Dec 25(S)`,
    ],
    note: [
      `챌린지 진행 방식 자세히 보기`,
      `About the Monthly Challenge`,
      's_portal_home.monthly_challenge.note',
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
      `About the Monthly Challenge`,
    ],
    note_body: [
      <>
        <b>STEP 1.</b> 링글 수업 듣고 스탬프 받기
        <br />
        <b>STEP 2.</b> 매달 스탬프 채우고 20분 무료 수업권 받기
        <br />
        + 보너스 스탬프와 서프라이즈 혜택도 놓치지 마세요!
        <br />
        <br />
        <b>혜택 🎁</b>
        <br />
        • 챌린지 달성자 전원에게 링글 계정으로 20분 수업권을 증정합니다. (수강기간: 지급일 기준 14일)
        <br />
        • [12월 한정] 챌린지 달성자 전원에게 1월 라이브 유료 서비스 이용권을 증정합니다.
        <br />
        • 챌린지 달성자 중 10명을 추첨하여 매달 5만원 상당의 서프라이즈 선물을 증정합니다. (1/6(금) 예정)
        <br />
        <br />
        <b>공통 안내</b>
        <br />
        • 모든 일정은 한국 시간 기준입니다.
        <br />
        • 혜택 당첨자는 발표일에 개별 안내해 드립니다.
        <br />
        • 잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.
        <br />
        • 본 이벤트는 월별 챌린지로 매달 새로 신청해야 합니다.
        <br />
        • 본 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.
        <br />
        <br />
        <b>수업 기준</b>
        <br />
        • 모든 수업권으로 매월 마지막 일 23:30PM에 시작한 수업까지 해당 월의 수업으로 인정됩니다.
        <br />
        • 수업 정상 수강, 튜터 노쇼, 수업 정각에 튜터 미배정이 발생한 경우에도 스탬프를 제공합니다.
        <br />• 40분/20분 수업권을 교차 수강할 경우, 40분/20분 수업 챌린지가 개별 적용됩니다.
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      's_portal_home.monthly_challenge.note_body',
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
    ],
    acheived: [
      `성공`,
      `Accomplished`,
      's_portal_home.monthly_challenge.acheived',
      `Accomplished`,
      `Accomplished`,
      `Accomplished`,
      `Accomplished`,
    ],
    fail: [`미달성`, `Skipped`, 's_portal_home.monthly_challenge.fail', `Skipped`, `Skipped`, `Skipped`, `Skipped`],
    in_progress: [
      `진행중`,
      `Ongoing`,
      's_portal_home.monthly_challenge.in_progress',
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
      `Ongoing`,
    ],
    min_40: [`40분`, `40min`, 's_portal_home.monthly_challenge.min_40', `40min`, `40min`, `40min`, `40min`],
    min_20: [`20분`, `20min`, 's_portal_home.monthly_challenge.min_20', `20min`, `20min`, `20min`, `20min`],
    crm_msg_20min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_20min_1',
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
    ],
    crm_msg_20min_2: [
      `💯 축하합니다! 두 번째 스탬프가 성공적으로 추가되었어요.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      's_portal_home.monthly_challenge.crm_msg_20min_2',
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
    ],
    crm_msg_20min_3: [
      `🔥 세 번째 스탬프 추가! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_20min_3',
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
    ],
    crm_msg_20min_4: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_20min_4',
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
    ],
    crm_msg_20min_5: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_20min_5',
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
    ],
    crm_msg_20min_6: [
      `🏆 꾸준히 수업해서 벌써 여섯 번째 도장을 받으셨어요!`,
      `🏆 You already received 6 stamps!`,
      's_portal_home.monthly_challenge.crm_msg_20min_6',
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
      `🏆 You already received 6 stamps!`,
    ],
    crm_msg_20min_7: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_20min_7',
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
    ],
    crm_msg_20min_8: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_20min_8',
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
    ],
    crm_msg_40min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_40min_1',
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      `🎉 A great start! You successfully completed your first lesson!`,
    ],
    crm_msg_40min_2: [
      `🔥 두 번째 스탬프 완료! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_40min_2',
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
    ],
    crm_msg_40min_3: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_40min_3',
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
    ],
    crm_msg_40min_4: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_40min_4',
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
    ],
    crm_msg_40min_5: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_40min_5',
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
    ],
    crm_msg_40min_6: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_40min_6',
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
    ],
    modal_checkbox: [
      `(선택) 매일 새로운 영어 표현을 배우면서 응원받기💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      's_portal_home.monthly_challenge.modal_checkbox',
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
    ],
    modal_desc2: [
      `응원 알림을 추가로 신청 하신 분들에 한하여 성공적인 챌린지 완주를 위한 영어 표현과 응원 메시지를  카카오톡으로 보내드려요. (링글에 가입한 번호의 카카오톡 계정을 통해 한국어로 제공됩니다.)`,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      's_portal_home.monthly_challenge.modal_desc2',
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
    ],
    crm_msg_20min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_20min_0',
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
    ],
    crm_msg_40min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_40min_0',
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
      `💜 Book your lesson to start the challenge!`,
    ],
    week_number: (n) => [
      `${n}주`,
      `Week ${n}`,
      's_portal_home.monthly_challenge.week_number',
      `Week ${n}`,
      `Week ${n}`,
      `Week ${n}`,
      `Week ${n}`,
    ],
    week2: [`2주`, `Week 2`, 's_portal_home.monthly_challenge.week2', `Week 2`, `Week 2`, `Week 2`, `Week 2`],
    week3: [`3주`, `Week 3`, 's_portal_home.monthly_challenge.week3', `Week 3`, `Week 3`, `Week 3`, `Week 3`],
    week4: [`4주`, `Week 4`, 's_portal_home.monthly_challenge.week4', `Week 4`, `Week 4`, `Week 4`, `Week 4`],
    modal_placeholder_nov: [
      `11월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Nov 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov',
      `Starts on Nov 1, 2023`,
      `Starts on Nov 1, 2024`,
      `Starts on Nov 1, 2025`,
      `Starts on Nov 1, 2026`,
    ],
    modal_placeholder_dec: [
      `12월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Dec 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_dec',
      `Starts on Dec 1, 2023`,
      `Starts on Dec 1, 2024`,
      `Starts on Dec 1, 2025`,
      `Starts on Dec 1, 2026`,
    ],
    modal_placeholder_oct2: [
      `10월 챌린지는 종료되었습니다.`,
      `Ended Oct 31, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_oct2',
      `Ended Oct 31, 2023`,
      `Ended Oct 31, 2024`,
      `Ended Oct 31, 2025`,
      `Ended Oct 31, 2026`,
    ],
    modal_placeholder_nov2: [
      `11월 챌린지는 종료되었습니다.`,
      `Ended Nov 30, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov2',
      `Ended Nov 30, 2023`,
      `Ended Nov 30, 2024`,
      `Ended Nov 30, 2025`,
      `Ended Nov 30, 2026`,
    ],
  },
  point: [
    `사용 가능한 포인트`,
    `Available points`,
    's_portal_home.point',
    `Available points`,
    `Available points`,
    `Available points`,
    `Available points`,
  ],
  point_alert: [
    `2월 3일 50,000포인트 소멸 예정`,
    `$38 Points valid until Feb 2`,
    's_portal_home.point_alert',
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
    `$38 Points valid until Feb 2`,
  ],
  point_tooltip: [
    `곧 사라져요!`,
    `Don’t forget!`,
    's_portal_home.point_tooltip',
    `Don’t forget!`,
    `Don’t forget!`,
    `Don’t forget!`,
    `Don’t forget!`,
  ],
  point_event: [
    `5만 포인트 잊으셨나요?`,
    `Remember $38P gift?`,
    's_portal_home.point_event',
    `Remember $38P gift?`,
    `Remember $38P gift?`,
    `Remember $38P gift?`,
    `Remember $38P gift?`,
  ],
  point_desc: [
    <>
      설 선물로 드린
      <br />
      5만 포인트가 아직
      <br />
      잠자고 있어요.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    's_portal_home.point_desc',
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
  ],
  point_btn: [
    `포인트로 구매하기`,
    `Buy Credits`,
    's_portal_home.point_btn',
    `Buy Credits`,
    `Buy Credits`,
    `Buy Credits`,
    `Buy Credits`,
  ],
  recommended_tutor: [
    `추천 튜터`,
    `Featured`,
    's_portal_home.recommended_tutor',
    `教师推荐`,
    `推薦的教師`,
    `おすすめ`,
    `Nổi bật`,
  ],
  recommended_banner: {
    title: [
      <>
        튜터가 너무 많아 고민되나요? 링글러들이 선호하는 <span className="text-purple-500">추천 튜터</span>를
        만나보세요!
      </>,
      <>
        Too many tutors to choose from? Check out our <span className="text-purple-500">Featured Tutors!</span>
      </>,
      's_portal_home.recommended_banner.title',
      `不知道怎麽选教师？可以参考其他Ringle学员推荐的教师！`,
      `不知道怎麼選教師？可以參考其他Ringle學員推薦的教師！`,
      `チューター選びにお困りですか？人気のおすすめチューターをチェック！`,
      `Lo lắng vì không biết chọn gia sư nào? Kiểm tra danh sách gia sư nổi bật!`,
    ],
    desc: [
      `추천 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.`,
      `We'll restore your credit if you are unsatisfied with a Featured Tutor.`,
      's_portal_home.recommended_banner.desc',
      `如果对于推荐教师的课程不满意，我们将退还您的课程券。`,
      `如果對於推薦教師的課程不滿意，我們將退還您的課程券。`,
      `おすすめチューターとのレッスンにご満足いただけない場合、レッスンチケットの復元が可能です。`,
      `Nếu không hài lòng với tiết học cùng gia sư được Ringle lựa chọn, bạn sẽ được hoàn phiếu học.`,
    ],
  },
  banner_modal: {
    title: [
      `전체 보기`,
      `View All`,
      's_portal_home.banner_modal.title',
      `查看全部`,
      `查看全部`,
      `すべて見る`,
      `Xem tất cả`,
    ],
  },
  banner_all: {
    btn: [`전체 보기`, `View All`, 's_portal_home.banner_all.btn', `查看全部`, `查看全部`, `すべて見る`, `Xem tất cả`],
  },
  new_tutor: {
    title: [
      `새로 온 튜터 50% 포인트백`,
      `Rookie Tutor 50% Value Back`,
      's_portal_home.new_tutor.title',
      `50% 点数返点`,
      `50% 點數回饋`,
      `50% 点数返点`,
      `50% Point Back`,
    ],
    desc: [
      <>
        <p>10대 1의 경쟁률을 뚫은 새로 온 튜터들을 만나보세요.</p>
        <p>
          수업 후기를 남기면 <span className="highlight">수업료의 50%</span>를 포인트로 돌려드려요.
        </p>
      </>,
      <>
        <p>Meet our highly-qualified Rookie Tutors.</p>
        <p>
          Leave a review to <span className="hightlight">get 50% value back in points.</span>
        </p>
      </>,
      's_portal_home.new_tutor.desc',
      <>
        <p>和新教师上课</p>
        <p>
          留下课后评价，<span className="highlight">获得课程价值50% 点数返点</span>
        </p>
      </>,
      <>
        <p>和新教師上課</p>
        <p>
          留下課後評價，<span className="highlight">獲得課程價值50% 點數回饋</span>
        </p>
      </>,
      <>
        <p>新規チューターのレビューを残すと</p>
        <p>
          <span className="highlight">料金の50％</span>をポイントで受け取れます。
        </p>
      </>,
      <>
        <p>Gặp gỡ nhóm gia sư mới chất lượng cao</p>
        <p>
          Viết nhận xét nhận hoàn điểm trị giá <span className="highlight">50% học phí</span>
        </p>
      </>,
    ],
  },
  ringle_pick: {
    title: [
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      's_portal_home.ringle_pick.title',
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      `Ringle’s Pick`,
      ``,
    ],
    subtitle: [
      <>
        내 목적에 맞는
        <br />
        공부방법을
        <br />
        골라봤어요.
      </>,
      <>
        How Ringle
        <br />
        Can Meet
        <br />
        Your Needs
      </>,
      's_portal_home.ringle_pick.subtitle',
      <>
        选择适合
        <br />
        自己的
        <br />
        学习方式
      </>,
      <>
        選擇適合
        <br />
        自己的
        <br />
        學習方式
      </>,
      <>
        目的に合った
        <br />
        学習方法を
        <br />
        ピックアップ
      </>,
      <>
        Ringle có thể <br />
        đáp ứng
        <br />
        nhu cầu của bạn.
      </>,
    ],
  },
  ringle_pick_1: {
    tag1: [
      `#초∙중급`,
      `#Starters`,
      's_portal_home.ringle_pick_1.tag1',
      `#初/中级学习者`,
      `#初/中級學習者`,
      `#初・中級`,
      `#Beginners`,
    ],
    tag2: [
      `#일상회화`,
      `#DailyEnglish`,
      's_portal_home.ringle_pick_1.tag2',
      `#日常对话`,
      `#日常對話`,
      `#日常会話`,
      `#DailyEnglish`,
    ],
    title: [
      <>
        원어민만 만나면
        <br />
        손에 땀이 나요.
      </>,
      <>
        English makes
        <br />
        me nervous.
      </>,
      's_portal_home.ringle_pick_1.title',
      <>
        和母语人士对话
        <br />
        感到紧张
      </>,
      <>
        和母語人士對話
        <br />
        覺得緊張
      </>,
      <>
        英語で話す時
        <br />
        緊張しすぎる…
      </>,
      <>
        Tiếng anh khiến
        <br />
        tôi lo lắng.
      </>,
    ],
    btn: [
      <>
        가벼운 주제로
        <br />
        영어에 익숙해지기
      </>,
      <>
        Get use to
        <br />
        speaking English
      </>,
      's_portal_home.ringle_pick_1.btn',
      <>
        用轻鬆主题
        <br />
        练习英语对话
      </>,
      <>
        用輕鬆主題
        <br />
        練習英文對話
      </>,
      <>
        簡単なテーマで
        <br />
        英語に慣れよう
      </>,
      <>
        Làm quen với
        <br />
        nói tiếng anh
      </>,
    ],
  },
  ringle_pick_2: {
    tag1: [
      `#비즈니스 영어`,
      `#BusinessEnglish`,
      's_portal_home.ringle_pick_2.tag1',
      `#商用英语`,
      `#商用英文`,
      `#ビジネス英語`,
      `#tiếnganhthươngmại`,
    ],
    title: [
      <>
        내일 바로 회사에서
        <br />
        영어를 써야해요.
      </>,
      <>
        I use English
        <br />
        every day at work.
      </>,
      's_portal_home.ringle_pick_2.title',
      <>
        每天在单位
        <br />
        都要使用英语
      </>,
      <>
        每天在公司
        <br />
        都要使用英文
      </>,
      <>
        明日会社で
        <br />
        英語が必要…
      </>,
      <>
        Tôi cần Tiếng Anh
        <br />
        trong công việc.
      </>,
    ],
    btn: [
      <>
        이메일 표현부터
        <br />
        비즈니스 트렌드까지
      </>,
      <>
        From writing emails
        <br />
        to biz trends
      </>,
      's_portal_home.ringle_pick_2.btn',
      <>
        从撰写英语邮件
        <br />
        到商业趋势
      </>,
      <>
        從撰寫英文郵件
        <br />
        到商業趨勢
      </>,
      <>
        メールでの表現から
        <br />
        ビジネストレンドまで
      </>,
      <>
        Viết email hoặc <br />
        tìm hiểu kinh doanh
      </>,
    ],
  },
  ringle_pick_3: {
    tag1: [
      `#유학/해외 취업 준비`,
      `#GoingAbroad`,
      's_portal_home.ringle_pick_3.tag1',
      `#海外留学/就业`,
      `#海外留學/就業`,
      `#留学/海外就職準備`,
      `#duhọc/địnhcưnướcngoài`,
    ],
    title: [
      <>
        글로벌에서 더 큰 꿈을
        <br />
        펼치고 싶어요.
      </>,
      <>
        I dream of
        <br />
        living globally.
      </>,
      's_portal_home.ringle_pick_3.title',
      <>
        在世界舞台
        <br />
        展现最好的自己
      </>,
      <>
        在世界舞台
        <br />
        展現最好的自己
      </>,
      <>
        グローバルの舞台で
        <br />
        大きな夢を叶えたい
      </>,
      <>
        Trở thành
        <br />
        công toàn cầu.
      </>,
    ],
    btn: [
      <>
        레쥬메 작성부터
        <br />
        인터뷰 준비까지
      </>,
      <>
        From resumes
        <br />
        to interviews
      </>,
      's_portal_home.ringle_pick_3.btn',
      <>
        从撰写履历
        <br />
        到准备面试
      </>,
      <>
        從撰寫履歷
        <br />
        到準備面試
      </>,
      <>
        レジュメから
        <br />
        面接準備まで
      </>,
      <>
        từ hồ sơ
        <br />
        đến phỏng vấn
      </>,
    ],
  },
}
export const s_lesson_review = {
  three_month_summary: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.three_month_summary',
    `最近3个月的课程信息摘要`,
    `最近3個月的課程資訊概要`,
    `最近3か月のレッスン統計まとめ`,
    `Tổng kết 3 tháng qua`,
  ],
  by_section: [
    `항목별 최신 결과`,
    `Latest Evaluations`,
    's_lesson_review.by_section',
    `各项目最新结果`,
    `各項目最新結果`,
    `項目別最新データ`,
    `Đánh giá mới nhất`,
  ],
  see_example: [
    `예시보기`,
    `View example`,
    's_lesson_review.see_example',
    `查看事例`,
    `查看例子`,
    `例を見る`,
    `Xem ví dụ`,
  ],
  no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_: (name, day) => [
    <>
      최근 3개월 동안 완료한 수업이 없습니다. <br />
      {name}님의 마지막 수업: {day}일 전
    </>,
    <>
      No lessons in the last 3 months.
      <br />
      Your last lesson was {day} days ago.
    </>,
    's_lesson_review.no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_',
    <>
      最近3个月没有完成的课程。
      <br />
      {name}用户的最后课程: {day}日前
    </>,
    <>
      最近3個月沒有完成的課程。
      <br />
      {name}最後一次上課：{day}天前
    </>,
    <>
      直近3か月間に完了したレッスンはありません。 <br />
      {name}さんの最後のレッスン: {day}日前
    </>,
    <>
      Không có buổi học nào trong 3 tháng qua.
      <br />
      Buổi học cuối cùng của bạn là {day} ngày trước.
    </>,
  ],
  are_you_curious_about_how_the_ringlers_do_lessons_: [
    <span>
      월별 수업 통계에서 <br />
      어떤 통계 데이터가 나오는지 궁금하신가요?
    </span>,
    <span>Sample Monthly Summary</span>,
    's_lesson_review.are_you_curious_about_how_the_ringlers_do_lessons_',
    <span>
      想知道每月课程统计中
      <br />
      会得出怎样的统计结果吗?
    </span>,
    <span>
      您好奇每月課程統計
      <br />
      出現什麼統計數據嗎？
    </span>,
    <span>
      月別レッスン統計では
      <br />
      どのような統計データが出ますか？
    </span>,
    <span>Mẫu tóm tắt hằng tháng</span>,
  ],
  top_5_ringler_lesson_num: (num) => [
    <span>상위 20% 고객의 수업 수는 {num} 회입니다.</span>,
    <span>Top 20%: {num} lessons</span>,
    's_lesson_review.top_5_ringler_lesson_num',
    <span>前20%的学员, 课程量为{num}次。</span>,
    <span>前20%學生的課程數是{num}次。</span>,
    <span>上位20%のRinglerのレッスン数は {num} 回です。</span>,
    <span>Top 20%: {num} buổi học</span>,
  ],
  Summary: [`개요`, `Overview`, 's_lesson_review.Summary', `概要`, `概要`, `概要`, `Tổng quan`],
  lesson: [`1:1 수업`, `1:1 Lesson`, 's_lesson_review.lesson', `1:1课程`, `1:1課程`, `1:1レッスン`, `Buổi học 1:1`],
  work_in_progress: [
    `집계중`,
    `Work in Progress..`,
    's_lesson_review.work_in_progress',
    `统计中`,
    `統計中`,
    `集計中`,
    `Đang tiến hành..`,
  ],
  We_are_working_on_it: [
    `계산 중에 있습니다`,
    `This data is not yet available`,
    's_lesson_review.We_are_working_on_it',
    `计算中`,
    `計算中`,
    `計算中です。`,
    `Dữ liệu hiện chưa có`,
  ],
  review: [`복습`, `Review`, 's_lesson_review.review', `复习`, `複習`, `復習`, `Xem lại`],
  reviewed_yet: [
    `복습을 완료하지 않은 수업`,
    `Not reviewed`,
    's_lesson_review.reviewed_yet',
    `未完成复习的课程`,
    `未完成複習的課程`,
    `復習未完了`,
    `Buổi học bạn chưa xem lại`,
  ],
  reviews_lesson: [
    `복습을 완료한 수업`,
    `Reviewed`,
    's_lesson_review.reviews_lesson',
    `已完成复习的课程`,
    `已完成複習的課程`,
    `復習を完了`,
    `Buổi học bạn đã xem lại`,
  ],
  rate_lesson: [
    `수업 평가하기`,
    `Rate Lesson`,
    's_lesson_review.rate_lesson',
    `前往课程评价`,
    `評論課程`,
    `レッスンを評価する`,
    `Xếp hạng buổi học`,
  ],
  to_do_list: [
    `복습 목록`,
    `To-Do List`,
    's_lesson_review.to_do_list',
    `复习目录`,
    `複習清單`,
    `復習リスト`,
    `Những việc cần làm`,
  ],
  preparing: [`준비중`, `Preparing`, 's_lesson_review.preparing', `准备中`, `準備中`, `準備中`, `Đang chuẩn bị`],
  options: {
    study: {
      title: [
        `예습 내역`,
        `My Lesson Prep`,
        's_lesson_review.options.study.title',
        `预习内容`,
        `預習內容`,
        `予習履歴`,
        `Chuẩn bị Buổi học`,
      ],
    },
    record: {
      title: [
        `수업 녹음`,
        `Lesson Recording`,
        's_lesson_review.options.record.title',
        `课程录音`,
        `課程錄音`,
        `レッスン録音`,
        `Ghi âm buổi học`,
      ],
      desc: [
        `대화를 클릭하면 해당하는 녹음 파트를 바로 재생할 수 있습니다.`,
        `Click on the conversation to play the corresponding part of the recording.`,
        's_lesson_review.options.record.desc',
        `单击对话以播放相应的录制部分。`,
        `單擊對話以播放相應的錄製部分。`,
        `録音パートを再生するには、会話をクリックしてください。`,
        `Nhấp vào một cuộc đối thoại để phát bản ghi tương ứng.`,
      ],
    },
    analysis: {
      title: [
        `수업 분석`,
        `Lesson Analysis`,
        's_lesson_review.options.analysis.title',
        `课程分析`,
        `課程分析`,
        `レッスン分析`,
        `Phân tích buổi học`,
      ],
    },
    feedback: {
      title: [
        `튜터 피드백`,
        `Tutor’s Feedback`,
        's_lesson_review.options.feedback.title',
        `教师反馈`,
        `任課教師意見回饋`,
        `チューターフィードバック`,
        `Đánh giá của gia sư`,
      ],
    },
    note: {
      title: [
        `수업 노트`,
        `Lesson Notes`,
        's_lesson_review.options.note.title',
        `课程笔记`,
        `課堂筆記`,
        `レッスンノート`,
        `Ghi chú buổi học`,
      ],
    },
    speakingAnalysis: {
      title: [
        `스피킹 분석 차트`,
        `Speaking Analysis Charts`,
        's_lesson_review.options.speakingAnalysis.title',
        `口语分析图`,
        `口說分析圖表`,
        `スピーキング分析チャート`,
        `Biểu đồ phân tích nói`,
      ],
    },
    suggestion: {
      title: [
        `스피킹 분석 & 제안`,
        `Patterns & Suggestions`,
        's_lesson_review.options.suggestion.title',
        `口语分析 & 提案`,
        `口說分析 & 建議`,
        `スピーキング分析 & ご提案`,
        `Đặc điểm & Đề xuất`,
      ],
    },
    homework: {
      title: [`숙제`, `Homework`, 's_lesson_review.options.homework.title', `作业`, `作業`, `宿題`, `Bài tập`],
    },
  },
  feedback_notice: [
    `* 노란 선은 영역별 점수 상위 20% 구간의 평균값입니다.`,
    `*The yellow line indicates average scores of top 20% Ringlers.`,
    's_lesson_review.feedback_notice',
    `* 黄线为各方面分数前20%的区间平均值`,
    `* 黃線意指各領域前20%分數區間的平均值。`,
    `* 黄色い線は分野別点数の上位20%区間の平均値です。`,
    `*Đường màu vàng thể hiện điểm trung bình của top 20% học viên Ringle.`,
  ],
  completeMp3: [
    `수업 다시 듣기를 완료하셨습니다.`,
    `You’ve done listening to lesson recording.`,
    's_lesson_review.completeMp3',
    `已完成课程重听。`,
    `您已重新聽完課程。`,
    `レッスンをもう一度聴くを完了しました。`,
    `Bạn đã nghe xong ghi âm buổi học.`,
  ],
  voca_range: [
    `사용 단어 범위`,
    `Range of Words`,
    's_lesson_review.voca_range',
    `使用的单词范围`,
    `使用單字範圍`,
    `使用単語範囲`,
    `Vốn từ vựng`,
  ],
  wpm: [`말하기 속도`, `Speech Pace`, 's_lesson_review.wpm', `口语速度`, `口說速度`, `話すスピード`, `Tốc độ nói`],
  wpm_unit: [
    `단어/분 (WPM)`,
    `words/min(WPM)`,
    's_lesson_review.wpm_unit',
    `单词/分钟(WPM)`,
    `單字／分鐘（WPM）`,
    `単語/分 (WPM)`,
    `từ/phút (WPM)`,
  ],
  me: [`나`, `Me`, 's_lesson_review.me', `我`, `我`, `Me`, `Tôi`],
  tutor: [`튜터`, `Tutor`, 's_lesson_review.tutor', `任课教师`, `任課教師`, `Tutor`, `Gia sư`],
  ringler: [`상위 20%`, `Top 20%`, 's_lesson_review.ringler', `前20%`, `前20%`, `Top 20%`, `Top 20%`],
  fillerWord: [
    `필러워드 사용 빈도`,
    `Patterns & Suggestions`,
    's_lesson_review.fillerWord',
    `赘词使用频率`,
    `填補詞使用頻率`,
    `つなぎ言葉使用頻度`,
    `Đặc điểm & Đề xuất`,
  ],
  speaking_pattern: [
    `수업 중 사용한 표현 관련`,
    `Speaking Pattern & Suggestions`,
    's_lesson_review.speaking_pattern',
    `课程中使用的相关表达`,
    `上課時使用的用法相關內容`,
    `レッスン中に使用した表現関連`,
    `Đặc điểm & Đề xuất`,
  ],
  completeAnalysis: [
    `수업 분석 확인하기를 완료하셨습니다.`,
    `You’ve done checking the lesson analysis.`,
    's_lesson_review.completeAnalysis',
    `已完成课程分析确认。`,
    `您已確認完課程分析。`,
    `レッスン分析確認を完了しました。`,
    `Bạn đã kiểm tra xong phân tích buổi học.`,
  ],
  completeFeedback: [
    `튜터 피드백 확인을 완료하셨습니다.`,
    `You’ve done checking and evaluating feedback.`,
    's_lesson_review.completeFeedback',
    `已确认教师反馈。`,
    `您已確認任課教師的意見回饋。`,
    `チューターフィードバック確認を完了しました。`,
    `Bạn đã kiểm tra và đánh giá nhận xét.`,
  ],
  completeAnalysisButton: [
    `수업 분석 확인하기 완료`,
    `Done checking lesson analysis`,
    's_lesson_review.completeAnalysisButton',
    `已确认课程分析`,
    `已確認完課程分析`,
    `レッスン分析確認完了`,
    `Kiểm tra xong phân tích buổi học`,
  ],
  completeFeedbackButton: [
    `튜터 피드백 확인 및 평가 완료`,
    `Done checking and evaluating feedback`,
    's_lesson_review.completeFeedbackButton',
    `已确认教师反馈及完成评价`,
    `已確認任課教師的意見回饋並完成評論`,
    `チューターフィードバック確認および評価完了`,
    `Kiểm tra và đánh giá xong nhận xét`,
  ],
  not_provide_feedback: [
    <>
      Writing Focused 주제 수업의 피드백은 수업 노트(Ringle Doc)에 포함되어 별도의 피드백 리포트는 제공되지 않습니다.
    </>,
    <>
      Feedback from Writing Focused Lessons is included in the Lesson Note(Ringle Doc), so a separate feedback report is
      not available.
    </>,
    's_lesson_review.not_provide_feedback',
    <>Writing Focused主题的课程反馈包含在课程笔记(Ringle Doc)中 因此不另行提供Feedback Report。</>,
    <>Writing Focused主題課程的意見回饋包含於課堂筆記（Ringle Doc）， 故不額外提供意見回饋報告書。</>,
    <>
      Writing Focusedテーマレッスンのフィードバックはレッスンノート(Ringle Doc)に含まれており、 別途のFeedback
      Reportは提供されません。
    </>,
    <>
      Nhận xét của Buổi học Tập trung Viết nằm trong Ghi chú Buổi học (Ringle Doc). Vì vậy không có báo cáo đánh giá
      riêng.
    </>,
  ],
  after_rating_the_lesson: [
    <>
      수업평가 완료 후에
      <br />
      복습하실 수 있습니다.
    </>,
    <>
      You can review a lesson
      <br />
      after rating the lesson.
    </>,
    's_lesson_review.after_rating_the_lesson',
    <>
      完成课程评价后
      <br />
      可进行复习。
    </>,
    <>
      完成課程評論後
      <br />
      可進行複習。
    </>,
    <>
      レッスン評価完了後に
      <br />
      復習することができます。
    </>,
    <>
      Bạn có thể xem lại buổi học
      <br />
      sau khi đánh giá.
    </>,
  ],
  my_lesson_count: [
    `내 수업 수`,
    `Your Lessons`,
    's_lesson_review.my_lesson_count',
    `我的课程数`,
    `我的課程數`,
    `マイレッスン数`,
    `Buổi học của bạn`,
  ],
  do_you_want_delete_uploaded_file: [
    `한 번 자료를 삭제 하시면 복원이 불가능합니다. 삭제하시겠습니까?`,
    `This action cannot be undone. Are you sure you want to delete this document?`,
    's_lesson_review.do_you_want_delete_uploaded_file',
    `材料删除后将无法复原。确定要删除吗?`,
    `一旦刪除資料將無法復原。確定要刪除嗎？`,
    `一度資料を削除すると復元できません。削除しますか？`,
    `Hành động này không thể hoàn tác. Bạn có chắc muốn xoá tài liệu này không?`,
  ],
  please_evaluate_feedback: [
    `이 피드백 보고서를 평가해주세요`,
    `Please evaluate this feedback report`,
    's_lesson_review.please_evaluate_feedback',
    `请评价此份Feedback Report`,
    `請評論這份意見回饋報告書`,
    `このフィードバックレポートを評価してください。`,
    `Vui lòng đánh giá bản nhận xét này`,
  ],
  please_write_reason: [
    `평가에 대한 이유를 적어주세요.\n고객님의 소중한 의견은 튜터가 양질의 피드백 보고서를 제공하는데 도움이 됩니다.`,
    `Please write down the reason for your evaluation.\nYour honest feedback will be of tremendous help for the tutor to grow further.`,
    's_lesson_review.please_write_reason',
    `请写下评价的理由。\n 用户们的宝贵意见有助于教师们提供更为优质的Feedback Report。`,
    `請針對評論寫下理由。\n學生的寶貴意見有助於任課教師提供優質的意見回饋報告書。`,
    `評価の理由を入力してください。\nお客様の大切なご意見は、チューターが効果的なフィードバックレポートを提供するのに役立ちます。`,
    `Vui lòng viết lí do cho đánh giá của bạn. Những nhận xét chân thành của bạn sẽ cực kì hữu ích để giúp gia sư phát triển hơn nữa.`,
  ],
  score_criteria: [
    `점수 기준`,
    `Score Criteria`,
    's_lesson_review.score_criteria',
    `分数标准`,
    `分數標準`,
    `採点基準`,
    `Tiêu chí chấm điểm`,
  ],
  see_frequently_used_phrases: [
    `내가 사용한 구문`,
    `View your expressions`,
    's_lesson_review.see_frequently_used_phrases',
    `我使用的句型`,
    `我使用的句型`,
    `自分が使用した構文`,
    `Xem mẫu câu bạn dùng`,
  ],
  present: [`현재`, `today`, 's_lesson_review.present', `当前`, `目前`, `現在`, `hôm nay`],
  top_5: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5', `前20%`, `前20%`, `上位20%`, `Top 20%`],
  top_5_ringler: [
    `상위 20% 링글러`,
    `Top 20%`,
    's_lesson_review.top_5_ringler',
    `前20%的Ringle用户`,
    `前20%的Ringler`,
    `上位20%のRingler`,
    `Top 20%`,
  ],
  modal_overall_this_month_40_20: [
    `해당 월에 완료된 40분 및 20분 수업 수를 분석합니다. 일주일 평균 수업 수는 완료된 총 수업 수를 주로 나누어 일주일 평균 몇 회의 수업을 완료하였는지 분석합니다.`,
    <span>Counts all lessons. Weekly average lessons are calculated on a monthly basis.&nbsp;</span>,
    's_lesson_review.modal_overall_this_month_40_20',
    `分析该月完成的20分钟及40分钟的课程数量。一周平均课程数是指完成的总课程数按周进行划分后得出的一周平均值。`,
    `分析該月完成的40分鐘及20分鐘課程數。一週平均課程數係將完成的總課程數按週區分，分析一週平均完成幾次課程。`,
    `その月に完了した40分および20分のレッスン数を分析します。1週間の平均レッスン数は、完了した合計レッスン数を週に分け、1週間に平均何回のレッスンを完了したかを分析します。`,
    <span>Đếm toàn bộ buổi học. Số buổi học trung bình mỗi tuần sẽ được tính theo từng tháng.&nbsp;</span>,
  ],
  modal_overall_this_month_top5: [
    `상위 20%: 상위 20%의 모집단은 해당 월에 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의 평균값입니다.`,
    <span>Top 20% calculated based on monthly completed lessons by all Ringle users.</span>,
    's_lesson_review.modal_overall_this_month_top5',
    `前20%: 前20%的对象仅限该月内完成的总课程数, 为总课程数前20%区间的平均值。`,
    `前20%：前20%族群僅限於該月完成的總課程數，為總課程數前20%區間的平均值。`,
    `上位20%: 上位20%の母集団は、その月に完了した合計レッスン数に限り、合計レッスン数の上位20%区間の平均値です。`,
    <span>Top 20% được tính dựa trên buổi học hoàn thành trong tháng của các học viên Ringle.</span>,
  ],
  modal_feedback_this_month: [
    <span>
      튜터 피드백 평균 점수는 아래 Fluency, Vocabulary, Grammar, Pronunication 네가지 항목의 평균 점수를 나타냅니다.
    </span>,
    `Shows monthly average score across four categories: Fluency, Vocabulary, Grammar, Pronunciation.`,
    's_lesson_review.modal_feedback_this_month',
    <span>教师反馈的平均分数为Fluency, Vocabulary, Grammar, Pronunication 四部分的平均分数</span>,
    <span>任課教師意見反饋的平均分數為下方Fluency、Vocabulary、Grammar、Pronunication 四項的平均分數。</span>,
    <span>
      チューターフィードバック平均点数は以下のFluency、Vocabulary、Grammar、Pronunicationの
      4つの項目の平均点数を表します。
    </span>,
    `Hiển thị điểm trung bình tháng dựa trên bốn kỹ năng: Độ trôi chảy, Từ vựng, Ngữ pháp, Phát âm.`,
  ],
  modal_wpm_this_month: [
    <span>
      해당 수업에서 내가 말한 속도입니다.
      <br /> 말하기 속도는 수업 시간 동안 내가 말한 단어 수를 기준으로 계산하며, 1분당 말한 단어 수입니다.
    </span>,
    `Total number of words uttered divided by the total number of minutes in lesson.`,
    's_lesson_review.modal_wpm_this_month',
    <span>
      该课程中本人的口语速度。
      <br />
      口语速度是指课程中以本人说出的单词数量为基准 于1分钟内所说的单词数量。
    </span>,
    <span>
      該課程中我的口說速度。
      <br />
      以上課時我說的單字數為準， 為每分鐘所說的單字數。
    </span>,
    <span>
      このレッスンであなたが話したスピードです。
      <br /> 話すスピードはレッスン中のにあなたが話した単語数を基準に計算され、 1分あたりの発話単語数を示します。
    </span>,
    `Tổng số từ đã nói chia ra tổng số phút của buổi học.`,
  ],
  modal_top5_wpm_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 수업에 한하며, 말하기 속도 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_wpm_selected',
    `前20%的对象仅限相同日期内完成的课程中, 口语速度为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的課程，為口說速度前20%區間的平均值。`,
    `上位20%の母集団データは同日内に完了したレッスンに限り、話すスピードの上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số các buổi học trên Ringle trong một ngày.`,
  ],
  modal_feedback_this_month_avg: [
    `튜터 피드백 평균 점수가 모든 사용자들 중 상위 20% 지점의 수치입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_feedback_this_month_avg',
    `教师反馈平均分数是指所有用户中位于前20%的数值。`,
    `任課教師意見反饋的平均分數為所有學生中位於前面第20%的數值。`,
    `チューターフィードバック平均点数は全ユーザーのうち上位20%地点の数値です。`,
    `Hiển thị top 20% trong số các buổi học trên Ringle trong một ngày.`,
  ],
  modal_word_selected: [
    `해당 수업에서 내가 사용한 단어 수이며, 중복값을 제외합니다.`,
    `Number of different words you used in a lesson.`,
    's_lesson_review.modal_word_selected',
    `该课程中本人使用的单词数量, 不包含重复值。`,
    `該課程中我使用的單字數，扣除重複值。`,
    `このレッスンであなたが使用した単語数であり、重複値を除きます。`,
    `Số lượng những từ khác nhau sử dụng trong buổi học.`,
  ],
  modal_phrase_selected: [
    `해당 수업에서 내가 사용한 구문 수이며, 중복값을 제외합니다.`,
    `Number of different expressions you used in a lesson.`,
    's_lesson_review.modal_phrase_selected',
    `该课程中本人使用的句型数量, 不包含重复值。`,
    `該課程中我使用的句型數，扣除重複值。`,
    `このレッスンであなたが使用した構文数であり、重複値を除きます。`,
    `Những mẫu câu khác nhau sử dụng trong buổi học.`,
  ],
  modal_top5_word_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected',
    `前20%的对象仅限相同日期内完成的20分钟课程中, 使用单词数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的20分鐘課程，為使用的單字數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した20分レッスンに限り、使用単語数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 20 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_phrase_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected',
    `前20%的对象仅限相同日期内完成的20分钟课程中, 使用句型数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的20分鐘課程，為使用的句型數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した20分レッスンに限り、使用構文数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 20 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_word_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected40',
    `前20%的对象仅限相同日期内完成的40分钟课程中, 使用单词数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的40分鐘課程，為使用的單字數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した40分レッスンに限り、使用単語数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 40 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  modal_top5_phrase_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected40',
    `前20%的对象仅限相同日期内完成的40分钟课程中, 使用句型数量为前20%区间的平均值。`,
    `前20%族群僅限於相同日期完成的40分鐘課程，為使用的句型數前20%區間的平均值。`,
    `上位20%の母集団は同日内に完了した40分レッスンに限り、使用構文数の上位20%区間の平均値です。`,
    `Hiển thị top 20% trong số tất cả buổi học 40 phút diễn ra trên Ringle trong cùng một ngày.`,
  ],
  times: [`회`, `Lessons`, 's_lesson_review.times', `次`, `次`, `回`, `Buổi học`],
  following_are_sample_data: [
    `하단의 차트와 통계 내용은 샘플 데이터입니다.`,
    `The charts & stats below are examples.`,
    's_lesson_review.following_are_sample_data',
    `下端图表及统计内容为样本数据。`,
    `下方的圖表與統計內容僅為樣本數據。`,
    `下のチャートと統計内容はサンプルデータです。`,
    `Ví dụ các biểu đồ & thống kê dưới.`,
  ],
  average_points: [
    `평균 점수`,
    `Avg. Score`,
    's_lesson_review.average_points',
    `平均分数`,
    `平均分數`,
    `平均点数`,
    `Điểm trung bình`,
  ],
  points: [`점`, ``, 's_lesson_review.points', `分`, `分`, `点`, ``],
  year_and_month_statistics: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[1]}, ${year}`,
    's_lesson_review.year_and_month_statistics',
    `${year}年${month}月`,
    `${year}年${month}月`,
    `${year}年 ${month}月`,
    `${getMonthName(month - 1)[1]}, ${year}`,
  ],
  year: (year) => [`${year}년`, `${year}`, 's_lesson_review.year', `${year}年`, `${year}年`, `${year}年`, `${year}`],
  to_You: (user) => [`${user}`, `You`, 's_lesson_review.to_You', `${user}`, `${user}`, `${user}`, `Bạn`],
  You_with_standard: (user) => [
    `${user}님은 총 수업 수 기준`,
    `Regarding total number of lessons, you are in the`,
    's_lesson_review.You_with_standard',
    `${user}用户, 以总课程数为基准`,
    `以總課程數為準，${user}`,
    `${user}さんは合計レッスン数基準`,
    `Xét theo tổng số buổi học, bạn đang ở`,
  ],
  are: [`님은`, `are`, 's_lesson_review.are', `属于`, `屬於`, `さんは`, `là`],
  apart_of: [`에 속합니다.`, ``, 's_lesson_review.apart_of', `之中。`, `。`, `に属しています。`, ``],
  tutors: [`튜터`, `Tutor`, 's_lesson_review.tutors', `任课教师`, `任課教師`, `チューター`, `Gia sư`],
  overview_modal_title: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.overview_modal_title',
    `最近3个月的课程信息摘要`,
    `最近3個月的課程資訊概要`,
    `最近3か月のレッスン統計まとめ`,
    `Tổng kết 3 tháng qua`,
  ],
  overview_modal_secTitle1: [
    `최근 3개월간 완료한 수업 데이터의 통계입니다.`,
    ``,
    's_lesson_review.overview_modal_secTitle1',
    `最近3个月间完成课程的数据统计`,
    `最近3個月期間完成的課程數據統計。`,
    `直近3か月間に完了したレッスンデータの統計です。`,
    ``,
  ],
  overview_modal_paragraph: [
    <span>
      수업 수: 40분 및 20분 수업의 총합
      <br />
      튜터 수: 함께한 튜터 수 (중복 튜터 제외)
      <br />
      교재 수: 학습한 교재 수 (중복 교재 제외)
      <br />
      <br />
      상위 20%: 상위 20%의 모집단은 각 링글러가 최근 3개월간 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의
      평균값입니다.
      <br />
    </span>,
    <span>Top 20%: Ringlers who have taken the highest number of lessons in a selected month (40min and 20min)</span>,
    's_lesson_review.overview_modal_paragraph',
    <span>
      课程数: 40分钟及20分钟课程总和
      <br />
      教师人数: 共同学习的教师数(重复教师除外)
      <br />
      教材数: 学习的教材数(重复教材除外)
      <br />
      <br />
      前20%: 前20%的对象是指各Ringle用户最近3个月间完成的 课程总和, 课程数前20%区间的平均值。
      <br />
    </span>,
    <span>
      課程數：40分鐘及20分鐘課程的總和
      <br />
      任課教師人數：一起學習的任課教師人數（扣除重複的任課教師）
      <br />
      教材數：學習的教材數（扣除重複的教材）
      <br />
      <br />
      前20%：前20%族群僅限於各Ringler於最近3個月期間完成的 總課程數，為總課程數前20%區間的平均值。
      <br />
    </span>,
    <span>
      レッスン数: 受講した40分/20分レッスン数合計
      <br />
      チューター数: レッスンを受けたチューター数 (重複は除く)
      <br />
      教材数: 学習した教材数 (重複は除く)
      <br />
      <br />
      上位20%: 上位20%の母集団は各Ringlerが直近3か月間に完了した合計レッスン数に
      限り、合計レッスン数の上位20%区間の平均値です。
      <br />
    </span>,
    <span>Top 20%: học viên Ringle học nhiểu buổi nhất trong tháng được chọn (40 phút và 20 phút)</span>,
  ],
  overview_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview_modal_extra',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Những buổi học vừa hoàn thành trong 48 giờ có thể sẽ không hiển thị.`,
  ],
  overview2_modal_title: [
    `항목별 최신 결과`,
    `Latest Evaluations`,
    's_lesson_review.overview2_modal_title',
    `各项目最新结果`,
    `各項目最新結果`,
    `項目別最新データ`,
    `Đánh giá mới nhất`,
  ],
  overview2_modal_secTitle1: [
    `항목별 가장 최근 수치와 상위 20% 수치를 비교 분석합니다.`,
    `Compare your performance against top 20% Ringlers.`,
    's_lesson_review.overview2_modal_secTitle1',
    `比较分析各项目最新数值与前20%数值。`,
    `比較分析各項目的最新數值與前20%數值。`,
    `項目別の直近の成果データと上位20%の数値を比較分析します。`,
    `So sánh hiệu suất của bạn với top 20% học viên Ringle.`,
  ],
  overview2_modal_paragraph: [
    <span>
      항목별 수치는 동일 수업의 결과가 아닐 수 있으며, 데이터 분석이 완료된 가장 최근 수업의 결과입니다.
      <br />
      상위 20%의 모집단은 동일 날짜에 완료된 수업에 한합니다.
    </span>,
    <span>
      Each score may be calculated from the most relevant lesson for that statistic.
      <br />
      Top 20% is calculated independently for each category.
      <br />
    </span>,
    's_lesson_review.overview2_modal_paragraph',
    <span>
      各项目数值可能非同一课程的结果, 而是完成数据 分析的最新课程结果。
      <br />
      前20%的对象仅限于相同日期完成的课程。
    </span>,
    <span>
      各項目數值可能並非相同課程的結果，而是完成數據分析的 最新課程結果。
      <br />
      前20%族群僅限於相同日期完成的課程。
    </span>,
    <span>
      項目別数値は同日のレッスンの結果ではない場合があり、データ分析が完了した最も 直近のレッスンの結果です。
      <br />
      上位20%の母集団は同日内に完了したレッスンに限ります。
    </span>,
    <span>
      Mỗi điểm số được tính dựa trên buổi học liên quan nhất trong thống kê.
      <br />
      Top 20% được tính độc lập cho mỗi phần.
      <br />
    </span>,
  ],
  overview2_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview2_modal_extra',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Những buổi học vừa hoàn thành trong 48 giờ có thể sẽ không hiển thị.`,
  ],
  feedback_modal_extra: [
    `*수업 녹음을 설정하지 않아 분석이 불가능한 수업은 해당 페이지에서 조회되지 않습니다.`,
    `*Unrecorded lessons are not included in analysis.`,
    's_lesson_review.feedback_modal_extra',
    `*因未设置课程录音而无法分析的课程, 相关页面将不提供查询。`,
    `*無法於該頁面查詢由於未設定課程錄音而無法分析的課程。`,
    `*レッスン録音を設定していないレッスンは分析ができないため、レッスン統計ページでは照会できません。`,
    `*Những buổi học không thu âm sẽ không đưa vào phân tích.`,
  ],
  overall_statistics: [
    `월별 수업 통계`,
    `Monthly Summary`,
    's_lesson_review.overall_statistics',
    `每月课程统计`,
    `每月課程統計`,
    `月別レッスン統計`,
    `Tổng kết tháng`,
  ],
  overall_statistics_graph: [
    `월별 수업 수`,
    `Number of Lessons per Month`,
    's_lesson_review.overall_statistics_graph',
    `每月课程数`,
    `每月課程數`,
    `月別レッスン数`,
    `Số buổi học mỗi tháng`,
  ],
  lessons: [`수업 수`, `Lessons`, 's_lesson_review.lessons', `课程数`, `課程數`, `レッスン数`, `Buổi học`],
  _: [`회`, ``, 's_lesson_review._', `次`, `次`, `回`, ``],
  forty_min_used_word_count: [
    `40분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_word_count',
    `40分钟课程使用的单词数量`,
    `40分鐘課程使用的單字數`,
    `40分レッスンの使用単語数`,
    `Số từ dùng trong buổi học 40 phút`,
  ],
  twenty_min_used_word_count: [
    `20분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.twenty_min_used_word_count',
    `20分钟课程使用的单词数量`,
    `20分鐘課程使用的單字數`,
    `20分レッスンの使用単語数`,
    `Số từ dùng trong buổi học 20 phút`,
  ],
  my_used_word_count: [
    `내 단어 수`,
    `You`,
    's_lesson_review.my_used_word_count',
    `我的单词数量`,
    `我的單字數`,
    `あなたの単語数`,
    `Bạn`,
  ],
  used_word_count: [
    `사용 단어 수`,
    `Used Words`,
    's_lesson_review.used_word_count',
    `使用的单词数量`,
    `使用的單字數`,
    `使用単語数`,
    `Từ đã dùng`,
  ],
  forty_min_used_phrase_count: [
    `40분 수업 사용 구문 수`,
    `Expressions used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_phrase_count',
    `40分钟课程使用的句型数量`,
    `40分鐘課程使用的句型數`,
    `40分レッスンの使用構文数`,
    `Mẫu câu sử dụng trong buổi học 40 phút của bạn`,
  ],
  twenty_min_used_phrase_count: [
    `20분 수업 사용 구문 수`,
    `Expressions used in your 20 minute lessons`,
    's_lesson_review.twenty_min_used_phrase_count',
    `20分钟课程使用的句型数量`,
    `20分鐘課程使用的句型數`,
    `20分レッスンの使用構文数`,
    `Mẫu câu sử dụng trong buổi học 20 phút của bạn`,
  ],
  used_phrase_count: [
    `사용 구문 수`,
    `Used Expressions`,
    's_lesson_review.used_phrase_count',
    `使用的句型数量`,
    `使用的句型數`,
    `使用構文数`,
    `Mẫu câu đã dùng`,
  ],
  my_used_phrase_count: [
    `내 구문 수`,
    `You`,
    's_lesson_review.my_used_phrase_count',
    `我的句型数量`,
    `我的句型數`,
    `あなたの構文数`,
    `Bạn`,
  ],
  word_list: [
    `단어 리스트`,
    `Word List`,
    's_lesson_review.word_list',
    `单词列表`,
    `單字清單`,
    `単語リスト`,
    `Danh sách từ`,
  ],
  phrase_list: [
    `구문 리스트`,
    `Expressions List`,
    's_lesson_review.phrase_list',
    `句型列表`,
    `句型清單`,
    `構文リスト`,
    `Danh sách mẫu câu`,
  ],
  my_speaking_rate: [
    `내 속도`,
    `You`,
    's_lesson_review.my_speaking_rate',
    `我的语速`,
    `我的速度`,
    `あなたのスピード`,
    `Bạn`,
  ],
  speaking_rate: [
    `말하기 속도`,
    `Speech Pace`,
    's_lesson_review.speaking_rate',
    `口语速度`,
    `口說速度`,
    `話すスピード`,
    `Tốc độ nói`,
  ],
  convert_month_day_year: (month, day) => [
    `${month}월 ${day}일`,
    `${getMonthName(month - 1)[0]} ${day}`,
    's_lesson_review.convert_month_day_year',
    `${month}月 ${day}日`,
    `${month}月${day}日`,
    `${month}月 ${day}日`,
    `${getMonthName(month - 1)[0]} ${day}`,
  ],
  convert_year: (year) => [
    `${year}년`,
    `${year}`,
    's_lesson_review.convert_year',
    `${year}年`,
    `${year}年`,
    `${year}年`,
    `${year}`,
  ],
  packets: [`교재 수`, `Materials`, 's_lesson_review.packets', `教材数量`, `教材數`, `教材数`, `Tài liệu`],
  points_only_kr: [`내 점수`, `You`, 's_lesson_review.points_only_kr', `我的分数`, `我的分數`, `あなたの点数`, `Bạn`],
  wpm_only_kr: [`내 속도`, `You`, 's_lesson_review.wpm_only_kr', `我的语速`, `我的速度`, `あなたのスピード`, `Bạn`],
  words_only_kr: [
    `내 단어 수`,
    `You`,
    's_lesson_review.words_only_kr',
    `我的单词数量`,
    `我的單字數`,
    `あなたの単語数`,
    `Bạn`,
  ],
  phrases_only_kr: [
    `내 구문 수`,
    `You`,
    's_lesson_review.phrases_only_kr',
    `我的句型数量`,
    `我的句型數`,
    `あなたの構文数`,
    `Bạn`,
  ],
  period_kr_time_to_US: (time) => [
    <>{time}</>,
    <>{getMonthName(time.split('.')[1] - 1)[1] + ' ' + time.split('.')[2] + ', ' + '20' + time.split('.')[0]}</>,
    's_lesson_review.period_kr_time_to_US',
    <>{time}</>,
    <>{time}</>,
    <>{time}</>,
    <>{getMonthName(time.split('.')[1] - 1)[1] + ' ' + time.split('.')[2] + ', ' + '20' + time.split('.')[0]}</>,
  ],
  tutor_s_feedback_score: [
    `튜터 피드백 점수`,
    `Tutor's Feedback Score`,
    's_lesson_review.tutor_s_feedback_score',
    `教师反馈分数`,
    `任課教師意見回饋分數`,
    `チューターフィードバック点数`,
    `Điểm đánh giá từ gia sư`,
  ],
  used_expressions: [
    `사용 구문 수`,
    `Used Expressions`,
    's_lesson_review.used_expressions',
    `使用的句型数量`,
    `使用的句型數`,
    `使用構文数`,
    `Mẫu câu đã dùng`,
  ],
  top_5_: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5_', `前20%`, `前20%`, `上位20%`, `Top 20%`],
  in_the_top_5_period: [
    `상위 20%`,
    `in the Top 20%.`,
    's_lesson_review.in_the_top_5_period',
    `前20%`,
    `前20%`,
    `上位20%`,
    `ở trong Top 20%.`,
  ],
  avg_per_week: [
    `일주일 평균`,
    `Avg. Per Week`,
    's_lesson_review.avg_per_week',
    `一周平均`,
    `一週平均`,
    `1週間の平均`,
    `Trung bình mỗi tuần`,
  ],
  you_are_in_the_top_5_: (user) => [
    <div>
      {user}님은 <span className="text-yellow-500">상위 20%</span>에 속합니다.
    </div>,
    <div>
      You are in the <span className="text-yellow-500">top 20%.</span>
    </div>,
    's_lesson_review.you_are_in_the_top_5_',
    <div>
      用户{user}属于<span className="text-yellow-500">前20%</span>。
    </div>,
    <div>
      {user}屬於<span className="text-yellow-500">前20%</span>。
    </div>,
    <div>
      {user}さんは <span className="text-yellow-500">上位20%</span>に属しています。
    </div>,
    <div>
      Bạn đang ở trong <span className="text-yellow-500">top 20%.</span>
    </div>,
  ],
  lesson_prep_review: [
    `예습 및 복습`,
    `Lesson Prep & Review`,
    's_lesson_review.lesson_prep_review',
    `预习和复习`,
    `預習與複習`,
    `予習および復習`,
    `Chuẩn bị Buổi học & Xem lại`,
  ],
  average_prep_review_percentages_per_lesson: [
    `수업별 평균 완료율`,
    `Average % Completed Per Lesson`,
    's_lesson_review.average_prep_review_percentages_per_lesson',
    `各课程平均完成率`,
    `各課程平均完成率`,
    `レッスン毎平均完了率`,
    `% Trung bình Hoàn thành một Buổi học`,
  ],
  no_data_in_2020: [
    <span>
      예습 및 복습의 데이터는
      <br />
      2021년 1월의 수업부터 확인하실 수 있습니다
    </span>,
    `Lesson Prep. & Review Data is available from January of 2021.`,
    's_lesson_review.no_data_in_2020',
    <span>
      预习及复习数据
      <br />
      可从2021年1月课程起进行确认
    </span>,
    <span>
      您可確認2021年1月以後的
      <br />
      課程預習與複習數據。
    </span>,
    <span>
      予習および復習データは
      <br />
      2021年1月のレッスンから確認することができます。
    </span>,
    `Chuẩn bị Buổi học & Số liệu Đánh giá có từ tháng 1 năm 2021.`,
  ],
  lesson_distribution_based_on_completion: [
    `예습 및 복습 완료 수업 수`,
    `Lessons you Prepped & Reviewed`,
    's_lesson_review.lesson_distribution_based_on_completion',
    `完成预习及复习的课程数量`,
    `完成預習與複習的課程數`,
    `予習および復習完了レッスン数`,
    `Buổi học bạn Đã chuẩn bị & Đã xem lại`,
  ],
  prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons:
    [
      `평균 예습률 및 복습률은 해당 월에 완료된 수업들에 대해 각각 예습률 및 복습률 총합을 완료된 수업 수로 나눈 수치입니다.`,
      `Calculated by dividing lessons that you prepped and reviewed by total lessons you had on a monthly basis.`,
      's_lesson_review.prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons',
      `平均预习率及复习率是指当月完成课程的各预习率及复习率的总和除以总完成课程数的数值。`,
      `平均預習率與複習率係將該月完成的課程各自的平均預習率與複習率的總和除以完成的課程數所得到的數值。`,
      `平均予習率および復習率は、その月に完了したレッスンをそれぞれ予習率と復習率の合計を完了したレッスン数で割った数値です。`,
      `Được tính bằng cách chia số buổi học bạn đã chuẩn bị và xem lại với tổng số buổi học đã học trong tháng.`,
    ],
  these_statistics_represent_the_disparities_between_completion_rates_: [
    `해당 월에 완료된 수업들에 대해 예습만 완료, 복습만 완료, 예습과 복습 모두 완료 및 예습과 복습 모두 미완료한 수업 수를 제공합니다.`,
    `Shows how many lessons you have prepped, reviewed, or both on a monthly basis.`,
    's_lesson_review.these_statistics_represent_the_disparities_between_completion_rates_',
    `对于当月完成的课程, 将为您提供仅完成预习, 仅完成复习, 预习及复习均完成以及预习及复习均未完成的数值。`,
    `針對該月完成的課程，提供僅完成預習、僅完成複習、預習與複習全部完成及預習與複習全部未完成的課程數。`,
    `その月に完了したレッスンについて、予習のみ完了、復習のみ完了、予習と復習すべて完了および予習と復習すべて未完了のレッスン数を提供します。`,
    `Hiển thị số buổi học bạn đã chuẩn bị, xem lại, hoặc cả hai trong tháng.`,
  ],
  encouraging_more_lessons_on_ringle: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
    </span>,
    <span>You haven't taken any lessons this month.</span>,
    's_lesson_review.encouraging_more_lessons_on_ringle',
    <span>
      本月未进行Ringle课程。
      <br /> 只要坚持Ringle课程 英语实力一定会得到提升。
    </span>,
    <span>
      本月您未進行Ringle課程，
      <br />
      只有按時進行Ringle課程 才能提升英語實力。
    </span>,
    <span>
      この月はRingleのレッスンを受けませんでした。
      <br /> 地道にRingleのレッスンを受けることだけが英語 力を向上することができます。
    </span>,
    <span>Bạn chưa học buổi nào trong tháng này.</span>,
  ],
  encouraging_more_lessons_on_ringle_48_hour_exception: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
      <br /> 48시간 이내 완료된 수업은 조회되지 않을 수 있습니다.
    </span>,
    <span>
      You haven't taken any lessons this month.
      <br />
      Lessons completed within 48 hours may not be shown.
      <br />
    </span>,
    's_lesson_review.encouraging_more_lessons_on_ringle_48_hour_exception',
    <span>
      本月未进行Ringle课程。 <br /> 只要坚持Ringle课程 英语实力一定会得到提升。
      <br /> 可能无法查询到48小时内完成的课程。
    </span>,
    <span>
      本月您未進行Ringle課程，
      <br />
      只有按時進行Ringle課程 才能提升英語實力。
      <br />
      可能無法查詢48小時以內完成的課程。
    </span>,
    <span>
      この月は受講されたRingleレッスンはありませんでした。 <br /> 英語力の向上は地道なレッスン受講が鍵となります。
      <br /> 48時間以内に完了したレッスンは照会できないことがあります。
    </span>,
    <span>
      Bạn chưa học buổi nào trong tháng này.
      <br />
      Buổi học hoàn thành trong 48 giờ có thể không hiển thị.
      <br />
    </span>,
  ],
  showAllTutors: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllTutors',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllPackets: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPackets',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllWordss: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllWordss',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  showAllPhrasess: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPhrasess',
    `${bool ? '隐藏' : '查看全部'}`,
    `${bool ? '收起' : '查看全部'}`,
    `${bool ? '閉じる' : 'すべて見る'}`,
    `${!bool ? 'Xem tất cả' : 'Đóng'}`,
  ],
  okay: [`확인`, `Ok`, 's_lesson_review.okay', `确认`, `確認`, `確認`, `Ok`],
  review_rate: [
    `평균 복습률`,
    `Review`,
    's_lesson_review.review_rate',
    `平均复习率`,
    `平均複習率`,
    `平均復習率`,
    `Xem lại`,
  ],
  prep_: [`평균 예습률`, `Prep`, 's_lesson_review.prep_', `平均预习率`, `平均預習率`, `平均予習率`, `Chuẩn bị`],
  lessson_distribution_based_on_completion: [
    `예습 및 복습 완료한 수업 수`,
    `Lesson Prepped & Reviewed`,
    's_lesson_review.lessson_distribution_based_on_completion',
    `完成预习及复习的课程数量`,
    `完成預習與複習的課程數`,
    `予習および復習が完了したレッスン数`,
    `Bài học Đã chuẩn bị & Đã xem lại`,
  ],
  prep_only: [
    `예습만 완료`,
    `Prep only`,
    's_lesson_review.prep_only',
    `仅完成了预习`,
    `僅完成預習`,
    `予習のみ完了`,
    `Đã chuẩn bị`,
  ],
  review_only: [
    `복습만 완료`,
    `Rev. only`,
    's_lesson_review.review_only',
    `仅完成了复习`,
    `僅完成複習`,
    `復習のみ完了`,
    `Đã xem lại`,
  ],
  both_completed: [
    `모두 완료`,
    `Both`,
    's_lesson_review.both_completed',
    `全部完成`,
    `全部完成`,
    `すべて完了`,
    `Cả hai`,
  ],
  both_incomplete: [
    `모두 미완료`,
    `Neither`,
    's_lesson_review.both_incomplete',
    `全部未完成`,
    `全部未完成`,
    `すべて未完了`,
    `Không cái nào`,
  ],
  total: [`총`, `Total`, 's_lesson_review.total', `总`, `一共`, `合計`, `Tổng cộng`],
  gae: [`개`, ``, 's_lesson_review.gae', `个`, `個`, `個`, ``],
  gae_words: [`개`, `words`, 's_lesson_review.gae_words', `个`, `個`, `個`, `từ`],
  gae_phrases: [`개`, `expressions`, 's_lesson_review.gae_phrases', `个`, `個`, `個`, `mẫu câu`],
  myung: [`명`, ``, 's_lesson_review.myung', `名`, `名`, `名`, ``],
  _3_tutors_you_did_a_lesson_with_the_most: [
    `제일 많이 함께한 튜터 3명`,
    `3 Tutors you had the most lessons with`,
    's_lesson_review._3_tutors_you_did_a_lesson_with_the_most',
    `选择次数最多的3名教师`,
    `最常一起學習的3名任課教師`,
    `最も多くレッスンを受けたチューター3名`,
    `3 gia sư bạn học cùng nhiều nhất`,
  ],
  _3_packets_you_did_a_lesson_with_the_most: [
    `제일 많이 학습한 교재 3개`,
    `3 Materials you selected the most`,
    's_lesson_review._3_packets_you_did_a_lesson_with_the_most',
    `学习次数最多的3个教材`,
    `最常學習的3個教材`,
    `最も多く学習した教材3つ`,
    `3 tài liệu bạn chọn nhiều nhất`,
  ],
  convert_num_to_month: (num) => [
    `${num}월`,
    `${getMonthName(num - 1)[1]}`,
    's_lesson_review.convert_num_to_month',
    `${num}月`,
    `${num}月`,
    `${num}月`,
    `Tháng ${num}`,
  ],
  convert_nums_to_month_and_year: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[0]}, ${year}`,
    's_lesson_review.convert_nums_to_month_and_year',
    `${year}年${month}月`,
    `${year}年${month}月`,
    `${year}年 ${month}月`,
    `Tháng ${month} năm ${year}`,
  ],
  last_3_months: [
    `최근 3개월`,
    `Last 3 months`,
    's_lesson_review.last_3_months',
    `最近3个月`,
    `最近3個月`,
    `最近3か月`,
    `3 tháng qua`,
  ],
  lessons_within_last_3_months: [
    `최근 3개월 수업 수`,
    `Lessons of the last 3 months`,
    's_lesson_review.lessons_within_last_3_months',
    `最近3个月的课程数量`,
    `最近3個月的課程數`,
    `最近3か月のレッスン数`,
    `Buổi học trong 3 tháng qua`,
  ],
  lessons_within_last_3_months_sample_data: [
    <span>
      최근 3개월 수업 수<span className="text-muted font-size-sm"> 샘플 데이터 입니다.</span>
    </span>,
    <span>
      Lessons of the last 3 months
      <span className="text-muted font-size-sm"> sample chart</span>
    </span>,
    's_lesson_review.lessons_within_last_3_months_sample_data',
    <span>
      最近3个月课程数量的
      <span className="text-muted font-size-sm"> 样本数据。</span>
    </span>,
    <span>
      最近3個月課程數的
      <span className="text-muted font-size-sm">樣本數據。</span>
    </span>,
    <span>
      最近3か月のレッスン数
      <span className="text-muted font-size-sm"> サンプルデータです。</span>
    </span>,
    <span>
      Buổi học trong 3 tháng qua
      <span className="text-muted font-size-sm"> sample chart</span>
    </span>,
  ],
  last_6_months: [
    `최근 6개월`,
    `Last 6 months`,
    's_lesson_review.last_6_months',
    `最近6个月`,
    `最近6個月`,
    `直近6か月`,
    `6 tháng qua`,
  ],
  last_1_year: [`최근 1년`, `Last 1 year`, 's_lesson_review.last_1_year', `最近1年`, `最近1年`, `直近1年`, `1 năm qua`],
  avg_score: [
    `평균 점수`,
    `Avg. Score`,
    's_lesson_review.avg_score',
    `平均分数`,
    `平均分數`,
    `平均点数`,
    `Điểm trung bình`,
  ],
  feedback_avg_score: [
    `튜터 피드백 평균 점수`,
    `Your Avg. Tutor Feedback Score`,
    's_lesson_review.feedback_avg_score',
    `教师反馈平均分数`,
    `任課教師意見回饋的平均分數`,
    `チューターフィードバック平均点数`,
    `Điểm trung bình đánh giá của gia sư`,
  ],
  feedback_avg: [
    `튜터 피드백: 평균`,
    `Tutor Feedback Score`,
    's_lesson_review.feedback_avg',
    `教师反馈: 平均`,
    `任課教師意見回饋：平均`,
    `チューターフィードバック: 平均`,
    `Điểm đánh giá của gia sư`,
  ],
  tutor_and_packet_for_this_lesson: [
    `이 수업의 튜터와 교재`,
    `Tutor and Materials for This Lesson`,
    's_lesson_review.tutor_and_packet_for_this_lesson',
    `此课程的教师及教材`,
    `此課程的任課教師與教材`,
    `このレッスンのチューターと教材`,
    `Gia sư và Tài liệu cho Buổi học này`,
  ],
  packet: [`교재`, `Materials`, 's_lesson_review.packet', `教材`, `教材`, `教材`, `Tài liệu`],
  packetSingular: [`교재`, `Materials`, 's_lesson_review.packetSingular', `教材`, `教材`, `教材`, `Tài liệu`],
  detailed_score: [
    `영역별 점수`,
    `Score by Area`,
    's_lesson_review.detailed_score',
    `各方面分数`,
    `各領域分數`,
    `分野別点数`,
    `Điểm từng phần`,
  ],
  my: [`내`, `Your`, 's_lesson_review.my', `本人`, `我的`, `あなたの`, `Của bạn`],
  last_10_lessons: [
    `최근 수업 10회`,
    `Last 10 Lessons`,
    's_lesson_review.last_10_lessons',
    `最近10次课程`,
    `最近10次課程`,
    `直近10回のレッスン`,
    `10 buổi học qua`,
  ],
  last_30_lessons: [
    `최근 수업 30회`,
    `Last 30 Lessons`,
    's_lesson_review.last_30_lessons',
    `最近30次课程`,
    `最近30次課程`,
    `直近30回のレッスン`,
    `30 buổi học qua`,
  ],
  last_50_lessons: [
    `최근 수업 50회`,
    `Last 50 Lessons`,
    's_lesson_review.last_50_lessons',
    `最近50次课程`,
    `最近50次課程`,
    `直近50回のレッスン`,
    `50 buổi học qua`,
  ],
  last_100_lessons: [
    `최근 수업 100회`,
    `Last 100 Lessons`,
    's_lesson_review.last_100_lessons',
    `最近100次课程`,
    `最近100次課程`,
    `直近100回のレッスン`,
    `100 buổi học qua`,
  ],
  my_score: [`내 점수`, `You`, 's_lesson_review.my_score', `我的分数`, `我的分數`, `あなたの点数`, `Bạn`],
  number_of_used_words: [
    `사용 단어 수`,
    `Used Words`,
    's_lesson_review.number_of_used_words',
    `使用的单词数量`,
    `使用的單字數`,
    `使用単語数`,
    `Từ đã dùng`,
  ],
  _40_min_lesson: [
    `40분 수업`,
    `40min`,
    's_lesson_review._40_min_lesson',
    `40分钟课程`,
    `40分鐘課程`,
    `40分レッスン`,
    `40 phút`,
  ],
  all_lesson: [
    `전체 수업`,
    `Total`,
    's_lesson_review.all_lesson',
    `全部课程`,
    `全部課程`,
    `すべてのレッスン`,
    `Tổng cộng`,
  ],
  packets_info1: [
    `해당 월에 학습한 교재 수를 분석하며, 중복 교재는 제외합니다.`,
    `Counts unique materials used in the selected month.`,
    's_lesson_review.packets_info1',
    `分析当月学习的教材数量, 重复教材除外。`,
    `分析該月學習的教材數，扣除重複的教材。`,
    `その月に学習した教材数の合計で、重複教材は除きます。`,
    `Đếm các tài liệu khác nhau bạn sử dụng trong tháng được chọn.`,
  ],
  packets_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.packets_info2',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Buổi học hoàn thành trong 48 giờ có thể không hiển thị.`,
  ],
  tutor_info1: [
    `해당 월에 함께한 튜터 수를 분석하며, 중복 튜터는 제외합니다.`,
    `Counts number of different tutors you had lessons with on a monthly basis.`,
    's_lesson_review.tutor_info1',
    `分析当月一起学习的教师人数, 重复教师除外。`,
    `分析該月一起學習的任課教師人數，扣除重複的任課教師。`,
    `その月にレッスンを受けたチューター数の合計で、重複チューターは除きます。`,
    `Đếm số lượng gia sư khác nhau bạn học cùng trong một tháng.`,
  ],
  tutor_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.tutor_info2',
    `*为保证数据分析的准确性, 统计截止于48小时前完成的课程。`,
    `*為了精確分析數據，反映48小時之前完成的課程。`,
    `*正確なデータ分析のため、48時間前までに完了したレッスンが反映されます。`,
    `*Buổi học hoàn thành trong 48 giờ có thể không hiển thị.`,
  ],
  tutors_number: [
    `튜터 수`,
    `Tutors`,
    's_lesson_review.tutors_number',
    `教师人数`,
    `任課教師人數`,
    `チューター数`,
    `Gia sư`,
  ],
  see_review_list: [
    `복습 목록 전체보기`,
    `View all`,
    's_lesson_review.see_review_list',
    `查看全部复习目录`,
    `查看所有複習清單`,
    `復習リストをすべて見る`,
    `Xem tất cả`,
  ],
  no_completed_lesson: [
    `현재  완료한 수업이 없습니다.`,
    `You haven't completed any lessons.`,
    's_lesson_review.no_completed_lesson',
    `当前没有完成的课程。`,
    `目前無已完成的課程。`,
    `現在完了したレッスンはありません。`,
    `Bạn chưa hoàn thành buổi học nào.`,
  ],
  recorded_script: [
    `녹음파일`,
    `Recording file`,
    's_lesson_review.recorded_script',
    `录音文件`,
    `錄音檔`,
    `録音ファイル`,
    `File thu âm`,
  ],
  in_review_page_what_you_can_do: [
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        복습페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 완료한 수업의 스크립트 및 분석 내용,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        튜터의 피드백 리포트 등을 확인하거나 수업을 다시 들을 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can replay lesson recording, read tutor feedback, or check AI Analysis under
      </Div>
      <Div spanTag textGray700 fontSize18 fontBold ml4>
        Lesson Review
      </Div>
    </>,
    's_lesson_review.in_review_page_what_you_can_do',
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        于复习页面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        可对完成的课程脚本及分析内容,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        教师的Feedback Report等进行确认或重听课程。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        可於複習頁面
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        確認完成的課程腳本及分析內容、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        任課教師的意見回饋報告書等，或再次聆聽課程。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        復習ページ
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        では完了したレッスンのスクリプトおよび分析内容、
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        チューターのFeedback Reportなどを確認したりレッスンをもう一度聴いたりすることができます。
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        Bạn có thể nghe lại thu âm, đọc đánh giá của gia sư hoặc kiểm tra phân tích AI ở phần
      </Div>
      <Div spanTag textGray700 fontSize18 fontBold>
        Xem lại buổi học
      </Div>
    </>,
  ],
  ringle_usage: [
    `링글 사용법`,
    `How Ringle works`,
    's_lesson_review.ringle_usage',
    `Ringle使用方法`,
    `Ringle使用方法`,
    `Ringleの使用方法`,
    `Cách Ringle hoạt động`,
  ],
  zoom_usage: [
    `화상 프로그램 (Zoom) 설치 가이드`,
    `How to Install Zoom`,
    's_lesson_review.zoom_usage',
    `视频程序(Zoom)安装指南`,
    `視訊軟體（Zoom）安裝指南`,
    `ビデオ会議アプリ (Zoom) インストールガイド`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  no_noshow_lesson: [
    `노쇼한 수업이 없습니다.`,
    `You showed up to all lessons.`,
    's_lesson_review.no_noshow_lesson',
    `没有旷课记录。`,
    `無未出現的課程。`,
    `無断欠席したレッスンはありません。`,
    `Bạn tham gia tất cả buổi học.`,
  ],
  tooltip: [
    `다운로드할 수업 녹음 파일이 없습니다.`,
    `There are no downloadable audio files.`,
    's_lesson_review.tooltip',
    `没有可下载的课程录音。`,
    `無可下載的課程錄音檔。`,
    `ダウンロードするレッスン録音ファイルはありません。`,
    `Không có tập âm thanh nào có thể tải.`,
  ],
  teens_complete_feedback: [
    `튜터 피드백 확인하기 완료`,
    `Done checking feedback`,
    's_lesson_review.teens_complete_feedback',
    `已完成教师反馈的确认`,
    `已確認任課教師的意見回饋`,
    `チューターフィードバック確認完了`,
    `Đã xem xong đánh giá`,
  ],
  caf_check: [
    `CAF 진단 확인하기`,
    `CAF 진단 확인하기`,
    's_lesson_review.caf_check',
    `CAF 진단 확인하기`,
    `CAF 진단 확인하기`,
    `CAF 진단 확인하기`,
    `CAF 진단 확인하기`,
  ],
  caf_tab: [`CAF 진단`, `CAF 진단`, 's_lesson_review.caf_tab', `CAF 진단`, `CAF 진단`, `CAF 진단`, `CAF 진단`],
  caf_menu: [`CAF 진단`, `CAF 진단`, 's_lesson_review.caf_menu', `CAF 진단`, `CAF 진단`, `CAF 진단`, `CAF 진단`],
  caf_msg: {
    noshow: [
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      's_lesson_review.caf_msg.noshow',
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
    ],
    noaudio: [
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      's_lesson_review.caf_msg.noaudio',
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
    ],
    freetopic: [
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      's_lesson_review.caf_msg.freetopic',
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
    ],
    prep: [
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      's_lesson_review.caf_msg.prep',
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
    ],
    gotoapp: [
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      's_lesson_review.caf_msg.gotoapp',
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
    ],
  },
  after_rating_the_lesson_pointback: (point) => [
    <>새로 온 튜터와 수업하셨군요! 수업 후기를 남기면 {point}P를 바로 받을 수 있어요!</>,
    <>How was your lesson with the Rookie Tutor? Leave a review and receive {point}P!</>,
    's_lesson_review.after_rating_the_lesson_pointback',
    <>和新導師一起完成了第一課！提交課後評價，即可獲得 {point}P！</>,
    <>和新导师一起完成了第一课！提交课后评价，即可获得 {point}P！</>,
    <>
      新しく来たチューターとレッスンしましたね！レッスンのレビューを残していただけたら {point}Pをすぐに受け取れます！
    </>,
    <>Buổi học với gia sư mới thế nào? Hãy để lại đánh giá và nhận điểm {point}!</>,
  ],
}
export const s_classroom = {
  help: [`지원 요청`, `Help`, 's_classroom.help', `在线帮助`, `即時Help`, `リアルタイムHelp`, `Hỗ trợ`],
  audio_problem: [
    `오디오 연결 오류`,
    `Audio issue`,
    's_classroom.audio_problem',
    `听不到声音`,
    `聽不到聲音`,
    `声が聞こえません`,
    `Vấn đề về âm thanh`,
  ],
  video_problem: [
    `Zoom 비디오 오류`,
    `Video issue`,
    's_classroom.video_problem',
    `看不到视频`,
    `看不到影像`,
    `ビデオが映りません`,
    `Vấn đề về video`,
  ],
  write_your_message: [
    `메시지를 입력해주세요`,
    `Write your message`,
    's_classroom.write_your_message',
    `请输入信息`,
    `請輸入訊息`,
    `メッセージを入力してください`,
    `Viết lời nhắn của bạn`,
  ],
  docs_problem: [
    `Ringle Docs 오류`,
    `Ringle Docs issue`,
    's_classroom.docs_problem',
    `看不到Ringle Docs`,
    `看不到Ringle Docs`,
    `Ringle Docsが見えません`,
    `Vấn đề về Ringle Docs`,
  ],
  zoom_problem: [
    `Zoom 실행 오류`,
    `Cannot open Zoom`,
    's_classroom.zoom_problem',
    `Zoom无法运行`,
    `無法執行Zoom`,
    `Zoomが起動しません`,
    `Không thể mở Zoom`,
  ],
  zoom_guide: [
    `Zoom 가이드`,
    `Zoom Guide`,
    's_classroom.zoom_guide',
    `Zoom指南`,
    `Zoom指南`,
    `Zoomガイド`,
    `Hướng dẫn dùng Zoom`,
  ],
  minimize_room_drage_it_here: [
    `Zoom을 최소화하여 이곳에 놓아주세요`,
    `Minimize ‘Zoom’ and drag it here.`,
    's_classroom.minimize_room_drage_it_here',
    `请将Zoom最小化放置于这里`,
    `請將Zoom最小化後放置於這裡。`,
    `Zoomを最小化してここにペーストしてください。`,
    `Thu nhỏ ‘Zoom’ và kéo vào đây.`,
  ],
  click_on_join_audio_button: [
    `소리가 들리지 않을 경우, Zoom화면 왼쪽 하단의 '오디오 연결' 또는 'Join audio' 아이콘을 눌러 [컴퓨터 오디오로 참가] 버튼을 클릭해주세요.`,
    `If you cannot hear the audio, place your mouse cursor on Zoom. Click on the "Join audio" button to start.`,
    's_classroom.click_on_join_audio_button',
    `如果没有声音, 请将鼠标移动至Zoom上方。点击左下方'参与视频'即'Join audio'图标进行尝试。`,
    `如果聽不到聲音，請將滑鼠移至Zoom上方。點擊左下方「加入音訊」或「Join audio」圖示進行。`,
    `音が聞こえない場合は、Zoomにマウスをのせてください。左下の「オーディオに参加」または「Join audio」アイコンを押してください。`,
    `Nếu bạn không thể nghe âm thanh, đưa chuột về màn hình Zoom. Chọn "Join audio" để nghe.`,
  ],
  plz_click_if_u_cannot_open_zoom: [
    `Zoom이 실행되지 않을때는 아래의 버튼을 클릭해주세요`,
    `If you cannot open Zoom, please click on the link below.`,
    's_classroom.plz_click_if_u_cannot_open_zoom',
    `Zoom无法运行时, 请点击下方按钮。`,
    `無法執行Zoom時，請點擊下方按鈕。`,
    `Zoomが開かない場合は下のボタンをクリックしてください。`,
    `Nếu bạn không thể mở Zoom, nhấn vào link dưới đây.`,
  ],
  zoom_force_run: [
    `Zoom 강제 실행`,
    `Force zoom to run`,
    's_classroom.zoom_force_run',
    `Zoom强制执行`,
    `強制執行Zoom`,
    `Zoom強制実行`,
    `Bắt buộc khởi chạy Zoom`,
  ],
  lesson_style: [
    `수업 방식`,
    `Lesson Style`,
    's_classroom.lesson_style',
    `课程形式`,
    `上課方式`,
    `レッスン方式`,
    `Hình thức học`,
  ],
  my_answer: [
    `내 답변`,
    `My Response`,
    's_classroom.my_answer',
    `我的回答`,
    `我的回覆`,
    `あなたの回答`,
    `Phản hồi của tôi`,
  ],
  leave_classroom: [
    `수업 나가기`,
    `Exit`,
    's_classroom.leave_classroom',
    `离开课程`,
    `離開課程`,
    `レッスンから退室する`,
    `Thoát ra`,
  ],
  leave_classroom_confirm: [
    `수업 화면에서 나가시겠습니까?`,
    `Are you sure you want to exit?`,
    's_classroom.leave_classroom_confirm',
    `您确定要离开课程画面吗?`,
    `確定要離開課程畫面嗎？`,
    `レッスン画面を閉じますか？`,
    `Bạn có chắc muốn thoát ra?`,
  ],
  popup_block_title: [
    `팝업 허용 요청`,
    `Please allow pop-ups on this page.`,
    's_classroom.popup_block_title',
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
    `Please allow pop-ups on this page.`,
  ],
  popup_block_subtitle: [
    `수업 프로그램인 Zoom을 실행하기 위해 팝업을 허용해주세요`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    's_classroom.popup_block_subtitle',
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
  ],
  modal_title: [
    `Zoom 화상수업 시작하기`,
    `Connecting to Zoom for Your Lesson`,
    's_classroom.modal_title',
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
    `Connecting to Zoom for Your Lesson`,
  ],
  modal_desc: [
    `곧 자동으로 열리는 Zoom 오디오는 이렇게 연결할 수 있어요.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    's_classroom.modal_desc',
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
  ],
  btn_1: [
    `다시 보지 않기`,
    `Do not show again`,
    's_classroom.btn_1',
    `Do not show again`,
    `Do not show again`,
    `Do not show again`,
    `Do not show again`,
  ],
  btn_2: [`확인`, `Got it`, 's_classroom.btn_2', `Got it`, `Got it`, `Got it`, `Got it`],
  modal_zoom_title: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    's_classroom.modal_zoom_title',
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
  ],
  modal_zoom_tap_1: [
    `소리가 들리지 않을 때`,
    `No sound is coming out`,
    's_classroom.modal_zoom_tap_1',
    `No sound is coming out`,
    `No sound is coming out`,
    `No sound is coming out`,
    `No sound is coming out`,
  ],
  modal_zoom_tap_2: [
    `Zoom이 실행되지 않을 때`,
    `Zoom won't launch`,
    's_classroom.modal_zoom_tap_2',
    `Zoom won't launch`,
    `Zoom won't launch`,
    `Zoom won't launch`,
    `Zoom won't launch`,
  ],
  modal_zoom_desc_1: [
    <>
      Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
      <br />
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>{' '}
      에서 카메라와 오디오를 테스트 할 수 있습니다.
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    's_classroom.modal_zoom_desc_1',
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
  ],
  modal_zoom_desc_2: (helpLink) => [
    <>
      Zoom이 열리지 않는다면 아래의 [Zoom 다시 열기]를 클릭하거나 링글 모바일 앱을 통해 접속해 주세요. 만약 문제가
      해결되지 않는다면, 페이지 상단의 {helpLink}을 통해 문의를 남겨주세요.
    </>,
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
    's_classroom.modal_zoom_desc_2',
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
  ],
  btn: [
    `Zoom 다시 열기`,
    `Relaunch Zoom`,
    's_classroom.btn',
    `Relaunch Zoom`,
    `Relaunch Zoom`,
    `Relaunch Zoom`,
    `Relaunch Zoom`,
  ],
  zoom_help: [
    `Zoom 오류 해결`,
    `Zoom Troubleshooting`,
    's_classroom.zoom_help',
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
    `Zoom Troubleshooting`,
  ],
  zoom_tooltip: [
    `Zoom이 열리지 않는다면 이곳을 클릭하세요.`,
    `If Zoom doesn't launch automatically,  
please click here.`,
    's_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_common = {
  ringle: [`Ringle`, `Ringle`, 's_common.ringle', `Ringle`, `Ringle`, `Ringle`, `Ringle`],
  minutes: [`분`, `-min`, 's_common.minutes', `分钟`, `分鐘`, `分`, `-phút`],
  minutesNoDash: [`분`, `min`, 's_common.minutesNoDash', `分钟`, `分鐘`, `分`, `phút`],
  ok: [`확인`, `Ok`, 's_common.ok', `确认`, `確定`, `OK`, `Ok`],
  confirm: [`확인`, `Confirm`, 's_common.confirm', `确认`, `確定`, `確認`, `Xác nhận`],
  cancel: [`취소`, `Cancel`, 's_common.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  exit: [`나가기`, `Exit`, 's_common.exit', `离开`, `離開`, `閉じる`, `Thoát ra`],
  send: [`보내기`, `Send`, 's_common.send', `发送`, `傳送`, `送る`, `Gửi`],
  save: [`저장`, `Save`, 's_common.save', `保存`, `儲存`, `保存`, `Lưu`],
  dont_show_again: [
    `다시 보지 않기`,
    `Don't show again`,
    's_common.dont_show_again',
    `不再显示`,
    `不再顯示`,
    `今後表示しない`,
    `Đừng hiển thị lại`,
  ],
  select: [`선택하기`, `Select`, 's_common.select', `选择`, `選擇`, `選択する`, `Chọn`],
  timezone: [`타임존`, `Time zone`, 's_common.timezone', `时区`, `時區`, `タイムゾーン`, `Múi giờ`],
  today: [`오늘`, `Today`, 's_common.today', `今日`, `今日`, `今日`, `Hôm nay`],
  _40min: [`40분`, `40min`, 's_common._40min', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  _20min: [`20분`, `20min`, 's_common._20min', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  you_can_search_by_keyboard: [
    `도시를 직접 입력하시거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list.`,
    's_common.you_can_search_by_keyboard',
    `请直接输入您所在的城市或从列表中进行选择。`,
    `請手動輸入城市或從清單中選擇。`,
    `都市を直接入力するか、リストから選択してください。`,
    `Nhập tên thành phố hoặc chọn từ danh sách.`,
  ],
  locale: (userLocale, routerLocale) => [
    <>
      현재 설정된 언어({userLocale})와 접속하려는 페이지의 언어({routerLocale}
      )가 다릅니다. 언어설정을 {routerLocale}(으)로 변경하시겠습니까?
    </>,
    <>
      The page you're about to enter has a different language setting ({routerLocale}) from your current language (
      {userLocale}). Do you want to change your current language setting to {routerLocale}?
    </>,
    's_common.locale',
    <>
      当前设定的语言({userLocale})和您要连接的页面语言({routerLocale}
      )存在差异。您要将语言变更为{routerLocale}(으)吗?
    </>,
    <>
      目前設定的語言（{userLocale}）和您欲連線的頁面語言 （{routerLocale}）不同。您要將語言變更為{routerLocale}嗎？
    </>,
    <>
      現在設定されている言語({userLocale})と接続しようとしているページの言語({routerLocale}
      )が異なります。言語設定を {routerLocale}に変更しますか？
    </>,
    <>
      Trang bạn chuẩn bị vào có cài đặt ngôn ngữ khác ({routerLocale}) ngôn ngữ bạn sử dụng ({userLocale}). Bạn có muốn
      đổi cài đặt ngôn ngữ hiện tại của bạn qua {routerLocale}?
    </>,
  ],
  save_and_enter: [
    `저장 & 수업 입장`,
    `Save & Enter`,
    's_common.save_and_enter',
    `保存 & 课程入场`,
    `儲存 & 進入課程`,
    `保存 & レッスン入室`,
    `Lưu & Enter`,
  ],
  mon: [`월`, `Mon`, 's_common.mon', `一`, `一`, `月`, `Thứ hai`],
  tue: [`화`, `Tue`, 's_common.tue', `二`, `二`, `火`, `Thứ ba`],
  wed: [`수`, `Wed`, 's_common.wed', `三`, `三`, `水`, `Thứ tư`],
  thu: [`목`, `Thu`, 's_common.thu', `四`, `四`, `木`, `Thứ năm`],
  fri: [`금`, `Fri`, 's_common.fri', `五`, `五`, `金`, `Thứ sáu`],
  sat: [`토`, `Sat`, 's_common.sat', `六`, `六`, `土`, `Thứ bảy`],
  sun: [`일`, `Sun`, 's_common.sun', `日`, `日`, `日`, `Chủ nhật`],
  please_contact_us_via_chat: [
    `페이지가 로드 되지 않으면, 아래 챗으로 문의주세요`,
    `If the page doesn't load, please contact us via chat.`,
    's_common.please_contact_us_via_chat',
    `如果网页不显示，请使用下面的方式联系我们`,
    `如果網頁不顯示，請使用下面的方式聯係我們`,
    `ページがロードされない場合は、下記のチャットへお問い合わせください`,
    `Nếu trang không tải được, vui lòng liên hệ Ringle qua chat.`,
  ],
  cookiebanner: {
    first: [
      `이 웹사이트를 계속 사용하시면 쿠키 사용에 동의하시는 것으로 간주됩니다.`,
      `By continuing to use this site, you accept our`,
      's_common.cookiebanner.first',
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
      `By continuing to use this site, you accept our`,
    ],
    second: [
      `쿠키 사용 여부`,
      `use of cookies.`,
      's_common.cookiebanner.second',
      `use of cookies.`,
      `use of cookies.`,
      `use of cookies.`,
      `use of cookies.`,
    ],
    third: [
      `는 설정을 통해 언제든 변경하실 수 있습니다.`,
      `You can change your cookie settings at any time.`,
      's_common.cookiebanner.third',
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
      `You can change your cookie settings at any time.`,
    ],
  },
  new: [`NEW`, ``, 's_common.new', ``, ``, ``, ``],
}
export const s_head = {
  student: {
    title: [
      `No.1 일대일 화상영어 링글`,
      `Ringle: Premium Private English Tutoring`,
      's_head.student.title',
      `Ringle: 1对1视频英语会话`,
      `Ringle: 1對1視訊英語會話`,
      `Ringle: プレミアムマンツーマン英語レッスン`,
      `Ringle: Học Tiếng Anh trực tuyến 1:1`,
    ],
    desc: [
      `스탠퍼드 MBA출신이 만든 1:1 화상 영어 명문대 원어민 튜터의 똑똑한 수업을 경험하세요!`,
      `Have an in-depth discussion with tutors from the top 30 universities in the U.S. and the U.K.`,
      's_head.student.desc',
      `请与常春藤名校任课教师用高品质教材进行讨论来纠正我的英语吧！`,
      `與常春藤名校任課教師以高品質教材進行討論，糾正我的英語吧！`,
      `アメリカ・イギリスの上位30大学に在籍のチューターとレベルの高いディスカッションをしましょう。`,
      `Thảo luận chuyên sâu cùng các gia sư đến từ top 30 trường đại học hàng đầu Anh và Mỹ.`,
    ],
    keywords: [
      `유학, 영어, 원어민, 일대일영어, 화상영어, 미국대학, 미국유학, 전화영어, 원어민 화상영어, 일대일 영어 회화, 화상영어 사이트, 미국대학교 순위`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.student.keywords',
      `留学, 英语, 母语人士, 1对1英语, 线上英语, 美国大学, 美国留学, 电话英语, 母语视频英语, 1对1英语会话, 视频英语网站, 美国大学排名`,
      `留學、英語、母語人士、1:1英語、視訊英語、美國大學、美國留學、電話英語、母語人士視訊英語、1:1英語會話、視訊英語網站、美國大學排名`,
      `留学、英語、ネイティブスピーカー、1:1英語、オンライン英会話、アメリカ大学、アメリカ留学、電話英語、ネイティブオンライン英会話、1:1英会話、オンライン英会話サイト、アメリカ大学ランキング`,
      `Đây là đâu`,
    ],
    author: [
      `Ringle Team`,
      `Ringle Team`,
      's_head.student.author',
      `Ringle Team`,
      `Ringle Team`,
      `Ringle Team`,
      `Đội ngũ Ringle`,
    ],
    noscript: [
      `귀하가 사용하고 계신 브라우저는 HTML5를 지원하고 있지 않아서, 할 수 없이 JScript를 써서 몇몇 요소가 제대로 보이도록 구현했습니다.
그런데 안타깝게도 귀하의 브라우저에선 스크립트 기능이 꺼져있습니다. 이 페이지가 제대로 표시되려면 스크립트 기능을 켜주셔야만 합니다.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.student.noscript',
      `您当前使用的浏览器无法支持HTML5, 因此只能使用JScript呈现部分要素。
但很抱歉您浏览器中的脚本功能已经关闭。您必须开启脚本功能, 才能完整显示此页面。`,
      `您目前使用的瀏覽器不支援HTML5，我們只能使用JScript呈現一些要素。
遺憾的是，在您的瀏覽器已關閉腳本功能。您必須打開腳本功能，才能完整顯示此頁面。`,
      `現在お使いのブラウザはHTML5に対応していないため、JScriptを使用していくつかの要素がきちんと見えるように実現しました。
しかし、残念ながらあなたのブラウザではスクリプト機能がオフになっています。このページを正しく表示するためには、スクリプト機能をオンにしてください。`,
      `Trình duyệt không được hỗ trợ. Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle.`,
    ],
    title_error_page: [
      `No.1 일대일 화상영어 링글.`,
      `Ringle: Premium Private English Tutoring.`,
      's_head.student.title_error_page',
      `Ringle: 1对1视频英语会话.`,
      `Ringle: 1對1視訊英語會話.`,
      `Ringle: プレミアムマンツーマン英語レッスン.`,
      `Ringle: Học Tiếng Anh trực tuyến 1:1.`,
    ],
  },
  tutor: {
    title: [
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      's_head.tutor.title',
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
    ],
    desc: [
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      's_head.tutor.desc',
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
    ],
    keywords: [
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.tutor.keywords',
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
    ],
    author: [
      `Ringle Team`,
      `Ringle Team`,
      's_head.tutor.author',
      `Ringle Team`,
      `Ringle Team`,
      `Ringle Team`,
      `Đội ngũ Ringle`,
    ],
    noscript: [
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.tutor.noscript',
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Trình duyệt không được hỗ trợ. Vui lòng sử dụng Google Chrome hoặc Microsoft Edge để truy cập vào Ringle.`,
    ],
  },
}
export const s_footer = {
  beyond_offline_new_standards: [
    `No.1 일대일 화상영어 링글`,
    `The New Standard in Online English Learning`,
    's_footer.beyond_offline_new_standards',
    `No.1的Ringle1对1线上英语`,
    `No.1的1:1視訊英語—Ringle`,
    `1on1オンライン英会話の新時代を駆けるRingle`,
    `Nâng tầm việc học tiếng Anh Online`,
  ],
  companay: [`Company`, `Company`, 's_footer.companay', `Company`, `Company`, `Company`, `Company`],
  b2b: [`Business`, `Business`, 's_footer.b2b', `Business`, `Business`, `Business`, `Business`],
  business_inquiry: [
    `제휴문의`,
    `B2B Overview`,
    's_footer.business_inquiry',
    `合作咨询`,
    `合作諮詢`,
    `法人向けサービスに関するお問い合わせ`,
    `Tổng quan B2B`,
  ],
  terms_of_privacy: [
    `개인정보처리방침`,
    `Privacy Policy`,
    's_footer.terms_of_privacy',
    `个人信息处理方针`,
    `個人資料處理方針`,
    `プライバシーポリシー`,
    `Chính sách bảo mật`,
  ],
  terms_of_service: [
    `이용약관`,
    `Terms of Service`,
    's_footer.terms_of_service',
    `使用条款`,
    `使用條款`,
    `利用規約`,
    `Điều khoản dịch vụ`,
  ],
  policy: [`정책`, `Policy`, 's_footer.policy', `政策`, `政策`, `ポリシー`, `Chính sách`],
  ringle_company: [
    `(주)링글잉글리시에듀케이션서비스`,
    `ⓒ Ringle English Education Service`,
    's_footer.ringle_company',
    `(株)Ringle English Education Service`,
    `Ringle英語教育服務股份有限公司`,
    `ⓒ Ringle English Education Service`,
    `ⓒ Ringle English Education Service`,
  ],
  ringle_address: [
    `서울 서초구 서초대로78길 22 (홍우제2빌딩) 11층`,
    `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_footer.ringle_address',
    `首尔江南区德黑兰路5街7号12层`,
    `首爾江南區德黑蘭路5街7號12樓`,
    `大韓民国ソウル特別市瑞草区瑞草路78ギル-22`,
    `7, Teheran-ro 5-gil, Gangnam-gu, Seoul, Republic of Korea`,
  ],
  ringle_phone: [
    <>
      사업자등록번호 876-81-00110 통신판매신고번호 2022-서울서초-3045
      <br />
      대표전화 02-6204-8880
    </>,
    ``,
    's_footer.ringle_phone',
    `营业执照号码: 876-81-00110 通信销售申告号码: 2019-首尔江南-04831 代表电话: 02-6204-8880`,
    `營利事業統一編號：876-81-00110 通訊販賣申報編號：2019-首爾江南-04831 公司代表號：02-6204-8880`,
    `事業者登録番号 876-81-00110 通信販売申告番号 2019-ソウル江南-04831 代表電話 02-6204-8880`,
    ``,
  ],
  ringle_ceo: [
    `대표이사 : 이성파, 이승훈. 개인정보책임자 : 이성파 contact@ringleplus.com`,
    `Email : contact@ringleplus.com`,
    's_footer.ringle_ceo',
    `代表理事: Lee Seongpa, Lee Seunghun。个人信息负责人: Lee Seongpa contact@ringleplus.com`,
    `代表理事：Lee Seongpa、Lee Seunghun。個人資料負責人：Lee Seongpa contact@ringleplus.com`,
    `代表取締役 : イ・ソンパ、イ・スンフン 個人情報責任者 : イ・ソンパ contact@ringleplus.com`,
    `Email : contact@ringleplus.com`,
  ],
  pricing: [`가격`, `Pricing`, 's_footer.pricing', `价格`, `價格`, `料金`, `Học phí`],
  ringle_team: [
    `링글 팀`,
    `Ringle Team`,
    's_footer.ringle_team',
    `Ringle团队`,
    `Ringle團隊`,
    `Ringleチーム`,
    `Ringle Team`,
  ],
  help_center: [`헬프센터`, `Help Center`, 's_footer.help_center', `常见问题`, `常見問題`, `ヘルプセンター`, `Help`],
  career: [`링글 채용`, ``, 's_footer.career', ``, ``, ``, ``],
}
export const s_faq = {
  enter_your_keyword: [
    `검색어를 입력하세요`,
    `Enter keyword(s) to search.`,
    's_faq.enter_your_keyword',
    `请输入搜索词`,
    `請輸入關鍵字`,
    `検索ワードを入力してください。`,
    `Nhập từ khoá để tìm kiếm.`,
  ],
  search: [`검색`, `Search`, 's_faq.search', `搜索`, `搜尋`, `検索`, `Tìm kiếm`],
  search_result: [
    `검색 결과`,
    `Search Results`,
    's_faq.search_result',
    `搜索结果`,
    `搜尋結果`,
    `検索結果`,
    `Kết quả tìm kiếm`,
  ],
  frequently_asked_questions: [
    `자주 묻는 질문`,
    `FAQ`,
    's_faq.frequently_asked_questions',
    `常见问题`,
    `常見問題`,
    `よくある質問`,
    `FAQ`,
  ],
  how_can_we_help_you: [
    `무엇을 도와드릴까요?`,
    `How can we help?`,
    's_faq.how_can_we_help_you',
    `您需要什么帮助?`,
    `需要什麼協助嗎？`,
    `何かお困りですか？`,
    `Chúng tôi có thể giúp gì cho bạn?`,
  ],
  channel_talk: [
    `1:1 문의하기`,
    `Contact Us`,
    's_faq.channel_talk',
    `在线咨询`,
    `聊天諮詢`,
    `お問い合わせ`,
    `Liên hệ với chúng tôi`,
  ],
}
export const s_privacy = {
  heading: [
    `개인정보처리방침`,
    `Privacy Policy`,
    's_privacy.heading',
    `个人信息处理方针`,
    `個人資料處理方針`,
    `個人情報保護方針`,
    `Chính sách bảo mật`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    's_privacy.text',
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
  ],
}
export const s_ip_policy = {
  heading: [
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    's_ip_policy.heading',
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    's_ip_policy.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
  ],
}
export const s_lesson_policy = {
  heading: [
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    's_lesson_policy.heading',
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
    `RINGLE LESSONS POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    's_lesson_policy.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
  ],
}
export const s_community_guide = {
  heading: [
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    's_community_guide.heading',
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    's_community_guide.text',
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
  ],
}
export const s_terms = {
  heading: [
    `이용 약관`,
    `Terms of Service`,
    's_terms.heading',
    `使用条款`,
    `使用條款`,
    `利用規約`,
    `Điều khoản dịch vụ`,
  ],
  text: [
    `
제1조(목적)
본 약관은 "서비스" 이용자가 (주)링글잉글리시에듀케이션서비스(이하 '회사')가 제공하는 온라인 영어교육 플랫폼(www.ringleplus.com) 및 제공되는 일체의 서비스(이하 '서비스’)를 이용함에 있어 회사와 회원의 권리와 의무 및 책임 사항, 이용 절차, 그리고 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조(용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
"서비스": 회사가 제공하는 수업과 교정 및 이용가능한 콘텐츠 일체
"회원" : 회사와 "서비스" 이용 계약을 체결하고 회사가 제공하는 "서비스"를 이용하는 자
"이메일(ID)" : 인터넷 메일 계정. 회사는 "회원"의 "이메일”을 "회원” 식별과 "회원"의 "서비스" 이용을 위한 고유의 아이디(ID)로 사용함
"비밀번호" : "회원"의 "이메일(ID)”과 일치된 "회원"임을 확인하고, "회원" 자신의 비밀을 보호하기 위하여 "회원"이 정한 문자와 숫자의 조합
"관리자" : "서비스"의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람
"수업권(Credit)” : 링글 1:1 영어회화 또는 인터뷰 수업 1회 단위를 수업권이라 함. 수업권 1개는 수업 1회를 의미함.
"패키지” : 링글이 2개 이상의 수업권을 묶음으로 판매하는 단위를 의미함. 예로, 6회 패키지는 곧 수업 6회(수업권 6개)의 묶음을 의미함.

제3조(약관의 효력과 변경)
회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 "서비스" 사이트 초기 화면에 게시합니다.
회사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.
회사는 약관을 개정하는 경우 개정 사유 및 시행일자를 명시하여 현행약관과 함께 시행일로부터 최소 7일 전에 제1항의 방법으로 공지합니다. 단, "회원"의 권리 또는 의무에 관한 중요한 규정의 변경은 최소 30일전에 공지하고 개정약관을 "회원"이 등록한 "이메일”로 발송하여 통지합니다.
회사의 제3항에 따른 개정약관 공지 또는 통지 후 "회원"이 약관 변경 적용일까지 명시적으로 거부의사를 표시하지 않은 경우 "회원"이 개정약관에 동의한 것으로 봅니다. 단, "회원"은 변경된 약관에 동의하지 않는 경우 "서비스"의 이용을 중단하고 이용계약을 해지할 수 있습니다.
"회원"은 약관의 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 "회원"의 피해는 회사가 책임지지 않습니다.

제4조(약관의 준칙)
이 약관에 명시되지 않은 사항과 이 약관의 해석에 관하여는 전기통신기본법, 전기통신사업법, 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호 관련 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 문화체육관광부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.

제5조(이용 계약의 성립)
이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.
"회원"에 가입하여 "서비스"를 이용하고자 하는 자는 회사에서 요청하는 개인신상정보를 제공해야 합니다.
회사는 다음 각 호에 해당하는 이용 신청에 대하여는 승낙하지 아니할 수 있습니다.
1) 이름이 실명이 아니거나 다른 사람의 명의를이용한 경우
2) 이용 신청 시 필요 내용을 허위로 기재하여 신청한 경우
3) 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
4) 기타 회사가 정한 이용 신청요건이 미비 된 경우

제6조(계약 사항의 변경)
"회원"은 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, "서비스" 관리를 위해 필요한 이메일(ID)는 수정이 불가능합니다.
"회원"은 가입시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 변경사항을 알려야 합니다.
제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제7조(개인정보보호 의무)
회사는 이용자의 정보 수집 시 "서비스" 이용을 위한 최소한의 정보를 수집합니다.
회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. 제공된 개인정보는 당해 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며 이에 대한 모든 책임은 회사가 집니다.
이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집 목적 및 이용 목적, 제3자에 대한 정보 제공 관련 사항(제공 받는 자, 제공 목적 및 제공할 정보의 내용), 수탁업체에 대한 정보제공 관련사항(수탁자, 위탁업무 내용 및 정보제공 항목) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’이라 함)에 규정한 사항을 미리 명시하거나 고지해야 하며 "회원”은 언제든지 이 동의를 철회할 수 있습니다.
회사는 개인정보 보호를 위하여 "관리자"를 최소한의 수로 한정하며 이용자 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
회사 또는 그로부터 개인정보를 제공 받는 제3자는 개인 정보의 수집목적 또는 제공 받는 목적을 달성할 때에는 당해 개인정보를 지체 없이 파괴합니다.

제8조 ("회원"의 "이메일(ID)" 및 "비밀번호" 관리 의무)
"회원"의 "이메일(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
회사는 "회원"의 "이메일(ID)"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 "이메일(ID)"의 이용을 제한할 수 있습니다.
"회원"은 "이메일(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
제3항의 경우에 해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제9조(정보 제공 및 "회원"에 대한 통지)
회사는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 전자우편, 유선, 서신우편 등의 방법으로 "회원"에게 제공할 수 있습니다.

제10조(회사의 의무)
회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 "서비스"를 제공할 의무가 있습니다.
회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않습니다.
"서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.
회사는 "회원"의 개인정보(신용 정보 보호) 보호를 위해 보안 시스템을 갖추며, 본인 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신 기본법 등 법률의 규정에 의해 국가 기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보 통신 윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그리하지 않습니다.

제11조("회원"의 의무)
"회원"은 "서비스"를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.
1) 신청 또는 변경 시 허위내용의 등록
2) 타인의 정보도용
3) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
4) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
5) "서비스" 이용시 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 언행
6) 회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
7) 기타 불법적이거나 부당한 행위
"회원"은 내용별로 회사가 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다
"회원"은 회사의 "서비스"를 이용하여 얻은 정보를 가공, 판매하는 행위 등 "서비스"에 기재된 자료를 상업적으로 사용하거나 "서비스"를 이용해 영업활동을 할 수 없습니다. 단, 비상업적 목적일 경우에는 회사의 사전 동의를 얻어 사용할 수 있습니다.

제12조("서비스" 제공 및 중단)
"서비스"의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.
회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
컴퓨터 등 시스템 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "관리자"가 임의로 날짜와 시간을 정해 "서비스"의 제공을 일시 중단할 수 있습니다. 이 경우 "회원"에게 사전 공지를 원칙으로 하며, 부득이한 사유가 있을 경우 사후에 통지할 수 있습니다.
회사는 제공하고 있는 "서비스"의 일부 또는 전부를 변경할 수 있습니다. "서비스" 이용 방법과 내용, 이용 시간 등에 변경이 있는 경우에는 변경 사유와 변경 내용, 제공일자 등을 변경 전 7일 이상 "서비스" 초기 화면에 게시하여야 합니다.

제13조(수업 진행, 일정 변경 및 취소)
수업 일정 변경 및 취소는 튜터가 배정되어 있는 수업의 경우, 수업 시작 24시간 이전까지는 (예: 수업 시작 28시간 전) 언제든 가능합니다. 튜터가 배정되어 있는 수업에 대해, 수업 시작 시간 24시간 이내 수업을 취소하는 경우 (예: 수업 시작 16시간 전) 수업권이 차감됩니다. 단, 튜터가 미배정되어 있는 수업의 경우, 언제든 수업 취소/변경이 가능합니다.

또한, 수업권 유효기간이 만료되는 경우에도 수업권이 자동 소멸됩니다. 삭제 또는 소멸된 수업권에 대해서는 환불을 제공하지 않습니다.
고객의 잘못으로 인한 수업권 삭제 관련하여, 이의가 존재하는 경우  "회원"은 회사 측에 통보해야 하며,  해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
"회원"의 수업 지각시 지각분을 보충해드리지 않습니다. 수업은 정시 시작, 종료를 원칙으로 합니다. 단, "튜터" 지각시 수업 시작 시점부터 40분 간 수업을 진행해 드립니다.

제 14조(포인트 규정)
링글이 수강생 및 가입자 분들께 지급한 포인트는 링글 서비스 가격을 할인받는 적립금으로 사용될 수 있습니다. 포인트는, 피추천인이 4회 이상 수업 결제 시 추천인에게 제공하는 추천 포인트, 링글 서비스에 하자 발생 시(예: 수업 24시간 이전 튜터 취소 시) 수강생에게 제공하는 보상 포인트, 돈을 주고 구매한 수업을 완강했을 시 제공되는 수업 포인트, 링글이 이벤트 목적으로 가입자 대상으로 제공하는 이벤트 포인트로 구분됩니다.
추천 포인트는, 피추천인이 링글이 정의하는 일정금액 이상 결제 시 추천인에게 제공하는 리워드 성격의 포인트로, 링글 수업 할인 목적 외, 추천 리워드 상품으로 교환 가능합니다.
수업 포인트는, 링글이 정의하는 미션을 달성한 수강생에게 제공하는 포인트로, 수업 포인트 적립 요건은 포인트 페이지에 명시되어 있습니다.
보상 포인트는, 링글 서비스의 하자 발생 시, 수강생에게 보상의 목적으로 제공하는 포인트로, 적립 요건은 포인트 페이지에 명시되어 있습니다.
이벤트 포인트는, 링글이 마케팅 및 프로모션 목적으로, 링글팀이 정한 가입자에게 제공되는 포인트 입니다.
추천 포인트 외 다른 포인트는 링글 수업 할인 목적으로만 이용 가능하며, 추천 리워드 상품으로의 교환은 불가능합니다.
모든 포인트의 유효기간은 생성일로부터 1년이며, 1년 뒤 포인트는 소멸됩니다.

제 15조(환불규정)

회사는 서울시간대 기준 2019년 1월 31일까지의 구매건에 대해서는, "회원"의 환불 요청이 있을시, 다음 조항에 의거해 환불을 처리합니다.
1) 수업료를 지불하고 미수강한 수업에 대해 전액 환급함
2) 이미 진행된 수업에 대해서는 환급하지 않음
3) 무료로 획득한 수업에 대해서는 환급하지 않음
4) 특별 패키지로 구성되어 할인가로 결제한 "회원"의 경우, 이미 수강한 수업은 할인가가 아닌 정상가로 계산하여 제한 후 잔액을 환급함
5) 유효기간이 연장된 수업권은 환불이 불가함
6) 수강증, 출석증이 발급된 구매건은 환불이 불가함

회사는 서울시간대 기준 2019년 2월 1일 구매건부터 아래 원칙에 의거하여 회원에게 환불을 제공합니다.

1) 무료로 제공된 수업 또는 진행 완료된 수업 또는 수업권이 차감된 수업 (예: 수업 시작 24시간 이내에 취소된 수업, 수강생이 노쇼한 수업 등)에 대해서는 환불이 불가능합니다.
2) 회원이 구매한 유료수업에 대한 환불은 아래의 기준에 해당하지 않은 경우 진행 가능합니다. 유료수업 중 환불 불가능한 수업권은 아래와 같습니다.
2-1) 회사는 유효기간이 절반 이하 남은 수업에 대해서는 환불을 제공하지 않습니다. (예: 유효기간 30일 수업의 경우, 유효기간이 15일 되는 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘링글 유효기간 연장 규정’에 의거하여 제공합니다.
2-2) 회사는 회원이 구입한 패키지의 수업이 절반 이하 되는 시점부터 환불이 불가능합니다 (예: 회원이 6회 패키지를 구입한 경우, 수업이 3회 남은 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘유효기간 연장 규정’에 의거하여 제공합니다.
2-3) 회사는 유효기간이 연장된 수업권(수업)은 환불이 불가능합니다.
2-4) 회사는 수강증/출석확인증이 발급된 수업에 대해서는 환불이 불가능합니다

3) 회사는 환불 가능한 수업권에 대해 회사의 환불 계산 공식에 의거하여 환불 가능 금액을 산정 후 환불을 제공합니다.
3-1) 회원이 아직 수업을 개시하지 않은 패키지에 대해서는, 위의 15조 2월 1일부터의 환불규정 2-4)에 해당하지 않는 한 전액 환불을 제공합니다 (예: 6회 패키지 구매 후, 한 개의 수업도 수강하지 않아서 유효기간이 계속 멈춰있는 패키지에 대한 요청 시, 수강증/출석확인증 발급이 안된 경우에는 전액 환불 제공)
3-2) 회원이 수강을 시작한 패키지에 대한 환불을 요청하는 경우 (예: 6회 패키지에 대해 2회 수업을 수강한 후 나머지 4회에 대해 환불 요청), 15조 환불규정의 ‘환불 불가능 수업’에 해당하지 않는 수업에 한해, 실결제 금액에서 이미 진행한 수업에 대한 ‘정가 수업료’를 차감한 금액 만큼 환불해 드립니다 (환불 가능 금액 = 결제 금액 - 정가 수업료 * 기 진행한 수업 수). 이미 진행한 수업에 대한 ‘정가 수업료’는 수업권 관리 페이지에서 확인 가능합니다.
3-3) 환불 금액 지급 방식은, 회원이 신용카드로 결제했을 시 결제 취소를 통해, 계좌 이체로 결제했을 시 송금의 방식으로 제공드립니다.

제16조(수업권 유효기간 차감, 연장 및 소멸한 수업권에 대한 복구 규정)

회사는 아래조항에 의거하여 유효기간 차감 및 연장을 진행합니다.
1) 패키지의 유효기간은 첫 수업을 완료한 시점부터 패키지 내 모든 수업권의 유효기간이 자동으로 차감되기 시작합니다. (예: 20회 패키지 이용 시, 첫 수업 완료 시점부터 나머지 19개 수업권의 유효기간이 자동 차감되기 시작함). 또는, 패키지 구매 후 5년이 되는 시점부터, 유효기간이 자동으로 차감되기 시작합니다.
2) 패키지 유효기간 연장 신청은 유효기간이 처음으로 절반 이하로 떨어지는 시점부터 가능합니다 (예: 6회 30일 패키지의 경우, 유효기간이 15일 이하가 되는 시점부터, 유효기간 연장이 가능함). 패키지의 유효기간 연장을 위한 금액은 ‘유효기간 연장 금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.
3) 유효기간이 소멸된 수업권은, 소멸이 된 후 3개월 이내만 부활이 가능합니다. 수업권 부활을 위한 금액은 ‘수업권 부활금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.

제17조(저작권의 귀속 및 이용제한)
회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
이용자는 회사를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송하거나 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

서울 시간대 기준 2019년 2월 1일부터 진행되는 수업 및 서비스 결과로 발생한 자료 및 데이터 저작권은 회사 및 회원 양측에 귀속됩니다. 회사는 수업 및 서비스 도중 발생한 결과물에 대해 non-disclosure agreement (NDA)를 이행하여, 데이터/자료가 고객 정보와 함께 그대로 외부에 노출되지 않도록 최선을 의무를 이행합니다. 단, 데이터/자료는 개인식별이 불가능한 형태로 저장되며, 회사는 해당 데이터/자료를 연구 및 제품개발 목적으로 이용할 수 있는 권한을 갖습니다. 회원의 동의가 있을 시, 또는 무료로 제공되는 기능에 대해서는 마케팅 및 홍보 목적으로 결과물을 이용 가능합니다.

제18조(계약 해지 및 "서비스" 이용 제한)
"회원"이 이용 계약을 해지하고자 하는 때에는 "회원" 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.
회사는 "회원"이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용 계약을 해지 하거나 또는 기간을 정하여 "서비스" 이용을 중지할 수 있습니다.
1) 타인의 "이메일(ID)" 및 "비밀번호"를 도용한 경우
2) "서비스" 운영을 고의로 방해한 경우
3) 가입한 이름이 실명이 아닌 경우
4) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
5) "회원"이 국익 또는 공익 저해 목적으로 "서비스" 이용을 계획 또는 실행하는 경우
6) 회사나 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
회사는 제2항의 규정에 의하여 이용 제한을 하고자 하는 경우에는 그 사유, 일시 및 기타사항을 정하여 이메일 또는 기타 방법에 의하여 해당 이용자 또는 대리인에게 통지 합니다. 다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니 합니다.
제2항의 규정에 의하여 이용 정지의 통지를 받은 이용자 또는 그 대리인은 그 이용 정지의 통지에 대하여 이의가 있을 때에는 이의 신청을 할 수 있습니다.
회사는 제3항의 규정에 의한 이의 신청에 대하여 그 확인을 위한 기간까지 이용 정지를 일시 연기 할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
회사는 이용정지 기간 중에 그 이용 정지 사유가 해소된 것이 확인된 경우에는 이용 정지 조치를 즉시 해제 합니다.

제19조(손해배상)
회사는 일체의 무료로 제공되는 "서비스"의 이용과 관련하여 "회원"에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.

제20조(면책조항)
회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
회사는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여 책임을 지지 않습니다.
회사는 "회원"이 "서비스"에 기재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.

제21조(관할 법원)
"서비스"의 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    's_terms.text',
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-purple-500" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-purple-500"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
  ],
}
export const s_password_reset = {
  reset_password: [
    `비밀번호 재설정`,
    `Reset Password`,
    's_password_reset.reset_password',
    `密码重置`,
    `重設密碼`,
    `パスワード再設定`,
    `Đặt lại mật khẩu`,
  ],
  enter_email_to_reset_your_password: [
    `이메일을 입력하시면, 비밀번호를 재설정 하실 수 있는 링크를 보내드립니다.`,
    `Enter your email address to receive a link to reset your password.`,
    's_password_reset.enter_email_to_reset_your_password',
    `输入电子邮箱, 将发送给您密码重置链接。`,
    `如輸入電子信箱，將為您發送重設密碼連結。`,
    `メールアドレスを入力すると、パスワードを再設定できるリンクを送信します。`,
    `Nhập địa chỉ email để nhận link đặt lại mật khẩu.`,
  ],
  email: [`이메일`, `Email`, 's_password_reset.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_your_email: [
    `이메일을 입력하세요`,
    `Please enter your email`,
    's_password_reset.enter_your_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập email`,
  ],
  submit: [`제출 하기`, `Submit`, 's_password_reset.submit', `提交`, `提交`, `送信する`, `Gửi`],
  did_you_remember_your_password: [
    <>비밀번호가 생각나셨나요?</>,
    `Did you remember your password?`,
    's_password_reset.did_you_remember_your_password',
    <>您找回密码了吗?</>,
    <>想起密碼了嗎？</>,
    <>パスワードを思い出しましたか？</>,
    `Bạn có nhớ mật khẩu của mình?`,
  ],
  sign_in: [`로그인`, `Log in`, 's_password_reset.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
}
export const s_complete = {
  title: [
    isMobile ? (
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>
    ) : (
      <>진짜 영어를 배울 수 있는 링글 수업을 시작해보세요.</>
    ),
    isMobile ? (
      <>
        Learn Real English
        <br />
        with Ringle
      </>
    ) : (
      <>Learn Real English with Ringle</>
    ),
    's_complete.title',
    isMobile ? (
      <>
        通过Ringle的课程
        <br />
        学习正宗的英语
      </>
    ) : (
      <>通过Ringle的课程学习正宗的英语。</>
    ),
    isMobile ? (
      <>
        請開始可以學習
        <br />
        真正英語的Ringle課程吧！
      </>
    ) : (
      <>請開始可以學習真正英語的Ringle課程吧！</>
    ),
    isMobile ? (
      <>
        リアルな英語を学ぶことができる
        <br />
        Ringleでレッスンを始めましょう。
      </>
    ) : (
      <>リアルな英語を学ぶことができるRingleでレッスンを始めましょう。</>
    ),
    isMobile ? (
      <>
        Học tiếng Anh thực tế
        <br />
        với Ringle
      </>
    ) : (
      <>Học tiếng Anh thực tế với Ringle</>
    ),
  ],
  welcome_to_ringle: [
    <>
      {isMobile ? (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      ) : (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      ) : (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      )}
    </>,
    's_complete.welcome_to_ringle',
    <>
      {isMobile ? (
        <>
          欢迎!
          <br />
          非常感谢您的注册!
        </>
      ) : (
        <>
          很高兴认识您!
          <br />
          非常感谢您的注册!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          歡迎您！
          <br />
          感謝您的註冊！
        </>
      ) : (
        <>
          歡迎您！
          <br />
          感謝您的註冊！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          ようこそ！
          <br />
          ご登録ありがとうございます。
        </>
      ) : (
        <>
          ようこそRingleへ！
          <br />
          ご登録していただき誠にありがとうございます。
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Chúc mừng bạn!
          <br />
          Tham gia cùng Ringle.
        </>
      ) : (
        <>
          Chúc mừng bạn!
          <br />
          YTham gia cùng Ringle.
        </>
      )}
    </>,
  ],
  trial_lesson: [
    <>
      {isMobile ? (
        <>체험 수업 무료로 신청하기</>
      ) : (
        <>
          체험 수업
          <br />
          무료로 신청하기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up for a Free Trial</>
      ) : (
        <>
          Sign up for a
          <br />
          Free Trial
        </>
      )}
    </>,
    's_complete.trial_lesson',
    <>
      {isMobile ? (
        <>请申请免费体验课程</>
      ) : (
        <>
          体验课程
          <br />
          免费申请
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>免費申請體驗課程</>
      ) : (
        <>
          免費申請
          <br />
          體驗課程
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>体験レッスンを無料で予約する</>
      ) : (
        <>
          体験レッスンを
          <br />
          無料で予約する
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Đăng kí để nhận buổi học thử miễn phí</>
      ) : (
        <>
          Đăng kí để nhận
          <br />
          Buổi học thử miễn phí
        </>
      )}
    </>,
  ],
  apply_for_20_min_lesson: [
    <>
      {isMobile ? (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로 경험해보세요!
        </>
      ) : (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로
          <br />
          경험해보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up now to browse our tutors & materials!</>
      ) : (
        <>
          Sign up now to browse
          <br />
          our tutors & materials!
        </>
      )}
    </>,
    's_complete.apply_for_20_min_lesson',
    <>
      {isMobile ? (
        <>
          请先
          <Div spanTag textPrimary>
            免费
          </Div>
          体验与众不同的教师及教材吧!
        </>
      ) : (
        <>
          请先
          <Div spanTag textPrimary>
            免费
          </Div>
          体验与众不同的
          <br />
          教师及教材吧!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          請先
          <Div spanTag textPrimary>
            免費
          </Div>
          體驗與眾不同的任課教師及教材吧！
        </>
      ) : (
        <>
          請先
          <Div spanTag textPrimary>
            免費
          </Div>
          體驗與眾不同的
          <br />
          任課教師及教材吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          並外れたチューターと教材を先に{' '}
          <Div spanTag textPrimary>
            無料
          </Div>
          で体験してみましょう！
        </>
      ) : (
        <>
          並外れたチューターと教材を先に{' '}
          <Div spanTag textPrimary>
            無料
          </Div>
          で
          <br />
          体験してみましょう！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Đăng ký ngay để cùng khám phá những gia sư & kho tài liệu của chúng tôi!</>
      ) : (
        <>
          Đăng ký ngay để khám phá
          <br />
          những gia sư & kho tài liệu của chúng tôi!
        </>
      )}
    </>,
  ],
  applyTrial: [
    <>바로 신청하기</>,
    <>Sign up now</>,
    's_complete.applyTrial',
    <>立即申请</>,
    <>立即申請</>,
    <>今すぐ予約する</>,
    <>Đăng kí ngay</>,
  ],
  dailyBrief: [
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    's_complete.dailyBrief',
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
  ],
  dailyBriefSub: [
    <>
      링글 회원이라면 누구나 무료로
      <br />볼 수 있는{' '}
      <Div spanTag textPrimary>
        하루 5분
      </Div>{' '}
      상식과 영어
    </>,
    <>5-minute dose of insight & English</>,
    's_complete.dailyBriefSub',
    <>
      只要是Ringle会员, 都可每天
      <br />
      免费浏览
      <Div spanTag textPrimary>
        5分钟的
      </Div>
      常识及英语
    </>,
    <>
      只要是Ringle會員，都可以
      <br />
      <Div spanTag textPrimary>
        一天5分鐘
      </Div>
      免費閱讀常識與英文
    </>,
    <>
      Ringle会員なら誰でも無料で
      <br />
      見ることができる{' '}
      <Div spanTag textPrimary>
        1日5分の
      </Div>{' '}
      知見と英語
    </>,
    <>5 phút tìm hiểu Insight & tiếng Anh</>,
  ],
  goDailyBrief: [
    <>Daily Brief 보러가기</>,
    <>Learn More</>,
    's_complete.goDailyBrief',
    <>前往浏览Daily Brief</>,
    <>前往查看Daily Brief</>,
    <>Daily Briefに移動</>,
    <>Tìm hiểu thêm</>,
  ],
  purchase_now: (amount) => [
    <>
      {isMobile ? (
        <>최대 3회 수업권 추가 + {amount}만원 할인</>
      ) : (
        <>
          최대 3회 수업권 +
          <br />
          최대 {amount}만원 할인
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Bonus Lesson + Up to {amount} OFF</>
      ) : (
        <>
          Bonus Lesson +
          <br />
          Up to {amount} OFF
        </>
      )}
    </>,
    's_complete.purchase_now',
    <>
      {isMobile ? (
        <>获取额外课程券 + {amount}优惠</>
      ) : (
        <>
          获取额外课程券 +
          <br />
          {amount}优惠
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>領取額外課程券 + {amount}優惠</>
      ) : (
        <>
          領取額外課程券 +
          <br />
          {amount}優惠
        </>
      )}
    </>,
    <>{isMobile ? <>追加レッスンチケット + {amount}追加割引</> : <>追加レッスンチケット + {amount}追加割引</>}</>,
    <>
      {isMobile ? (
        <>Buổi học tặng thêm + giảm giá {amount}</>
      ) : (
        <>
          Buổi học tặng thêm +
          <br />
          giảm giá {amount}
        </>
      )}
    </>,
  ],
  full_refund_guaranteed: [
    <>
      {isMobile ? (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      ) : (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      ) : (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      )}
    </>,
    's_complete.full_refund_guaranteed',
    <>
      {isMobile ? (
        <>
          首次购买优惠!
          <br />
          <Div textInfo>首次课程不满意时, 承诺100%退款</Div>
        </>
      ) : (
        <>
          首次购买优惠!
          <br />
          <Div textInfo>首次课程不满意时, 承诺100%退款</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          首次購買折扣優惠!
          <br />
          <Div textInfo>首次課程不滿意時，可全額退款</Div>
        </>
      ) : (
        <>
          首次購買折扣優惠!
          <br />
          <Div textInfo>首次課程不滿意時，可全額退款</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          初回購入割引特典!
          <br />
          初回レッスンにご満足いただ
          <br />
          <Div textInfo>けない場合、全額返金 </Div>
        </>
      ) : (
        <>
          初回購入割引特典!
          <br />
          <Div textInfo>
            初回レッスンで満足いただけ
            <br />
            なければ全額返金保証
          </Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Ưu đãi cho lần thanh toán đầu tiên!
          <br />
          <Div textInfo>Hoàn tiền 100%</Div>
        </>
      ) : (
        <>
          Ưu đãi cho lần thanh toán đầu tiên!
          <br />
          <Div textInfo>Hoàn tiền 100%</Div>
        </>
      )}
    </>,
  ],
  view_course_material: [
    `콘텐츠 교재 읽어보기`,
    `View lesson materials`,
    's_complete.view_course_material',
    `阅读教材内容`,
    `閱讀內容教材`,
    `価格を見る`,
    `Xem tài liệu học`,
  ],
  purchaseNow: [
    `지금 구매하기`,
    `Purchase Now`,
    's_complete.purchaseNow',
    `立即购买`,
    `立即購買`,
    `今すぐ購入する`,
    `Thanh toán ngay`,
  ],
  if_ringle_is_first_time: [
    <>링글이 처음이라면?</>,
    <>New to Ringle?</>,
    's_complete.if_ringle_is_first_time',
    <>您是初次使用Ringle吗?</>,
    <>如果您是第一次使用Ringle？</>,
    <>Ringleが初めてなら？</>,
    <>Lần đầu bạn học Ringle?</>,
  ],
  tutor: [
    <>
      {isMobile ? (
        <>링글 사용법 바로가기</>
      ) : (
        <>
          튜터 프로필
          <br />
          보러가기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>How it works: Ringle Lessons</>
      ) : (
        <>
          Browse tutors
          <br />
        </>
      )}
    </>,
    's_complete.tutor',
    <>
      {isMobile ? (
        <>查看Ringle使用方法</>
      ) : (
        <>
          前往查看
          <br />
          教师简介
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>立即前往Ringle使用方法</>
      ) : (
        <>
          前往查看
          <br />
          任課教師簡介
        </>
      )}
    </>,
    <>{isMobile ? <>Ringleの使用方法を見る</> : <>チュータープロフィール を見る</>}</>,
    <>
      {isMobile ? (
        <>Cách thức hoạt động: Buổi học của Ringle</>
      ) : (
        <>
          Xem gia sư
          <br />
        </>
      )}
    </>,
  ],
  tutorSub: [
    <>
      {isMobile ? (
        <>언제 어디서나, 원하는 수업을 자유롭게</>
      ) : (
        <>
          1000+명 이상의 검증된 영미권 명문대
          <br />
          <Div spanTag textPrimary>
            원어민 튜터
          </Div>
          를 만나보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Learn around your schedule</>
      ) : (
        <>
          Meet 1000+ Tutors!
          <br />{' '}
        </>
      )}
    </>,
    's_complete.tutorSub',
    <>
      {isMobile ? (
        <>自由进行希望的课程</>
      ) : (
        <>
          1000名以上, 出身享誉全球的英美名校
          <br />
          <Div spanTag textPrimary>
            与母语教师
          </Div>
          一起学习吧!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>隨時隨地自由進行想要的課程</>
      ) : (
        <>
          與1000名以上眾所公認的英美名校
          <br />
          <Div spanTag textPrimary>
            母語任課教師
          </Div>
          相見歡吧！
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>いつでもどこでも、好きなレッスンを自由に</>
      ) : (
        <>
          1000名以上の検証された
          <br />
          英米圏の
          <Div spanTag textPrimary>
            ネイティブチューター
          </Div>
          <br />
          と共にレッスンをしましょう。
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Tìm hiểu thời khoá biểu của bạn</>
      ) : (
        <>
          Gặp gỡ 1000+ gia sư!
          <br />{' '}
        </>
      )}
    </>,
  ],
  goTutor: [
    <>{isMobile ? <>간단한 사용법 보러가기</> : <>맞춤 튜터 찾아보기</>}</>,
    <>{isMobile ? <>How it works: Ringle Lessons</> : <>Browse tutors</>}</>,
    's_complete.goTutor',
    <>{isMobile ? <>查看便捷使用方法</> : <>查找适合的教师</>}</>,
    <>{isMobile ? <>前往查看簡單的使用方法</> : <>尋找合適的任課教師</>}</>,
    <>{isMobile ? <>簡単な使用方法を見る</> : <>チューターを探す</>}</>,
    <>{isMobile ? <>Cách thức hoạt động: Buổi học của Ringle</> : <>Xem gia sư</>}</>,
  ],
  check_out_material: [
    <>교재 둘러보기</>,
    <>Check out Materials</>,
    's_complete.check_out_material',
    <>查看教材</>,
    <>查看教材</>,
    <>教材を見る</>,
    <>Xem tài liệu</>,
  ],
  check_out_material_content: [
    <>
      Top MBA 출신과 명문대 튜터들이 직접 집필하는 <span className="text-purple-500">프로페셔널한 교재</span>를
      읽어보세요!
    </>,
    <>Check out curriculum written by top MBA grads and elite tutors! </>,
    's_complete.check_out_material_content',
    <>
      名牌大学教师亲自编写
      <br />
      赶快阅读Ringle的教材吧！
    </>,
    <>
      名牌大學導師親自執筆
      <br />
      趕快閲讀Ringle的教材吧！
    </>,
    <>
      Top MBA出身と名門大学の
      <br />
      チューターが直に執筆する
      <br />
      教材を読んでみてください！
    </>,
    <>
      Hãy đọc sách giáo khoa chuyên nghiệp do các giáo viên xuất thân từ Top MBA và các trường đại học danh tiếng trực
      tiếp viết!
    </>,
  ],
  check_out_material_content_btn: [
    <>교재 보러가기</>,
    <>View lesson materials</>,
    's_complete.check_out_material_content_btn',
    <>阅读教材内容</>,
    <>閱讀內容教材</>,
    <>コンテンツ教材を読んでみる</>,
    <>Xem tài liệu học</>,
  ],
  free_trial_lesson: [
    <>
      20분 무료체험
      <br />
      신청하기
    </>,
    <>
      Free 20-Min <br />
      Lesson
    </>,
    's_complete.free_trial_lesson',
    <>请申请免费体验课程</>,
    <>免費申請體驗課程</>,
    <>体験レッスンを無料で予約する</>,
    <>Đăng kí để nhận buổi học thử miễn phí</>,
  ],
  free_trial_lesson_content: [
    <>
      수업권 구매 전 링글을
      <br />
      먼저 경험할 수 있는 기회!
      <br />
      (~5월 31일까지)
    </>,
    <>Want to try before you buy? Register now! (through May 31)</>,
    's_complete.free_trial_lesson_content',
    <>
      请先 免费 体验与众不同的
      <br />
      教师及教材吧!
    </>,
    <>
      {' '}
      請先 免費 體驗與眾不同的
      <br />
      任課教師及教材吧！
    </>,
    <>
      並外れたチューターと教材を先に 無料 で<br />
      体験してみましょう！
    </>,
    <>Đăng ký ngay để khám phá những gia sư & kho tài liệu của chúng tôi!</>,
  ],
  free_trial_lesson_content_btn: [
    <>무료체험 신청하기</>,
    <>Register for free trial</>,
    's_complete.free_trial_lesson_content_btn',
    <>立即申请</>,
    <>立即申請</>,
    <>今すぐ予約する</>,
    <>Đăng kí ngay</>,
  ],
}
export const s_signin = {
  kakao_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.kakao_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  facebook_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.facebook_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  instagram_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signin.instagram_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  sign_in: [`로그인`, `Log in`, 's_signin.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  sign_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.sign_in_with_facebook',
    `使用Facebook账号登录`,
    `使用Facebook帳戶登入`,
    `Facebookでログイン`,
    `Đăng nhập bằng Facebook`,
  ],
  enter_your_email: [
    `이메일 주소(ID)를 입력하세요`,
    `Enter your email address (ID)`,
    's_signin.enter_your_email',
    `请输入电子邮箱(ID)`,
    `請輸入電子信箱（帳號）`,
    `メールアドレス(ID)を入力してください。`,
    `Nhập địa chỉ email của bạn (ID)`,
  ],
  enter_your_password: [
    `비밀번호를 입력하세요`,
    `Enter your password`,
    's_signin.enter_your_password',
    `请输入密码`,
    `請輸入密碼`,
    `パスワードを入力してください。`,
    `Nhập mật khẩu`,
  ],
  forgot_password: [
    `비밀번호를 잊으셨나요?`,
    `Forgot password?`,
    's_signin.forgot_password',
    `您忘记密码了吗?`,
    `忘記密碼？`,
    `パスワードを忘れた方はこちら`,
    `Quên mật khẩu?`,
  ],
  no_ringle_account: [
    `아직 링글 계정이 없으신가요?`,
    `Don't have a Ringle account?`,
    's_signin.no_ringle_account',
    `您还没有Ringle账号吗?`,
    `您還沒有Ringle帳戶嗎？`,
    `まだRingleアカウントがありませんか？`,
    `Bạn không có tài khoản Ringle?`,
  ],
  sign_up: [`회원가입`, `Sign Up`, 's_signin.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  sign_in_others: [
    <>소셜 계정으로 로그인</>,
    `Log in with`,
    's_signin.sign_in_others',
    <>使用社交账号登录</>,
    <>使用社群媒體帳戶登入</>,
    <>ソーシャルアカウントでログイン</>,
    `Đăng nhập bằng`,
  ],
  sns_inapp: [
    <>
      외부 채널을 통한 진입 시 간편 로그인이 제한됩니다. <br />
      링글앱으로 로그인 해주세요!
    </>,
    `You will not be able to sign in if you have accessed this page from a different app.
Please log in through the Ringle App!`,
    's_signin.sns_inapp',
    <>
      通过外部频道进入时，将限制简单登录。
      <br />
      请用Ringle APP登录!{' '}
    </>,
    <>
      透過外部頻道進入時，將限制簡易登入。
      <br />
      請利用Ringle APP登入！
    </>,
    <>
      別のアプリ経由でアクセスした場合、ログインが制限されます。
      <br />
      Ringleアプリでログインしてください！
    </>,
    <>
      Bạn không thể đăng nhập nếu đã truy cập trang này bằng ứng dụng khác.
      <br />
      Vui lòng đăng nhập bằng ứng dụng Ringle!
    </>,
  ],
  install_app: [
    `링글 앱 설치하기`,
    `Download App`,
    's_signin.install_app',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  log_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.log_in_with_facebook',
    `使用Facebook账号登录`,
    `使用Facebook帳戶登入`,
    `Facebookでログイン`,
    `Đăng nhập bằng Facebook`,
  ],
  log_in: [`로그인`, `Log in`, 's_signin.log_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  log_in_others: [
    <>소셜 계정으로 로그인</>,
    `Log in with`,
    's_signin.log_in_others',
    <>使用社交账号登录</>,
    <>使用社群媒體帳戶登入</>,
    <>ソーシャルアカウントでログイン</>,
    `Đăng nhập bằng`,
  ],
  title: [
    `Log in to Ringle`,
    `Log in to Ringle`,
    's_signin.title',
    `Log in to Ringle`,
    `Log in to Ringle`,
    `Log in to Ringle`,
    `Log in to Ringle`,
  ],
  inapp_alert: [
    `Gmail로 로그인하려면 PC 또는 링글앱을 사용해 주세요.`,
    `For Gmail social login, please use a PC or the Ringle app.`,
    's_signin.inapp_alert',
    `如果想用Gmail登入请使用PC或是Ringle APP`,
    `如果想用Gmail登入請使用PC或是Ringle APP`,
    `GmailログインはPCまたはRingleアプリをご利用ください。`,
    `Vui lòng đăng nhập vào Gmail bằng PC hoặc ứng dụng Ringle.`,
  ],
  enter_valid_email: [
    `유효한 이메일을 입력해 주세요`,
    `Please enter a valid email address`,
    's_signin.enter_valid_email',
    `请输入有效的电子邮箱地址。`,
    `請輸入有效的電子信箱。`,
    `有効なメールアドレスを入力してください。`,
    `Vui lòng nhập địa chỉ email hợp lệ`,
  ],
  password_length: [
    `비밀번호를 6자 이상 입력해주세요`,
    `Password has to be at least 6 characters`,
    's_signin.password_length',
    `请输入6位字符以上的密码`,
    `請輸入6字以上的密碼`,
    `パスワードを6文字以上入力してください。`,
    `Mật khẩu cần ít nhất 6 ký tự`,
  ],
  password_space: [
    `띄어쓰기 없이 입력해 주세요.`,
    `Please enter without spaces.`,
    's_signin.password_space',
    `输入时中间不能有空格`,
    `輸入時中間不能有空格`,
    `スペース（空白）なしで入力してください。`,
    `vui lòng nhập không có dấu cách`,
  ],
}
export const s_signup = {
  sign_up_with_sns: [
    <>소셜 계정으로 회원가입</>,
    `Sign up with`,
    's_signup.sign_up_with_sns',
    <>使用社交账号注册会员</>,
    <>使用社群媒體註冊會員</>,
    <>ソーシャルアカウントで会員登録</>,
    `Đăng ký với`,
  ],
  sign_up_with_email: [
    <>이메일로 가입</>,
    `Sign up with email`,
    's_signup.sign_up_with_email',
    <>使用电子邮箱注册会员</>,
    <>使用電子信箱註冊會員</>,
    <>メールアドレスで登録</>,
    `Đăng ký bằng email`,
  ],
  select_all: [
    <>모두 선택</>,
    `Select all`,
    's_signup.select_all',
    <>全部选择</>,
    <>全部選擇</>,
    <>すべて選択</>,
    `Chọn tất cả`,
  ],
  kakao_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.kakao_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  facebook_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.facebook_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  instagram_inapp: [
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    's_signup.instagram_inapp',
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
    `TO_BE_DELETED`,
  ],
  sign_up: [`회원가입`, `Sign Up`, 's_signup.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  email: [`이메일`, `Email`, 's_signup.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  enter_email: [
    `이메일을 입력하세요`,
    `Enter your email`,
    's_signup.enter_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Nhập email`,
  ],
  password: [`비밀번호`, `Password`, 's_signup.password', `密码`, `密碼`, `パスワード`, `Mật khẩu`],
  enter_password: [
    `비밀번호를 입력해주세요`,
    `Enter your password`,
    's_signup.enter_password',
    `请输入密码`,
    `請輸入密碼`,
    `パスワードを入力してください。`,
    `Nhập mật khẩu`,
  ],
  password_length: [
    `비밀번호를 6자 이상 입력해주세요`,
    `Password has to be at least 6 characters`,
    's_signup.password_length',
    `请输入6位字符以上的密码`,
    `請輸入6字以上的密碼`,
    `パスワードを6文字以上入力してください。`,
    `Mật khẩu cần ít nhất 6 ký tự`,
  ],
  confirm_password: [
    `비밀번호 확인`,
    `Confirm Password`,
    's_signup.confirm_password',
    `密码确认`,
    `確認密碼`,
    `パスワード確認`,
    `Xác nhận mật khẩu`,
  ],
  re_enter_your_password: [
    `비밀번호를 한번 더 입력해주세요`,
    `Re-enter your password`,
    's_signup.re_enter_your_password',
    `请再次输入密码`,
    `請再次輸入密碼`,
    `パスワードを再度入力してください。`,
    `Nhập lại mật khẩu của bạn`,
  ],
  enter_your_name: [
    `이름을 입력해주세요`,
    `Enter your name`,
    's_signup.enter_your_name',
    `请输入姓名`,
    `請輸入姓名`,
    `氏名を入力してください。`,
    `Nhập tên của bạn`,
  ],
  enter_additional_info: [
    `기업맞춤 / 친구 코드 입력 [선택]`,
    `Enter referral code`,
    's_signup.enter_additional_info',
    `输入企业特定/朋友代码 [选择]`,
    `輸入企業專用／朋友代碼 [選填]`,
    `企業専用 / 友達コード入力 [任意]`,
    `Nhập mã giới thiệu`,
  ],
  phone_number: [
    `전화 번호`,
    `Phone number`,
    's_signup.phone_number',
    `电话号码`,
    `電話號碼`,
    `電話番号`,
    `Số điện thoại`,
  ],
  sign_in: [`로그인`, `Log In`, 's_signup.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  already_have_account: [
    `이미 계정이 있으신가요?`,
    `Already have a Ringle account?`,
    's_signup.already_have_account',
    `已经注册有账户?`,
    `您已經有帳戶嗎？`,
    `既にアカウントをお持ちですか？`,
    `Bạn đã có tài khoản Ringle?`,
  ],
  i_want_to_receive_promotions: [
    `이벤트 및 혜택 소식을 수신하고 싶습니다`,
    `I want to receive marketing communications on events and offers.`,
    's_signup.i_want_to_receive_promotions',
    `希望接收营销及宣传促销信息`,
    `我想接收行銷與宣傳活動消息`,
    `マーケティング及びプロモーションのお知らせを受け取りたいです。`,
    `Tôi muốn nhận ưu đãi đặc biệt và những tin tức mới nhất từ Ringle`,
  ],
  optional: [`선택`, `Optional`, 's_signup.optional', `选择`, `可選`, `任意`, `Không bắt buộc`],
  required: [`필수`, `Required`, 's_signup.required', `必需`, `必選`, `必須`, `Bắt buộc`],
  i_older_14: [
    `만 14세 이상입니다`,
    `I am 14 years old or older.`,
    's_signup.i_older_14',
    `满14周岁以上`,
    `已滿14歲`,
    `満14歳以上ですか？`,
    `Tôi đã trên 14 tuổi.`,
  ],
  i_agree_to_privacy: [
    `개인정보 취급 방침에 동의합니다`,
    `I agree to the privacy policy`,
    's_signup.i_agree_to_privacy',
    `同意个人信息处理方针`,
    `同意個人資料處理方針`,
    `個人情報保護方針に同意します。`,
    `Tôi đồng ý với chính sách bảo mật`,
  ],
  i_agree_to_terms: [
    `이용약관에 동의합니다`,
    `I agree to the terms of service`,
    's_signup.i_agree_to_terms',
    `同意使用条款`,
    `同意使用條款`,
    `利用規約に同意します。`,
    `Tôi đồng ý với điều khoản dịch vụ`,
  ],
  company_code: [
    `기업 맞춤 코드(선택)`,
    `B2B Partner code (optional)`,
    's_signup.company_code',
    `企业特定代码(选择)`,
    `企業專用代碼（選填）`,
    `企業専用コード(任意)`,
    `Mã code đối tác B2B (không bắt buộc)`,
  ],
  verify: [`인증`, `Verify`, 's_signup.verify', `验证`, `驗證`, `認証`, `Xác minh`],
  refferal_code: [
    `친구 코드(선택)`,
    `Referral Code (optional)`,
    's_signup.refferal_code',
    `朋友代码(选择)`,
    `朋友代碼（選填）`,
    `招待コード(任意)`,
    `Mã giới thiệu (không bắt buộc)`,
  ],
  verified: [`인증 완료`, `Verified`, 's_signup.verified', `验证完成`, `驗證成功`, `認証完了`, `Đã xác minh`],
  sign_up_with_facebook: [
    `페이스북으로 가입하기`,
    `Sign up with Facebook`,
    's_signup.sign_up_with_facebook',
    `使用Facebook注册`,
    `使用Facebook註冊`,
    `Facebookで登録する`,
    `Đăng ký bằng Facebook`,
  ],
  privacy_consent: [
    `개인정보 취급 방침에 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy.`,
    's_signup.privacy_consent',
    `请同意个人信息处理方针`,
    `請同意個人資料處理方針`,
    `個人情報保護方針に同意してください。`,
    `Để hoàn thành đăng ký, bạn phải đồng ý Chính sách bảo mật của Ringle.`,
  ],
  privacy_terms_consent: [
    `개인정보 취급 방침과 이용 약관에 모두 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy and terms of service.`,
    's_signup.privacy_terms_consent',
    `请同意个人信息处理方针及使用条款`,
    `請同意個人資料處理方針及使用條款`,
    `個人情報保護方針と利用規約に同意してください。`,
    `Để hoàn thành đăng ký, bạn phải đồng ý với Chính sách bảo mật và Điều khoản dịch vụ của Ringle.`,
  ],
  please_enter_email: [
    `이메일을 입력해주세요`,
    `Please enter e-mail.`,
    's_signup.please_enter_email',
    `请输入电子邮箱`,
    `請輸入電子信箱`,
    `メールアドレスを入力してください。`,
    `Vui lòng nhập e-mail.`,
  ],
  password_check: [
    `비밀번호 일치 여부를 확인해주세요`,
    `Please confirm your password`,
    's_signup.password_check',
    `请确认密码输入是否一致`,
    `請確認密碼是否一致`,
    `パスワードを確認してください。`,
    `Vui lòng xác nhận mật khẩu của bạn`,
  ],
  please_enter_name: [
    `이름을 입력해주세요`,
    `Please enter your name`,
    's_signup.please_enter_name',
    `请输入姓名`,
    `請輸入姓名`,
    `氏名を入力してください。`,
    `Vui lòng nhập tên của bạn`,
  ],
  please_enter_name_more: [
    `이름을 2자 이상 입력해주세요`,
    `Please enter at least 2 characters in the name`,
    's_signup.please_enter_name_more',
    `请输入不少于2个字符的姓名`,
    `請輸入2字以上的姓名`,
    `氏名は2文字以上入力してください。`,
    `Vui lòng nhập ít nhất 2 ký tự trong tên của bạn`,
  ],
  additional_info: [
    `필수정보 입력`,
    `Required Information`,
    's_signup.additional_info',
    `输入必需信息`,
    `輸入必填資料`,
    `必須情報入力`,
    `Thông tin bắt buộc`,
  ],
  already_signed_up: [
    `이미 가입된 계정입니다.`,
    `This account already exists.`,
    's_signup.already_signed_up',
    `已经注册的账户`,
    `已註冊的帳戶。`,
    `すでに登録されているアカウントです。`,
    `Tài khoản đã tạo.`,
  ],
  basic_info: [
    `기본 정보`,
    `Basic Info`,
    's_signup.basic_info',
    `基本信息`,
    `基本資料`,
    `基本情報`,
    `Thông tin cơ bản`,
  ],
  veri_number: [
    `인증번호`,
    `Verification code`,
    's_signup.veri_number',
    `验证码`,
    `驗證碼`,
    `認証コード`,
    `Mã xác minh`,
  ],
  verified_success: [`인증됨`, `Verified`, 's_signup.verified_success', `已验证`, `已驗證`, `認証済み`, `Đã xác minh`],
  plz_veri_phone: [
    `휴대폰을 인증해주세요`,
    `Please verify your phone number`,
    's_signup.plz_veri_phone',
    `请进行手机验证`,
    `請驗證手機`,
    `電話番号を認証してください。`,
    `Vui lòng xác minh số điện thoại của bạn`,
  ],
  select_all_free_lesson: [
    `전체 동의 시 20분 무료체험 수업 제공`,
    `Select all for a free 20-min lesson`,
    's_signup.select_all_free_lesson',
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
    `Select all for a free 20-min lesson`,
  ],
  trial_modal: {
    title: [
      `20분 무료체험 혜택은 전체 마케팅 수신에 동의하신 분들께만 드리고 있어요.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      's_signup.trial_modal.title',
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
    ],
    content: [
      `전체 마케팅 수신에 동의하고 혜택을 받아보세요!`,
      `Opt in and win the offer!`,
      's_signup.trial_modal.content',
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
      `Opt in and win the offer!`,
    ],
    btn: [`전체 동의하고 혜택받기`, `Opt in`, 's_signup.trial_modal.btn', `Opt in`, `Opt in`, `Opt in`, `Opt in`],
  },
  trial_modal_after_agree: {
    title: [
      <>
        전체 마케팅 수신동의로 변경되었습니다. <br />
        지금 바로 20분 수업을 체험해보세요.
      </>,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      's_signup.trial_modal_after_agree.title',
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
    ],
    btn: [`무료체험 신청하기`, `Register for free trial`, 's_signup.trial_modal_after_agree.btn', ``, ``, ``, ``],
  },
  i_want_to_receive_promotions_list: {
    item1: [
      `이메일 수신 동의`,
      `Receive email list`,
      's_signup.i_want_to_receive_promotions_list.item1',
      `接收电子邮箱`,
      `接收電子信箱`,
      `Email`,
      `Email`,
    ],
    item2: [
      `SMS·알림톡 수신 동의`,
      `Receive text/Kakao messaging`,
      's_signup.i_want_to_receive_promotions_list.item2',
      `接收SMS·短信通知`,
      `接收簡訊·訊息通知`,
      `SMS・お知らせトーク`,
      `Tin nhắn/Kakao`,
    ],
    item3: [
      `앱 푸시 수신 동의`,
      `Receive app push notifications`,
      's_signup.i_want_to_receive_promotions_list.item3',
      `接收软件推送`,
      `接收APP推播通知`,
      `アプリのプッシュ`,
      `Thông báo điện thoại`,
    ],
  },
  sns_inapp: [
    <>
      외부 채널에서 접속한 경우 간편 회원가입이 제한됩니다. <br />
      링글 앱을 설치하고 링글의 다양한 콘텐츠를 경험하세요!{' '}
    </>,
    `You will not be able to sign up if you have accessed this page from a different app.
Download the Ringle App to see more contents!`,
    's_signup.sns_inapp',
    <>
      {' '}
      通过外部频道进入时，将限制简单会员注册。
      <br />
      安装Ringle APP， 请体验Ringle的服务于内容吧！{' '}
    </>,
    <>
      透過外部頻道進入時，將限制簡易會員註冊。
      <br />
      安裝Ringle APP，體驗Ringle的服務與內容吧！
    </>,
    <>
      別のアプリを使用した場合は、会員登録が制限されます。
      <br />
      RingleアプリをDLして、多様なコンテンツを経験してみてください！
    </>,
    <>
      Bạn không thể đăng ký nếu đã truy cập trang này bằng ứng dụng khác. <br />
      Tải ứng dụng Ringle để xem các nội dung khác!{' '}
    </>,
  ],
  install_app: [
    `링글 앱 설치하기`,
    `Download App`,
    's_signup.install_app',
    <>应用程序下载</>,
    <>下載APP</>,
    <>アプリダウンロード</>,
    <>Tải ứng dụng</>,
  ],
  log_in: [`로그인`, `로그인`, 's_signup.log_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
  enter_valid_email: [
    `유효한 이메일을 입력해 주세요`,
    `Please enter a valid email address`,
    's_signup.enter_valid_email',
    `请输入有效的电子邮箱地址。`,
    `請輸入有效的電子信箱。`,
    `有効なメールアドレスを入力してください。`,
    `Vui lòng nhập địa chỉ email hợp lệ`,
  ],
}
export const s_point = {
  your_points: [
    `보유 포인트`,
    `Your points`,
    's_point.your_points',
    `持有积分`,
    `持有點數`,
    `保有ポイント`,
    `Điểm của bạn`,
  ],
  policy_note: [
    `링글 포인트 유의사항`,
    `Terms and conditions for Points`,
    's_point.policy_note',
    `Ringle积分注意事项`,
    `Ringle點數注意事項`,
    `Ringleポイントの注意事項`,
    `Điều khoản`,
  ],
  point_history: [
    <>전체 포인트</>,
    <>Total Points</>,
    's_point.point_history',
    <>全部积分</>,
    <>全部點數</>,
    <>すべてのポイント</>,
    <>Tổng số điểm</>,
  ],
  invite: [`초대하기`, `Invite`, 's_point.invite', `邀请`, `邀請`, `招待する`, `Mời`],
  my_friend_point: [
    `친구 초대 포인트`,
    `Referral Points`,
    's_point.my_friend_point',
    `我的朋友邀请积分`,
    `我的邀請朋友點數`,
    `マイ友達招待ポイント`,
    `Điểm giới thiệu`,
  ],
  shipped_at_the_beginning_of_month: [
    `상품 전달은 매월 말일에 문자 메세지로 일괄 전달됩니다.`,
    `The gift card will be shipped at the end of every month`,
    's_point.shipped_at_the_beginning_of_month',
    `商品发送将于每月最后一天通过短信形式进行。`,
    `商品將於每月最後一天透過簡訊一併發送。`,
    `ギフトカードは毎月末日にメールで一括送信されます。`,
    `Thẻ quà tặng sẽ được chuyển vào cuối tháng`,
  ],
  use_point: [
    `포인트 사용하기`,
    `Use my points`,
    's_point.use_point',
    `使用积分`,
    `使用點數`,
    `ポイントを使用する`,
    `Sử dụng điểm của tôi`,
  ],
  total_point_earned: [
    `누적 포인트 내역`,
    `Total points earned`,
    's_point.total_point_earned',
    `积分累计明细`,
    `累積點數明細`,
    `合計獲得ポイント`,
    `Tổng số điểm bạn có`,
  ],
  total_use: [`총 사용`, `Total use`, 's_point.total_use', `总计使用`, `一共使用`, `合計使用ポイント`, `Tổng sử dụng`],
  total_points_used: [
    `사용 포인트 내역`,
    `Total points used`,
    's_point.total_points_used',
    `积分使用明细`,
    `使用點數明細`,
    `使用ポイント履歴`,
    `Tổng điểm đã sử dụng`,
  ],
  total_points: [`총 누적`, `Total points`, 's_point.total_points', `总累计`, `一共累積`, `総額`, `Tổng điểm`],
  invite_friend_for_secret_offer: [
    `시크릿 친구초대`,
    `Secret Offer Referrals`,
    's_point.invite_friend_for_secret_offer',
    `秘密邀请朋友`,
    `祕密邀請朋友`,
    `シークレット友達招待`,
    `Ưu đãi giới thiệu bí mật`,
  ],
  invite_friend: [
    `일반 친구초대`,
    `Regular Referrals`,
    's_point.invite_friend',
    `一般邀请朋友`,
    `一般邀請朋友`,
    `正規友達招待`,
    `Giới thiệu tiêu chuẩn`,
  ],
  all_invite_history: [
    `전체 현황`,
    `Total Status`,
    's_point.all_invite_history',
    `总体现状`,
    `整體現況`,
    `招待履歴`,
    `Tổng trạng thái`,
  ],
  exclude_event: [
    `이벤트 기간 제외 상시`,
    `Applicable any time other than event period`,
    's_point.exclude_event',
    `活动期间外`,
    `活動期間以外的期間`,
    `イベント期間を除く`,
    `Áp dụng bất kì khoảng thời gian nào ngoại trừ các sự kiện khác`,
  ],
  invite_history: [
    <>친구 초대 포인트</>,
    <>Referral Points</>,
    's_point.invite_history',
    <>朋友邀请积分</>,
    <>邀請朋友點數</>,
    <>友達招待ポイント</>,
    <>Điểm giới thiệu</>,
  ],
  received_point: (n) => [
    <>₩ {n} 받음</>,
    <>Received {n}</>,
    's_point.received_point',
    <>领取${n}</>,
    <>領取{n}</>,
    <>{n} 獲得済み</>,
    <>Nhận được {n}</>,
  ],
  ringle_coupon: [
    `1:1 회화 수업권(40분) 1회`,
    `1 Lesson Credit (40min)`,
    's_point.ringle_coupon',
    `1:1会话课程券(40分钟)1次`,
    `1:1會話課程券（40分鐘）1次`,
    `1:1レッスンのレッスンチケット(40分) 1回`,
    `Thẻ 1 Buổi (40 phút)`,
  ],
  no_use_point_history: [
    `사용 포인트 내역이 없습니다.`,
    `No point usage history.`,
    's_point.no_use_point_history',
    `不存在积分使用明细`,
    `無使用點數明細`,
    `使用ポイント履歴はありません。`,
    `Không có lịch sử sử dụng điểm.`,
  ],
  shinsegae_coupon: [`신세계 상품권`, `Shinsegae Gift Card`, 's_point.shinsegae_coupon', ``, ``, ``, ``],
  starbucks_coupon: [`스타벅스 상품권`, `Starbucks Gift Card`, 's_point.starbucks_coupon', ``, ``, ``, ``],
  has_been_chagned: [
    `선물로 변경이 완료 되었습니다.`,
    `You have successfully exchanged your points for the gift.`,
    's_point.has_been_chagned',
    `已变更为礼物。`,
    `已兌換禮物。`,
    `ギフトに変更が完了しました。`,
    `Bạn đã đổi điểm thành công để nhận quà.`,
  ],
  one_o_one_coversation_credit: [
    `추천 포인트 1:1 회화 수업권`,
    `Referral Point Credit`,
    's_point.one_o_one_coversation_credit',
    `推荐积分1:1会话课程券`,
    `推薦點數1:1會話課程券`,
    `おすすめポイント1:1レッスンのレッスンチケット`,
    `Điểm giới thiệu`,
  ],
  you_can_exchange_for_shinsegae_starbucks_coupon: [
    `신세계/스타벅스 쿠폰으로 교환 가능합니다.`,
    `You can exchange for Shinsegae or Starbucks Gift Card`,
    's_point.you_can_exchange_for_shinsegae_starbucks_coupon',
    ``,
    ``,
    ``,
    ``,
  ],
  coupons_are_shiped_to_mobile: [
    `상품권 및 쿠폰은, 매달 말일에 모바일로 일괄 배송해드립니다.`,
    `Gifts exchanged using Referral Points will be send at the end of every month.`,
    's_point.coupons_are_shiped_to_mobile',
    `商品券及优惠券将于每月最后一天统一发送至您的手机。`,
    `將於每月最後一天一併發送商品券或優惠券至手機。`,
    `商品券およびクーポンは、毎月末日にモバイルで一括送信いたします。`,
    `Quà tặng được đổi từ Điểm giới thiệu sẽ được gửi vào cuối mỗi tháng.`,
  ],
  exchange_for_gifts: [
    `선물로 변경하기`,
    `Exchange my points for gifts`,
    's_point.exchange_for_gifts',
    `变更为礼物`,
    `兌換禮物`,
    `ギフトに変更する`,
    `Đổi điểm lấy quà`,
  ],
  send_again: [`다시 보내기`, `Send again`, 's_point.send_again', `重新发送`, `重新發送`, `再送信`, `Gửi lại`],
  given_date: [`적립일`, `Date added`, 's_point.given_date', `积累日期`, `累積日`, `付与日`, `Ngày thêm vào`],
  expired_date: [`만료일`, `Expiration date`, 's_point.expired_date', `截止日期`, `到期日`, `満了日`, `Ngày hết hạn`],
  valid_until: [
    `유효기간`,
    `Validity period`,
    's_point.valid_until',
    `有效期限`,
    `有效期限`,
    `有効期間`,
    `Thời gian hiệu lực`,
  ],
  list_1: [
    `링글 포인트는 종류에 따라 사용처 및 사용조건, 유효기간 등이 다릅니다.`,
    `By type of Ringle Points, where to use, conditions of use, and validation date may vary.`,
    's_point.list_1',
    `Ringle积分可在1:1英语会话, 课程期限延长及付款时使用。`,
    `Ringle點數可用於1:1英語會話、延長聽課期間或付款。`,
    `Ringleポイントは1:1レッスン、受講期間延長、決済時に使用することができます。`,
    <>
      Bạn có thể sử dụng điểm để đặt lịch học hoặc gia hạn thời gian
      <br />
      kết thúc.
    </>,
  ],
  list_2: [
    `수업 포인트는 유료 수업 완료 7일 후 실결제 금액의 3%가 자동 적립되고, 수업권 구매 및 수강기간 연장 등에 사용할 수 있습니다.`,
    `Lesson points are automatically awarded at 3% of the actual payment amount in 7 days after the completion of the paid lesson. And it can be used to purchase lesson credits and extend the validation period.`,
    's_point.list_2',
    `积分的有效期限为获得之日起365天。(促销活动的积分于发行时将规定不同的有效期限。)`,
    `點數的有效期限自累積日起365天。（宣傳活動的點數於發送時各有不同的有效期限。）`,
    `ポイントの有効期間は付与日から365日です。(プロモーションポイントは発行されるたびに有効期間が異なります。)`,
    `Điểm sẽ hết hạn sau 365 ngày kể từ ngày phát hành. Điểm nhận được trong khoảng thời gian ưu đãi sẽ có ngày kết thúc khác nhau.`,
  ],
  list_3: [
    `친구 추천 포인트는 수업권 구매 및 수강기간 연장 이외에 상품권으로 교환할 수도 있습니다. 상품권은 입력된 휴대폰 번호로 매월 말 일괄 발송됩니다.`,
    `In addition to purchasing lesson credits and extending the course period, Referral points can be exchanged with gift certificates on the PC web. Gift certificates are sent at the end of each month to the mobile phone number registered.`,
    's_point.list_3',
    `课程积分将于付费购买正规课程后7日起累计。 (全部累计数额: 购买课程时实际付款金额的3%)`,
    `課程點數將於完成付費購買的正規課程7天後累積。（全部累積金額：購買課程券時實際付款金額的3%）`,
    `レッスンポイントは、有料で購入したレギュラーレッスン完了7日後に付与されます。(全体付与額:購入したレッスンチケットの実際の決済金額の3%)`,
    `Điểm thưởng tương đương 3% giá trị thanh toán của mỗi buổi học, và sẽ được nhận trong 7 ngày sau khi hoàn thành buổi học.`,
  ],
  list_4: [
    `수업권 구매를 위해 특별 지급된 경우, 최소 결제 금액이 있을 수 있습니다.`,
    `In the case of points awarded only for purchasing lesson credits, there may be a minimum payment amount.`,
    's_point.list_4',
    `使用免费课程券或复原非正常情况结束的课程时, 课程积分将不予累计。`,
    `使用免費課程券或復原異常結束的課程時，不累積課程點數。`,
    `無料レッスンチケットの使用または非正常的に終了したレッスンが復元される場合は、レッスンポイントは付与されません。`,
    `Buổi học miễn phí hoặc buổi học bị ngừng vì những trường hợp bất thường sẽ không được thưởng điểm.`,
  ],
  list_5: [
    `친구 추천 혜택으로 적립된 포인트는 상품권 등 선물로 교환할 수 있습니다.`,
    `Only Points earned through referrals can be exchanged for gift cards.`,
    's_point.list_5',
    `通过推荐朋友所获得的积分, 可进行商品券等礼品兑换。`,
    `透過推薦朋友累積的點數可兌換商品券等禮物。`,
    `友達紹介特典で付与されたポイントは、商品券などギフトに交換することができます。`,
    `Chỉ có Điểm nhận được qua việc giới thiệu bạn bè mới được đổi thành thẻ quà tặng.`,
  ],
  list_6: [
    `친구 추천 포인트로 변경한 선물은 매 월말 일괄 배송됩니다. (선물은 계정에 입력된 휴대폰 번호로 발송됩니다.)`,
    `Gifts exchanged using Referral Points will be sent at the end of the month.`,
    's_point.list_6',
    `使用推荐朋友所获得的积分兑换的礼物, 将于每月底统一发送。 (礼物将发送至账户验证的手机号码上。)`,
    `使用推薦朋友點數兌換的禮物將於每月底一併發送。（禮物將發送至輸入於帳戶的手機號碼。）`,
    `友達紹介ポイントで変更したギフトは、毎月末に一括送信されます。(ギフトはアカウントに入力された携帯電話番号に送信されます。)`,
    `Thẻ quà tặng quy đổi từ Điểm Giới thiệu sẽ được gửi vào cuối tháng.`,
  ],
  expiration_points_within_30_days: [
    <>30일 이내 만료 예정 포인트</>,
    <>The points will expire within 30 days</>,
    's_point.expiration_points_within_30_days',
    <>30日以内到期的积分</>,
    <>30天內將到期的點數</>,
    <>30日以内の満了予定ポイント</>,
    <>Điểm sẽ hết hạn trong 30 ngày tới</>,
  ],
  policy_faq: [
    <>링글 포인트 적립과 사용법이 궁금하신가요?</>,
    <>How to earn and use Ringle points</>,
    's_point.policy_faq',
    <>想了解Ringle积分的累计及使用方法吗?</>,
    <>想了解Ringle點數的累積及使用方法嗎？</>,
    <>Ringleポイント付与と使用方法が知りたいです</>,
    <>Cách kiếm và sử dụng điểm của Ringle</>,
  ],
  korean_name_number_required: [
    <>
      신세계/ 스타벅스 상품권의 경우, 수령자 본인의{' '}
      <span className="text-red-500">한국이름과 한국번호가 아닌 경우 수령에 제한</span>이 있을 수 있음을 안내드립니다.
      개인정보는 마이메뉴{'>'} 내 계정관리{'>'} 기본정보에서 확인/변경할 수 있습니다.
    </>,
    <>
      If the recipient is not registered as a Korean name and phone number, there might be restrictions in terms of
      receiving Shinsegae/Starbucks gift cards. Personal information can be viewed and varied in My Page{'>'} My Account
      {'>'} Basic info.
    </>,
    's_point.korean_name_number_required',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  how_to_use: [
    <>
      [링글 포인트 사용법]
      <br />
      1. 링글 수업권 구매시 포인트만큼 할인
      <br />
      2. 수업권 수강기간을 연장시 사용
      <br />
      <br />
      *포인트의 유효기간은 적립일로부터 365일입니다.
      <br />
      *추천한 친구가 결제 시, 제공되는 추천 포인트만 선물로 교환 가능합니다.
      <br />
      <br />
      [수강기간/포인트 확인법]
      <br />
      PC 웹: 오른쪽 상단 [아이콘] → [수업권/증명서류] OR [포인트]
      <br />
      모바일 앱: [나의 설정] → [나의 계정] → [포인트 관리]
    </>,
    <>
      You can use your Ringle Points towards your next purchase or use them to pay for extending your credits'
      expiration dates.
      <br />
      Points are valid for 365 days from the time of issuance (except for the first-purchase points,
      <br /> which expire 48 hours following the time of issuance).
      <br />
      <br />
      You can check under "Points" to check your points and their expiration dates.
    </>,
    's_point.how_to_use',
    <>
      [Ringle积分使用方法]
      <br />
      1. 购买Ringle课程券时, 将按积分获得优惠
      <br />
      2. 可在延长课程券课程期限时使用
      <br />
      <br />
      *积分有效期限为获得之日起365天。
      <br />
      *只能使用推荐的朋友付款时获得的积分兑换礼物。
      <br />
      <br />
      [课程期限/积分确认方法]
      <br />
      PC网页: 右侧上方[图标] → [课程券/证明材料] OR [积分]
      <br />
      应用程序: [我的设置] → [我的账户] → [积分管理]
    </>,
    <>
      [Ringle點數使用方法]
      <br />
      1. 購買Ringle課程券時，可獲得相當於點數的折扣
      <br />
      2. 用於延長課程券聽課期間
      <br />
      <br />
      *點數的有效期限自累積日起365天。
      <br />
      *僅可使用推薦的朋友付款時提供的推薦點數兌換禮物。
      <br />
      <br />
      [聽課期間／點數確認方法]
      <br />
      電腦版網站：右上方[圖示] → [課程券／證明] OR [點數]
      <br />
      手機APP：[我的設定] → [我的帳戶] → [點數管理]
    </>,
    <>
      [Ringleポイントの使用方法]
      <br />
      1. Ringleレッスンチケット購入時、ポイント分割引
      <br />
      2. レッスンチケットの受講期間を延長時に使用
      <br />
      <br />
      *ポイントの有効期間は付与日から365日です。
      <br />
      *紹介した友達が決済すると、提供される紹介ポイント分ギフトに交換可能です。
      <br />
      <br />
      [受講期間/ポイントの確認方法]
      <br />
      PCウェブ: 右上の[アイコン] → [レッスンチケット/証明書類] OR [ポイント]
      <br />
      モバイルアプリ: [マイページ] → [マイアカウント] → [ポイント管理]
    </>,
    <>
      Bạn có thể sử dụng điểm Ringle cho lần thanh toán tiếp theo hoặc gia hạn ngày kết thúc của thẻ học.
      <br />
      Điểm có giá trị trong 365 ngày kể từ ngày phát hành <br />
      <br />
      Bạn có thể kiểm tra dưới phần "Điểm" để xem điểm và ngày hết hạn.
    </>,
  ],
  usage: [
    `Q. 포인트는 어떻게 사용하나요? 보유한 포인트의 유효기간은 어디서 확인하나요?`,
    `Q. How can I use Ringle points? How long are they good for?`,
    's_point.usage',
    `Q. 积分如何使用? 持有中的积分有效期限如何确认?`,
    `Q. 如何使用點數？要在哪裡確認持有的點數有效期限呢？`,
    `Q. ポイントはどのように使いますか？保有しているポイントの有効期間はどこで確認できますか？`,
    `Q. Làm cách nào để sử dụng điểm Ringle? Và điểm có giá trị bao lâu?`,
  ],
  point_alert: [
    `설 선물로 드린 포인트가 곧 사라져요! (2023.02.03만료)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    's_point.point_alert',
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
  ],
  btn: [
    `사용하기`,
    `Use my points`,
    's_point.btn',
    `使用积分`,
    `使用點數`,
    `ポイントを使用する`,
    `Sử dụng điểm của tôi`,
  ],
  modal_title: [
    `새로 온 튜터 수업 포인트`,
    `Rookie Tutor Value-Back Points`,
    's_point.modal_title',
    `新導師課程點數`,
    `新导师课程积分`,
    `新規チューターポイント`,
    `Điểm buổi học với gia sư mới`,
  ],
  modal_desc: [
    `해당 포인트는 유료수업권으로 예약한 수업에 한하여 드리는 혜택으로 최종 결제 금액 기준 1회 수업료의 50%를 포인트로 드립니다.`,
    `50% of the lesson fee rewarded in points for paid lessons with Rookie Tutors.`,
    's_point.modal_desc',
    `僅限於用收費課程券預約的課程，以最終結算金額為准，1次課費的50%將作為點數返還。`,
    `仅限于用收费课程券预约的课程，以最终结算金额为准，1次课费的50%将作为积分返还。`,
    `該当ポイントは有料レッスンチケットで予約したレッスンに限り差し上げる特典であり最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
    `Đây là điểm ưu đãi chỉ áp dụng cho buổi học được đặt lịch bằng phiếu trả phí, 50% điểm sẽ được cộng 1 lần dựa trên số tiền thanh toán cuối cùng.`,
  ],
  modal_subtitle: [`포인트`, `Points`, 's_point.modal_subtitle', `點數`, `积分`, `ポイント`, `Điểm`],
  modal_list1: [
    `수업권 최종 결제 금액`,
    `Total payment billed`,
    's_point.modal_list1',
    `課程券最終結算金額`,
    `课程券最终结算金额`,
    `レッスンチケット最終決済金額`,
    `Tổng học phí`,
  ],
  modal_list2: [
    `1회 수업료 (최종 결제 금액 / 수업수)`,
    `Lesson fee (Payment per lesson)`,
    's_point.modal_list2',
    `1次課費（最終結算金額/課程數量）`,
    `1次课费（最终结算金额/课程数量）`,
    `一回のレッスンの金額`,
    `Học phí 1 buổi`,
  ],
  modal_list3: [
    `최종 적립 포인트`,
    `Total points earned`,
    's_point.modal_list3',
    `最終累積點數`,
    `最终积累点数`,
    `最終積立ポイント`,
    `Tổng điểm tích lũy`,
  ],
  modal_list1_1: (price) => [
    <>{price}</>,
    <>{price}</>,
    's_point.modal_list1_1',
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
  ],
  modal_list2_1: (price) => [
    <>{price}</>,
    <>{price}</>,
    's_point.modal_list2_1',
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
    <>{price}</>,
  ],
  modal_list3_1: (point) => [
    <>{point}P</>,
    <>{point}P</>,
    's_point.modal_list3_1',
    <>{point}P</>,
    <>{point}P</>,
    <>{point}P</>,
    <>{point}P</>,
  ],
}
export const s_change_password = {
  new_password: [
    `새 비밀번호`,
    `New password`,
    's_change_password.new_password',
    `新的密码`,
    `新密碼`,
    `新しいパスワード`,
    `Mật khẩu mới`,
  ],
  confirm_password: [
    `비밀번호 확인`,
    `Confirm password`,
    's_change_password.confirm_password',
    `确认密码`,
    `重新輸入密碼`,
    `パスワード確認`,
    `Xác nhận mật khẩu`,
  ],
  save: [`저장`, `save`, 's_change_password.save', `保存`, `儲存`, `保存`, `lưu`],
  password_placeholder: [
    `6글자 이상 입력해주세요.`,
    `Enter at least 6 characters`,
    's_change_password.password_placeholder',
    `请输入6个字符以上。`,
    `請輸入6字以上。`,
    `6文字以上入力してください。`,
    `Nhập ít nhất 6 ký tự`,
  ],
  confirm_password_placeholder: [
    `새 비밀번호를 다시 입력해주세요.`,
    `Re-enter your new password`,
    's_change_password.confirm_password_placeholder',
    `请重新输入密码。`,
    `請再次輸入新密碼。`,
    `新しいパスワードを再度入力。`,
    `Nhập lại mật khẩu của bạn`,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_change_password.your_option_change_has_been_completed',
    `变更内容已保存。`,
    `變更的內容儲存成功。`,
    `変更した内容が保存されました。`,
    `Đã lưu`,
  ],
}
export const s_mypage_nav = {
  credit: [
    `수업권/증명서류`,
    `Credits`,
    's_mypage_nav.credit',
    `课程券/证明材料`,
    `課程券／證明`,
    `レッスンチケット/証明書類`,
    `Thẻ học`,
  ],
  point: [`포인트`, `Points`, 's_mypage_nav.point', `积分`, `點數`, `ポイント`, `Điểm`],
  coupon: [`내 쿠폰함`, `Coupons`, 's_mypage_nav.coupon', `我的优惠券`, `我的優惠券`, `マイクーポン`, `Coupon`],
  basic_profile: [
    `기본 정보`,
    `Basic Info`,
    's_mypage_nav.basic_profile',
    `基本信息`,
    `基本資料`,
    `基本情報`,
    `Thông tin cơ bản`,
  ],
  set_profile: [
    `프로필 설정`,
    `Edit Profile`,
    's_mypage_nav.set_profile',
    `设置简介`,
    `簡介設定`,
    `プロフィール設定`,
    `Chỉnh sửa hồ sơ`,
  ],
  notification: [
    `알림 수신 설정`,
    `Notification Settings`,
    's_mypage_nav.notification',
    `设置接收提醒`,
    `通知接收設定`,
    `通知受信設定`,
    `Cài đặt thông báo`,
  ],
  password: [
    `비밀번호 변경`,
    `Change Password`,
    's_mypage_nav.password',
    `变更密码`,
    `變更密碼`,
    `パスワード変更`,
    `Đổi mật khẩu`,
  ],
  timezone: [
    `타임존 변경`,
    `Change Time zone`,
    's_mypage_nav.timezone',
    `变更时区`,
    `變更時區`,
    `タイムゾーン変更`,
    `Đổi múi giờ`,
  ],
  news_special_offers: [
    `혜택 정보 알림`,
    `Ringle Newsletter`,
    's_mypage_nav.news_special_offers',
    `优惠信息提醒`,
    `優惠資訊通知`,
    `特典情報のお知らせ`,
    `Ringle Newsletter`,
  ],
  receive_news_special_offers: [
    `마케팅 정보 알림 수신동의 안내`,
    ``,
    's_mypage_nav.receive_news_special_offers',
    `营销信息提醒接收同意说明`,
    `行銷資訊通知接收同意說明`,
    `マーケティング情報通知受信同意のご案内`,
    ``,
  ],
  sender_ringle: [
    `전송자: 링글잉글리시에듀케이션 서비스`,
    `Sender: Ringle Education Service`,
    's_mypage_nav.sender_ringle',
    `发送人: Ringle English Education Service`,
    `傳送者：Ringle英語教育服務`,
    `送信者: Ringle, Inc.`,
    `Người gửi: Dịch vụ giáo dục Ringle`,
  ],
}
export const s_credit = {
  your_credits: [``, `Your credits`, 's_credit.your_credits', ``, ``, ``, `Thẻ học của bạn`],
  credit_note: [
    `수업권 유의사항`,
    `Terms`,
    's_credit.credit_note',
    `课程券注意事项`,
    `課程券注意事項`,
    `レッスンチケットの注意事項`,
    `Điều khoản`,
  ],
  points_history: [
    `포인트 내역`,
    `Points History`,
    's_credit.points_history',
    `积分明细`,
    `點數明細`,
    `ポイント詳細`,
    `Lịch sử điểm`,
  ],
  invited_history: [
    `초대 현황`,
    `Referral History`,
    's_credit.invited_history',
    `邀请现状`,
    `邀請現況`,
    `紹介の現況`,
    `Lịch sử giới thiệu`,
  ],
  enter_gift_code: [
    `선물 코드 입력`,
    `Enter gift code`,
    's_credit.enter_gift_code',
    `输入礼物代码`,
    `輸入禮物代碼`,
    `ギフトコード入力`,
    `Nhập mã quà tặng`,
  ],
  please_enter_the_gift_code_here: [
    `여기에 선물 코드를 입력하세요`,
    `Please enter gift code here`,
    's_credit.please_enter_the_gift_code_here',
    `请在这里输入礼物代码`,
    `請在這裡輸入禮物代碼`,
    `ここにギフトコードを入力してください。`,
    `Vui lòng nhập mã quà tặng tại đây`,
  ],
  input: [`입력`, `Enter`, 's_credit.input', `输入`, `輸入`, `入力`, `Nhập`],
  exchanging_credit: [
    `수업권 변경하기`,
    `Exchange Credits`,
    's_credit.exchanging_credit',
    `变更课程券`,
    `變更課程券`,
    `レッスンチケットを変更する`,
    `Thẻ học quy đổi`,
  ],
  nonrefundable: [
    `환불 불가`,
    `Nonrefundable`,
    's_credit.nonrefundable',
    `不能退款`,
    `無法退款`,
    `返金不可`,
    `Không hoàn lại`,
  ],
  total_credits: [
    `총 수업권 수`,
    `Total Credits`,
    's_credit.total_credits',
    `总课程券数量`,
    `總課程券數`,
    `合計レッスンチケット数`,
    `Tổng số thẻ học`,
  ],
  unused_credits: [
    `미사용 수업권`,
    `Credits remaining`,
    's_credit.unused_credits',
    `未使用的课程券`,
    `未使用的課程券`,
    `未使用レッスンチケット`,
    `Thẻ học còn lại`,
  ],
  extended_valid_days: [
    `수강기간 연장`,
    `Extend expiration dates`,
    's_credit.extended_valid_days',
    `课程期限延长`,
    `延長聽課期間`,
    `受講期間延長`,
    `Gia hạn ngày kết thúc`,
  ],
  see_details: [
    `자세히 보기`,
    `See details`,
    's_credit.see_details',
    `查看详情`,
    `詳細內容`,
    `詳しく見る`,
    `Xem chi tiết`,
  ],
  lesson_book: [
    `수업 관리북`,
    `Lesson book`,
    's_credit.lesson_book',
    `课程管理本`,
    `課程管理本`,
    `レッスン管理ブック`,
    `Tổng hợp buổi học`,
  ],
  lesson_count: (count) => [
    <>{count}회</>,
    <>
      {count} {count > 1 ? 'Credits' : 'Credit'}
    </>,
    's_credit.lesson_count',
    <>{count}次</>,
    <>{count}次</>,
    <>{count}回</>,
    <>
      {count} {count > 1 ? 'Lessons' : 'Lesson'}
    </>,
  ],
  valid_n_days: (exp_days) => [
    <>수강 기간 : {exp_days}일</>,
    <>Expires in: {exp_days} Days </>,
    's_credit.valid_n_days',
    <>课程期限: {exp_days}日</>,
    <>聽課期間：{exp_days}日</>,
    <>受講期間 : {exp_days}日</>,
    <>Kết thúc trong: {exp_days} ngày </>,
  ],
  alert_deadline: (deadline) => [
    <>(~{deadline}까지)</>,
    <> (on {deadline})</>,
    's_credit.alert_deadline',
    <>(~{deadline}为止)</>,
    <>（至{deadline}）</>,
    <>(~{deadline}まで)</>,
    <> (Until ~{deadline})</>,
  ],
  not_started_credit: [
    `(첫 수업 완료 전까지 수강기간이 차감되지 않습니다)`,
    `(from your first completed lesson)`,
    's_credit.not_started_credit',
    `(首次课程完成前, 课程期限不被扣除)`,
    `（在完成首次課程之前，不扣除聽課期間。）`,
    `(初回レッスン完了後から受講期間が始まります。 )`,
    `(kể từ ngày hoàn thành buổi học đầu tiên)`,
  ],
  using_credit: [
    `사용 수업권 :`,
    `Used credit:`,
    's_credit.using_credit',
    `使用课程券:`,
    `使用的課程券：`,
    `使用レッスンチケット :`,
    `Thẻ học đã dùng:`,
  ],
  credit_has_expired: [
    `수업권 기간이 만료되었습니다`,
    `Credit(s) expired`,
    's_credit.credit_has_expired',
    `课程券已过期`,
    `課程券已過期`,
    `レッスンチケットの期間が満了しました。`,
    `Thẻ học hết hạn`,
  ],
  lessons_cancelled_within_n_hours: (h) => [
    <>수업 {h}시간 이내에 취소한 수업입니다</>,
    <>This lesson has been cancelled within {h} hours before it starts</>,
    's_credit.lessons_cancelled_within_n_hours',
    <>课程开始前{h}小时内, 取消的课程。</>,
    <>於課程開始前{h}小時以內取消的課程</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです。</>,
    <>Buổi học này bị huỷ trong vòng {h} giờ trước khi lớp bắt đầu</>,
  ],
  free_credit: [
    `당일수업권`,
    `Last-minute Credit`,
    's_credit.free_credit',
    `补充课程券`,
    `補充課程券`,
    `補充レッスンチケット`,
    `Thẻ Học bù`,
  ],
  no_show_lesson: [
    `노쇼한 수업입니다`,
    `You did not show up for this lesson`,
    's_credit.no_show_lesson',
    `未出席的课程`,
    `未出現的課程`,
    `無断欠席したレッスンです。`,
    `Bạn đã vắng mặt ở buổi học này`,
  ],
  credit_details: [
    `상세 수업권 현황`,
    `Purchase history`,
    's_credit.credit_details',
    `详细课程券现状`,
    `詳細課程券現況`,
    `詳細レッスンチケットの現況`,
    `Chi tiết thẻ học`,
  ],
  course_work_includes: [
    `수강일 포함`,
    `Include lesson days`,
    's_credit.course_work_includes',
    `包含课程日`,
    `包含聽課日`,
    `受講日を含む`,
    `Hiển thị số buổi học`,
  ],
  without_taking_one: [
    `수강일 미포함`,
    `Do not include lesson days`,
    's_credit.without_taking_one',
    `未包含课程日`,
    `未包含聽課日`,
    `受講日を含まない`,
    `Không hiển thị số buổi học`,
  ],
  display_full_price: [
    `전체 금액 표기`,
    `Display full price`,
    's_credit.display_full_price',
    `显示全部金额`,
    `標示全部金額`,
    `全金額表示`,
    `Hiển thị giá đầy đủ`,
  ],
  display_dividing_price: [
    `분할 금액 표기`,
    `Display installations`,
    's_credit.display_dividing_price',
    `显示部分金额`,
    `標示分批金額`,
    `分割金額表示`,
    `Hiển thị giá trả góp`,
  ],
  hangul_certificate: [
    `한글 증명서`,
    `Certificate in Korean`,
    's_credit.hangul_certificate',
    `中文证明`,
    `中文證明`,
    `証明書（日本語）`,
    `Chứng nhận bằng tiếng Hàn`,
  ],
  english_certificate: [
    `영문 증명서`,
    `Certificate in English`,
    's_credit.english_certificate',
    `英文证明`,
    `英文證明`,
    `証明書（英語）`,
    `Chứng nhận bằng tiếng Anh`,
  ],
  lesson_certificate: [
    `수강증`,
    `Lesson certificate`,
    's_credit.lesson_certificate',
    `听课证`,
    `上課證明`,
    `受講証`,
    `Chứng nhận buổi học`,
  ],
  there_is_no_receipt_please_contact_through_channeltalk: [
    `PayPal 결제 내역은 사용하시는 PayPal 계정을 통해 확인할 수 있습니다`,
    `PayPal payment receipts can be checked through the PayPal account that you use.`,
    's_credit.there_is_no_receipt_please_contact_through_channeltalk',
    `PayPal付款明细可通过PayPal账户进行确认`,
    `PayPal付款明細可透過PayPal帳戶確認`,
    `PayPal決済履歴は、ご使用のPayPalアカウントからご確認いただけます。`,
    `Kiểm tra biên nhận thanh toán PayPal thông qua tài khoản PayPal bạn dùng.`,
  ],
  attendance_certificate: [
    `출석증`,
    `Attendance certificate`,
    's_credit.attendance_certificate',
    `出席证`,
    `出席證明`,
    `出席証`,
    `Chứng nhận tham gia`,
  ],
  attendance_required_option: [
    `출석증 출력 시, 수강일이 포함됩니다.`,
    `The duration of the class will be included when the attendance certification is printed.`,
    's_credit.attendance_required_option',
    `打印出席证时, 将包含课程日。`,
    `列印出席證明時，將包含聽課日。`,
    `出席証の印刷時、受講日が含まれます。`,
    `Thời lượng buổi học sẽ có trong bản in chứng nhận tham gia.`,
  ],
  receipt: [`영수증`, `Receipt`, 's_credit.receipt', `收据`, `收據`, `領収証`, `Biên nhận`],
  select_form_of_certificate: [
    `증명서류 종류 선택`,
    `Select a type of certificate`,
    's_credit.select_form_of_certificate',
    `选择证明材料的种类`,
    `選擇證明種類`,
    `証明書類の種類選択`,
    `Chọn loại chứng nhận`,
  ],
  info_select_form_of_certificate: [
    `증명서류 종류를 선택해주세요`,
    `Select a type of certificate`,
    's_credit.info_select_form_of_certificate',
    `请选择证明材料的种类`,
    `請選擇證明種類`,
    `証明書類の種類を選択してください。`,
    `Chọn loại chứng nhận`,
  ],
  specified_lesson_period: [
    `수강 기간 지정`,
    `Select a lesson period`,
    's_credit.specified_lesson_period',
    `指定课程期限`,
    `指定聽課期間`,
    `受講期間指定`,
    `Chọn thời gian học`,
  ],
  select_form: [
    `양식 선택`,
    `Select a form`,
    's_credit.select_form',
    `选择样式`,
    `選擇格式`,
    `様式選択`,
    `Chọn một biểu mẫu`,
  ],
  preview_output: [
    `출력 내용 미리 보기`,
    `Print preview`,
    's_credit.preview_output',
    `预览打印内容`,
    `預覽列印`,
    `印刷プレビュー`,
    `Xem trước khi in`,
  ],
  issue_certificate: [
    `증명서 발급`,
    `Issue certificate`,
    's_credit.issue_certificate',
    `发放证明`,
    `核發證明`,
    `証明書発行`,
    `Xuất chứng nhận`,
  ],
  issue_receipt: [
    `영수증 발급`,
    `Issue receipt`,
    's_credit.issue_receipt',
    `发放收据`,
    `核發收據`,
    `領収証発行`,
    `Xuất biên nhận`,
  ],
  issue_date: [`발급 시점`, `Issue date`, 's_credit.issue_date', `签发时间`, `核發時間`, `発行時点`, `Ngày phát hành`],
  receipt_of_first_purchase: [
    `첫번째 결제 영수증`,
    `First payment receipt`,
    's_credit.receipt_of_first_purchase',
    `第一笔付款收据`,
    `第一次付款收據`,
    `1枚目の決済領収証`,
    `Biên nhận thanh toán đầu`,
  ],
  receipt_of_second_purchase: [
    `두번째 결제 영수증`,
    `Second payment receipt`,
    's_credit.receipt_of_second_purchase',
    `第二笔付款收据`,
    `第二次付款收據`,
    `2枚目の決済領収証`,
    `Biên nhận thanh toán thứ 2`,
  ],
  received: [`지급 완료`, `Received`, 's_credit.received', `已发送`, `發送成功`, `支給完了`, `Đã nhận`],
  lesson_recommend_duration: [
    `링글의 추천: 5일에 1회 수업`,
    `Ringle's recommendation: 1 lesson per 5 days`,
    's_credit.lesson_recommend_duration',
    `Ringle的推荐: 5天1次课程`,
    `Ringle推薦：5天1次課程`,
    `Ringleのおすすめ: 5日に1回レッスン`,
    `Ringle khuyến khích: 1 buổi học mỗi 5 ngày`,
  ],
  receipt_guide: [
    <>
      카카오페이 및 페이팔 결제 시, 링글 홈페이지 내 영수증 발급이 불가합니다.
      <br />
      해당 결제 사이트에서 직접 영수증 발급 가능한 점 안내드립니다
    </>,
    <>
      Receipt on PayPal is not provided on the Ringle webpage.
      <br />
      Please visit the official website for the receipt issued.
    </>,
    's_credit.receipt_guide',
    <>
      使用KakaoPay或PayPal付款时, 将无法在Ringle主页申请收据。
      <br />
      您可前往相关付款网站直接领取收据。
    </>,
    <>
      使用Kakao Pay或PayPal付款時，無法在Ringle首頁申請收據。
      <br />
      您可於該付款網站直接核發收據。
    </>,
    <>
      KakaoPayおよびPayPalで決済される場合、Ringleホームページ内から領収証の発行はできません。
      <br />
      各決済サイトから直接領収証の発行が可能です。ご了承ください。
    </>,
    <>
      Biên nhận trên PayPal không được cung cấp qua website Ringle.
      <br />
      Vui lòng vào website của PayPal để xuất biên nhận.
    </>,
  ],
  list_1: [
    `정규수업권 1회로 링글 1:1 수업 1회를 수강할 수 있으며, 정규 시간 내 자유롭게 이용가능합니다.`,
    `You can take one lesson per Credit.`,
    's_credit.list_1',
    `使用1张正规课程券, 可进行1次1:1课程。`,
    `可使用1張正規課程券進行1次1:1課程。`,
    `レギュラーレッスンチケット1枚で1回1:1レッスンを受講することができます。`,
    `Mỗi thẻ học dùng cho một buổi học.`,
  ],
  list_2: [
    `정규수업권은 [당일수업] 예약에도 사용할 수 있습니다.`,
    `You can book a lesson available in 24 hours with a Regular Credit.`,
    's_credit.list_2',
    `可用正规课程券预约/进行课程开始24小时前的课程。`,
    `可使用正規課程券預約／進行課程開始24小時前的課程。`,
    `24時間前のレッスンをレギュラーレッスンチケットで予約/受講することができます。`,
    `Bạn có thể đặt buổi học đang trống trong vòng 24 giờ bằng Thẻ Tiêu chuẩn.`,
  ],
  list_3: [
    `정규수업권으로 수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
    `Cancellation made on a lesson booked with Regular Credit 2-24 hours before the lesson will replace the used Regular Credit with a Last-minute Credit.`,
    's_credit.list_3',
    `24小时~2小时以内取消使用正规课程券预约的课程时, 将提供补充课程券。`,
    `取消使用正規課程券預約的課程開始24小時～2小時前的課程時，將提供補充課程券。`,
    `24時間~2時間以内のレギュラーレッスンチケットで予約したレッスンをキャンセルする場合、補充レッスンチケットが提供されます。`,
    `Buổi học đặt bằng Thẻ Tiêu chuẩn bị huỷ từ 2-24 giờ trước khi bắt đầu sẽ được thay thế bằng Thẻ Học bù.`,
  ],
  list_4: [
    `정규수업권으로 수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 당일수업권이 제공됩니다.`,
    `Cancellations made 2-24 hours before the lesson will replace the credit used with a Last-minute Credit.`,
    's_credit.list_4',
    `补充课程券是指可预约/进行其他学员取消的24小时以内的课程。`,
    `補充課程券是指可預約／進行其他學生取消之24小時以內課程的課程券。`,
    `補充レッスンチケットは、他の受講生が24時間以内にキャンセルしたレッスンを予約/受講することができるレッスンチケットです。`,
    `Thẻ Học bù có thể đặt buổi học đang trống trong 24 giờ.`,
  ],
  list_5: [
    `정규수업권으로 수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 당일수업권은 제공되지 않습니다.`,
    `Cancellations made less than 2 hours before the lesson will not restore the lesson credit.`,
    's_credit.list_5',
    `课程开始前2小时内取消时, 算作已经使用了课程券。`,
    `於課程開始前2小時以內取消預約的課程時，將消耗課程券。`,
    `2時間以内に予約されたレッスンをキャンセルする場合、レッスンチケットは消滅します。`,
    `Huỷ trong vòng 2 giờ trước buổi học sẽ không được hoàn lại.`,
  ],
  list_6: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `You must book and complete lessons before your credits expire.`,
    's_credit.list_6',
    `课程期限内应完成课程, 而非预约完课程。`,
    `請於聽課期間內聽完課程，而非預約課程。`,
    `受講期間内にレッスン予約ではなく、レッスン受講を完了しなければなりません。`,
    `Bạn phải đặt lịch và hoàn thành buổi học trước khi Thẻ học hết hạn.`,
  ],
  list_7: [
    `수업권 관련 문의사항이 있을 경우 [1:1 문의]로 연락해 주십시오.`,
    `If you have any questions regarding, please feel free to contact us via chat below.`,
    's_credit.list_7',
    `有关课程券的疑问, 可通过在线[咨询]方式联系我们。`,
    `如有課程券相關疑問，請透過[諮詢]聯絡我們。`,
    `レッスンチケットに関するお問い合わせは[お問い合わせ]チャンネルからご連絡ください。`,
    `Nếu bạn có bất kì câu hỏi liên quan, cứ liên lạc với chúng tôi qua chat.`,
  ],
  remaining: [`남음`, `Remaining`, 's_credit.remaining', ``, ``, `回残り`, `Buổi học còn lại`],
  transfer_credit: [
    `틴즈로 수업권 이관하기`,
    `Transfer Credits to Teens`,
    's_credit.transfer_credit',
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
    `Transfer Credits to Teens`,
  ],
  teens_credits: [
    `링글 틴즈로 이관한 수업권 입니다.`,
    `This credit has been transferred to Ringle Teens.`,
    's_credit.teens_credits',
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
    `This credit has been transferred to Ringle Teens.`,
  ],
  title_1: [
    `내 수업권`,
    `My Credits`,
    's_credit.title_1',
    `我的课程券`,
    `我的課程券`,
    `マイレッスンチケット`,
    `Phiếu học tập của tôi`,
  ],
  regular: [
    `정규수업권`,
    `Regular`,
    's_credit.regular',
    `正规课程券`,
    `正規課程券`,
    `レギュラーレッスンチケット`,
    `Buổi học tiêu chuẩn`,
  ],
  makeup: [
    `당일수업권`,
    `Last-minute`,
    's_credit.makeup',
    `当日课程券`,
    `當日課程券`,
    `補充レッスンチケット`,
    `phiếu học trong ngày`,
  ],
  limited: [
    `시간한정수업권`,
    `Limited Window`,
    's_credit.limited',
    `限时课程券`,
    `限時課程券`,
    `時間限定レッスンチケット`,
    `phiếu học giới hạn thời gian`,
  ],
  urgent: [
    `긴급편성수업권`,
    `FastBook`,
    's_credit.urgent',
    `紧急编排课程券`,
    `緊急編排課程券`,
    `サプライズチケット`,
    `Phiếu học tập khẩn cấp`,
  ],
  min_20: [`20분`, `20 minutes`, 's_credit.min_20', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  min_40: [`40분`, `40 minutes`, 's_credit.min_40', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  note_title: [
    `수업권 유의사항`,
    `Notice`,
    's_credit.note_title',
    `课程券注意事项`,
    `課程券注意事項`,
    `注意事項`,
    `Lưu ý`,
  ],
  note_1: [
    `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
    `Expiration date will begin to diminish immediately after you complete your first lesson. (You can take your first lesson anytime within one year from the day you purchased the credits.)`,
    's_credit.note_1',
    `听课时间将从完成第一节课之后开始计算。（购买课程券后，可在一年内的任意时间起开始上课）`,
    `聽課時間將從完成第一節課之後開始計算。（購買課程券後，可在一年內的任意時間起開始上課）`,
    `受講期間は初回レッスンを完了した時点からカウントます。（レッスンチケット購入後1年以内ならいつでもレッスン開始可能）`,
    `Thời gian học sẽ được tính từ thời điểm hoàn thành tiết học đầu tiên. (Có thể bắt đầu tiết học vào thời điểm mong muốn trong vòng 1 năm sau khi mua phiếu học tập)`,
  ],
  note_2: [
    `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않은 경우에는 수강기간이 자동 차감됩니다.`,
    `However, if you do not take your first lesson within one year from purchasing the credits, lesson days will begin to diminish automatically after one year.`,
    's_credit.note_2',
    `但是，如果在购买课程券后的一年内未开始上课，则课程时间将自动扣除。`,
    `但是，如果在購買課程券後的一年內未開始上課，則課程時間將自動扣除。`,
    `ただし、レッスンチケット購入後1年以内にレッスンを開始しなかった場合には、受講期間が自動でカウントを開始します。`,
    `Tuy nhiên, nếu không bắt đầu tiết học cho đến thời điểm được 1 năm sau khi mua vé, thời gian học sẽ bị trừ tự động.`,
  ],
  note_3: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `You must complete lessons within the lesson days, not just book the lessons.`,
    's_credit.note_3',
    `听课时间不是从预约的时间开始算起，而是从课程完成的时间开始算起。`,
    `聽課時間不是從預約的時間開始算起，而是從課程完成的時間開始算起。`,
    `レッスン期間の基準はレッスンを予約した時点ではなく、受講が完了した時点になります。`,
    `Tiêu chuẩn của thời gian học là thời điểm hoàn thành quá trình học chứ không phải thời điểm đặt trước lớp học.`,
  ],
  free: [`무료`, `Free`, 's_credit.free', `免费`, `免費`, `無料`, `Miễn phí`],
  no_refund: [
    `환불 불가`,
    `Non-Refundable`,
    's_credit.no_refund',
    `不可退款`,
    `不可退款`,
    `返金不可`,
    `Không thể hoàn lại tiền`,
  ],
  status_1: [`수강 기간`, `Expiration date`, 's_credit.status_1', `听课时间`, `聽課時間`, `受講期間`, `Thời gian học`],
  status_2: [`남은 횟수`, `Credits`, 's_credit.status_2', `所剩次数`, `所剩次數`, `残り回数`, `Số lần còn sót lại`],
  status_3: [`발급 시점`, `Issue date`, 's_credit.status_3', `发放时间`, `發放時間`, `発行時点`, `Thời gian cấp`],
  date_not_started: (remainingDays) => [
    `${remainingDays}일 (미시작)`,
    `${remainingDays} days (frozen)`,
    's_credit.date_not_started',
    `${remainingDays}天 (尚未开始)`,
    `${remainingDays}天 (尚未開始)`,
    `${remainingDays}日（始める前）`,
    `${remainingDays} (frozen)`,
  ],
  remain: (remainingCredits) => [
    `${remainingCredits}회`,
    `${remainingCredits} credits`,
    's_credit.remain',
    `${remainingCredits}次`,
    `${remainingCredits}次`,
    `${remainingCredits}回`,
    `${remainingCredits}Lần`,
  ],
  date: (remainingDays) => [
    `${remainingDays}일 남음`,
    `${remainingDays} days left`,
    's_credit.date',
    `剩下${remainingDays}天`,
    `剩下${remainingDays}天`,
    `${remainingDays}日残っています`,
    `${remainingDays}Ngày còn lại`,
  ],
  date_remain_days: (count) => [
    `${count}일`,
    `${count}day(s)`,
    's_credit.date_remain_days',
    `${count}天`,
    `${count}天`,
    `${count}日`,
    `${count}Ngày`,
  ],
  date_remain_hours: (count) => [
    `${count}시간`,
    `${count}hour(s)`,
    's_credit.date_remain_hours',
    `${count}小时`,
    `${count}小時`,
    `${count}時間`,
    `${count}Thời gian`,
  ],
  date_remain_msg: (remainDate) => [
    `${remainDate} 남음`,
    `${remainDate} left`,
    's_credit.date_remain_msg',
    `剩下 ${remainDate}`,
    `剩下 ${remainDate}`,
    `${remainDate}残っています`,
    `${remainDate}còn lại`,
  ],
  date_issue: (n) => [
    <DateComponent format="yyyy.mm.dd HH:mm:ss" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mm.dd.yyyy HH:mm:ss" locale="en">
      {n}
    </DateComponent>,
    's_credit.date_issue',
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  btn_1: [
    `수업 관리북`,
    `Lesson Book`,
    's_credit.btn_1',
    `课程管理手册`,
    `課程管理手冊`,
    `レッスン管理ブック`,
    `Sách quản lý tiết học`,
  ],
  btn_2: [`수강기간 연장`, `Extend dates`, 's_credit.btn_2', `延长听课时间`, `延長聽課時間`, `期間延長`, `Gia hạn`],
  btn_3: [
    `증명서/영수증 발급`,
    `Certificate/Receipt`,
    's_credit.btn_3',
    `开具证明/收据`,
    `開立證明/收據`,
    `証明書/領収書`,
    `Chứng nhận/hóa đơn`,
  ],
  see_detail: [`자세히 보기`, `See details`, 's_credit.see_detail', `了解详情`, `瞭解詳情`, `詳しく見る`, `Nhìn kĩ`],
  msg_refund: [
    `환불 완료`,
    `Refunded`,
    's_credit.msg_refund',
    `退款已完成`,
    `退款已完成`,
    `返金完了`,
    `Hoàn tất việc hoàn trả tiền`,
  ],
  filter: [`상세조회`, `Search`, 's_credit.filter', `查看详情`, `查看詳情`, `詳細を見る`, `Kiểm tra chi tiết`],
  option1_default: [
    `사용 가능 여부`,
    `Status`,
    's_credit.option1_default',
    `是否可以使用`,
    `是否可以使用`,
    `使用可否`,
    `Có hay không có khả năng sử dụng`,
  ],
  option1_1: [`전체보기`, `All`, 's_credit.option1_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option1_2: [`사용 가능`, `Unused`, 's_credit.option1_2', `可以使用`, `可以使用`, `使用可能`, `Có khả năng sử dụng`],
  option1_3: [
    `사용 완료/만료/환불`,
    `Used/Expired/Refunded`,
    's_credit.option1_3',
    `使用完毕/已过期/退款`,
    `使用完畢/已過期/退款`,
    `使用完了/満了/返金`,
    `Hoàn thành việc sử dụng/Hết hạn/Hoàn trả tiền`,
  ],
  option2_default: [
    `수업권 종류`,
    `Credit type`,
    's_credit.option2_default',
    `课程券种类`,
    `課程券種類`,
    `レッスンチケットの種類`,
    `Loại phiếu học tập`,
  ],
  option2_1: [`전체보기`, `All`, 's_credit.option2_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option2_2: [`정규수업권`, `Regular`, 's_credit.option2_2', `正规课程券`, `正規課程券`, `レギュラー`, `Tiêu chuẩn`],
  option2_3: [`당일수업권`, `Last-minute`, 's_credit.option2_3', `当日课程券`, `當日課程券`, `補充`, `Trong ngày`],
  option2_4: [
    `시간한정수업권`,
    `Limited Window`,
    's_credit.option2_4',
    `限时课程券`,
    `限時課程券`,
    `時間限定`,
    `Giới hạn`,
  ],
  option2_5: [
    `긴급편성수업권`,
    `FastBook`,
    's_credit.option2_5',
    `紧急编排课程券`,
    `緊急編排課程券`,
    `サプライズチケット`,
    `Mở gấp`,
  ],
  option3_default: [
    `수업 길이`,
    `Lesson Duration`,
    's_credit.option3_default',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời gian tiết học`,
  ],
  option3_1: [`전체 보기`, `All`, 's_credit.option3_1', `查看全部`, `查看全部`, `全体を見る`, `Nhìn toàn bộ`],
  option3_2: [`20분`, `20 minutes`, 's_credit.option3_2', `20分钟`, `20分鐘`, `20分`, `20 phút`],
  option3_3: [`40분`, `40 minutes`, 's_credit.option3_3', `40分钟`, `40分鐘`, `40分`, `40 phút`],
  apply: [`적용`, `Apply`, 's_credit.apply', `使用`, `使用`, `適用`, `áp dụng`],
  clear: [`초기화`, `Reset`, 's_credit.clear', `初始化`, `重置`, `初期化`, `Khởi tạo lại`],
  placeholder: [
    `찾으시는 수업권이 없습니다.`,
    `You have no credits that fit this criteria.`,
    's_credit.placeholder',
    `您要查找的课程券不存在。`,
    `您要查詢的課程券不存在。`,
    `お探しのレッスンチケットはありません。`,
    `Phiếu học tập mà anh/ chị đang cần tìm không có ạ.`,
  ],
  buy: [`구매하러 가기`, `Purchase now`, 's_credit.buy', `前往购买`, `前往購買`, `購入する`, `Đi mua`],
  title_2: [
    `수업권 상세 내역`,
    `Credit Details`,
    's_credit.title_2',
    `课程券详细信息`,
    `課程券詳細信息`,
    `レッスンチケットの詳細内容`,
    `Nội dung chi tiết phiếu học tập`,
  ],
  title_3: [
    `수업권 현황`,
    `Credit Summary`,
    's_credit.title_3',
    `教学券现状`,
    `課程券現狀`,
    `レッスンチケット現在`,
    `Tình hình hiện tại của phiếu học tập`,
  ],
  title_4: [
    `수강 내역`,
    `Lesson History`,
    's_credit.title_4',
    `听课记录`,
    `聽課記錄`,
    `レッスンの内訳`,
    `Nội dung chi tiết phiếu học tập`,
  ],
  available: [`사용 가능`, `Unused`, 's_credit.available', `可以使用`, `可以使用`, `使用可能`, `Nội dung khóa học`],
  number_available: (remainingCredits) => [
    `${remainingCredits}`,
    `${remainingCredits}`,
    's_credit.number_available',
    `${remainingCredits}`,
    `${remainingCredits}`,
    `${remainingCredits}`,
    `${remainingCredits}`,
  ],
  expired: [
    `만료/환불`,
    `Expired/Refunded`,
    's_credit.expired',
    `已到期/退款`,
    `已到期/退款`,
    `満了/返金`,
    `Hết hạn/ hoàn trả tiền`,
  ],
  number_expired: (expiredCredits) => [
    `${expiredCredits}`,
    `${expiredCredits}`,
    's_credit.number_expired',
    `${expiredCredits}`,
    `${expiredCredits}`,
    `${expiredCredits}`,
    `${expiredCredits}`,
  ],
  number_history: (completedCount) => [
    `${completedCount}`,
    `${completedCount}`,
    's_credit.number_history',
    `${completedCount}`,
    `${completedCount}`,
    `${completedCount}`,
    `${completedCount}`,
  ],
  table_header_1: [
    `수업권 종류`,
    `Credit type`,
    's_credit.table_header_1',
    `课程券种类`,
    `課程券種類`,
    `レッスンチケットの種類`,
    `các loại phiếu học tập`,
  ],
  table_header_2: [
    `수업권 수`,
    `Credits`,
    's_credit.table_header_2',
    `课程券数量`,
    `課程券數量`,
    `レッスンチケットの数`,
    `Số lượng phiếu học tập`,
  ],
  table_header_3: [
    `수강 기간`,
    `Expiration dates`,
    's_credit.table_header_3',
    `听课时间`,
    `聽課時間`,
    `レッスン期間`,
    `Thời gian khóa học`,
  ],
  table_header_4: [`No.`, `No.`, 's_credit.table_header_4', `No.`, `No.`, `No.`, `No.`],
  table_header_5: [
    `수업 내용`,
    `Lesson topic`,
    's_credit.table_header_5',
    `课程内容`,
    `課程內容`,
    `レッスン内容`,
    `Nội dung tiết học`,
  ],
  table_header_6: [
    `수업 시간`,
    `Lesson date`,
    's_credit.table_header_6',
    `课程时间`,
    `課程時間`,
    `レッスン時間`,
    `Thời gian tiết học`,
  ],
  msg_date: (date) => [
    `${date}일`,
    `${date}day(s)`,
    's_credit.msg_date',
    `${date}日`,
    `${date}日`,
    `${date}日`,
    `Ngày`,
  ],
  msg_expire: (n) => [
    <DateComponent format="yyyy.mm.dd HH:mm까지" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mm.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_expire',
    <DateComponent format="截止到yyyy.mm.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="截止到yyyy.mm.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mmまで" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="cho đến yyyy.mm.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  msg_expired: [
    `기간 만료`,
    `Expired`,
    's_credit.msg_expired',
    `时间已过期`,
    `時間已過期`,
    `期間満了`,
    `Thời gian hết hạn`,
  ],
  msg_date_expired: (n) => [
    <DateComponent format="yyyy.mm.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mm.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_date_expired',
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  list_latest: [
    `최신순`,
    `Newest`,
    's_credit.list_latest',
    `按最新的课程排序`,
    `按最新的課程排序`,
    `最新順`,
    `Thứ tự mới nhất`,
  ],
  list_late: [
    `오래된순`,
    `Oldest`,
    's_credit.list_late',
    `按之前的课程排序`,
    `按之前的課程排序`,
    `投稿順`,
    `Thứ tự cũ nhất`,
  ],
  lesson_date: (n) => [
    <DateComponent format="yyyy.mm.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="mm.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.lesson_date',
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-cn">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="zh-tw">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="ja">
      {n}
    </DateComponent>,
    <DateComponent format="yyyy.mm.dd HH:mm" locale="vi">
      {n}
    </DateComponent>,
  ],
  view_more: [`더보기`, `See more`, 's_credit.view_more', `查看更多`, `查看更多`, `もっと見る`, `Nhìn thêm`],
  tooltip_regular: [
    `정규수업권은 정규 시간 내 1:1 수업을 자유롭게 예약 가능한
수업권이며, 당일 수업 예약에도 사용할 수 있습니다.`,
    `Regular credits can be used to book 1:1 lessons for any time within regular hours, including last-minute lessons that begin within 24 hours from booking.`,
    's_credit.tooltip_regular',
    `正规课程券是可以在正规时间内自由预约1对1课程的课程券，也可以用于预约当天的课程。`,
    `正規課程券是可以在正規時間內自由預約1對1課程的課程券，也可以用於預約當天的課程。`,
    `レギュラーレッスンチケットは正規時間内に1on1のレッスンを自由に予約できるレッスンチケットであり、当日のレッスン予約にも使用できます。`,
    `Phiếu học tiêu chuẩn là phiếu có thể tự do đặt trước lớp học 1:1 trong thời gian chính quy và cũng có thể sử dụng cho việc đặt giờ học trong cùng một ngày.`,
  ],
  tooltip_makeup: [
    `당일수업권은 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있는 수업권으로, [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
    `Last-minute credits can only be used to book lessons that begin within 24 hours from booking. You can use Last-minute credits by going to [1:1 Lessons] > [Book Lessons] > [Last-minute Lessons].`,
    's_credit.tooltip_makeup',
    `以预约时间为准，当日课程券是只能预约24小时内开始课程的课程券，可在【1对1课程】>【预约】>【可预约的当天课程】中使用。`,
    `以預約時間爲準，當日課程券是只能預約24小時內開始課程的課程券，可在【1對1課程】>【預約】>【可預約的當天課程】中使用。`,
    `補充レッスンチケットは予約時点24時間以内に開始するレッスンのみ予約が可能なため、[1on1レッスン]>[予約]>[予約可能な当日レッスン]から使用できます。`,
    `Phiếu học trong ngày chỉ có thể đặt trước được các lớp học bắt đầu trong vòng 24 giờ trong thời gian tiêu chuẩn đặt chỗ, với phiếu này thì có thể sử dụng tại [Tiết học 1:1] > [Tiết học đặt trước] > [Tiết học cùng ngày có thể đặt trước]`,
  ],
  tooltip_limited: [
    `시간한정수업권은 정해진 시간대의 수업만 예약할 수 있는 수업권입니다.
(새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited Window credits can only be used to book lessons for certain time periods. (Early bird: 5:00-8:00 am (KST), Night owl: Midnight-03:00 am (KST))`,
    's_credit.tooltip_limited',
    `限时课程券是只能预约规定时间段课程的课程券。

（凌晨班：上午5点至上午8点（KST），深夜班：凌晨0点至凌晨3点（KST））`,
    `限時課程券是只能預約規定時間段課程的課程券。

（凌晨班：上午5點至上午8點（KST），深夜班：凌晨0點至凌晨3點（KST））`,
    `時間限定レッスンチケットは決まった時間帯のレッスンのみ予約可能なレッスンチケットです。
（早朝クラス：午前5時～8時(KST)、深夜クラス：午前0時～午前3時(KST)）`,
    `Phiếu học giới hạn thời gian là phiếu học chỉ có thể đặt trước các lớp học trong thời gian quy định.
 (Lớp sáng: 5h - 8h (KST), Lớp tối: 12h - 3h (KST)`,
  ],
  tooltip_urgent: [
    `긴급편성수업권은 예약 시점 기준 2시간 이내 시작하는 수업만
예약할 수 있는 수업권으로, [1:1수업]>[예약]>[예약 가능한
당일수업] 에서 사용하실 수 있습니다.`,
    `FastBook credits can only be used to book lessons that begin within 2 hours from booking. You can use FastBook credits by going to [1:1 Lessons] > [Book Lessons] > [Last-minute Lessons].`,
    's_credit.tooltip_urgent',
    `以预约时间为准，紧急编排课程券是只能预约2小时内开始课程的课程券，可在【1对1课程】>【预约】>【可预约的当天课程】中使用。`,
    `以預約時間爲準，緊急編排課程券是只能預約2小時內開始課程的課程券，可在【1對1課程】>【預約】>【可預約的當天課程】中使用。`,
    `サプライズチケットは予約時点基準の2時間以内に開始するレッスンのみ予約可能なレッスンチケットのため、[1on1レッスン]>[予約]>[予約可能な当日レッスン]から使用できます。`,
    `Phiếu học tập khẩn cấp chỉ có thể đặt trước các lớp học bắt đầu trong vòng 2 giờ tính theo thời gian đặt chỗ, có thể sử dụng trong [Lớp học 1:1>>[Lớp học đặt trước]>[Lớp học cùng ngày có thể đặt trước]`,
  ],
  tooltip_not_started: [
    <>
      첫 수업 수강 전까지는 수강기간이 차감되지 않습니다.
      <br />
      (단, 구매 1년 뒤 자동 차감 시작)
    </>,
    `Expiration dates are frozen until you complete your first lesson. (However, expiration dates will begin to diminish automatically after one year from purchase date.)`,
    's_credit.tooltip_not_started',
    `开始听第一节课之前不扣除听课时间。（但是，在购买课程券一年后将自动扣除课程时间。）`,
    `開始聽第一節課之前不扣除聽課時間。（但是，在購買課程券一年後將自動扣除課程時間。）`,
    `初回レッスン受講前までは受講期間がカウントされません。
（ただし、購入1年後は自動的にカウント開始）`,
    `Thời gian học sẽ không bị trừ trước tiết học đầu tiên.
 (Tuy nhiên, một năm sau khi mua sẽ bắt đầu khấu trừ tự động)`,
  ],
  tooltip_btn_2: [
    <>
      1. 무료수업권은 연장이 불가합니다.
      <br />
      2. 수강기간 만료시 연장이 불가합니다.
      <br />
      3. 수강기간 연장은 수강기간이 절반 이하 남은 시점부터 가능합니다.
    </>,
    `1. Expiration dates for free credits cannot be extended.
2. Expiration dates cannot be extended after the credit has expired.
3. Expiration dates can be extended when there are less than half of the lesson days left.`,
    's_credit.tooltip_btn_2',
    `1.免费课程券不可延长。
2.听课时间到期后不可延长。
3.听课时间自还剩不到一半时起可以延长听课时间。`,
    `1.免費課程券不可延長。
2.聽課時間到期後不可延長。
3.聽課時間自還剩不到一半時起可以延長聽課時間。`,
    `1.無料レッスンチケットは延長ができません。
2.受講期間満了時の延長はできません。
3.受講期間の延長は受講期間が半分よりも少なくなった時点から可能です。`,
    `1. Không thể gia hạn vé học miễn phí.
 2. Không thể gia hạn thời gian học khi hết hạn.
 3. Có thể gia hạn thời gian học từ thời điểm thời gian học còn lại dưới một nửa.`,
  ],
  lessons_cancelled_within_2_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.lessons_cancelled_within_2_hours',
    <>课程 {h}小时内取消的课程。</>,
    <>課程 {h}小時內取消的課程。</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです</>,
    <>Đây là tiết học đã bị hủy trong thời gian học tiết học.</>,
  ],
  no_show: [
    `수업 노쇼로 수업권이 차감되었습니다.`,
    `A credit was deducted for the no-show lesson.`,
    's_credit.no_show',
    `由于课程缺席，课程券被扣除了。`,
    `由於缺席課程，課程券被扣除了。`,
    `レッスンの無断欠席でレッスンチケットが差し引かれました。`,
    `Phiếu học tập đã bị trừ vì lý do bùng tiết học.`,
  ],
  lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 당일수업권이 제공되었습니다.</>,
    <>
      A last-minute credit has been issued for the lesson that was cancelled within {h} hours before it was scheduled to
      start.
    </>,
    's_credit.lessons_cancelled_within_24_hours',
    <>由于{h}小时内取消，您获得了当日课程券。</>,
    <>由於{h}小時內取消，您獲得了當日課程券。</>,
    <>{h}時間以内のキャンセルで補充レッスンチケットがプレゼントされました</>,
    <>Vé học trong ngày đã được cung cấp do hủy trong thời gian {h}.</>,
  ],
  hours: [`시간`, `Time`, 's_credit.hours', `时间`, `時間`, `時間`, `Giờ`],
  last_minute_lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.last_minute_lessons_cancelled_within_24_hours',
    <>课程 {h}小时内取消的课程。</>,
    <>課程 {h}小時內取消的課程。</>,
    <>レッスン {h}時間以内にキャンセルしたレッスンです</>,
    <>Đây là tiết học đã bị hủy trong thời gian học tiết học.</>,
  ],
  note: [
    `새로워진 [수업권/증명서류] 페이지를 확인해보세요.`,
    `Check out our renewed [Credits] page.`,
    's_credit.note',
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
    `Check out our renewed [Credits] page`,
  ],
  note_btn: [`안내문 보기`, `Learn more`, 's_credit.note_btn', `Learn more`, `Learn more`, `Learn more`, `Learn more`],
  changed: [`(변경됨)`, `(Exchanged)`, 's_credit.changed', `(Exchanged)`, `(Exchanged)`, `(Exchanged)`, `(Exchanged)`],
  date_expired: [`-`, `-`, 's_credit.date_expired', `-`, `-`, `-`, `-`],
  done: [`사용 완료`, `Used`, 's_credit.done', `使用完毕`, `使用完畢`, `使用完了`, `Hoàn thành việc sử dụng`],
}
export const s_mypage_noti = {
  n_hours_ago: (n) => [
    `${n} 시간 전`,
    `${n} hour before lesson`,
    's_mypage_noti.n_hours_ago',
    `${n}小时前`,
    `${n}小時前`,
    `${n} 時間前`,
    `${n} giờ trước buổi học`,
  ],
  email: [`이메일`, `Email`, 's_mypage_noti.email', `电子邮箱`, `電子信箱`, `メールアドレス`, `Email`],
  appo_push_noti: [
    `앱 푸시`,
    `App Push`,
    's_mypage_noti.appo_push_noti',
    `应用程序推送`,
    `APP推播通知`,
    `アプリプッシュ通知`,
    `Thông báo trên ứng dụng`,
  ],
  sms: [`SMS`, `SMS (Text message)`, 's_mypage_noti.sms', `SMS`, `簡訊`, `SMS`, `SMS (Text message)`],
  select_noti_times: [
    `알림 시간 (2개까지 설정 가능)`,
    `Notification Time (Up to 2)`,
    's_mypage_noti.select_noti_times',
    `提醒时间(最多可设定2个)`,
    `通知時間（最多可設定2個）`,
    `通知時間 (2つまで設定可能)`,
    `Số lần thông báo (Tối đa 2)`,
  ],
  saved: [`저장됨`, `Saved`, 's_mypage_noti.saved', `已保存`, `已儲存`, `保存済み`, `Đã lưu`],
  ringle_benefit: [
    `링글 소식 & 혜택`,
    `Ringle Newsletter`,
    's_mypage_noti.ringle_benefit',
    `Ringle消息 & 优惠`,
    `Ringle消息 & 優惠`,
    `Ringleニュース & 特典`,
    `Ringle Newsletter`,
  ],
  ringle_benefit_desc: [
    `링글 뉴스레터, 할인 혜택, 이벤트, 콘텐츠 업데이트 소식 등을 받으실 수 있습니다.`,
    `You will receive Ringle's exclusive promotions and latest news.`,
    's_mypage_noti.ringle_benefit_desc',
    `可接收到Ringle新闻邮件, 优惠信息, 活动, 内容更新等信息`,
    `您可接收Ringle電子報、折扣優惠、活動、內容更新等。`,
    `Ringleニュースレター、割引特典、イベント、コンテンツアップデートのお知らせなどをお受け取りいただけます。`,
    `Bạn sẽ nhận ưu đãi đặc biệt từ Ringle và những tin tức mới nhất.`,
  ],
  lesson_reminder: [
    `1:1 수업 리마인더`,
    `Lesson Reminder`,
    's_mypage_noti.lesson_reminder',
    `1:1课程提醒`,
    `1:1課程備忘錄`,
    `1:1レッスンリマインダー`,
    `Nhắc buổi học`,
  ],
  lesson_reminder_desc: [
    `1:1 수업의 학습 효과를 높일 수 있도록 다양한 리마인더를 받을 수 있습니다.`,
    `You will receive various reminders to help you stay on top of your lessons.`,
    's_mypage_noti.lesson_reminder_desc',
    `为提升1:1 课程的学习效果, 可接收多种提醒。`,
    `為提升1:1課程的學習效果，您可接收各種備忘錄。`,
    `1:1レッスンの学習効果を高めることができるように多様なリマインダーをお受け取りいただけます。`,
    `Bạn sẽ nhận được các thông báo nhắc khác nhau để đảm bảo sự chuyên cần.`,
  ],
  google_calendar: [
    `구글 캘린더 초대장`,
    `Google Calendar Invites`,
    's_mypage_noti.google_calendar',
    `谷歌日历邀请函`,
    `Google日歷邀請`,
    `Googleカレンダー招待状`,
    `Mời bằng Google Calendar`,
  ],
  calendar_schedule: [
    `신청한 1:1 수업의 일정`,
    `1:1 Lesson schedule`,
    's_mypage_noti.calendar_schedule',
    `申请的1:1 课程日程`,
    `申請的1:1課程日程`,
    `予約した1:1レッスンの日程`,
    `Lịch Buổi học 1:1`,
  ],
  calendar_desc: [
    `알림 수신 방법으로 선택한 이메일 계정으로 신청한 수업의 일정이 구글 캘린더 초대장으로 발송됩니다.`,
    `Lessons will automatically be added to your Google Calendar.`,
    's_mypage_noti.calendar_desc',
    `谷歌日历邀请函将申请的课程日程发送至提醒接收方式中设定的电子邮箱。`,
    `申請的課程日程將藉由Google日歷邀請發送至設為通知接收方式的電子信箱。`,
    `通知受信方法で選択したメールアドレスに、予約したレッスンの日程がGoogleカレンダーの招待状として送信されます。`,
    `Buổi học sẽ được tự động thêm vào Google Calendar của bạn.`,
  ],
  lesson_schedule: [
    `1:1 수업 스케줄 업데이트`,
    `Lesson Availability`,
    's_mypage_noti.lesson_schedule',
    `1:1 课程日程更新`,
    `1:1上課日程更新`,
    `1:1レッスンスケジュールアップデート`,
    `Buổi học trống lịch`,
  ],
  lesson_schedule_desc: [
    `찜한 튜터의 수업 스케줄이 업데이트되었을 때, 24시간 이내 시작하는 수업이 이용 가능할 때 알림을 받으실 수 있습니다.`,
    `You will be notified when your favorite tutors update their availabilities or a slot opens up.`,
    's_mypage_noti.lesson_schedule_desc',
    `收藏教师的课程日程更新或出现24小时内可进行的课程时, 将获得提醒。`,
    `當收藏的任課教師更新上課日程或有24小時以內開始的課程時，您可接收通知。`,
    `お気に入りチューターのレッスンスケジュールがアップデートされた時や、24時間以内に始まるレッスンの予約が可能な時にお知らせをお受け取りいただけます。`,
    `Bạn sẽ được thông báo khi gia sư yêu thích của bạn cập nhật lịch trống hoặc có vị trí được mở.`,
  ],
  notification_my_tutor: [
    `찜한 튜터의 수업 스케줄 업데이트`,
    `Updates to my Favorite Tutors' availability`,
    's_mypage_noti.notification_my_tutor',
    `收藏教师的课程日程更新`,
    `我收藏的任課教師上課日程更新`,
    `お気に入りチューターのレッスンスケジュールアップデート`,
    `Cập nhật lịch trống gia sư yêu thích của tôi`,
  ],
  lesson_in_24: [
    `24시간 이내 가능한 수업`,
    `Available Lessons in 24 Hours`,
    's_mypage_noti.lesson_in_24',
    `24小时以内可进行的课程`,
    `24小時以內可聆聽的課程`,
    `24時間以内に可能なレッスン`,
    `Buổi học trống lịch trong 24 giờ`,
  ],
  lesson_in_24_my_tutor: [
    `찜한 튜터의 수업만 알림 받기`,
    `Only notify me when my favorite tutors have a new availability in 24 hours`,
    's_mypage_noti.lesson_in_24_my_tutor',
    `只接收收藏教师的提醒`,
    `僅接收我收藏的任課教師課程通知`,
    `お気に入りチューターのレッスンのみお知らせを受け取る`,
    `Chỉ thông báo khi gia sư yêu thích của tôi trống lịch trong 24 giờ`,
  ],
  receive_email: [
    `이메일`,
    `Receive Email`,
    's_mypage_noti.receive_email',
    `电子邮箱`,
    `電子信箱`,
    `メールアドレス`,
    `Nhận Email`,
  ],
  sms_info: [
    `휴대폰 번호`,
    `Receive SMS (Text Message)`,
    's_mypage_noti.sms_info',
    `手机号码`,
    `手機號碼`,
    `携帯電話番号`,
    `Nhận tin nhắn điện thoại`,
  ],
  verified: [
    `인증 및 휴대폰 번호 변경이 완료 되었습니다.`,
    `Verification complete.`,
    's_mypage_noti.verified',
    `已完成验证及手机号码变更。`,
    `驗證及手機號碼變更成功。`,
    `認証および携帯電話番号の変更が完了しました。`,
    `Xác minh hoàn tất.`,
  ],
  phone: [
    `휴대폰 번호`,
    `Phone number`,
    's_mypage_noti.phone',
    `手机号码`,
    `手機號碼`,
    `携帯電話番号`,
    `Số điện thoại`,
  ],
  new_db_update: [
    `신규 Daily Brief 업데이트`,
    `New Daily Brief update`,
    's_mypage_noti.new_db_update',
    `最新的Daily Brief更新`,
    `全新Daily Brief更新`,
    `新規Daily Briefアップデート`,
    `Daily Brief mới cập nhật`,
  ],
  live_webinar_invitation: [
    `예약한 LIVE 웨비나의 일정`,
    `RSVP-ed LIVE Webinars`,
    's_mypage_noti.live_webinar_invitation',
    `预约的LIVE网络研讨会日程`,
    `已預約的LIVE網路研討會日程`,
    `予約したLIVE Webinarの日程`,
    `Đã xác nhận đăng ký Webinar trực tuyến`,
  ],
  live_webinar_app_push: [
    `리마인더 수신 방법`,
    `Delivery`,
    's_mypage_noti.live_webinar_app_push',
    `提醒接收方法`,
    `備忘錄接收方式`,
    `リマインダー受信方法`,
    `Gửi thông báo`,
  ],
  before_hour_and_two_min: [
    `LIVE 당일 1시간 전, 그리고 당일 시작 2분 후 제공됩니다.`,
    `Will be sent 1 hour before and 2 minutes after the LIVE Webinar stars.`,
    's_mypage_noti.before_hour_and_two_min',
    `LIVE当日1小时前及开始2分钟后提供。`,
    `於LIVE當天1小時前及當天開始2分鐘後提供。`,
    `LIVE当日の1時間前と当日の開始2分後に提供されます。`,
    `Sẽ được gửi trước 1 giờ và 2 phút sau khi Webinar trực tuyến bắt đầu.`,
  ],
  notification_detail: [
    `알림 세부 설정`,
    `Notification Settings`,
    's_mypage_noti.notification_detail',
    `设置提醒细节`,
    `詳細通知設定`,
    `通知詳細設定`,
    `Cài đặt thông báo`,
  ],
  change: [`변경`, `Update`, 's_mypage_noti.change', `变更`, `變更`, `変更`, `Cập nhật`],
  daily_brief: {
    title: [
      `Daily Brief 업데이트`,
      `Daily Brief`,
      's_mypage_noti.daily_brief.title',
      `Daily Brief更新`,
      `Daily Brief更新`,
      `Daily Briefアップデート`,
      `Daily Brief`,
    ],
    desc: [
      `월~금 매일 업데이트되는 Daily Brief 알림을 받으실 수 있습니다.`,
      `You can receive Daily Briefs every weekday.`,
      's_mypage_noti.daily_brief.desc',
      `周一~五, 可每日接收Daily Brief的更新提醒。`,
      `您可接收週一～五每日更新的Daily Brief通知。`,
      `月~金まで毎日アップデートされるDaily Briefのお知らせをお受け取りいただけます。`,
      `Bạn có thể nhận Daily Brief mỗi tuần.`,
    ],
  },
  live_webinar: {
    title: [
      `Live Webinar 리마인더`,
      `Live Webinar`,
      's_mypage_noti.live_webinar.title',
      `Live Webinar提醒`,
      `Live Webinar備忘錄`,
      `Live Webinarリマインダー`,
      `Webinar trực tuyến`,
    ],
    desc: [
      `매 주말 진행되는 링글 웹세미나 리마인더를 받으실 수 있습니다.`,
      `You can receive weekly notifications about Live Webinars.`,
      's_mypage_noti.live_webinar.desc',
      `每周末可接收Ringle网络研讨会提醒。`,
      `您可接收每週末進行的Ringle網路研討會備忘錄。`,
      `毎週末行われるRingleウェブセミナーのリマインダーをお受け取りいただけます。`,
      `Bạn sẽ nhận thông báo hằng tuần về Webinar trực tuyến.`,
    ],
  },
  change_email: [
    `이메일 변경`,
    `Change email`,
    's_mypage_noti.change_email',
    `电子邮箱变更`,
    `變更電子信箱`,
    `メールアドレス変更`,
    `Đổi email`,
  ],
  not_selected: [
    `선택하지 않음`,
    `Not Selected`,
    's_mypage_noti.not_selected',
    `未选`,
    `未選擇`,
    `未選択`,
    `Không chọn`,
  ],
  save: [`저장`, `Save`, 's_mypage_noti.save', `保存`, `儲存`, `保存`, `Lưu`],
  cancel: [`취소`, `Cancel`, 's_mypage_noti.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  enroll_phone_number: [
    `휴대폰 번호 등록`,
    `Register your mobile number`,
    's_mypage_noti.enroll_phone_number',
    `手机号码登录`,
    `登錄手機號碼`,
    `携帯電話番号登録`,
    `Đăng ký số điện thoại của bạn`,
  ],
  phone_number_verification: [
    `전화번호 인증`,
    `Phone number verification`,
    's_mypage_noti.phone_number_verification',
    `电话号码验证`,
    `電話號碼驗證`,
    `SMS認証`,
    `Số điện thoại xác minh`,
  ],
  veri_number: [
    `인증번호`,
    `Verification code`,
    's_mypage_noti.veri_number',
    `验证码`,
    `驗證碼`,
    `認証コード`,
    `Mã xác minh`,
  ],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_re_request',
    `重新发送验证码`,
    `重新傳送驗證碼`,
    `認証コード再リクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_request',
    `发送验证码`,
    `傳送驗證碼`,
    `認証コードリクエスト`,
    `Yêu cầu mã xác minh`,
  ],
  lesson_reminder_settings: [
    `수업 리마인더 설정`,
    `Lesson Reminder Settings`,
    's_mypage_noti.lesson_reminder_settings',
    `设置课程提醒`,
    `課程備忘錄設定`,
    `レッスンリマインダー設定`,
    `Cài đặt nhắc buổi học`,
  ],
  notification_channel: [
    `알림 채널`,
    `Notification Channel`,
    's_mypage_noti.notification_channel',
    `提醒频道`,
    `通知頻道`,
    `お知らせチャンネル`,
    `Kênh thông báo`,
  ],
  db_update_time: [
    `업데이트 시간 설정`,
    `App Push Schedule`,
    's_mypage_noti.db_update_time',
    `更新时间设定`,
    `更新時間設定`,
    `アップデート時間設定`,
    `Thông báo thời khoá biểu`,
  ],
  verify: [`입력 완료`, `Verify`, 's_mypage_noti.verify', `输入完成`, `輸入完畢`, `入力完了`, `Xác minh`],
  sms_kakao: [
    `SMS·알림톡`,
    `Text/Kakao`,
    's_mypage_noti.sms_kakao',
    `SMS·短信通知`,
    `簡訊·訊息通知`,
    `SMS・お知らせトーク`,
    `Tin nhắn/Kakao`,
  ],
  app_push: [
    `앱 푸시`,
    `App Push`,
    's_mypage_noti.app_push',
    `软件推送`,
    `APP推播通知`,
    `アプリのプッシュ`,
    `Thông báo điện thoại`,
  ],
  googlecalendar: [`이메일`, `Email`, 's_mypage_noti.googlecalendar', `电子邮箱`, `電子信箱`, `Email`, `Email`],
  googlecalendar_guide: [
    `구글 가입 계정에 한하여 예약한 수업 일정을 구글 캘린더 초대장으로 받을 수 있습니다.`,
    `Get calendar invites for scheduled lessons (only for users signed up with Google)`,
    's_mypage_noti.googlecalendar_guide',
    `限于谷歌账号，可通过谷歌日历邀请函收到预约的课程。`,
    `僅限使用Google註冊的帳戶可藉由Google日曆邀請接收預約的課程日程。`,
    `Googleの加入アカウントに限って予約したレッスンの日程をGoogleカレンダーの招待状から受け取ることができます。`,
    `Nhận thông báo trên lịch cho những buổi học đã đặt (chỉ áp dụng cho học viên đăng ký bằng tài khoản Google)`,
  ],
  creditinfo: {
    title: [`수업권/포인트/쿠폰 정보`, ``, 's_mypage_noti.creditinfo.title', ``, ``, ``, ``],
    credit_info: [
      `수업권·포인트`,
      `Credits & points`,
      's_mypage_noti.creditinfo.credit_info',
      `课程券，积分`,
      `課程券·點數`,
      `レッスンチケット・ポイント`,
      `Thẻ học & điểm`,
    ],
    credit_chanel: [
      `수업권`,
      `Credits`,
      's_mypage_noti.creditinfo.credit_chanel',
      `课程券`,
      `課程券`,
      `レッスンチケット`,
      `Thẻ học`,
    ],
    credit_guide: [``, ``, 's_mypage_noti.creditinfo.credit_guide', ``, ``, `お客様の設定価格`, ``],
    point_info: [
      `수업권의 수강기간 만료 알림을 받을 수 있습니다.`,
      `Get alerts for expiring credits`,
      's_mypage_noti.creditinfo.point_info',
      `可收到课程券的课程期间到期通知。`,
      `可接收課程券的聽課期間到期通知。`,
      `レッスンチケットの受講期間満了のお知らせを受け取ることができます。`,
      `Nhận thông báo khi thẻ học sắp hết hạn`,
    ],
    point_chanel: [`포인트`, `Points`, 's_mypage_noti.creditinfo.point_chanel', `积分`, `點數`, `ポイント`, `Điểm`],
    point_guide: [``, ``, 's_mypage_noti.creditinfo.point_guide', ``, ``, `お客様の設定価格`, ``],
  },
  lesson: {
    title: [
      `포인트 적립과 만료 알림을 받을 수 있습니다.`,
      `Get alerts for earned or expiring points`,
      's_mypage_noti.lesson.title',
      `可收到积分累积和积分到期通知。`,
      `可接收點數累積與到期通知。`,
      `ポイント確認のお知らせを受け取ることができます。`,
      `Nhận thông báo khi có điểm mới hoặc điểm hết hạn`,
    ],
    lesson_issue: [``, ``, 's_mypage_noti.lesson.lesson_issue', ``, ``, ``, ``],
    lesson_issue_chanel: [``, ``, 's_mypage_noti.lesson.lesson_issue_chanel', ``, ``, ``, ``],
    lesson_issue_guide: [``, ``, 's_mypage_noti.lesson.lesson_issue_guide', ``, ``, ``, ``],
    lesson_reminder: [``, ``, 's_mypage_noti.lesson.lesson_reminder', ``, ``, ``, ``],
    lesson_reminder_chanel: [``, ``, 's_mypage_noti.lesson.lesson_reminder_chanel', ``, ``, ``, ``],
    lesson_reminder_guide: [``, ``, 's_mypage_noti.lesson.lesson_reminder_guide', ``, ``, ``, ``],
    lessonalarm_time: [`알림 시간 (2개까지 설정가능)`, ``, 's_mypage_noti.lesson.lessonalarm_time', ``, ``, ``, ``],
    selectedtime1: [``, ``, 's_mypage_noti.lesson.selectedtime1', ``, ``, ``, ``],
    selectedtime2: [``, ``, 's_mypage_noti.lesson.selectedtime2', ``, ``, ``, ``],
    lesson_help: [``, ``, 's_mypage_noti.lesson.lesson_help', ``, ``, ``, ``],
    lesson_help_chanel: [``, ``, 's_mypage_noti.lesson.lesson_help_chanel', ``, ``, ``, ``],
    lesson_help_guide: [``, ``, 's_mypage_noti.lesson.lesson_help_guide', ``, ``, ``, ``],
    tutorschedule: [``, ``, 's_mypage_noti.lesson.tutorschedule', ``, ``, ``, ``],
    tutorschedule_chanel: [``, ``, 's_mypage_noti.lesson.tutorschedule_chanel', ``, ``, ``, ``],
    tutorschedule_guide: [``, ``, 's_mypage_noti.lesson.tutorschedule_guide', ``, ``, ``, ``],
    tutorschedule1: [``, ``, 's_mypage_noti.lesson.tutorschedule1', ``, ``, ``, ``],
    tutorschedule2: [``, ``, 's_mypage_noti.lesson.tutorschedule2', ``, ``, ``, ``],
    tutorschedule3: [``, ``, 's_mypage_noti.lesson.tutorschedule3', ``, ``, ``, ``],
    modal1: {
      title: [
        `알림 필수 항목`,
        `Notification required`,
        's_mypage_noti.lesson.modal1.title',
        `通知必要项目`,
        `通知必要項目`,
        `通知必須項目`,
        `Thông báo bắt buộc`,
      ],
      guide: [
        `하나 이상의 알림 채널을 꼭 설정해 주셔야 합니다.`,
        `Choose at least one notification channel.`,
        's_mypage_noti.lesson.modal1.guide',
        `请务必设定一个以上的通知频道。`,
        `必須設定一個以上的通知頻道。`,
        `一つ以上のお知らせチャンネルを必ず設定しなければなりません。`,
        `Chọn ít nhất một kênh thông báo.`,
      ],
      ok: [`확인`, `Confirm`, 's_mypage_noti.lesson.modal1.ok', `确认`, `確定`, `確認`, `Xác nhận`],
    },
  },
  content: {
    contentweekly: [``, ``, 's_mypage_noti.content.contentweekly', ``, ``, ``, ``],
    contentweekly_chanel: [``, ``, 's_mypage_noti.content.contentweekly_chanel', ``, ``, ``, ``],
    contentweekly_guide: [``, ``, 's_mypage_noti.content.contentweekly_guide', ``, ``, ``, ``],
    dailybrief: [``, ``, 's_mypage_noti.content.dailybrief', ``, ``, ``, ``],
    dailybrief_chanel: [``, ``, 's_mypage_noti.content.dailybrief_chanel', ``, ``, ``, ``],
    dailybrief_guide: [``, ``, 's_mypage_noti.content.dailybrief_guide', ``, ``, ``, ``],
    dbalarm_time: [``, ``, 's_mypage_noti.content.dbalarm_time', ``, ``, ``, ``],
    timezone: [``, ``, 's_mypage_noti.content.timezone', ``, ``, ``, ``],
    selecteddbtime: [``, ``, 's_mypage_noti.content.selecteddbtime', ``, ``, ``, ``],
    ringlelive: [``, ``, 's_mypage_noti.content.ringlelive', ``, ``, ``, ``],
    ringlelive_chanel: [``, ``, 's_mypage_noti.content.ringlelive_chanel', ``, ``, ``, ``],
    ringlelive_guide: [``, ``, 's_mypage_noti.content.ringlelive_guide', ``, ``, ``, ``],
    modal1: {
      title: [
        `Content Weekly 구독 취소 사유`,
        `Unsubscribe from Content Weekly`,
        's_mypage_noti.content.modal1.title',
        `Content Weekly 取消关注理由`,
        `Content Weekly訂閱取消原因`,
        `Content Weekly購読取り消しの理由`,
        `Hủy theo dõi Nội dung tuần`,
      ],
      guide: [
        `그동안 Content Weekly를 구독해 주셔서 감사합니다.
구독을 취소하는 이유를 알려주세요. (복수 선택 가능)`,
        `Before you go, would you be willing to tell us why you want to unsubscribe? (Choose one or more)`,
        's_mypage_noti.content.modal1.guide',
        `感谢您一直以来关注Content Weekly。
 请选择取消关注的理由。 (可多选)`,
        `感謝您一直以來訂閱Content Weekly，
請告訴我們取消訂閱的理由。（可複選）`,
        `今までContent Weeklyを購読していただきありがとうございます。
購読を取り消しする理由を教えてください。（複数選択可）`,
        `Trước khi hủy theo dõi, bạn có thể cho Ringle biết lí do tại sao không? (Có thể chọn nhiều đáp án)`,
      ],
      category1: [
        `영어 공부에 관심이 없어졌습니다.`,
        `I'm not interested in learning English anymore.`,
        's_mypage_noti.content.modal1.category1',
        `对英语没有兴趣了。`,
        `對英語學習不感興趣了。`,
        `英語の勉強に関心がなくなりました。`,
        `Tôi không hứng thú với việc học tiếng Anh.`,
      ],
      category2: [
        `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
        `Ringle content is not very effective for learning English or building insights.`,
        's_mypage_noti.content.modal1.category2',
        `Ringle内容对英语学习或Insight开发没有帮助。`,
        `Ringle內容對學習英語或開發見解沒有幫助。`,
        `Ringleのコンテンツが英語の勉強やインサイトの習得に貢献しませんでした。`,
        `Nội dung của Ringle không mang lại hiệu quả trong việc học tiếng Anhs.`,
      ],
      category3: [
        `시간이나 비용을 들이고 싶지 않습니다.`,
        `I don't want to invest any time or money into Ringle content.`,
        's_mypage_noti.content.modal1.category3',
        `不想花时间和费用。`,
        `不想花時間或費用。`,
        `時間や費用をかけたくありませんでした。`,
        `Tôi không muốn bỏ tiền để đọc những nội dung của Ringle.`,
      ],
      category4: [
        `다른 좋은 콘텐츠를 찾았습니다.`,
        `I have found better content somewhere else.`,
        's_mypage_noti.content.modal1.category4',
        `找到了其他好的项目。`,
        `已找到其他好內容。`,
        `他に良いコンテンツを探しました。`,
        `Tôi tìm được những nguồn nội dung tốt hơn.`,
      ],
      category5: [`기타`, `Others`, 's_mypage_noti.content.modal1.category5', `其他`, `其他`, `その他`, `Lí do khác`],
      placeholder: [
        `무엇인지 알려주세요!`,
        `Please specify.`,
        's_mypage_noti.content.modal1.placeholder',
        `请写下详细内容。`,
        `請告訴我們原因！`,
        `理由を教えてください、`,
        `Vui lòng nêu rõ.`,
      ],
      submit: [`제출하기`, `Submit`, 's_mypage_noti.content.modal1.submit', `提交`, `提交`, `提出する`, `Gửi`],
      toast: [
        `Content Weekly 구독 완료`,
        `You have successfully subscribed to Content Weekly`,
        's_mypage_noti.content.modal1.toast',
        `Content Weekly 关注完毕`,
        `Content Weekly訂閱成功`,
        `Content Weeklyの購読を完了`,
        `Bạn đã đăng ký theo dõi Nội dung tuần`,
      ],
      toast2: [
        `Content Weekly 구독 취소 사유 제출 완료`,
        `You have successfully unsubscribed from Content Weekly`,
        's_mypage_noti.content.modal1.toast2',
        `Content Weekly 取消关注理由提交完毕`,
        `已提交Content Weekly訂閱取消原因`,
        `Content Weeklyの購読取り消し理由の提出完了`,
        `Bạn đã hủy theo dõi Nội dung tuần`,
      ],
    },
  },
  newsnbenfit: {
    title: [``, ``, 's_mypage_noti.newsnbenfit.title', ``, ``, ``, ``],
    ringlenewsletter: [``, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter', ``, ``, ``, ``],
    ringlenewsletter_chanel: [``, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter_chanel', ``, ``, ``, ``],
    ringlenewsletter_guide: [``, ``, 's_mypage_noti.newsnbenfit.ringlenewsletter_guide', ``, ``, ``, ``],
    marketinginfo: [``, ``, 's_mypage_noti.newsnbenfit.marketinginfo', ``, ``, ``, ``],
    marketinginfo_chanel: [``, ``, 's_mypage_noti.newsnbenfit.marketinginfo_chanel', ``, ``, ``, ``],
    marketinginfo_guide: [``, ``, 's_mypage_noti.newsnbenfit.marketinginfo_guide', ``, ``, ``, ``],
  },
}
export const s_edit_profile = {
  timezone: [`타임존`, `Time zone`, 's_edit_profile.timezone', `时区`, `時區`, `タイムゾーン`, `Múi giờ`],
  change: [`변경`, `Change`, 's_edit_profile.change', `变更`, `變更`, `変更`, `Đổi`],
  korea_name: [`한국 이름`, `Name`, 's_edit_profile.korea_name', `中文姓名`, `中文姓名`, `氏名（日本語）`, `Tên`],
  company_name: [`회사 이름`, `Company`, 's_edit_profile.company_name', `公司名称`, `公司名稱`, `会社名`, `Công ty`],
  role: [`직책(역할)`, `Role`, 's_edit_profile.role', `职务(职责)`, `職責（角色）`, `役職(役割)`, `Chức vụ`],
  company_code: [
    `기업 맞춤 코드`,
    `B2B Partner code`,
    's_edit_profile.company_code',
    `企业特定代码`,
    `企業專用代碼`,
    `企業専用コード`,
    `Mã code đối tác B2B`,
  ],
  company_code_only_once_it_can_be_entered: [
    `기업 맞춤 코드는 1회에 한하여, 입력이 가능합니다. 기업 맞춤 코드 변경을 원하신다면 하단의 1:1 문의 해주시면 됩니다.`,
    `You can only enter one B2B Partner code. If you need to change your code, please contact us via chat below.`,
    's_edit_profile.company_code_only_once_it_can_be_entered',
    `企业特定代码仅限输入1次, 如想变更企业特定代码, 请联系下方的在线咨询。`,
    `企業專用代碼僅限輸入1次。如欲變更企業專用代碼，請透過下方的聊天聯絡我們。`,
    `企業専用コードは1回に限り入力可能です。企業専用コードの変更をご希望の場合は、下のチャネルトークからお問い合わせください。`,
    `Bạn chỉ có thể nhập một mã đối tác B2B. Nếu bạn cần đổi mã, vui lòng chat với chúng tôi.`,
  ],
  inquiries_about_the_company_codes_are_let_us_know_by_channeltalk: [
    `기업 맞춤 코드에 대한 문의는 1) 재직중인 회사 2) 링글 아이디와 함께 홈페이지 하단의 1:1 문의로 남겨주시면 됩니다.`,
    `For B2B Partner code related inquiries, please contact us via chat below and leave us your current company name and Ringle ID.`,
    's_edit_profile.inquiries_about_the_company_codes_are_let_us_know_by_channeltalk',
    `有关企业特定代码的问题, 请联系主页下方在线咨询, 同时提供您的1) 在职中的公司 2) Ringle ID`,
    `有關企業專用代碼的相關諮詢，請於下方的聊天連同1) 任職的公司 2) Ringle的帳號一併留言。`,
    `企業専用コードに関するお問い合わせは、1)在職中の会社、2)Ringle IDと共にホームページ下のチャネルトークからお願いいたします。`,
    `Đối với các câu hỏi liên quan đến mã đối tác B2B, vui lòng chat với chúng tôi và để lại tên công ty bạn đang làm và Ringle ID.`,
  ],
  channel_talk_contact: [
    `1:1 문의`,
    `Contact us via Chat`,
    's_edit_profile.channel_talk_contact',
    `在线咨询`,
    `聊天諮詢`,
    `チャネルトークでお問い合わせ`,
    `Liên lạc với chúng tôi qua chat`,
  ],
  save: [`저장`, `Save`, 's_edit_profile.save', `保存`, `儲存`, `保存`, `Lưu`],
  set_time_zone: [
    `타임존 설정`,
    `Set time zone`,
    's_edit_profile.set_time_zone',
    `设置时区`,
    `設定時區`,
    `タイムゾーン設定`,
    `Cài đặt múi giờ`,
  ],
  it_has_been_saved: [
    `저장되었습니다.`,
    `Saved`,
    's_edit_profile.it_has_been_saved',
    `已保存。`,
    `儲存成功。`,
    `保存されました。`,
    `Đã lưu`,
  ],
  complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_: [
    <>
      프로필을 완성하고, <span style={{ color: '#d3c8fc' }}>10,000P</span>를 받으세요! 튜터가 고객님을 위한 맞춤 수업을
      하는데 도움이 됩니다.
    </>,
    <>
      Complete your profile and earn&nbsp;
      <span style={{ color: '#d3c8fc' }}>$8.62P</span>!
    </>,
    's_edit_profile.complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_',
    <>
      完成简介, 获得<span style={{ color: '#d3c8fc' }}>$8.62P</span>
      吧! 有助于教师为学员提供更好的定制型课程。
    </>,
    <>
      完成簡介，領取<span style={{ color: '#d3c8fc' }}>$8.62P</span>
      吧！有助於任課教師為您提供客製化課程。
    </>,
    <>
      プロフィールを完成させて、 <span style={{ color: '#d3c8fc' }}>$8.62P</span>を
      受け取りましょう！チューターがお客様のためのカスタマイズレッスンをするのに役立ちます。
    </>,
    <>
      Hoàn thành hồ sơ của bạn và nhận&nbsp;
      <span style={{ color: '#d3c8fc' }}>$8.62P</span>!
    </>,
  ],
  haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_: [
    <>
      효과적인 맞춤 수업 위해
      <span className="text-purple-500"> 프로필 완성</span>하고
      <span className="text-blue-500"> 10,000 포인트</span> 적립 받으세요.
    </>,
    <>
      <span className="text-purple-500">Complete your profile</span> for a better Ringle class experience &
      <span className="text-blue-500"> receive $8.62 points</span>!
    </>,
    's_edit_profile.haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_',
    <>
      为打造更加有效的定制型课程
      <span className="text-purple-500">完成简介</span>领取
      <span className="text-blue-500">$8.62积分</span>吧。
    </>,
    <>
      為提供更有效果的客製化課程， 請<span className="text-purple-500">完成簡介</span>， 並領取
      <span className="text-blue-500">$8.62P</span>吧！
    </>,
    <>
      効果的なカスタマイズレッスンのために
      <span className="text-purple-500"> プロフィールを完成</span>させて
      <span className="text-blue-500">$8.62ポイント</span> を獲得しましょう。
    </>,
    <>
      <span className="text-purple-500">Hoàn thành thông tin cá nhân</span> cho trải nghiệm lớp học Ringle tốt hơn &
      <span className="text-blue-500">nhận $8.62 điểm</span>!
    </>,
  ],
  english_name: [
    `영어 이름`,
    `English Name`,
    's_edit_profile.english_name',
    `英文姓名`,
    `英文姓名`,
    `氏名（英語）`,
    `Tên Tiếng Anh`,
  ],
  occupation: [`직업`, `Occupation`, 's_edit_profile.occupation', `职业`, `職業`, `職業`, `Nghề nghiệp`],
  purpose_of_usaing_ringle: [
    `링글 사용 목적`,
    `Purpose of Using Ringle`,
    's_edit_profile.purpose_of_usaing_ringle',
    `选择使用Ringle的目的`,
    `Ringle使用目的`,
    `Ringleの使用目的`,
    `Lí do đến với Ringle`,
  ],
  self_introduction_in_english: [
    `영어 자기 소개`,
    `About Me`,
    's_edit_profile.self_introduction_in_english',
    `英文自我介绍`,
    `英文自我介紹`,
    `英語で自己紹介`,
    `Về tôi`,
  ],
  profile_movie: [
    `프로필 영상`,
    `Profile Video`,
    's_edit_profile.profile_movie',
    `简介短片`,
    `簡介影片`,
    `プロフィール動画`,
    `Video thông tin cá nhân`,
  ],
  required: [`필수`, `Required`, 's_edit_profile.required', `必需`, `必填`, `必須`, `Bắt buộc`],
  optional: [`선택`, `Optional`, 's_edit_profile.optional', `选择`, `選填`, `選択`, `Không bắt buộc`],
  enter_your_basic_info_in_english_: [
    `영어로 고객님에 대한 기본 소개를 입력해주세요.`,
    `Please tell us about yourself in English.`,
    's_edit_profile.enter_your_basic_info_in_english_',
    `请使用英文填写您的基本介绍`,
    `請以英文簡單介紹您自己。`,
    `英語であなたについて基本的な紹介を入力してください。`,
    `Vui lòng chia sẻ về bản thân bạn bằng tiếng Anh.`,
  ],
  change_video: [`변경하기`, `Change video`, 's_edit_profile.change_video', `变更`, `變更`, `変更する`, `Đổi video`],
  shoot_video: [
    `동영상 촬영`,
    `Record video`,
    's_edit_profile.shoot_video',
    `拍摄视频`,
    `拍攝影片`,
    `動画撮影`,
    `Quay video`,
  ],
  profile_photo: [
    `프로필 사진`,
    `Profile Photo`,
    's_edit_profile.profile_photo',
    `简介头像`,
    `大頭照`,
    `プロフィール写真`,
    `Ảnh đại diện`,
  ],
  occupation_company: [
    `직업`,
    `Occupation`,
    's_edit_profile.occupation_company',
    `职业`,
    `職業`,
    `職業`,
    `Nghề nghiệp`,
  ],
  complete_my_profile_for_a_lesson: [
    <>
      수업 전 튜터가 내게 꼭 맞는 수업을 준비할 수 있도록&nbsp;
      <Span primary>내 프로필</Span>을 완성해 보세요!
    </>,
    <>
      Please complete <Span primary>your profile</Span> for a better class experience!
    </>,
    's_edit_profile.complete_my_profile_for_a_lesson',
    <>
      课程开始前为帮助教师准备更适合您的课程内容&nbsp; 请完成<Span primary>我的简介</Span>!
    </>,
    <>
      為使任課教師於課程開始前能為我準備合適的課程，&nbsp; 請完成<Span primary>我的簡介</Span>！
    </>,
    <>
      レッスン前にチューターがあなたにぴったり合うレッスンを準備できるように&nbsp;
      <Span primary>マイプロフィール</Span>を完成させましょう！
    </>,
    <>
      Hãy hoàn thiện <Span primary>hồ sơ của bạn</Span> để có trải nghiệm tốt nhất!
    </>,
  ],
  more_effective_lessons: [
    <>
      여러분을 잘 알고 있는 튜터와 <Span primary>보다 더 효과적인 수업</Span>을 진행할 수 있어요.
    </>,
    ``,
    's_edit_profile.more_effective_lessons',
    <>
      和熟知您的教师一起<Span primary>开展更为有效的</Span>课程吧。
    </>,
    <>
      可與熟悉您的任課教師<Span primary>進行更有效果的課程</Span>。
    </>,
    <>
      皆さんをよく知っているチューターと <Span primary>より効果的なレッスン</Span>を 行いましょう。
    </>,
    ``,
  ],
  event_profile_points: [
    <>
      <span className="text-blue-500">[Event]</span> 성공적으로 내 프로필을 완성하면
      <span className="text-blue-500">10,000포인트</span>가 적립됩니다.
    </>,
    <>
      <Span warning>[Event]</Span> Complete your profile and earn $8.62P.
    </>,
    's_edit_profile.event_profile_points',
    <>
      <span className="text-blue-500">[Event]</span>成功完成我的简介时
      <span className="text-blue-500">可获得$8.62积分</span>。
    </>,
    <>
      <span className="text-blue-500">[Event]</span>成功完成我的簡介時，
      <span className="text-blue-500">將累積$8.62P</span>。
    </>,
    <>
      <span className="text-blue-500">[Event]</span> マイプロフィールを完成させると
      <span className="text-blue-500">$8.62ポイント</span>が付与されます。
    </>,
    <>
      <Span warning>[Event]</Span> Hoàn thiện hồ sơ của bạn và nhận $8.62 điểm.
    </>,
  ],
  event_only_tutor: [
    <>작성한 프로필은 수업 진행을 확정한 튜터만 확인할 수 있고, 언제든 수정할 수 있습니다.</>,
    <>Only tutors can see your profile and it can be modified at any time.&nbsp;</>,
    's_edit_profile.event_only_tutor',
    <>您的简介仅限确定授课的教师进行浏览, 同时您可随时对简介进行修改。</>,
    <>僅確定授課的任課教師可確認您填寫的簡介， 並且您隨時可以修改。</>,
    <>
      作成したプロフィールはレッスン進行が確定したチューターのみ確認することができ、いつでも編集することが できます。
    </>,
    <>Chỉ gia sư của bạn thấy hồ sơ của bạn và có thể thay đổi bất cứ lúc nào.&nbsp;</>,
  ],
  required_menu: [
    `필수 항목`,
    `Required`,
    's_edit_profile.required_menu',
    `必需项目`,
    `必填項目`,
    `必須項目`,
    `Bắt buộc`,
  ],
  optional_menu: [
    `선택 항목`,
    `Optional`,
    's_edit_profile.optional_menu',
    `选择项目`,
    `選填項目`,
    `選択項目`,
    `Không bắt buộc`,
  ],
  btn_rerecord: [
    `다시 촬영`,
    `Record again`,
    's_edit_profile.btn_rerecord',
    `重新拍摄`,
    `重新拍攝`,
    `再撮影`,
    `Quay lại`,
  ],
  info: [`안내`, `Info`, 's_edit_profile.info', `说明`, `說明`, `ご案内`, `Thông tin`],
  rerecord: [
    `다시 촬영하시면 기존에 촬영하신 동영상은 삭제됩니다. 다시 촬영하시겠습니까?`,
    `If you record again, previously recorded video will be deleted. Would you like to record again?`,
    's_edit_profile.rerecord',
    `重新拍摄时, 之前拍摄的视频将会删除。您确定要重新拍摄吗?`,
    `重新拍攝時，將刪除原本拍攝的影片。確定要重新拍攝嗎？`,
    `再撮影すると、すでに撮影した動画は削除されます。再撮影しますか？`,
    `Nếu bạn quay lại, video trước đó của bạn sẽ bị xoá. Bạn có muốn quay lại không?`,
  ],
  start_record: [
    `* 버튼을 누르는 순간 촬영이 시작됩니다.`,
    `Recording will start immediately after you push the button.`,
    's_edit_profile.start_record',
    `*点击按钮, 即可开始拍摄。`,
    `* 點擊按鈕便開始拍攝。`,
    `* ボタンを押した瞬間から撮影が始まります。`,
    `Quay hình sẽ bắt đầu ngay lập tức sau khi bạn nhấn nút.`,
  ],
  please_push_your_btn: [
    <>
      <Span primary>녹화 시작하기 </Span>를 눌러
      <br />
      촬영을 시작해주세요
    </>,
    <>
      When you're ready,
      <br />
      Please click Start Recording button below.
    </>,
    's_edit_profile.please_push_your_btn',
    <>
      <Span primary>点击拍摄</Span>,
      <br />
      即刻开始录制吧
    </>,
    <>
      <Span primary>點擊開始錄製</Span>，
      <br />
      開始拍攝吧！
    </>,
    <>
      <Span primary>録画開始 </Span>を押して
      <br />
      撮影を始めてください。
    </>,
    <>Nhấn "Bắt đầu quay” để quay video giới thiệu</>,
  ],
  study_goal: [
    `영어 공부 목표`,
    `Learning objectives`,
    's_edit_profile.study_goal',
    `英语学习目标`,
    `「英語學習目標」`,
    `英語学習の目標`,
    `Mục tiêu học tập`,
  ],
  more_than_50: [
    `(50 단어 이상 입력)`,
    `(enter min. 50 words)`,
    's_edit_profile.more_than_50',
    `(输入50单词以上)`,
    `（輸入50個單字以上）`,
    `(50単語以上入力)`,
    `(nhập tối thiểu 50 từ)`,
  ],
  name: [`이름`, `Name`, 's_edit_profile.name', `姓名`, `姓名`, `氏名`, `Tên`],
  word_count: [
    `입력된 단어 수`,
    `Word count:`,
    's_edit_profile.word_count',
    `输入的单词数量`,
    `輸入的單字數`,
    `入力した単語数`,
    `Tổng số từ:`,
  ],
  submitted: [
    `제출되었습니다.`,
    `Submitted`,
    's_edit_profile.submitted',
    `已提交。`,
    `提交成功。`,
    `送信されました。`,
    `Đã gửi`,
  ],
  check_point: [
    `포인트 확인`,
    `View Points`,
    's_edit_profile.check_point',
    `确认积分`,
    `確認點數`,
    `ポイント確認`,
    `Xem điểm`,
  ],
  close: [`닫기`, `Close`, 's_edit_profile.close', `关闭`, `關閉`, `閉じる`, `Đóng`],
  complete: [`작성 완료`, `Complete`, 's_edit_profile.complete', `填写完成`, `填寫完成`, `作成完了`, `Hoàn thành`],
  upload_picture: [
    `사진 업로드`,
    `Upload picture`,
    's_edit_profile.upload_picture',
    `上传照片`,
    `上傳照片`,
    `写真アップロード`,
    `Tải hình ảnh lên`,
  ],
  video_popup_title: [
    `프로필 영상 촬영`,
    `Self-introduction Video`,
    's_edit_profile.video_popup_title',
    `拍摄简介短片`,
    `拍攝簡介影片`,
    `プロフィール動画撮影`,
    `Video tự giới thiệu bản thân`,
  ],
  browser_media_permission: [
    <>
      카메라와 마이크 접근이 혀용되지 않아 동영상 촬영이 안됩니다.
      <br />
      브라우저 설정에서 카메라와 마이크 권한을 허용해주세요.
    </>,
    <>
      Access to camera and microphone was blocked so you can't record.
      <br />
      Please update your browser settings to allow access.
    </>,
    's_edit_profile.browser_media_permission',
    <>
      摄像头及麦克风未被允许连接, 因此不能进行视频的拍摄。
      <br />
      请在浏览器设置中允许使用摄像头及麦克风。
    </>,
    <>
      由於未允許相機與麥克風的存取權限，無法錄製影片。
      <br />
      請於瀏覽器設定允許相機與麥克風的權限。
    </>,
    <>
      カメラとマイクへのアクセスが許可されていないため、動画撮影ができません。
      <br />
      ブラウザ設定でカメラとマイクの権限を許可してください。
    </>,
    <>
      Truy cập vào camera và mic đã bị khoá nên bạn không thể thu âm.
      <br />
      Vui lòng cập nhật cài đặt trình duyệt để cấp quyền truy cập.
    </>,
  ],
  preview: [
    `프로필 미리보기`,
    `Preview`,
    's_edit_profile.preview',
    `预览简介`,
    `預覽簡介`,
    `プロフィールプレビュー`,
    `Xem trước`,
  ],
  go: [`바로가기`, `Go`, 's_edit_profile.go', `立即前往`, `立即前往`, `移動`, `Go`],
  start_recording: [
    `녹화 시작하기`,
    `Start Recording`,
    's_edit_profile.start_recording',
    `点击拍摄`,
    `始錄製`,
    `録画開始`,
    `Bắt đầu quay`,
  ],
  submit: [`제출하기`, `Submit`, 's_edit_profile.submit', `提交`, `提交`, `送信する`, `Gửi`],
  congratulations: [
    <>
      <Span textPrimary>축하합니다!</Span>
      <br />
      <Span textPrimary>1만 포인트</Span>가 지급되었습니다.
    </>,
    <>
      <Span primary>Congrats!</Span>
      <br />
      You got <Span primary>$8.62P.</Span>
    </>,
    's_edit_profile.congratulations',
    <>
      <Span primary>祝贺您!</Span>
      <br />
      <Span primary>$8.62</Span>已发送。
    </>,
    <>
      <Span primary>恭喜您！</Span>
      <br />
      已發送<Span primary>$8.62</Span>。
    </>,
    <>
      <Span primary>おめでとうございます！</Span>
      <br />
      <Span primary>$8.62ポイント</Span>が付与されました。
    </>,
    <>
      <Span primary>Chúc mừng bạn!</Span>
      <br />
      Bạn nhận được <Span primary>$8.62 điểm.</Span>
    </>,
  ],
  about_point: [
    <>
      발급된 포인트는 마이 페이지 - 포인트에서 확인 가능합니다.
      <br />* 최초 3회 수업권 구매시 사용 불가합니다.
    </>,
    <>
      Check your points under My Page - Points
      <br />
      *Can't apply points to first 3 lessons.
    </>,
    's_edit_profile.about_point',
    <>
      发送的积分, 可在我的主页 - 积分中进行确认。
      <br />
      *前3次课程券购买时无法使用。
    </>,
    <>
      可於我的頁面 - 點數確認發送的點數。
      <br />* 無法用於購買首3次課程券。
    </>,
    <>
      発行されたポイントはマイページ - ポイントから確認可能です。
      <br />* 初回3回レッスンチケット購入時は使用することができません。
    </>,
    <>
      Kiểm tra điểm của bạn dưới phần Trang của tôi - Điểm
      <br />
      *Không áp dụng điểm cho 3 buổi học đầu tiên.
    </>,
  ],
  use_towards_your_next_purchase: [
    `수업권 구매 시 사용가능합니다.`,
    `Use it towards your next purchase!`,
    's_edit_profile.use_towards_your_next_purchase',
    `可于课程券购买时使用。`,
    `可用於購買課程券。`,
    `レッスンチケット購入時に使用可能です。`,
    `Sử dụng trong lần thanh toán tiếp theo!`,
  ],
  stop_recording: [
    `녹화 종료하기`,
    `Stop Recording`,
    's_edit_profile.stop_recording',
    `结束录像`,
    `結束錄像`,
    `録画終了`,
    `Dừng ghi âm`,
  ],
  record_again: [
    `다시 촬영하기`,
    `Record Again`,
    's_edit_profile.record_again',
    `重新拍摄`,
    `重新拍攝`,
    `再撮影`,
    `Record Again`,
  ],
  delete_video: [`동영상 삭제`, `Delete video`, 's_edit_profile.delete_video', `删除视频`, `刪除影片`, ``, ``],
  click_button_to_save: {
    default: [
      `변경 사항이 있습니다. 저장하기 버튼을 눌러 저장하세요.`,
      ``,
      's_edit_profile.click_button_to_save.default',
      `存在变更事项, 请点击按钮进行保存。`,
      `您有變更事項，請點擊儲存按鈕儲存。`,
      `変更事項があります。保存するボタンを押して保存してください。`,
      ``,
    ],
    with_welcome_coupon: [
      `언어 변환 시, 제공된 웰컴쿠폰 사용이 제한됩니다.`,
      `The Welcome Coupon will be unavailable if you change your language setting.`,
      's_edit_profile.click_button_to_save.with_welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_zoom = {
  title: [
    `화상 프로그램 (Zoom) 설치`,
    `How to Install Zoom`,
    's_zoom.title',
    `安装视频程序(Zoom)`,
    `安裝視訊軟體（Zoom）`,
    `ビデオ会議アプリ (Zoom) インストール`,
    `Hướng dẫn cài đặt Zoom`,
  ],
  subtitle: [
    `링글 수업을 이용하기 위해서는, 줌(Zoom)설치가 필요합니다`,
    `You need to install Zoom in order to use Ringle`,
    's_zoom.subtitle',
    `Ringle课程需安装(Zoom)程序`,
    `為進行Ringle課程，需安裝Zoom。`,
    `Ringleのレッスンを行うためには、Zoomのインストールが必要です。`,
    `Bạn cần cài đặt Zoom để sử dụng Ringle`,
  ],
  step3: [
    <>
      3. 다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.
      <br />
      수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.
      <br />
      <br />
      *수업 시작 전 별도 줌 링크가 제공되지 않습니다.
    </>,
    <>
      3.After downloading, you do not need to make a new Zoom account. You just have to have the program installed on
      your computer.
    </>,
    's_zoom.step3',
    <>
      3. 完成下载后, 无需进行Zoom会员注册/登录/参与会议。
      <br />
      连接时, 可按照Ringle主页 - 预约的课程 - 点击课程入场按钮的顺序开始课程。
      <br />
      <br />
      *课程开始前, 不另行提供Zoom链接。
    </>,
    <>
      3. 下載完畢後，不需註冊Zoom會員／登入／加入會議。
      <br />
      進行課程連線時，可透過Ringle網頁 - 預定的課程 - 進入課程按鈕進場。
      <br />
      <br />
      *課程開始前不另外提供Zoom連結。
    </>,
    <>
      3. ダウンロード完了後、Zoom会員登録/ ログイン/ ミーティングに参加する必要はありません。
      <br />
      レッスンの際は、Ringleホームページ - レッスン予定 - レッスン入室ボタンから入室可能です。
      <br />
      <br />
      *レッスン開始前に別途Zoomのリンクは提供されません。
    </>,
    <>3.Sau khi tải xuống, bạn không cần có tài khoản Zoom. Bạn chỉ cần cài chương trình vào máy tính.</>,
  ],
  step4: [
    `4. Zoom Test 하기 (약 2분 소요)`,
    `4. Test Zoom (takes about 2 minutes)`,
    's_zoom.step4',
    `4. 进行Zoom Test(大约需要2分钟)`,
    `4. Test Zoom（約2分鐘）`,
    `4. Zoom Testする (約2分所要)`,
    `4. Kiểm tra Zoom (mất khoảng 2 phút)`,
  ],
  zoom_test_btn: [
    `Zoom Test 하기`,
    `Test Zoom`,
    's_zoom.zoom_test_btn',
    `进行Zoom Test`,
    `Test Zoom`,
    `Zoom Testする`,
    `Kiểm tra Zoom`,
  ],
  steps_are_following: [
    `단계는 아래와 같습니다.`,
    `For testing, follow these steps:`,
    's_zoom.steps_are_following',
    `步骤如下。`,
    `步驟如下。`,
    `手順は次のとおりです。`,
    `Để kiểm tra, làm theo các bước sau:`,
  ],
  step4_1_click_join_button: [
    `1. Zoom Test 입장 후, 참가 버튼을 클릭합니다.`,
    <>1) After entering the Zoom Test, click the "Join".</>,
    's_zoom.step4_1_click_join_button',
    `1. Zoom Test入场后, 点击参与按键。`,
    `1. 進入Zoom Test後，點擊加入按鈕。`,
    `1. Zoom Test 入室後、参加ボタンをクリックします。`,
    <>1) Sau khi mở Zoom Test, bấm "Join".</>,
  ],
  step4_2: [
    `2. Zoom. us 열기를 클릭합니다.`,
    <>2) Click "Open Zoom.us".</>,
    's_zoom.step4_2',
    `2. 点击打开Zoom. us。`,
    `2. 點擊開啟Zoom. us。`,
    `2. Zoom. us を開くをクリックします。`,
    <>2) Chọn "Open Zoom.us".</>,
  ],
  setp4_4_node: [
    <>만약, 줌 이용이 어려우시다면 언제든지 하단 1:1 문의/카카오톡을 통해 링글팀에게 알려주세요.</>,
    `If you have any problem using Zoom, please contact us. Thank you.`,
    's_zoom.setp4_4_node',
    <>如在Zoom的使用过程中出现问题, 可随时点击下方在线咨询/KakaoTalk 按钮, 联系Ringle团队。</>,
    <>使用Zoom時如有疑問，可隨時透過下方聊天／KakaoTalk告訴Ringle團隊。</>,
    <>もしZoomの利用が難しい場合、いつでも下のチャネルトーク/KakaoTalkから Ringleチームにご連絡ください。</>,
    `Nếu bạn gặp bất kỳ vấn đề về sử dụng Zoom, vui lòng liên hệ với chúng tôi. Cảm ơn.`,
  ],
  go_to_lesson_page_btn: [
    `예습 & 수업 페이지 가기`,
    `Go to Lesson`,
    's_zoom.go_to_lesson_page_btn',
    `前往预习 & 课程页面`,
    `前往預習 & 課程頁面`,
    `予習 & レッスンページに移動`,
    `Vào học`,
  ],
  new_guide_step_3: [
    <>
      3. 오디오 연결 방식을 선택 후<br />
      카메라, 스피커 및 마이크 테스트를 진행합니다.
    </>,
    <>
      3. Select audio option and <br />
      test camera, speaker and mic.
    </>,
    's_zoom.new_guide_step_3',
    <>
      3. 选择音频连接方式后, <br />
      对摄像头, 扬声器及麦克风进行测试。
    </>,
    <>
      3. 選擇音訊連接方式後，
      <br />
      進行相機、喇叭、麥克風測試。
    </>,
    <>
      3. オーディオ接続方式を選択後
      <br />
      カメラ、スピーカーおよびマイクテストを行います。
    </>,
    <>
      3. Chọn phần âm thanh và <br />
      kiểm tra camera, loa và mic.
    </>,
  ],
  new_guide_step_4: [
    <>
      4. 만약 카메라가 보이지 않으면,
      <br /> 하단 버튼을 클릭 후 카메라 설정을 변경해보세요.
    </>,
    <>
      4. If you're having video issues,
      <br /> please check your camera settings.
    </>,
    's_zoom.new_guide_step_4',
    <>
      4. 如果摄像头无法工作时, <br /> 请点击下端按钮对摄像头设定进行变更。
    </>,
    <>
      4. 如果看不到相機畫面，
      <br />
      請點擊下方按鈕變更相機設定。
    </>,
    <>
      4. もしカメラが映らなければ、
      <br /> 下のボタンをクリックしてカメラ設定を変更してみてください。
    </>,
    <>
      4. Nếu bạn có vấn đề về video,
      <br /> vui lòng kiểm tra cài đặt camera.
    </>,
  ],
  new_guide_step_5: [
    <>
      5. 만약 벨소리가 들리지 않으면,
      <br /> 하단 버튼을 클릭 후 스피커 설정을 변경해보세요.
    </>,
    <>
      5. If you don't hear the ringtone,
      <br /> please check your speaker settings.
    </>,
    's_zoom.new_guide_step_5',
    <>
      5. 如果听不到声音, <br /> 请点击下端按钮对扬声器设定进行变更。
    </>,
    <>
      5. 如果聽不到鈴聲，
      <br />
      請點擊下方按鈕變更喇叭設定。
    </>,
    <>
      5. もしベルの音が聞こえなければ、
      <br /> 下のボタンをクリックしてスピーカー設定を変更してみてください。
    </>,
    <>
      5. Nếu bạn không nghe được âm thanh,
      <br /> vui lòng chọn cài đặt loa.
    </>,
  ],
  new_guide_step_6: [
    <>
      6. 만약 마이크가 작동하지 않으면,
      <br /> 하단 버튼을 클릭 후 마이크 설정을 변경해보세요.
    </>,
    <>
      6. If your mic doesn't work,
      <br /> please check your input.
    </>,
    's_zoom.new_guide_step_6',
    <>
      6. 如果麦克风无法工作时, <br /> 请点击下端按钮对麦克风设定进行变更。
    </>,
    <>
      6. 如果麥克風未啟動，
      <br />
      請點擊下方按鈕變更麥克風設定。
    </>,
    <>
      6. もしマイクが作動しなければ、
      <br /> 下のボタンをクリックしてマイク設定を変更してみてください。
    </>,
    <>
      6. Nếu mic của bạn không hoạt động,
      <br /> vui lòng kiểm tra thiết bị thu.
    </>,
  ],
  new_guide_step_7: [
    <>
      7. 다음과 동일한 화면이 나오면,
      <br /> 카메라,스피커와 마이크가 정상적으로 작동중입니다.
    </>,
    <>
      7. If you see below screen,
      <br /> you're all good to go.
    </>,
    's_zoom.new_guide_step_7',
    <>
      7. 如果出现以下画面, <br /> 说明摄像头, 扬声器及麦克风均正常运转。
    </>,
    <>
      7. 如果出現以下畫面，
      <br />
      表示相機、喇叭、麥克風正常啟動中。
    </>,
    <>
      7. 次と同じ画面が出たら、
      <br /> カメラ、スピーカー、マイクが正常に作動中です。
    </>,
    <>
      7. Nếu bạn thấy màn hình phía dưới, <br /> mọi thứ đều sẵn sàng.
    </>,
  ],
  new_guide_step_8: [
    <>
      8. 수업 입장 시, "오디오 연결” 버튼을
      <br /> 클릭하여 오디오가 잘 연결되었는지 확인해보세요.
    </>,
    <>
      8. When you enter your lesson, <br />
      please "Join With Computer Audio".
    </>,
    's_zoom.new_guide_step_8',
    <>
      8. 课程入场时, 点击"音频连接” 按钮
      <br />
      确认音频连接是否正常。
    </>,
    <>
      8. 進入課程時，請點擊「加入音訊」按鈕，
      <br />
      確認音訊是否連接成功。
    </>,
    <>
      8. レッスン入室時、「オーディオに接続」ボタンを
      <br /> クリックしてオーディオが正常に接続されているか確認してください。
    </>,
    <>
      8. Khi vào học, <br />
      chọn "Join With Computer Audio".
    </>,
  ],
  new_guide_step_9: [
    `9. 수업 전 스피커와 비디오가 켜져 있는지 확인해보세요.`,
    `9. Please double check that your audio & video is on.`,
    's_zoom.new_guide_step_9',
    `9. 课程前请确认扬声器及视频装置是否开启。`,
    `9. 課程開始前請先確認喇叭與影像是否開啟。`,
    `9. レッスン前にスピーカーとビデオがオンになっているか確認してください。`,
    `9. Hãy kiểm tra kỹ âm thanh và video đã bật.`,
  ],
  step_1_zoom_download: [
    `Step 1. Zoom 다운로드`,
    `Step 1. Download Zoom`,
    's_zoom.step_1_zoom_download',
    `Step 1. 下载Zoom`,
    `Step 1. 下載Zoom`,
    `Step 1. Zoomをダウンロード`,
    `Bước 1. Tải ứng dụng Zoom`,
  ],
  plz_download_zoom: [
    `링글의 수업은 Zoom 으로 진행됩니다. 아래의 Zoom 파일을 다운로드 해주세요.`,
    `All Ringle lessons run on zoom.`,
    's_zoom.plz_download_zoom',
    `Ringle课程将使用Zoom程序进行, 请下载Zoom安装文件。`,
    `Ringle的課程透過Zoom進行，請下載下方的Zoom檔案。`,
    `RingleのレッスンはZoomで行われます。下のZoomファイルをダウンロードしてください。`,
    `Tất cả buổi học của Ringle diễn ra trên Zoom`,
  ],
  zoom_download: [
    `Zoom 다운로드`,
    `Download Zoom`,
    's_zoom.zoom_download',
    `Zoom下载`,
    `下載Zoom`,
    `Zoomをダウンロード`,
    `Tải ứng dụng Zoom`,
  ],
  step_2_install: [
    `Step 2. 설치`,
    `Step 2. Install Zoom`,
    's_zoom.step_2_install',
    `Step 2. 安装`,
    `Step 2. 安裝`,
    `Step 2. インストール`,
    `Bước 2. Cài đặt Zoom`,
  ],
  run_file_install_in_computer: [
    `다운 받은 파일을 실행 후, 내 컴퓨터에 설치해주세요.`,
    `After download, please install.`,
    's_zoom.run_file_install_in_computer',
    `运行下载的文件, 安装在我的电脑中。`,
    `執行下載的檔案後，安裝至您的電腦。`,
    `ダウンロードしたファイルを開き、自分のコンピューターにインストールしてください。`,
    `Sau khi tải xuống, hãy cài đặt.`,
  ],
  step3_class_access: [
    `Step 3. 수업접속`,
    `Step 3. Enter Lesson`,
    's_zoom.step3_class_access',
    `Step 3. 连接课程`,
    `Step 3. 連接課程`,
    `Step 3. レッスン接続`,
    `Bước 3. Vào học`,
  ],
  after_download_do_not_need_participation: [
    `다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.`,
    `You don't need a Zoom account to enter Ringle lessons.`,
    's_zoom.after_download_do_not_need_participation',
    `下载完成后, 无需进行Zoom会员注册/登录/参与会议。`,
    `下載完畢後，不需註冊Zoom會員／登入／加入會議。`,
    `ダウンロード完了後、Zoom会員登録/ログイン/ミーティングに参加する必要はありません。`,
    `Bạn không cần tài khoản Zoom để vào học Ringle.`,
  ],
  class_can_enter_with_button: [
    `수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.`,
    `Please sign in to Ringle and go to Upcoming Lessons > Enter Lesson to enter your lesson.`,
    's_zoom.class_can_enter_with_button',
    `连接时, 可按照Ringle主页 - 预约的课程 - 点击课程入场按钮的顺序开始课程`,
    `進行課程連線時，可透過Ringle網頁 - 預定的課程 - 進入課程按鈕進場。`,
    `レッスン接続時、Ringleホームページ - レッスン予定 - レッスン入室ボタンで入室可能です。`,
    `Vui lòng đăng nhập vào Ringle và đến Buổi học sắp tới > Nhấn nút Vào học.`,
  ],
  before_start_class_no_zoom_link: [
    `*수업 시작 전 별도 줌 링크가 제공되지 않습니다.`,
    `*We do not provide zoom link prior to lessons.`,
    's_zoom.before_start_class_no_zoom_link',
    `*课程开始前, 不另行提供Zoom链接。`,
    `*課程開始前不另外提供Zoom連結。`,
    `*レッスン開始前に別途Zoomのリンクは提供されません。`,
    `*Chúng tôi không cung cấp link zoom trước buổi học.`,
  ],
  step4_zoom_test: [
    `Step 4. Zoom Test`,
    `Step 4. Test Zoom`,
    's_zoom.step4_zoom_test',
    `Step 4. Zoom Test`,
    `Step 4. Zoom Test`,
    `Step 4. Zoom Test`,
    `Bước 4. Kiểm tra Zoom`,
  ],
  after_install_push_below_link: [
    `설치 후, 아래 링크를 눌러서 테스트를 해주세요. (약 2분 소요)`,
    `Please go to link below to test your audio & video on Zoom (takes 2 min).`,
    's_zoom.after_install_push_below_link',
    `安装后, 请点击以下链接进行测试。(大约需要2分钟)`,
    `安裝後請點擊以下連結測試。（約需2分鐘）`,
    `インストール後、下のリンクを押してテストしてください。(約2分所要)`,
    `Vui lòng vào link dưới đây để kiểm tra âm thanh & video trên Zoom (khoảng 2 phút).`,
  ],
  before_class_check_audio_video: [
    `수업 전에 오디오와 비디오가 올바로 동작하시는지 확인하실 수 있습니다.`,
    ``,
    's_zoom.before_class_check_audio_video',
    `课程开始前可对音频及视频是否处于正常状态进行确认。`,
    `課程開始前可確認音訊與影像是否正常啟動。`,
    `レッスン前にオーディオとビデオが正しく作動するか確認することができます。`,
    ``,
  ],
}
export const s_trial_completed = {
  completed: (name) => [
    <>
      {' '}
      {name}님
      <br />
      <Div spanTag textPrimary>
        체험 수업 신청 완료
      </Div>
      되었습니다.
    </>,
    <>
      {' '}
      Congrats {name}
      <br />
      <Div spanTag textPrimary>
        You booked your trial lesson
      </Div>
    </>,
    's_trial_completed.completed',
    <>
      {' '}
      {name}用户
      <br />
      <Div spanTag textPrimary>
        体验课程的申请
      </Div>
      已经完成。
    </>,
    <>
      {' '}
      {name}的
      <br />
      <Div spanTag textPrimary>
        體驗課程申請完成
      </Div>
      。
    </>,
    <>
      {' '}
      {name}さん
      <br />
      <Div spanTag textPrimary>
        体験レッスンの予約が完了
      </Div>
      しました。
    </>,
    <>
      {' '}
      Chúc mừng {name}
      <br />
      <Div spanTag textPrimary>
        Bạn đã đặt lịch buổi học thử
      </Div>
    </>,
  ],
  check_guide: [
    `자세한 체험 수업 가이드를 확인하고 싶다면?`,
    `How Ringle works`,
    's_trial_completed.check_guide',
    `如果想确认详细的体验课程指南?`,
    `如果想確認詳細的體驗課程指南？`,
    `詳しい体験レッスンガイドを確認したいなら？`,
    `Cách Ringle hoạt động`,
  ],
  time_completed: [`시간`, `Date & Time`, 's_trial_completed.time_completed', `时间`, `時間`, `時間`, `Ngày & Giờ`],
  material_completed: [`교재`, `Materials`, 's_trial_completed.material_completed', `教材`, `教材`, `教材`, `Tài liệu`],
  automaching: [
    `체험 수업 시 튜터는 자동으로 매칭됩니다.`,
    `We'll match you to a tutor`,
    's_trial_completed.automaching',
    `体验课程的教师将自动匹配。`,
    `進行體驗課程時，將自動配對任課教師。`,
    `体験レッスン時、チューターは自動的にマッチングされます。`,
    `Chúng tôi sẽ sắp xếp bạn với gia sư`,
  ],
  checking_three: [
    `링글 체험 수업 전 준비 사항 3가지`,
    `Prep for your lesson in 3 steps`,
    's_trial_completed.checking_three',
    `Ringle体验课程前需准备的三点`,
    `進行Ringle體驗課程前的3大準備事項`,
    `Ringle体験レッスン前に準備すること3つ`,
    `Chuẩn bị cho buổi học với 3 bước`,
  ],
  step_one: [
    `Step1. 교재 읽기 / 질문 선택`,
    `Step1. Read Materials & Select Questions`,
    's_trial_completed.step_one',
    `Step1. 阅读教材 / 选择问题`,
    `Step1. 閱讀教材／選擇提問`,
    `Step1. 教材を読む / 質問選択`,
    `Bước 1. Đọc Tài liệu & Chọn Câu hỏi`,
  ],
  step_two: [
    `Step2. 1:1 맞춤 수업 설정`,
    `Step2. Set Lesson Styles`,
    's_trial_completed.step_two',
    `Step2. 设置1:1定制型课程`,
    `Step2. 設定1:1客製化課程`,
    `Step2. 1:1カスタマイズレッスン設定`,
    `Bước 2. Chọn Hình thức học`,
  ],
  step_three: [
    `Step3. 카메라가 설치된 PC로 수업을 입장해주세요`,
    `Step3. Enter Lesson with Camera On`,
    's_trial_completed.step_three',
    `Step3. 使用设置有摄像头的电脑进入课程`,
    `Step3. 使用附有相機的電腦進入課程`,
    `Step3. カメラがあるPCでレッスンに入室してください。`,
    `Bước 3. Vào học và bật Camera`,
  ],
  step_one_desc: [
    `교재를 미리 읽어보고, 수업에서 다룰 질문을 선택한 후 답변을 준비해보세요.`,
    `Read your materials and choose which questions you'd like your tutor to ask you.`,
    's_trial_completed.step_one_desc',
    `请提前阅读教材, 选择于课堂上想要回答的问题并进行准备。`,
    `請事先閱讀教材，選擇上課時要討論的提問後，準備回覆內容。`,
    `教材を事前に読み、レッスンで扱う質問を選択して回答を準備しましょう。`,
    `Đọc tài liệu và chọn câu hỏi mà bạn muốn gia sư sẽ hỏi mình.`,
  ],
  step_two_desc: [
    `집중적으로 교정 받고 싶은 영역을 선택하여 나만의 수업을 만들어보세요.`,
    `Customize your lesson by selecting your correction areas.`,
    's_trial_completed.step_two_desc',
    `请选择希望集中进行校正的部分, 打造属于自己的完美课程吧。`,
    `選擇希望集中糾正的領域，打造專屬於自己的課程吧！`,
    `集中的に校正してもらいたい分野を選択し、自分だけのレッスンを作りましょう。`,
    `Điều chỉnh buổi học bằng cách chọn phần bạn muốn tập trung sửa lỗi.`,
  ],
  step_one_detail: [
    `교재 듣기 MP3, 핵심표현 등 다양한 자료를 수업 전 확인 가능합니다.`,
    `Also check out key expressions and related videos & news!`,
    's_trial_completed.step_one_detail',
    `教材听力MP3, 核心表达等多种资料,  于课前均可进行确认。`,
    `可於課程開始前聆聽教材MP3，並確認核心用法等各種資料。`,
    `教材のMP3ファイル、キーフレーズなど、さまざまな資料をレッスン前に確認可能です。`,
    `Và kiểm tra cả những mẫu câu chính và video & tin tức liên quan!`,
  ],
  step_two_detail: [
    `체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로만 진행합니다.
정규수업에서는 토론 중심 수업 설정도 가능합니다.`,
    `Your trial lesson will be comprised of 15-minute discussion and 5-minute corrections.`,
    's_trial_completed.step_two_detail',
    `体验课程的形式为完成各问题的对话后, 以纠正教学收尾。
正规课程可将内容设置为以讨论为主。`,
    `進行體驗課程時，在針對各提問結束對話後，以糾正方式進行。
進行正規課程時，可設定以討論為主的課程。`,
    `体験レッスンは、各質問の会話が終わった後に校正を受ける方式で行われます。
レギュラーレッスンではディスカッション中心レッスンに設定可能です。`,
    `Buổi học thử của bạn sẽ bao gồm 15 phút thảo luận và 5 phút sửa lỗi.`,
  ],
  step_three_detail: [
    `교재/실시간 교정화면은 모바일 환경에서 제한적으로 보일 수 있습니다.`,
    `You may not see real-time corrections if you join via mobile.`,
    's_trial_completed.step_three_detail',
    `教材/实时纠正画面, 于使用移动设备时可能会出现限制。`,
    `使用手機觀看教材／即時糾正畫面時可能有所限制。`,
    `教材/リアルタイム校正画面は、モバイル環境で制限的に表示されることがあります。`,
    `Bạn có thể sẽ không thấy sửa lỗi trực tiếp nếu bạn học bằng điện thoại`,
  ],
  before_lesson: [
    `수업 전 알아두세요`,
    `Please note:`,
    's_trial_completed.before_lesson',
    `课程前请务必了解`,
    `課程開始前請注意！`,
    `レッスン前にご確認ください。`,
    `Lưu ý:`,
  ],
  before_one: [
    `PC로 수업 입장을 권장드리며, Zoom 프로그램 설치가 필요합니다.`,
    `Please install Zoom prior to lesson and enter lesson via web browser.`,
    's_trial_completed.before_one',
    `建议使用PC进行课程, 同时必须安装Zoom程序。`,
    `建議使用電腦進入課程，並需安裝Zoom軟體。`,
    `PCでのレッスン入室を推奨しており、Zoomのインストールが必要です。`,
    `Vui lòng cài đặt Zoom trước buổi học và vào lớp bằng trình duyệt web.`,
  ],
  before_two: [
    `수업 후 제공되는 수업 녹음 파일 / 스크립트 / AI 분석 / 튜터 피드백으로 복습 가능합니다.`,
    `You can review lesson using lesson recording, audio transcript, AI analysis and tutor feedback.`,
    's_trial_completed.before_two',
    `课程后提供的课程录音 / 脚本 / AI分析 / 教师反馈均可于复习时使用。`,
    `可利用課程結束後提供的課程錄音檔／腳本／AI分析／任課教師意見回饋進行複習。`,
    `レッスン後に提供されるレッスン録音ファイル / スクリプト / AI analysis / チューターフィードバックで復習可能です。`,
    `Bạn có thể xem lại buổi học thông qua bản ghi âm, bản ghi thoại, phân tích AI và nhận xét của gia sư.`,
  ],
  before_three: [
    `교재/ 체험 수업 시간 변경 및 취소는 수업 시작 24시간 이전까지 가능합니다.`,
    `You may reschedule or change materials up to 24 hours before the lesson.`,
    's_trial_completed.before_three',
    `教材/体验课程的时间变更及取消, 需在课程开始前24小时进行。`,
    `最晚可於課程開始24小時前變更或取消教材／體驗課程時間。`,
    `教材/体験レッスンの時間変更およびキャンセルは、レッスン開始24時間前まで可能です。`,
    `Bạn có thể dời lịch hoặc thay đổi tài liệu tối thiểu 24 giờ trước buổi học..`,
  ],
  before_three_gray: [
    `*수업 시작 24시간 이내 변경 및 취소 희망 시 오른쪽 하단 1:1 문의를 통해 연락 바랍니다.`,
    `*If you need to reschedule or change materials in less than 24 hours before the lesson, please contact us.`,
    's_trial_completed.before_three_gray',
    `*课程开始前24小时内希望进行变更或取消时, 可通过右下方的在线咨询联系我们。`,
    `*於課程開始前24小時以內希望變更或取消時，請透過右下方的聊天聯絡我們。`,
    `*レッスン開始24時間以内の変更およびキャンセルをご希望の際は、右下のチャネルトークからご連絡ください。`,
    `*Nếu bạn phải dời lịch hoặc thay đổi tài liệu trong vòng 24 giờ trước khi buổi học bắt đầu, vui lòng chat với chúng tôi.`,
  ],
  go_home: [`홈으로 가기`, `Home`, 's_trial_completed.go_home', `前往主页`, `前往首頁`, `ホームに移動`, `Trang chủ`],
  button: [
    `체험 수업 준비하기`,
    `Lesson Prep`,
    's_trial_completed.button',
    `准备体验课程`,
    `準備體驗課程`,
    `体験レッスンを準備する`,
    `Chuẩn bị Buổi học`,
  ],
}
export const s_auth = {
  modify: [`수정`, `Update`, 's_auth.modify', `修改`, `修改`, `編集`, `Cập nhật`],
  phone_verification: [
    `휴대폰 인증`,
    `Phone Verification`,
    's_auth.phone_verification',
    `手机验证`,
    `手機驗證`,
    `SMS認証`,
    `Xác minh số điện thoại`,
  ],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_auth.verification_code_request',
    `申请验证码`,
    `傳送驗證碼`,
    `認証コードを受け取る`,
    `Yêu cầu mã xác minh`,
  ],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_auth.verification_code_re_request',
    `重新申请验证码`,
    `重新傳送驗證碼`,
    `認証コードを再送する`,
    `Yêu cầu mã xác minh`,
  ],
  submit_code: [`입력 완료`, `Verify`, 's_auth.submit_code', `输入完成`, `輸入完畢`, `入力完了`, `Xác minh`],
  kakao: [
    `카카오로 5초만에 시작`,
    `Sign up with Kakao`,
    's_auth.kakao',
    `使用Kakao账户5秒完成注册`,
    `使用Kakao 5秒註冊`,
    `KakaoTalkで5秒で登録`,
    `Đăng ký bằng Kakao`,
  ],
  with_email: [
    `이메일로 시작`,
    `Sign up with email`,
    's_auth.with_email',
    `使用电子邮箱注册`,
    `使用電子信箱註冊`,
    `メールアドレスで登録`,
    `Đăng ký bằng email`,
  ],
  naver: [
    `네이버로 가입`,
    `Sign up with Naver`,
    's_auth.naver',
    `使用Naver注册`,
    `使用NAVER註冊`,
    `Naverで登録`,
    `Đăng ký bằng Naver`,
  ],
  choose: [
    `회원가입 방법을 선택해주세요`,
    `Sign up with`,
    's_auth.choose',
    `请选择会员注册方法`,
    `請選擇會員註冊方式`,
    `会員登録方法を選択してください。`,
    `Đăng ký bằng`,
  ],
  send_verification: [
    `인증번호 전송`,
    `Send Verification Code`,
    's_auth.send_verification',
    `发送验证码`,
    `傳送驗證碼`,
    `認証コード送信`,
    `Gửi Mã xác minh`,
  ],
  ok: [`확인`, `OK`, 's_auth.ok', `确认`, `確認`, `確認する`, `Xác nhận`],
  phone_check: [
    `올바른 휴대폰 번호를 입력해주세요.`,
    `Invalid phone number. Please try again.`,
    's_auth.phone_check',
    `请输入正确的手机号码。`,
    `請輸入正確的手機號碼。`,
    `正しい電話番号を入力してください。`,
    `Số điện thoại không hợp lệ. Vui lòng thử lại.`,
  ],
  google: [
    `구글로 가입`,
    `Sign up with Google`,
    's_auth.google',
    `使用谷歌注册`,
    `使用Google註冊`,
    `Googleで登録`,
    `Đăng ký bằng Google`,
  ],
  facebook: [
    `페이스북으로 가입`,
    `Sign up with Facebook`,
    's_auth.facebook',
    `使用Facebook注册`,
    `使用Facebook註冊`,
    `Facebookで登録`,
    `Đăng ký bằng Facebook`,
  ],
  native_languaged: [
    `모국어`,
    `Native language`,
    's_auth.native_languaged',
    `母语`,
    `母語`,
    `母国語`,
    `Ngôn ngữ mẹ đẻ`,
  ],
  native_language_placeholder: [
    `모국어를 선택해주세요`,
    `Select your native language`,
    's_auth.native_language_placeholder',
    `请选择母语`,
    `請選擇母語`,
    `母国語を選択してください。`,
    `Chọn ngôn ngữ mẹ đẻ của bạn`,
  ],
  this_number_already_exists: [
    `이미 해당 번호로 가입된 계정이 있습니다. 링글팀에 문의해주세요.`,
    `An account with this number already exists. Please contact us with any questions.`,
    's_auth.this_number_already_exists',
    `已有用此号码加入的账号.如有疑问请联系客服.`,
    `以有用此號碼加入的賬號. 如有疑問請聯係客服.`,
    `登録済みのアカウントがあります。 Ringleチームにお問い合わせください。`,
    `Tài khoản với số điện thoại này đã tồn tại. Vui lòng liên hệ với chúng tôi nếu bạn có thắc mắc.`,
  ],
  title: [
    `Welcome to Ringle!`,
    `Welcome to Ringle!`,
    's_auth.title',
    `Welcome to Ringle!`,
    `Welcome to Ringle!`,
    `Welcome to Ringle!`,
    `Welcome to Ringle!`,
  ],
  apply_tutor: [
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    's_auth.apply_tutor',
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    `Apply to become a tutor`,
    `Apply to become a tutor`,
  ],
}
export const s_purchase = {
  header: [`결제하기`, `Purchase`, 's_purchase.header', `付款`, `付款`, `お支払い内容`, `Thanh toán`],
  name_ko: [`한글 이름`, `Name`, 's_purchase.name_ko', `中文姓名`, `中文姓名`, `氏名（日本語）`, `Tên`],
  lesson_length: [
    `수업길이`,
    `Duration`,
    's_purchase.lesson_length',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng`,
  ],
  time: [`시간대`, `Times`, 's_purchase.time', `时间段`, `時段`, `受講時間帯`, `Thời gian`],
  point: [`포인트`, `Points`, 's_purchase.point', `积分`, `點數`, `ポイント`, `Điểm`],
  price: [`결제금액`, `Subtotal`, 's_purchase.price', `付款金额`, `付款金額`, `決済金額`, `Tổng`],
  discount_price: [
    `총 할인 금액`,
    `Total discount`,
    's_purchase.discount_price',
    `总优惠金额`,
    `總折扣金額`,
    `割引分合計`,
    `Tổng giảm giá`,
  ],
  won: (price) => [`${price}원`, `$${price}`, 's_purchase.won', `${price}`, `${price}`, `${price}`, `$${price}`],
  total_price: [
    `최종 결제 금액`,
    `Your total`,
    's_purchase.total_price',
    `实际付款金额`,
    `最終付款金額`,
    `お支払い総額`,
    `Tổng cộng`,
  ],
  directPayment: [
    `카드 직접 입력`,
    `Enter card information`,
    's_purchase.directPayment',
    `直接输入银行卡`,
    `手動輸入信用卡`,
    `カード直接入力`,
    `Nhập thông tin thẻ`,
  ],
  virtual_account: [
    `무통장 입금`,
    `Wire Transfer`,
    's_purchase.virtual_account',
    `无存折汇款`,
    `無摺存款`,
    `口座振込`,
    `Chuyển khoản`,
  ],
  paypal: [`Paypal 사용`, `via PayPal`, 's_purchase.paypal', `使用Paypal`, `使用PayPal`, `PayPal使用`, `qua PayPal`],
  use_point: [
    `포인트 사용`,
    `Points applied`,
    's_purchase.use_point',
    `使用积分`,
    `使用點數`,
    `ポイント利用`,
    `Điểm được áp dụng`,
  ],
  verify: [`본인 인증`, `Verify`, 's_purchase.verify', `身份验证`, `身分驗證`, `本人認証`, `Xác minh`],
  verify_explanation: [
    `타인 명의 도용, 중복 가입 방지를 위해 본인을 인증합니다.`,
    `Please verify to prevent fraud.`,
    's_purchase.verify_explanation',
    `为防止盗用及重复注册的现象出现, 需要进行身份验证。`,
    `為防止盜用他人名義或重複註冊，進行身分驗證。`,
    `他人名義の不正利用および重複登録防止の目的で本人認証を行います。`,
    `Vui lòng xác minh để ngăn gian lận.`,
  ],
  usual_price: [`정가 가격`, `Regular price`, 's_purchase.usual_price', `定价`, `定價`, `通常価格`, `Giá tiêu chuẩn`],
  discount_sum: [
    `총 할인금액`,
    `Total discount`,
    's_purchase.discount_sum',
    `总优惠金额`,
    `總折扣金額`,
    `割引分合計`,
    `Tổng giảm giá`,
  ],
  account_transfer: [
    `실시간 계좌 이체`,
    `Wire Transfer`,
    's_purchase.account_transfer',
    `实时转账`,
    `即時轉帳`,
    `リアルタイム口座振替`,
    `Chuyển tiền`,
  ],
  naver_pay: [
    `네이버페이(카드결제)`,
    `NaverPay`,
    's_purchase.naver_pay',
    `Naver Pay(银行卡付款)`,
    `Naver Pay（信用卡付款）`,
    `NaverPay(カード決済)`,
    `NaverPay`,
  ],
  virtual_account_make: [
    `가상 계좌 발급`,
    `Virtual Account`,
    's_purchase.virtual_account_make',
    `提供虚拟账号`,
    `提供虛擬帳號`,
    `仮想口座発行`,
    `Virtual Account`,
  ],
  pay_pal: [`PayPal`, `PayPal`, 's_purchase.pay_pal', `PayPal`, `PayPal`, `PayPal`, `PayPal`],
  warning: [`주의사항`, `Terms`, 's_purchase.warning', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
  second_card: [
    `분할 결제 (두번째 카드)`,
    `Second card`,
    's_purchase.second_card',
    `分别付款 (银行卡二)`,
    `分批付款（第二張信用卡）`,
    `分割決済 (2枚目のカード)`,
    `Thẻ thứ hai`,
  ],
  first_card: [
    `분할 결제 (첫번째 카드)`,
    `First card`,
    's_purchase.first_card',
    `分别付款 (银行卡一)`,
    `分批付款（第一張信用卡）`,
    `分割決済 (1枚目のカード)`,
    `Thẻ thứ nhất`,
  ],
  two_digit: [`2자리`, `2 Digit`, 's_purchase.two_digit', `2个字符`, `2位數`, `2桁`, `2 chữ số`],
  only_domestic: [
    `국내 카드만 가능`,
    `Only available for Korean cards.`,
    's_purchase.only_domestic',
    `仅限韩国银行卡`,
    `僅限韓國銀行卡`,
    `国内(韓国)カード限定利用可能`,
    `Chỉ có sẵn cho thẻ Hàn Quốc`,
  ],
  purchase_agreement: [
    <>
      결제 내역을 확인하였으며{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">전자상거래법 및 링글 정책</span>
      </LinkLocale>
      에 의거하여 환불이 진행되는 것에 동의합니다.
    </>,
    <>
      I confirm my purchase and agree to{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">Ringle's terms and conditions</span>
      </LinkLocale>
      .
    </>,
    's_purchase.purchase_agreement',
    <>
      已确认付款明细，同意依据{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">电子商务交易法及Ringle政策</span>
      </LinkLocale>
      进行退款。{' '}
    </>,
    <>
      已確認付款明細，同意依據{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">電子商務交易法及Ringle政策</span>
      </LinkLocale>
      進行退款。
    </>,
    <>
      決済履歴を確認し、{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">電子商取引法およびRingleポリシー</span>
      </LinkLocale>
      に基づいて返金が行われることに同意します。
    </>,
    <>
      Tôi xác nhận thanh toán của mình và đồng ý với{' '}
      <LinkLocale href={urls.student.policy.all} target="_blank">
        <span className="underline text-purple-500">Điều khoản và điều kiện của Ringle</span>
      </LinkLocale>
      .
    </>,
  ],
  switch_to_won: [
    `한국 결제 수단으로 바꾸기`,
    `Switch to Korean payment method`,
    's_purchase.switch_to_won',
    `转换为韩国支付方式`,
    `轉換為韓國支付方式`,
    `韓国の決済方式に変更する`,
    `Chuyển phương thức thanh toán Hàn Quốc`,
  ],
  switch_to_other: [
    `해외 결제 수단으로 바꾸기`,
    `Switch to local payment method`,
    's_purchase.switch_to_other',
    `转换为本地支付方式`,
    `轉換為本地支付方式`,
    `現地の決済方式に変更する`,
    `Chuyển phương thức thanh toán địa phương`,
  ],
  process: {
    title: [
      `결제가 진행중입니다.`,
      `Processing your payment...`,
      's_purchase.process.title',
      `正在结算`,
      `正在結算`,
      `決済が行われています。`,
      `Đang tiến hành thanh toán...`,
    ],
    subtitle1: [
      `최대 1분이 소요될 수 있습니다. 잠시만 기다려주세요.`,
      `This may take up to a minute to complete.`,
      's_purchase.process.subtitle1',
      `可能需要1分钟，请耐心等待。`,
      `可能需要1分鐘，請耐心等待。`,
      `最大1分の所要時間があります。しばらくお待ちください。`,
      `Quy trình diễn ra trong vòng 1 phút.`,
    ],
    subtitle2: [
      `브라우저를 닫거나 새로고침 하시면 결제 오류가 발생할 수 있습니다.`,
      `Please stay on the page while the payment is being processed.`,
      's_purchase.process.subtitle2',
      `关闭或刷新页面可能会导致结算失败`,
      `關閉或刷新網頁可能會導致結算失敗`,
      `ブラウザを閉じたり更新したりすると決済エラーが発生する場合があります。`,
      `Vui lòng không thoát khỏi trang trong khi thah toán.`,
    ],
  },
  fail: {
    title: [
      <>
        결제를 완료할 수 없습니다.
        <br />
        다시 시도해주세요.
      </>,
      <>
        Your payment didn't go through.
        <br />
        Please try again.
      </>,
      's_purchase.fail.title',
      <>
        无法结算。
        <br />
        请再试一次。
      </>,
      <>
        無法結算。
        <br />
        請再試一次。
      </>,
      <>
        決済を完了することができません。
        <br />
        もう一度お試しください。
      </>,
      <>
        Thanh toán không thành công. <br />
        Vui lòng thử lại.
      </>,
    ],
    error: [
      `결제 오류`,
      `Transaction failed`,
      's_purchase.fail.error',
      `结算失败`,
      `結算失敗`,
      `決済エラー`,
      `Giao dịch thất bại`,
    ],
    history: [
      `결제 시도 내역`,
      `Payment attempts`,
      's_purchase.fail.history',
      `尝试结算记录`,
      `嘗試結算記錄`,
      `決済内訳`,
      `Thông tin thanh toán`,
    ],
    amount: [
      `결제 요청 금액`,
      `Amount due`,
      's_purchase.fail.amount',
      `结算金额`,
      `結算金額`,
      `決済要求金額`,
      `Số tiền đến hạn`,
    ],
    help1: [
      `결제 오류가 반복되신다면`,
      `Having trouble?`,
      's_purchase.fail.help1',
      `如果多次结算失败，`,
      `如果多次結算失敗，`,
      `決済エラーが繰り返される場合は、`,
      `Nếu bạn gặp sự cố`,
    ],
    help2: [
      `여기를 눌러 문의 해주세요.`,
      `Let us help you.`,
      's_purchase.fail.help2',
      `请点击此次咨询。`,
      `請點擊此處咨詢。`,
      `ここをクリックしてお問い合わせください。`,
      `vui lòng liên hệ với chúng tôi.`,
    ],
    btn: [`다시 결제하기`, `Try again`, 's_purchase.fail.btn', `重新结算`, `重新結算`, `もう一度決済する`, `Thử lại`],
    popup_title: [
      `팝업이 차단되어 결제할 수 없습니다. 팝업 허용 후 다시 시도해 주세요.`,
      `Payment could not be completed because pop-ups are blocked by the browser. Please try again after enabling pop-ups.`,
      's_purchase.fail.popup_title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice_1credit: [
    `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    's_purchase.notice_1credit',
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
  ],
  ticket_name: [`상품명`, `Ticket`, 's_purchase.ticket_name', ``, ``, ``, ``],
  ticket_date: [`참여날짜`, `Date`, 's_purchase.ticket_date', ``, ``, ``, ``],
  discount_secret: [
    `시크릿 쿠폰 할인`,
    `Secret Offer`,
    's_purchase.discount_secret',
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
    `Secret Offer`,
  ],
  total_price_card: {
    price: [
      `최종 결제 금액`,
      `Total`,
      's_purchase.total_price_card.price',
      `实际付款金额`,
      `最終付款金額`,
      `お支払い総額`,
      `Tổng cộng`,
    ],
    promo: [
      `프로모션 특가`,
      `Promotional Deal`,
      's_purchase.total_price_card.promo',
      `活动特价`,
      `活動特價`,
      `キャンペーン特価`,
      `Giá đặc biệt`,
    ],
  },
  tosspay: {
    title: [`토스페이 첫 결제 이벤트`, ``, 's_purchase.tosspay.title', ``, ``, ``, ``],
    text_btn: [`유의사항`, ``, 's_purchase.tosspay.text_btn', ``, ``, ``, ``],
    desc_1: [`토스페이로 생애 첫 결제 시 5천원 캐시백`, ``, 's_purchase.tosspay.desc_1', ``, ``, ``, ``],
    desc_2: [`4월 31일 까지, 4000명 선착순`, ``, 's_purchase.tosspay.desc_2', ``, ``, ``, ``],
    modal_title: [`토스페이 이벤트 유의사항`, ``, 's_purchase.tosspay.modal_title', ``, ``, ``, ``],
    modal_desc_1: [
      `결제 시 토스페이 간편결제 선택 시에만 할인이 적용됩니다. (기간 내 ID당 1회)`,
      ``,
      's_purchase.tosspay.modal_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_2: [
      `첫 충전 즉시 캐시백은 토스 전체 가맹점에서 결제 이력이 없는 경우 적용됩니다. (토스 ID당 1회)`,
      ``,
      's_purchase.tosspay.modal_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_3: [
      `결제 금액은 쿠폰 할인 및 적립금 적용 후 실 결제 금액입니다.`,
      ``,
      's_purchase.tosspay.modal_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_4: [
      `할인 혜택은 1개 주문건 당 결제 금액을 기준으로 적용됩니다.`,
      ``,
      's_purchase.tosspay.modal_desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_5: [
      `토스 이외의 간편결제수단 (네이버페이, 차이, 페이코 등) 이용 혹은 신용카드 선택 시, 즉시할인 적용이 불가합니다.`,
      ``,
      's_purchase.tosspay.modal_desc_5',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_6: [
      `결제 전체 취소 후 기간 내 재결제 시 혜택 적용되며, 결제 부분취소 후 재결제 시 혜택 미적용 됩니다.`,
      ``,
      's_purchase.tosspay.modal_desc_6',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_7: [
      `04월 01일부터 04월 31일까지 한정된 고객에게 선착순으로 적용되는 즉시할인 행사로, 조기 종료될 수 있습니다.`,
      ``,
      's_purchase.tosspay.modal_desc_7',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc_8: [
      `행사 내용은 토스 및 링글의 사정으로 중단 또는 변동될 수 있습니다.관련 문의는 토스 고객센터(1599-4905)로 문의 부탁드립니다.`,
      ``,
      's_purchase.tosspay.modal_desc_8',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_purchase_completed = {
  done: [`결제완료`, `Complete`, 's_purchase_completed.done', `付款完成`, `付款成功`, `決済完了`, `Hoàn thành`],
  done_desc: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
      <Div fontBold textD5>
        이제 링글 수업을 시작해보세요!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}用户
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
      </Div>
      <Div fontBold textD5>
        现在就开始您的Ringle课程吧!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
        。
      </Div>
      <Div fontBold textD5>
        現在就開始Ringle課程吧！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}さん
        <br />
        <Div spanTag textPrimary>
          決済が完了
        </Div>
        しました。
      </Div>
      <Div fontBold textD5>
        Ringleのレッスンを始めましょう！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Chào {name}
        <br />
        <Div spanTag textPrimary>
          Chúc mừng bạn!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        Chúng tôi đã nhận được thanh toán của bạn.
      </Div>
    </>,
  ],
  purchase_info: [
    `결제 내역`,
    `Purchase history`,
    's_purchase_completed.purchase_info',
    `结算内容`,
    `付款明細`,
    `決済履歴`,
    `Lịch sử mua hàng`,
  ],
  point_use: [
    `포인트 사용 내역`,
    `Points history`,
    's_purchase_completed.point_use',
    `积分使用内容`,
    `點數使用明細`,
    `ポイント使用履歴`,
    `Lịch sử điểm`,
  ],
  total: [
    `최종 결제 금액`,
    `Your total`,
    's_purchase_completed.total',
    `最终付款金额`,
    `最終付款金額`,
    `最終決済金額`,
    `Tổng cộng`,
  ],
  order: [
    `주문상품`,
    `Your cart`,
    's_purchase_completed.order',
    `订购的商品`,
    `訂購商品`,
    `購入内容`,
    `Giỏ hàng của bạn`,
  ],
  lesson_credit: [
    `수업권명`,
    `Package`,
    's_purchase_completed.lesson_credit',
    `课程券名称`,
    `課程券名稱`,
    `レッスンチケット`,
    `Gói`,
  ],
  lesson_length: [
    `수업길이`,
    `Duration`,
    's_purchase_completed.lesson_length',
    `课时长度`,
    `課程長度`,
    `レッスン時間`,
    `Thời lượng`,
  ],
  minutes: (minute) => [
    `${minute}분`,
    `${minute} min`,
    's_purchase_completed.minutes',
    `${minute}分钟`,
    `${minute}分鐘`,
    `${minute}分`,
    `${minute} phút`,
  ],
  exp_dates: [`시간대`, `Times`, 's_purchase_completed.exp_dates', `时间段`, `時段`, `時間帯`, `Thời gian`],
  time_desc: [
    `수업 시작 기준입니다.`,
    `Lesson starting times`,
    's_purchase_completed.time_desc',
    `以课程开始为准。`,
    `以課程開始時間為準。`,
    `レッスン開始時間`,
    `Thời gian bắt đầu buổi học`,
  ],
  duration: [
    `수강기간`,
    `Expiration dates`,
    's_purchase_completed.duration',
    `课程期限`,
    `聽課期間`,
    `有効期限`,
    `Ngày hết hạn`,
  ],
  duration_desc: [
    `첫 수업시작 전까지 수강기간은 미차감됩니다.`,
    `Calculated from the date you complete your first lesson using the purchased package.`,
    's_purchase_completed.duration_desc',
    `首次课程开始前, 课程期限不被扣除。`,
    `首次課程開始之前，不扣除聽課期間。`,
    `有効期限は初回レッスン受講後から起算されます。`,
    `Tính toán dựa vào ngày bạn hoàn thành buổi học đầu tiên đã thanh toán.`,
  ],
  challenge: [
    `도전기간`,
    `Challenge period`,
    's_purchase_completed.challenge',
    `挑战期间`,
    `挑戰期間`,
    `挑戦期間`,
    `Thời gian thử thách`,
  ],
  challenge_desc: (day, count) => [
    `첫수업 후 ${day}일내 정규수업권 소진 시, +${count}회가 지급됩니다.`,
    `Use all your credits in ${day} days and get +${count} bonus lessons.`,
    's_purchase_completed.challenge_desc',
    `首次课程后${day}日内, 正规课程券全部使用时, 将追加提供+${count}次。`,
    `首次課程結束後，於${day}天內將正規課程券全數使用時，將發送+${count}次。`,
    `初回レッスン後、${day}日以内にレギュラーレッスンチケット消費時、 +${count}回が支給されます。`,
    `Sử dụng tất cả thẻ học trong ${day} ngày và nhận +${count} buổi học tặng thêm.`,
  ],
  receipt_certificate: [
    `영수증/출석증은 PC에서 오른쪽 상단 프로필 > 수업권/증명서류 탭에서 PDF로 다운로드 가능합니다.`,
    `You can download your lesson certificates & proof of attendance under Credits.`,
    's_purchase_completed.receipt_certificate',
    `收据/出席证可在PC右上方简介 > 课程券/证明文件页面中下载PDF文本。`,
    `收據／出席證明可於電腦版右上方的簡介 > 課程券／證明分頁下載PDF檔。`,
    `領収証/出席証はPCの右上のプロフィール > レッスンチケット/証明書類タブからPDFでダウンロード可能です。`,
    `Bạn có thể tải chứng nhận buổi học & chứng nhận tham dự dưới phần Thẻ học.`,
  ],
  new_to_ringle: [
    `링글 수업 전 꼭 확인해 주세요!`,
    `New to Ringle?`,
    's_purchase_completed.new_to_ringle',
    `您是第一次使用Ringle吗?`,
    `如果您是第一次使用Ringle？`,
    `Ringleが初めてなら？`,
    `Lần đầu dùng Ringle?`,
  ],
  go_to_home: [
    `홈으로 가기`,
    `Home`,
    's_purchase_completed.go_to_home',
    `前往主页`,
    `前往首頁`,
    `ホームに移動`,
    `Trang chủ`,
  ],
  vbank_name: [
    `입금 가상 계좌번호`,
    `Virtual account number`,
    's_purchase_completed.vbank_name',
    `Virtual account number`,
    `Virtual account number`,
    `入金仮想口座番号`,
    `Số tài khoản ảo (Virtual account number)`,
  ],
  vbank_date: [
    `입금 기한`,
    `Payment due by`,
    's_purchase_completed.vbank_date',
    `Payment due by`,
    `Payment due by`,
    `入金期間`,
    `Hạn thanh toán`,
  ],
  vbank_title: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        아래 계좌로 입금해 주세요.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below
      </Div>{' '}
    </>,
    's_purchase_completed.vbank_title',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}様 <br /> 下記の口座に入金してください{' '}
      </Div>{' '}
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name} <br /> vui lòng thanh toán theo thông tin bên dưới{' '}
      </Div>{' '}
    </>,
  ],
  existing_customer: [
    `링글 수업, 이렇게도 활용할 수 있어요!`,
    ``,
    's_purchase_completed.existing_customer',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_booking: [
    `수업 예약하기`,
    `Book a lesson`,
    's_purchase_completed.go_to_booking',
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
  ],
  go_to_OT: [
    `OT 신청하기`,
    `Register Orientation`,
    's_purchase_completed.go_to_OT',
    `Register Orientation`,
    `Register Orientation`,
    `Register Orientation`,
    `Register Orientation`,
  ],
  go_to_booking_app: [
    `앱으로 수업 예약하기`,
    `Book a lesson`,
    's_purchase_completed.go_to_booking_app',
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
    `Book a lesson`,
  ],
  go_to_study_week: [
    `영어학습 세미나 신청하기`,
    `Sign up: 
How to Study English Seminar`,
    's_purchase_completed.go_to_study_week',
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
    `Sign up: 
How to Study English Seminar`,
  ],
  email_desc: [
    `컨퍼런스 신청이 완료되었습니다. 자세한 내용은
가입 시 입력하신 이메일로 보내드립니다.`,
    ``,
    's_purchase_completed.email_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`컨퍼런스 페이지로 돌아가기`, `Conference Page`, 's_purchase_completed.btn1', ``, ``, ``, ``],
  btn2: [`링글 알아보기`, `Ringle Home`, 's_purchase_completed.btn2', ``, ``, ``, ``],
  done_desc_conference: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc_conference',
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}用户
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
      </Div>
      <Div fontBold textD5>
        现在就开始您的Ringle课程吧!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}
        <br />
        <Div spanTag textPrimary>
          付款成功
        </Div>
        。
      </Div>
      <Div fontBold textD5>
        現在就開始Ringle課程吧！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}さん
        <br />
        <Div spanTag textPrimary>
          決済が完了
        </Div>
        しました。
      </Div>
      <Div fontBold textD5>
        Ringleのレッスンを始めましょう！
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Chào {name}
        <br />
        <Div spanTag textPrimary>
          Chúc mừng bạn!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        Chúng tôi đã nhận được thanh toán của bạn.
      </Div>
    </>,
  ],
  duration_desc_2: [
    `수업권 결제 직후부터 수강기간 차감이 시작됩니다.`,
    `Credits begin expiring from purchase date.`,
    's_purchase_completed.duration_desc_2',
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
  ],
}
export const s_mypage = {
  ringle_email: [
    `링글 가입 계정 이메일`,
    `Email (Ringle account)`,
    's_mypage.ringle_email',
    `注册Ringle账户的电子邮箱`,
    `註冊Ringle帳戶的電子信箱`,
    `Ringle登録アカウントのメールアドレス`,
    `Email (tài khoản Ringle)`,
  ],
  email2: [
    `추가 이메일`,
    `Secondary email`,
    's_mypage.email2',
    `其他电子邮箱`,
    `其他電子信箱`,
    `追加メールアドレス`,
    `Email thứ 2`,
  ],
  choose_email: [
    `알람 수신용 이메일을 입력하세요.`,
    `Please enter email for notifications.`,
    's_mypage.choose_email',
    `请输入接收提醒的电子邮箱。`,
    `請輸入接收通知的電子信箱。`,
    `通知受信用のメールアドレスを入力してください。`,
    `Nhập email để nhận thông báo.`,
  ],
  can_only_change_email2: [
    `링글 가입 계정 이메일은 변경되지 않으며, 알림 수신 이메일만 변경됩니다.`,
    `We'll send any notifications to this email. Your sign in (account) email won't change.`,
    's_mypage.can_only_change_email2',
    `Ringle账户的注册邮箱不能进行变更, 只能修改接收提醒的邮箱。`,
    `註冊Ringle帳戶的電子信箱將不變動，僅變更接收通知的電子信箱。`,
    `Ringle登録アカウントのメールアドレスは変更されず、通知受信メールアドレスのみ変更されます。`,
    `Chúng tôi sẽ gửi bạn bất kì thông báo nào đến email. Email đăng nhập (tài khoản) của bạn sẽ không thay đổi.`,
  ],
  timezone_desc: [
    `현재 위치와 타임존이 다른 경우, 도시 이름을 입력하거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list to change the time zone.`,
    's_mypage.timezone_desc',
    `当前位置与时区不符时, 请输入城市名称或从列表中选择。`,
    `目前位置與時區不符時，請輸入城市名稱或從清單中選擇。`,
    `現在地とタイムゾーンが異なる場合、都市名を入力またはリストから選択してください。`,
    `Nhập tên thành phố hoặc chọn từ danh sách để đổi múi giờ.`,
  ],
  save: [`저장`, `save`, 's_mypage.save', `保存`, `儲存`, `保存`, `lưu`],
  leave: {
    title: [
      `링글 탈퇴하기`,
      `Delete my account`,
      's_mypage.leave.title',
      `注销Ringle账号`,
      `註銷Ringle會員`,
      `Ringleを退会する`,
      `Xoá tài khoản của tôi`,
    ],
    description: [
      `유의사항에 모두 체크하고, 탈퇴를 누르면  계정 내용이 모두 삭제 됩니다.`,
      `Account information will be deleted when you confirm the terms and delete the account.`,
      's_mypage.leave.description',
      `确认全部注意事项, 点击注销后账户所有内容将被删除。`,
      `勾選所有注意事項並點擊註銷時，將刪除所有帳戶內容。`,
      `注意事項をすべてチェックして退会を押すと、アカウント内容がすべて削除されます。`,
      `Thông tin tài khoản sẽ bị xoá ngay sau khi bạn xác nhận điều khoản và xoá tài khoản.`,
    ],
    important_title: [
      `유의사항`,
      `Terms`,
      's_mypage.leave.important_title',
      `注意事项`,
      `注意事項`,
      `注意事項`,
      `Điều khoản`,
    ],
    important_title_1: [
      `수업권, 포인트 및 쿠폰 모두 삭제`,
      `Deleted accounts cannot be restored.`,
      's_mypage.leave.important_title_1',
      `注销的账户无法撤回。`,
      `無法復原註銷的帳戶。`,
      `退会するアカウントは戻すことができません。`,
      `Tài khoản đã xoá không thể khôi phục.`,
    ],
    important_title_2: [
      `탈퇴 후 수업권 환불 불가`,
      `Remained credits and points will all be deleted.`,
      's_mypage.leave.important_title_2',
      `剩余的课程券及优惠券也将全部删除。`,
      `將刪除所有剩餘的課程券及優惠券。`,
      `残っているレッスンチケットおよびクーポンはすべて削除されます。`,
      `Tất cả những thẻ học còn lại và điểm sẽ bị xoá.`,
    ],
    important_title_3: [
      `피드백 리포트, 수업 내역 등의 학습 기록의 재열람 불가`,
      `All lesson history will be deleted.`,
      's_mypage.leave.important_title_3',
      `全部学习记录将被删除。`,
      `將刪除所有學習紀錄。`,
      `すべての学習記録は削除されます。`,
      `Tất cả lịch sử buổi học sẽ bị xoá.`,
    ],
    important_title_4: [
      `재가입 시 다른 이메일 계정 필요`,
      `You will have to make a new account with a different email address if you want to sign up again.`,
      's_mypage.leave.important_title_4',
      `如果您想重新注册, 需提供其他电子邮箱。`,
      `如欲重新註冊，必須使用其他電子信箱。`,
      `再登録したい場合、他のメールアドレスを使用してください。`,
      `Bạn sẽ phải tạo tài khoản mới với một địa chỉ email khác nếu muốn đăng ký lại.`,
    ],
    submit: [`탈퇴`, `Leave`, 's_mypage.leave.submit', `注销`, `註銷`, `退会`, `Rời khỏi`],
    cancel: [`취소`, `Cancel`, 's_mypage.leave.cancel', `取消`, `取消`, `キャンセル`, `Huỷ`],
  },
  does_not_match: [
    `새 비밀번호와 일치하지 않습니다.`,
    `Password does not match.`,
    's_mypage.does_not_match',
    `与新密码不一致。`,
    `與新密碼不一致。`,
    `新しいパスワードと一致しません。`,
    `Mật khẩu không khớp.`,
  ],
  at_least_six_char: [
    `비밀번호를 6글자 이상 입력해주세요.`,
    `Your password must be at least 6 characters.`,
    's_mypage.at_least_six_char',
    `请输入6位字符以上的密码。`,
    `請輸入6字以上的密碼。`,
    `パスワードを6文字以上入力してください。`,
    `Mật khẩu của bạn cần ít nhất 6 ký tự.`,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_mypage.your_option_change_has_been_completed',
    `变更的内容已保存。`,
    `變更的內容儲存成功。`,
    `変更した内容が保存されました。`,
    `Đã lưu`,
  ],
  please_select_language: [
    `주요 사용 언어를 선택해주세요.`,
    `Please select language.`,
    's_mypage.please_select_language',
    `请选择主要使用语言.`,
    `請選擇主要使用的語言。`,
    `主な使用言語を選択してください。`,
    `Vui lòng chọn ngôn ngữ.`,
  ],
}
export const s_moportal = {
  group_a: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_a.welcome_to_ringle',
      <>
        您好,
        <br />
        尊贵的{name}用户!
      </>,
      <>
        您好，
        <br />
        {name}！
      </>,
      <>
        こんにちは、
        <br />
        {name}さん！{' '}
      </>,
      <>Xin chào {name}!</>,
    ],
    title: [
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>,
      <>
        Learn Real English
        <br />
        with Ringle
      </>,
      's_moportal.group_a.title',
      <>
        赶快开启学习正宗英语的
        <br />
        Ringle课程吧。
      </>,
      <>
        請開始可以學習
        <br />
        真正英語的Ringle課程吧！
      </>,
      <>
        リアルな英語を学ぶことができる
        <br />
        Ringleのレッスンを始めましょう。
      </>,
      <>
        Học tiếng Anh thực tế
        <br />
        với Ringle
      </>,
    ],
  },
  group_b: {
    welcome_to_ringle: [
      <>
        체험 수업은
        <br />
        만족스러우셨나요?
      </>,
      <>How was your trial lesson?</>,
      's_moportal.group_b.welcome_to_ringle',
      <>
        您对体验课程
        <br />
        满足吗?
      </>,
      <>
        您還滿意
        <br />
        體驗課程嗎？
      </>,
      <>
        体験レッスンは
        <br />
        ご満足いただけましたか？
      </>,
      <>Buổi học thử của bạn sao rồi?</>,
    ],
    title: [
      <>
        이제 원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_b.title',
      <>
        现在选择您满意的教师和教材
        <br />
        开始正规课程吧!
      </>,
      <>
        現在就選擇希望的任課教師和教材，
        <br />
        開始進行正規課程吧！
      </>,
      <>
        これからは好きなチューターと教材を選択して
        <br />
        レギュラーレッスンを始めましょう！
      </>,
      <>
        Trong buổi học thông thường bạn có thể
        <br />
        chọn gia sư & tài liệu
      </>,
    ],
    purchase: [
      <>수업권 보러가기</>,
      <>See pricing</>,
      's_moportal.group_b.purchase',
      <>查看课程券</>,
      <>前往查看課程券</>,
      <>レッスンチケットを見る</>,
      <>Xem học phí</>,
    ],
    purchase_sub: [
      <>
        나에게 맞는 수업권을 찾아보기!
        <br />
        심도 깊은 40분, 효율적인 20분
      </>,
      <>
        Find the right package for you
        <br />{' '}
      </>,
      's_moportal.group_b.purchase_sub',
      <>
        查找满足我需求的课程券!
        <br />
        深度解析40分钟, 高效便捷20分钟
      </>,
      <>
        尋找適合我的課程券！
        <br />
        具有深度的40分鐘、充滿效率的20分鐘
      </>,
      <>
        自分に合うレッスンチケットを探しましょう！
        <br />
        深く学べる40分、効率的な20分
      </>,
      <>
        Tìm gói học phù hợp cho bạn
        <br />{' '}
      </>,
    ],
    go_purchase: [
      <>가격 보러가기</>,
      <>See pricing</>,
      's_moportal.group_b.go_purchase',
      <>查看价格</>,
      <>前往查看價格</>,
      <>価格を見る</>,
      <>Xem học phí</>,
    ],
    review: [
      <>AI & 튜터가 분석해주는 복습</>,
      <>Feedback from your tutor & AI</>,
      's_moportal.group_b.review',
      <>经AI & 教师分析的复习内容</>,
      <>AI & 任課教師為您分析的複習</>,
      <>AI & チューターが分析してくれる復習</>,
      <>Đánh giá từ gia sư & AI</>,
    ],
    review_sub: [
      <>
        수업이 끝나도 사라지지 않는 영어,
        <br />
        링글을 끝까지 경험해보세요!
      </>,
      <>Review your lesson</>,
      's_moportal.group_b.review_sub',
      <>
        课程结束后也不会消失的英语
        <br />
        携手Ringle, 坚持到底!
      </>,
      <>
        即使課程結束也不會消失的英語，
        <br />
        攜手Ringle，堅持到底！
      </>,
      <>
        レッスンが終わっても消えない英語、
        <br />
        Ringleを最後までご体験ください！
      </>,
      <>Xem lại buổi học</>,
    ],
    go_review: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_b.go_review',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    how_to_ringle: [
      <>링글 사용법 바로가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.how_to_ringle',
      <>前往Ringle使用方法</>,
      <>立即前往Ringle使用方法</>,
      <>Ringleの使用方法を見る</>,
      <>Cách Ringle hoạt động</>,
    ],
    how_to_ringle_sub: [
      <>언제 어디서나, 원하는 수업을 자유롭게</>,
      <>Learn whenever and wherever</>,
      's_moportal.group_b.how_to_ringle_sub',
      <>自由进行希望的课程</>,
      <>隨時隨地自由進行想要的課程</>,
      <>いつでもどこでも、好きなレッスンを自由に</>,
      <>Học bất cứ khi nào và bất cứ nơi đâu</>,
    ],
    go_how_to_ringle: [
      <>간단한 사용법 보러가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.go_how_to_ringle',
      <>查看便捷使用方法</>,
      <>前往查看簡單的使用方法</>,
      <>簡単な使用方法を見る</>,
      <>Cách Ringle hoạt động</>,
    ],
  },
  group_c: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_c.welcome_to_ringle',
      <>
        您好,
        <br />
        尊贵的{name}用户!
      </>,
      <>
        您好，
        <br />
        {name}！
      </>,
      <>
        こんにちは、
        <br />
        {name}さん！{' '}
      </>,
      <>Xin chào {name}!</>,
    ],
    title: [
      <>
        링글 앱을 사용하면
        <br />
        손쉽게 수업을 예습하고 복습할 수 있어요!
      </>,
      <>Prep for your lessons via Ringe App!</>,
      's_moportal.group_c.title',
      <>
        使用Ringle应用程序,
        <br />
        可轻松对课程进行预习及复习!
      </>,
      <>
        使用Ringle APP的話，
        <br />
        可輕鬆預習及複習課程！
      </>,
      <>
        Ringleアプリを使用すると
        <br />
        手軽にレッスンを予習して復習することができます！
      </>,
      <>Chuẩn bị cho buổi học với ứng dụng Ringle!</>,
    ],
    schedule: [
      <>수업 예약하기</>,
      <>Book Lessons</>,
      's_moportal.group_c.schedule',
      <>课程预约</>,
      <>預約課程</>,
      <>レッスンを予約する</>,
      <>Đặt lịch học</>,
    ],
    schedule_sub: [
      <>
        원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_c.schedule_sub',
      <>
        选择自己满意的教师及教材
        <br />
        开始正规课程吧!
      </>,
      <>
        選擇希望的任課教師和教材，
        <br />
        開始進行正規課程吧！
      </>,
      <>
        好きなチューターと教材を選択して
        <br />
        レギュラーレッスンを始めましょう！
      </>,
      <>
        Trong buổi học thông thường bạn có thể
        <br />
        chọn gia sư & tài liệu
      </>,
    ],
    go_schedule: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_schedule',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    tutor: [
      <>튜터 프로필 보러가기</>,
      <>View Tutors</>,
      's_moportal.group_c.tutor',
      <>查看教师简介</>,
      <>前往查看任課教師簡介</>,
      <>チュータープロフィールを見る</>,
      <>Xem gia sư</>,
    ],
    tutor_sub: [
      <>
        1000+명 이상 검증된 <span className="text-purple-500">영미권 명문대 원어민 튜터</span>를 만나보세요!
      </>,
      <>Choose from 1000+ Tutors!</>,
      's_moportal.group_c.tutor_sub',
      <>
        1000名以上, 出身享誉全球的英美名校
        <br />
        携手母语教师一起学习吧!
      </>,
      <>
        與1000名以上眾所公認的英美名校
        <br />
        母語任課教師相見歡吧！
      </>,
      <>
        1000+名以上の検証された英米圏の名門大学の
        <br />
        ネイティブチューターをチェックしましょう！
      </>,
      <>Chọn từ 1000+ gia sư!</>,
    ],
    go_tutor: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_tutor',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
    materials: [
      <>링글 교재 보러가기</>,
      <>View Materials</>,
      's_moportal.group_c.materials',
      <>查看Ringle教材</>,
      <>前往查看Ringle教材</>,
      <>Ringleの教材を見る</>,
      <>Xem Tài liệu</>,
    ],
    materials_sub: [
      <>일상 대화부터 시사, 실전 비즈니스 영어까지</>,
      <>Master your English from casual to advanced.</>,
      's_moportal.group_c.materials_sub',
      <>从日常生活到时事, 实战商务英语</>,
      <>從日常生活到時事、實戰商務英語</>,
      <>日常会話から時事、実践ビジネス英語まで</>,
      <>Thành thạo khả năng tiếng Anh của bạn dù giao tiếp đời thường hay nâng cao.</>,
    ],
    go_materials: [
      <>앱에서 확인하기</>,
      <>View on Ringle App</>,
      's_moportal.group_c.go_materials',
      <>前往应用程序中确认</>,
      <>於APP確認</>,
      <>アプリで確認する</>,
      <>Xem trên ứng dụng Ringle</>,
    ],
  },
}
export const s_web_auth = {
  verify: [`인증`, `Verify`, 's_web_auth.verify', `验证`, `驗證`, `認証`, `Xác minh`],
}
export const t_dashboard = {
  credit: [`수업권 관리`, `Plans & Credits`, 't_dashboard.credit', ``, ``, ``, ``],
  child_account_settings: [`자녀 계정 관리`, `Child Account`, 't_dashboard.child_account_settings', ``, ``, ``, ``],
  my_account_settings: [`내 계정관리`, `My Account`, 't_dashboard.my_account_settings', ``, ``, ``, ``],
  dashboard: [`대시보드`, `Dashboard`, 't_dashboard.dashboard', ``, ``, ``, ``],
  parent: [`학부모`, `Parent`, 't_dashboard.parent', ``, ``, ``, ``],
  child_password: [`자녀 비밀번호 변경`, `Change Password`, 't_dashboard.child_password', ``, ``, ``, ``],
  child_timezone: [`자녀 타임존 변경`, `Change Timezone`, 't_dashboard.child_timezone', ``, ``, ``, ``],
  child_profile_title: [`자녀 프로필 설정`, `Profile`, 't_dashboard.child_profile_title', ``, ``, ``, ``],
  child_notification: [
    `자녀 알림 수신 정보`,
    `Notification Settings`,
    't_dashboard.child_notification',
    ``,
    ``,
    ``,
    ``,
  ],
  child_basic_info: [`자녀 기본 정보`, `Student profiles`, 't_dashboard.child_basic_info', ``, ``, ``, ``],
  go_to_study_place: [`학습공간 가기`, `Enter Learning Space`, 't_dashboard.go_to_study_place', ``, ``, ``, ``],
  validate_year: [
    `태어난 년도를 정확하게 입력해주세요.`,
    `Please enter the year you were born in.`,
    't_dashboard.validate_year',
    ``,
    ``,
    ``,
    ``,
  ],
  validate_month: [
    `태어난 월을 정확하게 입력해주세요.`,
    `Please enter the month you were born in.`,
    't_dashboard.validate_month',
    ``,
    ``,
    ``,
    ``,
  ],
  validate_day: [
    `태어난 일을 정확하게 입력해주세요.`,
    `Please enter the day you were born on.`,
    't_dashboard.validate_day',
    ``,
    ``,
    ``,
    ``,
  ],
  menu: {
    home: [`홈`, `Home`, 't_dashboard.menu.home', ``, ``, ``, ``],
    plans_credits: {
      default: [`수업권`, `Credits`, 't_dashboard.menu.plans_credits.default', ``, ``, ``, ``],
      myplans: [`내 요금제`, `My Plans`, 't_dashboard.menu.plans_credits.myplans', ``, ``, ``, ``],
      credits_history: [
        `크레딧 & 기록`,
        `Credits & History`,
        't_dashboard.menu.plans_credits.credits_history',
        ``,
        ``,
        ``,
        ``,
      ],
      points: [`포인트`, `Points`, 't_dashboard.menu.plans_credits.points', ``, ``, ``, ``],
    },
    child_account_settings: [
      `자녀 계정 관리`,
      `Child Account`,
      't_dashboard.menu.child_account_settings',
      ``,
      ``,
      ``,
      ``,
    ],
    my_account_settings: [`내 계정관리`, `My Account`, 't_dashboard.menu.my_account_settings', ``, ``, ``, ``],
    banner: {
      title: [
        <>
          교재를 선택하는데 도움이
          <br />
          필요하신가요?
        </>,
        `Can we assist you in choosing the right materials?`,
        't_dashboard.menu.banner.title',
        ``,
        ``,
        ``,
        ``,
      ],
      button: [`가이드 보기`, `View guide`, 't_dashboard.menu.banner.button', ``, ``, ``, ``],
    },
  },
  navi: {
    dashboard: [`대시보드`, `Dashboard`, 't_dashboard.navi.dashboard', ``, ``, ``, ``],
    buynow: [`구매`, `Buy now`, 't_dashboard.navi.buynow', ``, ``, ``, ``],
    profilemenu: {
      title1: [`학부모 대시보드`, `Parent Dashboard`, 't_dashboard.navi.profilemenu.title1', ``, ``, ``, ``],
      title2: [`자녀 계정`, `Child Account`, 't_dashboard.navi.profilemenu.title2', ``, ``, ``, ``],
      plans: [`요금제 & 지불방식`, `Plans & Payment`, 't_dashboard.navi.profilemenu.plans', ``, ``, ``, ``],
      noti: [`알림 수신 설정`, `Notification Settings`, 't_dashboard.navi.profilemenu.noti', ``, ``, ``, ``],
      faq: [`자주 묻는 질문`, `FAQ`, 't_dashboard.navi.profilemenu.faq', ``, ``, ``, ``],
      installzoom: [
        `화상 프로그램 (Zoom) 설치 가이드`,
        `How to Install Zoom`,
        't_dashboard.navi.profilemenu.installzoom',
        ``,
        ``,
        ``,
        ``,
      ],
      logout: [`로그아웃`, `Log out`, 't_dashboard.navi.profilemenu.logout', ``, ``, ``, ``],
    },
  },
  credits_payment: {
    title: [`수업권 & 포인트`, `Credits & Points`, 't_dashboard.credits_payment.title', ``, ``, ``, ``],
    credits1: {
      default: [`정규수업권`, `Regular`, 't_dashboard.credits_payment.credits1.default', ``, ``, ``, ``],
      inform: [
        `수업 시작 24시간 이전/이내 수업
모두 예약 가능한 수업권입니다.`,
        <>
          You can book any lessons available
          <br />
          (starting before or after 24 hours from now).
        </>,
        't_dashboard.credits_payment.credits1.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    credits2: {
      default: [`보충수업권`, `Make-up`, 't_dashboard.credits_payment.credits2.default', ``, ``, ``, ``],
      inform: [
        `수업 시작 24시간 이내 수업만 예약 가능한
수업권입니다. [예약 > 24시간 이내 수업]`,
        <>
          You can only book lessons starting within the next
          <br />
          24 hours on [1:1 Lessons] &gt; [Lesson in 24 hours]
        </>,
        't_dashboard.credits_payment.credits2.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    button1: [`자세히 보기`, `View detail`, 't_dashboard.credits_payment.button1', ``, ``, ``, ``],
    button2: [`결제방식`, `Manage payment`, 't_dashboard.credits_payment.button2', ``, ``, ``, ``],
    nextpayment: [`다음 결제일은`, `Next payment on`, 't_dashboard.credits_payment.nextpayment', ``, ``, ``, ``],
    card: [`비자카드 끝자리`, `Visa card Ending`, 't_dashboard.credits_payment.card', ``, ``, ``, ``],
    startpayment: {
      default: [
        `월간 요금제 시작`,
        `Start a Monthly Plan`,
        't_dashboard.credits_payment.startpayment.default',
        ``,
        ``,
        ``,
        ``,
      ],
      inform: [
        `요금제 선택하고 링글 틴즈 수업 듣기`,
        `Choose a plan to begin Ringle Lessons`,
        't_dashboard.credits_payment.startpayment.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  book_lesson: {
    title: [`수업 예약`, `Book Lessons`, 't_dashboard.book_lesson.title', ``, ``, ``, ``],
    sortbytime: {
      title: [`시간 먼저 선택`, `Sort by time`, 't_dashboard.book_lesson.sortbytime.title', ``, ``, ``, ``],
      infom: [
        `원하는 수업 시간을 먼저 선택할 수 있습니다.`,
        `See available tutors for selected time`,
        't_dashboard.book_lesson.sortbytime.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    sortbytutor: {
      title: [`튜터 먼저 선택`, `Sort by tutor`, 't_dashboard.book_lesson.sortbytutor.title', ``, ``, ``, ``],
      infom: [
        `원하는 튜터를 먼저 선택할 수 있습니다.`,
        `See available slots of selected tutors`,
        't_dashboard.book_lesson.sortbytutor.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    sameday: {
      title: [`예약 가능한 당일수업`, `Last-min Lesson`, 't_dashboard.book_lesson.sameday.title', ``, ``, ``, ``],
      infom: [
        `24시간 이내에 시작하는 수업을 예약할 수 있습니다.`,
        `Book with Regular, Last-minute lesson credits`,
        't_dashboard.book_lesson.sameday.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    banner: {
      title: [
        `교재를 선택하는데 도움이 필요하신가요?`,
        `Need help choosing materials?`,
        't_dashboard.book_lesson.banner.title',
        ``,
        ``,
        ``,
        ``,
      ],
      button: [`가이드 보기`, `View guide`, 't_dashboard.book_lesson.banner.button', ``, ``, ``, ``],
    },
  },
  upcoming_lesson: {
    title: [`예정된 수업`, `Upcoming Lesson`, 't_dashboard.upcoming_lesson.title', ``, ``, ``, ``],
    viewall: [`전체보기`, `View all`, 't_dashboard.upcoming_lesson.viewall', ``, ``, ``, ``],
    no_lesson: [
      `현재 예정되어 있는 수업이 없습니다.`,
      `You have no upcoming lessons.`,
      't_dashboard.upcoming_lesson.no_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  past_lesson: {
    title: [`지난 수업`, `Past Lesson`, 't_dashboard.past_lesson.title', ``, ``, ``, ``],
    viewall: [`전체보기`, `View all`, 't_dashboard.past_lesson.viewall', ``, ``, ``, ``],
    no_lesson: [
      `지난 수업 기록이 없습니다`,
      `You have no past lesson history.`,
      't_dashboard.past_lesson.no_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  upcoming_lesson_page: {
    title: [`예정된 수업`, `Upcoming Lessons`, 't_dashboard.upcoming_lesson_page.title', ``, ``, ``, ``],
    button1: [`더보기`, `See more`, 't_dashboard.upcoming_lesson_page.button1', ``, ``, ``, ``],
  },
  past_lesson_page: {
    title: [`지난 수업`, `Past Lessons`, 't_dashboard.past_lesson_page.title', ``, ``, ``, ``],
    button1: [`더보기`, `See more`, 't_dashboard.past_lesson_page.button1', ``, ``, ``, ``],
    dropdown: {
      all: [`전체`, `All`, 't_dashboard.past_lesson_page.dropdown.all', ``, ``, ``, ``],
      list_1: [
        `복습을 완료하지 않은 수업`,
        `Lessons you haven’t reviewed yet`,
        't_dashboard.past_lesson_page.dropdown.list_1',
        ``,
        ``,
        ``,
        ``,
      ],
      list_2: [
        `복습을 완료한 수업`,
        `Lessons you have reviewed`,
        't_dashboard.past_lesson_page.dropdown.list_2',
        ``,
        ``,
        ``,
        ``,
      ],
      list_3: [`노쇼`, `No-show`, 't_dashboard.past_lesson_page.dropdown.list_3', ``, ``, ``, ``],
    },
  },
  plans_credits: {
    title: [`요금제 & 크레딧`, `Plans & Credits`, 't_dashboard.plans_credits.title', ``, ``, ``, ``],
    my_plans: [`내 요금제`, `My Plans`, 't_dashboard.plans_credits.my_plans', ``, ``, ``, ``],
    credits_history: [
      `크레딧 & 기록`,
      `Credits & History`,
      't_dashboard.plans_credits.credits_history',
      ``,
      ``,
      ``,
      ``,
    ],
    points: [`포인트`, `Points`, 't_dashboard.plans_credits.points', ``, ``, ``, ``],
  },
  materials_banner: {
    title: [
      <>
        적합한 교재를 선택하는데 도움이
        <br />
        필요하신가요?
      </>,
      `Can we assist you in choosing the right materials?`,
      't_dashboard.materials_banner.title',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [`가이드 보기`, `View guide`, 't_dashboard.materials_banner.button', ``, ``, ``, ``],
  },
  add_child: {
    step1: {
      exit: [`나가기`, `Exit`, 't_dashboard.add_child.step1.exit', ``, ``, ``, ``],
      page_title: [`자녀계정 추가`, `Add Child`, 't_dashboard.add_child.step1.page_title', ``, ``, ``, ``],
      page_subtitle: [
        `STEP 1. 자녀 계정`,
        `STEP 1. Child Account`,
        't_dashboard.add_child.step1.page_subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      title: [`자녀 계정 생성`, `Create your child account`, 't_dashboard.add_child.step1.title', ``, ``, ``, ``],
      desc1: [`자녀 이름`, `Child’s Name`, 't_dashboard.add_child.step1.desc1', ``, ``, ``, ``],
      desc2: [`자녀 생년월일`, `Child’s Birthday`, 't_dashboard.add_child.step1.desc2', ``, ``, ``, ``],
      desc3: [`타임존`, `Time Zone`, 't_dashboard.add_child.step1.desc3', ``, ``, ``, ``],
      placeholder1: [`이름`, `First Name`, 't_dashboard.add_child.step1.placeholder1', ``, ``, ``, ``],
      placeholder2: [`성`, `Last Name`, 't_dashboard.add_child.step1.placeholder2', ``, ``, ``, ``],
      placeholder3: [
        `자녀계정 이메일 (ID)`,
        `Email (ID) for child’s account`,
        't_dashboard.add_child.step1.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder4: [
        `자녀 계정 비밀번호 (6자 이상)`,
        `Password for child’s account (At least 6 characters)`,
        't_dashboard.add_child.step1.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `비밀번호를 한번 더 입력해주세요`,
        `Re-enter password`,
        't_dashboard.add_child.step1.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
      mm: [`달`, `mm`, 't_dashboard.add_child.step1.mm', ``, ``, ``, ``],
      dd: [`일`, `dd`, 't_dashboard.add_child.step1.dd', ``, ``, ``, ``],
      yyyy: [`연도`, `yyyy`, 't_dashboard.add_child.step1.yyyy', ``, ``, ``, ``],
      title2: [
        `자녀가 직접 로그인을 할 수 있도록 하고 싶으신가요?`,
        `Do you wish your child to have their own login credentials?`,
        't_dashboard.add_child.step1.title2',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle2: [
        <>
          학생은 본인의 계정이 없더라도 부모의 계정으로
          <br />
          링글 틴즈에 로그인할 수 있습니다.
        </>,
        <>
          Your child(s) can access Ringle Teens using a parent’s login
          <br />
          credentials without their own login credentials.
        </>,
        't_dashboard.add_child.step1.subtitle2',
        ``,
        ``,
        ``,
        ``,
      ],
      radio1: [`예`, `Yes`, 't_dashboard.add_child.step1.radio1', ``, ``, ``, ``],
      radio2: [`아니오`, `No`, 't_dashboard.add_child.step1.radio2', ``, ``, ``, ``],
      msg1: [
        `올바른 이메일을 입력해주세요`,
        `Please enter email correctly`,
        't_dashboard.add_child.step1.msg1',
        ``,
        ``,
        ``,
        ``,
      ],
      msg2: [
        `비밀번호는 6자 이상 입력해주세요`,
        `Your password must be at least 6 characters.`,
        't_dashboard.add_child.step1.msg2',
        ``,
        ``,
        ``,
        ``,
      ],
      msg3: [
        `비밀번호를 인증해주세요`,
        `Please confirm your password`,
        't_dashboard.add_child.step1.msg3',
        ``,
        ``,
        ``,
        ``,
      ],
      tooltip: [
        <>
          이후에도 학부모 대시보드에서 자녀 계정을 추가하실 수 있습니다.
          <br />
          단, 한 부모 당 한개의 무료 수업권이 지급된다는 점을 유의해주세요.
        </>,
        <>
          You can add another child’s account later in the dashboard.
          <br />
          But be sure that only one free lesson is available per parent.
        </>,
        't_dashboard.add_child.step1.tooltip',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    step2: {
      toast: [
        `자녀 계정이 생성되었습니다`,
        `Child account was successfully created!`,
        't_dashboard.add_child.step2.toast',
        ``,
        ``,
        ``,
        ``,
      ],
      title: (name) => [
        `${name}에 대해 더 자세히 알려주세요`,
        `Tell us more about ${name}`,
        't_dashboard.add_child.step2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle1: (name) => [
        <>
          이 질문들을 통해 링글 틴즈의 튜터들은
          <br />
          {name}을(를) 위해 더 효과적인 수업을 진행할 수 있습니다.
        </>,
        <>
          These questions will help our tutors better understand {name}
          <br />
          so they can conduct more efficient lessons.
        </>,
        't_dashboard.add_child.step2.subtitle1',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle2: [
        `입력한 답변은 이후에 대시보드에서 수정하실 수 있습니다`,
        `You can edit your answers later in the dashboard.`,
        't_dashboard.add_child.step2.subtitle2',
        ``,
        ``,
        ``,
        ``,
      ],
      question1: (name) => [
        `${name}에게 영어가 모국어인가요?`,
        `Is English ${name}’s primary language?`,
        't_dashboard.add_child.step2.question1',
        ``,
        ``,
        ``,
        ``,
      ],
      question2: (name) => [
        <>
          {name}이 링글 틴즈를 통해
          <br />
          얻어가길 바라는 것은 무엇인가요? (복수 선택 가능)
        </>,
        <>
          What do you hope for {name} to accomplish using
          <br /> Ringle Teens? (multiple choice)
        </>,
        't_dashboard.add_child.step2.question2',
        ``,
        ``,
        ``,
        ``,
      ],
      question3: (name) => [
        <>
          {name}에게 딱 맞는 수업 교재를 추천할 수 있도록
          <br />
          주제를 선택해주세요 (세개 이상)
        </>,
        <>
          Would you select topics for us to recommend
          <br />
          {name}’s lesson materials? (3 and more)
        </>,
        't_dashboard.add_child.step2.question3',
        ``,
        ``,
        ``,
        ``,
      ],
      radio1: [`예`, `Yes`, 't_dashboard.add_child.step2.radio1', ``, ``, ``, ``],
      radio2: [`아니오`, `No`, 't_dashboard.add_child.step2.radio2', ``, ``, ``, ``],
      checkbox1: [`비판적 사고 능력`, `Critical Thinking`, 't_dashboard.add_child.step2.checkbox1', ``, ``, ``, ``],
      checkbox2: [`창의적 사교 능력`, `Creative Thinking`, 't_dashboard.add_child.step2.checkbox2', ``, ``, ``, ``],
      checkbox3: [`소통 능력`, `Communication`, 't_dashboard.add_child.step2.checkbox3', ``, ``, ``, ``],
      checkbox4: [`집중력`, `Concentration`, 't_dashboard.add_child.step2.checkbox4', ``, ``, ``, ``],
      btn1: [`완료하기`, `Complete`, 't_dashboard.add_child.step2.btn1', ``, ``, ``, ``],
    },
    modal_title: [
      `자녀 계정 생성 그만두기`,
      `Exit Adding Child Account`,
      't_dashboard.add_child.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc: [
      `자녀 계정 추가하기를 완료하지 않으셨습니다. 지금 그만두시면 현재까지 입력한 정보가 저장되지 않습니다. 나가시겠습니까?`,
      `You haven’t completed adding your child’s account yet. All information entered will be deleted if you exit now. Are you sure you want to exit?`,
      't_dashboard.add_child.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`가입 계속하기`, `Keep Signing Up`, 't_dashboard.add_child.modal_btn1', ``, ``, ``, ``],
    modal_btn2: [`나가기`, `Exit`, 't_dashboard.add_child.modal_btn2', ``, ``, ``, ``],
    toast_complete: [
      `자녀 계정이 추가되었습니다`,
      `Adding child was successfully completed`,
      't_dashboard.add_child.toast_complete',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  book_lwt: {
    title: [`Let’s Write Together`, `Let's Write Together`, 't_dashboard.book_lwt.title', ``, ``, ``, ``],
    btn1: [`더 알아보기`, `Learn More`, 't_dashboard.book_lwt.btn1', ``, ``, ``, ``],
    btn2: [`수업 신청하기`, `Schedule Lesson`, 't_dashboard.book_lwt.btn2', ``, ``, ``, ``],
    modal_title: [
      `Let’s Write Together란 무엇일까요?`,
      `Let's Write Together`,
      't_dashboard.book_lwt.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_body: [
      `링글 틴즈의 심화 Writing 프로그램으로 한 튜터와 40분 수업을 3번 진행하며 한 편의 글을 함께 완성하는 수업입니다.`,
      `Let’s Write Together is a writing program for students to write an essay with a tutor during the course of three 40-minute lessons.`,
      't_dashboard.book_lwt.modal_body',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`닫기`, `Close`, 't_dashboard.book_lwt.modal_btn1', ``, ``, ``, ``],
    modal_btn2: [`더 알아보기`, `Learn More`, 't_dashboard.book_lwt.modal_btn2', ``, ``, ``, ``],
  },
  lesson_lwt: {
    modal_title: [
      `수업 변경/취소`,
      `Reschedule or Cancel Lessons`,
      't_dashboard.lesson_lwt.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_body: [
      <>
        Let's Write Together 수업은 현재 소수의 튜터들에 의해서만 진행되기 때문에 실시간으로 수업의 일정을 변경하거나
        취소가 어렵습니다. 수업 일정 변경 또는 취소가 링글팀에 연락해주세요. (응답 가능 시간: 한국 시간 월-금
        10:00-18:00시)
        <br />
        <br />
        전화번호: +82 10-4599-3431
        <br />
        카카오톡 ID: http://pf.kakao.com/_xdxcPCxj
      </>,
      <>
        Because Let’s Write Together is a new program with a smaller selection of tutors, real time lesson rescheduling
        and cancellations are currently restricted. If you need to reschedule or cancel your lesson, please contact us
        by phone or KakaoTalk. (Business hours: Mon.-Fri. 10:00-18:00 KST)
        <br />
        <br />
        Phone: +82 10-4599-3431
        <br />
        KakaoTalk ID: http://pf.kakao.com/_xdxcPCxj
      </>,
      't_dashboard.lesson_lwt.modal_body',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`확인`, `Ok`, 't_dashboard.lesson_lwt.modal_btn1', ``, ``, ``, ``],
  },
  child_s: [`의`, `’s`, 't_dashboard.child_s', ``, ``, ``, ``],
  credits_points: {
    title: [`수업권·포인트`, `Credits & Points`, 't_dashboard.credits_points.title', ``, ``, ``, ``],
    placeholder: [
      `수업권을 구매하고 수업을 시작하세요!`,
      `Buy credits to begin Ringle Lessons!`,
      't_dashboard.credits_points.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`수업권 구매하기`, `Go to Purchase`, 't_dashboard.credits_points.btn', ``, ``, ``, ``],
    point: [`보유 포인트`, `Your points`, 't_dashboard.credits_points.point', ``, ``, ``, ``],
    point_expire: [
      `30일 이내 만료 예정 포인트`,
      `Points expires in 30 days`,
      't_dashboard.credits_points.point_expire',
      ``,
      ``,
      ``,
      ``,
    ],
    view_detail: [`자세히 보기`, `View detail`, 't_dashboard.credits_points.view_detail', ``, ``, ``, ``],
  },
}
export const t_my_original = {
  taught_material: [`수업한 교재`, `Learned Materials`, 't_my_original.taught_material', ``, ``, ``, ``],
  favorite_material: [
    `찜한 교재`,
    `Favorite Materials`,
    't_my_original.favorite_material',
    ``,
    ``,
    ``,
    `Tài liệu Yêu thích`,
  ],
  my_materials: [`내 교재`, `My Materials`, 't_my_original.my_materials', ``, ``, ``, ``],
  check_exp_course: [
    <>
      내가 수업한 교재를 <br /> 여기서 확인할 수 있습니다.
    </>,
    `See your previous materials.`,
    't_my_original.check_exp_course',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
}
export const t_menu = {
  parent_dashboard: [`부모 대시보드`, `Parent Dashboard`, 't_menu.parent_dashboard', ``, ``, ``, ``],
  child_space: [`자녀 학습공간`, `Student Learning Space`, 't_menu.child_space', ``, ``, ``, ``],
  landing: {
    book_a_call: [`상담 신청`, `Book a call`, 't_menu.landing.book_a_call', ``, ``, ``, ``],
  },
  change_language: [`언어 변경`, `Change Language`, 't_menu.change_language', ``, ``, ``, ``],
}
export const t_home = {
  todo_book_lesson: [
    `수업을 예약하고 영어 실력을 키워보아요! 💪`,
    `Book a Lesson and develop your 4Cs!💪`,
    't_home.todo_book_lesson',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  todo_all_done: [
    <>
      최고!
      <br />
      해야할 일을 모두 끝냈어요! 🥳{' '}
    </>,
    <>
      Woohoo!
      <br />
      You're done for today! 🥳{' '}
    </>,
    't_home.todo_all_done',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  recommended_tutor: [`추천 튜터`, `Recommended Tutor`, 't_home.recommended_tutor', ``, ``, ``, ``],
  check_out_tutors_recommended_by_ringle_users_: [
    `링글 틴즈 수강생이 추천하는 튜터를 확인해 보세요.`,
    `Check out tutors recommended by Ringle Teens users.`,
    't_home.check_out_tutors_recommended_by_ringle_users_',
    `请确认Ringle学员们推荐的教师。`,
    `請確認Ringle學生推薦的任課教師。`,
    `Ringle受講生がおすすめするチューターをチェックしてみましょう。`,
    `Xem thử những gia sư được Ringle giới thiệu.`,
  ],
}
export const t_todo = {
  all_todo_list: [`앞으로의 모든 학습 리스트입니다`, `Here are your to-do's.`, 't_todo.all_todo_list', ``, ``, ``, ``],
  prep_due: [`예습 Due`, `Lesson prep due`, 't_todo.prep_due', ``, ``, ``, ``],
  lesson_start_time: [`수업 시작 시간`, `Lesson schedule`, 't_todo.lesson_start_time', ``, ``, ``, ``],
  homework_due: [`숙제 Due`, `Homework due`, 't_todo.homework_due', ``, ``, ``, ``],
  after_1_hour: [`수업이 끝나고 1시간 후`, `An hour after Lesson`, 't_todo.after_1_hour', ``, ``, ``, ``],
  review_due: [`복습 Due`, `Review due`, 't_todo.review_due', ``, ``, ``, ``],
  after_7_days: [`수업이 끝나고 7일 후`, `7 days after Lesson`, 't_todo.after_7_days', ``, ``, ``, ``],
  complete: (n) => [<>완료 ({n}개)</>, <>{n} completed</>, 't_todo.complete', ``, ``, ``, ``],
  incomplete: (n) => [<>미완료 ({n}개)</>, <>{n} not completed</>, 't_todo.incomplete', ``, ``, ``, ``],
}
export const t_homework = {
  submit_homework_and_check_corrections: [
    <>
      <b>숙제 페이지</b>에서는 숙제의 확인 및 제출, <br />
      그리고 제출한 숙제에 대한 튜터의 교정안을 확인할 수 있습니다.
    </>,
    `Submit homework and check the tutor's corrections at the homework tab`,
    't_homework.submit_homework_and_check_corrections',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  past_due: [
    <>
      제출 기한이
      <br />
      지났습니다
    </>,
    `Submission deadline passed`,
    't_homework.past_due',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  wait_tutors_paraphrasing: [
    <>
      튜터의 교정안을
      <br />
      기다려주세요
    </>,
    `Waiting for tutor's correction`,
    't_homework.wait_tutors_paraphrasing',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  read_tutors_paraphrasing: [
    <>
      튜터의 교정안을
      <br />
      읽어보세요
    </>,
    `Check tutor's correction`,
    't_homework.read_tutors_paraphrasing',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  tutor_paraphrasing: [`튜터의 교정안`, `Tutor's correction`, 't_homework.tutor_paraphrasing', ``, ``, ``, ``],
  my_answer: [`나의 답변`, `My Answer`, 't_homework.my_answer', ``, ``, ``, ``],
  check_tutor_paraphrasing: [
    `튜터 교정안 확인하기 완료`,
    <>
      Done checking
      <br />
      Tutor's correction
    </>,
    't_homework.check_tutor_paraphrasing',
    ``,
    ``,
    ``,
    ``,
  ],
  not_submitted: [`미제출`, `Not submitted`, 't_homework.not_submitted', ``, ``, ``, ``],
  submit_before: [`제출 전`, `Before submission`, 't_homework.submit_before', ``, ``, ``, ``],
  submit_completed: [`제출 완료`, `Submission complete`, 't_homework.submit_completed', ``, ``, ``, ``],
  tutor_paraphrasing2: [`튜터 교정안`, `Tutor's correction`, 't_homework.tutor_paraphrasing2', ``, ``, ``, ``],
  paraphrasing_time: [`교정 일시`, `Correction Date`, 't_homework.paraphrasing_time', ``, ``, ``, ``],
  my_answer_placeholder: (min_word_count, max_word_count) => [
    <>
      답변을 최소 {min_word_count}단어, 최대 {max_word_count}단어 이내로 작성해주세요.
    </>,
    <>
      Please respond in {min_word_count} to {max_word_count} words
    </>,
    't_homework.my_answer_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  submit_due: [`제출 기한`, `Submission Deadline`, 't_homework.submit_due', ``, ``, ``, ``],
  remaintime_until_due: (remainTime) => [
    <Div>
      제출까지
      <br />
      <Div spanTag textPrimary fontBold>
        {remainTime}분
      </Div>{' '}
      남았습니다
    </Div>,
    `${remainTime} left until submission deadline`,
    't_homework.remaintime_until_due',
    <Div></Div>,
    <Div></Div>,
    <Div></Div>,
    <Div></Div>,
  ],
  hw_submittted: [`숙제를 제출했습니다`, `Submission complete`, 't_homework.hw_submittted', ``, ``, ``, ``],
  temp_saved: [`임시 저장 되었습니다.`, `Draft saved`, 't_homework.temp_saved', ``, ``, ``, ``],
  submit_hw: [`숙제 제출하기`, `Submit homework`, 't_homework.submit_hw', ``, ``, ``, ``],
  sure_final_submit: [
    <>
      숙제 제출 후에는 수정이 불가능합니다.
      <br />
      제출하시겠습니까?
    </>,
    `Homework cannot be edited after submission. Would you like to submit?`,
    't_homework.sure_final_submit',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  see_prep_btn: [`예습 보기`, `See Lesson Prep`, 't_homework.see_prep_btn', ``, ``, ``, ``],
  checked_tutor_para: [
    `튜터의 교정을 확인하였습니다.`,
    `Done checking Tutor's correction`,
    't_homework.checked_tutor_para',
    ``,
    ``,
    ``,
    ``,
  ],
  want_to_temp_save: [
    `지금까지 작성한 답변을 임시 저장하시겠습니까? 저장하지 않으면 작성한 답변이 사라집니다.`,
    `Do you want to save a draft of your response? Otherwise, your answer will not be restored.`,
    't_homework.want_to_temp_save',
    ``,
    ``,
    ``,
    ``,
  ],
  hw_question: [`숙제 질문`, `Homework question`, 't_homework.hw_question', ``, ``, ``, ``],
  temp_save: [`임시 저장`, `Save draft`, 't_homework.temp_save', ``, ``, ``, ``],
  submit: [`제출하기`, `Submit`, 't_homework.submit', ``, ``, ``, ``],
  cancel: [`취소`, `Cancel`, 't_homework.cancel', ``, ``, ``, ``],
  submission_date: [`제출 일시`, `Submission date`, 't_homework.submission_date', ``, ``, ``, ``],
  remaintime_until_due_in_15min: (remainTime) => [
    <Div>
      제출까지
      <br />
      <Div spanTag textDanger fontBold>
        {remainTime}분
      </Div>{' '}
      남았습니다
    </Div>,
    `${remainTime} left before submission`,
    't_homework.remaintime_until_due_in_15min',
    <Div></Div>,
    <Div></Div>,
    <Div></Div>,
    <Div></Div>,
  ],
  corrected: [`교정 완료`, `Correction Completed`, 't_homework.corrected', ``, ``, ``, ``],
  not_corrected_yet: [`교정 준비중`, `Correction in progress`, 't_homework.not_corrected_yet', ``, ``, ``, ``],
  minutes_left: (minutes) => [
    `${minutes}분 남음`,
    `${minutes} minutes left`,
    't_homework.minutes_left',
    ``,
    ``,
    ``,
    ``,
  ],
  out_of_n_words: (n) => [`/${n} 단어`, `/${n} word`, 't_homework.out_of_n_words', ``, ``, ``, ``],
  leave_homework: [`숙제 나가기`, `Exit homework`, 't_homework.leave_homework', ``, ``, ``, ``],
  just_leave: [`그냥 나가기`, `Just exit`, 't_homework.just_leave', ``, ``, ``, ``],
  save_and_leave: [`저장하고 나가기`, `Save and exit`, 't_homework.save_and_leave', ``, ``, ``, ``],
  child_answer: [`의 답변`, `s Answer`, 't_homework.child_answer', ``, ``, ``, ``],
}
export const t_preparation = {
  parent_question_no: [
    `부모님은 수업 질문을 선택하실 수 없습니다.`,
    `Parent cannot select questions to discuss in a lesson.`,
    't_preparation.parent_question_no',
    ``,
    ``,
    ``,
    ``,
  ],
  parent_function_no: [
    `부모님께 제공되지 않는 기능입니다.`,
    `This feature is not available to parent`,
    't_preparation.parent_function_no',
    ``,
    ``,
    ``,
    ``,
  ],
  guideline: [`가이드라인`, `Guideline`, 't_preparation.guideline', ``, ``, ``, ``],
}
export const t_schedule_enter = {
  sorry_no_timefirst: [
    <>
      아쉽게도 현재 버전에서는
      <br />
      ‘시간 먼저 선택’이 제공되지 않습니다.
      <br />
      불편을 드려 죄송합니다.
    </>,
    `Beta version does not provide 'Sort by time'.`,
    't_schedule_enter.sorry_no_timefirst',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  sorry_no_24: [
    <>
      아쉽게도 현재 버전에서는 ‘24시간 이내 수업 선택’이 제공되지 않습니다.
      <br />
      불편을 드려 죄송합니다.
    </>,
    `Beta version does not provide 'Last-minute Lessons'.`,
    't_schedule_enter.sorry_no_24',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
}
export const t_edit_profile = {
  intro_place1: [
    `영어로 자신에 대한 소개를 적어주세요.`,
    `Please introduce yourself.`,
    't_edit_profile.intro_place1',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place2: [
    `영어로 내가 잘하거나 좋아하는 것을 적어주세요.`,
    `Please write what you are good at or like.`,
    't_edit_profile.intro_place2',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place3: [
    `영어로 나의 꿈에 대해 적어주세요.`,
    `Please write about my dream.`,
    't_edit_profile.intro_place3',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place4: [
    `영어로 나의 학교 생활에 대해 적어주세요.`,
    `Please write about my school life.`,
    't_edit_profile.intro_place4',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place5: [
    `영어로 나의 요즘 고민 대해 적어주세요.`,
    `Please write about my current worries.`,
    't_edit_profile.intro_place5',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_portal_home = {
  confirm_delete_tutor: [
    `[튜터 > 내 튜터] 찜한 튜터에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from [Tutors > My Tutors]?`,
    't_portal_home.confirm_delete_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  like_added: [
    <>[교재 {'>'} 내 교재] 찜한 교재에 추가되었습니다.</>,
    <>Added from Materials {'>'} My Content.</>,
    't_portal_home.like_added',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  like_deleted: [
    <>교재 {'>'} 내 교재에서 삭제되었습니다.</>,
    <>Removed from Materials {'>'} My Content.</>,
    't_portal_home.like_deleted',
    <></>,
    <></>,
    <></>,
    <></>,
  ],
  confirm_delete: [
    `[교재 > 내 교재] 찜한 교재에서 삭제하시겠습니까?`,
    `Do you want to remove the material from your [Favorite Materials]?`,
    't_portal_home.confirm_delete',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_enter_browser: {
    popup_title: [
      `Browser Issue Detected`,
      `Browser Issue Detected`,
      't_portal_home.lesson_enter_browser.popup_title',
      `Browser Issue Detected`,
      `Browser Issue Detected`,
      `Browser Issue Detected`,
      `Browser Issue Detected`,
    ],
    popup_subtitle: (browserName, browserVer) => [
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      't_portal_home.lesson_enter_browser.popup_subtitle',
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
    ],
    left_btn: [
      `Enter Lesson`,
      `Enter Lesson`,
      't_portal_home.lesson_enter_browser.left_btn',
      `Enter Lesson`,
      `Enter Lesson`,
      `Enter Lesson`,
      `Enter Lesson`,
    ],
    right_btn: [
      `Download Chrome`,
      `Download Chrome`,
      't_portal_home.lesson_enter_browser.right_btn',
      `Download Chrome`,
      `Download Chrome`,
      `Download Chrome`,
      `Download Chrome`,
    ],
  },
}
export const t_materials = {
  no_result: [`😣  조건에 맞는 교재가 없습니다.`, `😣  No Result`, 't_materials.no_result', ``, ``, ``, ``],
}
export const t_upcoming_lessons = {
  after_tutor_cancel: {
    automatic_match_me_without_beta: [
      `다른 튜터 매칭 받기 (베타 버전에서는 지원하지 않습니다)`,
      `Match me to a differerent tutor (Unavailable in Beta)`,
      't_upcoming_lessons.after_tutor_cancel.automatic_match_me_without_beta',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_head = {
  student: {
    title: [`10대를 위한 1:1 화상영어`, `1:1 platform for in-depth learning`, 't_head.student.title', ``, ``, ``, ``],
  },
}
export const t_mypage_noti = {
  ringle_benefit: [`링글 틴즈 소식 & 혜택`, `Ringle Teens Newsletter`, 't_mypage_noti.ringle_benefit', ``, ``, ``, ``],
  ringle_benefit_desc: [
    `링글 틴즈 뉴스레터, 할인 혜택, 이벤트, 콘텐츠 업데이트 소식 등을 받으실 수 있습니다.`,
    `You will receive Ringle Teens's exclusive promotions and latest news.`,
    't_mypage_noti.ringle_benefit_desc',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_tutors = {
  no_result: [`😣  조건에 맞는 튜터가 없습니다.`, `😣  No Result`, 't_tutors.no_result', ``, ``, ``, ``],
}
export const t_classroom = {
  leave_class: [
    `수업 나가기`,
    `Leave Lesson`,
    't_classroom.leave_class',
    `离开`,
    `離開`,
    `レッスン退出`,
    `Rời buổi học`,
  ],
  cancel: [`취소`, `Cancel`, 't_classroom.cancel', `取消`, `取消`, `キャンセル`, `Hủy`],
  quit_the_current_class: [
    `현재 진행 중인 수업을 중단하고 나가시겠습니까?`,
    `Are you sure you want to leave this lesson?`,
    't_classroom.quit_the_current_class',
    `确定要离开吗？`,
    `確定要離開嗎？`,
    `現在進行中のレッスンを中断して退出しますか？`,
    `Bạn có chắc muốn rời lớp?`,
  ],
  lesson_stop: [
    `수업 중단`,
    `Leave Lesson`,
    't_classroom.lesson_stop',
    `离开`,
    `離開`,
    `レッスンを中断`,
    `Rời buổi học`,
  ],
  zoom_help: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 't_classroom.zoom_help', ``, ``, ``, ``],
  zoom_tooltip: [
    `Zoom이 열리지 않는다면 이곳을 클릭하세요.`,
    `If Zoom doesn't launch automatically,  
please click here.`,
    't_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_title: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 't_classroom.modal_zoom_title', ``, ``, ``, ``],
  modal_zoom_tap_2: [`Zoom이 실행되지 않을 때`, `Zoom won’t launch`, 't_classroom.modal_zoom_tap_2', ``, ``, ``, ``],
  modal_zoom_desc_2: [
    `Zoom이 열리지 않는다면 아래의 [Zoom 다시 열기]를 클릭하거나 링글 모바일 앱을 통해 접속해 주세요. 만약 문제가 해결되지 않는다면, 페이지 상단의 지원 요청을 통해 문의를 남겨주세요.`,
    `If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the problem isn't solved, please leave us a message by clicking on Help at the top.`,
    't_classroom.modal_zoom_desc_2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_tap_1: [`소리가 들리지 않을 때`, `No sound is coming out`, 't_classroom.modal_zoom_tap_1', ``, ``, ``, ``],
  modal_zoom_desc_1: [
    `Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
https://zoom.us/test 에서 카메라와 오디오를 테스트 할 수 있습니다.`,
    `After Zoom is launched, please connect your audio as follows. You can also test your camera and audio by going to https://zoom.us/test.`,
    't_classroom.modal_zoom_desc_1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`Zoom 다시 열기`, `Relaunch Zoom`, 't_classroom.btn', ``, ``, ``, ``],
}
export const s_lessons_schedule = {
  rules: {
    check_rules: [
      `수업 에티켓을 확인해 주세요!`,
      `Check Our Rules of Conduct`,
      's_lessons_schedule.rules.check_rules',
      `请确认课程规范!`,
      `請確認上課禮儀！`,
      `レッスンエチケットをご確認ください！`,
      `Kiểm tra Quy tắc ứng xử`,
    ],
    set_up: [
      `알맞은 수업 환경 준비하기`,
      `Set up an Environment for Learning`,
      's_lessons_schedule.rules.set_up',
      `打造舒适的课程环境`,
      `準備合適的上課環境`,
      `適切なレッスン環境を準備する`,
      `Thiết lập môi trường học tập`,
    ],
    do_not_drive: [
      `운전 중 / 이동 중 수업은 삼가 주세요.`,
      `Don’t Zoom and drive.`,
      's_lessons_schedule.rules.do_not_drive',
      `请勿于驾驶/移动中进行课程。`,
      `請避免於開車／移動中上課。`,
      `運転中 / 移動中のレッスンはお控えください。`,
      `Không dùng Zoom trong khi lái xe.`,
    ],
    no_drinking: [
      `음주 후 수업은 자제해 주세요.`,
      `No drinking during lessons.`,
      's_lessons_schedule.rules.no_drinking',
      `请勿饮酒后上课。`,
      `請避免於飲酒後上課。`,
      `飲酒後のレッスンはご遠慮ください。`,
      `Không sử dụng đồ uống có cồn trong buổi học.`,
    ],
    punctual: [
      `약속된 시간 지키기`,
      `Be Punctual`,
      's_lessons_schedule.rules.punctual',
      `请遵守约定的时间`,
      `遵守約定的時間`,
      `約束の時間を守る`,
      `Đúng giờ`,
    ],
    keep_schdules: [
      `정해진 수업 시간을 지켜주세요.`,
      `Keep track of your lesson schedules.`,
      's_lessons_schedule.rules.keep_schdules',
      `请遵守规定的课程时间。`,
      `請遵守指定上課時間。`,
      `決められたレッスン時間を守ってください。`,
      `Theo dõi thời khoá biểu của bạn`,
    ],
    no_external_request: [
      `수업 종료 이후 요청은 자제해 주세요.`,
      `Tutors aren’t responsible for\nany external lesson requests.`,
      's_lessons_schedule.rules.no_external_request',
      `课程结束后, 请勿提出要求。`,
      `課程結束後，請勿提出要求。`,
      `レッスン終了以降のリクエストはご遠慮ください。`,
      `Gia sư không chịu tránh nhiệm cho\nbất kỳ yêu cầu nào ngoài buổi học.`,
    ],
    with_respect: [
      `튜터 존중하기`,
      `Treat Your Tutor with Respect`,
      's_lessons_schedule.rules.with_respect',
      `请尊师重教`,
      `尊重任課教師`,
      `チューターを尊重する`,
      `Tôn trọng gia sư của bạn`,
    ],
    no_photos: [
      `수업 중 사적인 질문, 사진 촬영 등은 삼가 주세요.`,
      `Refrain from asking\nsensitive questions or taking photographs.`,
      's_lessons_schedule.rules.no_photos',
      `课程中请避免隐私问题及拍照。`,
      `上課時請勿提出私人問題或拍照。`,
      `レッスン中の私的な質問や写真撮影などはお控えください。`,
      `Không hỏi những câu hỏi\nnhạy cảm hoặc chụp hình.`,
    ],
    no_discriminatory_remarks: [
      `의도하지 않은 혐오 발언을 주의해 주세요.`,
      `Do not make discriminatory remarks.`,
      's_lessons_schedule.rules.no_discriminatory_remarks',
      `请注意无意识的污言秽语。`,
      `請避免無意中發表厭惡言論。`,
      `不用意な嫌悪発言にご注意ください。`,
      `Không đưa ra những nhận xét phân biệt đối xử.`,
    ],
    focus_on_learning: [
      `영어 학습 목적에 충실하기`,
      `Focus on Learning English`,
      's_lessons_schedule.rules.focus_on_learning',
      `秉持英语学习的初衷`,
      `忠於英語學習目的`,
      `英語学習の目的に忠実に`,
      `Tập trung vào việc học tiếng Anh`,
    ],
    avoid_unrelated: [
      `튜터에게 수업과 관련 없는 요청은 삼가 주세요.`,
      `Avoid making requests unrelated to the lesson.`,
      's_lessons_schedule.rules.avoid_unrelated',
      `请避免向教师提出与课程无关的请求。`,
      `請避免向任課教師提出與課程無關的要求。`,
      `チューターへのレッスンに関係のないリクエストはお控えください。`,
      `Tránh đưa ra những yêu cầu không liên quan đến buổi học.`,
    ],
    turn_on_video: [
      `학습 효과를 위해 카메라는 꼭 켜주세요.`,
      `Turn on video for effective communication.`,
      's_lessons_schedule.rules.turn_on_video',
      `为保障学习效果, 请务必开启摄像头。`,
      `為了學習效果，請務必開啟相機。`,
      `学習効果のためにカメラは必ずオンにしてください。`,
      `Bật video để giao tiếp hiệu quả hơn.`,
    ],
    follow_guidelines: [
      `수업 에티켓을 지키지 않으면, 링글 수업이 제한될 수 있습니다.`,
      `If the rules of conduct are not followed, future lessons may be limited.`,
      's_lessons_schedule.rules.follow_guidelines',
      `如果不能遵守相关规定, 您的Ringle学习可能会受到限制。`,
      `未遵守上課禮儀時，Ringle的課程可能受限。`,
      `レッスンエチケットを守らない場合、Ringleレッスンが制限されることがあります。`,
      `Nếu các hướng dẫn dưới đây không được tuân thủ, các buổi học trong tương lai sẽ bị hạn chế.`,
    ],
    check: [
      `잠깐! 수업 에티켓`,
      `Check Our Rules of Conduct!`,
      's_lessons_schedule.rules.check',
      `稍等! 课程规范`,
      `等一下！上課禮儀`,
      `ちょっと待って！レッスンエチケット`,
      `Kiểm tra Quy tắc ứng xử`,
    ],
    done_checking: [
      `확인 완료`,
      `Done checking`,
      's_lessons_schedule.rules.done_checking',
      `已确认`,
      `確認完畢`,
      `確認完了`,
      `Đã kiểm tra xong`,
    ],
    next: [`다음`, `Next`, 's_lessons_schedule.rules.next', `下一步`, `繼續`, `次へ`, `Tiếp theo`],
  },
  failure_reason: [
    `실패사유:`,
    `Reason:`,
    's_lessons_schedule.failure_reason',
    `失败理由：`,
    `失敗理由：`,
    `失敗理由:`,
    `Nguyên nhân không thành công:`,
  ],
  dont_zoom_travelling: [
    `이동하면서 줌에 접속하지 마세요`,
    `Don’t zoom when traveling`,
    's_lessons_schedule.dont_zoom_travelling',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const r_auth = {
  signin: {
    login: [`Log In`, `Log In`, 'r_auth.signin.login', ``, ``, ``, ``],
    forgot_password: [
      `Forgot your password?`,
      `Forgot your password?`,
      'r_auth.signin.forgot_password',
      ``,
      ``,
      ``,
      ``,
    ],
    signup: [`Sign Up`, `Sign Up`, 'r_auth.signin.signup', ``, ``, ``, ``],
    enter_your_email: [
      `Enter your email address (ID)`,
      `Enter your email address`,
      'r_auth.signin.enter_your_email',
      ``,
      ``,
      ``,
      ``,
    ],
    enter_your_password: [
      `Enter your password`,
      `Enter your password`,
      'r_auth.signin.enter_your_password',
      ``,
      ``,
      ``,
      ``,
    ],
    incorrect_password: [
      `Password is incorrect.`,
      `Incorrect password. Please try again or click "Forget your password?".`,
      'r_auth.signin.incorrect_password',
      ``,
      ``,
      ``,
      ``,
    ],
    enter_valid_email: [
      `Please enter a valid email address (ID)`,
      `Please enter a valid email address`,
      'r_auth.signin.enter_valid_email',
      ``,
      ``,
      ``,
      ``,
    ],
    password_length: [
      `Password has to be at least 6 characters`,
      `Password has to be at least 6 characters`,
      'r_auth.signin.password_length',
      ``,
      ``,
      ``,
      ``,
    ],
    password_space: [
      `띄어쓰기 없이 입력해 주세요.`,
      `Please enter without spaces.`,
      'r_auth.signin.password_space',
      `输入时中间不能有空格`,
      `輸入時中間不能有空格`,
      `スペース（空白）なしで入力してください。`,
      `vui lòng nhập không có dấu cách`,
    ],
  },
  signup: {
    title: [
      `Enter your academic email address`,
      `Enter your current university email address`,
      'r_auth.signup.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `If you have already graduated and no longer have access to an academic email address, please enter your current email address and check "I am no longer a student.`,
      <>
        If you have already graduated and no longer have access to a university email address, please enter your current
        email address and check "I am no longer a student."
      </>,
      'r_auth.signup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    email_address: [`Email address`, `Email address`, 'r_auth.signup.email_address', ``, ``, ``, ``],
    no_longer_student: [
      `I am no longer a student.`,
      `I am no longer a student`,
      'r_auth.signup.no_longer_student',
      ``,
      ``,
      ``,
      ``,
    ],
    verify_email_address: [
      `Verify Email Address`,
      `Verify Email Address`,
      'r_auth.signup.verify_email_address',
      ``,
      ``,
      ``,
      ``,
    ],
    already_ringle_account: [
      `Already have a Ringle account?`,
      `Already have a Ringle account?`,
      'r_auth.signup.already_ringle_account',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  verify_email: {
    title: [`Verify your email address`, `Verify your email address`, 'r_auth.verify_email.title', ``, ``, ``, ``],
    desc: [
      `Input the verification code sent to this email address.`,
      `Input the verification code sent to this email address.`,
      'r_auth.verify_email.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    change: [`Change`, `Change`, 'r_auth.verify_email.change', ``, ``, ``, ``],
    received_email: [
      `Haven't received an email?`,
      `Haven't received an email?`,
      'r_auth.verify_email.received_email',
      ``,
      ``,
      ``,
      ``,
    ],
    resend_email: [`Resend email`, `Resend email`, 'r_auth.verify_email.resend_email', ``, ``, ``, ``],
    continue: [`Continue`, `Continue`, 'r_auth.verify_email.continue', ``, ``, ``, ``],
    fail_title: [
      `The code you entered doesn't match the one we sent to your email address. Please try again.`,
      `The code you entered doesn't match the one we sent to your email address. Please try again.`,
      'r_auth.verify_email.fail_title',
      ``,
      ``,
      ``,
      ``,
    ],
    send_new_code: [`Send new code`, `Send new code`, 'r_auth.verify_email.send_new_code', ``, ``, ``, ``],
    retry: [`Retry`, `Retry`, 'r_auth.verify_email.retry', ``, ``, ``, ``],
    success_title: (email) => [
      `Check the inbox of ${email} to continue.`,
      `Check the inbox of ${email} to continue.`,
      'r_auth.verify_email.success_title',
      ``,
      ``,
      ``,
      ``,
    ],
    ok: [`OK`, `OK`, 'r_auth.verify_email.ok', ``, ``, ``, ``],
  },
  basic: {
    title: [
      `Enter your information`,
      <>
        Welcome to Ringle!
        <br />
        Enter your information{' '}
      </>,
      'r_auth.basic.title',
      ``,
      ``,
      ``,
      ``,
    ],
    email: [`Email`, `Email`, 'r_auth.basic.email', ``, ``, ``, ``],
    name: [`Name`, `Name`, 'r_auth.basic.name', ``, ``, ``, ``],
    name_desc: [
      `This should match the name on your government ID.`,
      `This should match the name on your government ID.`,
      'r_auth.basic.name_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    password: [`Password`, `Password`, 'r_auth.basic.password', ``, ``, ``, ``],
    password_desc: [
      `Passwords should be at least 6 characters long.`,
      `Passwords should be at least 6 characters long.`,
      'r_auth.basic.password_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    birthday: [`Birthday`, `Birthday`, 'r_auth.basic.birthday', ``, ``, ``, ``],
    birthday_desc: [
      `You must be at least 18 years old to sign up. Your birthday or age will not be shared with other Ringle users.`,
      `You must be at least 18 years old to sign up. Your birthday or age will not be shared with other Ringle users.`,
      'r_auth.basic.birthday_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    location: [`Location`, `Location`, 'r_auth.basic.location', ``, ``, ``, ``],
    location_desc: [
      `Your residential information will be used for tax reporting purposes only.`,
      `Your residential information will be used for tax reporting purposes only.`,
      'r_auth.basic.location_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    select_country_code: [`Select Country code`, `Select Country`, 'r_auth.basic.select_country_code', ``, ``, ``, ``],
    mobile_number: [`Mobile number`, `Mobile number`, 'r_auth.basic.mobile_number', ``, ``, ``, ``],
    mobile_number_desc: [
      `Please ensure this is accurate. You will receive updates regarding your application process and timeline vis this number`,
      `Please ensure this is accurate. You will receive updates regarding your application process and reminders via this number.`,
      'r_auth.basic.mobile_number_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    referral_code: [
      `Referral Code (optional)`,
      `Referral Code (optional)`,
      'r_auth.basic.referral_code',
      ``,
      ``,
      ``,
      ``,
    ],
    referral_code_desc: [
      `Have a referral code? Complete your first lesson within 90 days of sign up to receive a referral bonus.`,
      `Have a referral code? Complete your first lesson within 90 days of sign up to receive a referral bonus.`,
      'r_auth.basic.referral_code_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    verified: [`Verified`, `Verified`, 'r_auth.basic.verified', ``, ``, ``, ``],
    apply: [`Apply`, `Apply`, 'r_auth.basic.apply', ``, ``, ``, ``],
    agree_to: [`By signing up, I agree to`, `By signing up, I agree to`, 'r_auth.basic.agree_to', ``, ``, ``, ``],
    privacy: [`Privacy Policy.`, `Ringle's Privacy Policy.`, 'r_auth.basic.privacy', ``, ``, ``, ``],
    agree_and_sign_up: [`Agree and Sign up`, `Agree and Sign up`, 'r_auth.basic.agree_and_sign_up', ``, ``, ``, ``],
  },
}
export const r_application = {
  step1: {
    title: [`Application`, `Application`, 'r_application.step1.title', ``, ``, ``, ``],
    page_title: [
      `Step1. Submit application`,
      `Step1. Submit an application`,
      'r_application.step1.page_title',
      ``,
      ``,
      ``,
      ``,
    ],
    school: [`School`, `Higher Education`, 'r_application.step1.school', ``, ``, ``, ``],
    school_desc: [
      `Please use the school at which you achieved your highest qualification, for which you can provide Proof of Education.`,
      `Please use the university to which you've been admitted or from which you achieved your highest degree.`,
      'r_application.step1.school_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    school_polaceholder: [
      `Select your school`,
      `Select your school`,
      'r_application.step1.school_polaceholder',
      ``,
      ``,
      ``,
      ``,
    ],
    other_school: [`Other School`, `Other`, 'r_application.step1.other_school', ``, ``, ``, ``],
    field_study: [`Field of Study`, `Field of Study`, 'r_application.step1.field_study', ``, ``, ``, ``],
    field_study_placeholder: [
      `Select subject`,
      `Select your field of study`,
      'r_application.step1.field_study_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    major: [`Major`, `Major`, 'r_application.step1.major', ``, ``, ``, ``],
    graduated: [`Graduated`, `I've graduated`, 'r_application.step1.graduated', ``, ``, ``, ``],
    graduated_label: [
      `Expected graduation year`,
      `Expected Graduation year`,
      'r_application.step1.graduated_label',
      ``,
      ``,
      ``,
      ``,
    ],
    graduated_year: [`Graduation year`, `Graduation year`, 'r_application.step1.graduated_year', ``, ``, ``, ``],
    proof_of_education: [
      `Proof of Education (can be submitted later)`,
      `Proof of Education (can be submitted later)`,
      'r_application.step1.proof_of_education',
      ``,
      ``,
      ``,
      ``,
    ],
    resume: {
      no_larger_than_8mb: [
        `Your file should be no larger than 8mb.`,
        `Your file should be no larger than 8mb.`,
        'r_application.step1.resume.no_larger_than_8mb',
        ``,
        ``,
        ``,
        ``,
      ],
      unsupported_file_type: [
        `Unsupported file type. Please check and upload pdf file only.`,
        `Unsupported file type. Please check and upload pdf file only.`,
        'r_application.step1.resume.unsupported_file_type',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `Please provide a professional and polished resume that include your professional experience and extracurricular activities in your resume.`,
        `Please provide a polished resume that includes both professional experience and extracurricular pursuits.`,
        'r_application.step1.resume.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      upload: [`Upload`, `Upload`, 'r_application.step1.resume.upload', ``, ``, ``, ``],
      upload_guide: [
        `* Only pdf file is supported. The size of your uploaded file should be below 8 mb.`,
        `* Only pdf file is supported. The size of your uploaded file should be below 8 mb.`,
        'r_application.step1.resume.upload_guide',
        ``,
        ``,
        ``,
        ``,
      ],
      pdf_only: [
        <Div>
          Resume (
          <Div spanTag textDanger>
            *
          </Div>
          .pdf only)
        </Div>,
        <Div>
          Resume (
          <Div spanTag textDanger>
            *
          </Div>
          .pdf only)
        </Div>,
        'r_application.step1.resume.pdf_only',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    next: [`Next`, `Next`, 'r_application.step1.next', ``, ``, ``, ``],
    file: {
      max_file_size: [
        `* Max. file size : 8 MB`,
        `* Max. file size : 8 MB`,
        'r_application.step1.file.max_file_size',
        ``,
        ``,
        ``,
        ``,
      ],
      supported_pdf: [
        `* supported file format : pdf`,
        `* Supported file format : pdf`,
        'r_application.step1.file.supported_pdf',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    pending: {
      title: [
        `Thank you for your application`,
        `Thank you for your application`,
        'r_application.step1.pending.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `Please allow us time to review it as we will get back to you with the result as soon as possible.`,
        `We'll be in touch as soon as we've reviewed it.`,
        'r_application.step1.pending.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    passed: {
      title: [``, `Congratulations!`, 'r_application.step1.passed.title', ``, ``, ``, ``],
      desc: [
        ``,
        `You've successfully passed the first round, and have been chosen to conduct a Mock session.`,
        'r_application.step1.passed.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      btn: [`Schedule Mock Session`, `Schedule Mock Session`, 'r_application.step1.passed.btn', ``, ``, ``, ``],
      special: [
        ``,
        `Your resume screening has been waived, and you are now eligible to schedule a Mock session`,
        'r_application.step1.passed.special',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  step2: {
    page_title: [
      `Step 2: Schedule a Mock Session`,
      `Step 2: Schedule a Mock Session`,
      'r_application.step2.page_title',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [`Schedule a Mock Session`, `Schedule a Mock Session`, 'r_application.step2.title', ``, ``, ``, ``],
    date_time: [`Date & Time`, `Date & Time`, 'r_application.step2.date_time', ``, ``, ``, ``],
    date_time_placeholder: [
      `Pick a date and time`,
      `Pick a date and time`,
      'r_application.step2.date_time_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    additional_slot_desc: [
      `If no available slots suit you, please click "Request additional slot" below to be notified when new slots are released.`,
      `If no available slots suit you, please click "Request additional slot" below to be notified when new slots are released.`,
      'r_application.step2.additional_slot_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    additional_slot: [
      `Request additional slot`,
      `Request additional slot`,
      'r_application.step2.additional_slot',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_material: [`Lesson Material`, `Lesson Material`, 'r_application.step2.lesson_material', ``, ``, ``, ``],
    pick_lesson_material: [
      `Pick a lesson packet`,
      `Pick a lesson packet`,
      'r_application.step2.pick_lesson_material',
      ``,
      ``,
      ``,
      ``,
    ],
    selected_time: [
      `Selected Date and Time`,
      `Selected Date and Time`,
      'r_application.step2.selected_time',
      ``,
      ``,
      ``,
      ``,
    ],
    confirm: [`Confirm`, `Confirm`, 'r_application.step2.confirm', ``, ``, ``, ``],
    mock_session: {
      title: [
        `Please confirm your mock session details`,
        `Please confirm your mock session details`,
        'r_application.step2.mock_session.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `Your Mock session is scheduled for the following date and time.`,
        `Your Mock session is scheduled for the following date and time.`,
        'r_application.step2.mock_session.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      phone: [
        `Mock session notification will be sent to your mobile phone.`,
        `Mock Session reminder notification will be sent to this number.`,
        'r_application.step2.mock_session.phone',
        ``,
        ``,
        ``,
        ``,
      ],
      no_student: [
        `A student will be assigned soon.`,
        `A student will be assigned soon.`,
        'r_application.step2.mock_session.no_student',
        ``,
        ``,
        ``,
        ``,
      ],
      change: [`Change`, `Change`, 'r_application.step2.mock_session.change', ``, ``, ``, ``],
      complete: [
        `Complete Scheduling`,
        `Complete Scheduling`,
        'r_application.step2.mock_session.complete',
        ``,
        ``,
        ``,
        ``,
      ],
      timezone: [
        `You can find your timezone through typing in your keyboard`,
        `You can find your timezone through typing in your keyboard`,
        'r_application.step2.mock_session.timezone',
        ``,
        ``,
        ``,
        ``,
      ],
      select: [`Select`, `Select`, 'r_application.step2.mock_session.select', ``, ``, ``, ``],
    },
    selected_packet: [
      `Selected Lesson Packet`,
      `Selected Lesson Packet`,
      'r_application.step2.selected_packet',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  step3: {
    page_title: [`Step3. Mock session`, `Step3. Mock Session`, 'r_application.step3.page_title', ``, ``, ``, ``],
    list: {
      zoom: [`Download the Zoom app`, `Download the Zoom app`, 'r_application.step3.list.zoom', ``, ``, ``, ``],
      evaluation: [
        `Read our 3 evaluation criteria`,
        `Read our 4 core evaluation criteria`,
        'r_application.step3.list.evaluation',
        ``,
        ``,
        ``,
        ``,
      ],
      video: [
        `Try an interactive Ringle Docs tutorial`,
        `Try an interactive Ringle Docs tutorial`,
        'r_application.step3.list.video',
        ``,
        ``,
        ``,
        ``,
      ],
      sample: [
        `Watch a sample Mock Session`,
        `Watch a sample Mock Session`,
        'r_application.step3.list.sample',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    prep: [`Mock Session Preparation`, `Mock Session Preparation`, 'r_application.step3.prep', ``, ``, ``, ``],
    material: [`Material`, `Lesson Material`, 'r_application.step3.material', ``, ``, ``, ``],
    check_lesson_setting: [
      `Check Lesson Environment`,
      `Check Lesson Style and Request`,
      'r_application.step3.check_lesson_setting',
      ``,
      ``,
      ``,
      ``,
    ],
    enter_mock_session: [
      `Enter Mock Session`,
      `Enter Mock Session`,
      'r_application.step3.enter_mock_session',
      ``,
      ``,
      ``,
      ``,
    ],
    write_feedback: [
      `Write the Feedback report`,
      `Write your Feedback report`,
      'r_application.step3.write_feedback',
      ``,
      ``,
      ``,
      ``,
    ],
    write_feedback_desc: [
      `Please make sure that you submit a feedback report for today’s mock session after the lesson. Suggestions on further paraphrased sentences or correction would be very much appreciated from the student.`,
      <>
        Be sure to complete and submit your Feedback Report within 24 hours of your Mock Session. Your students will
        especially appreciate detailed explanations of more complex suggestions or additional paraphrasing options.
      </>,
      'r_application.step3.write_feedback_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    write_feedback_btn: [
      `Write the Feedback Report`,
      `Write the Feedback Report`,
      'r_application.step3.write_feedback_btn',
      ``,
      ``,
      ``,
      ``,
    ],
    complete_btn: [`Completed`, `Completed`, 'r_application.step3.complete_btn', ``, ``, ``, ``],
    guide: {
      page_title: [`Mock Session prep`, `Mock Session prep`, 'r_application.step3.guide.page_title', ``, ``, ``, ``],
      title: [
        `What is the Mock Session?`,
        `What is the Mock Session?`,
        'r_application.step3.guide.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `During the Mock Session, you'll be asked to lead a 20 minute Ringle lesson with a member of the Ringle team posing as a student.
We encourage you to approach this session as you would a real session on Ringle.`,
        `During the Mock Session, you'll be asked to lead a 20 minute Ringle lesson with a member of the Ringle team posing as a student.
We encourage you to approach this session as you would a real session on Ringle.`,
        'r_application.step3.guide.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session_procedure: [
        `The Mock Session Process`,
        `The Mock Session Process`,
        'r_application.step3.guide.mock_session_procedure',
        ``,
        ``,
        ``,
        ``,
      ],
      preparation: [`Step 1. Preparation`, `Step 1. Prepare`, 'r_application.step3.guide.preparation', ``, ``, ``, ``],
      preparation_1: [
        `• Preparation before the Mock Session.`,
        <>
          Use the provided{' '}
          <a
            className="text-blue-500"
            href="https://ringlecontents.s3.ap-northeast-2.amazonaws.com/Mock-Session-demo-v3.webm"
          >
            demo video
          </a>{' '}
          and{' '}
          <a
            className="text-blue-500"
            href="https://ringlecontents.s3.ap-northeast-2.amazonaws.com/MockSessionGuide_v1.6.APR.2022.pdf"
          >
            a guide
          </a>{' '}
          to prepare for the Mock Session.
        </>,
        'r_application.step3.guide.preparation_1',
        ``,
        ``,
        ``,
        ``,
      ],
      preparation_2: [
        `• We provide some demo videos and tutoring materials to help you to prepare the Mock session.`,
        `• We provide some demo videos and tutoring materials to help you to prepare the Mock session.`,
        'r_application.step3.guide.preparation_2',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session: [
        `Step 2. Mock Session`,
        `Step 2. Conduct the session`,
        'r_application.step3.guide.mock_session',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session_1: [
        `• Introduction (can be skipped if your student indicates so) (1 min)`,
        <>
          Conduct the Mock Session in accordance to the preparatory resources. A brief timeline for the Mock Session is
          as follows: A full structure and advice can be found in{' '}
          <a
            className="text-blue-500"
            href="https://ringlecontents.s3.ap-northeast-2.amazonaws.com/MockSessionGuide_v1.6.APR.2022.pdf"
          >
            the Mock Session Guide
          </a>
        </>,
        'r_application.step3.guide.mock_session_1',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session_2: [
        `• Check the lesson Style & Requests (1 min)`,
        `• Introduction (1 min)`,
        'r_application.step3.guide.mock_session_2',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session_3: [
        `• Provide your and follow-up questions after the student’s answer (17 min)`,
        `• Check the lesson Style & Requests (1 min)`,
        'r_application.step3.guide.mock_session_3',
        ``,
        ``,
        ``,
        ``,
      ],
      mock_session_4: [
        `• Wrap up the lesson (1 min)`,
        `• Ask the students question and transcribe(2min)`,
        'r_application.step3.guide.mock_session_4',
        ``,
        ``,
        ``,
        ``,
      ],
      feedback_report: [
        `Step 3. Feedback Report`,
        `Step 3. Complete your Feedback Report`,
        'r_application.step3.guide.feedback_report',
        ``,
        ``,
        ``,
        ``,
      ],
      feedback_report_1: [
        `• Complete Feedback Report`,
        `Submit the Feedback Report within 24 hours of your Mock Session.`,
        'r_application.step3.guide.feedback_report_1',
        ``,
        ``,
        ``,
        ``,
      ],
      preparation_for_the_mock_session: [
        `Preparation for the Mock session`,
        `Preparation for the Mock Session`,
        'r_application.step3.guide.preparation_for_the_mock_session',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom: [`Download the Zoom app`, `Download the Zoom app`, 'r_application.step3.guide.zoom', ``, ``, ``, ``],
      zoom_1: [
        `1. Please download a Zoom installer.`,
        `1. Please download a Zoom installer.`,
        'r_application.step3.guide.zoom_1',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom_down: [`Download zoom`, `Download zoom`, 'r_application.step3.guide.zoom_down', ``, ``, ``, ``],
      zoom_2: [
        `2. Please run the downloaded file (It is saved in the downloads folder).`,
        `2. Run the downloaded file. It can be found in your Downloads folder.`,
        'r_application.step3.guide.zoom_2',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom_3: [
        `3. The client installation will proceed once the file runs.`,
        `3. Follow the instruction with in the installer to complete the installation process.`,
        'r_application.step3.guide.zoom_3',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom_3_desc: [
        `* Please do not sign up or login in Zoom. You don’t have to sign up/login. It will cause a problem when you login Zoom.`,
        `N.b. Please do not login to Zoom within the app, as this can cause issues with our platform.`,
        'r_application.step3.guide.zoom_3_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom_4: [
        `4. Zoom Test (approximately 2 minutes)`,
        `4. Test Zoom`,
        'r_application.step3.guide.zoom_4',
        ``,
        ``,
        ``,
        ``,
      ],
      zoom_4_desc: [
        `After installation, make sure your audio and video are working properly before lessons.`,
        `After completing installation, take a couple of minutes to ensure your audio and video are working correctly within the app.`,
        'r_application.step3.guide.zoom_4_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      read: [
        `I have read the guideline`,
        `I have read these guidelines`,
        'r_application.step3.guide.read',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_title: [
        `Read our 3 evaluation criteria`,
        `Read our 4 core evaluation criteria`,
        'r_application.step3.guide.evaluation_title',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_1: [
        `1. Please download a Zoom installer.`,
        `1. Lesson prep`,
        'r_application.step3.guide.evaluation_1',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_1_desc: [
        `Maintaining a manner and Understanding the student’s preferred learning style as much as possible by setting lesson goals together.`,
        `How well you show you have prepared for the session`,
        'r_application.step3.guide.evaluation_1_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_2: [
        `2. Corrections & Paraphrasing Skill`,
        `2. Lesson attitude`,
        'r_application.step3.guide.evaluation_2',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_2_desc: [
        `Offering different sentence structures and various vocabulary suggestions through paraphrasing the student’s sentences while reasoning your corrections.`,
        `How well you are able to adopt the student's preferred learning style`,
        'r_application.step3.guide.evaluation_2_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_3: [
        `3. Discussion Leading Skill`,
        `3. Leading the lesson`,
        'r_application.step3.guide.evaluation_3',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_3_desc: [
        `Proactively leading the discussion with follow-up questions while building on what the student said.`,
        `How well you are able to structure the lesson`,
        'r_application.step3.guide.evaluation_3_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      paraphrasing_video_title: [
        `Try an interactive Ringle Docs tutorial`,
        `Try an interactive Ringle Docs tutorial`,
        'r_application.step3.guide.paraphrasing_video_title',
        ``,
        ``,
        ``,
        ``,
      ],
      paraphrasing_video_desc: [
        `Gain better understanding of how your Mock Session will be conducted by watching our sample video clip.`,
        `Learn how to structure and record your paraphrasing and correction suggestions through this short tutorial.`,
        'r_application.step3.guide.paraphrasing_video_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      watched_paraphrasing_video: [
        `I finish watching the Best Practice Video`,
        `I have watched the tutorial`,
        'r_application.step3.guide.watched_paraphrasing_video',
        ``,
        ``,
        ``,
        ``,
      ],
      sample_mock_session: [
        `Watch a sample Mock Session`,
        `Watch a sample Mock Session`,
        'r_application.step3.guide.sample_mock_session',
        ``,
        ``,
        ``,
        ``,
      ],
      watched_sample_video: [
        `I finish watching the sample Mock Session`,
        `I have watched the video`,
        'r_application.step3.guide.watched_sample_video',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_4: [
        `4. Correction and paraphrasing`,
        `4. Correction and paraphrasing`,
        'r_application.step3.guide.evaluation_4',
        ``,
        ``,
        ``,
        ``,
      ],
      evaluation_4_desc: [
        `How well you are able to offer and explain rephrased sentences and vocabulary suggestions`,
        `How well you are able to offer and explain rephrased sentences and vocabulary suggestions`,
        'r_application.step3.guide.evaluation_4_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      sample_mock_session_desc: [
        `Learn how to structure and conduct a Mock Session via this model Mock Session video.`,
        `Learn how to structure and conduct a Mock Session via this model Mock Session video.`,
        'r_application.step3.guide.sample_mock_session_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      continue_btn: [`Continue`, `Continue`, 'r_application.step3.guide.continue_btn', ``, ``, ``, ``],
    },
    pending: {
      title: [
        `Thank you for your time!`,
        `Thank you for your time!`,
        'r_application.step3.pending.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `We'll be in touch as soon as we've reviewed your Mock Session and Feedback Report.`,
        `We'll be in touch as soon as we've reviewed your Mock Session and Feedback Report.`,
        'r_application.step3.pending.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      passed: [`Passed`, `Passed`, 'r_application.step3.pending.passed', ``, ``, ``, ``],
      completed: [`Completed`, `Completed`, 'r_application.step3.pending.completed', ``, ``, ``, ``],
      under_review: [`Under Review`, `Under Review`, 'r_application.step3.pending.under_review', ``, ``, ``, ``],
      step: [`Step`, `Step`, 'r_application.step3.pending.step', ``, ``, ``, ``],
    },
    failed: {
      title: [
        `Thank you for applying to Ringle`,
        `Thank you for applying to Ringle`,
        'r_application.step3.failed.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          We appreciate you taking the time to apply to become a Ringle tutor.
          <br />
          <br />
          Unfortunately, following careful review of your Resume and Mock Session, we have decided not to move forward
          at this time.
          <br />
          <br />
          We wish you all the best in your future endeavors.
        </>,
        <>
          We appreciate you taking the time to apply to become a Ringle tutor.
          <br />
          <br />
          Unfortunately, following careful review of your Resume and Mock Session, we have decided not to move forward
          at this time.
          <br />
          <br />
          We wish you all the best in your future endeavors.
        </>,
        'r_application.step3.failed.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      keep_me: [
        `Keep me updated about Ringle`,
        `Keep me updated about Ringle`,
        'r_application.step3.failed.keep_me',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  main: {
    title: [`Welcome to Ringle!`, `Welcome to Ringle!`, 'r_application.main.title', ``, ``, ``, ``],
    start_button: [
      `Start Application Process`,
      `Begin Application Process`,
      'r_application.main.start_button',
      ``,
      ``,
      ``,
      ``,
    ],
    step1: {
      title: [`Submit an application`, `Submit an application`, 'r_application.main.step1.title', ``, ``, ``, ``],
      desc: [``, ``, 'r_application.main.step1.desc', ``, ``, ``, ``],
      poe_title: [``, `What constitutes the Proof of Education?`, 'r_application.main.step1.poe_title', ``, ``, ``, ``],
      poe_desc1: [
        ``,
        <>
          1. An Official Transcript, which includes your name, a seal and signature of the registrar of your university,
          all your coursework and degree information. <br />
          <br />
          2. An Official Enrollment Certificate, which confirms your matriculation and expected graduation date, printed
          with a seal and signature of the registrar of your university
        </>,
        'r_application.main.step1.poe_desc1',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    step2: {
      title: [`Schedule a Mock Session`, `Schedule a Mock Session`, 'r_application.main.step2.title', ``, ``, ``, ``],
      times: [``, `~ 5 mins`, 'r_application.main.step2.times', ``, ``, ``, ``],
      desc: [
        ``,
        `Indicate your time zone and select an available slot. You'll then be provided with materials to help you prepare fully for the Session.`,
        'r_application.main.step2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      caption: [
        ``,
        `*The Mock Session can only be conducted on a PC or Mac web browser.`,
        'r_application.main.step2.caption',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    step3: {
      title: [`Conduct a Mock Session`, `Conduct a Mock Session`, 'r_application.main.step3.title', ``, ``, ``, ``],
      times: [``, `~ 20 mins`, 'r_application.main.step3.times', ``, ``, ``, ``],
      desc: [
        ``,
        `The Mock Session is not an interview! You will demonstrate your ability to lead a lesson with a member of the Ringle team posing as a student.`,
        'r_application.main.step3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      btn: [`Go to Schedule Mock Session`, `Schedule Mock Session`, 'r_application.main.step3.btn', ``, ``, ``, ``],
    },
    page_title: [`Step1. Submit application`, `Step1. Application`, 'r_application.main.page_title', ``, ``, ``, ``],
    step: [`Step`, `Step`, 'r_application.main.step', ``, ``, ``, ``],
    mins: [`mins`, `mins`, 'r_application.main.mins', ``, ``, ``, ``],
    under_review: [`Under Review`, `Under Review`, 'r_application.main.under_review', ``, ``, ``, ``],
  },
  orientation: {
    pass: {
      title: [`Congratulations!`, `Congratulations!`, 'r_application.orientation.pass.title', ``, ``, ``, ``],
      desc: [
        `We were thoroughly impressed by your Mock Session and are thrilled to invite you to become a Ringle tutor.
We ask that you now complete our Orientation Process, after which you'll be free to open and conduct lessons.`,
        `Given that we are thorougly impressed by your Mock Session, we ask that you now complete our Orientation Process, after which you'll be able to conduct lessons.`,
        'r_application.orientation.pass.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle: [
        `Orientation will take around 45 minutes`,
        `Orientation will take around 45 minutes`,
        'r_application.orientation.pass.subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle_desc: [
        `Your progress will not be saved if you close the tab. Please complete the process in one sitting!`,
        `Your progress will not be saved if you close the tab. Please complete the process in one sitting!`,
        'r_application.orientation.pass.subtitle_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      step1: [
        `Watch our 3 orientation videos`,
        `Watch our 3 orientation videos`,
        'r_application.orientation.pass.step1',
        ``,
        ``,
        ``,
        ``,
      ],
      step2: [
        `Take two short quizzes to test your knowledge`,
        `Take a short quiz to test your knowledge`,
        'r_application.orientation.pass.step2',
        ``,
        ``,
        ``,
        ``,
      ],
      step3: [
        `Create your tutor profile`,
        `Create your tutor profile`,
        'r_application.orientation.pass.step3',
        ``,
        ``,
        ``,
        ``,
      ],
      step1_answer: [
        `The short multiple-choice quiz will ensure you're fully ready to begin tutoring.`,
        `These videos will cover Ringle, Navigating the plaform, and Leading different lesson types.`,
        'r_application.orientation.pass.step1_answer',
        ``,
        ``,
        ``,
        ``,
      ],
      step2_answer: [
        `Before you're able to open lessons, you need a shiny new profile to attract students!`,
        `The short multiple-choice quiz will ensure you're fully ready to begin tutoring.`,
        'r_application.orientation.pass.step2_answer',
        ``,
        ``,
        ``,
        ``,
      ],
      step3_answer: [
        `Before jumping into tutoring, you need a shiny new profile to attract students!`,
        `Before you're able to open lessons, you need a shiny new profile to attract students!`,
        'r_application.orientation.pass.step3_answer',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    step1: {
      title: [`Orientation`, `Orientation`, 'r_application.orientation.step1.title', ``, ``, ``, ``],
      desc: [
        `Your progress will not be saved if you close the tab. Please complete the process in one sitting`,
        `Your progress will not be saved if you close the tab. Please complete the process in one sitting`,
        'r_application.orientation.step1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      todo_list: [`To-Do list`, `To-Do list`, 'r_application.orientation.step1.todo_list', ``, ``, ``, ``],
      video: [`Video`, `Video`, 'r_application.orientation.step1.video', ``, ``, ``, ``],
      quiz: [`Quiz`, `Quiz`, 'r_application.orientation.step1.quiz', ``, ``, ``, ``],
      start_ot: [`Start Orientation`, `Start Orientation`, 'r_application.orientation.step1.start_ot', ``, ``, ``, ``],
      get_started: [`Get Started`, `Get Started`, 'r_application.orientation.step1.get_started', ``, ``, ``, ``],
    },
    video: {
      take_quiz: [`Take Quiz`, `Take Quiz`, 'r_application.orientation.video.take_quiz', ``, ``, ``, ``],
      confirm_vdieo: [
        `I confirm that I watched the video in full.`,
        `I have watched the video in full.`,
        'r_application.orientation.video.confirm_vdieo',
        ``,
        ``,
        ``,
        ``,
      ],
      outline: [`Outline`, `Outline`, 'r_application.orientation.video.outline', ``, ``, ``, ``],
      related_videos: [
        `Related Videos`,
        `Related Videos`,
        'r_application.orientation.video.related_videos',
        ``,
        ``,
        ``,
        ``,
      ],
      ot_quiz: [`Orientation Quiz`, `Orientation Quiz`, 'r_application.orientation.video.ot_quiz', ``, ``, ``, ``],
      confirm_modal: [
        <>
          Take the quiz to test if you’re ready to start!
          <br />
          <br />
          Please note that you won’t have access to the video during your quiz.
        </>,
        <>
          Take the quiz to test if you’re ready to start!
          <br />
          <br />
          Please note that you won’t have access to the video during your quiz.
        </>,
        'r_application.orientation.video.confirm_modal',
        ``,
        ``,
        ``,
        ``,
      ],
      cancel: [`Return to video`, `Return to video`, 'r_application.orientation.video.cancel', ``, ``, ``, ``],
      proceed: [`Take the quiz`, `Take the quiz`, 'r_application.orientation.video.proceed', ``, ``, ``, ``],
      retake_quiz: [`Retake Quiz`, `Retake Quiz`, 'r_application.orientation.video.retake_quiz', ``, ``, ``, ``],
      rewatch_quiz: [
        `Rewatch videos`,
        `Rewatch videos`,
        'r_application.orientation.video.rewatch_quiz',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    failed: {
      title: [`오리엔테이션 퀴즈 탈락`, `Oh no!`, 'r_application.orientation.failed.title', ``, ``, ``, ``],
      desc: [
        `Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description`,
        `You've reached the maximum number of quiz and missed both chances to demonstrate your understanding of necessary information before jumping into tutoring. Please click "Request to restart Orientation" below to be considered for another opportunity in retaking the Orientation.`,
        'r_application.orientation.failed.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      request_restart: [
        `Request to Restart OT`,
        `Request to restart Orientation`,
        'r_application.orientation.failed.request_restart',
        ``,
        ``,
        ``,
        ``,
      ],
      full_name: [
        `Your Full Name (as registered)`,
        `Full Name (as registered)`,
        'r_application.orientation.failed.full_name',
        ``,
        ``,
        ``,
        ``,
      ],
      sign_in_email: [
        `Sign-In Email`,
        `Sign-In Email`,
        'r_application.orientation.failed.sign_in_email',
        ``,
        ``,
        ``,
        ``,
      ],
      reason_for_requesting: [
        `Reason for requesting`,
        `Reason for the request`,
        'r_application.orientation.failed.reason_for_requesting',
        ``,
        ``,
        ``,
        ``,
      ],
      cancel: [`Cancel`, `Cancel`, 'r_application.orientation.failed.cancel', ``, ``, ``, ``],
      send_request: [`Send Request`, `Send request`, 'r_application.orientation.failed.send_request', ``, ``, ``, ``],
      requested_title: [
        `Your request has been successfully sent to Ringle team!`,
        `Your request has been successfully sent to Ringle team!`,
        'r_application.orientation.failed.requested_title',
        ``,
        ``,
        ``,
        ``,
      ],
      requested_desc: [
        `Upon your request, we'll review and advise you accordingly. We appreciate you taking time to complete the Orientation.`,
        `Upon your request, we'll review and advise you accordingly. We appreciate you taking time to complete the Orientation.`,
        'r_application.orientation.failed.requested_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      type_reason_for_requesting: [
        `Type reason for the request`,
        `Type reason for the request`,
        'r_application.orientation.failed.type_reason_for_requesting',
        ``,
        ``,
        ``,
        ``,
      ],
      confirm_modal_title: [
        `Your request has been successfully sent to Ringle team!`,
        `Your request has been successfully sent to Ringle team!`,
        'r_application.orientation.failed.confirm_modal_title',
        ``,
        ``,
        ``,
        ``,
      ],
      confirm_modal_desc: [
        `Upon your request, we'll review and advise you accordingly. We appreciate you taking time to complete the Orientation.`,
        `Upon your request, we'll review and advise you accordingly. We appreciate you taking time to complete the Orientation.`,
        'r_application.orientation.failed.confirm_modal_desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    quiz: {
      first_fail1: [
        `첫번째 오티 퀴즈 실패 페이지`,
        `Please review the answers to the questions you got wrong, rewatch the video if necessary, and try again.`,
        'r_application.orientation.quiz.first_fail1',
        ``,
        ``,
        ``,
        ``,
      ],
      first_fail2: [
        ``,
        <b>Please note: you will only have one chance to retry the quiz, so take your time to prepare!</b>,
        'r_application.orientation.quiz.first_fail2',
        ``,
        ``,
        ``,
        ``,
      ],
      success1: [
        `오티 퀴즈 성공시`,
        `You are certified Ringle ace and know everything that you need to know to beging tutoring!`,
        'r_application.orientation.quiz.success1',
        ``,
        ``,
        ``,
        ``,
      ],
      success2: [
        `오티 퀴즈 성공시`,
        `Click "Next" to set up your tutor profile.`,
        'r_application.orientation.quiz.success2',
        ``,
        ``,
        ``,
        ``,
      ],
      second_fail1: [
        `두번째 오티 퀴즈 실패 페이지`,
        `You have one more chance!`,
        'r_application.orientation.quiz.second_fail1',
        ``,
        ``,
        ``,
        ``,
      ],
      second_fail2: [
        ``,
        `Please note: you will only have one chance to retry the quiz, so take your time to prepare!`,
        'r_application.orientation.quiz.second_fail2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  profile: {
    page_title: [`Tutor Profile`, `Tutor Profile`, 'r_application.profile.page_title', ``, ``, ``, ``],
    title: [
      `Start creating your profile!`,
      `Start creating your profile!`,
      'r_application.profile.title',
      ``,
      ``,
      ``,
      ``,
    ],
    image: {
      title: [
        `Upload Your Profile Picture`,
        `Upload Your Profile Picture`,
        'r_application.profile.image.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `The ideal size for your Ringle profile picture is 170 x 170 pixels.`,
        `The ideal size for your Ringle profile picture is 170 x 170 pixels.`,
        'r_application.profile.image.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      precaution: [
        <>
          * Max. file size : 8 MB
          <br />* supported file format : jpg, png
        </>,
        <>
          * Max. file size : 8 MB
          <br />* supported file format : jpg, png
        </>,
        'r_application.profile.image.precaution',
        ``,
        ``,
        ``,
        ``,
      ],
      example: [`See Examples`, `See Examples`, 'r_application.profile.image.example', ``, ``, ``, ``],
      upload: [`Upload`, `Upload`, 'r_application.profile.image.upload', ``, ``, ``, ``],
      reupload: [`Re-upload`, `Re-upload`, 'r_application.profile.image.reupload', ``, ``, ``, ``],
      example_title: [
        `Profile Picture`,
        `Profile Picture`,
        'r_application.profile.image.example_title',
        ``,
        ``,
        ``,
        ``,
      ],
      example_desc: [
        `Although larger file sizes are great, try to avoid files with resolutions below 170 x 170 pixels.`,
        `Although larger file sizes are great, try to avoid files with resolutions below 170 x 170 pixels.`,
        'r_application.profile.image.example_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      good: [`Good`, `Good`, 'r_application.profile.image.good', ``, ``, ``, ``],
      bad: [`Bad`, `Bad`, 'r_application.profile.image.bad', ``, ``, ``, ``],
    },
    video: {
      title: [
        `Record your Self-Introduction Video`,
        `Record your Self-Introduction Video`,
        'r_application.profile.video.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `This short, 1-minute video will introduce you to Ringle students.`,
        `This short, 1-minute video will introduce you to Ringle students.`,
        'r_application.profile.video.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      example: [`See Examples`, `See Examples`, 'r_application.profile.video.example', ``, ``, ``, ``],
      record: [`Record`, `Record`, 'r_application.profile.video.record', ``, ``, ``, ``],
      example_title: [
        `Self-Introduction Video`,
        `Self-Introduction Video`,
        'r_application.profile.video.example_title',
        ``,
        ``,
        ``,
        ``,
      ],
      example_desc: [
        <Div textH5>
          <Div>• Please record the video from a quiet, well-lit space, with good audio and video quality.</Div>
          <Div>• Keep your video to no more than 1 minute in length.</Div>
          <Div>• Start with a brief introduction that includes your name, major, and job or dream job.</Div>
          <Div>
            • Share your love for English and relevant experience, including work, hobbies and extracurriculars!
          </Div>
          <Div>
            • You don't need to follow a set structure! Instead, say whatever you think will best represent your
            tutoring abilities to prospective students.
          </Div>
        </Div>,
        <Div textH5>
          <Div>• Please record the video from a quiet, well-lit space, with good audio and video quality.</Div>
          <Div>• Keep your video to no more than 1 minute in length.</Div>
          <Div>• Start with a brief introduction that includes your name, major, and job or dream job.</Div>
          <Div>
            • Share your love for English and relevant experience, including work, hobbies and extracurriculars!
          </Div>
          <Div>
            • You don't need to follow a set structure! Instead, say whatever you think will best represent your
            tutoring abilities to prospective students.
          </Div>
        </Div>,
        'r_application.profile.video.example_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      modal_title: [
        <Div textH1 fontBold>
          Record your{' '}
          <Div spanTag textPrimary>
            1-minute
          </Div>{' '}
          Self-Introduction Video
        </Div>,
        <Div textH1 fontBold>
          Record your{' '}
          <Div spanTag textPrimary>
            1-minute
          </Div>{' '}
          Self-Introduction Video
        </Div>,
        'r_application.profile.video.modal_title',
        ``,
        ``,
        ``,
        ``,
      ],
      modal_desc: [
        `This video will introduce both you and your speaking style to Ringle students. As such, please ensure that it is of a high quality, as it will often inform whether a student chooses to book a lesson with you.
You will be able to edit, re-record or re-upload this video after the initial upload if necessary.`,
        `This video will introduce both you and your speaking style to Ringle students. As such, please ensure that it is of a high quality, as it will often inform whether a student chooses to book a lesson with you.
You will be able to edit, re-record or re-upload this video after the initial upload if necessary.`,
        'r_application.profile.video.modal_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      next: [`Next`, `Next`, 'r_application.profile.video.next', ``, ``, ``, ``],
      get_ready_before_recording: [
        `Get Ready before Recording`,
        `Get ready before recording`,
        'r_application.profile.video.get_ready_before_recording',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline: [
        `Guideline for Recording Self-Introduction Video`,
        `Guideline for Recording Self-Introduction Video`,
        'r_application.profile.video.guideline',
        ``,
        ``,
        ``,
        ``,
      ],
      preparation: [`Setting`, `Preparation`, 'r_application.profile.video.preparation', ``, ``, ``, ``],
      preparation_desc: [
        <>
          <Div>• Please record the video from a quiet, well-lit space, with good audio and video quality.</Div>
          <Div>• Keep your video to no more than 1 minute in length.</Div>
        </>,
        <>
          <Div>• Please record the video from a quiet, well-lit space, with good audio and video quality.</Div>
          <Div>• Keep your video to no more than 1 minute in length.</Div>
        </>,
        'r_application.profile.video.preparation_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      structure_suggestion: [
        `Structure Suggestion`,
        `Structure Suggestion`,
        'r_application.profile.video.structure_suggestion',
        ``,
        ``,
        ``,
        ``,
      ],
      brief_introduction: [
        `Brief Introduction`,
        `Brief Introduction`,
        'r_application.profile.video.brief_introduction',
        ``,
        ``,
        ``,
        ``,
      ],
      brief_introduction_desc: [
        `Share your name, school, major, general location and job or dream job.`,
        `Share your name, school, major, general location and job or dream job.`,
        'r_application.profile.video.brief_introduction_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      interests: [`Your interests`, `Your interests`, 'r_application.profile.video.interests', ``, ``, ``, ``],
      interests_desc: [
        `Discuss your love for English and relevant experience, including work, hobbies and extracurriculars!`,
        `Discuss your love for English and relevant experience, including work, hobbies and extracurriculars!`,
        'r_application.profile.video.interests_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      closing_comment: [
        `Closing comment`,
        `Closing comment`,
        'r_application.profile.video.closing_comment',
        ``,
        ``,
        ``,
        ``,
      ],
      closing_comment_desc: [
        <>
          Wrap up the video with a brief closing remark that encourages the viewer to engage with your profile.
          <br />
          (I.e. "Thanks for watching! I hope to see you soon.")
        </>,
        <>
          Wrap up the video with a brief closing remark that encourages the viewer to engage with your profile.
          <br />
          (I.e. "Thanks for watching! I hope to see you soon.")
        </>,
        'r_application.profile.video.closing_comment_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      draft_script: [
        `Draft a script (optional)`,
        `Draft a script (optional)`,
        'r_application.profile.video.draft_script',
        ``,
        ``,
        ``,
        ``,
      ],
      draft_script_desc: [
        `If you'd like to prepare notes or a script, you can do so here.`,
        `If you'd like to prepare notes or a script, you can do so here.`,
        'r_application.profile.video.draft_script_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      draft_script_placeholder: [
        `Write your script here...`,
        `Write your script here...`,
        'r_application.profile.video.draft_script_placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
      ready: [`I'm Ready`, `I'm Ready`, 'r_application.profile.video.ready', ``, ``, ``, ``],
      back: [`Back`, `Back`, 'r_application.profile.video.back', ``, ``, ``, ``],
      start_title: [`Start Recording`, `Start Recording`, 'r_application.profile.video.start_title', ``, ``, ``, ``],
      script: [`Script`, `Script`, 'r_application.profile.video.script', ``, ``, ``, ``],
    },
    next: [`Next`, `Next`, 'r_application.profile.next', ``, ``, ``, ``],
    introduction: {
      title: [
        `Please now create a written profile to allow prospective students to learn more about you.`,
        `Create a written profile!`,
        'r_application.profile.introduction.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `Students will be able to see this content when browsing tutor profiles and it will often inform their decision to book a lesson with you. Take your time to get it right!`,
        `Students will be able to see this content when browsing tutor profiles and it will often inform their decision to book a lesson with you. Take your time to get it right!`,
        'r_application.profile.introduction.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      intro: [`Introduction`, `Introduction`, 'r_application.profile.introduction.intro', ``, ``, ``, ``],
      see_example: [`See Examples`, `See Examples`, 'r_application.profile.introduction.see_example', ``, ``, ``, ``],
      minimum: [`Minimum`, `Minimum`, 'r_application.profile.introduction.minimum', ``, ``, ``, ``],
      best_practice: [
        `Best Practice`,
        `Best Practice`,
        'r_application.profile.introduction.best_practice',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    junior_preference: {
      title: [
        `Complete your Profile!`,
        `Complete your profile!`,
        'r_application.profile.junior_preference.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `In this final step, indicate your teaching preferences and expertise. These will be shared with students.`,
        `In this final step, indicate your teaching preferences and expertise. These will be shared with students.`,
        'r_application.profile.junior_preference.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      junior: [
        `Junior Preference`,
        `Junior Preference`,
        'r_application.profile.junior_preference.junior',
        ``,
        ``,
        ``,
        ``,
      ],
      junior_desc: [
        `Please indicate whether you’re happy to teach junior students (under 18; K-12)`,
        `Please indicate whether you’re happy to teach junior students (under 18; K-12)`,
        'r_application.profile.junior_preference.junior_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      select_1: [
        `I would love to teach junior students`,
        `I would love to teach junior students`,
        'r_application.profile.junior_preference.select_1',
        ``,
        ``,
        ``,
        ``,
      ],
      select_2: [
        `I have no preference`,
        `I have no preference`,
        'r_application.profile.junior_preference.select_2',
        ``,
        ``,
        ``,
        ``,
      ],
      select_3: [
        `I do not want to teach junior students`,
        `I do not want to teach junior students`,
        'r_application.profile.junior_preference.select_3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    keyword: {
      title: [`Create your keywords`, `Create your keywords`, 'r_application.profile.keyword.title', ``, ``, ``, ``],
      desc: [
        `Choose keywords that you feel best represent your experience and interests. Put a star by the keyword in each category that you feel is more important. These will be highlighted on your profile's cover page, so choose wisely!`,
        `Choose keywords that you feel best represent your experience and interests. Put a star by the keyword in each category that you feel is more important. These will be highlighted on your profile's cover page, so choose wisely!`,
        'r_application.profile.keyword.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      experience: [`Experience`, `Experience`, 'r_application.profile.keyword.experience', ``, ``, ``, ``],
      interests: [`Interests`, `Interests`, 'r_application.profile.keyword.interests', ``, ``, ``, ``],
    },
    back: [`Back`, `Back`, 'r_application.profile.back', ``, ``, ``, ``],
    save: [`Save`, `Save`, 'r_application.profile.save', ``, ``, ``, ``],
  },
  policy: {
    title: [`Ringle Tutor Policy`, `Ringle Tutor Policy`, 'r_application.policy.title', ``, ``, ``, ``],
    page_title: [
      `Step3. Policy Agreement`,
      `Step3. Policy Agreement`,
      'r_application.policy.page_title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `Please check the box below to indicate that you read and agree to the conditions of Ringle Tutor Policy.`,
      `Please check the box below to indicate that you read and agree to the conditions of Ringle Tutor Policy.`,
      'r_application.policy.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    policy_btn: [
      `Read Ringle Tutor Policy`,
      `Read Ringle Tutor Policy`,
      'r_application.policy.policy_btn',
      ``,
      ``,
      ``,
      ``,
    ],
    agree_label: [
      `I acknowledge I have received a copy of the Ringle Tutor Policy, and I agree to read and follow these policies. I am aware if, at any time, I have questions regarding Ringle’s policies, I should direct them to the Ringle Tutor Team. I agree I shall abide by its provisions. I am also aware that Ringle, at any time, may on reasonable notice, change, add to, or delete from the provisions of this policy, and it is my responsibility to follow up and ask for clarificaition on any policy.`,
      `I acknowledge I have received a copy of the Ringle Tutor Policy, and I agree to read and follow these policies. I am aware if, at any time, I have questions regarding Ringle’s policies, I should direct them to the Ringle Tutor Team. I agree I shall abide by its provisions. I am also aware that Ringle, at any time, may on reasonable notice, change, add to, or delete from the provisions of this policy, and it is my responsibility to follow up and ask for clarificaition on any policy.`,
      'r_application.policy.agree_label',
      ``,
      ``,
      ``,
      ``,
    ],
    back: [`Back`, `Back`, 'r_application.policy.back', ``, ``, ``, ``],
    save: [`Save`, `Save`, 'r_application.policy.save', ``, ``, ``, ``],
  },
  after_policy_agree: {
    title: [
      `Get ready to open some lessons!`,
      `Get ready to open some lessons!`,
      'r_application.after_policy_agree.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `Now that you've finished our application and orientation processes, you're more than ready to jump into tutoring on Ringle.
Hit the button below to open up your schedule and start receiving bookings!`,
      `Now that you've finished our application and orientation processes, you're more than ready to jump into tutoring on Ringle.
Hit the button below to open up your schedule and start receiving bookings!`,
      'r_application.after_policy_agree.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`Open My Schedule`, `Open My Schedule`, 'r_application.after_policy_agree.btn', ``, ``, ``, ``],
  },
  after_first_lesson: {
    title: [
      `Congrats! You just completed your first lesson!`,
      `Congrats! You just completed your first lesson!`,
      'r_application.after_first_lesson.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        Way to go! You're officially a Ringle tutor!
        <br />
        <br />
        Before you can withdraw earnings, you need to provide payment information, including a taxpayer form and an
        active PayPal account.
      </>,
      <>
        Way to go! You're officially a Ringle tutor!
        <br />
        <br />
        Before you can withdraw earnings, you need to provide payment information, including a taxpayer form and an
        active PayPal account.
      </>,
      'r_application.after_first_lesson.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [
      `Complete Tax Payer Information`,
      `Complete Tax Payer Information`,
      'r_application.after_first_lesson.btn',
      ``,
      ``,
      ``,
      ``,
    ],
    payment: {
      title: [
        `Update your Tax Payer Information`,
        `Update your Tax Payer Information`,
        'r_application.after_first_lesson.payment.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `Before you can withdraw earnings, you need to provide payment information, including a taxpayer form and an active PayPal account.`,
        `Before you can withdraw earnings, you need to provide payment information, including a taxpayer form and an active PayPal account.`,
        'r_application.after_first_lesson.payment.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      btn: [
        `Complete Tax Payer Information`,
        `Complete Tax Payer Information`,
        'r_application.after_first_lesson.payment.btn',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const r_schedule = {
  remove_success_msg_desc: [
    ``,
    `Schedule has been successfully removed.`,
    'r_schedule.remove_success_msg_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  remove_success_msg: [``, `Schedule removed`, 'r_schedule.remove_success_msg', ``, ``, ``, ``],
  remove: [``, `Remove`, 'r_schedule.remove', ``, ``, ``, ``],
  remove_slot_msg: [``, `Delete available slot`, 'r_schedule.remove_slot_msg', ``, ``, ``, ``],
  remove_slot_msg_desc: [
    ``,
    `You are about to remove your available slots(s) from your calendar. Do you want to proceed?`,
    'r_schedule.remove_slot_msg_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  s_success_msg_desc: [``, `Your new schdule has been saved`, 'r_schedule.s_success_msg_desc', ``, ``, ``, ``],
  s_success_msg: [``, `Schedule Saved`, 'r_schedule.s_success_msg', ``, ``, ``, ``],
  open_slot_fail_msg: [``, `Schedule Opening failed`, 'r_schedule.open_slot_fail_msg', ``, ``, ``, ``],
  date_time: [``, `Date / Time`, 'r_schedule.date_time', ``, ``, ``, ``],
  duration: [``, `duration`, 'r_schedule.duration', ``, ``, ``, ``],
  set_as_repeated: [``, `Set as recurring schedule`, 'r_schedule.set_as_repeated', ``, ``, ``, ``],
  end_repeat: [``, `End recurring schedule on`, 'r_schedule.end_repeat', ``, ``, ``, ``],
  requested: [``, `Requested`, 'r_schedule.requested', ``, ``, ``, ``],
  scheduled_lesson: [``, `Scheduled lesson`, 'r_schedule.scheduled_lesson', ``, ``, ``, ``],
  repeat: [``, `Repeat`, 'r_schedule.repeat', ``, ``, ``, ``],
  uanvailable: [``, `Unavailable`, 'r_schedule.uanvailable', ``, ``, ``, ``],
  peaktime: [``, `Peak time`, 'r_schedule.peaktime', ``, ``, ``, ``],
  available: [``, `Available`, 'r_schedule.available', ``, ``, ``, ``],
  sync_calendar_btn: [``, `Sync my google calendar`, 'r_schedule.sync_calendar_btn', ``, ``, ``, ``],
  google_calendar: [``, `Google calendar`, 'r_schedule.google_calendar', ``, ``, ``, ``],
  teens_s_lessons: [``, `Teens scheduled lessons`, 'r_schedule.teens_s_lessons', ``, ``, ``, ``],
  adult_s_lessons: [``, `Adult scheduled lessons`, 'r_schedule.adult_s_lessons', ``, ``, ``, ``],
  weekly_lessons: [``, `Weekly lessons`, 'r_schedule.weekly_lessons', ``, ``, ``, ``],
  updated: [``, `Updated`, 'r_schedule.updated', ``, ``, ``, ``],
  remove_confirm_message: [
    ``,
    `You are about to remove your Google calendar from your scheduling page. If you want to proceed, hit "Remove`,
    'r_schedule.remove_confirm_message',
    ``,
    ``,
    ``,
    ``,
  ],
  remove_calendar: [``, `Remove Google calendar`, 'r_schedule.remove_calendar', ``, ``, ``, ``],
  schedule_guideline: [``, `Schedule Guideline`, 'r_schedule.schedule_guideline', ``, ``, ``, ``],
  s_guideline_content: [``, `content needed`, 'r_schedule.s_guideline_content', ``, ``, ``, ``],
  announce_placeholder: [
    ``,
    `Example:\nHi all! I’ll be taking a brief break from 9th - 13th March, but will be back to releasing lessons weekly by 14th March.\nI prefer to teach Correction-Focused rather than Discussion-Focused lessons, and also love leading Mock Interview lessons on my area of expertise, UI/UX design.\nIf you are looking for a tutor to teach your child, I’d recommend using one of Ringle’s other fantastic tutors, as I’m not as good with children as with adults.`,
    'r_schedule.announce_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_announcement: [``, `Tutor's Announcement`, 'r_schedule.tutors_announcement', ``, ``, ``, ``],
  tutors_announcement_desc: [
    ``,
    `Use this space to keep your students updated about your schedule, teaching preferences and expertise.`,
    'r_schedule.tutors_announcement_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  schedule_settings: [``, `My Scheduling Routine`, 'r_schedule.schedule_settings', ``, ``, ``, ``],
  open_slot: [``, `Open lesson slot`, 'r_schedule.open_slot', ``, ``, ``, ``],
  lesson_id: [``, `Lesson ID`, 'r_schedule.lesson_id', ``, ``, ``, ``],
  lesson_request: [``, `Requested Lesson`, 'r_schedule.lesson_request', ``, ``, ``, ``],
  cancel: [``, `Cancel`, 'r_schedule.cancel', ``, ``, ``, ``],
  save: [``, `Save`, 'r_schedule.save', ``, ``, ``, ``],
  opended_slot: [``, `Opened lesson slot`, 'r_schedule.opended_slot', ``, ``, ``, ``],
  cant_exceed_40: [``, `Maximum hours per week is 40 hours`, 'r_schedule.cant_exceed_40', ``, ``, ``, ``],
  available_hours: [``, `Desired lesson hours per week`, 'r_schedule.available_hours', ``, ``, ``, ``],
  update_schedule: [
    ``,
    `Select the day that you typically open your weekly schedule`,
    'r_schedule.update_schedule',
    ``,
    ``,
    ``,
    ``,
  ],
  my_usual_schedule: [
    ``,
    `Please update your scheduling routine and expectations. We will promptly support what you are planning for!`,
    'r_schedule.my_usual_schedule',
    ``,
    ``,
    ``,
    ``,
  ],
  schecule_setting_succ_toast: [
    ``,
    `Successfully updated schedule settings`,
    'r_schedule.schecule_setting_succ_toast',
    ``,
    ``,
    ``,
    ``,
  ],
  might_be_junior: [``, `This student might be a junior student (K-12).`, 'r_schedule.might_be_junior', ``, ``, ``, ``],
  repeat_alert: [``, `Select at least one day to make a recurring slot.`, 'r_schedule.repeat_alert', ``, ``, ``, ``],
  s_failed_msg: [``, `Failed to open schedule`, 'r_schedule.s_failed_msg', ``, ``, ``, ``],
  set_timezone: [``, `Change your time zone`, 'r_schedule.set_timezone', ``, ``, ``, ``],
  timezone_placeholder: [
    ``,
    `Enter or select the closest city to your location to indicate your time zone.`,
    'r_schedule.timezone_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  student_canceled_title: [``, `Student Canceled`, 'r_schedule.student_canceled_title', ``, ``, ``, ``],
  student_canceled_desc: [
    ``,
    `When a lesson is canceled, you can choose to cancel the lesson as well or keep the slot open for a new student to automatically be assigned.`,
    'r_schedule.student_canceled_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  settings_fail_toast: [``, `Update failed. Please try again.`, 'r_schedule.settings_fail_toast', ``, ``, ``, ``],
  status_success_toast: [
    ``,
    `Successfully updated Tutor's Announcement`,
    'r_schedule.status_success_toast',
    ``,
    ``,
    ``,
    ``,
  ],
  status_fail_toast: [``, `Save failed. Please try again.`, 'r_schedule.status_fail_toast', ``, ``, ``, ``],
  decline_lesson_btn: [`Decline`, `Decline`, 'r_schedule.decline_lesson_btn', ``, ``, ``, ``],
  accept_lesson_btn: [`Accept`, `Accept`, 'r_schedule.accept_lesson_btn', ``, ``, ``, ``],
  decline_alert_1: [``, `Are you sure you want to decline this lesson?`, 'r_schedule.decline_alert_1', ``, ``, ``, ``],
  decline_alert_2: [
    ``,
    `After declining, you won't be able to undo this action.`,
    'r_schedule.decline_alert_2',
    ``,
    ``,
    ``,
    ``,
  ],
  decline_alert_3: [
    ``,
    `Your acceptance rate can be affected by declining this lesson request.`,
    'r_schedule.decline_alert_3',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_alert_1: [
    ``,
    `You are about to accept this lesson. Do you want to proceed?`,
    'r_schedule.confirm_alert_1',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_alert_2: [
    ``,
    `By confirming the lesson request(s), you are accepting the terms
              and conditions of the lesson agreement.`,
    'r_schedule.confirm_alert_2',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_agreement: [``, `Lesson Agreement`, 'r_schedule.lesson_agreement', ``, ``, ``, ``],
  keep_the_lesson: [``, `Keep the lesson`, 'r_schedule.keep_the_lesson', ``, ``, ``, ``],
  not_changable_tooltip: [
    ``,
    <>
      Having once cancelled or not accepted,
      <br />
      you cannot receive new lessons in this time slot
    </>,
    'r_schedule.not_changable_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  no: [``, `No`, 'r_schedule.no', ``, ``, ``, ``],
  yes: [``, `Yes`, 'r_schedule.yes', ``, ``, ``, ``],
  delete: [``, `Delete`, 'r_schedule.delete', ``, ``, ``, ``],
  this_event_only: [``, `This slot only`, 'r_schedule.this_event_only', ``, ``, ``, ``],
  apply_to_all_future: [``, `This and following slots`, 'r_schedule.apply_to_all_future', ``, ``, ``, ``],
  schedule_removal_failed: [``, `Failed to delete`, 'r_schedule.schedule_removal_failed', ``, ``, ``, ``],
  change_slot_status_confirm: [
    ``,
    `You are about to remove an available slot from your calendar. Do you want to proceed?`,
    'r_schedule.change_slot_status_confirm',
    ``,
    ``,
    ``,
    ``,
  ],
  strike_tutor_warning: [
    ``,
    `Since you have accumulated too many strikes, you are no
              longer able to schedule lessons. Please submit an appeal to
              reactivate your account through the Chatbot. We will review your
              account and process your request as soon as possible. Thank you.`,
    'r_schedule.strike_tutor_warning',
    ``,
    ``,
    ``,
    ``,
  ],
  to: [``, `to`, 'r_schedule.to', ``, ``, ``, ``],
  end_repeat_2: [``, `End recurring schedule`, 'r_schedule.end_repeat_2', ``, ``, ``, ``],
  tooltip: {
    reopen: [``, `Click to re-open this slot`, 'r_schedule.tooltip.reopen', ``, ``, ``, ``],
    cannot_change: [
      ``,
      <>
        Having once calcelled or not accepted you cannot
        <br />
        receive new lessons in this time slot for 48 hours.
      </>,
      'r_schedule.tooltip.cannot_change',
      ``,
      ``,
      ``,
      ``,
    ],
    cannot_reopen: [
      ``,
      <>
        The slot cannot be reopened since the lesson <br />
        request was automatically declined
      </>,
      'r_schedule.tooltip.cannot_reopen',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  reached_forty_hours: [
    <>
      You have reached 40 hour limits! <br />
      You cannot open slots or accept further lessons for this week. If you need any help, please contact the Ringle
      Team.{' '}
    </>,
    <>
      You have reached 40 hour limits! <br />
      You cannot open slots or accept further lessons for this week. If you need any help, please contact the Ringle
      Team.{' '}
    </>,
    'r_schedule.reached_forty_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  hour_limit: [`40 Hour Limits`, `40 Hour Limits`, 'r_schedule.hour_limit', ``, ``, ``, ``],
  faq_popup: {
    title: [``, `Frequently asked questions about Scheduling`, 'r_schedule.faq_popup.title', ``, ``, ``, ``],
    q1: [
      ``,
      <>
        Do my originally scheduled lessons get automatically canceled if I edit my schedule and delete an opened slot?
      </>,
      'r_schedule.faq_popup.q1',
      ``,
      ``,
      ``,
      ``,
    ],
    q2: [
      ``,
      <>Can I receive another lesson request after I miss or decline a lesson request?</>,
      'r_schedule.faq_popup.q2',
      ``,
      ``,
      ``,
      ``,
    ],
    q3: [
      ``,
      <>What are Peak hours and how do I receive more lesson requests? </>,
      'r_schedule.faq_popup.q3',
      ``,
      ``,
      ``,
      ``,
    ],
    q4: [``, <>When can I receive my 3% incentive bonus?</>, 'r_schedule.faq_popup.q4', ``, ``, ``, ``],
    q5: [``, <>What is "Tutors Announcement”?</>, 'r_schedule.faq_popup.q5', ``, ``, ``, ``],
    q6: [``, <>How many hours of lessons can I conduct per week?</>, 'r_schedule.faq_popup.q6', ``, ``, ``, ``],
    q7: [``, <>How many lesson slots can I open?</>, 'r_schedule.faq_popup.q7', ``, ``, ``, ``],
    a1: [
      ``,
      <>
        No. Lessons that are previously scheduled will never be canceled even if you delete an opened slot for that time
        to rearrange your schedules.
      </>,
      'r_schedule.faq_popup.a1',
      ``,
      ``,
      ``,
      ``,
    ],
    a2: [
      ``,
      <>
        Requests for slots that are auto-declined will not be able to be reopened. We’ll be updating our notification
        system to ensure that you are all thoroughly notified about any pending lesson requests to ensure no lessons are
        auto-declined by accident.
        <br />
        If you actively decline a request, the slot will be available to reopen after 48 hours.
      </>,
      'r_schedule.faq_popup.a2',
      ``,
      ``,
      ``,
      ``,
    ],
    a3: [
      ``,
      <>
        <b>Peak Hours</b> are updated based on the demand and supply per slot. Current peak hours are{' '}
        <b>5 - 9 AM (EST)</b>. Peak hours are designated based on our data of the students’ lesson requests. Opening
        time slots as many as you can for the peak time will help a lot in receiving multiple lesson requests.
        Especially for the new and returning tutors.
      </>,
      'r_schedule.faq_popup.a3',
      ``,
      ``,
      ``,
      ``,
    ],
    a4: [
      ``,
      <>
        You can receive your 3% incentive bonus for all completed lessons (including Student No-Shows) that were
        scheduled (based on the date you’ve opened the slot) more than two weeks in advance.
      </>,
      'r_schedule.faq_popup.a4',
      ``,
      ``,
      ``,
      ``,
    ],
    a5: [
      ``,
      <>
        Make announcements or leave a short message to students through ‘Tutors Announcement’! You can make
        notifications about your holiday schedules or other exceptional situations when changes are to be made to your
        regular schedules. Students will be able to easily check out your announcements or special events through this
        space.
      </>,
      'r_schedule.faq_popup.a5',
      ``,
      ``,
      ``,
      ``,
    ],
    a6: [
      ``,
      <>
        You can conduct maximum of 40 hours of lesson per week (40-min lesson is counted as 1 hour, adn 20-min lesson as
        0.5 hours).
      </>,
      'r_schedule.faq_popup.a6',
      ``,
      ``,
      ``,
      ``,
    ],
    a7: [
      ``,
      <>
        You are allowed to open up to 60 hours of lessons per week. This helps you increase the chance of receiving the
        full 40 hours of lessons each week. Please note, as soon as 40 hours of lessons have been scheduled, the
        remaining slots will be automatically closed.
      </>,
      'r_schedule.faq_popup.a7',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq: {
    btn: [``, `Schedule FAQ`, 'r_schedule.faq.btn', ``, ``, ``, ``],
  },
  notice_banner: {
    title: [``, `Important notice`, 'r_schedule.notice_banner.title', ``, ``, ``, ``],
  },
  peak_time_banner: {
    text: [
      ``,
      `You will find the peak hours highlighted in yellow on the schedule calendar. We will update the peak hours based on the supply & demand for each slot and continuously inform you of the latest information.`,
      'r_schedule.peak_time_banner.text',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [``, `Open slots during peak hours`, 'r_schedule.peak_time_banner.title', ``, ``, ``, ``],
  },
  faq_confirm_popup: {
    title: [``, `Check Schedule FAQ`, 'r_schedule.faq_confirm_popup.title', ``, ``, ``, ``],
    content: [
      ``,
      `Check frequently asked questions and answers about scheduling policy and guidelines. Please contact the Ringle team for any further questions.`,
      'r_schedule.faq_confirm_popup.content',
      `Check frequently asked questions and answers about scheduling policy and guidelines. Please contact the Ringle team for any further questions.`,
      ``,
      ``,
      ``,
    ],
  },
  unassigned_lessons: [``, `Unassigned Lessons`, 'r_schedule.unassigned_lessons', ``, ``, ``, ``],
  requested_lessons: [``, `Requested Lessons`, 'r_schedule.requested_lessons', ``, ``, ``, ``],
  slot_desc_peaktime: [``, `More likely to get assigned on peak time`, 'r_schedule.slot_desc_peaktime', ``, ``, ``, ``],
  unassigned_lesson: [``, `Unassigned Lesson(s)`, 'r_schedule.unassigned_lesson', `Unassigned Lesson(s)`, ``, ``, ``],
  requested_lesson: [``, `Requested Lesson(s)`, 'r_schedule.requested_lesson', `Requested Lesson(s)`, ``, ``, ``],
  lesson_list_tab: {
    requested: [``, `Requested`, 'r_schedule.lesson_list_tab.requested', ``, ``, ``, ``],
    unassigned: [``, `Unassigned`, 'r_schedule.lesson_list_tab.unassigned', ``, ``, ``, ``],
  },
  no_unassigned_lesson: [
    ``,
    `No unassigned lessons`,
    'r_schedule.no_unassigned_lesson',
    `No unassigned lessons`,
    ``,
    ``,
    ``,
  ],
  no_requested_lesson: [
    ``,
    `You have no requested lessons to confirm.`,
    'r_schedule.no_requested_lesson',
    `You have no requested lessons to confirm.`,
    ``,
    ``,
    ``,
  ],
  check_lesson_history: [``, `Check your Previous Lesson history`, 'r_schedule.check_lesson_history', ``, ``, ``, ``],
  performance_log: [``, `Performance Log`, 'r_schedule.performance_log', ``, ``, ``, ``],
  decline_alert_4: [
    ``,
    `Declining this lesson offer will not affect your acceptance rate`,
    'r_schedule.decline_alert_4',
    ``,
    ``,
    ``,
    ``,
  ],
  auto_lock: {
    modal1: {
      title: [``, `Daily Cap Setting`, 'r_schedule.auto_lock.modal1.title', ``, ``, ``, ``],
      subtitle: [
        ``,
        `Open lesson hours directly on the scheduling page. Then, set a cap of how many hours you actually would like to teach within this time frame. Once you receive lesson requests that reach this maximum cap, your remaining slots will be automatically closed.`,
        'r_schedule.auto_lock.modal1.subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: (num) => [``, `min ${num}`, 'r_schedule.auto_lock.modal1.placeholder1', ``, ``, ``, ``],
      hours: [``, `hours`, 'r_schedule.auto_lock.modal1.hours', ``, ``, ``, ``],
      alert_msg1: [
        ``,
        `Enter the number of hours that you would like to teach.`,
        'r_schedule.auto_lock.modal1.alert_msg1',
        ``,
        ``,
        ``,
        ``,
      ],
      alert_msg2: [
        ``,
        `Daily Cap hours must exceed 50% of your opened hours`,
        'r_schedule.auto_lock.modal1.alert_msg2',
        ``,
        ``,
        ``,
        ``,
      ],
      btn1: [``, `Cancel`, 'r_schedule.auto_lock.modal1.btn1', ``, ``, ``, ``],
      btn2: [``, `Save`, 'r_schedule.auto_lock.modal1.btn2', ``, ``, ``, ``],
      toast_msg1: [``, `Daily Cap hours successfully set!`, 'r_schedule.auto_lock.modal1.toast_msg1', ``, ``, ``, ``],
      toast_msg2: [
        ``,
        `Daily Cap hours must exceed 50% of your opened hours`,
        'r_schedule.auto_lock.modal1.toast_msg2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    modal2: {
      btn1: [``, `OK`, 'r_schedule.auto_lock.modal2.btn1', ``, ``, ``, ``],
    },
    modal4: {
      btn1: [``, `OK`, 'r_schedule.auto_lock.modal4.btn1', ``, ``, ``, ``],
    },
    tooltip1: [
      ``,
      `Your Daily Cap has been reached, and the remaining slots have been closed.`,
      'r_schedule.auto_lock.tooltip1',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  peak_time: {
    bubble: {
      button: [``, `I got it`, 'r_schedule.peak_time.bubble.button', ``, ``, ``, ``],
      title: [``, `Check New Peak Time!`, 'r_schedule.peak_time.bubble.title', ``, ``, ``, ``],
    },
  },
}
export const s_certificate = {
  number_of_completed_lessons: (lessonCount, purchaseCount) => [
    <>
      (수강한 수업 수: {lessonCount}회/ 구매한 수업 수: {purchaseCount}회)
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    's_certificate.number_of_completed_lessons',
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
  ],
  ringle_english_education_service: [
    `㈜링글잉글리시에듀케이션서비스`,
    `Ringle English Education Service (Ringle Inc.)`,
    's_certificate.ringle_english_education_service',
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
    `Ringle English Education Service (Ringle Inc.)`,
  ],
  select_the_issue_priod: (firstPeriod, secondPeriod) => [
    <>
      {firstPeriod}~{secondPeriod} 내 증빙이 필요하신 기간을 선택해주세요.
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    's_certificate.select_the_issue_priod',
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
  ],
  set_the_beginning_date: [
    `1. 결제일 이후 시작일을 설정할 수 있습니다.`,
    `1. You can set the beginning date after the payment date.`,
    's_certificate.set_the_beginning_date',
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
    `1. You can set the beginning date after the payment date.`,
  ],
  be_at_least_1_won: [
    `1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least ₩1.`,
    's_certificate.be_at_least_1_won',
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
    `1. The issued amount should be at least ₩1.`,
  ],
  be_at_least_1_dollar: [
    `1. 증빙 금액은 최소 1$ 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least $1.`,
    's_certificate.be_at_least_1_dollar',
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
    `1. The issued amount should be at least $1.`,
  ],
  one_hundred_thousand_won: [
    `+10만원`,
    `+ ₩100,000`,
    's_certificate.one_hundred_thousand_won',
    `+ ₩100,000`,
    `+ ₩100,000`,
    `+ ₩100,000`,
    `+ ₩100,000`,
  ],
  ten_thousand_won: [
    `+1만원`,
    `+ ₩10,000`,
    's_certificate.ten_thousand_won',
    `+ ₩10,000`,
    `+ ₩10,000`,
    `+ ₩10,000`,
    `+ ₩10,000`,
  ],
  cannot_set_been_issued: [
    `2. 이미 증빙했던 기간 재설정은 불가합니다.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    's_certificate.cannot_set_been_issued',
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
  ],
  issue_certificate_once_lesson: [
    `2. 전체 금액 증빙은 수강증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_lesson ',
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
  ],
  issue_certificate_once_attend: [
    `2. 전체 금액 증빙은 출석증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_attend',
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
    `2. You can only issue certificate for total price if you issue once.`,
  ],
  set_atleast_more_than_two_days: [
    `3. 당일 증빙은 불가하며, 최소 2일 이상 증빙 기간을 설정해야 합니다.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    's_certificate.set_atleast_more_than_two_days',
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    `3. You cannot use today's date and the period should span at least two days.`,
  ],
  fifty_thousand_won: [
    `+5만원`,
    `+ ₩50,000`,
    's_certificate.fifty_thousand_won',
    `+ ₩50,000`,
    `+ ₩50,000`,
    `+ ₩50,000`,
    `+ ₩50,000`,
  ],
  subtotal: [`결제금액`, `Subtotal`, 's_certificate.subtotal', `Subtotal`, `Subtotal`, `Subtotal`, `Subtotal`],
  payment_date_small: [
    `결제일`,
    `Payment date`,
    's_certificate.payment_date_small',
    `Payment date`,
    `Payment date`,
    `Payment date`,
    `Payment date`,
  ],
  payment_date_large: [
    `결제일자`,
    `Payment Date`,
    's_certificate.payment_date_large',
    `Payment Date`,
    `Payment Date`,
    `Payment Date`,
    `Payment Date`,
  ],
  leave_the_possible_amount_for_the_next_issuance: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 1원으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance',
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
  ],
  leave_the_possible_amount_for_the_next_issuance_dollar: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 $1으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance_dollar',
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
  ],
  provider: [`공급자`, `Co.`, 's_certificate.provider', `Co.`, `Co.`, `Co.`, `Co.`],
  course: [`과정명`, `Course`, 's_certificate.course', `Course`, `Course`, `Course`, `Course`],
  education_service: [
    `교육서비스`,
    `Education Service`,
    's_certificate.education_service',
    `Education Service`,
    `Education Service`,
    `Education Service`,
    `Education Service`,
  ],
  choose_in_the_price_of_purchased_credits: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits',
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
  ],
  choose_in_the_price_of_purchased_credits_dollar: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 $1 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits_dollar',
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
  ],
  maximum_number_of_issuance_for_the_purchased_credits: [
    `구매한 수업권에 따라 최대 발급 횟수를 선택할 수 있습니다. 최초 설정한 발급 횟수는 변경이 불가하오니 신중히 결정해 주세요. (최대 분할 발급 횟수 = 수강기간/30일)`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    's_certificate.maximum_number_of_issuance_for_the_purchased_credits',
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
  ],
  can_choose_the_issued_period: [
    `구매한 수업권의 수강기간 만큼 증빙기간을 선택할 수 있습니다.`,
    `Please select a period up to the length of the lesson period.`,
    's_certificate.can_choose_the_issued_period',
    `Please select a period up to the length of the lesson period.`,
    `Please select a period up to the length of the lesson period.`,
    `Please select a period up to the length of the lesson period.`,
    `Please select a period up to the length of the lesson period.`,
  ],
  price: [`금 액`, `Price`, 's_certificate.price', `Price`, `Price`, `Price`, `Price`],
  enter_price: [`금액 입력`, `Enter`, 's_certificate.enter_price', `Enter`, `Enter`, `Enter`, `Enter`],
  issued_amount_large: [
    `금액 증빙`,
    `Issued Amount`,
    's_certificate.issued_amount_large',
    `Issued Amount`,
    `Issued Amount`,
    `Issued Amount`,
    `Issued Amount`,
  ],
  number_of_remained_lessons: [
    `남은 수업 수 :`,
    `Remaining lessons :`,
    's_certificate.number_of_remained_lessons',
    `Remaining lessons :`,
    `Remaining lessons :`,
    `Remaining lessons :`,
    `Remaining lessons :`,
  ],
  cumulative_attendance: [
    `누적 출석률`,
    `Cumulative`,
    's_certificate.cumulative_attendance',
    `Cumulative`,
    `Cumulative`,
    `Cumulative`,
    `Cumulative`,
  ],
  terms: [`링글 증명서 발급 유의사항`, `Terms`, 's_certificate.terms', `Terms`, `Terms`, `Terms`, `Terms`],
  not_included_in_the_attendance: [
    `마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    's_certificate.not_included_in_the_attendance',
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
  ],
  paid_lessons_or_completed_lessons: [
    `모든 증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 제공됩니다.`,
    `All certificates are provided only for paid and completed lessons.`,
    's_certificate.paid_lessons_or_completed_lessons',
    `All certificates are provided only for paid and completed lessons.`,
    `All certificates are provided only for paid and completed lessons.`,
    `All certificates are provided only for paid and completed lessons.`,
    `All certificates are provided only for paid and completed lessons.`,
  ],
  preview_and_issue: [
    `미리보기 & 발급하기`,
    `Preview & Issue`,
    's_certificate.preview_and_issue',
    `Preview & Issue`,
    `Preview & Issue`,
    `Preview & Issue`,
    `Preview & Issue`,
  ],
  issue_date_small: [
    `발급 일시`,
    `Issue date`,
    's_certificate.issue_date_small',
    `Issue date`,
    `Issue date`,
    `Issue date`,
    `Issue date`,
  ],
  issuance_period_small: [
    `발급 증빙 기간`,
    `Issuance period`,
    's_certificate.issuance_period_small',
    `Issuance period`,
    `Issuance period`,
    `Issuance period`,
    `Issuance period`,
  ],
  Issuance_number: [
    `발급 횟수`,
    `Certificate count`,
    's_certificate.Issuance_number',
    `Certificate count`,
    `Certificate count`,
    `Certificate count`,
    `Certificate count`,
  ],
  set_Issuance_number: [
    `발급 횟수 설정`,
    `Set Certificate count`,
    's_certificate.set_Issuance_number',
    `Set Certificate count`,
    `Set Certificate count`,
    `Set Certificate count`,
    `Set Certificate count`,
  ],
  issue_date_large: [
    `발급일자`,
    `Issue Date`,
    's_certificate.issue_date_large',
    `Issue Date`,
    `Issue Date`,
    `Issue Date`,
    `Issue Date`,
  ],
  number_of_issuance_large: [
    `발급횟수`,
    `Certificate count`,
    's_certificate.number_of_issuance_large',
    `Certificate count`,
    `Certificate count`,
    `Certificate count`,
    `Certificate count`,
  ],
  business_regristration_number: [
    `사업자 등록번호`,
    `Business Reg No.`,
    's_certificate.business_regristration_number',
    `Business Reg No.`,
    `Business Reg No.`,
    `Business Reg No.`,
    `Business Reg No.`,
  ],
  business_location: [
    `사업자 소재지`,
    `Business Location`,
    's_certificate.business_location',
    `Business Location`,
    `Business Location`,
    `Business Location`,
    `Business Location`,
  ],
  name_of_company: [
    `상 호`,
    `Name of company`,
    's_certificate.name_of_company',
    `Name of company`,
    `Name of company`,
    `Name of company`,
    `Name of company`,
  ],
  ringle_address: [
    `서울 서초구 서초대로78길 22 홍우 2빌딩 11층 링글`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_certificate.ringle_address',
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
  ],
  select: [`선택해 주세요.`, `Select`, 's_certificate.select', `Select`, `Select`, `Select`, `Select`],
  from_the_selected_beginning_date: (period) => [
    <>선택하신 시작일로부터 {period}일동안 증빙할 수 있습니다.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
    's_certificate.from_the_selected_beginning_date',
    <>You can issue for {period} days from the selected beginning date.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
  ],
  the_issuance_number_attendance_certificate: (maxCount, leftCount) => [
    <Div flex flexRow>
      선택한 발급 횟수는 총{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}회
      </Div>
      이고, 남은 발급 횟수는 <Div fontMedium>&nbsp;{leftCount}회</Div>입니다.
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    's_certificate.the_issuance_number_attendance_certificate',
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
  ],
  compared_completed_lessons: [
    `선택한 증빙 기간 내 수강한 수업 수 대비 선택한 출석 기준 수업 수를 기준으로 산정됩니다. (증빙 기간 내 출석률=수강한 수업 수/출석 기준 수업수*100)`,
    `Calculated using lessons completed within the selected issue period.`,
    's_certificate.compared_completed_lessons',
    `Calculated using lessons completed within the selected issue period.`,
    `Calculated using lessons completed within the selected issue period.`,
    `Calculated using lessons completed within the selected issue period.`,
    `Calculated using lessons completed within the selected issue period.`,
  ],
  calculated_based_on_the_total_lessons: [
    `선택한 증빙 기간과 관계없이 수강한 총 수업 수 대비 구매한 총 수업 수를 기준으로 산정됩니다. (누적 출석률=수강한 총 수업 수/구매한 총 수업 수*100)`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    's_certificate.calculated_based_on_the_total_lessons',
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
  ],
  name_of_representative: [
    `성 명`,
    `Representative`,
    's_certificate.name_of_representative',
    `Representative`,
    `Representative`,
    `Representative`,
    `Representative`,
  ],
  calculated_including_all_completed_lessons: [
    `수강 기간 내 진행한 모든 수업을 포함하여 출석률을 산정합니다.
포함되는 수업권의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등) 
마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    's_certificate.calculated_including_all_completed_lessons',
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
  ],
  lessons_arttendance_percentage: [
    `수강 횟수 (출석률)`,
    `Lessons (Attendance rate)`,
    's_certificate.lessons_arttendance_percentage',
    `Lessons (Attendance rate)`,
    `Lessons (Attendance rate)`,
    `Lessons (Attendance rate)`,
    `Lessons (Attendance rate)`,
  ],
  lesson_period: [
    `수강기간`,
    `Lesson Period`,
    's_certificate.lesson_period',
    `Lesson Period`,
    `Lesson Period`,
    `Lesson Period`,
    `Lesson Period`,
  ],
  student: [`수강자`, `Student`, 's_certificate.student', `Student`, `Student`, `Student`, `Student`],
  lesson_certificate: [
    `수강증`,
    `Lesson Certificate`,
    's_certificate.lesson_certificate',
    `Lesson Certificate`,
    `Lesson Certificate`,
    `Lesson Certificate`,
    `Lesson Certificate`,
  ],
  lesson_certificate_issuance_history: [
    `수강증 발급 내역`,
    `Certificate issuance history`,
    's_certificate.lesson_certificate_issuance_history',
    `Certificate issuance history`,
    `Certificate issuance history`,
    `Certificate issuance history`,
    `Certificate issuance history`,
  ],
  number_of_installations_for_lesson: [
    `수강증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_lesson',
    `Certificate Count`,
    `Certificate Count`,
    `Certificate Count`,
    `Certificate Count`,
  ],
  issuance_number_will_be_deducted_once_lesson: [
    `수강증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. 동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    's_certificate.issuance_number_will_be_deducted_once_lesson',
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
  ],
  number_of_completed_lessons_without_func: [
    `수강한 수업 수`,
    `Completed Lessons`,
    's_certificate.number_of_completed_lessons_without_func',
    `Completed Lessons`,
    `Completed Lessons`,
    `Completed Lessons`,
    `Completed Lessons`,
  ],
  lessons_count: [`수강횟수`, `Lessons`, 's_certificate.lessons_count', `Lessons`, `Lessons`, `Lessons`, `Lessons`],
  credits: [`수업권 정보`, `Credits`, 's_certificate.credits', `Credits`, `Credits`, `Credits`, `Credits`],
  no: [`아니오`, `No`, 's_certificate.no', `No`, `No`, `No`, `No`],
  select_language: [
    `언어 선택`,
    `Select Language`,
    's_certificate.select_language',
    `Select Language`,
    `Select Language`,
    `Select Language`,
    `Select Language`,
  ],
  type_of_business: [
    `업 태`,
    `Type of Business`,
    's_certificate.type_of_business',
    `Type of Business`,
    `Type of Business`,
    `Type of Business`,
    `Type of Business`,
  ],
  receipt: [`영수증`, `Receipt`, 's_certificate.receipt', `Receipt`, `Receipt`, `Receipt`, `Receipt`],
  issue_receipt: [
    `영수증 발급 안내`,
    `Issue Receipt`,
    's_certificate.issue_receipt',
    `Issue Receipt`,
    `Issue Receipt`,
    `Issue Receipt`,
    `Issue Receipt`,
  ],
  cannot_be_issued_in_installments: [
    `영수증은 카드사를 통해 발급되어 분할 발급은 불가합니다.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    's_certificate.cannot_be_issued_in_installments',
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
  ],
  english: [`영어`, `English`, 's_certificate.english', `English`, `English`, `English`, `English`],
  online_english_education: [
    `온라인 어학교육`,
    `Online English Education`,
    's_certificate.online_english_education',
    `Online English Education`,
    `Online English Education`,
    `Online English Education`,
    `Online English Education`,
  ],
  certify_that_the_above_mentioned_fact: [
    `* 본 화면은 미리 보기 용으로 증빙 효력이 없습니다.`,
    `* This screen is a preview and has no effect as a certificate.`,
    's_certificate.certify_that_the_above_mentioned_fact',
    `* This screen is a preview and has no effect as a certificate.`,
    `* This screen is a preview and has no effect as a certificate.`,
    `* This screen is a preview and has no effect as a certificate.`,
    `* This screen is a preview and has no effect as a certificate.`,
  ],
  representatives_names: [
    `이성파, 이승훈`,
    `Hoon Lee, Sungpah Lee`,
    's_certificate.representatives_names',
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
    `Hoon Lee, Sungpah Lee`,
  ],
  days: [`일`, `days`, 's_certificate.days', `days`, `days`, `days`, `days`],
  contact_number: [
    `전화번호`,
    `Contact Number`,
    's_certificate.contact_number',
    `Contact Number`,
    `Contact Number`,
    `Contact Number`,
    `Contact Number`,
  ],
  regular_lessons: [
    `정규수업 수강일`,
    `Regular Lessons`,
    's_certificate.regular_lessons',
    `Regular Lessons`,
    `Regular Lessons`,
    `Regular Lessons`,
    `Regular Lessons`,
  ],
  items_of_nusiness: [
    `종 목`,
    `Items of Business`,
    's_certificate.items_of_nusiness',
    `Items of Business`,
    `Items of Business`,
    `Items of Business`,
    `Items of Business`,
  ],
  issue_certificate: [
    `증명서 발급`,
    `Certificate`,
    's_certificate.issue_certificate',
    `Certificate`,
    `Certificate`,
    `Certificate`,
    `Certificate`,
  ],
  enter_all_information_required: [
    `증명서 발급에 필요한 모든 정보를 입력해주세요.`,
    `Please enter all information required to issue the certificate.`,
    's_certificate.enter_all_information_required',
    `Please enter all information required to issue the certificate.`,
    `Please enter all information required to issue the certificate.`,
    `Please enter all information required to issue the certificate.`,
    `Please enter all information required to issue the certificate.`,
  ],
  restricted_from_using_the_function: [
    `수강증/출석증이 발급된 수업권은 수업권 변경 기능 이용이 제한됩니다.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    's_certificate.restricted_from_using_the_function',
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
  ],
  certificates_not_be_eligible_for_refunds: [
    `수강증/출석증이 발급된 수업권은 환불 대상에서 제외됩니다.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    's_certificate.certificates_not_be_eligible_for_refunds',
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
  ],
  select_the_language: [
    `증명서의 언어를 선택해주세요`,
    `Select the language for the certificate.`,
    's_certificate.select_the_language',
    `Select the language for the certificate.`,
    `Select the language for the certificate.`,
    `Select the language for the certificate.`,
    `Select the language for the certificate.`,
  ],
  maximum_of_issued_amount: [
    `증빙 가능 금액 :`,
    `Maximum of Issued Amount :`,
    's_certificate.maximum_of_issued_amount',
    `Maximum of Issued Amount :`,
    `Maximum of Issued Amount :`,
    `Maximum of Issued Amount :`,
    `Maximum of Issued Amount :`,
  ],
  select_the_issuance_period_again: [
    `증빙 가능한 기간을 초과하여 선택하셨습니다. 증빙 기간을 다시 선택해주세요.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    's_certificate.select_the_issuance_period_again',
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
  ],
  you_cannot_issue_on_the_day: [
    `증빙 가능한 기간이 아닙니다. 증빙 기간을 다시 선택해주세요. 1회 발급 시 최소 증빙 기간은 2일이며, 당일 증빙은 불가합니다.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    's_certificate.you_cannot_issue_on_the_day',
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
  ],
  issued_amount: [
    `증빙 금액`,
    `Issued Amount`,
    's_certificate.issued_amount',
    `Issued Amount`,
    `Issued Amount`,
    `Issued Amount`,
    `Issued Amount`,
  ],
  issuance_period: [
    `증빙 기간`,
    `Issue Period`,
    's_certificate.issuance_period',
    `Issue Period`,
    `Issue Period`,
    `Issue Period`,
    `Issue Period`,
  ],
  attendance_percentage_during_the_issue_period: [
    `증빙 기간 내 출석률`,
    `During the issue period`,
    's_certificate.attendance_percentage_during_the_issue_period',
    `During the issue period`,
    `During the issue period`,
    `During the issue period`,
    `During the issue period`,
  ],
  include_all_completed_lessons: [
    `진행한 수업 모두 포함하기 (e.g.당일수업권, 보상수업권, 보너스 수업권 등)`,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    's_certificate.include_all_completed_lessons',
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
  ],
  extra_lessons: [
    `추가 수업 수강일`,
    `Extra Lessons`,
    's_certificate.extra_lessons',
    `Extra Lessons`,
    `Extra Lessons`,
    `Extra Lessons`,
    `Extra Lessons`,
  ],
  attendance_standard_of_lessons: [
    `출석 기준 수업 수`,
    `Eligible Lessons`,
    's_certificate.attendance_standard_of_lessons',
    `Eligible Lessons`,
    `Eligible Lessons`,
    `Eligible Lessons`,
    `Eligible Lessons`,
  ],
  lessons_cannot_be_entered_zero: [
    <>
      출석 기준 수업 수는 [남은 증빙 가능 수업 수~0] 회 이상으로 입력해야 합니다. <br />
      출석 기준 수업 수는 0회는 입력 불가합니다.
    </>,
    `Must be entered at least over [Number of classes that can be verified~0].`,
    's_certificate.lessons_cannot_be_entered_zero',
    `Must be entered at least over [Number of classes that can be verified~0].`,
    `Must be entered at least over [Number of classes that can be verified~0].`,
    `Must be entered at least over [Number of classes that can be verified~0].`,
    `Must be entered at least over [Number of classes that can be verified~0].`,
  ],
  attendance_percentage: [
    `출석률`,
    `Attendance Rate`,
    's_certificate.attendance_percentage',
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
  ],
  standard_number_of_lessons: [
    `출석률 산정 시 기준이 되는 수업 수 입니다. 수강생이 직접 입력 가능하며, 최대 입력 가능한 수업 수는 구매한 수업 횟수입니다. (출석률=수강 수업 수/출석 기준 수업 수) <br/> 마지막 회차 증빙은 남은 출석기준 수업 수가 자동으로 산정되며, 이는 변경이 불가합니다.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    's_certificate.standard_number_of_lessons',
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
  ],
  attendance_certificate: [
    `출석증`,
    `Attendance Certificate`,
    's_certificate.attendance_certificate',
    `Attendance Certificate`,
    `Attendance Certificate`,
    `Attendance Certificate`,
    `Attendance Certificate`,
  ],
  attendance_certificate_issuance_history: [
    `출석증 발급 내역`,
    `Attendance certificate issuance history`,
    's_certificate.attendance_certificate_issuance_history',
    `Attendance certificate issuance history`,
    `Attendance certificate issuance history`,
    `Attendance certificate issuance history`,
    `Attendance certificate issuance history`,
  ],
  select_attendance_percentage: [
    `출석증 발급 시, 표기될 출석률을 선택해주세요`,
    `Select the rate you'd like to show on the certificate.`,
    's_certificate.select_attendance_percentage',
    `Select the rate you'd like to show on the certificate.`,
    `Select the rate you'd like to show on the certificate.`,
    `Select the rate you'd like to show on the certificate.`,
    `Select the rate you'd like to show on the certificate.`,
  ],
  number_of_installations_for_attendance: [
    `출석증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_attendance',
    `Certificate Count`,
    `Certificate Count`,
    `Certificate Count`,
    `Certificate Count`,
  ],
  issuance_number_will_be_deducted_once_lesson_attendance: [
    <>
      출석증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. <br />
      동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    's_certificate.issuance_number_will_be_deducted_once_lesson_attendance',
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
  ],
  cash_receipts_kakao_naver: [
    `카카오페이/네이버페이 결제 시 현금영수증은 해당 플랫폼에서 신청 가능합니다.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    's_certificate.cash_receipts_kakao_naver',
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
  ],
  types_of_classes_included: [
    `포함되는 수업의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    's_certificate.types_of_classes_included',
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
  ],
  korean: [`한글`, `Korean`, 's_certificate.korean', `Korean`, `Korean`, `Korean`, `Korean`],
  you_cannot_change_in_future: [
    `현재 설정한 발급 횟수로 [수강증/출석증] 발급을 완료하면 차후 변경이 불가하오니 신중히 결정해주세요.`,
    `Please choose carefully as this setting cannot be reversed.`,
    's_certificate.you_cannot_change_in_future',
    `Please choose carefully as this setting cannot be reversed.`,
    `Please choose carefully as this setting cannot be reversed.`,
    `Please choose carefully as this setting cannot be reversed.`,
    `Please choose carefully as this setting cannot be reversed.`,
  ],
  confirm: [`확인`, `Confirm`, 's_certificate.confirm', `Confirm`, `Confirm`, `Confirm`, `Confirm`],
  classes: [`회`, ``, 's_certificate.classes', ``, ``, ``, ``],
  paypal_kakao_payment_receipts: [
    `Paypal/카카오페이 결제 영수증은 사용하시는 PayPal/카카오페이 계정을 통해 확인 가능합니다.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    's_certificate.paypal_kakao_payment_receipts',
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
  ],
  can_be_viewed_from_website: [
    `Paypal/카카오페이 결제 영수증은 Paypal/ 카카오페이에서 직접 확인 가능합니다.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    's_certificate.can_be_viewed_from_website',
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
  ],
  download_print_pdf: [
    `PDF 다운로드 / 출력`,
    `Download / Print PDF`,
    's_certificate.download_print_pdf',
    `Download / Print PDF`,
    `Download / Print PDF`,
    `Download / Print PDF`,
    `Download / Print PDF`,
  ],
  lessons: [`수업횟수`, `Lessons`, 's_certificate.lessons', `Lessons`, `Lessons`, `Lessons`, `Lessons`],
  payment_receipt_first: [
    `첫번째 영수증`,
    `Payment Receipt 1`,
    's_certificate.payment_receipt_first',
    `Payment Receipt 1`,
    `Payment Receipt 1`,
    `Payment Receipt 1`,
    `Payment Receipt 1`,
  ],
  payment_receipt_second: [
    `두번째 영수증`,
    `Payment Receipt 2`,
    's_certificate.payment_receipt_second',
    `Payment Receipt 2`,
    `Payment Receipt 2`,
    `Payment Receipt 2`,
    `Payment Receipt 2`,
  ],
  cumulative_lessons: [
    `누적 수강횟수`,
    `Cumulative Lessons`,
    's_certificate.cumulative_lessons',
    `Cumulative Lessons`,
    `Cumulative Lessons`,
    `Cumulative Lessons`,
    `Cumulative Lessons`,
  ],
  cumulative_attendance_percentage: [
    `누적 출석률`,
    `Cumulative Percentage`,
    's_certificate.cumulative_attendance_percentage',
    `Cumulative Percentage`,
    `Cumulative Percentage`,
    `Cumulative Percentage`,
    `Cumulative Percentage`,
  ],
  dropdown_certificates: [
    `회`,
    `certificate`,
    's_certificate.dropdown_certificates',
    `certificate`,
    `certificate`,
    `certificate`,
    `certificate`,
  ],
  notification: [
    `알림`,
    `Notification`,
    's_certificate.notification',
    `Notification`,
    `Notification`,
    `Notification`,
    `Notification`,
  ],
  select_period_tooltip: [
    `구매한 수업권의 수강기간 만큼 증빙기간을 선택할 수 있습니다. 1. 결제일 이후 시작일을 설정할 수 있습니다. 2. 이미 증빙했던 기간 재설정은 불가합니다. 3. 당일 증빙은 불가하며, 최소 2일 이상 증빙 기간을 설정해야 합니다.`,
    `You can choose the issue period as much as the lesson period 1. You can set the beginning date after the payment date. 2. You cannot set the period that has already been issued. 3. You cannot issue on the day and you must set at least more than 2 days.`,
    's_certificate.select_period_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_full_name: [
    `증명서 출력 시 [수강자명]이 비어 있는 경우 기본 정보 항목에 국문 이름을 입력해 주세요.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    's_certificate.enter_full_name',
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
  ],
  set_full_name: [
    `수강증/출석증 내 표기되는 수강자명은 계정관리에서 설정할 수 있습니다. [한글 이름 수정] / [영문 이름 수정]`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    's_certificate.set_full_name',
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
  ],
  name: [
    `수강생 정보`,
    `Student Information`,
    's_certificate.name',
    `Student Information`,
    `Student Information`,
    `Student Information`,
    `Student Information`,
  ],
  name_notice: [
    `이름이 정확하게 입력되어 있는지 확인해 주세요.`,
    `Please enter your name`,
    's_certificate.name_notice',
    `Please enter your name`,
    `Please enter your name`,
    `Please enter your name`,
    `Please enter your name`,
  ],
  name_korean: [`한글 이름`, ``, 's_certificate.name_korean', ``, ``, ``, ``],
  name_english: [
    `영어 이름`,
    `English Name`,
    's_certificate.name_english',
    `English Name`,
    `English Name`,
    `English Name`,
    `English Name`,
  ],
  name_korean_placeholder: [`한글 이름을 입력해 주세요.`, ``, 's_certificate.name_korean_placeholder', ``, ``, ``, ``],
  name_english_placeholder_1: [
    `First Name`,
    `First Name`,
    's_certificate.name_english_placeholder_1',
    `First Name`,
    `First Name`,
    `First Name`,
    `First Name`,
  ],
  name_english_placeholder_2: [
    `Last Name`,
    `Last Name`,
    's_certificate.name_english_placeholder_2',
    `Last Name`,
    `Last Name`,
    `Last Name`,
    `Last Name`,
  ],
  preview: [`미리보기`, `Preview`, 's_certificate.preview', `Preview`, `Preview`, `Preview`, `Preview`],
  fact_cerify: [
    `위의 수강 사실을 증명합니다.`,
    `This is to certify that the above mentioned fact is true.`,
    's_certificate.fact_cerify',
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
    `This is to certify that the above mentioned fact is true.`,
  ],
}
export const r_policy = {
  heading: [``, `Ringle Tutor Policy`, 'r_policy.heading', ``, ``, ``, ``],
  text: [
    ``,
    <>
      <b>Last Updated Mar 18, 2021</b>
      Ringle Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”) provides this Privacy
      Policy to inform you of our policies and procedures regarding the collection, use, and disclosure of personal
      information we receive from users of our services. This Privacy Policy applies to all information that has been
      provided to us through our websites (collectively, our "Sites”) or in the process of providing services (our
      "Services”) to our customers and visitors. This Privacy Policy may be updated from time to time. The most updated
      version of the Privacy Policy is available
      [HERE](https://www.notion.so/Privacy-Policy-7cd2c6f00bdd4fd8b3c8eb61f8a2e939). We will notify you of any material
      changes to this Privacy Policy by posting the new Privacy Policy wherever it may appear on our Sites, and give you
      notice that the Privacy Policy has been updated. You should consult this policy regularly for any changes, and it
      is your responsibility to become aware of and understand them. For the purposes of this policy, the terms "using"
      and "processing" information include using cookies on a computer, subjecting the information to statistical or
      other analysis and using or handling information in any way, including, but not limited to collecting, storing,
      evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization
      or among our affiliates within the United States or internationally. ---
      <b>NOTICE TO CALIFORNIA RESIDENTS</b>
      Our use of your information is subject to our "Privacy Notice for California Residents”, which is available
      [HERE](https://www.notion.so/Privacy-Notice-for-California-Residents-384c9774cb3d4146b3bc32c21751f520). If you are
      a California resident, please refer to that document for information on how we collect and use your personal
      information, and your rights under California law.
      <b>INFORMATION, COLLECTION, AND ITS USE</b>
      We collect information to provide and improve our Sites, our features, to administer accounts, and to provide our
      Services. This information can be personally identifiable, non-identifying, and include information relating to
      your device and activity, or of your own customers and their respective personal information. Please see the "What
      Kinds of Information We Collect” for more details on what we collect. We collect this information when you use the
      Services. For example, we collect information before you start using our Services, including for account
      registration, to create, share, or request feedback, or message and communicate with us. Depending on your privacy
      settings, we collect information about your computer and other devices you use to take part in the Services. We
      may associate device data to provide consistent o0Services, and to provide a secure environment for users. This
      can include collecting your device identifiers, location data, and IP address to verify registered users. This
      Privacy Policy does not apply to collection or practices of third-parties that we do not own or control, including
      third-party providers used in connection with the Services. While we attempt to only work with third-parties that
      share our policies, we cannot take responsibility for the policies or practices of third-parties. If we work with
      any third-parties with data and information policies that are different than our own, we will notify you before
      those policies affect you. Regardless, we strongly encourage you to review third-party privacy policies before
      using their services or sharing your personal information.
      <b>WHAT KINDS OF INFORMATION DO WE COLLECT?</b>
      PERSONALLY IDENTIFIABLE INFORMATION In the course of using the Services, we may ask you to provide us with, or
      grant us access to, or permission to obtain, certain personally identifiable information that can be used to
      contact or identify you. Personally identifiable information includes, but is not limited to, legal names, email
      addresses, mobile phone numbers, sex/gender, dates of birth, and billing address information (your "Personal
      Information"). We collect Personal Information in connection with your registration to form an account, and to
      participate in certain Services. We may also use your Personal Information to contact you with newsletters,
      updates, marketing or promotional materials and other information that may be of interest to you. If at any time
      you decide you no longer wish to receive such communications from us, please unsubscribe from further messages
      from any "Unsubscribe” function we provide, update your Account's "Notifications" settings, or write to us at
      RINGLE customer service via email at contact@ringleplus.com. We may also collect Personal Information about you,
      such as your name, email address and mobile number when you provide feedback on the Services. If we collect
      Personal Information to provide a specific Service, we will only use that information to provide the Service, and
      will not share that information with any third-party, including our third-party providers, except as necessary to
      provide the Service or as you have agreed to in writing with us. We use Personal Information (in some cases, in
      conjunction with Non-Identifying Information defined below) to provide the Services, send information about the
      Sites or Services, and to respond to and administer inquiries and requests. --- NON-IDENTIFYING INFORMATION We may
      also collect other information as part of registration for, and use of, certain Services, such as your ZIP or
      postal code, geographical data, birth month or day (but not both), and individual preferences ("Non-Identifying
      Information"). Certain Non-Identifying Information would be considered a part of Personal Information if it were
      combined with other identifiers (for example, by combining a ZIP or postal code with a street address) in a way
      that enables a person to be identified. Those same pieces of information are considered Non-Identifying
      Information when they are taken alone or combined only with other non-identifying information, such as your
      viewing preferences. We may use Non-Identifying Information for any lawful purpose, including by aggregating Non-
      Identifying Information from multiple users to provide better or more personalized user experiences, to improve
      the quality and value of the Services, and to analyze and understand how and where our Services are used. ---
      CALENDAR INFORMATION You may connect your Google calendar with RINGLE. Our calendar integration only checks the
      duration and free/busy status of the events in your calendar so that the scheduling process can be even easier and
      more efficient. We never store who you are meeting with, their email address, the meeting title, or any other
      details about the appointments in your connected calendar. --- LOG DATA When you visit the Site, regardless of
      whether you are a registered user or a non-registered visitor, our servers may automatically record information
      that your browser sends about your visit ("Log Data"). Log Data may include information such as your computer's or
      device’s Internet Protocol ("IP") address, browser type, or the webpage you were visiting before you came to a
      Site, pages of our Sites that you visit, the time spent on those pages, information you search for on our Sites,
      access times and dates, and other statistics. We use this information to improve our Site and Services and to
      deliver a better and more personalized service. For example, some of this information is collected so that when
      you visit our Sites, we will recognize you and serve information appropriate to your interests. We also use this
      information to verify that visitors meet the criteria required to process their requests. Log Data may be treated
      as Personal Information, but we may aggregate, analyze and evaluate such information for the same purposes as for
      Non-Identifying Information. --- COOKIES Like many website operators, we may use "cookies" to collect information.
      A cookie is a small data file that we transfer to your device's hard disk for record-keeping purposes. We will use
      cookies for two purposes. First, we may utilize persistent cookies to save your registration ID and login password
      for future logins to the Sites. Second, we may utilize session ID cookies to enable certain features of the
      Services, to better understand how you interact with our Sites, or Services and to monitor aggregate usage by
      users and web traffic routing. Unlike persistent cookies, session cookies are deleted from your computer or device
      when you log off from your Account and then close your browser. You can instruct your browser, by changing its
      options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you
      do not accept cookies, however, you may not be able to use all portions of the Site or certain functionalities of
      our Services. --- NOTICE ABOUT IDENTITY THEFT & PHISHING Identity theft and the practice known as "phishing" are
      of great concern to us. Safeguarding information to help protect you from identity theft is a top priority. We do
      not and will not, at any time, request or provide your Personal Information, including credit card information,
      login passwords, or identification numbers, such as your driver’s license number or Social Security number, in any
      email or other communication unless your Personal Information is needed to confirm your identity and respond to
      request you have made. For more information about phishing, visit the Federal Trade Commission's website here:
      https://www.consumer.ftc.gov/articles/0003-phishing. --- CalOPPA DISCLOSURE & TRACKING We do not currently respond
      to web browser "Do Not Track” settings that allow you to opt-out of information collection. --- OTHER WEB SITE
      ANALYTICS SERVICES We use third-party Service Providers such as Google Analytics to provide certain analytics and
      Viewer interactions services to RINGLE in connection with our operation of our Website, including the collection
      and tracking of certain data and information regarding the characteristics and activities of visitors to RINGLE.
      You may opt-out of relevant cookies using opt-out features on their respective websites.
      <b>WHO DO WE SHARE YOUR INFORMATION WITH?</b>
      The following list describes with whom we may share your information, including your Personal Information, and for
      what purposes. You may opt-out of any information disclosure by contacting us at RINGLE customer service via email
      at contact@ringleplus.com. If you choose to opt-out of any disclosure of your information, however, you may not be
      able to use all portions of the Site or certain functionalities of our Services. REGISTERED USERS When you
      register to join our Services, you must submit Personal Information to create an account. You can choose what
      other Personal Information you provide in connection with our Services that may be shared with other users.
      Providing this information is voluntary and you should only share as much as you would like to share with others.
      --- TRUSTED PARTNERS We may share generic, aggregated information that does not include Personal Information and
      we may otherwise disclose Non-Identifying Information and Log Data with our trusted business partners, affiliates,
      or advertisers for industry analysis, demographic profiling and other similar commercial purposes. Any aggregated
      information shared in these contexts will not contain your Personal Information. We will only share your Personal
      Information with our business partners for specific purposes, and only after receiving your prior consent to whom
      and for what purposes the information is shared. --- THIRD-PARTY PROVIDERS & SUBCONTRACTORS We engage and utilize
      third-party service providers, and other third-party companies and individuals to facilitate our Services, to
      provide certain Services on our behalf, such as payment processing services, maintenance services, and to assist
      us in analyzing how our Services are being used. These third-parties have access to your Personal Information only
      to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. --- LAW
      ENFORCEMENT We cooperate with government and law enforcement officials and private parties to enforce and comply
      with the law. We may access, read, preserve, or disclose any information about you to government or law
      enforcement officials or private third-parties as we, in our sole reasonable discretion, believe is necessary or
      appropriate to (a) respond to, or satisfy, applicable law, regulations, claims, court orders, and legal process
      (including but not limited to subpoenas); (b) follow and enforce our terms of Service, including this Privacy
      Policy, or to investigate any suspected violations; (c) detect, prevent, or address fraud, security, or technical
      issues; (d) to protect the property, rights, or safety of the public, us, our users, or any third- party; or (e)
      to prevent or stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally
      actionable activity. --- PARTIES TO BUSINESS TRANSFERS We may sell, transfer or otherwise share some or all of its
      assets, including your Personal Information, in connection with a merger, acquisition, reorganization or sale of
      assets, or in the event of bankruptcy, to third-parties. --- NO OTHER USES Except as expressly set out in this
      Privacy Policy, we will not share, sell, trade, or rent any of your Personal Information to any third-party for
      any purpose.
      <b>HOW TO CHANGE OR DELETE YOUR INFORMATION</b>
      We will retain your information for as long as you have an account, or as needed to provide a Service. All users
      may review, update, correct or delete their Personal Information. If you would like us to terminate your account,
      to stop using your information, or otherwise makes changes to, or delete your information in our systems, please
      contact us at RINGLE customer service via email at tutor@ringleplus.com, or use the functionalities we provide
      through our Sites to do so. We will use commercially reasonable efforts to honor your request as soon as possible.
      However, we reserve the right to retain an archived copy, which is not accessible to third-parties, of your
      records and information as required by law, to maintain accurate business records, resolve disputes, or enforce
      our agreement with you. THIRD-PARTY ANALYTICS SERVICES You may opt out of Google Analytics’ services using the
      Opt-Out feature on their website. The Google Analytics service is provided by Google Inc. You can opt-out from
      Google Analytics service from using your information by installing the Google Analytics Opt-out Browser tool:
      [https://tools.google.com/dlpage/gaoptout](https://tools.google.com/dlpage/gaoptout). For more information on the
      privacy practices of Google, please visit the Google Privacy & Terms web page:
      [https://www.google.com/policies/privacy](https://www.google.com/policies/privacy).
      <b>HOW WE SECURE YOUR INFORMATION</b>
      We are very concerned with safeguarding your information. However, we are not in the business of providing
      information security, and cannot guarantee perfect security over your information. We do however employ
      commercially reasonable data collection, storage, processing practices, and security measures, such as using SSL
      encryption on some but not all systems to protect against unauthorized access, use, loss, alteration or
      destruction of your Personal Information. We will make any legally required disclosures of any breach of the
      security, confidentiality, or integrity of your unencrypted electronically stored "personal data" (as defined in
      applicable state statutes on security breach notification) to you via email or conspicuous posting on our Sites as
      soon as commercially practicable, and without unreasonable delay, so long as those disclosures are consistent with
      (a) the legitimate needs of law enforcement; or (b) any measures necessary to determine the scope of the breach
      and restore the reasonable integrity of the data system. Do not provide us with your information if you are
      concerned with its disclosure or its security, or any of our policies described in this Privacy Policy.
      <b>Google API SERVICES USAGE DISCLOSURE</b>
      Ringle's use of information received from Google APIs will adhere to [Google API Services User Data
      Policy](https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes),
      including the Limited Use requirements.
      <b>NOTICE TO CALIFORNIA RESIDENTS</b>
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”). Our use of your information is fully intended to fully comply with the CCPA.
      Please consult our RINGLE "Privacy Notice for California Residents” (the "CCPA Notice”) for more information on
      additional rights and protections you have under the CCPA regarding your Personal Information. If you are a
      resident of California, and there is any conflict between the terms of this Privacy Policy and the CCPA Notice,
      the terms of the CCPA Notice will take precedence at all times.
      <b>NOTICE REGARDING INTERNATIONAL TRANSFER OF INFORMATION</b>
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction. If you are located outside the United States and choose to provide information to us, you agree
      that your Personal Information will be transferred to the United States and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <b>LINKS TO OTHER SITES</b>
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <b>OUR POLICY TOWARDS CHILDREN</b>
      Protecting the privacy of children is especially important to us, and we strictly comply with the FTC’s Children’s
      Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or meant for persons under the age of
      13. We do not knowingly collect or solicit personally identifiable information from children under 13, or allow
      them register accounts. If we become aware that a child under 13 has provided us with Personal Information, or any
      user has provided information of or about any person under the age of 13, we will delete such information from our
      systems. Do not submit any Personal Information to us if you are under the age of 13, or any information of, or
      about, persons under the age of 13. If you are a parent or guardian and you become aware that your child has
      provided us with Personal Information without your consent, you should contact us immediately at RINGLE customer
      service via email at contact@ringleplus.com, or use the contact functionality we provide through your account or
      on the Site to request we delete your child’s information.
      <b>CONTACT US</b>
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
    </>,
    'r_policy.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_subscribe = {
  message: {
    success: [
      `구독을 축하드립니다!`,
      `Congratulations, you have successfully subscribed!`,
      's_subscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    already_subscribed: [
      `이미 구독하고 계신 이메일 입니다. 스팸함을 확인해주세요.`,
      `You have already subscribed with this email. Please check your spam.`,
      's_subscribe.message.already_subscribed',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while subscribing to the newsletter. Please contact the administrator.`,
      's_subscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
    invalidName: [`이름을 적어주세요.`, `Please input your name.`, 's_subscribe.message.invalidName', ``, ``, ``, ``],
    invalidEmail: [
      `잘못된 이메일 형식입니다.`,
      `Not a valid email address`,
      's_subscribe.message.invalidEmail',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    name_input: [`이름`, `Name`, 's_subscribe.body_header.name_input', ``, ``, ``, ``],
    email_input: [`이메일`, `Email`, 's_subscribe.body_header.email_input', ``, ``, ``, ``],
    header_button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.header_button',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `매주 목요일, 금주의 가장 인기 있는 링글 콘텐츠를 받아보세요.`,
      `Subscribe to get Ringle's hottest contents every Thursday.`,
      's_subscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.button',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  success_header: {
    title: [
      `Content Weekly 구독 완료되었습니다!`,
      `You have successfully subscribed to the newsletter!`,
      's_subscribe.success_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `매주 목요일, 금주의 가장 인기 있는 링글 콘텐츠를 보내드릴게요.`,
      `Every Thursday, you will get Ringle's hottest contents.`,
      's_subscribe.success_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [`링글 콘텐츠 둘러보기`, `Check out Ringle Contents`, 's_subscribe.success_header.button', ``, ``, ``, ``],
    email_change_button: [
      `이메일 주소 변경하기`,
      `Change email address`,
      's_subscribe.success_header.email_change_button',
      `Change email address`,
      `Change email address`,
      `Change email address`,
      `Change email address`,
    ],
    email_subscribed: [
      `발송되는 이메일 주소:`,
      `Subscribed email address:`,
      's_subscribe.success_header.email_subscribed',
      `Subscribed email address:`,
      `Subscribed email address:`,
      `Subscribed email address:`,
      `Subscribed email address:`,
    ],
    content1: [
      `발송되고 있는 이메일 주소:`,
      `Subscribed email:`,
      's_subscribe.success_header.content1',
      `Subscribed email:`,
      `Subscribed email:`,
      `Subscribed email:`,
      `Subscribed email:`,
    ],
    content2: [
      `메일을 받지 못한 경우 스팸함이나 프로모션 카테고리를 확인해주세요.`,
      `Check out this week's Content Weekly`,
      's_subscribe.success_header.content2',
      `Please check your spam box.`,
      `Please check your spam box.`,
      `Please check your spam box.`,
      `Please check your spam box.`,
    ],
  },
  body: {
    example: [
      `이번주 Content Weekly 보기`,
      `Check out this week's Content Weekly`,
      's_subscribe.body.example',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subs_btn: [
    `Content Weekly 구독하기`,
    `Subscribe to Content Weekly`,
    's_subscribe.subs_btn',
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
    `Subscribe to Content Weekly`,
  ],
  failure_modal: {
    title: [
      `이미 구독중인 이메일입니다.`,
      `Your email is already on the subscription list.`,
      's_subscribe.failure_modal.title',
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
      `Your email is already on the subscription list.`,
    ],
    close: [`확인`, `Close`, 's_subscribe.failure_modal.close', `Close`, `Close`, `Close`, `Close`],
  },
}
export const s_unsubscribe = {
  message: {
    success: [
      `답변을 제출하셨습니다. 감사합니다.`,
      `Your response has been submitted. Thank you.`,
      's_unsubscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    submit_failure: [
      `답변 제출중 문제가 발생하셨습니다.`,
      `There was a problem while submitting your answers.`,
      's_unsubscribe.message.submit_failure',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독 취소중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while unsubscribing to the newsletter. Please contact the administrator.`,
      's_unsubscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    title: [
      `그동안 콘텐츠 뉴스레터를 구독해 주셔서 정말 감사합니다.`,
      `Thank you for your time as our subscriber.`,
      's_unsubscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `구독을 취소한 이유를 알려주세요. (복수 선택 가능)`,
      `Please tell us why you decided to unsubscribe.`,
      's_unsubscribe.body_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  reasons: {
    not_interested: [
      `영어 공부에 관심이 없어졌습니다.`,
      `I lost interest in studying English.`,
      's_unsubscribe.reasons.not_interested',
      ``,
      ``,
      ``,
      ``,
    ],
    contents_not_good: [
      `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
      `Ringle Contents did not help me in learning English or getting insights on topics.`,
      's_unsubscribe.reasons.contents_not_good',
      ``,
      ``,
      ``,
      ``,
    ],
    no_time: [
      `시간이나 비용을 들이고 싶지 않습니다.`,
      `I do not have time for the newsletters.`,
      's_unsubscribe.reasons.no_time',
      ``,
      ``,
      ``,
      ``,
    ],
    other_contents: [
      `다른 좋은 콘텐츠를 찾았습니다.`,
      `Found other helpful contents.`,
      's_unsubscribe.reasons.other_contents',
      ``,
      ``,
      ``,
      ``,
    ],
    other: [`기타`, `Other`, 's_unsubscribe.reasons.other', ``, ``, ``, ``],
    specify: [
      `좀 더 자세히 알려주세요!`,
      `Please tell us what it is!`,
      's_unsubscribe.reasons.specify',
      ``,
      ``,
      ``,
      ``,
    ],
    tell_us: [`무엇인지 알려주세요!`, `Tell us what it is!`, 's_unsubscribe.reasons.tell_us', ``, ``, ``, ``],
    no_email: [
      `링크에 문제가 있습니다. 이메일에 있는 링크를 사용해 주세요.`,
      `This is a corrupted link. Please use the link in the email.`,
      's_unsubscribe.reasons.no_email',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  button: [`제출하기`, `Submit`, 's_unsubscribe.button', ``, ``, ``, ``],
  disabled_button: [`제출 완료`, `Submitted`, 's_unsubscribe.disabled_button', ``, ``, ``, ``],
  resubscribe: [`다시 구독하기`, `Subscribe again`, 's_unsubscribe.resubscribe', ``, ``, ``, ``],
}
export const r_basic_info = {
  page_title: [``, `Basic Info`, 'r_basic_info.page_title', ``, ``, ``, ``],
  email_title: [``, `Email address`, 'r_basic_info.email_title', ``, ``, ``, ``],
  change_email: [``, `Change email address`, 'r_basic_info.change_email', ``, ``, ``, ``],
  email_placeholder: [``, `Email`, 'r_basic_info.email_placeholder', ``, ``, ``, ``],
  name_placeholder: [``, `First Last`, 'r_basic_info.name_placeholder', ``, ``, ``, ``],
  phone_title: [``, `Mobile number`, 'r_basic_info.phone_title', ``, ``, ``, ``],
  phone_placeholder: [``, `xxx-xxx-xxxx`, 'r_basic_info.phone_placeholder', ``, ``, ``, ``],
  phone_btn: [``, `Confirm`, 'r_basic_info.phone_btn', ``, ``, ``, ``],
  school_title: [``, `School`, 'r_basic_info.school_title', ``, ``, ``, ``],
  degree_placeholder: [``, `Degree`, 'r_basic_info.degree_placeholder', ``, ``, ``, ``],
  major_title: [``, `Major`, 'r_basic_info.major_title', ``, ``, ``, ``],
  poe_title: [``, `Proof of Education`, 'r_basic_info.poe_title', ``, ``, ``, ``],
  poe_desc: [
    ``,
    <>
      * Max file size: 8 MB <br />* Supported file format: pdf
    </>,
    'r_basic_info.poe_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  poe_reupload_btn: [``, `Re-upload`, 'r_basic_info.poe_reupload_btn', ``, ``, ``, ``],
  poe_preview_btn: [``, `Preview`, 'r_basic_info.poe_preview_btn', ``, ``, ``, ``],
  residence_title: [``, `Residence`, 'r_basic_info.residence_title', ``, ``, ``, ``],
  residence_desc: [
    ``,
    `Your residential information will be used for tax reporting purposes only. Inaccurate residential information may result in penalties for tax calculations.`,
    'r_basic_info.residence_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  street_placeholder: [``, `Street address`, 'r_basic_info.street_placeholder', ``, ``, ``, ``],
  city_placeholder: [``, `City`, 'r_basic_info.city_placeholder', ``, ``, ``, ``],
  zipcode_placeholder: [``, `Postal code`, 'r_basic_info.zipcode_placeholder', ``, ``, ``, ``],
  work_placeholder: [``, `Work`, 'r_basic_info.work_placeholder', ``, ``, ``, ``],
  position_placeholder: [``, `Position`, 'r_basic_info.position_placeholder', ``, ``, ``, ``],
  linkedin_placeholder: [``, `https://`, 'r_basic_info.linkedin_placeholder', ``, ``, ``, ``],
  linkedIn_title: [``, `LinkedIn`, 'r_basic_info.linkedIn_title', ``, ``, ``, ``],
  recomm_title: [``, `Referrer`, 'r_basic_info.recomm_title', ``, ``, ``, ``],
  delete_acc_btn: [``, `Delete My Account`, 'r_basic_info.delete_acc_btn', ``, ``, ``, ``],
  delete_acc_text1: [``, `Deleted accounts cannot be restored.`, 'r_basic_info.delete_acc_text1', ``, ``, ``, ``],
  delete_acc_text2: [
    ``,
    `Accumulated promotion points and any remaining balance will all be lost.`,
    'r_basic_info.delete_acc_text2',
    ``,
    ``,
    ``,
    ``,
  ],
  delete_acc_text3: [
    ``,
    `All lesson history & student reviews will be deleted.`,
    'r_basic_info.delete_acc_text3',
    ``,
    ``,
    ``,
    ``,
  ],
  delete_acc_text4: [
    ``,
    `You will have to make a new account with a different email address if you want to apply to Ringle again.`,
    'r_basic_info.delete_acc_text4',
    ``,
    ``,
    ``,
    ``,
  ],
  delete_acc_warning: [
    ``,
    `Your account information will be permanently deleted when you acknowledge the below terms and press "Permanently delete my account.`,
    'r_basic_info.delete_acc_warning',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_title: [``, `Terms`, 'r_basic_info.terms_title', ``, ``, ``, ``],
  leave_btn: [``, `Permanently delete my account`, 'r_basic_info.leave_btn', ``, ``, ``, ``],
}
export const r_common = {
  cancel_btn: [``, `Cancel`, 'r_common.cancel_btn', ``, ``, ``, ``],
  change_btn: [``, `Change`, 'r_common.change_btn', ``, ``, ``, ``],
  save_change_btn: [``, `Save changes`, 'r_common.save_change_btn', ``, ``, ``, ``],
  proceed: [``, `Proceed`, 'r_common.proceed', ``, ``, ``, ``],
  saved_toast: [``, `Saved`, 'r_common.saved_toast', ``, ``, ``, ``],
  back_to_list: [``, `Back to List`, 'r_common.back_to_list', ``, ``, ``, ``],
  cannot_find_contents: [``, `Cannot find contents?`, 'r_common.cannot_find_contents', ``, ``, ``, ``],
  please_contact_us: [``, `Please contact us.`, 'r_common.please_contact_us', ``, ``, ``, ``],
  help: [``, `Help`, 'r_common.help', ``, ``, ``, ``],
  lesson_note: {
    btn: [``, `Previous Lesson Notes`, 'r_common.lesson_note.btn', ``, ``, ``, ``],
  },
  accept_toast: [``, `Succesfully added lesson to schedule`, 'r_common.accept_toast', ``, ``, ``, ``],
  decline_toast: [
    ``,
    `You did not accept the lesson. This lesson will be assigned to another tutor.`,
    'r_common.decline_toast',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_style: {
    focus: [``, `Main Area of Focus`, 'r_common.lesson_style.focus', ``, ``, ``, ``],
    additional: [``, `Additional Requests`, 'r_common.lesson_style.additional', ``, ``, ``, ``],
    specific: [``, `Specific Lesson Request`, 'r_common.lesson_style.specific', ``, ``, ``, ``],
  },
  do_not_show_again: {
    btn: [``, `Do not show again`, 'r_common.do_not_show_again.btn', ``, ``, ``, ``],
  },
}
export const r_menu = {
  basic_info: [``, `Basic Info`, 'r_menu.basic_info', ``, ``, ``, ``],
  change_pw: [``, `Change Password`, 'r_menu.change_pw', ``, ``, ``, ``],
  change_tz: [``, `Change Time Zone`, 'r_menu.change_tz', ``, ``, ``, ``],
  write_resource: [``, `Write Resources`, 'r_menu.write_resource', ``, ``, ``, ``],
  lesson_style: [``, `Lesson Style`, 'r_menu.lesson_style', ``, ``, ``, ``],
}
export const r_change_pw = {
  pw_placeholder1: [``, `Must have at least 6 characters`, 'r_change_pw.pw_placeholder1', ``, ``, ``, ``],
  pw_placeholder2: [``, `Re-enter your new password`, 'r_change_pw.pw_placeholder2', ``, ``, ``, ``],
}
export const r_chaneg_tz = {
  tz_desc: [
    ``,
    `If your time zone is not correct, please select a different one.`,
    'r_chaneg_tz.tz_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  tz_change_confirm1: [
    ``,
    `Are you sure you want to change your time zone to America/New_York?`,
    'r_chaneg_tz.tz_change_confirm1',
    ``,
    ``,
    ``,
    ``,
  ],
  tz_change_confirm2: [
    ``,
    `Note: All scheduled lessons and lesson slots will change to the new, updated time zone.`,
    'r_chaneg_tz.tz_change_confirm2',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const r_noti_settings = {
  page_title: [``, `Notification Settings`, 'r_noti_settings.page_title', ``, ``, ``, ``],
  title_1: [``, `Email Push Notification`, 'r_noti_settings.title_1', ``, ``, ``, ``],
  title_2: [``, `Urgent Lesson Message`, 'r_noti_settings.title_2', ``, ``, ``, ``],
  desc_1: [``, `Lesson Apply Reminder, Urgent Request`, 'r_noti_settings.desc_1', ``, ``, ``, ``],
  desc_2: [
    ``,
    `Ringle will send a push notification when an unassigned lesson becomes available.`,
    'r_noti_settings.desc_2',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const r_announcement = {
  page_title: [``, `Announcements`, 'r_announcement.page_title', ``, ``, ``, ``],
}
export const r_resource = {
  today_s_expression: [``, `Today's expressions`, 'r_resource.today_s_expression', ``, ``, ``, ``],
  original: {
    dailybrief: [``, `Daily Brief`, 'r_resource.original.dailybrief', ``, ``, ``, ``],
  },
  this_week_s_topic: [``, `Topic of the week:`, 'r_resource.this_week_s_topic', ``, ``, ``, ``],
  all_viewed: [``, `Review complete`, 'r_resource.all_viewed', ``, ``, ``, ``],
  see_more: [``, `See more`, 'r_resource.see_more', ``, ``, ``, ``],
  search_placeholder: [``, `Enter keyword(s) to search`, 'r_resource.search_placeholder', ``, ``, ``, ``],
  search_material: [``, `Search`, 'r_resource.search_material', ``, ``, ``, ``],
  search_result: [``, `Search Results`, 'r_resource.search_result', ``, ``, ``, ``],
  no_result: [``, `No results`, 'r_resource.no_result', ``, ``, ``, ``],
  view_all: [``, `View all`, 'r_resource.view_all', ``, ``, ``, ``],
  material_download: [``, `PDF`, 'r_resource.material_download', ``, ``, ``, ``],
  title: [``, `Lesson Materials`, 'r_resource.title', ``, ``, ``, ``],
  listenMp3: [``, `You completed this audiobook!`, 'r_resource.listenMp3', ``, ``, ``, ``],
  doc: {
    search: {
      placeholder: [``, `Enter keyword(s) to search`, 'r_resource.doc.search.placeholder', ``, ``, ``, ``],
    },
  },
  search_webinar: [``, `Search`, 'r_resource.search_webinar', ``, ``, ``, ``],
  viewAll: [``, `View all`, 'r_resource.viewAll', ``, ``, ``, ``],
  related_material: [``, `Related Materials`, 'r_resource.related_material', ``, ``, ``, ``],
  related_article: [``, `Related News`, 'r_resource.related_article', ``, ``, ``, ``],
  related_video: [``, `Related Videos`, 'r_resource.related_video', ``, ``, ``, ``],
  clips: [``, `Clips`, 'r_resource.clips', ``, ``, ``, ``],
  tab_summary: [``, `Overview`, 'r_resource.tab_summary', ``, ``, ``, ``],
  tab_tutor: (name) => [``, `More webinars by ${name}`, 'r_resource.tab_tutor', ``, ``, ``, ``],
  a_tutor_will_be_added_soon: [
    ``,
    `A tutor will be added soon.`,
    'r_resource.a_tutor_will_be_added_soon',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const r_blog = {
  all: [``, `All`, 'r_blog.all', ``, ``, ``, ``],
  note_from_the_field: [``, `Notes from the Field`, 'r_blog.note_from_the_field', ``, ``, ``, ``],
  meet_the_team: [``, `Meet the team`, 'r_blog.meet_the_team', ``, ``, ``, ``],
  careers_corner: [``, `Careers Corner`, 'r_blog.careers_corner', ``, ``, ``, ``],
  english_in_the_wild: [``, `English in the Wild`, 'r_blog.english_in_the_wild', ``, ``, ``, ``],
  blog_list: [``, `Blog List`, 'r_blog.blog_list', ``, ``, ``, ``],
  blog: [``, `Blog`, 'r_blog.blog', ``, ``, ``, ``],
  write_blog: [``, `Write Blog`, 'r_blog.write_blog', ``, ``, ``, ``],
}
export const r_upcoming_lessons = {
  upcoming_lesson_enter_modal_content: (lesson, timezone) => [
    <></>,
    <>
      <Span size={14}>
        Lesson time: {lesson.start_time} ({timezone})
        <br />
        You can enter the lesson 15 minutes before it begins.
      </Span>
    </>,
    'r_upcoming_lessons.upcoming_lesson_enter_modal_content',
    ``,
    ``,
    ``,
    ``,
  ],
  no_lesson_tooltip_on_btn: [
    ``,
    <>
      <Div>
        You have no upcoming lessons.
        <br />
        Update your schedule to open slots!
      </Div>
    </>,
    'r_upcoming_lessons.no_lesson_tooltip_on_btn',
    ``,
    ``,
    ``,
    ``,
  ],
  no_lesson_tooltip_floating: [
    ``,
    <>
      <Div>
        You have no upcoming lessons.
        <br />
        Update your schedule to open slots!
      </Div>
    </>,
    'r_upcoming_lessons.no_lesson_tooltip_floating',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_schedule: [``, `Go to schedule`, 'r_upcoming_lessons.go_to_schedule', ``, ``, ``, ``],
  lesson_request_alert_1: (count) => [
    ``,
    <>
      You have {count} lesson requests. <br /> Click here to see details.
    </>,
    'r_upcoming_lessons.lesson_request_alert_1',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_request_alert_2: (count) => [
    ``,
    <>
      You have {count} lesson requests. <br /> Click here to see details.
    </>,
    'r_upcoming_lessons.lesson_request_alert_2',
    ``,
    ``,
    ``,
    ``,
  ],
  cancel_alert_msg: [``, `Please write at least 10 words.`, 'r_upcoming_lessons.cancel_alert_msg', ``, ``, ``, ``],
  cancel_popup_title: [``, `Cancellation Policy`, 'r_upcoming_lessons.cancel_popup_title', ``, ``, ``, ``],
  lesson_style_popup_title: [``, `Check Lesson Style`, 'r_upcoming_lessons.lesson_style_popup_title', ``, ``, ``, ``],
  view_profile_btn: [``, `View profile`, 'r_upcoming_lessons.view_profile_btn', ``, ``, ``, ``],
  lesson_prep_percentage: [``, `Lesson Preparation`, 'r_upcoming_lessons.lesson_prep_percentage', ``, ``, ``, ``],
  check_lesson_style_btn: [``, `Check lesson style`, 'r_upcoming_lessons.check_lesson_style_btn', ``, ``, ``, ``],
  enter_lesson_btn: [``, `Enter lesson`, 'r_upcoming_lessons.enter_lesson_btn', ``, ``, ``, ``],
  student_will_be_assigned: [
    ``,
    `Your student will be assigned shortly`,
    'r_upcoming_lessons.student_will_be_assigned',
    ``,
    ``,
    ``,
    ``,
  ],
  anonymous_student: [``, `Anonymous`, 'r_upcoming_lessons.anonymous_student', ``, ``, ``, ``],
}
export const r_preparation = {
  write_your_opinion: [``, `Write your opinion`, 'r_preparation.write_your_opinion', ``, ``, ``, ``],
  write_your_opinion_desc: [
    ``,
    `* You can view these notes in the classroom`,
    'r_preparation.write_your_opinion_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  student_response: [``, `Student's Response`, 'r_preparation.student_response', ``, ``, ``, ``],
  student_answer: [``, `Response:`, 'r_preparation.student_answer', ``, ``, ``, ``],
  example_txt_1: [
    ``,
    `A speculation can be created when certain format of trend starts
                to be seen.`,
    'r_preparation.example_txt_1',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_correction: [``, `Tutor's Correction:`, 'r_preparation.tutor_correction', ``, ``, ``, ``],
  example_txt_2: [
    ``,
    `A speculation can be created when certain format of trend starts
                to be seen.`,
    'r_preparation.example_txt_2',
    ``,
    ``,
    ``,
    ``,
  ],
  example_txt_3: [
    ``,
    `A speculation can be created when a certain format of a
                particular trend begins to be seen.`,
    'r_preparation.example_txt_3',
    ``,
    ``,
    ``,
    ``,
  ],
  example_txt_4: [
    ``,
    `Seeing the prices going up, people started to apply a financial
                concepts that emerged around that time.`,
    'r_preparation.example_txt_4',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_paraphrase: [``, `Tutor's Paraphrase:`, 'r_preparation.tutor_paraphrase', ``, ``, ``, ``],
  example_txt_5: [
    ``,
    `As prices rose, an emergence in the application of fundamental
                financial concepts began.`,
    'r_preparation.example_txt_5',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const r_past_lessons = {
  no_student: [``, `No student`, 'r_past_lessons.no_student', ``, ``, ``, ``],
  feedback_filter: [``, `Feedback required`, 'r_past_lessons.feedback_filter', ``, ``, ``, ``],
  all_filter: [``, `All`, 'r_past_lessons.all_filter', ``, ``, ``, ``],
  waiver_modal_title: [``, `Feedback Waiver Request`, 'r_past_lessons.waiver_modal_title', ``, ``, ``, ``],
  student_info_t_f: [``, `Teens, First-time user`, 'r_past_lessons.student_info_t_f', ``, ``, ``, ``],
  student_info_f: [``, `First-time user`, 'r_past_lessons.student_info_f', ``, ``, ``, ``],
  yrs_old: [``, `years old`, 'r_past_lessons.yrs_old', ``, ``, ``, ``],
  waiver_denied: [``, `Waiver denied`, 'r_past_lessons.waiver_denied', ``, ``, ``, ``],
  waiver_approved: [``, `Waiver approved`, 'r_past_lessons.waiver_approved', ``, ``, ``, ``],
  waiver_requested: [``, `Waiver requested`, 'r_past_lessons.waiver_requested', ``, ``, ``, ``],
  waiver_btn: [``, `Feedback waiver`, 'r_past_lessons.waiver_btn', ``, ``, ``, ``],
  feedback_not_rq: [``, `Feedback not required`, 'r_past_lessons.feedback_not_rq', ``, ``, ``, ``],
  feedback_completed: [``, `Feedback completed`, 'r_past_lessons.feedback_completed', ``, ``, ``, ``],
  write_feedback_btn: [``, `Write feedback`, 'r_past_lessons.write_feedback_btn', ``, ``, ``, ``],
  student_comment_modal_title: [``, `Student’s Feedback`, 'r_past_lessons.student_comment_modal_title', ``, ``, ``, ``],
  report_modal_title1: [``, `Report Inappropriate Conduct`, 'r_past_lessons.report_modal_title1', ``, ``, ``, ``],
  report_modal_content1: [
    ``,
    `What aspects of the student's conduct in this lesson were inappropriate?`,
    'r_past_lessons.report_modal_content1',
    ``,
    ``,
    ``,
    ``,
  ],
  report_modal_content2: [
    ``,
    <>
      Thank you for flagging an issue during the lesson; we have recorded the lesson environment at your request.
      <br />
      What aspects of the student’s conduct during the lesson did you feel were inappropriate? Please give specific
      timestamps or time frames where possible.
    </>,
    'r_past_lessons.report_modal_content2',
    ``,
    ``,
    ``,
    ``,
  ],
  report_modal_title2: [``, `Your report is being reviewed`, 'r_past_lessons.report_modal_title2', ``, ``, ``, ``],
  report_modal_content3: [
    ``,
    `If you need to modify your submission, click "Edit." Please note
              that if you choose to add or modify content in your
              report, the original version of your report will be lost.`,
    'r_past_lessons.report_modal_content3',
    ``,
    ``,
    ``,
    ``,
  ],
  report_modal_title3: [``, `Thank you for your submission`, 'r_past_lessons.report_modal_title3', ``, ``, ``, ``],
  report_modal_content4: [
    ``,
    `Thank you for submitting your report. We will review it in 1-3 business days and be in touch via email.`,
    'r_past_lessons.report_modal_content4',
    ``,
    ``,
    ``,
    ``,
  ],
  student_noshow: [``, `Student no-show`, 'r_past_lessons.student_noshow', ``, ``, ``, ``],
  tutor_noshow: [``, `Tutor no-show`, 'r_past_lessons.tutor_noshow', ``, ``, ``, ``],
  view_comment: [``, `View comment`, 'r_past_lessons.view_comment', ``, ``, ``, ``],
  commentmodal: {
    title1: [``, `What did you have problems with?`, 'r_past_lessons.commentmodal.title1', ``, ``, ``, ``],
    title2: [``, `What did you have problems with?`, 'r_past_lessons.commentmodal.title2', ``, ``, ``, ``],
    title3: [``, `What areas needed improvement?`, 'r_past_lessons.commentmodal.title3', ``, ``, ``, ``],
    title4: [``, `What small improvements would you suggest?`, 'r_past_lessons.commentmodal.title4', ``, ``, ``, ``],
    title5: [``, `What did you like the most?`, 'r_past_lessons.commentmodal.title5', ``, ``, ``, ``],
    title6: [``, `What did you like the most?`, 'r_past_lessons.commentmodal.title6', ``, ``, ``, ``],
    title7: [``, `What did you like the most?`, 'r_past_lessons.commentmodal.title7', ``, ``, ``, ``],
  },
  adjusted_rating: [``, `Ringle-adjusted rating:`, 'r_past_lessons.adjusted_rating', ``, ``, ``, ``],
  original_rating: [``, `Student's original rating:`, 'r_past_lessons.original_rating', ``, ``, ``, ``],
  writing_focused: [``, `Writing-focused lesson`, 'r_past_lessons.writing_focused', ``, ``, ``, ``],
  mock_session: [``, `Mock interview`, 'r_past_lessons.mock_session', ``, ``, ``, ``],
  suff_feedback: [``, `Sufficient feedback`, 'r_past_lessons.suff_feedback', ``, ``, ``, ``],
  sutdent_noshow_2: [``, `Student no-show`, 'r_past_lessons.sutdent_noshow_2', ``, ``, ``, ``],
  stude_not_attended: [``, `Student left`, 'r_past_lessons.stude_not_attended', ``, ``, ``, ``],
  ringle_confirmed: [``, `Ringle confirmed`, 'r_past_lessons.ringle_confirmed', ``, ``, ``, ``],
  waivermodal: {
    title1: [
      ``,
      `My student wanted to treat this as a Writing-focused lesson AND requested not to receive a feedback report.`,
      'r_past_lessons.waivermodal.title1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [
      ``,
      `My student wanted to treat this as a Mock Interview lesson AND requested not to receive a feedback report.`,
      'r_past_lessons.waivermodal.title2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [
      ``,
      `My student specifically asked not to receive a feedback report AND confirmed that the Tutor’s Editing Place is sufficient.`,
      'r_past_lessons.waivermodal.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    title4: [
      ``,
      `The student did not show up for the lesson, AND I stayed in the lesson for more than 25 minutes.`,
      'r_past_lessons.waivermodal.title4',
      ``,
      ``,
      ``,
      ``,
    ],
    title5: [
      ``,
      `My student showed up for the lesson just to inform me that he/she can not attend the lesson.`,
      'r_past_lessons.waivermodal.title5',
      ``,
      ``,
      ``,
      ``,
    ],
    title6: [
      ``,
      `I already contacted Ringle and received confirmation that they reviewed my case and will approve my waiver.`,
      'r_past_lessons.waivermodal.title6',
      ``,
      ``,
      ``,
      ``,
    ],
    sent: [``, `Waiver Request Submitted`, 'r_past_lessons.waivermodal.sent', ``, ``, ``, ``],
    result_msg: [
      ``,
      `Your feedback waiver request has been submitted. A Ringle team member will review and confirm/reject before your next payout is processed.`,
      'r_past_lessons.waivermodal.result_msg',
      ``,
      ``,
      ``,
      ``,
    ],
    select_reason: [
      ``,
      `Please select why you are requesting a waiver:`,
      'r_past_lessons.waivermodal.select_reason',
      ``,
      ``,
      ``,
      ``,
    ],
    warning1: [
      ``,
      <>
        If there isn’t a clear approval (i.e. written consent on Ringle Docs)
        <br />
        made by the student, the feedback waiver will not be approved.
      </>,
      'r_past_lessons.waivermodal.warning1',
      ``,
      ``,
      ``,
      ``,
    ],
    warning2: [
      ``,
      <>
        If you would like to request a waiver for any other reason(s), please contact Ringle with your Lesson ID and
        specific reason for the waiver.
      </>,
      'r_past_lessons.waivermodal.warning2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  nolesson_past: [
    ``,
    `You have no past lessons. :) You can update your schedule now to start teaching!`,
    'r_past_lessons.nolesson_past',
    ``,
    ``,
    ``,
    ``,
  ],
  nolesson_to_review: [
    ``,
    `You have no lessons to review. :) You can update your schedule now to teach more!`,
    'r_past_lessons.nolesson_to_review',
    ``,
    ``,
    ``,
    ``,
  ],
  report_btn: {
    tooltip3: [``, `Report inappropriate conduct?`, 'r_past_lessons.report_btn.tooltip3', ``, ``, ``, ``],
    tooltip1: [
      ``,
      `Please report the lesson via the on-site Chat function`,
      'r_past_lessons.report_btn.tooltip1',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip2: [
      ``,
      `You have reported this lesson. We will be in touch as soon as possible.`,
      'r_past_lessons.report_btn.tooltip2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  report_modal: {
    placeholder: [``, `Please describe the incident.`, 'r_past_lessons.report_modal.placeholder', ``, ``, ``, ``],
  },
  submit_feedback: [``, `Submit feedback to see this rating`, 'r_past_lessons.submit_feedback', ``, ``, ``, ``],
  pending: [`Pending`, `Pending`, 'r_past_lessons.pending', ``, ``, ``, ``],
  not_able_to_submit: [
    `You are not able to submit the feedback report since the 48-hour due has passed.`,
    `You are not able to submit the feedback report since the 48-hour due has passed.`,
    'r_past_lessons.not_able_to_submit',
    ``,
    ``,
    ``,
    ``,
  ],
  past_due_feedback: [`Past Due Feedback`, `Past Due Feedback`, 'r_past_lessons.past_due_feedback', ``, ``, ``, ``],
  waiver_confirmed: [`Waiver Confirmed`, `Waiver Confirmed`, 'r_past_lessons.waiver_confirmed', ``, ``, ``, ``],
  complete_report_before_24_hour: [
    `Complete the report before 24-hour late mark (48 hours after the lesson starting time) to receive the full lesson compensation.`,
    `Complete the report before 24-hour late mark (48 hours after the lesson starting time) to receive the full lesson compensation.`,
    'r_past_lessons.complete_report_before_24_hour',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback_required: [`Feedback Required`, `Feedback Required`, 'r_past_lessons.feedback_required', ``, ``, ``, ``],
  incomplete_feedback: [
    `Incomplete Feedback`,
    `Incomplete Feedback`,
    'r_past_lessons.incomplete_feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_title: [``, `Student’s Feedback`, 'r_past_lessons.modal_title', ``, ``, ``, ``],
  modal_subtitle_1: [``, `Keyword review`, 'r_past_lessons.modal_subtitle_1', ``, ``, ``, ``],
  modal_subtitle_2: [``, `Comments`, 'r_past_lessons.modal_subtitle_2', ``, ``, ``, ``],
  keyword_category_1: [``, `Teaching Ability`, 'r_past_lessons.keyword_category_1', ``, ``, ``, ``],
  keyword_category_2: [``, `Engagement/Preperation`, 'r_past_lessons.keyword_category_2', ``, ``, ``, ``],
  keyword_category_3: [``, `Attitude`, 'r_past_lessons.keyword_category_3', ``, ``, ``, ``],
  keyword_category_4: [``, `Teaching Ability`, 'r_past_lessons.keyword_category_4', ``, ``, ``, ``],
  keyword_category_5: [``, `Attitude/Environment`, 'r_past_lessons.keyword_category_5', ``, ``, ``, ``],
  original_text: [``, `Original text`, 'r_past_lessons.original_text', ``, ``, ``, ``],
  translated_text: [``, `Translated text`, 'r_past_lessons.translated_text', ``, ``, ``, ``],
  translated_info: [
    `해당 텍스트는 Google Translator를 통해 번역된 내용입니다. 실제 학생의 의도가 오번역될수 있으므로 유의 부탁드립니다.`,
    `This translation is generated by Google Translate. Please note that your student’s intended meaning may have been mistranslated.`,
    'r_past_lessons.translated_info',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [``, `Close`, 'r_past_lessons.btn', ``, ``, ``, ``],
  btn_translate: [``, `Google Translate`, 'r_past_lessons.btn_translate', ``, ``, ``, ``],
}
export const r_taxpayer = {
  full_name: [``, `Full name`, 'r_taxpayer.full_name', ``, ``, ``, ``],
  your_registration_card: [
    ``,
    `As shown on your registration card in Korean (e.g. 홍길동)`,
    'r_taxpayer.your_registration_card',
    ``,
    ``,
    ``,
    ``,
  ],
  resident_registration_number: [
    ``,
    `Resident registration number (RRN)`,
    'r_taxpayer.resident_registration_number',
    ``,
    ``,
    ``,
    ``,
  ],
  number_and_type_correspond: [
    ``,
    `Number and type must correspond with the provided name`,
    'r_taxpayer.number_and_type_correspond',
    ``,
    ``,
    ``,
    ``,
  ],
  should_have_format: [
    ``,
    `* Your RRN should have the format xxxxxx-xxxxxxx`,
    'r_taxpayer.should_have_format',
    ``,
    ``,
    ``,
    ``,
  ],
  address: [``, `Address`, 'r_taxpayer.address', ``, ``, ``, ``],
  please_enter_your_address: [
    ``,
    `Please enter your address, including apt. or suite no., in Korean. (e.g. 서울 강남구 테헤란로 26길 14 동아아파트 2동 109호)`,
    'r_taxpayer.please_enter_your_address',
    ``,
    ``,
    ``,
    ``,
  ],
  valid_postal_code: [``, `* Please enter a valid postal code`, 'r_taxpayer.valid_postal_code', ``, ``, ``, ``],
  submit: [``, `Submit`, 'r_taxpayer.submit', ``, ``, ``, ``],
  loading: [``, `loading`, 'r_taxpayer.loading', ``, ``, ``, ``],
  principle_resident_registration_number: [
    ``,
    `1. Principle: Resident Registration Number (RRN) issued by Korean tax authority`,
    'r_taxpayer.principle_resident_registration_number',
    ``,
    ``,
    ``,
    ``,
  ],
  issued_by_korean_tax_authority: [
    ``,
    `2. (In the absence of 1) Taxpayer ID Number issued by Korean tax authority`,
    'r_taxpayer.issued_by_korean_tax_authority',
    ``,
    ``,
    ``,
    ``,
  ],
  foreigner_registration_number: [
    ``,
    `3. (In the absence of 1, 2) Foreigner Registration Number under the Foreign Registration Card`,
    'r_taxpayer.foreigner_registration_number',
    ``,
    ``,
    ``,
    ``,
  ],
  report_domestic_residence: [
    ``,
    `4. (In the absence of 1 - 3) Registration Number under the Report of Domestic Residence (for a Korean national residing overseas or a Korean with foreign nationality)`,
    'r_taxpayer.report_domestic_residence',
    ``,
    ``,
    ``,
    ``,
  ],
  passport_number: [``, `5. (In the absence of 1 - 4) Passport Number`, 'r_taxpayer.passport_number', ``, ``, ``, ``],
  taxpayer_identification_number: [
    ``,
    `6. (In the absence of 1 - 5) Taxpayer Identification Number in the country of residence`,
    'r_taxpayer.taxpayer_identification_number',
    ``,
    ``,
    ``,
    ``,
  ],
  none: [``, `None`, 'r_taxpayer.none', ``, ``, ``, ``],
  recent_1_year: [``, `Last 1 year`, 'r_taxpayer.recent_1_year', ``, ``, ``, ``],
  recent_2_years: [``, `Last 2 years`, 'r_taxpayer.recent_2_years', ``, ``, ``, ``],
  yes: [``, `Yes`, 'r_taxpayer.yes', ``, ``, ``, ``],
  no: [``, `No`, 'r_taxpayer.no', ``, ``, ``, ``],
  question_a: [``, `a) Do you maintain an address in Korea?`, 'r_taxpayer.question_a', ``, ``, ``, ``],
  question_b: [
    ``,
    `b) Have you continuously resided in Korea for one year or longer?`,
    'r_taxpayer.question_b',
    ``,
    ``,
    ``,
    ``,
  ],
  question_c: [
    ``,
    `c) Have you stayed in Korea at least 365 days in aggregate during the recent two years?`,
    'r_taxpayer.question_c',
    ``,
    ``,
    ``,
    ``,
  ],
  question_d: [
    ``,
    `d) Has any of your family (i.e., spouse, childeren, etc.) continuously resided in Korea for one year or longer?`,
    'r_taxpayer.question_d',
    ``,
    ``,
    ``,
    ``,
  ],
  question_e: [
    ``,
    `e) Do you have a job requiring you to reside in Korea for one year or longer?`,
    'r_taxpayer.question_e',
    ``,
    ``,
    ``,
    ``,
  ],
  question_f: [``, `f) Are you a Korean government official?`, 'r_taxpayer.question_f', ``, ``, ``, ``],
  question_g: [
    ``,
    `g) Are you an employee as a Korean national seconded to an overseas branch, business office or subsidiary of a Korean company?`,
    'r_taxpayer.question_g',
    ``,
    ``,
    ``,
    ``,
  ],
  income_tax_return: [``, `As shown on your income tax return`, 'r_taxpayer.income_tax_return', ``, ``, ``, ``],
  date_of_birth: [``, `Date of birth`, 'r_taxpayer.date_of_birth', ``, ``, ``, ``],
  enter_your_date: [``, `Enter your date of birth as mm-dd-yyyy.`, 'r_taxpayer.enter_your_date', ``, ``, ``, ``],
  this_field_is_required: [
    ``,
    `* This field is required, and it should have format mm-dd-yyyy, e.g., 06-01-2014`,
    'r_taxpayer.this_field_is_required',
    ``,
    ``,
    ``,
    ``,
  ],
  telephone_number: [``, `Phone number`, 'r_taxpayer.telephone_number', ``, ``, ``, ``],
  phone_number: [``, `Phone number`, 'r_taxpayer.phone_number', ``, ``, ``, ``],
  taxpayer_number: [``, `Taxpayer ID Number`, 'r_taxpayer.taxpayer_number', ``, ``, ``, ``],
  number_and_type_must_correspond: [
    ``,
    `Number and type must correspond with the provided name`,
    'r_taxpayer.number_and_type_must_correspond',
    ``,
    ``,
    ``,
    ``,
  ],
  valid_tax_id_number: [``, `* Please enter a valid Tax ID Number`, 'r_taxpayer.valid_tax_id_number', ``, ``, ``, ``],
  number_of_days_in_korea: [``, `Number of days in Korea`, 'r_taxpayer.number_of_days_in_korea', ``, ``, ``, ``],
  enter_the_number_of_days_you_resided: [
    ``,
    `Enter the number of days you resided in Korea in a recent year. For a non-resident individual who has never been to Korea before, check "None.`,
    'r_taxpayer.enter_the_number_of_days_you_resided',
    ``,
    ``,
    ``,
    ``,
  ],
  select: [``, `Select`, 'r_taxpayer.select', ``, ``, ``, ``],
  number_of_days: [``, `Number of days`, 'r_taxpayer.number_of_days', ``, ``, ``, ``],
  next: [``, `Next`, 'r_taxpayer.next', ``, ``, ``, ``],
  determination_of_nonresident_individual: [
    ``,
    `Determination of Non-resident Individual`,
    'r_taxpayer.determination_of_nonresident_individual',
    ``,
    ``,
    ``,
    ``,
  ],
  question_h: [
    ``,
    `(h) If you are a national or permanent resident of a foreign country, enter the name of such foreign country.`,
    'r_taxpayer.question_h',
    ``,
    ``,
    ``,
    ``,
  ],
  city: [``, `City`, 'r_taxpayer.city', ``, ``, ``, ``],
  explanation_header: [
    ``,
    `The Applicant hereby confirms that with regard to submitting this Application pursuant to Article 156-6 of the Personal Income Tax Law ("PITL") and Article 207-8 of the Enforcement Decree of PITL ("ED-PITL"), the Applicant is clearly aware of the following
provided below, that there is no false statement in the contents of this Application, and that the Applicant is the beneficial
owner (or attorney-in-fact authorized to sign this Application on behalf of the beneficial owner) of all the domestic source income to which this Application relates.`,
    'r_taxpayer.explanation_header',
    ``,
    ``,
    ``,
    ``,
  ],
  explanation_first: [
    ``,
    `1) The Applicant is aware that if any of the contents of this Application is different from true facts, the amount of withholding tax under this Application may be less than the amount of withholding tax that shall be withheld in accordance with the relevant laws.`,
    'r_taxpayer.explanation_first',
    ``,
    ``,
    ``,
    ``,
  ],
  explanation_second: [
    ``,
    `2) If the Applicant's status is going to change from a non-resident individual to a resident individual based on the criteria of determination of a non-resident individual as above, the Applicant will immediately notify such change to whom which this application is filed to and file an annual income tax return together with the tax payment if my annual interest and dividend income exceeds KRW 40 million.`,
    'r_taxpayer.explanation_second',
    ``,
    ``,
    ``,
    ``,
  ],
  your_signature: [``, `Your signature`, 'r_taxpayer.your_signature', ``, ``, ``, ``],
  typing_your_name_acts_your_signature: [
    ``,
    `Typing your name acts as your signature`,
    'r_taxpayer.typing_your_name_acts_your_signature',
    ``,
    ``,
    ``,
    ``,
  ],
  your_name: [``, `Your name`, 'r_taxpayer.your_name', ``, ``, ``, ``],
  sign_date: [``, `Date of signature`, 'r_taxpayer.sign_date', ``, ``, ``, ``],
  use_mm_dd_yyyy_format: [
    ``,
    `Use mm-dd-yyyy format, e.g., 06-30-2014`,
    'r_taxpayer.use_mm_dd_yyyy_format',
    ``,
    ``,
    ``,
    ``,
  ],
  should_have_format_mm_dd_yyyy: [
    ``,
    `* This field is required, and it should have format mm-dd-yyyy, e.g.`,
    'r_taxpayer.should_have_format_mm_dd_yyyy',
    ``,
    ``,
    ``,
    ``,
  ],
  ssn: [``, `SSN`, 'r_taxpayer.ssn', ``, ``, ``, ``],
  ein: [``, `EIN`, 'r_taxpayer.ein', ``, ``, ``, ``],
  individual_sole_proprietor: [
    ``,
    `Individual/sole proprietor`,
    'r_taxpayer.individual_sole_proprietor',
    ``,
    ``,
    ``,
    ``,
  ],
  c_corporation: [``, `C Corporation`, 'r_taxpayer.c_corporation', ``, ``, ``, ``],
  s_corporation: [``, `S Corporation`, 'r_taxpayer.s_corporation', ``, ``, ``, ``],
  partnership: [``, `Partnership`, 'r_taxpayer.partnership', ``, ``, ``, ``],
  trust_estate: [``, `Trust/estate`, 'r_taxpayer.trust_estate', ``, ``, ``, ``],
  llc_single_member: [``, `LLC (Single member)`, 'r_taxpayer.llc_single_member', ``, ``, ``, ``],
  llc_c_corporation: [``, `LLC (C Corporation)`, 'r_taxpayer.llc_c_corporation', ``, ``, ``, ``],
  llc_s_corporation: [``, `LLC (S Corporation)`, 'r_taxpayer.llc_s_corporation', ``, ``, ``, ``],
  llc_partnership: [``, `LLC (Partnership)`, 'r_taxpayer.llc_partnership', ``, ``, ``, ``],
  exempt_payee: [``, `Exempt payee`, 'r_taxpayer.exempt_payee', ``, ``, ``, ``],
  as_shown_on_your_passport: [
    ``,
    `As shown on your passport in English (i.e. order of names is "First Middle Last")`,
    'r_taxpayer.as_shown_on_your_passport',
    ``,
    ``,
    ``,
    ``,
  ],
  business_disregarded_entity_name: [
    ``,
    `Business disregarded entity name`,
    'r_taxpayer.business_disregarded_entity_name',
    ``,
    ``,
    ``,
    ``,
  ],
  if_different_from_full_name: [
    ``,
    `If different from full name (optional)`,
    'r_taxpayer.if_different_from_full_name',
    ``,
    ``,
    ``,
    ``,
  ],
  taxpayer_id_number: [``, `Taxpayer ID Number`, 'r_taxpayer.taxpayer_id_number', ``, ``, ``, ``],
  nine_digit_number: [``, `Your TIN must be nine digits`, 'r_taxpayer.nine_digit_number', ``, ``, ``, ``],
  tax_classification: [``, `Tax classification`, 'r_taxpayer.tax_classification', ``, ``, ``, ``],
  exempt_payee_code: [``, `Exempt payee code (if any)`, 'r_taxpayer.exempt_payee_code', ``, ``, ``, ``],
  description_of_each_code: [
    ``,
    `Please see here for the description of each code`,
    'r_taxpayer.description_of_each_code',
    ``,
    ``,
    ``,
    ``,
  ],
  fatca_reporting_code: [
    ``,
    `Exemption from FATCA reporting code (if any)`,
    'r_taxpayer.fatca_reporting_code',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_your_address: [
    ``,
    `Please enter your address, including apt. or suite no.`,
    'r_taxpayer.enter_your_address',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_valid_postal_code: [
    ``,
    `* Please enter a valid Postal Code`,
    'r_taxpayer.enter_valid_postal_code',
    ``,
    ``,
    ``,
    ``,
  ],
  under_penalties_of_perjury: [
    ``,
    `Under penalties of perjury, I certify that:`,
    'r_taxpayer.under_penalties_of_perjury',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_explanation_secondpage_first: [
    ``,
    `1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and`,
    'r_taxpayer.w9_modal_explanation_secondpage_first',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_explanation_secondpage_second: [
    ``,
    `2. I am not subject to backup withholding because; (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and`,
    'r_taxpayer.w9_modal_explanation_secondpage_second',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_explanation_secondpage_third: [
    ``,
    `3. I am a U.S. citizen or other U.S. person (defined below); and`,
    'r_taxpayer.w9_modal_explanation_secondpage_third',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_explanation_secondpage_fourth: [
    ``,
    `4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.`,
    'r_taxpayer.w9_modal_explanation_secondpage_fourth',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_explanation_secondpage_final: [
    ``,
    `The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.`,
    'r_taxpayer.w9_modal_explanation_secondpage_final',
    ``,
    ``,
    ``,
    ``,
  ],
  typing_your_name_acts: [
    ``,
    `Typing your name acts as your signature`,
    'r_taxpayer.typing_your_name_acts',
    ``,
    ``,
    ``,
    ``,
  ],
  electronic_delivery: [``, `Electronic delivery`, 'r_taxpayer.electronic_delivery', ``, ``, ``, ``],
  uncheck_receive_mailed_copy: [
    ``,
    `Uncheck to receive a mailed copy in addition to an electronic copy.`,
    'r_taxpayer.uncheck_receive_mailed_copy',
    ``,
    ``,
    ``,
    ``,
  ],
  important_disclosure_information: [
    ``,
    `Please read important disclosure information.`,
    'r_taxpayer.important_disclosure_information',
    ``,
    ``,
    ``,
    ``,
  ],
  electronic_1099_forms: [
    ``,
    `Only issue me electronic 1099 forms`,
    'r_taxpayer.electronic_1099_forms',
    ``,
    ``,
    ``,
    ``,
  ],
  w9_modal_title: [``, `IRS Substitute Form W-9`, 'r_taxpayer.w9_modal_title', ``, ``, ``, ``],
  korean_modal_title: [``, `Korean Citizen Info.`, 'r_taxpayer.korean_modal_title', ``, ``, ``, ``],
  no2912_modal_title: [``, `Substitute Form No.29-12`, 'r_taxpayer.no2912_modal_title', ``, ``, ``, ``],
  tax_status_question_first: [``, `Q1. Are you a US citizen?`, 'r_taxpayer.tax_status_question_first', ``, ``, ``, ``],
  tax_status_question_second: [
    ``,
    `Q2. Are you a US Green Card holder?`,
    'r_taxpayer.tax_status_question_second',
    ``,
    ``,
    ``,
    ``,
  ],
  tax_status_question_third: [
    ``,
    `Q3. Are you a US resident for tax purposes, meeting the substantial presence test for the calendar year?`,
    'r_taxpayer.tax_status_question_third',
    ``,
    ``,
    ``,
    ``,
  ],
  tax_status_question_fourth: [
    ``,
    `Q4. Are you a Korean citizen?`,
    'r_taxpayer.tax_status_question_fourth',
    ``,
    ``,
    ``,
    ``,
  ],
  complete_form_W_9: [``, `Complete Form W-9`, 'r_taxpayer.complete_form_W_9', ``, ``, ``, ``],
  complete_korea_citizen_info: [
    ``,
    `Complete Korean Citizen Info.`,
    'r_taxpayer.complete_korea_citizen_info',
    ``,
    ``,
    ``,
    ``,
  ],
  complete_no2912_form: [``, `Complete Form No.29-12`, 'r_taxpayer.complete_no2912_form', ``, ``, ``, ``],
  edit_w9_modal: [``, `Edit your IRS Substitute Form W-9`, 'r_taxpayer.edit_w9_modal', ``, ``, ``, ``],
  edit_korean_modal: [``, `Edit your Korean Citizen Info.`, 'r_taxpayer.edit_korean_modal', ``, ``, ``, ``],
  edit_no2912_modal: [``, `Edit your Substitute Form No.29-12`, 'r_taxpayer.edit_no2912_modal', ``, ``, ``, ``],
  default: [``, `Default`, 'r_taxpayer.default', ``, ``, ``, ``],
  taxpayer_id: [``, <>Taxpayer ID : </>, 'r_taxpayer.taxpayer_id', ``, ``, ``, ``],
  added_on: [``, <>Added on </>, 'r_taxpayer.added_on ', ``, ``, ``, ``],
  edit: [``, `Edit`, 'r_taxpayer.edit', ``, ``, ``, ``],
  delete: [``, `Delete`, 'r_taxpayer.delete', ``, ``, ``, ``],
  tax_status: [``, `Tax Status`, 'r_taxpayer.tax_status', ``, ``, ``, ``],
  taxpayer_information: [``, `Taxpayer Information`, 'r_taxpayer.taxpayer_information', ``, ``, ``, ``],
  tax_payer_information: [
    ``,
    `Please add your taxpayer information.`,
    'r_taxpayer.tax_payer_information',
    ``,
    ``,
    ``,
    ``,
  ],
  add_new_taxpayer: [``, <>+ Add New Taxpayer</>, 'r_taxpayer.add_new_taxpayer', ``, ``, ``, ``],
  name_enter_in_korea: [``, `* Please enter your name in Korean.`, 'r_taxpayer.name_enter_in_korea', ``, ``, ``, ``],
  state_province_region: [``, `State/Province/Region`, 'r_taxpayer.state_province_region', ``, ``, ``, ``],
  postal_code: [``, `Postal code`, 'r_taxpayer.postal_code', ``, ``, ``, ``],
  id_number: [``, `ID number`, 'r_taxpayer.id_number', ``, ``, ``, ``],
  street_address: [``, `Street address`, 'r_taxpayer.street_address', ``, ``, ``, ``],
  state: [``, `State`, 'r_taxpayer.state', ``, ``, ``, ``],
  signature: [``, `Signature`, 'r_taxpayer.signature', ``, ``, ``, ``],
}
export const r_home = {
  lesson_request: {
    title: [``, `Lesson Requests`, 'r_home.lesson_request.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Your students are waiting. Confirm the requests as early as possible.`,
      'r_home.lesson_request.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  unassigned_lesson: {
    title: [``, `Unassigned Lessons`, 'r_home.unassigned_lesson.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Students are waiting for a tutors. Take the unassigned lessons.`,
      'r_home.unassigned_lesson.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  feedback: {
    title: [``, `Feedback Report`, 'r_home.feedback.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Your students are waiting for you to submit feedback.`,
      'r_home.feedback.desc',
      `Your students are waiting for you to submit feedback.`,
      ``,
      ``,
      ``,
    ],
  },
  schedule: {
    title: [``, `Schedule`, 'r_home.schedule.title', ``, ``, ``, ``],
    desc: [``, `Open lesson slots two weeks in advance for a bonus!`, 'r_home.schedule.desc', ``, ``, ``, ``],
  },
  tax_payer: {
    title: [``, `Taxpayer Info`, 'r_home.tax_payer.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Complete your taxpayer info to process your lesson compensation`,
      'r_home.tax_payer.desc',
      `Complete your taxpayer info to process your lesson compensation`,
      ``,
      ``,
      ``,
    ],
  },
  update_schedule: [``, `Update schedule`, 'r_home.update_schedule', ``, ``, ``, ``],
  new_materials: [``, `New Packets`, 'r_home.new_materials', ``, ``, ``, ``],
  ringle_resources: [``, `Ringle Resources`, 'r_home.ringle_resources', ``, ``, ``, ``],
  engagement_bonus: {
    title: [``, `Engagement bonus`, 'r_home.engagement_bonus.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Teach a minimum of one hour per week to receive a bonus of $20 every 4 consecutive weeks! Check your progress here:`,
      'r_home.engagement_bonus.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  new_tutor_bonus: {
    title: [``, `New Tutor Bonus`, 'r_home.new_tutor_bonus.title', ``, ``, ``, ``],
    desc: [``, `Check your progress toward the New Tutor Bonus`, 'r_home.new_tutor_bonus.desc', ``, ``, ``, ``],
    period: [
      ``,
      `Complete 10 hours of lessons within the following period`,
      'r_home.new_tutor_bonus.period',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  new_tutor_stamp_book: {
    desc: [
      ``,
      `You are on your way to unlocking the new tutor bonus challenge. 
You will win $100 for completing 10 hours of lessons within 2 weeks of passing the mock session. (40-min lesson will be counted as 1 hour, 20-min lesson as 0.5 hours)`,
      'r_home.new_tutor_stamp_book.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    mock_session_passed: [``, `Mock Session passed`, 'r_home.new_tutor_stamp_book.mock_session_passed', ``, ``, ``, ``],
    bonus_period: [``, `Challenge period`, 'r_home.new_tutor_stamp_book.bonus_period', ``, ``, ``, ``],
  },
}
export const r_performance = {
  strike_update: [``, `Strike Updates`, 'r_performance.strike_update', ``, ``, ``, ``],
  empty_message: [``, `You have no accumulated strikes. Keep it up! :)`, 'r_performance.empty_message', ``, ``, ``, ``],
  staus: {
    date: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.date',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    detail: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.detail',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    lesson_id: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.lesson_id',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    accumulate_strike: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.accumulate_strike',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    title: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.title',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    tutor_policy: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.tutor_policy',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    completed_lessons: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.completed_lessons',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    average_rating: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.average_rating',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    star: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.star',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    strikes: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.strikes',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    tutor_policy_desc1: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.tutor_policy_desc1',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    tutor_policy_desc2: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.tutor_policy_desc2',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    see_more: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.see_more',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    golden_star_tutor: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.golden_star_tutor',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
    golden_star_title: [
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      'r_performance.staus.golden_star_title',
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
      `TO_BE_DELETED`,
    ],
  },
  log: {
    summary_title: [``, `2-Week Performance Summary`, 'r_performance.log.summary_title', ``, ``, ``, ``],
    title: [``, `2 Week Performance Log`, 'r_performance.log.title', ``, ``, ``, ``],
    ringle_tutor_policy: [
      ``,
      `Ringle Tutor Policy`,
      'r_performance.log.ringle_tutor_policy',
      `Best Practice Lesson`,
      `(이거 Ringle Tutor Policy가 두개 있더라고요, 오른쪽 것을 이걸로 바꿔주세요)`,
      ``,
      ``,
    ],
    best_practice_lesson: [
      ``,
      `Best Practice Lesson`,
      'r_performance.log.best_practice_lesson',
      `Best Practice Lesson`,
      `(이거 Ringle Tutor Policy가 두개 있더라고요, 오른쪽 것을 이걸로 바꿔주세요)`,
      ``,
      ``,
    ],
    completed_lessons: [``, `Completed Lessons`, 'r_performance.log.completed_lessons', ``, ``, ``, ``],
    lesson_id: [``, `Lesson ID`, 'r_performance.log.lesson_id', ``, ``, ``, ``],
    date_type: [``, `Date/Type`, 'r_performance.log.date_type', ``, ``, ``, ``],
    date_type_tooltip: [
      ``,
      <>
        {' '}
        SC = Student's Choice <br /> RC = Ringle's Choice <br /> UA = Unassigned Lesson{' '}
      </>,
      'r_performance.log.date_type_tooltip',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_rating: [``, `Lesson Rating`, 'r_performance.log.lesson_rating', ``, ``, ``, ``],
    noshow: [``, `No-show`, 'r_performance.log.noshow', ``, ``, ``, ``],
    cancellation: [``, `Cancellation`, 'r_performance.log.cancellation', ``, ``, ``, ``],
    last_min_cancellation: [``, `Last Min Cancellation`, 'r_performance.log.last_min_cancellation', ``, ``, ``, ``],
    lateness: [``, `Lateness`, 'r_performance.log.lateness', ``, ``, ``, ``],
    feedback_report: [
      ``,
      <>
        Feedback
        <br />
        Report
      </>,
      'r_performance.log.feedback_report',
      ``,
      ``,
      ``,
      ``,
    ],
    requested_date_time: [``, `Requested Date/Time`, 'r_performance.log.requested_date_time', ``, ``, ``, ``],
    lesson_date_type: [``, `Lesson Date/Type`, 'r_performance.log.lesson_date_type', ``, ``, ``, ``],
    acceptance_status: [``, `Acceptance Status`, 'r_performance.log.acceptance_status', ``, ``, ``, ``],
    last_updated_date: [``, `Last Updated Date`, 'r_performance.log.last_updated_date', ``, ``, ``, ``],
    performance: [``, `Performance`, 'r_performance.log.performance', ``, ``, ``, ``],
    acceptance: [``, `Acceptance`, 'r_performance.log.acceptance', ``, ``, ``, ``],
    averagerating_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.averagerating_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.averagerating_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    acceptancerate_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.acceptancerate_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.acceptancerate_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    noshow_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.noshow_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.noshow_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    lastmincancellation_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.lastmincancellation_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.lastmincancellation_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    cancellation_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.cancellation_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.cancellation_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    lateness_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.lateness_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.lateness_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
    ifr_popup: {
      title: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.ifr_popup.title',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
      content: [
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        'r_performance.log.ifr_popup.content',
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
        `TO_BE_DELETED`,
      ],
    },
  },
  status: {
    date: [``, `Date`, 'r_performance.status.date', ``, ``, ``, ``],
    lesson_date: [``, `Lesson Date/Type`, 'r_performance.status.lesson_date', ``, ``, ``, ``],
    category: [``, `Category`, 'r_performance.status.category', ``, ``, ``, ``],
    detail: [``, `Details`, 'r_performance.status.detail', ``, ``, ``, ``],
    lesson_id: [``, `Lesson ID`, 'r_performance.status.lesson_id', ``, ``, ``, ``],
    title: [``, `Your Performance Overview`, 'r_performance.status.title', ``, ``, ``, ``],
    tutor_policy: [``, `Ringle Tutor Policy`, 'r_performance.status.tutor_policy', ``, ``, ``, ``],
    completed_lessons: [``, `Completed Lessons`, 'r_performance.status.completed_lessons', ``, ``, ``, ``],
    average_rating: [``, `Average Rating`, 'r_performance.status.average_rating', ``, ``, ``, ``],
    star: [``, `Stars`, 'r_performance.status.star', ``, ``, ``, ``],
    strikes: [``, `Strikes`, 'r_performance.status.strikes', ``, ``, ``, ``],
    tutor_policy_desc1: [
      ``,
      `Check the latest Tutor Policy`,
      'r_performance.status.tutor_policy_desc1',
      `Check the latest Tutor Policy`,
      ``,
      ``,
      ``,
    ],
    tutor_policy_desc2: [
      ``,
      `How does the Star-Strike System work?`,
      'r_performance.status.tutor_policy_desc2',
      `How does the Star-Strike System work?`,
      `Link to https://www.ringleplus.com/en/tutor/portal/policy (폴리시페이지)`,
      ``,
      ``,
    ],
    see_more: [``, `See more`, 'r_performance.status.see_more', ``, ``, ``, ``],
    golden_star_tutor: [
      ``,
      `See the lesson demo to improve your performance!`,
      'r_performance.status.golden_star_tutor',
      `See the lesson demo to improve your performance!`,
      ``,
      ``,
      ``,
    ],
    golden_star_title: [
      ``,
      `How to better communicate with my student?`,
      'r_performance.status.golden_star_title',
      `How to better communicate with my student?`,
      `Link to https://www.ringleplus.com/en/tutor/portal/resources/43 (무레아 best practice video)`,
      ``,
      ``,
    ],
    accumulate_strike: [`TO_BE_DELETED`, `TO_BE_DELETED`, 'r_performance.status.accumulate_strike', ``, ``, ``, ``],
    guideline: {
      title: [``, `Star-Strike Guideline`, 'r_performance.status.guideline.title', ``, ``, ``, ``],
      one_star: [``, `1 Star`, 'r_performance.status.guideline.one_star', ``, ``, ``, ``],
      zero_star: [``, `0 Star`, 'r_performance.status.guideline.zero_star', ``, ``, ``, ``],
      one_strike: [``, `1 Strike`, 'r_performance.status.guideline.one_strike', ``, ``, ``, ``],
      two_strikes: [``, `2 Strikes`, 'r_performance.status.guideline.two_strikes', ``, ``, ``, ``],
      three_strikes: [``, `3 Strikes`, 'r_performance.status.guideline.three_strikes', ``, ``, ``, ``],
      empty: [``, `-`, 'r_performance.status.guideline.empty', ``, ``, ``, ``],
    },
    accumulated_strikes: {
      see: [``, `See Accumulated Strikes`, 'r_performance.status.accumulated_strikes.see', ``, ``, ``, ``],
      title: [``, `Accumulated Strikes`, 'r_performance.status.accumulated_strikes.title', ``, ``, ``, ``],
      content: [
        ``,
        `If you accumulate more than 10 strikes over the lifetime of your account, your tutor account will automatically be deactivated and you’ll have to contact the Ringle team to discuss reopening your account and scheduling further lessons.`,
        'r_performance.status.accumulated_strikes.content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total`, 'r_performance.status.accumulated_strikes.total', ``, ``, ``, ``],
      since: [``, `Since`, 'r_performance.status.accumulated_strikes.since', ``, ``, ``, ``],
    },
    star_strike: {
      title: [``, `2-week Star/Strike Status`, 'r_performance.status.star_strike.title', ``, ``, ``, ``],
      total_star: [``, `Total star`, 'r_performance.status.star_strike.total_star', ``, ``, ``, ``],
      total_strikes: [``, `Total Strikes`, 'r_performance.status.star_strike.total_strikes', ``, ``, ``, ``],
      area: [``, `Area`, 'r_performance.status.star_strike.area', ``, ``, ``, ``],
      number: [``, `Number`, 'r_performance.status.star_strike.number', ``, ``, ``, ``],
      received_dates: [``, `Received Date`, 'r_performance.status.star_strike.received_dates', ``, ``, ``, ``],
      status: [``, `Status`, 'r_performance.status.star_strike.status', ``, ``, ``, ``],
    },
    acceptance_rate: {
      title: [``, `Lesson Acceptance History`, 'r_performance.status.acceptance_rate.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of lessons confirmed divided by the total number of lessons requested over the last 2 weeks.`,
        'r_performance.status.acceptance_rate.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [
        ``,
        `Acceptance rate`,
        'r_performance.status.acceptance_rate.guideline_table_0',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_1: [``, `95% or above`, 'r_performance.status.acceptance_rate.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `95% below`, 'r_performance.status.acceptance_rate.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `70% below`, 'r_performance.status.acceptance_rate.guideline_table_3', ``, ``, ``, ``],
      guideline_content: [
        ``,
        <>
          * Lessons that are not accepted within 24 hours of a request being submitted will be automatically declined.{' '}
          <br />
          * Once a slot is automatically declined, the slot cannot be reopened for another lesson request. We recommend
          accepting or declining the lesson as early as possible. <br />* Any lesson slot that you actively decline will
          be available for you to reopen 48 hours later.
        </>,
        'r_performance.status.acceptance_rate.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total Acceptance rate`, 'r_performance.status.acceptance_rate.total', ``, ``, ``, ``],
      total_requested: [``, `Total Requested`, 'r_performance.status.acceptance_rate.total_requested', ``, ``, ``, ``],
      accepted: [``, `Accepted`, 'r_performance.status.acceptance_rate.accepted', ``, ``, ``, ``],
      declined: [``, `Declined`, 'r_performance.status.acceptance_rate.declined', ``, ``, ``, ``],
      requested_date_time: [
        ``,
        `Requested Date/Time`,
        'r_performance.status.acceptance_rate.requested_date_time',
        ``,
        ``,
        ``,
        ``,
      ],
      acceptance_status: [
        ``,
        `Acceptance Status`,
        'r_performance.status.acceptance_rate.acceptance_status',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    avg_rating: {
      title: [``, `Lesson Rating History`, 'r_performance.status.avg_rating.title', ``, ``, ``, ``],
      guideline_table_0: [``, `Average Rating`, 'r_performance.status.avg_rating.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `4.5 or above`, 'r_performance.status.avg_rating.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `4.5 - 4.0`, 'r_performance.status.avg_rating.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `Lower than 4.0`, 'r_performance.status.avg_rating.guideline_table_3', ``, ``, ``, ``],
      guideline_content: [
        ``,
        <>
          * Lessons in which the student doesn't leave a rating will be granted an automatic rating of 4.5 <br />
          * Student no-shows will be granted an automatic rating of 4.5 <br />
          * 20-minute lessons are counted as 0.5 of a total lesson within the Average Rating calculation. <br />
          * Lessons in which the student is assigned within 24 hours of the lesson's start time will be granted an
          automatic rating of 4.5 if the original rating was lower. <br />* This rating only applies to the Average
          Rating, and not the rating visible in your Tutor Profile to students.
        </>,
        'r_performance.status.avg_rating.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total Average Rating`, 'r_performance.status.avg_rating.total', ``, ``, ``, ``],
      rating: [``, `Rating`, 'r_performance.status.avg_rating.rating', ``, ``, ``, ``],
    },
    lateness: {
      title: [``, `Lateness History`, 'r_performance.status.lateness.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of lessons you are late to by more than one minute.`,
        'r_performance.status.lateness.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [``, `Lateness`, 'r_performance.status.lateness.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `0 - 2`, 'r_performance.status.lateness.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `3`, 'r_performance.status.lateness.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `4`, 'r_performance.status.lateness.guideline_table_3', ``, ``, ``, ``],
      guideline_table_4: [``, `5`, 'r_performance.status.lateness.guideline_table_4', ``, ``, ``, ``],
      guideline_table_5: [``, `6`, 'r_performance.status.lateness.guideline_table_5', ``, ``, ``, ``],
      total: [``, `Total Latenesses`, 'r_performance.status.lateness.total', ``, ``, ``, ``],
      accumulated_latenesses: [
        ``,
        `Accumulated Latenesses`,
        'r_performance.status.lateness.accumulated_latenesses',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    no_show: {
      title: [``, `No-Show History`, 'r_performance.status.no_show.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of lessons that you either don’t enter, or enter over 3 minutes (for 40-min lessons) or 2 minutes (for 20-min lessons) late.`,
        'r_performance.status.no_show.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [``, `No-Show`, 'r_performance.status.no_show.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `0`, 'r_performance.status.no_show.guideline_table_1', ``, ``, ``, ``],
      guideline_table_3: [``, `1`, 'r_performance.status.no_show.guideline_table_3', ``, ``, ``, ``],
      guideline_table_4: [``, `2`, 'r_performance.status.no_show.guideline_table_4', ``, ``, ``, ``],
      guideline_table_5: [``, `3`, 'r_performance.status.no_show.guideline_table_5', ``, ``, ``, ``],
      guideline_content: [
        ``,
        `* For every No-Show count, 3 promotion bonus points will be deducted from your account.`,
        'r_performance.status.no_show.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total No-show`, 'r_performance.status.no_show.total', ``, ``, ``, ``],
      accumulated_noshows: [
        ``,
        `Accumulated No-Shows`,
        'r_performance.status.no_show.accumulated_noshows',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    ifr: {
      title: [``, `Incomplete Feedback Report History`, 'r_performance.status.ifr.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of Feedback Reports left incomplete more than 24 hours after the lesson's start time.`,
        'r_performance.status.ifr.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [``, `IFR`, 'r_performance.status.ifr.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `0`, 'r_performance.status.ifr.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `1`, 'r_performance.status.ifr.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `2`, 'r_performance.status.ifr.guideline_table_3', ``, ``, ``, ``],
      guideline_table_4: [``, `3`, 'r_performance.status.ifr.guideline_table_4', ``, ``, ``, ``],
      guideline_table_5: [``, `4`, 'r_performance.status.ifr.guideline_table_5', ``, ``, ``, ``],
      guideline_content: [
        ``,
        `* If a Feedback Report is left incomplete more than 48 hours after the lesson’s start time, the student will be refunded and the tutor will receive no compensation for the lesson.`,
        'r_performance.status.ifr.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total IFR`, 'r_performance.status.ifr.total', ``, ``, ``, ``],
      accumulated_ifrs: [``, `Accumulated IFRs`, 'r_performance.status.ifr.accumulated_ifrs', ``, ``, ``, ``],
    },
    lmc: {
      title: [``, `Last Minute Cancellation History`, 'r_performance.status.lmc.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of cancellations made within 24 hours of the lesson's start time.`,
        'r_performance.status.lmc.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [``, `LMC`, 'r_performance.status.lmc.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `0`, 'r_performance.status.lmc.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `1`, 'r_performance.status.lmc.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `2`, 'r_performance.status.lmc.guideline_table_3', ``, ``, ``, ``],
      guideline_table_4: [``, `3`, 'r_performance.status.lmc.guideline_table_4', ``, ``, ``, ``],
      guideline_table_5: [``, `4`, 'r_performance.status.lmc.guideline_table_5', ``, ``, ``, ``],
      guideline_content: [
        ``,
        `* For every last minute cancellation, 2 promotion points will be deducted from your account.`,
        'r_performance.status.lmc.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total LMC`, 'r_performance.status.lmc.total', ``, ``, ``, ``],
      accumulated_lmcs: [``, `Accumulated LMCs`, 'r_performance.status.lmc.accumulated_lmcs', ``, ``, ``, ``],
    },
    cancellation: {
      title: [``, `Cancellation History`, 'r_performance.status.cancellation.title', ``, ``, ``, ``],
      guideline_title: [
        ``,
        `The number of cancellations made more than 24 hours in advance of a lesson's scheduled start time.`,
        'r_performance.status.cancellation.guideline_title',
        ``,
        ``,
        ``,
        ``,
      ],
      guideline_table_0: [``, `Cancellation`, 'r_performance.status.cancellation.guideline_table_0', ``, ``, ``, ``],
      guideline_table_1: [``, `0 or 1`, 'r_performance.status.cancellation.guideline_table_1', ``, ``, ``, ``],
      guideline_table_2: [``, `2`, 'r_performance.status.cancellation.guideline_table_2', ``, ``, ``, ``],
      guideline_table_3: [``, `3`, 'r_performance.status.cancellation.guideline_table_3', ``, ``, ``, ``],
      guideline_table_4: [``, `4`, 'r_performance.status.cancellation.guideline_table_4', ``, ``, ``, ``],
      guideline_table_5: [``, `5`, 'r_performance.status.cancellation.guideline_table_5', ``, ``, ``, ``],
      guideline_content: [
        ``,
        `* For every lesson cancelled during this window, 1 promotion point will be deducted from your account.`,
        'r_performance.status.cancellation.guideline_content',
        ``,
        ``,
        ``,
        ``,
      ],
      total: [``, `Total Cancellation`, 'r_performance.status.cancellation.total', ``, ``, ``, ``],
      accumulated_cancellations: [
        ``,
        `Accumulated Cancellations`,
        'r_performance.status.cancellation.accumulated_cancellations',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const r_mypage_payout = {
  payout_methods: [``, `Payout Methods`, 'r_mypage_payout.payout_methods', ``, ``, ``, ``],
  edit: [``, `Edit`, 'r_mypage_payout.edit', ``, ``, ``, ``],
  update: [``, `update`, 'r_mypage_payout.update', ``, ``, ``, ``],
}
export const r_mypage = {
  please_write_your_video: [
    ``,
    `Please write a title for your video`,
    'r_mypage.please_write_your_video',
    ``,
    ``,
    ``,
    ``,
  ],
  check_box_acknowledge_your_video: [
    ``,
    `You have to check the box to acknowledge that your video will be available for viewing by Ringle users`,
    'r_mypage.check_box_acknowledge_your_video',
    ``,
    ``,
    ``,
    ``,
  ],
  error_uploading_your_video: [
    ``,
    `There was an error uploading your video`,
    'r_mypage.error_uploading_your_video',
    ``,
    ``,
    ``,
    ``,
  ],
  please_check_and_upload_again: [
    ``,
    `Unsupported file type. Please check and upload again.`,
    'r_mypage.please_check_and_upload_again',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_students_can_see_this_content: [
    ``,
    `Ringle students can see this content.`,
    'r_mypage.ringle_students_can_see_this_content',
    ``,
    ``,
    ``,
    ``,
  ],
  view_as_student: [``, `View as Student`, 'r_mypage.view_as_student', ``, ``, ``, ``],
  photo_video: [``, `Photo & Video`, 'r_mypage.photo_video', ``, ``, ``, ``],
  profile_picture: [``, `Profile Picture`, 'r_mypage.profile_picture', ``, ``, ``, ``],
  the_ideal_size_for_you: [
    ``,
    `The ideal size for your Ringle profile picture is 170 x 170 pixels.`,
    'r_mypage.the_ideal_size_for_you',
    ``,
    ``,
    ``,
    ``,
  ],
  max_file_size: [``, `Max. file size : 8 MB`, 'r_mypage.max_file_size', ``, ``, ``, ``],
  supported_file_format: [``, `supported file format : jpg, png`, 'r_mypage.supported_file_format', ``, ``, ``, ``],
  see_examples: [``, `See Examples`, 'r_mypage.see_examples', ``, ``, ``, ``],
  check_uploaded_file: [``, `Check uploaded file`, 'r_mypage.check_uploaded_file', ``, ``, ``, ``],
  re_upload: [``, `Re-upload`, 'r_mypage.re_upload', ``, ``, ``, ``],
  self_introduction_video: [``, `Self-introduction Video`, 'r_mypage.self_introduction_video', ``, ``, ``, ``],
  retake: [``, `Retake`, 'r_mypage.retake', ``, ``, ``, ``],
  teens_students_can_see_this_content: [
    ``,
    `Teens students can see this content.`,
    'r_mypage.teens_students_can_see_this_content',
    ``,
    ``,
    ``,
    ``,
  ],
  introduction: [``, `Introduction`, 'r_mypage.introduction', ``, ``, ``, ``],
  preview: [``, `Preview`, 'r_mypage.preview', ``, ``, ``, ``],
  save_change: [``, `Save Change`, 'r_mypage.save_change', ``, ``, ``, ``],
  input_keyword: [``, `Input keyword`, 'r_mypage.input_keyword', ``, ``, ``, ``],
  this_keyword_is_too_long: [
    ``,
    `This keyword is too long. Please input within 17 characters`,
    'r_mypage.this_keyword_is_too_long',
    ``,
    ``,
    ``,
    ``,
  ],
  please_fill_out_one_keyword_experience_category: [
    ``,
    `Please fill out at least one keyword in Experience category!`,
    'r_mypage.please_fill_out_one_keyword_experience_category',
    ``,
    ``,
    ``,
    ``,
  ],
  please_fill_out_least_one_keyword_in_interests_category: [
    ``,
    `Please fill out at least one keyword in Interests category!`,
    'r_mypage.please_fill_out_least_one_keyword_in_interests_category',
    ``,
    ``,
    ``,
    ``,
  ],
  please_choose_one_primary_keyword: [
    ``,
    `Please choose one primary keyword!`,
    'r_mypage.please_choose_one_primary_keyword',
    ``,
    ``,
    ``,
    ``,
  ],
  successfully_saved: [``, `Successfully saved`, 'r_mypage.successfully_saved', ``, ``, ``, ``],
  please_input_your_keywords_and_junior_preference: [
    ``,
    `Please input your keywords and junior preference!`,
    'r_mypage.please_input_your_keywords_and_junior_preference',
    ``,
    ``,
    ``,
    ``,
  ],
  love_to_teach_junior_students: [
    ``,
    `I would love to teach junior students`,
    'r_mypage.love_to_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  have_no_preference: [``, `I have no preference`, 'r_mypage.have_no_preference', ``, ``, ``, ``],
  want_to_teach_junior_students: [
    ``,
    `I do not want to teach junior students`,
    'r_mypage.want_to_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  preference: [``, `Preference`, 'r_mypage.preference', ``, ``, ``, ``],
  please_indicate_whether_you_happy_to_teach_junior_students: [
    ``,
    `Please indicate whether you’re happy to teach junior students (under 18; K-12)`,
    'r_mypage.please_indicate_whether_you_happy_to_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  your_keywords: [``, `Your Keywords`, 'r_mypage.your_keywords', ``, ``, ``, ``],
  choose_keywords_that_you_feel_best: [
    ``,
    `Choose keywords that you feel best represent your experience and interests. Put a star by the keyword in each category that you feel is more important. These will be highlighted on your profile's cover page, so choose wisely!`,
    'r_mypage.choose_keywords_that_you_feel_best',
    ``,
    ``,
    ``,
    ``,
  ],
  experience: [``, `Experience`, 'r_mypage.experience', ``, ``, ``, ``],
  interests: [``, `Interests`, 'r_mypage.interests', ``, ``, ``, ``],
}
export const r_promotion = {
  status: {
    level: [``, `Level`, 'r_promotion.status.level', ``, ``, ``, ``],
    point: [``, `Point`, 'r_promotion.status.point', ``, ``, ``, ``],
    base_pay: [``, `Base pay`, 'r_promotion.status.base_pay', ``, ``, ``, ``],
    your_promotion_points: [``, `Your Promotion Points`, 'r_promotion.status.your_promotion_points', ``, ``, ``, ``],
    this_week: [``, `This Week`, 'r_promotion.status.this_week', ``, ``, ``, ``],
    accumulated: [``, `Accumulated`, 'r_promotion.status.accumulated', ``, ``, ``, ``],
    total: [``, `Total`, 'r_promotion.status.total', ``, ``, ``, ``],
    pts: [``, `pts`, 'r_promotion.status.pts', ``, ``, ``, ``],
    lessons: [``, `Lessons`, 'r_promotion.status.lessons', ``, ``, ``, ``],
    golden_star_tutor: [``, `Golden star tutor`, 'r_promotion.status.golden_star_tutor', ``, ``, ``, ``],
    others: [``, `Others`, 'r_promotion.status.others', ``, ``, ``, ``],
    policy_on_level: [
      ``,
      `Tutor Payment Level`,
      'r_promotion.status.policy_on_level',
      `Tutor Payment Level`,
      ``,
      ``,
      ``,
    ],
    policy_on_level_desc: [
      ``,
      `Check out  your payment level based on the accumulated promotion points.`,
      'r_promotion.status.policy_on_level_desc',
      `Check out  your payment level based on the accumulated promotion points.`,
      ``,
      ``,
      ``,
    ],
    incentive: [``, `Incentive`, 'r_promotion.status.incentive', ``, ``, ``, ``],
    bonus: [``, `Bonus`, 'r_promotion.status.bonus', ``, ``, ``, ``],
  },
  log: {
    title: [``, `Point Log`, 'r_promotion.log.title', ``, ``, ``, ``],
    this_week: [``, `This Week`, 'r_promotion.log.this_week', ``, ``, ``, ``],
    total: [``, `Total`, 'r_promotion.log.total', ``, ``, ``, ``],
    lesson_id: [``, `Lesson ID`, 'r_promotion.log.lesson_id', ``, ``, ``, ``],
    date_time: [``, `Date/Time`, 'r_promotion.log.date_time', ``, ``, ``, ``],
    status: [``, `Status`, 'r_promotion.log.status', ``, ``, ``, ``],
    lesson_rating_point: [
      ``,
      <>
        Lesson
        <br />
        Rating & Point
      </>,
      'r_promotion.log.lesson_rating_point',
      ``,
      ``,
      ``,
      ``,
    ],
    golden_star_bonus: [
      ``,
      <>
        Golden Star
        <br />
        Bonus
      </>,
      'r_promotion.log.golden_star_bonus',
      ``,
      ``,
      ``,
      ``,
    ],
    others: [``, `Other`, 'r_promotion.log.others', ``, ``, ``, ``],
    lesson_rating: [
      ``,
      <>
        Lesson
        <br />
        Rating
      </>,
      'r_promotion.log.lesson_rating',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_point: [
      ``,
      <>
        Lesson
        <br />
        Point
      </>,
      'r_promotion.log.lesson_point',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const r_payment = {
  overview: {
    level: [``, `Level`, 'r_payment.overview.level', ``, ``, ``, ``],
    base_pay: [``, `Base pay`, 'r_payment.overview.base_pay ', ``, ``, ``, ``],
    available_balance: [``, `Available Balance`, 'r_payment.overview.available_balance', ``, ``, ``, ``],
    request_payouts: [``, `Request Payouts`, 'r_payment.overview.request_payouts', ``, ``, ``, ``],
    weekly_earnings: [``, `Your weekly earnings`, 'r_payment.overview.weekly_earnings', ``, ``, ``, ``],
    previous_week: [``, `Previous Week`, 'r_payment.overview.previous_week', ``, ``, ``, ``],
    this_week: [``, `This Week`, 'r_payment.overview.this_week', ``, ``, ``, ``],
    pay_day: [``, `Will be available for Payout on`, 'r_payment.overview.pay_day', ``, ``, ``, ``],
    lesson_payment: [``, `Lesson Payment`, 'r_payment.overview.lesson_payment', ``, ``, ``, ``],
    pending_earnings: [``, `Pending Earnings`, 'r_payment.overview.pending_earnings', ``, ``, ``, ``],
    incentives: [``, `Incentives`, 'r_payment.overview.incentives', ``, ``, ``, ``],
    bonus_payment: [``, `Bonus Payment`, 'r_payment.overview.bonus_payment', ``, ``, ``, ``],
    others: [``, `Others`, 'r_payment.overview.others', ``, ``, ``, ``],
    see_detail: [``, `See Details`, 'r_payment.overview.see_detail', ``, ``, ``, ``],
    feedback_report: [
      ``,
      `Lesson Feedback Reports need to be submitted within 24 hours after the scheduled lesson time.`,
      'r_payment.overview.feedback_report',
      ``,
      ``,
      ``,
      ``,
    ],
    go_past: [``, `Go to Past Lessons`, 'r_payment.overview.go_past', ``, ``, ``, ``],
    incentive: [``, `incentive`, 'r_payment.overview.incentive', ``, ``, ``, ``],
    weekly_incentive: [``, `Weekly Incentive`, 'r_payment.overview.weekly_incentive', ``, ``, ``, ``],
    achieved: [``, `Achieved`, 'r_payment.overview.achieved', ``, ``, ``, ``],
    not_achieved: [``, `Not achieved`, 'r_payment.overview.not_achieved', ``, ``, ``, ``],
    weekly_incentive_desc: [
      ``,
      `You will receive a bonus of 5% on an entire week’s compensation
                if you complete at least 10 hours of lessons with an Average
                Rating of 4.5 or above.`,
      'r_payment.overview.weekly_incentive_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    average_ratings: [``, `Average Ratings`, 'r_payment.overview.average_ratings', ``, ``, ``, ``],
    scheduling_incentive: [
      ``,
      `Lesson Scheduling Incentive`,
      'r_payment.overview.scheduling_incentive',
      ``,
      ``,
      ``,
      ``,
    ],
    scheduling_incentive_desc: [
      ``,
      `You will receive a bonus of 3% for all completed lessons that
                were scheduled more than 2 weeks in advance.`,
      'r_payment.overview.scheduling_incentive_desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  payout: {
    title: [``, `Request Payouts`, 'r_payment.payout.title', ``, ``, ``, ``],
    available_balance: [``, `Available Balance`, 'r_payment.payout.available_balance', ``, ``, ``, ``],
    enter: [``, `Enter`, 'r_payment.payout.enter', ``, ``, ``, ``],
    minimum_request: [
      ``,
      `*The minimum amount you can request is $ 90.`,
      'r_payment.payout.minimum_request',
      ``,
      ``,
      ``,
      ``,
    ],
    requested_amount: [``, `Requested amount`, 'r_payment.payout.requested_amount', ``, ``, ``, ``],
    paypal_fees: [``, `PayPal fees`, 'r_payment.payout.paypal_fees', ``, ``, ``, ``],
    you_will_get: [``, `You will get`, 'r_payment.payout.you_will_get', ``, ``, ``, ``],
    request_payouts: [``, `Request Payouts`, 'r_payment.payout.request_payouts', ``, ``, ``, ``],
    payout_status: [``, `Payout Status`, 'r_payment.payout.payout_status', ``, ``, ``, ``],
    transaction_history: [``, `Transaction History`, 'r_payment.payout.transaction_history', ``, ``, ``, ``],
    total_payout: [``, `Total Payout`, 'r_payment.payout.total_payout', ``, ``, ``, ``],
  },
  log: {
    lesson_agreement: [``, `Lesson Agreeement`, 'r_payment.log.lesson_agreement', ``, ``, ``, ``],
    lesson_info: {
      title: [``, `Lesson Info`, 'r_payment.log.lesson_info.title', ``, ``, ``, ``],
      slot_opened: [``, `Slot Opened (Last Updated)`, 'r_payment.log.lesson_info.slot_opened', ``, ``, ``, ``],
      lesson_requested: [``, `Lesson Requested`, 'r_payment.log.lesson_info.lesson_requested', ``, ``, ``, ``],
      lesson_accepted: [``, `Lesson Accepted`, 'r_payment.log.lesson_info.lesson_accepted', ``, ``, ``, ``],
      feedback_submitted: [``, `Feedback Submitted`, 'r_payment.log.lesson_info.feedback_submitted', ``, ``, ``, ``],
    },
    adjustment: {
      title: [``, `Adjustment`, 'r_payment.log.adjustment.title', ``, ``, ``, ``],
      network_adjustment: [``, `Network Adjustment`, 'r_payment.log.adjustment.network_adjustment', ``, ``, ``, ``],
      feedback_adjustment: [``, `Feedback Adjustment`, 'r_payment.log.adjustment.feedback_adjustment', ``, ``, ``, ``],
      other_adjustment: [``, `Other Adjustment`, 'r_payment.log.adjustment.other_adjustment', ``, ``, ``, ``],
    },
    incentives: {
      title: [``, `Incentives`, 'r_payment.log.incentives.title', ``, ``, ``, ``],
      weekly: [``, `Weekly Incentives`, 'r_payment.log.incentives.weekly', ``, ``, ``, ``],
      lesson: [``, `Lesson Incentives`, 'r_payment.log.incentives.lesson', ``, ``, ``, ``],
    },
    title: [``, `Pay Log`, 'r_payment.log.title', ``, ``, ``, ``],
    empty: [
      ``,
      <>
        You do not have any data
        <br /> for the dates currently selected.
      </>,
      'r_payment.log.empty',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const r_profile = {
  see_this_content: [``, `Ringle students can see this content.`, 'r_profile.see_this_content', ``, ``, ``, ``],
  view_as_student: [``, `View as Student`, 'r_profile.view_as_student', ``, ``, ``, ``],
  introduction: [``, `Introduction`, 'r_profile.introduction', ``, ``, ``, ``],
  at_least_50: [``, `At least 50 words`, 'r_profile.at_least_50', ``, ``, ``, ``],
  words: [``, `words`, 'r_profile.words', ``, ``, ``, ``],
  preview: [``, `Preview`, 'r_profile.preview', ``, ``, ``, ``],
  save_change: [``, `Save Changes`, 'r_profile.save_change', ``, ``, ``, ``],
  please_write_title: [``, `Please write a title for your video`, 'r_profile.please_write_title ', ``, ``, ``, ``],
  check_the_box_acknowledge: [
    ``,
    `You have to check the box to acknowledge that your video will be available for viewing by Ringle users`,
    'r_profile.check_the_box_acknowledge',
    ``,
    ``,
    ``,
    ``,
  ],
  error_uploading_your_video: [
    ``,
    `There was an error uploading your video`,
    'r_profile.error_uploading_your_video',
    ``,
    ``,
    ``,
    ``,
  ],
  unsupported_file_type: [
    ``,
    `Unsupported file type. Please check and upload again.`,
    'r_profile.unsupported_file_type',
    ``,
    ``,
    ``,
    ``,
  ],
  photo_video: [``, `Photo & Video`, 'r_profile.photo_video', ``, ``, ``, ``],
  profile_picture: [``, `Profile Picture`, 'r_profile.profile_picture', ``, ``, ``, ``],
  ideal_size_your_ringle_profile: [
    ``,
    `The ideal size for your Ringle profile picture is 170 x 170 pixels.`,
    'r_profile.ideal_size_your_ringle_profile',
    ``,
    ``,
    ``,
    ``,
  ],
  max_file_size: [``, `Max. file size : 8 MB`, 'r_profile.max_file_size', ``, ``, ``, ``],
  supported_file_format: [``, `supported file format : jpg, png`, 'r_profile.supported_file_format', ``, ``, ``, ``],
  see_examples: [``, `See Examples`, 'r_profile.see_examples', ``, ``, ``, ``],
  check_uploaded_file: [``, `Check uploaded file`, 'r_profile.check_uploaded_file', ``, ``, ``, ``],
  re_upload: [``, `Re-upload`, 'r_profile.re_upload', ``, ``, ``, ``],
  self_introduction_video: [``, `Self-introduction Video`, 'r_profile.self_introduction_video', ``, ``, ``, ``],
  retake: [``, `Retake`, 'r_profile.retake', ``, ``, ``, ``],
  this_keyword_is_too_long: [
    ``,
    `This keyword is too long. Please input within 17 characters`,
    'r_profile.this_keyword_is_too_long',
    ``,
    ``,
    ``,
    ``,
  ],
  fill_out_interests_category: [
    ``,
    `Please fill out at least one keyword in Interests category!`,
    'r_profile.fill_out_interests_category',
    ``,
    ``,
    ``,
    ``,
  ],
  fill_out_experience_category: [
    ``,
    `Please fill out at least one keyword in Experience category!`,
    'r_profile.fill_out_experience_category',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_one_primary_keyword: [
    ``,
    `Please choose one primary keyword!`,
    'r_profile.choose_one_primary_keyword',
    ``,
    ``,
    ``,
    ``,
  ],
  indicate_teach_junior_students: [
    ``,
    `Please indicate whether you’re happy to teach junior students!`,
    'r_profile.indicate_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  input_keywords_junior_preference: [
    ``,
    `Please input your keywords and junior preference!`,
    'r_profile.input_keywords_junior_preference',
    ``,
    ``,
    ``,
    ``,
  ],
  successfully_saved: [``, `Successfully saved`, 'r_profile.successfully_saved', ``, ``, ``, ``],
  love_to_teach_junior_students: [
    ``,
    `I would love to teach junior students`,
    'r_profile.love_to_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  no_preference: [``, `I have no preference`, 'r_profile.no_preference', ``, ``, ``, ``],
  dont_want_teach_junior_students: [
    ``,
    `I do not want to teach junior students`,
    'r_profile.dont_want_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  preference: [``, `Preference`, 'r_profile.preference', ``, ``, ``, ``],
  junior_preference: [``, `Junior Preference`, 'r_profile.junior_preference', ``, ``, ``, ``],
  indicate_whether_happy_teach_junior_students: [
    ``,
    `Please indicate whether you’re happy to teach junior students (under 18; K-12)`,
    'r_profile.indicate_whether_happy_teach_junior_students',
    ``,
    ``,
    ``,
    ``,
  ],
  your_keywords: [``, `Your Keywords`, 'r_profile.your_keywords', ``, ``, ``, ``],
  choose_keywords_that_you_feel_best: [
    ``,
    `Choose keywords that you feel best represent your experience and interests. Put a star by the keyword in each category that you feel is more important. These will be highlighted on your profile's cover page, so choose wisely!`,
    'r_profile.choose_keywords_that_you_feel_best',
    ``,
    ``,
    ``,
    ``,
  ],
  experience: [``, `Experience`, 'r_profile.experience', ``, ``, ``, ``],
  interests: [``, `Interests`, 'r_profile.interests', ``, ``, ``, ``],
  fill_out_introduction: [``, `Please fill out introduction!`, 'r_profile.fill_out_introduction', ``, ``, ``, ``],
  fill_out_fun_facts_about_me: [
    ``,
    `Please fill out fun facts about me!`,
    'r_profile.fill_out_fun_facts_about_me',
    ``,
    ``,
    ``,
    ``,
  ],
  select_other_question: [``, `Please select other question`, 'r_profile.select_other_question', ``, ``, ``, ``],
  teens_students_can_see_this: [
    ``,
    `Teens students can see this content.`,
    'r_profile.teens_students_can_see_this',
    ``,
    ``,
    ``,
    ``,
  ],
  your_interests: [``, `Your interests`, 'r_profile.your_interests', ``, ``, ``, ``],
  your_strength_tutor: [``, `Your strength as a tutor`, 'r_profile.your_strength_tutor', ``, ``, ``, ``],
  one_book_you_can_talk: [
    ``,
    `Something you are confident in teaching (SAT, debate, Casual conversation)`,
    'r_profile.one_book_you_can_talk',
    ``,
    ``,
    ``,
    ``,
  ],
  one_sentence_introduce_myself: [
    ``,
    `One sentence to introduce myself`,
    'r_profile.one_sentence_introduce_myself',
    ``,
    ``,
    ``,
    ``,
  ],
  at_least_25: [``, `at least 25 words`, 'r_profile.at_least_25', ``, ``, ``, ``],
  less_than_10_mb: [``, `Less than 10 MB is allowed.`, 'r_profile.less_than_10_mb', ``, ``, ``, ``],
  input_keyword: [``, `Input keyword`, 'r_profile.input_keyword', ``, ``, ``, ``],
  select_question: [``, `Select a question to answer`, 'r_profile.select_question', ``, ``, ``, ``],
  enter_book_author: [``, `Enter the book's author`, 'r_profile.enter_book_author', ``, ``, ``, ``],
  enter_book_title: [``, `Enter the book's title`, 'r_profile.enter_book_title', ``, ``, ``, ``],
  main_keyword: [``, `(main keyword)`, 'r_profile.main_keyword', ``, ``, ``, ``],
}
export const r_referral = {
  introduction: {
    intro1: [
      `Earn extra cash and raffle tickets for a  💻 MacBook Pro  💻`,
      `Earn extra cash and raffle tickets for a  💻 MacBook Pro  💻`,
      'r_referral.introduction.intro1',
      ``,
      ``,
      ``,
      ``,
    ],
    intro2: [
      `Refer your campus friends now!`,
      `Refer your campus friends now!`,
      'r_referral.introduction.intro2',
      ``,
      ``,
      ``,
      ``,
    ],
    intro3: [
      `Share the following link with your campus friends to enter.`,
      `Share the following link with your campus friends to enter.`,
      'r_referral.introduction.intro3',
      ``,
      ``,
      ``,
      ``,
    ],
    link: (referralCode) => [
      `Referral Link : https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
      `Referral Link : https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
      'r_referral.introduction.link',
      ``,
      ``,
      ``,
      ``,
    ],
    copy: {
      message: [
        `The link has been copied. Share this link with your campus friends!`,
        `The link has been copied. Share this link with your campus friends!`,
        'r_referral.introduction.copy.message',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: (referralCode) => [
        `Link: https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
        `Link: https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
        'r_referral.introduction.copy.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      msg: (referralCode) => [
        `Looking for a *super* flexible part-time job in college? 

Tutor with Ringle, an up-and-coming EdTech based in Seoul and San Francisco. 

- $17 ~ $20/hr (with additional weekly incentives + bonuses)
- Interesting conversation with global professionals on trending topics such as Netflix, the metaverse, and remote work
- Sign up NOW for a $5 gift card and a $100 bonus

Link: https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
        `Looking for a *super* flexible part-time job in college? 

Tutor with Ringle, an up-and-coming EdTech based in Seoul and San Francisco. 

- $17 ~ $20/hr (with additional weekly incentives + bonuses)
- Interesting conversation with global professionals on trending topics such as Netflix, the metaverse, and remote work
- Sign up NOW for a $5 gift card and a $100 bonus

Link: https://event.ringleplus.com/en/referral/verify?source=${referralCode}`,
        'r_referral.introduction.copy.msg',
        ``,
        ``,
        ``,
        ``,
      ],
      link: [
        `The link has been copied. Share this link with your campus friends!`,
        `The link has been copied. Share this link with your campus friends!`,
        'r_referral.introduction.copy.link',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    intro4: [
      `(*Participation in this event is NOT possible with your old referral code!)`,
      `(*Participation in this event is NOT possible with your old referral code!)`,
      'r_referral.introduction.intro4',
      ``,
      ``,
      ``,
      ``,
    ],
    contact: [
      `If you have more questions, please email us at tutor@ringleplus.com for additional information.`,
      `If you have more questions, please email us at tutor@ringleplus.com for additional information.`,
      'r_referral.introduction.contact',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  guide: {
    title1: [`What is one stamp worth?`, `What is one stamp worth?`, 'r_referral.guide.title1', ``, ``, ``, ``],
    title2: [`How do I collect stamps?`, `How do I collect stamps?`, 'r_referral.guide.title2', ``, ``, ``, ``],
    title3: [`How do I win a Macbook Pro?`, `How do I win a Macbook Pro?`, 'r_referral.guide.title3', ``, ``, ``, ``],
    title4: [`How to get raffle tickets?`, `How to get raffle tickets?`, 'r_referral.guide.title4', ``, ``, ``, ``],
    title5: [
      `Can I refer friends who have previously been referred to Ringle?`,
      `Can I refer friends who have previously been referred to Ringle?`,
      'r_referral.guide.title5',
      ``,
      ``,
      ``,
      ``,
    ],
    title6: [
      `What benefits do my friends enjoy?`,
      `What benefits do my friends enjoy?`,
      'r_referral.guide.title6',
      ``,
      ``,
      ``,
      ``,
    ],
    title7: [`What comes next?`, `What comes next?`, 'r_referral.guide.title7', ``, ``, ``, ``],
    desc1: [
      <Div textGray700>
        Every stamp is worth{' '}
        <Div inlineBlock textPrimary>
          $1
        </Div>
        , in which you can cash in in addition to automatically being in the draw for as many raffle tickets as you’d
        like. (to increase your chances of winning a MacBook Pro.)
      </Div>,
      <Div textGray700>
        Every stamp is worth{' '}
        <Div inlineBlock textPrimary>
          $1
        </Div>
        , in which you can cash in in addition to automatically being in the draw for as many raffle tickets as you’d
        like. (to increase your chances of winning a MacBook Pro.)
      </Div>,
      'r_referral.guide.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      <Div textGray700>
        Click "Copy Link” or "Copy Message” to invite friends from{' '}
        <Div textPrimary inlineBlock>
          your college
        </Div>
        .
      </Div>,
      <Div textGray700>
        Click "Copy Link” or "Copy Message” to invite friends from{' '}
        <Div textPrimary inlineBlock>
          your college
        </Div>
        .
      </Div>,
      'r_referral.guide.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      <Div textGray700>
        Collect{' '}
        <Div inlineBlock textPrimary>
          1 stamp
        </Div>{' '}
        when your friend signs up through your link.
      </Div>,
      <Div textGray700>
        Collect{' '}
        <Div inlineBlock textPrimary>
          1 stamp
        </Div>{' '}
        when your friend signs up through your link.
      </Div>,
      'r_referral.guide.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      <Div textGray700>
        Collect{' '}
        <Div inlineBlock textPrimary>
          5 stamps
        </Div>{' '}
        when your friend shows up to a mock session, regardless of the result.
      </Div>,
      <Div textGray700>
        Collect{' '}
        <Div inlineBlock textPrimary>
          5 stamps
        </Div>{' '}
        when your friend shows up to a mock session, regardless of the result.
      </Div>,
      'r_referral.guide.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc5: [
      <Div textGray700>Collect raffle tickets to be placed in a drawing</Div>,
      <Div textGray700>Collect raffle tickets to be placed in a drawing</Div>,
      'r_referral.guide.desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc6: [
      <Div textGray700>Earn 1 raffle ticket for every full stamp card (= 20 stamps).</Div>,
      <Div textGray700>Earn 1 raffle ticket for every full stamp card (= 20 stamps).</Div>,
      'r_referral.guide.desc6',
      ``,
      ``,
      ``,
      ``,
    ],
    desc7: [
      <Div flex flexCol>
        <Div textGray700>Earn 1 raffle ticket when your friend finishes their first lesson as a new tutor.</Div>
        <Div textGray600 fontSize14>
          In this case, your stamp card stays the same - you just get an additional raffle ticket (with $20)!
        </Div>
      </Div>,
      <Div flex flexCol>
        <Div textGray700>Earn 1 raffle ticket when your friend finishes their first lesson as a new tutor.</Div>
        <Div textGray600 fontSize14>
          In this case, your stamp card stays the same - you just get an additional raffle ticket (with $20)!
        </Div>
      </Div>,
      'r_referral.guide.desc7',
      ``,
      ``,
      ``,
      ``,
    ],
    desc8: [
      <Div textGray700>No. Only new campus friends who haven’t been referred before are eligible.</Div>,
      <Div textGray700>No. Only new campus friends who haven’t been referred before are eligible.</Div>,
      'r_referral.guide.desc8',
      ``,
      ``,
      ``,
      ``,
    ],
    desc9: [
      <Div textGray700>
        Once your friend signs up for Ringle, they’ll get a $5 starbucks gift card after the completion of a mock
        session.
      </Div>,
      <Div textGray700>
        Once your friend signs up for Ringle, they’ll get a $5 starbucks gift card after the completion of a mock
        session.
      </Div>,
      'r_referral.guide.desc9',
      ``,
      ``,
      ``,
      ``,
    ],
    desc10: [
      <Div textGray700>They’ll also get a $100 cash bonus after the completion of 10 lessons.</Div>,
      <Div textGray700>They’ll also get a $100 cash bonus after the completion of 10 lessons.</Div>,
      'r_referral.guide.desc10',
      ``,
      ``,
      ``,
      ``,
    ],
    desc11: [
      <Div flex flexCol>
        <Div textGray700>Raffle draw in February</Div>
        <Div textGray600 fontSize14>
          The MacBook Pro raffle will be held on February 14th 2PM EST, 2022 via Zoom. <br />
          The link and instructions will be announced later!
        </Div>
      </Div>,
      <Div flex flexCol>
        <Div textGray700>Raffle draw in February</Div>
        <Div textGray600 fontSize14>
          The MacBook Pro raffle will be held on February 14th 2PM EST, 2022 via Zoom. <br />
          The link and instructions will be announced later!
        </Div>
      </Div>,
      'r_referral.guide.desc11',
      ``,
      ``,
      ``,
      ``,
    ],
    desc12: [
      <Div flex flexCol>
        <Div textGray700>When do I receive my event earnings?</Div>
        <Div textGray600 fontSize14>
          Your total earnings will accumulate during the event and will be added to your balance
          <br />
          on February 14th 9PM EST.
        </Div>
      </Div>,
      <Div flex flexCol>
        <Div textGray700>When do I receive my event earnings?</Div>
        <Div textGray600 fontSize14>
          Your total earnings will accumulate during the event and will be added to your balance
          <br />
          on February 14th 9PM EST.
        </Div>
      </Div>,
      'r_referral.guide.desc12',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  new_tutor: {
    msg: (tutor) => [
      <>Your friend {tutor.name} has successfully completed the first lesson!</>,
      <>Your friend {tutor.name} has successfully completed the first lesson!</>,
      'r_referral.new_tutor.msg',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_gateway = {
  dashboard: [`학부모 대시보드`, `Go to Parent Dashboard`, 't_gateway.dashboard', ``, ``, ``, ``],
  enter_parent: (name) => [
    `부모로 입장`,
    <>
      {name}
      <br /> (Parent View)
    </>,
    't_gateway.enter_parent',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_where_to_go: [
    `이동할 공간을 선택해주세요.`,
    `Who's using Ringle Teens?`,
    't_gateway.choose_where_to_go',
    ``,
    ``,
    ``,
    ``,
  ],
  study_place_of: (name) => [<>{name}의 학습공간</>, <>{name}</>, 't_gateway.study_place_of', ``, ``, ``, ``],
  passcode: [`패스코드`, `Passcode`, 't_gateway.passcode', ``, ``, ``, ``],
  enter_as_1: (name) => [<>{name}으로 입장</>, <>{name}</>, 't_gateway.enter_as_1', ``, ``, ``, ``],
  enter_as_2: (name) => [<>{name}로 입장</>, <>{name}</>, 't_gateway.enter_as_2', ``, ``, ``, ``],
  enter_passcode: [
    `학부모 계정으로 이동하려면 패스코드를 입력해주세요.`,
    `Please enter your passcode to go to your parent account.`,
    't_gateway.enter_passcode',
    ``,
    ``,
    ``,
    ``,
  ],
  passcode_title: [`패스코드`, `Passcode`, 't_gateway.passcode_title', ``, ``, ``, ``],
  passcode_check: [
    `올바른 패스코드를 입력해주세요.`,
    `Please enter the correct passcode.`,
    't_gateway.passcode_check',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_content = {
  mobile_web: {
    access_on_app: [
      `앱을 다운 받고, 링글의 컨텐츠와 수업을 경험해 보세요.`,
      `Access all of Ringle's content for free
on Ringle App`,
      's_content.mobile_web.access_on_app',
      `下载APP观看更多免费内容`,
      `下載APP觀看更多免費内容`,
      `アプリをインストールして、無料でRingleのコンテンツを体験してみましょう。`,
      `Miễn phí tất cả nội dung trên ứng dụng Ringle`,
    ],
    view_in_app: [
      `앱으로 보기`,
      `Open app`,
      's_content.mobile_web.view_in_app',
      `使用APP观看`,
      `使用APP觀看`,
      `アプリで見る`,
      `Mở ứng dụng`,
    ],
    no_thanks: [
      `괜찮습니다, 모바일 웹으로 볼게요.`,
      `No thanks, I want to stay on this page.`,
      's_content.mobile_web.no_thanks',
      `使用Mobile Web观看`,
      `使用Mobile Web觀看`,
      `いいえ、モバイルアプリで見ます。`,
      `Tôi muốn ở lại trang này, cảm ơn.`,
    ],
    continue_on_app: [
      `앱에서 이어보기`,
      `Continue on app`,
      's_content.mobile_web.continue_on_app',
      `在APP上观看`,
      `在APP上觀看`,
      `アプリで続けて見る`,
      `Tiếp tục trên ứng dụng`,
    ],
    finished_preview: [
      `1분 미리듣기가 완료되었습니다.`,
      `You finished the 1-minute preview.`,
      's_content.mobile_web.finished_preview',
      `1分钟试听已结束`,
      `1分鐘試聽以結束`,
      `1分のプレビューが完了しました。`,
      `Bạn hoàn thành 1 phút ôn lại.`,
    ],
    RSVP_on_app: [
      `앱에서 웨비나 신청하기`,
      `RSVP on App`,
      's_content.mobile_web.RSVP_on_app',
      `在APP上申请网络研讨会`,
      `在APP上申請網路研討會`,
      `アプリでウェビナーを申請する`,
      `Giữ chỗ trên ứng dụng`,
    ],
    sign_in_to_view_webinar_clips: [
      `웨비나의 다른 클립은 로그인 후에 시청 가능합니다.`,
      `Please log in to view other webinar clips.`,
      's_content.mobile_web.sign_in_to_view_webinar_clips',
      `登录后可观看更多网络研讨会`,
      `登入后觀看更多網路研討會`,
      `ウェビナーの他のクリップはログインをした後に申請可能です。`,
      `Vui lòng đăng nhập để xem các video webinar.`,
    ],
    please_note: [`안내`, `Please note`, 's_content.mobile_web.please_note', `说明`, `説明`, `ご案内`, `Lưu ý`],
    can_access_after_sign_in: [
      `로그인 후 링글 앱과 PC 웹에서만 제공되는 기능입니다.`,
      `This feature can be accessed after signing in on Ringle website or App.`,
      's_content.mobile_web.can_access_after_sign_in',
      `只在APP和电脑上提供的功能`,
      `只在APP和電腦上提供的功能`,
      `ログイン後にRingleアプリ/Webで提供される機能です。`,
      `Nội dung này có thể truy cập khi đăng nhập vào website hoặc ứng dụng.`,
    ],
    cancel: [`취소`, `Cancel`, 's_content.mobile_web.cancel', `取消`, `取消`, `取り消し`, `Hủy`],
    open_app: [
      `앱으로 이동`,
      `Open app`,
      's_content.mobile_web.open_app',
      `移动至APP`,
      `移動至APP`,
      `アプリに移動`,
      `Mở ứng dụng`,
    ],
  },
  web: {
    sign_in_to_access: [
      `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
      `Log in to access all of Ringle's content for free`,
      's_content.web.sign_in_to_access',
      `登录后查看更多免费内容`,
      `登入后查看更多免費内容`,
      `ログインをして無料でRingleのコンテンツを体験してみてください。`,
      `Đăng nhập để xem toàn bộ nội dung Ringle miễn phí`,
    ],
    sign_in_to_continue: [
      `로그인하고 이어보기`,
      `Log in to continue`,
      's_content.web.sign_in_to_continue',
      `登录后继续观看`,
      `登入后繼續觀看`,
      `ログインをして続けてみる`,
      `Đăng nhập để tiếp tục`,
    ],
    sign_in_to_RSVP: [
      `로그인 후 신청하기`,
      `Log in to RSVP`,
      's_content.web.sign_in_to_RSVP',
      `登录后申请`,
      `登入后申請`,
      `ログイン後に申請する`,
      `Đăng nhập để giữ chỗ`,
    ],
    sign_in_to_download_MP3: [
      <>
        MP3파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the MP3.`,
      's_content.web.sign_in_to_download_MP3',
      `MP3下载需登录后进行。是否登录后继续下载？`,
      `MP3下載需登入后進行。是否登入后繼續下載？`,
      `MP3ファイルのダウンロード機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải file MP3.`,
    ],
    sign_in_to_download_PDF: [
      <>
        PDF파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the PDF.`,
      's_content.web.sign_in_to_download_PDF',
      `PDF下载需登录后进行。是否登录后继续下载？`,
      `PDF下載需登入后進行。是否登入后繼續下載？`,
      `PDFファイルのダウンロード機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải PDF.`,
    ],
    save: [`찜하기`, `Save`, 's_content.web.save', `收藏`, `收藏`, `お気に入りにする`, `Lưu`],
    sign_in_to_save: [
      <>
        찜하기 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to save.`,
      's_content.web.sign_in_to_save',
      `登录后可使用收藏功能。是否登录？`,
      `登入后可使用收藏功能。是否登入？`,
      `お気に入り機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để lưu lại.`,
    ],
    download_materials: [
      `자료 받기`,
      `Download materials`,
      's_content.web.download_materials',
      `获取资料`,
      `獲取資料`,
      `材料を貰う`,
      `Tải tài liệu`,
    ],
    sign_in_to_download_materials: [
      `자료 받기 기능은 로그인 후에 제공되는 기능입니다. 로그인하고 계속 진행하시겠습니까?`,
      `Please sign in to download webinar materials.`,
      's_content.web.sign_in_to_download_materials',
      `登录后可使用获取资料功能。是否登录？`,
      `登入后可使用獲取資料功能。是否登入？`,
      `材料を貰う機能はログイン後に提供される機能です。ログインしますか？`,
      `Đăng nhập để tải tài liệu webinar.`,
    ],
    sign_in: [`로그인`, `Log in`, 's_content.web.sign_in', `登录`, `登入`, `ログイン`, `Đăng nhập`],
    sign_up: [`회원가입 하기`, `Sign up`, 's_content.web.sign_up', `会员注册`, `會員註冊`, `会員登録`, `Đăng ký`],
  },
  do_you_like: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like',
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
  ],
  related_material_read: [
    `이 Daily Brief를 공유하거나 관련 교재를 읽어보세요.`,
    `Share it with your friends or view related materials.`,
    's_content.related_material_read',
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
    `Share it with your friends or view related materials.`,
  ],
  sharing: [`공유하기`, `Share`, 's_content.sharing', `Share`, `Share`, `Share`, `Share`],
  related_material: [
    `관련 교재 읽기`,
    `View Insights`,
    's_content.related_material',
    `View Insights`,
    `View Insights`,
    `View Insights`,
    `View Insights`,
  ],
  do_you_like_daily_brief: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like_daily_brief',
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
    `Liked this Daily Brief?`,
  ],
  kakakotalk: [
    `카카오톡`,
    `Kakao Talk`,
    's_content.kakakotalk',
    `Kakao Talk`,
    `Kakao Talk`,
    `Kakao Talk`,
    `Kakao Talk`,
  ],
  facebook: [`페이스북`, `Facebook`, 's_content.facebook', `Facebook`, `Facebook`, `Facebook`, `Facebook`],
  url_copy: [`URL복사`, `URL Copy`, 's_content.url_copy', `URL Copy`, `URL Copy`, `URL Copy`, `URL Copy`],
  link_copied: [
    <>
      링크가 복사되었습니다. <br /> 원하시는 곳에 복사하여 붙여넣어주세요.
    </>,
    `Link is copied to your clipboard.`,
    's_content.link_copied',
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
    `Link is copied to your clipboard.`,
  ],
}
export const r_feedback = {
  loading: {
    title: [``, `Ringle Recommends:`, 'r_feedback.loading.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Complete at least the Assessment & Overall Feedback immediately while the lesson is fresh in your mind!`,
      'r_feedback.loading.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tooltip: {
    desc: [
      ``,
      <>
        Use this menu to access the lesson’s Ringle Doc,
        <br /> the Student’s Request and our Feedback Report <br />
        Best-Practice Guide!{' '}
      </>,
      'r_feedback.tooltip.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  paraphrase: {
    after: [``, `After`, 'r_feedback.paraphrase.after', ``, ``, ``, ``],
    before: [``, `Before`, 'r_feedback.paraphrase.before', ``, ``, ``, ``],
    explanation: [``, `Explanation`, 'r_feedback.paraphrase.explanation', ``, ``, ``, ``],
  },
  toast: {
    k_12: [
      ``,
      `Thanks for marking this student as a Junior (K-12)! This will help other tutors learn more about the student.`,
      'r_feedback.toast.k_12',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  paraphrasePopup: {
    desc: [
      ``,
      `Choose one paraphrased sentence and explanation from Ringle Docs`,
      'r_feedback.paraphrasePopup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [``, `Importing Ringle Docs edits`, 'r_feedback.paraphrasePopup.title', ``, ``, ``, ``],
  },
  submitPopup: {
    title: [``, `Submit this Feedback Report`, 'r_feedback.submitPopup.title', ``, ``, ``, ``],
    review: [``, `Review`, 'r_feedback.submitPopup.review', ``, ``, ``, ``],
    submit: [``, `Submit`, 'r_feedback.submitPopup.submit', ``, ``, ``, ``],
    desc: [
      ``,
      `Before submitting, make sure to proofread your English!`,
      'r_feedback.submitPopup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  information: {
    title1: [``, `Material`, 'r_feedback.information.title1', ``, ``, ``, ``],
    title2: [``, `Student`, 'r_feedback.information.title2', ``, ``, ``, ``],
    tooltip1: [
      ``,
      `Check if your student is a Junior(K-12). This will help other tutors learn more about this student.`,
      'r_feedback.information.tooltip1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [``, `If this student is a junior (K-12), check this.`, 'r_feedback.information.desc2', ``, ``, ``, ``],
  },
  step3: {
    section1: [``, `Fluency`, 'r_feedback.step3.section1', ``, ``, ``, ``],
    section2: [``, `Grammar`, 'r_feedback.step3.section2', ``, ``, ``, ``],
    section3: [``, `Vocabulary`, 'r_feedback.step3.section3', ``, ``, ``, ``],
    stepNumber: [``, `Step 3/3`, 'r_feedback.step3.stepNumber', ``, ``, ``, ``],
    title: [``, `In-depth Feedback`, 'r_feedback.step3.title', ``, ``, ``, ``],
    count: [``, `Feedback`, 'r_feedback.step3.count', ``, ``, ``, ``],
    desc1: [``, `Main Areas of Focus`, 'r_feedback.step3.desc1', ``, ``, ``, ``],
    desc2: [``, `Fluency, Grammar, Vocabulary`, 'r_feedback.step3.desc2', ``, ``, ``, ``],
    count_rule: [``, `(min 3 ~ max 5)`, 'r_feedback.step3.count_rule', ``, ``, ``, ``],
    notab_placeholder1: [``, `Click`, 'r_feedback.step3.notab_placeholder1', ``, ``, ``, ``],
    notab_placeholder2: [
      ``,
      `next to a paraphrasing on the Ringle Doc to initiate feedback.`,
      'r_feedback.step3.notab_placeholder2',
      ``,
      ``,
      ``,
      ``,
    ],
    section_title: [``, `Choose Section`, 'r_feedback.step3.section_title', ``, ``, ``, ``],
  },
  step1: {
    stepNumber: [``, `Step 1/3`, 'r_feedback.step1.stepNumber', ``, ``, ``, ``],
    title: [``, `Assessment Score`, 'r_feedback.step1.title', ``, ``, ``, ``],
  },
  step2: {
    stepNumber: [``, `Step 2/3`, 'r_feedback.step2.stepNumber', ``, ``, ``, ``],
    title: [``, `Overall Feedback`, 'r_feedback.step2.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Please write your overall comments on the student's ability, a brief explanation of your
scores, and any advice that you might have to further the student's linguistic development.`,
      'r_feedback.step2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder: [``, `Type here...`, 'r_feedback.step2.placeholder', ``, ``, ``, ``],
  },
  button: {
    next: [``, `Next`, 'r_feedback.button.next', ``, ``, ``, ``],
    back: [``, `Back`, 'r_feedback.button.back', ``, ``, ``, ``],
    preview: [``, `Preview`, 'r_feedback.button.preview', ``, ``, ``, ``],
    submit: [``, `Submit`, 'r_feedback.button.submit', ``, ``, ``, ``],
  },
  pronunciation: {
    title: [``, `Pronunciation Tips`, 'r_feedback.pronunciation.title', ``, ``, ``, ``],
    title_desc: [``, `(Optional)`, 'r_feedback.pronunciation.title_desc', ``, ``, ``, ``],
    desc: [
      ``,
      `Provide advice on corrected mispronounced words or incorrect intonation.`,
      'r_feedback.pronunciation.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    button_title: [``, `Add Feedback`, 'r_feedback.pronunciation.button_title', ``, ``, ``, ``],
  },
  ringledocInformPopup: {
    title: [``, `This button is only available in Step 3.`, 'r_feedback.ringledocInformPopup.title', ``, ``, ``, ``],
    desc: [
      ``,
      `Importing Ringle Doc sentences is only available in Step 3.`,
      'r_feedback.ringledocInformPopup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const r_lesson_style = {
  student_info: {
    tooltip: [
      ``,
      `Below is the total number of Ringle lessons your student has had thus far. The number of lessons do not correlate with the student's English proficiency.
<br/><br/>
First Lesson : this lesson will be their first lesson<br/>
New Comer: 1-5 lessons<br/>
Ringler: 6-20 lessons<br/>
Active Ringler: more than 20 lessons`,
      'r_lesson_style.student_info.tooltip',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_login = {
  placeholder1: [
    `이메일 주소를 입력해주세요 (ID)`,
    `Enter your email address (ID)`,
    't_login.placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder2: [`비밀번호를 입력해주세요`, `Enter your password`, 't_login.placeholder2', ``, ``, ``, ``],
  alert_msg1: [
    `올바른 이메일을 적어주세요`,
    `Please enter a valid Email address`,
    't_login.alert_msg1',
    ``,
    ``,
    ``,
    ``,
  ],
  alert_msg2: [`존재하지 않는 비밀번호입니다`, `Did not match stored password`, 't_login.alert_msg2', ``, ``, ``, ``],
  btn1: [`로그인하기`, `Log in`, 't_login.btn1', ``, ``, ``, ``],
  btn2: [`가입하고 체험 수업 신청하기`, `Sign up & Try a Free Lesson`, 't_login.btn2', ``, ``, ``, ``],
  forgot_pw: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_login.forgot_pw', ``, ``, ``, ``],
  social_login: [`소셜 계정으로 로그인`, `Log in with`, 't_login.social_login', ``, ``, ``, ``],
  sign_up_message: [
    `링글 틴즈 학부모 계정이 없으신가요?`,
    `Don't have a Ringle Teens parent account?`,
    't_login.sign_up_message',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_transfer_credit = {
  title: [
    `틴즈로 수업권 이관`,
    `Transfer Lesson Credits to Ringle Teens`,
    's_transfer_credit.title',
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
    `Transfer Lesson Credits to Ringle Teens`,
  ],
  terms: [`유의사항`, `Terms`, 's_transfer_credit.terms', `Terms`, `Terms`, `Terms`, `Terms`],
  select_package: [
    `이관할 패키지를 선택해주세요.`,
    `Select a package to transfer`,
    's_transfer_credit.select_package',
    `Select a package to transfer`,
    `Select a package to transfer`,
    `Select a package to transfer`,
    `Select a package to transfer`,
  ],
  select_number: [
    `이관할 수업권 개수를 선택해주세요.`,
    `Select number of credits`,
    's_transfer_credit.select_number',
    `Select number of credits`,
    `Select number of credits`,
    `Select number of credits`,
    `Select number of credits`,
  ],
  check_credits: [
    `이관 수업권 확인`,
    `Check the transferred credits`,
    's_transfer_credit.check_credits',
    `Check the transferred credits`,
    `Check the transferred credits`,
    `Check the transferred credits`,
    `Check the transferred credits`,
  ],
  plus: [`플러스`, `Plus`, 's_transfer_credit.plus', `Plus`, `Plus`, `Plus`, `Plus`],
  teens: [`틴즈`, `Teens`, 's_transfer_credit.teens', ``, ``, ``, ``],
  view_terms: [
    `틴즈 이용 약관 및 개인정보 처리방침 보기`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    's_transfer_credit.view_terms',
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
  ],
  terms_title: [`수업권 변경 유의 사항`, `Terms`, 's_transfer_credit.terms_title', `Terms`, `Terms`, `Terms`, `Terms`],
  terms_agree: [
    `유의 사항, 틴즈 이용약관 및 개인정보 처리방침을 숙지 및 동의하였고, 이관을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    's_transfer_credit.terms_agree',
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
  ],
  transfer: [`이관 진행`, `Transfer`, 's_transfer_credit.transfer', `Transfer`, `Transfer`, `Transfer`, `Transfer`],
  terms_detail: [
    <>
      수업권 이관은 수업 패키지 별 1회만 가능합니다. 이미 이관된 수업권은 원상복구할 수 없으니 신중히 선택하시기
      바랍니다!
      <br />
      수업권 이관 후, 적립되는 수업 포인트는 기존 구매한 수업권의 회당 금액의 3%로 적립됩니다. (수업권 이관 이후,
      제공되는 총 포인트는 동일함) 이관 후 링글 틴즈 내에서 사용하신 수업권에 대한 포인트는 링글에서 사용 가능합니다.
      <br />
      정규수업권 4개 소진 시, 1회 당일수업권이 제공되는 수업권은 4개 이관할 때마다 1회 당일 수업권이 링글 계정으로
      지급됩니다.
    </>,
    <>
      IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and used within Ringle.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle account.{' '}
    </>,
    's_transfer_credit.terms_detail',
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
    <>
      Customers may only receive the benefits of our limited promotion if they transfer at least four Ringle Plus lesson
      credits IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and can only be used within Ringle Teens.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle Plus account.{' '}
    </>,
  ],
  terms_1: [
    `링글 틴즈 수업 운영시간: 7pm~다음날 1pm KST (3:00am ~ 9:00pm PST, 6:00am ~ 12:00am EST)`,
    `Please note that Ringle Teens operating hours are: 12pm~11pm EST (7pm~1pm KST, 3:00am~9:00pm PST)`,
    's_transfer_credit.terms_1',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_2: [
    `기존 링글 고객이 이관을 함으로써 링글 틴즈 계정이 자동으로 생성되며, 해당 링글 틴즈 계정으로 신청하신 수량의 수업권이 이관됩니다.`,
    `A Ringle Teens account is automatically generated once you transfer credits. The transferred lesson credits can be found in your Ringle Teens account.`,
    's_transfer_credit.terms_2',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_3: [
    `수업권 이관은 보유하신 패키지 당 1번씩 가능하며, 2022년 12월 31일까지 링글 정규 수업권에 한해 수업권 1매를 틴즈 수업권 1매로 이관할 수 있습니다.`,
    `Until December 31st, 2022, customers are eligible to transfer Ringle lesson credits for an equivalent amount of Ringle Teens credits. You are only able to conduct one transfer per credit package purchased.`,
    's_transfer_credit.terms_3',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_4: [
    `이관된 수업권의 잔여 수강 기간은 링글 틴즈에서 동일하게 적용됩니다.`,
    `Transferring lesson credits will not affect the expiry date.`,
    's_transfer_credit.terms_4',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_5: [
    `수업권 10개 이상 이관 시 40분 수업권을 추가로 지급드리며, 이관 기간 동안 수업권 1개 이상 이관하신 모든 분들 중 추첨을 통해 20가정에게 링글 스타트업 탐방 기회를 제공합니다. 혜택 당첨자에게는 이메일로 개별 안내드릴 예정입니다.`,
    `Customers who may receive the benefits of our promotion will be contacted via email.`,
    's_transfer_credit.terms_5',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_6: [
    `링글에서 장기 수업권 혜택으로 "정규 수업 4회 소진 시마다, 당일 수업권 1회 발급 혜택"을 받고 계신 경우, 장기 패키지 내 4회 수업권을 이관할 때마다 1회의 당일 수업권이 링글 계정으로 지급됩니다.`,
    `For the long-term package that provides a last-minute lesson credit(Make-up Credit) for every four regular lesson credits used, customers receive the following: For every four regular lesson credits transferred to Ringle Teens from the package, receive a last-minute lesson credit on their Ringle account.`,
    's_transfer_credit.terms_6',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_7: [
    `이관한 수업권은 환불이 불가하며, 이관한 수업권은 링글 내 원상 복구 불가합니다.`,
    `No refund is available for transferred credits and transferred credits can not be transferred back to Ringle Plus.`,
    's_transfer_credit.terms_7',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_8: [
    `링글 틴즈로 이관된 수업권에 대한 "예상 적립 포인트"는 링글에서 구매하신 수업권 실결제 금액의 3%입니다. 해당 금액이 매 수업 종료 7일 후 "링글 포인트"로 적립되며, 이관 수업권으로 받으신 포인트는 링글에서 사용 가능합니다.`,
    `Points for each lesson credit are valued at 3% of the total amount paid for the credit package. Lesson points will be calculated 7 days after the end of each lesson. Points accumulated through Ringle Teens can be used on Ringle.`,
    's_transfer_credit.terms_8',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_9: [
    `추가 문의 사항은 카카오톡 채널에서 1:1 상담 혹은 teens@ringleplus.com을 통해 문의하시기 바랍니다.`,
    `For further inquiries, book a call with our advisor here or contact us at teens@ringleplus.com.`,
    's_transfer_credit.terms_9',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_landing_home = {
  title1: {
    default: [
      <>
        당신이 꿈꾸던
        <br />
        영어실력과 커리어를
        <br />
        현실로
      </>,
      <>
        Improve your English,
        <br />
        Unlock your potential
        <br />
      </>,
      's_landing_home.title1.default',
      <>
        您即将可实现
        <br />
        梦寐以求的
        <br />
        英语实力及生涯
      </>,
      <>
        您夢寐以求的
        <br />
        英文實力及職涯
        <br />
        即將實現
      </>,
      <>
        目標とする
        <br />
        キャリアと
        <br />
        理想の英語力を
        <br />
        実現する
      </>,
      <>
        Cải thiện <br />
        tiếng Anh <br />
        Khai mở <br />
        tiềm năng
        <br />
      </>,
    ],
    desc: [
      <>
        아이비리그 출신 원어민 튜터와 함께하는
        <br />
        1:1 화상영어 수업
      </>,
      <>
        1:1 online English lessons
        <br />
        with top university students & graduates
      </>,
      's_landing_home.title1.desc',
      <>
        与常春藤名校母语任课教师一起进行
        <br />
        1:1视频英语课程
      </>,
      <>
        與常春藤名校的母語任課教師一起進行
        <br />
        1:1視訊英語課程
      </>,
      <>
        世界トップ大学出身のチューターが
        <br />
        1on1であなたの英語学習をサポート
      </>,
      <>
        Học tiếng Anh 1-1 cùng sinh viên các
        <br />
        trường Đại học hàng đầu
      </>,
    ],
  },
  btn1: [
    `링글 시작하기`,
    `Get Started`,
    's_landing_home.btn1',
    `开始Ringle`,
    `開始Ringle`,
    `Ringleをスタート`,
    `Bắt đầu`,
  ],
  title2: {
    mobile: [
      <>
        이미 많은 고객분들이
        <br />
        링글과 함께 꿈을 현실로
        <br />
        만들고 있습니다.
      </>,
      <>
        Ringle has allowed
        <br />
        countless dreams
        <br />
        to come true
      </>,
      's_landing_home.title2.mobile',
      <>
        已有很多学生
        <br />
        正在与Ringle一起
        <br />
        实现梦想。
      </>,
      <>
        已經有許多學生
        <br />
        正與Ringle一起
        <br />
        實現夢想。
      </>,
      <>
        既に多くの生徒達がRingleと一緒に
        <br />
        夢を現実のものとしています。
      </>,
      <>
        Ringle mở ra
        <br />
        những cơ hội mới
        <br />
        để bạn đạt được giấc mơ
      </>,
    ],
    desktop: [
      <>이미 많은 고객분들이 링글과 함께 꿈을 현실로 만들고 있습니다.</>,
      <>Ringle has allowed countless dreams to come true</>,
      's_landing_home.title2.desktop',
      <>已有很多学生正在与Ringle一起实现梦想。</>,
      <>已經有許多學生正與Ringle一起實現夢想。</>,
      <>既に多くの生徒達がRingleを利用して夢を現実のものとしています。</>,
      <>Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ</>,
    ],
    caption: [
      `*링글 제휴 기업`,
      `*Companies partnering with Ringle`,
      's_landing_home.title2.caption',
      `*Ringle合作公司`,
      `*Ringle合作公司`,
      `*Ringleと提携する企業`,
      `*Công ty đối tác`,
    ],
    pc: [
      `이미 많은 고객 분들이 링글과 함께 꿈을 현실로 만들고 있습니다`,
      `Ringle has allowed countless dreams to come true`,
      's_landing_home.title2.pc',
      <>已有很多学生正在与Ringle一起实现梦想。</>,
      <>已經有許多學生正與Ringle一起實現夢想。</>,
      <>既に多くのお客様がRingleと一緒に夢を現実にしています。</>,
      <>Ringle mở ra những cơ hội mới để bạn đạt được giấc mơ</>,
    ],
  },
  title3: {
    default: [
      <>
        영어로 생각을 나눌 수 있는
        <br />
        명문대 원어민 튜터
      </>,
      <>
        In-depth discussions
        <br />
        with exceptional tutors
      </>,
      's_landing_home.title3.default',
      <>
        可帮您提高英语思维的
        <br />
        名校母语任课教师
      </>,
      <>
        助您使用英語思考的
        <br />
        名校母語任課教師
      </>,
      <>
        英語力向上をサポートする
        <br />
        一流大学出身のチューター
      </>,
      <>
        Những buổi thảo luận sâu
        <br />
        cùng các gia sư hàng đầu
      </>,
    ],
    desc: [
      `영어가 모국어인 사람은 많지만 정돈된 영어를 쓰는 사람은 따로 있죠. 내 생각을 영어로 효과적으로 표현하게 도와주는 1,000명 이상의 아이비리그 출신 튜터들과 지적인 대화를 나누세요.`,
      `Speaking eloquently is more an art than science. Learn from 1000+ intellectual tutors from top universities to express your thoughts effectively.`,
      's_landing_home.title3.desc',
      `虽然使用英语母语者很多，但使用有条的英语者却不多。请与1000名以上的常春藤名校任课教师一起进行可帮您有效的使用英语的对话吧！`,
      `雖然英語母語人士多的是，但部分人士使用的是有條理的英語。與1,000名以上的常春藤名校任課教師一起展開知識性的對話，他們將助您更有效果地使用英語表達您的想法。`,
      `英語を母国語とする人は数多くいれど、正しい英語を使用することが出来る人は少数です。自らの考えを英語で効果的に表現する実力を、1,000名以上の一流大学出身のチューターとの知的対話を通じて、磨き上げましょう。`,
      `Hùng biện là một môn nghệ thuật. Cải thiện khả năng của bạn với sự giúp đỡ từ 1000+ gia sư xuất sắc ở các trường đại học hàng đầu.`,
    ],
    more_btn: [
      `튜터 더 알아보기`,
      `Meet our tutors`,
      's_landing_home.title3.more_btn',
      `查看更多任课教师`,
      `查看更多任課教師`,
      `Ringleのチューターをもっと見てみる`,
      `Gặp gỡ các gia sư`,
    ],
  },
  review1: {
    default: [
      `글로벌 기업에서 일할 때의 영어는 누군가를 설득해야 하는 경우가 많아서 한 단계 높은 비즈니스 영어가 필요하죠. 링글 튜터들의 피드백을 받으면서 영어로 일하는 자신감을 가지게 됐습니다.`,
      `In the workplace, you need strong communication skills to be persuasive. Since joining Ringle, I’ve been a better communicator and negotiator at work.`,
      's_landing_home.review1.default',
      `在国企工作时，经常需要用英语去说服他人，因此需使用高端的商务英语。与Ringle的任课教师们学习后，现在我才感觉到我终于可以尽我所能了。`,
      `在國際企業工作時，很常需要使用英語說服他人，因此需要高一層次的商務英語。與Ringle的任課教師們學習後，現在我才感覺到我終於能利用英語盡我所能了。`,
      `グローバル企業で仕事をする際には、英語で誰かを説得する必要のある場面も多く、レベルの高いビジネス英語が要求されます。Ringleのチューターとの練習を通じて獲得した英会話力によって、本当の意味でグローバルに活躍するビジネスマンになることができました。`,
      `Trong công việc, bạn cần có kỹ năng giao tiếp tốt để thuyết phục. Kể từ khi học cùng Ringle, tôi giao tiếp và đàm phán tốt hơn trong công việc.`,
    ],
    writer: [
      `서주영 / 파트너 엔지니어, Google HQ`,
      `Juyung Seo / Partner Engineer, Google HQ`,
      's_landing_home.review1.writer',
      `Juyung Seo／合作工程师，Google HQ`,
      `Juyung Seo／Google HQ的夥伴工程師`,
      `ソ・ジュヨン／パートナーエンジニア 、Google本社`,
      `Juyung Seo / Kỹ sư tại Google`,
    ],
    tag: {
      default: [
        `해외 유학`,
        `Studying Abroad`,
        's_landing_home.review1.tag.default',
        `海外留学`,
        `海外留學`,
        `海外留学`,
        `Du học`,
      ],
    },
    title: {
      default: [
        <>
          우지혜님의
          <br />
          미국 석사에서 커리어 점프까지
        </>,
        <>
          Jihye's journey
          <br />
          to getting an MBA and making a career change
        </>,
        's_landing_home.review1.title.default',
        <>
          Jihye
          <br />
          从读美国硕士到成功转职
        </>,
        <>
          Jihye
          <br />
          從唸美國碩士到成功轉職
        </>,
        <>
          ウ・ジヘさん
          <br />
          アメリカでのMBA取得とキャリアチェンジ
        </>,
        <>Hành trình học MBA và thay đổi nghề nghiệp</>,
      ],
    },
    desc1: {
      default: [
        `프로덕트 디자이너, ZipRecruiter`,
        `Product Designer, Zip Recruiter`,
        's_landing_home.review1.desc1.default',
        `产品设计师，ZipRecruiter`,
        `ZipRecruiter的產品設計師`,
        `プロダクトデザイナー、ZipRecruiter`,
        `Product Designer, nhà tuyển dụng Zip`,
      ],
    },
    desc2: {
      default: [
        `Pratt Institute 석사`,
        `Pratt Institute, Information Design Master's degree`,
        's_landing_home.review1.desc2.default',
        `Pratt Institute硕士`,
        `Pratt Institute碩士`,
        `Pratt Institute,情報デザイン修士課程卒業`,
        `Học viện Pratt Institute, Thạc sĩ Thiết kế thông tin`,
      ],
    },
  },
  title4: {
    default: [
      <>
        눈에 보이는 교정,
        <br />
        기억에 남는 맞춤 피드백
      </>,
      <>
        Real-time corrections and
        <br />
        Personalized feedback
      </>,
      's_landing_home.title4.default',
      <>
        看得到的纠正
        <br />
        深刻留下记忆的针对性反馈
      </>,
      <>
        看得到的糾正、
        <br />
        留下印象的客製化意見回饋
      </>,
      <>
        ビジュアル化される英語力
        <br />
        英語力を磨く
        <br />
        詳細なフィードバック
      </>,
      <>
        Sửa lỗi trực tiếp và
        <br />
        nhận xét được
        <br />
        cá nhân hoá
      </>,
    ],
    desc: [
      `링글의 수업은 말하고 사라지는 영어가 아닌 교정되는 내 영어를 눈으로 보고, 기록으로 남깁니다. 수업 후에는 영어 사용에 대한 AI 분석과 튜터의 피드백 리포트를 통해 성장하는 실력까지 확인하세요.`,
      `What you learn stays with you on Ringle. Real-time corrections are visualized and saved in your class notes. You can also monitor your growth through AI analysis and tutor reports.`,
      's_landing_home.title4.desc',
      `Ringle的课程不是只说不记的英语，而是可以确认并留下记录的英语。课程结束后为提升英语能力可通过AI分析和任课教师的意见反馈来确认您的英语能力。`,
      `Ringle的課程不會一說即逝，而是可以親眼確認自己的英語得到糾正並留下紀錄。請於課後透過針對英語使用的AI分析及任課教師的意見回饋報告書確認您成長的實力。`,
      `Ringleのレッスンは従来の英会話レッスンとは異なり、ただ単に話して終わりません。チューターにより添削された表現は、全て文章化されます。
レッスンの後は、チューターからのアドバイスをレポートの形式で受領出来るだけでなく、話した内容をAIが分析して定量的にフィードバックされます。`,
      `Tất cả những buổi học đều được lưu lại. Chỉnh sửa lỗi từ gia sư lưu trong ghi chú buổi học. Bạn có thể theo dõi sự tiến bộ qua phân tích AI và nhận xét của gia sư.`,
    ],
    more_btn: [
      `수업 시스템 더 알아보기`,
      `Browse our learning system`,
      's_landing_home.title4.more_btn',
      `查看更多课程系统`,
      `查看更多課程系統`,
      `レッスンシステムを詳しく知る`,
      `Trải nghiệm hệ thống học tập`,
    ],
  },
  review2: {
    default: [
      `최근에 영어 발표 연습을 하면서 교정 받은 표현은 절대 잊지 않고 계속 쓸 것 같아요. 다른 플랫폼에서는 수업이 끝나고 내용이 사라지는 느낌이 들었다면 링글은 내가 말하는 것을 눈으로 보면서 고쳐주고 저장까지 되니까 확실히 머릿속에 남는 느낌이 들어요.`,
      `Unlike other platforms, Ringle's system makes it easier to retain and implement what I learned. I can see what I said exactly and how it was corrected by the tutor. It's intuitive!`,
      's_landing_home.review2.default',
      `最近练习英语发表时，我发现我真的不忘记纠正过的英语，而且还可正确的使用英语。之前进行电话英语时，下课后总感觉都忘了自己说了什么，但在Ringle能亲自确认我说的英语，纠正我的错误并储存，感觉英语真的实实在在的留在脑海里。`,
      `最近練習英語發表時，感覺到我絕對不會忘記獲得糾正的用法並能持續使用。電話英語在下課後，感覺上課內容就消失了，但在Ringle能親眼確認我所說的話、獲得糾正並儲存，感覺實實在在地留在腦海中。`,
      `英語でのプレゼンテーションを練習をする際、Ringleのチューターから指摘を受けた表現は直ぐに自分のものとすることができます。通常の英会話レッスンでは、指摘を受けたとしても、そのまま記憶に留まらずに流れてしまうことも多くあります。Ringleのレッスンでは、話した内容と指摘が記録化されるため、確実に英語力の強化に繋がります。`,
      `Không giống những nền tảng khác, hệ thống Ringle giúp bạn lưu trữ và áp dụng những gì mình đã học dễ dàng hơn. Tôi có thể xem lại những cuộc trò chuyện cũ và cách gia sư sửa lỗi theo một cách trực quan nhất!`,
    ],
    writer: [
      `김도엽 / 북미총괄 컨트롤러, 삼성물산`,
      `Doyeop Kim / General Manager, Samsung C&T`,
      's_landing_home.review2.writer',
      `Doyeop Kim／三星物产北美总经理`,
      `Doyeop Kim／三星物產的北美總經理`,
      `キム・ドヨン／北米総括コントローラー、サムスン物産`,
      `Doyeop Kim / Giám đốc tại Americas, Samsung C&T`,
    ],
    tag: {
      default: [
        `직장인 MBA`,
        `MBA for Professionals`,
        's_landing_home.review2.tag.default',
        `上班族MBA`,
        `上班族MBA`,
        `ビジネスエリートのためのMBA`,
        `MBA cho sự nghiệp`,
      ],
    },
    title: {
      default: [
        <>
          이상윤님의
          <br />
          성공적인 MBA 에세이 준비
        </>,
        <>
          How Sangyun
          <br />
          wrote a successful MBA application essay
        </>,
        's_landing_home.review2.title.default',
        <>
          Sangyun
          <br />
          成功准备MBA申论
        </>,
        <>
          Sangyun
          <br />
          成功準備MBA申論
        </>,
        <>
          イ・サンユンさん
          <br />
          質の高いEssayの作成・成功裡に終えたMBA準備
        </>,
        <>
          Câu chuyện thành công của
          <br />
          Sangyun và bài luận MBA
        </>,
      ],
    },
    desc1: {
      default: [
        `공동 창업자, NextChapter`,
        `Co-founder, NextChapter`,
        's_landing_home.review2.desc1.default',
        `共同创办人，NextChapter`,
        `NextChapter的共同創辦人`,
        `共同創業者、NextChapter`,
        `Đồng sáng lập NextChapter`,
      ],
    },
    desc2: {
      default: [
        `HBS MBA Class of 2020`,
        `HBS MBA Class of 2020`,
        's_landing_home.review2.desc2.default',
        `HBS MBA Class of 2020`,
        `HBS MBA Class of 2020`,
        `Harvard Business School MBA Class of 2020`,
        `Trường kinh doanh Harvard Khoá 2020`,
      ],
    },
  },
  title5: {
    default: [
      <>
        지금 가장 주목받는 주제의
        <br />
        교재로 익히는 실전 영어
      </>,
      <>
        Lesson Materials
        <br />
        on Trending Topics
      </>,
      's_landing_home.title5.default',
      <>
        利用最受瞩目的主题教材
        <br />
        熟练实战英语
      </>,
      <>
        利用目前最夯的主題教材
        <br />
        熟練的實戰英語
      </>,
      <>
        実践的な英語を
        <br />
        最新トレンドに
        <br />
        沿って学ぶ
      </>,
      <>
        Tài liệu học
        <br />
        với chủ đề thời sự
      </>,
    ],
    desc: [
      `시사∙경제, 비즈니스, 자기 계발까지. 아이비리그 출신 원어민과 MBA 졸업생 등으로 구성된 콘텐츠 팀이 직접 만드는 1,000개 이상의 교재와 영상을 활용하여 내가 관심 있는 주제의 영어 표현을 익히세요.`,
      `Gain access to 1,000+ articles and videos on politics, economics, business, and self-improvement, produced by Ivy-League and MBA graduates.`,
      's_landing_home.title5.desc',
      `从时事·经济、商务到自我开发。请利用常春藤名校母语任课教师及MBA毕业生等所组成的内容团队亲自编写的1000多个教材及视频，来学习您感兴趣的主题,熟悉英语用法吧！`,
      `從時事·經濟、商務到自我開發，善用常春藤名校的母語人士及MBA畢業生等所組成的內容團隊親自打造的1,000多個教材及影片，利用您感興趣的主題熟悉英語用法吧！`,
      `一流大学の出身者やMBAホルダーによって作成された、最新の最新の時事トピックや経済、自己啓発等、多岐に渡る1,000個以上の教材とビデオを活用し、自分自身が関心を持つテーマに沿った英語表現を学びましょう！`,
      `Tiếp cận 1,000+ bài báo và video về chính trị, kinh tế, kinh doanh và phát triển bản thân được xây dựng bởi các sinh viên Ivy League và MBA.`,
    ],
    more_btn: [
      `교재 더 알아보기`,
      `See more Materials`,
      's_landing_home.title5.more_btn',
      `查看更多教材`,
      `查看更多教材`,
      `教材をもっと見てみる`,
      `Xem thêm Tài liệu`,
    ],
  },
  review3: {
    default: [
      `영어로 대화를 잘 하려면 말하고 싶은 주제에 대해 영어로 익숙해지는 게 더 중요하더라고요. 한국어도 할 말이 없으면 대화가 잘 안되잖아요. 링글 수업 후에는 외국 팀원들과 날씨 이야기 말고도 다양한 주제로 대화하기가 훨씬 수월해졌어요.`,
      `Having a good conversation depends on your familiarity with the topic in any language, and English is no exception. Since I started using Ringle, I've found it easier to talk to my English-speaking colleagues.`,
      's_landing_home.review3.default',
      `我发现如想用英语很好的进行沟通，重要的是需先用英语来熟悉想要说的主题。就像我们用韩语对话时，如没有想要聊的主题，就聊不下去！ 自从我上完Ringle课程后，除了天气话题外，和外国同事也可讨论各种主题了。`,
      `我發現如果想要擅長英語會話，更重要的是利用自己想說的主題熟悉英語。韓語也是，如果無話可說，就聊不下去！上完Ringle課程後，除了天氣話題之外，我更能輕鬆和外國組員們討論各種主題了。`,
      `英語でスムーズに会話をするためには、自分が話をしたいテーマに関連する英語表現に慣れることが、何より大切でした。母国語でも話す内容がないと会話は長く続きません。Ringleのレッスンを通じ、海外チームとも天気の話に加え、多様なテーマで会話をすることが容易になりました。`,
      `Cuộc hội thoại tốt hay không tuỳ thuộc vào việc bạn quen thuộc thế nào với chủ đề. Giao tiếp bằng tiếng Anh cũng vậy. Từ khi học Ringle, tôi cảm thấy tự tin hơn khi trò chuyện với các đồng nghiệp bản ngữ.`,
    ],
    writer: [
      `정수지 / 프로그램 리드, SAP`,
      `Suji Jeong / Program Lead, SAP`,
      's_landing_home.review3.writer',
      `Suji Jeong／项目负责人，SAP`,
      `Suji Jeong／SAP的計畫主管`,
      `ジョン・スジ／プログラムリード、SAP`,
      `Suji Jeong / Trường nhóm dự án, SAP`,
    ],
    tag: {
      default: [
        `글로벌 취업`,
        `Working Abroad`,
        's_landing_home.review3.tag.default',
        `国际就业`,
        `國際就業`,
        `海外での就職`,
        `Làm việc ở nước ngoài`,
      ],
    },
    title: {
      default: [
        <>
          최예원님의
          <br />
          글로벌 커리어로의 전환
        </>,
        <>
          Yewon's story
          <br />
          of restarting her career abroad
        </>,
        's_landing_home.review3.title.default',
        <>
          Yewon
          <br />
          展开她的国际职业生涯
        </>,
        <>
          Yewon
          <br />
          展開她的國際職涯
        </>,
        <>
          チェ・エウォンさんの
          <br />
          海外で働く真の意味でのグローバル人材成長
        </>,
        <>
          Câu chuyện của Yewon
          <br />
          bắt đầu công việc mới ở nước ngoài
        </>,
      ],
    },
    desc1: {
      default: [
        `클리니컬 디렉터, Merck`,
        `Clinical Director, Merck`,
        's_landing_home.review3.desc1.default',
        `临床主任，Merck`,
        `Merck的臨床主任`,
        `クリニカルディレクター、Merck`,
        `Clinical Director, Merck`,
      ],
    },
    desc2: {
      default: [
        `서울대 의대, 임상병리 박사`,
        `MD, PhD in Clinical Pharmacology`,
        's_landing_home.review3.desc2.default',
        `首尔大医学院临床病例博士`,
        `首爾大醫學院臨床病理博士`,
        `ソウル大学医学部、臨床病理博士`,
        `Thạc sĩ, Tiến sĩ Dược lâm sàng`,
      ],
    },
  },
  title6: {
    default: [
      <>
        더 이상 막연한 꿈이 아닌
        <br />
        현실로
      </>,
      <>
        No longer a dream,
        <br />
        but a reality
      </>,
      's_landing_home.title6.default',
      <>
        渺茫的梦想，
        <br />
        将成为现实
      </>,
      <>
        不再是渺茫的夢想，
        <br />
        而將化為現實
      </>,
      <>
        もはや夢で終わらせない
        <br />
        夢を現実に
      </>,
      <>
        Không phải một giấc mơ, <br />
        đó là hiện thực
      </>,
    ],
    desc: [
      <>
        해외 유학, 글로벌 취업 준비, 영어 실력 향상 등.
        <br />늘 마음속에만 담아둔 목표, 이제는 링글과 함께 현실로 만드세요.
      </>,
      <>
        Studying or working abroad
        <br />
        The dreams you've always aspired to realize them with Ringle
      </>,
      's_landing_home.title6.desc',
      <>
        海外留学、国际就业准备、提升英语实力等，
        <br />
        现在就请与Ringle一起实现很久以来深藏心中的梦想吧！
      </>,
      <>
        海外留學、國際就業準備、提升英語實力等，
        <br />
        現在就與Ringle一起實現長久以來深埋心中的夢想吧！
      </>,
      `海外留学やグローバル企業への就職準備、英語力の向上など、あなたが漠然と抱いていた目標をRingleを通じて現実のものとしましょう。`,
      <>
        Học hoặc làm việc ở nước ngoài <br />
        Giấc mơ bạn hằng theo đuổi, Ringle sẽ giúp bạn
      </>,
    ],
    more_btn: [
      `고객 스토리 더 알아보기`,
      `Check Out Testimonials`,
      's_landing_home.title6.more_btn',
      `查看更多学生故事`,
      `查看更多學生故事`,
      `Ringle利用者の体験談をもっと見てみる`,
      `Xem thêm đánh giá khách hàng`,
    ],
  },
  title7: {
    default: {
      mobile: [
        <>
          가입만으로도
          <br />
          주어지는 성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.mobile',
        <>
          只需注册，
          <br />
          就能获得成长的机会
        </>,
        <>
          光是註冊，
          <br />
          就能獲得成長的機會
        </>,
        <>
          Ringleへ登録し、
          <br />
          新たな英語力向上の旅を始めましょう
        </>,
        <>
          Đăng ký và bắt đầu hành trình của riêng bạn
          <br />
        </>,
      ],
      pc: [
        <>
          가입만으로도 주어지는
          <br />
          성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.pc',
        <>
          只需注册，
          <br />
          就能获得成长的机会
        </>,
        <>
          光是註冊，
          <br />
          就能獲得成長的機會
        </>,
        `無料で登録
自ら獲得する成長の機会`,
        <>
          Đăng ký và bắt đầu hành trình của riêng bạn
          <br />
        </>,
      ],
    },
    desc: {
      default: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          링글 앱 다운 받고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Download the Ringle app to view free content
        </>,
        's_landing_home.title7.desc.default',
        <>
          一直迟疑并拖延的英语学习！ <br />
          现在就下载Ringle APP利用免费内容开始学习英语吧！
        </>,
        <>
          一直遲疑並拖延的英語學習！
          <br />
          現在就下載Ringle APP利用免費內容開始吧！
        </>,
        <>
          これまでためらっていた英語学習
          <br />
          Ringleのアプリをダウンロードして、無料コンテンツから始めてみましょう。
        </>,
        <>
          Đừng trì hoãn nữa <br />
          Tải ứng dụng Ringle để xem nội dung miễn phí
        </>,
      ],
      pc: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          회원 가입하고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Sign up Ringle to view free content
        </>,
        's_landing_home.title7.desc.pc',
        <>
          一直迟疑并拖延的英语学习！ <br />
          请现在注册会员后，利用免费内容开始学习英语吧！
        </>,
        <>
          一直遲疑並拖延的英語學習！
          <br />
          現在就註冊會員並利用免費內容開始吧！
        </>,
        <>
          ためらい、伸ばしていた英語の勉強
          <br />
          会員登録をして無料コンテンツで始めてみましょう。
        </>,
        <>
          Đừng trì hoãn nữa <br />
          Tải ứng dụng Ringle để xem nội dung miễn phí
        </>,
      ],
    },
    brief: {
      mobile: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits <br />
          with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.mobile',
        <>
          在指定的时间接收消息通知，
          <br />
          每天培养学习好习惯。
          <br />
          <b>最新教材</b>
        </>,
        <>
          在指定的時間接收通知，
          <br />
          每天培養學習好習慣。
          <br />
          <b>最新教材</b>
        </>,
        <>
          指定時間にお知らせ
          <br />
          毎日の勉強
          <br />
          <b>最新の教材</b>
        </>,
        <>
          Nhận thông báo vào thời gian mong muốn <br />
          tạo thói quen học tập mỗi ngày..
          <br />
          <b>Tài liệu mới nhất</b>
        </>,
      ],
      pc: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.pc',
        <>
          在指定的时间接收消息通知，
          <br />
          每天培养学习好习惯。
          <br />
          <b>最新教材</b>
        </>,
        <>
          在指定的時間接收通知，
          <br />
          每天培養學習好習慣。
          <br />
          <b>最新教材</b>
        </>,
        <>
          指定時間にお知らせ毎日の勉強
          <br />
          <b>最新の教材</b>
        </>,
        <>
          Nhận thông báo vào thời gian mong muốn <br />
          tạo thói quen học tập mỗi ngày..
          <br />
          <b>Tài liệu mới nhất</b>
        </>,
      ],
    },
    webinars: {
      mobile: [
        <>
          인기 튜터와 <br />
          다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors
          <br />
          and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.mobile',
        <>
          与人气任课教师
          <br />
          及其他学生一起进行的课程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          與人氣任課教師
          <br />
          及其他學生一起進行的課程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          人気のRingleチューターと
          <br />
          他の生徒とともに作り上げるグループレッスン
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          Tham gia cùng những gia sư xuất sắc <br />
          và các học viên Ringle
          <br />
          <b>trên Ringle LIVE</b>
        </>,
      ],
      pc: [
        <>
          인기 튜터와 다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.pc',
        <>
          与人气任课教师
          <br />
          及其他学生一起进行的课程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          與人氣任課教師
          <br />
          及其他學生一起進行的課程。
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          人気のRingleチューターと
          <br />
          他の生徒とともに作り上げるグループレッスン
          <br />
          <b>Ringle LIVE</b>
        </>,
        <>
          Tham gia cùng những gia sư xuất sắc <br />
          và các học viên Ringle
          <br />
          <b>trên Ringle LIVE</b>
        </>,
      ],
    },
    events: {
      mobile: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>
            각종 이벤트와
            <br />
            수업 관련 알림까지
          </b>
        </>,
        <>
          Successfully form a new habit through
          <br />
          <b>
            our event updates and
            <br />
            class notifications
          </b>
        </>,
        's_landing_home.title7.events.mobile',
        <>
          有助于英语学习习惯的
          <br />
          <b>
            各种活动及
            <br />
            课程相关通知
          </b>
        </>,
        <>
          有助於英語學習習慣的
          <br />
          <b>
            各種活動及
            <br />
            課程相關通知
          </b>
        </>,
        <>
          英語学習の習慣を助けてくれる
          <b>
            各種のイベントと
            <br />
            レッスンに関連したお知らせまで
          </b>
        </>,
        <>
          Tạo thói quen thành công
          <br />
          <b>qua những thông báo sự kiện và lớp học</b>
        </>,
      ],
      pc: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>각종 챌린지와 이벤트까지</b>
        </>,
        <>
          Successfully form a new habit
          <br />
          <b>through events and challenges</b>
        </>,
        's_landing_home.title7.events.pc',
        <>
          有助于英语学习习惯的
          <br />
          <b>各种挑战及活动</b>
        </>,
        <>
          有助於英語學習習慣的
          <br />
          <b>各種挑戰及活動</b>
        </>,
        <>
          英語学習を習慣化する
          <br />
          <b>イベントや各種チャレンジ</b>
        </>,
        <>
          Tạo thói quen thành công
          <br />
          <b>qua những sự kiện và thử thách</b>
        </>,
      ],
    },
  },
  btn2: [
    `링글 앱 다운받기`,
    `Download the Ringle App`,
    's_landing_home.btn2',
    `下载Ringle APP`,
    `下載Ringle APP`,
    `Ringleのアプリをダウンロードする`,
    `Tải ngay ứng dụng Ringle`,
  ],
  title8: {
    mobile: [
      <>
        누구나 영어의 장벽을 넘어,
        <br />
        더 큰 기회에 도전하는 세상을
        <br />
        만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.mobile',
      <>
        Ringle将陪伴所有人
        <br />
        跨越英语障碍，直到打造
        <br />
        挑战更大机会的舞台。
      </>,
      <>
        Ringle會陪伴所有人
        <br />
        跨越英語的障礙，直到打造出
        <br />
        可以挑戰更大機會的世界。
      </>,
      <>
        誰もが英語のハードルを超えて、
        <br />
        より大きな機会に挑戦する世界まで
        <br />
        Ringleがご一緒します。
      </>,
      <>
        Một thế giới không rào cản tiếng Anh
        <br />
        Một thế giới để bạn mơ lớn
        <br />
        Và Ringle sẽ giúp bạn đạt được
      </>,
    ],
    subtitle1: [
      `지금까지 진행된 수업`,
      <>
        Total number
        <br />
        of lessons
      </>,
      's_landing_home.title8.subtitle1',
      `至今进行的课程`,
      `至今進行的課程`,
      `今までに完了したレッスン数`,
      `Tổng số buổi học`,
    ],
    count1: [`건`, ``, 's_landing_home.title8.count1', `件`, `件`, `回`, ``],
    subtitle2: [
      `수업 및 튜터 평균 만족도`,
      <>
        Average rating
        <br />
        of lessons and tutors
      </>,
      's_landing_home.title8.subtitle2',
      `对课程及教师的平均满意度`,
      `課程及任課教師平均滿意度`,
      `レッスンの平均満足度`,
      `Đánh giá trung bình`,
    ],
    count2: [`점`, ``, 's_landing_home.title8.count2', `分`, `分`, `点`, ``],
    count2_1: [`/5점`, `/5`, 's_landing_home.title8.count2_1', `／5分`, `／5分`, `／5点`, `/5`],
    subtitle3: [
      `최근 1개월간 수업한 튜터`,
      <>
        Active tutors
        <br />
        in the past month
      </>,
      's_landing_home.title8.subtitle3',
      `对课程及教师的平均满意度`,
      `最近1個月上過課的任課教師`,
      `アクティブチューター数`,
      `Gia sư dạy học trong tháng`,
    ],
    count3: [`명`, ``, 's_landing_home.title8.count3', `名`, `名`, `名`, ``],
    subtitle4: [
      `지금까지 등록된 교재`,
      <>
        Total number
        <br />
        of materials
      </>,
      's_landing_home.title8.subtitle4',
      `至今登录的教材`,
      `至今登錄的教材`,
      `これまでに登録された教材数`,
      `Tổng số tài liệu`,
    ],
    count4: [`개`, ``, 's_landing_home.title8.count4', `个`, `個`, `個`, ``],
    caption: [
      `*2022.11.07 내부 데이터 기준`,
      `*As of Nov 07, 2022`,
      's_landing_home.title8.caption',
      `*以2022.11.07内部数据为准`,
      `*以2022.11.07內部數據為準`,
      `*2022.11.07 Ringleの内部データ基準`,
      `Từ ngày 07/11/2022`,
    ],
    pc: [
      <>
        누구나 영어의 장벽을 넘어, 더 큰 기회에 도전하는
        <br />
        세상을 만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.pc',
      <>
        Ringle将陪伴所有人跨越英语障碍，
        <br />
        直到打造挑战更大机会的舞台。
      </>,
      <>
        Ringle會陪伴所有人跨越英語的障礙，
        <br />
        直到打造出可以挑戰更大機會的世界。
      </>,
      <>
        誰もが支障なく英語を駆使し
        <br />
        自由に夢を描くことができる世界の実現を
        <br />
        Ringleは目指しています。
      </>,
      <>
        Một thế giới không rào cản tiếng Anh
        <br />
        Một thế giới để bạn mơ lớn
        <br />
        Và Ringle sẽ giúp bạn đạt được
      </>,
    ],
  },
  btn3: [
    `링글 수업권 확인하기`,
    `Check Ringle Credits`,
    's_landing_home.btn3',
    `确认Ringle课程券`,
    `確認Ringle課程券`,
    `Ringleのレッスンチケットを確認する`,
    `Kiểm tra thẻ học Ringle`,
  ],
  btn4: [
    `링글 시작하기`,
    `Get Started`,
    's_landing_home.btn4',
    `开始Ringle`,
    `開始Ringle`,
    `Ringleを始める`,
    `Bắt đầu`,
  ],
  review4: {
    tag: {
      default: [
        `영어 실력 향상`,
        `Improve Your English`,
        's_landing_home.review4.tag.default',
        `提升英语实力`,
        `提升英語實力`,
        `根本的な英語力の向上を実感`,
        `Cải thiện tiếng Anh`,
      ],
    },
    title: {
      default: [
        <>
          김정은님의
          <br />
          해외 이직을 자극하는 영어 공부
        </>,
        <>
          Studying with Ringle
          <br />
          inspired Jeongeun to move abroad
        </>,
        's_landing_home.review4.title.default',
        <>
          Jeongeun
          <br />
          刺激准备转职海外的英语学习
        </>,
        <>
          Jeongeun
          <br />
          刺激轉職海外的英語學習
        </>,
        <>
          キム・ジョンさんの
          <br />
          Ringleで刺激を受け、海外転勤を決意
        </>,
        <>
          Học cùng Ringle truyền <br />
          cảm hứng để Jeoheun ra nước ngoài
        </>,
      ],
    },
    desc1: {
      default: [
        `주임 연구원, 에릭슨 - LG`,
        `Research Engineer, Ericsson-LG`,
        's_landing_home.review4.desc1.default',
        `主任研究员，Ericsson-LG`,
        `Ericsson-LG的研究工程師`,
        `主任研究員、エリクソン- LG`,
        `Kỹ sư nghiên cứu, Ericsson-LG`,
      ],
    },
  },
}
export const s_landing_promo = {
  hero_subtitle: [
    <>
      더 이상 영어 공부를
      <br />
      미룰 수 없는 당신을 위해
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    's_landing_promo.hero_subtitle',
    `为了不能再推迟英语学习的你`,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
    <>
      For you to get a jump-start
      <br />
      on studying English
    </>,
  ],
  subtitle2: [
    <>
      원어민의 영어에 한단계
      <br />더 가까워지고 싶다면?
    </>,
    `Want to speak more naturally?`,
    's_landing_promo.subtitle2',
    <>
      如果想朝着母语者英语水平
      <br />
      更近一步, 怎么办?
    </>,
    `Want to speak more naturally?`,
    `Want to speak more naturally?`,
    `Want to speak more naturally?`,
  ],
  title2: [
    <>
      당신만을 위한 영어수업
      <br />
      1:1로 만나세요.
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    's_landing_promo.title2',
    <>
      为你量身定制的英语课程
      <br />
      以1对1的方式开始吧。
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
    <>
      Get 1:1 Tutoring
      <br />
      Just for You
    </>,
  ],
  desc2: [
    <>
      단순 회화를 넘어 상황에 맞는
      <br />
      자연스러운 영어로 레벨 업하려면
      <br />내 영어에만 집중한 실시간 교정이 필요합니다.
    </>,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    's_landing_promo.desc2',
    <>
      如果不只学习单纯的会话，
      <br />
      还想提高水平，学习自然的、符合语境的英语，
      <br />
      那就需要只针对自己英语的实时纠正反馈。
    </>,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
    `Customized, real-time feedback is what you need to upgrade your English to the next level.`,
  ],
  subtitle4: [
    <>
      22년 여름, 6천명이 참여한
      <br />
      실리콘밸리 챌린지에 이은 또 다른 도전
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    's_landing_promo.subtitle4',
    ``,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
    <>
      Last Year's Big Hit
      <br />
      Ringle Challenge Is Back
    </>,
  ],
  desc4: [
    <>
      4개월 동안 24회 이상 수강한 5명에게
      <br />
      주어지는 보스턴&뉴욕 투어 기회!
      <br />
      글로벌 기업 현직자의 생생한 후기와 현지 분위기를
      <br />
      경험하고 더 넓은 세계를 향한 성장을 시작하세요.
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    's_landing_promo.desc4',
    ``,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
    <>
      Aren't you curious to know what it's like
      <br />
      to work and study in Boston & NYC?
      <br />
      Become one of the lucky five
      <br />
      to visit Boston & NYC with the Ringle Team!
    </>,
  ],
  subtitle6: (n) => [
    <>
      지금까지 <span className="text-[#FF6712]">{n}회</span> 공유했어요.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    's_landing_promo.subtitle6',
    ``,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
    <>
      You have shared <br />
      your study goals <span className="text-[#FF6712]">{n}</span> times.
    </>,
  ],
  btn1: [
    `나에게 맞는 수업권 더 보기`,
    `Find the perfect plan for you`,
    's_landing_promo.btn1',
    `了解更多适合自己的课程券`,
    `Find the perfect plan for you`,
    `Find the perfect plan for you`,
    `Find the perfect plan for you`,
  ],
  btn3: [
    `수업권 구매 후 신청할 수 있어요.`,
    `You can apply after purchasing credits.`,
    's_landing_promo.btn3',
    ``,
    `You can apply after purchasing credits.`,
    `You can apply after purchasing credits.`,
    `You can apply after purchasing credits.`,
  ],
  btn4: [`챌린지 더 알아보기`, `Learn more`, 's_landing_promo.btn4', ``, `Learn more`, `Learn more`, `Learn more`],
  notice: {
    title: [
      `프로모션 안내 및 유의 사항`,
      `Terms and conditions`,
      's_landing_promo.notice.title',
      `优惠活动介绍和注意事项`,
      `Terms and conditions`,
      `Terms and conditions`,
      `Terms and conditions`,
    ],
    title1: [
      `[공통 안내]`,
      `[All events]`,
      's_landing_promo.notice.title1',
      `【通用指南】`,
      `[All events]`,
      `[All events]`,
      `[All events]`,
    ],
    desc1: [
      <>
        1. 모든 일정은 KST 기준입니다.
        <br />
        2. 신년 프로모션 패키지는 기간 내 중복 구매가 가능합니다.
        <br />
        3. 기타 문의는 링글 카카오 채널@ringle로 문의 바랍니다.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      's_landing_promo.notice.desc1',
      <>
        1.所有日程以韩国时间为准。
        <br />
        2.在活动期间，新年优惠活动套餐可重複购买。
        <br />
        3.如有疑问，请通过Kakao频道@ringle进行咨询。
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
      <>
        1. Said timelines are in Korea Standard Time (KST).
        <br />
        2. Package can be purchased multiple times during the promotion period.
        <br />
        3. For any other inquiries, please contact the Ringle team via Ringle's KakaoTalk channel @ringle.
      </>,
    ],
    title2: [
      `[링글 스터디클럽]`,
      `[Ringle Study Club]`,
      's_landing_promo.notice.title2',
      ``,
      `[Ringle Study Club]`,
      `[Ringle Study Club]`,
      `[Ringle Study Club]`,
    ],
    desc2: [
      <>
        1. 이벤트 기간: 22.12.23 ~ 23.03.02
        <br />
        2. 대상자 발표: 각 팀별 모집 종료 후 개별 연락
        <br />
        3. 참여 방법: 프로모션 기간 내 정규수업권 구매 시 참여 자격 부여 / 자격 부여 받은 인원 중 신청 대상자에 한해
        선착순 250명 한정 / 아이디 1개당 1회만 참여 가능합니다.
        <br />
        4. 유의사항: 신청 완료 후 수업권 환불 시, 스터디클럽 신청이 취소될 수 있습니다.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      's_landing_promo.notice.desc2',
      ``,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
      <>
        1. Study Club period: December 23, 2022 - March 2, 2023
        <br />
        2. Study club members will be notified individually after applications for each team closes
        <br />
        3. Eligibility: You become eligible for the study club if you purchase a regular plan during the promotion
        period / 250 participants will be placed in each team on a first-come, first-served basis.
        <br />
        4. Please note that if you cancel the plan that made you eligible, you will automatically be disqualified.
      </>,
    ],
    title3: [
      `[영어공부 목표 공유하기 이벤트]`,
      `[New Year's Resolution Event]`,
      's_landing_promo.notice.title3',
      ``,
      `[New Year's Resolution Event]`,
      `[New Year's Resolution Event]`,
      `[New Year's Resolution Event]`,
    ],
    desc3: [
      <>
        1. 이벤트 기간: 22.12.28 ~ 23.02.02
        <br />
        2. 혜택 대상자 안내: 23.02.10(금)에 스타벅스 기프티콘 일괄 지급 / 링글 수업권 4회 당첨자는 개별 연락
        <br />
        3. 참여 방법: 프로모션 기간 중 카톡으로 목표 공유하기 버튼으로 카카오 채팅방에 목표를 공유 하면 성공 / 공유한
        횟수에 따라 혜택 차등 지급
        <br />
        4. 유의사항: 동일한 채팅방에 2회 이상 공유시 1회만 횟수로 인정됩니다. / 나와의 채팅방에 공유시 인정되지
        않습니다. / 부정한 방법으로 이벤트 참여 이력이 확인되는 경우 선물이 지급되지 않습니다.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      's_landing_promo.notice.desc3',
      ``,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
    ],
  },
  toast_msg1: [
    `신청 완료 되었습니다. 이메일을 확인해 주세요!`,
    `Thanks for applying! Please check your email.`,
    's_landing_promo.toast_msg1',
    ``,
    `Thanks for applying! Please check your email.`,
    `Thanks for applying! Please check your email.`,
    `Thanks for applying! Please check your email.`,
  ],
  toast_msg2: [
    `이미 신청하셨습니다. 이메일을 확인해 주세요!`,
    `Already applied. Please check your email!`,
    's_landing_promo.toast_msg2',
    ``,
    `Already applied. Please check your email!`,
    `Already applied. Please check your email!`,
    `Already applied. Please check your email!`,
  ],
  btn5: [
    `스터디클럽 신청하기`,
    `Apply to Join`,
    's_landing_promo.btn5',
    ``,
    `Apply to Join`,
    `Apply to Join`,
    `Apply to Join`,
  ],
  btn6: [
    `얼리버드 진행 중`,
    `Early Bird Offer`,
    's_landing_promo.btn6',
    `早鸟特价优惠正在进行中`,
    `Early Bird Offer`,
    `Early Bird Offer`,
    `Early Bird Offer`,
  ],
  btn7: [
    `지금 가격 알아보기`,
    `See all price offers`,
    's_landing_promo.btn7',
    `早鸟优惠进行中`,
    `See all price offers`,
    `See all price offers`,
    `See all price offers`,
  ],
  title5_pc: [
    <>
      영어에만 집중하세요.
      <br />
      특별한 여행은 저희가 책임질게요.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    's_landing_promo.title5_pc',
    ``,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
  ],
  title6_pc: [
    `아직 망설여지나요? 링글팀과 직접 상담하세요.`,
    ``,
    's_landing_promo.title6_pc',
    `还在犹豫吗？和Ringle团队聊聊`,
    ``,
    ``,
    ``,
  ],
  promo_banner: [
    `1년에 단 2번, 최대 49% 할인 받고 지금 링글 시작해 보세요!`,
    `Only twice a year, all lesson credits on sale!`,
    's_landing_promo.promo_banner',
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
    `Only twice a year, all lesson credits on sale!`,
  ],
  materials: {
    item1: {
      title: [
        `은행이 된 스타벅스`,
        `Starbucks is Also a Bank`,
        's_landing_promo.materials.item1.title',
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
        `Starbucks is Also a Bank`,
      ],
      category: [
        `#Retail`,
        `#Retail`,
        's_landing_promo.materials.item1.category',
        `#Retail`,
        `#Retail`,
        `#Retail`,
        `#Retail`,
      ],
      subtitle: [
        `기프트 카드의 경제`,
        `The economy of gift cards`,
        's_landing_promo.materials.item1.subtitle',
        `The economy of gift cards`,
        `The economy of gift cards`,
        `The economy of gift cards`,
        `The economy of gift cards`,
      ],
    },
    item2: {
      title: [
        `픽사 애니메이션 스튜디오`,
        `Pixar Animation Studios`,
        's_landing_promo.materials.item2.title',
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
        `Pixar Animation Studios`,
      ],
      category: [
        `#Business`,
        `#Business`,
        's_landing_promo.materials.item2.category',
        `#Business`,
        `#Business`,
        `#Business`,
        `#Business`,
      ],
      subtitle: [
        `창조적 영혼이 살아있는 회사`,
        `Creativity in action`,
        's_landing_promo.materials.item2.subtitle',
        `Creativity in action`,
        `Creativity in action`,
        `Creativity in action`,
        `Creativity in action`,
      ],
    },
    item3: {
      title: [
        `일터에서 인생의 반을 보내는
당신에게`,
        `Half Your Life at Work`,
        's_landing_promo.materials.item3.title',
        `Half Your Life at Work`,
        `Half Your Life at Work`,
        `Half Your Life at Work`,
        `Half Your Life at Work`,
      ],
      category: [
        `#Small Talk`,
        `#Small Talk`,
        's_landing_promo.materials.item3.category',
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
      ],
      subtitle: [
        `유일한 삶에 대한 지침서`,
        `A guide to being mortal`,
        's_landing_promo.materials.item3.subtitle',
        `A guide to being mortal`,
        `A guide to being mortal`,
        `A guide to being mortal`,
        `A guide to being mortal`,
      ],
    },
    item4: {
      title: [
        `가볍고 소소한 잡담`,
        `Small Talk`,
        's_landing_promo.materials.item4.title',
        `Small Talk`,
        `Small Talk`,
        `Small Talk`,
        `Small Talk`,
      ],
      category: [
        `#Small Talk`,
        `#Small Talk`,
        's_landing_promo.materials.item4.category',
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
        `#Small Talk`,
      ],
      subtitle: [
        `깊은 우정을 예고하는 전주곡`,
        `A prelude to deep friendships`,
        's_landing_promo.materials.item4.subtitle',
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
        `A prelude to deep friendships`,
      ],
    },
    item5: {
      title: [
        `비즈니스 이메일 쓰기`,
        `Writing Business Emails`,
        's_landing_promo.materials.item5.title',
        `Writing Business Emails`,
        `Writing Business Emails`,
        `Writing Business Emails`,
        `Writing Business Emails`,
      ],
      category: [
        `#Business`,
        `#Business`,
        's_landing_promo.materials.item5.category',
        `#Business`,
        `#Business`,
        `#Business`,
        `#Business`,
      ],
      subtitle: [
        `최대한 간결하게`,
        `Err on the side of simplicity`,
        's_landing_promo.materials.item5.subtitle',
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
        `Err on the side of simplicity`,
      ],
    },
  },
  page_title: [
    `2022 링글 하반기 프로모션`,
    `2022 Ringle Bi-annual Promo`,
    's_landing_promo.page_title',
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
    `2022 Ringle Bi-annual Promo`,
  ],
  page_description: [
    `연 최대할인 부터 영어 학습법까지`,
    `Biggest promotion of the year`,
    's_landing_promo.page_description',
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
    `Biggest promotion of the year`,
  ],
  bonus1_info2: {
    desc2: [
      ``,
      `The Zoom link will be sent to you before your orientation date.`,
      's_landing_promo.bonus1_info2.desc2',
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
      `The Zoom link will be sent to you before your orientation date.`,
    ],
  },
  hero_promo: [
    `23년 영어 공부, 지금 준비하세요.`,
    `For you to get a jump-start on studying English`,
    's_landing_promo.hero_promo',
    `Ringle新年优惠活动`,
    `For you to get a jump-start on studying English`,
    `For you to get a jump-start on studying English`,
    `For you to get a jump-start on studying English`,
  ],
  promo_date1: [
    `22.12.23 (금) - 23.02.02 (목)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date1',
    `2022.12.23(周五)~2023.02.02(周四)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
  ],
  promo_date2: [
    `23.01.06 (금) - 23.02.02 (목)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date2',
    `2023.01.06(五) - 2023.02.02(四）`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
  ],
  label_earlybird: [
    `얼리버드 혜택`,
    `Early bird`,
    's_landing_promo.label_earlybird',
    `早鸟特价活动`,
    `Early bird`,
    `Early bird`,
    `Early bird`,
  ],
  label_regular: [
    `신년 프로모션`,
    `New Year Promotion`,
    's_landing_promo.label_regular',
    `正式活动`,
    `New Year Promotion`,
    `New Year Promotion`,
    `New Year Promotion`,
  ],
  label_firstcome: [
    `선착순 마감`,
    `First come, first served`,
    's_landing_promo.label_firstcome',
    ``,
    `First come, first served`,
    `First come, first served`,
    `First come, first served`,
  ],
  label_recruiting: [
    `250명 선착순`,
    `250 spots only`,
    's_landing_promo.label_recruiting',
    ``,
    `250 spots only`,
    `250 spots only`,
    `250 spots only`,
  ],
  label_comingsoon: [
    `모집 전`,
    `Coming Soon`,
    's_landing_promo.label_comingsoon',
    ``,
    `Coming Soon`,
    `Coming Soon`,
    `Coming Soon`,
  ],
  label_closed: [`모집 마감`, `Closed`, 's_landing_promo.label_closed', ``, `Closed`, `Closed`, `Closed`],
  title1_mobile: [
    <>
      신년, 영어 정복을 위해
      <br />
      링글이 준비했습니다.
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_mobile',
    ``,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
  ],
  title1_pc: [
    `신년, 영어 정복을 위해 링글이 준비했습니다.`,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_pc',
    ``,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
  ],
  subtitle1: [
    `원어민과의 대화가 두렵다면?`,
    `Do you have English anxiety?`,
    's_landing_promo.subtitle1',
    <>
      如果害怕和英语母语者对话, <br />
      怎么办？
    </>,
    `Do you have English anxiety?`,
    `Do you have English anxiety?`,
    `Do you have English anxiety?`,
  ],
  desc1: [
    <>
      외국인과 편하게 대화하는 것이 모두의 목표죠.
      <br />
      영어 표현만 외우기보다는 원어민과 <br />
      실전을 연습해야 진짜 실력이 됩니다.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    's_landing_promo.desc1',
    <>
      能和外国人轻松对话是每个人的目标。
      <br />
      比起只背英语表达，
      <br />
      和英语母语者进行实战练习才能培养真正的实力。
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
  ],
  vedio_desc: [
    `소리를 켜고 실제 링글 수업을 확인해보세요!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    's_landing_promo.vedio_desc',
    `实际感受一下真正的Ringle课程吧！`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
  ],
  qna1: {
    title_pc: [
      `튜터의 말을 못 알아들어서 수업이 어려울까봐 걱정돼요.`,
      `I'm worried I won't follow what the tutor says.`,
      's_landing_promo.qna1.title_pc',
      `因为害怕听不懂任课教师的话，担心课程太难。`,
      `I'm worried I won't follow what the tutor says.`,
      `I'm worried I won't follow what the tutor says.`,
      `I'm worried I won't follow what the tutor says.`,
    ],
    title_mobile: [
      <>
        튜터의 말을 못 알아들어서
        <br />
        수업이 어려울까봐 걱정돼요.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      's_landing_promo.qna1.title_mobile',
      <>
        因为害怕听不懂任课教师的话，
        <br />
        担心课程太难。
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
      <>
        I'm worried I won't follow
        <br />
        what the tutor says.
      </>,
    ],
    review: [
      `해외 입국 심사도 못 알아듣고 긴장할 정도여서 링글 결제 전에 고민이 많았어요. 하지만 막상 수업을 들어보니 섣부른 걱정이었어요. 튜터들이 제 수준에 맞춰 수업을 진행하니 불편함이 없었고, 오히려 원어민과 대화를 할 수 있다는 자신감을 가지게 해준 것만으로 링글의 수업료가 아깝지 않습니다.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      's_landing_promo.qna1.review',
      `连海外入境审查都听不懂，甚至都会紧张，所以在Ringle付费之前很犹豫。但是真正听完课后发现是不必要的担心。任课教师根据我的水平进行授课，没有不便之处，反而让我有了和英语母语者一起对话的自信心，所以Ringle的学费一点都不觉得可惜。`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
      `I used to be so nervous about speaking in English with foreigners. But after trying out a lesson, I realized I didn't have to worry at all! Ringle's tutors are incredibly accommodating, and adjust the class level to my fluency. Ringle made me confident about speaking with native speakers, which alone makes the fees I paid worthwhile.`,
    ],
    reviewer_desc: [
      `링글 56회 수강, 디자이너`,
      `Took 56 lessons with Ringle`,
      's_landing_promo.qna1.reviewer_desc',
      `在Ringle听课56次 设计师`,
      `Took 56 lessons with Ringle`,
      `Took 56 lessons with Ringle`,
      `Took 56 lessons with Ringle`,
    ],
    reviewer: [
      `김미정 님`,
      `Kim Mi-jeong, Designer`,
      's_landing_promo.qna1.reviewer',
      `金美静`,
      `Kim Mi-jeong, Designer`,
      `Kim Mi-jeong, Designer`,
      `Kim Mi-jeong, Designer`,
    ],
  },
  qna2: {
    title_pc: [
      `왜 초중급자에게 원어민 화상영어가 좋은가요?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      's_landing_promo.qna2.title_pc',
      `为什么对于初中级学习者来说，母语视频英语课程好呢？`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
      `Why is 1:1 lessons helpful for beginners and intermediates?`,
    ],
    title_mobile: [
      <>
        왜 초중급자에게
        <br />
        원어민 화상영어가 좋은가요?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      's_landing_promo.qna2.title_mobile',
      <>
        为什么对于初中级学习者来说，
        <br />
        母语视频英语课程好呢？
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
      <>
        Why is 1:1 lessons helpful
        <br />
        for beginners and intermediates?
      </>,
    ],
    review: [
      `실시간 교정 덕분에 내가 지금 무엇을 잘하고 못하는지 판단이 돼요. 특히 튜터들에게 반복적으로 피드백을 받는 부분은 더 빨리 신경 써서 고치게 돼요. 제가 자주 쓰는 잘못된 문법도요. 이렇게 나에게 맞춘 피드백을 주니 빠른 시간에 진짜 영어 실력을 늘리는 기분이에요.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      's_landing_promo.qna2.review',
      `通过进行实时纠正，我能判断自己现在哪里做得好哪里做得不好。特别是任课教师反复给与反馈的地方，我能更留心去更正。我经常用错的语法也是如此。这样给我有针对性的反馈，感觉真的是在很短的时间内提高了英语水平。`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
      `The tutors correct my English in real-time, so I can tell what was right or wrong in my speech right away. And I could focus on the parts that the tutors repeatedly correct me on, like grammatical mistakes I make often. The tutors' personalized feedback has helped me to improve my English skills very quickly.`,
    ],
    reviewer_desc: [
      `링글 60회 수강, 개발자`,
      `Took 60 lessons with Ringle`,
      's_landing_promo.qna2.reviewer_desc',
      `在Ringle听课60次 开发者`,
      `Took 60 lessons with Ringle`,
      `Took 60 lessons with Ringle`,
      `Took 60 lessons with Ringle`,
    ],
    reviewer: [
      `정현석 님`,
      `Jeong Hyeon-seok, Developer`,
      's_landing_promo.qna2.reviewer',
      `郑贤硕`,
      `Jeong Hyeon-seok, Developer`,
      `Jeong Hyeon-seok, Developer`,
      `Jeong Hyeon-seok, Developer`,
    ],
  },
  qna3: {
    title_pc: [
      `다른 영어 서비스와 가격차이가 크게 나나요?`,
      `Is Ringle more expensive than other English learning services?`,
      's_landing_promo.qna3.title_pc',
      `和其它英语服务相比，价格差异大吗？`,
      `Is Ringle more expensive than other English learning services?`,
      `Is Ringle more expensive than other English learning services?`,
      `Is Ringle more expensive than other English learning services?`,
    ],
    title_mobile: [
      <>
        다른 영어 서비스와
        <br />
        가격차이가 크게 나나요?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      's_landing_promo.qna3.title_mobile',
      <>
        和其它英语服务相比，
        <br />
        价格差异大吗？
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
      <>
        Is Ringle more expensive than
        <br />
        other English learning services?
      </>,
    ],
    review: [
      `회화 학원도 다녀보고, 영어 앱 서비스도 결제해봤어요. 하지만 학원은 오히려 더 비싼 적도 있었고, 앱 서비스는 직접 대면으로 연습하지 못해서 아쉬움이 있더라고요. 운동 1:1 PT가 좋듯이 영어도 1:1로 하니까 확실한 효과가 보였어요. PT 가격 생각하면 링글 회당 가격이 합리적이라고 생각해요.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      's_landing_promo.qna3.review',
      `我上过英语学院，也使用过收费的英语应用程序服务，但是，有时候英语学院的价格更贵，应用程度服务不能进行面对面的练习，所以有点遗憾。就像1对1的健身运动一样，英语也是以1对1的方式上课才看到了更确切的效果。考虑到健身运动价格，我认为Ringle的每次课程价格是合理的。`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
      `I've taken English lessons from others apps and offline classes. Offline classes were even more expensive, while app services didn't offer the personalized care I was looking for. Ringle classes are like working with a personal trainer at a gym. Given the level of care and attention you get, it's an affordable option.`,
    ],
    reviewer_desc: [
      `링글 30회 수강, 마케터`,
      `Took 30 lessons with Ringle`,
      's_landing_promo.qna3.reviewer_desc',
      `在Ringle听课30次 营销人员`,
      `Took 30 lessons with Ringle`,
      `Took 30 lessons with Ringle`,
      `Took 30 lessons with Ringle`,
    ],
    reviewer: [
      `장서경 님`,
      `Jang Seo-young, Marketer`,
      's_landing_promo.qna3.reviewer',
      ``,
      `Jang Seo-young, Marketer`,
      `Jang Seo-young, Marketer`,
      `Jang Seo-young, Marketer`,
    ],
  },
  qna4: {
    title_pc: [
      `초중급자에게 맞는 수업권이 있을까요?`,
      `Which credit do you recommend for beginners/intermediates?`,
      's_landing_promo.qna4.title_pc',
      `有适合初中级学习者的课程券吗？`,
      `Which credit do you recommend for beginners/intermediates?`,
      `Which credit do you recommend for beginners/intermediates?`,
      `Which credit do you recommend for beginners/intermediates?`,
    ],
    title_mobile: [
      <>
        초중급자에게 맞는
        <br />
        수업권이 있을까요?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      's_landing_promo.qna4.title_mobile',
      <>
        有适合初中级学习者的
        <br />
        课程券吗？
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
      <>
        Which credit do you recommend
        <br />
        for beginners/intermediates?
      </>,
    ],
    review: [
      `처음에는 원어민과의 대화가 두려워서 20분 수업을 해봤는데 제가 영어를 잘하지 못하니까 교정할 게 많아서 시간이 짧더라고요. 40분 12회 혹은 24회 수업권이 저와 잘 맞아요. 주 1-2회 수업이라고 계산하면 한 달에 어학교육으로 쓰는 비용에 부담이 없더라고요.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      's_landing_promo.qna4.review',
      `一开始我害怕和英语母语者对话，所以只上了20分钟的课，但是因为我的英语不太好,需要纠正的东西很多,所以感觉时间很短。40分钟的课上12次或者24次的课程券比较适合我。如果以每周上1-2次课程计算，一个月用于语言学习的费用并没有负担。`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
      `I started with 20-minute lessons because I was nervous about talking in English, but it turned out to be too short because the tutors had to correct my English a lot. The 12 or 24 credit package for 40-minute lessons was perfect for me. Considering that I take one or two lessons a week, the money I pay per month is very reasonable.`,
    ],
    reviewer_desc: [
      `링글 50회 수강, 개발자`,
      `Took 50 lessons with Ringle`,
      's_landing_promo.qna4.reviewer_desc',
      `在Ringle听课50次 开发者`,
      `Took 50 lessons with Ringle`,
      `Took 50 lessons with Ringle`,
      `Took 50 lessons with Ringle`,
    ],
    reviewer: [
      `김현모 님`,
      `Kim Hyeon-mo, Developer`,
      's_landing_promo.qna4.reviewer',
      `金贤模`,
      `Kim Hyeon-mo, Developer`,
      `Kim Hyeon-mo, Developer`,
      `Kim Hyeon-mo, Developer`,
    ],
  },
  qna5: {
    title_pc: [
      `초중급자에게 적합한 교재와 튜터가 있나요?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      's_landing_promo.qna5.title_pc',
      `有适合初中级学习者的教材和任课教师吗？`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
      `Any recommended tutors/materials for beginners/intermediates?`,
    ],
    title_mobile: [
      <>
        초중급자에게 적합한
        <br />
        교재와 튜터가 있나요?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      's_landing_promo.qna5.title_mobile',
      <>
        有适合初中级学习者的
        <br />
        教材和任课教师吗？
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for beginners/intermediates?
      </>,
    ],
    review: [
      `주로 "Small Talk” 카테고리 교재를 많이 선택해요. 외국인과 날씨 이야기만 할 수 없잖아요. 일상 대화에서 자주 쓰는 영어 표현도 많이 배우고, 말할 거리를 미리 연습해요. 튜터는 친절하다는 후기가 많은 튜터가 저와 잘 맞았어요. 영어를 잘하지 못해도 무한 칭찬으로 자신감을 키워주거든요.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      's_landing_promo.qna5.review',
      `我主要是在"Small Talk” 目录上选择教材。总不能和外国人只聊天气吧，既能学习在日常生活中经常使用的英语表达，也能提前练习要说的内容。有很多评价说亲切的任课教师很适合我。即使英语不太好，也会经常表扬我，培养我的自信心。`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
      `I mostly choose from the "Small Talk" category, because I'm tired of only talking about the weather with people. These courses helped me learn frequently used expressions and the right questions to ask when chatting at networking events. I'd recommend tutors with reviews saying they are kind—these tutors boost your confidence.`,
    ],
    reviewer_desc: [
      `링글 40회 수강, 기획자`,
      `Took 40 lessons with Ringle`,
      's_landing_promo.qna5.reviewer_desc',
      `在Ringle听课40次 项目经理`,
      `Took 40 lessons with Ringle`,
      `Took 40 lessons with Ringle`,
      `Took 40 lessons with Ringle`,
    ],
    reviewer: [
      `서연우 님`,
      `Seo Yeon-wu, Project Manager`,
      's_landing_promo.qna5.reviewer',
      `徐延宇(音)`,
      `Seo Yeon-wu, Project Manager`,
      `Seo Yeon-wu, Project Manager`,
      `Seo Yeon-wu, Project Manager`,
    ],
  },
  qna6: {
    title_pc: [
      `영어로 의사소통은 가능한데 링글이 어떻게 도움이 될까요?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      's_landing_promo.qna6.title_pc',
      `如果已经可以用英语进行沟通，那Ringle有什么帮助呢？`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
      `What can Ringle offer if I'm comfortable talking in English?`,
    ],
    title_mobile: [
      <>
        영어로 의사 소통은 가능한데
        <br />
        링글이 어떻게 도움이 될까요?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      's_landing_promo.qna6.title_mobile',
      <>
        如果已经可以用英语进行沟通，
        <br />
        那Ringle有什么帮助呢？
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
      <>
        What can Ringle offer if
        <br />
        I'm comfortable talking in English?
      </>,
    ],
    review: [
      `영어로 이야기하는 데 큰 불편함은 없지만 누군가가 고쳐주지 않으니까 혹시 어색한 표현을 쓰고 있지는 않은지 또는 같은 표현만 쓰는지 알기 어렵더라고요. 링글 수업은 실시간으로 제 영어를 교정받고 수업 후 튜터 피드백 리포트와 통계 자료를 통해 분명하게 고칠 점을 파악할 수 있어요.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      's_landing_promo.qna6.review',
      `虽然用英语交流没有太大的不便，但是没人给纠正，所以很难知道自己是不是使用了不自然的表达方式，或者只用同样的表达方式。Ringle课程中，我的英语可以得到实时纠正，课后通过任课教师的反馈报告和统计资料，清晰得掌握自己需要改进的地方。`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
      `Although I'm fairly comfortable speaking in English, as a non-native speaker, I inevitably make mistakes I can't detect myself. Ringle's real-time correction, after-class feedback, and algorithm-powered progress updates have helped me identify and work on these issues.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 마케터`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna6.reviewer_desc',
      `在Ringle听课100次 营销人员`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `이윤진 님`,
      `Lee Yoon-jin, Marketer`,
      's_landing_promo.qna6.reviewer',
      `李允真`,
      `Lee Yoon-jin, Marketer`,
      `Lee Yoon-jin, Marketer`,
      `Lee Yoon-jin, Marketer`,
    ],
  },
  qna7: {
    title_pc: [
      `업무를 영어로 많이 하는데 링글을 어떻게 활용하면 좋은가요?`,
      `Why should I use Ringle? I use English every day at work.`,
      's_landing_promo.qna7.title_pc',
      `如果在工作中经常使用英语，那应该怎么利用好Ringle呢？`,
      `Why should I use Ringle? I use English every day at work.`,
      `Why should I use Ringle? I use English every day at work.`,
      `Why should I use Ringle? I use English every day at work.`,
    ],
    title_mobile: [
      <>
        업무를 영어로 많이 하는데
        <br />
        링글을 어떻게 활용하면 좋은가요?
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      's_landing_promo.qna7.title_mobile',
      <>
        如果在工作中经常使用英语，
        <br />
        那应该怎么利用好Ringle呢？
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
      <>
        Why should I use Ringle?
        <br />I use English every day at work.
      </>,
    ],
    review: [
      `계약서 등 중요한 문서를 작성할 때는 자유 주제 수업 중 문서 교정 수업을 잘 활용하고 있어요. 몇 번 문서 교정을 받으니 지금은 계약서를 쓰고 보는데 확실히 편해졌어요. 그 밖에 중요한 세일즈 프레젠테이션이나, 해외 출장 전에 링글로 미리 스피킹 연습을 해서 자신감을 키우고 나선답니다.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      's_landing_promo.qna7.review',
      `在撰写合同等重要文件时，我很好地利用了自由主题课程中的文件修改课程。经过几次文件修改，现在写合同和看合同确实方便了。除此之外，在做重要的销售陈述或者去海外出差前都用Ringle提前练习口语，培养自信心。`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
      `Ringle offers proofreading lessons, which is very useful when writing important documents like contracts. Taking the lessons made it much easier for me to write and read over contracts. I also use Ringle lessons to practice presentations and build my confidence before an important sales meeting or an overseas business trip.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 해외영업`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna7.reviewer_desc',
      `在Ringle听课100次 海外销售`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `Sandy 님`,
      `Sandy, Overseas Sales`,
      's_landing_promo.qna7.reviewer',
      `Sandy`,
      `Sandy, Overseas Sales`,
      `Sandy, Overseas Sales`,
      `Sandy, Overseas Sales`,
    ],
  },
  qna8: {
    title_pc: [
      `해외 취업을 준비 중입니다. 링글로 도움을 받을 수 있나요?`,
      `I want to work abroad. How can Ringle help?`,
      's_landing_promo.qna8.title_pc',
      `正在准备海外就业，可以得到Ringle的帮助吗？`,
      `I want to work abroad. How can Ringle help?`,
      `I want to work abroad. How can Ringle help?`,
      `I want to work abroad. How can Ringle help?`,
    ],
    title_mobile: [
      <>
        해외 취업을 준비 중입니다.
        <br />
        링글로 도움을 받을 수 있나요?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      's_landing_promo.qna8.title_mobile',
      <>
        正在准备海外就业，
        <br />
        可以得到Ringle的帮助吗？
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to work abroad.
        <br />
        How can Ringle help?
      </>,
    ],
    review: [
      `해외 취업 준비 중에 링글로 인터뷰 연습을 밀도 있게 진행했어요. 인터뷰가 잡히면 하루에 2-3개씩 수업을 잡고 다양한 튜터들과 인터뷰를 연습하면서 최적의 인터뷰 스크립트를 만들어 나갔습니다. 반복적으로 스크립트를 연습하고, 다양한 튜터들과 연습하고 나니 실전에서도 긴장감이 줄었어요.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      's_landing_promo.qna8.review',
      `在准备海外就业时，可以通过Ringle进行集中面试练习。如果有面试日程，可以每天安排2-3节课程，和不一样的任课教师练习面试，准备最佳的面试脚本。反复练习脚本，并和多位导师进行练习后，会减少实战中的紧张感。`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
      `Ringle helped me prepare for my job interviews. If I had an upcoming interview, I took 2-3 lessons a day with various tutors to practice and put together the best script possible for possible interview questions. Repeatedly practicing the script with various tutors helped me to feel comfortable during the actual job interview.`,
    ],
    reviewer_desc: [
      `링글 65회 수강, UX 디자이너`,
      `Took 65 lessons with Ringle`,
      's_landing_promo.qna8.reviewer_desc',
      `在Ringle听课65次 UX设计师`,
      `Took 65 lessons with Ringle`,
      `Took 65 lessons with Ringle`,
      `Took 65 lessons with Ringle`,
    ],
    reviewer: [
      `김창수 님`,
      `Kim Chang-su, UX Designer`,
      's_landing_promo.qna8.reviewer',
      `金昌洙`,
      `Kim Chang-su, UX Designer`,
      `Kim Chang-su, UX Designer`,
      `Kim Chang-su, UX Designer`,
    ],
  },
  qna9: {
    title_pc: [
      `유학 준비도 링글로 가능한가요?`,
      `I want to study abroad. How can Ringle help?`,
      's_landing_promo.qna9.title_pc',
      `留学也可以通过Ringle来做准备吗？`,
      `I want to study abroad. How can Ringle help?`,
      `I want to study abroad. How can Ringle help?`,
      `I want to study abroad. How can Ringle help?`,
    ],
    title_mobile: [
      <>
        유학 준비도
        <br />
        링글로 가능한가요?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      's_landing_promo.qna9.title_mobile',
      <>
        留学
        <br />
        也可以通过Ringle来做准备吗？
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
      <>
        I want to study abroad.
        <br />
        How can Ringle help?
      </>,
    ],
    review: [
      `저는 링글로 미국 MBA 유학을 준비했어요. 레쥬메와 에세이를 쓸 때 문서 교정 수업을 통해 튜터와 이야기를 나누니 의미가 훼손되지 않으면서도 자연스러운 표현으로 간결하게 정리할 수 있었어요. 인터뷰 준비 역시 여러 명의 튜터와 10회 이상 실전 연습을 한 덕분에 좋은 결과를 얻을 수 있었습니다.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      's_landing_promo.qna9.review',
      `我通过Ringle准备了美国MBA留学。在写简历和报告时，通过文件修改课程，和任课教师一起讨论，用既不破坏原意又自然的表达方式进行了简洁的整理。面试准备也是和多位任课教师一起进行10多次实战练习之后，才能够得到了一个好的结果。`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
      `I used Ringle's proofreading lessons to prepare my applications for MBA programs in the U.S. The tutors helped me to make my resume and personal statement more concise and natural while conveying what I meant to say better. I also practiced my interview skills with various tutors. Thanks to Ringle, I was able to get into the school I wanted.`,
    ],
    reviewer_desc: [
      `링글 80회 수강, 마케터`,
      `Took 80 lessons with Ringle`,
      's_landing_promo.qna9.reviewer_desc',
      `在Ringle听课80次 营销人员`,
      `Took 80 lessons with Ringle`,
      `Took 80 lessons with Ringle`,
      `Took 80 lessons with Ringle`,
    ],
    reviewer: [
      `강수연 님`,
      `Kang Su-yeon, Marketer`,
      's_landing_promo.qna9.reviewer',
      `姜秀妍`,
      `Kang Su-yeon, Marketer`,
      `Kang Su-yeon, Marketer`,
      `Kang Su-yeon, Marketer`,
    ],
  },
  qna10: {
    title_pc: [
      `상급자에게 도움이 되는 교재와 튜터가 있나요?`,
      `Any recommended tutors/materials for advanced learners?`,
      's_landing_promo.qna10.title_pc',
      `有对英语高级学习者有帮助的课程方式和任课教师吗？`,
      `Any recommended tutors/materials for advanced learners?`,
      `Any recommended tutors/materials for advanced learners?`,
      `Any recommended tutors/materials for advanced learners?`,
    ],
    title_mobile: [
      <>
        상급자에게 도움이 되는
        <br />
        교재와 튜터가 있나요?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      's_landing_promo.qna10.title_mobile',
      <>
        有对英语高级学习者有帮助的
        <br />
        课程方式和任课教师吗？
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
      <>
        Any recommended tutors/materials
        <br />
        for advanced learners?
      </>,
    ],
    review: [
      `회화에 익숙한 분이라면 다양한 악센트의 튜터와 토론 모드로 수업하는 것을 추천해요. 미국식 영어를 넘어 어떤 나라에서든, 어떤 국적의 상대방과도 영어로 의사소통이 가능하려면 반드시 필요한 과정이라고 생각해요. 또한 심도 있는 대화를 주고받다 보면 영어로 복잡한 생각도 정리하고 이야기하는 게 가능해져요.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      's_landing_promo.qna10.review',
      `对于熟悉会话的人，推荐多种口音的任课教师和讨论模式的课程。不仅仅是美式英语，如果想在任何一个国家和任何国籍的人用英语对话，我认为这是一个必需的过程。另外，通过进行深度的对话，可以用英语整理复杂的想法并进行交谈。`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
      `If you are comfortable with having conversations in English, I recommend taking discussion lessons with tutors with various accents. I think getting used to different accents other than American English is absolutely necessary for anyone who wants to communicate in English with people around the world.`,
    ],
    reviewer_desc: [
      `링글 100회 수강, 연구원`,
      `Took 100 lessons with Ringle`,
      's_landing_promo.qna10.reviewer_desc',
      `在Ringle听课100次 研究员`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
      `Took 100 lessons with Ringle`,
    ],
    reviewer: [
      `이석연 님`,
      `Lee Seok-yeon, Researcher`,
      's_landing_promo.qna10.reviewer',
      `李锡渊`,
      `Lee Seok-yeon, Researcher`,
      `Lee Seok-yeon, Researcher`,
      `Lee Seok-yeon, Researcher`,
    ],
  },
  title3_pc: [
    `신년 영어 공부, 가장 저렴하게 준비하세요.`,
    `The Best Deals For Studying English Available Now.`,
    's_landing_promo.title3_pc',
    `以2023年最优惠的价格报名Ringle吧。`,
    `The Best Deals For Studying English Available Now.`,
    `The Best Deals For Studying English Available Now.`,
    `The Best Deals For Studying English Available Now.`,
  ],
  title3_mobile: [
    <>
      신년 영어 공부, <br />
      가장 저렴하게
      <br />
      준비하세요.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    's_landing_promo.title3_mobile',
    <>
      以2023年
      <br />
      最优惠的价格
      <br />
      报名Ringle吧。
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
  ],
  month: [`/월`, `/Month`, 's_landing_promo.month', `/月`, `/Month`, `/Month`, `/Month`],
  nomal: [`정가`, `Regular:`, 's_landing_promo.nomal', `定价`, `Regular:`, `Regular:`, `Regular:`],
  won: [`원`, `KRW`, 's_landing_promo.won', `元`, `KRW`, `KRW`, `KRW`],
  discount: [
    `프로모션 할인가`,
    `Discounted:`,
    's_landing_promo.discount',
    `特价`,
    `Discounted:`,
    `Discounted:`,
    `Discounted:`,
  ],
  info_min: [
    `1회 수업 시간`,
    `Time per Lesson`,
    's_landing_promo.info_min',
    `一次课程时间`,
    `Time per Lesson`,
    `Time per Lesson`,
    `Time per Lesson`,
  ],
  info_count: [
    `수업 횟수`,
    `No. of credits`,
    's_landing_promo.info_count',
    `课程次数`,
    `No. of credits`,
    `No. of credits`,
    `No. of credits`,
  ],
  info_day: [
    `수강 기간`,
    `Expiration dates`,
    's_landing_promo.info_day',
    `上课时间`,
    `Expiration dates`,
    `Expiration dates`,
    `Expiration dates`,
  ],
  min: [`분`, `Min`, 's_landing_promo.min', `分`, `Min`, `Min`, `Min`],
  count: [`회`, ``, 's_landing_promo.count', `次`, ``, ``, ``],
  day: [`일`, `Days`, 's_landing_promo.day', `日`, `Days`, `Days`, `Days`],
  credit_desc: [
    `수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    's_landing_promo.credit_desc',
    `课程券在课程期间可以自由使用`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
  ],
  credit_desc2: [
    `100회 수업권은 25회 당일수업권이 추가 제공됩니다.`,
    `25 extra last-minute credits will be given`,
    's_landing_promo.credit_desc2',
    `追加赠送25张当日课程券`,
    `25 extra last-minute credits will be given`,
    `25 extra last-minute credits will be given`,
    `25 extra last-minute credits will be given`,
  ],
  benefit4_subtitle: [
    `링글이 처음이라면?`,
    `New to Ringle?`,
    's_landing_promo.benefit4_subtitle',
    `如果是第一次报名Ringle`,
    `New to Ringle?`,
    `New to Ringle?`,
    `New to Ringle?`,
  ],
  benefit4_title: [
    `신규회원 수업권 최대 3회 추가 증정`,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    's_landing_promo.benefit4_title',
    `最多赠送3次新会员优惠课程券`,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
  ],
  benefit5_subtitle: [
    `이미 링글 고객이라면?`,
    `Already a Ringler?`,
    's_landing_promo.benefit5_subtitle',
    `如果已经是Ringle会员`,
    `Already a Ringler?`,
    `Already a Ringler?`,
    `Already a Ringler?`,
  ],
  benefit5_title: [
    `보유 수업권 수강기간 연장 혜택`,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    's_landing_promo.benefit5_title',
    `延长持有课程券听课时间的优惠`,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
  ],
  title4_pc: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도 함께하면 가능해요.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_pc',
    ``,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
  ],
  title4_mobile: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도
      <br />
      함께하면 가능해요.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_mobile',
    ``,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
  ],
  studyclub: [
    `추가혜택 1. 스터디클럽 2기`,
    `Study Club Round 2`,
    's_landing_promo.studyclub',
    ``,
    `Study Club Round 2`,
    `Study Club Round 2`,
    `Study Club Round 2`,
  ],
  subtitle3: [
    <>
      실력을 만드는 영어 공부 습관,
      <br />
      4주 동안 함께 만들어요.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    's_landing_promo.subtitle3',
    ``,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
  ],
  desc3: [
    <>
      1. 매주 1시간 다른 수강생들과 영어 스피킹 연습
      <br />
      2. 매주 1회 이상 링글 수업 의무 수강
      <br />
      3. 링글팀의 1:1 목표관리
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    's_landing_promo.desc3',
    ``,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
  ],
  info1: [
    <>
      <b>참여 방법:</b>
      <br />
      프로모션 기간 내 정규수업권 구매 시 신청 가능
      <br />
      선착순 250명씩 총 4개 팀 운영 (총 1,000명)
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    's_landing_promo.info1',
    ``,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
  ],
  info2: [
    <>
      <b>운영 기간:</b>
      <br />
      각 팀별 운영 기간 하단 참고
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    's_landing_promo.info2',
    ``,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
  ],
  info3: [
    <>
      <b>운영 방식:</b>
      <br />
      주 1회 1시간씩 줌 화상 미팅으로
      <br />
      소그룹 영어 스피킹 연습 진행 (총 4주)
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    's_landing_promo.info3',
    ``,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
  ],
  info4: [
    <>
      <b>완주 조건:</b>
      <br />
      스터디 모임 3회 이상 참석, 수업 3회 이상 수강
      <br />
      미완주시 다음 스터디클럽 신청 불가
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    's_landing_promo.info4',
    ``,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
  ],
  info5: [
    <>
      <b>완주자 혜택:</b>
      <br />
      40분 정규 수업 1년 패키지 (1명 추첨)
      <br />
      10만원 상당의 깜짝 선물 (10명 추첨)
      <br />
      오피스 이벤트 초대
      <br />
      수강생 네트워킹 오픈채팅방 초대
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    's_landing_promo.info5',
    ``,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
  ],
  studyclub_apply: [`모집:`, `Apply:`, 's_landing_promo.studyclub_apply', ``, `Apply:`, `Apply:`, `Apply:`],
  studyclub_meetings: [
    `스터디 기간:`,
    `Meetings:`,
    's_landing_promo.studyclub_meetings',
    ``,
    `Meetings:`,
    `Meetings:`,
    `Meetings:`,
  ],
  studyclub_a: {
    default: [`A팀`, `A team`, 's_landing_promo.studyclub_a.default', ``, `A team`, `A team`, `A team`],
    date1: [
      `12/23 (금) 14:00 - 12/30 (금) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      's_landing_promo.studyclub_a.date1',
      ``,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
      `Dec 23 (Fri) 14:00 - Dec 30 (Fri) 23:59`,
    ],
    date2: [
      `1/5 - 2/2 매주 목요일 저녁 8시`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      's_landing_promo.studyclub_a.date2',
      ``,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
      `8 pm every Thu, Jan 5 - Feb 2, 2023`,
    ],
  },
  studyclub_b: {
    default: [`B팀`, `B team`, 's_landing_promo.studyclub_b.default', ``, `B team`, `B team`, `B team`],
    date1: [
      `12/31 (토) 00:00 - 1/13 (금) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      's_landing_promo.studyclub_b.date1',
      ``,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
      `Dec 31 (Sat) 00:00 - Jan 13 (Fri) 23:59`,
    ],
    date2: [
      `1/19 - 2/16 매주 목요일 저녁 8시`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      's_landing_promo.studyclub_b.date2',
      ``,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
      `8 pm every Thu, Jan 19 - Feb 16, 2023`,
    ],
  },
  studyclub_c: {
    default: [`C팀`, `C team`, 's_landing_promo.studyclub_c.default', ``, `C team`, `C team`, `C team`],
    date1: [
      `1/14 (토) 00:00 - 1/27 (금) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      's_landing_promo.studyclub_c.date1',
      ``,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
      `Jan 1 (Sat) 00:00 - Jan 27 (Fri) 23:59`,
    ],
    date2: [
      `2/2 - 2/23 매주 목요일 저녁 8시`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      's_landing_promo.studyclub_c.date2',
      ``,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
      `8 pm every Thu, Feb 2 - Feb 23, 2023`,
    ],
  },
  studyclub_d: {
    default: [`D팀`, `D team`, 's_landing_promo.studyclub_d.default', ``, `D team`, `D team`, `D team`],
    date1: [
      `1/28 (토) 00:00 - 2/2 (목) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      's_landing_promo.studyclub_d.date1',
      ``,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
      `Jan 28 (Sat) 00:00 - Feb 2 (Thu) 23:59`,
    ],
    date2: [
      `2/9 - 3/2 매주 목요일 저녁 8시`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      's_landing_promo.studyclub_d.date2',
      ``,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
      `8 pm every Thu, Feb 9 - Mar 2, 2023`,
    ],
  },
  study_en: [
    ``,
    `Study club meetings will be held in Korean.`,
    's_landing_promo.study_en',
    ``,
    `Study club meetings will be held in Korean.`,
    `Study club meetings will be held in Korean.`,
    `Study club meetings will be held in Korean.`,
  ],
  btn2: [
    `로그인하고 신청하기`,
    `Log in and Apply`,
    's_landing_promo.btn2',
    ``,
    `Log in and Apply`,
    `Log in and Apply`,
    `Log in and Apply`,
  ],
  title5_mobile: [
    <>
      영어에만 집중하세요.
      <br />
      특별한 여행은 저희가
      <br />
      책임질게요.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    's_landing_promo.title5_mobile',
    ``,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
    <>
      Focus on your English.
      <br />
      We'll take care of
      <br />
      your special journey.
    </>,
  ],
  Challenge: [
    `추가혜택 2. 보스턴&뉴욕 챌린지 3기`,
    `Boston & NYC Challenge`,
    's_landing_promo.Challenge',
    ``,
    `Boston & NYC Challenge`,
    `Boston & NYC Challenge`,
    `Boston & NYC Challenge`,
  ],
  challenge_begins: [
    `1월 6일부터 도전이 시작돼요.`,
    `The Challenge starts on Jan 6, 2023`,
    's_landing_promo.challenge_begins',
    ``,
    `The Challenge starts on Jan 6, 2023`,
    `The Challenge starts on Jan 6, 2023`,
    `The Challenge starts on Jan 6, 2023`,
  ],
  btn_txt1: [
    `지금이 신년 영어 공부 준비하기 딱 좋은 타이밍 ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
    's_landing_promo.btn_txt1',
    `2023年用最低价格购买的机会`,
    `The Perfect Time to Start Ringle! ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
  ],
  btn_txt2: [
    `링글 신년 프로모션`,
    `New Year Promotion`,
    's_landing_promo.btn_txt2',
    `一年只有两次的特价`,
    `New Year Promotion`,
    `New Year Promotion`,
    `New Year Promotion`,
  ],
  desc5_pc: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지, 어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_pc',
    <>
      想要知道在不同的情况下要怎麽使用Ringle, 想知道怎麽学英文吗？
      <br />
      <b>和Ringle团队聊聊吧.</b>
    </>,
    ``,
    ``,
    ``,
  ],
  title6_mobile: [
    <>
      아직 망설여지나요?
      <br />
      링글팀과 직접 상담하세요.
    </>,
    ``,
    's_landing_promo.title6_mobile',
    <>
      还在犹豫吗？
      <br />
      和Ringle团队聊聊
    </>,
    ``,
    ``,
    ``,
  ],
  desc5_mobile: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지,
      <br />
      어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_mobile',
    <>
      想要知道在不同的情况下要怎麽使用Ringle, 想知道怎麽学英文吗？
      <br />
      <br />
      <br />
      <b>和Ringle团队聊聊吧.</b>
    </>,
    ``,
    ``,
    ``,
  ],
  btn8: [`궁금한 점을 남겨주세요`, ``, 's_landing_promo.btn8', `留下您的问题`, ``, ``, ``],
  tab1: [`23년 최저가`, `Best Deal`, 's_landing_promo.tab1', ``, `Best Deal`, `Best Deal`, `Best Deal`],
  tab2: [`스터디클럽`, `Study Club`, 's_landing_promo.tab2', ``, `Study Club`, `Study Club`, `Study Club`],
  tab3: [`보스턴 챌린지`, `Challenge`, 's_landing_promo.tab3', ``, `Challenge`, `Challenge`, `Challenge`],
  benefit1_subtitle: [
    `가장 저렴하게 신년 영어공부 준비`,
    `Our Lowest Prices in 2023`,
    's_landing_promo.benefit1_subtitle',
    ``,
    `Our Lowest Prices in 2023`,
    `Our Lowest Prices in 2023`,
    `Our Lowest Prices in 2023`,
  ],
  benefit1_title: [
    `최대 38% 할인`,
    `Up to 38% off`,
    's_landing_promo.benefit1_title',
    ``,
    `Up to 38% off`,
    `Up to 38% off`,
    `Up to 38% off`,
  ],
  benefit2_subtitle: [
    `신규회원 구매 시`,
    `Special Offer for Newcomers`,
    's_landing_promo.benefit2_subtitle',
    ``,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
  ],
  benefit2_title: [
    `수업권 최대 3회 추가 증정`,
    `Up to 3 free credits`,
    's_landing_promo.benefit2_title',
    ``,
    `Up to 3 free credits`,
    `Up to 3 free credits`,
    `Up to 3 free credits`,
  ],
  benefit3_subtitle: [
    `링글러들과 함께 목표 달성`,
    `Learn with Ringlers`,
    's_landing_promo.benefit3_subtitle',
    ``,
    `Learn with Ringlers`,
    `Learn with Ringlers`,
    `Learn with Ringlers`,
  ],
  benefit3_title: [
    `스터디클럽 2기`,
    `Study Club Season 2`,
    's_landing_promo.benefit3_title',
    ``,
    `Study Club Season 2`,
    `Study Club Season 2`,
    `Study Club Season 2`,
  ],
  d_day_early: [
    `프로모션 마감까지`,
    `Early Bird Ends In:`,
    's_landing_promo.d_day_early',
    `早鸟优惠截止`,
    `Early Bird Ends In:`,
    `Early Bird Ends In:`,
    `Early Bird Ends In:`,
  ],
  regular: [
    `신년 프로모션 할인 마감까지`,
    `Promotion Ends In:`,
    's_landing_promo.regular',
    `优惠活动截止`,
    `Promotion Ends In:`,
    `Promotion Ends In:`,
    `Promotion Ends In:`,
  ],
  cowntdown: [`마감까지`, `Ends in`, 's_landing_promo.cowntdown', `优惠截止`, `Ends in`, `Ends in`, `Ends in`],
  hrs: [`시간`, `Hours`, 's_landing_promo.hrs', `时`, `Hours`, `Hours`, `Hours`],
  mins: [`분`, `Minutes`, 's_landing_promo.mins', `分`, `Minutes`, `Minutes`, `Minutes`],
  secs: [`초`, `Seconds`, 's_landing_promo.secs', `秒`, `Seconds`, `Seconds`, `Seconds`],
  d_day_txt1: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt1',
    <>
      Ringle 2023年
      <br />
      以最低价格购买的机会
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
  ],
  d_day_txt2: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt2',
    <>
      一年只有两次的
      <br />
      Ringle课程券优惠
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
  ],
  subtitle_1: [
    `초중급자를 위한 링글`,
    `For Beginners/Intermediates`,
    's_landing_promo.subtitle_1',
    `给初/中级英语学习者的Ringle`,
    `For Beginners/Intermediates`,
    `For Beginners/Intermediates`,
    `For Beginners/Intermediates`,
  ],
  subtitle_2: [
    `중상급자를 위한 링글`,
    `For Advanced Learners`,
    's_landing_promo.subtitle_2',
    `给中高级英语学习者的Ringle`,
    `For Advanced Learners`,
    `For Advanced Learners`,
    `For Advanced Learners`,
  ],
  title7_pc: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면 선물이 커져요!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    's_landing_promo.title7_pc',
    ``,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
  ],
  title7_mobile: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면
      <br />
      선물이 커져요!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    's_landing_promo.title7_mobile',
    ``,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
  ],
  btn9: [
    `목표 공유하기`,
    `Share Your Study Goals`,
    's_landing_promo.btn9',
    ``,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
  ],
  subtitle7: [`참여 혜택`, `Rewards`, 's_landing_promo.subtitle7', ``, `Rewards`, `Rewards`, `Rewards`],
  share: {
    three_times: [
      <>
        <span className="text-[#FF6712]">3회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      's_landing_promo.share.three_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
    ],
    subtitle1: [
      `추운 겨울에 딱 좋은`,
      `To fend off the winter cold`,
      's_landing_promo.share.subtitle1',
      ``,
      `To fend off the winter cold`,
      `To fend off the winter cold`,
      `To fend off the winter cold`,
    ],
    title1: [
      <>
        스타벅스
        <br />
        기프티콘 1장
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      's_landing_promo.share.title1',
      ``,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
    ],
    six_times: [
      <>
        <span className="text-[#FF6712]">6회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      's_landing_promo.share.six_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
    ],
    subtitle2: [
      `이미 받은 1장에`,
      `Let's double the stakes!`,
      's_landing_promo.share.subtitle2',
      ``,
      `Let's double the stakes!`,
      `Let's double the stakes!`,
      `Let's double the stakes!`,
    ],
    title2: [
      <>
        스타벅스
        <br />
        기프티콘 추가로 2장 더
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      's_landing_promo.share.title2',
      ``,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
    ],
    ten_times: [
      <>
        <span className="text-[#FF6712]">10회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      's_landing_promo.share.ten_times',
      ``,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
    ],
    subtitle3: [
      `위 모든 혜택에`,
      `All the rewards above PLUS`,
      's_landing_promo.share.subtitle3',
      ``,
      `All the rewards above PLUS`,
      `All the rewards above PLUS`,
      `All the rewards above PLUS`,
    ],
    title3: [
      <>
        링글 수업 4회권
        <br />
        당첨 기회까지
        <br />
      </>,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      's_landing_promo.share.title3',
      ``,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      <>
        A Chance to Win
        <br />4 Lesson Credits
      </>,
    ],
    desc: [
      `(총 2명)`,
      `(Limited to two winners)`,
      's_landing_promo.share.desc',
      ``,
      `(Limited to two winners)`,
      `(Limited to two winners)`,
      `(Limited to two winners)`,
    ],
  },
  card_subtitle: [
    `2023년 영어 공부 목표`,
    `Study Goals for 2023`,
    's_landing_promo.card_subtitle',
    ``,
    `Study Goals for 2023`,
    `Study Goals for 2023`,
    `Study Goals for 2023`,
  ],
  card_title1: [
    <>
      umm...
      <br />
      나 올훼 한국말
      <br />몬 해도 놀라지 뫄
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    's_landing_promo.card_title1',
    ``,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
  ],
  card_title2: [
    <>
      한 30년 한국말 하니까
      <br />
      좀 지겹더라구...
      <br />
      영어로 넘어가게
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    's_landing_promo.card_title2',
    ``,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
  ],
  card_title3: [
    <>
      영유 다니는 조카보다는
      <br />
      잘해보려구
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    's_landing_promo.card_title3',
    ``,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
  ],
  card_title4: [
    <>
      보여줄게
      <br />
      완전히 달라진
      <br />
      (영어 잘하는) 나
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    's_landing_promo.card_title4',
    ``,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
  ],
  card_title5: [
    <>
      실리콘밸리에 먼저
      <br />
      자리 잡고 있을게
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    's_landing_promo.card_title5',
    ``,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
  ],
  card_title6: [
    <>
      이제 파X고랑
      <br />
      헤어지려고
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    's_landing_promo.card_title6',
    ``,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
  ],
  label_earlybird_2: [
    `할인 혜택`,
    `Discounts`,
    's_landing_promo.label_earlybird_2',
    `Discounts`,
    `Discounts`,
    `Discounts`,
    `Discounts`,
  ],
  label_earlybird_3: [
    `프로모션 특가`,
    `Promotional Deal`,
    's_landing_promo.label_earlybird_3',
    `新年特价`,
    `Promotional Deal`,
    `Promotional Deal`,
    `Promotional Deal`,
  ],
  challenge_info1: [
    <>
      <b>투어 내용:</b>
      <br />
      - 항공권 + 숙박 + 프로그램 비용 포함
      <br />
      - 글로벌 테크/서비스 기업 오피스 투어 및 현직자 네트워킹
      <br />- Harvard 등에서 재학 중인 튜터와의 만남
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    's_landing_promo.challenge_info1',
    ``,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
  ],
  challenge_info2: [
    <>
      <b>챌린지 기간:</b> 23.01.06 ~ 23.04.30
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    's_landing_promo.challenge_info2',
    ``,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
  ],
  challenge_info3: [
    <>
      <b>참여 방법:</b>
      <br />
      - 챌린지 기간 내 20분 정규수업 32회, 40분 정규수업 24회 이상 정상 수강 시 챌린지 완주
      <br />
      - 챌린지 완주자에 한해 보스턴&뉴욕 여행 지원 자격 부여
      <br />
      <br />
      자세한 내용은 하단 링크 통해서 상세 페이지 확인
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    's_landing_promo.challenge_info3',
    ``,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
  ],
  btn_txt1_1: [`프로모션 가격에 추가 30만원 할인`, ``, 's_landing_promo.btn_txt1_1', ``, ``, ``, ``],
  benefit1_subtitle_1: [`가장 저렴하게 신년 영어공부 준비`, ``, 's_landing_promo.benefit1_subtitle_1', ``, ``, ``, ``],
  benefit1_title_1: [`최대 38% 할인`, ``, 's_landing_promo.benefit1_title_1', ``, ``, ``, ``],
  hero_promo_moW: [
    `23년 영어 공부, 지금 준비하세요.`,
    `Get Ready to Upgrade Your English`,
    's_landing_promo.hero_promo_moW',
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
    `Get Ready to Upgrade Your English`,
  ],
  btn_update: [`새로고침`, `Update`, 's_landing_promo.btn_update', `Update`, `Update`, `Update`, `Update`],
  subtitle6_desc1: [
    `[목표 공유하기 버튼]으로 공유한 경우만 인정돼요.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    's_landing_promo.subtitle6_desc1',
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
  ],
  subtitle6_desc2: [
    `나와의 채팅방/같은 채팅방에 여러 번 공유는 안 돼요.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    's_landing_promo.subtitle6_desc2',
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    `Sharing to yourself or to the same chatroom do not count.`,
  ],
  subtitle6_desc3: [
    `횟수가 안 올라간다면 새로고침 버튼을 눌러주세요.`,
    `To update share count, click the [Update] button.`,
    's_landing_promo.subtitle6_desc3',
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
    `To update share count, click the [Update] button.`,
  ],
  label_endingsoon: [
    `마감 임박`,
    `Ending Soon`,
    's_landing_promo.label_endingsoon',
    ``,
    `Ending Soon`,
    `Ending Soon`,
    `Ending Soon`,
  ],
  benefit6_subtitle: [
    `튜터도 만나고 글로벌 회사 탐방까지`,
    `Meet Tutors and Visit Global Companies`,
    's_landing_promo.benefit6_subtitle',
    ``,
    `Meet Tutors and Visit Global Companies`,
    `Meet Tutors and Visit Global Companies`,
    `Meet Tutors and Visit Global Companies`,
  ],
  benefit6_title: [
    `보스턴&뉴욕 챌린지 3기`,
    `Boston&NYC Challenge`,
    's_landing_promo.benefit6_title',
    ``,
    `Boston&NYC Challenge`,
    `Boston&NYC Challenge`,
    `Boston&NYC Challenge`,
  ],
  benefit7_subtitle: [
    `공유할수록 선물이 더 커지는`,
    `Share Your Study Goals`,
    's_landing_promo.benefit7_subtitle',
    ``,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
    `Share Your Study Goals`,
  ],
  benefit7_title: [
    `영어 목표 공유 이벤트`,
    `New Year's Event`,
    's_landing_promo.benefit7_title',
    `New Year's Event`,
    `New Year's Event`,
    `New Year's Event`,
    `New Year's Event`,
  ],
  title8: {
    mobile: [
      <>
        링글이 처음이라면? <br />
        수업권 최대 3회 <br />
        받아가세요.
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      's_landing_promo.title8.mobile',
      ``,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
    ],
    pc: [
      <>
        링글이 처음이라면? <br />
        수업권 최대 3회 받아가세요.
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      's_landing_promo.title8.pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome: [`신규회원 한정 혜택`, ``, 's_landing_promo.welcome', ``, ``, ``, ``],
  desc6: [
    <>
      <li className="li-tag-inside">가입 즉시 수업권 추가 증정 쿠폰 발급</li>
      <li className="li-tag-inside">12회 이상 수업권부터 적용 가능</li>
      <li className="li-tag-inside">수업권 별로 차등 적용</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    's_landing_promo.desc6',
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
  ],
  label_regular_2: [`기간 한정`, `Season`, 's_landing_promo.label_regular_2', ``, `Season`, `Season`, `Season`],
  welcome_btn: [
    `지금 신규회원 쿠폰 확인하기`,
    `View more`,
    's_landing_promo.welcome_btn',
    `View more`,
    `View more`,
    `View more`,
    `View more`,
  ],
  welcome_title: [
    `신규회원 한정 혜택`,
    `Special Offer for Newcomers`,
    's_landing_promo.welcome_title',
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
    `Special Offer for Newcomers`,
  ],
}
export const s_app_download = {
  label: [
    <>
      링글 앱에서 <b>수업을 준비</b>하고
      <br />
      <b>다양한 콘텐츠</b>를 확인해보세요.
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    's_app_download.label',
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
  ],
  button: [
    `앱에서 링글 시작하기`,
    `Continue in App`,
    's_app_download.button',
    `Continue in App`,
    `Continue in App`,
    `Continue in App`,
    `Continue in App`,
  ],
  footer: [
    `오늘은 모바일 웹을 더 볼래요.`,
    `Continue in Browser.`,
    's_app_download.footer',
    `Continue in Browser.`,
    `Continue in Browser.`,
    `Continue in Browser.`,
    `Continue in Browser.`,
  ],
  title: [
    <>
      링글 앱에서 <b>매일 새로운 영어 콘텐츠</b>를<br />
      <b>무료로</b> 받아보실 수 있어요!
    </>,
    ``,
    's_app_download.title',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_1: [`앱에서 더보기`, ``, 's_app_download.btn_1', ``, ``, ``, ``],
  btn_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_app_download.btn_2', ``, ``, ``, ``],
}
export const s_guide_modal = {
  title: [
    `링글 수업이 어떻게 진행되는지 궁금하다면?`,
    `Curious how Ringle lessons are conducted?`,
    's_guide_modal.title',
    `您若对Ringle课程感兴趣，可以观看`,
    `您若對Ringle課程感興趣，可以查看`,
    `リンクルレッスンについて知りたい方は`,
    `Nếu bạn đang muốn biết về buổi học của Ringle
Hãy xem trước buổi học`,
  ],
  btn: [
    `수업 미리보기`,
    `Lesson Preview`,
    's_guide_modal.btn',
    `课程预览`,
    `課程預覽`,
    `レッスンプレビュー`,
    `Hãy xem trước buổi học`,
  ],
}
export const s_guide_player = {
  title: [
    `링글 수업 가이드`,
    `Ringle Lesson Guide`,
    's_guide_player.title',
    `Ringle课程介绍`,
    `Ringle課程介紹`,
    `Ringleレッスンガイド`,
    `và hướng dẫn buổi học`,
  ],
  subtitle1: [
    `Step1. 수업 예약`,
    `Step 1. Book Lesson`,
    's_guide_player.subtitle1',
    `Step1. 预约`,
    `Step1. 預約`,
    `Step1. レッスン予約`,
    `Step1. Đặt lịch`,
  ],
  subtitle2: [
    `Step2. 예습 & 수업`,
    `Step 2. Prep & Lesson`,
    's_guide_player.subtitle2',
    `Step2. 预习`,
    `Step2. 預習`,
    `Step2. 予習 & レッスン`,
    `Step2. Chuẩn bị & buổi học`,
  ],
  subtitle3: [
    `Step3. 복습`,
    `Step 3. Review`,
    's_guide_player.subtitle3',
    `Step3. 复习`,
    `Step3. 複習`,
    `Step3. 復習`,
    `Step3. Đánh giá buổi học`,
  ],
  desc: [
    `가이드 영상은 Help Center에서도 시청이 가능합니다.`,
    `You can also watch the guide video at the Help Center.`,
    's_guide_player.desc',
    `介绍视频也可以在Help Center中观看。`,
    `介紹視頻也可以在Help Center中觀看。`,
    `ガイド映像はHelpCenterでもご覧いただけます。`,
    `hay bạn có thể xem video hướng dẫn trong Help Center`,
  ],
}
export const s_guide_dialog = {
  desc: [
    <>
      가이드 영상은 도움이 되셨나요? 이제 링글 수업을 시작해보세요!
      <br />
      해당 영상은 Help Center 에서 다시 시청 할 수 있습니다.
    </>,
    <>Was the guide video helpful? Start a Ringle lesson now! You can watch this video again at the Help Center.</>,
    's_guide_dialog.desc',
    <>
      观看介绍视频，对您是否有帮助呢？那就开始Ringle课程吧！
      <br /> 该视频可以在 Help Center中再次观看。
    </>,
    <>
      看完介紹視頻之後，對您是否有幫助呢？那就開始Ringle課程吧！
      <br /> 該視頻可以在 Help Center中再次觀看。
    </>,
    <>
      ガイド映像は役に立ちましたか？ではRingleのレッスンを始めてみましょう。
      <br />
      この映像はヘルプセンターから再視聴が可能です。
    </>,
    <>
      Video hướng dẫn có bổ ích không? Bây giờ thử bắt đầu buổi học Ringle nhé. <br /> Bạn có thể xem lại video này
      trong Help Center
    </>,
  ],
  btn: [`확인`, `Next`, 's_guide_dialog.btn', `确认`, `確認`, `確認`, `Ok`],
}
export const s_siliconvalley_challenge = {
  page_title: [
    `실리콘밸리 챌린지`,
    `Silicon Valley Challenge`,
    's_siliconvalley_challenge.page_title',
    `Silicon Valley Challenge`,
    `Silicon Valley Challenge`,
    `Silicon Valley Challenge`,
    `Silicon Valley Challenge`,
  ],
  section1: {
    title: [
      <>
        실리콘밸리를 넘어,
        <br />
        <div style={{ color: '#F66B0E' }}>나를 위한 도전</div>
      </>,
      <>
        <div style={{ color: '#F66B0E' }}>Challenge yourself</div>
        <div style={{ color: '#ffffff' }}>Silicon Valley and beyond</div>
      </>,
      's_siliconvalley_challenge.section1.title',
      <>
        <div style={{ color: '#F66B0E' }}>Challenge yourself</div>
        <div style={{ color: '#ffffff' }}>Silicon Valley and beyond</div>
      </>,
      <>
        <div style={{ color: '#F66B0E' }}>Challenge yourself</div>
        <div style={{ color: '#ffffff' }}>Silicon Valley and beyond</div>
      </>,
      <>
        <div style={{ color: '#F66B0E' }}>Challenge yourself</div>
        <div style={{ color: '#ffffff' }}>Silicon Valley and beyond</div>
      </>,
      <>
        <div style={{ color: '#F66B0E' }}>Challenge yourself</div>
        <div style={{ color: '#ffffff' }}>Silicon Valley and beyond</div>
      </>,
    ],
    subtitle: [
      `링글 실리콘밸리 챌린지`,
      `Ringle Silicon Valley Challenge`,
      's_siliconvalley_challenge.section1.subtitle',
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
    ],
    date: [
      `06.08 (수) - 10.06 (목)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      's_siliconvalley_challenge.section1.date',
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
    ],
    participating: [
      `함께 도전하고 있는 수강생`,
      `Participants`,
      's_siliconvalley_challenge.section1.participating',
      `Participants`,
      `Participants`,
      `Participants`,
      `Participants`,
    ],
    count: [
      `명`,
      `Ringlers`,
      's_siliconvalley_challenge.section1.count',
      `Ringlers`,
      `Ringlers`,
      `Ringlers`,
      `Ringlers`,
    ],
    btn1: [
      `챌린지 참여 신청하기`,
      `Join Challenge`,
      's_siliconvalley_challenge.section1.btn1',
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
      `Join Challenge`,
    ],
    btn2: [
      `챌린지 참여 중`,
      `Challenge in Progress`,
      's_siliconvalley_challenge.section1.btn2',
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
    ],
  },
  section2: {
    title: [
      <>
        꾸준함은 영어를 극복하는
        <br />
        가장 빠른 길입니다.
      </>,
      <>
        Consistency is the key
        <br />
        to mastering English
      </>,
      's_siliconvalley_challenge.section2.title',
      <>
        Consistency is the key
        <br />
        to mastering English
      </>,
      <>
        Consistency is the key
        <br />
        to mastering English
      </>,
      <>
        Consistency is the key
        <br />
        to mastering English
      </>,
      <>
        Consistency is the key
        <br />
        to mastering English
      </>,
    ],
    subtitle: [
      <>
        실리콘밸리 탐방이라는 목표를 넘어
        <br />
        올 하반기 꾸준한 영어 공부를 위해 링글이 <br />
        <b>Learning Mate가 되어드릴게요.</b>
      </>,
      <>
        The Silicon Valley trip is only the beginning
        <br />
        Ringle is here to help you maintain
        <br />
        your study routine for the rest of 2022.
        <br />
        <b>Don't give up!</b>
      </>,
      's_siliconvalley_challenge.section2.subtitle',
      <>
        The Silicon Valley trip is only the beginning
        <br />
        Ringle is here to help you maintain
        <br />
        your study routine for the rest of 2022.
        <br />
        <b>Don't give up!</b>
      </>,
      <>
        The Silicon Valley trip is only the beginning
        <br />
        Ringle is here to help you maintain
        <br />
        your study routine for the rest of 2022.
        <br />
        <b>Don't give up!</b>
      </>,
      <>
        The Silicon Valley trip is only the beginning
        <br />
        Ringle is here to help you maintain
        <br />
        your study routine for the rest of 2022.
        <br />
        <b>Don't give up!</b>
      </>,
      <>
        The Silicon Valley trip is only the beginning
        <br />
        Ringle is here to help you maintain
        <br />
        your study routine for the rest of 2022.
        <br />
        <b>Don't give up!</b>
      </>,
    ],
  },
  section3: {
    title: [
      `나의 챌린지 현황`,
      `Challenge Board`,
      's_siliconvalley_challenge.section3.title',
      `Challenge Board`,
      `Challenge Board`,
      `Challenge Board`,
      `Challenge Board`,
    ],
    time1: [`40분`, `40min`, 's_siliconvalley_challenge.section3.time1', `40min`, `40min`, `40min`, `40min`],
    time2: [`20분`, `20min`, 's_siliconvalley_challenge.section3.time2', `20min`, `20min`, `20min`, `20min`],
    challenge_date: [
      `챌린지 기간`,
      `Challenge Period`,
      's_siliconvalley_challenge.section3.challenge_date',
      `Challenge Period`,
      `Challenge Period`,
      `Challenge Period`,
      `Challenge Period`,
    ],
    date_remaining: (day) => [
      <>{day}일 남음</>,
      <>{day} Days Left</>,
      's_siliconvalley_challenge.section3.date_remaining',
      <>{day} Days Left</>,
      <>{day} Days Left</>,
      <>{day} Days Left</>,
      <>{day} Days Left</>,
    ],
    lesson_no: [
      `진행 횟수`,
      `Lessons Completed`,
      's_siliconvalley_challenge.section3.lesson_no',
      `Lessons Completed`,
      `Lessons Completed`,
      `Lessons Completed`,
      `Lessons Completed`,
    ],
    lesson: (number) => [
      <>{number}회 </>,
      <>{number}Lessons</>,
      's_siliconvalley_challenge.section3.lesson',
      <>{number}Lessons</>,
      <>{number}Lessons</>,
      <>{number}Lessons</>,
      <>{number}Lessons</>,
    ],
    message1: [
      <>
        절대 늦지 않았어요. <br />
        차근차근 한 수업, 한 수업씩.
      </>,
      <>
        It's never too late
        <br />
        Take one lesson at a time
      </>,
      's_siliconvalley_challenge.section3.message1',
      <>
        It's never too late
        <br />
        Take one lesson at a time
      </>,
      <>
        It's never too late
        <br />
        Take one lesson at a time
      </>,
      <>
        It's never too late
        <br />
        Take one lesson at a time
      </>,
      <>
        It's never too late
        <br />
        Take one lesson at a time
      </>,
    ],
    message2: [
      <>
        링글 포인트는 수강기간 연장 <br />및 결제 시 현금처럼 사용가능해요.
      </>,
      <>
        Ringle points can be used like cash when <br />
        purchasing credits{' '}
      </>,
      's_siliconvalley_challenge.section3.message2',
      <>
        Ringle points can be used like cash when <br />
        purchasing credits{' '}
      </>,
      <>
        Ringle points can be used like cash when <br />
        purchasing credits{' '}
      </>,
      <>
        Ringle points can be used like cash when <br />
        purchasing credits{' '}
      </>,
      <>
        Ringle points can be used like cash when <br />
        purchasing credits{' '}
      </>,
    ],
    message3: [
      <>
        이제 챌린지 완성까지
        <br />
        정말로 얼마 안남았어요!
      </>,
      <>
        You are almost there, <br />
        only a few days left!
      </>,
      's_siliconvalley_challenge.section3.message3',
      <>
        You are almost there, <br />
        only a few days left!
      </>,
      <>
        You are almost there, <br />
        only a few days left!
      </>,
      <>
        You are almost there, <br />
        only a few days left!
      </>,
      <>
        You are almost there, <br />
        only a few days left!
      </>,
    ],
    message4: [
      <>
        축하드립니다! 노력한 시간만큼 <br />
        영어 실력으로 돌아올거에요.{' '}
      </>,
      <>
        Congratulations! Your efforts will not
        <br />
        betray your English results
      </>,
      's_siliconvalley_challenge.section3.message4',
      <>
        Congratulations! Your efforts will not
        <br />
        betray your English results
      </>,
      <>
        Congratulations! Your efforts will not
        <br />
        betray your English results
      </>,
      <>
        Congratulations! Your efforts will not
        <br />
        betray your English results
      </>,
      <>
        Congratulations! Your efforts will not
        <br />
        betray your English results
      </>,
    ],
    reward1: [
      <>
        실리콘밸리
        <br />
        여행 응모권
      </>,
      <>
        Silicon Valley
        <br />
        Raffle Ticket
      </>,
      's_siliconvalley_challenge.section3.reward1',
      <>
        Silicon Valley
        <br />
        Raffle Ticket
      </>,
      <>
        Silicon Valley
        <br />
        Raffle Ticket
      </>,
      <>
        Silicon Valley
        <br />
        Raffle Ticket
      </>,
      <>
        Silicon Valley
        <br />
        Raffle Ticket
      </>,
    ],
    additional_mission: [
      `추가 미션도 놓치지 마세요!`,
      `Extra missions for you!`,
      's_siliconvalley_challenge.section3.additional_mission',
      `Extra missions for you!`,
      `Extra missions for you!`,
      `Extra missions for you!`,
      `Extra missions for you!`,
    ],
    mission2: {
      title: [``, ``, 's_siliconvalley_challenge.section3.mission2.title', ``, ``, ``, ``],
      detail: [``, ``, 's_siliconvalley_challenge.section3.mission2.detail', ``, ``, ``, ``],
    },
    mission3: {
      text1: [
        `SNS 후기 인증하면`,
        `Post on social media`,
        's_siliconvalley_challenge.section3.mission3.text1',
        `Post on social media`,
        `Post on social media`,
        `Post on social media`,
        `Post on social media`,
      ],
      text2: [
        <>
          전원) 교보문고 2만원 상품권
          <br />
          추첨) 20분 수업권 3회 증정
        </>,
        <>
          All) Kyobo Book Giftcards
          <br />
          Raffle) Three 20-min-Credits{' '}
        </>,
        's_siliconvalley_challenge.section3.mission3.text2',
        <>
          All) Kyobo Book Giftcards
          <br />
          Raffle) Three 20-min-Credits{' '}
        </>,
        <>
          All) Kyobo Book Giftcards
          <br />
          Raffle) Three 20-min-Credits{' '}
        </>,
        <>
          All) Kyobo Book Giftcards
          <br />
          Raffle) Three 20-min-Credits{' '}
        </>,
        <>
          All) Kyobo Book Giftcards
          <br />
          Raffle) Three 20-min-Credits{' '}
        </>,
      ],
    },
    status_start: [
      `도전 전`,
      `Upcoming`,
      's_siliconvalley_challenge.section3.status_start',
      `Upcoming`,
      `Upcoming`,
      `Upcoming`,
      `Upcoming`,
    ],
    status_progress: [
      `도전 중`,
      `In Progress`,
      's_siliconvalley_challenge.section3.status_progress',
      `In Progress`,
      `In Progress`,
      `In Progress`,
      `In Progress`,
    ],
    status_success: [
      `도전성공`,
      `Completed`,
      's_siliconvalley_challenge.section3.status_success',
      `Completed`,
      `Completed`,
      `Completed`,
      `Completed`,
    ],
    status_expired: [
      `미션만료`,
      `Expired`,
      's_siliconvalley_challenge.section3.status_expired',
      `Expired`,
      `Expired`,
      `Expired`,
      `Expired`,
    ],
    status_upload: [
      `인증하기`,
      `Upload`,
      's_siliconvalley_challenge.section3.status_upload',
      `Upload`,
      `Upload`,
      `Upload`,
      `Upload`,
    ],
    status_completed: [
      `인증완료`,
      `Complete`,
      's_siliconvalley_challenge.section3.status_completed',
      `Complete`,
      `Complete`,
      `Complete`,
      `Complete`,
    ],
    reward1_no_br: [
      <>실리콘밸리 여행 응모권</>,
      <>Silicon Valley Raffle Ticket</>,
      's_siliconvalley_challenge.section3.reward1_no_br',
      <>Silicon Valley Raffle Ticket</>,
      <>Silicon Valley Raffle Ticket</>,
      <>Silicon Valley Raffle Ticket</>,
      <>Silicon Valley Raffle Ticket</>,
    ],
    mission1: {
      normal: (month, count, duration) => [
        <>
          {month}월 한 달 동안 {count}회 완강시 {duration}분 수업권 1회 추가 증정
        </>,
        <>
          Complete {count} lessons in {month} & receive {duration}-min credit
        </>,
        's_siliconvalley_challenge.section3.mission1.normal',
        <>
          Complete {count} lessons in {month} & receive {duration}-min credit
        </>,
        <>
          Complete {count} lessons in {month} & receive {duration}-min credit
        </>,
        <>
          Complete {count} lessons in {month} & receive {duration}-min credit
        </>,
        <>
          Complete {count} lessons in {month} & receive {duration}-min credit
        </>,
      ],
      oct: (n) => [
        `10월에 스탬프 ${n}개 채우면, 20분 수업권 1회 증정`,
        `Collect 6 stamps in October and get one 20-min credit`,
        's_siliconvalley_challenge.section3.mission1.oct',
        `Collect 6 stamps in October and get one 20-min credit`,
        `Collect 6 stamps in October and get one 20-min credit`,
        `Collect 6 stamps in October and get one 20-min credit`,
        `Collect 6 stamps in October and get one 20-min credit`,
      ],
      detail: [
        `*10월 수업 미션은 실리콘밸리 챌린지가 종료되는 10/6 이후에도 계속됩니다. (유의사항 참고)`,
        `*October Challenge continues even after the Silicon Valley Challenge ends on October 6.`,
        's_siliconvalley_challenge.section3.mission1.detail',
        `*October Challenge continues even after the Silicon Valley Challenge ends on October 6.`,
        `*October Challenge continues even after the Silicon Valley Challenge ends on October 6.`,
        `*October Challenge continues even after the Silicon Valley Challenge ends on October 6.`,
        `*October Challenge continues even after the Silicon Valley Challenge ends on October 6.`,
      ],
      oct_mw: (n) => [
        <>
          10월에 스탬프 {n}개<br />
          채우면, 20분 수업권 증정
        </>,
        <>
          Collect {n} stamps in Oct
          <br />& get one 20-min credit
        </>,
        's_siliconvalley_challenge.section3.mission1.oct_mw',
        <>
          Collect {n} stamps in Oct
          <br />& get one 20-min credit
        </>,
        <>
          Collect {n} stamps in Oct
          <br />& get one 20-min credit
        </>,
        <>
          Collect {n} stamps in Oct
          <br />& get one 20-min credit
        </>,
        <>
          Collect {n} stamps in Oct
          <br />& get one 20-min credit
        </>,
      ],
    },
    message5: {
      main: [
        `챌린지 달성을 축하드립니다 🎉`,
        `챌린지 달성을 축하드립니다 🎉`,
        's_siliconvalley_challenge.section3.message5.main',
        `챌린지 달성을 축하드립니다 🎉`,
        `챌린지 달성을 축하드립니다 🎉`,
        `챌린지 달성을 축하드립니다 🎉`,
        `챌린지 달성을 축하드립니다 🎉`,
      ],
      email: (email) => [
        <>{email}에서 신청서를 확인해 주세요.</>,
        <>{email}에서 신청서를 확인해 주세요.</>,
        's_siliconvalley_challenge.section3.message5.email',
        <>{email}에서 신청서를 확인해 주세요.</>,
        <>{email}에서 신청서를 확인해 주세요.</>,
        <>{email}에서 신청서를 확인해 주세요.</>,
        <>{email}에서 신청서를 확인해 주세요.</>,
      ],
      date: [
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
        's_siliconvalley_challenge.section3.message5.date',
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
        `마감일: 10월 14일 (금) 11:59PM (KST)`,
      ],
      mo: {
        main: [
          `챌린지 달성을 축하드립니다 🎉`,
          `챌린지 달성을 축하드립니다 🎉`,
          's_siliconvalley_challenge.section3.message5.mo.main',
          `챌린지 달성을 축하드립니다 🎉`,
          `챌린지 달성을 축하드립니다 🎉`,
          `챌린지 달성을 축하드립니다 🎉`,
          `챌린지 달성을 축하드립니다 🎉`,
        ],
        email: (email) => [
          <>{email}에서 신청서를 확인해 주세요.</>,
          <>{email}에서 신청서를 확인해 주세요.</>,
          's_siliconvalley_challenge.section3.message5.mo.email',
          <>{email}에서 신청서를 확인해 주세요.</>,
          <>{email}에서 신청서를 확인해 주세요.</>,
          <>{email}에서 신청서를 확인해 주세요.</>,
          <>{email}에서 신청서를 확인해 주세요.</>,
        ],
        date: [
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
          's_siliconvalley_challenge.section3.message5.mo.date',
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
          `마감일: 10월 14일 (금) 11:59PM (KST)`,
        ],
      },
    },
  },
  modal: {
    title: [
      `챌린지 신청에 동의하십니까?`,
      `Join the challenge?`,
      's_siliconvalley_challenge.modal.title',
      `Join the challenge?`,
      `Join the challenge?`,
      `Join the challenge?`,
      `Join the challenge?`,
    ],
    body: [
      <>
        [신청]을 누름과 동시에 챌린지 관련 정보 전달을 <br />
        위한 개인정보사용에 동의합니다.{' '}
      </>,
      <>
        Click [Accept] to join the challenge and agree to <br />
        the use of your personal information.{' '}
      </>,
      's_siliconvalley_challenge.modal.body',
      <>
        Click [Accept] to join the challenge and agree to <br />
        the use of your personal information.{' '}
      </>,
      <>
        Click [Accept] to join the challenge and agree to <br />
        the use of your personal information.{' '}
      </>,
      <>
        Click [Accept] to join the challenge and agree to <br />
        the use of your personal information.{' '}
      </>,
      <>
        Click [Accept] to join the challenge and agree to <br />
        the use of your personal information.{' '}
      </>,
    ],
    btn1: [`신청`, `Accept`, 's_siliconvalley_challenge.modal.btn1', `Accept`, `Accept`, `Accept`, `Accept`],
    btn2: [`취소`, `Cancel`, 's_siliconvalley_challenge.modal.btn2', `Cancel`, `Cancel`, `Cancel`, `Cancel`],
  },
  toast1: [
    `이미 챌린지를 신청하셨습니다. 챌린지 현황을 확인하세요!`,
    `You have already entered the challenge.`,
    's_siliconvalley_challenge.toast1',
    `You have already entered the challenge.`,
    `You have already entered the challenge.`,
    `You have already entered the challenge.`,
    `You have already entered the challenge.`,
  ],
  section4: {
    title: [
      `참여 방법`,
      `How to participate`,
      's_siliconvalley_challenge.section4.title',
      `How to participate`,
      `How to participate`,
      `How to participate`,
      `How to participate`,
    ],
    how1: [
      <>
        페이지 상단 <span className="text-purple-500">[챌린지 참여하기]</span>를<br />
        클릭하여 참여 신청하기
      </>,
      <>
        Click <span className="text-purple-500">Join Challenge</span>
        <br />
        to participate
      </>,
      's_siliconvalley_challenge.section4.how1',
      <>
        Click <span className="text-purple-500">Join Challenge</span>
        <br />
        to participate
      </>,
      <>
        Click <span className="text-purple-500">Join Challenge</span>
        <br />
        to participate
      </>,
      <>
        Click <span className="text-purple-500">Join Challenge</span>
        <br />
        to participate
      </>,
      <>
        Click <span className="text-purple-500">Join Challenge</span>
        <br />
        to participate
      </>,
    ],
    how2: [
      <>
        열심히 <span className="text-purple-500">수업 듣기!</span>
      </>,
      <>
        Book & <span className="text-purple-500">take your lessons!</span>
      </>,
      's_siliconvalley_challenge.section4.how2',
      <>
        Book & <span className="text-purple-500">take your lessons!</span>
      </>,
      <>
        Book & <span className="text-purple-500">take your lessons!</span>
      </>,
      <>
        Book & <span className="text-purple-500">take your lessons!</span>
      </>,
      <>
        Book & <span className="text-purple-500">take your lessons!</span>
      </>,
    ],
    how3: [
      <>
        [나의 챌린지 현황]에서
        <br />
        <div className="text-purple-500">진행 상황 확인하고 혜택 얻기</div>
      </>,
      <>
        <span className="text-purple-500">Check your achievements</span>
        <br />
        on Challenge Board & earn rewards
      </>,
      's_siliconvalley_challenge.section4.how3',
      <>
        <span className="text-purple-500">Check your achievements</span>
        <br />
        on Challenge Board & earn rewards
      </>,
      <>
        <span className="text-purple-500">Check your achievements</span>
        <br />
        on Challenge Board & earn rewards
      </>,
      <>
        <span className="text-purple-500">Check your achievements</span>
        <br />
        on Challenge Board & earn rewards
      </>,
      <>
        <span className="text-purple-500">Check your achievements</span>
        <br />
        on Challenge Board & earn rewards
      </>,
    ],
    mission2: {
      detail_mw: [
        `*10월 수업 미션은 10/6 이후에도 계속됩니다.`,
        `*This continues until the end of Oct.`,
        's_siliconvalley_challenge.section4.mission2.detail_mw',
        `*This continues until the end of Oct.`,
        `*This continues until the end of Oct.`,
        `*This continues until the end of Oct.`,
        `*This continues until the end of Oct.`,
      ],
    },
  },
  section5: {
    title: [
      `참여 혜택`,
      `Rewards`,
      's_siliconvalley_challenge.section5.title',
      `Rewards`,
      `Rewards`,
      `Rewards`,
      `Rewards`,
    ],
    reward1: [
      `혜택 1. 수업 횟수를 채운 분들께`,
      `1. Complete the required number of lessons`,
      's_siliconvalley_challenge.section5.reward1',
      `1. Complete the required number of lessons`,
      `1. Complete the required number of lessons`,
      `1. Complete the required number of lessons`,
      `1. Complete the required number of lessons`,
    ],
    detail1: [
      <>
        정해진 수업 횟수 달성 시 <br />
        누적 최대 49,000포인트 지급
      </>,
      <>You can receive up to ₩49,000 points</>,
      's_siliconvalley_challenge.section5.detail1',
      <>You can receive up to ₩49,000 points</>,
      <>You can receive up to ₩49,000 points</>,
      <>You can receive up to ₩49,000 points</>,
      <>You can receive up to ₩49,000 points</>,
    ],
    reward2: [
      `혜택 2. 추가 미션 달성 시`,
      `2. Complete extra missions`,
      's_siliconvalley_challenge.section5.reward2',
      `2. Complete extra missions`,
      `2. Complete extra missions`,
      `2. Complete extra missions`,
      `2. Complete extra missions`,
    ],
    detail2: [
      <>
        교보문고 상품권부터
        <br />
        최대 3회 정규수업권까지
      </>,
      <>
        You can receive Ringle credits
        <br />& Kyobo Book Giftcards
      </>,
      's_siliconvalley_challenge.section5.detail2',
      <>
        You can receive Ringle credits
        <br />& Kyobo Book Giftcards
      </>,
      <>
        You can receive Ringle credits
        <br />& Kyobo Book Giftcards
      </>,
      <>
        You can receive Ringle credits
        <br />& Kyobo Book Giftcards
      </>,
      <>
        You can receive Ringle credits
        <br />& Kyobo Book Giftcards
      </>,
    ],
    reward3: [
      `혜택 3. 챌린지를 완주한 5명에게!`,
      `3. Five Ringlers who completed the challenge`,
      's_siliconvalley_challenge.section5.reward3',
      `3. Five Ringlers who completed the challenge`,
      `3. Five Ringlers who completed the challenge`,
      `3. Five Ringlers who completed the challenge`,
      `3. Five Ringlers who completed the challenge`,
    ],
    detail3: [
      `실리콘밸리 5박 6일 투어 기회`,
      `A 6 day trip to Silicon Valley`,
      's_siliconvalley_challenge.section5.detail3',
      `A 6 day trip to Silicon Valley`,
      `A 6 day trip to Silicon Valley`,
      `A 6 day trip to Silicon Valley`,
      `A 6 day trip to Silicon Valley`,
    ],
    info3: [
      <>
        [포함내용] 항공권 + 숙박 + 프로그램 비용
        <br />
        실리콘밸리 빅테크 기업 오피스 투어 및 현직자 네트워킹
        <br />
        Stanford 등에서 재학 중인 튜터와의 만남
      </>,
      <>
        [Details] A round-trip plane ticket & accommodation
        <br />
        A guided tour & networking with Silicon Valley tech companies
        <br />
        Meet-and-greets with Standford tutors
      </>,
      's_siliconvalley_challenge.section5.info3',
      <>
        [Details] A round-trip plane ticket & accommodation
        <br />
        A guided tour & networking with Silicon Valley tech companies
        <br />
        Meet-and-greets with Standford tutors
      </>,
      <>
        [Details] A round-trip plane ticket & accommodation
        <br />
        A guided tour & networking with Silicon Valley tech companies
        <br />
        Meet-and-greets with Standford tutors
      </>,
      <>
        [Details] A round-trip plane ticket & accommodation
        <br />
        A guided tour & networking with Silicon Valley tech companies
        <br />
        Meet-and-greets with Standford tutors
      </>,
      <>
        [Details] A round-trip plane ticket & accommodation
        <br />
        A guided tour & networking with Silicon Valley tech companies
        <br />
        Meet-and-greets with Standford tutors
      </>,
    ],
  },
  section6: {
    title: [
      <>친구를 초대해{isMobile && <br />} 함께 도전해 보세요</>,
      <>
        Invite a friend and
        <br />
        start the challenge together
      </>,
      's_siliconvalley_challenge.section6.title',
      <>
        Invite a friend and
        <br />
        start the challenge together
      </>,
      <>
        Invite a friend and
        <br />
        start the challenge together
      </>,
      <>
        Invite a friend and
        <br />
        start the challenge together
      </>,
      <>
        Invite a friend and
        <br />
        start the challenge together
      </>,
    ],
    subtitle1: [
      <>
        혼자 공부하기가 어렵나요?
        <br />
        친구들과 함께 서로 경쟁하며 공부하고 <br />
        지원금까지 받아가세요!
      </>,
      <>
        Finding it difficult to study alone?
        <br />
        Study with friends for some healthy competition while receiving awards!
      </>,
      's_siliconvalley_challenge.section6.subtitle1',
      <>
        Finding it difficult to study alone?
        <br />
        Study with friends for some healthy competition while receiving awards!
      </>,
      <>
        Finding it difficult to study alone?
        <br />
        Study with friends for some healthy competition while receiving awards!
      </>,
      <>
        Finding it difficult to study alone?
        <br />
        Study with friends for some healthy competition while receiving awards!
      </>,
      <>
        Finding it difficult to study alone?
        <br />
        Study with friends for some healthy competition while receiving awards!
      </>,
    ],
    card1_text1: [
      `초대장을 받은 친구는`,
      `Friends who sign up with your`,
      's_siliconvalley_challenge.section6.card1_text1',
      `Friends who sign up with your`,
      `Friends who sign up with your`,
      `Friends who sign up with your`,
      `Friends who sign up with your`,
    ],
    card1_text2: [
      <>
        <b>회원가입을 하면 50,000 P</b>를 받아요.
      </>,
      <>
        referral code earn <b>₩50,000 Points</b>
      </>,
      's_siliconvalley_challenge.section6.card1_text2',
      <>
        referral code earn <b>₩50,000 Points</b>
      </>,
      <>
        referral code earn <b>₩50,000 Points</b>
      </>,
      <>
        referral code earn <b>₩50,000 Points</b>
      </>,
      <>
        referral code earn <b>₩50,000 Points</b>
      </>,
    ],
    card1_detail: [
      `회원 가입 기준: ‘본인 인증’ 완료`,
      `Must complete identity verification to sign up`,
      's_siliconvalley_challenge.section6.card1_detail',
      `Must complete identity verification to sign up`,
      `Must complete identity verification to sign up`,
      `Must complete identity verification to sign up`,
      `Must complete identity verification to sign up`,
    ],
    card2_text1: [
      `친구가 첫 수업을 완료하면`,
      <>
        You will earn <b>₩50,000 Points</b>
      </>,
      's_siliconvalley_challenge.section6.card2_text1',
      <>
        You will earn <b>₩50,000 Points</b>
      </>,
      <>
        You will earn <b>₩50,000 Points</b>
      </>,
      <>
        You will earn <b>₩50,000 Points</b>
      </>,
      <>
        You will earn <b>₩50,000 Points</b>
      </>,
    ],
    card2_text2: [
      <>
        <b>나도 50,000 P</b>를 받아요
      </>,
      `when your friend takes the first lesson`,
      's_siliconvalley_challenge.section6.card2_text2',
      `when your friend takes the first lesson`,
      `when your friend takes the first lesson`,
      `when your friend takes the first lesson`,
      `when your friend takes the first lesson`,
    ],
    card2_detail: [
      `첫 수업 기준: ‘20만원 이상’ 유료 수업 결제 후 수업`,
      `First lesson must be from a package over ₩200,000`,
      's_siliconvalley_challenge.section6.card2_detail',
      `First lesson must be from a package over ₩200,000`,
      `First lesson must be from a package over ₩200,000`,
      `First lesson must be from a package over ₩200,000`,
      `First lesson must be from a package over ₩200,000`,
    ],
    btn: [
      `친구에게 초대장 보내기`,
      `Invite a Friend`,
      's_siliconvalley_challenge.section6.btn',
      `Invite a Friend`,
      `Invite a Friend`,
      `Invite a Friend`,
      `Invite a Friend`,
    ],
    modal: {
      title: [
        `친구 초대 링크 복사 완료`,
        `Invite a Friend`,
        's_siliconvalley_challenge.section6.modal.title',
        `Invite a Friend`,
        `Invite a Friend`,
        `Invite a Friend`,
        `Invite a Friend`,
      ],
      body: [
        `친구 초대 링크가 복사되었습니다.  원하시는 곳에 붙여 넣어 주세요!`,
        `Invitation link is copied to your clipboard.`,
        's_siliconvalley_challenge.section6.modal.body',
        `Invitation link is copied to your clipboard.`,
        `Invitation link is copied to your clipboard.`,
        `Invitation link is copied to your clipboard.`,
        `Invitation link is copied to your clipboard.`,
      ],
      btn: [`확인`, `OK`, 's_siliconvalley_challenge.section6.modal.btn', `OK`, `OK`, `OK`, `OK`],
    },
  },
  section7: {
    title: [
      `링글 실리콘밸리 챌린지 공유하기`,
      `Share Ringle Silicon Valley Challenge`,
      's_siliconvalley_challenge.section7.title',
      `Share Ringle Silicon Valley Challenge`,
      `Share Ringle Silicon Valley Challenge`,
      `Share Ringle Silicon Valley Challenge`,
      `Share Ringle Silicon Valley Challenge`,
    ],
  },
  section8: {
    title: [`유의 사항`, `Terms`, 's_siliconvalley_challenge.section8.title', `Terms`, `Terms`, `Terms`, `Terms`],
    item1: [
      `[공통 안내]`,
      `[General Notice]`,
      's_siliconvalley_challenge.section8.item1',
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
    ],
    item1_body: [
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • 모든 일정은 한국 시간 기준입니다.
            <br />
            • 혜택 당첨자 공지는 발표일에 개별 안내해 드립니다.
            <br />
            • 잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.
            <br />
            • 당첨자 발표 후 7일 이내에 당첨자와 연락이 닿지 않는 경우 혜택 당첨이 취소되며 다른 사람에게 이관될 수
            있습니다.
            <br />
            • 제공된 혜택은 양도 및 다른 상품으로 교환이 불가합니다.
            <br />
            • 실리콘밸리 투어 혜택은 중복 제공되지 않습니다. (포인트/수업권 혜택은 중복 제공 가능)
            <br />• 해당 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • All dates and times are in Korean Standard Time.
            <br />
            • Those receiving awards will be notified individually.
            <br />
            • If you cannot be contacted due to your entry of incorrect personal information, you may not be able to
            receive an award.
            <br />
            • If we cannot reach you within 7 days after the announcement date, your award will be cancelled and may be
            transferred to someone else.
            <br />
            • Awards cannot be assigned or exchanged for another product.
            <br />
            • The Silicon Valley Tour cannot be rewarded twice. (Points/credits can be awarded more than once each)
            <br />• Ringle reserves the right to cancel or modify the event if it is not capable of completion as
            planned for any reason outside Ringle's control. In such case, Ringle will notify in advance.
          </Div>
        </Div>
      </>,
      's_siliconvalley_challenge.section8.item1_body',
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • All dates and times are in Korean Standard Time.
            <br />
            • Those receiving awards will be notified individually.
            <br />
            • If you cannot be contacted due to your entry of incorrect personal information, you may not be able to
            receive an award.
            <br />
            • If we cannot reach you within 7 days after the announcement date, your award will be cancelled and may be
            transferred to someone else.
            <br />
            • Awards cannot be assigned or exchanged for another product.
            <br />
            • The Silicon Valley Tour cannot be rewarded twice. (Points/credits can be awarded more than once each)
            <br />• Ringle reserves the right to cancel or modify the event if it is not capable of completion as
            planned for any reason outside Ringle's control. In such case, Ringle will notify in advance.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • All dates and times are in Korean Standard Time.
            <br />
            • Those receiving awards will be notified individually.
            <br />
            • If you cannot be contacted due to your entry of incorrect personal information, you may not be able to
            receive an award.
            <br />
            • If we cannot reach you within 7 days after the announcement date, your award will be cancelled and may be
            transferred to someone else.
            <br />
            • Awards cannot be assigned or exchanged for another product.
            <br />
            • The Silicon Valley Tour cannot be rewarded twice. (Points/credits can be awarded more than once each)
            <br />• Ringle reserves the right to cancel or modify the event if it is not capable of completion as
            planned for any reason outside Ringle's control. In such case, Ringle will notify in advance.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • All dates and times are in Korean Standard Time.
            <br />
            • Those receiving awards will be notified individually.
            <br />
            • If you cannot be contacted due to your entry of incorrect personal information, you may not be able to
            receive an award.
            <br />
            • If we cannot reach you within 7 days after the announcement date, your award will be cancelled and may be
            transferred to someone else.
            <br />
            • Awards cannot be assigned or exchanged for another product.
            <br />
            • The Silicon Valley Tour cannot be rewarded twice. (Points/credits can be awarded more than once each)
            <br />• Ringle reserves the right to cancel or modify the event if it is not capable of completion as
            planned for any reason outside Ringle's control. In such case, Ringle will notify in advance.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • All dates and times are in Korean Standard Time.
            <br />
            • Those receiving awards will be notified individually.
            <br />
            • If you cannot be contacted due to your entry of incorrect personal information, you may not be able to
            receive an award.
            <br />
            • If we cannot reach you within 7 days after the announcement date, your award will be cancelled and may be
            transferred to someone else.
            <br />
            • Awards cannot be assigned or exchanged for another product.
            <br />
            • The Silicon Valley Tour cannot be rewarded twice. (Points/credits can be awarded more than once each)
            <br />• Ringle reserves the right to cancel or modify the event if it is not capable of completion as
            planned for any reason outside Ringle's control. In such case, Ringle will notify in advance.
          </Div>
        </Div>
      </>,
    ],
    item2: [
      `[챌린지 참여 기준]`,
      `[Challenge Details]`,
      's_siliconvalley_challenge.section8.item2',
      `[Challenge Details]`,
      `[Challenge Details]`,
      `[Challenge Details]`,
      `[Challenge Details]`,
    ],
    item2_body: [
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • 챌린지 기간은 6/8(수)~10/6(목) 23:59까지입니다. (10/6(목) 23:30 시작하는 수업 정상 완료 시 스탬프 제공)
            <br />
            • 정규수업권으로 예약한 수업 정상 수강, 또는 해당 수업에 튜터의 피치 못할 사정으로 튜터 노쇼/수업 정각에
            튜터 미배정이 발생한 경우 스탬프를 제공합니다.
            <br />
            • 당일/긴급/시간한정수업권으로 진행한 수업은 스탬프를 제공하지 않습니다.
            <br />• 40분/20분 수업권을 교차 수강할 경우, 40분/20분 수업 수강 혜택이 개별 적용됩니다.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Challenge starts on 6/8 (Wed) and ends on 10/6 (Thur) at 23:59. (Lessons starting on 10/6 (Thur) at 23:30
            will be counted upon completion)
            <br />
            • Stamps can be collected for regular lessons booked and completed, for tutor no-show, or tutor unassigned
            at the beginning of the lesson.
            <br />
            • Stamps cannot be collected for lessons completed using Last-minute/FastBook/Limited-Window credits.
            <br />• 20-min and 40-min lessons count towards the challenge independently.
          </Div>
        </Div>
      </>,
      's_siliconvalley_challenge.section8.item2_body',
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Challenge starts on 6/8 (Wed) and ends on 10/6 (Thur) at 23:59. (Lessons starting on 10/6 (Thur) at 23:30
            will be counted upon completion)
            <br />
            • Stamps can be collected for regular lessons booked and completed, for tutor no-show, or tutor unassigned
            at the beginning of the lesson.
            <br />
            • Stamps cannot be collected for lessons completed using Last-minute/FastBook/Limited-Window credits.
            <br />• 20-min and 40-min lessons count towards the challenge independently.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Challenge starts on 6/8 (Wed) and ends on 10/6 (Thur) at 23:59. (Lessons starting on 10/6 (Thur) at 23:30
            will be counted upon completion)
            <br />
            • Stamps can be collected for regular lessons booked and completed, for tutor no-show, or tutor unassigned
            at the beginning of the lesson.
            <br />
            • Stamps cannot be collected for lessons completed using Last-minute/FastBook/Limited-Window credits.
            <br />• 20-min and 40-min lessons count towards the challenge independently.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Challenge starts on 6/8 (Wed) and ends on 10/6 (Thur) at 23:59. (Lessons starting on 10/6 (Thur) at 23:30
            will be counted upon completion)
            <br />
            • Stamps can be collected for regular lessons booked and completed, for tutor no-show, or tutor unassigned
            at the beginning of the lesson.
            <br />
            • Stamps cannot be collected for lessons completed using Last-minute/FastBook/Limited-Window credits.
            <br />• 20-min and 40-min lessons count towards the challenge independently.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Challenge starts on 6/8 (Wed) and ends on 10/6 (Thur) at 23:59. (Lessons starting on 10/6 (Thur) at 23:30
            will be counted upon completion)
            <br />
            • Stamps can be collected for regular lessons booked and completed, for tutor no-show, or tutor unassigned
            at the beginning of the lesson.
            <br />
            • Stamps cannot be collected for lessons completed using Last-minute/FastBook/Limited-Window credits.
            <br />• 20-min and 40-min lessons count towards the challenge independently.
          </Div>
        </Div>
      </>,
    ],
    item3: {
      title: [
        `[혜택 지급 및 사용 안내]`,
        `[Award Details]`,
        's_siliconvalley_challenge.section8.item3.title',
        `[Award Details]`,
        `[Award Details]`,
        `[Award Details]`,
        `[Award Details]`,
      ],
      list1: [
        `수업 수강 혜택`,
        `Lesson credit awards`,
        's_siliconvalley_challenge.section8.item3.list1',
        `Lesson credit awards`,
        `Lesson credit awards`,
        `Lesson credit awards`,
        `Lesson credit awards`,
      ],
      body1: [
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • 수업 수강 혜택 포인트는 챌린지 달성 시 자동 적립됩니다. (유효기간: 적립일 기준 1년)
              <br />• 참여 시점과 무관하게 챌린지 기간 내 진행한 수업이 있다면 혜택은 소급 적용됩니다.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Points for completing lessons will automatically be awarded upon completion of required lessons. (Points
              will be valid for 1 year from the date of award)
              <br />• Awards will be retroactively applied regardless of when lessons are completed if lessons are
              completed within challenge dates.
            </Div>
          </Div>
        </>,
        's_siliconvalley_challenge.section8.item3.body1',
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Points for completing lessons will automatically be awarded upon completion of required lessons. (Points
              will be valid for 1 year from the date of award)
              <br />• Awards will be retroactively applied regardless of when lessons are completed if lessons are
              completed within challenge dates.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Points for completing lessons will automatically be awarded upon completion of required lessons. (Points
              will be valid for 1 year from the date of award)
              <br />• Awards will be retroactively applied regardless of when lessons are completed if lessons are
              completed within challenge dates.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Points for completing lessons will automatically be awarded upon completion of required lessons. (Points
              will be valid for 1 year from the date of award)
              <br />• Awards will be retroactively applied regardless of when lessons are completed if lessons are
              completed within challenge dates.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Points for completing lessons will automatically be awarded upon completion of required lessons. (Points
              will be valid for 1 year from the date of award)
              <br />• Awards will be retroactively applied regardless of when lessons are completed if lessons are
              completed within challenge dates.
            </Div>
          </Div>
        </>,
      ],
      list2: [
        `추가 미션 혜택`,
        `Additional mission awards`,
        's_siliconvalley_challenge.section8.item3.list2',
        `Additional mission awards`,
        `Additional mission awards`,
        `Additional mission awards`,
        `Additional mission awards`,
      ],
      body2: [
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • 7월, 8월, 9월의 추가 미션 종료일은 매월 마지막 일 23:59PM까지입니다.
              <br />
              • 7월, 8월, 9월의 추가 미션 혜택 수업권은 챌린지 달성 시 링글 계정으로 자동 제공됩니다. (수강기간: 지급일
              기준 60일)
              <br />• SNS 후기가 인증되면 10/12(수)에 20,000원 교보문고 상품권이 발송됩니다.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        's_siliconvalley_challenge.section8.item3.body2',
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
      ],
      list3: [
        `실리콘밸리 투어 혜택`,
        `Silicon Valley tour award`,
        's_siliconvalley_challenge.section8.item3.list3',
        `Silicon Valley tour award`,
        `Silicon Valley tour award`,
        `Silicon Valley tour award`,
        `Silicon Valley tour award`,
      ],
      body3: [
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • 참여 시점과 무관하게 챌린지 기간에 40분 정규수업권 24회 또는 20분 정규수업권 48회를 정상 사용하면
              실리콘밸리 투어 신청 대상자에 자동 포함됩니다.
              <br />
              • 실리콘밸리 투어 조건 달성자에게는 10/7(금)에 별도 연락을 통해 신청 양식을 보내드립니다. (투어 신청 동기
              및 가능 일정 등)
              <br />• 실리콘밸리 투어 최종 선발자 5명은 10/19(수)에 별도 연락 후 링글 공식 SNS 채널을 통해 공지됩니다.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Regardless of when you begin the challenge, complete 24 40-min regular lesson credits or 48 20-min
              regular lesson credits to become automatically eligible to enter the Silicon Valley tour challenge. <br />
              • Those who complete above requirements for the Silicon Valley tour challenge will be individually
              contacted and provided with an application form. (Motivation for entering, preferred dates and etc.)
              <br />• The final 5 selected for the Silicon Valley tour will be individually contacted on 10/19 (Wed) and
              announced on Ringle's official social media channel.
            </Div>
          </Div>
        </>,
        's_siliconvalley_challenge.section8.item3.body3',
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Regardless of when you begin the challenge, complete 24 40-min regular lesson credits or 48 20-min
              regular lesson credits to become automatically eligible to enter the Silicon Valley tour challenge. <br />
              • Those who complete above requirements for the Silicon Valley tour challenge will be individually
              contacted and provided with an application form. (Motivation for entering, preferred dates and etc.)
              <br />• The final 5 selected for the Silicon Valley tour will be individually contacted on 10/19 (Wed) and
              announced on Ringle's official social media channel.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Regardless of when you begin the challenge, complete 24 40-min regular lesson credits or 48 20-min
              regular lesson credits to become automatically eligible to enter the Silicon Valley tour challenge. <br />
              • Those who complete above requirements for the Silicon Valley tour challenge will be individually
              contacted and provided with an application form. (Motivation for entering, preferred dates and etc.)
              <br />• The final 5 selected for the Silicon Valley tour will be individually contacted on 10/19 (Wed) and
              announced on Ringle's official social media channel.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Regardless of when you begin the challenge, complete 24 40-min regular lesson credits or 48 20-min
              regular lesson credits to become automatically eligible to enter the Silicon Valley tour challenge. <br />
              • Those who complete above requirements for the Silicon Valley tour challenge will be individually
              contacted and provided with an application form. (Motivation for entering, preferred dates and etc.)
              <br />• The final 5 selected for the Silicon Valley tour will be individually contacted on 10/19 (Wed) and
              announced on Ringle's official social media channel.
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Regardless of when you begin the challenge, complete 24 40-min regular lesson credits or 48 20-min
              regular lesson credits to become automatically eligible to enter the Silicon Valley tour challenge. <br />
              • Those who complete above requirements for the Silicon Valley tour challenge will be individually
              contacted and provided with an application form. (Motivation for entering, preferred dates and etc.)
              <br />• The final 5 selected for the Silicon Valley tour will be individually contacted on 10/19 (Wed) and
              announced on Ringle's official social media channel.
            </Div>
          </Div>
        </>,
      ],
      mo_body2: [
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • 7월, 8월, 9월의 추가 미션 종료일은 매월 마지막 일 23:59PM까지입니다.
              <br />
              • 7월, 8월, 9월의 추가 미션 혜택 수업권은 챌린지 달성 시 링글 계정으로 자동 제공됩니다. (수강기간: 지급일
              기준 60일)
              <br />
              • SNS 후기가 인증되면 10/12(수)에 20,000원 교보문고 상품권이 발송됩니다.
              <br />
              • 10월 추가 미션은 실리콘밸리 챌린지가 종료되는 10/6 이후에도 10/31(월) 23:59PM까지 계속됩니다.
              <br />
              • 10월 추가 미션은 (1) 10월 동안 첫 수업권 구매 후 7일 내 첫 수업 시작 시, (2) 수업 정상 완료 시, (3)
              일주일에 1회 이상 4주 연속 수업을 들었을 때 [10월 미션] 스탬프 1개가 추가되며, 20분 수업은 스탬프 8개,
              40분 수업은 스탬프 6개를 받으면 20분 수업권이 링글 계정으로 자동 제공됩니다. (수강기간: 지급일 기준 14일)
              <br />
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />
              • Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to you
              on 10/12 (Wed).
              <br />
              • The monthly challenge for October can be completed by 23:59 PM, October 31 (Mon), even after the Silicon
              Valley Challenge ends on October 6.
              <br />• For the monthly challenge for October, you can get one stamp (1) when you take your first lesson
              within 7 days from purchase, (2) when you complete a lesson, (3) when you take at least one lesson for 4
              consecutive weeks. You will be automatically rewarded one 20-min credit after you receive 8 stamps for
              20-min lessons or 6 stamps for 40-min lessons (The rewarded credit will be valid for 14 days from
              issuance.)
            </Div>
          </Div>
        </>,
        's_siliconvalley_challenge.section8.item3.mo_body2',
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
        <>
          <Div ulTag listDisc>
            <Div liTag>
              • Additional missions in July, August and September close at 23:59 on the last day of each month.
              <br />
              • Additional mission award credits for July, August and September will automatically be awarded upon
              completion of required lessons. (Credits will be valid for 60 days)
              <br />• Upon verification of your social media review, a Kyobo book coupon worth 20,000KRW will be sent to
              you on 10/12 (Wed).
            </Div>
          </Div>
        </>,
      ],
    },
    item4: [
      `[개인 정보 수집 및 이용 안내]`,
      `[Collection and use of personal information]`,
      's_siliconvalley_challenge.section8.item4',
      `[Collection and use of personal information]`,
      `[Collection and use of personal information]`,
      `[Collection and use of personal information]`,
      `[Collection and use of personal information]`,
    ],
    item4_body: [
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • 링글은 혜택 당첨자 선정 및 발표 목적으로 개인 정보(성함, 연락처, 배송지)를 수집·이용합니다.
            <br />
            • 챌린지 참여자는 개인 정보 수집 및 이용에 대해 거부할 권리를 가지고 있으며, 이를 거부할 시 혜택 당첨에서
            제외됩니다.
            <br />• 수집된 정보는 혜택 당첨자 선정 및 발표, 상품 제공을 위해서만 이용되며, 목적 달성 시 파기됩니다.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Ringle will collect and use personal information (name, contact information, address) to select and
            announce awards.
            <br />
            • Entrants retain the right to refuse collection and use of personal information, entrants will not be
            eligible for awards upon such refusal.
            <br />• Collected information will only be used to select, announce and distribute awards, and will be
            destroyed upon such purpose being achieved.
          </Div>
        </Div>
      </>,
      's_siliconvalley_challenge.section8.item4_body',
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Ringle will collect and use personal information (name, contact information, address) to select and
            announce awards.
            <br />
            • Entrants retain the right to refuse collection and use of personal information, entrants will not be
            eligible for awards upon such refusal.
            <br />• Collected information will only be used to select, announce and distribute awards, and will be
            destroyed upon such purpose being achieved.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Ringle will collect and use personal information (name, contact information, address) to select and
            announce awards.
            <br />
            • Entrants retain the right to refuse collection and use of personal information, entrants will not be
            eligible for awards upon such refusal.
            <br />• Collected information will only be used to select, announce and distribute awards, and will be
            destroyed upon such purpose being achieved.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Ringle will collect and use personal information (name, contact information, address) to select and
            announce awards.
            <br />
            • Entrants retain the right to refuse collection and use of personal information, entrants will not be
            eligible for awards upon such refusal.
            <br />• Collected information will only be used to select, announce and distribute awards, and will be
            destroyed upon such purpose being achieved.
          </Div>
        </Div>
      </>,
      <>
        <Div ulTag listDisc>
          <Div liTag>
            • Ringle will collect and use personal information (name, contact information, address) to select and
            announce awards.
            <br />
            • Entrants retain the right to refuse collection and use of personal information, entrants will not be
            eligible for awards upon such refusal.
            <br />• Collected information will only be used to select, announce and distribute awards, and will be
            destroyed upon such purpose being achieved.
          </Div>
        </Div>
      </>,
    ],
  },
}
export const t_pricing = {
  title: [`수업권 구매`, `Purchase`, 't_pricing.title', ``, ``, ``, ``],
  dropdown_jpy: [`JPY`, `JPY`, 't_pricing.dropdown_jpy', ``, ``, ``, ``],
  dropdown_twd: [`TWD`, `TWD`, 't_pricing.dropdown_twd', ``, ``, ``, ``],
  dropdown_usd: [`USD`, `USD`, 't_pricing.dropdown_usd', ``, ``, ``, ``],
  dropdown_krw: [`KRW`, `KRW`, 't_pricing.dropdown_krw', ``, ``, ``, ``],
  dropdown_vnd: [`VND`, `VND`, 't_pricing.dropdown_vnd', ``, ``, ``, ``],
  dropdown_cny: [`CNY`, `CNY`, 't_pricing.dropdown_cny', ``, ``, ``, ``],
  duration: [`기간`, `Duration`, 't_pricing.duration', ``, ``, ``, ``],
  monthly: [`월간 요금제`, `Monthly Plans`, 't_pricing.monthly', ``, ``, ``, ``],
  subtitle: [
    `이 상품은 매달 정기적으로 결제가 진행되는 상품입니다.`,
    `Billed automatically on the same date/time every month`,
    't_pricing.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  buy: [`구매하기`, `Purchase`, 't_pricing.buy', ``, ``, ``, ``],
  detail: [`자세히 보기`, `View Detail`, 't_pricing.detail', ``, ``, ``, ``],
  first: [`첫 구매 혜택`, `First Purchase`, 't_pricing.first', ``, ``, ``, ``],
  credit_info: [`수업권 선택 방법`, `Credits Detail`, 't_pricing.credit_info', ``, ``, ``, ``],
  length: [`수업길이`, `Lesson duration`, 't_pricing.length', ``, ``, ``, ``],
  length_info: [
    `수업 진행 방식과 사용하는 콘텐츠는 동일`,
    `Lesson format and available materials are the same`,
    't_pricing.length_info',
    ``,
    ``,
    ``,
    ``,
  ],
  about_40: [
    <>
      하나의 주제에 대해 심도 있게!
      <br />
      최신 트렌드부터 북챗까지
      <br />
      지적 대화를 레벨업하는 40분 수업
    </>,
    `A 40-minute lesson is great for those who want an in-depth discussion with ample time to interact with the tutor.`,
    't_pricing.about_40',
    ``,
    ``,
    ``,
    ``,
  ],
  about_20: [
    <>
      짧지만 확실하게!
      <br />
      부담스러운 예습 시간은 줄이고
      <br />
      일상 대화 능력을 향상시키는
      <br />
      20분 수업
    </>,
    `A 20-minute lesson is great for those who want a short and concentrated lesson amidst busy schedules.`,
    't_pricing.about_20',
    ``,
    ``,
    ``,
    ``,
  ],
  time: [`시간대`, `Times`, 't_pricing.time', ``, ``, ``, ``],
  time_info: [`수업 시작 기준`, `Lesson starting time`, 't_pricing.time_info', ``, ``, ``, ``],
  all_time: [`정규`, `Regular`, 't_pricing.all_time', ``, ``, ``, ``],
  time_east: [`오후 12시 ~ 오후 11시 (EST)`, `12PM - 11PM (EST)`, 't_pricing.time_east', ``, ``, ``, ``],
  time_west: [`오전 9시 ~ 오후 8시 (PST)`, `9AM - 8PM (PST)`, 't_pricing.time_west', ``, ``, ``, ``],
  guarantee: [`링글이 보장합니다`, `We guarantee Ringle is worth your time`, 't_pricing.guarantee', ``, ``, ``, ``],
  refund_1: [
    <>
      첫 1회 수업 불만족 시 100% 환불
      <br />첫 수업 시작 전 수강 기간 미차감
      <br />
      수업 하루 전까지 스케줄 변경 가능
    </>,
    <>
      100% Money-back guarantee after the first lesson
      <br />
      Expiration begins after the first lesson
      <br />
      Reschedule lesson anytime up to 24 hours
    </>,
    't_pricing.refund_1',
    <>
      前 1 次课程如果不满意 100% 退款
      <br />
      第一节课开始之前不扣除听课时间
      <br />
      截止到课程前一天可变更日程
    </>,
    ``,
    ``,
    ``,
  ],
  refund_2: [
    <>
      수업권 구매 시 수강기간 연장 쿠폰 증정
      <br />첫 수업 시작 전 수강 기간 미차감
      <br />
      수업 하루 전까지 스케줄 변경 가능
    </>,
    <>
      Special coupon for extending lesson days
      <br />
      Expiration begins after the first lesson
      <br />
      Reschedule lesson anytime up to 24 hours
    </>,
    't_pricing.refund_2',
    <>
      购买课程券时赠送延长听课时间优惠券
      <br />
      第一节课开始之前不扣除听课时间
      <br />
      截止到课程前一天可变更日程
    </>,
    ``,
    ``,
    ``,
  ],
  makeup_lesson: [
    `수업 2시간 전까지 변경 시, 보충 수업권 제공`,
    `*Cancel up to 24 hours before the lesson`,
    't_pricing.makeup_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit: [`수강 혜택`, `Benefits`, 't_pricing.benefit', ``, ``, ``, ``],
  benefit_list_1: {
    default: [
      `매달 업데이트되는 최신 콘텐츠`,
      `New learning content updated monthly`,
      't_pricing.benefit_list_1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [`150+종의 링글`, `150+ Ringle Teens`, 't_pricing.benefit_list_1.desc1', ``, ``, ``, ``],
    desc2: [`매달 업데이트되는`, `Monthly Updates`, 't_pricing.benefit_list_1.desc2', ``, ``, ``, ``],
    desc3: [`매 수업 주어지는`, `After-lesson Homework`, 't_pricing.benefit_list_1.desc3', ``, ``, ``, ``],
    title1: [`오리지널 콘텐츠`, `Original Content`, 't_pricing.benefit_list_1.title1', ``, ``, ``, ``],
    title2: [`틴즈 전용 교재`, `Lesson Materials for Teens`, 't_pricing.benefit_list_1.title2', ``, ``, ``, ``],
    title3: [
      `작문 숙제 및 피드백`,
      <>
        Writing Assignment
        <br />
        and Feedback
      </>,
      't_pricing.benefit_list_1.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    caption1: [
      `PDF 파일 / MP3 오디오북 다운로드 지원`,
      `PDF/MP3 Audiobook provided`,
      't_pricing.benefit_list_1.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  benefit_list_2: {
    default: [
      `AI가 기록하는 복습 시스템`,
      `AI-supported review system`,
      't_pricing.benefit_list_2.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [`매 수업 종료 후 제공`, `Detailed Tutor Feedback`, 't_pricing.benefit_list_2.desc1', ``, ``, ``, ``],
    desc2: [`AI 기반 분석 시스템`, `AI Review system's`, 't_pricing.benefit_list_2.desc2', ``, ``, ``, ``],
    desc3: [`별도 제공`, `Additional Feature`, 't_pricing.benefit_list_2.desc3', ``, ``, ``, ``],
    title1: [
      `학생 및 부모 피드백`,
      `Feedback for Learners and Parents`,
      't_pricing.benefit_list_2.title1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`말하기 패턴 분석 자료`, `Speech pattern analysis`, 't_pricing.benefit_list_2.title2', ``, ``, ``, ``],
    title3: [
      `개별 첨삭 수업노트`,
      <>
        Lesson Notes for
        <br />
        Personalized Learning
      </>,
      't_pricing.benefit_list_2.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    caption1: [
      `모든 수업 녹음파일 무제한 듣기`,
      `Exclusive Lesson Materials`,
      't_pricing.benefit_list_2.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
    caption2: [
      `말하기, 듣기, 읽기, 쓰기 영역별 학습 데이터`,
      `Detailed feedback on vocabulary, fluency, grammar, and pronunciation`,
      't_pricing.benefit_list_2.caption2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice: [`유의사항`, `Terms`, 't_pricing.notice', ``, ``, ``, ``],
  recurring_info: {
    default: [`정기 결제 유의사항`, `Recurring payment policy`, 't_pricing.recurring_info.default', ``, ``, ``, ``],
    desc1: [
      `정기 결제 신청 시, 매월 같은 날에 자동으로 결제됩니다.`,
      `You are automatically charged for your monthly plan on the same date every month.`,
      't_pricing.recurring_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `정기 결제일은 최초로 수업권을 결제하는 일자로 자동 설정됩니다.`,
      `Your billing date corresponds to the day you purchased your monthly plan.`,
      't_pricing.recurring_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2_add: [
      `다만 특정 월에 결제일이 존재하지 않는 경우, 해당 월에 한하여 말일에 결제됩니다. (예를 들어, 1월 31일 가입자의 정기 결제일은 2월 28일, 3월 31일, 4월 30일 등)`,
      `If the day of your billing date does not occur in every month, you will be billed on the last day of that month instead. (For example, if you purchased your plan on Jan 31, your next payment will be on Feb 28, and subsequently on Mar 31, Apr 30, etc.)`,
      't_pricing.recurring_info.desc2_add',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `원화 및 달러를 제외한 통화의 경우, 달러 기준 결제 당시 환율(결제사 기준)로 계산하여 청구 됩니다. 사용하시는 은행이나 카드사에 따라 환율 및 해외 결제 수수료가 달라 실제 원화로 청구된 금액에는 차이가 발생할 수 있습니다.`,
      `For any currencies other than KRW or USD, you will be charged based on the USD exchange rate at the point of transaction. Your total in KRW may show a slight increase/decrease due to the different exchange rates or transaction fees used by different banks or credit card issuers.`,
      't_pricing.recurring_info.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      `다음 달 정기 결제 취소는 현지 시간 기준 결제일 "8AM” 까지 가능합니다.`,
      `If you cancel your plan before 8am on the billing date, you will not be charged after your current billing cycle.`,
      't_pricing.recurring_info.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc5: [
      `정기 결제가 결제수단 등의 문제로 늦어지면, 최종 결제가 이루어지는 시점 기준으로 정기 결제일이 재설정 됩니다.`,
      `If the payment is made after the billing date, your billing date will be changed to the date on which the payment went through.`,
      't_pricing.recurring_info.desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc6: [
      `정기 결제 실패 시 7일 후 자동 결제를 1회 시도합니다. 최종적으로 실패되면 이용 중단 상태로 최종 변경됩니다. 내 플랜 > 결제 정보 변경 에서 유효한 결제 수단으로 정기 결제 정보를 갱신하면 보류 중인 정기 결제가 재개됩니다.`,
      `After any failed transaction, you will be charged again seven days later. If the second attempt also fails, we will pause your monthly plan. Update your payment information on [My Plans] > [Edit Payment] with a viable payment option to resume your plan.`,
      't_pricing.recurring_info.desc6',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  refund_info: {
    default: [`환불정책`, `Refund policy`, 't_pricing.refund_info.default', ``, ``, ``, ``],
    desc1: [
      `환불은 고객센터 [1:1 문의]를 통해서만 가능합니다.`,
      `Refunds are only available via [Chat] with our Help Center.`,
      't_pricing.refund_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `전액환불: 사용한 수업권 없을 경우, 결제 시점으로 1개월 이내 전액 환불 가능합니다.`,
      `Full refund: An unused plan is eligible for a full refund within one month following the date of payment.`,
      't_pricing.refund_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `부분환불: 1) 결제 시점으로부터 15일 이내 2) 지급된 수업권을 절반 이하로 사용한 경우, [실결제금액]에서 10% 위약금을 제하고 [기이용수업권] 비중만큼 차감하여 지급합니다. (두 조건이 모두 충족되어야 합니다)`,
      `Partial refund: A used plan is eligible for a refund amount equal to the [total amount paid] minus 10% fee minus [the value of used credits] if 1) 15 days or less have lapsed since the date of payment, and 2) half or less of the credits have been used.`,
      't_pricing.refund_info.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_add: [
      `(예: 수강기간 15일이 남고 수업권이 절반 이상 남은 경우, 환불 가능합니다)`,
      `(Example: If you have half or more of the credits remaining and 15 days or more left until expiration, you're eligible for a refund.)`,
      't_pricing.refund_info.desc3_add',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_add2: [
      `사용한 수업권이 있을 경우, 결제 이후 16일이 되는 시점부터 환불이 불가합니다.`,
      `If you have used a credit or more from your plan, refund becomes unavailable from the 16th day following the date of payment.`,
      't_pricing.refund_info.desc3_add2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      `환불시 은행 및 카드 결제 수단의 수수료는 차감되고 환불될 수 있습니다.`,
      `No refunds are available for any transaction fees charged by your bank or credit card issuer.`,
      't_pricing.refund_info.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc5: [
      `정기 결제시 사용한 결제 수단과 다른 방식으로 환불할 경우 발생된 수수료는 반환되지 않습니다.`,
      `No refunds are available for any transaction fees charged for the refund made to a card/bank account different from the original payment method.`,
      't_pricing.refund_info.desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc6: [
      `수강기간이 연장된 수업권은 환불이 불가합니다.`,
      `No refunds are available for extended credits.`,
      't_pricing.refund_info.desc6',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit_subtitle: [
    `이 상품은 매달 정기적으로 결제가 진행되는 상품입니다.`,
    `Billed automatically on the same date/time every month`,
    't_pricing.credit_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  payment_info: {
    desc1: [
      `매월 동일 일자에 자동 결제 되도록 설정됩니다.`,
      `You will be automatically charged on the same date every month.`,
      't_pricing.payment_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `첫 결제일이 31일 이라면, 정기 결제는 매달 말일로 설정됩니다.`,
      `If you purchased the plan on the 31st, you will be charged on the last day of shorter months.`,
      't_pricing.payment_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_times: [`수업 시간`, `Lesson Times`, 't_pricing.lesson_times', ``, ``, ``, ``],
  per_month: (product_name) => [
    `${product_name} / 월`,
    `${product_name} / month`,
    't_pricing.per_month',
    ``,
    ``,
    ``,
    ``,
  ],
  total_today: (regular_price) => [
    `오늘의 금액 합계 ("${regular_price}/월. 요금제")`,
    `Your total today (for a "${regular_price}/mo. plan")`,
    't_pricing.total_today',
    ``,
    ``,
    ``,
    ``,
  ],
  only_applied_today: [
    `첫 구매 할인은 오늘 지불한 총 결제액에만 적용되며, 향후에는 자동으로 패키지 구매 할인만 적용된 금액이 청구됩니다.`,
    `The first purchase discount will only be applied for your total payment today, and you will be automatically charged the package discounted price onwards.`,
    't_pricing.only_applied_today',
    ``,
    ``,
    ``,
    ``,
  ],
  tab: {
    precaution: [`유의사항`, `Terms`, 't_pricing.tab.precaution', `注意事项`, `注意事項`, `注意事項`, `Điều khoản`],
  },
  including_trial: [
    `첫 구매 혜택도 첫 수업에 포함됨`,
    `*Includes trial lesson.`,
    't_pricing.including_trial ',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_1: [
    `첫 수업 시작 전까지`,
    `Lesson days are frozen until first lesson`,
    't_pricing.not_opened_1',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_2: [`수강기간 미차감`, ``, 't_pricing.not_opened_2', ``, ``, ``, ``],
  additional_extend: [
    `구매 1년 후 수강기간 자동 차감`,
    `Buy now and start later`,
    't_pricing.additional_extend',
    `可延长课程期限`,
    `可額外延長聽課期間`,
    `チケット有効期限は延長可能`,
    `Mua ngay và học sau`,
  ],
  guarantee3: [`링글이 보장합니다 3`, `Ringle guarantees 3`, 't_pricing.guarantee3', ``, ``, ``, ``],
  qna: {
    title: [`자주 묻는 질문`, `Frequently Asked Questions`, 't_pricing.qna.title', ``, ``, ``, ``],
    list1: {
      main: [
        `어떤 수업권을 선택하면 될까요?`,
        `What type of credit is recommended?`,
        't_pricing.qna.list1.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `20분과 40분 수업권은 수업 길이에만 차이가 있을 뿐, 진행 방식, 교재, 튜터 선택지는 모두 동일합니다.`,
        `The 20-minute and 40-minute lessons differ only in length of the lessons. The teaching method, textbooks, and tutor options are all identical.`,
        't_pricing.qna.list1.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `20분 수업은 비교적 가벼운 주제로 꾸준한 영어 말하기 습관을 형성하는데 추천하는 수업입니다.`,
        `The 20-minute lesson is recommended for those who are seeking to form a consistent habit of speaking in English with relatively lighter topics.`,
        't_pricing.qna.list1.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `40분 수업은 특정 주제에 대해 심도 있게 대화하거나, 인터뷰/Writing 첨삭에 추천하는 수업입니다.`,
        `The 40-minute class is recommended for those who want to have in-depth conversations on specific topics or for interviews/writing corrections.`,
        't_pricing.qna.list1.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list2: {
      main: [
        `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
        `Is there a set time/number of times to book classes?`,
        't_pricing.qna.list2.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `1:1 수업권은 횟수제로 제공되며, 구매하신 수업권 횟수만큼 수강기간 내 자유롭게 수강할 수 있습니다. 일 별 또는 주 별로 수강할 수 있는 수업 횟수에 제한이 없습니다.`,
        `1:1 lessons are offered on a frequency basis, and you can take as many classes as you like during the course period. There is no limit to the number of classes you can take per day or per week.`,
        't_pricing.qna.list2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `정규 수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Regular lessons start 24 hours from the time of reservation, and you can choose tutors and textbooks freely.`,
        't_pricing.qna.list2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `당일 수업은 예약시점 기준 24시간 이내에 시작하는 수업으로, 수업 1시간 전까지 교재 선정이 가능합니다.`,
        `Last-minute lessons start within 24 hours from the time of reservation, and textbooks can be selected 1 hour before class.`,
        't_pricing.qna.list2.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `[정규 수업권]은 정규 시간 내 자유롭게 이용가능하며, 당일 수업 예약에도 사용할 수 있습니다.`,
        `[Regular lesson credits] can be freely used during regular hours, and can also be used for Last-minute lesson reservations.`,
        't_pricing.qna.list2.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        `링글 틴즈 수업은 주중/주말 연중무휴로 아래 시간에 진행됩니다.`,
        `Ringle Teens classes are held on weekdays/weekends throughout the year during the times listed below.`,
        't_pricing.qna.list2.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
      desc6: [
        `오전 5AM ~ 밤 10PM (미국 동부 시간 기준EST)`,
        `5AM to 10PM (EST)`,
        't_pricing.qna.list2.desc6',
        ``,
        ``,
        ``,
        ``,
      ],
      desc7: [
        `오전 2AM ~ 저녁 7PM (미국 서부 시간 기준PST)`,
        `2AM to 7PM in the evening (PST)`,
        't_pricing.qna.list2.desc7',
        ``,
        ``,
        ``,
        ``,
      ],
      desc8: [
        `오후 7PM ~ 익일 오후 12:00 PM (한국 시간 기준KST)`,
        `7 PM ~ 12:00 PM the next day (KST)`,
        't_pricing.qna.list2.desc8',
        ``,
        ``,
        ``,
        ``,
      ],
      desc9: [
        `[당일수업권]이란 예약 시점 기준 24시간 이내에 시작하는 [당일 수업]만 예약할 수 있는 수업권으로, [1:1 수업] → [예약] → [당일 수업]에서 사용하실 수 있습니다.`,
        `[Last-minute lesson credit] is a class ticket that can only be reserved for [Last-minute lessons] that start within 24 hours from the time of reservation. [1:1 class] → [Reservation] → You can use it in [Last-minute lessons].`,
        't_pricing.qna.list2.desc9',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list3: {
      main: [
        `수업권의 수강기간이 궁금해요.`,
        `How long is the course period for lesson credits?`,
        't_pricing.qna.list3.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다.`,
        `The course period is deducted from the point of completion of the first class.`,
        't_pricing.qna.list3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다. 따라서, 수업권 구매 후 1년 내 원하시는 시점에 수업 시작이 가능합니다.`,
        `However, if you do not use your lesson credits up to one year upon purchase, the course period will be automatically deducted. Therefore, you can start your lessons at any time you want within one year of purchasing lesson credits.`,
        't_pricing.qna.list3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
        `The criterion of the course period is the time of completion of the course, not the time of booking the course.`,
        't_pricing.qna.list3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list4: {
      main: [`수업 취소 정책이 궁금해요.`, `How do I cancel lessons?`, 't_pricing.qna.list4.main', ``, ``, ``, ``],
      desc1: [
        `수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
        `Cancellations made more than 24 hours before the lesson will be refundable and restore the lesson credit.`,
        't_pricing.qna.list4.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.`,
        `Cancellations made 2-24 hours before the lesson will replace the credit used with a Last-minute credit.`,
        't_pricing.qna.list4.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
        `Cancellations made less than 2 hours before the lesson won't be refundable`,
        't_pricing.qna.list4.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list5: {
      main: [`수업권 환불 정책이 궁금해요.`, `How do I get a refund?`, 't_pricing.qna.list5.main', ``, ``, ``, ``],
      desc1: [
        `수업권 사용 이력이 없는 경우 전액 환불이 가능합니다.`,
        `Unused packages (none of the credits has been used) are eligible for a full refund`,
        't_pricing.qna.list5.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `첫 수업 진행 후 불만족 시, 전액 환불이 가능합니다. (무료 수업권 및 체험 수업 포함)`,
        `If your first lesson is unsatisfactory, you are able to get a full refund (including free and trial lessons)`,
        't_pricing.qna.list5.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        <>
          그 외 사용 이력이 있는 수업권은{' '}
          <LinkLocale href={urls.student.policy.terms} className="text-purple-500" target="_blank">
            이용약관 13조 및 15조
          </LinkLocale>
          에 의거하여 환불 처리합니다.{' '}
        </>,
        <>
          Refunds for credit(s) that have been used or deducted will be proceeded according to{' '}
          <LinkLocale href={urls.student.policy.terms} className="text-purple-500" target="_blank">
            Ringle Teens’ Terms of Service
          </LinkLocale>
        </>,
        't_pricing.qna.list5.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `수강기간 또는 수업 횟수가 절반 이하 남은 수업권, 수강기간 연장 이력이 있는 수업권은 환불이 불가합니다.`,
        `No refund is offered for credits starting halfway through the expiration dates or for half-used or more packages and credit(s) with extended expiration dates`,
        't_pricing.qna.list5.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        `환불 금액 산정 시, 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료가 차감될 수 있습니다.`,
        `In the case of refunds, any transaction fees incurred from your bank, credit card or Paypal will be deducted from the refund amount.`,
        't_pricing.qna.list5.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  min_40: [`40분`, `40min`, 't_pricing.min_40', ``, ``, ``, ``],
  min_20: [`20분`, `20min`, 't_pricing.min_20', ``, ``, ``, ``],
  pay_in_pieces: [`2개 카드 분할 결제 가능`, ``, 't_pricing.pay_in_pieces', ``, ``, ``, ``],
  interest_free: [`최대 7개월 무이자 할부`, ``, 't_pricing.interest_free', ``, ``, ``, ``],
  notice_usd: [
    ``,
    `The price shown in your local currency reflects today's exchange rate.`,
    't_pricing.notice_usd',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_exchange: [
    ``,
    `The final transaction price may differ due to the currency exchange rate
and processing fee applied by your credit card company.`,
    't_pricing.notice_exchange',
    ``,
    ``,
    ``,
    ``,
  ],
  per_credit: [`/크레딧`, `per credit`, 't_pricing.per_credit', ``, ``, ``, ``],
}
export const t_purchase = {
  dates: [`날짜들`, `Dates`, 't_purchase.dates', ``, ``, ``, ``],
  payment_date: [
    `결제 예정일`,
    <>
      Payment
      <br />
      date
    </>,
    't_purchase.payment_date',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option: [
    `정기 결제가 진행될 때 항상 포인트를 사용합니다.`,
    `Always redeem my points toward my monthly payments.`,
    't_purchase.point_option',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option_info: [
    `정기 결제가 진행될 때 자동으로 보유 포인트를 전부 사용하여 결제를 진행합니다.`,
    `Check the box to automatically use all your points for your monthly payments.`,
    't_purchase.point_option_info',
    ``,
    ``,
    ``,
    ``,
  ],
  title_card: [`카드로 결제하기`, `Pay with card`, 't_purchase.title_card', ``, ``, ``, ``],
  header: [`결제하기`, `Purchase`, 't_purchase.header', ``, ``, ``, ``],
  point: [`포인트`, `Points`, 't_purchase.point', ``, ``, ``, ``],
  my_point: [`보유포인트`, `My points`, 't_purchase.my_point', ``, ``, ``, ``],
  use_all: [`전부 사용`, `Use all`, 't_purchase.use_all', ``, ``, ``, ``],
  price: [`결제금액`, `Subtotal`, 't_purchase.price', ``, ``, ``, ``],
  usual_price: [`정가가격`, `Regular price`, 't_purchase.usual_price', ``, ``, ``, ``],
  discount_sum: [`총 할인금액`, `Total discount`, 't_purchase.discount_sum', ``, ``, ``, ``],
  use_point: [`포인트 사용`, `Points applied`, 't_purchase.use_point', ``, ``, ``, ``],
  total_price: [`최종 결제 금액`, `Your total`, 't_purchase.total_price', ``, ``, ``, ``],
  switch_to_other: [`해외 결제 수단으로 바꾸기`, ``, 't_purchase.switch_to_other', ``, ``, ``, ``],
  switch_to_kr: [``, `Switch to Korean payment method`, 't_purchase.switch_to_kr', ``, ``, ``, ``],
  purchase_method: [`결제 방식 선택`, ``, 't_purchase.purchase_method', ``, ``, ``, ``],
  purchase_method_card: [``, `Pay with card`, 't_purchase.purchase_method_card', ``, ``, ``, ``],
  domestic_payment: [`국내 카드 결제`, ``, 't_purchase.domestic_payment', ``, ``, ``, ``],
  pay_normal: [`카드 일반 결제`, ``, 't_purchase.pay_normal', ``, ``, ``, ``],
  pay_card: [`카드 직접 입력`, ``, 't_purchase.pay_card', ``, ``, ``, ``],
  pay_kakao: [`카카오페이`, ``, 't_purchase.pay_kakao', ``, ``, ``, ``],
  pay_samsung: [`삼성페이`, ``, 't_purchase.pay_samsung', ``, ``, ``, ``],
  pay_payco: [`PAYCO`, ``, 't_purchase.pay_payco', ``, ``, ``, ``],
  pay_naver: [`네이버페이(카드결제)`, ``, 't_purchase.pay_naver', ``, ``, ``, ``],
  pay_account: [`실시간 계좌 이체`, ``, 't_purchase.pay_account', ``, ``, ``, ``],
  pay_viryual: [`가상 계좌 발급`, ``, 't_purchase.pay_viryual', ``, ``, ``, ``],
  pay_two_card: [`2개 카드 결제`, ``, 't_purchase.pay_two_card', ``, ``, ``, ``],
  installment_payment: [`분할 결제`, ``, 't_purchase.installment_payment', ``, ``, ``, ``],
  note_1: [
    `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
    `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
    't_purchase.note_1',
    ``,
    ``,
    ``,
    ``,
  ],
  note_2: [
    `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
    `Unused credits' expiration dates will start counting down a year after the purchase date.`,
    't_purchase.note_2',
    ``,
    ``,
    ``,
    ``,
  ],
  note_3: [`국내 카드만 가능`, ``, 't_purchase.note_3', ``, ``, ``, ``],
  note_4: [
    `최종 결제는 미국 달러로 결제됩니다.`,
    `Payments will be processed in USD.`,
    't_purchase.note_4',
    ``,
    ``,
    ``,
    ``,
  ],
  purcahse_agreement: [
    `결제 내역을 확인하였으며 전자상거래법 및 링글 정책에 의거하여 환불이 진행되는 것에 동의합니다.`,
    `I confirm my purchase and agree to Rignle’s terms and`,
    't_purchase.purcahse_agreement',
    ``,
    ``,
    ``,
    ``,
  ],
  purchase: [`결제하기`, `conditions.`, 't_purchase.purchase', ``, ``, ``, ``],
  note_5: [
    `수업권 결제 직후부터 수강기간 차감이 시작됩니다.`,
    `Credits begin expiring from purchase date.`,
    't_purchase.note_5',
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
    `Credits begin expiring from purchase date.`,
  ],
}
export const t_purchase_completed = {
  exp_date_info: [
    `정기결제 수업권은 다음 정기 결제일 전날 23:59까지 수강할 수 있음`,
    `Monthly credits are valid until 23:59 pm on the day before the next billing date.`,
    't_purchase_completed.exp_date_info',
    ``,
    ``,
    ``,
    ``,
  ],
  validity: [`수강기간`, `Monthly validity`, 't_purchase_completed.validity', ``, ``, ``, ``],
  points_option: [`포인트 옵션`, `Points`, 't_purchase_completed.points_option', ``, ``, ``, ``],
  dates: [`결제 시작일`, `Purchase date`, 't_purchase_completed.dates', ``, ``, ``, ``],
  payments_date: [`결제 예정일`, `Billing date`, 't_purchase_completed.payments_date', ``, ``, ``, ``],
  point_selected_info: [
    `내 플랜 > 결제 수단 변경에서 옵션을 변경할 수 있습니다.`,
    `You can change your option on [My Plans] > [Edit Payment]`,
    't_purchase_completed.point_selected_info',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_info: [
    `수업권은 결제 시작 시점부터 사용 가능합니다.`,
    `You can start using your plan from the date of purchase.`,
    't_purchase_completed.credit_info',
    ``,
    ``,
    ``,
    ``,
  ],
  cancel_recurring_info: [
    `이 플랜을 취소하시려면 대시보드 > 내 플랜에서 수정이 가능합니다.`,
    `To end this plan, change your option on [My Plan] > [Edit Payment]`,
    't_purchase_completed.cancel_recurring_info',
    ``,
    ``,
    ``,
    ``,
  ],
  print_receipt_info: [
    `영수증/출석증은 결제 이메일에서 PDF로 다운로드 가능합니다.`,
    `Receipts and proof of attendance can be downloaded from your invoice email.`,
    't_purchase_completed.print_receipt_info',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`OT 자료 열람하기`, `Begin Orientation`, 't_purchase_completed.btn1', ``, ``, ``, ``],
  btn2: [`수업 예약하기`, `Book a Lesson`, 't_purchase_completed.btn2', ``, ``, ``, ``],
  every_month: (product_name) => [
    `${product_name} 매 월`,
    `${product_name} Every Month`,
    't_purchase_completed.every_month',
    ``,
    ``,
    ``,
    ``,
  ],
  subscription_info: [`정기 결제 정보`, `Payment Summary`, 't_purchase_completed.subscription_info', ``, ``, ``, ``],
  use_point: [
    `정기 결제 시 포인트를 사용합니다.`,
    `Use points for monthly payments.`,
    't_purchase_completed.use_point',
    ``,
    ``,
    ``,
    ``,
  ],
  dont_use_point: [
    `포인트를 사용하지 않습니다.`,
    `Do not use points for monthly payments.`,
    't_purchase_completed.dont_use_point',
    ``,
    ``,
    ``,
    ``,
  ],
  from: (created_at) => [`${created_at} 부터`, `From ${created_at}`, 't_purchase_completed.from', ``, ``, ``, ``],
  faq1: {
    title: [
      `수업 예약은 어떻게 하나요?`,
      `How can I book a lesson?`,
      't_purchase_completed.faq1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `365일 & 하루에도 여러 번 원하시는 일정에 맞춰 수업 예약 가능합니다.<br/><br/>[1:1 수업] &gt; [예약] 탭에서, 원하시는 시간/튜터/ 교재를 직접 선택하여 예약 가능합니다.`,
      `You can book and take lessons as many times as you want all year round.<br/><br/>Click on [1:1 lessons] → [Book] and select the time/tutor/material to book your Ringle Teens lesson.`,
      't_purchase_completed.faq1.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq2: {
    title: [
      `예습은 어떻게 하나요?`,
      `How can I prepare for a lesson?`,
      't_purchase_completed.faq2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `교재를 미리 읽어보고, 수업에서 다룰 질문을 선택한 후 답변을 준비해보세요.<br/><br/>수업 예약 후 [1:1 수업] &gt; [예정된 수업]에서 교재 및 MP3, 핵심표현 등 다양한 자료를 활용하여 예습해보세요.`,
      `Read the insight of the chosen lesson material and select questions to discuss in your lesson.<br/><br/>After booking a lesson, click on [1:1 lessons] → [Prep&Lesson] to access the insight, MP3, discussion questions, and links to relevant videos & news articles to prepare for your upcoming lesson.`,
      't_purchase_completed.faq2.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq3: {
    title: [
      `수업 입장은 어떻게 하나요?`,
      `How can I enter a lesson?`,
      't_purchase_completed.faq3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `화상수업을 위해 줌 설치/ 카메라가 설치된 PC에서 수업을 입장해주세요.<br/><br/>1:1 수업 &gt; 예정된 수업 &gt; 오른쪽 하단 [수업 입장] 버튼을 통해, 수업 입장 가능합니다.<br/><br/><Div textGray600>*교재/실시간 교정화면은 모바일 환경에서 제한적으로 보일 수 있습니다.</Div>`,
      `For your 1:1 video lesson, please enter the lesson on a camera-equipped laptop/PC with Zoom installed. <br/><br/>Click on 1:1 Lessons → [Prep&Lesson] → [Enter Lesson] to enter a lesson.<br/><br/><Div textGray600>*Viewing lesson material and real-time lesson notes can be limited on mobile devices.</Div>`,
      't_purchase_completed.faq3.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq4: {
    title: [
      `교재/수업 변경 및 취소는 어떻게 하나요?`,
      `How can I change or cancel a lesson or lesson material?`,
      't_purchase_completed.faq4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `교재/ 체험 수업 시간 변경 및 취소는 수업 시작 24시간 이전까지 가능합니다.<br/><br/>[정규 수업 취소 정책]
• 수업 시작 24시간 이전 취소: 해당 수업권 자동 복구
• 수업 시작 24시간 이내~ 2시간 이전 취소: 해당 수업권 차감 후, '보충수업권'* 지급
• 수업 시작 2시간 이내 취소: 해당 수업권 차감 및 보충 수업권 미제공<br/><br/><Div textGray600>*보충 수업은 수업 시작 24시간 이내 기존 수강생이 취소하는 수업을 말합니다. 제공된 보충 수업권은 수강 기간이 60일이며, 24시간 이내 가능한 수업 화면에서 예약 가능합니다. </Div>`,
      `You can only change the lesson materials and lesson time 24 hours prior to the start of a lesson.<br/><br/>[Regular Lesson Credit]
• For cancellations made more than 24 hours before the lesson, your regular lesson credit will be restored.
• For cancellations made between 2 and 24 hours before the lesson, your lesson credit will be expired, and a Last-minute Lesson Credit with a validity period of 60 days will be provided.
• For cancellations made less than 2 hours before the lesson, your credit will be expired.<br/><br/><Div textGray600>Last-minute Lesson Credits are used to book lessons that become available in 24 hours due to untaken slots or last-minute cancellations by other students. You can only use the Last-minute Lesson Credits to book lessons on "Last-minute Lesson” </Div>`,
      't_purchase_completed.faq4.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  ask_help_center: [
    `고객센터로 문의하세요`,
    `Please try a different payment method`,
    't_purchase_completed.ask_help_center',
    ``,
    ``,
    ``,
    ``,
  ],
  order: [`주문상품`, `Your cart`, 't_purchase_completed.order', ``, ``, ``, ``],
  lesson_credit: [`수업권명`, `Package`, 't_purchase_completed.lesson_credit', ``, ``, ``, ``],
  lesson_length: [`수업길이`, `Duration`, 't_purchase_completed.lesson_length', ``, ``, ``, ``],
  exp_dates: [`시간대`, `Times`, 't_purchase_completed.exp_dates', ``, ``, ``, ``],
  duration: [
    `수강기간`,
    `Expiration
dates`,
    't_purchase_completed.duration',
    ``,
    ``,
    ``,
    ``,
  ],
  done_desc: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
      <Div fontBold textD5>
        이제 링글 수업을 시작해보세요!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    't_purchase_completed.done_desc',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_purchase_fail = {
  list1: [`결제 요청 금액`, `Your total`, 't_purchase_fail.list1', ``, ``, ``, ``],
  list2: [`수업권명`, `Plan`, 't_purchase_fail.list2', ``, ``, ``, ``],
  list3: [`수업길이`, `Duration`, 't_purchase_fail.list3', ``, ``, ``, ``],
}
export const t_myplans = {
  title: [`내 요금제`, `My Plans`, 't_myplans.title', ``, ``, ``, ``],
  error_info: [
    `결제 정보를 업데이트해주세요`,
    `Please update your payment details`,
    't_myplans.error_info',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option: [
    `다음 결제 때 포인트 사용하기`,
    `Use points for the next payment`,
    't_myplans.point_option',
    ``,
    ``,
    ``,
    ``,
  ],
  change_option: [
    `다음 정기 결제에 포인트를 사용합니다.`,
    `Use points for the next payment`,
    't_myplans.change_option',
    ``,
    ``,
    ``,
    ``,
  ],
  change_option2: [
    `다음 정기 결제에 포인트를 사용하지 않습니다.`,
    `Do not use points for the next payment`,
    't_myplans.change_option2',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_empty: [
    `죄송합니다, 검색에 해당하는 수업권이 존재하지 않습니다.`,
    `Sorry, no lesson credits found for your search.`,
    't_myplans.notice_empty',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_start: [`구독 시작하기`, `Start a Plan`, 't_myplans.btn_start', ``, ``, ``, ``],
  btn1: [`결제방식 수정하기`, `Edit Payment`, 't_myplans.btn1', ``, ``, ``, ``],
  btn2: [`구독 취소하기`, `End Plan`, 't_myplans.btn2', ``, ``, ``, ``],
  next_payment: [`다음 결제일`, `Next payment on`, 't_myplans.next_payment', ``, ``, ``, ``],
}
export const t_edit_payment = {
  title_modal: [`결제방식 수정`, `Edit Payment`, 't_edit_payment.title_modal', ``, ``, ``, ``],
  btn1: [`취소`, `Cancel`, 't_edit_payment.btn1', ``, ``, ``, ``],
  btn2: [`저장`, `Save`, 't_edit_payment.btn2', ``, ``, ``, ``],
  title_modal2: [`결제방식이 승인되었습니다`, `Payment Approved`, 't_edit_payment.title_modal2', ``, ``, ``, ``],
  complete: (date) => [
    <>다음 결제일부터 새로 등록한 결제 수단으로 진행됩니다. 다음 정기 결제일은 {date}입니다.</>,
    <>You will be billed monthly with your newly registered payment method. Your next billing date is {date}.</>,
    't_edit_payment.complete',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_modal: [`확인`, `OK`, 't_edit_payment.btn_modal', ``, ``, ``, ``],
  toast: [`저장되었습니다!`, `Successfully Saved!`, 't_edit_payment.toast', ``, ``, ``, ``],
}
export const t_end_plans = {
  title: [`요금체 취소하기`, `End Plan`, 't_end_plans.title', ``, ``, ``, ``],
  checkout: (date) => [
    <>[필수] {date} 부터 결제가 이루어지지 않습니다.</>,
    <>[Required] You will not be billed from {date}.</>,
    't_end_plans.checkout',
    ``,
    ``,
    ``,
    ``,
  ],
  toast: [`이 요금제는 만료되었습니다`, `This plan has been ended`, 't_end_plans.toast', ``, ``, ``, ``],
  btn1: [`아니요, 이 요금제 유지하겠습니다`, `No, Keep This Plan`, 't_end_plans.btn1', ``, ``, ``, ``],
  btn2: [`이 요금제 취소하기`, `End This Plan`, 't_end_plans.btn2', ``, ``, ``, ``],
}
export const t_credits = {
  title1: [`내 수업권`, `Remaining Credits`, 't_credits.title1', ``, ``, ``, ``],
  title2: [`수업권 현황`, `Credit History`, 't_credits.title2', ``, ``, ``, ``],
  credits1: [`정규수업권`, `Regular`, 't_credits.credits1', ``, ``, ``, ``],
  credits2: [`당일수업권`, `Last-minute`, 't_credits.credits2', ``, ``, ``, ``],
  text_btn: [`수업권 구매하기`, `Buy More Credits`, 't_credits.text_btn', ``, ``, ``, ``],
  notice: [`수업권 유의사항`, `Terms`, 't_credits.notice', ``, ``, ``, ``],
  desc: [
    <>
      예약 가능 구간 외의 날짜에 수업을 예약하실 수<br />
      없습니다
    </>,
    `You cannot book lessons outside the monthly validity period.`,
    't_credits.desc',
    ``,
    ``,
    ``,
    ``,
  ],
  option1: [`사용가능`, `Available`, 't_credits.option1', ``, ``, ``, ``],
  option2: [`사용만료`, `Expired`, 't_credits.option2', ``, ``, ``, ``],
  option3: [`20분`, `20min`, 't_credits.option3', ``, ``, ``, ``],
  option4: [`40분`, `40min`, 't_credits.option4', ``, ``, ``, ``],
  option5: [`정규수업권`, `Regular`, 't_credits.option5', ``, ``, ``, ``],
  option6: [`당일수업권`, `Last-minute`, 't_credits.option6', ``, ``, ``, ``],
  option_info: [
    `버튼을 누르면 필터를 선택/해지할 수 있습니다.`,
    `Apply filters above.`,
    't_credits.option_info',
    ``,
    ``,
    ``,
    ``,
  ],
  exp_date: [`수강 기간`, `Duration`, 't_credits.exp_date', ``, ``, ``, ``],
  unused: [`남은 횟수`, `Remaining Credits`, 't_credits.unused', ``, ``, ``, ``],
  issue_date: [`발급 시점`, `Issue Date`, 't_credits.issue_date', ``, ``, ``, ``],
  see_detail: [`상세 내역 보기`, `See details`, 't_credits.see_detail', ``, ``, ``, ``],
  book: [`수업 관리북`, `Lesson book`, 't_credits.book', ``, ``, ``, ``],
  btn1: [`더보기`, `See more`, 't_credits.btn1', ``, ``, ``, ``],
  tooltip1: [
    <>
      수업 시작 24시간 이전/이내 수업
      <br />
      모두 예약 가능한 수업권입니다.
    </>,
    <>
      You can book any lessons available
      <br />
      (starting before or after 24 hours from now).
    </>,
    't_credits.tooltip1',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip2: [
    <>
      수업 시작 24시간 이내 수업만 예약 가능한
      <br />
      수업권입니다. [예약 &gt; 24시간 이내 수업]
    </>,
    `You can only book lessons starting within the next 24 hours.`,
    't_credits.tooltip2',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_empty: [
    `죄송합니다, 보유하고 계신 수업권이 없습니다`,
    `Sorry, no lesson credits found for your search`,
    't_credits.notice_empty',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_start: [`수업권 구매하기`, `Go to Purchase`, 't_credits.btn_start', ``, ``, ``, ``],
  desc1: [
    `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
    `Expiration date countdown will begin after the first lesson is completed.`,
    't_credits.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다.`,
    `However, if you do not take a lesson within 1 year after your purchase, the credits will automatically expire. Therefore, lessons may begin at anytime within 1 year of your purchase.`,
    't_credits.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `Expiration dates are calculated as of the time your first lesson is completed, not reserved.`,
    't_credits.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_credits_detail = {
  title: {
    default: [`수업권 상세 내역`, `Credits Detail`, 't_credits_detail.title.default', ``, ``, ``, ``],
  },
  title1: {
    default: [`남은 수업권`, `Unused Credits`, 't_credits_detail.title1.default', ``, ``, ``, ``],
    list1: [`남은 수업 수`, `Remaining Lessons`, 't_credits_detail.title1.list1', ``, ``, ``, ``],
    list2: [`수업권`, `Credits`, 't_credits_detail.title1.list2', ``, ``, ``, ``],
    list3: [`남은 수업 기간`, `Remaining Duration`, 't_credits_detail.title1.list3', ``, ``, ``, ``],
  },
  title2: {
    default: [`사용 수업권`, `Credits Used`, 't_credits_detail.title2.default', ``, ``, ``, ``],
    list1: [`수업 횟수`, `Lessons Complete`, 't_credits_detail.title2.list1', ``, ``, ``, ``],
    list2: [`수업 내용`, `Lesson Topic`, 't_credits_detail.title2.list2', ``, ``, ``, ``],
    list3: [`수업 시간`, `Lesson Time`, 't_credits_detail.title2.list3', ``, ``, ``, ``],
  },
  dropdown1: [`최근 수업 순`, `Sort by Newest`, 't_credits_detail.dropdown1', ``, ``, ``, ``],
  dropdown2: [`오래된 수업 순`, `Sort by Oldest`, 't_credits_detail.dropdown2', ``, ``, ``, ``],
  btn1: [`더보기`, `See more`, 't_credits_detail.btn1', ``, ``, ``, ``],
}
export const t_check_lesson_style = {
  lesson_goals: {
    title: [`수업 목표`, `Lesson Goals`, 't_check_lesson_style.lesson_goals.title', ``, ``, ``, ``],
  },
}
export const t_upcoming_lesson = {
  goals_for_this_lesson_optional: {
    default: [
      `이 수업을 듣는 목적 (선택)`,
      `Goals for This Lesson (optional)`,
      't_upcoming_lesson.goals_for_this_lesson_optional.default',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [
      `이 수업을 통해 배우고 싶은 것이나 이루고 싶은 것은 무엇인가요?`,
      `Write about what you want to learn or achieve during this lesson.`,
      't_upcoming_lesson.goals_for_this_lesson_optional.info',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_landing_home = {
  btn1: [`로그인`, `Sign-in`, 't_landing_home.btn1', ``, ``, ``, ``],
  btn2: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn2', ``, ``, ``, ``],
  title1: {
    default: [
      `Ringle Teens와 함께 사고력을 키워보세요`,
      `Grow Intellectually with Ringle Teens`,
      't_landing_home.title1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [`4가지 능력을 키워보세요`, `Expand your learner's 4Cs`, 't_landing_home.title1.desc', ``, ``, ``, ``],
    info1: [`소통 능력`, `Communication`, 't_landing_home.title1.info1', ``, ``, ``, ``],
    info2: [`비판적 사고 능력`, `Critical Thinking`, 't_landing_home.title1.info2', ``, ``, ``, ``],
    info3: [`창의적 사고 능력`, `Creative Thinking`, 't_landing_home.title1.info3', ``, ``, ``, ``],
    info4: [`집중력`, `Concentration Skills`, 't_landing_home.title1.info4', ``, ``, ``, ``],
  },
  btn3: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn3', ``, ``, ``, ``],
  title2: {
    default: [
      `상세한 1:1 온라인 토론 세션`,
      `In-depth 1:1 online Discussion Sessions`,
      't_landing_home.title2.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈는 하버드와 예일대와 같은 명문대학교 출신의 튜터들과의 1:1 수업을 통해 4C 능력을 기를 수 있는 기회를 제공합니다`,
      `Ringle Teens helps students develop their 4Cs by offering 1:1 lessons with tutors from prestigious universities, such as Harvard and Yale.`,
      't_landing_home.title2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn4: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn4', ``, ``, ``, ``],
  review1: {
    default: [
      <>
        링글 틴즈의 튜터들과 만남은 제 기대를 뛰어넘었어요!
        <br />이 수업을 통해 저는 다양한 정보에 대해 비판적사고를 하며 현명한 판단을 할 수 있게 되었어요.
      </>,
      <>
        Ringle Teens lessons exceeded my expectations!
        <br />
        My tutor helped me make intelligent judgments about various information. I have now become a critical thinker!
      </>,
      't_landing_home.review1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    writer: [
      `미국 펜실베이니아에 살고 있는 링글 틴즈 학생`,
      `A Ringle Teens Student in Pennsylvania, US`,
      't_landing_home.review1.writer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle2: {
    default: [
      `교육 및 학습에 대한 링글 틴즈만의 접근 방식`,
      `OUR UNIQUE APPROACH TO TEACHING AND LEARNING`,
      't_landing_home.subtitle2.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title3: {
    default: [
      <>
        링글 틴즈는 4C로<span className="text-purple-500">사고력을 확장합니다</span>
      </>,
      <>
        We Help Develop the <span className="text-purple-500">4Cs</span>
      </>,
      't_landing_home.title3.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈의 수업들은 검증 된 교육학적 방식인  '하브루타 교육법,' '하크네스 방법,' '소크라테스 대화법' 을 기반으로 진행됩니다.`,
      `Ringle Teens has adapted proven pedagogical methods and learning styles such as "the Havruta Method,” "the Harkness Method,” and "the Socratic Method.” Ringle Teens lessons reflect such learning styles.`,
      't_landing_home.title3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card1: {
    default: [`비판적 사고 능력`, `Critical Thinking`, 't_landing_home.card1.default', ``, ``, ``, ``],
    desc: [
      `다양한 기사와 관련 주제에 대한 논의를 바탕으로 논리적인 사고방식을 길러보세요`,
      `Make logical connections between ideas by reading articles and having relevant discussions`,
      't_landing_home.card1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card2: {
    default: [`창의적 사고 능력`, `Creative Thinking`, 't_landing_home.card2.default', ``, ``, ``, ``],
    desc: [
      `다양한 배경 출신의 튜터들과 수업하며 창의적인 사고 방식을 연습해보세요.`,
      `Practice innovative thinking by interacting with tutors of diverse backgrounds`,
      't_landing_home.card2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card3: {
    default: [`집중력`, `Concentration`, 't_landing_home.card3.default', ``, ``, ``, ``],
    desc: [
      `1:1 수업을 통해 집중력을 길러보세요.`,
      `Develop your concentration skills 
by participating in engaging 1:1 lessons`,
      't_landing_home.card3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card4: {
    default: [`소통 능력`, `Communication`, 't_landing_home.card4.default', ``, ``, ``, ``],
    desc: [
      `튜터들의 상세한 피드백을 바탕으로 말하기와 쓰기 실력을 향상시켜 보세요.`,
      `Improve your oral and written skills through detailed feedback from our tutors`,
      't_landing_home.card4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle3: {
    default: [
      `링글 틴즈 수업은 이렇게 진행됩니다!`,
      `HOW RINGLE TEENS WORKS`,
      't_landing_home.subtitle3.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title4: {
    default: [
      `예습,수업,복습으로 이어지는 사고력 다지기`,
      `Maximize Your Learner's Experience with 3 Steps`,
      't_landing_home.title4.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab1: {
    default: [`Step 1`, `Step 1`, 't_landing_home.tab1.default', ``, ``, ``, ``],
    title: [`수업 준비`, `Lesson Preparation`, 't_landing_home.tab1.title', ``, ``, ``, ``],
    desc1: [<>"인사이트" 읽기</>, <>Read the "Insight"</>, 't_landing_home.tab1.desc1', ``, ``, ``, ``],
    desc2: [`오디오 파일 듣기`, `Listen to an audio file`, 't_landing_home.tab1.desc2', ``, ``, ``, ``],
    desc3: [`비디오 클립 보기`, `Watch a video clip`, 't_landing_home.tab1.desc3', ``, ``, ``, ``],
    desc4: [
      `질문을 세가지 이상 선택하세요`,
      `Select 3+ questions to answer`,
      't_landing_home.tab1.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab2: {
    default: [`Step 2`, `Step 2`, 't_landing_home.tab2.default', ``, ``, ``, ``],
    title: [`수업 & 숙제`, `Lesson & Homework`, 't_landing_home.tab2.title', ``, ``, ``, ``],
    desc1: [
      `튜터와의 활발한 대화에 참여하세요`,
      `Engage in an active conversation with a tutor`,
      't_landing_home.tab2.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `튜터로부터 실시간 피드백을 받아보세요`,
      `Receive live feedback from the tutor`,
      't_landing_home.tab2.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `쓰기 숙제를 하며 수업을 마무리 하세요`,
      `Conclude your lesson with a written assignment`,
      't_landing_home.tab2.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab3: {
    default: [`Step 3`, `Step 3`, 't_landing_home.tab3.default', ``, ``, ``, ``],
    title: [`수업 복습하기`, `Lesson Review`, 't_landing_home.tab3.title', ``, ``, ``, ``],
    desc1: [
      `수업 녹음본을 듣고 복습하세요`,
      `Listen to the lesson recording and review your session`,
      't_landing_home.tab3.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `수업 분석, 피드백, 그리고 교정을 확인하세요`,
      `Check lesson analysis, feedback, and corrections`,
      't_landing_home.tab3.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `글쓰기 숙제에 대한 튜터의 피드백을 검토하세요`,
      `Review your tutor's feedback on your written assignment`,
      't_landing_home.tab3.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title5: {
    default: [
      `링글 틴즈의 모든 수업들은 다음과 같은 혜택을 제공합니다`,
      `All Ringle Teens Lesson Include`,
      't_landing_home.title5.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [
      <>
        학습자의 필요에 맞춘
        <br />
        다양한 종류의 수업 방식
      </>,
      <>
        Wide range of lesson
        <br />
        styles for your learner’s needs
      </>,
      't_landing_home.title5.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `수업 중 도움이 필요할 경우를 대비해 상시 대기 중인 모니터링 팀`,
      `On-demand monitoring team ready to support`,
      't_landing_home.title5.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      <>
        학부모 대시보드와
        <br />
        수업 진행 현황을 알리는 알림 시스템
      </>,
      <>
        Parent dashboard and
        <br />
        alarm system to check lesson progress
      </>,
      't_landing_home.title5.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      <>
        학부모님들에게만 보이는
        <br />
        튜터의 피드백
      </>,
      <>
        Tutor feedback exclusively
        <br />
        visible to parents
      </>,
      't_landing_home.title5.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn5: [`상담 신청`, `Ask More to Our Advisor`, 't_landing_home.btn5', ``, ``, ``, ``],
  subtitle4: {
    default: [
      `링글 틴즈의 튜터들을 소개합니다`,
      `KEY FEATURES: OUR TUTORS`,
      't_landing_home.subtitle4.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title6: {
    default: [
      `명문대 출신의 유능한 튜터들`,
      `Quality Tutors From Prestigious Universities`,
      't_landing_home.title6.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [
      `튜터 선발 과정에서 뛰어난 성적을 거둔 명문대 원어민 튜터들`,
      `Experienced native English speaking tutors who aced our tutor selection process`,
      't_landing_home.title6.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `STEM 에서 ART 까지 다양한 전공의 튜터들`,
      `Knowledgeable scholars in areas ranging from STEM to the Arts`,
      't_landing_home.title6.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `학업과 인생에 대한 멘토링까지 이끌어내는 튜터들`,
      `Mentors who can motivate students to have a positive attitude in life and academia`,
      't_landing_home.title6.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn6: [`가입하고 튜터들 보러가기`, `Sign up & Meet Our Tutors`, 't_landing_home.btn6', ``, ``, ``, ``],
  btn7: [`가입하고 더 다양한 교재들 보기`, `Sign up & See More Materials`, 't_landing_home.btn7', ``, ``, ``, ``],
  subtitle5: {
    default: [
      `십대 중심의 인사이트`,
      `KEY FEATURES: TEEN-FOCUSED INSIGHTS`,
      't_landing_home.subtitle5.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title8: {
    default: [
      `십대만을 위한 다양한 주제의 교재들`,
      `Articles on Various Topics for Teens`,
      't_landing_home.title8.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title9: {
    default_mobile: [
      `다양한 교재들을 둘러보세요!`,
      `Check our various materials!`,
      't_landing_home.title9.default_mobile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title10: {
    default: [
      `링글 틴즈에 대해 더 알고 싶으신가요?`,
      `Still Curious About Ringle Teens?`,
      't_landing_home.title10.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈 만의 학습자 맞춤수업을 통해 영어실력 향상을 경험하세요!`,
      `Let us help customize your learner's academic journey!`,
      't_landing_home.title10.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn8: [`오늘 상담을 예약해보세요!`, `Book a Call Today!`, 't_landing_home.btn8', ``, ``, ``, ``],
  title11: {
    default: [
      `학생들과 학부모들의 사용후기`,
      `5-Star Reviews from Students & Parents`,
      't_landing_home.title11.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  review2: {
    default1: [
      <>링글 틴즈는 단순히 무언가를 배우기만 하는 교육 플랫폼이 아니에요. </>,
      <>Ringle Teens is not just an educational platform where you simply learn things. </>,
      't_landing_home.review2.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `시야를 넓혀주고 전에는 상상하지도 못한 인생의 목표를 세우게 해주는 플랫폼입니다.`,
      `It helps you broaden your horizons and set life goals that you’ve never dreamed of achieving before.`,
      't_landing_home.review2.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학생`, `Teens Student`, 't_landing_home.review2.writer1', ``, ``, ``, ``],
    writer2: [`워싱턴, 미국`, `from Washington, US`, 't_landing_home.review2.writer2', ``, ``, ``, ``],
  },
  review3: {
    default1: [
      <>링글 틴즈의 수업들이 너무 즐거워서 제 친구들에게 추천했어요! 제 튜터의 피드백 덕분에 </>,
      <>
        I truly enjoyed my Ringle Teens lessons that I recommended this service to all of my friends! Thanks to my
        tutor’s helpful feedback,{' '}
      </>,
      't_landing_home.review3.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `저는 소통과 작문 능력을 기를 수 있었어요!`,
      `I have stronger communication and writing skills!`,
      't_landing_home.review3.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학생`, `Teens Student`, 't_landing_home.review3.writer1', ``, ``, ``, ``],
    writer2: [`펜실베이니아, 미국`, `from Pennsylvania, US`, 't_landing_home.review3.writer2', ``, ``, ``, ``],
  },
  review4: {
    default1: [
      <>소통 능력, 비판적 사고 능력, 창의적 사고 능력, 집중력은 모두 인생에서 필수적인 요소들입니다. </>,
      <>Concentration, communication, creative thinking, and critical thinking skills are essential in life. </>,
      't_landing_home.review4.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `제 아이는 이 모든 능력을 링글 틴즈에서 기를 수 있었어요!`,
      `Ringle Teens helped my child develop all of those on one platform!`,
      't_landing_home.review4.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학부모님`, `Teens Parent`, 't_landing_home.review4.writer1', ``, ``, ``, ``],
    writer2: [`베를린, 독일`, `from Berlin, Germany`, 't_landing_home.review4.writer2', ``, ``, ``, ``],
  },
  review5: {
    default1: [
      <>링글 틴즈의 튜터들은 제 아들이 4Cs를 향상시킬 수 있도록 집중적으로 지도해주었습니다. </>,
      <>Ringle Teens tutors paid extra attention to make sure my son improves his 4Cs. </>,
      't_landing_home.review5.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `덕분에 제 아들은 이제 디베이트를 매우 잘하게 되었어요!`,
      `My son now excels in discussion sessions and debate classes at his school!`,
      't_landing_home.review5.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학부모님`, `Teens Parent`, 't_landing_home.review5.writer1', ``, ``, ``, ``],
    writer2: [`시드니, 호주`, `from Sydney, Australia`, 't_landing_home.review5.writer2', ``, ``, ``, ``],
  },
  title12: {
    default: [`자주 묻는 질문`, `Frequently Asked Questions`, 't_landing_home.title12.default', ``, ``, ``, ``],
    faq1: {
      default: [
        `제 아이가 링글 틴즈를 통해 어떻게 4C 능력을 향상시킬 수 있나요?`,
        `How can Ringle Teens help my child hone his or her 4Cs?`,
        't_landing_home.title12.faq1.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `링글 틴즈는 튜터들이 4Cs(소통 능력, 비판적 사고 능력, 창의적 사고 능력, 집중력)을 기반으로 수업을 진행하고 피드백을 주도록 트레이닝하고 있습니다.`,
        `Ringle Teens trains its tutors to lead lessons and give helpful feedback constructed upon the 4Cs: Communication, Critical Thinking, Creative Thinking, and Concentration Skills.`,
        't_landing_home.title12.faq1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq2: {
      default: [
        `링글 틴즈는 어떤 나이대의 학생들을 위한 것인가요?`,
        `What ages is Ringle Teens aimed towards?`,
        't_landing_home.title12.faq2.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `9살부터 15살의 학생들을 대상으로 하고 있지만, 더 어리거나 나이가 많아도 누구든지 링글 틴즈의 수업을 유익하게 들으실 수 있습니다!`,
        `It is geared toward students from 9 to 15 years of age. However, younger and older students can still enjoy Ringle Teens lessons!`,
        't_landing_home.title12.faq2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq3: {
      default: [
        `수업의 가격은 어떠한가요?`,
        `How much do lessons cost?`,
        't_landing_home.title12.faq3.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `월간 요금제는 ____부터 시작하지만, 편의에 맞게 요금제를 사용자 맞춤화하여 이용하실 수도 있습니다! 한 달에 더 많은 수업을 신청하여 학습자의의 학습 여정을 한단계 더 증진시켜보세요. 모든 링글 틴즈 이용자는 저희의 튜터들, 십대들을 위한 학습 자료들, 유저분들만을 위한 이벤트들의 혜택을 누리실 수 있습니다.`,
        `Our monthly plan starts at $149 — yet, you may customize your plan!
Add more lessons per month to further your learner’s journey.  For every Ringle Teens user, access to our caring tutors, teens-oriented learning materials, and user-only events will be available.`,
        't_landing_home.title12.faq3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq4: {
      default: [
        `월간 요금제 취소는 어떻게 하나요?`,
        `How do I cancel my monthly plan?`,
        't_landing_home.title12.faq4.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          월간 요금제를 취소하기 위해서는 다음의 절차를 따라주세요.
          <br />
          1. 부모 계정으로 로그인
          <br />
          2. 좌측 메뉴에 있는 "내 약정" 클릭
          <br />
          3. "약정 관리"를 클릭하여 계정 삭제
        </>,
        <>
          If you want to cancel your monthly plan, follow the procedures below.
          <br />
          1. Login to Parent's account
          <br />
          2. Click on "My Plan" from the left side menu
          <br />
          3. Click on "Manage Plan" to cancel the plan
        </>,
        't_landing_home.title12.faq4.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq5: {
      default: [
        `링글 틴즈가 제 아이에게 맞을지 판단하기 위해 링글팀에 연락해도 되나요?`,
        `Can I contact the Ringle Team to see if Ringle Teens is right for my child?`,
        't_landing_home.title12.faq5.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `물론입니다! 1:1 상담을 신청하여 링글 틴즈 담당자와 상담을 해보세요. 또한, 질문이 있으시다면 언제든지 teens@ringleplus.com로 이메일 주시길 바랍니다.`,
        `Absolutely! Speak with a Ringle Teens advisor by signing up on our Calendly page for a 1:1 consultation. Also, feel free to email us with your inquiries at teens@ringleplus.com.`,
        't_landing_home.title12.faq5.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq6: {
      default: [
        `수업을 위해 어떤 셋업이 준비되어야 하나요?`,
        `What kind of setup will my learner need at home?`,
        't_landing_home.title12.faq6.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          1. Windows 혹은 Mac OS가 실행되고 마이크, 스피커, 웹캠까지 탑재된 컴퓨터를 준비해주세요
          <br />
          2. Chrome browser 와 Zoom Client 를 설치해주세요
          <br />
          3. 인터넷 연결
        </>,
        <>
          1. A computer that runs on Windows or Mac OS and has a microphone, speaker, and a webcam
          <br />
          2. Install the Chrome browser and Zoom Client
          <br />
          3. An Internet Connection
        </>,
        't_landing_home.title12.faq6.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq7: {
      default: [
        `링글 틴즈에 일주일간 들이는 시간은 대략 몇시간인가요?`,
        `What is a Ringle Teens user's expected time commitment for a week?`,
        't_landing_home.title12.faq7.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          학습자의 스케줄에 따라 얼마나 많은 수업을 들을지 결정하실 수 있습니다. 각 수업은 다음과 같은 세가지 단계들이
          포함되며, 모든 단계를 완료하는데에는 총 2~3시간 정도의 시간이 소요됩니다:
          <br />
          1. <b>수업 준비:</b> 기사를 읽고, 오디오 듣기, 영상 시청하기, 답변할 세가지 질문 선택하기
          <br />
          2. <b>수업과 숙제:</b> 20분/40분 수업을 듣고 작문 숙제까지 완료하기
          <br />
          3. <b>수업 복습:</b> 수업 녹화본, 수업 분석, 튜터의 피드백을 모두 복습하기
          <br />
        </>,
        <>
          You can decide how many lessons to take according to your learner’s schedule. Each lesson consists of the
          following elements, and these three elements take about 2 to 3 hours:
          <br />
          1.<b>Lesson Preparation:</b> Read and listen to an article, watch a clip, and select 3 questions to answer
          <br />
          2.<b>Lesson & Homework:</b> Take either 20-min or 40-min lesson and finish it with a written assignment
          <br />
          3.<b>Lesson Review:</b> Review your lesson recording, lesson analysis, and tutor’s feedback
        </>,
        't_landing_home.title12.faq7.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  title13: {
    default1: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.title13.default1', ``, ``, ``, ``],
    default2: [
      `링글 틴즈를 경험해보세요!`,
      `and Experience Ringle Teens!`,
      't_landing_home.title13.default2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn9: [`지금 무료수업 체험해보기`, `Try a Free Lesson Now`, 't_landing_home.btn9', ``, ``, ``, ``],
  subtitle1: {
    default: [`링글 틴즈란?`, `WHAT IS RINGLE TEENS`, 't_landing_home.subtitle1.default', ``, ``, ``, ``],
  },
  title7: {
    default_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.title7.default_mobile', ``, ``, ``, ``],
  },
  text_btn2_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn2_mobile', ``, ``, ``, ``],
  text_btn1_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn1_mobile', ``, ``, ``, ``],
  text_btn3_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn3_mobile', ``, ``, ``, ``],
  go_to_portal: [
    `포털로 가기`,
    `Go to Portal`,
    't_landing_home.go_to_portal',
    `Go to Portal`,
    `Go to Portal`,
    `Go to Portal`,
    `Go to Portal`,
  ],
  welcome: [`환영합니다!`, `Welcome!`, 't_landing_home.welcome', `Welcome!`, `Welcome!`, `Welcome!`, `Welcome!`],
}
export const t_sign_up = {
  title: [
    <>링글 틴즈 계정을 생성할 방식을 선택해주세요</>,
    <>How would you like to create your Ringle account?</>,
    't_sign_up.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle: [`소셜 계정으로 가입`, `Sign up with`, 't_sign_up.subtitle', ``, ``, ``, ``],
  or: [`혹은`, `or`, 't_sign_up.or', ``, ``, ``, ``],
  btn1: [`이메일로 가입하기`, `Sign up with email`, 't_sign_up.btn1', ``, ``, ``, ``],
  dssc: [`이미 링글 계정이 있으신가요?`, `Do you already have a Ringle account?`, 't_sign_up.dssc', ``, ``, ``, ``],
  btn2: [`링글 계정으로 가입하기`, `Sign up with Ringle Account`, 't_sign_up.btn2', ``, ``, ``, ``],
  teens_sign_up: [`링글 틴즈 가입`, `Teens Sign Up`, 't_sign_up.teens_sign_up', ``, ``, ``, ``],
  ok: [`확인`, `OK`, 't_sign_up.ok', ``, ``, ``, ``],
}
export const t_ringle_login = {
  title: [
    `링글 로그인 정보를 통해 계정 인증을 해주세요`,
    `Verify your account using your Ringle login credentials.`,
    't_ringle_login.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle: [
    `링글과 링글틴즈에서 동일한 로그인 정보를 사용하시게 됩니다.`,
    `You will use the same login credentials for Ringle & Ringle Teens.`,
    't_ringle_login.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder1: [`이메일 주소를 입력하세요`, `Enter your email adress`, 't_ringle_login.placeholder1', ``, ``, ``, ``],
  placeholder2: [`비밀번호를 입력하세요`, `Enter your password`, 't_ringle_login.placeholder2', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 적어주세요`, `Please enter a vaild Email address`, 't_ringle_login.msg1', ``, ``, ``, ``],
  msg2: [`존재하지 않는 비밀번호입니다`, `Did not match stored password`, 't_ringle_login.msg2', ``, ``, ``, ``],
  btn: [`로그인`, `Login`, 't_ringle_login.btn', ``, ``, ``, ``],
  forgot: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_ringle_login.forgot', ``, ``, ``, ``],
  desc: [`소셜 계정으로 로그인`, `Log in with`, 't_ringle_login.desc', ``, ``, ``, ``],
  tooltip: [
    `링글 혹은 링글 틴즈 계정의<br>비밀번호를 변경하시면<br>두 계정 모두에 반영됩니다.`,
    `Changing the password for either<br>Ringle or Ringle Teens will automatically<br>change both passwords.`,
    't_ringle_login.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_step1_email = {
  step1: [`학부모 계정`, `Parent Account`, 't_step1_email.step1', ``, ``, ``, ``],
  step2: [`자녀 계정`, `Child Account`, 't_step1_email.step2', ``, ``, ``, ``],
  step3: [`자녀 정보`, `Child Information`, 't_step1_email.step3', ``, ``, ``, ``],
  title: [`학부모 계정을 생성하세요`, `Create your parental account`, 't_step1_email.title', ``, ``, ``, ``],
  desc1: [`기본 정보`, `Basic Info`, 't_step1_email.desc1', ``, ``, ``, ``],
  desc2: [`휴대폰 인증하기`, `Phone number verification`, 't_step1_email.desc2', ``, ``, ``, ``],
  placeholder1: [`ID (이메일)`, `ID (Email)`, 't_step1_email.placeholder1', ``, ``, ``, ``],
  placeholder2: [
    `비밀번호 (6자 이상)`,
    `Password  (At least 6 characters)`,
    't_step1_email.placeholder2',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder3: [
    `비밀번호를 다시 입력해주세요`,
    `Re-enter your password`,
    't_step1_email.placeholder3',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder4: [`이름을 적어주세요.`, `Enter your name`, 't_step1_email.placeholder4', ``, ``, ``, ``],
  placeholder5: [
    `휴대폰 번호 (숫자만 입력)`,
    `Mobile number (Number only)`,
    't_step1_email.placeholder5',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder6: [`인증번호`, `Verification code`, 't_step1_email.placeholder6', ``, ``, ``, ``],
  checkbox_all: [`모두 선택`, `Select all`, 't_step1_email.checkbox_all', ``, ``, ``, ``],
  checkbox1: [
    `[필수] 18세 이상입니다`,
    `[Required] I am 18 years old or older.`,
    't_step1_email.checkbox1',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox2: (privacy_policy) => [
    <>[필수] {privacy_policy}에 동의합니다</>,
    <>[Required] I agree to the {privacy_policy}.</>,
    't_step1_email.checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox3: (terms_of_service) => [
    <>[필수] {terms_of_service}에 동의합니다</>,
    <>[Required] I agree to the {terms_of_service}.</>,
    't_step1_email.checkbox3',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox4: [
    `[선택] 이벤트 및 혜택 소식을 수신하고 싶습니다`,
    `[Optional] I want to receive Ringle Teens' exclusive promotions and latest news.`,
    't_step1_email.checkbox4',
    ``,
    ``,
    ``,
    ``,
  ],
  text_btn1: [`링글 틴즈 개인정보 취급 방침`, `Teens’ privacy policy`, 't_step1_email.text_btn1', ``, ``, ``, ``],
  text_btn2: [`링글 틴즈 이용약관`, `Teens’ terms of service`, 't_step1_email.text_btn2', ``, ``, ``, ``],
  btn1: [`인증번호 요청`, `Request Verification Code`, 't_step1_email.btn1', ``, ``, ``, ``],
  btn2: [`다음`, `Create & Next`, 't_step1_email.btn2', ``, ``, ``, ``],
  btn3: [`인증하기`, `Verify`, 't_step1_email.btn3', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 적어주세요`, `Please enter email correctly`, 't_step1_email.msg1', ``, ``, ``, ``],
  msg2: [
    `비밀번호는 6자 이상 되어야 합니다.`,
    `Your password must be at least 6 characters.`,
    't_step1_email.msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  msg3: [`비밀번호를 인증해주세요`, `Please confirm your password`, 't_step1_email.msg3', ``, ``, ``, ``],
  msg4: [
    `이름에는 2자 이상을 입력해주세요`,
    `Please enter at least 2 characters in the name`,
    't_step1_email.msg4',
    ``,
    ``,
    ``,
    ``,
  ],
  update: [`업데이트`, `Update`, 't_step1_email.update', ``, ``, ``, ``],
  btn_check: [`사용 가능 여부 확인`, `Check Availability`, 't_step1_email.btn_check', ``, ``, ``, ``],
  msg_success: [`사용 가능한 이메일입니다.`, `This email is available.`, 't_step1_email.msg_success', ``, ``, ``, ``],
  modal_title: [
    `이미 링글 계정이 있으신가요?`,
    `It seems you already have a Ringle account.`,
    't_step1_email.modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_subtitle1: [
    `링글과 동일한 계정을 사용하고 싶으시다면`,
    `Please verify your account using your Ringle login credentials`,
    't_step1_email.modal_subtitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_subtitle2: [
    `링글 로그인 정보를 통해 계정 인증을 해주세요`,
    `if you want to use the same account for Ringle Teens.`,
    't_step1_email.modal_subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_placeholder1: [
    `이메일 주소를 입력해주세요`,
    `Enter your email adress`,
    't_step1_email.modal_placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_placeholder2: [
    `비밀번호를 입력해주세요`,
    `Enter your password`,
    't_step1_email.modal_placeholder2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn: [`로그인`, `Login`, 't_step1_email.modal_btn', ``, ``, ``, ``],
  modal_forgot: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_step1_email.modal_forgot', ``, ``, ``, ``],
  modal_desc: [`소셜 계정으로 로그인`, `Log in with`, 't_step1_email.modal_desc', ``, ``, ``, ``],
  modal_msg1: [
    `올바른 이메일을 적어주세요`,
    `Please enter a vaild Email address`,
    't_step1_email.modal_msg1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_msg2: [
    `존재하지 않는 비밀번호입니다`,
    `Did not match stored password`,
    't_step1_email.modal_msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_tooltip: [
    <>
      링글 혹은 링글 틴즈 계정의
      <br />
      비밀번호를 변경하시면
      <br />두 계정 모두에 반영됩니다.
    </>,
    <>
      Changing the password for either
      <br />
      Ringle or Ringle Teens will automatically
      <br />
      change both passwords.
    </>,
    't_step1_email.modal_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  msg_failure: [
    `해당 이메일로 가입한 계정이 이미 존재합니다.`,
    `The account registered under this email already exists.`,
    't_step1_email.msg_failure',
    ``,
    ``,
    ``,
    ``,
  ],
  check_child_email: [
    `자녀의 이메일 계정을 확인해주세요.`,
    `Please check child's email address.`,
    't_step1_email.check_child_email',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_step1_social = {
  title: [`학부모 계정을 생성하세요`, `Create your parental account`, 't_step1_social.title', ``, ``, ``, ``],
  desc1: [`기본 정보`, `Basic Info`, 't_step1_social.desc1', ``, ``, ``, ``],
  desc2: [`휴대폰 인증하기`, `Phone number verification`, 't_step1_social.desc2', ``, ``, ``, ``],
  placeholder2: [`이름을 적어주세요.`, `Enter your name`, 't_step1_social.placeholder2', ``, ``, ``, ``],
  checkbox_all: [`모두 선택`, `Select all`, 't_step1_social.checkbox_all', ``, ``, ``, ``],
  checkbox1: [
    `[필수] 18세 이상입니다`,
    `[Required] I am 18 years old or older.`,
    't_step1_social.checkbox1',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox2: (privacy_policy) => [
    <>[필수] {privacy_policy}에 동의합니다</>,
    <>[Required] I agree to the {privacy_policy}.</>,
    't_step1_social.checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox3: (terms_of_service) => [
    <>[필수] {terms_of_service}에 동의합니다</>,
    <>[Required] I agree to the {terms_of_service}.</>,
    't_step1_social.checkbox3',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox4: [
    `[선택] 이벤트 및 혜택 소식을 수신하고 싶습니다`,
    `[Optional] I want to receive Ringle Teens' exclusive promotions and latest news.`,
    't_step1_social.checkbox4',
    ``,
    ``,
    ``,
    ``,
  ],
  text_btn1: [`링글 틴즈 개인정보 취급 방침`, `Teens’ privacy policy.`, 't_step1_social.text_btn1', ``, ``, ``, ``],
  text_btn2: [`링글 틴즈 이용약관`, `Teens’ terms of service.`, 't_step1_social.text_btn2', ``, ``, ``, ``],
  btn1: [`인증번호 요청`, `Request Verification Code`, 't_step1_social.btn1', ``, ``, ``, ``],
  btn2: [`생성하고 넘어가기`, `Create & Next`, 't_step1_social.btn2', ``, ``, ``, ``],
  btn3: [`인증하기`, `Verify`, 't_step1_social.btn3', ``, ``, ``, ``],
  update: [`업데이트`, `Update`, 't_step1_social.update', ``, ``, ``, ``],
}
export const t_step2 = {
  toast: [`계정 생성이 완료되었습니다!`, `Your account was successfully created!`, 't_step2.toast', ``, ``, ``, ``],
  title: [
    <>
      자녀 계정을 생성하고
      <br />
      무료 수업권을 받아보세요.
    </>,
    <>
      Create your child’s account
      <br />
      to redeem your Free Lesson.
    </>,
    't_step2.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle: [
    `부모계정 하나 당 한개의 무료 수업권이 지급됩니다`,
    `One free lesson limit per parent`,
    't_step2.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [`자녀 이름`, `Child’s Name`, 't_step2.desc1', ``, ``, ``, ``],
  desc2: [`자녀 생년월일`, `Child’s Birthday`, 't_step2.desc2', ``, ``, ``, ``],
  desc3: [`타임존`, `Time Zone`, 't_step2.desc3', ``, ``, ``, ``],
  placeholder1: [`이름`, `First Name`, 't_step2.placeholder1', ``, ``, ``, ``],
  placeholder2: [`성`, `Last Name`, 't_step2.placeholder2', ``, ``, ``, ``],
  placeholder3: [`자녀 계정 이메일 (ID)`, `Email (ID) for child’s account`, 't_step2.placeholder3', ``, ``, ``, ``],
  placeholder4: [
    `자녀 계정 비밀번호 (최소 6자)`,
    `Password for child’s account (At least 6 characters)`,
    't_step2.placeholder4',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder5: [`비밀번호를 다시 입력해주세요`, `Re-enter password`, 't_step2.placeholder5', ``, ``, ``, ``],
  mm: [`월`, `mm`, 't_step2.mm', ``, ``, ``, ``],
  dd: [`일`, `dd`, 't_step2.dd', ``, ``, ``, ``],
  yyyy: [`연도`, `yyyy`, 't_step2.yyyy', ``, ``, ``, ``],
  title2: [
    `자녀가 직접 로그인을 할 수 있도록 하고 싶으신가요?`,
    `Do you wish your child to have their own login credentials?`,
    't_step2.title2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle2: [
    <>
      학생은 본인의 계정이 없더라도 부모의 계정으로
      <br />
      링글 틴즈를 사용할 수 있습니다.
    </>,
    <>
      Your child(s) can access Ringle Teens using a parent’s login
      <br />
      credentials without their own login credentials.
    </>,
    't_step2.subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  radio1: [`예`, `Yes`, 't_step2.radio1', ``, ``, ``, ``],
  radio2: [`아니오`, `No`, 't_step2.radio2', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 적어주세요`, `Please enter email correctly`, 't_step2.msg1', ``, ``, ``, ``],
  msg2: [
    `비밀번호는 6자 이상 되어야 합니다.`,
    `Your password must be at least 6 characters.`,
    't_step2.msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  msg3: [`비밀번호를 인증해주세요`, `Please confirm your password`, 't_step2.msg3', ``, ``, ``, ``],
  tooltip: [
    `이후에도 학부모 대시보드에서 자녀 계정을<br>추가하실 수 있습니다. 단, 부모계정 하나 당 한개의 무료 수업권이 지급된다는 점을 유의해주세요.`,
    `You can add another child’s account later in the dashboard. But be sure that only one free lesson is available per parent.`,
    't_step2.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  btn2: [`다음`, `Create & Next`, 't_step2.btn2', ``, ``, ``, ``],
}
export const t_step3 = {
  toast: [`거의 다 마무리 되었습니다!`, `Great! You are almost done.`, 't_step3.toast', ``, ``, ``, ``],
  title: (name) => [
    `${name}에 대해서 더 자세히 알려주세요!`,
    `Tell us more about ${name}`,
    't_step3.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle1: (name) => [
    <>
      이 질문들을 통해 링글 틴즈의 튜터들은 {name}을(를)
      <br /> 더 잘 이해하고 효과적인 수업을 진행할 수 있습니다.
    </>,
    <>
      These questions will help our tutors better understand {name}
      <br />
      so they can conduct more efficient lessons.
    </>,
    't_step3.subtitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle2: [
    `입력한 답변은 이후에 대시보드에서 수정하실 수 있습니다`,
    `You can edit your answers later in the dashboard.`,
    't_step3.subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  question1: (name) => [
    `${name}(이)가 영어를 자유롭게 구사하나요?`,
    `Is English ${name}’s primary language?`,
    't_step3.question1',
    ``,
    ``,
    ``,
    ``,
  ],
  question2: (name) => [
    <>
      {name}가 링글 틴즈를 통해 무엇을
      <br />
      얻어갔으면 좋겠나요? (복수 선택 가능)
    </>,
    <>
      What do you hope for {name} to accomplish using
      <br /> Ringle Teens? (multiple choice)
    </>,
    't_step3.question2',
    ``,
    ``,
    ``,
    ``,
  ],
  question3: (name) => [
    <>
      {name}에게 딱 맞는 수업 교재를 추천할 수 있도록
      <br />
      주제를 선택해주세요 (세개 이상)
    </>,
    <>
      Would you select topics for us to recommend
      <br />
      {name}’s lesson materials? (3 and more)
    </>,
    't_step3.question3',
    ``,
    ``,
    ``,
    ``,
  ],
  radio1: [`예`, `Yes`, 't_step3.radio1', ``, ``, ``, ``],
  radio2: [`아니오`, `No`, 't_step3.radio2', ``, ``, ``, ``],
  checkbox1: [`비판적 사고 능력`, `Critical Thinking`, 't_step3.checkbox1', ``, ``, ``, ``],
  checkbox2: [`창의적 사고 능력`, `Creative Thinking`, 't_step3.checkbox2', ``, ``, ``, ``],
  checkbox3: [`소통 능력`, `Communication`, 't_step3.checkbox3', ``, ``, ``, ``],
  checkbox4: [`집중력`, `Concentration`, 't_step3.checkbox4', ``, ``, ``, ``],
  btn1: [`링글 틴즈 가입 마무리 하기`, `Complete Teens Sign Up`, 't_step3.btn1', ``, ``, ``, ``],
  modal_title: [`가입 그만두기`, `Leave Sign Up`, 't_step3.modal_title', ``, ``, ``, ``],
  modal_desc: [
    `아직 링글 틴즈 가입을 완료하지 않으셨습니다. 정말로 그만두시겠습니까?`,
    `You haven’t completed Teens sign up yet. Are you sure you want to log out ?`,
    't_step3.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn1: [`계속하기`, `Stay & Complete`, 't_step3.modal_btn1', ``, ``, ``, ``],
  modal_btn2: [`로그아웃`, `Log Out`, 't_step3.modal_btn2', ``, ``, ``, ``],
  primary_language: [
    `학생이 가장 잘 이해하고 자주 사용하는<br>언어가 학생의 모국어 입니다.`,
    `Primary language is the language the student best understands and uses most frequently to communicate with others.`,
    't_step3.primary_language',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_dashboard_childaccount = {
  basic_info: {
    title: [`기본 정보`, `Basic Info`, 't_dashboard_childaccount.basic_info.title', ``, ``, ``, ``],
    email_id: [`이메일 ID`, `Email ID`, 't_dashboard_childaccount.basic_info.email_id', ``, ``, ``, ``],
    set_login: [
      `로그인 정보를 입력해주세요`,
      `Set Login Credentials`,
      't_dashboard_childaccount.basic_info.set_login',
      ``,
      ``,
      ``,
      ``,
    ],
    first_name: [`이름`, `First Name`, 't_dashboard_childaccount.basic_info.first_name', ``, ``, ``, ``],
    last_name: [`성`, `Last Name`, 't_dashboard_childaccount.basic_info.last_name', ``, ``, ``, ``],
    birthday: [`생년월일`, `Birthday`, 't_dashboard_childaccount.basic_info.birthday', ``, ``, ``, ``],
    primary_language: (name) => [
      `${name}에게 영어가 모국어인가요?`,
      `Is English ${name}’s primary language?`,
      't_dashboard_childaccount.basic_info.primary_language',
      ``,
      ``,
      ``,
      ``,
    ],
    yes: [`예`, `Yes`, 't_dashboard_childaccount.basic_info.yes', ``, ``, ``, ``],
    no: [`아니오`, `No`, 't_dashboard_childaccount.basic_info.no', ``, ``, ``, ``],
    button_save: [`저장`, `Save`, 't_dashboard_childaccount.basic_info.button_save', ``, ``, ``, ``],
    dialog_1: {
      title: [
        `자녀 로그인 정보`,
        `Child’s Login Credentials`,
        't_dashboard_childaccount.basic_info.dialog_1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `학생은 본인의 계정이 없더라도 부모의 계정으로 링글 틴즈에 로그인할 수 있습니다.`,
        `Your child(ren) can access Ringle Teens using a parent’s login credentials without their own login credentials.`,
        't_dashboard_childaccount.basic_info.dialog_1.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자녀 계정 ID (6자 이상)`,
        `ID (Email) for child’s account`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `자녀 계정 비밀번호 (6자 이상)`,
        `Password for child’s account (At least 6 characters)`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder3: [
        `비밀번호를 한번 더 입력해주세요`,
        `Re-enter password`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`취소`, `Cancel`, 't_dashboard_childaccount.basic_info.dialog_1.button1', ``, ``, ``, ``],
      button2: [`생성하기`, `Create`, 't_dashboard_childaccount.basic_info.dialog_1.button2', ``, ``, ``, ``],
      error1: [
        `올바른 이메일을 입력해주세요`,
        `Please enter email correctly`,
        't_dashboard_childaccount.basic_info.dialog_1.error1',
        ``,
        ``,
        ``,
        ``,
      ],
      error2: [
        `비밀번호는 6자 이상 입력해주세요`,
        `Your password must be at least 6 characters`,
        't_dashboard_childaccount.basic_info.dialog_1.error2',
        ``,
        ``,
        ``,
        ``,
      ],
      error3: [
        `비밀번호를 인증해주세요`,
        `Please confirm your password`,
        't_dashboard_childaccount.basic_info.dialog_1.error3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  edit_profile: {
    title: [`의 프로필`, `’s Profile`, 't_dashboard_childaccount.edit_profile.title', ``, ``, ``, ``],
    detail: [
      `학생의 프로필은 튜터들만 볼 수 있고, 언제든지 수정할 수 있습니다.`,
      `Only tutors can see your profile and it can be modified at any time.`,
      't_dashboard_childaccount.edit_profile.detail',
      ``,
      ``,
      ``,
      ``,
    ],
    required: [`필수`, `Required`, 't_dashboard_childaccount.edit_profile.required', ``, ``, ``, ``],
    optional: [`선택`, `Optional`, 't_dashboard_childaccount.edit_profile.optional', ``, ``, ``, ``],
    preview: [`미리보기`, `Preview`, 't_dashboard_childaccount.edit_profile.preview', ``, ``, ``, ``],
    skills_to_develop: {
      title: [
        `기르고자 하는 능력 (4Cs)`,
        `Skills to Develop (4Cs)`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: (name) => [
        `${name}이 링글 틴즈 수업을 통해 어떤 능력을 기르면 좋겠나요?`,
        `Select skills you want ${name}to develop by taking Ringle Teens lessons.`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `비판적 사고 능력`,
        `Critical Thinking`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `창의적 사고 능력`,
        `Creative Thinking`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `소통능력`,
        `Communication`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `집중력`,
        `Concentration`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    lesson_goals: {
      title: [
        `링글 틴즈 수업을 통해 이루고자 하는 것은?`,
        `Lesson Goals from Ringle Teens`,
        't_dashboard_childaccount.edit_profile.lesson_goals.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈에서 이루고자 하는 목표를 선택해주세요`,
        `Select your goals from Ringle Teens lessons.`,
        't_dashboard_childaccount.edit_profile.lesson_goals.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `언어 및 어휘 능력을 기르기 위해`,
        `To enhance language and vocabulary skills`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `다양한 주제에 대해 배우기 위해`,
        `To gain knowledge on diverse themes`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기 위해`,
        `To better understand and set goals for college life/college prep`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기 위해`,
        `To explore career paths and understand life after college`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list4',
        ``,
        ``,
        ``,
        ``,
      ],
      list5: [
        `나의 성장을 위한 시간`,
        `To learn for the sake of learning`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list5',
        ``,
        ``,
        ``,
        ``,
      ],
      list6: [
        `튜터/멘토들로부터 영감을 받고 배우기 위해서`,
        `To be inspired by and learn from tutors/mentors`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list6',
        ``,
        ``,
        ``,
        ``,
      ],
      list7: [`기타`, `Others`, 't_dashboard_childaccount.edit_profile.lesson_goals.list7', ``, ``, ``, ``],
      list7_placeholder: [
        `다른 코멘트 남기기`,
        `Enter other comments`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list7_placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    materials_topics: {
      title: [
        `어떤 주제의 교재로 수업할까요?`,
        `Materials Topics`,
        't_dashboard_childaccount.edit_profile.materials_topics.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        <>
          관심 있는 주제를 3개 이상 선택해주세요. 선택을 반영하여 맞춤
          <br />
          수업 교재를 추천해드립니다.
        </>,
        `Select 3 topics (or more) of your choice. This would help us recommend intriguing lesson materials to you.`,
        't_dashboard_childaccount.edit_profile.materials_topics.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_dashboard_childaccount.edit_profile.materials_topics.button1', ``, ``, ``, ``],
      detail_styled: [
        <>
          <Div spanTag textPrimary>
            관심 있는 주제를 3개 이상 선택해주세요.
          </Div>{' '}
          선택을 반영하여 맞춤 수업 교재를 추천해드립니다.
        </>,
        <>
          <Div spanTag textPrimary>
            Select 3 topics (or more) of your choice.
          </Div>{' '}
          This would help us recommend intriguing lesson materials to you.
        </>,
        't_dashboard_childaccount.edit_profile.materials_topics.detail_styled',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    interests: {
      title: [`관심사`, `Interests`, 't_dashboard_childaccount.edit_profile.interests.title', ``, ``, ``, ``],
      detail: [
        <>
          관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를
          <br />
          해보세요.
        </>,
        `Your selection helps further a smooth conversation between you and your tutor.`,
        't_dashboard_childaccount.edit_profile.interests.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_dashboard_childaccount.edit_profile.interests.button1', ``, ``, ``, ``],
      message1: [
        `관심사를 선택해주세요`,
        `Select your interests`,
        't_dashboard_childaccount.edit_profile.interests.message1',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    about_me: {
      title: [`나에 관하여`, `About Me`, 't_dashboard_childaccount.edit_profile.about_me.title', ``, ``, ``, ``],
      detail: [
        `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
        `Your introduction helps your tutors lead the lesson efficiently.`,
        't_dashboard_childaccount.edit_profile.about_me.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      category1: [
        `나에 관하여 (50자 이상)`,
        `About Me (at least 50 words)`,
        't_dashboard_childaccount.edit_profile.about_me.category1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
        `Please enter who you are, where you live, what school you go to, etc.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      category2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Things I’m Good at or Things I Like`,
        't_dashboard_childaccount.edit_profile.about_me.category2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Please write about what you are good at or like.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      category3: [`나의 꿈`, `My Dreams`, 't_dashboard_childaccount.edit_profile.about_me.category3', ``, ``, ``, ``],
      placeholder3: [
        `꿈이 무엇인가요?`,
        `Please write about your dream.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      category4: [
        `학교 생활`,
        `My School Life`,
        't_dashboard_childaccount.edit_profile.about_me.category4',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder4: [
        `학교 생활에 대해 알려주세요.`,
        `Please write about your school life.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      category5: [
        `요즘 가지고 있는 고민들`,
        `My Current Worries`,
        't_dashboard_childaccount.edit_profile.about_me.category5',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `현재 고민거리들은 무엇인가요?`,
        `Please write about your current worries.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_photo: {
      title: [
        `프로필 사진`,
        `Profile Photo`,
        't_dashboard_childaccount.edit_profile.profile_photo.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_video: {
      title: [
        `프로필 비디오`,
        `Profile Video`,
        't_dashboard_childaccount.edit_profile.profile_video.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    button_save: [`저장하기`, `Save`, 't_dashboard_childaccount.edit_profile.button_save', ``, ``, ``, ``],
    photo: {
      button1: [`사진 업로드`, `Upload picture`, 't_dashboard_childaccount.edit_profile.photo.button1', ``, ``, ``, ``],
      button2: [`사진 변경하기`, `Change photo`, 't_dashboard_childaccount.edit_profile.photo.button2', ``, ``, ``, ``],
    },
    video: {
      button1: [`녹음본 변경`, `Record video`, 't_dashboard_childaccount.edit_profile.video.button1', ``, ``, ``, ``],
      button2: [`비디오 변경`, `Change video`, 't_dashboard_childaccount.edit_profile.video.button2', ``, ``, ``, ``],
    },
  },
}
export const t_materials_topics = {
  category1: {
    title: [`가벼운 주제`, `Everyday Talk`, 't_materials_topics.category1.title', ``, ``, ``, ``],
    chip1: [`생활과 테크`, `Lifestyle & Tech`, 't_materials_topics.category1.chip1', ``, ``, ``, ``],
    chip2: [`재미`, `Fun`, 't_materials_topics.category1.chip2', ``, ``, ``, ``],
    chip3: [`가족과 친구`, `Relationships`, 't_materials_topics.category1.chip3', ``, ``, ``, ``],
    chip4: [`음식`, `Food`, 't_materials_topics.category1.chip4', ``, ``, ``, ``],
    chip5: [`방학`, `Holidays`, 't_materials_topics.category1.chip5', ``, ``, ``, ``],
    chip6: [`동물`, `Animals`, 't_materials_topics.category1.chip6', ``, ``, ``, ``],
    chip7: [`예술과 운동`, `Arts & Sports`, 't_materials_topics.category1.chip7', ``, ``, ``, ``],
  },
  category2: {
    title: [`책/엔터테인먼트`, `Books/Media`, 't_materials_topics.category2.title', ``, ``, ``, ``],
    chip1: [`북챗`, `Bookchats`, 't_materials_topics.category2.chip1', ``, ``, ``, ``],
    chip2: [`디즈니`, `Disney`, 't_materials_topics.category2.chip2', ``, ``, ``, ``],
    chip3: [`리딩`, `Reading`, 't_materials_topics.category2.chip3', ``, ``, ``, ``],
    chip4: [`예술과 운동`, `Arts & Sports`, 't_materials_topics.category2.chip4', ``, ``, ``, ``],
  },
  category3: {
    title: [`사회/시사`, `Social Studies\t`, 't_materials_topics.category3.title', ``, ``, ``, ``],
    chip1: [`친환경/지속가능성`, `Sustainability`, 't_materials_topics.category3.chip1', ``, ``, ``, ``],
    chip2: [`역사와 정치`, `History & Politics`, 't_materials_topics.category3.chip2', ``, ``, ``, ``],
    chip3: [`일상과 테크`, `Lifestyle & Tech`, 't_materials_topics.category3.chip3', ``, ``, ``, ``],
    chip4: [`비즈니스와 경제`, `Biz & Econ`, 't_materials_topics.category3.chip4', ``, ``, ``, ``],
    chip5: [`사회`, `Society`, 't_materials_topics.category3.chip5', ``, ``, ``, ``],
  },
  category4: {
    title: [`과학/테크`, `Science/Tech`, 't_materials_topics.category4.title', ``, ``, ``, ``],
    chip1: [`과학`, `Science`, 't_materials_topics.category4.chip1', ``, ``, ``, ``],
    chip2: [`테크`, `Tech`, 't_materials_topics.category4.chip2', ``, ``, ``, ``],
  },
  category5: {
    title: [`공부하는 방법`, `Study Tips`, 't_materials_topics.category5.title', ``, ``, ``, ``],
    chip1: [`대학과 전공`, `College & Life`, 't_materials_topics.category5.chip1', ``, ``, ``, ``],
    chip2: [`배운다는 것은 무엇일까요?`, `Learning`, 't_materials_topics.category5.chip2', ``, ``, ``, ``],
    chip3: [`대학 원서`, `College Apps`, 't_materials_topics.category5.chip3', ``, ``, ``, ``],
    chip4: [`영어라는 언어`, `Language Arts`, 't_materials_topics.category5.chip4', ``, ``, ``, ``],
  },
}
export const t_interests = {
  category1: {
    title: [`취미`, `Hobbies`, 't_interests.category1.title', ``, ``, ``, ``],
    chip1: [`쓰기`, `Writing`, 't_interests.category1.chip1', ``, ``, ``, ``],
    chip2: [`책`, `Books`, 't_interests.category1.chip2', ``, ``, ``, ``],
    chip3: [`음악`, `Music`, 't_interests.category1.chip3', ``, ``, ``, ``],
    chip4: [`장난감`, `Toys`, 't_interests.category1.chip4', ``, ``, ``, ``],
    chip5: [`여행`, `Traveling`, 't_interests.category1.chip5', ``, ``, ``, ``],
    chip6: [`음식`, `Food`, 't_interests.category1.chip6', ``, ``, ``, ``],
    chip7: [`요리`, `Cooking`, 't_interests.category1.chip7', ``, ``, ``, ``],
    chip8: [`비디오 게임`, `Video Games`, 't_interests.category1.chip8', ``, ``, ``, ``],
    chip9: [`패션`, `Fashion`, 't_interests.category1.chip9', ``, ``, ``, ``],
    chip10: [`뷰티`, `Beauty`, 't_interests.category1.chip10', ``, ``, ``, ``],
  },
  category2: {
    title: [`미디어/엔터테인먼트`, `Media/Entertainment`, 't_interests.category2.title', ``, ``, ``, ``],
    chip1: [`영화`, `Movies`, 't_interests.category2.chip1', ``, ``, ``, ``],
    chip2: [`유튜브`, `YouTube`, 't_interests.category2.chip2', ``, ``, ``, ``],
    chip3: [`넷플릭스`, `Netflix`, 't_interests.category2.chip3', ``, ``, ``, ``],
    chip4: [`만화`, `Cartoons`, 't_interests.category2.chip4', ``, ``, ``, ``],
    chip5: [`포켓몬`, `Pokemon`, 't_interests.category2.chip5', ``, ``, ``, ``],
    chip6: [`디즈니`, `Disney`, 't_interests.category2.chip6', ``, ``, ``, ``],
    chip7: [`마블`, `Marvel`, 't_interests.category2.chip7', ``, ``, ``, ``],
    chip8: [`할리우드`, `Hollywood`, 't_interests.category2.chip8', ``, ``, ``, ``],
    chip9: [`케이팝`, `K-Pop`, 't_interests.category2.chip9', ``, ``, ``, ``],
    chip10: [`연예인들`, `Celebrities`, 't_interests.category2.chip10', ``, ``, ``, ``],
  },
  category3: {
    title: [`문화/예술`, `Culture/Arts`, 't_interests.category3.title', ``, ``, ``, ``],
    chip1: [`예술과 공예`, `Arts and Crafts`, 't_interests.category3.chip1', ``, ``, ``, ``],
    chip2: [`시각 예술`, `Visual Arts`, 't_interests.category3.chip2', ``, ``, ``, ``],
    chip3: [`사진`, `Photography`, 't_interests.category3.chip3', ``, ``, ``, ``],
    chip4: [`춤`, `Dance`, 't_interests.category3.chip4', ``, ``, ``, ``],
    chip5: [`드라마`, `Drama`, 't_interests.category3.chip5', ``, ``, ``, ``],
  },
  category4: {
    title: [`학교 생활`, `School Life`, 't_interests.category4.title', ``, ``, ``, ``],
    chip1: [`배움`, `Learning`, 't_interests.category4.chip1', ``, ``, ``, ``],
    chip2: [`학교 생활`, `School Life`, 't_interests.category4.chip2', ``, ``, ``, ``],
    chip3: [`대학 생활`, `College Life`, 't_interests.category4.chip3', ``, ``, ``, ``],
    chip4: [`대입 준비`, `College Prep`, 't_interests.category4.chip4', ``, ``, ``, ``],
    chip5: [`과외 활동`, `Extracurriculars`, 't_interests.category4.chip5', ``, ``, ``, ``],
  },
  category5: {
    title: [`인문학`, `Liberal Arts`, 't_interests.category5.title', ``, ``, ``, ``],
    chip1: [`수학`, `Math`, 't_interests.category5.chip1', ``, ``, ``, ``],
    chip2: [`천문학`, `Astronomy`, 't_interests.category5.chip2', ``, ``, ``, ``],
    chip3: [`과학`, `Science`, 't_interests.category5.chip3', ``, ``, ``, ``],
    chip4: [`자연`, `Nature`, 't_interests.category5.chip4', ``, ``, ``, ``],
    chip5: [`언어`, `Languages`, 't_interests.category5.chip5', ``, ``, ``, ``],
    chip6: [`역사`, `History`, 't_interests.category5.chip6', ``, ``, ``, ``],
    chip7: [`역사적 인물들`, `Historical Figures`, 't_interests.category5.chip7', ``, ``, ``, ``],
    chip8: [`정치`, `Politics`, 't_interests.category5.chip8', ``, ``, ``, ``],
  },
  category6: {
    title: [`운동/취미`, `Sports/Leisure`, 't_interests.category6.title', ``, ``, ``, ``],
    chip1: [`운동`, `Sports`, 't_interests.category6.chip1', ``, ``, ``, ``],
    chip2: [`건강`, `Health`, 't_interests.category6.chip2', ``, ``, ``, ``],
    chip3: [`피트니스`, `Fitness`, 't_interests.category6.chip3', ``, ``, ``, ``],
    chip4: [`야외 활동`, `Outdoor Activities`, 't_interests.category6.chip4', ``, ``, ``, ``],
  },
  category7: {
    title: [`IT/비즈니스`, `IT/Business`, 't_interests.category7.title', ``, ``, ``, ``],
    chip1: [`기업가 정신`, `Entrepreneurship`, 't_interests.category7.chip1', ``, ``, ``, ``],
    chip2: [`비즈니스`, `Business`, 't_interests.category7.chip2', ``, ``, ``, ``],
    chip3: [`기술`, `Technology`, 't_interests.category7.chip3', ``, ``, ``, ``],
    chip4: [`메타버스`, `Metaverse`, 't_interests.category7.chip4', ``, ``, ``, ``],
  },
  category8: {
    title: [`일상대화`, `Small Talk`, 't_interests.category8.title', ``, ``, ``, ``],
    chip1: [`우정`, `Friendship`, 't_interests.category8.chip1', ``, ``, ``, ``],
    chip2: [`MBTI`, `MBTI`, 't_interests.category8.chip2', ``, ``, ``, ``],
    chip3: [`반려동물`, `Pets`, 't_interests.category8.chip3', ``, ``, ``, ``],
    chip4: [`동물은 사랑입니다`, `Animals`, 't_interests.category8.chip4', ``, ``, ``, ``],
  },
}
export const t_account_setting = {
  basic_info_menu: [`기본 정보`, `Basic Info`, 't_account_setting.basic_info_menu', ``, ``, ``, ``],
  edit_profile_menu: [`프로필 편집`, `Edit Profile`, 't_account_setting.edit_profile_menu', ``, ``, ``, ``],
  change_password_menu: [`비밀번호 변경`, `Change Password`, 't_account_setting.change_password_menu', ``, ``, ``, ``],
  basic_info: {
    title: [`기본 정보`, `Basic Info`, 't_account_setting.basic_info.title', ``, ``, ``, ``],
    email_id: [`이메일 ID`, `Email ID`, 't_account_setting.basic_info.email_id', ``, ``, ``, ``],
    first_name: [`이름`, `First Name`, 't_account_setting.basic_info.first_name', ``, ``, ``, ``],
    last_name: [`성`, `Last Name`, 't_account_setting.basic_info.last_name', ``, ``, ``, ``],
    birthday: [`생년월일`, `Birthday`, 't_account_setting.basic_info.birthday', ``, ``, ``, ``],
    primary_language: [
      `영어가 모국어이신가요?`,
      `Is English my primary language?`,
      't_account_setting.basic_info.primary_language',
      ``,
      ``,
      ``,
      ``,
    ],
    yes: [`예`, `Yes`, 't_account_setting.basic_info.yes', ``, ``, ``, ``],
    no: [`아니오`, `No`, 't_account_setting.basic_info.no', ``, ``, ``, ``],
    button_save: [`저장`, `Save`, 't_account_setting.basic_info.button_save', ``, ``, ``, ``],
  },
  edit_profile: {
    title: [`프로필 편집`, `Edit Profile`, 't_account_setting.edit_profile.title', ``, ``, ``, ``],
    detail: [
      `학생의 프로필은 튜터들만 볼 수 있고, 언제든지 수정할 수 있습니다.`,
      `Only tutors can see your profile and it can be modified at any time.`,
      't_account_setting.edit_profile.detail',
      ``,
      ``,
      ``,
      ``,
    ],
    required: [`필수`, `Required`, 't_account_setting.edit_profile.required', ``, ``, ``, ``],
    optional: [`선택`, `Optional`, 't_account_setting.edit_profile.optional', ``, ``, ``, ``],
    preview: [`미리보기`, `Preview`, 't_account_setting.edit_profile.preview', ``, ``, ``, ``],
    skills_to_develop: {
      title: [
        `기르고자 하는 능력 (4Cs)`,
        `Skills to Develop (4Cs)`,
        't_account_setting.edit_profile.skills_to_develop.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈 수업을 통해 향상시키고 싶은 능력을 선택하세요.`,
        `Select skills you want to develop by taking Ringle Teens lessons.`,
        't_account_setting.edit_profile.skills_to_develop.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `비판적 사고 능력`,
        `Critical Thinking`,
        't_account_setting.edit_profile.skills_to_develop.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `창의적 사고 능력`,
        `Creative Thinking`,
        't_account_setting.edit_profile.skills_to_develop.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [`소통 능력`, `Communication`, 't_account_setting.edit_profile.skills_to_develop.list3', ``, ``, ``, ``],
      list4: [`집중력`, `Concentration`, 't_account_setting.edit_profile.skills_to_develop.list4', ``, ``, ``, ``],
    },
    lesson_goals: {
      title: [
        `링글 틴즈 수업을 통해 이루고자 하는 것은?`,
        `Lesson Goals from Ringle Teens`,
        't_account_setting.edit_profile.lesson_goals.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈에서 이루고자 하는 목표를 선택해주세요`,
        `Select your goals from Ringle Teens lessons.`,
        't_account_setting.edit_profile.lesson_goals.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `언어와 어휘 실력을 증진시키기 위해`,
        `To enhance language and vocabulary skills`,
        't_account_setting.edit_profile.lesson_goals.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `다양한 주제에 대해 배우기 위해`,
        `To gain knowledge on diverse themes`,
        't_account_setting.edit_profile.lesson_goals.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기 위해`,
        `To better understand and set goals for college life/college prep`,
        't_account_setting.edit_profile.lesson_goals.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기 위해`,
        `To explore career paths and understand life after college`,
        't_account_setting.edit_profile.lesson_goals.list4',
        ``,
        ``,
        ``,
        ``,
      ],
      list5: [
        `나의 성장을 위한 시간`,
        `To learn for the sake of learning`,
        't_account_setting.edit_profile.lesson_goals.list5',
        ``,
        ``,
        ``,
        ``,
      ],
      list6: [
        `튜터/멘토들로부터 좋은 영향을 받기 위해`,
        `To be inspired by and learn from tutors/mentors`,
        't_account_setting.edit_profile.lesson_goals.list6',
        ``,
        ``,
        ``,
        ``,
      ],
      list7: [`기타`, `Others`, 't_account_setting.edit_profile.lesson_goals.list7', ``, ``, ``, ``],
      list7_placeholder: [
        `다른 코멘트 남기기`,
        `Enter other comments`,
        't_account_setting.edit_profile.lesson_goals.list7_placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    materials_topics: {
      title: [
        `어떤 주제의 교재로 수업할까요?`,
        `Materials Topics`,
        't_account_setting.edit_profile.materials_topics.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        <>
          관심 있는 주제를 3개 이상 선택해주세요. 선택을 반영하여 맞춤
          <br />
          수업 교재를 추천해드립니다.
        </>,
        `Select  3 topics (or more) of your choice. This would help us recommend intriguing lesson materials to you.`,
        't_account_setting.edit_profile.materials_topics.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_account_setting.edit_profile.materials_topics.button1', ``, ``, ``, ``],
    },
    interests: {
      title: [`관심사`, `Interests`, 't_account_setting.edit_profile.interests.title', ``, ``, ``, ``],
      detail: [
        `관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를 해보세요.`,
        `Your selection helps further a smooth conversation between you and your tutor.`,
        't_account_setting.edit_profile.interests.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_account_setting.edit_profile.interests.button1', ``, ``, ``, ``],
      message1: [
        `관심사를 선택해주세요`,
        `Select your interests`,
        't_account_setting.edit_profile.interests.message1',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    about_me: {
      title: [`나에 관하여`, `About Me`, 't_account_setting.edit_profile.about_me.title', ``, ``, ``, ``],
      detail: [
        `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
        `Your introduction helps your tutors lead the lesson efficiently.`,
        't_account_setting.edit_profile.about_me.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      category1: [
        `나에 관하여 (50자 이상)`,
        `About Me (at least 50 words)`,
        't_account_setting.edit_profile.about_me.category1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
        `Please enter who you are, where you live, what school you go to, etc.`,
        't_account_setting.edit_profile.about_me.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      category2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Things I’m Good at or Things I Like`,
        't_account_setting.edit_profile.about_me.category2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Please write about what you are good at or like.`,
        't_account_setting.edit_profile.about_me.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      category3: [`나의 꿈`, `My Dreams`, 't_account_setting.edit_profile.about_me.category3', ``, ``, ``, ``],
      placeholder3: [
        `꿈이 무엇인가요?`,
        `Please write about your dream.`,
        't_account_setting.edit_profile.about_me.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      category4: [`학교 생활`, `My School Life`, 't_account_setting.edit_profile.about_me.category4', ``, ``, ``, ``],
      placeholder4: [
        `학교 생활에 대해 알려주세요.`,
        `Please write about your school life.`,
        't_account_setting.edit_profile.about_me.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      category5: [
        `요즘 가지고 있는 고민들`,
        `My Current Worries`,
        't_account_setting.edit_profile.about_me.category5',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `현재 고민거리들은 무엇인가요?`,
        `Please write about your current worries.`,
        't_account_setting.edit_profile.about_me.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_photo: {
      title: [`프로필 사진`, `Profile Photo`, 't_account_setting.edit_profile.profile_photo.title', ``, ``, ``, ``],
    },
    profile_video: {
      title: [`프로필 비디오`, `Profile Video`, 't_account_setting.edit_profile.profile_video.title', ``, ``, ``, ``],
    },
    button_save: [`저장하기`, `Save`, 't_account_setting.edit_profile.button_save', ``, ``, ``, ``],
    photo: {
      button1: [`사진 업로드`, `Upload picture`, 't_account_setting.edit_profile.photo.button1', ``, ``, ``, ``],
      button2: [`사진 변경하기`, `Change photo`, 't_account_setting.edit_profile.photo.button2', ``, ``, ``, ``],
    },
    video: {
      button1: [`녹음본 변경하기`, `Record video`, 't_account_setting.edit_profile.video.button1', ``, ``, ``, ``],
      button2: [`비디오 변경`, `Change video`, 't_account_setting.edit_profile.video.button2', ``, ``, ``, ``],
    },
  },
}
export const t_child_intro = {
  title_default: (name) => [
    <>
      반갑습니다,{' '}
      <Div spanTag textPrimary>
        {name}
      </Div>
      !<br />
      본인에 대해서 더 자세히 알려주실래요?
    </>,
    <>
      Nice to meet you,{' '}
      <Div spanTag textPrimary>
        {name}
      </Div>
      !<br />
      Could you help us to get to know you better?
    </>,
    't_child_intro.title_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `이 질문들을 통해 링글 틴즈는 학생에게 더 의미있는 경험을 제공할 수 있습니다.`,
    `Asking these questions will help us provide you with a better experience on Ringle Teens.`,
    't_child_intro.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    <>
      완성하지 않고 나가면
      <br />
      답변이 저장이 되지 않으며, 처음부터 새로 시작해야합니다.
      <br />
      반드시 모든 것을 한번에 완성해주세요!
    </>,
    <>
      Your progress won’t be saved and you will need to restart the questionnaire
      <br />
      if leave without completing.
      <br />
      Make sure to complete it in one sitting!
    </>,
    't_child_intro.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`시작해볼까요?`, `Let's Start`, 't_child_intro.btn1', ``, ``, ``, ``],
}
export const t_child_step1 = {
  title1_default: [
    `링글 틴즈에서 이루고 싶은 목표가 무엇인가요?`,
    <>
      What are{' '}
      <Div spanTag textPrimary>
        your goals
      </Div>{' '}
      from Ringle Teens lessons?
    </>,
    't_child_step1.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  title2_default: [
    `이루고 싶은 목표를 모두 선택해주세요`,
    `Select as many as you like`,
    't_child_step1.title2_default',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox1: [
    `언어와 어휘 실력을 증진시키기`,
    `To enhance language and vocabulary skills`,
    't_child_step1.checkbox1',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox2: [
    `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기`,
    `To gain knowledge on diverse themes`,
    't_child_step1.checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox3: [
    `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기`,
    `To better understand and set goals for college life/college prep`,
    't_child_step1.checkbox3',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox4: [`나의 성장`, `To learn for the sake of learning`, 't_child_step1.checkbox4', ``, ``, ``, ``],
  checkbox5: [
    `튜터/멘토들로부터 좋은 영향을 받기`,
    `To be inspired by and learn from tutors/mentors`,
    't_child_step1.checkbox5',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox6: [`기타`, `Others`, 't_child_step1.checkbox6', ``, ``, ``, ``],
  placeholder: [`다른 코멘트 남기기`, `Enter other comments`, 't_child_step1.placeholder', ``, ``, ``, ``],
  btn1: [`다음`, `Next`, 't_child_step1.btn1', ``, ``, ``, ``],
}
export const t_child_step2 = {
  banner1: [`입력해주셔서 감사합니다!`, `Thank you for your input!`, 't_child_step2.banner1', ``, ``, ``, ``],
  title1_default: [
    `관심 있는 주제를 3가지 이상 골라주세요`,
    <>
      <Div spanTag textPrimary>
        Pick 3 topics
      </Div>{' '}
      (or more) of your choice
    </>,
    't_child_step2.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `선택을 반영하여 맞춤 수업 교재를 추천해드립니다.`,
    `This would help us recommend intriguing lesson materials to you.`,
    't_child_step2.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`다음`, `Next`, 't_child_step2.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step2.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step2.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step3 = {
  banner1: [`잘하셨습니다!`, `Great job!`, 't_child_step3.banner1', ``, ``, ``, ``],
  title1_default: [
    `무엇에 관심이 있으신가요?`,
    `Let’s find out what your interests are.`,
    't_child_step3.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를 해보세요.`,
    `Your selection helps further a smooth conversation between you and your tutor.`,
    't_child_step3.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`다음`, `Next`, 't_child_step3.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step3.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step3.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step4 = {
  banner1: [
    `조금만 더 하면 링글 틴즈와 첫 수업을 시작하실 수 있어요!`,
    `You’re almost there to begin your first lesson with Ringle!`,
    't_child_step4.banner1',
    ``,
    ``,
    ``,
    ``,
  ],
  title1_default: [
    <>
      본인에 대해서{' '}
      <Div textPrimary spanTag>
        더 자세히 알려주세요.
      </Div>
    </>,
    <>
      Let’s learn a bit{' '}
      <Div textPrimary spanTag>
        more about you.
      </Div>
    </>,
    't_child_step4.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
    `Your introduction helps your tutors lead the lesson efficiently.`,
    't_child_step4.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder: [
    `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
    `Please enter who you are, where you live, what school you go to, etc.`,
    't_child_step4.placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  input_desc1: [`50자 이상`, `At least 50 words`, 't_child_step4.input_desc1', ``, ``, ``, ``],
  word_count: [`글자수:`, `Word count:`, 't_child_step4.word_count', ``, ``, ``, ``],
  btn1: [`다음`, `Next`, 't_child_step4.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step4.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step4.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step5 = {
  banner1: [`축하합니다! 모두 완료하셨습니다!`, `Yay! You made it!`, 't_child_step5.banner1', ``, ``, ``, ``],
  title1_default: [
    `본인에 대해 더 자세히 알려주세요!`,
    <>
      Is there{' '}
      <Div spanTag textPrimary>
        anything else you want to tell
      </Div>{' '}
      us?
    </>,
    't_child_step5.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `계정 설정에서 답변을 수정할 수 있습니다.`,
    `You can come back to these questions again through your profile settings.`,
    't_child_step5.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  title2_default: [
    `좋아하는 것이나 잘하는 것은 무엇인가요?`,
    `Things I’m Good at or Things I Like`,
    't_child_step5.title2_default',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder1: [
    `좋아하는 것이나 잘하는 것은 무엇인가요?`,
    `Please write about what you are good at or like.`,
    't_child_step5.placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  title3_default: [`나의 꿈`, `My Dreams`, 't_child_step5.title3_default', ``, ``, ``, ``],
  placeholder2: [`꿈이 무엇인가요?`, `Please write about your dreams`, 't_child_step5.placeholder2', ``, ``, ``, ``],
  title4_default: [`학교 생활`, `My School Life`, 't_child_step5.title4_default', ``, ``, ``, ``],
  placeholder3: [
    `학교 생활에 대해 알려주세요.`,
    `Please write about your school life.`,
    't_child_step5.placeholder3',
    ``,
    ``,
    ``,
    ``,
  ],
  title5_default: [`요즘 가지고 있는 고민들`, `My Current Worries`, 't_child_step5.title5_default', ``, ``, ``, ``],
  placeholder4: [
    `현재 고민거리들은 무엇인가요?`,
    `Please write about your current worries.`,
    't_child_step5.placeholder4',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`시작하기`, `Get Started`, 't_child_step5.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step5.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step5.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_logout = {
  modal_title: [`로그아웃`, `Log out`, 't_child_logout.modal_title', ``, ``, ``, ``],
  modal_desc: [
    <>
      아직 링글 틴즈 가입을 완료하지 않으셨습니다.
      <br />
      정말 그만두시겠습니까?
    </>,
    <>
      You haven't completed this process yet.
      <br />
      Are you sure you want to log out?
    </>,
    't_child_logout.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn1: [`계속해서 완성하기`, `Stay & Complete`, 't_child_logout.modal_btn1', ``, ``, ``, ``],
  modal_btn2: [`로그아웃`, `Log out`, 't_child_logout.modal_btn2', ``, ``, ``, ``],
}
export const r_preference = {
  title_default: [``, `Preference`, 'r_preference.title_default', ``, ``, ``, ``],
  subtitle1: [``, `One sentence to introduce myself`, 'r_preference.subtitle1', ``, ``, ``, ``],
  banner1: [``, `Teens students can see this content.`, 'r_preference.banner1', ``, ``, ``, ``],
  btn3: [``, `View as Student`, 'r_preference.btn3', ``, ``, ``, ``],
  desc1: [``, `at least 25 words`, 'r_preference.desc1', ``, ``, ``, ``],
  keywords: [``, `Your Keywords`, 'r_preference.keywords', ``, ``, ``, ``],
  index1: [``, `Your interests`, 'r_preference.index1', ``, ``, ``, ``],
  index2: [``, `Your strength as a tutor`, 'r_preference.index2', ``, ``, ``, ``],
  index3: [
    ``,
    `Something you are confident in teaching (SAT, debate, Casual conversation)`,
    'r_preference.index3',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle2: [``, `(main keyword)`, 'r_preference.subtitle2', ``, ``, ``, ``],
  books: [``, `Books you are confident in teaching`, 'r_preference.books', ``, ``, ``, ``],
  title1: {
    default: [``, `Book1`, 'r_preference.title1.default', ``, ``, ``, ``],
    info_title: [``, `Enter Book title`, 'r_preference.title1.info_title', ``, ``, ``, ``],
    info_author: [``, `Enter author's name`, 'r_preference.title1.info_author', ``, ``, ``, ``],
  },
  title2: {
    default: [``, `Book2`, 'r_preference.title2.default', ``, ``, ``, ``],
    info_title: [``, `Enter Book title`, 'r_preference.title2.info_title', ``, ``, ``, ``],
    info_author: [``, `Enter author's name`, 'r_preference.title2.info_author', ``, ``, ``, ``],
  },
  title3: {
    default: [``, `Book3`, 'r_preference.title3.default', ``, ``, ``, ``],
    info_title: [``, `Enter Book title`, 'r_preference.title3.info_title', ``, ``, ``, ``],
    info_author: [``, `Enter author's name`, 'r_preference.title3.info_author', ``, ``, ``, ``],
  },
  btn1: [``, `Save Changes`, 'r_preference.btn1', ``, ``, ``, ``],
  btn2: [``, `Preview`, 'r_preference.btn2', ``, ``, ``, ``],
}
export const t_welcome_parent = {
  page_title: [`환영합니다`, `Welcome`, 't_welcome_parent.page_title', ``, ``, ``, ``],
  btn_logout: [`로그아웃`, `Logout`, 't_welcome_parent.btn_logout', ``, ``, ``, ``],
  banner1: [`링글 틴즈에 오신 것을 환영합니다`, `Welcome to Ringle Teens`, 't_welcome_parent.banner1', ``, ``, ``, ``],
  section1: {
    subtitle: [
      `링글 틴즈 수업은 이렇게 진행됩니다!`,
      `HOW RINGLE TEENS WORKS`,
      't_welcome_parent.section1.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `자녀의 수업 방식을 언제, 어디서든 직접 설정하세요`,
      `Design your child’s lessons whenever and wherever`,
      't_welcome_parent.section1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    tab1: {
      default: [`Step 1`, `Step 1`, 't_welcome_parent.section1.tab1.default', ``, ``, ``, ``],
      title: [`예약 및 예습`, `Book & Lesson Prep`, 't_welcome_parent.section1.tab1.title', ``, ``, ``, ``],
      desc1: [
        `수업 예약하기 (시간/튜터 기준으로 분류)`,
        `Book a lesson (sort by time/ sort by tutor)`,
        't_welcome_parent.section1.tab1.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `맞춤 수업을 위한 수업 방식을 설정하세요`,
        `Set lesson style for customized lesson`,
        't_welcome_parent.section1.tab1.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `자녀의 수업 준비를 도와주세요 (교재 읽기, 오디오 파일 듣기, 답변할 질문 3개 이상 선택하기)`,
        `Assist child to prep for lesson (read the insight, listen to an audio file, select 3+ questions to answer)`,
        't_welcome_parent.section1.tab1.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab2: {
      default: [`Step 2`, `Step 2`, 't_welcome_parent.section1.tab2.default', ``, ``, ``, ``],
      title: [`수업 & 숙제`, `Lesson & Homework`, 't_welcome_parent.section1.tab2.title', ``, ``, ``, ``],
      desc1: [
        <>
          자녀가 시간에 맞춰 수업에 입장할 수 있도록
          <br />
          지도해주세요
        </>,
        `Make sure your child enters the lesson on time`,
        't_welcome_parent.section1.tab2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        <>
          학생은 수업이 끝나고 한시간 이내로 숙제를
          <br />
          제출해야합니다
        </>,
        `Your child will be asked to submit a written assignment within one hour after the lesson is over`,
        't_welcome_parent.section1.tab2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab3: {
      default: [`Step 3`, `Step 3`, 't_welcome_parent.section1.tab3.default', ``, ``, ``, ``],
      title: [`수업 복습`, `Lesson Review`, 't_welcome_parent.section1.tab3.title', ``, ``, ``, ``],
      desc1: [
        <>
          튜터의 수업 피드백을 확인하세요 (학부모님들을
          <br />
          위한 안내문 포함)
        </>,
        `Review tutor’s lesson feedback report (includes Message to Parent)`,
        't_welcome_parent.section1.tab3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `글쓰기 숙제에 대한 튜터의 피드백을 확인하세요`,
        `Check tutor’s corrections and feedback on written assignment`,
        't_welcome_parent.section1.tab3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        <>
          학생이 수업 분석과 피드백을 통해 지난 수업을
          <br />
          복습할 수 있도록 격려해주세요
        </>,
        `Encourage your child to review past lessons by checking lesson analysis and feedbacks`,
        't_welcome_parent.section1.tab3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  section2: {
    subtitle: [`학부모 대시보드`, `PARENT’S DASHBOARD`, 't_welcome_parent.section2.subtitle', ``, ``, ``, ``],
    title: [
      `대시보드에서 자녀의 수업, 계정 및 수업 크레딧을 관리하세요`,
      `Manage your child’s lessons, account, and lesson credits in the dashboard`,
      't_welcome_parent.section2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [`수업 확인 및 관리`, `Check & Manage Lessons`, 't_welcome_parent.section2.point1.title', ``, ``, ``, ``],
      desc: [
        `수업을 예약하고 자녀의 과거 및 향후 수업을 관리하세요`,
        `Book lessons and manage child’s upcoming & past lessons`,
        't_welcome_parent.section2.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [`요금제 및 크레딧`, `Plans & Credits`, 't_welcome_parent.section2.point2.title', ``, ``, ``, ``],
      desc: [
        `월간 요금제를 구매하고 수업 크레딧과 기록을 확인하세요`,
        `Purchase monthy plans and view lesson credits & history`,
        't_welcome_parent.section2.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [`계정 설정`, `Account Setting`, 't_welcome_parent.section2.point3.title', ``, ``, ``, ``],
      desc: [
        `자녀 계정을 추가하고 프로필 수정 및 계정 설정을 해주세요`,
        `Add child account, edit profiles and account settings`,
        't_welcome_parent.section2.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`대시보드로 가기`, `Go to Dashboard`, 't_welcome_parent.section2.btn1', ``, ``, ``, ``],
  },
  section3: {
    subtitle: [`학생으로 입장하기`, `ENTER AS STUDENT`, 't_welcome_parent.section3.subtitle', ``, ``, ``, ``],
    title: [
      `아이가 직접 성장하고 배울 수 있는 포털을 확인해 보세요.`,
      `Explore the portal where your child can take primary responsibility of their own learning`,
      't_welcome_parent.section3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [
        `튜터 및 교재 검색`,
        `Search Tutors & Materials`,
        't_welcome_parent.section3.point1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `튜터 및 관심 있는 수업자료를 직접 검색할 수 있습니다.`,
        `Students could search for tutors and lesson materials they are interested in`,
        't_welcome_parent.section3.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [
        `수업 예약 및 수업 방식 설정`,
        `Book Lessons & Set Lesson Style`,
        't_welcome_parent.section3.point2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          직접 수업을 예약하고 원하는 수업 방식을 요청할 수<br />
          있습니다.
        </>,
        `Student could directly book lessons and request lesson style`,
        't_welcome_parent.section3.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [`1:1 링글 수업`, `1:1 Ringle Lessons`, 't_welcome_parent.section3.point3.title', ``, ``, ``, ``],
      desc: [
        `수업 준비, 수업 및 숙제, 수업 복습을 위한 공간`,
        `Space for lesson prep, lesson & homework, and lesson review`,
        't_welcome_parent.section3.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`틴즈 둘러보기`, `Explore Teens`, 't_welcome_parent.section3.btn1', ``, ``, ``, ``],
  },
  section4: {
    subtitle: [`요금제 및 크레딧`, `PLANS & CREDITS`, 't_welcome_parent.section4.subtitle', ``, ``, ``, ``],
    title: [
      `자녀를 위한 최상의 요금제를 선택해보세요`,
      `Find the perfect monthly plan for your child`,
      't_welcome_parent.section4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [`20분 & 40분 수업`, `20-min & 40-min Lessons`, 't_welcome_parent.section4.point1.title', ``, ``, ``, ``],
      desc: [
        `20분 수업은 효율적이고 저렴하며 40분 수업은 심도 있는 토론을 보장합니다!`,
        `20-min lessons are efficient and less expensive, while 40-min lessons ensure in-depth discussions!`,
        't_welcome_parent.section4.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [
        `4/12/24/48회 수업권`,
        `4/12/24/48 Lesson Packages`,
        't_welcome_parent.section4.point2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `4회와 12회 수업권 모두 좋습니다! 아이가 열정이 넘치면 24회, 48회 수업권도 시도해보세요!`,
        `4 or 12 lesson credits are great! Have an ambitious child? Try 24 or 48 lesson credits!`,
        't_welcome_parent.section4.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [
        `패키지 할인 혜택을 누리세요`,
        `Enjoy our Package Deals`,
        't_welcome_parent.section4.point3.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `더 많이 구매할수록 더 많이 할인 됩니다! 최대 26% 할인된 가격으로 구매하세요!`,
        `The more you purchase, the bigger the discount! Get up to 26% off on your purchase!`,
        't_welcome_parent.section4.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`가격 확인하기`, `Check Pricing`, 't_welcome_parent.section4.btn1', ``, ``, ``, ``],
  },
  btn1: [`체험 수업을 신청하세요`, `Book a Trial Lesson`, 't_welcome_parent.btn1', ``, ``, ``, ``],
  welcome: [`환영합니다`, `Welcome`, 't_welcome_parent.welcome', ``, ``, ``, ``],
}
export const t_welcome_child = {
  page_title: [`환영합니다`, `Welcome`, 't_welcome_child.page_title', ``, ``, ``, ``],
  btn_logout: [`로그아웃`, `Logout`, 't_welcome_child.btn_logout', ``, ``, ``, ``],
  banner1: [`링글 틴즈에 오신 것을 환영합니다`, `Welcome to Ringle Teens`, 't_welcome_child.banner1', ``, ``, ``, ``],
  section1: {
    subtitle: [
      `링글 틴즈 수업은 이렇게 진행됩니다!`,
      `HOW RINGLE TEENS WORKS`,
      't_welcome_child.section1.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `수업 방식을 언제, 어디서든 직접 설정하세요`,
      `Design your lessons whenever and wherever`,
      't_welcome_child.section1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    tab1: {
      default: [`Step 1`, `Step 1`, 't_welcome_child.section1.tab1.default', ``, ``, ``, ``],
      title: [`수업 준비`, `Lesson Preparation`, 't_welcome_child.section1.tab1.title', ``, ``, ``, ``],
      desc1: [<>"인사이트" 읽기</>, <>Read the "Insight"</>, 't_welcome_child.section1.tab1.desc1', ``, ``, ``, ``],
      desc2: [`오디오 파일 듣기`, `Listen to an audio file`, 't_welcome_child.section1.tab1.desc2', ``, ``, ``, ``],
      desc3: [`비디오 클립 보기`, `Watch a video clip`, 't_welcome_child.section1.tab1.desc3', ``, ``, ``, ``],
      desc4: [
        `질문을 세가지 이상 선택하세요`,
        `Select 3+ questions to answer`,
        't_welcome_child.section1.tab1.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab2: {
      default: [`Step 2`, `Step 2`, 't_welcome_child.section1.tab2.default', ``, ``, ``, ``],
      title: [`수업 & 숙제`, `Lesson & Homework`, 't_welcome_child.section1.tab2.title', ``, ``, ``, ``],
      desc1: [
        `튜터와의 대화에 참여하세요`,
        `Engage in an active conversation with a tutor`,
        't_welcome_child.section1.tab2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `튜터로부터 실시간 피드백을 받아보세요`,
        `Receive live feedback from the tutor`,
        't_welcome_child.section1.tab2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `쓰기 숙제를 하며 수업을 마무리 하세요`,
        `Conclude your lesson with a written assignment`,
        't_welcome_child.section1.tab2.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab3: {
      default: [`Step 3`, `Step 3`, 't_welcome_child.section1.tab3.default', ``, ``, ``, ``],
      title: [`수업 복습`, `Lesson Review`, 't_welcome_child.section1.tab3.title', ``, ``, ``, ``],
      desc1: [
        `수업 녹음본을 듣고 복습하세요`,
        `Listen to the lesson recording and review your session`,
        't_welcome_child.section1.tab3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수업 분석, 피드백 및 교정본을 확인하세요`,
        `Check lesson analysis, feedback, and corrections`,
        't_welcome_child.section1.tab3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `글쓰기 숙제에 대한 튜터의 피드백을 검토하세요`,
        `Review your tutor's feedback on your written assignment`,
        't_welcome_child.section1.tab3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  section2: {
    subtitle: [
      `십대 중심의 인사이트`,
      `KEY FEATURES: TEEN-FOCUSED INSIGHTS`,
      't_welcome_child.section2.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `링글 틴즈의 흥미로운 교재들을 둘러보세요`,
      `Explore our interesting lesson materials.`,
      't_welcome_child.section2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: [
      <>
        학년별로 나뉘어진 교재들이 매주 업데이트
        <br />
        됩니다
      </>,
      `Articles are updated weekly and organized by grade level`,
      't_welcome_child.section2.point1',
      ``,
      ``,
      ``,
      ``,
    ],
    point2: [
      `수업 일상 대화, 교과목, 북챗과 같은 주제들로 진행됩니다`,
      `Lesson topics include casual conversation, school subjects, and book chats`,
      't_welcome_child.section2.point2',
      ``,
      ``,
      ``,
      ``,
    ],
    point3: [
      `학생들은 Student's Choice lessons를 통해 본인만의 교재에 집중할 수 있습니다`,
      `Teens can focus on their own content with Student’s Choice lessons`,
      't_welcome_child.section2.point3',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`교재 살펴보기`, `Explore Materials`, 't_welcome_child.section2.btn', ``, ``, ``, ``],
  },
  section3: {
    subtitle: [
      `링글 틴즈의 튜터들을 소개합니다`,
      `KEY FEATURES: OUR TUTORS`,
      't_welcome_child.section3.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `링글 틴즈의 뛰어난 튜터들에 대해 더 알아보세요`,
      `Learn more about our celebrated tutors.`,
      't_welcome_child.section3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: [
      `튜터 선발 과정에서 뛰어난 성적을 거둔 명문대 원어민 튜터들`,
      `Experienced native English speaking tutors who aced our tutor selection process`,
      't_welcome_child.section3.point1',
      ``,
      ``,
      ``,
      ``,
    ],
    point2: [
      `STEM 에서 ART 까지 다양한 전공의 튜터들`,
      `Knowledgeable scholars in areas ranging from STEM to the Arts`,
      't_welcome_child.section3.point2',
      ``,
      ``,
      ``,
      ``,
    ],
    point3: [
      <>
        학업과 인생에 대한 멘토링까지 이끌어내는
        <br />
        튜터들
      </>,
      `Mentors who can motivate students to have a positive attitude in life and academia`,
      't_welcome_child.section3.point3',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`튜터들 살펴보기`, `Explore Tutors`, 't_welcome_child.section3.btn', ``, ``, ``, ``],
  },
  btn1: [`수업 예약하기`, `Book a Lesson`, 't_welcome_child.btn1', ``, ``, ``, ``],
  btn2: [`수업 준비하러 가기`, `Go to Lesson Prep`, 't_welcome_child.btn2', ``, ``, ``, ``],
}
export const t_schedule = {
  lesson_prep: {
    btn: [
      `예습하러 가기`,
      `Lesson Prep`,
      't_schedule.lesson_prep.btn',
      `去预习`,
      `前往預習`,
      `予習する`,
      `Chuẩn bị Buổi học`,
    ],
  },
  byteensforteens: {
    tooltip_body: [
      `By Teens, For Teens는 Let's Write Together 수업을 통해 학생들이 직접 쓴 교재입니다.`,
      `By Teens, For Teens materials are written by teen students during Let’s Write Together lessons.`,
      't_schedule.byteensforteens.tooltip_body',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip_btn: [
      `Let's Write Together 알아보기`,
      `Learn More`,
      't_schedule.byteensforteens.tooltip_btn',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip_btn1: [`닫기`, `Close`, 't_schedule.byteensforteens.tooltip_btn1', ``, ``, ``, ``],
    tooltip_btn2: [`더 알아보기`, `Learn More`, 't_schedule.byteensforteens.tooltip_btn2', ``, ``, ``, ``],
  },
}
export const t_unassigned_lessons = {
  credits: {
    regular_credit: [
      `정규 수업권`,
      `Regular Credit`,
      't_unassigned_lessons.credits.regular_credit',
      `正规课程券`,
      `正規課程券`,
      `レギュラーレッスンチケット`,
      `Thẻ học tiêu chuẩn`,
    ],
    makeup_credit: [
      `보충 수업권`,
      `Last-minutes Lesson Credit`,
      't_unassigned_lessons.credits.makeup_credit',
      `补充课程券`,
      `補充課程券`,
      `補充レッスンチケット`,
      `Thẻ học bù`,
    ],
    urgent_credit: [
      `긴급편성 수업권`,
      `FastBook Lesson Credit`,
      't_unassigned_lessons.credits.urgent_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    no_credits_available: [
      `사용할 수 있는 수업권이 없습니다.`,
      `No credits available`,
      't_unassigned_lessons.credits.no_credits_available',
      `没有可使用的课程券。`,
      `無可使用的課程券。`,
      `使用できるレッスンチケットはありません。`,
      `Không còn thẻ học nào`,
    ],
  },
}
export const t_app_download = {
  title_default: [
    <>
      무료수업을 체험하고
      <br />
      링글 틴즈를 경험해보세요!
    </>,
    <>
      Try a Free Lesson and
      <br />
      Experience Ringle Teens!
    </>,
    't_app_download.title_default',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`앱 다운로드`, `Download App`, 't_app_download.btn1', ``, ``, ``, ``],
  underline_btn: [`브라우저로 계속하기`, `Continue in Browser.`, 't_app_download.underline_btn', ``, ``, ``, ``],
}
export const t_point = {
  list_1: [
    `링글 포인트는 1:1 영어회화, 수강기간 연장, 결제 시점에 사용할 수 있습니다.`,
    `You can use Points to pay for lesson credits.`,
    't_point.list_1',
    `You can use Points to pay for lesson credits.`,
    `You can use Points to pay for lesson credits.`,
    `You can use Points to pay for lesson credits.`,
    `You can use Points to pay for lesson credits.`,
  ],
  list_2: [
    `포인트 유효기간은 적립일로부터 365일 입니다. (프로모션 포인트는 발급될 때마다 상이한 유효기간이 제공됩니다.)`,
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
    't_point.list_2',
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
  ],
  list_3: [
    `수업 포인트는 유료로 구매한 정규수업 완료 7일 후 자동 적립됩니다. (전체 적립액: 구매한 수업권 실결제 금액의 3%)`,
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
    't_point.list_3',
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
  ],
  list_4: [
    `무료 수업권 사용 또는 비정상적으로 종료된 수업이 복구되는 경우 수업 포인트는 적립되지 않습니다.`,
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
    't_point.list_4',
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
  ],
}
export const s_b2b_credit_attandance = {
  b2b_titile: [
    `B2B 수업권 출석률`,
    `B2B Lessons Attendance Rate`,
    's_b2b_credit_attandance.b2b_titile',
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
    `B2B Lessons Attendance Rate`,
  ],
  b2b_card_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance.b2b_card_title',
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
  ],
  attandance: [
    `출석률`,
    `Attendance Rate`,
    's_b2b_credit_attandance.attandance',
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
    `Attendance Rate`,
  ],
  rate: (percent) => [
    <>{percent}%</>,
    <>{percent}%</>,
    's_b2b_credit_attandance.rate',
    <>{percent}%</>,
    <>{percent}%</>,
    <>{percent}%</>,
    <>{percent}%</>,
  ],
  off_track: [
    `출석률 미달`,
    `Off Track`,
    's_b2b_credit_attandance.off_track',
    `Off Track`,
    `Off Track`,
    `Off Track`,
    `Off Track`,
  ],
  see_detail: [
    `상세현황`,
    `See detail`,
    's_b2b_credit_attandance.see_detail',
    `See detail`,
    `See detail`,
    `See detail`,
    `See detail`,
  ],
  desc1: [
    `출석 수업 수`,
    `Number of lessons attended`,
    's_b2b_credit_attandance.desc1',
    `Number of lessons attended`,
    `Number of lessons attended`,
    `Number of lessons attended`,
    `Number of lessons attended`,
  ],
  count_1: (count) => [
    <>{count}</>,
    <>{count}</>,
    's_b2b_credit_attandance.count_1',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  complete: (count) => [
    <>회 완료/{count}회</>,
    <>/{count}</>,
    's_b2b_credit_attandance.complete',
    <>/{count}</>,
    <>/{count}</>,
    <>/{count}</>,
    <>/{count}</>,
  ],
  desc2: [
    `이수 기준 수업 수`,
    `Minimum number of lessons required`,
    's_b2b_credit_attandance.desc2',
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
    `Minimum number of lessons required`,
  ],
  count_2: (count) => [
    <>{count}</>,
    `{count}`,
    's_b2b_credit_attandance.count_2',
    `{count}`,
    `{count}`,
    `{count}`,
    `{count}`,
  ],
  credits: [`회`, ``, 's_b2b_credit_attandance.credits', ``, ``, ``, ``],
  desc3: [
    `남은 수업권`,
    `Available`,
    's_b2b_credit_attandance.desc3',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  regular_credits: (count) => [
    <>정규{count}회</>,
    <>Regular {count}</>,
    's_b2b_credit_attandance.regular_credits',
    <>Regular {count}</>,
    <>Regular {count}</>,
    <>Regular {count}</>,
    <>Regular {count}</>,
  ],
  free_credits: (count) => [
    <>당일{count}회</>,
    <>Last-minute {count}</>,
    's_b2b_credit_attandance.free_credits',
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
    <>Last-minute {count}</>,
  ],
  desc4: [
    `수강 기간`,
    `Expires in`,
    's_b2b_credit_attandance.desc4',
    `Expires in`,
    `Expires in`,
    `Expires in`,
    `Expires in`,
  ],
  count_3: (day) => [
    <>{day}</>,
    <>{day}</>,
    's_b2b_credit_attandance.count_3',
    <>{day}</>,
    <>{day}</>,
    <>{day}</>,
    <>{day}</>,
  ],
  days: [`일 남음`, `days`, 's_b2b_credit_attandance.days', `days`, `days`, `days`, `days`],
  modal_title: [
    `출석률 계산법`,
    `Calculation of attendance rate`,
    's_b2b_credit_attandance.modal_title',
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
    `Calculation of attendance rate`,
  ],
  modal_desc: [
    <>
      1. 수업 시간 50% 이상을 진행 해야만 출석으로 인정됩니다.
      <br />
      예) 40분 수업의 경우 20분 진행
      <br />
      <br />
      2. 해당 과정의 정규 수업권 및 당일 수업권으로 진행한 수업만 출석률 계산에 반영합니다.
      <br />
      보상 수업권*, 이전 과정에서 발급된 수업권, 개인적으로 구매한 수업권 등을 이용해 진행한 수업은 해당 과정의 출석률
      계산에 반영하지 않습니다.
      <br />
      <br />
      3. 튜터가 불가피한 사유로 수업 시작 24시간 이내 취소한 수업과 노쇼한 수업은 출석 인정됩니다.
      <br />
      <span className="text-[14px]">
        *보상 수업권은 튜터가 노쇼하거나 24시간 이내 취소한 수업, 링글 시스템 이슈로 원활하게 진행이 안된 수업 등에 대해
        보상의 의미로 지급하는 무료 수업권입니다.
      </span>
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    's_b2b_credit_attandance.modal_desc',
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
  ],
  btn: [`확인`, `OK`, 's_b2b_credit_attandance.btn', `OK`, `OK`, `OK`, `OK`],
}
export const s_b2b_credit_attandance_detail = {
  b2b_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance_detail.b2b_title',
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
    <>{name} B2B Lessons</>,
  ],
  subtitle_1: (week) => [
    <>{week}주차 출석률 현황</>,
    <>Attendance rate as of {week} </>,
    's_b2b_credit_attandance_detail.subtitle_1',
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
    <>Attendance rate as of {week} </>,
  ],
  label_1: [
    `OFF Track`,
    `OFF Track`,
    's_b2b_credit_attandance_detail.label_1',
    `OFF Track`,
    `OFF Track`,
    `OFF Track`,
    `OFF Track`,
  ],
  label_2: [
    `On Track`,
    `On Track`,
    's_b2b_credit_attandance_detail.label_2',
    `On Track`,
    `On Track`,
    `On Track`,
    `On Track`,
  ],
  label_3: [
    `Complete!`,
    `Complete!`,
    's_b2b_credit_attandance_detail.label_3',
    `Complete!`,
    `Complete!`,
    `Complete!`,
    `Complete!`,
  ],
  comment_1: (count) => [
    <>수강 첫 주입니다! 매주 {count}회 이상의 수업을 권장하고 있어요.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    's_b2b_credit_attandance_detail.comment_1',
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
  ],
  comment_2: [
    `잘하고 있어요!  꾸준히 수업하는 것이 영어 실력 향상의 지름길이에요.`,
    `You're doing great! Consistency is the key to improving your English.`,
    's_b2b_credit_attandance_detail.comment_2',
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
    `You're doing great! Consistency is the key to improving your English.`,
  ],
  comment_3: (count) => [
    <>기간 내 완강을 위해 {count}회 이상의 권장 수업 횟수를 채워주세요. </>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    's_b2b_credit_attandance_detail.comment_3',
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
  ],
  comment_4: [
    `완강을 축하드립니다! 노력한 만큼 영어 실력이 성장했을 거예요.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    's_b2b_credit_attandance_detail.comment_4',
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
  ],
  bar_title: [
    `수업 완료 횟수`,
    `Number of lessons completed`,
    's_b2b_credit_attandance_detail.bar_title',
    `Number of lessons completed`,
    `Number of lessons completed`,
    `Number of lessons completed`,
    `Number of lessons completed`,
  ],
  my_attandance: (percent) => [
    <>현재 출석률 {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    's_b2b_credit_attandance_detail.my_attandance',
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    <>Current attendance rate: {percent}%</>,
  ],
  b2b_attandance: (week, percent) => [
    <>
      {week}주차 권장 출석률 {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    's_b2b_credit_attandance_detail.b2b_attandance',
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
  ],
  bar_complete: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    's_b2b_credit_attandance_detail.bar_complete',
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
  ],
  subtitle_2: [
    `수업 예약하기`,
    `Book a Lesson`,
    's_b2b_credit_attandance_detail.subtitle_2',
    `Book a Lesson`,
    `Book a Lesson`,
    `Book a Lesson`,
    `Book a Lesson`,
  ],
  desc1: (count) => [
    <>주당 {count}회 이상 수업 권장</>,
    <>You should complete at least {count} lessons per week</>,
    's_b2b_credit_attandance_detail.desc1',
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
    <>You should complete at least {count} lessons per week</>,
  ],
  expire_day: (exp_days, deadline) => [
    <>
      수강기간: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    's_b2b_credit_attandance_detail.expire_day',
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
  ],
  b2b_lessons_credit: (name) => [
    <>{name} 정규 수업권</>,
    <>{name}</>,
    's_b2b_credit_attandance_detail.b2b_lessons_credit',
    <>{name}</>,
    <>{name}</>,
    <>{name}</>,
    <>{name}</>,
  ],
  available_1: [
    `남은 횟수`,
    `Available`,
    's_b2b_credit_attandance_detail.available_1',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  count_1: (count) => [
    <>{count}회</>,
    <>{count}</>,
    's_b2b_credit_attandance_detail.count_1',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  btn_1: [
    `시간 먼저 선택`,
    `Sort by time`,
    's_b2b_credit_attandance_detail.btn_1',
    `Sort by time`,
    `Sort by time`,
    `Sort by time`,
    `Sort by time`,
  ],
  btn_2: [
    `튜터 먼저 선택`,
    `Sort by tutor`,
    's_b2b_credit_attandance_detail.btn_2',
    `Sort by tutor`,
    `Sort by tutor`,
    `Sort by tutor`,
    `Sort by tutor`,
  ],
  b2b_lessons_free_credit: [
    `B2B 당일 수업권(수업취소)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    's_b2b_credit_attandance_detail.b2b_lessons_free_credit',
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    `B2B Last-minute Credit(Student Cancellation)`,
  ],
  available_2: [
    `남은 횟수`,
    `Available`,
    's_b2b_credit_attandance_detail.available_2',
    `Available`,
    `Available`,
    `Available`,
    `Available`,
  ],
  count_2: (count) => [
    <>{count}회</>,
    <>{count}</>,
    's_b2b_credit_attandance_detail.count_2',
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
    <>{count}</>,
  ],
  btn_3: [
    `당일 수업 예약`,
    `Last-minute Lessons`,
    's_b2b_credit_attandance_detail.btn_3',
    `Last-minute Lessons`,
    `Last-minute Lessons`,
    `Last-minute Lessons`,
    `Last-minute Lessons`,
  ],
}
export const s_daily_brief = {
  notice: [
    `Daily Brief가 ‘새로나온 교재'로 병합되었습니다. 홈 화면의 '새로나온 교재'로 매일 신규 콘텐츠를 만나보세요!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] on the homepage to access new contents every day!`,
    's_daily_brief.notice',
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] to access new contents every day!`,
  ],
  button: [`안내문 보기`, `Learn more`, 's_daily_brief.button', `Learn more`, `Learn more`, `Learn more`, `Learn more`],
}
export const s_landing_webinar = {
  register_modal_title: [
    `신청이 완료되었어요!`,
    `Your application is complete!`,
    's_landing_webinar.register_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  register_modal_text: [
    `LIVE 웨비나는 앱을 설치하시거나 PC에서 접속하신 후 입장하실 수 있어요.`,
    `You can enter the LIVE webinar from your computer or the Ringle mobile app`,
    's_landing_webinar.register_modal_text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_unassigned_lesson = {
  tooltip: [
    `당일수업 예약 시에도 교재를 선택할 수 있습니다.`,
    `You can also select the lesson material for last-minute lessons.`,
    's_unassigned_lesson.tooltip',
    `预约当日课程也可以选择教材。`,
    `預約當日課程也可以選擇教材。`,
    `当日レッスン予約時にも教材を選択することができます。`,
    `Bạn có thể chọn tài liệu kể cả khi đặt buổi học trong ngày.`,
  ],
  inform: {
    topic_of_this_lesson_will_be: [
      <>
        당일 수업은 24시간 이내 시작하는 수업으로, 수업까지 남은 시간에 따라 선택할 수 있는 교재가 달라집니다.
        <br />- 수업 시작 기준 1시간 이전 : 모든 교재
        <br />- 수업 시작 기준 1시간 이내: '자유주제' 또는 '튜터가 이전에 수업한 교재'
      </>,
      <>
        For last-minute lessons, you can select lesson materials as follows:
        <br />
        -Up until 1 hour before lesson begins: Any available lesson material
        <br />
        -Within one hour before lesson begins: 'Free Topic' or 'Tutor's Previously Taught Materials'
      </>,
      's_unassigned_lesson.inform.topic_of_this_lesson_will_be',
      <>
        24小时内开始的课程，可以选择教材。
        <br />
        -上课前1小时:可选择所有教材。
        <br />
        -上课前1小时内：可选择"自由主题”或"任课教师先前授课的教材”。
      </>,
      <>
        24小時內開始的課程，可以選擇教材。
        <br />
        -授業開始1時間前:すべての教材。
        <br />- 上課前1小時內：可選擇"自由主題”或"任課教師先前授課的教材”。
      </>,
      <>
        24時間以内に開始するレッスンとして教材の選択が可能です。
        <br />
        -授業開始1時間前:すべての教材。
        <br />
        ‐レッスン開始1時間以内：「自由な主題」または「チューターが以前レッスンした教材」を選択可能。
      </>,
      <>
        Bạn có thể chọn tài liệu với buổi học bắt đầu trong vòng 24 giờ.
        <br />- Trước giờ học 1 tiếng: tất cả sách giáo khoa.
        <br />- Trong vòng 1 giờ trước khi bắt đầu buổi học: bạn có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử
        dụng trước đây”.
      </>,
    ],
    title: [
      `당일수업 관련 안내`,
      `About Last-minute Lessons`,
      's_unassigned_lesson.inform.title',
      `关于当日课程`,
      `關於當日課程`,
      `補充レッスンのご案内`,
      `Về tiết học thay đổi phút chót`,
    ],
    topic_of_this_lesson_will_be_new: [
      <>
        당일 수업은 24시간 이내 시작하는 수업으로, 수업까지 남은 시간에 따라 선택할 수 있는 교재가 달라집니다.
        <li>수업 시작 기준 1시간 이전 : 모든 교재</li>
        <li> 수업 시작 기준 1시간 이내: '자유주제' 또는 '튜터가 이전에 수업한 교재'</li>
        <li> 정규수업권으로 예약하는 경우 포인트가 추가 적립됩니다. (40분: ￦3,000P / 20분: ￦1,000P) </li>
      </>,
      <>
        For last-minute lessons, you can select lesson materials as follows:
        <li>Up until 1 hour before lesson begins: Any available lesson material</li>
        <li>Within one hour before lesson begins: 'Free Topic' or 'Tutor's Previously Taught Materials'</li>
      </>,
      's_unassigned_lesson.inform.topic_of_this_lesson_will_be_new',
      <>
        24小时内开始的课程，可以选择教材。<li>上课前1小时:可选择所有教材。</li>
        <li>上课前1小时内：可选择"自由主题”或"任课教师先前授课的教材”。</li>
      </>,
      <>
        24小時內開始的課程，可以選擇教材。<li>授業開始1時間前:すべての教材。</li>
        <li>上課前1小時內：可選擇"自由主題”或"任課教師先前授課的教材”。</li>
      </>,
      <>
        当日レッスンは24時間以内に開始するレッスンであり。、レッスンまでの時間により選べる教材が異なります。
        <li>レッスン開始1時間以前：すべての教材</li>
        <li>レッスン開始1時間以内：「自由テーマ」又は「チューターが以前レッスンした教材」</li>
        <li>レギュラーレッスンチケットで予約の場合、ポイントが追加されます。(40分：￦3,000P / 20分：￦1,000P) </li>
      </>,
      <>
        Bạn có thể chọn tài liệu với buổi học bắt đầu trong vòng 24 giờ.
        <li> Trước giờ học 1 tiếng: tất cả sách giáo khoa.</li>
        <li>
          Trong vòng 1 giờ trước khi bắt đầu buổi học: bạn có thể chọn "Chủ đề tự do” hoặc "Tài liệu gia sư đã sử dụng
          trước đây”.
        </li>
      </>,
    ],
  },
  reservation_success_info: [
    <>
      당일수업은 튜터 및 시간 변경이 불가합니다.
      <br />
      당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.
    </>,
    <>
      You cannot change the tutor or time slot for last-minute lessons.
      <br />
      The credit used for booking the last-minute lesson will not be returned even if the lesson is cancelled or missed
      (no-show).
    </>,
    's_unassigned_lesson.reservation_success_info',
    <>
      当日课程不可以更改课教师和时间。
      <br />
      取消当日课程或缺席时，将扣除课程券。
    </>,
    <>
      當日課程不可以更改課教師和時間。
      <br />
      取消當日課程或缺席時，將扣除課程券。
    </>,
    <>
      当日レッスンはチューターおよび時間の変更ができません。
      <br />
      当日レッスンはレッスンキャンセルおよび無断欠席時にレッスンチケットが差し引かれます。
    </>,
    <>
      Với Buổi học trong ngày, bạn không thể thay đổi gia sư và thời gian học.
      <br />
      Với Buổi học trong ngày, thẻ học vẫn bị trừ khi hủy buổi học hoặc không tham gia.
    </>,
  ],
  reservation_failure_info: [
    `예약을 실패하였습니다. 다시 수업을 예약해주세요.`,
    `Your booking didn't go through. Please try booking a different lesson.`,
    's_unassigned_lesson.reservation_failure_info',
    `预约未成功。请重新预约课程。`,
    `預約未成功。請重新預約課程。`,
    `予約に失敗しました。もう一度レッスンを予約してください。`,
    `Đặt buổi học không thành công. Hãy đặt lại buổi học.`,
  ],
  time_filter_title: [
    `수업 시작 시간`,
    `Time Slot`,
    's_unassigned_lesson.time_filter_title',
    `课程开始时间`,
    `課程開始時間`,
    `レッスンの開始時間`,
    `Lịch trống`,
  ],
  time_filter_all: [`전체`, `All`, 's_unassigned_lesson.time_filter_all', `全部`, `全部`, `全て`, `Tất cả`],
  lesson_list_blank_info: [
    <>
      해당 시간에 신청 가능한 수업이 없습니다.
      <br />
      다른 수업 시작 시간을 선택해주세요.
    </>,
    <>
      There are no lessons available in this time slot. <br />
      Please try choosing a different time slot.
    </>,
    's_unassigned_lesson.lesson_list_blank_info',
    <>
      所选时间已无课程，
      <br /> 请重新选择开始时间。
    </>,
    <>
      所選時間已無課程，
      <br />
      請重新選擇開始時間。
    </>,
    <>
      この時間には予約可能なレッスンがありません。
      <br />
      別の時間を選択してください。
    </>,
    <>
      Không có thời gian trống, <br />
      mời bạn chọn lịch học khác.{' '}
    </>,
  ],
}
export const s_recommended_tutor = {
  notice: [
    `어떤 튜터와 수업할지 고민 중이라면?`,
    `Searching for a tutor?`,
    's_recommended_tutor.notice',
    `還在苦惱和哪位導師上課？`,
    `还在苦恼和哪位导师上课？`,
    `どんなチューターと会うか悩んでいるなら`,
    `Bạn không biết chọn gia sư nào?`,
  ],
  tag1: [`#높은평점`, `#HighlyRated`, 's_recommended_tutor.tag1', `#高评分`, `#高評分`, `#HighlyRated`, `#HighlyRated`],
  tag2: [
    `#링글팀pick`,
    `#RingleTeamPick`,
    's_recommended_tutor.tag2',
    `#Ringle团队推荐`,
    `#Ringle團隊推薦`,
    `#RingleTeamPick`,
    `#RingleTeamPick`,
  ],
  tag3: [
    `#불만족시수업권복구`,
    `#GetCreditBack`,
    's_recommended_tutor.tag3',
    `#不满意课程时恢复课程券使用`,
    `#不滿意課程時重新補發課程券`,
    `#GetCreditBack`,
    `#GetCreditBack`,
  ],
  desc1: [
    `일정 기간 높은 평점과 긍정적인 리뷰를 유지하는 튜터입니다.`,
    `These tutors have maintained high ratings and received positive reviews from students for a given period.`,
    's_recommended_tutor.desc1',
    `在一定的时间内保持较高评分和积极评价的任课教师。`,
    `在一定的時間內維持較高評分和正面評價的任課教師。`,
    `一定期間高い評価と良いレビューを維持しているチューターです。`,
    `Một gia sư duy trì xếp hạng cao và đánh giá tích cực trong một khoảng thời gian.`,
  ],
  desc2: [
    `링글팀이 직접 수업을 듣고 수업 진행 및 교정 능력이 뛰어난 튜터를 선발했습니다.`,
    `The list also includes tutors recommended by members of the Ringle team who personally took lessons from them.`,
    's_recommended_tutor.desc2',
    `Ringle团队亲自听课，筛选了授课和纠正能力强的任课教师。`,
    `Ringle團隊親自上課，篩選授課及糾正能力強的任課教師。`,
    `Ringleチームがレッスンを受け、優れているチューターを選定。`,
    `Đội Ringle chúng tôi đã tham gia tiết học và tuyển chọn được vị gia sư xuất sắc.`,
  ],
  desc3: [
    `새로 온 튜터 또는 추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
    `Credit restored if unsatisfied with the Rookie/Featured Tutor. (Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit)`,
    's_recommended_tutor.desc3',
    `對新導師或推薦導師的課程不滿意時，將退還課程券。 （用收費課程券預約後，導師評價分數不足3分時並提交原因，每月限2次）`,
    `对新导师或推荐导师的课程不满意时，将退还课程券。 （用收费课程券预约后，导师评价分数不足3分时并提交原因，每月限2次）`,
    `おすすめの/新規チューターのレッスンが満足できなかったら、レッスンチケットを復元いたします。（有料レッスンチケットで予約後評価３点以下および理由提出、月２回限定）`,
    `Nếu không hài lòng với buổi học của gia sư mới/ gia sư đề cử, phiếu học sẽ được hoàn. (Tối đa 2 lần/ tháng cho buổi học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
  ],
  desc0: [
    `링글에 새로 온 튜터 또는 링글팀의 추천 튜터를 만나보세요.`,
    `Try out our Rookie/Featured Tutors.`,
    's_recommended_tutor.desc0',
    `不妨來試試新導師或Ringle的推薦導師。`,
    `不妨来试试新导师或Ringle的推荐导师。`,
    `おすすめの/新規チューターと一緒に!`,
    `Chọn gia sư mới/ gia sư Ringle đề cử`,
  ],
  desc4: [
    `새로 온 튜터와 추천 튜터와의 수업 혜택은 현재 베타 운영중입니다.`,
    `The Rookie/Featured Tutor benefits are currently being beta-tested.`,
    's_recommended_tutor.desc4',
    `新導師和推薦導師的課程優惠火熱線上`,
    `新导师和推荐导师的课程优惠火热在线`,
    `新しく来たチューターとおすすめチューターとのレッスン特典は現在ベータ運営中です。`,
    `Ưu đãi buổi học với gia sư mới và gia sư đề cử hiện đang được thử nghiệm`,
  ],
}
export const s_contents_material_ad = {
  title_1: [
    `지금 무료로 회원가입 하고
콘텐츠를 끝까지 읽어보세요!`,
    ``,
    's_contents_material_ad.title_1',
    ``,
    ``,
    ``,
    ``,
  ],
  title_2: [
    `지금 무료로 회원가입 하고
한글 번역본으로 읽어보세요!`,
    ``,
    's_contents_material_ad.title_2',
    ``,
    ``,
    ``,
    ``,
  ],
  title_3: [
    `지금 무료로 회원가입 하고
수업질문을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_3',
    ``,
    ``,
    ``,
    ``,
  ],
  title_4: [
    `지금 무료로 회원가입 하고
핵심표현을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_4',
    ``,
    ``,
    ``,
    ``,
  ],
  title_5: [
    `링글 관련 콘텐츠는
앱에서 이용하실 수 있습니다.`,
    ``,
    's_contents_material_ad.title_5',
    ``,
    ``,
    ``,
    ``,
  ],
  title_6: [
    `링글 앱에서 더 많은 교재를
무료로 확인해보세요.`,
    ``,
    's_contents_material_ad.title_6',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_sign_up: [`회원가입 하기`, ``, 's_contents_material_ad.btn_sign_up', ``, ``, ``, ``],
  btn_login: [`로그인`, ``, 's_contents_material_ad.btn_login', ``, ``, ``, ``],
  subtitle: [`이미 회원가입 하셨다면?`, ``, 's_contents_material_ad.subtitle', ``, ``, ``, ``],
  subtitle_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_contents_material_ad.subtitle_2', ``, ``, ``, ``],
  tooltip: [
    `지금 무료로 회원가입 하고, 한글 번역본과 함께 읽어보세요!`,
    ``,
    's_contents_material_ad.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  see_detail: [`자세히 보기`, ``, 's_contents_material_ad.see_detail', ``, ``, ``, ``],
  btn_start_app: [`앱에서 링글 시작하기`, ``, 's_contents_material_ad.btn_start_app', ``, ``, ``, ``],
  title_7: [
    `지금 무료로 회원가입하고
링글의 콘텐츠를 이용해 보세요.`,
    ``,
    's_contents_material_ad.title_7',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_survey = {
  title: [`마지막 단계입니다!`, `Just one more step left to finish!`, 't_survey.title', ``, ``, ``, ``],
  subtitle: [
    <>
      링글 틴즈 서비스 개선을 위해 아래의 두가지 질문에
      <br />
      답변해주세요!
    </>,
    <>
      Please answer these two questions <br />
      to help Ringle Teens improve its product!
    </>,
    't_survey.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  question1_html: [
    <>
      링글 틴즈에 대해서 어떻게 알게 되셨나요? <br />
      (중복 선택 가능)
    </>,
    <>
      How did you know about Ringle Teens? <br />
      (multiple answers possible)
    </>,
    't_survey.question1_html',
    ``,
    ``,
    ``,
    ``,
  ],
  question1: [
    `링글 틴즈에 대해서 어떻게 알게 되셨나요? (중복 선택 가능)`,
    `How did you know about Ringle Teens? (multiple answers possible)`,
    't_survey.question1',
    ``,
    ``,
    ``,
    ``,
  ],
  q1_checkbox1: [`Ringle Plus 이메일/팝업`, `Ringle Plus email/Pop-up ads`, 't_survey.q1_checkbox1', ``, ``, ``, ``],
  q1_checkbox2: [
    `직접 문의 후 안내 받음`,
    `1:1 consultation with the Ringle Teens team`,
    't_survey.q1_checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  q1_checkbox3: [`지인 추천`, `Recommendation from a friend (Word of Mouth)`, 't_survey.q1_checkbox3', ``, ``, ``, ``],
  q1_checkbox4: [`검색`, `Search engine (e.g. Google, Naver)`, 't_survey.q1_checkbox4', ``, ``, ``, ``],
  q1_checkbox5: [`뉴스기사`, `News articles`, 't_survey.q1_checkbox5', ``, ``, ``, ``],
  q1_checkbox6: [`블로그`, `Blog Post`, 't_survey.q1_checkbox6', ``, ``, ``, ``],
  q1_checkbox7: [`기타`, `Other`, 't_survey.q1_checkbox7', ``, ``, ``, ``],
  placeholder1: [`기타 항목을 입력해 주세요`, `Enter other comments`, 't_survey.placeholder1', ``, ``, ``, ``],
  question2_html: [
    <>
      자녀 교육에 대한 정보를 어디에서 얻으시나요? <br />
      (중복 선택 가능)
    </>,
    <>
      Where do you find information about your <br />
      children's education? (multiple answers possible)
    </>,
    't_survey.question2_html',
    ``,
    ``,
    ``,
    ``,
  ],
  question2: [
    `자녀 교육에 대학 정보를 어디에서 얻으시나요? (중복 선택 가능)`,
    `Where do you find information about your children's education? (multiple answers possible)`,
    't_survey.question2',
    ``,
    ``,
    ``,
    ``,
  ],
  q2_checkbox1: [`학교/지역 커뮤니티`, `School / Local community`, 't_survey.q2_checkbox1', ``, ``, ``, ``],
  q2_checkbox2: [`온라인 커뮤니티`, `Online community`, 't_survey.q2_checkbox2', ``, ``, ``, ``],
  q2_checkbox3: [`SNS/온라인 광고`, `Social media / Online ads`, 't_survey.q2_checkbox3', ``, ``, ``, ``],
  q2_checkbox4: [`친구/지인`, `Friends (Word of Mouth)`, 't_survey.q2_checkbox4', ``, ``, ``, ``],
  q2_checkbox5: [`도서/신문/잡지`, `Books / Publication / Magazine`, 't_survey.q2_checkbox5', ``, ``, ``, ``],
  q2_checkbox6: [`입시설명회`, `College Admissions Consulting`, 't_survey.q2_checkbox6', ``, ``, ``, ``],
  q2_checkbox7: [`유학원/학원`, `Private educational institutions`, 't_survey.q2_checkbox7', ``, ``, ``, ``],
  q2_checkbox8: [`개인과외교사/멘토`, `Personal tutors or mentors`, 't_survey.q2_checkbox8', ``, ``, ``, ``],
  btn1: [`제출하기`, `Submit`, 't_survey.btn1', ``, ``, ``, ``],
  error: [
    `설문 결과를 저장하는 중 오류가 발생했습니다.`,
    `An error occurred while saving survey results.`,
    't_survey.error',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_onboarding = {
  optional: [`선택입력`, `Optional`, 't_onboarding.optional', ``, ``, ``, ``],
}
export const t_landing_home_kr = {
  hero: {
    title: [
      <>
        아이비리그 출신
        <br />
        원어민 튜터와 함께하는
        <br />
        1:1 화상영어 수업
      </>,
      ``,
      't_landing_home_kr.hero.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        영어가 장벽이 아닌 <b>디딤돌이 되고</b>
        <br />
        국내를 넘어{' '}
        <b>
          전 세계를 나의 무대로
          <br />
          생각하도록 만들어주세요.
        </b>
      </>,
      ``,
      't_landing_home_kr.hero.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`앱 다운 받고 무료 체험 시작하기`, ``, 't_landing_home_kr.hero.btn', ``, ``, ``, ``],
    btn_pc: [`지금 무료 체험 시작하기`, ``, 't_landing_home_kr.hero.btn_pc', ``, ``, ``, ``],
    review: {
      pc: [
        <>
          글로벌 무대를 꿈꾸는 아이들은
          <br />
          이미 링글 틴즈를 시작했습니다.
        </>,
        ``,
        't_landing_home_kr.hero.review.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  review: {
    default: [
      <>
        글로벌 무대를
        <br />
        꿈꾸는 아이들은
        <br />
        이미 링글 틴즈를 시작했습니다.
      </>,
      ``,
      't_landing_home_kr.review.default',
      ``,
      ``,
      ``,
      ``,
    ],
    label_parents: [`학부모 후기`, ``, 't_landing_home_kr.review.label_parents', ``, ``, ``, ``],
    label_students: [`학생 후기`, ``, 't_landing_home_kr.review.label_students', ``, ``, ``, ``],
  },
  review1: {
    desc: [
      `영어 유치원 졸업 후, 아이가 영어에 노출되는 시간이 줄어서 걱정했어요. 수준 높은 원어민과 집중해서 1:1 수업을 하니, 스피킹과 발음이 유지되고 단어가 느는 것이 보여서  매우 만족스럽습니다. 이에 힘입어 국제 중학교 진학을 생각하고 있습니다.`,
      ``,
      't_landing_home_kr.review1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등3학년 학부모, 김OO 님`, ``, 't_landing_home_kr.review1.reviewer', ``, ``, ``, ``],
  },
  review2: {
    desc: [
      `학원이랑 다르게 선생님이랑 1:1 로 하는 수업이라 좋아요. 제가 하고 싶은 이야기를 하니까 영어가 더 재미있어졌어요. 얼마 전에 영어 경시 대회에서 대상을 수상하게 된 건 링글 틴즈 튜터 덕분이에요.`,
      ``,
      't_landing_home_kr.review2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등4학년 학생, 이OO 군`, ``, 't_landing_home_kr.review2.reviewer', ``, ``, ``, ``],
  },
  review3: {
    desc: [
      `1년 정도 미국 생활을 준비하고 있는데, 막상 아이가 미국에서 적응을 잘 할 수 있을지 걱정이 많았어요. 그런데 튜터 선생님 만나서 말도 훨씬 자연스럽게 하고, 자기 생각도 잘 표현하는 거 보면서 훨씬 마음을 놓을 수 있게 되어 제겐 너무 고마운 플랫폼입니다.`,
      ``,
      't_landing_home_kr.review3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등6학년 학부모, 이OO 님`, ``, 't_landing_home_kr.review3.reviewer', ``, ``, ``, ``],
  },
  review4: {
    desc: [
      `어릴 때 미국에서 살았는데, 한국에 와서 영어를 자꾸 까먹는 것 같았거든요. 그런데 튜터 선생님이랑 얘기하다 보니 영어도 잘 나오고, 새로운 단어도 배우게 되어서 좋아요. 특히 제가 읽고 싶었지만 이해하기 어려웠던 고전 책들을 링글 틴즈에서 10권 넘게 읽었어요!`,
      ``,
      't_landing_home_kr.review4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등6학년 학생, 허OO 양`, ``, 't_landing_home_kr.review4.reviewer', ``, ``, ``, ``],
  },
  review5: {
    desc: [
      `아이의 미국 고등학교 입학을 위한 영어 인터뷰 및 에세이 준비를 앞두고 도움이 될만한 서비스가 있을까 찾다가 링글 틴즈를 만났습니다. 링글 틴즈 튜터들의 도움으로 아이가 원하는 학교에 진학할 수 있었습니다.`,
      ``,
      't_landing_home_kr.review5.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`미국 8학년 학부모, 김OO 님`, ``, 't_landing_home_kr.review5.reviewer', ``, ``, ``, ``],
  },
  review6: {
    desc: [
      `학원에서 단어만 외우다가 이렇게 선생님이랑 많이 얘기하고 내가 쓰고 싶은 글도 쓰니까 훨씬 재미있는 것 같아요. 어떤 생각을 말해도 다 잘 들어주고 친한 형, 누나랑 수다 떨듯이 영어 공부해서 좋아요.`,
      ``,
      't_landing_home_kr.review6.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`중학교1학년 학생, 권OO 양`, ``, 't_landing_home_kr.review6.reviewer', ``, ``, ``, ``],
  },
  review7: {
    desc: [
      `구글에서 인턴을 하고 있는 하버드 출신 튜터와 이야기를 나누더니 본인도 새로운 기술을 개발하는 엔지니어가 되고 싶다고 하네요. 우수한 튜터들에게 영어뿐만 아니라 다양하고 넓은 세상에 대한 긍정적인 영향을 받아요.`,
      ``,
      't_landing_home_kr.review7.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`미국 9학년 학부모, 권OO 님`, ``, 't_landing_home_kr.review7.reviewer', ``, ``, ``, ``],
  },
  review8: {
    desc: [
      `튜터 쌤이 다니고 있는 하버드 대학교 생활이 어떤지 얘기를 들을 수 있어서 좋았어요. 저도 제가 좋아하는 공부도 하고 미식축구도 하고, 영자 신문 클럽에도 들어갈 수 있는 아이비리그 대학교에 가보고 싶어요.`,
      ``,
      't_landing_home_kr.review8.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`고등학교 1학년 학생, 김OO 군`, ``, 't_landing_home_kr.review8.reviewer', ``, ``, ``, ``],
  },
  subtitle1: [`원어민이라고 다 좋은 영어 선생님일까요?`, ``, 't_landing_home_kr.subtitle1', ``, ``, ``, ``],
  title1: [
    <>
      차별화된 원어민 선생님.
      <br />
      아이비리그 출신의 똑똑한 튜터
    </>,
    ``,
    't_landing_home_kr.title1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    <>
      영∙미 Top 30 대학교의 우수한 교육 환경에서 높은 성취를 경험한 링글 틴즈 튜터들은 아이들에게 영어 그 이상을
      가르칩니다.
      <br />
      <br />
      검증된 역량과 인성을 갖춘 멘토로서 아이가 넓은 세상에 대한 호기심을 가지고 긍정적인 마인드를 가진 인재로 성장할 수
      있도록 도와줍니다.
    </>,
    ``,
    't_landing_home_kr.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  more_tutor: [`더 많은 튜터들을 만나보고 싶다면?`, ``, 't_landing_home_kr.more_tutor', ``, ``, ``, ``],
  text_btn1: [`지금 링글 틴즈 앱을 다운받으세요!`, ``, 't_landing_home_kr.text_btn1', ``, ``, ``, ``],
  subtitle2: [`링글에서는 어떻게 영어를 배우나요?`, ``, 't_landing_home_kr.subtitle2', ``, ``, ``, ``],
  title2: [
    <>
      영어 원서 읽기부터 멘토링까지.
      <br />
      진짜 영어 실력을 위한
      <br />
      1:1 맞춤 영어 수업
    </>,
    ``,
    't_landing_home_kr.title2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    `링글은 함께 읽기, 대화/토론하기, 쓰기, 멘토링까지 영어 실력 향상에 꼭 필요한 다양한 학습 활동을 제공합니다. 아이의 영어 실력에 가장 적합한 학습활동을 중심으로 최적화된 수업을 해보세요.`,
    ``,
    't_landing_home_kr.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  tab1: {
    btn: [`초6 이하`, ``, 't_landing_home_kr.tab1.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 - 발음·단어 익히기`, ``, 't_landing_home_kr.tab1.title1', ``, ``, ``, ``],
    desc_1: [
      `아이가 좋아하는 영어책을 가져와 튜터와 함께 읽으며 영어 발음과 읽기에 익숙해집니다.`,
      ``,
      't_landing_home_kr.tab1.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`대화하기 - 원어민과 대화 익숙해지기`, ``, 't_landing_home_kr.tab1.title2', ``, ``, ``, ``],
    desc_2: [
      `자녀의 영어 실력에 맞는 대화하기 교재를 통해 원어민과의 대화에 익숙해 집니다.`,
      ``,
      't_landing_home_kr.tab1.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab2: {
    btn: [`초6 - 중2`, ``, 't_landing_home_kr.tab2.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 - 문장 구성력과 이해력 높이기`, ``, 't_landing_home_kr.tab2.title1', ``, ``, ``, ``],
    desc_1: [
      `아이가 좋아하는 원서를 가져와 읽으며 영어 문장에 대한 구성과 이해력을 향상시킵니다.`,
      ``,
      't_landing_home_kr.tab2.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`토론하기 - 자신의 생각 정리하기`, ``, 't_landing_home_kr.tab2.title2', ``, ``, ``, ``],
    desc_2: [
      `직접 고른 토론하기 교재를 통해 자신의 생각을 정리하고 튜터에게 전달하는 연습을 합니다.`,
      ``,
      't_landing_home_kr.tab2.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`쓰기 - 일기·가벼운 에세이 쓰기`, ``, 't_landing_home_kr.tab2.title3', ``, ``, ``, ``],
    desc_3: [
      `일기, 가벼운 에세이를 쓰고, 튜터에게 첨삭 받거나 "함께 글쓰기” 수업을 통해 한 편의 글을 같이 완성합니다.`,
      ``,
      't_landing_home_kr.tab2.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab3: {
    btn: [`중2 +`, ``, 't_landing_home_kr.tab3.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 -  원어민 수준의 어휘력 익히기`, ``, 't_landing_home_kr.tab3.title1', ``, ``, ``, ``],
    desc_1: [
      `관심있는 신문 기사 혹은 원서를 가져와 튜터와 함께 읽으며 원어민 수준의 어휘와 표현을 배웁니다.`,
      ``,
      't_landing_home_kr.tab3.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`토론하기 - 논리적으로 설득하기`, ``, 't_landing_home_kr.tab3.title2', ``, ``, ``, ``],
    desc_2: [
      `직접 정한 토론하기 교재를 통해 자신의 생각을 논리적으로 전달하고 상대방을 설득하는 법을 배웁니다.`,
      ``,
      't_landing_home_kr.tab3.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`쓰기 - 긴 에세이·교재 쓰기`, ``, 't_landing_home_kr.tab3.title3', ``, ``, ``, ``],
    desc_3: [
      `긴 에세이를 직접 쓰고, 첨삭 받거나 "함께 글쓰기” 수업을 통해 튜터와 한편의 교재를 완성합니다.`,
      ``,
      't_landing_home_kr.tab3.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title4: [`멘토링 - 자신의 미래 설계하기`, ``, 't_landing_home_kr.tab3.title4', ``, ``, ``, ``],
    desc_4: [
      `다양한 튜터의 대입 노하우, 인턴 경험들을 듣고 자신의 미래를 구체화합니다.`,
      ``,
      't_landing_home_kr.tab3.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  fit: {
    default: [`어떤 아이에게 적합한가요?`, ``, 't_landing_home_kr.fit.default', ``, ``, ``, ``],
    desc1: [
      `영어 유치원, 학습지 등을 통해 영어에 익숙한 초등학생에게 필요한 수업 구성입니다. (Lexile 400 - 800 수준)`,
      ``,
      't_landing_home_kr.fit.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `다양한 영어 학습을 해 온 초등학교 고학년, 중학생 아이들에게 필요한 수업 구성입니다.  (Lexile 600 - 1,000 수준)`,
      ``,
      't_landing_home_kr.fit.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `난이도가 있는 영어 학습을 경험한 중학교 이상 아이들에게 적합한 수업 구성입니다. (Lexile 900+ 수준)`,
      ``,
      't_landing_home_kr.fit.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1_pc: [
      <>
        영어 유치원, 학습지 등을 통해 영어에 익숙한 초등학생에게 필요한 수업 구성입니다.
        <br />
        (Lexile 400 - 800 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc1_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2_pc: [
      <>
        다양한 영어 학습을 해 온 초등학교 고학년, 중학생 아이들에게 필요한 수업 구성입니다.
        <br />
        (Lexile 600 - 1,000 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc2_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_pc: [
      <>
        난이도가 있는 영어 학습을 경험한 중학교 이상 아이들에게 적합한 수업 구성입니다.
        <br />
        (Lexile 900+ 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc3_pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle3: [`어떤 교재로 수업하게 되나요?`, ``, 't_landing_home_kr.subtitle3', ``, ``, ``, ``],
  title3: [
    <>
      명문대 출신 에디터들이
      <br />
      만드는 1,500개 이상의 영어 교재
    </>,
    ``,
    't_landing_home_kr.title3',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    `링글 틴즈팀이 매달 새롭게 발행하는 교재들은 대화부터 멘토링까지, 학습 방법에 맞춰 내용이 구성되어 있으며 각 연령별 아이들이 가장 흥미를 느낄만한 주제를 선정하여 쓰여집니다.`,
    ``,
    't_landing_home_kr.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
  tab4: [`대화`, ``, 't_landing_home_kr.tab4', ``, ``, ``, ``],
  tab5: [`토론`, ``, 't_landing_home_kr.tab5', ``, ``, ``, ``],
  tab6: [`읽기/쓰기`, ``, 't_landing_home_kr.tab6', ``, ``, ``, ``],
  tab7: [`멘토링`, ``, 't_landing_home_kr.tab7', ``, ``, ``, ``],
  subtitle4: [`무엇을 더 얻을 수 있나요?`, ``, 't_landing_home_kr.subtitle4', ``, ``, ``, ``],
  title4: [
    <>
      사고력부터 집중력까지
      <br />
      영어 그 이상을 배우는 수업
    </>,
    ``,
    't_landing_home_kr.title4',
    ``,
    ``,
    ``,
    ``,
  ],
  desc4: [
    `사고력, 창의력, 집중력, 커뮤니케이션 능력을 요약하여 4C라고 말합니다. 미국 등의 교육 선진국에서는 4C를 미래 인재의 핵심 역량으로 정의하고 교육 방법에 적용하고 있습니다. 링글 틴즈는 학생들이 영어를 넘어 4C를 기를 수 있도록 수업을 이끌고 이에 맞춰 피드백을 주고 있습니다.`,
    ``,
    't_landing_home_kr.desc4',
    ``,
    ``,
    ``,
    ``,
  ],
  four_c: {
    title1: [`Critical Thinking`, ``, 't_landing_home_kr.four_c.title1', ``, ``, ``, ``],
    subtitle1: [`(사고력)`, ``, 't_landing_home_kr.four_c.subtitle1', ``, ``, ``, ``],
    desc1: [
      `교재를 통해서 자신의 생각을 논리적으로 발전시키고 이에 대한 심도 있는 토론이 가능하도록 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`Creative Thinking`, ``, 't_landing_home_kr.four_c.title2', ``, ``, ``, ``],
    subtitle2: [`(창의력)`, ``, 't_landing_home_kr.four_c.subtitle2', ``, ``, ``, ``],
    desc2: [
      `다양한 배경의 튜터들과 생각을 나누며 넓은 세상에 대한 이해를 돕고, 폭넓게 사고하는 것을 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`Concentration`, ``, 't_landing_home_kr.four_c.title3', ``, ``, ``, ``],
    subtitle3: [`(집중력)`, ``, 't_landing_home_kr.four_c.subtitle3', ``, ``, ``, ``],
    desc3: [
      `튜터와 1:1로 대면하여 대화하고 적극적으로 수업에 참여하면서 집중력을 기를 수 있습니다.`,
      ``,
      't_landing_home_kr.four_c.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    title4: [`Communication`, ``, 't_landing_home_kr.four_c.title4', ``, ``, ``, ``],
    subtitle4: [`(커뮤니케이션 능력)`, ``, 't_landing_home_kr.four_c.subtitle4', ``, ``, ``, ``],
    desc4: [
      `말하고, 쓰는 것을 반복하고, 튜터의 실시간 피드백을 통해 한 단계 높은 커뮤니케이션 방식을 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle5: [`링글 틴즈 수업은 어떻게 진행되나요?`, ``, 't_landing_home_kr.subtitle5', ``, ``, ``, ``],
  title5: [
    <>
      예습부터 복습까지.
      <br />
      체계적인 4단계 학습 시스템
    </>,
    ``,
    't_landing_home_kr.title5',
    ``,
    ``,
    ``,
    ``,
  ],
  desc5: [
    `링글 틴즈의 모든 수업은 4단계를 거쳐 진행됩니다. 이를 통해 원어민과의 수업 시간 전후로 밀도 있는 영어 학습이 가능합니다.`,
    ``,
    't_landing_home_kr.desc5',
    ``,
    ``,
    ``,
    ``,
  ],
  tab8: {
    btn: [`예습`, ``, 't_landing_home_kr.tab8.btn', ``, ``, ``, ``],
    title1: [`교재 혹은 함께 읽을 책 미리 읽기`, ``, 't_landing_home_kr.tab8.title1', ``, ``, ``, ``],
    title2: [`교재용 오디오 파일 듣기`, ``, 't_landing_home_kr.tab8.title2', ``, ``, ``, ``],
    title3: [`교재용 비디오 시청하기`, ``, 't_landing_home_kr.tab8.title3', ``, ``, ``, ``],
    title4: [`수업 중 답변할 3가지 질문 선택하기`, ``, 't_landing_home_kr.tab8.title4', ``, ``, ``, ``],
  },
  tab9: {
    btn: [`수업`, ``, 't_landing_home_kr.tab9.btn', ``, ``, ``, ``],
    title1: [`수업 예약시 20분 혹은 40분 수업 중 선택`, ``, 't_landing_home_kr.tab9.title1', ``, ``, ``, ``],
    title2: [`튜터와 교재에 대하여 대화/토론하기`, ``, 't_landing_home_kr.tab9.title2', ``, ``, ``, ``],
    title3: [`실시간으로 튜터에게 피드백 받기`, ``, 't_landing_home_kr.tab9.title3', ``, ``, ``, ``],
    title4: [`튜터에게 질문하며 궁금한 점 해소하기`, ``, 't_landing_home_kr.tab9.title4', ``, ``, ``, ``],
  },
  tab10: {
    btn: [`숙제`, ``, 't_landing_home_kr.tab10.btn', ``, ``, ``, ``],
    title1: [`오늘 수업과 관련한 영어 숙제 하기`, ``, 't_landing_home_kr.tab10.title1', ``, ``, ``, ``],
    title2: [`튜터의 숙제 피드백 확인 하기`, ``, 't_landing_home_kr.tab10.title2', ``, ``, ``, ``],
  },
  tab11: {
    btn: [`복습`, ``, 't_landing_home_kr.tab11.btn', ``, ``, ``, ``],
    title1: [`수업 녹음 파일 듣고 수업 복습하기`, ``, 't_landing_home_kr.tab11.title1', ``, ``, ``, ``],
    title2: [`단어 사용 분석, 자주 쓰는 표현 확인하기`, ``, 't_landing_home_kr.tab11.title2', ``, ``, ``, ``],
    title3: [`튜터 피드백 리포트, 수업 노트 확인하기`, ``, 't_landing_home_kr.tab11.title3', ``, ``, ``, ``],
  },
  subtitle6: [`수업 가격은 얼마인가요?`, ``, 't_landing_home_kr.subtitle6', ``, ``, ``, ``],
  title6: [
    <>
      원어민과의 1:1 영어 학습을
      <br />
      합리적인 가격에 진행하세요.
    </>,
    ``,
    't_landing_home_kr.title6',
    ``,
    ``,
    ``,
    ``,
  ],
  title7: [
    <>
      아직 망설여지나요?
      <br />
      하버드·MIT 출신의
      <br />
      틴즈팀이 직접 만나러 갑니다.
    </>,
    ``,
    't_landing_home_kr.title7',
    ``,
    ``,
    ``,
    ``,
  ],
  desc6: [
    <>
      아이 상황별로 어떻게 틴즈를 활용하면 되는지,
      <br />
      어떻게 영어를 공부해야 진로에 도움이 되는지.
      <br />
      궁금하신가요?
    </>,
    ``,
    't_landing_home_kr.desc6',
    ``,
    ``,
    ``,
    ``,
  ],
  desc7: [`링글 틴즈 팀과 이야기 나눠보세요.`, ``, 't_landing_home_kr.desc7', ``, ``, ``, ``],
  btn10: [`카카오 채팅으로 상담하기`, ``, 't_landing_home_kr.btn10', ``, ``, ``, ``],
  text_btn2: [`카카오가 없다면?`, ``, 't_landing_home_kr.text_btn2', ``, ``, ``, ``],
  title8: [
    <>
      앱 다운 받고
      <br />
      무료 체험 신청하기
    </>,
    ``,
    't_landing_home_kr.title8',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle7: [
    <>
      링글 틴즈 앱에서
      <br />
      교재부터 튜터까지 확인하고
      <br />
      무료 체험까지 놓치지 마세요!
    </>,
    ``,
    't_landing_home_kr.subtitle7',
    ``,
    ``,
    ``,
    ``,
  ],
  btn11: [`링글 틴즈 앱 다운 받기`, ``, 't_landing_home_kr.btn11', ``, ``, ``, ``],
  faq: {
    title: [`자주 묻는 질문`, ``, 't_landing_home_kr.faq.title', ``, ``, ``, ``],
  },
  faq1: {
    default: [`링글 틴즈는 몇 학년 학생에게 적합한가요?`, ``, 't_landing_home_kr.faq1.default', ``, ``, ``, ``],
    desc: [
      `초등학생 부터 고등학생까지 대상으로 하고 있지만, 더 어리거나 나이가 많아도 본인에게 필요한 학습 활동에 맞추어 링글 틴즈의 수업을 들으실 수 있습니다.`,
      ``,
      't_landing_home_kr.faq1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq2: {
    default: [`제 아이에게 맞을지 상담하고 시작할 수 있나요?`, ``, 't_landing_home_kr.faq2.default', ``, ``, ``, ``],
    desc: [
      `물론입니다. 링글 틴즈는 상담을 진행한 경우 학부모님과 아이의 만족도가 더 높은 수업 진행이 가능합니다. 카카오 채팅 혹은 이메일을 통해 편한 마음으로 1:1 상담을 신청해주세요.`,
      ``,
      't_landing_home_kr.faq2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq3: {
    default: [`수업 가격은 얼마인가요?`, ``, 't_landing_home_kr.faq3.default', ``, ``, ``, ``],
    desc: [
      `링글 틴즈 수업권은 패키지로 판매 되며, 16만 4천원부터 시작합니다. 다양한 상황과  학생의 편의에 맞게 요금제를 선택하실 수 있습니다. 자세한 내용은 링글 틴즈에 문의주세요!`,
      ``,
      't_landing_home_kr.faq3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq4: {
    default: [`결제하기 전에 무료 체험 수업이 가능한가요?`, ``, 't_landing_home_kr.faq4.default', ``, ``, ``, ``],
    desc: [
      `네, 무료 체험 수업이 가능합니다. 회원가입만 하면 지급되는 무료 수업권을 확인해보세요.`,
      ``,
      't_landing_home_kr.faq4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq5: {
    default: [`환불은 어떻게 진행되나요?`, ``, 't_landing_home_kr.faq5.default', ``, ``, ``, ``],
    desc: [
      <>
        환불을 원하실 경우 상담하기 혹은 이메일을 통해 링글팀에 연락주세요.
        <br />
        <br />
        1. 사용 이력이 없는 경우, 전액환불 가능합니다.
        <br />
        2. 수강기간 또는 수업 횟수가 절반 이하 남은 수업권은 환불이 불가합니다.
        <br />
        3. 환불금액 산정 시, 결제 수단에 대한 수수료가 차감될 수 있습니다.
      </>,
      ``,
      't_landing_home_kr.faq5.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq6: {
    default: [`링글 틴즈 튜터는 어떻게 선발하나요?`, ``, 't_landing_home_kr.faq6.default', ``, ``, ``, ``],
    desc: [
      <>
        링글 틴즈 튜터는 1,000명 이상의 튜터를 운영하고 있는 링글 플러스 튜터 팀의 노하우를 받아 다음의 프로세스를 거쳐
        선발 및 관리 됩니다.
        <br />
        <br />
        1. <b>이력 검증</b> : 아이비리그 포함 영∙미 Top30 대학교 재/졸업 증명서, 원어민 여부 등을 서류로 검증합니다.
        <br />
        2. <b>인터뷰</b> : 링글 팀이 실제 수업과 동일하게 수업을 진행하여 튜터 합격 여부 결정합니다.
        <br />
        3. <b>튜터 트레이닝</b> : 수업 운영, 피드백 등 세부적인 수업 트레이닝을 진행합니다.
        <br />
        4. <b>튜터 관리</b> : 지속적인 고객 리뷰 모니터링를 통해 컴플레인 삼진아웃제를 적용하고 튜터들의 주기적인
        재교육을 진행합니다.
      </>,
      ``,
      't_landing_home_kr.faq6.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq7: {
    default: [`수업을 위해 어떤 준비가 필요한가요?`, ``, 't_landing_home_kr.faq7.default', ``, ``, ``, ``],
    desc: [
      <>
        1. Windows 혹은 Mac OS가 실행되며 마이크, 스피커, 웹캠이 탑재된 컴퓨터/노트북을 준비해주세요.
        <br />
        2. Chrome browser 와 Zoom Client를 설치해주세요.
        <br />
        3. 인터넷 연결을 확인해주세요.
      </>,
      ``,
      't_landing_home_kr.faq7.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq8: {
    default: [
      `효과적인 수업을 위해서 일주일 중 몇 시간을 써야 하나요?`,
      ``,
      't_landing_home_kr.faq8.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        학생의 스케줄에 따라 일주일에도 여러번 수업을 진행하실 수 있습니다. 각 수업은 예습/수업/숙제/복습으로 구성되며,
        한 수업에 총 2-3시간 정도가 소요됩니다.
        <br />
        <br />
        1. <b>예습</b> : 교재 읽기 / 오디오 듣기 / 영상 시청/ 세가지 질문 선택하기
        <br />
        2. <b>수업</b> : 20분 혹은 40분 수업 진행하기
        <br />
        3. <b>숙제</b> : 수업 관련 숙제 완성 후 제출하기.
        <br />
        4. <b>복습</b> : 수업 녹화본, 수업 분석, 튜터의 피드백 복습하기
      </>,
      ``,
      't_landing_home_kr.faq8.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq9: {
    default: [`4C가 무엇인가요?`, ``, 't_landing_home_kr.faq9.default', ``, ``, ``, ``],
    desc: [
      `4Cs는 각각 Communication 의사소통 능력, Critical Thinking 비판적 사고력, Creative Thinking 창의적 사고 능력, Concentration Skill 집중력을 의미합니다. 현재 미국을 포함한 교육 선진국에서 꼽는 미래 인재의 핵심 역량입니다. 링글 틴즈는 튜터들이 4Cs을 기반으로 수업을 진행하고 피드백을 주도록 트레이닝하고 있습니다.`,
      ``,
      't_landing_home_kr.faq9.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit: {
    normal: [`회당`, ``, 't_landing_home_kr.credit.normal', ``, ``, ``, ``],
    discount: [`할인가`, ``, 't_landing_home_kr.credit.discount', ``, ``, ``, ``],
    won: [`원`, ``, 't_landing_home_kr.credit.won', ``, ``, ``, ``],
    info_min: [`1회 수업시간`, ``, 't_landing_home_kr.credit.info_min', ``, ``, ``, ``],
    info_count: [`수업 횟수`, ``, 't_landing_home_kr.credit.info_count', ``, ``, ``, ``],
    info_day: [`수강 기간`, ``, 't_landing_home_kr.credit.info_day', ``, ``, ``, ``],
    desc1: [`수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`, ``, 't_landing_home_kr.credit.desc1', ``, ``, ``, ``],
    desc2: [`수업권을 여러번 결제해도 매번 할인이 적용됩니다.`, ``, 't_landing_home_kr.credit.desc2', ``, ``, ``, ``],
  },
  btn12: [`링글 틴즈 시작하기`, ``, 't_landing_home_kr.btn12', ``, ``, ``, ``],
  title6_pc: [
    `원어민과의 1:1 영어 학습을 합리적인 가격에 진행하세요.`,
    ``,
    't_landing_home_kr.title6_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title9: [`링글 틴즈 가입하고 무료 체험 신청하기`, ``, 't_landing_home_kr.title9', ``, ``, ``, ``],
  desc8: [
    <>
      지금 링글 틴즈에서 교재부터 튜터까지 확인하고
      <br />
      무료 체험까지 놓치지 마세요!
    </>,
    ``,
    't_landing_home_kr.desc8',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_content = {
  mobile_web: {
    access_on_app: [
      `링글 틴즈 앱을 다운 받고, 무료 체험을 시작해보세요.`,
      `Download Ringle Teens App to book your lessons NOW!`,
      't_content.mobile_web.access_on_app',
      ``,
      ``,
      ``,
      ``,
    ],
    view_in_app: [`앱으로 보기`, `Open app`, 't_content.mobile_web.view_in_app', ``, ``, ``, ``],
  },
}
export const s_purcahse = {
  note_2card: [`2개 카드로 분할 결제시 일시불 결제만 가능`, ``, 's_purcahse.note_2card', ``, ``, ``, ``],
}
export const s_bn_challenge = {
  hero: {
    title: [
      `링글 보스턴&뉴욕 챌린지`,
      `Ringle Boston & NYC Challenge`,
      's_bn_challenge.hero.title',
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
      `Ringle Boston & NYC Challenge`,
    ],
    subtitle_1: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해`,
      `For you to get a jump-start on studying English`,
      's_bn_challenge.hero.subtitle_1',
      `为了不能再推迟英语学习的你`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
      `For you to get a jump-start on studying English`,
    ],
    subtitle_2: [
      `링글과 함께하는 세 번째 도전`,
      `The 3rd Challenge with Ringle`,
      's_bn_challenge.hero.subtitle_2',
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
      `The 3rd Challenge with Ringle`,
    ],
    date_start: [
      `1. 6(금)`,
      `Jan 6 (Fri)`,
      's_bn_challenge.hero.date_start',
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
      `Jan 6 (Fri)`,
    ],
    date_end: [
      `4. 30 (일)`,
      `Apr 30 (Sun)`,
      's_bn_challenge.hero.date_end',
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
      `Apr 30 (Sun)`,
    ],
    participants: (number) => [
      <>{number}명 참여중</>,
      <>{number} challengers</>,
      's_bn_challenge.hero.participants',
      <>{number} challengers</>,
      <>{number} challengers</>,
      <>{number} challengers</>,
      <>{number} challengers</>,
    ],
    btn_1: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.hero.btn_1',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    btn_2: [
      `챌린지 참여 중`,
      `Challenge in Progress`,
      's_bn_challenge.hero.btn_2',
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
      `Challenge in Progress`,
    ],
    btn_3: [
      `챌린지 종료`,
      `Challenge Closed`,
      's_bn_challenge.hero.btn_3',
      `Challenge Closed`,
      `Challenge Closed`,
      `Challenge Closed`,
      `Challenge Closed`,
    ],
  },
  modal: {
    title: [
      `보스턴&뉴욕 챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.modal.title',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    desc: [
      `[챌린지 참여하기]를 클릭하면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      's_bn_challenge.modal.desc',
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
    ],
    btn_1: [`취소`, `Cancel`, 's_bn_challenge.modal.btn_1', `Cancel`, `Cancel`, `Cancel`, `Cancel`],
    btn_2: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.modal.btn_2',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
  },
  progress: {
    title: [
      `나의 챌린지 현황`,
      `My Challenge Board`,
      's_bn_challenge.progress.title',
      `My Challenge Board`,
      `My Challenge Board`,
      `My Challenge Board`,
      `My Challenge Board`,
    ],
    toggle_40: [`40분`, `40 min`, 's_bn_challenge.progress.toggle_40', `41 min`, `42 min`, `43 min`, `44 min`],
    toggle_20: [`20분`, `20 min`, 's_bn_challenge.progress.toggle_20', `21 min`, `22 min`, `23 min`, `24 min`],
    subtitle_1: [
      `챌린지 목표`,
      `The Challenge`,
      's_bn_challenge.progress.subtitle_1',
      `The Challenge`,
      `The Challenge`,
      `The Challenge`,
      `The Challenge`,
    ],
    number_1_40: (number) => [
      <>{number} /24회 수업</>,
      <>{number} /24 lessons</>,
      's_bn_challenge.progress.number_1_40',
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
      <>{number} /24 lessons</>,
    ],
    number_1_20: (number) => [
      <>{number} /32회 수업</>,
      <>{number} /32 lessons</>,
      's_bn_challenge.progress.number_1_20',
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
      <>{number} /32 lessons</>,
    ],
    number_1_complete: [
      `챌린지 달성!`,
      `Completed!`,
      's_bn_challenge.progress.number_1_complete',
      `Completed!`,
      `Completed!`,
      `Completed!`,
      `Completed!`,
    ],
    number_2_40: (number) => [
      <>{number} /6회 수업</>,
      <>{number} /6 lessons</>,
      's_bn_challenge.progress.number_2_40',
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
      <>{number} /6 lessons</>,
    ],
    subtitle_2: [
      `월별 수업 미션`,
      `Monthly Mission`,
      's_bn_challenge.progress.subtitle_2',
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
      `Monthly Mission`,
    ],
    number_2_20: (number) => [
      <>{number} /8회 수업</>,
      <>{number} /8 lessons</>,
      's_bn_challenge.progress.number_2_20',
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
      <>{number} /8 lessons</>,
    ],
    number_2_complete: [
      `미션 성공`,
      `Completed!`,
      's_bn_challenge.progress.number_2_complete',
      `Completed!`,
      `Completed!`,
      `Completed!`,
      `Completed!`,
    ],
    subtitle_3: [
      `함께 도전중인 링글러`,
      `Total Challengers`,
      's_bn_challenge.progress.subtitle_3',
      `Total Challengers`,
      `Total Challengers`,
      `Total Challengers`,
      `Total Challengers`,
    ],
    number_3: (number) => [
      <>{number}명</>,
      <>{number} Ringlers</>,
      's_bn_challenge.progress.number_3',
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
      <>{number} Ringlers</>,
    ],
    point_gained: [
      `받은 포인트`,
      `Points received`,
      's_bn_challenge.progress.point_gained',
      `Points received`,
      `Points received`,
      `Points received`,
      `Points received`,
    ],
    point_40: (point) => [
      <>{point}/35,000 P</>,
      <>{point}/26.00P</>,
      's_bn_challenge.progress.point_40',
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
    ],
    point_20: (point) => [
      <>{point}/35,000 P</>,
      <>{point}/26.00P</>,
      's_bn_challenge.progress.point_20',
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
      <>{point}/26.00P</>,
    ],
    date_end: [
      `챌린지 종료일 2023년 4월 30일 KST`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      's_bn_challenge.progress.date_end',
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      `Challenge closes on Apr 30, 2023 (KST)`,
    ],
    btn_1: [
      `포인트 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2: [`적립 완료`, `Awarded`, 's_bn_challenge.progress.btn_2', `Awarded`, `Awarded`, `Awarded`, `Awarded`],
    btn_500: [
      `500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_1000: [
      `1000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_1500: [
      `1500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_1500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2000: [
      `2000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_2000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_2500: [
      `2500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_2500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_3000: [
      `3000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_3000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_3500: [
      `3500P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_3500',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    btn_4000: [
      `4000P 받기`,
      `Get points`,
      's_bn_challenge.progress.btn_4000',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
    complete: [
      `신청서 제출`,
      `Submit Form`,
      's_bn_challenge.progress.complete',
      `Submit Form`,
      `Submit Form`,
      `Submit Form`,
      `Submit Form`,
    ],
    btn_point: (point) => [
      <>{point}P 받기</>,
      `Get points`,
      's_bn_challenge.progress.btn_point',
      `Get points`,
      `Get points`,
      `Get points`,
      `Get points`,
    ],
  },
  extra_mission: {
    title: [
      `추가 미션도 놓치지 마세요!`,
      `Extra missions!`,
      's_bn_challenge.extra_mission.title',
      `Extra missions!`,
      `Extra missions!`,
      `Extra missions!`,
      `Extra missions!`,
    ],
    subtitle_1: [
      `2023 영어 목표 남기기`,
      `Submit Your 2023 Study Goals`,
      's_bn_challenge.extra_mission.subtitle_1',
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
      `Submit Your 2023 Study Goals`,
    ],
    desc_1: [
      `전원) 5,000 포인트 100% 증정, 추첨 100명) 스타벅스 기프티콘 증정`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      's_bn_challenge.extra_mission.desc_1',
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
    ],
    subtitle_2: [
      `SNS 후기 인증하기`,
      `Post a Review on SNS`,
      's_bn_challenge.extra_mission.subtitle_2',
      `Post a Review on SNS`,
      `Post a Review on SNS`,
      `Post a Review on SNS`,
      `Post a Review on SNS`,
    ],
    desc_2: [
      `최대 100명) 2만원 교보문고 상품권 증정, 추첨 5명) 20분 수업권 3회 증정`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      's_bn_challenge.extra_mission.desc_2',
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.extra_mission.btn_1', `Submit`, `Submit`, `Submit`, `Submit`],
    btn_2: [`인증하기`, `Upload`, 's_bn_challenge.extra_mission.btn_2', `Upload`, `Upload`, `Upload`, `Upload`],
    btn3: [`완료`, `Complete`, 's_bn_challenge.extra_mission.btn3', `Complete`, `Complete`, `Complete`, `Complete`],
    btn4: [`인증완료`, `Complete`, 's_bn_challenge.extra_mission.btn4', `Complete`, `Complete`, `Complete`, `Complete`],
  },
  goal: {
    title: [
      `2023년 영어를 정복한다면 무엇을 하고 싶나요?`,
      `What are your dreams after mastering English in 2023?`,
      's_bn_challenge.goal.title',
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
      `What are your dreams after mastering English in 2023?`,
    ],
    title_input: [
      `지금 영어 목표를 남기고 현실로 만들어봐요!`,
      `Submit your study goals and make it come true!`,
      's_bn_challenge.goal.title_input',
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
      `Submit your study goals and make it come true!`,
    ],
    placeholder: [
      `한 번 남긴 다짐은 수정할 수 없어요. (최소 10자)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      's_bn_challenge.goal.placeholder',
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.goal.btn_1', `Submit`, `Submit`, `Submit`, `Submit`],
    title_participate: [
      `챌린지 참여 후 나의 영어 목표를 남길 수 있어요.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.goal.title_participate',
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
    ],
    btn_2: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.goal.btn_2',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    reward_1: [
      `영어 목표를 남기면 5,000 포인트를 드려요.`,
      `Get 4.00P by submitting your study goals.`,
      's_bn_challenge.goal.reward_1',
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
      `Get 4.00P by submitting your study goals.`,
    ],
    reward_2: [
      `100분을 추첨하여 스타벅스 기프티콘도 드려요.`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      's_bn_challenge.goal.reward_2',
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      `And enter the Starbucks gift card raffle (100 winners).`,
    ],
    title_my_box: [
      `나의 영어 목표`,
      `My Study Goals`,
      's_bn_challenge.goal.title_my_box',
      `My Study Goals`,
      `My Study Goals`,
      `My Study Goals`,
      `My Study Goals`,
    ],
    title_box: (number) => [
      <>{number}번째 영어 목표</>,
      <>Study Goal {number}</>,
      's_bn_challenge.goal.title_box',
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
      <>Study Goal {number}</>,
    ],
    title_admin: [
      `링글팀이 선정한 최고의 영어 목표!`,
      `Ringle Team's Choice`,
      's_bn_challenge.goal.title_admin',
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
      `Ringle Team's Choice`,
    ],
    view_more: [`더 보기`, `See more`, 's_bn_challenge.goal.view_more', `See more`, `See more`, `See more`, `See more`],
    msg_error: [
      `10자 이상 200자 이내로 입력해 주세요.`,
      `Please enter between 10 and 200 characters.`,
      's_bn_challenge.goal.msg_error',
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
      `Please enter between 10 and 200 characters.`,
    ],
  },
  last_challenge: {
    title: [
      `링글 실리콘밸리 챌린지 1기`,
      `Ringle Silicon Valley Challenge`,
      's_bn_challenge.last_challenge.title',
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
      `Ringle Silicon Valley Challenge`,
    ],
    subtitle: [
      `이전 챌린지 돌아보기`,
      `Take a look at previous challenges`,
      's_bn_challenge.last_challenge.subtitle',
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
      `Take a look at previous challenges`,
    ],
    btn_1: [
      `챌린지 영상 더 보기`,
      `View more`,
      's_bn_challenge.last_challenge.btn_1',
      `View more`,
      `View more`,
      `View more`,
      `View more`,
    ],
    btn_2: [
      `챌린지 1기 인터뷰 읽기`,
      `Read more interviews`,
      's_bn_challenge.last_challenge.btn_2',
      `Read more interviews`,
      `Read more interviews`,
      `Read more interviews`,
      `Read more interviews`,
    ],
  },
  share: {
    title: [
      <>
        혼자 공부하기는 외롭잖아요.
        <br />
        지금 친구와 같이 도전해 볼까요?
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      's_bn_challenge.share.title',
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
    ],
    subtitle: [
      `링글 보스턴&뉴욕 챌린지 공유하기`,
      `Spread the word about the Boston & NYC Challenge`,
      's_bn_challenge.share.subtitle',
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
      `Spread the word about the Boston & NYC Challenge`,
    ],
  },
  floating: {
    title_1: [
      `챌린지에 참여만 해도 100% 혜택을 드려요.`,
      `Get rewards just by joining the challenge.`,
      's_bn_challenge.floating.title_1',
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
      `Get rewards just by joining the challenge.`,
    ],
    title_2: [
      `꾸준함은 영어를 극복하는 가장 빠른 길입니다!`,
      `Consistency is the key to mastering English!`,
      's_bn_challenge.floating.title_2',
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
      `Consistency is the key to mastering English!`,
    ],
    title_3: [
      `지금이 영어 공부하기 가장 좋은 타이밍!`,
      `Now is the best time to study English!`,
      's_bn_challenge.floating.title_3',
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
      `Now is the best time to study English!`,
    ],
    title_4: [
      `챌린지 달성을 축하드려요! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      's_bn_challenge.floating.title_4',
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      `Congrats on completing the challenge! 🎉`,
    ],
    btn_1: [
      `챌린지 참여하기`,
      `Join the Challenge`,
      's_bn_challenge.floating.btn_1',
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
      `Join the Challenge`,
    ],
    btn_2: [
      `수업 예약하기`,
      `Book a lesson`,
      's_bn_challenge.floating.btn_2',
      `Book a lesson`,
      `Book a lesson`,
      `Book a lesson`,
      `Book a lesson`,
    ],
    btn_3: [
      `수업권 구매하기`,
      `Purchase credits`,
      's_bn_challenge.floating.btn_3',
      `Purchase credits`,
      `Purchase credits`,
      `Purchase credits`,
      `Purchase credits`,
    ],
    subtitle: [
      `[나의 챌린지 현황] > [신청서 제출]을 클릭해 2023년 5월 10일 (수) 23:59PM까지 신청서를 작성해 주세요.`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.floating.subtitle',
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
    ],
  },
  terms: {
    title: [
      `보스턴&뉴욕 챌린지 안내 및 유의 사항`,
      `Terms`,
      's_bn_challenge.terms.title',
      `Terms`,
      `Terms`,
      `Terms`,
      `Terms`,
    ],
    subtitle_1: [
      `[공통 안내]`,
      `[General Notice]`,
      's_bn_challenge.terms.subtitle_1',
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
      `[General Notice]`,
    ],
    desc_1_1: [
      `모든 일정은 한국 시간 기준입니다.`,
      `All dates and times are in Korean Standard Time (KST).`,
      's_bn_challenge.terms.desc_1_1',
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
      `All dates and times are in Korean Standard Time (KST).`,
    ],
    desc_1_2: [
      `혜택 당첨자 공지는 발표일에 링글 계정에 등록된 연락처 및 이메일로 개별 안내해 드립니다.`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      's_bn_challenge.terms.desc_1_2',
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
    ],
    desc_1_3: [
      `잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      's_bn_challenge.terms.desc_1_3',
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
    ],
    desc_1_4: [
      `당첨자 발표 후 7일 이내에 당첨자와 연락이 닿지 않는 경우 혜택 당첨이 취소되며 다른 사람에게 이관될 수 있습니다.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      's_bn_challenge.terms.desc_1_4',
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
    ],
    desc_1_5: [
      `제공된 혜택은 양도 및 다른 상품으로 교환이 불가합니다.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      's_bn_challenge.terms.desc_1_5',
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
    ],
    desc_1_6: [
      `해당 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      's_bn_challenge.terms.desc_1_6',
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
    ],
    subtitle_2: [
      `[보스턴&뉴욕 챌린지]`,
      `[Boston & NYC Challenge]`,
      's_bn_challenge.terms.subtitle_2',
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
      `[Boston & NYC Challenge]`,
    ],
    desc_2_1: [
      `챌린지 기간 내 40분 24회 또는 20분 32회 수업을 완료한 분들은 [나의 챌린지 현황] > [신청서 제출]을 통해 보스턴/뉴욕 투어를 신청할 수 있습니다.`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.terms.desc_2_1',
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
    ],
    desc_2_2: [
      `챌린지 기간 1/6(금) 00:00부터 4/30(일) 23:59까지 진행한 모든 수업이 포함됩니다. (수업권 종류 제한 없음, 4/30(금) 저녁 23:30 시작하는 수업 포함)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      's_bn_challenge.terms.desc_2_2',
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
    ],
    desc_2_3: [
      `수업 시작 시간에 튜터 노쇼 또는 미배정이 발생한 경우도 수업 진행으로 인정됩니다.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      's_bn_challenge.terms.desc_2_3',
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
    ],
    desc_2_4: [
      `보스턴&뉴욕 투어 신청서는 챌린지 기간 내 챌린지 조건 달성 후 5/10(수) 23:59까지 제출할 수 있습니다.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      's_bn_challenge.terms.desc_2_4',
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
    ],
    desc_2_5: [
      `보스턴&뉴욕 투어 최종 선발자 5명은 5/31(수)에 별도 연락 후 링글 공식 SNS 채널을 통해 공지됩니다.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      's_bn_challenge.terms.desc_2_5',
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
    ],
    desc_2_6: [
      `보스턴&뉴욕 투어는 2023년 하반기에 예정되어 있습니다.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      's_bn_challenge.terms.desc_2_6',
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
    ],
    subtitle_3: [
      `[포인트 혜택]`,
      `[Bonus Points]`,
      's_bn_challenge.terms.subtitle_3',
      `[Bonus Points]`,
      `[Bonus Points]`,
      `[Bonus Points]`,
      `[Bonus Points]`,
    ],
    desc_3_1: [
      `수업 완료 후 [나의 챌린지 현황] > [포인트 받기]를 누르면 포인트가 적립됩니다.`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_3_1',
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
    ],
    desc_3_2: [
      `챌린지 참여 시점과 무관하게 챌린지 기간 내 진행한 수업이 있다면 혜택은 소급 적용됩니다.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      's_bn_challenge.terms.desc_3_2',
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
    ],
    desc_3_3: [
      `40분/20분 수업권을 교차 수강할 경우, 40분/20분 포인트 혜택이 개별 적용됩니다.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      's_bn_challenge.terms.desc_3_3',
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
    ],
    subtitle_4: [
      `[영어 목표 남기기 미션]`,
      `[Submit Your 2023 Study Goals Mission]`,
      's_bn_challenge.terms.subtitle_4',
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
      `[Submit Your 2023 Study Goals Mission]`,
    ],
    desc_4_1: [
      `영어 목표는 챌린지 참여 후 남길 수 있습니다.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.terms.desc_4_1',
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
      `You can submit your study goals after joining the challenge.`,
    ],
    desc_4_2: [
      `한번 남긴 의견은 수정 또는 삭제할 수 없으니 주의해 주시기 바랍니다.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      's_bn_challenge.terms.desc_4_2',
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
    ],
    desc_4_3: [
      `이벤트와 무관한 내용, 욕설 등이 포함된 영어 목표는 사전 안내 없이 삭제될 수 있습니다.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      's_bn_challenge.terms.desc_4_3',
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
    ],
    desc_4_4: [
      `10자에서 80자 내 영어 목표를 남기는 즉시 전원 5,000 포인트를 드립니다.`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_4_4',
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
    ],
    desc_4_5: [
      `영어 목표를 남겨주신 분들 중 100명을 추첨하여 5/10(수)에 스타벅스 기프티콘을 보내드립니다.`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      's_bn_challenge.terms.desc_4_5',
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
    ],
    subtitle_5: [
      `[SNS 후기 인증 미션]`,
      `[Post a Review on SNS Mission]`,
      's_bn_challenge.terms.subtitle_5',
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
      `[Post a Review on SNS Mission]`,
    ],
    desc_5_1: [
      `SNS 후기 인증 미션은 1/6(금)부터 4/30(일)까지 진행됩니다.`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      's_bn_challenge.terms.desc_5_1',
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
    ],
    desc_5_2: [
      `SNS 후기를 남겨주신 분들 중 최대 100명에게 20,000원 교보문고 상품권을 증정합니다.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      's_bn_challenge.terms.desc_5_2',
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
    ],
    desc_5_3: [
      `정성스럽게 쓴 SNS 후기 5명을 추첨하여 20분 수업권 3회를 증정합니다.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      's_bn_challenge.terms.desc_5_3',
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
    ],
    desc_5_4: [
      `SNS 후기 인증 혜택은 5/10(수)에 일괄 발송됩니다.`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      's_bn_challenge.terms.desc_5_4',
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
    ],
    subtitle_6: [`[월별 수업 미션]`, `[Monthly Mission]`, 's_bn_challenge.terms.subtitle_6', ``, ``, ``, ``],
    desc_6_1: [
      `매달 40분 수업 6회 또는 20분 수업 8회를 완료하면 같은 수업 길이의 무료 수업권을 제공합니다.`,
      `Get a free`,
      's_bn_challenge.terms.desc_6_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_6_2: [
      `1월 - 4월 월별 수업 미션 종료일은 매월 마지막 일 저녁 23:59입니다. (수업권 종류 제한 없음, 전날 저녁 23:30 시작하는 수업 포함)`,
      ``,
      's_bn_challenge.terms.desc_6_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_6_3: [
      `월별 수업 미션 달성 시, 2/1(수), 3/1(수), 4/1(토), 5/1(월) 링글 계정으로 보너스 수업권을 일괄 제공합니다. (수강기간: 지급일 기준 30일)`,
      ``,
      's_bn_challenge.terms.desc_6_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const r_classroom = {
  zoom_help: [``, `Zoom Troubleshooting`, 'r_classroom.zoom_help', ``, ``, ``, ``],
  zoom_tooltip: [
    ``,
    `If Zoom doesn't launch automatically,  
please click here.`,
    'r_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_title: [``, `Zoom Troubleshooting`, 'r_classroom.modal_zoom_title', ``, ``, ``, ``],
  modal_zoom_tap_2: [``, `Zoom won’t launch`, 'r_classroom.modal_zoom_tap_2', ``, ``, ``, ``],
  modal_zoom_desc_2: [
    ``,
    <>
      Please click the [Relaunch Zoom] button below. You can also try joining the lesson manually: Launch Zoom {'>'}{' '}
      Click [Join a meeting] {'>'} Enter the meeting ID. If you are asked to register for a meeting, please enter your
      name and Ringle-registered email address, and click "Register and Join." If you are asked for a meeting passcode,
      please enter the numbers "12345."
    </>,
    'r_classroom.modal_zoom_desc_2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_tap_1: [``, `No sound is coming out`, 'r_classroom.modal_zoom_tap_1', ``, ``, ``, ``],
  modal_zoom_desc_1: [
    ``,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to https://zoom.us/test.
    </>,
    'r_classroom.modal_zoom_desc_1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [``, `Relaunch Zoom`, 'r_classroom.btn', ``, ``, ``, ``],
  modal_zoom_desc_3: (helpLink) => [
    ``,
    <>If the problem persists, please click on&nbsp;{helpLink}&nbsp;at the top and leave us a message.</>,
    'r_classroom.modal_zoom_desc_3',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_desc_4: [
    ``,
    <>
      There is no Zoom URL created for this lesson. <br /> In these cases, it is normal for Zoom not to launch.
    </>,
    'r_classroom.modal_zoom_desc_4',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_2: (meeting_number) => [``, <>Copy meeting ID {meeting_number}</>, 'r_classroom.btn_2', ``, ``, ``, ``],
  modal_zoom_btn_3: [``, ``, 'r_classroom.modal_zoom_btn_3', ``, ``, ``, ``],
  link_copied: [
    <>미팅ID가 복사되었습니다.</>,
    <>Meeting ID is copied to your clipboard.</>,
    'r_classroom.link_copied',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_tutor = {
  label_recommended: [
    `불만족 시 100% 수업권 복구`,
    `100% credit-back guarantee`,
    's_tutor.label_recommended',
    `不滿意時可100%退還課程券`,
    `不满意时可100%退还课程券`,
    `ご不満時100％レッスンチケット復元`,
    `Hoàn 100% phiếu học nếu không hài lòng`,
  ],
}
export const r_student_profile = {
  label_1: [``, `Basic lv.`, 'r_student_profile.label_1', ``, ``, ``, ``],
  label_2: [``, `Elemantary lv.`, 'r_student_profile.label_2', ``, ``, ``, ``],
  label_3: [``, `Intermediate lv.`, 'r_student_profile.label_3', ``, ``, ``, ``],
  label_4: [``, `Advanced lv.`, 'r_student_profile.label_4', ``, ``, ``, ``],
  caption_1: [``, `Number of Ringle lesson`, 'r_student_profile.caption_1', ``, ``, ``, ``],
  caption_2: [``, `English level`, 'r_student_profile.caption_2', ``, ``, ``, ``],
  banner_title: [
    ``,
    `You are about to meet a student who’s joining a Ringle lesson for the first time.`,
    'r_student_profile.banner_title',
    ``,
    ``,
    ``,
    ``,
  ],
  banner_desc_1: [``, `SMILE is the key! 😄`, 'r_student_profile.banner_desc_1', ``, ``, ``, ``],
  banner_desc_2: [``, `Speak in a slow, clear and simple way.`, 'r_student_profile.banner_desc_2', ``, ``, ``, ``],
  banner_desc_3: [
    ``,
    `Suggest an ideal example to the student’s choppy answers.`,
    'r_student_profile.banner_desc_3',
    ``,
    ``,
    ``,
    ``,
  ],
  banner_desc_4: [
    ``,
    `Spend an extra 2 minutes, helping brainstorming the homework assignment.`,
    'r_student_profile.banner_desc_4',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_basic: [
    ``,
    `Short phrases–needs encouragement, simple vocabulary, basic sentences.`,
    'r_student_profile.tooltip_basic',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_elementary: [
    ``,
    `Clunky sentences–gentle correction, open-ended questions.`,
    'r_student_profile.tooltip_elementary',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_intermediate: [
    ``,
    `Limited vocabulary–expand vocab + improve communication.`,
    'r_student_profile.tooltip_intermediate',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_advanced: [
    ``,
    `Strong communication–improve sentence quality, nuanced feedback.`,
    'r_student_profile.tooltip_advanced',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_basic_title: [``, `English Level : Basic`, 'r_student_profile.popup_basic_title', ``, ``, ``, ``],
  popup_basic_desc: [
    ``,
    `These beginners can communicate in short, choppy phrases and need frequent encouragement. Focus on simple vocabulary, basic sentence structures, and lots of positive reinforcement. Be patient and celebrate small victories to build their confidence.`,
    'r_student_profile.popup_basic_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_elementary_title: [
    ``,
    `English Level : Elementary`,
    'r_student_profile.popup_elementary_title',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_elementary_desc: [
    ``,
    `Students at this level can converse in English, though their sentences may be clunky and incomplete. Help them form complete sentences and gently correct their mistakes. Encourage them to speak more by asking open-ended questions.`,
    'r_student_profile.popup_elementary_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_intermediate_title: [
    ``,
    `English Level : Intermediate`,
    'r_student_profile.popup_intermediate_title',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_intermediate_desc: [
    ``,
    `These students can express their opinions using a limited vocabulary. Your goal is to expand their vocabulary and improve their overall communication skills. Introduce new words and phrases during your conversations and practice using them in context.`,
    'r_student_profile.popup_intermediate_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_advanced_title: [``, `English Level : Advanced`, 'r_student_profile.popup_advanced_title', ``, ``, ``, ``],
  popup_advanced_desc: [
    ``,
    `These students communicate well with native speakers using well-constructed sentences. They seek to improve the quality and flow of their speech. Offer them nuanced feedback on their sentence structure and help them refine their language to sound more natural and fluent.`,
    'r_student_profile.popup_advanced_desc',
    ``,
    ``,
    ``,
    ``,
  ],
}
