import * as newSvgIconsModule from '@repo/icons/index'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactDOM from 'react-dom'
import { toast } from 'react-toastify'
import { COLORS } from './modules/vars'

interface IQIcon {
  iconKey: string
  color?: string
  id: string
  component: any
}

/**
 * ----- 🟢 사용 예시 🟢 ------
 * import { QIcon_alert } from '@repo/icons/index'
 * ...
 * <QIcon_alert color={COLORS.DANGER_LIGHT} />
 */

const QIcon = (props: IQIcon) => {
  const { iconKey, color, id, component } = props

  useEffect(() => {
    const renderComponentsWithDOM = (comp, id) => {
      const container = document.getElementById(id)
      const element = React.createElement(comp, { color })
      container && ReactDOM.render(element, container)
    }
    renderComponentsWithDOM(component, id)
  }, [color, component, id])

  return (
    <CopyToClipboard text={`<${iconKey} />`} key={iconKey} onCopy={() => toast('Copied!')}>
      <div className="flex h-[120px] w-[120px] cursor-pointer flex-col items-center justify-center gap-[16px] rounded-[5px] hover:bg-gray-200">
        <div id={id} className="mt-[32px] h-[64px] w-[32px] overflow-hidden"></div>
        <p className="w-full break-words text-center text-[10px]">{iconKey}</p>
      </div>
    </CopyToClipboard>
  )
}

const QIcons = () => {
  const [colorTheme, setColorTheme] = useState<string>()
  const handleChangeColor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setColorTheme(COLORS[e.target.value as keyof typeof COLORS])
  }

  useEffect(() => {
    setColorTheme(COLORS.PURPLE500)
  }, [])

  return (
    <>
      <div>
        <select onChange={(e) => handleChangeColor(e)} defaultValue={COLORS.PURPLE500}>
          {Object.keys(COLORS).map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap gap-[16px]">
        {Object.entries(newSvgIconsModule).map(([iconKey, svgIcon], idx) => (
          <QIcon
            id={idx + '_component'}
            iconKey={iconKey}
            component={svgIcon}
            key={idx + '_component'}
            color={colorTheme}
          />
        ))}
      </div>
    </>
  )
}

export default QIcons
