import { BUTTON_SIZE, DSButton, DSRadio } from '@repo/design-system/index'
import { useState } from 'react'
import { DSBottomSheet } from '../../index'
import { subHeaderClassName } from '../style'

const closeButtonNameList = ['none', 'close button']
const closeOnDimNameList = ['none', 'close on dim']
const closeOnDragNameList = ['none', 'close on drag']
const componentNameList = ['normal', 'long', 'nestedLong']

const BottomSheetComponent = () => {
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)

  const openBottomSheet: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowBottomSheet(true)
  }
  const closeBottomSheet: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setShowBottomSheet(false)
  }

  const [closeButtonIndex, setCloseButtonIndex] = useState<number>(0)
  const [closeOnDimIndex, setCloseOnDimIndex] = useState<number>(0)
  const [closeOnDragIndex, setCloseOnDragIndex] = useState<number>(0)
  const [componentIndex, setComponentIndex] = useState<number>(0)

  const closeButtonList = [false, true]
  const closeOnDimList = [false, true]
  const closeOnDragList = [false, true]

  const componentList = [
    <div key={0} className="flex h-full w-full items-center justify-center">
      <button className="bg-q-gray-200 h-[40px] rounded-[4px] px-[12px]" onClick={closeBottomSheet}>
        Close Button
      </button>
    </div>,
    <div key={1} className="scrollbar-new flex h-[1000px] w-full items-center justify-center">
      <button className="bg-q-gray-200 h-[40px] rounded-[4px] px-[12px]" onClick={closeBottomSheet}>
        Close Button
      </button>
    </div>,
    <div key={2} className="flex h-full w-full flex-col">
      <button className="bg-q-gray-200 h-[40px] flex-none rounded-[4px] px-[12px]" onClick={closeBottomSheet}>
        Close Button
      </button>
      <div className="scrollbar-new my-[8px] grow overflow-y-auto bg-gray-300">
        <div className="h-[1000px]" />
      </div>
      <button className="bg-q-gray-200 h-[40px] flex-none rounded-[4px] px-[12px]" onClick={closeBottomSheet}>
        Close Button
      </button>
    </div>,
  ]

  return (
    <div className="flex flex-col space-y-[24px]">
      <p className={subHeaderClassName}>Controls</p>
      <DSRadio
        controlSelected={[closeButtonIndex, setCloseButtonIndex]}
        containerClassName="flex flex-col space-y-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]"
      >
        {closeButtonNameList.map((name, index) => (
          <DSRadio.Item key={index} value={index}>
            {name}
          </DSRadio.Item>
        ))}
      </DSRadio>

      <DSRadio
        controlSelected={[closeOnDimIndex, setCloseOnDimIndex]}
        containerClassName="flex flex-col space-y-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]"
      >
        {closeOnDimNameList.map((name, index) => (
          <DSRadio.Item key={index} value={index}>
            {name}
          </DSRadio.Item>
        ))}
      </DSRadio>

      <DSRadio
        controlSelected={[closeOnDragIndex, setCloseOnDragIndex]}
        containerClassName="flex flex-col space-y-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]"
      >
        {closeOnDragNameList.map((name, index) => (
          <DSRadio.Item key={index} value={index}>
            {name}
          </DSRadio.Item>
        ))}
      </DSRadio>

      <DSRadio
        controlSelected={[componentIndex, setComponentIndex]}
        containerClassName="flex flex-col space-y-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]"
      >
        {componentNameList.map((compName, index) => (
          <DSRadio.Item key={index} value={index}>
            {compName}
          </DSRadio.Item>
        ))}
      </DSRadio>

      <div className="h-[4px]" />
      <div className="h-[4px] bg-gray-600" />
      <div className="h-[4px]" />

      <p className={subHeaderClassName}>Bottom Sheet QA</p>
      <DSButton size={BUTTON_SIZE.MD} onClick={openBottomSheet}>
        <DSButton.Label>Open Bottom Sheet</DSButton.Label>
      </DSButton>

      <DSBottomSheet
        id="DsBottomSheet"
        isOpen={showBottomSheet}
        setIsOpen={setShowBottomSheet}
        closeButton={closeButtonList[closeButtonIndex]}
        closeOnDim={closeOnDimList[closeOnDimIndex]}
        closeOnDrag={closeOnDragList[closeOnDragIndex]}
      >
        {componentList[componentIndex]}
      </DSBottomSheet>
    </div>
  )
}

export default BottomSheetComponent
