export const enum CHIP_TYPE {
  ACTIONABLE = 'actionable',
  CHOICE = 'choice',
  FILTER = 'filter',
}

export const enum CHIP_SIZE {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

// For CodeSnippet: CHIP_SIZE enum의 이름을 문자열로 매핑하는 객체
export const CHIP_SIZE_NAMES = {
  xs: 'CHIP_SIZE.XS',
  sm: 'CHIP_SIZE.SM',
  md: 'CHIP_SIZE.MD',
  lg: 'CHIP_SIZE.LG',
}
