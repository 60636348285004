import { urlParams } from '../modules/apis'

const urls = {
  student: {
    landing: {
      contents: {
        material: {
          detail: (materialId) => `/student/landing/contents/material/${materialId}`,
        },
        subscribe: {
          index: `/student/landing/contents/subscribe`,
        },
      },
      case: {
        customer: {
          beginner: `/student/landing/case/customer/beginner-intermediate`,
          liveAbroad: `/student/landing/case/customer/live-abroad`,
          studyAbroad: `/student/landing/case/customer/study-abroad`,
          professional: `/student/landing/case/customer/professional`,
        },
        purpose: {
          business: `/student/landing/case/need/business-english`,
          correction: `/student/landing/case/need/resume-essay-editing`,
          interview: `/student/landing/case/need/interview-prep`,
        },
      },
      invitation: {
        code: (code) => `/student/landing/referral?referralCode=${code}`,
        specialCode: (code) => `/student/landing/special-referral?referralCode=${code}`,
      },
      correction: `/student/landing/essay-editing-and-proofreading`,
      main: (rootUrl) => `/student/landing/${rootUrl}`,
      home: `/student/landing/home`,
      process: `/student/landing/how-it-works`,
      pricing: `/student/landing/pricing`,
      faq: `/student/landing/blog?type=question`,
      notice: `/student/landing/blog?type=notice`,
      tutors: `/student/landing/english-tutors`,
      topic: `/student/landing/english-learning-materials`,
      system: `/student/landing/english-learning-platform`,
      career: `/student/landing/career`,
      blog: {
        index: `/student/landing/blog?type=blog`,
        tags: (tag) => `/student/landing/blog/tags/${tag}`,
        detail: (slug) => `/student/landing/blog/${slug}`,
        live: (slug) => `/student/landing/blog/live/${slug}`,
        result: (keywords) => `/student/landing/blog/result/${keywords}`,
      },
      study: {
        readTogether: {
          detail: (slug) => `/student/landing/study/read-together/${slug}`,
        },
        readAlone: {
          detail: (slug) => `/student/landing/study/read-alone/${slug}`,
        },
        readPricing: {
          redirect: `/student/landing/study/read-pricing/redirect`,
          success: (purchaseId, isReadTogether) =>
            `/student/landing/study/read-pricing/success?purchaseId=${purchaseId}&isReadTogether=${isReadTogether}`,
          fail: (purchaseId, isReadTogether, error_msg) =>
            `/student/landing/study/read-pricing/fail?purchaseId=${purchaseId}&isReadTogether=${isReadTogether}&error_msg=${error_msg}`,
        },
      },
      b2b: `/student/landing/b2b`,
      b2bInquiry: `/student/landing/b2b/inquiry`,
      voucher: `/student/landing/voucher`,
      invite: `/student/landing/invite-event`,
      team: {
        index: `/student/landing/team`,
        detail: {
          href: `/student/landing/team/[id]`,
          as: (id) => `/student/landing/team/${id}`,
        },
      },
      profile: `/student/landing/profile`,
    },
    auth: {
      passwordReset: `/student/auth/password-reset`,
      signIn: `/student/auth/sign-in`,
      signUp: `/student/auth/sign-up`, // "이메일로 회원가입" 페이지 (회원가입 랜딩 페이지 X)
      signUpSocialCallback: `/student/auth/sign-up-social-callback`,
      new: `/student/auth/new`, // 회원가입 랜딩 페이지.
      additionalInfo: `/student/auth/additional-info`,
    },
    policy: {
      privacy: `/student/policy/privacy`,
      terms: `/student/policy/terms`,
      all: `/student/policy/all`,
      lessonPolicy: `/student/policy/lesson-policy`,
      ipPolicy: `/student/policy/ip-policy`,
      communityGuide: `/student/policy/community-guidelines`,
    },
    newPortal: {
      policy: {
        privacy: `/webview/policy/privacy`,
        terms: `/webview/policy/terms`,
        all: `/webview/policy/all`,
        lessonPolicy: `/webview/policy/lesson-policy`,
        ipPolicy: `/webview/policy/ip-policy`,
      },
      welcome: `/portal/welcome`,
      convertCredit: `/portal/convert-credit`,
      home: `/portal/home`,
      materials: {
        detail: (materialId) => `/portal/materials/detail/${materialId}`,
        list: `/portal/materials/list`,
        category: (categoryId) => `/portal/materials/list/${categoryId}`,
      },
      trial: {
        complete: (purchaseId) => `/portal/trial/complete?purchaseId=${purchaseId}`,
        inputProfile: `/portal/trial/input-profile`,
        purchase: (productId) => `/portal/trial/purchase/${productId}`,
        schedule: `/portal/trial/schedule`,
        video: `/portal/trial/video`,
      },
      mypage: {
        point: `/portal/mypage/point`,
        invite: `/portal/mypage/invite`,
        exchange: `/portal/mypage/exchangeReferralPoint`,
        basic: `/portal/mypage/basic-profile`,
        timezone: `/portal/mypage/timezone`,
        language: `/portal/mypage/change-language`,
        profile: `/portal/mypage/profile`,
        intro: `/portal/mypage/intro`,
        introDetail: `/portal/mypage/intro-detail`,
        introResult: `/portal/mypage/intro-result`,
        notification: `/portal/mypage/notification`,
        password: `/portal/mypage/change-password`,
        currentCredit: `/portal/mypage/credit`,
        weeklyLessonPackage: (couponBundleId: number) =>
          `/portal/mypage/credit/weekly-lesson-package/${couponBundleId}`,
        certificate: {
          index: `/portal/mypage/certificate`,
        },
        creditDetail: {
          href: `/portal/mypage/credit/details/[creditId]`,
          as: (creditId: number) => `/portal/mypage/credit/details/${creditId}`,
        },
        conversationCreditDetail: {
          href: `/portal/mypage/credit/details/conversation/[creditId]`,
          as: (creditId: number) => `/portal/mypage/credit/details/conversation/${creditId}`,
        },
        attendance: {
          href: `/portal/mypage/certificate/currentCredit/[id]`,
          as: (id) => `/portal/mypage/certificate/currentCredit/${id}`,
        },
        issueCredit: {
          href: `/portal/mypage/certificate/[purchaseId]`,
          as: (purchaseId, tabId?: number) => `/portal/mypage/certificate/${purchaseId}&tabId=${tabId}`,
        },
        extendCoupons: (creditId: number) => `/portal/mypage/credit/extend-coupons/${creditId}`,
        couponBox: `/portal/mypage/coupon-box`,
        extendCredit: (couponId: number) => `/portal/mypage/extendCredit/${couponId}`,
        account: `/portal/account`,
        zoom: `/portal/mypage/zoom`,
        leave: `/portal/mypage/leave`,
      },
      correction: {
        index: `/portal/correction`,
        progress: `/portal/correction/progress`,
      },
      pricing: {
        index: `/portal/pricing`,
        b2b: `/portal/pricing-b2b`,
        success: (id) => `/portal/pricing/success/${id}`,
        fail: (id) => `/portal/pricing/fail/${id}`,
        detail: (id) => `/portal/pricing/${id}`,
        redirect: `/portal/pricing/redirect`,
      },
      promotion: {
        secret: `/student/landing/event/secret-promotion`,
        secretEn: `/student/landing/event/secret-promotion-eng`,
        newYear: `/student/landing/event/newyear-promotion-sale`,
        newYearEn: `/student/landing/event/newyear-promotion-sale-eng`,
        careerup: `/student/landing/event/careerup-event`,
        summerPromotionKR: `/student/landing/event/summer-promotion`,
        summerPromotionEN: `/student/landing/event/summer-promotion-en`,
      },
      notice: {
        index: `/portal/notice`,
        detail: (slug) => `/portal/notice/${slug}`,
      },
      blog: {
        index: `/portal/blog`,
        detail: (slug) => `/portal/blog/${slug}`,
      },
      helpCenter: {
        index: `/portal/help-center`,
        detail: (slug) => `/portal/blog/${slug}`,
      },
      myRingle: {
        index: `/portal/my-ringle`,
        badges: `/portal/my-ringle/badges`,
        achievements: `/portal/my-ringle/achievements`,
        statistics: `/portal/my-ringle/statistics`,
        materials: `/portal/my-ringle/materials/bookmark`,
        experiencedMaterials: `/portal/my-ringle/materials/prev`,
        prevReadAlone: `/portal/my-ringle/read-program/read-alone`,
        prevReadTogether: `/portal/my-ringle/read-program/read-together`,
        AIAnalysis: {
          index: `/portal/my-ringle/ai-analysis`,
          asTab: (tab) => `/portal/my-ringle/ai-analysis?tab=${tab}`,
          asEdit: (tab) => `/portal/my-ringle/ai-analysis?tab=${tab}&edit=true`,
          asDate: (tab, startDate, endDate) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}`,
          asTag: (tab, startDate, endDate, tag) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}`,
          asTagBack: (tab, startDate, endDate, tag) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&back=true`,
          asHighlightId: (tab, startDate, endDate, tag, highlightId) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&highlightId=${highlightId}`,
          asHighlightBack: (tab, startDate, endDate, tag, highlightId) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&highlightId=${highlightId}&back=true`,
          asCafRingleTip: (tab, startDate, endDate, tag, tipTag) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&tipTag=${tipTag}`,
          asEditWithDate: (tab, startDate, endDate) =>
            `/portal/my-ringle/ai-analysis?tab=${tab}&startDate=${startDate}&endDate=${endDate}&edit=true`,
        },
      },
      aiTutor: {
        index: `/portal/ai-tutor`,
      },
      study: {
        index: `/portal/study`,
        readTogether: {
          index: `/portal/study/read-together`,
          detail: (slug) => `/portal/study/read-together/${slug}`,
        },
        readAlone: {
          index: `/portal/study/read-alone`,
          detail: (slug) => `/portal/study/read-alone/${slug}`,
        },
        readPricing: {
          redirect: `/portal/study/read-pricing/redirect`,
          success: (purchaseId, isReadTogether) =>
            `/portal/study/read-pricing/success?purchaseId=${purchaseId}&isReadTogether=${isReadTogether}`,
          fail: (purchaseId, isReadTogether, error_msg) =>
            `/portal/study/read-pricing/fail?purchaseId=${purchaseId}&isReadTogether=${isReadTogether}&error_msg=${error_msg}`,
        },
        activity: {
          index: `/portal/study/activity`,
          detail: (slug) => `/portal/study/activity/${slug}`,
        },
        dailyActivity: (date) => `/portal/study/daily-activity/${date}`,
        biteSizedFeedback: `/portal/study/bite-sized-feedback`,
      },
      event: {
        index: '/portal/event',
        list: `/portal/event/list`,
        test: `/portal/event/test-admin`,
        detail: (slug) => `/portal/event/detail/${slug}`,
        landingDetail: (slug) => `/student/landing/event/detail/${slug}`,
        landing: (slug) => `/student/landing/event/${slug}`,
        tag: (slug) => `/portal/event/tag/${slug}`,
        result: (slug) => `/portal/event/result/${slug}`,
        challenge: {
          index: `/portal/event/challenge`,
          detail: {
            href: `/portal/event/challenge/[challengeId]`,
            as: (challengeId) => `/portal/event/challenge/${challengeId}`,
          },
          option: {
            href: `/portal/event/challenge/option/[challengeId]`,
            as: (challengeId) => `/portal/event/challenge/option/${challengeId}`,
          },
          ended: `/portal/event/challenge/ended`,
        },
      },
      lessons: {
        tutors: `/portal/lessons/tutors`,
        materials: `/portal/lessons/materials`,
        onboarding: `/portal/lessons/onboarding`,
        complete: `/portal/lessons/complete`,
        upcomingLessons: `/portal/lessons/upcoming-lessons`,
        zoomGuide: `/portal/lessons/zoom-guide`,
        materialSelect: (lessonId) => `/portal/lessons/materials/${lessonId}`,
        materialChange: (lessonId) => `/portal/lessons/materials/${lessonId}?type=change`,
        reviews: {
          home: `/portal/lessons/review`,
          homeAsDate: (year, month) => `/portal/lessons/review?year=${year}&month=${month}`,
          detail: (lessonId) => `/portal/lessons/review/${lessonId}`,
          detailAs: (lessonId, type) => `/portal/lessons/review/${lessonId}?type=${type}`,
        },
        schedule: `/portal/lessons/schedule`,
        additionalSchedule: {
          index: (lessonId = null) =>
            lessonId
              ? `/portal/lessons/additional-schedule?lessonId=${lessonId}`
              : `/portal/lessons/additional-schedule`,
          complete: `/portal/lessons/additional-schedule/complete`,
        },
        preparation: {
          href: `/portal/lessons/preparation/[lessonId]`,
          as: (lessonId) => `/portal/lessons/preparation/${lessonId}`,
          asInsight: (lessonId) => `/portal/lessons/preparation/${lessonId}?type=insight`,
          asQuestion: (lessonId) => `/portal/lessons/preparation/${lessonId}?type=question`,
          asReview: (lessonId, isReview) => `/portal/lessons/preparation/${lessonId}?isReview=${isReview}`,
        },
        preparationOver: {
          href: `/portal/lessons/preparation-over/[lessonId]`,
          as: (lessonId) => `/portal/lessons/preparation-over/${lessonId}`,
        },
        evaluation: {
          href: `/portal/lessons/evaluation`,
          as: (lessonId) => `/portal/lessons/evaluation/${lessonId}`,
        },
        classroom: {
          href: `/portal/lessons/classroom/[lessonId]`,
          as: (lessonId) => `/portal/lessons/classroom/${lessonId}`,
        },
        cafStatistics: {
          index: `/portal/lessons/caf`,
          asTab: (tab) => `/portal/lessons/caf?tab=${tab}`,
          asEdit: (tab) => `/portal/lessons/caf?tab=${tab}&edit=true`,
          asDate: (tab, startDate, endDate) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}`,
          asTag: (tab, startDate, endDate, tag) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}`,
          asTagBack: (tab, startDate, endDate, tag) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&back=true`,
          asHighlightId: (tab, startDate, endDate, tag, highlightId) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&highlightId=${highlightId}`,
          asCafRingleTip: (tab, startDate, endDate, tag, tipTag) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&tipTag=${tipTag}`,
          asEditWithDate: (tab, startDate, endDate) =>
            `/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&edit=true`,
        },
        reviewDetail: {
          href: `/portal/lessons/review/[lessonId]`,
          as: (lessonId) => `/portal/lessons/review/${lessonId}`,
          asCourse: (lessonId) => `/portal/lessons/review/${lessonId}?type=course`,
          asMP3: (lessonId, duration) =>
            `/portal/lessons/review/${lessonId}?type=course&mp3_playback=${Math.floor(parseInt(duration))}`,
          asRecording: (lessonId, duration) =>
            `/portal/lessons/review/${lessonId}?type=recording&lesson_playback=${Math.floor(parseInt(duration))}`,
          asVideo: (lessonId, duration) =>
            `/portal/lessons/review/${lessonId}?type=youtube&video_playback=${Math.floor(parseInt(duration))}`,
          asArticle: (lessonId) => `/portal/lessons/review/${lessonId}?type=article`,
          asExpression: (lessonId) => `/portal/lessons/review/${lessonId}?type=expression`,
          asWrite: (lessonId, question) => `/portal/lessons/review/${lessonId}?type=course&question=${question}`,
          asFeedback: (lessonId) => `/portal/lessons/review/${lessonId}?type=feedback`,
          asCaf: (lessonId, tabIndex) => `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}`,
          asCafComponent: (lessonId, tabIndex, componentId) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&componentId=${componentId}`,
          asCafTag: (lessonId, tabIndex, tag) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tag=${tag}`,
          asCafTagAndId: (lessonId, tabIndex, tag, highlightId) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tag=${tag}&highlightId=${highlightId}`,
          asCafTagAndIdBack: (lessonId, tabIndex, tag, highlightId) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tag=${tag}&highlightId=${highlightId}&back=true`,
          asCafEditPopup: (lessonId, edit) => `/portal/lessons/review/${lessonId}?type=caf&edit=${edit}`,
          asCafMetric: (lessonId, tabIndex, componentId, metricTag) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&componentId=${componentId}&metricTag=${metricTag}`,
          asCafLevel: (lessonId, tabIndex, level, analysisType) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&level=${level}&analysisType=${analysisType}`,
          asCafRingleTip: (lessonId, tabIndex, tipTag, highlightId) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tipTag=${tipTag}&highlightId=${highlightId}`,
          asCafComplexityMetricDetail: (lessonId, componentId, metricTag, metricId, level_tag, title) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=1&componentId=${componentId}&metricTag=${metricTag}&metricId=${metricId}&errorTag=${level_tag}&title=${title}`,
          asCafAccuracyMetricDetail: (lessonId, metricId, error_tag, title) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=2&metricId=${metricId}&errorTag=${error_tag}&title=${title}`,
          asCafPronunciationMetricDetail: (lessonId, metricTag, highlightId, errorId) =>
            `/portal/lessons/review/${lessonId}?type=caf&tab=5&tag=${metricTag}&highlightId=${highlightId}&errorId=${errorId}`,
        },
      },
      tutors: {
        list: `/portal/tutors/recommend`,
        all: `/portal/tutors/all`,
        recommend: `/portal/tutors/recommend`,
        detail: {
          href: `/portal/tutors/[id]`,
          as: (id, tab?: number) => (tab ? `/portal/tutors/${id}?tab=${tab}` : `/portal/tutors/${id}`),
        },
        my: `/portal/tutors/my`,
        search: (keyword) => `/portal/tutors/search?keyword=${keyword}`,
        experienced: `/portal/tutors/experienced`,
      },
    },
    portal: {
      pricing: {
        index: `/student/portal/pricing`,
        detail: (id) => `/student/portal/pricing/${id}`,
      },
      materials: {
        view: {
          href: `/student/portal/materials/[materialId]`,
        },
      },
      correction: {
        request: `/student/portal/correction/request`,
        waiting: `/student/portal/correction/waiting`,
        completed: `/student/portal/correction/completed`,
      },
      tutors: {
        recommended: `/student/portal/tutors/recommended`,
      },
      invite: {
        event: `/student/portal/invite-event`,
      },
      lessons: {
        schedule: `/student/portal/lessons/schedule`,
        scheduleEnter: `/student/portal/lessons/schedule-enter`,
        unassigned: `/student/portal/lessons/unassigned-lessons`,
        scheduleMaterials: `/student/portal/lessons/schedule-materials`,
        classroom: {
          href: `/student/portal/lessons/classroom/[lessonId]`,
          as: (lessonId) => `/student/portal/lessons/classroom/${lessonId}`,
        },
        upcomingLessons: `/student/portal/lessons/upcoming-lessons`,
        preparation: {
          href: `/student/portal/lessons/preparation/[lessonId]`,
          as: (lessonId) => `/student/portal/lessons/preparation/${lessonId}`,
        },
        review: `/student/portal/lessons/review`,
        cafStatistics: {
          index: `/student/portal/lessons/caf`,
          asTab: (tab) => `/student/portal/lessons/caf?tab=${tab}`,
          asEdit: (tab) => `/student/portal/lessons/caf?tab=${tab}&edit=true`,
          asDate: (tab, startDate, endDate) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}`,
          asTag: (tab, startDate, endDate, tag) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}`,
          asTagBack: (tab, startDate, endDate, tag) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&back=true`,
          asHighlightId: (tab, startDate, endDate, tag, highlightId) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&highlightId=${highlightId}`,
          asHighlightBack: (tab, startDate, endDate, tag, highlightId) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&highlightId=${highlightId}&back=true`,
          asCafRingleTip: (tab, startDate, endDate, tag, tipTag) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&tipTag=${tipTag}`,
          asEditWithDate: (tab, startDate, endDate) =>
            `/student/portal/lessons/caf?tab=${tab}&startDate=${startDate}&endDate=${endDate}&edit=true`,
        },
        reviewDetail: {
          href: `/student/portal/lessons/review/[lessonId]`,
          as: (lessonId) => `/student/portal/lessons/review/${lessonId}`,
          asCourse: (lessonId) => `/student/portal/lessons/review/${lessonId}?type=course`,
          asMP3: (lessonId, duration) =>
            `/student/portal/lessons/review/${lessonId}?type=course&mp3_playback=${Math.floor(parseInt(duration))}`,
          asRecording: (lessonId, duration) =>
            `/student/portal/lessons/review/${lessonId}?type=recording&lesson_playback=${Math.floor(
              parseInt(duration)
            )}`,
          asVideo: (lessonId, duration) =>
            `/student/portal/lessons/review/${lessonId}?type=youtube&video_playback=${Math.floor(parseInt(duration))}`,
          asArticle: (lessonId) => `/student/portal/lessons/review/${lessonId}?type=article`,
          asExpression: (lessonId) => `/student/portal/lessons/review/${lessonId}?type=expression`,
          asWrite: (lessonId, question) =>
            `/student/portal/lessons/review/${lessonId}?type=course&question=${question}`,
          asFeedback: (lessonId) => `/student/portal/lessons/review/${lessonId}?type=feedback`,
          asCaf: (lessonId, tabIndex) => `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}`,
          asCafComponent: (lessonId, tabIndex, componentId) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&componentId=${componentId}`,
          asCafTag: (lessonId, tabIndex, tag) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tag=${tag}`,
          asCafTagAndId: (lessonId, tabIndex, tag, highlightId) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tag=${tag}&highlightId=${highlightId}`,
          asCafEditPopup: (lessonId, edit) => `/student/portal/lessons/review/${lessonId}?type=caf&edit=${edit}`,
          asCafMetric: (lessonId, tabIndex, componentId, metricTag) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&componentId=${componentId}&metricTag=${metricTag}`,
          asCafLevel: (lessonId, tabIndex, level, analysisType) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&level=${level}&analysisType=${analysisType}`,
          asCafRingleTip: (lessonId, tabIndex, tipTag, highlightId) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tabIndex}&tipTag=${tipTag}&highlightId=${highlightId}`,
          asCafComplexityMetricDetail: (lessonId, componentId, metricTag, metricId, level_tag, title) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=1&componentId=${componentId}&metricTag=${metricTag}&metricId=${metricId}&errorTag=${level_tag}&title=${title}`,
          asCafAccuracyMetricDetail: (lessonId, metricId, error_tag, title) =>
            `/student/portal/lessons/review/${lessonId}?type=caf&tab=2&metricId=${metricId}&errorTag=${error_tag}&title=${title}`,
        },
      },
      trial: {
        index: `/student/portal/trial`,
        complete: (purchaseId) => `/student/portal/trial/complete?purchaseId=${purchaseId}`,
        inputProfile: `/student/portal/trial/input-profile`,
        purchase: (productId) => `/student/portal/trial/purchase/${productId}`,
        schedule: `/student/portal/trial/schedule`,
        video: `/student/portal/trial/video`,
      },
      mypage: {
        basic: `/student/portal/mypage/basic-profile`,
        timezone: `/student/portal/mypage/timezone`,
        language: `/student/portal/mypage/change-language`,
        profile: `/student/portal/mypage/profile`,
        intro: `/student/portal/mypage/intro`,
        introDetail: `/student/portal/mypage/intro-detail`,
        introResult: `/student/portal/mypage/intro-result`,
        notification: `/student/portal/mypage/notification`,
        password: `/student/portal/mypage/change-password`,
        point: `/student/portal/mypage/point`,
        currentCredit: `/student/portal/mypage/credit`,
        creditDetail: {
          href: `/student/portal/mypage/credit/details/[creditId]`,
          as: (creditId: number) => `/student/portal/mypage/credit/details/${creditId}`,
        },
        attendance: {
          href: `/student/portal/mypage/credit/[certificateId]`,
          as: (certificateId) => `/student/portal/mypage/credit/${certificateId}`,
        },
        extendCoupons: (creditId: number) => `/student/portal/mypage/credit/extend-coupons/${creditId}`,
        couponBox: `/student/portal/mypage/coupon-box`,
        account: `/student/portal/mypage/account`,
        zoom: `/student/portal/mypage/zoom`,
      },
      convertCredit: `/student/portal/convert-credit`,
      transferCredit: `/student/portal/transfer-credit`,
      biteSizeFeedback: {
        index: `/student/portal/lessons/bite-sized-feedback`,
      },
    },
  },
  tutor: {
    policy: {
      privacy: `/tutor/policy/privacy`,
    },
    auth: {
      signIn: `/tutor/auth/sign-in`,
      signUp: `/tutor/auth/sign-up`,
    },
    landing: {
      home: `/tutor/landing/home`,
      about: `/tutor/landing/about-us`,
      blog: {
        index: `/tutor/landing/blog`,
      },
    },
    portal: {
      application: {
        ready: `/tutor/portal/application`,
        step1: {
          pass: `/tutor/portal/application/step-1/passed`,
        },
      },
      home: '/tutor/portal/home',
    },
  },
  share: {
    challenge: (challengeId: string, optionId: string, referralCode: string) =>
      `/share/challenge?challengeId=${challengeId}&optionId=${optionId}&referralCode=${referralCode}`,
  },

  // pc web
  caf: {
    highlight: {
      lesson_list: (lessonId, tab) => `/student/portal/lessons/review/${lessonId}?type=caf&tab=${tab}`,
    },
  },
  // mobile web
  webview: {
    policy: {
      privacy: `/webview/policy/privacy`,
      terms: `/webview/policy/terms`,
      all: `/webview/policy/all`,
      lessonPolicy: `/webview/policy/lesson-policy`,
      ipPolicy: `/webview/policy/ip-policy`,
    },
    leaveRingle: `/webview/appscreens/leave-ringle`,
    welcome: `/webview/welcome`,
    home: `/webview/home`,
    mypage: {
      point: `/webview/mypage/point`,
      invite: `/webview/mypage/invite`,
      basic: `/webview/mypage/basic-profile`,
      timezone: `/webview/mypage/timezone`,
      language: `/webview/mypage/change-language`,
      profile: `/webview/mypage/profile`,
      intro: `/webview/mypage/intro`,
      introDetail: `/webview/mypage/intro-detail`,
      introResult: `/webview/mypage/intro-result`,
      notification: `/webview/mypage/notification`,
      password: `/webview/mypage/change-password`,
      currentCredit: `/webview/mypage/credit`,
      issurance: {
        href: `/webview/mypage/certificate/issurance/[certificateId]`,
        as: (certificateId) => `/webview/mypage/certificate/issurance/${certificateId}`,
      },
      creditDetail: {
        href: `/webview/mypage/credit/details/[creditId]`,
        as: (creditId: number) => `/webview/mypage/credit/details/${creditId}`,
      },
      weeklyLessonPackage: (couponBundleId: number) =>
        `/webview/mypage/credit/weekly-lesson-package?id=${couponBundleId}`,
      attendance: {
        href: `/webview/mypage/certificate/currentCredit/[id]`,
        as: (id) => `/webview/mypage/certificate/currentCredit/${id}`,
      },
      issueCredit: {
        href: `/webview/mypage/certificate/[purchaseId]`,
        as: (purchaseId, tabId?: number) => `/webview/mypage/certificate/${purchaseId}&tabId=${tabId}`,
      },
      extendCoupons: (creditId: number) => `/webview/credit/extend-coupons/${creditId}`,
      couponBox: `/webview/mypage/coupon-box`,
      account: `/webview/account`,
      zoom: `/webview/zoom`,
      leave: `/webview/mypage/leave`,
    },
    pricing: {
      index: `/webview/pricing`,
      success: (id) => `/webview/pricing/success/${id}`,
      fail: (id) => `/webview/pricing/fail/${id}`,
      detail: (id) => `/webview/pricing/${id}`,
      redirect: `/webview/pricing/redirect`,
    },
    notice: {
      index: `/webview/notice`,
      detail: (slug) => `/webview/blog/${slug}`,
    },
    helpCenter: {
      index: `/webview/help-center`,
      showMessageger: `/webview/help-center?showMessenger=true`,
      detail: (slug) => `/webview/blog/${slug}`,
    },
    tutors: {
      list: (tab: string) => `/webview/tutors?tab=${tab}`,
      detail: {
        href: `/webview/tutors/[id]`,
        as: (id, tab = 0) => `/webview/tutors/${id}?tab=${tab}`,
      },
      my: `/webview/tutors/my`,
      search: (keyword) => `/webview/tutors/search?keyword=${keyword}`,
      experienced: `/webview/tutors/experienced`,
    },
    challenge: {
      index: `/webview/challenge`,
      detail: (challengeId) => `/webview/challenge?activity=ChallengeDetailActivity&challengeId=${challengeId}`,
      option: {
        detail: (challengeId) => `/webview/challenge?activity=OptionSelectActivity&challengeId=${challengeId}`,
      },
      badges: `/webview/challenge?activity=MyBadgesActivity`,
      ended: `/webview/challenge?activity=EndedChallengeActivity`,
      pricing: `/webview/challenge?activity=ChallengePricingMainActivity`,
    },

    event: {
      index: `/webview/event`,
      list: `/webview/event?activity=EventListActivity`,
      detail: (slug) => `/webview/event?activity=EventDetailActivity&slug=${slug}`,
    },
    blog: {
      index: `/webview/blog`,
      detail: (slug) => `/webview/blog?activity=BlogDetailActivity&slug=${slug}`,
    },
    ai_analysis: {
      statistics: {
        index: `/webview/lessons?activity=AIStatisticActivity`,
      },
    },
    caf: {
      statistic: {
        index: (start?: string, end?: string) => `/webview/caf/statistic?startDate=${start}&endDate=${end}`,
        highlight: {
          list: (start: string, end: string, pinned?: boolean) =>
            `/webview/caf/statistic/highlight/list${urlParams({ startDate: start, endDate: end, pinned })}`,
          tag: (tagId: number, start: string, end: string) =>
            `/webview/caf/statistic/highlight/${tagId}?startDate=${start}&endDate=${end}`,
        },
      },
      analysis: {
        index: (type: string, componentId: number) => `/webview/caf/analysis/${type}/${componentId}`,
        detail: (type: string, metricId: number, errorTag: number) =>
          `/webview/caf/analysis/${type}/detail/${metricId}?error_tag=${errorTag}`,
        level: (status: string, level: number) => `/webview/caf/analysis/caf_level?status=${status}&level=${level}`,
        jobFilter: `/webview/caf/analysis/job_filter`,
        jobInput: `/webview/caf/analysis/job_filter/input`,
      },
      highlight: {
        detail: (highlightId: number, tag: number) => `/webview/caf/highlight/${highlightId}?tag=${tag}`,
        list: (start: string, end: string) => `/webview/caf/highlight/list?startDate=${start}&endDate=${end}`,
        lesson_list: (lessonId: number, level: number, pinned?: boolean) =>
          `/webview/caf/highlight/list/${lessonId}${urlParams({ level, pinned })}`,
        tip: (tag) => `/webview/caf/highlight/ringle_tip?tag=${tag}`,
      },
      highlight_edit: (lessonId: number) => `/webview/caf/highlight-edit/${lessonId}`,
    },
    lessons: {
      additionalSchedule: {
        index: (lessonId = null) =>
          lessonId
            ? `/webview/lessons/additional-schedule?lessonId=${lessonId}`
            : `/webview/lessons/additional-schedule`,
        complete: `/webview/lessons/additional-schedule/complete`,
      },
      index: `/webview/lessons`,
      schedule: `/webview/lessons/schedule`,
      onboarding: `/webview/lessons/onboarding`,
      lessonMaterialDetail: (lessonId, materialId) =>
        `/webview/lessons?activity=LessonMaterialDetailActivity&lessonId=${lessonId}&materialId=${materialId}`,

      lesson_enter: (lessonId) => `/webview/lessons?activity=LessonEnterBeforeActivity&lessonId=${lessonId}`,
      lesson_cancle: (lessonId) => `/webview/lessons?activity=LessonCancelActivity&lessonId=${lessonId}`,
      lesson_style: (lessonId) => `/webview/lessons?activity=LessonStyleActivity&lessonId=${lessonId}`,
      tutor_matching_option: (lessonId) =>
        `/webview/lessons?activity=TutorMatchingOptionChangeActivity&lessonId=${lessonId}`,
      lesson_popup: (lessonId) => `/webview/lessons?activity=LessonReminderActivity&lessonId=${lessonId}`,
      preparation: (lessonId: number | string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}`,
      preparation_in_review: (lessonId: number | string, isReview: boolean) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&isReview=${isReview}`,
      preparation_insight: (lessonId: number | string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_question: (lessonId: number | string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_upload: (lessonId: number | string, type: string) =>
        `/webview/lessons?activity=LessonPreparationActivity&lessonId=${lessonId}&type=${type}`,
      preparation_over: (lessonId: string) =>
        `/webview/lessons?activity=LessonPreparationOverActivity&lessonId=${lessonId}`,
      preparation_answer_change: () => `/webview/lessons?activity=AnswerModifyActivity`,
      evaluation: (lessonId: number) => `/webview/lessons?activity=LessonEvaluationActivity&lessonId=${lessonId}`,
      materialSelect: (lessonId, materialId, type = null) =>
        `/webview/lessons?activity=LessonMaterialChangeActivity&lessonId=${lessonId}&materialId=${materialId}&type=${type}`,
      review: {
        detail: (lessonId) => `/webview/lessons?activity=ReviewDetailActivity&lessonId=${lessonId}`,
        detailAsType: (lessonId, type: 'caf' | 'feedback' | 'note' | 'record') =>
          `/webview/lessons?activity=ReviewDetailActivity&lessonId=${lessonId}&type=${type}`,
        lessonSetting: (lessonId) => `/webview/lessons?activity=ReviewLessonStyleActivity&lessonId=${lessonId}`,
      },
      material_detail: (materialId) => `/webview/lessons?activity=MaterialViewActivity&materialId=${materialId}`,
    },
    materials: {
      index: `/webview/materials`,
      detail: (materialId) => `/webview/materials?activity=MaterialsDetailActivity&materialId=${materialId}`,
      category: (categoryId) => `/webview/materials?activity=MaterialsCategoryActivity&categoryId=${categoryId}`,
    },
    study: {
      readTogether: {
        detail: (slug) => `/webview/study/read-together?activity=ReadTogetherDetailActivity&slug=${slug}`,
        success: (purchaseId) =>
          `/webview/study/read-together?activity=ReadTogetherPricingSuccessActivity&purchaseId=${purchaseId}`,
        fail: (purchaseId, errorMsg) =>
          `/webview/study/read-together?activity=ReadTogetherPricingFailActivity&purchaseId=${purchaseId}&errorMsg=${errorMsg}`,
      },
      readAlone: {
        detail: (slug) => `/webview/study/read-alone?activity=ReadAloneDetailActivity&slug=${slug}`,
        success: (purchaseId) =>
          `/webview/study/read-alone?activity=ReadAlonePricingSuccessActivity&purchaseId=${purchaseId}`,
        fail: (purchaseId, errorMsg) =>
          `/webview/study/read-alone?activity=ReadAlonePricingFailActivity&purchaseId=${purchaseId}&errorMsg=${errorMsg}`,
      },
      index: `/webview/study`,
      dailyActivity: (date) => `/webview/study?activity=StudyDailyActivity&date=${date}`,
      biteSizedFeedbackLanding: `/webview/study?activity=StudyBiteSizedFeedbackLandingActivity`,
      userReport: (setId) => `/webview/study?activity=StudyUserFeedbackActivity&setId=${setId}`,
    },
    myRingle: {
      index: `/webview/my-ringle`,
      prevReadTogether: `/webview/study/read-together?activity=PrevReadTogetherDetailActivity`,
      prevReadAlone: `/webview/study/read-alone?activity=PrevReadAloneDetailActivity`,
      badges: `/webview/my-ringle?activity=MyBadgesActivity`,
      achievements: `/webview/my-ringle?activity=AchievementsActivity`,
      materials: `/webview/materials?activity=MaterialsBookmarkActivity`,
      experiencedMaterials: `/webview/materials?activity=MaterialsPrevActivity`,
    },
    conversationalAI: {
      index: `/webview/conversational-ai`,
      list: `/webview/conversational-ai?activity=ConversationListActivity`,
      instruction: (material) =>
        `/webview/conversational-ai?activity=ConversationInstructionActivity&courseId=${material.courseId}&roleplayId=${material.roleplayId}`,
      feedback: (threadId) => `/webview/conversational-ai?activity=ConversationFeedbackActivity&threadId=${threadId}`,
      complete: (threadId) => `/webview/conversational-ai?activity=ConversationCompleteActivity&threadId=${threadId}`,
      analysis: (threadId) => `/webview/conversational-ai?activity=ConversationAnalysisActivity&threadId=${threadId}`,
      selectMaterials: `/webview/conversational-ai?activity=ConversationMaterialSelectActivity`,
      searchMaterials: `/webview/conversational-ai?activity=ConversationMaterialSearchActivity`,
      main: (threadId) => `/webview/conversational-ai?activity=ConversationMainActivity&threadId=${threadId}`,
      setting: {
        index: `/webview/conversational-ai?activity=ConversationSettingActivity`,
        level: `/webview/conversational-ai?activity=ConversationSettingLevelActivity`,
        speed: `/webview/conversational-ai?activity=ConversationSettingSpeedActivity`,
      },
      notification: `/webview/conversational-ai?activity=ConversationNotificationActivity`,
      test: (roleplayId) => `/webview/conversational-ai?activity=ConversationTestActivity&roleplayId=${roleplayId}`,
    },
    aiTutor: {
      index: (courseId = null, tab = null) => {
        if (courseId) {
          return `/webview/ai-tutor?activity=AITutorActivity&courseId=${courseId}&tabId=${tab}`
        } else {
          return `/webview/ai-tutor?activity=AITutorActivity&tabId=${tab}`
        }
      },
      list: () => `/webview/ai-tutor?activity=AITutorListActivity`,
      purchase: `/webview/ai-tutor?activity=AITutorPurchaseActivity`,
      purchaseComplete: (pushNotification, platform, productId) =>
        `/webview/ai-tutor?activity=AITutorPurchaseCompleteActivity&pushNotification=${pushNotification}&platform=${platform}&productId=${productId}`,
      activity: (id, tabId = 0) => `/webview/ai-tutor?activity=AITutorActActivity&lessonId=${id}&tabId=${tabId}`,
      roleplay: (id) => `/webview/ai-tutor?activity=AITutorRoleplayActivity&threadId=${id}`,
      roleplayDone: (id, roleplayId) =>
        `/webview/ai-tutor?activity=AITutorRoleplayCompleteActivity&threadId=${id}&roleplayId=${roleplayId}`,
      roleplayInstruction: (material) =>
        `/webview/ai-tutor?activity=AITutorRoleplayInstructionActivity&roleplayId=${material.roleplayId}`,
      discussion: {
        materialDetail: (courseId) =>
          `/webview/ai-tutor?activity=AITutorDiscussionMaterialDetailActivity&materialId=${courseId}`,
        materialSearch: () => `/webview/ai-tutor?activity=AITutorDiscussionMaterialSearchActivity`,
        materialCategory: (categoryId) =>
          `/webview/ai-tutor?activity=AITutorDiscussionMaterialCategoryActivity&categoryId=${categoryId}`,
        instruction: (courseId) =>
          `/webview/ai-tutor?activity=AITutorDiscussionInstructionActivity&courseId=${courseId}`,
      },
    },
  },
  admin: {
    home: `/admin`,
  },
}
export default urls
