// @ts-strict-ignore
import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH, fetchErrorAction, fetchSuccessAction } from '../../reducer/fetchReducer'

function* fetchSaga(action) {
  try {
    const data = yield call(action.call, action.payload)
    yield put(fetchSuccessAction(action.id, data))
    if (action.callback) {
      yield call(action.callback, data, { success: true, error: false })
    }
  } catch (error) {
    yield put(fetchErrorAction(action.id, error))
    if (action.callback) {
      yield call(action.callback, null, { success: false, error: true })
    }
  }
}

export default function* watchFetch() {
  yield takeEvery(FETCH, fetchSaga)
}
