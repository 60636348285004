import { useEffect } from 'react'
import { useDSModalState } from '../modules/DSModalContext'
import { DSMODAL_SIZE_TYPE } from './DSModalMain'

export interface DSModalContentProps {
  children?: React.ReactNode
  className?: string
}

const DSModalContent = (props: DSModalContentProps) => {
  const { children, className = '' } = props
  const { size } = useDSModalState()
  useEffect(() => {
    setTimeout(() => {
      const contentElement = document.getElementById('modal-content')
      if (contentElement) {
        contentElement.scrollTop = 0
      }
    }, 10)
  }, [])
  const classNameStatic = CLASSNAME[size]

  return (
    <div id="modal-content" className={classNameStatic + ' ' + className}>
      {children}
    </div>
  )
}

export default DSModalContent

const CLASSNAME: { [size in DSMODAL_SIZE_TYPE]?: string } = {
  sm: 'w-full Body2-14Md text-gray-700 mt-[4px]',
  lg: 'w-full Body1-16Md text-gray-700 mt-[12px]',
  xl: 'w-full Body2-14Md text-gray-700 mt-[8px] overflow-y-auto overflow-x-hidden',
  custom: 'w-full',
}
