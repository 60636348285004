// @ts-strict-ignore
export const INIT = 'profile/INIT'
export const SET_MATERIAL_TOPICS = 'profile/SET_MATERIAL_TOPICS'
export const SET_SELECTED_MATERIAL_TOPICS = 'profile/SET_SELECTED_MATERIAL_TOPICS'
export const SET_INTEREST_KEYWORDS = 'profile/SET_INTEREST_KEYWORDS'
export const SET_SELECTED_INTEREST_KEYWORDS = 'profile/SET_SELECTED_INTEREST_KEYWORDS'

const initialState = {
  materialTopics: [],
  interestKeywords: [],
  selectedMaterialTopics: [],
  selectedInterestKeywords: [],
}

export const initAction = () => ({ type: INIT })

export const setMaterialTopicsAction = (value) => ({
  type: SET_MATERIAL_TOPICS,
  value,
})

export const setSelectedMaterialTopicsAction = (value) => ({
  type: SET_SELECTED_MATERIAL_TOPICS,
  value,
})

export const setInterestKeywordsAction = (value) => ({
  type: SET_INTEREST_KEYWORDS,
  value,
})

export const setSelectedInterestKeywordsAction = (value) => ({
  type: SET_SELECTED_INTEREST_KEYWORDS,
  value,
})

const f = (action, func) => func(action)

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...initialState,
      }

    case SET_MATERIAL_TOPICS:
      return f(action, ({ value }) => {
        return {
          ...state,
          materialTopics: value,
        }
      })

    case SET_SELECTED_MATERIAL_TOPICS:
      return f(action, ({ value }) => {
        return {
          ...state,
          selectedMaterialTopics: value,
        }
      })

    case SET_INTEREST_KEYWORDS:
      return f(action, ({ value }) => {
        return {
          ...state,
          interestKeywords: value,
        }
      })

    case SET_SELECTED_INTEREST_KEYWORDS:
      return f(action, ({ value }) => {
        return {
          ...state,
          selectedInterestKeywords: value,
        }
      })

    default:
      return state
  }
}
export default profileReducer
