// @ts-strict-ignore
const GET_REVIEW_DATA = 'review/GET_REVIEW_DATA' as const
const PUT_AUDIO_CURRENT_TIME = 'review/PUT_AUDIO_CURRENT_TIME' as const
const PUT_AUDIO_AUTO_SCROLL = 'review/PUT_AUDIO_AUTO_SCROLL' as const

export const getReviewData = (field: string, data) => ({
  type: GET_REVIEW_DATA,
  field,
  data,
})
export const putAudioCurrentTime = (time, isAuto) => ({
  type: PUT_AUDIO_CURRENT_TIME,
  time,
  isAuto,
})
export const putAudioAutoScroll = () => ({ type: PUT_AUDIO_AUTO_SCROLL })

const initialState = {
  script: null,
  suggestion: null,
  phrase: null,
  caf: null,
  feedback: null,
  analysis: null,
  lessonInfo: null,
  audioCurrentTime: 0,
  audioAuto: false,
  isAutoScroll: true,
}

const f = (action, func) => func(action)
// 리듀서
const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW_DATA:
      return f(action, ({ field, data }) => {
        return {
          ...state,
          [field]: data,
        }
      })
    case PUT_AUDIO_CURRENT_TIME:
      return f(action, ({ time, isAuto }) => {
        return {
          ...state,
          audioCurrentTime: time ? time : 0,
          audioAuto: isAuto,
        }
      })
    case PUT_AUDIO_AUTO_SCROLL:
      return {
        ...state,
        isAutoScroll: !state.isAutoScroll,
      }
    default:
      return state
  }
}

export default reviewReducer
