import { ChallengeCampaignStatus } from '@repo/graphql/graphql'
import ImageUrlBuilder from '@sanity/image-url'
import {
  compareAsc,
  differenceInDays,
  isValid,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import detectBrowserLanguage from 'detect-browser-language'
import cookies from 'next-cookies'
import Router, { NextRouter } from 'next/router'
import Cookies from 'universal-cookie'
import {
  LOCALE_EN,
  LOCALE_JA,
  LOCALE_KO,
  LOCALE_VI,
  LOCALE_ZH_CHS,
  LOCALE_ZH_CHT,
  LocaleType,
  localeIndex,
} from '../modules/i18n/config'
import urls from '../modules/urls'
import {
  COOKIE_OPT,
  COUNTRY_ALPHA2_CODE,
  DOLLARS_TO_KRW,
  ROLE_STUDENT,
  SESSION_ROLE,
  TODO_TYPES,
} from '../modules/vars'
import { CURRENCY_LIST } from './consts/pricing'
import { formatTzTime } from './dateFormat'
import { COOKIE_DOMAIN, RINGLE_DOMAIN } from './envVars'
import { Url } from './hooks/common/useAppRouter'
import { getInitialLocale } from './i18n/getInitialLocale'
import { n_challenge } from './i18n/strings/auto/wording'
import client from './sanityClient'

export const isValidDate = (date) => {
  if (typeof date !== 'string' || date.length !== 10 || date[2] + date[5] !== '--') {
    return false
  }
  const mm = date.slice(0, 2)
  const dd = date.slice(3, 5)
  const yyyy = date.slice(6)
  const days = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ]
  let day = 30
  switch (mm) {
    case '01':
    case '03':
    case '05':
    case '07':
    case '08':
    case '10':
    case '12':
      if (!days.includes(dd)) {
        return false
      }
      break
    case '02':
      if (parseInt(yyyy) % 4 == 0) {
        day = 29
      } else {
        day = 28
      }
      if (!days.slice(0, day).includes(dd)) {
        return false
      }
      break
    default:
      day = 30
      if (!days.slice(0, day).includes(dd)) {
        return false
      }
      break
  }
  return true
}

export const isInputValue = (value) => {
  if (value == undefined || value == '' || value == null || value == ' ') {
    return false
  } else {
    return true
  }
}

export const isNumber = (str) => {
  const re = /[^0-9]/g
  if (str.match(re)) {
    return false
  }
  return true
}

export function firstUser(currentUser) {
  return !currentUser || (currentUser && currentUser.profile?.total_amount == 0)
}

export function isFirstBenefitUser(currentUser) {
  if (!currentUser) return true

  return currentUser && currentUser.first_purchase_benefit_available
}
export function isFitB2BUser(currentUser) {
  if (!currentUser) return false

  const codes = ['fit10100', 'fit10120', 'fit15180', 'fit20240', 'fit25150', 'fit30360', 'fit00000']

  return codes.includes(currentUser?.student_card?.company_code_group_code)
}
export function isInTargetGroupCompany(currentUser, codes) {
  if (!currentUser) return false

  return codes.includes(currentUser?.student_card?.company_code_group_code)
}
export function isInTargetSingleCompany(currentUser, codes) {
  if (!currentUser) return false

  return codes.includes(currentUser?.student_card?.company_code)
}
export function isB2BHRGroup(currentUser) {
  if (!currentUser) return false

  return currentUser?.braze?.private?.customer_type == 'B2B HR'
}
export function isSamsungUser(currentUser) {
  if (!currentUser) return false

  return currentUser?.student_card?.company_code == 'samsung1938'
}

export function isHavePromotionPointUser(currentUser) {
  if (!currentUser) return false

  return currentUser && currentUser.has_promotion_points
}

export function isNewPortalUser(currentUser) {
  if (!currentUser) return false

  if (currentUser.new_portal_user) return true

  if (currentUser.property_0 == 3) return true

  return false
}

export function isSecretPromotionUser(currentUser) {
  if (!currentUser) return false

  if (currentUser.id == 14240) return true

  return currentUser.secret_promotion_user
}

export function paidUser(currentUser) {
  return currentUser && currentUser.profile?.total_amount > 0
}

export function isWithIn24HoursUser(currentUser) {
  if (!currentUser) return false

  const dateString = currentUser?.braze?.private?.signup_date
  const targetDate = new Date(dateString).getTime()
  const now = new Date().getTime()
  const difference = now - targetDate

  const hoursDifference = difference / (1000 * 60 * 60)

  return hoursDifference <= 24
}

export function add24Hours(dateString) {
  const date = new Date(dateString)
  const result = new Date(date.getTime() + 24 * 60 * 60 * 1000)

  return result
}

export function languageByCountryCode(countryCode) {
  switch (countryCode) {
    case COUNTRY_ALPHA2_CODE.KR:
      return LOCALE_KO
    case COUNTRY_ALPHA2_CODE.JP:
      return LOCALE_JA
    case COUNTRY_ALPHA2_CODE.VN:
      return LOCALE_VI
    case COUNTRY_ALPHA2_CODE.CN:
      return LOCALE_ZH_CHS
    case COUNTRY_ALPHA2_CODE.TW:
      return LOCALE_ZH_CHT
    default:
      return LOCALE_EN
  }
}

export function getCurrency(countryCode) {
  const currencyIdx = CURRENCY_LIST.findIndex((currency) => currency.country_code === countryCode)
  return currencyIdx === -1 ? 'KRW' : CURRENCY_LIST[currencyIdx].name
}

export function isSupportedCountryCode(countryCode) {
  return (
    countryCode === COUNTRY_ALPHA2_CODE.KR ||
    countryCode === COUNTRY_ALPHA2_CODE.US ||
    countryCode === COUNTRY_ALPHA2_CODE.JP ||
    countryCode === COUNTRY_ALPHA2_CODE.VN ||
    countryCode === COUNTRY_ALPHA2_CODE.VN ||
    countryCode === COUNTRY_ALPHA2_CODE.CN ||
    countryCode === COUNTRY_ALPHA2_CODE.TW
  )
}

export function setAdHistory(query) {
  if (!query) {
    return
  }

  const cookies = new Cookies()
  const utm_ad_info = cookies.get('utm_ad_info')
  const { utm_source = '', utm_medium = '', utm_term = '', utm_campaign = '', utm_content = '' } = query
  let str = ''
  let count = 0
  if (utm_ad_info == '' || utm_ad_info == undefined) {
    str = '#1-rg'
    count = 1
  } else {
    count = parseInt(utm_ad_info.substring(0, 2)[1])
    str = `#${count + 1}-rg`
  }
  if (count != 0) {
    if (utm_source != '') {
      str += `&utm_source=${utm_source}`
    }
    if (utm_medium != '') {
      str += `&utm_medium=${utm_medium}`
    }
    if (utm_term != '') {
      str += `&utm_term=${utm_term}`
    }
    if (utm_campaign != '') {
      str += `&utm_campaign=${utm_campaign}`
    }
    if (utm_content != '') {
      str += `&utm_content=${utm_content}`
    }
    const now = new Date()
    const utcDate = utcToZonedTime(now, 'UTC')
    const formattedNow = format(utcDate, 'yyyy-MM-dd HH:mm:ss X', { timeZone: 'UTC' })
    if (typeof document !== 'undefined') {
      str += `&referrer=${document.referrer || ''}`
    }
    if (str.length > 5) {
      str += `&time=${formattedNow}`
      cookies.set('utm_ad_info', str + `${utm_ad_info ? utm_ad_info : ''}`, COOKIE_OPT)
    }
  }
}
export function setReferralCode(query) {
  if (!query) {
    return
  }

  const cookies = new Cookies()
  if (query.referralCode) {
    cookies.set('referralCode', query.referralCode, COOKIE_OPT)
  }
}
export function setVisitUtm(query) {
  const { utm_source = '', utm_medium = '', utm_term = '', utm_campaign = '', utm_content = '' } = query

  if (utm_source == '' && utm_medium == '' && utm_term == '' && utm_campaign == '' && utm_content == '') {
    return
  }

  const cookies = new Cookies()
  const expires = new Date()
  expires.setTime(expires.getTime() + 1 * 6 * 60 * 60 * 1000) // 쿠키 만료 기간을 6시간으로 설정

  let str = ''

  if (utm_source != '') {
    str += `&utm_source=${utm_source}`
  }
  if (utm_medium != '') {
    str += `&utm_medium=${utm_medium}`
  }
  if (utm_term != '') {
    str += `&utm_term=${utm_term}`
  }
  if (utm_campaign != '') {
    str += `&utm_campaign=${utm_campaign}`
  }
  if (utm_content != '') {
    str += `&utm_content=${utm_content}`
  }

  cookies.set('visit_utm_ad_info', str, { domain: COOKIE_DOMAIN, path: '/', expires: expires })
}

export function parseUtmParams(paramString) {
  const paramsObj = {
    utm_source: null,
    utm_medium: null,
    utm_term: null,
    utm_campaign: null,
    utm_content: null,
  }

  const pairs = paramString.split('&') || [] // '&'를 기준으로 각 파라미터를 분리

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=') // '='를 기준으로 키와 값을 분리
    // 값이 'null'이면 null을 할당, 그렇지 않으면 값을 그대로 할당
    paramsObj[key] = value === 'null' ? 'null' : decodeURIComponent(value)
  })

  return paramsObj
}

interface UTMData {
  utm_source: string
  utm_medium: string
  utm_term: string
  utm_campaign: string
  utm_content: string
  time: string
  referrer: string
}

function parseUTMBlock(utmBlock: string): UTMData {
  const parts = utmBlock.length > 0 ? utmBlock.split('&') : []
  const utmData = {
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_campaign: '',
    utm_content: '',
    time: '',
    referrer: '',
  }

  parts.forEach((part) => {
    const [key, value] = part.split('=')
    // 'rg'를 제외한 파트 처리
    if (key.includes('utm') || key === 'time') {
      utmData[key as keyof UTMData] = decodeURIComponent(value || '')
    }
  })
  return utmData
}

interface UTMInfo {
  referrer?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
}

export function getSupportedMimeTypes() {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska']
  const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus']

  const supportedTypes = []
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ]
      variations.forEach((variation) => {
        if (MediaRecorder.isTypeSupported(variation)) supportedTypes.push(variation)
      })
    })
  })
  return supportedTypes
}
export function getMonthName(d) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return [monthNames[d], shortMonthNames[d]]
}

export const wordCount = (text) => {
  text = text?.trim() || ''
  return text.length > 0 ? text.split(/\s+/).length : 0
}

export const getDday = (date) => {
  const now = new Date().getTime()
  const remainTime = date - now
  return Math.floor(remainTime / (1000 * 60 * 60 * 24))
}

export const getBrowser = (useragent) => {
  let browser = ''
  if (useragent.indexOf('FBAN') > -1 || useragent.indexOf('FBAV') > -1) {
    browser = 'facebook'
  } else if (useragent.indexOf('Instagram') > -1) {
    browser = 'instagram'
  } else if (useragent.indexOf('KAKAOTALK') != -1) {
    browser = 'kakaotalk'
  } else {
    return null
  }

  return {
    browser: browser,
  }
}

export const detectInAppBrowser = (agent) => {
  const inAppRegex = [
    /KAKAOTALK/i,
    /Instagram/i,
    /NAVER/i,
    /zumapp/i,
    /Whale/i,
    /Snapchat/i,
    /Line/i,
    /everytimeApp/i,
    /Whatsapp/i,
    /Electron/i,
    /wadiz/i,
    /AliApp/i,
    /FB_IAB/i,
    /FB4A/i,
    /FBAN/i,
    /FBIOS/i,
    /FCSS/i,
    /SamsungBrowser/i,
  ]

  return inAppRegex.some((mobile) => agent.match(mobile))
}

export function redirect(ctx, location) {
  const status = 302
  if (ctx.res) {
    // Seems to be the version used by zeit
    ctx.res.writeHead(status, {
      Location: location,
      'Content-Type': 'text/html; charset=utf-8',
    })
    ctx.res.end()
    return
  }
  Router.replace(location)
}

export const formatTime = (time: number) => {
  let minutes, seconds
  minutes = Math.floor(time / 60)
  minutes = minutes >= 10 ? minutes : '0' + minutes
  seconds = Math.floor(time % 60)
  seconds = seconds >= 10 ? seconds : '0' + seconds
  return minutes + ':' + seconds
}

export const formattedDate = (date: any, timezone: string) =>
  isValid(new Date(date)) ? formatTzTime(date, timezone, 'yyyy.MM.dd HH:mm') : date

export const redirectHome = (ctx, currentUser) => {
  const tutorUrl = `/en${urls.tutor.landing.home}`

  if (currentUser) {
    // if logged in
    let locale = 'en'
    if (currentUser.locale == COUNTRY_ALPHA2_CODE.KR) {
      locale = LOCALE_KO
    } else if (currentUser.locale == 'zh_chs') {
      locale = LOCALE_ZH_CHS
    } else if (currentUser.locale == 'zh_cht') {
      locale = LOCALE_ZH_CHT
    } else if (currentUser.locale == 'vi') {
      locale = LOCALE_VI
    } else if (currentUser.locale == 'ja') {
      locale = LOCALE_JA
    }
    const studentUrl = `/${locale}${urls.student.landing.home}`
    const location = currentUser.role == ROLE_STUDENT ? studentUrl : tutorUrl
    redirect(ctx, location)
    return
  }

  const { session_role } = cookies(ctx)
  if (session_role) {
    const { locale = LOCALE_KO } = cookies(ctx)
    const studentUrl = `/${locale}${urls.student.landing.home}`
    const location = session_role == SESSION_ROLE.TUTOR ? tutorUrl : studentUrl
    redirect(ctx, location)
    return
  }

  if (ctx.query['lang'] == LOCALE_KO) {
    const studentUrl = `/ko${urls.student.landing.home}`
    redirect(ctx, studentUrl)
    return
  }

  const browserLang = detectBrowserLanguage()

  if (browserLang) {
    if (browserLang.includes('ko')) {
      redirect(ctx, `/ko${urls.student.landing.home}`)
      return
    } else if (browserLang == 'zh-CN' || browserLang == 'zh') {
      //간체
      redirect(ctx, `/zh_chs${urls.student.landing.home}`)
      return
    } else if (browserLang == 'zh-TW' || browserLang == 'zh-HK') {
      //간체
      redirect(ctx, `/zh_cht${urls.student.landing.home}`)
      return
    } else if (browserLang.includes('ja')) {
      redirect(ctx, `/ja${urls.student.landing.home}`)
      return
    } else if (browserLang.includes('vi')) {
      redirect(ctx, `/vi${urls.student.landing.home}`)
      return
    } else {
      redirect(ctx, `/en${urls.student.landing.home}`)
      return
    }
  } else {
    redirect(ctx, `/en${urls.student.landing.home}`)
    return
  }
}

export const redirectNewPortal = (ctx, locale, urls) => {
  const newPortalUrl = `/${locale}${urls}`
  redirect(ctx, newPortalUrl)
  return
}

export const isMulticampus = (currentUser) => {
  if (currentUser) {
    if (currentUser.is_multicampus_member) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const locationMove = (router: NextRouter, locale: LocaleType, url: string) => {
  if (url.includes('[lang]')) {
    router.push(url.replace('[lang]', locale))
  } else {
    router.push(`/${locale}${url}`)
  }
}

export const locationMoveLocale = (router: NextRouter, url: string, config?: { shallow?: boolean }) => {
  if (
    url.startsWith('/en/') ||
    url.startsWith('/ko/') ||
    url.startsWith('/zh_chs/') ||
    url.startsWith('/zh_cht/') ||
    url.startsWith('/ja/')
  ) {
    router.push(url, undefined, config)
  } else if (url.includes('[lang]')) {
    router.push(url.replace('[lang]', getInitialLocale()), undefined, config)
  } else {
    router.push(`/${getInitialLocale()}${url}`, undefined, config)
  }
}

export const locationReplaceLocale = (router: NextRouter, url: Url, config?: { shallow?: boolean }) => {
  if (typeof url == 'string') {
    if (url.startsWith('/en/') || url.startsWith('/ko/')) {
      router.replace(url, undefined, config)
    } else if (url.includes('[lang]')) {
      router.replace(url.replace('[lang]', getInitialLocale()), undefined, config)
    } else {
      router.replace(`/${getInitialLocale()}${url}`, undefined, config)
    }
  } else {
    router.replace(url, undefined, config)
  }
}

export const locationMoveLocaleNewTab = (url) => {
  window.open(`/${getInitialLocale()}${url}`, '_blank')
}

export const locationMoveLocaleSizeTab = (url, locale = null) => {
  if (locale) {
    window.open(`/${locale}${url}`, '_blank', 'status=no,width=1100,height=1000,location=no')
  } else {
    window.open(`/${getInitialLocale()}${url}`, '_blank', 'status=no,width=800,height=1000,location=no')
  }
}

export const removeArray = (arr, value) => {
  return arr.filter((item) => {
    return item != value
  })
}

export const checkValidateFile = (file, validFileExtensions) => {
  let valid = false
  for (let j = 0; j < validFileExtensions.length; j++) {
    const sCurExtension = validFileExtensions[j]
    if (
      file.name.substr(file.name.length - sCurExtension.length, sCurExtension.length).toLowerCase() ==
      sCurExtension.toLowerCase()
    ) {
      valid = true
      break
    }
  }
  return valid
}

export const sumOfArray = (arr) => {
  if (arr.length == 0) return 0
  const sum = arr.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue
  })
  return sum
}

export const getChallengeDday = (comparingDate: string | Date, status: string, t, isLocaleKO) => {
  const kstTimezone = 'Asia/Seoul'
  const todayInUTC = new Date().toISOString()

  const todayInKST = utcToZonedTime(todayInUTC, kstTimezone)
  const comparingDateInKST = utcToZonedTime(comparingDate, kstTimezone)

  todayInKST.setHours(0, 0, 0, 0)
  comparingDateInKST.setHours(0, 0, 0, 0)

  const daysGap = differenceInDays(comparingDateInKST, todayInKST)

  const daysText =
    status === ChallengeCampaignStatus.OpenForRegistration
      ? daysGap > 0
        ? t(n_challenge.dday2(daysGap))
        : null
      : status === ChallengeCampaignStatus.Active
      ? daysGap > 0
        ? t(n_challenge.dday1(daysGap))
        : isLocaleKO
        ? '종료 D-Day'
        : 'End: D-Day'
      : null

  return daysText
}

export function timezonedNow(timezone = 'Asia/Seoul') {
  const now = new Date()
  return utcToZonedTime(now, timezone)
}

export const difference2Parts = (milliseconds) => {
  const secs = Math.floor(Math.abs(milliseconds) / 1000)
  const mins = Math.floor(secs / 60)
  const hours = Math.floor(mins / 60)
  const days = Math.floor(hours / 24)
  const millisecs = Math.floor(Math.abs(milliseconds)) % 1000
  const multiple = (term, n) => (n !== 1 ? `${n} ${term}s` : `1 ${term}`)

  return {
    days: days,
    hours: hours % 24,
    hoursTotal: hours,
    minutesTotal: mins,
    minutes: mins % 60,
    seconds: secs % 60,
    secondsTotal: secs,
    milliSeconds: millisecs,
    get diffStr() {
      return `${multiple(`day`, this.days)}, ${multiple(`hour`, this.hours)}, ${multiple(
        `minute`,
        this.minutes
      )} and ${multiple(`second`, this.seconds)}`
    },
    get diffStrMs() {
      return `${this.diffStr.replace(` and`, `, `)} and ${multiple(`millisecond`, this.milliSeconds)}`
    },
  }
}

// AWS BUCKET
const img_addr1 = 'd2mkevusy1mb28.cloudfront.net'
const img_addr2 = 'd38emex6h5e12i.cloudfront.net'

const convertResizableUrl = (url) => url.replace(img_addr1, img_addr2)

export const cleanUri = (url) => url.split('?')[0]
export const urlWidth = (url, w) => cleanUri(url ? convertResizableUrl(url) : '') + '?w=' + w

export const wordCountCalculate = (text) => {
  if (!text) {
    return 0
  }
  text = text.trim()
  return text.length > 0 ? text.split(/\s+/).length : 0
}

export const getUserCity = (timezone) => (timezone?.split('/')[1] ? timezone.split('/')[1] : timezone)

export const isPast = (timezone, time) => {
  return compareAsc(timezonedNow(timezone), new Date(time)) > 0
}

export const getTodoColor = (type) => {
  switch (type) {
    case TODO_TYPES.PREP:
      return 'success'
    case TODO_TYPES.REVIEW:
      return 'info'
    case TODO_TYPES.HOMEWORK:
      return 'secondary2'

    default:
      break
  }
}

export const ordinal = (i) => {
  const j = i % 10
  const k = i % 100
  if (j == 1 && k != 11) {
    return i + 'st'
  }
  if (j == 2 && k != 12) {
    return i + 'nd'
  }
  if (j == 3 && k != 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export const checkFinalWord = (word) => {
  if (typeof word !== 'string') return null
  const lastLetter = word[word.length - 1]
  const uni = lastLetter.charCodeAt(0)
  if (uni < 44032 || uni > 55203) return null

  return (uni - 44032) % 28 != 0
}

//FOR TUTOR SCHEDULING PAGE
export const dayOfWeeks = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
export const timezoneOffset = new Date().getTimezoneOffset() / 60
export const getAdjustedDateTimeMMT = (year, month, date, hour, minute, second) => {
  let adjustedDate = new Date()
  adjustedDate = setYear(adjustedDate, year)
  // date-fns에서 월은 0부터 시작하므로 1을 빼줍니다.
  adjustedDate = setMonth(adjustedDate, month - 1)
  adjustedDate = setDate(adjustedDate, date)
  adjustedDate = setHours(adjustedDate, hour)
  adjustedDate = setMinutes(adjustedDate, minute)
  adjustedDate = setSeconds(adjustedDate, second)

  return adjustedDate
}
export const getYearFromStr = (time) => parseInt(time?.slice(0, 4))
export const getMonthFromStr = (time) => parseInt(time?.slice(5, 7)) - 1
export const getDayFromStr = (time) => parseInt(time?.slice(8, 10))
export const getHourFromStr = (time: string) => parseInt(time?.slice(11, 13))
export const getMinuteFromStr = (time: string) => parseInt(time?.slice(14, 16))
export const getAbsoluteDate = (time) => {
  //2021-11-30T00:00 -> to Date object
  return getAdjustedDateTimeMMT(getYearFromStr(time), getMonthFromStr(time), getDayFromStr(time), 0, 0, 0)
}
export const getAbsoluteDateTime = (time) =>
  //2021-11-30T00:00 -> to DateTime object
  {
    return getAdjustedDateTimeMMT(
      getYearFromStr(time),
      getMonthFromStr(time),
      getDayFromStr(time),
      getHourFromStr(time),
      getMinuteFromStr(time),
      0
    )
  }

export const daysOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const t = (obj, lang) => {
  if (typeof obj == 'string') {
    return obj
  }
  const value = obj[localeIndex(lang)] === undefined ? obj[localeIndex(LOCALE_EN)] : obj[localeIndex(lang)]
  return value ? value : ''
}

export const getOgUrl = ({ lang, context }) => {
  const strippedPathName = context.pathname.includes('/[lang]')
    ? '/' + lang + context.pathname.replace('/[lang]', '')
    : context.pathname
  return `https://${RINGLE_DOMAIN}${strippedPathName}`
}

export const getLength = (str) => {
  if (typeof str !== 'string') {
    return 0
  }
  if (str.length == 0) {
    return 0
  }
  const temp = str
    .replace(/([\s]|(<\/?p>)|<br>|(&nbsp;))+/gm, ' ')
    .replace(/(<.*?>)/g, '')
    .trim()
  if (temp == '') {
    return 0
  }
  return temp.split(/\s+/).length
}

export const getRandom = (max, min) => {
  const randNum = Math.floor(Math.random() * (max - min + 1) + min)
  return randNum
}

export const avg = (arr) => {
  let sum = 0
  for (const item of arr) {
    sum += item
  }
  return sum / arr.filter((item) => item && item > 0).length || 0
}

export enum TUTOR_STATUS {
  APPLICATION = 0,
  ACTIVE = 1,
  NEW_TUTOR = 2,
  DORMANT = 3,
  READY = 4,
  ONE_STRIKE = 5,
  TWO_STRIKE = 6,
  OUT_THREE_STRIKE = 7,
  OUT_TEN_STRIKE = 8,
  HOLD = 9,
  BLACKLIST = 10,
  NOT_AGREED_POLICY = 11,
  UNDER_REVIEW = 12,
}

export const getTutorStatusString = (statusNum: number) => {
  switch (statusNum) {
    case TUTOR_STATUS.APPLICATION:
      return 'APPLICATION'
    case TUTOR_STATUS.ACTIVE:
      return 'ACTIVE'
    case TUTOR_STATUS.NEW_TUTOR:
      return 'NEW_TUTOR'
    case TUTOR_STATUS.DORMANT:
      return 'DORMANT'
    case TUTOR_STATUS.READY:
      return 'READY'
    case TUTOR_STATUS.ONE_STRIKE:
      return 'ONE_STRIKE'
    case TUTOR_STATUS.TWO_STRIKE:
      return 'TWO_STRIKE'
    case TUTOR_STATUS.OUT_THREE_STRIKE:
      return 'OUT_THREE_STRIKE'
    case TUTOR_STATUS.OUT_TEN_STRIKE:
      return 'OUT_TEN_STRIKE'
    case TUTOR_STATUS.HOLD:
      return 'HOLD'
    case TUTOR_STATUS.BLACKLIST:
      return 'BLACKLIST'
    case TUTOR_STATUS.NOT_AGREED_POLICY:
      return 'NOT_AGREED_POLICY'
    case TUTOR_STATUS.UNDER_REVIEW:
      return 'UNDER_REVIEW'
    default:
      return 'NOT_DEFINED'
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

enum DEVICE_TYPE {
  IOS,
  ANDROID,
}

export const getMobileDeviceType = (): DEVICE_TYPE => {
  if (typeof window === 'undefined') return DEVICE_TYPE.IOS
  return /iPad|iP(hone|od)/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    ? DEVICE_TYPE.IOS
    : DEVICE_TYPE.ANDROID
}

export const getTeensAppDownLinkByDevice = () => {
  const deviceType = getMobileDeviceType()

  return deviceType === DEVICE_TYPE.IOS
    ? 'https://apps.apple.com/us/app/%EB%A7%81%EA%B8%80-%ED%8B%B4%EC%A6%88-10%EB%8C%80%EB%A7%8C%EC%9D%84-%EC%9C%84%ED%95%9C-1-1-%ED%99%94%EC%83%81-%ED%8A%9C%ED%84%B0%EB%A7%81/id1570081422'
    : 'https://play.google.com/store/apps/details?id=com.ringleteens'
}

export const getQueryPageMetaData = (pageParam: number): { limit: number; offset: number } => ({
  limit: pageParam === 0 ? 10 : 5,
  offset: pageParam === 0 ? 0 : pageParam * 5 + 5,
})

export const getMonthNumbToStr = (monthNumb: number | any) => {
  switch (monthNumb) {
    case 1:
      return 'January'
    case 2:
      return 'February'
    case 3:
      return 'March'
    case 4:
      return 'April'
    case 5:
      return 'May'
    case 6:
      return 'June'
    case 7:
      return 'July'
    case 8:
      return 'August'
    case 9:
      return 'September'
    case 10:
      return 'October'
    case 11:
      return 'November'
    case 12:
      return 'December'
    default:
      return monthNumb
  }
}
export const getMonthStrToNumb = (monthString: string) => {
  switch (monthString) {
    case 'January':
      return 1
    case 'February':
      return 2
    case 'March':
      return 3
    case 'April':
      return 4
    case 'May':
      return 5
    case 'June':
      return 6
    case 'July':
      return 7
    case 'August':
      return 8
    case 'September':
      return 9
    case 'October':
      return 10
    case 'November':
      return 11
    case 'December':
      return 12
  }
}

//* ================================================================================================================================== */
//* 결제 / 화폐 관련 함수
//* ================================================================================================================================== */
export function financial(x, fixed = 2) {
  return Number.parseFloat(x).toFixed(fixed)
}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat().format(number)
}

export enum MATH_METHOD {
  ROUND = 'round',
  CEIL = 'ceil',
  FLOOR = 'floor',
}
export function calculateByMathMethod(mathMethod: MATH_METHOD, value) {
  switch (mathMethod) {
    case MATH_METHOD.ROUND:
      value = Math.round(value)
      break
    case MATH_METHOD.CEIL:
      value = Math.round(value)
      break
    case MATH_METHOD.FLOOR:
      value = Math.round(value)
      break
  }
  return value
}
export function priceWithCountryCode(
  price,
  country_code,
  converted = true,
  point = false,
  locale: any = LOCALE_KO,
  mathMethod = null
) {
  const exchange_rate = getExchangeRate(country_code)
  if (!converted) price = price / exchange_rate

  if (mathMethod) {
    price = calculateByMathMethod(mathMethod, price)
  }

  switch (country_code) {
    case COUNTRY_ALPHA2_CODE.KR:
      price = `${currencyFormat(price)}`
      if (!point) {
        if (locale == LOCALE_KO) {
          price += '원'
        } else {
          price = '₩' + price
        }
      }
      break
    case COUNTRY_ALPHA2_CODE.JP:
      price = `${currencyFormat(price)}円`
      break
    case COUNTRY_ALPHA2_CODE.VN:
      price = Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(price)
      break
    default:
      if (point) {
        price = Math.round(price * 100) / 100
      } else {
        price = Math.round(price)
      }
      price = `$${currencyFormat(price)}`
      break
  }
  return price
}

export const manWon = (price, countryCode, point = false, locale: any = LOCALE_KO, mathMethod = null) => {
  if (countryCode == COUNTRY_ALPHA2_CODE.KR) {
    return `${price / 10000}만원`
  } else {
    return priceWithCountryCode(price, countryCode, false, point, locale, mathMethod)
  }
}

/**
 * Adjusts a number to the specified digit.
 *
 * @param {"round" | "floor" | "ceil"} type The type of adjustment.
 * @param {number} value The number.
 * @param {number} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {number} The adjusted value.
 */
export const decimalAdjust = (type, value, exp) => {
  type = String(type)
  if (!['round', 'floor', 'ceil'].includes(type)) {
    throw new TypeError("The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.")
  }
  exp = Number(exp)
  value = Number(value)
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN
  } else if (exp === 0) {
    return Math[type](value)
  }
  const [magnitude, exponent = 0] = value.toString().split('e')
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`)
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e')
  return Number(`${newMagnitude}e${+newExponent + exp}`)
}
export const convertPrice = (price, country_code, point = true) => {
  switch (country_code) {
    case COUNTRY_ALPHA2_CODE.KR:
      break
    case COUNTRY_ALPHA2_CODE.JP:
    case COUNTRY_ALPHA2_CODE.TW:
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case COUNTRY_ALPHA2_CODE.CN:
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case COUNTRY_ALPHA2_CODE.VN:
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 1000) * 1000
      }
      break
    default:
      if (point) {
        price = decimalAdjust('floor', price, -2)
      } else {
        price = Math.round(price)
      }
      break
  }
  return price || 0
}
export const getExchangeRate = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_ALPHA2_CODE.KR:
      return 1
    case COUNTRY_ALPHA2_CODE.JP:
    case COUNTRY_ALPHA2_CODE.VN:
    case COUNTRY_ALPHA2_CODE.TW:
    case COUNTRY_ALPHA2_CODE.CN:
    case COUNTRY_ALPHA2_CODE.US:
      return DOLLARS_TO_KRW
    default:
      return DOLLARS_TO_KRW
  }
}

export const validPoints = (points: number | null) => {
  if (!points) {
    return 0
  }
  return points
}

//* ================================================================================================================================== */
//* 결제 / 화폐 관련 함수 리팩토링
//* ================================================================================================================================== */

/**
 * Convert Price with country code and Math methods
 * @param price - 변환활 금액
 * @param countryCode - 'KR' | 'US'
 * @param mathMethod - 'round' (default) | 'floor' | 'ceil'
 * @param decimals - false (default) | true ($ and (point or 수업권 연장))
 * @returns {number} 국가 코드에 따라 환율이 적용된 금액
 */
export function convertPriceWithCountryCode(
  price: number,
  countryCode: string,
  decimals = false,
  mathMethod: MATH_METHOD = MATH_METHOD.ROUND
): number {
  const exchangeRate = getExchangeRate(countryCode)
  price = price / exchangeRate

  // 0 (default) => 정수
  let exp = 0
  // if 소수점 필요한 경우 - $ and (point or 수업권 연장)
  // then 소수점 두 번째 자리까지 mathMethod
  if (decimals) {
    exp = -2
  }
  price = decimalAdjust(mathMethod, price, exp) // 2023.06 현재 정수 표현 또는 소수점 두 번째 자리까지 표현, 두 가지 경우만 필요해서 decimals를 boolean으로 설정했지만, 소수점이 다양하게 필요하다면 decimals를 number로 받아서 바로 decimalAdjust에 입력하기!
  return price || 0
}

export function formatNumberAsCurrency(price: number, countryCode: string, locale: string = LOCALE_KO) {
  let monetaryValue = ''
  switch (countryCode) {
    case COUNTRY_ALPHA2_CODE.KR:
      monetaryValue = `${currencyFormat(price)}`
      if (locale === LOCALE_KO) {
        monetaryValue = monetaryValue + '원'
      } else {
        monetaryValue = '₩' + monetaryValue
      }
      break
    case COUNTRY_ALPHA2_CODE.JP: // 2023.06 현재는 'KR' 제외한 모든 countryCode를 'US'로 치환해서 'JP'인 경우 존재 X
      monetaryValue = `${currencyFormat(price)}円`
      break
    case COUNTRY_ALPHA2_CODE.VN: // 2023.06 현재는 'KR' 제외한 모든 countryCode를 'US'로 치환해서 'VN'인 경우 존재 X
      monetaryValue = Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(price)
      break
    default:
      monetaryValue = `$${currencyFormat(price)}`
      break
  }
  return monetaryValue
}

export const dollar = (amount) => {
  if (isNaN(amount)) {
    return amount
  }
  if (amount < 0) {
    return `-$` + `${Math.abs(parseFloat(amount)).toFixed(2)}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  } else {
    return `$` + `${parseFloat(amount).toFixed(2)}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }
}

export function urlFor(source: string | object) {
  const builder = ImageUrlBuilder(client)
  return builder.image(source)
}
export function isWithin1Hour(dateData) {
  if (!dateData) return false

  const { year, month, day, hour, minute } = dateData
  const startTime = new Date(year, month - 1, day, hour, minute)
  const currentTime = new Date()
  const differenceInMilliseconds = startTime.getTime() - currentTime.getTime()

  return differenceInMilliseconds >= 0 && differenceInMilliseconds <= 3600000
}
export function formatDate(dateData, locale) {
  if (!dateData) return ''

  const { year, month, day, hour, minute } = dateData
  const date = new Date(year, month - 1, day, hour, minute)

  const monthFormatted = date.getMonth() + 1
  const dayFormatted = date.getDate()

  const getWeekdaysShort = (locale) => {
    switch (locale) {
      case 'ko':
        return ['일', '월', '화', '수', '목', '금', '토']
      case 'en':
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      case 'zh_chs':
        return ['日', '一', '二', '三', '四', '五', '六']
      case 'zh_cht':
        return ['日', '一', '二', '三', '四', '五', '六']
      case 'ja':
        return ['日', '月', '火', '水', '木', '金', '土']
      case 'vi':
        return ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
    }
  }
  const dayOfWeek = getWeekdaysShort(locale)[date.getDay()]

  let period = ''
  if (locale == LOCALE_KO) {
    if (hour == 0 && minute == 0) {
      period = '자정'
    } else if (hour == 0 && minute == 30) {
      period = '새벽'
    } else if (hour > 0 && hour < 5) {
      period = '새벽'
    } else if (hour >= 5 && hour < 12) {
      period = '오전'
    } else if (hour == 12 && minute == 0) {
      period = '정오'
    } else if (hour == 12 && minute == 30) {
      period = '오후'
    } else if (hour >= 12 && hour < 18) {
      period = '오후'
    } else if (hour >= 18) {
      period = '저녁'
    }
    return `${monthFormatted}.${dayFormatted} (${dayOfWeek}) ${period} ${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`
  } else {
    const period = hour < 12 || hour >= 24 ? 'AM' : 'PM'
    return `${monthFormatted}.${dayFormatted} (${dayOfWeek}) ${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')} ${period}`
  }
}

export function formatDateWithDuration(dateData, duration, isLocaleKO = true) {
  if (!dateData) return ''

  const { year, month, day, hour, minute } = dateData
  const startDate = new Date(year, month - 1, day, hour, minute)
  const endDate = new Date(startDate.getTime() + duration * 60000)

  const formatTime = (date) => {
    const hour = date.getHours()
    const minute = date.getMinutes()

    let period = ''

    if (isLocaleKO) {
      if (hour == 0 && minute == 0) {
        period = '자정'
      } else if (hour == 0 && minute == 30) {
        period = '새벽'
      } else if (hour > 0 && hour < 5) {
        period = '새벽'
      } else if (hour >= 5 && hour < 12) {
        period = '오전'
      } else if (hour == 12 && minute == 0) {
        period = '정오'
      } else if (hour == 12 && minute == 30) {
        period = '오후'
      } else if (hour >= 12 && hour < 18) {
        period = '오후'
      } else if (hour >= 18) {
        period = '저녁'
      }
    }

    if (isLocaleKO) {
      return `${period} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    } else {
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    }
  }

  const monthFormatted = startDate.getMonth() + 1
  const dayFormatted = startDate.getDate()
  const dayOfWeek = (
    isLocaleKO ? ['일', '월', '화', '수', '목', '금', '토'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  )[startDate.getDay()]
  return `${monthFormatted}.${dayFormatted} (${dayOfWeek}) ${formatTime(startDate)} - ${formatTime(endDate)}`
}

export function formatDateShort(dateData) {
  const { hour, minute } = dateData

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}

export function formatTimezoneDate(date, timezone, formatString = 'yyyy.MM.dd HH:mm:ss') {
  try {
    const zonedDate = utcToZonedTime(new Date(date), timezone)
    return format(zonedDate, formatString, { timeZone: timezone })
  } catch (err) {
    console.error('Error formatting date:', err)
    return ''
  }
}

export const timeDifference = (createdAt) => {
  const createdAtDate = new Date(createdAt)
  const plusOneHour = new Date(createdAtDate.getTime() + 60 * 60 * 1000).getTime()
  const currentTime = new Date().getTime()
  const differenceInMillis = plusOneHour - currentTime
  const totalSeconds = Math.floor(differenceInMillis / 1000)
  const minutesLeft = Math.floor(totalSeconds / 60)
  const secondsLeft = totalSeconds % 60

  return {
    minutes: minutesLeft,
    seconds: secondsLeft,
  }
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!keys2.includes(key)) return false
    if (!deepEqual(obj1[key], obj2[key])) return false
  }

  return true
}
