export const enum DSRADIO_BUTTON_TYPE {
  CHECKED = 'checked',
  CHECKED_DISABLED = 'checked_disabled',
  UNCHECKED = 'unchecked',
  UNCHECKED_DISABLED = 'unchecked_disabled',
}

export const enum DSRADIO_BUTTON_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

export type Selected = string | number | boolean
export type SelectedStateAction = React.Dispatch<React.SetStateAction<Selected>>
//

export interface DSRadioContextProps {
  selected: Selected
  setSelected: SelectedStateAction
  buttonPosition: DSRADIO_BUTTON_POSITION
  disabledAll: boolean
  activeColor: string
}
//

export interface DSRadioProps {
  controlSelected: [Selected, SelectedStateAction]
  buttonPosition?: DSRADIO_BUTTON_POSITION
  containerClassName?: string
  disabled?: boolean
  activeColor?: string
  children: React.ReactElement<DSRadioItemProps> | Array<React.ReactElement<DSRadioItemProps>>
}

//

export interface DSRadioItemButtonProps {
  type: DSRADIO_BUTTON_TYPE
  activeColor?: string
}

export interface DSRadioItemProps {
  value: Selected
  disabled?: boolean
  children?: React.ReactNode
  className?: string
  valueClassName?: string
}
