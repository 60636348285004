import { COOKIE_LIST } from '../../modules/vars'

export const MIDDLEWARE_ENV = process.env.ENVIRONMENT

export const MIDDLEWARE_DOMAIN = process.env.COOKIE_DOMAIN

export const MIDDLEWARE_COOKIE_DELETE_FLAG = 'delete'
export const MIDDLEWARE_COOKIE_DELETE_DATE = '1995-12-17T03:24:00'

export const TARGET_PARAMS_FOR_COOKIE = [
  COOKIE_LIST.JWT_TOKEN,
  COOKIE_LIST.SESSION_ROLE,
  COOKIE_LIST.PARENT_TOKEN,
  COOKIE_LIST.IS_ADMIN_LOGIN,
]

export const enum TEENS_PATHNAME {
  SIGN_IN = '/teens/auth/sign-in',
  DASHBOARD = '/teens/portal/dashboard',
  PORTAL_HOME = '/teens/portal/home',
}
