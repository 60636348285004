import { COUNTRY_ALPHA2_CODE } from '../vars'

export const PRICING_CURRENCY_OPTIONS = [
  {
    country_code: COUNTRY_ALPHA2_CODE.JP,
    name: '¥ JPY',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.TW,
    name: '$ TWD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: '$ USD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: '₩ KRW',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.VN,
    name: '₫ VND',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.CN,
    name: '¥ CNY',
  },
]

export const CURRENCY_LIST = [
  {
    country_code: COUNTRY_ALPHA2_CODE.JP,
    name: 'JPY',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.TW,
    name: 'TWD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: 'USD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: 'KRW',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.VN,
    name: 'VND',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.CN,
    name: 'CNY',
  },
]
