import { useEffect, useState } from 'react'
import { CheckBox as LegacyCheckBox } from '../../../../../apps/ringle-student/components/_legacy/CheckBox'
import { CheckBoxNew } from '../../../../../apps/ringle-student/components/_legacy/CheckBoxNew'
import { CheckBoxs as SecondLegacyCheckBox } from '../../../../../apps/ringle-student/components/_legacy/CheckBoxs'
import { Checkbox, DSRadio } from '../../index'
import CodeSnippet from '../CodeSnippet'
import {
  CHECKBOX_CHECKED,
  CHECKBOX_CHECKED_NAMES,
  CHECKBOX_POSITION,
  CHECKBOX_POSITION_NAMES,
  CHECKBOX_SHAPE,
  CHECKBOX_SHAPE_NAMES,
} from '../Components/Checkbox/types'
import { ControlClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../style'

const colContainerClassName = 'flex flex-col space-y-[8px]'
const rowContainerClassName = 'flex flex-row space-x-[24px]'
const gridContainerClassName = 'grid grid-cols-3 gap-[8px]'
const containerClassNameList = [colContainerClassName, rowContainerClassName, gridContainerClassName]

const SectionLegacy = () => {
  const [legacyChecked, setLegacyChecked] = useState(false)
  const [secondLegacyChecked, setSecondLegacyChecked] = useState(false)
  return (
    <section>
      <div className={headerClassName}>Legacy Checkbox</div>
      <div className={descriptionClassName}>
        Legacy Checkbox를 사용하고 있는 곳에서 Checkbox를 사용하도록 변경이 필요합니다.
        <br />
        Legacy Checkbox는 총 3개가 있습니다.
        <br />
        <br />
        student(약 4개 파일), teens(8개), tutor(12개), tutor-apply(1개) 각각에 모두 존재합니다.
      </div>
      <div className="my-[8px] max-w-max">
        <LegacyCheckBox
          disabled={false} //변경해야함
          checked={legacyChecked}
          onClick={(e) => setLegacyChecked((prev) => !prev)}
          label="Legacy Checkbox"
        />
      </div>
      <div className={descriptionClassName}>student(약 1개 파일), teens(쓰이진 않지만 존재) 각각에 존재합니다.</div>
      <div className="my-[8px] max-w-max">
        <SecondLegacyCheckBox
          disabled={false}
          checked={secondLegacyChecked}
          onClick={(e) => setSecondLegacyChecked((prev) => !prev)}
          label="Second Legacy Checkbox"
        />
      </div>
      <div className={descriptionClassName}>student(약 3개 파일)에 존재합니다.</div>
      <div className="my-[8px] max-w-max">
        <CheckBoxNew gap={12} mrCheckbox={8} checked={false} label={<div>Legacy Checkbox</div>} />
      </div>
    </section>
  )
}

interface SectopmExampleProps {
  shape: CHECKBOX_SHAPE
  type: CHECKBOX_CHECKED
  disabledAll: boolean
  buttonPosition: CHECKBOX_POSITION
  containerClassName: string
}

const SectionExample = ({ shape, type, disabledAll, buttonPosition, containerClassName }: SectopmExampleProps) => {
  return (
    <section>
      <div className={headerClassName}>Example Code</div>
      <CodeSnippet
        code={`
import { CHECKBOX_CHECKED, CHECKBOX_POSITION, CHECKBOX_SHAPE, Checkbox } from '@repo/design-system/index'
import { useState } from 'react'

const CheckboxComponent = () => {
  const [selectedList, setSelectedList] = useState([3, 4])

  return (
    <Checkbox${
      shape !== CHECKBOX_SHAPE.SQUARE
        ? `
      shape={${CHECKBOX_SHAPE_NAMES[shape]}}`
        : ''
    }${
          type !== CHECKBOX_CHECKED.UNCHECKED
            ? `
      type={${CHECKBOX_CHECKED_NAMES[type]}}`
            : ''
        }${
          disabledAll
            ? `
      disabledAll={${disabledAll}}`
            : ''
        }
      controlSelected={[selectedList, setSelectedList]}${
        buttonPosition !== CHECKBOX_POSITION.LEFT
          ? `
      buttonPosition={${CHECKBOX_POSITION_NAMES[buttonPosition]}}`
          : ''
      }
      containerClassName="${containerClassName}"
    >
      <Checkbox.Item value={1}>1</Checkbox.Item>
      <Checkbox.Item value={2}>2</Checkbox.Item>
      <Checkbox.Item value={3} disabled>
        3 (type & disabled)
      </Checkbox.Item>
      <Checkbox.Item value={4}>4 (type & enabled)</Checkbox.Item>
      <Checkbox.Item disabled value={5}>
        5 (untype & disabled)
      </Checkbox.Item>
      <Checkbox.Item value={6}>6 (untype & enabled)</Checkbox.Item>
      <Checkbox.Item value={7}>7</Checkbox.Item>
      <Checkbox.Item value={8}>8</Checkbox.Item>
    </Checkbox>
  )
}
`}
      />
    </section>
  )
}

const CheckboxComponent = () => {
  const [containerIndex, setContainerIndex] = useState<0 | 1 | 2>(0)
  const [buttonPosition, setButtonPosition] = useState<CHECKBOX_POSITION>(CHECKBOX_POSITION.LEFT)
  const [disabledAll, setDisabledAll] = useState(false)
  const [shape, setShape] = useState<CHECKBOX_SHAPE>(CHECKBOX_SHAPE.SQUARE)
  const [type, setType] = useState<CHECKBOX_CHECKED>(CHECKBOX_CHECKED.UNCHECKED)

  const [selectedList, setSelectedList] = useState([3, 4])

  useEffect(() => {
    if (buttonPosition === CHECKBOX_POSITION.RIGHT) {
      setContainerIndex(0)
    }
  }, [containerIndex, buttonPosition])

  return (
    <div className="flex flex-col gap-[8px]">
      <SectionLegacy />

      <div>
        <div className={headerClassName}>Checkbox</div>

        <section className="flex flex-col border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
          <div className={subHeaderClassName}>Controls</div>

          <div className="flex flex-row">
            <div className={ControlClassName}>container 속성</div>
            <DSRadio controlSelected={[containerIndex, setContainerIndex]} containerClassName={rowContainerClassName}>
              <DSRadio.Item value={0}>Column</DSRadio.Item>
              <DSRadio.Item disabled={buttonPosition === CHECKBOX_POSITION.RIGHT} value={1}>
                Row
              </DSRadio.Item>
              <DSRadio.Item disabled={buttonPosition === CHECKBOX_POSITION.RIGHT} value={2}>
                Grid (3 Columns)
              </DSRadio.Item>
            </DSRadio>
          </div>

          <div className="flex flex-row">
            <div className={ControlClassName}>button position</div>
            <DSRadio controlSelected={[buttonPosition, setButtonPosition]} containerClassName={rowContainerClassName}>
              <DSRadio.Item value={CHECKBOX_POSITION.LEFT}>Left</DSRadio.Item>
              <DSRadio.Item value={CHECKBOX_POSITION.RIGHT}>Right</DSRadio.Item>
            </DSRadio>
          </div>

          <div className="flex flex-row">
            <div className={ControlClassName}>disabled</div>
            <DSRadio controlSelected={[disabledAll, setDisabledAll]} containerClassName={rowContainerClassName}>
              <DSRadio.Item value={false}>All Enabled</DSRadio.Item>
              <DSRadio.Item value={true}>All Disabled</DSRadio.Item>
            </DSRadio>
          </div>

          <div className="flex flex-row">
            <div className={ControlClassName}>checkbox shape</div>
            <DSRadio controlSelected={[shape, setShape]} containerClassName={rowContainerClassName}>
              <DSRadio.Item value={CHECKBOX_SHAPE.SQUARE}>Square</DSRadio.Item>
              <DSRadio.Item value={CHECKBOX_SHAPE.CIRCLE}>Circle</DSRadio.Item>
            </DSRadio>
          </div>

          <div className="flex flex-row">
            <div className={ControlClassName}>checkbox</div>
            <DSRadio controlSelected={[type, setType]} containerClassName={rowContainerClassName}>
              <DSRadio.Item value={CHECKBOX_CHECKED.UNCHECKED}>Unchecked</DSRadio.Item>
              <DSRadio.Item value={CHECKBOX_CHECKED.CHECKED}>Checked</DSRadio.Item>
            </DSRadio>
          </div>
        </section>
      </div>

      <section className="mt-[12px]">
        <Checkbox
          shape={shape}
          type={type}
          disabledAll={disabledAll}
          controlSelected={[selectedList, setSelectedList]}
          buttonPosition={buttonPosition}
          containerClassName={containerClassNameList[containerIndex]}
        >
          <Checkbox.Item value={1}>1</Checkbox.Item>
          <Checkbox.Item value={2}>2</Checkbox.Item>
          <Checkbox.Item value={3} disabled>
            3 (type & disabled)
          </Checkbox.Item>
          <Checkbox.Item value={4}>4 (type & enabled)</Checkbox.Item>
          <Checkbox.Item disabled value={5}>
            5 (untype & disabled)
          </Checkbox.Item>
          <Checkbox.Item value={6}>6 (untype & enabled)</Checkbox.Item>
          <Checkbox.Item value={7}>7</Checkbox.Item>
          <Checkbox.Item value={8}>8</Checkbox.Item>
        </Checkbox>

        <p className="border-[1.5] border-1 rounded-[8px] bg-gray-50 py-[8px] px-[12px] max-w-max mt-[28px] font-bold">
          You selected <span className="text-purple-500">{selectedList.join(', ')}</span>
        </p>
      </section>

      <SectionExample
        shape={shape}
        type={type}
        disabledAll={disabledAll}
        buttonPosition={buttonPosition}
        containerClassName={containerClassNameList[containerIndex]}
      />
    </div>
  )
}

export default CheckboxComponent
