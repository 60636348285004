import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { DSInput } from '../..'
import RadioGroup2 from '../../../../../apps/ringle-teens/components/_legacy/RadioGroup2'
import CodeSnippet from '../CodeSnippet'
import DSRadio from '../Components/Radio/DSRadio'
import { DSRADIO_BUTTON_POSITION } from '../Components/Radio/dsradio.interface'
import { ControlClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../style'

const colContainerClassName = 'flex flex-col space-y-[8px]'
const rowContainerClassName = 'flex flex-row space-x-[24px]'
const gridContainerClassName = 'grid grid-cols-3 gap-[8px]'
const containerClassNameList = [colContainerClassName, rowContainerClassName, gridContainerClassName]

const SectionLegacy = () => {
  const [legacyRadioChecked, setLegacyRadioChecked] = useState(false)

  const lessonStyleSettings = []
  lessonStyleSettings.push({
    name: 'abcd',
    id: 'efg',
    value: 'hij',
  })

  return (
    <section>
      <div className={headerClassName}>Legacy Radio Button</div>
      <div className={descriptionClassName}>
        Legacy Radio Button을 사용하고 있는 곳에서 DSRadio을 사용하도록 변경이 필요합니다.
        <br />
        teens(약 8개 파일), tutor(11개), tutor-apply(4개) 각각에 모두 존재합니다.
      </div>
      <RadioGroup2
        clx="flex items-start"
        readOnly={false}
        settings={lessonStyleSettings}
        selected={legacyRadioChecked}
        setSelected={setLegacyRadioChecked}
      />
    </section>
  )
}

interface SectionExmapleProps {
  disabledAll: boolean
  buttonPosition: DSRADIO_BUTTON_POSITION
  containerClassName: string
  activeColor: string
}

const SectionExample = ({ disabledAll, buttonPosition, containerClassName, activeColor }: SectionExmapleProps) => {
  return (
    <div>
      <CodeSnippet
        code={`
import { COLORS, DSRADIO_BUTTON_POSITION, DSRadio } from '@repo/design-system/index'
import { useState } from 'react'

const RadioComponent = () => {
  const [selected, setSelected] = useState<number>(1)

  return (
    <DSRadio${
      disabledAll
        ? `
      disabled`
        : ''
    }
      controlSelected={[selected, setSelected]}${
        buttonPosition === DSRADIO_BUTTON_POSITION.RIGHT
          ? `
      buttonPosition={DSRADIO_BUTTON_POSITION.RIGHT}`
          : ''
      }
      containerClassName="${containerClassName}"${
          activeColor
            ? `
      activeColor={COLORS.BLUE500}`
            : ''
        }
    >
      <DSRadio.Item value={1}>1</DSRadio.Item>
      <DSRadio.Item value={2}>2</DSRadio.Item>
      <DSRadio.Item value={3}>3</DSRadio.Item>
      <DSRadio.Item value={4}>4</DSRadio.Item>
      <DSRadio.Item disabled value={5}>
        5 (Disabled)
      </DSRadio.Item>
      <DSRadio.Item value={6}>6</DSRadio.Item>
      <DSRadio.Item value={7}>7</DSRadio.Item>
    </DSRadio>
  )
}
          
      `}
      />
    </div>
  )
}

const Radio = () => {
  const [containerIndex, setContainerIndex] = useState<0 | 1 | 2>(0)
  const [buttonPosition, setButtonPosition] = useState<DSRADIO_BUTTON_POSITION>(DSRADIO_BUTTON_POSITION.LEFT)
  const [disabledAll, setDisabledAll] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(1)
  const [activeColor, setActiveColor] = useState<string>('')

  useEffect(() => {
    if (buttonPosition === DSRADIO_BUTTON_POSITION.RIGHT) {
      setContainerIndex(0)
    }
  }, [containerIndex, buttonPosition])

  return (
    <div className="flex flex-col gap-[8px]">
      <SectionLegacy />

      <div className={headerClassName}>Radio Button</div>
      <section className="flex flex-col gap-[0px] border-[1.5px] rounded-[8px] p-[20px] bg-gray-50 max-w-max">
        <p className={subHeaderClassName}>Controls</p>
        <div className="flex flex-row">
          <div className={ControlClassName}>container 속성</div>
          <DSRadio controlSelected={[containerIndex, setContainerIndex]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value={0}>Column</DSRadio.Item>
            <DSRadio.Item disabled={buttonPosition === DSRADIO_BUTTON_POSITION.RIGHT} value={1}>
              Row
            </DSRadio.Item>
            <DSRadio.Item disabled={buttonPosition === DSRADIO_BUTTON_POSITION.RIGHT} value={2}>
              Grid (3 Columns)
            </DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>button position</div>
          <DSRadio controlSelected={[buttonPosition, setButtonPosition]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value={DSRADIO_BUTTON_POSITION.LEFT}>Left Radio Button</DSRadio.Item>
            <DSRadio.Item value={DSRADIO_BUTTON_POSITION.RIGHT}>Right Radio Button</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row">
          <div className={ControlClassName}>disabled</div>
          <DSRadio controlSelected={[disabledAll, setDisabledAll]} containerClassName={rowContainerClassName}>
            <DSRadio.Item value={false}>All Enabled</DSRadio.Item>
            <DSRadio.Item value={true}>All Disabled</DSRadio.Item>
          </DSRadio>
        </div>

        <div className="flex flex-row items-center">
          <div className={classNames(ControlClassName, disabledAll && 'text-gray-300')}>active color</div>
          <DSInput
            type="text"
            placeholder="Enter color (e.g., #157B56)"
            value={activeColor}
            setValue={setActiveColor}
            name={'button border color'}
            clearable
            size="sm"
            required={false}
            className="bg-gray-50"
            disabled={disabledAll}
          />
        </div>
      </section>

      <section className="mt-[12px]">
        <DSRadio
          disabled={disabledAll}
          controlSelected={[selected, setSelected]}
          buttonPosition={buttonPosition}
          containerClassName={containerClassNameList[containerIndex]}
          activeColor={activeColor}
        >
          <DSRadio.Item value={1}>1</DSRadio.Item>
          <DSRadio.Item value={2}>2</DSRadio.Item>
          <DSRadio.Item value={3}>3</DSRadio.Item>
          <DSRadio.Item value={4}>4</DSRadio.Item>
          <DSRadio.Item disabled value={5}>
            5 (Disabled)
          </DSRadio.Item>
          <DSRadio.Item value={6}>6</DSRadio.Item>
          <DSRadio.Item value={7}>7</DSRadio.Item>
        </DSRadio>

        <p className="border-[1.5] border-1 rounded-[8px] bg-gray-50 py-[8px] px-[12px] max-w-max mt-[28px] font-bold">
          You selected <span className="text-purple-500">{selected}</span>
        </p>
      </section>

      <SectionExample
        disabledAll={disabledAll}
        buttonPosition={buttonPosition}
        containerClassName={containerClassNameList[containerIndex]}
        activeColor={activeColor}
      />
    </div>
  )
}

export default Radio
