import { PropsWithChildren } from 'react'
import { useButtonState } from '../modules/BuilderButtonStateContext'
import ButtonLoading from './BuilderButtonLoading'

export interface ButtonIconProps extends PropsWithChildren {
  className?: string
  alt: string
}

const BuilderButtonIcon = ({ children, className, alt }: ButtonIconProps) => {
  const { isLoading } = useButtonState()

  if (isLoading) {
    return <ButtonLoading />
  }

  if (typeof children === 'string') {
    return <img src={children} alt={alt} className={className} />
  }
  return <>{children}</>
}

export default BuilderButtonIcon
