import { COLORS } from '../../modules/vars'

export interface TabProps {
  children: React.ReactElement<TabItemProps> | Array<React.ReactElement<TabItemProps>>
  type: TAB_TYPE[keyof TAB_TYPE]
  customColor?: (typeof COLORS)[keyof typeof COLORS]
  className?: string
  activeTabIdx?: number
  borderNone?: boolean
  setActiveTabIdx?: any
  wrapperClassName?: string
}

export interface TabHeaderItemProps {
  tabName: string
  activeTabIdx?: number
  href?: string
  tabIndex: number
  containerRef: React.RefObject<HTMLDivElement>
  handleWheel: (event: WheelEvent) => void
  setActiveTabIdx?: any
  onClickTab?: any
}

export interface TabHeaderProps {
  tabNameList: string[]
  onClickList?: any
  className?: string
  borderNone?: boolean
  setActiveTabIdx?: any
  activeTabIdx?: number
  wrapperClassName?: string
}

export interface TabItemProps {
  name: string | React.ReactElement
  children?: React.ReactNode
  onClick?: any
  className?: string
}

export const enum TAB_TYPE {
  SCROLL = 'scrollable',
  FIXED = 'fixed',
}
