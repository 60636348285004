import { AxiosError } from 'axios'
import Cookies from 'universal-cookie'
import { COOKIE_LIST } from '../vars'

export const createAxiosTokenConfig = (token: string, data?) => {
  const cookies = new Cookies()
  const cafToken = cookies.get(COOKIE_LIST.CAF_TOKEN)
  if (data) {
    if (token || cafToken) {
      return {
        headers: {
          Authorization: `Bearer ${token || cafToken}`,
        },
        data,
      }
    } else {
      return { data }
    }
  } else {
    if (token || cafToken) {
      return {
        headers: {
          Authorization: `Bearer ${token || cafToken}`,
        },
      }
    } else {
      return {}
    }
  }
}

export const handleAxiosError = (e: AxiosError<any>, customErrMsg = '') => {
  console.error(e)
  return
}
