import { DSRADIO_BUTTON_TYPE } from './dsradio.interface'

export const getButtonType = (checked: boolean, disabled: boolean) => {
  switch (true) {
    case checked && !disabled:
      return DSRADIO_BUTTON_TYPE.CHECKED
    case checked && disabled:
      return DSRADIO_BUTTON_TYPE.CHECKED_DISABLED
    case !checked && !disabled:
      return DSRADIO_BUTTON_TYPE.UNCHECKED
    case !checked && disabled:
      return DSRADIO_BUTTON_TYPE.UNCHECKED_DISABLED
  }
}

export const getBorderColorClassName = (headerType: DSRADIO_BUTTON_TYPE) => {
  switch (headerType) {
    case DSRADIO_BUTTON_TYPE.CHECKED:
    case DSRADIO_BUTTON_TYPE.UNCHECKED:
      return 'border-gray-300'
    case DSRADIO_BUTTON_TYPE.CHECKED_DISABLED:
    case DSRADIO_BUTTON_TYPE.UNCHECKED_DISABLED:
      return 'border-gray-200'
  }
}

export const getInnerCircleColorClassName = (headerType: DSRADIO_BUTTON_TYPE) => {
  switch (headerType) {
    case DSRADIO_BUTTON_TYPE.CHECKED:
      return 'bg-purple-500'
    case DSRADIO_BUTTON_TYPE.CHECKED_DISABLED:
      return 'bg-gray-200'
    case DSRADIO_BUTTON_TYPE.UNCHECKED:
    case DSRADIO_BUTTON_TYPE.UNCHECKED_DISABLED:
    default:
      return ''
  }
}
