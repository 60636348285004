export const enum CHECKBOX_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const CHECKBOX_POSITION_NAMES = {
  left: 'CHECKBOX_POSITION.LEFT',
  right: 'CHECKBOX_POSITION.RIGHT',
}

export const enum CHECKBOX_SHAPE {
  SQUARE = 'square',
  CIRCLE = 'circle',
}

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const CHECKBOX_SHAPE_NAMES = {
  square: 'CHECKBOX_SHAPE.SQUARE',
  circle: 'CHECKBOX_SHAPE.CIRCLE',
}

export const enum CHECKBOX_CHECKED {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

// For CodeSnippet: enum의 이름을 문자열로 매핑하는 객체
export const CHECKBOX_CHECKED_NAMES = {
  checked: 'CHECKBOX_CHECKED.CHECKED',
  unchecked: 'CHECKBOX_CHECKED.UNCHECKED',
}

export type Selected = string | number | boolean
export type SelectedStateAction = React.Dispatch<React.SetStateAction<Selected[]>>
