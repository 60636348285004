import { LocaleType } from 'aws-sdk/clients/workdocs'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import { CurrentUser } from '../model/Common/common.interface'
import { useCurrentUser } from '../modules/context/UserContext'
import { IS_DEVELOPMENT } from '../modules/envVars'
import useTranslation from '../modules/i18n/useTranslation'
import { LandingState, STUDENT_PAGE_VIEW_ID } from '../modules/ids/gtm/student'
import { TUTOR_INPUT_ID } from '../modules/ids/gtm/tutor'
import { firstUser, getTutorStatusString } from '../modules/utils'

/** ----------------------- */
/** Coupon Helper Functions
/** ----------------------- */

interface IGtmUserIdDataLayer extends Record<string, string | number> {
  user_id: number
}

interface IGtmTutorStatusDataLayer extends Record<string, string | number> {
  tutor_status: string
}

export enum GTM_EVENT {
  /**
   * GOOGLE ADS & FACEBOOK PIXEL - STANDARD_EVENTS
   */
  COMPLETE_REGISTRATION = 'complete_registration', // Sign Up
  PURCHASE = 'purchase', // PV student/portal/pricing/success/[purchaseId]
  CONTACT = 'contact',
  ADD_TO_WITHLIST = 'add_to_wishlist', // PV student/portal/pricing
  ADD_PAYMENT_INFO = 'add_payment_info', // click purchase button after  PV student/portal/pricing
  INITIATE_CHECKOUT = 'initiate_checkout', // PV student/portal/pricing/[productId]
  START_TRIAL = 'start_trial',
  LEAD = 'lead',
  SCHEDULE = 'schedule',
  VIEW_CONTENT = 'view_content',
  SUBMIT_APPLICATOIN = 'submit_application',
  WEBINAR_ENROLL = 'webinar_enroll',

  /**
   * CUSTOM EVENTS
   */
  SIGN_IN = 'sign_in',
  SET_USER_ID = 'set_user_id',
  SET_USER_INFO = 'set_user_info',
  SET_IS_RINGLE_MEMBER = 'set_is_ringle_member',
  SET_TUTOR_STATUS = 'set_tutor_status',
  SET_HAS_CREDIT = 'set_has_credit',
  INPUT = 'input',
  IMPRESSION = 'impression',
  USERTYPE_DIMENSION = 'usertype_dimension',

  // Onsite GNB, Popup, Multimedia Banner Events
  VIEW_ONSITE_GNB = 'view_onsite_gnb',
  VIEW_ONSITE_POPUP = 'view_onsite_popup',
  VIEW_ONSITE_MULTI = 'view_onsite_multi',
  CLICK_ONSITE_GNB = 'click_onsite_gnb',
  CLICK_ONSITE_POPUP = 'click_onsite_popup',
  CLICK_ONSITE_MULTI = 'click_onsite_multi',
}

export enum GTM_CLICK_EVENT {
  /**
   * General Click Button Events
   */
  CLICK_BUTTON = 'click_button', // FIXME OLD CLICK BTN EVENT - LEGACY
  CLICK_BUTTON_NEW = 'click_button_new', // NEW CLICK BTN EVENT

  /**
   * Custom Click Button Events
   */
  CLICK_PRODUCT = 'click_product', // product_id
  CLICK_START_RINGLE = 'click_start_ringle',
  CLICK_INVITE = 'click_invite',
  CLICK_SHARE = 'click_share',
  CLICK_PURCHASE_CONFERENCE = 'click_purchase_conference',
  CLICK_BOOK_LESSON = 'click_book_lesson',
  CLICK_SELECT_CREDIT = 'click_select_credit',
  CLICK_SELECT_TUTOR = 'click_select_tutor',
  CLICK_SELECT_TUTOR_AVATAR = 'click_select_tutor_avatar',
  CLICK_NEXT_LESSON_SCHEDULE = 'click_next_lesson_schedule',
  CLICK_MATERIAL_CATEGORY = 'click_material_category',
  CLICK_SEE_MATERIAL_DETAIL = 'click_see_material_detail',
  CLICK_SELECT_MATERIAL = 'click_select_material',
  CLICK_BOOKMARK_MATERIAL = 'click_bookmark_material',
  CLICK_SEARCH_MATERIAL = 'click_search_material',
  CLICK_EXIT_BOOK_LESSON = 'click_exit_book_lesson',
  CLICK_CONTENT_AD_SIGN_UP = 'click_content_ad_sign_up',
  CLICK_MATERIAL_AD_APP_INSTALL = 'click_material_ad_app_install',
  CLICK_LANDING_BUTTON = 'click_landing_button',

  /**
   * Custom Teens Click Button Events
   */
  CLICK_INSTALL = 'click_install',
  CLICK_SIGN_UP = 'click_sign_up',
  CLICK_BOOK_A_CALL = 'click_book_a_call',

  //Home개편 Data Tracking
  CLICK_PORTAL_HOME_TUTOR_CARD = 'click_portal_home_tutor_card',
  CLICK_RINGLE_PRICK_TUTOR_CARD = 'click_ringle_pick_tutor_card',
  CLICK_RINGLE_PICK = 'click_ringle_pick',
  CLICK_CAROUSAL_TIPS = 'click_carousal_tips',
  CLICK_RINGLE_PICK_MATERIAL = 'click_ringle_pick_material',
  CLICK_RINGLE_PICK_WEBINAR = 'click_ringle_pick_webinar',
  CLICK_RINGLE_PICK_BLOG = 'click_ringle_pick_blog',
  CLICK_BOOKMARK = 'click_bookmark',
  CLICK_SECTION_BOOK_LESSON = 'click_section_book_lesson',
  CLICK_BOTTOM_BOOK_LESSON = 'click_bottom_book_lesson',
  CLICK_TUTOR_CARD = 'click_tutor_card',
  CLICK_RECOM_TUTOR_ALL = 'click_recom_tutor_all',
  CLICK_PHR_BANNER_RECOM_TUTOR = 'click_banner_recom_tutor',

  // Portal Tab
  CLICK_CLASSROOM_TAB = 'click_classroom_tab',

  //TutorProfile
  CLICK_TUTOR_PROFILE_BOOK_LESSON = 'click_tutor_profile_book_lesson',
  CLICK_TUTOR_PROFILE_BOOKMARK_TUTOR = 'click_tutor_profile_bookmark_tutor',
  CLICK_TUTOR_PROFILE_TAB = 'click_tutor_profile_tab',
}

export enum GTM_PV_EVENT {
  /**
   * Custom Page View Events
   */
  PV_BASIC = 'page_view_basic',
  PV_LANDING_MATERIAL_AD = 'view_landing_material_ad',

  WEB_VITAL_CHECK_FCP = 'web_vital_check_fcp',
  WEB_VITAL_CHECK_LCP = 'web_vital_check_lcp',
  WEB_VITAL_CHECK_TTI = 'web_vital_check_tti',

  //튜터 프로필 모달
  VIEW_TUTOR_PROFILE_MODAL = 'view_tutor_profile_modal',
  VIEW_CMS_EVENT_LANDING = 'view_cms_event_landing',
}

export enum LOCATION_TYPE {
  LANDING = 'landing',
  PORTAL = 'portal',
}

export enum TAG_MANAGER_ID_TYPE {
  PLUS = 'PLUS',
  TUTOR = 'TUTOR',
  TEENS = 'TEENS',
  QA = 'QA',
  WEBVIEW = 'WEBVIEW',
}

export enum LOCALE_USER_TYPE {
  STUDENT = 'student',
  TUTOR = 'tutor',
  TEENS = 'teens',
  WEBVIEW = 'webview',
}
export enum PLATFORM_TYPE {
  WEB = 'web',
  MOBILE_WEB = 'mobile_web',
  APP = 'app', //for webview pages
}

export const extractUserTypeFromPath = (path: string): LOCALE_USER_TYPE => {
  const splitUrlPath = path.split('/')

  // Return Student as default if splitted URL path has length below 2
  if (splitUrlPath.length < 3) {
    return LOCALE_USER_TYPE.STUDENT
  }

  // User Type is at the second index of the url path
  const userType = splitUrlPath[2] as LOCALE_USER_TYPE

  if (Object.values(LOCALE_USER_TYPE).includes(userType)) {
    return userType
  } else {
    return LOCALE_USER_TYPE.STUDENT
  }
}

export const mapUserTypeToGtmId = (userType: LOCALE_USER_TYPE): TAG_MANAGER_ID_TYPE => {
  switch (userType) {
    case LOCALE_USER_TYPE.STUDENT:
      return TAG_MANAGER_ID_TYPE.PLUS
    case LOCALE_USER_TYPE.TUTOR:
      return TAG_MANAGER_ID_TYPE.TUTOR
    case LOCALE_USER_TYPE.TEENS:
      return TAG_MANAGER_ID_TYPE.TEENS
    case LOCALE_USER_TYPE.WEBVIEW:
      return TAG_MANAGER_ID_TYPE.WEBVIEW
  }
}

export const TAG_MANAGER_IDS = {
  [TAG_MANAGER_ID_TYPE.PLUS]: 'GTM-N2DBP3J',
  [TAG_MANAGER_ID_TYPE.TUTOR]: 'GTM-PJL28WR',
  [TAG_MANAGER_ID_TYPE.TEENS]: 'GTM-5Q5PWWK',
  [TAG_MANAGER_ID_TYPE.QA]: 'GTM-5TKJMLT',
  // [TAG_MANAGER_ID_TYPE.WEBVIEW]: 'GTM-N2MDGWD',
  // 웹뷰 GTM 계정을 사용하지 않게되어 플러스로 통합
  [TAG_MANAGER_ID_TYPE.WEBVIEW]: 'GTM-N2DBP3J',
}

/** ------------------------------------ */
/** GTM INTIALIZATION / REMOVAL HELPERS  */
/** ------------------------------------ */

const getAllLoadedGTMScripts = (): HTMLScriptElement[] =>
  [].filter.call(document.getElementsByTagName('head')[0].children, (el: HTMLElement & HTMLScriptElement) =>
    el.src?.includes('googletagmanager')
  )

const getDataLayerListByKey = (key: string) =>
  (window.dataLayer || []).filter((dl: Record<string, string | number>) => dl[key] !== undefined)

export const isGTMLoaded = (): boolean => {
  if (typeof document !== 'undefined') {
    return getAllLoadedGTMScripts().length > 0
  } else {
    return false
  }
}

export const removeGTMScript = (): void => {
  const gtmTagScripts = getAllLoadedGTMScripts()

  for (let i = 0; i < gtmTagScripts.length; i++) {
    gtmTagScripts[i].parentNode.removeChild(gtmTagScripts[i])
  }

  // // Retrieve list of HTML Script Element within Head Tag
  // const headScriptElList: HTMLScriptElement[] = [].filter.call(
  //   document.getElementsByTagName('head')[0].children,
  //   (el: HTMLElement & HTMLScriptElement) => el.src
  // )

  // // Delete all GTM, Optimize Script Tags
  // headScriptElList.forEach((el: HTMLScriptElement) => {
  //   if (el.src.includes('googleoptimize') || el.src.includes('googletagmanager')) {
  //     el.parentNode.removeChild(el)
  //   }
  // })
}

export const initGTMScript = (userType: LOCALE_USER_TYPE): void => {
  const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : mapUserTypeToGtmId(userType)

  if (typeof window === 'undefined') return
  console.log(isGTMLoaded(), 'isGTMLoaded()')
  if (!isGTMLoaded()) {
    console.log(`Initializing Google Tag Manager - ${tagType}`)
    removeGTMScript()
    TagManager.initialize({
      gtmId: TAG_MANAGER_IDS[tagType],
    })
  } else {
    console.log(`Google Tag Manager - ALREADY INIT - ${tagType}`)
  }
}

export const setGTMUserId = (
  userType: LOCALE_USER_TYPE,
  userId: number,
  userEmail: string,
  isRingleMember: boolean,
  isAdminLogin: boolean
): void => {
  if (typeof window === 'undefined') return

  // Prod Mode + (Ringle Member OR Admin Login) => RETURN & Remove Script
  if (!IS_DEVELOPMENT && isAdminLogin) {
    if (isGTMLoaded()) {
      removeGTMScript()
    }
  } else {
    // If GTM is not loaded, initialize with user id
    // -> If user with token refreshed within portal page
    if (!isGTMLoaded()) {
      removeGTMScript()

      const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : mapUserTypeToGtmId(userType)

      console.log(`Initializing Google Tag Manager - ${userType} - SET GTM USER ID`)
      TagManager.initialize({
        gtmId: TAG_MANAGER_IDS[tagType],
        dataLayer: {
          user_id: userId,
          user_email: userEmail,
        },
      })
    } else {
      // If GTM is already loaded, push user ID to dataLayer
      const userIdDataLayerList: IGtmUserIdDataLayer[] = getDataLayerListByKey('user_id')
      const hasCurrentUserIdLayer = userIdDataLayerList.map((dl) => dl.user_id).includes(userId)

      // If dataLayer already contains user id, return the function
      if (userIdDataLayerList.length > 0 && hasCurrentUserIdLayer) {
        return
      }
    }

    // If dataLayer does not contain layer with user id, push user id data layer
    sendGTMEvent(GTM_EVENT.SET_USER_ID, {
      user_id: userId,
      user_email: userEmail,
    })
  }
}

export const setGTMTutorStatus = (tutorStatusId: number) => {
  const currentTutorStatus = getTutorStatusString(tutorStatusId)

  // If tutor status is not defined, return
  if (currentTutorStatus === 'NOT_DEFINED') return

  const tutorStatusIdDataLayerList: IGtmTutorStatusDataLayer[] = getDataLayerListByKey('tutor_status')

  if (tutorStatusIdDataLayerList.length > 0) {
    // Retrieve the most recent data layer that has tutor status
    const mostRecentDataLayer = tutorStatusIdDataLayerList[tutorStatusIdDataLayerList.length - 1]

    // If the most recent tutor status is different from current tutor status, send GTM event
    if (mostRecentDataLayer.tutor_status !== currentTutorStatus) {
      console.log(`- SET_TUTOR_STATUS: ${currentTutorStatus}`)
      sendGTMEvent(GTM_EVENT.SET_TUTOR_STATUS, {
        tutor_status: currentTutorStatus,
      })
    }
  } else {
    console.log(`- SET_TUTOR_STATUS: ${currentTutorStatus}`)
    sendGTMEvent(GTM_EVENT.SET_TUTOR_STATUS, {
      tutor_status: currentTutorStatus,
    })
  }
}

export const setGTMPlusCreditStatus = (hasCredit: boolean) => {
  const hasCreditDataLayerList: IGtmTutorStatusDataLayer[] = getDataLayerListByKey('has_credit')

  if (hasCreditDataLayerList.length > 0) {
    // Retrieve the most recent data layer that has 'has_credit'
    const mostRecentDataLayer = hasCreditDataLayerList[hasCreditDataLayerList.length - 1]

    // If the most credit status is different from the new credit status, send GTM event
    if (Boolean(mostRecentDataLayer.has_credit) !== hasCredit) {
      console.log(`- SET_HAS_CREDIT: ${hasCredit}`)
      sendGTMEvent(GTM_EVENT.SET_HAS_CREDIT, {
        has_credit: hasCredit,
      })
    }
  } else {
    console.log(`- SET_HAS_CREDIT: ${hasCredit}`)
    sendGTMEvent(GTM_EVENT.SET_HAS_CREDIT, {
      has_credit: hasCredit,
    })
  }
}

export const setGTMPlusUserInfo = (
  email: string,
  mobileNumber: string,
  enteredLessonCnt = 0,
  finishedLessonCnt = 0,
  isRingleMember: boolean
) => {
  console.log(`- SET_USER_INFO -`)
  console.log(`${email}, ${mobileNumber}`)
  console.log(`ENTERED LESSN CNT: ${enteredLessonCnt}`)
  console.log(`FINISHED LESSN CNT: ${finishedLessonCnt}`)

  sendGTMEvent(GTM_EVENT.SET_USER_INFO, {
    user_email: email,
    user_mobile_number: mobileNumber ? mobileNumber.replace('+', '') : '',
    user_entered_lesson_cnt: enteredLessonCnt,
    user_finished_lesson_cnt: finishedLessonCnt,
  })

  // If it's Ringle Member, set is_ringle_member property
  sendGTMEvent(GTM_EVENT.SET_IS_RINGLE_MEMBER, {
    is_ringle_member: isRingleMember,
  })
}

/* * ---------------- */
/* *  COMMON HELPERS  */
/* * ---------------- */

export const getCleanUrl = (pathname: string): string => pathname.replace('/[lang]/', '/')

/**
 * Send GTM Event Function
 * @param eventName GTM Event Name
 * @param eventPayload GTM Event Payload
 */

export const sendGTMEvent = async (eventName: string, eventPayload?: Record<string, any>): Promise<void> => {
  console.log(`SEND GTM EVENT: ${eventName}`, eventPayload)
  try {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventPayload,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

/**
 * Send Click Button Event to GTM w/ id
 * @param btnId clicked button id
 * @param pageLocation page location without query params(mandatory)
 * @param otherPayload page location without query params(optional)
 */

export const sendGTMBasicClickEvent = (btnId: string, otherPayload?: Record<string, any>): void => {
  console.log(`SEND GTM BASIC CLICK-EVENT: ${btnId}`, otherPayload)
  TagManager.dataLayer({
    dataLayer: {
      event: GTM_CLICK_EVENT.CLICK_BUTTON,
      button_id: btnId,
      ...otherPayload,
    },
  })
}

// export const useBasicPVEventWithInfo = (eventName, url) => {
//   const currentUser;
//   const locale;
//   return {
//     sendEvent: () => sendGTMBasicPageViewEvent()
//   }
// }

export const sendGTMCustomClickEvent = (
  event_id: GTM_CLICK_EVENT,
  button_id: string,
  btn_name: string,
  usertype: string,
  page_title: string,
  routerPath: string,
  otherPayload?: Record<string, any>
): void => {
  console.log('Sending GTM Custom Click Event...', {
    event_id,
    button_id,
    btn_name,
    usertype,
    page_title,
    routerPath,
    ...otherPayload,
  })

  const landingState: LandingState = routerPath.includes('/portal') ? LandingState.PORTAL : LandingState.LANDING

  TagManager.dataLayer({
    dataLayer: {
      event: event_id,
      button_id,
      btn_name,
      usertype,
      page_title: page_title ?? routerPath,
      landing_state: landingState,
      // Reset Payload Variable
      btn_area: undefined,
      scroll_y_percentage: undefined,
      category: undefined,
      ...otherPayload,
    },
  })
}

export const sendGTMBasicPageViewEvent = (
  page_id: string,
  usertype: string,
  locale: LocaleType,
  page_title: string,
  routerPath: string,
  otherPayload?: Record<string, any>
): void => {
  const type = extractUserTypeFromPath(routerPath)
  const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : mapUserTypeToGtmId(type)

  if (!isGTMLoaded()) {
    console.log(`Initializing Google Tag Manager - SEND BASIC PV`)
    TagManager.initialize({
      gtmId: TAG_MANAGER_IDS[tagType],
    })
  }

  console.log('Sending GTM Basic PV Event...', {
    event: GTM_PV_EVENT.PV_BASIC,
    page_id,
    usertype,
    locale,
    page_title,
    ...otherPayload,
  })

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_PV_EVENT.PV_BASIC,
      page_id,
      usertype,
      locale,
      page_title,
      ...otherPayload,
    },
  })
}

interface IGtmBasicViewProperties {
  page_id: STUDENT_PAGE_VIEW_ID
  page_title: string
  [key: string]: any
}
export const useGTMBasicPageViewEventWithProperties = ({ page_id, page_title, ...others }: IGtmBasicViewProperties) => {
  const routerPath = useRouter().asPath
  const { currentUser } = useCurrentUser()
  const usertype = getEventUserType(currentUser)
  const { locale } = useTranslation()
  const type = extractUserTypeFromPath(routerPath)
  const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : mapUserTypeToGtmId(type)

  return () => {
    if (!isGTMLoaded()) {
      console.log(`Initializing Google Tag Manager - SEND BASIC PV`)
      TagManager.initialize({
        gtmId: TAG_MANAGER_IDS[tagType],
      })
    }
    console.log('sendGTMPVEvent', GTM_PV_EVENT.PV_BASIC)
    console.log(page_id, page_title)
    TagManager.dataLayer({
      dataLayer: {
        event: GTM_PV_EVENT.PV_BASIC,
        page_id,
        usertype,
        locale,
        page_title,
        ...others,
      },
    })
  }
}

export const sendGTMCustomPageViewEvent = (
  event: GTM_PV_EVENT,
  page_id: string,
  routerPath: string,
  payload: Record<string, any>
): void => {
  const type = extractUserTypeFromPath(routerPath)
  const tagType = IS_DEVELOPMENT ? TAG_MANAGER_ID_TYPE.QA : mapUserTypeToGtmId(type)

  if (!isGTMLoaded()) {
    console.log(`Initializing Google Tag Manager - SEND CUSTOM PV`)
    TagManager.initialize({
      gtmId: TAG_MANAGER_IDS[tagType],
    })
  }

  console.log('Sending GTM Custom PV Event...', {
    event,
    page_id,
    ...payload,
  })

  TagManager.dataLayer({
    dataLayer: {
      event,
      page_id,
      ...payload,
    },
  })
}

/**
 * GTM Input Event Handler Function
 * @param inputId GTM Input Event ID
 * @param pageLocation Page Location
 * @param e Keyboard Event
 * @param customStr custom string value if event does not exist
 */
export const handleGTMInputEvent = (
  inputId: TUTOR_INPUT_ID,
  pageLocation: string,
  e: React.ChangeEvent<HTMLInputElement> = null,
  customStr: string = null
) => {
  e && e.preventDefault()

  // Return if both ChangeEvent and Custom String are null
  if (!e && !customStr) return

  // Return if target value is empty
  if (e && e.target.value.length === 0) return

  // Return if event does not exist and custom string length is zero
  if (!e && customStr && customStr.length === 0) return

  sendGTMEvent(GTM_EVENT.INPUT, {
    input_id: inputId,
    page_location: pageLocation,
  })
}

export const getEventUserType = (currentUser: CurrentUser | null) => {
  return !currentUser ? '신규방문자' : firstUser(currentUser) ? '첫결제자' : '기결제자'
}

/**
 * Get Location Type
 * @param path Router Path
 * @returns Location Type
 */
export const getLocationType = (path: string): LOCATION_TYPE => {
  if (path.includes(LOCATION_TYPE.LANDING)) {
    return LOCATION_TYPE.LANDING
  }

  if (path.includes(LOCATION_TYPE.PORTAL)) {
    return LOCATION_TYPE.PORTAL
  }
}

export const sendGTMWebVitalCheckFCP = (event: GTM_PV_EVENT, page_title: string, fcp: number): void => {
  console.log(fcp, 'WEB_VITAL_CHECK_FCP')
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      page_title: page_title,
      fcp: fcp,
    },
  })
}

export const sendGTMWebVitalCheckLCP = (event: GTM_PV_EVENT, page_title: string, lcp: number): void => {
  console.log(lcp, 'WEB_VITAL_CHECK_LCP')

  TagManager.dataLayer({
    dataLayer: {
      event: event,
      page_title: page_title,
      lcp: lcp,
    },
  })
}

export const sendGTMWebVitalCheckTTI = (event: GTM_PV_EVENT, page_title: string, tti: number): void => {
  console.log(tti, 'WEB_VITAL_CHECK_TTI')

  TagManager.dataLayer({
    dataLayer: {
      event: event,
      page_title: page_title,
      tti: tti,
    },
  })
}
