import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { COLORS } from '../../../modules/vars'
import { useButtonState } from '../modules/ButtonStateContext'
import { BUTTON_SIZE, BUTTON_SIZE_KEY, BUTTON_TYPE, BUTTON_TYPE_KEY } from '../types'
import ButtonLoading from './ButtonLoading'

export interface ButtonLabelProps extends PropsWithChildren {
  className?: string
  customTxtColor?: (typeof COLORS)[keyof typeof COLORS]
  children?: ReactNode
}

const ButtonLabel = ({ className, customTxtColor, children }: ButtonLabelProps) => {
  const { size, disabled, isLoading, type } = useButtonState()

  if (isLoading) {
    return <ButtonLoading />
  }

  return (
    <label
      className={classNames(
        styles.color({ type, disabled }),
        styles.size({ type, size }),
        className,
        !disabled && 'cursor-pointer'
      )}
      style={!disabled && customTxtColor ? { color: customTxtColor } : {}}
    >
      {children}
    </label>
  )
}

export default ButtonLabel

const styles = {
  size: ({ type, size }: { type: BUTTON_TYPE_KEY; size: BUTTON_SIZE_KEY }) =>
    classNames(
      type !== BUTTON_TYPE.TEXT && size === BUTTON_SIZE.XS && `Caption-12Md`,
      type === BUTTON_TYPE.TEXT && size === BUTTON_SIZE.SM && `Caption-12Bd`,
      size === BUTTON_SIZE.SM && `Body2-14Md`,
      size === BUTTON_SIZE.MD && `Body2-14Bd`,
      size === BUTTON_SIZE.LG && `Body1-16Bd`,
      size === BUTTON_SIZE.XL && `Body1-16Bd`
    ),
  color: ({ type, disabled }: { type: BUTTON_TYPE_KEY; disabled: boolean }) =>
    classNames(
      (type === BUTTON_TYPE.SOLID || disabled) && type !== BUTTON_TYPE.TEXT && `text-white`,
      type === BUTTON_TYPE.OUTLINE && !disabled && `text-black`,
      type === BUTTON_TYPE.TEXT && disabled && `text-gray-100`
    ),
}
