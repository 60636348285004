import { createContext, useContext } from 'react'
import { BUILDER_BUTTON_SIZE_KEY, BUILDER_BUTTON_TYPE_KEY } from '../types'

interface DefaultValueState {
  isLoading: boolean
  disabled: boolean
  type: BUILDER_BUTTON_TYPE_KEY
  size: BUILDER_BUTTON_SIZE_KEY
}

export const BuilderButtonContext = createContext<DefaultValueState | null>(null)

export const useButtonState = () => {
  const context = useContext(BuilderButtonContext)
  if (!context) {
    throw new Error('useButtonState는 ButtonProvider 안에서만 사용 가능합니다.')
  }
  return context
}
