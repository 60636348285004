import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IRemainingCredits } from '../../../model/credit.interface'
import { getJwtToken } from '../../../modules/auth'
import {
  creditListQueryApi,
  myCreditsQueryApi,
  pointsQueryApi,
  referralQueryApi,
  remainingCreditsQueryApi,
} from '../../react-query/queryApis'

export const useRemainingCredits = () => {
  const token = getJwtToken()

  return useQuery<IRemainingCredits, AxiosError>({
    queryKey: remainingCreditsQueryApi.getQueryKey(),
    queryFn: remainingCreditsQueryApi.getQueryFn(),
    enabled: !!token,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
}

export interface IPoint {
  amount: string
  content: string
  date: string
  expired_at: string
  normal_amount: number
  point_id: number
  status: number
  admin_can_delete: boolean
}
export interface IPointResponse {
  point_statement: IPoint[]
  referrals: any[]
  total_point: string
  will_be_expired_point: string
  points_expired_in_week: string
}
export const usePoints = (country_code = 'US') => {
  const token = getJwtToken()
  const searchParams = { country_code }

  return useQuery<IPointResponse, AxiosError>({
    queryKey: pointsQueryApi.getQueryKey({ searchParams }),
    queryFn: pointsQueryApi.getQueryFn({ searchParams }),

    // onError: (e) => handleAxiosError(e),
    retry: false,
    enabled: !!token,
  })
}

// Referral
export interface IReferralDatas {
  referrals: Referral[]
  referral_point_balance: number
}

export interface Referral {
  id: number
  name: string
  progress: number
  points: Point[] | null
}

export interface Point {
  id: number
  init_amount: number
  reason: number
  status: Status
}

export enum Status {
  ReadyToRedeem = 'ready_to_redeem',
}

export const useReferralPointList = () => {
  const token = getJwtToken()

  return useQuery<IReferralDatas, AxiosError>({
    queryKey: referralQueryApi.getQueryKey(),
    queryFn: referralQueryApi.getQueryFn(),
    enabled: !!token,
  })
}

export const useCreditList = () => {
  const token = getJwtToken()

  return useQuery<any, AxiosError>({
    queryKey: creditListQueryApi.getQueryKey(),
    queryFn: creditListQueryApi.getQueryFn(),
    retry: false,
    enabled: !!token,
  })
}

export interface PackageAndCreditSummary extends ICreditSummary {
  packages: IPackage[]
}
export interface IPackage {
  allowed_hours: any
  challenge_book: any
  challenge_date: string
  convert_hours: any
  coupon_bundle_id: number
  coupon_type_id: number
  coupons: ICoupon[]
  duration: number
  exp_dates: number
  has_long_term_benefits: boolean
  is_challenge: boolean
  is_free: boolean
  lesson_book: any
  name: string
  package_type: string
  started: false
  unused_coupons: number
}
export interface ICoupon {
  id: number
  coupon_type: string
  coupon_type_id: number
  name: string
  used: boolean
  target: number
  used_at: string
}
export interface ICreditSummary {
  free: IDurationType
  normal: IDurationType
  time_limit: IDurationType
}
export type IDurationType = {
  half: number
  full: number
}

export const useMyCredits = () => {
  const token = getJwtToken()

  return useQuery<PackageAndCreditSummary, AxiosError>({
    queryKey: myCreditsQueryApi.getQueryKey(),
    queryFn: myCreditsQueryApi.getQueryFn(),

    // onError: (e) => handleAxiosError(e),
    enabled: !!token,
  })
}
