import { Dialog } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DSModalContext } from '../modules/DSModalContext'

export type DSMODAL_SIZE_TYPE = 'sm' | 'lg' | 'xl' | 'xl_custom' | 'custom'

export const MODAL_CONTAINER_ID = 'ds-modal'

export interface DSModalProps {
  id: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  size: DSMODAL_SIZE_TYPE
  closeOnDim?: boolean
  closeButton?: boolean
  children: React.ReactNode
  blockAddHistory?: boolean
  className?: string
}

/**
 * 팝업 (프로모션 제외)
 * @param props
 * @returns
 */
const DSModalMain = (props: DSModalProps) => {
  const {
    id,
    isOpen,
    setIsOpen,
    size,
    closeOnDim,
    closeButton = false,
    children,
    blockAddHistory = false,
    className,
  } = props
  const [popupDOM, setPopupDOM] = useState(null)

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const dom = document.getElementById(MODAL_CONTAINER_ID)
      setPopupDOM(dom)
    }
  }, [])
  // const popupDOM = document.getElementById(POPUP_CONTAINER_ID)
  // const popupDOM = document.getElementById('headlessui-portal-root')

  const closePopup: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setIsOpen(false)
  }
  const onClickContent: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // Dim 까지 propagation 되면 팝업이 의도치 않게 닫힐 수 있으므로 stopPropagation 필수
    e.stopPropagation()
  }

  const dimClassName = DIM_CLASSNAME
  const containerClassName = `${CONATINER_CLASSNAME[size]} ${className}`

  const renderPopup = isOpen && !!popupDOM

  if (!renderPopup) return null

  return createPortal(
    <Dialog as="div" static open={isOpen} onClose={() => null}>
      <div id={id} className={dimClassName} onClick={closeOnDim ? closePopup : undefined}>
        <div className={containerClassName} onClick={onClickContent}>
          <DSModalContext.Provider value={{ setIsOpen, size, closeButton }}>{children}</DSModalContext.Provider>
        </div>
      </div>
    </Dialog>,
    popupDOM
  )
}

export default DSModalMain

const DIM_CLASSNAME =
  'fixed top-0 left-0 w-full h-full px-[16px] flex items-center justify-center bg-[rgba(0,0,0,0.4)] z-[2999]' // react-toastify의 z-index 9999보다 낮게 설정할 것

type ContainerClassName = Record<DSMODAL_SIZE_TYPE, string> & {
  xl_custom?: string
}

const CONATINER_CLASSNAME: ContainerClassName = {
  sm: 'w-[328px] px-[20px] py-[24px] bg-white rounded-[12px] z-[9999]',
  lg: 'w-[464px] p-[32px] bg-white rounded-[12px] z-[9999]',
  xl: 'w-[560px] p-[40px] bg-white rounded-[12px] flex flex-col items-end z-[9999]',
  xl_custom: 'w-[560px] bg-white rounded-[12px] flex flex-col z-[9999]',
  custom: 'flex flex-col z-[9999]',
}
