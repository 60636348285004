import { CDN_URL } from '../vars'

// @ts-strict-ignore
const service = 'adult'

export const ICON_SUBSCRIBE_EMAIL = `${CDN_URL}/new-theme/new-icons/${service}/icon/subscribe/icon_subscribe_email.svg`
export const LOCATION_ICON = `${CDN_URL}/new-theme/new-icons/${service}/icon/location/location_icon.svg`
export const NO_RESULT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/no_result/icon_no_result_gray.svg`
export const EMOJI_GOOD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/emoji/good/black.svg`
export const EMOJI_GOOD_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/emoji/good/gray.svg`
export const EMOJI_GOOD_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/emoji/good/primary.svg`
export const EMOJI_SORRY_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/emoji/sorry/black.svg`
export const EMOJI_SORRY_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/emoji/sorry/gray.svg`
export const EMOJI_SORRY_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/emoji/sorry/primary.svg`
export const ICON_14PX_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/icon/14px/placeholder.svg`
export const ICON_16PX_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/icon/16px/placeholder.svg`
export const ICON_20PX_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/icon/20px/placeholder.svg`
export const ICON_24PX_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/icon/24px/placeholder.svg`
export const ICON_20PX_NOTICE = `${CDN_URL}/new-theme/new-icons/${service}/icon/20px/notice.svg`
export const ICON_32_ADD_PERSON_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/add_person/black.svg`
export const ICON_32_BOOK_TIMER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/book_timer/black.svg`
export const ICON_32_CALENDER_CHECK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/calender_check/black.svg`
export const ICON_32_CALENDER_COLOR = `${CDN_URL}/new-theme/new-icons/${service}/icon/24px/calendar.png`
export const ICON_32_COMPASS_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/compass/black.svg`
export const ICON_32_GIFT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/gift/black.svg`
export const ICON_32_HOMEWORK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/homework/black.svg`
export const ICON_32_PURCHASE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/purchase/black.svg`
export const ICON_32_PURCHASE_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/purchase/red.svg`
export const ICON_32_RESERVATION_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/reservation/black.svg`
export const ICON_32_TIMER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/32/timer/black.svg`
export const ICON_32PX_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/icon/32px/placeholder.svg`
export const ICON_40_CHAT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/chat/black.svg`
export const ICON_CHAT_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/chat/gray700.svg`
export const ICON_CHAT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chat/primary.svg`
export const ICON_CHAT_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chat/secondary.svg`
export const ICON_40_EXIT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/exit/black.svg`
export const ICON_40_MIC_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/mic/black.svg`
export const ICON_40_MIC_OFF_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/mic_off/black.svg`
export const ICON_40_TIMEFIRST = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/timefirst.svg`
export const ICON_40_TUTORFIRST = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/tutorfirst.svg`
export const ICON_40_ZOOM_IN_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/zoom_in/black.svg`
export const ICON_40_PRESENT_GRAY_700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/40/present_40px.svg`
export const ICON_40_PRESENT_PRIMARY = `${CDN_URL}/web/202211/present_primary.svg`
export const ICON_40_PRESENT_INFO = `${CDN_URL}/web/202211/present_info.svg`
export const ICON_ACTIVITY_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/activity/active.svg`
export const ICON_ACTIVITY_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/activity/black.svg`
export const ICON_ACTIVITY_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/activity/gray.svg`
export const ICON_ACTIVITY_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/activity/inactive.svg`
export const ICON_ACTIVITY_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/activity/primary.svg`
export const ICON_ALERT_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/alert/red.svg`
export const ICON_ALERT_ORANGE = `${CDN_URL}/new-theme/new-icons/adult/icon/alert/orange.svg`
export const ICON_ARROW_RIGHT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-right/black.svg`
export const ICON_ARROW_RIGHT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-right/gray.svg`
export const ICON_ARROW_RIGHT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-right/primary.svg`
export const ICON_ARROW_DOWN_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-down/primary.svg`
export const ICON_ARROW_DOWN_FILL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-down/fill-primary.svg`
export const ICON_SUBARROW_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/subarrow/gray.svg`
export const ICON_BACKWARD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/backward/black.svg`
export const ICON_BAR_CHART_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/bar-chart/black.svg`
export const ICON_BAR_CHART_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/bar-chart/gray.svg`
export const ICON_BAR_CHART_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/bar-chart/primary.svg`
export const ICON_BELL_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/bell/active.svg`
export const ICON_BELL_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/bell/black.svg`
export const ICON_BELL_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/bell/inactive.svg`
export const ICON_BELL_INACTIVE_GRAY_600 = `${CDN_URL}/new-theme/new-icons/${service}/icon/bell/inactive-gray-600.svg`
export const ICON_BOOK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/book/black.svg`
export const ICON_BOOK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/book/gray.svg`
export const ICON_BOOK_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/book/primary.svg`
export const ICON_BOOK_OPEN_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/book-open/black.svg`
export const ICON_BOOK_OPEN_GRAY600 = `${CDN_URL}/new-theme/new-icons/${service}/icon/book-open/gray600.svg`
export const ICON_BOOK_OPEN_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/book-open/gray700.svg`
export const ICON_BOOK_OPEN_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/book-open/primary.svg`
export const ICON_BOOK_OPEN_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/book-open/secondary.svg`
export const ICON_CALENDER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender/black.svg`
export const ICON_CALENDER_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender/gray.svg`
export const ICON_CALENDER_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender/primary.svg`
export const ICON_CALENDER_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender/white.svg`
export const ICON_CALENDER_CHECK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender_check/black.svg`
export const ICON_CALENDER_CHECK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender_check/gray.svg`
export const ICON_CALENDER_CHECK_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/calender_check/white.svg`
export const ICON_CAMERA_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera/black.svg`
export const ICON_CAMERA_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera/gray.svg`
export const ICON_CAMERA_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera/primary.svg`
export const ICON_CAMERA_OFF_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera-off/black.svg`
export const ICON_CAMERA_OFF_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera-off/gray.svg`
export const ICON_CAMERA_OFF_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/camera-off/primary.svg`
export const ICON_CHECK_CIRCLE_COMPLETE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/complete/primary.svg`
export const ICON_CHECK_CIRCLE_COMPLETE = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/complete.svg`
export const ICON_CHECK_CIRCLE_GREEN = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/green.svg`
export const ICON_CHECK_CIRCLE_DARK_GREEN = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/dark-green.svg`
export const ICON_CHECK_CIRCLE_BLUE = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/blue.svg`
export const ICON_CHECK_CIRCLE_INCOMPLETE = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/incomplete.svg`
export const ICON_CHECK_CIRCLE_SMALL_COMPLETE = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/small/complete.svg`
export const ICON_CHECK_CIRCLE_SMALL_INCOMPLETE = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/small/incomplete.svg`
export const ICON_CHECK_CIRCLE_SMALL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/check-circle/small/primary.svg`
export const ICON_CHECKBOX_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/checkbox/active.svg`
export const ICON_CHECKBOX_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/checkbox/inactive.svg`
export const ICON_CHECKBOX_ACTIVE_TEENS = `${CDN_URL}/new-theme/new-icons/teens/icon/checkbox/active.svg`
export const ICON_CHECKBOX_SMALL_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/checkbox/small/active.svg`
export const ICON_CHECKBOX_SMALL_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/checkbox/small/inactive.svg`
export const ICON_CHEVRON_DOWN_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/black.svg`
export const ICON_CHEVRON_DOWN_BOLD_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/bold/gray.svg`
export const ICON_CHEVRON_DOWN_BOLD_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/bold/primary.svg`
export const ICON_CHEVRON_DOWN_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/gray.svg`
export const ICON_CHEVRON_DOWN_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/gray700.svg`
export const ICON_CHEVRON_DOWN_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/primary.svg`
export const ICON_CHEVRON_DOWN_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-down/white.svg`
export const ICON_CHEVRON_LEFT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/black.svg`
export const ICON_CHEVRON_LEFT_BOLD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/bold/black.svg`
export const ICON_CHEVRON_LEFT_BOLD_WHITE_X_LARGE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/bold/white/x-large.svg`
export const ICON_CHEVRON_LEFT_BOLD_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/bold/white.svg`
export const ICON_CHEVRON_LEFT_CIRCLE_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/circle/white.svg`
export const ICON_CHEVRON_LEFT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/gray.svg`
export const ICON_CHEVRON_LEFT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-left/primary.svg`
export const ICON_CHEVRON_RIGHT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/black.svg`
export const ICON_CHEVRON_RIGHT_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/white.svg`
export const ICON_CHEVRON_RIGHT_BOLD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/bold/black.svg`
export const ICON_CHEVRON_RIGHT_BOLD_WHITE_X_LARGE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/bold/white/x-large.svg`
export const ICON_CHEVRON_RIGHT_BOLD_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/bold/white.svg`
export const ICON_CHEVRON_RIGHT_CIRCLE_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/circle/white.svg`
export const ICON_CHEVRON_RIGHT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/gray.svg`
export const ICON_CHEVRON_RIGHT_GRAY_400 = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/gray400.svg`
export const ICON_CHEVRON_RIGHT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/primary.svg`
export const ICON_CHEVRON_RIGHT_ORANGE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-right/orange.svg`
export const ICON_CHEVRON_UP_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/black.svg`
export const ICON_CHEVRON_UP_BOLD_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/bold/secondary.svg`
export const ICON_CHEVRON_UP_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/gray.svg`
export const ICON_CHEVRON_UP_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/gray700.svg`
export const ICON_CHEVRON_UP_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/primary.svg`
export const ICON_CHEVRON_UP_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/secondary.svg`
export const ICON_CHEVRON_UP_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/chevron-up/white.svg`
export const ICON_CLOCK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/clock/black.svg`
export const ICON_CLOCK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/clock/gray.svg`
export const ICON_CLOCK_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/clock/primary.svg`
export const ICON_CLOCK_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/red.svg`
export const ICON_CONTENT_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/content/active.svg`
export const ICON_CONTENT_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/content/inactive.svg`
export const ICON_CREDIT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/credit/black.svg`
export const ICON_CREDIT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/credit/gray.svg`
export const ICON_CREDIT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/credit/primary.svg`
export const ICON_DOWNLOAD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/download/black.svg`
export const ICON_DOWNLOAD_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/download/gray.svg`
export const ICON_DOWNLOAD_GRAY_NORMAL = `${CDN_URL}/new-theme/new-icons/${service}/icon/download/gray_normal.svg`
export const ICON_DOWNLOAD_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/download/primary.svg`
export const ICON_EDIT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/black.svg`
export const ICON_EDIT_BLUE = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/blue.svg`
export const ICON_EDIT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/gray.svg`
export const ICON_EDIT_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/gray700.svg`
export const ICON_EDIT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/primary.svg`
export const ICON_EDIT_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/edit/secondary.svg`
export const ICON_EXIT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/exit/black.svg`
export const ICON_EXIT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/exit/gray.svg`
export const ICON_EXIT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/exit/primary.svg`
export const ICON_EXTERNAL_LINK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/external-link/black.svg`
export const ICON_EXTERNAL_LINK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/external-link/gray.svg`
export const ICON_EXTERNAL_LINK_GRAY_700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/external-link/gray700.svg`
export const ICON_EXTERNAL_LINK_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/external-link/primary.svg`
export const ICON_FILE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/file/black.svg`
export const ICON_FILE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/file/gray.svg`
export const ICON_FILE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/file/primary.svg`
export const ICON_FILTER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/filter/black.svg`
export const ICON_FILTER_BOLD_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/filter/bold/primary.svg`
export const ICON_FILTER_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/filter/gray.svg`
export const ICON_FILTER_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/filter/primary.svg`
export const ICON_FONTSIZE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/fontsize/black.svg`
export const ICON_FORWARD_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/forward/black.svg`
export const ICON_GIFT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/gift/black.svg`
export const ICON_GIFT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/gift/gray.svg`
export const ICON_GIFT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/gift/primary.svg`
export const ICON_GLOBE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/globe/black.svg`
export const ICON_GLOBE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/globe/gray.svg`
export const ICON_GLOBE_GRAY_700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/globe/gray_700.svg`
export const ICON_GLOBE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/globe/primary.svg`
export const ICON_GLOBE_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/globe/secondary.svg`
export const ICON_HAMBERGER_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/hamberger/gray.svg`
export const ICON_HEADPHONE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/headphone/black.svg`
export const ICON_HEADPHONE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/headphone/gray.svg`
export const ICON_HEADPHONE_GREEN = `${CDN_URL}/new-theme/new-icons/${service}/icon/headphone/green.svg`
export const ICON_HEADPHONE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/headphone/primary.svg`
export const ICON_HEART_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/heart/active.svg`
export const ICON_HEART_ACTIVE_2 = `${CDN_URL}/new-theme/new-icons/${service}/icon/heart/active_2.svg`
export const ICON_HEART_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/heart/inactive.svg`
export const ICON_HEART_INACTIVE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/heart/gray.svg`
export const ICON_HEART_INACTIVE_2 = `${CDN_URL}/new-theme/new-icons/${service}/icon/heart/inactive_2.svg`
export const ICON_HOME_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/home/active.svg`
export const ICON_HOME_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/home/inactive.svg`
export const ICON_HOMEWORK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/homework/black.svg`
export const ICON_HOMEWORK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/homework/gray.svg`
export const ICON_HOMEWORK_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/homework/primary.svg`
export const ICON_HOMEWORK_BLACK_BOLD = `${CDN_URL}/new-theme/new-icons/${service}/icon/homework/black_bold.svg`
export const ICON_HORIZONTAL_DOTS_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/horizontal_dots/black.svg`
export const ICON_HORIZONTAL_DOTS_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/horizontal_dots/gray.svg`
export const ICON_HORIZONTAL_DOTS_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/horizontal_dots/primary.svg`
export const ICON_INFORMATION_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/information/black.svg`
export const ICON_INFORMATION_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/information/gray.svg`
export const ICON_INFORMATION_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/information/primary.svg`
export const ICON_INFORMATION_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/information/red.svg`
export const ICON_INFORMATION_ALERT = `${CDN_URL}/new-theme/new-icons/${service}/icon/information/alert.svg`
export const ICON_LESSON_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/lesson/active.svg`
export const ICON_LESSON_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/lesson/inactive.svg`
export const ICON_LIST_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/list/black.svg`
export const ICON_LIST_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/list/gray.svg`
export const ICON_LIST_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/list/primary.svg`
export const ICON_LOCK_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/black.svg`
export const ICON_LOCK_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/gray.svg`
export const ICON_LOCK_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/primary.svg`
export const ICON_LOCK_SMALL_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/small/black.svg`
export const ICON_LOCK_SMALL_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/small/gray.svg`
export const ICON_LOCK_SMALL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/lock/small/primary.svg`
export const ICON_LOGO_R_LARGE = `${CDN_URL}/new-theme/new-icons/${service}/icon/logo/r-large.svg`
export const ICON_LOGO_R_SMALL = `${CDN_URL}/new-theme/new-icons/${service}/icon/logo/r-small.svg`
export const ICON_LOGO_RINGLE_LONG = `${CDN_URL}/new-theme/new-icons/${service}/icon/logo/ringle/long.svg`
export const ICON_LOGO_WHITE_BACKGROUND = `${CDN_URL}/new-theme/new-icons/${service}/icon/logo/white-background.svg`
export const ICON_LOGO_WHITE_ROUND = `${CDN_URL}/new-theme/new-icons/${service}/icon/logo/ringle/white.svg`
export const ICON_MIC_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/mic/black.svg`
export const ICON_MIC_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/mic/gray.svg`
export const ICON_MIC_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/mic/primary.svg`
export const ICON_MIC_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/mic/red.svg`
export const ICON_MIC_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/mic/white.svg`
export const ICON_PAUSE_BUTTON_LARGE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/pause_button/large/black.svg`
export const ICON_PLAY_BUTTON_LARGE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/large/black.svg`
export const ICON_PLAY_BUTTON_LARGE_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/large/white.svg`
export const ICON_PLAY_BUTTON_LARGE = `${CDN_URL}/new-theme/new-icons/adult/icon/play_button/large.svg`
export const ICON_PLAY_BUTTON_SMALL_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/small/black.svg`
export const ICON_PLAY_BUTTON_SMALL_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/small/gray.svg`
export const ICON_PLAY_BUTTON_SMALL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/small/primary.svg`
export const ICON_PLAY_BUTTON_SMALL = `${CDN_URL}/new-theme/new-icons/${service}/icon/play_button/small.svg`
export const ICON_PLUS_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus/black.svg`
export const ICON_PLUS_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus/gray.svg`
export const ICON_PLUS_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus/primary.svg`
export const ICON_PLUS_CIRCLE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus-circle/black.svg`
export const ICON_PLUS_CIRCLE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus-circle/gray.svg`
export const ICON_PLUS_CIRCLE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/plus-circle/primary.svg`
export const ICON_PRINTER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/printer/black.svg`
export const ICON_PRINTER_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/printer/gray.svg`
export const ICON_PRINTER_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/printer/primary.svg`
export const ICON_QUESTION_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/question/black.svg`
export const ICON_QUESTION_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/question/gray.svg`
export const ICON_QUESTION_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/question/primary.svg`
export const ICON_QUESTION_WARNING = `${CDN_URL}/new-theme/new-icons/${service}/icon/question/warning.svg`
export const ICON_RADIO_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/radio/active.svg`
export const ICON_RADIO_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/radio/inactive.svg`
export const ICON_RECORDING_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/recording/black.svg`
export const ICON_RECORDING_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/recording/gray.svg`
export const ICON_RECORDING_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/recording/primary.svg`
export const ICON_REPEAT_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/repeat/black.svg`
export const ICON_REPEAT_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/repeat/gray.svg`
export const ICON_REPEAT_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/repeat/primary.svg`
export const ICON_RINGLE_EDITOR_TEXT_BOLD = `${CDN_URL}/new-theme/new-icons/${service}/icon/ringledoc/text_bold.svg`
export const ICON_RINGLE_EDITOR_TEXT_ITALIC = `${CDN_URL}/new-theme/new-icons/${service}/icon/ringledoc/text_italic.svg`
export const ICON_RINGLE_EDITOR_TEXT_LINK = `${CDN_URL}/new-theme/new-icons/${service}/icon/ringledoc/text_link.svg`
export const ICON_RINGLE_EDITOR_TEXT_UNDERLINE = `${CDN_URL}/new-theme/new-icons/${service}/icon/ringledoc/text_underline.svg`
export const ICON_SEARCH_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/search/black.svg`
export const ICON_SEARCH_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/search/gray.svg`
export const ICON_SEARCH_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/search/primary.svg`
export const ICON_SEND_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/send/black.svg`
export const ICON_SEND_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/send/gray.svg`
export const ICON_SEND_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/send/primary.svg`
export const ICON_SETTINGS_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/settings/black.svg`
export const ICON_SETTINGS_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/settings/gray.svg`
export const ICON_SETTINGS_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/settings/primary.svg`
export const ICON_STAR_CONTAINED = `${CDN_URL}/new-theme/new-icons/${service}/icon/star/contained.svg`
export const ICON_STAR_OUTLINED = `${CDN_URL}/new-theme/new-icons/${service}/icon/star/outlined.svg`
export const ICON_STAR_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/star/primary.svg`
export const ICON_STAR_WHITE = `${CDN_URL}/new-theme/icon/star/white.svg`
export const ICON_STAR_SMALL_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/star/small/gray.svg`
export const ICON_STAR_SMALL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/star/small/primary.svg`
export const ICON_TARGET_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/target/black.svg`
export const ICON_TARGET_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/target/red.svg`
export const ICON_TARGET_YELLOW = `${CDN_URL}/new-theme/new-icons/${service}/icon/target/yellow.svg`
export const ICON_THUMBS_UP_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/thumbs-up/black.svg`
export const ICON_THUMBS_UP_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/thumbs-up/gray.svg`
export const ICON_THUMBS_UP_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/thumbs-up/primary.svg`
export const ICON_TRASH_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/trash/black.svg`
export const ICON_TRASH_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/trash/gray.svg`
export const ICON_TRASH_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/trash/primary.svg`
export const ICON_TRASH_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/trash/red.svg`
export const ICON_TUTOR_ACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/tutor/active.svg`
export const ICON_TUTOR_INACTIVE = `${CDN_URL}/new-theme/new-icons/${service}/icon/tutor/inactive.svg`
export const ICON_FEEDBACK_AFTER = `${CDN_URL}/new-theme/new-icons/${service}/icon/feedback-report/img_after.svg`
export const ICON_FEEDBACK_BEFORE = `${CDN_URL}/new-theme/new-icons/${service}/icon/feedback-report/img_before.svg`
export const ICON_FEEDBACK_EXPLANATION = `${CDN_URL}/new-theme/new-icons/${service}/icon/feedback-report/img_explanation.svg`
export const ICON_TV_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/tv/black.svg`
export const ICON_TV_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/tv/gray.svg`
export const ICON_TV_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/tv/primary.svg`
export const ICON_TV_YELLOW = `${CDN_URL}/new-theme/new-icons/${service}/icon/tv/yellow.svg`
export const ICON_USER_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/user/black.svg`
export const ICON_USER_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/user/gray.svg`
export const ICON_USER_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/user/primary.svg`
export const ICON_VIDEO_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/video/black.svg`
export const ICON_VIDEO_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/video/gray.svg`
export const ICON_VIDEO_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/video/primary.svg`
export const ICON_VIDEO_DANGER = `${CDN_URL}/new-theme/icon/icon_video.svg`
export const ICON_VIDEO_OFF_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/video-off/black.svg`
export const ICON_VIDEO_OFF_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/video-off/gray.svg`
export const ICON_VIDEO_OFF_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/video-off/primary.svg`
export const ICON_VOICEMAIL_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/voicemail/black.svg`
export const ICON_VOICEMAIL_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/voicemail/gray.svg`
export const ICON_VOICEMAIL_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/voicemail/primary.svg`
export const ICON_X_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/x/black.svg`
export const ICON_X_BOLD_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/x/bold/gray700.svg`
export const ICON_X_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/x/gray.svg`
export const ICON_X_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/x/primary.svg`
export const ICON_X_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/icon/x/white.svg`
export const ICON_X_CIRCLE_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/x-circle/black.svg`
export const ICON_X_CIRCLE_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/x-circle/gray.svg`
export const ICON_X_CIRCLE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/x-circle/primary.svg`
export const IMAGE_2_CARD_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/image/2-card/placeholder.svg`
export const IMAGE_CHART_PLACEHOLDER = `${CDN_URL}/new-theme/new-icons/${service}/image/chart/placeholder.svg`
export const IMAGE_LOGO_APPLE = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/apple.svg`
export const IMAGE_LOGO_CIRCLE = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/circle.svg`
export const IMAGE_LOGO_FACEBOOK = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/facebook.svg`
export const IMAGE_LOGO_GOOGLE = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/google.svg`
export const IMAGE_LOGO_KAKAOTALK = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/kakaotalk.svg`
export const IMAGE_LOGO_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/primary.svg`
export const IMAGE_LOGO_RECTANGLE = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/rectangle.svg`
export const IMAGE_LOGO_WHITE_TEXT = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/white-text.svg`
export const IMAGE_LOGO_WHITE = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/white.svg`
export const IMAGE_LOGO_ZOOM = `${CDN_URL}/new-theme/new-icons/${service}/image/logo/zoom.svg`
export const IMAGE_MEGAPHONE = `${CDN_URL}/new-theme/new-icons/${service}/image/megaphone.svg`
export const IMAGE_PERSON_SEARCH = `${CDN_URL}/new-theme/new-icons/${service}/image/person-search.svg`
export const IMG_CELEBRATION = `${CDN_URL}/new-theme/new-icons/${service}/img/celebration.svg`
export const IMG_RESERVATION = `${CDN_URL}/new-theme/new-icons/${service}/img/reservation.svg`
export const IMG_REVIEW = `${CDN_URL}/new-theme/new-icons/${service}/img/review.svg`
export const IMAGE_RESERVATION = `${CDN_URL}/new-theme/new-icons/${service}/image/reservation/reservation.svg`
export const IMAGE_ZOOM_USAGE = `${CDN_URL}/new-theme/new-icons/${service}/image/zoomusage/zoom-usage.svg`
export const IMAGE_RINGLE_USAGE = `${CDN_URL}/new-theme/new-icons/${service}/image/ringleusage/ringle-usage.svg`
export const TEENS_PARENT_LOCK = `${CDN_URL}/new-theme/new-icons/${service}/icon/parent_lock/parent_lock_icon.svg`
export const ICON_ALARM_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/tutor/past_lesson/icon/alarm/primary.svg`
export const ICON_ALARM_RED = `${CDN_URL}/new-theme/new-icons/${service}/icon/tutor/past_lesson/icon/alarm/red.svg`
export const ICON_ALARM_GRAY = `${CDN_URL}/new-theme/new-icons/${service}/icon/tutor/past_lesson/icon/alarm/gray.svg`
export const ICON_FEEDBACK_REPORT = `${CDN_URL}/new-theme/icon/lesson/icon_Feedback+Report.svg`
export const ICON_FEEDBACK_PRIMARY = `${CDN_URL}/new-theme/icon/lesson/icon_Feedback+Report_Primary.svg`
export const ICON_FEEDBACK_DISABLED = `${CDN_URL}/new-theme/icon/lesson/icon_Feedback+Report_disabled.svg`
export const ICON_LESSON_REQUEST = `${CDN_URL}/new-theme/icon/lesson/icon_Lesson+Requests.svg`
export const ICON_LESSON_PRIMARY = `${CDN_URL}/new-theme/icon/lesson/icon_Lesson+Requests_Primary.svg`
export const ICON_LESSON_DISABLED = `${CDN_URL}/new-theme/icon/lesson/icon_Lesson+Requests_disabled.svg`
export const ICON_PROFILE_REQUEST = `${CDN_URL}/new-theme/icon/lesson/icon_Profile+Update.svg`
export const ICON_PROFILE_PRIMARY = `${CDN_URL}/new-theme/icon/lesson/icon_Profile+Update_Primary.svg`
export const ICON_PROFILE_DISABLED = `${CDN_URL}/new-theme/icon/lesson/icon_Profile+Update_disabled.svg`
export const ICON_SCHEDULED = `${CDN_URL}/new-theme/icon/lesson/icon_Schedule.svg`
export const ICON_TAX_PAYER = `${CDN_URL}/new-theme/icon/lesson/icon_Taxpayer+Info.svg`
export const ICON_TAX_PAYER_PRIMARY = `${CDN_URL}/new-theme/icon/lesson/icon_Taxpayer+Info_Primary.svg`
export const ICON_TAX_PAYER_DISABLED = `${CDN_URL}/new-theme/icon/lesson/icon_Taxpayer+Info_disabled.svg`
export const ICON_PAGINATION_LEFT = `${CDN_URL}/new-theme/icon/pagination/ic_chevrons_left_black.svg`
export const ICON_PAGINATION_RIGHT = `${CDN_URL}/new-theme/icon/pagination/ic_chevrons_right_black.svg`
export const ICON_RED_TRIANGLE_ARROW = `${CDN_URL}/new-theme/new-icons/adult/icon/red_rounded_triangle_arrow.svg`
export const ICON_RED_ALERT_TRIANGLE = `${CDN_URL}/new-theme/tutor/icon_red_alert_triangle.svg`
export const ICON_CHECKMARK_PLUS_PRIMARY = `${CDN_URL}/new-theme/icon/checkmark_primary.svg`
export const ICON_CHECKMARK_TEENS_PRIMARY = `${CDN_URL}/new-theme/icon/checkmark_teens_primary.svg`
export const ICON_CHECKMARK_DISABLED = `${CDN_URL}/new-theme/icon/checkmark_disabled.svg`
export const ICON_CHECKBOX_ACTIVE_DANGER = `${CDN_URL}/new-theme/icon/checkbox_danger.svg`
export const ICON_CHECKBOX_ACTIVE_INFO = `${CDN_URL}/new-theme/icon/checkbox_info.svg`
export const ICON_REFRESH = `${CDN_URL}/new-theme/icon/refresh_primary.svg`
export const ICON_SHARE_PRIMARY = `${CDN_URL}/new-theme/icon/share/share_primary.svg`
export const ICON_SHARE_GRAY = `${CDN_URL}/new-theme/icon/share/share_gray.svg`
export const ICON_SHARE_BLACK = `${CDN_URL}/new-theme/icon/share/share_black.svg`
export const ICON_SHARE_WEBINAR = `${CDN_URL}/new-theme/icon/share/share_webinar.svg`
export const ICON_SHARE_FACEBOOK = `${CDN_URL}/new-theme/icon/share/share_facebook.svg`
export const ICON_SHARE_KAKAO = `${CDN_URL}/new-theme/icon/share/share_kakao.svg`
export const ICON_SHARE_LINK = `${CDN_URL}/new-theme/icon/share/share_link.svg`
export const ICON_TURN_LEFT_GRAY = `${CDN_URL}/new-theme/tutor/turn_left_gray.svg`
export const ICON_TURN_RIGHT_GRAY = `${CDN_URL}/new-theme/tutor/turn_right_gray.svg`
export const ICON_TURN_LEFT_PRIMARY = `${CDN_URL}/new-theme/tutor/turn_left_primary.svg`
export const ICON_TURN_RIGHT_PRIMARY = `${CDN_URL}/new-theme/tutor/turn_right_primary.svg`
export const ICON_RENEWED_ENGAGEMENT_BONUS = `${CDN_URL}/new-theme/tutor/icon_renewed_engagement_bonus.svg`
export const ICON_POINT = `${CDN_URL}/new-theme/new-icons/${service}/image/point/point.svg`
export const ICON_HINT = `${CDN_URL}/new-theme/new-icons/${service}/image/hint/hint.svg`
// CAF
export const ICON_HORIZONTAL_DOTS_GRAY700 = `${CDN_URL}/new-theme/new-icons/adult/icon/horizontal_dots/gray700.svg`
export const ICON_QUESTION_GRAY700 = `${CDN_URL}/new-theme/new-icons/adult/icon/question/gray700.svg`
export const ICON_PAUSE_BUTTON_GREY_BG = `${CDN_URL}/new-theme/new-icons/adult/icon/pause_btn/greybg.svg`
export const ICON_PLAY_BUTTON = `${CDN_URL}/new-theme/new-icons/adult/icon/play_btn/greybg.svg`
export const ICON_HINT_PRIMARY = `${CDN_URL}/new-theme/new-icons/adult/icon/hint/primary.svg`
export const ICON_X_CIRCLE_RED = `${CDN_URL}/new-theme/new-icons/adult/icon/x-circle/red.svg`
export const ICON_CHECK_CIRCLE_GREEN_2 = `${CDN_URL}/new-theme/new-icons/adult/icon/check-circle/small/green.svg`
export const ICON_PEOPLE_GRAY700 = `${CDN_URL}/new-theme/new-icons/${service}/icon/people/gray700.svg`
export const ICON_PEOPLE_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/people/primary.svg`
export const ICON_PEOPLE_SECONDARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/people/secondary.svg`
export const ICON_CAF_ANALYSIS_BLACK = `${CDN_URL}/new-theme/new-icons/${service}/icon/caf/black.svg`

// 2022 landing home & promotion landing
export const FOOTER_LOGO_KCAB = `${CDN_URL}/new-theme/landing/home/logos/KCAB-Logo.svg`
export const ICON_QUOTE_PRIMARY = `${CDN_URL}/new-theme/landing/home/quote_primary.svg`
export const ICON_THUMBS_UP_QUIZ = `${CDN_URL}/new-theme/tutor/summer_event/thumbs_up_x2.png`
export const LANDING_PROMO_1 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_1.svg`
export const LANDING_PROMO_2 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_2.svg`
export const LANDING_PROMO_3 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_3.svg`
export const LANDING_PROMO_4 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_4.svg`
export const LANDING_PROMO_HERO = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_hero.svg`
export const LANDING_PROMO_HERO_PC = (locale) =>
  `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_hero_desktop_${locale}.svg`
export const LANDING_PROMO_ONLINE = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_online_new.png`
export const LANDING_PROMO_PRICE = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_price.svg`
export const LANDING_PROMO_REVIEW_1 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review1.svg`
export const LANDING_PROMO_REVIEW_2 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review2.svg`
export const LANDING_PROMO_REVIEW_3 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review3.svg`
export const LANDING_PROMO_REVIEW_4 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review4.svg`
export const LANDING_PROMO_REVIEW_5 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review5.svg`
export const LANDING_PROMO_REVIEW_6 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_review6.svg`
export const LANDING_PROMO_RINGLE_TEAM = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_ringleteam.svg`
export const LANDING_PROMO_SEMINAR_1 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_seminar1.svg`
export const LANDING_PROMO_SEMINAR_2 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_seminar2.svg`
export const LANDING_PROMO_SEMINAR_3 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_seminar3.svg`
export const LANDING_PROMO_SEMINAR_4 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_seminar4.svg`
export const LANDING_PROMO_SEMINAR_5 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_seminar5.svg`
export const LANDING_PROMO_STUDY = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_study.svg`
export const LANDING_PROMO_TICKET = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_ticket.svg`
export const LANDING_PROMO_SPEAKING = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_speaking.svg`
export const LANDING_PROMO_INVITE = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_invite.svg`
export const LANDING_PROMO_AVATAR_1 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_avatar1.svg`
export const LANDING_PROMO_AVATAR_2 = `${CDN_URL}/new-theme/landing/promotion/s_landing_promo_avatar2.svg`

//2022 Summer Referral Event
export const REFERRAL_DOLLAR_PRIMARY = `${CDN_URL}/new-theme/tutor/summer_event/dollar_primary.svg`
export const REFERRAL_BOOK_PRIMARY = `${CDN_URL}/new-theme/tutor/summer_event/book_primary.svg`
export const REFERRAL_GLOBE_PRIMARY = `${CDN_URL}/new-theme/tutor/summer_event/globe_primary.svg`
export const REFERRAL_CLOCK_PRIMARY = `${CDN_URL}/new-theme/tutor/summer_event/clock_primary.svg`
export const REFERRAL_START_STEP1 = `${CDN_URL}/new-theme/tutor/summer_event/start_step1.svg`
export const REFERRAL_START_STEP2 = `${CDN_URL}/new-theme/tutor/summer_event/start_step2.svg`
export const REFERRAL_START_STEP3 = `${CDN_URL}/new-theme/tutor/summer_event/start_step3.svg`
export const REFERRAL_AVERAGE_TUTOR = `${CDN_URL}/new-theme/tutor/summer_event/img_average_tutor.svg`
export const REFERRAL_LANDING_HERO = `${CDN_URL}/new-theme/tutor/summer_event/img_referral_landing_hero.svg`
export const REFERRAL_LANDING_HERO_WEB = `${CDN_URL}/new-theme/tutor/summer_event/landing_hero_web.svg`
export const REFERRAL_RINGLE_WHITE = `${CDN_URL}/new-theme/tutor/summer_event/ringle_white.svg`
export const REFERRAL_TEACH_FOR_ALL = `${CDN_URL}/new-theme/tutor/summer_event/teach_for_all.svg`
export const REFERRAL_LOGO_BG_WHITE_ROUNDED = `${CDN_URL}/new-theme/tutor/summer_event/logo_bg_white_rounded.svg`
export const REFERRAL_ICON_HOME = `${CDN_URL}/new-theme/tutor/summer_event/home.svg`
export const REFERRAL_ICON_FACEBOOK = `${CDN_URL}/new-theme/tutor/summer_event/facebook.svg`
export const REFERRAL_ICON_INSTAGRAM = `${CDN_URL}/new-theme/tutor/summer_event/instagram.svg`
export const REFERRAL_ICON_LINKEDIN = `${CDN_URL}/new-theme/tutor/summer_event/linkedin.svg`
export const REFERRAL_ICON_COPY_LINK = `${CDN_URL}/new-theme/tutor/summer_event/btn_copylink.svg`
export const REFERRAL_ICON_DOWNLOAD = `${CDN_URL}/new-theme/tutor/summer_event/btn_download_image.svg`
export const REFERRAL_ICON_EMAIL = `${CDN_URL}/new-theme/tutor/summer_event/btn_email.svg`
export const REFERRAL_ICON_SHARE_FACEBOOK = `${CDN_URL}/new-theme/tutor/summer_event/btn_facebook.svg`
export const REFERRAL_ICON_SHARE_INSTAGRAM = `${CDN_URL}/new-theme/tutor/summer_event/btn_instagram.svg`
export const REFERRAL_ICON_SHARE_LINKEDIN = `${CDN_URL}/new-theme/tutor/summer_event/btn_linkedin.svg`
export const REFERRAL_ICON_SHARE_WHATSAPP = `${CDN_URL}/new-theme/tutor/summer_event/btn_whats_app.svg`
export const REFERRAL_IMAGE_WORLD = `${CDN_URL}/new-theme/tutor/summer_event/image_world.svg`
export const REFERRAL_PORTAL_HELP_IMAGE = `${CDN_URL}/new-theme/tutor/summer_event/portal_help_image.svg`
export const REFERRAL_TEACH_FOR_ALL_ONLY_WHITE = `${CDN_URL}/new-theme/tutor/summer_event/taach_for_all_text_only_white.svg`
export const REFERRAL_TEACH_FOR_ALL_TEXT_BLACK = `${CDN_URL}/new-theme/tutor/summer_event/teach_for_all_text_black.svg`
export const REFERRAL_ICON_PARTICIPATE_PAPER_AIRPLANE = `${CDN_URL}/new-theme/tutor/summer_event/img_participate_1.svg`
export const REFERRAL_ICON_PARTICIPATE_LETTER = `${CDN_URL}/new-theme/tutor/summer_event/img_participate_2.svg`
export const REFERRAL_ICON_PARTICIPATE_DONATION = `${CDN_URL}/new-theme/tutor/summer_event/img_participate_3.svg`
export const REFERRAL_ICON_PARTICIPATE_MONEY = `${CDN_URL}/new-theme/tutor/summer_event/img_participate_4.svg`
export const REFERRAL_ICON_VIDEO = `${CDN_URL}/new-theme/tutor/summer_event/donwload_zoom.png`
export const REFERRAL_ICON_MOCK_SESSION_STEP1 = `${CDN_URL}/new-theme/tutor/summer_event/step_1_prepare.svg`
export const REFERRAL_ICON_MOCK_SESSION_STEP2 = `${CDN_URL}/new-theme/tutor/summer_event/step_2_ace_session.svg`
export const REFERRAL_ICON_MOCK_SESSION_STEP3 = `${CDN_URL}/new-theme/tutor/summer_event/step_3_complete_report.svg`
export const REFERRAL_ICON_SMILE = `${CDN_URL}/new-theme/tutor/_img_smile_active.svg`
export const REFERRAL_ICON_NO_SMILE = `${CDN_URL}/new-theme/tutor/img_smile_gray.svg`
export const REFERRAL_ICON_HAT = `${CDN_URL}/new-theme/tutor/hat.svg`
export const REFERRAL_ICON_MAIL_ABLE = `${CDN_URL}/new-theme/tutor/summer_event/mail_able.svg`
export const REFERRAL_ICON_MAIL_UNABLE = `${CDN_URL}/new-theme/tutor/summer_event/mail_unable.svg`
export const REFERRAL_ICON_MAIL_HOVER = `${CDN_URL}/new-theme/tutor/summer_event/mail_hover.svg`
export const REFERRAL_ICON_PERSON = `${CDN_URL}/new-theme/tutor/summer_event/person.svg`
export const REFERRAL_ICON_RINGLE_BLACK_LOGO = `${CDN_URL}/new-theme/tutor/summer_event/ringle_black_logo.svg`
export const REFERRAL_ICON_REWARD_DONE = `${CDN_URL}/new-theme/tutor/summer_event/reward_done_icon.svg`
export const REFERRAL_ICON_REWARD_PENDING = `${CDN_URL}/new-theme/tutor/summer_event/reward_pending_icon.svg`
export const REFERRAL_ICON_LETTER = `${CDN_URL}/new-theme/tutor/referral_letter.svg`

//2022 TUTOR - SEOUL CHALLENGE
//season1
export const SEOUL_CHALLENGE_PHASE_2_AIRPLANE = `${CDN_URL}/new-theme/tutor/seoul_challenge/seoul_challenge_phase_2_board_airplane.svg`
export const ICON_AIRPLANE_BLACK = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_airplane_black.svg`
export const ICON_AIRPLANE_STAMP_BLUE = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_airplane_stamp_blue.svg`
export const ICON_AIRPLANE_STAMP_GREEN = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_airplane_stamp_green.svg`
export const ICON_SEOUL_GIFT_GRAY = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_gift_gray.svg`
export const ICON_SEOUL_GIFT_COMPLETE = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_stamp_gift_complete.svg`
export const ICON_STAMP_BLANK = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_stamp_blank.svg`
export const ICON_STAMP_GIFT_GRAY = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_stamp_gift_gray.svg`
export const ICON_PROGRESS_PIN = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_progress_pin.svg`

export const ICON_ARROW_DOWN_SM_PRIMARY = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-down-sm-primary.svg`
export const ICON_ARROW_UP_SM_DANGER = `${CDN_URL}/new-theme/new-icons/${service}/icon/arrow-up-sm-danger.svg`
export const ICON_TRIANGLE_ALERT_DANGER = `${CDN_URL}/new-theme/new-icons/${service}/icon/triangle-alert-danger.svg`
//season2
export const ICON_SEASON_SUCCESS_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_season_sucess__season2.svg`
export const ICON_SEASON_SUCCESS_LAST_WEEK_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_season_sucess_last_week_season2.svg`
export const ICON_WEEK_SUCCESS_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_week_success_season2.svg`
export const ICON_SEASON_FAILURE_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_season_failure_season_2.svg`
export const ICON_SURPRISE_WEEK_GRAY_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_surprise_week_gray_season2.svg`
export const ICON_SEASON_TICKET_GRAY = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_season_ticket_gray.svg`
export const ICON_SEASON_FAILURE_GRAY_600_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_season_failure_grary_600_season_2.svg`
export const ICON_NOT_OPEN_SURPRISE_WEEK_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_not_open_surprise_week_season_2.svg`
export const ICON_SURPRISE_WEEK_SUCCESS_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_surprise_week_success_season_2.svg`
export const ICON_SURPRISE_WEEK_FAILURE_SEASON_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_surprise_week_failure_season_2.svg`
//season3
export const ICON_SEASON3_SEASON_SUCCESS = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_stamp__success.png`
export const ICON_SEASON3_WEEK_SUCCESS = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_stamp.png`
export const ICON_SEASON3_WEEK_FAILURE = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_stamp_gray.png`
export const ICON_SEASON3_MISSION_FAILURE = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_stamp_gray-1.png`
export const SEASON_3_COMPLETE_IMAGE = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_complete.png`
export const SEASON_3_BANNER = `${CDN_URL}/new-theme/tutor/seoul_challenge/season3_banner.png`

export const DOUBLE_QUOTATION_MARKS = `${CDN_URL}/new-theme/tutor/winter_referral/double_quotation.svg`
export const SEASON_OF_GIVING = `${CDN_URL}/new-theme/tutor/winter_referral_portal/event_name.svg`

export const ENGAGEMENT_REPEAT_BLACK = `${CDN_URL}/new-theme/tutor/engagement_event/repeat_black.svg`
export const ENGAGEMENT_LESSON_BLACK = `${CDN_URL}/new-theme/tutor/engagement_event/lesson_black.svg`
export const ENGAGEMENT_DOWNLOAD_BLACK = `${CDN_URL}/new-theme/tutor/engagement_event/download_black.svg`
export const ENGAGEMENT_CALENDER_BLACK = `${CDN_URL}/new-theme/tutor/engagement_event/calender_black.svg`
export const ENGAGEMENT_BOOK_BLACK = `${CDN_URL}/new-theme/tutor/engagement_event/book_black.svg`
export const BLOG_NO_RESULT = `${CDN_URL}/new-theme/student/blog/no_result.svg`
