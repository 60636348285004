// @ts-strict-ignore
import { useMemo } from 'react'
import { getJwtToken } from '../../modules/auth'
import LinkLocale from '../../modules/i18n/LinkLocale'
import urls from '../../modules/urls'
import { SIGN_UP_MODE } from '../../modules/vars'

export enum LOGIN_URL_TYPE {
  SIGNIN,
  SIGNUP,
  NEW,
}

type LoginLinkPropsType = {
  href: string
  children?: any
  mode?: any
  afterLogin?: any
  className?: any
  tutor?: any
  as?: any
  productid?: string
  [x: string]: any
}

const LoginLink = ({ href, children, mode, as, tutor, productid, afterLogin, ...others }: LoginLinkPropsType) => {
  const getRedirectUrlQuery = (mode = null, productid = null, afterLogin = null) => {
    if (mode || productid || afterLogin) {
      return (
        '?' +
        [
          { label: 'mode', value: mode },
          { label: 'productID', value: productid },
          { label: 'redirect_url', value: afterLogin && encodeURIComponent(afterLogin) },
        ]
          .filter((item) => item.value !== null)
          .map((item) => `${item.label}=${item.value}`)
          .join('&')
      )
    } else {
      return ''
    }
  }

  const getLoginType = (tutor, mode, afterLogin) => {
    if (tutor) return LOGIN_URL_TYPE.SIGNUP
    if (mode === SIGN_UP_MODE.PURCHASE) return LOGIN_URL_TYPE.NEW
    if (mode === SIGN_UP_MODE.WEBINAR) return LOGIN_URL_TYPE.NEW
    if (mode === SIGN_UP_MODE.MATERIAL_AD_SIGN_UP) return LOGIN_URL_TYPE.NEW
    if (mode === SIGN_UP_MODE.SIGN_IN) return LOGIN_URL_TYPE.SIGNIN
    if (afterLogin) return LOGIN_URL_TYPE.SIGNIN
    return LOGIN_URL_TYPE.NEW
  }

  const getSignUpUrl = (tutor, mode, afterLogin) => {
    switch (getLoginType(tutor, mode, afterLogin)) {
      case LOGIN_URL_TYPE.SIGNIN:
        return urls.student.auth.signIn + getRedirectUrlQuery(mode, productid, afterLogin)
      case LOGIN_URL_TYPE.SIGNUP:
        return urls.tutor.auth.signUp + getRedirectUrlQuery(mode, productid, afterLogin)
      case LOGIN_URL_TYPE.NEW:
        return urls.student.auth.new + getRedirectUrlQuery(mode, productid, afterLogin)
    }
  }

  const signUpUrl = useMemo(() => getSignUpUrl(tutor, mode, afterLogin), [tutor, mode, afterLogin])

  return !getJwtToken() ? (
    <LinkLocale href={signUpUrl} as={signUpUrl} {...others}>
      {children}
    </LinkLocale>
  ) : (
    <LinkLocale href={href} as={as} {...others}>
      {children}
    </LinkLocale>
  )
}

export default LoginLink
