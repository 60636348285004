import { QIcon_close_default } from '@repo/icons/index'
import { PropsWithChildren } from 'react'
import { COLORS } from '../../../modules/vars'

export interface CloseButtonProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
}
const DSTooltipCloseButton = (props: PropsWithChildren<CloseButtonProps>) => {
  const { setIsOpen, onClose } = props

  const onClickCloseButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen(false)
    onClose && onClose(e)
  }

  return (
    <button className="flex" onClick={(e) => onClickCloseButton(e)}>
      <QIcon_close_default width={16} height={16} color={COLORS.WHITE} />
    </button>
  )
}

export default DSTooltipCloseButton
