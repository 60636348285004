import { CSSProperties } from 'react'

export const enum TOOLTIP_TYPE {
  /**
   * 마운트시 노출
   * 트리거, 툴팁, 바깥 클릭하면 제거
   * 스크롤 제거
   */
  TEXT_ONLY_AUTO = 'text_only_auto',
  /**
   * 클릭시 노출, 재노출
   * 트리거, 툴팁, 바깥 클릭하면 제거
   * 스크롤 제거
   */
  TEXT_ONLY_CLICK = 'text_only_click',
  /**
   * 마운트시 노출
   * 트리거, X 클릭하면 제거
   */
  TEXT_CLOSE = 'text_close',
  /**
   * 마운트시 노출
   * 트리거, 툴팁, 바깥 클릭하면 제거
   * 스크롤 제거
   */
  TEXT_LINK = 'text_link',
  /**
   * 마운트시 노출
   * 트리거, X, 링크 클릭하면 제거
   */
  TEXT_CLOSE_LINK = 'text_close_link',
}

export const enum TOOLTIP_CLICK_TYPE {
  TRIGGER = 'trigger',
  LINK = 'link',
  TOOLTIP = 'tooltip',
  OUTSIDE = 'outside',
}
export interface GetClickTypeProps {
  e: MouseEvent
  tooltipContainerRef: React.MutableRefObject<HTMLDivElement>
  triggerRef: React.MutableRefObject<HTMLSpanElement>
  linkRef: React.MutableRefObject<HTMLDivElement>
}

export interface ControlTooltipProps {
  tooltipType: TOOLTIP_TYPE
  clickType: TOOLTIP_CLICK_TYPE
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * Interface for the properties of a DS Tooltip component.
 */
export interface DSTooltipProps {
  /**
   * Only works when tooltip does not have close / link button.
   */
  isOpenOnClick?: boolean

  /**
   * Position relative to the anchor element where the tooltip will be rendered.
   */
  place: 'top' | 'right' | 'bottom' | 'left'

  /**
   * Use if tooltip should be closed with close button.
   */
  hasCloseButton?: boolean

  /**
   * Use if tooltip content should be shown with multiline.
   */
  isMultiline?: boolean

  /**
   * Component for button that will trigger tooltip open.
   */
  triggerComponent: React.ReactNode

  /**
   * Component for tooltip content.
   */
  contentComponent: React.ReactNode

  /**
   * Component for tooltip link.
   *
   * You can use DSTooltip.Link component if you want.
   */
  linkComponent?: React.ReactNode

  /**
   * Children components.
   */
  children?: undefined

  /**
   * If true, the default handling of the tooltip will be ignored.
   */
  ignoreHandling?: boolean

  /**
   * Callback function to be called when the tooltip is closed.
   */
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void

  /**
   * Style properties for the tooltip.
   */
  style?: CSSProperties
}
