export enum LandingState {
  // Migrated from student.
  PORTAL = 'portal',
  LANDING = 'landing',
}

export enum TEENS_PORTAL_BTN_ID {
  // Migrated from student.
  APPLY_OT = 'click_apply_ot',
  BOOK_LESSON = 'click_book_lesson',
}

export enum TEENS_BTN_ID {
  // Migrated from student.
  CLICK_DROPDOWN_CREDITS = 'click_dropdown_credits',
  CLICK_TAB_BOOK_LESSON = 'click_tab_book_lesson',
  CLICK_SELECT_CREDIT = 'click_select_credit',
  CLICK_SELECT_TUTOR = 'click_select_tutor',
  CLICK_SELECT_TUTOR_AVATAR = 'click_select_tutor_avatar',
  CLICK_NEXT_LESSON_SCHEDULE = 'click_next_lesson_schedule',
  CLICK_MATERIAL_CATEGORY = 'click_material_category',
  CLICK_MATERIAL_HASHTAG = 'click_material_hashtag',
  CLICK_SEE_MATERIAL_DETAIL = 'click_see_material_detail',
  CLICK_SELECT_MATERIAL = 'click_select_material',
  CLICK_BOOKMARK_MATERIAL = 'click_bookmark_material',
  CLICK_SEARCH_MATERIAL = 'click_search_material',
  CLICK_EXIT_BOOK_LESSON = 'click_exit_book_lesson',
  CLICK_BOOK_ANOTHER_LESSON = 'click_book_another_lesson',
  CLICK_SET_LESSON_STYLE = 'click_set_lesson_style',
  CLICK_MENU_WHAT_IS_RINGLE = 'click_menu_what_is_ringle',
  CLICK_MENU_TUTOR = 'click_menu_tutor',
  CLICK_MENU_MATERIALS = 'click_menu_materials',
  CLICK_MENU_FEATURES = 'click_menu_features',
  CLICK_MENU_RINGLE_FOR = 'click_menu_ringle_for',
  CLICK_MENU_GLOBAL_PROFESSIONALS = 'click_menu_global_professionals',
  CLICK_MENU_OVERSEAS_RESIDENTS = 'click_menu_overseas_residents',
  CLICK_MENU_INTERNATIONAL_STUDENTS = 'click_menu_international_students',
  CLICK_MENU_BEGINNER_INTERMEDIATE = 'click_menu_beginner_intermediate',
  CLICK_MENU_BUSINESS_ENGLISH = 'click_menu_business_english',
  CLICK_MENU_INTERVIEW_PREP = 'click_menu_interview_prep',
  CLICK_MENU_RESUME_ESSAY = 'click_menu_resume_essay',
  CLICK_MENU_PRICING = 'click_menu_pricing',
  CLICK_MENU_CONFERENCE = 'click_menu_conference',
  CLICK_MENU_BUSINESS = 'click_menu_business',
  CLICK_MENU_HELP = 'click_menu_help',
  CLICK_MENU_PROMOTION = 'click_menu_promotion',
  CLICK_MENU_CHALLENGE = 'click_menu_challenge',
  CLICK_MENU_BECOME_A_TUTOR = 'click_menu_become_a_tutor',
  CLICK_MENU_CHANGE_LANG = 'click_menu_change_lang',

  CLICK_INSTALL = 'click_install',
  CLICK_INSTALL_APP = 'click_install_app',
  CLICK_SIGN_UP = 'click_sign_up',
  CLICK_SIGN_UP_METHOD = 'click_sign_up_method',
  CLICK_BOOK_A_CALL = 'click_book_a_call',
  CLICK_PRICING = 'click_pricing',
}

export enum TEENS_PAGE_VIEW_ID {
  // Migrated from student.
  PORTAL_LESSON_SCHEDULE = 'view_book_lesson_detail',
  LESSON_CLASSROOM = 'view_lesson_classroom',

  LANDING_HOME = 'view_landing_home',
  LANDING_EVENT = 'view_landing_event',

  LANDING = 'view_landing',
  SIGN_UP = 'view_sign_up',
  SIGN_UP_INPUT = 'view_sign_up_input',
  PORTAL_PRICING = 'view_portal_pricing',
  PORTAL_GIFT = 'view_portal_gift',
  PORTAL_PURCHASE = 'view_portal_purchase',
  PORTAL_WELCOME = 'view_portal_welcome',

  // Retention 24.03.11
  // https://ringle.atlassian.net/browse/PLUS-748
  HACKLE_CLICK_BOOKING_BTN = 'hackle_click_booking_btn',
  HACKLE_CLICK_BOOKING_FLOATING_BTN = 'hackle_click_booking_floating_btn',
  HACKLE_CLICK_BOOKING_COMPLETED = 'booking',
}
