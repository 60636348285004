import { QIcon_chevron_right } from '@repo/icons/index'
import { PropsWithChildren } from 'react'
import { COLORS } from '../../../modules/vars'

export interface LinkButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const DSTooltipLinkButton = ({ onClick, children = undefined }: PropsWithChildren<LinkButtonProps>) => {
  return (
    <button onClick={onClick} className="Caption-12Bd flex flex-row items-center">
      {children}
      <QIcon_chevron_right width={12} height={12} color={COLORS.WHITE} />
    </button>
  )
}

export default DSTooltipLinkButton
