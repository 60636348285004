import { useEffect, useState } from 'react'
import { ToastOptions, cssTransition, toast } from 'react-toastify'

// 새로운 토스트 컨테이너의 아이디
export const TOAST_CONTAINER_ID = 'new-design-system-toast-container'

const MOBILE_PADDING = '16px'
const DESKTOP_PADDING = '24px'

interface IUseToast {
  mobilePadding?: any
}

const useToast = ({ mobilePadding = MOBILE_PADDING }: IUseToast = {}) => {
  const [click, setClick] = useState<boolean>(false)

  // Function to get the child element's ID by index
  const getChildIdByIndex = (parent, index) => {
    if (!parent || !parent.children || parent.children.length <= index) {
      return null
    }

    const child = parent.children[index]
    return child ? child.id : null
  }

  // 새로운 토스트 컨테이너에서 첫 번째 자식 엘리먼트 가져오기
  const parentElement: HTMLElement =
    typeof document !== 'undefined' ? document?.getElementById(TOAST_CONTAINER_ID) : null
  const firstChildElement: Element = parentElement?.firstElementChild

  // 특정 인덱스의 자식 엘리먼트의 ID를 가져와서 해당 토스트를 닫는 함수 호출
  useEffect(() => {
    if (parentElement) {
      const targetIndex1 = 3 // Desired child element index (e.g., 3 for the fourth child)
      const targetIndex2 = 4 // Desired child element index (e.g., 4 for the fifth child)

      const childId1 = getChildIdByIndex(firstChildElement, targetIndex1)
      const childId2 = getChildIdByIndex(firstChildElement, targetIndex2)

      if (childId1) {
        toast.dismiss(childId1)
      }
      if (childId2) {
        toast.dismiss(childId2)
      }
    }
  }, [click])

  const MOBILE_ONE_LINE_HEIGHT = 48
  const DESKTOP_ONE_LINE_HEIGHT = 66

  const adjustChildElementsPadding = (firstChildElement) => {
    if (!firstChildElement) {
      return
    }
    const childElements = Array.from(firstChildElement.children) as HTMLElement[]

    for (const element of childElements) {
      const isMobile = window.innerWidth < 576
      const shouldAdjustPadding = isMobile
        ? element.clientHeight > MOBILE_ONE_LINE_HEIGHT
        : element.clientHeight > DESKTOP_ONE_LINE_HEIGHT

      if (window.screen.width < window.innerWidth) {
        if (shouldAdjustPadding) {
          if (isMobile) {
            element.style.padding = mobilePadding
          } else {
            element.style.padding = DESKTOP_PADDING
          }
        }
      } else {
        if (shouldAdjustPadding) {
          if (isMobile) {
            element.style.padding = mobilePadding
          } else {
            element.style.padding = DESKTOP_PADDING
          }
        }
      }
    }
  }

  const animation = cssTransition({
    enter: 'slideInUp',
    exit: 'fadeOut',
  })

  // 공통으로 사용하는 토스트 옵션 정의
  const commonToastOptions: ToastOptions = {
    autoClose: 3000,
    progress: undefined, // 진행바 사용 안함
    theme: 'light', // 테마 설정
    containerId: TOAST_CONTAINER_ID, // 새로운 토스트 컨테이너 아이디
    className: 'sm:w-[512px] absolute p-[12px] sm:p-[20px] rounded-[8px]', // 클래스명 설정
    bodyClassName: 'text-black Body2-14Md sm:Body1-16Md p-[0px]', // 바디 클래스명 설정
    transition: animation, // 애니메이션 효과 설정
    onOpen: () => adjustChildElementsPadding(parentElement?.firstElementChild),
  }
  const toastSuccess = (message: string, customConfig?: ToastOptions) => {
    if (typeof window === 'undefined') {
      return // Return early if there's no window object
    }
    setClick((prev) => !prev)

    toast(message, {
      ...commonToastOptions,
      type: 'success',
      className: `${commonToastOptions.className} ${customConfig?.className} bg-green-50 select-none`,
      bodyClassName: `${commonToastOptions.bodyClassName} ${customConfig?.bodyClassName} bg-green-50 select-none`,
      ...customConfig,
    })
  }

  const toastError = (message: string, customConfig?: ToastOptions) => {
    if (typeof window === 'undefined') {
      return // Return early if there's no window object
    }
    setClick((prev) => !prev)

    toast(message, {
      ...commonToastOptions,
      type: 'error',
      className: `${commonToastOptions.className} ${customConfig?.className} bg-red-50`,
      bodyClassName: `${commonToastOptions.bodyClassName} ${customConfig?.bodyClassName} bg-red-50 select-none`,
    })
  }

  const toastInfo = (message: string | React.ReactNode, customConfig?: ToastOptions) => {
    if (typeof window === 'undefined') {
      return // Return early if there's no window object
    }
    setClick((prev) => !prev)

    toast(message, {
      ...commonToastOptions,
      type: 'default',
      className: `${commonToastOptions.className} ${customConfig?.className} bg-gray-100 select-none`,
      bodyClassName: `${commonToastOptions.bodyClassName} ${customConfig?.bodyClassName} bg-gray-100 select-none`,
    })
  }

  return { toastInfo, toastSuccess, toastError }
}

export default useToast
