// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  session: {
    isLoggedIn: false,
    currentUser: null,
    token: null,
    currentGeo: {
      city: '',
      country: '',
      country_code: '',
      currency: '',
      timezone: '',
    },
  },
}
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    login(state, action) {
      const { currentUser, token } = action.payload
      state.session.currentUser = currentUser
      state.session.isLoggedIn = true
      state.session.token = token
    },
    updateUser(state, action) {
      const { currentUser, token } = action.payload
      state.session.currentUser = currentUser
      state.session.isLoggedIn = true
      state.session.token = token
    },
    updateCurrUser(state, action) {
      state.session.currentUser = action.payload
    },
    logout(state) {
      state.session.currentUser = {}
      state.session.isLoggedIn = false
      state.session.token = null
    },
    updateGeo(state, action) {
      const { currentGeo } = action.payload
      state.session.currentGeo = currentGeo
    },
  },
})

export const appReducer = appSlice.reducer
export const appActions = appSlice.actions
