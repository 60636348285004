import { format } from 'date-fns'
import { enUS, ko } from 'date-fns/locale'

const formatDate = (date, formatStr, locale) => {
  const locales = { enUS, ko }
  return format(new Date(date), formatStr, { locale: locales[locale] })
}

const DateComponent = ({ format = 'yyyy.MM.dd', date = null, locale = 'enUS', className = null, children = null }) => {
  return <span>{date ? formatDate(date, format, locale) : formatDate(children, format, locale)}</span>
}

export default DateComponent
