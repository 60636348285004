// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'
import { endOfWeek, format, set, startOfMonth, startOfWeek } from 'date-fns'
import { WEEK_OPTIONS_SUN, getWeekDaysSun } from '../modules/schedule/scheduleUtils'
import { timeStringFormat } from '../modules/schedule/tutorScheduleUtils'
import {
  getAdjustedDateTimeMMT,
  getDayFromStr,
  getHourFromStr,
  getMinuteFromStr,
  getMonthFromStr,
  getYearFromStr,
} from '../modules/utils'
import { TUTOR_SLOT_TYPES } from '../modules/vars'

const google = [
  {
    title: '',
    start_time: '',
    end_time: '',
    date: '',
    is_allday: true,
    total_events: [
      {
        title: '',
        date: '',
        duration: 0,
      },
    ],
  },
]

const opened = [
  {
    title: 'opened',
    start_time: '',
    end_time: '',
  },
  {
    title: 'opened',
    start_time: '',
    end_time: '',
  },
]

const scheduled = [
  {
    title: '',
    start_time: '',
    end_time: '',
    student_type: 'teens',
    tutor_confirmed: true,
    lesson_id: 12345,
    status: '',
  },
]

const response = {
  data: {
    google,
    opened,
    scheduled,
  },
  user_context: {
    google_check_user_consent: true,
    google_consent_email: '',
    google_oauth_consent_url: '',
    schedule_setting: {
      updated: '',
      max_hours_per_week: 0,
    },
    status_message: null,
    timezone: 'Asia/Seoul',
    should_update_schedule_setting: true,
    should_update_announcement: true,
  },
  operation_info: [],
  banner: {},
  peak_time_info: null,
}

function slice_event(event, timezone) {
  const start_time = getAdjustedDateTimeMMT(
    getYearFromStr(event.start_time),
    getMonthFromStr(event.start_time),
    getDayFromStr(event.start_time),
    getHourFromStr(event.start_time),
    getMinuteFromStr(event.start_time),
    0
  )

  const midnight = set(new Date(start_time), {
    hours: 24,
    minutes: 0,
    seconds: 0,
  })

  const end_time = getAdjustedDateTimeMMT(
    getYearFromStr(event.end_time),
    getMonthFromStr(event.end_time),
    getDayFromStr(event.end_time),
    getHourFromStr(event.end_time),
    getMinuteFromStr(event.end_time),
    0
  )

  if (end_time > midnight) {
    const event_until_today = {
      ...event,
      start_time: format(start_time, timeStringFormat),
      end_time: format(midnight, timeStringFormat),
    }

    const event_after_today = {
      ...event,
      start_time: format(midnight, timeStringFormat),
      end_time: format(end_time, timeStringFormat),
    }

    return [event_until_today].concat(slice_event(event_after_today, timezone))
  } else {
    return [event]
  }
}

function process(items: any[], timezone: any) {
  let sliced_events: any[] = []
  items?.forEach((event: { start_time: any; end_time: any }) => {
    sliced_events = sliced_events.concat(slice_event(event, timezone))
  })

  return sliced_events
}

const tutorScheduleSlice = createSlice({
  name: 'tutorSchedule',
  initialState: {
    peakHourLessons: 0,
    showMission2Toast: false,
    showMission3Toast: false,
    dailyCapInfo: [],
    fetchBaseDay: startOfWeek(new Date()),
    selectedWeekStartDay: startOfWeek(new Date()),
    selectedWeekEndDay: endOfWeek(new Date()),
    calendarMonth: startOfMonth(new Date()),
    selectedDays: [new Date()],
    scheduleInfo: { ...response },
    toggleStatus: { google: true, requested: true, unassigned: true },
    newLessonSlot: {
      selectedDay: null,
      // payload
      date: '',
      start: '',
      end: '',
      repeatedWeekdays: [],
      repeatEndDate: '',
    },
    google_user_info: {
      google_check_user_consent: false,
      google_oauth_consent_url: '',
      google_consent_email: '',
    },
    focusedLesson: { lesson_id: '', origin: '', start_time: '' },
    activeTab: TUTOR_SLOT_TYPES.REQUESTED,
    // raw lessons data from the server
    unassignedLessons: [],
    requestedLessons: [],
    requestedLessonGroups: [],
    unassignedLessonGroups: [],
  },
  reducers: {
    init(state, action) {
      state.fetchBaseDay = startOfWeek(action.payload, WEEK_OPTIONS_SUN)
      state.selectedDays = getWeekDaysSun(action.payload)
      state.selectedWeekStartDay = startOfWeek(action.payload, WEEK_OPTIONS_SUN)
      state.selectedWeekEndDay = endOfWeek(action.payload, WEEK_OPTIONS_SUN)

      state.scheduleInfo.data = {
        google,
        opened,
        scheduled,
      }
    },
    showMission2Toast(state, action) {
      state.showMission2Toast = action.payload
    },
    showMission3Toast(state, action) {
      state.showMission3Toast = action.payload
    },
    setPeakHourLessons(state, action) {
      state.peakHourLessons = action.payload
    },
    setUnassignedLessons(state, action) {
      state.unassignedLessons = action.payload
    },
    setRequestedLessons(state, action) {
      state.requestedLessons = action.payload
    },
    setResponse(state, action) {
      state.scheduleInfo.data.opened = process(action.payload.response?.opened_timeslots, action.payload.timezone)
      state.scheduleInfo.data.scheduled = process(action.payload.response?.lesson_timeslots, action.payload.timezone)
    },
    setScheduleInfo(state, action) {
      console.log(action, 'action')
      state.scheduleInfo.user_context = action.payload.tutor_info
      state.scheduleInfo.operation_info = action.payload.operation_info
      state.scheduleInfo.peak_time_info = action.payload.peak_times
    },
    setAddOpenedSlot(state, action) {
      state.scheduleInfo.data.opened = process(action.payload.response, action.payload.timezone)
    },
    setRemoveReopenableSlot(state, action) {
      const filteredSchedules = [...state.scheduleInfo.data.scheduled]?.filter(
        (item, i) => item.lesson_id !== action.payload
      )
      state.scheduleInfo.data.scheduled = filteredSchedules
    },
    setRemoveOpenedSlot(state, action) {
      state.scheduleInfo.data.opened = process(action.payload.response, action.payload.timezone)
    },
    initGoogleEvents(state) {
      state.scheduleInfo.data.google = []
    },
    setGoogleCalendar(state, action) {
      state.scheduleInfo.data.google = process(action.payload.google, action.payload.timezone)
      state.google_user_info = action.payload.userContext
    },
    setRevokeGoogleCalendar(state) {
      state.scheduleInfo.data.google = []
      state.scheduleInfo.user_context = {
        ...state.scheduleInfo.user_context,
        google_oauth_consent_url: '',
        google_check_user_consent: false,
        google_consent_email: '',
      }
    },
    setUserContext(state, action) {
      state.scheduleInfo.user_context.schedule_setting[action.payload.type] = action.payload[action.payload.type]
    },
    setStatusMsg(state, action) {
      state.scheduleInfo.user_context.status_message = action.payload
    },
    setBaseDay(state, action) {
      state.fetchBaseDay = startOfWeek(action.payload, WEEK_OPTIONS_SUN)
    },
    setWeek(state, action) {
      state.selectedWeekStartDay = startOfWeek(action.payload, WEEK_OPTIONS_SUN)
      state.selectedWeekEndDay = endOfWeek(action.payload, WEEK_OPTIONS_SUN)
    },
    setSelectedDays(state, action) {
      state.selectedDays = getWeekDaysSun(action.payload)
    },
    setCalendarMonth(state, action) {
      state.calendarMonth = action.payload
    },
    setToggleType(state, action) {
      state.toggleStatus[action.payload.type] = !state.toggleStatus[action.payload.type]
    },

    // 모달 내에서 슬랏 오픈시
    setNewLessonSlot(state, action) {
      state.newLessonSlot = action.payload
    },
    setNewLessonDay(state, action) {
      state.newLessonSlot.selectedDay = action.payload
      state.newLessonSlot.date = action.payload
    },
    setNewLessonRepeatEndDay(state, action) {
      state.newLessonSlot.repeatEndDate = action.payload
    },
    setNewLessonRepeatedDays(state, action) {
      state.newLessonSlot.repeatedWeekdays = action.payload
    },
    setNewLessonStartTime(state, action) {
      state.newLessonSlot.start = action.payload
    },
    setNewLessonEndTime(state, action) {
      state.newLessonSlot.end = action.payload
    },
    setFocusedLesson(state, action) {
      state.focusedLesson = action.payload
    },
    setRequestedLessonGroups(state, action) {
      state.requestedLessonGroups = action.payload
    },
    setAcceptLesson(state, action) {
      const type = action.payload.type == 'requested' ? 'requestedLessons' : 'unassignedLessons'
      const lessonListIdx = state[type].findIndex((item) => item.lesson_id == action.payload.lessonId)
      const weeklySlotIdx = state.scheduleInfo.data.scheduled.findIndex(
        (lesson) => lesson.lesson_id == action.payload.lessonId
      )
      const targetLesson = {
        ...state.scheduleInfo.data.scheduled[weeklySlotIdx],
        status: action.payload.confirmStatus,
      }
      if (lessonListIdx > -1) {
        // Lesson list partial state change
        state[type][lessonListIdx] = { ...state[type][lessonListIdx], confirm_status: action.payload.confirmStatus }
        // Weekly Slot partial state change
        state.scheduleInfo.data.scheduled = [
          ...state.scheduleInfo.data.scheduled.filter((lesson) => lesson.lesson_id != action.payload.lessonId),
          targetLesson,
        ]
      } else {
        return
      }
    },
    setUnassignedLessonGroups(state, action) {
      state.unassignedLessonGroups = action.payload
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload
    },
    setDailyCapInfo(state, action) {
      state.dailyCapInfo = action.payload
    },
    updateDailyCapInfo(state, action) {
      const dailyCapIdx = state.dailyCapInfo.findIndex((cap) => cap.date == action.payload.date)
      if (dailyCapIdx > -1) {
        state.dailyCapInfo[dailyCapIdx] = action.payload
      }
    },
  },
})

export const tutorScheduleReducer = tutorScheduleSlice.reducer
export const tutorScheduleActions = tutorScheduleSlice.actions
