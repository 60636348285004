import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { COLOR_KEY } from '../../../modules/vars'
import { useChipState } from '../modules/ChipStateContext'
import { CHIP_SIZE, CHIP_TYPE } from '../types'

export interface ChipLabelProps extends PropsWithChildren {
  className?: string
  customTxtColor?: COLOR_KEY
}

const ChipLabel = ({ children, className = '', customTxtColor = undefined }: ChipLabelProps) => {
  const { type, size, selected, disabled } = useChipState()
  return (
    <span
      className={classNames(styles.label({ type, size, selected, disabled }), className)}
      style={{
        color: customTxtColor,
      }}
    >
      {children}
    </span>
  )
}
export default ChipLabel
const styles = {
  label: ({ type, size, selected, disabled }) =>
    classNames(
      'whitespace-nowrap',
      size === CHIP_SIZE.XS && `Overline-10Bd`,
      size === CHIP_SIZE.SM && `Caption-12Bd`,
      size === CHIP_SIZE.MD && `Body2-14Bd`,
      size === CHIP_SIZE.LG && `Body1-16Bd`,
      type === CHIP_TYPE.ACTIONABLE && 'text-white',
      type !== CHIP_TYPE.ACTIONABLE && !selected && `text-black`,
      type !== CHIP_TYPE.ACTIONABLE && selected && `text-purple-500`,
      type === CHIP_TYPE.CHOICE && disabled && `text-gray-300`
    ),
}
