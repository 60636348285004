import classNames from 'classnames'

interface DSInputHelperTextProps {
  type?: 'error' | 'default'
  children: React.ReactNode
}

const DSInputHelperText = (props: DSInputHelperTextProps) => {
  const { children, type } = props
  return <span className={styles.text(type)}>{children}</span>
}

export default DSInputHelperText

const styles = {
  text: (type?: string) =>
    classNames(`text-[12px] font-medium leading-[20px]`, type === 'error' ? `text-red-500` : `text-gray-500`),
}
