export const APP_SCREEN_NAME = {
  STACK_SCREEN: 'StackScreen',
  OUTLINK_SCREEN: 'OutLinkScreen',
  LESSON_SCREEN: 'Lessons',
  LESSON_ENTER: 'LessonEnter',
  GATE_WAY: 'Gateway',
  PAYMENT_SCREEN: 'PaymentWebView',
  PortalHome: 'PortalHome',
  Tutors: 'Tutors',
  Materials: 'Materials',
  MyPageBottomSheet: 'MyPageBottomSheet',
  PastBottomSheet: 'PastBottomSheet',
  CreateChildAccount: 'CreateChildAccount',
  PastLesson: 'PastLesson',
  LessonPreparation: 'LessonPreparation',
  HomeworkDetail: 'HomeworkDetail',
  CreditBottomSheet: 'CreditBottomSheet',
  Dashboard: 'Dashboard',
  UploadFile: 'UploadFile',
  ChangeChildInfo: 'ChangeChildInfo',
  Profile: 'ProfilePage',
}
