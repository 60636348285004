import { useFeatureFlagDetail } from '@hackler/react-sdk'
import { format, isValid } from 'date-fns'
import { CurrentUser } from '../model/Common/common.interface'

type HackleFlagKey = typeof HACKLE_FLAG_KEY
type HackleFlagValue = HackleFlagKey[keyof HackleFlagKey]
export enum HACKLE_FLAG_KEY {
  SECRET_PRICING = 35,
  CURRICULUM = 46,
}
export enum HACKLE_A_B_TESTING_KEY {
  PRICING_PAGE_NEW = 42,
}
export const HACKLE_FALLBACK_VALUES = {
  ...Object.values(HACKLE_FLAG_KEY).map((key) => ({ [key]: false })),
  // 여기에 Hackle이 Offline이거나 에러발생시에도 사용할 기능들을 추가
}
export const useFeatureFlag = (featureNumber: HackleFlagValue) => {
  const { isOn, reason } = useFeatureFlagDetail(featureNumber)
  /**
   * https://docs-kr.hackle.io/docs/react-feature-flag
   * DEFAULT_RULE: 개별 타겟팅, 사용자 타겟팅 중 어디에도 매치 되지 않았습니다.
   * INVALID_INPUT: (예: 숫자 타입을 넣어야 하는 파라미터에 문자 타입 입력)
   */
  const invalidReasons = ['SDK_NOT_READY', 'FEATURE_FLAG_NOT_FOUND', 'EXCEPTION', 'INVALID_INPUT']
  const isInvalid = invalidReasons.findIndex((v) => v === reason) >= 0
  return isInvalid ? HACKLE_FALLBACK_VALUES[featureNumber] : isOn
}

/**
 * 해클에 넘길 유저 관련 데이터를 생성하는 함수
 * @param userData CurrentUser
 * @returns Hackle용 유저 데이터
 */
export const getHackleUserData = (
  userData: CurrentUser,
  creditCount: number
): Record<string, string | number | object> | undefined => {
  if (userData) {
    return {
      userId: `${userData.id}`,
      properties: {
        userId: userData.id,
        timezone: userData.timezone,
        createdAt: userData.created_at,
        created_date: isValid(new Date(userData.created_at))
          ? format(new Date(userData.created_at), 'yyyyMMdd')
          : format(new Date(), 'yyyyMMdd'),
        locale: userData.locale,
        unusedCreditCount: creditCount,
      },
    }
  } else {
    return undefined
  }
}
