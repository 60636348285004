import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ICurrentGeo } from '../../../model/Common/common.interface'
import { currentGeoQueryApi } from '../../react-query/queryApis'

export const useCurrentGeo = () => {
  return useQuery<ICurrentGeo, AxiosError>({
    queryKey: currentGeoQueryApi.getQueryKey(),
    queryFn: currentGeoQueryApi.getQueryFn(),
  })
}
