export const DEFAULT_LOCALE = 'ko' as const
export const LOCALE_EN = 'en' as const
export const LOCALE_KO = 'ko' as const
export const LOCALE_KEY = 'key' as const
export const SUPPORTED_LOCALES = [LOCALE_KO, LOCALE_EN, LOCALE_KEY] as const
export const LANGUAGE_NAMES = {
  en: 'English',
  ko: '한국어',
}

export type LocaleType = (typeof SUPPORTED_LOCALES)[number]

export function isSupportedLocale(tested: string): tested is LocaleType {
  return SUPPORTED_LOCALES.some((locale) => locale === tested)
}

export function localeIndex(locale: LocaleType) {
  switch (locale) {
    case LOCALE_KO:
      return 0
    case LOCALE_EN:
      return 1
    case LOCALE_KEY:
      return 2
    default:
      return LOCALE_KO
  }
}
