import axios from 'axios'
import Cookies from 'universal-cookie'
import { createAxiosTokenConfig } from '../apis/axios-util'
import { getJwtToken } from '../auth'
import { COOKIE_LIST } from '../vars'
import { ApiType, ConstructorType, QueryFnPropsType, QueryKeyPropsType } from './queryApiTypes'

// * =========================================================================== */
// *
// * utils *
// *
// * =========================================================================== */

/**
 * Axios Get Util Function
 * - Takes queryKey as input (url & param)
 * - Uses the query key url for GET url and query key param for axios params
 * @param url: string
 * @param params: object
 * @returns T
 */
const axiosGetForQuery = async <T>(url: string, params?: object): Promise<T> => {
  const token = getJwtToken()
  const cookies = new Cookies()
  const cafToken = cookies.get(COOKIE_LIST.CAF_TOKEN)
  const { data } = await axios.get<T>(url, { params: { ...params }, ...createAxiosTokenConfig(token || cafToken) })
  console.log('%c--AXIOS QUERY RESPONSE--', 'background: blue; color: white')
  console.log('%cQUERY KEY', 'color: blue', [url, params])
  console.log('%cQUERY DATA', 'color: blue', data)
  return data
}

/**
 * Determine whether an object has pathParams field
 * @param url: string
 * @param params: object
 * @returns T
 */
function hasPathParmsField(props: any): props is { pathParams: any } {
  return props?.pathParams !== undefined
}

/**
 * Determine whether an object has searchParams field
 * @param url: string
 * @param params: object
 * @returns T
 */
function hasSearchParmsField(props: any): props is { searchParams: any } {
  return props?.searchParams !== undefined
}

// * =========================================================================== */
// *
// * QueryApi class definition *
// *
// * =========================================================================== */

class QueryApi<PathParamsType = void, SearchParamsType = void> {
  api: ApiType<PathParamsType>

  constructor({ api }: ConstructorType<PathParamsType>) {
    this.api = api
  }

  getQueryKey(props: QueryKeyPropsType<PathParamsType, SearchParamsType>) {
    if (hasPathParmsField(props)) {
      const { pathParams } = props

      if (hasSearchParmsField(props)) {
        const { searchParams } = props
        return searchParams ? [this.api(pathParams), searchParams] : [this.api(pathParams)]
      }

      return [this.api(pathParams)]
    }

    const api = this.api as ApiType<void>

    if (hasSearchParmsField(props)) {
      const { searchParams } = props
      return searchParams ? [api(), searchParams] : [api()]
    }

    return [api()]
  }

  getQueryFn<T = unknown>(props: QueryFnPropsType<PathParamsType, SearchParamsType>) {
    if (hasPathParmsField(props)) {
      const { pathParams } = props

      if (hasSearchParmsField(props)) {
        const { searchParams } = props
        return () => axiosGetForQuery<T>(this.api(pathParams), searchParams)
      }

      return () => axiosGetForQuery<T>(this.api(pathParams))
    }

    const api = this.api as ApiType<void>

    if (hasSearchParmsField(props)) {
      const { searchParams } = props
      return () => axiosGetForQuery<T>(api(), searchParams)
    }

    return () => axiosGetForQuery<T>(api())
  }
}

export default QueryApi
