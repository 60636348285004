import { useEffect, useState } from 'react'

export default function useIsTablet() {
  const TABLET_SIZE = 720
  const [isTablet, setIsTablet] = useState(false)

  const handleResize = () => {
    if (window.screen.width < window.innerWidth) {
      setIsTablet(window.screen.width < TABLET_SIZE)
    } else {
      setIsTablet(window.innerWidth < TABLET_SIZE)
    }
  }

  useEffect(() => {
    setIsTablet(window.innerWidth < TABLET_SIZE)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return [isTablet, setIsTablet]
}
