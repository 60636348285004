import classNames from 'classnames'

const Span = (props) => {
  const {
    className,
    block,
    sm,
    bold,
    primary,
    deepPrimary,
    secondary,
    relative,
    absolute,
    cursorPointer,
    cursorDefault,
    badgeDanger,
    white,
    yellow,
    warning,
    danger,
    black,
    success,
    muted,
    center,
    italic,
    underline,
    textLine,
    gold,
    info,
    m0,
    m1,
    m2,
    m3,
    m4,
    m5,
    mt0,
    mt1,
    mt2,
    mt3,
    mt4,
    mt5,
    mb0,
    mb1,
    mb2,
    mb3,
    mb4,
    mb5,
    mr0,
    mr1,
    mr2,
    mr3,
    mr4,
    mr5,
    ml0,
    ml1,
    ml2,
    ml3,
    ml4,
    ml5,
    d3,
    d4,
    d5,
    d6,
    h1,
    h2,
    h3,
    h4,
    h5,
    capitalize,
    uppercase,
    size,
    lineHeight,
    narrow,
    narrower,
    ...others
  } = props
  return (
    <span
      className={classNames(className, {
        'd-block': block,
        'badge badge-red-500': badgeDanger,
        'position-absolute': absolute,
        'position-relative': relative,
        'text-bold': bold,
        'text-purple-500': primary || secondary,
        'text-white': white,
        'text-blue-500': info,
        'text-red-500': danger,
        'text-yellow-500': warning,
        'text-muted': muted,
        'text-center': center,
        'text-green-500': success,
        'text-yellow': yellow,
        'text-black': black,
        'text-color-yellow': gold,
        'text-deep-primary': deepPrimary,
        'font-size-sm': sm,
        'font-italic': italic,
        'text-decoration-underline': underline,
        'cursor-default': cursorDefault,
        'cursor-pointer': cursorPointer,
        'text-decoration-through': textLine,
        h1: h1,
        h2: h2,
        h3: h3,
        h4: h4,
        h5: h5,
        'm-1': m1,
        'm-2': m2,
        'm-3': m3,
        'm-4': m4,
        'm-5': m5,
        'mt-0': mt0,
        'mt-1': mt1,
        'mt-2': mt2,
        'mt-3': mt3,
        'mt-4': mt4,
        'mt-5': mt5,
        'mb-0': mb0,
        'mb-1': mb1,
        'mb-2': mb2,
        'mb-3': mb3,
        'mb-4': mb4,
        'mb-5': mb5,
        'ml-0': ml0,
        'ml-1': ml1,
        'ml-2': ml2,
        'ml-3': ml3,
        'ml-4': ml4,
        'ml-5': ml5,
        'mr-0': mr0,
        'mr-1': mr1,
        'mr-2': mr2,
        'mr-3': mr3,
        'mr-4': mr4,
        'mr-5': mr5,
        'display-3': d3,
        'display-4': d4,
        'display-5': d5,
        'display-6': d6,
      })}
      style={{
        ...(size && { fontSize: size }),
        ...(lineHeight && { lineHeight: lineHeight }),
        ...(capitalize && { textTransform: 'capitalize' }),
        ...(uppercase && { textTransform: 'uppercase' }),
        ...(narrow && { letterSpacing: '-0.03em' }),
        ...(narrower && { letterSpacing: '-0.05em' }),
      }}
      {...others}
    >
      {props.children}
    </span>
  )
}
export default Span
