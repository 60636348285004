// @ts-strict-ignore
import { add, addDays, differenceInMilliseconds, differenceInMinutes, getWeek, startOfWeek } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { STUDENT_TYPE, TUTOR_SLOT_TYPES } from '../vars'
import { getAbsoluteMinute } from './scheduleUtils'

export const getAllDaysInTheWeek = (currentDate) => {
  const weekStart = startOfWeek(currentDate, { weekStartsOn: 0 }) // 일요일부터 시작하는 것으로 가정
  const days = Array.from({ length: 7 }).map((_, index) => {
    const currentDate = addDays(weekStart, index)
    return {
      date: currentDate.getDate(),
      dateStamp: currentDate.getTime(),
      weekDayName: format(currentDate, 'eee'),
    }
  })
  return days
}
// 0 AM-11 PM
export const times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

export const CELL_OFFSET = -2
export const FIRST_CELL = 7 //%
export const CELL_WIDTH = (100 - FIRST_CELL) / 7
export const RIGHT_MARGIN_UNIT = 1.2

export const generateWeekViewCoordinates = (event, startDate, type, dayOfWeek, currentUser) => {
  if (type == 'plus') {
    console.log(event, startDate, dayOfWeek, 'event')
  }
  const timezone = currentUser?.timezone
  const start = utcToZonedTime(new Date(event.start_time), timezone)
  const end = utcToZonedTime(new Date(event.end_time), timezone)
  const duration = differenceInMilliseconds(end, start)
  const weekStart = new Date(startDate)

  const top = (getAbsoluteMinute(event.start_time) / 60) * 100
  const hours = Math.floor(duration / (1000 * 60 * 60))
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
  const days = Math.floor(duration / (1000 * 60 * 60 * 24))

  const timeFactor = hours + minutes / 60 + days * 24
  const height = timeFactor * 100
  const weekDay = dayOfWeek
  const left = weekDay * CELL_WIDTH + FIRST_CELL

  const now = utcToZonedTime(new Date(), timezone)
  const diffStartInMinutes = differenceInMinutes(start, now)
  const isStartWithOver2Hours = diffStartInMinutes >= 120

  let width = CELL_WIDTH - RIGHT_MARGIN_UNIT

  // Additional logic remains the same, calculations for width based on conditions
  const startWeekNumber = getWeek(start, { weekStartsOn: 0 })
  const endWeekNumber = getWeek(end, { weekStartsOn: 0 })
  const weekStartNumber = getWeek(weekStart, { weekStartsOn: 0 })

  if (weekStartNumber > startWeekNumber && weekStartNumber === endWeekNumber) {
    width = CELL_WIDTH - RIGHT_MARGIN_UNIT
  }

  const getZIndex = () => {
    if (event.type == 'LAST_MINUTE' && type === TUTOR_SLOT_TYPES.OPENED && isStartWithOver2Hours) {
      return 3000
    }
    if (type === TUTOR_SLOT_TYPES.GOOGLE) {
      return 9
    }
    if (event.status === TUTOR_SLOT_TYPES.UNASSIGNED) {
      return 1000
    }
    if (type === TUTOR_SLOT_TYPES.OPENED) {
      return 10
    }
    if (type === STUDENT_TYPE.PLUS) {
      return 1000
    }
    if (type === STUDENT_TYPE.TEENS) {
      return 1000
    }
    if (type === 'FOCUS_ARROW') {
      return 2000
    } else return 5
  }

  const getWidth = () => {
    switch (type) {
      case TUTOR_SLOT_TYPES.GOOGLE:
        return `${width}%`
      case TUTOR_SLOT_TYPES.OPENED:
        return `${width - 2}%`
      case STUDENT_TYPE.PLUS:
        return `${width - 4}%`
      case STUDENT_TYPE.TEENS:
        return `${width - 4}%`
      case 'FOCUS_ARROW':
        return `calc(${width - 4}% + 18px)`

      default:
        return `${width}%`
    }
  }

  const getLeftValue = () => {
    switch (type) {
      case TUTOR_SLOT_TYPES.GOOGLE:
        return `calc(${left}% - ${CELL_OFFSET}px)`
      case TUTOR_SLOT_TYPES.OPENED:
        return `calc(${left + 2}% - ${CELL_OFFSET}px)`
      case STUDENT_TYPE.PLUS:
        return `calc(${left + 4}% - ${CELL_OFFSET}px)`
      case STUDENT_TYPE.TEENS:
        return `calc(${left + 4}% - ${CELL_OFFSET}px)`
      case 'FOCUS_ARROW':
        return `calc(${left + 4}% - ${CELL_OFFSET}px)`
      default:
        return `${left}%`
    }
  }

  // 구캘
  return {
    left: getLeftValue(),
    width: getWidth(),
    height: `calc(${height}% - ${2}px)`,
    top: top + '%',
    zIndex: getZIndex(),
  }
}

export const isTodaysDate = (dateStamp, currentUser) => {
  const timezone = currentUser?.timezone || 'UTC' // Default to UTC if timezone is not specified

  return (
    new Date(dateStamp).getDate() == utcToZonedTime(new Date(), timezone).getDate() &&
    new Date(dateStamp).getMonth() == utcToZonedTime(new Date(), timezone).getMonth()
  )
}

export const daysOfWeeksEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const timeStringFormat = "yyyy-MM-dd'T'HH:mm:ss"

// Thu~ Sat : initially focus next week!
export const THU_TO_SAT = (timezone: string) => {
  return zonedTimeToUtc(new Date(), timezone).getDay() >= 4
}

export const initialFocusDay = (timezone: string) => {
  return THU_TO_SAT(timezone)
    ? format(zonedTimeToUtc(add(new Date(), { days: 7 }), timezone), timeStringFormat)
    : format(zonedTimeToUtc(new Date(), timezone), timeStringFormat)
}
