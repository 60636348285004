import { create, StateCreator } from 'zustand'
import { persist } from 'zustand/middleware'

const middleware = (f: StateCreator<TrialState>) => persist<TrialState>(f, { name: 'nextjs' })

interface TrialState {
  price: number
  course: any
  tutor: any
  date: {
    classtimeId: any
    formattedTime: string
    day: any
    isToday: boolean
    startTime: any
  }
  isAutoMatching: boolean
  lastActiveTime: any
  init: () => void
  initTime: () => void
  initTutor: () => void
  initCourse: () => void
  initIsAutoMatching: () => void
  initLastActiveTime: () => void
  setTutor: (tutor: any) => void
  setCourse: (course: any) => void
  setDay: (day: any) => void
  setTime: (payload: any) => void
  setCurrentPrice: (price: number) => void
  setIsAutoMatching: (isAutoMatching: boolean) => void
  setLastActiveTime: (lastActiveTime: any) => void
}

const initialState = {
  price: 24000,
  course: null,
  tutor: null,
  date: {
    classtimeId: null,
    formattedTime: '',
    day: null,
    isToday: false,
    startTime: null,
  },
  isAutoMatching: false,
  lastActiveTime: null,
}

const useTrialStore = create<TrialState>()(
  middleware((set) => ({
    ...initialState,
    init: () => set(initialState),
    initTime: () =>
      set((state) => ({
        date: {
          ...state.date,
          classtimeId: null,
          formattedTime: '',
          day: null,
          isToday: false,
          startTime: null,
        },
      })),
    initTutor: () => set({ tutor: null }),
    initCourse: () => set({ course: null }),
    initIsAutoMatching: () => set({ isAutoMatching: false }),
    initLastActiveTime: () => set({ lastActiveTime: null }),
    setTutor: (tutor) => set({ tutor }),
    setCourse: (course) => set({ course }),
    setDay: (day) =>
      set((state) => ({
        date: {
          ...state.date,
          day,
        },
      })),
    setTime: (payload) =>
      set((state) => ({
        date: {
          ...state.date,
          formattedTime: payload.formatted_time,
          classtimeId: payload.classtime_id,
          isToday: payload.is_today,
          startTime: payload.start_time,
        },
      })),
    setCurrentPrice: (price) => set({ price }),
    setIsAutoMatching: (isAutoMatching) => set({ isAutoMatching }),
    setLastActiveTime: (lastActiveTime) => set({ lastActiveTime }),
  }))
)

export default useTrialStore
