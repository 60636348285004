import { useCallback } from 'react'
import { COOKIE_DOMAIN, RINGLE_DOMAIN } from '../../../envVars'
import useTranslation from '../../../i18n/useTranslation'

const useReturnFullUrl = () => {
  const { locale } = useTranslation()

  // 환경 변수 및 도메인 설정을 개선한 버전
  const domain = useCallback(() => {
    const isDevelopment = process.env.NODE_ENV === 'development'
    const isStaging = COOKIE_DOMAIN?.includes('staging')
    const isRingleFront = COOKIE_DOMAIN?.includes('ringlefront')

    if (isDevelopment) {
      if (process.env.IS_DEV_HTTPS && typeof process.env.IS_DEV_HTTPS != 'undefined') {
        return 'https://local.ringleplus.com:3000'
      } else {
        return 'http://local.ringleplus.com:3000'
      }
      // return 'http://10.0.2.2:3000' // 안드로이드 로컬 돌릴 때
    } else if (isStaging || isRingleFront) {
      return `https://${RINGLE_DOMAIN}`
    } else {
      return 'https://www.ringleplus.com'
    }
  }, []) // 환경 변수가 변경되지 않는 한 이 함수는 재생성되지 않습니다.

  // 함수를 반환할 때 useCallback을 사용하여 재생성을 방지
  const returnFullUrl = useCallback(
    (url) => {
      return `${domain()}/${locale}${url}`
    },
    [domain, locale]
  )

  return returnFullUrl
}

export default useReturnFullUrl
