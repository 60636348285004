import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ICurrentGeo } from '../../../model/Common/common.interface'
import { currentGeoQueryApi } from '../../react-query/queryApis'
import { COUNTRY_ALPHA2_CODE } from '../../vars'

const initialCurrentGeo: ICurrentGeo = {
  city: '',
  country: '',
  country_code: COUNTRY_ALPHA2_CODE.KR,
  currency: '',
  timezone: '',
}

export const useCurrentGeo = () => {
  return useQuery<ICurrentGeo, AxiosError>({
    queryKey: currentGeoQueryApi.getQueryKey(),
    queryFn: currentGeoQueryApi.getQueryFn(),

    initialData: initialCurrentGeo,
  })
}
