import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import apis from '../../../../apis'
import { getJwtToken } from '../../../../auth'
import QueryApi from '../../../../react-query/queryApiClass'

import { IMaterialDataCourse } from '../../../../../components/modal/MaterialBottomSheet'
import { ITutor } from '../../../../../model/tutor.interface'
import { axiosPost } from '../../../../axiosHelper'
import { IRecommendTutors } from './types'

interface RecommendedTutor {
  tutor_id: number
  score: number
  explanation: Explanation
  recommend: boolean
}

interface Explanation {
  beginner?: number
}

interface TutorRecommendations {
  recommended_tutors: RecommendedTutor[]
}

interface IRecommendTutorSearchParams {
  tutor_ids: number[]
  mode: string
}

export const useRecommendTutors = () => {
  return useMutation<TutorRecommendations, AxiosError, IRecommendTutorSearchParams>({
    mutationFn: (payload) => {
      return axiosPost(apis.tutor.fitPurpose(), payload)
    },
  })
}

interface PurposeContent {
  id: number
  plus_studying_english_purpose_category_id: number
  title_key: string
  need_content: boolean
  placeholder_key: null | string
  recommend_course_type: null | string
  active: boolean
  created_at: string
  updated_at: string
  recommend_course_ids: number[]
  key: string
}

interface IOnboardingResponse {
  purpose_content: PurposeContent
  materials: IMaterialDataCourse[]
}
const onboardingQueryApi = new QueryApi<void>({
  api: apis.onboarding.get,
})
export const useOnboardingCourseList = () => {
  const token = getJwtToken()

  return useQuery<IOnboardingResponse, AxiosError>({
    queryKey: onboardingQueryApi.getQueryKey(),
    queryFn: onboardingQueryApi.getQueryFn(),
    // onError: (error) => handleAxiosError(error),
    enabled: !!token,
    refetchOnWindowFocus: false,
  })
}

interface IOnboardingResponse {
  purpose_content: PurposeContent
  materials: IMaterialDataCourse[]
  lessons: any
}
interface IOnboardingQueryApiSearchParams {
  lesson_id: number
}
const onBoardingRecommendLessonsQueryApi = new QueryApi<void, IOnboardingQueryApiSearchParams>({
  api: apis.onboarding.recommendLessons,
})
export const useOnboardingRecommendLessons = (lesson_id = null) => {
  const token = getJwtToken()

  const searchParams = { lesson_id }

  return useQuery<IOnboardingResponse, AxiosError>({
    queryKey: onBoardingRecommendLessonsQueryApi.getQueryKey({ searchParams }),
    queryFn: onBoardingRecommendLessonsQueryApi.getQueryFn({ searchParams }),
    // onError: (error) => handleAxiosError(error),
    enabled: !!token,
    refetchOnWindowFocus: false,
  })
}
interface IFeaturedTutoQueryApiSearchParams {
  duration: number
}
const featuredTutorsQueryApi = new QueryApi<void, IFeaturedTutoQueryApiSearchParams>({
  api: apis.tutor.featured,
})
export const useFeaturedTutorList = (duration) => {
  const token = getJwtToken()

  const searchParams = { duration }

  return useQuery<ITutor[], AxiosError>({
    queryKey: featuredTutorsQueryApi.getQueryKey({ searchParams }),
    queryFn: featuredTutorsQueryApi.getQueryFn({ searchParams }),
    // onError: (error) => handleAxiosError(error),
    enabled: !!token && !!duration,
    refetchOnWindowFocus: false,
  })
}

interface ITutorsQueryApiSearchParams {
  page: number
  size: number
}
const recommendTutorsQueryApi = new QueryApi<void, ITutorsQueryApiSearchParams>({
  api: apis.tutor.recommend,
})
export const useRecommendTutorList = () => {
  const token = getJwtToken()

  const searchParams = { page: 1, size: 6 }

  return useQuery<IRecommendTutors, AxiosError, ITutor[]>({
    queryKey: recommendTutorsQueryApi.getQueryKey({ searchParams }),
    queryFn: recommendTutorsQueryApi.getQueryFn({ searchParams }),
    // onError: (error) => handleAxiosError(error),
    select: (data) => data.tutors,
    enabled: !!token,
    refetchOnWindowFocus: true,
  })
}

const allTutorsQueryApi = new QueryApi<void, ITutorsQueryApiSearchParams>({
  api: apis.tutor.allTutors,
})
export const useAllTutorList = () => {
  const token = getJwtToken()

  const searchParams = { page: 1, size: 6 }

  return useQuery<IRecommendTutors, AxiosError>({
    queryKey: allTutorsQueryApi.getQueryKey({ searchParams }),
    queryFn: allTutorsQueryApi.getQueryFn({ searchParams }),
    // onError: (error) => handleAxiosError(error),
    enabled: !!token,
    refetchOnWindowFocus: true,
  })
}

export const materialTopTutorsQueryApi = new QueryApi<{ materialId: string | string[] }, void>({
  api: apis.course.courseTopTutors,
})

export const useMaterialTopTutors = (materialId: string | string[]) => {
  const pathParams = { materialId }
  return useQuery<any, AxiosError>({
    queryKey: materialTopTutorsQueryApi.getQueryKey({ pathParams }),
    queryFn: materialTopTutorsQueryApi.getQueryFn({ pathParams }),

    // onError: (e) => handleAxiosError(e),
    retry: false,
  })
}

export const allMaterialsQueryApi = new QueryApi({
  api: apis.matching.materials.index,
})

export const useAllMaterials = () => {
  return useQuery<any, AxiosError>({
    queryKey: allMaterialsQueryApi.getQueryKey(),
    queryFn: allMaterialsQueryApi.getQueryFn(),
    select: (data) => data.lower_hashtags.map((item: any) => item.count).reduce((a: number, b: number) => a + b, 0),
    // onError: (e) => handleAxiosError(e),
    retry: false,
  })
}
