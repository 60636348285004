// @ts-strict-ignore
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
import fetch from 'isomorphic-unfetch'
import { NextRouter, useRouter } from 'next/router'
import { Cookies, useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import { GTM_EVENT, sendGTMEvent } from '../helpers/gtmHelper'
import { MIDDLEWARE_COOKIE_DELETE_FLAG } from '../helpers/middleware/middlewareVars'
import { CurrentUser } from '../model/Common/common.interface'
import apis from '../modules/apis'
import {
  COOKIE_OPT,
  COOKIE_OPT_FOR_ACCESS_TOKEN,
  FACEBOOK_APP_ID,
  TEENS_ACCOUNT_TYPE,
  TEENS_SESSION_ROLE,
} from '../modules/vars'
import { axiosPost } from './axiosHelper'
import { useCurrentUser } from './context/UserContext'
import { getEnv } from './envUtil'
import useIsMobile from './hooks/common/useIsMobile'
import { LocaleType } from './i18n/config'
import { getInitialLocale } from './i18n/getInitialLocale'
import { userQueryApi } from './react-query/queryApis'
import { TEENS_URL, getUserType, setCookieOnMiddleware } from './routing'
import urls from './urls'
import { locationMove } from './utils'
import { COOKIE_LIST } from './vars'

export const BY_PARENT = (currentUser: CurrentUser) => currentUser?.by_parent // 틴즈 부모로 로그인 여부
export const IS_PARENT = (currentUser: CurrentUser) => currentUser?.session_role === TEENS_SESSION_ROLE.PARENT // 틴즈 부모 여부
export const IS_CHILD = (currentUser: CurrentUser) => currentUser?.session_role === TEENS_SESSION_ROLE.CHILD // 틴즈 학생 여부

export const teensError = (errCond: boolean, text: string, target: any) => {
  if (errCond) console.error('TEENS_TEENS_TEENS', text, target)
}

export const useInvalidateCurrentUser = () => {
  const queryClient = useQueryClient()

  return async () => {
    await queryClient.invalidateQueries({ queryKey: userQueryApi.getQueryKey() })
  }
}

export const useLogin = () => {
  const [cookies, , removeCookie] = useCookies([COOKIE_LIST.LOGIN_AFTER_URL])
  const router = useRouter()
  const isMobile = useIsMobile()

  return async ({ signInEmailResponse }) => {
    if (!signInEmailResponse) {
      alert(signInEmailResponse.error)
      return
    }
    if (!signInEmailResponse.jwt_token) {
      alert('Error')
      return
    }
    removeCookie(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)
    removeCookie(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)

    const moveUrl = cookies[COOKIE_LIST.LOGIN_AFTER_URL]

    const newToken = signInEmailResponse.jwt_token
    const newSessionRole = getUserType(signInEmailResponse.user_type)

    console.info('Sign_in', {
      provider: 'email',
      service: 'teens',
      platform: isMobile ? 'mobile_web' : 'desktop',
    })

    const pathname =
      '/' +
      getInitialLocale() +
      (newSessionRole == 'parent'
        ? moveUrl
          ? moveUrl
          : isMobile
          ? TEENS_URL.PRICING
          : TEENS_URL.GATEWAY
        : TEENS_URL.PORTAL_HOME)

    const cookiesToSet = {
      jwt_token: newToken,
      session_role: newSessionRole,
    }

    await setCookieOnMiddleware({ router, pathname, cookiesToSet })
  }
}

export const useLogout = () => {
  const [, , removeCookie] = useCookies([])
  const router = useRouter()

  return async () => {
    // sentry user info
    Sentry.setUser(null)

    // FB delete login info
    window.sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`)
    window.localStorage.removeItem(`fblst_${FACEBOOK_APP_ID}`)

    removeCookie(COOKIE_LIST.EMAIL, COOKIE_OPT)
    removeCookie(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

    const pathname = '/' + getInitialLocale() + TEENS_URL.LANDING_HOME
    const cookiesToSet = {
      jwt_token: MIDDLEWARE_COOKIE_DELETE_FLAG,
      session_role: MIDDLEWARE_COOKIE_DELETE_FLAG,
    }

    await setCookieOnMiddleware({ router, pathname, cookiesToSet })
  }
}

interface SwitchToProps {
  queryObject?: Record<string, string>
  destination?: (typeof TEENS_URL)[keyof typeof TEENS_URL]
}

interface SwitchToParentProps extends SwitchToProps {
  parentId: number
  queryObject?: Record<string, string>
  destination?: TEENS_URL
}

// Switch to Parent with super pw
export const useSwitchToParent = () => {
  const { currentUser } = useCurrentUser()
  const router = useRouter()

  const passCode = 'abcd'
  const isChildByParent = IS_CHILD(currentUser) && BY_PARENT(currentUser)

  return async (props: SwitchToParentProps) => {
    const { parentId, queryObject = {}, destination = TEENS_URL.DASHBOARD } = props

    teensError(!isChildByParent, 'useSwitchToParent: loggedByParent validation failed', isChildByParent)

    const response = await axiosPost(apis.auth.passCode(parentId, passCode, isChildByParent))
    if (!response?.success) {
      teensError(false, 'useSwitchToParent: passCode api failed', response)
      return
    }

    const newCurrentUser: CurrentUser = response
    teensError(!IS_PARENT(newCurrentUser), 'useSwitchToChild: IS_PARENT validation failed', newCurrentUser)

    const newToken = newCurrentUser.jwt_token
    const newSessionRole = newCurrentUser.session_role

    const pathname = '/' + getInitialLocale() + destination
    const cookiesToSet = {
      jwt_token: newToken,
      session_role: newSessionRole,
      ...queryObject,
    }

    await setCookieOnMiddleware({ router, pathname, cookiesToSet })
  }
}
interface SwitchToChildProps extends SwitchToProps {
  childId: number
}

export const useSwitchToChild = () => {
  const { currentUser } = useCurrentUser()
  const router = useRouter()

  return async (props: SwitchToChildProps) => {
    const { childId, queryObject = {}, destination = TEENS_URL.PORTAL_HOME } = props

    teensError(!BY_PARENT(currentUser), 'useSwitchToChild: BY_PARENT validation failed', currentUser)

    const response = await axiosPost(apis.auth.accountSwitch(childId, true))
    if (!response?.success) {
      teensError(false, 'useSwitchToChild: accountSwitch api failed', response)
      return
    }

    const newCurrentUser: CurrentUser = response
    const isToChild = IS_CHILD(newCurrentUser)
    teensError(!isToChild, 'useSwitchToChild: IS_CHILD validation failed', newCurrentUser)

    const newToken = newCurrentUser.jwt_token
    const newSessionRole = newCurrentUser.session_role

    const pathname = '/' + getInitialLocale() + destination

    const cookiesToSet = {
      jwt_token: newToken,
      session_role: newSessionRole,
      ...queryObject,
    }

    await setCookieOnMiddleware({ router, pathname, cookiesToSet })
  }
}

export const useSignup = (type: 'email' | 'social') => {
  const cookies = new Cookies()
  const api = type === 'email' ? apis.auth.teensSignup.parent.email() : apis.auth.teensSignup.parent.socialAccount()

  return async (payload, callback, isMobile: boolean, onSuccess) => {
    try {
      const responseData = await axiosPost(api, payload, { shouldThrowError: true })

      if (!responseData?.jwt_token || payload?.session_role !== TEENS_SESSION_ROLE.PARENT) {
        toast.error(`Error : There has been an error with update. Please contact Ringle Team.`, {
          position: 'top-right',
        })
        return
      }

      cookies.set(COOKIE_LIST.JWT_TOKEN, responseData.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
      cookies.set(COOKIE_LIST.SESSION_ROLE, responseData.session_role, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.PHONE, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.EMAIL, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.PROVIDER, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.UID, COOKIE_OPT)
      if (responseData?.timezone) cookies.set(COOKIE_LIST.TIMEZONE, responseData.timezone, COOKIE_OPT)

      if (responseData.user) {
        const isRingleMember = responseData.user.is_ringle_member || responseData.user.email.includes('ringleplus.com')
        if (!isRingleMember) {
          sendGTMEvent(GTM_EVENT.COMPLETE_REGISTRATION, {
            user_id: responseData.user.id,
            signup_medium: isMobile ? 'mobile_web' : 'desktop_web',
            login_provider: payload?.provider,
          })

          console.info('Sign_up', {
            provider: payload?.provider,
            service: 'teens',
            platform: isMobile ? 'mobile_web' : 'desktop',
          })
        }
        onSuccess(responseData)
      }
    } catch (error) {
      callback(error?.response?.data?.error)
      console.error('teensEmailSignup error', error)
    }
  }
}

export const updateUserInfo = async (userInfo, callback) => {
  const childId = userInfo.child_id
  const url = childId ? `${apis.profile.info()}?child_id=${userInfo.child_id}` : apis.profile.info()

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getJwtToken(),
    },
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        if (data.user) {
          const cookies = new Cookies()
          cookies.set(COOKIE_LIST.USER_IMAGE, data.user.image_url, { path: '/' })
          cookies.set(COOKIE_LIST.TIMEZONE, data.user.timezone, { path: '/' })
          cookies.set(COOKIE_LIST.USER_ID, data.user.id, { path: '/' })
        }
        callback()
      } else {
        alert(data?.message)
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export const enum SOCIAL_LOGIN_PROVIDER {
  KAKAO = 'kakao',
  GOOGLE = 'google_oauth2',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  NAVER = 'naver',
}

export const enum PRODUCT_TYPE {
  PLUS = 'plus',
  TUTOR = 'tutor',
  TEENS = 'teens',
  ADMIN = 'admin',
}

export const enum OAUTH_GRANT_TYPE {
  PASSWORD = 'password', // 일반 email/sns login 시에 사용
  DELEGATION = 'delegation', // logged_by_parent, admin fake login인 경우에 사용
}

export interface ILoginPayload {
  grant_type: OAUTH_GRANT_TYPE
  username: string
  password: string
  locale: LocaleType
  product_type: PRODUCT_TYPE
  google_token_type?: 'id_token' | 'access_token' // only for {username:'snslogin:google'}
}

interface ISignInSocialAccountResponse {
  success: boolean
  account_id: number
  user_type: string
  phone?
  name: string
  phone_intl: string
  country_code: string
  onboarding_stage: number
  first_child_id: number
  timezone: string
  email: string
  user_id: number
  jwt_token: string
  provider: string
  uid: string
  error?
}

export const teensLoginWithProvider = async (
  router,
  payload: ILoginPayload,
  plusSignUpPopup,
  isThroughPlusPopup = false,
  provider = 'email',
  isMobile = false
) => {
  try {
    const response: ISignInSocialAccountResponse = await axiosPost(apis.auth.token(), payload, {
      shouldThrowError: true,
    })
    if (response) teensProcessSNS(router, response, plusSignUpPopup, isThroughPlusPopup, provider, isMobile)
  } catch (error) {
    const errorRes = error?.response?.data
    if (errorRes?.error) {
      alert(errorRes.error)
    }
  }
}

export const teensProcessSNS = (
  router: NextRouter,
  data: ISignInSocialAccountResponse,
  plusSignUpPopup?,
  isThroughPlusPopup = false,
  provider = 'email',
  isMobile = false
) => {
  const cookies = new Cookies()
  const url = urls.teens.auth.additionalInfo

  if (data) {
    if (data.user_type == TEENS_ACCOUNT_TYPE.NEW) {
      setCookieData(data)
      locationMove(router, url)
      return
    } else if (data.user_type == TEENS_ACCOUNT_TYPE.STUDENT) {
      //플러스 계정으로 회원가입하기
      if (isThroughPlusPopup) {
        setCookieData(data)
        locationMove(router, url)
        return
      } else {
        plusSignUpPopup()
      }
    } else if (data.user_type == TEENS_ACCOUNT_TYPE.PARENT) {
      //정상적인 경우 기 가입자의 경우에는 onboarding_stage가 0보다 큰 상태이기 때문에 토큰이 제대로 내려온다.
      cookies.set(COOKIE_LIST.JWT_TOKEN, data.jwt_token, COOKIE_OPT_FOR_ACCESS_TOKEN)
      //SNS로그인, 회원가입의 경우 부모만 가능하기 때문에 "Parent"로 세션롤을 넣어준다.
      cookies.set(COOKIE_LIST.SESSION_ROLE, TEENS_SESSION_ROLE.PARENT, COOKIE_OPT)
      cookies.remove(COOKIE_LIST.IS_ADMIN_LOGIN, COOKIE_OPT)

      console.info('Sign_in', {
        provider: provider,
        service: 'teens',
        platform: isMobile ? 'mobile_web' : 'desktop',
      })

      const moveUrl = cookies.get(COOKIE_LIST.LOGIN_AFTER_URL)
      if (moveUrl) {
        cookies.remove(COOKIE_LIST.LOGIN_AFTER_URL, COOKIE_OPT)
        locationMove(router, moveUrl)
      } else {
        locationMove(router, TEENS_URL.GATEWAY)
      }
    } else {
      alert(data?.error)
    }
  } else {
    alert(data?.error)
  }
}

export const getJwtToken = () => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_LIST.JWT_TOKEN)
}

export const removeJwtToken = () => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_LIST.JWT_TOKEN, COOKIE_OPT_FOR_ACCESS_TOKEN)
}

export const getRootUrl = () => {
  const cookies = new Cookies()
  const rootUrl = cookies.get(COOKIE_LIST.ROOT_URL)
  return rootUrl ? rootUrl : 'home'
}

export const emailRegExpServer = /^[A-Za-z0-9_.-]+@[A-Za-z0-9]+([.-][A-Za-z0-9]+)+$/
export function checkEmailValidity(asValue) {
  return emailRegExpServer.test(asValue) // 형식에 맞는 경우 true 리턴
}

export const noWhitespaceRegExp = /^\S*$/

export const setCookieData = (data) => {
  const cookies = new Cookies()
  //Account type
  if (data.user_type) cookies.set(COOKIE_LIST.ACCOUNT_TYPE, data.user_type, COOKIE_OPT)
  //SNS Provider
  if (data.provider) cookies.set(COOKIE_LIST.PROVIDER, data.provider, COOKIE_OPT)
  //Timezone
  if (data.timezone) cookies.set(COOKIE_LIST.TIMEZONE, data?.timezone, COOKIE_OPT)
  //Email
  if (data.email) cookies.set(COOKIE_LIST.EMAIL, data.email, COOKIE_OPT)
  //UID
  if (data.uid) cookies.set(COOKIE_LIST.UID, data.uid, COOKIE_OPT)
  //Name
  if (data.name) cookies.set(COOKIE_LIST.NAME, data.name, COOKIE_OPT)
  //Phone
  if (data.phone) cookies.set(COOKIE_LIST.PHONE, data.phone, COOKIE_OPT)
  //Phone Intl
  if (data.phone_intl) cookies.set(COOKIE_LIST.PHONE_INTL, data.phone_intl, COOKIE_OPT)
  //Account_ID
  if (data.account_id) cookies.set(COOKIE_LIST.ACCOUNT_ID, data.account_id, COOKIE_OPT)
}

/**
 * 휴대폰 번호 AES256으로 암호화해주는 함수
 * @param phone_number 휴대폰 번호
 * @returns 암호화된 휴대폰 번호
 */
export const encryptPhoneNum = (phone_number: string) => {
  const key = Utf8.parse(getEnv('CONF_PHONE_AES256_KEY'))
  const iv = Utf8.parse(getEnv('CONF_PHONE_AES256_IV'))

  return AES.encrypt(phone_number, key, { iv }).toString()
}

/**
 * 토큰에서 id값 추출해주는 함수
 * @param jwtToken 토큰
 * @returns id값 (없으면 null)
 */
export const getIdFromJwtToken = (jwtToken: string) => {
  if (!jwtToken) return null

  const payload = jwtToken.split('.')?.[1]
  if (!payload) return null

  const parsedWordArray = Base64.parse(payload)
  const decoded = Utf8.stringify(parsedWordArray)

  const obj = JSON.parse(decoded)
  const id = obj?.user_id ?? obj?.child_id ?? obj?.sub?.split(':')[1]

  return id
}
