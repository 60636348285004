import { useState } from 'react'
import CardTextarea from '../../../../../apps/ringle-student/components/_legacy/CardTextarea'
import { BUTTON_TYPE, DSButton, DSTextArea } from '../../index'
import { anchorClassName, descriptionClassName, headerClassName, subHeaderClassName } from '../style'
import SectionTextAreaExample from './TextArea/SectionTextAreaExample'

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const States = () => {
  const [value1, setValue1] = useState<string>('')
  const [value2, setValue2] = useState<string>('')

  return (
    <div>
      <p id="States" className={headerClassName}>
        States
      </p>

      <div className={subHeaderClassName}>TextArea without counter</div>
      <ul className="mt-[24px]">
        <div className="col-5 mb-[24px] pr-[40px]">
          <DSTextArea name={'self_intro'} value={value1} setValue={setValue1} placeholder="자기소개를 입력해주세요." />
        </div>
      </ul>

      <div className={subHeaderClassName}>TextArea with counter</div>
      <ul className="mt-[24px]">
        <div className="col-5 mb-[24px] pr-[40px]">
          <DSTextArea
            name={'self_intro'}
            value={value2}
            setValue={setValue2}
            placeholder="자기소개를 입력해주세요."
            textCounter={{ maxLength: 50 }}
          />
        </div>
      </ul>
    </div>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const Legacy = () => {
  const [text, setText] = useState('')

  const handleChange = (e) => {
    setText(e.target.value)
  }

  return (
    <section id="Legacy">
      <div className={headerClassName}>Legacy TextArea</div>
      <div className={descriptionClassName}>
        Legacy TextArea를 사용하고 있는 곳에서 TextArea를 사용하도록 변경이 필요합니다.
        <br />
        student(쓰이진 않지만 존재), teens(5개), tutor(10개), tutor-apply(쓰이진 않지만 존재) 각각에 모두 존재합니다.
      </div>
      <div className="flex flex-row w-[600px] items-end gap-[8px] mt-[8px]">
        <CardTextarea
          minRows={5}
          maxRows={5}
          cardClass="w-full border-1 bg-gray-50"
          onBlur={handleChange}
          placeholder="Enter Text"
          className="text-h5 text-gray-700"
        />
        <DSButton
          type={BUTTON_TYPE.OUTLINE}
          className="max-w-max items-end"
          onClick={(e) => alert(text || 'Enter text')}
        >
          <DSButton.Label>확인하기</DSButton.Label>
        </DSButton>
      </div>
    </section>
  )
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const TextAreaComponent = () => {
  return (
    <div className="flex h-full w-full flex-row">
      <div className="border-r-1 flex grow flex-col gap-[20px]">
        <p className={headerClassName}>Text area</p>
        <p className={descriptionClassName}>
          Text area는 문자, 숫자, 기호등을 입력하고 편집할 수 있는 입력 컴포넌트입니다. 입력이 multi-line인 경우 Text
          area를 사용합니다.
        </p>
        <SectionTextAreaExample />
        <States />
        <Legacy />
      </div>

      <div className="sticky top-[48px] flex h-0 w-[256px] flex-none flex-col gap-[12px] px-[24px]">
        <p className="mb-[12px] text-[20px] font-bold">Categories</p>
        <a href="#Inputs" className={anchorClassName}>
          TextArea
        </a>
        <a href="#States" className={anchorClassName}>
          States
        </a>
        <a href="#Legacy" className={anchorClassName}>
          Legacy
        </a>
      </div>
    </div>
  )
}

export default TextAreaComponent
